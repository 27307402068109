import React, { Component, createRef } from 'react'
import {
  Button, Form, Icon, Image, Container, Segment, Header, Input, Label,
  Select, Ref, Grid, Sticky, Progress
} from 'semantic-ui-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import avatar from "assets/img/user.png";
import PreloadFile, { previewUrl } from 'views/web/PreloadFile';
import { clickPreview } from 'views/web/RowImage'

// import StudentViewTabs from './StudentViewTabs';
// import { getLabelName2, getOptionsByKey, getOptionTextByValue } from 'views/widgets/FieldsRender';
// import FileUploader from "react-firebase-file-uploader";
import EmployeeViewTabs from './EmployeeViewTabs';
import intl from 'react-intl-universal';

const isWorkArr = [
  { key: 'isWorkOption1', text: '在職', value: '1' },
  { key: 'isWorkOption2', text: '離職', value: '0' },
]

const isWorkArrMain = [
  { key: 'isWorkOption0', text: '全部', value: '-1' },
  ...isWorkArr
]

export default class employeePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginUser: this.props.user.profile.toJSON(),
      isEdit: false, // props.isEdit,
      doc: {}, // 原本資料
      form: {},  // 更改處
      jpForm: {},
      optEmployees: [],
      resEmployees: [],
      searchText: '',
      formModal: {
        nameEmployees: '',
        nameEmployeesFull: '',
        partTimeEmployees: '',
        startSemesterNum: '',
        endSemesterNum: '',
      },
      show: false,
      // loading: false,
      selTypeId: '1',
      employee: [],
      fileArr: [],
      fileArrCheck: [],
      filenames: [],
      downloadURLs: [],
      downloadURLsArr: [],
      rowData: {},
      changeConfirm: true,
      loadingData: false,

    };
  }

  componentDidMount() {
    const { loginUser } = this.state
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const authPage = window.location.pathname.split('/')[2]
    const authUser = companyId + '_' + authPage
    let auth = {}
    if ((loginUser.authAdminA && loginUser.authAdminA.indexOf(companyId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
      auth = {
        edit: true,
      }
    } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
      auth = {
        edit: false,
      }
    } else {
      auth = {
        edit: false,
      }
    }
    this.setState({ auth }, () => this.handleFetch(this.props))
    // this.handleFetch(this.props)
  }

  componentWillReceiveProps(nextProps) {
    // const { firebase } = nextProps;
    // const db = firebase.firestore();
    this.handleFetch(nextProps, 0)
    // console.log(firebase.auth().currentUser);
  }


  handleFetch = async (props = this.props, isFetch = 1) => {
    const { match, history, companyObj } = props;
    // console.log(match, history);
    const { isEdit, selTypeId } = this.state;
    const { employeeId } = match.params;

    let employee = [];

    if (isFetch) {
      let now = new Date().getTime()
      // const db = firebase.firestore();
      // fetch 
      // const employee = []
      // const snap = await db.collection(`users`).where("authUserA", "array-contains", companyObj._id).get();
      // snap.forEach(item => employee.push({ _id: item.id, ...item.data() }));
      const user = await Parse.queryData('User', { authUserA: companyObj.objectId })
      const userSecret = await Parse.Cloud.run('getEmailsInCompany', { companyId: companyObj.objectId })
      employee = user.map(item => {
        let onbroad = new Date(item.onbroad_date).getTime()
        let seniorityYear = parseInt((now - onbroad) / 1000 / 60 / 60 / 24 / 365)
        let seniorityMonth = parseInt(((now - onbroad) - (seniorityYear * 86400000 * 365)) / 1000 / 60 / 60 / 24 / 30)
        if (seniorityMonth < 0) {
          seniorityMonth = 0
        }
        let obj = {
          ...item,
          ...userSecret.find(item2 => item2.objectId === item.objectId),
          jobyear: `${seniorityYear}年${seniorityMonth}月`
        }
        return obj
      })
      // this.setstate({ isFetch: false });
    } else {
      employee = this.state.employee;
    }

    if (!isEdit) {
      // const { match, clients } = props;
      // const { _id } = match.params;
      let currentEmployees = employee.sort((a, b) => ('' + a.jobNum).localeCompare(b.jobNum));
      let obj = {};
      // console.log(employeeId)
      if (employeeId) {
        obj = employee.find(item => item.objectId === employeeId) || {};
      } else {
        obj = currentEmployees.find(item => item.authLoginA && item.authLoginA.indexOf(companyObj.objectId) !== -1);
        obj && history.push(`/admin/employeePage/1/${obj.objectId}`);
      }
      let optEmployees = currentEmployees.map(item => ({
        key: item.objectId,
        text: `[${item.authLoginA && item.authLoginA.indexOf(companyObj.objectId) !== -1 ? '在職' : "離職"}] [${item.jobNum || '未輸入工號'}] ${item.name || item.username} ${item.name_eng || ''} ${'(' + (item.jobTitle || '未輸入職稱') + ')'}`,
        value: item.objectId,
        is_auth: item.authLoginA && item.authLoginA.indexOf(companyObj.objectId) !== -1 ? '1' : '0',
      }));
      // console.log(optEmployees)
      // console.log(obj);
      if (selTypeId === '-1') { // 在職＋離職
        this.setState({ doc: obj, form: obj, employee, optEmployees, resEmployees: optEmployees });
      } else {
        this.setState({ doc: obj, form: obj, employee, optEmployees, resEmployees: optEmployees.filter(item => item.is_auth === selTypeId) });
      }
    }
  }

  initEmployees = (props = this.props) => {
  }

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  handleUpdateSetting = (event, { name, value }) => {
    console.log('handleUpdateSetting');
    const { form, doc } = this.state;

    // console.log(name, value)
    if (this.isEmpty(form)) { // 空白
      this.setState({ form: { ...doc, [name]: value } });
    } else {
      this.setState({ form: { ...form, [name]: value } });
    }
  }

  handleChangeField = (e, data) => {
    const { form } = this.state
    if (data && data.id && data.id !== 'fieldFormGroup') {
      this.setState({ form: { ...form, [data.name]: data.checked === true ? '1' : '0' } })
    } else {
      if (e.target) {
        this.setState({ form: { ...form, [e.target.name]: e.target.value } })
      } else {
        if (typeof form[data.name] === 'string') {           //若原資料為string，存回去的資料型態就使用string。若為object，存回去的資料型態就使用object。 ps:react-select儲存為object
          this.setState({ form: { ...form, [data.name]: e.label } })
        } else if (typeof form[data.name] === 'object') {
          this.setState({ form: { ...form, [data.name]: e } })
        } else {
          this.setState({ form: { ...form, [data.name]: e } })
        }
      }
    }
  }

  saveUpdateSetting = async () => {
    const { match } = this.props;
    const { form, employee } = this.state;

    // console.log("update")
    // firebase.firestore().collection(`users`).doc(form.objectId)
    //   .update(form).then(docRef => {

    //     const data = employee;
    //     const index = data.findIndex(item => item.objectId === form.objectId);
    //     data[index] = { ...form }
    //     this.setState({ isEdit: false, doc: form, employee: data }, () => this.handleFetch(this.props, 0));
    //   });
    if (window.confirm('確定儲存？')) {
      let data = [...employee]
      const index = data.findIndex(item => item.objectId === form.objectId);
      data[index] = { ...form }
      await Parse.saveData('User', form);
      this.setState({ isEdit: false, doc: form, employee: data }, () => this.handleFetch(this.props, 1));
    }
  }

  cancelUpdateSetting = () => {
    const { form, doc } = this.state;
    let obj = {
      ...doc,
      // ps: '',
    }
    this.setState({ isEdit: false, form: obj });
  }

  printDiv = () => {
    window.print(); // 這是印整個頁面的
  }


  handleChange = (event, { name, value }) => {
    const { match, history, } = this.props
    const { work, optEmployees, form, searchText } = this.state
    const { _id } = match.params;

    let resEmployees;
    if (name === 'selTypeId') { // 1在職 0離職
      if (value === '-1') { // 全部
        resEmployees = optEmployees
      } else {
        resEmployees = optEmployees.filter(item => item.is_auth === value);
      }
      if (searchText) {
        resEmployees = resEmployees.filter(item => item.text.match(searchText));
      }
      this.setState({ resEmployees })
    }
    // else if (name === 'bgId') {
    //   resEmployees = optEmployees.filter(item => item.bgId === value)
    //   // this.setState({ resEmployees })
    // }
    this.setState({ [name]: value });
    // console.log('hello world')
  };


  handleClick = (event, d) => {
    let fleldVal = event.target.value;
    const { match, history } = this.props
    const { bgId, doc, employee } = this.state
    history.push(`/admin/employeePage/1/${fleldVal}`)
    // console.log(fleldVal)
    const obj = employee.find(item => item.objectId === fleldVal) || {};
    // const db = firebase.firestore();
    // let ref = db.collection(`company/${companyObj._id}/bg/${bgId}/employee`).doc();
    this.setState({ doc: obj, form: obj, searchText: '' });
  }

  contextRef = createRef()

  handleSearch = (e, { value }) => {
    const { optEmployees, selTypeId } = this.state;

    if (value) {
      // 搜尋值中間可以插入任意值 例如搜尋'程雪原' 可以找到 '程 雪原'
      const looseSearchValue = RegExp(value.split('').reduce((pattern, item) => pattern + item + '.*', '.*'));
      let resEmployees = []
      if (selTypeId === '-1') {
        resEmployees = optEmployees.filter(item => item.text.match(looseSearchValue));
      } else {
        resEmployees = optEmployees.filter(item => item.text.match(looseSearchValue) && item.is_auth === selTypeId)
      }
      this.setState({ resEmployees, searchText: value });
      // console.log(res)
    } else {
      const resEmployees = selTypeId === '-1' ? optEmployees : optEmployees.filter(item => item.is_auth === selTypeId)
      this.setState({ resEmployees, searchText: value });
    }
  }


  open = (e, data) => {
    const { form, isEdit } = this.state
    e.preventDefault();
    if (isEdit) {
      this.setState({ show: true, rowData: { ...form }, inputType: e.target.id })
    } else {
      this.setState({ preview: [e.target.id], inputType: e.target.id })
    }
  }

  close = () => this.setState({ show: false, preview: false, changeConfirm: true })


  handleChangeFile = async (file) => {
    this.setState({ file, changeConfirm: false })
  }

  handleSubmit = async () => {
    const { file, rowData, inputType, } = this.state
    const res = await Parse.putFile(file, inputType).catch((error) => {
      console.error(error.code + " " + error.message);
    }); // 單純存檔
    this.setState({ form: { ...rowData, [inputType]: res }, show: false, loadingData: false, changeConfirm: true });
  }

  handlePicDelete = async () => {
    const { form, rowData } = this.state
    if (window.confirm('確定刪除?')) {
      let obj = {
        ...form,
        profilePic: null
      }
      let obj2 = {
        ...rowData,
        profilePic: null
      }
      this.setState({ form: obj, rowData: obj2 })
    }
  }

  render() {
    // const { optSet, formSet, match, history, semesterYear, semester, systemObj, clients, bg } = this.props;
    const { companyObj } = this.props
    const { doc, form, isEdit, selTypeId, resEmployees, searchText, show, loading, formModal, employee, fileArrCheck, fileArr, rowData, inputType, changeConfirm, loadingData, auth = {}, preview } = this.state;
    return (<>
      <div className="content">
        <div style={{ padding: '10px' }}>
          <Ref innerRef={this.contextRef}>
            <Grid>
              <Grid.Column width={3} computer={3} tablet={5} mobile={16}>
                {/* <Sticky context={this.contextRef}> */}
                <Header inverted={companyObj.inverted} as='h3'>4-6 員工列表</Header>
                <Form size='small'>
                  {/* <Input labelPosition='left' > */}
                  {/* <Label color={'teal'}> <Icon name='newspaper' />表示</Label> */}
                  {/* <Select style={{
                      minWidth: '100px',
                      float: 'left'
                      // borderTopLeftRadius: '0',
                      // borderBottomLeftRadius: '0'
                    }} compact
                      options={bgSel}
                      onChange={this.handleChange}
                      disabled={isEdit}
                      value={bgId}
                      name='bgId' /> */}
                  {/* </Input>{' '} */}
                  {/* <br /> */}
                  {/* <Input labelPosition='left' > */}
                  {/* <Label color={'green'}> <Icon name='file alternate outline' />学期</Label> */}
                  <Select style={{
                    marginLeft: '10px',
                    minWidth: '100px',
                    // float: 'left'
                    // borderTopLeftRadius: '0',
                    // borderBottomLeftRadius: '0'
                  }} compact
                    // floating={true}
                    options={isWorkArrMain}
                    onChange={this.handleChange}
                    disabled={isEdit}
                    value={selTypeId}
                    name='selTypeId' />
                  <br />
                  {/* {!isEdit ?
                  <Button
                    style={{ marginTop: '4px' }}
                    // floated='right' 
                    size='tiny' primary onClick={() => this.setState({ show: true })} icon="add user" content="新增員工" /> : null}{' '} */}
                  <br />
                  <Form.Input
                    style={{ marginTop: '4px', marginBottom: '4px' }}
                    placeholder='Search...'
                    disabled={isEdit}
                    onChange={this.handleSearch}
                    value={searchText}
                  />
                </Form>
                <select multiple className="form-control ui form fluid search dropdown"
                  style={{ height: '600px', width: '100%', fontSize: '11px', overflowX: 'auto', }} name="EmployeesSel"
                  onChange={this.handleClick}
                  // value={[doc._id]}
                  disabled={isEdit}
                >
                  {resEmployees && resEmployees.map(item => <option key={item.key} className="item" value={item.value}>{item.text}</option>)}
                </select>
                {/* </Sticky> */}
              </Grid.Column>
              {form && form.objectId ?
                <Grid.Column width={13} computer={13} tablet={11} mobile={16}>
                  <Segment
                    clearing
                    vertical
                  >
                    <Header floated='left' as='h2' inverted={companyObj.inverted}>
                      {/* <div className="author"> */}
                      <a href="#pablo" onClick={this.open} >
                        <img
                          alt="個人圖片"
                          id='profilePic'
                          className="avatar border-gray"
                          style={{ maxWidth: '150px', maxHeight: '150px' }}
                          src={(form && form.profilePic && (form.profilePic._url || form.profilePic.url)) || avatar}
                        />
                        {/* <CardImg top width="100%" src="/assets/318x180.svg" alt="Card image cap" /> */}
                        {/* <Image src={'https://react.semantic-ui.com/images/wireframe/square-image.png'} size='tiny' /> */}
                      </a>
                      {/* <h5 className="title">{profile.name}</h5> */}
                      {/* <p className="description">@{profile.username}</p> */}
                      {/* </div> */}
                      <Header.Content style={{ marginLeft: '10px' }}>
                        {(form && form.jobNum) || '未輸入工號'}&nbsp;
                        {(form && form.name) || (form && form.username)}
                        <Header.Subheader>
                          {/* {form && form.clientNum ? <><span className="text-muted">{getLabelName2(formSet, 'clientNum')}:</span>&nbsp;{form.clientNum}&nbsp;&nbsp;</> : ''} */}
                          {form && form.employeeTitleText ? <><span className="text-muted">職位:</span>&nbsp;{form.employeeTitleText}&nbsp;&nbsp;</> : ''}
                          {form && form.partTimeemployeeText ? <><span className="text-muted"></span>&nbsp;{form.partTimeemployeeText}&nbsp;&nbsp;</> : ''}
                        </Header.Subheader>
                        {/* <br /> */}
                        <div style={{ marginTop: '15px' }}>
                          {isEdit ? <Button positive size='small' onClick={this.saveUpdateSetting} icon='save' content={intl.get('儲存')} /> : <Button primary size='small' onClick={() => this.setState({ isEdit: true })} icon='edit' content={intl.get('編輯')} disabled={!auth.edit} />}
                          {isEdit ? <Button basic size='small' onClick={this.cancelUpdateSetting} icon='remove' content={intl.get('取消')} /> : null}
                        </div>
                      </Header.Content>
                    </Header>
                  </Segment>
                  <br />
                  <EmployeeViewTabs
                    {...this.props}
                    doc={form}
                    auth={auth}
                    // form={form}
                    // bgId={bgId}
                    employee={employee}
                    isEdit={isEdit}
                    handleFetch={this.handleFetch}
                    handleUpdateSetting={this.handleUpdateSetting}
                    handleChangeField={this.handleChangeField}
                  />
                </Grid.Column> : null}
            </Grid>
          </Ref>
          {preview ? clickPreview(form[inputType] || avatar, preview, this.close) : null}
          <Modal
            isOpen={this.state.show}
            toggle={this.close}
            size="lg"
            className="mh-90 h-90 mw-100 w-90"
            style={{ paddingTop: '0px' }}
          // style={{ maxWidth: '1600px', width: '80%' }}
          >
            <ModalHeader className="justify-content-center">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.close}>
                <span aria-hidden="true">×</span>
              </button>
              更新圖片
          </ModalHeader>
            <ModalBody>
              <PreloadFile isImage='1' handleChangeFile={this.handleChangeFile} />
              {rowData[inputType] ? <h5>目前照片</h5> : null}
              {previewUrl(rowData[inputType], 1)}
            </ModalBody>
            <ModalFooter style={{ padding: '16px' }}>
              <Button content={'確認更改?'} onClick={() => this.setState({ loadingData: true }, () => this.handleSubmit())} hidden={changeConfirm} color='green' loading={loadingData} />
              <Button content={'刪除'} hidden={rowData[inputType] ? false : true} color='red' onClick={this.handlePicDelete} />
              <Button icon='x' content='關閉' onClick={this.close} />
            </ModalFooter>
          </Modal>


          {/* <Modal size='tiny' open={show} onClose={this.handleClose} centered={false}>
        <Modal.Header>新增</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths='equal'>
              <Form.Field control={Input} label='員工編號' placeholder='員工編號' onChange={this.handleChangeModal} name='orderIdemployee' value={formModal.orderIdemployee || ''} />
              <Form.Field control={Input} label='中文姓名' placeholder='中文姓名' onChange={this.handleChangeModal} name='nameemployee' value={formModal.nameemployee || ''} />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} >關閉</Button>
          <Button primary icon='checkmark' labelPosition='right' onClick={this.handleSubmit} content='新增' loading={loading} />
        </Modal.Actions>
      </Modal> */}
          {this.state.isUploading && <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 10000,
            width: '100vw',
            height: '100vh',
            background: 'rgba(0,0,0,0.87)',
            overflow: 'hodden'
          }}>
            <Progress percent={this.state.progress} indicating error={this.state.error} progress />
          </div>}
        </div >
      </div>
    </>
    );

  }
}
