import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Grid, Button, Card, Icon, Image, } from 'semantic-ui-react';
import 'react-day-picker/lib/style.css';
import Moment from 'moment';

import RollcallModal from './RollcallModal';
import { getNextAttendStatus, getBGColor, handleClickStudent, getBGText, showColorInfo } from 'views/widgets/FieldsRender';

const isEmptyObj = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
};

const i1style = { paddingTop: '2px', paddingBottom: '2px' };
const i2style = { paddingLeft: '2px', paddingRight: '2px' };

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  height: '100%',
  background: isDragging ? 'lightgreen' : 'grey',

  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
});

const cardComponent = (firebase, optSet, id, obj, isEdit, rollcallObj, handleShowModal, handleCloseModal) => (
  <Droppable
    droppableId={`${id}`}
    isDropDisabled={!isEdit}
  >
    {(provided, snapshot) => (<div
      ref={provided.innerRef}
      style={getListStyle(snapshot.isDraggingOver)}>
      {obj && obj.jpnName ?
        <Draggable
          draggableId={`draggable${id}`}
          isDragDisabled={!isEdit}
          index={id}>
          {(provided, snapshot) => (<div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}>
            <Card style={{ backgroundColor: getBGColor(optSet, rollcallObj.attendStatus) }}>
              <Card.Content style={{ padding: '5px' }}>
                <div
                  style={{ height: '80px', fontSize: '1em', cursor: isEdit ? '' : 'pointer', textAlign: 'center' }}
                  onClick={isEdit ? () => void 0 : () => { handleClickStudent(firebase, optSet, rollcallObj); handleCloseModal() }}
                >
                  {/* <Image floated='right' size='mini' circular src={obj.photo} /> */}
                  <div style={{ fontWeight: 'bold', fontSize: '1.1em', paddingBottom: '0px' }} >{rollcallObj && rollcallObj.attendStatusText !== "未決" && rollcallObj.attendStatusText} {obj.studentId}</div>
                  <span style={{
                    color: obj.gender === "1" ? '#cc0000' : '',
                    fontSize: obj.katakanaName && obj.katakanaName.length > 8 ? '1.1em' : '1.5em'
                  }}>{obj.katakanaName}</span><br />
                  <span style={{
                    color: obj.gender === "1" ? '#cc0000' : '',
                    fontSize: obj.katakanaName && obj.katakanaName.length > 10 ? '1.1em' : '1.5em'
                  }}>{obj.jpnName}</span> <br />
                </div>
                {obj.jpnName ?
                  <Button
                    size="mini"
                    // basic
                    // inverted={companyObj.inverted}
                    // color='blue'
                    fluid
                    disabled={isEdit}
                    // style={{ backgroundColor: '#fff' }}
                    // onClick={() => this.handleClickButton(classStudentsArr[index2], classObj)}
                    onClick={() => handleShowModal(obj, rollcallObj)}
                  ><Icon name='pencil alternate' /> 詳細</Button> : null}
              </Card.Content>
            </Card>
          </div>
          )}
        </Draggable> : <div style={{ height: '119px' }}>&nbsp;</div>}
      {/* {provided.placeholder} */}
    </div>
    )}
  </Droppable>);

export default class RollCallSeats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      modalObj: {},
      modalAttendObj: {},
      showSettingsModal: false,
      // seatArr: [...props.seatArr]
      seatArr: props.seatArr
    };
  }

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps.seatArr);
    if (nextProps.classObj !== this.props.classObj) {
      this.setState({ showSettingsModal: false, modalObj: {}, modalAttendObj: {} })
    }
    this.setState({ seatArr: nextProps.seatArr });
  }

  handleShowModal = (modalObj, modalAttendObj) => { this.setState({ showSettingsModal: true, modalObj, modalAttendObj }) }
  handleCloseModal = () => { this.setState({ showSettingsModal: false, modalObj: {}, modalAttendObj: {} }) }

  onDragEnd = (result) => {
    // const { seatArr } = this.state;
    const seatArr = [...this.state.seatArr];

    if (!result || !result.destination) {
      return;
    }

    if (result.source.index === result.destination.index &&
      result.source.droppableId === result.destination.droppableId) { } else {
      // console.log(result.source.droppableId, result.destination.droppableId);

      const x = result.source.droppableId;
      const y = result.destination.droppableId;
      // console.log(seatArr);
      const b = seatArr[y];
      seatArr[y] = seatArr[x];
      seatArr[x] = b;
      // console.log(seatArr);

      this.setState({ seatArr });
    }
  };

  saveSeatOrder = () => {
    const { firebase, classObj } = this.props;
    const { seatArr } = this.state;

    firebase.firestore().doc(`/Classroom/${classObj.objectId}`).update({ seatArr }).then(() => this.setState({ isEdit: false }))
  }
  cancelSeatOrder = () => {
    const { seatArr } = this.props;

    // console.log(seatArr, this.state.seatArr)
    this.setState({ seatArr, isEdit: false })
  }
  render() {
    const { firebase, classObj, optSet, formSet, weekday, period, classStudentsArr, courseName, calendar,
      teachersArrange, teachers, attendStatusArr, eventDate } = this.props;
    const { isEdit, modalObj, modalAttendObj, showSettingsModal, seatArr } = this.state;

    let obj = {};
    const course = 'course' + weekday + period; // classTimeArr2是哪一堂
    // const slot = 'slot' + index2 + classTimeArr2[index]; // classTimeArr2是哪一堂
    if (classObj && classObj.courseArrs && classObj.courseArrs.find(item => item.slot === course)) {
      obj = classObj.courseArrs.find(item => item.slot === course);
    }

    const arrCalendar = calendar.filter(item => item.eventDate === eventDate && item.status === "3");
    const attendSamePeriodArr = attendStatusArr.filter(item => item.weekday === weekday + "" && item.period === period);
    // console.log(eventDate, arrCalendar);
    // let teacherObj = {};
    // if (arrangeObj) {
    //   teacherObj = teachers.find(item => item._id === arrangeObj.teacherId) ?
    //     teachers.find(item => item._id === arrangeObj.teacherId) : {};
    // }
    // console.log(teacherObj && teacherObj.nameTeacher);

    // console.log('RollCallSeats')
    // console.log(seatArr)
    // console.log(classStudentsArr.map(item => `${item.studentId} ${item.jpnName}`));
    return (!arrCalendar.length ? <>
      <div>
        {/* {showColorInfo(optSet)} */}
        <span style={{ alignItems: 'center', justifyContent: 'center' }}>
          {/* 時限：{courseName} {!isEmptyObj(obj) && obj.courseName ? obj.courseName : ''}&nbsp; */}
          {/* 担任：{classObj.teacherText || '(尚未指定)'}&nbsp; */}
          {/* 講師：{teacherObj && teacherObj.nameTeacher} */}
        </span>
        <br />
        <br />
        <DragDropContext onDragEnd={this.onDragEnd} >
          <Grid columns={6} >
            {['', '', '', '', ''].map((i1, index1) => (
              <Grid.Row key={"i1" + index1} style={i1style} stretched>
                {['', '', '', '', '', ''].map((i2, index2) => {
                  const id = index1 * 6 + index2;
                  const seatStudentNum = id >= seatArr.length ? '' : seatArr[id] || '';
                  const studentObj = classStudentsArr.find(item => item.studentId === seatStudentNum) || {};
                  const rollcallObj = attendSamePeriodArr.find(item => item.studentId === studentObj.objectId) || {
                    objectId: studentObj.objectId,
                    jpnName: studentObj.jpnName,
                    studentId: studentObj.objectId,
                    studentNum: studentObj.studentId,
                    attendStatus: "-1",
                    attendStatusText: "",
                    classname: classObj.classSchool,
                    period,
                    weekday,
                    courseName,
                    eventDate,
                  };
                  return <Grid.Column key={"i2" + index2} style={i2style}>{cardComponent(firebase, optSet, id, studentObj, isEdit, rollcallObj, this.handleShowModal, this.handleCloseModal)}</Grid.Column>
                })}
              </Grid.Row>))}
          </Grid>
        </DragDropContext>
        <br />
        {showSettingsModal ?
          <RollcallModal
            optSet={optSet} modalObj={modalObj}
            showSettingsModal={showSettingsModal}
            handleCloseModal={this.handleCloseModal}
            firebase={firebase}
            eventDate={eventDate}
            period={period}
            classObj={classObj}
            closeModal={this.handleCloseModal}
            single={true}
          /> : null}
        <br />
        <Grid>
          <Grid.Row>
            <Grid.Column width={5}></Grid.Column>
            <Grid.Column width={5} style={{ border: '2px solid #000', textAlign: "center", paddingTop: '6px', marginRight: '20px', fontSize: '1.5em' }}><Icon name='university' />&nbsp; 教卓</Grid.Column>
            {isEdit ? <>
              <Button positive onClick={() => this.saveSeatOrder()} icon='save' content='保存' />
              <Button onClick={() => this.cancelSeatOrder()}><Icon name='times' />&nbsp;キャンセル</Button>
            </> : <Button primary onClick={() => this.setState({ isEdit: true })}><Icon name='edit' />&nbsp;編集席替え</Button>
            }
          </Grid.Row>
        </Grid>
        {/* 說明: 直接点名字卡，可依上面的順序切換学生上課狀態。点「詳細」，可切換クラス、設定遲到分鐘，或設定詳細的資訊。按「編集座位」可移到沒有人的位置上，或是和別人交換座位。 */}
      </div>
    </> : <>{arrCalendar.map(item => <div key={item.objectId} style={{ fontSize: '36px' }}>{item.title}</div>)}</>);
  }
}