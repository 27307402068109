import React, { Component } from 'react'
import { Select, Input } from 'semantic-ui-react';
import MaterialTable, { MTableBodyRow } from 'material-table'
import Moment from 'moment';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { typeCurrency, exportPdf } from 'views/web/CustomMaterialTable';

let rowData = {}
let dragData = {}
let is_authOpt = { '1': '在職', '0': '離職' }

const columns = () => ([
  { title: '姓名', field: 'name' },
  { title: '應上班時數', field: 'shouldWork', },
  { title: '實際上班時數', field: 'totalWorkTime' },
  { title: '請假時數', field: '' },
  { title: '應發薪資', field: 'personalSalary', },
  { title: '實發薪資', field: 'totalSalary', },
  { title: '備註', field: '' },
]);

const columns1 = () => ([
  { title: '在職', field: 'is_auth', lookup: is_authOpt, editable: 'never' },
  { title: '員工編號', field: 'jobNum', editable: 'never' },
  { width: 80, title: '員工姓名', field: 'name', editable: 'never' },
  { title: '到職日', field: 'onbroad_date', editable: 'never' },
  { title: '離職日', field: 'leave_date', editable: 'never' },
  { width: 80, title: '上班時數', field: 'workTime', },
  { title: '基本薪資', field: 'salary1', ...typeCurrency },
  { title: '交通津貼', field: 'salary2', ...typeCurrency },
  { title: '伙食津貼', field: 'salary3', ...typeCurrency },
  { title: '職務加給', field: 'salary4', ...typeCurrency },
  { title: '加班', field: 'overTimePay', ...typeCurrency },
  { title: '其他', field: 'other', ...typeCurrency },
  {
    title: '應領合計', field: 'totalrec', editable: 'never',
    // render: rowData => 'NT$' + (Number(rowData.salary1 || 0) + Number(rowData.salary2 || 0) + Number(rowData.salary3 || 0) + Number(rowData.salary4 || 0) + Number(rowData.other || 0))
  },
  { title: '請假', field: 'deduct1', ...typeCurrency },
  { title: '健保', field: 'deduct2', ...typeCurrency },
  { title: '勞保', field: 'deduct3', ...typeCurrency },
  { title: '勞工自提勞退', field: 'deduct5', ...typeCurrency },
  { title: '代扣稅款', field: 'deduct4', ...typeCurrency },
  {
    title: '應扣合計', field: 'totaldedu', editable: 'never',
    // render: rowData => 'NT$' + (Number(rowData.deduct1 || 0) + Number(rowData.deduct2 || 0) + Number(rowData.deduct3 || 0) + Number(rowData.deduct4 || 0))
  },
  {
    title: '實發金額', field: 'amount', editable: 'never', ...typeCurrency,
    // render: rowData => 'NT$' + ((Number(rowData.salary1 || 0) + Number(rowData.salary2 || 0) + Number(rowData.salary3 || 0) + Number(rowData.salary4 || 0) + Number(rowData.other || 0))
    //   - (Number(rowData.deduct1 || 0) + Number(rowData.deduct2 || 0) + Number(rowData.deduct3 || 0) + Number(rowData.deduct4 || 0)))
  },
  { title: '備註', field: 'ps' },
]);

export default class ClockSalary extends Component {
  state = {
    loginUser: this.props.user.profile.toJSON(),
    bankaccArr: [],
    dataArr: [],
    predictArr: [],
    deleteConfirm: false,
    loading: true,
    bankaccSel: '',
    preBalance: '',
    nextBalance: '',
    preBalanceArr: [],
    nextBalanceArr: [],
    monthSel: Moment(new Date()).format('YYYY-MM') || '',
  }

  async componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props, type = 1) => {
    const { monthSel } = this.state
    const { companyObj } = this.props
    const [year, month] = monthSel.split('-');
    const user = await Parse.queryData('User', { authLoginA: companyObj.objectId })
    const y = Number(year);
    const m = Number(month) - 1;
    const begin_date = new Date(y, m, 1).getTime();
    const end_date = new Date(y, m + 1, 0).getTime();

    const emDataArr = user.map(item => {
      if (!item.onbroad_date || typeof item.onbroad_date === "undefined") {
        return null;
      }

      // 進來時間 比月底還早的話 就要顯示 且 
      // 有離職時間的話 離職時間 比月初還晚的話 就要顯示
      if (new Date(item.onbroad_date).getTime() <= end_date) {
        if (!item.leave_date || typeof item.leave_date === "undefined") {
          // console.log(new Date(item.onbroad_date).getTime())
          return item;
        } else if (new Date(item.leave_date).getTime() >= begin_date) {
          // console.log(new Date(item.leave_date).getTime(), begin_date)
          return item;
        }
      }
      return null;
    }).filter(item => item);
    const attendanceList = await Parse.queryData('attendanceList', { companyId: companyObj.objectId, year, month })
    this.setState({ employee: emDataArr, loading: false, attendanceList }, () => this.handleCalculate())
  }

  handleCalculate = () => {
    const { attendanceList, employee } = this.state
    const newEmployee = employee.map(item => {
      const personalSalaryArr = attendanceList.filter(item2 => item2.offWorkAt && item2.userId === item.objectId)
      let totalWorkTime = 0
      let totalSalary = 0
      personalSalaryArr.forEach(item2 => {
        totalWorkTime += item2.workTime
        totalSalary += item2.salary
      })
      // let obj = {
      //   ...item,
      //   shouldWork: 176,
      //   totalWorkTime,
      //   personalSalary: 176 * 158,
      //   totalSalary
      // }
      let obj = {
        ...item,
        totalrec: Number(totalSalary || 0) + Number(rowData.salary2 || 0) + Number(rowData.salary3 || 0) + Number(rowData.salary4 || 0) + Number(rowData.other || 0),
        totaldedu: Number(rowData.deduct1 || 0) + Number(rowData.deduct2 || 0) + Number(rowData.deduct3 || 0) + Number(rowData.deduct4 || 0),
        amount: ((Number(totalSalary || 0) + Number(rowData.salary2 || 0) + Number(rowData.salary3 || 0) + Number(rowData.salary4 || 0) + Number(rowData.other || 0))
          - (Number(rowData.deduct1 || 0) + Number(rowData.deduct2 || 0) + Number(rowData.deduct3 || 0) + Number(rowData.deduct4 || 0))),
        salary1: totalSalary,
        workTime: totalWorkTime
      }
      return obj
    })
    this.setState({ dataArr: newEmployee })
  }


  handleAdd = async () => {
    const { newData, monthSel, bankaccSel } = this.state
    const [year, month] = monthSel.split('-');
    if (!year || !month) {
      return;
    }
    const obj = {
      ...newData,
    };
    await Parse.saveData('predict', obj);
    this.handleCalculate()
  }

  handleUpdate = async () => {
    const { newData, monthSel, bankaccSel, bankaccArr } = this.state
    const { companyObj } = this.props
    const [year, month] = monthSel.split('-');
    // let bankaccName = ''
    // if (bankaccArr && bankaccSel) {
    //   bankaccName = bankaccArr.find(item => item.value === bankaccSel).text
    // } else {
    //   bankaccName = '無資料'
    // }

    if (!year || !month) {
      return;
    }

    if (newData.showFlag === 0) {
      let obj = {
        ...newData,
        preBalanceIn: newData.income || '0',
        preBalanceOut: newData.outcome || '0',
      }
      await Parse.saveData('balance', obj);
    } else {
      const obj = { ...newData };
      await Parse.saveData('predict', obj);
    }
    this.handleCalculate()
  }

  handleDelete = async () => {
    const { oldData, monthSel, bankaccSel } = this.state
    const [year, month] = monthSel.split('-');
    if (!year || !month) {
      return;
    }
    if (oldData.showFlag === 0) {
      this.setState({ loading: false })
      return console.log("OO");
    } else {
      await Parse.deleteData('predict', oldData);
      this.handleCalculate()
      this.setState({ oldData: {} })
    }
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    this.setState({ [name]: value, loading: true }, () => this.handleFetch(this.props, 0));
  }

  render() {
    const { companyObj } = this.props
    const { dataArr, loading, bankaccArr, bankaccSel, monthSel, predictArr } = this.state;
    const [year, month] = monthSel.split('-') || ['', ''];
    let bankaccName = ''
    bankaccArr && bankaccSel ? bankaccName = bankaccArr.find(item => item.value === bankaccSel).text : bankaccName = '無資料'

    const editable = {
      onRowAdd: newData =>
        new Promise(async (resolve, reject) => {
          const db = Parse.Object.extend("predict");
          const newDoc = new db();
          const docRef = await newDoc.save();
          const data = [...predictArr];
          const obj = {
            _id: docRef.id,
            id: docRef.id,
            objectId: docRef.id,
            companyId: companyObj.objectId,
            companyName: companyObj.name,
            bankaccId: bankaccSel,
            bankaccName,
            year,
            month,
            monthSel,
            ...newData
          }
          data.pop();
          data.push(obj);
          this.setState({ predictArr: data, newData: obj, loading: true }, () => resolve());
        }).then(() => this.handleAdd()),
      onRowUpdate: (newData, oldData) =>
        new Promise(async (resolve, reject) => {
          let obj = {}
          if (newData.objectId && newData.showFlag === 0) {
            obj = {
              ...newData
            }
          } else if (newData.showFlag === 0) {
            const db = Parse.Object.extend("balance");
            const newDoc = new db();
            const docRef = await newDoc.save();
            obj = {
              ...newData,
              id: docRef.id,
              _id: docRef.id,
              objectId: docRef.id,
              companyId: companyObj.objectId || '',
              companyName: companyObj.name || '',
              bankaccId: bankaccSel || '',
              bankaccName,
              year,
              month,
              monthSel
            }
          } else {
            obj = {
              ...newData
            }
          }
          const data = [...predictArr];
          const index = data.indexOf(oldData);
          data[index] = obj;
          data.pop();
          this.setState({ predictArr: data, newData: obj, oldData, loading: true }, () => resolve());
        }).then(() => this.handleUpdate()),
      onRowDelete: oldData =>
        new Promise((resolve, reject) => {
          let data = [...predictArr];
          const index = data.indexOf(oldData);
          data.splice(index, 1);
          data.pop();
          this.setState({ predictArr: data, oldData, loading: true }, () => resolve());
        }).then(() => this.handleDelete()),
    }


    return (<>
      {/* 資金需求預估表 */}
      <div className="content">
        <br />
        <Input
          type="month"
          name="monthSel"
          value={monthSel}
          onChange={this.handleChange}
        />

        {/* <Select style={{
          position: 'relative', zIndex: 99,
          float: 'right',
          minWidth: '200px',
        }}
          compact
          name="bankaccSel"
          options={bankaccArr}
          value={bankaccSel}
          onChange={this.handleChange}
        /> */}
        <br />
        <br />
        {!year ? null : <>
          <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns1()}
            options={{
              addRowPosition: "first",
              exportButton: true,
              exportPdf: (columns, data) => exportPdf(columns, data, `薪資計算表`),
              pageSize: predictArr.length || 10,
              search: false
            }}
            data={dataArr}
            title={`薪資計算表`}
            editable={editable}
          />
        </>}
        <br />
        <br />
      </div>
    </>)
  }
}