import React, { Component } from 'react';
import { Card, Container, Grid, Header, Divider } from 'semantic-ui-react';
import { Row, Col } from 'reactstrap';

import UploadPhoto from '../../../widgets/UploadPhoto';
import { showField, showFieldText, getAvailblePeriodArr, getDate } from 'views/widgets/FieldsRender';

export default class InterviewStudent extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      // inputValue: null,
      // searchRes: [],
      show: false,
      doc: props.doc,
      webcamImg: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ doc: nextProps.doc });
  }

  render() {
    return (<>
      <Header as='h2'
        style={{ backgroundColor: 'lavender' }}
        content='面接記録' /* subheader='調査時間：2017年4月から 2018年12月 まで'*/ />
      <Divider />
      {/* <Grid columns='equal' divided> */}
      {/* <Col> */}
      {/* <Row> */}
      {/* <Col> */}
      <Row>
        {/* <Grid columns='equal'> */}
        <Col>
          <Card color='brown' fluid>
            <Card.Content header='基本情報' />
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "interviewName")}</Col>
                <Col>{showField(this.props, "interviewNational")}</Col>
                <Col>{showField(this.props, "interviewYearsold")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "interviewBirthday")}</Col>
                <Col>{showField(this.props, "interviewGender")}</Col>
                <Col>{showField(this.props, "interviewBornplace")}</Col>
                <Col>{showField(this.props, "interviewDate")}</Col>
                {/* </Grid> */}
              </Row >
            </Card.Content>
          </Card>
        </Col>
        {/* </Grid> */}
      </Row>
      {/* </Col> */}
      {/* </Row> */}
      <br />
      <Row>
        {/* <Grid columns='equal'> */}
        <Col>
          <Card color='brown' fluid>
            <Card.Content header='学経歴' />
            <Card.Content>
              <Row >
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "interviewEducation")}</Col>
                <Col>{showField(this.props, "interviewEducationOther")}</Col>
                <Col>{showField(this.props, "interviewWorkExperience")}</Col>
                {/* </Grid> */}
              </Row>
              <Row >
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "interviewWorkExperience1")}</Col>
                <Col>{showField(this.props, "interviewWorkExperience2")}</Col>
                <Col>{showField(this.props, "interviewWorkExperience3")}</Col>
                {/* </Grid> */}
              </Row>
              <Row >
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "interviewStudyPurpose")}</Col>
                <Col>{showField(this.props, "interviewStudyPurpose1")}</Col>
                <Col>{showField(this.props, "interviewSchoolCertificate")}</Col>
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
        </Col>
        {/* </Grid> */}
      </Row>
      <br />
      {/* </Col> */}
      {/* <Col> */}
      <Row>
        {/* <Grid columns='equal'> */}
        <Col>
          <Card color='brown' fluid>
            <Card.Content header='経費支弁、推薦者' />
            <Card.Content>
              <Row >
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "interviewSupportInterview")}</Col>
                <Col>{showField(this.props, "interviewSupportInterview1")}</Col>
                {/* </Grid> */}
              </Row>
              <Row columns='equal' >
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "interviewRecommendInterview")}</Col>
                <Col>{showField(this.props, "interviewRecommendInterview1")}</Col>
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
        </Col>
        {/* </Grid> */}
      </Row>
      <br />
      <Row>
        {/* <Grid columns='equal'> */}
        <Col>
          <Card color='brown' fluid>
            <Card.Content header='語言能力' />
            <Card.Content>
              <Row >
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "interviewJpnAbility")}</Col>
                <Col>{showField(this.props, "interviewJpnAbility1")}</Col>
                {/* </Grid> */}
              </Row>
              <Row >
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "interviewJpnAbilityInterview")}</Col>
                <Col>{showField(this.props, "interviewJpnAbilityInterview1")}</Col>
                {/* </Grid> */}
              </Row>
              <Row >
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "interviewEngAbilityInterview")}</Col>
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
        </Col>
        {/* </Grid> */}
      </Row>
      <br />
      {/* </Col> */}
      {/* <Col> */}
      <Row>
        {/* <Grid columns='equal'> */}
        <Col>
          <Card color='brown' fluid>
            <Card.Content header='注意必要な問題' />
            <Card.Content>
              <Row >
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "interviewNote1")}</Col>
                <Col>{showField(this.props, "interviewNote2")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "interviewNote3")}</Col>
                <Col>{showField(this.props, "interviewNote4")}</Col>
                <Col>{showField(this.props, "interviewNote5")}</Col>
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
        </Col>
        {/* </Grid> */}
      </Row>
      <br />
      <Row>
        {/* <Grid columns='equal'> */}
        <Col>
          <Card color='brown' fluid>
            <Card.Content header='外見' />
            <Card.Content>
              <Row >
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "interviewAppearance")}</Col>
                <Col>{showField(this.props, "interviewAttitude")}</Col>
                <Col>{showField(this.props, "interviewAttitude1")}</Col>
                <Col>{showField(this.props, "interviewPersonality")}</Col>
                {/* </Grid> */}
              </Row>
              <Row >
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "interviewPersonality1")}</Col>
                <Col>{showField(this.props, "interviewEnterPurpose")}</Col>
                <Col>{showField(this.props, "interviewEnterPurpose1")}</Col>
                {/* </Grid> */}
              </Row>
              <Row >
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "interviewInterested")}</Col>
                <Col>{showField(this.props, "interviewHobbies")}</Col>
                <Col>{showField(this.props, "interviewHobbies1")}</Col>
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
        </Col>
        {/* </Grid> */}
      </Row>
      {/* </Col> */}
      {/* </Grid> */}
    </>);
  }
}

