import React, { Component } from 'react';
import { Table, Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';

import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import { getOptionsSelByKey } from 'views/widgets/FieldsRender';
import Parse from '../../../widget/parse'

const style = {
  tableShow: {
    display: 'table',
  },
  tableHide: {
    display: 'none',
  }
}

export default class DormContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrRow: [],
      loading: true,
      classSchool: 'A',
      bikeData: [],
      parttimeData: [],
      students: []
    };
  }

  componentDidMount = async () => {
    // console.log('componentDidMount');
    const { companyObj } = this.props
    const data = await Parse.queryData('Students', { companyId: companyObj.objectId });
    this.setState({ students: data }, () => this.handleFetch());
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = (props = this.props) => {
    const { firebase, match } = props
    const { classSchool, students } = this.state
    const tmp = students.filter(item =>
      (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
      (item.applyResult === '1' || item.studentType === "2"));
    const arrRow = tmp;//.filter(item => item.classSchool === classSchool); // 找到這班的所有人
    this.setState({ arrRow, loading: false });
  }

  handleChange = (event, { value }) => this.setState({ classSchool: value }, () => this.handleFetch())

  render() {
    const { history, match, optSet, companyObj } = this.props;
    const { arrRow, loading, classSchool, bikeData, parttimeData, graduationData } = this.state
    const classSchoolOpt = getOptionsSelByKey(optSet, "classSchool");
    // console.log(parttimeData)
    return (<div ref={el => (this.componentRef = el)} className="">
      <h2 style={{ margin: 0, float: 'left' }}>学生住所一覧</h2>
      <div style={{ float: 'right' }}>
        {/* <ReactToPrint
          trigger={(e) => <Button
            content='印刷'
            // label='&nbsp;'
            color='orange' icon='print'
            size='small'
            floated='right'
            className='no-print'
          />}
          content={() => this.componentRef}
          pageStyle={"@page { size: auto;  margin:5mm; }*{font-family: 'Noto Serif JP', serif;}"}
        /> */}
        <ModalPdf
          {...this.props}
          // ref={(ref) => { this.pdfModal = ref }}
          codekey="table38"
          params={arrRow}
        />
        <ExportTableToExcelButton floated='right' size='small' />
        {/* <Input labelPosition='left' style={{ marginRight: 20 }}>
          <Label color='violet'><Icon name='building' />クラス</Label>
          <Form.Select
            style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
            name='classroom'
            value={classSchool}
            options={classSchoolOpt}
            onChange={this.handleChange} />
        </Input> */}
      </div>
      <div style={{ clear: 'both' }}></div>
      <Table celled striped compact
        className="thPadding2px tdPadding2px" id='excel'>
        <Table.Header >
          <Table.Row textAlign='center'>
            <Table.HeaderCell style={{ width: '80px' }}  >学籍番号</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '80px' }}  >クラス</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '100px' }} >氏名</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '150px' }} >電話番号</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '150px' }} >携帯電話番号</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '300px' }} >連絡先</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {arrRow.length ? arrRow.map((item, index) => {
            return <React.Fragment key={index}>
              <Table.Row textAlign='center'>
                <Table.Cell>{item.studentId}</Table.Cell>
                <Table.Cell>{item.classSchool || ''}</Table.Cell>
                <Table.Cell>{item.jpnName}</Table.Cell>
                <Table.Cell>{item.telephone || item.homeTel || ''}</Table.Cell>
                <Table.Cell>{item.cellphone || item.jpnCellphone || ''}</Table.Cell>
                <Table.Cell>{item.jpnAddr || ''}</Table.Cell>
              </Table.Row>
            </React.Fragment>
          }) : <Table.Row><Table.Cell colSpan='6' textAlign='center'>(資料無し)</Table.Cell></Table.Row>}
        </Table.Body>
      </Table>
      {loading ? <div style={{ justifyContent: 'center', margin: '30px 0' }}>
        <Segment>
          <Dimmer active inverted={companyObj.inverted}>
            <Loader inverted={companyObj.inverted}>Loading</Loader>
          </Dimmer>
          <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        </Segment>
      </div> : null}
    </div>);
  }
}