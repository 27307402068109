import React, { Component } from 'react'
// import { Container, Icon, Image, Grid } from 'semantic-ui-react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Loader, Icon } from 'semantic-ui-react'
import {
  FormGroup,
  Label,
  Input,
  // Row,
  // Col
} from 'reactstrap';

import Parse from 'widget/parse'
// import localization from 'widget/MaterialTableOpt'
// import PreloadFile from './PreloadFile';

class ModalMultiInput extends Component {
  state = {
    loading: false,
    show: false,
    dataArr: [],
  }


  handleInput = (e) => {
    this.setState({ str: e.target.value })
  }


  handleSave = async () => {
    const { str } = this.state
    const { condition = {}, dbName, columns } = this.props
    if (window.confirm('確認添加?')) {
      const promises = [];
      const inputArr = str.split('\n')
      const noSpace = inputArr.filter(item => item !== '')
      const inputObjArr = noSpace.map(item => {
        const objArr = item.split(',')
        let obj = {}
        let count = 0
        columns.forEach(item2 => {
          if (item2.editComponent === undefined && item2.lookup === undefined) {
            obj = {
              ...obj,
              [item2.field]: objArr[count],
            }
            count += 1
          }
        })
        return obj
      })
      inputObjArr.forEach(item => {
        const obj = {
          ...item,
          ...condition,
        }
        // Parse.saveData(dbName, obj);
        promises.push(Parse.saveData(dbName, obj));
      })
      await Promise.all(promises).catch(error => console.log('error', error))
      this.setState({ loading: false, show: false, str: '' }, () => this.props.handleData())
    }
  }

  handleOpen = () => {
    this.setState({ show: true, });
  }

  handleClose = () => {
    this.setState({
      dataArr: [],
      show: false,
    });
  }


  render() {
    // const { rowData = {}, isImage, fileOpt = [], } = this.props;
    // const { loading, show, fileOptId, files, show2 } = this.state;
    const { columns } = this.props;
    const { loading, show, str = '' } = this.state;
    return (<>
      {/* <Button color='primary' onClick={this.handleOpen}><i className="fa fa-plus" /> 快速添加  </Button> */}
      <Icon link name='list' onClick={this.handleOpen} />
      <Modal
        isOpen={show}
        toggle={this.handleClose}
        size="lg"
      // className="mh-90 h-90 mw-100 w-90"
      // style={{ paddingTop: '0px' }}
      // style={{ maxWidth: '1600px', width: '80%' }}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
            <span aria-hidden="true">×</span>
          </button>
              多數添加
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            {columns.map(item => item.editComponent === undefined && item.lookup === undefined ? <Label for="class">{`${item.title},`}</Label> : null)}
            <Input type="textarea" name="text" id="class" onChange={this.handleInput} />
          </FormGroup>
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button color='success' onClick={this.handleSave} disabled={!str.length}>
            {!loading ? <><i className="fa fa-upload" />  上傳</> : <Loader active inverted inline size='small' />}</Button>{' '}
          <Button icon='x' onClick={this.handleClose}>關閉</Button>
        </ModalFooter>
      </Modal>

    </>);
  }
}

export default ModalMultiInput;