import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

// import RowsDnd from './RowsDnd'

import { exportPdf } from 'views/web/CustomMaterialTable';
// reactstrap components
import { Row, Col, Form, FormGroup, Input, Label, Button } from "reactstrap";
// import CurStudentInfo from "./CurStudentInfo";

const columns = () => ([
  { width: 80, title: '日期', field: 'date' },
  { width: 400, title: '題目', field: 'test' },
  { width: 80, title: '類型', field: 'type' },
]);

class TestBankDesign extends Component {
  state = {
    activeItem: 'bio',
    dataArr: [],
    rowData: {},
    loading: true
  }

  async componentDidMount() {
    const data1 = await Parse.queryData('testBank');
    this.setState({ dataArr: data1, loading: false });
  }

  handleAdd = async () => {
    const { newData } = this.state;
    Parse.saveData('testBank', newData);
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    Parse.saveData('testBank', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    Parse.deleteData('testBank', oldData);
    this.setState({ oldData: {} })
  }


  handleItemClick = (e, { name }) =>
    this.setState({
      activeItem: name
    })

  handleData = (rowData) => {
    this.setState({ rowData });
  }

  render() {

    const { dataArr, rowData, loading } = this.state
    return (<div className="content">
      <Row>
        <Col><legend>1-1 題庫設計</legend></Col>
      </Row>
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '設定'),
          pageSize: 10,
          search: false,
          tableLayout: 'fixed'
        }}
        data={dataArr}
        title="設定"
        // actions={[
        //   {
        //     icon: 'view_headline',
        //     tooltip: '查看',
        //     onClick: (event, rowData) => this.handleData(rowData)
        //   }
        // ]}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const data = [...dataArr];
              const db = Parse.Object.extend("testBank");
              const newDoc = new db();
              const doc = await newDoc.save();
              newData.objectId = doc.id;
              newData.id = doc.id;
              newData._id = doc.id;

              data.push(newData);
              this.setState({ dataArr: data, newData }, () => resolve());
            }).then(() => this.handleAdd()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...dataArr];
              const index = data.indexOf(oldData);
              data[index] = newData;
              // handleState('people0', data);
              this.setState({ dataArr: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...dataArr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              // handleState('people0', data);
              this.setState({ dataArr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
      <br />
      <br />
    </div>);
  }
}

export default TestBankDesign;