import React, { Component } from 'react'
import { Table, Header, Label, Segment, Tab, Select, Form, Checkbox } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import Moment from 'moment';
import {
  Button,
  ButtonGroup,
  // Label,
  FormGroup,
  Row,
  Col,
  UncontrolledTooltip,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, mgyearOpt, monthOpt, exportPdf } from 'views/web/CustomMaterialTable';
import { useAsyncDebounce } from 'react-table';
import ModalNewMember from './ModalNewMember';
import QRCode from 'qrcode.react'

const typeOpt = [
  { text: '本日訂單', value: 'today', key: '1' },
  { text: '全部訂單', value: 'all', key: '2' },
]

const d = new Date()
const tolerance_time = 10;
const payWayOpt = { '1': '一小時', '2': '二小時', '3': '三小時', '4': '四小時', '5': '包日' }
class CrewService extends Component {
  state = {
    activeItem: 'bio',
    dataArr: [],
    typeSel: 'today',
    loading: true,
    isOpen: false,
    stop: false,
    allStop: false,
    newPeople: false,
    ordering1Obj: {},
    rowData: {},
    meal1Data: {},
    meals1Arr: [],
    meals2Arr: [],
    orderArr: [],
    peopleArr: [],
    checkedValue: [],
    rowDataArr: [],
    paidArr: []
  }
  componentDidMount = () => {
    this.handleFetch()
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, match } = props
    const { ordering1Id } = match.params;
    // console.log(ordering1Id)
    const companyId = companyObj.objectId
    const ordering1Obj = await Parse.fetchDocument('ordering1', ordering1Id);
    const dataArr = await Parse.queryData('ordering2', { companyId, ordering1Id: ordering1Id, isDrink: '0', isOpen: '1' });
    const paidArr = await Parse.queryData('ordering2', { companyId, ordering1Id: ordering1Id, isDrink: '0', isOpen: '0' });
    const orderArr = await Parse.queryData('ordering2', { companyId, ordering1Id: ordering1Id, isDrink: '1' });
    const meals1Arr = await Parse.queryData('meals1', { companyId });
    // console.log(ordering1Obj)
    this.setState({ ordering1Obj, meals1Arr, dataArr, loading: false, orderArr, paidArr })
    if (dataArr.length) {
      this.func()
    } else {
      this.setState({ allStop: true })
    }
  }

  func = async () => {
    const { companyObj, match } = this.props
    const { ordering1Id } = match.params;
    const { stop, allStop } = this.state
    // const { orderArr } = this.state
    // console.log(ordering1Id)
    const companyId = companyObj.objectId
    const dataArr = await Parse.queryData('ordering2', { companyId, ordering1Id: ordering1Id, isDrink: '0', isOpen: '1' });
    const d1 = new Date((dataArr && dataArr.openTime && dataArr[0].openTime.iso) || '')
    const d2 = new Date((dataArr && dataArr.currentTime && dataArr[0].currentTime.iso) || '')
    // console.log(d1)
    // console.log(d2)
    // console.log(parseInt(d2 - d1) / 1000 / 60);
    const mins = parseInt(d2 - d1) / 1000 / 60
    // let money = 0;
    // if (mins < 0) {
    //   money = 0;
    // }
    // if (mins <= 60 + tolerance_time) {
    //   money = 50;
    // } else if (mins <= 120 + tolerance_time) { // 2 hr
    //   money = 100;
    // } else if (mins <= 180 + tolerance_time) { // 3 hr
    //   money = 140;
    // } else if (mins <= 240 + tolerance_time) { // 4 hr
    //   money = 180;
    // } else {
    //   money = 230;
    // }
    this.setState({ dataArr, mins: mins.toFixed(0) })

    if (stop) {
      console.log('stop')
    } else {
      const promises = [];
      dataArr.forEach(item => {

        promises.push(Parse.saveData('ordering2', {
          ...item,
          currentTime: new Date(),
          playedMins: Number(mins.toFixed(0)),
          // money
        }))
      })

      await Promise.all(promises).catch(error => console.log("error", error));
      setTimeout(this.func, 60000);
    }
  };

  handleMeals = async (item, type) => {
    const { companyObj, match } = this.props
    const { ordering1Id } = match.params;
    const companyId = companyObj.objectId
    const { orderArr, ordering1Obj, rowDataArr, checkedValue } = this.state
    const index = orderArr.indexOf(item)
    const number = rowDataArr.length
    if (type === '1') {
      rowDataArr.forEach(async (item2) => {
        const db = Parse.Object.extend("ordering2");
        const newDoc = new db();
        const doc = await newDoc.save();

        const obj = {
          ...item,
          objectId: doc.id,
          id: doc.id,
          _id: doc.id,
          ordering2Id: item2.objectId,
          ordering1Id: ordering1Obj.objectId,
          companyId: ordering1Obj.companyId,
          companyName: ordering1Obj.companyName,
          tableName: ordering1Obj.tableName,
          orderNum: ordering1Obj.orderNum,
          openChair: ordering1Obj.openChair,
          openPeople: ordering1Obj.openPeople,
          isDrink: '1',
          isOpen: '1'
        };
        orderArr.push(obj)
        await Parse.saveData('ordering2', obj);
        await Parse.saveData('ordering1', { ...ordering1Obj, totalDrinkPrice: Number(ordering1Obj.totalDrinkPrice || 0) + (Number(item.price) * Number(number) || 0) });

        const indexof = checkedValue.indexOf(item2.objectId);
        checkedValue.splice(indexof, 1)
        rowDataArr.splice(indexof, 1)
        this.setState({ orderArr, checkedValue, rowDataArr })
      })
      // const orderArr = await Parse.queryData('ordering2', { companyId, ordering1Id: ordering1Id, isDrink: '1' });
      this.setState({ ordering1Obj: { ...ordering1Obj, totalDrinkPrice: Number(ordering1Obj.totalDrinkPrice || 0) + (Number(item.price) * Number(number) || 0) } })
    } else {
      orderArr.splice(index, 1)
      await Parse.deleteData('ordering2', item);
      await Parse.saveData('ordering1', { ...ordering1Obj, totalDrinkPrice: Number(ordering1Obj.totalDrinkPrice || 0) - Number(item.price || 0) });
      this.setState({ orderArr, ordering1Obj: { ...ordering1Obj, totalDrinkPrice: Number(ordering1Obj.totalDrinkPrice || 0) - Number(item.price || 0) } })
    }
  }

  handleMember = async (type) => {
    const { checkedValue, dataArr, ordering1Obj } = this.state
    const data = [...dataArr];

    let totalTablePrice = ordering1Obj.totalTablePrice
    if (type === '1') {
      checkedValue.forEach(async (item) => {
        const oldData = dataArr.find(item2 => item2.objectId === item)
        // console.log(oldData);
        if (oldData.countMethod !== '會員價') {
          let money = 0
          if (oldData.payWay === '1') {
            money = 40
            totalTablePrice = totalTablePrice - 10
          } else if (oldData.payWay === '2') {
            money = 80
            totalTablePrice = totalTablePrice - 20
          } else if (oldData.payWay === '3') {
            money = 110
            totalTablePrice = totalTablePrice - 30
          } else if (oldData.payWay === '4') {
            money = 150
            totalTablePrice = totalTablePrice - 30
          } else if (oldData.payWay === '5') {
            money = 180
            totalTablePrice = totalTablePrice - 50
          }
          const index = data.indexOf(oldData);
          data[index] = { ...oldData, countMethod: '會員價', price: String(money) };
          await Parse.saveData('ordering2', { ...oldData, countMethod: '會員價', price: String(money) });
        }
      })
    } else {
      checkedValue.forEach(async (item) => {
        const oldData = dataArr.find(item2 => item2.objectId === item)
        if (oldData.countMethod !== '非會員價') {
          let money = 0
          if (oldData.payWay === '1') {
            money = 50
            totalTablePrice = totalTablePrice + 10
          } else if (oldData.payWay === '2') {
            money = 100
            totalTablePrice = totalTablePrice + 20
          } else if (oldData.payWay === '3') {
            money = 140
            totalTablePrice = totalTablePrice + 30
          } else if (oldData.payWay === '4') {
            money = 180
            totalTablePrice = totalTablePrice + 30
          } else if (oldData.payWay === '5') {
            money = 230
            totalTablePrice = totalTablePrice + 50
          }
          const index = data.indexOf(oldData);
          data[index] = { ...oldData, countMethod: '非會員價', price: String(money) };
          await Parse.saveData('ordering2', { ...oldData, countMethod: '非會員價', price: String(money) });
        }
      })
    }
    await Parse.saveData('ordering1', { ...ordering1Obj, totalTablePrice });
    this.setState({ dataArr: data, ordering1Obj: { ...ordering1Obj, totalTablePrice } })
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  handleRow = (item) => {
    const { checkedValue, rowDataArr } = this.state;
    const value = item.objectId
    const indexof = checkedValue.indexOf(value);
    if (checkedValue.indexOf(value) === -1) {
      checkedValue.push(value);
      rowDataArr.push(item)
    } else {
      checkedValue.splice(indexof, 1)
      rowDataArr.splice(indexof, 1)
    }
    this.setState({ checkedValue, rowDataArr })
  }

  isOpen = async (type) => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const { checkedValue, dataArr, orderArr, ordering1Obj } = this.state
    this.setState({ openType: type, isOpen: true })
    if (type === '1') {
      let drinkTotal = 0;
      let foodTotal = 0;
      let tableTotal = 0;
      let total = 0;
      for (let i = 0; i < checkedValue.length; i++) {
        const tableObj = dataArr.find(item => item.objectId === checkedValue[i]) || {}
        tableTotal += Number(tableObj.price || 0)
        const drinkObj = orderArr.find(item => item.ordering2Id === checkedValue[i]) || {}
        drinkTotal += Number(drinkObj.price || 0)
      }
      total = tableTotal + drinkTotal
      this.setState({ tableTotal, drinkTotal, total });
    } else {
      let drinkTotal = 0;
      let foodTotal = 0;
      let tableTotal = 0;
      let total = 0;
      tableTotal = (ordering1Obj.totalTablePrice || 0) - (ordering1Obj.receivedTablePrice || 0)
      drinkTotal = (ordering1Obj.totalDrinkPrice || 0) - (ordering1Obj.receivedDrinkPrice || 0)
      this.setState({ tableTotal, drinkTotal, total: drinkTotal + tableTotal, openType: type });
    }
  }

  isClose = () => {
    this.setState({ isOpen: false, stop: false, newPeople: false });
  }

  stop = () => { this.setState({ stop: true }) }

  allStop = async () => {
    const { companyObj, match } = this.props
    const companyId = companyObj.objectId
    const { ordering1Id } = match.params;
    const { dataArr } = this.state
    console.log(dataArr);
    const promises = []
    const data = [...dataArr]
    dataArr.forEach(async (item) => {
      promises.push(Parse.saveData('ordering2', { ...item, closeTime: new Date(), isOpen: '0' }))
      const indexof = data.indexOf(item);
      data.splice(indexof, 1)
    })
    await Promise.all(promises).catch(error => console.log("error", error));
    const paidArr = await Parse.queryData('ordering2', { companyId, ordering1Id: ordering1Id, isDrink: '0', isOpen: '0' });
    this.setState({ dataArr: data, paidArr, stop: false, allStop: true })
  }

  handleleave = async (openType) => {
    const { companyObj, match, history } = this.props
    const companyId = companyObj.objectId
    const { ordering1Id } = match.params;
    const { receivedTotalPrice, tableTotal, drinkTotal, total, ordering1Obj, rowDataArr, dataArr } = this.state
    if (openType === '1') {
      //ordering1
      const obj = {
        ...ordering1Obj,
        receivedTablePrice: (ordering1Obj.receivedTablePrice || 0) + tableTotal,
        receivedDrinkPrice: (ordering1Obj.receivedDrinkPrice || 0) + drinkTotal,
        receivedFoodPrice: (ordering1Obj.receivedFoodPrice || 0) + (Number(receivedTotalPrice) - tableTotal - drinkTotal),
        receivedTotalPrice: (ordering1Obj.receivedTotalPrice || 0) + Number(receivedTotalPrice),
      }
      await Parse.saveData('ordering1', obj);

      //ordering2
      const promises = []
      rowDataArr.forEach(async (item) => {
        promises.push(Parse.saveData('ordering2', { ...item, closeTime: new Date(), isOpen: '0' }))
        const indexof = dataArr.indexOf(item);
        dataArr.splice(indexof, 1)
      })
      await Promise.all(promises).catch(error => console.log("error", error));

      const paidArr = await Parse.queryData('ordering2', { companyId, ordering1Id: ordering1Id, isDrink: '0', isOpen: '0' });
      this.setState({ dataArr, isOpen: false, ordering1Obj: obj, paidArr })
    } else {
      await Parse.saveData('ordering1', { ...ordering1Obj, isOpen: '0' }).then(history.push(`/admin/CrewOpentable`));
    }
  }

  handleChangeMeals = async (meal1Data) => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const meals2Arr = await Parse.queryData('meals2', { companyId, meals1Id: meal1Data.objectId });
    this.setState({ meals2Arr, meal1Data })
  }

  newPeople = () => { this.setState({ newPeople: true }) }

  addPeople = async () => {
    const { ordering1Obj, dataArr } = this.state
    const db = Parse.Object.extend("ordering2");
    const newDoc = new db();
    const doc = await newDoc.save();

    const obj = {
      objectId: doc.id,
      id: doc.id,
      _id: doc.id,
      ordering2Id: doc.id,
      ordering1Id: ordering1Obj.objectId,
      companyId: ordering1Obj.companyId,
      companyName: ordering1Obj.companyName,
      tableName: ordering1Obj.tableName,
      orderNum: ordering1Obj.orderNum,
      openPeople: ordering1Obj.openPeople,
      isDrink: '0',
      isOpen: '1',
      isFood: '0',
      price: String(ordering1Obj.money),
      countMethod: "非會員價",
      payWay: ordering1Obj.payWay,
      openTime: new Date(),
      currentTime: new Date(),
      payWayText: payWayOpt[ordering1Obj.payWay],
    }
    const obj2 = {
      ...ordering1Obj,
      openChair: String(Number(ordering1Obj.openChair) + 1),
      totalTablePrice: ordering1Obj.totalTablePrice + Number(obj.price)
    }
    dataArr.push(obj)
    await Parse.saveData('ordering2', obj);
    await Parse.saveData('ordering1', obj2);

    this.setState({ dataArr, ordering1Obj: obj2, newPeople: false }, () => this.func())
  }
  handleBack = () => {
    const { history } = this.props
    history.push('/admin/CrewTablestatus')
  }
  render() {
    const { companyObj } = this.props
    const { dataArr, orderArr, meals2Arr, ordering1Obj, checkedValue, mins, stop, isOpen, paidArr, rowDataArr,
      receivedTotalPrice, tableTotal, drinkTotal, total, openType, allStop, meals1Arr, meal1Data, newPeople } = this.state
    const rowData = rowDataArr[0] || {}

    return (<>
      <div className="content">
        <Header as='h2' className='crewServiceHeader' inverted={companyObj.inverted}>單號{ordering1Obj.orderNum}   桌號{ordering1Obj.tableName}   人數{ordering1Obj.openChair}位   目前金額{(ordering1Obj.totalTablePrice || 0) + (ordering1Obj.totalDrinkPrice || 0) + (ordering1Obj.totalFoodPrice || 0) - (ordering1Obj.receivedTotalPrice || 0)}元   開桌人員{ordering1Obj.openPeople} </Header>

        {/* <ModalNewMember  {...this.props} /> */}
        <Button color='info' onClick={this.newPeople}>新增來賓</Button>
        <Button className='backButton' style={{ marginLeft: '80%', fontSize: '18px', backgroundColor: '#750000' }} size='lg' onClick={this.handleBack}>返回</Button>
        {/* <Button color='success'>載入會員卡</Button>
        <Button color='success'>本單轉至空桌</Button> */}
        <br />
        <hr />
        <br />
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>開始時間</Table.HeaderCell>
              <Table.HeaderCell>遊玩時間</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {dataArr.map((item, index) => <>
              <Table.Row active key={index} style={{ cursor: 'pointer' }} onClick={() => this.handleRow(item)}>
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell>
                  {/* <Checkbox onClick={() => this.handleCheckbox(item)} /> */}
                  <Checkbox
                    label=''
                    value={item.objectId}
                    checked={checkedValue.indexOf(item.objectId) !== -1}
                  // disabled={suirChecked}
                  // onClick={() => this.handleRow(item)}
                  />
                </Table.Cell>
                <Table.Cell>{(item.openTime && Moment(item.openTime.iso).format('HH:mm:ss')) || ''}</Table.Cell>
                <Table.Cell>{item.playedMins || ''}分鐘</Table.Cell>
              </Table.Row>
              {orderArr.find(item2 => item2.ordering2Id === item.objectId) ? <>
                {orderArr.filter(item2 => item2.ordering2Id === item.objectId).map((item, index) =>
                  <Table.Row key={index}>
                    <Table.Cell>
                      <Button color='danger' onClick={() => this.handleMeals(item, '2')}>刪除</Button>
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>{item.commodity}</Table.Cell>
                    <Table.Cell>{item.price}</Table.Cell>
                  </Table.Row>
                )}
              </> : null}
            </>)}
          </Table.Body>
        </Table >
        {/* <Button color='success' >不玩桌遊</Button> */}
        {/* <Button color='success' >取消不玩桌遊</Button> */}
        {/* <Button color='success' >使用熟客卡半價</Button> */}
        {/* <Button color='success' >取消熟客卡半價</Button> */}
        {rowData && rowData.objectId ? <>
          {/* <Button color='success' onClick={() => this.handleMember('1')}>使用會員卡</Button> */}
          {/* <Button color='danger' onClick={() => this.handleMember('2')}>取消使用會員卡</Button> */}
          {/* <Button color='success' >強制使用會員價</Button>
        <Button color='success' >取消會員價</Button>
        <Button color='success' >已點飲料價</Button>
        <Button color='success' >沒點飲料價</Button>
        <Button color='success' >使用折扣</Button> */}
          {/* <Button color='info' onClick={() => this.isOpen('1')}>提前離場</Button> */}
          {/* <Button color='success' >刪除</Button> */}
          <br />
          <br />
          {meals1Arr.map((item, index) => <>
            <Button key={index} style={{ color: '#000' }} size='lg' color='warning' onClick={() => this.handleChangeMeals(item)}>{item.value}</Button>
          </>)}
          <br />
          {meal1Data && meal1Data.objectId ? <>
            {meals2Arr.map((item, index) => <>
              {index !== 0 && (meals2Arr[index].meals1Id === meals2Arr[index - 1].meals1Id) ? <></> : <br />}
              <Button key={index} color='success' onClick={() => this.handleMeals(item, '1')}>{item.commodity}</Button>
            </>)}
          </> : null}
        </> : null}
        {/* <hr />
        <Row>
          <Col sm='4'></Col>
          <Col sm='4'>
            <QRCode value="http://localhost:4500/admin/CrewTablestatus" style={{ width: '256px', height: '256px' }} />
            <br />
            <Label as='a' href='http://localhost:4500/admin/CrewTablestatus' style={{ marginLeft: '72px' }}>本頁QR Code</Label>
          </Col>
          <Col sm='4'></Col>
        </Row> */}
        {/* 
        <br />
        <h3>餐點</h3> */}
        {/* {meals2Arr.map((item, index) =>
          <Button key={index} color='success' onClick={() => this.handleMeals(item, '1')}>{item.commodity}</Button>
        )} */}
        {/* <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>餐點</th>
              <th>價格</th>
              <th>狀態</th>
            </tr>
          </thead>
          <tbody>
            {orderArr.map((item, index) =>
              <tr key={index}>
                <td>
                  <Button color='danger' onClick={() => this.handleMeals(item, '2')}>刪除</Button>
                   <Button color='success' >取消熟客卡半價</Button> 
               <Button color='success' >使用熟客卡半價</Button> 
                </td>
                <td>{item.commodity}</td>
                <td>{item.price}</td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table> */}
        {/* <br />
        <hr />
        <br />
        <h3>已結帳清單</h3>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>開始時間</Table.HeaderCell>
              <Table.HeaderCell>結束時間</Table.HeaderCell>
              <Table.HeaderCell>遊玩時間</Table.HeaderCell>
              <Table.HeaderCell>選擇方案</Table.HeaderCell>
              <Table.HeaderCell>計價方式</Table.HeaderCell>
              <Table.HeaderCell>價格</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {paidArr.map((item, index) => <>
              <Table.Row active key={index} style={{ cursor: 'pointer' }} onClick={() => this.handleRow(item)}>
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell>{(item.openTime && Moment(item.openTime.iso).format('YYYY-MM-DD  HH:mm:ss')) || ''}</Table.Cell>
                <Table.Cell>{(item.closeTime && Moment(item.closeTime.iso).format('YYYY-MM-DD  HH:mm:ss')) || ''}</Table.Cell>
                <Table.Cell>{item.playedMins || ''}分鐘</Table.Cell>
                <Table.Cell>{item.payWayText || ''}</Table.Cell>
                <Table.Cell>{item.countMethod || ''}</Table.Cell>
                <Table.Cell>{item.price || ''}</Table.Cell>
              </Table.Row>
              {orderArr.find(item2 => item2.ordering2Id === item.objectId) ? <>
                {orderArr.filter(item2 => item2.ordering2Id === item.objectId).map((item, index) =>
                  <Table.Row key={index}>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>{item.commodity}</Table.Cell>
                    <Table.Cell>{item.price}</Table.Cell>
                  </Table.Row>
                )}
              </> : null}
            </>)}
          </Table.Body>
        </Table >
        <br />
        <hr />
        <br />
        <Row>
          <Col md='2'>
            <div className="text-right">
              <h3 className="no-m m-t-sm">應收</h3>
              <hr />
              <h4 className="no-m m-t-sm">開桌</h4>
              <h4 className="no-m">NTD.{ordering1Obj.totalTablePrice || 0}</h4>
              <hr />
              <h4 className="no-m m-t-sm">飲料</h4>
              <h4 className="no-m">NTD.{ordering1Obj.totalDrinkPrice || 0}</h4>
              <hr />
              <h4 className="no-m m-t-sm">餐點</h4>
              <h4 className="no-m">NTD.{ordering1Obj.totalFoodPrice || 0}</h4>
              <hr />
              <h4 className="no-m m-t-md">合計</h4>
              <h1 className="no-m">NTD.{(ordering1Obj.totalTablePrice || 0) + (ordering1Obj.totalDrinkPrice || 0) + (ordering1Obj.totalFoodPrice || 0)}</h1>
              <hr />
            </div>
          </Col>
          <Col md='2'>
            <div className="text-right">
              <h3 className="no-m m-t-sm">已收</h3>
              <hr />
              <h4 className="no-m m-t-sm">開桌</h4>
              <h4 className="no-m">NTD.{ordering1Obj.receivedTablePrice || 0}</h4>
              <hr />
              <h4 className="no-m m-t-sm">飲料</h4>
              <h4 className="no-m">NTD.{ordering1Obj.receivedDrinkPrice || 0}</h4>
              <hr />
              <h4 className="no-m m-t-sm">餐點</h4>
              <h4 className="no-m">NTD.{ordering1Obj.receivedFoodPrice || 0}</h4>
              <hr />
              <h4 className="no-m m-t-md">合計</h4>
              <h1 className="no-m">NTD.{ordering1Obj.receivedTotalPrice || 0}</h1>
              <hr />
            </div>
          </Col>
          <Col md='2'>
            <div className="text-right">
              <h3 className="no-m m-t-sm">未收</h3>
              <hr />
              <h4 className="no-m m-t-sm">開桌</h4>
              <h4 className="no-m">NTD.{(ordering1Obj.totalTablePrice || 0) - (ordering1Obj.receivedTablePrice || 0)}</h4>
              <hr />
              <h4 className="no-m m-t-sm">飲料</h4>
              <h4 className="no-m">NTD.{(ordering1Obj.totalDrinkPrice || 0) - (ordering1Obj.receivedDrinkPrice || 0)}</h4>
              <hr />
              <h4 className="no-m m-t-sm">餐點</h4>
              <h4 className="no-m">NTD.{(ordering1Obj.totalFoodPrice || 0) - (ordering1Obj.receivedFoodPrice || 0)}</h4>
              <hr />
              <h4 className="no-m m-t-md text-success">合計</h4>
              <h1 className="no-m text-success">
                <b>NTD.{(ordering1Obj.totalTablePrice || 0) + (ordering1Obj.totalDrinkPrice || 0) + (ordering1Obj.totalFoodPrice || 0) - (ordering1Obj.receivedTotalPrice || 0)}</b>
              </h1>
              <hr />
            </div>
          </Col>
          <Col md='6'>
            <Button disabled={allStop} color="primary" size="lg" block style={{ fontSize: '2rem', padding: '50px' }} onClick={this.stop}>全部停時</Button>
            <br />
            <Button color="primary" size="lg" block style={{ fontSize: '2rem', padding: '50px' }} onClick={() => this.isOpen('2')}>付清</Button>
          </Col>
        </Row> */}
        <Modal
          isOpen={isOpen}
          size='md'
          toggle={this.isClose}
        >
          <ModalHeader className="justify-content-center">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.isClose}>
              <span aria-hidden="true">×</span>
            </button>
            {openType === '1' ? '提前離開' : '確定結帳'}
          </ModalHeader>
          <ModalBody><>
            {/* 關桌時間 <Form.Input fluid type="datetime-local" name='closeTime' value={closeTime} onChange={this.handleChange} /><br /> */}
            <h4 className="no-m">飲料 NTD.{drinkTotal}</h4>
            <h4 className="no-m">開桌 NTD.{tableTotal}</h4>
            <br />
            <h2 className="no-m">合計 NTD.{total}</h2>
            {openType === '1' ? <Form.Input fluid name='receivedTotalPrice' value={receivedTotalPrice} placeholder='輸入欲付金額' onChange={this.handleChange} /> : null}<br />
          </>
          </ModalBody>
          <ModalFooter style={{ padding: '16px' }}>
            <Button color='success' onClick={() => this.handleleave(openType)}>確定</Button>
            <Button color='danger' onClick={this.isClose} >關閉</Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={stop}
          size='md'
          toggle={this.isClose}
        >
          <ModalHeader className="justify-content-center">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.isClose}>
              <span aria-hidden="true">×</span>
            </button>
          全部停時
          </ModalHeader>
          <ModalBody><>
            確定要全部停時？
            {/* 關桌時間 <Form.Input fluid type="date" name='closeTime' value={closeTime} onChange={this.handleChange} /><br /> */}
          </>
          </ModalBody>
          <ModalFooter style={{ padding: '16px' }}>
            <Button color='success' onClick={this.allStop}>確定</Button>
            <Button color='danger' onClick={this.isClose} >關閉</Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={newPeople}
          size='md'
          toggle={this.isClose}
        >
          <ModalHeader className="justify-content-center">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.isClose}>
              <span aria-hidden="true">×</span>
            </button>
          新增來賓
          </ModalHeader>
          <ModalBody><>
            確定要新增來賓？
            {/* 關桌時間 <Form.Input fluid type="date" name='closeTime' value={closeTime} onChange={this.handleChange} /><br /> */}
          </>
          </ModalBody>
          <ModalFooter style={{ padding: '16px' }}>
            <Button color='success' onClick={this.addPeople}>確定</Button>
            <Button color='danger' onClick={this.isClose} >關閉</Button>
          </ModalFooter>
        </Modal>
      </div >
    </>);
  }
}

export default CrewService;