import React, { Component } from 'react'
import { Container, Dimmer, Loader, Image, Segment, Header } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Parse from 'widget/parse'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row, Col,
  Form, FormGroup, Input, Label, Button
} from "reactstrap";
import { dorm } from "views/formDef/object";
import { fieldCol } from "views/formDef/function";
import ModalAutoAddr from './ModalAutoAddr';

const columns = () => ([
  { title: 'FundCurrencyValue', field: 'FundCurrencyValue' },
  { title: 'ValuationCurrencyValue', field: 'ValuationCurrencyValue' },
]);

export default class Client1a extends Component {
  state = {
    dataArr: [],
    clientObj: this.props.clientObj,
  }

  async componentDidMount() {
    this.handleFetch()
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, match } = this.props
    const companyId = companyObj.objectId;
    const data = await Parse.queryData("building", { companyId })
    const data1 = await Parse.queryData("room", { companyId })
    const roomOpt = []
    data1.forEach(item => {
      roomOpt.push({ value: item.roomNum, label: `[${item.buildingName}]${item.roomNum}` })
    })
    this.setState({ roomOpt, data1: data.sort((a, b) => a.order - b.order), loading: false })
  }

  handleSaveFile = async () => {
    const { handleFetch, companyObj } = this.props
    const { clientObj } = this.state

    Parse.saveData('clients', clientObj);
    window.alert("已儲存")
  }

  handleChange = (e, data) => {
    if (e.target) {
      this.setState({ clientObj: { ...this.state.clientObj, [e.target.name]: e.target.value } })
    } else {
      this.setState({ clientObj: { ...this.state.clientObj, [data.name]: e } })
    }
  }

  onRowDataChange = (clientObj) => {
    this.setState({ clientObj })
  }

  render() {
    const { dataArr, loading } = this.props;
    const { clientObj, roomOpt } = this.state;
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h4">#{clientObj.uid || ''} {clientObj.name || ''} 基本資料</CardTitle>
        </CardHeader>
        <CardBody>
          <Form >
            <Row form>
              {fieldCol(dorm.roomNum, "", "2", clientObj.roomNum, this.handleChange, false, roomOpt)}
              {fieldCol(dorm.name, "", "2", clientObj.name, this.handleChange)}
              {fieldCol(dorm.idcardNum, "", "2", clientObj.idcardNum, this.handleChange)}
              {fieldCol(dorm.cellphone, "", "2", clientObj.cellphone, this.handleChange)}
              <Col md={1}>
                <FormGroup>
                  <ModalAutoAddr {...this.props} type='3' rowData={clientObj} field1="nowPostcode" field2="addr" onRowDataChange={this.onRowDataChange} />
                </FormGroup>
              </Col>
              {fieldCol(dorm.addr, "", "2", clientObj.addr, this.handleChange)}
            </Row>
            <Row form>
              {fieldCol(dorm.phone, "", "2", clientObj.phone, this.handleChange)}
              {fieldCol(dorm.school, "", "2", clientObj.school, this.handleChange)}
              {fieldCol(dorm.department, "", "2", clientObj.department, this.handleChange)}
              {fieldCol(dorm.grade, "", "2", clientObj.grade, this.handleChange)}
            </Row>
            <br />
            <hr />
            <br />
            <Row form>
              {fieldCol(dorm.emergency, "", "2", clientObj.emergency, this.handleChange)}
              {fieldCol(dorm.relationship, "", "2", clientObj.relationship, this.handleChange)}
              {fieldCol(dorm.emergencyCellphone, "", "2", clientObj.emergencyCellphone, this.handleChange)}
              {fieldCol(dorm.emergencyPhone, "", "2", clientObj.emergencyPhone, this.handleChange)}
              <Col md={1}>
                <FormGroup>
                  <ModalAutoAddr {...this.props} type='3' rowData={clientObj} field1="nowPostcode" field2="emergencyAddr" onRowDataChange={this.onRowDataChange} />
                </FormGroup>
              </Col>
              {fieldCol(dorm.emergencyAddr, "", "2", clientObj.emergencyAddr, this.handleChange)}
            </Row>
            <Row form>
              {fieldCol(dorm.ps, "", "2", clientObj.ps, this.handleChange)}
            </Row>
            <br />
            <Button onClick={this.handleSaveFile}>儲存</Button>
          </Form>
        </CardBody>
      </Card>
      // <Form>
      //   <Form.Group widths='equal'>
      //     <Form.Input fluid label='房號' placeholder='房號' value={roomNum} />
      //     <Form.Input fluid label='姓名' placeholder='姓名' value={name} />
      //     <Form.Input fluid label='身分證字號' placeholder='身分證號' value={idcardNum} />
      //   </Form.Group>
      //   <Form.Group widths='equal'>
      //     <Form.Input fluid label='手機' placeholder='手機' value={cellphone} />
      //     <Form.Input fluid label='電話' placeholder='電話' value={phone} />
      //     <Form.Input fluid label='戶籍地址' placeholder='戶籍地址' value={addr} />
      //   </Form.Group>
      //   <Form.Group widths='equal'>
      //     <Form.Input fluid label='學校' placeholder='學校' value={school} />
      //     <Form.Input fluid label='系別' placeholder='系別' value={department} />
      //     <Form.Input fluid label='年級' placeholder='年級' value={grade} />
      //   </Form.Group>
      //   <br />
      //   <br />
      //   <Form.Group widths='equal'>
      //     <Form.Input fluid label='緊急聯絡人' placeholder='緊急聯絡人' value={emergency} />
      //     <Form.Input fluid label='關係' placeholder='關係' value={relationship} />
      //     <Form.Input fluid label='手機' placeholder='手機' value={emergencyCellphone} />
      //   </Form.Group>
      //   <Form.Group widths='equal'>
      //     <Form.Input fluid label='電話' placeholder='電話' value={emergencyPhone} />
      //     <Form.Input fluid label='地址' placeholder='地址' value={emergencyAddr} />
      //     <Form.Input fluid label='備註' placeholder='備註' value={ps} />
      //   </Form.Group>
      //   <Form.Button onClick={this.handleSaveFile}>儲存</Form.Button>
      // </Form>
    )
  }
}