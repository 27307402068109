import React, { Component } from 'react'
import { Container, Header, Form, Icon } from 'semantic-ui-react'
import RollCallPage from '../Rollcall/RollCallPage';

export class RollCall extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
    };
  }

  handleChange = (e, { value }) => this.setState({ value })

  render() {
    return (
      <div>
        <RollCallPage {...this.props} />
      </div>
    )
  }
}
export default RollCall