import React from "react";
import intl from 'react-intl-universal';
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import PageHeader from 'components/Headers/PageHeader';// reactstrap components

// core components

function IndexHeader() {
  return (
    <>
      <div
        className="page-header"
        style={{
          backgroundImage: "url(" + require("assets/img/cover.jpg") + ")"
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <div className="motto">
            <h1 className="text-center">Paper Kit PRO React</h1>
            <h3 className="text-center">Components</h3>
            <h3 className="text-center">{intl.get('SIMPLE')}</h3>
          </div>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;
