
import React, { Component } from 'react'
import { Button, Progress, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Loader } from 'semantic-ui-react'
import intl from 'react-intl-universal';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import EditWebPage from './EditWebPage';

class ModalPage extends Component {
  state = {
    loading: false,
    loadingBtn: false,
    show: false,
    form: {}, // 要存到資料庫的
    file: null,
    files: [],
    isLoading: false,
    albums: [],
    fileCenter1Arr: [],
    fileCenter2Arr: [],
  }

  async componentDidMount() {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data = await Parse.queryData('fileCenter1', { companyId });
    const data1 = await Parse.queryData('fileCenter2', { companyId });

    this.setState({ fileCenter1Arr: data, fileCenter2Arr: data1 }, () => this.handleFetch())

  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps)
  }

  handleFetch = async (props = this.props) => {
    const form = props.form || {};
    this.setState({ form });
  }

  handleOpen = () => {
    this.setState({ show: true });
  }

  handleClose = () => {
    this.setState({ show: false });
  }

  handleAddUpdate = async () => {
    const { companyObj, pageObj, type } = this.props; // type === '1' 新增
    const { form, file, files } = this.state;
    const { componentId = '--' } = form;
    this.setState({ isLoading: true });
    console.log(files);
    if (componentId === '--') {
      window.alert("請選擇版型");
      return;
    }
    let newData = form;
    if (type === '1') {
      const db = Parse.Object.extend("webpage");
      const newDoc = new db();
      const doc = await newDoc.save();

      newData = {
        objectId: doc.id,
        id: doc.id,
        _id: doc.id,
        pageId: pageObj.objectId,
        companyId: companyObj.objectId,
        companyName: companyObj.name,
        ...form,
      };
    }
    await Parse.saveData('webpage', newData);

    let resFile = {}
    if (file && file.name) {
      resFile = await Parse.saveImgThumb(file, 'webpage', form.objectId, 'img1File', 'img1File2')
      newData = {
        ...newData,
        img1File: type === '1' ? resFile.theFile1 && resFile.theFile1.url : resFile.theFile1 && resFile.theFile1._url,
      };
      this.props.handleComponentChange(type, newData)
    }

    if (files.length) {
      const promises = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        promises.push(Parse.putFile(file));
      }

      const theFiles = await Promise.all(promises).catch(error => console.log("error", error));
      const promises2 = [];
      theFiles.forEach(async (theFile) => {
        const db = Parse.Object.extend("webimage");
        const newDoc = new db();
        const doc = await newDoc.save();

        const element = {
          objectId: doc.id,
          id: doc.id,
          _id: doc.id,
          companyId: companyObj.objectId,
          companyName: companyObj.name,
          pageId: pageObj.objectId,
          componentId,
          theFile
        };
        promises2.push(Parse.saveData('webimage', element));
      })
      await Promise.all(promises2).catch(error => console.log("error", error));
    }
    this.setState({ isLoading: false });
    this.handleClose()
  }

  handleDelete = async () => {
    const { form } = this.state
    this.setState({ loading: true });
    await Parse.deleteData('webpage', form);
    this.setState({ loading: false, show: false })
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({ form: { ...this.state.form, [id]: value } })
  }

  handleEditorChange = (id, value) => {
    this.setState({ form: { ...this.state.form, [id]: value } })
  }

  handleChangeState = (name, value) => {
    console.log(name);
    console.log(value);
    this.setState({ [name]: value });
  }


  render() {
    const { pageObj, type = '1', } = this.props
    const { loading, show, form, isLoading, albums, file, files, fileCenter1Arr, fileCenter2Arr } = this.state

    const url = `/page/${pageObj.path2 || pageObj.objectId}`
    return (<>
      <div>
        <h4>頁面連結：<a href={url} target='_blank' rel="noopener noreferrer">{url}</a>
          <Button
            className="float-right"
            floated='right'
            color={type === '1' ? "primary" : 'success'}
            onClick={this.handleOpen}
          >{type === '1' ? intl.get('新增') : intl.get('編輯')}</Button>
        </h4>
      </div>
      <Modal
        isOpen={show}
        toggle={this.handleClose}
        size="lg"
        className="mh-90 h-90 mw-100 w-90"
        style={{ paddingTop: '0px' }}
        scrollable={true}
      >
        <ModalHeader className="justify-content-center">
          {type === '1' ? intl.get('新增') : intl.get('編輯')} {pageObj.name} 頁面項目
          {/* <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon> */}
        </ModalHeader>
        <ModalBody style={{ padding: "20px 30px" }}>
          <EditWebPage
            {...this.props}
            form={form}
            pageObj={pageObj}
            albums={albums}
            file={file}
            files={files}
            fileCenter1Arr={fileCenter1Arr}
            fileCenter2Arr={fileCenter2Arr}
            handleChange={this.handleChange} // reactstrap
            handleChangeState={this.handleChangeState} //file, files
            handleEditorChange={this.handleEditorChange} // Edior
          />
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          {/* {total ? <Progress value={(loaded / total) * 100} /> : null} */}
          <Button
            disabled={form.componentId && form.componentId === '--'}
            color={type === '1' ? "primary" : 'success'}
            onClick={this.handleAddUpdate}
          >
            {type === '1' ? intl.get('新增') : intl.get('儲存')}
            {!isLoading ? '' : <Loader active inverted inline size='small' />}
          </Button>{' '}
          {type === '1' ? null : <Button color="danger" onClick={this.handleDelete}>{!loading ? intl.get('刪除') : <Loader active inverted inline size='small' />}</Button>}{' '}
          <Button color="secondary" onClick={this.handleClose} >{intl.get('關閉')}</Button>
        </ModalFooter>
      </Modal>
    </>);
  }
}

export default ModalPage;
