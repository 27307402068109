import React, { Component } from 'react';
import { Form, Checkbox, Input, Select, Button, Modal, Table, Grid, Icon, Header, Confirm, TextArea, Label } from 'semantic-ui-react';
import Parse from "widget/parse";

const CourseCheckbox = ({ name, period, weekday, isCheck, isEdit, changeCheckbox }) => (
  <Form key={name + period + weekday} style={{ textAlign: 'center' }}>
    <Form.Field
      control={Checkbox}
      name={name}
      value={name}
      onChange={changeCheckbox}
      checked={isCheck}
      disabled={!isEdit}
    />
  </Form>);

const CourseSelect = ({ name, selWeekday, value, changeSelect }) => {
  const arr = [];
  for (let i = Number(selWeekday); i > 0; i--) {
    arr.push(i.toString());
  }
  if (!arr.length) {
    arr.push('0');
  }
  const optionsNum = arr.map(item => ({ key: item, text: item, value: item }))
  optionsNum.unshift({ key: 'empty', text: '- 選択 -', value: '0' });
  return (
    <td key={name + selWeekday + value} style={{ textAlign: 'center' }}>
      <Input >
        <Select
          options={optionsNum} onChange={changeSelect} value={value} name={name} fluid />
      </Input>
      {/* <Form.Group >
        <Form.Control
          as="select"
          placeholder="select"
          name={name}
          value={value}
          onChange={changeSelect}
        >
          <option value="">-- 選択 --</option>
          {arr.map(item => (<option key={item} value={item}>{item}</option>))}
        </Form.Control>
      </Form.Group > */}
    </td >
  );
};

const semesterArr = [
  { key: '2020,A', text: '2020A', value: '2020,A' },
  { key: '2020,S', text: '2020S', value: '2020,S' },
  { key: '2019,A', text: '2019A', value: '2019,A' },
  { key: '2019,S', text: '2019S', value: '2019,S' },
  { key: '2018,A', text: '2018A', value: '2018,A' },
  { key: '2018,S', text: '2018S', value: '2018,S' },
  { key: '2017,A', text: '2017A', value: '2017,A' },
  { key: '2017,S', text: '2017S', value: '2017,S' },
]

export default class List2Teacher extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      classroom: [],
      arrangeArr: props.arrangeArr || [], // 所有的 資料都先更新在這邊 保存時再一併寫回去
      // currentSemester: '',
      slotArr: [],
      selWeekdayArr: [0, 0, 0, 0, 0],
      maxWeekdayArr: ['0', '0', '0', '0', '0'],
      nonconsecutive: false,
      // form: {
      //   // 判斷當下處於哪一個学年，如果當下時間是在今年的4/1之後，表示目前学年是(今年)，否則為(去年)
      //   semesterYear: (new Date() >= new Date(new Date().getFullYear(), 3) ? new Date().getFullYear() : new Date().getFullYear() - 1) + '',
      //   // 判斷當下處於哪一個学期，如果當下時間是在今年的4/1到9/30之間，表示目前学年是(春)，否則為(秋)
      //   semester: (new Date() >= new Date(new Date().getFullYear(), 3) && new Date() < new Date(new Date().getFullYear(), 9)) ? 'S' : 'A',
      // }
      yearsemester: `${this.props.semesterYear},${this.props.semester}`,
      semesterYear: this.props.semesterYear,
      semester: this.props.semester,
    };
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.onChangePreference = this.onChangePreference.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getCurrentArragement = this.getCurrentArragement.bind(this);
  }

  componentWillMount() {
    console.log(this.props._id, this.props)
    this.getCurrentArragement(this.props._id);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.teachersObj.objectId !== nextProps.teachersObj.objectId) {
      this.setState({ arrangeArr: nextProps.arrangeArr }, this.getCurrentArragement(nextProps.teachersObj.objectId));
    }
  }

  onChangeSelect(e, data) { // 更新最大值
    // console.log(e.target.name);
    // console.log(e.target.value);

    let arr = this.state.maxWeekdayArr;
    arr[Number(data.name)] = data.value;
    this.setState({ maxWeekdayArr: arr }, () => this.updateArrangeArr()); // 再進行更新
  }

  onChangePreference(e) { // 更新最大值
    // console.log(e.target.name);
    // console.log(e.target.value);

    this.setState({ nonconsecutive: !this.state.nonconsecutive }, () => this.updateArrangeArr()); // 再進行更新
  }

  onChangeCheckbox(e, data) {
    // console.log(e.target.name);
    // console.log(e.target.value);
    // console.log(e.target.checked);
    const { slotArr } = this.state;
    const { value, checked } = data;
    // console.log("start", slotArr);

    const arr = slotArr;
    if (value.charAt(0) === 'a') { // 處理全選択的部份
      if (checked) {
        for (let i = 0; i < 8; i++) {
          const item = i + value.charAt(1);
          if (slotArr.indexOf(item) === -1) {
            arr.push(item);
          }
        }
      } else {
        for (let i = 0; i < 8; i++) {
          const item = i + value.charAt(1);
          if (slotArr.indexOf(item) > -1) {
            const arrIndex = arr.indexOf(item);
            arr.splice(arrIndex, 1);
          }
        }
      }
    }

    if (checked && slotArr.indexOf(value) === -1) { // 沒勾 -> 有勾，原本的陣列中沒有 要在arr中追加
      arr.push(value);
    } else if (slotArr.indexOf(value) > -1) {  // 有找到 要移除這個
      const arrIndex = slotArr.indexOf(value);
      arr.splice(arrIndex, 1);
    }
    this.setState({ slotArr: arr });
    // console.log("end", slotArr);

    /// 算已選的
    const arr2 = [0, 0, 0, 0, 0];
    // console.log('aaa');
    for (let i = 0; i < 5; i++) {
      let count = 0;
      for (let j = 0; j < slotArr.length; j++) {
        const element = slotArr[j];
        if (element.charAt(0) !== 'a' && element.charAt(1) === i.toString()) {
          count++;
        }
      }
      arr2[i] = count;
    }
    this.setState({ selWeekdayArr: arr2 }, () => this.updateArrangeArr());
  }

  updateArrangeArr() {
    // const { } = this.props;
    const { arrangeArr, slotArr, selWeekdayArr, maxWeekdayArr, nonconsecutive, semester, semesterYear } = this.state;
    const arr = arrangeArr;

    if (arr && arr.length) { // 找原本的陣列有沒有值 有的話刪掉
      // console.log(arr);
      // let removeIndex = arr.map(item => item.semeter).indexOf(currentSemester);
      let removeIndex = arr.map(item => item.semesterYear + semester).indexOf(semesterYear + semester);//加map是讓這個變成一個單純的陣列
      ~removeIndex && arr.splice(removeIndex, 1);
    }

    // console.log(semester);
    const obj = {
      // semeter: currentSemester,
      semester,
      semesterYear,
      slotArr,
      selWeekdayArr,
      maxWeekdayArr,
      nonconsecutive,
    }
    arr.push(obj);
    // console.log(arr)
    this.setState({ arrangeArr: arr });
    this.props.saveArrangement(arr);
  }

  async getCurrentArragement(_id) {
    const { firebase, teachersObj, match } = this.props;
    const { semester, semesterYear, arrangeArr, currentSubscription } = this.state;
    // const { slotArr, selWeekdayArr, maxWeekdayArr } = this.state;
    // console.log("getCurrentArragement", semester, semesterYear);
    // let arrangeArr = [];
    let id = _id || match.params._id;
    let slotArr = [];
    let selWeekdayArr = [0, 0, 0, 0, 0];
    let maxWeekdayArr = ['0', '0', '0', '0', '0'];
    let nonconsecutive = false;
    const query = new Parse.Query('Teachers');
    query.equalTo('objectId', id);
    query.select('arrangeArr')
    const snap = await query.find();
    const arrangeCurrentArr = snap.map(data => data.toJSON());
    // firebase.firestore().doc(`Teachers/${id}`)
    //   .get().then(documentSnapshot => {
    // console.log(`Teachers/${id}`)
    // let arrangeCurrentArr = documentSnapshot.get('arrangeArr') || [];
    this.setState({ arrangeArr: arrangeCurrentArr });
    if (currentSubscription) {
      // console.log('currentSubscription.unsubscribe')
      await currentSubscription.unsubscribe();
      this.setState({ currentSubscription: null })
    }

    let subscription = await query.subscribe().catch(error => console.error(error));

    subscription.on('create', (data) => {
      const arrangeArr = [...this.state.arrangeArr, data.toJSON()];
      this.setState({ arrangeArr }, () => this.scrollToBottom())
    });
    subscription.on('update', (data) => {
      const arrangeArr = [...this.state.arrangeArr, data.toJSON()];
      this.setState({ arrangeArr }, () => this.scrollToBottom())
    });
    subscription.on('delete', (data) => {
      const arrangeArr = [...this.state.arrangeArr, data.toJSON()];
      this.setState({ arrangeArr }, () => this.scrollToBottom())
    });
    if (arrangeCurrentArr && arrangeCurrentArr.length && arrangeCurrentArr.find(item => item.semester === semester && item.semesterYear === semesterYear)) {
      const obj = arrangeCurrentArr.find(item => item.semester === semester && item.semesterYear === semesterYear);
      slotArr = obj.slotArr || [];
      selWeekdayArr = obj.selWeekdayArr || [0, 0, 0, 0, 0];
      maxWeekdayArr = obj.maxWeekdayArr || ['0', '0', '0', '0', '0'];
      nonconsecutive = obj.nonconsecutive || false;
    }
    this.setState({ slotArr, selWeekdayArr, maxWeekdayArr, nonconsecutive, currentSubscription: subscription });
    // });
    // console.log(arrangeArr)
  }

  handleChange(e, { name, value }) {
    let semesterYear, semester = ''
    if (name === 'yearsemester') {
      semesterYear = value.substring(0, 4);
      semester = value.substring(5);
      // console.log(semesterYear, semester, [name]: value )
      this.setState({ form: { ...this.state.form }, semesterYear, semester, [name]: value }, () => this.getCurrentArragement(this.props._id))
    }
    else {
      this.setState({ form: { ...this.state.form, [name]: value } }, () => this.getCurrentArragement(this.props._id))
    }
  }

  render() {
    const { isEdit } = this.props;
    const { slotArr, selWeekdayArr, maxWeekdayArr, nonconsecutive, yearsemester } = this.state;

    const weekday = ['月', '火', '水', '木', '金'];
    const period = ["一", "二", "三", "四", "五", "六", "七", "八"];
    return (
      <>
        <Input labelPosition='left'>
          <Label color={'pink'}> <Icon name='calendar' />学期</Label>
          <Select compact options={semesterArr} onChange={this.handleChange} value={yearsemester} name='yearsemester' id='yearsemester' />
        </Input>
        <br />
        <Form.Field
          control={Checkbox}
          label="非連続で授業 (例：1256 1278 3478)"
          onChange={this.onChangePreference}
          checked={nonconsecutive}
          disabled={!isEdit}
        // value={item.value}
        />
        <br />
        <Table striped celled size="small">
          <thead>
            <tr>
              <th>#</th>
              {weekday.map(item => (<th key={item} style={{ textAlign: 'center' }}>{item}</th>))}
            </tr>
            <tr>
              <th style={{ textAlign: 'center' }}>全選択</th>
              {([0, 1, 2, 3, 4]).map((item2, index2) => {
                return (
                  <th key={item2}>
                    <CourseCheckbox
                      key={item2}
                      name={`a${index2}`}
                      weekday={`${index2}`}
                      isCheck={slotArr.indexOf(`a${index2}`) !== -1}
                      isEdit={isEdit}
                      changeCheckbox={this.onChangeCheckbox}
                    />
                  </th>
                )
              })}</tr>
          </thead>
          <tbody>
            {period.map((item, index) => (
              <tr key={item}>
                <td style={{ textAlign: 'center' }}>{item}</td>
                {([0, 1, 2, 3, 4]).map((item2, index2) => {
                  return (
                    <td key={item2}>
                      <CourseCheckbox
                        key={item2}
                        name={`${index}${index2}`}
                        period={`${index}`}
                        weekday={`${index2}`}
                        isCheck={slotArr.indexOf(`${index}${index2}`) !== -1}
                        isEdit={isEdit && slotArr.indexOf(`a${index2}`) === -1}
                        changeCheckbox={this.onChangeCheckbox}
                      // course={course}
                      // value={course_id}
                      />
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td style={{ textAlign: 'center' }} md={1}>授業可能時間数</td>
              {(['0', '1', '2', '3', '4']).map((item2, index2) => (<td key={item2} md={2} style={{ textAlign: 'center' }}>{selWeekdayArr[index2]}</td>))}
            </tr>
            <tr>
              <td style={{ textAlign: 'center' }} md={1}>授業最多時間数</td>
              {(['0', '1', '2', '3', '4']).map((item2, index2) => (isEdit ?
                <CourseSelect
                  key={item2}
                  name={item2}
                  selWeekday={selWeekdayArr[index2]}
                  value={maxWeekdayArr[index2]}
                  changeSelect={this.onChangeSelect}
                // updateSelect={this.handleChange2}
                /> : <td key={item2} style={{ textAlign: 'center' }}>{maxWeekdayArr[index2]}</td>))}
            </tr>
          </tfoot>
        </Table>
      </>
    );
  }
}