
import React, { Component } from 'react'
import { Container, Grid, Header, Form, Segment, Image } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf, rowReOrder } from 'views/web/CustomMaterialTable';

// import RowsDnd from './RowsDnd'

// reactstrap components
// import {
//   Card,
//   CardHeader,
//   CardBody,
//   CardTitle,
//   Table,
//   Row,
//   Col
// } from "reactstrap";

const columns = () => ([
  { title: '順序', field: 'order', type: 'numeric' },
  { title: '班別', field: 'value' },
]);

// const style = {
//   flex: {
//     display: 'flex'
//   },
//   flexCenter: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center'
//   }
// }

class Class extends Component {
  state = {
    dataArr: [],
    data1: [],
    loading: false
  }
  async componentDidMount() {
    const { companyObj, match } = this.props
    const { familyId } = match.params;
    const data1 = await Parse.queryData("templeclass", { companyId: companyObj.objectId, familyId })
    this.setState({ dataArr: data1.sort((a, b) => a.order - b.order), loading: false })
  }
  handleAdd = async () => {
    const { newData1 } = this.state;
    Parse.saveData('templeclass', newData1);
  }

  handleUpdate = async () => {
    const { newData1 } = this.state;
    Parse.saveData('templeclass', newData1);
  }

  handleDelete = async () => {
    const { oldData1 } = this.state
    Parse.deleteData('templeclass', oldData1);
    this.setState({ oldData1: {} })
  }

  handleState = (dataArr, arrName = 'dataArr') => {
    this.setState({ [arrName]: dataArr })
  }

  render() {
    // const { companyObj } = this.props;
    const { dataArr, loading } = this.state;
    return (<div className="content">
      <br />
      <br />
      <Grid>
        <Grid.Row>
          <Grid.Column width={6} computer={6} tablet={16} mobile={16}>
            <MaterialTable
              isLoading={loading}
              localization={localization()}
              columns={columns()}
              options={{
                pageSize: (dataArr.length || 10),
                search: false
              }}
              // components={{
              //   Toolbar: props => (
              //     <div>
              //       <div style={{ width: '130px', float: 'right', textAlign: 'right', padding: '15px' }}>
              //         <RowsDnd {...this.props} orderField='orderId' columns={columns()} dataArr={dataArr} handleReorder={this.handleReorder} />
              //       </div>
              //       <MTableToolbar {...props} />
              //       <div style={{ clear: 'both' }}></div>
              //     </div>
              //   ),
              // }}
              data={dataArr}
              title="班別"
              components={{
                Row: props => rowReOrder(props, 'templeclass', 'order', dataArr, 'dataArr', this.handleState)
              }}
              editable={{
                onRowAdd: newData1 =>
                  new Promise(async (resolve, reject) => {
                    const data = [...dataArr];
                    const db = Parse.Object.extend("templeclass");
                    const newDoc = new db();
                    const doc = await newDoc.save();
                    newData1.objectId = doc.id;
                    newData1.id = doc.id;
                    newData1._id = doc.id;

                    data.push(newData1);

                    this.setState({ dataArr: data, newData1 }, () => resolve());
                  }).then(() => this.handleAdd()),
                onRowUpdate: (newData1, oldData1) =>
                  new Promise((resolve, reject) => {
                    const data = [...dataArr];
                    const index = data.indexOf(oldData1);
                    data[index] = newData1;
                    // console.log(data1)
                    this.setState({ dataArr: data, newData1, oldData1 }, () => resolve());
                  }).then(() => this.handleUpdate()),
                onRowDelete: oldData1 =>
                  new Promise((resolve, reject) => {
                    let data = [...dataArr];
                    const index = data.indexOf(oldData1);
                    data.splice(index, 1);
                    this.setState({ dataArr: data, oldData1 }, () => resolve());
                  }).then(() => this.handleDelete()),
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>);
  }
}

export default Class;
