const { gzip, ungzip } = require('node-gzip');

const searchParams = (params) => Object.keys(params).map((key) => {
  return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
}).join('&');

export const fetchData = (url, data = {}, options = {}) => { // Default options are marked with *
  const { method = 'GET', cache = 'no-cache' } = options;
  const header0 = {
    cache, //: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      // 'user-agent': 'Mozilla/4.0 MDN Example',
      'content-type': 'application/x-www-form-urlencoded'
    },
    // method: 'POST', // *GET, POST, PUT, DELETE, etc.
    method,
    // mode: 'cors', // no-cors, cors, *same-origin
    // redirect: 'follow', // manual, *follow, error
    // referrer: 'no-referrer', // *client, no-referrer
  }

  let header = header0;
  if (method === 'GET') {
    var esc = encodeURIComponent;
    var query = Object.keys(data).map(k => esc(k) + '=' + esc(data[k])).join('&');
    // console.log(`${url}?${query}`)
    return fetch(`${url}?${query}`, header);//.then(response => response.json()) // 輸出成 json
  } else {
    header = {
      body: searchParams(data), // must match 'Content-Type' header
      ...header0
    }
    return fetch(url, header).then(response => response.json()) // 輸出成 json
  }
}
export const fetchGz2Txt = (url, data = {}, options = {}) => { // Default options are marked with *
  const { method = 'GET', cache = 'force-cache' } = options;
  const header0 = {
    cache, //: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      // 'user-agent': 'Mozilla/4.0 MDN Example',
      'content-type': 'application/x-www-form-urlencoded',
      'Accept-encoding': 'gzip,deflate'
    },
    // method: 'POST', // *GET, POST, PUT, DELETE, etc.
    method,
    // mode: 'cors', // no-cors, cors, *same-origin
    // redirect: 'follow', // manual, *follow, error
    // referrer: 'no-referrer', // *client, no-referrer
  }

  let header = header0;
  if (method === 'GET') {
    var esc = encodeURIComponent;
    var query = Object.keys(data).map(k => esc(k) + '=' + esc(data[k])).join('&');
    // console.log(`${url}?${query}`)
    return fetch(`${url}${query ? ('?' + query) : ''}`, header).then(async (response) => {
      const res = await response.arrayBuffer()
      const data = new Uint8Array(res);
      const decompressed = await ungzip(data).catch(error => console.error(error))
      return decompressed.toString();
    })
  } else {
    header = {
      body: searchParams(data), // must match 'Content-Type' header
      ...header0
    }
    return fetch(url, header).then(response => response.json()) // 輸出成 json
  }
}

const fetchJson = (url, data = {}, options = {}) => { // Default options are marked with *
  const { method = 'GET', cache = 'no-cache' } = options;
  const header0 = {
    cache, //: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      'user-agent': 'Mozilla/4.0 MDN Example',
      'content-type': 'application/json'
    },
    // method: 'POST', // *GET, POST, PUT, DELETE, etc.
    method,
    mode: 'cors', // no-cors, cors, *same-origin
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // *client, no-referrer
  }

  let header = header0;
  if (method === 'GET') {
    var esc = encodeURIComponent;
    var query = Object.keys(data).map(k => esc(k) + '=' + esc(data[k])).join('&');
    // console.log(`${url}?${query}`)
    return fetch(`${url}?${query}`, header).then(response => response.json()) // 輸出成 json
  } else {
    header = {
      body: JSON.stringify(data), // must match 'Content-Type' header
      ...header0
    }
    return fetch(url, header).then(response => response.json()) // 輸出成 json
  }
}

export default fetchJson;