import React, { Component } from 'react'
import { Container, Dimmer, Loader, Image, Segment, Input } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Moment from 'moment';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf, typeCurrency } from 'views/web/CustomMaterialTable';

let categoryOpt = { '0': '應收', '1': '應付' }

const columns = () => ([
  { title: '類別', field: 'category', lookup: categoryOpt },
  { title: '項目', field: 'title' },
  { title: '前期累積', field: 'previous', ...typeCurrency },
  { title: '本月金額', field: 'money', ...typeCurrency },
  {
    title: '合計', field: 'total',
    render: rowData => <>{'NT$' + rowData.total}</>,
    editComponent: prop => {
      return <>{String(Number(prop.rowData.previous || 0) + Number(prop.rowData.money || 0))}</>
    },
  },
  { title: '備註', field: 'ps' },
]);

export default class Fin1114 extends Component {
  state = {
    dataArr: [],
    deleteConfirm: false,
    loading: true,
    monthSel: Moment(new Date()).format('YYYY-MM') || '',
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj } = props;
    const { monthSel, } = this.state
    const [year, month] = monthSel.split('-');
    if (!year || !month) {
      return;
    }
    const companyId = companyObj.objectId;
    const accountRecPay = await Parse.queryData('accountRecPay', { companyId, monthSel });
    let dataArr = accountRecPay.sort((a, b) => ('' + a.category).localeCompare(b.category));
    this.setState({ dataArr, loading: false });
    // const receivable = await Parse.queryData('receivable', { companyId });
    // this.setState({ receivableArr: receivable, receivable });
    // const payable = await Parse.queryData('payable', { companyId });
    // this.setState({ payableArr: payable, loading: false, payable });
  }

  handleAdd = async () => {
    const { newData, monthSel, bankaccSel } = this.state
    const [year, month] = monthSel.split('-');
    if (!year || !month) {
      return;
    }
    const obj = {
      ...newData,
    };
    await Parse.saveData('accountRecPay', obj);
  }

  handleUpdate = async () => {
    const { newData, monthSel } = this.state
    const [year, month] = monthSel.split('-') || ['', ''];
    if (!year || !month) {
      return;
    }
    const obj = {
      ...newData,
    };
    await Parse.saveData('accountRecPay', obj);
  }

  handleDelete = async () => {
    const { oldData, monthSel, bankaccSel } = this.state
    const [year, month] = monthSel.split('-') || ['', ''];
    if (!year || !month) {
      return;
    }

    await Parse.deleteData('accountRecPay', oldData);
    this.setState({ oldData: {} })
  }

  handleChange = (event, data) => {
    // console.log(data)
    const { name, value } = data;
    this.setState({ [name]: value, loading: true }, () => this.handleFetch());
  }

  render() {
    const { companyObj, auth } = this.props
    const { dataArr, loading, monthSel, receivableArr, payableArr } = this.state;
    const [year, month] = monthSel.split('-') || ['', ''];

    const editable = {
      isEditHidden: () => !auth.edit,
      isDeleteHidden: () => !auth.edit,
      onRowAdd: newData =>
        new Promise(async (resolve, reject) => {
          const db = Parse.Object.extend("accountRecPay");
          const newDoc = new db();
          const doc = await newDoc.save();
          const data = [...dataArr];
          const obj = {
            _id: doc.id,
            id: doc.id,
            objectId: doc.id,
            companyId: companyObj.objectId || '',
            companyName: companyObj.name || '',
            monthSel,
            year,
            month,
            ...newData,
            total: String(Number(newData.previous || 0) + Number(newData.money || 0)),
          }
          data.push(obj);
          this.setState({ dataArr: data, newData: obj }, () => resolve());
        }).then(() => this.handleAdd()),
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          const data = [...dataArr];
          const index = data.indexOf(oldData);
          let obj = {
            ...newData,
            total: String(Number(newData.previous || 0) + Number(newData.money || 0))
          }
          data[index] = obj;
          this.setState({ dataArr: data, newData: obj, oldData }, () => resolve());
        }).then(() => this.handleUpdate()),
      onRowDelete: oldData =>
        new Promise((resolve, reject) => {
          const data = [...dataArr];
          const index = data.indexOf(oldData);
          data.splice(index, 1);
          this.setState({ dataArr: data, oldData }, () => resolve());
        }).then(() => this.handleDelete()),
    }

    if (auth.edit === false) {
      delete editable.onRowAdd
    }

    return (<>
      {/* 資金需求預估表 */}
      <br />
      <Input
        type="month"
        name="monthSel"
        value={monthSel}
        onChange={this.handleChange}
      />
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => {
            const dataArr = data.map(item => ({ ...item, category: categoryOpt[item.category] || '' }))
            exportPdf(columns, dataArr, `${year} 年 ${month} 月 應收應付帳款明細表`)
          },
          pageSize: dataArr.length || 10,
          search: false
        }}
        data={dataArr}
        title={`${year} 年 ${month} 月 應收應付帳款明細表`}
        editable={editable}
      />
      <br />
      {/* <MaterialTable
          isLoading={loading}
          localization={localization()}
          columns={columns()}
          options={{
            addRowPosition: "first",
exportButton: true,
            pageSize: payableArr.length || 10,
            search: false
          }}
          data={payableArr}
          title={`${year} 年 ${month} 月 應付帳款明細表`}
          editable={{
            onRowAdd: newData =>
              new Promise(async (resolve, reject) => {
                const db = Parse.Object.extend("payable");
                const newDoc = new db();
                const doc = await newDoc.save();
                const data = payableArr;
                const obj = {
                  ...newData,
                  _id: doc.id,
                  id: doc.id,
                  objectId: doc.id,
                  companyId: companyObj.objectId || '',
                  companyName: companyObj.name || '',
                }
                data.push(obj);
                this.setState({ payableArr: data, newData: obj }, () => resolve());
              }).then(() => this.handlePayAdd()),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const data = payableArr;
                const index = data.indexOf(oldData);
                data[index] = newData;
                this.setState({ payableArr: data, newData, oldData }, () => resolve());
              }).then(() => this.handlePayUpdate()),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                let data = payableArr;
                const index = data.indexOf(oldData);
                data.splice(index, 1);
                this.setState({ payableArr: data, oldData }, () => resolve());
              }).then(() => this.handlePayDelete()),
          }}
        /> */}
    </>)
  }
}