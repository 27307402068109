import React, { Component } from 'react'
// import { Table, Header, Segment, Tab, Select } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import { Row, Col, Form, FormGroup, Input, Label, Button } from "reactstrap";

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf, rowReOrder } from 'views/web/CustomMaterialTable';


const columns = () => ([
  { width: 60, title: '順序', field: 'order', type: 'numeric' },
  // { width: 80, title: '類', field: 'type' },
  { width: 80, title: '類別', field: 'name' },
  { width: 80, title: '預設月息 (%)', field: 'interest' },
  // { width: 80, title: '每期天數', field: 'daysPerPeriod' },
  // { width: 80, title: '期息(分)', field: 'periodInterest' },
  // { width: 80, title: '期滿月數', field: 'expiryMonths' },
]);

class Pawnticket extends Component {
  state = {
    activeItem: 'bio',
    dataArr: [],
    rowData: {},
    loading: true
  }

  async componentDidMount() {
    const data1 = await Parse.queryData('pawnType');
    this.setState({ dataArr: data1, loading: false });
  }

  handleAdd = async () => {
    // const { newData } = this.state;
    // Parse.saveData('pawnType', newData);
    this.handleUpdate()
  }

  handleUpdate = async () => {
    const { companyObj } = this.props
    const { newData } = this.state;
    Parse.saveData('pawnType', {
      ...newData,
      companyId: companyObj.objectId || '',
      companyName: companyObj.name || '',
    });
  }

  handleDelete = async () => {
    const { oldData } = this.state
    Parse.deleteData('pawnType', oldData);
    this.setState({ oldData: {} })
  }


  handleItemClick = (e, { name }) =>
    this.setState({
      activeItem: name
    })

  handleData = (rowData) => {
    this.setState({ rowData });
  }

  handleState = (dataArr, arrName = 'dataArr') => {
    this.setState({ [arrName]: dataArr })
  }

  render() {
    const { dataArr, rowData, loading } = this.state
    return (<div className="content">
      <Row>
        <Col><legend>3-1 典當品設定</legend></Col>
      </Row>
      <br />
      <Row>
        <Col md={6}>
          <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns()}
            options={{
              addRowPosition: "first",
              exportButton: true,
              exportPdf: (columns, data) => exportPdf(columns, data, '設定'),
              pageSize: 10,
              search: false,
              tableLayout: 'fixed'
            }}
            components={{
              Row: props => rowReOrder(props, 'pawnType', 'order', dataArr, 'dataArr', this.handleState)
            }}
            data={dataArr}
            title="設定"
            // actions={[
            //   {
            //     icon: 'view_headline',
            //     tooltip: '查看',
            //     onClick: (event, rowData) => this.handleData(rowData)
            //   }
            // ]}
            editable={{
              onRowAdd: newData =>
                new Promise(async (resolve, reject) => {
                  const data = [...dataArr];
                  const db = Parse.Object.extend("pawnType");
                  const newDoc = new db();
                  const doc = await newDoc.save();
                  newData.objectId = doc.id;
                  newData.id = doc.id;
                  newData._id = doc.id;

                  data.push(newData);
                  this.setState({ dataArr: data, newData }, () => resolve());
                }).then(() => this.handleAdd()),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const data = [...dataArr];
                  const index = data.indexOf(oldData);
                  data[index] = newData;
                  // handleState('people0', data);
                  this.setState({ dataArr: data, newData, oldData }, () => resolve());
                }).then(() => this.handleUpdate()),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  let data = [...dataArr];
                  const index = data.indexOf(oldData);
                  data.splice(index, 1);
                  // handleState('people0', data);
                  this.setState({ dataArr: data, oldData }, () => resolve());
                }).then(() => this.handleDelete()),
            }}
          />
        </Col>
      </Row>
      <br />
      <br />
    </div>
    );
  }
}

export default Pawnticket;