
import React, { Component } from 'react'
// import { Container, Header, Modal, Button, Table, Form, Grid, Image, Input, Checkbox, Divider, Tab, Dimmer, Loader, Icon, Segment } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

import Users from './Users';
import System from './System';
import Company from './Company';
import ModalUpdateFile from 'views/web/ModalUpdateFile';
import { RowImage } from '../web/RowImage';
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

// const sizeSel = { 'mini': '1 最小', 'tiny': '2 預設', 'small': '3', 'medium': '4', 'large': '5', 'big': '6', 'huge': '7', 'massive': '8 最大' }
const webtypeSel = {
  'restaurant': '餐廳',
  'makeup': '美妝美容',
  'clothes': '服飾',
  'temple': '佛教',
  'dao': '道教',
  'hanbury': '漢栢略',
  'publishing': '出版社',
  'pawnshop': '當舖',
  'dorm': '套房出租',
  'autorepair': '修車廠',
  'healthy': '健康管理',
  'salesInventory': '銷貨庫存',
  'crm': '客戶關係管理',
  'official': '官網',
  "onlinetest": '線上測驗',
  'school': '日語學校',
  'streaming': '網路電視',
  'ziwei': '紫微斗數',
  'develop': '開發用',
  'doubleC': 'doubleC'
};

const devPhaseSel = {
  '1': '1 上線',
  '2': '2 修改',
  '3': '3 洽談',
  '4': '4 推廣',
  '5': '5 關閉',
  '6': '6 穩定',
}

const columns = ({ pray1, richMenuChannel, sectorSel }) => {

  return [
    { width: 120, title: '類別', field: 'devPhaseId', lookup: devPhaseSel },
    { width: 80, title: '網站別', field: 'webtypeId', lookup: webtypeSel },
    { width: 150, title: '公司名(中)', field: 'name' },
    {
      width: 300, title: 'line開通碼', field: 'objectId', editable: 'never', render: rowData => {
        let idObj = {}
        richMenuChannel.filter(item => item.bgId === rowData.webtypeId).forEach(({ objectId, rankId }) => { idObj = { ...idObj, [rankId]: objectId } });
        // console.log(richMenuChannel.filter(item => item.bgId === rowData.webtypeId))
        // console.log(idObj)
        // console.log(idObj)

        // if (!rowData.useLine) {
        //   return <></>;
        // } else {
        return <>
          老闆：{rowData.objectId}{idObj['1'] ? "-" + idObj['1'] : ''}<br />
          員工：{rowData.objectId}{idObj['2'] ? "-" + idObj['2'] : ''}<br />
          客人：{rowData.objectId}{idObj['3'] ? "-" + idObj['3'] : ''}<br />
        </>
        // }
      }
    },
    // { width: 200, title: '備註', field: 'ps' },
    { width: 60, title: '官網', field: 'useOfficial', type: 'boolean' },
    { width: 80, title: '多國語系', field: 'isMultiLang', type: 'boolean' },
    { width: 60, title: 'Line', field: 'useLine', type: 'boolean' },
    { width: 60, title: '差勤', field: 'useAttendance', type: 'boolean' },
    { width: 60, title: '資源', field: 'useResource', type: 'boolean' },
    { width: 60, title: '會計', field: 'useAccount', type: 'boolean' },
    { width: 60, title: '傳銷', field: 'useMLM', type: 'boolean' },
    { width: 80, title: '白色選單', field: 'whiteNavbar', type: 'boolean' }, //, headerStyle: { minWidth: 100 }
    { width: 80, title: '深色', field: 'inverted', type: 'boolean' }, //, headerStyle: { minWidth: 100 }
    { width: 100, title: 'channelId', field: 'channelId' },
    { width: 400, title: 'channelAccessToken', field: 'channelAccessToken' },
    { width: 100, title: 'channelSecret', field: 'channelSecret' },
    {
      width: 50, title: '', field: 'img0sel', filtering: false, headerStyle: { textAlign: 'center' }, render: rowData => <></>,
      editComponent: props => <ModalUpdateFile isUploadOri={true} fieldname='img0File' rowData={props.rowData} onRowDataChange={props.onRowDataChange} />
    },
    { width: 120, title: '0 登入背景', field: 'img0File', filtering: false, render: rowData => <RowImage rowData={rowData} field='img0File' />, editComponent: props => <RowImage rowData={props.rowData} field='img0File' /> },
    {
      width: 50, title: '', field: 'img1sel', filtering: false, headerStyle: { textAlign: 'center' }, render: rowData => <></>,
      editComponent: props => <ModalUpdateFile isUploadOri={true} fieldname='img1File' rowData={props.rowData} onRowDataChange={props.onRowDataChange} />
    },
    { width: 120, title: '1 Logo', field: 'img1File', filtering: false, render: rowData => <RowImage rowData={rowData} field='img1File' />, editComponent: props => <RowImage rowData={props.rowData} field='img1File' /> },
    {
      width: 50, title: '', field: 'img2sel', filtering: false, headerStyle: { textAlign: 'center' }, render: rowData => <></>,
      editComponent: props => <ModalUpdateFile isUploadOri={true} fieldname='img2File' rowData={props.rowData} onRowDataChange={props.onRowDataChange} />
    },
    { width: 80, title: '2 Icon', field: 'img2File', filtering: false, render: rowData => <RowImage rowData={rowData} field='img2File' />, editComponent: props => <RowImage rowData={props.rowData} field='img2File' /> },
    {
      width: 50, title: '', field: 'img3sel', filtering: false, headerStyle: { textAlign: 'center' }, render: rowData => <></>,
      editComponent: props => <ModalUpdateFile isUploadOri={true} fieldname='img3File' rowData={props.rowData} onRowDataChange={props.onRowDataChange} />
    },
    { width: 80, title: '3 後台背景', field: 'img3File', filtering: false, render: rowData => <RowImage rowData={rowData} field='img3File' />, editComponent: props => <RowImage rowData={props.rowData} field='img3File' /> },
    { width: 80, title: '公司縮寫', field: 'nameAbbr' },
    { width: 180, title: '系統網址', field: 'url' },
    { width: 80, title: '營業時間', field: 'opentime' },
    { width: 80, title: '財團法人', field: 'isConsortium', type: 'boolean' },
    { width: 80, title: '劃撥帳號', field: 'transferAccount' },
    // { width: 100, title: '登入背景色', field: 'loginBgColor' },
    { width: 100, title: '背景色', field: 'bgColor' },
    { width: 100, title: '背景Cover', field: 'bgCover', type: 'boolean' },
    // { width: 120, title: '公司名(英)', field: 'companyEngName' },
    // { width: 150, title: '公司帳號(英數)', field: 'companyCode' },
    // { width: 130, title: '公司抬頭', field: 'companyTitle' },
    // { width: 80, title: '電話', field: 'phoneNum' },
    // { width: 80, title: '傳真', field: 'fax' },
    // { width: 180, title: '地址', field: 'address' },
    // { width: 180, title: 'latitude', field: 'latitude', type: 'numeric' },
    // { width: 180, title: 'longitude', field: 'longitude', type: 'numeric' },
    // { width: 80, title: '寺廟類別', field: 'templeType', lookup: objTempleType },
    { width: 80, title: '陽上', field: 'liveLabel' },
    { width: 80, title: '超渡', field: 'passLabel' },
    { width: 80, title: '隱藏生日', field: 'hideBirth', type: 'boolean' },
    { width: 80, title: '隱藏班別', field: 'hideClass', type: 'boolean' },
    { width: 80, title: '隱藏財團法人', field: 'hideFoundation', type: 'boolean' },
    { width: 80, title: '隱藏往生堂', field: 'hideLotus', type: 'boolean' },
    { width: 80, title: '隱藏淨土堂', field: 'hideLotus2', type: 'boolean' },
    // { width: 80, title: '顯示生日', field: 'showBirth' , type: 'boolean' },
    // { width: 80, title: '顯示生日', field: 'showBirth' , type: 'boolean' },
    // { width: 180, title: '對外網站', field: 'website' },
    // { width: 180, title: '電子郵件', field: 'email' },
    // { width: 80, title: '註冊職稱', field: 'contactTitle' },
    // { width: 80, title: '註冊人', field: 'contactName' },
    // { width: 80, title: '註冊電話', field: 'contactPhone' },
    // { width: 80, title: '註冊Email', field: 'contactEmail' },
    // { width: 180, title: '職稱', field: 'contactTitle' },
    // { width: 180, title: '職稱', field: 'contactTitle' },
    // { width: 80, title: '顏色', field: 'colorHex' },
    // { width: 120, title: '_id', field: '_id' },
    { width: 120, title: 'id', field: 'id' },
    // { title: '測試ID', field: 'testcompanyId' },
    // { title: '順序', field: 'order' },
    // { title: '連結埠', field: 'port' },
    { width: 80, title: '法會年度', field: 'yearDef', },
    { width: 80, title: '法會字母', field: 'orderalphaDef', },
    { width: 80, title: '法會單號', field: 'ordernumDef' },
    {
      width: 80, title: '目前法會', field: 'pray1IdDef'
      // , lookup: pray1Sel, 
    },
    { width: 80, title: '合併法會', field: 'isPrayCombine', type: 'boolean' },
    { width: 80, title: '點燈年度', field: 'yearDef2', },
    { width: 80, title: '點燈字母', field: 'orderalphaDef2', },
    { width: 80, title: '點燈單號', field: 'ordernumDef2' },
  ]
};

class Companies extends Component {
  state = {
    newData: {},
    oldData: {},
    companyObj: {},
    data1: [],
    data2: [],
    data3: [],
    // sectorArr: [],
    // sectorSel: {},
    loading: true,
    selId: 0
  }

  async componentDidMount() {
    const data1 = await Parse.queryData('company');
    const data2 = await Parse.queryData('richMenuChannel');
    const data3 = await Parse.queryData('pray1');
    this.setState({ data1, data2, data3, loading: false });
  }

  handleAdd = async () => {
    const { newData } = this.state;
    console.log(newData);
    Parse.saveData('company', newData);
  }

  handleUpdate = async () => {
    const { newData } = this.state;

    Parse.saveData('company', {
      ...newData,
      hideClass: Number(!!newData.hideClass),
    });
  }

  handleDelete = async () => {
    const { oldData } = this.state
    Parse.deleteData('company', oldData);
    this.setState({ oldData: {} })
  }

  handleData = (rowData, selId) => {
    this.setState({ companyObj: rowData, selId });
  }

  render() {
    const { data1, data2, data3, companyObj, loading, selId } = this.state

    return (<div className="content">
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns({ richMenuChannel: data2, pray1: data3, data2 })}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '公司一覽'),
          pageSize: data1.length || 10,
          search: true,
          filtering: true,
          tableLayout: 'fixed',
          // fixedColumns: {
          //   left: 2,
          //   right: 0
          // }
        }}
        data={data1}
        title="公司一覽"
        actions={[
          {
            icon: 'people',
            tooltip: '使用者',
            onClick: (event, rowData) => this.handleData(rowData, 1)
          },
          // {
          //   icon: 'view_headline',
          //   tooltip: '設定',
          //   onClick: (event, rowData) => this.handleData(rowData, 2)
          // },
        ]}
        onRowClick={(e, rowData) => this.handleData(rowData, 1)}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const data = [...data1];
              const db = Parse.Object.extend("company");
              const newDoc = new db();
              const doc = await newDoc.save();
              // const id = Parse.generateObjectId()
              newData.objectId = doc.id;
              newData.id = doc.id;
              newData._id = doc.id;

              if (newData.webtypeId === 'temple') {
                newData.loginBgColor = '#f1eee4'
                newData.liveLabel = '陽上';
                newData.passLabel = '超渡';
              }

              data.push(newData);
              this.setState({ data1: data, newData }, () => resolve());
            }).then(() => this.handleAdd()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...data1];
              const index = data.indexOf(oldData);
              console.log(newData)
              data[index] = newData;
              this.setState({ data1: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...data1];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              this.setState({ data1: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
      <br />
      {companyObj && companyObj.objectId ? <>
        {selId === 1 ? <Users {...this.props} companyObj={companyObj} /> : null}
        {selId === 2 ? <System {...this.props} companyObj={companyObj} /> : null}
      </> : null}
      <br />
      <br />
      {/* <Company {...this.props} /> */}
    </div>);
  }
}

export default Companies;