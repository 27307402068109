import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie';

import configureStore from './store/index';
import App from './App';

import "assets/css/bootstrap.min.css";
// import "bootstrap/dist/css/bootstrap.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import './index.css';

const initialState = {
  user: {
    isAuthenticated: false,
    profile: {}
  }
};

const store = configureStore(initialState);
ReactDOM.render(<CookiesProvider><Provider store={store}><App /></Provider></CookiesProvider>, document.getElementById("root"));
