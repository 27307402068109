import React, { Component } from 'react'
import { Container, Dimmer, Loader, Image, Segment, Input, Select } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Moment from 'moment';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf, typeCurrency } from 'views/web/CustomMaterialTable';

const columns = () => ([
  dateCol({ title: '日期', field: 'eventDate' }),
  { title: '會計科目1', field: 'acc1Text' }, // 資料庫下拉式
  { title: '會計科目2', field: 'acc2Text' },
  { title: '科目細項', field: 'acc3Text' }, // 資料庫下拉式
  { title: '發票/收據號碼', field: 'invoiceNumber' },
  { title: '說明', field: 'title' },
  { title: '金額', field: 'price', ...typeCurrency },
  { title: '備註', field: 'ps' },
]);

export default class Fin1121 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bankaccShowArr: [],
      bankaccArr: [],
      dataArr: [],
      deleteConfirm: false,
      loading: true,
      bankaccSel: '',
      yearmonth: Moment(new Date()).format('YYYY-MM') || '',
    }
    this.tableRef = React.createRef();
  }

  async componentDidMount() {
    const { companyObj } = this.props;
    const companyId = companyObj.objectId;

    const bankacc = await Parse.queryData('bankacc', { companyId });
    const bankaccArr = bankacc.filter(item => item.value).map(item => ({
      key: item.objectId, text: `${item.value}`, value: item.objectId
    }));
    const bankaccSel = bankacc && bankacc.length ? bankacc[0].objectId : '';
    this.setState({ bankaccSel, bankaccArr, bankacc }, () => this.handleFetch())
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps, 0);
  }

  handleFetch = async (props = this.props, type = 1) => {
    const { companyObj } = props;
    const { yearmonth, bankaccSel } = this.state
    const [year, month] = yearmonth.split('-');
    const companyId = companyObj.objectId;
    if (type === 1) {
      const cashbook = await Parse.queryData('cashbook', { companyId, yearmonth: yearmonth }, { orderBy: 'eventDate' });
      const sortData = cashbook.sort((a, b) => a.eventDate && b.eventDate && new Date(a.eventDate.iso) - new Date(b.eventDate.iso))
      const bankaccCalArr = sortData.filter(item => item.bankaccId === bankaccSel && item.yearmonth === yearmonth);
      let total = 0
      let obj = {}
      bankaccCalArr.forEach(item => {
        if (item.acc1Id === 'o2oKwXbPjS') {
          total = total - Number(item.price || 0)
        } else {
          total = total + Number(item.price || 0)
        }
      })
      obj = {
        eventDate: new Date(year, month, 0).toISOString(),
        acc1Text: '結餘',
        price: total,
      }
      bankaccCalArr.push(obj)
      const bankaccShowArr = bankaccCalArr.map(item => ({ ...item, eventDate: Moment(new Date((item.eventDate && item.eventDate.iso) || item.eventDate)).format('YYYY-MM-DD') }))
      this.setState({ bankaccSel, loading: false, bankaccShowArr, cashbook, });
    }
  }

  handleChange = (event, { name, value }) => {
    // const { name, value } = data;
    const { cashbook, bankaccSel, yearmonth } = this.state
    this.setState({ loading: true })
    let bankaccCalArr = []
    let yearmonthArr = []
    if (name === 'yearmonth') {
      yearmonthArr = value.split('-');
      // bankaccCalArr = cashbook.filter(item => item.bankaccId === bankaccSel && item.yearmonth === value)
    } else if (name === 'bankaccSel') {
      yearmonthArr = yearmonth.split('-');
      // bankaccCalArr = cashbook.filter(item => item.bankaccId === value && item.yearmonth === yearmonth)
    }
    const [year, month] = yearmonthArr
    let total = 0
    let obj = {}
    // bankaccCalArr.forEach(item => {
    //   if (item.acc1Id === 'o2oKwXbPjS') {
    //     total = total - Number(item.price || 0)
    //   } else {
    //     total = total + Number(item.price || 0)
    //   }
    // })
    // obj = {
    //   eventDate: new Date(year, month, 0).toISOString(),
    //   acc1Text: '結餘',
    //   price: total,
    // }
    // bankaccCalArr.push(obj)
    // const bankaccShowArr = bankaccCalArr.map(item => ({ ...item, eventDate: Moment(new Date((item.eventDate && item.eventDate.iso) || item.eventDate)).format('YYYY-MM-DD') }))
    // let pageSize = { target: { value: bankaccShowArr.length } }
    // this.tableRef.current.onChangeRowsPerPage(pageSize)
    this.setState({ [name]: value, loading: false }
      , () => this.handleFetch()
    );
  }

  render() {
    const { loading, bankaccArr, bankaccSel, yearmonth, bankaccShowArr } = this.state;
    const [year, month] = yearmonth.split('-');

    return (<>
      {/* 零用金及銀行帳戶明細表 */}
      < br />
      <Input
        type="month"
        name="yearmonth"
        value={yearmonth}
        onChange={this.handleChange}
      />

      <Select style={{
        position: 'relative',
        float: 'right',
        minWidth: '200px',
        zIndex: 10000,
      }}
        compact
        name="bankaccSel"
        options={bankaccArr}
        value={bankaccSel}
        onChange={this.handleChange}
      />
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${year} 年 ${month} 月零用金及銀行帳戶明細表`),
          pageSize: bankaccShowArr.length || 10,
          search: false
        }}
        tableRef={this.tableRef}
        data={bankaccShowArr}
        title={`${year} 年 ${month} 月零用金及銀行帳戶明細表`}
      />
    </>)
  }
}