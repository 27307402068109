import React, { Component } from 'react';
import { Grid, Form, Button, TextArea, Table } from 'semantic-ui-react';
import ModalPdf from 'widget/pdf/ModalPdf';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import Parse from '../../../widget/parse'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// import Moment from 'moment';
// import { isEventInBetween } from '../../define/calendar';
// import { cellShow, weekdayArrCN, periodArrCN } from 'views/widgets/FieldsRender';

const weekAndDay = (date) => {
  return Math.ceil(date.getDate() / 7);// + ' ' + days[date.getDay()];
}

const arrFields = [
  { title: '授業內容', type: 'scheduledProgress' },
  { title: '進度連絡', type: 'actualProgress' },
  { title: '講師コメント', type: 'comment1' },
  // { title: '担任コメント', type: 'comment2' },
  // { title: '主任コメント', type: 'comment3' },
  // { title: '学院長コメント', type: 'comment4' },
  { title: '備考', type: 'ps' },
];

const TAStyle = {
  height: 200,
  minWidth: '100%',
  border: '1px solid rgba(34,36,38,.15)',
  borderRadius: '.3rem',
  outline: 0,
  padding: '.8em 1em',
  lineHeight: 1.28
}
export default class ClassProgression extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selStudentObj: '',
      selCourseName: '',
      selWeekday: '',
      selPeriod: '',
      selDate: '',
      showModal: false,
      rollcallArr: [],
      unsub: null,
      doc: {},
      index: 0,
      isShowingModal: false,
      form: {
        classroomId: props.classId || '',
        semesterYear: props.semesterYear,
        semester: props.semester,
      },
      progressArr: []
    };
  }

  async componentDidMount() {
    // console.log('componentDidMount');
    const { firebase, weekday, period, eventDate, classId } = this.props
    const { currentSubscription } = this.state
    const query = new Parse.Query('Progress');
    query.equalTo('classroomId', classId)
    query.equalTo('objectId', `${eventDate}_${period === '4' ? '56' : '78'}`)
    query.limit(10000);
    const snap = await query.find();
    const doc = (snap.length && snap[0].toJSON()) || {};
    this.setState({ doc })
    if (currentSubscription) {
      // console.log('currentSubscription.unsubscribe')
      await currentSubscription.unsubscribe();
      this.setState({ currentSubscription: null })
    }
    let subscription = await query.subscribe().catch(error => console.error(error));
    subscription.on('create', (data) => {
      const doc = [...this.state.doc, data.toJSON()];
      this.setState({ doc })
    });
    subscription.on('update', (data) => {
      const doc = [...this.state.doc, data.toJSON()];
      this.setState({ doc })
    });
    subscription.on('delete', (data) => {
      const doc = [...this.state.doc, data.toJSON()];
      this.setState({ doc })
    });
    this.setState({ currentSubscription: subscription })

    // if (classId && eventDate && period) {
    //   if (this.state.unsub) {
    //     this.state.unsub();
    //     //  console.log('unsub worked!')
    //   }
    //   const unsub = firebase.firestore().collection("Classroom")
    //     .doc(classId)
    //     .collection("Progress")
    //     .doc(`${eventDate}_${period === '4' ? '56' : '78'}`)
    //     .onSnapshot(snap => {
    //       const doc = snap.data() || {};
    //       console.log(doc)
    //       this.setState({ doc });
    //     }, err => {
    //       console.log(`Encountered error: ${err}`);
    //     });
    //   this.setState({ unsub });
    // }
  }

  async componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    const { firebase, weekday, period, eventDate, classId } = nextProps;
    const { currentSubscription } = this.state
    if (classId && eventDate && period) {
      const query = new Parse.Query('Progress');
      query.equalTo('classroomId', classId)
      query.equalTo('objectId', `${eventDate}_${period === '4' ? '56' : '78'}`)
      query.limit(10000);
      const snap = await query.find();
      const doc = (snap.length && snap[0].toJSON()) || {};
      this.setState({ doc })
      if (currentSubscription) {
        // console.log('currentSubscription.unsubscribe')
        await currentSubscription.unsubscribe();
        this.setState({ currentSubscription: null })
      }
      let subscription = await query.subscribe().catch(error => console.error(error));
      subscription.on('create', (data) => {
        const doc = [...this.state.doc, data.toJSON()];
        this.setState({ doc })
      });
      subscription.on('update', (data) => {
        const doc = [...this.state.doc, data.toJSON()];
        this.setState({ doc })
      });
      subscription.on('delete', (data) => {
        const doc = [...this.state.doc, data.toJSON()];
        this.setState({ doc })
      });
      this.setState({ currentSubscription: subscription })

      // if (this.state.unsub) {
      //   this.state.unsub();
      //   //  console.log('unsub worked!')
      // }
      // const unsub = firebase.firestore().collection("Classroom")
      //   .doc(classId)
      //   .collection("Progress")
      //   .doc(`${eventDate}_${period === '4' ? '56' : '78'}`)
      //   .onSnapshot(snap => {
      //     const doc = snap.data() || {};
      //     console.log(doc)
      //     this.setState({ doc });
      //   }, err => {
      //     console.log(`Encountered error: ${err}`);
      //   });
      // this.setState({ unsub });
    }
    if (nextProps.classObj.objectId !== this.props.classObj.objectId) {
      this.setState({
        selStudentObj: '',
        selCourseName: '',
        selWeekday: '',
        selPeriod: '',
        selDate: '',
        showModal: false,
      });
    }

    const { rollcallArr0 = [], rollcallArr1 = [], rollcallArr2 = [], rollcallArr3 = [], rollcallArr4 = [] } = nextProps;

    const arr = [
      ...rollcallArr0,
      ...rollcallArr1,
      ...rollcallArr2,
      ...rollcallArr3,
      ...rollcallArr4
    ];
    this.setState({ progressArr: arr })
  }

  componentWillUnmount() {
    // document.oncontextmenu = function () {
    //   return true;
    // }
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    const { form } = this.state
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      }
    });
    console.log(form)
  };

  handleClose = () => {
    const { classId, semesterYear, semester } = this.props;
    this.setState({
      isShowingModal: false,
      form: {
        classroomId: classId,
        semesterYear,
        semester,
      },
    });
  };

  handleSubmit = () => {
    const { firebase } = this.props;
    const { form } = this.state;

    Object.keys(form).forEach(key => form[key] === undefined ? delete form[key] : '');
    // /Classroom/iAfLdrL54ihfRLn9N/Progress/2019-05-08_78
    if (!form || !form.classroomId || !form.eventDate || !form.period) {
      return;
    }
    console.log(form);

    firebase.firestore().doc(`Classroom/${form.classroomId}/Progress/${form.eventDate}_${form.period === '4' ? '56' : '78'}`)
      .set(form)

    this.handleClose();
  };
  handleClickComment = (eventDate, obj, index, weekday, period) => {
    // console.log(obj)
    const { progressArr } = this.state
    const { classObj, semester, semesterYear } = this.props

    const type = arrFields[index].type;
    const title = arrFields[index].title;

    const form = obj && obj.classroomId ?
      {
        ...obj,
        eventDate,
        semester,
        semesterYear,
        teacher: classObj.teacher,
        teacherText: classObj.teacherText
      } : {
        classroomId: classObj.objectId,
        eventDate,
        period: period,
        semester: classObj.semester,
        semesterYear: classObj.semesterYear,
        weekday: weekday,
        teacher: classObj.teacher,
        teacherText: classObj.teacherText
      }
    this.setState({ isShowingModal: true, progressArr, title, type, form });
  }

  render() {
    const { optSet, classObj, year, week, history, calendar, mode, weekDateRange, weekDateRange2, weekday, period, eventDate, rollcallArr2 } = this.props;
    const {
      // selStudentObj, selCourseName, selWeekday, selPeriod, selDate, showModal, HourAttendance, 
      progressArr, isShowingModal, form, title, type, doc } = this.state;
    const weekdayArr = [1, 2, 3, 4, 5];                                                // sun: 0, mon: 1, tues: 2, wed: 3, thurs: 4, friday: 5, sat: 6
    const headerArr = classObj.classTime === '1' ? [1, 2, 3, 4] : [5, 6, 7, 8];          // 午前顯示: [1, 2, 3, 4], 午後顯示[5, 6, 7, 8]
    // const periodArr = headerArr.slice().map(v => v - 1);                               // 午前: [0, 1, 2, 3], 午後: [4, 5, 6, 7]

    const month = weekDateRange[0].getMonth() + 1;

    // const showDevelop = true;// Roles.userIsInRole(userId, ['admin', 'developer']);

    // console.log(progressArr)
    const arrPdf = [{
      weekDateRange,
      classObj,
      year,
      week,
      progressArr,
      period
    }]

    return (<>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p style={{ fontSize: '18px' }}>
          {classObj.classSchool} CLASS {year}年{month}月 第{weekAndDay(weekDateRange[0])}週 {`${month}月${weekDateRange[0].getDate()}日`} ~ {`${weekDateRange[4].getMonth() + 1}月${weekDateRange[4].getDate()}日`} 担任: {classObj.teacherText}
        </p>
        <div>
          <ModalPdf
            {...this.props}
            // ref={(ref) => { this.pdfModal = ref }}
            codekey="classProgressionPdf"
            params={arrPdf}
          />
          <ExportTableToExcelButton floated='right' size='small' />
        </div>
      </div>
      {year && week ? <Table size="small" id='excel' celled structured style={{ textAlign: 'center', padding: '2px' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell rowSpan={2} ></Table.HeaderCell>
            <Table.HeaderCell colSpan={4}>{weekDateRange[0].getDate()}日 ㈪</Table.HeaderCell>
            <Table.HeaderCell colSpan={4}>{weekDateRange[1].getDate()}日 ㈫</Table.HeaderCell>
            <Table.HeaderCell colSpan={4}>{weekDateRange[2].getDate()}日 ㈬</Table.HeaderCell>
            <Table.HeaderCell colSpan={4}>{weekDateRange[3].getDate()}日 ㈭</Table.HeaderCell>
            <Table.HeaderCell colSpan={4}>{weekDateRange[4].getDate()}日 ㈮</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            {weekdayArr.map((item, index) =>
              <React.Fragment key={`${item}_${index}`}>
                <Table.HeaderCell>{headerArr[0]}</Table.HeaderCell>
                <Table.HeaderCell>{headerArr[1]}</Table.HeaderCell>
                <Table.HeaderCell>{headerArr[2]}</Table.HeaderCell>
                <Table.HeaderCell>{headerArr[3]}</Table.HeaderCell>
              </React.Fragment>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {arrFields.map(({ title, type }, index) => (
            <Table.Row key={"aa" + index}>
              <Table.Cell>{title}</Table.Cell>
              {weekdayArr.map((week, weekIndex) => {
                const weekday = (week - 1) + '';
                // console.log(title, type, weekday, period, progressArr);
                const obj12 = progressArr.find(obj => obj.weekday === weekday && obj.period === period);
                const period2 = (Number(period) + 2) + '';
                const obj34 = progressArr.find(obj => obj.weekday === weekday && obj.period === period2);

                // console.log(obj12);
                // console.log(obj34);

                return <React.Fragment key={'bb' + index + week}>
                  <Table.Cell
                    style={{ cursor: 'pointer', wordBreak: 'break-word' }}
                    onClick={() => this.handleClickComment(weekDateRange2[weekIndex], obj12, index, weekday, period)}
                    colSpan='2'
                  >
                    {obj12 && obj12[type]}
                  </Table.Cell>
                  <Table.Cell
                    style={{ cursor: 'pointer', wordBreak: 'break-word' }}
                    onClick={() => this.handleClickComment(weekDateRange2[weekIndex], obj34, index, weekday, period2)}
                    colSpan='2'
                  >
                    {obj34 && obj34[type]}
                  </Table.Cell>
                </React.Fragment>
              }
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table> : null
      }
      <Modal isOpen={isShowingModal} onClose={() => this.handleClose()} size="large" >
        <ModalHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{title}</div>
          <div style={{ fontSize: '1.2rem', fontWeight: '400' }}>
            <span>{form.eventDate}</span>
            <span style={{ marginLeft: 10 }}>担任: {classObj.teacherText}</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <Form.Group controlid="formControlsTextarea">
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <TextArea
                    style={TAStyle}
                    placeholder="入力してください"
                    name={type}
                    onChange={this.handleChange}
                    value={form[type]}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form.Group>
        </ModalBody>
        <ModalBody style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <br />
          <Button color='green' onClick={() => this.handleSubmit()} icon='save' content='保存' />
          <Button onClick={() => this.handleClose()} icon='close' content='キャンセル' />
        </ModalBody>
      </Modal>
    </>)
  }
}