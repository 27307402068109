
import React, { Component } from 'react'
import { Container, Header, Grid, Dimmer, Loader, Image, Segment } from 'semantic-ui-react';
import MaterialTable from 'material-table'

import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

const columns = () => ([
  // { title: '預設', field: 'now_use' },
  { title: '宮名', field: 'templeText' },
  { title: '主神', field: 'mainGod' },
  { title: '聯絡人', field: 'contactText' },
  { title: '聯絡電話', field: 'contactTel' },
  { title: '地址', field: 'addr' },
]);

const columns2 = () => ([
  // { title: '預設', field: 'now_use' },
  { title: '項目', field: 'item_Text' },
  { title: '日期', field: 'date' },
  { title: '禮金', field: 'giftGold' },
  { title: '備註', field: 'PS' },
]);

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

class FriendTemple extends Component {
  state = {
    friend: [],
    friend2: [],
    data1: [],
    data2: [],
    sel1: '',
    sel2: '',
    title1: '',
    title2: '',
    loading: false,
    obj: {},
  }

  render() {
    const { data1, data2, sel1, loading } = this.state;

    return (<>
      <div className="content">
        <div style={{ ...style.flexCenter, margin: '2rem auto', position: 'relative', zIndex: 100 }}>
          {/* <Header as='h2' style={{ margin: '2rem 0' }}>友宮</Header> */}
        </div>
        <br />
        <Grid style={{ margin: '2rem auto' }}>
          <Grid.Row>
            <Grid.Column width={10} computer={10} tablet={10} mobile={16}>
              <MaterialTable
                isLoading={loading}
                localization={localization()}
                columns={columns()}
                options={{
                  addRowPosition: "first",
                  exportButton: true,
                  exportPdf: (columns, data) => exportPdf(columns, data, '宮名'),
                  pageSize: data1.length || 5,
                  search: false
                }}
                data={data1}
                title="宮名"
                actions={[
                  {
                    icon: 'view_headline',
                    tooltip: '查看',
                    onClick: (event, rowData) => this.handleData("1", rowData)
                  }
                ]}
                onRowClick={(event, rowData) => this.handleData("1", rowData)}
                editable={{
                  onRowAdd: newData1 =>
                    new Promise((resolve, reject) => {
                      // console.log(data)
                      // const db = firebase.firestore();
                      // const docRef = db.collection(`company/${companyObj._id}/friend`).doc();
                      const data = [...data1];
                      const obj = {
                        // _id: docRef.id,
                        // id: docRef.id,
                        // companyId: companyObj.id,
                        // companyName: companyObj.name,
                        // product1_id: sel1,
                        // product1_text: (friend.find(item => item._id === sel1) && friend.find(item => item._id === sel1).value) || '',
                        // ...newData1
                      }
                      data.push(obj);

                      this.setState({ data1: data, newData1: obj }, () => resolve());
                    }).then(() => this.handleAdd("1")),
                  onRowUpdate: (newData1, oldData1) =>
                    new Promise((resolve, reject) => {
                      const data = [...data1];
                      const index = data.indexOf(oldData1);
                      data[index] = newData1;
                      // console.log(data1)
                      this.setState({ data1: data, newData1, oldData1 }, () => resolve());
                    }).then(() => this.handleUpdate("1")),
                  onRowDelete: oldData1 =>
                    new Promise((resolve, reject) => {
                      let data = [...data1];
                      const index = data.indexOf(oldData1);
                      data.splice(index, 1);
                      this.setState({ data1: data, oldData1 }, () => resolve());
                    }).then(() => this.handleDelete("1")),
                }}
              />
            </Grid.Column>
            <Grid.Column width={6} computer={6} tablet={6} mobile={16}>
              {sel1 ?
                <MaterialTable
                  localization={localization()}
                  columns={columns2()}
                  options={{
                    addRowPosition: "first",
                    exportButton: true,
                    exportPdf: (columns, data) => exportPdf(columns, data, '禮金簿'),
                    pageSize: data2.length || 5,
                    search: false
                  }}
                  // onColumnDragged={}
                  data={data2}
                  title={"禮金簿"}
                  actions={[
                    {
                      icon: 'view_headline',
                      tooltip: '查看',
                      onClick: (event, rowData) => this.handleData("2", rowData)
                    }
                  ]}
                  onRowClick={(event, rowData) => this.handleData("2", rowData)}
                  editable={{
                    onRowAdd: newData2 =>
                      new Promise((resolve, reject) => {
                        // const db = firebase.firestore();
                        // const docRef = db.collection(`company/${companyObj._id}/friend`).doc();
                        const data = [...data2];

                        const obj = {
                          // _id: docRef.id,
                          // id: docRef.id,
                          // companyId: companyObj.id,
                          // companyName: companyObj.name,
                          // friendId: sel1,
                          // friendText: (friend.find(item => item._id === sel1) && friend.find(item => item._id === sel1).value) || '',
                          // ...newData2
                        }
                        data.push(obj);

                        this.setState({ data2: data, newData2: obj }, () => resolve());
                      }).then(() => this.handleAdd("2")),
                    onRowUpdate: (newData2, oldData2) =>
                      new Promise((resolve, reject) => {
                        const data = [...data2];
                        const index = data.indexOf(oldData2);
                        data[index] = newData2;
                        // console.log(data1)
                        this.setState({ data2: data, newData2, oldData2 }, () => resolve());
                      }).then(() => this.handleUpdate("2")),
                    onRowDelete: oldData2 =>
                      new Promise((resolve, reject) => {
                        let data = [...data2];
                        const index = data.indexOf(oldData2);
                        data.splice(index, 1);
                        this.setState({ data2: data, oldData2 }, () => resolve());
                      }).then(() => this.handleDelete("2")),
                  }}
                /> : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <br />
      </div>
    </>);
  }
}

export default FriendTemple;
