import React, { Component } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Button } from 'semantic-ui-react';

const grid = 8;
const getItemStyle = (isDragging, draggableStyle, isFolder) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  color: '#f5f5f5', // 字的顏色

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : isFolder ? '#3e5067' : '#85a9d8', // 背景顏色 拉的時候 目録 一般

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: 6, //grid,
  width: 250,
  flexGrow: 1,
  minHeight: '100px',
  // overflow: 'auto',
});

export default class BeautifulDnd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      showCmp: false,
      showAllCmp: false,
    };
  }

  handleOpen = (open, setting = {}, parentFolderObj = {}) => {
    // console.log(setting);
    this.props.handleOpen(open, setting, parentFolderObj);
  }

  render() {
    const { props } = this;
    const { formSet, level, parentFolderObj } = this.props;

    let arr = [];
    if (level === 0) { // arr 同層下的東西
      arr = formSet.filter(item => item.level === 0).sort((a, b) => a.orderId - b.orderId);
    } else if (parentFolderObj && parentFolderObj._id) {
      arr = formSet.filter(item => item.parentFolderId === props.parentFolderObj._id).sort((a, b) => a.orderId - b.orderId);
    }

    return (<>
      <div style={{ padding: '0 2px 2px 2px', display: 'block' }} >
        <p style={{ overflow: 'auto' }} >Level: {level + 1} {(parentFolderObj && parentFolderObj.label) || '根目録'}</p>
        <Droppable droppableId={(parentFolderObj && parentFolderObj._id ? parentFolderObj._id : 'root')}>
          {(provided, snapshot) => (<div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
            {arr.length ? <>{arr.sort((a, b) => a.orderId - b.orderId).map((item, index) => (
              <Draggable key={item._id} draggableId={item._id} index={index}>
                {item.type === 'folder' ? (provided, snapshot) => (
                  <div
                    onClick={() => this.setState({ title: item.content, parentFolderObj: item, showCmp: true })}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                      item.type === 'folder'
                    )}
                  >
                    {/* {item.orderId + 1} [目録] {item.key} */}
                    {item.orderId + 1} {item.key}
                    <Button size="tiny" floated='right' onClick={() => this.handleOpen(true, item, parentFolderObj)} icon='edit' />
                    <br /> {item.label} {item.value ? <><br />{item.value}</> : null}
                  </div>
                ) : (provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                      item.type === 'folder'
                    )}
                  >
                    {item.orderId + 1} [{item.type}] {item.key}
                    <Button size="tiny" floated='right' onClick={() => this.handleOpen(true, item, parentFolderObj)} icon='edit' />
                    <br />{item.label} : {item.value || "(空白)"}
                  </div>
                )}
              </Draggable>
            ))}
            </> : <>尚無項目</>}
            {provided.placeholder}
          </div>)}
        </Droppable>
        <br />
        <Button type="button" size="tiny" primary onClick={() =>
          this.handleOpen(true, { level, parentFolderId: parentFolderObj && parentFolderObj._id ? parentFolderObj._id : '' }, parentFolderObj)} icon='plus' />
        {this.state.showAllCmp ?
          <Button type="button" floated='right' size="tiny" basic color='red' onClick={() => this.setState({ showAllCmp: false })} icon='window close' /> :
          <Button type="button" floated='right' size="tiny" positive onClick={() => this.setState({ showAllCmp: true })} icon='sitemap' />
        }
        {this.state.showCmp ? <Button floated='right' type="button" size="tiny" basic color='red' onClick={() => this.setState({ showCmp: false })} icon='window close outline' /> : ''}&nbsp;
        </div>
      {this.state.showAllCmp && arr.length ? <>{arr.sort((a, b) => a.orderId - b.orderId).map((item, index) => ( // 全部的孩子
        <BeautifulDnd
          {...this.props}
          key={index}
          level={props.level + 1}
          parentFolderObj={item}
        />
      ))}</> : null}
      {this.state.showCmp && this.state.parentFolderObj._id ? // 單一的
        <BeautifulDnd
          {...this.props}
          key={'single_' + this.state.parentFolderObj._id}
          level={props.level + 1}
          parentFolderObj={this.state.parentFolderObj}
        /> : null}
    </>);
  }
}