import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

const columns = () => ([
  { title: '檔案名稱', field: 'name' },
  { title: '預覽 ', field: 'url', render: rowData => <a href={rowData.url} target='_blank' rel="noopener noreferrer">{rowData.name}</a>, editable: "never" },
  { title: '備註', field: 'remark' },
]);

export default class Hr1 extends Component {
  state = {
    dataArr: [],
    filenames: [],
    downloadURLs: [],
    docs: [],
    fileArr: [],
    fileArrCheck: [],
    show: false,
    loading: true,
    obj: {}
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj } = props;

    const companyId = companyObj.objectId;
    const data = await Parse.queryData('hrform_management', { companyId });
    this.setState({ dataArr: data, loading: false });
  }

  handleUpdate = async () => {
    const { newData } = this.state
    await Parse.saveData('hrform_management', newData);
  }

  render() {
    const { dataArr, loading } = this.state;

    return (<div className='content'>
      {/* <Container> */}
      {/* <div style={{ ...style.flexCenter, position: 'relative', zIndex: 100 }}>
          <Header as='h2' style={{ margin: '2rem 0' }}>4-1 人事規章</Header>
        </div> */}
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '人事規章'),
          pageSize: dataArr.length || 10,
          search: false
        }}
        data={dataArr}
        title="人事規章"
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...dataArr];
              const index = data.indexOf(oldData);
              data[index] = newData;
              // handleState('people0', data);
              this.setState({ dataArr: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate(0)),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...dataArr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              // handleState('people0', data);
              this.setState({ dataArr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete(0)),
        }}
      />
      <br />
      <br />
    </div>)
  }
}