/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function SectionProject(props = {}) {
  const { form } = props;
  //  console.log(form)
  const { text2 = 'text2', text3 = 'text3', text4 = 'text4', text5 = 'text5', text6 = 'text6',
    text7 = 'text7', text8 = 'text8', text9 = 'text9', text10 = 'text10', text11 = 'text11', text12 = 'text12',
    text13 = 'text13', text14 = 'text14', text15 = 'text15', text16 = 'text16', text17 = 'text17',
    text18 = 'text18', text19 = 'text19', text20 = 'text20' } = form;
  const [activePill, setActivePill] = React.useState("1");
  return (
    <>
      <div className="section section-project cd-section" id="projects">
        {/* ********* PROJECTS 2 ********* */}
        <div className="projects-2 section section-dark">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h6 className="text-muted">{text2}</h6>
                <h2 className="title">{text3}</h2>
                <h5 className="description">
                  {text4}
                </h5>
              </Col>
            </Row>
            <div className="space-top" />
            <Row>
              <Col md="4">
                <Card className="card-plain">
                  <CardImg top tag="div">
                    <a
                      href="http://www.creative-tim.com/product/paper-kit-react?ref=pkpr-sections-page"
                      target="_blank"
                    >
                      <img
                        alt="..."
                        className="img"
                        src={require("assets/img/sections/opt_pk_react_thumbnail.jpg")}
                      />
                    </a>
                  </CardImg>
                  <CardBody>
                    <a
                      href="http://www.creative-tim.com/product/paper-kit-react?ref=pkpr-sections-page"
                      target="_blank"
                    >
                      <CardTitle tag="h4">{text5}</CardTitle>
                      <br />
                    </a>
                    <h6 className="card-category text-muted">{text6}</h6>
                    <p className="card-description">
                      {text7}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-plain">
                  <CardImg top tag="div">
                    <a
                      href="https://www.creative-tim.com/product/argon-dashboard-pro-react?ref=pkpr-sections-page"
                      target="_blank"
                    >
                      <img
                        alt="..."
                        className="img"
                        src={require("assets/img/sections/argon-dashboard-pro-react.jpg")}
                      />
                    </a>
                  </CardImg>
                  <CardBody>
                    <a
                      href="https://www.creative-tim.com/product/argon-dashboard-pro-react?ref=pkpr-sections-page"
                      target="_blank"
                    >
                      <CardTitle tag="h4">{text8}</CardTitle>
                      <br />
                    </a>
                    <h6 className="card-category text-muted">
                      {text9}
                    </h6>
                    <p className="card-description">
                      {text10}
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-plain">
                  <CardImg top tag="div">
                    <a
                      href="https://www.creative-tim.com/product/blk-design-system-pro-react?ref=pkpr-sections-page"
                      target="_blank"
                    >
                      <img
                        alt="..."
                        className="img"
                        src={require("assets/img/sections/blk-design-system-pro-react.jpg")}
                      />
                    </a>
                  </CardImg>
                  <CardBody>
                    <a
                      href="https://www.creative-tim.com/product/blk-design-system-pro-react?ref=pkpr-sections-page"
                      target="_blank"
                    >
                      <CardTitle tag="h4">
                        {text11}
                      </CardTitle>
                    </a>
                    <h6 className="card-category text-muted">{text12}</h6>
                    <p className="card-description">
                      {text13}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END PROJECTS 2 ********* */}
      </div>
    </>
  );
}

export default SectionProject;
