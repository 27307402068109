import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

const columns = () => ([
  { title: '檔案名稱', field: 'name' },
  { title: '預覽 ', field: 'url', render: rowData => <a href={rowData.url} target='_blank'>{rowData.name}</a>, editable: "never" },
  { title: '備註', field: 'remark' },
]);

export default class Hr2 extends Component {
  state = {
    dataArr: [],
    filenames: [],
    downloadURLs: [],
    docs: [],
    fileArr: [],
    fileArrCheck: [],
    show: false,
    loading: true,
    obj: {}
  }

  handleUpdate = async () => {
    const { companyObj } = this.props
    const { newData } = this.state
    // const db = firebase.firestore()
    // const docRef = db.doc(`company/${companyObj._id}/bc/${newData.id}`)
    // const obj = { ...newData };
    // await docRef.update(obj);
    await Parse.saveData('hrform_management', newData);
    // this.setState({ oldData: {}, newData: {} })
  }
  // handleUpdate = async () => {
  //   const {_id } = this.props;
  //   const { newData1 } = this.state;
  //   const db = firebase.firestore();

  //   const docRef = db.collection(`company/${companyObj._id}/hrform_management/${_id}`).doc(newData1.id);
  //   await docRef.set({ oldData1: {}, newData1: {} });
  // }

  handleDelete = async () => {
    // const { companyObj } = this.props
    // const { oldData } = this.state
    // const db = firebase.firestore();
    // if (oldData.fakefilename) {
    //   await firebase.storage().ref("bc").child(oldData.fakefilename).delete();
    // }
    // await db.collection(`company/${companyObj._id}/bc`).doc(oldData.id).delete();
    this.setState({ oldData: {} })
  }

  // handleData = (id, rowData) => {
  //   const { acc1, acc2, acc3 } = this.state;
  //   const sel = rowData.id;
  //   if (id === "1") {
  //     this.setState({ sel1: sel, sel2: '', sel3: '', sel4: '', title1: rowData.value, data2: acc2.filter(item => item.acc1Id === sel) })
  //   } else if (id === "2") {
  //     this.setState({ sel2: sel, sel3: '', sel4: '', title2: rowData.value, data3: acc3.filter(item => item.acc2Id === sel) })
  //   } else if (id === "3") {
  //     this.setState({ sel3: sel, sel4: '', title3: rowData.value, obj: rowData })
  //   }
  // }

  // componentDidMount() {
  //   const { hrform_management } = this.props
  //   this.setState({ dataArr: hrform_management })
  // }
  // showModal = (e) => {
  //   e.preventDefault()
  //   this.setState({ show: true })
  // }

  handleUploadStart = () => this.setState({ isUploading: true, });

  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = async filename => {
    const { match, doc, companyObj } = this.props;
    const { listType, clientId } = match.params;
    const { fileArr, loading, fileArrCheck, dataArr } = this.state
    // const downloadURL = this.props.firebase
    // // console.log(fileArr)
    // const downloadURLFunc = async filename => {
    /*
  const db = firebase.firestore();
  const downloadURL = await this.props.firebase
    .storage()
    .ref("bc")
    .child(filename)
    .getDownloadURL();
  // return downloadURL
  // }
  let nowFileObj = fileArr.find(item => (item.fakefilename === filename))
  // fileArr.forEach(item => {
  //   // console.log(item)
  // const downloadURL = downloadURLFunc(item.fakefilename)
  // const downloadURL = await this.props.firebase
  //   .storage()
  //   .ref("files")
  //   .child(item.filename)
  //   .getDownloadURL();
  let ref = db.collection(`company/${companyObj._id}/bc`).doc();
  const insertAt = new Date()
  nowFileObj = {
    _id: ref.id,
    id: ref.id,
    ...nowFileObj,
    insertAt,
    url: downloadURL
  }
  await ref.set(nowFileObj);
  dataArr.push(nowFileObj)
  // const docs = this.state.docs;
  // docs.push(nowFileObj);

  let data = fileArrCheck
  const index = data.indexOf(filename);
  data.splice(index, 1);
  // console.log(data.length)
  if (data.length === 0) {
    this.setState({ loading: false })
  } else {
    this.setState({ fileArrCheck: data })
  }

  this.setState(oldState => ({
    filenames: [...oldState.filenames, filename],
    downloadURLs: [...oldState.downloadURLs, downloadURL],
    // uploadProgress: 100,
    dataArr,
    isUploading: false,
    // docs
  })
  );
  */
  };

  render() {
    const { firebase } = this.props;
    const { dataArr, show, loading } = this.state;
    const { docs, fileArr, fileArrCheck } = this.state
    return (<>
      <div className='content'>
        {/* <Container> */}
        {/* <div style={{ ...style.flexCenter, position: 'relative', zIndex: 100 }}>
          <Header as='h2' style={{ margin: '2rem 0' }}>4-2 空白表單</Header>
        </div> */}
        <br />
        <br />
        <MaterialTable
          isLoading={loading}
          // components={{
          //   Toolbar: props => (<div>
          //     <div style={{ width: '210px', float: 'right', textAlign: 'right', padding: '15px' }}>
          //       <Button as='label'
          //         color='orange'
          //         size='small'
          //         content={<>選擇檔案</>}
          //         labelPosition="left"
          //         icon={<Icon name='arrow alternate circle up' />}
          //       />
          //     </div>
          //     <MTableToolbar {...props} />
          //     <div style={{ clear: 'both' }}></div>
          //   </div>),
          // }}
          localization={localization()}
          columns={columns()}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportAllData: true,
            exportPdf: (columns, data) => exportPdf(columns, data, '空白表單'),
            pageSize: dataArr.length || 10,
            search: false
          }}
          data={dataArr}
          title="空白表單"
          editable={{
            // onRowAdd: newData =>
            //   new Promise((resolve, reject) => {
            //     const db = firebase.firestore();
            //     const docRef = db.collection(`company/${companyObj._id}/hrform_management`).doc();
            //     const data = [...dataArr];
            //     const obj = {
            //       _id: docRef.id,
            //       id: docRef.id,
            //       ...newData
            //     }
            //     data.push(obj);
            //     // handleState('people0', data);
            //     this.setState({ dataArr: data, newData: obj }, () => resolve());
            //   }).then(() => this.handleAdd()),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const data = [...dataArr];
                const index = data.indexOf(oldData);
                data[index] = newData;
                // handleState('people0', data);
                this.setState({ dataArr: data, newData, oldData }, () => resolve());
              }).then(() => this.handleUpdate(0)),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                let data = [...dataArr];
                const index = data.indexOf(oldData);
                data.splice(index, 1);
                // handleState('people0', data);
                this.setState({ dataArr: data, oldData }, () => resolve());
              }).then(() => this.handleDelete(0)),
          }}
        />
        <br />
        <br />
        {/* </Container> */}
        {/* <Modal open={show} size='large' centered={false}>
        <Modal.Header style={{ ...style.flexCenter }}>
          <Header as='h3' content='預覽文件' style={{ margin: 0 }} />
          <Icon name='close' style={{ cursor: 'pointer' }} onClick={() => this.setState({ show: false })} />
        </Modal.Header>
        <Modal.Content></Modal.Content>
        <Modal.Actions>
          <Button content='關閉' onClick={() => this.setState({ show: false })} />
        </Modal.Actions>
      </Modal> */}
      </div>
    </>)
  }
}