import React, { Component } from 'react'
import { Button, Grid, Form } from 'semantic-ui-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MaterialTable from 'material-table'
import { Select, MenuItem } from '@material-ui/core';
// import Moment from 'moment';
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

import ModalPraying2 from './ModalPraying2'
import ModalPdf from 'widget/pdf/ModalPdf';
import { DimmerImg } from '../../layouts/Dimmer';
import ModalReceivable from './ModalReceivable';
import Parse, { funcPad } from '../../widget/parse'
import localization from 'widget/MaterialTableOpt'

const columns = (people1Sel = {}, mgyearSel = {}, mgyearDefault = '') => [
  // { title: '預設', field: 'now_use' },
  { title: '年度', field: 'year', lookup: mgyearSel, initialEditValue: mgyearDefault },
  { title: '儲值點數', field: 'point' },
  { title: '已收金額', field: 'receiveNum' },
  { title: '付款人', field: 'people', lookup: people1Sel, initialEditValue: '-1' },
  // { title: '收取日期', field: 'payDate' },
  // { title: '欄 數量', field: 'oilpray_y', lookup: objOilPrayY },
  // { title: '專用法會', field: 'only_pray', lookup: pray1 },
  // { title: '價錢', field: 'price' },
  { title: '備註', field: 'ps' },
]
const prayTypeOpt = {
  "1": "超渡",
  "2": "斗燈",
  "3": "祈福",
  "4": "法會",
  "5": "光明燈",
}
export default class ModalPraying1 extends Component {
  state = {
    show: false,
    data1: [],
    data2: [],
    ordering1Id: '',
    newData1: {},
    newData2: {},
    oldData1: {},
    oldData2: {},
    loading: true,
    companyObjLive: {},
    loading1: true,
    pray2Obj: [],
    familyObj: {}
  }

  componentDidMount = async () => {
    this.handleParams()
  }

  componentWillReceiveProps(nextProps) {
    this.handleParams(nextProps);
  }

  handleParams = async (props = this.props) => {
    const { match, category, companyObj } = props;
    const { t1 } = match.params;
    if (typeof t1 !== 'undefined' && t1 === 'p0') {
      this.setState({ show: true, }, () => this.handleFetch(props));
    }
  }

  handleFetch = async (props = this.props) => {
    const { match, companyObj, category } = props;
    const { t1Id, familyId } = match.params;
    if (!familyId) {
      return;
    }
    // console.log(t1Id)
    // const companyId = companyObj.objectId;
    const data1 = await Parse.queryData('point', { familyId });
    const mgyear = await Parse.queryData('mgyear', { companyId: companyObj.objectId });
    let mgyearSel = {}
    let mgyearDefault = ''
    mgyear.forEach(item => {
      mgyearSel = { ...mgyearSel, [item.value]: item.value }
      if (item.now_use === '-1') {
        mgyearDefault = item.value
      }
    })
    // const familyObj = await Parse.fetchDocument('family', familyId)
    // console.log(familyObj)
    this.setState({ data1, loading1: false, mgyearSel, mgyearDefault });
  }

  handleAdd = async () => {
    const { familyId } = this.props
    const { newData1 } = this.state
    await Parse.saveData('point', newData1);

    const familyObj = await Parse.fetchDocument('family', familyId)
    await Parse.saveData('family', { ...familyObj, point: (familyObj.point || 0) + Number(newData1.point) });

  }

  handleUpdate = async () => {
    const { familyId } = this.props
    const { newData1, oldData1 } = this.state
    await Parse.saveData('point', newData1);
    const familyObj = await Parse.fetchDocument('family', familyId)
    await Parse.saveData('family', { ...familyObj, point: (familyObj.point || 0) - Number(oldData1.point) + Number(newData1.point) });
  }

  handleDelete = async () => {
    const { familyId } = this.props
    const { oldData1 } = this.state;
    await Parse.deleteData('point', oldData1);
    const familyObj = await Parse.fetchDocument('family', familyId)
    await Parse.saveData('family', { ...familyObj, point: (familyObj.point || 0) - Number(oldData1.point) });
    this.setState({ oldData1: {} })
  }

  handleClickModalBtn = () => {
    const { history, familyId } = this.props;
    history.push(`/admin/Family/${familyId}/p0`);
  }

  handleClosePdf = () => {
    const { history, familyId } = this.props;
    history.push(`/admin/Family/${familyId}`);
    this.setState({ show: false });
  }
  render() {
    const { people1, companyObj, familyId, obj } = this.props
    const { show, data1, loading1, mgyearSel, mgyearDefault } = this.state;
    const { title, color, icon } = obj;
    let people1Sel = { '-1': '請選擇' };
    people1.forEach(item => people1Sel = { ...people1Sel, [item.objectId]: item.name });

    return (<>
      <Button icon={icon} content={title} color={color} size='small' floated='right' onClick={this.handleClickModalBtn} />
      <Modal
        isOpen={show}
        toggle={this.handleClosePdf}
        size="lg"
        className="mh-90 h-90 mw-100 w-90"
        style={{ paddingTop: '0px' }}
        scrollable={true}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggle}>
            <span aria-hidden="true" onClick={this.handleClosePdf}>×</span>
          </button>
          {title} 管理
        </ModalHeader>
        <ModalBody style={{ padding: "20px 30px" }}>
          <Grid >
            <Grid.Row columns='equal'>
              <Grid.Column width={10}>
                <MaterialTable
                  isLoading={loading1}
                  localization={localization()}
                  title={this.props.title || '申辦服務'}
                  columns={columns(people1Sel, mgyearSel, mgyearDefault)}
                  options={{
                    addRowPosition: "first",
                    exportButton: true,
                    exportPdf: (columns, data) => exportPdf(columns, data, this.props.title || '申辦服務'),
                    pageSize: 5,
                    search: false,
                    tableLayout: 'fixed',
                  }}
                  data={data1}
                  // actions={[
                  //   {
                  //     icon: 'view_headline',
                  //     tooltip: '查看',
                  //     onClick: (event, rowData) => this.handleData(rowData)
                  //   },
                  //   {
                  //     icon: 'file_copy',
                  //     tooltip: '複製',
                  //     onClick: (event, rowData) => this.handleDuplicate(rowData)
                  //   }
                  // ]}
                  // onRowClick={(event, rowData) => this.handleData(rowData)}
                  editable={{
                    onRowAdd: newData1 =>
                      new Promise(async (resolve, reject) => {
                        const data = [...data1];
                        const db = Parse.Object.extend("point");
                        const newDoc = new db();
                        const doc = await newDoc.save();
                        newData1.objectId = doc.id;
                        newData1.id = doc.id;
                        newData1._id = doc.id;
                        newData1.companyId = companyObj.objectId
                        newData1.companyName = companyObj.name
                        // newData1.category = category
                        newData1.familyId = familyId

                        data.push(newData1);
                        this.setState({ data1: data, newData1 }, () => resolve());
                      }).then(() => this.handleAdd()),
                    onRowUpdate: (newData1, oldData1) =>
                      new Promise((resolve, reject) => {
                        // console.log(newData1)
                        // if (newData1.pray1Id) {
                        //   newData1.pray1Text = pray1Sel[newData1.pray1Id];
                        // }
                        // if (newData1.pray2Id) {
                        //   newData1.pray2Text = pray2.find(item => item.objectId === newData1.pray2Id).value;
                        // }
                        // newData1.accReceivable = Number(newData1.accReceivable || 0);
                        // newData1.accReceived = Number(newData1.accReceived || 0);
                        // newData1.accPending = Number(newData1.accReceivable || 0) - Number(newData1.accReceived || 0);
                        // console.log(newData1)
                        const data = [...data1];
                        const index = data.indexOf(oldData1);
                        data[index] = newData1;

                        // const { pray1Id, pray1Text, pray2Id, pray2Text, year, ordernum, serial, pray2OrderId } = newData1;
                        // const data2 = this.state.data2 && this.state.data2.map(item => ({
                        //   ...item, pray1Id, pray1Text, pray2Id, pray2Text, year, ordernum, serial, pray2OrderId
                        // }));

                        this.setState({ data1: data, newData1, oldData1 }, () => resolve());
                      }).then(() => this.handleUpdate()),
                    onRowDelete: oldData1 =>
                      new Promise((resolve, reject) => {
                        let data = [...data1];
                        const index = data.indexOf(oldData1);
                        data.splice(index, 1);
                        this.setState({ data1: data, oldData1 }, () => resolve());
                      }).then(() => this.handleDelete()),
                  }}
                />
              </Grid.Column>
              {/* <Grid.Column width={6}>
                {ordering1Id ? <>
                  {loading ? <DimmerImg /> : <>
                    <ModalPdf {...this.props} title='繳款單' nowform={'80'} params={{ people1, people0, praying1Obj, companyObj: companyObjLive }} />
                    <ModalPdf {...this.props} title='收據' nowform={'81'} params={{ people1, people0, praying1Obj, companyObj: companyObjLive }} />
                    <br />
                    <br />
                    <ModalReceivable {...this.props} praying1Obj={praying1Obj} handleUpdateAcc={this.handleUpdateAcc} />
                  </>}
                </> : null}
              </Grid.Column> */}
            </Grid.Row>
            {/* <Grid.Row columns='equal'>
              <Grid.Column width={16}>
                {ordering1Id ? <div style={{ maxWidth: '100%' }}>
                  <ModalPraying2
                    {...this.props}
                    isLoading={loading}
                    selectData={selectData}
                    pray2Obj={pray2Obj}
                    praying1Obj={praying1Obj}
                    data1={data1} data2={data2}
                    setData2={this.setData2}
                    category={category}
                    praying1Type={praying1Type}
                    fieldTypeId={fieldTypeId} />
                </div> : null}
              </Grid.Column>
            </Grid.Row> */}
          </Grid>
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button onClick={this.handleClosePdf} icon='checkmark' content='關閉' />
        </ModalFooter>
      </Modal>
    </>)
  }
}