import React, { Component } from 'react'
import { Container, Button, Dimmer, Loader, Image, Segment, Header, Tab, Form } from 'semantic-ui-react';
import MaterialTable from 'material-table'

import localization from 'widget/MaterialTableOpt'
// const roomType = { '0': '單人房', '1': '雙人房' };

const columns = () => ([
  { title: '入住日期', field: 'checkIn', type: 'date' },
  { title: '退房日期', field: 'checkOut', type: 'date' },
  { title: '姓名', field: 'name' },
  { title: '房號', field: 'roomNum' },
  { title: '繳款', field: 'accReceived' },
]);

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export default class Client1 extends Component {
  state = {
    dataArr: [],
    dataArr1: [],
    dataArr2: [],
    loading: true,
  }

  componentDidMount() {
    // const { calendar } = this.props
    // this.setState({ dataArr: calendar })
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = () => {
    const { firebase, companyObj, rowData, roomObj } = this.props;
    const { id, newData } = this.state
    // console.log(`bg/${doc.bgId}/clients/${clientId}/portfolios`)
    const db = firebase.firestore();
    db.collectionGroup(`pay1`).where('roomId', '==', roomObj._id)
      .get().then(snap => {
        const data = [];
        snap.forEach(item => data.push({ id: item.id, ...item.data() }));
        this.setState({ dataArr2: data, loading: false });
        console.log(data)
      }, err => { console.log(`Encountered error: ${err}`); });
  }

  // handleAdd = async () => {
  //   const { firebase, companyObj, rowData, roomObj } = this.props
  //   const { newData, id } = this.state
  //   const obj = {
  //     ...newData,
  //   };
  //   // console.log(obj)
  //   const db = firebase.firestore();
  //   const docRef = db.collection(`company/${companyObj.id}/building/${rowData.id}/room/${roomObj.id}/room1`).doc(obj.id);
  //   await docRef.set(obj);
  // }
  // handleUpdate = async () => {
  //   const { firebase, companyObj, rowData, roomObj } = this.props
  //   const { newData } = this.state
  //   const db = firebase.firestore()
  //   const docRef = db.doc(`company/${companyObj.id}/building/${rowData.id}/room/${roomObj.id}/room1/${newData.id}`)
  //   const obj = { ...newData };
  //   await docRef.update(obj);
  // }

  // // this.setState({ oldData: {}, newData: {} })

  // handleDelete = async () => {
  //   const { firebase, companyObj, rowData, roomObj } = this.props
  //   const { oldData, id } = this.state
  //   const db = firebase.firestore()
  //   await db.collection(`company/${companyObj.id}/building/${rowData.id}/room/${roomObj.id}/room1`).doc(oldData.id).delete();
  //   // console.log(`${refId} deleted`);
  //   this.setState({ oldData: {} })
  // }

  render() {
    const { firebase, companyObj, roomObj, rowData, } = this.props;
    const { dataArr2, loading } = this.state;
    // console.log(roomObj)
    return (<>
      <Container>
        <div style={{ ...style.flexCenter, position: 'relative', zIndex: 100 }}>
          {/* <Header as='h2' style={{ margin: '2rem 0' }}>客房資料</Header> */}
        </div>
        <Form>
          <br />
          <br />
          {roomObj && roomObj.id ?
            <MaterialTable
              isLoading={loading}
              localization={localization()}
              columns={columns()}
              options={{
                pageSize: 5,
                search: false
              }}
              data={dataArr2}
              title={'收款紀錄'}
            // editable={{
            //   onRowAdd: newData =>
            //     new Promise((resolve, reject) => {
            //       const db = firebase.firestore();
            //       const docRef = db.collection(`company/${companyObj.id}/building/${rowData.id}/room/${roomObj.id}/room1`).doc();
            //       const data = dataArr2;
            //       const obj = {
            //         id: docRef.id,
            //         _id: docRef.id,
            //         companyId: companyObj.id,
            //         companyName: companyObj.companyName,
            //         buildingId: rowData._id,
            //         buildingName: rowData.buildingName,
            //         roomTypeText: roomType[newData.roomType],
            //         roomId: roomObj.id,
            //         ...newData
            //       }
            //       // data.push(obj);
            //       data.push(obj);
            //       this.setState({ dataArr2: data, newData: obj }, () => resolve());
            //     }).then(() => this.handleAdd()),
            //   onRowUpdate: (newData, oldData) =>
            //     new Promise((resolve, reject) => {
            //       const data = dataArr2;
            //       const index = data.indexOf(oldData);

            //       newData.roomTypeText = roomType[newData.roomType];
            //       data[index] = newData;
            //       // handleState('people0', data);
            //       this.setState({ dataArr2: data, newData, oldData }, () => resolve());
            //     }).then(() => this.handleUpdate()),
            //   onRowDelete: oldData =>
            //     new Promise((resolve, reject) => {
            //       let data = dataArr2;
            //       const index = data.indexOf(oldData);
            //       data.splice(index, 1);
            //       // handleState('people0', data);
            //       this.setState({ dataArr2: data, oldData }, () => resolve());
            //     }).then(() => this.handleDelete()),
            // }}
            /> : null}
          <br />
          <br />
        </Form>
      </Container>
    </>)
  }
}