import React, { Component } from 'react'
// import { useParams } from "react-router-dom";
import { Header, Form, Grid, Menu, Input, Dimmer, Loader, Image, Button, Segment, FormGroup } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import {
  // Container,
  // Table,
  Row,
  Col,
  // Badge,
  // Button,
  // Card,
  // CardBody,
  // CardTitle,
} from "reactstrap";
import { DelayInput } from 'react-delay-input';
// import MaterialTable, { MTableToolbar } from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ModalNewTemple from './ModalNewTemple';
// import RowsDnd from './RowsDnd'
import TableTemple from './TableTemple';
// import TablePeople from './TablePeople';
// import fetchUrl from '../../widget/http';

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}
// const url = 'https://restful.infowin.com.tw/api/people2';

const fetchPeople = async (companyObj, params, searchText) => {
  const { page = 1, pageSize = 5 } = params
  // console.log(params, searchText)

  let query = new Parse.Query('temple');

  if (searchText) {
    const p0 = new Parse.Query("temple");
    // p0.fullText("name", searchText);
    p0.startsWith("name", searchText);

    const p1 = new Parse.Query("temple");
    p1.startsWith("addr", searchText);

    const p2 = new Parse.Query("temple");
    p2.startsWith("phone", searchText);

    query = Parse.Query.or(p0, p1, p2);
  }
  query.select(['name', 'buddhist', 'addr', 'phone']);

  query.equalTo("companyId", companyObj.objectId);

  const totalCount = await query.count();

  // if (params.sorter) {
  //   const s = params.sorter.split('_');

  //   if (s[1] === 'descend') {
  //     query.descending(s[0]);
  //   } else {
  //     query.ascending(s[0]);
  //   }

  //   // Sorts the results in descending order by the score field
  // }
  query.limit(pageSize);
  query.skip((Number(page)) * (pageSize));

  const snap = await query.find();
  const data = snap.map(data => data.toJSON());
  console.log(data)
  return {
    data,
    page,
    totalCount,
  }
}

class Temple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      familyId: '',
      searchValue: '',
      people: [],
      templeclassSel: {},
      selectData: {},
      rowData: {},
      templeData: [],
      objectId: ''
    }
    this.tableRef = React.createRef();
  }

  // async componentDidMount() {
  //   const { match, companyObj } = this.props;
  //   const familyId = match.params.familyId || '';
  //   this.setState({ familyId })

  //   const companyId = companyObj.objectId;
  //   const templeclassObj = await Parse.getSelection('templeclass', { companyId }, { name: 'name', sort: 'name' });
  //   const templelevelObj = await Parse.getSelection('templelevel', { companyId }, { name: 'name', sort: 'name' });
  //   const praytypeObj = await Parse.getSelection('praytype', { companyId }, { name: 'name', sort: 'name' });
  //   const pray1Obj = await Parse.getSelection('pray1', { companyId }, { name: 'name', sort: 'name' });
  //   Parse.fetchDocument('company', companyId).then(companyObjLive => this.setState({ companyObjLive }));

  //   // console.log(pray1Obj);
  //   const selectData = { templeclassObj, templelevelObj, praytypeObj, pray1Obj }
  //   this.setState({ selectData }, () => this.handleFetch())
  // }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.match.params || nextProps.match.params.objectId !== this.props.match.params.objectId) {
      const objectId = nextProps.match.params.objectId || '';
      this.setState({ objectId, loading: true }, () => this.handleFetch(nextProps));
    }
    if (typeof nextProps.match.params.objectId === 'undefined') {
      this.setState({ loading: false, objectId: '', people: [] })
    }
  }

  handleFetch = async (props = this.props) => {
    const { match } = props;
    const { objectId } = match.params;
    if (objectId) {
      this.handleLoadTemple(objectId);
    } else {
      this.setState({ loading: false });
    }
  }

  handleLoadTemple = async (objectId) => {
    if (!objectId) {
      return;
    }
    const { companyObj } = this.props;
    const companyId = companyObj.objectId;
    const templeData = await Parse.queryData("temple", { companyId, objectId })
    this.setState({ loading: false, objectId, templeData })
  }

  // handlePeopleAdd = async (newData) => {
  //   console.log(newData)
  //   const { people } = this.state;
  //   const data = [...people];
  //   data.push(newData)
  //   Parse.saveData('people', newData);
  //   console.log(data);
  //   return new Promise((resolve, reject) => this.setState({ people: data }, () => resolve()));

  // }

  // handlePeopleUpdate = async ({ newData, oldData }) => {
  //   const { companyObj } = this.props
  //   const { people } = this.state

  //   // const db = firebase.firestore();
  //   const data = people;
  //   const index = data.indexOf(oldData);

  //   let saveAll = 0;
  //   if (newData.mainPerson === '-1') {
  //     data.forEach(item => item.mainPerson = '0');
  //     saveAll = 1;
  //   }
  //   data[index] = newData;

  //   if (saveAll === 0) {
  //     // const ref = db.collection(`company/${companyObj._id}/family/${newData.familyId}/people`).doc(newData.id)
  //     // await ref.update(newData).catch((e) => console.error(e));
  //     Parse.saveData('people', newData);
  //   } else {
  //     // const batch = db.batch()
  //     // data.forEach(item => {
  //     //   const ref = db.collection(`company/${companyObj._id}/family/${item.familyId}/people`).doc(item.id)
  //     //   batch.update(ref, item)
  //     // });
  //     // await batch.commit();
  //   }
  //   return new Promise((resolve, reject) => this.setState({ people: data }, () => resolve()));
  // }

  // handlePeopleDelete = async (oldData) => {
  //   const { people } = this.state
  //   let data = people;
  //   const index = data.indexOf(oldData);
  //   data.splice(index, 1);

  //   Parse.deleteData('people', oldData);
  //   return new Promise((resolve, reject) => this.setState({ people: data, oldData: {} }, () => resolve()));
  // }

  // handleFamilysAddrChange = async (obj) => {
  //   const { companyObj } = this.props;
  //   const { people } = this.state;
  //   const people1 = people.filter(item => item.isLive === '1');
  //   if (!people1.length) { return 0; }
  //   const companyId = companyObj.objectId;
  //   const familyId = people[0].familyId;
  //   Parse.saveall('people', { companyId, familyId, isLive: "1" }, obj)

  //   const arr = people.map(item => item.isLive === '1' ? { ...item, ...obj } : item);
  //   this.setState({ people: arr })
  //   return 1;
  // }

  handleChange = async (e, { name, value }) => {
    this.setState({ [name]: value }, () => this.tableRef.current.onQueryChange());
  }

  clear = () => {
    this.ref.inputRef.current.value = '';
    // this.setState({ searchValue: '' }, () => this.tableRef.current.onQueryChange());
  }
  handleClickName = (objectId) => {
    const { history } = this.props;
    console.log(objectId);
    history.push(`/admin/Temple/${objectId}`);
    this.setState({ objectId })

  }
  render() {
    const { companyObj } = this.props
    const { objectId, searchValue, loading, people, templeData } = this.state;

    return (<>
      <div className="content">
        <div style={{ ...style.flexCenter, margin: '2rem auto' }}>
          <Row>
            <Col xs="8" sm="10">
              <Form>
                <FormGroup widths='equal'>
                  <DelayInput
                    inputRef={ref => { this.ref = ref; }}
                    name='searchValue'
                    placeholder='請輸入寺廟姓名、電話、手機'
                    style={{ width: '100%', minWidth: 350, float: 'left' }}
                    onChange={(e) => this.handleChange(e, { name: 'searchValue', value: e.target.value })}
                    // minLength={1}
                    delayTimeout={300}
                    action={{ icon: 'cancel', onClick: this.clear }}
                    element={Input}
                  />
                  <Button.Group style={{ marginLeft: '50px' }}>
                    <ModalNewTemple  {...this.props} />
                  </Button.Group>
                </FormGroup>
              </Form>
            </Col>
          </Row>
          {/* <div style={{ marginTop: '12px' }}> */}
          {/* <Header as='h4' disabled> {!searchValue ? '請輸入2個字以上 來搜尋信眾' : null}</Header> */}
          {/* <Header as='h4' disabled>  請輸入2個字以上 來搜尋信眾 </Header> */}
          {/* </div> */}
        </div>
        <Row>
          <Col xs="12" sm="10">
            <MaterialTable
              localization={localization()}
              tableRef={this.tableRef}
              title="搜尋結果"
              options={{
                search: false,
              }}
              columns={[
                { width: 50, title: '寺廟名', field: 'name' },
                { width: 80, title: '法師', field: 'buddhist' },
                { width: 250, title: '地址', field: 'addr', cellStyle: { width: '250px' }, headerStyle: { width: '250px' } },
                { width: 80, title: '電話', field: 'phone' },
              ]}
              data={query =>
                new Promise((resolve, reject) => {
                  fetchPeople(companyObj, query, searchValue).then(result => resolve(result))
                })
              }
              onRowClick={(e, rowData) => this.handleClickName(rowData.objectId)}
              actions={[
                {
                  icon: 'group', // view_headline
                  tooltip: '顯示家庭',
                  onClick: (event, rowData) => this.handleClickName(rowData.objectId)
                },
              ]}
            />
          </Col>
        </Row>
        <br />
        {objectId ? <>
          <TableTemple {...this.props} objectId={objectId} templeData={templeData} />
        </> : null}
        {/* {objectId && loading && <div style={{ color: 'grey' }}> <DimmerImg /></div>} */}
      </div>
    </>);
  }
}

export default Temple;