import React, { Component } from 'react'
import { Container, Header, Dimmer, Loader, Image, Segment, Select } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

const staffStateOpt = [
  { key: 'staffStateOpt1', value: '1', text: '在職' },
  { key: 'staffStateOpt2', value: '0', text: '離職' },
  { key: 'staffStateOptAll', value: '-1', text: '全部' },
]

const authObj = { '0': '禁止', '1': '讀取', '2': '讀取+編輯', '3': '讀取+編輯+刪除' }

const columns = () => ([
  { title: '在職狀態(登入)', field: 'is_auth', lookup: { '1': '在職', '0': '離職' } },
  { title: '工號', field: 'jobNum' },
  { title: '中文姓名', field: 'name' },
  // { title: '英文姓名', field: 'engname' },
  { title: '1 財務&績效管理', field: 'auth1', lookup: authObj },
  { title: '2 人資管理', field: 'auth2', lookup: authObj },
  { title: '3 客戶管理', field: 'auth3', lookup: authObj },
  { title: '4 售後服務', field: 'auth4', lookup: authObj },
  { title: '5 產品中心', field: 'auth5', lookup: authObj },
  { title: '6 網站管理', field: 'auth6', lookup: authObj },
  { title: '4-1 案件追蹤', field: 'auth7', lookup: authObj },
  { title: '4-2 保費提醒', field: 'auth8', lookup: authObj },
  { title: '4-3 產品配息調整', field: 'auth9', lookup: authObj },
  { title: '設定', field: 'auth0', lookup: authObj },
]);

export default class Hr9 extends Component {
  state = {
    dataArr: [],
    selTypeId: '1',
    bgId: '',
    bgSel: [],
    employee: [],
    loading: true,
  }

  componentDidMount() {
    this.handleFetch()
  }

  handleFetch = async (props = this.props) => {
    // const { companyObj } = props;
    // const { bgId } = this.state;
    // const db = firebase.firestore();

    // const employee = [];
    // const snap = await db.collection(`users`).where("authUserA", "array-contains", companyObj._id).get()
    //   .catch(err => { console.log('Error getting document', err); });
    // snap.forEach(item => employee.push({ _id: item.id, ...item.data() }));
    // this.setState({ dataArr: employee.filter(item => item.is_auth === "1"), loading: false });
  }

  handleUpdate = async () => {
    // const { companyObj } = this.props
    // const { newData } = this.state
    // const db = firebase.firestore()
    // // console.log(bgId, newData.id)
    // const docRef = db.doc(`users/${newData.id}`)
    // await docRef.update(newData);
    // this.setState({ oldData: {}, newData: {} })
  }

  handleChange = (event, { name, value }) => {
    const { employee } = this.state
    if (name === 'selTypeId') { // 1在職 0離職
      if (value === '-1') { // 全部
        this.setState({ dataArr: employee, [name]: value })
      } else {
        const dataArr = employee.filter(item => item.is_auth === value);
        this.setState({ dataArr, [name]: value })
      }
    } else if (name === 'bgId') {
      const { bg } = this.props
      const bgText = bg.find(item => item.id === value).value || '';
      this.setState({ [name]: value, bgText }, () => this.handleFetch());
    } else {
      this.setState({ [name]: value })
    }
  };

  render() {
    // const { firebase } = this.props
    const { dataArr, selTypeId, loading } = this.state;

    return (<Container>
      <div style={{ margin: '2rem auto' }}>
        <Select name="selTypeId" style={{ position: 'relative', zIndex: 99, float: 'right' }} options={staffStateOpt} value={selTypeId} onChange={this.handleChange} />
        {/* <Select name="bgId" style={{ marginRight: '4px', position: 'bgId', zIndex: 99, float: 'right' }} options={bgSel} value={bgId} onChange={this.handleChange} /> */}
        {/* <Header as='h2' style={{ margin: 0 }}>4-7 權限設定</Header> */}
      </div>
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        style={{ marginBottom: '2rem' }}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data),
          pageSize: dataArr.length || 10,
          search: false,
        }}
        data={dataArr}
        title=""
        editable={{
          // onRowAdd: newData =>
          //   new Promise((resolve, reject) => {
          //     const data = [...dataArr];
          //     data.push(newData);
          //     // handleState('people0', data);
          //     this.setState({ dataArr: data, newData }, () => resolve());
          //   }).then(() => this.handleAdd(0, 0)),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...dataArr];
              const index = data.indexOf(oldData);
              data[index] = newData;
              // console.log(newData)
              // handleState('people0', data);
              this.setState({ dataArr: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          // onRowDelete: oldData =>
          //   new Promise((resolve, reject) => {
          //     let data = [...dataArr];
          //     const index = data.indexOf(oldData);
          //     data.splice(index, 1);
          //     // handleState('people0', data);
          //     this.setState({ dataArr: data, oldData }, () => resolve());
          //   }).then(() => this.handleDelete(0)),
        }}
      />
      <br />
    </Container>)
  }
}