import React, { Component } from 'react';
import { Grid, Table } from 'semantic-ui-react';

// const tdCss = {
//   whiteSpace: 'nowrap',
//   overflow: 'hidden',
//   textOverflow: 'ellipsis'
// };

export default class ViewArrangeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // studentArr: [],
    };
  }
  getFloorArr = (rooms) => {
    return (
      rooms ? rooms.reduce((floor, room) => {
        room.roomFloor && floor.indexOf(room.roomFloor) === -1 ?
          floor.push(room.roomFloor) : void (0)
        return floor;
      }, []) : []
    );
  };
  render() {
    const { students, classroom, semesterYear, semester, dorms } = this.props;
    // const { studentArr } = this.state;
    const classroomArr = classroom.filter(item => item.semesterYear === semesterYear && item.semester === semester);
    const dormsArr = dorms && [dorms[4], dorms[2], dorms[3], dorms[0], dorms[1], dorms[6], dorms[5]]

    const arr = students.filter(item => (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
      (item.applyResult === '1' || item.studentType === "2"))

    return (<Grid style={{ margin: '0px' }} id='excel'>
      <React.Fragment>
        <Grid.Row columns='equal' style={{ paddingTop: '0px' }}>
          {dorms && [3, 1, 1, 1, 1].map((item, index) =>
            <Grid.Column key={index} style={{ paddingLeft: '1px', paddingRight: '2px', }}>
              {/* <Grid.Row style={{ paddingTop: '0px' }}> */}
              {dormsArr.splice(0, item).map((dormObj, index) => {
                // const studentsByClass = arr.filter(item1 => item1.classSchool === classObj.classSchool);
                const rooms = dormObj.roomData.sort((a, b) => ('' + a.roomFloor).localeCompare(b.roomFloor))
                const floors = this.getFloorArr(rooms)
                console.log(floors)
                return (
                  <Table striped celled structured size="small" className="tdPadding2px thPadding2px">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell colSpan={5}>〒{dormObj.dormitoryPostcode} {dormObj.dormitoryAddr}   {dormObj.dormitoryName}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {floors.map(floor =>
                        <Table.Row key={floor}>
                          <Table.Cell colSpan={1} style={{ width: '40px', textAlign: 'center' }}>{floor}</Table.Cell>
                          {rooms.filter(room => room.roomFloor === floor)
                            .sort((a, b) => ('' + a.roomName).localeCompare(b.roomName)).map((item2, index2) =>
                              <Table.Row key={item2._id}>
                                <Table.Cell colSpan={1} style={{ width: '40px', textAlign: 'center' }}>{item2.roomName}</Table.Cell>
                                {new Array(Number(item2.roomType || '')).fill().map((bed, index) =>
                                  <Table.Row key={index}>
                                    <Table.Cell colSpan={1} style={{ width: '40px', textAlign: 'center', height: '20px' }}>{item2[`studentNum${index + 1}`] || '  '}</Table.Cell>
                                    <Table.Cell colSpan={1} style={{ width: '100px' }}>{item2[`studentName${index + 1}`] || '  '}</Table.Cell>
                                    <Table.Cell colSpan={1} style={{ width: '40px' }}>{'  '}</Table.Cell>
                                  </Table.Row>)}
                              </Table.Row>)}
                        </Table.Row>)}
                    </Table.Body>
                  </Table>);
              })}
              {/* </Grid.Row> */}
            </Grid.Column>)}
        </Grid.Row>
      </React.Fragment>
    </Grid>);
  }
}