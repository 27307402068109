import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";
import Moment from 'moment';

// core components

function SectionBlog(props = {}) {
  const { form, cookies, webimageArr, imagePreviewUrl } = props;
  const { text2 = '', text3 = '', file1 = {} } = form;
  const dbLang = cookies.get('dbLang') || 'name';
  let arr = []
  arr = webimageArr.filter(item => item.navId === form.objectId)
  if (!arr.length) {
    arr = [{ icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' }]
  }
  const picUrl = (file1 && file1.url) || imagePreviewUrl;
  return (
    <>
      <div className="section secion-blog cd-section" id="blogs">
        {/* *********  BLOGS 4 ********* */}
        <div className="blog-4">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title text-center" >{text2}</h2>
                <br />
              </Col>
            </Row>
            <Row>
              {arr.map((item, index) => <>
                <Col md="6">
                  <Card className="card-plain card-blog text-center">
                    <div className="card-image">
                      <a href={`${item.url}`} target='_blank' >
                        <img
                          alt="..."
                          className="img img-raised"
                          style={{ width: '500px', height: '335px' }}
                          src={(item.file1 && item.file1.url) || require('assets/img/bg/damir-bosnjak.jpg')}
                        />
                      </a>
                    </div>
                    <CardBody>
                      <h4 className="card-category" style={{ color: `${index % 4 === 0 ? '#f7c23c' : index % 4 === 3 ? '#6bd197' : index % 4 === 2 ? '#f55a3d' : '#50bdda'}`, fontWeight: 800 }}>{item['title1' + dbLang] || ''}</h4>
                      <CardTitle tag="h3">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          {item.title}
                        </a>
                      </CardTitle>
                      <p className="card-description">
                        {item['content1' + dbLang] || ''}
                      </p>
                      <br />
                      {/* <Button
                        className="btn-round"
                        color="warning"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        Read More
                  </Button> */}
                    </CardBody>
                  </Card>
                </Col>
              </>)}
            </Row>
            <>
            </>
          </Container>
        </div>
        {/* ********* END BLOGS 4 ********* */}
      </div>
    </>
  );
}

export default SectionBlog;
