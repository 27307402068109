import React, { Component } from 'react';
import { Button, Grid, Select, Form, Input, Label, Icon } from 'semantic-ui-react';
import Parse from 'widget/parse'
// import localization from 'widget/MaterialTableOpt'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { getPdfsrcAsync, PdfIframe, PdfPanel, PdfButton } from 'widget/pdf/loadCore';
// import { _calculateAge } from '../../widget/define/date'

const URL = 'https://firebasestorage.googleapis.com/v0/b/hanbury-infowin.appspot.com/o';

const serviceSel = [
  { key: '0', text: '--請選擇--', value: '@@' },
  { key: '232', text: "無PDF表單", disabled: true },
  { key: '-1', text: "0. 其他", value: '0', url: 'other' },
  { key: '233', text: "VOYA", disabled: true },
  { key: '61', text: '1. VOYA- 更改個人資料', value: '61', url: URL + '/demo%2F1.%20VOYA-%20%E6%9B%B4%E6%94%B9%E5%80%8B%E4%BA%BA%E8%B3%87%E6%96%99.pdf?alt=media&token=b759cb83-c047-4eb1-88f2-55f2564a271c' },
  { key: '62', text: '2. VOYA- 更改要保人', value: '62', url: URL + '/demo%2F2.%20VOYA-%20%E6%9B%B4%E6%94%B9%E8%A6%81%E4%BF%9D%E4%BA%BA.pdf?alt=media&token=91c79e0f-3011-41ec-9ec5-e48b68082711' },
  { key: '63', text: '3. VOYA- 更改受益人', value: '63', url: URL + '/demo%2F3.%20VOYA-%20%E6%9B%B4%E6%94%B9%E5%8F%97%E7%9B%8A%E4%BA%BA.pdf?alt=media&token=dc734cce-a080-48c4-b084-cc039b558576' },
  { key: '65', text: '4. VOYA- VOYA匯款指示', value: '65', url: URL + '/demo%2F4.%20VOYA-%20VOYA%E5%8C%AF%E6%AC%BE%E6%8C%87%E7%A4%BA.pdf?alt=media&token=389616ff-a87c-45fa-a7b5-e8c1e653222a' },
  { key: '234', text: "Grandtag", disabled: true },
  { key: '26', text: '11. 申請及更改Blueocean帳戶基本資料', value: '26', url: URL + '/demo%2F11.%20%E7%94%B3%E8%AB%8B%E5%8F%8A%E6%9B%B4%E6%94%B9Blueocean%E5%B8%B3%E6%88%B6%E5%9F%BA%E6%9C%AC%E8%B3%87%E6%96%99.pdf?alt=media&token=6eb21645-648d-4488-b350-e0473d55f26c' },
  { key: '235', text: "GT Madison Realty", disabled: true },
  { key: '27', text: '21. 更改房產持有人配息帳戶及地址', value: '27', url: URL + '/demo%2F21.%20%E6%9B%B4%E6%94%B9%E6%88%BF%E7%94%A2%E6%8C%81%E6%9C%89%E4%BA%BA%E9%85%8D%E6%81%AF%E5%B8%B3%E6%88%B6%E5%8F%8A%E5%9C%B0%E5%9D%80.pdf?alt=media&token=ff7a34e8-48fb-4106-9fb5-d3c16d21eb75' },
  { key: '236', text: "FPI", disabled: true },
  { key: '1', text: '31. 提款_v1', value: '1', url: URL + '/demo%2F31.%20%E6%8F%90%E6%AC%BE_v1.pdf?alt=media&token=698071fa-c486-423b-9ccb-f9e4c14b2094' },
  { key: '86', text: '31-1. 提款_v2', value: '86' },
  { key: '87', text: '31-2. 提款繳VOYA_v2', value: '87' },
  { key: '2', text: '32. 滿期解約_v1', value: '2', url: URL + '/demo%2F32.%20%E6%BB%BF%E6%9C%9F%E8%A7%A3%E7%B4%84_v1.pdf?alt=media&token=2355a612-fd7a-436b-8b4c-91fc59c85115' },
  { key: '82', text: '32-1. 滿期解約_v2', value: '82' },
  { key: '02', text: '33. 提前解約_v1', value: '02', url: URL + '/demo%2F33.%20%E6%8F%90%E5%89%8D%E8%A7%A3%E7%B4%84_v1.pdf?alt=media&token=ebc58ef4-d9da-40d7-a45f-508c644dce2f' },
  { key: '83', text: '33-1. 提前解約_v2', value: '83' },
  { key: '3', text: '33. 更改付款模式- New BSO + Cancel BSO', value: '3', url: URL + '/demo%2F33.%20%E6%9B%B4%E6%94%B9%E4%BB%98%E6%AC%BE%E6%A8%A1%E5%BC%8F-%20New%20BSO%20%2B%20Cancel%20BSO.pdf?alt=media&token=980e65a8-6314-4fcf-a64d-17bfdd4cec1e' },
  { key: '4', text: '34. 更改付款模式- New BSO', value: '4', url: URL + '/demo%2F34.%20%E6%9B%B4%E6%94%B9%E4%BB%98%E6%AC%BE%E6%A8%A1%E5%BC%8F-%20New%20BSO.pdf?alt=media&token=ba0aa240-43c3-4a86-87ec-7f368a36f156' },
  { key: '5', text: '35. 更改付款模式- Cancel BSO', value: '5', url: URL + '/demo%2F35.%20%E6%9B%B4%E6%94%B9%E4%BB%98%E6%AC%BE%E6%A8%A1%E5%BC%8F-%20Cancel%20BSO.pdf?alt=media&token=70057ea9-3013-46b1-bf03-a63ad9be140f' },
  { key: '6', text: '36. 更改付款模式- NEW Update DCA_v1', value: '6', url: URL + '/demo%2F36.%20%E6%9B%B4%E6%94%B9%E4%BB%98%E6%AC%BE%E6%A8%A1%E5%BC%8F-%20NEW%20Update%20DCA_v1.pdf?alt=media&token=cc9b5164-9d26-4cac-be5f-1fcb1d1b1a91' },
  { key: '84', text: '36-1. 更改付款模式- NEW Update DCA_v2', value: '84' },
  { key: '7', text: '37. 更改付款模式- Update DCA + Cancel BSO', value: '7', url: URL + '/demo%2F37.%20%E6%9B%B4%E6%94%B9%E4%BB%98%E6%AC%BE%E6%A8%A1%E5%BC%8F-%20Update%20DCA%20%2B%20Cancel%20BSO.pdf?alt=media&token=05756cba-1701-4265-b9ed-0043238a19bd' },
  { key: '8', text: '38. 申請BIL', value: '8', url: URL + '/demo%2F38.%20%E7%94%B3%E8%AB%8BBIL.pdf?alt=media&token=1776994d-25cf-462d-9ac3-2c71f94da60c' },
  { key: '9', text: '39. 申請復效- Reinstatement + BSO', value: '9', url: URL + '/demo%2F39.%20%E7%94%B3%E8%AB%8B%E5%BE%A9%E6%95%88-%20Reinstatement%20%2B%20BSO.pdf?alt=media&token=fa7a8b21-c5e6-4b36-bca6-f62b25a2294c' },
  { key: '10', text: '40. 申請復效- Reinstatement + DCA', value: '10', url: URL + '/demo%2F40.%20%E7%94%B3%E8%AB%8B%E5%BE%A9%E6%95%88-%20Reinstatement%20%2B%20DCA.pdf?alt=media&token=1584e200-a27b-4d12-9475-7d4d4033b778' },
  { key: '11', text: '41. 申請復效- Reinstatement + BIL', value: '11', url: URL + '/demo%2F41.%20%E7%94%B3%E8%AB%8B%E5%BE%A9%E6%95%88-%20Reinstatement%20%2B%20BIL.pdf?alt=media&token=a2e7dbb3-4ead-4062-9335-aefc587be26c' },
  { key: '12', text: '42. 申請復效- Reinstatement + DCA + BIL', value: '12', url: URL + '/demo%2F42.%20%E7%94%B3%E8%AB%8B%E5%BE%A9%E6%95%88-%20Reinstatement%20%2B%20DCA%20%2B%20BIL.pdf?alt=media&token=77f385c8-adc6-4652-a0ea-ce5ea17af89c' },
  { key: '13', text: '43. 申請復效- Reinstatement + DCA + BIL + Cancel BSO', value: '13', url: URL + '/demo%2F43.%20%E7%94%B3%E8%AB%8B%E5%BE%A9%E6%95%88-%20Reinstatement%20%2B%20DCA%20%2B%20BIL%20%2B%20Cancel%20BSO.pdf?alt=media&token=ae438296-a879-44fb-9ab5-b6f3a5652bd6' },
  { key: '14', text: '44. 申請暫停供款- Paid up + Cancel BSO', value: '14', url: URL + '/demo%2F44.%20%E7%94%B3%E8%AB%8B%E6%9A%AB%E5%81%9C%E4%BE%9B%E6%AC%BE-%20Paid%20up%20%2B%20Cancel%20BSO.pdf?alt=media&token=e45e8108-7134-4cb0-b9da-008567cac840' },
  { key: '15', text: '45. 申請暫停供款- Paid up', value: '15', url: URL + '/demo%2F45.%20%E7%94%B3%E8%AB%8B%E6%9A%AB%E5%81%9C%E4%BE%9B%E6%AC%BE-%20Paid%20up.pdf?alt=media&token=e932ceb6-53e5-4cb3-b082-5bd72da697de' },
  { key: '16', text: '46. 申請保單假期 - Premuim Holiday + Cancel BSO', value: '16', url: URL + '/demo%2F46.%20%E7%94%B3%E8%AB%8B%E4%BF%9D%E5%96%AE%E5%81%87%E6%9C%9F%20-%20Premuim%20Holiday%20%2B%20Cancel%20BSO.pdf?alt=media&token=5d16ed1c-3ba3-44e0-b28b-8303f1031d1e' },
  { key: '17', text: '47. 申請保單假期 - Premuim Holiday', value: '17' },
  { key: '18', text: '48. 降低供款 - 降供 + DCA', value: '18', url: URL + '/demo%2F48.%20%E9%99%8D%E4%BD%8E%E4%BE%9B%E6%AC%BE%20-%20%E9%99%8D%E4%BE%9B%20%2B%20DCA.pdf?alt=media&token=4089d9f6-8523-496a-bd1b-257430b78085' },
  { key: '19', text: '49. 降低供款 - 降供 + DCA + Cancel BSO', value: '19', url: URL + '/demo%2F49.%20%E9%99%8D%E4%BD%8E%E4%BE%9B%E6%AC%BE%20-%20%E9%99%8D%E4%BE%9B%20%2B%20DCA%20%2B%20Cancel%20BSO.pdf?alt=media&token=0e45d9c5-5819-46fa-a856-f63f4fda6cbb' },
  { key: '20', text: '50. 降低供款 - 降供 + BSO + Cancel BSO', value: '20', url: URL + '/demo%2F50.%20%E9%99%8D%E4%BD%8E%E4%BE%9B%E6%AC%BE%20-%20%E9%99%8D%E4%BE%9B%20%2B%20BSO%20%2B%20Cancel%20BSO.pdf?alt=media&token=c8c91489-7b08-4ac5-8868-dccff3fba0b8' },
  { key: '21', text: '51. 降低供款 - 降供 + Cancel BSO', value: '21', url: URL + '/demo%2F51.%20%E9%99%8D%E4%BD%8E%E4%BE%9B%E6%AC%BE%20-%20%E9%99%8D%E4%BE%9B%20%2B%20Cancel%20BSO.pdf?alt=media&token=cca48fd2-ab0c-4917-a13a-fd006bd8ceae' },
  { key: '22', text: '52. 降低供款 - 降供 + BSO', value: '22' },
  { key: '23', text: '53. 降低供款 - 降供', value: '23' },
  { key: '24', text: '54. 變更通訊戶籍地址', value: '24' },
  { key: '88', text: '54-1. 更新聯絡資料', value: '88' },
  { key: '25', text: '55. 更改手機號碼', value: '25', url: URL + '/demo%2F55.%20%E6%9B%B4%E6%94%B9%E6%89%8B%E6%A9%9F%E8%99%9F%E7%A2%BC.pdf?alt=media&token=687210fe-a049-41f1-89a6-ba25d378df83' },
  { key: '28', text: '56. 更改EMAIL', value: '28', url: URL + '/demo%2F56.%20%E6%9B%B4%E6%94%B9EMAIL.pdf?alt=media&token=cf2fa03a-69f3-4f33-b48a-1f18a8e6a492' },
  { key: '29', text: '57. 取消OMA', value: '29' },
  { key: '30', text: '58. 保單遺失', value: '30' },
  { key: '31', text: '59. 設定OMA', value: '31', url: URL + '/demo%2F59.%20%E8%A8%AD%E5%AE%9AOMA.pdf?alt=media&token=8af71578-3ac1-465d-a583-92ff1da33d88' },
  { key: '32', text: '60. 更改姓名', value: '32' },
  { key: '33', text: '61. 更改簽名樣式_v1', value: '33', url: URL + '/demo%2F61.%20%E6%9B%B4%E6%94%B9%E7%B0%BD%E5%90%8D%E6%A8%A3%E5%BC%8F_v1.pdf?alt=media&token=c450f5b4-e995-4074-886c-3e758e6561b1' },
  { key: '89', text: '61-1. 更改簽名樣式_v2', value: '89' },
  { key: '34', text: '62. 保單轉讓', value: '34', url: URL + '/demo%2F62.%20%E4%BF%9D%E5%96%AE%E8%BD%89%E8%AE%93.pdf?alt=media&token=8310fcac-eba3-43d0-a676-f1b3a9067fb2' },
  { key: '35', text: '63. 變更供款週期 + 匯款指示(TT)', value: '35', url: URL + '/demo%2F63.%20%E8%AE%8A%E6%9B%B4%E4%BE%9B%E6%AC%BE%E9%80%B1%E6%9C%9F%20%2B%20%E5%8C%AF%E6%AC%BE%E6%8C%87%E7%A4%BA(TT).pdf?alt=media&token=0071223e-fbda-4745-89ea-6f51c6c4afda' },
  { key: '36', text: '64. 變更供款週期 + 匯款指示(TT) + Cancel BSO', value: '36', url: URL + '/demo%2F64.%20%E8%AE%8A%E6%9B%B4%E4%BE%9B%E6%AC%BE%E9%80%B1%E6%9C%9F%20%2B%20%E5%8C%AF%E6%AC%BE%E6%8C%87%E7%A4%BA(TT)%20%2B%20Cancel%20BSO.pdf?alt=media&token=f859f02f-f03f-4c9a-8a6a-8bf52462496b' },
  { key: '37', text: '65. 變更供款週期', value: '37' },
  { key: '38', text: '66. 變更供款週期 + BSO', value: '38', url: URL + '/demo%2F66.%20%E8%AE%8A%E6%9B%B4%E4%BE%9B%E6%AC%BE%E9%80%B1%E6%9C%9F%20%2B%20BSO.pdf?alt=media&token=0636f98c-e586-41fa-872b-8009003807a8' },
  { key: '39', text: '67. 變更供款週期 + BSO + Cancel BSO', value: '39' },
  { key: '40', text: '68. 變更供款週期 + DCA', value: '40', url: URL + '/demo%2F68.%20%E8%AE%8A%E6%9B%B4%E4%BE%9B%E6%AC%BE%E9%80%B1%E6%9C%9F%20%2B%20DCA.pdf?alt=media&token=c16f7fbb-7a9d-4fda-8ac9-472ae775ae07' },
  { key: '41', text: '69. 變更供款週期 + DCA + Cancel BSO', value: '41', url: URL + '/demo%2F69.%20%E8%AE%8A%E6%9B%B4%E4%BE%9B%E6%AC%BE%E9%80%B1%E6%9C%9F%20%2B%20DCA%20%2B%20Cancel%20BSO.pdf?alt=media&token=d75a71b7-4fd5-4ca2-96c9-1d2305cfa152' },
  { key: '42', text: '70. 降低供款金額與週期', value: '42', url: URL + '/demo%2F70.%20%E9%99%8D%E4%BD%8E%E4%BE%9B%E6%AC%BE%E9%87%91%E9%A1%8D%E8%88%87%E9%80%B1%E6%9C%9F.pdf?alt=media&token=107d3e47-7d1c-4abc-93fc-44a9d99c4102' },
  { key: '43', text: '71. 降低供款金額與週期 + DCA + Cancel BSO', value: '43', url: URL + '/demo%2F71.%20%E9%99%8D%E4%BD%8E%E4%BE%9B%E6%AC%BE%E9%87%91%E9%A1%8D%E8%88%87%E9%80%B1%E6%9C%9F%20%2B%20DCA%20%2B%20Cancel%20BSO.pdf?alt=media&token=97335dea-131c-49ff-99ae-5b63c0c78514' },
  { key: '44', text: '72. 降低供款金額與週期 + DCA', value: '44', url: URL + '/demo%2F72.%20%E9%99%8D%E4%BD%8E%E4%BE%9B%E6%AC%BE%E9%87%91%E9%A1%8D%E8%88%87%E9%80%B1%E6%9C%9F%20%2B%20DCA.pdf?alt=media&token=7c6a83e5-d7aa-4850-82e2-29cb5a23e4d4' },
  { key: '45', text: '73. 降低供款金額與週期 + BSO+Cancel BSO', value: '45', url: URL + '/demo%2F73.%20%E9%99%8D%E4%BD%8E%E4%BE%9B%E6%AC%BE%E9%87%91%E9%A1%8D%E8%88%87%E9%80%B1%E6%9C%9F%20%2B%20BSO%2BCancel%20BSO.pdf?alt=media&token=5762bd95-4c7e-4e15-9602-5b89d01d02d9' },
  { key: '46', text: '74. 降低供款金額與週期 + BSO', value: '46', url: URL + '/demo%2F74.%20%E9%99%8D%E4%BD%8E%E4%BE%9B%E6%AC%BE%E9%87%91%E9%A1%8D%E8%88%87%E9%80%B1%E6%9C%9F%20%2B%20BSO.pdf?alt=media&token=f6ec2e35-aa68-40d8-ae37-1bc06ba4f7d2' },
  { key: '47', text: '75. 降低供款金額與週期 + 匯款指示(TT)', value: '47', url: URL + '/demo%2F75.%20%E9%99%8D%E4%BD%8E%E4%BE%9B%E6%AC%BE%E9%87%91%E9%A1%8D%E8%88%87%E9%80%B1%E6%9C%9F%20%2B%20%E5%8C%AF%E6%AC%BE%E6%8C%87%E7%A4%BA(TT).pdf?alt=media&token=8e81e9c5-1601-42f5-9434-f4c2aa9ab269' },
  { key: '48', text: '76. 降低供款金額與週期 + 匯款指示(TT) + Cancel BSO', value: '48' },
  { key: '49', text: '77. 變更供款方式 + 匯款指示(TT) + Cancel BSO', value: '49' },
  { key: '50', text: '78. 變更供款方式 + 匯款指示(TT)', value: '50' },
  { key: '51', text: '79. 變更供款方式 + DCA', value: '51', url: URL + '/demo%2F79.%20%E8%AE%8A%E6%9B%B4%E4%BE%9B%E6%AC%BE%E6%96%B9%E5%BC%8F%20%2B%20DCA.pdf?alt=media&token=3bdc861f-3d43-4943-9d39-559251e47a85' },
  { key: '52', text: '80. 變更供款方式 + DCA+Cancel BSO', value: '52', url: URL + '/demo%2F80.%20%E8%AE%8A%E6%9B%B4%E4%BE%9B%E6%AC%BE%E6%96%B9%E5%BC%8F%20%2B%20DCA%2BCancel%20BSO.pdf?alt=media&token=966814fa-0407-4ff4-a5c7-903c4a8872bb' },
  { key: '53', text: '81. 變更供款方式 + BSO', value: '53', url: URL + '/demo%2F81.%20%E8%AE%8A%E6%9B%B4%E4%BE%9B%E6%AC%BE%E6%96%B9%E5%BC%8F%20%2B%20BSO.pdf?alt=media&token=ba5e35c3-5eac-4f66-b09c-02756e75e81e' },
  { key: '54', text: '82. 變更供款方式 + BSO+ Cancel BSO', value: '54', url: URL + '/demo%2F82.%20%E8%AE%8A%E6%9B%B4%E4%BE%9B%E6%AC%BE%E6%96%B9%E5%BC%8F%20%2B%20BSO%2B%20Cancel%20BSO.pdf?alt=media&token=e13e46fc-caf9-42de-9c84-c5f8861e99d2' },
  { key: '55', text: '83. 變更供款方式 + Cancel BSO', value: '55', url: URL + '/demo%2F83.%20%E8%AE%8A%E6%9B%B4%E4%BE%9B%E6%AC%BE%E6%96%B9%E5%BC%8F%20%2B%20Cancel%20BSO.pdf?alt=media&token=9bc78b5d-c70b-49aa-84fb-7bc1ce0c210f' },
  { key: '56', text: '84. 變更供款方式', value: '56' },
  { key: '57', text: '85. 授權第三方付款人支付-銀行帳戶', value: '57', url: URL + '/demo%2F85.%20%E6%8E%88%E6%AC%8A%E7%AC%AC%E4%B8%89%E6%96%B9%E4%BB%98%E6%AC%BE%E4%BA%BA%E6%94%AF%E4%BB%98-%E9%8A%80%E8%A1%8C%E5%B8%B3%E6%88%B6.pdf?alt=media&token=8ae46bee-53eb-4ef3-baf6-bea33a4f67ec' },
  { key: '58', text: '86. 授權第三方付款人支付-信用卡', value: '58', url: URL + '/demo%2F86.%20%E6%8E%88%E6%AC%8A%E7%AC%AC%E4%B8%89%E6%96%B9%E4%BB%98%E6%AC%BE%E4%BA%BA%E6%94%AF%E4%BB%98-%E4%BF%A1%E7%94%A8%E5%8D%A1.pdf?alt=media&token=f37ac8e3-35ba-4c21-b5b7-ed59b79da6f4' },
  { key: '59', text: '87. 增加供款-Permier_v1', value: '59', url: URL + '/demo%2F87.%20%E5%A2%9E%E5%8A%A0%E4%BE%9B%E6%AC%BE-Permier_v1.pdf?alt=media&token=c9772704-86c3-4005-9c5c-0a365fdceee4' },
  { key: '68', text: '87-1. 增加供款-Permier(未超過保額)', value: '68' },
  { key: '70', text: '87-2. 增加供款-Permier (已超過保額)', value: '70' },
  { key: '60', text: '88. 增加供款-Permier Ultra_v1', value: '60', url: URL + '/demo%2F88.%20%E5%A2%9E%E5%8A%A0%E4%BE%9B%E6%AC%BE-Permier%20Ultra_v1.pdf?alt=media&token=64147fc6-8fd3-4390-b976-9dda7a1514cd' },
  { key: '69', text: '88-1. 增加供款-Permier Ultra(未超過保額)', value: '69' },
  { key: '71', text: '88-2. 增加供款-Permier Ultra(已超過保額)', value: '71' },
  { key: '64', text: '89. 匯款指示', value: '64', url: URL + '/demo%2F89.%20%E5%8C%AF%E6%AC%BE%E6%8C%87%E7%A4%BA.pdf?alt=media&token=e552018d-9b64-45fa-ba05-f514122fca71' },
  { key: '66', text: '90. 委任第三方受益人_v1', value: '66', url: URL + '/demo%2F90.%20%E5%A7%94%E4%BB%BB%E7%AC%AC%E4%B8%89%E6%96%B9%E5%8F%97%E7%9B%8A%E4%BA%BA_v1.pdf?alt=media&token=a0442168-b67a-40e9-9f8a-52103ac1c9a6' },
  { key: '85', text: '90-1. 委任第三方受益人_v2', value: '85' },
  { key: '67', text: '91. 變更服務代表', value: '67', url: URL + '/demo%2F91.%20%E8%AE%8A%E6%9B%B4%E6%9C%8D%E5%8B%99%E4%BB%A3%E8%A1%A8.pdf?alt=media&token=a4aa4f98-05a1-42a8-a974-6619a2e89ad2' },
  { key: '90', text: '92. 恢復供款', value: '90' },
]

const LabelInput = ({ id, content, value, icon, readOnly = false, onChange = null }) =>
  <Input size='mini' action={{ size: 'mini', basic: true, color: readOnly ? '' : 'blue', labelPosition: 'left', icon, content, }} id={id} name={id} style={{ marginBottom: '1px' }}
    placeholder={content} value={value} readOnly={readOnly} actionPosition='left' type='text' fluid onChange={onChange} />

export default class PdfModal2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfsrc: '',
      code: '',
      showPdf: false,
      obj: {},
      form: {},
      // doc: '',
      serviceId: '@@',
      url: ''
    };
  }

  componentDidMount() {
    const { doc, } = this.props;
    // const { clients } = 
    // const clientObj = clients.find(item => item._id === doc.client_id) || {}
    this.setState({
      form: {
        ...doc,
        identify: '', addr1_eng: '', policy_currency: '', policyxx_num: '', birthday: '',
        last_name: doc.name_eng, first_name: doc.name_eng,
        product: doc.product4_text + " (" + doc.product4_engtext + ")",
        insertedBy_CE: doc.product4_text + " (" + doc.product4_engtext + ")",
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    const { doc, } = nextProps;
    // const { clients } = 

    // const clientObj = clients.find(item => item._id === doc.client_id) || {}
    this.setState({
      form: {
        ...doc,
        identify: '', addr1_eng: '', policy_currency: '', policyxx_num: '', birthday: '',
        last_name: doc.name_eng,
        first_name: doc.name_eng,
        product: doc.product4_text + " (" + doc.product4_engtext + ")",
        insertedBy_CE: doc.product4_text + " (" + doc.product4_engtext + ")",
      }
    })
  }

  handleShowPdf = () => {
    const { form, serviceId } = this.state
    this.setState({ showPdf: true });

    // const { code = `doc.text(15, 15, '尚未輸入程式碼');`, format = "A4", orientation = "P" } = formSet.find(item => item.key === codekey) || {};
    // const codeForm = this.handleRowdata();
    const codeForm = { code: `doc.text(15, 15, '尚未輸入程式碼');`, format: "a4", orientation: "p" }; // 真正要印的東西
    // console.log(codeForm)
    // console.log(nowform)
    // const nowform = form;
    this.setState({ pdfsrc: null }, () => {
      getPdfsrcAsync({ ...this.props, codeForm, nowservice: serviceId, params: { form } })
        .then(({ pdfsrc, doc }) => { this.setState({ pdfsrc, doc }); })
    });
  }

  handleChange = (event, { name, value }) => {
    if (name === 'serviceId') {
      const url = serviceSel.find(item => item.value === value) ? serviceSel.find(item => item.value === value).url || '' : ''
      this.setState({ [name]: value, url }, () => this.handleShowPdf());
    } else {
      this.setState({ form: { ...this.state.form, [name]: value, } });
    }
  }

  handleAdd = async () => {
    const { companyObj, doc, clients } = this.props
    const { form, serviceId } = this.state
    // console.log("add")
    // console.log(form)
    // const db = firebase.firestore();
    const serviceItem = serviceSel.find(item => item.value === serviceId) || ''
    const clientObj = clients.find(item => item.objectId === form.client_id) || {}
    // console.log(form)
    // const docRef = db.collection(`company/${companyObj._id}/bg/${bgId}/clients/${doc.client_id}/portfolios/${doc._id}/as`).doc()
    const db = Parse.Object.extend("afterservice");
    const newDoc = new db();
    const docRef = await newDoc.save();
    const obj = {
      // ...form,
      ...clientObj,
      id: docRef.id,
      _id: docRef.id,
      objectId: docRef.id,
      portfolio_id: form.objectId || '',
      client_id: clientObj.objectId || '',
      uid: form.uid || '',
      account_num: form.account_num || '',
      product4_text: form.product4_text || '',
      provider_id: form.provider_id || '',
      provider_chttext: form.provider_chttext || '',
      provider_engtext: form.provider_engtext || '',
      serviceText: serviceItem.text,
      serviceId: serviceItem.value,
      email: form.email || '',
      status: "1",
      status_text: "申請中",
      as_action: "2",
      as_action_owner: "4",
    };
    // console.log(obj)
    // const docRef = db.collection(`company/${companyObj._id}/bg/${bgId}/as`).add(obj.id);
    // await docRef.set(obj);
    await Parse.saveData('afterservice', obj);
    this.props.history.push(`/admin/After3`);
  }

  render() {
    const { title = '產生PDF', showPdf } = this.props
    const { serviceId, form, pdfsrc, url } = this.state;
    // console.log(pdfsrc)
    return (<>
      <Modal
        isOpen={showPdf}
        toggle={this.props.handleClosePdf}
        size="lg"
        className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader >
          <h4 style={{ margin: 0 }}>產生PDF</h4>
          <Icon name='close' onClick={this.props.handleClosePdf} style={{ cursor: 'pointer', margin: 0 }}></Icon>
        </ModalHeader>
        <ModalBody>
          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <Form>
                  <Form.Select inline fluid label='服務項目' options={serviceSel} name='serviceId' value={serviceId} onChange={this.handleChange} />
                  <LabelInput id='name_cht' icon='user' content='客戶姓名' value={form.name_cht} readOnly={true} />
                  <LabelInput id='product4_text' icon='user' content='產品名' value={form.product4_text} readOnly={true} />
                  <LabelInput id='name_eng' icon='user' content='英文名字' value={form.name_eng} onChange={this.handleChange} />
                  <LabelInput id='contactnum' icon='user' content='聯絡電話' value={form.contactnum} onChange={this.handleChange} />
                  <LabelInput id='email' icon='user' content='電子信箱' value={form.email} onChange={this.handleChange} />
                  <LabelInput id='account_num' icon='user' content='計劃號碼' value={form.account_num} onChange={this.handleChange} />
                  <LabelInput id='account_num2' icon='user' content='保單號碼' value={form.account_num2} onChange={this.handleChange} />
                  <LabelInput id='addr1_eng' icon='user' content='聯絡地址' value={form.addr1_eng} onChange={this.handleChange} />
                  <LabelInput id='insertedBy' icon='user' content='計劃名稱' value={form.insertedBy} onChange={this.handleChange} />

                  <LabelInput content='HSBC姓名' id='hsbc_account_name' icon='user' value={form.hsbc_account_name} onChange={this.handleChange} />
                  <LabelInput content='HSBC號碼' id='hsbc_account_num' icon='user' value={form.hsbc_account_num} onChange={this.handleChange} />
                  <LabelInput content='姓' id='last_name' icon='user' value={form.last_name} onChange={this.handleChange} />
                  <LabelInput content='名' id='first_name' icon='user' value={form.first_name} onChange={this.handleChange} />
                  <LabelInput content='提取款額' id='withdrawlamount' icon='user' value={form.withdrawlamount} onChange={this.handleChange} />
                  <LabelInput content='退保提款原因' id='with_reason' icon='user' value={form.with_reason} onChange={this.handleChange} />
                  <LabelInput content='信用卡銀行' id='creditcard_bank' icon='user' value={form.creditcard_bank} onChange={this.handleChange} />
                  <LabelInput content='發卡銀行國家' id='creditcard_bankname' icon='user' value={form.creditcard_bankname} onChange={this.handleChange} />
                  <LabelInput content='信用卡號碼' id='creditcard_num' icon='user' value={form.creditcard_num} onChange={this.handleChange} />
                  <LabelInput content='信用卡效期(月)' id='creditcard_term_month' icon='user' value={form.creditcard_term_month} onChange={this.handleChange} />
                  <LabelInput content='信用卡效期(年)' id='creditcard_term_year' icon='user' value={form.creditcard_term_year} onChange={this.handleChange} />
                  <LabelInput content='現時定額供款' id='fpi_now_same_payment' icon='user' value={form.fpi_now_same_payment} onChange={this.handleChange} />
                  <LabelInput content='現時定額供款(W)' id='fpi_now_same_payment_words' icon='user' value={form.fpi_now_same_payment_words} onChange={this.handleChange} />
                  <LabelInput content='附加資料' id='add_imfo' icon='user' value={form.add_imfo} onChange={this.handleChange} />
                  <LabelInput content='預設勾選' id='check' icon='user' value={form.check} onChange={this.handleChange} />

                  <LabelInput id='custom_1' icon='user' content='自訂一' value={form.custom_1} onChange={this.handleChange} />
                  <LabelInput id='custom_2' icon='user' content='自訂二' value={form.custom_2} onChange={this.handleChange} />
                  <LabelInput id='custom_3' icon='user' content='自訂三' value={form.custom_3} onChange={this.handleChange} />
                  <LabelInput id='custom_4' icon='user' content='自訂四' value={form.custom_4} onChange={this.handleChange} />
                  <LabelInput id='custom_5' icon='user' content='自訂五' value={form.custom_5} onChange={this.handleChange} />
                  <LabelInput id='custom_6' icon='user' content='自訂六' value={form.custom_6} onChange={this.handleChange} />
                  <LabelInput id='custom_7' icon='user' content='自訂七' value={form.custom_7} onChange={this.handleChange} />
                  <LabelInput id='custom_8' icon='user' content='自訂八' value={form.custom_8} onChange={this.handleChange} />
                  <LabelInput id='custom_9' icon='user' content='自訂九' value={form.custom_9} onChange={this.handleChange} />
                  <LabelInput id='custom_10' icon='user' content='自訂十' value={form.custom_10} onChange={this.handleChange} />
                  <LabelInput id='custom_11' icon='user' content='自訂十一' value={form.custom_11} onChange={this.handleChange} />
                  <LabelInput id='custom_12' icon='user' content='自訂十二' value={form.custom_12} onChange={this.handleChange} />
                  <LabelInput id='custom_13' icon='user' content='自訂十三' value={form.custom_13} onChange={this.handleChange} />
                  <LabelInput id='custom_14' icon='user' content='自訂十四' value={form.custom_14} onChange={this.handleChange} />
                  <LabelInput id='custom_15' icon='user' content='自訂十五' value={form.custom_15} onChange={this.handleChange} />
                </Form>
              </Grid.Column>
              <Grid.Column width={10}>
                {serviceId && serviceId !== '@@' && serviceId !== '0' ? <PdfIframe pdfsrc={pdfsrc} height={'100%'} doc={form} /> : '無PDF內容'}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          {/* <Grid> */}
          <Grid.Row>
            <Grid.Column width={6}></Grid.Column>
            <Grid.Column width={10}>
              <Button onClick={() => this.handleShowPdf()} content='重新製作PDF' disabled={!url || serviceId === '0'} />
              <Button onClick={() => window.open(url)} content='範例' disabled={!url || serviceId === '0'} />
              <Button onClick={this.handleAdd} icon='checkmark' content='新申請' disabled={!url} />
              <Button onClick={this.props.handleClosePdf} icon='close' content='關閉' />
            </Grid.Column>
          </Grid.Row>
          {/* </Grid> */}
        </ModalFooter>
      </Modal>

    </>)
  }
}