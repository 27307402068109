import React, { Component } from 'react';
import { Form, Tabs, Tab, Card, Grid, Button, Confirm, Header } from 'semantic-ui-react';
import { showField, showFieldText, getAvailblePeriodArr, getDate } from 'views/widgets/FieldsRender';
import { Row, Col } from 'reactstrap';

export default class List6Student extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  removeRow = () => {
    const { firebase, match, history, refetch } = this.props
    const { _id, list } = match.params;
    firebase.firestore().collection('Students').doc(_id).delete().then(() => {
      this.close();
      refetch('Students');
      this.setState({ doc: {}, form: {} }, () => {
        history.push(`/StudentPage/${list}`);
      })
    })
  }
  render() {
    return (<>
      <Header as='h2' dividing
        content='備考'
        style={{ backgroundColor: 'lavender' }}
      // subheader='調査時間：2017年4月から 2018年12月 まで'
      />
      <br />
      <Row>
        {/* <Grid columns='equal'> */}
        <Col>{showField(this.props, "ps")}</Col>
        {/* </Grid> */}
      </Row>
      <br />
      {/* <h3>1-1-① 入学問合</h3>
      <Row>
        <Grid columns='equal'>
          <Col>{showField(this.props, "lastContactTime")}</Col>
          <Col>{showField(this.props, "contactMethod")}</Col>
        </Grid>
      </Row> */}
      <h3>2-1-① 選考結果許可証発給管理</h3>
      <Row>
        {/* <Grid columns='equal'> */}
        <Col>{showField(this.props, "testResult")}</Col>
        <Col>{showField(this.props, "interviewResult")}</Col>
        <Col>{showField(this.props, "admissionLiceResult")}</Col>
        <Col>{showField(this.props, "admissionLiceNum")}</Col>
        <Col>{showField(this.props, "ispassInform")}</Col>
        {/* </Grid> */}
      </Row>
      {/* <h3>2-1-③ 入学許可書</h3>
      <Row>
        <Grid columns='equal'>
          <Col>{showField(this.props, "theResultAdmissionPermit")}</Col>
          <Col>{showField(this.props, "issueAdmissionPermit")}</Col>
          <Col>{showField(this.props, "validityAdmissionPermit")}</Col>
          <Col>{showField(this.props, "numAdmissionPermit")}</Col>
        </Grid>
      </Row> */}
      <h3>2-1-③ 在留資格認定交付者管理</h3>
      <Row>
        {/* <Grid columns='equal'> */}
        {/* <Col>{showField(this.props, "resultOfCertificateOfEligibility")}</Col> */}
        {/* <Col>{showField(this.props, "visaResult")}</Col>
          <Col>{showField(this.props, "enrollmentSituation")}</Col> */}
        <Col>{showField(this.props, "informDate")}</Col>
        <Col>{showField(this.props, "informWay")}</Col>
        <Col>{showField(this.props, "tuitionFee")}</Col>
        <Col>{showField(this.props, "feeReceivedDate")}</Col>
        {/* <Col>{showField(this.props, "interviewResult")}</Col> */}
        {/* </Grid> */}
      </Row>
      <h3>3-1-⑤ 来日予定</h3>
      <Row>
        {/* <Grid columns='equal'> */}
        <Col>{showField(this.props, "expectedDateOfEntry")}</Col>
        <Col>{showField(this.props, "arrivalTime")}</Col>
        <Col>{showField(this.props, "whetherPickedUp")}</Col>
        <Col>{showField(this.props, "accommodations")}</Col>
        {/* </Grid> */}
      </Row>
      <Row>
        {/* <Grid columns='equal'> */}
        <Col>{showField(this.props, "checkinNum")}</Col>
        <Col>{showField(this.props, "checkInSchoolDate")}</Col>
        <Col>{showField(this.props, "checkInSchoolPs")}</Col>
        {/* </Grid> */}
      </Row>
      <h3>2-1-③ 在留資格認定不交付者管理</h3>
      <Row>
        {/* <Grid columns='equal'> */}
        {/* <Col>{showField(this.props, "resultOfCertificateOfEligibility")}</Col> */}
        {/* <Col>{showField(this.props, "visaResult")}</Col>
          <Col>{showField(this.props, "enrollmentSituation")}</Col> */}
        <Col>{showField(this.props, "notPassReason")}</Col>
        <Col>{showField(this.props, "informDate")}</Col>
        <Col>{showField(this.props, "informWay")}</Col>
        <Col>{showField(this.props, "isApplyAgain")}</Col>
        {/* <Col>{showField(this.props, "interviewResult")}</Col> */}
        {/* </Grid> */}
      </Row>
      <h3>3-1-② 学生証</h3>
      <Row>
        {/* <Grid columns='equal'> */}
        <Col>{showField(this.props, "launchDate")}</Col>
        <Col>{showField(this.props, "dateofEnrollment")}</Col>
        {/* </Grid> */}
      </Row>
      {/*<h3>2-1-⑦ 入学予定申請者</h3>
      <Row>
        <Grid columns='equal'>
          <Col>{showField(this.props, "lastContactTime")}</Col>
          <Col>{showField(this.props, "contactMethod")}</Col>
        </Grid>
      </Row>
      <h3>2-1-⑧ 受入れリスト</h3>
      <Row>
        <Grid columns='equal'>
          <Col>{showField(this.props, "lastContactTime")}</Col>
          <Col>{showField(this.props, "contactMethod")}</Col>
        </Grid>
      </Row>
      <h3>2-1-⑨ 申請結果記録表</h3>
      <Row>
        <Grid columns='equal'>
          <Col>{showField(this.props, "lastContactTime")}</Col>
          <Col>{showField(this.props, "contactMethod")}</Col>
        </Grid>
      </Row>
      <h3>2-1-⑩ 推薦学生</h3>
      <Row>
        <Grid columns='equal'>
          <Col>{showField(this.props, "lastContactTime")}</Col>
          <Col>{showField(this.props, "contactMethod")}</Col>
        </Grid>
      </Row>
      <h3>2-1-⑪ 削除学生</h3>
      <Row>
        <Grid columns='equal'>
          <Col>{showField(this.props, "lastContactTime")}</Col>
          <Col>{showField(this.props, "contactMethod")}</Col>
        </Grid>
      </Row> */}
      {/* <br />
      <h3>削除</h3> */}
      <br />
      <Button negative size='small' variant='outline-danger' onClick={this.open} icon='delete' content='学生の資料を削除する' />
      <Confirm content='削除してもいいですか？' confirmButton='はい' cancelButton='いいえ' open={this.state.open} onCancel={this.close} onConfirm={this.removeRow} />
    </>);
  }
}

