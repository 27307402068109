import React, { Component } from 'react';
import { Grid, Table, Header, Divider, Dimmer, Loader, Image, Segment, Input, Icon, Label, Form } from 'semantic-ui-react';
import AttendanceCalculation from '../../../widgets/AttendanceCalculation';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { LocaleUtils } from 'react-day-picker';
import Moment from 'moment';
import { pad } from '../../../../widget/define/string';
import { getThisWeekList, showFieldText, getAvailblePeriodArr, getDate, getMonthWeek } from 'views/widgets/FieldsRender';
import { hidden } from 'ansi-colors';
import ModalPdf from 'widget/pdf/ModalPdf';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton'
import { Link } from 'react-router-dom';
import Parse from '../../../../widget/parse'

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tableShow: {
    display: 'table-row-group',
  },
  tableHide: {
    display: 'none',
  },
  textEllipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '300px'
  }
}

export default class Instruction extends Component {
  constructor(props) {
    super(props);
    const d = new Date();
    this.state = {
      show: false,
      doc: props.doc,
      isEdit: props.isEdit,
      isShow: false,
      form: {},
      isDelete: false,
      relative: [],
      instruct: [],
      loading: false,
      confirm: false,
      showTab: false,
      // eventDate: this.getToday(),
      eventDate: '',
      stu: [],
      specData: [],
      yearmonth: '',
      students: []
      // yearmonth: d.getFullYear().toString() + '-' + pad(d.getMonth() + 1, 2)
    };
  }

  async componentDidMount() {
    // console.log('componentDidMount');
    const { companyObj } = this.props
    const data = await Parse.queryData('Students', { companyId: companyObj.objectId });
    this.setState({ students: data, loading: true }, () => this.handleFetch())
  }

  // componentWillReceiveProps(nextProps) {
  //   // console.log('componentWillReceiveProps');
  //   this.handleFetch(nextProps);
  // }

  // getSnapshotBeforeUpdate(prevProps, prevState) {
  //   console.log('getSnapshotBeforeUpdate');
  //   if (prevState.eventDate !== this.state.eventDate) {
  //     console.log('change');
  //     this.handleFetch(prevProps);
  //     return {};
  //   }
  //   return null
  // }
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   console.log('componentDidUpdate');
  //   if (snapshot !== null) {
  //     this.handleFetch(prevProps);
  //   }
  // }

  handleFetch = async (props = this.props) => {
    const { firebase, match, companyObj } = props;
    const { yearmonth } = this.state

    // if (yearmonth && yearmonth !== '') {

    //   const specData = await Parse.queryData('Consult', { companyId: companyObj.objectId });
    //   this.setState({ specData, show: true, showTab: true, loading: false })
    //   // console.log(yearmonth, specData);
    // } else {
    const specData = await Parse.queryData('Consult', { companyId: companyObj.objectId });
    specData.sort((a, b) => new Date(b.inquiryDate) - new Date(a.inquiryDate))
    this.setState({ specData, show: true, showTab: true, loading: false }, () => this.getPrintData())
    // console.log(yearmonth, specData);
    // }
  }
  handleChange = (event, data) => {
    const { name, value } = data
    if (name === 'yearmonth') {
      const [year, month1] = value && value.split('-'); // 2019-W10 => 2019, W10
      const month = Number(month1).toString(); // W10 => 10
      this.setState({ yearmonth: value, year, month, loading: 1 }, () => this.handleFetch());
    }
    // const eventDate = Moment(new Date(date)).format('YYYY-MM-DD');
    // this.setState({ eventDate: value }, () => this.handleFetch())
  }

  setSettingOnChange = (setting) => {
    let fieldName = setting.target.name;
    let fleldVal = setting.target.value;

    this.setState({ form: { ...this.state.form, [fieldName]: fleldVal } })
  }

  saveInsertSetting = () => {
    const { form, doc } = this.state;
    // const method = 'student.updateSetting';

    let obj = {};

    if (doc.weeklyRepArr) {
      obj = { weeklyRepArr: [...doc.weeklyRepArr, form] }
    } else {
      obj = { weeklyRepArr: [form] }
    }

    // console.log(obj);

    this.props.setRelativeObj(obj);
    // console.log(this.state);

    // Meteor.call(method, doc, (error, result) => {
    //   if (error) {
    //     Bert.alert(error.reason, 'danger');
    //   } else {
    //     // Bert.alert('Unit inserted!', 'success');
    //     this.closeRowModal();
    //   }
    // });
  }

  getStartDate = (date) => {
    const lastWeek = getThisWeekList(new Date(date));
    return lastWeek[0];
  }

  // getEndDate = (date) => {
  //   const lastWeek = getLastWeekList(new Date(date));
  //   return lastWeek[6];
  // }

  closeTab = () => this.setState({ showTab: false })

  getToday = () => {
    return new Date().toISOString().split('T')[0];
  };

  getPrintData = () => {
    const { formSet, optSet } = this.props
    const { specData, students } = this.state
    let printData = []
    specData.map(item => {
      if (item) {
        let obj = { ...item }
        let student = students.find(stu => stu.objectId === item.studentId);
        obj.studentId = student && student.studentId;
        obj.inquiryDate = item.inquiryDate && Moment(item.inquiryDate.split('T')[0]).format('YYYY/MM/DD');
        obj.inquiryType = showFieldText(formSet, optSet, "inquiryType", item);
        obj.contactMethod = showFieldText(formSet, optSet, "contactMethod", item);
        printData.push(obj)
      }
    })
    this.setState({ printData })
  }

  render() {
    const { doc, formSet, optSet, semesterYear, semester, calendar, classroom, isEdit, companyObj } = this.props;
    const { printData, yearmonth, relative, loading, instruct, showTab, eventDate, studentsInClass, stu, specData, show, students } = this.state
    const arr2 = ['1', '2'];
    const arr4 = ['1', '2', '3', '4']
    const arr5 = ['1', '2', '3', '4', '5'];
    const arr6 = ['1', '2', '3', '4', '5', '6'];

    let startDate, lastWeek = '';
    // const availablePeriodArr = getAvailblePeriodArr(semesterYear, semester);
    // let endDate = getDate(availablePeriodArr[0], 'endDate', false);

    // if (!endDate || !availablePeriodArr.length) {
    //   return <>endDate error!</>;
    // }

    // lastWeek = getLastWeekList(endDate);
    // startDate = lastWeek[0];
    // endDate = lastWeek[6];

    const classRoomArr = classroom.filter(item => item.isEnableClass === true && item.semesterYear === semesterYear && item.semester === semester && item.classSchool === showFieldText('classSchool', doc));

    // console.log(doc);

    return (<>
      <div style={{ ...style.flexCenter }}>
        <Header as='h2' content={`${eventDate} 相談記録一覽表`}
          style={{ backgroundColor: 'lavender', margin: 0 }} /* subheader='調査時間：2017年4月から 2018年12月 まで'*/ />
        {/* {!isEdit && isEdit !== undefined ? <div style={{ ...style.flexCenter }}>
          <Button color='blue' icon='file alternate' content='詳細' onClick={() => this.setState({ showTab: true })} style={{ marginRight: 10 }} />
          <Button color='orange' onClick={() => this.props.history.push(`/printRegisteredStudent/${doc._id}`)}>印刷</Button>
        </div>
          : <Button onClick={this.handleOpen} inverted={companyObj.inverted} color='blue' icon='add' content='追加' />} */}
        {/* <div style={{ ...style.flexCenter, minWidth: 400 }}>
          <Form.Input
            label='試験年月日'
            type='date'
            name='testDate'
            value={eventDate || ''}
            onChange={this.handleChange}
          // readOnly={readOnly}
          /> */}
        <div style={{ display: 'flex', marginTop: '1em' }}>
          <Input labelPosition='left' style={{ marginLeft: 10 }}>
            <Label color={'teal'}> <Icon name='calendar alternate' />月</Label>
            <Form.Input
              style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
              name="yearmonth" type="month" value={yearmonth} onChange={this.handleChange} />
          </Input>
          <ExportTableToExcelButton size='small' />
          <ModalPdf
            {...this.props}
            // ref={(ref) => { this.pdfModal = ref }}
            codekey="instructionPdf"
            params={printData}
          />
          {/* </div> */}
        </div>
      </div>
      <Divider />
      <Grid.Row>
        <Table id='excel' celled size="small" style={{ textAlign: 'center', tableLayout: "auto", display: showTab ? 'table' : 'none', opacity: showTab ? '1' : '0', transition: '.5s ease-in-out' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>学籍番号</Table.HeaderCell>
              <Table.HeaderCell>氏名</Table.HeaderCell>
              {/* <Table.HeaderCell>クラス</Table.HeaderCell> */}
              <Table.HeaderCell>相談日</Table.HeaderCell>
              <Table.HeaderCell style={{ ...style.textEllipsis }} >相談內容</Table.HeaderCell>
              <Table.HeaderCell >相談類別</Table.HeaderCell>
              <Table.HeaderCell >連絡法</Table.HeaderCell>
              <Table.HeaderCell >連絡資訊</Table.HeaderCell>
              <Table.HeaderCell >経辦人</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body style={!show ? style.tableHide : style.tableShow}>
            {specData && specData.length ? specData.map((item, index) => {
              let stu = students.find(stu => stu.objectId === item.studentId)
              return <React.Fragment key={`specData${index}`}>
                <Table.Row>
                  {/* <Table.Cell width="10%">今週</Table.Cell> */}
                  <Table.Cell>{stu && stu.studentId}</Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/5`}>{item.studentJpnName}</Link></Table.Cell>
                  {/* <Table.Cell>{stu && stu.classSchool}</Table.Cell> */}
                  <Table.Cell>{item.inquiryDate && Moment(item.inquiryDate.split('T')[0]).format('YYYY/MM/DD')}</Table.Cell>
                  <Table.Cell style={{ ...style.textEllipsis, textAlign: 'left' }}>{item.content}</Table.Cell>
                  <Table.Cell>{showFieldText(formSet, optSet, "inquiryType", item)}</Table.Cell>
                  <Table.Cell>{showFieldText(formSet, optSet, "contactMethod", item)}</Table.Cell>
                  <Table.Cell>{item.contactInfo}</Table.Cell>
                  <Table.Cell>{item.handlerName}</Table.Cell>
                </Table.Row>
              </React.Fragment>
            }) : <Table.Row><Table.Cell colSpan='6' textAlign='center'>(資料無し)</Table.Cell></Table.Row>}
          </Table.Body>
        </Table>
      </Grid.Row>
      {loading ? <div style={{ display: !show ? 'flex' : 'none', justifyContent: 'center', margin: '30px 0' }}>
        <Segment>
          <Dimmer active inverted={companyObj.inverted}>
            <Loader inverted={companyObj.inverted}>Loading</Loader>
          </Dimmer>
          <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        </Segment>
      </div> : null}
    </>);
  }
}

