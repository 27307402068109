import React, { Component } from 'react'
import { Container, Button, Dimmer, Loader, Image, Segment, Header, Select, Form } from 'semantic-ui-react';
import MaterialTable from 'material-table'

import localization from 'widget/MaterialTableOpt'
import Moment from 'moment';
import { dateCol, typeCurrency, monthOpt, mgyearOpt, exportPdf } from "views/web/CustomMaterialTable";
import Parse from 'widget/parse'
import C13a from './C13a';

// const roomType = { '0': '單人房', '1': '雙人房' };

const columns = (roomArr = []) => ([
  dateCol({ width: 100, title: '繳費日期', field: 'payDate' }),
  dateCol({ width: 100, title: '開始日期', field: 'startDate' }),
  dateCol({ width: 100, title: '結束日期', field: 'endDate' }),
  { width: 80, title: '房號', field: 'roomNum' },
  {
    width: 80, title: '每月應繳金額', field: 'receiveNum',
    render: rowData => (roomArr.find(item => item.roomNum === rowData.roomNum) && roomArr.find(item => item.roomNum === rowData.roomNum).rent) || '',
  },
  { width: 80, title: '繳費金額', field: 'price' },
]);

const columns2 = () => ([
  dateCol({ width: 80, title: '支出日期', field: 'date' }),
  { width: 80, title: '支出項目', field: 'expenditureItem' },
  { width: 80, title: '金額', field: 'money' },
  { width: 80, title: '備註', field: 'ps' },
]);

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

const d = new Date()

export default class Client1 extends Component {
  state = {
    data1: [],
    data2: [],
    roomArr: [],
    rowData: {},
    roomObj: {},
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
    searchDate: Moment(d).format('YYYY-MM'),
    loading: true,
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async () => {
    const { companyObj } = this.props;
    const companyId = companyObj.objectId
    const { searchDate, mgyearSel, monthSel } = this.state
    const data = await Parse.queryData('paying1', { companyId, paymentType: '5' });
    const data2 = data.filter(item => (Date.parse(new Date(item.startDate))).valueOf() < (Date.parse(new Date(searchDate))).valueOf() && (Date.parse(new Date(item.endDate))).valueOf() > (Date.parse(new Date(searchDate))).valueOf())
    // console.log(data2)
    const data1 = await Parse.queryData('room', { companyId });
    const data3 = await Parse.queryData('expenditure', { companyId, year: mgyearSel, month: monthSel });
    this.setState({ data1: data2.sort((a, b) => ('' + a.roomNum).localeCompare(b.roomNum)), data2: data3, roomArr: data1, loading: false })
  }

  handleChange = (e, { name, value }) => {
    const { mgyearSel, monthSel } = this.state
    if (name === 'mgyearSel') {
      this.setState({ searchDate: `${Number(value) + 1911}-0${monthSel}-01` })
    } else if (name === 'monthSel') {
      this.setState({ searchDate: `${Number(mgyearSel) + 1911}-0${value}-01` })
    }
    this.setState({ [name]: value }, () => this.handleFetch())
  }

  //   var ScheduleDate = "2016-06-01 00:00:00", CurrentDate = "2016-06-02 00:00:00";

  // if ( (Date.parse(ScheduleDate)).valueOf() < (Date.parse(CurrentDate)).valueOf())
  // {
  // alert("ScheduleDate比系統目前時間小");
  // }

  render() {
    const { data1, data2, mgyearSel, monthSel, loading, roomArr } = this.state;

    return (<>
      <div className='content'>
        <div style={{ ...style.flexCenter, position: 'relative', zIndex: 100 }}>
          {/* <Header as='h2' style={{ margin: '2rem 0' }}>客房資料</Header> */}
        </div>
        <Select name="mgyearSel" style={{ position: 'relative', zIndex: 99, marginRight: '8px' }} options={mgyearOpt} value={mgyearSel} onChange={this.handleChange} />
        <Select name="monthSel" style={{ position: 'relative', zIndex: 99, marginRight: '8px' }} options={monthOpt} value={monthSel} onChange={this.handleChange} />
        <br />
        <br />
        <Form>
          <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns(roomArr)}
            options={{
              pageSize: 5,
              search: false,
              tableLayout: 'fixed'
            }}
            data={data1}
            title={'收入'}
          // actions={[
          //   {
          //     icon: 'view_headline',
          //     tooltip: '查看',
          //     onClick: (event, rowData) => this.handleData(rowData)
          //   }
          // ]}
          // editable={{
          //   onRowAdd: newData =>
          //     new Promise(async (resolve, reject) => {
          //       const data = [...data1];
          //       const db = Parse.Object.extend("");
          //       const newDoc = new db();
          //       const doc = await newDoc.save();
          //       const obj = {
          //         id: doc.id,
          //         _id: doc.id,
          //         companyId: companyObj.id,
          //         companyName: companyObj.companyName,
          //         ...newData
          //       }
          //       // data.push(obj);
          //       data.push(obj);
          //       this.setState({ data1: data, newData: obj }, () => resolve());
          //     }).then(() => this.handleAdd(1)),
          //   onRowUpdate: (newData, oldData) =>
          //     new Promise((resolve, reject) => {
          //       const data = data1;
          //       const index = data.indexOf(oldData);
          //       data[index] = newData;
          //       // handleState('people0', data);
          //       this.setState({ data1: data, newData, oldData }, () => resolve());
          //     }).then(() => this.handleUpdate(1)),
          //   onRowDelete: oldData =>
          //     new Promise((resolve, reject) => {
          //       let data = data1;
          //       const index = data.indexOf(oldData);
          //       data.splice(index, 1);
          //       // handleState('people0', data);
          //       this.setState({ data1: data, oldData }, () => resolve());
          //     }).then(() => this.handleDelete(1)),
          // }}
          />
          <br />
          <br />
          <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns2()}
            options={{
              pageSize: 5,
              search: false,
              tableLayout: 'fixed'
            }}
            data={data2}
            title={'支出'}
          // actions={[
          //   {
          //     icon: 'view_headline',
          //     tooltip: '查看',
          //     onClick: (event, rowData) => this.handleData(rowData)
          //   }
          // ]}
          // editable={{
          //   onRowAdd: newData =>
          //     new Promise(async (resolve, reject) => {
          //       const data = [...data1];
          //       const db = Parse.Object.extend("");
          //       const newDoc = new db();
          //       const doc = await newDoc.save();
          //       const obj = {
          //         id: doc.id,
          //         _id: doc.id,
          //         companyId: companyObj.id,
          //         companyName: companyObj.companyName,
          //         ...newData
          //       }
          //       // data.push(obj);
          //       data.push(obj);
          //       this.setState({ data1: data, newData: obj }, () => resolve());
          //     }).then(() => this.handleAdd(1)),
          //   onRowUpdate: (newData, oldData) =>
          //     new Promise((resolve, reject) => {
          //       const data = data1;
          //       const index = data.indexOf(oldData);
          //       data[index] = newData;
          //       // handleState('people0', data);
          //       this.setState({ data1: data, newData, oldData }, () => resolve());
          //     }).then(() => this.handleUpdate(1)),
          //   onRowDelete: oldData =>
          //     new Promise((resolve, reject) => {
          //       let data = data1;
          //       const index = data.indexOf(oldData);
          //       data.splice(index, 1);
          //       // handleState('people0', data);
          //       this.setState({ data1: data, oldData }, () => resolve());
          //     }).then(() => this.handleDelete(1)),
          // }}
          />
        </Form>
      </div>
    </>)
  }
}