import React, { Component } from 'react'
import { Grid, Form, Tabs, Tab, Nav, Card, Modal, Container, Input, Select, Button, Label, Segment, Icon, Header } from 'semantic-ui-react';
// import Moment from 'moment';
import ReactToPrint from "react-to-print";
import Parse from 'widget/parse'

import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import { getLatestYearWeek, getLatestYear, getLatestWeek, getMinWeek, getMaxWeek, getDateOfISOWeek, getPrevYearWeek, getNextYearWeek, getWeekdayRangeArrDate, getWeekdayRangeArr } from '../../../widget/define/week';
import SelectWeek from '../../widgets/SelectWeek';
import ClassProgression from './ClassProgression';
const weekdayArr2 = ['月', '火', '水', '木', '金'];

export class ClassProgressionPage extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
      classArr: [], // 現在所有的classroom
      loadingClassStu: true,
      classObj: {},
      yearWeek: getLatestYearWeek(props.semesterYear, props.semester),
      year: getLatestYear(props.semesterYear, props.semester),
      week: getLatestWeek(props.semesterYear, props.semester),
      minWeek: getMinWeek(props.semesterYear, props.semester),
      maxWeek: getMaxWeek(props.semesterYear, props.semester),
      unsubArr: [null, null, null, null, null],
      // rollcallRollcallArr: [[], [], [], [], []],
      rollcallArr0: [],
      rollcallArr1: [],
      rollcallArr2: [],
      rollcallArr3: [],
      rollcallArr4: [],
    };
  }

  componentDidMount() {
    // console.log('componentDidMount')
    this.handleClassroom(this.props)
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps')
    if (
      nextProps.semesterYear !== this.props.semesterYear ||
      nextProps.semester !== this.props.semester) {

      this.setState({
        yearWeek: getLatestYearWeek(nextProps.semesterYear, nextProps.semester),
        year: getLatestYear(nextProps.semesterYear, nextProps.semester),
        week: getLatestWeek(nextProps.semesterYear, nextProps.semester),
        minWeek: getMinWeek(nextProps.semesterYear, nextProps.semester),
        maxWeek: getMaxWeek(nextProps.semesterYear, nextProps.semester),
      }
        , () => this.handleProTab(nextProps)
      );
    }
    if (nextProps.classId !== this.props.classId) {
      this.handleClassroom(nextProps)
    }
  }

  handleClassroom = (props = this.props) => {
    const { eventDate, classArr } = this.state
    const { classId, classroom, classroomL, handleLazyLoad } = props
    if (!classroomL) {
      handleLazyLoad('classroom');
    } else if (classId !== '-1') {
      const classObj = classroom.find(item => item.objectId === classId);

      const period = classObj.classTime === '1' ? '0' : '4';

      const periodArr1 = classObj ? (classObj.classTime === '1' ? [0, 1, 2, 3] : [4, 5, 6, 7]) : [];
      const periodArr2 = classObj ? (classObj.classTime === '1' ? ['一', '二', '三', '四'] : ['五', '六', '七', '八']) : [];

      const weekday = eventDate && (new Date(eventDate).getDay() - 1) + "";
      const courseName = eventDate && period ? weekdayArr2[Number(weekday)] + periodArr2[period % 4] : '';

      const selPeriodArr = [];
      periodArr1.forEach((item, index) => selPeriodArr.push({ key: "p1" + index, text: periodArr2[index], value: item.toString() }));
      this.setState({ classObj, weekday, period, periodArr1, periodArr2, selPeriodArr, courseName },
        () => this.handleProTab(props)
      );
    }
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value, loading: 1 });
  }

  handleWeekChange = (e, { name, value }) => {
    const [year, Wweek] = value.split('-'); // 2019-W10 => 2019, W10
    const week = Wweek.slice(1); // W10 => 10
    this.setState({ yearWeek: value, year: Number(year), week: Number(week), loading: 1 }, () => this.handleProTab(this.props));
  };

  handleProTab = async (props = this.props) => {
    const { firebase, calendar } = props;
    const { year, week, unsubArr, classObj } = this.state;
    const { currentSubscription0, currentSubscription1, currentSubscription2, currentSubscription3, currentSubscription4 } = this.state
    const weekStartDate = getDateOfISOWeek(year, week, 0);
    const weekDateRange2 = getWeekdayRangeArr(weekStartDate);
    // console.log(weekDateRange2)
    const funcAggDate = (snap) => {
      const arr = [];
      snap.forEach(doc => {
        var obj = { ...doc }
        console.log(obj);
        arr.push({
          scheduledProgress: obj.scheduledProgress,
          actualProgress: obj.actualProgress,
          comment1: obj.comment1,
          ps: obj.ps,
          classroomId: obj.classroomId,
          weekday: obj.weekday,
          period: obj.period
        })
      })
      return arr;
    }
    let arrCalendar = calendar.filter(item => item.eventDate === weekDateRange2[0] && item.status === "3");
    // unsubArr.forEach(item => item && item.unsub && item.unsub());
    const query = new Parse.Query('Progress');
    query.equalTo('classroomId', classObj.objectId)
    const query1 = query
    const query2 = query
    const query3 = query
    const query4 = query
    query.equalTo('eventDate', weekDateRange2[0])
    query1.equalTo('eventDate', weekDateRange2[1])
    query2.equalTo('eventDate', weekDateRange2[2])
    query3.equalTo('eventDate', weekDateRange2[3])
    query4.equalTo('eventDate', weekDateRange2[4])
    query.limit(10000);

    const unsub0 = !arrCalendar.length && query.find().then(doc => {
      const data = funcAggDate(doc)
      this.setState({ rollcallArr0: data })
    })

    if (currentSubscription0) {
      // console.log('currentSubscription.unsubscribe')
      await currentSubscription0.unsubscribe();
      this.setState({ currentSubscription0: null })
    }
    let subscription = await query.subscribe().catch(error => console.error(error));
    subscription.on('create', (data) => {
      const rollcallArr0 = [...this.state.rollcallArr0, data.toJSON()];
      this.setState({ rollcallArr0 })
    });
    subscription.on('update', (data) => {
      const rollcallArr0 = [...this.state.rollcallArr0, data.toJSON()];
      this.setState({ rollcallArr0 })
    });
    subscription.on('delete', (data) => {
      const rollcallArr0 = [...this.state.rollcallArr0, data.toJSON()];
      this.setState({ rollcallArr0 })
    });
    this.setState({ currentSubscription0: subscription })
    // firebase.firestore().collectionGroup('Progress')
    //       .where('eventDate', '==', weekDateRange2[0])
    //       .where('classroomId', '==', classObj._id)
    //       .onSnapshot(snap => {
    //         // console.log(snap)
    //         const data = funcAggDate(snap);
    //         this.setState({ rollcallArr0: data });
    //       }, err => {
    //         console.log(`Encountered error: ${err}`);
    //       });
    arrCalendar = calendar.filter(item => item.eventDate === weekDateRange2[1] && item.status === "3")
    const unsub1 = !arrCalendar.length && query1.find().then(doc => {
      const data = funcAggDate(doc)
      this.setState({ rollcallArr1: data })
    })
    if (currentSubscription1) {
      // console.log('currentSubscription.unsubscribe')
      await currentSubscription1.unsubscribe();
      this.setState({ currentSubscription1: null })
    }
    let subscription1 = await query1.subscribe().catch(error => console.error(error));
    subscription1.on('create', (data) => {
      const rollcallArr1 = [...this.state.rollcallArr1, data.toJSON()];
      this.setState({ rollcallArr1 })
    });
    subscription1.on('update', (data) => {
      const rollcallArr1 = [...this.state.rollcallArr1, data.toJSON()];
      this.setState({ rollcallArr1 })
    });
    subscription1.on('delete', (data) => {
      const rollcallArr1 = [...this.state.rollcallArr1, data.toJSON()];
      this.setState({ rollcallArr1 })
    });
    this.setState({ currentSubscription1: subscription1 })
    // firebase.firestore().collectionGroup('Progress')
    //       .where('eventDate', '==', weekDateRange2[1])
    //       .where('classroomId', '==', classObj._id)
    //       .onSnapshot(snap => {
    //         // console.log(snap)
    //         const data = funcAggDate(snap);
    //         this.setState({ rollcallArr1: data });
    //       }, err => {
    //         console.log(`Encountered error: ${err}`);
    //       });
    arrCalendar = calendar.filter(item => item.eventDate === weekDateRange2[2] && item.status === "3")
    const unsub2 = !arrCalendar.length && query2.find().then(doc => {
      const data = funcAggDate(doc)
      this.setState({ rollcallArr2: data })
    })
    if (currentSubscription2) {
      // console.log('currentSubscription.unsubscribe')
      await currentSubscription2.unsubscribe();
      this.setState({ currentSubscription2: null })
    }
    let subscription2 = await query2.subscribe().catch(error => console.error(error));
    subscription2.on('create', (data) => {
      const rollcallArr2 = [...this.state.rollcallArr2, data.toJSON()];
      this.setState({ rollcallArr2 })
    });
    subscription2.on('update', (data) => {
      const rollcallArr2 = [...this.state.rollcallArr2, data.toJSON()];
      this.setState({ rollcallArr2 })
    });
    subscription2.on('delete', (data) => {
      const rollcallArr2 = [...this.state.rollcallArr2, data.toJSON()];
      this.setState({ rollcallArr2 })
    });
    this.setState({ currentSubscription2: subscription2 })
    // firebase.firestore().collectionGroup('Progress')
    //       .where('eventDate', '==', weekDateRange2[2])
    //       .where('classroomId', '==', classObj._id)
    //       .onSnapshot(snap => {
    //         // console.log(snap)
    //         const data = funcAggDate(snap);
    //         this.setState({ rollcallArr2: data });
    //       }, err => {
    //         console.log(`Encountered error: ${err}`);
    //       });
    arrCalendar = calendar.filter(item => item.eventDate === weekDateRange2[3] && item.status === "3")
    const unsub3 = !arrCalendar.length && query3.find().then(doc => {
      const data = funcAggDate(doc)
      this.setState({ rollcallArr3: data })
    })
    if (currentSubscription3) {
      // console.log('currentSubscription.unsubscribe')
      await currentSubscription3.unsubscribe();
      this.setState({ currentSubscription3: null })
    }
    let subscription3 = await query3.subscribe().catch(error => console.error(error));
    subscription3.on('create', (data) => {
      const rollcallArr3 = [...this.state.rollcallArr3, data.toJSON()];
      this.setState({ rollcallArr3 })
    });
    subscription3.on('update', (data) => {
      const rollcallArr3 = [...this.state.rollcallArr3, data.toJSON()];
      this.setState({ rollcallArr3 })
    });
    subscription3.on('delete', (data) => {
      const rollcallArr3 = [...this.state.rollcallArr3, data.toJSON()];
      this.setState({ rollcallArr3 })
    });
    this.setState({ currentSubscription3: subscription3 })
    // firebase.firestore().collectionGroup('Progress')
    //       .where('eventDate', '==', weekDateRange2[3])
    //       .where('classroomId', '==', classObj._id)
    //       .onSnapshot(snap => {
    //         // console.log(snap)
    //         const data = funcAggDate(snap);
    //         this.setState({ rollcallArr3: data });
    //       }, err => {
    //         console.log(`Encountered error: ${err}`);
    //       });
    arrCalendar = calendar.filter(item => item.eventDate === weekDateRange2[4] && item.status === "3")
    const unsub4 = !arrCalendar.length && query4.find().then(doc => {
      const data = funcAggDate(doc)
      this.setState({ rollcallArr4: data })
    })
    if (currentSubscription4) {
      // console.log('currentSubscription.unsubscribe')
      await currentSubscription4.unsubscribe();
      this.setState({ currentSubscription4: null })
    }
    let subscription4 = await query4.subscribe().catch(error => console.error(error));
    subscription4.on('create', (data) => {
      const rollcallArr4 = [...this.state.rollcallArr4, data.toJSON()];
      this.setState({ rollcallArr4 })
    });
    subscription4.on('update', (data) => {
      const rollcallArr4 = [...this.state.rollcallArr4, data.toJSON()];
      this.setState({ rollcallArr4 })
    });
    subscription4.on('delete', (data) => {
      const rollcallArr4 = [...this.state.rollcallArr4, data.toJSON()];
      this.setState({ rollcallArr4 })
    });
    this.setState({ currentSubscription4: subscription4 })
    // firebase.firestore().collectionGroup('Progress')
    //       .where('eventDate', '==', weekDateRange2[4])
    //       .where('classroomId', '==', classObj._id)
    //       .onSnapshot(snap => {
    //         // console.log(snap)
    //         const data = funcAggDate(snap);
    //         this.setState({ rollcallArr4: data });
    //       }, err => {
    //         console.log(`Encountered error: ${err}`);
    //       });
    // this.setState({ unsubArr: [unsub0, unsub1, unsub2, unsub3, unsub4] });

  }

  handleDailyRollcall = (props = this.props) => {
    // console.log('handleDailyRollcall')
    const { firebase, /*students,*/ calendar, classId } = props;
    const { unsubArr } = this.state;
    // this.setState({ calendarHolidayArr });

    if (/*students.length &&*/ classId) {
      const { year, week } = this.state
      const weekStartDate = getDateOfISOWeek(year, week, 0);
      const weekDateRange2 = getWeekdayRangeArr(weekStartDate);
    }
  }

  // setPrevYearWeek = () => {
  //   const { yearWeek, minWeek } = this.state;
  //   const { prevYear, prevWeek, prevYearWeek } = getPrevYearWeek(yearWeek, minWeek);
  //   this.setState({ yearWeek: prevYearWeek, year: prevYear, week: prevWeek, loading: 1 }, () => this.handleStudentList());
  // };

  // setNextYearWeek = () => {
  //   const { yearWeek, maxWeek } = this.state;
  //   const { nextYear, nextWeek, nextYearWeek } = getNextYearWeek(yearWeek, maxWeek);
  //   this.setState({ yearWeek: nextYearWeek, year: nextYear, week: nextWeek, loading: 1 }, () => this.handleStudentList());
  // };


  render() {
    const { optSet, classroom, semesterYear, semester, classId } = this.props;
    const { classArr, classObj, weekday, period, selPeriodArr, courseName, eventDate, calendarHolidayArr,
      rollcallArr, loadingClassStu, attendStatusArr, seatOrderArr, classroomId, yearWeek, minWeek, maxWeek,
      year, week, rollcallArr0, rollcallArr1, rollcallArr2, rollcallArr3, rollcallArr4 } = this.state;

    const weekStartDate = getDateOfISOWeek(year, week, 0);
    // // construct date range  from monday to friday of specific week
    const weekDateRange = getWeekdayRangeArrDate(weekStartDate);
    const weekDateRange2 = getWeekdayRangeArr(weekStartDate);
    return (
      <div style={{ padding: '0 2% 2% 2%' }} ref={el => (this.componentRef = el)} className="">
        <Header as='h2' floated='left'>進度連絡</Header>
        <div style={{ float: 'right' }}>
          {/* <ReactToPrint
            trigger={(e) => <Button
              content='印刷'
              // label='&nbsp;'
              color='orange' icon='print'
              size='small'
              floated='right'
              className='no-print'
            />}
            content={() => this.componentRef}
            pageStyle={"@page { size: auto;  margin:5mm; }*{font-family: 'Noto Serif JP', serif;}"}
          /> */}
          {/* <ExportTableToExcelButton floated='right' size='small' /> */}
          {/* <Input labelPosition='left' className='no-print' >
            <Label color={'blue'}> <Icon name='building' />クラス</Label>
            <Select style={{
              minWidth: '300px',
              borderTopLeftRadius: '0',
              borderBottomLeftRadius: '0'
            }} compact options={selClassArr} onChange={this.handleChange} value={classId} name='classId' />
          </Input>&nbsp;&nbsp;&nbsp; */}
          <SelectWeek
            yearWeek={yearWeek}
            // minWeek={minWeek}
            // maxWeek={maxWeek}
            handleWeekChange={this.handleWeekChange}
          />
        </div>
        {/* <br /> */}
        <div style={{ clear: 'both' }}></div>
        <ClassProgression
          {...this.props}
          // action={'0'}
          classObj={classObj}
          year={year} week={week}
          rollcallArr={rollcallArr}
          weekDateRange={weekDateRange}
          weekDateRange2={weekDateRange2}
          period={period}
          rollcallArr0={rollcallArr0}
          rollcallArr1={rollcallArr1}
          rollcallArr2={rollcallArr2}
          rollcallArr3={rollcallArr3}
          rollcallArr4={rollcallArr4}
        />
      </div>)
  }
}

export default ClassProgressionPage