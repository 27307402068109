
/**
 * Auth Service
 */
// import axios from 'axios';
// import { API_URL } from '../config';
import Parse, { loginUser } from 'widget/parse';

const AuthService = {
  login: (email, password, companyObj) => {
    // return axios.post(API_URL + '/auth', { email: email, password: password });
    return loginUser(email, password, companyObj);
  },
  register: (username, password, email) => {
    // return axios.post(API_URL + '/register', { data });
    // return signupUser(username, password, email);
    return Parse.signupUser(username, password, username);
  },
  getProfile: () => {
    // console.log(this.authHeader())
    // return axios.get(API_URL + '/profile', { headers: this.authHeader() });
    const token = localStorage.getItem('token');
    return Parse.getProfile(token);

  },
  logout: function () {
    Parse.logoutUser();
    localStorage.removeItem('token');
  },
  getToken: function () {
    return localStorage.getItem('token');
  },
  saveToken: function (token) {
    localStorage.setItem('token', token);
  },
  authHeader: function () {
    return { Authorization: this.getToken() }
  }
}

export default AuthService
