import React from "react";
// react plugin used to create DropdownMenu for selecting items
// import Select from "react-select";
import { withCookies } from 'react-cookie';
import intl from 'react-intl-universal';
import { Container, Image, Segment, Grid, List, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

// reactstrap components
// import { Button, FormGroup, Container, Row, Col } from "reactstrap";

// core components

function SectionFooterAreas6(props = {}) {
  const { form, companyObj, cookies, history, imagePreviewUrl } = props;
  // console.log(companyObj)
  const footerMain = {
    background: '#fff',
    color: '#555',
    borderTop: '5px solid rgba(0, 0, 0, 0.2)',
    padding: 0,
    margin: 0
  }
  const flexRow = {
    display: 'flex',
    alignItems: 'center',
  }
  const flexCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
  const _margin = {
    margin: 0,
    fontSize: '14px',
    fontFamily: 'cursive'
    // borderBottom: '1px dotted #444'
  }
  const flexCol = {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'cursive',
    fontSize: '20px'
  }
  const listHeader = {
    fontWeight: 'bolder',
    fontFamily: 'cursive',
    fontSize: '20px'
  }
  const listDes = {
    fontWeight: 500,
    fontSize: '20px',
    fontFamily: 'cursive'
  }
  const bgPadding = {
    padding: '55px 0px 20px 0px'
  }
  const paddingReset = {
    padding: 0
  }
  const footerLink = {
    margin: '3px',
    fontSize: '14px',
    color: '#333'
  }
  const footerDiv = {
    margin: '0 3px',
    color: '#333'
  }
  const footerCopyright = {
    padding: '15px 0 10px',
    background: '#DDD'
  }
  const copyrightText = {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 400,
    marginTop: 0
  }
  // console.log(history)
  // console.log(history.location)

  const dbLang = cookies.get('dbLang') || 'name';
  const path = history.location.pathname
  const { img1File, text2 } = form
  const picUrl = (img1File && img1File.url) || imagePreviewUrl || '/hp1/24.jpg';
  return (<>
    <div style={{
      height: '400px', width: '100%', backgroundSize: 'cover', backgroundImage: "url(" +
        picUrl +
        ")", backgroundPosition: 'center center', backgroundRepeat: 'no-repeat'
    }}>
      <Image style={{ width: '420px', left: '60%', top: '70%', opacity: 0.7 }} src={(companyObj.img1File && companyObj.img1File.url) || ''} />
    </div>
    <Segment style={footerMain} className='no-print'>
      <Container>
        <Grid className='footerArea6' columns={3} style={bgPadding}>
          <Grid.Row style={{ ...paddingReset, display: 'flex' }}>
            <div style={{ ...flexCol, marginLeft: '5%' }}>
              <List style={flexCol}>
                <List.Header style={listHeader}>
                  {/* {intl.get('Address:')}  〒460-0007<br /> */}
                  住所  〒460-0007<br />
                </List.Header>
              </List>
            </div>
            <div style={{ ...flexCol, marginRight: '6%' }}>
              <List.Description style={listDes}>
                <br />
                {/* {intl.get('愛知県名古屋市中区新栄1－30－3')} */}
                愛知県名古屋市中区新栄1－30－3
                <br />
                {/* {intl.get('愛知県名古屋市中区新栄1－30－29')} */}
                愛知県名古屋市中区新栄1－30－29
                {/* {companyObj[`addr${dbLang}`] || ''}{intl.get('愛知県名古屋市中区新栄1－30－29')} */}

              </List.Description>
            </div>
            <div style={flexCol}>
              {/* {intl.get('お問い合わせ：')} */}
              お問い合わせ：
            </div>
            <div style={{ ...flexCol, marginBottom: '3%' }}>
              <List>
                <br />
                <List.Item style={flexRow}>
                  <List.Header as='h6' style={_margin}>TEL :&nbsp;</List.Header>
                  <List.Description style={listDes} as='p'>(052) 262-3366</List.Description>
                </List.Item>
                <List.Item style={flexRow}>
                  <List.Header as='h6' style={_margin}>Fax : &nbsp;</List.Header>
                  <List.Description style={listDes} as='p'>(052) 262-3369</List.Description>
                </List.Item>
                <List.Item style={flexRow}>
                  <List.Header as='h6' style={_margin}>Email : &nbsp;</List.Header>
                  <List.Description style={listDes} as='p'>School＠aiaso.gr.jp</List.Description>
                </List.Item>
                <List.Item style={flexRow}>
                  <List.Header as='h6' style={_margin}>QQ : &nbsp;</List.Header>
                  <List.Description style={listDes} as='p'>572014702</List.Description>
                </List.Item>
                <List.Item style={flexRow}>
                  <List.Header as='h6' style={_margin}>Skype :&nbsp; </List.Header>
                  <List.Description style={listDes} as='p'>aiasoso</List.Description>
                </List.Item>
                <List.Item style={flexRow}>
                  <List.Header as='h6' style={_margin}>Wechat/LINE :&nbsp; </List.Header>
                  <List.Description style={listDes} as='p'>aiajapanese</List.Description>
                </List.Item>
              </List>
            </div>
            {/* <List.Item style={flexRow}>
              <List.Header as='h6' style={_margin}>Wechat : &nbsp;</List.Header>
              <List.Description as='p'>aiajapanese</List.Description>
            </List.Item>
            <List.Item style={flexRow}>
              <List.Header as='h6' style={_margin}>Line :&nbsp; </List.Header>
              <List.Description as='p'>aiajapanese</List.Description>
            </List.Item> */}
          </Grid.Row>
          <Grid.Row>
            <div style={{ fontFamily: 'cursive', color: '#cfb09c', fontSize: '1.2rem', paddingLeft: '31%' }}>
              {/* {intl.get('受付時間：')} 9:00  -  17:30 ({intl.get('休日：')} 星期六、星期日、法定假日) */}
                   受付時間： 9:00  -  17:30 (休日： 土曜日、日曜日、法定休日)
          </div>
          </Grid.Row>
        </Grid>
      </Container>
      <div style={footerCopyright}>
        <List link style={flexCenter}>
          <List.Item as={Link} style={footerLink} to={'.'}>{intl.get('Home')}</List.Item>
          <List.Item style={footerDiv}> | </List.Item>
          {path === '/' ?
            <List.Item as={Link} style={footerLink} to={'./page/sitemap'}>サイトマップ</List.Item>
            // <List.Item as={Link} style={footerLink} to={'./page/sitemap'}>{intl.get('サイトマップ')}</List.Item>
            : <List.Item as={Link} style={footerLink} to={'./sitemap'}>サイトマップ</List.Item>}
          {/* : <List.Item as={Link} style={footerLink} to={'./sitemap'}>{intl.get('サイトマップ')}</List.Item>} */}
          <List.Item style={footerDiv}>|</List.Item>
          {path === '/' ?
            <List.Item as={Link} style={footerLink} to={'./page/personalPolicy'}>個人情報保護に関する方針</List.Item>
            // <List.Item as={Link} style={footerLink} to={'./page/personalPolicy'}>{intl.get('個人情報保護に関する方針')}</List.Item>
            : <List.Item as={Link} style={footerLink} to={'./personalPolicy'}>個人情報保護に関する方針</List.Item>}
          {/* : <List.Item as={Link} style={footerLink} to={'./personalPolicy'}>{intl.get('個人情報保護に関する方針')}</List.Item>} */}
          <List.Item style={footerDiv}>|</List.Item>
          {path === '/' ?
            <List.Item as={Link} style={footerLink} to={'./page/privacyPolicy'}>プライバシーポリシー</List.Item>
            // <List.Item as={Link} style={footerLink} to={'./page/privacyPolicy'}>{intl.get('プライバシーポリシー')}</List.Item>
            : <List.Item as={Link} style={footerLink} to={'./privacyPolicy'}>プライバシーポリシー</List.Item>}
          {/* : <List.Item as={Link} style={footerLink} to={'./privacyPolicy'}>{intl.get('プライバシーポリシー')}</List.Item>} */}
        </List>
        <Header as='p' style={copyrightText}>
          Copyright® {companyObj.en} All Rights Reserved.
      </Header>
      </div>
    </Segment>
  </>);
}

// export default SectionFooterAreas;
export default withCookies(SectionFooterAreas6);
