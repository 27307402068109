import React, { Component } from 'react'
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";

import EmployeeTab1 from "./EmployeeTab1";
import EmployeeTab2 from "./EmployeeTab2";
import EmployeeTab3 from "./EmployeeTab3";
import EmployeeTab4 from "./EmployeeTab4";
// import EmployeeTab5 from "./EmployeeTab5";

class Employee1 extends Component {
  state = {
    // activeItem: 'bio',
    // dataArr: [],
    loading: true,
    tabId: "p2",
  }

  componentDidMount = async () => {
    this.handleParams()
  }

  componentWillReceiveProps(nextProps) {
    this.handleParams(nextProps);
  }

  handleParams = async (props = this.props) => {
    const { match, category, companyObj } = props;
    const { t1 } = match.params;
    if (typeof t1 !== 'undefined'
      //  && t1 === category
    ) {
      this.setState({ tabId: t1, ordering1Id: '', data2: [], loading: true }
        // , () => this.handleFetch(props)
      );
    }
  }

  render() {
    const { history, employeeId, loading, employeeObj } = this.props;
    const { tabId } = this.state
    return (<div className="content">
      <h4>{employeeObj.jobNum || ''} {employeeObj.name || employeeObj.username}</h4>
      <div className="nav-tabs-navigation">
        <div className="nav-tabs-wrapper">
          <Nav id="tabs" role="tablist" tabs>
            <NavItem>
              <NavLink
                aria-expanded={
                  tabId === "p2"
                }
                data-toggle="tab"
                href="#"
                role="tab"
                className={
                  tabId === "p2"
                    ? "active"
                    : ""
                }
                onClick={(e) => {
                  e.preventDefault()
                  // this.setState({ tabId: "p2" })
                  history.push(`/admin/Employee/${employeeId}/p2`)
                }
                }
              >
                業績一覽
                          </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-expanded={tabId === "p1"}
                data-toggle="tab"
                href="#"
                role="tab"
                className={
                  tabId === "p1"
                    ? "active"
                    : ""
                }
                onClick={(e) => {
                  e.preventDefault()
                  // this.setState({ tabId: "p1" })
                  history.push(`/admin/Employee/${employeeId}/p1`)
                }
                }
              >
                基本資料
                          </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-expanded={
                  tabId === "p3"
                }
                data-toggle="tab"
                href="#"
                role="tab"
                className={
                  tabId === "p3"
                    ? "active"
                    : ""
                }
                onClick={(e) => {
                  e.preventDefault()
                  // this.setState({ tabId: "p2" })
                  history.push(`/admin/Employee/${employeeId}/p3`)
                }
                }
              >
                保證金
                          </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-expanded={
                  tabId === "p4"
                }
                data-toggle="tab"
                href="#"
                role="tab"
                className={
                  tabId === "p4"
                    ? "active"
                    : ""
                }
                onClick={(e) => {
                  e.preventDefault()
                  history.push(`/admin/Employee/${employeeId}/p4`)
                }
                }
              >
                薪資條
                          </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                aria-expanded={
                  tabId === "p4"
                }
                data-toggle="tab"
                href="#"
                role="tab"
                className={
                  tabId === "p4"
                    ? "active"
                    : ""
                }
                onClick={(e) => {
                  e.preventDefault()
                  // this.setState({ tabId: "p3" })
                  history.push(`/admin/Employee/${employeeId}/p4`)
                }
                }
              >
                停車
                          </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink
                aria-expanded={
                  tabId === "p5"
                }
                data-toggle="tab"
                href="#"
                role="tab"
                className={
                  tabId === "p5"
                    ? "active"
                    : ""
                }
                onClick={(e) => {
                  e.preventDefault()
                  // this.setState({ tabId: "p3" })
                  history.push(`/admin/Employee/${employeeId}/p5`)
                }
                }
              >
                出勤 事假扣款
                          </NavLink>
            </NavItem> */}
            {/* <NavItem>
                <NavLink
                  aria-expanded={
                    tabId === "p4"
                  }
                  data-toggle="tab"
                  href="#"
                  role="tab"
                  className={
                    tabId === "p4"
                      ? "active"
                      : ""
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    // this.setState({ tabId: "p4" })
                    history.push(`/admin/Employee/${employeeId}/p4`)
                  }
                  }
                >
                  欠款記錄
                          </NavLink>
              </NavItem> */}
          </Nav>
        </div>
      </div>
      <TabContent
        className="text-center"
        id="my-tab-content"
        activeTab={tabId}
      >
        <TabPane tabId="p1" role="tabpanel">
          <EmployeeTab1 {...this.props} />
        </TabPane>
        <TabPane tabId="p2" role="tabpanel">
          <EmployeeTab2 {...this.props} employeeObj={employeeObj} />
        </TabPane>
        <TabPane tabId="p3" role="tabpanel">
          <EmployeeTab3 {...this.props} employeeObj={employeeObj} />
        </TabPane>
        <TabPane tabId="p4" role="tabpanel">
          <EmployeeTab4 {...this.props} />
        </TabPane>
        {/* <TabPane tabId="p5" role="tabpanel">
          <EmployeeTab5 {...this.props} />
        </TabPane> */}
      </TabContent>
      <br />
    </div>);
  }
}

export default Employee1;