import React, { Component } from 'react'
import { Button, Form, Checkbox, Table } from 'semantic-ui-react';
// import MaterialTable from 'material-table'
// import Icon from '@material-ui/core/Icon'
import { Modal, ModalHeader, ModalBody, ModalFooter, Table as Table2 } from 'reactstrap';
import { IconButton } from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';

export default class ModalNamelist extends Component {
  state = {
    open: false,
    suirChecked: false,
    checkedValue: [],
    radioValue: (this.props.people1 && this.props.people1.find(item => item.mainPerson === '-1')
      && this.props.people1.find(item => item.mainPerson === '-1').objectId) || ''
  }

  open = () => this.setState({ open: true })

  close = () => this.setState({ open: false })

  toggle = () => {
    const { people1 } = this.props
    const { suirChecked } = this.state;
    // console.log(suirChecked);

    let checkedValue = [];

    if (!suirChecked) {
      people1.forEach(({ objectId }) => { checkedValue.push(objectId); });
    } else {
      checkedValue = [];
    }
    this.setState({ checkedValue, suirChecked: !suirChecked });
  }

  handleClick = (e, item) => {
    // console.log('Click', e.target.checked, item)

    const { checkedValue } = this.state;
    const { value, checked } = item;

    const indexof = checkedValue.indexOf(value);
    if (checkedValue.indexOf(value) === -1) {
      checkedValue.push(value);
    } else {
      checkedValue.splice(indexof, 1)
    }
    this.setState({ checkedValue });
  }

  handleRadioChange = (e, { value }) => this.setState({ radioValue: value })

  handleSubmitCard = () => {
    const { people1, rowData, onRowDataChange } = this.props;
    const { radioValue, checkedValue } = this.state;

    const nameArr = []
    let addr = '';

    const main = people1.find(item => item.objectId === radioValue);
    if (main) {
      nameArr.push(main.name);
      addr = main.addr;
    }

    const arr = checkedValue.filter(item => item !== radioValue);
    arr.forEach(item => {
      const obj = people1.find(item2 => item2.objectId === item);
      if (obj) {
        nameArr.push(obj.name);
      }
    })

    onRowDataChange({ ...rowData, livename: nameArr.join(','), addr/*, people1: people1Arr*/ });
    this.close();
  }

  handleSubmit1 = () => {
    const { people1, rowData, onRowDataChange } = this.props;
    const { radioValue, checkedValue } = this.state;

    const nameArr = []
    let addr = '';

    const main = people1.find(item => item.objectId === radioValue);
    if (main) {
      nameArr.push(main.name);
      addr = main.addr;
    }

    const arr = checkedValue.filter(item => item !== radioValue);
    arr.forEach(item => {
      const obj = people1.find(item2 => item2.objectId === item);
      if (obj) {
        nameArr.push(obj.name);
      }
    })

    onRowDataChange({ ...rowData, livename: nameArr.join(','), addr/*, people1: people1Arr*/ });
    this.close();
  }

  handleSubmit2 = () => {
    const { rowData, handleMultiAdd } = this.props
    const { checkedValue } = this.state;

    handleMultiAdd(rowData, checkedValue);
    this.close();
  }

  handleClickPass = (objectId, name, addr = '', _objectId) => {
    const { rowData, onRowDataChange, people0 } = this.props;
    // let people0Arr = []
    // if (_objectId) {
    // const main = people0.find(item => item.objectId === _objectId);
    // people0Arr.push(main)
    // }

    if (addr) {
      onRowDataChange({ ...rowData, ['passname' + objectId]: name, addr/*, people0: people0Arr*/ });
    } else {
      onRowDataChange({ ...rowData, ['passname' + objectId]: name/*, people0: people0Arr*/ });
    }

    this.close();
  }

  render() {
    const { people1, people0, isLive, cardslot = '0' } = this.props
    const { open, suirChecked, checkedValue, radioValue } = this.state

    // console.log(rowData)
    // console.log(people)
    return (<>
      <IconButton onClick={this.open}><Menu fontSize='small' /></IconButton>
      <Modal
        isOpen={open}
        //  onOpen={this.open}
        //  onClose={this.close}
        size={isLive === '0' ? '' : 'lg'}
        // isOpen={this.state.modalDemo}
        toggle={this.close}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggle}>
            <span aria-hidden="true">×</span>
          </button>
          代入{isLive === '1' ? "陽上" : "超渡"}資料
        </ModalHeader>
        <ModalBody>
          {/* 主 {radioValue} 副 {checkedValue.join(', ')} */}
          {isLive === '0' ? <Table striped size='small'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>名稱</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>地址</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {people0.map(({ objectId, name, addr, passYear, passMonth, passDay }) => (
                <Table.Row key={'p0' + objectId}>
                  <Table.Cell><Button icon='tag' size='tiny' onClick={() => this.handleClickPass("", name, addr, objectId)} /> {name}</Table.Cell>
                  <Table.Cell>{passYear} {passYear && '年'} {passMonth} {passMonth && '月'} {passDay} {passDay && '日'}</Table.Cell>
                  <Table.Cell>{addr}</Table.Cell>
                </Table.Row>
              ))}
              {cardslot === '1' ? <>
                <Table.Row>
                  <Table.Cell><Button icon='tag' size='tiny' onClick={() => this.handleClickPass("1", '氏歷代祖先')} /> 氏歷代祖先</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell><Button icon='tag' size='tiny' onClick={() => this.handleClickPass("2", '冤親債主')} /> 冤親債主</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell><Button icon='tag' size='tiny' onClick={() => this.handleClickPass("3", '地基主')} /> 地基主</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell><Button icon='tag' size='tiny' onClick={() => this.handleClickPass("4", '嬰靈')} /> 嬰靈</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row></> : null}
              {cardslot === '2' ? <>
                <Table.Row>
                  <Table.Cell><Button icon='tag' size='tiny' onClick={() => this.handleClickPass("", '氏歷代祖先')} /> 氏歷代祖先</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell><Button icon='tag' size='tiny' onClick={() => this.handleClickPass("", '冤親債主')} /> 冤親債主</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell><Button icon='tag' size='tiny' onClick={() => this.handleClickPass("", '地基主')} /> 地基主</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell><Button icon='tag' size='tiny' onClick={() => this.handleClickPass("", '嬰靈')} /> 嬰靈</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row></> : null}
            </Table.Body>
          </Table> :
            <Table striped size='small'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>
                    <Checkbox
                      label='全選'
                      onClick={this.toggle}
                    // name='radioGroup'
                    />
                    {/* <FormGroup check>
                      <Label check>
                        <Input disabled type="checkbox"
                          onClick={this.toggle}
                        />
                        <span className="form-check-sign" />
                      全選
                          </Label></FormGroup> */}
                  </Table.HeaderCell>
                  <Table.HeaderCell>姓名</Table.HeaderCell>
                  <Table.HeaderCell>生日</Table.HeaderCell>
                  <Table.HeaderCell>地址</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {people1.map(({ objectId, name, addr, bornYear, bornMonth, bornDay, mainPerson }) => (
                  <Table.Row key={'p1' + objectId}>
                    <Table.Cell>
                      <Form.Radio
                        label='主'
                        value={objectId}
                        checked={radioValue === objectId}
                        onChange={this.handleRadioChange}
                        name='radioGroup'
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        label='副'
                        value={objectId}
                        checked={checkedValue.indexOf(objectId) !== -1}
                        disabled={suirChecked}
                        onClick={this.handleClick}
                        onChange={this.handleChange}
                      />
                      {/* <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={checkedValue.indexOf(id) !== -1}
                            disabled={suirChecked}
                            onClick={this.handleClick}
                            onChange={this.handleChange}
                          />
                          <span className="form-check-sign" />
                            副
                          </Label>
                      </FormGroup> */}
                    </Table.Cell>
                    <Table.Cell>{mainPerson === -1 || mainPerson === "-1" ? '[戶長]' : null} {name}</Table.Cell>
                    <Table.Cell>{bornYear} {bornYear && '年'} {bornMonth} {bornMonth && '月'} {bornDay} {bornDay && '日'}</Table.Cell>
                    {isLive === '1' ? <Table.Cell>{addr}</Table.Cell> : null}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>}
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          {cardslot === '1' ? <Button icon='tag' primary content='代入' onClick={this.handleSubmitCard} />
            : <>{isLive === '0' ? null : <>
              <Button icon='tag' primary content='代入本筆 (．主, v 副)' onClick={this.handleSubmit1} />
              <Button icon='tags' color='green' content='新增多筆 (v 副)' disabled={!checkedValue.length} onClick={this.handleSubmit2} />
            </>}
            </>}
          <Button icon='x' content='關閉' onClick={this.close} />
        </ModalFooter>
      </Modal>
    </>)
  }
}
