import React, { Component } from 'react'
import { Container, Header, Button, Dimmer, Form, Grid, Image, Checkbox, Divider, Loader, Segment, Icon } from 'semantic-ui-react';
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from 'reactstrap';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

import intl from 'react-intl-universal';

const group = (companyObj, item, handleChange) =>
  <Col md={3} style={{ marginBottom: '10px' }}>
    <br />
    <FormGroup >
      <Label for="label">{item.label}</Label>
      <Input style={{ fontSize: '13px' }} size='mini' name={item.name} value={companyObj[item.name]} onChange={handleChange} />
    </FormGroup>
  </Col >;

const arrField = [
  { name: 'name', label: '公司名稱(中)' },
  { name: 'companyEngName', label: '公司名稱(英)' },
  { name: 'companyTitle', label: '公司抬頭' },
  { name: 'ownerName', label: '負責人' },
  { name: 'phoneNum', label: '電話' },
  { name: 'phoneNum2', label: '(撥打)電話' },
  { name: 'fax', label: '傳真' },
  { name: 'transferAccount', label: '劃撥帳號' },
  { name: 'address', label: '地址' },
  { name: 'website', label: '官方網站' },
  { name: 'email', label: '電子信箱' },
  { name: 'address', label: '地址' },
  { name: 'latitude', label: 'latitude' },
  { name: 'longitude', label: 'longitude' },
  { name: 'fbUrl', label: 'FB粉絲團' },
  { name: 'gmapUrl', label: 'Google地圖' },
  { name: 'lineUrl', label: '官方line' },
  { name: 'contactName', label: '連絡人' },
  { name: 'contactPhone', label: '連絡人手機' },
  { name: 'contactPhone2', label: '(撥打)連絡人手機' },
  { name: 'contactEmail', label: '連絡人Email' },
];

export default class Company1 extends Component {
  state = {
    pray1Opt: [],
    pray1IdDef: '',
    // : this.props.companyObj.pray1IdDef,
    yearDef: '',
    yearDef2: '',
    orderalphaDef: '',
    orderalphaDef2: '',
    ordernumDef: '',
    ordernumDef2: '',
    sectorEngText: '',
    pray2numLen: ''
  }

  componentDidMount = async () => {
    this.handleFetch(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    // const { companyObj } = this.props;
    const companyObj = await Parse.fetchDocument("company", props.companyObj.objectId)
    const { yearDef, orderalphaDef, ordernumDef, yearDef2, orderalphaDef2, ordernumDef2, sectorEngText, pray1IdDef, pray2numLen } = companyObj;
    console.log(companyObj)
    const pray1Data = await Parse.queryData('pray1', { companyId: companyObj.objectId });
    const data = []
    pray1Data.map(item => data.push({ key: item.id, text: item.value, value: item.id }))
    // console.log(data)

    this.setState({ pray1Opt: data, loading: false, yearDef, orderalphaDef, ordernumDef, yearDef2, orderalphaDef2, ordernumDef2, sectorEngText, pray1IdDef, pray2numLen })

  }

  handleChange = (event, data) => {
    const { name, value } = data;
    this.setState({ [name]: value })
  }

  handleSaveCompany = async () => {
    const { companyObj } = this.props
    const { pray1Opt, pray1IdDef } = this.state

    if (pray1IdDef !== companyObj.pray1IdDef) {
      pray1Opt.map(item => Parse.saveData('pray1', { id: item.key, now_use: '0' }))
      Parse.saveData('pray1', { id: pray1IdDef, now_use: '-1' })
    }


    const form = this.state;
    delete form.pray1Opt;
    await Parse.saveData('company', { objectId: companyObj.objectId, ...form })

    // if (yearDef !== companyObj.yearDef || orderalphaDef !== companyObj.orderalphaDef || ordernumDef !== companyObj.ordernumDef) {
    //   Parse.saveData('company', { objectId: companyObj.objectId, yearDef, orderalphaDef, ordernumDef })
    // }

    // if (yearDef2 !== companyObj.yearDef2 || orderalphaDef2 !== companyObj.orderalphaDef2 || ordernumDef2 !== companyObj.ordernumDef2) {
    //   Parse.saveData('company', { objectId: companyObj.objectId, yearDef2, orderalphaDef2, ordernumDef2 })
    // }

    window.alert('已儲存');
  }

  render() {
    const { companyObj } = this.props
    const { pray1Opt, pray1IdDef, yearDef, orderalphaDef, ordernumDef, yearDef2, orderalphaDef2, ordernumDef2, sectorEngText, pray2numLen = 3 } = this.state
    // const { pray1Opt } = ;

    // console.log(this.state)

    return (<div>
      <Grid>
        {sectorEngText === 'temple' ? <>
          <Form>
            <Row form>
              <Col md={6}>
                <br />
                <label style={{ fontSize: '15px' }}>服務設定</label>
                <br />
                <br />
                <label style={{ fontSize: '13px' }}>新建法會、點燈項目時，自動帶入之內容</label>
              </Col>
            </Row>
          </Form>
          <Col md={6}>
            <Form>
              <br />
              <Form.Group >
                <Form.Select
                  options={pray1Opt}
                  style={{ fontSize: '13px' }}
                  size='mini'
                  name='pray1IdDef'
                  value={pray1IdDef}
                  width={12}
                  label='目前法會'
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input style={{ fontSize: '13px' }} name='yearDef' size='mini' value={yearDef} width={4} label='法會年度' onChange={this.handleChange} />
                <Form.Input style={{ fontSize: '13px' }} name='orderalphaDef' size='mini' value={orderalphaDef} width={4} label='法會字母' onChange={this.handleChange} />
                <Form.Input style={{ fontSize: '13px' }} name='ordernumDef' size='mini' value={ordernumDef} width={4} label='法會單號' onChange={this.handleChange} />
                <Form.Input style={{ fontSize: '13px' }} name='pray2numLen' size='mini' value={pray2numLen} width={4} label='法會項目 編號位數' onChange={this.handleChange} />
              </Form.Group>
              <Form.Group >
                <Form.Input style={{ fontSize: '13px' }} name='yearDef2' size='mini' value={yearDef2} width={4} label='點燈年度' onChange={this.handleChange} />
                <Form.Input style={{ fontSize: '13px' }} name='orderalphaDef2' size='mini' value={orderalphaDef2} width={4} label='點燈字母' onChange={this.handleChange} />
                <Form.Input style={{ fontSize: '13px' }} name='ordernumDef2' size='mini' value={ordernumDef2} width={4} label='點燈單號' onChange={this.handleChange} />
              </Form.Group>
              <Form.Group >
                <Form.Button size='mini' color='green' onClick={this.handleSaveCompany}>{intl.get('儲存')}</Form.Button>
              </Form.Group>
            </Form>
          </Col>
        </> : null}
        {sectorEngText === 'temple' ? <Divider /> : null}
        <Form>
          <Row form>
            <Col md={6}>
              <br />
              <label style={{ fontSize: '15px' }}>用戶資料</label>
              <br />
              <br />
              <label style={{ fontSize: '13px' }}>您的電子郵件地址是您在系統上的身份，並用於登錄。</label>
            </Col>
            {arrField.map(item => group(companyObj, item, this.handleChange))}
            <Col md={12}>
              <br />
              <Button size='mini' color='green' onClick={this.handleSaveCompany}>{intl.get('儲存')}</Button>
            </Col>
          </Row>
        </Form>
        {/* <Divider />
          <Grid.Row>
            <Grid.Column width={8}>
              <br />
              <label style={{ fontSize: '15px' }}>用戶資料</label>
              <br />
              <br />
              <label style={{ fontSize: '13px' }}>您的電子郵件地址是您在小麥上的身份，並用於登錄。</label>
            </Grid.Column>
            <Grid.Column width={8}>
              <Form>
                <br />
                <Form.Group >
                  <Form.Input style={{ fontSize: '13px' }} size='mini' value={email} disabled width={12} label='電子信箱' />
                </Form.Group>
                <Form.Group >
                  <Form.Input style={{ fontSize: '13px' }} size='mini' value={name} width={12} label='名稱' />
                </Form.Group>
                <Form.Group >
                  <Form.Button size='mini' color='green' disabled={true}>儲存</Form.Button>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row> */}

        {/* <Divider /> */}
      </Grid>
      <br />
    </div>)
  }
}
