import React, { Component } from 'react';
import { Tab, ListGroup, Container, Form, Grid } from 'semantic-ui-react';

import List1Employee from './List1Employee';
// import List2Client from './List2Client';
// import List3Client from './List3Client';
// import List4Employee from './List4Employee';
// import List2Teacher from './teacherList/List2Teacher';
// import List3Teacher from './teacherList/List3Teacher';
// import DocumentTeacher from './teacherList/DocumentTeacher';
// import List5Employee from './List5Employee';

const size = 'small';
const TabRender = ({ component, ...rest }) => <Tab.Pane><Form size={size}>{React.createElement(component, { ...rest })}</Form></Tab.Pane>;
const menuTitle = (str1, str2 = '', icon = 'tty', LEN = 4) =>
  <>
    {/* <div><Icon name={icon} /></div> */}

    {/* <div style={{ marginBottom: '8px' }}>{str1}</div> */}
    {str1}
    {str2 ? <>{str2.substr(0, LEN)}</> : null}
    {str2.length >= LEN ? <><br />{str2.substr(LEN, LEN)}</> : null}
    {str2.length >= LEN * 2 ? <><br />{str2.substr(LEN * 2, LEN)}</> : null}
    {str2.length >= LEN * 3 ? <><br />{str2.substr(LEN * 3, LEN)}</> : null}
    {str2.length >= LEN * 4 ? <><br />{str2.substr(LEN * 4, LEN)}</> : null}
  </>;

export default class EmployeeViewTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: props.match.params.activeIndex || 0,
    };
  }
  handleTabChange = (e, { activeIndex }) => {
    const { match, doc, teachers } = this.props
    // const { list = "1" } = match.params;
    // console.log(clientsObj);
    // console.log(clientsObj._id);
    this.props.history.push(`/EmployeePage/${doc._id}/${activeIndex}`);
    this.setState({ activeIndex })
  }

  panes = [
    { key: 'List1Employee', menuItem: { key: 'List1Employee', content: menuTitle('', '基本資料') }, render: () => <TabRender {...this.props} component={List1Employee} /> },
    // { menuItem: { content: menuTitle('', '投資內容') }, render: () => <TabRender {...this.props} component={List2Client} /> },
    // { menuItem: { content: menuTitle('', '投資總覽') }, render: () => <TabRender {...this.props} component={List2Client} /> },
    // { menuItem: { content: menuTitle('', '繳費情況') }, render: () => <TabRender {...this.props} component={List2Client} /> },
    // { menuItem: { content: menuTitle('', '客服跟進事項') }, render: () => <TabRender {...this.props} component={List3Client} /> },
    // { key: 'List4Employee', menuItem: { key: 'List4Employee', content: menuTitle('', '附加檔案') }, render: () => <TabRender {...this.props} component={List4Employee} /> },
    // { menuItem: { content: menuTitle('6-2-②', '時間割配当') }, render: () => <TabRender {...this.props} component={List2Teacher} /> },
    // { menuItem: { content: menuTitle('6-2-③', 'フィル') }, render: () => <TabRender {...this.props} component={DocumentTeacher} /> },

    // { menuItem: 'マスター情報', render: () => <Tab.Pane><List1Teacher {...this.props} /></Tab.Pane> },
    // { menuItem: '時間割配当', render: () => <Tab.Pane><List2Teacher {...this.props} /></Tab.Pane> },
    // { menuItem: 'フィル', render: () => <Tab.Pane><List3Teacher {...this.props} /></Tab.Pane> },

    // { key: 'List5Employee', menuItem: { key: 'List5Employee', content: menuTitle('', '權限設定') }, render: () => <TabRender {...this.props} component={List5Employee} /> },
  ]

  render() {
    const { isEdit } = this.props;
    const { activeIndex } = this.state;
    return (
      // <Form>
      <Tab
        fluid="true"
        activeIndex={activeIndex} onTabChange={this.handleTabChange}
        menu={{ pointing: true }}
        panes={this.panes} />
      // </Form>
    );
  }
}