
import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

const columns = () => ([
  { title: 'NO', field: 'no', editable: false },
  { title: '捐款編號', field: 'donateNo' },
  { title: '姓名', field: 'name' },
  { title: '電話', field: 'phonenum' },
  { title: 'E-mail', field: 'email' },
  { title: '發布IP', field: 'ip' },
  { title: '金額', field: 'amount' },
  { title: '狀態', field: 'state' },
  { title: '接收時間', field: 'receive_time' },
  { title: '觀看內容', field: 'watchcontent' },
]);

class DonaterList extends Component {
  state = {
    newData: {},
    oldData: {},
    data1: [],
    loading: true,
  }

  async componentDidMount() {
    const data1 = await Parse.queryData('donatorlist');
    data1.map((item, index) => item.no = index + 1);
    this.setState({ data1, loading: false });
  }

  handleNo = () => {
    const { data1 } = this.state;
    data1.map((item, index) => item.no = index + 1);
    this.setState({ data1 });
  }

  handleAdd = async () => {
    const { newData } = this.state;
    Parse.saveData('donatorlist', newData);
    this.handleNo();
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    Parse.saveData('donatorlist', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    Parse.deleteData('donatorlist', oldData);
    this.setState({ oldData: {} })
    this.handleNo();
  }

  handleData = (rowData, selId) => {
    this.setState({ companyObj: rowData, selId });
  }


  render() {
    const { data1, loading } = this.state;

    return (
      <>
        <div className="content">
          <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns()} options={{
              addRowPosition: "first",
              pageSize: 5,
              search: true,
            }}
            data={data1}
            // actions={[
            //   {
            //     icon: 'view_headline',
            //     tooltip: '查看',
            //     onClick: (event, rowData) => this.handleData(rowData)
            //   }
            // ]}
            onRowClick={(event, rowData) => this.handleData(rowData)}
            title={'捐款護持列表'}
            editable={{
              onRowAdd: newData =>
                new Promise(async (resolve, reject) => {
                  const data = [...data1];
                  const db = Parse.Object.extend("donatorlist");
                  const newDoc = new db();
                  const doc = await newDoc.save();
                  newData.objectId = doc.id;
                  newData.id = doc.id;
                  newData._id = doc.id;

                  data.push(newData);
                  this.setState({ data1: data, newData }, () => resolve());
                }).then(() => this.handleAdd()),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const data = [...data1];
                  const index = data.indexOf(oldData);
                  data[index] = newData;
                  this.setState({ data1: data, newData, oldData }, () => resolve());
                }).then(() => this.handleUpdate()),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  let data = [...data1];
                  const index = data.indexOf(oldData);
                  data.splice(index, 1);
                  this.setState({ data1: data, oldData }, () => resolve());
                }).then(() => this.handleDelete()),
            }}
          />
          <br />
          {/* {rowData && rowData.number ?
        <Segment>
          <Form>
            <Form.Group widths='equal'>
              <Form.Select fluid label='位置' name='sideId' value={rowData.sideId} onChange={this.handleChange} />
              <Form.Input fluid label='編號' name='number' value={rowData.number} onChange={this.handleChange} />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Input fluid label='供奉陽上人' name='livename' value={rowData.livename} onChange={this.handleChange} />
              <Form.Input fluid label='供奉亡者' name='passname' value={rowData.passname} onChange={this.handleChange} />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Input fluid abel='備註' name='ps' value={rowData.ps} onChange={this.handleChange} />
            </Form.Group>
            <Form.Button color='blue' onClick={this.handleSaveCardslot}>儲存</Form.Button>
          </Form>
        </Segment>
        : null}
      <br /> */}
          <br />
        </div>
      </>
    );
  }
}

export default DonaterList;
