import React, { Component } from 'react'
import Moment from 'moment';
import MaterialTable from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from "views/web/CustomMaterialTable";

// 1 月報表
// 結清日期	編號	借款日期	合件	姓名	主	 金額 	車號	 部份還本 	 結餘金額 	備註	身分證號碼	手機號	保人手機號
const columns2 = () => ([
  dateCol({ width: 80, title: '收款日期', field: 'paymentDate', type: 'date' }),
  { width: 80, title: '入資金', field: 'fund' },
  { width: 80, title: '退資金', field: 'refund' },
  { width: 80, title: '業績', field: 'performance' },
  { width: 80, title: '佣金', field: 'commission', editable: 'never' },
  { width: 80, title: '結餘', field: 'balance', editable: 'never' },
  { width: 80, title: '小計', field: 'total', editable: 'never' },
  { width: 80, title: '資保息', field: 'capitalGuaranteed', editable: 'never' },
]);

export default class EmployeeTab3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data2: [],
      newData: {},
      oldData: {},
    }
    this.tableRef = React.createRef();
  }

  componentDidMount = async () => {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { match, companyObj, employeeId, employeeObj } = props;
    const { t1Id, clientId } = match.params;
    // console.log(employeeObj)
    // console.log(employeeId)
    // if (!clientId) {
    //   return;
    // }
    // const companyId = companyObj.objectId;
    const data2 = await Parse.queryData('margin', { companyId: companyObj.objectId, employeeId });

    const checkNum = (value) => !isNaN(value) ? value : 0;
    const arrAccNum = (arr, fleld) => arr.reduce((a, b) => Number(a) + Number(b[fleld]), 0);

    const arr = data2.sort((a, b) => Moment(a.paymentDate.iso) - Moment(b.paymentDate.iso)).map((item, index) => ({
      ...item,
      // accReceivable: typeof item.accReceivable === 'number' ? item.accReceivable : 0,
      // balance:  index===0?checkNum(item.balance):checkNum(item.balance),
      balance: checkNum(item.balance),
    }))

    const arr2 = arr.map((item, index) => ({
      ...item,
      total: Number(item.balance) + (index === 0 ? 0 : Number(arr[index - 1].balance)),
    }))

    const arr3 = arr2.map((item, index) => ({
      ...item,
      capitalGuaranteed: Number(item.total) * Number(employeeObj.interest) * 0.01
    }))

    this.setState({ data2: arr3.sort((a, b) => Moment(b.paymentDate.iso) - Moment(a.paymentDate.iso)) });

  }
  handleAdd = async () => {
    const { employeeObj } = this.props
    const { newData, data2 } = this.state;
    const obj = {
      ...employeeObj,
      marginTotal: String(Number(data2[0].total || 0) + Number(newData.fund || 0))
    }
    console.log(obj)
    Parse.saveData('User', obj);
    Parse.saveData('margin', newData);
  }

  handleUpdate = async () => {
    const { newData } = this.state;

    Parse.saveData('margin', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    Parse.deleteData('margin', oldData);
    this.setState({ oldData: {} })
  }
  render() {
    // const { people0, people1,  pawning1Obj, familyId, companyObj, category, selectData, pray2Obj = {} } = this.props;
    const { companyObj, employeeId, employeeObj } = this.props
    const { data2 } = this.state
    // const { pray1Obj = {}, praytypeObj = {} } = selectData
    // const { objectId, ordernum = '', pray1Text = '', pray2Text = '', serial = '' } = pawning1Obj;
    // const pray1 = pray1Obj.arr || []

    // console.log(this.tableRef.current)

    // console.log(data)
    return (<>
      {/* ｍｉｃ：保證金資息計算方式 要放滿多久才可以領資息 月息1.7%是用30天去計算的嗎?<br /> */}
      <MaterialTable
        localization={localization()}
        columns={columns2()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '保證金'),
          pageSize: data2.length < 5 ? 5 : data2.length + 1,
          search: false,
          tableLayout: 'fixed',
        }}
        data={data2}
        tableRef={this.tableRef}
        title={`保證金`}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const data = [...data2];
              const db = Parse.Object.extend("margin");
              const newDoc = new db();
              const doc = await newDoc.save();

              const obj = {
                _id: doc.id,
                id: doc.id,
                objectId: doc.id,
                companyId: companyObj.objectId,
                employeeId,
                balance: String(Number(newData.fund || 0) - Number(newData.refund || 0)),
                employeeName: employeeObj.name,
                ...newData,
              };

              data.push(obj);
              this.setState({ data2: data, newData: obj }, () => resolve());
            }).then(() => this.handleAdd()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...data2];
              const index = data.indexOf(oldData);
              const obj = {
                balance: String(Number(newData.fund || 0) - Number(newData.refund || 0)),
                ...newData,
              }

              data[index] = obj;
              this.setState({ data2: data, newData: obj, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...data2];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              this.setState({ data2: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
    </>)
  }
}