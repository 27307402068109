import React, { Component } from 'react';
import { Form, Tabs, Tab, Table, Container, Button, Grid, Icon, TextArea, Confirm, Header, Divider } from 'semantic-ui-react';
import ReactToPrint from "react-to-print";
import Moment from 'moment';
import { LocaleUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import ModalPdf from 'widget/pdf/ModalPdf';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import AttendanceCalculation from '../../../widgets/AttendanceCalculation';
import { getThisWeekList, renderTextValue, getMonthWeek } from 'views/widgets/FieldsRender';
import Parse from '../../../../widget/parse'

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}
const d = new Date();
export default class Table39 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: this.props.user.profile.toJSON(),
      show: false,
      doc: props.doc,
      isEdit: props.isEdit,
      isShow: false,
      form: {},
      isDelete: false,
      relative: [],
      instruct: [],
      loading: false,
      confirm: false,
      showTab: true,
      instructObj: {},
      classroom: [],

    };
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    if (this.props.doc !== nextProps.doc) {
      this.handleFetch(nextProps);
      this.setState({ instructObj: {} })
    }
  }

  handleFetch = async (props = this.props) => {
    const { firebase, match, companyObj } = props;
    const { _id } = match.params;

    // firebase.firestore()
    //   .collection(`Students/${_id}/Special`)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ instruct: data });
    //   }, err => { console.log(`Encountered error: ${err}`); });
    const data = await Parse.queryData('Special', { companyId: companyObj.objectId, studentId: _id });
    this.setState({ instruct: data });

    // firebase.firestore()
    //   .collection(`Students/${_id}/Relative`)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ relative: data });
    //   }, err => { console.log(`Encountered error: ${err}`); });
    const data1 = await Parse.queryData('Relative', { companyId: companyObj.objectId, studentId: _id });
    this.setState({ relative: data1 });

    // firebase.firestore()
    //   .collection(`Students/${_id}/Parttime`)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ parttime: data });
    //   }, err => { console.log(`Encountered error: ${err}`); });
    const data2 = await Parse.queryData('Parttime', { companyId: companyObj.objectId, studentId: _id });
    this.setState({ parttime: data2 });

    // firebase.firestore()
    //   .collection(`Students/${_id}/Weekly`)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ weekly: data });
    //   }, err => { console.log(`Encountered error: ${err}`); });
    const data3 = await Parse.queryData('Weekly', { companyId: companyObj.objectId, studentId: _id });
    this.setState({ weekly: data3 });

    const data4 = await Parse.queryData('Classroom', { companyId: companyObj.objectId, studentId: _id });
    this.setState({ classroom: data4 });


  }

  handleSubmit = async () => {
    const { companyObj, doc, semester, semesterYear } = this.props
    const { form, instruct, weekly } = this.state
    // const db = firebase.firestore();
    const setting = this.state.form;
    setting.studentId = this.props.doc.objectId;
    setting.studentJpnName = this.props.doc.jpnName;

    this.setState({ loading: true })
    const newRows = [...instruct];
    const d = new Date(form.eventDate)
    let weeklyExpectedDay = 0;
    let weeklyExpectedHours = 0;
    let weeklyRealDay = 0;
    let weeklyRealHours = 0;
    weekly.forEach(item2 => {
      // console.log(item.data())
      // let item2 = item.data()
      if (new Date(item2.endDate) <= d) {//計算到當月
        weeklyExpectedDay += Number(item2.weeklyExpectedDay);
        weeklyExpectedHours += Number(item2.weeklyExpectedHours);
        weeklyRealDay += Number(item2.weeklyRealDay);
        weeklyRealHours += Number(item2.weeklyRealHours);
      }
    })

    let hoursRate = Number(parseFloat(weeklyRealHours / weeklyExpectedHours * 100).toFixed(1))
    let daysRate = Number(parseFloat(weeklyRealDay / weeklyExpectedDay * 100).toFixed(1))
    console.log({
      hoursRate,
      daysRate,
      weeklyExpectedDay,
      weeklyExpectedHours,
      weeklyRealDay,
      weeklyRealHours
    })

    if (!form.objectId) {
      // const docRef = db.collection(`Students/${doc.objectId}/Special`).doc();
      const db = Parse.Object.extend("Special");
      const newDoc = new db();
      const docRef = await newDoc.save();
      const obj = {
        ...form,
        yearmonth: `${form.eventDate.substring(0, 6)}`,
        hoursRate,
        daysRate,
        objectId: docRef.id,
        studentId: doc.objectId,
        studentIdKey: doc.studentId,
        studentName: doc.jpnName,
        semesterYear,
        semester,
        companyId: companyObj.objectId,
        companyName: companyObj.name,
        // createdAt: new Date(),
        classSchool: doc.classSchool
      };
      // await docRef.set(obj);
      await Parse.saveData('Special', obj)
      newRows.push(obj);
    } else {
      // await db.collection(`Students/${doc.objectId}/Special`).doc(form.objectId).update({ ...form, yearmonth: `${form.eventDate.substring(0, 7)}`, classSchool: doc.classSchool, hoursRate, daysRate });
      await Parse.saveData('Special', { ...form, yearmonth: `${form.eventDate.substring(0, 7)}`, classSchool: doc.classSchool, hoursRate, daysRate })
      let index = newRows.findIndex((item => item.objectId === form.objectId));
      newRows[index] = form;
    }
    this.setState({ instruct: newRows, loading: false, instructObj: {} });
    this.handleClose();
  }

  handleEdit = (instructObj) => this.setState({ show: true, form: { ...instructObj } });

  handleOpen = () => this.setState({ show: true, form: {} });

  handleClose = () => this.setState({ show: false, isDelete: false, });

  handleRemove = async () => {
    const { firebase, doc } = this.props
    const { form, instruct } = this.state;
    // await firebase.firestore().collection(`Students/${doc.objectId}/Special`).doc(form.objectId).delete();
    await Parse.deleteData('Special', form)
    const newRows = [...instruct];
    let index = newRows.findIndex((item => item.objectId === form.objectId));
    newRows.splice(index, 1);
    this.setState({ instruct: newRows });
    // firebase.firestore().collection(`Students/${doc._id}/Special`).doc(form._id).delete()
    // console.log(form._id);
    this.handleClose();
    this.hideConfirm();
  }

  showConfirm = (obj) => this.setState({ confirm: true, form: obj });
  hideConfirm = () => this.setState({ confirm: false, form: {} });

  handleDayChange = (date) => {
    const eventDate = Moment(new Date(date)).format('YYYY-MM-DD');
    const { doc } = this.state;

    this.setState({ form: { ...this.state.form, eventDate } });
  }

  setSettingOnChange = (setting) => {
    let fieldName = setting.target.name;
    let fleldVal = setting.target.value;

    this.setState({ form: { ...this.state.form, [fieldName]: fleldVal } })
  }

  saveInsertSetting = () => {
    const { form, doc } = this.state;
    // const method = 'student.updateSetting';

    let obj = {};

    if (doc.weeklyRepArr) {
      obj = { weeklyRepArr: [...doc.weeklyRepArr, form] }
    } else {
      obj = { weeklyRepArr: [form] }
    }

    // console.log(obj);

    this.props.setRelativeObj(obj);
    // console.log(this.state);
  }

  getStartDate = (date) => {
    const lastWeek = getThisWeekList(new Date(date));
    return lastWeek[0];
  }

  getPrintData = () => {
    const { doc } = this.props;
    const { relative, instructObj, parttime } = this.state;
    let week = getMonthWeek(new Date(instructObj.eventDate))
    let obj = { ...relative[0], ...instructObj, ...parttime[0], week }
    this.setState({ params: { ...doc, ...obj } })
    console.log(obj)
  }

  render() {
    const { doc, optSet, formSet, semesterYear, semester, calendar, classroom, isEdit, companyObj } = this.props;
    const { form, isDelete, relative, parttime, loading, instruct, showTab, instructObj, params, userData } = this.state
    // const arr2 = ['1', '2'];
    // const arr4 = ['1', '2', '3', '4']
    // const arr5 = ['1', '2', '3', '4', '5'];
    // const arr6 = ['1', '2', '3', '4', '5', '6'];
    // let startDate, lastWeek = '';

    // const availablePeriodArr = getAvailblePeriodArr(semesterYear, semester);
    // let endDate = getDate(availablePeriodArr[0], 'endDate', false);

    // if (!endDate || !availablePeriodArr.length) {
    //   return <>endDate error!</>;
    // }

    // lastWeek = getLastWeekList(endDate);
    // startDate = lastWeek[0];
    // endDate = lastWeek[6];

    const classRoomArr = classroom.filter(item => item.isEnableClass === true && item.semesterYear === semesterYear && item.semester === semester && item.classSchool === doc.classSchool) || [];
    // console.log(doc);
    // console.log(instruct)
    return (<>
      <Header as='h2' content='特別指導学生週間報告書'
        style={{ backgroundColor: 'lavender', margin: 0 }} /* subheader='調査時間：2017年4月から 2018年12月 まで'*/ />
      <br />
      <Button disabled={!isEdit} onClick={this.handleOpen} inverted={companyObj.inverted} color='blue' icon='add' content='追加' />

      {/* {!showTab ? <Button color='blue' icon='file alternate' content='詳細' onClick={() => this.setState({ showTab: true })} style={{ marginRight: 10 }} />
        : <Button onClick={() => this.setState({ showTab: false })} icon='remove' content='閉じる' inverted={companyObj.inverted} />} */}
      <Table celled>
        <Table.Header>
          <Table.Row textAlign='center'>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell style={{}}>日付</Table.HeaderCell>
            <Table.HeaderCell style={{}}>今週の報告內容</Table.HeaderCell>
            <Table.HeaderCell style={{}}>指導·行動·結果</Table.HeaderCell>
            {/* <Table.HeaderCell style={{}}>飛び級状況</Table.HeaderCell> */}
            {/* <Table.HeaderCell></Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {instruct.length ? instruct.map((item, index) => (<React.Fragment key={index}>
            <Table.Row>
              <Table.Cell style={{ padding: 5, width: '80px' }}>
                <Button primary size='small'
                  onClick={() => this.setState({ instructObj: item }, () => this.getPrintData())}
                  icon='file alternate' />
                {isEdit ? <>
                  <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} icon='edit' />
                  <Button inverted={companyObj.inverted} color='red' size='small' onClick={() => this.showConfirm(item)} icon='delete' />
                </> : null}
              </Table.Cell>
              <Table.Cell>{item.eventDate}</Table.Cell>
              <Table.Cell>{item.weeklyReportDetail && item.weeklyReportDetail.substring(0, 20)}{item.weeklyReportDetail && item.weeklyReportDetail.length > 20 ? "..." : null}</Table.Cell>
              <Table.Cell>{item.weeklyReportAction && item.weeklyReportAction.substring(0, 20)}{item.weeklyReportAction && item.weeklyReportAction.length > 20 ? "..." : null}</Table.Cell>
            </Table.Row>
          </React.Fragment>)) : <Table.Row>
              <Table.Cell colSpan='15' textAlign='center' >(資料無し)</Table.Cell>
            </Table.Row>}
        </Table.Body>
      </Table>
      <Divider />
      {instructObj && instructObj.objectId ? <div>
        {/* <ReactToPrint
          trigger={(e) => <Button
            content='印刷'
            // label='&nbsp;'
            color='orange'
            icon='print'
            size='small'
            floated='right'
            className='no-print'
          />}
          content={() => this.componentRef}
          pageStyle={"@page { size: auto;  margin: 10mm; }*{font-family: 'Noto Serif JP', serif;}"}
        /> */}
        <ModalPdf
          {...this.props}
          // ref={(ref) => { this.pdfModal = ref }}
          codekey="table39Pdf"
          params={params}
          floated='right'
        />
        <ExportTableToExcelButton floated='right' size='small' />

        <div ref={el => (this.componentRef = el)} className="" >
          <h1>特別指導学生週間報告書</h1>
          <h4>【{doc.classSchool}】クラス 担任[ {classRoomArr.length && classRoomArr[0].teacherText} {classRoomArr.length && classRoomArr[0].teacherTitleText2 ? <>({(classRoomArr.length && classRoomArr[0].teacherTitleText2) || ''})</> : ''}]</h4>
          <div>{Moment(new Date()).format('YYYY/MM/DD')} 印刷者: {userData.name}</div>
          <Grid.Row style={{ margin: '25px 0' }}>
            <Table id='excel' celled structured size="small" style={{ textAlign: 'center', tableLayout: "auto", }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='6' style={{ textAlign: 'center' }}>基  本  情  報</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{doc.studentId}</Table.Cell>
                  <Table.Cell colSpan="2">{doc.semesterYear}.{doc.semester === 'S' ? '4' : '10'}月生</Table.Cell>
                  <Table.Cell rowSpan="2" colSpan='2'>連絡先<br />【 本人 】</Table.Cell>
                  <Table.Cell >{doc.jpnAddr}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell rowSpan="2" colSpan="3">{doc.jpnName}</Table.Cell>
                  <Table.Cell >{doc.jpnTel || doc.jpnCellphone}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell rowSpan="2" colSpan="2">連絡先<br />【 推薦者/保証人 】</Table.Cell>
                  <Table.Cell>{doc.agentName}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell rowSpan="2" style={{ lineHeight: '5' }}>ビザ期限</Table.Cell>
                  <Table.Cell rowSpan="2" colSpan="2" style={{ lineHeight: '5' }}>{doc.visaExpireDate1}</Table.Cell>
                  <Table.Cell>{doc.agentTel}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell >続柄</Table.Cell>
                  {/* <Table.Cell width="7.5%">留学センタ－</Table.Cell> */}
                  <Table.Cell >{doc.agentRelationshipText}</Table.Cell>
                  <Table.Cell>{doc.agentAddr}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>出席率</Table.Cell>
                  <Table.Cell colSpan="2">
                    入学 ~ {new Date(instructObj.eventDate).getFullYear()}年{new Date(instructObj.eventDate).getMonth() + 1}月第{getMonthWeek(new Date(instructObj.eventDate))}週
                  </Table.Cell>
                  <Table.Cell rowSpan="2" colSpan="2">連絡先<br />【 親族 】</Table.Cell>
                  <Table.Cell>{relative.length ? relative[0].name : ''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell >日数計算</Table.Cell>
                  <Table.Cell colSpan="2">
                    {instructObj.daysRate}%
                    {/* <AttendanceCalculation
                    doc={doc} calendar={calendar} studentsInClass={studentsInClass} isStudent={true} option="ratio" option1="actualDays" option2="expectedDays"
                    startDate={startDate} endDate={endDate}
                  />{`%`} */}
                  </Table.Cell>
                  <Table.Cell>{relative.length ? relative[0].tel : ''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>時間計算</Table.Cell>
                  <Table.Cell colSpan="2">
                    {instructObj.hoursRate}%
                    {/* <AttendanceCalculation
                    doc={doc} calendar={calendar} studentsInClass={studentsInClass} isStudent={true} option="ratio" option1="actualHours" option2="expectedHours"
                    startDate={startDate} endDate={endDate}
                  />{`%`} */}
                  </Table.Cell>
                  <Table.Cell>続柄</Table.Cell>
                  <Table.Cell>{renderTextValue(optSet, { type: 'select', value: 'relationship' }, relative.length && relative[0].relationship)}</Table.Cell>
                  {/* <Table.Cell>{relative.length ? (relative[0].relationship === '1' ? '母' : '父') : ''}</Table.Cell> */}
                  <Table.Cell>{relative.length ? relative[0].addr : ''} </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>アルバイト</Table.Cell>
                  <Table.Cell colSpan="2">{parttime.length ? parttime[0].storeName : ''}</Table.Cell>
                  <Table.Cell>{parttime.length ? parttime[0].personincharge : ''}</Table.Cell>
                  <Table.Cell>{parttime.length ? parttime[0].storetel : ''}</Table.Cell>
                  <Table.Cell>{parttime.length ? parttime[0].storeAddress : ''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>督促書発送</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell rowSpan="2"></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>警告書発送</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              </Table.Body>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='6' style={{ textAlign: 'center' }}>週  間  報  告</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan="2" >日付</Table.Cell>
                  <Table.Cell colSpan="3" >出席日数</Table.Cell>
                  {/* <Table.Cell ></Table.Cell> */}
                  <Table.Cell colSpan="1" >出席時数</Table.Cell>
                  {isEdit ? <Table.Cell ></Table.Cell> : null}
                </Table.Row>
                {/* {doc.weeklyRepArr ? doc.weeklyRepArr.sort((a, b) => new Date(b.eventDate) - new Date(a.eventDate)).map(item => */}
                {/* {instruct && instruct.length ?  */}
                {/* {instruct.map((item, index) => (<React.Fragment key={`td${index}`}> */}
                <Table.Row>
                  {/* <Table.Cell width="10%">今週</Table.Cell> */}
                  <Table.Cell colSpan="2">{instructObj.eventDate}</Table.Cell>
                  <Table.Cell colSpan="3">{instructObj.actualDay}</Table.Cell>
                  <Table.Cell colSpan="1">{instructObj.actualHours}</Table.Cell>
                  {/* {isEdit ? <Table.Cell>
                    <Button size='small' inverted={companyObj.inverted} color='yellow' onClick={() => this.handleEdit(instructObj)} >詳細</Button>
                  </Table.Cell>
                    : null} */}
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan="6">報  告  內  容</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan="6" style={{ textAlign: 'left' }}>
                    <div style={{ maxWidth: '100%', maxHeight: '76px', overflowY: 'auto', wordBreak: 'break-all' }}>不良發生原因: {instructObj.weeklyReportDetail}</div>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan="6" style={{ textAlign: 'left' }}>
                    <div style={{ maxWidth: '100%', maxHeight: '76px', overflowY: 'auto', wordBreak: 'break-all' }}>改善要點: {instructObj.weeklyReportAction}</div>
                  </Table.Cell>
                </Table.Row>
                {/* </React.Fragment> */}
                {/* ))} */}
                {/* : <Table.Row><Table.Cell colSpan='6'>(資料無し)</Table.Cell></Table.Row>} */}
              </Table.Body>
            </Table>
          </Grid.Row>
        </div>
      </div> : null
      }
      {/* <Modal open={this.state.show} onClose={this.handleClose} size="large" > */}
      <Modal
        isOpen={this.state.show}
        toggle={this.handleClose}
        size="lg"
        className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader style={{ ...style.flexCenter }}>
          <h2 style={{ margin: 0 }}>特別指導学生報告</h2>
          <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
        </ModalHeader>
        <ModalBody>
          {/* {ClassroomObj.map((setting) => ( */}
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <label style={{ display: 'block', marginBottom: 5 }}>日付</label>
                <DayPickerInput onDayChange={this.handleDayChange} style={{ display: 'block' }}
                  localeUtils={LocaleUtils} locale="id" value={form.eventDate ? Moment(form.eventDate).format("YYYY/MM/DD") : ''}
                  inputProps={{
                    className: "form-control",
                    name: 'eventDate',
                    style: {
                      border: '1px solid rgba(34,36,38,.15)',
                      borderRadius: '.3rem',
                      minWidth: 196,
                      minHeight: 38,
                      padding: '.7rem',
                      outline: 'none'
                    }
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row key={'1'}>
              <Grid.Column>
                <label style={{ display: 'block', marginBottom: 5 }}>今週の報告內容（400字以內）</label>
                <Form.Field
                  control={TextArea} placeholder="入力してください" name='weeklyReportDetail'
                  onChange={this.setSettingOnChange}
                  value={form.weeklyReportDetail ? form.weeklyReportDetail : ''}
                  style={{ height: 200, width: '100%', borderRadius: '.3rem', outline: 'none', border: "1px solid rgba(34,36,38,.15)", padding: '1rem' }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row key={'2'}>
              <Grid.Column>
                <label style={{ display: 'block', marginBottom: 5 }}>指導·行動·結果（200字以內）</label>
                <Form.Field
                  control={TextArea} placeholder="入力してください" name='weeklyReportAction'
                  onChange={this.setSettingOnChange}
                  value={form.weeklyReportAction ? form.weeklyReportAction : ''}
                  style={{ height: 200, width: '100%', borderRadius: '.3rem', outline: 'none', border: "1px solid rgba(34,36,38,.15)", padding: '1rem' }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ModalBody>
        <ModalBody>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={this.handleSubmit} color="green" loading={loading} icon='checkmark' content={form.objectId ? '保存' : '入力'} />
            {/* <Button className={isReadOnly ? 'hidden' : ''} variant="light" onClick={() => this.cancelEditRowModal()}>キャンセル</Button> */}
            {/* <Button className={!isReadOnly ? 'hidden' : ''} variant='warning' onClick={() => this.editRowModal()}icon='edit' content='修正' /> */}
            {/* <Button className={!isDelete ? 'hidden' : ''} onClick={this.showConfirm} inverted={companyObj.inverted} color="red" icon='delete' content='削除' /> */}
            <Button onClick={this.handleClose} icon='remove' content='閉じる' basic />
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={this.state.confirm}
        toggle={this.hideConfirm}
        size="lg"
        className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader>削除</ModalHeader>
        <ModalBody>
          削除してもいいですか？
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button onClick={this.handleRemove} inverted color='red' icon='delete' content='はい' />
          <Button onClick={this.hideConfirm} content='いいえ' />
        </ModalFooter>
      </Modal>
      {/* <Confirm
        header='削除'
        content='削除してもいいですか?'
        cancelButton='いいえ'
        confirmButton='はい'
        open={this.state.confirm}
        onCancel={this.hideConfirm}
        onConfirm={this.handleRemove}
        size='mini'
        centered={false} /> */}
    </>);
  }
}

