import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Button, Label, Divider } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'

import { Row, Col } from "reactstrap";
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { mgyearOpt, monthOpt, exportPdf } from 'views/web/CustomMaterialTable';
import QRCode from 'qrcode.react'
import Moment from 'moment';


const style = {
  divSpaceBlock: {
    position: 'absolute',
    borderRadius: '5px',
    border: '1px dashed #000',
    padding: '10px',
    textAlign: 'center',
    fontSize: '30px',
  },
  divTableBlock: {
    position: 'absolute',
    borderRadius: '5px',
    border: '1px solid #000',
    padding: '8px',
    textAlign: 'center',
    height: '80px',
    cursor: 'pointer',
    width: '170px',

  }
}

const columns = (employeeObj) => ([
  // { width: 40, title: '代號', field: 'code', lookup: code },
  { width: 80, title: '客戶姓名', field: 'clientName' },
  { width: 80, title: '當物類別', field: 'pawnTypeText' },
  { width: 80, title: '(總)本金結餘', field: 'principalRest' },
  { width: 80, title: '(總)應收利息', field: 'interestPlan' },
  { width: 80, title: '(總)繳息金額', field: 'interestPaid' },
  { width: 10, title: '', field: 'aaa', },
  { width: 80, title: '計劃繳息日期', field: 'planDate', editable: 'never' },
  { width: 80, title: '合件', field: 'joint' },
  { width: 80, title: '比例', field: 'proportion', editable: 'never' },
  { width: 80, title: '業績', field: 'performance' },
  { width: 80, title: '應收利息', field: 'interestPlanSelf' },
  { width: 80, title: '實收利息', field: 'interestPaidSelf' },
  { width: 80, title: '佣金', field: 'commission' },
  { width: 180, title: '備註', field: 'ps' }
]);

const d = new Date()
class CrewTablestatus extends Component {
  state = {
    activeItem: 'bio',
    data: [],
    dataArr: [],
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
  }
  componentDidMount = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    let query = new Parse.Query('ordering1');
    // query.equalTo('isOpen', '1');
    let subscription1 = await query.subscribe();
    // let query2 = new Parse.Query('ordering2');
    // let subscription2 = await query2.subscribe();
    this.setState({
      subscription1,
      // subscription2
    });
    this.handleFetch()


    subscription1.on('create', async (object) => {
      console.log('object created', object);
      const data = await Parse.fetchDocument('ordering1', object.id)
      const dataArr = [...this.state.dataArr, data]
      this.setState({ dataArr })
    });

    subscription1.on('update', async (object) => {
      // console.log('object updated', object);
      const dataArr = await Parse.queryData('ordering1', { companyId, isOpen: '1' })
      this.setState({ dataArr })
    });
  }

  componentWillUnmount() {
    const { subscription1 } = this.state
    subscription1.unsubscribe();
  }

  handleFetch = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const table = await Parse.queryData('ordering1', { companyId, isOpen: '1' })
    this.setState({ dataArr: table })
  }

  handleTo = (e, data) => {
    const { match, history } = this.props
    history.push(`/admin/CrewService/${data.id}`)
  }

  render() {
    const { companyObj } = this.props
    const { dataArr, monthSel, mgyearSel } = this.state

    return (<div className="content" style={{ backgroundColor: '#fff' }}>
      <Header as='h2' inverted={companyObj.inverted}>開桌</Header>
      <Row>
        <Col>
          {dataArr.map(item => <Button className='tableStatusButton' color='black' basic size='massive' content={<><h2>{item.tableName}<br />{Moment(new Date(item.openTime.iso)).format('LT')}</h2><h2>{item.openChair}位</h2></>} id={item.objectId} onClick={this.handleTo} />)}
          {/* <Button color='black' basic size='massive' content={<><h2>A1 窗邊左 14:00</h2><br /><h2>7位</h2></>} /> */}
          {/* <div class="div-space-block" style={{ ...style.divSpaceBlock, height: '180px', width: '120px', left: '20px', top: '20px', paddingTop: '60px' }}> 玄關 </div> */}
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col sm='4'></Col>
        <Col sm='4'>
          <QRCode className='qrcode' value="https://tabletop.infowin.com.tw/admin/CrewTablestatus" style={{ width: '256px', height: '256px' }} />
          <br />
          <br />
          <Label as='a' href='https://tabletop.infowin.com.tw/admin/CrewTablestatus' style={{ marginLeft: '72px' }}>本頁QR Code</Label>
        </Col>
        <Col sm='4'></Col>
      </Row>
    </div >);
  }
}

export default CrewTablestatus;