import React, { Component } from 'react'
import { Container, Icon, Table, Button, Modal, Header, Form, Pagination, Dropdown, Confirm } from 'semantic-ui-react'
import _ from 'lodash'
import MyModalTable from '../../widgets/MyModalTable';
import Parse from '../../../widget/parse'

export class CourseName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      author: '',
      confirmOpen: false,
      number: 0,
    }
  }

  async componentDidMount() {
    const { firebase, companyObj } = this.props;

    // firebase.firestore().collection("Course")
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }));
    //     // console.log(data)
    //     this.setState({ course: data });
    //   });
    const data = await Parse.queryData('Course', { companyId: companyObj.objectId });
    this.setState({ course: data });

  }

  handleChange = (e) => {
    console.log(e);
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = (e) => {
    // console.log(e);
    e.preventDefault();
    // this.props.createProject(this.state);
    // this.props.history.push('/');

    const { firebase } = this.props
    const { courseName } = this.state
    firebase.firestore().collection('Course').add({
      courseName
    }).then((docRef) => {
      // this.setState({
      //   title: '',
      //   description: '',
      //   author: ''
      // });
      // this.props.history.push("/")
      console.log(docRef);
      this.handleClose();
      this.clearInput();
    }).catch((error) => {
      console.error("Error adding document: ", error);
    });
  }

  fields = [
    {
      id: 'courseName',
      title: 'コース名'
    },
  ]

  render() {
    const { firebase, optSet } = this.props;
    const { course } = this.state

    const columns = this.fields.map(item => ({ name: item.id, ...item }));

    // console.log(this.fields);
    return (
      <div>
        <MyModalTable
          pageTitleText='授業管理'
          firebase={firebase} fields={this.fields}
          collection='Course'
          {...this.props}
          columns={columns}
          rows={course}
        />
      </div >
    )
  }
}
export default CourseName