import React, { Component } from 'react';
import { Tab, ListGroup, Container, Form, Icon } from 'semantic-ui-react';
import HostPrint from '../../widgets/HostPrint';

const size = 'small';
const TabRender = ({ component, ...rest }) => <Tab.Pane><Form size={size}>{React.createElement(component, { ...rest })}</Form></Tab.Pane>;
const menuTitle = (str1, str2 = '', icon = 'tty', LEN = 4) =>
  <>
    {/* <div><Icon name={icon} /></div> */}

    {/* <div style={{ marginBottom: '8px' }}>{str1}</div> */}
    {str1}
    {str2 ? <>{str2.substr(0, LEN)}</> : null}
    {str2.length >= LEN ? <><br />{str2.substr(LEN, LEN)}</> : null}
    {str2.length >= LEN * 2 ? <><br />{str2.substr(LEN * 2, LEN)}</> : null}
    {str2.length >= LEN * 3 ? <><br />{str2.substr(LEN * 3, LEN)}</> : null}
    {str2.length >= LEN * 4 ? <><br />{str2.substr(LEN * 4, LEN)}</> : null}
  </>;
export default class ListTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: props.match.params.activeIndex || '-1',
      codeForm: {},
      statusKey: props.match.params.list,
      studentSelected: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match !== this.props.match) {
      this.setState({ statusKey: nextProps.match.params.list });
    }
  }

  handleTabChange = (e, { activeIndex, codeid }) => {
    const { match, doc, formSet } = this.props
    const { list = "1" } = match.params;
    // const codeForm = formSet.find(item => item._id === codeid);

    // this.props.history.push(`/StudentPage/${list}/${doc._id}/${activeIndex}`);
    this.setState({ activeIndex })
    this.props.studentLock(activeIndex);
  }

  render() {
    const { formSet, companyObj } = this.props;
    const { activeIndex, statusKey } = this.state;
    const key = statusKey === '1' ? 'recruitP' : 'studentP'
    const level0Obj = formSet.find(item => item.key === key) || {};
    const level1Arr = level0Obj && level0Obj._id ? formSet.filter(item => item.parentFolderId === level0Obj._id).sort((a, b) => a.orderId - b.orderId) : [];
    const studentPTeachArr = formSet.find(item => item.key === 'studentPTeach') && formSet.filter(item => item.parentFolderId === formSet.find(item => item.key === 'studentPTeach')._id).sort((a, b) => a.orderId - b.orderId)
    if (statusKey === '2') level1Arr.push(...studentPTeachArr)
    // console.log(level1Arr)
    const panes = level1Arr.map((item, index) => (
      {
        menuItem: { key: index, content: `${index + 1}.${item.label}` },
        // menuItem: { content: item.label.length > 7 ? `${index + 1}.${item.label.substr(0, 6)}${item.label.substr(6, item.label.length)}` : `${index + 1}.${item.label}` },
        render: () => <TabRender
          {...this.props}
          component={HostPrint}
          codeid={item._id}
          codekey={item.key}
          title={`${item.label}`}
          studentSelected={this.props.studentSelected} />
      }
    ))
    return (<><Tab
      activeIndex={activeIndex} onTabChange={this.handleTabChange}
      menu={{ pointing: true, inverted: companyObj.inverted }}
      panes={panes} />
      <br></br>
      {activeIndex === '-1' ? <h3>印刷する書類を選択して下さい</h3> : ''}</>
    );
  }
}