import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import Moment from 'moment';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ModalUploadFiles from './ModalUploadFiles';
import { dateCol, imgBtnCol, imgPreviewCol, exportPdf, rowReOrder } from './CustomMaterialTable';
import { RowImage } from './RowImage';


const columns = ({ isMultiLang, photos }) => ([
  { width: 60, title: '順序', field: 'order', type: 'numeric' },
  { title: '公開', field: 'isopen', lookup: { '1': '公開', '0': '隱藏' }, initialEditValue: '1' },
  { title: '首頁', field: 'dashboard', type: 'boolean', initialEditValue: false },
  { title: '跑馬燈', field: 'carousel', lookup: { '1': '是', '0': '否' }, initialEditValue: '0' },
  { width: 120, title: '封面', field: 'img1File', filtering: false, editable: 'never', render: rowData => <RowImage rowData={rowData} field='img1File2' photos={photos} /> },
  { title: '環境設施', field: 'environmentalFacilities', lookup: { '0': '顯示', '1': '不顯示' } },
  dateCol({ title: '日期', field: 'date' }),
  { title: `名稱${isMultiLang ? '(繁體中文)' : ''}`, field: 'name' },
  { title: '簡體中文', field: 'zhHant', hidden: !isMultiLang },
  { title: '日文', field: 'ja', hidden: !isMultiLang },
  { title: '英文', field: 'en', hidden: !isMultiLang },
  { title: '越南文', field: 'vi', hidden: !isMultiLang },
  { title: '備註', field: 'ps' },
]);

const columns2 = ({ isMultiLang, photos }) => ([
  { width: 60, title: '順序', field: 'order', type: 'numeric' },
  { title: '封面', field: 'iscover', type: 'boolean', initialEditValue: false },
  imgBtnCol({ field: 'img1File' }),
  imgPreviewCol({ title: '照片', field: 'img1File', photos }),
  { title: `說明${isMultiLang ? '(繁體中文)' : ''}`, field: 'name' },
  { title: '簡體中文', field: 'zhHant', hidden: !isMultiLang },
  { title: '日文', field: 'ja', hidden: !isMultiLang },
  { title: '英文', field: 'en', hidden: !isMultiLang },
  { title: '越南文', field: 'vi', hidden: !isMultiLang },
  { title: '備註', field: 'ps' },
]);

export class AlbumCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginUser: this.props.user.profile.toJSON(),
      rowData: {},
      data1: [],
      data2: [],
      loading: true,
      loading1: false,
      newData: {},
      oldData: {},
      environmentalFacilities: '0'
    };
  }

  componentDidMount = () => {
    // const { companyObj } = this.props
    // const { loginUser } = this.state
    // const companyId = companyObj.objectId
    // const authPage = window.location.pathname.split('/')[2]
    // const authUser = companyId + '_' + authPage
    // let auth = {}
    // if ((loginUser.authAdminA&&loginUser.authAdminA.indexOf(companyId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
    //   auth = {
    //     edit: true,
    //   }
    // } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
    //   auth = {
    //     edit: false,
    //   }
    // } else {
    //   auth = {
    //     edit: false,
    //   }
    // }
    let auth = {
      edit: true
    }
    this.setState({ auth }, () => this.handleFetch(this.props))
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj } = props;
    const companyId = companyObj.objectId

    const data1 = await Parse.queryData('album', { companyId }, { orderBy: 'order' });
    // console.log(data1)
    this.setState({ loading: false, data1 })
  }

  handleData = async (rowData) => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    this.setState({ loading1: true })
    const data = await Parse.queryData('webimage', { companyId, albumId: rowData.objectId });

    // console.log(data, rowData.objectId)
    this.setState({ loading1: false, rowData, data2: data });
  }

  // handleAdd = async (type) => {
  //   const { newData } = this.state;

  //   this.setState({ loading: true });
  //   if (type === '1') {
  //     await Parse.saveData('album', newData);
  //   } else {
  //     await Parse.saveData('webimage', newData);
  //   }
  //   this.handleFetch()
  // }

  handleUpdate = async (type) => {
    const { newData, data2, data1, rowData } = this.state

    this.setState({ loading: true });
    if (type === '1') {

      const obj = {
        ...newData,
        date: (newData.date && new Date(newData.date.iso || newData.date)) || new Date(),
        dateText: newData && newData.date ? Moment(newData.date).format('YYYY-MM-DD') : '',
      }
      if (newData.dashboard) {
        const promises = []
        let data = [...data1]
        const index = data.indexOf(newData)
        data[index] = obj
        data.forEach(item => promises.push(Parse.saveData('album', item)))
        await Promise.all(promises).catch(error => console.log("error", error));
      } else {
        await Parse.saveData('album', obj);
      }
      this.handleFetch()
    } else {
      if (newData.iscover === true) {
        const promises = [];
        promises.push(Parse.saveData('album', {
          ...rowData,
          img1File: newData.img1File,
          img1File2: newData.img1File2
        }));
        data2.forEach((item, i) => promises.push(Parse.saveData('webimage', data2[i])));
        await Promise.all(promises).catch(error => console.log("error", error));
        this.handleFetch()
      } else {
        await Parse.saveData('webimage', newData)
      }
    }
    this.setState({ loading: false });
    // this.handleFetch()
  }

  handleDelete = async (type) => {
    const { companyObj } = this.props
    const { oldData } = this.state
    const companyId = companyObj.objectId
    this.setState({ loading: true });

    if (type === '1') {
      await Parse.deleteData('album', oldData);
      await Parse.destroyData('webimage', { companyId, albumId: oldData.objectId });
    } else {
      await Parse.deleteData('webimage', oldData);
    }
    // this.handleFetch()
    this.setState({ oldData: {}, loading: false });
  }

  handleState = (dataArr, arrName = 'dataArr') => {
    this.setState({ [arrName]: dataArr })
  }

  editable = {
    isEditHidden: () => !this.state.auth.edit,
    isDeleteHidden: () => !this.state.auth.edit,
    onRowAdd: newData =>
      new Promise(async (resolve, reject) => {
        const db = Parse.Object.extend("album");
        const newDoc = new db();
        const docRef = await newDoc.save();
        let data = [...this.state.data1];
        let obj = {
          ...newData,
          id: docRef.id,
          _id: docRef.id,
          objectId: docRef.id,
          companyId: this.props.companyObj.objectId,
          companyName: this.props.companyObj.name,
        }
        if (obj.dashboard) {
          obj.carousel = '1'
          data = data.map(item => ({ ...item, dashboard: false }))
        }
        data.push(obj);
        this.setState({ data1: data, newData: obj }, () => resolve());
      }).then(() => this.handleUpdate('1')),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        let data = [...this.state.data1];
        const index = data.indexOf(oldData);
        if (newData.dashboard) {
          newData.carousel = '1'
          data = data.map(item => ({ ...item, dashboard: false }))
        }
        data[index] = newData;
        let obj = {
          ...newData,
        }
        this.setState({ data1: data, newData: obj, oldData }, () => resolve());
      }).then(() => this.handleUpdate('1')),
    onRowDelete: oldData =>
      new Promise((resolve, reject) => {
        let data = [...this.state.data1];
        const index = data.indexOf(oldData);
        data.splice(index, 1);
        // handleState('people0', data);
        this.setState({ data1: data, oldData }, () => resolve());
      }).then(() => this.handleDelete('1')),
  }

  render() {
    const { companyObj } = this.props
    const { loading, loading1, rowData, data1, data2, auth = {} } = this.state

    // console.log(data1, data2, rowData)

    if (auth.edit === false) {
      delete this.editable.onRowAdd
    }

    return (<>
      <div className='content'>
        <br />
        <br />
        <MaterialTable
          isLoading={loading}
          localization={localization()}
          columns={columns({ isMultiLang: companyObj.isMultiLang })}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportPdf: (columns, data) => exportPdf(columns, data, '相簿'),
            pageSize: 5,
            search: false,
          }}
          components={{
            Row: props => rowReOrder(props, 'album', 'order', data1, 'data1', this.handleState)
          }}
          tableRef={this.tableRef}
          data={data1}
          title="相簿"
          actions={[
            {
              icon: 'view_headline',
              tooltip: '查看',
              onClick: (event, rowData) => this.handleData(rowData)
            }
          ]}
          onRowClick={(event, rowData) => this.handleData(rowData)}
          editable={this.editable}
        />
        <br />
        <br />
        {rowData && rowData.objectId ? <>
          {auth.edit ? <ModalUploadFiles {...this.props} isImage='1' rowData={rowData} handleData={this.handleData} /> : null}
          <br />
          <br />
          <MaterialTable
            isLoading={loading1}
            localization={localization()}
            columns={columns2({ isMultiLang: companyObj.isMultiLang, photos: data2 })} options={{
              addRowPosition: "first",
              exportButton: true,
              exportPdf: (columns, data) => exportPdf(columns, data, '照片'),
              pageSize: 5,
              search: false,
            }}
            tableRef={this.tableRef}
            data={data2}
            title={`${rowData.name}相簿：照片`}
            components={{
              Row: props => rowReOrder(props, 'webimage', 'order', data2, 'data2', this.handleState)
            }}
            // actions={[
            //   {
            //     icon: 'view_headline',
            //     tooltip: '查看',
            //     onClick: (event, rowData) => this.handleData(rowData)
            //   }
            // ]}
            editable={{
              isEditHidden: () => !auth.edit,
              isDeleteHidden: () => !auth.edit,
              // onRowAdd: newData =>
              //   new Promise(async (resolve, reject) => {
              //     const db = Parse.Object.extend("webimage");
              //     const newDoc = new db();
              //     const docRef = await newDoc.save();
              //     const data = [...data2];
              //     let obj = {
              //       ...newData,
              //       id: docRef.id,
              //       _id: docRef.id,
              //       objectId: docRef.id,
              //       companyId: companyObj.objectId,
              //       companyName: companyObj.name,
              //       albumId: rowData.objectId
              //     }
              //     data.push(obj);
              //     this.setState({ data2: data, newData: obj }, () => resolve());
              //   }).then(() => this.handleUpdate('2')),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  let data = [...data2];
                  const index = data.indexOf(oldData);
                  if (newData.iscover === true) {
                    data = data2.map(item => ({ ...item, iscover: false }))
                  }
                  data[index] = newData;
                  let obj = { ...newData }

                  this.setState({ data2: data, newData: obj, oldData }, () => resolve());
                }).then(() => this.handleUpdate('2')),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  let data = [...data2];
                  const index = data.indexOf(oldData);
                  data.splice(index, 1);
                  this.setState({ data2: data, oldData }, () => resolve());
                }).then(() => this.handleDelete('2')),
            }}
          />
        </> : null}
      </div>
    </>)
  }
}

export default AlbumCenter