import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

const columns = () => ([
  { title: '牌照號碼', field: 'licenseNum' },
  { title: '車主名稱', field: 'ownersName' },
  { title: '引擎號碼', field: 'engineNum' },
  { title: '車身號碼', field: 'bodyNum' },
  { title: '住所電話', field: 'homePhone' },
  { title: '手機號碼', field: 'mobilePhoneNum' },
  { title: '使用名稱', field: 'useName' },
  { title: '聯絡名稱', field: 'contactName' },
]);
const columns2 = () => ([
  { title: '', field: '' },
]);
export default class Hr1 extends Component {
  state = {
    dataArr: [],
    filenames: [],
    downloadURLs: [],
    docs: [],
    fileArr: [],
    fileArrCheck: [],
    show: false,
    // loading: true,
    obj: {}
  }

  // componentDidMount() {
  //   this.handleFetch();
  // }

  // componentWillReceiveProps(nextProps) {
  //   this.handleFetch(nextProps);
  // }

  // handleFetch = async (props = this.props) => {
  //   const { companyObj } = props;

  //   const companyId = companyObj.objectId;
  //   const data = await Parse.queryData('hrform_management', { companyId });
  //   this.setState({ dataArr: data, loading: false });
  // }

  // handleUpdate = async () => {
  //   const { newData } = this.state
  //   await Parse.saveData('hrform_management', newData);
  // }

  render() {
    const { dataArr, loading } = this.state;

    return (<div className='content'>
      {/* <Container> */}
      {/* <div style={{ ...style.flexCenter, position: 'relative', zIndex: 100 }}>
          <Header as='h2' style={{ margin: '2rem 0' }}>4-1 人事規章</Header>
        </div> */}
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          pageSize: 5,
          search: true,
          searchFieldStyle: {
            width: 90,
          }
        }}
        data={dataArr}
        title={'查詢資料'}
      />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns2()}
        options={{
          pageSize: 5,
          search: true,
          searchFieldStyle: {
            width: 90,
          }
        }}
        data={dataArr}
        title={'查詢方式'}
      />
      <br />
      <br />
    </div>)
  }
}