import React, { Component } from 'react';
import { Form, Button, Modal, Table, Grid, Icon, Header, Confirm, TextArea, Divider, Card, Tab, Radio } from 'semantic-ui-react';
import { showField } from 'views/widgets/FieldsRender';
import Moment from 'moment';
import Parse from 'widget/parse'
import { Row, Col } from 'reactstrap'

const clientTypeOpt = [
  { key: 'clientType1', text: '在籍学生', value: '1' },
  { key: 'clientType2', text: '其他', value: '2' },
]

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export default class DormInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      isReadOnly: false,
      form: {
        // receptionAuth: '0',
        // recruitAuth: '0',
        // enrollAuth: '0',
        // academicAuth: '0',
        // gAffairAuth: '0',
        // pAffairAuth: '0',
        // receptionAuthText: '無',
        // recruitAuthText: '無',
        // enrollAuthText: '無',
        // academicAuthText: '無',
        // gAffairAuthText: '無',
        // pAffairAuthText: '無',
        ...props.roomObj
      },
      loading: false,
      confirm: false,
      unsub: null,
      room: [],
      contract: []
      // stuData: [],
      // roomType: this.props.roomType,
    };
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);

    // if (nextProps.roomType !== this.state.roomType) {
    //   console.log('123')
    //   this.setState({ roomType: nextProps.roomType });
    // }
    // this.handleFetch(nextProps)
    // this.setState({ form: nextProps.roomObj });
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, match } = this.props
    // firebase.firestore().collection(`Dormitory/${match.params._id}/Room/${match.params.roomid}/Contract`)
    //   .get().then(snapshot => {
    //     const contractData = []
    //     snapshot.forEach(fileItem => contractData.push({ _id: fileItem.id, ...fileItem.data() }));
    //     this.setState({ contract: contractData })
    //   }).catch(error => {
    //     console.log(error);
    //   });
    const contract = await Parse.queryData('Contract', { companyId: companyObj.objectId, dormId: match.params._id, roomId: match.params.roomid });
    this.setState({ contract })
  }

  handleChange = (event, { name, value }) => {
    const { form } = this.state
    this.setState({
      form: {
        ...form,
        [name]: value,
      }
    }, () => console.log(form))
  }

  handleChangeType = (event, { name, value }) => {
    const { form } = this.state
    let dataNum = Number(name.substring(10))
    console.log(dataNum)
    this.setState({
      form: {
        ...form,
        [name]: value,
        [`studentNum${dataNum}`]: '',
        [`name${dataNum}`]: ''
      }
    }, () => console.log(form))
  }
  handleChangeSel = (e, { name, value }) => {
    const { contract } = this.state
    const fieldName = name;
    const fieldValue = value;
    let bedNum = fieldName.substring(13)
    let mappingArr = [
      { from: 'studentName', to: `studentName${bedNum}` },
      { from: 'studentNum', to: `studentNum${bedNum}` },
      { from: 'contractSign', to: `contractSign${bedNum}` },
      { from: 'tenantType', to: `tenantType${bedNum}` },
      { from: 'contractStart', to: `contractStart${bedNum}` },
      { from: 'contractEnd', to: `contractEnd${bedNum}` },
      { from: 'rent', to: `rent${bedNum}` },
    ];

    // let form = { _id: this.props.doc._id };

    // if (fieldValue === 'clear') { // 清空表單
    //   mappingArr.forEach(({ to }) => {
    //     const setting = { [to]: '' };
    //     form = { ...form, ...setting };
    //   })
    // } else if (fieldValue !== "fromSupport" && fieldValue !== "fromAgent") { // 將参照親族資料寫入表單
    //   const { relative } = this.state;
    //   const referRelative = relative.find(item => item._id === fieldValue);

    //   if (referRelative) {
    //     mappingArr.forEach(({ from, to }) => {
    //       if (referRelative[from]) {
    //         const setting = { [to]: referRelative[from] };
    //         form = { ...form, ...setting };
    //       }
    //     });
    //   }
    // } else {
    let form = { objectId: this.props.doc.objectId };
    const referContract = contract.find(item => item.objectId === fieldValue);
    mappingArr.forEach(({ from, to }) => {
      const setting = { [to]: referContract[from] };
      form = { ...form, ...setting };
    });
    // }
    this.props.setRelativeObj(form);
    this.setState({ [fieldName]: fieldValue })
  };
  render() {
    const { dorms, dormObj, roomObj, match, isEdit, students = [], handleUpdateSetting, doc } = this.props
    const { form, room, loading, stuData, referContract, contract } = this.state;
    const { roomName, roomFloor, roomType, roomStatus/*, roomTypeText, roomStatusText */ } = form;
    const stuArr = students.sort((a, b) => b.studentId - a.studentId).map(item => ({ key: item.objectId, value: item.objectId, text: `${item.studentId} ${item.jpnName || item.homeName}` }))
    // const stuOpt = [
    //   { key: 'stuOptNull', value: ' ', text: '-- 選択 --' },
    //   ...stuArr
    // ]
    const referOptions = contract.sort((a, b) => a.contractSign - b.contractSign)
      .map(item => ({ key: item.objectId, value: item.objectId, text: `${item.studentNum} ${item.studentName}`, bedNum: item.bedNum }))
    const referOptionsArr = [
      { key: 'sel', text: '-- 選択 --', value: ' ', disabled: false },
      ...referOptions
    ]
    let bedNumOpt = new Array(Number(roomObj.roomType || '')).fill().map((item, index) => ({ key: index, text: `床位${index + 1}`, i: index + 1 }))
    return (
      <div style={{ border: '1px solid #d4d4d5', padding: '1em 1em', background: '#FFFFFB' }}>
        <Form.Field>
          <div style={style.flexCenter}>
            <Header as='h2' content='マスター情報' />
            {/* {isEdit ? <Button inverted={companyObj.inverted} color='blue' icon='save' content='保存' onClick={this.handleSubmit} loading={loading} /> : null} */}
          </div>
          <Divider />
          {/* <Grid columns='equal' divided> */}
          <Col >
            <Card fluid>
              <Card.Content header='房間情報' />
              <Card.Content>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col>{showField(this.props, "roomName")}</Col>
                  <Col>{showField(this.props, "roomFloor")}</Col>
                  <Col>{showField(this.props, "roomType")}</Col>
                  {/* </Grid> */}
                </Row>
              </Card.Content>
            </Card>
            <Card fluid>
              <Card.Content header='房客情報' />
              <Card.Content>
                {/* {this.renderForm(roomType)} */}
                {bedNumOpt.map(({ i }) => (
                  <Row key={`newArr${i}`}>
                    {/* <Grid columns='equal'> */}
                    <Row>
                      <Col width={1}>床位{i}</Col>
                      {isEdit ? <Col width={3}>
                        <Form.Select label='情報導入' defaultValue={[`referContract${i}`] || ''} name={`referContract${i}`} onChange={this.handleChangeSel} options={referOptionsArr.filter(item => item.bedNum === i)} />
                      </Col> : null}
                      {/* <Col width={3}>
                            <Form.Select
                              disabled={!isEdit}
                              label='房客類型'
                              options={clientTypeOpt}
                              name={`clientType${i}`}
                              defaultValue={doc[`clientType${i}`] || '2'}
                              onChange={handleUpdateSetting} />
                          </Col>
                          {doc[`clientType${i}`] && doc[`clientType${i}`] === '1' ?
                            <Col>
                              <Form.Select disabled={!isEdit} search options={stuOpt} label='在籍学生' defaultValue={' '} name={`studentData${i}`} readOnly={!isEdit} onChange={handleUpdateSetting} />
                            </Col> : null} */}
                      <Col>
                        {isEdit ? <Form.Input
                          label='学籍番号' value={doc[`studentNum${i}`] || ''}
                          readOnly={doc[`clientType${i}`] === '1'} name={`studentNum${i}`} onChange={handleUpdateSetting}
                        /> : <Form.Input
                            label='学籍番号' value={doc[`studentNum${i}`] || ''}
                            readOnly={doc[`clientType${i}`] === '1'} name={`studentNum${i}`} readOnly
                          />}
                      </Col>
                      <Col>
                        {isEdit ? <Form.Input
                          label='日本語氏名' value={doc[`studentName${i}`] || ''}
                          name={`name${i}`} onChange={handleUpdateSetting}
                        /> : <Form.Input
                            label='日本語氏名' value={doc[`studentName${i}`] || ''}
                            name={`name${i}`} readOnly
                          />}
                      </Col>
                    </Row>
                    <Row>
                      <Col width={1}></Col>
                      <Col>
                        {isEdit ? <Form.Input
                          type="date" label='合約開始'
                          value={Moment(doc[`contractStart${i}`] || '').format('YYYY/MM/DD')}
                          name={`contractStart${i}`} onChange={handleUpdateSetting}
                        /> : <Form.Input
                            type="date" label='合約開始'
                            value={Moment(doc[`contractStart${i}`] || '').format('YYYY/MM/DD')}
                            name={`contractStart${i}`} readOnly
                          />}
                      </Col>
                      <Col>
                        {isEdit ? <Form.Input
                          type="date" label='合約結束'
                          value={Moment(doc[`contractEnd${i}`] || '').format('YYYY/MM/DD')}
                          name={`contractEnd${i}`} onChange={handleUpdateSetting}
                        /> : <Form.Input
                            type="date" label='合約結束'
                            value={Moment(doc[`contractEnd${i}`] || '').format('YYYY/MM/DD')}
                            name={`contractEnd${i}`} readOnly
                          />}
                      </Col>
                      <Col>
                        {isEdit ? <Form.Input
                          label='每月租金' value={doc[`rent${i}`] || ''}
                          name={`rent${i}`} onChange={handleUpdateSetting}
                        /> : <Form.Input
                            label='每月租金' value={doc[`rent${i}`] || ''}
                            name={`rent${i}`} readOnly />}
                      </Col>
                    </Row>
                    {/* </Grid> */}
                  </Row>
                ))}
              </Card.Content>
            </Card>
          </Col>

          {/* </Grid> */}
        </Form.Field>
      </div>
    )
  }
}