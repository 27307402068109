import React, { Component } from 'react'
import { Menu } from 'semantic-ui-react';

import Fin1102 from './Fin1102'
import Fin1102a2 from './Fin1102a2'
import Fin1117a2 from './Fin1117a2'
import Fin1103 from './Fin1103'
import Fin1104 from './Fin1104'
import Fin1111 from './Fin1111'
import Fin1112 from './Fin1112'
import Fin1113 from './Fin1113'
import Fin1114 from './Fin1114'
import Fin1116 from './Fin1116'
import Fin1117 from './Fin1117'
import Fin1121 from './Fin1121'
import Fin1131 from './Fin1131'

const menu = [{
  id: 'fin0',
  content: '5-1 資料輸入',
  menu: [
    { id: 'fin3-1', content: '5-1-1 日記帳' },
    { id: 'fin1-2', content: '5-1-2 月薪資表' },
    { id: 'fin1-4', content: '5-1-3 應收應付帳款明細表' },
    { id: 'fin1-1', content: '5-1-4 資金預估表' },
    { id: 'fin1-9', content: '5-1-5 資產負債表' },
  ]
}, {
  id: 'fin2',
  content: '5-2 財務報表',
  menu: [
    { id: 'fin2-1', content: '5-2-1 零用金及銀行帳戶明細表' },
    { id: 'fin1-3', content: '5-2-2 年度薪資總表' },
    { id: 'fin1-6', content: '5-2-3 損益表（收支總表）' },
    { id: 'fin1-7', content: '5-2-4 資產負債表' },
  ]
}, {
  id: 'fin1',
  content: '5-3 設定',
  menu: [
    { id: 'fin0-2', content: '5-3-1 會計科目' },
    { id: 'fin0-5', content: '5-3-2 資產負債科目' },
    { id: 'fin0-3', content: '5-3-3 銀行帳戶' },
    { id: 'fin0-4', content: '5-3-4 會計年度' }
  ]
}];

export default class Financial1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginUser: this.props.user.profile.toJSON(),
      dataArr: [],
      activeItem: props.match.params.activeItem || 'fin0',
      activeItem2: '',
      menu2: [],
    }
  }

  componentDidMount() {
    const { match, history } = this.props
    const { activeItem } = this.state

    const menu2 = (menu.find(item => item.id === activeItem) && menu.find(item => item.id === activeItem).menu) || [];
    const activeItem2 = match.params.activeItem2 || menu2[0].id;
    this.setState({ menu2, activeItem2 })
    history.push(`/admin/Financial1/${activeItem}/${activeItem2}`);
  }

  componentWillReceiveProps(nextProps) {
    const { loginUser } = this.state
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const pageArr = window.location.pathname.split('/')
    const authPage = pageArr[2] + '_' + pageArr[3]
    const authUser = companyId + '_' + authPage
    let auth = {}
    if ((loginUser.authAdminA && loginUser.authAdminA.indexOf(companyId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
      auth = {
        edit: true,
      }
    } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
      auth = {
        edit: false,
      }
    } else {
      auth = {
        edit: false,
      }
    }
    this.setState({ auth })
    this.handleFetch(nextProps);
  }

  handleFetch = (props = this.props) => {
    const { match, history } = props;
    const activeItem = props.match.params.activeItem
    const menu2 = (menu.find(item => item.id === activeItem) && menu.find(item => item.id === activeItem).menu) || [];
    const activeItem2 = match.params.activeItem2 || menu2[0].id;
    if (match.params.activeItem2 === undefined) {
      history.push(`/admin/Financial1/${activeItem}/${activeItem2}`);
    }
    this.setState({ menu2, activeItem, activeItem2 })
  }

  handleItemClick = (e, { name }) => {
    const { history } = this.props
    const { activeItem2 } = this.state

    const menu2 = (menu.find(item => item.id === name) && menu.find(item => item.id === name).menu) || [];
    history.push(`/admin/Financial1/${name}/${activeItem2}`);
    this.setState({ activeItem: name, menu2, activeItem2: menu2.length ? menu2[0].id : '' })
  }

  handleItemClick2 = (e, { name }) => {
    const { history } = this.props
    const { activeItem } = this.state
    history.push(`/admin/Financial1/${activeItem}/${name}`);
    this.setState({ activeItem2: name })
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  render() {
    const { companyObj } = this.props
    const { activeItem2, menu2, auth = {} } = this.state;

    return (<div className="content">
      <div>
        {menu2.length ? <Menu
          secondary={true} pointing={true} inverted={companyObj.inverted}
          attached='top' tabular stackable>
          {menu2.map(({ id, content }) => <Menu.Item key={id} name={id} content={content} active={activeItem2 === id} onClick={this.handleItemClick2} />)}
        </Menu> : null}
        {activeItem2 === 'fin0-2' ? <Fin1102 {...this.props} auth={auth} /> : null}
        {activeItem2 === 'fin0-3' ? <Fin1103 {...this.props} auth={auth} /> : null}
        {activeItem2 === 'fin0-5' ? <Fin1102a2 {...this.props} auth={auth} /> : null}
        {activeItem2 === 'fin0-4' ? <Fin1104 {...this.props} auth={auth} /> : null}

        {activeItem2 === 'fin1-1' ? <Fin1111 {...this.props} auth={auth} /> : null}
        {activeItem2 === 'fin1-2' ? <Fin1112 {...this.props} auth={auth} /> : null}
        {activeItem2 === 'fin1-3' ? <Fin1113 {...this.props} auth={auth} /> : null}
        {activeItem2 === 'fin1-4' ? <Fin1114 {...this.props} auth={auth} /> : null}
        {activeItem2 === 'fin1-6' ? <Fin1116 {...this.props} auth={auth} /> : null}
        {activeItem2 === 'fin1-7' ? <Fin1117 {...this.props} auth={auth} /> : null}
        {activeItem2 === 'fin1-9' ? <Fin1117a2 {...this.props} auth={auth} /> : null}

        {activeItem2 === 'fin2-1' ? <Fin1121 {...this.props} auth={auth} /> : null}
        {activeItem2 === 'fin3-1' ? <Fin1131 {...this.props} auth={auth} /> : null}
        <br />
        <br />
      </div>
    </div>)
  }
}