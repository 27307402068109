import React, { Component } from 'react';
import { Form, Grid, Header } from 'semantic-ui-react';
import { showField, showFieldText } from 'views/widgets/FieldsRender';
import { Row, Col } from 'reactstrap';
import {
  Card, CardBody
} from "reactstrap";
import Parse from 'widget/parse.js'


export default class DormStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // dormitorys: []
    };
  }

  // componentDidMount() {
  //   // console.log('componentDidMount');
  //   this.handleFetch();
  // }

  // componentWillReceiveProps(nextProps) {
  //   // console.log('componentWillReceiveProps');
  //   this.handleFetch(nextProps);
  // }

  // handleFetch = async (props = this.props) => {
  //   const { firebase, match } = this.props
  //   const { _id } = match.params;
  //   // firebase.firestore().collection("Dormitorys")
  //   //   .get().then(snap => {
  //   //     const data = [];
  //   //     snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }));
  //   //     // console.log(data)
  //   //     this.setState({ dormitorys: data });
  //   //   });
  //   // firebase.firestore().collection(`Students/${_id}/Dormitorys`)
  //   //   .get().then(snap => {
  //   //     const data = [];
  //   //     snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }));
  //   //     // console.log(data)
  //   //     this.setState({ dormitorys: data });
  //   //   });
  //   const dormitorys = await Parse.queryData
  // }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.props.handleUpdateSetting({ key: name, value });
  };


  render() {
    const { semesterYear, semester, isEdit, doc } = this.props;
    // const { dormitorys } = this.state

    // const domitorysArr = dormitorys.map(item => ({ key: item._id, value: item._id, text: item.dormitoryName }))
    // const dormitorysOpt = [
    //   { key: '', value: '', text: '-- 選択 --' },
    //   ...domitorysArr
    // ]

    return (<>
      <Header as='h2' dividing
        content='寮予約'
        style={{ backgroundColor: 'lavender' }}
      // subheader='調査時間：2017年4月から 2018年12月 まで'
      />
      {/* <br /> */}
      <Row>
        {/* <Grid columns='equal'> */}
        <Col>
          <Card fluid>
            <CardBody>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "hopeDorm")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                {/* <Col>
                        {isEdit ? <Form.Select
                          label='第一予想'
                          options={dormitorysOpt}
                          readOnly={isEdit ? false : true}
                          name="hope1Dorm" value={doc.hope1Dorm ? doc.hope1Dorm : ''}
                          onChange={this.handleChange}
                        /> : showField(this.props, "hope1Dorm")}
                      </Col> */}
                <Col>{showField(this.props, "hope1Dorm")}</Col>
                <Col>{showField(this.props, "type1Dorm")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                {/* <Col>
                        {isEdit ? <Form.Select
                          label='第二予想'
                          options={dormitorysOpt}
                          readOnly={isEdit ? false : true}
                          name="hope2Dorm" value={doc.hope2Dorm ? doc.hope2Dorm : ''}
                          onChange={this.handleChange}
                        /> : showField(this.props, "hope2Dorm")}
                      </Col> */}
                <Col>{showField(this.props, "hope2Dorm")}</Col>
                <Col>{showField(this.props, "type2Dorm")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                {/* <Col>
                        {isEdit ? <Form.Select
                          label='第三予想'
                          options={dormitorysOpt}
                          readOnly={isEdit ? false : true}
                          name="hope3Dorm"
                          value={doc.hope3Dorm ? doc.hope3Dorm : ''}
                          onChange={this.handleChange}
                        /> : showField(this.props, "hope3Dorm")}
                      </Col> */}
                <Col>{showField(this.props, "hope3Dorm")}</Col>
                <Col>{showField(this.props, "type3Dorm")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "psDorm")}</Col>
                {/* </Grid> */}
              </Row>
            </CardBody>
          </Card>
        </Col>
        {/* </Grid> */}
      </Row>
    </>);
  }
}