
import React, { Component } from 'react'
// import { Container, Header, Modal, Button, Table, Form, Grid, Image, Input, Checkbox, Divider, Tab } from 'semantic-ui-react';


// reactstrap components

class PrintPage extends Component {
  state = {
  }

  render() {
    // const { } = this.state

    return (
      <>
        <div className="content">
          PrintPage
          </div>      </>
    );
  }
}

export default PrintPage;
