import React, { Component } from 'react'
import { Container, Button, Icon, Form, Menu } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import _ from 'underscore'
import Moment from 'moment';
import Parse from 'widget/parse'

import localization from 'widget/MaterialTableOpt'
import UserAdd from './UserAdd'
import User1 from './User1'
import User2 from './User2'
import User3 from './User3'
import User4 from './User4';

const d = new Date();
const date = d.getFullYear() + "年" + (d.getMonth() + 1) + "月" + d.getDate() + "日";

const userOpt = [
  { key: '0', text: '全部', value: '0' },
  { key: '1', text: '在職', value: '1' },
  { key: '2', text: '離職', value: '2' },
]

const menu = [
  { id: 'user1', content: '基本資料' },
  { id: 'user2', content: '權限設定' },
  { id: 'user3', content: '登入資訊' },
  { id: 'user4', content: '操作記錄' },
  // { id: 'user5', content: '刪除帳號' },
]

// authAdminA: (2) ["nsgdMpkSq4", "qDtG0Bi0xX"]
// authAllA: (5) ["nsgdMpkSq4", "qDtG0Bi0xX", "OcuM5jEqh7", "efnvfE1gJS", "2V4JaTdWr6"]
// authLoginA: (5) ["OcuM5jEqh7", "nsgdMpkSq4", "efnvfE1gJS", "2V4JaTdWr6", "qDtG0Bi0xX"]
// authUserA: 

const columns = (companySel = {}) => ([
  { width: 80, title: '使用者名稱', field: 'username', editable: 'never' },
  { width: 80, title: '工號', field: 'jobNum' },
  { width: 80, title: '職稱', field: 'jobTitle' },
  { width: 80, title: '資宸所屬員工', field: 'infowin', type: 'boolean' },
  { width: 80, title: '姓名', field: 'name' },
  { width: 80, title: '手機', field: 'phone' },
  // { width: 80, title: '電子信箱', field: 'email' },
  // { title: '介紹人', field: 'recommandName' },
  // { title: '優惠碼', field: 'discountCode' },
  // { title: '建立日期', field: 'createdAt', editable: 'never' },
  // { title: '最後更新日期', field: 'updatedAt', editable: 'never' },
  // { title: '使用者', field: 'authUser', type: 'boolean', width: 60 },
  {
    title: 'ACL', field: 'authUserA', type: 'boolean', width: 160,
    render: rowData => <pre>{JSON.stringify(rowData.ACL, '', 2)}</pre>
    // render: rowData => rowData.ACL.role
  },
  {
    title: '1用戶 authUser', field: 'authUserA', type: 'boolean', width: 160,
    render: rowData => rowData.authUserA && rowData.authUserA.map(item => <div>{companySel[item]}</div>)
  },
  {
    title: '2在職 authLogin', field: 'authLoginA', type: 'boolean', width: 160,
    render: rowData => rowData.authLoginA && rowData.authLoginA.map(item => <div>{companySel[item]}</div>)
  },
  {
    title: '3系統管理 authAdmin', field: 'authAdminA', type: 'boolean', width: 160,
    render: rowData => rowData.authAdminA && rowData.authAdminA.map(item => <div>{companySel[item]}</div>)
  },
  { width: 160, title: '備註', field: 'ps' },
  { width: 120, title: '新增時間', field: 'createdAt', type: 'datetime', editable: 'never' },
  { width: 120, title: '更新時間', field: 'updatedAt', type: 'datetime', editable: 'never' },
]);

const columns2 = () => ([
  { width: 80, title: '行業別', field: 'sectorText', editable: 'never' },
  { width: 120, title: '公司名(中)', field: 'companyName', editable: 'never' },
  { title: '使用者', field: 'authUser', type: 'boolean', width: 60 },
  { title: '登入', field: 'authLogin', type: 'boolean', width: 60 },
  { title: '人資', field: 'authHr', type: 'boolean', width: 60 },
  { title: '會計', field: 'authAccount', type: 'boolean', width: 60 },
  { title: '管理', field: 'authAdmin', type: 'boolean', width: 60 },
]);

const getAuthArray = (company, user, flag) => company[flag] ? _.union(user[flag + 'A'], [company.objectId]) : _.without(user[flag + 'A'], company.objectId);
const getAuth = (id, arr) => (arr.length && arr.indexOf(id) !== -1)

export default class AllUsers extends Component {
  state = {
    dataArrOri: [],
    dataArr: [],
    loading: false,
    companyArr: [],
    userObj: {},
    newData: {},
    oldData: {},
    newData2: {},
    oldData2: {},
    activeItem: 'user1',
    statusSel: '1'
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    // const { companyObj } = props;

    const companies = await Parse.getSelection('company', {}, { name: 'name', sort: 'name' })
    // console.log(companies.sel)
    this.setState({ companySel: companies.sel });
    // const users = await Parse.queryData("User");

    const classDb = Parse.Object.extend("User");
    const query = new Parse.Query(classDb);
    const snap = await query.find();

    const users = snap.map(data => {
      // console.log(data.id, data.getACL())
      return {
        permissions: (data.getACL() && data.getACL().permissionsById) || {},
        ...data.toJSON()
      }
    });

    // const dataArrOri = users
    const dataArrOri = users.map(element => ({
      ...element,
      // authAdmin: getAuth(companyObj.objectId, element.authAdminA || []),
      // authUser: getAuth(companyObj.objectId, element.authUserA || []),
      // authLogin: getAuth(companyObj.objectId, element.authLoginA || []),
    }))

    console.log(dataArrOri)
    this.setState({ dataArrOri, dataArr: dataArrOri })
  }

  getfilterUsers = () => {
    const { companyObj } = this.props
    const { statusSel, dataArrOri = [] } = this.state

    let dataArr = [];
    if (statusSel === '0') {
      dataArr = dataArrOri;
    } else if (statusSel === '1') {
      dataArr = dataArrOri.filter(item => item.authLoginA && item.authLoginA.indexOf(companyObj.objectId) !== -1)
    } else if (statusSel === '2') {
      dataArr = dataArrOri.filter(item => !item.authLoginA || item.authLoginA.indexOf(companyObj.objectId) === -1)
    }
    this.setState({ dataArr });
  }

  handleUpdate = async () => {
    const getAuthArray = (company, user, flag) => user[flag] ? _.union(user[flag + 'A'], [company.objectId]) : _.without(user[flag + 'A'], company.objectId);

    const { companyObj } = this.props
    const { newData } = this.state

    const userObj2 = newData;
    userObj2.authAdminA = getAuthArray(companyObj, userObj2, 'authAdmin')
    // userObj2.authHrA = getAuthArray(companyObj, userObj2, 'authHr')
    // userObj2.authAccountA = getAuthArray(companyObj, userObj2, 'authAccount')
    userObj2.authUserA = getAuthArray(companyObj, userObj2, 'authUser')
    userObj2.authLoginA = getAuthArray(companyObj, userObj2, 'authLogin')
    userObj2.authAllA = _.union(userObj2.authAdminA,
      //  userObj2.authHrA, userObj2.authAccountA, 
      userObj2.authUserA);

    delete userObj2.authAdmin;
    // delete userObj2.authHr;
    // delete userObj2.authAccount;
    delete userObj2.authUser;
    delete userObj2.authLogin;
    console.log(userObj2)

    // await db.doc(`users/${newData.id}`).set(userObj2);
    await Parse.saveData("User", userObj2)
    this.setState({ oldData: {}, newData: {} })
  }

  // handleDelete = async (id) => {
  //   const { firebase } = this.props
  //   const { oldData } = this.state
  //   const db = firebase.firestore()

  //   await db.collection(`users`).doc(oldData.id).delete();
  //   this.setState({ oldData: {} })
  // }

  handleData = (userObj) => {
    const { companyArr } = this.state
    const { authAdminA = [], authHrA = [], authAccountA = [], authUserA = [], authLoginA = [] } = userObj;

    const companyArr2 = companyArr.map(item => ({
      ...item,
      authAdmin: getAuth(item._id, authAdminA),
      authHr: getAuth(item._id, authHrA),
      authAccount: getAuth(item._id, authAccountA),
      authUser: getAuth(item._id, authUserA),
      authLogin: getAuth(item._id, authLoginA),
    }));
    this.setState({ userObj, companyArr: companyArr2 });
  }

  handleUpdateUserAuth = async () => {
    const { firebase } = this.props;
    const { userObj, newData2 } = this.state
    const db = firebase.firestore()
    // console.log(companyArr, userObj, newData2)

    const userObj2 = userObj;
    userObj2.authAdminA = getAuthArray(newData2, userObj2, 'authAdmin')
    userObj2.authHrA = getAuthArray(newData2, userObj2, 'authHr')
    userObj2.authAccountA = getAuthArray(newData2, userObj2, 'authAccount')
    userObj2.authUserA = getAuthArray(newData2, userObj2, 'authUser')
    userObj2.authLoginA = getAuthArray(newData2, userObj2, 'authLogin')
    userObj2.authAllA = _.union(userObj2.authAdminA, userObj2.authHrA, userObj2.authAccountA, userObj2.authUserA);

    await db.doc(`users/${userObj2.id}`).update(userObj2);
  }

  resetPW = () => {
    const { firebase } = this.props;
    const auth = firebase.auth();
    auth.sendPasswordResetEmail('yinchen618@gmail.com').then(function () {
      // Email sent.
    }).catch(function (error) {
      // An error happened.
    });
  }
  handleItemClick = (e, { name }) => {
    // const { activeItem, activeItem2 } = this.state
    // console.log(bgId)
    // const menu2 = (menu.find(item => item.id === name) && menu.find(item => item.id === name).menu) || [];
    // this.props.history.push(`/Financial1/${bgId}/${name}/${menu2[0].id}`);
    this.setState({ activeItem: name })
  }
  handleChange = (e, { name, value }) => {
    const { statusSel } = this.state
    this.setState({ statusSel: value }, () => this.getfilterUsers())
  }
  handleChange1 = (e, { name, value }) => {
    const { userObj } = this.state;
    this.setState({ userObj: { ...userObj, [name]: value } })
  }

  handleSaveUser = async () => {
    const { } = this.props
    const { userObj } = this.state;
    // const { pray1 } = ;
    // const db = firebase.firestore();
    // const docRef = db.collection(`users`).doc(userObj.id);

    // if (this.props.companyObj.pray1IdDef !== companyObj.pray1IdDef) {
    //   const arr = pray1.map((item, i) => ({ ...item, now_use: (companyObj.pray1IdDef === item.id) ? "-1" : '0' }));
    //   const batch = db.batch()
    //   arr.forEach((item, index) => {
    //     const ref = db.doc(`company/${companyObj.objectId}/pray1/${item.id}`);
    //     batch.update(ref, item);
    //   })
    //   await batch.commit()
    // }

    // console.log(userObj)
    // await docRef.update(userObj);
    // window.alert('已儲存');
    this.handleFetch();
  }
  sendMailTest = () => {
    const { firebase } = this.props;
    const db = firebase.firestore()

    // var newDateObj = Moment(new Date()).add(3, 'm').toDate();

    db.collection('mail').add({
      // delivery: {
      //   state: 'PROCESSING',
      //   leaseExpireTime: newDateObj
      // },
      from: '666 <no-reply@gmail.com>',
      replyTo: 'rrr <rrr@infowin.com.tw>',
      to: 'yinchen618@gmail.com',
      message: {
        subject: `rrr`,
        html: `666234您的帳號：122223`,
      }
    }).then(() => {
      window.alert('已成功寄出')
      console.log('Queued email for delivery!')
      // res.status(200).send('Email sent successfull!')
    })
  }

  render() {
    const { companyObj } = this.props;
    const { dataArr, userObj, loading, statusSel, activeItem, companySel } = this.state;
    // console.log(userObj)

    return (<>
      {/* <Button basic color='green' size='tiny' onClick={() => this.resetPW()}>
        <Icon name='user plus' /> 重設密碼</Button>
      <Button basic color='olive' size='tiny' onClick={() => this.sendMailTest()}>
        <Icon name='user plus' /> 測試</Button> */}
      <div className="content">
        <Form>
          <Form.Group>
            <Form.Select style={{ position: 'relative', zIndex: 110 }}
              loading={loading}
              options={userOpt}
              onChange={this.handleChange}
              value={statusSel}
              name='statusSel' />
          </Form.Group>
          <br />
          <MaterialTable
            isLoading={loading}
            components={{
              Toolbar: props => (<div>
                <div style={{ width: '150px', float: 'right', textAlign: 'right', padding: '15px' }}>
                  <UserAdd
                    {...this.props}
                    refetch={this.handleFetch}
                  />
                </div>
                <MTableToolbar {...props} />
                <div style={{ clear: 'both' }}></div>
              </div>),
            }}
            localization={localization()}
            columns={columns(companySel)} options={{
              addRowPosition: "first",
              pageSize: 20,
              search: true,
              tableLayout: 'fixed',
            }}
            data={dataArr}
            // actions={[
            //   {
            //     icon: 'view_headline',
            //     tooltip: '查看',
            //     onClick: (event, rowData) => this.handleData(rowData)
            //   }
            // ]}
            // onRowClick={(event, rowData) => this.handleData(rowData)}
            title={'所有使用者'}
            editable={{
              // onRowAdd: newData =>
              //   new Promise((resolve, reject) => {
              //     const db = firebase.firestore();
              //     const docRef = db.collection('users').doc();
              //     const data = [...dataArr];

              //     const obj = {
              //       _id: docRef.id,
              //       id: docRef.id,
              //       ...newData,
              //       createdDate: date,
              //       createdAt: new Date(),
              //     }
              //     data.push(obj);
              //     this.setState({ dataArr: data, newData: obj }, () => resolve());
              //   }).then(() => this.handleAdd()),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const data = [...dataArr];
                  const index = data.indexOf(oldData);
                  data[index] = newData;

                  const obj = {
                    ...newData,
                    // updatedDate: date,
                    updatedAt: new Date(),
                  }
                  this.setState({ dataArr: data, newData: obj, oldData }, () => resolve());
                }).then(() => this.handleUpdate()),
              // onRowDelete: oldData =>
              //   new Promise((resolve, reject) => {
              //     let data = [...dataArr];
              //     const index = data.indexOf(oldData);
              //     data.splice(index, 1);
              //     this.setState({ dataArr: data, oldData }, () => resolve());
              //   }).then(() => this.handleDelete()),
            }}
          /></Form>
        <br />
        {userObj && userObj._id ? <>
          <Menu pointing>
            {menu.map(({ id, content }) =>
              <Menu.Item
                key={id}
                name={id}
                // activeIndex={activeIndex}
                content={content}
                active={activeItem === id}
                onClick={this.handleItemClick}
              />)}
          </Menu>
          <br />
          {activeItem === 'user1' ? <User1 {...this.props} userObj={userObj} handleChange={this.handleChange1} handleSaveUser={this.handleSaveUser} /> : null}
          {activeItem === 'user2' ? <User2 {...this.props} /> : null}
          {activeItem === 'user3' ? <User3 {...this.props} /> : null}
          {activeItem === 'user4' ? <User4 {...this.props} /> : null}
          {/* <User1 {...this.props} /> */}
          {/* <MaterialTable
          columns={columns2()}
          options={{
            addRowPosition: "first",
exportButton: true,
            pageSize: 5,//data1.length || 5,
            search: false,
            tableLayout: 'fixed',
          }}
          data={companyArr}
          title={`權限一覽 - ${userObj.email}`}
          editable={{
            onRowUpdate: (newData2, oldData2) =>
              new Promise((resolve, reject) => {
                const data = companyArr;
                const index = data.indexOf(oldData2);

                data[index] = newData2;
                // console.log(data, newData)
                this.setState({ companyArr: data, newData2, oldData2 }, () => resolve());
              }).then(() => this.handleUpdateUserAuth()),
          }}
        /> */}
        </> : null}
      </div>
    </>)
  }
}