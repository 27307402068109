import React, { Component } from 'react';
import { Table, Header, Segment, Tab, Select } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, typeCurrency, monthOpt, mgyearOpt, exportPdf } from 'views/web/CustomMaterialTable';

// 1 月報表
// 結清日期	編號	借款日期	合件	姓名	主	 金額 	車號	 部份還本 	 結餘金額 	備註	身分證號碼	手機號	保人手機號
const columns = (employeeObj) => ([
  // { width: 40, title: '代號', field: 'code', lookup: code },
  { width: 80, title: '客戶姓名', field: 'clientName' },
  { width: 80, title: '當物類別', field: 'pawnTypeText' },
  { width: 80, title: '(總)本金結餘', field: 'principalRest', ...typeCurrency },
  { width: 80, title: '(總)應收利息', field: 'interestPlan', ...typeCurrency },
  { width: 80, title: '(總)繳息金額', field: 'interestPaid', ...typeCurrency },
  { width: 10, title: '', field: 'aaa', },
  { width: 80, title: '計劃繳息日期', field: 'planDate', editable: 'never' },
  {
    width: 80, title: '合件', field: 'joint', editable: 'never',
    render: rowData => <>
      {(rowData && rowData.jointObjArr && rowData.jointObjArr.length && rowData.jointObjArr.map(({ objectId, main, jobNum }, index) =>
        <span key={objectId} style={main === '1' ? { color: 'red' } : {}}>{index !== 0 ? ',' : null}{jobNum}</span>)) || null}
    </>
  },
  { width: 80, title: '比例', field: 'proportion', editable: 'never' },
  { width: 80, title: '業績', field: 'performance', ...typeCurrency },
  { width: 80, title: '應收利息', field: 'interestPlanSelf', ...typeCurrency },
  { width: 80, title: '實收利息', field: 'interestPaidSelf', ...typeCurrency },
  { width: 80, title: '佣金', field: 'commission', ...typeCurrency },
  { width: 180, title: '備註', field: 'ps' },
]);

const d = new Date()
export default class EmployeeTab2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataArr: [],
      data1: [],
      rowData: {},
      newData: {},
      oldData: {},
      loading: true,
      performanceTotal: 0,
      interestPlanSelfTotal: 0,
      interestPaidSelfTotal: 0,
      interestUnPaidSelfTotal: 0,
      commissionTotal: 0,
      monthSel: String(d.getMonth() + 1),
      mgyearSel: String(d.getFullYear() - 1911),
    }
  }

  componentDidMount = () => {
    this.handleFetch()
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { employeeObj } = props
    const { interest, objectId } = employeeObj;

    let performanceTotal = 0
    let interestPlanSelfTotal = 0
    let interestPaidSelfTotal = 0
    let commissionTotal = 0

    const data = await Parse.queryData('pawning2', { jointArr: objectId });
    const data1 = data.map(item => {
      const { jointObjArr, principalRest, interestPlan, interestPaid } = item;
      const jointObj = jointObjArr.find(item2 => item2.objectId === objectId)
      if (jointObj) {
        const { proportion } = jointObj

        const performance = principalRest ? Number(principalRest) * Number(proportion) / 100 : 0;
        const interestPlanSelf = interestPlan ? Number(interestPlan) * Number(proportion) / 100 : 0;
        const interestPaidSelf = interestPaid ? Number(interestPaid) * Number(proportion) / 100 : 0;
        const commission = interestPaidSelf ? Number(interestPaidSelf) * Number(interest) / 100 : 0;

        performanceTotal += performance;
        interestPlanSelfTotal += interestPlanSelf;
        interestPaidSelfTotal += interestPaidSelf;
        commissionTotal += commission;

        return { ...item, performance, interestPlanSelf, interestPaidSelf, commission }
      } else {
        return item;
      }
    })
    const interestUnPaidSelfTotal = interestPlanSelfTotal - interestPaidSelfTotal;
    this.setState({
      dataArr: data1, data1, loading: false,
      performanceTotal,
      interestPlanSelfTotal,
      interestPaidSelfTotal,
      interestUnPaidSelfTotal,
      commissionTotal
    });
  }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value }, () => this.hanldeFilter())
  }

  hanldeFilter = () => {
    const { dataArr, monthSel } = this.state
    const data = []
    dataArr.forEach(item => {
      const d1 = new Date(item.planDate)
      const month = String(d1.getMonth() + 1)
      if (month === monthSel) {
        data.push({ ...item })
      }
    })
    this.setState({ data1: data })
  }

  render() {
    const { employeeObj } = this.props
    const { dataArr, data1, loading, mgyearSel, monthSel,
      // performanceTotal,
      // interestPlanSelfTotal,
      // interestPaidSelfTotal,
      interestUnPaidSelfTotal,
      commissionTotal } = this.state;
    return (<>
      {/* 加入年/月 */}
      <div style={{ textAlign: 'left' }}>
        <Select name="mgyearSel" style={{ position: 'relative', zIndex: 99, marginRight: '8px' }} options={mgyearOpt} value={mgyearSel} onChange={this.handleChange} />
        <Select name="monthSel" style={{ position: 'relative', zIndex: 99 }} options={monthOpt} value={monthSel} onChange={this.handleChange} />
      </div>
      <br />
      {/* 可以選 借款/繳息/繳息 - 已繳/繳息 - 未繳/還款<br />
      日期	編號	借款 合件	主	 金額 	車號	 部份還本 	 結餘金額 	備註<br /> */}
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns(employeeObj)}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `業績： [未收利息合計] ${interestUnPaidSelfTotal} [佣金合計] ${commissionTotal}`),
          pageSize: 20,//dataArr.length < 5 ? 5 : dataArr.length + 1,
          search: false,
          tableLayout: 'fixed',
        }}
        data={data1}
        // tableRef={this.tableRef}
        title={`業績： [未收利息合計] ${interestUnPaidSelfTotal} [佣金合計] ${commissionTotal}`}
        actions={[
          {
            icon: 'view_headline',
            tooltip: '查看',
            // onClick: (event, rowData) => this.handleData(rowData)
          }
        ]}
      />
    </>)
  }
}