import React, { Component } from 'react'
import { Header, Grid, Divider, Button, List, Segment, Form } from 'semantic-ui-react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

export default class ColorPicker extends Component {
  state = {
    displayColorPicker: false,
  }
  handleChange = (e, { value }) => this.setState({ value })

  handleColorClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleColorClose = () => {
    this.setState({ displayColorPicker: false })
  };

  render() {
    const { field, handleChange, handleChangeColorComplete, companyObj } = this.props
    const { displayColorPicker } = this.state
    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          // background: `rgba(${companyObj.color.r}, ${companyObj.color.g}, ${companyObj.color.b}, ${companyObj.color.a})`,
          background: (companyObj && companyObj[field]) || '',
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });
    return (<>
      <div style={styles.swatch} onClick={this.handleColorClick}>
        <div style={styles.color} />
      </div>
      {displayColorPicker ? <div style={styles.popover}>
        <div style={styles.cover} onClick={this.handleColorClose} />
        <SketchPicker color={companyObj[field]} myfield={field} onChange={(color, event) => handleChangeColorComplete(color, event, field)} />
      </div> : null}
      <Form.Input name={field} fluid value={(companyObj && companyObj[field]) || ''} onChange={handleChange} />
    </>)
  }
}

