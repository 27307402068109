// import Moment from 'moment';
const Moment = require('moment');
const { set0String } = require('./date');

export const getLatestYearWeek = (semesterYear, semester) => {
  const year = getLatestYear(semesterYear, semester);
  const week = getLatestWeek(semesterYear, semester);
  const yearWeek = getCorrectYearWeekFormat(year, week);

  return (yearWeek);
};

export const getLatestYear = (semesterYear, semester) => {
  const today = new Date();
  const firstDayOfSemester = getFirstDayOfSemester(semesterYear, semester);
  const lastDayOfSemester = getLastDayOfSemester(semesterYear, semester);

  // 如果今天小於該学期第一天，則設定為該学期第一天所在的年
  // 如果今天大於該学期最後一天，則設定為該学期最後一天所在的年
  // 否則設定為今天所在的年
  if (today < firstDayOfSemester) {
    return firstDayOfSemester.getFullYear();
  }
  if (today > lastDayOfSemester) {
    return lastDayOfSemester.getFullYear();
  }
  return today.getFullYear();
};

export const getLatestWeek = (semesterYear, semester) => {
  const today = new Date();
  const firstDayOfSemester = getFirstDayOfSemester(semesterYear, semester);
  const lastDayOfSemester = getLastDayOfSemester(semesterYear, semester);

  // 如果今天小於該学期第一天，則設定為該学期第一天所在的週
  // 如果今天大於該学期最後一天，則設定為該学期最後一天所在的週
  // 否則設定為今天所在的週
  if (today < firstDayOfSemester) {
    return getWeekNumber(firstDayOfSemester)[1];
  }
  if (today > lastDayOfSemester) {
    return getWeekNumber(lastDayOfSemester)[1];
  }
  return getWeekNumber(today)[1];
};

// 找出該学期上限的週
// getMaxWeek = (semesterYear, semester) => {
//   let year, weekNo;

//   // 找出該学期結束於哪一週，有兩種情況
//   // 如果是S学期，即為找出當年9/30在哪一週，以那週當作上限
//   // 如果是A学期，即為找出隔年3/31在哪一週，以那週當作上限
//   if (semester === 'S') {
//     year = semesterYear;
//     // 9/30
//     weekNo = getWeekNumber(new Date(year, 8, 30))[1];
//   } else {
//     year = Number(semesterYear) + 1;
//     // 3/31
//     weekNo = getWeekNumber(new Date(year, 2, 31))[1];
//   }

//   return `${year}-W${weekNo}`;
// }

// 找出該学期結束於哪一週，有兩種情況
// 如果是S学期，即為找出當年9/30在哪一週，以那週當作上限
// 如果是A学期，即為找出隔年3/31在哪一週，以那週當作上限
export const getMaxWeek = (semesterYear, semester) => {
  const lastDayOfSemester = getLastDayOfSemester(semesterYear, semester);
  const year = lastDayOfSemester.getFullYear();
  const week = getWeekNumber(lastDayOfSemester)[1];

  return `${year}-W${week}`;
};

// 找出該学期下限的週
// getMinWeek = (semesterYear, semester) => {
//   let year, weekNo;

//   // 找出該学期開始於哪一週，有兩種情況
//   // 如果是S学期，即為找出當年4/1在哪一週，以那週當作下限
//   // 如果是A学期，即為找出當年10/1在哪一週，以那週當作下限
//   if (semester === 'S') {
//     year = semesterYear;
//     // 4/1
//     weekNo = getWeekNumber(new Date(year, 3))[1];
//   } else {
//     year = semesterYear;
//     // 10/1
//     weekNo = getWeekNumber(new Date(year, 9))[1];
//   }

//   return `${year}-W${weekNo}`;
// }

// 找出該学期下限的週
// 如果是S学期，即為找出當年4/1在哪一週，以那週當作下限
// 如果是A学期，即為找出當年10/1在哪一週，以那週當作下限
export const getMinWeek = (semesterYear, semester) => {
  const firstDayOfSemester = getFirstDayOfSemester(semesterYear, semester);
  const year = firstDayOfSemester.getFullYear();
  const week = getWeekNumber(firstDayOfSemester)[1];

  return `${year}-W${week}`;
};

// 計算在第幾週
export const getWeekNumber = (d) => {
  // Copy date so don't modify original
  d = new Date(+d);
  d.setHours(0, 0, 0);
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  // Get first day of year
  const yearStart = new Date(d.getFullYear(), 0, 1);
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  // Return array of year and week number
  return [d.getFullYear(), weekNo];
};

export const getYearWeek = (d = new Date()) => {
  // Copy date so don't modify original
  d = new Date(+d);
  d.setHours(0, 0, 0);
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  // Get first day of year
  const yearStart = new Date(d.getFullYear(), 0, 1);
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  // Return array of year and week number
  return `${d.getFullYear()}-W${set0String(weekNo)}`;
};

// 一年有幾週
export const weeksInYear = (year) => {
  const d = new Date(year, 11, 31);
  const week = getWeekNumber(d)[1];
  return week === 1 ? getWeekNumber(d.setDate(24))[1] : week;
};

export const getFirstDayOfSemester = (semesterYear, semester) => {
  if (semester === 'S') {
    // 4/1
    return (new Date(semesterYear, 3));
  }
  // 10/1
  return (new Date(semesterYear, 9));
};

export const getLastDayOfSemester = (semesterYear, semester) => {
  if (semester === 'S') {
    // 9/30
    return (new Date(semesterYear, 8, 30));
  }
  // 3/31
  return (new Date(Number(semesterYear) + 1, 2, 31));
};

export const getCorrectYearWeekFormat = (year, week) => {
  // case1: 2021-W09
  if (Number(week) < 10) {
    return (`${year}-W0${week}`);
    // case2: 2021-W50
  }
  return (`${year}-W${week}`);
};

export const getPrevYearWeek = (yearWeek, minWeek) => {
  // 2017-W10 => 2019, W10
  const [year, Wweek] = yearWeek.split('-');
  // W10 => 10
  const week = Wweek.slice(1);
  // check week number of previous year is 52 or 53
  const weekNum = weeksInYear(Number(year) - 1);
  let prevYear; let prevWeek; let
    prevYearWeek;
  // 已經到下限週
  if (yearWeek === minWeek) {
    return;
  }

  // case1: 2021-W01 => 2020-W53
  if (Number(week) === 1) {
    prevYear = Number(year) - 1;
    prevWeek = weekNum;
    prevYearWeek = `${Number(year) - 1}-W${weekNum}`;
    // case2: 2017-W10 => 2017-W09
  } else if (Number(week) <= 10) {
    prevYear = Number(year);
    prevWeek = Number(week) - 1;
    prevYearWeek = `${Number(year)}-W0${Number(week) - 1}`;
    // case3: 2017-W32 => 2017-W31
  } else {
    prevYear = Number(year);
    prevWeek = Number(week) - 1;
    prevYearWeek = `${Number(year)}-W${Number(week) - 1}`;
  }

  return { prevYear, prevWeek, prevYearWeek };
};

export const getNextYearWeek = (yearWeek, maxWeek) => {
  // 2017-W10 => 2019, W10
  const [year, Wweek] = yearWeek.split('-');
  // W10 => 10
  const week = Wweek.slice(1);
  // check week number of this year is 52 or 53
  const weekNum = weeksInYear(year);
  let nextYear; let nextWeek; let
    nextYearWeek;

  // 已經到上限週
  if (yearWeek === maxWeek) {
    return;
  }
  // case1: 2020-W53 => 2021-W01
  if (Number(week) === weekNum) {
    nextYear = Number(year) + 1;
    nextWeek = 1;
    nextYearWeek = `${Number(year) + 1}-W01`;
    // case2: 2017-W05 => 2017-W06
  } else if (Number(week) <= 8) {
    nextYear = Number(year);
    nextWeek = Number(week) + 1;
    nextYearWeek = `${Number(year)}-W0${Number(week) + 1}`;
    // case3: 2017-W32 => 2017-W33
  } else {
    nextYear = Number(year);
    nextWeek = Number(week) + 1;
    nextYearWeek = `${Number(year)}-W${Number(week) + 1}`;
  }

  return { nextYear, nextWeek, nextYearWeek };
};


/* 回傳包含入学至畢業的所有週的陣列，每個元素也是一個包含當週週一到週五日期的陣列
 * PS: 每個元素type都是date
 * ex:
 *  [
 *    [mon, tues, wed, thurs, fri], // 第一週
 *    [mon, tues, wed, thurs, fri], // 第二週
 *                ...
 *    [mon, tues, wed, thurs, fri], // 最後一週
 *  ]
 */
export const getWeekRangeArr = (doc) => {
  const weekRangeArr = [];
  const entryYear = Number(doc.semesterYear);
  const entryMonth = doc.semester === 'S' ? 4 : 10;
  // ex:
  //  entryYear: 2018, entryMonth: 4
  //  firstSchoolDay: 2018/4/1
  //  lastSchoolDay: 2020/3/31
  const firstSchoolDay = new Date(entryYear, entryMonth - 1);
  // console.log(firstSchoolDay, firstSchoolDay.getDay())
  if (firstSchoolDay.getDay() === 6) { // 如果第一天是禮拜六，初始日加兩天
    firstSchoolDay.setDate(firstSchoolDay.getDate() + 2);
  } else if (firstSchoolDay.getDay() === 0) { // 如果第一天是禮拜日，初始日加一天
    firstSchoolDay.setDate(firstSchoolDay.getDate() + 1);
  }
  const lastSchoolDay = new Date(entryYear + 2, 2, 31);
  const firstSchoolWeek = getWeekNumber(firstSchoolDay)[1];
  // 入学到畢業包含的週数
  const weeksIncluded = getWeeksIncluded(firstSchoolDay, lastSchoolDay);
  // 入学第一天所在的週的週一日期
  const initWeekStartDate = getDateOfISOWeek(entryYear, firstSchoolWeek, 0);
  // console.log(initWeekStartDate)
  for (let i = 0; i < weeksIncluded; i++) {
    const weekStartDate = new Date(initWeekStartDate);
    weekStartDate.setDate(weekStartDate.getDate() + 7 * i);
    weekRangeArr.push(getWeekdayRangeArr(weekStartDate));
  }

  return weekRangeArr;
};

export const getWeekdayRangeArr = (weekStartDate) => {
  // construct date range from monday to friday of specific week
  const weekdayRangeArr = [1, 2, 3, 4, 5].map((item, index) => {
    const date = new Date(weekStartDate);
    date.setDate(date.getDate() + index);

    return Moment(date).format('YYYY-MM-DD');
  });

  return weekdayRangeArr;
};

export const getWeekdayRangeArrDate = (weekStartDate) => {
  // construct date range from monday to friday of specific week
  const weekdayRangeArr = [1, 2, 3, 4, 5].map((item, index) => {
    const date = new Date(weekStartDate);
    date.setDate(date.getDate() + index);

    return date;
  });

  return weekdayRangeArr;
};


// 計算兩個日期間總共包含幾週
export const getWeeksIncluded = (date1, date2) => {
  // 回傳值，紀錄兩日期包含幾週
  let weeksIncluded = 0;
  // 紀錄兩日期誰較早或較晚
  let earlyDate; let
    lateDate;
  // 紀錄兩日期在第幾週
  let week1; let
    week2;
  // 紀錄兩日期差幾年
  let yearDiff;

  // 兩個日期一樣，則包含一週
  if (date1 === date2) {
    weeksIncluded = 1;

    // 日期不一樣
  } else {
    // 判斷日期先後
    if (date1 < date2) {
      earlyDate = new Date(date1);
      lateDate = new Date(date2);
    } else {
      earlyDate = new Date(date2);
      lateDate = new Date(date1);
    }

    week1 = getWeekNumber(earlyDate)[1];
    week2 = getWeekNumber(lateDate)[1];

    // 兩日期所在年份一樣
    if (earlyDate.getFullYear() === lateDate.getFullYear()) {
      weeksIncluded = week2 - week1 + 1;

      // 兩日期所在年份不一樣
    } else {
      yearDiff = lateDate.getFullYear() - earlyDate.getFullYear();

      for (let i = 0; i <= yearDiff; i++) {
        // 第一年
        if (i === 0) {
          weeksIncluded += (weeksInYear(earlyDate.getFullYear()) - week1 + 1);
          // 最後一年
        } else if (i === yearDiff) {
          weeksIncluded += week2;
          // 中間經過年份
        } else {
          weeksIncluded += weeksInYear(earlyDate.getFullYear() + i);
        }
      }
    }
  }

  return weeksIncluded;
};

export const getDateOfISOWeek = (y, w, d = 0) => {
  const simple = new Date(y, 0, 1 + (w - 1) * 7);
  const dow = simple.getDay();
  const ISOweekStart = simple;
  if (dow <= 4) { ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1); } else { ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay()); }

  ISOweekStart.setDate(ISOweekStart.getDate() + d);

  return ISOweekStart;
};


// 日数計
export const getDaysCount = (statusArr, dateRangeArr, periodArr, calendar = [], startDate, minus, loading = 0) => {
  // console.log(statusArr, dateRangeArr, periodArr, calendar);
  let totalDays = minus !== 0 ? dateRangeArr.length - minus : dateRangeArr.length;
  // let totalDays = dateRangeArr.length;
  let countDays = 0;

  if (!loading) { // 更換頁面比較不卡
    for (let i = 0; i < dateRangeArr.length; i++) {
      // 應到
      if (calendar.find(item => item.eventDate === dateRangeArr[i] && new Date(item.eventDate) > new Date(startDate) && item.status === '3')) {
        // console.log('success');
        totalDays--;
      }
    }

    // 都沒記錄等於沒出席
    if (statusArr.length === 0) {
      return `0/${totalDays}`;
    }

    for (let i = 0; i < dateRangeArr.length; i++) {
      // 實到
      for (let j = 0; j < periodArr.length; j++) {
        if (
          // 如果 一天中 有某時段有紀錄 且 該紀錄不是缺席種類 當天便算出席日(即便四節課只出席一節也算)
          statusArr.find(item => item.eventDate === dateRangeArr[i]
            && item.period === (`${periodArr[j]}`)
            && item.attendStatus
            && (item.attendStatus !== '5' && item.attendStatus !== '6' && item.attendStatus !== '7' && item.attendStatus !== '8' && item.attendStatus !== '-1'))
        ) {
          countDays++;
          break;
        }
      }
    }
  }

  return `${countDays}/${totalDays}`;
};

// 時間計
export const getHoursCount = (statusArr, dateRangeArr, periodArr, calendar = [], startDate, minus, loading) => {
  let totalHours = minus !== 0 ? (dateRangeArr.length - minus) * periodArr.length : (dateRangeArr.length) * periodArr.length;
  let countHours = 0;

  if (!loading) { // 更換頁面比較不卡
    for (let i = 0; i < dateRangeArr.length; i++) {
      // 應到
      if (calendar.find(item => item.eventDate === dateRangeArr[i] && new Date(item.eventDate) > new Date(startDate) && item.status === '3')) {
        // console.log('success');
        totalHours -= periodArr.length;
      }
    }
    // 都沒記錄等於沒出席
    if (statusArr.length === 0) {
      return `0/${totalHours}`;
    }

    for (let i = 0; i < dateRangeArr.length; i++) {
      for (let j = 0; j < periodArr.length; j++) {
        if (
          // 如果 有某時段有紀錄 且 該紀錄不是缺席種類 該時段便算出席
          statusArr.find(item => item.eventDate === dateRangeArr[i]
            && item.period === (`${periodArr[j]}`)
            && item.attendStatus
            && (item.attendStatus !== '5' && item.attendStatus !== '6' && item.attendStatus !== '7' && item.attendStatus !== '8' && item.attendStatus !== '-1'))
        ) {
          countHours++;
          continue;
        }
      }
    }
  }

  return `${countHours}/${totalHours}`;
};