import React, { Component } from 'react';
import { Table, Container, Form, Grid, Button, Checkbox, Confirm, Header, TextArea, Radio, Icon } from 'semantic-ui-react';
import Moment from 'moment';
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';
import { getSemesterAttendByWeekly, getAllAttendByWeekly } from './Table13';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import AttendanceCalculation from '../../../widgets/AttendanceCalculation';
import { getWeekNumber } from '../../../../widget/define/week';
import { set0String } from '../../../../widget/define/date';
import { showField, showFieldText, getAvailblePeriodArr, getOptionsSelByKey, renderFieldNoLabel, getOptionTextByValue, getOptionsByKey } from 'views/widgets/FieldsRender';
import Parse from 'widget/parse.js'

const flexCenter = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}

class NestedModal extends Component {
  state = { open: false }

  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  render() {
    const { arrGrade, grade, studentsInClass } = this.props;
    const { open } = this.state

    return (
      <Modal
        centered={true}
        open={open}
        onOpen={this.open}
        onClose={this.close}
        size='large'
        trigger={<Button fluid icon='list' />}
      >
        <ModalHeader>テストコード選択</ModalHeader>
        <ModalBody>
          <Table celled size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell rowSpan={2}>#</Table.HeaderCell>
                <Table.HeaderCell colSpan={2}>テスト</Table.HeaderCell>
                <Table.HeaderCell rowSpan={2}>クラス</Table.HeaderCell>
                <Table.HeaderCell rowSpan={2}>氏名</Table.HeaderCell>
                <Table.HeaderCell rowSpan={2}>学期</Table.HeaderCell>
                <Table.HeaderCell rowSpan={2}>素点</Table.HeaderCell>
                <Table.HeaderCell colSpan={5}>100点</Table.HeaderCell>
                <Table.HeaderCell rowSpan={2}>文字語彙</Table.HeaderCell>
                <Table.HeaderCell rowSpan={2}>聴解</Table.HeaderCell>
                <Table.HeaderCell rowSpan={2}>文法</Table.HeaderCell>
                <Table.HeaderCell rowSpan={2}>読解</Table.HeaderCell>
                <Table.HeaderCell colSpan={2}>得点</Table.HeaderCell>
                <Table.HeaderCell colSpan={2}>評価</Table.HeaderCell>
                <Table.HeaderCell rowSpan={2}>係数</Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>コード</Table.HeaderCell>
                <Table.HeaderCell>レベル</Table.HeaderCell>
                <Table.HeaderCell>文字語彙</Table.HeaderCell>
                <Table.HeaderCell>聴解</Table.HeaderCell>
                <Table.HeaderCell>文法</Table.HeaderCell>
                <Table.HeaderCell>読解</Table.HeaderCell>
                <Table.HeaderCell>文法読解計</Table.HeaderCell>
                <Table.HeaderCell>作文</Table.HeaderCell>
                <Table.HeaderCell>会話</Table.HeaderCell>
                <Table.HeaderCell>作文</Table.HeaderCell>
                <Table.HeaderCell>会話</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {arrGrade && arrGrade.sort((a, b) => ('' + a.testCode).localeCompare(b.testCode)).map((item, index) => {
                const gradeObj = grade.find(item1 => item1.objectId === item.gradeId);
                if (!gradeObj) {
                  return null;
                }
                return (<Table.Row key={`td${index}`}>
                  <Table.Cell style={{ padding: 10 }}>
                    <Button primary size='small'
                      onClick={() => this.setState({ open: false }, () => { this.props.handleClickGrade(this.props.field, gradeObj.testCode) })}
                      icon='tag' />
                  </Table.Cell>
                  <Table.Cell singleLine>{gradeObj.testCode || ''}</Table.Cell>
                  <Table.Cell singleLine>{gradeObj.testLevel}</Table.Cell>
                  <Table.Cell>{item.newClass}</Table.Cell>
                  <Table.Cell>{item.jpnName}</Table.Cell>
                  {/* {console.log(`${getWeekNumber(new Date(gradeObj.testDate))[0]}-${getWeekNumber(new Date(gradeObj.testDate))[1]}`)} */}
                  <Table.Cell>{studentsInClass.find(att => att.yearweek === `${getWeekNumber(new Date(gradeObj.testDate))[0]}-${set0String(getWeekNumber(new Date(gradeObj.testDate))[1])}`) &&
                    studentsInClass.find(att => att.yearweek === `${getWeekNumber(new Date(gradeObj.testDate))[0]}-${set0String(getWeekNumber(new Date(gradeObj.testDate))[1])}`).semesterNum}</Table.Cell>

                  <Table.Cell>{item.resTotalScore}</Table.Cell>
                  <Table.Cell>{item.resVocabularyScore}</Table.Cell>
                  <Table.Cell>{item.resListeningScore}</Table.Cell>
                  <Table.Cell>{item.resGrammarScore}</Table.Cell>
                  <Table.Cell>{item.resReadingScore}</Table.Cell>
                  <Table.Cell>{item.resVocabularyScore}</Table.Cell>
                  <Table.Cell>{item.vocabularyScore}</Table.Cell>
                  <Table.Cell>{item.listeningScore}</Table.Cell>
                  <Table.Cell>{item.grammarScore}</Table.Cell>
                  <Table.Cell>{item.readingScore}</Table.Cell>
                  {/* <Table.Cell>{item.totalScore}</Table.Cell> */}
                  <Table.Cell>{item.writingScore}</Table.Cell>
                  <Table.Cell>{item.conversationScore}</Table.Cell>
                  <Table.Cell>{item.writingScore}</Table.Cell>
                  <Table.Cell>{item.conversationScore}</Table.Cell>
                  <Table.Cell>{item.gpaAvg}</Table.Cell>
                </Table.Row>)
              })}
              {!arrGrade.length ? <Table.Row><Table.Cell colSpan={17} textAlign='center' >(資料無し)</Table.Cell></Table.Row> : null}
            </Table.Body>
          </Table>
        </ModalBody>
        {/* <Modal.Actions>
          <Button icon='check' content='All Done' onClick={this.close} />
        </Modal.Actions> */}
      </Modal>
    )
  }
}

const PrintPage = (props) => {
  const { optSet, semesterYear, semester, doc, isEdit, studentsInClass, calendar,
    activeKey, form, arrGrade, tableData, handleChange } = props;
  const availablePeriodArr = getAvailblePeriodArr(doc.semesterYear, doc.semester);

  const arr1 = [];
  const arr2 = [];
  const arr3 = [];
  const arr5 = [];
  const avgD = [];
  const avgH = [];
  const arr4 = [getAllAttendByWeekly(studentsInClass)[0],
  getAllAttendByWeekly(studentsInClass)[2],
  getAllAttendByWeekly(studentsInClass)[3],
  getAllAttendByWeekly(studentsInClass)[1],
  getAllAttendByWeekly(studentsInClass)[4],
  getAllAttendByWeekly(studentsInClass)[5],];
  const semesterInRoman = doc.semester === "S" ? ['I', 'II', 'III', 'IV'] : ['I', 'II', 'III'];

  semesterInRoman.map(item => {
    arr1.push(getSemesterAttendByWeekly(studentsInClass, item)[0])
    arr2.push(getSemesterAttendByWeekly(studentsInClass, item)[2])
    arr3.push(getSemesterAttendByWeekly(studentsInClass, item)[3])
    arr5.push(getSemesterAttendByWeekly(studentsInClass, item)[1])
    avgD.push(getSemesterAttendByWeekly(studentsInClass, item)[4])
    avgH.push(getSemesterAttendByWeekly(studentsInClass, item)[5])
  })
  // if (availablePeriodArr.length) {
  //   availablePeriodArr.forEach((item, index) => {
  //     const startDate = getDate(item, 'startDate', false);
  //     const endDate = getDate(item, 'endDate', false);
  //     arr1.push(<AttendanceCalculation doc={doc} calendar={calendar} studentsInClass={studentsInClass} isStudent={true} option="expectedDays" startDate={startDate} endDate={endDate} />);
  //     arr2.push(<AttendanceCalculation doc={doc} calendar={calendar} studentsInClass={studentsInClass} isStudent={true} option="actualDays" startDate={startDate} endDate={endDate} />);
  //     arr3.push(<AttendanceCalculation doc={doc} calendar={calendar} studentsInClass={studentsInClass} isStudent={true} option="actualHours" startDate={startDate} endDate={endDate} />);

  //     arr4[0] = arr4[0] + (Number(arr1[index]) || 0);
  //     arr4[1] = arr4[1] + (Number(arr2[index]) || 0);
  //     arr4[2] = arr4[2] + (Number(arr3[index]) || 0);
  //   });
  // }
  const arr = doc.semester === 'S' ? ['Ⅰ', 'Ⅱ', 'Ⅲ', 'Ⅳ'] : ['Ⅰ', 'Ⅱ', 'Ⅲ'];
  // console.log(arrGrade, form, arrGrade.find(item => item.testCode === form.testcode0))

  return <>
    <Grid.Row style={flexCenter}>
      <span style={{ width: '110px' }}></span>
      <h3 style={{ margin: 0, textAlign: 'center' }}>{activeKey === "1" || activeKey === "2" ? "成績" : null}{activeKey === "1" ? " · " : null}{activeKey === "1" || activeKey === "3" ? "出席" : null}証明書</h3>
      <h5 style={{ margin: 0 }}>NO. _______________</h5>
    </Grid.Row>
    <Grid style={{ marginTop: '20px', ...flexCenter }}>
      <h4 style={{ margin: 0 }}>学籍番号：{doc.studentId}</h4>
      <h4 style={{ margin: 0 }}>氏名：{doc.jpnName}
        {/* ({doc.genderText}) */}
      </h4>
      <span style={{ width: '110px' }}></span>
      {/* <Grid.Column md={4}> */}
      <h4 style={{ float: 'right' }}>( 男  /  女 )</h4>
      {/* <h4 style={{ float: 'right' }}></h4> */}
      {/* </Grid.Column> */}
    </Grid>
    <Grid style={{ display: 'flex', justifyContent: 'space-between', marginTop: 30, marginBottom: 20 }}>
      <div>
        <h5 style={{ margin: 0 }}>国籍：{doc.nationalityText}</h5>
        {/* <Grid.Column><p>日本語科1年半コース / 日本語2年コース  (   　　　年　　　月 卒業 / 卒業見達 / 修了 / 中途退学 )/ 聴講生</p></Grid.Column> */}
        <div>日本語科 {doc.semester === "S" ? "2年" : "1年半"} コース  ( {Number(doc.semesterYear) + 2} 年 3 月 卒業 / 卒業見達 / 修了 / 中途退学 ) / 聴講生 )
         {/* / 聴講生 */}
        </div>
        <div>上記の者の{activeKey !== '3' ? '学業成績(日本語能力)' : '出席率'}は下記のとおりです</div>
      </div>
      <h5 style={{ margin: 0 }}>{Moment(doc.birthday).format('YYYY年MM月DD日')} 生</h5>
    </Grid>
    {activeKey === '1' || activeKey === '2' ?
      <Table celled style={{ textAlign: 'center', marginBottom: '0' }} size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ width: "14%" }} rowSpan='2'>科目</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "14%" }}>学期</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "18%" }}>1期</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "18%" }}>2期</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "18%" }}>3期</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "18%" }}>{doc.semester === "S" ? "4期" : null}</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            {/* <Table.HeaderCell>#</Table.HeaderCell> */}
            <Table.HeaderCell style={{ borderLeft: '1px solid rgba(34,36,38,.1)' }}>※1 レべル</Table.HeaderCell>
            {arr.map((data, i) => <Table.HeaderCell key={'ptc' + i}>{form['testlevel' + i + 'Text']}</Table.HeaderCell>)}
            {doc.semester === "A" ? <Table.HeaderCell>&nbsp;</Table.HeaderCell> : null}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan='2'>文字·語彙</Table.Cell>
            {/* {arr.map((data, i) => <Table.Cell key={'ptc' + i}>{'testlevel' + i + 'Text'}</Table.Cell>)} */}
            <Table.Cell>{form.testcode0 && arrGrade.find(item => item.testCode === form.testcode0) && arrGrade.find(item => item.testCode === form.testcode0).resVocabularyScore}</Table.Cell>
            <Table.Cell>{form.testcode1 && arrGrade.find(item => item.testCode === form.testcode1) && arrGrade.find(item => item.testCode === form.testcode1).resVocabularyScore}</Table.Cell>
            <Table.Cell>{form.testcode2 && arrGrade.find(item => item.testCode === form.testcode2) && arrGrade.find(item => item.testCode === form.testcode2).resVocabularyScore}</Table.Cell>
            <Table.Cell>{doc.semester === "S" ? (form.testcode2 && arrGrade.find(item => item.testCode === form.testcode3) && arrGrade.find(item => item.testCode === form.testcode3).resVocabularyScore) : null}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan='2'>聴解</Table.Cell>
            <Table.Cell>{form.testcode0 && arrGrade.find(item => item.testCode === form.testcode0) && arrGrade.find(item => item.testCode === form.testcode0).resListeningScore}</Table.Cell>
            <Table.Cell>{form.testcode1 && arrGrade.find(item => item.testCode === form.testcode1) && arrGrade.find(item => item.testCode === form.testcode1).resListeningScore}</Table.Cell>
            <Table.Cell>{form.testcode2 && arrGrade.find(item => item.testCode === form.testcode2) && arrGrade.find(item => item.testCode === form.testcode2).resListeningScore}</Table.Cell>
            <Table.Cell>{doc.semester === "S" ? (form.testcode2 && arrGrade.find(item => item.testCode === form.testcode3) && arrGrade.find(item => item.testCode === form.testcode3).resListeningScore) : null}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan='2'>文法</Table.Cell>
            <Table.Cell>{form.testcode0 && arrGrade.find(item => item.testCode === form.testcode0) && arrGrade.find(item => item.testCode === form.testcode0).resGrammarScore}</Table.Cell>
            <Table.Cell>{form.testcode1 && arrGrade.find(item => item.testCode === form.testcode1) && arrGrade.find(item => item.testCode === form.testcode1).resGrammarScore}</Table.Cell>
            <Table.Cell>{form.testcode2 && arrGrade.find(item => item.testCode === form.testcode2) && arrGrade.find(item => item.testCode === form.testcode2).resGrammarScore}</Table.Cell>
            <Table.Cell>{doc.semester === "S" ? (form.testcode2 && arrGrade.find(item => item.testCode === form.testcode3) && arrGrade.find(item => item.testCode === form.testcode3).resGrammarScore) : null}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan='2'>読解</Table.Cell>
            <Table.Cell>{form.testcode0 && arrGrade.find(item => item.testCode === form.testcode0) && arrGrade.find(item => item.testCode === form.testcode0).resReadingScore}</Table.Cell>
            <Table.Cell>{form.testcode1 && arrGrade.find(item => item.testCode === form.testcode1) && arrGrade.find(item => item.testCode === form.testcode1).resReadingScore}</Table.Cell>
            <Table.Cell>{form.testcode2 && arrGrade.find(item => item.testCode === form.testcode2) && arrGrade.find(item => item.testCode === form.testcode2).resReadingScore}</Table.Cell>
            <Table.Cell>{doc.semester === "S" ? (form.testcode2 && arrGrade.find(item => item.testCode === form.testcode3) && arrGrade.find(item => item.testCode === form.testcode3).resReadingScore) : null}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan='2'>作文</Table.Cell>
            <Table.Cell>{form.writecode0 && arrGrade.find(item => item.testCode === form.writecode0) && arrGrade.find(item => item.testCode === form.writecode0).writingScore}</Table.Cell>
            <Table.Cell>{form.writecode1 && arrGrade.find(item => item.testCode === form.writecode1) && arrGrade.find(item => item.testCode === form.writecode1).writingScore}</Table.Cell>
            <Table.Cell>{form.writecode2 && arrGrade.find(item => item.testCode === form.writecode2) && arrGrade.find(item => item.testCode === form.writecode2).writingScore}</Table.Cell>
            <Table.Cell>{doc.semester === "S" ? (form.writecode3 && arrGrade.find(item => item.testCode === form.writecode3) && arrGrade.find(item => item.testCode === form.writecode3).writingScore) : null}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan='2'>会話</Table.Cell>
            <Table.Cell>{form.writecode0 && arrGrade.find(item => item.testCode === form.writecode0) && arrGrade.find(item => item.testCode === form.writecode0).conversationScore}</Table.Cell>
            <Table.Cell>{form.writecode1 && arrGrade.find(item => item.testCode === form.writecode1) && arrGrade.find(item => item.testCode === form.writecode1).conversationScore}</Table.Cell>
            <Table.Cell>{form.writecode2 && arrGrade.find(item => item.testCode === form.writecode2) && arrGrade.find(item => item.testCode === form.writecode2).conversationScore}</Table.Cell>
            <Table.Cell>{doc.semester === "S" ? (form.writecode3 && arrGrade.find(item => item.testCode === form.writecode3) && arrGrade.find(item => item.testCode === form.writecode3).conversationScore) : null}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan='6' style={{ textAlign: 'left' }}>
              {/* <br /> */}
              <div>① 評点は100を満点とします。</div>
              <div>② 作文 • 会話の評価は以下のとおりです。</div>
              <div>   A:特に優れている　B:優れている　C:普通である　D:やや劣る　E:劣る</div>
              <div>③ 学期は半年を1期とします。</div>
              <div>   4月入学生は2年コースの計4期、10月入学生は1年半コースの計3期です。</div>
              <div>④ 筆記試験は年4回、作文会話試験は年2回の実施です。</div>
              <div>＊1　レベルとは、どのレベルのテストを試験したかを表すものです。</div>
              <div>受験テストのレベルは、初級前期/初期後期/初中級/中級/上級/専修の6つです。</div>
            </Table.Cell>
          </Table.Row>
          {activeKey === '2' ? <Table.Row>
            <Table.Cell colSpan='6' style={{ textAlign: 'left', height: '100px' }}>備考
              {showField(props, "certificatePs2", 0)}
            </Table.Cell>
          </Table.Row> : null}
        </Table.Body>
      </Table> : null}
    {activeKey === "1" || activeKey === "3" ? <Table style={{ textAlign: 'center' }} size="small">
      <Table.Body>
        <Table.Row>
          <Table.Cell style={{ width: "25%" }}>&nbsp;</Table.Cell>
          <Table.Cell style={{ width: "15%" }}>1期</Table.Cell>
          <Table.Cell style={{ width: "15%" }}>2期</Table.Cell>
          <Table.Cell style={{ width: "15%" }}>3期</Table.Cell>
          <Table.Cell style={{ width: "15%" }}>{doc.semester === "S" ? "4期" : ""}</Table.Cell>
          <Table.Cell style={{ width: "15%" }}>合計</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>授業日数</Table.Cell>
          <Table.Cell>{arr1[0] || "0"}日</Table.Cell>
          <Table.Cell>{arr1[1] || "0"}日</Table.Cell>
          <Table.Cell>{arr1[2] || "0"}日</Table.Cell>
          <Table.Cell>{doc.semester === "S" ? (arr1[3] || "0") + "日" : ""}</Table.Cell>
          <Table.Cell>{arr4[0]}日</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>出席日数</Table.Cell>
          <Table.Cell>{arr2[0] || "0"}日</Table.Cell>
          <Table.Cell>{arr2[1] || "0"}日</Table.Cell>
          <Table.Cell>{arr2[2] || "0"}日</Table.Cell>
          <Table.Cell>{doc.semester === "S" ? (arr2[3] || "0") + "日" : ""}</Table.Cell>
          <Table.Cell>{arr4[1]}日</Table.Cell>
        </Table.Row>
        {/* <Table.Row>
          <Table.Cell>授業時間数</Table.Cell>
          <Table.Cell>{arr5[0] || "0"}時間</Table.Cell>
          <Table.Cell>{arr5[1] || "0"}時間</Table.Cell>
          <Table.Cell>{arr5[2] || "0"}時間</Table.Cell>
          <Table.Cell>{doc.semester === "S" ? (arr5[3] || "0") + "時間" : ""}</Table.Cell>
          <Table.Cell>{arr4[3]}時間</Table.Cell>
        </Table.Row> */}
        <Table.Row>
          <Table.Cell>出席時間数</Table.Cell>
          <Table.Cell>{arr3[0] || "0"}時間</Table.Cell>
          <Table.Cell>{arr3[1] || "0"}時間</Table.Cell>
          <Table.Cell>{arr3[2] || "0"}時間</Table.Cell>
          <Table.Cell>{doc.semester === "S" ? (arr3[3] || "0") + "時間" : ""}</Table.Cell>
          <Table.Cell>{arr4[2]}時間</Table.Cell>
        </Table.Row>
        {/* <Table.Row>
          <Table.Cell>日出席率</Table.Cell>
          <Table.Cell>{avgD[0] || "--"}％</Table.Cell>
          <Table.Cell>{avgD[1] || "--"}％</Table.Cell>
          <Table.Cell>{avgD[2] || "--"}％</Table.Cell>
          <Table.Cell>{doc.semester === "S" ? (avgD[3] || "0") + "％" : ""}</Table.Cell>
          <Table.Cell>{arr4[4]}％</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>時出席率</Table.Cell>
          <Table.Cell>{avgH[0] || "--"}％</Table.Cell>
          <Table.Cell>{avgH[1] || "--"}％</Table.Cell>
          <Table.Cell>{avgH[2] || "--"}％</Table.Cell>
          <Table.Cell>{doc.semester === "S" ? (avgH[3] || "0") + "％" : ""}</Table.Cell>
          <Table.Cell>{arr4[5]}％</Table.Cell>
        </Table.Row> */}
        <Table.Row>
          <Table.Cell colSpan='6' style={{ textAlign: 'left', height: '100px' }}>備考：<br />
          通算出席率(日数)は{arr4[4]}%、通算出席率(時間)は{arr4[5]}%です<br /><br />
            <Form.Group>
              <Form.Checkbox type="checkbox" lable='上記の出席は' onChange={handleChange} name="isWant" checked={tableData.isWant} />
            上記の出席は<Form.Input disabled={!tableData.isWant} width='1' onChange={handleChange} name="year" defaultValue={tableData.year} />年
            <Form.Input disabled={!tableData.isWant} width='1' onChange={handleChange} name="month" defaultValue={tableData.month} />月
            <Form.Input disabled={!tableData.isWant} width='1' onChange={handleChange} name="day" defaultValue={tableData.day} />日までのものです
            </Form.Group>
            {form.ps}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
      : null}
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Grid.Column>
        <p>名古屋市中区新栄1-30-29<br />愛知国際学院<br />学院長 荘昌憲<br />記載責任者</p>
      </Grid.Column>
    </div></>;
}

export default class TranscriptAndAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: '1',
      tableData: {
        isWant: false,
        year: '',
        month: '',
        day: ''
      },
      form: {},
      show: false,
      arrRow: [],
      arrGrade: [],
      confirm: false,
      loading: false,
      printPage: {}
    };
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    if (this.props.doc !== nextProps.doc) {
      this.handleFetch(nextProps);
      this.setState({ printPage: {} });
    }
  }

  handleFetch = async (props = this.props) => {
    const { match, grade, companyObj } = props
    const { _id } = match.params;
    if (_id) {
      // firebase.firestore().doc(`/Students/${_id}/Monthly/${Moment(new Date()).format('YYYY-MM')}`).get().then(snap => {
      //   if (snap.data())
      //     this.setState({ semesterNum: snap.data().semesterNum })
      //   // console.log(snap.data().semesterNum)
      // })
      // firebase.firestore()
      //   .collectionGroup('Weekly')
      //   .where('studentId', '==', _id)
      //   .get().then(snap => {
      //     const data = [];
      //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }))
      //     this.setState({ studentsInClass: data, printPage: {} })
      //   })
      const studentsInClass = await Parse.queryData('Weekly', { companyId: companyObj.objectId, studentId: _id });

      const arrRow = await Parse.queryData('Transcript', { companyId: companyObj.objectId, studentId: _id });

      const gradeArr = await Parse.queryData('Grade2', { companyId: companyObj.objectId, studentId: _id });
      const arrGrade = []
      gradeArr.forEach(item => arrGrade.push({
        ...item,
        testCode: (grade.find(item1 => item1.objectId === item.gradeId) && grade.find(item1 => item1.objectId === item.gradeId).testCode) || ''
      }));
      this.setState({ studentsInClass, arrRow, arrGrade })
      // firebase.firestore()
      //   .collection(`Students/${_id}/Transcript`)
      //   .get().then(snap => {
      //     const data = [];
      //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
      //     // console.log(data);
      //     this.setState({ arrRow: data });
      //   }, err => {
      //     console.log(`Encountered error: ${err}`);
      //   });
      // firebase.firestore()
      //   .collection(`Students/${_id}/Grade`)
      //   .get().then(snap => {
      //     const data = [];
      //     snap.forEach(item => data.push({
      //       _id: item.id, ...item.data(),
      //       testCode: (grade.find(item1 => item1._id === item.data().gradeId) && grade.find(item1 => item1._id === item.data().gradeId).testCode) || ''
      //     }));
      //     // console.log(data);
      //     this.setState({ arrGrade: data });
      //   }, err => {
      //     console.log(`Encountered error: ${err}`);
      //   });
    }
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    const { form, tableData } = this.state;
    if (name === 'isWant') {
      console.log(value)
      this.setState({ tableData: { ...tableData, [name]: !tableData.isWant } }, () => this.getPrintData());
    } else if (name === 'year' || name === 'month' || name === 'day') {
      this.setState({ tableData: { ...tableData, [name]: value } }, () => this.getPrintData());
    } else
      this.setState({ form: { ...form, [name]: value } });
  };

  handleClickGrade = (name, value) => {
    // const { name, value } = data;
    const { form } = this.state;
    this.setState({ form: { ...form, [name]: value } });
  };

  handleSubmit = async () => {
    const { companyObj, optSet, doc, semesterYear, semester } = this.props;
    const { form, arrRow } = this.state;
    // const db = firebase.firestore();

    this.setState({ loading: true });

    form.status3Text = getOptionTextByValue(optSet, 'studentStatus3', form.status3);
    form.testlevel0Text = form.testlevel0 && getOptionTextByValue(optSet, 'studentLevel', form.testlevel0);
    form.testlevel1Text = form.testlevel1 && getOptionTextByValue(optSet, 'studentLevel', form.testlevel1);
    form.testlevel2Text = form.testlevel2 && getOptionTextByValue(optSet, 'studentLevel', form.testlevel2);
    form.testlevel3Text = form.testlevel3 && getOptionTextByValue(optSet, 'studentLevel', form.testlevel3);
    // form.status3Text = getOptionTextByValue(optSet, 'studentLevel', form.status3);

    const newRows = [...arrRow];
    if (!form.objectId) {
      // const docRef = db.collection(`Students/${doc._id}/Transcript`).doc();
      const db = Parse.Object.extend("Transcript");
      const newDoc = new db();
      const docRef = await newDoc.save();
      const obj = {
        ...form,
        objectId: docRef.id,
        studentId: doc.objectId,
        studentIdKey: doc.studentId,
        studentName: doc.jpnName,
        semesterYear,
        semester,
        companyId: companyObj.objectId,
        companyName: companyObj.name,
        // createdAt: new Date()
      };

      for (const key in obj) {
        if (typeof obj[key] === 'undefined') {
          obj[key] = '';
        }
      }
      // console.log(obj)
      // await docRef.set(obj);
      await Parse.saveData('Transcript', obj)
      newRows.push(obj);
    } else {
      // await db.collection(`Students/${doc._id}/Transcript`).doc(form._id).update(form);
      Parse.saveData('Transcript', form)
      let index = newRows.findIndex((item => item.objectId === form.objectId));
      newRows[index] = form;
    }

    this.setState({ arrRow: newRows, loading: false, printPage: {} });
    this.handleClose();
  }

  handleOpen = () => {
    this.setState({ show: true })
  }

  handleEdit = (form) => {
    this.setState({ show: true, form: { ...form } });
  }

  handleClose = () => {
    this.setState({
      show: false,
      form: {},
    });
  }

  handleRemove = async () => {
    const { firebase, doc } = this.props
    const { form, arrRow } = this.state;
    // await firebase.firestore().collection("Students").doc(doc.objectId).collection("Transcript").doc(form.objectId).delete();
    await Parse.deleteData('Transcript', form)
    const newRows = [...arrRow];
    let index = newRows.findIndex((item => item.objectId === form.objectId));
    newRows.splice(index, 1);
    this.setState({ arrRow: newRows });

    this.handleClose();
    this.hideConfirm();
  }

  showConfirm = (bicycleObj) => this.setState({ confirm: true, form: { ...bicycleObj } });
  hideConfirm = () => this.setState({ confirm: false, form: {} });

  getPrintData = () => {
    const { doc } = this.props;
    const { arrGrade, arrRow, studentsInClass, tableData, printPage } = this.state;
    let obj = { resVocabularyScore: [], resListeningScore: [], resGrammarScore: [], resReadingScore: [], writingScore: [], conversationScore: [], testLevel: [] };
    for (let i = 0; i < 4; i++) {
      printPage && arrGrade.filter(item => item.testCode === printPage['testcode' + i])
        .map(item => {
          obj.resVocabularyScore.push(item.resVocabularyScore)
          obj.resListeningScore.push(item.resListeningScore)
          obj.resGrammarScore.push(item.resGrammarScore)
          obj.resReadingScore.push(item.resReadingScore)
          obj.testLevel.push(arrRow[0]['testlevel' + i + 'Text'])
        })
      printPage && arrGrade.filter(item => item.testCode === printPage['writecode' + i])
        .map(item => {
          obj.writingScore.push(item.writingScore)
          obj.conversationScore.push(item.conversationScore)
        })
    }

    const arr1 = [];
    const arr2 = [];
    const arr3 = [];
    const arr5 = [];
    const avgD = [];
    const avgH = [];
    const arr4 = [getAllAttendByWeekly(studentsInClass)[0],
    getAllAttendByWeekly(studentsInClass)[2],
    getAllAttendByWeekly(studentsInClass)[3],
    getAllAttendByWeekly(studentsInClass)[1],
    getAllAttendByWeekly(studentsInClass)[4],
    getAllAttendByWeekly(studentsInClass)[5],];
    const semesterInRoman = doc.semester === "S" ? ['I', 'II', 'III', 'IV'] : ['I', 'II', 'III'];

    semesterInRoman.map(item => {
      arr1.push(getSemesterAttendByWeekly(studentsInClass, item)[0])
      arr2.push(getSemesterAttendByWeekly(studentsInClass, item)[2])
      arr3.push(getSemesterAttendByWeekly(studentsInClass, item)[3])
      arr5.push(getSemesterAttendByWeekly(studentsInClass, item)[1])
      avgD.push(getSemesterAttendByWeekly(studentsInClass, item)[4])
      avgH.push(getSemesterAttendByWeekly(studentsInClass, item)[5])
    })
    this.setState({ params: { ...doc, ...obj, arr1, arr2, arr3, arr4, arr5, avgD, avgH, tableData, ps: printPage.ps } })
    // console.log(this.state.params)
  }

  render() {
    const { optSet, semesterYear, semester, doc, isEdit, calendar, companyObj } = this.props;
    const { activeKey, arrRow, show, form, loading, printPage, arrGrade, params, studentsInClass, tableData } = this.state;

    const status3 = getOptionsSelByKey(optSet, "studentStatus3");
    const studentLevel = getOptionsSelByKey(optSet, "studentLevel").concat([{ key: 'aa', text: '(無)', value: '' }]);
    // console.log(status3);
    const arr = doc.semester === 'S' ? ['Ⅰ', 'Ⅱ', 'Ⅲ', 'Ⅳ'] : ['Ⅰ', 'Ⅱ', 'Ⅲ'];
    console.log(printPage)
    return (<>
      <div className='no-print'>
        <Header as='h2' dividing
          content='成績 · 出席証明書'
          style={{ backgroundColor: 'lavender' }}
        // subheader='調査時間：2017年4月から 2018年12月 まで'
        />
        <Form.Group>
          <Form.Button
            content='追加'
            onClick={this.handleOpen} icon='add'
            color='blue'
            disabled={!isEdit}
            size='small'
          />
        </Form.Group>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>学籍<br />番号</Table.HeaderCell>
              <Table.HeaderCell>氏名</Table.HeaderCell>
              <Table.HeaderCell>進路区分</Table.HeaderCell>
              <Table.HeaderCell>予定日</Table.HeaderCell>
              {arr.map((item, index) => <Table.HeaderCell key={'a' + index}>第{item}期<br />テスト<br />コード</Table.HeaderCell>)}
              {arr.map((item, index) => <Table.HeaderCell key={'b' + index}>第{item}期<br />作文会話<br />テスト<br />コード</Table.HeaderCell>)}
              {arr.map((item, index) => <Table.HeaderCell key={'c' + index}>第{item}期<br />テスト<br />レベル</Table.HeaderCell>)}
              <Table.HeaderCell>備考</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {arrRow.map((item, index) => (<Table.Row key={`td${index}`}>
              <Table.Cell style={{ padding: 5 }}>
                <Button primary size='small' onClick={() => this.setState({ printPage: item }, () => this.getPrintData())} icon='file alternate' />
                {isEdit ? <>
                  <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} icon='edit' />
                  <Button inverted={companyObj.inverted} color='red' size='small' onClick={() => this.showConfirm(item)} icon='delete' />
                </> : null}
              </Table.Cell>
              <Table.Cell>{item.studentIdKey}</Table.Cell>
              <Table.Cell>{item.studentName}</Table.Cell>
              <Table.Cell>{item.status3Text}</Table.Cell>
              <Table.Cell>{item.planDate && Moment(item.planDate).format('YYYY/MM/DD')}</Table.Cell>
              {arr.map((data, i) => <Table.Cell key={'tc' + index + i}>{item['testcode' + i]}</Table.Cell>)}
              {arr.map((data, i) => <Table.Cell key={'wc' + index + i}>{item['writecode' + i]}</Table.Cell>)}
              {arr.map((data, i) => <Table.Cell key={'tl' + index + i}>{item['testlevel' + i + 'Text']}</Table.Cell>)}
              <Table.Cell>{item.ps}</Table.Cell>
            </Table.Row>))}
            {!arrRow.length ? <Table.Row><Table.Cell colSpan={17} textAlign='center' >(資料無し)</Table.Cell></Table.Row> : null}
          </Table.Body>
        </Table>
        {/* <br /> */}
        {/* <br /> */}
      </div>
      {printPage && printPage.objectId ? <>
        <Form.Group style={{ float: 'right' }}>
          <Form.Select label='類別' placeholder='類別' value={activeKey} onChange={(e, { value }) => this.setState({ activeKey: value })}
            options={[
              { key: '1', value: '1', text: '成績·出席証明書' },
              { key: '2', value: '2', text: '成績証明書' },
              { key: '3', value: '3', text: '出席証明書' },
            ]} />
          {activeKey !== '1' ? <ReactToPrint
            trigger={(e) => <Form.Button
              content='印刷'
              label='&nbsp;'
              color='orange' icon='print'
              size='small'
            />}
            content={() => this.componentRef}
            pageStyle={"@page { size: auto;  margin: 10mm; }*{font-family: 'Noto Serif JP', serif;}"}
          /> : null}
          {activeKey === '1' ? <>
            <div>
              <label style={{ display: 'block', margin: '0 0 .28571429rem 0' }}>&nbsp;</label>
              <ModalPdf
                {...this.props}
                // ref={(ref) => { this.pdfModal = ref }}
                codekey="jpn552"
                params={params}
              />
            </div>
          </> : null}
        </Form.Group>
        <div style={{ clear: 'both' }}></div>
        <br />
        <div ref={el => (this.componentRef = el)} >
          <PrintPage {...this.props} activeKey={activeKey} form={printPage} arrGrade={arrGrade} studentsInClass={studentsInClass} tableData={tableData} handleChange={this.handleChange} />
        </div>
      </> : null}
      {/* <Modal open={show} onClose={this.handleClose} size='small' centered={false}> */}
      <Modal
        isOpen={show}
        toggle={this.handleClose}
        size="lg"
        className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader>
          <h2 style={{ margin: 0 }}> 成績·出席証明書 {form.objectId ? '修正' : '追加'}</h2>
          {/* <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon> */}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Grid columns='equal'>
              <Grid.Column ><Form.Input fluid label='学籍番号' disabled={true} readOnly={true} name="studentId" value={doc.studentId} /></Grid.Column>
              <Grid.Column ><Form.Input fluid label='氏名' disabled={true} readOnly={true} name="jpnName" value={doc.jpnName} /></Grid.Column>
              <Grid.Column ><Form.Select fluid
                label='進路区分'
                options={status3}
                name="status3" value={form.status3}
                onChange={this.handleChange} /></Grid.Column>
              <Grid.Column ><Form.Input fluid label='予定日' type="date" name="planDate" value={form.planDate} onChange={this.handleChange} /></Grid.Column>
            </Grid>
            <label>成績</label>
            <Table definition size='small'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4} />
                  <Table.HeaderCell width={3}>基本科目</Table.HeaderCell>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                  <Table.HeaderCell width={3}>作文•会話</Table.HeaderCell>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                  <Table.HeaderCell width={3}>レベル</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {arr.map((item, index) =>
                  <Table.Row key={'form' + index}>
                    <Table.Cell>第{item}期テストコード</Table.Cell>
                    <Table.Cell><Form.Input fluid name={'testcode' + index} value={form['testcode' + index]} onChange={this.handleChange} /></Table.Cell>
                    <Table.Cell><NestedModal {...this.props} arrGrade={arrGrade} studentsInClass={studentsInClass} field={'testcode' + index} handleClickGrade={this.handleClickGrade} /></Table.Cell>
                    <Table.Cell><Form.Input fluid name={'writecode' + index} value={form['writecode' + index]} onChange={this.handleChange} /></Table.Cell>
                    <Table.Cell><NestedModal {...this.props} arrGrade={arrGrade} studentsInClass={studentsInClass} field={'writecode' + index} handleClickGrade={this.handleClickGrade} /></Table.Cell>
                    <Table.Cell>
                      <Form.Select fluid
                        options={studentLevel}
                        name={'testlevel' + index} value={form['testlevel' + index]}
                        onChange={this.handleChange} />
                    </Table.Cell>
                  </Table.Row>)}
              </Table.Body>
            </Table>
            <Grid columns='equal'>
              <Grid.Column >
                <Form.Field control={TextArea} name="ps" value={form.ps} label='備考' onChange={this.handleChange} />
              </Grid.Column>
              <Grid.Column >
                <Form.Input fluid label='休学備考' name="suspendPs" value={form.suspendPs} onChange={this.handleChange} />
                <Form.Group inline>
                  <Form.Field
                    name="suspendPeriod"
                    control={Radio}
                    label='無'
                    value='0'
                    checked={form.suspendPeriod === '0'}
                    onChange={this.handleChange}
                  />
                  <Form.Field
                    name="suspendPeriod"
                    control={Radio}
                    label='1期後'
                    value='1'
                    checked={form.suspendPeriod === '1'}
                    onChange={this.handleChange}
                  />
                  <Form.Field
                    name="suspendPeriod"
                    control={Radio}
                    label='2期後'
                    value='2'
                    checked={form.suspendPeriod === '2'}
                    onChange={this.handleChange}
                  />
                  <Form.Field
                    name="suspendPeriod"
                    control={Radio}
                    label='3期後'
                    value='3'
                    checked={form.suspendPeriod === '3'}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Grid.Column>
            </Grid>
          </Form>
          <Grid>
            <Grid.Row>
              <Grid.Column style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button size='small' onClick={this.handleSubmit} inverted={companyObj.inverted} color='green' loading={loading} icon='checkmark' content={form.objectId ? '保存' : '追加'} />
                <Button size='small' onClick={this.handleClose} basic>閉じる</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={this.state.confirm}
        toggle={this.hideConfirm}
        size="lg"
        className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader>削除</ModalHeader>
        <ModalBody>
          削除してもいいですか？
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button onClick={this.handleRemove} inverted color='red' icon='delete' content='はい' />
          <Button onClick={this.hideConfirm} content='いいえ' />
        </ModalFooter>
      </Modal>
      {/* <Confirm
        header='削除'
        content='削除してもいいですか?'
        cancelButton='いいえ'
        confirmButton='はい'
        open={this.state.confirm}
        onCancel={this.hideConfirm}
        onConfirm={this.handleRemove}
        size='mini'
        centered={false} /> */}
    </>);
  }
}