import React, { Component } from 'react';
import { Form, Button, Modal, Table, Grid, Icon, Header, Confirm, TextArea } from 'semantic-ui-react';

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export default class List3Teacher extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      show: false,
      isReadOnly: false,
      form: {},
      loading: false,
      confirm: false,
      unsub: null,
      inquiry: [],
      readOnly: false,
    };

  }
  render() {
    return (
      <div>123567</div>
    )
  }
}