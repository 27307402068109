import React, { Component } from 'react'
import { Button, Form, Grid, Select, Table } from 'semantic-ui-react';
import Moment from 'moment';

// jspdf table ref. https://github.com/simonbengtsson/jsPDF-AutoTable
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton'
import { getPdfsrcAsync, PdfPanel } from 'widget/pdf/loadCore';
import { _calculateAge } from 'widget/define/date'
import Parse from 'widget/parse'
// import localization from 'widget/MaterialTableOpt'

export default class PrintPdf extends Component {

  constructor(props) {
    super(props);
    this.state = {
      doc: null,
      pdfsrc: null,
      students: []
      // height:750,
    };
  }
  componentDidMount = async () => {
    // console.log('componentDidMount')
    const { companyObj } = this.props
    const data = await Parse.queryData('Students', { companyId: companyObj.objectId });
    this.setState({ students: data }, () => this.fetchData());

  }
  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps', nextProps);
    // console.log(nextProps.codeForm, this.props.codeForm);
    if (nextProps.codeForm.code !== this.props.codeForm.code) {
      this.fetchData(nextProps)
    }
    if (nextProps.dataArr !== this.props.dataArr) {
      this.fetchData(nextProps)
    }
  }

  fetchData = (props = this.props) => {
    console.log('fetchData')
    // this.setState({ pdfsrc: null }, () => this.updateCodeToIFrame());
    this.setState({ pdfsrc: null }, () => {
      getPdfsrcAsync(props)
        .then(({ pdfsrc, doc }) => {
          this.setState({ pdfsrc, doc });
        })
    });
  }

  render() {

    // if (dirkey === 'recruitP') {
    //   if (statusKey === "immigrationApplication" || statusKey === "inquiry" || statusKey === "selection" || statusKey === "admissionPermit" || statusKey === "applyFail" || statusKey === "recruitStudent" || statusKey === "certainlyEntryList" || statusKey === "applyResultList" || statusKey === "allRecommendList") {
    //     selectList = [];
    //   } else {
    //     selectList = students.filter(item => item.semesterYear === semesterYear && item.semester === semester).sort((a, b) => a.studentId - b.studentId);
    //   }
    // } else if (dirkey === 'studentP' || dirkey === 'studentPGov' || key === "table13") {
    //   selectList = students.filter(item => (
    //     item.semesterYear == semesterYear && item.semester == "S"
    //     || item.semesterYear == semesterYear && item.semester == semester
    //     || Number(item.semesterYear) === Number(semesterYear) - 1)).sort((a, b) => a.studentId - b.studentId);
    // } else if (key === 'jpn46' || key === "table18") {
    //   selectList = classroom.filter(item => item.semesterYear === semesterYear && item.semester === semester).sort((a, b) => a.classSchool - b.classSchool);
    // } else if (key === 'table36') {
    //   selectList = teachers;
    // } else {
    //   selectList = [];
    // }
    // if (dirkey === 'recruitP') {
    //   return selectList.map(({ _id, jpnName, studentId, semester, semesterYear }) => (<option style={{ width: '280px' }} key={_id} value={_id}>{semesterYear.substr(-2)}({semester}) {studentId} {jpnName}</option>));
    // } else if (dirkey === 'studentP' || dirkey === 'studentPGov' || key === "table13") {
    //   if (key === 'jpn26') { // 自主退学申請書
    //     return selectList.filter(el => el.isDropout === "1").map(({ _id, jpnName, studentId, semester, semesterYear }) => (<option style={{ width: '280px' }} key={_id} value={_id}>{semesterYear.substr(-2)}({semester}){studentId} {jpnName}</option>));
    //   }
    //   return selectList.map(({ _id, jpnName, studentId, semester, semesterYear }) => (<option style={{ width: '280px' }} key={_id} value={_id}>{semesterYear.substr(-2)}({semester}){studentId} {jpnName}</option>));
    // } else {
    //   if (key === 'jpn46' || key === 'table18') { // クラス進学一覧表
    //     return selectList.map(({ _id, classSchool, entryYear, entryText, teacherText }) => (<option style={{ width: '280px' }} key={_id} value={_id}>{classSchool}班 {entryYear.substring(2)}年{entryText} {teacherText}</option>));
    //   } else if (key === 'table36') { // 校長、主任教教員一覧表
    //     return selectList.map(({ _id, nameTeacher, teacherTitleText }) => (<option style={{ width: '280px' }} key={_id} value={_id}>{teacherTitleText} {nameTeacher}</option>));
    //   } else {
    //     return ''
    //   }
    // }

    const { height = 900, codeForm, semesterYear, semester, yearsemester } = this.props;
    console.log(codeForm)
    const { key } = codeForm;
    const { pdfsrc, doc, students } = this.state;
    const semes = yearsemester ? yearsemester.semes : semester
    const year = yearsemester ? yearsemester.year : semesterYear
    return (<>
      {/* <h3>PDF出力</h3> */}
      {key ? <>
        {/* <Button primary color='teal' icon='file alternate outline' content='ログイン' onClick={this.loadPDF} />{' '} */}
        {key === 'table09' || key === 'table23' ? <ExportTableToExcelButton /> : null}
      </> : null}
      {/* loading ? '' : '印刷する書類を選擇して下さい' */}
      <PdfPanel pdfsrc={pdfsrc} height={height} doc={doc} />

      {key === 'table09' ?
        <Table id="excel" striped celled size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>申請日</Table.HeaderCell>
              <Table.HeaderCell>通番</Table.HeaderCell>
              <Table.HeaderCell>番号</Table.HeaderCell>
              <Table.HeaderCell>申請番号</Table.HeaderCell>
              <Table.HeaderCell>日本語教育機関名</Table.HeaderCell>
              <Table.HeaderCell>国籍</Table.HeaderCell>
              <Table.HeaderCell>7ヶ国</Table.HeaderCell>
              <Table.HeaderCell>氏名</Table.HeaderCell>
              <Table.HeaderCell>生年月日</Table.HeaderCell>
              <Table.HeaderCell>年齢</Table.HeaderCell>
              <Table.HeaderCell>性別</Table.HeaderCell>
              {/* <Table.HeaderCell>出生地</Table.HeaderCell> */}
              {/* <Table.HeaderCell>居住地</Table.HeaderCell> */}
              <Table.HeaderCell>経費支弁方法</Table.HeaderCell>
              <Table.HeaderCell>経費支弁者</Table.HeaderCell>
              <Table.HeaderCell>在籍予定期間</Table.HeaderCell>
              <Table.HeaderCell>最終学歴</Table.HeaderCell>
              <Table.HeaderCell>仲介業者又は仲介者</Table.HeaderCell>
              <Table.HeaderCell>（ベトナムの場合）
          仲介業者又は仲介者の本国政府による登録番号</Table.HeaderCell>
              <Table.HeaderCell>不交付歴等の有無</Table.HeaderCell>
              {/* <Table.HeaderCell>日本語能力について客観的資料</Table.HeaderCell> */}
              <Table.HeaderCell>追完提出予定</Table.HeaderCell>
              {/* <Table.HeaderCell>そのた</Table.HeaderCell> */}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {students.filter(item => item.semester === semes && item.semesterYear === year && item.studentStatus === "10")
              .map((item, index) =>
                <Table.Row>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>{}</Table.Cell>
                  <Table.Cell>愛知国際学院</Table.Cell>
                  <Table.Cell>{item.nationalityText}</Table.Cell>
                  <Table.Cell>{item['7Country'] === "1" ? "O" : ''}</Table.Cell>
                  <Table.Cell>{item.engName}</Table.Cell>
                  <Table.Cell>{Moment(item.birthday).format('YYYY年MM月DD日')}</Table.Cell>
                  <Table.Cell>{_calculateAge(item.birthday)}</Table.Cell>
                  <Table.Cell>{item.genderText}</Table.Cell>
                  {/* <Table.Cell>{(item.bornPlaceText || "") + (item.bornPlaceOther || "")}</Table.Cell> */}
                  {/* <Table.Cell>{(item.bornPlaceText || "") + (item.bornPlaceOther || "")}</Table.Cell> */}
                  <Table.Cell>{item.payMyself ? "本人負担" : item.payInjap ? "在日経費支弁者負担" : item.scholarship ? "奨学金" : item.outPay ? "外国送金" : item.othher ? item.othher : ""}</Table.Cell>
                  <Table.Cell>{item.supportRelationshipText}</Table.Cell>
                  <Table.Cell>{item.semester === "S" ? "2" : "1.5"}年</Table.Cell>
                  <Table.Cell>{item.schoDisText}</Table.Cell>
                  <Table.Cell>{item.agentCenterName}</Table.Cell>
                  <Table.Cell>{item.agentCenterNumber}</Table.Cell>
                  {/* <Table.Cell></Table.Cell> */}
                  <Table.Cell>{item['noGiveHistoryText']}</Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
          </Table.Body>
        </Table> : null}
      {key === 'table23' ?
        <Table id="excel" striped celled size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>受理番号</Table.HeaderCell>
              <Table.HeaderCell>入学許可番号</Table.HeaderCell>
              <Table.HeaderCell>名前</Table.HeaderCell>
              <Table.HeaderCell>国籍</Table.HeaderCell>
              <Table.HeaderCell>性別</Table.HeaderCell>
              <Table.HeaderCell>学歴</Table.HeaderCell>
              <Table.HeaderCell>経費支弁者氏名</Table.HeaderCell>
              <Table.HeaderCell>提出</Table.HeaderCell>
              <Table.HeaderCell>許可</Table.HeaderCell>
              <Table.HeaderCell>入国日</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {students.filter(item => item.semester === semes && item.semesterYear === year && item.studentStatus === "10")
              .map((item, index) =>
                <Table.Row>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>
                    {item.studentId}
                    {/* <br /> */}
                    {/* {item.studentId} */}
                  </Table.Cell>
                  <Table.Cell>{item.jpnName}</Table.Cell>
                  <Table.Cell>{item.nationalityText}</Table.Cell>
                  <Table.Cell>{item.genderText}</Table.Cell>
                  <Table.Cell>{item.finalSchoolName}</Table.Cell>
                  <Table.Cell>{item.supportName}</Table.Cell>
                  <Table.Cell>{}</Table.Cell>
                  <Table.Cell>{}</Table.Cell>
                  <Table.Cell>{}</Table.Cell>
                </Table.Row>
              )}
          </Table.Body>
        </Table> : null}
    </>)
  }
}
