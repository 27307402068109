import React, { Component } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row, Col,
  Form, FormGroup, Input, Label, Button
} from "reactstrap";
import { Form as Form2, TextArea } from 'semantic-ui-react';

import intl from 'react-intl-universal';
import MaterialTable from 'material-table'
import TextareaAutosize from "react-textarea-autosize";

import { clientPawn } from "views/formDef/object";
import { fieldCol } from "views/formDef/function";
import ModalAutoAddr from './ModalAutoAddr';
import Parse from "widget/parse";
import localization from 'widget/MaterialTableOpt'
import { FormControl } from "@material-ui/core";

const relationshipOpt = {
  "0": "父",
  "1": "母",
  "2": "兄",
  "3": "姊",
  "4": "弟",
  "5": "妹",
  "6": "子",
  "7": "女",
  "8": "祖父",
  "9": "祖母",
  "10": "朋友",
  "11": "配偶",
  "12": "情侶",
  "13": "其他",
}

const columns = () => ([
  { width: 80, title: '關係', field: "contactPerson_relationship", lookup: relationshipOpt },
  { width: 80, title: '其他關係', field: "contactPerson_relationship2" },
  { width: 80, title: '聯絡人', field: "contactPerson_Name" },
  { width: 80, title: '聯絡電話', field: "contactPerson_Tel" },
  { width: 80, title: '行動電話', field: "contactPerson_Phone" },
]);

class ClientTab1 extends Component {
  state = {
    clientObj: {},
    contactPersonArr: [],
    loading: true
  }

  componentDidMount = async () => {
    this.handleFetch()
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, clientObj } = props
    const companyId = companyObj.objectId;

    this.setState({ clientObj: {}, contactPersonArr: [], loading: true });
    const contactPersonArr = await Parse.queryData('people', { companyId, clientId: clientObj.objectId });
    this.setState({ clientObj, contactPersonArr, loading: false });
  }

  onRowDataChange = (clientObj) => {
    this.setState({ clientObj })
  }

  handleChange = (e, data) => {
    if (e.target) {
      this.setState({ clientObj: { ...this.state.clientObj, [e.target.name]: e.target.value } })
    } else {
      this.setState({ clientObj: { ...this.state.clientObj, [data.name]: e } })
    }
  }

  handleAdd = async () => {
    const { newData } = this.state;
    await Parse.saveData('people', newData);
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    await Parse.saveData('people', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    await Parse.deleteData('people', oldData);
    this.setState({ oldData: {} })
  }

  handleSave = async () => {
    const { clientObj } = this.state;
    await Parse.saveData('clients', clientObj);
    window.alert('已儲存')
  }

  render() {
    const { companyObj } = this.props
    const { clientObj, contactPersonArr, loading } = this.state

    // console.log(clientObj)
    return (<div className="content">
      <Card>
        <CardHeader>
          <CardTitle tag="h4">#{clientObj.uid || ''} {clientObj.name || ''} 基本資料</CardTitle>
        </CardHeader>
        <CardBody>
          <Form >
            <Row form>
              {fieldCol(clientPawn.name, "", "2", clientObj.name, this.handleChange)}
              {fieldCol(clientPawn.sexualId, "", "2", clientObj.sexualId, this.handleChange)}
              {fieldCol(clientPawn.peopleIdNumber, "", "2", clientObj.peopleIdNumber, this.handleChange)}
              {fieldCol(clientPawn.marriageState, "", "2", clientObj.marriageState, this.handleChange)}
              {fieldCol(clientPawn.childNum, "", "2", clientObj.childNum, this.handleChange)}
              {fieldCol(clientPawn.birthday, "", "2", clientObj.birthday, this.handleChange)}
            </Row>
            <Row form>
              {fieldCol(clientPawn.education, "", "2", clientObj.education, this.handleChange)}
              {fieldCol(clientPawn.cellnum, "", "2", clientObj.cellnum, this.handleChange)}
              {fieldCol(clientPawn.homePhone, "", "2", clientObj.homePhone, this.handleChange)}
              {fieldCol(clientPawn.nowStayPhone, "", "2", clientObj.nowStayPhone, this.handleChange)}
              {fieldCol(clientPawn.graduateElementarySchool, "", "2", clientObj.graduateElementarySchool, this.handleChange)}
              {fieldCol(clientPawn.livingState, "", "2", clientObj.livingState, this.handleChange)}
            </Row>
            <Row form>
              <Col md={1}>
                <FormGroup>
                  <ModalAutoAddr {...this.props} type='3' rowData={clientObj} field1="homePostcode" field2="homeAddr" onRowDataChange={this.onRowDataChange} />
                </FormGroup>
              </Col>
              {fieldCol(clientPawn.homePostcode, "", "1", clientObj.homePostcode, this.handleChange)}
              {fieldCol(clientPawn.homeAddr, "", "4", clientObj.homeAddr, this.handleChange)}
              <Col md={1}>
                <FormGroup>
                  <ModalAutoAddr {...this.props} type='3' rowData={clientObj} field1="nowPostcode" field2="nowAddr" onRowDataChange={this.onRowDataChange} />
                </FormGroup>
              </Col>
              {fieldCol(clientPawn.nowPostcode, "", "1", clientObj.nowPostcode, this.handleChange)}
              {fieldCol(clientPawn.nowAddr, "", "4", clientObj.nowAddr, this.handleChange)}
            </Row>
            <Row form>
              <Col md={1}>
                <FormGroup>
                  <ModalAutoAddr {...this.props} type='3' rowData={clientObj} field1="billPostcode" field2="billAddr" onRowDataChange={this.onRowDataChange} />
                </FormGroup>
              </Col>
              {fieldCol(clientPawn.billPostcode, "", "1", clientObj.billPostcode, this.handleChange)}
              {fieldCol(clientPawn.billAddr, "", "4", clientObj.billAddr, this.handleChange)}
              <Col md={1}>
                <FormGroup>
                  <ModalAutoAddr {...this.props} type='3' rowData={clientObj} field1="officePostcode" field2="officeAddr" onRowDataChange={this.onRowDataChange} />
                </FormGroup>
              </Col>
              {fieldCol(clientPawn.officePostcode, "", "1", clientObj.officePostcode, this.handleChange)}
              {fieldCol(clientPawn.officeAddr, "", "4", clientObj.officeAddr, this.handleChange)}
            </Row>
          </Form>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">服務單位資料</CardTitle>
        </CardHeader>
        <CardBody>
          <Form>
            <Row form>
              {fieldCol(clientPawn.companyname, "", "2", clientObj.companyname, this.handleChange)}
              {fieldCol(clientPawn.uniformNumbers, "", "2", clientObj.uniformNumbers, this.handleChange)}
              {fieldCol(clientPawn.CompanyPhone, "", "2", clientObj.CompanyPhone, this.handleChange)}
              {fieldCol(clientPawn.businessItems, "", "2", clientObj.businessItems, this.handleChange)}
              {fieldCol(clientPawn.department, "", "2", clientObj.department, this.handleChange)}
              {fieldCol(clientPawn.guarantorPosition, "", "2", clientObj.guarantorPosition, this.handleChange)}
            </Row>
            <Row form>
              {fieldCol(clientPawn.guarantorCompanyAddr, "", "4", clientObj.guarantorCompanyAddr, this.handleChange)}
              {fieldCol(clientPawn.deadLine, "", "2", clientObj.deadLine, this.handleChange)}
              {fieldCol(clientPawn.businessSalary, "", "2", clientObj.businessSalary, this.handleChange)}
              {fieldCol(clientPawn.otherIncome, "", "2", clientObj.otherIncome, this.handleChange)}
              {fieldCol(clientPawn.totalIncome, "", "2", clientObj.totalIncome, this.handleChange)}
            </Row>
          </Form>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle tag="h4">其他不動產收入</CardTitle>
        </CardHeader>
        <CardBody>
          <Form>
            <Row form>
              <Col md={1}>
                <FormGroup>
                  <ModalAutoAddr {...this.props} type='3' rowData={clientObj} field1="billAddr" field2="realEstate_1" onRowDataChange={this.onRowDataChange} />
                </FormGroup>
              </Col>
              {fieldCol(clientPawn.realEstate_1, "", "8", clientObj.realEstate_1, this.handleChange)}
              {fieldCol(clientPawn.realEstate_1_income, "", "2", clientObj.realEstate_1_income, this.handleChange)}
            </Row>
            <Row form>
              <Col md={1}>
                <FormGroup>
                  <ModalAutoAddr {...this.props} type='3' rowData={clientObj} field1="billAddr" field2="realEstate_2" onRowDataChange={this.onRowDataChange} />
                </FormGroup>
              </Col>
              {fieldCol(clientPawn.realEstate_2, "", "8", clientObj.realEstate_2, this.handleChange)}
              {fieldCol(clientPawn.realEstate_2_income, "", "2", clientObj.realEstate_2_income, this.handleChange)}
            </Row>
            <Row form>
              <Col md={1}>
                <FormGroup>
                  <ModalAutoAddr {...this.props} type='3' rowData={clientObj} field1="billAddr" field2="realEstate_3" onRowDataChange={this.onRowDataChange} />
                </FormGroup>
              </Col>
              {fieldCol(clientPawn.realEstate_3, "", "8", clientObj.realEstate_3, this.handleChange)}
              {fieldCol(clientPawn.realEstate_3_income, "", "2", clientObj.realEstate_3_income, this.handleChange)}
            </Row>
          </Form>
        </CardBody>
      </Card>
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          // addRowPosition: "first",
          exportButton: true,
          pageSize: 3,//data.length || 5,
          search: false,
          tableLayout: 'fixed',
        }}
        data={contactPersonArr}
        title='聯絡人資料（不同居所）'
        // actions={[
        //   {
        // icon: 'view_headline',
        // tooltip: '查看',
        // onClick: (event, rowData) => this.handleData(rowData)
        //   }
        // ]}
        // onRowClick={(event, rowData) => this.handleData(rowData)}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const data = [...contactPersonArr];
              const db = Parse.Object.extend("people");
              const newDoc = new db();
              const doc = await newDoc.save();
              newData.objectId = doc.id;
              newData.id = doc.id;
              newData._id = doc.id;
              newData.companyId = companyObj.objectId;
              newData.clientId = clientObj.objectId;

              data.push(newData);
              // this.props.handleChangeContactPerson(data);
              this.setState({ contactPersonArr: data, newData }, () => resolve());
            }).then(() => this.handleAdd()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...contactPersonArr];
              const index = contactPersonArr.indexOf(oldData);
              data[index] = newData;
              // console.log(data)
              // this.props.handleChangeContactPerson(data);
              this.setState({ contactPersonArr: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...contactPersonArr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              // this.props.handleChangeContactPerson(data);
              this.setState({ contactPersonArr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
      <br />
      <br />
      <Card>
        <CardBody>
          <Form>
            <FormGroup>
              <Label for="exampleText">備註</Label>
              <Input type="textarea"
                // row={50}
                // cols={50}
                // size='lg'
                cssModule={{ height: '400px !important' }}
                name="ps" id="ps" value={clientObj.ps || ''} onChange={this.handleChange} />
              {/* <Form2.Field
                useCacheForDOMMeasurements
                control={TextareaAutosize}
                // fluid
                value={clientObj.ps || ''}
                name="ps"
                id='ps'
                // action={{ icon: 'cancel', onClick: () => this.clear('To2') }}
                onChange={this.handleChange}
              // placeholder='請輸入Email，並以逗號分隔'
              /> */}
            </FormGroup>
            <Button onClick={this.handleSave}>{intl.get('儲存')}</Button>
          </Form>
        </CardBody>
      </Card>
    </div>);
  }
}

export default ClientTab1;
