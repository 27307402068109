export const user = {
  companyName: {
    label: '公司名稱',
    name: "companyName",
    type: 'text',
  },
  email: {
    label: '電子郵件',
    name: "email",
    type: 'email',
  },
  is_auth: {
    label: '就職狀態',
    name: "is_auth",
    type: 'select',
    option: 'userOpt',
    // select: 'is_auth'
  },
  onbroad_date: {
    label: '就職日期',
    name: "onbroad_date",
    type: 'date',
  },
  leave_date: {
    label: '離職日期',
    name: "leave_date",
    type: 'date',
  },
  name: {
    label: '姓名',
    name: "name",
    type: 'text',
  },
  jobNum: {
    label: '工號',
    name: "jobNum",
    type: 'text',
  },
  name_eng: {
    label: '英文姓名',
    name: "name_eng",
    type: 'text',
  },
  tw_id: {
    label: '身分證字號',
    name: "tw_id",
    type: 'text',
  },
  jobyear: {
    label: '年資',
    name: "jobyear",
    type: 'text',
  },
  jobTitle: {
    label: '職稱',
    name: "jobTitle",
    type: 'text',
  },
  passport_name: {
    label: '護照姓名',
    name: "passport_name",
    type: 'text',
  },
  birthday_date: {
    label: '生日',
    name: "birthday_date",
    type: 'date',
  },
  phone: {
    label: '聯絡電話',
    name: "phone",
    type: 'text',
  },
  aan: {
    label: 'AAN',
    name: "aan",
    type: 'text',
  },
  ps: {
    label: '備註',
    name: "ps",
    type: 'textarea',
  },
  role: {
    label: '身份',
    name: "role",
    type: 'select',
    option: 'userOpt',
  },
}
export const clientHanbury = {
  cellnum: {
    label: '行動電話',
    type: 'text',
    name: "cellnum"
  },
  uid: {
    label: '客戶編號',
    type: 'text',
    name: 'uid',
  },
  name_cht: {
    label: '中文姓名',
    type: 'text',
    name: 'name_cht',
  },
  name_eng: {
    label: '英文姓名',
    type: 'text',
    name: 'name_eng',
  },
  sexual_text: {
    label: '性別',
    type: 'select',
    name: 'sexual_text',
    option: 'clientHanburyOpt',
  },
  title_text: {
    label: '稱謂',
    type: 'text',
    name: 'title_text',
  },
  marriage: {
    label: '婚姻',
    type: 'select',
    name: 'marriage',
    option: 'clientHanburyOpt',
  },
  country_text: {
    label: '國籍',
    type: 'text',
    name: 'country_text',
  },
  country2_text: {
    label: '國籍(2)',
    type: 'text',
    name: 'country2_text',
  },
  passport: {
    label: '護照號碼',
    type: 'text',
    name: 'passport',
  },
  birthday: {
    label: '生日',
    type: 'date',
    name: 'birthday',
  },
  identify: {
    label: '身分證字號',
    type: 'text',
    name: 'identify',
  },
  email: {
    label: '電子信箱',
    type: 'text',
    name: 'email',
  },
  email2: {
    label: '電子信箱(備用)',
    type: 'text',
    name: 'email2',
  },
  addr1_post5: {
    label: '通訊地址(中) 區號',
    type: 'text',
    name: 'addr1_post5',
  },
  addr1_phone: {
    label: '聯絡電話(H)',
    type: 'text',
    name: 'addr1_phone',
  },
  agent_id: {
    label: 'Agent',
    type: 'text',
    name: 'agent_id',
  },
  addr2_cht: {
    label: '戶籍地址(中)',
    type: 'text',
    name: 'addr2_cht',
  },
  addr2_eng: {
    label: '戶籍地址(英)',
    type: 'text',
    name: 'addr2_eng',
  },
  addr1_eng: {
    label: '通訊地址(英)',
    type: 'text',
    name: 'addr1_eng',
  },
  fin_com_name: {
    label: '公司名稱(中)',
    type: 'text',
    name: 'fin_com_name',
  },
  fin_com_name_eng: {
    label: '公司名稱(英)',
    type: 'text',
    name: 'fin_com_name_eng',
  },
  fin_title: {
    label: '職稱',
    type: 'text',
    name: 'fin_title',
  },
  fin_com_tele: {
    label: '公司電話',
    type: 'text',
    name: 'fin_com_tele',
  },
  fin_com_fax: {
    label: '公司傳真',
    type: 'text',
    name: 'fin_com_fax',
  },
  fin_com_addrcht: {
    label: '公司地址(中)',
    type: 'text',
    name: 'fin_com_addrcht',
  },
  fin_com_addreng: {
    label: '公司地址(英)',
    type: 'text',
    name: 'fin_com_addreng',
  },
  fin_invest_exp: {
    label: '投資經驗',
    type: 'text',
    name: 'fin_invest_exp',
  },
  fin_invest_purpose: {
    label: '投資目的',
    type: 'text',
    name: 'fin_invest_purpose',
  },
  fin_ps: {
    label: '備註',
    type: 'text',
    name: 'fin_ps',
  },
  is_periodmail: {
    label: '寄送電子報',
    type: 'checkbox',
    name: 'is_periodmail',
  },
  periodmail2: {
    label: '投資月報表',
    type: 'checkbox',
    name: 'periodmail2',
  },
  periodmail3: {
    label: 'Hanbury View',
    type: 'checkbox',
    name: 'periodmail3',
  },
  periodmail4: {
    label: 'Hanbury Finanical',
    type: 'checkbox',
    name: 'periodmail4',
  },
  periodmail5: {
    label: 'VOYA年度繳費提醒',
    type: 'checkbox',
    name: 'periodmail5',
  },
  periodmail6: {
    label: 'FPI逾期款項提醒',
    type: 'checkbox',
    name: 'periodmail6',
  },
  periodmail7: {
    label: '生日賀卡',
    type: 'checkbox',
    name: 'periodmail7',
  },
  periodmail8: {
    label: '年節賀卡',
    type: 'checkbox',
    name: 'periodmail8',
  },
  // contactnum: {
  //   label: 'TEL',
  //   type: 'text',
  //   name: "contactnum"
  // },
}

export const clientPawn = {
  name: {
    label: '姓名',
    type: 'text',
    name: "name"
  },
  sexualId: {
    label: '性別',
    type: 'select',
    name: "sexualId",
    option: 'clientPawnOpt',
  },
  peopleIdNumber: {
    label: '身分證字號',
    type: 'text',
    name: "peopleIdNumber"
  },
  marriageState: {
    label: '婚姻狀況',
    type: 'select',
    name: "marriageState",
    option: 'clientPawnOpt',
  },
  childNum: {
    label: '子女',
    type: 'select',
    name: "childNum",
    option: 'clientPawnOpt',

  },
  birthday: {
    label: '出生日期',
    type: 'text',
    name: "birthday"
  },
  education: {
    label: '學歷',
    type: 'select',
    name: "education",
    option: 'clientPawnOpt',
  },
  cellnum: {
    label: '行動電話',
    type: 'text',
    name: "cellnum"
  },
  homePhone: {
    label: '戶籍電話',
    type: 'text',
    name: "homePhone"
  },
  nowStayPhone: {
    label: '現居電話',
    type: 'text',
    name: "nowStayPhone"
  },
  homeAddr: {
    label: '戶籍地址',
    type: 'text',
    name: "homeAddr"
  },
  homePostcode: {
    label: '郵遞區號',
    type: 'text',
    name: "homePostcode"
  },
  billAddr: {
    label: '帳單地址',
    type: 'text',
    name: "billAddr"
  },
  billPostcode: {
    label: '郵遞區號',
    type: 'text',
    name: "billPostcode"
  },
  nowAddr: {
    label: '現居地址',
    type: 'text',
    name: "nowAddr"
  },
  nowPostcode: {
    label: '郵遞區號',
    type: 'text',
    name: "nowPostcode"
  },
  officeAddr: {
    label: '公司地址',
    type: 'text',
    name: "officeAddr"
  },
  officePostcode: {
    label: '郵遞區號',
    type: 'text',
    name: "officePostcode"
  },
  graduateElementarySchool: {
    label: '畢業國小',
    type: 'text',
    name: "graduateElementarySchool"
  },
  livingState: {
    label: '居住現況',
    type: 'select',
    name: "livingState",
    option: 'clientPawnOpt',
  },
  companyname: {
    label: '公司名稱',
    type: 'text',
    name: "companyname"
  },
  uniformNumbers: {
    label: '統一編號',
    type: 'text',
    name: "uniformNumbers"
  },
  CompanyPhone: {
    label: '公司電話',
    type: 'text',
    name: "CompanyPhone"
  },
  businessItems: {
    label: '營業項目',
    type: 'text',
    name: "businessItems"
  },
  department: {
    label: '所屬部門',
    type: 'text',
    name: "department"
  },
  guarantorPosition: {
    label: '職稱',
    type: 'text',
    name: "guarantorPosition"
  },
  guarantorCompanyAddr: {
    label: '公司地址',
    type: 'text',
    name: "guarantorCompanyAddr"
  },
  deadLine: {
    label: '到期日期',
    type: 'date',
    name: "deadLine"
  },
  businessSalary: {
    label: '本業薪資',
    type: 'text',
    name: "businessSalary"
  },
  otherIncome: {
    label: '其他收入',
    type: 'text',
    name: "otherIncome"
  },
  totalIncome: {
    label: '總收入',
    type: 'text',
    name: "totalIncome"
  },
  realEstate_1: {
    label: '地址1',
    type: 'text',
    name: "realEstate_1"
  },
  realEstate_1_income: {
    label: '每月收入1',
    type: 'text',
    name: "realEstate_1_income"
  },
  realEstate_2: {
    label: '地址2',
    type: 'text',
    name: "realEstate_2"
  },
  realEstate_2_income: {
    label: '每月收入2',
    type: 'text',
    name: "realEstate_2_income"
  },
  realEstate_3: {
    label: '地址3',
    type: 'text',
    name: "realEstate_3"
  },
  realEstate_3_income: {
    label: '每月收入3',
    type: 'text',
    name: "realEstate_3_income"
  },
  contactPerson1_Name: {
    label: '聯絡人(一)',
    type: 'text',
    name: "contactPerson1_Name"
  },
  contactPerson1_Tel: {
    label: '聯絡電話',
    type: 'text',
    name: "contactPerson1_Tel"
  },
  contactPerson1_Phone: {
    label: '行動電話',
    type: 'text',
    name: "contactPerson1_Phone"
  },
  contactPerson1_relationship: {
    label: '關係',
    type: 'select',
    name: "contactPerson1_relationship",
    option: 'clientPawnOpt',
  },
  contactPerson2_Name: {
    label: '聯絡人(二)',
    type: 'text',
    name: "contactPerson2_Name"
  },
  contactPerson2_Tel: {
    label: '聯絡電話',
    type: 'text',
    name: "contactPerson2_Tel"
  },
  contactPerson2_Phone: {
    label: '行動電話',
    type: 'text',
    name: "contactPerson2_Phone"
  },
  contactPerson2_relationship: {
    label: '關係',
    type: 'select',
    name: "contactPerson2_relationship",
    option: 'clientPawnOpt',
  },

}

export const employeePawn = {
  name: {
    label: '姓名',
    type: 'text',
    name: "name"
  },
  sexualId: {
    label: '性別',
    type: 'select',
    name: "sexualId",
    option: 'employeePawnOpt',
  },
  birthday: {
    label: '生日',
    type: 'date',
    name: "birthday"
  },
  residentialAddr: {
    label: '居住地址',
    type: 'text',
    name: "residentialAddr"
  },
  residentialPhone: {
    label: '居住電話',
    type: 'text',
    name: "residentialPhone"
  },
  censusAddr: {
    label: '戶籍地址',
    type: 'text',
    name: "censusAddr"
  },
  censusPhone: {
    label: '戶籍電話',
    type: 'text',
    name: "censusPhone"
  },
  ps: {
    label: '備註',
    type: 'textarea',
    name: "ps"
  },
}

export const userPawn = {
  name: {
    label: '姓名',
    type: 'text',
    name: "name"
  },
  sexualId: {
    label: '性別',
    type: 'select',
    name: "sexualId",
    option: 'userPawnOpt',
  },
  birthday: {
    label: '生日',
    type: 'date',
    name: "birthday"
  },
  residentialAddr: {
    label: '居住地址',
    type: 'text',
    name: "residentialAddr"
  },
  residentialPhone: {
    label: '居住電話',
    type: 'text',
    name: "residentialPhone"
  },
  censusAddr: {
    label: '戶籍地址',
    type: 'text',
    name: "censusAddr"
  },
  censusPhone: {
    label: '戶籍電話',
    type: 'text',
    name: "censusPhone"
  },
  ps: {
    label: '備註',
    type: 'textarea',
    name: "ps"
  },
}

export const portfolio = {
  account_num: {
    label: 'Policy No.',
    type: 'text',
    name: "account_num"
  },
  assume_insure_price: {
    label: '規劃投保金額',
    type: 'text',
    name: "assume_insure_price",
  },
  effective_date: {
    label: '生效日(計息日)',
    type: 'date',
    name: "effective_date"
  },
  confirmed_insure_price: {
    label: '確認投保金額',
    type: 'text',
    name: "confirmed_insure_price"
  },
  backdate: {
    label: 'Back Date',
    type: 'date',
    name: "backdate"
  },
  pay_year_num: {
    label: '繳費年期',
    type: 'number',
    name: "pay_year_num"
  },
  cpaletter_date: {
    label: 'CPA Letter',
    type: 'date',
    name: "cpaletter_date"
  },
  temp_applybook_date: {
    label: '臨時申請書',
    type: 'date',
    name: "temp_applybook_date"
  },
  bodycheck_date: {
    label: '體檢日期',
    type: 'date',
    name: "bodycheck_date"
  },
  bodycheck_place: {
    label: '體檢地點',
    type: 'text',
    name: "bodycheck_place"
  },
  is_trust: {
    label: '信託',
    type: 'select',
    name: "is_trust",
    option: 'portfolioOpt',
  },
  sign_date: {
    label: '簽約日期',
    type: 'date',
    name: "sign_date"
  },
  trust_isrevoke: {
    label: '信託種類',
    type: 'select',
    name: "trust_isrevoke",
    option: 'portfolioOpt',
  },
  bodychecktype3: {
    label: '核保體位',
    type: 'select',
    name: "bodychecktype3",
    option: 'portfolioOpt',
  },
  trust_lawyer: {
    label: '信託律師',
    type: 'text',
    name: "trust_lawyer"
  },
  insurance_receive_date: {
    label: '保單收執',
    type: 'date',
    name: "insurance_receive_date"
  },
  trust_name: {
    label: '信託名稱',
    type: 'text',
    name: "trust_name"
  },
  insurance_give_date: {
    label: '保單交付',
    type: 'date',
    name: "insurance_give_date"
  },
  trust_trustee: {
    label: 'Trustee',
    type: 'text',
    name: "trust_trustee"
  },
  onlineaccess_account: {
    label: 'Online Access(帳號)',
    type: 'text',
    name: "onlineaccess_account"
  },
  trust_grantor: {
    label: 'Grantor',
    type: 'text',
    name: "trust_grantor"
  },
  onlineaccess_password: {
    label: 'Online Access(密碼)',
    type: 'text',
    name: "onlineaccess_password"
  },
  trust_ssnitin: {
    label: 'SSN / ITIN',
    type: 'text',
    name: "trust_ssnitin"
  },
  trust_bankaccount: {
    label: '信託帳戶',
    type: 'text',
    name: "trust_bankaccount"
  },
  tp: {
    label: 'TP',
    type: 'text',
    name: "tp"
  },
  trust_account: {
    label: '帳號',
    type: 'text',
    name: "trust_account"
  },
  trust_routenum: {
    label: '匯款路線號碼',
    type: 'text',
    name: "trust_routenum"
  },
  trust_beneficial1_name: {
    label: '信託受益人1(姓名)',
    type: 'text',
    name: "trust_beneficial1_name"
  },
  trust_beneficial1_percent: {
    label: '信託受益人1(％)',
    type: 'text',
    name: "trust_beneficial1_percent"
  },
  trust_beneficial1_relationship: {
    label: '信託受益人1(關係)',
    type: 'text',
    name: "trust_beneficial1_relationship"
  },
  nowphase: {
    label: '項目狀況',
    type: 'select',
    name: "nowphase",
    option: 'portfolioOpt',
  },
  trust_beneficial2_name: {
    label: '信託受益人2(姓名)',
    type: 'text',
    name: "trust_beneficial2_name"
  },
  trust_beneficial2_percent: {
    label: '信託受益人2(％)',
    type: 'text',
    name: "trust_beneficial2_percent"
  },
  trust_beneficial2_relationship: {
    label: '信託受益人2(關係)',
    type: 'text',
    name: "trust_beneficial2_relationship"
  },
  // trust_beneficial3_name: {
  //   label: '信託受益人3(姓名)',
  //   type: 'text',
  //   name: "trust_beneficial3_name"
  // },
  // trust_beneficial3_percent: {
  //   label: '信託受益人3(％)',
  //   type: 'text',
  //   name: "trust_beneficial3_percent"
  // },
  // trust_beneficial3_relationship: {
  //   label: '信託受益人3(關係)',
  //   type: 'text',
  //   name: "trust_beneficial3_relationship"
  // },
  // trust_beneficial4_name: {
  //   label: '信託受益人4(姓名)',
  //   type: 'text',
  //   name: "trust_beneficial4_name"
  // },
  // trust_beneficial4_percent: {
  //   label: '信託受益人4(％)',
  //   type: 'text',
  //   name: "trust_beneficial4_percent"
  // },
  // trust_beneficial4_relationship: {
  //   label: '信託受益人4(關係)',
  //   type: 'text',
  //   name: "trust_beneficial4_relationship"
  // },
  telephone_credit: {
    label: '電話徵信',
    type: 'text',
    name: "telephone_credit"
  },
  bodychecktype1: {
    label: '核保體位1',
    type: 'select',
    name: "bodychecktype1",
    option: 'portfolioOpt',
  },
  bodychecktype2: {
    label: '核保體位2',
    type: 'select',
    name: "bodychecktype2",
    option: 'portfolioOpt',
  },
  currency_type: {
    label: '幣別',
    type: 'select',
    name: "currency_type",
    option: 'portfolioOpt',
  },
  stockitem: {
    label: '項目名稱',
    type: 'select',
    name: "stockitem",
    option: 'portfolioOpt',
  },
  prebuybook_date: {
    label: '預購書',
    type: 'date',
    name: "prebuybook_date"
  },
  certificate_no: {
    label: 'Certificate Number',
    type: 'text',
    name: "certificate_no"
  },
  holdstock_num: {
    label: '持有股數 (shares)',
    type: 'text',
    name: "holdstock_num"
  },
  stockcontract_date: {
    label: '股權合約書',
    type: 'date',
    name: "stockcontract_date"
  },
  giveinterestconfirm_date: {
    label: '帳戶配息確認單',
    type: 'date',
    name: "giveinterestconfirm_date"
  },
  invest_money: {
    label: '投資金額',
    type: 'text',
    name: "invest_money"
  },
  stockrightapply_date: {
    label: '股權申請書',
    type: 'date',
    name: "stockrightapply_date"
  },
  passport_publicprove: {
    label: '護照公證',
    type: 'date',
    name: "passport_publicprove"
  },
  holdstock_percent: {
    label: '持股比例 (%)',
    type: 'text',
    name: "holdstock_percent"
  },
  address_publicprove: {
    label: '地址公證',
    type: 'text',
    name: "address_publicprove"
  },
  stockcredit_give: {
    label: '股權憑證交付',
    type: 'date',
    name: "stockcredit_give"
  },
  payment_date: {
    label: '(原)匯款日',
    type: 'date',
    name: "payment_date"
  },
  givecontract_date: {
    label: '契約書交付',
    type: 'date',
    name: "givecontract_date"
  },
  payment_method: {
    label: '匯款方式',
    type: 'text',
    name: "payment_method"
  },
  return_principal_date: {
    label: '本金返還',
    type: 'date',
    name: "return_principal_date"
  },
  receive_money_date: {
    label: '收款憑證',
    type: 'date',
    name: "receive_money_date"
  },
  irs_w7_sign_date: {
    label: '簽署日期',
    type: 'date',
    name: "irs_w7_sign_date"
  },
  irs_w7_status: {
    label: '狀態irs',
    type: 'select',
    name: "irs_w7_status",
    option: 'portfolioOpt',
  },
  irs_w7_sent_date: {
    label: '遞交日期irs',
    type: 'date',
    name: "irs_w7_sent_date"
  },
  irs_ps: {
    label: '備註irs',
    type: 'text',
    name: "irs_ps"
  },
  itin_text: {
    label: '輸入文字(編號)',
    type: 'text',
    name: "itin_text"
  },
  itin_status: {
    label: '狀態it',
    type: 'select',
    name: "itin_status",
    option: 'portfolioOpt',
  },
  itin_date: {
    label: '遞交日期',
    type: 'date',
    name: "itin_date"
  },
  itin_ps: {
    label: '備註',
    type: 'text',
    name: "itin_ps"
  },
  giveinterest_period: {
    label: '配息週期',
    type: 'select',
    name: "giveinterest_period",
    option: 'portfolioOpt',
  },
  unitroomnum: {
    label: '單位房號',
    type: 'text',
    name: "unitroomnum"
  },
  invest_type: {
    label: '投資種類',
    type: 'select',
    name: "invest_type",
    option: 'portfolioOpt',
  },
  company_type: {
    label: '公司形式',
    type: 'select',
    name: "company_type",
    option: 'portfolioOpt',
  },
  is_leave: {
    label: '離場機制 / 是否有離場',
    type: 'select',
    name: "is_leave",
    option: 'portfolioOpt',
  },
  arrReturnPrincipal: {
    label: '本金返還(離場) 日期/金額',
    type: 'text',
    name: "arrReturnPrincipal"
  },
  leavetotal_money: {
    label: '總餘下投資金額',
    type: 'text',
    name: "leavetotal_money"
  },
  certpassport_use_date: {
    label: '護照有效日期',
    type: 'date',
    name: "certpassport_use_date"
  },
  certpassport_status: {
    label: '狀態cp',
    type: 'select',
    name: "certpassport_status",
    option: 'portfolioOpt',
  },
  certpassport_sent_date: {
    label: '遞交日期cp',
    type: 'date',
    name: "certpassport_sent_date"
  },
  certpassport_ps: {
    label: '備註cp',
    type: 'text',
    name: "certpassport_ps"
  },
  t2848_text: {
    label: '2848',
    type: 'text',
    name: "t2848_text"
  },
  taxret_text: {
    label: 'Tax Return',
    type: 'text',
    name: "taxret_text"
  },
  collectdone_date: {
    label: '集資完成日期',
    type: 'date',
    name: "collectdone_date"
  },
  payMonthNum: {
    label: '配息月份',
    type: 'select',
    name: "payMonthNum",
    option: 'portfolioOpt',
  },
  assumeinvest_year: {
    label: '預計投資年期',
    type: 'text',
    name: "assumeinvest_year"
  },
  assumeleave_year: {
    label: '預計退場(西元年)',
    type: 'select',
    name: "assumeleave_year",
    option: 'portfolioOpt',
  },
  assumeleave_season: {
    label: '預計退場(第幾季)',
    type: 'select',
    name: "assumeleave_season",
    option: 'portfolioOpt',
  },
  p5Country: {
    label: '房地產項目國家',
    type: 'select',
    name: "p5Country",
    option: 'portfolioOpt',
  },
  account_num2: {
    label: '保單號碼',
    type: 'text',
    name: "account_num2"
  },
  apply_birthday: {
    label: '生日',
    type: 'date',
    name: "apply_birthday"
  },
  insurance_status: {
    label: '保單狀態',
    type: 'select',
    name: "insurance_status",
    option: 'portfolioOpt',
  },
  policy_firstperson: {
    label: '第一(或唯一)保單持有人',
    type: 'text',
    name: "policy_firstperson"
  },
  policy_secondperson: {
    label: '第二保單持有人',
    type: 'text',
    name: "policy_secondperson"
  },
  payperiod_fpi: {
    label: '供款週期',
    type: 'select',
    name: "payperiod_fpi",
    option: 'portfolioOpt',
  },
  paymethod_fpi: {
    label: '供款方式',
    type: 'select',
    name: "paymethod_fpi",
    option: 'portfolioOpt',
  },
  apply_addr: {
    label: '地址',
    type: 'text',
    name: "apply_addr"
  },
  apply_telephone: {
    label: '連絡電話',
    type: 'text',
    name: "apply_telephone"
  },
  apply_email: {
    label: 'E-mail',
    type: 'text',
    name: "apply_email"
  },
  apply_passport: {
    label: '護照號碼',
    type: 'text',
    name: "apply_passport"
  },
  creditcard_num: {
    label: '信用卡號碼',
    type: 'text',
    name: "creditcard_num"
  },
  creditcard_name: {
    label: '信用卡持有人名稱',
    type: 'text',
    name: "creditcard_name"
  },
  creditcard_addr: {
    label: '信用卡持有人地址',
    type: 'text',
    name: "creditcard_addr"
  },
  policy_nextpay_date: {
    label: '下次繳費日期',
    type: 'date',
    name: "policy_nextpay_date"
  },
  policy_period_year: {
    label: '保單年期(年)',
    type: 'text',
    name: "policy_period_year"
  },
  policy_period_month: {
    label: '保單年期(月)',
    type: 'select',
    name: "policy_period_month",
    option: 'portfolioOpt',
  },
  policy_effective_date: {
    label: '保單生效日',
    type: 'date',
    name: "policy_effective_date"
  },
  policy_endpay_date: {
    label: '終止繳費日期',
    type: 'date',
    name: "policy_endpay_date"
  },
  creditcard_bank: {
    label: '信用卡銀行',
    type: 'text',
    name: "creditcard_bank"
  },
  creditcard_bankname: {
    label: '發卡銀行國家',
    type: 'text',
    name: "creditcard_bankname"
  },
  creditcard_date: {
    label: '信用卡效期',
    type: 'date',
    name: "creditcard_date"
  },
  hsbc_account_name: {
    label: 'HSBC帳戶姓名',
    type: 'text',
    name: "hsbc_account_name"
  },
  hsbc_account_num: {
    label: 'HSBC帳戶號碼',
    type: 'text',
    name: "hsbc_account_num"
  },
  terminal_price: {
    label: '解約/違約金',
    type: 'text',
    name: "terminal_price"
  },
  terminal_restvalue: {
    label: '解約剩餘價值',
    type: 'text',
    name: "terminal_restvalue"
  },
  insurancepay_status: {
    label: '繳款狀態',
    type: 'select',
    name: "insurancepay_status",
    option: 'portfolioOpt',
  },
  product4_engtext: {
    label: '產品名稱',
    type: 'text',
    name: "product4_engtext"
  },
  policy_currency_type: {
    label: '保單幣別',
    type: 'select',
    name: "policy_currency_type",
    option: 'portfolioOpt',
  },
  policy_end_date: {
    label: '保單到期日',
    type: 'date',
    name: "policy_end_date"
  },
  fpi_done_withdraw_money: {
    label: '已供款總額',
    type: 'text',
    name: "fpi_done_withdraw_money"
  },
  fpi_able_withdraw_money: {
    label: '可提款總額 (Total "A" UNITS)',
    type: 'text',
    name: "fpi_able_withdraw_money"
  },
  fpi_all_withdraw_money: {
    label: '總提款金額',
    type: 'text',
    name: "fpi_all_withdraw_money"
  },
  apply_cellphone: {
    label: '手機號碼',
    type: 'text',
    name: "apply_cellphone"
  },
  account_invest_per: {
    label: '帳戶投資本益比 %',
    type: 'text',
    name: "account_invest_per"
  },
  fpi_data_updatedate: {
    label: '友誠更新日期',
    type: 'text',
    name: "fpi_data_updatedate"
  },
  certificate_name: {
    label: 'Name of Certificate',
    type: 'text',
    name: "certificate_name"
  },
  stockrightsummit: {
    label: '股權憑證交付',
    type: 'date',
    name: "stockrightsummit"
  },
  provider_engtext: {
    label: '供應商',
    type: 'text',
    name: "provider_engtext"
  },
  fpi_now_same_payment: {
    label: '現時定額供款',
    type: 'text',
    name: "fpi_now_same_payment"
  },
  beneficial1_name: {
    label: '受益人1(姓名)',
    type: 'text',
    name: "beneficial1_name"
  },
  // beneficial1_name2: {
  //   label: '受益人1(姓名)',
  //   type: 'text',
  //   name: "beneficial1_name2"
  // },
  beneficial1_percent: {
    label: '受益人1(％)',
    type: 'text',
    name: "beneficial1_percent"
  },
  beneficial1_relationship: {
    label: '受益人1(關係)',
    type: 'text',
    name: "beneficial1_relationship"
  },
  beneficial2_name: {
    label: '受益人2(姓名)',
    type: 'text',
    name: "beneficial2_name"
  },
  // beneficial2_name2: {
  //   label: '受益人2b',
  //   type: 'text',
  //   name: "beneficial2_name2"
  // },
  beneficial2_percent: {
    label: '受益人2(％)',
    type: 'text',
    name: "beneficial2_percent"
  },
  beneficial2_relationship: {
    label: '受益人2(關係)',
    type: 'text',
    name: "beneficial2_relationship"
  },
  // beneficial3_name: {
  //   label: '受益人3(姓名)',
  //   type: 'text',
  //   name: "beneficial3_name"
  // },
  // beneficial3_name2: {
  //   label: '受益人3b',
  //   type: 'text',
  //   name: "beneficial3_name2"
  // },
  // beneficial3_percent: {
  //   label: '受益人3(％)',
  //   type: 'text',
  //   name: "beneficial3_percent"
  // },
  // beneficial3_relationship: {
  //   label: '受益人3(關係)',
  //   type: 'text',
  //   name: "beneficial3_relationship"
  // },accountconfirm_date
  accountconfirm_date: {
    label: '帳戶配息確認單',
    type: 'date',
    name: "accountconfirm_date"
  },
}
export const dorm = {
  roomNum: {
    label: '房號',
    type: 'select',
    name: "roomNum"
  },
  name: {
    label: '姓名',
    type: 'text',
    name: "name"
  },
  idcardNum: {
    label: '身分證字號',
    type: 'text',
    name: "idcardNum",
  },
  cellphone: {
    label: '手機',
    type: 'text',
    name: "cellphone"
  },
  phone: {
    label: '電話',
    type: 'text',
    name: "phone"
  },
  addr: {
    label: '戶籍地址',
    type: 'text',
    name: "addr"
  },
  school: {
    label: '學校',
    type: 'text',
    name: "school"
  },
  department: {
    label: '系別',
    type: 'text',
    name: "department"
  },
  grade: {
    label: '年級',
    type: 'text',
    name: "grade"
  },
  emergency: {
    label: '緊急聯絡人',
    type: 'text',
    name: "emergency"
  },
  relationship: {
    label: '關係',
    type: 'text',
    name: "relationship"
  },
  emergencyCellphone: {
    label: '手機',
    type: 'text',
    name: "emergencyCellphone"
  },
  emergencyPhone: {
    label: '電話',
    type: 'text',
    name: "emergencyPhone"
  },
  emergencyAddr: {
    label: '地址',
    type: 'text',
    name: "emergencyAddr"
  },
  ps: {
    label: '備註',
    type: 'textarea',
    name: "ps"
  }
}
