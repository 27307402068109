import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { Row, Col, } from "reactstrap";
import { dateCol, typeCurrency, monthOpt, mgyearOpt, exportPdf } from "views/web/CustomMaterialTable";

const columns = () => ([
  { width: 80, title: '代號', field: 'jobNum', editable: 'never' },
  { width: 80, title: '姓名', field: 'name', editable: 'never' },
  { width: 80, title: '業績', field: 'performanceTotal', editable: 'never', ...typeCurrency },
  { width: 80, title: '應收利息', field: 'interestPlanSelfTotal', editable: 'never', ...typeCurrency },
  { width: 80, title: '實收利息', field: 'interestPaidSelfTotal', editable: 'never', ...typeCurrency },
  { width: 80, title: '佣金', field: 'commissionTotal', editable: 'never', ...typeCurrency },
  { width: 80, title: '代墊', field: 'interestUnPaidSelfTotal', editable: 'never', ...typeCurrency },
]);

const columns2 = () => ([
  { width: 80, title: '計劃繳息日期', field: 'planDate', editable: 'never' },
  { width: 80, title: '本金結餘', field: 'principalRest', editable: 'never', ...typeCurrency },
  { width: 80, title: '應收利息', field: 'interestPlan', editable: 'never', ...typeCurrency },
  dateCol({ title: '還本日期', field: 'principalPaidDate' }),
  { width: 80, title: '本金還本', field: 'principalPaid', headerStyle: { textAlign: 'left' }, ...typeCurrency },
  dateCol({ title: '繳息日期', field: 'interestPaidDate' }),
  { width: 80, title: '繳息金額', field: 'interestPaid', headerStyle: { textAlign: 'left' }, ...typeCurrency },

  { width: 80, title: '業績', field: 'performance', headerStyle: { textAlign: 'left' }, ...typeCurrency },
  { width: 80, title: '應收利息', field: 'interestPlanSelf', headerStyle: { textAlign: 'left' }, ...typeCurrency },
  { width: 80, title: '實收利息', field: 'interestPaidSelf', headerStyle: { textAlign: 'left' }, ...typeCurrency },
  { width: 80, title: '佣金', field: 'commission', headerStyle: { textAlign: 'left' }, ...typeCurrency },
  { width: 80, title: '代墊', field: 'interestUnPaidSelf', headerStyle: { textAlign: 'left' }, ...typeCurrency },
  { width: 180, title: '備註', field: 'ps' },
]);

const d = new Date()
class ABaccount extends Component {
  state = {
    activeItem: 'bio',
    dataArr: [],
    rowData: {},
    loading: true,
    type: 'total',
    totalAccId: 'total',
    employeeObj: [],
    employeeArr: [],
    employeeId: '-1',
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
    arr2: [],
  }

  async componentDidMount() {
    const { companyObj } = this.props
    const employeeArr = await Parse.queryData('User', { authAllA: companyObj.objectId });
    this.setState({ employeeArr }, () => this.handleFetch())
  }

  handleFetch = async () => {
    const { companyObj } = this.props
    const { employeeArr, mgyearSel, monthSel, } = this.state
    const year = mgyearSel;
    const month = monthSel;

    this.setState({ loading: true })
    const pawning2Arr = await Parse.queryData('pawning2', { companyId: companyObj.objectId, year, month });

    const dataArr = employeeArr.map(employeeData => {
      let performanceTotal = 0
      let interestPlanSelfTotal = 0
      let interestPaidSelfTotal = 0
      let commissionTotal = 0
      let interestUnPaidSelfTotal = 0;

      console.log(pawning2Arr);
      pawning2Arr.forEach(item => {
        const { jointObjArr, principalRest, interestPlan, interestPaid } = item;
        if (jointObjArr) {
          let jointObj = {}
          if (jointObjArr) {
            jointObj = jointObjArr.find(item2 => item2.objectId === employeeData.objectId)
          }
          if (jointObj) {
            console.log(jointObj);
            const { proportion } = jointObj
            const performance = principalRest ? Number(principalRest) * Number(proportion) / 100 : 0;
            const interestPlanSelf = interestPlan ? Number(interestPlan) * Number(proportion) / 100 : 0;
            const interestPaidSelf = interestPaid ? Number(interestPaid) * Number(proportion) / 100 : 0;
            const commission = interestPaidSelf ? Number(interestPaidSelf) * Number(employeeData.interest) / 100 : 0;

            performanceTotal += performance;
            console.log(performance);
            interestPlanSelfTotal += interestPlanSelf;
            interestPaidSelfTotal += interestPaidSelf;
            commissionTotal += commission;
          }
        }
      })
      interestUnPaidSelfTotal = interestPlanSelfTotal - interestPaidSelfTotal;

      return {
        ...employeeData,
        performanceTotal: String(performanceTotal),
        interestPlanSelfTotal: String(interestPlanSelfTotal),
        interestPaidSelfTotal: String(interestPaidSelfTotal),
        commissionTotal: String(commissionTotal),
        interestUnPaidSelfTotal: String(interestUnPaidSelfTotal),
      }
    })
    console.log(dataArr);
    this.setState({ employeeArr: dataArr, pawning2Arr, loading: false })
  }

  handleData = async (rowData) => {
    const { pawning2Arr } = this.state
    const employeeId = rowData.objectId;
    const arr2 = pawning2Arr.filter(item => item.jointArr && item.jointArr.indexOf(employeeId) !== -1).map(item => {
      const { jointObjArr, principalRest, interestPlan, interestPaid } = item;
      const jointObj = jointObjArr.find(item2 => item2.objectId === employeeId)

      const { proportion } = jointObj
      const performance = principalRest ? Number(principalRest) * Number(proportion) / 100 : 0;
      const interestPlanSelf = interestPlan ? Number(interestPlan) * Number(proportion) / 100 : 0;
      const interestPaidSelf = interestPaid ? Number(interestPaid) * Number(proportion) / 100 : 0;
      const commission = interestPaidSelf ? Number(interestPaidSelf) * Number(rowData.interest) / 100 : 0;
      const interestUnPaidSelf = interestPlanSelf - interestPaidSelf;
      return { ...item, performance, interestPlanSelf, interestPaidSelf, commission, interestUnPaidSelf }
    })
    this.setState({ rowData, arr2 });
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value }, () => this.handleFetch())
  }

  render() {
    const { employeeArr, rowData, loading, monthSel, mgyearSel, arr2 } = this.state

    return (<div className="content">
      <Row>
        <Col><legend>2-5 佣金</legend></Col>
      </Row>
      <Select name="mgyearSel" style={{ position: 'relative', zIndex: 99, marginRight: '8px' }} options={mgyearOpt} value={mgyearSel} onChange={this.handleChange} />
      <Select name="monthSel" style={{ position: 'relative', zIndex: 99 }} options={monthOpt} value={monthSel} onChange={this.handleChange} />
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearSel}年 ${monthSel}月`),
          pageSize: 5,
          search: false,
          tableLayout: 'fixed',
        }}
        data={employeeArr}
        title={`${mgyearSel}年 ${monthSel}月`}
        actions={[
          {
            icon: 'view_headline',
            tooltip: '查看',
            onClick: (event, rowData) => this.handleData(rowData)
          }
        ]}
        onRowClick={(event, rowData) => this.handleData(rowData)}
      />
      <br />
      <br />
      {rowData.objectId ?
        <MaterialTable
          isLoading={loading}
          localization={localization()}
          columns={columns2()}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearSel}年 ${monthSel}月 ${rowData.name}`),
            pageSize: 10,
            search: false,
            tableLayout: 'fixed',
          }}
          data={arr2}
          title={`${mgyearSel}年 ${monthSel}月 ${rowData.name}`}
        /> : null}
      <br />
    </div>);
  }
}

export default ABaccount;