import React, { Component } from 'react'
import { Modal, Button, Form, Input, Select, TextArea } from 'semantic-ui-react';
import _ from 'lodash';

import delay from '../../../widget/define/delay'

const typeOption = [
  // { key: '0', text: '', value: '0' },
  { key: 'string', text: '文字 String', value: 'string' },
  { key: 'number', text: '数字 Number', value: 'number' },
  { key: 'folder', text: '資料夾 Folder', value: 'folder' }
]

const tfOption = [
  { key: '1', text: 'True', value: true },
  { key: '0', text: 'False', value: false },
]

class OptionSettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {}
    };
  }

  componentWillReceiveProps(nextProps) {   // update的時候用的
    // console.log(nextProps.setting)
    if (nextProps.setting) {
      if (nextProps.setting._id) { // 修正的時候
        this.setState({ form: { ...nextProps.setting, parentFolderId: nextProps.parentFolderObj._id || '', isEnable: true } });
      } else {
        this.setState({ form: { level: (nextProps.setting && nextProps.setting.level) || 0, type: 'string', parentFolderId: nextProps.parentFolderObj._id || '', isEnable: true } });
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.state;
    this.props.handleSet(form);
  }

  handleChange = (e, { name, value }) => this.setState({ form: { ...this.state.form, [name]: value } })

  handleSetKeyName = (e, { name, value }) => {
    e.persist();
    this.setState({ form: { ...this.state.form, key: value } }, () => {
      delay(() => {
        this.setState({ form: { ...this.state.form, key: _.camelCase(value.trim()) } });
      }, 300);
    });
  }

  render() {
    const { open, onHide, setting, optSet, formSet, parentFolderObj, loading, handleDelete } = this.props;
    const { form } = this.state;
    return (<Modal size='small' open={open} onClose={onHide} centered={false}>
      <Modal.Header>{parentFolderObj && parentFolderObj.label ? parentFolderObj.label : ''} {setting && setting.label} {setting && setting._id ? '編集' : '追加'}欄位</Modal.Header>
      <Modal.Content>
        <Form onSubmit={event => this.handleSubmit(event)}>
          <Form.Group widths='equal'>
            {form.type === 'folder' ?
              <Form.Field label='Key Name' name='key' control={Input} fluid value={form.key} onChange={this.handleSetKeyName} /> :
              <Form.Field label='Key Name' name='key' control={Input} disabled fluid value={'option'} />}
            <Form.Field label='是否啟用' name='isEnable' control={Select} options={tfOption} fluid value={form.isEnable} onChange={this.handleChange} />
            <Form.Field label='Type' control={Select} name='type' options={typeOption} placeholder='type' onChange={this.handleChange} value={form.type} />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Field label='Label (日)' name='label' control={Input} fluid value={form.label} placeholder="日文欄位名稱" onChange={this.handleChange} />
            <Form.Field label='Label (中)' name='labelCht' control={Input} fluid value={form.labelCht} placeholder="中文欄位名稱" onChange={this.handleChange} />
            <Form.Field label='Label (英)' name='labelEng' control={Input} fluid value={form.labelEng} placeholder="英文欄位名稱" onChange={this.handleChange} />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Field
              label='Parent Folder'
              name="parentFolderId"
              // disabled={form.type === 'folder' ? true : false}
              value={form.parentFolderId}
              onChange={this.handleChange}
              control={Select}
              options={[{ key: 'root', value: '', text: '[0] root: 根目録' }].concat(optSet.filter(item => (item.type === 'folder')).sort((a, b) => a.key.localeCompare(b.key)).map(({ _id, level, key, label }) => ({ key: _id, value: _id, text: `[${level}] ${key} ${label}` })))} />
            <Form.Field
              label='Print Form'
              name="isPrintForm"
              disabled={form.type === 'string' ? true : false}
              value={form.isPrintForm}
              onChange={this.handleChange}
              control={Select} options={tfOption}
            />
          </Form.Group>
          <Form.Group widths='equal'>
            {form.type === 'number' ? (<Form.Field label='Value' name='value' type="number" control={Input} fluid onChange={this.handleChange} value={form.value} />) : ''}
            {form.type === 'string' ? (<Form.Field label='Value' name='value' type="text" control={Input} fluid onChange={this.handleChange} value={form.value} />) : ''}
            {form.type === 'folder' ? (<Form.Field label='Value' name='value' type="text" control={Input} fluid onChange={this.handleChange} value={form.value} placeholder="" />) : ''}
          </Form.Group>

          <Form.Group widths='equal'>
            <Form.Field label='Note 1' name='note1' control={Input} fluid value={form.note1} placeholder="備用欄位1" onChange={this.handleChange} />
            <Form.Field label='Note 2' name='note2' control={Input} fluid value={form.note2} placeholder="備用欄位2" onChange={this.handleChange} />
          </Form.Group>

          {/* {form.isPrintForm === '1' && form.type !== 'folder' ?
              <Form.Group>
                <Grid.Row>
                  <Grid.Column xs={12} sm={12}>
                    <label>プレビュ—區表格欄位</label>
                    <Select
                      placeholder="表格欄位"
                      value={form.printFormCol}
                      onChange={this.handleChange}
                      options={this.props.optSet.filter(item => (item.key !== 'folder')).sort((a, b) => a.key.localeCompare(b.key))}
                      isMulti
                      isSearchable
                    />
                  </Grid.Column>
                </Grid.Row>
              </Form.Group> : ''}
            {form.isPrintForm !== '1' && form.type !== 'folder' ?
              <Form.Group>
                <Grid.Row>
                  <Grid.Column xs={12} sm={6}>
                    <label>Column Width</label>
                    <input
                      type="number"
                      name="columnWidth"
                      value={form.columnWidth}
                      placeholder="欄位寬度"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Form.Group> : ''} */}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {setting && setting._id ? <Button type="button" floated='left' color='red' onClick={handleDelete} icon='delete' content='削除' /> : null}
        <Button type="button" loading={loading} onClick={this.handleSubmit} primary icon='checkmark' content={setting ? '保存' : '追加'} />
        <Button type="button" onClick={onHide} icon='remove' content='キャンセル' />
      </Modal.Actions>
    </Modal>);
  }
}

export default OptionSettingsModal;
