import React, { Component } from 'react'
import { Container, Icon, Table, Grid, Button, Modal, Header, Input, Label, Select } from 'semantic-ui-react'
import _ from 'lodash'
import { Link } from 'react-router-dom';
import Parse from 'widget/parse'

import MyModalTable from '../../widgets/MyModalTable';
import { getLabelName2 } from 'views/widgets/FieldsRender';
import ModalPdf from 'widget/pdf/ModalPdf';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipse: {
    // boxSizong: 'border-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}

export class CounselingSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      author: '',
      graduates: [],
      yearsemester: `${this.props.semesterYear},${this.props.semester}`
    }
  }
  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { firebase, match, companyObj } = this.props
    // firebase.firestore()
    //   .collectionGroup('Graduate')
    //   // .orderBy('relationship')
    //   // .where('eventDate', '==', eventDate).where('classname', '==', className)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     console.log(data);
    //     this.setState({ graduates: data });
    //   }, err => {
    //     console.log(`Encountered error: ${err}`);
    //   });
    const graduates = await Parse.queryData('Graduate', { companyId: companyObj.objectId })
    this.setState({ graduates })
  }

  handleChange = (event, data) => {
    let fieldName = data.name;
    let fleldVal = data.value;
    this.setState({ [fieldName]: fleldVal });
  }
  render() {
    const { firebase, match, history, formSet, optSet, students, semester, semesterYear } = this.props;
    const { graduates, yearsemester } = this.state;
    const semesterArr = [
      { key: '2019,A', text: '2019A', value: '2019,A' },
      { key: '2019,S', text: '2019S', value: '2019,S' },
      { key: '2018,A', text: '2018A', value: '2018,A' },
      { key: '2018,S', text: '2018S', value: '2018,S' },
      { key: '2017,A', text: '2017A', value: '2017,A' },
      { key: '2017,S', text: '2017S', value: '2017,S' },
    ]
    let year = yearsemester.substring(0, 4);
    let semes = yearsemester.substring(5);
    let graduateEnter = graduates.filter(item => item.isEnter === '1')
    let graStudents = students.filter(item => item.semester === semes && item.semesterYear === year)
    let arrPdf = graStudents.map(item => ({
      ...item,
      targetSchoolText: graduateEnter.find(item2 => item2.studentId === item._id) ? graduateEnter.find(item2 => item2.studentId === item._id).targetSchoolText : null,
      targetSubject: graduateEnter.find(item2 => item2.studentId === item._id) ? graduateEnter.find(item2 => item2.studentId === item._id).targetSubject : null
    }))
    console.log(arrPdf)

    return (
      <>

        <div style={{ ...style.flexCenter }}>
          <Header as='h2' style={{ margin: 0 }}>進路結果一覧</Header>
          <div style={{ ...style.flexCenter }}>

            <Input labelPosition='left' >
              <Label color={'blue'}> <Icon name='file alternate outline' />卒業生入学期</Label>
              <Select style={{
                minWidth: '250px',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0'
              }} compact
                options={semesterArr} onChange={this.handleChange} value={yearsemester} name='yearsemester' />
            </Input>
            <div style={{ ...style.flexCenter, minWidth: 220, marginLeft: 10 }}>
              <ExportTableToExcelButton size='small' />
              <ModalPdf
                {...this.props}
                // ref={(ref) => { this.pdfModal = ref }}
                codekey="counselingSummaryPdf"
                params={arrPdf.sort((a, b) => a.studentNum - b.studentNum)}
              />
            </div>
          </div>
        </div>
        <div style={{ maxWidth: '100vw', overflowX: 'scroll', marginTop: 25 }}>
          <Table id='excel' celled striped size='small' selectable>
            <Table.Header>
              <Table.Row textAlign='center'>
                {/* <Table.Cell></Table.Cell> */}
                <Table.HeaderCell style={style.textEllipse}>卒業(退学)年月日</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>学籍番号</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>日本語氏名</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>進路状況</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>決定進学学校名</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>決定進学科目</Table.HeaderCell>
                {/* <Table.HeaderCell style={style.textEllipse}>合格/不合格</Table.HeaderCell> */}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {graStudents.sort((a, b) => a.studentNum - b.studentNum).map((item, index) => (<Table.Row key={item._id}>
                {/* <Link to={`/TeacherPage/${item._id}`}> */}
                {/* <Table.Cell style={style.textEllipse}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                      <Link to={`/TeacherPage/${item._id}`}><Button inverted={companyObj.inverted} color='yellow' size='small' style={{ margin: 15 }}>詳細</Button></Link>
                      <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} style={{ margin: 15 }}icon='edit' content='修正' />
                    </div>
                  </Table.Cell> */}
                {/* // {console.log(graduateEnter)} */}
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item._id}/0/9`}>{item.graduateDate}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item._id}/0/9`}>{item.studentId}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item._id}/0/9`}>{item.jpnName}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item._id}/0/9`}>{item.wayOutSituationText}</Link></Table.Cell>
                {graduateEnter.find(item2 => item2.studentId === item._id) ? <>
                  <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item._id}/0/9`}>{graduateEnter.find(item2 => item2.studentId === item._id).targetSchoolText}</Link></Table.Cell>
                  <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item._id}/0/9`}>{graduateEnter.find(item2 => item2.studentId === item._id).targetSubject}</Link></Table.Cell></> :
                  <><Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell></>}
                {/* <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item._id}/0/9`}>{item.isPass}</Link></Table.Cell> */}
                {/* </Link> */}
              </Table.Row>))}
            </Table.Body>
          </Table>
        </div>

        {/* <Modal open={this.state.show} onClose={this.handleClose} size='tiny'>
          <Modal.Header style={style.flexCenter}>
            <h2 style={{ margin: 0 }}> {form._id ? form.teacherTitleText + ' ' + form.nameTeacher + ' 教師' : '教師'}</h2>
            <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
          </Modal.Header>
          <Modal.Content>
            <Grid>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>状態</label>
                  <Form.Select
                    options={isWorkArrModal}
                    defaultValue={form._id ? form.staffStatus : ' '}
                    onChange={this.handleChange}
                    name='staffStatus'
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>教員番号</label>
                  <Form.Input
                    style={{ minWidth: 196 }}
                    name='teacherNum'
                    value={form.teacherNum ? form.teacherNum : ' '}
                    onChange={this.handleChange}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>氏名 (簡稱)</label>
                  <Form.Input
                    style={{ minWidth: 196 }}
                    name='nameTeacher'
                    value={form.nameTeacher ? form.nameTeacher : ''}
                    onChange={this.handleChange}
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>氏名 (全名)</label>
                  <Form.Input
                    style={{ minWidth: 196 }}
                    name='nameTeacherFull'
                    value={form.nameTeacherFull || ''}
                    onChange={this.handleChange}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>職名</label>
                  <Form.Select
                    options={staffNameArr}
                    defaultValue={form._id ? form.teacherTitle : ' '}
                    onChange={this.handleChange}
                    name='teacherTitle'
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>専任/非専任</label>
                  <Form.Select
                    options={staffProArr}
                    defaultValue={form._id ? form.partTimeTeacher : ' '}
                    onChange={this.handleChange}
                    name='partTimeTeacher'
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>就職学期</label>
                  <Form.Select
                    options={semesterArrStart}
                    onChange={this.handleChange}
                    name='startSemesterNum'
                    defaultValue={form.startSemester || ' '}
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>離職学期</label>
                  <Form.Select
                    options={semesterArrEnd}
                    onChange={this.handleChange}
                    name='endSemesterNum'
                    defaultValue={form.endSemester || ' '}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Content style={{ borderTop: '1px solid rgba(34,36,38,.15)', display: 'flex', justifyContent: 'flex-end' }}>
            <Button size='small' onClick={this.handleSubmit} inverted={companyObj.inverted} color='green' loading={loading} icon='checkmark' content={form._id ? '保存' : '追加'} />
            <Button size='small' onClick={this.handleClose} basic>閉じる </Button>
          </Modal.Content>
        </Modal> */}
        {/* <Confirm
          header='削除確認'
          content='削除確認?'
          cancelButton='閉じる'
          confirmButton='削除'
          open={this.state.confirm}
          onCancel={this.hideConfirm}
          onConfirm={this.handleRemove}
          size='mini'
          centered={false} /> */}
      </>
    )
  }
}
export default CounselingSummary