import React, { Component } from 'react'
// import { Container, Header, Modal, Button, Table, Form, Grid, Image, Input, Checkbox } from 'semantic-ui-react';
// import Moment from 'moment';
import MaterialTable from 'material-table'
import { Select, Input, MenuItem } from '@material-ui/core';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ModalNamelist from './ModalNamelist'
import ModalNamelist3 from './ModalNamelist3'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

const columns2 = (webtypeTemple, category, people1 = [], people0 = [], praytype = {}, handleMultiAdd, isPrayCombine = false, initPraytypeId = '', praying1Type = '', praytypeArr) => {
  let cols = [];
  if (webtypeTemple) {
    cols = [
      { width: 60, title: '牌位種類', field: 'praytypeId', lookup: praytype, initialEditValue: initPraytypeId },
      { width: 60, title: '外加價錢', field: 'rowTotal', type: 'numeric' },
      {
        width: 60, title: '選擇', field: 'live_select',
        headerStyle: { textAlign: 'center' },
        render: rowData => <></>,
        editComponent: props => <ModalNamelist rowData={props.rowData} people1={people1} isLive='1' onRowDataChange={props.onRowDataChange} handleMultiAdd={handleMultiAdd} />
      },
      { width: 160, title: '陽上', field: 'livename' },
      {
        width: 60, title: '選擇', field: 'pass_select',
        headerStyle: { textAlign: 'center' },
        render: rowData => <></>,
        editComponent: props => !isPrayCombine ?
          <ModalNamelist rowData={props.rowData} people1={people1} people0={people0} cardslot='1' isLive='0' onRowDataChange={props.onRowDataChange} /> :
          <ModalNamelist3 rowData={props.rowData} people1={people1} people0={people0} onRowDataChange={props.onRowDataChange} />
      },
      { width: 60, title: '拔', field: 'passname' },
      { width: 60, title: '拔 歷代祖先', field: 'passname1' },
      { width: 60, title: '拔 冤親債主', field: 'passname2' },
      { width: 160, title: '拔 地基主', field: 'passname3' },
      { width: 60, title: '拔 嬰靈', field: 'passname4' },
      { width: 120, title: '地址', field: 'addr', hidden: isPrayCombine },
      { width: 120, title: '建立時間', editable: 'never', field: 'createdAt', type: 'datetime', hidden: isPrayCombine },
      { width: 120, title: '修改時間', editable: 'never', field: 'updatedAt', type: 'datetime', hidden: isPrayCombine },
    ]
  } else {
    if (praying1Type === '1') {
      cols = [
        { title: '申報項目', field: 'praytypeId', lookup: praytype },
        { title: '箱數\n桌數', field: 'box_num', type: 'numeric' },
        { title: '小計', field: 'rowTotal', type: 'numeric' },
        {
          title: '選擇', field: 'live_select',
          headerStyle: { textAlign: 'center' },
          render: rowData => <></>,
          editComponent: props => <ModalNamelist rowData={props.rowData} people1={people1} isLive='1' onRowDataChange={props.onRowDataChange} handleMultiAdd={handleMultiAdd} />
        },
        { title: '財寶箱名', field: 'boxText' },
        { title: '幾名', field: 'people_num' },
        {
          title: '超度', field: 'pass_select',
          headerStyle: { textAlign: 'center' },
          render: rowData => <></>,
          editComponent: props => <ModalNamelist rowData={props.rowData} people1={people1} people0={people0} isLive='0' onRowDataChange={props.onRowDataChange} />
        },
        { title: '關係', field: 'relation_text' },
        { title: '姓1(公/媽)', field: 'lastname1_text' },
        { title: '姓2(氏)', field: 'lastname2_text' },
        { title: '名字', field: 'passname_text' },
        { title: '姓別', field: 'passname_gender_id' },
        { title: '生于 農曆(年月日時)', field: 'birth_day' },
        { title: '卒于 農曆(年月日時)', field: 'pass_day' },
        { title: '陽壽', field: 'live_year' },
        { title: '地址', field: 'addr' },
        { width: 120, title: '建立時間', editable: 'never', field: 'createdAt', type: 'datetime' },
        { width: 120, title: '修改時間', editable: 'never', field: 'updatedAt', type: 'datetime' },
      ]
    } else {
      cols = [
        {
          title: '申報項目(工本費)', field: 'praytypeId', lookup: praytype,
          render: rowData => rowData.praytypeText,
          editComponent: props => {
            return <Select
              value={(props.rowData.praytypeId !== '1' && props.rowData.praytypeId) || initPraytypeId}
              // value={props.rowData.praytypeId || initPraytypeId}
              onChange={e => {
                const praytypeId = e.target.value;
                const praytypeObj = praytypeArr.find(item => item.objectId === praytypeId)
                // const praytypeText = pray2Obj.value;
                // const pray2OrderId = pray2Obj.order;
                // const accReceivable = pray2Obj.price;
                props.onRowDataChange({ ...props.rowData, praytypeId, rowTotal: praytypeObj.price })
              }}>
              {praytypeArr.map((item, index) => <MenuItem key={'a' + item.objectId + index} value={item.objectId}>{item.value}</MenuItem>)}
            </Select>
          },
          initialEditValue: '1'
        },
        {
          title: '小計', field: 'rowTotal',
          render: rowData => rowData.rowTotal,
          editComponent: props => {
            const praytypeObj = praytypeArr.find(item => item.objectId === initPraytypeId)
            // props.onRowDataChange({ ...props.rowData, rowTotal: praytypeObj.price })
            // return praytypeObj.price
            return <Input value={props.rowData.rowTotal || praytypeObj.price} onChange={e => { props.onRowDataChange({ ...props.rowData, rowTotal: e.target.value || '0' }) }} />
          },
        },
        {
          title: '選擇', field: 'live_select',
          headerStyle: { textAlign: 'center' },
          render: rowData => <></>,
          editComponent: props => <ModalNamelist rowData={props.rowData} people1={people1} isLive='1' onRowDataChange={props.onRowDataChange} handleMultiAdd={handleMultiAdd} />
        },
        { title: '善信', field: 'livename' },
        { title: '生于 農曆(年月日時)', field: 'birth_day' },
        { title: '卒于 農曆(年月日時)', field: 'pass_day' },
        { title: '歲數', field: 'live_year' },
        { title: '地址', field: 'addr' },
        { width: 120, title: '建立時間', editable: 'never', field: 'createdAt', type: 'datetime' },
        { width: 120, title: '修改時間', editable: 'never', field: 'updatedAt', type: 'datetime' },
      ]
    }
  };
  return cols;
}

export default class ModalPraying2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data2: props.data2,
      newData: {},
      oldData: {},
    }
    this.tableRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    // this.handleParams(nextProps);
    this.setState({ data2: nextProps.data2 });
  }

  handleUpdate = async () => {
    const { people0, people1 } = this.props
    const { newData } = this.state
    // console.log(newData)
    const obj = {
      ...newData,
      rowTotal: Number(newData.rowTotal) || 0,
    };

    if (newData.livename && newData.livename.indexOf(',') !== -1) {
      let livenameArr = newData.livename.split(',');
      let livenameData = []
      if (livenameArr && livenameArr.length) {
        livenameArr.forEach(item => {
          people1.forEach(item1 => {
            if (item1.name === item) {
              livenameData.push(item1)
            }
          })
        })
        obj.people1 = livenameData
      }
    }
    if (newData.passname) {
      let passnameArr = newData.passname.split(',')
      let passnameData = []
      if (passnameArr && passnameArr.length) {
        passnameArr.forEach(item => {
          people0.forEach(item1 => {
            if (item1.name === item) {
              passnameData.push(item1)
            }
          })
        })
        obj.people0 = passnameData
      }
    }
    delete obj.pray2_orderid
    delete obj.accReceivable
    delete obj.accReceived
    delete obj.accPending
    delete obj.createdAt
    delete obj.updatedAt
    // console.log(obj)
    await Parse.saveData('praying2', obj);
  }

  handleDelete = async () => {
    // const { companyObj, familyId, praying1Obj } = this.props
    const { oldData } = this.state
    await Parse.deleteData('praying2', oldData);
    this.setState({ oldData: {} })
  }

  handleMultiAdd = async (rowData, nameArr) => {
    if (!rowData.praytypeId) {
      window.alert('請先選牌位種類')
      return;
    }
    const { history, familyId, category, selectData, people1, pray2Obj, praying1Obj } = this.props
    const { praytypeObj } = selectData;

    const obj = {
      ...praying1Obj,
      praying1Id: praying1Obj.objectId,
      ...rowData,
      rowTotal: Number(rowData.rowTotal) || 0,
    };

    if (!!obj.praytypeId && typeof obj.praytypeId !== 'undefined') {
      obj.praytypeText = praytypeObj.sel[obj.praytypeId];
    }
    if (!!obj.pray2Id) {
      obj.pray2Text = pray2Obj.arr.find(item => item.objectId === obj.pray2Id).value;
    }

    // console.log(rowData, nameArr)
    const promises = [];
    // const data2 = [...this.state.data2];
    nameArr.forEach(async (item) => {
      const p = people1.find(item2 => item2.objectId === item);
      const db = Parse.Object.extend("praying2");
      const newDoc = new db();
      const doc = await newDoc.save();
      const obj2 = {
        ...obj,
        objectId: doc.id,
        _id: doc.id,
        id: doc.id,
        livename: p.name || '',
        addr: p.addr || '',
      }

      delete obj2.pray2_orderid
      delete obj2.accReceivable
      delete obj2.accReceived
      delete obj2.accPending
      delete obj2.createdAt
      delete obj2.updatedAt
      promises.push(Parse.saveData('praying2', obj2));
      // data2.push(obj2)
    });

    // setData2(data2)
    await Promise.all(promises).catch(error => console.error(error));
    // this.setState({ data2 });
    // console.log(data2)
    history.push(`/admin/Family/${familyId}/${category}/${praying1Obj.objectId}`);

    // this.tableRef.current && this.tableRef.current.onQueryChange();
  }

  render() {
    const { people0, people1, isLoading, praying1Obj, familyId, companyObj, category, selectData, pray2Obj = {}, type, praying1Type } = this.props;
    const { data2 } = this.state
    const { pray1Obj = {}, praytypeObj = {} } = selectData
    const { objectId, ordernum = '', pray1Text = '', pray2Text = '', serial = '', fieldTypeId = '' } = praying1Obj;
    // const pray1 = pray1Obj.arr || []
    // console.log(data2)
    const pray2 = pray2Obj.arr || []
    let praytype = []
    if (companyObj.webtypeId === 'temple') {
      praytype = praytypeObj.arr.filter(item => item.field_type === type) || []
    } else {
      praytype = praytypeObj.arr.filter(item => item.field_type === fieldTypeId) || []
    }
    let ptype = {};
    praytype.sort((a, b) => a.order - b.order).forEach(item => {
      if (!item.only_pray) {
        ptype = { ...ptype, [item.objectId]: item.value };
      } else if (item.only_pray === praying1Obj.pray1Id) {
        ptype = { ...ptype, [item.objectId]: `[專用] ` + item.value };
      }
    });
    let nowPray2 = {}
    if (companyObj.webtypeId === 'temple') {
      nowPray2 = pray2.find(item => item.objectId === praying1Obj.pray2Id);
    } else {
      nowPray2 = pray2.find(item => String(item.order) === praying1Obj.fieldTypeId);
    }
    const initPraytypeId = (nowPray2 && nowPray2.defPraytypeId) || '';
    return (<MaterialTable
      isLoading={isLoading}
      localization={localization()}
      columns={columns2(companyObj.webtypeId === "temple", category, people1, people0, ptype, this.handleMultiAdd, companyObj.isPrayCombine, initPraytypeId, praying1Type, praytype)}
      options={{
        addRowPosition: "first",
        exportButton: true,
        exportPdf: (columns, data) => exportPdf(columns, data, `服務內容：${ordernum} ${pray1Text} ${pray2Text} #${serial}`),
        pageSize: data2.length < 5 ? 5 : data2.length + 1,
        search: false,
        tableLayout: 'fixed',
      }}
      data={data2}
      tableRef={this.tableRef}
      title={`服務內容：${ordernum} ${pray1Text} ${pray2Text} #${serial}`}
      editable={{
        onRowAdd: newData =>
          new Promise(async (resolve, reject) => {
            const data = [...data2];
            const db = Parse.Object.extend("praying2");
            const newDoc = new db();
            const doc = await newDoc.save();

            const obj = {
              ...praying1Obj,
              _id: doc.id,
              id: doc.id,
              objectId: doc.id,
              praying1Id: objectId,
              companyId: companyObj.objectId,
              category,
              ...newData,
            };
            delete obj.accPending;
            delete obj.accReceivable;
            delete obj.accReceived;

            if (!!obj.praytypeId && typeof obj.praytypeId !== 'undefined') {
              // obj.pray1Text = pray1.find(item => item.id === newData1.pray1Id).value;
              obj.praytypeText = ptype[obj.praytypeId];
            }
            if (!!newData.pray2Id) {
              obj.pray2Text = pray2.find(item => item.objectId === obj.pray2Id).value;
              obj.pray2OrderId = pray2.find(item => item.objectId === obj.pray2Id).order;
            }

            data.push(obj);
            this.setState({ data2: data, newData: obj }, () => resolve());
          }).then(() => this.handleUpdate()),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const data = [...data2];
            const index = data.indexOf(oldData);
            const obj = { ...newData }

            if (!!obj.praytypeId && typeof obj.praytypeId !== 'undefined') {
              // obj.pray1Text = pray1.find(item => item.id === newData1.pray1Id).value;
              obj.praytypeText = ptype[obj.praytypeId];
            }
            if (!!newData.pray2Id) {
              obj.pray2Text = pray2.find(item => item.objectId === obj.pray2Id).value;
              obj.pray2OrderId = pray2.find(item => item.objectId === obj.pray2Id).order;
            }

            data[index] = obj;
            this.setState({ data2: data, newData: obj, oldData }, () => resolve());
          }).then(() => this.handleUpdate()),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            let data = [...data2];
            const index = data.indexOf(oldData);
            data.splice(index, 1);
            this.setState({ data2: data, oldData }, () => resolve());
          }).then(() => this.handleDelete()),
      }}
    />)
  }
}