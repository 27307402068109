import React from 'react'

export const WebComponentOpt = [
  { id: 'PageHeader', value: '封面', showImage: true, showTitle1: true, textNum: 1 },
  { id: 'Common0', value: '版', showImage: true, showEditor: true, },
  { id: 'AlbumTest', value: '相簿', showImage: true },
  { id: 'QuickTest', value: '快速測驗' },
  {
    id: 'Test', value: '測驗',
    textNum: 2,
    showTable: true,
    columns: { showFile1: true, showAlign: true, showTitle1: true, showContent1: true }
  },
  { id: 'FileDownload', value: '檔案下載' },
  { id: 'Cooperation', value: '方案' },
  { id: 'RecordInquire', value: '紀錄查詢' },
  { id: 'TransactionRecord', value: '交易紀錄' },
  { id: 'Toeic', value: '多益' },
  { id: 'Toeic1', value: '多益1' },
  {
    id: 'Slider', value: 'Slider',
    showTable: true, textNum: 2,
    columns: { showFile1: true, showAlign: true, showTitle1: true, showContent1: true }
  },
  { id: 'Header1', value: 'Header 1' },
  { id: 'Header2', value: 'Header 2' },
  {
    id: 'Header3', value: 'Header 3',
    showTable: true,
    columns: { showFile1: true, showAlign: true, showTitle1: true, showContent1: true }
  },
  { id: 'Header4', value: 'Header 4' },
  {
    id: 'Header5', value: 'Header 5',
    showTable: true,
    columns: { showFile1: true, showAlign: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Feature1', value: 'Feature 1', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true, showUrl: true }
  },
  {
    id: 'Feature2', value: 'Feature 2', textNum: 2,
    showTable: true, showImage: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Feature3', value: 'Feature 3', textNum: 1,
    showTable: true, showImage: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Feature4', value: 'Feature 4', textNum: 3,
    showImage: true, showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Feature5', value: 'Feature 5',
    showImage: true, textNum: 1, showTable: true,
    columns: { showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Blog1', value: 'Blog 1', textNum: 1,
    showTable: true,
    columns: { showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Blog2', value: 'Blog 2', textNum: 1,
    showTable: true,
    columns: { showIcon: true, showTitle1: true, showContent1: true }
  },
  { id: 'Blog3', value: 'Blog 3', showImage: true },
  {
    id: 'Blog4', value: 'Blog 4', textNum: 1,
    showTable: true,
    columns: { showFile1: true, showTitle1: true, showContent1: true, showUrl: true }
  },
  { id: 'Blog5', value: 'Blog 5' },
  {
    id: 'Blog6', value: 'Blog 6', textNum: 1,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Team1', value: 'Team 1', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Team2', value: 'Team 2', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Team3', value: 'Team 3', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Team4', value: 'Team 4', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Team5', value: 'Team 5', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Project1', value: 'Project 1', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Project2', value: 'Project 2', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Project3', value: 'Project 3', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Project4', value: 'Project 4', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Pricing1', value: 'Pricing 1', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Pricing2', value: 'Pricing 2', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Pricing3', value: 'Pricing 3', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Pricing4', value: 'Pricing 4', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Pricing5', value: 'Pricing 5', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Testimonials1', value: 'Testimonials 1', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Testimonials2', value: 'Testimonials 2', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Testimonials3', value: 'Testimonials 3', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'ContactUs1', value: 'ContactUs 1', textNum: 2,
    showTable: true,
    columns: { showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'ContactUs2', value: 'ContactUs 2', textNum: 2
  },
  {
    id: 'FooterBlack', value: 'FooterBlack', textNum: 1,
    showTable: true,
    columns: { showTitle1: true, showUrl: true }
  },
  {
    id: 'Footer1', value: 'Footer1', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Footer2', value: 'Footer2', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Footer3', value: 'Footer3', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Footer4', value: 'Footer4', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Footer5', value: 'Footer5', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Footer6', value: 'Footer6', textNum: 2,
    showTable: true,
    columns: { showFile1: true, showIcon: true, showTitle1: true, showContent1: true }
  },
  {
    id: 'Footer7', value: 'Footer7', textNum: 2,
    showImage: true
  },
  {
    id: 'SectionTable', value: 'SectionTable', textNum: 2,
    showImage: true
  },
  { id: 'SectionCourse', value: 'SectionCourse' },
  { id: 'SectionYearPlan', value: 'SectionYearPlan' },
  { id: 'FloorMaps', value: 'FloorMaps' },
  { id: 'StartUp', value: 'StartUp' },
  { id: 'StartUp1', value: 'StartUp1' },
  { id: 'StartUp2', value: 'StartUp2' },
  { id: 'StartUp3', value: 'StartUp3' },
  { id: 'StartUp4', value: 'StartUp4' },
  { id: 'StartUp1Edit', value: 'StartUp1Edit' },
  { id: 'StartUpEdit', value: 'StartUpEdit' },
]
