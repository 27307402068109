// import React from 'react'
import { getCookie } from 'tiny-cookie'

const localizationSel = {
  name: {
    body: {
      emptyDataSourceMessage: '沒有找到記錄',
      addTooltip: "新增",
      deleteTooltip: "刪除",
      editTooltip: "編輯",
      filterRow: {
        filterTooltip: "過濾器"
      },
      editRow: {
        deleteText: "確定要刪除此項目嗎？",
        cancelTooltip: "取消",
        saveTooltip: "儲存"
      }
    },
    grouping: {
      placeholder: "將標題拖到此處以將它們組合在一起"
    },
    header: {
      actions: "操作"
    },
    pagination: {
      labelDisplayedRows: "{from}-{to} 共{count}筆",
      labelRowsSelect: "筆/每頁",
      labelRowsPerPage: "筆/每頁",
      firstAriaLabel: "首頁",
      firstTooltip: "首頁",
      previousAriaLabel: "上一頁",
      previousTooltip: "上一頁",
      nextAriaLabel: "下一頁",
      nextTooltip: "下一頁",
      lastAriaLabel: "最後一頁",
      lastTooltip: "最後一頁"
    },
    toolbar: {
      addRemoveColumns: "新增或刪除列",
      nRowsSelected: "已選擇{0}行",
      showColumnsTitle: "顯示列",
      showColumnsAriaLabel: "顯示列",
      exportTitle: "導出",
      exportAriaLabel: "導出",
      exportCSVName: "導出為CSV",
      exportPDFName: "導出為PDF",
      clearSearchAriaLabel: "清除搜索",
      searchTooltip: "搜索",
      searchPlaceholder: "搜索"
    }
  },
  zhHant: {
    body: {
      emptyDataSourceMessage: "没有找到匹配的记录",
      addTooltip: "添加",
      deleteTooltip: "删除",
      editTooltip: "编辑",
      filterRow: {
        filterTooltip: "过滤器"
      },
      editRow: {
        deleteText: "确定要删除此项目吗？",
        cancelTooltip: "取消",
        saveTooltip: "保存"
      }
    },
    grouping: {
      placeholder: "将标题拖到此处以将它们组合在一起"
    },
    header: {
      actions: "动作"
    },
    pagination: {
      labelDisplayedRows: "{from}到{to}，共{count}",
      labelRowsSelect: "每页结果",
      labelRowsPerPage: "每页结果",
      firstAriaLabel: "首页",
      firstTooltip: "首页",
      previousAriaLabel: "上一页",
      previousTooltip: "上一页",
      nextAriaLabel: "下一页",
      nextTooltip: "下一页",
      lastAriaLabel: "最后一页",
      lastTooltip: "最后一页"
    },
    toolbar: {
      addRemoveColumns: "添加或删除列",
      nRowsSelected: "已选择{0}行",
      showColumnsTitle: "显示列",
      showColumnsAriaLabel: "显示列",
      exportTitle: "导出",
      exportAriaLabel: "导出",
      exportCSVName: "导出为CSV",
      exportPDFName: "导出为PDF",
      clearSearchAriaLabel: "清除搜索",
      searchTooltip: "搜索",
      searchPlaceholder: "搜索"
    }
  },
  ja: {
    body: {
      emptyDataSourceMessage: "申し訳ありませんが、一致するレコードは見つかりませんでした",
      addTooltip: "追加",
      deleteTooltip: "削除",
      editTooltip: "編集",
      filterRow: {
        filterTooltip: "フィルター"
      },
      editRow: {
        deleteText: "このアイテムを削除してもよろしいですか？",
        cancelTooltip: "キャンセル",
        saveTooltip: "保存"
      }
    },
    grouping: {
      placeholder: "ヘッダーをここにドラッグしてグループ化します"
    },
    header: {
      actions: 'アクション'
    },
    pagination: {
      labelDisplayedRows: "{from} to {to} of {count}",
      labelRowsSelect: "ページごとの結果",
      labelRowsPerPage: "ページごとの結果",
      firstAriaLabel: "最初のページ",
      firstTooltip: "最初のページ",
      previousAriaLabel: "前のページ",
      previousTooltip: "前のページ",
      nextAriaLabel: "次のページ",
      nextTooltip: "次のページ",
      lastAriaLabel: "最終ページ",
      lastTooltip: "最終ページ"
    },
    toolbar: {
      addRemoveColumns: "列の追加または削除",
      nRowsSelected: "{0}行が選択されました",
      showColumnsTitle: "列を表示",
      showColumnsAriaLabel: "列を表示",
      exportTitle: "エクスポート",
      exportAriaLabel: "エクスポート",
      exportName: "CSVにエクスポート",
      exportCSVName: "CSVにエクスポート",
      exportPDFName: "PDFにエクスポート",
      clearSearchAriaLabel: "清除搜索",
      searchTooltip: "検索",
      searchPlaceholder: "検索"
    }
  },
  vi: {
    body: {
      emptyDataSourceMessage: "Xin lỗi, không có hồ sơ phù hợp được tìm thấy",
      addTooltip: "Thêm",
      deleteTooltip: "Xóa",
      editTooltip: "Chỉnh sửa",
      filterRow: {
        filterTooltip: "Bộ lọc"
      },
      editRow: {
        deleteText: "Bạn có chắc chắn muốn xóa mục này?",
        cancelTooltip: "Hủy",
        saveTooltip: "Lưu"
      }
    },
    grouping: {
      placeholder: "Kéo các tiêu đề ở đây để nhóm chúng lại với nhau"
    },
    header: {
      actions: "Hành động"
    },
    pagination: {
      labelDisplayedRows: " {từ} đến {đến} của {Count}",
      labelRowsSelect: "kết quả trên mỗi trang",
      labelRowsPerPage: "kết quả trên mỗi trang",
      firstAriaLabel: "Trang đầu tiên",
      firstTooltip: "Trang đầu tiên",
      previousAriaLabel: "Trang trước",
      previousTooltip: "Trang trước",
      nextAriaLabel: "Trang tiếp theo",
      nextTooltip: "Trang tiếp theo",
      lastAriaLabel: "Trang cuối",
      lastTooltip: "Trang cuối"
    },
    toolbar: {
      addRemoveColumns: "Thêm hoặc xóa cột",
      nRowsSelected: "{0} hàng đã chọn",
      showColumnsTitle: "Hiển thị cột",
      showColumnsAriaLabel: "Hiển thị cột",
      exportTitle: "Xuất khẩu",
      exportAriaLabel: "Xuất khẩu",
      exportCSVName: "Xuất sang CSV",
      exportPDFName: "Xuất sang PDF",
      // clearSearchAriaLabel: """,
      searchTooltip: "Tìm kiếm",
      searchPlaceholder: "Tìm kiếm"
    }
  }
};

const Localization = () => {
  const dbLang = getCookie('dbLang') || 'name';
  // console.log(getCookie('dbLang'))
  return localizationSel[dbLang]
}

export default Localization;
