import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react';
// import Moment from 'moment';
import MaterialTable, { MTableToolbar } from 'material-table'
import { Row, Col, Form, FormGroup, Input, Label, Button } from "reactstrap";
// import { Select, MenuItem } from '@material-ui/core';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
// import ModalNamelist from './ModalNamelist'
// import ModalNamelist3 from './ModalNamelist3'
import { dateCol, typeCurrency, exportPdf } from 'views/web/CustomMaterialTable';
import ModalMultiInput from 'views/web/ModalMultiInput';

// 1 月報表
// 結清日期	編號	借款日期	合件	姓名	主	 金額 	車號	 部份還本 	 結餘金額 	備註	身分證號碼	手機號	保人手機號
const columns2 = () => ([
  { width: 80, title: '計劃繳息日期', field: 'planDate', editable: 'never' },
  { width: 80, title: '本金結餘', field: 'principalRest', editable: 'never', ...typeCurrency },
  { width: 80, title: '應收利息', field: 'interestPlan', editable: 'never', ...typeCurrency },
  dateCol({ title: '還本日期', field: 'principalPaidDate' }),
  { width: 80, title: '本金還本', field: 'principalPaid', headerStyle: { textAlign: 'left' }, ...typeCurrency },
  dateCol({ title: '繳息日期', field: 'interestPaidDate' }),
  { width: 80, title: '繳息金額', field: 'interestPaid', headerStyle: { textAlign: 'left' }, ...typeCurrency },
  { width: 180, title: '備註', field: 'ps' },
]);

export default class ClientTab3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data1: [],
      data2: [],
      newData: {},
      newData1: {},
      oldData: {},
      oldData1: {},
      loading: true,
      classObj: {},
      verArr: []
    }
    this.tableRef = React.createRef();
  }

  componentDidMount = async () => {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { match, companyObj, rowData } = props;
    const { clientId } = match.params;
    const { classObj } = this.state
    if (!clientId) {
      return;
    }
    // const companyId = companyObj.objectId;
    this.setState({ loading: true })
    const data1 = await Parse.queryData('User', { companyId: companyObj.objectId, schoolId: rowData.objectId, type: 'class' });
    const testBank = await Parse.queryData('testBank');
    const verArr = []
    testBank.forEach(item => {
      verArr.push({ key: item.objectId, value: item.objectId, text: item.version })
    })
    this.setState({ data1, verArr, loading: false });
  }

  handleAdd = async (type) => {
    const { newData, newData1 } = this.state;
    if (type === '1') {
      await Parse.saveData('User', newData);
    } else {
      await Parse.saveData('User', newData1);
    }
    this.handleData()
  }

  handleUpdate = async (type) => {
    const { newData, newData1 } = this.state;
    if (type === '1') {
      await Parse.saveData('User', newData);
    } else {
      await Parse.saveData('User', newData1);
    }
  }

  handleDelete = async (type) => {
    const { oldData, oldData1 } = this.state
    if (type === '1') {
      await Parse.deleteData('User', oldData);
      this.setState({ oldData: {} })
    } else {
      await Parse.deleteData('User', oldData1);
      this.setState({ oldData1: {} })
    }

  }

  handleData = async (rowData) => {
    const { companyObj } = this.props
    const data2 = await Parse.queryData('User', { companyId: companyObj.objectId, classId: rowData.objectId });
    this.setState({ data2, classObj: rowData })
  }
  render() {
    const { rowData, companyObj } = this.props
    const { data1, data2, loading, classObj, verArr } = this.state
    return (<>
      <Row>
        <Col md='6'>
          <MaterialTable
            // isLoading={loadingData}
            localization={localization()}
            // columns={columns()}
            columns={[
              // { width: 120, title: '系所', field: 'department' },
              // { width: 100, title: '學年度', field: 'schoolYear' },
              // { width: 100, title: '課程', field: 'course' },
              // { width: 100, title: '班級', field: 'class' },
              // {
              //   width: 150, title: '權限', field: 'teacherAuth',
              //   render: rowData => <>
              //     <Dropdown placeholder='權限' multiple selection options={userArr} defaultValue={rowData.teacher} style={{ zIndex: 99 }} disabled />
              //   </>,
              //   editComponent: props => <>
              //     <Dropdown placeholder='權限' multiple selection options={userArr} onChange={(e, data) => props.onRowDataChange({ ...props.rowData, teacher: data.value })} style={{ zIndex: 99 }} defaultValue={props.rowData.teacher} />
              //   </>,
              // },
              { width: 80, title: '班級名稱', field: 'name' },
              { width: 80, title: '班級號碼', field: 'number' },
              { width: 80, title: '備註', field: 'ps' },
            ]} options={{
              addRowPosition: "first",
              exportButton: true,
              exportPdf: (columns, data) => exportPdf(columns, data, `${rowData.name_cht} 班級管理`),
              pageSize: 10,
              search: false,
              tableLayout: 'fixed'
            }}
            components={{
              Toolbar: props => (<div>
                <div style={{ float: 'right', textAlign: 'right', padding: '22px 16px 22px 4px' }}>
                  <ModalMultiInput {...this.props} handleData={this.handleFetch} columns={props.columns} condition={{ companyId: companyObj.objectId, companyName: companyObj.name, schoolId: rowData.objectId, schoolName: rowData.name_cht }} dbName='class' />
                </div>
                <MTableToolbar {...props} />
                <div style={{ clear: 'both' }}></div>
              </div>),
            }}
            data={data1}
            title={`${rowData.name_cht} 班級管理`}
            actions={[
              {
                icon: 'view_headline',
                tooltip: '查看',
                onClick: (event, rowData) => this.handleData(rowData)
              }
            ]}
            editable={{
              onRowAdd: newData =>
                new Promise(async (resolve, reject) => {
                  const data = [...data1];
                  const db = Parse.Object.extend("User");
                  const newDoc = new db();
                  const doc = await newDoc.save();
                  // newData.objectId = doc.id;
                  // newData.id = doc.id;
                  // newData._id = doc.id;
                  let obj = {
                    ...newData,
                    objectId: doc.id,
                    id: doc.id,
                    _id: doc.id,
                    companyId: companyObj.objectId,
                    companyName: companyObj.name,
                    schoolId: rowData.objectId,
                    schoolName: rowData.name_cht,
                    type: 'class'
                  }
                  data.push(obj);
                  this.setState({ data1: data, newData: obj }, () => resolve());
                }).then(() => this.handleAdd('1')),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const data = [...data1];
                  const index = data.indexOf(oldData);
                  data[index] = newData;
                  // handleState('people0', data);
                  this.setState({ data1: data, newData, oldData }, () => resolve());
                }).then(() => this.handleUpdate('1')),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  let data = [...data1];
                  const index = data.indexOf(oldData);
                  data.splice(index, 1);
                  // handleState('people0', data);
                  this.setState({ data1: data, oldData }, () => resolve());
                }).then(() => this.handleDelete('1')),
            }}
          />
        </Col>
        <Col md='6'>
          {classObj && classObj.objectId ? <MaterialTable
            // isLoading={loadingData}
            localization={localization()}
            // columns={columns()}
            columns={[
              // { width: 120, title: '系所', field: 'department' },
              // { width: 100, title: '學年度', field: 'schoolYear' },
              // { width: 100, title: '課程', field: 'course' },
              // { width: 100, title: '班級', field: 'class' },
              // {
              //   width: 150, title: '權限', field: 'teacherAuth',
              //   render: rowData => <>
              //     <Dropdown placeholder='權限' multiple selection options={userArr} defaultValue={rowData.teacher} style={{ zIndex: 99 }} disabled />
              //   </>,
              //   editComponent: props => <>
              //     <Dropdown placeholder='權限' multiple selection options={userArr} onChange={(e, data) => props.onRowDataChange({ ...props.rowData, teacher: data.value })} style={{ zIndex: 99 }} defaultValue={props.rowData.teacher} />
              //   </>,
              // },
              { width: 80, title: '學生名稱', field: 'name' },
              { width: 80, title: '學生號碼', field: 'number' },
              {
                width: 150, title: '權限', field: 'teacherAuth',
                render: rowData => <>
                  <Dropdown placeholder='權限' multiple selection options={verArr} defaultValue={rowData.teacher} style={{ zIndex: 99 }} disabled />
                </>,
                editComponent: props => <>
                  <Dropdown placeholder='權限' multiple selection options={verArr} onChange={(e, data) => props.onRowDataChange({ ...props.rowData, teacher: data.value })} style={{ zIndex: 99 }} defaultValue={props.rowData.teacher} />
                </>,
              },
              { width: 80, title: '備註', field: 'ps' },
            ]} options={{
              addRowPosition: "first",
              exportButton: true,
              exportPdf: (columns, data) => exportPdf(columns, data, `${rowData.name_cht} 學生管理`),
              pageSize: 10,
              search: false,
              tableLayout: 'fixed'
            }}
            components={{
              Toolbar: props => (<div>
                <div style={{ float: 'right', textAlign: 'right', padding: '22px 16px 22px 4px' }}>
                  <ModalMultiInput {...this.props} handleData={this.handleFetch} columns={props.columns} condition={{ companyId: companyObj.objectId, companyName: companyObj.name, schoolId: rowData.objectId, schoolName: rowData.name_cht, classId: classObj.objectId, className: classObj.name }} dbName='students' />
                </div>
                <MTableToolbar {...props} />
                <div style={{ clear: 'both' }}></div>
              </div>),
            }}
            data={data2}
            title={`${rowData.name_cht} 學生管理`}
            // actions={[
            //   {
            //     icon: 'view_headline',
            //     tooltip: '查看',
            //     onClick: (event, rowData) => this.handleData(rowData)
            //   }
            // ]}
            editable={{
              onRowAdd: newData1 =>
                new Promise(async (resolve, reject) => {
                  const data = [...data2];
                  const db = Parse.Object.extend("User");
                  const newDoc = new db();
                  const doc = await newDoc.save();
                  // newData1.objectId = doc.id;
                  // newData1.id = doc.id;
                  // newData1._id = doc.id;
                  let obj = {
                    ...newData1,
                    objectId: doc.id,
                    id: doc.id,
                    _id: doc.id,
                    companyId: companyObj.objectId,
                    companyName: companyObj.name,
                    schoolId: rowData.objectId,
                    schoolName: rowData.name_cht,
                    classId: classObj.objectId,
                    className: classObj.name
                  }
                  data.push(obj);
                  this.setState({ data2: data, newData1: obj }, () => resolve());
                }).then(() => this.handleAdd('2')),
              onRowUpdate: (newData1, oldData1) =>
                new Promise((resolve, reject) => {
                  const data = [...data2];
                  const index = data.indexOf(oldData1);
                  data[index] = newData1;
                  // handleState('people0', data);
                  this.setState({ data2: data, newData1, oldData1 }, () => resolve());
                }).then(() => this.handleUpdate('2')),
              onRowDelete: oldData1 =>
                new Promise((resolve, reject) => {
                  let data = [...data2];
                  const index = data.indexOf(oldData1);
                  data.splice(index, 1);
                  // handleState('people0', data);
                  this.setState({ data2: data, oldData1 }, () => resolve());
                }).then(() => this.handleDelete('2')),
            }}
          /> : null}
        </Col>
      </Row>
    </>)
  }
}