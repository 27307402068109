import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

const columns = () => ([
  { width: 100, title: '交修單號', field: 'deliveryOrderNum' },
  { width: 100, title: '交修日期', field: 'deliveryDate' },
  { width: 100, title: '接待人員', field: 'receptionist' },
  { width: 100, title: '廠區車位', field: 'factoryParking' },
  { width: 100, title: '交修單別', field: 'deliveryFormat' },
  { width: 100, title: '牌照號碼', field: 'licenseNum' },
  { width: 100, title: '車輛廠牌', field: 'vehicleBrand' },
  { width: 100, title: '車輛車系', field: 'vehicle' },
  { width: 100, title: '車輛車型', field: 'vehicleModel' },
  { width: 100, title: '出版年份/月份', field: 'publicationYearMonth' },
  { width: 100, title: '進廠里程數', field: 'mileage' },
  { width: 100, title: '取車卡號', field: 'pickUpCardNum' },
  { width: 100, title: '進廠日期', field: 'dateOfArrival' },
  { width: 100, title: '進廠時間', field: 'timeToEnterTheFactory' },
  { width: 100, title: '交修業態', field: 'deliveryFormat' },
  { width: 100, title: '交修類別', field: 'deliveryType' },
  { width: 100, title: '交修現況', field: 'deliveryStatus' },
  { width: 100, title: '車主名稱', field: 'ownersName' },
  { width: 100, title: '專屬技師', field: 'exclusiveTechnician' },
  { width: 100, title: '機油廠牌', field: 'engineOilBrand' },
  { width: 100, title: '定期保養方式', field: 'regularMaintenance' },
  { width: 100, title: '檢查號碼', field: 'checkNum' },
  { width: 100, title: '顏色名稱', field: 'colorName' },
  { width: 100, title: '引擎號碼', field: 'engineNum' },
  { width: 100, title: '車身號碼', field: 'bodyNum' },
  { width: 100, title: '底盤號碼', field: 'chassisNum' },
  { width: 100, title: '前次進廠里程', field: 'mileageBeforeEnteringTheFactory' },
  { width: 100, title: '前次進廠日期', field: 'dateOfLastEntry' },
  { width: 100, title: '下次定保里程', field: 'nextGuaranteedMileage' },
  { width: 100, title: '交修摘要', field: 'deliverySummary' },
]);
const columns2 = () => ([
  { title: '序號', field: 'serialNum' },
  { title: '交修類別', field: 'deliveryType' },
  { title: '維修工作代號', field: 'maintenanceWorkCode' },
  { title: '維修工作名稱', field: 'repairWorkName' },
  { title: '維修動作', field: 'maintenanceAction' },
  { title: '實際工資率', field: 'realWageRate' },
  { title: '實際工時', field: 'actualWorkingHours' },
  { title: '金額', field: 'amount' },
  { title: '維修技師', field: 'maintenanceTechnician' },
  { title: '交修獎金', field: 'handoverBonus' },
  { title: '交修分析', field: 'handoverAnalysis' },
  { title: '備註說明', field: 'instructionManual' },
]);
export default class Hr1 extends Component {
  state = {
    dataArr: [],
    filenames: [],
    downloadURLs: [],
    docs: [],
    fileArr: [],
    fileArrCheck: [],
    show: false,
    // loading: true,
    obj: {}
  }

  // componentDidMount() {
  //   this.handleFetch();
  // }

  // componentWillReceiveProps(nextProps) {
  //   this.handleFetch(nextProps);
  // }

  // handleFetch = async (props = this.props) => {
  //   const { companyObj } = props;

  //   const companyId = companyObj.objectId;
  //   const data = await Parse.queryData('hrform_management', { companyId });
  //   this.setState({ dataArr: data, loading: false });
  // }

  // handleUpdate = async () => {
  //   const { newData } = this.state
  //   await Parse.saveData('hrform_management', newData);
  // }

  render() {
    const { dataArr, loading } = this.state;

    return (<div className='content'>
      {/* <Container> */}
      {/* <div style={{ ...style.flexCenter, position: 'relative', zIndex: 100 }}>
          <Header as='h2' style={{ margin: '2rem 0' }}>4-1 人事規章</Header>
        </div> */}
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          pageSize: 5,
          search: true,
          tableLayout: 'fixed',
          searchFieldStyle: {
            width: 90,
          }
        }}
        data={dataArr}
        title={'車輛交修'}
      />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns2()}
        options={{
          pageSize: 5,
          search: true,
          searchFieldStyle: {
            width: 90,
          }
        }}
        data={dataArr}
        title={'交修明細'}
      />
      <br />
      <br />
    </div>)
  }
}