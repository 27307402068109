import React, { Component } from 'react';
import { Tab, ListGroup, Container, Form, Grid } from 'semantic-ui-react';

import List1Teacher from './teacherList/List1Teacher';
import List2Teacher from './teacherList/List2Teacher';
import List3Teacher from './teacherList/List3Teacher';
import DocumentTeacher from './teacherList/DocumentTeacher';

const size = 'small';
const TabRender = ({ component, ...rest }) => <Tab.Pane><Form.Field size={size}>{React.createElement(component, { ...rest })}</Form.Field></Tab.Pane>;
const menuTitle = (str1, str2 = '', icon = 'tty', LEN = 4) =>
  <>
    {/* <div><Icon name={icon} /></div> */}

    {/* <div style={{ marginBottom: '8px' }}>{str1}</div> */}
    {str1}
    {str2 ? <>{str2.substr(0, LEN)}</> : null}
    {str2.length >= LEN ? <><br />{str2.substr(LEN, LEN)}</> : null}
    {str2.length >= LEN * 2 ? <><br />{str2.substr(LEN * 2, LEN)}</> : null}
    {str2.length >= LEN * 3 ? <><br />{str2.substr(LEN * 3, LEN)}</> : null}
    {str2.length >= LEN * 4 ? <><br />{str2.substr(LEN * 4, LEN)}</> : null}
  </>;

export default class TeacherListTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: props.match.params.activeIndex || 0,
    };
  }
  handleTabChange = (e, { activeIndex }) => {
    const { match, teachersObj, teachers } = this.props
    const { list = "1" } = match.params;
    console.log(teachersObj);
    console.log(teachersObj._id);
    this.props.history.push(`/admin/TeacherPage/${teachersObj.objectId}/${activeIndex}`);
    this.setState({ activeIndex })
  }

  panes = [
    { menuItem: { key: '0001', content: menuTitle('6-2-①', 'マスター情報') }, render: () => <TabRender {...this.props} component={List1Teacher} /> },
    { menuItem: { key: '0002', content: menuTitle('6-2-②', '時間割配当') }, render: () => <TabRender {...this.props} component={List2Teacher} /> },
    { menuItem: { key: '0003', content: menuTitle('6-2-③', 'フィル') }, render: () => <TabRender {...this.props} component={DocumentTeacher} /> },

    // { menuItem: 'マスター情報', render: () => <Tab.Pane><List1Teacher {...this.props} /></Tab.Pane> },
    // { menuItem: '時間割配当', render: () => <Tab.Pane><List2Teacher {...this.props} /></Tab.Pane> },
    // { menuItem: 'フィル', render: () => <Tab.Pane><List3Teacher {...this.props} /></Tab.Pane> },
  ]

  render() {
    const { isEdit } = this.props;
    const { activeIndex } = this.state;
    return (
      <Form.Field>
        <Tab
          // fluid
          activeIndex={activeIndex} onTabChange={this.handleTabChange}
          menu={{ pointing: true }}
          panes={this.panes} />
      </Form.Field>
    );
  }
}