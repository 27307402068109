import React, { Component } from 'react'
import { Container, Dimmer, Loader, Segment, Image, Select } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf, typeCurrency } from 'views/web/CustomMaterialTable';


const columns = () => ([
  { title: '項目', field: 'accountText' },
  { title: '12月', field: 'price12', ...typeCurrency },
  { title: '1月', field: 'price1', ...typeCurrency },
  { title: '2月', field: 'price2', ...typeCurrency },
  { title: '3月', field: 'price3', ...typeCurrency },
  { title: '4月', field: 'price4', ...typeCurrency },
  { title: '5月', field: 'price5', ...typeCurrency },
  { title: '6月', field: 'price6', ...typeCurrency },
  { title: '7月', field: 'price7', ...typeCurrency },
  { title: '8月', field: 'price8', ...typeCurrency },
  { title: '9月', field: 'price9', ...typeCurrency },
  { title: '10月', field: 'price10', ...typeCurrency },
  { title: '11月', field: 'price11', ...typeCurrency },
  { title: '合計', field: 'priceTotal', ...typeCurrency },
]);

const funcAccText = (item, ac2Data2Arr) => {
  if (ac2Data2Arr.find(item2 => item2.acc3Id === item && item2.acc3Id !== "")) {
    return ac2Data2Arr.find(item2 => item2.acc3Id === item && item2.acc3Id !== "").acc3Text
  } else if (ac2Data2Arr.find(item2 => item2.acc2Id === item && item2.acc2Id !== "")) {
    return ac2Data2Arr.find(item2 => item2.acc2Id === item && item2.acc2Id !== "").acc2Text
  } else if (ac2Data2Arr.find(item2 => item2.acc1Id === item && item2.acc1Id !== "")) {
    return ac2Data2Arr.find(item2 => item2.acc1Id === item && item2.acc1Id !== "").acc1Text
  }
}

const funcPrice = (item, ac2DataArr = [], month) => {
  let totalprice = 0;
  if (month) {
    const data = ac2DataArr.filter(item2 => (item2.acc3Id === item || item2.acc2Id === item || item2.acc1Id === item) && item2.month === month)
    // console.log(data)
    data.forEach(item2 => {
      if (!(item2.price === undefined)) {
        totalprice += Number(item2.price)
      }
    })
  } else {
    const data = ac2DataArr.filter(item2 => (item2.acc3Id === item || item2.acc2Id === item || item2.acc1Id === item))
    data.forEach(item2 => {
      if (!(item2.price === undefined)) {
        totalprice += Number(item2.price)
      }
    })
  }
  return totalprice
}

const funcTotal = (dataArr, obj = {}) => {
  let total1 = obj.price1 || 0;
  let total2 = obj.price2 || 0;
  let total3 = obj.price3 || 0;
  let total4 = obj.price4 || 0;
  let total5 = obj.price5 || 0;
  let total6 = obj.price6 || 0;
  let total7 = obj.price7 || 0;
  let total8 = obj.price8 || 0;
  let total9 = obj.price9 || 0;
  let total10 = obj.price10 || 0;
  let total11 = obj.price11 || 0;
  let total12 = obj.price12 || 0;
  let totalprice = obj.priceTotal || 0;
  dataArr.forEach(item2 => total1 += Number(item2.price1))
  dataArr.forEach(item2 => total2 += Number(item2.price2))
  dataArr.forEach(item2 => total3 += Number(item2.price3))
  dataArr.forEach(item2 => total4 += Number(item2.price4))
  dataArr.forEach(item2 => total5 += Number(item2.price5))
  dataArr.forEach(item2 => total6 += Number(item2.price6))
  dataArr.forEach(item2 => total7 += Number(item2.price7))
  dataArr.forEach(item2 => total8 += Number(item2.price8))
  dataArr.forEach(item2 => total9 += Number(item2.price9))
  dataArr.forEach(item2 => total10 += Number(item2.price10))
  dataArr.forEach(item2 => total11 += Number(item2.price11))
  dataArr.forEach(item2 => total12 += Number(item2.price12))
  dataArr.forEach(item2 => totalprice += Number(item2.priceTotal))
  return {
    title: "total",
    accountText: "合計",
    price1: total1,// totalprice1: total1,
    price2: total2,// totalprice2: total2,
    price3: total3,// totalprice3: total3,
    price4: total4,// totalprice4: total4,
    price5: total5,// totalprice5: total5,
    price6: total6,// totalprice6: total6,
    price7: total7,// totalprice7: total7,
    price8: total8,// totalprice8: total8,
    price9: total9,// totalprice9: total9,
    price10: total10, // totalprice10: total10,
    price11: total11,// totalprice11: total11,     
    price12: total12,// totalprice12: total12,
    priceTotal: totalprice,   // total: totalprice,
    totalr: 0
    // amountTax: totalTax,// totalTax: totalTax,
  }
}

const acc2price = (arr, ac2DataArr) =>
  arr.map(item => ({
    accountText: funcAccText(item, ac2DataArr),
    price1: funcPrice(item, ac2DataArr, '01'),
    price2: funcPrice(item, ac2DataArr, '02'),
    price3: funcPrice(item, ac2DataArr, '03'),
    price4: funcPrice(item, ac2DataArr, '04'),
    price5: funcPrice(item, ac2DataArr, '05'),
    price6: funcPrice(item, ac2DataArr, '06'),
    price7: funcPrice(item, ac2DataArr, '07'),
    price8: funcPrice(item, ac2DataArr, '08'),
    price9: funcPrice(item, ac2DataArr, '09'),
    price10: funcPrice(item, ac2DataArr, '10'),
    price11: funcPrice(item, ac2DataArr, '11'),
    price12: funcPrice(item, ac2DataArr, '12'),
    priceTotal: funcPrice(item, ac2DataArr),
    totalr: funcPrice(item, ac2DataArr)
  }))

export default class Fin1116 extends Component {
  state = {
    dataArr: [],
    data2Arr: [],
    ac2DataArr: [],
    ac2Data2Arr: [],
    deleteConfirm: false,
    loading: true,
    mgyearSel: '',
    mgyearArr: [],
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props, type = 1) => {
    const { match, companyObj } = props;
    const companyId = companyObj.objectId;
    const { mgyearSel } = this.state

    if (type === 1) {
      const mgyear = await Parse.queryData('mgyear', { companyId });
      const mgyearArr = mgyear.filter(item => item.value_AD).map(item => ({
        key: item.objectId, text: `${item.value_AD}`, value: item.value_AD
      }));
      let now = String(new Date().getFullYear())
      const mgyearSel = (mgyear && mgyear.length && mgyear.find(item => item.value_AD === now).value_AD) || ''
      this.setState({ mgyearArr, mgyearSel });
      // const ac2DataArr = []
      const ac2DataArr = await Parse.queryData('cashbook', { companyId, year: mgyearSel, acc1Id: '9snvSJKem7' });

      const salary = await Parse.queryData('salary', { companyId, year: mgyearSel });
      let saObj = {
        accountText: '員工薪資總支出',
        price1: 0,
        price2: 0,
        price3: 0,
        price4: 0,
        price5: 0,
        price6: 0,
        price7: 0,
        price8: 0,
        price9: 0,
        price10: 0,
        price11: 0,
        price12: 0,
        priceTotal: 0,
      }
      salary.forEach(item => {
        saObj.priceTotal = saObj.priceTotal + item.amount
        switch (item.month) {
          case '01':
            saObj.price1 = saObj.price1 + item.amount
            break;
          case '02':
            saObj.price2 = saObj.price2 + item.amount
            break;
          case '03':
            saObj.price3 = saObj.price3 + item.amount
            break;
          case '04':
            saObj.price4 = saObj.price4 + item.amount
            break;
          case '05':
            saObj.price5 = saObj.price5 + item.amount
            break;
          case '06':
            saObj.price6 = saObj.price6 + item.amount
            break;
          case '07':
            saObj.price7 = saObj.price7 + item.amount
            break;
          case '08':
            saObj.price8 = saObj.price8 + item.amount
            break;
          case '09':
            saObj.price9 = saObj.price9 + item.amount
            break;
          case '10':
            saObj.price10 = saObj.price10 + item.amount
            break;
          case '11':
            saObj.price11 = saObj.price11 + item.amount
            break;
          case '12':
            saObj.price12 = saObj.price12 + item.amount
            break;
          default: console.log(item, '無薪資資料')
        }
      })
      // console.log(saObj)
      const accountIdArr = [];
      // const acc3IdArr = [];
      ac2DataArr.forEach(({ acc3Id, acc2Id, acc1Id }) => {
        if (acc3Id !== undefined && acc3Id !== "") {
          if (accountIdArr.indexOf(acc3Id) === -1) {
            accountIdArr.push(acc3Id)
          }
        } else if (acc2Id !== undefined && acc2Id !== "" && accountIdArr.indexOf(acc2Id) === -1) {
          accountIdArr.push(acc2Id);
        }
        // else if (acc1Id !== undefined && acc1Id !== "" && accountIdArr.indexOf(acc1Id) === -1) {
        //   accountIdArr.push(acc1Id);
        // }
      });
      const dataArr11 = acc2price(accountIdArr, ac2DataArr);
      const totalObj = funcTotal(dataArr11);
      const dataArr = dataArr11.map(item => {
        const totalr = `${((Number(item.totalr) / Number(totalObj.priceTotal)) * 100).toFixed(2)}`
        return {
          ...item,
          totalr,
        }
      })
      dataArr.forEach(item => totalObj.totalr = Number(item.totalr) + Number(totalObj.totalr))
      dataArr.push(totalObj)
      this.setState({ dataArr });
      const ac2Data2Arr = await Parse.queryData('cashbook', { companyId, year: mgyearSel, acc1Id: 'o2oKwXbPjS' });
      const accountIdArr2 = [];
      // const acc3IdArr = [];
      ac2Data2Arr.forEach(({ acc3Id, acc2Id, acc1Id }) => {
        if (acc3Id !== undefined && acc3Id !== "") {
          if (accountIdArr2.indexOf(acc3Id) === -1) {
            accountIdArr2.push(acc3Id)
          }
        } else if (acc2Id !== undefined && acc2Id !== "" && accountIdArr2.indexOf(acc2Id) === -1) {
          accountIdArr2.push(acc2Id);
        }
        // else if (acc1Id !== undefined && acc1Id !== "" && accountIdArr2.indexOf(acc1Id) === -1) {
        //   accountIdArr2.push(acc1Id);
        // }
      });
      const dataArr21 = acc2price(accountIdArr2, ac2Data2Arr);
      const totalObj2 = funcTotal(dataArr21);
      const data2Arr = dataArr21.map(item => {
        const totalr = `${((Number(item.totalr) / Number(totalObj2.priceTotal)) * 100).toFixed(2)}`
        return {
          ...item,
          totalr,
        }
      })
      data2Arr.forEach(item => totalObj2.totalr = Number(item.totalr) + Number(totalObj2.totalr))
      // data2Arr.push(saObj)
      data2Arr.push(totalObj2)
      this.setState({ data2Arr, loading: false });
    } else {
      // const ac2DataArr = []
      const ac2DataArr = await Parse.queryData('cashbook', { companyId, year: mgyearSel, acc1Id: '9snvSJKem7' });

      const salary = await Parse.queryData('salary', { companyId, year: mgyearSel });
      let saObj = {
        accountText: '員工薪資總支出',
        price1: 0,
        price2: 0,
        price3: 0,
        price4: 0,
        price5: 0,
        price6: 0,
        price7: 0,
        price8: 0,
        price9: 0,
        price10: 0,
        price11: 0,
        price12: 0,
        priceTotal: 0,
      }
      salary.forEach(item => {
        saObj.priceTotal = saObj.priceTotal + item.amount
        switch (item.month) {
          case '01':
            saObj.price1 = saObj.price1 + item.amount
            break;
          case '02':
            saObj.price2 = saObj.price2 + item.amount
            break;
          case '03':
            saObj.price3 = saObj.price3 + item.amount
            break;
          case '04':
            saObj.price4 = saObj.price4 + item.amount
            break;
          case '05':
            saObj.price5 = saObj.price5 + item.amount
            break;
          case '06':
            saObj.price6 = saObj.price6 + item.amount
            break;
          case '07':
            saObj.price7 = saObj.price7 + item.amount
            break;
          case '08':
            saObj.price8 = saObj.price8 + item.amount
            break;
          case '09':
            saObj.price9 = saObj.price9 + item.amount
            break;
          case '10':
            saObj.price10 = saObj.price10 + item.amount
            break;
          case '11':
            saObj.price11 = saObj.price11 + item.amount
            break;
          case '12':
            saObj.price12 = saObj.price12 + item.amount
            break;
          default: console.log(item, '無薪資資料')
        }
      })
      // console.log(saObj)
      const accountIdArr = [];
      // const acc3IdArr = [];
      ac2DataArr.forEach(({ acc3Id, acc2Id, acc1Id }) => {
        if (acc3Id !== undefined && acc3Id !== "") {
          if (accountIdArr.indexOf(acc3Id) === -1) {
            accountIdArr.push(acc3Id)
          }
        } else if (acc2Id !== undefined && acc2Id !== "" && accountIdArr.indexOf(acc2Id) === -1) {
          accountIdArr.push(acc2Id);
        }
        // else if (acc1Id !== undefined && acc1Id !== "" && accountIdArr.indexOf(acc1Id) === -1) {
        //   accountIdArr.push(acc1Id);
        // }
      });
      const dataArr11 = acc2price(accountIdArr, ac2DataArr);
      const totalObj = funcTotal(dataArr11);
      const dataArr = dataArr11.map(item => {
        const totalr = `${((Number(item.totalr) / Number(totalObj.priceTotal)) * 100).toFixed(2)}`
        return {
          ...item,
          totalr,
        }
      })
      dataArr.forEach(item => totalObj.totalr = Number(item.totalr) + Number(totalObj.totalr))
      dataArr.push(totalObj)
      this.setState({ dataArr });
      const ac2Data2Arr = await Parse.queryData('cashbook', { companyId, year: mgyearSel, acc1Id: 'o2oKwXbPjS' });
      const accountIdArr2 = [];
      // const acc3IdArr = [];
      ac2Data2Arr.forEach(({ acc3Id, acc2Id, acc1Id }) => {
        if (acc3Id !== undefined && acc3Id !== "") {
          if (accountIdArr2.indexOf(acc3Id) === -1) {
            accountIdArr2.push(acc3Id)
          }
        } else if (acc2Id !== undefined && acc2Id !== "" && accountIdArr2.indexOf(acc2Id) === -1) {
          accountIdArr2.push(acc2Id);
        }
        //  else if (acc1Id !== undefined && acc1Id !== "" && accountIdArr2.indexOf(acc1Id) === -1) {
        //   accountIdArr2.push(acc1Id);
        // }
      });
      const dataArr21 = acc2price(accountIdArr2, ac2Data2Arr);
      const totalObj2 = funcTotal(dataArr21);
      const data2Arr = dataArr21.map(item => {
        const totalr = `${((Number(item.totalr) / Number(totalObj2.priceTotal)) * 100).toFixed(2)}`
        return {
          ...item,
          totalr,
        }
      })
      data2Arr.forEach(item => totalObj2.totalr = Number(item.totalr) + Number(totalObj2.totalr))
      // data2Arr.push(saObj)
      data2Arr.push(totalObj2)
      this.setState({ data2Arr, loading: false });
    }
  }
  handleChange = (event, data) => {
    const { name, value } = data;
    this.setState({ [name]: value, loading: true }, () => this.handleFetch(this.props, 0));
  }
  render() {
    const { dataArr, data2Arr, loading, mgyearArr, mgyearSel } = this.state;
    return (<>
      {/* 資金需求預估表 */}
      <br />
      <Select style={{
        position: 'relative', zIndex: 99,
        float: 'left',
        minWidth: '200px',
      }}
        compact
        name="mgyearSel"
        options={mgyearArr}
        value={mgyearSel}
        onChange={this.handleChange}
      />
      <br />
      {/* <Select style={{
          position: 'relative', zIndex: 99,
          float: 'right',
          minWidth: '200px',
          // borderTopLeftRadius: '0',
          // borderBottomLeftRadius: '0'
        }}
          compact
          name="bankaccSel"
          options={bankaccArr}
          value={bankaccSel}
          onChange={this.handleChange}
        /> */}
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearSel} 年度損益表   收入項目`),
          pageSize: 5,
          search: false
        }}
        data={dataArr}
        title={`${mgyearSel} 年度損益表   收入項目`}
      />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearSel} 年度損益表   支出科目`),
          pageSize: 5,
          search: false
        }}
        data={data2Arr}
        title={`${mgyearSel} 年度損益表   支出科目`}
      />
    </>)
  }
}