import jsPDF from '../pdf/JspdfMyFonts';
// import { pdfkit2jspdf, drawTable } from '../../widget/define/jspdf'
// import { _calculateAge } from '../../widget/define/date'

import { getLabelName2, showFieldText } from 'views/widgets/FieldsRender';
import logoBase64 from '../../views/hanbury/png/logo'

import {
  pdfas1, pdfas2, pdfas02, pdfas3, pdfas4, pdfas5, pdfas6,
  pdfas7, pdfas8, pdfas9, pdfas10, pdfas11,
  pdfas12, pdfas13, pdfas14, pdfas15, pdfas16,
  pdfas17, pdfas18, pdfas19, pdfas20, pdfas21,
  pdfas22, pdfas23, pdfas24, pdfas25, pdfas26,
  pdfas27, pdfas28, pdfas29, pdfas30, pdfas31,
  pdfas32, pdfas33, pdfas34, pdfas35, pdfas36,
  pdfas37, pdfas38, pdfas39, pdfas40, pdfas41,
  pdfas42, pdfas43, pdfas44, pdfas45, pdfas46,
  pdfas47, pdfas48, pdfas49, pdfas50, pdfas51,
  pdfas52, pdfas53, pdfas54, pdfas55, pdfas56,
  pdfas57, pdfas58, pdfas59, pdfas60, pdfas61,
  pdfas62, pdfas63, pdfas64, pdfas65, pdfas66,
  pdfas67, pdfas68, pdfas69, pdfas70, pdfas71,
  pdfas72, pdfas73, pdfas74, pdfas75, pdfas76,
  pdfas77, pdfas78, pdfas79, pdfas80, pdfas81,
  pdfas82, pdfas83, pdfas84, pdfas85, pdfas86,
  pdfas87, pdfas88, pdfas89, pdfas90,
} from './pdfHanbury';

// const d = new Date();
// const nowDate = `${d.getFullYear() - 1911}年${d.getMonth() + 1}月${d.getDate()}日`;

const portfolioPage = (doc, props) => {
  const { formSet, optSet, value, arrObj, companyObj, codeForm, nowform, params, printDate, lightType, selectData, nowservice } = props;

  const { form } = params;
  doc.addImage(logoBase64, 'PNG', 0, 0, a4pageWidth, a4pageHeight + 10);

  doc.setFont('msjh');
  doc.setFontSize(10);
  // console.log(form)
  // console.log(formSet, optSet, value, form)

  const getLabel = (name) => typeof getLabelName2(formSet, name) === 'string' ? getLabelName2(formSet, name).trim() : '';
  const getValue = (value) => typeof showFieldText(formSet, optSet, value, form) === 'string' ? showFieldText(formSet, optSet, value, form).trim() : '';
  const getTable = (title, body) => {
    // doc.internal.pageSize = 200;
    doc.autoTable({
      theme: 'grid',
      head: [
        [{ content: title, colSpan: 4, styles: { fillColor: [89, 156, 222] } }],
        [{ content: '計劃資料', colSpan: 4, styles: { fillColor: [255, 248, 214], textColor: [102, 34, 0] } }],
      ],
      body,
      tableWidth: 'wrap',
      styles: { font: "msjh", fontStyle: "normal" },
      headStyles: {
        font: "msjh", fontStyle: "normal",
      },
      columnStyles: {
        0: {
          columnWidth: 80,
          // textColor: [240, 240, 240],
          fillColor: [183, 183, 220]
        },
        1: {
          columnWidth: 178,
          fillColor: null
        },
        2: {
          columnWidth: 80,
          // textColor: [240, 240, 240, 0],
          fillColor: [183, 183, 220]
        },
        3: {
          columnWidth: 178,
          fillColor: null
          // fillColor: 'transparent'
          // fillColor: 'rbga(0, 183, 220, 100)'
        }
      },
      // willDrawCell: function(rows) {
      //   var pageNumber = doc.internal.getCurrentPageInfo().pageNumber;
      //   if(numRows > 12){
      //     if(pageNumber == 1){
      //         if (rows.row.index > 0 && rows.row.index % 16 === 0 && rows.column.index === rows.table.columns.length - 1) {
      //           doc.addPage();
      //         }    
      //     }else{
      //       if (rows.row.index > 0 && rows.row.index % 30 === 0 && rows.column.index === rows.table.columns.length - 1) {
      //         doc.addPage();
      //         }
      //     }
      //   }
      // }, 
      //   didDrawPage: function(data) {
      //      // footer
      //       pdf.setFontSize(8);
      //       var yFooter = pageSize - 20
      //       pdf.text(footer, 300, yFooter, "center");
      //   },
    })
  }

  // common
  doc.autoTable({
    startY: 70,
    head: [
      [getLabel('client_uid'), getLabel('name_cht'), getLabel('contactnum'), getLabel('email')]
    ],
    body: [
      [getValue('client_uid'), getValue('name_cht'), getValue('contactnum'), getValue('email')]
    ],
    styles: { font: "msjh", fontStyle: "normal" },
    headStyles: { font: "msjh", fontStyle: "normal", fontSize: 10, cellWidth: 'wrap', fillColor: [0, 161, 92] },
    bodyStyles: { fontSize: 10 },
  })

  // each porfolio
  if (form.template_id === "1") {
  } else if (form.template_id === "2") {
    const body = [
      [getLabel('account_num'), getValue('account_num'),
        //  getLabel('nowstatus'), getValue('nowstatus')
      ],
      [getLabel('assume_insure_price'), getValue('assume_insure_price'), getLabel('effective_date'), getValue('effective_date')],
      [getLabel('confirmed_insure_price'), getValue('confirmed_insure_price'), getLabel('backdate'), getValue('backdate')],
      [getLabel('pay_year_num'), getValue('pay_year_num'), getLabel('telephone_credit'), getValue('telephone_credit')],
      [getLabel('bodycheck_date'), getValue('bodycheck_date'), getLabel('cpaletter_date'), getValue('cpaletter_date')],
      // problem
      [getLabel('bodycheck_place'), getValue('bodycheck_place'), '受益人1', `${getValue('beneficial1_name')} ${getValue('beneficial1_percent')}% ${getValue('beneficial1_relationship')}`],
      // problem
      [getLabel('sign_date'), getValue('sign_date'), '受益人2', getValue('beneficial2_name') + ' ' + getValue('beneficial2_percent') + '% ' + getValue('beneficial2_relationship')],
      [getLabel('bodychecktype1'), getValue('bodychecktype1'),
        // '受益人3', getValue('beneficial3_name') + ' ' + getValue('beneficial3_percent') + '% ' + getValue('beneficial3_relationship')
      ],
      [getLabel('bodychecktype2'), getValue('bodychecktype2'),
        // '受益人4', getValue('beneficial4_name') + ' ' + getValue('beneficial4_percent') + '% ' + getValue('beneficial4_relationship')
      ],
      [getLabel('insurance_receive_date'), getValue('insurance_receive_date'), getLabel('is_trust'), getValue('is_trust')],
      [getLabel('insurance_give_date'), getValue('insurance_give_date'), getLabel('trust_isrevoke'), getValue('trust_isrevoke')],
      [getLabel('onlineaccess_account'), getValue('onlineaccess_account'), getLabel('trust_lawyer'), getValue('trust_lawyer')],
      [getLabel('onlineaccess_password'), getValue('onlineaccess_password'), getLabel('trust_name'), getValue('trust_name')],
      [getLabel(''), getValue(''), getLabel('trust_trustee'), getValue('trust_trustee')],
      [getLabel(''), getValue(''), getLabel('trust_grantor'), getValue('trust_grantor')],
      [getLabel(''), getValue(''), getLabel('trust_ssnitin'), getValue('trust_ssnitin')],
      [getLabel(''), getValue(''), getLabel('trust_bankaccount'), getValue('trust_bankaccount')],
      [getLabel('tp'), getValue('tp'), getLabel('trust_account'), getValue('trust_account')],
      [getLabel('provider_id'), getValue('provider_chttext'), getLabel('trust_routenum'), getValue('trust_routenum')],
      [getLabel('nowphase'), getValue('nowphase'), '信託受益人1', getValue('trust_beneficial1_name') + ' ' + getValue('trust_beneficial1_percent') + '% ' + getValue('trust_beneficial1_relationship')],
      [getLabel('currency_type'), getValue('currency_type'), '信託受益人2', getValue('trust_beneficial2_name') + ' ' + getValue('trust_beneficial2_percent') + '% ' + getValue('trust_beneficial2_relationship')],
      // [getLabel(''), getValue(''), '信託受益人3', getValue('trust_beneficial3_name') + ' ' + getValue('trust_beneficial3_percent') + '% ' + getValue('trust_beneficial3_relationship')],
      // [getLabel(''), getValue(''), '信託受益人4', getValue('trust_beneficial4_name') + ' ' + getValue('trust_beneficial4_percent') + '% ' + getValue('trust_beneficial4_relationship')],
    ]
    getTable(form.product4_text, body)
  } else if (form.template_id === "3") {
    const body = [
      [getLabel('stockitem'), getValue('stockitem'), getLabel('prebuybook_date'), getValue('prebuybook_date')],
      ['投資種類', '股權', getLabel('stockcontract_date'), getValue('stockcontract_date')],
      [getLabel('certificate_no'), getValue('certificate_no'), getLabel('giveinterestconfirm_date'), getValue('giveinterestconfirm_date')],
      [getLabel('account_num'), getValue('account_num'), getLabel(''), getValue('')],
      [getLabel('invest_money'), getValue('invest_money'), getLabel('stockrightapply_date'), getValue('stockrightapply_date')],
      [getLabel('holdstock_num'), getValue('holdstock_num'), getLabel('passport_publicprove'), getValue('passport_publicprove')],
      [getLabel('holdstock_percent'), getValue('holdstock_percent'), getLabel('address_publicprove'), getValue('address_publicprove')],
      [getLabel('effective_date'), getValue('effective_date'), getLabel('stockcredit_give'), getValue('stockcredit_give')],
      [getLabel('payment_date'), getValue('payment_date'), getLabel(''), getValue('')],
      [getLabel('arrPaymentDateMoney'), getValue('arrPaymentDateMoney'), getLabel('givecontract_date'), getValue('givecontract_date')],
      [getLabel('payment_method'), getValue('payment_method'), getLabel('return_principal_date'), getValue('return_principal_date')],
      [getLabel('receive_money_date'), getValue('receive_money_date'), getLabel(''), getValue('')],
      [
        { content: getLabel('interest_condition') },
        form.interest_condition_text,
        { content: 'UK Tax FORM', styles: { fillColor: [253, 236, 150] } },
        {
          content:
            '簽署日期：' + getValue('irs_w7_sign_date') + '\n' +
            '狀態：' + getValue('irs_w7_status') + '\n' +
            '遞交日期：' + getValue('irs_w7_sent_date') + '\n' +
            '備註：' + getValue('irs_ps')
        }
      ],
      [
        { content: getLabel('pay_year_num') },
        { content: getValue('pay_year_num') },
        { content: 'UK Tax No.', styles: { fillColor: [253, 236, 150] } },
        {
          content:
            '簽署日期：' + getValue('itin_text') + '\n' +
            '狀態：' + getValue('itin_status') + '\n' +
            '遞交日期：' + getValue('itin_date') + '\n' +
            '備註：' + getValue('itin_ps')
        }
      ],
      [getLabel('giveinterest_period'), getValue('giveinterest_period'), getLabel('currency_type'), getValue('currency_type')],
      [getLabel('provider_id'), getValue('provider_chttext'), getLabel(''), getValue('')],
      [getLabel('nowphase'), getValue('nowphase'), getLabel(''), getValue('')],
      [{ content: '配息銀行帳戶', colSpan: 4, styles: { textColor: [102, 34, 0], fillColor: [255, 248, 214] } }],
      [getLabel('beneficiary_bank_location'), { content: getValue('beneficiary_bank_location'), colSpan: 3 }],
      [getLabel('beneficiary_bank_name'), { content: getValue('beneficiary_bank_name'), colSpan: 3 }],
      [getLabel('beneficiary_bank_address'), { content: getValue('beneficiary_bank_address'), colSpan: 3 }],
      [getLabel('swift_code'), { content: getValue('swift_code'), colSpan: 3 }],
      [getLabel('beneficiary_account_number'), { content: getValue('beneficiary_account_number'), colSpan: 3 }],
    ]

    getTable(form.product4_text, body)
  } else if (form.template_id === "4") {
    const body = [
      [getLabel('account_num'), getValue('account_num'), getLabel('prebuybook_date'), getValue('prebuybook_date')],
      [getLabel('unitroomnum'), getValue('unitroomnum'), getLabel('accountconfirm_date'), getValue('accountconfirm_date')],
      ['投資種類', '產權', getLabel('givecontract_date'), getValue('givecontract_date')],
      [getLabel('invest_money'), getValue('invest_money'), getLabel('return_principal_date'), getValue('return_principal_date')],
      [getLabel('effective_date'), getValue('effective_date'), '', ''],

      [{ content: '匯款記錄', colSpan: 4, styles: { fillColor: [255, 248, 214] } }],
      [getLabel('payment_date'), getValue('payment_date'), '', ''],
      [getLabel('arrPaymentDateMoney'), getValue('arrPaymentDateMoney'), getLabel('payment_method'), getValue('payment_method')],
      [getLabel('receive_money_date'), getValue('receive_money_date'), '', ''],
      [getLabel('interest_condition'),
      form.interest_condition_text,
        '', ''],
      [getLabel('pay_year_num'), getValue('pay_year_num'), '', ''],
      [getLabel('giveinterest_period'), getValue('giveinterest_period'), '', ''],
      [getLabel('provider_id'), getValue('provider_chttext'), '', ''],
      [
        { content: getLabel('nowphase') },
        { content: getValue('nowphase') },
        { content: 'UK Tax FORM', styles: { fillColor: [253, 236, 150] } },
        {
          content:
            '簽署日期：' + getValue('irs_w7_sign_date') + '\n' +
            '狀態：' + getValue('irs_w7_status') + '\n' +
            '遞交日期：' + getValue('irs_w7_sent_date') + '\n' +
            '備註：' + getValue('irs_ps')
        }
      ],
      [
        { content: '' },
        { content: '' },
        { content: 'UK Tax No.', styles: { fillColor: [253, 236, 150] } },
        {
          content:
            '輸入文字(編號)：' + getValue('itin_text') + '\n' +
            '狀態：' + getValue('itin_status') + '\n' +
            '遞交日期：' + getValue('itin_date') + '\n' +
            '備註：' + getValue('itin_ps')
        }
      ],

      [{ content: '配息銀行帳戶', colSpan: 4, styles: { textColor: [102, 34, 0], fillColor: [255, 248, 214] } }],
      [getLabel('beneficiary_bank_location'), { content: getValue('beneficiary_bank_location'), colSpan: 3 }],
      [getLabel('beneficiary_bank_name'), { content: getValue('beneficiary_bank_name'), colSpan: 3 }],
      [getLabel('beneficiary_bank_address'), { content: getValue('beneficiary_bank_address'), colSpan: 3 }],
      [getLabel('swift_code'), { content: getValue('swift_code'), colSpan: 3 }],
      [getLabel('beneficiary_account_number'), { content: getValue('beneficiary_account_number'), colSpan: 3 }],
    ]
    getTable(form.product4_text, body)
  } else if (form.template_id === "5") {
    const body = [
      [getLabel('account_num'), getValue('account_num'), getLabel('prebuybook_date'), getValue('prebuybook_date')],
      [getLabel('invest_type'), getValue('invest_type'), getLabel('givecontract_date'), getValue('givecontract_date')],
      [getLabel('company_type'), getValue('company_type'), getLabel('is_leave'), getValue('is_leave')],
      [getLabel('invest_money'), getValue('invest_money'), getLabel('arrReturnPrincipal'), getValue('arrReturnPrincipal')],
      [getLabel('effective_date'), getValue('effective_date'), getLabel('leavetotal_money'), getValue('leavetotal_money')],
      [
        getLabel('arrPaymentDateMoney'), getValue('arrPaymentDateMoney'),
        { content: 'IRS W7 FORM', styles: { fillColor: [253, 236, 150] } },
        {
          content:
            '簽署日期：' + getValue('irs_w7_sign_date') + '\n' +
            '狀態：' + getValue('irs_w7_status') + '\n' +
            '遞交日期：' + getValue('irs_w7_sent_date') + '\n' +
            '備註：' + getValue('irs_ps')
        }
      ],
      [
        getLabel('payment_method'), getValue('payment_method'),
        { content: 'Certified Passport', styles: { fillColor: [253, 236, 150] } },
        {
          content:
            '護照有效日期：' + getValue('certpassport_use_date') + '\n' +
            '狀態：' + getValue('certpassport_status') + '\n' +
            '遞交日期：' + getValue('certpassport_sent_date') + '\n' +
            '備註：' + getValue('certpassport_ps')
        }
      ],
      [
        getLabel('receive_money_date'), getValue('receive_money_date'),
        { content: 'ITIN/SSN No.', styles: { fillColor: [253, 236, 150] } },
        {
          content:
            '輸入文字(編號)' + getValue('itin_text') + '\n' +
            '狀態：' + getValue('itin_status') + '\n' +
            '遞交日期：' + getValue('itin_date') + '\n' +
            '備註：' + getValue('itin_ps')
        }
      ],
      [getLabel('interest_condition'),
      // getValue('interest_condition'),
      form.interest_condition_text,
      { content: getLabel('t2848_text'), styles: { fillColor: [253, 236, 150] } }, getValue('t2848_text')],
      [getLabel('pay_year_num'), getValue('pay_year_num'), { content: getLabel('taxret_text'), styles: { fillColor: [253, 236, 150] } }, getValue('taxret_text')],
      [getLabel('giveinterest_period'), getValue('giveinterest_period'), getLabel('collectdone_date'), getValue('collectdone_date')],
      [getLabel('payMonthNum'), getValue('payMonthNum'), getLabel('assumeinvest_year'), getValue('assumeinvest_year')],
      [getLabel('provider_id'), getValue('provider_chttext'), '預計離場', getValue('assumeleave_year') + '/' + getValue('assumeleave_season') + '(西元年/第幾季)'],

      [{ content: '配息銀行帳戶', colSpan: 4, styles: { textColor: [102, 34, 0], fillColor: [255, 248, 214] } }],
      [getLabel('beneficiary_bank_location'), { content: getValue('beneficiary_bank_location'), colSpan: 3 }],
      [getLabel('beneficiary_bank_name'), { content: getValue('beneficiary_bank_name'), colSpan: 3 }],
      [getLabel('beneficiary_bank_address'), { content: getValue('beneficiary_bank_address'), colSpan: 3 }],
      [getLabel('swift_code'), { content: getValue('swift_code'), colSpan: 3 }],
      [getLabel('beneficiary_account_number'), { content: getValue('beneficiary_account_number'), colSpan: 3 }],
    ]
    getTable(form.product4_text, body)
  } else if (form.template_id === "6") {
    const body = [
      [getLabel('provider_engtext'), getValue('provider_engtext'), getLabel('apply_birthday'), getValue('apply_birthday')],
      [getLabel('product4_engtext'), getValue('product4_engtext'), getLabel('apply_email'), getValue('apply_email')],
      [getLabel('account_num'), getValue('account_num'), getLabel('apply_telephone'), getValue('apply_telephone')],
      [getLabel('account_num2'), getValue('account_num2'), getLabel('apply_addr'), getValue('apply_addr')],
      [getLabel('policy_firstperson'), getValue('policy_firstperson'), getLabel('apply_passport'), getValue('apply_passport')],
      [getLabel('policy_secondperson'), getValue('policy_secondperson'), getLabel('apply_cellphone'), getValue('apply_cellphone')],
      [getLabel('policy_effective_date'), getValue('policy_effective_date'), getLabel('hsbc_account_name'), getValue('hsbc_account_name')],
      [getLabel('policy_end_date'), getValue('policy_end_date'), getLabel('hsbc_account_num'), getValue('hsbc_account_num')],
      ['保單年期', getValue('policy_period_year') + ' 年 /' + getValue('policy_period_month'), getLabel('creditcard_num'), getValue('creditcard_num')],
      [getLabel('insurance_status'), getValue('insurance_status'), getLabel('creditcard_date'), getValue('creditcard_date')],
      [getLabel('insurancepay_status'), getValue('insurancepay_status'), getLabel('creditcard_bank'), getValue('creditcard_bank')],
      [getLabel('policy_endpay_date'), getValue('policy_endpay_date'), getLabel('creditcard_bankname'), getValue('creditcard_bankname')],
      [getLabel('policy_nextpay_date'), getValue('policy_nextpay_date'), getLabel('creditcard_name'), getValue('creditcard_name')],
      [getLabel('paymethod_fpi'), getValue('paymethod_fpi'), getLabel('creditcard_addr'), getValue('creditcard_addr')],
      [getLabel('payperiod_fpi'), getValue('payperiod_fpi'), getLabel('policy_currency_type'), getValue('policy_currency_type')],
      [getLabel('nowphase'), getValue('nowphase'), '最後更新日期', form.nowUpdatedAt],
    ]

    getTable(form.product4_text, body)
  } else if (form.template_id === "7") {
    const body = [
      [getLabel('certificate_name'), getValue('certificate_name'), getLabel('invest_money'), getValue('invest_money')],
      [getLabel('certificate_no'), getValue('certificate_no'), getLabel('payment_date'), getValue('payment_date')],
      [getLabel('effective_date'), getValue('effective_date'), getLabel('payment_method'), getValue('payment_method')],
      ['供應商', 'Grandtag', getLabel('receive_money_date'), getValue('receive_money_date')],
      [getLabel('nowphase'), getValue('nowphase'), getLabel('stockrightsummit'), getValue('stockrightsummit')],

      [{ content: '配息銀行帳戶', colSpan: 4, styles: { textColor: [102, 34, 0], fillColor: [255, 248, 214] } }],
      [getLabel('beneficiary_bank_location'), { content: getValue('beneficiary_bank_location'), colSpan: 3 }],
      [getLabel('beneficiary_bank_name'), { content: getValue('beneficiary_bank_name'), colSpan: 3 }],
      [getLabel('beneficiary_bank_address'), { content: getValue('beneficiary_bank_address'), colSpan: 3 }],
      [getLabel('swift_code'), { content: getValue('swift_code'), colSpan: 3 }],
      [getLabel('beneficiary_account_number'), { content: getValue('beneficiary_account_number'), colSpan: 3 }],
    ]

    getTable(form.product4_text, body)
  } else if (form.template_id === "8") {
    const body = [
      [getLabel('provider_engtext'), getValue('provider_engtext'), getLabel('apply_birthday'), getValue('apply_birthday')],
      [getLabel('product4_engtext'), getValue('product4_engtext'), getLabel('apply_email'), getValue('apply_email')],
      [getLabel('account_num'), getValue('account_num'), getLabel('apply_telephone'), getValue('apply_telephone')],
      [getLabel('account_num2'), getValue('account_num2'), getLabel('apply_addr'), getValue('apply_addr')],
      [getLabel('policy_firstperson'), getValue('policy_firstperson'), getLabel('apply_passport'), getValue('apply_passport')],
      [getLabel('policy_secondperson'), getValue('policy_secondperson'), getLabel('apply_cellphone'), getValue('apply_cellphone')],
      [getLabel('policy_effective_date'), getValue('policy_effective_date'), getLabel('hsbc_account_name'), getValue('hsbc_account_name')],
      [getLabel('policy_end_date'), getValue('policy_end_date'), getLabel('hsbc_account_num'), getValue('hsbc_account_num')],
      ['保單年期', getValue('policy_period_year') + ' / ' + getValue('policy_period_month') + '月', getLabel('creditcard_num'), getValue('creditcard_num')],
      [getLabel('insurance_status'), getValue('insurance_status'), getLabel('creditcard_date'), getValue('creditcard_date')],
      [getLabel('insurancepay_status'), getValue('insurancepay_status'), getLabel('creditcard_bank'), getValue('creditcard_bank')],
      [getLabel('policy_endpay_date'), getValue('policy_endpay_date'), getLabel('creditcard_bankname'), getValue('creditcard_bankname')],
      [getLabel('policy_nextpay_date'), getValue('policy_nextpay_date'), getLabel('creditcard_name'), getValue('creditcard_name')],
      [getLabel('paymethod_fpi'), getValue('paymethod_fpi'), getLabel('creditcard_addr'), getValue('creditcard_addr')],
      [getLabel('payperiod_fpi'), getValue('payperiod_fpi'), getLabel('policy_currency_type'), getValue('policy_currency_type')],
      [getLabel('nowphase'), getValue('nowphase'), '最後更新日期', form.nowUpdatedAt],
    ]
    getTable(form.product4_text, body)
  }
}
const a4pageHeight = 841.89;
const a4pageWidth = 595.28;

const data = async (props) => {
  const { formSet, optSet, value, arrObj, companyObj, codeForm, nowform, params, printDate, lightType, selectData, nowservice } = props;
  const { form } = params
  let doc = new jsPDF({ format: 'a4', unit: 'pt' });



  if (nowform === '1') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    portfolioPage(doc, props);//, datetime);
    // } else if (nowform === '46') {
  } else if (nowservice === '1') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas1(doc, form);
  } else if (nowservice === '2') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas2(doc, form);
  } else if (nowservice === '02') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas02(doc, form);
  } else if (nowservice === '3') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas3(doc, form);
  } else if (nowservice === '4') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas4(doc, form);
  } else if (nowservice === '5') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas5(doc, form);
  } else if (nowservice === '6') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas6(doc, form);
  } else if (nowservice === '7') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas7(doc, form);
  } else if (nowservice === '8') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas8(doc, form);
  } else if (nowservice === '9') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas9(doc, form);
  } else if (nowservice === '10') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas10(doc, form);
  } else if (nowservice === '11') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas11(doc, form);
  } else if (nowservice === '12') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas12(doc, form);
  } else if (nowservice === '13') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas13(doc, form);
  } else if (nowservice === '14') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas14(doc, form);
  } else if (nowservice === '15') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas15(doc, form);
  } else if (nowservice === '16') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas16(doc, form);
  } else if (nowservice === '17') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas17(doc, form);
  } else if (nowservice === '18') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas18(doc, form);
  } else if (nowservice === '19') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas19(doc, form);
  } else if (nowservice === '20') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas20(doc, form);
  } else if (nowservice === '21') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas21(doc, form);
  } else if (nowservice === '22') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas22(doc, form);
  } else if (nowservice === '23') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas23(doc, form);
  } else if (nowservice === '24') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas24(doc, form);
  } else if (nowservice === '25') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas25(doc, form);
  } else if (nowservice === '26') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas26(doc, form);
  } else if (nowservice === '27') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas27(doc, form);
  } else if (nowservice === '28') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas28(doc, form);
  } else if (nowservice === '29') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas29(doc, form);
  } else if (nowservice === '30') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas30(doc, form);
  } else if (nowservice === '31') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas31(doc, form);
  } else if (nowservice === '32') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas32(doc, form);
  } else if (nowservice === '33') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas33(doc, form);
  } else if (nowservice === '34') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas34(doc, form);
  } else if (nowservice === '35') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas35(doc, form);
  } else if (nowservice === '36') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas36(doc, form);
  } else if (nowservice === '37') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas37(doc, form);
  } else if (nowservice === '38') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas38(doc, form);
  } else if (nowservice === '39') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas39(doc, form);
  } else if (nowservice === '40') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas40(doc, form);
  } else if (nowservice === '41') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas41(doc, form);
  } else if (nowservice === '42') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas42(doc, form);
  } else if (nowservice === '43') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas43(doc, form);
  } else if (nowservice === '44') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas44(doc, form);
  } else if (nowservice === '45') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas45(doc, form);
  } else if (nowservice === '46') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas46(doc, form);
  } else if (nowservice === '47') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas47(doc, form);
  } else if (nowservice === '48') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas48(doc, form);
  } else if (nowservice === '49') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas49(doc, form);
  } else if (nowservice === '50') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas50(doc, form);
  } else if (nowservice === '51') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas51(doc, form);
  } else if (nowservice === '52') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas52(doc, form);
  } else if (nowservice === '53') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas53(doc, form);
  } else if (nowservice === '54') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas54(doc, form);
  } else if (nowservice === '55') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas55(doc, form);
  } else if (nowservice === '56') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas56(doc, form);
  } else if (nowservice === '57') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas57(doc, form);
  } else if (nowservice === '58') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas58(doc, form);
  } else if (nowservice === '59') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas59(doc, form);
  } else if (nowservice === '60') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas60(doc, form);
  } else if (nowservice === '61') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas61(doc, form);
  } else if (nowservice === '62') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas62(doc, form);
  } else if (nowservice === '63') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas63(doc, form);
  } else if (nowservice === '64') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas64(doc, form);
  } else if (nowservice === '65') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas65(doc, form);
  } else if (nowservice === '66') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas66(doc, form);
  } else if (nowservice === '67') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas67(doc, form);
    // } else if (nowservice === '18a'  ) {
    //   doc = new jsPDF({ format: 'a4', unit: 'pt' });
    //   await pdfas18a(doc, form);
    // } else if (nowservice === '19a'  ) {
    //   doc = new jsPDF({ format: 'a4', unit: 'pt' });
    //   await pdfas19a(doc, form);
  } else if (nowservice === '81') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas81(doc, form);
  } else if (nowservice === '82') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas82(doc, form);
  } else if (nowservice === '83') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas83(doc, form);
  } else if (nowservice === '84') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas84(doc, form);
  } else if (nowservice === '85') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas85(doc, form);
  } else if (nowservice === '86') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas86(doc, form);
  } else if (nowservice === '87') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas87(doc, form);
  } else if (nowservice === '88') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas88(doc, form);
  } else if (nowservice === '89') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas89(doc, form);
  } else if (nowservice === '90') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas90(doc, form);
  } else if (nowservice === '68') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas68(doc, form);
  } else if (nowservice === '69') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas69(doc, form);
  } else if (nowservice === '70') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas70(doc, form);
  } else if (nowservice === '71') {
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    await pdfas71(doc, form);
  }
  return doc
}

export default data;
