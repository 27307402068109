import React, { Component } from 'react'
import { Container, Button, Icon, Form, Menu } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import _ from 'underscore'
import Moment from 'moment';
import Parse from 'widget/parse'
import {
  // Container,
  // Table,
  Row,
  Col,
  // Badge,
  // Button,
  // Card,
  // CardBody,
  // CardTitle,
} from "reactstrap";

import localization from 'widget/MaterialTableOpt'
import RoleUserAdd from './RoleUserAdd'
const d = new Date();
const date = d.getFullYear() + "年" + (d.getMonth() + 1) + "月" + d.getDate() + "日";

const userOpt = [
  { key: '0', text: '全部', value: '0' },
  { key: '1', text: '在職', value: '1' },
  { key: '2', text: '離職', value: '2' },
]

const menu = [
  { id: 'user1', content: '基本資料' },
  { id: 'user2', content: '權限設定' },
  { id: 'user3', content: '登入資訊' },
  { id: 'user4', content: '操作記錄' },
  // { id: 'user5', content: '刪除帳號' },
]

const columns = () => ([
  // { width: 80, title: '使用者名稱', field: 'username', editable: 'never' },
  // { width: 80, title: '工號', field: 'jobNum' },
  // { width: 80, title: '職稱', field: 'jobTitle' },
  { width: 80, title: '角色', field: 'name', editable: 'never' },
  // { width: 80, title: '手機', field: 'phone' },
  // { width: 80, title: '電子信箱', field: 'email' },
  // { title: '介紹人', field: 'recommandName' },
  // { title: '優惠碼', field: 'discountCode' },
  // { title: '建立日期', field: 'createdAt', editable: 'never' },
  // { title: '最後更新日期', field: 'updatedAt', editable: 'never' },
  // { title: '使用者', field: 'authUser', type: 'boolean', width: 60 },
  // { title: '在職', field: 'authLogin', type: 'boolean', width: 60 },
  // { title: '人資', field: 'authHr', type: 'boolean', width: 60 },
  // { title: '會計', field: 'authAccount', type: 'boolean', width: 60 },
  // { title: '系統管理', field: 'authAdmin', type: 'boolean', width: 60 },
  // { width: 160, title: '備註', field: 'ps' },
  { width: 120, title: '新增時間', field: 'createdAt', type: 'datetime', editable: 'never' },
  { width: 120, title: '更新時間', field: 'updatedAt', type: 'datetime', editable: 'never' },
]);


const columns2 = (companySel = {}) => ([
  { width: 80, title: '使用者名稱', field: 'username', editable: 'never' },
  { width: 80, title: '工號', field: 'jobNum' },
  { width: 80, title: '職稱', field: 'jobTitle' },
  { width: 80, title: '姓名', field: 'name' },
  { width: 80, title: '手機', field: 'phone' },
  // { width: 80, title: '電子信箱', field: 'email' },
  // { title: '介紹人', field: 'recommandName' },
  // { title: '優惠碼', field: 'discountCode' },
  {
    title: '1用戶 authUser', field: 'authUserA', type: 'boolean', width: 160,
    render: rowData => rowData.authUserA.map(item => <div>{companySel[item]}</div>)
  },
  {
    title: '2在職 authLogin', field: 'authLoginA', type: 'boolean', width: 160,
    render: rowData => rowData.authLoginA.map(item => <div>{companySel[item]}</div>)
  },
  {
    title: '3系統管理 authAdmin', field: 'authAdminA', type: 'boolean', width: 160,
    render: rowData => rowData.authAdminA.map(item => <div>{companySel[item]}</div>)
  },
  { width: 160, title: '備註', field: 'ps' },
  { width: 120, title: '新增時間', field: 'createdAt', type: 'datetime', editable: 'never' },
  { width: 120, title: '更新時間', field: 'updatedAt', type: 'datetime', editable: 'never' },
]);

const getAuthArray = (company, user, flag) => company[flag] ? _.union(user[flag + 'A'], [company.objectId]) : _.without(user[flag + 'A'], company.objectId);
const getAuth = (id, arr) => (arr.length && arr.indexOf(id) !== -1)

export default class Roles extends Component {
  state = {
    dataArrOri: [],
    dataArr: [],
    loading: true,
    loading2: true,
    companyArr: [],
    userObj: {},
    newData: {},
    oldData: {},
    newData2: {},
    oldData2: {},
    activeItem: 'user1',
    statusSel: '1',
    userArr: [],
    roleObj: {},
    companySel: {}
  }

  async componentDidMount() {
    this.handleFetch();

    const companies = await Parse.getSelection('company', {}, { name: 'name', sort: 'name' })
    this.setState({ companySel: companies.sel });
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const query = new Parse.Query(Parse.Role);
    query.ascending('order');
    const snap = await query.find();

    const dataArrOri = snap.map((data) => {
      // console.log(data)
      // const userArr = await data.relation("users").query().map(item => item.toJSON())
      // console.log(userArr)
      // each(async (relatedObject) => {
      //   console.log(relatedObject.toJSON())
      // })
      // data.get("users").find().then(item => console.log(item))
      return {
        // userArr,
        raw: data,
        permissions: data.getACL().permissionsById,
        ...data.toJSON()
      }
    });

    // console.log(dataArrOri)
    this.setState({ dataArrOri, dataArr: dataArrOri, loading: false });

    // const user = Parse.User.current();
    // const query2 = await new Parse.Query(Parse.Role).equalTo('users', user).find()
    // console.log(query2[0].toJSON())
  }

  handleUpdate = async () => {
    const { newData } = this.state
    // delete newData.permissions
    // delete newData.raw
    await Parse.saveData("_Role", { ps: newData.ps || '' })

    this.setState({ oldData: {}, newData: {} })
  }

  handleDelete = async () => {
    const { oldData2, roleObj } = this.state
    console.log(oldData2)

    var query = new Parse.Query(Parse.User);
    query.equalTo('email', oldData2.username);
    query.limit(1);
    const userArr = await query.find();

    if (userArr.length) { // 資料庫裡面有這個使用者了
      const user = userArr[0];
      // console.log(user)
      // console.log(roleObj)
      const role = roleObj.raw;
      // console.log(role)
      // console.log(role.getUsers())
      role.getUsers().remove(user);
      await role.save().catch(error => console.error(error));
    }
    // console.log(userArr)

    this.setState({ oldData2: {} })
  }

  handleData = async (roleObj) => {
    console.log(roleObj.raw)
    this.setState({ loading2: true })
    var query = new Parse.Query(Parse.Role);
    query.equalTo('name', roleObj.name);
    query.limit(1);
    const snap = await query.find();
    // console.log(snap.length)
    // console.log(snap[0])

    const userArr = await snap[0].relation("users").query().map(item => (item.toJSON()))
    // console.log(userArr)
    this.setState({ roleObj, userArr, loading2: false })
    // const snap = await role.getUsers().query();//.map(item => item.toJSON())
    // console.log(snap)
    // const users = await snap.map(item => item.toJSON());
    // console.log(users)

    // role.getUsers().add(usersToAddToRole);
    // role.getRoles().add(rolesToAddToRole);
    // role.save();
  }

  handleChange = (e, { name, value }) => {
  }

  newRole = async () => {
    const user = Parse.User.current();

    // if (!user.existed()) {
    //   var userACL = new Parse.ACL(user);
    //   userACL.setWriteAccess(user,false);
    //   user.setACL(userACL);
    //   user.save();
    // }

    const roleACL = new Parse.ACL();
    roleACL.setPublicReadAccess(true);
    roleACL.setWriteAccess(user, true);

    const role = new Parse.Role("Client", roleACL);
    await role.save();
    role.getUsers().add(user);
    await role.save();
    console.log(role.id)

    // role.save();
  }

  render() {
    const { companyObj } = this.props;
    const { dataArr, userArr, loading, roleObj, loading2, companySel } = this.state;
    // console.log(userObj)

    return (<>
      {/* <Button basic color='green' size='tiny' onClick={() => this.resetPW()}>
        <Icon name='user plus' /> 重設密碼</Button>
      <Button basic color='olive' size='tiny' onClick={() => this.sendMailTest()}>
        <Icon name='user plus' /> 測試</Button> */}
      <div className="content">
        <h2>角色</h2>
        {/* <Divider /> */}
        <Row>
          <Col sm='8'>
            {/* <Form.Group>
                <Form.Select style={{ position: 'relative', zIndex: 110 }}
                  options={userOpt}
                  onChange={this.handleChange}
                  value={statusSel}
                  name='statusSel' />
              </Form.Group> */}
            {/* <br /> */}

            <MaterialTable
              // components={{
              //   Toolbar: props => (<div>
              //     <div style={{ width: '150px', float: 'right', textAlign: 'right', padding: '15px' }}>
              //       <UserAdd
              //         {...this.props}
              //         refetch={this.handleFetch}
              //       />
              //     </div>
              //     <MTableToolbar {...props} />
              //     <div style={{ clear: 'both' }}></div>
              //   </div>),
              // }}
              isLoading={loading}
              localization={localization()}
              columns={columns()}
              options={{
                pageSize: 5,
                search: true,
                tableLayout: 'fixed',
              }}
              data={dataArr}
              // actions={[
              //   {
              //     icon: 'view_headline',
              //     tooltip: '查看',
              //     onClick: (event, rowData) => this.handleData(rowData)
              //   }
              // ]}
              onRowClick={(event, rowData) => this.handleData(rowData)}
              title={'所有角色'}
            // editable={{
            //   onRowUpdate: (newData, oldData) =>
            //     new Promise((resolve, reject) => {
            //       const data = [...dataArr];
            //       const index = data.indexOf(oldData);
            //       data[index] = newData;

            //       const obj = {
            //         ...newData,
            //         // updatedDate: date,
            //       }
            //       this.setState({ dataArr: data, newData: obj, oldData }, () => resolve());
            //     }).then(() => this.handleUpdate()),
            // }}
            />
          </Col>
        </Row>
        <br />
        {roleObj.objectId ?
          <MaterialTable
            components={{
              Toolbar: props => (<div>
                <div style={{ width: '150px', float: 'right', textAlign: 'right', padding: '15px' }}>
                  <RoleUserAdd
                    {...this.props}
                    roleObj={roleObj}
                    refetch={this.handleData}
                  />
                </div>
                <MTableToolbar {...props} />
                <div style={{ clear: 'both' }}></div>
              </div>),
            }}
            isLoading={loading2}
            localization={localization()}
            columns={columns2(companySel)} options={{
              addRowPosition: "first",
              pageSize: 5,
              search: true,
              tableLayout: 'fixed',
            }}
            data={userArr}
            // actions={[
            //   {
            //     icon: 'view_headline',
            //     tooltip: '查看',
            //     onClick: (event, rowData) => this.handleData(rowData)
            //   }
            // ]}
            // onRowClick={(event, rowData) => this.handleData(rowData)}
            title={roleObj.name + ' 使用者'}
            editable={{
              onRowDelete: oldData2 =>
                new Promise((resolve, reject) => {
                  let data = userArr;
                  const index = data.indexOf(oldData2);
                  data.splice(index, 1);
                  this.setState({ userArr: data, oldData2 }, () => resolve());
                }).then(() => this.handleDelete()),
            }}
          /> : null}
        <Button onClick={this.newRole}>新增</Button>
      </div>
    </>)
  }
}