import React, { Component } from 'react'
import { Form, Button, Modal, Table, Grid, Header, Icon, Confirm, Select } from 'semantic-ui-react'
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
import Parse from '../../../widget/parse'

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}

export default class Arrangement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      show: false,
      guidance: [],
      confirm: false,
      loading: false,
    };
  }

  async componentDidMount() {
    const { companyObj } = this.props
    const data = await Parse.queryData('Students', { companyId: companyObj.objectId });
    this.setState({ students: data }, () => this.handleFetch());
  }

  handleFetch = async (props = this.props) => {
    const { firebase, companyObj } = props
    const { isEdit, doc } = this.state;

    // firebase.firestore()
    //   .collection(`Dormitory`)
    //   .orderBy('dormitoryFrom', 'asc')
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ dorms: data });
    //     // console.log(data)
    //   }, err => {
    //     console.log(`Encountered error: ${err}`);
    //   });
    const dorms = await Parse.queryData('Dormitory', { companyId: companyObj.objectId }, { orderBy: 'dormitoryFrom' });
    this.setState({ dorms });

  }
  handleChange = (e, { value }) => this.setState({ value })

  render() {
    const { isEdit, doc, companyObj } = this.props
    const { guidance, form, disabled, readOnly, loading, show, confirm, dorms, students } = this.state
    const dormsArr = dorms && [dorms[4], dorms[2], dorms[3], dorms[0], dorms[1], dorms[6], dorms[5]]
    let rooms = []
    let tenants = []
    dormsArr && dormsArr.map((item, index) => {
      rooms = item.roomData.sort((a, b) => ('' + a.roomName).localeCompare(b.roomName))
    })
    rooms.map((item2, index2) =>
      new Array(Number(item2.roomType || '')).fill().map((bed, index) => {
        let studentName = item2[`studentName${index + 1}`]
        let studentNum = item2[`studentNum${index + 1}`]
        let student = students.filter(student => student.studentId === item2[`studentNum${index + 1}`])
        let studentClass = student.classSchool
        let studentType = student.studentType === '1' ? '在' : ''
        tenants.push({ ...item2, studentName, studentNum, studentClass, studentType })
      }
      ))
    return (
      <>
        <Header as='h2' dividing
          content='安排'
        // subheader='調査時間：2017年4月から 2018年12月 まで'
        />
        {isEdit ? <Button inverted={companyObj.inverted} color='blue' onClick={this.handleOpen} icon='add' content='追加' /> : ''}
        <Table celled structured>
          <Table.Header>
            <Table.Row>
              {/* {isEdit ? <Table.HeaderCell>#</Table.HeaderCell> : null} */}
              <Table.HeaderCell>分類</Table.HeaderCell>
              <Table.HeaderCell>番號</Table.HeaderCell>
              <Table.HeaderCell>名前</Table.HeaderCell>
              <Table.HeaderCell>性別</Table.HeaderCell>
              <Table.HeaderCell>クラス</Table.HeaderCell>
              <Table.HeaderCell>寮</Table.HeaderCell>
              <Table.HeaderCell>室</Table.HeaderCell>
              <Table.HeaderCell>そのまま</Table.HeaderCell>
              <Table.HeaderCell>移動</Table.HeaderCell>
              <Table.HeaderCell>退寮</Table.HeaderCell>
              <Table.HeaderCell>追伸</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tenants && tenants.length ? tenants.map(item => {
              return (
                <React.Fragment >
                  <Table.Row>
                    {/* {isEdit ? <Table.Cell style={{ padding: 10 }}>
                      <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} icon='edit' /><br /> <br />
                      <Button inverted={companyObj.inverted} color='red' size='small' onClick={() => this.showConfirm(item)} icon='delete' />
                    </Table.Cell> : null} */}
                    <Table.Cell>{item.studentType}</Table.Cell>
                    <Table.Cell>{item.studentNum}</Table.Cell>
                    <Table.Cell>{item.studentName}</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>{item.classSchool}</Table.Cell>
                    <Table.Cell>{item.dormitoryName}</Table.Cell>
                    <Table.Cell>{item.roomName}</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                </React.Fragment>)
            }) : <Table.Row><Table.Cell colSpan={isEdit ? 12 : 11} textAlign='center'>(資料無し)</Table.Cell></Table.Row>}
          </Table.Body>
        </Table>

        <Modal open={show} onClose={this.handleClose}>
          <Modal.Header style={style.flexCenter}>
            <h2 style={{ margin: 0 }}> {form._id ? '修正' : '追加'}記録</h2>
            <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
          </Modal.Header>
          <Modal.Content>
            <Form>
              <Grid>
                <Grid.Row columns='equal'>
                  <Grid.Column>
                    <Form.Input
                      label='代号'
                      name='dormitoryName'
                      value={form.dormitoryName || ''}
                      onChange={this.handleChange}
                      readOnly={readOnly}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      label='郵便番号'
                      name='dormitoryPostcode'
                      value={form.dormitoryPostcode || ''}
                      onChange={this.handleChange}
                      readOnly={readOnly}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      label='住所'
                      name='dormitoryAddr'
                      value={form.dormitoryAddr || ''}
                      onChange={this.handleChange}
                      readOnly={readOnly}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
          <Modal.Actions style={{ ...style.flex, justifyContent: 'flex-end' }}>
            <Button onClick={this.handleSubmit} inverted={companyObj.inverted} color='green' loading={loading} icon='checkmark' content={form._id ? '保存' : '追加'} />
            <Button onClick={this.handleClose} inverted={companyObj.inverted} color='red' icon='delete' content='クリア' />
          </Modal.Actions>
        </Modal>
        <Confirm
          header='削除'
          content='削除してもいいですか?'
          cancelButton='いいえ'
          confirmButton='はい'
          open={this.state.confirm}
          onCancel={this.hideConfirm}
          onConfirm={this.handleRemove}
          size='mini'
          centered={false} />
      </>
    )
  }

}
