import React, { Component } from 'react'
// import { Container, Icon, Image, Grid } from 'semantic-ui-react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Image, Loader, Icon, Form } from 'semantic-ui-react'
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from 'reactstrap';

import Parse from 'widget/parse'
import PreloadFile from '../web/PreloadFile';

class ModalUploadFiles extends Component {
  state = {
    loading: false,
    show: false,
    files: [],
    fileOptId: '',
    show2: false,
  }

  componentDidMount = () => {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { fileOpt = [] } = props;
    if (fileOpt && fileOpt.length) {
      const fileOptId = fileOpt[0].value;
      this.setState({ fileOptId })
    }
  }

  handleOpen = () => {
    if (this.props.dbName && this.props.fieldName) {
      this.setState({ show2: true })
    } else {
      this.setState({ show: true, });
    }
  }

  handleClose = () => {
    this.setState({
      files: [],
      show2: false,
      show: false,
    });
  }

  handleUploadImg = async () => {
    const { handleFetch, companyObj, rowData, handleData, pageObj, group } = this.props
    const { files } = this.state
    this.setState({ loading: true })
    const promises = [];
    for (let i = 0; i < files.length; i++) {
      const db = Parse.Object.extend("webimage");
      const newDoc = new db();
      promises.push(newDoc.save());
    }

    const docArr = await Promise.all(promises).catch(error => console.log("error", error));
    const promises2 = [];
    for (let i = 0; i < docArr.length; i++) {
      const file = files[i];
      const doc = docArr[i];
      const element = {
        objectId: doc.id,
        id: doc.id,
        _id: doc.id,
        companyId: companyObj.objectId,
        companyName: companyObj.name,
        pageId: pageObj.objectId,
        componentId: pageObj.name,
        navId: pageObj.nav1Id,
        name: file.name || '',
        group
      };
      promises2.push(Parse.saveData('webimage', element));
      promises2.push(Parse.saveImgThumb(file, 'webimage', doc.id, 'img1File', 'img1File2'));
    }

    await Promise.all(promises2).catch(error => console.log("error", error));
    this.setState({ loading: false })
    handleData(rowData)
    this.handleClose()
    handleFetch()
  }

  handleUploadFile = async () => {
    const { companyObj, rowData, handleData } = this.props
    const { files, fileOptId } = this.state
    this.setState({ loading: true })

    const promises = [];
    for (let i = 0; i < files.length; i++) {
      const db = Parse.Object.extend("fileCenter2");
      const newDoc = new db();
      promises.push(newDoc.save());
    }
    const docArr = await Promise.all(promises).catch(error => console.log("error", error));

    const promises2 = [];
    for (let i = 0; i < docArr.length; i++) {
      const file = files[i];
      const doc = docArr[i];
      const element = {
        objectId: doc.id,
        id: doc.id,
        _id: doc.id,
        companyId: companyObj.objectId,
        companyName: companyObj.name,
        fileCenter1Id: rowData.objectId,
        fileCenter1Text: rowData.name,
        name: file.name || '',
      };
      promises2.push(
        Parse.saveData('fileCenter2', element).then(async () => {
          await Parse.saveFile(file, '1', doc.id, 'fileCenter2', fileOptId);
        })
      );
    }
    await Promise.all(promises2).catch(error => console.log("error", error));

    this.setState({ loading: false })
    handleData()
    this.handleClose()
  }

  handleCustomUploadFile = async () => {
    const { companyObj, handleData, dbName, fieldName, condition = {} } = this.props
    const { files } = this.state
    this.setState({ loading: true })

    const promises = [];
    for (let i = 0; i < files.length; i++) {
      const db = Parse.Object.extend(dbName);
      const newDoc = new db();
      promises.push(newDoc.save());
    }
    const docArr = await Promise.all(promises).catch(error => console.log("error", error));

    const promises2 = [];
    for (let i = 0; i < docArr.length; i++) {
      const file = files[i];
      const doc = docArr[i];
      const element = {
        ...condition,
        objectId: doc.id,
        id: doc.id,
        _id: doc.id,
        companyId: companyObj.objectId,
        companyName: companyObj.name,
        name: file.name || '',
      };
      promises2.push(
        Parse.saveData(dbName, element).then(async () => {
          await Parse.saveFile(file, '1', doc.id, dbName, fieldName);
        })
      );
    }
    await Promise.all(promises2).catch(error => console.log("error", error));

    this.setState({ loading: false })
    handleData()
    this.handleClose()
  }

  handleUploadAnnounceFile = async () => {
    const { rowData, AnnounceCenterObj, handleData } = this.props
    const { files } = this.state
    this.setState({ loading: true })
    const promises = [];
    for (let i = 0; i < files.length; i++) {
      const db = Parse.Object.extend("webimage");
      const newDoc = new db();
      promises.push(newDoc.save());
    }
    const docArr = await Promise.all(promises).catch(error => console.log("error", error));

    const promises2 = [];
    for (let i = 0; i < docArr.length; i++) {
      const file = files[i];
      const doc = docArr[i];
      const element = {
        objectId: doc.id,
        id: doc.id,
        _id: doc.id,
        companyId: AnnounceCenterObj.companyObj.objectId,
        companyName: AnnounceCenterObj.companyObj.name,
        pageId: rowData.objectId,
        name: file.name || '',
        type: 'announce'
      };
      promises2.push(
        Parse.saveData('webimage', element).then(async () => {
          await Parse.saveFile(file, '1', doc.id, 'webimage', 'file1');
        })
      );
    }
    await Promise.all(promises2).catch(error => console.log("error", error));

    this.setState({ loading: false })
    handleData()
    this.handleClose()
  }

  handleChangeFileOpt = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  handleChangeFile = (files) => {
    this.setState({ files })
  }

  render() {
    // const { rowData = {}, isImage, fileOpt = [], } = this.props;
    // const { loading, show, fileOptId, files, show2 } = this.state;
    const { rowData = {}, isImage, fileOpt = [], isAnnounce, isEdit = true } = this.props;
    const { loading, show, fileOptId, files, show2 } = this.state;

    return (<>
      <Button color='primary' disabled={!isEdit} onClick={this.handleOpen}><i className="fa fa-plus" /> 新增{isImage === '1' ? '照片' : '檔案'}</Button>
      <Modal
        isOpen={show}
        toggle={this.handleClose}
        size="lg"
      // className="mh-90 h-90 mw-100 w-90"
      // style={{ paddingTop: '0px' }}
      // style={{ maxWidth: '1600px', width: '80%' }}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
            <span aria-hidden="true">×</span>
          </button>
              新增 {rowData.name} {isImage === '1' ? '相簿照片' : '檔案'}
        </ModalHeader>
        <ModalBody>
          {fileOpt.length ? <Row>
            <Col md={6}>
              <Form.Select label="上傳欄位" style={{ marginRight: '5px' }}
                options={fileOpt} onChange={this.handleChangeFileOpt} name='fileOptId' value={fileOptId} />
            </Col>
          </Row> : null}<br />
          <PreloadFile multiple={true} isImage={isImage} handleChangeFile={this.handleChangeFile} />
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          {isAnnounce === '1' ? <>
            <Button color='success' onClick={this.handleUploadAnnounceFile} disabled={!files.length} >
              {!loading ? <><i className="fa fa-upload" /> 上傳</> : <Loader active inverted inline size='small' />}</Button>{' '}</>
            : <><Button color='success' onClick={isImage === '1' ? this.handleUploadImg : this.handleUploadFile} disabled={!files.length} >
              {!loading ? <><i className="fa fa-upload" /> 上傳</> : <Loader active inverted inline size='small' />}</Button>{' '}</>}
          <Button icon='x' onClick={this.handleClose}>關閉</Button>
        </ModalFooter>
      </Modal>

      <Modal    //有輸入dbName和fieldName時，使用CustomUpload
        isOpen={show2}
        toggle={this.handleClose}
        size="lg"
      // className="mh-90 h-90 mw-100 w-90"
      // style={{ paddingTop: '0px' }}
      // style={{ maxWidth: '1600px', width: '80%' }}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
            <span aria-hidden="true">×</span>
          </button>
              新增 {rowData.name} {isImage === '1' ? '相簿照片' : '檔案'}
        </ModalHeader>
        <ModalBody>
          <PreloadFile multiple={true} isImage={isImage} handleChangeFile={this.handleChangeFile} />
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button color='success' onClick={isImage === '1' ? this.handleUploadImg : this.handleCustomUploadFile} disabled={!files.length} >
            {!loading ? <><i className="fa fa-upload" /> 上傳</> : <Loader active inverted inline size='small' />}</Button>{' '}
          <Button icon='x' onClick={this.handleClose}>關閉</Button>
        </ModalFooter>
      </Modal>
    </>);
  }
}

export default ModalUploadFiles;