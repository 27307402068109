import React, { Component } from 'react'
import { Container } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
// import { Row, Col } from 'reactstrap';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import PageHeader from 'components/Headers/PageHeader';
import LocaleCompany from './LocaleCompany'
import LocaleContent from './LocaleContent';

const pageType = { '1': '頁面', '2': '目錄', '3': '一頁式', '4': '頁腳', '5': '非選單頁面' }

const columns1 = () => ([
  // { width: 60, title: '開啟', field: 'isOpen', type: 'boolean', initialEditValue: true, editable: 'never' },
  { width: 60, title: '類別', field: 'pageType', lookup: pageType, initialEditValue: '1', editable: 'never' },
  // { width: 60, title: '順序', field: 'order', type: 'numeric', editable: 'never' },
  { width: 60, title: '路徑', field: 'path1', editable: 'never' },
  { width: 160, title: '🇹🇼 繁體中文', field: 'name' },
  { width: 160, title: '🇨🇳 简体中文', field: 'zhHant' },
  { width: 160, title: '🇯🇵 日本語', field: 'ja' },
  { width: 160, title: '🇺🇲 English', field: 'en' },
  { width: 160, title: '🇻🇳 Vietnam', field: 'vi' },
]);

const columns2 = () => ([
  // { width: 50, title: '開啟', field: 'isOpen', type: 'boolean', initialEditValue: true, editable: 'never' },
  // { width: 50, title: '順序', field: 'order', type: 'numeric', editable: 'never' },
  { width: 60, title: '路徑', field: 'path2', editable: 'never' },
  // { width: 160, title: '名稱', field: 'name' },
  { width: 160, title: '🇹🇼 繁體中文', field: 'name' },
  { width: 160, title: '🇨🇳 简体中文', field: 'zhHant' },
  { width: 160, title: '🇯🇵 日本語', field: 'ja' },
  { width: 160, title: '🇺🇲 English', field: 'en' },
  { width: 160, title: '🇻🇳 Vietnam', field: 'vi' },
]);

export default class LocaleManage extends Component {
  state = {
    typeId: '',
    data: [],
    data2: [],
    newData: {},
    loading1: true,
    loading2: false,
    rowData: {},
    rowData2: {},
    pageObj: {}
  }

  async componentDidMount() {
    const { companyObj } = this.props;
    const data = await Parse.queryData('webnav', { companyId: companyObj.objectId })
    this.setState({ data, loading1: false })
  }

  // handleAdd = async (key) => {
  //   const { companyObj } = this.props;
  //   const { newData } = this.state;
  //   newData.companyId = companyObj.objectId;
  //   newData.companyName = companyObj.name;

  //   Parse.saveData('webnav', newData);
  // }

  handleUpdate = async (key) => {
    const { newData } = this.state;
    Parse.saveData('webnav', newData);
  }

  // handleDelete = async (key) => {
  //   const { oldData } = this.state
  //   Parse.deleteData('webnav', oldData);
  //   this.setState({ oldData: {} })
  // }

  handleData = async (rowData) => {
    this.setState({ rowData, loading2: false });
  }
  handleData2 = async (rowData2) => {
    this.setState({ rowData2 });
  }

  render() {
    const { data = [], loading1, loading2, rowData, rowData2 } = this.state;

    const data1 = data.filter(item => item.level === '1' && (item.pageType === '1' || item.pageType === '2' || item.pageType === '3' || item.pageType === '5'));
    const data2 = data.filter(item => item.level === '2' && item.nav1Id === rowData.objectId);
    const data4 = data.filter(item => item.pageType === '4');

    return (
      <>
        <ColorNavbar  {...this.props} data={data} data1={data1} />
        <PageHeader text='多國語系' url='/images/blog/full/22.jpg' />
        <Container style={{ minHeight: '400px' }}>
          <br />
          <LocaleCompany {...this.props} />
          <br />
          <br />
          <MaterialTable
            isLoading={loading1}
            localization={localization()}
            columns={columns1()} options={{
              addRowPosition: "first",
              // addRowPosition: "first",
              exportButton: true,
              pageSize: 6,//data.length || 5,
              search: false,
              tableLayout: 'fixed',
            }}
            data={data1}
            title="大項 (首頁使用第一項)"
            actions={[
              {
                icon: 'view_headline',
                tooltip: '查看',
                onClick: (event, rowData) => this.handleData(rowData)
              }
            ]}
            onRowClick={(event, rowData) => this.handleData(rowData)}
            editable={{
              // onRowAdd: newData =>
              //   new Promise(async (resolve, reject) => {
              //     const data1 = data;
              //     const db = Parse.Object.extend("webnav");
              //     const newDoc = new db();
              //     const doc = await newDoc.save();
              //     newData.objectId = doc.id;
              //     newData.id = doc.id;
              //     newData._id = doc.id;
              //     newData.level = '1';

              //     data1.push(newData);
              //     this.setState({ data: data1, newData }, () => resolve());
              //   }).then(() => this.handleAdd("1")),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const data1 = data;
                  const index = data1.indexOf(oldData);
                  data1[index] = newData;
                  // console.log(data)
                  this.setState({ data: data1, newData, oldData }, () => resolve());
                }).then(() => this.handleUpdate()),
              // onRowDelete: oldData =>
              //   new Promise((resolve, reject) => {
              //     let data1 = data;
              //     const index = data1.indexOf(oldData);
              //     data1.splice(index, 1);
              //     this.setState({ data: data1, oldData }, () => resolve());
              //   }).then(() => this.handleDelete("1")),
            }}
          />
          <br />
          <br />
          {!rowData.objectId || (rowData.pageType !== '2' && rowData.pageType !== '3' && rowData.pageType !== '5') ? null : <MaterialTable
            localization={localization()}
            isLoading={loading2}
            columns={columns2()} options={{
              addRowPosition: "first",
              // addRowPosition: "first",
              exportButton: true,
              pageSize: 6,//data2.length || 5,
              search: false,
              tableLayout: 'fixed',
            }}
            data={data2}
            title={rowData.name + " 選項"}
            actions={[
              {
                icon: 'view_headline',
                tooltip: '查看',
                onClick: (event, rowData) => this.handleData2(rowData)
              }
            ]}
            onRowClick={(event, rowData) => this.handleData2(rowData)}
            editable={{
              // onRowAdd: newData =>
              //   new Promise(async (resolve, reject) => {
              //     const data2 = data;
              //     const db = Parse.Object.extend("webnav");
              //     const newDoc = new db();
              //     const doc = await newDoc.save();
              //     newData.objectId = doc.id;
              //     newData.id = doc.id;
              //     newData._id = doc.id;
              //     newData.level = '2';
              //     newData.nav1Id = rowData.objectId;

              //     data2.push(newData);
              //     this.setState({ data: data2, data2, newData }, () => resolve());
              //   }).then(() => this.handleAdd("2")),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const data2 = data;
                  const index = data2.indexOf(oldData);
                  data2[index] = newData;

                  this.setState({ data: data2, newData, oldData }, () => resolve());
                }).then(() => this.handleUpdate()),
              // onRowDelete: oldData =>
              //   new Promise((resolve, reject) => {
              //     let data2 = data;
              //     const index = data.indexOf(oldData);
              //     data2.splice(index, 1);
              //     this.setState({ data: data2, oldData }, () => resolve());
              //   }).then(() => this.handleDelete("2")),
            }}
          />}
          <br />
          {rowData2 && rowData2.objectId ? <LocaleContent {...this.props} pageObj={rowData2} pageId={rowData2.objectId} /> : null}
          <br />
        </Container>
        <FooterBlack />
      </>
    )
  }
}