import React, { Component } from 'react'
import { Form, Button, Modal, Table, Grid, Header, Icon, Confirm, Select } from 'semantic-ui-react'
import _ from 'lodash'

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}

export default class ArrangeDorm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      show: false,
      guidance: [],
      confirm: false,
      loading: false,
    };
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = (props = this.props) => {
    const { firebase, match } = props;
    // const { filesData } = this.state
    // const { _id } = match.params;

    // firebase.firestore().collection(`Dormitory`).get().then(snap => {
    //   var promises = [];
    //   snap.forEach(item => {
    //     var promise = firebase.firestore().collection(`Dormitory/${_id}/Room/${item.id}/files`)
    //       .get().then(snapshot => {
    //         // console.log(snapshot.size);
    //         const filesData = []
    //         snapshot.forEach(fileItem => filesData.push({ _id: fileItem.id, ...fileItem.data(), parttimeId: item.id }));
    //         return { _id: item.id, ...item.data(), filesData };
    //       });
    //     promises.push(promise);
    //   });
    //   Promise.all(promises).then(results => {
    //     // console.log('all promises were done')
    //     // console.log(results);
    //     this.setState({ guidance: results })
    //   }).catch(error => {
    //     console.log(error);
    //   });

    // }, err => {
    //   console.log(`Encountered error: ${err}`);
    // });
  }

  handleSubmit = async () => {
    const { firebase, doc, semesterYear, semester } = this.props;
    const { form, guidance } = this.state;
    const db = firebase.firestore();

    this.setState({ loading: true });
    const newGuidance = [...guidance];
    // if (!form._id) {
    //   const docRef = db.collection(`Dormitory/${doc._id}/Room`).doc();
    //   const obj = {
    //     ...form,
    //     _id: docRef.id,
    //     studentId: doc._id,
    //     studentIdKey: doc.studentId,
    //     studentName: doc.jpnName,
    //     semesterYear,
    //     semester,
    //     createdAt: new Date()
    //   };
    //   await docRef.set(obj);
    //   newGuidance.push(obj);
    // } else {
    //   await db.collection(`Dormitory/${doc._id}/Room`).doc(form._id).update(form);
    //   let index = newGuidance.findIndex((item => item._id === form._id));
    //   newGuidance[index] = form;
    // }

    this.setState({ guidance: newGuidance, loading: false });
    this.handleClose();
  }

  handleChange = (event, data) => {
    const { name, value } = data
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    })
  }

  handleOpen = () => {
    this.setState({ show: true })
  }

  handleEdit = (guidanceObj) => {
    this.setState({ show: true, form: { ...guidanceObj } });
  }

  handleClose = () => {
    this.setState({ show: false, form: {}, readOnly: false, fileObj: {} });
  }

  handleRemove = async () => {
    const { firebase, doc } = this.props
    const { form, guidance } = this.state;
    // await firebase.firestore().collection(`Dormitory/${doc._id}/Room`).doc(form._id).delete();
    const newGuidance = [...guidance];
    let index = newGuidance.findIndex((item => item._id === form._id));
    newGuidance.splice(index, 1);
    this.setState({ guidance: newGuidance });

    this.handleClose();
    this.hideConfirm();
  }

  showConfirm = (guidanceObj) => {
    this.setState({ confirm: true, form: { ...guidanceObj } });
  }
  hideConfirm = () => this.setState({ confirm: false, form: {} });

  fields = [
    {
      id: 'dormitoryName',
      title: '代号'
    },
    {
      id: 'roomNum',
      // width: 100,
      title: '間数'
    },
    {
      id: 'bedNum',
      // width: 100,
      title: '床数'
    },
    {
      id: 'bedNum1',
      // width: 100,
      title: '入住床数'
    },
    {
      id: 'bedNum0',
      // width: 100,
      title: '空床数'
    },
    {
      id: 'dormitoryPostcode',
      // width: 120,
      title: '郵便番号'
    },
    {
      id: 'dormitoryAddr',
      title: '住所'
    }
  ]
  render() {
    const { isEdit, doc, companyObj } = this.props
    const { guidance, form, disabled, readOnly, loading, show, confirm, } = this.state
    return (
      <>
        <Header as='h2' dividing
          content='予想状況'
        // subheader='調査時間：2017年4月から 2018年12月 まで'
        />
        {isEdit ? <Button inverted={companyObj.inverted} color='blue' onClick={this.handleOpen} icon='add' content='追加' /> : ''}
        <Table celled structured>
          <Table.Header>
            <Table.Row>
              {isEdit ? <Table.HeaderCell>#</Table.HeaderCell> : null}
              <Table.HeaderCell>期別</Table.HeaderCell>
              <Table.HeaderCell>名前</Table.HeaderCell>
              <Table.HeaderCell>第一予想</Table.HeaderCell>
              <Table.HeaderCell>第二予想</Table.HeaderCell>
              <Table.HeaderCell>第三予想</Table.HeaderCell>
              <Table.HeaderCell>決定</Table.HeaderCell>
              <Table.HeaderCell>備考</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {guidance.length ? guidance.map((item, index) => (<React.Fragment key={index}>
              <Table.Row>
                {isEdit ? <Table.Cell style={{ padding: 10 }}>
                  <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} icon='edit' /><br /> <br />
                  <Button inverted={companyObj.inverted} color='red' size='small' onClick={() => this.showConfirm(item)} icon='delete' />
                </Table.Cell> : null}
                <Table.Cell>{item.dormitoryName}</Table.Cell>
                <Table.Cell>{item.roomNum}</Table.Cell>
                <Table.Cell>{item.bedNum}</Table.Cell>
                <Table.Cell>{item.bedNum1}</Table.Cell>
                <Table.Cell>{item.bedNum0}</Table.Cell>
                <Table.Cell>{item.dormitoryPostcode}</Table.Cell>
                <Table.Cell>{item.dormitoryAddr}</Table.Cell>
              </Table.Row>
            </React.Fragment>)) : <Table.Row><Table.Cell colSpan={isEdit ? 8 : 7} textAlign='center'>(資料無し)</Table.Cell></Table.Row>}
          </Table.Body>
        </Table>

        <Modal open={show} onClose={this.handleClose}>
          <Modal.Header style={style.flexCenter}>
            <h2 style={{ margin: 0 }}> {form._id ? '修正' : '追加'}記録</h2>
            <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
          </Modal.Header>
          <Modal.Content>
            <Form>
              <Grid>
                <Grid.Row columns='equal'>
                  <Grid.Column>
                    <Form.Input
                      label='代号'
                      name='dormitoryName'
                      value={form.dormitoryName || ''}
                      onChange={this.handleChange}
                      readOnly={readOnly}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      label='郵便番号'
                      name='dormitoryPostcode'
                      value={form.dormitoryPostcode || ''}
                      onChange={this.handleChange}
                      readOnly={readOnly}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      label='住所'
                      name='dormitoryAddr'
                      value={form.dormitoryAddr || ''}
                      onChange={this.handleChange}
                      readOnly={readOnly}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
          <Modal.Actions style={{ ...style.flex, justifyContent: 'flex-end' }}>
            <Button onClick={this.handleSubmit} inverted={companyObj.inverted} color='green' loading={loading} icon='checkmark' content={form._id ? '保存' : '追加'} />
            <Button onClick={this.handleClose} inverted={companyObj.inverted} color='red' icon='delete' content='キャンセル' />
          </Modal.Actions>
        </Modal>
        <Confirm
          header='削除'
          content='削除してもいいですか?'
          cancelButton='いいえ'
          confirmButton='はい'
          open={this.state.confirm}
          onCancel={this.hideConfirm}
          onConfirm={this.handleRemove}
          size='mini'
          centered={false} />
      </>
    )
  }
}