
import React, { Component } from 'react'
import { Container, Grid, Header, Form, Segment, Menu, Table, Icon } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'

// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { Card, CardBody, Row, Col } from "reactstrap";

// import _ from 'underscore'

// import RowsDnd from './RowsDnd'

const localizer = momentLocalizer(moment);

var today = new Date();
var y = today.getFullYear();
var m = today.getMonth();
var d = today.getDate();

const event = [
  {
    title: "All Day Event",
    allDay: true,
    start: new Date(y, m, 1),
    end: new Date(y, m, 1)
  },
  {
    title: "Meeting",
    start: new Date(y, m, d - 1, 10, 30),
    end: new Date(y, m, d - 1, 11, 30),
    allDay: false,
    color: "green"
  },
  {
    title: "Lunch",
    start: new Date(y, m, d + 7, 12, 0),
    end: new Date(y, m, d + 7, 14, 0),
    allDay: false,
    color: "red"
  },
];

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

class ProgramList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataArr: [],
      loading: false,
      events: event,
      alert: null,
    };
  }

  selectedEvent = event => {
    alert(event.title);
  };
  addNewEventAlert = slotInfo => {
    this.setState({
      alert: (
        <SweetAlert
          input
          showCancel
          style={{ display: "block" }}
          title="Input something"
          onConfirm={e => this.addNewEvent(e, slotInfo)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
        />
      )
    });
  };
  addNewEvent = (e, slotInfo) => {
    var newEvents = this.state.events;
    newEvents.push({
      title: e,
      start: slotInfo.start,
      end: slotInfo.end
    });
    this.setState({
      alert: null,
      events: newEvents
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };
  eventColors = (event, start, end, isSelected) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  };

  render() {
    const { companyObj } = this.props;
    const { dataArr, loading, rowData, alert } = this.state;

    return (
      <>
        <div className="content">
          {alert}
          <Card className="card-calendar">
            <CardBody>
              <Calendar
                selectable
                localizer={localizer}
                events={this.state.events}
                defaultView="week"
                scrollToTime={new Date(1970, 1, 1, 6)}
                defaultDate={new Date()}
                onSelectEvent={event => this.selectedEvent(event)}
                onSelectSlot={slotInfo => this.addNewEventAlert(slotInfo)}
                eventPropGetter={this.eventColors}
                style={{ height: 1200 }}
              />
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

export default ProgramList;
