import React, { Component } from 'react'
import { Button, Form, Checkbox, Table, Select, Input } from 'semantic-ui-react';
// import MaterialTable from 'material-table'
// import Icon from '@material-ui/core/Icon'
import { Modal, ModalHeader, ModalBody, ModalFooter, Table as Table2 } from 'reactstrap';
import { IconButton } from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';
// import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

export default class ModalNamelist extends Component {
  state = {
    open: false,
    suirChecked: false,
    checkedValue: this.props.rowData.jointArr || [],
    proportionArr: [],
    radioValue: (this.props.rowData.jointObjArr
      && this.props.rowData.jointObjArr.find(item => item.main === '1').objectId) || '',
    proportionSel: {},
  }
  componentDidMount = async () => {
    const { rowData } = this.props
    console.log(rowData)
    let sel = {}
    rowData.jointObjArr && rowData.jointObjArr.forEach(item => {
      sel = {
        ...sel,
        [item.objectId]: item.proportion
      }
    })
    this.setState({ proportionSel: sel })
  }
  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  toggle = () => {
    const { people1 } = this.props
    const { suirChecked } = this.state;

    let checkedValue = [];

    if (!suirChecked) {
      people1.forEach(({ objectId }) => { checkedValue.push(objectId); });
    } else {
      checkedValue = [];
    }
    this.setState({ checkedValue, suirChecked: !suirChecked });
  }

  handleClick = (e, item) => {
    // console.log('Click', e.target.checked, item)

    const { checkedValue } = this.state;
    const { value, checked } = item;

    const indexof = checkedValue.indexOf(value);
    if (checkedValue.indexOf(value) === -1) {
      checkedValue.push(value);
    } else {
      checkedValue.splice(indexof, 1)
    }
    this.setState({ checkedValue });
  }

  handleRadioChange = (e, { value }) => this.setState({ radioValue: value })

  handleSubmit1 = async () => {
    const { employee, rowData, onRowDataChange } = this.props;
    const { radioValue, checkedValue, proportionSel } = this.state;

    const nameArr = []
    const proportionArr = []
    const jointArr = []
    const jointObjArr = []

    const main = employee.find(item => item.objectId === radioValue);
    if (main) {
      const { objectId, jobNum, name, username } = main
      const proportion = proportionSel[objectId];
      if (proportion) {
        nameArr.push(jobNum || name || username);
        proportionArr.push(proportion);
        jointArr.push(objectId)
        jointObjArr.push({ objectId, jobNum, name, username, proportion, main: "1" })
      } else {
        window.alert('請輸入比例');
        return;
      }
    }

    const arr = checkedValue.filter(item => item !== radioValue);
    arr.forEach(item => {
      const obj = employee.find(item2 => item2.objectId === item);
      if (obj) {
        const { objectId, jobNum, name, username } = obj
        const proportion = proportionSel[objectId];
        if (proportion) {
          nameArr.push(jobNum || name || username);
          proportionArr.push(proportion);
          jointArr.push(objectId)
          jointObjArr.push({ objectId, jobNum, name, username, proportion, main: "0" })
        } else {
          window.alert('請輸入比例');
          return;
        }
      }
    })
    // console.log(nameArr, proportionArr, jointArr, jointArr)
    let total = 0;
    proportionArr.forEach(item => { total += Number(item) })
    if (total !== 100) {
      window.alert(`比例合計為${total} 不為100，請確認`);
      return;
    }
    console.log(nameArr)
    console.log(jointObjArr)
    onRowDataChange({ ...rowData, jointArr, jointObjArr, joint: nameArr, proportion: proportionArr.join(',') });
    this.close();
  }

  handleChange = (e, { name, value }) => {
    const proportionSel = { ...this.state.proportionSel, [name]: value };
    this.setState({ proportionSel })
  }

  render() {
    const { employee } = this.props
    const { open, suirChecked, checkedValue, radioValue, proportionSel } = this.state

    return (<>
      <IconButton onClick={this.open}><Menu fontSize='small' /></IconButton>
      <Modal
        isOpen={open}
        size='lg'
        toggle={this.close}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggle}>
            <span aria-hidden="true">×</span>
          </button>
          代入員工資料
        </ModalHeader>
        <ModalBody>
          <Table striped size='small'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>
                  <Checkbox
                    label='全選'
                    onClick={this.toggle}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>姓名</Table.HeaderCell>
                <Table.HeaderCell>比例 (%)</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {employee.map(({ objectId, jobNum, name, username, proportion, addr }) => (
                <Table.Row key={'p1' + objectId}>
                  <Table.Cell>
                    <Form.Radio
                      label='主'
                      value={objectId}
                      checked={radioValue === objectId}
                      onChange={this.handleRadioChange}
                      name='radioGroup'
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Checkbox
                      label='副'
                      value={objectId}
                      checked={radioValue !== objectId && checkedValue.indexOf(objectId) !== -1}
                      disabled={suirChecked}
                      onClick={this.handleClick}
                    />
                  </Table.Cell>
                  <Table.Cell>{jobNum} {name || username}</Table.Cell>
                  <Table.Cell>
                    <Input type='number' name={objectId} value={proportionSel[objectId] || ''} onChange={this.handleChange}>{proportion}</Input>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button icon='tag' primary content='代入' onClick={this.handleSubmit1} />
          <Button icon='x' content='關閉' onClick={this.close} />
        </ModalFooter>
      </Modal>
    </>)
  }
}
