import AuthService from '../../services/AuthService';
import { setCurrentUser, registerSuccess, logoutUser, getErrors } from "./actionCreators";

/**
 * Login user action
 */
export const loginUser = (state, history, companyObj) => dispatch => {
  AuthService.login(state.email, state.password).then(resp => {
    // console.log(companyObj, resp.toJSON())
    const user = resp.toJSON();
    if (!user.authLoginA || !user.authLoginA.length || user.authLoginA.indexOf(companyObj.objectId) === -1) {
      dispatch(getErrors({
        // loginError: error.response.data.error
        loginError: "本系統無開通此帳號的權限，請洽系統管理者協助開啟"
      }));
    }
    // companyObj
    else if (resp.id) {
      dispatch(setCurrentUser(resp));
      AuthService.saveToken(resp.id);
      // window.location.href = '/';
      history.push('/admin/dashboard');
    }
  }).catch(error => {
    // if (error.response.data) {
    if (error.code) {
      dispatch(getErrors({
        // loginError: error.response.data.error
        loginError: error.message
      }));
    }
  });
}

/**
 * Logout action
 */
export const logout = (history, toUrl = '/auth/login') => dispatch => {
  AuthService.logout();
  dispatch(logoutUser());
  // if (toUrl === '/') {
  //   window.location.href = '/';
  // } else {
  history.push(toUrl)
  // }
  // window.location.reload();
}

/**
 * Register user action
 */
export const registerUser = (data, history) => dispatch => {
  AuthService.register(data).then(resp => {
    if (resp.data.success) {
      dispatch(registerSuccess());
      history.push('/login');
    }
  }).catch(error => {
    if (error.response.data) {
      dispatch(getErrors({
        registerError: error.response.data.error
      }));
    }
  });
}
