

export const salaryPage = (doc, dataArr, params) => {
  const { companyObj } = params[0]
  console.log(params)
  console.log(dataArr)
  for (let i = 0; i < dataArr.length; i++) {
    if (i % 4 === 0) {
      doc.setFont('msjh')
      doc.rect(10, 80, 190, 450);
      doc.rect(5, 5, 200, 550);
      doc.setFontSize(14);
      doc.text(companyObj.name, 45, 30)
      doc.text('薪資袋', 130, 30)
      doc.text(dataArr[i].year + '   年     ' + dataArr[i].month + '  月 份', 30, 50)
      doc.text('職別：', 20, 70)
      doc.text(dataArr[i].jobNum, 70, 70)
      doc.text('姓名：', 110, 70)
      doc.text(dataArr[i].name, 155, 70)

      doc.setFontSize(12);
      doc.text('職務加給', 45, 95)
      doc.text('底薪', 45, 115)
      doc.text('全勤', 45, 135)
      doc.text('工作獎金', 45, 155)
      doc.text('伙食', 45, 175)
      doc.text('佣金', 45, 195)
      doc.text('資保息', 45, 215)
      doc.text('端午禮金', 45, 235)
      doc.text('油資', 45, 255)

      doc.text('出勤紀錄', 45, 345)
      doc.text('代墊', 45, 365)
      doc.text('勞健保', 45, 385)

      doc.text('0', 130, 95)
      doc.text(dataArr[i].baseSalary || '', 130, 115)
      doc.text(dataArr[i].fullAttendance || '', 130, 135)
      doc.text(dataArr[i].workBonus || '', 130, 155)
      doc.text(dataArr[i].foodStipend || '', 130, 175)
      doc.text(dataArr[i].commission || '', 130, 195)
      doc.text(dataArr[i].capitalGuaranteed || '', 130, 215)
      // doc.text(dataArr[i].duanwuGift, 130, 235)
      doc.text(dataArr[i].oilMoney || '', 130, 235)

      doc.setFontSize(14);
      doc.text('應', 15, 110)
      doc.text('支', 15, 160)
      doc.text('金', 15, 210)
      doc.text('額', 15, 260)
      doc.text('合     計', 30, 320)
      doc.text(dataArr[i].priceTotal, 130, 320)

      doc.text('應', 15, 360)
      doc.text('扣', 15, 390)
      doc.text('金', 15, 420)
      doc.text('額', 15, 450)
      doc.text('合     計', 30, 488)

      doc.setFontSize(16);
      doc.text('實領金額', 30, 518)


      //直線
      doc.line(40, 80, 40, 300)
      doc.line(40, 330, 40, 470)

      doc.line(120, 80, 120, 530)
      //橫線
      doc.line(10, 300, 200, 300)  //合計
      doc.line(10, 330, 200, 330)  //合計

      doc.line(10, 470, 200, 470)  //合計
      doc.line(10, 495, 200, 495)  //合計

      doc.line(40, 100, 200, 100)
      doc.line(40, 120, 200, 120)
      doc.line(40, 140, 200, 140)
      doc.line(40, 160, 200, 160)
      doc.line(40, 180, 200, 180)
      doc.line(40, 200, 200, 200)
      doc.line(40, 220, 200, 220)
      doc.line(40, 240, 200, 240)
      doc.line(40, 260, 200, 260)
      doc.line(40, 280, 200, 280)

      doc.line(40, 350, 200, 350)
      doc.line(40, 370, 200, 370)
      doc.line(40, 390, 200, 390)
      doc.line(40, 410, 200, 410)
      doc.line(40, 430, 200, 430)
      doc.line(40, 450, 200, 450)
      doc.line(40, 470, 200, 470)

    } else if ((i + 3) % 4 === 0) { //中間左
      doc.rect(220, 80, 190, 450);
      doc.rect(215, 5, 200, 550);
      doc.setFontSize(14);
      doc.text(companyObj.name, 255, 30)
      doc.text('薪資袋', 340, 30)
      doc.text(dataArr[i].year + '   年     ' + dataArr[i].month + '  月 份', 240, 50)
      doc.text('職別：', 230, 70)
      doc.text(dataArr[i].jobNum, 280, 70)
      doc.text('姓名：', 320, 70)
      doc.text(dataArr[i].name, 365, 70)

      doc.setFontSize(12);
      doc.text('職務加給', 255, 95)
      doc.text('底薪', 255, 115)
      doc.text('全勤', 255, 135)
      doc.text('工作獎金', 255, 155)
      doc.text('伙食', 255, 175)
      doc.text('佣金', 255, 195)
      doc.text('資保息', 255, 215)
      doc.text('端午禮金', 255, 235)
      doc.text('油資', 255, 255)

      doc.text('出勤紀錄', 255, 345)
      doc.text('代墊', 255, 365)
      doc.text('勞健保', 255, 385)

      doc.text('0', 340, 95)
      doc.text(dataArr[i].baseSalary || '', 340, 115)
      doc.text(dataArr[i].fullAttendance || '', 340, 135)
      doc.text(dataArr[i].workBonus || '', 340, 155)
      doc.text(dataArr[i].foodStipend || '', 340, 175)
      doc.text(dataArr[i].commission || '', 340, 195)
      doc.text(dataArr[i].capitalGuaranteed || '', 340, 215)
      // doc.text(dataArr[i].duanwuGift, 340, 235)
      doc.text(dataArr[i].oilMoney || '', 340, 235)

      doc.setFontSize(14);
      doc.text('應', 225, 110)
      doc.text('支', 225, 160)
      doc.text('金', 225, 210)
      doc.text('額', 225, 260)
      doc.text('合     計', 240, 320)
      doc.text(dataArr[i].priceTotal, 340, 320)

      doc.text('應', 225, 360)
      doc.text('扣', 225, 390)
      doc.text('金', 225, 420)
      doc.text('額', 225, 450)
      doc.text('合     計', 240, 488)

      doc.setFontSize(16);
      doc.text('實領金額', 240, 518)


      //直線
      doc.line(250, 80, 250, 300)
      doc.line(250, 330, 250, 470)

      doc.line(330, 80, 330, 530)
      //橫線
      doc.line(220, 300, 410, 300)  //合計
      doc.line(220, 330, 410, 330)  //合計

      doc.line(220, 470, 410, 470)  //合計
      doc.line(220, 495, 410, 495)  //合計

      doc.line(250, 100, 410, 100)
      doc.line(250, 120, 410, 120)
      doc.line(250, 140, 410, 140)
      doc.line(250, 160, 410, 160)
      doc.line(250, 180, 410, 180)
      doc.line(250, 200, 410, 200)
      doc.line(250, 220, 410, 220)
      doc.line(250, 240, 410, 240)
      doc.line(250, 260, 410, 260)
      doc.line(250, 280, 410, 280)

      doc.line(250, 350, 410, 350)
      doc.line(250, 370, 410, 370)
      doc.line(250, 390, 410, 390)
      doc.line(250, 410, 410, 410)
      doc.line(250, 430, 410, 430)
      doc.line(250, 450, 410, 450)
      doc.line(250, 470, 410, 470)
    }
    else if ((i + 2) % 4 === 0) { //中間右
      doc.rect(430, 80, 190, 450);
      doc.rect(425, 5, 200, 550);
      doc.setFontSize(14);
      doc.text(companyObj.name, 465, 30)
      doc.text('薪資袋', 550, 30)
      doc.text(dataArr[i].year + '   年     ' + dataArr[i].month + '  月 份', 450, 50)
      doc.text('職別：', 440, 70)
      doc.text(dataArr[i].jobNum, 490, 70)
      doc.text('姓名：', 530, 70)
      doc.text(dataArr[i].name, 575, 70)

      doc.setFontSize(12);
      doc.text('職務加給', 465, 95)
      doc.text('底薪', 465, 115)
      doc.text('全勤', 465, 135)
      doc.text('工作獎金', 465, 155)
      doc.text('伙食', 465, 175)
      doc.text('佣金', 465, 195)
      doc.text('資保息', 465, 215)
      doc.text('端午禮金', 465, 235)
      doc.text('油資', 465, 255)

      doc.text('出勤紀錄', 465, 345)
      doc.text('代墊', 465, 365)
      doc.text('勞健保', 465, 385)

      doc.text('0', 550, 95)
      doc.text(dataArr[i].baseSalary || '', 550, 115)
      doc.text(dataArr[i].fullAttendance || '', 550, 135)
      doc.text(dataArr[i].workBonus || '', 550, 155)
      doc.text(dataArr[i].foodStipend || '', 550, 175)
      doc.text(dataArr[i].commission || '', 550, 195)
      doc.text(dataArr[i].capitalGuaranteed || '', 550, 215)
      // doc.text(dataArr[i].duanwuGift, 550, 235)
      doc.text(dataArr[i].oilMoney || '', 550, 235)

      doc.setFontSize(14);
      doc.text('應', 435, 110)
      doc.text('支', 435, 160)
      doc.text('金', 435, 210)
      doc.text('額', 435, 260)
      doc.text('合     計', 450, 320)
      doc.text(dataArr[i].priceTotal, 550, 320)

      doc.text('應', 435, 360)
      doc.text('扣', 435, 390)
      doc.text('金', 435, 420)
      doc.text('額', 435, 450)
      doc.text('合     計', 450, 488)

      doc.setFontSize(16);
      doc.text('實領金額', 450, 518)


      //直線
      doc.line(460, 80, 460, 300)
      doc.line(460, 330, 460, 470)

      doc.line(540, 80, 540, 530)
      //橫線
      doc.line(430, 300, 620, 300)  //合計
      doc.line(430, 330, 620, 330)  //合計

      doc.line(430, 470, 620, 470)  //合計
      doc.line(430, 495, 620, 495)  //合計

      doc.line(460, 100, 620, 100)
      doc.line(460, 120, 620, 120)
      doc.line(460, 140, 620, 140)
      doc.line(460, 160, 620, 160)
      doc.line(460, 180, 620, 180)
      doc.line(460, 200, 620, 200)
      doc.line(460, 220, 620, 220)
      doc.line(460, 240, 620, 240)
      doc.line(460, 260, 620, 260)
      doc.line(460, 280, 620, 280)

      doc.line(460, 350, 620, 350)
      doc.line(460, 370, 620, 370)
      doc.line(460, 390, 620, 390)
      doc.line(460, 410, 620, 410)
      doc.line(460, 430, 620, 430)
      doc.line(460, 450, 620, 450)
      doc.line(460, 470, 620, 470)

    } else if ((i + 1) % 4 === 0) { //右
      doc.rect(640, 80, 190, 450);
      doc.rect(635, 5, 200, 550);
      doc.setFontSize(14);
      doc.text(companyObj.name, 675, 30)
      doc.text('薪資袋', 760, 30)
      doc.text(dataArr[i].year + '   年     ' + dataArr[i].month + '  月 份', 660, 50)
      doc.text('職別：', 650, 70)
      doc.text(dataArr[i].jobNum, 700, 70)
      doc.text('姓名：', 740, 70)
      doc.text(dataArr[i].name, 785, 70)

      doc.setFontSize(12);
      doc.text('職務加給', 675, 95)
      doc.text('底薪', 675, 115)
      doc.text('全勤', 675, 135)
      doc.text('工作獎金', 675, 155)
      doc.text('伙食', 675, 175)
      doc.text('佣金', 675, 195)
      doc.text('資保息', 675, 215)
      doc.text('端午禮金', 675, 235)
      doc.text('油資', 675, 255)

      doc.text('出勤紀錄', 675, 345)
      doc.text('代墊', 675, 365)
      doc.text('勞健保', 675, 385)

      doc.text('0', 760, 95)
      doc.text(dataArr[i].baseSalary || '', 760, 115)
      doc.text(dataArr[i].fullAttendance || '', 760, 135)
      doc.text(dataArr[i].workBonus || '', 760, 155)
      doc.text(dataArr[i].foodStipend || '', 760, 175)
      doc.text(dataArr[i].commission || '', 760, 195)
      doc.text(dataArr[i].capitalGuaranteed || '', 760, 215)
      // doc.text(dataArr[i].duanwuGift, 760, 235)
      doc.text(dataArr[i].oilMoney || '', 760, 235)

      doc.setFontSize(14);
      doc.text('應', 645, 110)
      doc.text('支', 645, 160)
      doc.text('金', 645, 210)
      doc.text('額', 645, 260)
      doc.text('合     計', 660, 320)
      doc.text(dataArr[i].priceTotal, 760, 320)

      doc.text('應', 645, 360)
      doc.text('扣', 645, 390)
      doc.text('金', 645, 420)
      doc.text('額', 645, 450)
      doc.text('合     計', 660, 488)

      doc.setFontSize(16);
      doc.text('實領金額', 660, 518)


      //直線
      doc.line(670, 80, 670, 300)
      doc.line(670, 330, 670, 470)

      doc.line(750, 80, 750, 530)
      //橫線
      doc.line(640, 300, 830, 300)  //合計
      doc.line(640, 330, 830, 330)  //合計

      doc.line(640, 470, 830, 470)  //合計
      doc.line(640, 495, 830, 495)  //合計

      doc.line(670, 100, 830, 100)
      doc.line(670, 120, 830, 120)
      doc.line(670, 140, 830, 140)
      doc.line(670, 160, 830, 160)
      doc.line(670, 180, 830, 180)
      doc.line(670, 200, 830, 200)
      doc.line(670, 220, 830, 220)
      doc.line(670, 240, 830, 240)
      doc.line(670, 260, 830, 260)
      doc.line(670, 280, 830, 280)

      doc.line(670, 350, 830, 350)
      doc.line(670, 370, 830, 370)
      doc.line(670, 390, 830, 390)
      doc.line(670, 410, 830, 410)
      doc.line(670, 430, 830, 430)
      doc.line(670, 450, 830, 450)
      doc.line(670, 470, 830, 470)
      doc.addPage();
    }

    // // doc.text()
    // if (i < dataArr.length - 1) {
    //   doc.addPage();
    // }
  }
}
