import React, { Component } from 'react'
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
import { Container, Header, Form, Icon, Grid, Image } from 'semantic-ui-react'
import { ModalGateway, Modal } from 'react-images';
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import PageHeader from 'components/Headers/PageHeader';
import { Col, Row, CardImg } from 'reactstrap';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
// import './css/startup1.css'
export class StartUp extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
      isOpen: false,
    };
  }


  handleChange = (e, { value }) => this.setState({ value })

  handleClose = () => { this.setState({ isOpen: false }) };
  handleOpen = () => { this.setState({ isOpen: true }) };

  //   handleChange = (e) => {
  //     // console.log(e);
  //     this.setState({
  //       [e.target.id]: e.target.value
  //     })
  //   }

  //   handleSubmit = (e) => {
  //     // console.log(e);
  //     e.preventDefault();
  //     this.props.createProject(this.state);
  //     this.props.history.push('/');
  //   }
  render() {
    const { isOpen } = this.state
    //     const { auth } = this.props;
    //     if (!auth.uid) return <Redirect to='/signin' />
    // const { value } = this.state
    return (<>
      {/* <Container style={content}> */}
      <section id="goto267301" className="section-base mainBox background-setting" style={{ backgroundImage: 'url(https://img.holkee.com/site/upload/5f927880-1818ap0ab-0ee3-9284e4fc/025af993647d8156116d7795d6f69df4_double.jpg)' }}>
        <div className="ContentsBox style-one">
          <div className="imgBox">
            <img decoding="async" src="https://img.holkee.com/site/upload/5f927880-1818ap0ab-0ee3-9284e4fc/cd2e0535441964dda609195a6112cf95_double.png" className="i-amphtml-fill-content i-amphtml-replaced-content" alt='' />
          </div>
          <div className="aBox"></div>
        </div>
      </section>
      <section className='section-base galleryBox'>
        <div className='ContentsBox default clearfix'>
          <div className='aBox'>
            <h3 className="main_title" >PHOTOGRAPHY</h3>
            <p className="sub_title" >Jack studio 成立於2015年，一直以來以數位藝術與設計作為不變的創作主軸。
            <br />
              最專業的人像/商品/美食/品牌形象攝影團隊。</p>
          </div>
          <div className='aBox'>
            <div className='photo_list clearfix'>
              <div className='grid_container' onClick={this.handleOpen}>
                {/* <figure className='photo'> */}
                <img src='https://img.holkee.com/site/upload/5f927880-1818ap0ab-0ee3-9284e4fc/59dc4041abc7b57f5e7d9587bd8d89d4_double.jpg' alt='' />
                {/* </figure> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section-base about_us_box'>
        <div className='ContentsBox default clearfix'>
          <div className='aBox fl'>
            <div className='imgBox'>
              <img src='https://img.holkee.com/site/upload/5f927880-1818ap0ab-0ee3-9284e4fc/e6961d713fc1b9069f9b814c2e0706ef_double.jpg' alt='' />
            </div>
          </div>
          <div className='aBox fr'>
            <h3 className="main_title" >Jack studio</h3>
            <p className="sub_title" style={{ textAlign: 'center' }}>
              《 服務項目 》<br /><br />廣告人像攝影<br /><br />建築室內空間攝影<br /><br />藝術品攝影<br /><br />商業攝影<br /><br />企業形像攝影<br /> <br />婚禮紀錄<br /><br />微電影拍製<br /><br />
              全家福攝影<br /><br />工商活動攝影<br /><br />行銷影片拍製
            </p>
            <p className="sub_btn"><a className="ana-button-click" href="#goto267304" target="_top" data-vars-event-category="聯繫我們" data-vars-event-label="#goto267304">聯繫我們</a></p>
          </div>
        </div>
      </section>
      <section className='section-base about_us_box'>
        <div className='ContentsBox style-two clearfix'>
          <div className='aBox'>
            <div className='imgBox'>
              <img src='https://img.holkee.com/site/upload/5f927880-1818ap0ab-0ee3-9284e4fc/9b489bd3dc684aff2f1ead33f0892e09_double.png' alt='' />
            </div>
          </div>
          <div className='aBox'>
            <h3 className="main_title" style={{ textAlign: 'center', fontSize: '1.75rem', color: '#FFFFFF' }}>客製化西裝</h3>
          </div>
        </div>
      </section>
      <section className='section-base slidesBox'>
        <div className='ContentsBox style-one'>
          <div className='text-box'>
            <h3 className="main_title">TEAM LEADER</h3>
          </div>
          <div className='slider-box i-amphtml-element i-amphtml-layout-fixed-height i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout'>
            <div className='i-amphtml-carousel-content'>
              <div className='i-amphtml-carousel-scroll'>
                <div className='content-box amp-carousel-slide amp-scrollable-carousel-slide'>
                  <div className='photo'>
                    <img style={{ borderRadius: '50%' }} src='https://img.holkee.com/site/upload/5f927880-1818ap0ab-0ee3-9284e4fc/0a61c0872b7755935be53ed3b196c612_double.jpg' alt='' />
                  </div>
                </div>
                <div className='content-box amp-carousel-slide amp-scrollable-carousel-slide'>
                  <div className='photo'>
                    <img style={{ borderRadius: '50%' }} src='https://img.holkee.com/site/upload/5f927880-1818ap0ab-0ee3-9284e4fc/8271e0127ae3aaf305e8a1db344f9474_double.jpg' alt='' />
                  </div>
                </div>
                <div className='content-box amp-carousel-slide amp-scrollable-carousel-slide'>
                  <div className='photo'>
                    <img style={{ borderRadius: '50%' }} src='https://img.holkee.com/site/upload/5f927880-1818ap0ab-0ee3-9284e4fc/fecc36573d46ef872b24033f60275fc3_double.jpg' alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section-base contact_us'>
        <div className='ContentsBox default clearfix'>
          <div className='aBox'>
            <div className='mapBox'>
              <iframe className='i-amphtml-element i-amphtml-layout-fill i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout' sandbox="allow-scripts allow-same-origin allow-popups" title='google' src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDXtBW26A1ECFElryP7Qar7EPx78d3ptV0&q=%E5%8F%B0%E5%8C%97%E5%B8%82%E4%BF%A1%E7%BE%A9%E8%B7%AF%E4%BA%94%E6%AE%B57%E8%99%9F" width='100%' height='100%' />
            </div>
          </div>
          <div className='aBox'>
            <h3 className="main_title" style={{ textAlign: 'center', fontSize: '1.75rem' }} >Jack studio</h3>
            <p className="sub_title" style={{ textAlign: 'center', fontSize: '0.9375rem' }}>欢迎各种拍摄与合作邀约</p>
            <ul className="info">
              <li><p className="address">台北市信義路五段7號</p></li>
              <li><p className="phone"><a className="ana-button-click" href="tel:0908-000-1234" data-vars-event-category="contact" data-vars-event-label="0908-000-1234">0908-000-1234</a></p></li>
              <li><p className="time">10:00 AM - 11:30 PM</p></li>
              <li><p className="email"><a className="ana-button-click" href="mailto:service@example.com" data-vars-event-category="contact" data-vars-event-label="service@example.com">service@example.com</a></p></li>
            </ul>
          </div>
        </div>
      </section>
      <section className='section-base follow_us background-setting' style={{ backgroundImage: 'url(https://img.holkee.com/site/upload/5f927880-1818ap0ab-0ee3-9284e4fc/1ada06d12fb2fab39f3437a1b37d54f0_double.jpg)' }}>
        <div className='ContentsBox default'>
          <h3 className="main_title" style={{ textAlign: 'center', fontSize: '1.75rem' }}>FOLLOW US</h3>
          <p className="sub_title" style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '0.9375rem' }}>關注我們的 Facebook 粉絲頁或追蹤我們的 Instagram，以關注我們的最新動態！</p>
          <ul className="follow_us_icon">
            <li><a className="ana-social-click" style={{ backgroundColor: '#000000' }} href="https://www.holkee.com" target="_blank" data-vars-event-category="social" data-vars-event-label="Facebook"><i className="fab fa-facebook-square"></i></a></li>
            <li><a className="ana-social-click" style={{ backgroundColor: '#000000' }} href="https://www.holkee.com" target="_blank" data-vars-event-category="social" data-vars-event-label="Instagram"><i className="fab fa-instagram"></i></a></li>
            <li><a className="ana-social-click" style={{ backgroundColor: '#000000' }} href="https://line.me/R/ti/p/@bca1493j" target="_blank" data-vars-event-category="social" data-vars-event-label="LINE"><i className="fab fa-line"></i></a></li>
            <li><a className="ana-social-click" style={{ backgroundColor: '#000000' }} href="https://twitter.com/holkeego" target="_blank" data-vars-event-category="social" data-vars-event-label="Twitter"><i className="fab fa-twitter"></i></a></li>
            <li><a className="ana-social-click" style={{ backgroundColor: '#000000' }} href="https://www.youtube.com/channel/UCWdDQAD5syDC1nYlhMOVeMA/" target="_blank" data-vars-event-category="social" data-vars-event-label="YouTube"><i className="fab fa-youtube"></i></a></li>
          </ul>
        </div>
      </section>
      <footer className='FooterBox'>
        <div className='ContentsBox clearfix'>
          <ul className="share-tools">
            <li>
              <div style={{ width: '75px', height: '24px' }} className='ana-share-click i-amphtml-element i-amphtml-layout-fixed i-amphtml-layout-size-defined amp-social-share-facebook i-amphtml-built i-amphtml-layout'>
                分享
            </div>
            </li>
            <li>
              <div style={{ width: '75px', height: '24px' }} className='ana-share-click i-amphtml-element i-amphtml-layout-fixed i-amphtml-layout-size-defined amp-social-share-pinterest i-amphtml-built i-amphtml-layout'>
                分享
            </div>
            </li>
            <li>
              <div style={{ width: '75px', height: '24px' }} className='ana-share-click i-amphtml-element i-amphtml-layout-fixed i-amphtml-layout-size-defined amp-social-share-twitter i-amphtml-built i-amphtml-layout'>
                分享
            </div>
            </li>
          </ul>
          <p className="copyrights" >Copyrights All Rights Reserved.</p>
        </div>
      </footer>
      <ModalGateway>
        {isOpen ? (
          <Modal onClose={this.handleClose}>
            <img src='https://img.holkee.com/site/upload/5f927880-1818ap0ab-0ee3-9284e4fc/59dc4041abc7b57f5e7d9587bd8d89d4_double.jpg' alt='' />
          </Modal>
        ) : null}
      </ModalGateway>
      {/* </Container> */}
    </>)
  }
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     createProject: (project) => dispatch(createProject(project))
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HostIndex)
export default StartUp