import React, { Component } from 'react'
import { Button, Icon, Grid, Image, Loader } from 'semantic-ui-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { IconButton } from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import PreloadFile, { previewUrl } from './PreloadFile';
import { RowImage } from '../web/RowImage'

export default class ModalUpdateFile extends Component {
  state = {
    open: false,
    file: null,
    isSaving: false,
  }

  open = () => this.setState({ open: true })
  close = () => this.setState({
    open: false,
    file: null,
    isSaving: false,
  })

  toggle = () => this.setState({ open: !this.state.open, })

  handleSubmit = async (type = '1') => {
    const { rowData, fieldname, onRowDataChange, isImage, dbname } = this.props
    const { file } = this.state
    this.setState({ isSaving: true });
    let theFile = null;
    if (isImage === '1') { // 照片
      if (type === '2') { // 原圖
        theFile = await Parse.putFile(file, '') // 單純存檔
        onRowDataChange({ ...rowData, [fieldname]: theFile })
      } else {
        // theFile = await Parse.saveImg(file)
        let res = {}
        if (dbname) {
          res = await Parse.putFile(file, 'aa').catch((error) => {
            console.error(error.code + " " + error.message);
          }); // 單純存檔
          console.log(theFile)
          onRowDataChange({ ...rowData, [fieldname]: res, }) //[fieldname + '_name']: theFile._source.file.name
        } else {
          res = await Parse.saveImgThumb(file, 'webimage', rowData.objectId, 'img1File', 'img1File2');
          const { theFile1, theFile2 } = res;
          onRowDataChange({ ...rowData, [fieldname]: theFile1, [fieldname + '2']: theFile2 })
        }
        // console.log(res)
      }
    } else { //檔案
      theFile = await Parse.putFile(file, 'aa').catch((error) => {
        console.error(error.code + " " + error.message);
      }); // 單純存檔
      onRowDataChange({ ...rowData, [fieldname]: theFile }) //, [fieldname + '_name']: theFile._source.file.name
    }

    this.setState({ isSaving: false })
    this.close()
  }

  handleChangeFile = (file) => {
    console.log(file)
    this.setState({ file });
  }

  handleRemove = () => {
    const { rowData, fieldname, onRowDataChange } = this.props
    onRowDataChange({ ...rowData, [fieldname]: null })
  }

  render() {
    const { title = '修改', rowData = [], fieldname, isImage, isUploadOri = false } = this.props
    const { open, file, isSaving } = this.state
    // const fileUrl = (rowData && rowData[fieldname] && rowData[fieldname]["url"]) || ''
    // const ext = 'jpg';

    return (<>
      {/* <Button icon='edit' onClick={this.open} /> */}
      <IconButton onClick={this.toggle}><Menu size='small' /></IconButton>
      <Modal
        isOpen={open}
        toggle={this.close}
        size="lg"
      // className="mh-90 h-90 mw-100 w-90"
      // style={{ paddingTop: '0px' }}
      // style={{ maxWidth: '1600px', width: '80%' }}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.close}>
            <span aria-hidden="true">×</span>
          </button>
          {title}{isImage === '1' ? '照片' : '檔案'}
        </ModalHeader>
        <ModalBody>
          <PreloadFile isImage={isImage} handleChangeFile={this.handleChangeFile} />
          {rowData[fieldname] ? <h5>目前{isImage === '1' ? '照片' : '檔案'}</h5> : null}
          {isImage === '1' ? previewUrl(rowData[fieldname]) :
            <RowImage rowData={rowData} field={fieldname} />}
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          {isUploadOri ? <>{isImage === '1' ? <Button icon='save' content={!isSaving ? '更新(保留透明背景)' : <Loader active inverted inline size='small' />}
            onClick={() => this.handleSubmit('2')} hidden={!file} color='green' /> : null}</> : null}
          <Button icon='save' content={!isSaving ? '更新' : <Loader active inverted inline size='small' />} onClick={this.handleSubmit} hidden={!file} color='green' />
          <Button icon='trash' content={'刪除'} hidden={rowData[fieldname] ? false : true} color='red' onClick={this.handleRemove} />
          <Button icon='x' content='關閉' onClick={this.close} />
        </ModalFooter>
      </Modal>
    </>)
  }
}
