import React, { Component } from 'react'
import {
  // Modal, 
  Button,
  Form, Grid
} from 'semantic-ui-react';
import ModalAutoAddr from './ModalAutoAddr';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

const genderOpt = [
  { text: '-- 選擇 --', value: '-1', key: 'grnderNull' },
  { text: '男', value: '1', key: 'grnder0' },
  { text: '女', value: '2', key: 'grnder1' },
]

export default class ModalNewFamily extends Component {
  state = {
    dataArr: [],
    open: false,
    form: {
      name: '',
      sexual: '-1',
      telephone: '',
      cellphone: '',
      postcode: '',
      addr: '',
    }
  }

  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })
  toggle = () => this.setState({ open: !this.state.open })

  handleChange = (evt, { name, value }) => {
    this.setState({
      form: { ...this.state.form, [name]: value }
    })
  }

  handleSubmit = async () => {
    const { history, companyObj } = this.props;
    const { form } = this.state;
    // const db = firebase.firestore();

    this.setState({ loading: true });

    // const docRef = db.collection(`company/${companyObj._id}/family`).doc();
    const db = Parse.Object.extend("family");
    const newDoc = new db();
    const doc = await newDoc.save();

    const familyId = doc.id;
    const obj = {
      ...form,
      // objectId: familyId,
      id: familyId,
      _id: familyId,
      if_is: '0',
      log_name: '',
      number: '',
      ori_id: '',
      ori_user_id: '',
      passNumber: '',
      user_id: '',
      createdAt: new Date(),
      companyId: companyObj.id,
      companyName: companyObj.name,
    };
    await Parse.saveData('family', obj);

    console.log('1', obj)
    // newDataArr.push(obj);
    // const docRef2 = db.collection(`company/${companyObj._id}/family/${familyId}/people`).doc();
    const db1 = Parse.Object.extend("people");
    const newDoc1 = new db1();
    const doc1 = await newDoc1.save();
    const obj2 = {
      ...form,
      // objectId: doc1.id,
      id: doc1.id,
      _id: doc1.id,
      familyId,
      companyId: companyObj.id,
      companyName: companyObj.name,
      mainPerson: '-1',
      isLive: '1',
      createdAt: new Date(),
    };
    // await docRef2.set(obj2);
    await Parse.saveData('people', obj2);
    console.log('2', obj2)
    // newDataArr.push(obj2);

    // this.setState({ dataArr: newDataArr, loading: false });
    history.push(`/admin/Family/${familyId}`);
    // window.alert('已新增 ' + obj.name)
    this.close();
  }

  onRowDataChange = ({ postcode = '', addr = '' }) => {
    this.setState({ form: { ...this.state.form, postcode, addr } })
  }

  render() {
    // const { rowData, people1, people0, isLive } = this.props
    const { open, form } = this.state
    const { name, sexual, telephone, cellphone, postcode, addr } = form;

    // console.log(rowData)
    // console.log(people)
    return (<>
      <Button icon='group' content='新增家庭' color='blue' onClick={this.open} />
      {/* <Modal
        open={open}
        onOpen={this.open}
        onClose={this.close}
        centered={false}
        size='mini'
      >
        <Modal.Header>
          新增家庭 (請輸入戶長）
        </Modal.Header>
        <Modal.Content scrolling>
          <Form size='big'>
            <Grid>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <Form.Input
                    label='姓名'
                    placeholder='姓名'
                    name='name'
                    value={name}
                    onChange={this.handleChange} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Select
                    label='性別'
                    options={genderOpt}
                    name='sexual'
                    value={sexual}
                    // defaultValue='-1'
                    onChange={this.handleChange} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Input
                    label='電話'
                    placeholder='電話'
                    name='telephone'
                    value={telephone}
                    onChange={this.handleChange} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={9}>
                  <Form.Input
                    label='郵遞區號'
                    placeholder='郵遞區號'
                    name='postcode'
                    value={postcode}
                    onChange={this.handleChange} />
                </Grid.Column>
                <Grid.Column width={7}>
                  <Form.Field>
                    <label>&nbsp;</label>
                    <ModalAutoAddr {...this.props} type="2" onRowDataChange={this.onRowDataChange} />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Input
                    label='地址'
                    placeholder='地址'
                    name='addr'
                    value={addr}
                    onChange={this.handleChange} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button icon='x' content='關閉' onClick={this.close} />
          <Button positive content='新增' icon='add' onClick={this.handleSubmit} />
        </Modal.Actions>
      </Modal> */}

      <Modal
        isOpen={open}
        //  onOpen={this.open}
        //  onClose={this.close}
        // isOpen={this.state.modalDemo}
        toggle={this.toggle}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggle}>
            <span aria-hidden="true">×</span>
          </button>
          新增家庭 (請輸入戶長）
        </ModalHeader>
        <ModalBody>
          <Form size='big'>
            <Grid>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <Form.Input
                    label='姓名'
                    placeholder='姓名'
                    name='name'
                    value={name}
                    onChange={this.handleChange} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Select
                    label='性別'
                    options={genderOpt}
                    name='sexual'
                    value={sexual}
                    // defaultValue='-1'
                    onChange={this.handleChange} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Input
                    label='電話'
                    placeholder='電話'
                    name='telephone'
                    value={telephone}
                    onChange={this.handleChange} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={9}>
                  <Form.Input
                    label='郵遞區號'
                    placeholder='郵遞區號'
                    name='postcode'
                    value={postcode}
                    onChange={this.handleChange} />
                </Grid.Column>
                <Grid.Column width={7}>
                  <Form.Field>
                    <label>&nbsp;</label>
                    <ModalAutoAddr {...this.props} type="2" onRowDataChange={this.onRowDataChange} />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Input
                    label='地址'
                    placeholder='地址'
                    name='addr'
                    value={addr}
                    onChange={this.handleChange} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button icon='x' content='關閉' onClick={this.close} />
          {/* <Button color="secondary" onClick={this.toggle}>
            Close
        </Button> */}
          <Button positive content='新增' icon='add' onClick={this.handleSubmit} />
        </ModalFooter>
      </Modal>
      {/* </TransitionablePortal> */}
    </>
    )
  }
}
