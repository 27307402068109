import React, { Component } from 'react';
import { Grid, Form, Button, Modal, Table, Input, Icon, Confirm } from 'semantic-ui-react';
import Moment from 'moment';

import { set0String } from '../../widget/define/date'
import { getExpectedAttndDays, isValidDate, getTypeDays, getCorrespondingYear, getJpnDay, getEventArr, isHoliday, getExpectedAttndDaysInMonth } from '../../widget/define/calendar'
import { getOptionsByKey } from './FieldsRender';
import Parse from 'widget/parse.js'

export default class AnnualCalendar extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      calendar: props.calendar,
      show: false,
      confirm: false,
      isEdit: false,
      form: {
        type: '',
        title: '',
        status: '',
        eventDate: ''
      },
    };
  }

  async componentDidMount() {
    const { companyObj } = this.props
    const calendar = await Parse.queryData('Calendar', { companyId: companyObj.objectId })
    this.setState({ calendar })
  }

  handleClickCell = (...event) => {
    // console.log(event);
    const { form } = this.state;
    let [year, month, day] = event;

    year = year + "";
    month = set0String(month);
    day = set0String(day);
    const eventDate = year + "-" + month + "-" + day;
    const status = "1";

    // console.log(year, month, day);
    this.setState({ show: true, form: { ...form, year, month, day, eventDate, status } })
  };

  setWeekend = () => {//資料庫新增一學年度週末休假，觸發點？？？
    const { firebase } = this.props;
    const { form, calendar } = this.state;
    const newCalendar = [...calendar];

    form.status = "3";
    form.type = "1";

    for (let i = 0; i < 364; i++) {
      const d = new Date(2020, 3, 1 + i)
      form.year = d.getFullYear() + '';
      form.month = set0String((d.getMonth() + 1));
      form.day = set0String(d.getDate());
      form.eventDate = form.year + "-" + form.month + "-" + form.day;

      // console.log(new Date(2020, 3, 1 + i))
      const weekday = (new Date(2020, 3, 1 + i)).getDay();
      if (weekday === '0' || weekday === '6') {
        if (!calendar.find(item => item.year === form.year && item.month === form.month && item.day === form.day)) {
          firebase.firestore().collection("Calendar").add(form)
            .then(doc => {
            })
          console.log('add', form.eventDate);
          newCalendar.push(form);
        }
      }
    }
    this.setState({ calendar: newCalendar });

    // firebase.firestore().collection("Calendar")
    //   .where('year', '==', year)
    //   .where('month', '==', month)
    //   .where('day', '==', day)
    //   .get().then(querySnapshot => {
    //     querySnapshot.forEach(documentSnapshot => {
    //       if (!documentSnapshot.data()) {
    //         firebase.firestore().collection("Calendar").add(eventDate, status, type, year, month, day)
    //         console.log('exist', year, month, day)
    //       }
    //     });
    //   })
    // }
    // console.log(year, month, day);


    // this.setState({ show: true, form: { ...form, year, month, day, eventDate, status } })
  };

  handleClickEvent = (event) => {
    // console.log(event);
    // return;

    const { type, status, title, _id, color, eventDate } = event;
    const { form } = this.state;

    const d = new Date(eventDate);
    const year = d.getFullYear() + "";
    const month = set0String(d.getMonth() + 1);
    const day = set0String(d.getDate());

    this.setState({ show: true, isEdit: true, form: { ...form, status, title, _id, eventDate, year, month, day, type } })
  };

  handleChange = (event, data) => {
    const { form } = this.state;
    const { name, value } = data;

    const title = form.title || ''
    const type = form.type || ''
    if (name === 'eventDate') {
      const d = new Date(value);
      const year = d.getFullYear() + "";
      const month = set0String(d.getMonth() + 1);
      const day = set0String(d.getDate());

      this.setState({ form: { ...form, eventDate: value, year, month, day, title, type } });
    } else {
      this.setState({ form: { ...form, title, type, [name]: value } });
    }
  };

  handleChangeType = (status) => {
    const { form } = this.state;
    const title = form.title || "";
    // status 3: 学生不出席
    this.setState({ form: { ...form, status, title } })
  };

  handleClose = () => {
    this.setState({
      show: false,
      isEdit: false,
      form: {
        type: '-1',
        title: '',
        status: '',
      },
    });
  }

  handleSubmit = () => {
    const { firebase } = this.props;
    const { form, isEdit, calendar } = this.state;
    const newCalendar = [...calendar];
    const db = firebase.firestore();
    let _id = form._id
    // console.log(form);
    // return;
    if (isEdit) {
      db.collection("Calendar").doc(form._id)
        .update(form).then(docRef => {
          // console.log(docRef)
          let index = newCalendar.findIndex((item => item._id === form._id));
          newCalendar[index] = form;
          this.setState({ calendar: newCalendar }, () => this.handleClose());
        });
    } else {
      db.collection("Calendar").add(form).then(docRef => {
        console.log(docRef)
        _id = docRef.id
        // form={...form,_id:docRef.id}
        newCalendar.push({ ...form, _id });
        this.setState({ calendar: newCalendar }, () => this.handleClose());
      })
    }
  }

  handleRemove = () => {
    const { firebase } = this.props
    const { form, calendar } = this.state;
    const { _id } = form;
    const db = firebase.firestore();

    db.collection("Calendar").doc(form._id).delete()
    const newCalendar = [...calendar];
    let index = newCalendar.findIndex((item => item._id === form._id));
    newCalendar.splice(index, 1);
    this.setState({ calendar: newCalendar });
    this.handleClose();
    this.hideConfirm();
  }


  showConfirm = () => this.setState({ confirm: true });
  hideConfirm = () => this.setState({ confirm: false });

  render() {
    const { semesterYear, semester, optSet, } = this.props;
    const { show, isEdit, form, calendar } = this.state;
    // console.log(semester);

    const arrD31 = Array.from(Array(31).keys(), n => n + 1);
    const arrM1 = [4, 5, 6, 7, 8, 9];
    const arrM2 = [10, 11, 12, 1, 2, 3];
    const fullWidth = { width: '16%', textAlign: 'center' };
    const smallWidth = { width: '3%', textAlign: 'center' }
    const midWidth = { width: '13%', textAlign: 'left' }
    const diagonalLine = { backgroundImage: 'linear-gradient(to bottom right, transparent calc(50% - 1px), black, transparent calc(50% + 1px))' };

    /* months of next year, ex. display 2018/10 2018/11 2018/12 2019/1 2019/2 2019/3 on same page */
    const arrM = semester === 'S' ? arrM1 : arrM2;
    const semesterYearNext = Number(semesterYear) + 1;
    const semesterYearPrev = Number(semesterYear) - 1 + '';

    const statusArr = [
      { text: '非休日', status: '1', },
      // { text: '都休假', status: '2', },
      { text: '休日', status: '3', },
    ];

    const calendarEventArr = getOptionsByKey(optSet, 'calendarEvent');

    const selCalendar = [{ key: 'empty', text: '-- 選択 --', value: '' }];

    calendarEventArr
      .forEach(({ _id, label, value }) =>
        selCalendar.push({ key: _id, text: label, value }));
    const tdCss = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      texOverflow: 'ellipsis',
      maxWidth: '100%'
    }

    return (
      <>
        {/* <h3 style={{ textAlign: 'center' }}>{semesterYear} 年度 行事予定</h3> */}
        <Table
          celled structured size="small"
          style={{ fontSize: "15px", width: '100%' }}
          className='calendarcss2'
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={smallWidth} colSpan="1"></Table.HeaderCell>
              {arrM.map(month => (<Table.HeaderCell key={semesterYear + semester + month} style={fullWidth} colSpan="6">{month}月</Table.HeaderCell>))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {arrD31.map(day => (
              <Table.Row key={semesterYear + semester + day}>
                <Table.Cell style={smallWidth} colSpan="1">{day}</Table.Cell>
                {arrM.map(month => (
                  <React.Fragment key={semesterYear + semester + month + '_' + day}>
                    {isValidDate(getCorrespondingYear(semesterYear, month), month, day) ?
                      <>
                        <Table.Cell
                          style={{
                            ...smallWidth,
                            cursor: 'pointer'
                          }}
                          colSpan="1"
                          onClick={() => this.handleClickCell(getCorrespondingYear(semesterYear, month), month, day)}
                        >
                          {getJpnDay(getCorrespondingYear(semesterYear, month), month, day)}
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            ...midWidth,
                            backgroundColor: isHoliday(calendar, getCorrespondingYear(semesterYear, month), month, day) ? 'pink' : '',
                            padding: '3px',
                            ...tdCss
                          }}
                          colSpan="5">
                          {getEventArr(calendar, getCorrespondingYear(semesterYear, month), month, day).map((event, index) =>
                            (<div
                              key={semesterYear + semester + index}
                              style={{
                                cursor: 'pointer', backgroundColor: !event.type ? '' :
                                  calendarEventArr.find(el => el.value === event.type) ? calendarEventArr.find(el => el.value === event.type).note2 : ''
                              }}
                              onClick={() => this.handleClickEvent(event)}
                            >
                              <span>{event.title ? event.title : '(休)'}</span>
                            </div>))}
                        </Table.Cell>
                      </>
                      :
                      <>
                        <Table.Cell style={{ backgroundColor: 'rgb(200,200,200)' }} colSpan="1"></Table.Cell>
                        <Table.Cell style={{ backgroundColor: 'rgb(200,200,200)' }} colSpan="5"></Table.Cell>
                      </>
                    }
                  </React.Fragment>
                ))}
              </Table.Row>))}
            <Table.Row>
              <Table.Cell style={smallWidth}></Table.Cell>
              {arrM.map((month, index) => (
                <Table.Cell key={semesterYear + semester + "arrm" + index} style={fullWidth} colSpan="6">
                  {/* <OverlayTrigger placement='top'
                    overlay={<Popover id="popover-basic" title="Popover right">test</Popover>}
                  >
                    <></>
                  </OverlayTrigger> */}
                  {getExpectedAttndDaysInMonth(calendar, getCorrespondingYear(semesterYear, month), month)}日
                  ({getExpectedAttndDaysInMonth(calendar, getCorrespondingYear(semesterYear, month), month) * 4}時間)
                </Table.Cell>
              ))}
            </Table.Row>
          </Table.Body>
        </Table>
        {semester === 'S' ? <>
          <h5>{semesterYearPrev}年度 ({semesterYearPrev}年4月 ~ {semesterYear}年3月) までの授業日数</h5>
          <br />
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Table size="small" className='calendarcss2'>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell style={{ width: "25%" }}></Table.HeaderCell>
                      <Table.HeaderCell style={{ width: "25%" }}>{semesterYearPrev}年度 </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: "25%" }}>{semesterYear}年度</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: "25%" }}>合計</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{semesterYearPrev.slice(2)}年4月生</Table.Cell>
                      <Table.Cell>{getExpectedAttndDays(calendar, new Date(semesterYearPrev, 3), new Date(semesterYear, 2, 31), true)}日</Table.Cell>
                      <Table.Cell>+&nbsp;{getExpectedAttndDays(calendar, new Date(semesterYear, 3), new Date(semesterYearNext, 2, 31), true)}日</Table.Cell>
                      <Table.Cell>=&nbsp;{getExpectedAttndDays(calendar, new Date(semesterYearPrev, 3), new Date(semesterYearNext, 2, 31), true)}日</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>{semesterYear.slice(2)}年4月生</Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell>+&nbsp;{getExpectedAttndDays(calendar, new Date(semesterYear, 3), new Date(semesterYearNext, 2, 31), true)}日</Table.Cell>
                      <Table.Cell>=&nbsp;{getExpectedAttndDays(calendar, new Date(semesterYear, 3), new Date(semesterYearNext, 2, 31), true)}日</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column width={8}>
                <Table size="small" className='calendarcss2'>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell style={{ width: "25%" }}></Table.HeaderCell>
                      <Table.HeaderCell style={{ width: "25%" }}>{semesterYearPrev}年度 </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: "25%" }}>{semesterYear}年度</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: "25%" }}>合計</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{semesterYearPrev.slice(2)}年10月生</Table.Cell>
                      <Table.Cell>{getExpectedAttndDays(calendar, new Date(semesterYearPrev, 9), new Date(semesterYear, 2, 31), true)}日</Table.Cell>
                      <Table.Cell>+&nbsp;{getExpectedAttndDays(calendar, new Date(semesterYear, 3), new Date(semesterYearNext, 2, 31), true)}日</Table.Cell>
                      <Table.Cell>=&nbsp;{getExpectedAttndDays(calendar, new Date(semesterYearPrev, 9), new Date(semesterYearNext, 2, 31), true)}日</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>{semesterYear.slice(2)}年10月生</Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell>{getExpectedAttndDays(calendar, new Date(semesterYear, 9), new Date(semesterYearNext, 2, 31), true)}日</Table.Cell>
                      <Table.Cell>=&nbsp;{getExpectedAttndDays(calendar, new Date(semesterYear, 9), new Date(semesterYearNext, 2, 31), true)}日</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <Table celled structured size="small" className='calendarcss2'>
                  <Table.Body>
                    {calendarEventArr.map(item => (
                      <Table.Row key={'cer' + item._id}>
                        <Table.Cell style={{ width: '50%', backgroundColor: item.note2 }}>{item.label}</Table.Cell>
                        <Table.Cell>{getTypeDays(calendar, new Date(semesterYear, 3), new Date(semesterYearNext, 2, 31), item.value)}日</Table.Cell>
                        {/* <Table.Cell>{getTypeDays(calendar, new Date(semesterYearPrev, 9), new Date(semesterYearNext, 2, 31), item.value)}日</Table.Cell> */}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </> :
          <>
            <h5><br /></h5>
            <br />
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Table size="small" className='calendarcss2' >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>期間</Table.HeaderCell>
                        <Table.HeaderCell>授業日数</Table.HeaderCell>
                        <Table.HeaderCell>授業時間</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>{semesterYear}年度前期</Table.Cell>
                        <Table.Cell>4月1日-9月30日</Table.Cell>
                        <Table.Cell>{getExpectedAttndDays(calendar, new Date(semesterYear, 3), new Date(semesterYear, 8, 30), true)}日</Table.Cell>
                        <Table.Cell>{getExpectedAttndDays(calendar, new Date(semesterYear, 3), new Date(semesterYear, 8, 30), true) * 4}時間</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>{semesterYear}年度後期</Table.Cell>
                        <Table.Cell>10月1日-3月31日</Table.Cell>
                        <Table.Cell>{getExpectedAttndDays(calendar, new Date(semesterYear, 9), new Date(semesterYearNext, 2, 31), true)}日</Table.Cell>
                        <Table.Cell>{getExpectedAttndDays(calendar, new Date(semesterYear, 9), new Date(semesterYearNext, 2, 31), true) * 4}時間</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Table size="small" className='calendarcss2'>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>卒業規定時間</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>１年半コース</Table.Cell>
                        <Table.Cell>{getExpectedAttndDays(calendar, new Date(semesterYearPrev, 9), new Date(semesterYearNext, 2, 31), true)}日</Table.Cell>
                        <Table.Cell>{getExpectedAttndDays(calendar, new Date(semesterYearPrev, 9), new Date(semesterYearNext, 2, 31), true) * 4}時間</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>２年　コース</Table.Cell>
                        <Table.Cell>{getExpectedAttndDays(calendar, new Date(semesterYearPrev, 3), new Date(semesterYearNext, 2, 31), true)}日</Table.Cell>
                        <Table.Cell>{getExpectedAttndDays(calendar, new Date(semesterYearPrev, 3), new Date(semesterYearNext, 2, 31), true) * 4}時間</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>}
        <Modal open={show} onClose={this.handleClose} size="mini" centered={false}>
          <Modal.Header>
            {isEdit ? '修正' : '追加'}行事予定
          </Modal.Header>
          <Modal.Content>
            <Grid.Row>
              <Grid.Column style={{ margin: '15px 0' }}>
                <Form.Group >
                  <label>日付</label>{'  '}
                  <Form.Input style={{ minWidth: '14em' }} type="date" name="eventDate"
                    onChange={this.handleChange}
                    defaultValue={Moment(form.eventDate).format('YYYY-MM-DD')}
                  // value={Moment(new Date(form.eventDate)).format('YYYY/MM/DD')}
                  />
                </Form.Group>
              </Grid.Column>
              <Grid.Column style={{ margin: '15px 0' }}>
                <Form.Group>
                  <label style={{ display: 'block' }}>出席狀況</label>
                  <Button.Group name="status">
                    {statusArr.map(item => (
                      <Button style={{ minWidth: '7em' }} inverted color='blue' key={semesterYear + semester + item.status}
                        active={item.status === form.status ? true : false} onClick={() => this.handleChangeType(item.status)}>{item.text}</Button>
                    ))}
                  </Button.Group>
                </Form.Group>
              </Grid.Column>
              <Grid.Column style={{ margin: '15px 0' }}>
                <Form.Group >
                  <label>內容</label>{'  '}
                  <Form.Field style={{ minWidth: '14em' }} control={Input} name="title"
                    // readOnly={form.type !== "-1" ? true : false}
                    onChange={this.handleChange} value={form.title} />
                </Form.Group>
              </Grid.Column>

              <Grid.Column style={{ margin: '15px 0' }}>
                <div controlid="formControlsOffDay">
                  <label>行事選択</label>
                  <Form.Select
                    name="type"
                    onChange={this.handleChange}
                    value={form.type}
                    placeholder='行事選択'
                    // defaultValue='なし'
                    options={selCalendar} />
                </div >
              </Grid.Column >
            </Grid.Row >
          </Modal.Content >
          <Modal.Content style={{ borderTop: '1px solid rgba(34, 36, 38, .15)', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="success"
              onClick={this.handleSubmit}
              color='blue'
              content={isEdit ? '修正' : '追加'}
              icon={isEdit ? 'edit' : 'calendar plus'} />
            {isEdit ?
              <>
                <Button
                  variant="outline-danger"
                  onClick={this.showConfirm}
                  color='red'
                  content='削除'
                  icon='trash alternate' />
                <Confirm
                  header='削除事件'
                  content='確定要削除此事件嗎?'
                  cancelButton='いいえ'
                  confirmButton='はい'
                  open={this.state.confirm}
                  onCancel={this.hideConfirm}
                  onConfirm={this.handleRemove}
                  size='mini'
                  centered={false} />
              </> :
              ''}
            <Button onClick={this.handleClose} icon='remove' content='閉じる' />
          </Modal.Content>
        </Modal >
      </>
    )
  }
}