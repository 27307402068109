import React, { Component } from 'react'
import { Input, Form, Button, Label, Icon, Header, Select, Grid, Segment, Table } from 'semantic-ui-react';
import ReactToPrint from "react-to-print";
import CalendarHeatmap from 'react-calendar-heatmap';
import Parse from 'widget/parse'

// const selType = [
//   // { key: '0', text: '', value: '0' },
//   { key: '1', text: '出席', value: '1' },
//   { key: '2', text: '班別', value: '2' },
//   { key: '3', text: '班別+出席', value: '3' },
// ]

export default class ClassRollcallYearly extends Component {
  constructor(props) {
    super(props);
    const d = new Date();
    this.state = {
      classArr: [], // 現在所有的classroom
      selClassArr: [],
      classId: '',
      className: '',
      loadingClassStu: true,
      classObj: {},
      selTypeId: '1',
      isEdit: false,
      classroom: 'A',
      studentsClass: []
    };
  }

  componentDidMount() {
    // console.log('componentDidMount')
    this.handleInitial()

  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps')
    this.handleInitial(nextProps)
    // if (
    //   nextProps.semesterYear !== this.props.semesterYear ||
    //   nextProps.semester !== this.props.semester) {

    //   this.setState({
    //     yearWeek: getLatestYearWeek(nextProps.semesterYear, nextProps.semester),
    //     year: getLatestYear(nextProps.semesterYear, nextProps.semester),
    //     week: getLatestWeek(nextProps.semesterYear, nextProps.semester),
    //     // minWeek: getMinWeek(nextProps.semesterYear, nextProps.semester),
    //     // maxWeek: getMaxWeek(nextProps.semesterYear, nextProps.semester),
    //   }
    //     , () => this.handleDailyRollcall(nextProps)
    //   );
    // }
  }

  handleInitial = (props = this.props) => {
    // console.log('componentWillReceiveProps');
    const { classroom, semesterYear, semester } = props;

    const classArr = classroom
      .filter(item => item.isEnableClass === true && item.semesterYear === semesterYear && item.semester === semester)
      .sort((a, b) => ('' + a.classSchool).localeCompare(b.classSchool));

    if (classArr.length) {
      const classId = classArr[0]._id;
      const selClassArr = [];
      if (!classArr.length) {
        selClassArr.push({ key: 'empty', text: '-- 無クラス --', value: '' })
      } else {
        // selClassArr.push({ key: '-1', text: '-- 在籍生全部 --', value: '-1' })
        // selClassArr.push({ key: '-2', text: '-- 卒業生全部 --', value: '-2' })
        classArr.forEach(({ _id, classSchool, teacherText, entryYear, entryText }) =>
          selClassArr.push({ key: _id, text: `${classSchool}クラス ${entryYear}年${entryText} ${teacherText || ''}`, value: _id }))
      }
      this.setState({ classArr, selClassArr, classId }, () => this.handleClassroom(props));
    }
  }

  handleClassroom = async (props = this.props) => {
    const { students, companyObj } = props
    const { eventDate, classId, classArr } = this.state
    const classObj = classArr.find(item => item._id === classId);

    if (!classObj) {
      return;
    }

    const className = classObj.classSchool;

    const tmp = students.filter(item =>
      (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
      (item.applyResult === '1' || item.studentType === "2"));

    let studentObj = []
    const studentsClass = tmp.filter(item => item.classSchool === className);
    // console.log(studentsClass)
    for (const { _id, jpnName, studentId, semesterYear, semester } of studentsClass) {
      const colorObj = [];
      // const dailyRef = await firebase.firestore().collection(`/Students/${_id}/Daily`).get()
      const dailyRef = await Parse.queryData('Daily', { companyId: companyObj.objectId, studentId: _id })
      dailyRef.forEach(doc => {
        let date = doc.eventDate;
        let color = doc.attendanceColor;
        colorObj.push({ date, color })
        // console.log(doc.id, ' => ', doc.data());
      });
      studentObj.push({ _id, studentId, jpnName, semesterYear, semester, colorObj })
    }
    this.setState({ studentObj })
    console.log(studentObj)
    this.setState({ studentsClass, className, classObj });
  }

  handleChange = (event, data) => {
    // console.log('handleChange')
    let fieldName = data.name;
    let fleldVal = data.value;
    // console.log(fieldName, fleldVal);

    if (fieldName === 'classId') { // 更新クラス的時候
      const { classroom } = this.props;
      const selClassroom = classroom.find(item => item.classSchool === fleldVal);
      const period = selClassroom ? (selClassroom.classTime === '1' ? "0" : "4") : "";
      this.setState({ [fieldName]: fleldVal, period }, () => this.handleClassroom());
    } else if (fieldName === 'selTypeId') {
      this.setState({ selTypeId: fleldVal });
    } else {
      this.setState({ [fieldName]: fleldVal, loading: 1 });
    }
  }

  render() {
    const { optSet, semesterYear, semester, firebase } = this.props;
    const { studentObj } = this.state;
    // console.log(obj)
    const { studentsClass, selClassArr, classId, classObj,
      // classArr, weekday, period, selPeriodArr, courseName, eventDate, calendarHolidayArr,
      //  loadingClassStu, attendStatusArr, seatOrderArr, classroomId
    } = this.state;
    return (<div style={{ padding: '2%' }} ref={el => (this.componentRef = el)}>
      <Header as='h2' floated='left'>年別出席簿</Header>
      <div style={{ float: 'right' }}>
        <Input labelPosition='left' >
          <Label color={'blue'}> <Icon name='building' />クラス</Label>
          <Select style={{
            minWidth: '100px',
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0'
          }} compact options={selClassArr} onChange={this.handleChange} value={classId} name='classId' />
        </Input>
        <ReactToPrint
          trigger={(e) => <Button
            content='印刷'
            // label='&nbsp;'
            color='orange' icon='print'
            size='small'
            floated='right'
            className='no-print'
          />}
          content={() => this.componentRef}
          pageStyle={"@page { size: auto;  margin:5mm; }*{font-family: 'Noto Serif JP', serif;}"}
        />
      </div>
      <div style={{ clear: 'both' }}>

        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              {studentObj && studentObj.map((item, index) =>
                <Segment vertical key={index}>
                  <Label as='a' color='grey' size='large' ribbon>
                    {item.studentId} {item.jpnName}
                  </Label>
                  <span>{item.semesterYear}年{item.semester === 'S' ? '4月' : '10月'} ~ {Number(item.semesterYear) + 2}年3月</span>
                  {item.colorObj &&
                    <CalendarHeatmap
                      startDate={new Date('2018-03-31')}//不含
                      endDate={new Date('2020-03-31')}//含
                      values={item.colorObj}
                      weekdayLabels={['', '月', '火', '水', '木', '金', '']}
                      classForValue={(value) => {
                        if (!value) {
                          return 'color-scale-1';
                        }
                        // console.log(`color-scale-${value.color}`)
                        return `color-scale-${value.color}`;
                      }}
                      tooltipDataAttrs={value => {
                        return { 'data-tip': `${value.date}` }
                      }}
                      showWeekdayLabels={true}
                      showMonthLabels={true}
                      onClick={value => {
                        let status = ''
                        if (value) {
                          if (value.color === '2')
                            status = '欠席'
                          else if (value.color === '3')
                            status = '遅刻'
                          else if (value.color === '0')
                            status = '未點名'
                          alert(`${value.date}: ${status}`)
                        }
                      }}

                    />}
                </Segment>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div >);
  }
};
