import React, { Component } from 'react'
import { Container, Header, Button, Form, Select } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

const periodmailSel = [{ key: '2', text: '全部', value: '2' },
{ key: '1', text: '需寄送生日賀卡', value: '1' },
{ key: '0', text: '不寄送生日賀卡', value: '0' }]

const columns = () => ([
  { title: 'Agent', field: 'agent_text', editable: 'never' },
  { title: '客戶姓名', field: 'name_cht', editable: 'never' },
  { title: '賀卡', field: 'periodmail7', lookup: { '0': '不寄送', '1': '需寄送' } },
  dateCol({ title: '客戶生日', field: 'birthday', editable: 'never' },),
  { title: '備註', field: 'psps' },
]);

export default class After5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginUser: this.props.user.profile.toJSON(),
      productType2Opt: [{ key: 'productType20', text: '無', value: 'productType20' }],
      productType3Opt: [{ key: 'productType30', text: '無', value: 'productType30' }],
      productType4Opt: [{ key: 'productType40', text: '無', value: 'productType40' }],
      disabled: true,
      disabled2: true,
      disabled3: true,
      type1: '',
      type2: '',
      value1: 'productType10',
      value2: 'productType20',
      value3: 'productType30',
      value4: 'productType40',
      dataArr: [],
      loading: true,
      periodmail7Id: '1',
    }
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    const { loginUser } = this.state
    const { companyObj } = this.props
    const authPage = window.location.pathname.split('/')[2]
    const authUser = companyObj.objectId + '_' + authPage
    let auth = {}
    let isAgent = ''

    if (loginUser.authAdminA && loginUser.authAdminA.indexOf(companyObj.objectId) === -1 && loginUser.role && loginUser.role.value === '1') {
      isAgent = loginUser.objectId
    }

    if ((loginUser.authAdminA && loginUser.authAdminA.indexOf(companyObj.objectId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
      auth = {
        edit: true,
      }
    } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
      auth = {
        edit: false,
      }
    } else {
      auth = {
        edit: false,
      }
    }
    this.setState({ auth, isAgent }, () => this.handleFetch())
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps)
  }

  handleFetch = async (props = this.props, type = 1) => {
    const { companyObj } = props;
    const { periodmail7Id, isAgent } = this.state;
    const companyId = companyObj.objectId;

    if (type === 1) {
      let data = []
      if (isAgent) {
        data = await Parse.queryData('clients', { companyId, agent_id: isAgent });
      } else {
        data = await Parse.queryData('clients', { companyId });
      }
      let filData = []
      let now = new Date();
      let nowMonth = now.getMonth() + 1 > 12 ? 1 : now.getMonth() + 1
      let nextMonth = now.getMonth() + 2 > 12 ? (now.getMonth() + 2) - 12 : now.getMonth() + 2
      periodmail7Id && periodmail7Id !== '1' ? filData = data.filter(item => item.periodmail7 === periodmail7Id) : filData = data.filter(item => item.periodmail7 === '1')
      let sortArr = filData.filter(item => item.birthday_mm === nowMonth && item.birthday_dd >= now.getDate())
      let sortArr2 = filData.filter(item => item.birthday_mm === nextMonth && now.getDate() - item.birthday_dd >= 0)
      let dataArr1 = sortArr.sort((a, b) => ('' + a.birthday_dd).localeCompare(b.birthday_dd));
      let dataArr2 = sortArr2.sort((a, b) => ('' + a.birthday_dd).localeCompare(b.birthday_dd));
      let dataArr = []
      dataArr = dataArr.concat(dataArr1, dataArr2)
      this.setState({ dataArr, loading: false, clients: data })
    }
  }

  handleUpdate = async () => {
    const { newData } = this.state
    let obj = {
      ...newData,
    }
    // console.log(obj)
    await Parse.saveData('clients', obj);
    this.setState({ newData: {} }, () => this.handleFetch())
  }

  handleChange = (e, { name, value }) => {
    const { clients, } = this.state
    let now = new Date();
    let nowMonth = now.getMonth() + 1 > 12 ? 1 : now.getMonth() + 1
    let nextMonth = now.getMonth() + 2 > 12 ? (now.getMonth() + 2) - 12 : now.getMonth() + 2
    let filData = []
    if (value !== '2') {
      filData = clients.filter(item => item.periodmail7 === value)
    } else {
      filData = clients
    }
    let sortArr = filData.filter(item => item.birthday_mm === nowMonth && item.birthday_dd >= now.getDate())
    let sortArr2 = filData.filter(item => item.birthday_mm === nextMonth && now.getDate() - item.birthday_dd >= 0)
    let dataArr1 = sortArr.sort((a, b) => ('' + a.birthday_dd).localeCompare(b.birthday_dd));
    let dataArr2 = sortArr2.sort((a, b) => ('' + a.birthday_dd).localeCompare(b.birthday_dd));
    let dataArr = []
    dataArr = dataArr.concat(dataArr1, dataArr2)
    this.setState({ dataArr, [name]: value })
  }

  render() {
    const { dataArr, loading, periodmail7Id, auth } = this.state;

    return (<div className='content'>
      <Container>
        <div style={{ ...style.flexCenter, margin: '2rem auto' }}>
          {/* <Header as='h2' style={{ margin: 0 }}>2-4 生日提醒</Header>
          <div style={{ ...style.flex, alignItems: 'flex-end' }}>
            <Button content='寄案件追蹤給Agent' />
            <Form><Form.Select options={[]} label='Agent' /></Form>
          </div> */}
          <Select style={{
            minWidth: '100px',
            zIndex: 99,
            // borderTopLeftRadius: '0',
            // borderBottomLeftRadius: '0'
          }} compact
            options={periodmailSel}
            onChange={this.handleChange}
            value={periodmail7Id}
            name='periodmail7Id' />
        </div>
        <MaterialTable
          isLoading={loading}
          localization={localization()}
          columns={columns()}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportAllData: true,
            exportPdf: (columns, data) => exportPdf(columns, data,),
            pageSize: dataArr.length || 10,
            search: false,
          }}
          data={dataArr}
          title=""
          editable={{
            isEditHidden: () => !auth.edit,
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const data = [...dataArr];
                const index = data.indexOf(oldData);
                data[index] = newData;
                this.setState({ dataArr: data, newData, oldData, loading: true }, () => resolve());
              }).then(() => this.handleUpdate(0)),
          }}
        />
        <br />
        <br />
      </Container>
    </div>)
  }
}