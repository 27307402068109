import React, { Component } from 'react'
import { Button, Form, Checkbox, Table } from 'semantic-ui-react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Table as Table2, Toast, ToastBody } from 'reactstrap';
import { IconButton } from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';

const sel = { 'passname': '拔', 'passname1': '拔 歷代祖先', 'passname2': '拔 冤親債主', 'passname3': '拔 地基主', 'passname4': '拔 嬰靈' };

export default class ModalNamelist3 extends Component {
  state = {
    open: false,
    suirChecked: false,
    checkedValue1: [],
    checkedValue2: [],
    checkedValue3: [],
    checkedValue4: [],
    checkedValue5: [],
    checkedValue6: [],
    checkedValue7: [],
  }

  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  handleClick = (e, item) => {
    const { value, field } = item;

    const checkedValue = this.state[`checkedValue${field}`]
    const indexof = checkedValue.indexOf(value);
    if (checkedValue.indexOf(value) === -1) {
      checkedValue.push(value);
    } else {
      checkedValue.splice(indexof, 1)
    }
    this.setState({ [`checkedValue${field}`]: checkedValue });
  }

  handleSubmit = (e, item) => {
    const { rowData, people1, people0, onRowDataChange } = this.props;
    const { field } = item;
    const checkedValue = this.state[`checkedValue${field}`]

    const peopleArr = (field === '5' || field === '6' || field === '7') ? people0 : people1;
    let nameArr = checkedValue.map(item => {
      const obj = peopleArr.find(item2 => item2.objectId === item);
      return (obj && obj.name) || ''
    })

    let fieldName = ''
    let resArr = nameArr;
    if (field === '1' || field === '5') {
      fieldName = `passname1`;
      resArr = nameArr.map(item => item.substr(0, 1) + '氏歷代袓先')
    } else if (field === '3') { // 地基主
      fieldName = `passname3`;
      resArr = checkedValue.map(item => {
        const obj = people1.find(item2 => item2.objectId === item);
        return (obj && obj.addr) || ''
      })
    } else if (field === '6') { // 超渡
      fieldName = `passname`;
    } else if (field === '7') { // 嬰靈
      fieldName = `passname4`;
    } else {
      fieldName = `passname${field}`;
    }

    const res = resArr.join(',');
    onRowDataChange({ ...rowData, [fieldName]: res });
    window.alert(`代入 ${sel[fieldName]}: ${res}`);
    // this.close();
  }

  render() {
    const { people1, people0 } = this.props
    const { open, checkedValue1, checkedValue2, checkedValue3, checkedValue4, checkedValue5, checkedValue6, checkedValue7 } = this.state

    return (<>
      <IconButton onClick={this.open}><Menu fontSize='small' /></IconButton>
      <Modal
        isOpen={open}
        //  onOpen={this.open}
        //  onClose={this.close}
        size={'lg'}
        toggle={this.close}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.close}>
            <span aria-hidden="true">×</span>
          </button>
          代入超渡資料 (合併)
        </ModalHeader>
        <ModalBody>
          <Table striped size='small'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>陽上名字</Table.HeaderCell>
                <Table.HeaderCell>歷代袓先</Table.HeaderCell>
                <Table.HeaderCell>冤親債主</Table.HeaderCell>
                <Table.HeaderCell>地基主</Table.HeaderCell>
                <Table.HeaderCell>嬰靈</Table.HeaderCell>
                <Table.HeaderCell>地址</Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell><Button size='mini' content='代入' field='1' onClick={this.handleSubmit} /></Table.HeaderCell>
                <Table.HeaderCell><Button size='mini' content='代入' field='2' onClick={this.handleSubmit} /></Table.HeaderCell>
                <Table.HeaderCell><Button size='mini' content='代入' field='3' onClick={this.handleSubmit} /></Table.HeaderCell>
                <Table.HeaderCell><Button size='mini' content='代入' field='4' onClick={this.handleSubmit} /></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {people1.map(({ objectId, name, addr }) => (
                <Table.Row key={'p1' + objectId}>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell> <Checkbox
                    value={objectId}
                    checked={checkedValue1.indexOf(objectId) !== -1}
                    onClick={this.handleClick}
                    field='1'
                  /></Table.Cell>
                  <Table.Cell> <Checkbox
                    value={objectId}
                    checked={checkedValue2.indexOf(objectId) !== -1}
                    onClick={this.handleClick}
                    field='2'
                  /></Table.Cell>
                  <Table.Cell> <Checkbox
                    value={objectId}
                    checked={checkedValue3.indexOf(objectId) !== -1}
                    onClick={this.handleClick}
                    field='3'
                  /></Table.Cell>
                  <Table.Cell> <Checkbox
                    value={objectId}
                    checked={checkedValue4.indexOf(objectId) !== -1}
                    onClick={this.handleClick}
                    field='4'
                  /></Table.Cell>
                  <Table.Cell>{addr}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Table striped size='small'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>過往姓名</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
              {people0.length ? <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell><Button size='mini' field='5' onClick={this.handleSubmit} content='歷代袓先' /></Table.HeaderCell>
                <Table.HeaderCell><Button size='mini' field='6' onClick={this.handleSubmit} content='超渡' /></Table.HeaderCell>
                <Table.HeaderCell><Button size='mini' field='7' onClick={this.handleSubmit} content='嬰靈' /></Table.HeaderCell>
              </Table.Row> :
                <Table.Row >
                  <Table.HeaderCell colSpan={4}>(無超渡者)</Table.HeaderCell>
                </Table.Row>}
            </Table.Header>
            {people0.length ? <Table.Body>
              {people0.map(({ objectId, name, addr, passYear, passMonth, passDay }) => (
                <Table.Row key={'p0' + objectId}>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell> <Checkbox
                    value={objectId}
                    checked={checkedValue5.indexOf(objectId) !== -1}
                    onClick={this.handleClick}
                    field='5'
                  /></Table.Cell>
                  <Table.Cell> <Checkbox
                    value={objectId}
                    checked={checkedValue6.indexOf(objectId) !== -1}
                    onClick={this.handleClick}
                    field='6'
                  /></Table.Cell>
                  <Table.Cell> <Checkbox
                    value={objectId}
                    checked={checkedValue7.indexOf(objectId) !== -1}
                    onClick={this.handleClick}
                    field='7'
                  /></Table.Cell>
                </Table.Row>
              ))}
            </Table.Body> : null}
          </Table>
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button icon='x' content='關閉' onClick={this.close} />
        </ModalFooter>
      </Modal>
    </>)
  }
}
