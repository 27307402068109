import React, { Component } from 'react'
import { Container, Icon, Table, Button, Checkbox, Header, Form, Pagination, Dropdown, Confirm, Input, Label, Tab, Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import Moment from 'moment';
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';
import _ from 'lodash'
import Parse from '../../../widget/parse'


import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import { getWeekNumber, getYearWeek, getWeekdayRangeArr, getWeekdayRangeArrDate, getDateOfISOWeek } from '../../../widget/define/week';
import { pad } from '../../../widget/define/string';
import { weekAndDay, getExpectedAttndDays } from '../../../widget/define/calendar';
import SelectWeek from '../../widgets/SelectWeek';
import { getOptionsSelByKey } from 'views/widgets/FieldsRender';
import ClassGeneration from '../Teacher/ClassGeneration';

const attendanceTypeOpt = [
  { key: 'attendanceTypeOpt1', value: '1', text: '学生トータル出席率リスト' },
  { key: 'attendanceTypeOpt2', value: '2', text: '学生月別出席率リスト' },
  { key: 'attendanceTypeOpt3', value: '3', text: '学生週別出席率リスト' },
  { key: 'attendanceTypeOpt4', value: '4', text: '学生期間指定出席率リスト' },
]
const d = new Date();

const countRate = (a1, b1) => {
  const a = a1 + '';
  const b = b1 + '';
  if (a === "0" && b === "0") {
    return 0;
    // } else if (a === "0" && b === "0") {
    //   return '0%';
  }
  return (Math.round((Number(a) / Number(b) * 1000)) / 10);
}

const colorRate = (a1, b1) => {
  const a = a1 + '';
  const b = b1 + '';
  const red = '#ff8383';
  const yellow = '#ffff6a';
  if (a === "0" && b === "0") {
    // return "100%";
  } else if (a === "0" && b === "0") {
    return red;
  }

  const data = Math.round((Number(a) / Number(b) * 1000)) / 10;
  if (data < 90) {
    return red;
  } else if (data < 95) {
    return yellow;
  }
  return '';
}

export default class AttendanceRate extends Component {
  constructor(props) {
    super(props);
    const d = new Date();
    this.state = {
      allClass: props.classId === 'all' ? true : false,
      loading: false,
      yearWeek: getYearWeek(),
      yearmonth: d.getFullYear().toString() + '-' + pad(d.getMonth() + 1, 2),
      yearmonth0: d.getFullYear().toString() + '-' + pad(d.getMonth() + 1, 2),
      year: d.getFullYear().toString(),
      year0: d.getFullYear().toString(),
      // month: pad(d.getMonth() + 1, 2),
      month: (d.getMonth() + 1) + '',
      month0: (d.getMonth() + 1) + '',
      week: '',
      // classroom: 'A',
      arrStu: [],
      arrRow: [],
      // weekStartDate: getDateOfISOWeek(getWeekNumber(d)[0], getWeekNumber(d)[1], 0),
      weekDateRange: getWeekdayRangeArrDate(Moment(getDateOfISOWeek(getWeekNumber(d)[0], getWeekNumber(d)[1], 0)).format('YYYY-MM-DD')),
      attendKey1: 'xoZzPJIShGRbGDixZ4PG', // 出席 - スマホ使用
      attendKey2: 'Jzi6GnDOr47dnuVvJ0Wu', // 出席 - 居眠り
      attendKey3: 'gT64SX1KHktrPLqoXrmx', // 出席 - その他
      absenceKey1: 'Jymxnr6zBby2ZFenSm80', // 欠席 - わかってる→理由
      absenceKey2: 'uYRdzPeGwVY95zZTWbAg', // 欠席 - わからない、又は連絡なし
      absenceKey3: 'C9YGEjbPDFMztsnVDE0S', // 欠席 - その他
    }
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('reload')
    if (nextProps.semesterYear !== this.props.semesterYear ||
      nextProps.semester !== this.props.semester ||
      // nextProps.params !== this.props.params ||
      nextProps.classId !== this.props.classId) {

      this.setState({ allClass: nextProps.classId === 'all' ? true : false }, () => this.handleFetch(nextProps, this.state))
    }
    if (nextProps.type !== this.props.type) {
      const d = new Date();
      let thisYearMonth = d.getFullYear().toString() + '-' + pad(d.getMonth() + 1, 2)
      this.setState({
        yearmonth: thisYearMonth,
        yearmonth0: thisYearMonth,
        year: d.getFullYear().toString(),
        year0: d.getFullYear().toString(),
        month: (d.getMonth() + 1) + '',
        month0: (d.getMonth() + 1) + '',
      }, () => this.handleFetch(nextProps, this.state))
    }
  }

  handleFetch = async (props = this.props, state = this.state) => {
    const { companyObj, match, students, type, classId, classroom, calendar } = props;
    const { yearWeek, yearmonth0, yearmonth, year, month, allClass, weekDateRange } = state;
    this.setState({ loading: true })
    let firstDay = Moment(weekDateRange[0]).set('date', 1).format('YYYY-MM-DD');
    // console.log(firstDay)
    const firstdayWeek = getWeekNumber(new Date(firstDay));
    // console.log(firstdayWeek)
    const firstdayweekStartDate = getDateOfISOWeek(firstdayWeek[0], firstdayWeek[1], 0);
    const firstweekDateRange = getWeekdayRangeArr(firstdayweekStartDate);
    // console.log(firstweekDateRange)
    let difMonth = (firstDay === firstweekDateRange[1] || firstDay === firstweekDateRange[2] || firstDay === firstweekDateRange[3] || firstDay === firstweekDateRange[4]) ? 1 : 0
    this.setState({ difMonth })

    const tmp = students.filter(item =>
      (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
      (item.applyResult === '1' || item.studentType === "2") && item.classSchool);

    let arrStu = tmp;
    if (classId !== 'all') { // 全部的出席率，只能照班來顯示
      const classObj = classroom.find(item => item.objectId === classId);
      const className = classObj.classSchool;
      arrStu = tmp.filter(item => item.classSchool === className);
      this.setState({ arrStu, className })
    } else {
      arrStu = tmp
      this.setState({ arrStu })
    }
    // console.log(arrStu);

    // var db = firebase.firestore();
    var promises = [];
    const y_w = yearWeek.replace(/[^\d.-]/g, '');

    // console.log(classroom, type, year, month, y_w);

    if (allClass === true) {
      if (type === "3") { // 週別
        const query = new Parse.Query('Weekly');
        query.equalTo('companyId', companyObj.companyId)
        query.equalTo('yearweek', y_w)
        query.lessThanOrEqualTo('daysRate', 100)
        query.limit(10000);
        const promise = query.find().then(snap => {
          let data = []
          snap.forEach(item => data.push(item.toJSON()))
          return { data };
        })
        promises.push(promise);
        // const promise = db.collectionGroup('Weekly')
        //   .where('yearweek', '==', y_w)
        //   .where('daysRate', '<=', 100)
        //   .get().then(snap => {
        //     let data = []
        //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }))
        //     // let daysRate = data[0].daysRate && Number(data[0].daysRate.substring(0, data[0].daysRate.length - 1)) / 100
        //     // console.log(daysRate)
        //     return { data };
        //   })
        // promises.push(promise);
      } else if (type === "2") { // 月別
        const query = new Parse.Query('Monthly');
        query.equalTo('companyId', companyObj.companyId)
        query.equalTo('year', year)
        query.equalTo('month', month)
        query.lessThanOrEqualTo('daysRate', 100)
        query.limit(10000);
        const promise = query.find().then(snap => {
          let data = []
          snap.forEach(item => data.push(item.toJSON()))
          return { data };
        })
        promises.push(promise);
        // const promise = db.collectionGroup('Monthly')
        //   // .where('studentId', '==', _id)
        //   .where('year', '==', year)
        //   .where('month', '==', month)
        //   .where('daysRate', '<=', 100)
        //   .get().then(snap => {
        //     const data = [];
        //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }))
        //     return { data };
        //   })
        // promises.push(promise);
      }
      else if (type === "1" || type === "4") { // トータル or 期間指定
        arrStu.forEach(({ objectId, ...rest }) => {
          const query = new Parse.Query('Monthly');
          query.equalTo('companyId', companyObj.companyId)
          query.equalTo('studentId', objectId)
          query.lessThanOrEqualTo('daysRate', 100)
          query.limit(10000);
          const promise = query.find().then(snap => {
            let data = []
            snap.forEach(item => data.push(item.toJSON()))
            return { objectId, ...rest, data };
          })
          promises.push(promise);
          //   const promise = db.collectionGroup('Monthly')
          //     .where('studentId', '==', _id)
          //     .where('daysRate', '<=', 100)
          //     .get().then(snap => {
          //       const data = [];
          //       snap.forEach(item => data.push({ _id: item.id, ...item.data() }))
          //       return { _id, ...rest, data };
          //     })
          //   promises.push(promise);
        })
      }
    } else {
      arrStu.forEach(({ objectId, ...rest }) => {
        if (type === "1" || type === "4") { // トータル or 期間指定
          const promise = Parse.queryData('Monthly', { companyId: companyObj.objectId, studentId: objectId }).then(snap => {
            const data = [];
            snap.forEach(item => data.push(item))
            return { objectId, ...rest, data };
          })
          promises.push(promise);
          // const promise = db.collectionGroup('Monthly')
          //   .where('studentId', '==', _id)
          //   .get().then(snap => {
          //     const data = [];
          //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }))
          //     return { _id, ...rest, data };
          //   })
          // promises.push(promise);
        } else if (type === "2") { // 月別
          const promise = Parse.queryData('Monthly', { companyId: companyObj.objectId, studentId: objectId, year, month }).then(snap => {
            const data = [];
            snap.forEach(item => data.push(item))
            return { objectId, ...rest, data };
          })
          promises.push(promise);
          // const promise = db.collectionGroup('Monthly')
          //   .where('studentId', '==', _id)
          //   .where('year', '==', year)
          //   .where('month', '==', month)
          //   .get().then(snap => {
          //     const data = [];
          //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }))
          //     // console.log(data)
          //     return { _id, ...rest, data };
          //   })
          // promises.push(promise);
        } else if (type === "3") { // 週別
          const promise = Parse.queryData('Weekly', { companyId: companyObj.objectId, studentId: objectId, yearweek: y_w }).then(snap => {
            const data = [];
            snap.forEach(item => data.push(item))
            return { objectId, ...rest, data };
          })
          promises.push(promise);
          // const promise = db.collectionGroup('Weekly')
          //   .where('studentId', '==', _id)
          //   .where('yearweek', '==', y_w)
          //   .get().then(snap => {
          //     const data = [];
          //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }))
          //     return { _id, ...rest, data };
          //   })
          // promises.push(promise);
        }
      });
    }
    Promise.all(promises).then(results => {
      // console.log('all promises were done')
      // console.log(results);
      const arrRow = [];
      const d = new Date();
      const nowYear = d.getFullYear()
      const nowMonth = (d.getMonth() + 1) + '';
      if (type === "1") { // トータル
        results.forEach(item => {
          // db.collectionGroup('Rollcall')
          //   .where('studentId', '==', item._id)
          //   .get().then(snap => {
          //     const ps = [];
          //     snap.forEach(item2 => ps.push({ _id: item2.id, ...item2.data() }))
          //     item.data[0].psArr = ps
          //     console.log(item.data)
          //   })
          let weeklyExpectedDay = 0;
          let weeklyExpectedHours = 0;
          let weeklyRealDay = 0;
          let weeklyRealHours = 0;

          item.data.forEach(item2 => {
            if (new Date(item2.startDate) <= d) { //計算到當月
              //當月只計算到當天
              if (d.getMonth() === new Date(item2.startDate).getMonth() && nowYear === new Date(item2.startDate).getFullYear()) {
                const monthlyExpectedDay = getExpectedAttndDays(calendar, Moment(d).set('date', 1).format('YYYY-MM-DDT00:00:00'), Moment(d).format('YYYY-MM-DDT00:00:00'));
                // console.log(weeklyExpectedDay, Moment(d).set('date', 1).format('YYYY-MM-DD'), d)
                weeklyExpectedDay += Number(monthlyExpectedDay);
                weeklyExpectedHours += (Number(monthlyExpectedDay) * 4);
                weeklyRealDay += Number(item2.weeklyRealDay);
                weeklyRealHours += Number(item2.weeklyRealHours);
              } else {
                weeklyExpectedDay += Number(item2.weeklyExpectedDay);
                weeklyExpectedHours += Number(item2.weeklyExpectedHours);
                weeklyRealDay += Number(item2.weeklyRealDay);
                weeklyRealHours += Number(item2.weeklyRealHours);
              }
            }
          })
          arrRow.push({
            ...item.data[item.data.length - 1],
            weeklyExpectedDay,
            weeklyExpectedHours,
            weeklyRealDay,
            weeklyRealHours,
            // classSchool: item.classSchool
          })
        })
        // if (classroom === 'all') {
        //   arrRow.filter(item => countRate(item.weeklyRealHours, item.weeklyExpectedHours) < 95)
        // }
      } else if (type === "2") { // 月別
        results.forEach(item => {
          // arrRow.push(item.data[0])
          if (d.getMonth() + 1 === Number(month) && nowYear === Number(year)) { //當月只計算到當天
            const weeklyExpectedDay = getExpectedAttndDays(calendar, Moment(d).set('date', 1).format('YYYY-MM-DDT00:00:00'), Moment(d).format('YYYY-MM-DDT00:00:00'));
            // console.log(weeklyExpectedDay, Moment(d).set('date', 1).format('YYYY-MM-DD'), d)
            item.data.map(item2 => arrRow.push({ ...item2, weeklyExpectedDay, weeklyExpectedHours: weeklyExpectedDay * 4 + '' }))
          } else
            item.data.map(item2 => arrRow.push(item2))
        })
      } else if (type === "3") { // 週別
        results.forEach(item => {
          // console.log(item)
          if (getYearWeek(d) === this.state.yearWeek) { //當週只計算到當天
            const weeklyExpectedDay = getExpectedAttndDays(calendar, weekDateRange[0], d);
            item.data.map(item2 => arrRow.push({ ...item2, weeklyExpectedDay, weeklyExpectedHours: weeklyExpectedDay * 4 + '' }))
          } else
            // db.collectionGroup('Rollcall')
            //   .where('studentId', '==', item._id)
            //   .where('eventDate', '<=', item.data[0].endDate)
            //   .where('eventDate', '>=', item.data[0].startDate)
            //   // .get().then(snap => {
            //   //   const ps = [];
            //   //   snap.forEach(item2 => ps.push({ _id: item2.id, ...item2.data() }))
            //   //   item.data[0].psArr = ps
            //   .get().then(snap => {
            //     const inClassStatus = [];
            //     snap.forEach(item2 => {
            //       inClassStatus.push({ _id: item2.id, ...item2.data() })
            //     })
            //     item.data[0].statusArr = inClassStatus
            //     // console.log(item.data)
            //   })
            item.data.map(item2 => arrRow.push(item2))
          // arrRow.push(item.data[0])
        })
      } else if (type === "4") { // 期間指定
        const ym0 = new Date(yearmonth0 + '-01'); // 開始
        const ym = new Date(yearmonth + '-01'); // 結束
        // console.log(yearmonth0, yearmonth)

        results.forEach(item => {
          // db.collectionGroup('Rollcall')
          //   .where('studentId', '==', item._id)
          //   .where('eventDate', '<=', ym)
          //   .where('eventDate', '>=', ym0)
          //   .get().then(snap => {
          //     const ps = [];
          //     snap.forEach(item2 => ps.push({ _id: item2.id, ...item2.data() }))
          //     item.data[0].psArr = ps
          //     console.log(item.data)
          //   })
          let weeklyExpectedDay = 0;
          let weeklyExpectedHours = 0;
          let weeklyRealDay = 0;
          let weeklyRealHours = 0;

          item.data.forEach(item2 => {
            const startOfMonth = new Date(item2.startDate);
            if (startOfMonth >= ym0 && startOfMonth <= ym) {
              if (d.getMonth() === startOfMonth.getMonth() && nowYear === startOfMonth.getFullYear()) { //當月只計算到當天
                const monthlyExpectedDay = getExpectedAttndDays(calendar, Moment(d).set('date', 1).format('YYYY-MM-DDT00:00:00'), Moment(d).format('YYYY-MM-DDT00:00:00'));
                // console.log(weeklyExpectedDay, Moment(d).set('date', 1).format('YYYY-MM-DD'), d)
                weeklyExpectedDay += Number(monthlyExpectedDay);
                weeklyExpectedHours += (Number(monthlyExpectedDay) * 4);
                weeklyRealDay += Number(item2.weeklyRealDay);
                weeklyRealHours += Number(item2.weeklyRealHours);
              } else {
                weeklyExpectedDay += Number(item2.weeklyExpectedDay);
                weeklyExpectedHours += Number(item2.weeklyExpectedHours);
                weeklyRealDay += Number(item2.weeklyRealDay);
                weeklyRealHours += Number(item2.weeklyRealHours);
              }
            }
          })
          arrRow.push({
            ...item.data[item.data.length - 1],
            weeklyExpectedDay,
            weeklyExpectedHours,
            weeklyRealDay,
            weeklyRealHours
          })
        })
      }
      arrRow.map(item => {
        item.dayRate = item && countRate(item.weeklyRealDay, item.weeklyExpectedDay)
        item.hourRate = item && countRate(item.weeklyRealHours, item.weeklyExpectedHours)
        item.dayColor = item && colorRate(item.weeklyRealDay, item.weeklyExpectedDay)
        item.hourColor = item && colorRate(item.weeklyRealHours, item.weeklyExpectedHours)
      })
      if (allClass === true) {
        arrRow.sort((a, b) => ('' + a.studentNum).localeCompare('' + b.studentNum))
          .sort((a, b) => (Number(a.dayRate)) - (Number(b.dayRate)))
      }
      // console.log(arrRow);
      this.setState({ arrRow, loading: false }, () => this.getPrintData())
    }).catch(error => {
      console.log(error);
    });
  }

  handleChange = (e, { name, value, checked }) => {
    // console.log(data)
    console.log(name, value)

    if (name === 'yearmonth') {
      const [year, month1] = value && value.split('-'); // 2019-W10 => 2019, W10
      const month = Number(month1).toString(); // W10 => 10
      this.setState({ yearmonth: value, year, month, loading: 1 }, () => this.handleFetch());
    } else if (name === 'yearmonth0') {
      const [year0, month1] = value && value.split('-'); // 2019-W10 => 2019, W10
      const month0 = Number(month1).toString(); // W10 => 10
      this.setState({ yearmonth0: value, year0, month0, loading: 1 }, () => this.handleFetch());
    } else if (name === 'allClass') {
      this.setState({ allClass: checked }, () => this.handleFetch());
    } else {
      this.setState({
        [name]: value,
        loading: 1
      }, () => this.handleFetch());
    }
  }

  handleWeekChange = (e, data) => {
    let yearWeek = data;
    if (typeof data !== 'string') {
      yearWeek = data.value
    }
    const [year, Wweek] = yearWeek && yearWeek.split('-'); // 2019-W10 => 2019, W10
    const week = Wweek.slice(1); // W10 => 10
    const weekStartDate = getDateOfISOWeek(year, week, 0);
    const weekDateRange = getWeekdayRangeArrDate(weekStartDate);

    let firstDay = Moment(weekDateRange[0]).set('date', 1).format('YYYY-MM-DD');
    // console.log(firstDay)
    const firstdayWeek = getWeekNumber(new Date(firstDay));
    // console.log(firstdayWeek)
    const firstdayweekStartDate = getDateOfISOWeek(firstdayWeek[0], firstdayWeek[1], 0);
    const firstweekDateRange = getWeekdayRangeArr(firstdayweekStartDate);
    // console.log(firstweekDateRange)
    let difMonth = (firstDay === firstweekDateRange[1] || firstDay === firstweekDateRange[2] || firstDay === firstweekDateRange[3] || firstDay === firstweekDateRange[4]) ? 1 : 0

    this.setState({ difMonth, yearWeek, year, week, loading: 1, weekDateRange }, () => this.handleFetch());
  };

  // handleStatus = (item) => {
  //   const { attendKey1, attendKey2, attendKey3, absenceKey1, absenceKey2, absenceKey3 } = this.state
  //   if (item && item.statusArr && item.statusArr.length) {
  //     item.statusArr.map((item2, index2) => {
  //       if (item2.inClassStatus2) {
  //         console.log(Object.keys(item2.inClassStatus2)[Object.values(item2.inClassStatus2).findIndex(item3 => item3 === true)])
  //       }
  //       return <></>
  //       // <span key={`${index}ps${index2}`}>{item2.ps}</span>) : ''}
  //     })
  //   } else {
  //     return ''
  //   }
  // }
  handleSort = clickedColumn => () => {
    const { column, arrRow, direction } = this.state

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        arrRow: _.sortBy(arrRow, [clickedColumn]),
        direction: 'ascending',
      }, () => this.getPrintData())
      return
    }
    // if (column === 'dayRate') {
    //   this.setState({
    //     column: clickedColumn,
    //     arrRow: _.sortBy(arrRow, [clickedColumn]),
    //     direction: 'ascending',
    //   }, () => this.getPrintData())
    //   return
    // }
    this.setState({
      arrRow: arrRow.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    }, () => this.getPrintData())
  }

  getPrintData = () => {
    const { doc, type } = this.props;
    const { difMonth, arrRow, className, year, month, weekDateRange, year0, month0, allClass } = this.state;
    let classSel = this.props.classroom.find(item => item.classSchool === className);
    let subtitle = ''
    // {type === "1" ? <p style={{ fontSize: '18px', margin: 0 }}>{classroom} クラス 入学日{classSel.entryYear}年{classSel.entryMonth}月1日 ~ 今{`${new Date().getFullYear()}年${new Date().getMonth() + 1}月${new Date().getDate()}日`}</p> : null}
    // {type === "2" ? <p style={{ fontSize: '18px', margin: 0 }}>{classroom} クラス {year}年{month}月</p> : null}
    // {type === "3" ? <p style={{ fontSize: '18px', margin: 0 }}>{classroom} クラス {year}年{weekDateRange[0].getMonth() + 1}月 第{weekAndDay(weekDateRange[0])}週 {`${weekDateRange[0].getMonth() + 1}月${weekDateRange[0].getDate()}日`} ~ {`${weekDateRange[4].getMonth() + 1}月${weekDateRange[4].getDate()}日`}</p> : null}
    // {type === "4" ? <p style={{ fontSize: '18px', margin: 0 }}>{classroom} クラス {year0}年{month0}月 ~ {year}年{month}月</p> : null}
    switch (type) {
      case '1':
        subtitle = `${!allClass ? className : 'トータル'} クラス 入学日${classSel ? classSel.entryYear : ''}年${classSel ? classSel.entryMonth : ''}月1日 ～ 今${new Date().getFullYear()}年${new Date().getMonth() + 1}月${new Date().getDate()}日`
        break;
      case '2':
        subtitle = `${!allClass ? className : 'トータル'} クラス ${year}年${month}月${d.getMonth() + 1 === Number(month) ? `1日 ～ 今${month}月${d.getDate()}日` : ''}`
        break;
      case '3':
        subtitle = `${!allClass ? className : 'トータル'} クラス ${year}年${weekDateRange[0].getMonth() + 1}月 第${weekAndDay(weekDateRange[0]) + difMonth}週${weekDateRange[0].getMonth() !== weekDateRange[4].getMonth() ? ` / ${weekDateRange[4].getFullYear()}年${weekDateRange[4].getMonth() + 1}月 第${weekAndDay(weekDateRange[4])}週` : ''} ${(weekDateRange[0]).getMonth() + 1}月${weekDateRange[0].getDate()}日 ～ ${getYearWeek(d) === this.state.yearWeek ? '今' + (d.getMonth() + 1) : (weekDateRange[4].getMonth() + 1)}月${(getYearWeek(d) === this.state.yearWeek ? d : weekDateRange[4]).getDate()}日`
        break;
      case '4':
        subtitle = `${!allClass ? className : 'トータル'} クラス ${year0}年${month0}月 ～ ${d.getMonth() + 1 === Number(month) ? '今' : ''}${year}年${month}月${d.getMonth() + 1 === Number(month) ? d.getDate() + '日' : ''}`
        break;
      default:
        subtitle = ''
        break;
    }
    let arr = [];
    arrRow.map(item => {
      // let dayRate = item && countRate(item.weeklyRealDay, item.weeklyExpectedDay)
      // let hourRate = item && countRate(item.weeklyRealHours, item.weeklyExpectedHours)
      // let dayColor = item && colorRate(item.weeklyRealDay, item.weeklyExpectedDay)
      // let hourColor = item && colorRate(item.weeklyRealHours, item.weeklyExpectedHours)
      arr.push({
        ...item,
        // dayRate,
        // hourRate,
        // dayColor,
        // hourColor,
        className,
        subtitle
      })
    })
    // console.log(arr)
    this.setState({ params: arr })
  }

  render() {
    const { optSet, type, classroom, companyObj } = this.props;
    const { difMonth, yearWeek, month, month0, allClass, className, direction, column, yearmonth, yearmonth0, arrRow, loading, year, year0, weekDateRange, params, attendKey1, attendKey2, attendKey3, absenceKey1, absenceKey2, absenceKey3 } = this.state
    const classSchool = [{ key: '0', text: '- クラス選択 -', value: '0' }, { key: 'all', text: '全校出席異常', value: 'all' }].concat(getOptionsSelByKey(optSet, "classSchool"));
    let classSel = this.props.classroom.find(item => item.classSchool === className);
    let codekey = ''
    if (type === '1')
      codekey = 'att2'
    else if (type === '2')
      codekey = 'att3'
    else if (type === '3')
      codekey = 'att4'
    else if (type === '4')
      codekey = 'att5'
    // console.log(arrRow)
    return (
      <div ref={el => (this.componentRef = el)} className="">
        <div style={{ padding: '0px' }} >

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px 0' }}>
            <Header as='h2' style={{ margin: 0 }}>{attendanceTypeOpt.find(item => item.value === type) ? attendanceTypeOpt.find(item => item.value === type).text : ''}</Header>
            <div style={{ display: 'flex' }}>
              {/* <ReactToPrint
              trigger={(e) => <Button
                content='印刷'
                // label='&nbsp;'
                color='orange' icon='print'
                size='small'
                floated='right'
                className='no-print'
              />}
              content={() => this.componentRef}
              pageStyle={"@page { size: auto;  margin:25mm; }*{font-family: 'Noto Serif JP', serif;}"}
            /> */}
              <ExportTableToExcelButton size='small' />
              <ModalPdf
                {...this.props}
                // ref={(ref) => { this.pdfModal = ref }}
                codekey={codekey}
                params={params}
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {type === "1" ? <p style={{ fontSize: '18px', margin: 0 }}>{className} クラス 入学日 ~ 今{`${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`}</p> : null}
            {type === "2" ? <p style={{ fontSize: '18px', margin: 0 }}>{!allClass ? className : 'トータル'} クラス {year}年{month}月{d.getMonth() + 1 === Number(month) ? `1日 ～ 今${month}月${d.getDate()}日` : ''}</p> : null}
            {type === "3" ? <p style={{ fontSize: '18px', margin: 0 }}>{!allClass ? className : 'トータル'} クラス
            {year}年{weekDateRange[0].getMonth() + 1}月 第{weekAndDay(weekDateRange[0]) + difMonth}週{weekDateRange[0].getMonth() !== weekDateRange[4].getMonth() ? ` / ${weekDateRange[4].getFullYear()}年${weekDateRange[4].getMonth() + 1}月 第${weekAndDay(weekDateRange[4])}週` : ''}&nbsp;&nbsp;
 {`${weekDateRange[0].getMonth() + 1}月${weekDateRange[0].getDate()}日`} ～ {`${getYearWeek(d) === this.state.yearWeek ? ' 今' + (d.getMonth() + 1) : (weekDateRange[4].getMonth() + 1)}月${(getYearWeek(d) === this.state.yearWeek ? d : weekDateRange[4]).getDate()}日`}</p> : null}
            {type === "4" ? <p style={{ fontSize: '18px', margin: 0 }}>{!allClass ? className : 'トータル'} クラス {year0}年{month0}月 ~ {d.getMonth() + 1 === Number(month) ? '今' : ''}{year}年{month}月{d.getMonth() + 1 === Number(month) ? d.getDate() + '日' : ''}</p> : null}
            <div style={{ display: 'flex' }}>
              {/* <Input labelPosition='left'>
                <Label color='violet'><Icon name='building' />クラス</Label>
                <Form.Select
                  style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
                  name='classroom'
                  value={classroom}
                  options={classSchool}
                  onChange={this.handleChange} />
              </Input> */}
              {/* {type === '2' || type === '3' ?
                <Checkbox label='顯示全校異常出席' name='allClass' onClick={this.handleChange} defaultChecked={allClass} /> : null}
              &nbsp;&nbsp;&nbsp; */}
              {type === "4" ? <Input labelPosition='left' style={{ marginLeft: 10 }}>
                <Label color={'teal'}> <Icon name='calendar alternate' />月</Label>
                <Form.Input
                  style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
                  name="yearmonth0" type="month" value={yearmonth0} onChange={this.handleChange} />
              </Input> : null}
              {type === "4" ? <p style={{ fontSize: '14px', margin: 0 }}>から～</p> : null}
              {type === "2" || type === "4" ? <> <Input labelPosition='left' style={{ marginLeft: 20 }}>
                <Label color={'teal'}> <Icon name='calendar alternate' />月</Label>
                <Form.Input
                  style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
                  name="yearmonth" type="month" value={yearmonth} onChange={this.handleChange} />
              </Input>
                {/* <Button basic circular >
                  <a href="https://us-central1-school-infowin.cloudfunctions.net/jsonImport6">Renew</a>
                </Button> */}
              </> : null}
              {type === "4" ? <p style={{ fontSize: '14px', margin: 0 }}>まで</p> : null}
              {type === "3" ? <>
                <div style={{ marginLeft: 20 }}>
                  <SelectWeek
                    yearWeek={yearWeek}
                    handleWeekChange={this.handleWeekChange}
                  />
                </div>
                <div style={{ float: 'right' }}>
                  <Label pointing='left' size={'large'}>{`${weekDateRange[0].getMonth() + 1} 月 ${weekDateRange[0].getDate()} 日`} ~ {`${weekDateRange[4].getMonth() + 1} 月 ${weekDateRange[4].getDate()} 日`}</Label></div></> : null}
            </div>
          </div>
        </div>
        {allClass && type === '1' ? <Table celled striped selectable id='excel' className='tdPadding2px thPadding2px'>
          <Table.Header>
            <Table.Row textAlign='center'>
              <Table.HeaderCell rowSpan={2}>クラス</Table.HeaderCell>
              <Table.HeaderCell rowSpan={2}>担当</Table.HeaderCell>
              <Table.HeaderCell rowSpan={2}>人数</Table.HeaderCell>
              <Table.HeaderCell colSpan={2}>50以下人数</Table.HeaderCell>
              <Table.HeaderCell colSpan={2}>50--59人数</Table.HeaderCell>
              <Table.HeaderCell colSpan={2}>60--69人数</Table.HeaderCell>
              <Table.HeaderCell colSpan={2}>70--79人数</Table.HeaderCell>
              <Table.HeaderCell colSpan={2}>80--89人数</Table.HeaderCell>
              <Table.HeaderCell colSpan={2}>90--94人数</Table.HeaderCell>
              <Table.HeaderCell colSpan={2}>95--100人数</Table.HeaderCell>
              <Table.HeaderCell colSpan={2}>平均出席率</Table.HeaderCell>
            </Table.Row>
            <Table.Row textAlign='center'>
              <Table.HeaderCell>時</Table.HeaderCell>
              <Table.HeaderCell>日</Table.HeaderCell>
              <Table.HeaderCell>時</Table.HeaderCell>
              <Table.HeaderCell>日</Table.HeaderCell>
              <Table.HeaderCell>時</Table.HeaderCell>
              <Table.HeaderCell>日</Table.HeaderCell>
              <Table.HeaderCell>時</Table.HeaderCell>
              <Table.HeaderCell>日</Table.HeaderCell>
              <Table.HeaderCell>時</Table.HeaderCell>
              <Table.HeaderCell>日</Table.HeaderCell>
              <Table.HeaderCell>時</Table.HeaderCell>
              <Table.HeaderCell>日</Table.HeaderCell>
              <Table.HeaderCell>時</Table.HeaderCell>
              <Table.HeaderCell>日</Table.HeaderCell>
              <Table.HeaderCell>時</Table.HeaderCell>
              <Table.HeaderCell>日</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {classroom ? classroom.map((item, index) => {
              const classNum = arrRow.filter(arr => arr.classname === item.classSchool)
              let avgDayRate = 0
              let avghourRate = 0
              classNum.map(item => {
                avgDayRate += item.dayRate
                avghourRate += item.hourRate
              })
              return <React.Fragment key={index}>
                <Table.Row>
                  <Table.Cell>{item.classSchool}</Table.Cell>
                  <Table.Cell>{item.teacherText}</Table.Cell>
                  <Table.Cell>{classNum.length}</Table.Cell>
                  <Table.Cell>{classNum.filter(item => item.hourRate < 50).length}</Table.Cell>
                  <Table.Cell>{classNum.filter(item => item.dayRate < 50).length}</Table.Cell>
                  <Table.Cell>{classNum.filter(item => item.hourRate < 60 && item.hourRate >= 50).length}</Table.Cell>
                  <Table.Cell>{classNum.filter(item => item.dayRate < 60 && item.dayRate >= 50).length}</Table.Cell>
                  <Table.Cell>{classNum.filter(item => item.hourRate < 70 && item.hourRate >= 60).length}</Table.Cell>
                  <Table.Cell>{classNum.filter(item => item.dayRate < 70 && item.dayRate >= 60).length}</Table.Cell>
                  <Table.Cell>{classNum.filter(item => item.hourRate < 80 && item.hourRate >= 70).length}</Table.Cell>
                  <Table.Cell>{classNum.filter(item => item.dayRate < 80 && item.dayRate >= 70).length}</Table.Cell>
                  <Table.Cell>{classNum.filter(item => item.hourRate < 90 && item.hourRate >= 80).length}</Table.Cell>
                  <Table.Cell>{classNum.filter(item => item.dayRate < 90 && item.dayRate >= 80).length}</Table.Cell>
                  <Table.Cell>{classNum.filter(item => item.hourRate < 95 && item.hourRate >= 90).length}</Table.Cell>
                  <Table.Cell>{classNum.filter(item => item.dayRate < 95 && item.dayRate >= 90).length}</Table.Cell>
                  <Table.Cell>{classNum.filter(item => item.hourRate <= 100 && item.hourRate >= 95).length}</Table.Cell>
                  <Table.Cell>{classNum.filter(item => item.dayRate <= 100 && item.dayRate >= 95).length}</Table.Cell>
                  <Table.Cell>{classNum.length ? (avgDayRate / classNum.length).toFixed(1) : 0}</Table.Cell>
                  <Table.Cell>{classNum.length ? (avghourRate / classNum.length).toFixed(1) : 0}</Table.Cell>
                </Table.Row>
              </React.Fragment>
            }) : <Table.Row>
                <Table.Cell colSpan='15' textAlign='center' >(資料無し)</Table.Cell>
              </Table.Row>}
          </Table.Body>
        </Table> :
          <Table size="small" striped celled className='thPadding2px tdPadding2px'>
            <Table.Header >
              <Table.Row>
                <Table.HeaderCell style={{ width: "60px" }} ></Table.HeaderCell>
                <Table.HeaderCell style={{ width: "60px" }} >50以下</Table.HeaderCell>
                <Table.HeaderCell style={{ width: "60px" }} >50~70</Table.HeaderCell>
                <Table.HeaderCell style={{ width: "60px" }} >70~80</Table.HeaderCell>
                <Table.HeaderCell style={{ width: "60px" }} >80~90</Table.HeaderCell>
                <Table.HeaderCell style={{ width: "60px" }} >90以上</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>日出席率人数</Table.Cell>
                <Table.Cell>{arrRow ? arrRow.filter(item => item.dayRate < 50).length : 0}</Table.Cell>
                <Table.Cell>{arrRow ? arrRow.filter(item => item.dayRate < 70 && item.dayRate >= 50).length : 0}</Table.Cell>
                <Table.Cell>{arrRow ? arrRow.filter(item => item.dayRate < 80 && item.dayRate >= 70).length : 0}</Table.Cell>
                <Table.Cell>{arrRow ? arrRow.filter(item => item.dayRate < 90 && item.dayRate >= 80).length : 0}</Table.Cell>
                <Table.Cell>{arrRow ? arrRow.filter(item => item.dayRate >= 90).length : 0}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>時出席率人数</Table.Cell>
                <Table.Cell>{arrRow ? arrRow.filter(item => item.hourRate < 50).length : 0}</Table.Cell>
                <Table.Cell>{arrRow ? arrRow.filter(item => item.hourRate < 70 && item.hourRate >= 50).length : 0}</Table.Cell>
                <Table.Cell>{arrRow ? arrRow.filter(item => item.hourRate < 80 && item.hourRate >= 70).length : 0}</Table.Cell>
                <Table.Cell>{arrRow ? arrRow.filter(item => item.hourRate < 90 && item.hourRate >= 80).length : 0}</Table.Cell>
                <Table.Cell>{arrRow ? arrRow.filter(item => item.hourRate >= 90).length : 0}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>}
        <Table celled sortable textAlign='center' id='excel' className='tdPadding2px thPadding2px'>
          <Table.Header>
            <Table.Row>
              {!allClass ? null : <Table.HeaderCell>番号</Table.HeaderCell>}
              <Table.HeaderCell sorted={column === 'studentNum' ? direction : null} onClick={this.handleSort('studentNum')}>学籍番号</Table.HeaderCell>
              {!allClass ? null : <Table.HeaderCell>クラス</Table.HeaderCell>}
              <Table.HeaderCell>氏名</Table.HeaderCell>
              {type === "2" || type === "3" ? <Table.HeaderCell>年</Table.HeaderCell> : null}
              {type === "2" || type === "3" ? <Table.HeaderCell>月</Table.HeaderCell> : null}
              {type === "3" ? <Table.HeaderCell>週</Table.HeaderCell> : null}
              <Table.HeaderCell sorted={column === 'dayRate' ? direction : null} onClick={this.handleSort('dayRate')}>日出席率（％）</Table.HeaderCell>
              <Table.HeaderCell sorted={column === 'hourRate' ? direction : null} onClick={this.handleSort('hourRate')}>時出席率（％）</Table.HeaderCell>
              <Table.HeaderCell>授業日数</Table.HeaderCell>
              <Table.HeaderCell>出席日数</Table.HeaderCell>
              <Table.HeaderCell>授業時間</Table.HeaderCell>
              <Table.HeaderCell>出席時間</Table.HeaderCell>
              {type === '3' ? <Table.HeaderCell>備考</Table.HeaderCell> : null}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {loading ? <Table.Row>
              <Table.Cell colSpan={12}>
                <Segment>
                  <Dimmer active inverted={companyObj.inverted}>
                    <Loader inverted={companyObj.inverted}>Loading</Loader>
                  </Dimmer>
                  <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                </Segment>
              </Table.Cell></Table.Row> :
              <>{
                arrRow && arrRow.length ? arrRow.map((item, index) => (<Table.Row key={"aaa" + index}>
                  {!allClass ? null : <Table.Cell>{index + 1}</Table.Cell>}
                  <Table.Cell>{item && item.studentNum}</Table.Cell>
                  {allClass ? <Table.HeaderCell>{item && item.classname}</Table.HeaderCell> : null}
                  <Table.Cell><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/2/2`}>{item && item.jpnName}</Link></Table.Cell>
                  {type === "2" || type === "3" ? <Table.Cell>{item && item.year}</Table.Cell> : null}
                  {type === "2" || type === "3" ? <Table.Cell>{item && item.month}</Table.Cell> : null}
                  {/* {type === "3" ? <Table.Cell>{item && (Moment(weekDateRange[0]) < Moment(d).set('date', 1) && Moment(d).set('date', 1) < Moment(weekDateRange[4]) ? Number(item.week) + 1 : item.week)}</Table.Cell> : null} */}
                  {type === "3" ? <Table.Cell>{item && item.week}</Table.Cell> : null}
                  <Table.Cell style={{ backgroundColor: item ? item.dayColor : null }}>{item ? item.dayRate + '%' : null}</Table.Cell>
                  <Table.Cell style={{ backgroundColor: item ? item.hourColor : null }}>{item ? item.hourRate + '%' : null}</Table.Cell>
                  <Table.Cell>{item && item.weeklyExpectedDay}</Table.Cell>
                  <Table.Cell>{item && item.weeklyRealDay}</Table.Cell>
                  <Table.Cell>{item && item.weeklyExpectedHours}</Table.Cell>
                  <Table.Cell>{item && item.weeklyRealHours}</Table.Cell>
                  {type === "3" ? <Table.Cell>
                    {/* {this.handleStatus(item)} */}
                    {item && item.statusArr && item.statusArr.length ? item.statusArr.map((item2, index2) => {
                      let statusMark = ''
                      let statusColor = '#000'
                      if (item2.inClassStatus1) {
                        let statusLabel1 = Object.keys(item2.inClassStatus1)[Object.values(item2.inClassStatus1).findIndex(item3 => item3 === true)]
                        switch (statusLabel1) {
                          case absenceKey1:
                            statusMark = '①'
                            statusColor = '#db2828'
                            break;
                          case absenceKey2:
                            statusMark = '②'
                            statusColor = '#db2828'
                            break;
                          case absenceKey3:
                            statusMark = '③'
                            statusColor = '#db2828'
                            break;
                          default:
                            statusMark = ''
                            statusColor = '#000'
                            break;
                        }
                      }
                      if (item2.inClassStatus2) {
                        let statusLabel2 = Object.keys(item2.inClassStatus2)[Object.values(item2.inClassStatus2).findIndex(item3 => item3 === true)]
                        switch (statusLabel2) {
                          case attendKey1:
                            statusMark = '①'
                            statusColor = '#21ba45'
                            break;
                          case attendKey2:
                            statusMark = '②'
                            statusColor = '#21ba45'
                            break;
                          case attendKey3:
                            statusMark = '③'
                            statusColor = '#21ba45'
                            break;
                          default:
                            statusMark = ''
                            statusColor = '#000'
                            break;
                        }
                      }
                      return <span key={`${index}mark${index2}`} style={{ color: statusColor }}>{statusMark}</span>

                      // <span key={`${index}ps${index2}`}>{item2.ps}</span>) : ''}
                    }) : ''}
                  </Table.Cell> : null}
                </Table.Row>)
                ) : <Table.Row><Table.Cell colSpan={10}>出席資料無し</Table.Cell></Table.Row>}</>}
          </Table.Body>
        </Table>
      </div>)
  }
}