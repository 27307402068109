
import React, { Component } from 'react'
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";

import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import Footer2 from "components/Footer/Footer.jsx";
import routes from "routes.js";
// import styled from 'styled-components';

// import css from '../assets/css/paper-dashboard.min.scoped.css';
// import css from 'assets/css/paper-dashboard.min.css';
// import 'assets/css/paper-dashboard.min.css';
// import css2 from 'assets/css/demo-dashboard.scoped.css';
var ps;
// console.log(css)
// const StyledDashBoard = styled.div`
//   ${css}
//   ${css2}
// `

// const StyledDashBoard = styled.div`
// `

class Pages extends Component {
  componentDidMount = async () => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.fullPages);
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            key={key}
            path={prop.layout + prop.path}
            render={props => (
              React.createElement((prop.component), { ...props, ...this.props })
            )}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    // console.log(styles)
    return (<>
      <AuthNavbar {...this.props} />
      <div className="wrapper wrapper-full-page" ref="fullPages">
        <div className="full-page section-image">
          <Switch>{this.getRoutes(routes)}</Switch>
          <Footer2 fluid />
        </div>
      </div>
    </>);
  }
}

export default Pages;