import React, { Component } from 'react'
import { Header, Form, Button, Icon } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Moment from 'moment';
// import JoditEditor from "jodit-react";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'widget/ckeditor';
import localization from 'widget/MaterialTableOpt'
import Parse from 'widget/parse'


// const TH = [
//   { text: '' },
//   { text: '權限' },
//   { text: '帳號' },
//   { text: '電子郵件' },
//   { text: '建立日期' }
// ]

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

const columns = () => ([
  // { title: '#', field: 'sexual' },
  { title: '主旨', field: 'subject' },
  { title: '收件人', field: 'To_name_cht' },
  // { title: '收件人2', field: 'To2' },
  { title: '副本', field: 'cc' },
  { title: '密件副本', field: 'bcc' },
  { title: '狀態', field: 'status_text' },
  { title: '排程時間', field: 'scheduleAt', render: rowData => Moment(rowData.scheduleAt.iso).format('YYYY-MM-DD HH:mm:ss') },
  {
    title: '附件', field: 'attachments', render: rowData => {
      let showArr = []
      if (rowData.attachments !== undefined && rowData.attachments.length > 0) {
        rowData.attachments.forEach(item => {
          showArr.push(<a href={item.path} target="_blank" rel="noopener noreferrer">{item.filename}</a>)
          showArr.push(<br />)
        })
      }
      return showArr
    }
  },
]);

export default class After1c extends Component {
  state = {
    dataArr: [],
    show: false,
    rowData: {},
    loading: true,
  }

  async componentDidMount() {
    const { firebase, match, isEdit, bgId, companyObj } = this.props;
    // const db = firebase.firestore();
    const companyId = companyObj.objectId
    // if (bgId) {
    // db.collection(`company/${companyObj._id}/bg/${bgId}/sendEmail`).where('status', '==', '2').where('type', '==', 'timeEmail').get().then(snap => {
    // const data = [];
    // snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }));
    const email = await Parse.queryData('email', { companyId, status: '2', type: 'timeEmail' }, { orderBy: 'scheduleAt_descend' });
    this.setState({ dataArr: email, loading: false })
    // }, err => {
    //   console.log(`Encountered error: ${err}`);
    // });
    // }
    // this.handleFetch();
  }

  handleClose = () => {
    this.setState({ show: false });
  }

  render() {
    const { companyObj } = this.props
    const { dataArr, show, rowData, loading, isEdit } = this.state;

    return (<Form inverted={companyObj.inverted}>
      <Header inverted={companyObj.inverted} as='h3' style={{ margin: '1rem auto' }}>2-1-3 寄件備份</Header>
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        style={{ marginBottom: '2rem' }}
        columns={columns()}
        options={{
          // addRowPosition: "first",
          exportButton: true,
          pageSize: dataArr.length || 10,
          search: true
        }}
        actions={[
          {
            icon: 'view_headline',
            tooltip: '查看',
            onClick: (e, Data) => this.setState({ show: true, rowData: Data })
          }
        ]}
        data={dataArr}
        title="寄件備份"
      // editable={{
      //   onRowAdd: newData =>
      //     new Promise((resolve, reject) => {
      //       const data = [...dataArr];
      //       data.push(newData);
      //       // handleState('people0', data);
      //       this.setState({ dataArr: data, newData }, () => resolve());
      //     }).then(() => this.handleAdd(0, 0)),
      //   onRowUpdate: (newData, oldData) =>
      //     new Promise((resolve, reject) => {
      //       const data = [...dataArr];
      //       const index = data.indexOf(oldData);
      //       data[index] = newData;
      //       // handleState('people0', data);
      //       this.setState({ dataArr: data, newData, oldData }, () => resolve());
      //     }).then(() => this.handleUpdate(0)),
      //   onRowDelete: oldData =>
      //     new Promise((resolve, reject) => {
      //       let data = [...dataArr];
      //       const index = data.indexOf(oldData);
      //       data.splice(index, 1);
      //       // handleState('people0', data);
      //       this.setState({ dataArr: data, oldData }, () => resolve());
      //     }).then(() => this.handleDelete(0)),
      // }}
      />
      <br />
      <br />
      <Modal isOpen={show} toggle={this.handleClose} centered={false}>
        <ModalHeader>郵件內容
           <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
        </ModalHeader>
        <ModalBody><Form>
          <CKEditor
            editor={ClassicEditor}
            data={rowData ? rowData.html : null}
            onInit={iniEditor => {
              // You can store the "editor" and use when it is needed.
              console.log('Editor is ready to use!', iniEditor);
              iniEditor.isReadOnly = true;
              // this.setState({ editor })
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              this.handleBlur("html", data)
              // console.log({ event, editor, data });
            }}
          // onBlur={(event, editor) => {
          //   console.log('Blur.', editor);
          // }}
          // onFocus={(event, editor) => {
          //   console.log('Focus.', editor);
          // }}
          />
        </Form>
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button onClick={this.handleClose} inverted color='red' icon='delete' content='返回' />
        </ModalFooter>
      </Modal>
    </Form>)
  }
}
