import React, { Component } from 'react'
import { Button } from 'semantic-ui-react';
import ReactFileReader from 'react-file-reader';
import MaterialTable, { MTableToolbar } from 'material-table'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

const columns = () => ([
  { title: 'PriceDate', field: 'PriceDate' },
  { title: 'ExtractTime', field: 'ExtractTime' },
  { title: 'PlanNumber', field: 'LifePlanReference' },
  { title: 'FundDescription', field: 'FundDescription' },
  { title: 'UnitType', field: 'UnitType' },
  { title: 'UnitsHeld', field: 'UnitsHeld' },
  { title: 'BidPrice', field: 'BidPrice' },
  { title: 'FundCurrencyISO', field: 'FundCurrency' },
  { title: 'FundCurrencyValue', field: 'FundCurrencyValue' },
  { title: 'ValuationCurrencyValue', field: 'ValuationCurrencyValue' },
]);

export default class After6 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginUser: this.props.user.profile.toJSON(),
      dataObjArr: [],
      funArr: [],
      funNameArr: [],
      loading: false,
      loading2: false,
    };
  }

  componentDidMount() {
    const { loginUser } = this.state
    const { companyObj } = this.props
    const authPage = window.location.pathname.split('/')[2]
    const authUser = companyObj.objectId + '_' + authPage
    let auth = {}

    if ((loginUser.authAdminA && loginUser.authAdminA.indexOf(companyObj.objectId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
      auth = {
        edit: true,
      }
    } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
      auth = {
        edit: false,
      }
    } else {
      auth = {
        edit: false,
      }
    }
    this.setState({ auth })
  }

  handleFiles = async (files) => {
    const dataObjArr = []
    let funArr = []
    let funNameArr = []
    this.setState({ loading: true });

    let reader = new FileReader();
    reader.onload = (e) => {
      const objArr = reader.result.split("\r\n")
      objArr.splice(0, 1);
      objArr.splice(objArr.length - 1, 1);
      objArr.forEach(item => {
        const array1 = item.split(',')
        const array2 = array1[0].split(" ")

        let exTime = `${array2[0]} AM ${array2[2]}`
        if (array2[1] === "ï¿½Wï¿½ï¿½" || array2[1] === "¤U¤È") {
          exTime = `${array2[0]} PM ${array2[2]}`;
          // } else if (array2[1] === "¤U¤È") {
          // if (array2[1] === "¤W¤È") {
          // } else if (array2[1] === "�U��") {
        }
        const obj = {
          ExtractTime: exTime || '',
          BidPrice: array1[10] || '',
          FundCurrency: array1[12] || '',
          FundCurrencyValue: array1[13] || '',
          FundDescription: array1[7] || '',
          LifePlanReference: array1[3] || '',
          PriceDate: array1[11] || '',
          UnitType: array1[8] || '',
          UnitsHeld: array1[9] || '',
          ValuationCurrencyValue: array1[17] || '',
          PolicyId: array1[4] || '',
        }
        const fund = { // 要記錄的東西
          FundCode: array1[5] || '',
          FundDescription: array1[7] || '',
          BidPrice: array1[10] || '',
          PriceDate: array1[11] || '',
          FundCurrencyISO: array1[16] || '',
        }
        dataObjArr.push({ ...obj, ...fund })
        if (funNameArr.indexOf(fund.FundDescription) === -1) {
          funNameArr.push(fund.FundDescription)
          funArr.push(fund)
        }
      })
      this.setState({ dataObjArr, funArr, funNameArr, loading: false });
    }
    reader.readAsBinaryString(files[0]);
  }

  handleUpdate = async () => {
    const { companyObj } = this.props
    const { dataObjArr, funArr, funNameArr } = this.state
    const companyId = companyObj.objectId

    if (window.confirm('確認上傳？')) {
      this.setState({ loading2: true })
      const portfolios = await Parse.queryData('portfolios', { companyId })
      const fundConfigure = await Parse.queryData('fundConfigure', { companyId, PriceDate: dataObjArr[0].PriceDate })

      const updateObjArr = []; // 要新增到 fundConfigure 的資料
      dataObjArr.forEach(item => {
        if (fundConfigure.find(item2 =>
          (item.PriceDate === item2.PriceDate &&
            // item.ExtractTime === item2.ExtractTime &&   ///2021/05/11會有資料重複問題，目前移除ExtractTime(製表時間)判斷
            item.UnitsHeld === item2.UnitsHeld &&
            item.UnitType === item2.UnitType &&
            item.FundCurrencyValue === item2.FundCurrencyValue)
          && (item.LifePlanReference === item2.accountNum || item.PolicyId === item2.accountNum2))) {
          console.log('重複的資料')
        } else if (updateObjArr.indexOf(item) === -1) {
          updateObjArr.push(item)
        }
      })

      const promises = [];
      updateObjArr.forEach(() => {
        const db = Parse.Object.extend("fundConfigure");
        const newDoc = new db();
        promises.push(newDoc.save());
      })
      const docArr = await Promise.all(promises).catch(error => console.log("error", error));

      const promises2 = [];
      docArr.forEach((doc, i) => {
        const portfolioRef = portfolios.find(item => updateObjArr[i].LifePlanReference === item.account_num || updateObjArr[i].PolicyId === item.account_num2)
        // console.log(portfolioRef)
        delete updateObjArr[i].PolicyId
        const obj = {
          ...updateObjArr[i],
          id: doc.id,
          _id: doc.id,
          objectId: doc.id,
          accountNum: portfolioRef.account_num || '',
          accountNum2: portfolioRef.account_num2 || '',
          companyId: companyObj.objectId || '',
          companyName: companyObj.name || '',
          order: i,
          clientId: portfolioRef.client_id || '',
          portfoliosId: portfolioRef.objectId || '',
        }
        promises2.push(Parse.saveData('fundConfigure', obj));
      })
      await Promise.all(promises2).catch(error => console.log("error", error));

      // 上面已經把所有該存到 fundConfigure 的東西，都存進去了
      // return;
      const fund1 = await Parse.queryData('fund1', { companyId });
      const fund2 = await Parse.queryData('fund2', { companyId, PriceDate: dataObjArr[0].PriceDate });

      // 把新的 FundDescription 寫到 fund1 裡面
      if (funNameArr.length) {
        const promises = [];
        funNameArr.forEach(item => {
          if (!fund1.find(item2 => item2.FundDescription === item)) {
            const db = Parse.Object.extend("fund1");
            const newDoc = new db();
            promises.push(newDoc.save().then(async (doc) => {
              const obj = {
                id: doc.id,
                _id: doc.id,
                objectId: doc.id,
                companyId: companyObj.objectId || '',
                companyName: companyObj.name || '',
                FundDescription: item
              }
              await Parse.saveData('fund1', obj);
            }));
          }
        });
        await Promise.all(promises).catch(error => console.log("error", error));
      }

      // 把新的 fundConfigure 寫到 fund2 裡面
      if (funArr.length) {
        const promises = [];
        funArr.forEach(item => {
          if (!fund2.find(item2 => item2.FundDescription === item.FundDescription && item2.PriceDate === item.PriceDate)) {
            const db = Parse.Object.extend("fund2");
            const newDoc = new db();
            promises.push(newDoc.save().then(async (doc) => {
              const obj = {
                id: doc.id,
                _id: doc.id,
                objectId: doc.id,
                companyId: companyObj.objectId || '',
                companyName: companyObj.name || '',
                ...item
              }
              await Parse.saveData('fund2', obj);
            }));
          }
        });
        await Promise.all(promises).catch(error => console.log("error", error));
        this.setState({
          dataObjArr: [],
          funArr: [],
          funNameArr: [],
          loading: false,
          loading2: false
        }, () => { window.alert('上傳成功') })
      }
    } else {
      this.setState({ loading: false })
    }
  }

  render() {
    const { dataObjArr, loading, loading2, auth = {} } = this.state;

    return (<div className="content">
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '2-5 基金淨值更新'),
          pageSize: 10,
          search: false
        }}
        data={dataObjArr}
        title={`2-5 基金淨值更新`}
        components={{
          Toolbar: props => (<div>
            <div style={{ width: '200px', float: 'right', textAlign: 'right', padding: '15px' }}>
              <Button.Group>
                <ReactFileReader handleFiles={this.handleFiles} fileTypes={'.csv'} disabled={!auth.edit}>
                  <Button color='orange' size='small' content='讀檔' disabled={!auth.edit}></Button>
                </ReactFileReader>
                <Button loading={loading2} color='blue' size='small' content='上傳' style={{ marginLeft: '5px' }}
                  disabled={dataObjArr.length === 0}
                  onClick={this.handleUpdate}
                />
              </Button.Group>
            </div>
            <MTableToolbar {...props} />
            <div style={{ clear: 'both' }}></div>
          </div>),
        }}
      />
      <br />
      <br />
    </div>)
  }
}