
import React, { Component } from 'react'
import { Container, Table, Form, Button, Tab } from 'semantic-ui-react';
import Cashier1 from './Cashier1';
import Cashier2 from './Cashier2';

// import RowsDnd from './RowsDnd'

// reactstrap components

// const style = {
//   flex: {
//     display: 'flex'
//   },
//   flexCenter: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center'
//   }
// }

class Cashier extends Component {
  state = {
    loading: true,
    loadingBtn: false,
  }

  render() {
    const { companyObj } = this.props
    const props = this.props;

    const panes = [
      { menuItem: '法會', render: () => <Tab.Pane>{<Cashier1 {...props} />}</Tab.Pane> },
      { menuItem: '點燈', render: () => <Tab.Pane>{<Cashier2 {...props} />}</Tab.Pane> },
    ]

    return (
      <>
        <div className="content">
          <Form style={{ position: 'relative', zIndex: 99, }}>
            <Tab panes={panes} {...props} />
            {/* <Tab menu={{ inverted: companyObj.inverted }} panes={panes} {...props} /> */}
          </Form>
        </div>
      </>
    );
  }
}

export default Cashier;
