import React, { Component } from 'react'
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
import { Container, Header, Form, Icon, Grid } from 'semantic-ui-react'
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import PageHeader from 'components/Headers/PageHeader';
import { ListGroup, ListGroupItem } from 'reactstrap';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
export class Intro1 extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
    };
  }



  handleChange = (e, { value }) => this.setState({ value })

  //   handleChange = (e) => {
  //     // console.log(e);
  //     this.setState({
  //       [e.target.id]: e.target.value
  //     })
  //   }

  //   handleSubmit = (e) => {
  //     // console.log(e);
  //     e.preventDefault();
  //     this.props.createProject(this.state);
  //     this.props.history.push('/');
  //   }
  render() {
    //     const { auth } = this.props;
    //     if (!auth.uid) return <Redirect to='/signin' />
    // const { value } = this.state

    const bannerImg = {
      height: 390,
      backgroundImage: `url(${'/images/aichi/g2/DSC_8099.jpg'})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: '50% 40%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
    const bannerText = {
      fontSize: '2.8rem',
      fontWeight: '600',
      letterSpacing: '2px',
      color: 'rgba(255, 255, 255, .9)',
      width: '1140px',
      textAlign: 'right'
    }
    const content = {
      maxWidth: '1170px',
      margin: '80px auto 110px',
      flexDirection: 'column'
    }
    const list = {
      listStyleType: 'circle'
    }
    const item = {

    }
    const itemTitle = {
      fontWeight: 400,
      margin: '0 0 17px 0',
      lineHeight: '1.8'
    }
    const itemText = {
      margin: '0 0 30px 0',
      lineHeight: '1.8'
    }
    const itemTextL = {
      margin: '0 0 47px 0',
      lineHeight: '1.8'
    }
    const font = {
      red: {
        color: 'crimson'
      },
      bold: {
        fontWeight: 700
      }
    }

    return (<>
      <PageHeader url='/hp1/24.jpg' text={'購買快速模擬測驗測驗'} />
      <Container style={content}>
        <AudioPlayer
          autoPlay
          src='/voice.wav'
          onPlay={e => console.log("onPlay")}
        // other props here
        />
        <h3>10題聽力 </h3>
        <p>Be sure to <strong>not use the standard <code>.btn</code> classes here</strong>.</p>
        <ListGroup>
          <ListGroupItem active action>Cras justo odio</ListGroupItem>
          <ListGroupItem tag="button" action>Dapibus ac facilisis in</ListGroupItem>
          <ListGroupItem tag="button" action>Morbi leo risus</ListGroupItem>
          <ListGroupItem tag="button" action>Porta ac consectetur ac</ListGroupItem>
          <ListGroupItem tag="button" action>Vestibulum at eros</ListGroupItem>
        </ListGroup>
        <h3>10題閱讀 </h3>
        <p>Be sure to <strong>not use the standard <code>.btn</code> classes here</strong>.</p>
        <ListGroup>
          <ListGroupItem active action>Cras justo odio</ListGroupItem>
          <ListGroupItem tag="button" action>Dapibus ac facilisis in</ListGroupItem>
          <ListGroupItem tag="button" action>Morbi leo risus</ListGroupItem>
          <ListGroupItem tag="button" action>Porta ac consectetur ac</ListGroupItem>
          <ListGroupItem tag="button" action>Vestibulum at eros</ListGroupItem>
        </ListGroup>
      </Container>
    </>)
  }
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     createProject: (project) => dispatch(createProject(project))
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HostIndex)
export default Intro1