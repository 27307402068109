import React, { Component } from 'react'
import { Grid, Header, Form, Segment, Menu, Container } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
// import { Row, Col } from 'reactstrap';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import WebSiteShow from './WebSiteShow';
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import PageHeader from 'components/Headers/PageHeader';
import { rowReOrder } from 'views/web/CustomMaterialTable';


const pageType = { '1': '頁面', '2': '目錄', '3': '一頁式', '4': '頁腳', '5': '非選單頁面' }
const layoutType = { '1': '大', '2': '適中' }

const columns1 = () => ([
  { width: 60, title: '開啟', field: 'isOpen', type: 'boolean', initialEditValue: true },
  { width: 60, title: '類別', field: 'pageType', lookup: pageType, initialEditValue: '1' },
  { width: 60, title: '順序', field: 'order', type: 'numeric' },
  { width: 60, title: '路徑', field: 'path1' },
  { width: 160, title: '名稱', field: 'name' },
]);

const columns2 = () => ([
  { width: 50, title: '開啟', field: 'isOpen', type: 'boolean', initialEditValue: true },
  { width: 50, title: '順序', field: 'order', type: 'numeric' },
  { width: 60, title: '路徑', field: 'path2' },
  { width: 60, title: '字體大小', field: 'layout', lookup: layoutType },
  { width: 160, title: '名稱', field: 'name' },
]);

export default class webDesign extends Component {
  state = {
    typeId: '',
    data: [],
    data2: [],
    newData: {},
    loading1: true,
    loading2: false,
    rowData: {},
    pageId: '',
    pageObj: {}
  }

  async componentDidMount() {
    const { companyObj } = this.props;
    const data = await Parse.queryData('webnav', { companyId: companyObj.objectId })
    this.setState({ data, loading1: false })
  }

  handleAdd = async (key) => {
    const { companyObj } = this.props;
    const { newData } = this.state;
    newData.companyId = companyObj.objectId;
    newData.companyName = companyObj.name;

    await Parse.saveData('webnav', newData);
  }

  handleUpdate = async (key) => {
    const { newData } = this.state;
    await Parse.saveData('webnav', newData);
  }

  handleDelete = async (key) => {
    const { oldData } = this.state
    await Parse.deleteData('webnav', oldData);
    this.setState({ oldData: {} })
  }

  handleData = async (rowData) => {
    this.setState({ rowData, loading2: false });
    if (rowData.pageType === '1' || rowData.pageType === '4') {
      this.handleData2(rowData);
    }
  }

  handleData2 = async (rowData) => {
    // this.setState({ rowData, loading2: false });
    this.setState({ pageId: rowData.objectId });
  }

  handleState = (dataArr, arrName = 'dataArr') => {
    this.setState({ [arrName]: dataArr })
  }

  render() {
    const { data = [], loading1, loading2, rowData, pageId } = this.state;

    const level1 = data.filter(item => item.level === '1').sort((a, b) => a.order - b.order);
    const data1 = data.filter(item => item.level === '1' && (item.pageType === '1' || item.pageType === '2' || item.pageType === '3')).sort((a, b) => a.order - b.order);;
    const data2 = data.filter(item => item.level === '2' && item.nav1Id === rowData.objectId).sort((a, b) => a.order - b.order);;
    // const data4 = data.filter(item => item.pageType === '4');

    return (
      <>
        <div className="content">
          <ColorNavbar {...this.props} data={data} data1={data1} />
          <PageHeader text='網站設計' url='/images/blog/full/3.jpg' />
          <Container style={{ minHeight: '400px' }}>
            <br />
            <br />
            <Grid>
              <Grid.Row >
                <Grid.Column width={8}>
                  <MaterialTable
                    isLoading={loading1}
                    localization={localization()}
                    columns={columns1()}
                    options={{
                      // addRowPosition: "first",
                      exportButton: true,
                      pageSize: 10,//data.length || 5,
                      search: false,
                      tableLayout: 'fixed',
                    }}
                    data={level1}
                    title="大項 (首頁使用第一項)"
                    actions={[
                      {
                        icon: 'view_headline',
                        tooltip: '查看',
                        onClick: (event, rowData) => this.handleData(rowData)
                      }
                    ]}
                    components={{
                      Row: props => rowReOrder(props, 'webnav', 'order', data1, 'data1', this.handleState)
                    }}
                    onRowClick={(event, rowData) => this.handleData(rowData)}
                    editable={{
                      onRowAdd: newData =>
                        new Promise(async (resolve, reject) => {
                          const data1 = data;
                          const db = Parse.Object.extend("webnav");
                          const newDoc = new db();
                          const doc = await newDoc.save();
                          newData.objectId = doc.id;
                          newData.id = doc.id;
                          newData._id = doc.id;
                          newData.level = '1';

                          data1.push(newData);
                          this.setState({ data: data1, newData }, () => resolve());
                        }).then(() => this.handleAdd("1")),
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                          const data1 = data;
                          const index = data.indexOf(oldData);
                          data1[index] = newData;
                          // console.log(data)
                          this.setState({ data: data1, newData, oldData }, () => resolve());
                        }).then(() => this.handleUpdate("1")),
                      onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                          let data1 = data;
                          const index = data1.indexOf(oldData);
                          data1.splice(index, 1);
                          this.setState({ data: data1, oldData }, () => resolve());
                        }).then(() => this.handleDelete("1")),
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  {/* {!rowData.objectId || (rowData.pageType !== '2' && rowData.pageType !== '3' && rowData.pageType !== '5') ? null :  */}
                  {!rowData.objectId || rowData.pageType === '1' || rowData.pageType === '4' ? null :
                    <MaterialTable
                      localization={localization()}
                      isLoading={loading2}
                      columns={columns2()}
                      options={{
                        // addRowPosition: "first",
                        exportButton: true,
                        pageSize: 10,//data2.length || 5,
                        search: false,
                        tableLayout: 'fixed',
                      }}
                      data={data2}
                      title={rowData.name + " 選項"}
                      actions={[
                        {
                          icon: 'view_headline',
                          tooltip: '查看',
                          onClick: (event, rowData) => this.handleData2(rowData)
                        }
                      ]}
                      components={{
                        Row: props => rowReOrder(props, 'webnav', 'order', data2, 'data2', this.handleState)
                      }}
                      onRowClick={(event, rowData) => this.handleData2(rowData)}
                      editable={{
                        onRowAdd: newData =>
                          new Promise(async (resolve, reject) => {
                            const data2 = data;
                            const db = Parse.Object.extend("webnav");
                            const newDoc = new db();
                            const doc = await newDoc.save();
                            newData.objectId = doc.id;
                            newData.id = doc.id;
                            newData._id = doc.id;
                            newData.level = '2';
                            newData.nav1Id = rowData.objectId;

                            data2.push(newData);
                            this.setState({ data: data2, data2, newData }, () => resolve());
                          }).then(() => this.handleAdd("2")),
                        onRowUpdate: (newData, oldData) =>
                          new Promise((resolve, reject) => {
                            const data2 = data;
                            const index = data2.indexOf(oldData);
                            data2[index] = newData;

                            this.setState({ data: data2, newData, oldData }, () => resolve());
                          }).then(() => this.handleUpdate("2")),
                        onRowDelete: oldData =>
                          new Promise((resolve, reject) => {
                            let data2 = data;
                            const index = data.indexOf(oldData);
                            data2.splice(index, 1);
                            this.setState({ data: data2, oldData }, () => resolve());
                          }).then(() => this.handleDelete("2")),
                      }}
                    />}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <br />
            <br />
            <WebSiteShow {...this.props} data1={data1} data={data} pageId={pageId} />
          </Container>
          <br />
          <br />
          <FooterBlack />
        </div>
      </>
    )
  }
}