import React, { Component } from 'react'
import { Container, Icon, Table, Grid, Button, Modal, Header, Input, Label, Select } from 'semantic-ui-react'
import _ from 'lodash'
import { Link } from 'react-router-dom';
import Moment from 'moment';
import Parse from 'widget/parse'

import { getLabelName2, getOptionsByKey } from 'views/widgets/FieldsRender';
import ModalPdf from 'widget/pdf/ModalPdf';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import { getDiffDays } from '../../../widget/define/calendar';
const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipse: {
    // boxSizong: 'border-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}
const getLabel = (formSet, key) => {
  // return key;
  return formSet.find(item2 => item2.key === key) ? formSet.find(item2 => item2.key === key).label : key;
}
const retRTobj = (formSet, arrKey, width) => {
  return arrKey.map(item => {
    var form = formSet.find(obj => obj.key === item);
    if (!form) {
      return { title: item }
    } else {
      var id = item
      if (form.type === 'select') {
        id = `${item}Text`;
      }
      return { id, title: getLabel(formSet, item), width, type: form.type }
    }
  });
}

export class GraduateRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      author: '',
      graduates: [],
      yearsemester: `${this.props.semesterYear},${this.props.semester}`,
      graduateYear: `${Number(this.props.semesterYear) + 1}`,
      isPass: '-1'
    }
  }
  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = (props = this.props) => {
    const { companyObj, match } = this.props
    // firebase.firestore()
    //   .collectionGroup('Graduate')
    //   // .orderBy('relationship')
    //   // .where('eventDate', '==', eventDate).where('classname', '==', className)
    //   .get().then(snap => {
    //     let data = [];
    //     snap.forEach(item => {
    //       // let grade = {}
    //       let EJU = []
    //       let JLPT = []
    //       let ENG = []
    //       let findIndex = data.findIndex(item2 => item2.studentId === item.data().studentId)
    //       if (findIndex === -1) {
    //         firebase.firestore()
    //           .collection(`Students/${item.data().studentId}/EJU`)
    //           .get().then(snap => {
    //             snap.forEach(item => EJU.push({ _id: item.id, ...item.data() }));
    //             EJU.sort((a, b) => Moment(b.testDate) - Moment(a.testDate))
    //           });
    //         firebase.firestore()
    //           .collection(`Students/${item.data().studentId}/JLPT`)
    //           .where('gradeText', '==', 'N1')
    //           .get().then(snap => {
    //             snap.forEach(item => JLPT.push({ _id: item.id, ...item.data() }));
    //             JLPT.sort((a, b) => Moment(b.testDate) - Moment(a.testDate))
    //           });
    //         firebase.firestore()
    //           .collection(`Students/${item.data().studentId}/ENG`)
    //           .get().then(snap => {
    //             snap.forEach(item => ENG.push({ _id: item.id, ...item.data() }));
    //             ENG.sort((a, b) => Moment(b.testDate) - Moment(a.testDate))
    //           });
    //         data.push({
    //           _id: item.id, ...item.data(), grade: { EJU, JLPT, ENG }, EJU, JLPT, ENG
    //           // _id: item.id, ...item.data(), grade: { EJU: EJU[0], JLPT0: JLPT[1], JLPT1: JLPT[0], ENG }
    //           // _id: item.id, ...item.data(), ...EJU[0]
    //         })
    //       } else {
    //         data.push({
    //           _id: item.id, ...item.data(), ...data[findIndex].grade
    //         })
    //       }
    //     });
    //     console.log(data)
    //     this.setState({ graduates: data });
    //     firebase.firestore()
    //       .collectionGroup(`ENG`)
    //       .get().then(snap => {
    //         let data = []
    //         snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //         data.sort((a, b) => Moment(b.testDate) - Moment(a.testDate))
    //         // console.log(data)
    //         this.setState({ ENG: data })
    //       });
    //   }, err => {
    //     console.log(`Encountered error: ${err}`);
    //   });
    Parse.queryData('Graduate', { companyId: companyObj.objectId }).then(snap => {
      let data = [];
      snap.forEach(item => {
        // let grade = {}
        let EJU = []
        let JLPT = []
        let ENG = []
        let findIndex = data.findIndex(item2 => item2.studentId === item.studentId)
        if (findIndex === -1) {
          EJU = Parse.queryData('EJU', { companyId: companyObj.objectId, studentId: item.studentId }, { orderBy: 'testDate_descend' })
          // firebase.firestore()
          //   .collection(`Students/${item.studentId}/EJU`)
          //   .get().then(snap => {
          //     snap.forEach(item => EJU.push({ _id: item.id, ...item }));
          //     EJU.sort((a, b) => Moment(b.testDate) - Moment(a.testDate))
          //   });
          // Parse.queryData('EJU', { companyId: companyObj.objectId, studentId: item.studentId,gradeText:'N1' })
          JLPT = Parse.queryData('JLPT', { companyId: companyObj.objectId, studentId: item.studentId, gradeText: 'N1' }, { orderBy: 'testDate_descend' })
          // firebase.firestore()
          //   .collection(`Students/${item.studentId}/JLPT`)
          //   .where('gradeText', '==', 'N1')
          //   .get().then(snap => {
          //     snap.forEach(item => JLPT.push({ _id: item.id, ...item }));
          //     JLPT.sort((a, b) => Moment(b.testDate) - Moment(a.testDate))
          //   });
          ENG = Parse.queryData('ENG', { companyId: companyObj.objectId, studentId: item.studentId }, { orderBy: 'testDate_descend' })

          // firebase.firestore()
          //   .collection(`Students/${item.studentId}/ENG`)
          //   .get().then(snap => {
          //     snap.forEach(item => ENG.push({ _id: item.id, ...item }));
          //     ENG.sort((a, b) => Moment(b.testDate) - Moment(a.testDate))
          //   });
          data.push({
            objectId: item.objectId, ...item, grade: { EJU, JLPT, ENG }, EJU, JLPT, ENG
            // _id: item.id, ...item, grade: { EJU: EJU[0], JLPT0: JLPT[1], JLPT1: JLPT[0], ENG }
            // _id: item.id, ...item, ...EJU[0]
          })
        } else {
          data.push({
            objectId: item.objectId, ...item, ...data[findIndex].grade
          })
        }
      });
      console.log(data)
      this.setState({ graduates: data });
      // firebase.firestore()
      //   .collectionGroup(`ENG`)
      //   .get().then(snap => {
      //     let data = []
      //     snap.forEach(item => data.push({ _id: item.id, ...item }));
      //     data.sort((a, b) => Moment(b.testDate) - Moment(a.testDate))
      //     // console.log(data)
      //     this.setState({ ENG: data })
      //   });
      data = Parse.queryData('ENG', { companyId: companyObj.objectId }, { orderBy: 'testDate_descend' })
      this.setState({ ENG: data })
    }, err => {
      console.log(`Encountered error: ${err}`);
    });
  }

  handleChange = (event, data) => {
    let fieldName = data.name;
    let fleldVal = data.value;
    this.setState({ [fieldName]: fleldVal });
  }
  render() {
    const { firebase, match, history, formSet, optSet, students, semester, semesterYear } = this.props;
    const { graduates, yearsemester, isPass, graduateYear } = this.state;
    const semesterYearArr = getOptionsByKey(optSet, 'semesterYear').map(item => ({ key: item._id, text: item.label, value: item.value }));
    const semesterArr = [
      { key: '2019,A', text: '2019A', value: '2019,A' },
      { key: '2019,S', text: '2019S', value: '2019,S' },
      { key: '2018,A', text: '2018A', value: '2018,A' },
      { key: '2018,S', text: '2018S', value: '2018,S' },
      { key: '2017,A', text: '2017A', value: '2017,A' },
      { key: '2017,S', text: '2017S', value: '2017,S' },
    ]
    let year = yearsemester.substring(0, 4);
    let semes = yearsemester.substring(5);
    let loadAllCurrentSemesterYear = "1"; // if==1, 2018S => 2017S 2017A 2018S, if==0 2018S => 2018S
    // console.log(graduates)
    let graduation = [];
    if (isPass === '1' || isPass === '2') {
      graduation = graduates.filter(item => item.isAdmitted === isPass && item.semester === semes && item.semesterYear === year)
    } else {
      graduation = graduates.filter(item => item.semesterYear === (Number(graduateYear) - 2) + '')
    }
    graduation.forEach(item => (
      // item.EJU,
      item.ejuTimes = item.EJU.length === 0 ? '' : item.EJU.length > 1 ? '2' : '1',
      item.TOEFL = item.ENG.length && item.ENG.filter(i => !!i.TOEFL)[0],
      item.TOEIC = item.ENG.length && item.ENG.filter(i => !!i.TOEIC)[0],
      // console.log(item.TOEFL),
      // item.EJU.map(item2 => console.log(item2)),
      item.totalDescription = item.EJU[0] ? item.EJU[0].totalDescription : ''))
    return (
      <>

        <div style={{ ...style.flexCenter }}>
          <Header as='h2' style={{ margin: 0 }}>大学進学調查</Header>
          <div style={{ ...style.flexCenter }}>
            {/* <Input labelPosition='left' >
                <Label color={'blue'}> <Icon name='file alternate outline' />卒業生入学期</Label>
                <Select style={{
                  minWidth: '120px',
                  borderTopLeftRadius: '0',
                  borderBottomLeftRadius: '0',
                  marginRight: 10
                }} compact
                  options={semesterArr} onChange={this.handleChange} value={yearsemester} name='yearsemester' />
              </Input> */}
            <Input labelPosition='left' >
              <Label color={'blue'}> <Icon name='file alternate outline' />卒業年</Label>
              <Select style={{
                minWidth: '120px',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
                marginRight: 10
              }} compact
                options={semesterYearArr} onChange={this.handleChange} value={graduateYear} name='graduateYear' />
            </Input>

            <div style={{ ...style.flexCenter, minWidth: 220, marginLeft: 10 }}>
              <ExportTableToExcelButton size='small' />
              <ModalPdf
                {...this.props}
                // ref={(ref) => { this.pdfModal = ref }}
                codekey="tableOfGraduation"
                params={graduation.sort((a, b) => ('' + a.studentIdKey).localeCompare(b.studentIdKey))}
              />
            </div>
          </div>
        </div>
        <div style={{ maxWidth: '100vw', overflowX: 'scroll', marginTop: 25 }}>
          <Table id='excel' celled striped size='small' selectable>
            <Table.Header>
              <Table.Row textAlign='center'>
                <Table.HeaderCell style={style.textEllipse} rowSpan='3'>学生No.</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse} rowSpan='3'>受験校</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse} rowSpan='3'>学部／研究科</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse} rowSpan='3'>学科／專攻</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse} rowSpan='3'>大学院</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse} rowSpan='3'>合否</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse} colSpan='9'>日本留学試験</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse} rowSpan='2' colSpan='2'>日本語能力試験<br />N1</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse} colSpan='2'>英語試験</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse} rowSpan='3'>その他</Table.HeaderCell>
              </Table.Row>
              <Table.Row textAlign='center'>
                <Table.HeaderCell style={style.textEllipse} rowSpan='2'>回</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse} rowSpan='2'>日本語</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse} rowSpan='2'>記述</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse} colSpan='3'>理科</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse} rowSpan='2'>総合科目</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse} colSpan='2'>数学</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse} rowSpan='2'>TOEFL<br />※iBTスコア<br />(0-120点)</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse} rowSpan='2'>TOEIC</Table.HeaderCell>
              </Table.Row>
              <Table.Row textAlign='center'>
                <Table.HeaderCell style={style.textEllipse}>物理</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>化学</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>生物</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>コ1</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>コ2</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>1回</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>2回</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {graduation.sort((a, b) => ('' + a.studentIdKey).localeCompare(b.studentIdKey)).map((item, index) => (<Table.Row key={item._id}>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.studentIdKey}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.targetSchoolText}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.targetSubject}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.isAdmitted === '1' ? '○' : '✕'}</Link></Table.Cell>
                {/* EJU */}
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.ejuTimes}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.EJU && item.EJU[0] && item.EJU[0].jpTotalScore}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.totalDescription}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.EJU && item.EJU[0] && item.EJU[0].physicsScore}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.EJU && item.EJU[0] && item.EJU[0].chemistryScore}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.EJU && item.EJU[0] && item.EJU[0].biologyScore}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.EJU && item.EJU[0] && item.EJU[0].generalScore}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.EJU && item.EJU[0] && item.EJU[0].math1Score}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.EJU && item.EJU[0] && item.EJU[0].math2Score}</Link></Table.Cell>
                {/* JLPT */}
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.JLPT && item.JLPT[1] && item.JLPT[1].totalScore || ''}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.JLPT && item.JLPT[0] && item.JLPT[0].totalScore || ''}</Link></Table.Cell>
                {/* ENGLISH TEST */}
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.TOEFL && item.TOEFL.TOEFL}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.TOEIC && item.TOEIC.TOEIC}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{}</Link></Table.Cell>
                {/* </Link> */}
              </Table.Row>))}
            </Table.Body>
          </Table>
        </div>

      </>
    )
  }
}
export default GraduateRecord