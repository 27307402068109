import React, { Component } from 'react'
import {
  Button,
  Form, Grid
} from 'semantic-ui-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Parse, { funcPad } from '../../widget/parse'

export default class ModalNewClient extends Component {
  state = {
    open: false,
    form: {
      uid: '',
      name: '',
      telephone: '',
      cellnum: '',
    }
  }

  open = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId;
    const companyObjLive = await Parse.fetchDocument('company', companyId, ['yearDef', 'orderalphaDef', 'ordernumDef', 'pray1IdDef']);
    const { orderalphaDef2, ordernumDef2 } = companyObjLive;
    console.log(ordernumDef2)
    console.log(orderalphaDef2)
    const uid = orderalphaDef2 + funcPad(Number(ordernumDef2) + 1, 8);
    this.setState({ form: { ...this.state.form, uid }, open: true });
  }

  close = () => this.setState({ open: false })
  toggle = () => this.setState({ open: !this.state.open })

  handleChange = (evt, { name, value }) => {
    this.setState({
      form: { ...this.state.form, [name]: value }
    })
  }

  handleSubmit = async () => {
    const { history, companyObj } = this.props;
    const { form } = this.state;

    const db = Parse.Object.extend("clients");
    const newDoc = new db();
    const doc = await newDoc.save();

    const obj = {
      ...form,
      objectId: doc.id,
      id: doc.id,
      _id: doc.id,
      companyId: companyObj.objectId,
      companyName: companyObj.name,

    };
    await Parse.saveData('clients', obj);

    // 這是給 company 的
    const ordernumDef2 = String(Number(companyObj.ordernumDef2) + 1);
    await Parse.saveData('company', { ...companyObj, ordernumDef2 });

    this.setState({
      form: {
        name: '',
        telephone: '',
        cellnum: '',
        postcode: '',
        addr: '',
      }
    })
    history.push(`/admin/SalesInventory/${doc.id}`);
    this.close();
  }

  render() {
    const { open, form } = this.state
    const { name, uid, cellnum } = form;

    return (<>
      <Button icon='group' content='新增客戶' color='blue' onClick={this.open} />
      <Modal
        isOpen={open}
        size='md'
        toggle={this.toggle}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggle}>
            <span aria-hidden="true">×</span>
          </button>
          新增客戶
        </ModalHeader>
        <ModalBody>
          <Form size='big'>
            <Grid>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <Form.Input
                    label='客戶編號'
                    // placeholder='客戶證號'
                    name='uid'
                    value={uid}
                    onChange={this.handleChange} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Input
                    label='姓名'
                    placeholder='姓名'
                    name='name'
                    value={name}
                    // defaultValue='-1'
                    onChange={this.handleChange} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Input
                    label='手機'
                    placeholder='手機'
                    name='cellnum'
                    value={cellnum}
                    // defaultValue='-1'
                    onChange={this.handleChange} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button icon='x' content='關閉' onClick={this.close} />
          <Button positive content='新增' icon='add' onClick={this.handleSubmit} />
        </ModalFooter>
      </Modal>
    </>)
  }
}