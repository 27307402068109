import React, { Component } from 'react'
import { Container } from 'semantic-ui-react'
import {
  Card,
  CardBody,
  Row,
  Col
} from "reactstrap";
import Carousel, { ModalGateway, Modal } from 'react-images';
import intl from 'react-intl-universal';
import Moment from 'moment';

import PageHeader from 'components/Headers/PageHeader';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

export class AlbumTest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0,
      lightboxIsOpen: false,
      photos: [],
      album: [],
      isOpen: false
    };
  }

  componentDidMount = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data = await Parse.queryData('album', { companyId, isopen: '1' }, { sort: 'date_descend' });
    this.setState({ album: data })
  }

  handlePhotos = async (rowData, albumId) => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const photos = await Parse.queryData('webimage', { companyId, albumId });
    this.setState({ photos, rowData, isOpen: true })
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  };

  render() {

    const { form, imagePreviewUrl } = this.props
    const { text1, img1File, img1Url, dbLang } = form
    const { album, photos, rowData, field = 'img1File2', isOpen } = this.state
    const picUrl = imagePreviewUrl || (img1File && img1File.url) || '/hp1/24.jpg';

    const src0 = (rowData && rowData[field.substr(0, field.length - 1)]);
    const source = src0 && src0.url;
    const caption = (rowData && rowData.name) || ''

    const items = photos && photos.length ? photos.map(item => {
      const src = (item && item[field.substr(0, field.length - 1)]);
      const caption = (item && item.name) || ''
      const source = src && src.url;
      return { source, caption };
    }) : [{ source, caption }]

    const currentIndex = photos.findIndex(item => item.objectId === rowData.objectId) !== -1 ?
      photos.findIndex(item => item.objectId === rowData.objectId) : 0;

    const options = { currentIndex }

    // console.log(album)
    return (<>
      <PageHeader url={picUrl} text={intl.get('活動寫真')} />
      <div className="blog-2 section section-gray">
        <br />
        <br />
        <br />
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <Row>
                {album.map(item =>
                  <Col md="4">
                    <Card className="card-blog" style={{ cursor: 'pointer' }}>
                      <div className="card-image">
                        <img
                          className="img img-raised"
                          alt={item.name}
                          src={item.img1File2.url}
                          onClick={(e) => this.handlePhotos(item, item.objectId)}
                        />
                      </div>
                      <CardBody>
                        <h5 style={{ fontWeight: 500 }} className="card-category text-info">{intl.get(item.name)}</h5>
                        <h6 style={{ fontWeight: 400 }} className="card-category text-info">{Moment(item.date ? item.date.iso : item.date).format('YYYY-MM-DD')}</h6>
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          <br />
          <br />
          <ModalGateway>
            {isOpen ? (
              <Modal onClose={this.handleClose}>
                <Carousel views={items} {...options} />
              </Modal>
            ) : null}
          </ModalGateway>
        </Container>
      </div>
    </>)
  }
}

export default AlbumTest