import React, { Component } from 'react'
import { Button, Grid, Form } from 'semantic-ui-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MaterialTable from 'material-table'
import { Select, MenuItem } from '@material-ui/core';
// import Moment from 'moment';
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

import ModalPraying2 from './ModalPraying2'
import ModalPdf from 'widget/pdf/ModalPdf';
import { DimmerImg } from '../../layouts/Dimmer';
import ModalReceivable from './ModalReceivable';
import Parse, { funcPad } from '../../widget/parse'
import localization from 'widget/MaterialTableOpt'

const columns = (webtypeTemple, pray1, pray2, year, ordernum, pray1Id, people1Sel, pray2numLen = 3, mgyearSel, mgyearDefault, pray2Obj) => {
  let colname = [];
  if (webtypeTemple) {
    colname = [
      { width: 120, title: '單號', field: 'ordernum', initialEditValue: ordernum, defaultSort: 'desc' },
      { width: 60, title: '年度', field: 'year', initialEditValue: year || mgyearDefault, lookup: mgyearSel },
      { width: 160, title: '法會', field: 'pray1Id', lookup: pray1, initialEditValue: pray1Id },
      {
        width: 100, title: '項目', field: 'pray2Id',
        render: rowData => rowData.pray2Text,
        editComponent: props => {
          console.log(pray2)
          const thisPray2 = pray2.filter(item => item.pray1Id === props.rowData.pray1Id).sort((a, b) => a.order - b.order)
          const p2use = thisPray2.find(item => item.now_use === '-1') // 找預設的
          const thisPray2Id = (p2use && p2use.objectId) || (thisPray2.length && thisPray2[0].objectId); // 沒有預設 就用第一個
          if (!thisPray2.find(item => item.objectId === props.rowData.pray2Id)) { // 有換 pray1 就重設pray2id
            const pray2Obj = thisPray2.find(item => item.objectId === thisPray2Id);
            const pray2Id = pray2Obj.objectId;
            const pray2Text = pray2Obj.value;
            const pray2OrderId = pray2Obj.order;
            const accReceivable = pray2Obj.price;
            const serial = funcPad((Number(pray2Obj.now_num || 0) + 1) + '', Number(pray2numLen));
            props.onRowDataChange({ ...props.rowData, pray2Id, pray2Text, serial, pray2OrderId, accReceivable })
          }
          return <Select
            value={props.rowData.pray2Id || thisPray2Id}
            onChange={e => {
              const pray2Id = e.target.value;
              const pray2Obj = pray2.find(item => item.objectId === pray2Id);
              const serial = funcPad((Number(pray2Obj.now_num || 0) + 1) + '', Number(pray2numLen));
              const pray2Text = pray2Obj.value;
              const pray2OrderId = pray2Obj.order;
              const accReceivable = pray2Obj.price;
              props.onRowDataChange({ ...props.rowData, pray2Id, pray2Text, serial, pray2OrderId, accReceivable })
            }}>
            {thisPray2.map((item, index) => <MenuItem key={'a' + item.objectId + index} value={item.objectId}>{item.value}</MenuItem>)}
          </Select>
        }
      },
      { width: 60, title: '編號', field: 'serial' },
      // { title: '建立日期', field: 'createdAt', editable: 'never' },
      { width: 60, title: '應收', field: 'accReceivable', type: 'numeric' },
      { width: 60, title: '已收', field: 'accReceived', type: 'numeric' },
      { width: 60, title: '待收', field: 'accPending', type: 'numeric', editable: 'never' },
      { width: 80, title: '申辦人', field: 'whoapply', lookup: people1Sel },
      { width: 60, title: '帳冊', field: 'booksNum' },
      { width: 90, title: '備註', field: 'ps' },
      { width: 90, title: '建立時間', editable: 'never', field: 'createdAt', type: 'datetime' },
      { width: 90, title: '修改時間', editable: 'never', field: 'updatedAt', type: 'datetime' },
    ]
  } else {
    colname = [
      { width: 80, title: '單號', field: 'ordernum', initialEditValue: ordernum, defaultSort: 'desc' },
      { title: '年度', field: 'year', initialEditValue: year },
      { title: '法會名稱', field: 'pray1Id', lookup: pray1, initialEditValue: pray1Id },
      {
        title: '法會項目', field: 'fieldTypeId', initialEditValue: '1',
        render: rowData => rowData.fieldTypeIdText || prayTypeOpt[rowData.fieldTypeId],
        editComponent: props => {
          const thisPray2 = pray2.sort((a, b) => a.order - b.order)
          const pray2Value = (thisPray2.length && thisPray2.find(item => String(item.order) === props.rowData.fieldTypeId)) || {}
          const p2use = thisPray2.find(item => item.now_use === '-1') // 找預設的
          const thisPray2Id = (p2use && p2use.objectId) || (thisPray2.length && thisPray2[0].objectId); // 沒有預設 就用第一個
          if (!thisPray2.find(item => item.objectId === props.rowData.pray2Id)) { // 有換 pray1 就重設pray2id
            const pray2Obj = thisPray2.find(item => item.objectId === thisPray2Id);
            const pray2Id = pray2Obj.objectId;
            const pray2Text = pray2Obj.value;
            const pray2OrderId = pray2Obj.order;
            const accReceivable = pray2Obj.price;
            const serial = funcPad((Number(pray2Obj.now_num || 0) + 1) + '', Number(pray2numLen));
            props.onRowDataChange({ ...props.rowData, pray2Id, pray2Text, serial, pray2OrderId, accReceivable })
          }
          return <Select
            value={pray2Value.objectId || thisPray2Id}
            onChange={e => {
              const pray2Id = e.target.value;
              const pray2Obj = pray2.find(item => item.objectId === pray2Id);
              const serial = funcPad((Number((pray2Obj && pray2Obj.now_num) || 0) + 1) + '', Number(pray2numLen));
              const pray2Text = pray2Obj.value;
              const pray2OrderId = pray2Obj.order;
              const accReceivable = pray2Obj.price;
              props.onRowDataChange({ ...props.rowData, fieldTypeId: String(pray2OrderId), pray2Id, pray2Text, serial, pray2OrderId, accReceivable })
            }}>
            {thisPray2.map((item, index) => <MenuItem key={'a' + item.objectId + index} value={item.objectId}>{item.value}</MenuItem>)}
          </Select>
        }
      },
      { title: '法會序號', field: 'serial' },
      { title: '應收金額', field: 'cash' },
      { title: '申請日期', field: 'date' },
      { title: '申請人', field: 'whoapply_text' },
      { title: '地址', field: 'addr' },
      { title: '備註', field: 'ps' },
      { width: 90, title: '建立時間', editable: 'never', field: 'createdAt', type: 'datetime' },
      { width: 90, title: '修改時間', editable: 'never', field: 'updatedAt', type: 'datetime' },
    ]
  }
  return colname;
};
const prayTypeOpt = {
  "1": "超渡",
  "2": "斗燈",
  "3": "太歲",
  "4": "祈福",
  "5": "光明燈",
}
export default class ModalPraying1 extends Component {
  state = {
    show: false,
    data1: [],
    data2: [],
    ordering1Id: '',
    newData1: {},
    newData2: {},
    oldData1: {},
    oldData2: {},
    loading: true,
    companyObjLive: {},
    loading1: true,
    pray2Obj: [],
    prayTypeOpt: {}
  }

  componentDidMount = async () => {
    this.handleParams()
  }

  componentWillReceiveProps(nextProps) {
    this.handleParams(nextProps);
  }

  handleParams = async (props = this.props) => {
    const { match, category, companyObj } = props;
    const { t1 } = match.params;
    if (typeof t1 !== 'undefined' && t1 === category) {
      this.setState({ show: true, }, () => this.handleFetch(props));
    }
    const mgyear = await Parse.queryData('mgyear', { companyId: companyObj.objectId });
    let mgyearSel = {}
    let mgyearDefault = ''
    mgyear.forEach(item => {
      mgyearSel = { ...mgyearSel, [item.value]: item.value }
      if (item.now_use === '-1') {
        mgyearDefault = item.value
      }
    })

    const companyId = companyObj.objectId;
    const pray2Obj = await Parse.getSelection('pray2', { companyId }, { name: 'value', sort: 'order' });
    const companyObjLive = await Parse.fetchDocument('company', companyId, ['yearDef', 'orderalphaDef', 'ordernumDef', 'pray1IdDef']);
    this.setState({ pray2Obj, companyObjLive, mgyearDefault, mgyearSel });
  }

  handleFetch = async (props = this.props) => {
    const { match, companyObj, category } = props;
    const { t1Id, familyId } = match.params;
    if (!familyId) {
      return;
    }
    // console.log(t1Id)
    // const companyId = companyObj.objectId;
    const data1 = await Parse.queryData('praying1', { familyId });
    this.setState({ data1, ordering1Id: '', data2: [] }, async () => {
      if (typeof t1Id !== 'undefined') {
        const praying1Obj = data1.find(item => item.objectId === t1Id);
        const praying1Id = (praying1Obj && praying1Obj.objectId) || ''
        if (praying1Id) {
          this.setState({ ordering1Id: t1Id, praying1Obj, loading1: false, });
          const data2 = await Parse.queryData('praying2', { familyId, praying1Id });
          this.setState({ data2, loading: false });
        }
      } else {
        this.setState({ loading1: false, loading: false });
      }
    });
  }

  handleClosePdf = () => {
    const { history, familyId } = this.props;
    history.push(`/admin/Family/${familyId}`);
    this.setState({ show: false });
  }

  handleData = (rowData) => {
    const { history, familyId, category } = this.props;
    // console.log(familyId, category, rowData)
    this.setState({ ordering1Id: '' })
    if (rowData.objectId) {
      history.push(`/admin/Family/${familyId}/${category}/${rowData.objectId}`);
    }
    // console.log(rowData)
    this.setState({ praying1Type: rowData.prayType })
  }

  savePraying1 = async () => {
    const { companyObj, category } = this.props
    const { newData1, companyObjLive } = this.state
    const obj = { ...newData1, };
    // console.log(obj)
    // console.log(category)
    if (companyObjLive.webtypeId === "temple") {
      if (category === 'p1') {
        if (obj.ordernum) {
          Parse.saveData("company", { objectId: companyObj.objectId, ordernumDef: obj.ordernum.substr(1) })
        }
        if (obj.serial) {
          Parse.saveData("pray2", { objectId: obj.pray2Id, now_num: obj.serial })
        }
      }
    } else {
      if (obj.ordernum) {
        Parse.saveData("company", { objectId: companyObj.objectId, ordernumDef: obj.ordernum.substr(1) })
      }
      if (obj.serial) {
        Parse.saveData("pray2", { objectId: obj.pray2Id, now_num: obj.serial })
      }
    }
    console.log(newData1)
    await Parse.saveData('praying1', newData1);
  }

  handleAdd = async () => { // praying1 add
    const { history, familyId, category } = this.props
    const { newData1 } = this.state

    await this.savePraying1();
    this.setState({ loading1: true }, () => history.push(`/admin/Family/${familyId}/${category}/${newData1.objectId}`));
    // this.handleParams();
    // this.handleData(newData1);
  }

  handleUpdate = async () => {
    await this.savePraying1();

    const { newData1 } = this.state
    const { pray1Id, pray1Text, pray2Id, pray2Text, year, ordernum, serial, pray2OrderId } = newData1;
    const praying1Id = newData1.objectId;
    if (pray2OrderId) {
      await Parse.saveall('praying2', { praying1Id }, { pray1Id, pray1Text, pray2Id, pray2Text, year, ordernum, serial, pray2OrderId: Number(pray2OrderId) })
    } else {
      await Parse.saveall('praying2', { praying1Id }, { pray1Id, pray1Text, pray2Id, pray2Text, year, ordernum, serial })
    }
    this.setState({ oldData1: {}, newData1: {} })
  }

  handleDelete = async () => {
    const { history, familyId, category } = this.props
    const { oldData1 } = this.state
    // const companyId = companyObj.objectId;
    // console.log(oldData1)
    await Parse.destroyData('praying2', { praying1Id: oldData1.objectId });
    await Parse.destroyData('received', { praying1Id: oldData1.objectId });
    await Parse.deleteData('praying1', oldData1);

    this.setState({ oldData1: {} }, () => history.push(`/admin/Family/${familyId}/${category}`));
  }

  handleDuplicate = async (rowData) => {
    if (!window.confirm("確認要複製 " + rowData.ordernum + " 嗎?")) {
      return;
    }
    if (!rowData.pray2Id) {
      window.alert('此服務沒有法會項目，無法複製')
      return;
    }
    const { history, category } = this.props
    const { pray2Obj = {}, companyObjLive } = this.state
    const { yearDef, orderalphaDef, ordernumDef } = companyObjLive;

    const pray2 = pray2Obj.arr || []
    // console.log(pray2)
    // const p2Obj = pray2.find(item => item.objectId === rowData.pray2Id);
    // console.log(p2Obj)
    // console.log(rowData)
    // const serial = funcPad((Number(p2Obj.now_num || 0) + 1) + '', 4);
    const ordernum = orderalphaDef + funcPad(Number(ordernumDef) + 1, 8);
    const familyId = rowData.familyId;
    const praying1Id = rowData.objectId; // 要複製的praying1 id
    const companyId = companyObjLive.objectId;

    rowData.year = yearDef;
    rowData.ordernum = ordernum;
    // rowData.serial = serial;

    if (rowData.ordernum) {
      Parse.saveData("company", { objectId: companyId, ordernumDef: ordernum.substr(1) })
    }
    if (rowData.serial) {
      Parse.saveData("pray2", { objectId: rowData.pray2Id, now_num: rowData.serial })
    }

    const db = Parse.Object.extend("praying1");
    const newDoc = new db();
    const doc = await newDoc.save();
    rowData.objectId = doc.id;
    rowData.id = doc.id;
    rowData._id = doc.id;
    rowData.companyId = companyObjLive.objectId;
    rowData.companyName = companyObjLive.name;
    delete rowData.createdAt;
    delete rowData.updatedAt;

    await Parse.saveData('praying1', rowData);

    const data2 = await Parse.queryData("praying2", { praying1Id });
    // console.log(data2);
    const promises = [];
    data2.forEach(async (item) => {
      const db = Parse.Object.extend("praying2");
      const newDoc = new db();
      const doc = await newDoc.save();
      const obj = {
        ...item,
        ...rowData,
        praying1Id: rowData.objectId,
        objectId: doc.id,
        _id: doc.id,
        id: doc.id,
      }
      delete obj.accPending;
      delete obj.accReceivable;
      delete obj.accReceived;
      delete obj.createdAt;
      delete obj.updatedAt;
      // console.log(obj)
      promises.push(Parse.saveData('praying2', obj));
    })

    await Promise.all(promises).catch(error => console.error(error));
    this.setState({ loading1: true }, () => history.push(`/admin/Family/${familyId}/${category}/${rowData.objectId}`));
  }

  handleClickModalBtn = () => {
    const { history, familyId, category } = this.props;
    history.push(`/admin/Family/${familyId}/${category}`);
  }

  handleUpdateAcc = async (newData) => {
    const { companyObj } = this.props;
    const { data1 } = this.state

    await Parse.saveData('praying1', newData);

    const data = data1;
    const index = data.findIndex(item => item.objectId === newData.objectId);
    if (index !== -1) {
      data[index] = newData;
      this.setState({ data1: data });
    }
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value }, () => this.handleFetch())
  }

  render() {
    const { people1, people0, familyId, obj, category, selectData } = this.props
    const { show, data1, data2, ordering1Id, praying1Obj, loading, companyObjLive, loading1, pray2Obj = {}, fieldTypeId, praying1Type, mgyearSel = {}, mgyearDefault = '' } = this.state;
    const { pray1Obj = {} } = selectData;
    const pray1Sel = pray1Obj.sel || {}
    const pray2 = pray2Obj.arr || {}
    const prayTypeOpt = pray2Obj.sel || {}
    const { title, color, icon } = obj;
    const { yearDef, orderalphaDef, ordernumDef, pray1IdDef, pray2numLen } = companyObjLive;
    const ordernum = orderalphaDef + funcPad(Number(ordernumDef) + 1, 8);
    let people1Sel = { '-1': '(無)' };
    // console.log(people1)
    people1.forEach(item => people1Sel = { ...people1Sel, [item.objectId]: item.name });

    return (<>
      <Button icon={icon} content={title} color={color} size='small' floated='right' onClick={this.handleClickModalBtn} />
      <Modal
        isOpen={show}
        toggle={this.handleClosePdf}
        size="lg"
        className="mh-90 h-90 mw-100 w-90"
        style={{ paddingTop: '0px' }}
        scrollable={true}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggle}>
            <span aria-hidden="true" onClick={this.handleClosePdf}>×</span>
          </button>
          {title} 管理
        </ModalHeader>
        <ModalBody style={{ padding: "20px 30px" }}>
          <Grid >
            <Grid.Row columns='equal'>
              <Grid.Column width={10}>
                <MaterialTable
                  isLoading={loading1}
                  localization={localization()}
                  title={this.props.title || '申辦服務'}
                  columns={columns(companyObjLive.webtypeId === "temple", pray1Obj.sel, pray2, yearDef, ordernum, pray1IdDef, people1Sel, pray2numLen, mgyearSel, mgyearDefault, pray2Obj)}
                  options={{
                    addRowPosition: "first",
                    exportButton: true,
                    exportPdf: (columns, data) => exportPdf(columns, data, this.props.title || '申辦服務'),
                    pageSize: 5,
                    search: false,
                    tableLayout: 'fixed',
                  }}
                  data={data1}
                  actions={[
                    {
                      icon: 'view_headline',
                      tooltip: '查看',
                      onClick: (event, rowData) => this.handleData(rowData)
                    },
                    {
                      icon: 'file_copy',
                      tooltip: '複製',
                      onClick: (event, rowData) => this.handleDuplicate(rowData)
                    }
                  ]}
                  onRowClick={(event, rowData) => this.handleData(rowData)}
                  editable={{
                    onRowAdd: newData1 =>
                      new Promise(async (resolve, reject) => {
                        const data = [...data1];
                        const db = Parse.Object.extend("praying1");
                        const newDoc = new db();
                        const doc = await newDoc.save();
                        newData1.objectId = doc.id;
                        newData1.id = doc.id;
                        newData1._id = doc.id;
                        newData1.companyId = companyObjLive.objectId
                        newData1.companyName = companyObjLive.name
                        newData1.category = category
                        newData1.familyId = familyId
                        newData1.accReceivable = Number(newData1.accReceivable || 0)
                        newData1.accReceived = Number(newData1.accReceived || 0)
                        newData1.accPending = Number(newData1.accReceivable || 0) - Number(newData1.accReceived || 0)
                        if (companyObjLive.webtypeId === "temple") {
                          if (newData1.pray1Id) {
                            newData1.pray1Text = pray1Sel[newData1.pray1Id];
                          }
                          if (newData1.pray2Id) {
                            newData1.pray2Text = pray2.find(item => item.objectId === newData1.pray2Id).value;
                          }
                        } else {
                          // newData1.fieldTypeId = newData1.prayType
                          newData1.fieldTypeText = prayTypeOpt[newData1.fieldTypeId]
                        }
                        data.push(newData1);
                        this.setState({ data1: data, newData1 }, () => resolve());
                      }).then(() => this.handleAdd()),
                    onRowUpdate: (newData1, oldData1) =>
                      new Promise((resolve, reject) => {
                        console.log(newData1)
                        if (newData1.pray1Id) {
                          newData1.pray1Text = pray1Sel[newData1.pray1Id];
                        }
                        if (newData1.pray2Id) {
                          newData1.pray2Text = pray2.find(item => item.objectId === newData1.pray2Id).value;
                        }
                        // if (companyObjLive.webtypeId === "dao") {
                        //   newData1.fieldTypeId = newData1.prayType
                        //   newData1.fieldTypeText = prayTypeOpt[newData1.prayType]
                        // }
                        newData1.accReceivable = Number(newData1.accReceivable || 0);
                        newData1.accReceived = Number(newData1.accReceived || 0);
                        newData1.accPending = Number(newData1.accReceivable || 0) - Number(newData1.accReceived || 0);
                        newData1.fieldTypeText = prayTypeOpt[newData1.fieldTypeId]
                        newData1.companyId = companyObjLive.objectId
                        newData1.companyName = companyObjLive.name
                        console.log(newData1)
                        const data = [...data1];
                        const index = data.indexOf(oldData1);
                        data[index] = newData1;

                        const { pray1Id, pray1Text, pray2Id, pray2Text, year, ordernum, serial, pray2OrderId } = newData1;
                        const data2 = this.state.data2 && this.state.data2.map(item => ({
                          ...item, pray1Id, pray1Text, pray2Id, pray2Text, year, ordernum, serial, pray2OrderId
                        }));

                        this.setState({ data1: data, data2, newData1, oldData1 }, () => resolve());
                      }).then(() => this.handleUpdate()),
                    onRowDelete: oldData1 =>
                      new Promise((resolve, reject) => {
                        let data = [...data1];
                        const index = data.indexOf(oldData1);
                        data.splice(index, 1);
                        this.setState({ data1: data, oldData1 }, () => resolve());
                      }).then(() => this.handleDelete()),
                  }}
                />
              </Grid.Column>
              <Grid.Column width={6}>
                {ordering1Id ? <>
                  {loading ? <DimmerImg /> : <>
                    <ModalPdf {...this.props} title='繳款單' nowform={'80'} params={{ people1, people0, praying1Obj, companyObj: companyObjLive }} />
                    <ModalPdf {...this.props} title='收據' nowform={'81'} params={{ people1, people0, praying1Obj, companyObj: companyObjLive }} />
                    <br />
                    <br />
                    <ModalReceivable {...this.props} praying1Obj={praying1Obj} handleUpdateAcc={this.handleUpdateAcc} />
                  </>}
                </> : null}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
              <Grid.Column width={16}>
                {ordering1Id ? <div style={{ maxWidth: '100%' }}>
                  <ModalPraying2
                    {...this.props}
                    isLoading={loading}
                    selectData={selectData}
                    pray2Obj={pray2Obj}
                    praying1Obj={praying1Obj}
                    data1={data1} data2={data2}
                    setData2={this.setData2}
                    category={category}
                    praying1Type={praying1Type}
                    fieldTypeId={fieldTypeId} />
                </div> : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button onClick={this.handleClosePdf} icon='checkmark' content='關閉' />
        </ModalFooter>
      </Modal>
    </>)
  }
}