import React, { Component } from 'react';
import { Tab, ListGroup, Container, Form, Icon } from 'semantic-ui-react';

import applyDocStudent from './List/applyDocStudent';
import InterviewStudent from './List/InterviewStudent';
import SignStudent from './List/SignStudent';
import StudyStudent from './List/StudyStudent';
import SupportStudent from './List/SupportStudent';
// import RecommandStudent fro./List/AgentStudentent';
import RelativeStudent from './List/RelativeStudent';
// import CheckSheetStudent from './List/CheckSheetStudent';
import AgentStudent from './List/AgentStudent';
import DormStudent from './List/DormStudent';
import HostPrint from '../../widgets/HostPrint';
import List6Student from './List/List6Student';
// import ApplicationStudent from './List/ApplicationStudent';
// import PrintStudent from './List/PrintStudent';

// import PagePrint from './../../components//ModalPdf/PagePrint';
// import RecruitResult from './List/RecruitResult';
// import Registration from './Registration';
// import RelativeStudent from './RelativeStudent';

const size = 'small';
const TabRender = ({ component, ...rest }) => <Tab.Pane><Form size={size}>{React.createElement(component, { ...rest })}</Form></Tab.Pane>;
const menuTitle = (str1, str2 = '', icon = 'tty', LEN = 4) =>
  <>
    {/* <div><Icon name={icon} /></div> */}

    {/* <div style={{ marginBottom: '8px' }}>{str1}</div> */}
    {str1}
    {str2 ? <>{str2.substr(0, LEN)}</> : null}
    {str2.length >= LEN ? <><br />{str2.substr(LEN, LEN)}</> : null}
    {str2.length >= LEN * 2 ? <><br />{str2.substr(LEN * 2, LEN)}</> : null}
    {str2.length >= LEN * 3 ? <><br />{str2.substr(LEN * 3, LEN)}</> : null}
    {str2.length >= LEN * 4 ? <><br />{str2.substr(LEN * 4, LEN)}</> : null}
  </>;
export default class ListTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: props.match.params.activeIndex || 0,
      studentObj: props.doc
    };
  }

  componentWillReceiveProps(nextProps) {
    const { activeIndex } = nextProps
    if (nextProps.activeIndex !== this.state.activeIndex) {
      this.setState({ activeIndex });
    }
    if (nextProps.doc !== this.props.doc) {
      // console.log(nextProps.doc)
      this.setState({ studentObj: nextProps.doc });
    }
  }

  handleTabChange = (e, { activeIndex }) => {
    const { match, doc } = this.props
    const { list = "1" } = match.params;
    this.props.history.push(`/admin/StudentPage/${list}/${doc.objectId}/${activeIndex}`);
    // const studentObj = [];
    // studentObj.push(doc);
    this.setState({ activeIndex })
  }

  panes = [
    { menuItem: { key: 'listtabmenu1', content: menuTitle('2-0-①', 'マスター情報') }, render: () => <TabRender {...this.props} component={SignStudent} /> },
    { menuItem: { key: 'listtabmenu2', content: menuTitle('2-0-②', '学習歴') }, render: () => <TabRender {...this.props} component={StudyStudent} /> },
    { menuItem: { key: 'listtabmenu3', content: menuTitle('2-0-③', '親族') }, render: () => <TabRender {...this.props} component={RelativeStudent} /> },
    { menuItem: { key: 'listtabmenu4', content: menuTitle('2-0-④', '経費支弁者') }, render: () => <TabRender {...this.props} component={SupportStudent} /> },
    { menuItem: { key: 'listtabmenu5', content: menuTitle('2-0-⑤', '推薦者') }, render: () => <TabRender {...this.props} component={AgentStudent} /> },
    { menuItem: { key: 'listtabmenu6', content: menuTitle('2-0-⑥', '書類状況') }, render: () => <TabRender {...this.props} component={applyDocStudent} /> },
    { menuItem: { key: 'listtabmenu7', content: menuTitle('2-0-⑦', '面接記録') }, render: () => <TabRender {...this.props} component={InterviewStudent} /> },
    { menuItem: { key: 'listtabmenu8', content: menuTitle('2-0-⑧', '備考') }, render: () => <TabRender {...this.props} component={List6Student} /> },
    { menuItem: { key: 'listtabmenu9', content: menuTitle('2-0-⑨', '印刷') }, render: () => <TabRender {...this.props} component={HostPrint} dirkey="recruitP" studentObj={this.state.studentObj} /> },
    // { menuItem: '出願書類', render: () => <TabRender {...this.props} component={CheckSheetStudent} />},
  ]

  render() {
    const { companyObj } = this.props
    const { activeIndex } = this.state;

    return (<Tab
      // fluid
      activeIndex={activeIndex} onTabChange={this.handleTabChange}
      menu={{ pointing: true, inverted: companyObj.inverted }}
      panes={this.panes} />);
  }
}