import React, { Component } from 'react'
import { Button, Form, Grid, } from 'semantic-ui-react';
import { ZipCodeTW } from "zipcode-tw-react"
// import MaterialTable from 'material-table'
// import Icon from '@material-ui/core/Icon'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { IconButton } from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

const numBtn = [
  { key: 'numBtn0', text: '0', value: '0' },
  { key: 'numBtn1', text: '1', value: '1' },
  { key: 'numBtn2', text: '2', value: '2' },
  { key: 'numBtn3', text: '3', value: '3' },
  { key: 'numBtn4', text: '4', value: '4' },
  { key: 'numBtn5', text: '5', value: '5' },
  { key: 'numBtn6', text: '6', value: '6' },
  { key: 'numBtn7', text: '7', value: '7' },
  { key: 'numBtn8', text: '8', value: '8' },
  { key: 'numBtn9', text: '9', value: '9' },
]
const numBtn2 = [
  // { key: 'numBtn20', text: '零', value: '0' },
  { key: 'numBtn21', text: '一', value: '1' },
  { key: 'numBtn22', text: '二', value: '2' },
  { key: 'numBtn23', text: '三', value: '3' },
  { key: 'numBtn24', text: '四', value: '4' },
  { key: 'numBtn25', text: '五', value: '5' },
  { key: 'numBtn26', text: '六', value: '6' },
  { key: 'numBtn27', text: '七', value: '7' },
  { key: 'numBtn28', text: '八', value: '8' },
  { key: 'numBtn29', text: '九', value: '9' },
]

const addrBtn = [
  { key: 'addrBtn0', text: '市', value: '0' },
  { key: 'addrBtn1', text: '縣', value: '1' },
  { key: 'addrBtn2', text: '鄉', value: '2' },
  { key: 'addrBtn3', text: '鎮', value: '3' },
  { key: 'addrBtn4', text: '區', value: '4' },
  { key: 'addrBtn5', text: '路', value: '5' },
  { key: 'addrBtn6', text: '街', value: '6' },
  { key: 'addrBtn7', text: '巷', value: '7' },
  { key: 'addrBtn8', text: '弄', value: '8' },
  { key: 'addrBtn9', text: '號', value: '9' },
  { key: 'addrBtn10', text: '樓', value: '10' },
  { key: 'addrBtn11', text: '←', value: '11' },
]

const inputStyle = {
  fontFamily: 'Lato',
  margin: '0 1rem 0',
  outline: 0,
  appearance: 'none',
  lineHeight: '1.21428571em',
  padding: '.67857143em 1em',
  fontSize: '1em',
  background: '#fff',
  border: '1px solid rgba(34,36,38,.15)',
  color: 'rgba(0,0,0,.87)',
  borderRadius: '.28571429rem',
  boxShadow: '0 0 0 0 transparent inset',
  transition: 'color .1s ease,border-color .1s ease',
  flex: 1
}

export default class ModalAutoAddr extends Component {
  state = {
    open: false,
    // open: false,
    zipObj: {
      displayType: 'text',
      county: '',
      district: '',
      zipcode: ''
    },
    form: {
      postcode: '',
      addr: '',
      addr4: '',
      name2: ''
    },
    // postcodeOpt: [{ key: `post32null`, text: `選擇郵遞區號`, value: `-1` }],
    loadingAddr4: false,
    addr4Opt: [{ key: `addr4null`, text: `選擇路名`, value: `-1` }],
    name2Opt: [{ key: `name2null`, text: `選擇牌號`, value: `-1` }],
    liveId: '-1',
    passId: '-1',
  }

  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })
  toggle = () => this.setState({ open: !this.state.open })

  handleChange = (e, { name, value }) => this.setState({ form: { ...this.state.form, [name]: value } })

  handleClick = (e, { children }) => {
    // console.log(this.state.form.addr);
    let val = this.state.form.addr + children
    this.setState({ form: { ...this.state.form, addr: val } })
  }

  // 變更地址資訊
  handleZipCodeChange = (e) => {
    const { countyFieldName, countyValue, districtFieldName, districtValue, zipFieldName, zipValue } = e;
    if (e.zipFieldName === 'zipCode') {
      this.setState({ form: { addr4: '-1', name2: '', liveId: '-1', passId: '-1', } }, () => this.getAddr4(e.zipValue))
    }

    // console.log(e);
    this.setState({
      zipObj: {
        [zipFieldName]: zipValue,
        [countyFieldName]: countyValue,
        [districtFieldName]: districtValue,
      },
      form: {
        postcode: e.zipValue,
        addr: `${e.countyValue}${e.districtValue}`,
        // addr: `${countyVal}${districtVal}`,
      }
    });
  }

  handleZipCodeNotExists = (e) => {  // 處理郵遞區號不存在
    const { countyFieldName, countyValue, districtFieldName, districtValue, zipFieldName, zipValue, origZipValue } = e;
    this.setState({
      zipObj: {
        [zipFieldName]: zipValue,
        [countyFieldName]: countyValue,
        [districtFieldName]: districtValue
      }
    });
    console.log('郵遞區號不存在: ' + origZipValue, '', 'error');
  }

  getAddr4 = async (val) => {
    const { companyObj } = this.props;
    const { zipObj } = this.state
    // const db = firebase.firestore()

    this.setState({ liveId: '-1', passId: '-1', loadingAddr4: true })
    // db.collection('addr4').where('post3', '==', val).get().then(snap => { // .orderBy('district_id')
    //   let data = [];
    //   // const postcodeOpt = [{ key: `post32null`, text: `選擇郵遞區號`, value: `-1` }]
    //   snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }))
    const data = await Parse.queryData("addr4", { post3: val })
    let dataSort = data.sort((a, b) => a.post32 - b.post32)

    const addr4Opt = []
    dataSort.forEach((item, index) => {
      // postcodeOpt.push({ key: `post32${item.name1}${index}`, text: `${item.name1}`, value: `${item.name1}` })
      let findIndex = addr4Opt.findIndex(item2 => item2.value === item.name1)
      if (findIndex === -1) {
        addr4Opt.push({ key: `addr4${item.name1}${index}`, text: `${item.name1}`, value: `${item.name1}` })
      }
    })
    this.setState({
      // addr4: dataSort,
      // postcodeOpt,
      addr4Opt: [{ key: `addr4null`, text: `-- 選擇${zipObj.district || ''}路名 --`, value: `-1` }].concat(addr4Opt.sort((a, b) => a.text.localeCompare(b.text))),
      form: { addr4: '-1' },
      loadingAddr4: false,
    }, err => { console.log(`Encountered error: ${err}`); })
  }

  handleChangeAddr4 = (e, { name, value }) => {
    const { zipObj } = this.state;
    const { county, district, zipCode } = zipObj
    const addr = county + district + (value !== '-1' ? value : '');
    this.setState({ form: { postcode: zipCode, addr, addr4: value, }, })
  }

  handleChangePeopleAddr = (e, { name, value, options }) => {
    const addr = value;
    const obj = options.find(item => item.value === value)
    const postcode = obj ? obj.key.split('-')[0] : ''
    this.setState({
      form: { addr, postcode, addr4: '' },
      zipObj: { county: '', district: '', zipCode: postcode, },
      liveId: '-1', passId: '-1',
      [name]: value,
    })
  }

  handleSubmitAddr = () => {
    const { rowData, onRowDataChange, type, field1, field2 } = this.props;
    const { form } = this.state;
    const { postcode, addr } = form
    if (type === "0") {
      onRowDataChange({ ...rowData, postcode, addr });
    } else if (type === "1") {
      onRowDataChange({ postcode, addr })
    } else if (type === "2") {
      onRowDataChange({ postcode, addr })
    } else if (type === "3") {
      onRowDataChange({ ...rowData, [field1]: postcode, [field2]: addr });
    }
    this.close();
  }

  typeButton = () => {
    const { type } = this.props;
    if (type === "0") { // TablePeople 表格
      return <IconButton onClick={this.toggle}><Menu size='small' /></IconButton>;
    } else if (type === "1") { // TablePeople 修改全戶地址
      return <Button onClick={this.toggle} content='全戶地址' icon='edit' color='teal' />
    } else if (type === "2") { // ModalNewFamily
      return <Button onClick={this.toggle} content='地址' size='big' icon='edit' basic color='teal' />
    } else if (type === "3") { // ModalNewFamily
      return <><label>&nbsp;</label><div><IconButton onClick={this.toggle}><Menu size='medium' /></IconButton></div></>;
    }
  }

  typeSubmitText = () => {
    const { type } = this.props;
    if (type === "0") { // TablePeople 表格
      return '代入地址';
    } else if (type === "1") { // TablePeople 修改全戶地址
      return '更改全戶地址';
    } else if (type === "2") { // ModalNewFamily
      return '代入地址';
    }
  }

  render() {
    const { people1 = [], people0 = [], title = '選擇地址自動代入郵遞區號' } = this.props
    const { open, zipObj, addr4Opt, name2Opt, form, loadingAddr4, liveId, passId } = this.state
    const { county, district, zipCode } = zipObj

    // const liveAddrArr = (people1.length && people1.filter(item => item.addr).map((item, index) => ({ key: `${item.postcode || ''}-${index}a`, text: `[${item.name}] ${item.postcode || ''} ${item.addr || ''}`, value: `${item.addr || ''}` }))) || [];
    // const passAddrArr = (people0.length && people0.filter(item => item.addr).map((item, index) => ({ key: `${item.postcode || ''}-${index}b`, text: `[${item.name}] ${item.postcode || ''} ${item.addr || ''}`, value: `${item.addr || ''}` }))) || [];
    // const liveAddrOpt = [{ key: `aa`, text: `-- 選擇陽上地址 --`, value: `-1` }, ...liveAddrArr]
    // const passAddrOpt = [{ key: `bb`, text: `-- 選擇拔渡地址 --`, value: `-1` }, ...passAddrArr]

    return (<>
      {this.typeButton()}
      <Modal
        isOpen={open}
        toggle={this.toggle}
        size="lg"
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggle}>
            <span aria-hidden="true">×</span>
          </button>
          {title}
        </ModalHeader>
        <ModalBody>
          <Grid>
            <Grid.Row style={{ paddingBottom: 0, marginBottom: '.28571429rem' }}>
              <Grid.Column>
                <label style={{ color: 'rgba(0,0,0,.87)', fontSize: '.92857143em', fontWeight: 700 }}>選擇地區(會自動填上郵遞區號)</label>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch', paddingTop: 0 }}>
              <ZipCodeTW
                displayType={'text'}
                countyValue={county}
                districtValue={district}
                zipCodeValue={zipCode}
                handleChangeCounty={this.handleZipCodeChange}
                handleChangeDistrict={this.handleZipCodeChange}
                handleChangeZipCode={this.handleZipCodeChange}
                handleBlurZipCode={this.handleZipCodeChange}
                handleZipCodeNotExists={this.handleZipCodeNotExists}
                zipCodePlaceholder='郵遞區號'
                countyStyle={inputStyle}
                districtStyle={inputStyle}
                zipStyle={inputStyle}
                zipCodePositionLast={true}
              />
              <Form style={{ margin: '0 1rem 0' }}><Form.Select loading={loadingAddr4} options={addr4Opt} value={form.addr4} onChange={this.handleChangeAddr4} /></Form>
            </Grid.Row>
            {/* {people1.length || people0.length ? <Grid.Row columns='equal'>
              <Grid.Column>
                {people1.length ? <Form><Form.Select options={liveAddrOpt} // label='陽上地址'
                  onChange={this.handleChangePeopleAddr} name='liveId' value={liveId} defaultValue=' ' /></Form> : null}
              </Grid.Column>
              <Grid.Column>
                {people0.length ? <Form><Form.Select options={passAddrOpt} // label='拔渡地址'
                  onChange={this.handleChangePeopleAddr} name='passId' value={passId} defaultValue=' ' /></Form> : null}
              </Grid.Column>
            </Grid.Row> : null} */}
            <Grid.Row>
              <Grid.Column>
                <Button.Group>
                  {numBtn && numBtn.length ? numBtn.map(item => <Button onClick={this.handleClick} key={item.key}>{item.text}</Button>) : null}
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ padding: 0 }}>
              <Grid.Column>
                <Button.Group>
                  {numBtn2 && numBtn2.length ? numBtn2.map(item => <Button onClick={this.handleClick} key={item.key}>{item.text}</Button>) : null}
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button.Group>
                  {addrBtn && addrBtn.length ? addrBtn.map(item => <Button onClick={this.handleClick} key={item.key}>{item.text}</Button>) : null}
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
              <Grid.Column>
                <Form>
                  <Form.Input label='地址' name='addr' onChange={this.handleChange} placeholder="輸入地址" value={form.addr} />
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button icon='x' content='關閉' onClick={this.close} />
          <Button icon='tag' primary content={this.typeSubmitText()} onClick={this.handleSubmitAddr} />
        </ModalFooter>
      </Modal>
    </>)
  }
}
