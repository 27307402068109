import React, { Component } from 'react'
import { Form } from 'semantic-ui-react';
import { Card, CardBody, CardHeader, Row, Col, Badge, Toast, ToastHeader, ToastBody } from 'reactstrap';

import Parse from 'widget/parse'
import { fieldCol } from "views/formDef/function";
import { portfolio } from 'views/formDef/object';
import zIndex from '@material-ui/core/styles/zIndex';

export default class Portfolio3 extends Component {
  state = {
    icSel: [],
    icId: '',
    providerSel: [],
    providerId: '',
  }

  async componentDidMount() {
    this.handleFetch(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps)
  }

  handleFetch = async (props = this.props) => {
    const { doc, companyObj, ic, provider } = props
    const companyId = companyObj.objectId;
    // const ic = await Parse.queryData('interestcondition', { companyId });
    // const provider = await Parse.queryData('provider', { companyId });

    const { agent } = this.props
    const agentSel = agent.map(item => ({ key: item.objectId, text: item.name, value: item.objectId }))
    // this.setState({ agentSel })

    const icSel = ic.map(item => ({
      key: item.objectId, text: `${item.item}`, value: item.objectId
    }))
    const providerSel = provider.map(item => ({
      key: item.objectId, text: `${item.name_cht}`, value: item.objectId
    }))
    let icId
    if (ic && ic.length && ic.find(item => (item.objectId === doc.interest_condition))) {
      icId = ic.find(item => (item.objectId === doc.interest_condition)).objectId
    } else {
      icId = ''
    }
    // this.setState({ icSel, icId });
    let providerId
    if (provider && provider.length && provider.find(item => (item.objectId === doc.provider_id))) {
      providerId = provider.find(item => (item.objectId === doc.provider_id)).objectId
    } else {
      providerId = ''
    }

    this.setState({ providerSel, providerId, icSel, icId, agentSel });
  }

  handleChange = (event, data) => {
    const { handleSelectUpdate } = this.props
    const { name, value } = data;
    handleSelectUpdate(data)
    this.setState({ [name]: value })
  }

  render() {
    const { doc, isEdit, showTable, handleChangeField } = this.props
    const { icId, icSel, providerId, providerSel, agentSel } = this.state;

    return (<>
      <Card fluid>
        <CardHeader tag="h5" style={{ backgroundColor: '#f07b51' }}> [英國房產-股權] {doc.product4_text}</CardHeader>
        <CardBody>
          <Row>
            {fieldCol(portfolio.stockitem, "pr-1", "3", doc.stockitem, handleChangeField, !isEdit)}
            {fieldCol(portfolio.certificate_no, "pr-1", "3", doc.certificate_no, handleChangeField, !isEdit)}
            {fieldCol(portfolio.account_num, "pr-1", "3", doc.account_num, handleChangeField, !isEdit)}
            {fieldCol(portfolio.invest_money, "pr-1", "3", doc.invest_money, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.holdstock_percent, "pr-1", "3", doc.holdstock_percent, handleChangeField, !isEdit)}
            {fieldCol(portfolio.holdstock_num, "pr-1", "3", doc.holdstock_num, handleChangeField, !isEdit)}
            {fieldCol(portfolio.effective_date, "pr-1", "3", doc.effective_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.payment_date, "pr-1", "3", doc.payment_date, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.payment_method, "pr-1", "3", doc.payment_method, handleChangeField, !isEdit)}
            {fieldCol(portfolio.receive_money_date, "pr-1", "3", doc.receive_money_date, handleChangeField, !isEdit)}
            <Col sm='3'><Form.Select fluid label='利率條件' options={icSel} name='icId' onChange={this.handleChange} value={icId} disabled={!isEdit} /></Col>
            {fieldCol(portfolio.pay_year_num, "pr-1", "3", doc.pay_year_num, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.giveinterest_period, "pr-1", "3", doc.giveinterest_period, handleChangeField, !isEdit)}
            <Col sm='3'><Form><Form.Select fluid label='供應商' options={providerSel} name='providerId' onChange={this.handleChange} value={providerId} disabled={!isEdit} /></Form></Col>
            {fieldCol(portfolio.nowphase, "pr-1", "3", doc.nowphase, handleChangeField, !isEdit)}
            {fieldCol(portfolio.prebuybook_date, "pr-1", "3", doc.prebuybook_date, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.stockcontract_date, "pr-1", "3", doc.stockcontract_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.giveinterestconfirm_date, "pr-1", "3", doc.giveinterestconfirm_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.stockrightapply_date, "pr-1", "3", doc.stockrightapply_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.passport_publicprove, "pr-1", "3", doc.passport_publicprove, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.address_publicprove, "pr-1", "3", doc.address_publicprove, handleChangeField, !isEdit)}
            {fieldCol(portfolio.givecontract_date, "pr-1", "3", doc.givecontract_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.return_principal_date, "pr-1", "3", doc.return_principal_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.currency_type, "pr-1", "3", doc.currency_type, handleChangeField, !isEdit)}
          </Row>
          <Row>
            <Col sm='6'>{this.props.handlePaymentDateMoney(1)}</Col>
            <Col sm='6'><Form.Select fluid label='agent' options={agentSel} id='agent_id' name='agent_text' onChange={this.handleChange} value={doc.agent_id || ''} disabled={!isEdit} /></Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col>
              <Card fluid style={{ borderLeft: 'solid', backgroundColor: 'lightgoldenrodyellow', maxWidth: 'inherit' }}>
                <CardHeader style={{ backgroundColor: 'lightgoldenrodyellow', borderBottom: 'double' }}>UK Tax FORM</CardHeader>
                <CardBody>
                  <Row>
                    {fieldCol(portfolio.irs_w7_sign_date, "pr-1", "3", doc.irs_w7_sign_date, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.irs_w7_status, "pr-1", "3", doc.irs_w7_status, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.irs_w7_sent_date, "pr-1", "3", doc.irs_w7_sent_date, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.irs_ps, "pr-1", "3", doc.irs_ps, handleChangeField, !isEdit)}
                  </Row>
                </CardBody>
              </Card>

              {/* <Toast style={{ borderColor: 'black', backgroundColor: 'lightgoldenrodyellow', maxWidth: 'inherit' }}>
                <ToastHeader>UK Tax FORM</ToastHeader>
                <ToastBody>
                  <Row>
                    {fieldCol(portfolio.irs_w7_sign_date, "pr-1", "3", doc.irs_w7_sign_date, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.irs_w7_status, "pr-1", "3", doc.irs_w7_status, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.irs_w7_sent_date, "pr-1", "3", doc.irs_w7_sent_date, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.irs_ps, "pr-1", "3", doc.irs_ps, handleChangeField, !isEdit)}
                  </Row>
                </ToastBody>
              </Toast> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <Card fluid style={{ borderLeft: 'solid', backgroundColor: 'lightgoldenrodyellow', maxWidth: 'inherit' }}>
                <CardHeader style={{ backgroundColor: 'lightgoldenrodyellow', borderBottom: 'double' }}>UK Tax No.</CardHeader>
                <CardBody>
                  <Row>
                    {fieldCol(portfolio.itin_text, "pr-1", "3", doc.itin_text, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.itin_status, "pr-1", "3", doc.itin_status, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.itin_date, "pr-1", "3", doc.itin_date, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.itin_ps, "pr-1", "3", doc.itin_ps, handleChangeField, !isEdit)}
                  </Row>
                </CardBody>
              </Card>
              {/* <Toast style={{ borderColor: 'black', backgroundColor: 'lightgoldenrodyellow', maxWidth: 'inherit' }}>
                <ToastHeader>UK Tax No.</ToastHeader>
                <ToastBody>
                  <Row>
                    {fieldCol(portfolio.itin_text, "pr-1", "3", doc.itin_text, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.itin_status, "pr-1", "3", doc.itin_status, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.itin_date, "pr-1", "3", doc.itin_date, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.itin_ps, "pr-1", "3", doc.itin_ps, handleChangeField, !isEdit)}
                  </Row>
                </ToastBody>
              </Toast> */}
            </Col>
          </Row>

          {/* <Row>

          </Row> */}
        </CardBody>
      </Card>
      {showTable('Beneficiary Bank')}
      {showTable('GiveInterestRecord')}
      {showTable('ReturnPrincipal')}
    </>)
  }
}
