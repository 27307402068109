import React from "react";
import { Link } from 'react-router-dom';
import classnames from "classnames";
import Headroom from "headroom.js";
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
// import intl from 'react-intl-universal';
import {
  // Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip
} from "reactstrap";
import { Image, Select } from 'semantic-ui-react';

import { logout } from '../../store/actions/auth';
import DropdownLang1 from "./DropdownLang1";
const hostname = window.location.hostname;

const ColorNavbar = (props = {}) => {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const { history, cookies, companyObj = {}, user = {}, webnavArr = [], companies = [], changeCompany } = props;
  const dbLang = cookies.get('dbLang') || 'name';

  const logout = () => {
    // const toUrl = companyObj.useOfficial ? "/" : '/auth/login';
    // const toUrl = '/auth/login';
    // window.location.href = window.location.hostname + toUrl;
    props.logout(props.history, '/');
  }

  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  const data1 = webnavArr.filter(item => item.level === '1' && (item.pageType === '1' || item.pageType === '2' || item.pageType === '3'));

  const profile = (user.isAuthenticated && user.profile && user.profile.toJSON()) || {};
  const [name = '', host] = user.isAuthenticated ? profile.username && profile.username.split('@') : ['', '']

  let showUser = true
  if (companyObj.objectId === 'aKDM0xR8py' && !user.isAuthenticated) {
    showUser = false
  }
  return (<>
    {bodyClick ? (
      <div
        id="bodyClick"
        onClick={() => {
          document.documentElement.classList.toggle("nav-open");
          setBodyClick(false);
          setCollapseOpen(false);
        }}
      />
    ) : null}
    {companyObj.objectId === 'Wuwrcczn0Z' ? <>
      <Navbar className={classnames("fixed-top", navbarColor)} expand="lg" id="navbar-main">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" to="/" tag={Link}>
              {companyObj.img1File ? <Image size="small" src={companyObj.img1File.url} /> : companyObj.name}
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              {/* {intl.get('愛知国際学院')} */}
              {companyObj[dbLang]}
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              {data1.filter(item => item.isOpen).map(item => (item.pageType === '2' || item.pageType === '3') ?
                <UncontrolledDropdown nav inNavbar key={item.objectId} >
                  {/* <DropdownToggle color="default" nav caret>{item[dbLang]}</DropdownToggle> */}
                  {/* <DropdownMenu className="dropdown-danger" right> */}
                  {webnavArr
                    .filter(item2 => item2.isOpen && item2.level === '2' && item2.nav1Id === item.objectId)
                    .map(element =>
                      <DropdownItem
                        key={element.objectId}
                        id={"b" + element.objectId}
                        to={`${item.pageType === '3' ? '/#' : '/page/'}${element.path2 || element.objectId}`}
                        tag={Link}
                      >
                        {element[dbLang]}
                      </DropdownItem>
                    )}
                  {/* </DropdownMenu> */}
                </UncontrolledDropdown> :
                <NavItem key={item.objectId} >
                  <NavLink id={"a" + item.objectId} to={`/page/${item.path1 || item.objectId}`} tag={Link}>{item[dbLang]}</NavLink>
                </NavItem>
              )}
              {companyObj.isMultiLang ? <DropdownLang1 {...props} /> : null}
              {showUser ?
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle caret color="default" nav>
                    <i className="fa fa-user-o" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink" className="dropdown-danger" right>
                    <DropdownItem header>{name}</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem to="/profile" tag={Link}>個人資料</DropdownItem>
                    <DropdownItem to="/webDesign" tag={Link}>網站設計</DropdownItem>
                    {companyObj.isMultiLang ? <DropdownItem to="/localeManage" tag={Link}>多國語系</DropdownItem> : null}
                    <DropdownItem href="/admin/dashboard" >後台管理</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      // href="/logout"
                      href="#"
                      onClick={logout}
                    >登出</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> : null}
              {hostname === 'localhost' ?
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle caret color="default" nav>
                    <i className="fa fa-desktop" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink" className="dropdown-danger" right>
                    {companies.length && companies.map(({ name, objectId }) => (<DropdownItem key={"ab" + objectId}
                      onClick={e => {
                        e.preventDefault()
                        changeCompany(objectId)
                        history.push('/')
                      }}
                    >{name}</DropdownItem>))}
                  </DropdownMenu>
                </UncontrolledDropdown> : null}
            </Nav>
          </Collapse>
        </Container>
      </Navbar></> :



      <Navbar className={classnames("fixed-top", navbarColor)} expand="lg" id="navbar-main">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" to="/" tag={Link}>
              {companyObj[dbLang]}
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              {/* {intl.get('愛知国際学院')} */}
              {companyObj[dbLang]}
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              {data1.filter(item => item.isOpen).map(item => (item.pageType === '2' || item.pageType === '3') ?
                <UncontrolledDropdown nav inNavbar key={item.objectId} >
                  <DropdownToggle color="default" nav caret>{item[dbLang]}</DropdownToggle>
                  <DropdownMenu className="dropdown-danger" right>
                    {webnavArr
                      .filter(item2 => item2.isOpen && item2.level === '2' && item2.nav1Id === item.objectId)
                      .map(element =>
                        <DropdownItem
                          key={element.objectId}
                          id={"b" + element.objectId}
                          to={`${item.pageType === '3' ? '/#' : '/page/'}${element.path2 || element.objectId}`}
                          tag={Link}
                        >
                          {element[dbLang]}
                        </DropdownItem>
                      )}
                  </DropdownMenu>
                </UncontrolledDropdown> :
                <NavItem key={item.objectId} >
                  <NavLink id={"a" + item.objectId} to={`/page/${item.path1 || item.objectId}`} tag={Link}>{item[dbLang]}</NavLink>
                </NavItem>
              )}
              {companyObj.isMultiLang ? <DropdownLang1 {...props} /> : null}
              {showUser ?
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle caret color="default" nav>
                    <i className="fa fa-user-o" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink" className="dropdown-danger" right>
                    <DropdownItem header>{name}</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem to="/profile" tag={Link}>個人資料</DropdownItem>
                    <DropdownItem to="/webDesign" tag={Link}>網站設計</DropdownItem>
                    {companyObj.isMultiLang ? <DropdownItem to="/localeManage" tag={Link}>多國語系</DropdownItem> : null}
                    <DropdownItem href="/admin/dashboard" >後台管理</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      // href="/logout"
                      href="#"
                      onClick={logout}
                    >登出</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> : null}
              {hostname === 'localhost' ?
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle caret color="default" nav>
                    <i className="fa fa-desktop" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink" className="dropdown-danger" right>
                    {companies.length && companies.map(({ name, objectId }) => (<DropdownItem key={"ab" + objectId}
                      onClick={e => {
                        e.preventDefault()
                        changeCompany(objectId)
                        history.push('/')
                      }}
                    >{name}</DropdownItem>))}
                  </DropdownMenu>
                </UncontrolledDropdown> : null}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>}
  </>);
}

// export default ColorNavbar;
const mapStateToProps = state => ({
  user: state.user
});
// export default withCookies(ColorNavbar);
export default connect(mapStateToProps, { logout })(withCookies(ColorNavbar));

