import React, { Component } from "react";
import AliceCarousel from 'react-alice-carousel';
import { Segment } from 'semantic-ui-react';
import ImageMapper from 'react-image-mapper';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col
} from "reactstrap";
import Parse from 'widget/parse'
import 'react-alice-carousel/lib/alice-carousel.css';

// core components
const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};
const path = '/images/aichi_floorMaps/'
const MAP = {
  name: "my-map",
  areas: [
    // { name: "1", shape: "poly", coords: [25,33,27,300,128,240,128,94], preFillColor: "green", fillColor: "blue"  },
    // { name: "2", shape: "poly", coords: [219,118,220,210,283,210,284,119], preFillColor: "pink"  },
    // { name: "3", shape: "poly", coords: [381,241,383,94,462,53,457,282], fillColor: "yellow"  },
    // { name: "4", shape: "poly", coords: [245,285,290,285,274,239,249,238], preFillColor: "red"  },
    // { name: "5", shape: "circle", coords: [170, 100, 25] },
    // { name: "room--RF", shape: "rect", coords: [147, 1336, 253, 1384] },
    { name: "building", shape: "rect", coords: [175, 280, 127, 305] },
    { name: "obuilding", shape: "rect", coords: [466, 18, 422, 43] },
    { name: "room room__B1--A", shape: "rect", coords: [135, 599, 82, 616] },
    { name: "room room__B1--B", shape: "rect", coords: [193, 599, 140, 616] },
    { name: "room room__B1--C", shape: "rect", coords: [235, 579, 199, 614] },
    { name: "room room__1F--A", shape: "rect", coords: [204, 534, 137, 552] },
    { name: "room room__2F--A", shape: "rect", coords: [232, 450, 189, 472] },
    { name: "room room__2F--D", shape: "rect", coords: [198, 470, 120, 489] },
    { name: "room room__3F--B", shape: "rect", coords: [232, 386, 189, 407] },
    { name: "room room__3F--D", shape: "rect", coords: [195, 402, 152, 423] },
    { name: "room room__3F--E", shape: "rect", coords: [125, 392, 82, 413] },
    { name: "room room--RF", shape: "rect", coords: [180, 337, 123, 355] },
    { name: "room room__O1F", shape: "rect", coords: [503, 528, 404, 546] },
    { name: "room room__O2F--A", shape: "rect", coords: [527, 453, 487, 472] },
    { name: "room room__O2F--B", shape: "rect", coords: [461, 463, 405, 482] },
    { name: "room room__O3F--A", shape: "rect", coords: [525, 387, 487, 407] },
    { name: "room room__O3F--B", shape: "rect", coords: [472, 394, 398, 413] },
    { name: "room room__O4F--A", shape: "rect", coords: [522, 324, 485, 344] },
    { name: "room room__O4F--B", shape: "rect", coords: [461, 334, 405, 353] },
    { name: "room room__O5F--A", shape: "rect", coords: [522, 260, 485, 280] },
    { name: "room room__O5F--B", shape: "rect", coords: [461, 271, 407, 290] },
    { name: "room room__O6F--A", shape: "rect", coords: [522, 197, 485, 217] },
    { name: "room room__O6F--B", shape: "rect", coords: [460, 207, 401, 225] },
    { name: "room room__O7F--A", shape: "rect", coords: [522, 128, 485, 147] },
    { name: "room room__O7F--B", shape: "rect", coords: [461, 141, 395, 160] },
    { name: "room room--ORF", shape: "rect", coords: [472, 70, 420, 90] },
  ]
}
export class SectionHeader2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0,
      lightboxIsOpen: false,
      photos: [],
      album: [],
      rowData: {},
      isOpen: false,
      imgSrc: ''
    };
  }

  componentDidMount = async () => {
    // const { companyObj } = this.props
    // // const companyId = companyObj.objectId
    // const data = await Parse.queryData('album', { companyId: 'aKDM0xR8py', isopen: '1' }, { sort: 'date_descend' });
    // this.setState({ album: data })
  }

  showImg = (obj) => {
    console.log(obj)
    switch (obj.name) {
      case 'building':
        this.setState({ imgSrc: `${path}1.jpg`, title: '本館', open: true })
        // item = {imgSrc: `${path}RF.jpg`, title: '休憩所(喫煙所)'};
        break;
      case 'obuilding':
        this.setState({ imgSrc: `${path}2.jpg`, title: '別館', open: true })
        // item = {imgSrc: `${path}RF.jpg`, title: '休憩所(喫煙所)'};
        break;
      case 'room room--RF':
        this.setState({ imgSrc: `${path}RF.jpg`, title: '休憩所', open: true })
        // item = {imgSrc: `${path}RF.jpg`, title: '休憩所(喫煙所)'};
        break;
      case 'room room__3F--E':
        this.setState({ imgSrc: `${path}3F_00.jpg`, title: '和室', open: true })
        break;
      case 'room room__3F--D':
        this.setState({ imgSrc: `${path}3F_D.jpg`, title: '舞台', open: true })
        break;
      // case 'room room__3F--C':
      //   this.setState({ imgSrc: `${path}3F_C.jpg`, title: '教室', open: true })
      //   break;
      // case 'room room__3F--B':
      //   this.setState({ imgSrc: `${path}3F_B.jpg`, title: '教室', open: true })
      //   break;
      case 'room room__3F--B':
        this.setState({ imgSrc: `${path}3F_A.jpg`, title: '教室', open: true })
        break;
      case 'room room__2F--D':
        this.setState({ imgSrc: `${path}2F_0.jpg`, title: 'セミナー室', open: true })
        break;
      // case 'room room__2F--C':
      //   this.setState({ imgSrc: `${path}2F_C.jpg`, title: '教室', open: true })
      //   break;
      // case 'room room__2F--B':
      //   this.setState({ imgSrc: `${path}2F_B.jpg`, title: '教室', open: true })
      //   break;
      case 'room room__2F--A':
        this.setState({ imgSrc: `${path}2F_A.jpg`, title: '教室', open: true })
        break;
      case 'room room__1F--A':
        this.setState({ imgSrc: `${path}1F_0.jpg`, title: '教職員室', open: true })
        break;
      case 'room room__B1--C':
        this.setState({ imgSrc: `${path}B1_0.jpg`, title: '文化教室', open: true })
        break;
      case 'room room__B1--B':
        this.setState({ imgSrc: `${path}B1_1.jpg`, title: '音樂室', open: true })
        break;
      case 'room room__B1--A':
        this.setState({ imgSrc: `${path}B1_2.jpg`, title: '図書室', open: true })
        break;
      case 'room room--ORF':
        this.setState({ imgSrc: `${path}ORF.jpg`, title: '休憩所', open: true })
        break;
      // case 'room room__O7F--B':
      //   this.setState({ imgSrc: `${path}O7F_B.jpg`, title: '学院長室', open: true })
      //   break;
      case 'room room__O7F--A':
        this.setState({ imgSrc: `${path}O5F_A.jpg`, title: '教室', open: true })
        break;
      // case 'room room__O6F--B':
      //   this.setState({ imgSrc: `${path}O6F_B.jpg`, title: '保健室', open: true })
      //   break;
      case 'room room__O6F--A':
        this.setState({ imgSrc: `${path}O4F_A.jpg`, title: '教室', open: true })
        break;
      case 'room room__O5F--B':
        this.setState({ imgSrc: `${path}O5F_B.jpg`, title: '小教室', open: true })
        break;
      case 'room room__O5F--A':
        this.setState({ imgSrc: `${path}O5F_A.jpg`, title: '教室', open: true })
        break;
      case 'room room__O4F--B':
        this.setState({ imgSrc: `${path}O4F_B.jpg`, title: '小教室', open: true })
        break;
      case 'room room__O4F--A':
        this.setState({ imgSrc: `${path}O4F_A.jpg`, title: '教室', open: true })
        break;
      case 'room room__O3F--A':
        this.setState({ imgSrc: `${path}O3F_A.jpg`, title: '教室', open: true })
        break;
      case 'room room__O2F--A':
        this.setState({ imgSrc: `${path}O2F_A.jpg`, title: '教室', open: true })
        break;
      case 'room room__O1F':
        this.setState({ imgSrc: `${path}O1F.jpg`, title: 'カフェスペース', open: true })
        break;
      default:
        break
    }
  }
  render() {
    const { form, cookies, num } = this.props;
    const { imgSrc } = this.state
    // console.log(form)
    const { text2 = '', text3 = '', img1File } = form;

    return (
      <>
        {num === 1 ? <>
          <Row>
            <Col>
              <h1 style={{ textAlign: 'center' }}>校舍</h1>
              <br />
              <br />
              <p>二棟の校舎が並立し、学生に快適な学習環境を提供しています。校内には、図書館、自習室を始め、進学指導室、文化体験教室（和室）、音楽室、保健室があります。各教室には全てパソコンがあり、教育のIT化にも力を入れています。
              </p>
              <br />
              <br />
              <br />
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: 'center' }} md='6' sm='12'>
              <ImageMapper
                src={'/images/aichiNew/aichiNewFloorMap.png'}
                map={MAP}
                width={600}
                justifyContent='center'
                // onLoad={() => this.load()}
                // style={{ cursor: 'pointer', margin: 0 }}
                onClick={area => this.showImg(area)}
                fillColor='rgba(255, 255, 255, 0.5)'
              // strokeColor='rgba(0, 0, 0, 0.5)'
              // onMouseEnter={area => this.enterArea(area)}
              // onMouseLeave={area => this.leaveArea(area)}
              // onMouseMove={(area, _, evt) => this.moveOnArea(area, evt)}
              // onImageClick={() => imgPreviewCol(photos)}
              // onImageMouseMove={evt => this.moveOnImage(evt)}
              />
            </Col>
            <Col style={{ textAlign: 'center' }} md='6' sm='12'>
              {imgSrc ? <img src={imgSrc} style={{
                width: '600px', height: '400px', userSelect: 'none', marginTop: '16%', marginLeft: '20%'
              }} alt="img" /> : null}
            </Col>
          </Row>
          <br />
          <br />
          <br />
        </> : num === 2 ? <><div style={{ textAlign: 'center' }}>
          <h2 style={{ fontWeight: 500, fontFamily: 'PMingLiU' }}>セミナーハウス</h2>
        </div>
          <p><br /><br /><br /><br />
          </p>
          <div style={{ display: 'flex' }}>
            <img src="/images/aichiNew/school1.jpg" style={{ width: '23%', height: '20%', marginLeft: '1%' }} alt="img" />
            <img src="/images/aichiNew/school2.jpg" style={{ width: '20%', height: '20%', marginLeft: '4%' }} alt="img" />
            <img src="/images/aichiNew/school3.jpg" style={{ width: '20%', height: '20%', marginLeft: '4%' }} alt="img" />
            <img src="/images/aichiNew/school4.jpg" style={{ width: '20%', height: '20%', marginLeft: '4%' }} alt="img" />
          </div>
          <p><br /><br /><br /><br />
          </p>
          <div>
            <p>日本語や日本文化を学びたい方、日本語を研究曾日本語教育をされている方、日本に来て自身の肌で日本を感じたい方々のために、実用的で多種多様な短期遊学のプログラムをリーズナブルに幅広く体験して頂ける短期コースを設けており、本セミナーハウスはそのための宿泊施設です。</p>
          </div></> : <Segment style={{ padding: '60px 50px 40px 20px' }}><div style={{ display: 'flex' }}>
            <img src="/images/aichiNew/school5.jpg" style={{ marginLeft: '2%', width: '400px', height: '600px' }} alt="img" />
            <div style={{ marginLeft: '8%' }}>
              <h1>学生寮</h1><p><br /></p><p><br /></p>
              <p><br /></p>
              <p>生活に必要な家具家電を備え、徒歩（自転車）で通学可能な範囲に学生寮があります。シングルルーム、ツインルーム、シェアハウスなど、色々なタイプの部屋があります。インターネット（WiFi）も完備。
              </p>
              <br />
              <div style={{ display: 'flex' }}>
                <div style={{ width: '200px' }}>部屋タイプ</div><div>寮費/月</div>
              </div>
              <br />
              <div style={{ display: 'flex' }}>
                <div style={{ width: '200px' }}>シングルルーム</div><div>45000～52000円</div>
              </div>
              <br />
              <div style={{ display: 'flex' }}>
                <div style={{ width: '200px' }}>ツインルーム</div><div>27000～35000円</div>
              </div>
              <br />
              <div style={{ display: 'flex' }}>
                <div style={{ width: '200px' }}>シェアハウス</div><div>20000～32000円  水道光熱費  別途精算</div>
              </div>
              <br />
              <div>※入寮費（家賃2ヶ月分）と退寮清掃料20000円を頂戴します。</div>
            </div>
          </div></Segment>}

      </>
    );
  }
}

export default SectionHeader2;
