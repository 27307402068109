import React, { Component } from 'react'
import { Header, Form, Grid, Menu, Input, Dimmer, Loader, Image, Button, Confirm, FormGroup } from 'semantic-ui-react';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MaterialTable from 'material-table'
import {
  // Container,
  // Table,
  Row,
  Col,
} from "reactstrap";
import { DelayInput } from 'react-delay-input';
import localization from 'widget/MaterialTableOpt'

import Parse from 'widget/parse'
import { mgyearOpt, monthOpt, exportPdf } from 'views/web/CustomMaterialTable';
// import localization from 'widget/MaterialTableOpt'
// import ModalNewClient from './ModalNewClient';
// import ModalHandle from './ModalHandle';
// import ClientTab from './ClientTab';
// const typeText = ['水淨膚', '美體雕塑', '皮漾技術', '牙齒美白']

const columns = () => ([
  // { width: 80, title: '項目', field: 'makeupType', lookup: { '0': '水淨膚', '1': '美體雕塑', '2': '皮漾技術', '3': '牙齒美白' } },
  { width: 80, title: '項目', field: 'courseName' }, // A:3 B:1
  { width: 80, title: '組別', field: 'section' },
  { width: 80, title: '開始時間', field: 'startTime' },
  { width: 80, title: '結束時間', field: 'endTime' },
  { width: 80, title: '每節長度(分)', field: 'periodTime' },
  // { width: 80, title: '電話', field: 'reservePs' },
]);

const columns2 = () => ([
  // { width: 80, title: '項目', field: 'makeupType', lookup: { '0': '水淨膚', '1': '美體雕塑', '2': '皮漾技術', '3': '牙齒美白' } },
  { width: 80, title: '組別', field: 'section' },
  { width: 80, title: '床數', field: 'bedNum' },
]);

class Reserve extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // loading: true,
      searchValue: '',
      objectId: '',
      clientObj: {},
      userAll: [],
      subscription1: null,
      employee: [],
      data1: [],
      data2: []
    }
    this.tableRef = React.createRef();
  }

  componentDidMount = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    let query = new Parse.Query('reserveData');
    // query.equalTo('isOpen', '1');
    let subscription1 = await query.subscribe();
    // let query2 = new Parse.Query('ordering2');
    // let subscription2 = await query2.subscribe();
    this.setState({
      subscription1
      // subscription2
    });
    this.handleFetch()

    subscription1.on('create', async (object) => {
      console.log('object created', object);
      const data = await Parse.fetchDocument('reserveData', object.id)
      const data1 = [...this.state.data1, data]
      this.setState({ data1 })
    });

    subscription1.on('update', async (object) => {
      // console.log('object updated', object);
      const data = await Parse.fetchDocument('reserveData', object.id)
      const data1 = [...this.state.data1, data]
      this.setState({ data1 })
    });
  }

  componentWillUnmount() {
    const { subscription1 } = this.state
    subscription1.unsubscribe();
  }

  handleFetch = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data1 = await Parse.queryData('product1', { companyId })
    const data2 = await Parse.queryData('product2', { companyId })
    this.setState({ data1, data2 })
  }

  handleAdd = async (type) => {
    const { newData, newData1 } = this.state
    if (type === '1') {
      await Parse.saveData('product1', newData);
    } else {
      await Parse.saveData('product2', newData1);
    }
  }

  handleUpdate = async (type) => {
    const { newData, newData1 } = this.state
    if (type === '1') {
      await Parse.saveData('product1', newData);
    } else {
      await Parse.saveData('product2', newData1);
    }
  }

  handleDelete = async (type) => {
    const { oldData, oldData1 } = this.state
    if (type === '1') {
      await Parse.deleteData('product1', oldData);
    } else {
      await Parse.deleteData('product2', oldData1);
    }

    this.setState({ oldData: {}, oldData1: {} })
  }

  clear = () => {
    this.ref.inputRef.current.value = '';
    this.setState({ searchValue: '' }, () => this.tableRef.current.onQueryChange())
  }

  render() {
    const { companyObj, user } = this.props
    const { searchValue, loading, data1, data2 } = this.state;

    return (<>
      <div className="content">
        <Row>
          <Col xs="12" sm="10">
            <MaterialTable
              // isLoading={loading}
              localization={localization()}
              columns={columns()}
              options={{
                addRowPosition: "first",
                exportButton: true,
                exportPdf: (columns, data) => exportPdf(columns, data, '設定'),
                pageSize: 5,
                search: false,
                tableLayout: 'fixed'
              }}
              data={data1}
              title="預約管理"
              actions={[
                {
                  icon: 'view_headline',
                  tooltip: '查看',
                  onClick: (event, rowData) => this.handleData(rowData)
                }
              ]}
              editable={{
                onRowAdd: newData =>
                  new Promise(async (resolve, reject) => {
                    const data = [...data1];
                    const db = Parse.Object.extend("product1");
                    const newDoc = new db();
                    const doc = await newDoc.save();
                    newData.objectId = doc.id;
                    newData.id = doc.id;
                    newData._id = doc.id;
                    newData.companyId = companyObj.objectId
                    newData.companyName = companyObj.name

                    data.push(newData);
                    this.setState({ data1: data, newData }, () => resolve());
                  }).then(() => this.handleAdd('1')),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    const data = [...data1];
                    const index = data.indexOf(oldData);
                    data[index] = newData;
                    // handleState('people0', data);
                    this.setState({ data1: data, newData, oldData }, () => resolve());
                  }).then(() => this.handleUpdate('1')),
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    let data = [...data1];
                    const index = data.indexOf(oldData);
                    data.splice(index, 1);
                    // handleState('people0', data);
                    this.setState({ data1: data, oldData }, () => resolve());
                  }).then(() => this.handleDelete('1')),
              }}
            />
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col xs="12" sm="10">
            <MaterialTable
              // isLoading={loading}
              localization={localization()}
              columns={columns2()}
              options={{
                addRowPosition: "first",
                exportButton: true,
                exportPdf: (columns, data) => exportPdf(columns, data, '設定'),
                pageSize: 5,
                search: false,
                tableLayout: 'fixed'
              }}
              data={data2}
              title=""
              // actions={[
              //   {
              //     icon: 'view_headline',
              //     tooltip: '查看',
              //     onClick: (event, rowData) => this.handleData(rowData)
              //   }
              // ]}
              editable={{
                onRowAdd: newData1 =>
                  new Promise(async (resolve, reject) => {
                    const data = [...data2];
                    const db = Parse.Object.extend("product2");
                    const newDoc = new db();
                    const doc = await newDoc.save();
                    newData1.objectId = doc.id;
                    newData1.id = doc.id;
                    newData1._id = doc.id;
                    newData1.companyId = companyObj.objectId
                    newData1.companyName = companyObj.name
                    // newData1.makeupTypeText = typeText[newData1.makeupType]

                    data.push(newData1);
                    console.log(newData1)
                    this.setState({ data2: data, newData1 }, () => resolve());
                  }).then(() => this.handleAdd('2')),
                onRowUpdate: (newData1, oldData1) =>
                  new Promise((resolve, reject) => {
                    const data = [...data2];
                    const index = data.indexOf(oldData1);
                    data[index] = newData1;
                    // handleState('people0', data);
                    this.setState({ data2: data, newData1, oldData1 }, () => resolve());
                  }).then(() => this.handleUpdate('2')),
                onRowDelete: oldData1 =>
                  new Promise((resolve, reject) => {
                    let data = [...data2];
                    const index = data.indexOf(oldData1);
                    data.splice(index, 1);
                    // handleState('people0', data);
                    this.setState({ data2: data, oldData1 }, () => resolve());
                  }).then(() => this.handleDelete('2')),
              }}
            />
          </Col>
        </Row>
        <br />
        {/* {clientObj && clientObj.objectId ? <ClientTab {...this.props} clientObj={clientObj} /> : null} */}
      </div>
    </>);
  }
}

export default Reserve;