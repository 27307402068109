import React, { Component } from 'react'
import { Container, Button, Icon, Form, Menu } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import _ from 'underscore'
// import Moment from 'moment';
import Parse from 'widget/parse'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

import localization from 'widget/MaterialTableOpt'
import UserAdd from './UserAdd'
import routes from 'routes.js'
// import AnnounceCenter from 'views/web/AnnounceCenter';

// const authObj = { '0': '禁止', '1': '讀取', '2': '讀取+編輯', '3': '讀取+編輯+刪除' }

// const columns = () => ([
//   { title: '在職狀態(登入)', field: 'is_auth', lookup: { '1': '在職', '0': '離職' } },
//   { title: '工號', field: 'jobNum' },
//   { title: '中文姓名', field: 'name' },
//   // { title: '英文姓名', field: 'engname' },
//   { title: '1 財務&績效管理', field: 'auth1', lookup: authObj },
//   { title: '2 人資管理', field: 'auth2', lookup: authObj },
//   { title: '3 客戶管理', field: 'auth3', lookup: authObj },
//   { title: '4 售後服務', field: 'auth4', lookup: authObj },
//   { title: '5 產品中心', field: 'auth5', lookup: authObj },
//   { title: '6 網站管理', field: 'auth6', lookup: authObj },
//   { title: '4-1 案件追蹤', field: 'auth7', lookup: authObj },
//   { title: '4-2 保費提醒', field: 'auth8', lookup: authObj },
//   { title: '4-3 產品配息調整', field: 'auth9', lookup: authObj },
//   { title: '設定', field: 'auth0', lookup: authObj },
// ]);

const userOpt = [
  { key: '0', text: '全部', value: '0' },
  { key: '1', text: '在職', value: '1' },
  { key: '2', text: '離職', value: '2' },
]

const authOpt = { '0': '無', '1': '讀', '2': '編輯', }

// const menu = [
//   { id: 'user1', content: '基本資料' },
//   { id: 'user2', content: '權限設定' },
//   { id: 'user3', content: '登入資訊' },
//   { id: 'user4', content: '操作記錄' },
//   // { id: 'user5', content: '刪除帳號' },
// ]

const columns = ({ companyObj, richMenuChannel }) => ([
  { width: 180, title: '使用者名稱', field: 'username', editable: 'never' },
  { width: 80, title: '工號', field: 'jobNum' },
  {
    width: 300, title: '開通碼 objectId', field: 'objectId', render: rowData => {
      let idObj = {}
      if (richMenuChannel.find(item => item.companyId === companyObj.objectId)) {
        richMenuChannel.filter(item => item.companyId === companyObj.objectId).forEach(({ objectId, rankId }) => { idObj = { ...idObj, [rankId]: objectId } });
        return <>
          老闆：{companyObj.objectId}-{idObj['1'] || ''}<br />
        員工：{companyObj.objectId}-{idObj['2'] || ''}<br />
        客人：{companyObj.objectId}<br />
        </>
      } else {
        richMenuChannel.filter(item => item.bgId === companyObj.webtypeId).forEach(({ objectId, rankId }) => { idObj = { ...idObj, [rankId]: objectId } });
        return <>
          老闆：{companyObj.objectId}-{idObj['1'] || ''}<br />
        員工：{companyObj.objectId}-{idObj['2'] || ''}<br />
        客人：{companyObj.objectId}<br />
        </>
      }
      // console.log(richMenu.filter(item => item.bgId === rowData.webtypeId))
      // console.log(idObj)


    }
  },
  { width: 80, title: '職稱', field: 'jobTitle' },
  { width: 80, title: '姓名', field: 'name' },
  { width: 80, title: '手機', field: 'phone' },
  // { width: 80, title: '電子信箱', field: 'email' },
  // { title: '介紹人', field: 'recommandName' },
  // { title: '優惠碼', field: 'discountCode' },
  // { title: '建立日期', field: 'createdAt', editable: 'never' },
  // { title: '最後更新日期', field: 'updatedAt', editable: 'never' },
  // { title: '使用者', field: 'authUser', type: 'boolean', width: 60 },
  { title: '在職', field: 'authLogin', type: 'boolean', width: 60 },
  // { title: '人資', field: 'authHr', type: 'boolean', width: 60 },
  // { title: '會計', field: 'authAccount', type: 'boolean', width: 60 },
  { title: '系統管理', field: 'authAdmin', type: 'boolean', width: 60 },
  { width: 160, title: '備註', field: 'ps' },
  { width: 120, title: '新增時間', field: 'createdAt', type: 'datetime', editable: 'never' },
  { width: 120, title: '更新時間', field: 'updatedAt', type: 'datetime', editable: 'never' },
]);


// const columns2 = () => ([
//   { width: 80, title: '姓名', field: 'name', editable: 'never' },
//   { width: 80, title: '工號', field: 'jobNum', editable: 'never' },
//   { width: 80, title: '客戶', field: 'auth1', type: 'boolean' },
//   { width: 80, title: '售後', field: 'auth2', type: 'boolean' },
//   { width: 80, title: '產品', field: 'auth3', type: 'boolean' },
//   { width: 80, title: '人資', field: 'auth4', type: 'boolean' },
//   { width: 80, title: '財務', field: 'auth5', type: 'boolean' },
//   { width: 80, title: '檔案', field: 'authFile', type: 'boolean' },
//   // { width: 80, title: '行業別', field: 'sectorText', editable: 'never' },
//   // { width: 120, title: '公司名(中)', field: 'companyName', editable: 'never' },
//   // { title: '使用者', field: 'authUser', type: 'boolean', width: 60 },
//   // { title: '登入', field: 'authLogin', type: 'boolean', width: 60 },
//   // { title: '人資', field: 'authHr', type: 'boolean', width: 60 },
//   // { title: '會計', field: 'authAccount', type: 'boolean', width: 60 },
//   // { title: '管理', field: 'authAdmin', type: 'boolean', width: 60 },
// ]);

// const getAuthArray = (company, user, flag) => company[flag] ? _.union(user[flag + 'A'], [company.objectId]) : _.without(user[flag + 'A'], company.objectId);
const getAuth = (id, arr) => (arr.length && arr.indexOf(id) !== -1)

export default class Users extends Component {
  state = {
    loginUser: this.props.user.profile.toJSON(),
    dataArrOri: [],
    dataArr: [],
    loading: false,
    loading2: false,
    companyArr: [],
    userObj: {},
    richMenuChannel: [],
    userArr: [],
    newData: {},
    oldData: {},
    newData2: {},
    oldData2: {},
    activeItem: 'user1',
    statusSel: '1'
  }

  async componentDidMount() {
    const { companyObj } = this.props
    const { loginUser } = this.state
    const companyId = companyObj.objectId
    const authPage = window.location.pathname.split('/')[2]
    const authUser = companyId + '_' + authPage
    let auth = {}
    if ((loginUser.authAdminA && loginUser.authAdminA.indexOf(companyId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2') || loginUser.infowin) {
      auth = {
        edit: true,
      }
    } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
      auth = {
        edit: false,
      }
    } else {
      auth = {
        edit: false,
      }
    }

    let userRoutes = []
    let userViewsRoutes = []
    if (routes && routes.length) {
      userRoutes = routes.filter(item => (item && item.webtypeArr && item.webtypeArr.indexOf(this.props.companyObj.webtypeId) !== -1) || item.webtypeArr === undefined)
    }
    userRoutes.forEach(item => {   //無"系統"內選項，有系統管理權限 即有"系統"的所有選項與權限
      if (item.state !== "SystemCollapse") {
        userViewsRoutes.push(...item.views)
      }
    })
    // userViewsRoutes = userViewsRoutes.filter(item => item.path.split('/')[1] !== 'AnnounceCenter' || item.path.split('/')[1] !== 'AlbumCenter')
    // console.log(userViewsRoutes)
    // console.log(userMiniRoutes)
    // const authColumns = []
    const authMiniColumns = []
    // for (let i = 0; i < userRoutes.length; i++) {
    //   let obj = {
    //     width: 50,
    //     title: userRoutes[i].name,
    //     field: companyObj.webtypeId + '_' + userRoutes[i].state,
    //     lookup: authOpt,
    //   }
    //   authColumns.push(obj)
    // }
    // console.log(userViewsRoutes)
    for (let i = 0; i < userViewsRoutes.length; i++) {
      let obj = {
        width: 50,
        title: userViewsRoutes[i].name,
        field: userViewsRoutes[i] && userViewsRoutes[i].defaultPath ? companyId + '_' + userViewsRoutes[i].path.split('/')[1] + '_' + userViewsRoutes[i].defaultPath.split('/')[1]
          : companyId + '_' + userViewsRoutes[i].path.split('/')[1],
        lookup: authOpt,
      }
      authMiniColumns.push(obj)
    }
    console.log(authMiniColumns)
    // this.setState({ authColumns, authMiniColumns })
    const richMenuChannel = await Parse.queryData('richMenuChannel');
    this.setState({ authMiniColumns, richMenuChannel, auth }, () => this.handleFetch())
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj } = props;

    const users = await Parse.queryData("User", { authLoginA: companyObj.objectId });
    // console.log(users)
    const dataArrOri = users.map(element => ({
      ...element,
      authAdmin: getAuth(companyObj.objectId, element.authAdminA || []),
      authUser: getAuth(companyObj.objectId, element.authUserA || []),
      authLogin: getAuth(companyObj.objectId, element.authLoginA || []),
      // client: getAuth(companyObj.objectId, element.clientA || []),
      // afterSale: getAuth(companyObj.objectId, element.afterSaleA || []),
      // products: getAuth(companyObj.objectId, element.productsA || []),
      // hr: getAuth(companyObj.objectId, element.hrA || []),
      // finance: getAuth(companyObj.objectId, element.financeA || []),
      // file: getAuth(companyObj.objectId, element.fileA || []),
    }))


    this.setState({ dataArrOri, dataArr: dataArrOri })
  }

  getfilterUsers = () => {
    const { companyObj } = this.props
    const { statusSel, dataArrOri = [] } = this.state

    let dataArr = [];
    if (statusSel === '0') {
      dataArr = dataArrOri;
    } else if (statusSel === '1') {
      dataArr = dataArrOri.filter(item => item.authLoginA && item.authLoginA.indexOf(companyObj.objectId) !== -1)
    } else if (statusSel === '2') {
      dataArr = dataArrOri.filter(item => !item.authLoginA || item.authLoginA.indexOf(companyObj.objectId) === -1)
    }
    this.setState({ dataArr });
  }

  handleUpdate = async () => {
    const getAuthArray = (company, user, flag) => user[flag] ? _.union(user[flag + 'A'], [company.objectId]) : _.without(user[flag + 'A'], company.objectId);
    const userToRoleNamed = async (user, flag, roleType) => user[flag] ? await Parse.Cloud.run("addUserToRoleNamed", { userId: user.objectId, roleType }) : await Parse.Cloud.run("deleteUserToRoleNamed", { userId: user.objectId, roleType })
    const { companyObj } = this.props
    const { newData } = this.state

    const userObj2 = newData;
    if (!(userObj2.ACL && userObj2.ACL['role:SuperAdmin'])) {
      // console.log('notSUPER')
      if (!userObj2['authAdmin']) {   //若無Admin權限，照方程式設定有打勾新增無打勾移除relation。若有Admin權限，強制將Staff的relation移除。
        userToRoleNamed(userObj2, 'authAdmin', 'Admin')
        userToRoleNamed(userObj2, 'authLogin', 'Staff')
        const query = new Parse.Query('User');
        query.equalTo("objectId", userObj2.objectId)
        const snap = await query.first()
        const userACL = new Parse.ACL();
        userACL.setRoleWriteAccess('Staff', true);
        userACL.setPublicReadAccess(true);
        snap.setACL(userACL);
        await snap.save()
      } else {
        userToRoleNamed(userObj2, 'authAdmin', 'Admin')
        await Parse.Cloud.run("deleteUserToRoleNamed", { userId: userObj2.objectId, roleType: 'Staff' })
        const query = new Parse.Query('User');
        query.equalTo("objectId", userObj2.objectId)
        const snap = await query.first()
        const userACL = new Parse.ACL();
        userACL.setRoleWriteAccess('Admin', true);
        userACL.setPublicReadAccess(true);
        snap.setACL(userACL);
        await snap.save()
      }
    }
    userObj2.authAdminA = getAuthArray(companyObj, userObj2, 'authAdmin')

    // userObj2.authHrA = getAuthArray(companyObj, userObj2, 'authHr')
    // userObj2.authAccountA = getAuthArray(companyObj, userObj2, 'authAccount')
    userObj2.authUserA = getAuthArray(companyObj, userObj2, 'authUser')
    userObj2.authLoginA = getAuthArray(companyObj, userObj2, 'authLogin')
    userObj2.authAllA = _.union(userObj2.authAdminA,
      //  userObj2.authHrA, userObj2.authAccountA, 
      userObj2.authUserA);

    delete userObj2.authAdmin;
    // delete userObj2.authHr;
    // delete userObj2.authAccount;
    delete userObj2.authUser;
    delete userObj2.authLogin;
    // console.log(userObj2)
    // await db.doc(`users/${newData.id}`).set(userObj2);
    await Parse.saveData("User", userObj2)
    this.setState({ oldData: {}, newData: {} })
  }

  // handleDelete = async (id) => {
  //   const { firebase } = this.props
  //   const { oldData } = this.state
  //   const db = firebase.firestore()

  //   await db.collection(`users`).doc(oldData.id).delete();
  //   this.setState({ oldData: {} })
  // }

  handleData = (userObj) => {
    // const { companyArr } = this.state
    // const { authAdminA = [], authHrA = [], authAccountA = [], authUserA = [], authLoginA = [] } = userObj;

    // const companyArr2 = companyArr.map(item => ({
    //   ...item,
    //   authAdmin: getAuth(item._id, authAdminA),
    //   authHr: getAuth(item._id, authHrA),
    //   authAccount: getAuth(item._id, authAccountA),
    //   authUser: getAuth(item._id, authUserA),
    //   authLogin: getAuth(item._id, authLoginA),
    // }));
    // const { userArr } = this.state
    const { authObj = {} } = userObj
    const userArr = []
    userArr.push(authObj)
    this.setState({ userObj, userArr, loading2: false });
  }

  handleUpdateUserAuth = async () => {
    const { companyObj } = this.props
    const { newData2 } = this.state
    const getAuthArray = (company, user, flag) => user[flag] ? _.union(user[flag + 'A'], [company.objectId]) : _.without(user[flag + 'A'], company.objectId);
    const userObj2 = newData2;
    userObj2.authAdminA = getAuthArray(companyObj, userObj2, 'authAdmin')
    userObj2.clientA = getAuthArray(companyObj, userObj2, 'client')
    userObj2.afterSaleA = getAuthArray(companyObj, userObj2, 'afterSale')
    userObj2.productsA = getAuthArray(companyObj, userObj2, 'products')
    userObj2.hrA = getAuthArray(companyObj, userObj2, 'hr')
    userObj2.financeA = getAuthArray(companyObj, userObj2, 'finance')
    userObj2.fileA = getAuthArray(companyObj, userObj2, 'file')

    // userObj2.authHrA = getAuthArray(companyObj, userObj2, 'authHr')
    // userObj2.authAccountA = getAuthArray(companyObj, userObj2, 'authAccount')
    userObj2.authUserA = getAuthArray(companyObj, userObj2, 'authUser')
    userObj2.authLoginA = getAuthArray(companyObj, userObj2, 'authLogin')
    userObj2.authAllA = _.union(userObj2.authAdminA,
      //  userObj2.authHrA, userObj2.authAccountA, 
      userObj2.authUserA);

    delete userObj2.authAdmin;
    delete userObj2.clent;
    delete userObj2.afterSale;
    delete userObj2.products;
    delete userObj2.hr;
    delete userObj2.finance;
    delete userObj2.file;
    // delete userObj2.authHr;
    // delete userObj2.authAccount;
    delete userObj2.authUser;
    delete userObj2.authLogin;
    // console.log(userObj2)
    // await db.doc(`users/${newData.id}`).set(userObj2);
    // await Parse.saveData("User", userObj2)
    // const { firebase } = this.props;
    // const { userObj, newData2 } = this.state
    // const db = firebase.firestore()
    // // console.log(companyArr, userObj, newData2)

    // const userObj2 = userObj;
    // userObj2.authAdminA = getAuthArray(newData2, userObj2, 'authAdmin')
    // userObj2.authHrA = getAuthArray(newData2, userObj2, 'authHr')
    // userObj2.authAccountA = getAuthArray(newData2, userObj2, 'authAccount')
    // userObj2.authUserA = getAuthArray(newData2, userObj2, 'authUser')
    // userObj2.authLoginA = getAuthArray(newData2, userObj2, 'authLogin')
    // userObj2.authAllA = _.union(userObj2.authAdminA, userObj2.authHrA, userObj2.authAccountA, userObj2.authUserA);

    // await db.doc(`users/${userObj2.id}`).update(userObj2);
  }

  resetPW = () => {
    const { firebase } = this.props;
    const auth = firebase.auth();
    auth.sendPasswordResetEmail('yinchen618@gmail.com').then(function () {
      // Email sent.
    }).catch(function (error) {
      // An error happened.
    });
  }
  handleItemClick = (e, { name }) => {
    // const { activeItem, activeItem2 } = this.state
    // console.log(bgId)
    // const menu2 = (menu.find(item => item.id === name) && menu.find(item => item.id === name).menu) || [];
    // this.props.history.push(`/Financial1/${bgId}/${name}/${menu2[0].id}`);
    this.setState({ activeItem: name })
  }
  handleChange = (e, { name, value }) => {
    // const { statusSel } = this.state
    this.setState({ statusSel: value }, () => this.getfilterUsers())
  }
  handleChange1 = (e, { name, value }) => {
    const { userObj } = this.state;
    this.setState({ userObj: { ...userObj, [name]: value } })
  }

  sendMailTest = () => {
    const { firebase } = this.props;
    const db = firebase.firestore()

    // var newDateObj = Moment(new Date()).add(3, 'm').toDate();

    db.collection('mail').add({
      // delivery: {
      //   state: 'PROCESSING',
      //   leaseExpireTime: newDateObj
      // },
      from: '666 <no-reply@gmail.com>',
      replyTo: 'rrr <rrr@infowin.com.tw>',
      to: 'yinchen618@gmail.com',
      message: {
        subject: `rrr`,
        html: `666234您的帳號：122223`,
      }
    }).then(() => {
      window.alert('已成功寄出')
      console.log('Queued email for delivery!')
      // res.status(200).send('Email sent successfull!')
    })
  }

  handleAuth = async () => {
    const { companyObj } = this.props
    const { newData2, userObj } = this.state
    // console.log(newData2)
    const user = {
      ...userObj
    }
    delete user.authAdmin
    delete user.authUser
    delete user.authLogin
    let obj = {
      ...user,
      authObj: {
        ...newData2
      },
    }
    await Parse.saveData("User", obj)
    this.setState({ loading2: false })
  }

  render() {
    const { companyObj } = this.props;
    const { dataArr, userObj, loading, statusSel, userArr, richMenuChannel, authMiniColumns, loading2, auth = {} } = this.state;
    const columns3 = () => authMiniColumns
    return (<>
      <div className="content">
        <Form>
          <Form.Group>
            <Form.Select style={{ position: 'relative', zIndex: 110 }}
              loading={loading}
              options={userOpt}
              onChange={this.handleChange}
              value={statusSel}
              name='statusSel' />
          </Form.Group>
        </Form>
        <br />
        <MaterialTable
          loading={loading}
          components={{
            Toolbar: props => (<div>
              <div style={{ width: '150px', float: 'right', textAlign: 'right', padding: '15px' }}>
                {auth.edit ? <UserAdd
                  {...this.props}
                  refetch={this.handleFetch}
                /> : null}
              </div>
              <MTableToolbar {...props} />
              <div style={{ clear: 'both' }}></div>
            </div>),
          }}
          localization={localization()}
          columns={columns({ companyObj, richMenuChannel })}
          options={{
            pageSize: 5,
            search: true,
            tableLayout: 'fixed',
          }}
          data={dataArr}
          actions={[
            {
              icon: 'view_headline',
              tooltip: '查看',
              onClick: (event, rowData) => this.setState({ loading2: true }, () => this.handleData(rowData))
            }
          ]}
          onRowClick={(event, rowData) => this.setState({ loading2: true }, () => this.handleData(rowData))}
          title={companyObj.name + ' 使用者'}
          editable={{
            isEditHidden: () => !auth.edit,
            // onRowAdd: newData =>
            //   new Promise((resolve, reject) => {
            //     const db = firebase.firestore();
            //     const docRef = db.collection('users').doc();
            //     const data = [...dataArr];

            //     const obj = {
            //       _id: docRef.id,
            //       id: docRef.id,
            //       ...newData,
            //       createdDate: date,
            //       createdAt: new Date(),
            //     }
            //     data.push(obj);
            //     this.setState({ dataArr: data, newData: obj }, () => resolve());
            //   }).then(() => this.handleAdd()),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const data = [...dataArr];
                const index = data.indexOf(oldData);
                data[index] = newData;
                const obj = {
                  ...newData,
                  // updatedDate: date,
                  updatedAt: new Date(),
                }
                this.setState({ dataArr: data, newData: obj, oldData }, () => resolve());
              }).then(() => this.handleUpdate()),
            // onRowDelete: oldData =>
            //   new Promise((resolve, reject) => {
            //     let data = [...dataArr];
            //     const index = data.indexOf(oldData);
            //     data.splice(index, 1);
            //     this.setState({ dataArr: data, oldData }, () => resolve());
            //   }).then(() => this.handleDelete()),
          }}
        />
        <br />
        {userObj && userObj.objectId ?
          <MaterialTable
            isLoading={loading2}
            columns={columns3()} options={{
              addRowPosition: "first",
              exportButton: true,
              exportPdf: (columns, data) => exportPdf(columns, data, `權限一覽 - ${userObj.name}`),
              pageSize: 1,//data1.length || 5,
              search: false,
              tableLayout: 'fixed',
            }}
            data={userArr}
            title={`權限一覽 - ${userObj.name}`}
            editable={{
              isEditHidden: () => !auth.edit,
              onRowUpdate: (newData2, oldData2) =>
                new Promise((resolve, reject) => {
                  const data = [...userArr];
                  const index = data.indexOf(oldData2);
                  data[index] = newData2;
                  // console.log(data, newData)
                  this.setState({ userArr: data, newData2, oldData2, loading2: true }, () => resolve());
                }).then(() => this.handleAuth()),
              // }).then(() => this.handleUpdateUserAuth()),
            }}
          /> : null}
      </div>
    </>)
  }
}