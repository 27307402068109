import React, { Component } from 'react'
import { Container, Header, Modal, Button, Table, Form, Icon, Select, Checkbox, Grid } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import intl from 'react-intl-universal';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';


const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

const columns = () => ([
  { title: '產品名稱', field: 'name_cht', render: rowData => rowData.value ? rowData.value : rowData.name_cht, editable: 'never' },
  { title: '英文名稱', field: 'name_eng', editable: 'never' },
  {
    title: '檔案 ', field: 'src', render: rowData => {
      let showArr = []
      if (rowData && rowData.attachments && rowData.attachments.length) {
        rowData.attachments.forEach(item => {
          showArr.push(<a href={item.src} target="_blank" rel="noopener noreferrer">{item.filename}</a>)
          showArr.push(<br />)
        })
        return showArr
      }
    }, editable: 'never'
  }
]);

const columns2 = () => ([
  { title: '檔案名稱', field: 'src', render: rowData => <a href={rowData.src} target='_blank'>{rowData.filename} </a>, editable: 'never' },
]);

export default class Product2 extends Component {
  state = {
    loginUser: this.props.user.profile.toJSON(),
    dataArr: [],
    dataArr2: [],
    dataArr3: [],
    dataArr4: [],
    dataAllArr: [],
    fileArr: [],
    fileArrCheck: [],
    filenames: [],
    downloadURLs: [],
    downloadURLsArr: [],
    dataSel: [],
    dataId: '',
    rowData: {},
    confirmDel: false,
    delDataArr: [],
    rows: [],
  }

  componentDidMount() {
    const { loginUser } = this.state
    const { companyObj } = this.props
    const authPage = window.location.pathname.split('/')[2]
    const authUser = companyObj.objectId + '_' + authPage
    let auth = {}

    if ((loginUser.authAdminA && loginUser.authAdminA.indexOf(companyObj.objectId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
      auth = {
        edit: true,
      }
    } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
      auth = {
        edit: false,
      }
    } else {
      auth = {
        edit: false,
      }
    }
    this.setState({ auth }, () => this.handleFetch())
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps, 0);
  }

  handleFetch = async (props = this.props, type = 1) => {
    const { companyObj } = props;
    const companyId = companyObj.objectId;
    const product1 = await Parse.queryData('product1', { companyId });
    const product2 = await Parse.queryData('product2', { companyId });
    const product3 = await Parse.queryData('product3', { companyId });
    const product4 = await Parse.queryData('product4', { companyId });

    const allArr = product2.concat(product3, product4)
    let dataAllArr = []
    const fileCenter2 = await Parse.queryData('fileCenter2', { companyId, type: 'product' }).then(fileCenter2 => {
      dataAllArr = allArr.map(item => {
        const filData = fileCenter2.filter(item2 => item2.fileCenter1Id === item.objectId)
        const attachments = filData.map(item2 => ({ id: item2.objectId, src: item2.file1.url, filename: item2.name }))
        let obj = {
          ...item,
          attachments
        }
        return obj
      })
    })
    if (type === 1) {
      let dataSel = product1.map(item => ({ key: item.objectId, text: `${item.value}`, value: item.objectId }))
      this.setState({ dataSel, dataId: (dataSel && dataSel.length && dataSel[0] && dataSel[0].value) || '' })
    }
    this.setState({ dataArr: product1, dataArr2: product2, dataArr3: product3, dataArr4: product4, dataAllArr, loading: false, rowData: [] })
  }

  handleUpdate = async () => {
    const { newData } = this.state
    let obj = {}
    obj = {
      ...newData,
      tableData: {},
    }
    if (obj.product1_id && obj.product2_id && obj.product3_id) {
      await Parse.saveData('product4', obj);
    } else if (obj.product1_id && obj.product2_id && obj.product3_id === undefined) {
      await Parse.saveData('product3', obj);
    } else if (obj.product1_id && obj.product2_id === undefined && obj.product3_id === undefined) {
      await Parse.saveData('product2', obj);
    }
    this.setState({ oldData: {}, newData: {}, loading: false })
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    this.setState({ [name]: value, loading: false });
  }


  render() {
    const { loading, rowData, dataAllArr, dataSel, dataId, confirmDel, delDataArr, rows, auth } = this.state;
    let displayArr = dataAllArr.filter(item => item.product1_id === dataId)
    return (<div className='content'>
      <Grid>
        <Grid.Row>
          <Grid.Column md={{ size: 6, offset: 3 }}>
            {/* <Header as='h2' style={{ margin: '2rem auto' }}>3-2 產品比較</Header> */}
            <br />
            <Select style={{
              position: 'relative', zIndex: 99,
              float: 'left',
              minWidth: '200px',
              // borderTopLeftRadius: '0',
              // borderBottomLeftRadius: '0'
            }}
              compact
              name="dataId"
              options={dataSel}
              value={dataId}
              onChange={this.handleChange}
            />
            <br />
            <br />
            <br />
            <MaterialTable
              isLoading={loading && dataAllArr}
              localization={localization()}
              tableRef={this.tableRef}
              columns={[
                ...columns(),
              ]}
              parentChildData={(row, rows) => {
                if (row.product3_id && rows.find(item => item.objectId === row.product3_id)) {
                  return rows.find(item => item.objectId === row.product3_id)
                } else if (row.product2_id && rows.find(item => item.objectId === row.product2_id)) {
                  return rows.find(item => item.objectId === row.product2_id)
                } else if (row.product1_id && rows.find(item => item.objectId === row.product1_id)) {
                  return rows.find(item => item.objectId === row.product1_id)
                }
              }
              }
              options={{
                addRowPosition: "first",
                exportButton: true,
                exportAllData: true,
                exportPdf: (columns, data) => exportPdf(columns, data, '產品比較'),
                pageSize: dataAllArr.length || 1,
                search: false,
                toolbar: false,
                paging: false,
              }}
              data={displayArr}
              title="產品比較"
            // editable={{
            //   isEditHidden: () => !auth.edit,
            //   onRowUpdate: (newData, oldData) =>
            //     new Promise((resolve, reject) => {
            //       const data = dataAllArr;
            //       const index = data.indexOf(oldData);
            //       data[index] = newData;
            //       // handleState('people0', data);
            //       this.setState({ dataAllArr: data, newData, oldData, loading: true }, () => resolve());
            //     }).then(() => this.handleUpdate()),
            // }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div >)
  }
}

// handleFileDelete = async (e, { data }) => {
//   const {refetch, companyObj } = this.props
//   const db = firebase.firestore()
//   let obj = {}
//   // if (rowData.publishAt_time === '' || rowData.publishAt_time === null || rowData.insertedAt_time === '' || rowData.insertedAt_time === null) {
//   // obj = {
//   //   id: rowData.id,
//   //   _id: rowData.id,
//   //   value: rowData.value || '',
//   //   towhom: rowData.towhom || '',
//   //   towhom_text: rowData.towhom ? towhomSel[rowData.towhom].text : '',
//   //   publishAt_time: (rowData.publishAt_time !== '' && rowData.publishAt_time !== null && typeof rowData.publishAt_time === 'object' && rowData.publishAt_time.toISOString()) || (rowData.publishAt_time !== '' && rowData.publishAt_time !== null && typeof rowData.publishAt_time === 'string' && rowData.publishAt_time) || '',
//   //   insertedAt_time: (rowData.insertedAt_time !== '' && rowData.insertedAt_time !== null && typeof rowData.insertedAt_time === 'object' && rowData.insertedAt_time.toISOString()) || (rowData.insertedAt_time !== '' && rowData.insertedAt_time !== null && typeof rowData.insertedAt_time === 'string' && rowData.insertedAt_time) || '',
//   // }
//   let tableData = {}
//   if (window.confirm('確定要刪除檔案?')) {
//     // obj = { ...data, tableData }
//     // delete obj.fakefilename
//     // delete obj.filename
//     // delete obj.lastModified
//     // delete obj.lastModifiedDate
//     // delete obj.webkitRelativePath
//     // delete obj.size
//     // delete obj.type
//     // delete obj.src
//     // } else {
//     //   obj = {
//     //     id: rowData.id,
//     //     _id: rowData.id,
//     //     value: rowData.value || '',
//     //     towhom: rowData.towhom || '',
//     //     towhom_text: rowData.towhom ? towhomSel[rowData.towhom].text : '',
//     //     publishAt_time: rowData.publishAt_time && typeof rowData.publishAt_time === "string" ? rowData.publishAt_time : rowData.publishAt_time.toISOString(),
//     //     insertedAt_time: rowData.insertedAt_time && typeof rowData.insertedAt_time === "string" ? rowData.insertedAt_time : rowData.insertedAt_time.toISOString(),
//     //   };
//     // }
//     // await firebase.storage().ref("productFiles").child(data.fakefilename).delete();
//     // await db.collection(`company/${companyObj._id}/product1/${obj.product1_id}/product2/${obj.product2_id}/product3/${obj.product3_id}/product4`).doc(obj.id).set(obj);
//     // await refetch('Product')
//     // window.alert('刪除成功')
//     // this.setState({ loading: true }, () => this.handleFetch())
//   }

// }