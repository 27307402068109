import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

// import RowsDnd from './RowsDnd'
import { DelayInput } from 'react-delay-input';

// reactstrap components
import { Row, Col, Form, FormGroup, Input, Label, Button } from "reactstrap";
// import CurStudentInfo from "./CurStudentInfo";

const columns = () => ([
  { width: 80, title: '使用者名稱', field: 'userName' },
  { width: 80, title: '測驗日期', field: 'testDate' },
  { width: 80, title: '總成績', field: 'totalGrades' },
  { width: 80, title: '聽力成績', field: 'listenGrades' },
  { width: 80, title: '閱讀成績', field: 'readGrades' },
  { width: 80, title: '耗費時間', field: 'timeSpend' },
]);

class UserGradesSearch extends Component {
  state = {
    activeItem: 'bio',
    dataArr: [],
    rowData: {},
    loading: true
  }

  async componentDidMount() {
    const data1 = await Parse.queryData('UserGrades');
    this.setState({ dataArr: data1, loading: false });
  }

  handleAdd = async () => {
    const { newData } = this.state;
    Parse.saveData('UserGrades', newData);
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    Parse.saveData('UserGrades', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    Parse.deleteData('UserGrades', oldData);
    this.setState({ oldData: {} })
  }


  handleItemClick = (e, { name }) =>
    this.setState({
      activeItem: name
    })

  handleData = (rowData) => {
    this.setState({ rowData });
  }

  render() {

    const { dataArr, rowData, loading } = this.state
    return (<div className="content">
      <Row>
        <Col><legend>2-1 使用者成績查詢 </legend></Col>
      </Row>
      <div style={{ margin: '15px' }}>
        <Row>
          <Form>
            <FormGroup widths='equal'>
              <DelayInput
                inputRef={ref => { this.ref = ref; }}
                name='searchValue'
                placeholder='請輸入使用者'
                style={{ width: '100%', minWidth: 350, float: 'left' }}
                onChange={(e) => this.handleChange(e, { name: 'searchValue', value: e.target.value })}
                // minLength={1}
                delayTimeout={300}
                action={{ icon: 'cancel', onClick: this.clear }}
                element={Input}
              />
            </FormGroup>
          </Form>
        </Row>
      </div>
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '設定'),
          pageSize: 10,
          search: false,
          tableLayout: 'fixed'
        }}
        data={dataArr}
        title="設定"
        // actions={[
        //   {
        //     icon: 'view_headline',
        //     tooltip: '查看',
        //     onClick: (event, rowData) => this.handleData(rowData)
        //   }
        // ]}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const data = [...dataArr];
              const db = Parse.Object.extend("UserGrades");
              const newDoc = new db();
              const doc = await newDoc.save();
              newData.objectId = doc.id;
              newData.id = doc.id;
              newData._id = doc.id;

              data.push(newData);
              this.setState({ dataArr: data, newData }, () => resolve());
            }).then(() => this.handleAdd()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...dataArr];
              const index = data.indexOf(oldData);
              data[index] = newData;
              // handleState('people0', data);
              this.setState({ dataArr: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...dataArr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              // handleState('people0', data);
              this.setState({ dataArr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
      <br />
      <br />
    </div >);
  }
}

export default UserGradesSearch;