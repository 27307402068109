import React, { Component } from 'react'
import { Header, Form, Grid, Menu, Input, Dimmer, Loader, Image, Button, Tab, FormGroup } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import { DelayInput } from 'react-delay-input';
import { Row, Col, } from "reactstrap";

// import ModalAutoEmergencyAddr from './ModalAutoEmergencyAddr'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

import Client1a from './Client1a';
import Client1b from './Client1b';
import ModalNewClient from './ModalNewClient';
import ModalAutoAddr from './ModalAutoAddr'

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

const columns = ({ thatProps, roomSel = {} }) => ([
  dateCol({ width: 80, field: 'checkInDate', title: '簽約日期' }),
  dateCol({ width: 80, field: 'checkOutDate', title: '退租日期' }),
  { width: 60, field: 'roomId', title: '房號', lookup: roomSel },
  { width: 60, field: 'name', title: '姓名' },
  { width: 90, field: 'idcardNum', title: '身分證字號' },
  // { width: 80, field: 'job', title: '職業' },
  // { width: 80, field: 'type', title: '類別' },
  { width: 60, field: 'phone', title: '電話' },
  { width: 60, field: 'cellphone', title: '手機' },
  // { width: 80, field: 'fax', title: '傳真' },
  {
    width: 80, title: '選擇(代入郵遞區號)', field: 'postcode_select',
    headerStyle: { textAlign: 'center' },
    render: rowData => <></>,
    editComponent: props => <ModalAutoAddr {...thatProps} field='addr' rowData={props.rowData} onRowDataChange={props.onRowDataChange} />
  },
  { width: 80, title: '郵遞區號', field: 'postcode' },
  { width: 140, title: '戶籍地址', field: 'addr', cellStyle: { width: '250px' }, headerStyle: { width: '250px' } },
  { width: 70, field: 'school', title: '學校' },
  { width: 60, field: 'department', title: '系所' },
  { width: 50, field: 'grade', title: '年級' },
  { width: 90, field: 'emergency', title: '緊急聯絡人' },
  { width: 60, field: 'relationship', title: '關係' },
  { width: 60, field: 'emergencyCellphone', title: '手機' },
  { width: 60, field: 'emergencyPhone', title: '電話' },
  {
    width: 80, title: '選擇(代入郵遞區號)', field: 'postcode_select',
    headerStyle: { textAlign: 'center' },
    render: rowData => <></>,
    editComponent: props => <ModalAutoAddr {...thatProps} field='emergencyAddr' rowData={props.rowData} onRowDataChange={props.onRowDataChange} />
  },
  { width: 80, title: '郵遞區號', field: 'postcode' },
  { width: 140, title: '地址', field: 'emergencyAddr', cellStyle: { width: '250px' }, headerStyle: { width: '250px' } },
  { width: 60, field: 'ps', title: '備註' },
]);

const fetchPeople = async (companyObj, params, searchText, user) => {
  const { page = 1, pageSize = 5 } = params
  const { profile } = user
  // console.log(params, searchText)

  let query = new Parse.Query('clients');

  if (searchText) {
    const p0 = new Parse.Query("clients");
    // p0.fullText("name", searchText);
    p0.startsWith("name", searchText);

    const p1 = new Parse.Query("clients");
    p1.startsWith("cellnum", searchText);

    const p2 = new Parse.Query("clients");
    p2.startsWith("roomNum", searchText);

    query = Parse.Query.or(p0, p1, p2);
  }
  // query.select(['uid', 'name', 'residentialAddr', 'cellnum', 'residentialPhone', 'sexualId']);
  query.equalTo("companyId", companyObj.objectId);

  const totalCount = await query.count();

  // if (params.sorter) {
  //   const s = params.sorter.split('_');

  //   if (s[1] === 'descend') {
  //     query.descending(s[0]);
  //   } else {
  //     query.ascending(s[0]);
  //   }

  //   // Sorts the results in descending order by the score field
  // }
  query.limit(pageSize);
  query.skip((Number(page)) * (pageSize));

  const snap = await query.find();
  const data = snap.map(data => data.toJSON()).sort((a, b) => ('' + a.roomNum).localeCompare(b.roomNum));
  return { data, page, totalCount, }
}

class dormClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataArr: [],
      rowData: {},
      searchValue: '',
      clientObj: {},
    }
    this.tableRef = React.createRef();
  }
  async componentDidMount() {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data1 = await Parse.queryData('room', { companyId });
    let data = { '-1': '請選擇' };
    data1.forEach(item => data = { ...data, [item.objectId]: item.roomNum });

    this.setState({ roomArr: data1, roomSel: data }, () => this.handleFetch());
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.match.params || nextProps.match.params.clientId !== this.props.match.params.clientId) {
      this.handleFetch(nextProps);
    }
  }

  handleFetch = async (props = this.props) => {
    const { match } = props;
    const { clientId } = match.params;
    if (clientId) {
      const clientObj = await Parse.fetchDocument("clients", clientId);
      this.setState({ clientObj })
    } else {
      this.setState({ clientObj: {} })
    }
  }

  handleAdd = async () => {
    const { newData, id } = this.state
    Parse.saveData('clients', newData);
  }

  handleUpdate = async () => {
    const { newData, id } = this.state
    Parse.saveData('clients', newData);
    this.setState({ loading: false }, () => this.tableRef.current.onQueryChange());
  }

  handleDelete = async () => {
    const { oldData, id } = this.state
    Parse.deleteData('clients', oldData);
    this.setState({ loading: false, oldData: {} }, () => this.tableRef.current.onQueryChange())
  }

  handleClickName = async (clientId) => {
    const { history, match } = this.props;
    const { t1 = 'p2' } = match.params;
    history.push(`/admin/dormClient/${clientId}/${t1}`)
  }

  handleChange = async (e, { name, value }) => {
    this.setState({ [name]: value }, () => this.tableRef.current.onQueryChange());
  }

  clear = () => {
    this.ref.inputRef.current.value = '';
    this.setState({ searchValue: '' }, () => this.tableRef.current.onQueryChange())
  }
  render() {
    const { user, companyObj } = this.props
    const { dataArr, loading, rowData, clientObj, roomSel, roomArr, searchValue } = this.state;
    console.log(companyObj)
    return (<>
      <div className="content">
        <div style={{ ...style.flexCenter, margin: '2rem auto' }}>
          <Form>
            <FormGroup widths='equal'>
              <DelayInput
                inputRef={ref => { this.ref = ref; }}
                name='searchValue'
                placeholder='請輸入姓名、手機、房號'
                style={{ width: '100%', minWidth: 350, float: 'left' }}
                onChange={(e) => this.handleChange(e, { name: 'searchValue', value: e.target.value })}
                // minLength={1}
                delayTimeout={300}
                action={{ icon: 'cancel', onClick: this.clear }}
                element={Input}
              />
              <Button.Group style={{ marginLeft: '50px' }}>
                <ModalNewClient  {...this.props} />
              </Button.Group>
            </FormGroup>
          </Form>
        </div>
        <Row>
          <Col xs="8" sm="8">
            <MaterialTable
              isLoading={loading}
              localization={localization()}
              tableRef={this.tableRef}
              title="搜尋結果，點擊載入房客名單"
              options={{
                search: false,
                tableLayout: 'fixed',
                pageSize: 5
              }}
              // actions={[{
              //   // width: 50,
              //   icon: 'group', // view_headline
              //   tooltip: '顯示',
              //   onClick: (e, rowData) => this.handleClickName(rowData.objectId)
              // }]}
              columns={[
                { width: 100, title: '房間號碼', field: 'roomNum' },
                { width: 150, title: '客戶姓名', field: 'name' },
                // { width: 80, title: '性別', field: 'sexualId' },
                { width: 250, title: '手機', field: 'cellnum' },
                // { width: 100, title: '開通碼', field: 'objectId' },
                // { width: 180, title: '地址', field: 'residentialAddr', cellStyle: { width: '250px' }, headerStyle: { width: '250px' } },
                // {
                //   width: 20, title: '選擇', field: 'live_select',
                //   headerStyle: { textAlign: 'right' },
                //   render: rowData => <></>,
                //   // editComponent: props => <ModalHandle rowData={props.rowData} employee={employee} onRowDataChange={props.onRowDataChange} />
                // },
                // {
                //   width: 100, title: '業務', field: 'handle',
                //   render: rowData => <>
                //     {(rowData && rowData.jointObjArr && rowData.jointObjArr.length && rowData.jointObjArr.map(({ objectId, main, jobNum }, index) =>
                //       <span key={objectId} >{index !== 0 ? ',' : null}{jobNum}</span>)) || null}</>,
                //   editComponent: props => <>
                //     {(props.rowData && props.rowData.jointObjArr && props.rowData.jointObjArr.length && props.rowData.jointObjArr.map(({ objectId, main, jobNum }, index) =>
                //       <span key={objectId} >{index !== 0 ? ',' : null}{jobNum}</span>)) || null}</>
                // },
              ]}
              data={query => {
                this.setState({ loading: true });
                return new Promise((resolve, reject) => {
                  fetchPeople(companyObj, query, searchValue, user).then(result => {
                    this.setState({ loading: false }, () => resolve(result));
                  })
                })
              }}
              onRowClick={(e, rowData) => this.handleClickName(rowData.objectId)}
              editable={{
                //   onRowAdd: newData =>
                //     new Promise(async (resolve, reject) => {
                //       const data = [...dataArr];
                //       const db = Parse.Object.extend("clients");
                //       const newDoc = new db();
                //       const doc = await newDoc.save();
                //       newData.objectId = doc.id;
                //       newData.id = doc.id;
                //       newData._id = doc.id;
                //       newData.companyId = companyObj.objectId
                //       data.push(newData);
                //       this.setState({ dataArr: data, newData }, () => resolve());
                //     }).then(() => this.handleAdd()),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    // const data = [...dataArr];
                    // const index = data.indexOf(oldData);
                    // data[index] = newData;
                    // console.log(newData)
                    this.setState({ newData, oldData }, () => resolve());
                  }).then(() => this.handleUpdate()),
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    // let data = [...dataArr];
                    // const index = data.indexOf(oldData);
                    // data.splice(index, 1);
                    this.setState({ oldData }, () => resolve());
                  }).then(() => this.handleDelete()),
              }}
            />
          </Col>
        </Row>
        <br />
        <br />
        {clientObj && clientObj.objectId ? <Tab menu={{ secondary: true, pointing: true }} renderActiveOnly={true} panes={[{
          menuItem: '交易記錄',
          render: () => <Tab.Pane attached={false}><Client1b {...this.props} clientObj={clientObj} roomSel={roomSel} roomArr={roomArr} /></Tab.Pane>,
        }, {
          menuItem: '客戶資料',
          render: () => <Tab.Pane attached={false}><Client1a {...this.props} clientObj={clientObj} handleFetch={this.handleFetch} /></Tab.Pane>,
        },]} /> : null}
      </div>
    </>)
  }
}
export default dormClient;
