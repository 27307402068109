import React, { Component } from 'react';
import { Table, Modal, Grid, Form, Button, TextArea } from 'semantic-ui-react';
// import Moment from 'moment';
import Parse from '../../../widget/parse'


const arrFields = [
  { title: '授業內容', type: 'scheduledProgress' },
  { title: '進度連絡', type: 'actualProgress' },
  { title: '講師コメント', type: 'comment1' },
  // { title: '担任コメント', type: 'comment2' },
  // { title: '主任コメント', type: 'comment3' },
  // { title: '学院長コメント', type: 'comment4' },
  { title: '備考', type: 'ps' },
];

const w = ['月', '火', '水', '木', '金'];
const p = ['一', '二', '三', '四', '五', '六', '七', '八'];

export default class RollCallProgress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        classroomId: props.classId || '',
        semesterYear: props.semesterYear,
        semester: props.semester,
      },
      isShowingModal: false,
      textVal: [],
      unsub: null,
      doc: {},
      index: 0
    };
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch()
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps)
    // const { firebase, weekday, period, eventDate, classId } = nextProps;

    // if (classId && eventDate && period) {
    //   if (this.state.unsub) {
    //     this.state.unsub();
    //     //  console.log('unsub worked!')
    //   }
    //   const unsub = firebase.firestore().collection("Classroom").doc(classId).collection("Progress")
    //     .doc(`${eventDate}_${period === '4' ? '56' : '78'}`)
    //     .onSnapshot(snap => {
    //       const doc = snap.data() || {};
    //       // console.log(doc)
    //       this.setState({ doc });
    //     }, err => {
    //       console.log(`Encountered error: ${err}`);
    //     });
    //   this.setState({ unsub });
    // }
  }

  handleFetch = async (props = this.props) => {
    const { currentSubscription } = this.state
    const { firebase, weekday, period, eventDate, classId } = props

    // if (classId && eventDate && period) {
    //   if (this.state.unsub) {
    //     this.state.unsub();
    //     //  console.log('unsub worked!')
    //   }
    //   const unsub = firebase.firestore()
    // .collection("Classroom")
    //     .doc(classId)
    //     .collection("Progress")
    //     .doc(`${eventDate}_${period === '4' ? '56' : '78'}`)
    //     .onSnapshot(snap => {
    //       const doc = snap.data() || {};
    //       // console.log(doc)
    //       this.setState({ doc });
    //     }, err => {
    //       console.log(`Encountered error: ${err}`);
    //     });
    //   this.setState({ unsub });
    // }
    const query = new Parse.Query('Progress');
    query.equalTo('classroomId', classId)
    query.equalTo('objectId', eventDate)
    query.limit(10000);
    const snap = await query.find();
    const doc = (snap[0] && snap[0].toJSON()) || {}
    this.setState({ doc })
    if (currentSubscription) {
      // console.log('currentSubscription.unsubscribe')
      await currentSubscription.unsubscribe();
      this.setState({ currentSubscription: null })
    }
    let subscription = await query.subscribe().catch(error => console.error(error));
    subscription.on('create', (data) => {
      const doc = [...this.state.doc, data.toJSON()];
      this.setState({ doc })
    });
    subscription.on('update', (data) => {
      const doc = [...this.state.doc, data.toJSON()];
      this.setState({ doc })
    });
    subscription.on('delete', (data) => {
      const doc = [...this.state.doc, data.toJSON()];
      this.setState({ doc })
    });
    this.setState({ currentSubscription: subscription })
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    const { form } = this.state
    this.setState({ form: { ...this.state.form, [name]: value } });
    // console.log(form);
  };

  handleSubmit = () => {
    const { firebase } = this.props;
    const { form } = this.state;
    // console.log(form);
    firebase.firestore().doc(`Classroom/${form.classroomId}/Progress/${form.eventDate}_${form.period === '4' ? '56' : '78'}`)
      .set(form)

    this.handleClose();
  };

  handleClose = () => {
    const { classId, semesterYear, semester } = this.props;
    this.setState({
      isShowingModal: false,
      form: {
        classroomId: classId,
        semesterYear,
        semester,
      },
    });
  };

  getTeacherName = (weekday, period) => {
    // const { arrangement, teachers } = this.props;
    // const slotObj = arrangement.find(item => item.weekday === weekday && item.period === period);
    // console.log(arrangement);
    // console.log(teachers);
    // console.log(slotObj);
    // return (
    //   !!slotObj && slotObj.teacherId && teachers.find(item => item._id === slotObj.teacherId) ?
    //     teachers.find(item => item._id === slotObj.teacherId).nameTeacher : ''
    // );
  };

  getCourseName = (weekday, period) => {
    const { courseArrs } = this.props;
    const courseObj =
      courseArrs.length && courseArrs.find(item => item.weekday === w[Number(weekday)] && item.period === p[Number(period)]);

    return (
      courseObj && courseObj.courseName ? courseObj.courseName : ''
    );
  };

  handleClickComment = (eventDate, weekday, period, index) => {
    const { classId } = this.props
    const { doc } = this.state
    // const classProgressObj = this.getClassProgressObj(eventDate, weekday, period);
    const type = arrFields[index].type;
    const title = arrFields[index].title;

    const form = doc && doc.classroomId ?
      { // 原本資料庫就有這筆資料
        ...doc,
        // [type]: obj && obj[type] ? obj[type] : '',
      } : { // 建一個新的obj 準備送到資料庫去
        ...this.state.form,
        classroomId: classId,
        eventDate,
        weekday,
        period,
        // [type]: obj && obj[type] ? obj[type] : '',
      };

    this.setState({
      isShowingModal: true,
      index,
      type,
      title,
      form,
      // type: 'scheduledProgress',
      // ...arrFields[index]
    });
  }

  render() {
    const { weekday, period, eventDate } = this.props;
    const { form, isShowingModal, title, type, doc, index } = this.state;
    const date = new Date(eventDate);
    let periodNum = "";
    let periodText = "";

    if (period === "0" || period === "1") {
      periodNum = "0";
      periodText = "12";
    } else if (period === "2" || period === "3") {
      periodNum = "2";
      periodText = "34";
    } else if (period === "4" || period === "5") {
      periodNum = "4";
      periodText = "56";
    } else if (period === "6" || period === "7") {
      periodNum = "6";
      periodText = "78";
    }

    const TAStyle = {
      height: 200,
      minWidth: '100%',
      border: '1px solid rgba(34,36,38,.15)',
      borderRadius: '.3rem',
      outline: 0,
      padding: '.8em 1em',
      lineHeight: 1.28
    }

    return (
      <>
        <br />
        <Table celled structured striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ textAlign: 'center', width: '150px' }}>{periodText}</Table.HeaderCell>
              <Table.HeaderCell style={{ textAlign: 'center' }}>{date.getMonth() + 1}月{date.getDate()}日 ({w[Number(weekday)]})</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {/* <Table.Row>
              <Table.Cell style={{ textAlign: 'center' }}></Table.Cell>
              <Table.Cell style={{ textAlign: 'center', wordBreak: 'break-word' }}>
                授業：{this.getTeacherName(weekday, periodNum)} {this.getCourseName(weekday, periodNum)}
              </Table.Cell>
            </Table.Row> */}
            {arrFields.map(({ title, type }, index) => (
              <Table.Row key={"aa" + index}>
                <Table.Cell>{title}</Table.Cell>
                <Table.Cell
                  style={{ cursor: 'pointer', wordBreak: 'break-word' }}
                  onClick={() => this.handleClickComment(eventDate, weekday, periodNum, index)}>
                  {doc && doc[type]}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        < Modal open={isShowingModal} onClose={() => this.handleClose()} size="large" >
          <Modal.Header>
            {form.eventDate} {title}
          </Modal.Header>
          <Modal.Content>
            <Form.Group controlid="formControlsTextarea">
              <Grid>
                {type ? <Grid.Row>
                  <Grid.Column>
                    <TextArea
                      style={TAStyle}
                      placeholder="入力してください"
                      name={type}
                      onChange={this.handleChange}
                      value={form[type]}
                    />
                  </Grid.Column>
                </Grid.Row> : null}
              </Grid>
            </Form.Group>
          </Modal.Content>
          <Modal.Content style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button color='green' onClick={() => this.handleSubmit()} icon='save' content='保存' />
            <Button onClick={() => this.handleClose()} icon='close' content='キャンセル' />
          </Modal.Content>
        </Modal>
      </>
    );
  }
}