import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Moment from 'moment';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, typeCurrency, exportPdf } from 'views/web/CustomMaterialTable';

// 1 月報表
const columns2 = (payTypeId = '') => ([

  dateCol({ title: '消費日期', field: 'consumDate' }),
  { width: 180, title: '金額', field: 'money' },
  { width: 180, title: '備註', field: 'ps' },
]);

export default class ClientPawning2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data2: [],
      newData: {},
      oldData: {},
      clients2Arr: []
    }
    this.tableRef = React.createRef();
  }

  componentDidMount = async () => {
    const clients2Arr = await Parse.queryData('clients2', { isPeople: '0' }); // 先抓所有的員工

    this.setState({ clients2Arr });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data2: nextProps.data2 });
  }

  handleAdd = async () => {
    const { newData } = this.state
    await Parse.saveData('clients2', newData);
  }

  handleUpdate = async () => {
    const { newData } = this.state
    await Parse.saveData('clients2', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    await Parse.deleteData('clients2', oldData);
    this.setState({ oldData: {} })
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  render() {
    const { pawning1Obj, loading2, clientObj, clients2Obj } = this.props;
    const { data2 } = this.state
    // const { ordernum = '', payTypeId = '3' } = pawning1Obj;
    return (<>
      <MaterialTable
        isLoading={loading2}
        localization={localization()}
        columns={columns2()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `服務內容：${clients2Obj.name}`),
          pageSize: 5,// data2.length < 5 ?  : data2.length + 1,
          search: false,
          tableLayout: 'fixed',
        }}
        data={data2}
        tableRef={this.tableRef}
        title={`服務內容：${clients2Obj.name}`}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const data = [...data2];
              const db = Parse.Object.extend("clients2");
              const newDoc = new db();
              const doc = await newDoc.save();

              const obj = {
                ...pawning1Obj,
                _id: doc.id,
                id: doc.id,
                objectId: doc.id,
                clientId: clientObj.objectId,
                clients2Id: clients2Obj.objectId,
                consumDateText: Moment(newData.consumDate).format('YYYY-MM-DD'),
                isPeople: '0',
                ...newData,
                // currentBalance: String(Number(newData.principal || 0) - Number(newData.repaymentMoney || 0)),
              };
              data.push(obj);
              this.setState({ data2: data, newData: obj }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...data2];
              const index = data.indexOf(oldData);
              const obj = {
                ...newData,
                interestPaid: Number(newData.interestPaid)
              }
              data[index] = obj;
              this.setState({ data2: data, newData: obj, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...data2];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              this.setState({ data2: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
    </>)
  }
}