import React, { Component } from 'react'
import { Button, Grid } from 'semantic-ui-react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';

import MaterialTable from 'material-table'

import ModalAutoAddr from './ModalAutoAddr'
import ModalPraying1 from './ModalPraying1'
import ModalLighting1 from './ModalLighting1'
import ModalPoint from './ModalPoint'
import ModalPdf from 'widget/pdf/ModalPdf';
import TableCardslot from './TableCardslot'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';


const arrTime2 = ["吉", "子", "丑", "寅", "卯", "辰", "巳", "午", "未", "申", "酉", "戍", "亥", "夜子"];//戌
const objTime2 = { '0': "吉", '1': "子", '2': "丑", '3': "寅", '4': "卯", '5': "辰", '6': "巳", '7': "午", '8': "未", '9': "申", '10': "酉", '11': "戍", '12': "亥", '13': "夜子" };
const objZodiac = { '1': "鼠", '2': "牛", '3': "虎", '4': "兔", '5': "龍", '6': "蛇", '7': "馬", '8': "羊", '9': "猴", '10': "雞", '11': "狗", '12': "豬" }
const objSexual = { '1': '男', '2': '女' };

const columns = ({ thatprops = {}, templeclassSel = {}, templelevelSel = {}, people1 }) => {
  const { companyObj } = thatprops;

  return [
    { width: 50, title: '戶長', field: 'mainPerson', lookup: { '0': '否', '-1': '是' }, },
    { width: 50, title: '郵寄', field: 'mailThis', lookup: { '0': '否', '-1': '是' } },
    { width: 80, title: '姓名', field: 'name' },
    { width: 50, title: '姓別', field: 'sexualId', lookup: { '1': '男', '2': '女' } },
    companyObj.hideBirthday ? {} : { width: 80, title: '生日(國)', field: 'birthday' },
    companyObj.hideZodiacId ? {} : { width: 60, title: '生肖', field: 'zodiacId', lookup: objZodiac },
    companyObj.hideBornYear ? {} : { width: 60, title: '生年(農)', field: 'bornYear' },
    companyObj.hideBornMonth ? {} : { width: 60, title: '生月(農)', field: 'bornMonth' },
    companyObj.hideBornDay ? {} : { width: 60, title: '生日(農)', field: 'bornDay' },
    companyObj.hideBornTime ? {} : { width: 60, title: '生時(農)', field: 'bornTime', lookup: objTime2 },
    {
      width: 50, title: '選擇', field: 'postcode_select',
      headerStyle: { textAlign: 'center' },
      render: rowData => <></>,
      editComponent: props => <ModalAutoAddr {...thatprops} type='0' rowData={props.rowData} onRowDataChange={props.onRowDataChange} />
    },
    { width: 80, title: '郵遞區號', field: 'postcode' },
    { width: 250, title: '地址', field: 'addr', cellStyle: { width: '250px' }, headerStyle: { width: '250px' } },
    { width: 80, title: '手機', field: 'cellphone' },
    { width: 80, title: '電話', field: 'telephone' },
    companyObj.hideLongLive ? {} : { width: 60, title: '功德堂', field: 'longLive' },
    companyObj.hideLotus ? {} : { width: 60, title: '往生堂', field: 'lotus' },
    companyObj.hideLotus2 ? {} : { width: 60, title: '淨土堂', field: 'lotus2' },
    companyObj.hideTempleClass ? {} : { width: 60, title: '班別', field: 'templeClass', lookup: templeclassSel },
    companyObj.hideTempleLevel ? {} : { width: 60, title: '身份', field: 'templeLevel', lookup: templelevelSel },
    companyObj.hideMemberId ? {} : { width: 60, title: '社員編號', field: 'memberId' },
    { width: 80, title: 'EMail', field: 'email' },
    { width: 60, title: '移至拔度', field: 'isLive', lookup: { '0': '是', '1': '否' } },
    { width: 100, title: '建立時間', editable: 'never', field: 'createdAt', type: 'datetime' },
    { width: 100, title: '修改時間', editable: 'never', field: 'updatedAt', type: 'datetime' },
  ]
};

const columns0 = ({ thatprops = {} }) => {
  const { companyObj } = thatprops;

  return [
    { width: 160, title: '姓名', field: 'name' },
    { width: 60, title: '姓別', field: 'sexualId', lookup: { '0': '', '1': '男', '2': '女' } },
    { width: 60, title: '生年(農)', field: 'bornYear' },
    { width: 60, title: '生月(農)', field: 'bornMonth' },
    { width: 60, title: '生日(農)', field: 'bornDay' },
    { width: 60, title: '生時(農)', field: 'bornTime', lookup: objTime2 },
    companyObj.hideBirth ? {} : { width: 60, title: '生肖', field: 'zodiacId', lookup: objZodiac },
    { width: 60, title: '享壽', field: 'live_year' },
    { width: 60, title: '卒年(農)', field: 'passYear' },
    { width: 60, title: '卒月(農)', field: 'passMonth' },
    { width: 60, title: '卒日(農)', field: 'passDay' },
    { width: 60, title: '卒時(農)', field: 'passTime', lookup: objTime2 },
    {
      width: 60, title: '選擇', field: 'postcode_select',
      headerStyle: { textAlign: 'center' },
      render: rowData => <></>,
      editComponent: props => <ModalAutoAddr {...thatprops} type='0' rowData={props.rowData} onRowDataChange={props.onRowDataChange} />
    },
    { width: 60, title: '郵遞區號', field: 'postcode' },
    { width: 220, title: '地址', field: 'addr', cellStyle: { width: '250px' }, headerStyle: { width: '250px' } },
    companyObj.hideLongLive ? {} : { width: 60, title: '功德堂', field: 'longLive' },
    companyObj.hideLotus ? {} : { width: 60, title: '往生堂', field: 'lotus' },
    companyObj.hideLotus2 ? {} : { width: 60, title: '淨土堂', field: 'lotus2' },
    { width: 60, title: '移至陽上', field: 'isLive', lookup: { '1': '是', '0': '否' } },
    { width: 100, title: '建立時間', editable: 'never', field: 'createdAt', type: 'datetime' },
    { width: 100, title: '修改時間', editable: 'never', field: 'updatedAt', type: 'datetime' },
  ]
};

export default class TablePeople extends Component {
  state = {
    oldData: {},
    newData: {},
    confirm: false,
    loading: false,
  }

  deleteFamily = async () => {
    const { history, familyId, companyObj, handleFamilyChange } = this.props
    // const { loading } = this.state
    const companyId = companyObj.objectId;
    await Parse.destroyData('people', { companyId, familyId })
    await Parse.destroyData('praying1', { companyId, familyId })
    await Parse.destroyData('praying2', { companyId, familyId })
    await Parse.destroyData('lighting1', { companyId, familyId })
    await Parse.destroyData('lighting2', { companyId, familyId })

    this.setState({ confirm: false }, () => {
      handleFamilyChange(null, { name: 'people', value: [] })
      history.push('/admin/Family')
    })
  }

  handleChange = (e, { name, value }) => this.setState({ form: { ...this.state.form, [name]: value } })

  handleClick = (e, { children }) => {
    // console.log(this.state.form.addr);
    let val = this.state.form.addr + children
    this.setState({ form: { ...this.state.form, addr: val } })
  }

  render() {
    const { people1, people0, familyId, companyObj, selectData,
      handlePeopleAdd, handlePeopleUpdate, handlePeopleDelete } = this.props
    const { loading, confirm } = this.state
    const { webtypeId } = companyObj;
    const { templeclassObj = {}, templelevelObj = {} } = selectData;
    const templeclassSel = templeclassObj.sel || {};
    const templelevelSel = templelevelObj.sel || {};
    // console.log(selectData)
    // console.log(templeclassSel)
    // console.log(templelevelSel)
    return (<>
      {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 15 }}> */}
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign={"right"}>
            {webtypeId === 'temple' ?// 佛教
              <Button.Group style={{ marginRight: '5px' }}>
                <ModalPraying1 {...this.props} selectData={selectData} obj={{ title: '法會', subdb1: 'praying1', subdb2: 'praying2', color: 'orange', icon: 'fire' }} category='p1' />
                <ModalLighting1 {...this.props} selectData={selectData} obj={{ title: '點燈', subdb1: 'lighting1', subdb2: 'lighting2', color: 'yellow', icon: 'lightbulb' }} />
                <ModalPoint {...this.props} obj={{ title: '點數', color: 'green', icon: 'dot circle' }} />
              </Button.Group> : null}
            {webtypeId === 'dao' ? // 道教
              <Button.Group style={{ marginRight: '5px' }}>
                <ModalPraying1 {...this.props} obj={{ title: '法會', subdb1: 'praying1', subdb2: 'praying2', color: 'orange', icon: 'fire' }} category='p2' />
                {/* <ModalPraying1 {...this.props} obj={{ title: '斗燈', subdb1: 'praying1', subdb2: 'praying2', color: 'orange', icon: 'adjust' }} category='p3' />
            <ModalPraying1 {...this.props} obj={{ title: '祈福', subdb1: 'praying1', subdb2: 'praying2', color: 'orange', icon: 'adjust' }} category='p4' />
            <ModalPraying1 {...this.props} obj={{ title: '法會', subdb1: 'praying1', subdb2: 'praying2', color: 'orange', icon: 'adjust' }} category='p5' />
            <ModalLighting1 {...this.props} obj={{ title: '光明燈', subdb1: 'lighting1', subdb2: 'lighting2', color: 'orange', icon: 'adjust' }} /> */}
              </Button.Group> : null}
            {webtypeId === 'xxxx' ? // 密宗
              <Button.Group style={{ marginRight: '5px' }}>
                <ModalPraying1 {...this.props} obj={{ title: '法會', subdb1: 'praying1', subdb2: 'praying2', color: 'orange', icon: 'fire' }} category='p1' />
                <ModalLighting1 {...this.props} obj={{ title: '點燈', subdb1: 'lighting1', subdb2: 'lighting2', color: 'orange', icon: 'lightbulb' }} />
              </Button.Group> : null}
            <Button.Group>
              <ModalPdf {...this.props} title='全戶資料' nowform={'20'} params={{ companyObj, people1, people0 }} />
              <ModalAutoAddr {...this.props} type="1" />
              <Button content='刪除整戶' icon='trash alternate' onClick={() => this.setState({ confirm: true })} />
            </Button.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <br />

      {/* </div> */}
      <Modal
        isOpen={confirm}
        toggle={() => this.setState({ confirm: false })}
        // size="sm"
        className="mh-100 h-90 mw-20 w-20"
      >
        <ModalHeader className="font-weight-bold" >刪除整戶資料</ModalHeader>
        <ModalBody style={{ fontSize: 20 }}>確定要刪除整戶資料嗎？</ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button onClick={() => this.setState({ confirm: false })}>取消</Button>{' '}
          <Button color="red" onClick={this.deleteFamily}>確認</Button>
        </ModalFooter>
      </Modal>
      <MaterialTable
        localization={localization()}
        columns={columns({ thatprops: this.props, templeclassSel, templelevelSel, people1 }).filter(item => item.field)}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '陽上'),
          pageSize: 5,
          search: false,
          tableLayout: 'fixed',
        }}
        data={people1}
        title="陽上"
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const db = Parse.Object.extend("people");
              const newDoc = new db();
              const doc = await newDoc.save();

              newData.objectId = doc.id
              newData.id = doc.id
              newData._id = doc.id
              newData.companyId = companyObj.objectId
              newData.companyName = companyObj.name
              newData.familyId = familyId
              newData.isLive = '1'
              newData.mainPerson = '0'
              newData.bornTimeText = (newData.bornTime) ? arrTime2[Number(newData.bornTime)] : '吉'
              newData.passTimeText = (newData.passTime) ? arrTime2[Number(newData.passTime)] : '吉'
              // newData.createdAt = new Date()

              newData.zodiacText = (newData.zodiacId && objZodiac[newData.zodiacId]) || '';
              newData.sexualText = (newData.sexualId && objSexual[newData.sexualId]) || '';

              await handlePeopleAdd(newData)
              resolve()
            }).then(() => { }),
          onRowUpdate: (newData, oldData) =>
            new Promise(async (resolve, reject) => {
              newData.zodiacText = (newData.zodiacId && objZodiac[newData.zodiacId]) || '';
              newData.sexualText = (newData.sexualId && objSexual[newData.sexualId]) || '';
              await handlePeopleUpdate({ newData, oldData })
              resolve();
            }).then(() => { }),
          onRowDelete: oldData =>
            new Promise(async (resolve, reject) => {
              await handlePeopleDelete(oldData);
              resolve();
            }).then(() => { }),
        }}
      />
      <br />
      <br />
      <MaterialTable
        localization={localization()}
        columns={columns0({ thatprops: this.props, people0 })}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '拔渡'),
          pageSize: 5,
          search: false,
          tableLayout: 'fixed',
        }}
        data={people0}
        title="拔渡"
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const db = Parse.Object.extend("people");
              const newDoc = new db();
              const doc = await newDoc.save();

              newData.objectId = doc.id
              newData.id = doc.id
              newData._id = doc.id
              newData.companyId = companyObj.objectId
              newData.companyName = companyObj.name
              newData.familyId = familyId
              newData.isLive = '0'
              newData.mainPerson = '0'
              newData.bornTimeText = (newData.bornTime) ? arrTime2[Number(newData.bornTime)] : '吉'
              newData.passTimeText = (newData.passTime) ? arrTime2[Number(newData.passTime)] : '吉'

              newData.zodiacText = (newData.zodiacId && objZodiac[newData.zodiacId]) || '';
              newData.sexualText = (newData.sexualId && objSexual[newData.sexualId]) || '';

              await handlePeopleAdd(newData)
              resolve()
            }).then(() => { }),
          onRowUpdate: (newData, oldData) =>
            new Promise(async (resolve, reject) => {
              newData.bornTimeText = (newData.bornTime) ? arrTime2[Number(newData.bornTime)] : '吉'
              newData.passTimeText = (newData.passTime) ? arrTime2[Number(newData.passTime)] : '吉'

              newData.zodiacText = (newData.zodiacId && objZodiac[newData.zodiacId]) || '';
              newData.sexualText = (newData.sexualId && objSexual[newData.sexualId]) || '';

              await handlePeopleUpdate({ newData, oldData })
              resolve();
            }).then(() => { }),
          onRowDelete: oldData =>
            new Promise(async (resolve, reject) => {
              await handlePeopleDelete(oldData);
              resolve();
            }).then(() => { }),
        }}
      />
      <br />
      <br />
      <TableCardslot {...this.props} />
    </>)
  }
}
