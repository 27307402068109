/*紫微斗數 Chinese Astrology Zi Wei Dou Shu*/
/*天干地支五行局*/
export const YinYang = ["陽", "陰"];
export const HeavenlyStems = ["甲", "乙", "丙", "丁", "戊", "己", "庚", "辛", "壬", "癸"];
export const ShengXiaoGB = ["鼠", "牛", "虎", "兔", "龍", "蛇", "馬", "羊", "猴", "雞", "狗", "豬"];
export const EarthlyBranches = ["子", "丑", "寅", "卯", "辰", "巳", "午", "未", "申", "酉", "戌", "亥"];
export const Palace = ["命宮", "父母宮", "福德宮", "田宅宮", "官祿宮", "交友宮", "遷移宮", "疾厄宮", "財帛宮", "子女宮", "夫妻宮", "兄弟宮", "身"];
export const FiveElements = ["水二局", "火六局", "土五局", "木三局", "金四局"];
export const DaShian = [2, 6, 5, 3, 4];//搭配五行局
/*Stars*/
export const StarM_A14 = ["紫微", "天機", "太陽", "武曲", "天同", "廉貞", "天府", "太陰", "貪狼", "巨門", "天相", "天梁", "七殺", "破軍"];
export const StarM_A07 = ["文昌", "文曲", "左輔", "右弼", "天魁", "天鉞", "祿存"];
export const StarM_S04 = ["化祿", "化權", "化科", "化忌"];
export const StarM_B06 = ["擎羊", "陀羅", "火星", "鈴星", "地空", "地劫"];
// export const StarO_S05 = ["天馬", "龍池", "鳳閣", "紅鸞", "天喜"];
export const StarO_S05 = ["天喜", "天虛", "天哭", "紅鸞", "龍池", "鳳閣", "孤辰", "寡宿", "破碎", "華蓋", "咸池", "蜚廉"];
export const Doctor12 = ["博士", "力士", "青龍", "小耗", "將軍", "奏書", "飛廉", "喜神", "病符", "大耗", "伏兵", "官符"]
// export const Doctor21 = ["官符", "伏兵", "大耗", "病符", "喜神", "飛廉", "奏書", "將軍", "小耗", "青龍", "力士", "博士",]
export const Longevity12 = ["長生", "沐浴", "冠帶", "臨官", "帝旺", "衰", "病", "死", "墓", "絕", "胎", "養",]
export const Longevity21 = ["養", "胎", "絕", "墓", "死", "病", "衰", "帝旺", "臨官", "冠帶", "沐浴", "長生"]
export const Before12 = ["歲建", "晦氣", "喪門", "貫索", "官符", "小耗", "大耗", "龍德", "白虎", "天德", "弔客", "病符"]
/*0:水二局:[1,2,2,3,3,4,4,5,5,6,6,7,7,8,8,9,9,10,10,11,11,0,0,1,1,2,2,3,3,4]
  3:木三局:[4,1,2,5,2,3,6,3,4,7,4,5,8,5,6,9,6,7,10,7,8,11,8,9,0,9,10,1,10,11]
  4:金四局:[11,4,1,2,0,5,2,3,1,6,3,4,2,7,4,5,3,8,5,6,4,9,6,7,5,10,7,8,6,11]
  2:土五局:[6,11,4,1,2,7,0,5,2,3,8,1,6,3,4,9,2,7,4,5,10,3,8,5,6,11,4,9,6,7]
  1:火六局:[9,6,11,4,1,2,10,7,0,5,2,3,11,8,1,6,3,4,0,9,2,7,4,5,1,10,3,8,5,6]*/
export const FiveEleTable = [
  [1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11, 11, 0, 0, 1, 1, 2, 2, 3, 3, 4],
  [9, 6, 11, 4, 1, 2, 10, 7, 0, 5, 2, 3, 11, 8, 1, 6, 3, 4, 0, 9, 2, 7, 4, 5, 1, 10, 3, 8, 5, 6],
  [6, 11, 4, 1, 2, 7, 0, 5, 2, 3, 8, 1, 6, 3, 4, 9, 2, 7, 4, 5, 10, 3, 8, 5, 6, 11, 4, 9, 6, 7],
  [4, 1, 2, 5, 2, 3, 6, 3, 4, 7, 4, 5, 8, 5, 6, 9, 6, 7, 10, 7, 8, 11, 8, 9, 0, 9, 10, 1, 10, 11],
  [11, 4, 1, 2, 0, 5, 2, 3, 1, 6, 3, 4, 2, 7, 4, 5, 3, 8, 5, 6, 4, 9, 6, 7, 5, 10, 7, 8, 6, 11]];
export const FiveEleArr = [[0, 1, 3, 2, 4, 1], [1, 2, 4, 3, 0, 2], [2, 3, 0, 4, 1, 3], [3, 4, 1, 0, 2, 4], [4, 0, 2, 1, 3, 0]];

//StarM_A14 => 0:紫微,1:天機,2:太陽,3:武曲,4:天同,5:廉貞,6:天府,7:太陰,8:貪狼,9:巨門,10:天相,11:天梁,12:七殺,13:破軍
export const Star_A14 = [
  [[0], [], [13], [], [5, 6], [7], [8], [4, 9], [3, 10], [2, 11], [12], [1]],
  [[1], [0, 13], [], [6], [7], [5, 8], [9], [10], [4, 11], [3, 12], [2], []],
  [[13], [1], [0, 6], [7], [8], [9], [5, 10], [11], [12], [10], [3], [2]],
  [[2], [6], [1, 7], [0, 8], [9], [10], [11], [5, 12], [], [], [4], [3, 13]],
  [[3, 6], [2, 7], [8], [1, 9], [0, 10], [11], [12], [], [5], [], [13], [4]],
  [[4, 7], [3, 8], [2, 9], [10], [1, 10], [0, 12], [], [], [], [5, 13], [], [6]],
  [[8], [4, 9], [3, 10], [2, 11], [12], [1], [0], [], [13], [], [5, 6], [7]],
  [[9], [10], [4, 11], [3, 12], [2], [], [1], [0, 13], [], [6], [7], [5, 8]],
  [[5, 10], [11], [12], [10], [3], [2], [13], [1], [0, 6], [7], [8], [9]],
  [[11], [5, 12], [], [], [4], [3, 13], [2], [6], [1, 7], [0, 8], [9], [10]],
  [[12], [], [5], [], [13], [4], [3, 6], [2, 7], [8], [1, 9], [0, 10], [11]],
  [[], [], [], [5, 13], [], [6], [4, 7], [3, 8], [2, 9], [10], [1, 10], [0, 12]]
];
//安紫微諸星表，第7列，[6]為天府
export const Star_Z06 = [
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  [11, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  [9, 10, 11, 0, 1, 2, 3, 4, 5, 6, 7, 8],
  [8, 9, 10, 11, 0, 1, 2, 3, 4, 5, 6, 7],
  [7, 8, 9, 10, 11, 0, 1, 2, 3, 4, 5, 6],
  [4, 5, 6, 7, 8, 9, 10, 11, 0, 1, 2, 3],
  [4, 3, 2, 1, 0, 11, 10, 9, 8, 7, 6, 5]
];
export const Star_T08 = [
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 0],
  [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 0, 1],
  [3, 4, 5, 6, 7, 8, 9, 10, 11, 0, 1, 2],
  [4, 5, 6, 7, 8, 9, 10, 11, 0, 1, 2, 3],
  [5, 6, 7, 8, 9, 10, 11, 0, 1, 2, 3, 4],
  [6, 7, 8, 9, 10, 11, 0, 1, 2, 3, 4, 5],
  [10, 11, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
];
//StarM_A07=new Array("文昌","文曲","左輔","右弼","天魁","天鉞","祿存");
//左輔星、右弼星、文昌星、文曲星、祿存星、天馬星、擎羊星、陀羅星、火星、鈴星、天魁星、天鉞星、地空星、地劫星
//0:文昌 1:文曲 (時) 2:左輔 3:右弼 (月) 4:天魁 5:天鉞 6:祿存(年干)
export const Star_G07 = [
  [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0, 11],
  [4, 5, 6, 7, 8, 9, 10, 11, 0, 1, 2, 3],
  [4, 5, 6, 7, 8, 9, 10, 11, 0, 1, 2, 3],
  [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0, 11],
  [1, 0, 11, 11, 1, 0, 1, 6, 3, 3],
  [7, 8, 9, 9, 7, 8, 7, 2, 5, 5],
  [2, 3, 5, 6, 5, 6, 8, 9, 11, 0]
];
//StarM_S04=new Array("化祿","化權","化科","化忌");
//0:紫微,1:天機,2:太陽,3:武曲,4:天同,5:廉貞,6:天府,7:太陰,8:貪狼,9:巨門,10:天相,11:天梁,12:七殺,13:破軍
//0:文昌 1:文曲 (時) 2:左輔 3:右弼 (月) 4:天魁 5:天鉞 6:祿存(年干)
//0:化祿 1:化權 2:化科 3:化忌
export const Star_S04 = [
  [StarM_A14[5], StarM_A14[1], StarM_A14[4], StarM_A14[7], StarM_A14[8], StarM_A14[3], StarM_A14[2], StarM_A14[9], StarM_A14[11], StarM_A14[13]],
  [StarM_A14[13], StarM_A14[11], StarM_A14[1], StarM_A14[4], StarM_A14[7], StarM_A14[8], StarM_A14[3], StarM_A14[2], StarM_A14[0], StarM_A14[9]],
  [StarM_A14[3], StarM_A14[0], StarM_A07[0], StarM_A14[1], StarM_A07[3], StarM_A14[11], StarM_A14[7], StarM_A07[1], StarM_A07[2], StarM_A14[7]],
  [StarM_A14[2], StarM_A14[7], StarM_A14[5], StarM_A14[9], StarM_A14[1], StarM_A07[1], StarM_A14[4], StarM_A07[0], StarM_A14[3], StarM_A14[8]]
];
//StarM_B06=new Array("擎羊","陀羅","火星","鈴星","天空","地劫");
//0:擎羊 1:陀羅 2:火星 3:鈴星 4:天空 5:地劫
//0:2,6,10 1:8,0,4 2:5,9,1 3:11,3,7  %4=> 2,0,1,3
//改成0:8,0,4 1:5,9,1 2:2,6,10 3:11,3,7 
export const Star_B06 = [
  [3, 4, 6, 7, 6, 7, 9, 10, 0, 1],
  [1, 2, 4, 5, 4, 5, 7, 8, 10, 11],
  [[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 0, 1], [3, 4, 5, 6, 7, 8, 9, 10, 11, 0, 1, 2], [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 0], [9, 10, 11, 0, 1, 2, 3, 4, 5, 6, 7, 8]],
  [[10, 11, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9], [10, 11, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9], [3, 4, 5, 6, 7, 8, 9, 10, 11, 0, 1, 2], [10, 11, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]],
  [11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0],
  [11, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
];
//StarO_S05=new Array("天馬","龍池","鳳閣","紅鸞","天喜"");
// export const StarO_S05 = ["天哭", "天虛", "天哭", "天德", "紅鸞", "天喜", "龍池", "鳳閣", "孤辰", "寡宿", "破碎", "大耗", "華蓋", "解神", "咸池", "劫殺", "天馬", "蜚廉";
// ["天哭", "天虛", "紅鸞", "天喜", "龍池", "鳳閣", "孤辰", "寡宿", "天才", "天壽", "蜚廉", "破碎", "天姚", "天刑", "天馬", "天巫", "解神", "陰煞", "天月", "天官", "天褔", "三台", "八座", "恩光", "天貴", "台輔", "封誥", "天使", "天傷"];
export const Star_OS5 = [
  [9, 8, 7, 6, 5, 4, 3, 2, 1, 0, 11, 10],//天喜
  [6, 7, 8, 9, 10, 11, 0, 1, 2, 3, 4, 5],//天虛
  [6, 5, 4, 3, 2, 1, 0, 11, 10, 9, 8, 7],//天哭
  // [9, 10, 11, 0, 1, 2, 3, 4, 5, 6, 7, 8],//天德
  [3, 2, 1, 0, 11, 10, 9, 8, 7, 6, 5, 4],//紅鸞
  [4, 5, 6, 7, 8, 9, 10, 11, 0, 1, 2, 3],//龍池
  [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0, 11],//鳳閣
  [2, 2, 5, 5, 5, 8, 8, 8, 0, 0, 0, 2],//孤辰
  [10, 10, 1, 1, 1, 4, 4, 4, 7, 7, 7, 10],//寡宿
  [5, 1, 9, 5, 1, 9, 5, 1, 9, 5, 1, 9],//破碎
  // [6, 7, 8, 9, 10, 11, 0, 1, 2, 3, 4, 5],//大耗
  [4, 1, 10, 7, 4, 1, 10, 7, 4, 1, 10, 7],//華蓋
  [9, 6, 3, 0, 9, 6, 3, 0, 9, 6, 3, 0],//咸池
  // [5, 2, 11, 8, 5, 2, 11, 8, 5, 2, 11, 8],//劫殺
  [2, 11, 8, 5, 2, 11, 8, 5, 2, 11, 8, 5],//天馬
  [8, 9, 10, 5, 6, 7, 2, 3, 4, 11, 0, 1],//蜚廉
];

