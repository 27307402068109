
import React, { Component } from 'react'
import { Table, Grid, Form } from 'semantic-ui-react';
// import ModalPdf from 'widget/pdf/ModalPdf';
// import MaterialTable from 'material-table'
import { Bar } from 'react-chartjs-2';
// import RowsDnd from './RowsDnd'
import Parse from 'parse';
import { all } from 'underscore';

// reactstrap components

const pray1StatData1 = (label, data) => ({
  labels: label,
  datasets: [
    {
      label: '點數',
      backgroundColor: 'rgba(87, 171, 255, 1)',
      borderColor: 'rgba(87, 171, 255, 1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(87, 171, 255,1)',
      hoverBorderColor: 'rgba(87, 171, 255,1)',
      data
    }
  ]
})
const options = {
  scales: {
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: '年度'
      }
    }],
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: '點數'
      },
      ticks: {
        maxTicksLimit: 30,
        beginAtZero: true,
      }
    }],
  }
}

class Statistic3 extends Component {
  state = {
    yearData: [],
    yearArr: [],
    mgyearId: '-1',
    pray1Id: '-1',
    paystatusId: '-1',
    arrStat: [],
  }

  async componentDidMount() {
    const { companyObj } = this.props;
    const point = await Parse.queryData('point', { companyId: companyObj.objectId })
    const mgyear = await Parse.queryData('mgyear', { companyId: companyObj.objectId });
    this.setState({ point, mgyear }, () => this.handleShowGraph())
  }

  handleShowGraph = () => {
    const { data2, point, mgyear } = this.state

    // // 整理各年度加總的object
    // pray1Arr.forEach(item => {
    //   if (item.accReceivable) {
    //     const obj = item.accReceivable;
    //     Object.keys(obj).forEach(year => {
    //       allObj[year + ''] = (allObj[year + ''] || 0) + obj[year + ''];
    //     })
    //   }
    // })

    const allReceivableLabel = mgyear.map(item => item.value)
    const allReceivableData = allReceivableLabel.map(item => {
      let allPoint = 0
      if (point.filter(item2 => item2.year === item)) {
        point.filter(item2 => item2.year === item).forEach(item2 => allPoint += Number(item2.point))
        return allPoint
      } else {
        return 0
      }
    })

    // const allReceivableLabel2 = [];
    // const allReceivableData2 = [];

    // data2.forEach(item => {
    //   allReceivableLabel2.push(item._id);
    //   allReceivableData2.push(item.totalAccReceivable);
    // })
    const dataArr = allReceivableLabel.map((item, index) => ({ year: item, point: allReceivableData[index] }))
    this.setState({ allReceivableLabel, allReceivableData, dataArr })
  }

  render() {
    // const { } = this.props;
    const { dataArr = [], allReceivableLabel = [], allReceivableData = [], pray1Id, paystatusId, loading, loadingBtn, allReceivableLabel2, allReceivableData2, arrStat } = this.state;


    return (
      <>
        <div className="content">
          <Grid columns={2} divided style={{ margin: '20px' }}>
            <Grid.Row>
              <Grid.Column width={16}>
                <h2>統計圖表</h2>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form>
                  <Form.Group inline>
                    {/* <Form.Select label='年度' options={[{ key: 'aa', value: '-1', text: '全部' }].concat(mgyearOpt)} value={mgyearId} name='mgyearId' onChange={this.handleChange} />
                    <Form.Select label='點燈' options={[{ key: 'aa', value: '-1', text: '全部' }].concat(pray1Opt)} value={pray1Id} name='pray1Id' onChange={this.handleChange} disabled='true' />
                    <Form.Select label='付款狀況' options={payStatusOpt} value={paystatusId} name='paystatusId' onChange={this.handleChange} disabled='true' />
                    <Form.Button color='blue' loading={loadingBtn} >重整資料</Form.Button> */}
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <h3>所有點數總收入</h3>
                <Bar
                  data={pray1StatData1(allReceivableLabel, allReceivableData)}
                  height={400}
                  options={options}
                />
              </Grid.Column>
              <Grid.Column>
                <Table >
                  <Table.Header>
                    <Table.Row>
                      {/* {allReceivableLabel.map(item => <Table.HeaderCell>{item}</Table.HeaderCell>)} */}
                      <Table.HeaderCell>年度</Table.HeaderCell>
                      <Table.HeaderCell>點數</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {dataArr.map(item => <><Table.Row><Table.Cell>{item.year}</Table.Cell><Table.Cell>{item.point}</Table.Cell></Table.Row></>)}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
            {/* <Grid.Row>
              {arrStat.map(item => <Grid.Column>
                <h2>{item.pray1Name}</h2>
                <Bar
                  data={pray1StatData1(item.receivableLabel, item.receivableData)}
                  height={200}
                />
              </Grid.Column>)}
            </Grid.Row> */}
            {/* <Grid.Row>
            <h2>點燈</h2>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Bar
                data={peopledata}
                height={200}
              />
            </Grid.Column>
            <Grid.Column>
              <Bar
                data={moneydata}
                height={200}
              />
            </Grid.Column>
          </Grid.Row> */}
          </Grid>
        </div>
      </>
    );
  }
}

export default Statistic3;
