
export const a4pageHeight = 841.89;
export const a4pageWidth = 595.28;
export const a4pageWidthHalf = a4pageWidth / 2;
export const a4pageWidthHalf2 = a4pageWidth / 4;

export const digit = ['○', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九'];
export const dateDigit = ['○', '正', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九', '廿', '廿一', '廿二', '廿三', '廿四', '廿五', '廿六', '廿七', '廿八', '廿九', '三十', '三十一'];
export const dateDigitMonth = ['○', '初一', '初二', '初三', '初四', '初五', '初六', '初七', '初八', '初九', '十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九', '廿', '廿一', '廿二', '廿三', '廿四', '廿五', '廿六', '廿七', '廿八', '廿九', '三十', '三十一'];
export const dateDigit2 = ['○', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九', '廿', '廿一', '廿二', '廿三', '廿四', '廿五', '廿六', '廿七', '廿八', '廿九', '三十', '三十一'];

export const arrChineseYear = ["", "鼠", "牛", "虎", "兔", "龍", "蛇", "馬", "羊", "猴", "雞", "狗", "豬"];
export const objChineseYear2 = [{ id: "1", value: "鼠" }, { id: "2", value: "牛" }, { id: "3", value: "虎" }, { id: "4", value: "兔" }, { id: "5", value: "龍" }, { id: "6", value: "蛇" }, { id: "7", value: "馬" }, { id: "8", value: "羊" }, { id: "9", value: "猴" }, { id: "10", value: "雞" }, { id: "11", value: "狗" }, { id: "12", value: "豬" }];
export const chineseNumber = ["", "甲", "乙", "丙", "丁", "戊", "己", "庚", "辛", "壬", "癸"];
export const arrTime = ["吉", "子", "丑", "寅", "卯", "辰", "巳", "午", "未", "申", "酉", "戍", "亥", "夜子"];//戌

export const objSexual2 = [{ id: "1", value: "男" }, { id: "2", value: "女" }, { id: "3", value: "(空白)" },];

export const arrConflict = [
  "",
  "制白虎星",
  "紫微星拱照",
  "安太歲",
  "制死符",
  "制五鬼官符",
  "制桃花",
  "制喪門吊客",
  "太陽",
  "太歲當頭 安太歲",
  "制病符",
  "制天狗",
  "吉星照臨",
];

export const digitUppercase = (n) => {
  const fraction = ['角', '分'];
  const digit = [
    '零', '壹', '貳', '參', '肆',
    '伍', '陸', '柒', '捌', '玖'
  ];
  const unit = [
    ['元', '萬', '億'],
    ['', '拾', '佰', '仟']
  ];
  const head = n < 0 ? '欠' : '';
  n = Math.abs(n);
  let s = '';
  for (let i = 0; i < fraction.length; i++) {
    s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
  }
  s = s || '整';
  // s = s || '';
  n = Math.floor(n);
  for (let i = 0; i < unit[0].length && n > 0; i++) {
    let p = '';
    for (let j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p;
      n = Math.floor(n / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }
  return head + s.replace(/(零.)*零元/, '元')
    .replace(/(零.)+/g, '零')
    // .replace(/^整$/, '零元');
    .replace(/^整$/, '零元整');
};

export const currentdateD = (d) => {
  let dd = new Date(d);
  return dd.getFullYear() + '/' + (dd.getMonth() + 1) + '/' + dd.getDate(); // +" "+dd.getHours() +':'+ dd.getMinutes() +':'+ dd.getSeconds()
}
export const currentdate = () => {
  let dd = new Date();
  return dd.getFullYear() + '/' + (dd.getMonth() + 1) + '/' + dd.getDate(); // +" "+dd.getHours() +':'+ dd.getMinutes() +':'+ dd.getSeconds()
}
export const currentyear = () => {
  let dd = new Date();
  return dd.getFullYear() //+ '/' + (dd.getMonth() + 1) + '/' + dd.getDate(); // +" "+dd.getHours() +':'+ dd.getMinutes() +':'+ dd.getSeconds()
}
export const currentdate2 = () => {
  let dd = new Date();
  return dd.getFullYear() + '-' + (dd.getMonth() + 1) + '-' + dd.getDate(); // +" "+dd.getHours() +':'+ dd.getMinutes() +':'+ dd.getSeconds()
}
export const currentdate3 = () => {
  let dd = new Date();
  let mon = dd.getMonth() + 1;
  if (mon < 10) {
    mon = "0" + mon;
  }
  return dd.getFullYear() + "-" + mon + "-" + dd.getDate(); // +" "+dd.getHours() +':'+ dd.getMinutes() +':'+ dd.getSeconds()
}

export const funcObjFind = (obj, id) => {
  let ret = "";
  obj.forEach(function (entry) {
    // console.log(entry);
    if (Number(entry.id) === id) {
      ret = entry.value;
    }
  });
  return ret;
}
export const funcObjFind2 = (obj, id) => {
  let ret = "";
  obj.forEach(function (entry) {
    // console.log(entry);
    if (Number(entry.value) === id) {
      ret = entry.text;
    }
  });
  return ret;
}

export const funcIsObjFindLabel = (obj, label) => {
  // console.log("funcIsObjFindLabel: "+label);
  // console.log(obj);

  for (let i = 0; i < obj.length; i++) {
    // console.log("obj[i].label: "+obj[i].label);
    if (obj[i].label === label) {
      return 1;
    }
  }
  return 0;
}
export const funcAddObjLabelValue = (obj, label, value) => {
  obj.forEach(function (entry) {
    // console.log(entry);
    if (entry.label === label) {
      entry.value += Number(value);
      return 1;
    }
  });
}
export const funcStrcatObjLabelValue = (arr, longlife_text) => {
  let str = "";
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].label.indexOf("長生燈") != -1) {
      str = str + arr[i].label + arr[i].value + longlife_text + " ";
    } else {
      str = str + arr[i].label + arr[i].value + " ";
    }
  }
  return str;
}

export const isChinese = (temp) => {
  let re = /[^\u4e00-\u9fa5]/;
  if (re.test(temp)) return false;
  return true;
}
export const isChineseStr = (str) => {
  let res = false;
  for (let i = 0; i < str.length; i++) {
    let c = str.charAt(i);
    if (isChinese(c)) {
      res = true;
      break;
    }
  }
  return res;
}

export const Blength = (str = '') => {
  const arr = str.match(/[^\x00-\xff]/gi);
  return arr === null ? str.length : str.length + arr.length;
};

export const counterDigitLowercase = (n) => {
  let str = n;
  let res = 0;
  for (let x = 0; x < str.length; x++) {
    let c = str.charAt(x);

    if (!!c) {
      if (x === 0) {
        res = digit.indexOf(c) * 10;
      } else if (x === 1) {
        res = res + digit.indexOf(c);
      }
    }
  }
  return res;
}
export const digitLowercase = (n) => {
  let str = Number(n).toString();
  let res = "";
  for (let x = 0; x < str.length; x++) {
    let c = str.charAt(x);
    if (!isNaN(Number(c))) {
      res = res + digit[Number(c)];
    } else {
      res = res + c;
    }
  }
  return res;
}
export const checkIsNumber = (number) => {
  return !isNaN(parseInt(number));
}
export const getCurrentPart = (numString, numTarget, numLowerTarget, numLowerUnitLength) => {
  //拆解成陣列
  numString = numString.split("");
  //預設回傳結果
  let result = "";
  //是否輸出過1-9
  let isLastNumberNonZero = false;
  //從後往前掃
  //1011一千○一十一
  for (let index = numString.length - 1; index >= 0; index--) {
    //目前位數
    let currentDigit = numString.length - index;
    //目前的數字
    let currentNumber = numString[index] - '0';
    //上一個位數為非0
    //剛開始為false(沒有上一個位數)，之後會偵測上一個位數(之前的小位數)是否為非0
    isLastNumberNonZero = ((index + 1) === numString.length) ? false : (numString[index + 1] - '0' > 0);
    //剛開始遇到零不處理，除非有之後遇到1-9
    if (isLastNumberNonZero || currentNumber > 0)
      result = numTarget[currentNumber] //數字
        +
        (currentNumber > 0 ? numLowerTarget[(currentDigit - 1) % numLowerUnitLength] : "") //小單位(個十百千) 大於0才會輸出
        +
        result; //上一個位數 
  }
  return result;
};
export const intToChineseNumberString = (number = 0, useLowerType = true) => {
  // let obj = this; //待回傳的物件
  const numLower = ['○', '一', '二', '三', '四', '五', '六', '七', '八', '九']; //小寫中文
  const numUpper = ['零', '壹', '貳', '參', '肆', '伍', '陸', '柒', '捌', '玖']; //大寫中文
  let numTarget = useLowerType ? numLower : numUpper; //指定要用哪一個中文（大小寫）
  const numLowerUnit1 = ['', '十', '百', '千']; //小寫中文小單位
  const numLowerUnit2 = ['', '拾', '佰', '仟']; //大寫中文小單位
  let numLowerTarget = useLowerType ? numLowerUnit1 : numLowerUnit2; //指定要用哪一個中文（小單位）
  let numLowerUnitLength = 4; //小單位長度
  const numLargeUnit = ['', '萬', '億', '兆', '京', '垓', '秭', '穰', '溝', '澗', '正', '載', '極']; //中文大單位
  let numNegative = "-"; //負值
  //判斷是否為數值型態

  console.log(number)
  //如果不是數值，則擲出例外
  if (!checkIsNumber(number)) {
    Error("輸入的形態不是Number");
    throw new Error("輸入的形態不是Number");
  }
  //如果是零，則直接輸出
  else if (number === 0) return numTarget[0];
  //轉成字串(使用絕對值避免處理負號)
  let numberString = String(Math.abs(number));
  //準備輸出的字串
  let output = "";
  //小單位(四位數)的傳回值(傳入數字字串)

  //剛開始小單位長度(前面多出的部份)，Ex 10000，多出的部份為1
  let initialPartLegth = numberString.length % numLowerUnitLength;
  if (initialPartLegth > 0)
    output = getCurrentPart(numberString.substr(0, initialPartLegth), numTarget, numLowerTarget, numLowerUnitLength) + numLargeUnit[Math.floor(numberString.length / 4)];
  //之後每次掃四個位數
  for (let i = initialPartLegth; i < numberString.length; i += numLowerUnitLength) {
    let partResult = getCurrentPart(numberString.substr(i, numLowerUnitLength), numTarget, numLowerTarget, numLowerUnitLength);
    output += partResult + (partResult !== "" ? numLargeUnit[(numberString.length - i) / 4 - 1] : "");
  }
  //回傳時如有負值，則加上-
  return (number < 0 ? numNegative : "") + output;
  //回傳物件
  // return obj;
}
export const dateAddSpace = (n) => {
  // let res = "";
  let i = 0, j = 0, k = 0;

  if (n.indexOf("年") !== -1) i = n.indexOf("年");
  if (n.indexOf("月") !== -1) j = n.indexOf("月");
  if (n.indexOf("日") !== -1) k = n.indexOf("日");

  let str = n;

  // 日的後面 再加個空白
  str = str.substr(0, k + 1) + " " + str.substr(k + 1);

  // 日
  if ((k - j) === 2) {
    str = str.substr(0, j + 1) + " " + str.substr(j + 1);
  }

  // 月
  if ((j - i) === 2) {
    str = str.substr(0, i + 1) + " " + str.substr(i + 1);
  }

  // 年
  if (i <= 2) {
    if (i === 1) {
      str = " " + str.charAt(0) + " " + str.substr(1);
    } else if (i === 2) {
      str = " " + str;
    }
  }
  // console.log(n+ " i: "+ i + " j: "+ j+" k: "+ k + " @"+ str);

  return str;
  /*    for (let x = 0; x < str.length; x++){
        let c = str.charAt(x);
        if(c ===  "年" || c ===  "月" || c ===  "日"){
          if(c ===  "年") i = x;
          if(c ===  "月") j = x;
          if(c ===  "日") k = x;
          if( i==2 ){
            res = res + c + " ";
          }
        }
        else{
          res = res + c;
        }
    }
    return res;*/
}

export const getLightType = (type) => {
  if (type === '1') {
    return '光明燈';
  } else if (type === '3') {
    return '藥師燈';
  } else if (type === '5') {
    return '光明大燈';
  } else if (type === '7') {
    return '藥師大燈';
  }
}

export const commaToSpace = (str = '') => {
  // console.log(this)
  if (typeof str !== 'undefined') {
    return str.replace(/,/g, " ");
  }
}
export const toStraight = (str = '', num = 1) => {
  let hex, i;

  let result = "";
  for (i = 0; i < str.length; i++) {
    hex = str.charAt(i);
    for (let j = 0; j < num; j++) {
      hex = hex + '\n'
    }
    result += hex;
  }
  return result
}

export const dateTW = (doc, x, y, printDate, fZ10) => {
  // console.log(x, y, setFontSize, printDate);
  // let top_padding = Math.floor(setFontSize / 10) * 2;

  // printDate format 2000-10-10
  // console.log(printDate);
  const datetimeY = !!printDate && !!printDate.length ? printDate.split('-')[0] - 1911 + '' : ''  // 民國 year
  const datetimeM = !!printDate && !!printDate.length ? printDate.split('-')[1] : ''  // month
  const datetimeD = !!printDate && !!printDate.length ? printDate.split('-')[2] : ''  // date

  // if (!!fZ10) {
  //   doc.setFontSize(setFontSize).setFont('BiauKai').text("中華民國", x, y)
  //   doc.setFont('Times', 'Roman').text("  " + datetimeY + "  ", x + 39, y)
  //   doc.setFont('BiauKai').text("年", x + 68, y)
  //   doc.setFont('Times', 'Roman').text("  " + datetimeM + "  ", x + 80, y)
  //   doc.setFont('BiauKai').text("月", x + 104, y)
  //   doc.setFont('Times', 'Roman').text("  " + datetimeD + "  ", x + 116, y)
  //   doc.setFont('BiauKai').text("日", x + 140, y);
  // } else {
  //   doc.setFontSize(setFontSize).setFont('BiauKai').text("中華民國", x, y)
  //   doc.setFont('Times', 'Roman').text("  " + datetimeY + "  ", x + 54, y)
  //   doc.setFont('BiauKai').text("年", x + 88, y)
  //   doc.setFont('Times', 'Roman').text("  " + datetimeM + "  ", x + 100, y)
  //   doc.setFont('BiauKai').text("月", x + 124, y)
  //   doc.setFont('Times', 'Roman').text("  " + datetimeD + "  ", x + 136, y)
  //   doc.setFont('BiauKai').text("日", x + 160, y);
  // }
  doc.setFont('BiauKai').text(`中華民國 ${datetimeY} 年 ${datetimeM} 月 ${datetimeD} 日`, x, y);

}

export const splice = (str, idx, rem, s) => {
  return (str.slice(0, idx) + s + str.slice(idx + Math.abs(rem)));
};

export const printTitle = (doc, printDate, entry, companyObj) => {
  // console.log(companyObj.name)
  let title_y = 30;
  let x = 50
  if (companyObj.isConsortium) {
    doc.setFont('BiauKai').setFontSize(14).text("財團法人", 50, title_y + 2);
    x += 66
  }
  doc.setFontSize(20).text(companyObj.name, x, title_y);

  x = x + (companyObj.name.length * 20) + 10
  doc.setFont('Times', 'Roman');
  doc.setFontSize(20).text(entry.year + "", x, title_y);

  x = x + 30
  doc.setFont('BiauKai');
  const str = " 年度 " + entry.pray1Text + " 詳細疏文"
  doc.setFontSize(20).text(str, x, title_y);

  x = x + (str * 20) + 10
  doc.setFontSize(14);
  dateTW(doc, 630, title_y + 3, printDate);

  // now_y = 50; // 內容開始的高
  doc.setFontSize(18);
}

export const newPage = (doc, printDate, entry, companyObj) => {
  doc.addPage();
  printTitle(doc, printDate, entry, companyObj);
}

export const printPageNum = (doc, num) => {
  doc.setFontSize(14);
  doc.setFont('BiauKai').text("第  " + num + "  頁", 360, 570);
  // doc.setFont('Times', 'Roman').text( + '', 388, 560);
  // doc.setFont('BiauKai').text(, 400, 560);
  // doc.setFont('BiauKai').text("頁，共", 400, 560);
  // doc.setFont('Times','Roman').text(totalPage, footer_width_shift+102, table_y+table_height+16, { align: 'center'});
  // doc.setFont('BiauKai').text("頁", footer_width_shift+135, table_y+table_height+15);
}

export const funcPad = (num, size) => {
  const s = "00000000" + num;
  return s.substr(s.length - size);
}

export const cutStrToArr = (str = '', cutNum) => {
  // if (typeof str !== 'undefined') {
  //   return [];
  // }
  let arrStr = [];
  let start = 0, next = 0;

  if (str.length < cutNum) { // 不用分割
    arrStr.push(str.toString());
  }
  else {
    // console.log("ori: " + this);
    cutNum = cutNum - 3;
    while (start < str.length && next !== -1) {
      next = str.indexOf(" ", start + cutNum);
      if (next !== -1) {
        arrStr.push(str.substring(start, next).trim());
        start = next;
      }
      else {
        arrStr.push(str.substring(start, str.length).trim());
        // console.log("start: "+ start + " next: "+ next );
      }
    }
  }
  // 每列的名字齊頭
  // console.log("arrStr");
  // console.log(arrStr);

  // 超過6個人的時候
  if (arrStr.length >= 6) {
    cutNum = 17;
    arrStr = [];
    start = next = 0;

    cutNum = cutNum - 3;
    while (start < str.length && next !== -1) {

      next = str.indexOf(" ", start + cutNum);
      if (next !== -1) {
        arrStr.push(str.substring(start, next).trim());
        start = next;
      }
      else {
        arrStr.push(str.substring(start, str.length).trim());
        // console.log("start: "+ start + " next: "+ next );
      }
    }
    // 每列的名字齊頭
    // console.log("arrStr");
    // console.log(arrStr);
  }

  if (arrStr.length === 1) {
    return arrStr;
  } else if (arrStr.length > 1) {
    // 先弄成名字的二維陣列
    let arrAll = [];
    for (let i = 0; i < arrStr.length; i++) {
      arrAll.push(arrStr[i].split(" "));
    }
    // console.log("");
    // console.log("arrAll");
    // console.log(arrAll);
    // 如果有7位的時候，第一個的第三個 要放到第三個的第二個
    if (arrAll.length === 3) {
      if (arrAll[1].length === 3 && arrAll[2].length === 1) {
        arrAll[2].unshift(arrAll[1].pop());
      }
    }
    // 如果有10位的時候，第一個的第三個 要放到第三個的第二個
    else if (arrAll.length === 4) {
      if (arrAll[2].length === 3 && arrAll[3].length === 1) {
        arrAll[3].unshift(arrAll[2].pop());
      }
    } else if (arrAll.length === 5) {
      // 如果有13位的時候，第一個的第三個 要放到第三個的第二個
      if (arrAll[3].length === 3 && arrAll[4].length === 1) {
        arrAll[4].unshift(arrAll[3].pop());
      }
      // 如果有17位的時候，第一個的第三個 要放到第三個的第二個
      else if (arrAll[3].length === 4 && arrAll[4].length === 1) {
        arrAll[4].unshift(arrAll[3].pop());
        arrAll[4].unshift(arrAll[1].pop());
      }
      // 18
      else if (arrAll[3].length === 4 && arrAll[4].length === 2) {
        arrAll[4].unshift(arrAll[3].pop());
      }
    }
    // console.log(arrStr);

    let maxArr = 0;
    for (let i = 0; i < arrAll.length; i++) {
      if (arrAll[i].length > maxArr) maxArr = arrAll[i].length;
    }
    let arrMaxNum = [];
    for (let i = 0; i < maxArr; i++) {
      arrMaxNum.push(0);
    }
    for (let i = 0; i < arrAll.length; i++) {
      for (let j = 0; j < arrAll[i].length; j++) {
        if (!!arrAll[i][j] && arrAll[i][j].length > arrMaxNum[j])
          arrMaxNum[j] = arrAll[i][j].length;
      }
    }
    // console.log("arrMaxNum");
    // console.log(arrMaxNum);

    for (let i = 0; i < arrAll.length; i++) {
      for (let j = 0; j < arrAll[i].length; j++) {
        if (!!arrAll[i][j] && arrAll[i][j].length < arrMaxNum[j]) {
          let sp = "";
          for (let k = 0; k < arrMaxNum[j] - arrAll[i][j].length; k++) {
            sp += " ";
          }
          arrAll[i][j] += sp;
        }
      }
    }
    // console.log("arrAll");
    // console.log(arrAll);

    let arrAll2 = [];
    for (let i = 0; i < arrAll.length; i++) {
      arrAll2.push(arrAll[i].join(" "));
    }
    // console.log("arrAll2");
    // console.log(arrAll2);

    return arrAll2;
  }
}

/////加頓號//////
export const cutStrToArr_1 = (str = "", cutNum) => {
  let arrStr = [];
  let start = 0
  let next = 0;

  if (str.length < cutNum) {
    // 不用分割
    arrStr.push(str.toString());
  } else {
    // console.log("ori: " + str);
    cutNum = cutNum - 3;
    while (start < str.length && next !== -1) {
      next = str.indexOf(" ", start + cutNum);
      if (next !== -1) {
        arrStr.push(str.substring(start, next).trim());
        start = next;
      } else {
        arrStr.push(str.substring(start, str.length).trim());
        // console.log("start: "+ start + " next: "+ next );
      }
    }
  }
  // 每列的名字齊頭
  // console.log("arrStr");
  // console.log(arrStr);x

  if (arrStr.length === 1) {
    let arrAll2 = [];
    let arrAll = [];
    for (let i = 0; i < arrStr.length; i++) {
      arrAll.push(arrStr[i].split(" "));
    }
    for (let i = 0; i < arrAll.length; i++) {
      arrAll2.push(arrAll[i].join("、"));
    }
    return arrAll2;
    // return arrStr;
  } else if (arrStr.length > 1) {
    // 先弄成名字的二維陣列
    let arrAll = [];
    for (let i = 0; i < arrStr.length; i++) {
      arrAll.push(arrStr[i].split(" "));
    }
    // console.log("");
    // console.log("arrAll");
    // console.log(arrAll);
    // console.log(arrStr);

    let maxArr = 0;
    for (let i = 0; i < arrAll.length; i++) {
      if (arrAll[i].length > maxArr) maxArr = arrAll[i].length;
    }
    let arrMaxNum = [];
    for (let i = 0; i < maxArr; i++) {
      arrMaxNum.push(0);
    }
    for (let i = 0; i < arrAll.length; i++) {
      for (let j = 0; j < arrAll[i].length; j++) {
        if (!!arrAll[i][j] && arrAll[i][j].length > arrMaxNum[j])
          arrMaxNum[j] = arrAll[i][j].length;
      }
    }
    // console.log("arrMaxNum");
    // console.log(arrMaxNum);

    // for (i = 0; i < arrAll.length; i++) {
    //     for (j = 0; j < arrAll[i].length; j++) {
    //         if (!!arrAll[i][j] && arrAll[i][j].length < arrMaxNum[j]) {
    //             let sp = "";
    //             for (k = 0; k < arrMaxNum[j] - arrAll[i][j].length; k++) {
    //                 sp += " ";
    //             }
    //             arrAll[i][j] += sp;
    //         }
    //     }
    // }
    // console.log("arrAll");
    // console.log(arrAll);

    let arrAll2 = [];
    for (let i = 0; i < arrAll.length; i++) {
      arrAll2.push(arrAll[i].join("、"));
    }
    // console.log("arrAll2");
    // console.log(arrAll2);

    return arrAll2;
  }
};

export const cutStrToArr2 = (str, cutNum) => {
  const arrStr = [];
  let start = 0;
  let next = 0;

  if (str.length < cutNum) {
    // 不用分割
    arrStr.push(str.toString());
  } else {
    // console.log("ori: " + this);
    cutNum = cutNum - 3;
    while (start < str.length && next !== -1) {
      next = str.indexOf(" ", start + cutNum);
      if (next !== -1) {
        arrStr.push(str.substring(start, next).trim());
        start = next;
      } else {
        arrStr.push(str.substring(start, str.length).trim());
        // console.log("start: "+ start + " next: "+ next );
      }
    }
  }
  // 每列的名字齊頭
  // console.log("arrStr");
  // console.log(arrStr);

  if (arrStr.length === 1) {
    return arrStr;
  } else if (arrStr.length > 1) {
    // 先弄成名字的二維陣列
    const arrAll = [];
    for (let i = 0; i < arrStr.length; i++) {
      arrAll.push(arrStr[i].split(" "));
    }
    // console.log("");
    // console.log("arrAll");
    // console.log(arrAll);
    // console.log(arrStr);

    let maxArr = 0;
    for (let i = 0; i < arrAll.length; i++) {
      if (arrAll[i].length > maxArr) { maxArr = arrAll[i].length };
    }
    const arrMaxNum = [];
    for (let i = 0; i < maxArr; i++) {
      arrMaxNum.push(0);
    }
    for (let i = 0; i < arrAll.length; i++) {
      for (let j = 0; j < arrAll[i].length; j++) {
        if (!!arrAll[i][j] && arrAll[i][j].length > arrMaxNum[j])
          arrMaxNum[j] = arrAll[i][j].length;
      }
    }

    const arrAll2 = [];
    for (let i = 0; i < arrAll.length; i++) {
      arrAll2.push(arrAll[i].join(" "));
    }
    // console.log("arrAll2");
    // console.log(arrAll2);
    return arrAll2;
  }
};

export const strChtEngMix2 = (doc, str, x, y, size) => {
  let xOffest = 2;
  let yOffest = 0;
  if (size >= 18) {
    xOffest = 4;
    yOffest = 1;
  }

  let now_x = x;
  let now_y = y;

  let doc_x = x;

  for (let i = 0; i < str.length; i++) {
    const nowchar = str.charAt(i);
    // console.log(nowchar.match(/[A-Z]/g));
    // console.log(Blength(nowchar) + " " + nowchar)
    if (Blength(nowchar) === 1 || nowchar.match(/（|）|｛|｝/)) { // 如果是英文或數字
      // const size2 = Math.ceil(size / 2);
      if (nowchar === "\n") { // 換行
        if (i !== 0 && i !== str.length - 1) {
          if (Blength(str.charAt(i + 1)) === 2) {  // 下一個字是全型的話 再真的換行
            now_y = now_y + size;
          } else if (Blength(str.charAt(i - 1)) === 2 && Blength(str.charAt(i + 1)) === 1) { // 第一個半型字，要再往下一點
            now_y = now_y + 6;
          }
        }
        now_x = x;
      } else {
        // doc.setFontSize(size2);
        doc.setFontSize(size).setFont("times", "normal");

        if (nowchar.match(/[a-z]|[A-Z]|[0-9]|(|)|{|}|（|）|｛|｝|-/)) { // 英數再做旋轉處理 不然一樣
          // doc.text(nowchar, now_x + 4, now_y, null, -90);
          doc.text(nowchar, now_x + xOffest, now_y + yOffest, null, -90);
          now_y = now_y + doc.getTextWidth(nowchar);
        } else {
          doc.text(nowchar, now_x, now_y);
        }
      }
    } else { // 如果是中文
      doc.setFontSize(size).setFont('BiauKai');
      doc.text(nowchar, now_x, now_y);

      if (doc_x - now_x !== size) { // 有字的話 就往下再跑一個
        now_x = doc_x + size;
      } else {
        now_x = doc_x;
      }
    }
  }
}
