import React, { Component } from 'react';
import { Row, Col, Table, Container, Header, Form, Icon, Modal, Select, Input, Button, Grid, TextArea, Tab } from 'semantic-ui-react'
import { MdSave, MdModeEdit, MdCancel } from "react-icons/md";
import ModalPdf from 'widget/pdf/ModalPdf';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import Parse from 'widget/parse'

import 'moment/locale/ja';

const CourseSelect = ({ course, name, handleChange, value }) => {
  const optionsCourse = course.sort((a, b) => b.orderId - a.orderId)
    .map(({ objectId, courseName }) => ({ key: objectId, text: courseName, value: objectId }))
  // optionsCourse.unshift({ key: 'empty', text: '- 選択 -', value: '0' });

  return (
    <Input >
      <Select
        options={optionsCourse} onChange={handleChange} value={value} /*defaultValue='0'*/ name={"course" + name} />
    </Input>
    // <Form.Group >
    //   <Form.Control
    //     as="select" placeholder="select" name={"course" + name}
    //     onChange={handleChange} value={value}
    //   >
    //     <option value="">-- 選択 --</option>
    //     {course.sort((a, b) => a.orderId - b.orderId).map(({ _id, value, courseName, courseTeacherText }) =>
    //       (<option key={_id} value={_id}>{courseName}</option>))}
    //   </Form.Control>
    // </Form.Group >
  );
};

export default class ViewCourseClass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEdit: false,
      form: {
        objectId: props.classroomObj.objectId,
        courseArrs: props.classroomObj.courseArrs ? props.classroomObj.courseArrs : []
      }
    };
  }

  componentWillMount() {
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReveiveProps');
    if (this.props.classroomObj !== nextProps.classroomObj) {
      this.setState({
        form: {
          objectId: nextProps.classroomObj.objectId,
          courseArrs: nextProps.classroomObj.courseArrs ? nextProps.classroomObj.courseArrs : []
        },
      });
    }
  }

  handleChangeCourseSelect = (event, data) => {
    const { course } = this.props;
    const { courseArrs = [] } = this.state.form;

    let fieldName = data.name;
    let fleldVal = data.value;

    if (courseArrs.length) {
      let removeIndex = courseArrs.map(item => item.slot).indexOf(fieldName);
      ~removeIndex && courseArrs.splice(removeIndex, 1); // 刪掉同一個slot的
    }
    let newArr = courseArrs;

    let courseObj = course.find(item => item.objectId === fleldVal);
    if (courseObj) { // 有東西要追加的話 再放到這邊
      const w = ["月", "火", "水", "木", "金"];
      const p = ["一", "二", "三", "四", "五", "六", "七", "八"];

      let obj = {
        slot: fieldName,
        course_id: fleldVal,
        courseName: courseObj.courseName,
        period: p[Number(fieldName.charAt(7))],
        weekday: w[Number(fieldName.charAt(6))],
      };
      newArr.push(obj);
    }
    // console.log(courseObj, newArr);

    this.setState({ form: { ...this.state.form, courseArrs: newArr } })
  }

  handleSubmit = async () => {
    // const { firebase } = this.props;

    const objectId = this.state.form.objectId;
    const courseArrs = this.state.form.courseArrs;
    // firebase.firestore().doc(`Classroom/${objectId}`).update({ courseArrs }).then(
    //   console.log(`Document update`)
    // );
    let obj = {
      objectId,
      courseArrs,
    }
    await Parse.saveData('Classroom', obj).then(
      console.log(`Document update`)
    )
    // let options = [...optionsArr]
    // let index = options.findIndex((item => item.key === selValue))
    // options[index].text = title;
    // this.setState({ optionsArr: options, isEdit: false })
    this.setState({ isEdit: false })
  }

  cancelEdit = () => {
    const { doc, form } = this.state;

    this.setState({ isEdit: false, form: { ...form, courseArrs: doc } });
  }

  clearEdit = () => {
    this.setState({ form: { ...this.state.form, courseArrs: [] } });
  }

  render() {
    const { classroomObj, course } = this.props;
    const { isEdit, form } = this.state;
    const classTimeArr1 = classroomObj.classTime === '1' ? ["一", "二", "三", "四"] : ["五", "六", "七", "八"];
    const classTimeArr2 = classroomObj.classTime === '1' ? [0, 1, 2, 3] : [4, 5, 6, 7];
    const arrPdf = [{
      classroomObj,
      form,
      course
    }]
    return (
      <>
        <div style={{ float: 'right' }}>
          {isEdit ?
            <>
              <Button positive onClick={this.handleSubmit} icon='save' content='保存' />
              <Button basic onClick={this.cancelEdit} icon='times' content='キャンセル' />
              <Button negative onClick={this.clearEdit} icon='times' content='クリア' />
            </>
            : <>
              <Button positive onClick={() => this.setState({ isEdit: true, doc: [...form.courseArrs] })} icon="edit" className="no-print" content="編集" />
              <ModalPdf
                {...this.props}
                // ref={(ref) => { this.pdfModal = ref }}
                codekey="classTimeTablePdf"
                params={arrPdf}
              />
              <ExportTableToExcelButton floated='right' />
            </>}
        </div>
        <div style={{ clear: 'both' }}></div>

        <br />
        <Table celled striped style={{ textAlign: "center", width: "100%" }} id='excel'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>&nbsp;</Table.HeaderCell>
              <Table.HeaderCell>月</Table.HeaderCell>
              <Table.HeaderCell>火</Table.HeaderCell>
              <Table.HeaderCell>水</Table.HeaderCell>
              <Table.HeaderCell>木</Table.HeaderCell>
              <Table.HeaderCell>金</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              classTimeArr1 && classTimeArr1.map((item, index) => (
                <Table.Row key={`tr${index}`}>
                  <Table.Cell>{item}</Table.Cell>
                  {([1, 2, 3, 4, 5]).map((item2, index2) => {
                    let course_id = '';
                    if (form.courseArrs && form.courseArrs.find(element => element.slot === 'course' + index2 + classTimeArr2[index])) {
                      course_id = form.courseArrs.find(element => element.slot === 'course' + index2 + classTimeArr2[index]).course_id;
                    }
                    return (
                      isEdit ?
                        <Table.Cell>
                          <CourseSelect
                            key={item2} course={course}
                            name={index2 + "" + classTimeArr2[index]}
                            handleChange={this.handleChangeCourseSelect}
                            value={course_id}
                          />
                        </Table.Cell> :
                        course_id ?
                          course && course.find(item3 => item3.objectId === course_id) ? <Table.Cell key={'a' + index2}>{course.find(item3 => item3.objectId === course_id).courseName}</Table.Cell> : <Table.Cell key={'b' + index2}></Table.Cell>
                          : <Table.Cell key={'c' + index2}></Table.Cell>
                    )
                  })}
                </Table.Row>
              ))
            }
          </Table.Body>
        </Table>
      </>
    );
  }
}
