import React, { Component } from 'react'
// import { Table, Header, Segment, Tab, Select } from 'semantic-ui-react';
// import MaterialTable, { MTableToolbar } from 'material-table'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row, Col,
  Form, FormGroup, Input, Label, Button
} from "reactstrap";

import intl from 'react-intl-universal';

import Parse from "widget/parse";
import ModalAutoAddr from './ModalAutoAddr';
import { fieldCol } from "views/formDef/function";
import { employeePawn } from "views/formDef/object";

class EmployeeTab1 extends Component {
  state = {
    employeeObj: this.props.employeeObj
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ employeeObj: nextProps.employeeObj });
  }

  onRowDataChange = (employeeObj) => {
    // this.setState({ form: { ...this.state.form, postcode, addr } })
    this.setState({ employeeObj })
  }

  handleChange = (e, data) => {
    // console.log(e, data)
    // console.log(typeof e)
    if (e.target) {
      this.setState({ employeeObj: { ...this.state.employeeObj, [e.target.name]: e.target.value } })
    } else {
      this.setState({ employeeObj: { ...this.state.employeeObj, [data.name]: e } })
    }
  }

  handleSave = async () => {
    const { employeeObj } = this.state;
    await Parse.saveData('User', employeeObj);
    window.alert('已儲存')
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render() {
    const { employeeObj } = this.state

    return (<div className="content">
      {/* 排版整理好，用和client一樣的 */}
      <Card>
        <CardBody>
          <Form>
            <Row form>
              {fieldCol(employeePawn.name, "", "3", employeeObj.name, this.handleChange)}
              {fieldCol(employeePawn.sexualId, "", "3", employeeObj.sexualId, this.handleChange)}
              {fieldCol(employeePawn.birthday, "", "3", employeeObj.birthday, this.handleChange)}
            </Row>
            <Row>
              {fieldCol(employeePawn.residentialPhone, "", "3", employeeObj.residentialPhone, this.handleChange)}
              <Col md={1}>
                <FormGroup>
                  <ModalAutoAddr {...this.props} type='3' rowData={employeeObj} field1="residentialPostcode" field2="residentialAddr" onRowDataChange={this.onRowDataChange} />
                </FormGroup>
              </Col>
              {fieldCol(employeePawn.residentialAddr, "", "4", employeeObj.residentialAddr, this.handleChange)}
            </Row>
            <Row>
              {fieldCol(employeePawn.censusPhone, "", "3", employeeObj.censusPhone, this.handleChange)}
              <Col md={1}>
                <FormGroup>
                  <ModalAutoAddr {...this.props} type='3' rowData={employeeObj} field1="censusPostcode" field2="censusAddr" onRowDataChange={this.onRowDataChange} />
                </FormGroup>
              </Col>
              {fieldCol(employeePawn.censusAddr, "", "4", employeeObj.censusAddr, this.handleChange)}
            </Row>
            <hr style={{ margin: '20px' }} />
            <Row form>
            </Row>
            <FormGroup>
              <Label for="exampleText">備註</Label>
              <Input type="textarea" name="ps" id="ps" value={employeeObj.ps || ''} />
            </FormGroup>
            <Button onClick={this.handleSave}>{intl.get('儲存')}</Button>
          </Form>
        </CardBody>
      </Card>
    </div>
    );
  }
}

export default EmployeeTab1;
