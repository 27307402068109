import React, { Component } from 'react';
import { Button, Header, Divider, Checkbox, Form } from 'semantic-ui-react';
import { Row, Col } from 'reactstrap';
import intl from 'react-intl-universal';
import { Card, CardBody, CardHeader, } from "reactstrap";
import Parse from 'widget/parse'

import List4Client from './List4Client';
import { clientHanbury } from "views/formDef/object";
import { fieldCol } from "views/formDef/function";

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export default class List1Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      isReadOnly: false,
      form: {},
      loading: true,
      confirm: false,
      unsub: null,
      isSpread: false
    };
  }

  async componentDidMount() {
    const { agent } = this.props
    const agentSel = agent.map(item => ({ key: item.objectId, text: item.name, value: item.objectId }))
    this.setState({ agentSel })
  }

  componentWillReceiveProps(nextProps) {
  }

  handleIsSpread = () => {
    if (this.state.isSpread === false) {
      this.setState({ isSpread: true })
    } else {
      this.setState({ isSpread: false })
    }
  }

  handleIsSpread2 = () => {
    if (this.state.isSpread2 === false) {
      this.setState({ isSpread2: true })
    } else {
      this.setState({ isSpread2: false })
    }
  }

  handleChange = (event, data) => {
    const { handleSelectUpdate } = this.props
    const { name, value } = data;
    this.setState({ [name]: value })
  }

  render() {
    const { isEdit, doc, auth } = this.props
    const { isSpread, isSpread2, agentSel } = this.state
    return (<>
      <div style={style.flexCenter}>
        <Header as='h2' content='基本資料' />
        <div style={{ marginTop: '15px' }}>
          {isEdit ? <Button positive size='small' onClick={this.props.saveUpdateSetting} icon='save' content={intl.get('儲存')} /> : <Button primary size='small' onClick={this.props.handleIsEdit} icon='edit' content={intl.get("編輯")} disabled={!auth.edit} />}
          {isEdit ? <Button color="red" size='small' onClick={this.props.handleConfirm} icon='delete' content={intl.get('刪除')} /> : null}
          {isEdit ? <Button basic size='small' onClick={this.props.cancelUpdateSetting} icon='remove' content={intl.get('取消')} /> : null}
        </div>
      </div>
      <Divider />
      <Row>
        <Col>
          <Card outline color='secondary' fluid style={{}} >
            <CardHeader tag='h4' style={{ backgroundColor: '#f07b51' }} >
              本人基本情報
            </CardHeader>
            <CardBody>
              <Row>
                {fieldCol(clientHanbury.uid, "pr-1", "3", doc.uid, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.name_cht, "pr-1", "3", doc.name_cht, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.name_eng, "pr-1", "3", doc.name_eng, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.birthday, "pr-1", "3", doc.birthday, this.props.handleChangeField, !isEdit)}
              </Row>
              <Row>
                {fieldCol(clientHanbury.identify, "pr-1", "3", doc.identify, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.email, "pr-1", "3", doc.email, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.email2, "pr-1", "3", doc.email2, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.title_text, "pr-1", "3", doc.title_text, this.props.handleChangeField, !isEdit)}
              </Row>
              <Row>
                {fieldCol(clientHanbury.country_text, "pr-1", "3", doc.country_text, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.country2_text, "pr-1", "3", doc.country2_text, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.marriage, "pr-1", "3", doc.marriage, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.passport, "pr-1", "3", doc.passport, this.props.handleChangeField, !isEdit)}
              </Row>
              <Row>
                {fieldCol(clientHanbury.cellnum, "pr-1", "3", doc.cellnum, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.addr1_phone, "pr-1", "3", doc.addr1_phone, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.sexual_text, "pr-1", "3", doc.sexual_text, this.props.handleChangeField, !isEdit)}
                <Col sm='3'><Form.Select fluid label='agent' options={agentSel} id='agent_id' name='agent_text' onChange={this.props.handleSelectUpdate} value={doc.agent_id || ''} disabled={!isEdit} /></Col>
                {/* {fieldCol(clientHanbury.agent_id, "pr-1", "3", doc.agent_id, this.props.handleChangeField, !isEdit)} */}
              </Row>
              <Row>
                {fieldCol(clientHanbury.addr1_post5, "pr-1", "3", doc.addr1_post5, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.addr1_eng, "pr-1", "3", doc.addr1_eng, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.addr2_cht, "pr-1", "3", doc.addr2_cht, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.addr2_eng, "pr-1", "3", doc.addr2_eng, this.props.handleChangeField, !isEdit)}
                {/* </Grid> */}
              </Row>
              <Row>
              </Row>
              <br />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <Row>
        <Col>
          <Card outline color='secondary' fluid style={{}}>
            <a style={{ cursor: 'pointer' }} onClick={this.handleIsSpread}>
              <CardHeader tag='h4' style={{ backgroundColor: '#f07b51' }}>
                {/* <CardTitle  inverse>本人基本情報</CardTitle> */}
                  公司 & 財務
                </CardHeader>
            </a>
            {isSpread ? <CardBody >
              <Row>
                {fieldCol(clientHanbury.fin_com_name, "pr-1", "3", doc.fin_com_name, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.fin_com_name_eng, "pr-1", "3", doc.fin_com_name_eng, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.fin_title, "pr-1", "3", doc.fin_title, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.fin_com_tele, "pr-1", "3", doc.fin_com_tele, this.props.handleChangeField, !isEdit)}
              </Row>
              <Row>
                {fieldCol(clientHanbury.fin_com_fax, "pr-1", "3", doc.fin_com_fax, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.fin_com_addrcht, "pr-1", "3", doc.fin_com_addrcht, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.fin_com_addreng, "pr-1", "3", doc.fin_com_addreng, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.fin_invest_exp, "pr-1", "3", doc.fin_invest_exp, this.props.handleChangeField, !isEdit)}
              </Row>
              <Row>
                {fieldCol(clientHanbury.fin_invest_purpose, "pr-1", "3", doc.fin_invest_purpose, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.fin_ps, "pr-1", "3", doc.fin_ps, this.props.handleChangeField, !isEdit)}
              </Row>
              <Row>
                {fieldCol(clientHanbury.is_periodmail, "pr-1", "3", doc.is_periodmail, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.periodmail2, "pr-1", "3", doc.periodmail2, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.periodmail3, "pr-1", "3", doc.periodmail3, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.periodmail4, "pr-1", "3", doc.periodmail4, this.props.handleChangeField, !isEdit)}
              </Row>
              <Row>
                {fieldCol(clientHanbury.periodmail5, "pr-1", "3", doc.periodmail5, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.periodmail6, "pr-1", "3", doc.periodmail6, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.periodmail7, "pr-1", "3", doc.periodmail7, this.props.handleChangeField, !isEdit)}
                {fieldCol(clientHanbury.periodmail8, "pr-1", "3", doc.periodmail8, this.props.handleChangeField, !isEdit)}
              </Row>
            </CardBody> : null}
          </Card>
          <br />
          <br />
          <br />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card outline color='secondary' fluid style={{}}>
            <a style={{ cursor: 'pointer' }} onClick={this.handleIsSpread2}>
              <CardHeader tag='h4' style={{ backgroundColor: '#f07b51' }}>
                附加檔案
                </CardHeader>
            </a>
            {isSpread2 ? <CardBody ><List4Client {...this.props} /></CardBody> : null}
          </Card>
        </Col>
      </Row>
    </>
    )
  }
}