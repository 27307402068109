import React, { Component } from 'react'
// import { Button } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
// import ReactFileReader from 'react-file-reader';
// import { Select, MenuItem } from '@material-ui/core';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
// import { RowImage } from 'views/web/RowImage';
// import ModalUpdateFile from 'views/web/ModalUpdateFile'
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from 'widget/ckeditor';
// import RowsDnd from './RowsDnd'
import TestBankImport2 from './TestBankImport2'
// reactstrap components
import { Row, Col } from "reactstrap";
// import ModalUpdateFile from "views/web/ModalUpdateFile";
// import { render } from "react-dom/cjs/react-dom.development";
// import { imgBtnCol, imgPreviewCol } from 'views/web/CustomMaterialTable';
// import { Input, TextField } from "@material-ui/core";

const YNSel = { 'Y': 'Y', 'N': 'N' }
const verSel = { '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', '6': '6', '7': '7' }
const answerSel = { 'A': 'A', 'B': 'B', 'C': 'C', 'D': 'D' }
const parSel = { '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', '6': '6', '7': '7' }
const partOpt = [
  { key: '1', text: '1', value: '1' },
  { key: '2', text: '2', value: '2' },
  { key: '3', text: '3', value: '3' },
  { key: '4', text: '4', value: '4' },
  { key: '5', text: '5', value: '5' },
  { key: '6', text: '6', value: '6' },
  { key: '7', text: '7', value: '7' },
]
const readSel = { 'topic': '文章', 'question': '題目' }


class TestBankImport extends Component {
  state = {
    activeItem: 'bio',
    dataArr: [],
    exDataArr: [],
    dataObjArr: [],
    rowData: {},
    loading: true,
    loadingData: true,
    show: false,
    isFile: false,
    loading2: false,
    preview: false,
  }

  async componentDidMount() {
    this.handleFetch()
  }

  handleFetch = async () => {
    const { newData } = this.state;
    const testBank = await Parse.queryData('testBank');
    // const testExBank = await Parse.queryData('testExBank');
    this.setState({ dataArr: testBank, loading: false });
  }



  handleAdd = async () => {
    const { newData } = this.state;
    await Parse.saveData('testBank', newData);
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    await Parse.saveData('testBank', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    await Parse.deleteData('testBank', oldData);
    this.setState({ oldData: {} })
  }

  handleClickName = async (verId) => {
    if (this.state.verId === verId) {
      this.setState({ show: !this.state.show })
    } else {
      this.setState({ show: true, verId, })
    }
  }


  handleData = (rowData) => {
    this.setState({ rowData });
  }

  handleChange = (e, data) => {
    // const { name, value } = data
    // console.log(e.target)
    // onRowDataChange({ ...rowData, [name]: value })
    if (e.target.value === '1' || e.target.value === '3' || e.target.value === '4') {
      this.setState({ isFile: true })
    } else {
      this.setState({ isFile: false })
    }
    // this.setState({ [name]: value });
  }


  render() {
    const { dataArr, loading, show, verId } = this.state
    const { companyObj } = this.props
    return (<div className="content">
      <Row>
        <Col><legend>1-3 題庫匯入</legend></Col>
      </Row>
      <Row>
        <Col>
          <MaterialTable
            isLoading={loading}
            localization={localization()}
            // tableRef={this.tableRef}
            title="題庫總覽"
            options={{
              search: false,
            }}
            columns={[
              { width: 50, title: '版本', field: 'version', lookup: verSel },
              { width: 80, title: '題庫名稱', field: 'name' },
              // { width: 50, title: '姓別', field: 'sexualId', lookup: { '1': '男', '2': '女' } },
              // { width: 250, title: '地址', field: 'addr', cellStyle: { width: '250px' }, headerStyle: { width: '250px' } },
              // { width: 80, title: '手機', field: 'cellphone' },
              // { width: 80, title: '電話', field: 'telephone' },
            ]}
            data={dataArr}
            // data={query =>
            //   new Promise((resolve, reject) => {
            //     console.log(query)
            //   })
            // }
            onRowClick={(e, rowData) => this.handleClickName(rowData.version)}
            // actions={[
            //   {
            //     icon: 'group', // view_headline
            //     tooltip: '顯示家庭',
            //     onClick: (event, rowData) => this.handleClickName(rowData.familyId)
            //   },
            // ]}
            editable={{
              onRowAdd: newData =>
                new Promise(async (resolve, reject) => {
                  const data = [...dataArr];
                  const db = Parse.Object.extend("testBank");
                  const newDoc = new db();
                  const doc = await newDoc.save();
                  let obj = {
                    id: doc.id,
                    _id: doc.id,
                    objectId: doc.id,
                    companyId: companyObj.objectId,
                    companyName: companyObj.name,
                  }
                  data.push(obj);
                  this.setState({ dataArr: data, newData: obj }, () => resolve());
                }).then(() => this.handleAdd(0)),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const data = [...dataArr];
                  const index = data.indexOf(oldData);
                  data[index] = newData;
                  console.log(newData)
                  // handleState('people0', data);
                  this.setState({ dataArr: data, newData, oldData }, () => resolve());
                }).then(() => this.handleUpdate(0)),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  let data = [...dataArr];
                  const index = data.indexOf(oldData);
                  data.splice(index, 1);
                  // handleState('people0', data);
                  this.setState({ dataArr: data, oldData }, () => resolve());
                }).then(() => this.handleDelete(0)),
            }}
          />
          <br />
          <br />
        </Col>
      </Row>
      <Row>
        <Col>
          {show ?
            <TestBankImport2 {...this.props} verId={verId} />
            : null}
        </Col>
      </Row>
    </div>
    );
  }
}

export default TestBankImport;
