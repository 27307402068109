import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select, Input, Form } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'

import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Moment from 'moment';
import Parse, { funcPad } from 'widget/parse'
// import localization from 'widget/MaterialTableOpt'
// import { mgyearOpt, monthOpt, exportPdf } from 'views/web/CustomMaterialTable';
// import MembersTable from './MembersTable';
// import BoardgameTable from './BoardgameTable';
import InAndOut from './InAndOut';
import CrewOrder from './CrewOrder';

const style = {
  divSpaceBlock: {
    position: 'absolute',
    borderRadius: '5px',
    border: '1px dashed #000',
    padding: '10px',
    textAlign: 'center',
    fontSize: '30px',
  },
  divTableBlock: {
    position: 'absolute',
    borderRadius: '5px',
    border: '1px solid #000',
    padding: '8px',
    textAlign: 'center',
    height: '80px',
    cursor: 'pointer',
    width: '170px',

  }
}
const columns = () => ([
  { width: 80, title: '預約日期', field: 'reserveDate' },
  { width: 80, title: '時間', field: 'reserveTime' },
  { width: 80, title: '人數', field: 'reservePeople' },
  { width: 80, title: '座位', field: 'reserveTable' },
  { width: 80, title: '姓名', field: 'reserveName' },
  { width: 80, title: '電話', field: 'reserveNum' },
  { width: 80, title: '備註', field: 'reservePs' },
]);
const opt = { '1': '報價中', '2': '訂單成立 撿貨中', '3': '撿貨完成 出貨中', '4': '部分產品 已出貨', '5': '所有產品 已出貨', '6': '收到物品 已完成(已自取)', '7': '已取消' }

const showTable = (ordering1, tableName) => {
  return (<>
    <span style={{ fontSize: '22px' }}>{tableName}   </span><span style={{ fontSize: '16px' }}>{(ordering1 && `${ordering1.openChair}位`) || ''}</span >
    <br />
    <span style={{ fontSize: '16px' }}>{(ordering1 && `開桌時間：${Moment(ordering1.openTime.iso).format('hh:mm:ss')}`) || ''}</span>
    <br />
    <span style={{ fontSize: '16px' }}>{(ordering1 && `目前金額：${(ordering1.totalTablePrice || 0) + (ordering1.totalDrinkPrice || 0) + (ordering1.totalFoodPrice || 0) - (ordering1.receivedTotalPrice || 0)} 元`)}</span>
  </>)
}

const d = new Date()
const tolerance_time = 10;
let timer = null
class CrewOpentable extends Component {
  state = {
    activeItem: 'bio',
    data: [],
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
    rowData: {},
    memberObj: {},
    boardgameObj: {},
    publisherId: '-1',
    publishersOpt: [],
    boardgameArr: [],
    boardgameArr2: [],
    gameDeposit: 0,
    open: false,
    number: '1',
    mealTypeOpt: [],
    mealTypeId: '-1',
    mealsArr: [],
    listArr: [],
    profile: {},
    nowTable: '',
    nowTime: '',
    timeObj: {},
    ordering1Arr: [],
    subscription1: null,
    ordering1Id: '',
    reserveData: [],
    employeeArr: []
    // subscription2: null,
  }

  async componentDidMount() {
    ///
    // let query = new Parse.Query('ordering1');
    // // query.equalTo('isOpen', '1');
    // let subscription1 = await query.subscribe();
    // // let query2 = new Parse.Query('ordering2');
    // // let subscription2 = await query2.subscribe();
    // this.setState({
    //   subscription1,
    //   // subscription2
    // });
    this.handleFetch()


    // subscription1.on('create', (object) => {
    //   console.log('object created', object);
    //   const ordering1Arr = [...this.state.ordering1Arr, object]
    //   this.setState({ ordering1Arr })
    // });

    // subscription1.on('update', (object) => {
    //   console.log('object updated', object);
    //   const ordering1Arr = [...this.state.ordering1Arr, object]
    //   this.setState({ ordering1Arr })
    // });
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  componentWillUnmount() {
    const { subscription1, subscription2 } = this.state
    // subscription1.unsubscribe();
    // subscription2.unsubscribe();
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, user, match } = props
    const companyId = companyObj.objectId
    const { ordering1Id } = match.params;
    // console.log(ordering1Id);
    const reserveData = await Parse.queryData('reserveData', { companyId });
    const ordering1Arr = await Parse.queryData('ordering1', { companyId, isOpen: '1' });
    const employeeArr = await Parse.queryData('User', { authLoginA: companyId })
    const employeeOpt = []
    employeeArr.forEach(item => {
      employeeOpt.push({ key: item.objectId, value: item.objectId, text: item.name })
    })
    this.func()
    this.setState({ employeeArr, employeeOpt, ordering1Arr, ordering1Id, reserveData })
  }

  func = async () => {
    const { companyObj } = this.props
    const { ordering1Obj } = this.state
    // console.log(ordering1Id)
    const companyId = companyObj.objectId
    const dataArr = await Parse.queryData('ordering2', { companyId, isDrink: '0', isOpen: '1' });
    const ordering1Arr = await Parse.queryData('ordering1', { companyId, isOpen: '1' });
    // const d1 = new Date((dataArr.length && dataArr[0].openTime && dataArr[0].openTime.iso) || '')
    // const d2 = new Date((dataArr.length && dataArr[0].currentTime && dataArr[0].currentTime.iso) || '')
    // const mins = parseInt(d2 - d1) / 1000 / 60
    // let min = Math.ceil(mins / 60)
    // this.setState({ dataArr })

    // if (dataArr.length && dataArr[0].floor === '1') {
    const promises = [];
    //   let totalTablePrice = 0

    dataArr.forEach(async (item) => {
      let totalTablePrice = 0
      if (item.floor === '1') {
        const d1 = new Date((item.openTime && item.openTime.iso) || '')
        const d2 = new Date()
        const mins = parseInt(d2 - d1) / 1000 / 60
        let money = 0;
        let payWay = ''
        if (item.isMember === '0') {
          if (mins < 30) {
            money = 0;
            payWay = '未滿半小時'
          } else if (mins <= 60 + tolerance_time) {
            money = 50;
            payWay = '一小時'
          } else if (mins <= 120 + tolerance_time) { // 2 hr
            money = 100;
            payWay = '二小時'
          } else if (mins <= 180 + tolerance_time) { // 3 hr
            money = 140;
            payWay = '三小時'
          } else if (mins <= 240 + tolerance_time) { // 4 hr
            money = 180;
            payWay = '四小時'
          } else {
            money = 230;
            payWay = '包日'
          }
        } else {
          if (mins < 30) {
            money = 0;
            payWay = '未滿半小時'
          } else if (mins <= 60 + tolerance_time) {
            money = 40;
            payWay = '一小時'
          } else if (mins <= 120 + tolerance_time) { // 2 hr
            money = 80;
            payWay = '二小時'
          } else if (mins <= 180 + tolerance_time) { // 3 hr
            money = 110;
            payWay = '三小時'
          } else if (mins <= 240 + tolerance_time) { // 4 hr
            money = 150;
            payWay = '四小時'
          } else {
            money = 180;
            payWay = '包日'
          }
        }
        promises.push(Parse.saveData('ordering2', {
          ...item,
          currentTime: new Date(),
          playedMins: Number(mins.toFixed(0)),
          price: String(money),
          payWay
          // money
        }))
      } else if (item.floor === '2') {
        const d1 = new Date((item.openTime && item.openTime.iso) || '')
        const d2 = new Date()
        const mins = parseInt(d2 - d1) / 1000 / 60
        // let min = Math.ceil(mins / 60)
        promises.push(Parse.saveData('ordering2', {
          ...item,
          currentTime: new Date(),
          playedMins: Number(mins.toFixed(0)),
          // price: String(min * 100)
          // money
        }))
      }
      // ordering1Arr.forEach(async (item) => {
      //   const dataArr = await Parse.queryData('ordering2', { companyId, ordering1Id: item.objectId, isDrink: '0' });
      //   dataArr.forEach(item2 => {
      //     totalTablePrice+=totalTablePrice+item2.
      //   })
      // })
    })
    timer = setTimeout(this.func, 60000)
    await Promise.all(promises).catch(error => console.log("error", error));
    // await Promise.all(promises).catch(error => console.log("error", error));
    // await Parse.saveData('ordering1', { ...ordering1Obj, totalTablePrice });
    // this.setState({ ordering1Obj: { ...ordering1Obj, totalTablePrice } })

    //   } else if (dataArr.length && dataArr[0].floor === '2') {
    //     const promises = [];
    //     const d1 = new Date((dataArr.length && dataArr[0].openTime && dataArr[0].openTime.iso) || '')
    //     const d2 = new Date((dataArr.length && dataArr[0].currentTime && dataArr[0].currentTime.iso) || '')
    //     const mins = parseInt(d2 - d1) / 1000 / 60
    //     let min = Math.ceil(mins / 60)
    //     dataArr.forEach(item => {
    //       promises.push(Parse.saveData('ordering2', {
    //         ...item,
    //         currentTime: new Date(),
    //         playedMins: Number(mins.toFixed(0)),
    //         // price: String(min * 100)
    //         // money
    //       }))
    //     })
    //     await Promise.all(promises).catch(error => console.log("error", error));
    //     await Parse.saveData('ordering1', { ...ordering1Obj, totalTablePrice: min * 100 });
    //     this.setState({ ordering1Obj: { ...ordering1Obj, totalTablePrice: min * 100 } })
    //   }
    //   // setTimeout(this.func, 60000);
    //   timer = setTimeout(this.func, 60000)
  };

  handleOpentable = async () => {
    const { history, companyObj } = this.props
    const companyId = companyObj.objectId;
    const { nowTable, number, profile, ordering1Arr } = this.state
    const companyObjLive = await Parse.fetchDocument('company', companyId, ['yearDef', 'orderalphaDef', 'ordernumDef', 'pray1IdDef']);
    const { orderalphaDef2, ordernumDef2 } = companyObjLive;
    const uid = orderalphaDef2 + funcPad(Number(ordernumDef2) + 1, 8);
    let floor = ''
    let payWay = ''
    let money = 0
    if (nowTable === 'A1' || nowTable === 'A2' || nowTable === 'A3' || nowTable === 'A4' || nowTable === 'A5') {
      floor = '1'
      payWay = '未滿半小時'
      money = 0
    } else {
      floor = '2'
      money = 0
    }

    const db = Parse.Object.extend("ordering1");
    const newDoc = new db();
    const doc = await newDoc.save();

    const obj = {
      objectId: doc.id,
      id: doc.id,
      _id: doc.id,
      companyId: companyObj.objectId,
      companyName: companyObj.name,
      tableName: nowTable,
      orderNum: uid,
      floor,
      openTime: new Date(),
      orderDateText: Moment(d).format('YYYY-MM-DD'),
      openChair: number,
      openPeople: (profile && profile.name) || '',
      isOpen: '1',
      totalTablePrice: money,
      money
    };
    await Parse.saveData('ordering1', obj);
    ordering1Arr.push(obj)
    this.setState({ ordering1Arr })

    const promises = [];
    for (let i = 0; i < number; i++) {
      const db = Parse.Object.extend("ordering2");
      const newDoc = new db();
      promises.push(newDoc.save())
    }
    const arr = await Promise.all(promises).catch(error => console.log("error", error));
    const promises2 = [];
    // console.log(promises);
    arr.forEach(doc => {
      promises2.push(Parse.saveData('ordering2', {
        ...obj,
        objectId: doc.id,
        id: doc.id,
        _id: doc.id,
        ordering1Id: obj.objectId,
        ordering2Id: doc.id,
        isFood: '0',
        isDrink: '0',
        isTable: '1',
        isMember: '0',
        floor,
        price: String(money),
        countMethod: "非會員價",
        payWay,
        currentTime: new Date(),
        money
      }));
    });
    await Promise.all(promises2).catch(error => console.log("error", error));

    //company
    const newOrdernumDef2 = String(Number(ordernumDef2) + 1);
    await Parse.saveData('company', { ...companyObj, ordernumDef2: newOrdernumDef2 });

    this.setState({ open: false, }, clearTimeout(timer), history.push(`/admin/CrewOpentable/${obj.objectId}`))

  }

  open = (nowTable) => {
    const { history } = this.props
    const { ordering1Arr } = this.state
    // console.log(ordering1Arr)
    const ordering1Id = (ordering1Arr.find(item => item.tableName === nowTable) && ordering1Arr.find(item => item.tableName === nowTable).objectId) || ''
    if (ordering1Id) {
      history.push(`/admin/CrewOpentable/${ordering1Id}`)
    } else {
      this.setState({ nowTable, time: Moment().format('LT'), open: true })
    }
  }

  close = () => { this.setState({ open: false }) }

  handleButton = (type) => {
    this.setState({ number: type })
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  handleReserve = async (rowData) => {
    const { reserveData } = this.state
    await Parse.deleteData('reserveData', rowData);
    const indexof = reserveData.indexOf(rowData);
    reserveData.splice(indexof, 1)
    this.setState({ reserveData })
  }
  // func = () => {
  //   const { nowTable, timeObj, A1 } = this.state
  //   const d1 = A1.openTime
  //   const d2 = new Date();
  //   console.log(parseInt(d2 - d1) / 1000 / 60);
  //   const mins = parseInt(d2 - d1) / 1000 / 60
  //   let money = 0;
  //   if (mins < 0) {
  //     money = 0;
  //   }
  //   if (mins <= 60 + tolerance_time) {
  //     money = 60;
  //   } else if (mins <= 90) {  // 1.5hr
  //     money = 90;
  //   } else if (mins <= 120 + tolerance_time) { // 2 hr
  //     money = 120;
  //   } else if (mins <= 150) { // 2.5 hr
  //     money = 150;
  //   } else if (mins <= 180 + tolerance_time) { // 3 hr
  //     money = 180;
  //   } else if (mins <= 210) { // 3.5 hr
  //     money = 210;
  //   } else if (mins <= 240 + tolerance_time) { // 4 hr
  //     money = 240;
  //   } else if (mins <= 270) { // 4.5 hr
  //     money = 270;
  //   } else {
  //     money = 300;
  //   }

  //   this.setState({ A1: { ...A1, money } })
  //   // setTimeout(this.func, 1000);
  //   // setTimeout(this.func, 60000);
  // };

  render() {
    const { companyObj } = this.props
    const { dataArr, staff, employeeOpt, reserveData, number, time, payWay, open, ordering1Id, profile, ordering1Arr,
    } = this.state
    // console.log(ordering1Arr)
    return (<>
      {!ordering1Id ?
        <div className="content">
          <Header as='h2' inverted={companyObj.inverted}>桌況一覽</Header>
          <div id="main-wrapper" style={{ padding: '2%', backgroundColor: '#fff' }}>
            <div className="row rowBoxRwd">
              <div className="rwd-width col-lg-6">
                <div className="row">
                  <div className="panel-body">
                    <h3>一樓</h3>
                    <div className="big-right" style={{ width: '590px', position: 'relative', border: '2px solid #000', height: '320px' }}>
                      <div className="div-space-block" style={{ ...style.divSpaceBlock, height: '280px', width: '120px', left: '20px', top: '20px', paddingTop: '120px' }}> 玄關 </div>
                      <div className="div-table-block" style={ordering1Arr.find(item => item.tableName === 'A1') ? { ...style.divTableBlock, left: '180px', top: '20px', backgroundColor: 'rgba(48, 133, 214, 0.9)', color: '#fff', lineHeight: 1.2 } : { ...style.divTableBlock, left: '180px', top: '20px', fontSize: '26px' }} onClick={() => this.open('A1')}>{showTable(ordering1Arr.find(item => item.tableName === 'A1'), 'A1')}</div>
                      <div className="div-table-block" style={ordering1Arr.find(item => item.tableName === 'A2') ? { ...style.divTableBlock, left: '370px', top: '20px', backgroundColor: 'rgba(48, 133, 214, 0.9)', color: '#fff', lineHeight: 1.2 } : { ...style.divTableBlock, left: '370px', top: '20px', fontSize: '26px' }} onClick={() => this.open('A2')}>{showTable(ordering1Arr.find(item => item.tableName === 'A2'), 'A2')}</div>
                      <div className="div-table-block" style={ordering1Arr.find(item => item.tableName === 'A3') ? { ...style.divTableBlock, left: '180px', top: '120px', backgroundColor: 'rgba(48, 133, 214, 0.9)', color: '#fff', lineHeight: 1.2 } : { ...style.divTableBlock, left: '180px', top: '120px', fontSize: '26px' }} onClick={() => this.open('A3')}>{showTable(ordering1Arr.find(item => item.tableName === 'A3'), 'A3')}</div>
                      <div className="div-table-block" style={ordering1Arr.find(item => item.tableName === 'A4') ? { ...style.divTableBlock, left: '370px', top: '120px', backgroundColor: 'rgba(48, 133, 214, 0.9)', color: '#fff', lineHeight: 1.2 } : { ...style.divTableBlock, left: '370px', top: '120px', fontSize: '26px' }} onClick={() => this.open('A4')}>{showTable(ordering1Arr.find(item => item.tableName === 'A4'), 'A4')}</div>
                      <div className="div-table-block" style={ordering1Arr.find(item => item.tableName === 'A5') ? { ...style.divTableBlock, left: '180px', top: '220px', backgroundColor: 'rgba(48, 133, 214, 0.9)', color: '#fff', lineHeight: 1.2 } : { ...style.divTableBlock, left: '180px', top: '220px', fontSize: '26px' }} onClick={() => this.open('A5')}>{showTable(ordering1Arr.find(item => item.tableName === 'A5'), 'A5')}</div>
                    </div>
                  </div>
                </div>
                <div className="row form-inline">
                  <div className="rwd-width col-md-3">
                    {/* <button className="btn btn-success btn-lg btn-sellproduct" style={{ width: '100%', marginTop: '20px' }} data-toggle="modal" data-target="#myModal">入帳出帳</button> */}
                    {/* <InAndOut  {...this.props} profile={profile} /> */}
                  </div>
                </div>
              </div>
              <div className="rwd-width col-lg-6">
                <div className="panel-body">
                  <h3>二樓</h3>
                  <div style={{ width: '590px', position: 'relative', border: '2px solid #000', height: '520px' }}>
                    <div className="div-space-block" style={{ ...style.divSpaceBlock, height: '280px', width: '195px', left: '10px', top: '20px', paddingTop: '210px' }}></div>
                    <div className="div-table-block" style={ordering1Arr.find(item => item.tableName === 'B3') ? { ...style.divTableBlock, left: '220px', top: '20px', backgroundColor: 'rgba(48, 133, 214, 0.9)', color: '#fff', lineHeight: 1.2 } : { ...style.divTableBlock, left: '220px', top: '20px', fontSize: '26px' }} onClick={() => this.open('B3')}>{showTable(ordering1Arr.find(item => item.tableName === 'B3'), 'B3')}</div>
                    {/* <div className="div-table-block" data-no="B2" style={ordering1Arr.find(item => item.tableName === 'B2') ? { ...style.divTableBlock, left: '405px', top: '20px', backgroundColor: 'rgba(48, 133, 214, 0.9)', color: '#fff', lineHeight: 1.2 } : { ...style.divTableBlock, left: '405px', top: '20px', fontSize: '26px' }} onClick={() => this.open('B2')}>{showTable(ordering1Arr.find(item => item.tableName === 'B2'), 'B2')}</div>  */}
                    {/* <div className="div-table-block"  style={ordering1Arr.find(item => item.tableName === 'B3') ? { ...style.divTableBlock, left: '220px', top: '120px', backgroundColor: 'rgba(48, 133, 214, 0.9)', color: '#fff', lineHeight: 1.2 } : { ...style.divTableBlock, left: '220px', top: '120px', fontSize: '26px' }} onClick={() => this.open('B3')}>{showTable(ordering1Arr.find(item => item.tableName === 'B3'), 'B3')}</div> */}
                    <div className="div-table-block" style={ordering1Arr.find(item => item.tableName === 'B4') ? { ...style.divTableBlock, left: '405px', top: '120px', backgroundColor: 'rgba(48, 133, 214, 0.9)', color: '#fff', lineHeight: 1.2 } : { ...style.divTableBlock, left: '405px', top: '120px', fontSize: '26px' }} onClick={() => this.open('B4')}>{showTable(ordering1Arr.find(item => item.tableName === 'B4'), 'B4')}</div>
                    <div className="div-table-block" style={ordering1Arr.find(item => item.tableName === 'B5') ? { ...style.divTableBlock, left: '220px', top: '220px', backgroundColor: 'rgba(48, 133, 214, 0.9)', color: '#fff', lineHeight: 1.2 } : { ...style.divTableBlock, left: '220px', top: '220px', fontSize: '26px' }} onClick={() => this.open('B5')}>{showTable(ordering1Arr.find(item => item.tableName === 'B5'), 'B5')}</div>
                    {/* <div className="div-table-block" data-no="B6" style={ordering1Arr.find(item => item.tableName === 'B6') ? { ...style.divTableBlock, left: '405px', top: '220px', backgroundColor: 'rgba(48, 133, 214, 0.9)', color: '#fff', lineHeight: 1.2 } : { ...style.divTableBlock, left: '405px', top: '220px', fontSize: '26px' }} onClick={() => this.open('B6')}>{showTable(ordering1Arr.find(item => item.tableName === 'B6'), 'B6')}</div> */}
                    <div className="div-table-block" style={ordering1Arr.find(item => item.tableName === 'B6') ? { ...style.divTableBlock, left: '220px', top: '320px', backgroundColor: 'rgba(48, 133, 214, 0.9)', color: '#fff', lineHeight: 1.2 } : { ...style.divTableBlock, left: '220px', top: '320px', fontSize: '26px' }} onClick={() => this.open('B6')}>{showTable(ordering1Arr.find(item => item.tableName === 'B6'), 'B6')}</div>
                    <div className="div-table-block" style={ordering1Arr.find(item => item.tableName === 'B7') ? { ...style.divTableBlock, left: '220px', top: '420px', backgroundColor: 'rgba(48, 133, 214, 0.9)', color: '#fff', lineHeight: 1.2 } : { ...style.divTableBlock, left: '220px', top: '420px', fontSize: '26px' }} onClick={() => this.open('B7')}>{showTable(ordering1Arr.find(item => item.tableName === 'B7'), 'B7')}</div>
                    <div className="div-space-block" style={{ ...style.divSpaceBlock, height: '80px', width: '170px', left: '405px', top: '220px' }}> 樓梯 </div>
                    <div className="div-table-block" style={ordering1Arr.find(item => item.tableName === 'B1') ? { ...style.divTableBlock, left: '20px', top: '40px', backgroundColor: 'rgba(48, 133, 214, 0.9)', color: '#fff', lineHeight: 1.2 } : { ...style.divTableBlock, left: '20px', top: '40px', fontSize: '26px' }} onClick={() => this.open('B1')}>{showTable(ordering1Arr.find(item => item.tableName === 'B1'), 'B1')}</div>
                    <div className="div-table-block" style={ordering1Arr.find(item => item.tableName === 'B2') ? { ...style.divTableBlock, left: '20px', top: '140px', backgroundColor: 'rgba(48, 133, 214, 0.9)', color: '#fff', lineHeight: 1.2 } : { ...style.divTableBlock, left: '20px', top: '140px', fontSize: '26px' }} onClick={() => this.open('B2')}>{showTable(ordering1Arr.find(item => item.tableName === 'B2'), 'B2')}</div>
                    {/* <div className="div-table-block" data-no="C3" style={ordering1Arr.find(item => item.tableName === 'C3') ? { ...style.divTableBlock, left: '20px', top: '240px', backgroundColor: 'rgba(48, 133, 214, 0.9)', color: '#fff', lineHeight: 1.2 } : { ...style.divTableBlock, left: '20px', top: '240px', fontSize: '26px' }} onClick={() => this.open('C3')}>{showTable(ordering1Arr.find(item => item.tableName === 'C3'), 'C3')}</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <Header as='h2' inverted={companyObj.inverted}>預約一覽</Header>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>預約日期</Table.HeaderCell>
                <Table.HeaderCell>時間</Table.HeaderCell>
                <Table.HeaderCell>人數</Table.HeaderCell>
                <Table.HeaderCell>座位</Table.HeaderCell>
                <Table.HeaderCell>姓名</Table.HeaderCell>
                <Table.HeaderCell>電話</Table.HeaderCell>
                <Table.HeaderCell>備註</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {reserveData.map((item, index) => <>
                <Table.Row key={index} >
                  <Table.Cell>
                    <Button color='danger' onClick={() => this.handleReserve(item)}>刪除</Button>
                  </Table.Cell>
                  <Table.Cell>{item.reserveDate}</Table.Cell>
                  <Table.Cell>{item.reserveTime}</Table.Cell>
                  <Table.Cell>{item.reservePeople}</Table.Cell>
                  <Table.Cell>{item.reserveTable}</Table.Cell>
                  <Table.Cell>{item.reserveName}</Table.Cell>
                  <Table.Cell>{item.reserveNum}</Table.Cell>
                  <Table.Cell>{item.reservePs}</Table.Cell>
                </Table.Row>
              </>)}
            </Table.Body>
          </Table >
        </div >
        : <CrewOrder key {...this.props} />}
      <Modal
        isOpen={open}
        size='md'
        toggle={this.close}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggle}>
            <span aria-hidden="true">×</span>
          </button>
        開桌
      </ModalHeader>
        <ModalBody><>
          開桌時間 <Form.Input fluid type="text" name='time' value={time} readOnly />
          人數 <Form.Input fluid type="text" name='number' value={number} readOnly />
          <Button color='info' style={{ width: '30%' }} onClick={() => this.handleButton('1')}>1</Button>
          <Button color='info' style={{ width: '30%' }} onClick={() => this.handleButton('2')}>2</Button>
          <Button color='info' style={{ width: '30%' }} onClick={() => this.handleButton('3')}>3</Button>
          <Button color='info' style={{ width: '30%' }} onClick={() => this.handleButton('4')}>4</Button>
          <Button color='info' style={{ width: '30%' }} onClick={() => this.handleButton('5')}>5</Button>
          <Button color='info' style={{ width: '30%' }} onClick={() => this.handleButton('6')}>6</Button>
          <Button color='info' style={{ width: '30%' }} onClick={() => this.handleButton('7')}>7</Button>
          <Button color='info' style={{ width: '30%' }} onClick={() => this.handleButton('8')}>8</Button>
          <Button color='info' style={{ width: '30%' }} onClick={() => this.handleButton('9')}>9</Button>
          <Button color='info' style={{ width: '30%' }} onClick={() => this.handleButton('10')}>10</Button>
          <Button color='info' style={{ width: '30%' }} onClick={() => this.handleButton('11')}>11</Button>
          <Button color='info' style={{ width: '30%' }} onClick={() => this.handleButton('12')}>12</Button><br /><br />
       開桌人員 <Form.Select fluid name='staff' value={staff} options={employeeOpt} readOnly /><br />
          {/* {nowTable === 'A1' || nowTable === 'A2' || nowTable === 'A3' || nowTable === 'A4' || nowTable === 'A5' ? <>選擇方案 <Form.Select fluid name='payWay' value={payWay} options={[{ key: 'aa', value: '-1', text: '請選擇' }].concat(payWaySel)} onChange={this.handleChange} /><br /></> : null} */}
          <br />
        </>
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button color='danger' onClick={this.close} >關閉</Button>
          <Button color='success' onClick={this.handleOpentable}>開桌</Button>
        </ModalFooter>
      </Modal>
    </>);
  }
}

export default CrewOpentable;