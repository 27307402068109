import React, { Component } from 'react'
import { Container, Header, Grid, Dimmer, Loader, Image, Segment } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { Col, Row } from "reactstrap";
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';


const columns = () => ([
  { title: '項目', field: 'value' },
]);

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export default class Fin1102 extends Component {
  state = {
    acc1: [],
    acc2: [],
    acc3: [],
    data1: [],
    data2: [],
    data3: [],
    sel1: '',
    sel2: '',
    sel3: '',
    title1: '',
    title2: '',
    title3: '',
    loading: true,
    obj: {},
    auth: {},
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { match, companyObj } = props;

    const companyId = companyObj.objectId;
    const account1 = await Parse.queryData('account1', { companyId, balance: '0' });
    // let data1 = account1.filter(item => item.objectId !== 'vtvfMREPEJ' && item.objectId !== 'XZb3Gy9eLg' && item.objectId !== 'b4zzTZiWmQ')

    let acc1 = {}
    account1.forEach(item => {
      // if (item.objectId !== 'vtvfMREPEJ' && item.objectId !== 'XZb3Gy9eLg' && item.objectId !== 'b4zzTZiWmQ') {
      acc1 = { ...acc1, [item.objectId]: item.value };
      // }
    });
    this.setState({ acc1, data1: account1, account1 });
    const account2 = await Parse.queryData('account2', { companyId });

    this.setState({ acc2: account2 });

    const account3 = await Parse.queryData('account3', { companyId });
    this.setState({ acc3: account3 });
    this.setState({ loading: false })
  }

  handleAdd = async (id) => {
    const { newData1, newData2, newData3 } = this.state;

    if (id === "1") {
      await Parse.saveData('account1', newData1)
    } else if (id === "2") {
      await Parse.saveData('account2', newData2)
    } else if (id === "3") {
      await Parse.saveData('account3', newData3)
    }
    this.handleFetch()
  }

  handleUpdate = async (id) => {
    const { newData1, newData2, newData3 } = this.state

    if (id === "1") {
      await Parse.saveData('account1', newData1)
    } else if (id === "2") {
      await Parse.saveData('account2', newData2)
    } else if (id === "3") {
      await Parse.saveData('account3', newData3)
    }
    this.handleFetch()
  }

  handleDelete = async (id) => {
    const { oldData1, oldData2, oldData3, acc1, acc2, acc3 } = this.state
    const promises = []
    if (id === "1") {
      if (acc3.find(item => item.a1_id === oldData1.objectId)) {
        promises.push(Parse.destroyData('account3', { a1_id: oldData1.objectId }))
      }
      if (acc2.find(item => item.a1_id === oldData1.objectId)) {
        promises.push(Parse.destroyData('account2', { a1_id: oldData1.objectId }))
      }
      await Parse.deleteData('account1', oldData1);
      await Promise.all(promises).catch(error => console.log("error", error))
      this.setState({ sel1: '', sel2: '' }, () => this.handleFetch())
    } else if (id === "2") {
      if (acc3.find(item => item.a2_id === oldData2.objectId)) {
        promises.push(Parse.destroyData('account3', { a2_id: oldData2.objectId }))
      }
      await Parse.deleteData('account2', oldData2);
      await Promise.all(promises).catch(error => console.log("error", error))
      this.setState({ sel2: '' }, () => this.handleFetch())
    } else if (id === "3") {
      await Parse.deleteData('account3', oldData3);
      this.handleFetch()
    }
  }

  handleData = (id, rowData) => {
    const { acc1, acc2, acc3 } = this.state;
    const sel = rowData.objectId;
    if (id === "1") {
      this.setState({ sel1: sel, sel2: '', sel3: '', sel4: '', title1: rowData.value, data2: acc2.filter(item => item.a1_id === sel) })
    } else if (id === "2") {
      this.setState({ sel2: sel, sel3: '', sel4: '', title2: rowData.value, data3: acc3.filter(item => item.a2_id === sel) })
    } else if (id === "3") {
      this.setState({ sel3: sel, sel4: '', title3: rowData.value, obj: rowData })
    }
  }

  editable1 = {
    isEditHidden: () => !this.props.auth.edit,
    isDeleteHidden: rowData => rowData.objectId === '9snvSJKem7' || rowData.objectId === 'o2oKwXbPjS' || !this.props.auth.edit ? true : false,
    onRowAdd: newData1 =>
      new Promise(async (resolve, reject) => {
        const db = Parse.Object.extend("account1");
        const newDoc = new db();
        const doc = await newDoc.save();
        const data = [...this.state.data1];

        const obj = {
          _id: doc.id || '',
          id: doc.id || '',
          objectId: doc.id || '',
          companyId: this.props.companyObj.objectId || '',
          companyName: this.props.companyObj.name || '',
          balance: '0',
          ...newData1
        }
        data.push(obj);

        this.setState({ data1: data, newData1: obj, loading: true }, () => resolve());
      }).then(() => this.handleAdd("1")),
    onRowUpdate: (newData1, oldData1) =>
      new Promise((resolve, reject) => {
        const data = [...this.state.data1];
        const index = data.indexOf(oldData1);
        data[index] = newData1;
        this.setState({ data1: data, newData1, oldData1, loading: true }, () => resolve());
      }).then(() => this.handleUpdate("1")),
    onRowDelete: oldData1 =>
      new Promise((resolve, reject) => {
        let data = [...this.state.data1];
        const index = data.indexOf(oldData1);
        data.splice(index, 1);
        this.setState({ data1: data, oldData1, loading: true }, () => resolve());
      }).then(() => this.handleDelete("1")),
  }

  editable2 = {
    isEditHidden: () => !this.props.auth.edit,
    isDeleteHidden: () => !this.props.auth.edit,
    onRowAdd: newData2 =>
      new Promise(async (resolve, reject) => {
        const db = Parse.Object.extend("account2");
        const newDoc = new db();
        const doc = await newDoc.save();
        const data = [...this.state.data2];

        const obj = {
          _id: doc.id || '',
          id: doc.id || '',
          objectId: doc.id || '',
          companyId: this.props.companyObj.id || '',
          companyName: this.props.companyObj.name || '',
          a1_id: this.state.sel1 || '',
          a1_text: (this.state.account1.find(item => item.objectId === this.state.sel1) && this.state.account1.find(item => item.objectId === this.state.sel1).value) || '',
          ...newData2
        }
        data.push(obj);

        this.setState({ data2: data, newData2: obj, loading: true }, () => resolve());
      }).then(() => this.handleAdd("2")),
    onRowUpdate: (newData2, oldData2) =>
      new Promise((resolve, reject) => {
        const data = [...this.state.data2];
        const index = data.indexOf(oldData2);
        data[index] = newData2;
        // console.log(data1)
        this.setState({ data2: data, newData2, oldData2, loading: true }, () => resolve());
      }).then(() => this.handleUpdate("2")),
    onRowDelete: oldData2 =>
      new Promise((resolve, reject) => {
        let data = [...this.state.data2];
        const index = data.indexOf(oldData2);
        data.splice(index, 1);
        this.setState({ data2: data, oldData2, loading: true }, () => resolve());
      }).then(() => this.handleDelete("2")),
  }

  editable3 = {
    isEditHidden: () => !this.props.auth.edit,
    isDeleteHidden: () => !this.props.auth.edit,
    onRowAdd: newData3 =>
      new Promise(async (resolve, reject) => {
        const db = Parse.Object.extend("account3");
        const newDoc = new db();
        const doc = await newDoc.save();
        const data = [...this.state.data3];

        const obj = {
          _id: doc.id || '',
          id: doc.id || '',
          objectId: doc.id || '',
          companyId: this.props.companyObj.id || '',
          companyName: this.props.companyObj.name || '',
          a1_id: this.state.sel1 || '',
          a1_text: (this.state.account1.find(item => item.objectId === this.state.sel1) && this.state.account1.find(item => item.objectId === this.state.sel1).value) || '',
          a2_id: this.state.sel2 || '',
          a2_text: (this.state.acc2.find(item => item.objectId === this.state.sel2) && this.state.acc2.find(item => item.objectId === this.state.sel2).value) || '',
          ...newData3
        }
        data.push(obj);
        this.setState({ data3: data, newData3: obj, loading: true }, () => resolve());
      }).then(() => this.handleAdd("3")),

    onRowUpdate: (newData3, oldData3) =>
      new Promise((resolve, reject) => {
        const data = [...this.state.data3];
        const index = data.indexOf(oldData3);
        data[index] = newData3;
        // console.log(data1)
        this.setState({ data3: data, newData3, oldData3, loading: true }, () => resolve());
      }).then(() => this.handleUpdate("3")),
    onRowDelete: oldData3 =>
      new Promise((resolve, reject) => {
        let data = [...this.state.data3];
        const index = data.indexOf(oldData3);
        data.splice(index, 1);
        this.setState({ data3: data, oldData3, loading: true }, () => resolve());
      }).then(() => this.handleDelete("3")),
  }

  render() {
    const { companyObj, auth } = this.props;
    // const { bg } = ;
    const { account1, acc2,
      data1, data2, data3, title1, title2, title3, sel1, sel2, sel3, obj, loading } = this.state;

    // const 

    // const 

    // const 

    if (auth.edit === false) {
      delete this.editable1.onRowAdd
      delete this.editable2.onRowAdd
      delete this.editable3.onRowAdd
    }

    return (<>
      <div style={{ ...style.flexCenter, position: 'relative', zIndex: 100 }}>
        <Header inverted={companyObj.inverted} as='h2' style={{ margin: '2rem 0' }}>會計科目</Header>
      </div>
      {/* <Grid columns={3} divided> */}
      {/* <Grid.Row> */}
      {/* <Grid.Column> */}
      <Row>
        <Col md={{ size: 4, }}>
          <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns()} options={{
              addRowPosition: "first",
              exportButton: true,
              exportAllData: true,
              exportPdf: (columns, data) => exportPdf(columns, data, '項目'),
              pageSize: data1.length || 5,
              search: false
            }}
            data={data1}
            title='項目'
            actions={[
              {
                icon: 'view_headline',
                tooltip: '查看',
                onClick: (event, rowData) => this.handleData("1", rowData)
              }
            ]}
            onRowClick={(event, rowData) => this.handleData("1", rowData)}
            editable={this.editable1}
          />
        </Col>
        {/* </Grid.Column> */}
        {/* <Grid.Column> */}
        <Col sm='4'>
          {sel1 ?
            <MaterialTable
              isLoading={loading}
              localization={localization()}
              columns={columns()}
              options={{
                addRowPosition: "first",
                exportButton: true,
                exportAllData: true,
                exportPdf: (columns, data) => exportPdf(columns, data, "會計科目 " + title1),
                pageSize: data2.length || 5,
                search: false
              }}
              // onColumnDragged={}
              data={data2}
              title={"會計科目 " + title1}
              actions={[
                {
                  icon: 'view_headline',
                  tooltip: '查看',
                  onClick: (event, rowData) => this.handleData("2", rowData)
                }
              ]}
              onRowClick={(event, rowData) => this.handleData("2", rowData)}
              editable={this.editable2}
            /> : null}
          {/* </Grid.Column> */}
          {/* <Grid.Column> */}
        </Col>
        <Col sm='4'>
          {sel2 ? <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns()} options={{
              addRowPosition: "first",
              exportButton: true,
              exportAllData: true,
              exportPdf: (columns, data) => exportPdf(columns, data, "會計科目 " + title2),
              pageSize: data3.length || 5,
              search: false
            }}
            data={data3}
            title={"會計科目 " + title2}
            editable={this.editable3}
          /> : null}
        </Col>
      </Row>
      {/* </Grid.Column> */}
      {/* </Grid.Row> */}
      {/* </Grid> */}
      <br />
      <br />
    </>)
  }
}