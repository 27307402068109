import React, { Component } from 'react'
import { Container, Header, Form, Icon, Select, Input, Button, Grid, TextArea } from 'semantic-ui-react'
import { DateInput } from 'semantic-ui-calendar-react';
import MyTables2Editable from './MyTables2Editable';
import { getOptionsSelByKey, showCurrentClass } from './FieldsRender';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import SelectDate from './SelectDate';
// import Moment from 'moment';

export class MyModalTable extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      modalTitle: '',
      modalOpen: false,
      show: false,
      value0: '',
      value1: '',
      _value0: '0',
      _value1: '0',
      semesterYear: props.semesterYear,
      semester: props.semester,
      studentStatus: '10',
      loading: false,
      form: {},
      data: [],
      // collection: props.collection || 'projects'
    };
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = (props = this.props) => {
    const { rows } = props;
    this.setState({ data: rows });
  }

  handleClose = () => {
    this.setState({ show: false, form: {} });
  }

  handleChange = (event, { name, value }) => {
    if (name === '_value0') {
      this.setState({ [name]: value, value0: value });
    } else if (name === '_value1') {
      this.setState({ [name]: value, value1: value });
    } else if (name === 'testCode' || name === 'testLevel' || name === 'testDate' || name === 'placementDate' || name === 'ps') {
      this.setState({ form: { ...this.state.form, [name]: value } });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleChangeRecept = (event, { name, value }) => {
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    })
  }

  handleSubmit = async () => {
    const { collection, list, pageTitleText, examKey, rows, reception, receptObj, refetch } = this.props;
    const { value0, value1, isEdit, studentStatus, form, data } = this.state;
    // const db = firebase.firestore();
    this.setState({ loading: true })
    // console.log(form);
    // return;
    if (collection === 'Students') {
      const obj = {
        semesterYear: this.state.semesterYear,
        semester: this.state.semester,
        semesterText: this.state.semester === 'S' ? '春' : '秋',
        studentStatus: studentStatus,
        studentStatus2: studentStatus === '10' ? '11' : studentStatus,
        recruitNum: value0 || '',
        studentId: value1 || '',

      }
      // firebase.firestore().collection("Students").add(obj).then(ref => {
      //   // console.log('Added document with ID: ', ref.id);
      //   this.handleClose();
      //   this.props.history.push(`/StudentPage/${list}/${ref.id}`);
      // })
    }
    if (pageTitleText === '定期テスト結果入力' || pageTitleText === '校外試験結果入力') {
      const setting = form;
      setting.testType = '2';
      if (pageTitleText === '校外試験結果入力') {
        setting.testType = '3';
      }
      setting.sortDate = setting && setting.testDate;
      const d = new Date(setting.sortDate);

      setting.semester = d.getMonth() >= 3 && d.getMonth() < 9 ? "S" : "A";
      setting.semesterYear = d.getMonth() < 3 ? d.getFullYear() - 1 : d.getFullYear();
      // firebase.firestore().collection("Grade").add(setting).then(ref => {
      //   // console.log('Added document with ID: ', ref.id);
      //   firebase.firestore().doc(`Grade/${ref.id}`).update('_id', ref.id).then(() => {
      //     refetch('Grade');
      //     this.props.history.push(`/examTable/${ref.id}`)
      //     this.handleClose();
      //   });
      // })
    }
    if (reception === "1" && receptObj && receptObj.key) {
      // if (!form._id) {
      //   const newRows = [...data];
      //   if (!form._id) {
      //     const docRef = db.collection(receptObj.key).doc();
      //     const obj = {
      //       ...form,
      //       _id: docRef.id,
      //       createdAt: new Date()
      //     };
      //     await docRef.set(obj);
      //     newRows.push(obj);
      //   } else {
      //     await db.collection(receptObj.key).doc(form._id).update(form);
      //     let index = newRows.findIndex((item => item._id === form._id));
      //     newRows[index] = form;
      //   }

      //   this.setState({ form: {}, data: newRows, loading: false }, () => this.handleClose());
      // }
    }
  }
  handleRowDelete = (_id) => {
    const { data } = this.state;
    const newRows = [...data];
    const findIndex = newRows && newRows.findIndex(item => item._id === _id);
    newRows.splice(findIndex, 1);
    this.setState({ data: newRows });
  }
  render() {
    const { columns, pageTitleText, orderBy, titleFields, whereArr, whereArr2, list, optSet, systemObj, collection, semesterYear, semester, rows, reception, receptObj } = this.props;
    const { show, value0, value1, _value0, _value1, studentStatus, form, loading, data } = this.state
    const options0 = [{ key: '0', text: '-- 選擇 --', value: '0' }];
    const options1 = [{ key: '0', text: '-- 選擇 --', value: '0' }];

    if (systemObj && systemObj.recruitNum && systemObj.studentNum) {
      options0.push({ key: '1', text: `既：${systemObj.recruitNum} 現：${Number(systemObj.recruitNum) + 1}`, value: (Number(systemObj.recruitNum) + 1).toString() })
      options1.push({ key: '1', text: `既：${systemObj.studentNum} 現：${Number(systemObj.studentNum) + 1}`, value: (Number(systemObj.studentNum) + 1).toString() })
    }
    const semesterYearArr = getOptionsSelByKey(optSet, 'semesterYear');
    const semestersArr = getOptionsSelByKey(optSet, 'semester');

    const examObj = (optSet ? optSet.find(item => item.key === 'classroom') : '');
    let examArr = [];
    if (examObj && examObj._id) {
      examArr = optSet.filter(item => (item.parentFolderId === examObj._id && (item.key === 'ejuExam' || item.key === 'jlptExam' || item.key === 'englishExam'))).sort((a, b) => a.orderId - b.orderId);
      examArr = examArr.map(item => ({ key: item._id, text: item.label, value: item.label }));
      examArr.unshift({ key: 'empty', text: '-- 選擇 --', value: '' });
    }

    const statusArr = [
      // { key: '0', text: '', value: '0' },
      { key: '1', text: '募集', value: '10' },
      { key: '2', text: '在籍', value: '30' },
    ]
    return (<>
      <Header as='h2' floated='left'>{reception === "1" && receptObj ? receptObj.label : pageTitleText}</Header>
      <Button floated='right' primary onClick={() => this.setState({ show: true })} icon="add user" content="新增" />
      <MyTables2Editable
        {...this.props}
        rows={data}
        handleRowDelete={this.handleRowDelete}
      />
      {/* <Modal size='tiny' open={show} onClose={this.handleClose} centered={false}> */}
      <Modal
        isOpen={this.state.show}
        toggle={this.handleClose}
        size="lg"
        // className="mh-120 h-90 mw-120 w-90"
        style={{ paddingTop: '0px' }}
        scrollable={true}
      >
        <ModalHeader className="justify-content-center">
          {reception === "1" && receptObj ? receptObj.label : ""} 新增
          </ModalHeader>
        <ModalBody style={{ padding: "20px 30px" }}>
          {collection === 'Students' ?
            <Form>
              <Form.Group widths='equal'>
                <Form.Select label='学年' fluid name='semesterYear' options={semesterYearArr} value={this.state.semesterYear} onChange={this.handleChange} />
                <Form.Select label='学期' fluid name='semester' options={semestersArr} value={this.state.semester} onChange={this.handleChange} />
                <Form.Field control={Select} label='類別' options={statusArr} placeholder='類別' onChange={this.handleChange} name='studentStatus' value={studentStatus} />
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Field control={Select} label='選擇募集番号' options={options0} placeholder='選擇募集番号' onChange={this.handleChange} name='_value0' value={_value0} />
                <Form.Field control={Input} label='募集番号' placeholder='募集番号' onChange={this.handleChange} name='value0' value={value0} />
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Field control={Select} label='選擇学籍番号' options={options1} placeholder='選擇学籍番号' onChange={this.handleChange} name='_value1' value={_value1} />
                <Form.Field control={Input} label='学籍番号' placeholder='学籍番号' onChange={this.handleChange} name='value1' value={value1} />
              </Form.Group>
            </Form> : null}
          {/* {pageTitleText === '校外試験結果入力' ?
            <Form>
              <Form.Field>開課期別: {showCurrentClass(semesterYear, semester)}</Form.Field>
              <Form.Group widths='equal'>
                <DateInput label='試験日' name="testDate" value={form.testDate} iconPosition="left" dateFormat='YYYY-MM-DD' popupPosition='bottom center' closable closeOnMouseLeave={false} onChange={this.handleChange} />
                <Form.Field control={Select} label='試験レべル' options={examArr} onChange={this.handleChange} name='testLevel' value={form.testLevel} />
              </Form.Group>
            </Form> : null} */}
          {pageTitleText === '定期テスト結果入力' ?
            <Form>
              <Form.Group widths='equal'>
                <Form.Field control={Input}
                  label='テストコード' name='testCode' value={form.testCode} onChange={this.handleChange} />
                <Form.Field control={Input}
                  label='試験レべル' name='testLevel' value={form.testLevel} onChange={this.handleChange} />
              </Form.Group>
              <Form.Group widths='equal'>
                <DateInput required label='試験日' name="testDate" value={form.testDate} iconPosition="left" dateFormat='YYYY-MM-DD' popupPosition='bottom center' closable onChange={this.handleChange} />
                {/* <DateInput required label='放置日' name="placementDate" value={form.placementDate} iconPosition="left" dateFormat='YYYY-MM-DD' popupPosition='bottom center' closable onChange={this.handleChange} /> */}
                {/* </Form.Group> */}
                {/* <Form.Group widths='equal'> */}
                <Form.Field control={Input} label='備考' onChange={this.handleChange} name='ps' value={form.ps} />
              </Form.Group>
            </Form> : null}
          {pageTitleText === '接客管理' ? <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column><Header as='h3'>来客時間（予定）</Header></Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <DateInput closable label='起'
                    name="customerStart" value={form.testDate}
                    iconPosition="left" dateFormat='YYYY-MM-DD'
                    popupPosition='bottom center' onChange={this.handleChangeRecept} />
                </Grid.Column>
                <Grid.Column>
                  <DateInput closable label='止'
                    name="customerEnd" value={form.testDate}
                    iconPosition="left" dateFormat='YYYY-MM-DD'
                    popupPosition='bottom center' onChange={this.handleChangeRecept} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column><Header as='h3'>来客情報</Header></Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <Form.Input label='名前' defaultValue={form.custName || ''} name='custName' onChange={this.handleChangeRecept} />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input label='会社' defaultValue={form.custCompanyName || ''} name='custCompanyName' onChange={this.handleChangeRecept} />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input label='来客人数' defaultValue={form.guestNum || ''} name='guestNum' onChange={this.handleChangeRecept} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <Form.Input label='連絡先' defaultValue={form.custCompanyAddr || ''} name='custCompanyAddr' onChange={this.handleChangeRecept} />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input label='Eメール' defaultValue={form.custEmail || ''} name='custEmail' onChange={this.handleChangeRecept} />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input label='部署' defaultValue={form.custTitle || ''} name='custTitle' onChange={this.handleChangeRecept} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <Form.Input label='住所' defaultValue={form.custLivingAddr || ''} name='custLivingAddr' onChange={this.handleChangeRecept} />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input label='応对者' defaultValue={form.responder || ''} name='responder' onChange={this.handleChangeRecept} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field control={TextArea} placeholder=''
                    name='content' label='備考'
                    onChange={this.handleChangeRecept} defaultValue={form.appendix ? form.appendix : ''}
                    style={{ height: 100, width: '100%', borderRadius: '.3rem', outline: 'none', border: "1px solid rgba(34,36,38,.15)", padding: '1rem' }}>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column><Header as='h3'>用件內容</Header></Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <Form.Input label='タイトル' defaultValue={form.topic || ''} name='topic' onChange={this.handleChangeRecept} />
                </Grid.Column>
                <Grid.Column></Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field control={TextArea} placeholder=''
                    name='content' label='內容'
                    onChange={this.handleChangeRecept} defaultValue={form.content || ''}
                    style={{ height: 100, width: '100%', borderRadius: '.3rem', outline: 'none', border: "1px solid rgba(34,36,38,.15)", padding: '1rem' }}>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form> : null}
          {reception === '1' ? <Form>
            <Grid>
              {columns.map(item => {
                return <Grid.Column width={8}>
                  <Form.Input label={item.title} defaultValue={form[item.name] || ''} name={item.name} onChange={this.handleChangeRecept} />
                </Grid.Column>
              })}
            </Grid>
          </Form> : null}
        </ModalBody>
        <ModalFooter style={{ padding: "20px 30px" }}>
          <Button onClick={this.handleClose} >關閉</Button>
          <Button primary icon='checkmark' labelPosition='right' onClick={this.handleSubmit} content='新增' loading={loading} />
        </ModalFooter>
      </Modal>
    </>)
  }
}

export default MyModalTable