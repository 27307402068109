
import React, { Component } from 'react'
import { Container } from 'semantic-ui-react';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import WebContent from './WebContent';
import ModalPage from './ModalPage';
import { DimmerImg } from '../../layouts/Dimmer';

class WebPage extends Component {
  state = {
    loading: true,
    loadingBtn: false,
    show: false,
    data: [],
  }

  async componentDidMount() {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data = await Parse.queryData('announceCenter', { companyId });
    this.setState({ announceCenterArr: data }, () => this.handleFetch())

  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps)
  }

  handleFetch = async (props = this.props) => {
    // 抓這頁的component
    const { companyObj, pageObj } = props;
    const data = await Parse.queryData('webpage', { companyId: companyObj.objectId, pageId: pageObj.objectId })
    this.setState({ data, loading: false })
  }

  handleComponentChange = (type, newData) => {
    const data = [...this.state.data]
    if (type === '1') { // 新增
      data.push(newData)
    } else {
      const index = data.findIndex(item => item.objectId === newData.objectId);
      data[index] = newData;
    }
    this.setState({ data })
  }

  render() {
    const { pageObj } = this.props
    const { loading, data, announceCenterArr } = this.state
    // console.log(data)
    // console.log('這頁要顯示的東西', data);
    return (<>
      {loading ? <DimmerImg /> : ''}
      {!loading && !data.length ? `(${pageObj.name} 無資料)` : null}
      {data.sort((a, b) => a.order - b.order).map(item => <>
        <ModalPage key={item.objectId} {...this.props} form={item} type='2' handleComponentChange={this.handleComponentChange} announceCenterArr={announceCenterArr} />
        <br />
        <br />
        <WebContent {...this.props} form={item} announceCenterArr={announceCenterArr} />
      </>)}
      <br />
      <hr />
      <ModalPage {...this.props} pageObj={pageObj} type='1' handleComponentChange={this.handleComponentChange} announceCenterArr={announceCenterArr} />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>);
  }
}

export default WebPage