const isDone = (arr) => {
  return !arr.find(item => item.teacherId === '');
};

// 讓導師在指導的クラス所上的天数可以盡量達到4天(8堂)
const mustSet = (classTimetable, teacherObj, slot, minWorkdayNum = 4, shiftUnit = 2) => {
  const workdayArr = [];
  const possibleWorkdayArr = [];
  let isMustSet = false;

  // 今天以前排了幾天
  classTimetable.filter(item => Number(item.weekday) < Number(slot.weekday) && item.teacherId === teacherObj.teacherId) &&
    classTimetable.filter(item => Number(item.weekday) < Number(slot.weekday) && item.teacherId === teacherObj.teacherId)
      .forEach(item => {
        if (workdayArr.indexOf(item.weekday) === -1)
          workdayArr.push(item.weekday);
      });

  // 今天以後還有機會排幾天
  classTimetable.filter(item => Number(item.weekday) > Number(slot.weekday)) &&
    classTimetable.filter(item => Number(item.weekday) > Number(slot.weekday) && teacherObj.arrangement.slotArr.includes(`${item.period}${item.weekday}`))
      .forEach(item => {
        if (possibleWorkdayArr.indexOf(item.weekday) === -1)
          possibleWorkdayArr.push(item.weekday);
      });

  // 不算今天情況下，其他天的可能配当天数是否小於4天，小於則今天一定要配当
  if (workdayArr.length + possibleWorkdayArr.length < minWorkdayNum) {
    isMustSet = true;
    let nextPeriod = Number(slot.period) + shiftUnit + '';

    // 檢査此堂課今天的之後所有時段還有沒有機會配当，若無表示現在此時段一定要配当
    // 如果今天還有下一時段
    while (!!classTimetable.find(({ weekday, period }) => weekday === slot.weekday && period === nextPeriod)) {
      // 而且老師可以配当
      if (teacherObj.arrangement.slotArr.includes(`${nextPeriod}${slot.weekday}`)) {
        isMustSet = false;
        break;
      }
      else {
        nextPeriod = Number(nextPeriod) + shiftUnit + '';
      }
    }
  }

  return isMustSet;
}

const randomSet = () => {
  return Math.random() < 0.5 ? true : false;
};

// 檢査如果此節課配当，是否會造成老師連續上課(檢査此節課的上一節以及下一節老師是否有配当)
const isNonconsecutive = (timetable, teacherObj, slot, shiftUnit = 2) => {
  return (
    timetable.filter(({ weekday, period, teacherId }) =>
      weekday === slot.weekday &&
      teacherId === teacherObj.teacherId &&
      (period === ((Number(slot.period) - shiftUnit) + '') || period === ((Number(slot.period) + shiftUnit) + ''))).length
      ? false : true
  );
};

// 讓任教老師的年齡跟性別能平均，以年齡中位数及男女做區分成兩部分老師，預設是3代表一個部分老師最多三位
// true表示此老師通過性別年齡限制檢査，可以配当，false則不行配当
const checkAgeAndGender = (classInfo, teacherObj, medianBirthday, slot, limit = 3) => {
  const classObj = classInfo.find(item => item.classroomId === slot.classroomId);

  // 如果クラス還沒任何老師 或 此老師已経有在此クラス任教
  if (classObj.teacherRoster.length === 0 || !!classObj.teacherRoster.find(item => item.teacherId === teacherObj.teacherId))
    return true;

  // 檢査性別
  if (teacherObj.gender) {
    if (classObj.teacherRoster.filter(item => item.gender === teacherObj.gender).length >= limit) {
      return false;
    }
  }

  // 檢査年齡
  if (teacherObj.birthday) {
    // 如果此老師年齡剛好等於中位数，可以配当
    if (teacherObj.birthday === medianBirthday) {
      return true;
    } else if (teacherObj.birthday < medianBirthday && classObj.teacherRoster.filter(item => item.birthday < medianBirthday).length >= limit) {
      return false;
    } else if (teacherObj.birthday > medianBirthday && classObj.teacherRoster.filter(item => item.birthday > medianBirthday).length >= limit) {
      return false;
    }
  }

  return true;
}

const hasSameShiftInOtherClassroom = (timetable, teacherObj, slot) => {
  return timetable.filter(({ classroomId, weekday, period, teacherId }) =>
    classroomId !== slot.classroomId &&
    weekday === slot.weekday &&
    period === slot.period &&
    teacherId === teacherObj.teacherId).length
    ? true : false;
};

const hasShiftOnSameDay = (classTimetable, teacherObj, slot) => {
  return classTimetable.filter(({ weekday, teacherId }) =>
    weekday === slot.weekday && teacherId === teacherObj.teacherId).length ? true : false;
};


const hasReachedMaxShiftOnTheDay = (timetable, teacherObj, slot, shiftUnit = 2) => {
  const shiftNumOnTheDay = timetable.filter(({ weekday, teacherId }) => weekday === slot.weekday && teacherId === teacherObj.teacherId).length;
  const maxShiftOnTheDay = Number(teacherObj.arrangement.maxWeekdayArr[Number(slot.weekday)]);

  return (shiftNumOnTheDay + shiftUnit) > maxShiftOnTheDay ? true : false;
};

const hasReachedMaxShiftPerTeacher = (teacherObj) => {
  return teacherObj.shiftNum >= teacherObj.maxShift;
};

const hasReachedMaxShiftPerClass = (classTimetable, teacherObj, maxShift = 10) => {
  let shift = classTimetable.reduce((currShift, slot) => {
    // console.log(slot, teacherObj)
    return currShift + (slot.teacherId === teacherObj.teacherId ? 1 : 0);
  }, 0);

  return shift >= maxShift;
};

const hasReachedMaxTeachersPerClass = (classInfo, teacherObj, slot, maxTeacherNum = 6) => {
  let classObj = classInfo.find(item => item.classroomId === slot.classroomId);

  return (classObj.teacherRoster.length >= maxTeacherNum && classObj.teacherRoster.indexOf(teacherObj.teacherId) === -1 ? true : false);
};

const isTeacherAvailable = (teacherObj, slot) => {
  return !!(teacherObj.arrangement && teacherObj.arrangement.slotArr && teacherObj.arrangement.slotArr.includes(`${slot.period}${slot.weekday}`));
};

const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

// 將條件一樣的老師湊成group，並且將該組老師們做shuffle
const shuffleByGroup = (teacherList) => {
  let orderedTeacherList = [];
  let group = [];
  let orderedItem = {};

  for (let i = 0; i < teacherList.length; i++) {
    teacherList[i].arrangement.slotArr ?
      teacherList[i].slotArrLength = teacherList[i].arrangement.slotArr.length :
      teacherList[i].slotArrLength = 0;

    // 第一次進來先設定該group的條件
    if (orderedItem === {}) {
      orderedItem.contract = teacherList[i].contract;
      orderedItem.shiftNum = teacherList[i].shiftNum;
      orderedItem.slotArrLength = teacherList[i].slotArrLength;
      group.push(teacherList[i]);
    }
    // 條件一樣的老師加進該group
    else if (teacherList[i].contract === orderedItem.contract && teacherList[i].shiftNum === orderedItem.shiftNum && teacherList[i].slotArrLength === orderedItem.slotArrLength) {
      group.push(teacherList[i]);
    }
    // 條件不一樣表示是下一組的開始，先將此組group做shuffle並加入orderedTeacherList後，改變條件
    else {
      orderedTeacherList = orderedTeacherList.concat(shuffle(group));
      group = [];
      orderedItem.contract = teacherList[i].contract;
      orderedItem.shiftNum = teacherList[i].shiftNum;
      orderedItem.slotArrLength = teacherList[i].slotArrLength;
      group.push(teacherList[i]);
    }

    // 已達リスト尾，加入最後一組リスト
    if (i === teacherList.length - 1) {
      orderedTeacherList = orderedTeacherList.concat(shuffle(group));
    }
  }

  return orderedTeacherList;
};

const getOrderedTeacherList = (teacherList) => {
  // 先將老師リスト依照 (目前已排時数) => (兼職或専任) => (導師所有可上課時数) 做排序
  let orderedTeacherList = teacherList.sort((a, b) => a.shiftNum - b.shiftNum || b.contract - a.contract || a.arrangement.slotArr.length - b.arrangement.slotArr.length);
  // 將老師リスト依照相同的 時数 兼職或専任 可上課時数 分成若干小組，每個小組在做shuffle達到亂数效果
  orderedTeacherList = shuffleByGroup(orderedTeacherList);

  return orderedTeacherList;
};

const getAvailTeacherList = (w, p, teachersInfo) => {
  return teachersInfo.filter(item => item.arrangement && item.arrangement.slotArr && item.arrangement.slotArr.includes(`${p}${w}`));
};

export const getMedianBirthdayOfTeachers = (teachers) => {
  const birthdayArr = teachers.filter(item => item.birthdayTeacher).map(item => item.birthdayTeacher.split('-')[0]).sort();
  const medianIndex = Math.floor(birthdayArr.length / 2);
  const medianBirthday = Number(birthdayArr[medianIndex]);

  return medianBirthday;
};

// 排導師們在其担任導師的クラス的時段
export const setHomeroomTeacher = (timetable, teachersInfo, classInfo, classroom, filter) => {
  // const retTimetable = timetable;
  for (let i = 0; i < classroom.length; i++) {
    const classTimetable = timetable.filter(item => item.classroomId === classroom[i]._id && item.homeroomTeacherId);
    // console.log(classTimetable)
    let teacherObj = {};
    if (classTimetable.length) {
      teacherObj = teachersInfo.find(item => item.teacherId === classTimetable[0].homeroomTeacherId);
    }
    // console.log(teacherObj)
    // 如果此班 沒導師 || 配当表已完成 || クラス的導師時数已達上限(例如一個クラス，導師最多只能上10堂課) || 導師自身時数已達上限(例如一個老師，最多一週只能上20堂課) 則跳出
    if (!classTimetable.length || isDone(classTimetable) || hasReachedMaxShiftPerClass(classTimetable, teacherObj) || hasReachedMaxShiftPerTeacher(teacherObj)) {
      break;
    }

    // 排序過的的該班クラス配当表
    // let initClassTimetable = classTimetable.sort((a, b) => a.weekday - b.weekday || a.period - b.period);

    classTimetable
      .sort((a, b) => a.weekday - b.weekday || a.period - b.period)
      .map(slot => {
        if (slot.teacherId === ''                                                                             // 該時段尚未分配老師
          && isTeacherAvailable(teacherObj, slot)                                                        // 檢査該老師在該時段是否為可以上課
          && (filter.indexOf('1') === -1 || !hasReachedMaxShiftPerClass(classTimetable, teacherObj))     // 檢査是否超過每班一位老師的上課時数上限
          && (filter.indexOf('2') === -1 || !hasReachedMaxShiftPerTeacher(teacherObj))                   // 檢査該老師是否超過每位導師上課時数上限
          && (filter.indexOf('3') === -1 || !hasShiftOnSameDay(classTimetable, teacherObj, slot))        // 檢査該老師在該堂課同一天其他時段是否已経有上課
          && !hasSameShiftInOtherClassroom(timetable, teacherObj, slot)                                  // 檢査該老師在不同堂課同一時段是否已経有上課
          && (filter.indexOf('5') === -1 || !hasReachedMaxShiftOnTheDay(timetable, teacherObj, slot))    // 檢査該老師是否超過每日想要上課時数上限
          && (filter.indexOf('6') === -1 || (teacherObj.nonconsecutive ? isNonconsecutive(timetable, teacherObj, slot) : true))) { // 檢査該老師是否要求非連續上課

          // 檢査是否一定要排(為了讓導師盡量達到4天(8小時))
          if (mustSet(classTimetable, teacherObj, slot)) {
            setTimetable(timetable, teachersInfo, classInfo, teacherObj, slot);
          }
          // 隨機決定是否要排
          else if (randomSet()) {
            // 排該老師在該時段
            setTimetable(timetable, teachersInfo, classInfo, teacherObj, slot);
          }
        }
      });

    // // 依序排該クラス的時段
    // initClassTimetable.reduce((currClassTimetable, slot) => {
    //   if (slot.teacherId === ''                                             // 該時段尚未分配老師
    //     && !hasReachedMaxShiftPerClass(currClassTimetable, teacherObj) // 檢査是否超過每班一位老師的上課時数上限
    //     && !hasReachedMaxShiftPerTeacher(teacherObj)                   // 檢査該老師是否超過每位導師上課時数上限
    //     && !hasShiftOnSameDay(currClassTimetable, teacherObj, slot)    // 檢査該老師在該堂課同一天其他時段是否已経有上課
    //     && !hasSameShiftInOtherClassroom(timetable, teacherObj, slot)  // 檢査該老師在不同堂課同一時段是否已経有上課
    //     && isTeacherAvailable(teacherObj, slot)                        // 檢査該老師在該時段是否為可以上課
    //     && (teacherObj.nonconsecutive ? isNonconsecutive(timetable, teacherObj, slot) : true)) { // 檢査該老師是否要求非連續上課

    //     // 檢査是否一定要排(為了讓導師盡量達到4天(8小時))
    //     if (mustSet(currClassTimetable, teacherObj, slot)) {
    //       setTimetable(timetable, teachersInfo, classInfo, teacherObj, slot);
    //       console.log('before', currClassTimetable);
    //       currClassTimetable = timetable.filter(item2 => item2.classroomId === slot.classroomId);
    //       console.log('after', currClassTimetable);
    //     }
    //     // 隨機決定是否要排
    //     else if (randomSet()) {
    //       // 排該老師在該時段
    //       setTimetable(timetable, teachersInfo, classInfo, teacherObj, slot);
    //       currClassTimetable = timetable.filter(item2 => item2.classroomId === slot.classroomId);
    //     }
    //   }
    //   return currClassTimetable;
    // }, initClassTimetable);
  }
};

export const setAllTeacher = (timetable, teachersInfo, classInfo, medianBirthday, filter) => {
  // const timetable = oriTimetable;
  const weekday = ['0', '1', '2', '3', '4'];
  const period = ['0', '1', '2', '3', '4', '5', '6', '7'];

  // 一次排同一天同一時段的全部クラス，例如先排星期一第一節有上課時段的所有クラス
  // 然後用一個可在該時段上課的老師清單，依序分配老師到每個時段，避開老師在同一時段重複上好幾堂課的問題
  weekday.forEach(w =>
    period.forEach(p => {
      let teacherList = [];

      teacherList = getAvailTeacherList(w, p, teachersInfo);   // 所有在此天(weekday)此節(period)可以上課的老師リスト
      teacherList = getOrderedTeacherList(teacherList);        // 將所有可上課老師在特定的規則下亂数排序

      // 找出該時段開課的所有クラス
      timetable.filter(({ weekday, period, teacherId }) => weekday === w && period === p && teacherId === '')
        .sort((a, b) => a.weekday - b.weekday || a.period - b.period)
        .reduce((teacherList, slot) => {
          // 用來暫存條件不符合的老師們
          let tempTeacherList = [];
          // 更新該時段所屬的クラス的クラス時段表以便後續檢査用
          let classTimetable = timetable.filter(item => item.classroomId === slot.classroomId);

          // 如果還有老師在リスト中，繼續測試下一位老師是否可排進該時段
          while (teacherList.length) {
            if ((filter.indexOf('7') === -1 || !hasReachedMaxTeachersPerClass(classInfo, teacherList[0], slot))      // 檢査是否已達每班導師数量上限
              && (filter.indexOf('1') === -1 || !hasReachedMaxShiftPerClass(classTimetable, teacherList[0]))         // 檢査是否超過每班一位老師的上課時数上限
              && (filter.indexOf('2') === -1 || !hasReachedMaxShiftPerTeacher(teacherList[0]))                       // 檢査該老師是否超過每位導師上課時数上限
              && (filter.indexOf('3') === -1 || !hasShiftOnSameDay(classTimetable, teacherList[0], slot))            // 檢査該老師在該堂課同一天其他時段是否已経有上課
              && !hasSameShiftInOtherClassroom(timetable, teacherList[0], slot)                                      // 檢査該老師在不同堂課同一時段是否已経有上課
              && (filter.indexOf('5') === -1 || !hasReachedMaxShiftOnTheDay(timetable, teacherList[0], slot))        // 檢査該老師是否超過每日想要上課時数上限
              && (filter.indexOf('8') === -1 || checkAgeAndGender(classInfo, teacherList[0], medianBirthday, slot))  // 檢査該老師是否符合每班的性別以及年齡平均分配原則
              && (filter.indexOf('6') === -1 || (teacherList[0].nonconsecutive ? isNonconsecutive(timetable, teacherList[0], slot) : true))) { // 檢査該老師是否要求非連續上課

              // 排該老師在該時段
              setTimetable(timetable, teachersInfo, classInfo, teacherList[0], slot);
              // 將已排入老師移至清單尾並進入跳出迴圈
              teacherList = loopTeacherList(teacherList);
              break;
            }
            else {
              // 如果該老師不符合，先暫存到tempTeacherList
              tempTeacherList.push(teacherList.shift());
            }
          }
          // 該時段排序完成，因為還會繼續排同一時段的其他クラス
          // 所以將不符合老師リスト重新加回在リスト最前方，避免餓死
          teacherList.unshift(...tempTeacherList);

          // 回傳最新的導師清單
          return teacherList;
        }, teacherList);
    })
  );
};

const adjustTimetable = (timetable, teacherInfo) => {
  // 其他條件
};

const setTimetable = (timetable, teachersInfo, classInfo, teacherObj, slot, isDoubleShift = true) => {
  if (timetable.find(item => item === slot)) {
    // 這種情況是像愛知一次要連上兩節課(12/34/56/78)
    if (isDoubleShift) {
      if (slot.period % 2 === 0) {
        const index = timetable.indexOf(slot);
        const classObj = classInfo.find(item => item.classroomId === slot.classroomId);
        timetable[index].teacherId = timetable[index + 1].teacherId = teacherObj.teacherId;
        teachersInfo.find(item => item.teacherId === teacherObj.teacherId).shiftNum += 2;
        if (classObj.teacherRoster.length === 0 || classObj.teacherRoster.find(item => item.teacherId === teacherObj.teacherId) === undefined) {
          const classIndex = classInfo.indexOf(classObj);
          classInfo[classIndex].teacherRoster
            .push(
              {
                teacherId: teacherObj.teacherId,
                gender: teacherObj.gender,
                birthday: teacherObj.birthday,
              }
            );
        }
      }
    }
    // 一般情況一次上一節課
    else {
      const classObj = classInfo.find(item => item.classroomId === slot.classroomId);
      timetable.find(item => item === slot).teacherId = teacherObj.teacherId;
      teachersInfo.find(item => item.teacherId === teacherObj.teacherId).shiftNum++;
      if (classObj.teacherRoster.length === 0 || classObj.teacherRoster.find(item => item.teacherId === teacherObj.teacherId) === undefined) {
        const classIndex = classInfo.indexOf(classObj);
        classInfo[classIndex].teacherRoster
          .push(
            {
              teacherId: teacherObj.teacherId,
              gender: teacherObj.gender,
              birthday: teacherObj.birthday,
            }
          );
      }
    };
  };
};

const loopTeacherList = (teacherList) => {
  teacherList.push(teacherList.shift());
  return teacherList;
};