import React, { Component } from 'react'
// import { Container, Header, Modal, Button, Table, Form, Grid, Image, Input, Checkbox } from 'semantic-ui-react';
// import Moment from 'moment';
import MaterialTable from 'material-table'
// import { Select, MenuItem } from '@material-ui/core';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
// import ModalNamelist from './ModalNamelist'
// import ModalNamelist3 from './ModalNamelist3'
import { monthSel, mgyearSel, typeCurrency, exportPdf } from 'views/web/CustomMaterialTable';

// const positionOpt = { '1': '店長', '2': '會計', '3': '櫃檯' }

// 1 月報表
// 結清日期	編號	借款日期	合件	姓名	主	 金額 	車號	 部份還本 	 結餘金額 	備註	身分證號碼	手機號	保人手機號
const columns = () => ([
  { width: 80, title: '年份', field: 'year', lookup: mgyearSel },
  { width: 80, title: '月份', field: 'month', lookup: monthSel },
  { width: 80, title: '底薪', field: 'baseSalary', ...typeCurrency },
  { width: 80, title: '全勤', field: 'fullAttendance', ...typeCurrency },
  { width: 80, title: '工作獎金', field: 'workBonus', ...typeCurrency },
  { width: 80, title: '伙食', field: 'foodStipend', ...typeCurrency },
  { width: 80, title: '佣金', field: 'commission', ...typeCurrency },
  { width: 80, title: '資保息', field: 'capitalGuaranteed', ...typeCurrency },
  { width: 80, title: '端午禮金', field: 'duanwuGift', ...typeCurrency },
  { width: 80, title: '油資', field: 'oilMoney', ...typeCurrency },
  { width: 80, title: '合計', field: 'priceTotal', ...typeCurrency },
]);

export default class Profile3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataArr: [],
      rowData: {},
      loading: true,
    }
    this.tableRef = React.createRef();
  }

  componentDidMount = async () => {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, emplyeeId } = props;
    const data = await Parse.queryData('salaryslip', { companyId: companyObj.objectId, emplyeeId });
    this.setState({ dataArr: data, loading: false });
  }

  handleAdd = async () => {
    const { newData } = this.state;
    Parse.saveData('salaryslip', newData);
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    Parse.saveData('salaryslip', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    Parse.deleteData('salaryslip', oldData);
    this.setState({ oldData: {} })
  }
  render() {
    const { employeeId } = this.props
    const { dataArr, loading } = this.state;
    // console.log(employeeId)
    return (<>
      <MaterialTable
        isLoading={loading}
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '薪資條'),
          pageSize: dataArr.length < 5 ? 5 : dataArr.length + 1,
          search: false,
          tableLayout: 'fixed',
        }}
        data={dataArr}
        tableRef={this.tableRef}
        title={`薪資條`}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const data = [...dataArr];
              const db = Parse.Object.extend("salaryslip");
              const newDoc = new db();
              const doc = await newDoc.save();
              newData.objectId = doc.id;
              newData.id = doc.id;
              newData._id = doc.id;
              newData.employeeId = employeeId;

              data.push(newData);
              newData.priceTotal = Number(newData.baseSalary) + Number(newData.fullAttendance) + Number(newData.postAddition) +
                Number(newData.foodStipend) + Number(newData.healthStipend) + Number(newData.duanwuGift) + Number(newData.oilMoney);

              this.setState({ dataArr: data, newData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...dataArr];
              const index = data.indexOf(oldData);
              newData.priceTotal = Number(newData.baseSalary) + Number(newData.fullAttendance) + Number(newData.postAddition) +
                Number(newData.foodStipend) + Number(newData.healthStipend) + Number(newData.duanwuGift) + Number(newData.oilMoney);

              data[index] = newData;
              console.log(newData)
              // let price = 0;
              // data[index].forEach(item => { // 計算total
              //   price += Number(item);
              // });
              // data[index][data.length - 1] = price; // 將計算完的結果assign '合計':'priceTotal'

              this.setState({ dataArr: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...dataArr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              this.setState({ dataArr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />}
    </>)
  }
}