import React, { Component } from 'react';
import { Table, Label, Form, Segment, Dimmer, Loader, Image } from 'semantic-ui-react';
import Moment from 'moment';

import { getOptionTextByValue } from '../../widgets/FieldsRender'
import { isEventInBetween, weekAndDay } from '../../../widget/define/calendar';
import { getWeekNumber, getDateOfISOWeek, getWeekdayRangeArr } from '../../../widget/define/week';
import RollcallModal from '../../schoolhost/Rollcall/RollcallModal'
// import { cellShow, weekdayArrCN, periodArrCN } from 'views/widgets/FieldsRender';
import ModalPdf from 'widget/pdf/ModalPdf';
import { BeatLoader } from 'react-spinners';
import Parse from 'widget/parse'


const showCell = (attendStatus, attendStatusText) => {
  if (attendStatus === "1") {
    return <div style={{ fontSize: '18px', marginTop: '3px' }}>○</div>
  } else if (attendStatus === "2") {
    return <p style={{ fontSize: '18px' }}>⦰</p>
  } else if (typeof attendStatusText === 'string') {
    return <div style={{ fontSize: '18px', marginTop: '3px' }}>{attendStatusText.substring(0, 1)}</div>
  }
}

export default class ClassRollcall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selStudentObj: {},
      selPeriod: '',
      selDate: '',
      showModal: false,
      rollcallArr: [],
      multiStudent: [],
      unsub: null
    };
  }

  componentDidMount() {
    this.handleFetch()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.multiSelect !== this.props.multiSelect) {
      this.setState({
        multiStudent: [],
        selPeriod: '',
        selDate: '',
        selStudentObj: {},
        selStudentId: ''
      });
    }
    if (nextProps.weekDateRange2 !== this.props.weekDateRange2 ||
      nextProps.classObj.objectId !== this.props.classObj.objectId) {
      this.setState({
        multiStudent: [],
        selPeriod: '',
        selDate: '',
        selStudentObj: {},
        selStudentId: '',
        showModal: false,
        loading: false
      }, () => this.handleFetch(nextProps))
    }
  }

  handleFetch = async (props = this.props) => {
    const { students, classObj, weekDateRange2, rollcallArr0, rollcallArr1, rollcallArr2, rollcallArr3, rollcallArr4 } = props;
    const { rollcallArr } = this.state
    const arr = [];
    this.setState({
      rollcallArr: []
    });
    // 同一天的合在一起
    rollcallArr0.forEach(item => {
      const findIndex = arr.findIndex(item2 => item2.studentId === item.studentId);
      (findIndex !== -1) ? arr[findIndex] = { ...arr[findIndex], ...item } : arr.push(item);
    });
    rollcallArr1.forEach(item => {
      // console.log(item);
      const findIndex = arr.findIndex(item2 => item2.studentId === item.studentId);
      (findIndex !== -1) ? arr[findIndex] = { ...arr[findIndex], ...item } : arr.push(item);
    });
    rollcallArr2.forEach(item => {
      const findIndex = arr.findIndex(item2 => item2.studentId === item.studentId);
      (findIndex !== -1) ? arr[findIndex] = { ...arr[findIndex], ...item } : arr.push(item);
    });
    rollcallArr3.forEach(item => {
      const findIndex = arr.findIndex(item2 => item2.studentId === item.studentId);
      (findIndex !== -1) ? arr[findIndex] = { ...arr[findIndex], ...item } : arr.push(item);
    });
    rollcallArr4.forEach(item => {
      const findIndex = arr.findIndex(item2 => item2.studentId === item.studentId);
      (findIndex !== -1) ? arr[findIndex] = { ...arr[findIndex], ...item } : arr.push(item);
    });
    if (arr.length) {
      // console.log(classObj);
      // const classname = classObj.classSchool;

      // // console.log('arr', arr);

      // const tmp = students.filter(item =>
      //   (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32') &&
      //   (item.applyResult === '1' || item.studentType === "2"));

      // const rollcallArr0 = [];
      // const arrStu = tmp.filter(item => item.classSchool === classname);
      // arrStu.forEach(item => {
      //   const obj = arr.find(item2 => item2.studentId === item._id);
      //   if (obj) {
      //     rollcallArr0.push({ ...obj, jpnName: item.jpnName });
      //   }
      // })
      // console.log('rollcallArr0', rollcallArr0);
      // const rollcallArr = rollcallArr0.sort((a, b) => (a.studentNum).localeCompare(b.studentNum));

      // console.log('rollcallArr', rollcallArr)
      const newRollcallArr = await arr.map(item => ({
        ...item,
        daysCount: (item.daysCount0 || 0) + (item.daysCount1 || 0) + (item.daysCount2 || 0) + (item.daysCount3 || 0) + (item.daysCount4 || 0),
        hoursCount: (item.hoursCount0 || 0) + (item.hoursCount1 || 0) + (item.hoursCount2 || 0) + (item.hoursCount3 || 0) + (item.hoursCount4 || 0)
      }))
      // this.setState({ rollcallArr: rollcallArr.length ? rollcallArr : newRollcallArr });
      this.setState({ rollcallArr: newRollcallArr || rollcallArr, loading: true });
    }
    // this.setState({  loading: true });
  }
  // // 日数計
  // getDaysCount = (calendar, studentObj, dateRangeArr, periodArr) => {
  //   const { rollcallArr } = this.state;
  //   const studentStatus = rollcallArr.filter(item => item.studentId === studentObj._id);
  //   let countDays = 0;

  //   const holidayArr = calendar.filter(eventObj => eventObj.status === '3' && isEventInBetween(eventObj, dateRangeArr[0], dateRangeArr[4]));
  //   const totalDays = dateRangeArr.length - holidayArr.length;

  //   // 都沒記錄等於沒出席
  //   if (studentStatus.length === 0) {
  //     return `0/${totalDays}`;
  //   }

  //   for (let i = 0; i < dateRangeArr.length; i++) {
  //     for (let j = 0; j < periodArr.length; j++) {
  //       if (
  //         // 如果 一天中 有某時段有紀錄 且 該紀錄不是缺席種類 當天便算出席日(即便四節課只出席一節也算)
  //         !!studentStatus.find(item =>
  //           // Moment(new Date(item.eventDate)).format('YYYY-MM-DD') === Moment(new Date(dateRangeArr[i])).format('YYYY-MM-DD') &&
  //           item.eventDate === Moment(new Date(dateRangeArr[i])).format('YYYY-MM-DD') &&
  //           item.period === (periodArr[j] + '') &&
  //           item.attendStatus &&
  //           (item.attendStatus !== '5' && item.attendStatus !== '6' && item.attendStatus !== '7' && item.attendStatus !== '8'))
  //       ) {
  //         countDays++;
  //         break;
  //       }
  //     }
  //   }
  //   return `${countDays}/${totalDays}`;
  // };

  // // 時間計
  // getHoursCount = (calendar, studentObj, dateRangeArr, periodArr) => {
  //   const { rollcallArr } = this.props;
  //   const studentStatus = rollcallArr.filter(item => item.studentId === studentObj._id);
  //   const holidayArr = calendar.filter(eventObj => eventObj.status === '3' && isEventInBetween(eventObj, dateRangeArr[0], dateRangeArr[4]));
  //   const totalHours = (dateRangeArr.length - holidayArr.length) * periodArr.length;
  //   let countHours = 0;

  //   // 都沒記錄等於沒出席
  //   if (studentStatus.length === 0) {
  //     return `0/${totalHours}`;
  //   }

  //   for (let i = 0; i < dateRangeArr.length; i++) {
  //     for (let j = 0; j < periodArr.length; j++) {
  //       if (
  //         // 如果 有某時段有紀錄 且 該紀錄不是缺席種類 該時段便算出席
  //         !!studentStatus.find(item =>
  //           item.eventDate === Moment(new Date(dateRangeArr[i])).format('YYYY-MM-DD') &&
  //           item.period === (periodArr[j] + '') &&
  //           item.attendStatus &&
  //           (item.attendStatus !== '5' && item.attendStatus !== '6' && item.attendStatus !== '7' && item.attendStatus !== '8'))
  //       ) {
  //         countHours++;
  //         continue;
  //       }
  //     }
  //   }
  //   return `${countHours}/${totalHours}`;
  // };

  // getHoursAttendance = (calendar, studentObj, dateRangeArr, periodArr) => {
  //   const { rollcallArr } = this.props;
  //   const studentStatus = rollcallArr.filter(item => item.studentId === studentObj._id);
  //   const holidayArr = calendar.filter(eventObj => eventObj.status === '3' && isEventInBetween(eventObj, dateRangeArr[0], dateRangeArr[4]));
  //   const totalHours = (dateRangeArr.length - holidayArr.length) * periodArr.length;
  //   let countHours = 0;
  //   // 都沒記錄等於沒出席
  //   if (studentStatus.length === 0) {
  //     return 0;
  //   }

  //   for (let i = 0; i < dateRangeArr.length; i++) {
  //     for (let j = 0; j < periodArr.length; j++) {
  //       if (
  //         // 如果 有某時段有紀錄 且 該紀錄不是缺席種類 該時段便算出席
  //         !!studentStatus.find(item =>
  //           item.eventDate === Moment(new Date(dateRangeArr[i])).format('YYYY-MM-DD') &&
  //           item.period === (periodArr[j] + '') &&
  //           item.attendStatus &&
  //           (item.attendStatus !== '5' && item.attendStatus !== '6' && item.attendStatus !== '7' && item.attendStatus !== '8'))
  //       ) {
  //         countHours++;
  //         continue;
  //       }
  //     }
  //   }
  //   return countHours / totalHours;
  // };

  handleClickCell = async (e) => {
    // console.log('handleClickCell', e);
    const [studentId, eventDate, period] = e.currentTarget.id.split(',');
    const { students, multiSelect } = this.props;
    const { multiStudent } = this.state;
    const selStudentObj = students.find(item => item.objectId === studentId) || {};
    if (multiSelect === true) {
      let findIndex = multiStudent.findIndex(item => studentId === item.selStudentId &&
        eventDate === item.selDate &&
        period === item.selPeriod)
      if (findIndex === -1) {
        multiStudent.push({
          selPeriod: period,
          selDate: eventDate,
          selStudentObj,
          selStudentId: selStudentObj.objectId
        })
      } else {
        multiStudent.splice(findIndex, 1);
      }
      // console.log(multiStudent)
      this.setState({
        multiStudent,
        selStudentObj: {},
        // showModal: true 
      })
    } else if (multiSelect === false) {
      this.setState({
        showModal: true,
        selPeriod: period,
        selDate: eventDate,
        selStudentObj,
        selStudentId: selStudentObj.objectId
      })
    }
  }

  handleClickName = async (e) => {//點名字此週未點名的全出席
    // console.log('handleClickCell', e);
    const [studentId] = e.currentTarget.id.split(',');
    const { companyObj, students, classObj, weekDateRange, firebase, calendar } = this.props;
    const db = firebase.firestore()
    const headerArr = classObj.classTime === '1' ? [1, 2, 3, 4] : [5, 6, 7, 8];          // 午前顯示: [1, 2, 3, 4], 午後顯示[5, 6, 7, 8]
    const periodArr = headerArr.slice().map(v => v - 1);
    let student = students.find(item => item.objectId === studentId)
    // const rollcall = [];
    const rollcall = await Parse.queryData('Rollcall', { companyId: companyObj.objectId, studentId })

    // const rollcallRef = await db.collectionGroup('Rollcall').where('studentId', '==', studentId).get();
    // // console.log(rollcallRef.size);
    // rollcallRef.forEach(doc => {
    //   rollcall.push({ _id: doc.id, ...doc.data() })
    //   // console.log(doc.id, ' => ', doc.data());
    // });
    var promises = [];
    for (let k = 0; k < weekDateRange.length; k++) {
      var batch = db.batch();
      let ddd = Moment(weekDateRange[k]).format('YYYY-MM-DD')
      if (calendar.filter(eventObj => eventObj.eventDate === ddd && eventObj.status === '3').length) {
        continue; // 如果這天學校沒上課的話，就跳過
      }
      const todayRollcall = rollcall.filter(item => item.eventDate === ddd);
      // 要上課的話
      for (let j = 0; j < periodArr.length; j++) {// 如果 一天中 有某時段有紀錄 且 該紀錄不是缺席種類 當天便算出席日(即便四節課只出席一節也算)
        if (todayRollcall.find(item => item.period === (`${periodArr[j]}`) && item.attendStatus !== '-1')) {
          console.log(ddd, periodArr[j])
          continue;
        } else {
          // console.log(week[k], periodArr[j])
          const weekday = ddd && (new Date(ddd).getDay() - 1) + "";
          const weekdayArr2 = ['月', '火', '水', '木', '金'];
          // const classObj = classroom.find(item => item._id === classId);
          // const periodArr2 = semesterYear === "2017" || semesterYear === "2019" ? ['一', '二', '三', '四'] : ['五', '六', '七', '八'];
          const period = periodArr[j].toString()
          const courseName = ddd && period ? weekdayArr2[Number(weekday)] + headerArr[periodArr[j] % 4] : '';

          const obj = {
            // ...rollcallObj,
            objectId: studentId,
            jpnName: student.jpnName,
            studentId: studentId,
            studentNum: student.studentId,
            attendStatus: '1',
            attendStatusText: '出席',
            classname: student.classSchool || '',
            period: period,
            weekday,
            courseName,
            eventDate: ddd,
          }

          var dataRef = db.doc(`/Students/${studentId}/Daily/${ddd}`)
          batch.set(dataRef, {
            classname: obj.classname,
            eventDate: ddd,
            studentId: studentId
          })

          var dataRef2 = db.doc(`/Students/${studentId}/Daily/${ddd}/Rollcall/${period}`)
          batch.set(dataRef2, obj)
        }
      }
      var promise = batch.commit();
      promises.push(promise);
    }
  }

  handleAttandChange = (attendStatus) => {
    const { multiSelect, optSet } = this.props
    const { multiStudent, selPeriod, selDate, selStudentId, rollcallArr } = this.state;
    let newArr = [...rollcallArr]
    let attendStatusText = getOptionTextByValue(optSet, 'attentInClassroom', attendStatus)
    if (multiSelect === true) {
      multiStudent.map(item => {
        const weekday = item.selDate && (new Date(item.selDate).getDay() - 1) + "";
        let stuRollcall = newArr.find(rollcall => rollcall.studentId === item.selStudentId) || { studentId: item.selStudentId }
        stuRollcall['attendStatus' + weekday + item.selPeriod] = attendStatus
        stuRollcall['attendStatusText' + weekday + item.selPeriod] = attendStatusText
        newArr = [...newArr, stuRollcall]
      })
      this.setState({ rollcallArr: newArr })
    } else if (multiSelect === false) {
      const weekday = selDate && (new Date(selDate).getDay() - 1) + "";
      let stuRollcall = newArr.find(rollcall => rollcall.studentId === selStudentId) || { studentId: selStudentId }
      stuRollcall['attendStatus' + weekday + selPeriod] = attendStatus
      stuRollcall['attendStatusText' + weekday + selPeriod] = attendStatusText
      this.setState({ rollcallArr: [...newArr, stuRollcall] })
    }
  }

  getBGColor = (studentId, eventDate, period) => {
    const { multiSelect } = this.props;
    const { multiStudent } = this.state;
    if (multiSelect === true) {
      return (
        multiStudent.find(item =>
          studentId === item.selStudentId &&
          eventDate === item.selDate &&
          period + '' === item.selPeriod + ''
        ) ?
          { backgroundColor: 'yellow' } : { cursor: 'pointer' }
      )
    } else if (multiSelect === false) {
      return (
        studentId === this.state.selStudentId &&
          eventDate === this.state.selDate &&
          period + '' === this.state.selPeriod + '' ?
          { backgroundColor: 'yellow' } : { cursor: 'pointer' }
      )
    }
  }

  closeModal = () => {
    this.setState({
      showModal: false
    })
  }

  handleClear = () => {
    this.setState({
      multiStudent: []
    })
  }

  render() {
    const { difMonth, optSet, classObj, year, week, history, calendar, mode, studentsClass,
      weekDateRange, weekDateRange2, selTypeId, firebase, multiSelect } = this.props;
    const { showModal, HourAttendance, loading,
      rollcallArr, multiStudent, selStudentObj, selPeriod, selDate } = this.state;
    // const { selStudentObj, selPeriod, selDate } = multiStudent
    const weekdayArr = [1, 2, 3, 4, 5];                                                // sun: 0, mon: 1, tues: 2, wed: 3, thurs: 4, friday: 5, sat: 6
    const headerArr = classObj.classTime === '1' ? [1, 2, 3, 4] : [5, 6, 7, 8];          // 午前顯示: [1, 2, 3, 4], 午後顯示[5, 6, 7, 8]
    const periodArr = headerArr.slice().map(v => v - 1);                               // 午前: [0, 1, 2, 3], 午後: [4, 5, 6, 7]
    // console.log(rollcallArr)
    const month = weekDateRange[0].getMonth() + 1;
    // let firstDay = Moment(weekDateRange[0]).set('date', 1).format('YYYY-MM-DD');
    // // console.log(firstDay)
    // const firstdayWeek = getWeekNumber(new Date(firstDay));
    // // console.log(firstdayWeek)
    // const weekStartDate = getDateOfISOWeek(firstdayWeek[0], firstdayWeek[1], 0);
    // const firstweekDateRange = getWeekdayRangeArr(weekStartDate);
    // // console.log(firstweekDateRange)
    // let difMonth = (firstDay === firstweekDateRange[1] || firstDay === firstweekDateRange[2] || firstDay === firstweekDateRange[3] || firstDay === firstweekDateRange[4]) ? 1 : 0
    // console.log(difMonth)

    // const showDevelop = true;// Roles.userIsInRole(userId, ['admin', 'developer']);

    // var arrAttendance = [];
    // var sum, avg, countninty, counteighty, countseventy, countsixty;
    // arrAttendance = rollcallArr0.length ? rollcallArr0.map(student => this.getHoursAttendance(calendar, student, weekDateRange, periodArr)) : null;
    // if (!!arrAttendance) {
    //   sum = afirstweekDateRangerrAttendance.reduce((previous, current) => current += previous);
    //   avg = Math.round(sum / arrAttendance.length * 100);
    //   countninty = arrAttendance.filter(i => i > 0.89).length;
    //   counteighty = arrAttendance.filter(i => (i < 0.9 && i > 0.79)).length;
    //   countseventy = arrAttendance.filter(i => (i < 0.8 && i > 0.69)).length;
    //   countsixty = arrAttendance.filter(i => (i < 0.7)).length;
    // }

    // console.log('selTypeId', selTypeId)

    // const staticArr = [];
    // if (weekDateRange && weekDateRange.length) {
    //   let dayTotal0 = 0;
    //   let dayTotal1 = 0;
    //   let hoursTotal0 = 0;
    //   let hoursTotal1 = 0;

    //   console.log(weekDateRange);
    //   // 每週
    //   weekDateRange.forEach(item => {
    //     // const rollcallObj = rollcallArr.find(item2=>item2.eventDate===)

    //     const day = getDaysCount(rollcallArr, item, periodArr, calendar);
    //     const hours = getHoursCount(rollcallArr, item, periodArr, calendar);

    //     const arrDay = day.split("/");
    //     const arrHours = hours.split("/");

    //     dayTotal0 += Number(arrDay[0]);
    //     dayTotal1 += Number(arrDay[1]);
    //     hoursTotal0 += Number(arrHours[0]);
    //     hoursTotal1 += Number(arrHours[1]);

    //     const dayAcc = dayTotal0 + "/" + dayTotal1;
    //     const hoursAcc = hoursTotal0 + "/" + hoursTotal1;

    //     const obj = { day, hours, dayAcc, hoursAcc };
    //     staticArr.push(obj);
    //   });
    // }
    // console.log(studentsClass)

    const commonRowSpan = selTypeId === '3' ? "2" : "1";
    const arrPdf = [{
      year,
      week,
      classObj,
      weekdayArr,
      headerArr,
      periodArr,
      month,
      weekDateRange,
      studentsClass,
      rollcallArr,
      weekDateRange2,
      calendar,
      difMonth
    }]
    return (<>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p style={{ fontSize: '18px' }}>
          {classObj.classSchool} クラス   担任: {classObj.teacherText} <br />
          {year}年{month}月 第{weekAndDay(weekDateRange[0]) + difMonth}週{weekDateRange[0].getMonth() !== weekDateRange[4].getMonth() ? ` / ${weekDateRange[4].getFullYear()}年${weekDateRange[4].getMonth() + 1}月 第${weekAndDay(weekDateRange[4])}週` : ''}&nbsp;&nbsp;
          {`${month}月${weekDateRange[0].getDate()}日`} ~ {`${weekDateRange[4].getMonth() + 1}月${weekDateRange[4].getDate()}日`}
        </p>
        <Form.Field>
          <Label pointing size={'large'}>{`${month} 月 ${weekDateRange[0].getDate()} 日`} ~ {`${weekDateRange[4].getMonth() + 1} 月 ${weekDateRange[4].getDate()} 日`}</Label>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <ModalPdf
            {...this.props}
            // ref={(ref) => { this.pdfModal = ref }}
            codekey={'att6'}
            floated='right'
            params={arrPdf}
          />
        </Form.Field>
      </div>
      {showModal || multiSelect ?
        <RollcallModal
          {...this.props}
          modalObj={selStudentObj}
          classObj={classObj}
          eventDate={selDate}
          period={selPeriod}
          closeModal={this.closeModal}
          multiStudent={multiStudent}
          handleClear={this.handleClear}
          handleAttandChange={this.handleAttandChange}
        /> : null}
      {year && week ?
        <Table id='excel' structured celled className='tdPadding2px thPadding2px' style={{ textAlign: 'center', padding: '2px' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell rowSpan={2} ><br />NO.</Table.HeaderCell>
              <Table.HeaderCell rowSpan={2} ><br />氏名</Table.HeaderCell>
              <Table.HeaderCell colSpan={4}>{weekDateRange[0].getDate()}日 ㈪</Table.HeaderCell>
              <Table.HeaderCell colSpan={4}>{weekDateRange[1].getDate()}日 ㈫</Table.HeaderCell>
              <Table.HeaderCell colSpan={4}>{weekDateRange[2].getDate()}日 ㈬</Table.HeaderCell>
              <Table.HeaderCell colSpan={4}>{weekDateRange[3].getDate()}日 ㈭</Table.HeaderCell>
              <Table.HeaderCell colSpan={4}>{weekDateRange[4].getDate()}日 ㈮</Table.HeaderCell>
              <Table.HeaderCell rowSpan={2}>日<br />数<br />計</Table.HeaderCell>
              <Table.HeaderCell rowSpan={2}>時<br />間<br />計</Table.HeaderCell>
              {/* <Table.HeaderCell rowSpan={2}>累<br />日<br />計</Table.HeaderCell>
              <Table.HeaderCell rowSpan={2}>累<br />時<br />計</Table.HeaderCell> */}
            </Table.Row>
            <Table.Row>
              {weekdayArr.map((item, index) =>
                <React.Fragment key={`${item}_${index}`}>
                  <Table.HeaderCell>{headerArr[0]}</Table.HeaderCell>
                  <Table.HeaderCell>{headerArr[1]}</Table.HeaderCell>
                  <Table.HeaderCell>{headerArr[2]}</Table.HeaderCell>
                  <Table.HeaderCell>{headerArr[3]}</Table.HeaderCell>
                </React.Fragment>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {/* {loading ? <Table.Row>
              <Table.Cell colSpan={24}>
                <Segment>
                  <Dimmer active inverted={companyObj.inverted}>
                    <Loader inverted={companyObj.inverted}>Loading</Loader>
                  </Dimmer>
                  <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                </Segment>
              </Table.Cell></Table.Row> : <> */}
            {studentsClass.length ? studentsClass.map(student => {
              const rollcall = rollcallArr.find(item => item.studentId === student.objectId) || {};
              return (<Table.Row key={'ss' + student.objectId}>
                <Table.Cell rowSpan={commonRowSpan}>{student.studentId} </Table.Cell>
                <Table.Cell id={`${student.objectId}`} rowSpan={commonRowSpan} /*onClick={this.handleClickName}*/>{typeof student.jpnName === "string" ? student.jpnName : ''} </Table.Cell>
                {weekDateRange2.map((eventDate, week) => {
                  const arrCalendar = calendar.filter(item => item.eventDate === eventDate && item.status === "3");
                  if (arrCalendar.length) {
                    return (<Table.Cell colSpan={4} key={"wdr" + week} >{arrCalendar.map(item => <div key={item.objectId} style={{ color: '#999' }}>{item.title ? item.title : '(休)'}</div>)}</Table.Cell>);
                  }

                  return (<React.Fragment key={week}>
                    {rollcallArr.length ? periodArr.map(period => {
                      const attendStatus = rollcall['attendStatus' + week + period]
                      const attendStatusText = rollcall['attendStatusText' + week + period]
                      return <Table.Cell key={'cell' + week + period + rollcall.studentId}
                        id={`${student.objectId},${eventDate},${period}`} period={period}
                        style={this.getBGColor(student.objectId, eventDate, period)}
                        onClick={this.handleClickCell}>
                        {showCell(attendStatus, attendStatusText)}
                      </Table.Cell>;
                    }) : <Table.Cell colSpan='4'><BeatLoader loading sizeUnit="px" size={12} color='#4A90E2' /></Table.Cell>}
                  </React.Fragment>)
                })}
                <Table.Cell rowSpan={commonRowSpan}>{rollcall.daysCount}</Table.Cell>
                <Table.Cell rowSpan={commonRowSpan}>{rollcall.hoursCount}</Table.Cell>
                {/* <Table.Cell rowSpan={commonRowSpan}></Table.Cell>
                <Table.Cell rowSpan={commonRowSpan}></Table.Cell> */}
              </Table.Row>)
            }) : null}
            {/* </>} */}
          </Table.Body>
        </Table> : null}
    </>);
  }
}