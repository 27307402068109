import React, { Component } from 'react'
import { Container, Header, Grid, Dimmer, Loader, Image, Segment } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { Col, Row } from "reactstrap";
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

const columns = () => ([
  { title: '項目', field: 'value' },
]);

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}
export default class Fin1102a2 extends Component {
  state = {
    acc1: [],
    acc2: [],
    acc3: [],
    data1: [],
    data2: [],
    data3: [],
    sel1: '',
    sel2: '',
    sel3: '',
    title1: '',
    title2: '',
    title3: '',
    loading: true,
    obj: {},
    auth: {},
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { match, companyObj } = props;
    const companyId = companyObj.objectId;

    const account1 = await Parse.queryData('account1', { companyId, balance: '1' });
    // let dataArr = account1.filter(item => item.objectId === 'vtvfMREPEJ' || item.objectId === 'XZb3Gy9eLg' || item.objectId === 'b4zzTZiWmQ')
    this.setState({ acc1: account1, data1: account1, loading: false, account1 });

    const account2 = await Parse.queryData('account2', { companyId });
    this.setState({ acc2: account2 });
  }

  handleAdd = async (id) => {
    const { newData1, newData2, newData3 } = this.state;
    if (id === "1") {
      await Parse.saveData('account1', newData1)
    } else if (id === "2") {
      await Parse.saveData('account2', newData2)
    }
    this.handleFetch()
  }

  handleUpdate = async (id) => {
    const { newData1, newData2, newData3 } = this.state

    if (id === "1") {
      await Parse.saveData('account1', newData1)
    } else if (id === "2") {
      await Parse.saveData('account2', newData2)
    }
    this.handleFetch()
  }

  handleDelete = async (id) => {
    const { oldData1, oldData2, oldData3, acc2, acc1 } = this.state
    if (id === "1") {
      if (acc2.find(item => item.a1_id === oldData1.objectId)) {
        await Parse.destroyData('account2', { a1_id: oldData1.objectId })
      }
      await Parse.deleteData('account1', oldData1);
      this.setState({ sel1: '' })
      this.handleFetch()
    } else if (id === "2") {
      await Parse.deleteData('account2', oldData2);
      this.handleFetch()
    }
  }

  handleData = (id, rowData) => {
    const { acc1, acc2, acc3 } = this.state;
    const sel = rowData.objectId;
    if (id === "1") {
      this.setState({ sel1: sel, sel2: '', sel3: '', sel4: '', title1: rowData.value, data2: acc2.filter(item => item.a1_id === sel) })
    }
    // else if (id === "2") {
    //   this.setState({ sel2: sel, sel3: '', sel4: '', title2: rowData.value, data3: acc3.filter(item => item.a2_id === sel) })
    // }
  }

  editable1 = {
    isEditHidden: () => !this.props.auth.edit,
    isDeleteHidden: rowData => rowData.objectId === 'vtvfMREPEJ' || rowData.objectId === 'XZb3Gy9eLg' || rowData.objectId === 'b4zzTZiWmQ' || !this.props.auth.edit,
    onRowAdd: newData1 =>
      new Promise(async (resolve, reject) => {
        const db = Parse.Object.extend("account1");
        const newDoc = new db();
        const doc = await newDoc.save();
        const data = [...this.state.data1];

        const obj = {
          _id: doc.id || '',
          id: doc.id || '',
          objectId: doc.id || '',
          companyId: this.props.companyObj.objectId || '',
          companyName: this.props.companyObj.name || '',
          a1_id: this.state.sel1 || '',
          a1_text: (this.state.acc1.find(item => item.objectId === this.state.sel1) && this.state.acc1.find(item => item.objectId === this.state.sel1).value) || '',
          balance: '1',
          ...newData1
        }
        data.push(obj);

        this.setState({ data1: data, newData1: obj }, () => resolve());
      }).then(() => this.handleAdd("1")),
    onRowUpdate: (newData1, oldData1) =>
      new Promise((resolve, reject) => {
        const data = [...this.state.data1];
        const index = data.indexOf(oldData1);
        data[index] = newData1;
        this.setState({ data1: data, newData1, oldData1 }, () => resolve());
      }).then(() => this.handleUpdate("1")),
    onRowDelete: oldData1 =>
      new Promise((resolve, reject) => {
        let data = [...this.state.data1];
        const index = data.indexOf(oldData1);
        data.splice(index, 1);
        this.setState({ data1: data, oldData1 }, () => resolve());
      }).then(() => this.handleDelete("1")),
  }

  editable2 = {
    isEditHidden: () => !this.props.auth.edit,
    isDeleteHidden: () => !this.props.auth.edit,
    onRowAdd: newData2 =>
      new Promise(async (resolve, reject) => {
        const db = Parse.Object.extend("account2");
        const newDoc = new db();
        const doc = await newDoc.save();
        const data = [...this.state.data2];

        const obj = {
          _id: doc.id || '',
          id: doc.id || '',
          objectId: doc.id || '',
          companyId: this.props.companyObj.objectId || '',
          companyName: this.props.companyObj.name || '',
          a1_id: this.state.sel1 || '',
          a1_text: (this.state.acc1.find(item => item.objectId === this.state.sel1) && this.state.acc1.find(item => item.objectId === this.state.sel1).value) || '',
          ...newData2
        }
        data.push(obj);

        this.setState({ data2: data, newData2: obj }, () => resolve());
      }).then(() => this.handleAdd("2")),
    onRowUpdate: (newData2, oldData2) =>
      new Promise((resolve, reject) => {
        const data = [...this.state.data2];
        const index = data.indexOf(oldData2);
        data[index] = newData2;
        // console.log(data1)
        this.setState({ data2: data, newData2, oldData2 }, () => resolve());
      }).then(() => this.handleUpdate("2")),
    onRowDelete: oldData2 =>
      new Promise((resolve, reject) => {
        let data = [...this.state.data2];
        const index = data.indexOf(oldData2);
        data.splice(index, 1);
        this.setState({ data2: data, oldData2 }, () => resolve());
      }).then(() => this.handleDelete("2")),
  }

  render() {
    const { companyObj, auth } = this.props;
    const { acc1, acc2, acc3, data1, data2, data3, title1, title2, title3, sel1, sel2, sel3, obj, loading } = this.state;

    // const 

    // const

    if (auth.edit === false) {
      delete this.editable1.onRowAdd
      delete this.editable2.onRowAdd
    }

    return (<>
      <div style={{ ...style.flexCenter, position: 'relative', zIndex: 100 }}>
        <Header inverted={companyObj.inverted} as='h2' style={{ margin: '2rem 0' }}>資產負債科目</Header>
      </div>
      {/* <Grid columns={2} divided> */}
      {/* <Grid.Row> */}
      {/* <Grid.Column> */}
      <Row>
        <Col md={{ size: 6, }}>
          <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns()} options={{
              addRowPosition: "first",
              exportButton: true,
              exportAllData: true,
              exportPdf: (columns, data) => exportPdf(columns, data, '項目'),
              pageSize: data1.length || 5,
              search: false
            }}
            data={data1}
            title='項目'
            actions={[
              {
                icon: 'view_headline',
                tooltip: '查看',
                onClick: (event, rowData) => this.handleData("1", rowData)
              }
            ]}
            onRowClick={(event, rowData) => this.handleData("1", rowData)}
            editable={this.editable1}
          />
          {/* </Grid.Column> */}
        </Col>
        {/* <Grid.Column> */}
        <Col>
          {sel1 ?
            <MaterialTable
              localization={localization()}
              columns={columns()}
              options={{
                addRowPosition: "first",
                exportButton: true,
                exportAllData: true,
                exportPdf: (columns, data) => exportPdf(columns, data, "資產負債科目 " + title1),
                pageSize: data2.length || 5,
                search: false
              }}
              data={data2}
              title={"資產負債科目 " + title1}
              // onRowClick={(event, rowData) => this.handleData("2", rowData)}
              editable={this.editable2}
            /> : null}
          {/* </Grid.Column> */}
        </Col>
        {/* </Grid.Row> */}
      </Row>
      {/* </Grid> */}
      <br />
      <br />
    </>)
  }
}