
export const getNationalityArr = (students) => {
  return (
    students ? students.reduce((nationality, student) => {
      student.nationality && nationality.indexOf(student.nationality) === -1 ?
        nationality.push(student.nationality) : void (0)
      // console.log(nationality, student.nationality);
      return nationality;
    }, []) : []
  );
};