/*eslint-disable*/
import React from "react";
import { withCookies } from 'react-cookie';

// reactstrap components
import { Container, Row } from "reactstrap";


const FooterBlack = (props) => {

  return (<>
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <a
                  href="#"
                  target="_blank"
                  className="mr-1"
                >
                  About
                  </a>
              </li>
              <li>
                <a
                  href="#"
                  target="_blank"
                  className="mr-1"
                >
                  Blog
                  </a>
              </li>
              <li>
                <a
                  href="#"
                  target="_blank"
                >
                  Licenses
                  </a>
              </li>
              <li>
                <a
                  href="/auth/login"
                // target="_blank"
                >
                  Admin
                  </a>
              </li>
            </ul>
          </nav>
          <div className="credits ml-auto">
            <nav className="footer-nav">
              <ul>
                {/* <li>
                    &nbsp;
                  </li> */}
                {/* <li>
                    <span className="" onClick={() => changeLang('en-US')}>
                      English
              </span>
                  </li>
                  <li>
                    <span className="" onClick={() => changeLang('ja-JP')}>
                      日本語
                      </span>
                  </li>
                  <li>
                    <span className="" onClick={() => changeLang('zh-TW')}>
                      繁體中文
              </span>
                  </li>
                  <li>
                    <span className="" onClick={() => changeLang('zh-CN')}>
                      簡體中文
              </span>
                  </li> */}
                <li>
                  <span className="copyright">
                    © {new Date().getFullYear()}
                , made with <i className="fa fa-heart heart" /> by Infowin Technology
              </span>
                </li>
              </ul>
            </nav>
          </div>
        </Row>
      </Container>
    </footer>
  </>
  );
}

// export default FooterBlack;
export default withCookies(FooterBlack);