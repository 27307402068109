import React, { Component } from 'react'
import { Container, Header, Button, Form, Grid, Checkbox, Icon } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

const statusSel = [
  { key: '0', text: '-- 全選 --', value: '0' },
  { key: '1', text: '申請中', value: '1' },
  { key: '2', text: '已完成', value: '2' },
  { key: '3', text: '已取消', value: '3' },
  { key: '4', text: 'Pending', value: '4' },
]

const columns = () => ([
  dateCol({ title: '日期', field: 'caseDate' }),
  { title: '客戶名稱', field: 'name_cht', editable: 'never' },
  { title: 'Client Name', field: 'name_eng', editable: 'never' },
  { title: 'Provider', field: 'provider_engtext', editable: 'never' },
  { title: '單號', field: 'uid', editable: 'never' },
  {
    title: 'Service Item', field: 'as_template_text', editable: 'never',
    render: rowData => rowData.as_template_text || rowData.serviceText
  },
  dateCol({ title: 'Due Date', field: 'dueDate' }),
  { title: 'Status', field: 'status', lookup: { '1': '申請中', '2': '已完成', '3': '已取消', '4': 'Pending' } },
  { title: '備註', field: 'ps' },
  { title: 'Description', field: 'description' },
  { title: 'Action Owner', field: 'bgText' },
]);

export default class After3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginUser: this.props.user.profile.toJSON(),
      productType2Opt: [{ key: 'productType20', text: 'agent', value: 'productType20' }],
      productType3Opt: [{ key: 'productType30', text: 'status', value: 'productType30' }],
      productType4Opt: [{ key: 'productType40', text: 'actionowner', value: 'productType40' }],
      disabled: true,
      disabled2: true,
      disabled3: true,
      type1: '',
      type2: '',
      value1: 'productType10',
      value2: 'productType20',
      value3: 'productType30',
      value4: 'productType40',
      dataArr: [],
      dataArrOri: [],
      statusId: '1',
      loading: true,
      boxId: '',
      rowData: {},
      rowDataChecked: '-1',
      searchText: '',
    }
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    const { loginUser } = this.state
    const { companyObj } = this.props
    const authPage = window.location.pathname.split('/')[2]
    const authUser = companyObj.objectId + '_' + authPage
    let auth = {}
    let isAgent = ''

    if (loginUser.authAdminA && loginUser.authAdminA.indexOf(companyObj.objectId) === -1 && loginUser.role.value === '1') {
      isAgent = loginUser.objectId
    }

    if ((loginUser.authAdminA && loginUser.authAdminA.indexOf(companyObj.objectId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
      auth = {
        edit: true,
      }
    } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
      auth = {
        edit: false,
      }
    } else {
      auth = {
        edit: false,
      }
    }
    this.setState({ auth, isAgent }, () => this.handleFetch(this.props))
  }

  handleFetch = async (props = this.props) => {
    const { isAgent } = this.state
    const { companyObj } = props;
    const companyId = companyObj.objectId;
    let afterserviceArr = []
    if (isAgent) {
      afterserviceArr = await Parse.queryData('afterservice', { companyId, agent_id: isAgent });
    } else {
      afterserviceArr = await Parse.queryData('afterservice', { companyId });
    }
    const afterservice = afterserviceArr.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    const portfolios = await Parse.queryData('portfolios', { companyId });
    const dataArr = afterservice.map(item => {
      let obj = {}
      if (item.portfolio) {
        obj = {
          ...item.portfolio,
          ...item,
        }
        delete obj.portfolio
        return obj
      } else if (item.portfolio_id) {
        let porData = portfolios.find(item2 => item2.objectId === item.portfolio_id)
        obj = {
          ...porData,
          ...item,
        }
        return obj
      } else {
        return item
      }
    })
    this.setState({ dataArr: dataArr.filter(item => item.status === '1'), dataArrOri: dataArr, loading: false, afterservice });
  }

  handleUpdate = async () => {
    const { newData, statusId } = this.state
    console.log(newData)
    let obj = {
      ...newData,
      caseDate: newData.caseDate ? (new Date(newData.caseDate.iso || newData.caseDate)) || new Date() : null,
      dueDate: newData.dueDate ? (new Date(newData.dueDate.iso || newData.dueDate)) || new Date() : null,
    }
    await Parse.saveData('afterservice', obj);
    this.setState({ oldData: {}, newData: {}, }, () => this.handleChange(null, { name: 'statusId', value: statusId }))
  }

  handleDelete = async () => {
    const { oldData, statusId } = this.state
    await Parse.deleteData('afterservice', oldData);
    this.setState({ oldData: {} }, () => this.handleChange(null, { name: 'statusId', value: statusId }))
  }

  handleSearch = (e, { value }) => {
    const { dataArrOri, statusId } = this.state;

    if (value) {
      // 搜尋值中間可以插入任意值 例如搜尋'程雪原' 可以找到 '程 雪原'
      const looseSearchValue = RegExp(value.split('').reduce((pattern, item) => pattern + item + '.*', '.*'));
      const dataArrSearch = dataArrOri.filter(item => (item && item.portfolio && item.portfolio.name_cht && item.portfolio.name_cht.match(looseSearchValue)) || (item && item.name_cht && item.name_cht.match(looseSearchValue)));
      let dataArr = []
      if (statusId !== '0') {
        dataArr = dataArrSearch.filter(item => item.status === statusId)
      } else {
        dataArr = dataArrSearch
      }
      this.setState({ dataArr, searchText: value });
    }
    else {
      let dataArr = []
      if (statusId !== '0') {
        dataArr = dataArrOri.filter(item => item.status === statusId)
      } else {
        dataArr = dataArrOri
      }
      this.setState({ dataArr, searchText: value });
    }
  }

  handleChange = (e, data) => {
    const { dataArrOri, searchText } = this.state
    const { name, value, checked, rowData } = data
    if (name === 'statusId') {
      if (value === '0') {
        let dataArrSearch = []
        if (searchText && searchText.length) {
          const looseSearchValue = RegExp(searchText.split('').reduce((pattern, item) => pattern + item + '.*', '.*'));
          dataArrSearch = dataArrOri.filter(item => (item && item.portfolio && item.portfolio.name_cht && item.portfolio.name_cht.match(looseSearchValue)) || (item && item.name_cht && item.name_cht.match(looseSearchValue)));
        } else {
          dataArrSearch = dataArrOri
        }
        this.setState({ dataArr: dataArrSearch, [name]: value }, () => this.tableRef.current.onChangePage(null, 0))
      } else if (value !== '0') {
        const data = dataArrOri.filter(item => item.status === value)
        let dataArrSearch = []
        if (searchText && searchText.length) {
          const looseSearchValue = RegExp(searchText.split('').reduce((pattern, item) => pattern + item + '.*', '.*'));
          dataArrSearch = data.filter(item => (item && item.portfolio && item.portfolio.name_cht && item.portfolio.name_cht.match(looseSearchValue)) || (item && item.name_cht && item.name_cht.match(looseSearchValue)));
        } else {
          dataArrSearch = data
        }
        this.setState({ dataArr: dataArrSearch, [name]: value }, () => this.tableRef.current.onChangePage(null, 0))
      }
    } else if (name === 'rowDataChecked') {
      if (checked === true) {
        console.log(rowData)
        data.onRowDataChange({ ...rowData, important: '1' })
      } else if (checked === false) {
        data.onRowDataChange({ ...rowData, important: '0' })
      }
    }
  }

  render() {
    const { companyObj } = this.props
    const { productType4Opt, dataArr, value4, loading, statusId, searchText, auth, dataArrOri } = this.state;

    return (<div className="content">
      <Container>
        {/* <div style={{ ...style.flexCenter, margin: '2rem auto' }}>
          <Header as='h2' style={{ margin: 0 }}>2-2 案件追蹤</Header>
          <div style={{ ...style.flex }}>
            <Button content='下載' />
            <Button content='寄案件追蹤給Agent' />
          </div>
        </div> */}
        <br />
        <br />
        <Grid style={{ marginBottom: '2rem' }}>
          <Grid.Row columns='equal'>
            <Grid.Column computer={8} tablet={8} mobile={16}>
              <Header inverted={companyObj.inverted} content='SEARCH' />
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={16}>
              <Form><Form.Input name='productfilter' onChange={this.handleSearch} placeholder='Client Name' value={searchText} /></Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns='equal'>
            <Grid.Column computer={8} tablet={8} mobile={16}>
              <Header inverted={companyObj.inverted} content='STATUS' />
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={16}>
              <Form><Form.Select options={statusSel} name='statusId' onChange={this.handleChange} value={statusId} style={{ zIndex: 999 }} /></Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns='equal'>
            <Grid.Column computer={8} tablet={8} mobile={16}>
              <Header inverted={companyObj.inverted} content='ACTIONOWNER' />
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={16}>
              <Form><Form.Select options={productType4Opt} name='productType4' onChange={this.handleChange} value={value4} style={{ zIndex: 99 }} /></Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        tableRef={this.tableRef}
        columns={[{
          title: '重要', field: 'important',
          render: rowData => rowData.important === '1' ? <Icon name='exclamation' color='red' /> : null,
          editComponent: props => {
            return <Checkbox name='rowDataChecked' onClick={this.handleChange} checked={props.rowData.important === '1' ? true : false} onRowDataChange={props.onRowDataChange} rowData={props.rowData} />
          }
        }, ...columns()]}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data),
          pageSize: 20,
          search: false,
        }}
        data={dataArr}
        title=""
        editable={{
          isEditHidden: () => !auth.edit,
          isDeleteHidden: () => !auth.edit,
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              // const data = [...dataArr];
              const data2 = [...dataArrOri];
              // const index = data.indexOf(oldData);
              const index2 = data2.indexOf(oldData);
              let obj = {}
              obj = {
                ...newData,
                // dueDate: (newData.dueDate !== '' && newData.dueDate !== null && typeof newData.dueDate === 'object' && newData.dueDate.toISOString()) || (newData.dueDate !== '' && newData.dueDate !== null && typeof newData.dueDate === 'string' && newData.dueDate) || '',
                // dueDate: (newData.dueDate !== '' && newData.dueDate !== null && typeof newData.dueDate === 'object' && newData.dueDate.toISOString()) || (newData.dueDate !== '' && newData.dueDate !== null && typeof newData.dueDate === 'string' && newData.dueDate) || '',
                // caseDate: (newData.caseDate !== '' && newData.caseDate !== null && typeof newData.caseDate === 'object' && newData.caseDate.toISOString()) || (newData.caseDate !== '' && newData.caseDate !== null && typeof newData.caseDate === 'string' && newData.caseDate) || '',
                status_text: statusSel.find(item => item.value === newData.status).text,
              };
              // data[index] = obj;
              data2[index2] = obj;
              // handleState('people0', data);
              this.setState({ dataArrOri: data2, newData: obj, oldData }, () => resolve());
            }).then(() => this.handleUpdate(0)),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              // const data = [...dataArr];
              // const index = data.indexOf(oldData);
              // data.splice(index, 1);
              const data2 = [...dataArrOri];
              const index2 = data2.indexOf(oldData);
              data2.splice(index2, 1);
              // handleState('people0', data);
              this.setState({ dataArrOri: data2, oldData }, () => resolve());
            }).then(() => this.handleDelete(0)),
        }}
      />
      <br />
      <br />
    </div>)
  }
}