import React, { Component, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { SyncLoader } from 'react-spinners';

import Dropzone from 'react-dropzone'
import Webcam from "react-webcam";
import { Modal, Button, Grid, Progress, Icon } from 'semantic-ui-react';


// import resizebase64 from "resize-base64";

export default class UploadPhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      webcamImg: '',
      imageSrc: '',
      loaded: false,

      filenames: [],
      downloadURLs: [],
    };
  }

  componentWillMount() {
  }

  componentDidMount() {
    this.getBase64Photo();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ doc: nextProps.doc });
    // console.log('doit');
    this.getBase64Photo();
  }

  handleOpen = () => {
    this.setState({ show: true, webcamImg: '' });
  }

  handleClose = () => {
    this.setState({ show: false, webcamImg: '', form: {} });
  }

  getBase64Photo = () => {
    const { doc, photoField, match } = this.props;
    this.setState({ loaded: false });

    const findId = doc._id;
    // console.log(findId);

    // firebase.firestore().doc(`Students/${match.params._id}`).get().then(documentSnapshot => {
    //   let photo = documentSnapshot.get('photo');
    //   if (photo) {
    //     this.setState({ imageSrc: photo });
    //   } else {
    //     this.setState({ imageSrc: '' });
    //   }
    //   this.setState({ loaded: true });

    // })
    // Meteor.call('base64.findOne', findId, photoField, (error, imageSrc) => {
    //   if (error) {
    //     Bert.alert(error.reason, 'danger');
    //   } else {
    //     if (imageSrc) {
    //       this.setState({ imageSrc });
    //     } else {
    //       this.setState({ imageSrc: '' });
    //     }
    //     this.setState({ loaded: true });
    //   }
    // });
  }

  recapture = () => {
    this.setState({ webcamImg: '' });
  }

  setRef = webcam => {
    this.webcam = webcam;
  };

  removePicture = () => {
    const { doc, photoField } = this.props;

    // if (confirm('Are you sure? This is permanent!')) {
    //   Meteor.call('base64.remove', doc._id, photoField, (error) => {
    //     if (error) {
    //       Bert.alert(error.reason, 'danger');
    //     } else {
    //       this.setState({ imageSrc: '' });
    //       Bert.alert('Photo deleted!', 'success');
    //     }
    //   });
    //   // Meteor.call('base64.uploadFiles', _id, photoField, downloadUrl, (error) => {
    //   //   if (error) {
    //   //     Bert.alert(error.reason, 'danger');
    //   //   } else {
    //   //     this.setState({ imageSrc: '' });
    //   //     Bert.alert('Photo deleted!', 'success');
    //   //   }
    //   // });
    // }
  }

  handleSubmit = () => {
    const { doc, photoField } = this.props;
    const { webcamImg } = this.state;

    let userId = doc._id;
    // let metaContext = { avatarId: userId };

    // var selectedFile = acceptedFiles[0];
    // selectedFile.convertToBase64((base64) => {
    //   // alert(base64);
    //   this.setState({ imageSrc: base64 });

    //   let methodName = 'student.uploadPhotos';
    //   if (photoField === "photoTeacher") {
    //     methodName = 'teacher.uploadPhotos';
    //   }
    //   // console.log(methodName, userId, photoField, base64);
    //   Meteor.call(methodName, userId, photoField, base64);
    // });

    // let methodName = 'student.uploadPhotos';
    // if (photoField === "photoTeacher") {
    //   methodName = 'teacher.uploadPhotos';
    // }
    let methodName = 'base64.uploadFiles';
    // console.log(methodName, userId, photoField, webcamImg);

    // Meteor.call(methodName, userId, photoField, webcamImg, () => {
    //   this.setState({
    //     imageSrc: { [photoField]: webcamImg },
    //     show: false
    //   })
    // });
  }

  capture = () => {
    const imageSrc = this.webcam.getScreenshot();
    this.setState({ webcamImg: imageSrc });
    // console.log(imageSrc);
  };

  // dataURLtoFile(dataurl, filename) {
  //   let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
  //     bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   return new File([u8arr], filename, { type: mime });
  // }
  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccess = async filename => {
    const { match } = this.props;

    // const downloadURL = await this.props.firebase
    //   .storage()
    //   .ref("images")
    //   .child(filename)
    //   .getDownloadURL();

    // // console.log(downloadURL);
    // // firebase.firestore().doc(`Students/${match.params._id}`).update({ photo: downloadURL });
    // this.setState(oldState => ({
    //   filenames: [...oldState.filenames, filename],
    //   downloadURLs: [...oldState.downloadURLs, downloadURL],
    //   uploadProgress: 100,
    //   isUploading: false,
    //   imageSrc: downloadURL
    // }));
  };

  upload() {
    const { doc, photoField } = this.props;

    let userId = doc._id;
    // let metaContext = { avatarId: userId };

    // var selectedFile = acceptedFiles[0];
    // selectedFile.convertToBase64((base64) => {
    //   // alert(base64);
    //   this.setState({ imageSrc: { [photoField]: base64 } });

    //   const methodName = 'base64.uploadFiles';
    //   Meteor.call(methodName, userId, photoField, base64);
    // });

    // console.log(document.getElementById('input').files);
    // uploader.send(document.getElementById('input').files[0], function (error, downloadUrl) { // you can use refs if you like
    //   if (error) {
    //     // Log service detailed response
    //     console.error('Error uploading', uploader.xhr.response);
    //     alert(error); // you may want to fancy this up when you're ready instead of a popup.
    //   }
    //   else {
    //     // we use $set because the user can change their avatar so it overwrites the url :)
    //     // console.log("downloadUrl", downloadUrl);

    //     if (photoField === "photoTeacher") {
    //       Meteor.call('teacher.uploadPhotos', userId, photoField, downloadUrl);
    //     } else {
    //       Meteor.call('student.uploadPhotos', userId, photoField, downloadUrl);
    //     }
    //   }
    //   // you will need this in the event the user hit the update button because it will remove the avatar url
    //   // this.setState({ avatar: downloadUrl });
    // }.bind(this));
  }

  onDrop = async (acceptedFiles, rejectedFiles) => {
    // const { photoField, doc } = this.props;
    this.setState({ loaded: false })
    // console.log('acceptedFiles', acceptedFiles);
    // console.log('rejectedFiles', rejectedFiles);
    // const req = request.post('/upload');
    // acceptedFiles.forEach(file => {
    //     //  req.attach(file.name, file);
    //     console.log(file);
    // });
    // req.end(callback);
    // let userId = doc._id;
    // let metaContext = { avatarId: userId };

    var selectedFile = acceptedFiles[0];
    console.log(selectedFile)
    // return;

    const { match } = this.props;

    const downloadURL = await this.props.firebase
      .storage()
      .ref("images")
      .child(selectedFile)
      .getDownloadURL();

    // console.log(downloadURL);
    // firebase.firestore().doc(`Students/${match.params._id}`).update({ photo: downloadURL });
    // this.setState(oldState => ({
    //   // filenames: [...oldState.filenames, filename],
    //   downloadURLs: [...oldState.downloadURLs, downloadURL],
    //   uploadProgress: 100,
    //   isUploading: false
    // }));

    // selectedFile.convertToBase64(async (picOri) => {
    //   // alert(base64);
    //   // console.log("base", picOri.length);
    //   const picSm = await resizedataURL(picOri, 60);
    //   const picMd = await resizedataURL(picOri, 120);
    //   const picLg = await resizedataURL(picOri, 400);
    //   // console.log("picOri", picOri.length, picLg.length, picMd.length, picSm.length);
    //   this.setState({ imageSrc: picMd });

    //   const methodName = 'base64.uploadFiles';
    //   Meteor.call(methodName, userId, photoField, picOri, picSm, picMd, picLg, () => {
    //     this.setState({ loaded: true });
    //   });
    // });

    // let uploader = new Slingshot.Upload("UsersAvatar", metaContext);
    // uploader.send(acceptedFiles[0], function (error, downloadUrl) { // you can use refs if you like
    //   if (error) {
    //     // Log service detailed response
    //     console.error('Error uploading', error);
    //     // console.error('Error uploading', uploader.xhr.response);
    //     alert(error); // you may want to fancy this up when you're ready instead of a popup.
    //   }
    //   else {
    //     // we use $set because the user can change their avatar so it overwrites the url :)
    //     // console.log("downloadUrl", downloadUrl);

    //   }
    // you will need this in the event the user hit the update button because it will remove the avatar url
    // this.setState({ avatar: downloadUrl });
    // }.bind(this));
  }

  render() {
    const { isEdit, doc, photoField } = this.props;
    const { imageSrc, loaded } = this.state;

    const videoConstraints = {
      width: 1280,
      height: 720,
      facingMode: "user"
    };

    const dropzoneStyle = {
      // width: 200,
      maxWidth: "100%",
      maxHeight: "120px",
      // height: 250,
      borderWidth: 2,
      borderColor: '#666',
      borderStyle: 'dashed',
      borderRadius: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
    isEdit ? dropzoneStyle.borderStyle = 'dashed' : dropzoneStyle.borderStyle = 'solid';
    isEdit ? dropzoneStyle.cursor = 'pointer' : dropzoneStyle.cursor = 'default';

    const activeStyle = {
      borderStyle: 'solid',
      backgroundColor: '#eee'
    };

    const disabledStyle = {
      opacity: 0.8
    };

    const acceptStyle = {
      borderStyle: 'solid',
      borderColor: '#6c6',
      backgroundColor: '#eee'
    };

    const rejectStyle = {
      borderStyle: 'solid',
      borderColor: '#c66',
      backgroundColor: '#eee'
    };

    const accept = "image/png, image/jpeg, image/gif";

    return (
      <>
        {this.state.isUploading && <div>Progress: <Progress percent={this.state.progress} indicating /></div>}
        {/* {this.state.avatarURL && <img src={this.state.avatarURL} />} */}
        <span style={{ fontSize: '14px' }}>
          {/* {photoField === "photos" ? "個人" : photoField === "photoTeacher" ? "教師" : photoField === "photosInterview" ? "面接" : photoField === "photosInterview2" ? "面接2" : ""} */}
          写真&nbsp;</span>
        <br />
        <span align="center">{imageSrc ? <img src={imageSrc} style={{ maxWidth: '100%' }} /> : <>写真なし</>}</span>
        <br />
        {isEdit ? <Button as='label' fluid
          content={<>ファイルを選ぶ
            {/* <FileUploader
            hidden
            accept="image/*"
            name="avatar"
            randomizeFilename
            storageRef={this.props.firebase.storage().ref("images")}
            onUploadStart={this.handleUploadStart}
            onUploadError={this.handleUploadError}
            onUploadSuccess={this.handleUploadSuccess}
            onProgress={this.handleProgress}
            multiple
          /> */}
          </>}
          labelPosition="left"
          icon={<Icon name='file' />}
        /> : null}
        {/* <Dropzone
          // onDrop={acceptedFiles => console.log(acceptedFiles)}
          onDrop={this.onDrop}
          disabled={!isEdit}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <span align="center">{doc[photoField] ? <img src={doc[photoField]} style={{ width: '100%' }} /> : <>写真なし</>}</span>
                <p>Drag 'n' drop some files here, or click to select files</p>
                {loaded ? <span align="center">{imageSrc ? <img src={imageSrc} style={{ maxWidth: '100%' }} /> : <>写真なし</>}</span>
                  : <SyncLoader loading sizeUnit="px" size={18} color='#4A90E2' />}

              </div>
            </section>
          )}
        </Dropzone> */}
        {/* {isEdit ? <Button size="small" basic color='orange' onClick={this.handleOpen} style={{ marginRight: '40px' }}>カメラ</Button> : ''} */}
        {isEdit && (doc[photoField] || imageSrc) ? <Button size="small" variant="outline-danger" onClick={this.removePicture}>削除写真</Button> : ''}

        <Modal open={this.state.show} onHide={this.handleClose} size="large">
          <Modal.Content closeButton>
            <Modal.Header>写真取</Modal.Header>
          </Modal.Content>
          <Modal.Content>
            <Grid.Row>
              <Grid.Column sm={12}>
                {this.state.webcamImg ?
                  <img src={this.state.webcamImg} /> :
                  <Webcam
                    audio={false}
                    width={'100%'}
                    ref={this.setRef}
                    screenshotFormat="image/jpeg"
                    // width={350}
                    videoConstraints={videoConstraints}
                  />
                }
              </Grid.Column>
            </Grid.Row>
          </Modal.Content>
          <Modal.Content>
            {this.state.webcamImg ?
              <Button basic color='orange' onClick={this.recapture}>重拍</Button> :
              <Button basic color='green' onClick={this.capture}>拍照</Button>
            }
            {this.state.webcamImg ?
              <Button primary onClick={this.handleSubmit} icon='add' content='新增' /> : ''
            }
            <Button onClick={this.handleClose} icon='remove' content='關閉' />
          </Modal.Content>
        </Modal>
      </>
    );
  }
};