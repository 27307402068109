import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

const columns = () => ([
  { title: '牌照號碼', field: 'licenseNum' },
  { title: '車主名稱', field: 'ownersName' },
  { title: '出廠年份/月份', field: 'factoryYearMonth' },
  { title: '排氣量', field: 'displacement' },
  { title: '車身類型', field: 'bodyType' },
  { title: '車輛廠牌', field: 'vehicleBrand' },
  { title: '車輛車系', field: 'vehicle' },
  { title: '車輛車型', field: 'vehicleModel' },
  { title: '引擎類型', field: 'engineType' },
  { title: '顏色名稱', field: 'colorName' },
  { title: '檢查號碼', field: 'checkNum' },
  { title: '引擎號碼', field: 'engineNum' },
  { title: '車身號碼', field: 'bodyNum' },
  { title: '底盤號碼', field: 'chassisNum' },
  { title: '變速箱類型', field: 'gearboxType' },
  { title: '車主資料', field: 'ownerInformation' },
  { title: '預定定保日期', field: 'scheduledWarrantyDate' },
  { title: '使用人資料', field: 'userProfile' },
  { title: '預定定保里程', field: 'bookAGuaranteedMileage' },
  { title: '聯絡人資料', field: 'contactInformation' },
  { title: '定保里程間隔', field: 'guaranteedMileageInterval' },
]);
const columns2 = () => ([
  { title: '序號', field: 'serialNum' },
  { title: '交修類別', field: 'deliveryType' },
  { title: '零件編號', field: 'partNum' },
  { title: '零件名稱', field: 'partName' },
  { title: '簡碼編號', field: 'shortcodeNum' },
  { title: '零件級別', field: 'partLevel' },
  { title: '計算單位', field: 'unitOfMeasurement' },
  { title: '庫存儲位', field: 'inventoryStorage' },
  { title: '單價', field: 'unitPrice' },
  { title: '數量', field: 'quantity' },
  { title: '金額', field: 'amount' },
  { title: '標準單價', field: 'standardUnitPrice' },
  { title: '折扣率', field: 'discountRate' },
  { title: '維修技師', field: 'maintenanceTechnician' },
  { title: '交修獎金', field: 'handoverBonus' },
  { title: '交修分析', field: 'handoverAnalysis' },
]);

export default class Hr1 extends Component {
  state = {
    dataArr: [],
    filenames: [],
    downloadURLs: [],
    docs: [],
    fileArr: [],
    fileArrCheck: [],
    show: false,
    // loading: true,
    obj: {}
  }

  // componentDidMount() {
  //   this.handleFetch();
  // }

  // componentWillReceiveProps(nextProps) {
  //   this.handleFetch(nextProps);
  // }

  // handleFetch = async (props = this.props) => {
  //   const { companyObj } = props;

  //   const companyId = companyObj.objectId;
  //   const data = await Parse.queryData('hrform_management', { companyId });
  //   this.setState({ dataArr: data, loading: false });
  // }

  // handleUpdate = async () => {
  //   const { newData } = this.state
  //   await Parse.saveData('hrform_management', newData);
  // }

  render() {
    const { dataArr, loading } = this.state;

    return (<div className='content'>
      {/* <Container> */}
      {/* <div style={{ ...style.flexCenter, position: 'relative', zIndex: 100 }}>
          <Header as='h2' style={{ margin: '2rem 0' }}>4-1 人事規章</Header>
        </div> */}
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          pageSize: 5,
          search: true,
          searchFieldStyle: {
            width: 90,
          }
        }}
        data={dataArr}
        title={'維修紀錄'}
      />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns2()}
        options={{
          pageSize: 5,
          search: true,
          searchFieldStyle: {
            width: 90,
          }
        }}
        data={dataArr}
        title={'交修零件'}
      />
      <br />
      <br />
    </div>)
  }
}