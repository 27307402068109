import React, { Component } from 'react'
import { Container, Icon, Button, Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
import MaterialTable, { MTableToolbar } from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ModalUploadFiles from 'views/web/ModalUploadFiles';
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';


// const src = '/image-text.png'

const columns = () => ([
  // { title: '發佈對象 ', field: 'towhom', lookup: { '0': '', '1': '內部', '2': '外部' } },
  { title: '預覽', field: 'src', render: rowData => <a href={rowData && rowData.theFile && rowData.theFile.url} target="_blank" rel="noopener noreferrer">{rowData.name}</a> },
  { title: '名稱', field: 'filename' },
  // { title: '上傳時間', field: 'insertAt', render: rowData => Moment(rowData.insertAt||rowData.insertAt.).format("YYYY-MM-DD hh:mm:ss") },
]);

export default class List4Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      content: '',
      filenames: [],
      downloadURLs: [],
      docs: [],
      fileArr: [],
      fileArrCheck: [],
      loading: true,
    };
  }

  fileInputRef = React.createRef();


  componentDidMount() {
    this.handleFetch(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // // // console.log('componentWillReceiveProps');
    const { firebase, deleteAllFiles, history, refetch, companyObj } = nextProps
    const { doc } = this.props
    const { docs } = this.state
    // console.log(doc)
    // if (deleteAllFiles) {
    //   // delete fileArr
    //   const db = firebase.firestore();
    //   let batch = db.batch();
    //   if (docs.length > 0) {
    //     // filedoc.forEach(item => {
    //     for (let i = 0; i < docs.length; i++) {
    //       console.log(docs[i])
    //       const fileRef = db.collection(`users`).doc(doc._id)
    //       batch.delete(fileRef)
    //       firebase.storage().ref("files").child(docs[i].fakefilename).delete();
    //       // batch.delete(storageRef)
    //     }
    //     // })
    //   }
    //   batch.commit();
    //   // refetch("Clients")
    //   nextProps.handleFetch()
    //   window.alert('刪除成功')
    //   // history.push(`/employee/1`)
    //   nextProps.handleIsNotEdit()
    // } else {
    this.handleFetch(nextProps);
    // }
  }

  handleFetch = async (props = this.props) => {
    const { firebase, match, history, doc, bgId, companyObj } = props;
    const { employeeId, portfolioId = '' } = match.params;
    // const db = firebase.firestore();
    // console.log("good")
    // if (bgId) {
    // db.collection(`users/${employeeId}/files`).orderBy('insertAt').get().then(snap => {
    // console.log("fetch")
    this.setState({ loading: true })
    const userFile = await Parse.queryData('userFile', { companyId: companyObj.objectId, employeeId: doc.objectId })
    // snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    this.setState({ docs: userFile, loading: false })
    // })
    // }
  }

  handleDelete = async (fileId, filename) => {
    const { oldData, } = this.state;
    // try {
    // await firebase.firestore().collection(`users/${employeeId}/files`).doc(`${fileId}`).delete();
    // await firebase.storage().ref("files").child(filename).delete();
    await Parse.deleteData('userFile', oldData)
    this.setState({ oldData: {} })
    // } catch (error) {
    // console.log("handled by outer try-catch", error);
    // }
  }

  handleUploadStart = () => this.setState({ isUploading: true, });
  // handleProgress = progress => {
  //   // console.log("5");
  //   this.setState({ progress });
  // };
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleFilesChange = async (e) => {
    const fileArr = e.target.files;
    if (!fileArr || !fileArr.length) {
      return
    }
    const { companyObj, doc } = this.props
    console.log(doc)
    const promises = [];
    for (let i = 0; i < fileArr.length; i++) {
      const file = fileArr[i];
      promises.push(Parse.putFile(file));
    }
    const theFiles = await Promise.all(promises).catch(error => console.log("error", error));

    const promises2 = [];
    theFiles.forEach(async (theFile) => {
      const db = Parse.Object.extend("userFile");
      const newDoc = new db();
      const docRef = await newDoc.save();

      const element = {
        objectId: docRef.id,
        id: docRef.id,
        _id: docRef.id,
        companyId: companyObj.objectId,
        companyName: companyObj.name,
        employeeId: doc.objectId,
        theFile
      };
      promises2.push(Parse.saveData('userFile', element));
    })
    await Promise.all(promises2).catch(error => console.log("error", error));
    window.alert('上傳成功')
  }


  render() {
    const { doc, auth } = this.props;
    //     if (!auth.uid) return <Redirect to='/signin' />
    const { docs, loading, fileArr, fileArrCheck, } = this.state

    // const { photoIndex, isOpen } = this.state;
    // const { isLoading } = this.props;
    // const { selectedIndex, lightboxIsOpen } = this.state;

    return (<Container>
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        components={{
          Toolbar: props => (<div>
            <div style={{ width: '210px', float: 'right', textAlign: 'right', padding: '15px' }}>
              {auth.edit ? <ModalUploadFiles {...this.props} isImage='0' handleData={this.handleFetch} dbName='userFile' fieldName='theFile' condition={{ employeeId: doc.objectId }} /> : null}
              {/* <Button as='label'
                    color='orange'
                    size='small'
                    content={<>選擇檔案（多選）<FileUploader
                      hidden
                      // accept="image/*,application/pdf"
                      name="avatar"
                      // filename={file => this.state.username + file.name.split('.')[1]; }
                      filename={file => {
                        let obj = {}
                        let fakeFileName = `${String(Math.random() * 100000000000000000)}_${file.lastModified}_${file.name}`
                        obj = {
                          fakefilename: fakeFileName,
                          filename: file.name,
                          lastModified: file.lastModified,
                          lastModifiedDate: file.lastModifiedDate,
                          webkitRelativePath: file.webkitRelativePath,
                          size: file.size,
                          type: file.type,
                        }
                        fileArr.push(obj)
                        fileArrCheck.push(fakeFileName)
                        this.setState({ fileArr, loading: true, fileArrCheck })
                        return fakeFileName
                      }}
                      // filename={file => this.setState({ realFileName: file })}
                      // randomizeFilename
                      storageRef={this.props.firebase.storage().ref(`files`)}
                      onUploadStart={this.handleUploadStart}
                      onUploadError={this.handleUploadError}
                      onUploadSuccess={this.handleUploadSuccess}
                      // onProgress={this.handleProgress}
                      multiple
                    /></>}
                    labelPosition="left"
                    icon={<Icon name='arrow alternate circle up' />}
                  /> */}
            </div>
            <MTableToolbar {...props} />
            <div style={{ clear: 'both' }}></div>
          </div>),
        }}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '附加檔案'),
          pageSize: 5,
          search: false
        }}
        // data2={bankaccSel}
        data={docs}
        title={`附加檔案`}
        editable={{
          isDeleteHidden: () => !auth.edit,
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...docs];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              // handleState('people0', data);
              this.setState({ docs: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
    </Container >)
  }
}
