import React, { Component } from 'react'
import { Table, Form, Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';
import { Link } from 'react-router-dom';

import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import Parse from '../../../widget/parse'

const bikeStateOpt = [
  { key: 'bikeStateNull', value: ' ', text: '-- 選択 --' },
  { key: 'bikeState1', value: '1', text: '使用中' },
  { key: 'bikeState2', value: '2', text: '紛失' },
]

const style = {
  tableShow: {
    display: 'table',
  },
  tableHide: {
    display: 'none',
  }
}

export default class StuBike extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: props.match.params.activeIndex1 || '0',
      // doc: props.students[0],
      // docId: props.students[0]._id,
      arrRow: [],
      loading: true,
      show: false,
      bikeData: [],
      bicycle: [],
      students: []
    };
  }

  async componentDidMount() {
    // console.log('componentDidMount');
    const { companyObj } = this.props
    const data = await Parse.queryData('Bicycle', { companyId: companyObj.objectId });
    this.setState({ bicycle: data })

    const data1 = await Parse.queryData('Students', { companyId: companyObj.objectId });
    this.setState({ students: data1 }, () => this.handleFetch());
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { match, companyObj } = this.props
    const { bikeState, students, bicycle } = this.state
    const tmp = students.filter(item =>
      (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
      (item.applyResult === '1' || item.studentType === "2"));
    const arrRow = tmp;//.filter(item => item.classSchool === classSchool); // 找到這班的所有人
    this.setState({ arrRow, loading: 1 });

    // const db = firebase.firestore();

    if (bikeState && bikeState !== ' ') {
      // db.collectionGroup('Bicycle').where('condition', '==', bikeState).get().then(snap2 => {
      //   snap2.forEach(bike => bikeData.push({ _id: bike.id, ...bike.data() }))
      const bikeData = await Parse.queryData('Bicycle', { companyId: companyObj.objectId, condition: bikeState });
      this.setState({ bikeData, loading: 0 })
    } else {
      // db.collectionGroup('Bicycle').get().then(snap2 => {
      //   snap2.forEach(bike => bikeData.push({ _id: bike.id, ...bike.data() }))
      const bikeData = await Parse.queryData('Bicycle', { companyId: companyObj.objectId });
      this.setState({ bikeData, loading: 0 })
    }
    // const promises = [];
    // arrRow.forEach(doc => {
    //   const promise = Parse.queryData('Bicycle', { companyId: companyObj.objectId }).then(snap2 => {
    //     const bikeData = [];
    //     snap2.forEach(bike => bikeData.push(bike))
    //     return bikeData;
    //   })
    // var promise = db.collection(`Students/${doc._id}/Bicycle`)
    //   .get().then(snap2 => {
    //     const bikeData = [];
    //     snap2.forEach(bike => bikeData.push({ _id: bike.id, ...bike.data() }))
    //     return bikeData;
    //   })
    //   promises.push(promise);
    // });

    // Promise.all(promises).then(results => {
    //   console.log(results);
    //   const bikeData = results.filter(item => item.length);
    //   console.log(bikeData);
    //   this.setState({ bikeData, loading: 0 })
    // }).catch(error => {
    //   console.log(error);
    // });
  }

  handleChange = (event, data) => {
    const { name, value } = data
    let bikeStateTitle = '';
    if (value === '1') {
      bikeStateTitle = '使用中'
    } else if (value === '2') {
      bikeStateTitle = '紛失'
    }
    this.setState({ bikeState: value, bikeStateTitle }, () => this.handleFetch())
  }

  render() {
    const { companyObj } = this.props
    const { arrRow, show, loading, bikeData, bikeState, bikeStateTitle } = this.state
    // const numPerRow = 2
    // const bikes = []
    // arrRow.forEach(item => item.bikeData.map((item2, index2) => bikes.push(item2)))
    // const numTotal = bikes && bikes.length
    // console.log(bikeData)
    const titleArr = ['学籍番号', '氏名', '色', '自転車番号', '情況'];
    return (<div ref={el => (this.componentRef = el)} className="">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2 style={{ margin: 0 }}>自転車一覧{bikeState && bikeState !== ' ' ? `(${bikeStateTitle})` : null}</h2>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <Form.Field style={{ margin: '0 10px' }}>
            <Form.Select
              options={bikeStateOpt}
              label='自転車情況'
              name='bikeState'
              onChange={this.handleChange}
              defaultValue=' '
            /></Form.Field>
          <div style={{ float: 'right' }}>
            {/* <ReactToPrint
              trigger={(e) => <Button
                content='印刷'
                // label='&nbsp;'
                floated='right'
                color='orange' icon='print'
                className='no-print'
              />}
              content={() => this.componentRef}
              pageStyle={"@page { size: auto;  margin:5mm; }*{font-family: 'Noto Serif JP', serif;}"}
            /> */}
            <ModalPdf
              {...this.props}
              // ref={(ref) => { this.pdfModal = ref }}
              codekey="table16"
              params={bikeData}
            />
            <ExportTableToExcelButton />
          </div>
        </div>
      </div>
      <Table celled compact structured id='excel'>
        <Table.Header>
          <Table.Row textAlign='center'>
            {titleArr.map((item, index) => <Table.HeaderCell key={`title${index}`} >{item}</Table.HeaderCell>)}
          </Table.Row>
        </Table.Header>
        <Table.Body >
          {bikeData.map((item, index) =>
            <Table.Row key={`111${index}`} textAlign='center' style={{ borderBottom: '1px solid rgba(34,36,38,.1)' }}>
              {/* <Table.Cell collapsing>{item[0].studentId}</Table.Cell>
              <Table.Cell collapsing>{item[0].studentName}</Table.Cell>
              <Table.Cell collapsing>{item.map(item2 => <p key={`color${item2.number}`}>{item2.colorText}</p>)}</Table.Cell>
              <Table.Cell collapsing>{item.map(item2 => <p key={`number${item2.number}`}>{item2.number}</p>)}</Table.Cell>
              <Table.Cell collapsing>{item.map(item2 => <p key={`condition${item2.number}`}>{item2.conditionText || item2.condition}</p>)}</Table.Cell> */}
              <Table.Cell collapsing>{item.studentIdKey}</Table.Cell>
              <Table.Cell collapsing><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/2/1`}>{item.studentName}</Link></Table.Cell>
              <Table.Cell collapsing>{item.colorText}</Table.Cell>
              <Table.Cell collapsing>{item.number}</Table.Cell>
              <Table.Cell collapsing>{item.conditionText || item.condition}</Table.Cell>
            </Table.Row>)}
        </Table.Body>
      </Table>
      {loading ? <div style={{ justifyContent: 'center', margin: '30px 0' }}>
        <Segment>          <Dimmer active inverted={companyObj.inverted}>
          <Loader inverted={companyObj.inverted}>Loading</Loader>
        </Dimmer>
          <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        </Segment>
      </div> : null}
    </div>)
  }
}