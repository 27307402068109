import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  Label,
  FormGroup,
  Input
} from "reactstrap";
import Moment from 'moment';
import intl from 'react-intl-universal';
import { Button } from "semantic-ui-react";
import ModalAnnounce from '../web/ModalAnnounce';


// core components
function SectionBlog3(props = {}) {
  const { form, announceCenterArr, companyObj, handleAnnounceCenterArr, imagePreviewUrl } = props;
  const { img1File } = form
  // console.log(announceCenterArr)
  const picUrl = (img1File && img1File.url) || imagePreviewUrl || '/hp1/24.jpg';
  // console.log(img1File)
  return (
    <>
      {/* <div
        className="page-header page-header-xs"
        style={{
          backgroundImage:
            "url(" +
            picUrl +
            ")"
        }}
      > */}
      <div className="section secion-blog cd-section" style={{ margin: '200px 0px' }} id="blogs">
        {/* ********* BLOGS 3 ********* */}
        <div className="blog-3">
          <Container style={{ border: '1px solid' }}>
            <Row className='blogRow' style={{ paddingBottom: '20px' }}>
              <Col className="ml-auto mr-auto" md="10">
                <h2 className="title">{intl.get('最新消息')}</h2>
                <br />
                {announceCenterArr.map((item, index) => <>
                  {index % 2 === 0 ?
                    <Card className="card-plain card-blog">
                      <Row style={{ marginBottom: '20px' }}>
                        {item.img1File ?
                          <Col md="4">
                            <div className="card-image">
                              <img
                                alt="..."
                                className="img"
                                src={(item.img1File && item.img1File.url) || 'https://s2.best-wallpaper.net/wallpaper/5120x2880/1902/News-orange-background-creative-picture_5120x2880.jpg'}
                              />
                            </div>
                          </Col> : null}
                        <Col md="8">
                          <CardBody>
                            <CardTitle tag="h3">
                              <h6 style={{ fontSize: '22px' }}>
                                {Moment(item.date.iso).format('YYYY-MM-DD')}
                              </h6>
                            </CardTitle>
                            <p className="card-description" style={{ fontWeight: 500, fontSize: '18px' }}>
                              {item.title}
                            </p>
                          </CardBody>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12" md={{ size: 6, offset: 5 }}>
                          <ModalAnnounce fieldname='content' isEdit='0' AnnounceCenterObj={props} rowData={item} showContent={true} button='1' />
                        </Col>
                      </Row>
                    </Card> :
                    <Card className="card-plain card-blog">
                      <Row style={{ marginBottom: '10px' }}>
                        <Col md="8">
                          <CardBody>
                            <CardTitle tag="h3">
                              <h6 style={{ fontSize: '22px' }}>
                                {Moment(item.date.iso).format('YYYY-MM-DD')}
                              </h6>
                            </CardTitle>
                            <p className="card-description" style={{ fontWeight: 500, fontSize: '18px' }}>
                              {item.title}
                            </p>
                          </CardBody>
                        </Col>
                        {item.img1File ?
                          <Col md="4">
                            <div className="card-image">
                              <img
                                alt="..."
                                className="img"
                                src={(item.img1File && item.img1File.url) || 'https://s2.best-wallpaper.net/wallpaper/5120x2880/1902/News-orange-background-creative-picture_5120x2880.jpg'}
                              />
                            </div>
                          </Col> : null}
                      </Row>
                      <Row>
                        <Col sm="12" md={{ size: 6, offset: 5 }}>
                          <ModalAnnounce fieldname='content' isEdit='0' AnnounceCenterObj={props} rowData={item} showContent={true} button='1' />
                        </Col>
                      </Row>
                    </Card>}
                </>)}
                <Row>
                  <Col>
                    <Button floated='right' color='purple' onClick={handleAnnounceCenterArr} >{intl.get('更多消息')}</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{

              backgroundImage:
                "url(" +
                picUrl +
                ")",
              height: '500px',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}>
            </Row>
          </Container>
        </div>

        {/* ********* END BLOGS 3 ********* */}
      </div>

      {/* </div> */}
    </>
  );
}

export default SectionBlog3;
