
import React, { Component } from 'react'
import { Grid, Form, Table, Divider, Select } from 'semantic-ui-react';
// import MaterialTable from 'material-table'
import { Bar } from 'react-chartjs-2';
import Parse from 'parse';


var serverUrl = 'https://parse.infowin.com.tw/parse';
var appID = 'parseServer';

Parse.initialize("parseServer", "infowin");
Parse.serverURL = 'https://parse.infowin.com.tw/parse'

// import RowsDnd from './RowsDnd'

// reactstrap components

const payStatusOpt = [
  { key: 'all', text: '全部', value: '-1' },
  { key: 'paid', text: '已繳清', value: '0' },
  { key: 'unpaid', text: '未繳清', value: '1' },
]

const pray1StatData1 = (label, data) => ({
  labels: label,
  datasets: [
    {
      label: '總收入',
      backgroundColor: 'rgba(87, 171, 255, 1)',
      borderColor: 'rgba(87, 171, 255, 1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(87, 171, 255,1)',
      hoverBorderColor: 'rgba(87, 171, 255,1)',
      data
    }
  ]
})

const options = {
  scales: {
    xAxes: [{
      scaleLabel: {
        display: true,
        labelString: '法會'
      },
      ticks: {

      }
    }],
    yAxes: [{
      scaleLabel: {
        display: true,
        labelString: '已收'
      },
      ticks: {
        maxTicksLimit: 30,
        beginAtZero: false,
      }
    }],
  }
}

class Statistic2 extends Component {
  state = {
    mgyearId: '-1',
    pray1Id: '-1',
    paystatusId: '-1',
    arrStat: [],

    data1: [],
    data2: [],
    prayArr1: [],
    prayArr2: [],
    praySel: [],
    yearArr: [],
    yearSel: [],
    prayYearSelValue: '-1',
    praySelValue1: '-1',
    praySelValue2: '-1',

    allReceivableLabel: [],
    allReceivableData: [],
    allReceivableLabel2: [],
    allReceivableData2: [],

  }
  async componentDidMount() {
    const { companyObj } = this.props;

    const mgyear = await Parse.queryData('mgyear', { companyId: companyObj.objectId });
    const mgyearSel = mgyear.map(item => ({ key: item.objectId, text: item.value, value: item.value }))
    const mgyearId = (mgyear.find(item => item.now_use === '-1') && mgyear.find(item => item.now_use === '-1').value) || mgyear[0].value
    // const praying1 = await Parse.queryData('praying1', { companyId: companyObj.objectId, year: mgyearId }, { selectArr: ['pray1Id', 'accReceived'] });
    // const praying2 = await Parse.queryData('praying2', { companyId: companyObj.objectId, year: mgyearId }, { selectArr: ['pray1Id', 'rowTotal'] });
    // const query = new Parse.Query('lighting1');
    // query.equalTo('companyId', companyObj.objectId)
    // query.equalTo('year', '108')
    // query.equalTo('objectId', "8PCtL25GQF")
    // query.exists('accReceived')
    // query.select(['pray1Id', 'accReceived'])
    // query.limit(100000);
    // const snap = await query.find();
    // const praying1 = snap.map(data => data.toJSON());
    // const query2 = new Parse.Query('lighting2');
    // query2.equalTo('companyId', companyObj.objectId)
    // query2.equalTo('year', '108')
    // query2.equalTo('lighting1Id', "8PCtL25GQF")
    // // query2.exists('rowTotal')
    // // query2.select(['pray1Id', 'rowTotal'])
    // query2.limit(100000);
    // const snap2 = await query2.find();
    // const praying2 = snap2.map(data => data.toJSON());
    // console.log(praying1, praying2)
    const lighting2_light = await Parse.queryData('lighting2', { companyId: companyObj.objectId, year: mgyearId, p1: '-1' },);
    const lighting2_pharmacist = await Parse.queryData('lighting2', { companyId: companyObj.objectId, year: mgyearId, p3: '-1' },);
    const lighting2_biglight = await Parse.queryData('lighting2', { companyId: companyObj.objectId, year: mgyearId, p5: '-1' },);
    const lighting2_bigpharmacist = await Parse.queryData('lighting2', { companyId: companyObj.objectId, year: mgyearId, p7: '-1' },);
    const light1 = await Parse.queryData('light1', { companyId: companyObj.objectId });
    const allReceivableLabel = light1.map(item => item.value === '光明燈' || item.value === '藥師燈' || item.value === '光明大燈' || item.value === '藥師大燈' ? item.value : '').filter(item => item)
    this.setState({ mgyearSel, mgyearId, lighting2_light, lighting2_pharmacist, light1, lighting2_biglight, lighting2_bigpharmacist, allReceivableLabel }, () => this.handleShowGraph())
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value, loading: true }, () => this.handleData());
  }

  handleData = async () => {
    const { companyObj } = this.props
    const { mgyearId, yearSel } = this.state
    // const praying1 = await Parse.queryData('praying1', { companyId: companyObj.objectId, year: mgyearId }, { selectArr: ['pray1Id', 'accReceived'], limit: 100000 });
    // const praying2 = await Parse.queryData('praying2', { companyId: companyObj.objectId, year: mgyearId }, { selectArr: ['pray1Id', 'rowTotal'], limit: 100000 });
    // const query = new Parse.Query('praying1');
    // query.equalTo('companyId', companyObj.objectId)
    // query.equalTo('year', mgyearId)
    // query.exists('accReceived')
    // query.select(['pray1Id', 'accReceived'])
    // query.limit(100000);
    // const snap = await query.find();
    // const praying1 = snap.map(data => data.toJSON());
    // const query2 = new Parse.Query('praying2');
    // query2.equalTo('companyId', companyObj.objectId)
    // query2.equalTo('year', mgyearId)
    // query2.exists('rowTotal')
    // query2.select(['pray1Id', 'rowTotal'])
    // query2.limit(100000);
    // const snap2 = await query2.find();
    // const praying2 = snap2.map(data => data.toJSON());
    const lighting2_light = await Parse.queryData('lighting2', { companyId: companyObj.objectId, year: mgyearId, p1: '-1' },);
    const lighting2_pharmacist = await Parse.queryData('lighting2', { companyId: companyObj.objectId, year: mgyearId, p3: '-1' },);
    const lighting2_biglight = await Parse.queryData('lighting2', { companyId: companyObj.objectId, year: mgyearId, p5: '-1' },);
    const lighting2_bigpharmacist = await Parse.queryData('lighting2', { companyId: companyObj.objectId, year: mgyearId, p7: '-1' },);
    this.setState({ lighting2_light, lighting2_pharmacist, lighting2_biglight, lighting2_bigpharmacist, }, () => this.handleShowGraph())
    // //這邊會依照select去更新data1和data2的資料（從資料庫直接抓） 再到handleShowGraph去把圖表要的資料抽出來

    // const data1 = [ // 暫時的資料 假設有選
    //   {
    //     "_id": "106",
    //     "totalAccReceivable": 525900
    //   },
    //   {
    //     "_id": "107",
    //     "totalAccReceivable": 652000
    //   },
    //   {
    //     "_id": "108",
    //     "totalAccReceivable": 760600
    //   },
    //   {
    //     "_id": "109",
    //     "totalAccReceivable": 193200
    //   }
    // ];

    // if (praySelValue1 === '-1') {    // 沒選擇法會

    //   // const query = new Parse.Query("praying1");

    //   // 下面 db.getCollection('praying1') 可能要換成 const data1 = query

    //   // db.getCollection('praying1').aggregate([
    //   //   { $match : { companyId : { $eq : "IaFBUo2J16" } } },   
    //   //   { $group: { _id: "$year", totalAccReceivable: { $sum: "$accReceivable" } } },
    //   //   { $sort : { _id : 1} }
    //   //   ])

    // }
    // else {      // 有選擇法會

    //   // const query = new Parse.Query("praying1");

    //   // 下面 db.getCollection('praying1') 可能要換成 const data1 = query

    //   // db.getCollection('praying1').aggregate([
    //   //   { $match: { companyId: { $eq: "IaFBUo2J16" }, pray1Text: { $eq: praySel[praySelValue1].text } } },
    //   //   { $group: { _id: "$year", totalAccReceivable: { $sum: "$accReceivable" } } },
    //   //   { $sort: { _id: 1 } }
    //   // ])

    // }

    // const data2 = [  // 暫時的資料
    //   {
    //     "_id": "個人",
    //     "totalAccReceivable": 139070
    //   },
    //   {
    //     "_id": "吉祥功德主",
    //     "totalAccReceivable": 150900
    //   },
    //   {
    //     "_id": "圓滿功德主",
    //     "totalAccReceivable": 221380
    //   },
    //   {
    //     "_id": "如意功德主",
    //     "totalAccReceivable": 178500
    //   },
    //   {
    //     "_id": "福慧功德主",
    //     "totalAccReceivable": 250000
    //   },
    //   {
    //     "_id": "總功德主",
    //     "totalAccReceivable": 701500
    //   }
    // ];

    // if (praySelValue2 === '-1' && prayYearSelValue === '-1') { // 都沒選擇

    //   // const query = new Parse.Query("praying1");

    //   // 下面 db.getCollection('praying1') 可能要換成 const data2 = query

    //   // db.getCollection('praying1').aggregate([
    //   //   { $match : { companyId : { $eq : "IaFBUo2J16" } } },   
    //   //   { $group: { _id: "$pray2Text", totalAccReceivable: { $sum: "$accReceivable" } } },
    //   //   { $sort : { _id : 1} }
    //   //   ])

    // }
    // else if (praySelValue2 !== '-1') {  // 有選擇法會
    //   // const query = new Parse.Query("praying1");

    //   // 下面 db.getCollection('praying1') 可能要換成 const data2 = query

    //   // db.getCollection('praying1').aggregate([
    //   //   { $match : { companyId : { $eq : "IaFBUo2J16" }, pray1Text : { $eq : praySel[praySelValue2].text } } },   
    //   //   { $group: { _id: "$pray2Text", totalAccReceivable: { $sum: "$accReceivable" } } },
    //   //   { $sort : { _id : 1} }
    //   //   ])
    // }
    // else if (prayYearSelValue !== '-1') { // 有選擇年度
    //   // const query = new Parse.Query("praying1");

    //   // 下面 db.getCollection('praying1') 可能要換成 const data2 = query

    //   // db.getCollection('praying1').aggregate([
    //   //   { $match : { companyId : { $eq : "IaFBUo2J16" }, year : { $eq : yearSel[prayYearSelValue].text } } },   
    //   //   { $group: { _id: "$pray2Text", totalAccReceivable: { $sum: "$accReceivable" } } },
    //   //   { $sort : { _id : 1} }
    //   //   ])
    // }
    // else {   // 都有做選擇

    //   // const query = new Parse.Query("praying1");

    //   // 下面 db.getCollection('praying1') 可能要換成 const data2 = query

    //   // db.getCollection('praying1').aggregate([
    //   //   { $match : { companyId : { $eq : "IaFBUo2J16" }, year : { $eq : yearSel[prayYearSelValue].text }, pray1Text : { $eq : praySel[praySelValue2].text } } },   
    //   //   { $group: { _id: "$pray2Text", totalAccReceivable: { $sum: "$accReceivable" } } },
    //   //   { $sort : { _id : 1} }
    //   //   ])

    // }

    // this.setState({ data1, data2, loading: false }, () => this.handleShowGraph());

  }

  handleShowGraph = () => {
    const { lighting2_light, lighting2_pharmacist, light1, lighting2_biglight, lighting2_bigpharmacist, allReceivableId = [], allReceivableLabel, } = this.state

    // // 整理各年度加總的object
    // pray1Arr.forEach(item => {
    //   if (item.accReceivable) {
    //     const obj = item.accReceivable;
    //     Object.keys(obj).forEach(year => {
    //       allObj[year + ''] = (allObj[year + ''] || 0) + obj[year + ''];
    //     })
    //   }
    // })

    const allReceivableData = allReceivableLabel.map(item => {
      let allPoint = 0
      switch (item) {
        case '光明燈':
          allPoint = lighting2_light.length * Number(light1.find(item2 => item2.value === item).price)
          break;
        case '藥師燈':
          allPoint = lighting2_pharmacist.length * Number(light1.find(item2 => item2.value === item).price)
          break;
        case '光明大燈':
          allPoint = lighting2_biglight.length * Number(light1.find(item2 => item2.value === item).price)
          break;
        case '藥師大燈':
          allPoint = lighting2_bigpharmacist.length * Number(light1.find(item2 => item2.value === item).price)
          break;
        default:
          console.log(item)
          break;
      }
      // if (lighting2_light.filter(item2 => item2.pray1Id === item)) {
      //   praying1.filter(item2 => item2.pray1Id === item).forEach(item2 => item2.accReceived ? allPoint += Number(item2.accReceived) : allPoint += 0)
      // }
      // if (praying2.filter(item2 => item2.pray1Id === item)) {
      //   praying2.filter(item2 => item2.pray1Id === item).forEach(item2 => item2.rowTotal ? allPoint += Number(item2.rowTotal) : allPoint += 0)
      // }
      return allPoint
    })
    const dataArr = allReceivableLabel.map((item, index) => ({ year: item, point: allReceivableData[index] }))
    this.setState({ allReceivableLabel, allReceivableData, dataArr })

    // const allReceivableLabel = [];
    // const allReceivableData = [];

    // data1.forEach(item => {
    //   allReceivableLabel.push(item._id);
    //   allReceivableData.push(item.totalAccReceivable);
    // })

    // const allReceivableLabel2 = [];
    // const allReceivableData2 = [];

    // data2.forEach(item => {
    //   allReceivableLabel2.push(item._id);
    //   allReceivableData2.push(item.totalAccReceivable);
    // })

    // this.setState({ allReceivableLabel, allReceivableData, allReceivableLabel2, allReceivableData2 })


    // if (Object.keys(allObj).length) {
    //   // console.log(allObj);
    //   // allObj = {103: 176404000, 104: 8799000, 105: 7577500, 106: 5374000, 107: 5745000, 108: 4668500, 109: 740500}

    //   const allReceivableLabel = [];
    //   const allReceivableData = [];

    //   // 整理各年度陣列,金額陣列
    //   Object.keys(allObj).sort((a, b) => a - b).forEach(item => {
    //     allReceivableLabel.push(item + '')
    //     allReceivableData.push(allObj[item + ''])
    //   });

    //   this.setState({ allReceivableLabel: allReceivableLabel, allReceivableData: allReceivableData })
    //   /////////////////

    //   const arrStat = []
    //   pray1Arr.forEach(pray1Obj => {
    //     const receivableLabel = [];
    //     const receivableData = []
    //     Object.keys(pray1Obj.accReceivable).sort((a, b) => a - b).forEach(item => {
    //       receivableLabel.push(item + '')
    //       receivableData.push(pray1Obj.accReceivable[item])
    //     });
    //     arrStat.push({ pray1Name: pray1Obj.value, receivableLabel, receivableData })
    //   })

    //   this.setState({ arrStat })
    // }

  }
  // handleShowGraph = () => {
  //   const { pray1Arr, mgyearId } = this.state
  //   if (mgyearId === '-1' && pray1Arr.length) {
  //     const allObj = {};
  //     // 整理各年度加總的object
  //     pray1Arr.forEach(item => {
  //       if (item.accReceivable) {
  //         const obj = item.accReceivable;
  //         Object.keys(obj).forEach(year => {
  //           allObj[year + ''] = (allObj[year + ''] || 0) + obj[year + ''];
  //         })
  //       }
  //     })

  //     if (Object.keys(allObj).length) {
  //       // console.log(allObj);
  //       // allObj = {103: 176404000, 104: 8799000, 105: 7577500, 106: 5374000, 107: 5745000, 108: 4668500, 109: 740500}

  //       const allReceivableLabel = [];
  //       const allReceivableData = [];
  //       // 整理各年度陣列,金額陣列
  //       Object.keys(allObj).sort((a, b) => a - b).forEach(item => {
  //         allReceivableLabel.push(item + '')
  //         allReceivableData.push(allObj[item + ''])
  //       });

  //       this.setState({ allReceivableLabel: allReceivableLabel, allReceivableData: allReceivableData })
  //       /////////////////

  //       const arrStat = []
  //       pray1Arr.forEach(pray1Obj => {
  //         const receivableLabel = [];
  //         const receivableData = []
  //         Object.keys(pray1Obj.accReceivable).sort((a, b) => a - b).forEach(item => {
  //           receivableLabel.push(item + '')
  //           receivableData.push(pray1Obj.accReceivable[item])
  //         });
  //         arrStat.push({ pray1Name: pray1Obj.value, receivableLabel, receivableData })
  //       })

  //       this.setState({ arrStat })
  //     }
  //   } else {
  //     const allReceivableLabel = [];
  //     const allReceivableData = [];

  //     pray1Arr.forEach(pray1Obj => {
  //       if (pray1Obj.value) {
  //         allReceivableLabel.push(pray1Obj.value)
  //       }
  //       if (pray1Obj.accReceivable && pray1Obj.accReceivable[mgyearId]) {
  //         allReceivableData.push(pray1Obj.accReceivable[mgyearId])
  //       }
  //     })
  //     console.log(allReceivableData)
  //     this.setState({ allReceivableLabel, allReceivableData, arrStat: [] })
  //   }
  // }

  render() {
    const { mgyearId, prayId, mgyearSel, loading, loadingBtn, allReceivableLabel, allReceivableData, arrStat, allReceivableLabel2, allReceivableData2, yearSel, praySel, praySelValue1, praySelValue2, prayYearSelValue } = this.state;

    return (
      <>
        <div className="content">
          <Grid columns={2} divided style={{ margin: '20px' }}>
            <Grid.Row>
              <Grid.Column width={16}><h2>統計圖表</h2></Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form>
                  <Form.Group inline>
                    {/* <Form.Select label='年度' options={[{ key: 'aa', value: '-1', text: '全部' }].concat(mgyearOpt)} value={mgyearId} name='mgyearId' onChange={this.handleChange} />
                      <Form.Select label='法會' options={[{ key: 'aa', value: '-1', text: '全部' }].concat(pray1Opt)} value={pray1Id} name='pray1Id' onChange={this.handleChange} disabled='true' />
                      <Form.Select label='付款狀況' options={payStatusOpt} value={paystatusId} name='paystatusId' onChange={this.handleChange} disabled='true' />
                      <Form.Button color='blue' loading={loadingBtn} >重整資料</Form.Button> */}
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column computer={8} mobile={16}>
                <h3>{mgyearId === '-1' ? '所有' : mgyearId}年度各點燈總收入</h3>
                <p>
                  點燈
                  <Select
                    style={{ width: "30%" }}
                    compact
                    options={mgyearSel}
                    onChange={this.handleChange}
                    value={mgyearId}
                    name='mgyearId' />
                </p>

                <br />

                <Bar
                  data={pray1StatData1(allReceivableLabel, allReceivableData)}
                  height={400}
                  options={options}
                />
              </Grid.Column>
              <Grid.Column computer={8} mobile={16}>
                <Table size='small'>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>年度</Table.HeaderCell>
                      <Table.HeaderCell>應收</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {allReceivableLabel.length &&
                      allReceivableLabel.map((item, index) => (<Table.Row key={'arl' + index}>
                        <Table.Cell>{item}</Table.Cell>
                        <Table.Cell>{allReceivableData[index]}</Table.Cell>
                      </Table.Row>))}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              {arrStat.map(item =>
                <Grid.Column computer={8} mobile={16}>
                  <h2>{item.pray1Name}</h2>
                  <Bar
                    data={pray1StatData1(item.receivableLabel, item.receivableData)}
                    height={200}
                  />
                </Grid.Column>)}
            </Grid.Row>

            {/* <Divider />

            <Grid.Row>
              <Grid.Column width={16}>
                <Form>
                  <Form.Group inline>
                    <Form.Select label='年度' options={[{ key: 'aa', value: '-1', text: '全部' }].concat(mgyearOpt)} value={mgyearId} name='mgyearId' onChange={this.handleChange} />
                      <Form.Select label='法會' options={[{ key: 'aa', value: '-1', text: '全部' }].concat(pray1Opt)} value={pray1Id} name='pray1Id' onChange={this.handleChange} disabled='true' />
                      <Form.Select label='付款狀況' options={payStatusOpt} value={paystatusId} name='paystatusId' onChange={this.handleChange} disabled='true' />
                      <Form.Button color='blue' loading={loadingBtn} >重整資料</Form.Button>
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column computer={8} mobile={16}>
                <h3>{mgyearId === '-1' ? '所有' : mgyearId}年度法會總收入</h3>

                <p>
                  年度
                  <Select
                    style={{ width: "30%" }}
                    compact
                    options={yearSel}
                    onChange={this.handleChange}
                    value={prayYearSelValue}
                    name='prayYearSelValue' />
                  法會
                  <Select
                    style={{ width: "30%" }}
                    compact
                    options={praySel}
                    onChange={this.handleChange}
                    value={praySelValue2}
                    name='praySelValue2' />
                </p>

                <br />

                <Bar
                  data={pray1StatData1(allReceivableLabel2, allReceivableData2)}
                  height={200}
                />
              </Grid.Column>
              <Grid.Column computer={8} mobile={16}>
                <Table size='small'>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>年度</Table.HeaderCell>
                      <Table.HeaderCell>應收</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {allReceivableLabel2.length &&
                      allReceivableLabel2.map((item, index) => (<Table.Row key={'arl' + index}>
                        <Table.Cell>{item}</Table.Cell>
                        <Table.Cell>{allReceivableData2[index]}</Table.Cell>
                      </Table.Row>))}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              {arrStat.map(item =>
                <Grid.Column computer={8} mobile={16}>
                  <h2>{item.pray1Name}</h2>
                  <Bar
                    data={pray1StatData1(item.receivableLabel, item.receivableData)}
                    height={200}
                  />
                </Grid.Column>)}
            </Grid.Row> */}

            {/* <Grid.Row>
            <h2>點燈</h2>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Bar
                data={peopledata}
                height={200}
              />
            </Grid.Column>
            <Grid.Column>
              <Bar
                data={moneydata}
                height={200}
              />
            </Grid.Column>
          </Grid.Row> */}
          </Grid>
        </div>
      </>
    );
  }
}

export default Statistic2;
