import React, { Component, } from 'react';
import Moment from 'moment';
import { Form, Button, Grid, Table, Icon, Header, Confirm, TextArea } from 'semantic-ui-react';

import { showField, showFieldText, getOptionsByKey, renderFieldNoLabel, getOptionTextByValue } from 'views/widgets/FieldsRender';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Parse from '../../../../widget/parse'

export default class List4Student extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        number: '',
        color: this.getDefaultValue("bicycleColor"),
        condition: this.getDefaultValue("bicycleCondition"),
      },
      show: false,
      arrRow: [],
      confirm: false,
      loading: false,
    };
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    if (this.props.match !== nextProps.match)
      this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { firebase, match, companyObj } = props
    const { _id } = match.params;
    if (_id) {
      const data = await Parse.queryData('Bicycle', { companyId: companyObj.objectId, studentId: _id });

      // firebase.firestore()
      //   .collection(`Students/${_id}/Bicycle`)
      //   // .orderBy('relationship')
      //   // .where('eventDate', '==', eventDate).where('classname', '==', className)
      //   .get().then(snap => {
      //     const data = [];
      //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
      //     // console.log(data);
      this.setState({ arrRow: data });

    }
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    const { form } = this.state;
    this.setState({ form: { ...this.state.form, [name]: value } });
  };

  handleSubmit = async () => {
    const { companyObj, optSet, doc, semesterYear, semester } = this.props;
    const { form, arrRow } = this.state;
    // const db = firebase.firestore();

    this.setState({ loading: true });

    form.colorText = getOptionTextByValue(optSet, "bicycleColor", form.color);
    form.conditionText = getOptionTextByValue(optSet, "bicycleCondition", form.condition);
    // form.createdAt = Moment(new Date()).format('YYYY/MM/DD')

    const newRows = [...arrRow];
    if (!form.objectId) {
      // const docRef = db.collection(`Students/${doc.objectId}/Bicycle`).doc();
      const db = Parse.Object.extend('Bicycle');
      const newDoc = new db();
      const docRef = await newDoc.save();
      const obj = {
        ...form,
        objectId: docRef.id,
        studentId: doc.objectId,
        studentIdKey: doc.studentId,
        studentName: doc.jpnName,
        semesterYear,
        semester,
        companyId: companyObj.objectId,
        companyName: companyObj.name,
        createdDate: Moment(new Date()).format('YYYY/MM/DD')
      };
      // await docRef.set(obj);
      await Parse.saveData('Bicycle', obj)
      newRows.push(obj);
    } else {
      form.updatedAt = Moment(new Date()).format('YYYY/MM/DD')
      await Parse.saveData('Bicycle', form)
      // await db.collection(`Students/${doc.objectId}/Bicycle`).doc(form.objectId).update(form);
      let index = newRows.findIndex((item => item.objectId === form.objectId));
      newRows[index] = form;
    }

    this.setState({ arrRow: newRows, loading: false }, () => this.handleFetch());
    this.handleClose();
  }

  handleOpen = () => {
    this.setState({ show: true })
  }

  handleEdit = (bicycleObj) => {
    this.setState({ show: true, form: { ...bicycleObj } });
  }

  handleClose = () => {
    this.setState({
      show: false,
      form: {
        number: '',
        color: this.getDefaultValue("bicycleColor"),
        condition: this.getDefaultValue("bicycleCondition"),
      },
    });
  }

  handleRemove = async () => {
    const { firebase, doc } = this.props
    const { form, arrRow } = this.state;
    // await firebase.firestore().collection("Students").doc(doc.objectId).collection("Bicycle").doc(form.objectId).delete();
    await Parse.deleteData('Bicycle', form)
    const newRows = [...arrRow];
    let index = newRows.findIndex((item => item.objectId === form.objectId));
    newRows.splice(index, 1);
    this.setState({ arrRow: newRows });

    this.handleClose();
    this.hideConfirm();
  }

  showConfirm = (bicycleObj) => this.setState({ confirm: true, form: { ...bicycleObj } });
  hideConfirm = () => this.setState({ confirm: false, form: {} });

  getDefaultValue = (key) => {
    const { optSet } = this.props;
    const options = getOptionsByKey(optSet, key);
    return options[0].value;
  };

  render() {
    const { optSet, doc, isEdit, companyObj } = this.props;
    const { form, loading, confirm, arrRow, show } = this.state;
    const colors = getOptionsByKey(optSet, "bicycleColor");
    const conditions = getOptionsByKey(optSet, "bicycleCondition");

    return (<>
      <Header as='h2' dividing
        content='自転車'
        style={{ backgroundColor: 'lavender' }}
      // subheader='調査時間：2017年4月から 2018年12月 まで'
      />
      <Button inverted={companyObj.inverted} color='blue' disabled={!isEdit} onClick={this.handleOpen} icon='add' content='追加' />
      <br />
      <Table celled>
        <Table.Header>
          <Table.Row>
            {isEdit ? <Table.HeaderCell style={{ width: '100px' }}>#</Table.HeaderCell> : null}
            <Table.HeaderCell>自転車番号</Table.HeaderCell>
            <Table.HeaderCell>顏色</Table.HeaderCell>
            <Table.HeaderCell>情況</Table.HeaderCell>
            <Table.HeaderCell>記入時間</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {arrRow.map((item, index) => (<Table.Row key={`td${index}`}>
            {isEdit ? <Table.Cell style={{ padding: 10 }}>
              <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} icon='edit' />
              <Button inverted={companyObj.inverted} color='red' size='small' onClick={() => this.showConfirm(item)} icon='delete' />
            </Table.Cell> : null}
            <Table.Cell>{item.number}</Table.Cell>
            <Table.Cell>{item.colorText}</Table.Cell>
            <Table.Cell>{item.conditionText || item.condition}</Table.Cell>
            <Table.Cell>{item.createdDate ? item.createdDate : item.createdAt}</Table.Cell>
          </Table.Row>
          ))}
          {!arrRow.length ? <Table.Row>
            <Table.Cell colSpan={isEdit ? 5 : 4} textAlign='center' >(資料無し)</Table.Cell>
          </Table.Row> : null}
        </Table.Body>
      </Table>

      {/* <Modal open={show} onClose={this.handleClose} size='mini'> */}
      <Modal
        isOpen={show}
        toggle={this.handleClose}
        size="lg"
        className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader>
          <h2 style={{ margin: 0 }}> 自転車{form.objectId ? '修正' : '追加'}</h2>
          {/* <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon> */}
        </ModalHeader>
        <ModalBody>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <label style={{ display: 'block', marginBottom: 5 }}>自転車番号</label>
                <Form.Input
                  name="number" value={form.number} onChange={this.handleChange} style={{ minWidth: 196 }} placeholder='自転車番号'
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <label style={{ display: 'block', marginBottom: 5 }}>顏色</label>
                <Form.Select onChange={this.handleChange} name='color' defaultValue={form.objectId ? form.color : '1'}
                  options={colors.map(item => ({ key: item._id, value: item.value, text: item.label }))} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <label style={{ display: 'block', marginBottom: 5 }}>情況</label>
                <Form.Select onChange={this.handleChange} name="condition" defaultValue={form.objectId ? form.condition : '1'}
                  options={conditions.map(item => ({ key: item._id, value: item.value, text: item.label }))} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button size='small' onClick={this.handleSubmit} inverted={companyObj.inverted} color='green' loading={loading} icon='checkmark' content={form.objectId ? '保存' : '追加'} />
                <Button size='small' onClick={this.handleClose} basic>閉じる</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={this.state.confirm}
        toggle={this.hideConfirm}
        size="lg"
        className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader>削除</ModalHeader>
        <ModalBody>
          削除してもいいですか？
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button onClick={this.handleRemove} inverted color='red' icon='delete' content='はい' />
          <Button onClick={this.hideConfirm} content='いいえ' />
        </ModalFooter>
      </Modal>
      {/* <Confirm
        header='削除'
        content='削除してもいいですか?'
        cancelButton='いいえ'
        confirmButton='はい'
        open={this.state.confirm}
        onCancel={this.hideConfirm}
        onConfirm={this.handleRemove}
        size='mini'
        centered={false} /> */}
    </>);
  }
}

