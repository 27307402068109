import React, { Component } from 'react'
// import { Container, Icon, Image, Grid } from 'semantic-ui-react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Image, Loader, Icon, Form, Container } from 'semantic-ui-react'
import { IconButton } from '@material-ui/core';
import { withCookies } from 'react-cookie';
import Menu from '@material-ui/icons/Menu';
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from 'reactstrap';
import intl from 'react-intl-universal';

import Parse from 'widget/parse'
// import localization from 'widget/MaterialTableOpt'
// import { readFileAsURL } from './RowImage';
import Editor from '../../widget/Editor';
import PreloadFile, { previewUrl2 } from './PreloadFile';
// import AnnounceMaterialTable from './AnnounceMaterialTable';
import EditWebMaterialTable from '../webadmin/EditWebMaterialTable';
import ModalUploadFiles from './ModalUploadFiles';
// import { WebComponentOpt } from '../webadmin/WebComponentOpt';
// import ts from 'typescript';


const selOpt = {
  id: 'ModalAnnounce', value: 'Feature 2',
  showTable: true,
  columns: { showFile1: true, showTitle1: true, showCover: true }
};

const colInput = (id, label, value, readOnly, handleChange) => <Col key={`textaa${id}`} md={4}>
  <FormGroup >
    <Label for={`${id}`}>{label}</Label>
    <Input type="text" id={`${id}`} value={value} readOnly={readOnly} onChange={handleChange} />
  </FormGroup>
</Col>;
const contentCss = {
  maxWidth: '1170px',
  margin: '40px auto 110px',
  flexDirection: 'column',
}
class ModalAnnounce extends Component {
  state = {
    loading: false,
    show: false,
    files: [],
    form: {},
    data1: []
  }

  componentDidMount = () => {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { rowData, AnnounceCenterObj, photos } = props;
    let data1 = []
    if (rowData.objectId) {
      // data1 = photos.filter(item => item.pageId === rowData.objectId)
      data1 = await Parse.queryData('webimage', { companyId: AnnounceCenterObj.companyObj.objectId, pageId: rowData.objectId });
    }
    this.setState({ form: rowData, data1 })
  }

  toggle = () => this.setState({ show: !this.state.show, })

  handleOpen = () => {
    this.setState({ show: true, });
  }

  handleClose = () => {
    this.setState({
      show: false,
      // form: this.state.doc, file: {},
      isPreview: false,
      isSaving: false,
      editor: 0
    });
  }


  // handleUploadFile = async () => {
  //   const { rowData, AnnounceCenterObj } = this.props
  //   const { files } = this.state
  //   this.setState({ loading: true })
  //   const promises = [];
  //   for (let i = 0; i < files.length; i++) {
  //     const db = Parse.Object.extend("webimage");
  //     const newDoc = new db();
  //     promises.push(newDoc.save());
  //   }
  //   const docArr = await Promise.all(promises).catch(error => console.log("error", error));

  //   const promises2 = [];
  //   for (let i = 0; i < docArr.length; i++) {
  //     const file = files[i];
  //     const doc = docArr[i];
  //     const element = {
  //       objectId: doc.id,
  //       id: doc.id,
  //       _id: doc.id,
  //       companyId: AnnounceCenterObj.companyObj.objectId,
  //       companyName: AnnounceCenterObj.companyObj.name,
  //       pageId: rowData.objectId,
  //       name: file.name || '',
  //       type: 'announce'
  //     };
  //     promises2.push(
  //       Parse.saveData('webimage', element).then(async () => {
  //         await Parse.saveFile(file, '1', doc.id, 'webimage', 'file1');
  //       })
  //     );
  //   }
  //   await Promise.all(promises2).catch(error => console.log("error", error));

  //   this.setState({ loading: false })
  //   // handleData()
  //   this.handleClose()
  // }

  handleSubmit = async () => {
    const { onRowDataChange } = this.props
    const { form } = this.state
    onRowDataChange(form)
    this.setState({ show: false })
  }

  handleChangeForm = (e) => {
    const { id, value } = e.target
    const { form } = this.state
    this.setState({ form: { ...form, [id]: value } })
  }



  handleEditorChange = (id, value) => {
    this.setState({ form: { ...this.state.form, [id]: value } })
  }
  render() {
    const { cookies, rowData, showContent, AnnounceCenterObj, button } = this.props;
    const { loading, show, form, files, data1 } = this.state;
    const { title, to, content, } = form
    // const selObj = WebComponentOpt.find(item => item.id === 'ModalAnnounce') || {}
    const dbLang = cookies.get('dbLang') || 'name';
    // console.log(data1)
    return (<>
      {/* <Button color='primary' onClick={this.handleOpen}><i className="fa fa-plus" /> 新增{isImage === '1' ? '照片' : '檔案'}</Button> */}
      {button === '1' ? <Button style={{ marginLeft: '20px' }} color="purple" onClick={this.toggle} >{intl.get('read more')}</Button>
        : <IconButton onClick={this.toggle}><Menu size='small' /></IconButton>}
      <Modal
        isOpen={show}
        toggle={this.handleClose}
        size="lg"
      // className="mh-90 h-90 mw-100 w-90"
      // style={{ paddingTop: '0px' }}
      // style={{ maxWidth: '1600px', width: '80%' }}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
            <span aria-hidden="true">×</span>
          </button>
              公告 {rowData.name}
        </ModalHeader>
        <ModalBody>
          <Row>
            {colInput('title', '標題', title, showContent, this.handleChangeForm)}
            {colInput('to', '對象', to, showContent, this.handleChangeForm)}
          </Row>
          {!showContent ?
            <Editor field='content' content={content} handleEditorChange={this.handleEditorChange} /> :
            <Container style={contentCss}>
              <p>內文：</p>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </Container>}
          <br />
          <br />
          {!showContent ?
            <>
              {/* <PreloadFile multiple={true} files={files} handleChangeFile={this.handleChangeFile} /> */}
              <ModalUploadFiles {...this.props} isAnnounce='1' isImage='0' rowData={rowData} handleData={this.handleFetch} />
              <br />
              <br />
              <EditWebMaterialTable
                onRowDataChange={this.props.onRowDataChange}
                AnnounceCenterObj={AnnounceCenterObj}
                form={{
                  ...form,
                  showContent,
                  componentId: 'Attachment',
                  // navId: AnnounceCenterObj.objectId,
                  pageId: rowData.objectId,
                  type: 'announce'
                }}
                showColumns={selOpt.columns}
                data1={data1}
              /></> :
            <>
              {data1.map(item => <div> {previewUrl2(item.file1, item['title1' + dbLang] || item.name)}</div>)}
            </>
          }
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          {!showContent ? <Button color='success' onClick={this.handleSubmit} >{!loading ? <><i className="fa fa-tag" /> 確認</> : <Loader active inverted inline size='small' />}</Button> : null}{' '}
          <Button icon='x' onClick={this.handleClose}>關閉</Button>
        </ModalFooter>
      </Modal>
    </>);
  }
}

// export default ModalAnnounce;
export default withCookies(ModalAnnounce);
