import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select, Form } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import Moment from 'moment';
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';


// import RowsDnd from './RowsDnd'

// reactstrap components
import { Row, Col, FormGroup, Input, Label, Button } from "reactstrap";
// import { dateCol } from "views/web/CustomMaterialTable";
// import CurStudentInfo from "./CurStudentInfo";

const columns = () => ([
  // { width: 80, title: '日期', field: 'date', type: 'date' },
  { width: 80, title: '代號', field: 'nickname' },
  { width: 80, title: '金額', field: 'money' },
  { width: 80, title: '日期', field: 'date' },
  { width: 80, title: '利息', field: 'interest', editable: 'never' },
  { width: 80, title: '電話', field: 'phone' },
  { width: 80, title: '銀行', field: 'bank' },
  { width: 80, title: '姓名', field: 'name' },
  { width: 80, title: '帳號', field: 'accountNum' },
  { width: 80, title: '備註', field: 'ps' },

]);

class TotalAccount extends Component {
  state = {
    activeItem: 'bio',
    dataArr: [],
    rowData: {},
    totalMoney: '',
    totalInterest: ''
    // form: []

  }

  async componentDidMount() {
    this.handleFetch();
  }

  handleFetch = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    // const { totalMoney } = this.state
    const data = await Parse.queryData('goldMaster', { companyId });
    console.log(data);
    let totalMoney = 0
    let totalInterest = 0
    data.forEach(item => {
      totalMoney += Number(item.money)
      totalInterest += Number(item.interest)
    })
    this.setState({ dataArr: data, totalMoney: String(totalMoney), totalInterest: String(totalInterest) })
  }
  handleAdd = async () => {
    const { newData, totalMoney, totalInterest } = this.state;
    Parse.saveData('goldMaster', newData).then(() => this.handleFetch());
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    Parse.saveData('goldMaster', newData).then(() => this.handleFetch());
  }

  handleDelete = async () => {
    const { oldData } = this.state
    Parse.deleteData('goldMaster', oldData).then(() => this.handleFetch());
    this.setState({ oldData: {} })
  }
  // handleChange=(name,value)=>{
  //   this.setState({form: { ...this.state.form, [name]:value },[name]:value})
  // }
  // handleChange = (e, { name, value }) => {
  //   this.setState({ form: { ...this.state.form, [name]: value } })
  // }
  // handleSave = async () => {
  //   const { companyObj } = this.props
  //   const companyId = companyObj.objectId
  //   const { form } = this.state;

  //   // console.log(form);
  //   Parse.saveData('goldMaster', form);
  // }

  render() {
    const { companyObj } = this.props
    const { dataArr, rowData, loading, totalMoney, totalInterest } = this.state
    return (<div className="content">
      <Row>
        <Col><legend>3-2 金主</legend></Col>
      </Row>
      <br />
      <Row>
        <Col md='1'>
          <label>總金額</label>
          <Form.Input
            name='totalMoney'
            value={totalMoney}
          />

        </Col>
        <Col md='1'>
          <label>總利息</label>
          <Form.Input
            name='totalInterest'
            value={totalInterest}
          />
        </Col>
      </Row>
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '金主資料'),
          pageSize: 10,
          search: false,
          tableLayout: 'fixed'
        }}
        data={dataArr}
        title='金主資料'
        // actions={[
        //   {
        //     icon: 'view_headline',
        //     tooltip: '查看',
        //     onClick: (event, rowData) => this.handleData(rowData)
        //   }
        // ]}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const data = [...dataArr];
              const db = Parse.Object.extend("goldMaster");
              const newDoc = new db();
              const doc = await newDoc.save();

              const obj = {
                _id: doc.id,
                id: doc.id,
                objectId: doc.id,
                companyId: companyObj.objectId,
                interest: String(newData.money * 0.1),
                ...newData
              }
              data.push(obj);
              this.setState({ dataArr: data, newData: obj }, () => resolve());
            }).then(() => this.handleAdd()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...dataArr];
              const index = data.indexOf(oldData);
              const obj = {
                interest: String(newData.money * 0.1),
                ...newData
              }
              data[index] = obj;
              // handleState('people0', data);
              this.setState({ dataArr: data, newData: obj, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...dataArr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              // handleState('people0', data);
              this.setState({ dataArr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
      <br />
      <br />
    </div >);
  }
}

export default TotalAccount;