import Parse from 'parse';
import Resizer from 'react-image-file-resizer';

import { str2pinyin } from './multilang'

export const funcPad = (num, size) => {
  var s = "00000000" + num;
  return s.substr(s.length - size);
}

Parse.serverURL = 'https://parse.infowin.com.tw/parse'
Parse.liveQueryServerURL = 'wss://parse.infowin.com.tw/wss';
Parse.initialize("parseServer", "infowin");

// Parse.LiveQuery.on('open', () => {
//   console.log('socket connection established');
// });

// Parse.LiveQuery.on('close', () => {
//   console.log('socket connection closed');
// });

// Parse.LiveQuery.on('error', (error) => {
//   console.log(error);
// });

// Parse.generateObjectId = () => {
//   return Math.random().toString(36).slice(-8);
// }

Parse.sendmail = async (params) => {
  await Parse.Cloud.run("sendmail", params);
  return "1"
}

Parse.changeUserPassword = async (username, oldPassword, newPassword) => {
  await Parse.Cloud.run("changeUserPassword", { username, oldPassword, newPassword })
    .catch((error) => {
      console.error(error.code + " " + error.message);
    });
  return "1"
}

Parse.resetpw = async (email) => {
  // Parse.sendmail
  // Math.random().toString(36).slice(-8);

  return new Promise((resolve, reject) => {
    Parse.User.requestPasswordReset(email).then(res => {
      // console.log(res)
      resolve("1")
    }).catch((error) => {
      console.error(error.code + " " + error.message)
      // alert("Error: " + error.code + " " + error.message);
      resolve("Error: " + error.code + " " + error.message)
    });
  })
}

const imageFileResizer = async (file, maxX, maxY) => {
  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(file, maxX, maxY, 'JPEG', 75, 0,
      async (uri) => {
        // console.log(uri)
        resolve(uri)
      }, 'base64');
  })
}

Parse.saveFile = async (file, id, docId, dbName, fileFieldName) => {
  const parseFile = new Parse.File(id + str2pinyin(file.name), file, file.type);
  const theFile = await parseFile.save(
    // {
    //   progress: (progressValue, loaded, total, { type }) => {
    //     if (type === "upload" && progressValue !== null) {
    //       // Update the UI using progressValue
    //       this.setState({ loaded, total })
    //     }
    //   }
    // }
  ).catch(err => console.error(err));

  // console.log(file, id, str2pinyin(file.name), docId, dbName, fileFieldName)
  // console.log(theFile)
  const classDb = Parse.Object.extend(dbName);
  var newDoc = new classDb();
  newDoc.id = docId;
  newDoc.set(fileFieldName, theFile);
  const doc = await newDoc.save();
  return {
    doc,
    theFile,
  }
}

Parse.putFile = async (file, id = '') => {
  const parseFile = new Parse.File(id + str2pinyin(file.name), file, file.type);
  const theFile = await parseFile.save(
    // {
    //   progress: (progressValue, loaded, total, { type }) => {
    //     if (type === "upload" && progressValue !== null) {
    //       // Update the UI using progressValue
    //       this.setState({ loaded, total })
    //     }
    //   }
    // }
  ).catch(err => console.error(err));
  return theFile;
}

Parse.saveImg = async (file, id = '', maxX = 2048, maxY = 1152) => {
  const base64 = await imageFileResizer(file, maxX, maxY);
  const parseFile = new Parse.File(id + str2pinyin(file.name), { base64 }, "image/jpg");
  const theFile = await parseFile.save(
    // {
    //   progress: (progressValue, loaded, total, { type }) => {
    //     if (type === "upload" && progressValue !== null) {
    //       // Update the UI using progressValue
    //       this.setState({ loaded, total })
    //     }
    //   }
    // }
  ).catch(err => console.error(err));
  return theFile;
}

Parse.saveImgThumb = async (file, dbName, docId, imgName, thumbName) => {
  // console.log(dbName, docId, imgName, thumbName)
  console.log(file)
  const theFile1 = await Parse.saveImg(file, "1", 2048, 1152);
  const theFile2 = await Parse.saveImg(file, "2", 640, 360);
  // console.log(theFile1, theFile2)
  const classDb = Parse.Object.extend(dbName);
  var newDoc = new classDb();
  newDoc.id = docId;
  newDoc.set(imgName, theFile1);
  newDoc.set(thumbName, theFile2);
  const doc = await newDoc.save();
  return {
    doc,
    theFile1,
    theFile2
  }
}

Parse.fetchDocument = async (dbName, id, selectArr = []) => {
  // const classDb = Parse.Object.extend(dbName);
  // const query = new Parse.Query(classDb);
  const query = new Parse.Query(dbName);
  // query.include('name')
  // query.select('name')
  query.equalTo('objectId', id);
  if (selectArr.length) {
    query.select(selectArr);
  }
  query.limit(1);

  const snap = await query.first();
  return (snap && snap.toJSON()) || {}
}

Parse.findDoc = async (dbName, equalToObj = {}, selectArr = []) => {
  const classDb = Parse.Object.extend(dbName);
  const query = new Parse.Query(classDb);

  // const { selectArr = [], orderBy = '' } = opt;

  if (Object.keys(equalToObj).length) {
    Object.keys(equalToObj).forEach(key => {
      query.equalTo(key, equalToObj[key]);
    })
  }
  // const total = await query.count() || 1000;

  // query.ascending('name');
  // query.ascending('order');

  // if (params.sorter) {
  //   const s = params.sorter.split('_');

  //   if (s[1] === 'descend') {
  //     query.descending(s[0]);
  //   } else {
  //     query.ascending(s[0]);
  //   }

  //   // Sorts the results in descending order by the score field
  // }
  // query.limit(pageSize);
  // query.limit(1);
  // query.skip(((current) - 1) * (pageSize));

  if (selectArr.length) {
    // console.log(selectArr)
    query.select(selectArr);
    // query.select('name');
  }

  const snap = await query.first();
  return (snap && snap.toJSON()) || {}
}

// Parse.batchSave = async (dbName, arrObj) => {
//   const classDb = Parse.Object.extend(dbName);
//   // const query = new Parse.Query(classDb);

//   console.log(arrObj);
//   Parse.Object.saveAll(arrObj, {
//     success: function (list) {
//       // All the objects were saved.
//       console.log(list);
//     },
//     error: function (error) {
//       // An error occurred while saving one of the objects.
//       console.error(error)
//     },
//   })
// }

Parse.saveall = async (dbName, equalToObj, obj) => {
  const classDb = Parse.Object.extend(dbName);
  const query = new Parse.Query(classDb);

  if (Object.keys(equalToObj).length) {
    Object.keys(equalToObj).forEach(key => {
      query.equalTo(key, equalToObj[key]);
    })
  }
  const results = await query.find();
  const saveArr = results.map(item => {
    Object.keys(obj).forEach(key => {
      let value = obj[key] || '';
      item.set(key, value);
    });
    return item
  })

  console.log(saveArr);
  Parse.Object.saveAll(saveArr, {
    success: function (list) {
      console.log(list);
    },
    error: function (error) {
      console.error(error)
    },
  })
}

Parse.queryData = async (dbName, equalToObj = {}, opt = {}) => {
  // console.log('Parse.queryData', dbName, equalToObj)
  // const classDb = Parse.Object.extend(dbName);
  const query = new Parse.Query(dbName);

  const { selectArr = [], orderBy = '', limit = 10000 } = opt;
  if (selectArr.length) {
    // console.log(selectArr)
    // query.select('name');
    query.select(selectArr);
    // query.select('objectId', 'name_cht');
  }

  if (Object.keys(equalToObj).length) {
    Object.keys(equalToObj).forEach(key => {
      query.equalTo(key, equalToObj[key]);
    })
  }
  // const total = await query.count() || 1000;

  if (!orderBy) {
    query.ascending('name');
    query.ascending('order');
  } else {
    const s = orderBy.split('_');
    if (s[1] === 'descend') {
      query.descending(s[0]);
    } else {
      query.ascending(s[0]);
    }
  }

  // query.limit(pageSize);
  // query.limit(limit || total);
  query.limit(limit);
  // query.skip(((current) - 1) * (pageSize));
  await query.find().catch(error => {
    console.log(error)
    // window.localStorage.clear();
    // window.location.href = '/auth/login';

  });
  const snap = await query.find();
  const data1 = snap.map(data => data.toJSON());
  return data1
}

Parse.getSelection = async (dbName, equalToObj = {}, { name = 'value', sort = 'order' }) => {
  const arr = await Parse.queryData(dbName, equalToObj);

  const opt = []
  arr.sort((a, b) => a[sort] - b[sort]).forEach(item => opt.push({ key: item.objectId, value: item.objectId, text: item[name] }))

  let sel = {}
  arr.sort((a, b) => a[sort] - b[sort]).forEach(item => sel = { ...sel, [item.objectId]: item[name] })

  return { arr, opt, sel }
}

Parse.mySchema = (obj) => {
  console.log(obj)
  return obj
}

Parse.saveData = async (dbName, obj) => {
  // Parse.mySchema(obj)
  console.log('saveData', dbName, obj);

  delete obj.createdAt;
  delete obj.updatedAt;
  delete obj.ACL;
  delete obj.sessionToken;

  const classDb = Parse.Object.extend(dbName);
  const saveObj = new classDb();
  saveObj.id = obj.objectId || obj.id;
  /*
    const classSchema = new Parse.Schema(dbName);
    const schema = await classSchema.get();
    const fields = schema.fields
    Object.keys(obj).forEach(key => {
      let value = obj[key] || '';
      if (fields[key] && fields[key].type) {
        if (fields[key].type === 'String') {
          value = value + ''
        } else if (fields[key].type === 'Number') {
          value = Number(value);
        } else if (fields[key].type === 'Date') {
          // if (value && value.iso) {
          //   value = new Date(value.iso);
          // } else {
          // console.log(value)
          // value = new Date(value);
          // console.log(value)
          // }
        } else if (fields[key].type === 'Boolean') {
          value = !!value
        }
      }
      if (typeof value !== 'undefined') {
        // console.log(key, value, fields[key].type)
        saveObj.set(key, value);
      }
    });
    */
  Object.keys(obj).forEach(key => {
    let value = obj[key];
    if (key === 'order') {
      value = Number(value)
    }
    // if (value === true || value === false) {
    //   console.log(key, value)
    //   value = !!value
    // }
    saveObj.set(key, value);
  });

  await saveObj.save().catch(err => {
    // window.alert('錯誤 資料存入失敗' + err); 
    console.error(err);
  })
}

// 刪除多個，要帶條件進來
Parse.destroyData = async (dbName, equalToObj) => {
  const query = new Parse.Query(dbName);
  if (Object.keys(equalToObj).length) {
    Object.keys(equalToObj).forEach(key => {
      query.equalTo(key, equalToObj[key]);
    })
  }
  query.each((data) => data.destroy())
    .then((res) => {
      console.log(res)
      // Done
    }, (error) => {
      console.error(error)
      // Error
    });
}

Parse.deleteFile = async () => {
  if (false) {
    var AWS = require('aws-sdk');

    AWS.config.update({
      accessKeyId: 'xxxxx',
      secretAccessKey: 'xxxxx'
    });
    AWS.config.region = 'xxxxxx';

    function deleteS3File(fn) {
      var bucketInstance = new AWS.S3();
      var params = {
        Bucket: 'elasticbeanstalk-xxxxxxxx',
        Key: 'images/' + fn // my image files are under /images folder
      };
      bucketInstance.deleteObject(params, function (err, data) {
        if (err) {
          console.log("deleteS3File - Check if you have sufficient permissions : " + err);
        }
        // else {
        // console.log("deleteS3File - File deleted successfully = images/" + fn + ", with error: " + err);
        // }
      });
    }
  }
  // @@// ////clean up orphaned file on S3
  // Parse.Cloud.afterDelete("myClass", function(request) {
  // deleteS3File( request.object.get("pfFilecolumn").name() );
  // });
}

Parse.deleteData = async (dbName, obj) => {
  var classDb = Parse.Object.extend(dbName);
  var delObj = new classDb();
  delObj.id = obj.objectId || obj.id;
  await delObj.destroy().catch(err => console.error(err))
}

Parse.signupUser = async (username, password, email = '', companyId = '') => {
  if (!username || !password) {
    return 'error';
  }

  var user = new Parse.User();
  user.set("username", username);
  user.set("password", password);
  user.set("email", email);

  if (companyId) {
    user.set("authAllA", [companyId]);
    user.set("authLoginA", [companyId]);
    user.set("authUserA", [companyId]);
  }
  const roleType = 'Staff'
  const userACL = new Parse.ACL();
  userACL.setRoleWriteAccess(roleType, true);
  userACL.setPublicReadAccess(true);
  user.setACL(userACL);
  // other fields can be set just like with Parse.Object
  // user.set("phone", "415-392-0202");
  try {
    await user.signUp();
    await Parse.Cloud.run("addUserToRoleNamed", { userId: user.id, roleType })
    window.alert("註冊成功");
    return '1';
    // Hooray! Let them use the app now.
  } catch (error) {
    // Show the error message somewhere and let the user try again.
    window.alert("Error: " + error.code + " " + error.message);
  }
}

export const signinUser = async (email, password) => {
  // console.log('signinUser', email, password)
  if (!email || !password) {
    return 'error';
  }

  try {
    const user = await Parse.User.logIn(email, password);
    // console.log(user)
    // window.alert("登入成功");
    return '1'

    // Hooray! Let them use the app now.
  } catch (error) {
    // Show the error message somewhere and let the user try again.
    window.alert("Error: " + error.code + " " + error.message);
    return '0'
  }
}
export const loginUser = async (email, password, companyObj) => {
  return Parse.User.logIn(email, password);
}

export const getUser = () => {
  const currentUser = Parse.User.current();
  // console.log('currentUser', currentUser)
  // if (currentUser) {
  // do stuff with the user
  // } else {
  // show the signup or login page
  // window.location.href = '/auth/login';
  // }
  return currentUser
}
Parse.getProfile = async (objectId) => {
  // console.log('Parse.getProfile')
  const query = new Parse.Query(Parse.User);
  query.equalTo("objectId", objectId);  // find all the women
  // const women = await query.find();
  const snap = await query.find();
  return snap[0]
  // const data1 = snap.map(data => data.toJSON()) || [];
  // return data1[0]
}

Parse.logoutUser = async () => {
  await Parse.User.logOut().catch(error => console.error(error));
  // .then(() => {
  // var currentUser = Parse.User.current();  // this will now be null
  return '1'
  // });
}

export default Parse
