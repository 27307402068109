import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select, Form, TextArea } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import {
  Button,
  ButtonGroup,
  Label,
  FormGroup,
  Row,
  Col,
  UncontrolledTooltip,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { mgyearOpt, monthOpt, exportPdf } from 'views/web/CustomMaterialTable';

const columns = (employeeObj) => ([
  // { width: 40, title: '代號', field: 'code', lookup: code },
  { width: 80, title: '客戶姓名', field: 'clientName' },
  { width: 80, title: '當物類別', field: 'pawnTypeText' },
  { width: 80, title: '(總)本金結餘', field: 'principalRest' },
  { width: 80, title: '(總)應收利息', field: 'interestPlan' },
  { width: 80, title: '(總)繳息金額', field: 'interestPaid' },
  { width: 10, title: '', field: 'aaa', },
  { width: 80, title: '計劃繳息日期', field: 'planDate', editable: 'never' },
  { width: 80, title: '合件', field: 'joint' },
  { width: 80, title: '比例', field: 'proportion', editable: 'never' },
  { width: 80, title: '業績', field: 'performance' },
  { width: 80, title: '應收利息', field: 'interestPlanSelf' },
  { width: 80, title: '實收利息', field: 'interestPaidSelf' },
  { width: 80, title: '佣金', field: 'commission' },
  { width: 180, title: '備註', field: 'ps' }
]);

const numberSel = [
  { text: '1', value: '1', key: '1' },
  { text: '2', value: '2', key: '2' },
  { text: '3', value: '3', key: '3' },
  { text: '4', value: '4', key: '4' },
  { text: '5', value: '5', key: '5' },
  { text: '6', value: '6', key: '6' },
  { text: '7', value: '7', key: '7' },
  { text: '8', value: '8', key: '8' },
  { text: '9', value: '9', key: '9' },
  { text: '10', value: '10', key: '10' },
  { text: '11', value: '11', key: '11' },
  { text: '12', value: '12', key: '12' },
]

const seatsSel = [
  { text: '一樓', value: '1', key: '1' },
  { text: '二樓', value: '2', key: '2' },
  { text: '包廂', value: '3', key: '3' },
]

const seatsOpt = { '1': '一樓', '2': '二樓', '3': '包廂' }
const d = new Date()
class ReserveCalendar extends Component {
  state = {
    activeItem: 'bio',
    data: [],
    open: false,
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
    form: {},
    eventArr: [],
    typeOpt: []
  }

  componentDidMount = () => {
    this.handleFetch()
  }

  handleFetch = async () => {
    const { companyObj } = this.props
    const { monthSel, mgyearSel } = this.state
    const companyId = companyObj.objectId
    // console.log(monthSel);
    // console.log(mgyearSel);
    const typeOpt = []
    const data = await Parse.queryData('reserveData', { companyId });
    const data2 = await Parse.queryData('product1', { companyId });
    data2.forEach(item => {
      typeOpt.push({ text: item.courseName, value: item.courseName, key: item.objectId })
    })
    const eventArr = []
    data.map(item => {
      eventArr.push({
        title: `${item.type}\n${item.reserveTime}\n${item.reserveName}\n${item.reserveNum}`,
        start: `${item.reserveDate}`
      })
    })
    const data1 = await Parse.queryData('pawning2', { companyId, month: monthSel, year: mgyearSel });

    // console.log(eventArr);
    this.setState({ data: data1, eventArr, typeOpt })
  }

  handleChange1 = (e, { name, value }) => {
    const { form } = this.state
    this.setState({ form: { ...form, [name]: value } })
  }

  handledateClick = (info) => {
    const { form } = this.state
    if ((Date.parse(d)).valueOf() > (Date.parse(new Date(info.dateStr))).valueOf()) {
      alert("此日無法預約");
    } else {
      this.setState({ form: { ...form, reserveDate: info.dateStr }, open: true })
    }
  }

  close = () => { this.setState({ open: false }) }

  handleReserve = async () => {
    const { companyObj } = this.props
    const { form } = this.state
    const obj = {
      ...form,
      companyId: companyObj.objectId,
      reserveTableText: seatsOpt[form.reserveTable]
    }
    // console.log(obj);
    await Parse.saveData('reserveData', obj);
    this.setState({ open: false, form: {} })
  }

  render() {
    const { companyObj } = this.props
    const { form, monthSel, mgyearSel, open, eventArr, typeOpt } = this.state
    const { reserveDate, reservePeople = '-1', reserveTable = '-1', reservePs, reserveNum, reserveName, type } = form
    return (<div className="content">
      <Header as='h2' inverted={companyObj.inverted}>預約</Header>
      <Row>
        <Col md={{ size: 8 }}>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            dateClick={this.handledateClick}
            events={eventArr}
            // locales: allLocales
            locale='zh-tw'
          />
        </Col>
      </Row>
      <Modal
        isOpen={open}
        size='md'
        toggle={this.close}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggle}>
            <span aria-hidden="true">×</span>
          </button>
        預約
      </ModalHeader>
        <ModalBody><>
          <h3 style={{ textAlign: 'center' }}>{reserveDate}</h3>
          服務項目 <Form.Select fluid name='type' value={type} options={typeOpt} onChange={this.handleChange1} />
          <br />
          預約時間 <Form.Input fluid type="time" name='reserveTime' onChange={this.handleChange1} />
          <br />
          姓名 <Form.Input fluid type="text" name='reserveName' value={reserveName} onChange={this.handleChange1} />
          <br />
          電話 <Form.Input fluid type="text" name='reserveNum' value={reserveNum} onChange={this.handleChange1} />
          <br />
          備註<Form.TextArea name='reservePs' value={reservePs} placeholder='備註' onChange={this.handleChange1} />
        </>
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button color='danger' onClick={this.close} >關閉</Button>
          <Button color='success' onClick={this.handleReserve}>預約</Button>
        </ModalFooter>
      </Modal>

    </div >);
  }
}

export default ReserveCalendar;