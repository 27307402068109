import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select, Icon } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'

import { Row, Col } from "reactstrap";
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { mgyearOpt, exportPdf, rowReOrder } from 'views/web/CustomMaterialTable';
const columns = () => ([
  { width: 80, title: '順序', field: 'orderId' },
  { width: 80, title: '廠商名稱', field: 'name' },
  { width: 80, title: '是否為出版/代理商', field: 'isPublisher' },
  { width: 80, title: '是否為進貨商', field: 'isSeller' },
  { width: 80, title: '出貨方式', field: 'sendmethod' },
  { width: 80, title: '折數 (100%)', field: 'discount' },
  { width: 80, title: '連絡人', field: 'contactname' },
  { width: 80, title: '連絡方式', field: 'contactmail' },
  { width: 80, title: '出貨限制', field: 'condition' },
  { width: 80, title: '備註', field: 'ps' },
]);
const columns2 = () => ([
  { width: 80, title: '名稱', field: 'name' },
  { width: 80, title: '展示照片', field: 'singalCheck' },
  { width: 80, title: '說明內文', field: 'singalCheck' },
  { width: 80, title: '內文預覽', field: 'singalCheck' },
  { width: 80, title: '說明', field: 'singalCheck' },
  { width: 80, title: '出版/代理商', field: 'games1Id' },
  { width: 80, title: '進貨商', field: 'games1Id2' },
  { width: 80, title: '進貨商供貨', field: 'isStock' },
  { width: 80, title: '老闆或店長會', field: 'isBossKnow' },
  { width: 80, title: '熱門', field: 'isHot' },
  { width: 80, title: '店內教學', field: 'isTeach' },
  { width: 80, title: '上架數', field: 'number0' },
  { width: 80, title: '全新庫存數', field: 'number1' },
  { width: 80, title: '開封庫存數', field: 'number2' },
  { width: 80, title: '條碼', field: 'barcode' },
  { width: 80, title: '零售價', field: 'price' },
  { width: 80, title: '會員價', field: 'price1' },
  { width: 80, title: '蝦皮上架', field: 'isShopee' },
  { width: 80, title: '本店蝦皮價', field: 'price2' },
  { width: 80, title: '網路最低價', field: 'lowestPrice' },
  { width: 80, title: '網路最低賣家', field: 'lowestSeller' },
  { width: 80, title: '網路最低價連結', field: 'lowestLink' },
  { width: 80, title: '出租押金', field: 'gameDeposit' },
  { width: 80, title: '租金2日', field: 'gameRent' },
  { width: 80, title: '最低年齡', field: 'minYearsOlds' },
  { width: 80, title: '最少人數', field: 'playerMin' },
  { width: 80, title: '最多人數', field: 'playerMax' },
  { width: 80, title: '卡牌尺寸', field: 'size' },
  { width: 80, title: '備註', field: 'ps' },
]);

const d = new Date()
class Managegames extends Component {
  state = {
    activeItem: 'bio',
    data1: [],
    data2: [],
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
    rowData: {}
  }
  componentDidMount = () => {
    this.handleFetch()
  }
  handleFetch = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data1 = await Parse.queryData('publishers', { companyId });

    this.setState({ data1 })
  }
  handleAdd = async (type) => {
    const { newData, newData1 } = this.state;
    if (type === '1') {
      await Parse.saveData('publishers', newData);
    } else {
      await Parse.saveData('boardgames', newData1);
    }
  }

  handleUpdate = async (type) => {
    const { newData, newData1 } = this.state;
    if (type === '1') {
      await Parse.saveData('publishers', newData);
    } else {
      await Parse.saveData('boardgames', newData1);
    }
  }

  handleDelete = async (type) => {
    const { oldData, oldData1 } = this.state
    if (type === '1') {
      await Parse.deleteData('publishers', oldData);
    } else {
      await Parse.deleteData('boardgames', oldData1);
    }
  }
  handleData = async (rowData) => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data2 = await Parse.queryData('boardgames', { companyId, publisherId: rowData.objectId });

    this.setState({ rowData, data2 })
  }
  handleState = (dataArr, arrName = 'dataArr') => {
    this.setState({ [arrName]: dataArr })
  }
  render() {
    const { companyObj } = this.props
    const { data1, data2, monthSel, mgyearSel, rowData } = this.state

    return (<div className="content">
      <Header as='h2' inverted={companyObj.inverted}>桌遊管理</Header>
      <Row>
        <Col md='6'>
          <MaterialTable
            localization={localization()}
            columns={columns()}
            options={{
              addRowPosition: "first",
              exportButton: true,
              exportPdf: (columns, data) => exportPdf(columns, data, '出版商(代理商)'),
              pageSize: 10,
              search: false,
              tableLayout: 'fixed'
            }}
            data={data1}
            title="出版商(代理商)"
            actions={[
              {
                icon: 'view_headline',
                tooltip: '查看',
                onClick: (event, rowData) => this.handleData(rowData)
              }
            ]}
            components={{
              Row: props => rowReOrder(props, 'publishers', 'orderId', data1, 'data1', this.handleState)
            }}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  const data = [...data1];
                  const obj = {
                    ...newData,
                    companyId: companyObj.objectId
                  }
                  data.push(obj);
                  // handleState('people0', data);
                  this.setState({ data1: data, newData: obj }, () => resolve());
                }).then(() => this.handleAdd('1')),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const data = [...data1];
                  const index = data.indexOf(oldData);
                  data[index] = newData;
                  // handleState('people0', data);
                  this.setState({ data1: data, newData, oldData }, () => resolve());
                }).then(() => this.handleUpdate('1')),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  let data = [...data1];
                  const index = data.indexOf(oldData);
                  data.splice(index, 1);
                  // handleState('people0', data);
                  this.setState({ data1: data, oldData }, () => resolve());
                }).then(() => this.handleDelete('1')),
            }}
          />
        </Col>
        <Col md='6'>
          {rowData && rowData.objectId ?
            <MaterialTable
              localization={localization()}
              columns={columns2()}
              options={{
                addRowPosition: "first",
                exportButton: true,
                exportPdf: (columns, data) => exportPdf(columns, data, '桌遊'),
                pageSize: 10,
                search: false,
                tableLayout: 'fixed'
              }}
              data={data2}
              title="桌遊"
              // actions={[
              //   {
              //     icon: 'view_headline',
              //     tooltip: '查看',
              //     onClick: (event, rowData) => this.handleData(rowData)
              //   }
              // ]}
              components={{
                Row: props => rowReOrder(props, 'boardgames', 'orderId', data2, 'data2', this.handleState)
              }}
              editable={{
                onRowAdd: newData1 =>
                  new Promise((resolve, reject) => {
                    const data = [...data2];
                    const obj = {
                      ...newData1,
                      companyId: companyObj.objectId,
                      publisherId: rowData.objectId,
                      publisherName: rowData.name
                    }
                    data.push(obj);
                    // handleState('people0', data);
                    this.setState({ data2: data, newData1: obj }, () => resolve());
                  }).then(() => this.handleAdd('2')),
                onRowUpdate: (newData1, oldData1) =>
                  new Promise((resolve, reject) => {
                    const data = [...data2];
                    const index = data.indexOf(oldData1);
                    data[index] = newData1;
                    // handleState('people0', data);
                    this.setState({ data2: data, newData1, oldData1 }, () => resolve());
                  }).then(() => this.handleUpdate('2')),
                onRowDelete: oldData1 =>
                  new Promise((resolve, reject) => {
                    let data = [...data2];
                    const index = data.indexOf(oldData1);
                    data.splice(index, 1);
                    // handleState('people0', data);
                    this.setState({ data2: data, oldData1 }, () => resolve());
                  }).then(() => this.handleDelete('2')),
              }}
            /> : null}
        </Col>
      </Row>
    </div >);
  }
}

export default Managegames;