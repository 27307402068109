import React, { Component } from 'react'
import { Container, Input, Dimmer, Loader, Segment, Image, Button } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import { Select, MenuItem } from '@material-ui/core';
import Moment from 'moment';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf, typeCurrency } from 'views/web/CustomMaterialTable';
// import hanbury from 'widget/pdfCompany/coreHanbury';

const lendTypeObj = { '0': '借', '1': '貸' };

const columns = (bankaccOpt = {}, acc1Opt = {}, acc2Arr = [], acc3Arr = []) => ([
  dateCol({ title: '日期', field: 'eventDate' }),
  { title: '帳戶', field: 'bankaccId', lookup: bankaccOpt }, // 資料庫下拉式
  { title: '會計科目1', field: 'acc1Id', lookup: acc1Opt }, // 資料庫下拉式
  {
    title: '會計科目2', field: 'acc2Id',
    render: rowData => rowData.acc2Text,
    editComponent: props => {
      return <Select
        value={props.rowData.acc2Id}
        onChange={e => {
          const acc2Id = e.target.value;
          const acc2Obj = acc2Arr.find(item => item.objectId === acc2Id);
          const acc2Text = (acc2Obj && acc2Obj.value) || '';
          props.onRowDataChange({ ...props.rowData, acc2Id, acc2Text })
        }}
      >
        {acc2Arr.filter(item => item.a1_id === props.rowData.acc1Id).length ?
          acc2Arr.filter(item => item.a1_id === props.rowData.acc1Id).map((item, index) =>
            (<MenuItem key={'a' + item.objectId + index} value={item.objectId}>{item.value}</MenuItem>)) :
          <MenuItem key={'b'} value="">(無資料)</MenuItem>}
      </Select>
    }
  }, // 資料庫下拉式
  {
    title: '科目細項', field: 'acc3Id',
    render: rowData => rowData.acc3Text,
    editComponent: props => {
      return <Select
        value={props.rowData.acc3Id}
        onChange={e => {
          const acc3Id = e.target.value || '';
          const acc3Obj = acc3Arr.find(item => item.objectId === acc3Id);
          const acc3Text = (acc3Obj && acc3Obj.value) || '';

          props.onRowDataChange({ ...props.rowData, acc3Id, acc3Text })
        }}
      >
        {acc3Arr.filter(item => item.a2_id === props.rowData.acc2Id && item.a1_id === props.rowData.acc1Id).length ?
          acc3Arr.filter(item => item.a2_id === props.rowData.acc2Id && item.a1_id === props.rowData.acc1Id).map((item, index) =>
            (<MenuItem key={'a' + item.objectId + index} value={item.objectId}>{item.value}</MenuItem>)) :
          <MenuItem key={'b'} value="">(無資料)</MenuItem>}
      </Select>
    }
  }, // 資料庫下拉式
  { title: '發票/收據號碼', field: 'invoiceNumber' },
  { title: '說明', field: 'title' },
  { title: '金額', field: 'price', ...typeCurrency },
  { title: '備註', field: 'ps' },
]);

export default class Fin1131 extends Component {
  state = {
    bankaccArr: [],
    dataArr: [],
    deleteConfirm: false,
    loading: true,
    bankaccSel: '',
    yearmonth: Moment(new Date()).format('YYYY-MM') || '',
  }

  async componentDidMount() {
    const { companyObj } = this.props;
    const companyId = companyObj.objectId;

    const bankacc = await Parse.queryData('bankacc', { companyId });


    let bankaccOpt = {}
    bankacc.forEach(item => {
      bankaccOpt = { ...bankaccOpt, [item.objectId]: item.value };
    })

    const bankaccArr = bankacc.filter(item => item.value).map(item => ({
      key: item.objectId, text: `${item.value}`, value: item.objectId
    }));
    const bankaccSel = bankacc && bankacc.length ? bankacc[0].objectId : '';
    this.setState({ bankaccArr, bankaccSel, bankaccOpt }, () => this.handleFetch());
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props, flag = 1) => {
    const { companyObj } = props;
    const { yearmonth } = this.state
    const [year, month] = yearmonth.split('-');

    const companyId = companyObj.objectId;

    if (flag === 1) {
      Parse.queryData('cashbook', { companyId, yearmonth: yearmonth, year: year, month: month, balance: '0' }, { orderBy: 'eventDate' }).then(async (cashbook) => {
        // const dataArr = cashbook.filter(item => item.acc1Id !== 'vtvfMREPEJ' && item.acc1Id !== 'XZb3Gy9eLg' && item.acc1Id !== 'b4zzTZiWmQ').map(item => ({ ...item, eventDateAt: item.eventDateAt }))
        const account1 = await Parse.queryData('account1', { companyId, balance: '0' });

        let acc1Opt = {}
        account1.forEach(item => {
          acc1Opt = { ...acc1Opt, [item.objectId]: item.value };
        });
        const account2 = await Parse.queryData('account2', { companyId });
        const account3 = await Parse.queryData('account3', { companyId });
        this.setState({ acc1Opt, acc2Arr: account2, account2, acc3Arr: account3, account3, loading: false, dataArr: cashbook, cashbook });
      })
    } else {
      const cashbook = await Parse.queryData('cashbook', { companyId, yearmonth: yearmonth, year: year, month: month, balance: '0' }, { orderBy: 'eventDate' })
      this.setState({ dataArr: cashbook, cashbook, loading: false })
    }
    // this.setState({ acc1Opt,acc2Arr:account2, account2 ,acc3Arr:account3, account3});

    // const acc2Arr = account2;
    // this.setState({ acc2Arr, account2 });

    // const acc3Arr = account3;
    // this.setState({ acc3Arr, account3 });
  }

  handleAdd = async () => {
    const { newData, yearmonth } = this.state
    const obj = {
      ...newData,
      yearmonth,
    };
    await Parse.saveData('cashbook', obj).then(() => this.handleFetch(this.props, 0));
  }

  handleUpdate = async () => {
    const { newData } = this.state
    const obj = {
      ...newData,
      // eventDate: (newData.eventDate && new Date(newData.eventDate.iso || newData.eventDate)) || new Date()
      eventDate: newData.eventDate ? (new Date(newData.eventDate.iso || newData.eventDate)) || new Date() : null,
    };
    await Parse.saveData('cashbook', obj);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    await Parse.deleteData('cashbook', oldData);
    this.setState({ oldData: {} })
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    this.setState({ [name]: value, loading: true }, () => this.handleFetch(this.props, 0));
  }

  render() {
    const { companyObj, auth } = this.props
    const { dataArr = [], loading, yearmonth, bankaccOpt, acc1Opt, acc2Arr, acc3Arr } = this.state;
    const [year, month] = yearmonth.split('-');

    const editable = {
      isEditHidden: () => !auth.edit,
      isDeleteHidden: () => !auth.edit,
      onRowAdd: newData =>
        new Promise(async (resolve, reject) => {
          const data = [...dataArr];
          const db = Parse.Object.extend("cashbook");
          const newDoc = new db();
          const doc = await newDoc.save();
          const obj = {
            objectId: doc.id,
            id: doc.id,
            _id: doc.id,
            companyId: companyObj.objectId,
            companyName: companyObj.name,
            year,
            month,
            acc1Text: acc1Opt[newData.acc1Id] || "",
            lendTypeText: lendTypeObj[newData.lendTypeId] || "",
            bankaccText: bankaccOpt[newData.bankaccId] || "",
            balance: '0',
            ...newData
          }
          data.push(obj);
          this.setState({ dataArr: data, newData: obj, loading: true }, () => resolve());
        }).then(() => this.handleAdd()),
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          const data = [...dataArr];
          const index = data.indexOf(oldData);
          const obj = {
            ...newData,
            year,
            month,
            acc1Text: acc1Opt[newData.acc1Id] || "",
            lendTypeText: lendTypeObj[newData.lendTypeId] || "",
            bankaccText: bankaccOpt[newData.bankaccId] || "",
          };

          data[index] = obj;
          this.setState({ dataArr: data, newData: obj, oldData }, () => resolve());
        }).then(() => this.handleUpdate()),
      onRowDelete: oldData =>
        new Promise((resolve, reject) => {
          let data = [...dataArr];
          const index = data.indexOf(oldData);
          data.splice(index, 1);
          this.setState({ dataArr: data, oldData }, () => resolve());
        }).then(() => this.handleDelete()),
    }

    if (auth.edit === false) {
      delete editable.onRowAdd
    }



    return (<>
      {/* 零用金及銀行帳戶明細表 */}
      <br />
      <Input
        type="month"
        name="yearmonth"
        value={yearmonth}
        onChange={this.handleChange}
      />
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns(bankaccOpt, acc1Opt, acc2Arr, acc3Arr)}
        components={{
          Toolbar: props => (<div>
            <div style={{ float: 'right', textAlign: 'right', padding: '22px 16px 22px 4px' }}>
            </div>
            <MTableToolbar {...props} />
            <div style={{ clear: 'both' }}></div>
          </div>),
        }}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => {
            const dataArr = data.map(item => ({ ...item, bankaccId: bankaccOpt[item.bankaccId] || '', acc1Id: acc1Opt[item.acc1Id] || '', acc2Id: acc2Arr.find(item2 => item.acc2Id === item2.objectId).value || '', acc3Id: acc3Arr.find(item2 => item.acc3Id === item2.objectId).value || '' }))
            return exportPdf(columns, dataArr, `${year}年${month}月 日記帳`)
          },
          pageSize: dataArr.length < 10 ? 10 : dataArr.length,
          search: false
        }}
        data={dataArr}
        title={`${year}年${month}月 日記帳`}
        editable={editable}
      />
    </>)
  }
}