import React, { Component, useState } from 'react'
import {
  Button,
} from 'reactstrap';
import JoditEditor from "jodit-react";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'widget/ckeditor';

const Editor = (props) => {
  const [editor, setEditor] = useState(0);
  const { field, content, handleEditorChange } = props;

  return (<>
    <Button color="primary" onClick={() => setEditor(!editor)}>切換編輯器 </Button> 目前： {editor ? 'JoditEditor (看原始碼用這個)' : 'CKEditor (上傳、貼上圖片用這個)'}
    <br />
    {/* <br /> */}
    {editor ?
      <JoditEditor
        // config={{ height: 400, }}
        style={{ height: 400 }}
        name={field}
        value={content}
        tabIndex={2}
        onChange={data => handleEditorChange(field, data)}
      /> :
      <CKEditor
        editor={ClassicEditor}
        data={content}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleEditorChange(field, data)
        }}
      />}
  </>)
}

export default Editor;