import React, { Component } from 'react'
import { Form, Button, Table, Grid, Header, Icon, Confirm, Select, Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
import _ from 'lodash'
// import MyModalTable from '../../widgets/MyModalTable';
import { Link } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Parse from '../../../widget/parse'

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  tableShow: {
    display: 'table',
  },
  tableHide: {
    display: 'none',
  }
}

export default class Guidance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      show: false,
      guidance: [],
      confirm: false,
      loading: false,
      showModal: false,
    }
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = (props = this.props) => {
    const { companyObj, match } = props;
    // const { filesData } = this.state
    // const { _id } = match.params;

    // firebase.firestore().collection(`Dormitory`).get().then(snap => {
    //   var promises = [];
    //   snap.forEach(item => {
    //     var promise = firebase.firestore().collection(`Dormitory/${_id}/Room/${item.id}/files`)
    //       .get().then(snapshot => {
    //         // console.log(snapshot.size);
    //         const filesData = []
    //         snapshot.forEach(fileItem => filesData.push({ _id: fileItem.id, ...fileItem.data(), parttimeId: item.id }));
    //         return { _id: item.id, ...item.data(), filesData };
    //       });
    //     promises.push(promise);
    //   });
    //   Promise.all(promises).then(results => {
    //     // console.log('all promises were done')
    //     // console.log(results);
    //     this.setState({ guidance: results })
    //   }).catch(error => {
    //     console.log(error);
    //   });

    // }, err => {
    //   console.log(`Encountered error: ${err}`);
    // });

    Parse.queryData('Dormitory', { companyId: companyObj.objectId }).then(snap => {
      var promises = [];
      snap.forEach(doc => {
        var promise = Parse.queryData('Room', { companyId: companyObj.objectId, dormId: doc.id })
          .then(snap2 => {
            const roomData = [];
            snap2.forEach(room => roomData.push(room))
            return { objectId: doc.objectId, ...doc, roomData };
          })
        promises.push(promise);
      });
      Promise.all(promises).then(results => {
        // console.log('all promises were done')
        // console.log(results);
        this.setState({ guidance: results, show: true, loading: false })
      }).catch(error => {
        console.log(error);
      });
    });

    // firebase.firestore().collection("Dormitory").get().then(snap => {
    //   var promises = [];
    //   snap.forEach(doc => {
    //     var promise = firebase.firestore().collection(`Dormitory/${doc.id}/Room`)
    //       .get().then(snap2 => {
    //         const roomData = [];
    //         snap2.forEach(room => roomData.push({ _id: room.id, ...room.data() }))
    //         return { _id: doc.id, ...doc.data(), roomData };
    //       })
    //     promises.push(promise);
    //   });
    //   Promise.all(promises).then(results => {
    //     // console.log('all promises were done')
    //     // console.log(results);
    //     this.setState({ guidance: results, show: true, loading: false })
    //   }).catch(error => {
    //     console.log(error);
    //   });
    // });
  }

  handleSubmit = async () => {
    const { firebase, doc, semesterYear, semester } = this.props;
    const { form, guidance } = this.state;
    const db = firebase.firestore();

    this.setState({ loading: true });
    const newGuidance = [...guidance];
    if (!form._id) {
      const docRef = db.collection(`Dormitory`).doc();
      const obj = {
        ...form,
        _id: docRef.id,
        createdAt: new Date()
      };
      await docRef.set(obj);
      newGuidance.push(obj);
    } else {
      await db.collection(`Dormitory`).doc(form._id).update(form);
      let index = newGuidance.findIndex((item => item._id === form._id));
      newGuidance[index] = form;
    }

    this.setState({ guidance: newGuidance, loading: false });
    this.handleClose();
  }

  handleChange = (event, data) => {
    const { name, value } = data
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    })
  }

  handleOpen = () => {
    this.setState({ showModal: true })
  }

  handleEdit = (guidanceObj) => {
    this.setState({ showModal: true, form: { ...guidanceObj } });
  }

  handleClose = () => {
    this.setState({ showModal: false, form: {}, readOnly: false, fileObj: {} });
  }

  handleRemove = async () => {
    const { firebase, doc } = this.props
    const { form, guidance } = this.state;
    await firebase.firestore().collection(`Dormitory`).doc(form._id).delete();
    const newGuidance = [...guidance];
    let index = newGuidance.findIndex((item => item._id === form._id));
    newGuidance.splice(index, 1);
    this.setState({ guidance: newGuidance });

    this.handleClose();
    this.hideConfirm();
  }

  showConfirm = (guidanceObj) => {
    this.setState({ confirm: true, form: { ...guidanceObj } });
  }
  hideConfirm = () => this.setState({ confirm: false, form: {} });

  handleBedNum = (room, type) => {
    let bedNum = room && room.length ? Number(room.reduce((acc, cur) =>
      ({ roomType: Number(acc.roomType) + Number(cur.roomType) })).roomType) : 0
    let bedNum1 = 0 // 入住床數
    let bedNum0 = 0 // 空床數

    room.forEach(item => {
      if (item.studentNum1 && item.studentNum1.length) {
        bedNum1++
      }
      if (item.studentNum2 && item.studentNum2.length) {
        bedNum1++
      }
      if (item.studentNum3 && item.studentNum3.length) {
        bedNum1++
      }
      if (item.studentNum4 && item.studentNum4.length) {
        bedNum1++
      }
    })
    bedNum0 = bedNum - bedNum1
    if (type === '1') {
      return bedNum1
    } else {
      return bedNum0
    }
  }

  fields = [
    {
      id: 'dormitoryName',
      title: '代号'
    },
    {
      id: 'roomNum',
      // width: 100,
      title: '間数'
    },
    {
      id: 'bedNum',
      // width: 100,
      title: '床数'
    },
    {
      id: 'bedNum1',
      // width: 100,
      title: '入住床数'
    },
    {
      id: 'bedNum0',
      // width: 100,
      title: '空床数'
    },
    {
      id: 'dormitoryPostcode',
      // width: 120,
      title: '郵便番号'
    },
    {
      id: 'dormitoryAddr',
      title: '住所'
    }
  ]
  render() {
    const { isEdit, doc, companyObj } = this.props
    const { guidance, form, disabled, readOnly, loading, show, confirm, showModal } = this.state
    const dormitoryFromOpt = [{ key: 0, text: '學校', value: '1' }, { key: 1, text: '賃貸', value: '2' }]
    return (
      <>
        <div style={{ ...style.flexCenter, marginBottom: 15 }}>
          <Header as='h2' style={{ margin: 0 }}
            content='寮一覧'
          // subheader='調査時間：2017年4月から 2018年12月 まで'
          />
          <Button inverted={companyObj.inverted} color='blue' onClick={this.handleOpen} icon='add' content='追加' />
        </div>
        <Divider />
        <Table celled structured style={!show ? style.tableHide : style.tableShow}>
          <Table.Header>
            <Table.Row>
              {/* {isEdit ? <Table.HeaderCell>#</Table.HeaderCell> : null} */}
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>代号</Table.HeaderCell>
              <Table.HeaderCell>間数</Table.HeaderCell>
              <Table.HeaderCell>床数</Table.HeaderCell>
              <Table.HeaderCell>入住床数</Table.HeaderCell>
              <Table.HeaderCell>空床数</Table.HeaderCell>
              <Table.HeaderCell>郵便番号</Table.HeaderCell>
              <Table.HeaderCell>住所</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {guidance.length ? guidance.map((item, index) => (<React.Fragment key={index}>
              <Table.Row>
                {/* {isEdit ? <Table.Cell style={{ padding: 10 }}>
                  <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} icon='edit' /><br /> <br />
                  <Button inverted={companyObj.inverted} color='red' size='small' onClick={() => this.showConfirm(item)} icon='delete' />
                </Table.Cell> : null} */}
                <Table.Cell style={{ ...style.flexCenter, padding: 10, justifyContent: 'space-around' }}>
                  <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} icon='edit' />
                  <Button inverted={companyObj.inverted} color='red' size='small' onClick={() => this.showConfirm(item)} icon='delete' />
                </Table.Cell>
                <Table.Cell>{item.dormitoryName}</Table.Cell>
                {/* <Table.Cell>{item.roomNum}</Table.Cell>
                <Table.Cell>{item.bedNum}</Table.Cell>
                <Table.Cell>{item.bedNum1}</Table.Cell>
                <Table.Cell>{item.bedNum0}</Table.Cell> */}
                <Table.Cell>{item.roomData && item.roomData.length || '0'}</Table.Cell>
                {item.roomData && item.roomData.length ? <Table.Cell>
                  {item.roomData && item.roomData.reduce((acc, cur) =>
                    ({ roomType: Number(acc.roomType) + Number(cur.roomType) })
                  ).roomType}
                </Table.Cell> : <Table.Cell>0</Table.Cell>}
                <Table.Cell>{this.handleBedNum(item.roomData, '1')}</Table.Cell>
                <Table.Cell>{this.handleBedNum(item.roomData, '0')}</Table.Cell>
                {/* <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell> */}
                <Table.Cell>{item.dormitoryPostcode}</Table.Cell>
                <Table.Cell>{item.dormitoryAddr}</Table.Cell>
              </Table.Row>
            </React.Fragment>)) : <Table.Row><Table.Cell colSpan='8' textAlign='center'>(資料無し)</Table.Cell></Table.Row>}
          </Table.Body>
        </Table>

        {/* <Modal open={showModal} onClose={this.handleClose}> */}
        <Modal
          isOpen={showModal}
          toggle={this.handleClose}
          size="lg"
          // className="mh-100 h-90 mw-130 w-130"
          style={{ paddingTop: '0px' }}
          scrollable={true}
        >
          <ModalHeader className="justify-content-center">
            <h2 style={{ margin: 0 }}> {form._id ? '修正' : '追加'}記録</h2>
            <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
          </ModalHeader>
          <ModalBody style={{ padding: "20px 30px" }}>
            <Form>
              <Grid>
                <Grid.Row columns='equal'>
                  <Grid.Column>
                    <Form.Input
                      label='代号'
                      name='dormitoryName'
                      value={form.dormitoryName || ''}
                      onChange={this.handleChange}
                      readOnly={readOnly}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      label='郵便番号'
                      name='dormitoryPostcode'
                      value={form.dormitoryPostcode || ''}
                      onChange={this.handleChange}
                      readOnly={readOnly}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      label='住所'
                      name='dormitoryAddr'
                      value={form.dormitoryAddr || ''}
                      onChange={this.handleChange}
                      readOnly={readOnly}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Select
                      label='寮的來源'
                      options={dormitoryFromOpt}
                      name='dormitoryFrom'
                      value={form.dormitoryFrom || ''}
                      onChange={this.handleChange}
                      readOnly={readOnly}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </ModalBody>
          <ModalFooter style={{ padding: '16px' }}>
            <Button onClick={this.handleSubmit} inverted={companyObj.inverted} color='green' loading={loading} icon='checkmark' content={form._id ? '保存' : '追加'} />
            <Button onClick={this.handleClose} inverted={companyObj.inverted} color='red' icon='delete' content='キャンセル' />
          </ModalFooter>
        </Modal>
        <Confirm
          header='削除'
          content='削除してもいいですか?'
          cancelButton='いいえ'
          confirmButton='はい'
          open={this.state.confirm}
          onCancel={this.hideConfirm}
          onConfirm={this.handleRemove}
          size='mini'
          centered={false} />
        <div style={{ display: !show ? 'flex' : 'none', justifyContent: 'center', margin: '30px 0' }}>
          <Segment>
            <Dimmer active inverted={companyObj.inverted}>
              <Loader inverted={companyObj.inverted}>Loading</Loader>
            </Dimmer>
            <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
          </Segment>
        </div>
      </>
    )
  }
}
