import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";


// core components

function SectionBlog(props = {}) {
  const { cookies, form, webimageArr } = props;
  const { text2 = 'text2' } = form;
  const dbLang = cookies.get('dbLang') || 'name';


  let arr = []
  arr = webimageArr.filter(item => item.navId === form.objectId)
  if (!arr.length) {
    arr = [{ icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' }]
  }
  return (
    <>
      <div className="section secion-blog cd-section" id="blogs">
        {/* ********* BLOGS 2 ********* */}
        <div className="blog-2 section section-gray">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <h2 className="title">{text2}</h2>
                <br />
                <Row>
                  {arr.map((item) =>
                    <Col md="4">
                      <Card className="card-blog">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img
                              alt="..."
                              className="img img-raised"
                              src={require("assets/img/sections/miguel-perales.jpg")}
                            />
                          </a>
                        </div>
                        <CardBody>
                          {/* <h6 className="card-category text-info">{text3}</h6> */}
                          <CardTitle tag="h5">
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              {item['title1' + dbLang] || ''}
                            </a>
                          </CardTitle>
                          <p className="card-description">

                            {item['content1' + dbLang] || ''}
                          </p>
                          <hr />
                          {/* <CardFooter>
                          <div className="author">
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              <img
                                alt="..."
                                className="avatar img-raised mr-2"
                                src={require("assets/img/faces/ayo-ogunseinde-2.jpg")}
                              />
                              <span>Mike John</span>
                            </a>
                          </div>
                          <div className="stats">
                            <i className="fa fa-clock-o" /> 5 min read
                          </div>
                        </CardFooter> */}
                        </CardBody>
                      </Card>
                    </Col>)}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END BLOGS 2 ********* */}
      </div>
    </>
  );
}

export default SectionBlog;
