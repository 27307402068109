import React, { Component, } from 'react';
import { Form, Button, Confirm, Card, Table, Header, Radio, Divider, Icon } from 'semantic-ui-react';
import { getOptionTextByValue, getOptionsByKey, showField, } from 'views/widgets/FieldsRender';
import Parse from '../../../../widget/parse'
import { Row, Col } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// import {
//   Card, CardImg, CardText, CardBody,
//   CardTitle, CardSubtitle, CardHeader, CardLink
// } from "reactstrap";

export default class GraduateStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        targetSchool: '0',
        targetSubject: '',
        isAdmitted: '-1',
        isEnter: '0',
        applyProcess: '-1',
      },
      graduation: [],
      show: false,
    };
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { firebase, match, companyObj } = props;
    const { _id } = match.params;

    const data = await Parse.queryData('Graduate', { companyId: companyObj.objectId, studentId: _id });
    this.setState({ graduation: data });

    // firebase.firestore()
    //   .collection(`Students/${_id}/Graduate`)
    //   // .orderBy('relationship')
    //   // .where('eventDate', '==', eventDate).where('classname', '==', className)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ graduation: data });
    //   }, err => { console.log(`Encountered error: ${err}`); });

  }

  handleChange = (event, data) => {
    const { optSet } = this.props
    const { name, value, checked } = data;
    this.setState({ form: { ...this.state.form, [name]: value } });
    if (name === 'isEnter') {
      let decision = checked === true ? '1' : '0'
      this.setState({ form: { ...this.state.form, [name]: decision } })
    } else if (name === 'targetSchool') {
      let targetSchoolText = getOptionTextByValue(optSet, "hopeSchoolName", value) || '';
      this.setState({ form: { ...this.state.form, 'targetSchoolText': targetSchoolText } })
    }
  };

  handleSubmit = async () => {
    const { companyObj, optSet, doc } = this.props;
    const { form, graduation } = this.state;
    // const db = firebase.firestore();

    this.setState({ loading: true });

    // form.targetSchoolText = form.targetSchool === '0' ? form.targetSchoolText : getOptionTextByValue(optSet, "hopeSchoolName", form.targetSchool);
    form.isAdmittedText = getOptionTextByValue(optSet, "isAdmitted", form.isAdmitted);
    form.applyProcessText = getOptionTextByValue(optSet, "applyProcess", form.applyProcess);
    // form.isEnterText = getOptionTextByValue(optSet, "decision", form.isEnter);
    form.firstKindText = getOptionTextByValue(optSet, "1stKind", form.firstKind);
    form.secondKindText = getOptionTextByValue(optSet, "2ndKind", form.secondKind);
    form.thirdKindText = getOptionTextByValue(optSet, "3rdKind", form.thirdKind);
    form.fourthKindText = getOptionTextByValue(optSet, "4thKind", form.fourthKind);

    const newRows = [...graduation];
    if (!form.objectId) {
      // const docRef = db.collection(`Students/${doc._id}/Graduate`).doc();
      const db = Parse.Object.extend('Graduate');
      const newDoc = new db();
      const docRef = await newDoc.save();
      const obj = {
        ...form,
        objectId: docRef.id,
        studentId: doc.objectId,
        studentIdKey: doc.studentId,
        studentName: doc.jpnName,
        classSchool: doc.classSchool || '',
        semesterYear: doc.semesterYear,
        semester: doc.semester,
        companyId: companyObj.objectId,
        companyName: companyObj.name,
        // createdAt: new Date()
      };
      // await docRef.set(obj);
      await Parse.saveData('Graduate', obj)
      newRows.push(obj);
    } else {
      // await db.collection(`Students/${doc._id}/Graduate`).doc(form._id).update(form);
      await Parse.saveData('Graduate', form)
      let index = newRows.findIndex((item => item.objectId === form.objectId));
      newRows[index] = form;
    }

    this.setState({ graduation: newRows, loading: false });
    this.handleClose();
  }

  handleOpen = () => {
    this.setState({ show: true })
  }

  handleEdit = (bicycleObj) => {
    this.setState({ show: true, form: { ...bicycleObj } });
  }

  handleClose = () => {
    this.setState({
      show: false,
      form: {
        targetSchool: '0',
        targetSubject: '',
        isAdmitted: '-1',
        isEnter: '-1',
        applyProcess: '-1',
        firstKind: '-1',
        secondKind: '-1',
        thirdKind: '-1',
        fourthKind: '-1',
      },
    });
  }

  handleRemove = async () => {
    const { firebase, doc } = this.props
    const { form, graduation } = this.state;
    // await firebase.firestore().collection("Students").doc(doc._id).collection("Graduate").doc(form._id).delete();
    await Parse.deleteData('Graduate', form)
    const newRows = [...graduation];
    let index = newRows.findIndex((item => item.objectId === form.objectId));
    newRows.splice(index, 1);
    this.setState({ graduation: newRows });

    this.handleClose();
    this.hideConfirm();
  }

  showConfirm = (bicycleObj) => this.setState({ confirm: true, form: { ...bicycleObj } });
  hideConfirm = () => this.setState({ confirm: false, form: {} });

  getDefaultValue = (key) => {
    const { optSet } = this.props;
    const options = getOptionsByKey(optSet, key);
    return options[0].value;
  };
  showField(field) {
    const { formSet, doc } = this.props;
    let obj = formSet.find(item => item.key === field);

    if (typeof obj === 'undefined') {
      return;
    }
    return this.props.renderField(obj, doc[field]);
  }

  render() {
    const { optSet, isEdit, doc, companyObj } = this.props;
    const { form, graduation, loading } = this.state;
    const hopeSchools = getOptionsByKey(optSet, 'hopeSchoolName');
    const isAdmittedOptions = getOptionsByKey(optSet, 'isAdmitted');
    const isDecideOptions = getOptionsByKey(optSet, 'decision');
    const applyOptions = getOptionsByKey(optSet, 'applyProcess');
    const plannedWayOut = getOptionsByKey(optSet, 'plannedWayOut');
    const firstKind = getOptionsByKey(optSet, '1stKind');
    const secondKind = getOptionsByKey(optSet, '2ndKind');
    const thirdKind = getOptionsByKey(optSet, '3rdKind');
    const fourthKind = getOptionsByKey(optSet, '4thKind');

    const plannedWayOutArr = [
      ...plannedWayOut,
      { key: '000', label: '無', value: '0' }
    ]
    const hopeSchoolsOpts = hopeSchools.sort((a, b) => a.orderId - b.orderId)
      .map(({ _id, value, label }) => ({ key: _id, value: value, text: label }))
    const hopeSchoolsOptsArr = [
      { key: 'hope000', text: '-- 選択 --', value: '0' },
      ...hopeSchoolsOpts
    ]
    const isAdmittedOptionsOpts = isAdmittedOptions.sort((a, b) => a.orderId - b.orderId)
      .map(({ _id, value, label }) => ({ key: _id, value: value, text: label }))
    const isAdmittedOptionsOptsArr = [
      { key: 'isAdmitted000', text: '-- 選択 --', value: '-1' },
      ...isAdmittedOptionsOpts
    ]
    const isDecideOptionsOpts = isDecideOptions.sort((a, b) => a.orderId - b.orderId)
      .map(({ _id, value, label }) => ({ key: _id, value: value, text: label }))
    const isDecideOptionsOptsArr = [
      { key: 'isDecide000', text: '-- 選択 --', value: '-1' },
      ...isDecideOptionsOpts
    ]
    const applyOptionsOpts = applyOptions.sort((a, b) => a.orderId - b.orderId)
      .map(({ _id, value, label }) => ({ key: _id, value: value, text: label }))
    const applyOptionsOptsArr = [
      { key: 'process000', text: '-- 選択 --', value: '-1' },
      ...applyOptionsOpts
    ]
    const firstKindOpts = firstKind.sort((a, b) => a.orderId - b.orderId)
      .map(({ _id, value, label }) => ({ key: _id, value: value, text: label }))
    const firstKindOptsArr = [
      { key: 'firstKind000', text: '-- 選択 --', value: '-1' },
      ...firstKindOpts
    ]
    const secondKindOpts = secondKind.sort((a, b) => a.orderId - b.orderId)
      .map(({ _id, value, label }) => ({ key: _id, value: value, text: label }))
    const secondKindOptsArr = [
      { key: 'secondKind000', text: '-- 選択 --', value: '-1' },
      ...secondKindOpts
    ]
    const thirdKindOpts = thirdKind.sort((a, b) => a.orderId - b.orderId)
      .map(({ _id, value, label }) => ({ key: _id, value: value, text: label }))
    const thirdKindOptsArr = [
      { key: 'thirdKind000', text: '-- 選択 --', value: '-1' },
      ...thirdKindOpts
    ]
    const fourthKindOpts = fourthKind.sort((a, b) => a.orderId - b.orderId)
      .map(({ _id, value, label }) => ({ key: _id, value: value, text: label }))
    const fourthKindOptsArr = [
      { key: 'fourthKind000', text: '-- 選択 --', value: '-1' },
      ...fourthKindOpts
    ]

    return (<>
      <Header as='h2' dividing
        content='進路調查'
        style={{ backgroundColor: 'lavender' }}
      // subheader='調査時間：2017年4月から 2018年12月 まで'
      />
      <Row>
        {/* <Grid columns='equal' divided> */}
        <Col>
          <Row>
            {/* <Grid columns='equal'> */}
            <Col>
              <Card fluid>
                <Card.Content header='進路希望調査' />
                {/* <CardHeader tag="h5" > 進路希望調査</CardHeader> */}
                {/* <CardBody> */}
                <Card.Content>
                  <Row>
                    {/* <Grid columns='equal'> */}
                    <Col>第一希望</Col>
                    <Col>第二希望</Col>
                    {/* </Grid> */}
                  </Row>
                  <Divider />
                  <Row>
                    {/* <Grid columns='equal'> */}
                    <Col>
                      {plannedWayOutArr.map(item =>
                        <Form.Field
                          key={'a' + item._id}
                          control={Radio}
                          readOnly={!isEdit}
                          label={item.label}
                          onChange={this.props.handleUpdateSetting}
                          value={item.value}
                          checked={doc.plannedWayOut1 === item.value}
                          name='plannedWayOut1' />)}
                    </Col>
                    <Col>
                      {plannedWayOutArr.map(item =>
                        <Form.Field
                          key={'b' + item._id}
                          control={Radio}
                          readOnly={!isEdit}
                          label={item.label}
                          onChange={this.props.handleUpdateSetting}
                          value={item.value}
                          checked={doc.plannedWayOut2 === item.value}
                          name='plannedWayOut2' />)}
                    </Col>
                    {/* </Grid> */}
                  </Row>
                </Card.Content>
                {/* </CardBody> */}
              </Card>
            </Col>
            {/* </Grid> */}
          </Row>
        </Col>
        <Col>
          <Row>
            {/* <Grid columns='equal'> */}
            <Col>
              <Card fluid>
                <Card.Content header='卒業(退学)年月日' />
                {/* <CardHeader tag="h5" > 卒業(退学)年月日</CardHeader> */}
                {/* <CardBody> */}
                <Card.Content>
                  <Row>
                    {/* <Grid columns='equal'> */}
                    <Col>{showField(this.props, 'gradNum')}</Col>
                    {/* </Grid> */}
                  </Row>
                  <Row>
                    {/* <Grid columns='equal'> */}
                    <Col>{showField(this.props, 'graduateDate')}</Col>
                    {/* </Grid> */}
                  </Row>
                  <Row>
                    {/* <Grid columns='equal'> */}
                    <Col>{showField(this.props, 'wayOutSituation')}</Col>
                    {/* </Grid> */}
                  </Row>
                  {/* </CardBody> */}
                </Card.Content>
              </Card>
            </Col>
            {/* </Grid> */}
          </Row>
        </Col>
        <Col>
          <Row>
            {/* <Grid columns='equal'> */}
            <Col>
              <Card fluid>
                <Card.Content header='就業予定' />
                {/* <CardHeader tag="h5" > 就業予定</CardHeader> */}
                {/* <CardBody> */}
                <Card.Content>
                  <Row>
                    {/* <Grid columns='equal'> */}
                    <Col>
                      {showField(this.props, 'nameOfCompany')}
                    </Col>
                    <Col>
                      {showField(this.props, 'typeOfBusiness')}
                    </Col>
                    <Col>
                      {showField(this.props, 'addressOfCompany')}
                    </Col>
                    {/* </Grid> */}
                  </Row>
                  <Row>
                    {/* <Grid columns='equal'> */}
                    <Col>
                      {showField(this.props, 'addrIndepBus')}
                    </Col>
                    <Col>
                      {showField(this.props, 'typeOfBusinessindep')}
                    </Col>
                    <Col>
                      {showField(this.props, 'financingPlanOrMethods')}
                    </Col>
                    {/* </Grid> */}
                  </Row>
                  <Row>
                    {/* <Grid columns='equal'> */}
                    <Col>
                      {showField(this.props, 'otherPlanAfterGraduation')}
                    </Col>
                    {/* </Grid> */}
                  </Row>
                </Card.Content>
                {/* </CardBody> */}
              </Card>
            </Col>
            {/* </Grid> */}
          </Row>
        </Col>
        {/* </Grid> */}
      </Row>
      <br /> <br />
      <Row>
        {/* <Grid columns='equal'> */}
        <Col>
          <Card fluid>
            <Card.Content header='進学希望校' />
            {/* <CardHeader tag="h5" > 進学希望校</CardHeader> */}
            {/* <CardBody> */}
            <Card.Content>
              <br />
              <Button disabled={!isEdit} floated='right' inverted={companyObj.inverted} color='blue' onClick={this.handleOpen} icon='add' content='追加' />
              <br />
              <br />
              <Table celled striped size='small' selectable>
                <Table.Header>
                  <Table.Row textAlign='center'>
                    {isEdit ? <Table.HeaderCell style={{ width: '100px' }}>#</Table.HeaderCell> : null}
                    <Table.HeaderCell>進路希望校名</Table.HeaderCell>
                    <Table.HeaderCell>学部(研究科)</Table.HeaderCell>
                    <Table.HeaderCell>学科</Table.HeaderCell>
                    <Table.HeaderCell>区分4</Table.HeaderCell>
                    <Table.HeaderCell>申請段階</Table.HeaderCell>
                    <Table.HeaderCell>合否</Table.HeaderCell>
                    <Table.HeaderCell>決定</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {graduation.map((item, index) => (<Table.Row key={`td${index}`}>
                    {isEdit ? <Table.Cell style={{ padding: 10 }}>
                      <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} icon='edit' />
                      <Button inverted={companyObj.inverted} color='red' size='small' onClick={() => this.showConfirm(item)} icon='delete' />
                    </Table.Cell> : null}
                    <Table.Cell >{item.firstKindText || ''} {item.targetSchoolText}</Table.Cell>
                    <Table.Cell >{item.secondKindText || ''} {item.targetDepartment || ''}</Table.Cell>
                    <Table.Cell >{item.thirdKindText || ''} {item.targetSubject || ''}</Table.Cell>
                    <Table.Cell >{item.fourthKindText || ''}</Table.Cell>
                    <Table.Cell >{item.applyProcessText || ''}</Table.Cell>
                    <Table.Cell >{item.isAdmittedText || ''}</Table.Cell>
                    <Table.Cell >{item.isEnter === '1' ? 'V' : ''}</Table.Cell>
                  </Table.Row>))}
                  {!graduation.length ? <Table.Row>
                    <Table.Cell colSpan={isEdit ? 4 : 3} textAlign='center' >(資料無し)</Table.Cell>
                  </Table.Row> : null}
                </Table.Body>
              </Table>

              {/* <Modal open={this.state.show} onClose={this.handleClose} size='small'> */}
              <Modal
                isOpen={this.state.show}
                toggle={this.handleClose}
                // size="sm"
                className="mh-80 h-80 mw-80 w-80"
                style={{ paddingTop: '0px' }}
                scrollable={true}
              >
                <ModalHeader>進学希望校 修正</ModalHeader>
                <ModalBody>
                  <Form>
                    <Row>
                      {/* <Grid columns='equal'> */}
                      <Form.Select
                        label='区分1'
                        options={firstKindOptsArr}
                        readOnly={isEdit ? false : true}
                        name="firstKind"
                        defaultValue={form.firstKind ? form.firstKind : '-1'}
                        onChange={this.handleChange}
                      />
                      <Form.Select
                        label='学校選択'
                        search
                        options={hopeSchoolsOptsArr}
                        readOnly={isEdit ? false : true}
                        name="targetSchool"
                        defaultValue={form.targetSchool ? form.targetSchool : '0'}
                        onChange={this.handleChange}
                      />
                      <Icon name='arrow right' size='large'></Icon>
                      <Form.Input
                        label='学校名'
                        readOnly={isEdit ? false : true}
                        name="targetSchoolText"
                        value={form.targetSchoolText || ''}
                        onChange={this.handleChange}
                      />
                      {/* <Col>{showField(this.props, "1stKind")}</Col>
                        <Col>{showField(this.props, "targetSchool")}</Col> */}
                      {/* </Grid> */}
                    </Row>
                    <br />
                    <Row>
                      {/* <Grid columns='equal'> */}
                      <Form.Select
                        label='区分2'
                        options={secondKindOptsArr}
                        readOnly={isEdit ? false : true}
                        name="secondKind"
                        defaultValue={form.secondKind ? form.secondKind : '-1'}
                        onChange={this.handleChange}
                      />
                      <Form.Input
                        label='学部(研究科)'
                        readOnly={isEdit ? false : true}
                        name="targetDepartment"
                        defaultValue={form.targetDepartment || ''}
                        onChange={this.handleChange}
                      />
                      {/* </Grid> */}
                    </Row>
                    <br />
                    <Row>
                      {/* <Grid columns='equal'> */}
                      <Form.Select
                        label='区分3'
                        options={thirdKindOptsArr}
                        readOnly={isEdit ? false : true}
                        name="thirdKind"
                        defaultValue={form.thirdKind ? form.thirdKind : '-1'}
                        onChange={this.handleChange}
                      />
                      <Form.Input
                        label='学科'
                        readOnly={isEdit ? false : true}
                        name="targetSubject"
                        defaultValue={form.targetSubject || ''}
                        onChange={this.handleChange}
                      />
                      {/* </Grid> */}
                    </Row>
                    <br />
                    <Row>
                      {/* <Grid columns='equal'> */}
                      <Form.Select
                        label='区分4'
                        options={fourthKindOptsArr}
                        readOnly={isEdit ? false : true}
                        name="fourthKind"
                        defaultValue={form.fourthKind ? form.fourthKind : '-1'}
                        onChange={this.handleChange}
                      />
                      {/* </Grid> */}
                    </Row>
                    <br />
                    <Divider />
                    <br />
                    <Row>
                      {/* <Grid columns='equal'> */}
                      <Form.Select
                        label='申請段階'
                        options={applyOptionsOptsArr}
                        readOnly={isEdit ? false : true}
                        name="applyProcess"
                        defaultValue={form.applyProcess ? form.applyProcess : '0'}
                        onChange={this.handleChange}
                      // style={{ minWidth: 196 }}
                      />
                      <Form.Select
                        label='合否'
                        options={isAdmittedOptionsOptsArr}
                        readOnly={isEdit ? false : true}
                        name="isAdmitted"
                        defaultValue={form.isAdmitted ? form.isAdmitted : '-1'}
                        onChange={this.handleChange}
                      // style={{ minWidth: 196 }}
                      />
                      <Col>
                        <Form.Checkbox
                          label='進学校'
                          readOnly={isEdit ? false : true}
                          name="isEnter"
                          checked={form.isEnter === '1' ? true : false}
                          onChange={this.handleChange}
                        />
                      </Col>
                      {/* </Grid> */}
                    </Row>
                  </Form>
                </ModalBody>
                <ModalBody>
                  <Button size='small' onClick={this.handleSubmit} inverted={companyObj.inverted} color='green' loading={loading} icon='checkmark' content={form.objectId ? '保存' : '追加'} />
                  <Button size='small' onClick={this.handleClose} basic>キャンセル</Button>
                </ModalBody>
              </Modal>
              <Modal
                isOpen={this.state.confirm}
                toggle={this.hideConfirm}
                size="lg"
                className="mh-100 h-90 mw-100 w-90"
              >
                <ModalHeader>削除</ModalHeader>
                <ModalBody>
                  削除してもいいですか？
        </ModalBody>
                <ModalFooter style={{ padding: '16px' }}>
                  <Button onClick={this.handleRemove} inverted color='red' icon='delete' content='はい' />
                  <Button onClick={this.hideConfirm} content='いいえ' />
                </ModalFooter>
              </Modal>
              {/* <Confirm
                header='削除'
                content='削除してもいいですか?'
                cancelButton='いいえ'
                confirmButton='はい'
                open={this.state.confirm}
                onCancel={this.hideConfirm}
                onConfirm={this.handleRemove}
                size='mini'
                centered={false} /> */}
              {/* </CardBody> */}
            </Card.Content>
          </Card>
        </Col>
        {/* </Grid> */}
      </Row>
    </>);
  }
}

