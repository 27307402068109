import React, { Component } from 'react'
import { Container, Button, Dimmer, Loader, Image, Segment, Header, Select, Table, Label, Icon } from 'semantic-ui-react';
import MaterialTable from 'material-table'

import localization from 'widget/MaterialTableOpt'
import Moment from 'moment';
import { dateCol, typeCurrency, monthOpt, mgyearOpt, exportPdf } from "views/web/CustomMaterialTable";
import Parse from 'widget/parse'
import C13a from './C13a';
import {
  Row,
  Col,
} from "reactstrap";
// const roomType = { '0': '單人房', '1': '雙人房' };

const columns = (roomArr = []) => ([
  dateCol({ width: 100, title: '繳費日期', field: 'payDate' }),
  dateCol({ width: 100, title: '開始日期', field: 'startDate' }),
  dateCol({ width: 100, title: '結束日期', field: 'endDate' }),
  { width: 80, title: '房號', field: 'roomNum' },
  {
    width: 80, title: '每月應繳金額', field: 'receiveNum',
    render: rowData => (roomArr.find(item => item.roomNum === rowData.roomNum) && roomArr.find(item => item.roomNum === rowData.roomNum).rent) || '',
  },
  { width: 80, title: '繳費金額', field: 'price' },
]);

const columns2 = () => ([
  dateCol({ width: 80, title: '支出日期', field: 'date' }),
  { width: 80, title: '支出項目', field: 'expenditureItem' },
  { width: 80, title: '金額', field: 'money' },
  { width: 80, title: '備註', field: 'ps' },
]);

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

const d = new Date()

export default class Client1 extends Component {
  state = {
    data1: [],
    data2: [],
    clientsArr: [],
    lineUsersArr: [],
    roomArr: [],
    rowData: {},
    roomObj: {},
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
    searchDate: Moment(d).format('YYYY-MM'),
    loading: true,
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const clientsArr = await Parse.queryData('clients', { companyId });
    const lineUsersArr = await Parse.queryData('lineUsers', { companyId });
    this.setState({ clientsArr, lineUsersArr })
  }

  render() {
    const { clientsArr, lineUsersArr } = this.state;
    return (<>
      <div className='content'>
        <Row>
          <Col md='5'>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>房號</Table.HeaderCell>
                  <Table.HeaderCell>姓名</Table.HeaderCell>
                  <Table.HeaderCell>連結</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {clientsArr.map((item, index) =>
                  <Table.Row>
                    <Table.Cell>{item.roomNum}</Table.Cell>
                    <Table.Cell>{item.name}</Table.Cell>
                    {lineUsersArr.find(item2 => item2.clientId === item.objectId) ?
                      <Table.Cell style={{ color: 'green' }}>已連結</Table.Cell> : <Table.Cell style={{ color: 'red' }}>未連結</Table.Cell>
                    }
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Col>
        </Row>
      </div>
    </>)
  }
}