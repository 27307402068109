import React, { Component } from 'react';
import { Icon, Card, Form, Grid, Button, Header, TextArea } from 'semantic-ui-react';

// import UploadPhoto64B from '../../../components/UploadPhoto/UploadPhoto64B';
import UploadPhoto from '../../../widgets/UploadPhoto';
import { showField, showFieldText, getAvailblePeriodArr, getOptionsByKey } from 'views/widgets/FieldsRender';
import { Row, Col } from 'reactstrap'
export default class List1Student extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps.doc.studentStatus);
  }

  render() {
    const { doc, optSet, isEdit, addNum } = this.props;
    // const classSchool = getOptionsByKey(optSet, "classSchool");
    // const options = classSchool.map(item => ({ key: item._id, value: item.value, text: item.label }))

    return (<>
      <Header as='h2' dividing
        content='本人基本情報'
        // subheader='調査時間：2017年4月から 2018年12月 まで'
        style={{ backgroundColor: 'lavender' }}
      />
      <br />
      {/* <Grid columns={3} divided> */}
      {/* <Col> */}
      <Row>
        {/* <Grid columns='equal'> */}
        <Col sm='4'>
          <Card fluid>
            <Card.Content header='本人基本情報' className='card-title' />
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "jpnName")}</Col>
                <Col>{showField(this.props, "katakanaName")}</Col>
                <Col>{showField(this.props, "engName")}</Col>
                <Col>{showField(this.props, "homeName")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "gender")}</Col>
                <Col>{showField(this.props, "nationality")}</Col>
                <Col>{showField(this.props, "birthday")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "bornPlace")}</Col>
                <Col>{showField(this.props, "bornPlaceOther")}</Col>
                <Col>{showField(this.props, "bornPlace2")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "lodgingCost")}</Col>
                <Col>{showField(this.props, "sceduleAfterscho")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "smoking")}</Col>
                <Col>{showField(this.props, "isMarried")}</Col>
                <Col>{showField(this.props, "shoeCase")}</Col>
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
        </Col>
        {/* </Grid> */}
        {/* </Col> */}
        <Col sm='4'>
          <Card fluid >
            <Card.Content header='学籍情報' className='card-title' />
            <Card.Content >
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "studentId")}</Col>
                <Col>{showField(this.props, "classSchool")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "courseStartDate")}</Col>
                <Col>{showField(this.props, "lessonStartDate")}</Col>
                <Col>{showField(this.props, "courseEndDate")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col >{showField(this.props, "studentStatus")}</Col>
                <Col >{showField(this.props, "studentStatusOther")}</Col>
                {/* </Grid> */}
              </Row>
              {doc.studentStatus === '31' || doc.studentStatus === '32' ?
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col>{showField(this.props, "dropoutReason")}</Col>
                  <Col>{showField(this.props, "dropoutPlan")}</Col>
                  {/* </Grid> */}
                </Row> : null}
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "semesterYear")}</Col>
                <Col>{showField(this.props, "semester")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col >{showField(this.props, "studentType")}</Col>
                <Col>{showField(this.props, "applyResult")}</Col>
                {/* </Grid> */}
                {/* <Grid columns='equal'>
                  <Col>{showField(this.props, "ps")}</Col>
                </Grid> */}
              </Row>
            </Card.Content>
          </Card>
        </Col>
        <Col sm='4'>
          <Row>
            {/* <Grid columns='equal'> */}
            <Col>
              <Card fluid>
                <Card.Content color='orange' header='本人連絡情報' className='card-title' />
                <Card.Content >
                  <Row>
                    {/* <Grid columns='equal'> */}
                    <Col>{showField(this.props, "qq")}</Col>
                    <Col>{showField(this.props, "line")}</Col>
                    <Col>{showField(this.props, "skype")}</Col>
                    {/* </Grid> */}
                  </Row>
                  <Row>
                    {/* <Grid columns='equal'> */}
                    <Col>{showField(this.props, "facebook")}</Col>
                    <Col>{showField(this.props, "email")}</Col>
                    {/* </Grid> */}
                  </Row>
                </Card.Content>
              </Card>
            </Col>
            {/* </Grid> */}
          </Row>
          <Row>
            {/* <Grid columns='equal'> */}
            <Col>
              <Card fluid >
                <Card.Content color='orange' header='本国連絡先情報' className='card-title' />
                <Card.Content >
                  <Row>
                    {/* <Grid columns='equal'> */}
                    <Col>{showField(this.props, "homeCellphone")}</Col>
                    <Col>{showField(this.props, "homeTel")}</Col>
                    {/* </Grid> */}
                  </Row>
                  <Row>
                    {/* <Grid columns='equal'> */}
                    <Col width={4}>{showField(this.props, "homePostcode")}</Col>
                    <Col width={12}>{showField(this.props, "homeAddr")}</Col>
                    {/* </Grid> */}
                  </Row>
                </Card.Content>
              </Card>
            </Col>
            {/* </Grid> */}
          </Row>
          <br />
          <Row>
            {/* <Grid columns='equal'> */}
            <Col>
              <Card fluid >
                <Card.Content header='日本連絡先情報' className='card-title' />
                <Card.Content >
                  <Row>
                    {/* <Grid columns='equal'> */}
                    {/* <Col>{showField(this.props, "jpnContactFrient")}</Col> */}
                    <Col>{showField(this.props, "jpnCellphone")}</Col>
                    <Col>{showField(this.props, "jpnTel")}</Col>
                    {/* </Grid> */}
                  </Row>
                  <Row>
                    {/* <Grid columns='equal'> */}
                    {/* <Col>{showField(this.props, "jpnPostcode")}</Col>
                        <Col>{showField(this.props, "jpnAddr")}</Col> */}

                    <Col width={isEdit ? 4 : 6}><Form.Field>
                      <label>郵便番号</label>
                      {isEdit ? <Form.Input type="text" value={doc.jpnPostcode ? doc.jpnPostcode : ''} name={'jpnPostcode'} onChange={this.props.handleUpdateSetting} />
                        : <Form.Input readOnly value={doc.jpnPostcode ? doc.jpnPostcode : ''} />}
                    </Form.Field></Col>
                    <Col width={10}><Form.Field>
                      <label>住所</label>
                      {isEdit ? <Form.TextArea value={doc.jpnAddr ? doc.jpnAddr : ''} name={'jpnAddr'} onChange={this.props.handleUpdateSetting} />
                        : <Form.TextArea readOnly value={doc.jpnAddr ? doc.jpnAddr : ''} />}
                    </Form.Field></Col>
                    {isEdit ? <Col width={2}><Button icon color='green' onClick={() => this.props.addAddr()}> <Icon name='plus circle' /></Button></Col> : null}
                    {/* </Grid> */}
                  </Row>
                  <br />
                  {addNum > 1 ?
                    <Row>
                      {/* <Grid columns='equal'> */}
                      <Col width={isEdit ? 4 : 6}><Form.Field>
                        <label>郵便番号1</label>
                        {isEdit ? <Form.Input type="text" value={doc.jpnPostcode1} name={'jpnPostcode1'} onChange={this.props.handleUpdateSetting} />
                          : <Form.Input readOnly value={doc.jpnPostcode1} />}
                      </Form.Field></Col>
                      <Col width={10}><Form.Field>
                        <label>住所1</label>
                        {isEdit ? <Form.TextArea value={doc.jpnAddr1} name={'jpnAddr1'} onChange={this.props.handleUpdateSetting} />
                          : <Form.TextArea readOnly value={doc.jpnAddr1} />}
                      </Form.Field></Col>
                      {isEdit ? <Col width={2}><Button icon color='red' onClick={() => this.props.deleteAddr(1)}><Icon name='minus circle' /></Button></Col> : null}
                      {/* </Grid> */}
                    </Row> : null}
                  {['2', '3', '4', '5'].map((item, index) => {
                    if (index >= addNum - 2)
                      return null
                    else
                      return (
                        <Row key={index}>
                          {/* <Grid columns='equal'> */}
                          <Col width={isEdit ? 4 : 6}><Form.Field>
                            <label>郵便番号{item}</label>
                            {isEdit ? <Form.Input type="text" value={doc[`jpnPostcode${item}`]} name={`jpnPostcode${item}`} onChange={this.props.handleUpdateSetting} />
                              : <Form.Input readOnly value={doc[`jpnPostcode${item}`]} />}
                          </Form.Field></Col>
                          <Col width={10}><Form.Field>
                            <label>住所{item}</label>
                            {isEdit ? <Form.TextArea value={doc[`jpnAddr${item}`]} name={`jpnAddr${item}`} onChange={this.props.handleUpdateSetting} />
                              : <Form.TextArea readOnly value={doc[`jpnAddr${item}`]} />}
                          </Form.Field></Col>
                          {isEdit ? <Col width={2}><Button icon color='red' onClick={() => this.props.deleteAddr(item)} ><Icon name='minus circle' /></Button></Col> : null}
                          {/* </Grid> */}
                        </Row>
                      )
                  }
                  )}
                </Card.Content>
              </Card>
            </Col>
            {/* </Grid> */}
          </Row>
        </Col>
      </Row>

      {/* </Grid> */}
    </>);
  }
}