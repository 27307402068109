import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

// import RowsDnd from './RowsDnd'

// reactstrap components
import { Row, Col, Form, FormGroup, Input, Label, Button } from "reactstrap";
// import CurStudentInfo from "./CurStudentInfo";

const columns = () => ([
  // { width: 50, title: '戶長', field: 'mainPerson', lookup: { '0': '否', '-1': '是' }, },
  { width: 60, title: '填寫日', field: 'writeDate' },
  { width: 80, title: '求戒類別', field: 'preceptsType', lookup: { '1': '比丘尼戒', '2': '比丘戒', '3': '增益比丘尼戒', '4': '增益比丘戒', '5': '沙彌尼戒', '6': '沙彌戒', '7': '五戒 菩薩戒', '8': '五戒', '9': '菩薩戒' } }, // preceptsTypeText
  { width: 60, title: '班號', field: 'classNum1' },
  { width: 60, title: '壇號', field: 'classNum2' },
  // { width: 60, title: '稱號', field: 'titleType', lookup: { '1': '長老', '2': '法師', '3': '單位', '4': '團體', '5': '個人', '6': '其他' } },
  { width: 60, title: '法名 (內號)', field: 'dharma' },
  { width: 80, title: '法名 英文', field: 'dharmaEng' },
  { width: 60, title: '字號 (外號)', field: 'name1' },
  { width: 80, title: '字號 英文', field: 'name1Eng' },
  { width: 80, title: '俗名 中文', field: 'name' },
  { width: 80, title: '俗名護照 英文', field: 'nameEng' },
  { width: 80, title: '姓別', field: 'sexualId', lookup: { '1': '男', '2': '女' } },
  // { width: 80, title: '年齡', field: 'age' },
  { width: 150, title: '身份證號', field: 'idcardNum' },
  { width: 150, title: '生日', field: 'birthday' },
  { width: 70, title: '出生地 中文', field: 'birthplace' },
  { width: 70, title: '出生地 英文', field: 'birthplaceEng' },
  { width: 80, title: '國籍 中文', field: 'nationality' },
  { width: 80, title: '國籍 英文', field: 'nationalityEng' },
  { width: 150, title: '電話', field: 'phone' },
  { width: 150, title: '手機', field: 'cellphone' },
  { width: 180, title: '通訊地址', field: 'addr' },
  { width: 180, title: 'email', field: 'email' },
  { width: 80, title: '剃度師長德號', field: 'teacherDharma' },
  { width: 80, title: '剃度師長 英文', field: 'teacherDharmaEng' },
  { width: 70, title: '師長受戒處', field: 'teacherOrdainedText', }, // teacherOrdainedText
  { width: 70, title: '師長受戒日期', field: 'teacherOrdainedDate' },
  { width: 80, title: '剃度皈依 常住 中文', field: 'shavedConversionText', }, // shavedConversionText
  { width: 80, title: '剃度皈依 常住 英文', field: 'shavedConversionEng' },
  { width: 80, title: '剃度皈依日期', field: 'shavedConversionDate' },
  { width: 80, title: '現住寺院 中文', field: 'templeNowText' }, // templeNowText
  { width: 80, title: '現住寺院 英文', field: 'templeNowEng' },
  { width: 80, title: '現任職務', field: 'positionNow' },
  { width: 80, title: '佛教會會員證號', field: 'buddhistMemberNum' },
  { width: 180, title: '現住寺院地址', field: 'templeNowAddr' },
  { width: 80, title: '五碼郵號', field: 'postcode' },
  { width: 180, title: '現住寺院地址英文街路名', field: 'templeNowRoad' },
  { width: 180, title: '現住寺院地址英文鄉鎮名', field: 'templeNowCity' },
  { width: 180, title: '現住寺院地址英文國名', field: 'templeNowCountry' },
  { width: 80, title: '寺院電話', field: 'templePhone' },
  { width: 80, title: '寺院傳真', field: 'templeFax' },
  { width: 120, title: '寺院電郵', field: 'templeEmail' },
  { width: 80, title: '郵寄連絡寺院', field: 'mailTempleText' }, // mailTempleText
  { width: 180, title: '郵寄連絡地址', field: 'mailAddr' },
  { width: 80, title: '郵寄連絡電話', field: 'mailPhone' },
  { width: 80, title: '袈娑長', field: 'lingerLength' },
  { width: 80, title: '袈娑寬', field: 'lingerWidth' },
  { width: 60, title: '身高(公分)', field: 'height' },
  { width: 60, title: '體重(公斤)', field: 'weight' },
  { width: 80, title: '世學學歷', field: 'worldEducation' },
  { width: 80, title: '最終學歷', field: 'finalEducation' },
  { width: 80, title: '繄急連絡人', field: 'emergency' },
  { width: 80, title: '繄急連絡電話', field: 'emergencyPhone' },
  { width: 80, title: '備註', field: 'ps' },
  // { width: 80, title: '超渡祖先名', field: 'passname' },
  { width: 80, title: '歷代祖先', field: 'passname1' },
  { width: 180, title: '超渡地址', field: 'passnameAddr' },
  { width: 80, title: '牌位列印', field: 'tabletsPrint' },
]);



class TableBuddhist extends Component {
  state = {
    activeItem: 'bio',
    dataArr: [],
    rowData: {},
    loading: true
  }

  async componentDidMount() {
    const { objectId } = this.props
    const data1 = await Parse.queryData('buddhist', { objectId });
    this.setState({ dataArr: data1, loading: false });
  }

  // handleAdd = async () => {
  //   const { newData } = this.state;
  //   Parse.saveData('pawnRecord', newData);
  // }

  // handleUpdate = async () => {
  //   const { newData } = this.state;
  //   Parse.saveData('pawnRecord', newData);
  // }

  // handleDelete = async () => {
  //   const { oldData } = this.state
  //   Parse.deleteData('pawnRecord', oldData);
  //   this.setState({ oldData: {} })
  // }

  handleItemClick = (e, { name }) =>
    this.setState({
      activeItem: name
    })

  handleData = (rowData) => {
    this.setState({ rowData });
  }

  render() {
    const { objectId, buddhistData, loading } = this.props
    const { dataArr } = this.state
    return (<>
      {/*  <div className="content"> */}
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '法師資訊'),
          pageSize: 2,
          search: false,
          tableLayout: 'fixed'
        }}
        data={buddhistData || dataArr}
        title="法師資訊"
        actions={[
          {
            icon: 'view_headline',
            tooltip: '查看',
            onClick: (event, rowData) => this.handleData(rowData)
          }
        ]}
      // editable={{
      //   onRowAdd: newData =>
      //     new Promise(async (resolve, reject) => {
      //       const data = [...dataArr];
      //       const db = Parse.Object.extend("pawnRecord");
      //       const newDoc = new db();
      //       const doc = await newDoc.save();
      //       newData.objectId = doc.id;
      //       newData.id = doc.id;
      //       newData._id = doc.id;
      //       newData.clientId = objectId

      //       data.push(newData);
      //       this.setState({ dataArr: data, newData }, () => resolve());
      //     }).then(() => this.handleAdd()),
      //   onRowUpdate: (newData, oldData) =>
      //     new Promise((resolve, reject) => {
      //       const data = [...dataArr];
      //       const index = data.indexOf(oldData);
      //       data[index] = newData;
      //       // handleState('people0', data);
      //       this.setState({ dataArr: data, newData, oldData }, () => resolve());
      //     }).then(() => this.handleUpdate()),
      //   onRowDelete: oldData =>
      //     new Promise((resolve, reject) => {
      //       let data = [...dataArr];
      //       const index = data.indexOf(oldData);
      //       data.splice(index, 1);
      //       // handleState('people0', data);
      //       this.setState({ dataArr: data, oldData }, () => resolve());
      //     }).then(() => this.handleDelete()),
      // }}
      />
      <br />
      <br />
      {/*  </div> */}
    </>);
  }
}

export default TableBuddhist;