import React, { Component } from 'react';
import { Table, Container, Form, Button, Grid, TextArea, Header, Confirm, Select, Sticky } from 'semantic-ui-react';
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';
import Moment from 'moment';
import { getExpectedAttndDays } from '../../../widget/define/calendar';
import Parse from '../../../widget/parse'

import { getLabelName2, getOptionsSelByKey, getOptionTextByValue } from 'views/widgets/FieldsRender';
import { selType, statusArr, semesterArr } from '../../../widget/define/define'
import { from } from 'rxjs';

const nowTimestamp = Date.now()
const nowObj = new Date();
let nowYear = nowObj.getFullYear()
let nowMonth = nowObj.getMonth() + 1  // getMonth()為0-11，所以要+1
let lastMonth = nowMonth - 1 > 0 ? nowMonth - 1 : 12
let lastMonthYear = lastMonth > nowMonth ? nowYear - 1 : nowYear
let lastMonth2 = lastMonth - 1 > 0 ? lastMonth - 1 : 12
let lastMonthYear2 = lastMonth2 > lastMonth ? lastMonthYear - 1 : lastMonthYear
let onejan = new Date(nowObj.getFullYear(), 0, 1);
let nowYearWeek = Math.ceil((((nowObj - onejan) / 86400000) + onejan.getDay() + 1) / 7);
export default class AttendancePredictList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attendDay: 0,
      absentDay: 0,
      predictionDays: 0,
      predictionHours: 0,
      form: {},
      attendWeek: [],
      // nowToISO: new Date(nowTimestamp).toISOString(),
      nowToISO: new Date().toISOString(),
      nowYear,
      nowMonth,
      lastMonth,
      lastMonthYear,
      lastMonth2,
      lastMonthYear2,
      countRelDay: 0,
      countRelHour: 0,
      countExpDay: 0,
      countExpHour: 0,
      lastWeekData: [],
      nowMonthData: [],
      lastMonthData: [],
      lastMonthData2: [],
      nowYearWeek,
      confirm: false,
      show: false,
      filterForm: {
        searchText: '',
        selClass: '0',
        year: '2019',
        semes: 'A',
        yearsemester: '2019,A',
        applyResult: '0',
        studentStatus3: '0'
      },
      resStudents: [],
      extStudents: [],
      doc: {}
    };

    this.dailyAttendanceRate = [];
    this.monthAttendanceRate = [];
  }
  componentDidMount() {
    this.dailyAttendanceRate.forEach(item => {
      let rateNum = item.outerText.slice(0, -1);
      if (item && Number(rateNum) < 95) {
        item.bgColor = 'red';
      }
    })
    this.monthAttendanceRate.forEach(item => {
      let rateNum = item.outerText.slice(0, -1);
      if (item && Number(rateNum) < 90) {
        item.bgColor = 'red';
      }
    })
    this.handleFetch()
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps)
  }

  componentDidUpdate() {
    this.dailyAttendanceRate.forEach(item => {
      let rateNum = item.outerText.slice(0, -1);
      if (item && Number(rateNum) < 95) {
        item.bgColor = 'red';
      }
    })
    this.monthAttendanceRate.forEach(item => {
      let rateNum = item.outerText.slice(0, -1);
      if (item && Number(rateNum) < 90) {
        item.bgColor = 'red';
      }
    })
  }

  handleFetch = (props = this.props) => {
    const { companyObj, match, students, calendar } = props;
    const { doc, nowToISO, nowYear, nowMonth, lastMonth, lastMonthYear, lastMonth2, lastMonthYear2, nowYearWeek } = this.state
    // const { _id } = match.params;
    this.studentSelect(props)
    let allHour = 0
    let relHour = 0
    if (doc.objectId) {
      console.log(doc)
      const d = new Date();
      let weeklyExpectedDay = 0;
      let weeklyExpectedHours = 0;
      let weeklyRealDay = 0;
      let weeklyRealHours = 0;
      Parse.queryData('Monthly', { companyId: companyObj.objectId, studentId: doc.objectId }).then(snap => {
        const data = [];
        snap.forEach(item => {
          // let item2 = item
          if (new Date(item.startDate) <= d) {//計算到當月
            // console.log(item2.startDate)
            //當月只計算到當天
            if (d.getMonth() === new Date(item.startDate).getMonth() && nowYear === new Date(item.startDate).getFullYear()) {
              const monthlyExpectedDay = getExpectedAttndDays(calendar, Moment(d).set('date', 1).format('YYYY-MM-DDT00:00:00'), Moment(d).format('YYYY-MM-DDT00:00:00'));
              // console.log(weeklyExpectedDay, Moment(d).set('date', 1).format('YYYY-MM-DD'), d)
              weeklyExpectedDay += Number(monthlyExpectedDay);
              weeklyExpectedHours += (Number(monthlyExpectedDay) * 4);
              weeklyRealDay += Number(item.weeklyRealDay);
              weeklyRealHours += Number(item.weeklyRealHours);
            } else {
              weeklyExpectedDay += Number(item.weeklyExpectedDay);
              weeklyExpectedHours += Number(item.weeklyExpectedHours);
              weeklyRealDay += Number(item.weeklyRealDay);
              weeklyRealHours += Number(item.weeklyRealHours);
            }
          }
        })

        // firebase.firestore().collection(`Students/${doc._id}/Monthly`)
        //   .get().then(snap => {
        //     const data = [];
        //     snap.forEach(item => {
        //       let item2 = item.data()
        //       if (new Date(item2.startDate) <= d) {//計算到當月
        //         // console.log(item2.startDate)
        //         //當月只計算到當天
        //         if (d.getMonth() === new Date(item2.startDate).getMonth() && nowYear === new Date(item2.startDate).getFullYear()) {
        //           const monthlyExpectedDay = getExpectedAttndDays(calendar, Moment(d).set('date', 1).format('YYYY-MM-DDT00:00:00'), Moment(d).format('YYYY-MM-DDT00:00:00'));
        //           // console.log(weeklyExpectedDay, Moment(d).set('date', 1).format('YYYY-MM-DD'), d)
        //           weeklyExpectedDay += Number(monthlyExpectedDay);
        //           weeklyExpectedHours += (Number(monthlyExpectedDay) * 4);
        //           weeklyRealDay += Number(item2.weeklyRealDay);
        //           weeklyRealHours += Number(item2.weeklyRealHours);
        //         } else {
        //           weeklyExpectedDay += Number(item2.weeklyExpectedDay);
        //           weeklyExpectedHours += Number(item2.weeklyExpectedHours);
        //           weeklyRealDay += Number(item2.weeklyRealDay);
        //           weeklyRealHours += Number(item2.weeklyRealHours);
        //         }
        //       }
        //     })
        let hoursRate = weeklyRealHours / weeklyExpectedHours
        let daysRate = weeklyRealDay / weeklyExpectedDay
        this.setState({ hoursRate, daysRate, weeklyRealHours, weeklyExpectedHours, weeklyRealDay, weeklyExpectedDay, loading: false });
        // firebase.firestore().collection(`Students/${doc._id}/Daily`).where('eventDate', '<', nowToISO).get().then(snap => {
        //   var promises = [];
        //   snap.forEach(item => {
        //     var promise = firebase.firestore().collection(`Students/${doc._id}/Daily/${item.id}/Rollcall`)
        //       // .where('attendStatus', "==", '8')
        //       .get().then(snapshot => {
        //         const allData = []
        //         const relData = []
        //         snapshot.forEach(fileItem => {
        //           allData.push({ _id: fileItem.id, ...fileItem.data() })   //  今日前所有應出席時數
        //           allHour += allData.length
        //           if (fileItem.data().attendStatus === '1' || fileItem.data().attendStatus === '2') {
        //             relData.push({ _id: fileItem.id, ...fileItem.data() })  //  今日前所有實出席時數
        //             relHour += relData.length
        //           }
        //         });
        //         return { allHour, relHour, relData, allData };
        //       });
        //     promises.push(promise);
        //   });
        //   Promise.all(promises).then(results => {
        //     // console.log('all promises were done')
        //     let allHour = Number(results[results.length - 1].allHour)
        //     let relHour = Number(results[results.length - 1].relHour)
        //     let allDay = Number(results.length)
        //     let relDay = Number(results.filter(item => item.relData.length !== 0).length)

        //     let hoursRate = relHour / allHour
        //     let daysRate = relDay / allDay
        //     this.setState({ allHour, relHour, allDay, relDay, hoursRate, daysRate })
        //     // console.log(relHour, allHour, relDay, allDay, hoursRate, daysRate);
        //   }).catch(error => {
        //     console.log(error);
        //   });

      }, err => {
        console.log(`Encountered error: ${err}`);
      });
    }
  }

  studentSelect = (props = this.props) => {
    const { match, students } = props;
    const { extStudents, filterForm } = this.state;
    const { year, semes, applyResult, selClass, studentStatus3 } = filterForm;

    let currentStudents = students.concat(extStudents).sort((a, b) => a.semesterYear - b.semesterYear);//studentsList.sort((a, b) => a.semester - b.semester).sort((a, b) => a.semesterYear - b.semesterYear);
    let optStudents; // 沒有搜尋時的選單
    let resStudents; // 有加搜尋的東西 resStudents
    let tmp = currentStudents.sort((a, b) => ('' + a.studentId).localeCompare(b.studentId)).filter(item =>
      (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
      (item.applyResult === '1' || item.studentType === "2") //在學 || 聽講
    )

    if (selClass && selClass !== '0' && selClass !== "-1") {
      tmp = tmp.filter(item => item.classSchool === selClass); // 在籍生
    } else if (selClass === "-1") {
      // console.log('-1')
      tmp = tmp.filter(item => !item.classSchool); // 找出所有還沒編班的
    }

    optStudents = tmp.map(item => ({
      key: item.objectId,
      text: `[${item.classSchool || '-'}] ${item.studentId ? `${item.semesterYear.substr(-2)}(${item.semester})${item.studentId}` : '----'} ${item.jpnName}`,
      value: item.objectId, classroom: item.classSchool, semester: item.semester
    }));
    if (!optStudents.length) {
      optStudents.push({ key: '-1', text: '(無)', value: '' })
    }
    resStudents = optStudents;

    this.setState({ resStudents, optStudents });
  }


  handleChangeFilter = (event, { name, value }) => {
    const { match, history, students } = this.props
    const { filterForm } = this.state
    const { _id, list } = match.params;

    if (name === 'yearsemester') {
      const year = value.substring(0, 4);
      const semes = value.substring(5);
      const yearsemester = value;
      this.setState({ filterForm: { ...filterForm, year, semes, selClass: '0', yearsemester, searchText: '' } }, () => this.studentSelect());
    } else if (name === 'selClass') {
      const selClass = value;
      this.setState({ filterForm: { ...filterForm, selClass, searchText: '' } }, () => this.studentSelect());
    }
  }

  handleChange = (event, { name, value }) => {
    this.setState({ error1: false, error2: false, show: false, [name]: Number(value) }, () => {
      // const { attendDay, absentDay, allHour, relHour, allDay, relDay } = this.state
      const { attendDay, absentDay, weeklyRealHours, weeklyExpectedHours, weeklyRealDay, weeklyExpectedDay, } = this.state
      if (attendDay === '' || absentDay === '') {
        this.showConfirm()
      } else {
        let predictionHours = (weeklyRealHours + attendDay * 4) / (weeklyExpectedHours + attendDay * 4 + absentDay * 4)
        let predictionDays = (weeklyRealDay + attendDay) / (weeklyExpectedDay + attendDay + absentDay);
        this.setState({ predictionHours, predictionDays, show: true })
      }
    })
  }

  showConfirm = () => this.setState({ confirm: true });
  hideConfirm = () => {
    const { attendDay, absentDay } = this.state
    this.setState({ confirm: false })
    if (attendDay === '' && absentDay === '') {
      this.setState({ error1: true, error2: true })
    } else if (attendDay === '') {
      this.setState({ error1: true })
    } else if (absentDay === '') {
      this.setState({ error2: true })
    }
  };

  handleClick = (event, data) => {
    const { value } = data;
    const { students } = this.props
    const obj = students.find(item => item.objectId === value) || {};
    this.setState({ doc: obj, form: obj }, () => this.handleFetch());
  }
  handleSearch = (e, { value }) => {
    const { optStudents } = this.state;
    const { filterForm } = this.state

    if (value) {  // 搜尋值中間可以插入任意值 例如搜尋'程雪原' 可以找到 '程 雪原'
      const looseSearchValue = RegExp(value.split('').reduce((pattern, item) => pattern + item + '.*', '.*'));

      const resStudents = optStudents.filter(item => item.text.match(looseSearchValue));
      this.setState({ resStudents, filterForm: { ...filterForm, searchText: value } });
    } else {
      this.setState({ resStudents: optStudents, filterForm: { ...filterForm, searchText: '' } });
    }
  }

  render() {
    const { semesterYear, semester, calendar, studentsInClass, optSet } = this.props;
    const { absentDay, attendDay, predictionDays, predictionHours, hoursRate, daysRate, filterForm, resStudents, doc } = this.state;
    const { yearsemester, selClass, searchText } = filterForm;
    const classSchoolArr = [{ key: '0', text: '- クラス選択 -', value: '0' }, { key: '-1', text: '未定', value: '-1' }].concat(getOptionsSelByKey(optSet, 'classSchool'));
    resStudents.map(item => ({ key: item.key, className: 'item', value: item.value, text: item.text }))
    const stuOpt = [
      { key: 'stuOptNull', className: 'item', value: ' ', text: '-- 選択 --' },
      ...resStudents
    ]
    const arrPdf = [
      {
        studentId: doc.studentId,
        jpnName: doc.jpnName,
        attendDay,
        absentDay,
        dRate: `${Math.round(daysRate * 10000) / 100 || '-'}%`, //日数出席率
        hRate: `${Math.round(hoursRate * 10000) / 100 || '-'}%`, //時間出席率
        dPRate: `${this.state.show === true ? Math.round(predictionDays * 10000) / 100 : Math.round(daysRate * 10000) / 100}%`, //予測日出席率
        hPRate: `${this.state.show === true ? Math.round(predictionHours * 10000) / 100 : Math.round(hoursRate * 10000) / 100}%`, //予測時出席率
      }
    ]
    return (<div ref={el => (this.componentRef = el)} className="" style={{ borderRadius: '.28571429rem', padding: '5px 0' }} >
      <div style={{ padding: '2%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 2rem .5rem' }}>
          <Header as='h2' content='個人出席率予測' style={{ margin: 0 }} />
          <div style={{ display: 'flex', /*paddingRight: '3rem'*/ }}>
            <div style={{ display: 'flex', marginLeft: 10 }}>
              <Form.Select search name="studentSel" onChange={this.handleClick} defaultValue={' '} options={stuOpt} />
            </div>
            <ModalPdf
              {...this.props}
              // ref={(ref) => { this.pdfModal = ref }}
              codekey="attPredictionPdf"
              params={arrPdf}
            />
          </div>
        </div>
        <Grid style={{ margin: '0 1rem' }}>
          {doc.objectId ? <Grid.Row columns='equal' style={{ fontSize: '1.5rem' }}>
            <Grid.Column> {doc.studentId} {doc.jpnName}</Grid.Column>
          </Grid.Row> : null}
          <Grid.Row columns='equal' style={{ alignItems: 'flex-end' }}>
            <Grid.Column>
              <Form.Field size='large'>
                <Form.Input
                  type='number'
                  error={this.state.error1}
                  label='予測出席日数'
                  name='attendDay'
                  onChange={this.handleChange}
                  value={attendDay ? attendDay : 0} />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field size='large'>
                <Form.Input
                  type='number'
                  error={this.state.error2}
                  label='予測欠席日数'
                  name='absentDay'
                  onChange={this.handleChange}
                  value={absentDay ? absentDay : 0} />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ alignItems: 'center' }}>
            <Grid.Column width={2}><div>今の出席率</div></Grid.Column>
            <Grid.Column width={14}>
              <Table celled size="large" >
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={5}>日数出席率&nbsp;&nbsp;&nbsp;&nbsp;</Table.Cell>
                    <Table.Cell width={3}>{Math.round(daysRate * 10000) / 100 || '0'}%</Table.Cell>
                    <Table.Cell width={5}>時間出席率&nbsp;&nbsp;&nbsp;&nbsp;</Table.Cell>
                    <Table.Cell width={3}>{Math.round(hoursRate * 10000) / 100 || '0'}%</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ alignItems: 'center' }}>
            <Grid.Column width={2}><div>予測出席率</div></Grid.Column>
            <Grid.Column width={14}>
              <Table celled size="large" >
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={5}>予測日出席率</Table.Cell>
                    <Table.Cell width={3}>{this.state.show === true ? Math.round(predictionDays * 10000) / 100 : Math.round(daysRate * 10000) / 100 || '0'}%</Table.Cell>
                    <Table.Cell width={5}>予測時出席率</Table.Cell>
                    <Table.Cell width={3}>{this.state.show === true ? Math.round(predictionHours * 10000) / 100 : Math.round(hoursRate * 10000) / 100 || '0'}%</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {/* </Grid.Column> */}
        {/* </Grid.Row> */}
      </div>
      <Confirm
        header='必須填寫預測天數'
        content='必須填寫預測天數'
        cancelButton={null}
        confirmButton='閉じる'
        open={this.state.confirm}
        // onCancel={this.hideConfirm}
        onConfirm={this.hideConfirm}
        size='mini'
        centered={false} />
    </div>);
  }
}