import React, { Component, } from 'react';
import Moment from 'moment';
import { Form, Button, Table, Header, Divider, Dimmer, Loader, Image, Segment } from 'semantic-ui-react';
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton'
// import { showField, showFieldText, getAvailblePeriodArr, getOptionsByKey, renderSettingField } from 'views/widgets/FieldsRender';
import { getOptionsSelByKey } from 'views/widgets/FieldsRender';
import { Link } from 'react-router-dom';
import Parse from '../../../../widget/parse'

const style = {
  tableShow: {
    display: 'table',
  },
  tableHide: {
    display: 'none',
  }
}
const roundArr = [
  { key: 'first', value: '1', text: '第一回' },
  { key: 'second', value: '2', text: '第二回' },
]
const yearArr = [
  { key: '2019', value: '2019', text: '2019年度' },
  { key: '2020', value: '2020', text: '2020年度' },
  { key: '2021', value: '2021', text: '2021年度' },
  { key: '2022', value: '2022', text: '2022年度' },
]

const examTypeOpt = [
  // { key: 'typeTestNull', value: ' ', text: '-- 選択 --' },
  { key: 'typeTes1', value: 'EJU', text: '1 日本留学試験(EJU)' },
  { key: 'typeTes2', value: 'JLPT', text: '2 日本語能力試験(JLPT)' },
  { key: 'typeTes3', value: 'ENG', text: '3 英語試験(TOEFL/TOEIC)' },
]

const getScoreField = (scoreName, item, handleUpdateSetting, type = "text") => {
  // if (type === 'select') {
  //   return <Select
  //     fluid compact
  //     name={scoreName}
  //     item2={item}
  //     onChange={handleUpdateSetting}
  //     options={abcdArr}
  //     value={item[scoreName] ? item[scoreName] : ''}
  //   />
  // } else if (type === 'classroom') {
  //   return <Select
  //     fluid compact
  //     name={scoreName}
  //     item2={item}
  //     onChange={handleUpdateSetting}
  //     options={a2zArr}
  //     value={item[scoreName] ? item[scoreName] : ''}
  //   />
  // } else {
  return <Form.Input
    fluid
    name={scoreName}
    item2={item}
    onChange={handleUpdateSetting}
    value={item[scoreName] ? item[scoreName] : ''}
  />
  // }
}

export default class ExamList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      show: false,
      exam: [],
      examData: [],
      confirm: false,
      loading: false,
      disabled: false,
      examType: 'EJU',
      examTypeTitle: '日本留学試験結果(EJU)',
      stu: [],
      // testDate: this.getToday(),
      testDate: '',
      noData: false,
      classSchool: '-1',
      showTab: false,
      students: []
    };
  }

  async componentDidMount() {
    // console.log('componentDidMount');
    const { companyObj } = this.props
    const data = await Parse.queryData('Students', { companyId: companyObj.objectId });
    this.setState({ students: data }, () => this.handleFetch());
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   // console.log(nextProps, prevState)
  //   // if (nextProps.id !== prevState.prevId) {
  //   //   return {};
  //   // }
  //   return {};
  // }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return nextState.testDate !== this.state.testDate || nextState.examType !== this.state.examType
  // }
  // getSnapshotBeforeUpdate(prevProps, prevState) {
  //   console.log('getSnapshotBeforeUpdate');
  //   if (prevState.testDate !== this.state.testDate || prevState.examType !== this.state.examType) {
  //     console.log('change');
  //     this.handleFetch(prevProps);
  //     return {};
  //   }
  //   return null
  // }
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   console.log('componentDidUpdate');
  //   if (snapshot !== null) {
  //     this.handleFetch(prevProps);
  //   }
  // }

  handleFetch = async (props = this.props) => {
    const { firebase, match, companyObj } = props;
    const { testDate, examType, classSchool, students } = this.state
    const { _id } = match.params;
    // this.setState({ loading: true })


    const arrStu = students.filter(item =>
      (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
      (item.applyResult === '1' || item.studentType === "2"))
    // .sort((a, b) => (('' + a.studentId).localeCompare(b.studentId)));

    let dbName = examType

    let isPassN1 = 0
    let notPassN1 = 0
    let isPassN2 = 0
    let notPassN2 = 0
    let isPassN3 = 0
    let notPassN3 = 0
    let isPassN4 = 0
    let notPassN4 = 0
    let isPassN5 = 0
    let notPassN5 = 0;

    const examData = []
    if (testDate && testDate !== '') {
      const data = await Parse.queryData(dbName, { companyId: companyObj.objectId, testDate: testDate });
      // firebase.firestore().collectionGroup(dbName)
      //   .where('testDate', '==', testDate)
      //   .get().then(snap => {
      data.forEach(item => {
        // const obj = { _id: item.id, ...item.data() };
        if (arrStu.find(item2 => item2.objectId === item.studentId)) {
          if (dbName === 'JLPT') {
            examData.push(item);
            switch (item.grade) {
              case '1':
                return (item.isPass === '1') ?
                  isPassN1 += 1 :
                  notPassN1 += 1
              case '2':
                return (item.isPass === '1') ?
                  isPassN2 += 1 :
                  notPassN2 += 1
              case '3':
                return (item.isPass === '1') ?
                  isPassN3 += 1 :
                  notPassN3 += 1
              case '4':
                return (item.isPass === '1') ?
                  isPassN4 += 1 :
                  notPassN4 += 1
              case '5':
                return (item.isPass === '1') ?
                  isPassN5 += 1 :
                  notPassN5 += 1
              case '':
              default:
                return
            }
          }
          examData.push(item);
        }
      });
      this.setState({ examData, showTab: true, show: true, loading: false, isPassN1, isPassN2, isPassN3, isPassN4, isPassN5, notPassN1, notPassN2, notPassN3, notPassN4, notPassN5 });
      // });
    } else {
      const data = await Parse.queryData(dbName, { companyId: companyObj.objectId });
      // firebase.firestore().collectionGroup(dbName).get().then(snap => {
      data.forEach(item => {
        // const obj = { _id: item.id, ...item.data() };
        if (arrStu.find(item2 => item2.objectId === item.studentId)) {
          if (dbName === 'JLPT') {
            examData.push(item);
            if (classSchool === '-1' || item.classSchool === classSchool) {
              switch (item.grade) {
                case '1':
                  return (item.isPass === '1') ?
                    isPassN1 += 1 :
                    notPassN1 += 1
                case '2':
                  return (item.isPass === '1') ?
                    isPassN2 += 1 :
                    notPassN2 += 1
                case '3':
                  return (item.isPass === '1') ?
                    isPassN3 += 1 :
                    notPassN3 += 1
                case '4':
                  return (item.isPass === '1') ?
                    isPassN4 += 1 :
                    notPassN4 += 1
                case '5':
                  return (item.isPass === '1') ?
                    isPassN5 += 1 :
                    notPassN5 += 1
                // case '':
                default:
                  return
              }
            }
          }
          examData.push(item);
        }
      });
      let sortedDate = examData.sort((a, b) => (('' + a.studentIdKey).localeCompare(b.studentIdKey)))

      if (classSchool !== '-1') {
        let newRow = examData.filter(item => item.classSchool === classSchool)
          .sort((a, b) => (('' + a.studentIdKey).localeCompare(b.studentIdKey)))
        this.setState({ filterData: newRow })
      }
      this.setState({ examData, doc: examData, showTab: true, show: true, loading: false, isPassN1, isPassN2, isPassN3, isPassN4, isPassN5, notPassN1, notPassN2, notPassN3, notPassN4, notPassN5 });
      // });
    }
  }

  handleChange = (event, data) => {
    const { examData, examType, classSchool } = this.state
    const { name, value } = data
    let examTypeTitle = '';
    if (name === 'examType') {
      if (value === 'EJU') {
        examTypeTitle = '日本留学試験結果(EJU)'
      } else if (value === 'JLPT') {
        examTypeTitle = '日本語能力試験結果(JLPT)'
      } else if (value === 'ENG') {
        examTypeTitle = '英語試験結果(TOEFL/TOEIC)'
      }
      this.setState({ examType: value, examTypeTitle, loading: true }, () => this.handleFetch())
    } else if (name === 'testDate') {
      this.setState({ testDate: value }, () => this.handleFetch())
    } else if (name === 'classroom') {
      let newRow = examData.filter(item => item.classSchool === value)
      this.setState({ classSchool: value, filterData: newRow }, () => this.handleFetch())
      // this.setState({ classSchool: value, filterData: newRow }, examType === 'JLPT' ? () => this.handleFetch() : null)
    }
  }

  handleUpdateSetting = (event, data) => {
    const { examData } = this.state
    let fieldName = data.name;
    let fleldVal = data.value;

    const arr = [...examData];
    const findIndex = arr.findIndex(item => item.objectId === data.item2._id);
    if (findIndex !== -1) {
      const obj = arr[findIndex];
      arr[findIndex] = { ...obj, [fieldName]: fleldVal }
      this.setState({ examData: arr });
    }
  }

  saveUpdateSetting = () => {
    const { firebase } = this.props;
    const { examData, examType } = this.state
    const db = firebase.firestore()
    examData.forEach(item => {
      if (examType === 'JLPT') {
        item.totalScore = Number(item.vocabularyScore) + Number(item.grammarScore) + Number(item.listeningScore)
        switch (item.grade) {
          case '1':
          default:
            if (item.vocabularyScore >= 19 && item.listeningScore >= 19 && item.grammarScore >= 19 && item.totalScore >= 100) {
              item.isPass = '1'
              item.isPassText = '合格'
            } else {
              item.isPass = '0'
              item.isPassText = '不合格'
            }
            break;
          case '2':
            if (item.vocabularyScore >= 19 && item.listeningScore >= 19 && item.grammarScore >= 19 && item.totalScore >= 90) {
              item.isPass = '1'
              item.isPassText = '合格'
            } else {
              item.isPass = '0'
              item.isPassText = '不合格'
            }
            break;
          case '3':
            if (item.vocabularyScore >= 19 && item.listeningScore >= 19 && item.grammarScore >= 19 && item.totalScore >= 95) {
              item.isPass = '1'
              item.isPassText = '合格'
            } else {
              item.isPass = '0'
              item.isPassText = '不合格'
            }
            break;
          case '4':
            if (item.vocabularyScore45 >= 38 && item.listeningScore >= 19 && item.totalScore >= 90) {
              item.isPass = '1'
              item.isPassText = '合格'
            } else {
              item.isPass = '0'
              item.isPassText = '不合格'
            }
            break;
          case '5':
            if (item.vocabularyScore45 >= 38 && item.listeningScore >= 19 && item.totalScore >= 80) {
              item.isPass = '1'
              item.isPassText = '合格'
            } else {
              item.isPass = '0'
              item.isPassText = '不合格'
            }
            break;
        }
      }
      else if (examType === 'EJU')
        item.jpTotalScore = Number(item.jpListeningScore) + Number(item.jpReadingScore)

      db.doc(`/Students/${item.studentId}/${examType}/${item._id}`).update(item)

    })
    this.setState({ isEdit: false, doc: examData });
  }

  renderTH = (examType) => {
    switch (examType) {
      case 'JLPT':
        return (<>
          <Table.Row>
            <Table.HeaderCell>試験年・回</Table.HeaderCell>
            <Table.HeaderCell>学籍番号</Table.HeaderCell>
            <Table.HeaderCell>氏名</Table.HeaderCell>
            <Table.HeaderCell>クラス</Table.HeaderCell>
            <Table.HeaderCell>受験級</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "120px" }}>言語知識（文字・語彙）</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "120px" }}>言語知識（文法・読解）</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "120px" }}>聴解</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "120px" }}>合計</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "70px" }}>合否</Table.HeaderCell>
          </Table.Row>
        </>)
      case 'ENG':
        return (<>
          <Table.Row>
            <Table.HeaderCell>試験年月日</Table.HeaderCell>
            <Table.HeaderCell>学籍番号</Table.HeaderCell>
            <Table.HeaderCell>氏名</Table.HeaderCell>
            <Table.HeaderCell>クラス</Table.HeaderCell>
            <Table.HeaderCell>TOEIC</Table.HeaderCell>
            <Table.HeaderCell>TOEFL</Table.HeaderCell>
          </Table.Row>
        </>)
      case 'EJU':
        // default:
        return (<>
          <Table.Row>
            <Table.HeaderCell>試験年・回</Table.HeaderCell>
            <Table.HeaderCell>学籍番号</Table.HeaderCell>
            <Table.HeaderCell>氏名</Table.HeaderCell>
            <Table.HeaderCell>クラス</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "100px" }}>聴解(日本語)</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "100px" }}>読解(日本語)</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "100px" }}>日本語合計点</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "100px" }}>文法</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "100px" }}>論理</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "100px" }}>記述</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "100px" }}>物理</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "100px" }}>生物</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "100px" }}>化学</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "100px" }}>総合科目</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "100px" }}>数学 I</Table.HeaderCell>
            <Table.HeaderCell style={{ width: "100px" }}>数学 II</Table.HeaderCell>
          </Table.Row>
        </>)
      case '':
      default:
        return
    }
  }

  renderTD = (examData, examType, testDate, classSchool) => {
    const { isEdit } = this.state
    if (this.state.filterData && this.state.classSchool !== '-1')
      examData = this.state.filterData
        .sort((a, b) => (('' + a.studentIdKey).localeCompare(b.studentIdKey)))
    switch (examType) {
      case 'JLPT':
        return (<>
          {examData && examData.length ? examData.map((item, index) => <Table.Row negative={item.isPass === '0'} key={index}>
            <Table.Cell>{item.testYear && (item.testYear + '年度' + (item.testRound && roundArr.find(round => round.value === item.testRound).text))}</Table.Cell>
            <Table.Cell>{item.studentIdKey || ''}</Table.Cell>
            <Table.Cell><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/1`}>{item.studentName || ''}</Link></Table.Cell>
            <Table.Cell>{item.classSchool || ''}</Table.Cell>
            <Table.Cell>{item.gradeText || ''}</Table.Cell>
            {item.vocabularyScore45 && item.vocabularyScore45 !== '' ?
              <Table.Cell colSpan='2'>{isEdit ? getScoreField('vocabularyScore45', item, this.handleUpdateSetting, "number") : item.vocabularyScore45}</Table.Cell> : <>
                <Table.Cell>{isEdit ? getScoreField('vocabularyScore', item, this.handleUpdateSetting, "number") : item.vocabularyScore || ''}</Table.Cell>
                <Table.Cell>{isEdit ? getScoreField('grammarScore', item, this.handleUpdateSetting, "number") : item.grammarScore || ''}</Table.Cell></>}
            <Table.Cell>{isEdit ? getScoreField('listeningScore', item, this.handleUpdateSetting, "number") : item.listeningScore || ''}</Table.Cell>
            <Table.Cell>{(item.vocabularyScore45 ? Number(item.vocabularyScore45) : Number(item.vocabularyScore) + Number(item.grammarScore)) + Number(item.listeningScore) || ''}</Table.Cell>
            {/* <Table.Cell>{item.totalScore || ''}</Table.Cell> */}
            <Table.Cell>{item.isPassText || ''}</Table.Cell>
          </Table.Row>)
            : classSchool && classSchool !== '' ? examData.filter(item => item.classSchool === classSchool).length === 0 ? <Table.Row><Table.Cell colSpan='15' textAlign='center'>(資料無し)</Table.Cell></Table.Row>
              : testDate && testDate !== '' ? examData.filter(item => item.testDate === testDate).length === 0 ? <Table.Row><Table.Cell colSpan='15' textAlign='center'>(資料無し)</Table.Cell></Table.Row> : null : null : null}
        </>)
      case 'ENG':
        return (<>
          {examData && examData.length ? examData.map((item, index) => <Table.Row key={index}>
            <Table.Cell>{item.testDate}</Table.Cell>
            <Table.Cell>{item.studentIdKey}</Table.Cell>
            <Table.Cell>{item.studentName}</Table.Cell>
            <Table.Cell>{item.classSchool}</Table.Cell>
            <Table.Cell>{item.TOEIC}</Table.Cell>
            <Table.Cell>{item.TOEFL}</Table.Cell>
          </Table.Row>)
            : classSchool && classSchool !== '' ? examData.filter(item => item.classSchool === classSchool).length === 0 ? <Table.Row><Table.Cell colSpan='15' textAlign='center'>(資料無し)</Table.Cell></Table.Row>
              : testDate && testDate !== '' ? examData.filter(item => item.testDate === testDate).length === 0 ? <Table.Row><Table.Cell colSpan='15' textAlign='center'>(資料無し)</Table.Cell></Table.Row> : null : null : null}
        </>)
      case 'EJU':
        // default:
        return (<>
          {examData.length ? examData.map((item, index) => <React.Fragment key={index}>
            <Table.Row>
              <Table.Cell>{item.testYear && (item.testYear + '年度' + (item.testRound && roundArr.find(round => round.value === item.testRound).text))}</Table.Cell>
              <Table.Cell>{item.studentIdKey}</Table.Cell>
              <Table.Cell><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/1`}>{item.studentName}</Link></Table.Cell>
              <Table.Cell>{item.classSchool}</Table.Cell>
              <Table.Cell>{isEdit ? getScoreField('jpListeningScore', item, this.handleUpdateSetting, "number") : item.jpListeningScore}</Table.Cell>
              <Table.Cell>{isEdit ? getScoreField('jpReadingScore', item, this.handleUpdateSetting, "number") : item.jpReadingScore}</Table.Cell>
              <Table.Cell>{item.jpTotalScore}</Table.Cell>
              <Table.Cell>{isEdit ? getScoreField('jpGrammarScore', item, this.handleUpdateSetting, "number") : item.jpGrammarScore}</Table.Cell>
              <Table.Cell>{isEdit ? getScoreField('logicScore', item, this.handleUpdateSetting, "number") : item.logicScore}</Table.Cell>
              <Table.Cell>{isEdit ? getScoreField('totalDescription', item, this.handleUpdateSetting, "number") : item.totalDescription}</Table.Cell>
              <Table.Cell>{isEdit ? getScoreField('physicsScore', item, this.handleUpdateSetting, "number") : item.physicsScore}</Table.Cell>
              <Table.Cell>{isEdit ? getScoreField('biologyScore', item, this.handleUpdateSetting, "number") : item.biologyScore}</Table.Cell>
              <Table.Cell>{isEdit ? getScoreField('chemistryScore', item, this.handleUpdateSetting, "number") : item.chemistryScore}</Table.Cell>
              <Table.Cell>{isEdit ? getScoreField('generalScore', item, this.handleUpdateSetting, "number") : item.generalScore}</Table.Cell>
              <Table.Cell>{isEdit ? getScoreField('math1Score', item, this.handleUpdateSetting, "number") : item.math1Score}</Table.Cell>
              <Table.Cell>{isEdit ? getScoreField('math2Score', item, this.handleUpdateSetting, "number") : item.math2Score}</Table.Cell>
            </Table.Row>
          </React.Fragment>)
            : classSchool && classSchool !== '' ? examData.filter(item => item.classSchool === classSchool).length === 0 ? <Table.Row><Table.Cell colSpan='16' textAlign='center'>(資料無し)</Table.Cell></Table.Row>
              : testDate && testDate !== '' ? examData.filter(item => item.testDate === testDate).length === 0 ? <Table.Row><Table.Cell colSpan='15' textAlign='center'>(資料無し)</Table.Cell></Table.Row> : null : null : null}
        </>)
      case '':
      default:
        return
    }
  }

  render() {
    const { optSet, companyObj } = this.props
    const { isEdit, classSchool, loading, confirm, doc, show, examData, testDate, examType, examTypeTitle, filterData, isPassN1, isPassN2, isPassN3, isPassN4, isPassN5, notPassN1, notPassN2, notPassN3, notPassN4, notPassN5 } = this.state;
    const classSchoolOpt = getOptionsSelByKey(optSet, "classSchool");
    let classSchoolArr = [{ key: '000', value: '-1', text: '全て' }, ...classSchoolOpt]

    return (<>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Header as='h2' content='校外テスト一覧'
          style={{ backgroundColor: 'lavender', margin: 0 }} />

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minWidth: 750 }}>

          {/* <Input labelPosition='left' style={{ marginRight: 20 }}>
            <Label color='violet'><Icon name='building' />クラス</Label>
            <Form.Select
              style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
              name='classroom'
              value={classSchool}
              options={classSchoolOpt}
              onChange={this.handleChange} />
          </Input> */}
          <Form.Select
            label='クラス'
            name='classroom'
            value={classSchool}
            options={classSchoolArr}
            onChange={this.handleChange}
          />
          <Form.Select
            label='試験類別'
            name='examType'
            options={examTypeOpt}
            onChange={this.handleChange}
            defaultValue='EJU'
          />
          <Form.Select
            label='試験年'
            name='testYear'
            options={yearArr}
            onChange={this.handleChange}
            defaultValue={''}
          // defaultValue={new Date().getFullYear()+''}
          />
          <Form.Select
            label='回'
            name='testRound'
            options={roundArr}
            onChange={this.handleChange}
            defaultValue=''
          // defaultValue='1'
          />
          <div style={{ display: 'flex', marginTop: '1em' }}>
            <ExportTableToExcelButton size='small' />
            <ModalPdf
              {...this.props}
              // ref={(ref) => { this.pdfModal = ref }}
              codekey="examListPdf"
              params={(filterData && this.state.classSchool !== '-1') ? filterData : examData}
            />
          </div>
        </div>
      </div>
      <Divider /><div style={{ display: this.state.showTab ? 'block' : 'none' }}>
        <Header as='h2'>{testDate} {examTypeTitle}</Header>
        <div style={{ float: 'right' }}>
          {isEdit ? <><Button positive onClick={() => this.saveUpdateSetting()} icon='save' content='保存' />
            <Button basic onClick={() => this.setState({ examData: doc, isEdit: false })} icon='times' content='キャンセル' /></> :
            <Button size='medium' color='green' onClick={() => this.setState({ isEdit: true })} icon='edit' content="成績編集" />}
        </div>
        <div id='excel'>
          <Table celled structured style={!show ? style.tableHide : style.tableShow}>
            <Table.Header>
              {this.renderTH(examType)}
            </Table.Header>
            <Table.Body className='TB'>
              {this.renderTD(examData, examType, testDate, classSchool)}
              {/* {stu.every(item => !item.examData.length) ? <Table.Row><Table.Cell colSpan='15' textAlign='center'>(資料無し)</Table.Cell></Table.Row> : null}           */}
            </Table.Body>
          </Table></div>
        {examType === 'JLPT' ? <Table celled textAlign='center' style={{ display: !show ? 'none' : 'table', maxWidth: 500, margin: '20px auto' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={6}>受験級</Table.HeaderCell>
              <Table.HeaderCell width={5}>合格</Table.HeaderCell>
              <Table.HeaderCell width={5}>不合格</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>N1</Table.Cell>
              <Table.Cell>{isPassN1}人</Table.Cell>
              <Table.Cell>{notPassN1}人</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>N2</Table.Cell>
              <Table.Cell>{isPassN2}人</Table.Cell>
              <Table.Cell>{notPassN2}人</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>N3</Table.Cell>
              <Table.Cell>{isPassN3}人</Table.Cell>
              <Table.Cell>{notPassN3}人</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>N4</Table.Cell>
              <Table.Cell>{isPassN4}人</Table.Cell>
              <Table.Cell>{notPassN4}人</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>N5</Table.Cell>
              <Table.Cell>{isPassN5}人</Table.Cell>
              <Table.Cell>{notPassN5}人</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table> : null}
      </div>
      <div style={{ display: !show ? 'flex' : 'none', justifyContent: 'center', margin: '30px 0' }}>
        <Segment>
          <Dimmer active inverted={companyObj.inverted}>
            <Loader inverted={companyObj.inverted}>Loading</Loader>
          </Dimmer>
          <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        </Segment>
      </div>
    </>);
  }
}
