import React, { Component } from 'react'
import { Select, Input } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Moment from 'moment';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { typeCurrency, exportPdf } from 'views/web/CustomMaterialTable';
import { TextField } from '@material-ui/core';


const columns = () => ([
  {
    title: '摘要', field: 'content',
    editComponent: props => props.rowData.showFlag === 0 ? props.rowData.content : <TextField value={props.rowData.content} onChange={e => props.onRowDataChange({ ...props.rowData, content: e.target.value })} />
  },
  { title: '預估收入', field: 'income', ...typeCurrency },
  { title: '預估支出', field: 'outcome', ...typeCurrency },
  { title: '備註', field: 'ps' }
]);

export default class Fin1111 extends Component {
  state = {
    bankaccArr: [],
    dataArr: [],
    predictArr: [],
    deleteConfirm: false,
    loading: true,
    bankaccSel: '',
    preBalance: '',
    nextBalance: '',
    preBalanceArr: [],
    nextBalanceArr: [],
    monthSel: Moment(new Date()).format('YYYY-MM') || '',
  }

  async componentDidMount() {
    const { companyObj } = this.props;
    const companyId = companyObj.objectId;

    const bankacc = await Parse.queryData('bankacc', { companyId });

    const bankaccArr = bankacc.filter(item => item.value).map(item => ({
      key: item.objectId, text: `${item.value}`, value: item.objectId
    }));
    const bankaccSel = bankacc && bankacc.length ? bankacc[0].objectId : '';
    this.setState({ dataArr: bankacc, bankaccArr, bankaccSel, bankacc }, () => this.handleFetch());
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props, type = 1) => {
    const { companyObj } = props;
    const { monthSel, bankaccSel } = this.state
    const [year, month] = monthSel.split('-');
    if (!year || !month) {
      return;
    }
    const companyId = companyObj.objectId;

    if (type === 1 && year && month) {

      let newDataArr = []
      const predict = await Parse.queryData('predict', { companyId, bankaccId: bankaccSel, monthSel });
      const balance = await Parse.queryData('balance', { companyId, bankaccId: bankaccSel, monthSel });
      // let data = {};
      let obj = {}
      let balanceObj = (balance && balance.pop()) || {}
      // console.log(balanceObj, balanceObj)
      if (balanceObj) {
        // data = {
        //   objectId: balanceObj.objectId,
        //   ...balanceObj,
        // }
        obj = {
          ...balanceObj,
          content: "上月結餘",
          income: balanceObj.preBalanceIn || 0,
          outcome: balanceObj.preBalanceOut || 0,
          showFlag: 0
        }
      } else {
        obj = {
          content: "上月結餘",
          income: 0,
          outcome: 0,
          showFlag: 0
        }
      }


      if (predict.find(item => item.showFlag === 0)) {
        newDataArr = predict.map(item => {
          if (item.showFlag === 0) {
            return obj
          } else {
            return item
          }
        })
      } else {
        predict.unshift(obj)
      }


      this.setState({ predictArr: predict });
      this.handleCalculate()

    } else if (year && month) {

      let newDataArr = []
      const predict = await Parse.queryData('predict', { companyId, bankaccId: bankaccSel, monthSel });
      const balance = await Parse.queryData('balance', { companyId, bankaccId: bankaccSel, monthSel });
      // let data = {};
      let obj = {}
      let balanceObj = (balance && balance.pop()) || {}
      // console.log(balanceObj, balanceObj)
      if (balanceObj) {
        // data = {
        //   objectId: balanceObj.objectId,
        //   ...balanceObj,
        // }
        obj = {
          ...balanceObj,
          content: "上月結餘",
          income: balanceObj.preBalanceIn || 0,
          outcome: balanceObj.preBalanceOut || 0,
          showFlag: 0
        }
      } else {
        obj = {
          content: "上月結餘",
          income: 0,
          outcome: 0,
          showFlag: 0
        }
      }


      if (predict.find(item => item.showFlag === 0)) {
        newDataArr = predict.map(item => {
          if (item.showFlag === 0) {
            return obj
          } else {
            return item
          }
        })
      } else {
        predict.unshift(obj)
      }


      this.setState({ predictArr: predict });
      this.handleCalculate()
    }
  }

  handleCalculate = async () => {
    const { companyObj } = this.props
    const { newData, monthSel, bankaccSel, predictArr, preBalance } = this.state
    const [year, month] = monthSel.split('-');
    let allIncome = 0
    let allOutcome = 0
    // let allBalance
    let total = 0
    predictArr.forEach(item => {
      allIncome = Number(item.income || 0) + allIncome
      allOutcome = Number(item.outcome || 0) + allOutcome
    })
    total = String(allIncome - allOutcome)

    const obj = {
      content: '合計',
      income: allIncome,
      outcome: allOutcome,
      ps: `總收支結餘= NT$${total}`,
      showFlag: 1
    }
    predictArr.push(obj)
    this.setState({ predictArr, nextBalance: total, loading: false })
  }

  handleAdd = async () => {
    const { newData, monthSel, bankaccSel } = this.state
    const [year, month] = monthSel.split('-');
    if (!year || !month) {
      return;
    }
    const obj = {
      ...newData,
    };
    await Parse.saveData('predict', obj);
    this.handleCalculate()
  }

  handleUpdate = async () => {
    const { newData, monthSel, bankaccSel, bankaccArr } = this.state
    const { companyObj } = this.props
    const [year, month] = monthSel.split('-');
    // let bankaccName = ''
    // if (bankaccArr && bankaccSel) {
    //   bankaccName = bankaccArr.find(item => item.value === bankaccSel).text
    // } else {
    //   bankaccName = '無資料'
    // }

    if (!year || !month) {
      return;
    }

    if (newData.showFlag === 0) {
      let obj = {
        ...newData,
        preBalanceIn: newData.income || 0,
        preBalanceOut: newData.outcome || 0,
      }
      await Parse.saveData('balance', obj);
    } else {
      const obj = { ...newData };
      await Parse.saveData('predict', obj);
    }
    this.handleCalculate()
  }

  handleDelete = async () => {
    const { oldData, monthSel, bankaccSel } = this.state
    const [year, month] = monthSel.split('-');
    if (!year || !month) {
      return;
    }
    if (oldData.showFlag === 0) {
      this.setState({ loading: false })
      return console.log("OO");
    } else {
      await Parse.deleteData('predict', oldData);
      this.handleCalculate()
      this.setState({ oldData: {} })
    }
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    this.setState({ [name]: value, loading: true }, () => this.handleFetch(this.props, 0));
  }

  render() {
    const { companyObj, auth } = this.props
    const { dataArr, loading, bankaccArr, bankaccSel, monthSel, predictArr } = this.state;
    const [year, month] = monthSel.split('-') || ['', ''];
    let bankaccName = ''
    bankaccArr && bankaccSel ? bankaccName = bankaccArr.find(item => item.value === bankaccSel).text : bankaccName = '無資料'

    const editable = {
      isEditHidden: rowData => rowData.showFlag === 1 || !auth.edit ? true : false,
      isDeleteHidden: rowData => rowData.showFlag === 1 || rowData.showFlag === 0 || !auth.edit ? true : false,
      onRowAdd: newData =>
        new Promise(async (resolve, reject) => {
          const db = Parse.Object.extend("predict");
          const newDoc = new db();
          const docRef = await newDoc.save();
          const data = [...predictArr];
          const obj = {
            _id: docRef.id,
            id: docRef.id,
            objectId: docRef.id,
            companyId: companyObj.objectId,
            companyName: companyObj.name,
            bankaccId: bankaccSel,
            bankaccName,
            year,
            month,
            monthSel,
            ...newData
          }
          data.pop();
          data.push(obj);
          this.setState({ predictArr: data, newData: obj, loading: true }, () => resolve());
        }).then(() => this.handleAdd()),
      onRowUpdate: (newData, oldData) =>
        new Promise(async (resolve, reject) => {
          let obj = {}
          if (newData.objectId && newData.showFlag === 0) {
            obj = {
              ...newData
            }
          } else if (newData.showFlag === 0) {
            const db = Parse.Object.extend("balance");
            const newDoc = new db();
            const docRef = await newDoc.save();
            obj = {
              ...newData,
              id: docRef.id,
              _id: docRef.id,
              objectId: docRef.id,
              companyId: companyObj.objectId || '',
              companyName: companyObj.name || '',
              bankaccId: bankaccSel || '',
              bankaccName,
              year,
              month,
              monthSel
            }
          } else {
            obj = {
              ...newData
            }
          }
          const data = [...predictArr];
          const index = data.indexOf(oldData);
          data[index] = obj;
          data.pop();
          this.setState({ predictArr: data, newData: obj, oldData, loading: true }, () => resolve());
        }).then(() => this.handleUpdate()),
      onRowDelete: oldData =>
        new Promise((resolve, reject) => {
          let data = [...predictArr];
          const index = data.indexOf(oldData);
          data.splice(index, 1);
          data.pop();
          this.setState({ predictArr: data, oldData, loading: true }, () => resolve());
        }).then(() => this.handleDelete()),
    }

    if (auth.edit === false) {
      delete editable.onRowAdd
    }

    return (<>
      {/* 資金需求預估表 */}
      <br />
      <Input
        type="month"
        name="monthSel"
        value={monthSel}
        onChange={this.handleChange}
      />

      <Select style={{
        position: 'relative', zIndex: 99,
        float: 'right',
        minWidth: '200px',
      }}
        compact
        name="bankaccSel"
        options={bankaccArr}
        value={bankaccSel}
        onChange={this.handleChange}
      />
      <br />
      <br />
      {!year ? null : <>
        <MaterialTable
          isLoading={loading}
          localization={localization()}
          columns={columns()}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportAllData: true,
            exportPdf: (columns, data) => exportPdf(columns, data, `${year} 年 ${month} 月 ${bankaccName} 資金預估表`),
            pageSize: predictArr.length || 10,
            search: false
          }}
          data2={bankaccSel}
          data={predictArr}
          title={`${year} 年 ${month} 月 ${bankaccName} 資金預估表`}
          editable={editable}
        />
      </>}
      <br />
      <br />
    </>)
  }
}