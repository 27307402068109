import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  // NavItem,
  // NavLink,
  // Nav,
  // TabContent,
  // TabPane,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function SectionPricing(props = {}) {
  const { form } = props;
  //  console.log(form)
  const { text2 = 'text2', text3 = 'text3', text4 = 'text4', text5 = 'text5', text6 = 'text6',
    text7 = 'text7', text8 = 'text8', text9 = 'text9', text10 = 'text10', text11 = 'text11', text12 = 'text12',
    text13 = 'text13', text14 = 'text14', text15 = 'text15', text16 = 'text16', text17 = 'text17',
    text18 = 'text18', text19 = 'text19', text20 = 'text20', text21 = 'text21', text22 = 'text22',
    text23 = 'text23', text24 = 'text24', text25 = 'text25', text26 = 'text26', text27 = 'text27' } = form;
  const [activePill, setActivePill] = React.useState("1");
  // pills for the last pricing
  // const [pillActive, setPillActive] = React.useState("personal");
  return (
    <>
      <div className="section section-pricing cd-section" id="pricing">
        {/* ********* PRICING 4 ********* */}
        <div className="pricing-4 section section-dark">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">{text2}</h2>
                <h5 className="description">
                  {text3}
                </h5>
              </Col>
            </Row>
            <div className="space-top" />
            <Row>
              <Col md="3">
                <Card className="card-pricing card-plain">
                  <CardBody>
                    <h6 className="card-category text-success">{text4}</h6>
                    <CardTitle tag="h1">{text5}</CardTitle>
                    <ul className="text-white">
                      <li>
                        <i className="fa fa-check mr-1" />
                        {text6}
                      </li>
                      <li>
                        <i className="fa fa-check mr-1" />
                        {text7}
                      </li>
                      <li>
                        <i className="fa fa-times mr-1" />
                        {text8}
                      </li>
                    </ul>
                    <Button
                      className="btn-outline-neutral btn-round"
                      color="default"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      {text9}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card className="card-pricing">
                  <CardBody>
                    <h6 className="card-category text-success">
                      {text10}
                    </h6>
                    <CardTitle tag="h1">{text11}</CardTitle>
                    <ul>
                      <li>
                        <i className="fa fa-check mr-1" />
                        {text12}
                      </li>
                      <li>
                        <i className="fa fa-check mr-1" />
                        {text13}
                      </li>
                      <li>
                        <i className="fa fa-times mr-1" />
                        {text14}
                      </li>
                    </ul>
                    <Button
                      className="btn-round"
                      color="success"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                      outline
                    >
                      {text15}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card className="card-pricing card-plain">
                  <CardBody>
                    <h6 className="card-category text-success">
                      {text16}
                    </h6>
                    <CardTitle tag="h1">{text17}</CardTitle>
                    <ul className="text-white">
                      <li>
                        <i className="fa fa-check mr-1" />
                        {text18}
                      </li>
                      <li>
                        <i className="fa fa-check mr-1" />
                        {text19}
                      </li>
                      <li>
                        <i className="fa fa-times mr-1" />
                        {text20}
                      </li>
                    </ul>
                    <Button
                      className="btn-outline-neutral btn-round"
                      color="default"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      {text21}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card className="card-pricing card-plain">
                  <CardBody>
                    <h6 className="card-category text-success">{text22}</h6>
                    <CardTitle tag="h1">{text23}</CardTitle>
                    <ul className="text-white">
                      <li>
                        <i className="fa fa-check mr-1" />
                        {text24}
                      </li>
                      <li>
                        <i className="fa fa-check mr-1" />
                        {text25}
                      </li>
                      <li>
                        <i className="fa fa-times mr-1" />
                        {text26}
                      </li>
                    </ul>
                    <Button
                      className="btn-outline-neutral btn-round"
                      color="default"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      {text27}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END PRICING 4 ********* */}
      </div>
    </>
  );
}

export default SectionPricing;
