import React, { Component } from 'react';
import { Form, Button, Modal, Table, Grid, Icon, Header, Confirm, TextArea, Divider, Card } from 'semantic-ui-react';
import _ from 'underscore'

// import { showField, showFieldText, getAvailblePeriodArr, getOptionsByKey } from 'views/widgets/FieldsRender';

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}
const getAuthArray = (company, user, flag) => company[flag] ? _.union(user[flag + 'A'], [company.id]) : _.without(user[flag + 'A'], company.id);
const getAuth = (id, arr) => (arr.length && arr.indexOf(id) !== -1)

export default class User4 extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }

  handleFetch = async (props = this.props) => {
  }

  render() {
    const { bg, handleUpdateSetting, doc, form, userObj } = this.props
    // const { is_auth, onbroad_date, leave_date, name, worknumber, engname, tw_id, passport_name, birthday_date, jobyear, jobTitle, email, phone, ps } = this.state
    // const { teacher, loading } = this.state;
    // console.log(form.department_id)
    return (<>
      <div style={style.flexCenter}>
        <Header as='h2' content='操作記錄' />
      </div>
      <Divider />
      <Form>
        <br />
      </Form>
    </>
    )
  }
}