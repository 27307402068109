
import React, { Component } from 'react'
import { Grid, } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf, rowReOrder } from 'views/web/CustomMaterialTable';

const columns = (light1, light2) => ([
  // { title: '預設', field: 'now_use' },
  { title: '順序', field: 'order' },
  { title: '點燈名稱', field: 'value' },
  { title: '目前編號', field: 'now_id' },
  { title: '價錢', field: 'price' },
  { title: '備註', field: 'ps' },
]);

const columns2 = (light1, light2) => ([
  { title: '順序', field: 'order' },
  { title: '點燈名稱', field: 'value', editable: 'never' },
  { title: '編號', field: 'name' },
  { title: '開始編號', field: 'start_num' },
  { title: '結束編號', field: 'end_num' },
  // { title: '價錢', field: 'price' },
]);

class Light extends Component {
  state = {
    light1Id: '',
    data1: [],
    data2: [],
    newData1: {},
    newData2: {},
    loading: true,
    loading2: false,
  }

  async componentDidMount() {
    const { companyObj } = this.props
    // const { familyId } = match.params;
    const data = await Parse.queryData("light1", { companyId: companyObj.objectId })
    this.setState({ data1: data.sort((a, b) => a.order - b.order), loading: false })
  }
  handleAdd = async (key) => {
    if (key === "1") {
      const { newData1 } = this.state;
      Parse.saveData('light1', newData1);
    } else {
      const { newData2 } = this.state;
      Parse.saveData('light2', newData2);
    }
  }

  handleUpdate = async (key) => {
    if (key === "1") {
      const { newData1 } = this.state;
      Parse.saveData('light1', newData1);
    } else {
      const { newData2 } = this.state;
      Parse.saveData('light2', newData2);
    }
  }

  handleDelete = async (key) => {
    if (key === "1") {
      const { oldData1 } = this.state
      Parse.deleteData('light1', oldData1);
      this.setState({ oldData1: {} })
    } else {
      const { oldData2 } = this.state
      Parse.deleteData('light2', oldData2);
      this.setState({ oldData2: {} })
    }
  }

  handleData = async (rowData) => {
    // console.log(rowData);
    const light1Id = rowData.objectId
    this.setState({ light1Id: '', rowData, loading2: true });
    if (light1Id) {
      const data2 = await Parse.queryData('light2');
      this.setState({ data2: data2.filter(item => item.light1Id === light1Id), light1Id, loading2: false });
    }
  }

  handleState = (dataArr, arrName = 'dataArr') => {
    this.setState({ [arrName]: dataArr })
  }

  render() {
    const { companyObj } = this.props
    const { open, data1, data2, pray1Id, loading, loading2, rowData, light1Id } = this.state
    return (<>
      <div className="content">
        <Grid>
          <Grid.Row >
            <Grid.Column width={7} computer={7} tablet={7} mobile={16}>
              <MaterialTable
                isLoading={loading}
                localization={localization()}
                columns={columns()}
                options={{
                  addRowPosition: "first",
                  exportButton: true,
                  exportPdf: (columns, data) => exportPdf(columns, data, '點燈名稱'),
                  pageSize: data1.length || 5,
                  search: false
                }}
                // onColumnDragged={}
                // components={{
                //   Toolbar: props => (
                //     <div>
                //       <div style={{ width: '130px', float: 'right', textAlign: 'right', padding: '15px' }}>
                //         <RowsDnd {...this.props} orderField='order_id' columns={columns()} dataArr={data1} handleReorder={this.handleReorder} />
                //       </div>
                //       <MTableToolbar {...props} />
                //       <div style={{ clear: 'both' }}></div>
                //     </div>
                //   ),
                // }}
                components={{
                  Row: props => rowReOrder(props, 'light1', 'order', data1, 'data1', this.handleState)
                }}
                data={data1}
                title="點燈名稱"
                actions={[
                  {
                    icon: 'view_headline',
                    tooltip: '查看',
                    onClick: (event, rowData) => this.handleData(rowData)
                  }
                ]}
                onRowClick={(event, rowData) => this.handleData(rowData)}
                editable={{
                  onRowAdd: newData1 =>
                    new Promise(async (resolve, reject) => {
                      const data = [...data1];
                      const db = Parse.Object.extend("light1");
                      const newDoc = new db();
                      const doc = await newDoc.save();
                      newData1.objectId = doc.id;
                      newData1.id = doc.id;
                      newData1._id = doc.id;
                      newData1.companyId = companyObj.objectId;
                      newData1.companyName = companyObj.name;

                      data.push(newData1);
                      this.setState({ data1: data, newData1 }, () => resolve());
                    }).then(() => this.handleAdd("1")),
                  onRowUpdate: (newData1, oldData1) =>
                    new Promise((resolve, reject) => {
                      const data = [...data1];
                      const index = data.indexOf(oldData1);
                      data[index] = newData1;
                      // console.log(data1)
                      this.setState({ data1: data, newData1, oldData1 }, () => resolve());
                    }).then(() => this.handleUpdate("1")),
                  onRowDelete: oldData1 =>
                    new Promise((resolve, reject) => {
                      let data = [...data1];
                      const index = data.indexOf(oldData1);
                      data.splice(index, 1);
                      this.setState({ data1: data, oldData1 }, () => resolve());
                    }).then(() => this.handleDelete("1")),
                }}
              />
            </Grid.Column>
            <Grid.Column width={9} computer={9} tablet={9} mobile={16}>
              {!light1Id ? null : <MaterialTable
                isLoading={loading2}
                localization={localization()}
                columns={columns2(data1, data2)}
                options={{
                  addRowPosition: "first",
                  exportButton: true,
                  exportPdf: (columns, data) => exportPdf(columns, data, '點燈項目'),
                  pageSize: data2.length || 5,
                  search: false
                }}
                components={{
                  Row: props => rowReOrder(props, 'light2', 'order', data2, 'data2', this.handleState)
                }}
                data={data2}
                title="點燈項目"
                editable={{
                  onRowAdd: newData2 =>
                    new Promise(async (resolve, reject) => {
                      const data = [...data2];
                      const db = Parse.Object.extend("light2");
                      const newDoc = new db();
                      const doc = await newDoc.save();
                      newData2.objectId = doc.id;
                      newData2.id = doc.id;
                      newData2._id = doc.id;

                      data.push(newData2);
                      this.setState({ data2: data, newData2 }, () => resolve());
                    }).then(() => this.handleAdd("2")),
                  onRowUpdate: (newData2, oldData2) =>
                    new Promise((resolve, reject) => {
                      const data = [...data2];
                      const index = data.indexOf(oldData2);
                      data[index] = newData2;
                      this.setState({ data2: data, newData2, oldData2 }, () => resolve());
                    }).then(() => this.handleUpdate("2")),
                  onRowDelete: oldData2 =>
                    new Promise((resolve, reject) => {
                      let data = [...data2];
                      const index = data.indexOf(oldData2);
                      data.splice(index, 1);
                      this.setState({ data2: data, oldData2 }, () => resolve());
                    }).then(() => this.handleDelete("2")),
                }}
              />}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <br />
      </div>
    </>);
  }
}

export default Light;
