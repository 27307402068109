
import React, { Component } from 'react'
import { Grid, Button } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import Parse, { funcPad } from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf, rowReOrder } from 'views/web/CustomMaterialTable';
import { createJSDocTypeLiteral } from 'typescript';

// import RowsDnd from './RowsDnd'

// reactstrap components
// import {
//   Card,
//   CardHeader,
//   CardBody,
//   CardTitle,
//   Table,
//   Row,
//   Col
// } from "reactstrap";

const columns = () => ([
  { title: '順序', field: 'order', type: 'numeric' },
  { title: '身份', field: 'value' },
]);

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

class Identity extends Component {
  state = {
    dataArr: [],
    loading: false
  }
  async componentDidMount() {
    const { companyObj, match } = this.props
    // const { familyId } = match.params;
    const data1 = await Parse.queryData("templelevel", { companyId: companyObj.objectId })
    this.setState({ dataArr: data1, loading: false })
  }
  handleAdd = async () => {
    const { newData1 } = this.state;
    Parse.saveData('templelevel', newData1);
  }

  handleUpdate = async () => {
    const { newData1 } = this.state;
    Parse.saveData('templelevel', newData1);
  }

  handleDelete = async () => {
    const { oldData1 } = this.state
    Parse.deleteData('templelevel', oldData1);
    this.setState({ oldData1: {} })
  }

  handleState = (dataArr, arrName = 'dataArr') => {
    this.setState({ [arrName]: dataArr })
  }

  // handleButton = async () => {
  //   const { companyObj } = this.props
  //   const companyId = companyObj.objectId
  //   // console.log(data)
  //   // console.log("data length:", data.length)
  //   // console.log("pray2test=>" + data[0].pray2Text)
  //   // data[0].pray2Text = '整修佛像'
  //   // console.log("pray2testNew=>" + data[0].pray2Text)
  //   // const data1 = await Parse.queryData("praying2", { companyId: companyObj.objectId, testData: '1' })
  //   // console.log(data.length)
  //   data.forEach(item => {
  //     // console.log(item)
  //     const obj = {
  //       // await Parse.destroyData('family', { testData: '1' });
  //       // await Parse.destroyData('people', { testData: '1' });
  //       // await Parse.destroyData('praying1', { testData: '1' });
  //       // await Parse.destroyData('praying2', { testData: '1' });
  //       ...item,
  //       pray2Text: '韋馱菩薩'
  //     }
  //     Parse.saveData('praying1', obj);
  //     console.log("處理完成 obj：", obj)
  //   })
  //   console.log("第一部分處理完成")
  // let query = new Parse.Query('donate');
  // query.equalTo("companyId", companyId);

  // query.exists("name")
  // query.doesNotExist("donate1")
  // query.notEqualTo("name", '')
  // query.limit(30000)
  // const snap = await query.find();
  // //  const data = snap.map(data => data.toJSON())
  // const data = snap.map(data => data.toJSON()).sort((a, b) => ('' + a.ledger).localeCompare(b.ledger));\
  // const data = await Parse.queryData("family", { companyId: companyObj.objectId, testData: '1' })
  // const data1 = await Parse.queryData("donate", {})
  // const data1 = await Parse.queryData("people", { companyId: companyObj.objectId, testData: '1' })
  // console.log(data1.length);
  // console.log(data1);
  // { name: '釋覺文', donateDate: '2020/1/14', payStatus: '付清', unit: '1', ps: '十方法界一切眾生', total: '10000', payee: '釋妙逸', advisePeople: '', receiptNum: '#77-1/013539' },
  // const promises = []
  //data.forEach(async (item, index) => {
  // const obj = data1.find(item2 => item2.name === item.name)
  // const obj1 = await Parse.findDoc("praying1", { familyId: item.objectId })
  //family
  // const db = Parse.Object.extend("family");
  // const newDoc = new db();
  // const doc = await newDoc.save();
  // const familyObj = {
  //   objectId: doc.id,
  //   id: doc.id,
  //   _id: doc.id,
  //   companyName: companyObj.name,
  //   name: item.name,
  //   if_is: '0',
  //   addr: item.addr,
  //   companyId: companyObj.objectId,
  //   testData: '1'
  // }
  // //   promises.push(Parse.saveData('family', familyObj))

  // Parse.saveData('family', familyObj);
  //people
  // const db3 = Parse.Object.extend("people");
  // const newDoc3 = new db3();
  // const doc3 = await newDoc3.save();
  // const peopleObj = {
  //   objectId: doc3.id,
  //   id: doc3.id,
  //   _id: doc3.id,
  //   familyId: item.objectId,
  //   companyName: companyObj.name,
  //   name: item.name,
  //   // if_is: '0', 
  //   addr: item.addr,
  //   mainPerson: '-1',
  //   isLive: '1',
  //   companyId: companyObj.objectId,
  //   testData: '1'
  // }
  // // console.log('familyObj', familyObj)
  // // Parse.saveData('people', peopleObj);
  // Parse.saveData('people', peopleObj)

  //praying1
  // const db1 = Parse.Object.extend("praying1");
  // const newDoc1 = new db1();
  // const doc1 = await newDoc1.save();

  // const praying1Obj = {
  //   objectId: doc1.id,
  //   id: doc1.id,
  //   _id: doc1.id,
  //   familyId: item.objectId,
  //   ordernum: `R${funcPad(index, 8)}`,
  //   // const ordernum = orderalphaDef + funcPad(Number(ordernumDef) + 1, 8);
  //   companyName: companyObj.name,
  //   companyId: companyObj.objectId,
  //   pray1Text: obj.pray1Text,
  //   pray1Id: obj.pray1Id,
  //   pray2Text: obj.pray2Text,
  //   pray2Id: obj.pray2Id,
  //   year: obj.donateDate && (obj.donateDate.substring(0, 4) === '2020' ? '109' : obj.donateDate.substring(0, 4) === '2021' ? '110' : ''),
  //   applyDate: obj.donateDate,
  //   testData: '1'
  // }
  // Parse.saveData('praying1', praying1Obj);
  //   promises.push(Parse.saveData('praying1', praying1Obj))
  // console.log('praying1Obj', praying1Obj)
  //praying2
  // const db2 = Parse.Object.extend("praying2");
  // const newDoc2 = new db2();
  // const doc2 = await newDoc2.save();
  // const praying2Obj = {
  //   objectId: doc2.id,
  //   id: doc2.id,
  //   _id: doc2.id,
  //   familyId: item.objectId,
  //   praying1Id: obj1.objectId,
  //   // ordernum: `R00000${index}`,
  //   praytypeId: obj.praytypeId || '',
  //   praytypeText: obj.praytypeText || '',
  //   livename: obj.name || '',
  //   serial: `00${index}`,
  //   companyName: companyObj.name,
  //   companyId: companyObj.objectId,
  //   pray1Text: obj.pray1Text,
  //   pray1Id: obj.pray1Id,
  //   pray2Text: obj.pray2Text,
  //   pray2Id: obj.pray2Id,
  //   year: obj.donateDate && (obj.donateDate.substring(0, 4) === '2020' ? '109' : obj.donateDate.substring(0, 4) === '2021' ? '110' : ''),
  //   applyDate: obj.donateDate,
  //   testData: '1'
  // }
  // Parse.saveData('praying2', praying2Obj);
  //   promises.push(Parse.saveData('praying2', praying2Obj))
  // }
  // await Promise.all(promises).catch(error => console.log('error', error))
  //   // console.log(praying2Obj);

  //   // if (item.receiptNum.length > 6) {
  //   //   const obj = {
  //   //     ...item,
  //   //     ledger: item.receiptNum.split("/")[0],
  //   //     receiptNum: item.receiptNum.split("/")[1]
  //   //   }
  //   // const db = Parse.Object.extend("donate");
  //   // const newDoc = new db();
  //   // const doc = await newDoc.save();
  //   // const obj = {
  //   //   ...item,
  //   //   objectId: doc.id,
  //   //   id: doc.id,
  //   //   _id: doc.id,
  //   //   companyId,
  //   //   companyName: companyObj.name,
  //   //   pray1Id: '1pzegsRGTy',
  //   //   pray1Text: '重建祈福消災法會',
  //   //   pray2Id: '1tkNEDJ5kw',
  //   //   pray2Text: '建材認捐大柱',
  //   //   addr: '',
  //   // }
  //   // Parse.saveData('donate', obj);
  //})
  // }
  render() {
    // const { companyObj } = this.props;
    const { dataArr, loading } = this.state;
    return (<div className="content">
      <br />
      <br />
      <Grid>
        <Grid.Row>
          <Grid.Column width={6} computer={6} tablet={16} mobile={16}>
            <MaterialTable
              isLoading={loading}
              localization={localization()}
              columns={columns()}
              options={{
                pageSize: (dataArr.length || 10),
                search: false
              }}
              // components={{
              //   Toolbar: props => (
              //     <div>
              //       <div style={{ width: '130px', float: 'right', textAlign: 'right', padding: '15px' }}>
              //         <RowsDnd {...this.props} orderField='orderId' columns={columns()} dataArr={dataArr} handleReorder={this.handleReorder} />
              //       </div>
              //       <MTableToolbar {...props} />
              //       <div style={{ clear: 'both' }}></div>
              //     </div>
              //   ),
              // }}
              components={{
                Row: props => rowReOrder(props, 'templelevel', 'order', dataArr, 'dataArr', this.handleState)
              }}
              data={dataArr}
              title="身份"
              editable={{
                onRowAdd: newData1 =>
                  new Promise(async (resolve, reject) => {
                    const data = [...dataArr];
                    const db = Parse.Object.extend("templelevel");
                    const newDoc = new db();
                    const doc = await newDoc.save();
                    newData1.objectId = doc.id;
                    newData1.id = doc.id;
                    newData1._id = doc.id;

                    data.push(newData1);

                    this.setState({ dataArr: data, newData1 }, () => resolve());
                  }).then(() => this.handleAdd()),
                onRowUpdate: (newData1, oldData1) =>
                  new Promise((resolve, reject) => {
                    const data = [...dataArr];
                    const index = data.indexOf(oldData1);
                    data[index] = newData1;
                    // console.log(data1)
                    this.setState({ dataArr: data, newData1, oldData1 }, () => resolve());
                  }).then(() => this.handleUpdate()),
                onRowDelete: oldData1 =>
                  new Promise((resolve, reject) => {
                    let data = [...dataArr];
                    const index = data.indexOf(oldData1);
                    data.splice(index, 1);
                    this.setState({ dataArr: data, oldData1 }, () => resolve());
                  }).then(() => this.handleDelete()),
              }}
            />

          </Grid.Column>
        </Grid.Row>
      </Grid>
      {/* <Button onClick={this.handleButton}>輸入</Button> */}
    </div>);
  }
}

export default Identity;
