import React, { Component } from 'react';
import { Form, Container, Grid, Header, Card } from 'semantic-ui-react';
import { showField, showFieldText, getAvailblePeriodArr, getDate, getOptionTextByValue } from 'views/widgets/FieldsRender';
import { Row, Col } from 'reactstrap';

import Parse from 'widget/parse.js'

export default class SupportStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relative: [],
      referRelative: ' ',
      referRelative2: ' '
    };
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');

    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, match } = props;
    const { _id } = match.params;
    const relative = await Parse.queryData('Relative', { companyId: companyObj.objectId, studentId: _id });
    this.setState({ relative })
    // firebase.firestore()
    //   .collection(`Students/${_id}/Relative`)
    //   // .orderBy('relationship')
    //   // .where('eventDate', '==', eventDate).where('classname', '==', className)
    //   .get().then(snap => {
    //     // console.log(snap)
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ relative: data });
    //   }, err => { console.log(`Encountered error: ${err}`); });
  }
  handleChangeSel = (e, { name, value }) => {
    const fieldName = name;
    const fieldValue = value;

    // referRelative: 親族 -> 支付者 ok
    let mappingArr = [
      { from: 'name', to: 'supportName' },
      { from: 'nationality', to: 'supportNationality' },
      { from: 'relationship', to: 'supportRelationship' },
      { from: 'relationshipOther', to: 'supportRelationshipOther' },
      { from: 'birthday', to: 'supportBirthday' },
      { from: 'career', to: 'suportCareer' },
      { from: 'careerOther', to: 'suportCareerOther' },
      { from: 'annualIncome', to: 'supportIncome' },
      { from: 'isLiveTogether', to: 'supportIsLiveTogether' },
      { from: 'residenceQualify', to: 'supportResidenceQualify' },
      { from: 'residenceNum', to: 'supportResidenceNum' },
      { from: 'tel', to: 'supportTel' },
      { from: 'cellphone', to: 'supportCellphone' },
      { from: 'wechat', to: 'supportWechat' },
      { from: 'qq', to: 'supportQq' },
      { from: 'facebook', to: 'supportFacebook' },
      { from: 'email', to: 'supportEmail' },
      { from: 'postcode', to: 'supportPostcode' },
      { from: 'addr', to: 'supportAddr' },
      { from: 'workName', to: 'supportWorkName' },
      { from: 'workTel', to: 'supportWorkTel' },
      { from: 'workEmail', to: 'supportWorkEmail' },
      { from: 'workUrl', to: 'supportWorkUrl' },
      { from: 'workPostcode', to: 'supportWorkPostcode' },
      { from: 'workAddr', to: 'supportWorkAddr' },
    ];

    if (fieldName === 'referRelative' && fieldValue === "fromAgent") {
      // 推薦者 -> 支付者 ok
      mappingArr = [
        { from: 'agentName', to: 'supportName' },
        { from: 'agentNationality', to: 'supportNationality' },
        { from: 'agentRelationship', to: 'supportRelationship' },
        { from: 'agentRelationshipOther', to: 'supportRelationshipOther' },
        { from: 'agentBirthday', to: 'supportBirthday' },
        { from: 'agentResidenceQualify', to: 'supportResidenceQualify' },
        { from: 'agentTel', to: 'supportTel' },
        { from: 'agentCellphone', to: 'supportCellphone' },
        { from: 'agentWechat', to: 'supportWechat' },
        { from: 'agentQq', to: 'supportQq' },
        { from: 'agentFb', to: 'supportFacebook' },
        { from: 'agentEmail', to: 'supportEmail' },
        { from: 'agentPostcode', to: 'supportPostcode' },
        { from: 'agentAddr', to: 'supportAddr' },
        { from: 'agentCoName', to: 'supportWorkName' },
        { from: 'agentCoTel', to: 'supportWorkTel' },
        { from: 'agentCoEmail', to: 'supportWorkEmail' },
        { from: 'agentCoUrl', to: 'supportWorkUrl' },
        { from: 'agentCoPostcode', to: 'supportWorkPostcode' },
        { from: 'agentCoAddr', to: 'supportWorkAddr' },
      ];
    }

    if (fieldName === 'referRelative2' && fieldValue !== "fromSupport" && fieldValue !== "fromAgent") {
      // referRelative2: 親族 -> 共同支付者 ok
      mappingArr = [
        { from: 'name', to: 'coSupportName' },
        { from: 'relationship', to: 'coSupportRelationship' },
        { from: 'relationshipOther', to: 'coSupportRelationshipOther' },
        { from: 'tel', to: 'coSupportTel' },
        { from: 'cellphone', to: 'coSupportCellphone' },
        { from: 'addr', to: 'coSupportAddr' },
        { from: 'workName', to: 'coSupportWorkName' },
        { from: 'workTel', to: 'coSupportWorkTel' },
        { from: 'workPostcode', to: 'coSupportWorkPostcode' },
        { from: 'workAddr', to: 'coSupportWorkAddr' },
      ];
    } else if (fieldName === 'referRelative2' && fieldValue === "fromSupport") {
      // referRelative2: 支付者 -> 共同支付者 ok
      mappingArr = [
        { from: 'supportName', to: 'coSupportName' },
        { from: 'supportRelationship', to: 'coSupportRelationship' },
        { from: 'supportRelationshipOther', to: 'coSupportRelationshipOther' },
        { from: 'supportTel', to: 'coSupportTel' },
        { from: 'supportCellphone', to: 'coSupportCellphone' },
        { from: 'supportAddr', to: 'coSupportAddr' },
        { from: 'supportWorkName', to: 'coSupportWorkName' },
        { from: 'supportWorkTel', to: 'coSupportWorkTel' },
        { from: 'supportWorkPostcode', to: 'coSupportWorkPostcode' },
        { from: 'supportWorkAddr', to: 'coSupportWorkAddr' },
      ];
    } else if (fieldName === 'referRelative2' && fieldValue === "fromAgent") {
      // referRelative2: 推薦者 -> 共同支付者 ok
      mappingArr = [
        { from: 'agentName', to: 'coSupportName' },
        { from: 'agentRelationship', to: 'coSupportRelationship' },
        { from: 'agentRelationshipOther', to: 'coSupportRelationshipOther' },
        { from: 'agentTel', to: 'coSupportTel' },
        { from: 'agentCellphone', to: 'coSupportCellphone' },
        { from: 'agentAddr', to: 'coSupportAddr' },
        { from: 'agentCoName', to: 'coSupportWorkName' },
        { from: 'agentCoTel', to: 'coSupportWorkTel' },
        { from: 'agentCoPostcode', to: 'coSupportWorkPostcode' },
        { from: 'agentCoAddr', to: 'coSupportWorkAddr' },
      ];
    }

    let form = { _id: this.props.doc._id };

    if (fieldValue === 'clear') { // 清空表單
      mappingArr.forEach(({ to }) => {
        const setting = { [to]: '' };
        form = { ...form, ...setting };
      })
    } else if (fieldValue !== "fromSupport" && fieldValue !== "fromAgent") { // 將参照親族資料寫入表單
      const { relative } = this.state;
      const referRelative = relative.find(item => item._id === fieldValue);

      if (referRelative) {
        mappingArr.forEach(({ from, to }) => {
          if (referRelative[from]) {
            const setting = { [to]: referRelative[from] };
            form = { ...form, ...setting };
          }
        });
      }
    } else {
      mappingArr.forEach(({ from, to }) => {
        const setting = { [to]: this.props.doc[from] };
        form = { ...form, ...setting };
      });
    }
    this.props.setRelativeObj(form);
    this.setState({ [fieldName]: fieldValue })
  };

  render() {
    const { optSet, doc, isEdit, agents } = this.props;
    const { relative, referRelative, referRelative2 } = this.state;
    const primaryOptions = relative.sort((a, b) => a.relationship - b.relationship).map(item => ({ key: item._id, value: item._id, text: /*[{getOptionTextByValue(optSet, 'relationship', item.relationship)}]*/ item.name }))
    const primaryOptionsArr = [
      { key: 'sel', text: '-- 選択 --', value: ' ', disabled: false },
      { key: 'clear', text: '-- クリア --', value: 'clear', disabled: false },
      // { key: 'fromSupport', text: `[経費支弁者] ${doc.supportName ? doc.supportName : '(無)'}`, value: '', disabled: doc.supportName ? false : true },
      { key: 'fromAgent', text: `[推薦者] ${doc.agentName ? doc.agentName : '(無)'}`, value: 'fromAgent', disabled: doc.agentName ? false : true },
      { key: 'selRel', text: '=== 親族 ===', value: '', disabled: true },
      ...primaryOptions
    ]
    const secondaryOptionsArr = [
      { key: 'sel', text: '-- 選択 --', value: ' ', disabled: false },
      { key: 'clear', text: '-- クリア --', value: 'clear', disabled: false },
      { key: 'fromSupport', text: `[経費支弁者] ${doc.supportName ? doc.supportName : '(無)'}`, value: 'fromSupport', disabled: doc.supportName ? false : true },
      { key: 'fromAgent', text: `[推薦者] ${doc.agentName ? doc.agentName : '(無)'}`, value: 'fromAgent', disabled: doc.agentName ? false : true },
      { key: 'selRel', text: '=== 親族 ===', value: '', disabled: true },
      ...primaryOptions
    ];

    return (<>
      <Header as='h2' dividing
        content='経費支弁者'
        style={{ backgroundColor: 'lavender' }}
      // subheader='調査時間：2017年4月から 2018年12月 まで'
      />
      {isEdit ? <Form.Select width={4} label='情報参照' defaultValue={referRelative} name="referRelative" onChange={this.handleChangeSel} options={primaryOptionsArr} /> : ''}
      {/* <Grid columns='equal'> */}
      <Row>
        <Col>
          <Card fluid>
            <Card.Content header='基本情報' className='card-title' />
            <Card.Content>
              <Row>
                {/*  <Grid columns='equal'> */}
                <Col>{showField(this.props, "supportName")}</Col>
                <Col>{showField(this.props, "supportNationality")}</Col>
                <Col>{showField(this.props, "supportBirthday")}</Col>
                {/*  </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "supportRelationship")}</Col>
                <Col>{showField(this.props, "supportRelationshipOther")}</Col>
                <Col>{showField(this.props, "supportIsLiveTogether")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "supportResidenceQualify")}</Col>
                <Col>{showField(this.props, "supportResidenceNum")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "suportCareer")}</Col>
                <Col>{showField(this.props, "suportCareerOther")}</Col>
                <Col>{showField(this.props, "supportIncome")}</Col>
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
        </Col>
        <Col>
          <Card fluid>
            <Card.Content header='勤務先' className='card-title' />
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "supportWorkName")}</Col>
                <Col>{showField(this.props, "supportWorkTel")}</Col>
                <Col>{showField(this.props, "supportWorkEmail")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "supportWorkUrl")}</Col>
                <Col width={4}>{showField(this.props, "supportWorkPostcode")}</Col>
                <Col>{showField(this.props, "supportWorkAddr")}</Col>
                {/* <Col md={3}>{showField(this.props, "supportWork")}</Col>supportFax */}
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Card fluid>
            <Card.Content header='連絡先' className='card-title' />
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "supportTel")}</Col>
                <Col>{showField(this.props, "supportCellphone")}</Col>
                <Col>{showField(this.props, "supportWechat")}</Col>
                <Col>{showField(this.props, "supportQq")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "supportFacebook")}</Col>
                <Col>{showField(this.props, "supportEmail")}</Col>
                <Col width={3}>{showField(this.props, "supportPostcode")}</Col>
                <Col>{showField(this.props, "supportAddr")}</Col>
                {/* <Col md={2}>{showField(this.props, "supportFax")}</Col> */}
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
        </Col>
        <Col>
          <Card fluid>
            <Card.Content header='共同支付者' className='card-title' />
            <Card.Content>
              {isEdit ? <Form.Select width={6} label='共同支付者 情報参照' defaultValue={referRelative2} name="referRelative2" onChange={this.handleChangeSel} options={secondaryOptionsArr} /> : ''}
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "coSupportRelationship")}</Col>
                <Col>{showField(this.props, "coSupportRelationshipOther")}</Col>
                <Col>{showField(this.props, "coSupportName")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "coSupportTel")}</Col>
                <Col>{showField(this.props, "coSupportCellphone")}</Col>
                <Col>{showField(this.props, "coSupportAddr")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "coSupportWorkName")}</Col>
                <Col>{showField(this.props, "coSupportWorkTel")}</Col>
                <Col>{showField(this.props, "coSupportWorkPostcode")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "coSupportWorkAddr")}</Col>
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
        </Col>
      </Row>
      {/* </Grid> */}
    </>);
  }
}
