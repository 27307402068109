
import React, { Component } from 'react'
import { Container, Header, Modal, Button, Table, Form, Grid, Image, Input, Checkbox, Divider, Tab } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import localization from 'widget/MaterialTableOpt'
import { mgyearOpt, monthOpt, exportPdf } from 'views/web/CustomMaterialTable';
import ModalUpdateFile from 'views/web/ModalUpdateFile';
import { RowImage } from '../web/RowImage';
import ColorPicker from './ColorPicker';
import Parse from 'widget/parse'

// reactstrap components

const columns = () => ([
  // { width: 40, title: '代號', field: 'code', lookup: code },
  {
    width: 50, title: '', field: 'img0sel', filtering: false, headerStyle: { textAlign: 'center' }, render: rowData => <></>,
    editComponent: props => <ModalUpdateFile isUploadOri={true} fieldname='img1File' rowData={props.rowData} onRowDataChange={props.onRowDataChange} />
  },
  { width: 120, title: '圖片', field: 'img1File', filtering: false, render: rowData => <RowImage rowData={rowData} field='img1File' />, editComponent: props => <RowImage rowData={props.rowData} field='img1File' /> },
  { width: 80, title: '標題', field: 'title' },
  {
    width: 80, title: '背景顏色', field: 'boxBgColor', render: rowData => <div style={{ backgroundColor: `${rowData.boxBgColor}` }}>Color</div>,
    editComponent: props => <ColorPicker field='boxBgColor' rowData={props.rowData} onRowDataChange={props.onRowDataChange} />
  },
  { width: 80, title: '文字', field: 'text' },
  { width: 80, title: '回傳文字', field: 'actionText' },
]);

class LineNews extends Component {
  state = {
    dataArr: []
  }
  async componentDidMount() {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const dataArr = await Parse.queryData('lineNews', { companyId });
    this.setState({ dataArr });
  }

  handleAdd = async () => {
    const { newData } = this.state;
    await Parse.saveData('lineNews', newData);
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    await Parse.saveData('lineNews', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    await Parse.deleteData('lineNews', oldData);
    this.setState({ oldData: {} })
  }
  render() {
    const { companyObj } = this.props
    const { dataArr } = this.state
    return (<div className="content">
      <Header as='h2'>
        最新消息
        {/* <Header.Subheader>您可建立具有視覺效果的互動型選單，於聊天室中提供優惠券、網址連結及相關行銷資訊以進行宣傳。</Header.Subheader> */}
      </Header>
      <MaterialTable
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '公司一覽'),
          pageSize: 5,
          search: true,
          tableLayout: 'fixed',
        }}
        data={dataArr}
        title="查詢結果"
        // actions={[
        //   {
        //     icon: 'view_headline',
        //     tooltip: '查看',
        //     onClick: (event, rowData) => this.handleData(rowData)
        //   }
        // ]}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const data = [...dataArr];
              const db = Parse.Object.extend("lineNews");
              const newDoc = new db();
              const doc = await newDoc.save();
              // const id = Parse.generateObjectId()
              newData.objectId = doc.id;
              newData.id = doc.id;
              newData._id = doc.id;
              newData.bgId = companyObj.webtypeId
              newData.companyId = companyObj.objectId


              data.push(newData);
              // handleState('people0', data);
              this.setState({ dataArr: data, newData }, () => resolve());
            }).then(() => this.handleAdd(0, 0)),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...dataArr];
              const index = data.indexOf(oldData);
              data[index] = newData;
              // handleState('people0', data);
              this.setState({ dataArr: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate(0)),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...dataArr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              // handleState('people0', data);
              this.setState({ dataArr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete(0)),
        }}
      />
    </div>);
  }
}

export default LineNews;