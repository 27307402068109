
import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

const columns = () => ([
  { title: '位置', field: 'sideId', lookup: { '1': '東', '2': '西' } },
  { title: '編號', field: 'number' },
  { title: '供奉陽上人', field: 'livename' },
  { title: '供奉亡者', field: 'passname' },
  { title: '備註', field: 'ps' },
]);

class Cardslot extends Component {
  state = {
    dataArrOri: [],
    dataArr: [],
    loading: false,
    companyArr: [],
    userObj: {},
    userObj2: {},
    newData: {},
    oldData: {},
    newData2: {},
    oldData2: {},
    companyObj: [],
    rowData: {}
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, familyId } = props;
    const companyId = companyObj.objectId;
    const data = await Parse.queryData("cardslot", { companyId })
    this.setState({ dataArr: data.sort((a, b) => a.order - b.order), loading: false })
  }

  render() {
    // const { companyObj } = this.props;
    const { dataArr, loading, rowData } = this.state;

    return (<div className="content">
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          pageSize: 5,
          search: true,
          searchFieldStyle: {
            width: 90,
          }
        }}
        data={dataArr}
        // actions={[
        //   {
        //     icon: 'view_headline',
        //     tooltip: '查看',
        //     onClick: (event, rowData) => this.handleData(rowData)
        //   }
        // ]}
        // onRowClick={(event, rowData) => this.handleData(rowData)}
        title={'所有永久牌位'}
      // editable={{
      //   onRowAdd: newData =>
      //     new Promise((resolve, reject) => {
      //       // const db = firebase.firestore();
      //       // const docRef = db.collection(`company/${companyObj._id}/cardslot`).doc();
      //       const data = [...dataArr];

      //       const obj = {
      //         // _id: docRef.id,
      //         // id: docRef.id,
      //         // ...newData,
      //         // createdDate: date,
      //         // createdAt: new Date(),
      //       }
      //       data.push(obj);
      //       this.setState({ dataArr: data, newData: obj }, () => resolve());
      //     }).then(() => this.handleAdd()),
      //   onRowUpdate: (newData, oldData) =>
      //     new Promise((resolve, reject) => {
      //       const data = [...dataArr];
      //       const index = data.indexOf(oldData);
      //       data[index] = newData;

      //       const obj = {
      //         ...newData,
      //         // updatedDate: date,
      //         // updatedAt: new Date(),
      //       }
      //       this.setState({ dataArr: data, newData: obj, oldData }, () => resolve());
      //     }).then(() => this.handleUpdate()),
      //   onRowDelete: oldData =>
      //     new Promise((resolve, reject) => {
      //       let data = [...dataArr];
      //       const index = data.indexOf(oldData);
      //       data.splice(index, 1);
      //       this.setState({ dataArr: data, oldData }, () => resolve());
      //     }).then(() => this.handleDelete()),
      // }}
      />
      <br />
      {/* {rowData && rowData.number ?
        <Segment>
          <Form>
            <Form.Group widths='equal'>
              <Form.Select fluid label='位置' name='sideId' value={rowData.sideId} onChange={this.handleChange} />
              <Form.Input fluid label='編號' name='number' value={rowData.number} onChange={this.handleChange} />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Input fluid label='供奉陽上人' name='livename' value={rowData.livename} onChange={this.handleChange} />
              <Form.Input fluid label='供奉亡者' name='passname' value={rowData.passname} onChange={this.handleChange} />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Input fluid abel='備註' name='ps' value={rowData.ps} onChange={this.handleChange} />
            </Form.Group>
            <Form.Button color='blue' onClick={this.handleSaveCardslot}>儲存</Form.Button>
          </Form>
        </Segment>
        : null}
      <br /> */}
      <br />
    </div>);
  }
}

export default Cardslot;
