import React, { Component } from 'react'
import { Container, Header, Button, Form } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

const commaToNumber = (val) => {
  if (!val) return "";
  val = val.toString().replace(/(\d+),(?=\d{3}(\D|$))/g, "$1");
  return parseFloat(val);
}

const columns = (handleToPage, loading, auth) => ([
  { title: '單號', field: 'uid' },
  { title: 'Name', field: 'name_cht' },
  { title: '規劃繳費日期', field: 'pmr_payment1date' },
  { title: '規劃繳費金額', field: 'pmr_payment1money' },
  { title: '單號', field: 'account_num' },
  { title: '備註', field: 'pmr_ps' },
  {
    title: '寄件', field: 'addTime', render: rowData => {
      return (rowData.email ? <Button
        name='send'
        size='mini'
        loading={loading}
        content='前往寄件'
        rowData={rowData}
        disabled={!auth.edit}
        onClick={handleToPage}
      /> : null)
    }
  },
  {
    title: '追蹤', field: 'addTime', render: rowData => {
      return (<Button
        name='track'
        size='mini'
        loading={loading}
        content='前往追蹤'
        data={rowData}
        disabled={!auth.edit}
        onClick={handleToPage}
      />)
    }
  },
]);

export default class After4 extends Component {
  state = {
    loginUser: this.props.user.profile.toJSON(),
    productType2Opt: [{ key: 'productType20', text: '無', value: 'productType20' }],
    productType3Opt: [{ key: 'productType30', text: '無', value: 'productType30' }],
    productType4Opt: [{ key: 'productType40', text: '無', value: 'productType40' }],
    disabled: true,
    disabled2: true,
    disabled3: true,
    type1: '',
    type2: '',
    value1: 'productType10',
    value2: 'productType20',
    value3: 'productType30',
    value4: 'productType40',
    dataArr: [],
  }

  componentDidMount() {
    const { loginUser } = this.state
    const { companyObj } = this.props
    const authPage = window.location.pathname.split('/')[2]
    const authUser = companyObj.objectId + '_' + authPage
    let auth = {}
    let isAgent = ''

    if (loginUser.authAdminA && loginUser.authAdminA.indexOf(companyObj.objectId) === -1 && loginUser.role && loginUser.role.value === '1') {
      isAgent = loginUser.objectId
    }

    if ((loginUser.authAdminA && loginUser.authAdminA.indexOf(companyObj.objectId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
      auth = {
        edit: true,
      }
    } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
      auth = {
        edit: false,
      }
    } else {
      auth = {
        edit: false,
      }
    }
    this.setState({ auth, isAgent }, () => this.handleFetch())
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }
  handleFetch = async (props = this.props) => {
    const { isAgent } = this.state
    const { companyObj } = props;
    const companyId = companyObj.objectId;
    let portfolios
    if (isAgent) {
      portfolios = await Parse.queryData('portfolios', { companyId, agent_id: isAgent })
    } else {
      portfolios = await Parse.queryData('portfolios', { companyId, })
    }
    const paymentRecord = await Parse.queryData('paymentRecord', { companyId })
    let nowyear = new Date().getFullYear()
    const nowPaymentRecord = paymentRecord.filter(item => new Date(item.pmr_payment1date).getFullYear() === nowyear || new Date(item.pmr_payment1date).getFullYear() === nowyear + 1)
    const handleStatus = (pmr_payment1money, pmr_payment2money) => {
      let status_text = "未繳";
      if (!pmr_payment2money) {
        status_text = "未繳";
      } else if (!!pmr_payment2money) {
        if (commaToNumber(pmr_payment2money) === "0") {
          status_text = "展延";
        } else if (commaToNumber(pmr_payment2money) < commaToNumber(pmr_payment1money)) {
          status_text = "部分繳";
        } else if (commaToNumber(pmr_payment2money) === commaToNumber(pmr_payment1money)) {
          status_text = "已繳";
        } else if (commaToNumber(pmr_payment2money) > commaToNumber(pmr_payment1money)) {
          status_text = "溢繳";
        }
      }
      return status_text;
    }

    const newDataArr = []
    nowPaymentRecord.forEach(item => {
      if (portfolios.find(item2 => item2.objectId === item.portfoliosId)) {
        let status = ''
        status = handleStatus(item.pmr_payment1money, item.pmr_payment2money)
        if (status === "部分繳" || status === "未繳" || status === "展延") {
          const porData = portfolios.find(item2 => item2.objectId === item.portfoliosId)
          let obj = {
            ...item,
            ...porData,
            status
          }
          newDataArr.push(obj)
        }
      }
    })
    const dataArr = newDataArr.sort((a, b) => new Date(a.pmr_payment1date) - new Date(b.pmr_payment1date))
    this.setState({ dataArr, paymentRecord, portfolios })
  }

  handleToPage = async (e, { name, value, data, rowData }) => {
    const { history, companyObj } = this.props
    if (name === 'send') {
      if (rowData && rowData.email) {
        console.log(rowData.email)
        history.push(`/admin/After1/${rowData.email}`);
      } else {
        window.alert('無信箱')
      }
    } else if (name === 'track') {
      if (window.confirm('確認新增案件追蹤？')) {
        this.setState({ loading: true })
        const db = Parse.Object.extend("afterservice");
        const newDoc = new db();
        const doc = await newDoc.save();
        let obj = {
          // ...data,
          id: doc.id,
          _id: doc.id,
          objectId: doc.id,
          companyId: companyObj.objectId,
          companyName: companyObj.name,
          client_id: data.client_id || '',
          portfolio_id: data.portfoliosId || '',
          name_cht: data.name_cht || '',
          name_eng: data.name_eng || '',
          uid: data.uid || '',
          as_template_id: 'Nhx2ZDsK7D2dAnmpe',
          as_template_text: 'Voya保費繳費提醒',
          email: data.email || '',
          status: "1",
          status_text: "申請中",
          as_action: "2",
          as_action_owner: "4",
        };
        console.log(obj)
        await Parse.saveData('afterservice', obj);
        window.alert('已新增案件追蹤')
        history.push(`/admin/After3`);
      }
    }
  }


  render() {
    const { loading, dataArr, auth } = this.state;

    return (<div className="content">
      {/* <div style={{ ...style.flexCenter, margin: '2rem auto' }}>
          <Header as='h2' style={{ margin: 0 }}>2-3 繳費提醒</Header>
          <div style={{ ...style.flex, alignItems: 'flex-end' }}>
            <Button content='寄案件追蹤給Agent' />
            <Form><Form.Select options={[]} label='Agent' /></Form>
          </div>
        </div> */}
      <MaterialTable
        localization={localization()}
        columns={columns(this.handleToPage, loading, auth)}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data),
          pageSize: dataArr.length || 10,
          search: false,
        }}
        data={dataArr}
        title=""
      />
      <br />
      <br />
    </div>)
  }
}