import React, { Component } from 'react'
import { Header, Grid, Divider, Button, List, Segment, Form } from 'semantic-ui-react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

export default class ColorPicker extends Component {
  state = {
    displayColorPicker: false,
    color: {
      r: '',
      g: '',
      b: '',
      a: '',
    }
  }
  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color) => {
    const { onRowDataChange, rowData } = this.props
    this.setState({ colorhex: color.hex, color: color.rgb })
    onRowDataChange({ ...rowData, 'boxBgColor': color.hex })
  };

  render() {
    const { field, handleChange, handleChangeColorComplete } = this.props
    const { displayColorPicker, colorhex } = this.state
    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {displayColorPicker ? <div style={styles.popover}>
          <div style={styles.cover} onClick={this.handleClose} />
          <SketchPicker color={this.state.color} onChange={this.handleChange} />
        </div> : null}
        <Form.Input name={field} fluid value={colorhex} onChange={this.handleChange} />
      </div>)
  }
}

