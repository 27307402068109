import React, { Component } from 'react'
import { Container, Icon, Button, Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
import MaterialTable, { MTableToolbar } from 'material-table'
// import Lightbox from 'react-image-lightbox';
// import Carousel, { Modal, ModalGateway } from 'react-images';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ModalUploadFiles from 'views/web/ModalUploadFiles';
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

const columns = () => ([
  { title: '預覽', field: 'src', render: rowData => <a href={rowData && rowData.theFile && rowData.theFile.url} target="_blank" rel="noopener noreferrer">{rowData.name}</a> },
  { title: '名稱', field: 'filename' },
]);

export default class List4Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      content: '',
      filenames: [],
      downloadURLs: [],
      docs: [],
      fileArr: [],
      fileArrCheck: [],
      loading: true,
    };
  }

  fileInputRef = React.createRef();

  componentDidMount() {
    this.handleFetch(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { deleteAllFiles, history, refetch, companyObj } = nextProps
    const { doc } = this.props
    const { docs } = this.state
    if (deleteAllFiles) {
      if (docs.length > 0) {
        for (let i = 0; i < docs.length; i++) {
          Parse.deleteData('clientFile', docs[i])
        }
        // })
      }
      window.alert('刪除成功')
      nextProps.handleIsNotEdit()
    } else {
      this.handleFetch(nextProps);
    }
  }

  handleFetch = async (props = this.props) => {
    const { match, history, doc, companyObj } = props;
    const companyId = companyObj.objectId;
    this.setState({ loading: true })
    const clientFile = await Parse.queryData('clientFile', { companyId, clientId: doc.objectId, portfolioId: undefined })
    console.log(clientFile)
    this.setState({ loading: false, docs: clientFile, clientFile })
  }

  handleDelete = async () => {
    const { oldData } = this.state
    // const { match, doc } = this.props;
    try {
      Parse.deleteData('clientFile', oldData)
      this.setState({ oldData: {} })
    } catch (error) {
      console.log("handled by outer try-catch", error);
    }
  }

  handleUploadStart = () => this.setState({ isUploading: true, });

  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleFilesChange = async (e) => {
    const fileArr = e.target.files;
    console.log(fileArr)
    if (!fileArr || !fileArr.length) {
      return
    }
    const { companyObj, doc } = this.props
    const promises = [];
    for (let i = 0; i < fileArr.length; i++) {
      const file = fileArr[i];
      promises.push(Parse.putFile(file));
    }
    const theFiles = await Promise.all(promises).catch(error => console.log("error", error));

    const promises2 = [];
    theFiles.forEach(async (theFile) => {
      const db = Parse.Object.extend("clientFile");
      const newDoc = new db();
      const docRef = await newDoc.save();

      const element = {
        objectId: docRef.id,
        id: docRef.id,
        _id: docRef.id,
        companyId: companyObj.objectId,
        companyName: companyObj.name,
        clientId: doc.objectId,
        theFile
      };
      promises2.push(Parse.saveData('clientFile', element));
    })
    await Promise.all(promises2).catch(error => console.log("error", error)).then(() => this.handleFetch());
    // this.handleFetch()
    window.alert('上傳成功')
  }

  render() {
    const { docs, loading, fileArr, fileArrCheck } = this.state
    const { doc, auth } = this.props
    return (
      <Container>
        <MaterialTable
          isLoading={loading}
          components={{
            Toolbar: props => (<div>
              <div style={{ width: '220px', float: 'right', textAlign: 'right', padding: '15px' }}>

                {/* <Button as='label'
                    color='orange'
                    size='small'
                    content={<>選擇檔案（多選)</>}
                    labelPosition="left"
                    icon={<Icon name='arrow alternate circle up' />}
                  /> */}
                {auth.edit ?
                  <ModalUploadFiles {...this.props} isImage='0' handleData={this.handleFetch} dbName='clientFile' fieldName='theFile' condition={{ clientId: doc.objectId }} />
                  : null}
              </div>
              <MTableToolbar {...props} />
              <div style={{ clear: 'both' }}></div>
            </div>),
          }}
          localization={localization()}
          columns={columns()}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportAllData: true,
            exportPdf: (columns, data) => exportPdf(columns, data,),
            pageSize: 5,
            search: false
          }}
          // data2={bankaccSel}
          data={docs}
          title=''
          editable={{
            isDeleteHidden: () => !auth.edit,
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                let data = docs;
                const index = data.indexOf(oldData);
                data.splice(index, 1);
                // handleState('people0', data);
                this.setState({ docs: data, oldData }, () => resolve());
              }).then(() => this.handleDelete()),
          }}
        />
      </Container >
    )
  }
}