import React, { Component } from 'react'
import { Form } from 'semantic-ui-react';

import PrintTabs from './PrintTabs';

const strategy = [
  { value: '0', description: '即時更新' },
  { value: '1', description: '一覧表顯示' },
  { value: '5', description: '滿版顯示' },
  { value: '2', description: '程式碼 顯示' },
  { value: '7', description: '程式碼 加高' },
  { value: '4', description: 'PDFプレビュ—自動ログイン' },
  { value: '6', description: 'PDFプレビュ—加高' },
  { value: '3', description: '筆記顯示' },
];

export default class PrintPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: ['1', '2', '4'],
    };
  }

  onChangeFilter = (e) => {
    const { filter } = this.state;
    let tempFilter = filter;
    const value = e.target.value
    const index = filter.indexOf(value);

    if (index === -1) {
      tempFilter.push(value);
      this.setState({ filter: tempFilter });
    } else {
      tempFilter.splice(index, 1);
      this.setState({ filter: tempFilter });
    }
  };

  render() {
    const { filter } = this.state;

    return (<div className='content'>
      <PrintTabs {...this.props} showForm={filter} />
      <Form>
        <Form.Group grouped>
          {strategy.map(item => <Form.Field
            key={item.value}
            type='checkbox'
            value={item.value}
            onChange={this.onChangeFilter}
            checked={filter && filter.indexOf(item.value) !== -1}
            label={item.description}
            control='input' />)}
        </Form.Group>
      </Form>
    </div>);
  }
};
