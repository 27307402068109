import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select, Icon } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import Moment from 'moment';

import { Row, Col } from "reactstrap";
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, timeCol, monthOpt, exportPdf } from 'views/web/CustomMaterialTable';
const typeOpt = [
  { text: '本日訂單', value: 'today', key: '1' },
  { text: '全部訂單', value: 'all', key: '2' },
]
const columns = (employeeObj) => ([
  { width: 80, title: '明細', field: 'view' },
  { width: 80, title: '訂單時間', field: 'orderDateText' },
  { width: 80, title: '單號', field: 'orderNum' },
  { width: 80, title: '桌號', field: 'tableName' },
  timeCol({ width: 100, title: '開桌時間', field: 'openTime' }),
  timeCol({ width: 100, title: '關桌時間', field: 'closeTime' }),
  { width: 80, title: '開桌人數', field: 'openChair' },
  // { width: 80, title: '餐費', field: 'totalFoodPrice' },
  { width: 80, title: '應收桌費', field: 'totalTablePrice' },
  { width: 80, title: '應收餐點', field: 'totalDrinkPrice' },
  // { width: 80, title: '合計', field: 'totalPrice' },
  { width: 80, title: '實付', field: 'receivedTotalPrice' },
  // { width: 80, title: '經手人', field: 'openStaffStr' },
  { width: 80, title: '備註', field: 'ps' },
]);

const d = new Date()
class CrewAllorder extends Component {
  state = {
    activeItem: 'bio',
    dataArr: [],
    typeSel: 'today',
    // loading: true
  }
  componentDidMount = () => {
    this.handleFetch()
  }

  handleFetch = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data1 = await Parse.queryData('ordering1', { companyId, isOpen: '0', orderDateText: Moment(d).format('YYYY-MM-DD') });
    const dataArr = data1.map(item => ({
      ...item,
      totalFoodPrice: item.totalFoodPrice || 0,
      openTime: Moment(new Date((item.openTime && item.openTime.iso) || item.openTime)).format('YYYY-MM-DD hh:mm:ss'),
      closeTime: (item.closeTime && Moment(new Date((item.closeTime && item.closeTime.iso) || item.closeTime)).format('YYYY-MM-DD hh:mm:ss')) || '',
      totalPrice: item.totalFoodPrice || 0 + item.totalDrinkPrice || 0 + item.totalTablePrice || 0
    }))
    this.setState({ dataArr, loading: false })
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value }, () => this.hanldeFilter(value))
  }

  hanldeFilter = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const { typeSel } = this.state
    if (typeSel === 'today') {
      const data1 = await Parse.queryData('ordering1', { companyId, isOpen: '0', orderDateText: Moment(d).format('YYYY-MM-DD') });
      this.setState({ dataArr: data1, loading: false })
    } else {
      const data1 = await Parse.queryData('ordering1', { companyId, isOpen: '0', });
      this.setState({ dataArr: data1, loading: false })
    }
  }

  render() {
    const { companyObj } = this.props
    const { dataArr, loading, typeSel } = this.state

    return (<div className="content">
      <Header as='h2' inverted={companyObj.inverted}>訂單</Header>
      <Select name="typeSel" style={{ position: 'relative', zIndex: 99, marginRight: '8px' }} options={typeOpt} value={typeSel} onChange={this.handleChange} />
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '訂單'),
          pageSize: 10,
          search: false,
          tableLayout: 'fixed'
        }}
        tableRef={this.tableRef}
        data={dataArr}
        title="訂單"
      />
    </div >);
  }
}

export default CrewAllorder;