import React, { Component } from 'react'
import { Select, Input } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Moment from 'moment';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { exportPdf, dateTimeCol } from 'views/web/CustomMaterialTable';
import data from 'widget/pdfCompany/coreTemple';


const columns = () => ([
  { width: 80, title: '工號', field: 'jobNum' },
  { width: 80, title: '打卡人', field: 'name' },
  dateTimeCol({ title: '上班時間', field: 'onWorkAt' }),
  dateTimeCol({ title: '下班時間', field: 'offWorkAt' }),
  { width: 80, title: '上班時數', field: 'workTime' },
  { width: 80, title: '薪水', field: 'salary' },
  { width: 80, title: '出勤狀況', field: 'situation' },
  // { title: '預估收入', field: 'income', ...typeCurrency },
  // { title: '預估支出', field: 'outcome', ...typeCurrency },
  // { title: '備註', field: 'ps' }
]);

export default class ClockManage extends Component {
  state = {
    loginUser: this.props.user.profile.toJSON(),
    bankaccArr: [],
    dataArr: [],
    predictArr: [],
    deleteConfirm: false,
    loading: true,
    bankaccSel: '',
    preBalance: '',
    nextBalance: '',
    preBalanceArr: [],
    nextBalanceArr: [],
    monthSel: Moment(new Date()).format('YYYY-MM') || '',
  }

  async componentDidMount() {
    this.handleFetch()
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props, type = 1) => {
    const { loginUser, monthSel } = this.state
    const { companyObj } = this.props
    const [year, month] = monthSel.split('-');
    // const list = await Parse.queryData('attendanceList', { companyId: companyObj.objectId })
    const list = await Parse.queryData('attendanceList', { companyId: companyObj.objectId, userId: loginUser.objectId, year, month }, { orderBy: 'createdAt_descend' })
    let total = 0
    let salary = 0
    list.forEach(item => {
      total = total + item.workTime
      salary = salary + item.salary
    })
    list.unshift({ name: '總計', workTime: total, salary })
    this.setState({ dataArr: list, loading: false })
  }

  handleAdd = async () => {
    const { newData, monthSel } = this.state
    const { companyObj } = this.props
    const [year, month] = monthSel.split('-');

    await Parse.saveData('attendanceList', newData);
  }

  handleUpdate = async () => {
    const { newData, monthSel } = this.state
    const { companyObj } = this.props
    const [year, month] = monthSel.split('-');

    await Parse.saveData('attendanceList', newData);
  }

  handleDelete = async () => {
    const { oldData, monthSel, bankaccSel } = this.state
    const [year, month] = monthSel.split('-');
    if (!year || !month) {
      return;
    }
    if (oldData.showFlag === 0) {
      this.setState({ loading: false })
      return console.log("OO");
    } else {
      await Parse.deleteData('predict', oldData);
      this.handleCalculate()
      this.setState({ oldData: {} })
    }
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    this.setState({ [name]: value, loading: true }, () => this.handleFetch(this.props, 0));
  }

  render() {
    const { companyObj } = this.props
    const { dataArr, loading, bankaccArr, bankaccSel, monthSel, predictArr, loginUser } = this.state;
    const [year, month] = monthSel.split('-') || ['', ''];
    let bankaccName = ''
    bankaccArr && bankaccSel ? bankaccName = bankaccArr.find(item => item.value === bankaccSel).text : bankaccName = '無資料'

    const editable = {
      onRowAdd: newData =>
        new Promise(async (resolve, reject) => {
          const db = Parse.Object.extend("attendanceList");
          const newDoc = new db();
          const docRef = await newDoc.save();
          let now = newData.onWorkAt
          let year = String(now.getFullYear())
          let month = ''
          if ((now.getMonth() + 1) < 10) {
            month = '0' + String(now.getMonth() + 1)
          } else {
            month = String(now.getMonth() + 1)
          }
          // let situation = ''
          // if (now.getHours() <= 9 || (now.getHours() === 9 && now.getMinutes() <= 30)) {
          //   situation = '準時'
          // } else if ((now.getHours() === 9 && now.getMinutes() > 30) || now.getHours() > 9) {
          //   situation = '遲到'
          // }

          let onWorkTime = now.getTime() //計算上班時數
          let offWorkTime = newData.offWorkAt.getTime()
          let workTime = offWorkTime - onWorkTime
          // let milliseconds = parseInt((workTime % 1000) / 100),
          // seconds = Math.floor((workTime / 1000) % 60),
          // minutes = Math.floor((workTime / (1000 * 60)) % 60),
          let hours = Math.floor((workTime / (1000 * 60 * 60)));
          // hours = (hours < 10) ? "0" + hours : hours;
          // minutes = (minutes < 10) ? "0" + minutes : minutes;
          // seconds = (seconds < 10) ? "0" + seconds : seconds;
          // let str = hours + ":" + minutes + ":" + seconds + "." + milliseconds;

          let salary = hours * 158


          const data = [...dataArr];
          const obj = {
            ...newData,
            _id: docRef.id,
            id: docRef.id,
            objectId: docRef.id,
            companyId: companyObj.objectId,
            companyName: companyObj.name,
            userId: loginUser.objectId,
            name: loginUser.name || '',
            jobNum: loginUser.jobNum || '',
            situation: '',
            // onWorkAt: now,
            salary,
            year,
            month,
          }
          data.shift();
          data.unshift(obj);
          this.setState({ dataArr: data, newData: obj, loading: true }, () => resolve());
        }).then(() => this.handleAdd()),
      onRowUpdate: (newData, oldData) =>
        new Promise(async (resolve, reject) => {
          let obj = {}
          if (newData.objectId && newData.showFlag === 0) {
            obj = {
              ...newData
            }
          } else if (newData.showFlag === 0) {
            const db = Parse.Object.extend("balance");
            const newDoc = new db();
            const docRef = await newDoc.save();
            obj = {
              ...newData,
              id: docRef.id,
              _id: docRef.id,
              objectId: docRef.id,
              companyId: companyObj.objectId || '',
              companyName: companyObj.name || '',
              bankaccId: bankaccSel || '',
              bankaccName,
              year,
              month,
              monthSel
            }
          } else {
            obj = {
              ...newData
            }
          }
          const data = [...dataArr];
          const index = data.indexOf(oldData);
          data[index] = obj;
          data.pop();
          this.setState({ dataArr: data, newData: obj, oldData, loading: true }, () => resolve());
        }).then(() => this.handleUpdate()),
      onRowDelete: oldData =>
        new Promise((resolve, reject) => {
          let data = [...dataArr];
          const index = data.indexOf(oldData);
          data.splice(index, 1);
          data.pop();
          this.setState({ dataArr: data, oldData, loading: true }, () => resolve());
        }).then(() => this.handleDelete()),
    }


    return (<>
      <div className="content">
        {/* 資金需求預估表 */}
        <br />
        <Input
          type="month"
          name="monthSel"
          value={monthSel}
          onChange={this.handleChange}
        />


        <br />
        <br />
        {!year ? null : <>
          <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns()} options={{
              addRowPosition: "first",
              exportButton: true,
              exportPdf: (columns, data) => exportPdf(columns, data, `${year} 年 ${month} 月 ${bankaccName} 資金預估表`),
              pageSize: predictArr.length || 10,
              search: false
            }}
            data={dataArr}
            title={`打卡記錄`}
            editable={editable}
          />
        </>}
        <br />
        <br />
      </div>
    </>)
  }
}