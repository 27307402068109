import React from 'react'
import { Form } from 'semantic-ui-react';

export const FormRadio = ({ label, id, value, onChange }) => (<Form.Group key={'dd' + id} inline>
  <Form.Radio label={label} value={id} checked={value === id} name='type' onChange={onChange} />
</Form.Group>)

export const isHas = { '-1': '有', '0': '無' }

// const daoPraySel = { '1': '超渡', '2': '斗燈', '3': '祈福', '4': '法會', '5': '光明燈' }

export const columns1 = (praytypeSel = {}) => ([
  { width: 60, title: '年度', field: 'year', editable: 'never' },
  { width: 120, title: '單號', field: 'ordernum', editable: 'never' },
  { width: 100, title: '法會名稱', field: 'pray1Text', editable: 'never' },
  { width: 60, title: '法會項目', field: 'pray2Text', editable: 'never' },
  { width: 60, title: '列印順序', field: 'pray2OrderId', editable: 'never' },
  { width: 60, title: '牌位種類', field: 'praytypeId', lookup: praytypeSel, editable: 'never' },
  { width: 120, title: '牌位種類(字)', field: 'praytypeText', editable: 'never' },
  { width: 60, title: '編號', field: 'serial' },
  // { width: 60, title: '外加價錢', field: 'rowTotal', editable: 'never' },
  { width: 160, title: '陽上', field: 'livename' },
  { width: 100, title: '拔', field: 'passname' },
  { width: 100, title: '拔 歷代祖先', field: 'passname1' },
  { width: 100, title: '拔 冤親債主', field: 'passname2' },
  { width: 100, title: '拔 地基主', field: 'passname3' },
  { width: 100, title: '拔 嬰靈', field: 'passname4' },
  { width: 200, title: '地址', field: 'addr' },
  // { title: '建立時間', field: 'createdAt', render: rowData => (rowData.createdAt && rowData.createdAt['seconds'] && Moment(new Date(rowData.createdAt['seconds'] * 1000)).format('YYYY-MM-DD HH:mm:ss')) || '' },
  // { width: 140, title: '建立時間', editable: 'never', render: rowData => (rowData && rowData.createdAt && rowData.createdAt.toDate && Moment(rowData.createdAt.toDate()).format('YYYY-MM-DD HH:mm:ss')) },
  { width: 140, title: '建立時間', editable: 'never', field: 'createdAt', type: 'datetime' },
  // { width: 140, title: '修改時間', editable: 'never', render: rowData => (rowData && rowData.createdAt && rowData.updatedAt.toDate && Moment(rowData.updatedAt.toDate()).format('YYYY-MM-DD HH:mm:ss')) },
  { width: 140, title: '修改時間', editable: 'never', field: 'updatedAt', type: 'datetime' },
  // { title: '修改時間', field: 'updatedAt' },
]);

export const columns2 = () => ([
  { width: 60, title: '年度', field: 'year', editable: 'never' },
  { width: 60, title: '單號', field: 'ordernum', editable: 'never' },
  { width: 60, title: '名字', field: 'peopleName' },
  // { title: '生辰', field: 'lunar_birth_text', render: rowData => (rowData && rowData.createdAt && rowData.createdAt.toDate && Moment(rowData.createdAt.toDate()).format('YYYY-MM-DD HH:mm:ss')) },
  // { title: '時辰', field: 'lunar_time_text' },
  { width: 60, title: '光明燈', field: 'p1', lookup: isHas, editable: 'never' },
  { width: 60, title: '第二位', field: 'p1Name2', editable: 'never' },
  // { title: '闔家', field: 'p1_isallfamily' },
  { width: 60, title: '燈號', field: 'p1Num' },
  { width: 60, title: '藥師燈', field: 'p3', lookup: isHas, editable: 'never' },
  { width: 60, title: '其他位', field: 'p3Name2', editable: 'never' },
  { width: 60, title: '燈號', field: 'p3Num' },
  { width: 60, title: '光明大燈', field: 'p5', lookup: isHas, editable: 'never' },
  { width: 60, title: '其他位', field: 'p5Name2', editable: 'never' },
  { width: 60, title: '燈號', field: 'p5Num' },
  { width: 60, title: '藥師大燈', field: 'p7', lookup: isHas, editable: 'never' },
  { width: 60, title: '其他位', field: 'p7Name2', editable: 'never' },
  { width: 60, title: '燈號', field: 'p7Num' },
  { width: 60, title: '地址', field: 'addr' },
  { width: 60, title: '建立時間', editable: 'never', field: 'updatedAt', type: 'datetime' },
]);

export const columns3 = (templeclass = {}, templelevel = {}, form = { mailThis1: true, post5code1: true, postcode1: true, addr1: true, cellphone1: true, telephone1: true, email1: true, templeClass1: true, templeLevel1: true, memberId1: true, longLive1: true, lotus1: true, lotus21: true }) => ([
  { width: 80, title: '姓名', field: 'name' },
  { width: 50, title: '郵寄', field: 'mailThis', hidden: !form.mailThis1, lookup: { '0': '否', '-1': '是' } },
  { width: 80, title: '郵遞區號5碼', field: 'post5code', hidden: !form.post5code1 },
  { width: 80, title: '郵遞區號', field: 'postcode', hidden: !form.postcode1 },
  { width: 120, title: '地址', field: 'addr', cellStyle: { width: '250px' }, headerStyle: { width: '250px' }, hidden: !form.addr1 },
  { width: 100, title: '手機', field: 'cellphone', hidden: !form.cellphone1 },
  { width: 100, title: '電話', field: 'telephone', hidden: !form.telephone1 },
  { width: 100, title: 'EMail', field: 'email', hidden: !form.email1 },
  { width: 80, title: '班別', field: 'templeClass', lookup: templeclass, hidden: !form.templeClass1 },
  { width: 80, title: '身份', field: 'templeLevel', lookup: templelevel, hidden: !form.templeLevel1 },
  { width: 80, title: '社員編號', field: 'memberId', hidden: !form.memberId1 },
  { width: 80, title: '功德堂', field: 'longLive', hidden: !form.longLive1 },
  { width: 80, title: '往生堂', field: 'lotus', hidden: !form.lotus1 },
  { width: 80, title: '淨土堂', field: 'lotus2', hidden: !form.lotus21 },
]);

export const columns11 = () => ([
  { width: 60, title: '編號', field: 'ordernum' },
  { width: 60, title: '年度', field: 'year' },
  { width: 60, title: '法會', field: 'pray1Text' },
  // { title: 'text', field: 'serial' },
  { width: 60, title: '序號', field: 'serial' },
  { width: 60, title: '申請人', field: 'whoapplyText' },
  { width: 60, title: '申請地址', field: 'addr' },
  { width: 60, title: '應收', field: 'accReceivable' },
  { width: 60, title: '已收', field: 'accReceived' },
  { width: 60, title: '餘額', field: 'accPending' },
  { width: 60, title: '申請日期', field: 'date' },
]);

export const columns12 = () => ([
  { width: 60, title: '編號', field: 'ordernum' },
  { width: 60, title: '年度', field: 'year' },
  { width: 60, title: '法會', field: 'pray1Text' },
  { width: 60, title: '序號', field: 'serial' },
  { width: 60, title: '類別', field: 'fieldTypeText' },
  { width: 60, title: '牌位種類', field: 'praytypeText' },
  { width: 60, title: '善信', field: 'livename' },
  { width: 60, title: '超渡姓1(公/媽)', field: 'lastname1' },
  { width: 60, title: '超渡姓2(氏)', field: 'lastname2' },
  { width: 60, title: '超渡名', field: 'passname' },
  { width: 60, title: '性別', field: 'sexualId' },
  { width: 60, title: '生肖', field: 'zodiacId' },
  { width: 60, title: '財寶箱數', field: 'boxNum' },
  { width: 60, title: '金額', field: 'rowTotal', type: 'numeric' },
  { width: 60, title: '生于 農曆\n(年月日時)', field: 'birthDay' },
  { width: 60, title: '卒于 農曆\n(年月日時)', field: 'passDay' },
  // { title: '陽壽', field: 'live_year' },
  { width: 60, title: '地址', field: 'addr' },
  { width: 60, title: '建立日期', field: 'createdAt' },
]);

export const columns13 = () => ([
  { width: 60, title: '編號', field: 'ordernum' },
  { width: 60, title: '年度', field: 'year' },
  { width: 60, title: '法會', field: 'pray1Text' },
  { width: 60, title: '序號', field: 'serial' },
  { width: 60, title: '類別', field: 'fieldTypeText' },
  { width: 60, title: '牌位種類', field: 'praytypeText' },
  { width: 60, title: '善信', field: 'livename' },
  { width: 60, title: '性別', field: 'sexualId' },
  { width: 60, title: '生肖', field: 'zodiacId' },
  { width: 60, title: '金額', field: 'rowTotal', type: 'numeric' },
  { width: 60, title: '生于 農曆\n(年月日時)', field: 'birthDay' },
]);

export const columns14 = () => ([
  { width: 60, title: '編號', field: 'ordernum' },
  { width: 60, title: '年度', field: 'year' },
  { width: 60, title: '法會', field: 'pray1Text' },
  { width: 60, title: '序號', field: 'serial' },
  { width: 60, title: '類別', field: 'fieldTypeText' },
  { width: 60, title: '牌位種類', field: 'praytypeText' },
  { width: 60, title: '善信', field: 'livename' },
  { width: 60, title: '性別', field: 'sexualId' },
  { width: 60, title: '生肖', field: 'zodiacId' },
  { width: 60, title: '金額', field: 'rowTotal', type: 'numeric' },
  { width: 60, title: '生于 農曆\n(年月日時)', field: 'birthDay' },
]);

export const columns15 = () => ([
  { width: 60, title: '編號', field: 'ordernum' },
  { width: 60, title: '年度', field: 'year' },
  { width: 60, title: '法會', field: 'pray1Text' },
  { width: 60, title: '序號', field: 'serial' },
  { width: 60, title: '類別', field: 'fieldTypeText' },
  { width: 60, title: '牌位種類', field: 'praytypeText' },
  { width: 60, title: '善信', field: 'livename' },
  { width: 60, title: '性別', field: 'sexualId' },
  { width: 60, title: '生肖', field: 'zodiacId' },
  { width: 60, title: '金額', field: 'rowTotal', type: 'numeric' },
  { width: 60, title: '生于 農曆\n(年月日時)', field: 'birthDay' },
]);

export const columns16 = () => ([
  { title: '姓名', field: 'name' },
]);
export const columns17 = () => ([
  { title: '姓名', field: 'name' },
]);

/*
const daoPrayOpt = [
  { text: '超渡', value: '1', key: '1' },
  { text: '斗燈', value: '2', key: '2' },
  { text: '祈福', value: '3', key: '3' },
  { text: '法會', value: '4', key: '4' },
  { text: '光明燈', value: '5', key: '5' },
]

const fieldType4 = [
  {
    "_id": "xqZKGby4M8",
    "value": "元神燈",
    "print_type": "PG6mXdjzNd",
  }, {
    "_id": "3DuyPpq6Af",
    "value": "財神燈",
    "order": 31,
    "field_type": "4",
    "price": "3600",
    "boxNum": "0",
    "table_name": "0",
    "people_num": "1",
    "companyId": "shh6ftbGsP",
    "companyName": "龍潭慈惠堂",
    "printTypeId": "mJ7JNybss3",
    "print_type": "mJ7JNybss3",
    "tableData": {
      "id": 58
    }
  }, {
    "_id": "RqRzFpmEdi",
    "value": "消災補運",
    "order": 32,
    "price": "2400",
    "boxNum": "0",
    "table_name": "0",
    "field_type": "4",
    "people_num": "1",
    "companyId": "shh6ftbGsP",
    "companyName": "龍潭慈惠堂",
    "printTypeId": "2Yo2hdoDuJ",
    "print_type": "2Yo2hdoDuJ",
    "tableData": {
      "id": 60
    }
  }, {
    "_id": "kjqdZdQGfx",
    "value": "智慧燈",
    "order": 30,
    "price": "2400",
    "boxNum": "0",
    "table_name": "0",
    "field_type": "4",
    "people_num": "1",
    "companyId": "shh6ftbGsP",
    "companyName": "龍潭慈惠堂",
    "printTypeId": "f53NjXi4hm",
    "print_type": "f53NjXi4hm",
    "tableData": {
      "id": 56
    }
  }, {
    "_id": "RuCnXptgGz",
    "value": "因果 - 事業",
    "order": 54,
    "field_type": "4",
    "price": "0",
    "boxNum": "0",
    "table_name": "0",
    "companyId": "shh6ftbGsP",
    "companyName": "龍潭慈惠堂",
    "printTypeId": "rBcvHGskSa",
    "print_type": "rBcvHGskSa",
    "tableData": {
      "id": 64
    }
  }, {
    "_id": "QtPLM7eo26",
    "value": "因果- 業障",
    "order": 55,
    "field_type": "4",
    "price": "0",
    "boxNum": "0",
    "table_name": "0",
    "companyId": "shh6ftbGsP",
    "companyName": "龍潭慈惠堂",
    "printTypeId": "2ARqT5H8s6",
    "print_type": "2ARqT5H8s6",
    "tableData": {
      "id": 65
    }
  }, {
    "_id": "HGqzFb5bSj",
    "value": "因果- 身體",
    "order": 56,
    "field_type": "4",
    "price": "0",
    "boxNum": "0",
    "table_name": "0",
    "companyId": "shh6ftbGsP",
    "companyName": "龍潭慈惠堂",
    "printTypeId": "L7bTYCXQBC",
    "print_type": "L7bTYCXQBC",
    "tableData": {
      "id": 66
    }
  }, {
    "_id": "QcdC6ababp",
    "order": 0,
    "field_type": "4",
    "value": "財利燈",
    "table_name": "0",
    "price": "500",
    "companyId": "shh6ftbGsP",
    "companyName": "龍潭慈惠堂",
    "print_type": "5wM6A3Kmp9",
    "tableData": {
      "id": 0
    }
  }, {
    "_id": "44PgpbfMGk",
    "field_type": "4",
    "order": 0,
    "value": "文昌燈",
    "price": "500",
    "companyId": "shh6ftbGsP",
    "companyName": "龍潭慈惠堂",
    "print_type": "vemyD5nCTS",
    "tableData": {
      "id": 1
    }
  }
]
*/