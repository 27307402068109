import React, { Component } from 'react'
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
import { Container, Header, Form, Icon, Grid, Select, Divider, Label, Segment } from 'semantic-ui-react'
import {
  Row, Col, CardText, CardBody, Card,
  CardTitle, CardSubtitle, Button
} from 'reactstrap';

import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import PageHeader from 'components/Headers/PageHeader';
import { ListGroup, ListGroupItem } from 'reactstrap';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

const directions = {
  '1': `Directions: In this part, you're presented with photographs and four sentences about the photographs. Choose the one sentence that best describes the situation in the photograph. Mark your choice (A), (B), (C), or (D) on your answer sheet. The sentences will not be printed in your test book, and you will hear them only one time.`,
  '2': `Directions: In this part, you will hear a question or a statement and three responses only one time. They will not be printed in your test book. Choose the best response to the question or statement and mark your choice (A), (B), or (C) on your answer sheet.`,
  '3': `Directions: In this part, you will hear some conversations between two or three people.You will also hear three questions about the conversations only one time. The conversations will not be printed in your test book. Choose the best answer to the questions and mark your choice (A), (B), (C), or (D) on your answer sheet.`,
  '4': `Directions: In this part, you will hear some talks by one speaker.You will also hear three questions about the talks only one time. The talks will not be printed in your test book. Choose the best answer to the questions and mark your choice (A), (B), (C), or (D) on your answer sheet.`,
  '5': `Directions: In this part, a word or phrase is missing in each of the sentences. Four answer choices are given below each sentence. Select the best answer to complete the sentence. Then mark your choice (A), (B), (C) or (D) on your answer sheet.`,
  '6': `Directions: In this part, words, phrases, or sentences are missing from each text. Four answer choices for each question are given below the text. Select the best answer to complete the text. Then mark your choice (A), (B), (C), or (D) on your answer sheet.`,
  '7': `Directions: In this part, you will read a selection of texts, such as e-mails, instant messages, and articles. Each text or set of texts is followed by several questions. Select the best answer for each question and mark your choice (A), (B), (C), or (D) on your answer sheet.`,
}

const partArr = ['1', '2', '3', '4', '5', '6', '7']


export class Toeic1 extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
      data: [],
      data1: [],
      versionsArr: [],
      version: "-1"
    };
  }

  async componentDidMount() {
    this.handleFetch()
  }

  handleFetch = async () => {
    const { newData } = this.state;
    const data = await Parse.queryData('testBank');
    const data1 = await Parse.queryData('testExBank');
    const versionsArr = []
    data.forEach(item => {
      versionsArr.push({ key: item.objectId, value: item.version, text: item.version })
    })
    console.log(data)
    console.log(data1)
    this.setState({ data, data1, loading: false, versionsArr });

  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value })

  //   handleChange = (e) => {
  //     // console.log(e);
  //     this.setState({
  //       [e.target.id]: e.target.value
  //     })
  //   }

  //   handleSubmit = (e) => {
  //     // console.log(e);
  //     e.preventDefault();
  //     this.props.createProject(this.state);
  //     this.props.history.push('/');
  //   }
  render() {
    //     const { auth } = this.props;
    //     if (!auth.uid) return <Redirect to='/signin' />
    const { data, data1, versionsArr, version } = this.state

    const content = {
      maxWidth: '1170px',
      margin: '80px auto 110px',
      flexDirection: 'column',

    }
    const arr2 = [];
    const arr = data1.filter(item => item.part === '2')
    console.log(arr)
    for (let i = 0; i < arr.length; i += 2) {
      const obj = {
        '1': arr[i],
        '2': i + 1 < arr.length ? arr[i + 1] : {},
      }
      arr2.push(obj);
    }
    console.log(arr2)
    return (<>

      {partArr.map(item => <>
        <Row>
          <Col sm="6" md={{ size: 6, offset: 3 }}>
            <Segment>{`Part ${item}`}<br />{directions[item]}</Segment>
            {data1.filter(item2 => item2.part === item).map(item3 => <>
              {item === '1' ? <>
                <Row>
                  <Col sm="11" md={{ size: 6, offset: 4 }}>
                    <h4>{item3.number}. {item3.topic}</h4>
                  </Col>
                </Row>
              </> : null}
              {item === '2' ? <>
                <Row>
                  <Col md="5" style={{ marginLeft: '60px' }}>
                    <h4>{item3.number}. {item3.topic}</h4>
                  </Col>
                  <Col md="5" style={{ marginLeft: '20px' }}>
                    <h4>{item3.number}. {item3.topic}</h4>
                  </Col>
                </Row>
              </> : null}
              {item === '3' ? <>
                <Row>
                  <Col md="5" style={{ marginLeft: '60px' }}>
                    <h4>{item3.number}. {item3.topic}</h4>
                  </Col>
                  <Col md="5" style={{ marginLeft: '20px' }}>
                    <h4>{item3.number}. {item3.topic}</h4>
                  </Col>
                </Row>
              </> : null}
              <Row>
                <Col sm="11" md={{ size: 6, offset: 3 }}>

                </Col>
              </Row>
            </>)}
          </Col>
        </Row>

      </>)}
      <Container style={content}>
        <br />
      </Container>
    </>)
  }
}

export default Toeic1