import React, { Component } from 'react'
import { Container, Icon, Table, Grid, Button, Modal, Header, Input, Label, Select } from 'semantic-ui-react'
import _ from 'lodash'
import { getOptionTextByValue, getOptionsByKey } from 'views/widgets/FieldsRender';
import Moment from 'moment';
import { getNationalityArr } from '../../../widget/define/students'
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';
import Parse from '../../../widget/parse'

import { Link } from 'react-router-dom';
import zIndex from '@material-ui/core/styles/zIndex';

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipse: {
    // boxSizong: 'border-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

const getStudentsByStatus = (students, status) => {
  return (
    students ?
      students.filter(item => item.studentStatus === status) : []
  )
};

export class GraduateStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      author: '',
      graduates: [],
      graduateYear: `${Number(this.props.semesterYear) + 1}`,
      yearsemester: `${this.props.semesterYear},${this.props.semester}`,
      students: []
    }
  }
  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { firebase, match, companyObj } = this.props
    // firebase.firestore()
    //   .collectionGroup('Graduate')
    //   // .orderBy('relationship')
    //   // .where('eventDate', '==', eventDate).where('classname', '==', className)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     // console.log(data);
    //     this.setState({ graduates: data });
    //   }, err => {
    //     console.log(`Encountered error: ${err}`);
    //   });
    const data = await Parse.queryData('Graduate', { companyId: companyObj.objectId });
    this.setState({ graduates: data });

    // const data1 = await Parse.queryData('Students', { companyId: companyObj.objectId });
    // this.setState({ students: data1 });


  }
  handleChange = (event, data) => {
    let fieldName = data.name;
    let fleldVal = data.value;
    this.setState({ [fieldName]: fleldVal });
  }
  render() {
    const { students, match, history, formSet, optSet, semester, semesterYear } = this.props;
    const { graduates, yearsemester, graduateYear } = this.state;
    const semesterYearArr = getOptionsByKey(optSet, 'semesterYear').map(item => ({ key: item._id, text: item.label, value: item.value }));
    const semesterArr = [
      { key: '2019,A', text: '2019A', value: '2019,A' },
      { key: '2019,S', text: '2019S', value: '2019,S' },
      { key: '2018,A', text: '2018A', value: '2018,A' },
      { key: '2018,S', text: '2018S', value: '2018,S' },
      { key: '2017,A', text: '2017A', value: '2017,A' },
      { key: '2017,S', text: '2017S', value: '2017,S' },
    ]
    let year = yearsemester.substring(0, 4);
    let semes = yearsemester.substring(5);
    let graStudents = students.filter(item => item.semesterYear === (Number(graduateYear) - 2) + '')
    graStudents.forEach(item2 => {
      item2.schoolOrCompany = item2.wayOutSituation === '4' ? item2.nameOfCompany :
        item2.wayOutSituation === '1' ?
          graduates && graduates.find(graduate => graduate.studentId === item2._id && graduate.isEnter === '1')
          && graduates.find(graduate => graduate.studentId === item2._id && graduate.isEnter === '1').targetSchoolText : ''
    })
    // console.log(graStudents)
    return (
      <>
        <div style={{ padding: '10px', overflowX: 'scroll' }}>

          <div style={{ ...style.flexCenter, marginBottom: 15 }}>
            <Header as='h2' style={{ margin: 0 }}>卒業生の進路状況</Header>
            <div style={{ ...style.flex }}>
              <div style={{ ...style.flexCenter }}>
                <div>
                  {/* <Input labelPosition='left' >
                      <Label color={'blue'}> <Icon name='file alternate outline' />卒業生入学期</Label>
                      <Select style={{
                        minWidth: '250px',
                        borderTopLeftRadius: '0',
                        borderBottomLeftRadius: '0'
                      }} compact
                        options={semesterArr} onChange={this.handleChange} value={yearsemester} name='yearsemester' />
                    </Input> */}
                  <Input labelPosition='left' >
                    <Label color={'blue'}> <Icon name='file alternate outline' />卒業年</Label>
                    <Select style={{
                      minWidth: '120px',
                      borderTopLeftRadius: '0',
                      borderBottomLeftRadius: '0',
                      marginRight: 10,
                      zIndex: 999999,
                    }} compact
                      options={semesterYearArr} onChange={this.handleChange} value={graduateYear} name='graduateYear' />
                  </Input>
                </div>
              </div>
              <div style={{ ...style.flexCenter, marginLeft: 10, minWidth: 220 }}>
                {/* <ReactToPrint
                    trigger={(e) => <Button
                      // floated='right'
                      content='印刷'
                      // label='&nbsp;'
                      color='orange' icon='print'
                      size='small'
                    />}
                    content={() => this.componentRef}
                    pageStyle={"@page { size: auto;  margin:5mm; }*{font-family: 'Noto Serif JP', serif;}"}
                  /> */}
                <ExportTableToExcelButton /*floated='right'*/ size='small' />
                <ModalPdf
                  {...this.props}
                  // ref={(ref) => { this.pdfModal = ref }}
                  codekey="graduateStatusPdf"
                  params={graStudents.sort((a, b) => a.studentNum - b.studentNum)}
                />
              </div>
            </div>
          </div>
          <div ref={el => (this.componentRef = el)} className="">
            {/* <h1 style={{ float: 'left' }}>卒業生の進路状況</h1> */}
            <Table id="excel" celled striped size='small' selectable>
              <Table.Header>
                <Table.Row textAlign='center'>
                  {/* <Table.Cell></Table.Cell> */}
                  <Table.HeaderCell style={style.textEllipse}>国籍</Table.HeaderCell>
                  {/* <Table.HeaderCell style={style.textEllipse}>学籍番号</Table.HeaderCell> */}
                  <Table.HeaderCell style={style.textEllipse}>氏名</Table.HeaderCell>
                  <Table.HeaderCell style={style.textEllipse}>生年月日</Table.HeaderCell>
                  <Table.HeaderCell style={style.textEllipse}>性別</Table.HeaderCell>
                  <Table.HeaderCell style={style.textEllipse}>入国年月日</Table.HeaderCell>
                  <Table.HeaderCell style={style.textEllipse}>卒業(退学)年月日</Table.HeaderCell>
                  <Table.HeaderCell style={style.textEllipse}>進路</Table.HeaderCell>
                  <Table.HeaderCell style={style.textEllipse}>進学学校/就職先</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {graStudents.sort((a, b) => a.studentNum - b.studentNum).map((item, index) => (<Table.Row key={item._id}>
                  {/* <Link to={`/TeacherPage/${item._id}`}> */}
                  {/* <Table.Cell style={style.textEllipse}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                      <Link to={`/TeacherPage/${item._id}`}><Button inverted={companyObj.inverted} color='yellow' size='small' style={{ margin: 15 }}>詳細</Button></Link>
                      <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} style={{ margin: 15 }}icon='edit' content='修正' />
                    </div>
                  </Table.Cell> */}
                  <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/3/${item._id}/0/0`}>{item.nationalityText}</Link></Table.Cell>
                  {/* <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/3/${item._id}/0/0`}>{item.studentId}</Link></Table.Cell> */}
                  <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/3/${item._id}/0/0`}>{item.jpnName}</Link></Table.Cell>
                  <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/3/${item._id}/0/0`}>{item.birthday ? Moment(item.birthday).format('YYYY年MM月DD日') : ''}</Link></Table.Cell>
                  <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/3/${item._id}/0/0`}>{item.genderText}</Link></Table.Cell>
                  <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/3/${item._id}/0/0`}>{item.landingAdmitDate ? Moment(item.landingAdmitDate).format('YYYY年MM月DD日') : ''}</Link></Table.Cell>
                  <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/3/${item._id}/0/0`}>{item.courseEndDate ? Moment(item.courseEndDate).format('YYYY年MM月DD日') : ''}</Link></Table.Cell>
                  <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/3/${item._id}/0/0`}>{item.wayOutSituationText}</Link></Table.Cell>
                  <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/3/${item._id}/0/0`}>{item.schoolOrCompany}</Link></Table.Cell>
                  {/* {item.wayOutSituation === '4' ? <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/3/${item._id}/0/9`}>{item.nameOfCompany}</Link></Table.Cell> :
                      item.wayOutSituation === '1' ?
                        <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/3/${item._id}/0/9`}>
                          {graduates.find(graduate => graduate.studentId === item._id && graduate.isEnter === '1').targetSchoolText}</Link></Table.Cell>
                    : <Table.Cell></Table.Cell>} */}
                  {/* </Link> */}
                </Table.Row>))}
              </Table.Body>
            </Table>
          </div>

        </div>
      </>
    )
  }
}
export default GraduateStatus