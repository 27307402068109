import React, { Component } from 'react'
// import { Grid, Header, Form, Segment, Menu, Container } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
// import { Row, Col } from 'reactstrap';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
// import ColorNavbar from "components/Navbars/ColorNavbar.js";
// import FooterBlack from "components/Footers/FooterBlack.js";
// import PageHeader from 'components/Headers/PageHeader';

// const pageType = { '1': '頁面', '2': '目錄', '3': '一頁式', '4': '頁腳', '5': '非選單頁面' }

const columns1 = () => ([
  // { width: 60, title: '開啟', field: 'isOpen', type: 'boolean', initialEditValue: true, editable: 'never' },
  // { width: 60, title: '類別', field: 'pageType', lookup: pageType, initialEditValue: '1', editable: 'never' },
  // { width: 60, title: '順序', field: 'order', type: 'numeric', editable: 'never' },
  { width: 100, title: 'ID', field: 'objectId', editable: 'never' },
  { width: 160, title: '🇹🇼 繁體中文', field: 'name' },
  { width: 160, title: '🇨🇳 简体中文', field: 'zhHant' },
  { width: 160, title: '🇯🇵 日本語', field: 'ja' },
  { width: 160, title: '🇺🇲 English', field: 'en' },
  { width: 160, title: '🇻🇳 Vietnam', field: 'vi' },
]);

export default class LocaleContent extends Component {
  state = {
    typeId: '',
    data: [],
    data2: [],
    newData: {},
    loading1: true,
    loading2: false,
    rowData: {},
    pageObj: {}
  }

  async componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps)
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, pageId } = props;
    const data = await Parse.queryData('localeContent', { companyId: companyObj.objectId, pageId })
    // console.log(data)
    this.setState({ data, loading1: false })
  }

  handleAdd = async (key) => {
    const { companyObj, pageId } = this.props;
    const { newData } = this.state;
    newData.companyId = companyObj.objectId;
    newData.companyName = companyObj.name;
    newData.pageId = pageId;

    Parse.saveData('localeContent', newData);
  }

  handleUpdate = async (key) => {
    const { newData } = this.state;
    Parse.saveData('localeContent', newData);
  }

  handleDelete = async (key) => {
    const { oldData } = this.state
    Parse.deleteData('localeContent', oldData);
    this.setState({ oldData: {} })
  }

  // handleData = async (rowData) => {
  //   this.setState({ rowData, loading2: false });
  // }
  // handleData2 = async (rowData2) => {
  //   this.setState({ rowData2 });
  // }

  render() {
    const { pageObj } = this.props
    const { data = [], loading1, loading2, rowData } = this.state;

    return (
      <>
        <MaterialTable
          isLoading={loading1}
          localization={localization()}
          columns={columns1()}
          options={{
            // addRowPosition: "first",
            exportButton: true,
            pageSize: 1,//data.length || 5,
            search: false,
            tableLayout: 'fixed',
          }}
          data={data}
          title={pageObj.name}
          // actions={[
          //   {
          // icon: 'view_headline',
          // tooltip: '查看',
          // onClick: (event, rowData) => this.handleData(rowData)
          //   }
          // ]}
          // onRowClick={(event, rowData) => this.handleData(rowData)}
          editable={{
            onRowAdd: newData =>
              new Promise(async (resolve, reject) => {
                const data1 = data;
                const db = Parse.Object.extend("localeContent");
                const newDoc = new db();
                const doc = await newDoc.save();
                newData.objectId = doc.id;
                newData.id = doc.id;
                newData._id = doc.id;
                newData.level = '1';

                data1.push(newData);
                this.setState({ data: data1, newData }, () => resolve());
              }).then(() => this.handleAdd("1")),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const data1 = data;
                const index = data.indexOf(oldData);
                data1[index] = newData;
                // console.log(data)
                this.setState({ data: data1, newData, oldData }, () => resolve());
              }).then(() => this.handleUpdate()),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                let data1 = data;
                const index = data1.indexOf(oldData);
                data1.splice(index, 1);
                this.setState({ data: data1, oldData }, () => resolve());
              }).then(() => this.handleDelete("1")),
          }}
        />
      </>
    )
  }
}