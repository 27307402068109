import React, { Component, createRef } from 'react'
import {
  Button, Form, Icon, Image, Container, Segment, Header, Input, Label,
  Select, Ref, Grid, Sticky, Progress, Confirm
} from 'semantic-ui-react';

import ClientsViewTabs from './ClientsViewTabs';
import Parse from 'widget/parse'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// const objPortfolioTemplate = [
//   { key: 'profolioTmp', text: '-- 全選 --', value: '-1' },
//   { key: "profolioTmp0", value: "0", text: "顯示樣版無定義" },
//   { key: "profolioTmp1", value: "1", text: "保險-Pru Life" }, // ok
//   { key: "profolioTmp2", value: "2", text: "保險-VOYA" },     // ok like 1 ok
//   { key: "profolioTmp3", value: "3", text: "英國房產-股權" },  // ok
//   { key: "profolioTmp4", value: "4", text: "英國房產-產權" },   // ok like 3
//   { key: "profolioTmp5", value: "5", text: "美國、加拿大房產" },    // ok like 3
//   { key: "profolioTmp6", value: "6", text: "FPI" }, // ok
//   { key: "profolioTmp7", value: "7", text: "股票" },                  // ok like 3
//   { key: "profolioTmp8", value: "8", text: "AVIVA/Zurich/Standardlife/MassMutual/AIA" }, // ok like 6
//   // { id:"9", value:"(AS)Zurich架構"},
//   // { id:"10", value:"(AS)AVIVA架構"},
//   // { id:"11", value:"(AS)FPI架構"},
// ];

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const alphabetArr = alphabet.split('').map(item => ({
  key: `${item}`, text: `${item}`, value: `${item}`
}));
alphabetArr.unshift({ key: 'first', text: '-- 全選 --', value: 'first' })

export default class clientsPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loginUser: this.props.user.profile.toJSON(),
      productType1Opt: [],
      productType2Opt: [{ key: 'productType20', text: '-- 全選 --', value: '-1' }],
      productType3Opt: [{ key: 'productType30', text: '-- 全選 --', value: '-1' }],
      productType4Opt: [{ key: 'productType40', text: '-- 全選 --', value: '-1' }],
      disabled2: true,
      disabled3: true,
      disabled4: true,
      value1: '-1',
      value2: '-1',
      value3: '-1',
      value4: '-1',
      valueProvider: '-1',
      valueAgent: '-1',
      valueType: '-1',
      // dataArr: [],
      // dataArr2: [],
      providerOpt: [],
      isEdit: false, // props.isEdit,
      willDel: false,// props.willDel,
      doc: {}, // 原本資料
      form: {},  // 更改處
      jpForm: {},
      selClass: '0',
      optClients: [],
      resClients: [],
      searchText: '',
      formModal: {
        nameClient: '',
        nameClientFull: '',
        partTimeClient: '',
        startSemesterNum: '',
        endSemesterNum: '',
      },
      newClient: '',
      show: false,
      loading: true,
      isSort: false,
      confirm: false,
      deleteAllFiles: false,
      currentClients: [],
      fileArr: [],
      fileArrCheck: [],
      filenames: [],
      downloadURLs: [],
      downloadURLsArr: [],
      clients: [],
      sortOption: [
        { key: '0', value: '0', text: '無排序' },
        { key: 'uid', value: 'uid', text: '編號' },
        { key: 'name_cht', value: 'name_cht', text: '姓名' },
        { key: 'sexual_id', value: 'sexual_id', text: '性別' },
        // { key: 'd', value: 'd', text: '無效' },
        // { key: 'e', value: 'e', text: '無產品' },
      ],
      sortId: '0',
      buttonLoading: false,
    };
  }

  async componentDidMount() {
    this.handleFetchPor()
    const { loginUser } = this.state
    const { companyObj } = this.props
    const authPage = window.location.pathname.split('/')[2]
    const authUser = companyObj.objectId + '_' + authPage
    let auth = {}
    let isAgent = ''

    if (loginUser.authAdminA && loginUser.authAdminA.indexOf(companyObj.objectId) === -1 && loginUser.role && loginUser.role.value === '1') {
      isAgent = loginUser.objectId
    }

    if ((loginUser.authAdminA && loginUser.authAdminA.indexOf(companyObj.objectId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
      auth = {
        edit: true,
      }
    } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
      auth = {
        edit: false,
      }
    } else {
      auth = {
        edit: false,
      }
    }
    const users = await Parse.queryData('User', { authUserA: companyObj.objectId })
    const agent = users.filter(item => item.role && item.role.value === '1')
    this.setState({ auth, isAgent, agent }, () => this.handleFetch(this.props, 1))
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps, 0);
  }

  handleFetchPor = async () => {
    const { companyObj } = this.props;
    const companyId = companyObj.objectId;
    const portfolios = await Parse.queryData('portfolios', { companyId });
    this.setState({ optPortfolios: portfolios, portfolios })
  }

  handleFetch = async (props = this.props, reClient = 1, rePor = 0) => {
    const { isAgent } = this.state
    const { match, history, companyObj } = props;
    const { listType = '1', clientId } = match.params;
    const companyId = companyObj.objectId;

    let obj = {};
    let currentClients = reClient === 1 ? [] : this.state.currentClients

    if (rePor === 1) {
      this.handleFetchPor()
    }
    if (reClient === 1) {
      let clients = []
      if (isAgent && isAgent.length) {
        clients = await Parse.queryData('clients', { companyId, agent_id: isAgent });
      } else {
        clients = await Parse.queryData('clients', { companyId });
      }
      currentClients = clients.sort((a, b) => ('' + a.uid).localeCompare(b.uid));
      this.setState({ currentClients, clients })
      if (clientId && clients.find(item => item.objectId === clientId)) {
        obj = clients.find(item => item.objectId === clientId);
        this.setState({ doc: obj, form: obj, loading: false });
      } else if (listType === "1") {
        obj = clients[0];
        history.push(`/admin/clientsPage/1/${(clients && clients.length && clients[0].objectId) || ''}`);
      }
    } else if (reClient === 0) {
      if (clientId && currentClients.find(item => item.objectId === clientId)) {
        obj = currentClients.find(item => item.objectId === clientId);
        this.setState({ doc: obj, form: obj, loading: false });
      }
      // }
    }
    // let currentClients = clients.sort((a, b) => ('' + a.uid).localeCompare(b.uid));
    let optClients = currentClients.map(item => ({
      key: item.objectId, text: `${item.uid} ${item.name_cht} (${item.sexual_text})`, value: item.objectId
    }));
    // let resClients = optClients.filter(item => item.value === obj.objectId)
    let resClients = optClients.filter(item => item.value === obj.objectId)
    this.setState({ optClients, resClients, optPortfolios: [], resPortfolios: [], currentClients });
  }


  handleConfirm = () => {
    this.setState({ confirm: true })
  }

  handleRemove = async () => {
    const { match, companyObj, history } = this.props;
    const { portfolios } = this.state
    const { form, formModal, value4, value1, value2, value3, filedoc, currentClients } = this.state;
    const { clientId, portfolioId = '' } = match.params;
    // const db = firebase.firestore();
    // let batch = db.batch();
    // const porArr = portfolios.filter(item => item.client_id === form.objectId)
    if (portfolios && portfolios.length && portfolios.find(item => item.client_id === form.objectId)) {
      const promises = []
      let data = portfolios.filter(item => item.client_id === form.objectId)
      data.forEach(item => promises.push(Parse.destroyData('paymentRecord', { portfolioId: item.objectId }), Parse.destroyData('returnPrincipal', { portfolioId: item.objectId }), Parse.destroyData('fileCenter2', { fileCenter1Id: item.objectId }), Parse.destroyData('afterservice', { portfolio_id: item.objectId })))
      // await Parse.destroyData('paymentRecord', { portfolioId: form.objectId })
      // await Parse.destroyData('returnPrincipal', { portfolioId: form.objectId })
      // await Parse.destroyData('fileCenter2', { fileCenter1Id: form.objectId })
      await Promise.all(promises).catch(error => console.log("error", error));
    }
    // console.log(porArr)
    await Parse.destroyData('portfolios', { client_id: form.objectId })
    await Parse.destroyData('clientFile', { clientId: form.objectId })
    // const clientRef = db.collection(`company/${companyObj._id}/bg/${form.bgId}/clients`).doc(form._id)
    // batch.delete(clientRef)
    let data = currentClients
    const index = data.indexOf(form);
    data.splice(index, 1);
    await Parse.deleteData('clients', form);
    let newForm = data.length > 0 && data[0]
    // await batch.commit()
    window.alert('刪除成功')
    history.push(`/admin/clientsPage/1/${newForm.objectId}`);
    this.setState({ form: newForm, deleteAllFiles: true, currentClients: data, isEdit: false, buttonLoading: false }, () => this.handleFetch(this.props, 1, 1))
    this.handleCloseConfirm()
  }

  isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  handleIsEdit = () => {
    this.setState({ isEdit: true })
  }

  handleIsNotEdit = () => {
    this.setState({ deleteAllFiles: false, isEdit: false, confirm: false })
  }

  handleUpdateSetting = (event, data) => {
    const { form, doc } = this.state;
    const { name, value, checked, id } = data;
    // return;
    // doc[setting.key] = setting.value;
    if (!this.isEmpty(form)) {
      if (name === "checkbox") {
        if (checked === true) {
          // const obj = {
          //   ...doc,
          //   [id]: "1"
          // }
          this.setState({ form: { ...this.state.form, [id]: "1" } })
        } else if (checked === false) {
          // const obj = {
          //   ...doc,
          //   [id]: "0"
          // }
          this.setState({ form: { ...this.state.form, [id]: "0" } })
        }
      } else {
        this.setState({ form: { ...form, [name]: value } });
      }
    } else {
      this.setState({ form: { ...doc, [name]: value } });
    }
  }

  saveUpdateSetting = async () => {
    const { match, refetch, companyObj } = this.props;
    const { form, isEdit, currentClients, doc, portfolios } = this.state;
    // const db = firebase.firestore(); 
    // console.log(form._id)
    if (window.confirm('確定儲存？')) {
      let data = currentClients
      const index = data.indexOf(doc);
      data[index] = form
      // let optClients = data.map(item => ({
      //   key: item._id, text: `${item.uid} ${item.name_cht} (${item.sexual_text})`, value: item._id
      // }));
      // await db.collection(`company/${companyObj._id}/bg/${form.bgId}/clients`).doc(form.objectId).update(form)
      const promises = []
      portfolios.forEach(item => {
        if (item.client_id === form.objectId) {
          let obj = {
            ...item,
            client_uid: form.uid || '',
            name_cht: form.name_cht || "",
            name_eng: form.name_eng || "",
            contactnum: form.cellnum || '',
            email: form.email || ''
          }
          promises.push(Parse.saveData('portfolios', obj))
        }
      })
      await Promise.all(promises).catch(error => console.log("error", error));

      const obj = {
        ...form,
        birthday_yyyy: (form.birthday && Number(form.birthday.split('-')[0])) || null,
        birthday_mm: (form.birthday && Number(form.birthday.split('-')[1])) || null,
        birthday_dd: (form.birthday && Number(form.birthday.split('-')[2])) || null,
      }
      await Parse.saveData('clients', obj);
      this.setState({ isEdit: false, doc: obj, currentClients: data }, () => this.handleFetch(this.props, 0));
    }
  }

  cancelUpdateSetting = () => {
    const { form, doc } = this.state;
    this.setState({ isEdit: false, form: doc, willDel: true });
  }

  printDiv = () => {
    window.print(); // 這是印整個頁面的
  }

  handleClose = () => {
    // form: {client: '-1' }
    this.setState({ show: false });
  }

  handleCloseConfirm = () => {
    // form: {client: '-1' }
    this.setState({ confirm: false });
  }


  handleChangeField = (e, data) => {
    const { form } = this.state
    if (data && data.id && data.id !== 'fieldFormGroup') {
      this.setState({ form: { ...form, [data.name]: data.checked === true ? '1' : '0' } })
    } else {
      if (e.target) {
        this.setState({ form: { ...form, [e.target.name]: e.target.value } })
      } else {
        if (typeof form[data.name] === 'string') {           //若原資料為string，存回去的資料型態就使用string。若為object，存回去的資料型態就使用object。 ps:react-select儲存為object
          this.setState({ form: { ...form, [data.name]: e.label } })
        } else if (typeof form[data.name] === 'object') {
          this.setState({ form: { ...form, [data.name]: e } })
        } else {
          this.setState({ form: { ...form, [data.name]: e.label } })
        }
      }
    }
  }

  handleSubmit = async () => {
    const { history, collection, list, pageTitleText, examKey, refetch, companyObj } = this.props;
    const { bgId, formModal } = this.state;
    this.setState({ loading: true })
    const db = Parse.Object.extend("clients");
    const newDoc = new db();
    const doc = await newDoc.save();
    const obj = {
      id: doc.id,
      _id: doc.id,
      objectId: doc.id,
      companyId: companyObj.id,
      companyName: companyObj.name,
      uid: formModal.uidClient,
      name_cht: formModal.name_chtClient,
    }

    await Parse.saveData('clients', obj);
    history.push(`/admin/clientsPage/1/${obj._id}`);
    this.setState({ loading: false, show: false, formModal: {} })
    this.handleFetch(this.props, 1, 1)
    // }
  }

  handleChange = (event, { name, value }) => {
    const { match, } = this.props
    const { clients, doc } = this.state
    const { listType, clientId } = match.params;
    if (listType === '1') {
      let resClients;
      if (name === 'sortId') {
        if (value === 'uid') {
          let currentClients = clients.sort((a, b) => ('' + a.uid).localeCompare(b.uid));
          resClients = currentClients.map(item => ({
            key: item.objectId, text: `${item.uid} ${item.name_cht} (${item.sexual_text})`, value: item.objectId
          }));
          this.setState({ resClients })
        } else if (value === 'name_cht') {
          let currentClients = clients.sort((a, b) => ('' + a.name_cht).localeCompare(b.name_cht));
          resClients = currentClients.map(item => ({
            key: item.objectId, text: `${item.uid} ${item.name_cht} (${item.sexual_text})`, value: item.objectId
          }));
          this.setState({ resClients })
        } else if (value === 'sexual_id') {
          let currentClients = clients.sort((a, b) => ('' + a.sexual_text).localeCompare(b.sexual_text));
          resClients = currentClients.map(item => ({
            key: item.objectId, text: `${item.uid} ${item.name_cht} (${item.sexual_text})`, value: item.objectId
          }));
          this.setState({ resClients })
        } else if (value === '0') {
          let currentClients = clients.sort((a, b) => ('' + a.uid).localeCompare(b.uid));
          let mapClient = currentClients.map(item => ({
            key: item.objectId, text: `${item.uid} ${item.name_cht} (${item.sexual_text})`, value: item.objectId
          }));
          resClients = mapClient.filter(item => item.key === doc.objectId)
          this.setState({ resClients })
        }
      }
      this.setState({ [name]: value })
    }
  };

  handleTableData = () => {
    const { portfolios } = this.state
    const { value1, value2, value3, value4, valueType, valueProvider } = this.state;

    let optPortfolios = [...portfolios];
    let resPortfolios
    if (value1 !== '-1') {
      resPortfolios = optPortfolios.filter(item => item.product1_id === value1).map(item => ({
        key: item.objectId, text: `${item.uid} ${item.name_cht} (1.${item.product1_text} 2.${item.product2_text} 3.${item.product3_text} 4.${item.product4_text})`, value: item.objectId
      }));
    }
    if (value2 !== '-1') {
      resPortfolios = optPortfolios.filter(item => item.product2_id === value2).map(item => ({
        key: item.objectId, text: `${item.uid} ${item.name_cht} (1.${item.product1_text} 2.${item.product2_text} 3.${item.product3_text} 4.${item.product4_text})`, value: item.objectId
      }));;
    }
    if (value3 !== '-1') {
      resPortfolios = optPortfolios.filter(item => item.product3_id === value3).map(item => ({
        key: item.objectId, text: `${item.uid} ${item.name_cht} (1.${item.product1_text} 2.${item.product2_text} 3.${item.product3_text} 4.${item.product4_text})`, value: item.objectId
      }));;
    }
    if (value4 !== '-1') {
      resPortfolios = optPortfolios.filter(item => item.product4_id === value4).map(item => ({
        key: item.objectId, text: `${item.uid} ${item.name_cht} (1.${item.product1_text} 2.${item.product2_text} 3.${item.product3_text} 4.${item.product4_text})`, value: item.objectId
      }));;
    }
    if (valueType !== '-1') {
      resPortfolios = optPortfolios.filter(item => item.template_id === valueType).map(item => ({
        key: item.objectId, text: `${item.uid} ${item.name_cht} (1.${item.product1_text} 2.${item.product2_text} 3.${item.product3_text} 4.${item.product4_text})`, value: item.objectId
      }));;
    }
    if (valueProvider !== '-1') {
      resPortfolios = optPortfolios.filter(item => item.provider_id === valueProvider).map(item => ({
        key: item.objectId, text: `${item.uid} ${item.name_cht} (1.${item.product1_text} 2.${item.product2_text} 3.${item.product3_text} 4.${item.product4_text})`, value: item.objectId
      }));
    }
    this.setState({ optPortfolios: resPortfolios, resPortfolios, isSort: true })
  }

  handleChangeModal = (event, { name, value }) => {
    const { formModal } = this.state
    // this.setState({ [fieldName]: fleldVal });
    if (name === '_value0') {
      this.setState({ formModal: { ...formModal, [name]: value, value0: value } })
    } else if (name === '_value1') {
      this.setState({ formModal: { ...formModal, [name]: value, value1: value } })
    } else {
      this.setState({ formModal: { ...formModal, [name]: value } })
    }
  };

  handleClick = (event, d) => {
    let fleldVal = event.target.value;
    const { match, history } = this.props
    const { clients } = this.state
    const { portfolios } = this.state
    const { listType, clientId } = match.params;
    // if (listType === "1") {
    history.push(`/admin/clientsPage/1/${fleldVal}`);
    // const obj = clients.find(item => item._id === fleldVal) || {};
    this.setState({ searchText: '', sortId: '0' });
    // }
  }

  handleDelete = async () => {
    const { match, companyObj } = this.props;
    const { form, employee } = this.state;
    // if (window.confirm('確認刪除？')) {
    //   await firebase.storage().ref("images").child(form.photo_fakefilename).delete();
    //   // const docRef = firebase.firestore().collection(`company/${companyObj._id}/bg/${bgId}/clients`).doc(form._id);
    //   delete form.photo
    //   delete form.photo_fakefilename
    //   delete form.photo_filename
    //   let obj = {
    //     ...form,
    //   }
    //   // await docRef.set(obj)
    //   await Parse.saveData('company', obj);
    //   this.setState({ form: obj, doc: obj })
    // }
  }

  contextRef = createRef()

  handleSearch = (e, { value }) => {
    const { optClients, sortId } = this.state;

    if (value) {
      // 搜尋值中間可以插入任意值 例如搜尋'程雪原' 可以找到 '程 雪原'
      const looseSearchValue = RegExp(value.split('').reduce((pattern, item) => pattern + item + '.*', '.*'));

      const resClients = optClients.filter(item => item.text.match(looseSearchValue));
      this.setState({ resClients, searchText: value });
      // console.log(res)
    } else {
      if (sortId !== '0') {
        let name = 'sortId'
        let value2 = sortId
        this.setState({ searchText: value }, () => this.handleChange('', { name, value: value2 }));
      } else {

        this.setState({ resClients: optClients, searchText: value })
      }
    }
  }

  handleSelectUpdate = (e, data) => {
    const { agent } = this.state
    const { name, value, id } = data;
    let obj = agent.find(item => item.objectId === value)
    this.setState({ form: { ...this.state.form, [name]: obj.name, [id]: obj.objectId } })
  }

  render() {
    const { match, companyObj } = this.props;
    const { listType } = match.params;
    const { doc, form, isEdit, selTypeId, resClients, resPortfolios, searchText, show, loading, formModal, deleteAllFiles, confirm, portfolios, agent, clients } = this.state;
    const { buttonLoading, sortId, sortOption, auth = {} } = this.state;
    // const extProps = { bgId }
    return (<div className="content"><div style={{ padding: '10px' }}>
      <Ref innerRef={this.contextRef}>
        <Grid>
          <Grid.Column width={3} computer={3} tablet={5} mobile={16}>
            {/* <Sticky context={this.contextRef}> */}
            {/* <Header as='h3'>1-1 客戶列表</Header> */}
            <Form size='small'>
              {/* <Input labelPosition='left' > */}
              {/* <Label color={'teal'}> <Icon name='newspaper' />表示</Label> */}
              {/* <Select style={{
                  minWidth: '100px',
                  // borderTopLeftRadius: '0',
                  // borderBottomLeftRadius: '0'
                }} compact
                  options={bgSel}
                  onChange={this.handleChange}
                  disabled={isEdit}
                  value={bgId}
                  name='bgId' /> */}
              {/* </Input>{' '} */}
              <br />
              {/* <Input labelPosition='left' > */}
              {/* <Label color={'green'}> <Icon name='file alternate outline' />学期</Label> */}

              <br />
              {!isEdit ?
                <Button
                  style={{ marginTop: '4px' }}
                  // floated='right' 
                  size='tiny' primary onClick={() => this.setState({ show: true })} icon="add user" content="新增客戶" disabled={!auth.edit} /> : null}{' '}
              <br />
              <Form.Select
                label={<Header as='h4' inverted={companyObj.inverted} content='排序' style={{ marginTop: '10px', marginBottom: '10px' }} />}
                disabled={isEdit}
                options={sortOption}
                value={sortId}
                name='sortId'
                onChange={this.handleChange}
              />
              <br />
              <Form.Input
                style={{ marginTop: '4px', marginBottom: '4px' }}
                placeholder='Search...'
                disabled={isEdit}
                onChange={this.handleSearch}
                value={searchText}
              />
            </Form>
            {resClients && resClients.length ?
              <select multiple className="form-control ui form fluid search dropdown"
                style={{ height: resClients && resClients.length === 1 ? '25px' : '600px', width: '100%', fontSize: '11px', overflowX: 'auto', }} name="clientSel"
                // style={{ height: '600px', width: '100%', fontSize: '11px' }}
                // name="clientSel"
                onChange={this.handleClick}
                // value={[doc._id]}
                disabled={isEdit}
              >
                {resClients && resClients.map(item => <option key={item.key} className="item" value={item.value}>{item.text}</option>)}
              </select> : null}
            {/* </Sticky> */}
          </Grid.Column>
          {form && form.objectId ?
            <Grid.Column width={13} computer={13} tablet={11} mobile={16}>
              <Segment
                clearing
                vertical
              >
                {/* {!form.photo ? <Button floated='left' as='label' basic inverted
                  content={<>
                    {isEdit ? <FileUploader
                      hidden
                      accept="image/*"
                      name="avatar"
                      // randomizeFilename
                      filename={file => {
                        let obj = {};
                        let fakeFileName = `${String(Math.random() * 100000000000000000)}_${file.lastModified}_${file.name}`
                        obj = {
                          fakefilename: fakeFileName,
                          filename: file.name,
                          lastModified: file.lastModified,
                          lastModifiedDate: file.lastModifiedDate,
                          webkitRelativePath: file.webkitRelativePath,
                          size: file.size,
                          type: file.type,
                        }
                        fileArr.push(obj)
                        fileArrCheck.push(fakeFileName)
                        this.setState({ fileArr, loading: true, fileArrCheck })
                        return fakeFileName
                      }}
                      // storageRef={this.props.firebase.storage().ref("images")}
                      onUploadStart={this.handleUploadStart}
                      onUploadError={this.handleUploadError}
                      onUploadSuccess={this.handleUploadSuccess}
                    // onProgress={this.handleProgress}
                    /> : null}
                    <div style={{ position: 'relative' }}>
                      <Image src={(form && form.photo) || 'https://react.semantic-ui.com/images/wireframe/square-image.png'} size='tiny'
                        style={{ marginBottom: '0px' }} />
                      {isEdit ? <Icon name='edit' style={{
                        color: '#000',
                        position: 'absolute',
                        bottom: 5,
                        right: 0,
                        fontSize: 18,
                        background: 'rgba(255,255,255,0.7)'
                      }} />
                        : null}
                    </div>
                  </>}
                /> : <Button
                    floated='left' as='label' basic inverted
                    content={<>
                      <div style={{ position: 'relative' }}>
                        <Image src={(form && form.photo) || 'https://react.semantic-ui.com/images/wireframe/square-image.png'} size='tiny'
                          style={{ marginBottom: '0px' }} />
                        {isEdit ? <Icon name='delete' style={{
                          color: '#000',
                          position: 'absolute',
                          bottom: 5,
                          right: 0,
                          fontSize: 18,
                          background: 'rgba(255,255,255,0.7)'
                        }} />
                          : null}
                      </div>
                    </>}
                    onClick={isEdit ? this.handleDelete : null}
                  />} */}
                <Header inverted={companyObj.inverted} floated='left' as='h2'>
                  <Header.Content>
                    {form && form.uid}&nbsp;
                  {form && form.name_cht}
                    <Header.Subheader>
                      {/* {form && form.clientNum ? <><span className="text-muted">{getLabelName2(formSet, 'clientNum')}:</span>&nbsp;{form.clientNum}&nbsp;&nbsp;</> : ''} */}
                      {form && form.clientTitleText ? <><span className="text-muted">職位:</span>&nbsp;{form.clientTitleText}&nbsp;&nbsp;</> : ''}
                      {form && form.partTimeClientText ? <><span className="text-muted"></span>&nbsp;{form.partTimeClientText}&nbsp;&nbsp;</> : ''}
                    </Header.Subheader>
                    {/* <br /> */}
                  </Header.Content>
                </Header>
              </Segment>
              <br />
              <ClientsViewTabs
                {...this.props}
                clients={clients}
                agent={agent}
                doc={form}
                auth={auth}
                portfolios={portfolios}
                isEdit={isEdit}
                deleteAllFiles={deleteAllFiles}
                handleConfirm={this.handleConfirm}
                handleUpdateSetting={this.handleUpdateSetting}
                handleIsEdit={this.handleIsEdit}
                handleIsNotEdit={this.handleIsNotEdit}
                saveUpdateSetting={this.saveUpdateSetting}
                cancelUpdateSetting={this.cancelUpdateSetting}
                handleFetchPor={this.handleFetchPor}
                handleChangeField={this.handleChangeField}
                handleSelectUpdate={this.handleSelectUpdate}
              />
            </Grid.Column> : null}
        </Grid>
      </Ref>
      {/* <Modal size='tiny' open={true} onClose={this.handleClose} centered={false}> */}
      {/* <Modal size='tiny' open={show} onClose={this.handleClose} centered={false}> */}
      {/* <Modal.Header>新增</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Group widths='equal'>
                <Form.Field control={Input} label='客戶編號' placeholder='客戶編號' onChange={this.handleChangeModal} name='uidClient' value={formModal.uidClient || ''} />
                <Form.Field control={Input} label='中文姓名' placeholder='中文姓名' onChange={this.handleChangeModal} name='name_chtClient' value={formModal.name_chtClient || ''} />
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleClose} >關閉</Button>
            <Button primary icon='checkmark' labelPosition='right' onClick={this.handleSubmit} content='新增' loading={loading} />
          </Modal.Actions>
        </Modal> */}

      <Modal isOpen={show} toggle={this.handleClose} centered={false}>
        <ModalHeader>新增</ModalHeader>
        <ModalBody><Form>
          <Form.Group widths='equal'>
            <Form.Field control={Input} label='客戶編號' placeholder='客戶編號' onChange={this.handleChangeModal} name='uidClient' value={formModal.uidClient || ''} />
            <Form.Field control={Input} label='中文姓名' placeholder='中文姓名' onChange={this.handleChangeModal} name='name_chtClient' value={formModal.name_chtClient || ''} />
          </Form.Group>
        </Form></ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button onClick={this.handleClose} >關閉</Button>
          <Button primary icon='checkmark' labelPosition='right' onClick={this.handleSubmit} content='新增' loading={loading} />
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={confirm}
        toggle={() => this.setState({ confirm: false })}
        size="lg"
        className="mh-100 h-90 mw-100 w-90"
      >
        <ModalBody>
          確定要刪除這筆資料嗎？
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button onClick={() => this.setState({ buttonLoading: true }, () => this.handleRemove())} inverted color='red' icon='delete' loading={buttonLoading} content='刪除' />
          <Button onClick={() => this.setState({ confirm: false })} content='取消' />
        </ModalFooter>

      </Modal>
      {/* <Modal isOpen={show} toggle={this.handleClose} centered={false}>
        <ModalHeader>更換照片</ModalHeader>
        <ModalBody>

        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button onClick={this.handleClose} >關閉</Button>
          <Button primary icon='checkmark' labelPosition='right' onClick={this.handleSubmit} content='新增' loading={loading} />
        </ModalFooter>
      </Modal> */}

      {/* <Confirm
        header='確定刪除?'
        content='確定刪除?'
        cancelButton='關閉'
        confirmButton='刪除'
        open={confirm}
        onCancel={() => this.setState({ confirm: false })}
        onConfirm={this.handleRemove}
        size='large'
        centered={true} />
      {this.state.isUploading && <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 10000,
        width: '100vw',
        height: '100vh',
        background: 'rgba(0,0,0,0.87)',
        overflow: 'hodden'
      }}> */}
      {/* <Progress percent={this.state.progress} indicating error={this.state.error} progress />
    </div>} */}
    </div > </div >);

  }
}


// {listType === "1" ? '': <Sticky context={this.contextRef}>
// <Header inverted={companyObj.inverted} as='h3'>1-2 投資列表</Header>
// <Form size='small'>
//   {/* <Input labelPosition='left' > */}
//   {/* <Label color={'teal'}> <Icon name='newspaper' />表示</Label> */}
//   <Select style={{
//     minWidth: '100px',
//     // borderTopLeftRadius: '0',
//     // borderBottomLeftRadius: '0'
//   }} compact
//     options={bgSel}
//     onChange={this.handleChange}
//     disabled={isEdit}
//     value={bgId}
//     name='bgId' />
//   <br />
//   <Select style={{
//     minWidth: '100px',
//   }}
//     placeholder='Select your option'
//     options={selOptions}
//   />
//   <br />
//   <br />
//   <Grid.Row columns='equal'>
//     <Grid.Column width={2}>
//       <Header content='產品類別' />
//     </Grid.Column>
//     <Select
//       options={productType1Opt} name='productType1' onChange={this.handleChange} value={value1} />
//     <br />
//     <Select
//       options={productType2Opt} name='productType2' onChange={this.handleChange} value={value2} disabled={disabled2} />
//     <br />
//     <Select
//       options={productType3Opt} name='productType3' onChange={this.handleChange} value={value3} disabled={disabled3} />
//     <br />
//     <Select
//       options={productType4Opt} name='productType4' onChange={this.handleChange} value={value4} disabled={disabled4} />
//     <br />
//   </Grid.Row>
//   <br />
//   <Grid.Row columns='equal'>
//     <Grid.Column width={2}>
//       <Header content='供應商' />
//     </Grid.Column>
//     <Select options={providerOpt} name='providerType' onChange={this.handleChange} value={valueProvider} />
//     <Grid.Column width={2}>
//       <Header content='AGENT' />
//     </Grid.Column>
//     <Select options={productType4Opt} name='productType4' onChange={this.handleChange} value={valueAgent} />
//     <br />
//   </Grid.Row>
//   <Grid.Row columns='equal'>
//     <Grid.Column width={2}>
//       <Header content='版型' />
//     </Grid.Column>
//     <Select options={objPortfolioTemplate} name='portfolioType' onChange={this.handleChange} value={valueType} />
//     <Grid.Column width={2}>
//       <Header content='客戶' />
//     </Grid.Column>
//     <Select style={{
//       minWidth: '100px',
//     }}
//       placeholder='Select your alpha'
//       options={alphabetArr}
//     />
//     {/* <div style={{ ...style.flex, flexWrap: 'wrap', background: '#f5f5e6', width: '75%' }}>
//         {alphabetArr.map((item, index) => <span key={`${index}alphabetArr`} style={{ ...style.alphabetBtn }} className='alphabetBtn' onClick={this.handleClick} >{item}</span>)}
//       </div> */}
//     {/* <Button content='客戶' icon='add' color='purple' /> */}
//   </Grid.Row>
//   {!isEdit ?
//     <Button
//       style={{ marginTop: '4px' }}
//       // floated='right' 
//       size='tiny' primary onClick={() => this.setState({ show: true })} icon="add user" content="新增客戶" /> : null}{' '}
//   <br />
//   <Form.Input
//     style={{ marginTop: '4px', marginBottom: '4px' }}

//     placeholder='Search...'
//     disabled={isEdit}
//     onChange={this.handleSearch}
//     value={searchText}
//   />
// </Form>
// <select multiple className="form-control ui form fluid search dropdown"
//   style={{ height: '600px', width: '100%', fontSize: '11px' }} name="clientSel"
//   onChange={this.handleClick}
//   // value={[doc._id]}
//   disabled={isEdit}
// >
//   {resPortfolios && resPortfolios.map(item => <option key={item.key} className="item" value={item.value}>{item.text}</option>)}
// </select>
// </Sticky>}

// {listType === "1" ?'': <Header inverted={companyObj.inverted} floated='left' as='h2'>
//                   <Header.Content>
//                     {form && form.client_uid}&nbsp;
//                   {form && form.name_cht}
//                     <Header.Subheader>
//                       {/* {form && form.clientNum ? <><span className="text-muted">{getLabelName2(formSet, 'clientNum')}:</span>&nbsp;{form.clientNum}&nbsp;&nbsp;</> : ''} */}
//                       {form && form.clientTitleText ? <><span className="text-muted">職位:</span>&nbsp;{form.clientTitleText}&nbsp;&nbsp;</> : ''}
//                       {form && form.partTimeClientText ? <><span className="text-muted"></span>&nbsp;{form.partTimeClientText}&nbsp;&nbsp;</> : ''}
//                     </Header.Subheader>
//                     {/* <br /> */}
//                     <div style={{ marginTop: '15px' }}>
//                       {isEdit ? <Button positive size='small' onClick={this.saveUpdateSetting} icon='save' content='儲存' /> : <Button primary size='small' onClick={() => this.setState({ isEdit: true })} icon='edit' content="編輯" />}
//                       {isEdit ? <Button basic size='small' onClick={this.cancelUpdateSetting} icon='remove' content='取消' /> : null}
//                     </div>
//                   </Header.Content>
//                 </Header>
//               }