import React, { Component } from 'react'
import { Input, Form, Button, Label, Icon, Header, Select, Checkbox } from 'semantic-ui-react';
import ReactToPrint from "react-to-print";
import Moment from 'moment';

import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import { getOptionsSelByKey } from 'views/widgets/FieldsRender';
import { getWeekNumber, getYearWeek, getDateOfISOWeek, getWeekdayRangeArrDate, getWeekdayRangeArr } from '../../../widget/define/week';
import ClassRollcall from './ClassRollcall';
import SelectWeek from '../../widgets/SelectWeek';
import ModalPdf from 'widget/pdf/ModalPdf';
import Parse from 'widget/parse'

const weekdayArr2 = ['月', '火', '水', '木', '金'];

const isAttend = (obj) => {
  if (obj.attendStatus === "1" || obj.attendStatus === "2" || obj.attendStatus === "3" || obj.attendStatus === "4") {
    return 1;
  }
  return 0;
}

const funcAggDate = (snap, studentsClass) => {
  const arr = []; // 同一個陣列，一直塞進來
  // console.log(studentsClass)
  snap.forEach(doc => {
    // console.log(doc.data())
    var obj = { period: doc.objectId, ...doc };
    // console.log(obj)
    // if (studentsClass.indexOf(item => item._id === obj._id) !== -1) {
    // console.log(obj.weekday + obj.period ,obj.jpnName);

    // 把同一天的 都先集合在一起
    const findIndex = arr.findIndex(item => item.studentId === obj.studentId);
    if (findIndex !== -1) {
      // console.log(arr[findIndex], isAttend(obj))
      arr[findIndex]['attendStatus' + obj.weekday + obj.period] = obj.attendStatus;
      arr[findIndex]['attendStatusText' + obj.weekday + obj.period] = obj.attendStatusText;
      arr[findIndex]['daysCount' + obj.weekday] = arr[findIndex]['daysCount' + obj.weekday] || isAttend(obj);
      arr[findIndex]['hoursCount' + obj.weekday] = arr[findIndex]['hoursCount' + obj.weekday] + isAttend(obj);
    } else {
      arr.push({
        jpnName: obj.jpnName,
        studentId: obj.studentId,
        studentNum: obj.studentNum,
        ['eventDate' + obj.weekday]: obj.eventDate,
        ['classname' + obj.weekday]: obj.classname,
        ['weekday' + obj.weekday]: obj.weekday,
        ['attendStatus' + obj.weekday + obj.period]: obj.attendStatus,
        ['attendStatusText' + obj.weekday + obj.period]: obj.attendStatusText,
        ['daysCount' + obj.weekday]: isAttend(obj),
        ['hoursCount' + obj.weekday]: isAttend(obj),
      });
    }
    // }
  });

  // console.log(arr)
  return arr;
}
// const selType = [
//   // { key: '0', text: '', value: '0' },
//   { key: '1', text: '出席', value: '1' },
//   { key: '2', text: '班別', value: '2' },
//   { key: '3', text: '班別+出席', value: '3' },
// ]

export default class ClassRollcallPage extends Component {
  constructor(props) {
    super(props);
    const d = new Date();
    this.state = {
      classArr: [], // 現在所有的classroom
      selClassArr: [],
      // classId: '',
      className: '',
      loadingClassStu: true,
      classObj: {},
      yearWeek: getYearWeek(),
      year: d.getFullYear(),
      week: Number(getYearWeek().substr(6)),
      // minWeek: getMinWeek(props.semesterYear, props.semester),
      // maxWeek: getMaxWeek(props.semesterYear, props.semester),
      unsubArr: [null, null, null, null, null],
      // rollcallRollcallArr: [[], [], [], [], []],
      rollcallArr0: [],
      rollcallArr1: [],
      rollcallArr2: [],
      rollcallArr3: [],
      rollcallArr4: [],
      selTypeId: '1',
      isEdit: false,
      classroom: 'A',
      studentsClass: [],
      multiSelect: false
    };
  }

  componentDidMount() {
    // console.log('componentDidMount')
    this.handleInitial()

  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps')
    if (nextProps.semesterYear !== this.props.semesterYear ||
      nextProps.semester !== this.props.semester ||
      nextProps.classId !== this.props.classId) {
      this.handleInitial(nextProps)
    }
    // if (
    //   nextProps.semesterYear !== this.props.semesterYear ||
    //   nextProps.semester !== this.props.semester) {

    //   this.setState({
    //     yearWeek: getLatestYearWeek(nextProps.semesterYear, nextProps.semester),
    //     year: getLatestYear(nextProps.semesterYear, nextProps.semester),
    //     week: getLatestWeek(nextProps.semesterYear, nextProps.semester),
    //     // minWeek: getMinWeek(nextProps.semesterYear, nextProps.semester),
    //     // maxWeek: getMaxWeek(nextProps.semesterYear, nextProps.semester),
    //   }
    //     , () => this.handleDailyRollcall(nextProps)
    //   );
    // }
  }

  handleInitial = (props = this.props) => {
    // console.log('componentWillReceiveProps');
    const { classroom, studentsL, semesterYear, semester, handleLazyLoad } = props;
    if (!studentsL) {
      handleLazyLoad('students');
    } else {
      const classArr = classroom
        .filter(item => item.isEnableClass === true && item.semesterYear === semesterYear && item.semester === semester)
        .sort((a, b) => ('' + a.classSchool).localeCompare(b.classSchool));

      if (classArr.length) {
        // const classId = classArr[0]._id;
        const selClassArr = [];
        if (!classArr.length) {
          selClassArr.push({ key: 'empty', text: '-- 無クラス --', value: '' })
        } else {
          // selClassArr.push({ key: '-1', text: '-- 在籍生全部 --', value: '-1' })
          // selClassArr.push({ key: '-2', text: '-- 卒業生全部 --', value: '-2' })
          classArr.forEach(({ objectId, classSchool, teacherText, entryYear, entryText }) =>
            selClassArr.push({ key: objectId, text: `${classSchool}クラス ${entryYear}年${entryText} ${teacherText || ''}`, value: objectId }))
        }
        this.setState({ classArr, selClassArr }, () => this.handleClassroom(props));
      }
    }
  }

  handleClassroom = (props = this.props) => {
    const { students, classId } = this.props
    const { eventDate, classArr } = this.state
    const classObj = classArr.find(item => item.objectId === classId);

    if (!classObj) {
      return;
    }

    const className = classObj.classSchool;
    const period = classObj.classTime === '1' ? '0' : '4';

    const periodArr1 = classObj ? (classObj.classTime === '1' ? [0, 1, 2, 3] : [4, 5, 6, 7]) : [];
    const periodArr2 = classObj ? (classObj.classTime === '1' ? ['一', '二', '三', '四'] : ['五', '六', '七', '八']) : [];

    const weekday = eventDate && (new Date(eventDate).getDay() - 1) + "";
    const courseName = eventDate && period ? weekdayArr2[Number(weekday)] + periodArr2[period % 4] : '';


    const tmp = students.filter(item =>
      (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
      (item.applyResult === '1' || item.studentType === "2"));

    const studentsClass = tmp.filter(item => item.classSchool === className);



    const selPeriodArr = [];
    periodArr1.forEach((item, index) => selPeriodArr.push({ key: "p1" + index, text: periodArr2[index], value: item.toString() }));
    this.setState({ studentsClass, className, classObj, weekday, period, periodArr1, periodArr2, selPeriodArr, courseName },
      () => this.handleDailyRollcall(props)
    );
  }

  handleChange = (event, data) => {
    // console.log('handleChange')
    let fieldName = data.name;
    let fleldVal = data.value;
    // console.log(fieldName, fleldVal);

    if (fieldName === 'classId') { // 更新クラス的時候
      const { classroom } = this.props;
      const selClassroom = classroom.find(item => item.classSchool === fleldVal);
      const period = selClassroom ? (selClassroom.classTime === '1' ? "0" : "4") : "";
      this.setState({ [fieldName]: fleldVal, period }, () => this.handleClassroom());
    } else if (fieldName === 'selTypeId') {
      this.setState({ selTypeId: fleldVal });
    } else if (fieldName === 'multiSelect') {
      this.setState({ multiSelect: data.checked });
    } else {
      this.setState({ [fieldName]: fleldVal, loading: 1 });
    }
  }

  handleWeekChange = (e, { name, value }) => {
    // console.log(yearWeek, data);
    // return;
    const [year, Wweek] = value.split('-'); // 2019-W10 => 2019, W10
    const week = Wweek.slice(1); // W10 => 10
    this.setState({ yearWeek: value, year: Number(year), week: Number(week), loading: 1, rollcallArr0: [], rollcallArr1: [], rollcallArr2: [], rollcallArr3: [], rollcallArr4: [] }
      , () => this.handleDailyRollcall(this.props));
  };

  handleDailyRollcall = (props = this.props) => {
    // console.log('handleDailyRollcall')
    const { companyObj, students, calendar } = props;
    const { className, unsubArr, studentsClass } = this.state;
    // this.setState({ calendarHolidayArr });

    const { timerId } = this.state
    clearTimeout(timerId);
    let timer = setTimeout(() => {

      if (students.length && className) {
        const { year, week } = this.state
        const weekStartDate = getDateOfISOWeek(year, week, 0);
        const weekDateRange2 = getWeekdayRangeArr(weekStartDate);
        // console.log(weekDateRange2)

        // arrCalendar 有的話 就是放假
        let arrCalendar = calendar.filter(item => item.eventDate === weekDateRange2[0] && item.status === "3");
        // unsubArr.forEach(item => item && item.unsub && item.unsub());
        const unsub0 = !arrCalendar.length && Parse.queryData('Rollcall', { companyId: companyObj.objectId, eventDate: weekDateRange2[0] }).then(snap => {
          const data = funcAggDate(snap, studentsClass);
          this.setState({ rollcallArr0: data });
        }, err => {
          console.log(`Encountered error: ${err}`);
        });
        // firebase.firestore().collectionGroup('Rollcall')
        //   .where('eventDate', '==', weekDateRange2[0]) //.where('classname','==', className)
        //   .get().then(snap => {
        //     const data = funcAggDate(snap, studentsClass);
        //     this.setState({ rollcallArr0: data });
        //   }, err => {
        //     console.log(`Encountered error: ${err}`);
        //   });
        arrCalendar = calendar.filter(item => item.eventDate === weekDateRange2[1] && item.status === "3")
        const unsub1 = !arrCalendar.length && Parse.queryData('Rollcall', { companyId: companyObj.objectId, eventDate: weekDateRange2[1] }).then(snap => {
          const data = funcAggDate(snap, studentsClass);
          this.setState({ rollcallArr1: data });
        }, err => {
          console.log(`Encountered error: ${err}`);
        });
        // firebase.firestore().collectionGroup('Rollcall')
        //   .where('eventDate', '==', weekDateRange2[1])
        //   .get().then(snap => {
        //     const data = funcAggDate(snap, studentsClass);
        //     this.setState({ rollcallArr1: data });
        //   }, err => {
        //     console.log(`Encountered error: ${err}`);
        //   });
        arrCalendar = calendar.filter(item => item.eventDate === weekDateRange2[2] && item.status === "3")
        const unsub2 = !arrCalendar.length && Parse.queryData('Rollcall', { companyId: companyObj.objectId, eventDate: weekDateRange2[2] }).then(snap => {
          const data = funcAggDate(snap, studentsClass);
          this.setState({ rollcallArr2: data });
        }, err => {
          console.log(`Encountered error: ${err}`);
        });
        // firebase.firestore().collectionGroup('Rollcall')
        //   .where('eventDate', '==', weekDateRange2[2])
        //   .get().then(snap => {
        //     const data = funcAggDate(snap);
        //     this.setState({ rollcallArr2: data });
        //   }, err => {
        //     console.log(`Encountered error: ${err}`);
        //   });
        arrCalendar = calendar.filter(item => item.eventDate === weekDateRange2[3] && item.status === "3")
        const unsub3 = !arrCalendar.length && Parse.queryData('Rollcall', { companyId: companyObj.objectId, eventDate: weekDateRange2[3] }).then(snap => {
          const data = funcAggDate(snap, studentsClass);
          this.setState({ rollcallArr3: data });
        }, err => {
          console.log(`Encountered error: ${err}`);
        });
        // firebase.firestore().collectionGroup('Rollcall')
        //   .where('eventDate', '==', weekDateRange2[3])
        //   .get().then(snap => {
        //     const data = funcAggDate(snap, studentsClass);
        //     this.setState({ rollcallArr3: data });
        //   }, err => {
        //     console.log(`Encountered error: ${err}`);
        //   });
        arrCalendar = calendar.filter(item => item.eventDate === weekDateRange2[4] && item.status === "3")
        const unsub4 = !arrCalendar.length && Parse.queryData('Rollcall', { companyId: companyObj.objectId, eventDate: weekDateRange2[4] }).then(snap => {
          const data = funcAggDate(snap, studentsClass);
          this.setState({ rollcallArr4: data });
        }, err => {
          console.log(`Encountered error: ${err}`);
        });
        // firebase.firestore().collectionGroup('Rollcall')
        //   .where('eventDate', '==', weekDateRange2[4])
        //   .get().then(snap => {
        //     const data = funcAggDate(snap, studentsClass);
        //     this.setState({ rollcallArr4: data });
        //   }, err => {
        //     console.log(`Encountered error: ${err}`);
        //   });
        // this.setState({ unsubArr: [unsub0, unsub1, unsub2, unsub3, unsub4] });
      }
    }, 1500);
    this.setState({ timerId: timer })
  }

  render() {
    const { optSet, semesterYear, semester, classId } = this.props;
    const { studentsClass, selClassArr, classObj,
      // classArr, weekday, period, selPeriodArr, courseName, eventDate, calendarHolidayArr,
      //  loadingClassStu, attendStatusArr, seatOrderArr, classroomId, rollcallArr, 
      yearWeek, minWeek, maxWeek, selTypeId, isEdit, classroom, multiSelect,
      year, week, rollcallArr0, rollcallArr1, rollcallArr2, rollcallArr3, rollcallArr4 } = this.state;
    const weekStartDate = getDateOfISOWeek(year, week, 0);
    // // construct date range  from monday to friday of specific week
    const weekDateRange = getWeekdayRangeArrDate(weekStartDate);
    const weekDateRange2 = getWeekdayRangeArr(weekStartDate);

    let firstDay = Moment(weekDateRange[0]).set('date', 1).format('YYYY-MM-DD');
    // console.log(firstDay)
    const firstdayWeek = getWeekNumber(new Date(firstDay));
    // console.log(firstdayWeek)
    const firstdayweekStartDate = getDateOfISOWeek(firstdayWeek[0], firstdayWeek[1], 0);
    const firstweekDateRange = getWeekdayRangeArr(firstdayweekStartDate);
    // console.log(firstweekDateRange)
    let difMonth = (firstDay === firstweekDateRange[1] || firstDay === firstweekDateRange[2] || firstDay === firstweekDateRange[3] || firstDay === firstweekDateRange[4]) ? 1 : 0
    // console.log(difMonth)

    const classSchool = getOptionsSelByKey(optSet, "classSchool");
    return (<div style={{ padding: '2%' }} ref={el => (this.componentRef = el)}>
      <Header as='h2' floated='left'>週別出席簿</Header>
      <div style={{ float: 'right' }}>
        <Checkbox label='點名複選' name='multiSelect' onClick={this.handleChange} defaultChecked={multiSelect} />
        &nbsp;&nbsp;&nbsp;
        {/* <Input labelPosition='left' >
          <Label color={'blue'}> <Icon name='building' />クラス</Label>
          <Select style={{
            minWidth: '100px',
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0'
          }} compact options={selClassArr} onChange={this.handleChange} value={classId} name='classId' />
        </Input> */}
        {/* 
        <Input labelPosition='left' style={{ marginRight: 20 }}>
          <Label color='violet'><Icon name='building' />クラス</Label>
          <Form.Select
            name='classroom'
            value={classroom}
            options={classSchool}
            onChange={this.handleChange} />
        </Input> */}
        &nbsp;&nbsp;&nbsp;
        <SelectWeek
          name='selweek'
          yearWeek={yearWeek}
          // minWeek={minWeek}
          // maxWeek={maxWeek}
          handleWeekChange={this.handleWeekChange}
        />        &nbsp;&nbsp;&nbsp;
        {/* <Input labelPosition='left' >
          <Label color={'orange'}> <Icon name='newspaper' />表示</Label>
          <Select style={{
            minWidth: '100px',
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0'
          }} compact options={selType} onChange={this.handleChange} value={selTypeId} name='selTypeId' />
        </Input>&nbsp;&nbsp;&nbsp; */}
        {/* {isEdit ? <Button positive onClick={() => this.saveUpdateSetting()} icon='save' content='保存' /> :
          <Button size='small' color='green' onClick={() => this.setState({ isEdit: true })} icon='edit' className="no-print" content="編集" />} */}
        {/* {isEdit ? <Button basic onClick={() => this.setState({ isEdit: false })} icon='times' content='キャンセル' /> : null}
        <ReactToPrint
          trigger={(e) => <Button
            content='印刷'
            // label='&nbsp;'
            color='orange' icon='print'
            size='small'
            floated='right'
            className='no-print'
          />}
          content={() => this.componentRef}
          pageStyle={"@page { size: auto;  margin:5mm; }*{font-family: 'Noto Serif JP', serif;}"}
        /> */}
        {/* <ModalPdf
          {...this.props}
          // ref={(ref) => { this.pdfModal = ref }}
          codekey={'att6'}
          floated='right'
          params={ClassRollcall}
        /> */}
        <ExportTableToExcelButton floated='right' size='small' />
      </div>
      <div style={{ clear: 'both' }}></div>
      <ClassRollcall
        {...this.props}
        // action={'0'}
        studentsClass={studentsClass}
        classObj={classObj}
        year={year} week={week}
        // rollcallArr={rollcallArr}
        weekDateRange={weekDateRange}
        weekDateRange2={weekDateRange2}
        rollcallArr0={rollcallArr0}
        rollcallArr1={rollcallArr1}
        rollcallArr2={rollcallArr2}
        rollcallArr3={rollcallArr3}
        rollcallArr4={rollcallArr4}
        selTypeId={selTypeId}
        multiSelect={multiSelect}
        difMonth={difMonth}
      />
    </div >);
  }
};
