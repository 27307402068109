import React, { Component } from 'react';
import { Container, Select, Table, Button, Image } from 'semantic-ui-react'
import Moment from 'moment';
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import { showField, showFieldText, getOptionTextByValue } from 'views/widgets/FieldsRender';
import { semesterArr } from '../../../widget/define/define'
import Parse from '../../../widget/parse'

const style = {
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    placeItems: 'center',
    placeContent: 'center',
    border: '1px solid rgba(34,36,38,.1)'
  },
  gridItem: {
    border: '1px solid rgba(34,36,38,.1)',
    // padding: 10,
    boxSizing: 'border-box',
    wordBreak: 'break-word'
  },
  gridBox: {
    textAlign: 'center',
    border: '1px solid rgba(34,36,38,.1)',
    minWidth: '313.75px',
    boxSizing: 'border-box',
    fontSize: '20px',
  },
  gridHeader: {
    width: '25%',
    padding: '10px 0',
    borderRight: '1px solid rgba(34,36,38,.1)',
  },
  gridContent: {
    width: '75%',
    padding: '10px 0',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

}

const getData = (item2, contentArr, titleIdx, optSet, semesterYear, semester) => {
  if (contentArr[titleIdx] === 'birthday') { // 生日
    return Moment(item2.birthday).format("YYYY/MM/DD");
  } else if (contentArr[titleIdx].constructor === Array) { //国籍
    return `${item2[contentArr[titleIdx][0]] ? getOptionTextByValue(optSet, contentArr[titleIdx][0], item2[contentArr[titleIdx][0]]) : ''}   ${item2[contentArr[titleIdx][1]] ? getOptionTextByValue(optSet, contentArr[titleIdx][1], item2[contentArr[titleIdx][1]]) : ''}`;
  } else if (contentArr[titleIdx] === 'photo') { // 写真
    return <Image style={{ margin: '0 auto' }} size="small" src={item2.photo || 'https://react.semantic-ui.com/images/wireframe/square-image.png'} />;
  } else if (contentArr[titleIdx] === 'jpnName') { // 氏名
    return <a href={`/registeredStudents/${item2._id}`} target="_blank" rel="noopener noreferrer">{item2[contentArr[titleIdx]] || '(なし)'}</a>;
  } else if (contentArr[titleIdx] === 'studentId') { // 番号
    return `${semesterYear.substr(-2)}(${semester})${item2[contentArr[titleIdx]] || '(なし)'}`;
  } else { // 
    return `${item2[contentArr[titleIdx]] || ''}`;
  }
};

export default class PhotoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yearsemester: `${props.semesterYear},${props.semester}`,
      year: props.semesterYear,
      semes: props.semester,
      students: []
    };
  }

  async componentDidMount() {
    const { semesterYear, semester, companyObj } = this.props

    const data = await Parse.queryData('Students', { companyId: companyObj.objectId });
    this.setState({ students: data }, () => this.handleFetch());

  }
  handleFetch = async () => {
    const { semesterYear, semester } = this.props
    const { students } = this.state

    const tmp = students.filter(item =>
      (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
      (item.applyResult === '1' && item.studentType !== "2"));
    const studentArr = tmp.filter(item => item.semesterYear === semesterYear && item.semester === semester);

    this.setState({ studentArr }
    )
  }
  handleChange = (event, { name, value }) => {
    const { match, history, students } = this.props

    if (name === 'yearsemester') {
      const year = value.substring(0, 4);
      const semes = value.substring(5);
      const yearsemester = value;
      const tmp = students.filter(item =>
        (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
        (item.applyResult === '1' || item.studentType === "2"));
      const studentArr = tmp.filter(item => item.semesterYear === year && item.semester === semes); // 找到這班的所有人

      this.setState({ year, semes, yearsemester, studentArr }
        // () => this.studentSelect()
      );
    }
  };

  render() {
    const { optSet, semesterYear, semester, students } = this.props;
    const { yearsemester, year, semes, studentArr } = this.state;
    let semesArr = semesterArr

    // const semesterInNumber = semester === 'S' ? '4' : '10';
    // const studentArr = students.sort((a, b) => a.semester - b.semester).sort((a, b) => a.semesterYear - b.semesterYear);

    const numTotal = studentArr && studentArr.length;
    const numPerRow = 6;
    // const titleArr = ['番号', '氏名', '写真', '国籍', '生年月日', '住所', '日本住所'];
    // const contentArr = ['studentId', 'jpnName', 'photo', ['nationality', 'gender'], 'birthday', 'homeAddr', 'jpnAddr'];

    const titleArr = ['番号', '氏名', '写真', '国籍', '生年月日', '住所'];
    const contentArr = ['studentId', 'jpnName', 'photo', ['nationality', 'gender'], 'birthday', 'homeAddr'];

    return (<div style={{ padding: '10px', overflowX: 'scroll' }}>
      <div style={{ marginBottom: '16px' }}>
        <ModalPdf
          {...this.props}
          // ref={(ref) => { this.pdfModal = ref }}
          codekey="table31"
          // codekey="photoListPdf"
          params={studentArr}
        />
        <ExportTableToExcelButton floated='right' size='small' />
        <Select style={{
          marginBottom: '4px',
          maxWidth: '200px',
        }} compact
          floated='right'
          fluid
          options={semesArr}
          onChange={this.handleChange}
          // disabled={isEdit}
          value={yearsemester}
          name='yearsemester' />
      </div>
      <div ref={el => (this.componentRef = el)} className="">
        <h1 style={{ float: 'left' }}>
          {/* {semesterYear} 年 {semesterInNumber} 月生  */}
        在籍生生徒写真</h1>
        {/* <div style={{ float: 'right' }}> */}
        <div style={{ clear: 'both' }}></div>
        <Table celled striped size="small" textAlign='center' structured id='excel'>
          {studentArr && studentArr.map((item, rowIdx) =>
            rowIdx % numPerRow === 0 ?
              <tbody key={'key' + rowIdx} style={{ borderBottom: '1px solid rgba(34,36,38,.1)' }}
              // className={rowIdx % 3 === 0 ? "page-break" : ""}
              >
                {titleArr.map((item1, titleIdx) =>
                  <tr key={'key' + titleIdx}>
                    <td width={2} style={{ whiteSpace: 'nowrap' }}>{item1}</td>
                    {studentArr
                      .slice(rowIdx, rowIdx + numPerRow <= numTotal ? (rowIdx + numPerRow) : (rowIdx + (numTotal % numPerRow)))
                      .map(item2 =>
                        <td key={item2._id} width={2} style={{ wordBreak: 'break-word' }}>
                          {getData(item2, contentArr, titleIdx, optSet, item2.semesterYear, item2.semester)}
                        </td>)}
                  </tr>
                )}
              </tbody>
              : null)}
        </Table>
      </div>
    </div >);
  }
}