import React, { Component } from 'react'
import { Form, Tabs, Tab, Grid, Menu, Icon } from 'semantic-ui-react';

import { renderSettingField, renderTextValue } from 'views/widgets/FieldsRender';

import GraduateStudent from './List/GraduateStudent';
import ListTabs0 from './ListTabs0';
import ListTabs from './ListTabs';
import ListTabs2 from './ListTabs2';

const menuTitle = (str1, str2 = '', LEN = 4) =>
  <>
    <div style={{ marginBottom: '8px' }}>{str1}</div>
    {str2 ? <>{str2.substr(0, LEN)}</> : null}
    {str2.length >= LEN ? <><br />{str2.substr(LEN, LEN)}</> : null}
    {str2.length >= LEN * 2 ? <><br />{str2.substr(LEN * 2, LEN)}</> : null}
    {str2.length >= LEN * 3 ? <><br />{str2.substr(LEN * 3, LEN)}</> : null}
    {str2.length >= LEN * 4 ? <><br />{str2.substr(LEN * 4, LEN)}</> : null}
  </>;

const panes = [
  { name: 'list2a', icon: 'address book', text: '3-0-①', text2: '基本情報' },
  { name: 'list2b', icon: 'book', text: '3-0-②', text2: '学業情報' },
  { name: 'list2c', icon: 'globe', text: '3-0-③', text2: '生活指導' },
  { name: 'list2f', icon: 'browser', text: '3-0-④', text2: '成績出席' },
  { name: 'list2e', icon: 'university', text: '3-0-⑤', text2: '特別指導' },
  // { name: 'list2g', icon: 'unordered list', text: '3-0-⑥', text2: 'テスト一覧' },
  // { name: 'list2h', icon: 'ticket', text: '3-0-⑦', text2: '在留予定' },
  { name: 'list2i', icon: 'conversation', text: '3-0-⑥', text2: '指導記録' },
  { name: 'list2i', icon: 'bed', text: '3-0-⑦', text2: '寮' }, //⑩
  { name: 'list2d', icon: 'sticky note', text: '3-0-⑧', text2: '書類印刷' }, // 個人書類
];

class StudentViewTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: props.match.params.activeIndex1 || '0',
      isResetTabs: false
    };
  }

  renderFieldNoLabel = (level, docData) =>
    this.props.isEdit ?
      <div style={{ padding: '6px' }}><span className="text-muted"></span>{renderSettingField(this.props.optSet, level, docData)}</div>
      : <div keyname={level.key}><span className="text-muted"></span>{renderTextValue(this.props.optSet, level, docData)}</div>;

  // handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  handleTabChange = (e, { activeindex }) => {
    const { match, doc } = this.props
    const { list = "1" } = match.params;
    this.props.history.push(`/admin/StudentPage/${list}/${doc.objectId}/${activeindex}`);
    this.setState({ activeIndex: activeindex + '' })
  }
  render() {
    const { isEdit, statusKey, list = '1', companyObj } = this.props;
    const { activeIndex } = this.state;

    return (<>
      {/* <Grid.Row>
        <Grid.Column> */}
      {list === '2' || list === '3' ? <>
        <Menu icon='labeled' compact pointing inverted={companyObj.inverted}>
          {panes.map(({ name, icon, text, text2 }, index) =>
            <Menu.Item
              activeindex={index}
              key={name + index}
              name={name}
              active={activeIndex === index + ''}
              onClick={this.handleTabChange}
            >
              <Icon name={icon} />
              {menuTitle(text, text2, 5)}
            </Menu.Item>)}
        </Menu>
        <br />
        <br />
      </> : null}

      {statusKey === 'graduateSummary' ? <GraduateStudent
        {...this.props}
        isEdit={isEdit}
        renderText={this.renderText}
      /> : ''}

      {list === '0' ?
        <ListTabs0
          {...this.props}
          isEdit={isEdit}
          renderFieldNoLabel={this.renderFieldNoLabel}
          listType='1'
          activeIndex1={activeIndex}
        /> : null}
      {list === '1' ?
        <ListTabs
          {...this.props}
          isEdit={isEdit}
          renderFieldNoLabel={this.renderFieldNoLabel}
          listType='1'
          activeIndex1={activeIndex}
        /> : null}
      {list === '2' || list === '3' ?
        <ListTabs2
          {...this.props}
          isEdit={isEdit}
          renderFieldNoLabel={this.renderFieldNoLabel}
          activeIndex1={activeIndex}
        /> : null}
      {/* </Grid.Column>
      </Grid.Row> */}
    </>);
  }
}

export default StudentViewTabs;