import React from 'react'
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react';

import shortParagraph from './shortParagraph.png'
import intl from 'react-intl-universal';

export const DimmerImg = ({ style = {}, text = intl.get('読み込み中') + '...' }) =>
  <Segment style={style}>
    <Dimmer active inverted>
      <Loader inverted><span style={{ color: '#999' }}>{text}</span></Loader>
    </Dimmer>
    <Image src={shortParagraph} />
  </Segment>

const MyDimmer = ({ text = intl.get('ページの読み込み') + '...' }) =>
  <Dimmer active inverted>
    <Loader >
      {/* {companyObj.name} {companyObj.sectorText}管理系統 <br /> */}
      <span style={{ color: '#999' }}>
        {text}
      </span>
      {/* <Image src="icon_mai_load.png" size="small" /> */}
    </Loader>
  </Dimmer>

export default MyDimmer;
