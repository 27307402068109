import React, { Component } from 'react';
import { Grid, Header, Card } from 'semantic-ui-react';

import { showField } from 'views/widgets/FieldsRender';
import { Row, Col } from 'reactstrap';
// import {
//   Card, CardImg, CardText, CardBody,
//   CardTitle, CardSubtitle, CardHeader, CardLink
// } from "reactstrap";

export default class List3Student extends Component {
  render() {
    return (<>
      <Header as='h2' dividing
        content='学歴/日本語学習歴'
        style={{ backgroundColor: 'lavender' }}
      // subheader='調査時間：2017年4月から 2018年12月 まで'
      />
      <br />
      {/* <Grid columns='equal'> */}
      <Row>
        <Col sm='6'>
          <Card fluid>
            <Card.Content header='最終学歷' className='card-title' />
            {/* <CardHeader tag="h5" > 最終学歷</CardHeader>
            <CardBody> */}
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col sm='6'>{showField(this.props, "schoDis")}</Col>
                <Col sm='6'>{showField(this.props, "schoolDepart")}</Col>
                {/* <Col>{showField(this.props, "finalEdu")}</Col>
                  <Col>{showField(this.props, "schoolDepart")}</Col> */}
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col sm='6'>{showField(this.props, "finEduGra")}</Col>
                <Col sm='6'>{showField(this.props, "graDate")}</Col>
                {/* <Col>{showField(this.props, "studiedYear")}</Col>
                  <Col>{showField(this.props, "graduateYm")}</Col> */}
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "graSchoolName").length ? showField(this.props, "graSchoolName") : showField(this.props, "graSchoolName")}</Col>
                {/* </Grid> */}
              </Row>
            </Card.Content>
            {/* </CardBody> */}
          </Card>
        </Col>
        <Col sm='6'>
          <Card fluid>
            <Card.Content header='日本語学習歷' className='card-title' />
            {/* <CardHeader tag="h5" > 日本語学習歷</CardHeader>
            <CardBody> */}
            <Card.Content>
              {/* <Grid columns='equal'> */}
              <Col>{showField(this.props, "japLangLearnHour2")}</Col>
              {/* <Col>日本語資格</Col> */}
              {/* </Grid> */}
            </Card.Content>
            {/* </CardBody> */}
          </Card>
        </Col>
      </Row>
      {/* </Grid> */}
    </>);
  }
}

