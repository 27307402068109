import jsPDF from '../pdf/JspdfMyFonts';

import { repairList1, repairList2, repairList3, repairList4, repairList5, repairList6 } from './pdfAutorepair';

// const d = new Date();
// const nowDate = `${d.getFullYear() - 1911}年${d.getMonth() + 1}月${d.getDate()}日`;

const data = (props) => {
  // const { formSet, optSet, value, arrObj, companyObj, codeForm, nowform, params, printDate, lightType, selectData, nowservice, form } = props;
  const { nowform } = props
  let doc = new jsPDF({ format: 'a4', unit: 'pt' });
  if (nowform === "1") {
    repairList1(doc);//, datetime);
  } else if (nowform === '2') {
    repairList2(doc);//, datetime);
  } else if (nowform === '3') {
    repairList3(doc);//, datetime);
  } else if (nowform === '4') {
    repairList4(doc);//, datetime);
  } else if (nowform === '5') {
    repairList5(doc);//, datetime);
  } else if (nowform === '6') {
    repairList6(doc);//, datetime);
  }


  return doc
}

export default data;
