import React, { Component } from 'react'
import { Container, Icon, Table, Button, Modal, Header, Form, Pagination, Dropdown, Confirm, Label, Input, Select, TextArea, Grid, Divider } from 'semantic-ui-react'
import _ from 'lodash'
import MyModalTable from '../../widgets/MyModalTable';
import ModalPdf from 'widget/pdf/ModalPdf';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import Moment from 'moment';
import Parse from 'widget/parse'


const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipse: {
    // boxSizong: 'border-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}

export default class ClassReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      author: '',
      confirmOpen: false,
      number: 0,
      classArr: [],
      selClassArr: [],
      classReport: [],
      show: false,
      form: {
        eventDate: this.getToday()
      },
      confirm: false,
    }
  }

  componentDidMount() {
    this.handleFetch();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.semesterYear !== this.props.semesterYear ||
      nextProps.semester !== this.props.semester ||
      nextProps.classId !== this.props.classId) {
      this.handleFetch(nextProps)
    }
  }
  // getSnapshotBeforeUpdate(prevProps, prevState) {
  //   // console.log('getSnapshotBeforeUpdate');
  //   if (prevState.classSchool !== this.state.classSchool) {
  //     console.log('change');
  //     this.handleFetch(prevProps);
  //     return {};
  //   }
  //   return null
  // }
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   // console.log('componentDidUpdate');
  //   if (snapshot !== null) {
  //     this.handleFetch(prevProps);
  //   }
  // }

  handleFetch = (props = this.props) => {
    const { companyObj, classId, classroom, classroomL, handleLazyLoad, semester, semesterYear } = props;
    if (!classroomL) {
      handleLazyLoad('Classroom', 'classroom');
    } else {
      // const classObj = classroom.find(item => item._id === classId);
      // const className = classObj.classSchool;
      // firebase.firestore().collection(`Classroom`).where('classSchool', '==', className)
      //   .where('semesterYear', '==', semesterYear)
      //   .where('semester', '==', semester).get().then(snap => {
      //     var promises = [];
      //     snap.forEach(item => {
      //       var promise = firebase.firestore().collection(`Classroom/${item.id}/Situation`)
      //         .where('classSchool', '==', className)
      //         .get().then(snapshot => {
      //           const classData = []
      //           snapshot.forEach(fileItem => classData.push({ _id: fileItem.id, ...fileItem.data() }));
      //           classData.sort((a, b) => Moment(b.eventDate) - Moment(a.eventDate))
      //           return { _id: item.id, ...item.data(), classData };
      //         });
      //       promises.push(promise);
      //     });
      //     Promise.all(promises).then(results => {
      //       // console.log('all promises were done')
      //       console.log(results);
      //       this.setState({ classSchool: className, classList: results, loading: false })
      //     }).catch(error => {
      //       console.log(error);
      //     });

      //   }, err => {
      //     console.log(`Encountered error: ${err}`);
      //   });
      console.log(classId)
      if (classId !== '-1') {
        const classObj = classroom.find(item => item.objectId === classId)
        Parse.queryData('Situation', { companyId: companyObj.objectId, classId }, { orderBy: 'eventDate_descend' }).then(snapshot => {
          const classData = []
          snapshot.forEach(fileItem => classData.push({ ...classObj, objectId: fileItem.objectId, ...fileItem }));
          this.setState({ classSchool: classObj.classSchool, classData, loading: false })
        });
        // firebase.firestore().collection(`Classroom/${classId}/Situation`)
        //   .get().then(snapshot => {
        //     const classData = []
        //     snapshot.forEach(fileItem => classData.push({ ...classObj, _id: fileItem.id, ...fileItem.data() }));
        //     classData.sort((a, b) => Moment(b.eventDate) - Moment(a.eventDate))
        //     this.setState({ classSchool: classObj.classSchool, classData, loading: false })
        //   });
      }
    }
  }

  handleChange = (event, data) => {
    const { name, value, options } = data

    this.setState({ form: { ...this.state.form, [name]: value } })
    // console.log(this.state.form);
  }

  handleEdit = (classReportObj) => {
    console.log(classReportObj);
    this.setState({ show: true, form: { ...classReportObj } });
  }

  handleOpen = (classReportObj) => this.setState({ show: true, form: { eventDate: this.getToday(), classSchool: this.state.classSchool } });

  handleClose = () => this.setState({ show: false, form: { eventDate: this.getToday(), classSchool: this.state.classSchool } });

  showConfirm = (classReportObj) => this.setState({ confirm: true, form: { ...classReportObj } });

  hideConfirm = () => this.setState({ confirm: false, form: { eventDate: this.getToday() } });

  handleSubmit = async () => {
    const { firebase, classroom, classId, semesterYear, semester } = this.props;
    const { form, classData, classSchool } = this.state;
    const db = firebase.firestore();

    this.setState({ loading: true });
    const newRows = [...classData];
    if (!form._id) {
      const docRef = db.collection(`Classroom/${classId}/Situation`).doc();
      const obj = {
        ...form,
        _id: docRef.id,
        classId,
        classSchool,
        semesterYear,
        semester,
        createdAt: new Date()
      };
      // console.log(classId);
      // console.log(obj);
      newRows.push(obj);
      await docRef.set(obj);
    } else {
      const updObj = {
        ...form,
        updatedAt: new Date()
      }
      await db.collection(`Classroom/${classId}/Situation`).doc(form._id).update(updObj);
      let index = newRows.findIndex((item => item._id === form._id));
      newRows[index] = form;
    }

    this.setState({ classData: newRows, loading: false });
    this.handleClose();
    this.handleFetch();
  }

  handleRemove = async () => {
    const { firebase, classId } = this.props
    const { form, classData } = this.state;
    await firebase.firestore().collection(`Classroom/${classId}/Situation`).doc(form._id).delete();
    const newRows = [...classData];
    let index = newRows.findIndex((item => item._id === form._id));
    newRows.splice(index, 1);
    this.setState({ classData: newRows });

    this.handleClose();
    this.hideConfirm();
    this.handleFetch();
  }

  getToday = () => {
    return new Date().toISOString().split('T')[0];
  };

  render() {
    const { companyObj, classId } = this.props;
    const { selClassArr, classReport, form, loading, classData, classSchool, } = this.state

    return (
      <div>
        <div>
          <Header as='h2' floated='left'>{classSchool} クラス状況報告</Header>
          <div style={{ ...style.flexCenter, float: 'right' }}>
            {/* <Input labelPosition='left'>
              <Label color={'blue'}> <Icon name='building' />クラス</Label>
              <Select style={{
                minWidth: '300px',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0'
              }} compact options={selClassArr} onChange={this.handleChange} value={classSchool} name='classId' />
            </Input> */}
            <Button color='blue' inverted={companyObj.inverted} icon='add' content='追加' size='small' onClick={this.handleOpen} style={{ marginLeft: 15 }} />
            <div style={{ ...style.flexCenter, minWidth: 220 }}>
              <ExportTableToExcelButton size='small' />
              <ModalPdf
                {...this.props}
                // ref={(ref) => { this.pdfModal = ref }}
                codekey="classReportPdf"
                params={classData}
              />
            </div>
          </div>
          <div style={{ clear: 'both' }}></div>
        </div>

        <Table id='excel' celled structured style={{ marginBottom: 25 }} textAlign='center'>
          <Table.Header>
            <Table.Row textAlign='center'>
              <Table.HeaderCell width={4}>日付</Table.HeaderCell>
              <Table.HeaderCell width={4}>先生</Table.HeaderCell>
              <Table.HeaderCell width={2}>クラス</Table.HeaderCell>
              <Table.HeaderCell width={6}>進度</Table.HeaderCell>
              <Table.HeaderCell rowSpan='2' width={2} style={{ minWidth: 75 }}>操作</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell colSpan='2' textAlign='center'>担任連絡事項</Table.HeaderCell>
              <Table.HeaderCell colSpan='2' textAlign='center'>校方指示</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {classData && classData.length ? classData.map((item1, index1) => <React.Fragment key={`trr${index1}`}>
              <Table.Row>
                <Table.Cell width={4}>{item1.eventDate}</Table.Cell>
                <Table.Cell width={4}>{item1.teachingText}</Table.Cell>
                <Table.Cell width={2}>{item1.classSchool}</Table.Cell>
                <Table.Cell width={6}>{item1.progression || ''}</Table.Cell>
                <Table.Cell width={2} textAlign='center' rowSpan='2'>
                  {/* <Button inverted={companyObj.inverted} color='green' size='mini' onClick={this.handleOpen}>詳細</Button> */}
                  <Button style={{ margin: 5 }} inverted={companyObj.inverted} color='green' size='mini' icon='edit' onClick={() => this.handleEdit(item1)} />
                  <Button style={{ margin: 5 }} inverted={companyObj.inverted} color='red' size='mini' icon='delete' onClick={() => this.showConfirm(item1)} />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell colSpan='2' textAlign='center'>
                  <div style={{ maxWidth: '100%', maxHeight: '76px', overflowY: 'auto', wordBreak: 'break-all' }}>{item1.report || ''}</div>
                </Table.Cell>
                <Table.Cell colSpan='2' textAlign='center'>
                  <div style={{ maxWidth: '100%', maxHeight: '76px', overflowY: 'auto', wordBreak: 'break-all' }}>{item1.assignment || ''}</div>
                </Table.Cell>
              </Table.Row>
            </React.Fragment>) : <Table.Row><Table.Cell rowSpan='2' colSpan='5' textAlign='center'>(資料無し)</Table.Cell></Table.Row>}
          </Table.Body>
        </Table>
        <Modal open={this.state.show} onClose={this.handleClose}>
          <Modal.Header style={style.flexCenter}>
            <h2 style={{ margin: 0 }}>{form.objectId ? '修正' : '追加'}報告</h2>
            <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
          </Modal.Header>
          <Modal.Content>
            <Form>
              <Grid>
                <Grid.Row columns='equal'>
                  <Grid.Column>
                    <Form.Input
                      // control={Input}
                      label='日付'
                      type='date'
                      name='eventDate'
                      onChange={this.handleChange}
                      value={form.eventDate || ''}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      label='先生'
                      name='teachingText'
                      onChange={this.handleChange}
                      value={form.teacringText || ''}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      label='クラス'
                      name='classSchool'
                      onChange={this.handleChange}
                      value={form.classSchool || ''}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                  <Grid.Column>
                    <Form.Field
                      label='進度'
                      control={TextArea}
                      name='progression'
                      onChange={this.handleChange}
                      value={form.progression || ''}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                  <Grid.Column>
                    <Form.Field
                      label='担任連絡事項'
                      control={TextArea}
                      name='report'
                      onChange={this.handleChange}
                      value={form.report || ''}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                  <Grid.Column>
                    <Form.Field
                      label='校方指示'
                      control={TextArea}
                      name='assignment'
                      onChange={this.handleChange}
                      value={form.assignment || ''}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            {form.objectId ? <Button color='green' content='保存' icon='save' onClick={this.handleSubmit} loading={loading} />
              : <Button color='green' content='追加' icon='add' onClick={this.handleSubmit} loading={loading} />}
            <Button basic content='キャンセル' icon='close' onClick={this.handleClose} />
          </Modal.Actions>
        </Modal>
        <Confirm
          header='削除'
          content='削除してもいいですか?'
          cancelButton='いいえ'
          confirmButton='はい'
          open={this.state.confirm}
          onCancel={this.hideConfirm}
          onConfirm={this.handleRemove}
          size='mini'
          centered={false} />
      </div>
    )
  }
}