import React, { Component } from 'react'
import { Modal, Button, Form, Input, Select, TextArea } from 'semantic-ui-react';
import _ from 'lodash';

import delay from '../../../widget/define/delay'

const typeOption = [
  // { key: '0', text: '', value: '0' },
  { key: 'string', text: '文字 String', value: 'string' },
  { key: 'textarea', text: '文字框 TextArea', value: 'textarea' },
  { key: 'select', text: '下拉式 Select', value: 'select' },
  { key: 'date', text: '日付 Date', value: 'date' },
  { key: 'datetime', text: '日付時間 DateTime', value: 'datetime' },
  { key: 'week', text: '週間 Week', value: 'week' },
  { key: 'month', text: '年月 Year/Month', value: 'month' },
  { key: 'folder', text: '資料夾 Folder', value: 'folder' },
  // { key: 'nouse', text: '=== 以下停用 ===', value: 'nouse', disable: true },
  // { key: 'period', text: '期間 Year/Month', value: 'period' },
  // { key: 'number', text: '数字 Number', value: 'number' },
  // { key: 'boolean', text: '是/否 Boolean (true/false)', value: 'boolean' },
  // { key: 'radio', text: '單選 Radio', value: 'radio' },
  // { key: 'checkbox', text: '多選 Checkbox', value: 'checkbox' },
  // { key: 'url', text: '網址 url', value: 'url' },
  // { key: 'email', text: 'Eメール email', value: 'email' },
  // { key: 'tel', text: '電話 Tel', value: 'tel' },
  // { key: 'custom', text: '自訂 Custom', value: 'custom' },
  // { key: 'refer', text: '参照 Refer', value: 'refer' },
]

const tfOption = [
  { key: '1', text: 'True', value: true },
  { key: '0', text: 'False', value: false },
]

class FormSettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {}
    };
  }

  componentWillReceiveProps(nextProps) {   // update的時候用的
    // console.log(nextProps.setting)
    if (nextProps.setting) {
      if (nextProps.setting._id) { // 修正的時候
        this.setState({ form: { ...nextProps.setting, parentFolderId: nextProps.parentFolderObj._id || '', isEnable: true } });
      } else {
        this.setState({ form: { level: (nextProps.setting && nextProps.setting.level) || 0, type: 'string', parentFolderId: nextProps.parentFolderObj._id || '', isEnable: true } });
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.state;
    this.props.handleSet(form);
  }

  handleChange = (e, { name, value }) => this.setState({ form: { ...this.state.form, [name]: value } })

  handleSetKeyName = (e, { name, value }) => {
    e.persist();
    this.setState({ form: { ...this.state.form, key: value } }, () => {
      delay(() => {
        this.setState({ form: { ...this.state.form, key: _.camelCase(value.trim()) } });
      }, 300);
    });
  }

  getPeriodArr = () => {
    // create empty arrays - yearArr and monthArr and yearMonthArr
    const yearArr = [], monthArr = [], yearMonthArr = [];
    const maxYear = 50;
    // generate year 0 - maxYear and push into yearArr
    for (let i = 0; i <= maxYear; i++) {
      yearArr.push(i);
    }
    // generate month 0 - 12 and push into monthArr
    for (let i = 0; i <= 12; i++) {
      monthArr.push(i);
    }
    // combine yearArr and monthArr as yearMonthArr  
    yearArr.forEach(year =>
      monthArr.forEach(month =>
        yearMonthArr.push(
          {
            value: `${year}-${month}`,
            text: `${year}年${month}月`,
          }
        )
      )
    );
    return yearMonthArr;
  }

  render() {
    const { open, onHide, setting, optSet, formSet, parentFolderObj, loading, handleDelete } = this.props;
    const { form } = this.state;
    return (<Modal size='small' open={open} onClose={onHide} centered={false}>
      <Modal.Header>{parentFolderObj && parentFolderObj.label ? parentFolderObj.label : ''} {setting && setting.label} {setting && setting._id ? '編集' : '追加'}欄位</Modal.Header>
      <Modal.Content>
        <Form onSubmit={event => this.handleSubmit(event)}>
          <Form.Group widths='equal'>
            <Form.Field label='Key Name' name='key' control={Input} fluid value={form.key} onChange={this.handleSetKeyName} />
            <Form.Field label='是否啟用' name='isEnable' control={Select} options={tfOption} fluid value={form.isEnable} onChange={this.handleChange} />
            <Form.Field label='Type' control={Select} name='type' options={typeOption} placeholder='type' onChange={this.handleChange} value={form.type} />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Field label='Label (日)' name='label' control={Input} fluid value={form.label} placeholder="日文欄位名稱" onChange={this.handleChange} />
            <Form.Field label='Label (中)' name='labelCht' control={Input} fluid value={form.labelCht} placeholder="中文欄位名稱" onChange={this.handleChange} />
            <Form.Field label='Label (英)' name='labelEng' control={Input} fluid value={form.labelEng} placeholder="英文欄位名稱" onChange={this.handleChange} />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Field
              label='Parent Folder'
              name="parentFolderId"
              // disabled={form.type === 'folder' ? true : false}
              value={form.parentFolderId}
              onChange={this.handleChange}
              control={Select}
              options={[{ key: 'root', value: '', text: '[0] root: 根目録' }].concat(formSet.filter(item => (item.type === 'folder')).sort((a, b) => a.key.localeCompare(b.key)).map(({ _id, level, key, label }) => ({ key: _id, value: _id, text: `[${level}] ${key} ${label}` })))} />
            <Form.Field
              label='Print Form'
              name="isPrintForm"
              disabled={form.type === 'string' ? true : false}
              value={form.isPrintForm}
              onChange={this.handleChange}
              control={Select} options={tfOption}
            />
          </Form.Group>
          <Form.Group widths='equal'>
            {form.type === 'boolean' ? (<Form.Field label='Value' control={Select} options={tfOption} name='defaultValue' fluid placeholder='' onChange={this.handleChange} value={form.defaultValue} />) : ''}
            {form.type === 'number' ? (<Form.Field label='Value' name='defaultValue' type="number" control={Input} fluid onChange={this.handleChange} value={form.defaultValue} />) : ''}
            {form.type === 'datetime' ? (<Form.Field label='Value' name='defaultValue' type="datetime-local" control={Input} fluid onChange={this.handleChange} value={form.defaultValue} />) : ''}
            {form.type === 'week' ? (<Form.Field label='Value' name='defaultValue' type="week" control={Input} fluid onChange={this.handleChange} value={form.defaultValue} />) : ''}
            {form.type === 'email' ? (<Form.Field label='Value' name='defaultValue' type="email" control={Input} fluid onChange={this.handleChange} value={form.defaultValue} />) : ''}
            {form.type === 'url' ? (<Form.Field label='Value' name='defaultValue' type="url" control={Input} fluid onChange={this.handleChange} value={form.defaultValue} />) : ''}
            {form.type === 'tel' ? (<Form.Field label='Value' name='defaultValue' type="tel" control={Input} fluid onChange={this.handleChange} value={form.defaultValue} />) : ''}
            {form.type === 'string' ? (<Form.Field label='Value' name='defaultValue' type="text" control={Input} fluid onChange={this.handleChange} value={form.defaultValue} />) : ''}
            {form.type === 'textarea' ? (<Form.Field label='Value' name='defaultValue' control={TextArea} onChange={this.handleChange} value={form.defaultValue} />) : ''}
            {form.type === 'select' || form.type === 'radio' || form.type === 'checkbox' ?
              (<><Form.Field
                label='Value' name='value' control={Select} fluid onChange={this.handleChange} value={form.value}
                options={optSet.filter(item => (item.key !== 'option')).sort((a, b) => a.key && a.key.localeCompare(b.key)).map(({ _id, level, key, label, childNum }) =>
                  ({ key: _id, value: key, text: `[${level}] ${key} ${label} (選項数: ${childNum})` }))} />
                <Form.Field label='defaultValue' name='defaultValue' type="text" control={Input} fluid onChange={this.handleChange} value={form.defaultValue} /></>) : ''}
            {form.type === 'number' ? (<Form.Field label='Value' name='defaultValue' type="number" control={Input} fluid onChange={this.handleChange} value={form.defaultValue} />) : ''}
            {form.type === 'folder' ? (<Form.Field label='Value' name='defaultValue' type="text" control={Input} fluid onChange={this.handleChange} value={form.defaultValue} placeholder="" />) : ''}
            {form.type === 'date' ? (<Form.Field label='Value' name='defaultValue' type="date" control={Input} fluid onChange={this.handleChange} value={form.defaultValue} />) : ''}
            {form.type === 'month' ? (<Form.Field label='Value' name='defaultValue' type="month" control={Input} fluid onChange={this.handleChange} value={form.defaultValue} />) : ''}

            {/* {form.type === 'custom' ? null : ''} */}
            {/* {form.type === 'refer' ? (
                    <input
                      type="text"
                      name="defaultValue"
                      value={form.value}
                      onChange={this.handleChange}
                      placeholder="請輸入要参照的欄位key值"
                    />
                  ) : ''} */}
            {/* {form.type === 'period' ? (
                    <select
                      name="periodPicker"
                      value={form.value}
                      onChange={this.handleChange}
                    >
                      {this.getPeriodArr().map(({ value, text }) => <option key={value} value={value}>{text}</option>)}
                    </select>
                  ) : ''} */}
          </Form.Group>

          {/* {form.isPrintForm === '1' && form.type !== 'folder' ?
              <Form.Group>
                <Grid.Row>
                  <Grid.Column xs={12} sm={12}>
                    <label>プレビュ—區表格欄位</label>
                    <Select
                      placeholder="表格欄位"
                      value={form.printFormCol}
                      onChange={this.handleChange}
                      options={this.props.formSet.filter(item => (item.key !== 'folder')).sort((a, b) => a.key.localeCompare(b.key))}
                      isMulti
                      isSearchable
                    />
                  </Grid.Column>
                </Grid.Row>
              </Form.Group> : ''}
            {form.isPrintForm !== '1' && form.type !== 'folder' ?
              <Form.Group>
                <Grid.Row>
                  <Grid.Column xs={12} sm={6}>
                    <label>Column Width</label>
                    <input
                      type="number"
                      name="columnWidth"
                      value={form.columnWidth}
                      placeholder="欄位寬度"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Form.Group> : ''} */}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {setting && setting._id ? <Button type="button" floated='left' color='red' onClick={handleDelete} icon='delete' content='削除' /> : null}
        <Button type="button" loading={loading} onClick={this.handleSubmit} primary icon='checkmark' content={setting ? '保存' : '追加'} />
        <Button type="button" onClick={onHide} icon='remove' content='キャンセル' />
      </Modal.Actions>
    </Modal>);
  }
}

export default FormSettingsModal;
