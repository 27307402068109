import React, { Component, } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table'
import { Form, Button, Table, Grid, Header, Icon, Confirm, Select, Input, Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import intl from 'react-intl-universal';
import { Card, CardBody, CardHeader, Row, Col, } from "reactstrap";

import ModalPdf from 'widget/pdf/ModalPdf';
import { DimmerImg } from '../../layouts/Dimmer';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ModalUploadFiles from 'views/web/ModalUploadFiles';
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

import Portfolio1 from './Portfolio1';
import Portfolio2 from './Portfolio2';
import Portfolio3 from './Portfolio3';
import Portfolio4 from './Portfolio4';
import Portfolio5 from './Portfolio5';
import Portfolio6 from './Portfolio6';
import Portfolio7 from './Portfolio7';
import Portfolio8 from './Portfolio8';

const columns = () => ([
  { title: '預覽', field: 'src', render: rowData => <a href={rowData && rowData.theFile && rowData.theFile.url} target="_blank" rel="noopener noreferrer">{rowData.name}</a> },
  { title: '名稱', field: 'filename' },
]);

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

const funcObjFind2 = (obj, id) => {
  var ret = "";
  obj.forEach((entry) => {
    // console.log(entry);
    if (Number(entry.value) === id) {
      ret = entry.text;
    }
  });
  return ret;
}

const yyyymmdd = (d) => {
  var yyyy = d.getFullYear().toString();
  var mm = (d.getMonth() + 1).toString(); // getMonth() is zero-based
  var dd = d.getDate().toString();
  return yyyy + "-" + (mm[1] ? mm : "0" + mm[0]) + "-" + (dd[1] ? dd : "0" + dd[0]); // padding
};

const objInsuranceStatus = [
  { value: 1, text: '生效 In force' },
  { value: 2, text: '解約 Surrender' },
];

const objNowPhase = [
  { value: 1, text: '申請中' },
  { value: 2, text: '已生效' },
  { value: 3, text: '無效' },
];

const objHalfYear = [
  { value: "1", text: "一次" },
  { value: "2", text: "一年" },
  { value: "3", text: "上半年" },
  { value: "4", text: "下半年" },
];

//number加千分號
const commaSeparateNumber = (val) => {
  if (!val) return "0";
  val = val.toString().replace(/,/g, "");
  return Number(parseFloat(val)).toLocaleString('en');
}
//移除千分號
const commaToNumber = (val) => {
  if (!val) return "";
  val = val.toString().replace(/(\d+),(?=\d{3}(\D|$))/g, "$1");
  return parseFloat(val);
}

export default class List2Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productType1Opt: [],
      productType2Opt: [{ key: 'productType20', text: '-- 全選 --', value: '-1' }],
      productType3Opt: [{ key: 'productType30', text: '-- 全選 --', value: '-1' }],
      productType4Opt: [{ key: 'productType40', text: '-- 全選 --', value: '-1' }],
      disabled2: true,
      disabled3: true,
      disabled4: true,
      value1: '-1',
      value2: '-1',
      value3: '-1',
      value4: '-1',
      dataArr: [],
      dataArr2: [],
      docs: {},
      form: {},
      // formModal: {},
      show: false,
      portfolios: [],
      confirm: false,
      loading: true,
      portfoliosSel: '',
      title: '',
      content: '',
      filenames: [],
      downloadURLs: [],
      filedoc: [],
      fileArr: [],
      fileArrCheck: [],
      itemCount: [],
      loadingPorfolio: true,
      datePDM: '',
      moneyPDM: '',
      dateRP: '',
      moneyRP: '',
      afterArr: [],
      product1: [],
      buttonLoading: false,
      spread: false,
      spread1: false,
      spread2: false,
      paymentChange: false,
      PDMChange: false,
      RPChange: false,
      arrDelete: []
    };
  }

  async componentDidMount() {
    const { isEdit, companyObj } = this.props;
    const companyId = companyObj.objectId;
    // const { product1, portfolios } = ;
    // console.log(portfolios.filter(item => item.template_id === '8'))
    // console.log('componentDidMount');
    // console.log("me")
    Parse.queryData('provider', { companyId }).then(async (provider) => {
      this.setState({ provider })
    })



    Parse.queryData('product1', { companyId }).then(async (product1) => {
      const product2 = await Parse.queryData('product2', { companyId });
      const product3 = await Parse.queryData('product3', { companyId });
      const product4 = await Parse.queryData('product4', { companyId });
      // const product1 = await Parse.queryData('product1', { companyId });
      // const product2 = await Parse.queryData('product2', { companyId });
      // const product3 = await Parse.queryData('product3', { companyId });
      // const product4 = await Parse.queryData('product4', { companyId });

      if (product1 && product1.length) {
        const productType1Opt = [{ key: 'productType', text: '-- 全選 --', value: '-1' }].concat(product1.sort((a, b) => a.order - b.order).map(item => ({ key: item.objectId, text: item.value, value: item.objectId })));
        this.setState({
          productType1Opt,
          // value1: productType1Opt[0].value,
          // valueProvider: providerOpt[0].value,
          product1,
          product2,
          product3,
          product4
        })
      }
    });
    // let dataArr = portfolios.map(item => item = { ...item, tableData: {} })
    // Parse.queryData('portfolios', { companyId }).then(portfolios => {
    //   let copyData = portfolios.map(item => item = { ...item, copy: 1 })
    //   this.setState({ portfolios: copyData });
    // });
    Parse.queryData('portfolios', { companyId }).then(async (portfolios) => {
      const ic = await Parse.queryData('interestcondition', { companyId })
      this.setState({ ic, portfolios }, () => this.handleFetch())
    })


    // if (product1 && product1.length) {
    //   const productType1Opt = [{ key: 'productType', text: '-- 全選 --', value: '-1' }].concat(product1.sort((a, b) => a.orderId - b.orderId).map(item => ({ key: item._id, text: item.value, value: item._id })));
    //   this.setState({
    //     productType1Opt,
    //     // value1: productType1Opt[0].value,
    //     // valueProvider: providerOpt[0].value,
    //   })
    // }
    // this.setState({ product1, portfolios })

  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    // let copyData = this.props.portfolios.map(item => item = { ...item, copy: 1 })
    // this.setState({ portfolios: copyData });
    // let copyData = this.props.portfolios.map(item => item = { ...item, copy: 1 })
    // this.setState({ portfolios: copyData });
    this.handleFetch(nextProps, 0);
  }

  handleChange = (event, data) => {
    const { name, value, id, entry, arrPaymentRecord, newPaymentRecord, order } = data;
    const { formModal, form } = this.state
    // const {  } = this.props
    const { product1, product2, product3, product4, portfolios, } = this.state
    const { value1, value2, value3, value4, valueType, valueProvider } = this.state;
    let obj = {}
    if (name === 'productType1') {
      const productType2Opt = [{ key: 'productType20', text: '-- 全選 --', value: '-1' }].concat(product2.filter(item => item.product1_id === value)
        .sort((a, b) => a.orderId - b.orderId).map(item => ({ key: item.objectId, text: item.name_cht + ' (' + item.name_eng + ')', value: item.objectId })));
      obj = { value1: value, value2: '-1', value3: '-1', value4: '-1', disabled2: false, disabled3: true, disabled4: true, productType2Opt };
    } else if (name === 'productType2') {
      const productType3Opt = [{ key: 'productType30', text: '-- 全選 --', value: '-1' }].concat(product3.filter(item => item.product2_id === value)
        .sort((a, b) => a.orderId - b.orderId).map(item => ({ key: item.objectId, text: item.name_cht + ' (' + item.name_eng + ')', value: item.objectId })));
      obj = { value2: value, value3: '-1', value4: '-1', disabled3: false, disabled4: true, productType3Opt };
    } else if (name === 'productType3') {
      const productType4Opt = [{ key: 'productType40', text: '-- 全選 --', value: '-1' }].concat(product4.filter(item => item.product3_id === value)
        .sort((a, b) => a.orderId - b.orderId).map(item => ({ key: item.objectId, text: item.name_cht + ' (' + item.name_eng + ')', value: item.objectId })));
      obj = { value3: value, value4: '-1', disabled4: false, productType4Opt };
    } else if (name === 'productType4') {
      obj = { value4: value };
    } else {
      this.setState({ [name]: value })
    }
    // this.setState({
    //   // formModal: {
    //   //   ...formModal,
    //   //   [name]: value
    //   // }
    //   [name]: value,
    // });

    if (id === "input") {
      this.setState({ form: { ...form, [name]: value } })
    }

    if (name === 'portfoliosSel') {
      const { history, doc } = this.props;
      this.setState({ form: portfolios.find(item => item.objectId === value), docs: portfolios.find(item => item.objectId === value) }
        , () => history.push(`/admin/clientsPage/1/${doc.objectId}/1/${value}`)
      )
    }

    if (id === "payment") {
      let obj = entry
      let count = 0
      if (order || order === 0) {
        count = order
        obj = {
          ...obj,
          order: order + 1,
          [name]: value
        }
      } else {
        count = entry.order - 1
        obj = {
          ...obj,
          [name]: value
        }
      }
      arrPaymentRecord[count] = obj
      this.setState({ arrPaymentRecord, paymentChange: true })
    }

    this.setState({ ...obj })
  }


  handleFetchPor = async () => {
    const { companyObj } = this.props;
    const companyId = companyObj.objectId;
    const portfolios = await Parse.queryData('portfolios', { companyId });
    this.setState({ optPortfolios: portfolios, portfolios })
  }

  handleFetch = async (props = this.props, type = 0) => {
    const { match, history, doc, isEdit, companyObj } = props;
    const { afterservice, portfolios, ic } = this.state
    const { clientId, portfolioId = '' } = match.params;

    const companyId = companyObj.objectId;
    this.setState({ loading: true })
    let clientPor = []
    if (portfolioId === '') {
      this.setState({ loading: true, loadingPorfolio: true })
    }
    clientPor = portfolios.filter(item => item.client_id === doc.objectId)

    if (clientId) { // 每次換人或換投資項目，就會一直重抓，就先這樣吧
      // console.log('重抓投資陣列')  
      let form = {}
      // await db.collection(`company/${companyObj._id}/bg/${doc.bgId}/clients/${clientId}/portfolios`).get().then(snap => {
      // const data = [];
      // snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
      // const clientPor = await Parse.queryData('portfolios', { companyId, client_id: doc.objectId });
      // const clientPor = portfolios.filter(item => item.client_id === doc.objectId)
      let portfoliosSel = (clientPor && clientPor.length && clientPor[0].objectId) || '';
      form = clientPor[0] || {}
      let arrFundConfigure = []
      let arrPaymentRecord = []
      let arrReturnPrincipal = []
      let paymentDateMoney = []
      let giveInterestRecord = []

      if (portfolioId) {
        form = clientPor.find(item => item.objectId === portfolioId)
        // console.log(portfolioId, data, form);
        portfoliosSel = (form && form.objectId) || '';
        const promises = []
        promises.push(Parse.queryData('fundConfigure', { companyId, portfoliosId: portfolioId }))
        promises.push(Parse.queryData('paymentRecord', { companyId, portfoliosId: portfolioId }))
        promises.push(Parse.queryData('returnPrincipal', { companyId, portfoliosId: portfolioId }))
        promises.push(Parse.queryData('paymentDateMoney', { companyId, portfoliosId: portfolioId }))
        promises.push(Parse.queryData('giveInterestRecord', { companyId, portfoliosId: portfolioId }))
        const [fundConfigure, parsePaymentRecord, parseReturnPrincipal, parsepaymentDateMoney, parsegiveInterestRecord] = await Promise.all(promises)
        arrPaymentRecord = parsePaymentRecord
        arrReturnPrincipal = parseReturnPrincipal
        paymentDateMoney = parsepaymentDateMoney
        giveInterestRecord = parsegiveInterestRecord
        // const fundConfigure = await Parse.queryData('fundConfigure', { companyId, portfoliosId: portfolioId });
        // arrPaymentRecord = await Parse.queryData('paymentRecord', { companyId, portfoliosId: portfolioId });
        // arrReturnPrincipal = await Parse.queryData('returnPrincipal', { companyId, portfoliosId: portfolioId });
        // paymentDateMoney = await Parse.queryData('paymentDateMoney', { companyId, portfoliosId: portfolioId });
        // giveInterestRecord = await Parse.queryData('giveInterestRecord', { companyId, portfoliosId: portfolioId });
        arrFundConfigure = fundConfigure.sort((a, b) => new Date(b.PriceDate) - new Date(a.PriceDate));

        if (paymentDateMoney) {
          let arrPaymentDateMoney = ''
          paymentDateMoney.forEach(item => arrPaymentDateMoney += `${item.date} $${commaSeparateNumber(item.money)}\n`)
          form = {
            ...form,
            arrPaymentDateMoney,
          }
        }

        if (arrReturnPrincipal) {
          let arrReturnPrincipalStr = ''
          arrReturnPrincipal.forEach(item => arrReturnPrincipalStr += `${item.date} $${commaSeparateNumber(item.money)}\n`)
          form = {
            ...form,
            arrReturnPrincipal: arrReturnPrincipalStr,
          }
        }

        if (form.interest_condition) {
          form = {
            ...form,
            interest_condition_text: (ic && ic.length && ic.find(item => item.objectId === form.interest_condition) && ic.find(item => item.objectId === form.interest_condition).item) || ''
          }
        }

      } else if (!portfolioId) {
        history.push(`/admin/clientsPage/1/${doc.objectId}/1/${form.objectId}`)
      }
      this.setState({ clientPor, portfoliosSel, form, docs: form, loadingPorfolio: false, arrFundConfigure, arrPaymentRecord, arrReturnPrincipal, paymentDateMoney, giveInterestRecord });
      // if (form && form.uid && false) {
      //   const afterservice = await Parse.queryData('afterservice', { companyId });
      //   const data = afterservice.filter(item => item.portfolio.uid === form.uid)
      //   this.setState({ afterArr: data })
      // }

      // if (form && form.uid && afterservice) {
      //   const afterArr = afterservice.filter(item => item.portfolio_id === form.objectId)
      //   console.log(afterArr)
      //   this.setState({ afterArr })
      // }

      if (portfolioId) {
        const filedoc = await Parse.queryData('clientFile', { companyId, clientId: clientId, portfolioId: portfolioId });
        this.setState({ filedoc })
      }
    }
    this.setState({ loading: false, loadingPorfolio: false })
  }

  handleDelete = async () => {
    const { oldData } = this.state
    const { match, doc } = this.props;
    const { listType, clientId, portfolioId } = match.params;
    try {
      await Parse.deleteData('clientFile', oldData)
      this.setState({ oldData: {} })
    } catch (error) {
      console.log("handled by outer try-catch", error);
    }
  }


  handleChangeField = (e, data) => {
    const { form } = this.state
    if (data && data.id && data.id !== 'fieldFormGroup') {
      this.setState({ form: { ...form, [data.name]: data.checked === true ? '1' : '0' } })
    } else {
      if (e.target) {
        this.setState({ form: { ...form, [e.target.name]: e.target.value } })
      } else {
        if (typeof form[data.name] === 'object') {
          this.setState({ form: { ...form, [data.name]: e } })
        } else {
          this.setState({ form: { ...form, [data.name]: e.value } })
        }
      }
    }
  }

  handleSubmit = async () => {
    const { doc, history, companyObj, agent } = this.props;
    const { product4 } = this.state
    const { portfolios, value4, value1, value2, value3 } = this.state;
    const db = Parse.Object.extend("portfolios");
    const newDoc = new db();
    const docRef = await newDoc.save();
    const newRows = [...portfolios];
    const obj = {
      _id: docRef.id,
      id: docRef.id,
      objectId: docRef.id,
      agent_id: doc.agent_id || "",
      agent_text: doc.agent_text || (doc.agent_id && agent.find(item => item.objectId === doc.agent_id).name) || "",
      companyId: companyObj.id,
      companyName: companyObj.name,
      client_id: doc.objectId,
      client_uid: doc.uid,
      name_cht: doc.name_cht || "",
      name_eng: doc.name_eng || "",
      contactnum: doc.cellnum || '',
      email: doc.email || doc.email2 || '',
      product1_id: value1,
      product1_text: product4.find(item => item.objectId === value4).product1_text || "",
      product2_id: value2,
      product2_text: product4.find(item => item.objectId === value4).product2_text || "",
      product3_id: value3,
      product3_text: product4.find(item => item.objectId === value4).product3_text || "",
      product4_id: value4,
      product4_text: product4.find(item => item.objectId === value4).name_cht || "",
      template_id: product4.find(item => item.objectId === value4).template_id,
    }
    let data = portfolios
    data.push(obj)
    await Parse.saveData('portfolios', obj);
    newRows.push(obj);
    this.handleFetchPor()
    this.props.handleFetchPor()
    this.props.handleIsNotEdit()
    history.push(`/admin/clientsPage/1/${doc.objectId}/1/${obj.objectId}`)

    this.setState({ portfolios: data, loading: true, loadingPorfolio: true, value1: '-1', value2: '-1', value3: '-1', value4: '-1', disabled2: true, disabled3: true, disabled4: true, }, () => this.handleFetch(this.props, 1));
    this.handleClose();
  }


  handleOpen = (portfoliosObj) => {
    this.setState({ show: true });
  }

  handleClose = () => {
    this.setState({ show: false, transShow: false });
  }

  handleAlert = () => {
    window.alert("請選擇 產品類別4 再新增")
  }

  handleRemove = async () => {
    const { match, history, doc, isEdit, refetch, companyObj } = this.props;
    const { form, formModal, portfolios, value4, value1, value2, value3, filedoc } = this.state;
    const { clientId, portfolioId = '' } = match.params;

    if (filedoc.length > 0) {
      await Parse.destroyData('clientFile', { portfolioId: form.objectId })
      await Parse.destroyData('paymentRecord', { portfolioId: form.objectId })
      await Parse.destroyData('returnPrincipal', { portfolioId: form.objectId })
      await Parse.destroyData('fileCenter2', { fileCenter1Id: form.objectId })
      await Parse.destroyData('afterservice', { portfolio_id: form.objectId })
    }
    await Parse.deleteData('portfolios', form).then(() => this.handleFetchPor(), () => this.props.handleFetchPor())
    window.alert('刪除成功')
    history.push(`/admin/clientsPage/1/${doc.objectId}/1`)
    this.setState({ form: {}, filedoc: [], confirm: false, loading: true, loadingPorfolio: true, buttonLoading: false }, () => this.handleFetch(this.props, 1))
  }

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  handleUpdateSetting = (event, data) => {
    const { form, docs } = this.state;
    const { name, value } = data;
    console.log(data)
    // return;
    // doc[setting.key] = setting.value;
    if (this.isEmpty(form)) {
      this.setState({ form: { ...docs, [name]: value } });
    } else {
      this.setState({ form: { ...form, [name]: value } });
    }
  }

  handleSelectUpdate = (data) => {
    const { form, ic, provider } = this.state;
    const { agent } = this.props
    const { name, value, id } = data;
    if (provider.find(item => (item.objectId === value))) {
      const obj = provider.find(item => (item.objectId === value))
      this.setState({ form: { ...form, provider_chttext: obj.name_cht, provider_engtext: obj.name_eng, provider_id: obj.objectId } });
    } else if (ic.find(item => (item.objectId === value))) {
      const obj = ic.find(item => (item.objectId === value))
      this.setState({ form: { ...form, interest_condition: obj.objectId } });
    } else if (agent.find(item => item.objectId === value)) {
      let obj = agent.find(item => item.objectId === value)
      this.setState({ form: { ...form, [name]: obj.name, [id]: obj.objectId } })
    }
  }

  saveUpdateSetting = async () => {
    const { match, refetch, companyObj, agent } = this.props;
    const { form, isEdit, paymentChange, arrPaymentRecord, paymentDateMoney, arrReturnPrincipal, PDMChange, RPChange, arrDelete } = this.state;
    const { clientId, portfolioId = '' } = match.params;
    this.setState({ saveLoading: true })
    delete form.arrPaymentDateMoney
    delete form.interest_condition_text
    delete form.arrReturnPrincipal
    if (window.confirm('確定儲存？')) {

      if (paymentChange) {
        const promises = []
        for (let i = 0; i < arrPaymentRecord.length; i++) {
          if (arrPaymentRecord[i].objectId) {
            promises.push(Parse.saveData('paymentRecord', arrPaymentRecord[i]))
          } else {
            const db = Parse.Object.extend("paymentRecord");
            const newDoc = new db();
            const doc = await newDoc.save();
            let obj = {
              id: doc.id,
              _id: doc.id,
              objectId: doc.id,
              companyId: companyObj.objectId,
              companyName: companyObj.name,
              portfoliosId: form.objectId,
              ...arrPaymentRecord[i],
            }
            promises.push(Parse.saveData('paymentRecord', obj))
          }
        }
        await Promise.all(promises).catch(error => console.log("error", error));
      }

      if (PDMChange) {
        const promises = []
        for (let i = 0; i < paymentDateMoney.length; i++) {
          if (paymentDateMoney[i].objectId) {
            promises.push(Parse.saveData('paymentDateMoney', paymentDateMoney[i]))
          } else {
            const db = Parse.Object.extend("paymentDateMoney");
            const newDoc = new db();
            const doc = await newDoc.save();
            let obj = {
              id: doc.id,
              _id: doc.id,
              objectId: doc.id,
              companyId: companyObj.objectId,
              companyName: companyObj.name,
              portfoliosId: form.objectId,
              ...paymentDateMoney[i],
            }
            promises.push(Parse.saveData('paymentDateMoney', obj))
          }
        }
        await Promise.all(promises).catch(error => console.log("error", error));
      }

      if (RPChange) {
        const promises = []
        for (let i = 0; i < arrReturnPrincipal.length; i++) {
          if (arrReturnPrincipal[i].objectId) {
            promises.push(Parse.saveData('returnPrincipal', arrReturnPrincipal[i]))
          } else {
            const db = Parse.Object.extend("returnPrincipal");
            const newDoc = new db();
            const doc = await newDoc.save();
            let obj = {
              id: doc.id,
              _id: doc.id,
              objectId: doc.id,
              companyId: companyObj.objectId,
              companyName: companyObj.name,
              portfoliosId: form.objectId,
              ...arrReturnPrincipal[i],
            }
            promises.push(Parse.saveData('returnPrincipal', obj))
          }
        }
        await Promise.all(promises).catch(error => console.log("error", error));
      }

      if (arrDelete && arrDelete.length) {
        const promises = []
        for (let i = 0; i < arrDelete.length; i++) {
          if (arrDelete[i].obj.objectId) {
            promises.push(Parse.deleteData(arrDelete[i].dbName, arrDelete[i].obj))
          }
        }
        await Promise.all(promises).catch(error => console.log("error", error));
      }

      await Parse.saveData('portfolios', form).then(() => this.handleFetchPor(), () => this.props.handleFetchPor(), () => this.handleFetch());
      this.props.handleIsNotEdit()
      this.setState({ paymentChange: false, PDMChange: false, RPChange: false, arrDelete: [] })
    }
    this.setState({ saveLoading: false })
  }

  cancelUpdateSetting = () => {
    const { form, docs } = this.state;
    this.handleFetch(this.props, 1)
    this.props.handleIsNotEdit();
    this.setState({ value1: '-1', value2: '-1', value3: '-1', value4: '-1', disabled2: true, disabled3: true, disabled4: true, loadingPorfolio: true, paymentChange: false, PDMChange: false, RPChange: false, arrDelete: [] });
  }

  handleTransShow = () => this.setState({ transShow: true });

  handleChangeModal = (e, { name, value }) => this.setState({ formModal: { ...this.state.formModal, [name]: value } })

  portfolioTransform = async () => {
    const { clients } = this.props
    const { form, docs, formModal } = this.state;
    const { uidClient, name_chtClient } = formModal
    let obj = {}
    if (clients.find(item => item.uid === uidClient && item.name_cht === name_chtClient)) {
      if (window.confirm('確認轉移至此客戶?')) {
        this.setState({ loadingTrans: true })
        const clientData = clients.find(item => item.uid === uidClient && item.name_cht === name_chtClient)
        obj = {
          ...form,
          agent_id: clientData.agent_id || "",
          agent_text: clientData.agent_text || "",
          client_id: clientData.objectId,
          client_uid: clientData.uid,
          name_cht: clientData.name_cht || "",
          name_eng: clientData.name_eng || "",
        }
        await Parse.saveData('portfolios', obj).then(() => this.handleFetchPor(), () => this.props.handleFetchPor())
        this.props.handleIsNotEdit();
        this.setState({ value1: '-1', value2: '-1', value3: '-1', value4: '-1', disabled2: true, disabled3: true, disabled4: true, loadingPorfolio: true, paymentChange: false, transShow: false, loadingTrans: false });
      }
    } else {
      window.alert('找不到此客戶')
    }
    // this.handleFetchPor()
    // this.props.handleFetchPor()
    // this.handleFetch(this.props, 1)
  }

  handleIsSpread = () => {
    const { spread } = this.state
    this.setState({ spread: !spread })
  }

  handleIsSpread1 = () => {
    const { spread1 } = this.state
    this.setState({ spread1: !spread1 })
  }

  handleIsSpread2 = () => {
    const { spread2 } = this.state
    this.setState({ spread2: !spread2 })
  }

  showTable = (type) => {
    const { isEdit, } = this.props
    // const { ic } = 
    const { form = {}, ic = [], spread, spread1, arrFundConfigure, arrPaymentRecord, arrReturnPrincipal, paymentDateMoney, giveInterestRecord } = this.state

    if (type === 'GiveInterestRecord') { // 配息記錄==========================================================
      let all_total_principal = 0;

      //利率
      const calInterest = (day, principal, rate) => {
        if (!principal || principal === "")
          return "0";

        let interest = 0;

        // principal = principal.replace(/\,/g, '');
        interest = commaToNumber(principal) * Number(rate) / 100 / 365 * Number(day);
        interest = interest.toFixed(2);

        // if(typeof interest != "number"){
        //     return "0";
        // }

        return interest.toString();
      }

      // 6/30 or 12/31

      const next_end_date = (d) => {
        let r = new Date();
        if (d.getMonth() < 6) {
          r.setFullYear(d.getFullYear(), 5, 30);
        } else {
          r.setFullYear(d.getFullYear(), 11, 31);
        }
        return r;
      }

      // 7/1 or 1/1
      const next_start_date = (d) => {
        let r = new Date();
        if (d.getMonth() < 6) {
          r.setFullYear(d.getFullYear(), 6, 1);
        } else {
          r.setFullYear(d.getFullYear() + 1, 0, 1);
        }
        return r;
      }

      // 下個月一號
      // const next_helfyearly_start_date = (d) => {
      //   let r = new Date();
      //   r.setFullYear(d.getFullYear(), d.getMonth() + 1, 1)
      //   return r;
      // }

      // 下一年同天
      // const next_yearly_start_date = (d) => {
      //   let r = new Date();
      //   r.setFullYear(d.getFullYear(), d.getMonth() + 12, d.getDate())
      //   return r;
      // }

      const getDatePeriod = (d) => {
        if (d.getMonth() < 6) {
          return "上半年";
        } else {
          return "下半年";
        }
      }
      // console.log("asd");

      // start
      let arr_payment = paymentDateMoney;
      const icNow = ic.find(item => item.objectId === form.interest_condition);

      let arrdom = [];
      // let fixdom = [];
      let arrsmalldom = [];
      let totaldom = [];
      // console.log(ic)
      if (!icNow) {
        console.log("請選擇利率條件");
        // $("#giveInterestRecord-footer").html('請先選擇<a href="#ic-link">利率條件</a>');
      } else if (ic.interest_period === "0") { //不固定
        let arrData = giveInterestRecord;
        // console.log(arrData);
        if (arrData) {
          for (let i = 0; i < arrData.length; i++) {
            let entry = arrData[i];
            let start_date = new Date(arrData[i].gir_start_date);
            let end_date = new Date(arrData[i].gir_end_date);

            let timeDiff = Math.abs(start_date.getTime() - end_date.getTime());
            // let gir_diff_day = Math.ceil(timeDiff / (1000 * 3600 * 24))+1;
            let gir_diff_day = Math.ceil(timeDiff / (1000 * 3600 * 24));
            // arrData[i].gir_interest = calInterest(arrData[i].gir_diff_day, arrData[i].gir_principal, arrData[i].gir_interest_rate);
            let gir_interest = calInterest(arrData[i].gir_diff_day, arrData[i].gir_principal, arrData[i].gir_interest_rate) || "0";

            let dom = {
              gir_year: entry.gir_year,
              HalfYear: funcObjFind2(objHalfYear, entry.gir_halfyear),
              gir_principal: commaSeparateNumber(entry.gir_principal),
              start_date: entry.gir_start_date,
              end_date: yyyymmdd(end_date),
              gir_interest_rate: entry.gir_interest_rate,
              gir_diff_day: gir_diff_day,
              gir_interest: commaSeparateNumber(gir_interest),
              gir_amount_dispensed: commaSeparateNumber(entry.gir_amount_dispensed),
              gir_amount_dispensed_date: entry.gir_amount_dispensed_date,
              gir_marks: entry.gir_marks,
            };

            arrdom.push(dom);
          }
        }
        return (
          <Table celled >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan='12' style={{ textAlign: 'left' }}>配息紀錄</Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                {/* {isEdit ? <Table.HeaderCell style={{ width: '100px' }}>#</Table.HeaderCell> : null} */}
                <Table.HeaderCell>年度</Table.HeaderCell>
                <Table.HeaderCell>期別</Table.HeaderCell>
                <Table.HeaderCell>投資金額(本金)</Table.HeaderCell>
                <Table.HeaderCell>投資年期(起)</Table.HeaderCell>
                <Table.HeaderCell>投資年期(迄)</Table.HeaderCell>
                <Table.HeaderCell>年息率</Table.HeaderCell>
                <Table.HeaderCell>計息天數(365天)</Table.HeaderCell>
                <Table.HeaderCell>利息(美元)</Table.HeaderCell>
                <Table.HeaderCell>當期分派金額(美元)</Table.HeaderCell>
                <Table.HeaderCell>累積分派金額(美元)</Table.HeaderCell>
                <Table.HeaderCell>配息日期</Table.HeaderCell>
                <Table.HeaderCell>備註</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {/* {form.giveInterestRecord && form.giveInterestRecord.length && form.giveInterestRecord.map((entry) => (<Table.Row> */}
              {arrdom.map((entry) => (<Table.Row>
                <Table.Cell>{"" && "[x]"} &nbsp; {entry.order + " "} {entry.gir_year || ""}</Table.Cell>
                <Table.Cell>{entry.gir_year || ""}</Table.Cell>
                <Table.Cell>{entry.BidPrice || ""}</Table.Cell>
                {/* <Table.Cell>{funcObjFind2(objHalfYear, entry.gir_halfyear) || ""}</Table.Cell> */}
                <Table.Cell>{entry.gir_halfyear || ""}</Table.Cell>
                <Table.Cell>{commaSeparateNumber(entry.gir_principal) || ""}</Table.Cell>
                <Table.Cell>{entry.gir_start_date || ""}</Table.Cell>
                <Table.Cell>{entry.gir_end_date || ""}</Table.Cell>
                <Table.Cell>{entry.gir_interest_rate || ""}</Table.Cell>
                <Table.Cell>{entry.gir_diff_day || ""}</Table.Cell>
                <Table.Cell>$ {commaSeparateNumber(entry.gir_interest) || ""}</Table.Cell>
                <Table.Cell>$ {commaSeparateNumber(entry.gir_amount_dispensed) || ""}</Table.Cell>
                <Table.Cell>{entry.gir_amount_dispensed_date || ""}</Table.Cell>
                <Table.Cell>{entry.gir_marks || ""}</Table.Cell>
              </Table.Row>))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='2' >小計</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell colSpan='2'>總計</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
                <Table.HeaderCell >{commaSeparateNumber(all_total_principal)}</Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>)
      } else { // 固定配息
        let all_total_principal = 0;
        let all_total_diff_day = 0;
        let all_total_interest = 0;
        let all_total_amount_dispensed = 0;
        let all_total_payment = 0;

        if (arr_payment) {
          for (let i = 0; i < arr_payment.length; i++) { // 有幾筆投資的資料
            all_total_payment += Number(arr_payment[i].money);
          }
        }
        let arr_retprincipal = arrReturnPrincipal; // 本金返還 date, money
        let str_interest = "";
        if (arr_payment) {
          for (let i = 0; i < arr_payment.length; i++) { // 有幾筆投資的資料
            let order_id = arr_payment[i].order;
            let principal = arr_payment[i].money || 0;
            // console.log(arr_payment[i]);

            let now_date = new Date();
            let start_date = "";
            let end_date = "";
            let principal_show = "";
            let j = 0;

            let total_principal = 0;
            let total_diff_day = 0;
            let total_interest = 0;
            let total_amount_dispensed = 0;

            total_principal += Number(principal);
            do {
              if (!start_date) { // 是第一次進來的時候
                start_date = new Date(arr_payment[i].date);
                end_date = next_end_date(start_date);
                principal_show = "$" + commaSeparateNumber(principal);
              } else {
                start_date = next_start_date(start_date);
                end_date = next_end_date(start_date);
                principal_show = "";
              }

              if (now_date.getTime() < end_date.getTime()) {
                // 現在的日子比之前少的話 就跳掉
                // end date會是之前的日子 如果之前的結束時間 大於現在的時間 就不用再新增下一筆資料
                break;
              }
              // console.log("start date");
              // console.log(start_date);
              // console.log("end date");
              // console.log(end_date);

              let interest = 0;

              // 找配息的利率
              let interest_rate = 0;
              let diff_day = 0;
              if (!!icNow) {
                let i1 = (parseInt(j / 2) + 1).toString();
                if (!!icNow['y' + i1]) {
                  interest_rate = icNow['y' + i1];
                  // console.log("got interest_rate "+ interest_rate +" "+i1);
                }
                // console.log("interest_rate: "+ interest_rate);

                let timeDiff = Math.abs(start_date.getTime() - end_date.getTime());
                // let diff_day = Math.ceil(timeDiff / (1000 * 3600 * 24))+1;
                diff_day = Math.ceil(timeDiff / (1000 * 3600 * 24));
                if (diff_day >= 180 && diff_day <= 185) {
                  diff_day = 182.5;
                }

                interest = calInterest(diff_day, principal, interest_rate); //money*interest_rate/100/365*diff_day;
                str_interest = "$" + commaSeparateNumber(interest);
              }
              // console.log("principal: "+ principal);
              // console.log("interest: "+ interest);

              let datePeriod = getDatePeriod(start_date);

              let amount_dispensed = 0; // 當期分派金額
              let amount_dispensed_date = 0; // 配息日期
              let marks = ""; // 備註
              if (!!giveInterestRecord && typeof giveInterestRecord === "object") {
                for (let k = 0; k < giveInterestRecord.length; k++) {
                  let tobj = giveInterestRecord[k];
                  if (Number(tobj.order) === Number(order_id) && Number(tobj.order_id2) === Number(j)) {
                    amount_dispensed = tobj.amount_dispensed;
                    amount_dispensed_date = tobj.amount_dispensed_date;
                    marks = tobj.marks;

                    break;
                  }
                }
              }
              // 本金返還
              // console.log("start_date: " + start_date);
              if (typeof arr_retprincipal === "object")
                for (let k = 0; k < arr_retprincipal.length; k++) {
                  let dd = arr_retprincipal[k].date;
                  if (!!dd) {
                    let ddd = new Date(dd);
                    if (ddd >= start_date && ddd < end_date) {
                      // console.log("dd: " + dd);

                      // let ret_principal = commaToNumber(arr_retprincipal[k].money)/arr_payment.length; // 每次的返還金 都平均還給各筆
                      // 本次匯款金額* (本次返還金/總匯款金額)
                      let ret_principal = principal * (commaToNumber(arr_retprincipal[k].money) / all_total_payment);

                      // commaToNumber(arr_retprincipal[k].money)/arr_payment.length; // 每次的返還金 都平均還給各筆
                      marks = marks + dd + " 返: $" + commaSeparateNumber(ret_principal);
                      // if(!!principal_show){
                      //     principal_show = principal_show + "<br />" + dd + "<br />返: $"+commaSeparateNumber(ret_principal);
                      // }
                      // else{
                      //     principal_show = dd + "<br />返: $"+commaSeparateNumber(ret_principal);
                      // }
                      // arr_retprincipal.splice(i, 1);

                      // 如果在這個時候 有本金返還，本金和利息要重算
                      let timeDiff1 = Math.abs(start_date.getTime() - ddd.getTime());
                      let diff_day1 = Math.ceil(timeDiff1 / (1000 * 3600 * 24)) + 1;
                      // let diff_day1 = Math.ceil(timeDiff1 / (1000 * 3600 * 24));
                      let interest1 = calInterest(diff_day1, principal, interest_rate);;

                      // principal = commaToNumber(principal) - commaToNumber(arr_retprincipal[k].money); // 減去已領的
                      principal = commaToNumber(principal) - commaToNumber(ret_principal); // 減去已領的
                      principal_show = "$" + commaSeparateNumber(principal);

                      let timeDiff2 = Math.abs(ddd.getTime() - end_date.getTime());
                      let diff_day2 = Math.ceil(timeDiff2 / (1000 * 3600 * 24)) - 1;
                      let interest2 = calInterest(diff_day2, principal, interest_rate);;
                      // console.log("interest1: " + interest1 + " interest2: " + interest2);
                      interest = parseFloat(interest1) + parseFloat(interest2);
                      str_interest = ("$" + commaSeparateNumber(interest) || "");
                      str_interest = str_interest + " ($" + commaSeparateNumber(interest1) + " + $" + commaSeparateNumber(interest2) + ")";
                      break;
                    }
                  }
                }
              total_diff_day += Number(diff_day);
              total_interest += parseFloat(interest);
              // console.log(total_interest)
              total_amount_dispensed += parseFloat(amount_dispensed);

              // 把要印的資料先用陣列存起來，在之後的return()一次印
              let dom = {
                whichYear: ("第 " + (Math.ceil((j + 1) / 2)) + " 年"),
                datePeriod,
                principal_show,
                start_date: (start_date && yyyymmdd(start_date)) || '',
                end_date: (end_date && yyyymmdd(end_date)) || '',
                interest_rate,
                diff_day,
                str_interest,
                total_interest: total_interest.toFixed(2),
                total_amount_dispensed,
                marks,
                flag: 0
              };

              arrdom.push(dom);
              j++;
            } while (now_date.getTime() > end_date.getTime());

            // 小計
            // 把要印的資料先用陣列存起來，在之後的return()一次印
            let dom = {
              principal: commaSeparateNumber(principal),
              total_diff_day: commaSeparateNumber(total_diff_day),
              total_interest: ('$' + commaSeparateNumber(total_interest)),
              total_amount_dispensed: commaSeparateNumber(total_amount_dispensed),
              flag: 1
            };
            arrdom.push(dom);
            // arrsmalldom.push(dom);

            // all_total_principal += total_principal;
            all_total_principal += commaToNumber(principal);
            all_total_diff_day += total_diff_day;
            all_total_interest += total_interest;
            all_total_amount_dispensed += total_amount_dispensed;
          }

          // commaSeparateNumber(all_total_diff_day)

          // 把要印的資料先用陣列存起來，在之後的return()一次印
          let dom = { //總計
            all_total_principal: commaSeparateNumber(all_total_principal),
            all_total_interest: commaSeparateNumber(all_total_interest),
            all_total_amount_dispensed: commaSeparateNumber(all_total_amount_dispensed),
          };
          totaldom.push(dom);

          // console.log(arrdom)
          // console.log(arrsmalldom)
          // console.log(totaldom)

          return (
            <Table celled >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='12' style={{ textAlign: 'left' }}>配息紀錄</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  {/* {isEdit ? <Table.HeaderCell style={{ width: '100px' }}>#</Table.HeaderCell> : null} */}
                  <Table.HeaderCell>年度</Table.HeaderCell>
                  <Table.HeaderCell>期別</Table.HeaderCell>
                  <Table.HeaderCell>投資金額(本金)</Table.HeaderCell>
                  <Table.HeaderCell>投資年期(起)</Table.HeaderCell>
                  <Table.HeaderCell>投資年期(迄)</Table.HeaderCell>
                  <Table.HeaderCell>年息率</Table.HeaderCell>
                  <Table.HeaderCell>計息天數(365天)</Table.HeaderCell>
                  <Table.HeaderCell>利息(美元)</Table.HeaderCell>
                  <Table.HeaderCell>當期分派金額(美元)</Table.HeaderCell>
                  <Table.HeaderCell>累積分派金額(美元)</Table.HeaderCell>
                  <Table.HeaderCell>配息日期</Table.HeaderCell>
                  <Table.HeaderCell>備註</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {/* {form.giveInterestRecord && form.giveInterestRecord.length && form.giveInterestRecord.map((entry) => (<Table.Row> */}
                {arrdom.map((entry) =>
                (entry.flag === 0 ? (<Table.Row>
                  {/* <Table.Cell>{"" && "[x]"} &nbsp; {entry.order_id + " "} {entry.gir_year || ""}</Table.Cell> */}
                  <Table.Cell>{entry.whichYear || ""}</Table.Cell>
                  <Table.Cell>{entry.datePeriod || ""}</Table.Cell>
                  <Table.Cell>{entry.principal_show || ""}</Table.Cell>
                  <Table.Cell>{entry.start_date || ""}</Table.Cell>
                  <Table.Cell>{entry.end_date || ""}</Table.Cell>
                  <Table.Cell>{entry.interest_rate || ""}%</Table.Cell>
                  <Table.Cell>{entry.diff_day || ""}</Table.Cell>
                  <Table.Cell>{entry.str_interest || ""}</Table.Cell>
                  <Table.Cell>${entry.total_interest || ""}</Table.Cell>
                  <Table.Cell>{entry.total_amount_dispensed || ""}</Table.Cell>
                  <Table.Cell>{entry.amount_dispensed_date || ""}</Table.Cell>
                  <Table.Cell>{entry.marks || ""}</Table.Cell>
                </Table.Row>) : (<Table.Row>
                  <Table.Cell colSpan='2' >小計</Table.Cell>
                  <Table.Cell >{entry.principal || ""}</Table.Cell>
                  <Table.Cell>-</Table.Cell>
                  <Table.Cell>-</Table.Cell>
                  <Table.Cell>-</Table.Cell>
                  <Table.Cell >{commaSeparateNumber(entry.total_diff_day) || ""}</Table.Cell>
                  <Table.Cell >{entry.total_interest || ""}</Table.Cell>
                  <Table.Cell >${commaSeparateNumber(entry.total_amount_dispensed) || ""}</Table.Cell>
                  <Table.Cell >${commaSeparateNumber(entry.total_amount_dispensed) || ""}</Table.Cell>
                  <Table.Cell>-</Table.Cell>
                  <Table.Cell>-</Table.Cell>
                </Table.Row>))
                )}
              </Table.Body>
              <Table.Footer>
                {totaldom && totaldom.length && totaldom[0] ?
                  <Table.Row>
                    <Table.HeaderCell colSpan='2'>總計</Table.HeaderCell>
                    <Table.HeaderCell >{commaSeparateNumber(totaldom[0].all_total_principal)}</Table.HeaderCell>
                    <Table.HeaderCell>-</Table.HeaderCell>
                    <Table.HeaderCell>-</Table.HeaderCell>
                    <Table.HeaderCell>-</Table.HeaderCell>
                    <Table.HeaderCell >{commaSeparateNumber(totaldom[0].all_total_interest)}</Table.HeaderCell>
                    <Table.HeaderCell >{commaSeparateNumber(totaldom[0].all_total_amount_dispensed)}</Table.HeaderCell>
                    <Table.HeaderCell >{commaSeparateNumber(totaldom[0].all_total_amount_dispensed)}</Table.HeaderCell>
                    <Table.HeaderCell >{commaSeparateNumber(totaldom[0].all_total_amount_dispensed)}</Table.HeaderCell>
                    <Table.HeaderCell>-</Table.HeaderCell>
                    <Table.HeaderCell>-</Table.HeaderCell>
                    {/* <Table.HeaderCell >{commaSeparateNumber(totaldom[0].aaa)}</Table.HeaderCell> */}
                  </Table.Row> : null}
              </Table.Footer>
            </Table>);
        }
      }
    } else if (type === 'FundConfigure') {
      let total_A_ValuationCurrencyValue = 0
      const showLastArrFundConfigure = arrFundConfigure.filter(item => item.PriceDate === arrFundConfigure[0].PriceDate)
      showLastArrFundConfigure.forEach(entry => {
        if (entry.UnitType === "A") {
          total_A_ValuationCurrencyValue = total_A_ValuationCurrencyValue + Number(entry.ValuationCurrencyValue);
        }
      })
      const total_ValuationCurrencyValue = (showLastArrFundConfigure && showLastArrFundConfigure.reduce((acc, item) => acc + Number(item.ValuationCurrencyValue), 0)) || 0;
      const account_invest_per = (total_ValuationCurrencyValue - Number(String(form.fpi_done_withdraw_money).replace(/,/g, '')) + Number(String(form.fpi_all_withdraw_money).replace(/,/g, ''))) / Number(String(form.fpi_done_withdraw_money).replace(/, /g, ''));
      let last_fpi_data_updatedate = "";
      return <>
        <Table stackable>
          <Table.Header>
            {/* <Table.Row>
            </Table.Row> */}
            <Table.HeaderCell colSpan='9' style={{ textAlign: 'left', cursor: 'pointer', backgroundColor: '#f07b51', fontSize: '17px' }} onClick={this.handleIsSpread}>基金配置</Table.HeaderCell>
            {spread ? <Table.Row>
              {/* {isEdit ? <Table.HeaderCell style={{ width: '100px' }}>#</Table.HeaderCell> : null} */}
              {/* <Table.HeaderCell>週期</Table.HeaderCell>
            <Table.HeaderCell>規劃繳費日期</Table.HeaderCell>
            <Table.HeaderCell>規劃繳費金額</Table.HeaderCell>
            <Table.HeaderCell>實際繳費日期</Table.HeaderCell>
            <Table.HeaderCell>實際繳費金額</Table.HeaderCell>
            <Table.HeaderCell>繳費方式</Table.HeaderCell>
            <Table.HeaderCell>繳費狀態</Table.HeaderCell>
            <Table.HeaderCell>備註</Table.HeaderCell> */}
              <Table.HeaderCell>現時基金配置</Table.HeaderCell>
              <Table.HeaderCell>Unit Type</Table.HeaderCell>
              <Table.HeaderCell>單價日期</Table.HeaderCell>
              <Table.HeaderCell>單價</Table.HeaderCell>
              <Table.HeaderCell>單位數</Table.HeaderCell>
              <Table.HeaderCell>幣別</Table.HeaderCell>
              <Table.HeaderCell>淨值</Table.HeaderCell>
              <Table.HeaderCell>結餘(USD)</Table.HeaderCell>
              <Table.HeaderCell>百分比</Table.HeaderCell>
            </Table.Row> : null}
          </Table.Header>
          {spread ? <Table.Body>
            {showLastArrFundConfigure && showLastArrFundConfigure.length && showLastArrFundConfigure.map((entry, i) => (<Table.Row>
              <Table.Cell active>{"" && "[刪除] "}[{i + 1}]&nbsp; {entry.FundDescription || ""}</Table.Cell>
              <Table.Cell>{entry.UnitType || ""}</Table.Cell>
              <Table.Cell>{entry.PriceDate || ""}</Table.Cell>
              <Table.Cell>{entry.BidPrice || ""}</Table.Cell>
              <Table.Cell>{commaSeparateNumber(entry.UnitsHeld) || ""}</Table.Cell>
              <Table.Cell>{entry.FundCurrency || ""}</Table.Cell>
              <Table.Cell>$ {commaSeparateNumber(entry.FundCurrencyValue) || ""}</Table.Cell>
              <Table.Cell>$ {commaSeparateNumber(entry.ValuationCurrencyValue) || ""}</Table.Cell>
              <Table.Cell>{commaSeparateNumber(entry.ValuationCurrencyValue / total_ValuationCurrencyValue * 100)} %</Table.Cell>
            </Table.Row>))}
          </Table.Body> : null}
          {spread ? <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='7' style={{ textAlign: 'right' }}>現時戶口總值</Table.HeaderCell>
              <Table.HeaderCell colSpan='2' >$ {commaSeparateNumber(total_ValuationCurrencyValue)}</Table.HeaderCell>
            </Table.Row>
          </Table.Footer> : null}
        </Table>
        {spread ? <Table className="Table">
          <Table.Header>
            <Table.Row>
              <Table.Cell active>現時定額供款</Table.Cell>
              <Table.Cell>{form.fpi_now_same_payment}</Table.Cell>
              <Table.Cell active>總提款金額</Table.Cell>
              <Table.Cell>{form.fpi_all_withdraw_money}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell active>已供款總額</Table.Cell>
              <Table.Cell>{form.fpi_done_withdraw_money}</Table.Cell>
              <Table.Cell active>可提款總額 (Total "A" UNITS)</Table.Cell>
              <Table.Cell>${total_A_ValuationCurrencyValue ? total_A_ValuationCurrencyValue.toFixed(2) : 0}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell active>友誠更新日期</Table.Cell>
              <Table.Cell>{showLastArrFundConfigure && showLastArrFundConfigure.length && showLastArrFundConfigure[0].ExtractTime}</Table.Cell>
              <Table.Cell active>帳戶投資本益比 %</Table.Cell>
              <Table.Cell>{commaSeparateNumber(account_invest_per)}</Table.Cell>
            </Table.Row>
          </Table.Header>
        </Table> : null}
      </>
    } else if (type === 'WithdrawBankAccount') {

      return (<Table >
        <Table.Header>
          <Table.Row >
            <Table.HeaderCell colSpan="8" class="warning" style={{ cursor: 'pointer', backgroundColor: '#f07b51', fontSize: '17px' }} onClick={this.handleIsSpread1}>常用提款受款銀行帳戶</Table.HeaderCell>
          </Table.Row>
          {spread1 ? <Table.Row active >
            <Table.HeaderCell>編號</Table.HeaderCell>
            <Table.HeaderCell>銀行名稱</Table.HeaderCell>
            <Table.HeaderCell>銀行地址</Table.HeaderCell>
            <Table.HeaderCell>戶口持有人姓名</Table.HeaderCell>
            <Table.HeaderCell>戶口號碼</Table.HeaderCell>
            <Table.HeaderCell>SWIFT / BIC 編碼(如適用)</Table.HeaderCell>
            <Table.HeaderCell>ABA編碼 (美國付款)</Table.HeaderCell>
            <Table.HeaderCell>附言</Table.HeaderCell>
          </Table.Row> : null}
        </Table.Header>
        <Table.Body>
          {spread1 && arrPaymentRecord && arrPaymentRecord.length && arrPaymentRecord.map((entry, index) => (<Table.Row>
            <Table.Cell>{index + 1}</Table.Cell>
            <Table.Cell>{entry.wdba_bankname || ""}</Table.Cell>
            <Table.Cell>{entry.wdba_bankaddr || ""}</Table.Cell>
            <Table.Cell>{entry.wdba_holdername || ""}</Table.Cell>
            <Table.Cell>{entry.wdba_holdernum || ""}</Table.Cell>
            <Table.Cell>{entry.wdba_swift_bic || ""}</Table.Cell>
            <Table.Cell>{entry.wdba_abacode || ""}</Table.Cell>
            <Table.Cell>{entry.wdba_ps || ""}</Table.Cell>
          </Table.Row>))}
          {/* {spread1 && arrCustomerFollowItems && arrCustomerFollowItems.length && arrCustomerFollowItems.map((entry, index) => (<Table.Row>
            <Table.Cell>{index + 1}</Table.Cell>
            <Table.Cell>{entry.cfi_date || ""}</Table.Cell>
            <Table.Cell>{entry.cfi_serviceitem || ""}</Table.Cell>
            <Table.Cell>{entry.cfi_status || ""}</Table.Cell>
            <Table.Cell>{entry.cfi_description || ""}</Table.Cell>
            <Table.Cell>{entry.wdba_swift_bic || ""}</Table.Cell>
            <Table.Cell>{entry.cfi_action || ""}</Table.Cell>
            <Table.Cell>{entry.cfi_actionowner || ""}</Table.Cell>
          </Table.Row>))} */}
        </Table.Body>
      </Table >)

    } else if (type === 'ReturnPrincipal') { // 本金返還

      return (
        <Table >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>本金返還（離場)    日期/金額</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              {arrReturnPrincipal && arrReturnPrincipal.length && arrReturnPrincipal.map((entry) => (<Table.Cell key={entry.objectId}>{(entry.date + "  " + commaSeparateNumber(entry.money)) || ""}</Table.Cell>))}
            </Table.Row>
            <Table.Row >
              <Table.HeaderCell>匯款日/金額</Table.HeaderCell>
            </Table.Row>
            {paymentDateMoney && paymentDateMoney.length && paymentDateMoney.map((entry) => (<Table.Row key={entry.objectId}><Table.Cell>{(entry.date + "  " + commaSeparateNumber(entry.money)) || ""}</Table.Cell></Table.Row>))}
          </Table.Header>
        </Table>
      )
    } else if (type === 'PaymentRecord') {
      const handleStatus = (pmr_payment1money, pmr_payment2money) => {
        let status_text = "未繳";
        if (!pmr_payment2money) {
          status_text = "未繳";
        } else if (!!pmr_payment2money) {
          if (commaToNumber(pmr_payment2money) === "0") {
            status_text = "展延";
          } else if (commaToNumber(pmr_payment2money) < commaToNumber(pmr_payment1money)) {
            status_text = "部分繳";
          } else if (commaToNumber(pmr_payment2money) === commaToNumber(pmr_payment1money)) {
            status_text = "已繳";
          } else if (commaToNumber(pmr_payment2money) > commaToNumber(pmr_payment1money)) {
            status_text = "溢繳";
          }
        }
        return status_text;
      }
      let showArr = [];
      if (form.pay_year_num) {
        for (let i = 0; i < form.pay_year_num; i++) {
          let entry = arrPaymentRecord[i] || {}
          if (arrPaymentRecord[i]) {
            showArr.push(<Table.Row>
              <Table.Cell>第 {entry.order} 年</Table.Cell>
              <Table.Cell>{!isEdit ? entry.pmr_payment1date || "" : <Form.Input fluid placeholder='規劃繳費日期' value={entry.pmr_payment1date || ""} name="pmr_payment1date" onChange={this.handleChange} id="payment" entry={entry} arrPaymentRecord={arrPaymentRecord} />}</Table.Cell>
              <Table.Cell>{!isEdit ? commaSeparateNumber(entry.pmr_payment1money || "") : <Form.Input fluid placeholder='規劃繳費金額' value={entry.pmr_payment1money || ""} name="pmr_payment1money" onChange={this.handleChange} id="payment" entry={entry} arrPaymentRecord={arrPaymentRecord} />}</Table.Cell>
              <Table.Cell>{!isEdit ? entry.pmr_payment2date || "" : <Form.Input fluid placeholder='實際繳費期限' value={entry.pmr_payment2date || ""} name="pmr_payment2date" onChange={this.handleChange} id="payment" entry={entry} arrPaymentRecord={arrPaymentRecord} />}</Table.Cell>
              <Table.Cell>{!isEdit ? commaSeparateNumber(entry.pmr_payment2money || "") : <Form.Input fluid placeholder='實際繳費金額	' value={entry.pmr_payment2money || ""} name="pmr_payment2money" onChange={this.handleChange} id="payment" entry={entry} arrPaymentRecord={arrPaymentRecord} />}</Table.Cell>
              <Table.Cell>{!isEdit ? entry.pmr_paymethod || "" || "" : <Form.Input fluid placeholder='繳費方式' value={entry.pmr_paymethod || ""} name="pmr_paymethod" onChange={this.handleChange} id="payment" entry={entry} arrPaymentRecord={arrPaymentRecord} />}</Table.Cell>
              <Table.Cell fluid>{handleStatus(entry.pmr_payment1money, entry.pmr_payment2money) || ""}</Table.Cell>
              <Table.Cell>{!isEdit ? entry.pmr_ps || "" : <Form.Input fluid placeholder='備註' value={entry.pmr_ps || ""} name="pmr_ps" onChange={this.handleChange} id="payment" entry={entry} arrPaymentRecord={arrPaymentRecord} />} </Table.Cell>
            </Table.Row>)
          } else {
            showArr.push(<Table.Row>
              <Table.Cell>第 {i + 1} 年</Table.Cell>
              <Table.Cell>{!isEdit ? "" : <Form.Input fluid placeholder='規劃繳費日期' value={entry.pmr_payment1date || ""} name="pmr_payment1date" onChange={this.handleChange} id="payment" entry={entry} order={i} arrPaymentRecord={arrPaymentRecord} />}</Table.Cell>
              <Table.Cell>{!isEdit ? "" : <Form.Input fluid placeholder='規劃繳費金額' value={entry.pmr_payment1money || ""} name="pmr_payment1money" onChange={this.handleChange} id="payment" entry={entry} order={i} arrPaymentRecord={arrPaymentRecord} />}</Table.Cell>
              <Table.Cell>{!isEdit ? "" : <Form.Input fluid placeholder='實際繳費期限' value={entry.pmr_payment2date || ""} name="pmr_payment2date" onChange={this.handleChange} id="payment" entry={entry} order={i} arrPaymentRecord={arrPaymentRecord} />}</Table.Cell>
              <Table.Cell>{!isEdit ? "" : <Form.Input fluid placeholder='實際繳費金額	' value={entry.pmr_payment2money || ""} name="pmr_payment2money" onChange={this.handleChange} id="payment" entry={entry} order={i} arrPaymentRecord={arrPaymentRecord} />}</Table.Cell>
              <Table.Cell>{!isEdit ? "" : <Form.Input fluid placeholder='繳費方式' value={entry.pmr_paymethod || ""} name="pmr_paymethod" onChange={this.handleChange} id="payment" entry={entry} order={i} arrPaymentRecord={arrPaymentRecord} />}</Table.Cell>
              <Table.Cell fluid>{handleStatus(entry.pmr_payment1money, entry.pmr_payment2money) || ""}</Table.Cell>
              <Table.Cell>{!isEdit ? "" : <Form.Input fluid placeholder='備註' value={entry.pmr_ps || ""} name="pmr_ps" onChange={this.handleChange} id="payment" entry={entry} order={i} arrPaymentRecord={arrPaymentRecord} />} </Table.Cell>
            </Table.Row>)
          }
        }
      }
      return (
        <Table fixed>
          <Table.Header>
            <Table.Row >
              <Table.HeaderCell colSpan='8' style={{ cursor: 'pointer', backgroundColor: '#f07b51', fontSize: '17px' }} onClick={this.handleIsSpread}>繳費資料 (選擇「繳費年期」 變更此欄列數)</Table.HeaderCell>
            </Table.Row>
            {spread ?
              <Table.Row>
                <Table.HeaderCell>週期</Table.HeaderCell>
                <Table.HeaderCell>規劃繳費日期</Table.HeaderCell>
                <Table.HeaderCell>規劃繳費金額</Table.HeaderCell>
                <Table.HeaderCell>實際繳費期限</Table.HeaderCell>
                <Table.HeaderCell>實際繳費金額</Table.HeaderCell>
                <Table.HeaderCell>繳費方式</Table.HeaderCell>
                <Table.HeaderCell>繳費狀態</Table.HeaderCell>
                <Table.HeaderCell>備註</Table.HeaderCell>
              </Table.Row>
              : null}
          </Table.Header>
          {/* {(entry.pmr_paymethod || "")}{(entry.pmr_ps || "")} */}
          {/* {entry.pmr_payment1date || ""}{commaSeparateNumber(entry.pmr_payment1money || "")}{(entry.pmr_payment2date || "")}{commaSeparateNumber(entry.pmr_payment2money || "")} */}
          {spread ? showArr : null}
          {/* {spread && arrPaymentRecord && arrPaymentRecord.length && arrPaymentRecord.map((entry) => (<Table.Row>
            <Table.Cell>第 {entry.order_id} 年</Table.Cell>
            <Table.Cell>{!isEdit ? entry.pmr_payment1date || "" : <Form.Input fluid placeholder='規劃繳費日期' value={entry.pmr_payment1date || ""} name="pmr_payment1date" onChange={this.handleChange} id="payment" entry={entry} arrPaymentRecord={form.arrPaymentRecord} />}</Table.Cell>
            <Table.Cell>{!isEdit ? commaSeparateNumber(entry.pmr_payment1money || "") : <Form.Input fluid placeholder='規劃繳費金額' value={entry.pmr_payment1money || ""} name="pmr_payment1money" onChange={this.handleChange} id="payment" entry={entry} arrPaymentRecord={form.arrPaymentRecord} />}</Table.Cell>
            <Table.Cell>{!isEdit ? entry.pmr_payment2date || "" : <Form.Input fluid placeholder='實際繳費期限' value={entry.pmr_payment2date || ""} name="pmr_payment2date" onChange={this.handleChange} id="payment" entry={entry} arrPaymentRecord={form.arrPaymentRecord} />}</Table.Cell>
            <Table.Cell>{!isEdit ? commaSeparateNumber(entry.pmr_payment2money || "") : <Form.Input fluid placeholder='實際繳費金額	' value={entry.pmr_payment2money || ""} name="pmr_payment2money" onChange={this.handleChange} id="payment" entry={entry} arrPaymentRecord={form.arrPaymentRecord} />}</Table.Cell>
            <Table.Cell>{!isEdit ? entry.pmr_paymethod || "" || "" : <Form.Input fluid placeholder='繳費方式' value={entry.pmr_paymethod || ""} name="pmr_paymethod" onChange={this.handleChange} id="payment" entry={entry} arrPaymentRecord={form.arrPaymentRecord} />}</Table.Cell>
            <Table.Cell fluid>{handleStatus(entry.pmr_payment1money, entry.pmr_payment2money) || ""}</Table.Cell>
            <Table.Cell>{!isEdit ? entry.pmr_ps || "" : <Form.Input fluid placeholder='備註' value={entry.pmr_ps || ""} name="pmr_ps" onChange={this.handleChange} id="payment" entry={entry} arrPaymentRecord={form.arrPaymentRecord} />} </Table.Cell>
          </Table.Row>))
          } */}
        </Table >
      )
    } else if (type === 'Beneficiary Bank') {

      return (<Table >
        <Table.Header>
          <Table.Row >
            <Table.HeaderCell colSpan="2" className="warning">配息銀行帳戶</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell style={{ width: '30%' }}>Beneficiary Bank Location</Table.HeaderCell>
            <Table.Cell>{!isEdit ? form.beneficiary_bank_location : <Form.Input fluid placeholder='Beneficiary Bank Location' value={form.beneficiary_bank_location} name="beneficiary_bank_location" onChange={this.handleChange} id="input" />}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell style={{ width: '30%' }}>Beneficiary Bank Name</Table.HeaderCell>
            <Table.Cell>{!isEdit ? form.beneficiary_bank_name : <Form.Input fluid placeholder='Beneficiary Bank Name' value={form.beneficiary_bank_name} name="beneficiary_bank_name" onChange={this.handleChange} id="input" />}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell style={{ width: '30%' }}>Beneficiary Bank Address</Table.HeaderCell>
            <Table.Cell>{!isEdit ? form.beneficiary_bank_address : <Form.Input fluid placeholder='Beneficiary Bank Address' value={form.beneficiary_bank_address} name="beneficiary_bank_address" onChange={this.handleChange} id="input" />}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell style={{ width: '30%' }}>SWIFT code</Table.HeaderCell>
            <Table.Cell>{!isEdit ? form.swift_code : <Form.Input fluid placeholder='SWIFT code' value={form.swift_code} name="swift_code" onChange={this.handleChange} id="input" />}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell style={{ width: '30%' }}>Beneficiary Account Number</Table.HeaderCell>
            <Table.Cell>{!isEdit ? form.beneficiary_account_number : <Form.Input fluid placeholder='Beneficiary Account Number' value={form.beneficiary_account_number} name="beneficiary_account_number" onChange={this.handleChange} id="input" />}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell style={{ width: '30%' }}>Beneficiary Name</Table.HeaderCell>
            <Table.Cell>{!isEdit ? form.beneficiary_name : <Form.Input fluid placeholder='Beneficiary Name' value={form.beneficiary_name} name="beneficiary_name" onChange={this.handleChange} id="input" />}</Table.Cell>
          </Table.Row>
        </Table.Header>
      </Table >)
    }
  }

  handleAddPDM = () => {
    let { paymentDateMoney = [] } = this.state;
    const { datePDM = [], moneyPDM = [] } = this.state;
    const date = datePDM;
    const money = moneyPDM;
    paymentDateMoney.push({ date, money });
    paymentDateMoney = paymentDateMoney.map((item, order) => ({ ...item, order }));

    this.setState({ paymentDateMoney, datePDM: '', moneyPDM: '', PDMChange: true });
  }

  handleOnChangePDM = (e, { name, value, orderid }) => {
    // console.log(name, value, orderid)
    if (name === 'datePDM' || name === 'moneyPDM') { // 新增
      console.log(value)
      this.setState({ [name]: value });
    } else {  // 編輯
      const { paymentDateMoney } = this.state;
      const index = paymentDateMoney.findIndex((item => item.order === orderid))
      paymentDateMoney[index][name] = value;
      this.setState({ paymentDateMoney, PDMChange: true });
    }
  }

  handleDeletePDM = (order_id) => {
    const { paymentDateMoney, arrDelete } = this.state;

    const index = paymentDateMoney.findIndex((item => item.order === order_id))
    arrDelete.push({ obj: { ...paymentDateMoney.splice(index, 1)[0] }, dbName: 'paymentDateMoney' });
    this.setState({ paymentDateMoney, PDMChange: true, arrDelete });
  }

  handleAddRP = () => {
    let { arrReturnPrincipal = [] } = this.state;
    const { dateRP = [], moneyRP = [] } = this.state;
    const date = dateRP;
    const money = moneyRP;
    arrReturnPrincipal.push({ date, money });
    arrReturnPrincipal = arrReturnPrincipal.map((item, order) => ({ ...item, order }));

    this.setState({ arrReturnPrincipal, dateRP: '', moneyRP: '', RPChange: true });
  }

  handleOnChangeRP = (e, { name, value, orderid }) => {
    // console.log(name, value, orderid)
    if (name === 'dateRP' || name === 'moneyRP') { // 新增
      this.setState({ [name]: value });
    } else {  // 編輯
      const { arrReturnPrincipal } = this.state;
      const index = arrReturnPrincipal.findIndex((item => item.order === orderid))
      arrReturnPrincipal[index][name] = value;
      this.setState({ arrReturnPrincipal, RPChange: true });
    }
  }

  handleDeleteRP = (order_id) => {
    const { arrReturnPrincipal, arrDelete } = this.state;

    const index = arrReturnPrincipal.findIndex((item => item.order === order_id))
    arrDelete.push({ obj: { ...arrReturnPrincipal.splice(index, 1)[0] }, dbName: 'returnPrincipal' });
    this.setState({ arrReturnPrincipal, RPChange: true, arrDelete });
  }

  handlePaymentDateMoney = (id) => {
    const { isEdit } = this.props;
    const { datePDM, moneyPDM, paymentDateMoney, arrReturnPrincipal, dateRP, moneyRP } = this.state
    return (id === 1 ? <Form.Field>
      <label>計息日/金額</label>
      {paymentDateMoney ? paymentDateMoney.sort((a, b) => Number(a.order) - Number(b.order)).map(({ date, money, order }) => isEdit ?
        // <Grid key={'bb' + order}>
        <>
          <Row>
            <Col sm='1'><Button size='small' icon='minus' onClick={() => this.handleDeletePDM(order)} /></Col>
            <Col sm='6'><Input type='date' name='date' value={date} orderid={order} onChange={this.handleOnChangePDM} /></Col>
            <Col sm='4'><Input name='money' label='$' value={money} orderid={order} onChange={this.handleOnChangePDM} /></Col>
          </Row>
          {/* </Grid>  */}
        </> : <div key={'aa' + order}>{date} ${commaSeparateNumber(money)}</div>) : null}

      {isEdit ?
        // <Grid>
        <>
          <Row>
            <Col sm='1'><Button size='small' icon='plus' onClick={this.handleAddPDM} /></Col>
            {/* <Col sm='6'><Input type='date' name='datePDM' value={datePDM} onChange={this.handleOnChangePDM} /></Col>
          <Col sm='4'><Input name='moneyPDM' label='$' value={moneyPDM} onChange={this.handleOnChangePDM} /></Col> */}
            {/* </Grid.Row>
        <Grid.Row> */}
          </Row>
          {/* </Grid> */}
        </>
        : null}
    </Form.Field>
      :
      <Form.Field>
        <label>本金返還(離場) 日期/金額</label>
        {arrReturnPrincipal ? arrReturnPrincipal.sort((a, b) => Number(a.order) - Number(b.order)).map(({ date, money, order }) => isEdit ?
          // <Grid key={'bb' + order}>
          <>
            <Row>
              <Col sm='1'><Button size='small' icon='minus' onClick={() => this.handleDeleteRP(order)} /></Col>
              <Col sm='6'><Input type='date' name='date' value={date} orderid={order} onChange={this.handleOnChangeRP} /></Col>
              <Col sm='4'><Input name='money' label='$' value={money} orderid={order} onChange={this.handleOnChangeRP} /></Col>
            </Row>
            {/* </Grid>  */}
          </> : <div key={'bb' + order}>{date} ${commaSeparateNumber(money)}</div>) : null}

        {isEdit ?
          // <Grid>
          <>
            <Row>
              <Col sm='1'><Button size='small' icon='plus' onClick={this.handleAddRP} /></Col>
              {/* <Col sm='6'><Input type='date' name='dateRP' value={dateRP} onChange={this.handleOnChangeRP} /></Col>
          <Col sm='4'><Input name='moneyRP'  label='$' value={moneyRP} onChange={this.handleOnChangeRP} /></Col> */}
              {/* </Grid.Row>
        <Grid.Row> */}
            </Row>
            {/* </Grid> */}
          </>
          : null}
      </Form.Field>)
  }


  handleFilesChange = async (e) => {
    const fileArr = e.target.files;
    if (!fileArr || !fileArr.length) {
      return
    }
    const { form } = this.state
    const { companyObj } = this.props
    const promises = [];
    for (let i = 0; i < fileArr.length; i++) {
      const file = fileArr[i];
      promises.push(Parse.putFile(file));
    }
    const theFiles = await Promise.all(promises).catch(error => console.log("error", error));

    const promises2 = [];
    theFiles.forEach(async (theFile) => {
      const db = Parse.Object.extend("clientFile");
      const newDoc = new db();
      const docRef = await newDoc.save();

      const element = {
        objectId: docRef.id,
        id: docRef.id,
        _id: docRef.id,
        companyId: companyObj.objectId,
        companyName: companyObj.name,
        clientId: form.client_id,
        portfolioId: form.objectId,
        theFile
      };
      promises2.push(Parse.saveData('clientFile', element));
    })
    await Promise.all(promises2).catch(error => console.log("error", error));
    window.alert('上傳成功')
  }

  render() {
    const { auth, doc, isEdit, } = this.props;
    const { form, formModal = {}, portfolios, loading, portfoliosSel, loadingPorfolio, afterArr, clientPor, spread2, ic, provider, transShow } = this.state;
    const { filedoc, fileArr, fileArrCheck, show } = this.state
    const options = [];
    const { productType1Opt, productType2Opt, productType3Opt, productType4Opt, providerOpt,
      disabled2, disabled3, disabled4, value1, value2, value3, value4, buttonLoading, loadingTrans, saveLoading } = this.state;
    if (clientPor && clientPor.length <= 0) {
      options.push({ key: 'selRelNo', text: '(無)', value: '', disabled: true })
    } else if (clientPor) {
      clientPor.forEach(item => {
        if (!item.account_num) {
          options.push({ key: item.objectId, text: `${item.product3_text}`, value: item.objectId })
        } else {
          options.push({ key: item.objectId, text: `${item.product3_text + " " + item.account_num + " " + funcObjFind2(objInsuranceStatus, item.insurance_status) + " " + funcObjFind2(objNowPhase, item.nowphase)}`, value: item.objectId })
        }
      });
    }
    let showAfter = afterArr.map(item => {
      if (item) {
        return (
          <Table.Row>
            <Table.Cell>@</Table.Cell>
            <Table.Cell>{item.uid}</Table.Cell>
            <Table.Cell>{item.dueDate}</Table.Cell>
            <Table.Cell>{item.serviceText}</Table.Cell>
            <Table.Cell>{item.status_text}</Table.Cell>
            <Table.Cell>{item.ps}</Table.Cell>
            <Table.Cell>{item.as_action}</Table.Cell>
            <Table.Cell>{item.as_action_owner}</Table.Cell>
          </Table.Row>
        )
      } else if (afterArr.length === 0) {
        return (
          <Table.Row>
            <Table.Cell colSpan='8'>暫無資料</Table.Cell>
          </Table.Row>
        )
      }
      return ''
    })
    // <Table.HeaderCell>#</Table.HeaderCell>
    //         <Table.HeaderCell>Portfolio</Table.HeaderCell>
    //         <Table.HeaderCell>Date</Table.HeaderCell>
    //         <Table.HeaderCell>Service Item</Table.HeaderCell>
    //         <Table.HeaderCell>Status</Table.HeaderCell>
    //         <Table.HeaderCell>Description</Table.HeaderCell>
    //         <Table.HeaderCell>Action</Table.HeaderCell>
    //         <Table.HeaderCell>Action Owner</Table.HeaderCell>
    //       </Table.Row>

    // console.log(form)
    return (<>
      <Header as='h2' dividing
        content='投資內容'
        style={{ backgroundColor: 'lavender' }}
      // subheader='調査時間：2017年4月から 2018年12月 まで'
      />
      <Button disabled={!isEdit} floated="left" inverted color='blue' onClick={this.handleOpen} icon='add' content='新增' />
      <Input labelPosition='left'>
        <Select style={{
          minWidth: '300px',
          borderTopLeftRadius: '0',
          borderBottomLeftRadius: '0'
        }} compact options={options} onChange={this.handleChange} value={portfoliosSel} name='portfoliosSel' />
      </Input>
      {loadingPorfolio ? null :
        <div style={{ float: 'right', marginLeft: "10px" }}>
          {isEdit ? <Button positive size='small' disabled={portfoliosSel === '' ? true : false} onClick={this.saveUpdateSetting} icon='save' content={intl.get('儲存')} loading={saveLoading} /> : <Button primary size='small' onClick={this.props.handleIsEdit} icon='edit' content={intl.get('編輯')} disabled={!auth.edit} />}
          {isEdit ? <Button color="red" size='small' disabled={portfoliosSel === '' ? true : false} onClick={() => this.setState({ confirm: true })} icon='delete' content={intl.get('刪除')} /> : null}
          {isEdit ? <Button color="yellow" size='small' onClick={this.handleTransShow} icon='exchange' content={intl.get('轉移')} disabled={portfoliosSel === '' ? true : false} /> : null}
          {isEdit ? <Button basic size='small' onClick={this.cancelUpdateSetting} icon='remove' content={intl.get('取消')} /> : null}
        </div>}

      {loadingPorfolio || isEdit ? null :
        <div style={{ float: 'right' }}>
          <ModalPdf
            {...this.props}
            // codekey='praypage1'
            params={{ form: { ...form, ...doc } }}
            // arrObj={dataArr}
            nowform={'1'}
          // type={type}
          // printDate={printDate}
          // lightType={lightType}
          />
        </div>}
      {/* <div style={{ clear: 'both' }}></div> */}
      {loadingPorfolio ? <DimmerImg /> :
        <Table celled structured size="small">
          <Table.Header>
            <Table.Row>
              {/* {isEdit ? <Table.HeaderCell rowSpan="2" style={{ width: '100px' }}>#</Table.HeaderCell> : null} */}
              <Table.HeaderCell>客戶編號</Table.HeaderCell>
              <Table.HeaderCell>姓名</Table.HeaderCell>
              <Table.HeaderCell>連絡電話</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>財務顧問</Table.HeaderCell>
              <Table.HeaderCell>投資項目</Table.HeaderCell>
              <Table.HeaderCell>Policy No.</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {clientPor && clientPor.length && form && form.objectId ?
              <Table.Row>
                {/* {isEdit ? <Table.Cell style={{ padding: 10 }}>
                  <Button inverted color='green' size='small' onClick={() => this.handleEdit(form)} icon='edit' />
                  <Button inverted color='red' size='small' onClick={() => this.showConfirm(form)} icon='delete' />
                </Table.Cell> : null} */}
                {/* {isEdit ? <Table.Cell >#</Table.Cell> : null} */}
                <Table.Cell >{doc.uid || ''}</Table.Cell>
                <Table.Cell>{doc.name_cht || ''}</Table.Cell>
                <Table.Cell>{doc.cellnum || ''}</Table.Cell>
                <Table.Cell>{doc.email || doc.email2 || ''}</Table.Cell>
                <Table.Cell>{form.agent_text}</Table.Cell>
                <Table.Cell>{form.product4_text}</Table.Cell>
                <Table.Cell>{form.account_num}</Table.Cell>
              </Table.Row> :
              <Table.Row><Table.Cell colSpan={isEdit ? 10 : 9} textAlign='center' >(無資料)</Table.Cell></Table.Row>}
          </Table.Body>
        </Table>}

      {clientPor && clientPor.length && form && form.objectId && (!loadingPorfolio) ? <>
        {form.template_id === "1" ? <Portfolio1 {...this.props} ic={ic} provider={provider} doc={form} showTable={this.showTable} handleUpdateSetting={this.handleUpdateSetting} handleSelectUpdate={this.handleSelectUpdate} handleChangeField={this.handleChangeField} /> : null}
        {form.template_id === "2" ? <Portfolio2 {...this.props} ic={ic} provider={provider} doc={form} showTable={this.showTable} handleUpdateSetting={this.handleUpdateSetting} handleSelectUpdate={this.handleSelectUpdate} handleChangeField={this.handleChangeField} /> : null}
        {form.template_id === "3" ? <Portfolio3 {...this.props} ic={ic} provider={provider} doc={form} showTable={this.showTable} handlePaymentDateMoney={this.handlePaymentDateMoney} handleUpdateSetting={this.handleUpdateSetting} handleSelectUpdate={this.handleSelectUpdate} handleChangeField={this.handleChangeField} /> : null}
        {form.template_id === "4" ? <Portfolio4 {...this.props} ic={ic} provider={provider} doc={form} showTable={this.showTable} handlePaymentDateMoney={this.handlePaymentDateMoney} handleUpdateSetting={this.handleUpdateSetting} handleSelectUpdate={this.handleSelectUpdate} handleChangeField={this.handleChangeField} /> : null}
        {form.template_id === "5" ? <Portfolio5 {...this.props} ic={ic} provider={provider} doc={form} showTable={this.showTable} handlePaymentDateMoney={this.handlePaymentDateMoney} handleUpdateSetting={this.handleUpdateSetting} handleSelectUpdate={this.handleSelectUpdate} handleChangeField={this.handleChangeField} /> : null}
        {form.template_id === "6" ? <Portfolio6 {...this.props} ic={ic} provider={provider} doc={form} showTable={this.showTable} handleUpdateSetting={this.handleUpdateSetting} handleSelectUpdate={this.handleSelectUpdate} handleChangeField={this.handleChangeField} /> : null}
        {form.template_id === "7" ? <Portfolio7 {...this.props} ic={ic} provider={provider} doc={form} showTable={this.showTable} handleUpdateSetting={this.handleUpdateSetting} handleSelectUpdate={this.handleSelectUpdate} handleChangeField={this.handleChangeField} /> : null}
        {form.template_id === "8" ? <Portfolio8 {...this.props} ic={ic} provider={provider} doc={form} showTable={this.showTable} handleUpdateSetting={this.handleUpdateSetting} handleSelectUpdate={this.handleSelectUpdate} handleChangeField={this.handleChangeField} /> : null}
      </> : null}

      <Card outline color='secondary' fluid style={{}}>
        <a style={{ cursor: 'pointer' }} onClick={this.handleIsSpread2}>
          <CardHeader tag='h4' style={{ backgroundColor: '#f07b51' }}>
            {/* <CardTitle  inverse>本人基本情報</CardTitle> */}
                  附加檔案
                </CardHeader>
        </a>
        {spread2 ? <CardBody >
          {form && form.objectId ? <MaterialTable
            isLoading={loading}
            components={{
              Toolbar: props => (<div>
                <div style={{ width: '210px', float: 'right', textAlign: 'right', padding: '15px' }}>
                  {auth.edit ? <ModalUploadFiles {...this.props} isImage='0' handleData={this.handleFetch} dbName='clientFile' fieldName='theFile' condition={{ clientId: doc.objectId, portfolioId: form.objectId }} /> : null}
                </div>
                <MTableToolbar {...props} />
                <div style={{ clear: 'both' }}></div>
              </div>),
            }}
            localization={localization()}
            columns={columns()} options={{
              addRowPosition: "first",
              exportButton: true,
              exportAllData: true,
              exportPdf: (columns, data) => exportPdf(columns, data, '附加檔案'),
              pageSize: 5,
              search: false
            }}
            // data2={bankaccSel}
            data={filedoc}
            title={`附加檔案`}
            editable={{
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  let data = filedoc;
                  const index = data.indexOf(oldData);
                  data.splice(index, 1);
                  // handleState('people0', data);
                  this.setState({ filedoc: data, oldData }, () => resolve());
                }).then(() => this.handleDelete()),
            }}
          /> : null}
        </CardBody> : null}
      </Card>
      {/* {form && form.objectId ? */}

      {/* : null} */}
      <Modal
        isOpen={show}
        toggle={this.handleClose}
        size="lg"
        className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader style={style.flexCenter}>
          <h2 style={{ margin: 0 }}>新增投資項目</h2>
          <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
        </ModalHeader>
        <ModalBody>
          <Form>
            <Form.Select label='產品類別1' name='productType1' options={productType1Opt} onChange={this.handleChange} value={value1} />
            <Form.Select label='產品類別2' name='productType2' options={productType2Opt} onChange={this.handleChange} value={value2} disabled={disabled2} />
            <Form.Select label='產品類別3' name='productType3' options={productType3Opt} onChange={this.handleChange} value={value3} disabled={disabled3} />
            <Form.Select label='產品類別4' name='productType4' options={productType4Opt} onChange={this.handleChange} value={value4} disabled={disabled4} />
          </Form>
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button onClick={value1 === "-1" || value2 === "-1" || value3 === "-1" || value4 === "-1" ? this.handleAlert : this.handleSubmit} inverted color='green' loading={loading} icon='checkmark' content={'新增'} />
          <Button onClick={this.handleClose} inverted color='red' icon='delete' content='取消' />
        </ModalFooter>
      </Modal>

      <Modal isOpen={transShow} toggle={this.handleClose} centered={false}>
        <ModalHeader>保單轉移</ModalHeader>
        <ModalBody><Form>
          <Form.Group widths='equal'>
            <Form.Field control={Input} label='客戶編號' placeholder='客戶編號' onChange={this.handleChangeModal} name='uidClient' value={formModal.uidClient || ''} />
            <Form.Field control={Input} label='中文姓名' placeholder='中文姓名' onChange={this.handleChangeModal} name='name_chtClient' value={formModal.name_chtClient || ''} />
          </Form.Group>
        </Form></ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button color='yellow' icon='checkmark' labelPosition='right' onClick={this.portfolioTransform} content='轉移' loading={loadingTrans} />
          <Button onClick={this.handleClose} >關閉</Button>
        </ModalFooter>
      </Modal>

      {/* <Modal open={this.state.show} onClose={this.handleClose} dialogclassname="modal-90w">
        <Modal.Header style={style.flexCenter}>
          <h2 style={{ margin: 0 }}>新增投資項目</h2>
          <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Select label='產品類別1' name='productType1' defaultValue=' ' options={productType1Opt} onChange={this.handleChange} value={value1} />
            <Form.Select label='產品類別2' name='productType2' defaultValue=' ' options={productType2Opt} onChange={this.handleChange} value={value2} disabled={disabled2} />
            <Form.Select label='產品類別3' name='productType3' defaultValue=' ' options={productType3Opt} onChange={this.handleChange} value={value3} disabled={disabled3} />
            <Form.Select label='產品類別4' name='productType4' defaultValue=' ' options={productType4Opt} onChange={this.handleChange} value={value4} disabled={disabled4} />
          </Form>
        </Modal.Content>
        <Modal.Content style={{ borderTop: '1px solid rgba(34,36,38,.15)', display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={value1 === "-1" || value2 === "-1" || value3 === "-1" || value4 === "-1" ? this.handleAlert : this.handleSubmit} inverted color='green' loading={loading} icon='checkmark' content={'新增'} />
          <Button onClick={this.handleClose} inverted color='red' icon='delete' content='取消' />
        </Modal.Content>
      </Modal> */}
      {/* <Confirm
        header='確定刪除?'
        content='確定刪除?'
        cancelButton='關閉'
        confirmButton='刪除'
        open={this.state.confirm}
        onCancel={() => this.setState({ confirm: false })}
        onConfirm={this.handleRemove}
        size='large'
        centered={true} /> */}
      <Modal
        isOpen={this.state.confirm}
        toggle={() => this.setState({ confirm: false })}
        size="lg"
        className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader>確認刪除？</ModalHeader>
        <ModalBody>
          確定要刪除這筆資料嗎？
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button onClick={() => this.setState({ buttonLoading: true }, () => this.handleRemove())} inverted color='red' icon='delete' loading={buttonLoading} content='刪除' />
          <Button onClick={() => this.setState({ confirm: false })} content='取消' />
        </ModalFooter>
      </Modal>
    </>);
  }
}
