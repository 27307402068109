import React from 'react'
import HorizontalComponent from './FormSettings/HorizontalComponent';

const FormSettings = (props) => (<div className='content'>
  <h2 style={{ margin: '0 20px' }}>FormSettings 欄位設定</h2>
  <div style={{ margin: '0 20px', overflowX: 'auto' }}>
    <HorizontalComponent {...props} />
  </div>
</div>)

export default FormSettings