import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function SectionHeader3(props = {}) {
  const { form, cookies, webimageArr, imagePreviewUrl } = props;
  // console.log(form)
  const { text2 = '', text3 = '', img1File } = form;
  const dbLang = cookies.get('dbLang') || 'name';
  let arr = []
  arr = webimageArr.filter(item => item.navId === form.objectId)
  if (!arr.length) {
    arr = [{ icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' }]
  }
  const arr2 = [];

  for (let i = 0; i < arr.length; i += 2) {
    const obj = {
      '1': arr[i],
      '2': i + 1 < arr.length ? arr[i + 1] : {},
    }
    arr2.push(obj);
  }
  const picUrl = (img1File && img1File.url) || imagePreviewUrl;
  return (
    <>
      <div className="section section-feature cd-section" style={{ margin: '200px 0px' }} id="features">
        {/* ********* FEATURES 3 ********* */}
        <div className="features-3">
          <Container>
            <Row>
              <Col md="6">
                <Row>
                  <Container>
                    <h2 className="title">{text2}</h2>
                  </Container>
                </Row>
                <Row style={{ marginRight: '-150px' }}>
                  {arr2.map((item, index) => <>
                    <Col lg="6" md="12" sm="12">
                      <div className="info info-horizontal">
                        <div className="icon icon-success" style={{ color: `${index % 3 === 0 ? '#6bd197' : '#f55a3d'}` }}>
                          <i className={`nc-icon ${item['1'].icon}`} />
                        </div>
                        <div className="description">
                          <h4 className="info-title" style={{ color: `${index % 3 === 0 ? '#6bd197' : '#f55a3d'}`, fontSize: '18px', width: '400px' }}>{item['1']['title1' + dbLang] || ''}</h4>
                          <p>
                            {item['1']['content1' + dbLang] || ''}
                          </p>
                          <Button
                            color="link"
                            style={{ color: '#866155', fontWeight: 400 }}
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            See more
                        </Button>
                        </div>
                      </div>
                      <div className="info info-horizontal">
                        <div className="icon icon-warning" style={{ color: `${index % 3 === 0 ? '#f7c23c' : '#50bdda'}` }}>
                          <i className={`nc-icon ${item['2'].icon}`} />
                        </div>
                        <div className="description">
                          <h4 className="info-title" style={{ color: `${index % 3 === 0 ? '#f7c23c' : '#50bdda'}`, fontSize: '18px', width: '400px' }}>{item['2']['title1' + dbLang] || ''}</h4>
                          <p>
                            {item['2']['content1' + dbLang] || ''}
                          </p>
                          <Button
                            color="link"
                            style={{ color: '#866155', fontWeight: 400 }}
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            See more
                        </Button>
                        </div>
                      </div>
                    </Col>
                  </>)}
                </Row>
              </Col>
              <Col className="ml-auto" lg="6" md="12" sm="12">
                <div className="iphone-container" style={{ maxWidth: '300px' }}>
                  <img
                    alt="..."
                    src={picUrl || require("assets/img/sections/iphone.png")}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END FEATURES 3 ********* */}
      </div>
    </>
  );
}

export default SectionHeader3;
