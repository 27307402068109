import React, { Component } from 'react';
import { Form, Tabs, Tab, Card, Container, Grid, Select, Divider, Header, Button, Confirm } from 'semantic-ui-react';

import { showField, showFieldText, getAvailblePeriodArr, getDate, getOptionsByKey } from 'views/widgets/FieldsRender';
import InquiryStudent from './InquiryStudent';
import { Row, Col } from 'reactstrap';
import { async } from 'parse/lib/browser/Storage';
import Parse from 'widget/parse'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


export default class EnquiryStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  removeRow = async () => {
    const { firebase, match, history, refetch } = this.props
    const { _id, list } = match.params;
    // firebase.firestore().collection('Students').doc(_id).delete().then(() => {
    //   this.close();
    //   refetch('Students');
    //   this.setState({ doc: {}, form: {}, }, () => {
    //     history.push(`/StudentPage/${list}`);
    //   })
    // })
    await Parse.destroyData('Inquiry', { studentId: _id })
    await Parse.destroyData('Students', { objectId: _id })
    refetch('Students', 'students')
    this.close()
    history.push(`/admin/StudentPage/${list}`);
  }
  render() {
    const { doc, optSet, isEdit } = this.props;
    // console.log(this.props)

    // const classArr = getOptionsByKey(optSet, "classSchool").map(item => ({ key: item._id, text: item.label, value: item.value }));
    // classArr.unshift({ key: 'empty', text: '-- 選択 --', value: '' });
    return (<>
      <Header as='h2' dividing
        content='問合せ記録帳'
      // subheader='調査時間：2017年4月から 2018年12月 まで'
      />
      {/* <Grid columns='two' divided> */}
      <Row>
        <Col>
          <Card fluid>
            <Card.Content header='問合せ者情報' className='card-title' />
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "recruitNum")}</Col>
                <Col>{showField(this.props, "enquiryDate")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "agentName")}</Col>
                <Col>{showField(this.props, "agentNationality")}</Col>
                {/* <Col>{showField(this.props, "enquiryName")}</Col>
                    <Col>{showField(this.props, "enquiryNationality")}</Col> */}
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col >{showField(this.props, "enquiryWay")}</Col>
                <Col >{showField(this.props, "agentRelation")}</Col>
                <Col >{showField(this.props, "agentRelationshipText")}</Col>
                <Col >{showField(this.props, "relationshipOfSchool")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "agentWechat")}</Col>
                <Col>{showField(this.props, "agentQq")}</Col>
                <Col>{showField(this.props, "agentFb")}</Col>
                {/* <Col>{showField(this.props, "enquirySnsWay")}</Col>
                    <Col>{showField(this.props, "enquirySnsId")}</Col> */}
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "agentTel")}</Col>
                <Col>{showField(this.props, "agentEmail")}</Col>
                <Col>{showField(this.props, "agentAddr")}</Col>
                {/* <Col>{showField(this.props, "enquiryTel")}</Col>
                    <Col>{showField(this.props, "enquiryEmail")}</Col>
                    <Col>{showField(this.props, "enquiryAddress")}</Col> */}
                {/* </Grid> */}
              </Row>
              {/* <Row> */}
              <h3>勤務先</h3>
              {/* </Row> */}
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "agentCoName")}</Col>
                <Col>{showField(this.props, "agentCoTel")}</Col>
                <Col>{showField(this.props, "agentCoEmail")}</Col>
                <Col>{showField(this.props, "agentCoAddr")}</Col>
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
        </Col>
        <Col>
          {/* <Card fluid>
              <Card.Content>
                <Row>
                  <Grid columns='equal'>
                    <Col>{showField(this.props, "semesterYear")}</Col>
                    <Col>{showField(this.props, "semester")}</Col>
                    <Col>{showField(this.props, "applyResult")}</Col>
                  </Grid>
                </Row>
                <Row>
                  <Grid columns='equal'>
                    <Col >{showField(this.props, "enquiryPs")}</Col>
                  </Grid>
                </Row>
              </Card.Content>
            </Card> */}
          <Card fluid>
            <Card.Content header='学生情報' className='card-title' />
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "semesterYear")}</Col>
                <Col>{showField(this.props, "semester")}</Col>
                <Col>{showField(this.props, "applyResult")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "homeName")}</Col>
                <Col>{showField(this.props, "gender")}</Col>
                <Col>{showField(this.props, "age")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "nationality")}</Col>
                <Col >{showField(this.props, "bornPlace")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "schoDis")}</Col>
                <Col>{showField(this.props, "sceduleAfterscho")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col >{showField(this.props, "futureOutWay")}</Col>
                {/* <Col >{showField(this.props, "enquiryPs")}</Col> */}
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "homeCellphone")}</Col>
                <Col >{showField(this.props, "email")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "weChat")}</Col>
                <Col>{showField(this.props, "qq")}</Col>
                <Col>{showField(this.props, "line")}</Col>
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
        </Col>
      </Row>
      {/* </Grid> */}
      <Divider />
      {/* <Grid> */}
      <Row>
        <Col>
          <InquiryStudent {...this.props} />
        </Col>
      </Row>
      {/* </Grid> */}
      <Divider />
      {/* <Grid> */}
      <Row>
        <Col>
          {/* <h3>削除</h3>
            <br /> */}
          <Button negative size='small' variant='outline-danger' onClick={this.open} icon='delete' content='削除' />
          {/* <Confirm size='tiny' content='削除してもいいですか？' confirmButton='はい' cancelButton='いいえ' open={this.state.open} onCancel={this.close} onConfirm={this.removeRow} /> */}
          <Modal
            isOpen={this.state.open}
            toggle={this.close}
            size="lg"
            className="mh-100 h-90 mw-100 w-90"
          >
            <ModalHeader>削除</ModalHeader>
            <ModalBody>
              削除してもいいですか？
        </ModalBody>
            <ModalFooter style={{ padding: '16px' }}>
              <Button onClick={this.removeRow} inverted color='red' icon='delete' content='はい' />
              <Button onClick={this.close} content='いいえ' />
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
      {/* </Grid> */}


    </>);
  }
}