import React, { Component } from 'react'
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
import { Container, Header, Form, Icon, Image, Grid, Segment, Item, Table } from 'semantic-ui-react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import { Link } from 'react-router-dom'
// import Lightbox from 'react-image-lightbox';
import Carousel, { ModalGateway, Modal } from 'react-images';
import intl from 'react-intl-universal';

import * as createHistory from "history";
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import PageHeader from 'components/Headers/PageHeader';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { previewUrl } from 'views/web/PreloadFile';

export class FileDownload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileCenter1Arr: [],
      fileCenter2Arr: [],
    };
  }

  componentDidMount = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data1 = await Parse.queryData('fileCenter2', { companyId });

    this.setState({ fileCenter2Arr: data1 })
  }


  render() {
    //     const { auth } = this.props;
    //     if (!auth.uid) return <Redirect to='/signin' />

    const { pageObj, form, imagePreviewUrl, isLoading } = this.props
    const { text1, img1File, img1Url, dbLang, flieType } = form
    const { fileCenter2Arr } = this.state
    const fileCenter2Data = fileCenter2Arr.filter(item => item.fileCenter1Text === flieType)
    console.log(fileCenter2Data)
    return (<>
      {/* <PageHeader url={picUrl} text={intl.get('檔案下載')} /> */}
      <div className="blog-2 section section-gray">
        <br />
        <br />
        <Container>
          <Table celled textAlign='center' style={{ margin: '0 0 2rem' }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell >{intl.get('書類名')}</Table.HeaderCell>
                <Table.HeaderCell >{intl.get('ダウンロード')}</Table.HeaderCell>
                {/* {th.map((item, index) => <Table.HeaderCell key={`dataTh${index}`}>{item.text}</Table.HeaderCell>)} */}
              </Table.Row>
            </Table.Header>
            {fileCenter2Data.map((item, index) =>
              < Table.Body >
                <Table.Row key={`dataTr`}>
                  <Table.Cell className='fileDownload'>{intl.get(item.name)}</Table.Cell>
                  <Table.Cell className='fileDownload'>{previewUrl(item.file1, false)}</Table.Cell>
                  {/* <Table.Cell>{item.name}</Table.Cell> */}
                </Table.Row>
              </Table.Body>
            )}
          </Table>
        </Container>
        <br />
        <br />
      </div>



      {/* <Form>
          <Form.Group widths='equal'>
            <Form.Input readOnly type='date' label={t('時間')} placeholder='日付' onChange={this.handleChange} id='date' value={date} />
            <Form.Input readOnly label={t('活動名')} placeholder='活動名' onChange={this.handleChange} id='title' value={title} />
          </Form.Group>
          <Form.Field> */}
      {/* <Checkbox label='I agree to the Terms and Conditions' /> */}
      {/* </Form.Field> */}
      {/* <Button type='submit'>Submit</Button> */}
      {/* </Form> */}
      {/* <div align="right">
          <Button onClick={this.handleOpen}>修改</Button>
          <Button color='green' onClick={() => history.goBack()}>返回相簿</Button>
        </div> */}
    </>)
  }
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     createProject: (project) => dispatch(createProject(project))
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HostIndex)
export default FileDownload