import React, { Component } from 'react'
import { Select, Input, Table } from 'semantic-ui-react';
import MaterialTable from 'material-table'

import ExportTableToExcelButton from 'widget/ExportTableToExcelButton'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf, typeCurrency } from 'views/web/CustomMaterialTable';


const columns = () => ([
  { title: '項目', field: 'accountText' },
  { title: '說明', field: 'title' },
  { title: '12月', field: 'price12', ...typeCurrency },
  { title: '1月', field: 'price1', ...typeCurrency },
  { title: '2月', field: 'price2', ...typeCurrency },
  { title: '3月', field: 'price3', ...typeCurrency },
  { title: '4月', field: 'price4', ...typeCurrency },
  { title: '5月', field: 'price5', ...typeCurrency },
  { title: '6月', field: 'price6', ...typeCurrency },
  { title: '7月', field: 'price7', ...typeCurrency },
  { title: '8月', field: 'price8', ...typeCurrency },
  { title: '9月', field: 'price9', ...typeCurrency },
  { title: '10月', field: 'price10', ...typeCurrency },
  { title: '11月', field: 'price11', ...typeCurrency },
  { title: '總計', field: 'priceTotal', ...typeCurrency },
  { title: '％', field: 'totalr' },
]);

const funcAccText = (item, ac2Data2Arr) => {
  if (ac2Data2Arr.find(item2 => item2.detail === item && item2.detail !== "")) {
    return ac2Data2Arr.find(item2 => item2.detail === item && item2.detail !== "").detail
  } else if (ac2Data2Arr.find(item2 => item2.acc2Id === item && item2.acc2Id !== "")) {
    return ac2Data2Arr.find(item2 => item2.acc2Id === item && item2.acc2Id !== "").acc2Text
  } else if (ac2Data2Arr.find(item2 => item2.acc1Id === item && item2.acc1Id !== "")) {
    return ac2Data2Arr.find(item2 => item2.acc1Id === item && item2.acc1Id !== "").acc1Text
  }
}

const funcPrice = (item, ac2DataArr = [], month) => {
  let totalprice = 0;
  if (month) {
    const data = ac2DataArr.filter(item2 => (item2.detail === item || item2.acc2Id === item || item2.acc1Id === item) && item2.month === month)
    // console.log(data)
    data.forEach(item2 => {
      if (!(item2.price === undefined)) {
        totalprice += Number(item2.price)
      }
    })
  } else {
    const data = ac2DataArr.filter(item2 => (item2.detail === item || item2.acc2Id === item || item2.acc1Id === item))
    data.forEach(item2 => {
      if (!(item2.price === undefined)) {
        totalprice += Number(item2.price)
      }
    })
  }
  return totalprice
}

const acc2price = (arr, ac2DataArr) =>
  arr.map(item => ({
    accountText: funcAccText(item, ac2DataArr),
    price1: funcPrice(item, ac2DataArr, '01'),
    price2: funcPrice(item, ac2DataArr, '02'),
    price3: funcPrice(item, ac2DataArr, '03'),
    price4: funcPrice(item, ac2DataArr, '04'),
    price5: funcPrice(item, ac2DataArr, '05'),
    price6: funcPrice(item, ac2DataArr, '06'),
    price7: funcPrice(item, ac2DataArr, '07'),
    price8: funcPrice(item, ac2DataArr, '08'),
    price9: funcPrice(item, ac2DataArr, '09'),
    price10: funcPrice(item, ac2DataArr, '10'),
    price11: funcPrice(item, ac2DataArr, '11'),
    price12: funcPrice(item, ac2DataArr, '12'),
    priceTotal: funcPrice(item, ac2DataArr),
    totalr: funcPrice(item, ac2DataArr)
  }))

export default class Fin1117 extends Component {
  state = {
    dataArr: [],
    dataArr_2: [],
    data2Arr: [],
    data2Arr_2: [],
    data3Arr: [],
    ac2DataArr: [],
    ac2Data2Arr: [],
    deleteConfirm: false,
    loading: true,
    mgyearSel: '',
    mgyearArr: [],
  }
  async componentDidMount() {
    const { companyObj } = this.props
    const companyId = companyObj.objectId;
    let now = String(new Date().getFullYear())
    const mgyear = await Parse.queryData('mgyear', { companyId });
    const mgyearArr = mgyear.filter(item => item.value_AD).map(item => ({
      key: item._id, text: `${item.value_AD}`, value: item.value_AD
    }));
    const mgyearSel = (mgyear.find(item => item.value_AD === now) && mgyear.find(item => item.value_AD === now).value_AD) || ''
    this.setState({ mgyearArr, mgyearSel });
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props, type = 1) => {
    const { companyObj } = props
    const companyId = companyObj.objectId;
    const { mgyearSel } = this.state
    if (type === 1) {
      const cashbook = await Parse.queryData('cashbook', { companyId, year: mgyearSel, acc1Id: 'vtvfMREPEJ' });

      // 非流動資產
      const ac2DataArr = cashbook.filter(item => item.flow === '0')
      this.setState({ ac2DataArr });
      const accountIdArr = [];
      // const acc3IdArr = [];
      ac2DataArr.forEach(({ detail, acc2Id, acc1Id }) => {
        if (detail !== undefined && detail !== "" && accountIdArr.indexOf(detail) === -1) {
          accountIdArr.push(detail)
        } else if (acc2Id !== undefined && acc2Id !== "" && accountIdArr.indexOf(acc2Id) === -1) {
          accountIdArr.push(acc2Id);
        }
        //else if (acc1Id !== undefined && acc1Id !== "" && accountIdArr.indexOf(acc1Id) === -1) {
        //          accountIdArr.push(acc1Id);
        //        }
      });
      const dataArr11 = acc2price(accountIdArr, ac2DataArr);
      const funcTotal = (dataArr) => {
        let total1 = 0;
        let total2 = 0;
        let total3 = 0;
        let total4 = 0;
        let total5 = 0;
        let total6 = 0;
        let total7 = 0;
        let total8 = 0;
        let total9 = 0;
        let total10 = 0;
        let total11 = 0;
        let total12 = 0;
        let totalprice = 0;
        dataArr.forEach(item2 => total1 += Number(item2.price1))
        dataArr.forEach(item2 => total2 += Number(item2.price2))
        dataArr.forEach(item2 => total3 += Number(item2.price3))
        dataArr.forEach(item2 => total4 += Number(item2.price4))
        dataArr.forEach(item2 => total5 += Number(item2.price5))
        dataArr.forEach(item2 => total6 += Number(item2.price6))
        dataArr.forEach(item2 => total7 += Number(item2.price7))
        dataArr.forEach(item2 => total8 += Number(item2.price8))
        dataArr.forEach(item2 => total9 += Number(item2.price9))
        dataArr.forEach(item2 => total10 += Number(item2.price10))
        dataArr.forEach(item2 => total11 += Number(item2.price11))
        dataArr.forEach(item2 => total12 += Number(item2.price12))
        dataArr.forEach(item2 => totalprice += Number(item2.priceTotal))
        return {
          title: "total",
          accountText: "總計",
          price1: total1,// totalprice1: total1,
          price2: total2,// totalprice2: total2,
          price3: total3,// totalprice3: total3,
          price4: total4,// totalprice4: total4,
          price5: total5,// totalprice5: total5,
          price6: total6,// totalprice6: total6,
          price7: total7,// totalprice7: total7,
          price8: total8,// totalprice8: total8,
          price9: total9,// totalprice9: total9,
          price10: total10, // totalprice10: total10,
          price11: total11,// totalprice11: total11,     
          price12: total12,// totalprice12: total12,
          priceTotal: totalprice,   // total: totalprice,
          // amountTax: totalTax,// totalTax: totalTax,
          totalr: 0
        }
      }
      const totalObj = funcTotal(dataArr11);
      const dataArr = dataArr11.map(item => {
        const totalr = `${((Number(item.totalr) / Number(totalObj.priceTotal)) * 100).toFixed(2)}`
        return {
          ...item,
          totalr,
        }
      })
      dataArr.forEach(item => totalObj.totalr = Number(item.totalr) + Number(totalObj.totalr))
      dataArr.push(totalObj)
      this.setState({ dataArr });

      // 流動資產
      const ac2DataArr_2 = cashbook.filter(item => item.flow === '1')
      this.setState({ ac2DataArr_2 });
      const accountIdArr_2 = [];
      ac2DataArr_2.forEach(({ detail, acc2Id, acc1Id }) => {
        if (detail !== undefined && detail !== "" && accountIdArr_2.indexOf(detail) === -1) {
          accountIdArr_2.push(detail)
        } else if (acc2Id !== undefined && acc2Id !== "" && accountIdArr_2.indexOf(acc2Id) === -1) {
          accountIdArr_2.push(acc2Id);
        }
        //else if (acc1Id !== undefined && acc1Id !== "" && accountIdArr_2.indexOf(acc1Id) === -1) {
        //        accountIdArr_2.push(acc1Id);
        //    }
      });
      const dataArr11_2 = acc2price(accountIdArr_2, ac2DataArr_2);
      const totalObj_2 = funcTotal(dataArr11_2);
      const dataArr_2 = dataArr11_2.map(item => {
        const totalr = `${((Number(item.totalr) / Number(totalObj_2.priceTotal)) * 100).toFixed(2)}`
        return {
          ...item,
          totalr,
        }
      })
      dataArr_2.forEach(item => totalObj_2.totalr = Number(item.totalr) + Number(totalObj_2.totalr))
      dataArr_2.push(totalObj_2)
      this.setState({ dataArr_2 });


      const cashbook2 = await Parse.queryData('cashbook', { companyId, year: mgyearSel, acc1Id: 'XZb3Gy9eLg' });
      // 非流動負債
      const ac2Data2Arr_2 = cashbook2.filter(item => item.flow === '0')
      const accountIdArr2_2 = [];
      ac2Data2Arr_2.forEach(({ detail, acc2Id, acc1Id }) => {
        if (detail !== undefined && detail !== "") {
          if (accountIdArr2_2.indexOf(detail) === -1) {
            accountIdArr2_2.push(detail)
          }
        } else if (acc2Id !== undefined && acc2Id !== "" && accountIdArr2_2.indexOf(acc2Id) === -1) {
          accountIdArr2_2.push(acc2Id);
        }
        //else if (acc1Id !== undefined && acc1Id !== "" && accountIdArr2_2.indexOf(acc1Id) === -1) {
        //          accountIdArr2_2.push(acc1Id);
        //        }
      });
      const dataArr21_2 = acc2price(accountIdArr2_2, ac2Data2Arr_2);
      const totalObj2_2 = funcTotal(dataArr21_2);
      const data2Arr_2 = dataArr21_2.map(item => {
        const totalr = `${((Number(item.totalr) / Number(totalObj2_2.priceTotal)) * 100).toFixed(2)}`
        return {
          ...item,
          totalr,
        }
      })
      data2Arr_2.forEach(item => totalObj2_2.totalr = Number(item.totalr) + Number(totalObj2_2.totalr))
      data2Arr_2.push(totalObj2_2)
      this.setState({ data2Arr_2 });

      // 流動負債
      const ac2Data2Arr = cashbook2.filter(item => item.flow === '1')
      const accountIdArr2 = [];
      ac2Data2Arr.forEach(({ detail, acc2Id, acc1Id }) => {
        if (detail !== undefined && detail !== "") {
          if (accountIdArr2.indexOf(detail) === -1) {
            accountIdArr2.push(detail)
          }
        } else if (acc2Id !== undefined && acc2Id !== "" && accountIdArr2.indexOf(acc2Id) === -1) {
          accountIdArr2.push(acc2Id);
        }
        //else if (acc1Id !== undefined && acc1Id !== "" && accountIdArr2.indexOf(acc1Id) === -1) {
        //          accountIdArr2.push(acc1Id);
        //        }
      });
      const dataArr21 = acc2price(accountIdArr2, ac2Data2Arr);
      const totalObj2 = funcTotal(dataArr21);
      const data2Arr = dataArr21.map(item => {
        const totalr = `${((Number(item.totalr) / Number(totalObj2.priceTotal)) * 100).toFixed(2)}`
        return {
          ...item,
          totalr,
        }
      })
      data2Arr.forEach(item => totalObj2.totalr = Number(item.totalr) + Number(totalObj2.totalr))
      data2Arr.push(totalObj2)
      this.setState({ data2Arr });
      const cashbook3 = await Parse.queryData('cashbook', { companyId, year: mgyearSel, acc1Id: 'b4zzTZiWmQ' })
      const accountIdArr3 = [];
      cashbook3.forEach(({ detail, acc2Id, acc1Id }) => {
        if (detail !== undefined && detail !== "") {
          if (accountIdArr3.indexOf(detail) === -1) {
            accountIdArr3.push(detail)
          }
        } else if (acc2Id !== undefined && acc2Id !== "" && accountIdArr3.indexOf(acc2Id) === -1) {
          accountIdArr3.push(acc2Id);
        } // else if (acc1Id !== undefined && acc1Id !== "" && accountIdArr3.indexOf(acc1Id) === -1) {
        //          accountIdArr3.push(acc1Id);
        //        }
      });
      const dataArr31 = acc2price(accountIdArr3, cashbook3);
      const totalObj3 = funcTotal(dataArr31);
      const data3Arr = dataArr31.map(item => {
        const totalr = `${((Number(item.totalr) / Number(totalObj3.priceTotal)) * 100).toFixed(2)}`
        return {
          ...item,
          totalr,
        }
      })
      data3Arr.forEach(item => totalObj3.totalr = Number(item.totalr) + Number(totalObj3.totalr))
      data3Arr.push(totalObj3)
      this.setState({ data3Arr, loading: false });
    } else {
      const cashbook = await Parse.queryData('cashbook', { companyId, year: mgyearSel, acc1Id: 'vtvfMREPEJ' });

      // 非流動資產
      const ac2DataArr = cashbook.filter(item => item.flow === '0')
      this.setState({ ac2DataArr });
      const accountIdArr = [];
      ac2DataArr.forEach(({ detail, acc2Id, acc1Id }) => {
        if (detail !== undefined && detail !== "" && accountIdArr.indexOf(detail) === -1) {
          accountIdArr.push(detail)
        } else if (acc2Id !== undefined && acc2Id !== "" && accountIdArr.indexOf(acc2Id) === -1) {
          accountIdArr.push(acc2Id);
        }
        //else if (acc1Id !== undefined && acc1Id !== "" && accountIdArr.indexOf(acc1Id) === -1) {
        //          accountIdArr.push(acc1Id);
        //        }
      });
      const dataArr11 = acc2price(accountIdArr, ac2DataArr);
      const funcTotal = (dataArr) => {
        let total1 = 0;
        let total2 = 0;
        let total3 = 0;
        let total4 = 0;
        let total5 = 0;
        let total6 = 0;
        let total7 = 0;
        let total8 = 0;
        let total9 = 0;
        let total10 = 0;
        let total11 = 0;
        let total12 = 0;
        let totalprice = 0;
        dataArr.forEach(item2 => total1 += Number(item2.price1))
        dataArr.forEach(item2 => total2 += Number(item2.price2))
        dataArr.forEach(item2 => total3 += Number(item2.price3))
        dataArr.forEach(item2 => total4 += Number(item2.price4))
        dataArr.forEach(item2 => total5 += Number(item2.price5))
        dataArr.forEach(item2 => total6 += Number(item2.price6))
        dataArr.forEach(item2 => total7 += Number(item2.price7))
        dataArr.forEach(item2 => total8 += Number(item2.price8))
        dataArr.forEach(item2 => total9 += Number(item2.price9))
        dataArr.forEach(item2 => total10 += Number(item2.price10))
        dataArr.forEach(item2 => total11 += Number(item2.price11))
        dataArr.forEach(item2 => total12 += Number(item2.price12))
        dataArr.forEach(item2 => totalprice += Number(item2.priceTotal))
        return {
          title: "total",
          accountText: "總計",
          price1: total1,// totalprice1: total1,
          price2: total2,// totalprice2: total2,
          price3: total3,// totalprice3: total3,
          price4: total4,// totalprice4: total4,
          price5: total5,// totalprice5: total5,
          price6: total6,// totalprice6: total6,
          price7: total7,// totalprice7: total7,
          price8: total8,// totalprice8: total8,
          price9: total9,// totalprice9: total9,
          price10: total10, // totalprice10: total10,
          price11: total11,// totalprice11: total11,     
          price12: total12,// totalprice12: total12,
          priceTotal: totalprice,   // total: totalprice,
          totalr: 0
        }
      }
      const totalObj = funcTotal(dataArr11);
      const dataArr = dataArr11.map(item => {
        const totalr = `${((Number(item.totalr) / Number(totalObj.priceTotal)) * 100).toFixed(2)}`
        return {
          ...item,
          totalr,
        }
      })
      dataArr.forEach(item => totalObj.totalr = Number(item.totalr) + Number(totalObj.totalr))
      dataArr.push(totalObj)
      this.setState({ dataArr });

      // 流動資產
      const ac2DataArr_2 = cashbook.filter(item => item.flow === '1')
      this.setState({ ac2DataArr_2 });
      const accountIdArr_2 = [];
      ac2DataArr_2.forEach(({ detail, acc2Id, acc1Id }) => {
        if (detail !== undefined && detail !== "" && accountIdArr_2.indexOf(detail) === -1) {
          accountIdArr_2.push(detail)
        } else if (acc2Id !== undefined && acc2Id !== "" && accountIdArr_2.indexOf(acc2Id) === -1) {
          accountIdArr_2.push(acc2Id);
        } //else if (acc1Id !== undefined && acc1Id !== "" && accountIdArr_2.indexOf(acc1Id) === -1) {
        //        accountIdArr_2.push(acc1Id);
        //    }
      });
      const dataArr11_2 = acc2price(accountIdArr_2, ac2DataArr_2);
      const totalObj_2 = funcTotal(dataArr11_2);
      const dataArr_2 = dataArr11_2.map(item => {
        const totalr = `${((Number(item.totalr) / Number(totalObj_2.priceTotal)) * 100).toFixed(2)}`
        return {
          ...item,
          totalr,
        }
      })
      dataArr_2.forEach(item => totalObj_2.totalr = Number(item.totalr) + Number(totalObj_2.totalr))
      dataArr_2.push(totalObj_2)
      this.setState({ dataArr_2 });

      // 非流動負債
      const cashbook2 = await Parse.queryData('cashbook', { companyId, year: mgyearSel, acc1Id: 'XZb3Gy9eLg' });
      const ac2Data2Arr_2 = cashbook2.filter(item => item.flow === '0')
      const accountIdArr2_2 = [];
      ac2Data2Arr_2.forEach(({ detail, acc2Id, acc1Id }) => {
        if (detail !== undefined && detail !== "") {
          if (accountIdArr2_2.indexOf(detail) === -1) {
            accountIdArr2_2.push(detail)
          }
        } else if (acc2Id !== undefined && acc2Id !== "" && accountIdArr2_2.indexOf(acc2Id) === -1) {
          accountIdArr2_2.push(acc2Id);
        }
        //else if (acc1Id !== undefined && acc1Id !== "" && accountIdArr2_2.indexOf(acc1Id) === -1) {
        //          accountIdArr2_2.push(acc1Id);
        //        }
      });
      const dataArr21_2 = acc2price(accountIdArr2_2, ac2Data2Arr_2);
      const totalObj2_2 = funcTotal(dataArr21_2);
      const data2Arr_2 = dataArr21_2.map(item => {
        const totalr = `${((Number(item.totalr) / Number(totalObj2_2.priceTotal)) * 100).toFixed(2)}`
        return {
          ...item,
          totalr,
        }
      })
      data2Arr_2.forEach(item => totalObj2_2.totalr = Number(item.totalr) + Number(totalObj2_2.totalr))
      data2Arr_2.push(totalObj2_2)
      this.setState({ data2Arr_2 });

      // 流動負債
      const ac2Data2Arr = cashbook2.filter(item => item.flow === '1')
      const accountIdArr2 = [];
      ac2Data2Arr.forEach(({ detail, acc2Id, acc1Id }) => {
        if (detail !== undefined && detail !== "") {
          if (accountIdArr2.indexOf(detail) === -1) {
            accountIdArr2.push(detail)
          }
        } else if (acc2Id !== undefined && acc2Id !== "" && accountIdArr2.indexOf(acc2Id) === -1) {
          accountIdArr2.push(acc2Id);
        } //else if (acc1Id !== undefined && acc1Id !== "" && accountIdArr2.indexOf(acc1Id) === -1) {
        //          accountIdArr2.push(acc1Id);
        //        }
      });
      const dataArr21 = acc2price(accountIdArr2, ac2Data2Arr);
      const totalObj2 = funcTotal(dataArr21);
      const data2Arr = dataArr21.map(item => {
        const totalr = `${((Number(item.totalr) / Number(totalObj2.priceTotal)) * 100).toFixed(2)}`
        return {
          ...item,
          totalr,
        }
      })
      data2Arr.forEach(item => totalObj2.totalr = Number(item.totalr) + Number(totalObj2.totalr))
      data2Arr.push(totalObj2)
      this.setState({ data2Arr });
      const cashbook3 = await Parse.queryData('cashbook', { companyId, year: mgyearSel, acc1Id: 'b4zzTZiWmQ' })
      const accountIdArr3 = [];
      cashbook3.forEach(({ detail, acc2Id, acc1Id }) => {
        if (detail !== undefined && detail !== "") {
          if (accountIdArr3.indexOf(detail) === -1) {
            accountIdArr3.push(detail)
          }
        } else if (acc2Id !== undefined && acc2Id !== "" && accountIdArr3.indexOf(acc2Id) === -1) {
          accountIdArr3.push(acc2Id);
        }
        // else if (acc1Id !== undefined && acc1Id !== "" && accountIdArr3.indexOf(acc1Id) === -1) {
        //          accountIdArr3.push(acc1Id);
        //        }
      });
      const dataArr31 = acc2price(accountIdArr3, cashbook3);
      const totalObj3 = funcTotal(dataArr31);
      const data3Arr = dataArr31.map(item => {
        const totalr = `${((Number(item.totalr) / Number(totalObj3.priceTotal)) * 100).toFixed(2)}`
        return {
          ...item,
          totalr,
        }
      })
      data3Arr.forEach(item => totalObj3.totalr = Number(item.totalr) + Number(totalObj3.totalr))
      data3Arr.push(totalObj3)
      this.setState({ data3Arr, loading: false });
    }
  }

  showTable = () => {
    const { companyObj } = this.props
    const { dataArr, dataArr_2, data2Arr_2, data2Arr, data3Arr, mgyearSel } = this.state;
    let leftArr = [<><Table.HeaderCell as='h4'>流動資產</Table.HeaderCell><Table.HeaderCell></Table.HeaderCell></>]
    let left2Arr = [<><Table.HeaderCell as='h4'>非流動資產</Table.HeaderCell><Table.HeaderCell></Table.HeaderCell></>]
    let rightArr = [<><Table.HeaderCell as='h4'>流動負債</Table.HeaderCell><Table.HeaderCell></Table.HeaderCell></>]
    let right2Arr = [<><Table.HeaderCell as='h4'>非流動負債</Table.HeaderCell><Table.HeaderCell></Table.HeaderCell></>]
    let rightArr2 = [<><Table.HeaderCell as='h4'>權益</Table.HeaderCell>
      <Table.HeaderCell></Table.HeaderCell></>]
    let tableDataArr = dataArr.concat()
    let price1 = tableDataArr.splice(tableDataArr.length - 1, 1)
    let tableDataArr_2 = dataArr_2.concat()
    let price1_2 = tableDataArr_2.splice(tableDataArr_2.length - 1, 1)
    let tableDataArr2 = data2Arr_2.concat()
    let price2 = tableDataArr2.splice(tableDataArr2.length - 1, 1)
    let tableDataArr2_2 = data2Arr.concat()
    let price2_2 = tableDataArr2_2.splice(tableDataArr2_2.length - 1, 1)
    let tableDataArr3 = data3Arr.concat()
    let price3 = tableDataArr3.splice(tableDataArr3.length - 1, 1)
    let outputTable = []
    for (let i = 0; i < Math.max(tableDataArr.length, tableDataArr_2.length, tableDataArr2.length, tableDataArr2_2.length, tableDataArr3.length); i++) {
      if (tableDataArr[i] !== undefined) {
        left2Arr.push(<><Table.Cell>{tableDataArr[i].accountText}</Table.Cell>
          <Table.Cell>{tableDataArr[i].priceTotal}</Table.Cell></>)
      }
      if (tableDataArr_2[i] !== undefined) {
        leftArr.push(<><Table.Cell>{tableDataArr_2[i].accountText}</Table.Cell>
          <Table.Cell>{tableDataArr_2[i].priceTotal}</Table.Cell></>)
      }
      if (tableDataArr2[i] !== undefined) {
        right2Arr.push(<><Table.Cell>{tableDataArr2[i].accountText}</Table.Cell>
          <Table.Cell>{tableDataArr2[i].priceTotal}</Table.Cell></>)
      }
      if (tableDataArr2_2[i] !== undefined) {
        rightArr.push(<><Table.Cell>{tableDataArr2_2[i].accountText}</Table.Cell>
          <Table.Cell>{tableDataArr2_2[i].priceTotal}</Table.Cell></>)
      }
      if (tableDataArr3[i] !== undefined) {
        rightArr2.push(<><Table.Cell>{tableDataArr3[i].accountText}</Table.Cell>
          <Table.Cell>{tableDataArr3[i].priceTotal}</Table.Cell></>)
      }
    }
    leftArr.push(<><Table.Cell>流動資產合計</Table.Cell>
      <Table.Cell>{price1_2[0] && price1_2[0].priceTotal}</Table.Cell></>)
    left2Arr.push(<><Table.Cell>非流動資產合計</Table.Cell>
      <Table.Cell>{price1[0] && price1[0].priceTotal}</Table.Cell></>)
    left2Arr.push(<><Table.Cell>資產合計</Table.Cell>
      <Table.Cell>{(price1[0] && price1[0].priceTotal) + (price1_2[0] && price1_2[0].priceTotal)}</Table.Cell></>)
    rightArr.push(<><Table.Cell>流動負債合計</Table.Cell>
      <Table.Cell>{price2_2[0] && price2_2[0].priceTotal}</Table.Cell></>)
    right2Arr.push(<><Table.Cell>非流動負債合計</Table.Cell>
      <Table.Cell>{price2[0] && price2[0].priceTotal}</Table.Cell></>)
    right2Arr.push(<><Table.Cell>負債合計</Table.Cell>
      <Table.Cell>{(price2[0] && price2[0].priceTotal) + (price2_2[0] && price2_2[0].priceTotal)}</Table.Cell></>)
    rightArr2.push(<><Table.Cell>權益合計</Table.Cell>
      <Table.Cell>{price3[0] && price3[0].priceTotal}</Table.Cell></>)
    rightArr2.push(<><Table.Cell>負債與權益合計</Table.Cell>
      <Table.Cell>{(price2[0] && price2[0].priceTotal) + (price2_2[0] && price2_2[0].priceTotal) + (price3[0] && price3[0].priceTotal)}</Table.Cell></>)
    leftArr = leftArr.concat(left2Arr)
    rightArr = rightArr.concat(right2Arr, rightArr2)
    for (let i = 0; i < Math.max(leftArr.length, rightArr.length); i++) {
      let obj
      if (leftArr[i] === undefined) {
        obj = <Table.Row><Table.Cell></Table.Cell><Table.Cell></Table.Cell>{rightArr[i]}</Table.Row>
        outputTable.push(obj)
      } else if (rightArr[i] === undefined) {
        obj = <Table.Row>{leftArr[i]}<Table.Cell></Table.Cell><Table.Cell></Table.Cell></Table.Row>
        outputTable.push(obj)
      } else {
        obj = obj = <Table.Row>{leftArr[i]}{rightArr[i]}</Table.Row>
        outputTable.push(obj)
      }
    }

    return (<><Table.Header>
      <Table.Row>
        <Table.HeaderCell colSpan='4' textAlign='center'>{companyObj.name}</Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <Table.HeaderCell colSpan='4' textAlign='center'>資產負債表</Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <Table.HeaderCell colSpan='4' textAlign='center'>{mgyearSel}年</Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <Table.HeaderCell width={5}>項目</Table.HeaderCell>
        <Table.HeaderCell width={3}>金額</Table.HeaderCell>
        <Table.HeaderCell width={5}>項目</Table.HeaderCell>
        <Table.HeaderCell width={3}>金額</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
      <Table.Body>
        {outputTable}
      </Table.Body></>)
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    this.setState({ [name]: value, loading: true }, () => this.handleFetch(this.props, 0));
  }

  render() {
    const { dataArr, dataArr_2, data2Arr, data2Arr_2, data3Arr, loading, mgyearArr, mgyearSel } = this.state;
    // console.log(data2Arr)
    return (<>
      <br />
      <br />
      <ExportTableToExcelButton floated='right' name={`${mgyearSel}年_資產負債表`} id='excel_cashbook' />
      <Table id='cashbook' celled hidden>
        {this.showTable()}
      </Table>
      <Select style={{
        position: 'relative', zIndex: 99,
        float: 'left',
        minWidth: '200px',
      }}
        compact
        name="mgyearSel"
        options={mgyearArr}
        value={mgyearSel}
        onChange={this.handleChange}
      />
      <br />
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearSel} 年度 流動資產項目`),
          pageSize: 5,
          search: false
        }}
        data={dataArr_2}
        title={`${mgyearSel} 年度 流動資產項目`}
      />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearSel} 年度 非流動資產項目`),
          pageSize: 5,
          search: false
        }}
        data={dataArr}
        title={`${mgyearSel} 年度 非流動資產項目`}
      />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearSel} 年度 流動負債科目`),
          pageSize: 5,
          search: false
        }}
        data={data2Arr}
        title={`${mgyearSel} 年度 流動負債科目`}
      />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearSel} 年度 非流動負債科目`),
          pageSize: 5,
          search: false
        }}
        data={data2Arr_2}
        title={`${mgyearSel} 年度 非流動負債科目`}
      />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearSel} 年度 權益科目`),
          pageSize: 5,
          search: false
        }}
        data={data3Arr}
        title={`${mgyearSel} 年度 權益科目`}
      />
    </>)
  }
}