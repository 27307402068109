import React, { Component } from 'react'
import { Form } from 'semantic-ui-react';
import { Card, CardBody, CardHeader, Row, Col, Toast, ToastHeader, ToastBody } from 'reactstrap';

import Parse from 'widget/parse'
import { fieldCol } from "views/formDef/function";
import { portfolio } from 'views/formDef/object';

export default class Portfolio5 extends Component {
  state = {
    icSel: [],
    icId: '',
    providerSel: [],
    providerId: '',
  }

  async componentDidMount() {
    const { doc, companyObj, ic, provider } = this.props
    const companyId = companyObj.objectId;
    // const ic = await Parse.queryData('interestcondition', { companyId });
    // const provider = await Parse.queryData('provider', { companyId });

    const { agent } = this.props
    const agentSel = agent.map(item => ({ key: item.objectId, text: item.name, value: item.objectId }))
    // this.setState({ agentSel })

    const icSel = ic.map(item => ({
      key: item.objectId, text: `${item.item}`, value: item.objectId
    }))
    const providerSel = provider.map(item => ({
      key: item.objectId, text: `${item.name_cht}`, value: item.objectId
    }))
    let icId
    if (ic && ic.length && ic.find(item => (item.objectId === doc.interest_condition))) {
      icId = ic.find(item => (item.objectId === doc.interest_condition)).objectId
    } else {
      icId = ''
    }
    // this.setState({ icSel, icId });
    let providerId
    if (provider && provider.length && provider.find(item => (item.objectId === doc.provider_id))) {
      providerId = provider.find(item => (item.objectId === doc.provider_id)).objectId
    } else {
      providerId = ''
    }

    this.setState({ providerSel, providerId, icSel, icId, agentSel });
  }

  handleChange = (event, data) => {
    const { handleSelectUpdate } = this.props
    const { name, value } = data;
    handleSelectUpdate(data)
    this.setState({ [name]: value })
  }

  render() {
    const { doc, isEdit, showTable, handleChangeField } = this.props
    const { icId, icSel, providerId, providerSel, agentSel } = this.state;

    return (<>
      <Card fluid>
        {/* <Card.Content header={`[美國、加拿大房產] ${doc.product4_text}`} /> */}
        <CardHeader tag="h5" style={{ backgroundColor: '#f07b51' }}> [美國、加拿大房產] {doc.product4_text}</CardHeader>
        <CardBody>
          <Row>
            {fieldCol(portfolio.account_num, "pr-1", "3", doc.account_num, handleChangeField, !isEdit)}
            {fieldCol(portfolio.invest_type, "pr-1", "3", doc.invest_type, handleChangeField, !isEdit)}
            {fieldCol(portfolio.company_type, "pr-1", "3", doc.company_type, handleChangeField, !isEdit)}
            {fieldCol(portfolio.invest_money, "pr-1", "3", doc.invest_money, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.effective_date, "pr-1", "3", doc.effective_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.payment_method, "pr-1", "3", doc.payment_method, handleChangeField, !isEdit)}
            {fieldCol(portfolio.receive_money_date, "pr-1", "3", doc.receive_money_date, handleChangeField, !isEdit)}
            <Col><Form.Select fluid label='利率條件' options={icSel} name='icId' onChange={this.handleChange} value={icId} disabled={!isEdit} /></Col>
          </Row>
          <Row>
            {fieldCol(portfolio.pay_year_num, "pr-1", "3", doc.pay_year_num, handleChangeField, !isEdit)}
            {fieldCol(portfolio.giveinterest_period, "pr-1", "3", doc.giveinterest_period, handleChangeField, !isEdit)}
            {fieldCol(portfolio.payMonthNum, "pr-1", "3", doc.payMonthNum, handleChangeField, !isEdit)}
            <Col><Form.Select fluid label='供應商' options={providerSel} name='providerId' onChange={this.handleChange} value={providerId} disabled={!isEdit} /></Col>
          </Row>
          <Row>
            {fieldCol(portfolio.nowphase, "pr-1", "3", doc.nowphase, handleChangeField, !isEdit)}
            {fieldCol(portfolio.prebuybook_date, "pr-1", "3", doc.prebuybook_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.givecontract_date, "pr-1", "3", doc.givecontract_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.is_leave, "pr-1", "3", doc.is_leave, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {/* {fieldCol(portfolio.arrReturnPrincipal, "pr-1", "3", doc.arrReturnPrincipal, handleChangeField, !isEdit)} */}
            {fieldCol(portfolio.leavetotal_money, "pr-1", "3", doc.leavetotal_money, handleChangeField, !isEdit)}
            {fieldCol(portfolio.p5Country, "pr-1", "3", doc.p5Country, handleChangeField, !isEdit)}
            {fieldCol(portfolio.currency_type, "pr-1", "3", doc.currency_type, handleChangeField, !isEdit)}
            {fieldCol(portfolio.collectdone_date, "pr-1", "3", doc.collectdone_date, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.assumeinvest_year, "pr-1", "3", doc.assumeinvest_year, handleChangeField, !isEdit)}
            {fieldCol(portfolio.taxret_text, "pr-1", "3", doc.taxret_text, handleChangeField, !isEdit)}
            {fieldCol(portfolio.t2848_text, "pr-1", "3", doc.t2848_text, handleChangeField, !isEdit)}
            <Col sm='3'><Form.Select fluid label='agent' options={agentSel} id='agent_id' name='agent_text' onChange={this.handleChange} value={doc.agent_id || ''} disabled={!isEdit} /></Col>
          </Row>
          <Row>
            <Col sm='6'>{this.props.handlePaymentDateMoney(1)}</Col>
            <Col sm='6'>{this.props.handlePaymentDateMoney(2)}</Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col>
              <Card fluid style={{ borderLeft: 'solid', backgroundColor: 'lightgoldenrodyellow', maxWidth: 'inherit' }}>
                <CardHeader style={{ backgroundColor: 'lightgoldenrodyellow', borderBottom: 'double' }}>IRS W7 FORM</CardHeader>
                <CardBody>
                  <Row>
                    {fieldCol(portfolio.irs_w7_sign_date, "pr-1", "3", doc.irs_w7_sign_date, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.irs_w7_status, "pr-1", "3", doc.irs_w7_status, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.irs_w7_sent_date, "pr-1", "3", doc.irs_w7_sent_date, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.irs_ps, "pr-1", "3", doc.irs_ps, handleChangeField, !isEdit)}
                  </Row>
                </CardBody>
              </Card>
              {/* <Toast style={{ borderColor: 'black', backgroundColor: 'lightgoldenrodyellow', maxWidth: 'inherit' }}>
                <ToastHeader>IRS W7 FORM</ToastHeader>
                <ToastBody>
                  <Row>
                    {fieldCol(portfolio.irs_w7_sign_date, "pr-1", "3", doc.irs_w7_sign_date, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.irs_w7_status, "pr-1", "3", doc.irs_w7_status, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.irs_w7_sent_date, "pr-1", "3", doc.irs_w7_sent_date, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.irs_ps, "pr-1", "3", doc.irs_ps, handleChangeField, !isEdit)}
                  </Row>
                </ToastBody>
              </Toast> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <Card fluid style={{ borderLeft: 'solid', backgroundColor: 'lightgoldenrodyellow', maxWidth: 'inherit' }}>
                <CardHeader style={{ backgroundColor: 'lightgoldenrodyellow', borderBottom: 'double' }}>Certified Passport</CardHeader>
                <CardBody>
                  <Row>
                    {fieldCol(portfolio.certpassport_use_date, "pr-1", "3", doc.certpassport_use_date, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.certpassport_status, "pr-1", "3", doc.certpassport_status, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.certpassport_sent_date, "pr-1", "3", doc.certpassport_sent_date, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.certpassport_ps, "pr-1", "3", doc.certpassport_ps, handleChangeField, !isEdit)}
                  </Row>
                </CardBody>
              </Card>
              {/* <Toast style={{ borderColor: 'black', backgroundColor: 'lightgoldenrodyellow', maxWidth: 'inherit' }}>
                <ToastHeader>Certified Passport</ToastHeader>
                <ToastBody>
                  <Row>
                    {fieldCol(portfolio.certpassport_use_date, "pr-1", "3", doc.certpassport_use_date, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.certpassport_status, "pr-1", "3", doc.certpassport_status, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.certpassport_sent_date, "pr-1", "3", doc.certpassport_sent_date, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.certpassport_ps, "pr-1", "3", doc.certpassport_ps, handleChangeField, !isEdit)}
                  </Row>
                </ToastBody>
              </Toast> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <Card fluid style={{ borderLeft: 'solid', backgroundColor: 'lightgoldenrodyellow', maxWidth: 'inherit' }}>
                <CardHeader style={{ backgroundColor: 'lightgoldenrodyellow', borderBottom: 'double' }}>ITIN/SSN No.</CardHeader>
                <CardBody>
                  <Row>
                    {fieldCol(portfolio.itin_text, "pr-1", "3", doc.itin_text, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.itin_status, "pr-1", "3", doc.itin_status, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.itin_date, "pr-1", "3", doc.itin_date, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.itin_ps, "pr-1", "3", doc.itin_ps, handleChangeField, !isEdit)}
                  </Row>
                </CardBody>
              </Card>
              {/* <Toast style={{ borderColor: 'black', backgroundColor: 'lightgoldenrodyellow', maxWidth: 'inherit' }}>
                <ToastHeader>ITIN/SSN No.</ToastHeader>
                <ToastBody>
                  <Row>
                    {fieldCol(portfolio.itin_text, "pr-1", "3", doc.itin_text, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.itin_status, "pr-1", "3", doc.itin_status, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.itin_date, "pr-1", "3", doc.itin_date, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.itin_ps, "pr-1", "3", doc.itin_ps, handleChangeField, !isEdit)}
                  </Row>
                </ToastBody>
              </Toast> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <Card fluid style={{ borderLeft: 'solid', backgroundColor: 'lightgoldenrodyellow', maxWidth: 'inherit' }}>
                <CardHeader style={{ backgroundColor: 'lightgoldenrodyellow', borderBottom: 'double' }}>預計退場</CardHeader>
                <CardBody>
                  <Row>
                    {fieldCol(portfolio.assumeleave_year, "pr-1", "3", doc.assumeleave_year, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.assumeleave_season, "pr-1", "3", doc.assumeleave_season, handleChangeField, !isEdit)}
                  </Row>
                </CardBody>
              </Card>
              {/* <Toast style={{ borderColor: 'black', maxWidth: 'inherit' }}>
                <ToastHeader>預計退場</ToastHeader>
                <ToastBody>
                  <Row>
                    {fieldCol(portfolio.assumeleave_year, "pr-1", "3", doc.assumeleave_year, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.assumeleave_season, "pr-1", "3", doc.assumeleave_season, handleChangeField, !isEdit)}
                  </Row>
                </ToastBody>
              </Toast> */}
            </Col>
          </Row>
        </CardBody>
      </Card>
      {showTable('Beneficiary Bank')}
      {showTable('GiveInterestRecord')}
    </>)
  }
}