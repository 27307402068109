import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function SectionTeam(props = {}) {
  const { form, cookies, webimageArr } = props;
  const { text2 = '', text3 = '', img1File = {} } = form;
  const dbLang = cookies.get('dbLang') || 'name';
  const arr = webimageArr.filter(item => item.navId === form.objectId)
  return (
    <>
      <div className="section section-team cd-section" id="teams">
        {/* ********* TEAM 3 ********* */}
        <div className="team-3">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">{text2}</h2>
                <h5 className="description">
                  {text3}
                </h5>
              </Col>
            </Row>
            <div className="space-top" />
            <Row>
              {arr.map((item, index) => <>
                <Col md="6">
                  <Card className="card-profile card-plain">
                    <Row>
                      <Col md="5">
                        <CardImg top tag="div">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img
                              alt="..."
                              className="img"
                              src={(img1File && img1File.url) || require("assets/img/faces/clem-onojeghuo-3.jpg")}
                            />
                          </a>
                        </CardImg>
                      </Col>
                      <Col md="7">
                        <CardBody className="text-left">
                          <CardTitle tag="h3" style={{ color: `${index % 4 === 0 ? '#F2EEE5' : index % 4 === 3 ? '#E5C1C5' : index % 4 === 2 ? '#C3E2DD' : '#6ECEDA'}` }}>{item['title1' + dbLang] || ''}</CardTitle>
                          {/* <h6 className="card-category"></h6> */}
                          <p className="card-description">
                            {item['content1' + dbLang] || ''}
                          </p>
                          <CardFooter className="pull-left">
                            <Button
                              className="btn-just-icon btn-link mr-1"
                              color="twitter"
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              <i className="fa fa-twitter" />
                            </Button>
                            <Button
                              className="btn-just-icon btn-link mr-1"
                              color="facebook"
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              <i className="fa fa-facebook" />
                            </Button>
                            <Button
                              className="btn-just-icon btn-link"
                              color="google"
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              <i className="fa fa-google-plus" />
                            </Button>
                          </CardFooter>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </>)}
            </Row>
          </Container>
        </div>
        {/* ********* END TEAM 3 ********* */}
      </div>
    </>
  );
}

export default SectionTeam;
