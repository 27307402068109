import React, { Component } from 'react'
import _ from 'lodash'
import Moment from 'moment';
import { Container, Icon, Table, Input, Label, Select, Grid, Header, Menu, Tab, Segment, Form, Button } from 'semantic-ui-react'
// import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';
import { _calculateAge } from '../../../widget/define/date'
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import { showFieldText } from 'views/widgets/FieldsRender';
import { selType, statusArr, semesterArr } from '../../../widget/define/define'
import Parse from '../../../widget/parse'
import { DimmerImg } from '../../../layouts/Dimmer';

const getLabel = (formSet, key) => {
  return formSet.find(item2 => item2.key === key) ? formSet.find(item2 => item2.key === key).label : key;
}
const retRTobj = (formSet, arrKey, width) => {
  return arrKey.map(item => {
    var form = formSet.find(obj => obj.key === item);
    if (!form) {
      return { title: item }
    } else {
      var id = item
      if (form.type === 'select') {
        id = `${item}Text`;
      }
      return { id, title: getLabel(formSet, item), width, type: form.type }
    }
  });
}

const showTodayText = (date) => {
  // return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
  var d = new Date(date);
  return d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate() + "";
}
export default class StudentsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // doc: (props.students.length && props.students[0]) || '',
      // docId: (props.students.length && props.students[0].objectId) || '',
      students: [],
      selTypeId: '1',
      filterForm: {
        searchText: '',
        selClass: '0',
        year: props.semesterStatus === 'recruit' || props.tablename === 'inquiry' ? (props.semester === 'A' ? `${Number(props.semesterYear) + 1}` : props.semesterYear) : props.semesterYear,
        semes: props.semesterStatus === 'recruit' || props.tablename === 'inquiry' ? (props.semester === 'A' ? 'S' : 'A') : props.semester,
        yearsemester: props.semesterStatus === 'recruit' || props.tablename === 'inquiry' ? (props.semester === 'A' ? `${Number(props.semesterYear) + 1},S` : `${props.semesterYear},A`) : `${props.semesterYear},${props.semester}`,
        applyResult: '0'
      },
      loading: true
    };
  }

  componentDidMount() {
    this.handleFetch();
  }

  // componentWillReceiveProps(nextProps) {
  //   // console.log(nextProps.semesterStatus)
  //   // if (nextProps.semesterStatus !== this.props.semesterStatus ||
  //   //   nextProps.tablename === 'inquiry')
  //   if (nextProps.match.params.list0 !== this.props.match.params.list0)
  //     // console.log(nextProps.tablename)
  //     this.setState({
  //       year: nextProps.tablename === 'inquiry' ? `${Number(nextProps.semesterYear) + 1}` : this.props.semesterStatus === 'recruit' ? (this.props.semester === 'A' ? `${Number(this.props.semesterYear) + 1}` : this.props.semesterYear) : this.props.semesterYear,
  //       semes: nextProps.semesterStatus === 'recruit' ? (this.props.semester === 'A' ? 'S' : 'A') : this.props.semester,
  //       yearsemester: nextProps.tablename === 'inquiry' ? `${Number(this.props.semesterYear) + 1},${this.props.semester}` : this.props.semesterStatus === 'recruit' ? (this.props.semester === 'A' ? `${Number(this.props.semesterYear) + 1},S` : `${this.props.semesterYear},A`) : `${this.props.semesterYear},${this.props.semester}`,
  //     })
  // };

  handleFetch = async (props = this.props) => {
    const { firebase, studentsL, handleLazyLoad, companyObj } = props;
    // const { students } = this.state
    // const db = firebase.firestore();
    const students = await Parse.queryData('Students', { companyId: companyObj.objectId });
    // console.log(data)
    // if (!this.state.doc) {
    //   const doc = (students.length && props.students[0]) || '';
    //   const docId = (students.length && props.students[0]._id) || '';
    //   this.setState({ doc, docId });
    // }
    const parttimeData = await Parse.queryData('Parttime', { companyId: companyObj.objectId });
    // db.collectionGroup('Parttime').get().then(snap => {
    //   const data = [];
    //   snap.forEach(doc => data.push(doc.data()));
    //   this.setState({ parttimeData: data });
    // }, err => {
    //   console.log(`Encountered error: ${err}`);
    // });
    const consult = await Parse.queryData('Consult', { companyId: companyObj.objectId });
    // db.collectionGroup('Consult').get().then(snap => {
    //   const data = [];
    //   snap.forEach(doc => data.push(doc.data()));
    //   this.setState({ consult: data });
    // }, err => {
    //   console.log(`Encountered error: ${err}`);
    // });
    const graduation = await Parse.queryData('Graduate', { companyId: companyObj.objectId });
    // db.collectionGroup('Graduate').get().then(snap => {
    //   const data = [];
    //   snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //   this.setState({ graduation: data });
    // }, err => { console.log(`Encountered error: ${err}`); });
    this.setState({ students, parttimeData, consult, graduation, loading: false });

  }

  handleChange = (event, { name, value }) => {
    const { match, history, students } = this.props
    const { filterForm } = this.state
    const { _id, list } = match.params;

    if (name === 'yearsemester') {
      const year = value.substring(0, 4);
      const semes = value.substring(5);
      const yearsemester = value;
      this.setState({ filterForm: { ...filterForm, year, semes, selClass: '0', yearsemester, searchText: '' } }
        // () => this.studentSelect()
      );
    }
    // else if (name === 'selClass') {
    //   const selClass = value;
    //   this.setState({ filterForm: { ...filterForm, selClass, searchText: '' } }, () => this.studentSelect());
    // } else if (name === 'applyResult') {
    //   const applyResult = value;
    //   this.setState({ filterForm: { ...filterForm, applyResult, searchText: '' } }, () => this.studentSelect());
    // } else if (name === 'selTypeId') { // 選募集/在籍
    //   // history.push(`/StudentPage/${value}/${_id || (students.length && students[0]._id) || ''}`);
    //   this.setState({ filterForm: { ...filterForm, searchText: '' }, selTypeId: value }, () => this.studentSelect());
    // }
  };

  handleStudentSel = (event, data) => {
    let fleldVal = data.value;
    const { match, history } = this.props
    const { students } = this.state
    const { list = "1" } = match.params;
    // history.push(`/StudentPage/${list}/${fleldVal}`);

    const obj = students.find(item => item.objectId === fleldVal) || {};
    this.setState({ doc: obj });
  };

  render() {
    const { formSet, optSet, match, tablename, title } = this.props
    const { filterForm, selTypeId, params, consult, parttimeData, graduation, students, loading } = this.state
    // const { list = "1" } = match.params;
    console.log(tablename)
    const { yearsemester, applyResult, selClass, searchText } = filterForm;
    const recuritFields = [{
      id: 'recruitNum',
      title: getLabel(formSet, 'recruitNum'),
      width: 100,
    },
    // {
    //   id: 'studentId',
    //   title: getLabel(formSet, 'studentId'),
    //   width: 100,
    // },
    // {
    //   id: 'semesterYear',
    //   title: '学年',
    //   width: 70,
    // },
    // {
    //   id: 'semester',
    //   title: '学期',
    //   width: 50,
    // },
    {
      id: 'jpnName',
      title: getLabel(formSet, 'jpnName'),
      width: 150,
    },
    {
      id: 'nationalityText',
      title: '国籍',
    },];

    const studentFields = [{
      id: 'studentId',
      title: getLabel(formSet, 'studentId'),
      width: 100,
    },
    {
      id: 'semesterYear',
      title: '学年',
      width: 70,
    },
    {
      id: 'semester',
      title: '学期',
      width: 50,
    },
    {
      id: 'classSchool',
      title: 'クラス',
      width: 50,
    }
    ];

    let fields = [];

    // const selectionresult = ['testResult','','','','','',''];
    const selectionresult = ['testResult', 'interviewResult', 'admissionLiceResult', 'admissionLiceNum', 'ispassInform'];
    // const admissionPermit = ['theResultAdmissionPermit', 'issueAdmissionPermit', 'validityAdmissionPermit', 'numAdmissionPermit'];
    // const pickUp = ['expectedDateOfEntry', 'arrivalTime', 'whetherPickedUp', 'accommodations', 'arrivalSchoolTime', 'arrivalPs'];
    const pickUp = ['jpnName', 'nationality', 'expectedDateOfEntry', 'arrivalTime', 'whetherPickedUp', 'accommodations', 'checkinNum', 'checkInSchoolDate', 'checkInSchoolPs'];
    const accountOpening = ['arrivalBankCode', 'arrivalBankNo', 'arrivalMobile'];
    const parttime = ['permitNum', 'permitDate', 'storeName', 'storeAddressAndTel', 'workday', 'worktime', 'salary', 'personincharge'];
    const bike = ['bicycleNum', 'bicycleColor', 'bicycleStatus'];
    const extentDate = ['jpnName', 'nationality', 'visaExpireDate1', 'visaDuring1', 'visaExpireDate2', 'visaDuring2', 'visaExpireDate3', 'visaDuring3'];
    const dropoutList = ['jpnName', 'nationality', 'dropoutReason', 'dropoutPlan', 'dropoutDate', 'dropoutIsPermit'];
    const dropoutList2 = ['jpnName', 'nationality', 'dropoutReason'];
    const approvePrint = [];
    const applyResultList = ['jpnName', 'nationality'];
    const allRecommendList = ['agentCenterName', 'agentCenterNumber', 'agentName', 'agentNationality', 'agentRelationship'];
    const applyEntryList = ['jpnName', 'nationality', 'supportName', 'schoolagree', 'expectedDateOfEntry'];
    const inquiry = ['recruitNum', 'homeName', 'nationality', 'gender', 'age', 'agentRelationship', 'agentName', 'relationshipOfSchool', 'agentWechat', 'agentQq', 'agentTel', 'agentEmail', 'applyResult'];
    // 申込み状況: 電話問合せ　メール問合せ　受付問合せ　資料請求中　資料郵送済み　面接予定　面接合格済み　申請資料準備中　申請資料チェック中　S
    const studentCard = ['jpnName', 'nationality', 'launchDate', 'dateofEnrollment'];
    const certainlyEntryList = ['receptionDate', 'isPass', 'applyNum', 'educationInstitution', 'nationality',
      '7Country', 'jpnName', 'birthday', 'studentId', 'gender', 'remittanceMethod',
      'supportName', 'validityAdmissionPermit', 'finalEdu', 'recommName',
      'recommNameVietnam', 'nonDeliveryHistory', 'additionalSubmission'];
    const graduateSummary = ['jpnName', 'nationality', 'graduateDate', 'wayOutSituation'];
    const enrollBeforeAfterList = ['studentId', 'jpnName', '入學日', '修了日', '期間'];
    const immigrationApplication = ['applyResult', 'informDate', 'informWay', 'tuitionFee', 'feeReceivedDate']; // resultOfCertificateOfEligibility 在留資格認定結果
    const graduateAward = ['jpnName', 'nationality', 'gradPrice', 'gradPrice2', '日出席率', '時出席率', '日留試6月', '日留試11月', '校内試験9月', '11月', '1月', '日能試N1', '校内賞罰得点', '備考', '確認'];
    const recruitStudent = []; // recuritFields 已包含全部資料
    const classContact = ['jpnName', 'nationality'];
    // const registeredStudents = ['jpnName', 'nationality', 'japAbility', 'applicationFeeSelection', 'recommName'];
    const registeredStudents = ['jpnName', 'nationality'];
    const insurance = ['jpnName', 'nationality', 'healthAssuranceNum', 'healthAssuranceExpireDate', 'visaExpireDate1', 'residentCardnumber', 'copyResident1', 'visaExpireDate2', 'visaDuring2', 'copyResident2', 'visaExpireDate3', 'visaDuring3', 'copyResident3'];
    const instruction = ['inquiryDate', 'inquiryWay', 'inquiryType', 'inquiryContent', 'jpnName', 'contactMethod', 'contactInfo', 'handlerName']
    const applyFail = ['notPassReason', 'informDate', 'informWay', 'isApplyAgain']; // recuritFields 已包含全部資料
    const delstudents = ['jpnName', 'nationality'];
    const arrivalList = ['expectedDateOfEntry', 'arrivalTime', 'whetherPickedUp', 'accommodations', 'checkinNum', 'checkInSchoolDate', 'checkInSchoolPs']
    // const graduateCollegeInvest = ['targetSchool', 'targetSubject', 'studentArr'];
    const counselingSummary = ['graduateDate', 'studentId', 'jpnName', 'majorDesired', 'isPass'];
    const interviewAll = ['studentId', 'jpnName', 'interviewName', 'interviewNational', 'interviewYearsold', 'interviewBirthday', 'interviewGender', 'interviewBornplace', 'interviewDate', 'interviewEducation', 'interviewEducationOther', 'interviewWorkExperience',
      'interviewWorkExperience1', 'interviewWorkExperience2',
      'interviewWorkExperience3', 'interviewStudyPurpose', 'interviewStudyPurpose1', 'interviewSchoolCertificate', 'interviewSupportInterview', 'interviewSupportInterview1',
      'interviewRecommendInterview', 'interviewRecommendInterview1', 'interviewJpnAbility', 'interviewJpnAbility1', 'interviewJpnAbilityInterview', 'interviewJpnAbilityInterview1',
      'interviewEngAbilityInterview', 'interviewNote1', 'interviewNote2', 'interviewNote3', 'interviewNote4', 'interviewNote5', 'interviewAppearance', 'interviewAttitude', 'interviewAttitude1',
      'interviewPersonality', 'interviewPersonality1', 'interviewEnterPurpose', 'interviewEnterPurpose1', 'interviewInterested', 'interviewHobbies', 'interviewHobbies1'
    ];
    const studentList = ['jpnName', 'nationality', 'gender', 'graSchoolName', 'supportName', '提出日', '許可日', 'arrivalDate'];
    const applicantList = [
      // '日本語教育機関名', 
      'nationality', 'engName', 'birthday', 'age', 'gender', 'supportingMethod', 'supportRelationship',
      'studyTime', 'schoDis', 'agentCenterName', 'agentCenterNumber', 'noGiveHistory', 'additionalSubmission'];
    // { id: 'studyTime', title: '在籍予定期間' }
    // const graduateTestInvest = ['classSchool', 'studentId', 'semesterYear', 'jpnName', 'nameOfSchool', 'department'];
    const selection = ['testResult', 'interviewResult', 'preEduSeleResult', 'admissionLiceResult']
    let loadAllCurrentSemesterYear = "1"; // if==1, 2018S => 2017S 2017A 2018S, if==0 2018S => 2018S

    let getParam = "";
    switch (tablename) {
      case 'inquiry': // 入学問合せ
        loadAllCurrentSemesterYear = "0";
        fields = [
          // ctrl1,
          // ...recuritFields,
          ...retRTobj(formSet, inquiry),
          // columnDel
        ];
        // modalEditForm = inquiry;
        break;
      case 'selection': // 選考結果
        loadAllCurrentSemesterYear = "0";
        fields = [
          // ctrl1,
          // ctrlResult("#link13"),
          ...recuritFields,
          ...retRTobj(formSet, selectionresult),
          // columnDel
        ];
        // modalEditForm = selectionresult;
        break;
      // case 'admissionPermit': // 入学許可
      //   loadAllCurrentSemesterYear = "0";
      //   fields = [
      //     // ctrl1,
      //     ...recuritFields,
      //     ...retRTobj(formSet, admissionPermit),
      //     // {
      //     //   id:  'studentId',
      //     //   title: '入学許可書番号'
      //     // },
      //   ];
      //   // modalEditForm = admissionPermit;
      //   break;
      case 'immigrationApplication': // 入管申請
        loadAllCurrentSemesterYear = "0";
        fields = [
          // ctrlImmigrationApplication,
          {
            id: 'recruitNum',
            title: getLabel(formSet, 'recruitNum'),
            width: 100,
          },
          {
            id: 'studentId',
            title: getLabel(formSet, 'studentId'),
            width: 100,
          },
          {
            id: 'jpnName',
            title: getLabel(formSet, 'jpnName'),
            width: 150,
          },
          {
            id: 'nationalityText',
            title: '国籍',
          },
          ...retRTobj(formSet, immigrationApplication),
          // columnDel
        ];
        break;
      case 'applyFail': // 在留資格不交付
        loadAllCurrentSemesterYear = "0";
        fields = [
          // ctrlApplyFail,
          ...recuritFields,
          ...retRTobj(formSet, applyFail),
          // {
          //   id:  'a',
          //   title: '不交付理由番号'
          // },
          // {
          //   id:  'healthInsuranceNo',
          //   title: '不交付理由'
          // },
        ];
        // modalEditForm = applyFail;
        break;
      case 'recruitStudent':
        fields = [
          ...recuritFields,
          ...retRTobj(formSet, recruitStudent),
        ];
        break;
      case 'pickUp':
        loadAllCurrentSemesterYear = "0";
        fields = [
          // ctrl1,
          ...studentFields,
          ...retRTobj(formSet, pickUp),
        ];
        // modalEditForm = pickUp;
        break;
      case 'certainlyEntryList':
        fields = [
          // ctrl1,
          // ...studentFields,
          ...retRTobj(formSet, certainlyEntryList),
        ];
        // modalEditForm = certainlyEntryList;
        break;
      case 'accountOpening':
        fields = [
          // ctrl1,
          ...studentFields,
          ...retRTobj(formSet, accountOpening),
        ];
        // modalEditForm = accountOpening;
        break;
      case 'parttime':
        loadAllCurrentSemesterYear = "0";
        fields = [
          // ctrl1,
          ...studentFields,
          {
            id: 'jpnName',
            title: '日本語氏名'
          },
          ...retRTobj(formSet, parttime),
        ];
        // modalEditForm = parttime;
        break;
      case 'registeredStudents':
        loadAllCurrentSemesterYear = "0";
        fields = [
          // ctrl1,
          ...studentFields,
          {
            id: 'genderText',
            title: '性別'
          },
          ...retRTobj(formSet, registeredStudents),
          // columnDel
        ];
        // modalEditForm = registeredStudents;
        break;
      case 'graduateAward':
        fields = [
          // ctrl1,
          ...studentFields,
          ...retRTobj(formSet, graduateAward),
        ];
        // modalEditForm = graduateAward;
        break;
      case 'bike':
        loadAllCurrentSemesterYear = "0";
        fields = [
          // ctrl1,
          ...studentFields,
          {
            id: 'bicycle',
            title: '学生自転ステツカ一',
            Cell: row => (<React.Fragment>{this.showBicycleTitle(row.original)}</React.Fragment>)
          },
          // ...retRTobj(formSet, bike),
        ];
        // modalEditForm = bike;
        break;
      case 'extentDate':
        fields = [
          // ctrlResult("#link2"),
          ...studentFields,
          ...retRTobj(formSet, extentDate),
        ];
        // modalEditForm = extentDate;
        break;
      case 'studentCard':
        loadAllCurrentSemesterYear = "0";
        fields = [
          // ctrl1,
          ...studentFields,
          ...retRTobj(formSet, studentCard),
        ];
        // modalEditForm = studentCard;
        break;
      case 'graduateSummary':
        fields = [
          // ctrl1,
          ...studentFields,
          ...retRTobj(formSet, graduateSummary),
          {
            id: 'schoolOrCompany',
            title: '進学学校/就職先'
          }
        ];
        // modalEditForm = graduateSummary;
        break;
      case 'enrollBeforeAfterList':
        fields = [
          ...retRTobj(formSet, enrollBeforeAfterList),
          {
            id: 'enrollBefore',
            title: '入学前'
          },
          {
            id: 'enrollAfter',
            title: '入学後'
          },
        ];
        // modalEditForm = graduateSummary;
        break;
      case 'dropoutList':
        loadAllCurrentSemesterYear = "0";
        getParam = "isDropout=1";
        fields = [
          // ctrlResult("#link11"),
          ...studentFields,
          ...retRTobj(formSet, dropoutList),
        ];
        // modalEditForm = dropoutList;
        break;
      case 'dropoutList2':
        loadAllCurrentSemesterYear = "0";
        getParam = "isDropout=2";
        fields = [
          // ctrlResult("#link11"),
          ...studentFields,
          ...retRTobj(formSet, dropoutList2),
        ];
        // modalEditForm = dropoutList;
        break;
      case 'approvePrint':
        fields = [
          // ctrl1,
          ...studentFields,
          ...retRTobj(formSet, approvePrint),
        ];
        break;
      case 'applyResultList':
        fields = [
          // ctrl0,
          ...studentFields,
          ...retRTobj(formSet, applyResultList),
        ];
        break;
      case 'allRecommendList':
        fields = [
          // ctrl1,
          ...recuritFields,
          ...retRTobj(formSet, allRecommendList),
        ];
        // modalEditForm = allRecommendList;
        break;
      case 'applyEntryList':
        fields = [
          // ctrl1,
          ...studentFields,
          ...retRTobj(formSet, applyEntryList),
        ];
        // modalEditForm = applyEntryList;
        break;
      case 'applyEndList':
        fields = [
          // ctrl1,
          {
            id: 'index',
            title: '番号\nNo.'
          },
          {
            id: 'nationalityText',
            title: '国籍·地域\nNationality/Region'
          },
          {
            id: 'fullName',
            title: '氏名\nName'
          },
          {
            id: 'genderText',
            title: '性別\nSex'
          },
          {
            id: 'birthday',
            title: '生年月日\nDate of Birth'
          },
          {
            id: 'jpnAddr',
            title: '住居地\nAddress in Japan'
          },
          {
            id: 'residentCardnumber',
            title: '在留カード番号\nResidence card No.'
          },
        ];
        break;
      case 'delstudents':
        fields = [
          // ctrlDeleteStudent,
          ...studentFields,
          {
            id: 'studentStatusText',
            title: '学生状態'
          },
          ...retRTobj(formSet, delstudents),
        ];
        break;
      case 'insurance':
        loadAllCurrentSemesterYear = "0";
        fields = [
          // ctrlResult("#link2"),
          // ...studentFields,
          ...retRTobj(formSet, insurance),
          // {
          //   title: '',
          //   headerClassName: '',
          //   fields: [
          //     // ctrlResult("#link2"),
          //     ...studentFields,
          //   ],
          // },
          // {
          //   title: '健康保険証',
          //   headerClassName: '',
          //   fields: [{
          //     id: 'healthAssuranceNum',
          //     title: '番号'
          //   },
          //   {
          //     id: 'healthAssuranceExpireDate',
          //     title: '期限',
          //     Cell: row => (
          //       row.value ? Moment(row.value).format("YYYY/MM/DD") : ''
          //     )
          //   }]
          // },
          // {
          //   title: '初回',
          //   headerClassName: '',
          //   fields: [{
          //     id: 'visaExpireDate1',
          //     title: 'ビサ期限',
          //     width: 108,
          //   },
          //   {
          //     id: 'residentCardnumber',
          //     title: '外国人登録',
          //     width: 143,
          //   },
          //   {
          //     id: 'copyResident1Text',
          //     title: 'コビー',
          //     width: 60,
          //   }]
          // },
          // {
          //   title: '第一回更新',
          //   headerClassName: '',
          //   fields: [
          //     {
          //       id: 'visaExpireDate2',
          //       title: 'ビサ期限',
          //       width: 108,
          //     },
          //     {
          //       id: 'visaDuring2',
          //       title: '期間',
          //       width: 143,
          //     },
          //     {
          //       id: 'copyResident2Text',
          //       title: 'コビー',
          //       width: 60,
          //     }]
          // },
          // {
          //   title: '第二回更新',
          //   headerClassName: '',
          //   fields: [{
          //     id: 'visaExpireDate3',
          //     title: 'ビサ期限',
          //     width: 108,
          //   },
          //   {
          //     id: 'visaDuring3',
          //     title: '期間',
          //     width: 143,
          //   },
          //   {
          //     id: 'copyResident3Text',
          //     title: 'コビー',
          //     width: 60,
          //   }]
          // }
        ];
        // modalEditForm = insurance;
        break;
      case 'instruction':
        fields = [
          ...retRTobj(formSet, instruction),
        ];
        break;
      case 'interviewAll':
        fields = [
          ...retRTobj(formSet, interviewAll),
        ];
        break;
      case 'studentList':
        fields = [
          {
            id: '',
            title: '受理番号'
          },
          {
            id: 'admissionLiceNum',
            title: '入学許可番号'
          },
          ...retRTobj(formSet, studentList),
        ];
        break;
      case 'applicantList':
        fields = [
          {
            id: 'eventDate',
            title: '申請日'
          },
          {
            id: 'isPass',
            title: '通番'
          },
          {
            id: 'index',
            title: '番号'
          },
          {
            id: 'applyNum',
            title: '申請番号'
          },
          {
            id: 'aiasoName',
            title: '日本語教育機関名'
          },
          ...retRTobj(formSet, applicantList),
        ];
        break;
      case 'arrivalList':
        fields = [
          // {
          //   id: 'eventDate',
          //   title: '日付'
          // },
          {
            id: 'classSchool',
            title: 'クラス'
          },
          {
            id: 'studentId',
            title: '学籍番号'
          },
          {
            id: 'jpnName',
            title: '氏名'
          },
          ...retRTobj(formSet, arrivalList),
          {
            id: 'createdAt',
            title: '記入時間'
          }
        ]
        break;
      case 'classContact':
        fields = [
          // ctrl1,
          ...studentFields,
          ...retRTobj(formSet, classContact),
        ];
        // modalEditForm = insurance;
        break;
      case 'graduateCollegeInvest':
        fields = [
          {
            id: 'targetSchool',
            title: '学校名'
          },
          {
            id: 'targetSubject',
            title: '学科'
          },
          {
            id: 'studentArr',
            title: '学籍番号 / 氏名 / 学年.学期 / 錄取結果'
          }
        ]
        break;
      case 'counselingSummary':
        fields = [
          ...retRTobj(formSet, counselingSummary),
          ...fields = [
            {
              id: 'nameOfSchool',
              title: '進学希望学校名'
            },
            {
              id: 'majorDesired',
              title: '希望科目'
            }
          ]
        ]
        break;
      case 'graduateTestInvest':
        fields = [
          {
            id: 'classSchool',
            title: 'クラス'
          },
          {
            id: 'studentId',
            title: '学籍番号'
          },
          {
            id: 'semesterYear',
            title: '期生'
          },
          {
            id: 'jpnName',
            title: '氏名'
          },
          {
            id: 'nameOfSchool',
            title: '進学校名'
          },
          {
            id: 'department',
            title: '学部（研究科）'
          }
        ]
        break;
      // eslint-disable-next-line no-duplicate-case
      case 'selection':
        fields = [
          ...retRTobj(formSet, selection)
        ]
        break;
      // case 'selection':
      //   break;
      default:
        break;
    }

    // let arrStu = tmp;
    // if (type === "1") { // 全部的出席率，只能照班來顯示
    //   arrStu = arrStu.filter(item => item.classSchool === classroom);
    // } else if (classroom !== "0") {
    //   arrStu = arrStu.filter(item => item.classSchool === classroom);
    // }
    // let applyDate = new Date(this.props.systemObj.applyDate)
    let rows = students.filter(item =>
      (item.studentStatus !== '0' && item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32') &&
      (item.applyResult === '1' || item.studentType === "2") && item.semesterYear === filterForm.year && item.semester === filterForm.semes);
    let semesArr = semesterArr

    if (tablename === 'admissionPermit'
      || tablename === "certainlyEntryList" || tablename === 'recruitStudent'
      || tablename === "applyResultList") {
      rows = students.filter(item => item.applyResult !== '5' && item.applyResult !== '6' && item.studentStatus === '10' && item.studentStatus2 === '11' && item.semesterYear === filterForm.year && item.semester === filterForm.semes)
        .sort((a, b) => ('' + a.recruitNum).localeCompare(b.recruitNum))
    }
    // console.log(graduation)
    if (tablename === 'graduateSummary') {
      rows.map(item => {
        item.schoolOrCompany = item.wayOutSituation === '4' ? item.nameOfCompany :
          item.wayOutSituation === '1' ?
            graduation && graduation.find(graduate => graduate.studentId === item.objectId && graduate.isEnter === '1').targetSchoolText : ''
      })
    }
    if (tablename === 'immigrationApplication' || tablename === 'arrivalList') {
      rows = students.filter(item => item.studentStatus === '30' && item.applyResult === '1' && item.semesterYear === filterForm.year && item.semester === filterForm.semes);
    }
    if (tablename === 'selection' || tablename === 'allRecommendList'
      || tablename === 'interviewAll' || tablename === 'studentList') {
      rows = students.filter(item => item.applyResult !== '5' && item.applyResult !== '6' && item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0' && item.semesterYear === filterForm.year && item.semester === filterForm.semes)
        .sort((a, b) => ('' + a.recruitNum).localeCompare(b.recruitNum))
    }
    if (tablename === 'extentDate') {
      rows = students.filter(item => (item.visaExpireDate2 || item.visaExpireDate3) && item.semesterYear === filterForm.year && item.semester === filterForm.semes);
    }
    if (tablename === 'inquiry') {
      rows = students.sort((a, b) => ('' + a.recruitNum).localeCompare(b.recruitNum))
        .filter(item => item.semesterYear === filterForm.year && item.semester === filterForm.semes)
      rows.map(item => {
        item.agentRelationshipText = item.agentRelationshipText && item.agentRelationshipText.length ? item.agentRelationshipText : item.agentRelationText
      })
    }
    if (tablename === 'applyFail') {
      rows = students.filter(item => item.studentStatus === '10' && item.applyResult === '2' && item.semesterYear === filterForm.year && item.semester === filterForm.semes);
    }
    if (tablename === 'applyEntryList') {
      rows = students.filter(item => item.studentStatus === '10' && item.studentStatus2 === '21' && item.semesterYear === filterForm.year && item.semester === filterForm.semes);
    }
    if (tablename === 'delstudents') {
      rows = students.filter(item => item.studentStatus === '-1' && item.semesterYear === filterForm.year && item.semester === filterForm.semes);
    }
    if (tablename === 'dropoutList') {
      rows = students.filter(item => item.studentStatus === '31' && item.semesterYear === filterForm.year && item.semester === filterForm.semes);
    }
    if (tablename === 'dropoutList2') {
      rows = students.filter(item => item.studentStatus === '32' && item.semesterYear === filterForm.year && item.semester === filterForm.semes);
    }
    if (tablename === 'applicantList') {
      rows = students.sort((a, b) => ('' + a.studentId).localeCompare(b.studentId))
        .filter(item =>
          (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
          item.applyResult === '-1' &&
          item.semesterYear === filterForm.year && item.semester === filterForm.semes);

      rows.map((item, index) => {
        // item.eventDate = applyDate && applyDate.getMonth() + 1 + "月" + applyDate.getDate() + "日"
        item.supportingMethod = item.payMyself ? "本人負担" : item.payInjap ? "在日経費支弁" : item.scholarship ? "奨学金" : item.outPay ? "外国送金" : item.othher ? item.othher : ""
        item.studyTime = item.semester === "S" ? "2年" : "1.5年"
        item.index = index + 1
        item.age = _calculateAge(item.birthday)
        item.aiasoName = '愛知国際学院'
        if (item.supportRelationship === '11' && item.supportRelationshipOther)
          item.supportRelationshipText = item.supportRelationshipOther
      })
    }
    if (tablename === 'parttime') {
      let parttimeNow = {}
      rows.map((item, index) => {
        parttimeNow = parttimeData && parttimeData.filter(data => data.studentId === item.objectId).sort((a, b) => b.createdAt - a.createdAt)[0];
        if (parttimeNow)
          parttime.map(column => { item[column] = parttimeNow[column] })
      })
    }
    if (tablename === 'applyEndList') {
      const pattern2 = new RegExp("[A-Za-z]+");
      semesArr = [{ key: 'empty', text: `在籍生(${Number(this.props.semesterYear) - 1}、${this.props.semesterYear})`, value: '0' }, ...semesterArr]
      rows = students.filter(item =>
        yearsemester === '0' ?
          (item.semesterYear === (Number(this.props.semesterYear) - 1 + '') || item.semesterYear === this.props.semesterYear) && item.applyResult === '1' :
          // item.studentStatus !== '0' && 
          item.applyResult === '1' && item.semesterYear === filterForm.year && item.semester === filterForm.semes);
      rows.map((item, index) => {
        item.index = index + 1
        item.fullName = item.jpnName + (pattern2.test(item.jpnName) ? '' : ('  ' + (item.engName || '')))
      })
      // console.log(rows)
    }
    if (tablename === 'instruction') {
      let consultNow = {}
      rows.map((item, index) => {
        consultNow = consult && consult.filter(data => data.studentId === item.objectId).sort((a, b) => b.createdAt - a.createdAt)[0];
        if (consultNow) {
          instruction.map(column => {
            if (column === 'inquiryContent')
              item['inquiryContent'] = consultNow['content']
            else if (column === 'inquiryWay')
              item['inquiryWayText'] = showFieldText(formSet, optSet, "inquiryWay", consultNow)
            else if (column === 'contactMethod')
              item['contactMethodText'] = showFieldText(formSet, optSet, "contactMethod", consultNow)
            else if (column === 'inquiryType')
              item['inquiryTypeText'] = showFieldText(formSet, optSet, "inquiryType", consultNow)
            else
              item[column] = consultNow[column]
          })
        }
      })
    }
    if (tablename === 'enrollBeforeAfterList') {
      rows.map((item, enrollBefore) => {
        // let learnHour = item.japLangLearnHour2;
        // item.japLangLearnHour2 = learnHour === '' ? '無' : learnHour;
        item.enrollBefore = `学習時數：${!item.japLangLearnHour2 ? '無' : item.japLangLearnHour2}`
      })
    }

    // const columns = fields.map(item => ({ name: item.id, ...item }));
    // console.log(columns);
    return (
      <div className='content'>
        <div style={{ padding: '10px', overflowX: 'scroll' }}>
          {/* <Header as='h3'>{list === '1' ? '2-3 募集' : '3-4 在籍'}生一覧</Header> */}
          <div style={{ marginBottom: '16px' }}>
            {/* <Form.Group widths='equal'> */}
            {/* <Select style={{
          minWidth: '100px',
          marginBottom: '4px',
        }} compact
          options={selType}
          onChange={this.handleChange}
          // disabled={isEdit}
          value={selTypeId}
          name='selTypeId' /> */}
            {/* <ReactToPrint
          trigger={(e) => <Button
            floated='right'
            content='印刷'
            // label='&nbsp;'
            color='orange' icon='print'
            size='small'
          />}
          content={() => this.componentRef}
          pageStyle={"@page { size: auto;  margin:5mm; }*{font-family: 'Noto Serif JP', serif;}"}
        /> */}
            <ModalPdf
              {...this.props}
              // ref={(ref) => { this.pdfModal = ref }}
              codekey={tablename + 'Pdf'}
              params={rows}
              yearsemester={yearsemester}
            />
            <ExportTableToExcelButton floated='right' />
            <Select style={{
              marginBottom: '4px',
              maxWidth: '200px',
            }} compact
              floated='right'
              fluid
              options={semesArr}
              onChange={this.handleChange}
              // disabled={isEdit}
              value={yearsemester}
              name='yearsemester' />
          </div>
          <div ref={el => (this.componentRef = el)} className="" >
            <h1 style={{ float: 'left' }}>{title}</h1>
            <div style={{ float: 'right', marginTop: '20px' }}>印刷日：{Moment(new Date()).format('YYYY/MM/DD')}</div>
            {loading ? <DimmerImg /> : <Table id="excel" sortable celled structured size='small'>
              <Table.Header>
                {/* {fields && fields.length ?
            (<Table.Row textAlign='center'>
              {fields.map(({ id, key, title, colspan = 1, rowspan = 1 }) =>
                (<Table.HeaderCell key={id} colSpan={colspan} rowSpan={rowspan}>{title}</Table.HeaderCell>))}
            </Table.Row>) : null} */}

                {tablename === 'applyEndList' ?
                  <>
                    <Table.Row>
                      <Table.HeaderCell colSpan='7'>参考様式2の4 別紙リスト(「留学」·複数：受入れの開始,終了又は状況)  List of subject of notification</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell colSpan='7'>届 出 機 関 名 Name of the organization</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell colSpan='7'>届 出 の 対 象 者（ 中 長 期 在 留 者 ） Subjects of notification(MID-TO-LONG TERM RESIDENT)</Table.HeaderCell>
                    </Table.Row>
                  </>
                  : null}
                {tablename === 'interviewAll' ?
                  <Table.Row>
                    <Table.HeaderCell colSpan='9'>基本情報</Table.HeaderCell>
                    <Table.HeaderCell colSpan='9'>学経歴</Table.HeaderCell>
                    <Table.HeaderCell colSpan='4'>経費支弁、推薦保証者</Table.HeaderCell>
                    <Table.HeaderCell colSpan='5'>語言能力</Table.HeaderCell>
                    <Table.HeaderCell colSpan='5'>注意必要な問題</Table.HeaderCell>
                    <Table.HeaderCell colSpan='10'>外見</Table.HeaderCell>
                  </Table.Row> : null}
                {tablename === 'insurance' ?
                  <Table.Row>
                    <Table.HeaderCell colSpan='2'>基本情報</Table.HeaderCell>
                    <Table.HeaderCell colSpan='2'>健康保険証</Table.HeaderCell>
                    <Table.HeaderCell colSpan='3'>初回</Table.HeaderCell>
                    <Table.HeaderCell colSpan='3'>第一回更新</Table.HeaderCell>
                    <Table.HeaderCell colSpan='3'>第二回更新</Table.HeaderCell>
                  </Table.Row> : null}
                <Table.Row>
                  {/* <Table.HeaderCell style={{ width: '60px' }} ></Table.HeaderCell> */}
                  {fields.map(({ id, title, width = 100, columnInvisible = '0' }, index) => (columnInvisible === '1' ? '' :
                    <Table.HeaderCell key={'a' + index + id}
                      // sorted={column === id ? direction : null}
                      // onClick={this.handleSort(id)}
                      // width={width}
                      style={{ width: { width } + 'px' }}
                    >{title}</Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {!rows.length ? <Table.Row><Table.Cell colSpan={fields.length} textAlign='center'>(資料無し)</Table.Cell></Table.Row> : null}
                {rows.map(({ objectId, ...rest }) => (<Table.Row key={objectId}>
                  {fields.map(({ id, type, columnInvisible = '0' }, index) => (columnInvisible === '1' ? '' :
                    <Table.Cell key={'b' + index + id}>
                      {id === 'birthday' ? rest[id] ? showTodayText(rest[id]) : '' :
                        type === 'date' && rest[id] ? Moment(rest[id] || '').format('YYYY/MM/DD') :
                          (rest && rest[id]) || ''}
                    </Table.Cell>
                  ))}
                </Table.Row>))}
              </Table.Body>
            </Table>}
          </div>
        </div >
      </div>)
  }
}
