import React, { Component } from 'react'
import { Header, Button, Grid, Icon, Form, Checkbox, Message, Loader } from 'semantic-ui-react';
// import fetchJson from '../widgets/http';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Parse from "widget/parse";
import _ from 'underscore'

const style = {
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  layout: {
    flexDirection: 'column',
    // minHeight: '100vh'
  },
}
const getAuthArray = (company, user, flag) => user[flag] ? _.union(user[flag + 'A'], [company.objectId]) : _.without(user[flag + 'A'], company.objectId);


export default class UserAdd extends Component {
  state = {
    form: {
      email: '',
      authUser: true,
      authLogin: true,
      authAdmin: false,
      // phoneNumber: '0900000000'
    },
    open: false,
    isLoading: false
  }

  handleChange = (e, data) => {
    const { name, value, checked } = data
    const { form } = this.state
    if (name === 'email') {
      this.setState({ form: { ...form, [name]: value }, msgError: '' })
    } else {
      this.setState({ form: { ...form, [name]: checked }, msgError: '' })
    }
  }

  handleSubmit = async () => {
    const { companyObj } = this.props;
    const { form, error } = this.state;
    const { email, authAdmin, authUser, authLogin } = form;

    if (!email) {
      window.alert('請輸入 E-Mail')
      return;
    }
    this.setState({ isLoading: true });

    const userArr = await Parse.queryData("User", { email });
    let password = '';
    // console.log(userArr)
    const roleType = authAdmin ? 'Admin' : 'Staff';

    if (userArr.length) { // 資料庫裡面有這個使用者了
      const user = userArr[0];
      const { authAdminA = [], authUserA = [], authLoginA = [] } = user;
      const userObj = { objectId: user.objectId, authAdminA, authUserA, authLoginA, authAdmin, authUser, authLogin };
      userObj.authAdminA = getAuthArray(companyObj, userObj, 'authAdmin')
      userObj.authUserA = getAuthArray(companyObj, userObj, 'authUser')
      userObj.authLoginA = getAuthArray(companyObj, userObj, 'authLogin')
      userObj.authAllA = _.union(userObj.authLogin, userObj.authAdminA, userObj.authUserA);
      delete userObj.authAdmin;
      delete userObj.authUser;
      delete userObj.authLogin;
      // await Parse.Cloud.run("addUserToRoleNamed", { userId: user.objectId, roleType })
      // const userACL = new Parse.ACL();
      // userACL.setRoleWriteAccess('Staff', true);
      // userACL.setPublicReadAccess(true);
      // user.setACL(userACL);
      // console.log(userObj)
      await Parse.saveData("User", userObj)
    } else {
      password = Math.random().toString(36).slice(-8);

      var user = new Parse.User();
      user.set("username", email);
      user.set("password", password);
      user.set("email", email);

      const userObj = { authAdmin, authUser, authLogin };
      userObj.authAdminA = getAuthArray(companyObj, userObj, 'authAdmin')
      userObj.authUserA = getAuthArray(companyObj, userObj, 'authUser')
      userObj.authLoginA = getAuthArray(companyObj, userObj, 'authLogin')
      userObj.authAllA = _.union(userObj.authLogin, userObj.authAdminA, userObj.authUserA);

      user.set("authAdminA", userObj.authAdminA);
      user.set("authUserA", userObj.authUserA);
      user.set("authLoginA", userObj.authLoginA);
      user.set("authAllA", userObj.authAllA);

      // const roleType = authAdmin ? 'Admin' : 'Staff';
      // console.log(authAdmin, roleType)
      const userACL = new Parse.ACL();
      // userACL.setRoleWriteAccess("Admin", true);
      userACL.setRoleWriteAccess(roleType, true);
      userACL.setPublicReadAccess(true);
      user.setACL(userACL);

      // const test = await Parse.Cloud.run("test")
      // console.log(test)

      // try {
      await user.signUp().catch(error => console.error(error));
      await Parse.Cloud.run("addUserToRoleNamed", { userId: user.id, roleType })
      // } catch (error) {
      // alert("Error: " + error.code + " " + error.message);
      // }
    }
    this.setState({ isLoading: false });
    // const res = await Parse.sendmail({ from: "測試 <aaa@gmain.com>", to: form.email, subject: "111re哈哈set password", html: "t321est" }
    await Parse.sendmail({
      from: `${companyObj.name} <mai8info@gmain.com>`,
      to: email,
      subject: `新增使用權限 ${companyObj.name}`,
      html: `您的帳號：${email}<br /> ${password && `您的密碼：${password}`}<br /><br />登入網址：https://${companyObj.url}`
    });
    this.props.refetch();
    // this.close();
    this.setState({ open: false }, () => window.alert("已成功新增帳號"));
  }

  close = () => this.setState({ open: false })
  render() {
    const { companyObj } = this.props
    const { form, open, error, isLoading } = this.state;
    const { email, authLogin, authAdmin, } = form;

    return (<>
      <Button basic color='green' size='tiny' onClick={() => this.setState({ open: true })}>
        <Icon name='user plus' />新增使用者</Button>
      <Modal
        isOpen={open}
        //  onOpen={this.open}
        //  onClose={this.close}
        // isOpen={this.state.modalDemo}
        toggle={this.close}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggle}>
            <span aria-hidden="true">×</span>
          </button>
          {companyObj.name} 新增使用者
        </ModalHeader>
        <ModalBody>
          <Grid>
            <Grid.Row>
              <Grid.Column width={5}>登入信箱</Grid.Column>
              <Grid.Column width={11}>
                <Form.Input
                  id="email"
                  required
                  fluid icon='mail'
                  iconPosition='left'
                  placeholder='登入信箱'
                  onChange={this.handleChange}
                  name='email'
                  value={email}
                // error={!email && written ? { content: '必填', pointing: 'below' } : false}
                />
              </Grid.Column>
            </Grid.Row>
            {/* <Grid.Row>
                  <Grid.Column width={5}>連絡電話/手機</Grid.Column>
                  <Grid.Column width={11}>
                    <Form.Input
                      id="phoneNumber"
                      required
                      fluid icon='phone'
                      iconPosition='left'
                      placeholder='連絡電話/手機'
                      onChange={this.handleChange}
                      name='phoneNumber'
                      value={phoneNumber}
                    // error={!email && written ? { content: '必填', pointing: 'below' } : false}
                    />
                  </Grid.Column>
                </Grid.Row> */}
            <Grid.Row>
              <Grid.Column width={5}>
                權限
              </Grid.Column>
              <Grid.Column width={11} style={{ ...style.flexCenter }}>
                {/* <Checkbox label='使用者' visibletype='2' name='authUser' onClick={this.handleChange} /> */}
                <Checkbox label='在職' visibletype='2' name='authLogin' checked={authLogin} onClick={this.handleChange} />
                {/* <Checkbox label='人資' visibletype='2' name='authHr' onClick={this.handleChange} />
                    <Checkbox label='會計' visibletype='2' name='authAccount' onClick={this.handleChange} /> */}
                <Checkbox label='系統管理' visibletype='2' name='authAdmin' checked={authAdmin} onClick={this.handleChange} />
                <span>&nbsp;</span>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          {error ? <Message negative>
            <Message.Header>錯誤</Message.Header>
            <p>{JSON.stringify(error, null, 2)}</p>
          </Message> : null}
          <br />
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button negative basic onClick={() => this.setState({ open: false })}>關閉</Button>
          <Button
            positive
            // icon='user plus'
            // labelPosition='right'
            onClick={this.handleSubmit}
          >
            {!isLoading
              ? "新增"
              : <Loader active inverted inline size='small' />}
          </Button>
        </ModalFooter>
      </Modal>
    </>)
  }
}