
import React, { Component } from 'react';
import { Container, Grid, Divider, Header, Card } from 'semantic-ui-react';
import { showField, showFieldText, getAvailblePeriodArr, getDate } from 'views/widgets/FieldsRender';
import { Row, Col } from 'reactstrap';
// import {
//   Card, CardImg, CardText, CardBody,
//   CardTitle, CardSubtitle, CardHeader, CardLink
// } from "reactstrap";

export default class List2Student extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { doc } = this.props;

    let isA = 0;
    if (doc.studentId && doc.studentId.substr(0, 1) === 'A') {
      isA = 1;
    }

    return (<>
      <Header as='h2' dividing
        content='旅券&在留登録'
        style={{ backgroundColor: 'lavender' }}
      // subheader='調査時間：2017年4月から 2018年12月 まで'
      />
      <br />
      {/* <Grid columns='equal'> */}
      <Row>
        <Col sm='6'>
          {/* <Row> */}
          <Card fluid >
            <Card.Content header='旅券情報' className='card-title' />
            <Card.Content>
              {/* <CardHeader tag="h5" > 旅券情報</CardHeader> */}
              {/* <CardBody> */}
              <Row>
                {/* <Grid columns='equal'> */}
                <Col sm='4'>{showField(this.props, "passportNum")}</Col>
                <Col sm='4'>{showField(this.props, "passportExpireDate")}</Col>
                <Col sm='4'>{showField(this.props, "passportIssueDate")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col sm='4'>{showField(this.props, "psptIssuingAuthority")}</Col>
                <Col sm='4'>{showField(this.props, "historyInout")}</Col>
                <Col sm='4'>{showField(this.props, "landingAdmitDate")}</Col>
                {/* </Grid> */}
              </Row>
            </Card.Content>
            {/* </CardBody> */}
          </Card>
          {/* </Row> */}
          <br />
          {/* <Row> */}
          <Card fluid >
            <Card.Content header='在留力一ドと保険証情報' className='card-title' />
            {/* <CardHeader tag="h5" > 在留力一ドと保険証情報</CardHeader> */}
            {/* <CardBody> */}
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col sm='6'>{showField(this.props, "residentCardnumber")}</Col>
                <Col sm='6'>{showField(this.props, "stayQualify")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col sm='4'>{showField(this.props, "copyResident1")}</Col>
                <Col sm='4'>{showField(this.props, "copyResident2")}</Col>
                <Col sm='4'>{showField(this.props, "copyResident3")}</Col>
                {/* </Grid> */}
              </Row>
              {/* </CardBody> */}
            </Card.Content>
          </Card>
          {/* </Row> */}
        </Col>
        <Col sm='6'>
          {/* <Row> */}
          <Card fluid >
            <Card.Content header='健康保険証情報' className='card-title' />
            {/* <CardHeader tag="h5" > 健康保険証情報</CardHeader> */}
            {/* <CardBody> */}
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col sm='6'>{showField(this.props, "healthAssuranceNum")}</Col>
                <Col sm='6'>{showField(this.props, "healthAssuranceExpireDate")}</Col>
                {/* </Grid> */}
              </Row>
            </Card.Content>
            {/* </CardBody> */}
          </Card>
          {/* </Row> */}
          <br />
          {/* <Row> */}
          <Card fluid >
            <Card.Content header='ビザ情報' className='card-title' />
            {/* <CardHeader tag="h5" > ビザ情報</CardHeader> */}
            {/* <CardBody> */}
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col sm='6'>{showField(this.props, "visaExpireDate1")}</Col>
                <Col sm='6'>{showField(this.props, "visaDuring1")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col sm='6'>{showField(this.props, "visaExpireDate2")}</Col>
                <Col sm='6'>{showField(this.props, "visaDuring2")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col sm='6'>{showField(this.props, "visaExpireDate3")}</Col>
                <Col sm='6'>{showField(this.props, "visaDuring3")}</Col>
                {/* </Grid> */}
              </Row>
              {/* </CardBody> */}
            </Card.Content>
          </Card>
          {/* </Row> */}
        </Col>
      </Row>
      {/* </Grid> */}
    </>);
  }
}

