
import React, { Component } from 'react'
import { Container, Header, Grid, Divider } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'

// import RowsDnd from './RowsDnd'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';


const columns1 = () => ([
  // { title: '', field: '' },
  { title: '狀態', field: 'type', lookup: { 1: '出借中', 2: '已歸還' }, },
  { title: '神祇名稱', field: 'god_name', lookup: { 1: '正爐主', 2: '出巡母娘', 3: '副爐主1號', 4: '副爐主2號', 5: '副爐主3號', 6: '副爐主5號', 7: '副爐主6號', 8: '副爐主7號', 9: '副爐主8號', 10: '副爐主9號', 11: '副爐主10號', 12: '副爐主11號', 13: '副爐主12號', 14: '文昌筆', 15: '濟公師父', 16: '母娘' }, },
  { title: '善信編號', field: 'people_id' },
  { title: '善信姓名', field: 'people_name' },
  { title: '聯絡電話', field: 'who_phone' },
  { title: '借神地址', field: 'who_addr' },
  { title: '押爐金', field: ' ' },
  { title: '借神日期', field: 'insertedDate' },
  { title: '備註', field: 'ps' },
]);
const columns2 = () => ([
  // { title: '', field: '' },
  { title: '狀態', field: 'type', lookup: { 1: '出借中', 2: '已歸還' }, },
  { title: '神祇名稱', field: 'god_name', lookup: { 1: '正爐主', 2: '出巡母娘', 3: '副爐主1號', 4: '副爐主2號', 5: '副爐主3號', 6: '副爐主5號', 7: '副爐主6號', 8: '副爐主7號', 9: '副爐主8號', 10: '副爐主9號', 11: '副爐主10號', 12: '副爐主11號', 13: '副爐主12號', 14: '文昌筆', 15: '濟公師父', 16: '母娘' }, },
  { title: '善信編號', field: 'people_id' },
  { title: '善信姓名', field: 'people_name' },
  { title: '聯絡電話', field: 'who_phone' },
  { title: '借神地址', field: 'who_addr' },
  { title: '借神日期', field: 'insertedDate' },
  { title: '押爐金', field: ' ' },
  { title: '油香', field: ' ' },
  { title: '歸還日期', field: 'updatedDate' },
]);

class BorrowGod extends Component {
  state = {
    borrowgod1: [],
    borrowgod2: [],
    data1: [],
    data2: [],
    sel1: '',
    sel2: '',
    title1: '',
    title2: '',
    loading: false,
    obj: {},
    form: '200',
  }

  render() {
    const { data1, data2, loading } = this.state;

    return (<div className="content">
      <Grid style={{ margin: '2rem auto' }}>
        <Grid.Row><Grid.Column>
          {/* <Header inverted={companyObj.inverted} as='h2'> */}
          <Header as='h2'>
            出借中</Header></Grid.Column></Grid.Row>
        <Grid.Row >
          <Grid.Column width={16}>
            <MaterialTable
              isLoading={loading}
              components={{
                Toolbar: props => (<div>
                  <div style={{ width: '150px', float: 'right', textAlign: 'right', padding: '15px' }}>
                    {/* <ModalPdf
                              {...this.props}
                              // ref={(ref) => { this.pdfModal = ref }}
                              // codekey='printTest'
                              nowform={'200'}
                              arrObj={data1}
                            /> */}
                  </div>
                  <MTableToolbar {...props} />
                  <div style={{ clear: 'both' }}></div>
                </div>),
              }}
              localization={localization()}
              columns={columns1()}
              options={{
                addRowPosition: "first",
                exportButton: true,
                exportPdf: (columns, data) => exportPdf(columns, data),
                pageSize: 10,
                search: false,
                sorting: true
              }}
              data={data1}
              title=""
              // actions={[
              //   {
              //     icon: 'view_headline',
              //     tooltip: '查看',
              //     onClick: (event, rowData) => this.handleData("1", rowData)
              //   }
              // ]}
              editable={{
                onRowAdd: newData1 =>
                  new Promise((resolve, reject) => {
                    // console.log(data)
                    // const db = firebase.firestore();
                    // const docRef = db.collection(`company/${companyObj._id}/borrowgod`).doc();
                    // const data = [...data1];

                    // const obj = {
                    //   _id: docRef.id,
                    //   id: docRef.id,
                    //   companyId: companyObj.id,
                    //   companyName: companyObj.name,
                    //   // product1_id: sel1,
                    //   // product1_text: (borrowgod.find(item => item._id === sel1) && borrowgod.find(item => item._id === sel1).value) || '',
                    //   ...newData1
                    // }
                    // data.push(obj);

                    // this.setState({ data1: data, newData1: obj }, () => resolve());
                  }).then(() => this.handleAdd()),
                onRowUpdate: (newData1, oldData1) =>
                  new Promise((resolve, reject) => {
                    const data = [...data1];
                    const index = data.indexOf(oldData1);
                    data[index] = newData1;
                    // console.log(data1)
                    this.setState({ data1: data, newData1, oldData1 }, () => resolve());
                  }).then(() => this.handleUpdate()),
                onRowDelete: oldData1 =>
                  new Promise((resolve, reject) => {
                    let data = [...data1];
                    const index = data.indexOf(oldData1);
                    data.splice(index, 1);
                    this.setState({ data1: data, oldData1 }, () => resolve());
                  }).then(() => this.handleDelete()),
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row><Grid.Column>
          {/* <Header inverted={companyObj.inverted} as='h2'> */}
          <Header as='h2'>
            已歸還
                  </Header></Grid.Column></Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <MaterialTable
              isLoading={loading}
              components={{
                Toolbar: props => (<div>
                  <div style={{ width: '150px', float: 'right', textAlign: 'right', padding: '15px' }}>
                    {/* <ModalPdf
                              {...this.props}
                              // ref={(ref) => { this.pdfModal = ref }}
                              // codekey='printTest'
                              // params={data1}
                              nowform={'200'}
                              arrObj={data2}
                            /> */}
                  </div>
                  <MTableToolbar {...props} />
                  <div style={{ clear: 'both' }}></div>
                </div>),
              }}
              localization={localization()}
              columns={columns2()}
              options={{
                addRowPosition: "first",
                exportButton: true,
                exportPdf: (columns, data) => exportPdf(columns, data),
                pageSize: 10,
                search: false
              }}
              data={data2}
              title=""
              // actions={[
              //   {
              //     icon: 'view_headline',
              //     tooltip: '查看',
              //     onClick: (event, rowData) => this.handleData("1", rowData)
              //   }
              // ]}
              editable={{
                onRowAdd: newData1 =>
                  new Promise((resolve, reject) => {
                    // console.log(data)
                    // const db = firebase.firestore();
                    // const docRef = db.collection(`company/${companyObj._id}/borrowgod`).doc();
                    // const data = [...data2];

                    // const obj = {
                    //   _id: docRef.id,
                    //   id: docRef.id,
                    //   companyId: companyObj.id,
                    //   companyName: companyObj.name,
                    //   // product1_id: sel1,
                    //   // product1_text: (borrowgod.find(item => item._id === sel1) && borrowgod.find(item => item._id === sel1).value) || '',
                    //   ...newData1
                    // }
                    // data.push(obj);
                    // this.setState({ data1: data, newData1: obj }, () => resolve());
                  }).then(() => this.handleAdd()),
                onRowUpdate: (newData1, oldData1) =>
                  new Promise((resolve, reject) => {
                    const data = [...data2];
                    const index = data.indexOf(oldData1);
                    data[index] = newData1;
                    // console.log(data1)
                    this.setState({ data1: data, newData1, oldData1 }, () => resolve());
                  }).then(() => this.handleUpdate()),
                onRowDelete: oldData1 =>
                  new Promise((resolve, reject) => {
                    let data = [...data2];
                    const index = data.indexOf(oldData1);
                    data.splice(index, 1);
                    this.setState({ data1: data, oldData1 }, () => resolve());
                  }).then(() => this.handleDelete()),
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>);
  }
}

export default BorrowGod;
