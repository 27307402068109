import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select, Icon } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'

import { Row, Col } from "reactstrap";
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { mgyearOpt, monthOpt, exportPdf } from 'views/web/CustomMaterialTable';

const columns = () => ([
  { width: 80, title: '區號', field: 'areaCode' },
  { width: 80, title: '位置說明', field: 'locationDescription' },
]);
const columns2 = () => ([
  { width: 80, title: '區號', field: 'areaCode' },
  { width: 80, title: '桌號', field: 'tableNum' },
  { width: 80, title: '固定座位數', field: 'fixedSeatsNum' },
  { width: 80, title: '位置說明', field: 'locationDescription' },

]);

const d = new Date()
class Borrowgame extends Component {
  state = {
    activeItem: 'bio',
    data1: [],
    data2: [],
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
    rowData: {}
  }
  componentDidMount = () => {
    this.handleFetch()
  }
  handleFetch = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data1 = await Parse.queryData('area', { companyId });

    this.setState({ data1 })
  }
  handleAdd = async (type) => {
    const { newData, newData1 } = this.state;
    if (type === '1') {
      await Parse.saveData('area', newData);
    } else {
      await Parse.saveData('tablePosition', newData1);
    }
  }

  handleUpdate = async (type) => {
    const { newData, newData1 } = this.state;
    if (type === '1') {
      await Parse.saveData('area', newData);
    } else {
      await Parse.saveData('tablePosition', newData1);
    }
  }

  handleDelete = async (type) => {
    const { oldData, oldData1 } = this.state
    if (type === '1') {
      await Parse.deleteData('area', oldData);
    } else {
      await Parse.deleteData('tablePosition', oldData1);
    }
  }

  handleData = async (rowData) => {
    console.log(rowData);
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data2 = await Parse.queryData('tablePosition', { companyId, areaId: rowData.objectId });

    this.setState({ rowData, data2 })
  }
  render() {
    const { companyObj } = this.props
    const { data1, data2, monthSel, mgyearSel, rowData } = this.state
    console.log(data1);
    return (<div className="content">
      <Header as='h2' inverted={companyObj.inverted}>桌位管理</Header>
      <Row>
        <Col md='6'>
          <MaterialTable
            localization={localization()}
            columns={columns()}
            options={{
              addRowPosition: "first",
              exportButton: true,
              exportPdf: (columns, data) => exportPdf(columns, data, '分區'),
              pageSize: 10,
              search: false
            }}
            data={data1}
            title="分區"
            actions={[
              {
                icon: 'view_headline',
                tooltip: '查看',
                onClick: (event, rowData) => this.handleData(rowData)
              }
            ]}
            editable={{
              onRowAdd: newData =>
                new Promise(async (resolve, reject) => {
                  const data = [...data1];
                  const db = Parse.Object.extend("area");
                  const newDoc = new db();
                  const docRef = await newDoc.save();
                  let obj = {
                    ...newData,
                    id: docRef.id,
                    _id: docRef.id,
                    objectId: docRef.id,
                    companyId: companyObj.objectId,
                    companyName: companyObj.name,
                  }
                  data.push(obj);
                  // handleState('people0', data);
                  this.setState({ data1: data, newData: obj }, () => resolve());
                }).then(() => this.handleAdd('1')),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const data = [...data1];
                  const index = data.indexOf(oldData);
                  data[index] = newData;
                  // handleState('people0', data);
                  this.setState({ data1: data, newData, oldData }, () => resolve());
                }).then(() => this.handleUpdate('1')),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  let data = [...data1];
                  const index = data.indexOf(oldData);
                  data.splice(index, 1);
                  // handleState('people0', data);
                  this.setState({ data1: data, oldData }, () => resolve());
                }).then(() => this.handleDelete('1')),
            }}
          />
        </Col>
        {rowData && rowData.objectId ?
          <Col md='6'>
            <MaterialTable
              localization={localization()}
              columns={columns2()}
              options={{
                addRowPosition: "first",
                exportButton: true,
                exportPdf: (columns, data) => exportPdf(columns, data, '桌位'),
                pageSize: 10,
                search: false
              }}
              data={data2}
              title="桌位"
              // actions={[
              //   {
              //     icon: 'view_headline',
              //     tooltip: '查看',
              //     onClick: (event, rowData) => this.handleData(rowData)
              //   }
              // ]}
              editable={{
                onRowAdd: newData1 =>
                  new Promise(async (resolve, reject) => {
                    const data = [...data2];
                    const db = Parse.Object.extend("tablePosition");
                    const newDoc = new db();
                    const docRef = await newDoc.save();
                    let obj = {
                      ...newData1,
                      id: docRef.id,
                      _id: docRef.id,
                      objectId: docRef.id,
                      companyId: companyObj.objectId,
                      companyName: companyObj.name,
                      areaId: rowData.objectId
                    }
                    data.push(obj);
                    // handleState('people0', data);
                    this.setState({ data2: data, newData1: obj }, () => resolve());
                  }).then(() => this.handleAdd('2')),
                onRowUpdate: (newData1, oldData1) =>
                  new Promise((resolve, reject) => {
                    const data = [...data2];
                    const index = data.indexOf(oldData1);
                    data[index] = newData1;
                    // handleState('people0', data);
                    this.setState({ data2: data, newData1, oldData1 }, () => resolve());
                  }).then(() => this.handleUpdate('2')),
                onRowDelete: oldData1 =>
                  new Promise((resolve, reject) => {
                    let data = [...data2];
                    const index = data.indexOf(oldData1);
                    data.splice(index, 1);
                    // handleState('people0', data);
                    this.setState({ data2: data, oldData1 }, () => resolve());
                  }).then(() => this.handleDelete('2')),
              }}
            />
          </Col> : null}
      </Row>
    </div >);
  }
}

export default Borrowgame;