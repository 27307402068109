import React, { Component } from 'react'
import { Header, Button, Form, Grid, Checkbox, Select, Icon, Dropdown } from 'semantic-ui-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TextareaAutosize from "react-textarea-autosize";
import MaterialTable from 'material-table'
import CKEditor from '@ckeditor/ckeditor5-react';

import ClassicEditor from 'widget/ckeditor';
import ModalUploadFiles from 'views/web/ModalUploadFiles';
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';
import localization from 'widget/MaterialTableOpt'

import Parse from 'widget/parse'

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

const clearObj = {
  from: "notice@hanburyifa.com",
  to: [],
  To2: '',
  cc: '',
  bcc: '',
  subject: '',
  Body: '',
  Portfolio: '',
}

const emailList = [
  { key: '1', value: '1', text: ' - 非電子報表 -' },
  { key: '2', value: '2', text: '投資月報表' },
  { key: '3', value: '3', text: 'Hanbury View' },
  { key: '4', value: '4', text: 'Hanbury Finanical' },
  { key: '5', value: '5', text: 'VOYA年度繳費提' },
  { key: '6', value: '6', text: 'FPI逾期款項提醒' },
  { key: '7', value: '7', text: '生日賀卡' },
  { key: '8', value: '8', text: '年節賀卡' }
]

const columns = (show) => ([
  // { title: '發佈對象 ', field: 'towhom', lookup: { '0': '', '1': '內部', '2': '外部' } },
  // { title: '#', field: 'sexual' },
  { title: '預覽', field: 'theFile', render: rowData => rowData && rowData.theFile ? <a href={rowData.theFile.url} target="_blank" rel="noopener noreferrer"> {rowData.name}</a> : null },
  // { title: '名稱', field: 'filename' },
  // { title: '上傳時間', field: 'insertAt', render: rowData => Moment(rowData.insertAt||rowData.insertAt.).format("YYYY-MM-DD hh:mm:ss") },
]);

const porColumns = (handleOpenPdf) => ([
  { title: '編號', field: 'uid' },
  { title: '姓名', field: '', render: rowData => `${rowData.name_cht}\n${rowData.email}` },
  { title: '產品類別1/2/3', field: '', render: rowData => `${rowData.product1_text}/${rowData.product2_text}/${rowData.product3_text}` },
  { title: '產品類別4', field: 'product4_text' },
  { title: 'Agent', field: 'agent_text' },
  // { title: '狀態', field: 'status_text' },
  // { title: '排程時間', field: 'schedule', render: rowData => Moment(rowData.schedule).format('YYYY-MM-DD HH:mm:ss') },
  // { title: '附件', field: 'addTime' },
]);

export default class After1a extends Component {
  state = {
    user: this.props.user.profile,
    dataArr: [],
    clientFileArr: [],
    dataArr3: [],
    fileArr: [],
    fileArrCheck: [],
    filenames: [],
    downloadURLs: [],
    downloadURLsArr: [],
    emails: [],
    emailsSel: [],
    emailsId: "",
    emailListId: '1',
    mailObj: {
      from: "notice@hanburyifa.com",
      to: [],
      To2: '',
      cc: '',
      bcc: '',
      subject: '',
      Body: '',
      Portfolio: '',
    },
    searchQuery: null,
    value: [],
    // options: [],
    newTitle: {},
    oldTitle: {},
    deleteConfirm: false,
    scheduleTime: false,
    show: false,

    portfoliosArr: [],
    selPortfoliosArr: [],
    onClickPortfoliosObj: {},
    portfolioEmailArr: [],
    productType1Opt: [],
    productType2Opt: [{ key: 'productType20', text: '-- 全選 --', value: '-1' }],
    productType3Opt: [{ key: 'productType30', text: '-- 全選 --', value: '-1' }],
    productType4Opt: [{ key: 'productType40', text: '-- 全選 --', value: '-1' }],
    disabled2: true,
    disabled3: true,
    disabled4: true,
    value1: '-1',
    value2: '-1',
    value3: '-1',
    value4: '-1',
    valueProvider: '-1',
    valueAgent: '-1',
    valueType: '-1',
    providerOpt: [],
    loading: true,
    autoDetect: false,
  }

  fileInputRef = React.createRef();

  componentDidMount = async () => {
    const { companyObj } = this.props;
    const companyId = companyObj.objectId;

    Parse.queryData('product1', { companyId }, ['order', 'value']).then(async (product1) => {
      const product2 = await Parse.queryData('product2', { companyId });
      const product3 = await Parse.queryData('product3', { companyId });
      const product4 = await Parse.queryData('product4', { companyId });
      const provider = await Parse.queryData('provider', { companyId });

      if (product1 && product1.length) {
        const productType1Opt = [{ key: 'productType', text: '-- 全選 --', value: '-1' }].concat(product1.sort((a, b) => a.order - b.order).map(item => ({ key: item.objectId, text: item.value, value: item.objectId })));
        const providerOpt = [{ key: 'providerType', text: '-- 全選 --', value: '-1' }].concat(provider.map(item => ({ key: item.objectId, text: item.name_cht + ' (' + item.name_eng + ')', value: item.objectId })));
        this.setState({ productType1Opt, providerOpt, })
      }
      this.setState({ product1, product2, product3, product4, provider, });
    });

    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props, type = 1) => {
    const { user } = this.state;
    const { match, companyObj, portfolios } = props;
    const companyId = companyObj.objectId;

    this.setState({ loading: true })

    if (type === 1) {
      if (window.location.pathname.split('/').pop() !== 'After1') {
        const to = window.location.pathname.split('/').pop()
        this.setState({ mailObj: { ...this.state.mailObj, To2: to } })
      }
      const data = await Parse.queryData('mailtitle', { companyId });
      const emailsSel = data.map(item => ({
        key: item.objectId, text: `${item.view_title}`, value: item.objectId
      }))
      let obj = {
        key: "noselect",
        text: `--樣板--`,
        value: `noselect`,
      }
      emailsSel.unshift(obj)
      this.setState({ emails: data, emailsSel, emailsId: (emailsSel && emailsSel.length && emailsSel[0].value) || '' });

      const dataArr = await Parse.queryData('clientFile', { companyId, clientId: user.id, type: 'emailFile', flag: 1 });
      this.setState({ dataArr })
    } else if (type === 0) {
      const data = await Parse.queryData('mailtitle', { companyId });
      const emailsSel = data.map(item => ({
        key: item.objectId, text: `${item.view_title}`, value: item.objectId
      }))
      let obj = {
        key: "noselect",
        text: `--樣板--`,
        value: `noselect`,
      }
      emailsSel.unshift(obj)
      this.setState({ emails: data, emailsSel });
    }

    this.setState({ loading: false, portfoliosArr: portfolios })
  }


  handleRowDelete = async () => {
    const { oldData, user } = this.state
    await Parse.deleteData('clientFile', oldData)
    this.setState({ oldData: {} })
  }

  handleSend = async () => {
    const { companyObj, clients } = this.props
    const { mailObj, dataArr, user, selPortfoliosArr, autoDetect, portfoliosArr } = this.state
    const { from, to = [], To2 = '', cc = '', bcc = '', subject = '', Body = '', Portfolio = '', scheduleAt = new Date() } = mailObj;

    const ccArr = (cc && cc.split(',')) || []
    const bccArr = (bcc && bcc.split(',')) || []
    let clientMail = to
    let clientMail2 = (To2 && To2.split(',')) || []
    let portfolioMail = (Portfolio && Portfolio.split(',')) || []
    let sendClientMail = []
    let sendPortfolioMail = portfolioMail
    let noEmail = []
    let noPortfolio = []
    if (autoDetect === true) {
      if (dataArr.length) {
        const sendFileArr = dataArr.map(item => item.name && item.name.split('_')[0] === 'Plan Report' ? { ...item, account_num: item.name.split('_')[1] } : null).filter(item => item)
        if (sendFileArr.length) {
          const account_numArr = portfoliosArr.map(item => item.account_num ? item : null).filter(item => item)
          const noObjArr = []
          const noEmailArr = []
          const sendPorArr = []
          sendFileArr.forEach(item => {
            const fileNum = item.account_num
            const porObj = account_numArr.find(item2 => item2.account_num === fileNum)
            if (porObj) {
              if (porObj.email) {
                sendPorArr.push(porObj)
              } else {
                noEmailArr.push(`\n${item.name}`)
              }
            } else {
              noObjArr.push(`\n${item.name}`)
            }
          })
          sendPorArr.forEach(item => {
            // if (sendFileArr.find(item2=>item2.account_num===item.account_num)) {
            const srcArr = []
            const file = sendFileArr.find(item2 => item2.account_num === item.account_num)
            const fileObj = {
              id: file.objectId,
              filename: file.name,
              path: file.theFile.url,
            }
            srcArr.push(fileObj)
            delete file.account_num
            let updateObj = {
              ...file,
              flag: 2
            }
            Parse.saveData('clientFile', updateObj)
            // }
            let html_header = '<img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/rY66GsLbHnTm9Tvrx-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Header.png" /><br />' + '<br />';
            let html_body = Body;
            let html_footer = '<br />' + '<br /><img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/yKNhmTQoRepiYtRSj-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Footer.png" />';
            delete mailObj.to
            delete mailObj.To2

            let obj = {
              ...mailObj,
              companyId: companyObj.objectId || '',
              companyName: companyObj.name || '',
              cc: ccArr,
              bcc: bccArr,
              html: html_header + html_body + html_footer,
              status: "99",
              status_text: "未寄出",
              to: item.email,
              To_name_cht: (clients && clients.find(item2 => item2.email === item.email) && clients.find(item2 => item2.email === item.email).name) || item.email,
              attachments: srcArr,
              type: 'timeEmail',
              scheduleAt,
            };
            let now = new Date()
            if (obj.scheduleAt <= now) {
              Parse.sendmail(obj)
              obj = {
                ...obj,
                status: '2',
                status_text: "已寄出",
              }
            }
            Parse.saveData('email', obj);
          })
          window.alert("寄出成功")
          this.setState({ mailObj: clearObj, value: [], emailListId: '1', dataArr: [], scheduleTime: false, emailsId: 'noselect' }, () => this.handleFetch(this.props, 1))
          if (noObjArr.length) {
            window.alert(`${noObjArr}  找無此檔案的保單`)
          }
          if (noEmailArr.length) {
            window.alert(`${noEmailArr}  此保單無電子信箱`)
          }
        } else {
          window.alert('無檔案可偵測，請輸入Plan Report檔')
        }
      } else {
        window.alert('無檔案可偵測，請輸入Plan Report檔')
      }
    } else {
      if (clientMail.length > 0 || clientMail2.length > 0 || portfolioMail.length > 0) {
        clientMail.forEach(item => {
          if (item) {
            if (clients.find(item2 => item2.objectId === item)) {
              let data = ''
              data = clients.find(item2 => item2.objectId === item).email
              if (data === undefined || data === '') {
                noEmail.push(`\n${clients.find(item2 => item2.objectId === item).uid} ${clients.find(item2 => item2.objectId === item).name_cht}`)
              } else if (sendClientMail.indexOf(data) === -1 && data !== undefined && data !== '') {
                sendClientMail.push(data)
              }
            }
          }
        })
        clientMail2.forEach(item => {
          if (item) {
            if (sendClientMail.indexOf(item) === -1 && item !== '' && item !== undefined) {
              sendClientMail.push(item)
            }
          }
        })

        sendClientMail.forEach(item => {
          if (portfolioMail.indexOf(item) !== -1) {
            const index = portfolioMail.indexOf(item);
            portfolioMail.splice(index, 1);
          }
        })

        sendClientMail.forEach(item => {
          let srcArr = []
          if (dataArr.length > 0) {
            dataArr.forEach(item2 => {
              let fileObj = {
                id: item2.objectId,
                filename: item2.name,
                path: item2.theFile.url,
              }
              srcArr.push(fileObj)
              let obj = {
                ...item2,
                flag: 2
              }
              Parse.saveData('clientFile', obj)
            })
          }
          let html_header = '<img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/rY66GsLbHnTm9Tvrx-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Header.png" /><br />' + '<br />';
          let html_body = Body;
          let html_footer = '<br />' + '<br /><img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/yKNhmTQoRepiYtRSj-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Footer.png" />';
          delete mailObj.to
          delete mailObj.To2

          let obj = {
            ...mailObj,
            companyId: companyObj.objectId || '',
            companyName: companyObj.name || '',
            cc: ccArr,
            bcc: bccArr,
            html: html_header + html_body + html_footer,
            status: "99",
            status_text: "未寄出",
            to: item,
            To_name_cht: (clients && clients.find(item2 => item2.email === item) && clients.find(item2 => item2.email === item).name) || item,
            attachments: srcArr,
            type: 'timeEmail',
            scheduleAt,
          };
          let now = new Date()
          if (obj.scheduleAt <= now) {
            Parse.sendmail(obj)
            obj = {
              ...obj,
              status: '2',
              status_text: "已寄出",
            }
          }
          Parse.saveData('email', obj);
        })

        sendPortfolioMail.forEach(item => {
          let srcArr = []
          if (dataArr.length > 0) {
            dataArr.forEach(item2 => {
              let fileObj = {
                id: item2.objectId,
                filename: item2.name,
                path: item2.theFile.url,
              }
              srcArr.push(fileObj)
              let obj = {
                ...item2,
                flag: 2
              }
              Parse.saveData('clientFile', obj)
            })
          }
          let html_header = '<img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/rY66GsLbHnTm9Tvrx-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Header.png" />';
          let html_body = Body;
          let html_footer = '<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />' + '<br /><img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/yKNhmTQoRepiYtRSj-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Footer.png" />';
          delete mailObj.to
          delete mailObj.To2

          let obj = {
            ...mailObj,
            cc: ccArr,
            bcc: bccArr,
            companyId: companyObj.objectId || '',
            companyName: companyObj.name || '',
            html: html_header + html_body + html_footer,
            status: "99",
            status_text: "未寄出",
            to: item,
            To_name_cht: (selPortfoliosArr && selPortfoliosArr.find(item2 => item2.email === item) && selPortfoliosArr.find(item2 => item2.email === item).name) || item,
            attachments: srcArr,
            type: 'timeEmail',
            scheduleAt,
          };
          let now = new Date()
          if (obj.scheduleAt <= now) {
            Parse.sendmail(obj)
            obj = {
              ...obj,
              status: '2',
              status_text: "已寄出",
            }
          }
          Parse.saveData('email', obj);
        })

        window.alert("寄出成功")
        this.setState({ mailObj: clearObj, value: [], emailListId: '1', dataArr: [], scheduleTime: false, emailsId: 'noselect' })
      } else {
        window.alert("請輸入至少一位收件者")
      }
    }
    if (noEmail.length > 0) {
      window.alert(`${noEmail}  無Email`)
    }
    if (noPortfolio.length > 0) {
      console.log(noPortfolio)
      window.alert(`${noPortfolio}  無此單號`)
    }
  }

  handleChange = (e, { name, value }) => {
    const { emails } = this.state
    const { product2, product3, product4 } = this.state

    let obj = {}
    if (name === "emailsId") {
      if (emails.find(item => (item.objectId === value))) {
        const showObj = emails.find(item => (item.objectId === value))
        let bcc = ""
        if (typeof (showObj.bcc) === "string") {
          bcc = showObj.bcc
        } else if (typeof (showObj.bcc) === "object") {
          showObj.bcc.forEach(item => (bcc += `${item},`))
        }
        obj = {
          from: "notice@hanburyifa.com",
          To2: showObj.receiver || '',
          cc: showObj.cc || '',
          bcc,
          subject: showObj.view_title || '',
          Body: showObj.context || '',
        }
      } else {
        obj = {
          from: "notice@hanburyifa.com",
          To2: '',
          cc: '',
          bcc: '',
          subject: '',
          Body: '',
        }
      }
      this.setState({ mailObj: { ...this.state.mailObj, ...obj }, [name]: value, value: [], emailListId: '1' });
    }
    if (name === "scheduleAt") {
      let time = new Date(value)
      this.setState({ mailObj: { ...this.state.mailObj, [name]: time }, [name]: value });
    }

    if (name === 'clearPortfolio') {
      this.setState({ mailObj: { ...this.state.mailObj, Portfolio: '' } })
    }
    if (name === 'productType1') {
      const productType2Opt = [{ key: 'productType20', text: '-- 全選 --', value: '-1' }].concat(product2.filter(item => item.product1_id === value)
        .sort((a, b) => a.order - b.order).map(item => ({ key: item.objectId, text: item.name_cht + ' (' + item.name_eng + ')', value: item.objectId })));
      obj = { value1: value, value2: '-1', value3: '-1', value4: '-1', disabled2: false, disabled3: true, disabled4: true, productType2Opt };
    } else if (name === 'productType2') {
      const productType3Opt = [{ key: 'productType30', text: '-- 全選 --', value: '-1' }].concat(product3.filter(item => item.product2_id === value)
        .sort((a, b) => a.order - b.order).map(item => ({ key: item.objectId, text: item.name_cht + ' (' + item.name_eng + ')', value: item.objectId })));
      obj = { value2: value, value3: '-1', value4: '-1', disabled3: false, disabled4: true, productType3Opt };
    } else if (name === 'productType3') {
      const productType4Opt = [{ key: 'productType40', text: '-- 全選 --', value: '-1' }].concat(product4.filter(item => item.product3_id === value)
        .sort((a, b) => a.order - b.order).map(item => ({ key: item.objectId, text: item.name_cht + ' (' + item.name_eng + ')', value: item.objectId })));
      obj = { value3: value, value4: '-1', disabled4: false, productType4Opt };
    } else if (name === 'productType4') {
      obj = { value4: value };
    } else if (name === 'portfolioType') {
      obj = { valueType: value };
    } else if (name === 'providerType') {
      obj = { valueProvider: value };
    }

    this.setState({ ...obj }, () => this.handleTableData());
  }

  handleTableData = () => {
    const { portfolios } = this.props
    const { value1, value2, value3, value4, valueType, valueProvider } = this.state;

    let portfoliosArr = [...portfolios];
    if (value1 !== '-1') {
      portfoliosArr = portfoliosArr.filter(item => item.product1_id === value1);
    }
    if (value2 !== '-1') {
      portfoliosArr = portfoliosArr.filter(item => item.product2_id === value2);
    }
    if (value3 !== '-1') {
      portfoliosArr = portfoliosArr.filter(item => item.product3_id === value3);
    }
    if (value4 !== '-1') {
      portfoliosArr = portfoliosArr.filter(item => item.product4_id === value4);
    }
    if (valueType !== '-1') {
      portfoliosArr = portfoliosArr.filter(item => item.template_id === valueType);
    }
    if (valueProvider !== '-1') {
      portfoliosArr = portfoliosArr.filter(item => item.provider_id === valueProvider);
    }
    // console.log(portfoliosArr)
    this.setState({ portfoliosArr })
  }

  handleShow = () => {
    this.setState({ show: true })
  }

  handleDeleteConfirm = () => {
    this.setState({ deleteConfirm: true })
  }

  handleClose = () => {
    this.setState({ show: false, deleteConfirm: false });
  }
  handlePortfolios = (dataArr, onClickData) => {
    // console.log(dataArr, onClickData)
    this.setState({ selPortfoliosArr: dataArr, onClickPortfoliosObj: onClickData });
  }

  handleClonePortfolios = (e, data) => {
    const { selPortfoliosArr } = this.state;
    let str = ''
    let noEmail = []
    let showArr = []
    selPortfoliosArr.forEach(item => {
      if (item.email === '' || item.email === undefined) {
        noEmail.push(`\n${item.uid} ${item.name_cht}`)
      } else if (showArr.indexOf(item.email) === -1) {
        showArr.push(item.email)
        // str += `${item.email},`
      }
    })
    if (noEmail.length > 0) {
      window.alert(`${noEmail}  無Email`)
    }
    showArr.forEach(item => str += `${item},`)
    this.setState({ show: false, mailObj: { ...this.state.mailObj, Portfolio: str } })
  }

  handleCheck = (e, { name, value, checked }) => {
    if (name === "scheduleAt") {
      if (checked === true) {
        this.setState({ scheduleTime: true })
      } else {
        delete this.state.mailObj.scheduleAt
        this.setState({ scheduleTime: false })
      }
    } else if (name === 'autoDetect') {
      this.setState({ [name]: checked })
    }
  }


  handleChangeSel = (e, { value }) => this.setState({ value, mailObj: { ...this.state.mailObj, to: value } })
  handleSearchChange = (e, { searchQuery }) => this.setState({ searchQuery })

  clear = (name) => {
    this.setState({ mailObj: { ...this.state.mailObj, [name]: '' } })
  }

  handleChangeMail = (e, { name, value }) => {
    this.setState({ mailObj: { ...this.state.mailObj, [name]: value } });
  }

  handleBlur = (name, value, data) => {
    this.setState({ mailObj: { ...this.state.mailObj, [name]: value } })
  }

  handleChangeMail2 = (e, name) => {
    this.setState({ mailObj: { ...this.state.mailObj, [name]: e.target.value } });
  }

  handleChangeList = (e, { name, value }) => {
    const { clients } = this.props
    const To2 = clients.filter(item => item['periodmail' + value] === '1').map(item => item.email).join(',');
    this.setState({ mailObj: { ...this.state.mailObj, To2 }, emailListId: value, emailsId: 'noselect' })
  }

  templateAdd = async () => {
    const { emailsId, emailsSel, emails, mailObj } = this.state;
    const { from, To2, cc, bcc, subject, Body } = mailObj;
    const { companyObj } = this.props
    let Seldata = emailsSel;
    if (mailObj.subject === '') {
      window.alert('要輸入主旨才可以新增')
    } else {
      const db = Parse.Object.extend("mailtitle");
      const newDoc = new db();
      const docRef = await newDoc.save();
      const obj = {
        _id: docRef.id,
        id: docRef.id,
        objectId: docRef.id,
        companyId: companyObj.objectId,
        companyName: companyObj.name,
        context: Body,
        data: subject,
        view_title: subject,
      }
      Seldata.push({ key: obj._id, value: obj._id, text: subject })
      // await docRef.set(obj);
      await Parse.saveData('mailtitle', obj)
      window.alert('新增成功')
      this.handleFetch(this.props, 0)
      this.setState({ emailsSel: Seldata, newTitle: obj, emailsId: obj.id });
    }
  }

  templateSave = async () => {
    const { emailsId, emailsSel, emails, mailObj } = this.state;
    const { from, To2, cc, bcc, subject, Body } = mailObj;
    const Seldata = emailsSel;
    const tempdata = emails.find(item => item.objectId === emailsId)
    const index = Seldata.indexOf(tempdata);

    if (window.confirm('確定儲存?')) {
      const obj = {
        ...tempdata,
        context: Body,
        data: subject,
        view_title: subject,
      }
      Seldata[index] = { key: obj.objectId, value: obj.objectId, text: subject };
      await Parse.saveData('mailtitle', obj)
      window.alert('儲存成功')
      this.handleFetch(this.props, 0)
      this.setState({ emailsSel: Seldata, newTitle: obj });
    }
  }

  templateDelete = async () => {
    const { emailsId, emailsSel, emails, mailObj } = this.state;
    this.setState({ deleteLoading: true })
    await new Promise(async (resolve) => {
      const Seldata = emailsSel;
      const tempdata = emails.find(item => item.objectId === emailsId)
      // const maildata = tempdata[0];
      const index = Seldata.indexOf(tempdata);
      Seldata.splice(index, 1);
      await Parse.deleteData('mailtitle', tempdata)
      this.setState({ emailsSel: Seldata, oldTitle: tempdata, mailObj: clearObj, emailsId: 'noselect' }, () => resolve());
    }).then(() => this.handleFetch())
    this.setState({ deleteLoading: false, deleteConfirm: false })
    window.alert('刪除成功')
  }

  handleFilesChange = async (e) => {
    const fileArr = e.target.files;
    this.setState({ loading: true })
    if (!fileArr || !fileArr.length) {
      this.setState({ loading: false })
      return
    }
    const { form, user } = this.state
    const { companyObj } = this.props
    const promises = [];
    for (let i = 0; i < fileArr.length; i++) {
      const file = fileArr[i];
      promises.push(Parse.putFile(file));
    }
    const theFiles = await Promise.all(promises).catch(error => console.log("error", error));

    const promises2 = [];
    theFiles.forEach(async (theFile) => {
      const db = Parse.Object.extend("clientFile");
      const newDoc = new db();
      const docRef = await newDoc.save();

      const element = {
        objectId: docRef.id,
        id: docRef.id,
        _id: docRef.id,
        companyId: companyObj.objectId,
        companyName: companyObj.name,
        clientId: user.id,
        type: 'emailFile',
        flag: 1,
        theFile
      };
      promises2.push(Parse.saveData('clientFile', element));
    })
    window.alert('上傳成功')
    await Promise.all(promises2).catch(error => console.log("error", error)).then(() => this.handleFetch(this.props, 1));
  }

  render() {
    const { companyObj, clientOpt, auth = {} } = this.props
    const { dataArr, dataArr2, dataArr3, mailObj, emailsSel, emailsId, fileArr, loading,
      fileArrCheck, emails, activeItem, value, deleteConfirm, scheduleTime, emailListId, show, user } = this.state;
    const { from, To2, cc, bcc, subject, Body, Portfolio } = mailObj;
    const { productType1Opt, productType2Opt, productType3Opt, productType4Opt, providerOpt,
      valueProvider, valueAgent, valueType,
      disabled2, disabled3, disabled4, value1, value2, value3, value4, portfoliosArr, deleteLoading, autoDetect } = this.state;
    // console.log(emails)
    // console.log(emailsSel);
    return (<Form inverted={companyObj.inverted}>
      <Header inverted={companyObj.inverted} as='h3' style={{ margin: '1rem auto' }}>2-1-1 新增郵件</Header>
      <br />
      <Grid>
        <Grid.Row>
          <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
            <label>主旨內文樣板</label>
          </Grid.Column>
          <Grid.Column width={7} style={{ ...style.flexCenter }} computer={7} tablet={7} mobile={16}>
            <Form.Select
              style={{ width: '100%', marginRight: '20px' }} options={emailsSel} name='emailsId'
              onChange={this.handleChange} value={emailsId} />
          </Grid.Column>
          <Grid.Column width={6} computer={6} tablet={6} mobile={16}>
            <Button primary content='新增' onClick={this.templateAdd} disabled={!auth.edit} />
            <Button positive content='儲存' disabled={emailsId === 'noselect' || !auth.edit} onClick={this.templateSave} />
            <Button color='red' content='刪除' disabled={emailsId === 'noselect' || !auth.edit} onClick={this.handleDeleteConfirm} />
            <Modal
              isOpen={deleteConfirm}
              toggle={this.handleClose}
              size="lg"
              className="mh-100 h-90 mw-100 w-90"
            >
              <ModalBody>
                確定要刪除這筆資料嗎？
        </ModalBody>
              <ModalFooter style={{ padding: '16px' }}>
                <Button onClick={this.templateDelete} inverted color='red' icon='delete' content='刪除' loading={deleteLoading} />
                <Button onClick={this.handleClose} content='取消' />
              </ModalFooter>
            </Modal>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
            <label>From 寄件人</label>
          </Grid.Column>
          <Grid.Column width={10} computer={10} tablet={10} mobile={16}>
            <Form.Input value={from} readOnly onChange={this.handleChangeMail} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
            <label>To 收件人1 (客戶)</label>
          </Grid.Column>
          <Grid.Column width={10} computer={10} tablet={10} mobile={16}>
            <Dropdown
              fluid
              selection
              multiple={true}
              search={true}
              options={clientOpt}
              value={value}
              onChange={this.handleChangeSel}
              onSearchChange={this.handleSearchChange}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
            <label>To 收件人2 (逗號分隔)</label>
          </Grid.Column>
          <Grid.Column width={8} computer={8} tablet={8} mobile={16}>
            <Form.Field
              useCacheForDOMMeasurements
              control={TextareaAutosize}
              value={To2}
              name="To2"
              action={{ icon: 'cancel', onClick: () => this.clear('To2') }}
              onChange={e => this.handleChangeMail2(e, 'To2')}
              placeholder='請輸入Email，並以逗號分隔' />
          </Grid.Column>
          <Grid.Column width={3} computer={3} tablet={5} mobile={16}>
            <Select fluid placeholder='電子報表種類' options={emailList} onChange={this.handleChangeList} value={emailListId} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
            <label>投資單號(信箱)</label>
          </Grid.Column>
          <Grid.Column width={8} computer={8} tablet={8} mobile={16}>
            <Form.Field
              useCacheForDOMMeasurements
              control={TextareaAutosize}
              // fluid
              value={Portfolio}
              name="Portfolio"
              action={{ icon: 'cancel', onClick: () => this.clear('Portfolio') }}
              onChange={e => this.handleChangeMail2(e, 'Portfolio')}
              placeholder='請輸入投資單號，並以逗號分隔' />
          </Grid.Column>
          <Grid.Column width={3} computer={3} tablet={5} mobile={16}>
            <Button content='選擇投資單號' onClick={this.handleShow} disabled={!auth.edit} />
            <br />
            <br />
            <Button content='清空投資單號' name='clearPortfolio' onClick={this.handleChange} disabled={!auth.edit} />
          </Grid.Column>
        </Grid.Row>
        {/* <Grid.Row>
          <Grid.Column width={3}>
            <label>To 收件人1+2</label>
          </Grid.Column>
          <Grid.Column width={10}>
            {To.join(',')}
          </Grid.Column>
        </Grid.Row> */}
        <Grid.Row>
          <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
            <label>Cc 副本 (逗號分隔)</label>
          </Grid.Column>
          <Grid.Column width={10}>
            <Form.Input fluid value={cc} name="cc" action={{ icon: 'cancel', onClick: () => this.clear('cc') }} onChange={this.handleChangeMail} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
            <label>Bcc 密件副本 (逗號分隔)</label>
          </Grid.Column>
          <Grid.Column width={10}>
            <Form.Input fluid value={bcc} name="bcc" action={{ icon: 'cancel', onClick: () => this.clear('bcc') }} onChange={this.handleChangeMail} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
            <label>主旨</label>
          </Grid.Column>
          <Grid.Column width={10}>
            <Form.Input fluid value={subject} name="subject" action={{ icon: 'cancel', onClick: () => this.clear('subject') }} onChange={this.handleChangeMail} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
            <label>內文</label>
          </Grid.Column>
          <Grid.Column width={13} computer={13} tablet={13} mobile={16} >
            <CKEditor
              editor={ClassicEditor}
              data={Body}
              onInit={editor => {
                // You can store the "editor" and use when it is needed.
                console.log('Editor is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                this.handleBlur("Body", data, editor)
                // console.log({ event, editor, data });
              }}
            // onBlur={(event, editor) => {
            //   console.log('Blur.', editor);
            // }}
            // onFocus={(event, editor) => {
            //   console.log('Focus.', editor);
            // }}
            />
            {/* <Form style={{ width: '100%' }}><TextArea /></Form> */}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
            <label>預定寄送時間</label>
          </Grid.Column>
          <Grid.Column width={1}>
            <Checkbox onClick={this.handleCheck} name="scheduleAt" />
          </Grid.Column>
          <Grid.Column width={5}>
            {scheduleTime ?
              <Form.Input
                name='scheduleAt'
                type="datetime-local"
                // label='寄送日期'
                // value={createdAt1D}
                checked={scheduleTime}
                onChange={this.handleChange}
              /> : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
            <label>附加檔案</label>
          </Grid.Column>
          <Grid.Column width={13} style={{ ...style.flexCenter }}>
            {/* <Checkbox label='使用自動偵測的mail' /> */}
            <div>
              {auth.edit ? <ModalUploadFiles {...this.props} isImage='0' handleData={this.handleFetch} dbName='clientFile' fieldName='theFile' condition={{ flag: 1, clientId: user.id, type: 'emailFile' }} /> : null}
              {/* <Button content='選擇用戶文件' /> */}
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3} computer={3} tablet={3} mobile={0}></Grid.Column>
          <Grid.Column width={13} computer={13} tablet={13} mobile={16} >
            <MaterialTable
              isLoading={loading}
              localization={localization()}
              columns={columns()}
              options={{
                addRowPosition: "first",
                exportButton: true,
                exportAllData: true,
                exportPdf: (columns, data) => exportPdf(columns, data, '附加檔案'),
                pageSize: dataArr.length || 5,
                search: true
              }}
              data={dataArr}
              title="附加檔案"
              editable={{
                //   onRowAdd: newData =>
                //     new Promise((resolve, reject) => {
                //       const data = [...dataArr];
                //       data.push(newData);
                //       // handleState('people0', data);
                //       this.setState({ dataArr: data, newData }, () => resolve());
                //     }).then(() => this.handleAdd(0, 0)),
                //   onRowUpdate: (newData, oldData) =>
                //     new Promise((resolve, reject) => {
                //       const data = [...dataArr];
                //       const index = data.indexOf(oldData);
                //       data[index] = newData;
                //       // handleState('people0', data);
                //       this.setState({ dataArr: data, newData, oldData }, () => resolve());
                //     }).then(() => this.handleUpdate(0)),
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    let data = [...dataArr];
                    const index = data.indexOf(oldData);
                    data.splice(index, 1);
                    // handleState('people0', data);
                    this.setState({ dataArr: data, oldData }, () => resolve());
                  }).then(() => this.handleRowDelete(0)),
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign='right'>
            <div>
              <Checkbox onClick={this.handleCheck} name="autoDetect" value={autoDetect} label='自動偵測檔案所屬email' />
            </div>
            <Button primary content='送出' icon='mail outline' onClick={this.handleSend} disabled={!auth.edit} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <br />
      <br />
      <Modal
        isOpen={show}
        toggle={this.handleClose}
        size="lg"
        className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader style={style.flexCenter}>
          <h2 style={{ margin: 0 }}>選擇投資單號</h2>
          <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
        </ModalHeader>
        <ModalBody>
          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <Form>
                  <Form.Select label="產品類別1" options={productType1Opt} name='productType1' onChange={this.handleChange} value={value1} />
                  <Form.Select label="產品類別2" options={productType2Opt} name='productType2' onChange={this.handleChange} value={value2} disabled={disabled2} />
                  <Form.Select label="產品類別3" options={productType3Opt} name='productType3' onChange={this.handleChange} value={value3} disabled={disabled3} />
                  <Form.Select label="產品類別4" options={productType4Opt} name='productType4' onChange={this.handleChange} value={value4} disabled={disabled4} />
                  {/* <Header content='供應商' /> */}
                  <Form.Select label="供應商" options={providerOpt} name='providerType' onChange={this.handleChange} value={valueProvider} />
                </Form>
              </Grid.Column>
              <Grid.Column width={10}>
                <MaterialTable
                  isLoading={loading}
                  localization={localization()}
                  // style={{ marginBottom: '2rem' }}
                  columns={porColumns()}
                  options={{
                    addRowPosition: "first",
                    exportButton: true,
                    exportAllData: true,
                    exportPdf: (columns, data) => exportPdf(columns, data, '投資單號'),
                    pageSize: 20,
                    search: true,
                    selection: true
                  }}
                  onSelectionChange={this.handlePortfolios}
                  data={portfoliosArr}
                  title="投資單號"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid >
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button onClick={this.handleClonePortfolios} inverted color='green' icon='copy outline' content='帶入' />
          <Button onClick={this.handleClose} inverted color='red' icon='delete' content='取消' />
        </ModalFooter>
      </Modal>
    </Form >)
  }
}
//  <Modal open={show} onClose={this.handleClose} dialogclassname="modal-90w">
//         <Modal.Header style={style.flexCenter}>
//           <h2 style={{ margin: 0 }}>選擇投資單號</h2>
//           <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
//         </Modal.Header>
//         <Modal.Content>
//           <Grid>
//             <Grid.Row>
//               <Grid.Column width={6}>
//                 <Form>
//                   <Form.Select label="產品類別1" options={productType1Opt} name='productType1' onChange={this.handleChange} value={value1} />
//                   <Form.Select label="產品類別2" options={productType2Opt} name='productType2' onChange={this.handleChange} value={value2} disabled={disabled2} />
//                   <Form.Select label="產品類別3" options={productType3Opt} name='productType3' onChange={this.handleChange} value={value3} disabled={disabled3} />
//                   <Form.Select label="產品類別4" options={productType4Opt} name='productType4' onChange={this.handleChange} value={value4} disabled={disabled4} />
//                   {/* <Header content='供應商' /> */}
//                   <Form.Select label="供應商" options={providerOpt} name='providerType' onChange={this.handleChange} value={valueProvider} />
//                   {/* <Header content='AGENT' /> */}
//                   <Form.Select label="AGENT" options={productType4Opt} name='productType4' onChange={this.handleChange} value={valueAgent} />
//                 </Form>
//               </Grid.Column>
//               <Grid.Column width={10}>
//                 <MaterialTable
//                   isLoading={loading}
//                   // style={{ marginBottom: '2rem' }}
//                   columns={porColumns()}
//                   options={{
//                     addRowPosition: "first",
// exportButton: true,
//                     pageSize: 20,
//                     search: true,
//                     selection: true
//                   }}
//                   onSelectionChange={this.handlePortfolios}
//                   data={portfoliosArr}
//                   title="投資單號"
//                 />
//               </Grid.Column>
//             </Grid.Row>
//           </Grid >
//         </Modal.Content >
//         <Modal.Content style={{ borderTop: '1px solid rgba(34,36,38,.15)', display: 'flex', justifyContent: 'flex-end' }}>
//           <Button onClick={this.handleClonePortfolios} inverted color='green' icon='copy outline' content='帶入' />
//           <Button onClick={this.handleClose} inverted color='red' icon='delete' content='取消' />
//         </Modal.Content>
//       </Modal >