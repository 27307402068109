import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select, Form } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'

import { Row, Col, Input } from "reactstrap";
import Moment from 'moment';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { mgyearOpt, exportPdf } from 'views/web/CustomMaterialTable';
import MembersTable from './MembersTable';
import BoardgameTable from './BoardgameTable';

const columns = () => ([
  { width: 80, title: '貨物狀態', field: 'cargoStatus', lookup: opt },
  { width: 80, title: '總價', field: 'totalPrice' },
  { width: 80, title: '進貨商', field: 'games1Id2' },
  { width: 80, title: '進貨日', field: 'date' }
]);
const opt = { '1': '報價中', '2': '訂單成立 撿貨中', '3': '撿貨完成 出貨中', '4': '部分產品 已出貨', '5': '所有產品 已出貨', '6': '收到物品 已完成(已自取)', '7': '已取消' }

const d = new Date()
class CrewStorage extends Component {
  state = {
    activeItem: 'bio',
    data: [],
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
    rowData: {},
    memberObj: {},
    boardgameObj: {},
    publisherId: '-1',
    publishersOpt: [],
    boardgameArr: [],
    boardgameArr2: [],
    gameDeposit: 0,
  }
  componentDidMount = () => {
    this.handleFetch()
  }
  handleFetch = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data1 = await Parse.queryData('storage', { companyId });
    const data2 = await Parse.queryData('publishers', { companyId });
    const opt = []
    let sel = {}
    data2.forEach(item => {
      opt.push({ text: item.name, value: item.objectId, key: item.objectId })
      sel = { ...sel, [item.objectId]: item.name }
    })

    this.setState({ dataArr: data1, publishersOpt: opt, sel })
  }
  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }
  handleChange1 = (e, item) => {
    const { name, value } = e.target
    const { boardgameArr2 } = this.state
    boardgameArr2.push({ ...item, [name]: value })
    this.setState({ boardgameArr2 })
  }

  handleClickName = (rowData, type) => {
    const { boardgameArr, gameDeposit } = this.state
    if (type === 'member') {
      this.setState({ memberObj: rowData })
    } else {
      boardgameArr.push(rowData)
      const number = gameDeposit + Number(rowData.gameDeposit)
      this.setState({ boardgameArr, gameDeposit: number })
    }
  }
  saveStorage = async () => {
    const { companyObj } = this.props
    const { cargoStatus, ps, boardgameArr2, publisherId, sel } = this.state
    let total = 0
    boardgameArr2.forEach(item => {
      total += total + Number(item.number) * Number(item.price)
    })
    let obj = {
      games1Id2: sel[publisherId],
      companyId: companyObj.objectId,
      date: Moment(d).format('YYYY-MM-DD') || '',
      totalPrice: String(total),
      ps: ps
    }
    console.log(obj)
    await Parse.saveData('storage', obj);
    this.handleFetch()
  }
  handleUpdate = async () => {
    const { newData } = this.state;
    await Parse.saveData('storage', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    await Parse.deleteData('storage', oldData);
    this.setState({ oldData: {} })
  }
  render() {
    const { companyObj } = this.props
    const { dataArr, gameDeposit, boardgameArr, publisherId, number, publishersOpt, ps } = this.state
    // console.log(rowData);
    return (<div className="content">
      <Header as='h2' inverted={companyObj.inverted}>進貨</Header>
      <Row style={{ backgroundColor: '#fff' }}>
        <Col md='6'>
          <h4>1.選擇進貨商</h4>
          <Form.Select label='進貨商' width={8} name='publisherId' value={publisherId} options={[{ key: 'aa', value: '-1', text: '請選擇' }].concat(publishersOpt)} onChange={this.handleChange} />
          <br />
          <h4>2.輸入桌遊條碼</h4>
          {/* <Form>
              <Form.Group widths='equal' inline>
                <Form.Input fluid />
                <Form.Button content='Submit' />
              </Form.Group>
            </Form> */}
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>遊戲名</Table.HeaderCell>
                <Table.HeaderCell>售價</Table.HeaderCell>
                <Table.HeaderCell>數量</Table.HeaderCell>
                <Table.HeaderCell>備貨流程</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {boardgameArr.map(item =>
                <Table.Row>
                  <Table.Cell>{item.name}</Table.Cell>
                  <Table.Cell>{item.price}</Table.Cell>
                  <Table.Cell>
                    <Form.Input name='number' value={number} onChange={e => this.handleChange1(e, item)} />
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table >
          <br />
          <h4>3.收取押金：NTD. {gameDeposit}</h4>
          <Form>
            <Form.Group >
              <Form.Input label='備註' width={8} name='ps' value={ps} onChange={this.handleChange} />
            </Form.Group>
            <br />
            <Form.Button color='blue' content='進貨' onClick={this.saveStorage} />
          </Form>
        </Col>
        <Col md='6'>
          {/* <MembersTable {...this.props} handleClickName={this.handleClickName} /> */}
          <BoardgameTable {...this.props} handleClickName={this.handleClickName} />
        </Col>
      </Row>
      <br />
      <br />
      <MaterialTable
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '進貨'),
          pageSize: 10,
          search: false
        }}
        data={dataArr}
        title="進貨"
        // actions={[
        //   {
        //     icon: 'view_headline',
        //     tooltip: '查看',
        //     onClick: (event, rowData) => this.handleData(rowData)
        //   }
        // ]}
        editable={{
          // onRowAdd: newData =>
          //   new Promise((resolve, reject) => {
          //     const data = [...dataArr];
          //     data.push(newData);
          //     // handleState('people0', data);
          //     this.setState({ dataArr: data, newData }, () => resolve());
          //   }).then(() => this.handleAdd(0, 0)),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...dataArr];
              const index = data.indexOf(oldData);
              data[index] = newData;
              // handleState('people0', data);
              this.setState({ dataArr: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate(0)),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...dataArr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              // handleState('people0', data);
              this.setState({ dataArr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete(0)),
        }}
      />

    </div >);
  }
}

export default CrewStorage;