import React, { Component } from 'react'
import { Button, Checkbox, Form, Input, Radio, Select, TextArea, Table } from 'semantic-ui-react'
import Moment from 'moment';
import Parse from 'widget/parse'
import { getNationalityArr } from '../../widget/define/students'

export const weekdayArrCN = ['日', '月', '火', '水', '木', '金', '土'];
export const periodArrCN = ['ㄧ', '二', '三', '四', '五', '六', '七', '八'];

export const getFormByKey = (formSet, key) => {
  if (!formSet) {
    console.log("option setting empty!");
    return;
  }
  const obj = formSet.find(item => item.key === key);
  console.log(obj, formSet.filter(item => item.parentFolderId === obj._id))
  return obj ? formSet.filter(item => item.parentFolderId === obj._id).sort((a, b) => a.orderId - b.orderId) : [];
}

export const getOptionsByKey = (optSet, key) => {
  if (!optSet) {
    console.log("option setting empty!");
    return;
  }
  const obj = optSet.find(item => item.key === key);
  return obj ? optSet.filter(item => item.parentFolderId === obj._id).sort((a, b) => a.orderId - b.orderId) : [];
}

export const getOptionsSelByKey = (optSet, key) => {
  if (!optSet) {
    console.log("option setting empty!");
    return;
  }
  const obj = optSet.find(item => item.key === key);
  return obj ? optSet.filter(item => item.parentFolderId === obj._id).sort((a, b) => a.orderId - b.orderId).map(item => ({ key: item._id, text: item.label, value: item.value })) : [];
}

export const getLabelName = (loading, formSet, key) => (!loading && formSet.find(item => item.key === key) && formSet.find(item => item.key === key).label) || key;

export const getLabelName2 = (formSet, key) => (formSet.find(item => item.key === key) && formSet.find(item => item.key === key).label) || key;

export const getOptLabelName = (optSet, key) => (optSet.find(item => item.key === key) && optSet.find(item => item.key === key).label) || key;

export const getOptLabelName2 = (optSet, id) => (optSet.find(item => item._id === id) && optSet.find(item => item._id === id).label) || id;

export const getOptionTextByValue = (optSet, key, value) => {
  const obj = optSet.find(item => item.key === key);
  if (!obj || !obj._id) return false;
  const arr = optSet.filter(item => item.parentFolderId === obj._id);
  return obj && arr.find(item => item.value === value) ? arr.find(item => item.value === value).label : '';
}

export const getOptionNote1ByValue = (optSet, key, value) => {
  const obj = optSet.find(item => item.key === key);
  if (!obj || !obj._id) return false;
  const arr = optSet.filter(item => item.parentFolderId === obj._id)
  return obj && arr.find(item => item.value === value) ? arr.find(item => item.value === value).note1 : '(なし)';
}

export const getOptionNote2ByValue = (optSet, key, value) => {
  const obj = optSet.find(item => item.key === key);
  if (!obj || !obj._id) return false;
  const arr = optSet.filter(item => item.parentFolderId === obj._id)
  return obj && arr.find(item => item.value === value) ? arr.find(item => item.value === value).note2 : '(なし)';
}

export const showCurrentClass = (semesterYear, semester) => {
  const lastYear = Number(semesterYear) - 1;
  // return lastYear + '春 ' + lastYear + '秋 ' + semesterYear + '春 ' + (semester === "A" ? semesterYear + '秋' : '');
  return lastYear + '年4月生、' + lastYear + '年10月生、' + semesterYear + '年4月生' + (semester === "A" ? '、' + semesterYear + '年10月生' : '');
}

export const getCurrentClass = (studentId, eventDate) => {
  // console.log('getCurrentClass', studentId, eventDate);
  const currentDate = eventDate ? eventDate : Moment(new Date()).format("YYYY-MM-DD");
  const g1Obj = {}; //Grade.findOne({ testType: "2", placementDate: { $lte: currentDate } }, { sort: { placementDate: -1 } });
  // const g2Obj = Grade2.findOne({ studentId, gradeId: g1Obj._id });
  // const g2Obj = Grade2.findOne({ studentId, testDate: { $lte: currentDate } }, { sort: { testDate: -1 } });
  const g2Obj = {};
  //  Grade2.findOne({
  //   // gradeId: g1Obj._id,
  //   studentId,
  //   isDel: "0",
  //   // placementDate: { $lte: currentDate },
  //   placementDate: g1Obj.placementDate,
  //   newClass: { $exists: true },
  // }, { sort: { placementDate: -1 } });

  // return g1Obj;
  // console.log(g2Obj);
  // if (g2Obj && g2Obj.newClass)
  //   console.log(g2Obj.newClass || "");
  return (g2Obj && g2Obj.newClass) || "";
}

export const getCurrentGrade = (eventDate) => {
  // console.log('getCurrentGrade', eventDate);
  const currentDate = eventDate ? eventDate : Moment(new Date()).format("YYYY-MM-DD");
  return {}; // Grade.findOne({ testType: "2", placementDate: { $lte: currentDate } }, { sort: { placementDate: -1 } });
}

export const getLatestClass = (studentId) => {
  const obj = {}; //Grade2.findOne({ newClass: { $ne: null }, studentId }, { sort: { placementDate: -1 } });
  return obj && obj.newClass ? obj.newClass : '';
}

export const showFieldText = (formSet, optSet, formkey, obj) => {
  if (!obj || !formkey) return '';
  // console.log('showFieldText');
  // console.log(formkey, obj);
  let value = obj[formkey];
  if (!value) {
    return '';
  }
  if (!formSet.length || !optSet.length) {
    return '錯誤:找不到key值' + formkey;
  }

  let form = formSet.find(item => item.key === formkey);

  if (form.type === "select") {
    let optParentObj = optSet.find(item => item.key === form.value);
    let opt = optSet.find(item => item.parentFolderId === optParentObj._id && item.value === value) || {};
    return opt.label;
  }
  else if (form.type === "date") {
    if (!value) return '';
    let d = new Date(value);
    return d.getFullYear() + "年" + (d.getMonth() + 1) + "月" + d.getDate() + "日";
  }

  return value;
}

export const showFieldValue = function (formkey, obj) {
  // console.log('showFieldText');
  // console.log(formkey, obj);
  let value = obj[formkey];
  if (!value) {
    return '';
  }
  let checkForm = {}; // FormSettings.find({ key: formkey }).count();
  if (!checkForm) {
    return '錯誤:找不到key值' + formkey;
  }

  let form = {}; // FormSettings.findOne({ key: formkey });

  if (form.type === "select") {
    let optParentObj = {}; // OptionSettings.findOne({ key: form.value }); ///国籍
    let opt = {}; // OptionSettings.findOne({ parentFolderId: optParentObj._id, value });
    return opt.value;
  }
  else if (form.type === "date") {
    let d = new Date(value);
    return d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate();
  }

  return value;
}

// 回傳学生的国籍組成字串，strType可以為(0: 不包含性別, 1: 包含性別)
export const getStudentCountryText = (optSet, students, strType = 0) => {
  const nationalityArr = getNationalityArr(students);
  const studentsUnknownNation = students.filter(item => nationalityArr.indexOf(item.nationality) === -1);

  return (
    students.length && nationalityArr.length && (strType === 0 || strType === 1) ?
      nationalityArr
        .sort((a, b) => students.filter(item2 => item2.nationality === b).length - students.filter(item2 => item2.nationality === a).length)
        .reduce((text, nationality, index) => {
          let studentsInSameNation = students.filter(item => item.nationality === nationality);

          // 字串不包含性別
          if (strType === 0) {
            return index === nationalityArr.length - 1 ?
              text + `${getOptionTextByValue(optSet, 'nationality', nationality)} ${studentsInSameNation.length}　`
              : text + `${getOptionTextByValue(optSet, 'nationality', nationality)} ${studentsInSameNation.length}　`
            // 字串包含性別
          } else if (strType === 1) {
            return index === nationalityArr.length - 1 ?
              text + `${getOptionTextByValue(optSet, 'nationality', nationality)} ${studentsInSameNation.length}(男 ${studentsInSameNation.filter(item => item.gender === '2').length} 女 ${studentsInSameNation.filter(item => item.gender === '1').length}) 未知 ${studentsUnknownNation.length}　`
              : text + `${getOptionTextByValue(optSet, 'nationality', nationality)} ${studentsInSameNation.length}(男 ${studentsInSameNation.filter(item => item.gender === '2').length} 女 ${studentsInSameNation.filter(item => item.gender === '1').length})　`
          }
          return '';
        }, '') : '未知'
  );
}

/**
 * 回傳目前上課中的是哪些入学学期.
 * ex. 如果現在年份学期是2019春，則會找到入学為2018S 2018A 2019S三個期別
 *
 * @param {string} semesterYear - 学年度.
 * @param {string} semester - 学期(A/S).
 * @returns {Array.<Object>} Array of object.
 */
export const getStudentNumBySemester = (students, semesterYear, semester) =>
  (students.filter(item => item.semesterYear === semesterYear && item.semester === semester).length);

export const getActiveSemesterArr = (semesterYear, semester) => {
  let activeSemesterArr = [];
  let semesterPrev = semester;
  let semesterYearPrev = Number(semesterYear);
  let semesterNum = (semester === 'S') ? 3 : 4;
  let count = 0;
  let arr = []
  while (semesterNum-- && count < 10) {
    // 年份存成字串
    activeSemesterArr.push({ semesterYear: semesterYearPrev + '', semester: semesterPrev });
    // 設為前一個学期，(学期A變S或S變A 且 如果目前是S，則前一学期的年份要減1)
    semesterYearPrev = (semesterPrev === 'S') ? semesterYearPrev - 1 : semesterYearPrev;
    semesterPrev = (semesterPrev === 'S') ? 'A' : 'S';
    count++;
  }
  activeSemesterArr.map(item => arr.unshift(item))
  // console.log('activeSemesterArr', activeSemesterArr);
  return arr;
};

// export const getBGColor = (optSet, classStudentsArr, weekday, period, studentObj, classroomObj, eventDate) => {
//   const eventObj = classStudentsArr.find(item =>
//     // item.classroomObj._id === classroomObj._id &&
//     item.studentId === studentObj._id &&
//     item.weekday === weekday &&
//     item.period === period &&
//     item.eventDate === eventDate);
//   // Moment(item.eventDate).format("YYYY-MM-DD") === Moment(eventDate).format("YYYY-MM-DD"));

//   return (
//     eventObj !== undefined && eventObj.attendStatus ?
//       (getOptionNote2ByValue(optSet, 'attentInClassroom', eventObj.attendStatus) ?
//         getOptionNote2ByValue(optSet, 'attentInClassroom', eventObj.attendStatus) : '#b39ddb')
//       : ''
//   );
// };
export const getBGColor = (optSet, attendStatus) => (attendStatus && attendStatus !== "-1" ?
  (getOptionNote2ByValue(optSet, 'attentInClassroom', attendStatus) ? getOptionNote2ByValue(optSet, 'attentInClassroom', attendStatus) : '#b39ddb') : '');

export const getBGText = (optSet, classStudentsArr, weekday, period, studentObj, classroomObj, eventDate) => {
  const eventObj = classStudentsArr.find(item =>
    // item.classroomObj._id === classroomObj._id &&
    item.studentId === studentObj._id &&
    item.weekday === weekday &&
    item.period === period &&
    // Moment(item.eventDate).format("YYYY-MM-DD") === Moment(eventDate).format("YYYY-MM-DD"));
    item.eventDate === eventDate);

  return (
    eventObj !== undefined && eventObj.attendStatus ?
      (getOptionTextByValue(optSet, 'attentInClassroom', eventObj.attendStatus) ?
        getOptionTextByValue(optSet, 'attentInClassroom', eventObj.attendStatus).substr(0, 1) : '(無)')
      : ''
  );
};
export const getBGText2 = (optSet, classStudentsArr, weekday, period, studentObj, classroomObj, eventDate) => {
  const eventObj = classStudentsArr.find(item =>
    // item.classroomObj._id === classroomObj._id &&
    item.studentId === studentObj._id &&
    item.weekday === weekday &&
    item.period === period &&
    item.eventDate === eventDate);
  // Moment(item.eventDate).format("YYYY-MM-DD") === Moment(eventDate).format("YYYY-MM-DD"));

  return (
    eventObj !== undefined && eventObj.attendStatus ? (
      eventObj.attendStatus === "1" ?
        <div style={{ fontSize: '22px', marginTop: '3px' }}>○</div> :
        eventObj.attendStatus === "2" ?
          <p style={{ paddingTop: '1px' }}>⦰</p> :
          <div style={{ fontSize: '25px', marginTop: '3px' }}>{getOptionTextByValue(optSet, 'attentInClassroom', eventObj.attendStatus).substr(0, 1)}</div>
    ) : ''
  );
};

export const showColorInfo = (optSet) => {
  const attendStatusArr = getOptionsByKey(optSet, 'attentInClassroom').filter(item => item.note2).sort((a, b) => a.orderId - b.orderId);
  const cardCss = {
    display: 'inline-flex',
    color: 'black',
    width: '70px',
    // height: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px'
  };

  return (
    <Button.Group floated='right'>
      <Button
        disabled
        style={{
          ...cardCss,
          backgroundColor: `#fff`
        }}></Button>
      {attendStatusArr
        .map(item =>
          <Button
            key={item._id}
            disabled
            style={{
              ...cardCss,
              backgroundColor: `${item.note2}`
            }}>{item.label}</Button>
        )}
    </Button.Group>
  );
};

export const getNextAttendStatus = (optSet, currentStatus) => {
  const attendStatusArr = getOptionsByKey(optSet, 'attentInClassroom').filter(item => item.note2).sort((a, b) => a.orderId - b.orderId);

  if (currentStatus === '-1') {
    return attendStatusArr[0].value;
  }

  const currentIndex = attendStatusArr.findIndex(item => item.value === currentStatus) || 0;
  return attendStatusArr[(currentIndex + 1) % attendStatusArr.length].value;
};

export const getLastAttendStatus = (optSet, currentStatus) => {
  const attendStatusArr = getOptionsByKey(optSet, 'attentInClassroom').filter(item => item.note2).sort((a, b) => b.orderId - a.orderId);

  if (currentStatus === '-1') {
    return attendStatusArr[0].value;
  }

  const currentIndex = attendStatusArr.findIndex(item => item.value === currentStatus) || 0;
  return attendStatusArr[(currentIndex + 1) % attendStatusArr.length].value;
};

export const handleClickStudent = (firebase, optSet, rollcallObj) => {
  // console.log(rollcallObj)
  const { attendStatus } = rollcallObj;
  const nextAttendStatus = getNextAttendStatus(optSet, attendStatus);
  const obj = {
    ...rollcallObj,
    attendStatus: rollcallObj.attendStatus === '8' ? "-1" : nextAttendStatus,
    attendStatusText: rollcallObj.attendStatus === '8' ? "" : getOptionTextByValue(optSet, 'attentInClassroom', nextAttendStatus)
  }
  // console.log('handleClickStudent', obj)

  firebase.firestore().doc(`/Students/${obj._id}/Daily/${obj.eventDate}`).set({
    classname: obj.classname,
    eventDate: obj.eventDate,
    studentId: obj._id
  })
    .then(() => {
      firebase.firestore().doc(`/Students/${obj._id}/Daily/${obj.eventDate}/Rollcall/${obj.period}`).set(obj)
        .then(() => {
          // console.info('handleClickStudent update success')
        })
        .catch((e) => console.error(e));
    })
    .catch((e) => console.error(e))
}

export const handleClickModalAttend = async (companyObj, optSet, studentObj, attendStatus, studentId, eventDate, period, classObj) => {
  // const { attendStatus } = rollcallObj;
  // console.log('class', classObj)

  const nextAttendStatus = getNextAttendStatus(optSet, attendStatus);
  const weekday = eventDate && (new Date(eventDate).getDay() - 1) + "";
  const weekdayArr2 = ['月', '火', '水', '木', '金'];
  // const classObj = classroom.find(item => item._id === classId);

  const periodArr2 = classObj ? (classObj.classTime === '1' ? ['1', '2', '3', '4'] : ['5', '6', '7', '8']) : [];
  const courseName = eventDate && period ? weekdayArr2[Number(weekday)] + periodArr2[period % 4] : '';

  const obj = {
    // ...rollcallObj,
    objectId: studentObj.objectId,
    companyId: companyObj.objectId,
    companyName: companyObj.name,
    jpnName: studentObj.jpnName,
    studentId: studentObj.objectId,
    studentNum: studentObj.studentId,
    attendStatus,
    attendStatusText: getOptionTextByValue(optSet, 'attentInClassroom', attendStatus),
    classname: studentObj.classSchool,
    period,
    weekday,
    courseName,
    eventDate,
  }
  // console.log('handleClickModalAttend', obj)

  // await firebase.firestore().doc(`/Students/${obj.objectId}/Daily/${obj.eventDate}`).set({
  //   classname: obj.classname,
  //   eventDate: obj.eventDate,
  //   studentId: obj.objectId
  // })
  await Parse.saveData('Daily', { objectId: obj.objectId, classname: obj.classname, eventDate: obj.eventDate, studentId: obj.objectId, companyId: companyObj.objectId, companyName: companyObj.name, })
  await Parse.saveData('Rollcall', obj)

  // await firebase.firestore().doc(`/Students/${obj.objectId}/Daily/${obj.eventDate}/Rollcall/${obj.period}`).set(obj, { merge: true })
  //   .then(() => console.info('update success'))
  //   .catch((e) => console.error(e));
}

export const handleRightClickStudent = (optSet, weekday, period, courseName, classStudentsArr, studentObj, classroomObj, eventDate) => {

  const eventObj = classStudentsArr.find(item =>
    item.classroomObj._id === classroomObj._id
    && item.studentId === studentObj._id
    && item.weekday === weekday
    && item.period === period
    // Moment(item.eventDate).format("YYYY-MM-DD") === Moment(eventDate).format("YYYY-MM-DD"));
    && item.eventDate === eventDate);
  let method, obj;

  // console.log("studentObj", studentObj);
  // console.log("eventObj", eventObj);

  if (eventObj === undefined) {
    method = 'classStudentsArr.insertSetting';
    obj = {
      eventDate,
      attendStatus: getLastAttendStatus(optSet, '-1'),
      // studentObj,
      studentId: studentObj._id,
      classroomObj,
      weekday,
      period,
      courseName
      // courseObj,
    }
  } else {
    const attendStatus = getLastAttendStatus(optSet, eventObj.attendStatus);
    // console.log("attendStatus", attendStatus);

    if (attendStatus === '8') {
      method = 'classStudentsArr.remove';
      obj = eventObj;
    } else {
      method = 'classStudentsArr.updateSetting';
      obj = {
        ...eventObj,
        attendStatus
      }
    }
  }
}

export const cellStyle = { cursor: "pointer", fontSize: '28px', lineHeight: '0px', padding: '20px 0px 0px 0px' };
export const cellShow = (optSet, classStudentsArr, weekday, student, crItem, eventDate, period, courseName, mode, handleClickCell) => (
  <Table.Cell
    key={'cell' + weekday + period + student._id}
    style={{
      ...cellStyle,
      backgroundColor: getBGColor(optSet, classStudentsArr, weekday, period, student, crItem, eventDate)
    }}
    onClick={mode === "1" ?

      () => handleClickCell(student, eventDate, period) :
      () => handleClickStudent(optSet, weekday, period, courseName, classStudentsArr, student, crItem, eventDate)

    }
    onContextMenu={mode === "1" ?
      () => handleClickCell(student, eventDate, period) :
      () => handleRightClickStudent(optSet, weekday, period, courseName, classStudentsArr, student, crItem, eventDate)
    }
  >
    {getBGText2(optSet, classStudentsArr, weekday, period, student, crItem, eventDate)}
    {document.oncontextmenu = function () {
      return false;
    }}
  </Table.Cell>

);


// 回傳学生到今天為止的学期陣列
// ex: 2017年春天入学，現在是是2018年12月
// 回傳的陣列是 [{ semesterYear: 2017, semester: 'S' }, { semesterYear: 2018, semester: 'S' }, { semesterYear: 2018, semester: 'A' }]
export const getAvailblePeriodArr = (semesterYear, semester) => {
  if (!semester || !semesterYear) {
    return [];
  }

  let availablePeriodArr = [];
  let semesterNext = semester;
  let semesterYearNext = Number(semesterYear);
  let semesterYearEnd = Number(semesterYear) + 2;
  let count = 0;

  // 如果2017春天入学，會上課到2019年3/31，也就是說是到2018/秋這個学期
  while ((semesterYearNext < semesterYearEnd) && count < 10) {
    // console.log(semesterNext, semesterYearNext, semesterYearEnd)
    if (semesterNext === 'S') {
      if (new Date() >= new Date(semesterYearNext, 3)) {
        availablePeriodArr.push({ semesterYear: semesterYearNext, semester: semesterNext });
        semesterNext = 'A';
      } else {
        break;
      }
    } else {
      if (new Date() >= new Date(semesterYearNext, 9)) {
        availablePeriodArr.push({ semesterYear: semesterYearNext, semester: semesterNext });
        semesterNext = 'S';
        semesterYearNext++;
      } else {
        break;
      }
    }
    count++;
  }
  // console.log('availablePeriodArr', availablePeriodArr);
  return availablePeriodArr;
};

export const getDate = (periodObj, option = "startDate", showAll = false, availablePeriodArr = null) => {
  // 如果showAll是true，顯示入学至今的出席率;否則顯示選択学期的出席率
  var startDate, endDate;

  if (!periodObj || !periodObj.semester || !periodObj.semesterYear) return '';

  if (showAll) {
    let indexOfLastArr = availablePeriodArr.length - 1;
    let startMonth = availablePeriodArr[0].semester === 'S' ? 4 : 10;
    let endMonth = availablePeriodArr[indexOfLastArr].semester === 'S' ? 10 : 4;
    startDate = new Date(Number(availablePeriodArr[0].semesterYear), startMonth - 1);

    // 如果学期是春季，則會是同一年，否則會到下一年
    availablePeriodArr[indexOfLastArr].semester === 'S' ?
      endDate = new Date(Number(availablePeriodArr[indexOfLastArr].semesterYear), endMonth - 1) :
      endDate = new Date(Number(availablePeriodArr[indexOfLastArr].semesterYear) + 1, endMonth - 1);
  } else {
    // ex: 2018/S => startDate: 2018/4/1, endDate: 2018/10/1
    // ex: 2018/A => startDate: 2018/10/1, endDate: 2019/4/1
    if (periodObj.semester === 'S') {
      startDate = new Date(periodObj.semesterYear, 3);
      endDate = new Date(periodObj.semesterYear, 9);
    } else {
      startDate = new Date(Number(periodObj.semesterYear), 9);
      endDate = new Date(Number(periodObj.semesterYear) + 1, 3);
    }
  }
  // 如果是選択目前正處於的学期，endDate就是今天的日付
  if (endDate > new Date()) {
    let today = new Date();

    // 目前今天的時間是設定成00:00:00，不確定當天上下午的部分要怎麼計算?
    today.setHours(0, 0, 0, 0);
    endDate = today;
  }

  return (
    option === 'startDate' ? startDate : endDate
  );
}

// export class FieldsRender extends Component {
//   constructor(props) {
//     super(props);
//     // console.log(props);
//     this.state = {
//       modalTitle: '',
//       modalOpen: false,
//       collection: props.collection || 'projects'
//     };
//   }

//   render() {
//     // const {fields, pageTitleText, orderBy, titleFields } = this.props;
//     // const { modalTitle, collection } = this.state
//     //     const { auth } = this.props;
//     //     if (!auth.uid) return <Redirect to='/signin' />
//     // const { value } = this.state
//     return (
//       <>
//         234
//       </>
//     )
//   }
// }

// export default FieldsRender


export const getPeriodArr = () => {
  // create empty arrays - yearArr and monthArr and yearMonthArr
  const yearArr = [], monthArr = [], yearMonthArr = [];
  const maxYear = 50;
  // generate year 0 - maxYear and push into yearArr
  for (let i = 0; i <= maxYear; i++) {
    yearArr.push(i);
  }
  // generate month 0 - 12 and push into monthArr
  for (let i = 0; i <= 12; i++) {
    monthArr.push(i);
  }
  // combine yearArr and monthArr as yearMonthArr  
  yearArr.forEach(year =>
    monthArr.forEach(month =>
      yearMonthArr.push(
        {
          value: `${year}-${month}`,
          text: `${year}年${month}月`,
        }
      )
    )
  );
  return yearMonthArr;
}

export const renderSettingValue = (optSet, type, key, optName = '', value = '', onChange) => { // 編集的欄位2
  // console.log("renderSettingValue", type, key, optName, value);

  let option = {}, arrOption = [];
  if (type === 'select' && optName) {
    option = optSet.find((item) => (item.type === 'folder' && item.key === optName));
    if (option && option._id) {
      arrOption = optSet.filter((item) => (item.parentFolderId === option._id))
        .sort((a, b) => a.orderId - b.orderId);
    }
    // console.log(arrOption);
  }

  let periodOption = [];
  if (type === 'period') {
    periodOption = getPeriodArr();
  }

  const formSelect = arrOption.filter(item => item.isEnable).map(item => ({ key: item._id, text: item.label, value: item.value }));
  formSelect.unshift({ key: 'empty', text: '-- 選択 --', value: '' })
  // console.log(arrOption2);

  return {
    // new version using semantic-ui-react to let us using defaultValue property
    boolean: () => (<Form.Input type="text" defaultValue={value} name={key} onChange={onChange} />),
    number: () => (<Form.Input type="number" defaultValue={value} name={key} onChange={onChange} />),
    string: () => (<Form.Input type="text" defaultValue={value} name={key} onChange={onChange} />),
    month: () => (<Form.Input type="month" defaultValue={value} placeholder="" name={key} onChange={onChange} />),
    textarea: () => (<Form.Field control={TextArea} defaultValue={value} name={key} onChange={onChange} />),
    // select: () => (<Form.Field control={Select} options={formSelect} value={value}  name={key} onChange={onChange} />),
    select: () => (<Form.Select fluid options={formSelect} defaultValue={value} name={key} onChange={onChange} />),
    radio: () => (<Form.Field control={Radio} defaultValue={value} name={key} onChange={onChange} />),
    checkbox: () => (<Form.Field control={Checkbox} defaultValue={value} name={key} onChange={onChange} />),
    custom: () => (<Form.Input defaultValue={value} name={key} onChange={onChange} />),
    folder: () => (<Form.Input defaultValue={value} name={key} onChange={onChange} />),
    refer: () => (<Form.Input defaultValue={value} name={key} onChange={onChange} />),
    date: () => (<Form.Input type="date" defaultValue={value} name={key} onChange={onChange} />),
    datetime: () => (<Form.Field type="datetime-local" defaultValue={value && value.toISOString()} name={key} onChange={onChange} />),
    period: () => (<select value={value} name={key} onChange={onChange}>
      <option value=''>-- 選択 --</option>
      {periodOption.map(({ value, text }) => (<option key={value} value={value}>{text}</option>))}
    </select>),
  }[type]();
}

// 這邊前面的是FormSetting的element，所以FormSetting.key是欄位名稱, FormSetting.value是字串的預設值或是下拉式表單的option名稱, 真正的值應該是doc[key]
export const renderSettingField = (optSet, { _id, type, key, value }, docData, handleUpdateSetting) => renderSettingValue(optSet, type, key, value, docData, handleUpdateSetting); // 編集的欄位1

export const renderTextValue = (optSet, formObj, value) => { // 顯示的內容
  if (!value) return;
  // const { optSet } = this.props;
  if (formObj.type === 'string' || formObj.type === 'number' || formObj.type === 'textarea') {
    return value;
  } else if (formObj.type === 'boolean') {
    return value
  } else if (formObj.type === 'date') {
    return Moment(value).format('YYYY/MM/DD');
  } else if (formObj.type === 'select' || formObj.type === 'radio') {
    const optParentObj = optSet.find(element => (formObj.value === element.key));
    if (!optParentObj || !optParentObj._id) {
      // console.log(value, '找無應的選項1', formObj);
      return value;
    }
    const opt = optSet.filter(item => (item.parentFolderId === optParentObj._id)).find(data => (data.value === value)); //這個要確定要怎麼做
    if (opt && opt.label) {
      return opt.label;
    }
    // console.log(value, '找無應的選項2', formObj);
    // return value + ' 找無應的選項2';
    return value;
  } else if (formObj.type === 'month' || formObj.type === 'period') {
    const [year, month] = value.split('-');

    return `${year}年${month}月`;
  }
  return formObj.type + ' ' + value + ' 還沒做好';
}

export const renderField = (optSet, level, docData, isEdit, handleUpdateSetting, isReadOnly) =>
  <Form.Field>
    <label>{level.label}</label>
    {isEdit && !isReadOnly ? renderSettingField(optSet, level, docData, handleUpdateSetting) :
      level.type === "textarea" ? <TextArea readOnly value={docData ? renderTextValue(optSet, level, docData) : ''} />
        : <Input readOnly value={docData ? renderTextValue(optSet, level, docData) : ''} />}
  </Form.Field>;

export const renderFieldNoLabel = (optSet, level, docData, isEdit = 1, handleUpdateSetting, isReadOnly) =>
  isEdit ? <div style={{ padding: '6px' }}><span className="text-muted"></span>{renderSettingField(optSet, level, docData, handleUpdateSetting)}</div>
    : <div keyname={level.key}><span className="text-muted"></span>{renderTextValue(optSet, level, docData)}</div>;

export const renderText = (optSet, level, docData) =>
  <div keyname={level.key}><span className="text-muted">
    {level.label}
    {/* {level.labelEng ? <span>({level.labelEng})</span> : ''} */}
    <br /></span>&nbsp;
    {docData ? renderTextValue(optSet, level, docData) : "尚未填寫"}
  </div>;

export const showField = (props, field, isLabel = 1, isReadOnly = 0) => {
  const { formSet, optSet, isEdit, doc, handleUpdateSetting } = props;
  let obj = formSet.find(item => item.key === field);

  if (typeof obj === 'undefined') {
    // console.log(field);
    return;
  }
  if (isLabel) {
    return renderField(optSet, obj, doc[field], isEdit, handleUpdateSetting, isReadOnly);
  }
  return renderFieldNoLabel(optSet, obj, doc[field], isEdit, handleUpdateSetting, isReadOnly);
}

export const showText = (props, field, doc) => {
  const { formSet, optSet } = props;
  let obj = formSet.find(item => item.key === field);

  if (typeof obj === 'undefined') {
    // console.log(obj);
    return;
  }
  // return this.state.doc[field];
  return renderText(optSet, obj, doc[field]);
}

// 前一学期工作時間
export const getPreviousWorkHours = (teachersArrange, semesterYear, semester, teacherId) => {
  // 如果當前是春，則学年要減1 且 春季變秋季
  if (semester === "S") {
    semesterYear = (Number(semesterYear) - 1) + '';
    semester = "A";
    // 否則学年不變，秋季變春季
  } else {
    semester = "S";
  }

  return getWorkHours(teachersArrange, semesterYear, semester, teacherId);
}

// 當前学期工作時間
export const getCurrentWorkHours = (teachersArrange, semesterYear, semester, teacherId) => {

  return getWorkHours(teachersArrange, semesterYear, semester, teacherId);
}

// 下一学期工作時間
export const getNextWorkHours = (teachersArrange, semesterYear, semester, teacherId) => {
  // 如果當前是秋，則学年要加1 且 秋季變春季
  if (semester === "A") {
    semesterYear = (Number(semesterYear) + 1) + '';
    semester = "S";
    // 否則学年不變，春季變秋季
  } else {
    semester = "A";
  }

  return getWorkHours(teachersArrange, semesterYear, semester, teacherId);
}


export const getWorkHours = (teachersArrange, semesterYear, semester, teacherId) => {
  const teachersArrangeObj = teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester);
  const currentSelArr = teachersArrangeObj && teachersArrangeObj.selArr ? teachersArrangeObj.selArr : [];

  // 找出老師排的課
  const teachClassesArr = currentSelArr.filter(item => item.teacherId === teacherId);

  return teachClassesArr.length ? teachClassesArr.length : 0;
}

export const getPreferredWorkHours = (teachers, semesterYear, semester, teacherId) => {
  const teacherObj = teachers.find(item => item._id === teacherId);
  const maxWeekdayArr =
    teacherObj &&
      teacherObj.arrangeArr &&
      teacherObj.arrangeArr.find(item => item.semesterYear === semesterYear && item.semester === semester) &&
      teacherObj.arrangeArr.find(item => item.semesterYear === semesterYear && item.semester === semester).maxWeekdayArr ?
      teacherObj.arrangeArr.find(item => item.semesterYear === semesterYear && item.semester === semester).maxWeekdayArr : [];
  const maxWeekdayText = maxWeekdayArr.length ? maxWeekdayArr.reduce((accuText, currText) => accuText + currText) : '00000';

  return maxWeekdayText;
}

// calculate the period of time from joiningYearMonth to Now
export const getSeniority = (teachers, teacherId) => {
  const teacherObj = teachers.find(item => item._id === teacherId);
  const joiningYearMonth = teacherObj && teacherObj.joiningYearMonth ? teacherObj.joiningYearMonth : '';

  if (!joiningYearMonth) {
    return '';
  }

  const [joiningYear, joiningMonth] = joiningYearMonth.split('-');
  const nowYear = new Date().getFullYear();
  const nowMonth = new Date().getMonth() + 1;
  const diffMonths = (nowYear * 12 + nowMonth) - (Number(joiningYear) * 12 + Number(joiningMonth));

  return `${Math.floor(diffMonths / 12)}年${diffMonths % 12}月`;
}

// 獲取上週一到上週日
export const getLastWeekList = (date) => {
  if (!date) return [];

  const dateTime = date.getTime();
  const dateDay = date.getDay();
  const oneDayTime = 24 * 3600 * 1000;
  let weekList = [];

  for (let i = 0; i < 7; i++) {
    let time = dateTime - (dateDay + (7 - 1 - i)) * oneDayTime;
    weekList[i] = new Date(time);
  }
  // console.log(weekList);

  return weekList;
};

// 獲取本週一到上週日
export const getThisWeekList = (date) => {
  if (!date) return [];

  const dateTime = date.getTime();
  const dateDay = date.getDay();
  const oneDayTime = 24 * 3600 * 1000;
  let weekList = [];

  for (let i = 0; i < 7; i++) {
    let time = dateTime - (dateDay - 1 - i) * oneDayTime;
    weekList[i] = new Date(time);
  }
  return weekList;
};

// 給定日期算出該年的第幾天
export const getDayIndexOfYear = (date) => {
  const daysOfMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  let res = 0;

  for (let i = 0; i < m - 1; i++) {
    res += daysOfMonth[i];
  }

  if ((y % 400 === 0 || (y % 4 === 0 && y % 100 !== 0)) && m > 2) {
    res += d + 1;
  } else {
    res += d
  }

  return res;
};
// 當月第幾週
export const getMonthWeek = (t) => {
  // console.log(t);
  var _t = new Date();
  _t.setYear(t.getFullYear())
  _t.setMonth(t.getMonth())
  _t.setDate(t.getDate())

  var date1 = _t.getDate(); //给定的日期是几号

  _t.setDate(1)
  var d = _t.getDay(); //1. 得到当前的1号是星期几。
  var fisrtWeekend = d;
  if (d === 0) {
    fisrtWeekend = 1;
    //1号就是星期天
  }
  else {
    fisrtWeekend = 7 - d + 1; //第一周的周未是几号
  }

  if (date1 <= fisrtWeekend) {
    return 1
  }
  else {
    return 1 + Math.ceil((date1 - fisrtWeekend) / 7)
  }
};

export const getStartDate = (year, month) => {
  const start = new Date(year, month)
  // console.log(start);

  return new Date(start.setDate(1));
}

export const getEndtDate = (year, month) => {
  const start = new Date(year, month)
  // console.log(start);

  return new Date(start.setDate(-1));
}