import React, { Component } from 'react'
import { Form, Table } from 'semantic-ui-react';
import { Card, CardBody, CardHeader, Row, Col } from 'reactstrap';

import Parse from 'widget/parse'
import { fieldCol } from "views/formDef/function";
import { portfolio } from 'views/formDef/object';

export default class Portfolio8 extends Component {
  state = {
    icSel: [],
    icId: '',
    providerSel: [],
    providerId: '',
    spread: false,
  }
  async componentDidMount() {
    const { doc, companyObj, ic, provider } = this.props
    const companyId = companyObj.objectId;
    // const ic = await Parse.queryData('interestcondition', { companyId });
    // const provider = await Parse.queryData('provider', { companyId });

    const { agent } = this.props
    const agentSel = agent.map(item => ({ key: item.objectId, text: item.name, value: item.objectId }))
    // this.setState({  })

    const icSel = ic.map(item => ({
      key: item.objectId, text: `${item.item}`, value: item.objectId
    }))
    const providerSel = provider.map(item => ({
      key: item.objectId, text: `${item.name_cht}`, value: item.objectId
    }))
    let icId
    if (ic && ic.length && ic.find(item => (item.objectId === doc.interest_condition))) {
      icId = ic.find(item => (item.objectId === doc.interest_condition)).objectId
    } else {
      icId = ''
    }
    // this.setState({  });

    let providerId
    if (provider && provider.length && provider.find(item => (item.objectId === doc.provider_id))) {
      providerId = provider.find(item => (item.objectId === doc.provider_id)).objectId
    } else {
      providerId = ''
    }

    this.setState({ providerSel, providerId, icSel, icId, agentSel });
  }

  handleIsSpread = () => {
    const { spread } = this.state
    this.setState({ spread: !spread })
  }

  handleChange = (event, data) => {
    const { handleSelectUpdate } = this.props
    const { name, value } = data;
    handleSelectUpdate(data)
    this.setState({ [name]: value })
  }

  render() {
    const { doc, isEdit, showTable, handleChangeField } = this.props
    const { providerId, providerSel, spread, agentSel } = this.state;

    return (<>
      <Card fluid>
        {/* <Card.Content header={`[FPI架構] ${doc.product4_text}`} /> */}
        <CardHeader tag="h5" style={{ backgroundColor: '#f07b51' }}> [AVIVA/Zurich/Standardlife/MassMutual/AIA] {doc.product4_text}</CardHeader>
        <CardBody>
          <Row>
            <Col sm='3'><Form.Select fluid label='供應商' options={providerSel} name='providerId' onChange={this.handleChange} value={providerId} disabled={!isEdit} /></Col>
            {fieldCol(portfolio.product4_engtext, "pr-1", "3", doc.product4_engtext, handleChangeField, !isEdit)}
            {fieldCol(portfolio.account_num, "pr-1", "3", doc.account_num, handleChangeField, !isEdit)}
            {fieldCol(portfolio.account_num2, "pr-1", "3", doc.account_num2, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.policy_firstperson, "pr-1", "3", doc.policy_firstperson, handleChangeField, !isEdit)}
            {fieldCol(portfolio.policy_secondperson, "pr-1", "3", doc.policy_secondperson, handleChangeField, !isEdit)}
            {fieldCol(portfolio.apply_birthday, "pr-1", "3", doc.apply_birthday, handleChangeField, !isEdit)}
            {fieldCol(portfolio.apply_email, "pr-1", "3", doc.apply_email, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.apply_telephone, "pr-1", "3", doc.apply_telephone, handleChangeField, !isEdit)}
            {fieldCol(portfolio.apply_addr, "pr-1", "3", doc.apply_addr, handleChangeField, !isEdit)}
            {fieldCol(portfolio.apply_passport, "pr-1", "3", doc.apply_passport, handleChangeField, !isEdit)}
            {fieldCol(portfolio.apply_cellphone, "pr-1", "3", doc.apply_cellphone, handleChangeField, !isEdit)}
          </Row>
          <hr className="my-2" style={{ borderColor: 'black' }} />
          <Row>
            {fieldCol(portfolio.policy_effective_date, "pr-1", "3", doc.policy_effective_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.policy_end_date, "pr-1", "3", doc.policy_end_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.policy_period_year, "pr-1", "3", doc.policy_period_year, handleChangeField, !isEdit)}
            {fieldCol(portfolio.policy_period_month, "pr-1", "3", doc.policy_period_month, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.insurance_status, "pr-1", "3", doc.insurance_status, handleChangeField, !isEdit)}
            {fieldCol(portfolio.insurancepay_status, "pr-1", "3", doc.insurancepay_status, handleChangeField, !isEdit)}
            {fieldCol(portfolio.policy_endpay_date, "pr-1", "3", doc.policy_endpay_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.policy_nextpay_date, "pr-1", "3", doc.policy_nextpay_date, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.paymethod_fpi, "pr-1", "3", doc.paymethod_fpi, handleChangeField, !isEdit)}
            {fieldCol(portfolio.payperiod_fpi, "pr-1", "3", doc.payperiod_fpi, handleChangeField, !isEdit)}
            {fieldCol(portfolio.nowphase, "pr-1", "3", doc.nowphase, handleChangeField, !isEdit)}
            {fieldCol(portfolio.hsbc_account_name, "pr-1", "3", doc.hsbc_account_name, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.hsbc_account_num, "pr-1", "3", doc.hsbc_account_num, handleChangeField, !isEdit)}
            {fieldCol(portfolio.creditcard_num, "pr-1", "3", doc.creditcard_num, handleChangeField, !isEdit)}
            {fieldCol(portfolio.creditcard_date, "pr-1", "3", doc.creditcard_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.creditcard_bank, "pr-1", "3", doc.creditcard_bank, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.creditcard_bankname, "pr-1", "3", doc.creditcard_bankname, handleChangeField, !isEdit)}
            {fieldCol(portfolio.creditcard_name, "pr-1", "3", doc.creditcard_name, handleChangeField, !isEdit)}
            {fieldCol(portfolio.creditcard_addr, "pr-1", "3", doc.creditcard_addr, handleChangeField, !isEdit)}
            {fieldCol(portfolio.policy_currency_type, "pr-1", "3", doc.policy_currency_type, handleChangeField, !isEdit)}
          </Row>
          <Row>
            <Col sm='3'><Form.Select fluid label='agent' options={agentSel} id='agent_id' name='agent_text' onChange={this.handleChange} value={doc.agent_id || ''} disabled={!isEdit} /></Col>
            {fieldCol(portfolio.fpi_all_withdraw_money, "pr-1", "3", doc.fpi_all_withdraw_money, handleChangeField, !isEdit)}
          </Row>
          {/* <Row>
          {fieldCol(portfolio.terminal_price, "pr-1", "3", doc.terminal_price, handleChangeField, !isEdit)}
            {fieldCol(portfolio.terminal_restvalue, "pr-1", "3", doc.terminal_restvalue, handleChangeField, !isEdit)}
            {fieldCol(portfolio.fpi_done_withdraw_money, "pr-1", "3", doc.fpi_done_withdraw_money, handleChangeField, !isEdit)}
            {fieldCol(portfolio.fpi_able_withdraw_money, "pr-1", "3", doc.fpi_able_withdraw_money, handleChangeField, !isEdit)}
            {fieldCol(portfolio.fpi_all_withdraw_money, "pr-1", "3", doc.fpi_all_withdraw_money, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.account_invest_per, "pr-1", "3", doc.account_invest_per, handleChangeField, !isEdit)}
            {fieldCol(portfolio.fpi_data_updatedate, "pr-1", "3", doc.fpi_data_updatedate, handleChangeField, !isEdit)}
          </Row> */}
        </CardBody>
      </Card>
      {showTable('FundConfigure')}
      <Table>
        <Table.Header>
          <Table.HeaderCell colSpan='9' style={{ textAlign: 'left', cursor: 'pointer', backgroundColor: '#f07b51', fontSize: '17px' }} onClick={this.handleIsSpread}>受益人</Table.HeaderCell>
          {spread ? <><Table.Row>
            <Table.HeaderCell active />
            <Table.HeaderCell>中文姓名</Table.HeaderCell>
            <Table.HeaderCell>英文姓名</Table.HeaderCell>
            <Table.HeaderCell>比例</Table.HeaderCell>
            <Table.HeaderCell>關係</Table.HeaderCell>
            <Table.HeaderCell>解約試算</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>

            <Table.Row>
              <Table.Cell active>受益人1</Table.Cell>
              <Table.Cell >{doc.beneficial1_name}</Table.Cell>
              <Table.Cell >{doc.beneficial1_name2}</Table.Cell>
              <Table.Cell >{doc.beneficial1_percent} %</Table.Cell>
              <Table.Cell >{doc.beneficial1_relationship}</Table.Cell>
              <Table.Cell active>解約/違約金</Table.Cell>
              <Table.Cell>{doc.terminal_price}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell active>受益人2</Table.Cell>
              <Table.Cell >{doc.beneficial2_name}</Table.Cell>
              <Table.Cell >{doc.beneficial2_name2}</Table.Cell>
              <Table.Cell >{doc.beneficial2_percent} %</Table.Cell>
              <Table.Cell >{doc.beneficial2_relationship}</Table.Cell>
              <Table.Cell active>解約剩餘價值</Table.Cell>
              <Table.Cell>{doc.terminal_restvalue}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell active>受益人3</Table.Cell>
              <Table.Cell >{doc.beneficial3_name}</Table.Cell>
              <Table.Cell >{doc.beneficial3_name2}</Table.Cell>
              <Table.Cell >{doc.beneficial3_percent} %</Table.Cell>
              <Table.Cell >{doc.beneficial3_relationship}</Table.Cell>
              <Table.Cell active ></Table.Cell>
              <Table.Cell ></Table.Cell>
            </Table.Row></> : null}
        </Table.Header>
      </Table >
      {showTable('WithdrawBankAccount')}
    </>)
  }
}

// import React, { Component } from 'react'
// // import { Form } from 'semantic-ui-react';
// import { Card, CardBody, CardHeader, Row, Col } from 'reactstrap';

// // import Parse from 'widget/parse'
// import { fieldCol } from "views/formDef/function";
// import { portfolio } from 'views/formDef/object';

// export default class Portfolio8 extends Component {
//   render() {
//     const { doc, isEdit, showTable, handleChangeField } = this.props

//     return (<>
//       <Card fluid>
//         <CardHeader tag="h5" style={{ backgroundColor: '#f07b51' }}> [AVIVA/Zurich/Standardlife/MassMutual/AIA] {doc.product4_text}</CardHeader>
//         <CardBody>
//           <Row>
//             {fieldCol(portfolio.provider_engtext, "pr-1", "3", doc.provider_engtext, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.apply_birthday, "pr-1", "3", doc.apply_birthday, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.insurance_status, "pr-1", "3", doc.insurance_status, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.policy_nextpay_date, "pr-1", "3", doc.policy_nextpay_date, handleChangeField, !isEdit)}
//           </Row>
//           <Row>
//             {fieldCol(portfolio.payperiod_fpi, "pr-1", "3", doc.payperiod_fpi, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.paymethod_fpi, "pr-1", "3", doc.paymethod_fpi, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.apply_addr, "pr-1", "3", doc.apply_addr, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.apply_telephone, "pr-1", "3", doc.apply_telephone, handleChangeField, !isEdit)}
//           </Row>
//           <Row>
//             {fieldCol(portfolio.apply_email, "pr-1", "3", doc.apply_email, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.apply_passport, "pr-1", "3", doc.apply_passport, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.policy_firstperson, "pr-1", "3", doc.policy_firstperson, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.policy_secondperson, "pr-1", "3", doc.policy_secondperson, handleChangeField, !isEdit)}
//           </Row>
//           <Row>
//             {fieldCol(portfolio.creditcard_num, "pr-1", "3", doc.creditcard_num, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.creditcard_name, "pr-1", "3", doc.creditcard_name, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.creditcard_addr, "pr-1", "3", doc.creditcard_addr, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.account_num2, "pr-1", "3", doc.account_num2, handleChangeField, !isEdit)}
//           </Row>
//           <Row>
//             {fieldCol(portfolio.policy_period_year, "pr-1", "3", doc.policy_period_year, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.policy_period_month, "pr-1", "3", doc.policy_period_month, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.policy_effective_date, "pr-1", "3", doc.policy_effective_date, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.policy_endpay_date, "pr-1", "3", doc.policy_endpay_date, handleChangeField, !isEdit)}
//           </Row>
//           <Row>
//             {fieldCol(portfolio.policy_currency_type, "pr-1", "3", doc.policy_currency_type, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.policy_end_date, "pr-1", "3", doc.policy_end_date, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.hsbc_account_name, "pr-1", "3", doc.hsbc_account_name, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.hsbc_account_num, "pr-1", "3", doc.hsbc_account_num, handleChangeField, !isEdit)}
//           </Row>
//           <Row>
//             {fieldCol(portfolio.creditcard_bank, "pr-1", "3", doc.creditcard_bank, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.creditcard_date, "pr-1", "3", doc.creditcard_date, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.insurancepay_status, "pr-1", "3", doc.insurancepay_status, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.product4_engtext, "pr-1", "3", doc.product4_engtext, handleChangeField, !isEdit)}
//           </Row>
//           <Row>
//             {fieldCol(portfolio.terminal_restvalue, "pr-1", "3", doc.terminal_restvalue, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.terminal_price, "pr-1", "3", doc.terminal_price, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.account_num, "pr-1", "3", doc.account_num, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.account_invest_per, "pr-1", "3", doc.account_invest_per, handleChangeField, !isEdit)}
//           </Row>
//           <Row>
//             {fieldCol(portfolio.fpi_done_withdraw_money, "pr-1", "3", doc.fpi_done_withdraw_money, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.fpi_able_withdraw_money, "pr-1", "3", doc.fpi_able_withdraw_money, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.fpi_data_updatedate, "pr-1", "3", doc.fpi_data_updatedate, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.fpi_now_same_payment, "pr-1", "3", doc.fpi_now_same_payment, handleChangeField, !isEdit)}
//           </Row>
//           <Row>
//             {fieldCol(portfolio.apply_cellphone, "pr-1", "3", doc.apply_cellphone, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.nowphase, "pr-1", "3", doc.nowphase, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.fpi_all_withdraw_money, "pr-1", "3", doc.fpi_all_withdraw_money, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.creditcard_bankname, "pr-1", "3", doc.creditcard_bankname, handleChangeField, !isEdit)}
//           </Row>
//           <Row>
//             {fieldCol(portfolio.beneficial1_name, "pr-1", "3", doc.beneficial1_name, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.beneficial1_name2, "pr-1", "3", doc.beneficial1_name2, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.beneficial1_percent, "pr-1", "3", doc.beneficial1_percent, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.beneficial1_relationship, "pr-1", "3", doc.beneficial1_relationship, handleChangeField, !isEdit)}
//           </Row>
//           <Row>
//             {fieldCol(portfolio.beneficial2_name, "pr-1", "3", doc.beneficial2_name, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.beneficial2_name2, "pr-1", "3", doc.beneficial2_name2, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.beneficial2_percent, "pr-1", "3", doc.beneficial2_percent, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.beneficial2_relationship, "pr-1", "3", doc.beneficial2_relationship, handleChangeField, !isEdit)}
//           </Row>
//           {/* <Row>
//             {fieldCol(portfolio.beneficial3_name, "pr-1", "3", doc.beneficial3_name, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.beneficial3_name2, "pr-1", "3", doc.beneficial3_name2, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.beneficial3_percent, "pr-1", "3", doc.beneficial3_percent, handleChangeField, !isEdit)}
//             {fieldCol(portfolio.beneficial3_relationship, "pr-1", "3", doc.beneficial3_relationship, handleChangeField, !isEdit)}
//           </Row> */}
//         </CardBody>
//       </Card>
//       {showTable('WithdrawBankAccount')}
//     </>)
//   }
// }





