import Moment from 'moment';
import { pad } from './string';

export const _calculateAge = (birthday) => { // birthday is a date
  var ageDifMs = new Date() - new Date(birthday).getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const getDateOfISOWeek = (y, w, d = 0) => {
  const simple = new Date(y, 0, 1 + (w - 1) * 7);
  const dow = simple.getDay();
  const ISOweekStart = simple;
  if (dow <= 4)
    ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else
    ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());

  ISOweekStart.setDate(ISOweekStart.getDate() + d);

  return ISOweekStart;
}

export const set0String = (str) => (typeof str !== "string" && str < 10 ? "0" + (str) : "" + str);


export const showTodayText = function () {
  const d = new Date();
  // return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
  return d.getFullYear() + "年" + (d.getMonth() + 1) + "月" + d.getDate() + "日";
}

export const showTodayText2 = function () {
  const d = new Date();
  // return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
  return d.getFullYear() + "-" + pad((d.getMonth() + 1), 2) + "-" + pad(d.getDate(), 2);
}

export const showTodayWeek = function () {
  const d = new Date();
  // return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
  // return d.getFullYear() + "-" + pad((d.getMonth() + 1), 2) + "-" + pad(d.getDate(), 2);
  return d.getFullYear() + "-W" + pad(d.getWeek(), 2);
}

/**
 * 回傳指定的format日期格式
 *
 * @param {date} date - 合法的日期型態.
 * @param {string} format - 合法的Moment format格式字串.
 * @returns {string} format of date.
 */
export const getCustomFormat = (date, format = 'YYYY-MM-DD') => {
  let tempDate = date;
  // console.log(Moment(tempDate).format('YYYY-MM-DD'));

  return Moment(tempDate).format(format);
};

/**
 * 回傳該日期所在的星期的全部七天日期
 *
 * @param {date} fromDate - 合法的日期型態.
 * @returns {Array.<date>} Array of date.
 */
export const getWholeWeek = (fromDate) => {
  let sunday = new Date(fromDate.setDate(fromDate.getDate() - fromDate.getDay()))
    , result = [new Date(sunday)];
  while (sunday.setDate(sunday.getDate() + 1) && sunday.getDay() !== 0) {
    result.push(new Date(sunday));
  }
  return result;
};

/**
 * Returns the week number for this date.  dowOffset is the day of week the week
 * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
 * the week returned is the ISO 8601 week number.
 * @param int dowOffset
 * @return int
 */
Date.prototype.getWeek = function (dowOffset) {
  /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */

  dowOffset = typeof (dowOffset) === 'int' ? dowOffset : 0; //default dowOffset to zero
  var newYear = new Date(this.getFullYear(), 0, 1);
  var day = newYear.getDay() - dowOffset; //the day of week the year begins on
  day = (day >= 0 ? day : day + 7);
  var daynum = Math.floor((this.getTime() - newYear.getTime() -
    (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;
  var weeknum;
  //if the year starts before the middle of a week
  if (day < 4) {
    weeknum = Math.floor((daynum + day - 1) / 7) + 1;
    if (weeknum > 52) {
      var nYear = new Date(this.getFullYear() + 1, 0, 1);
      var nday = nYear.getDay() - dowOffset;
      var nday = nday >= 0 ? nday : nday + 7;
      /*if the next year starts before the middle of
        the week, it is week #1 of that year*/
      weeknum = nday < 4 ? 1 : 53;
    }
  }
  else {
    weeknum = Math.floor((daynum + day - 1) / 7);
  }
  return weeknum;
};
