import React, { Component } from 'react';
import { Grid, Table, Header, Divider, Dimmer, Loader, Image, Segment, Input, Icon, Label, Form } from 'semantic-ui-react';
import AttendanceCalculation from '../../../widgets/AttendanceCalculation';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { LocaleUtils } from 'react-day-picker';
import Moment from 'moment';
import { pad } from '../../../../widget/define/string';
import { getThisWeekList, showFieldText, getAvailblePeriodArr, getDate, getMonthWeek } from 'views/widgets/FieldsRender';
import { hidden } from 'ansi-colors';
import ModalPdf from 'widget/pdf/ModalPdf';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton'
import { Link } from 'react-router-dom';
import Parse from 'widget/parse.js'

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tableShow: {
    display: 'table-row-group',
  },
  tableHide: {
    display: 'none',
  },
  textEllipsis: {
    overflow: 'hidden',
    whiteSpace: 'pre-wrap',
    textOverflow: 'ellipsis',
    maxWidth: '300px'
  }
}

export default class Table39List extends Component {
  constructor(props) {
    super(props);
    const d = new Date();
    this.state = {
      show: false,
      doc: props.doc,
      isEdit: props.isEdit,
      isShow: false,
      form: {},
      isDelete: false,
      relative: [],
      instruct: [],
      loading: false,
      confirm: false,
      showTab: false,
      // eventDate: this.getToday(),
      eventDate: '',
      stu: [],
      specData: [],
      yearmonth: ''
      // yearmonth: d.getFullYear().toString() + '-' + pad(d.getMonth() + 1, 2)
    };
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  // getSnapshotBeforeUpdate(prevProps, prevState) {
  //   console.log('getSnapshotBeforeUpdate');
  //   if (prevState.eventDate !== this.state.eventDate) {
  //     console.log('change');
  //     this.handleFetch(prevProps);
  //     return {};
  //   }
  //   return null
  // }
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   console.log('componentDidUpdate');
  //   if (snapshot !== null) {
  //     this.handleFetch(prevProps);
  //   }
  // }

  handleFetch = async (props = this.props) => {
    const { companyObj, match } = props;
    const { yearmonth } = this.state
    this.setState({ loading: true })
    // const db = firebase.firestore();
    const specData = [];
    if (yearmonth && yearmonth !== '') {

      // db.collectionGroup('Special').where('yearmonth', '==', yearmonth)
      //   .get().then(snap => {
      //     snap.forEach(fileItem => {
      //       let startDate = Moment(this.getStartDate(fileItem.data().eventDate)).format('YYYY-MM-DD')
      //       let actualDay = ''
      //       let actualHours = ''
      //       db.collection(`Students/${fileItem.data().studentId}/Weekly`)
      //         .where('startDate', '==', startDate)
      //         .get().then(snapGet => {
      //           snapGet.forEach(item => {
      //             actualDay = item.data().weeklyRealDay
      //             actualHours = item.data().weeklyRealHours
      //             let eventDate = Moment(new Date(fileItem.data().eventDate)).format('YYYY/MM/DD')
      //             specData.push({ _id: fileItem.id, ...fileItem.data(), actualDay, actualHours, eventDate })
      //           })
      //           this.setState({ specData })
      //         })
      //     })
      this.setState({ show: true, showTab: true, loading: false })
      console.log(yearmonth, specData);
      // })
    } else {
      const special = await Parse.queryData('Special', { companyId: companyObj.objectId });
      // db.collectionGroup('Special').get().then(snap => {
      special.forEach(async (fileItem) => {
        let startDate = Moment(this.getStartDate(fileItem.eventDate)).format('YYYY-MM-DD')
        let actualDay = ''
        let actualHours = ''
        // db.collection(`Students/${fileItem.data().studentId}/Weekly`)
        //   .where('startDate', '==', startDate)
        //   .get().then(snapGet => {
        const snapGet = await Parse.queryData('Weekly', { companyId: companyObj.objectId, studentId: fileItem.studentId, startDate });
        snapGet.forEach(item => {
          actualDay = item.weeklyRealDay
          actualHours = item.weeklyRealHours
          let eventDate = Moment(new Date(fileItem.eventDate)).format('YYYY/MM/DD')
          specData.push({ objectId: fileItem.objectId, ...fileItem, actualDay, actualHours, eventDate })
        })
        this.setState({ specData })
      })
      // })
      this.setState({ show: true, showTab: true, loading: false })
      console.log(yearmonth, specData);
      // })
    }

    // firebase.firestore().collection(`Students`).orderBy('studentId').get().then(snap => {
    //   var promises = [];
    //   snap.forEach(item => {
    //     var promise = firebase.firestore().collection(`Students/${item.id}/Special`)
    //       .where('eventDate', '==', eventDate)
    //       .get().then(snapshot => {
    //         const specData = []
    //         snapshot.forEach(fileItem => specData.push({ _id: fileItem.id, ...fileItem.data() }));
    //         return { _id: item.id, ...item.data(), specData };
    //       });
    //     promises.push(promise);
    //   });
    //   Promise.all(promises).then(results => {
    //     // console.log('all promises were done')
    //     // console.log(results);
    //     this.setState({ stu: results, show: true, loading: false })
    //   }).catch(error => {
    //     console.log(error);
    //   });

    // }, err => {
    //   console.log(`Encountered error: ${err}`);
    // });
  }

  handleChange = (event, data) => {
    const { name, value } = data
    if (name === 'yearmonth') {
      const [year, month1] = value && value.split('-'); // 2019-W10 => 2019, W10
      const month = Number(month1).toString(); // W10 => 10
      this.setState({ yearmonth: value, year, month, loading: 1 }, () => this.handleFetch());
    }
    // const eventDate = Moment(new Date(date)).format('YYYY-MM-DD');
    // this.setState({ eventDate: value }, () => this.handleFetch())
  }

  setSettingOnChange = (setting) => {
    let fieldName = setting.target.name;
    let fleldVal = setting.target.value;

    this.setState({ form: { ...this.state.form, [fieldName]: fleldVal } })
  }

  saveInsertSetting = () => {
    const { form, doc } = this.state;
    // const method = 'student.updateSetting';

    let obj = {};

    if (doc.weeklyRepArr) {
      obj = { weeklyRepArr: [...doc.weeklyRepArr, form] }
    } else {
      obj = { weeklyRepArr: [form] }
    }

    // console.log(obj);

    this.props.setRelativeObj(obj);
    // console.log(this.state);

    // Meteor.call(method, doc, (error, result) => {
    //   if (error) {
    //     Bert.alert(error.reason, 'danger');
    //   } else {
    //     // Bert.alert('Unit inserted!', 'success');
    //     this.closeRowModal();
    //   }
    // });
  }

  getStartDate = (date) => {
    const lastWeek = getThisWeekList(new Date(date));
    return lastWeek[0];
  }

  // getEndDate = (date) => {
  //   const lastWeek = getLastWeekList(new Date(date));
  //   return lastWeek[6];
  // }

  closeTab = () => this.setState({ showTab: false })

  getToday = () => {
    return new Date().toISOString().split('T')[0];
  };

  render() {
    const { doc, grade2, grade, semesterYear, semester, calendar, classroom, isEdit } = this.props;
    const { form, yearmonth, relative, loading, instruct, showTab, eventDate, studentsInClass, stu, specData, show } = this.state
    const arr2 = ['1', '2'];
    const arr4 = ['1', '2', '3', '4']
    const arr5 = ['1', '2', '3', '4', '5'];
    const arr6 = ['1', '2', '3', '4', '5', '6'];

    let startDate, lastWeek = '';
    // const availablePeriodArr = getAvailblePeriodArr(semesterYear, semester);
    // let endDate = getDate(availablePeriodArr[0], 'endDate', false);

    // if (!endDate || !availablePeriodArr.length) {
    //   return <>endDate error!</>;
    // }
    const row = specData.sort((a, b) => ('' + b.eventDate).localeCompare('' + a.eventDate))
    // lastWeek = getLastWeekList(endDate);
    // startDate = lastWeek[0];
    // endDate = lastWeek[6];

    const classRoomArr = classroom.filter(item => item.isEnableClass === true && item.semesterYear === semesterYear && item.semester === semester && item.classSchool === showFieldText('classSchool', doc));

    // console.log(doc);

    return (<>
      <div style={{ ...style.flexCenter }}>
        <Header as='h2' content={`${eventDate} 特別指導学生週間報告書一覽`}
          style={{ backgroundColor: 'lavender', margin: 0 }} /* subheader='調査時間：2017年4月から 2018年12月 まで'*/ />
        {/* {!isEdit && isEdit !== undefined ? <div style={{ ...style.flexCenter }}>
          <Button color='blue' icon='file alternate' content='詳細' onClick={() => this.setState({ showTab: true })} style={{ marginRight: 10 }} />
          <Button color='orange' onClick={() => this.props.history.push(`/printRegisteredStudent/${doc._id}`)}>印刷</Button>
        </div>
          : <Button onClick={this.handleOpen} inverted color='blue' icon='add' content='追加' />} */}
        {/* <div style={{ ...style.flexCenter, minWidth: 400 }}>
          <Form.Input
            label='試験年月日'
            type='date'
            name='testDate'
            value={eventDate || ''}
            onChange={this.handleChange}
          // readOnly={readOnly}
          /> */}
        <div style={{ display: 'flex', marginTop: '1em' }}>
          <Input labelPosition='left' style={{ marginLeft: 10 }}>
            <Label color={'teal'}> <Icon name='calendar alternate' />月</Label>
            <Form.Input
              style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
              name="yearmonth" type="month" value={yearmonth} onChange={this.handleChange} />
          </Input>
          <ExportTableToExcelButton size='small' />
          <ModalPdf
            {...this.props}
            // ref={(ref) => { this.pdfModal = ref }}
            codekey="table39ListPdf"
            params={row}
          />
          {/* </div> */}
        </div>
      </div>
      <Divider />
      <Grid.Row>
        <Table id='excel' celled size="small" style={{ textAlign: 'center', tableLayout: "auto", display: showTab ? 'table' : 'none', opacity: showTab ? '1' : '0', transition: '.5s ease-in-out' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>学籍番号</Table.HeaderCell>
              <Table.HeaderCell>氏名</Table.HeaderCell>
              <Table.HeaderCell>クラス</Table.HeaderCell>
              <Table.HeaderCell>日付</Table.HeaderCell>
              <Table.HeaderCell >出席日数</Table.HeaderCell>
              <Table.HeaderCell >出席時数</Table.HeaderCell>
              <Table.HeaderCell style={{ ...style.textEllipsis }}>今週の報告內容</Table.HeaderCell>
              <Table.HeaderCell style={{ ...style.textEllipsis }}>指導·行動·結果</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body style={!show ? style.tableHide : style.tableShow}>
            {row && row.length ? row.map((item, index) => (<React.Fragment key={`specData${index}`}>
              <Table.Row>
                {/* <Table.Cell width="10%">今週</Table.Cell> */}
                <Table.Cell>{item.studentIdKey}</Table.Cell>
                <Table.Cell><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/4`}>{item.studentJpnName}</Link></Table.Cell>
                <Table.Cell>{item.classSchool}</Table.Cell>
                <Table.Cell>{item.eventDate}</Table.Cell>
                <Table.Cell>{item.actualDay}</Table.Cell>
                <Table.Cell>{item.actualHours}</Table.Cell>
                {/* <Table.Cell width={4} style={{ textAlign: 'left' }}>
                  {item.weeklyReportDetail && item.weeklyReportDetail.length > 100 ? item.weeklyReportDetail.substring(0, 100) + '...' : item.weeklyReportDetail}
                </Table.Cell> 
                <Table.Cell width={4} style={{ textAlign: 'left' }}>
                  {item.weeklyReportAction && item.weeklyReportAction.length > 100 ? item.weeklyReportAction.substring(0, 100) + '...' : item.weeklyReportAction}
                </Table.Cell>*/}
                <Table.Cell style={{ ...style.textEllipsis, textAlign: 'left', boxSizing: 'border-box', textOverflow: 'linebreak' }}>
                  {item.weeklyReportDetail}
                </Table.Cell>
                <Table.Cell style={{ ...style.textEllipsis, textAlign: 'left', boxSizing: 'border-box', textOverflow: 'linebreak' }}>
                  {item.weeklyReportAction}
                </Table.Cell>
              </Table.Row>
            </React.Fragment>)) : <Table.Row><Table.Cell colSpan='6' textAlign='center'>(資料無し)</Table.Cell></Table.Row>}
          </Table.Body>
        </Table>
      </Grid.Row>
      <div style={{ display: !show ? 'flex' : 'none', justifyContent: 'center', margin: '30px 0' }}>
        <Segment>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
          <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        </Segment>
      </div>
    </>);
  }
}

