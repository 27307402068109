import Plugin from '@ckeditor/ckeditor5-core/src/plugin';


export default class EditorCustomAttribute extends Plugin {
  constructor(editor) {
    super(editor);
  }

  init() {

  }

  afterInit() {
    const editor = this.editor;

    // Define on which elements the CSS classes should be preserved:
    // setupCustomClassConversion('img', 'image', editor);
    setupCustomClassConversion('table', 'table', editor);

    editor.conversion.for('upcast').add(upcastCustomClasses('figure'), { priority: 'low' });

    // Define custom attributes that should be preserved.
    // setupCustomAttributeConversion('img', 'image', 'style', editor);
    setupCustomAttributeConversion('table', 'table', 'border', editor);
    setupCustomAttributeConversion('table', 'table', 'cellspacing', editor);
    setupCustomAttributeConversion('table', 'table', 'cellpadding', editor);
  }

}

function setupCustomClassConversion(viewElementName, modelElementName, editor) {
  // The 'customClass' attribute stores custom classes from the data in the model so that schema definitions allow this attribute.
  editor.model.schema.extend(modelElementName, { allowAttributes: ['customClass'] });

  // Defines upcast converters for the <img> and <table> elements with a "low" priority so they are run after the default converters.
  editor.conversion.for('upcast').add(upcastCustomClasses(viewElementName), { priority: 'low' });

  // Defines downcast converters for a model element with a "low" priority so they are run after the default converters.
  // Use `downcastCustomClassesToFigure` if you want to keep your classes on <figure> element or `downcastCustomClassesToChild`
  // if you would like to keep your classes on a <figure> child element, i.e. <img>.
  editor.conversion.for('downcast').add(downcastCustomClassesToFigure(modelElementName), { priority: 'low' });
  // editor.conversion.for( 'downcast' ).add( downcastCustomClassesToChild( viewElementName, modelElementName ), { priority: 'low' } );
}

/**
* Sets up a conversion for a custom attribute on the view elements contained inside a <figure>.
*
* This method:
* - Adds proper schema rules.
* - Adds an upcast converter.
* - Adds a downcast converter.
*/
function setupCustomAttributeConversion(viewElementName, modelElementName, viewAttribute, editor) {
  // Extends the schema to store an attribute in the model.
  const modelAttribute = `custom${viewAttribute}`;

  editor.model.schema.extend(modelElementName, { allowAttributes: [modelAttribute] });

  editor.conversion.for('upcast').add(upcastAttribute(viewElementName, viewAttribute, modelAttribute));
  editor.conversion.for('downcast').add(downcastAttribute(modelElementName, viewElementName, viewAttribute, modelAttribute));
}

/**
* Creates an upcast converter that will pass all classes from the view element to the model element.
*/
function upcastCustomClasses(elementName) {
  return dispatcher => dispatcher.on(`element:${elementName}`, (evt, data, conversionApi) => {
    const viewItem = data.viewItem;
    const modelRange = data.modelRange;

    const modelElement = modelRange && modelRange.start.nodeAfter;

    if (!modelElement) {
      return;
    }

    // The upcast conversion picks up classes from the base element and from the <figure> element so it should be extensible.
    const currentAttributeValue = modelElement.getAttribute('customClass') || [];

    currentAttributeValue.push(...viewItem.getClassNames());

    conversionApi.writer.setAttribute('customClass', currentAttributeValue, modelElement);
  });
}

/**
* Creates a downcast converter that adds classes defined in the `customClass` attribute to a <figure> element.
*
* This converter expects that the view element is nested in a <figure> element.
*/
function downcastCustomClassesToFigure(modelElementName) {
  return dispatcher => dispatcher.on(`insert:${modelElementName}`, (evt, data, conversionApi) => {
    const modelElement = data.item;

    const viewFigure = conversionApi.mapper.toViewElement(modelElement);

    if (!viewFigure) {
      return;
    }

    // The code below assumes that classes are set on the <figure> element.
    conversionApi.writer.addClass(modelElement.getAttribute('customClass'), viewFigure);
  });
}

/**
* Creates a downcast converter that adds classes defined in the `customClass` attribute to a <figure> child element.
*
* This converter expects that the view element is nested in a <figure> element.
*/
function downcastCustomClassesToChild(viewElementName, modelElementName) {
  return dispatcher => dispatcher.on(`insert:${modelElementName}`, (evt, data, conversionApi) => {
    const modelElement = data.item;

    const viewFigure = conversionApi.mapper.toViewElement(modelElement);

    if (!viewFigure) {
      return;
    }

    // The code below assumes that classes are set on the element inside the <figure>.
    const viewElement = findViewChild(viewFigure, viewElementName, conversionApi);

    conversionApi.writer.addClass(modelElement.getAttribute('customClass'), viewElement);
  });
}

/**
* Helper method that searches for a given view element in all children of the model element.
*
* @param {module:engine/view/item~Item} viewElement
* @param {String} viewElementName
* @param {module:engine/conversion/downcastdispatcher~DowncastConversionApi} conversionApi
* @return {module:engine/view/item~Item}
*/
function findViewChild(viewElement, viewElementName, conversionApi) {
  const viewChildren = Array.from(conversionApi.writer.createRangeIn(viewElement).getItems());

  return viewChildren.find(item => item.is('element', viewElementName));
}

/**
* Returns the custom attribute upcast converter.
*/
function upcastAttribute(viewElementName, viewAttribute, modelAttribute) {
  return dispatcher => dispatcher.on(`element:${viewElementName}`, (evt, data, conversionApi) => {
    const viewItem = data.viewItem;
    const modelRange = data.modelRange;

    const modelElement = modelRange && modelRange.start.nodeAfter;

    if (!modelElement) {
      return;
    }

    conversionApi.writer.setAttribute(modelAttribute, viewItem.getAttribute(viewAttribute), modelElement);
  });
}

/**
* Returns the custom attribute downcast converter.
*/
function downcastAttribute(modelElementName, viewElementName, viewAttribute, modelAttribute) {
  return dispatcher => dispatcher.on(`insert:${modelElementName}`, (evt, data, conversionApi) => {
    const modelElement = data.item;

    const viewFigure = conversionApi.mapper.toViewElement(modelElement);
    const viewElement = findViewChild(viewFigure, viewElementName, conversionApi);

    if (!viewElement) {
      return;
    }
    let value = modelElement.getAttribute(modelAttribute) || ''
    if (value === '') {
      if (viewAttribute === 'border') {
        value = '1'
      } else if (viewAttribute === 'cellpadding') {
        value = '3'
      } else if (viewAttribute === 'cellspacing') {
        value = '0'
      } else {
        value = ''
      }
    }
    // conversionApi.writer.setAttribute(viewAttribute, modelElement.getAttribute(modelAttribute), viewElement);
    conversionApi.writer.setAttribute(viewAttribute, value, viewElement);
  });
}