import React, { Component } from 'react'
import { Container, Icon, Table, Grid, Button, Modal, Header, Form, Pagination, Dropdown, Confirm } from 'semantic-ui-react'
import _ from 'lodash'
import { getOptionTextByValue, showCurrentClass, getActiveSemesterArr } from 'views/widgets/FieldsRender';
import Moment from 'moment';
import { getNationalityArr } from '../../../widget/define/students'
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import Parse from '../../../widget/parse'
import ModalPdf from 'widget/pdf/ModalPdf';

import { Link } from 'react-router-dom';
const semesterArr = [
  { key: '2021,S', text: '2021S', value: '2021,S' },
  { key: '2020,A', text: '2020A', value: '2020,A' },
  { key: '2020,S', text: '2020S', value: '2020,S' },
  { key: '2019,A', text: '2019A', value: '2019,A' },
  { key: '2019,S', text: '2019S', value: '2019,S' },
  { key: '2018,A', text: '2018A', value: '2018,A' },
  { key: '2018,S', text: '2018S', value: '2018,S' },
  { key: '2017,A', text: '2017A', value: '2017,A' },
  { key: '2017,S', text: '2017S', value: '2017,S' },
]
const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipse: {
    // boxSizong: 'border-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

const getStudentsBySemester = (students, semester) => {
  return (
    students ?
      students.filter(item => item.semesterYear === semester.toString().substring(0, 4) && item.semester === semester.toString().substring(4)) : []
  )
};


export class TransitionReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      author: '',
      graduates: [],
      classroom: [],
      classroomS: []
    }
  }
  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, match, semesterYear, semester } = this.props
    // firebase.firestore()
    //   .collection('Classroom')
    //   .where('semesterYear', '==', (Number(semesterYear) - 1) + '')
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     // console.log(data);
    //     this.setState({ classroomLast: data });
    //   }, err => {
    //     console.log(`Encountered error: ${err}`);
    //   });
    if (semester === 'A') {
      const classroomS = await Parse.queryData('Classroom', { companyId: companyObj.objectId, semesterYear, semester: 'S' });
      this.setState({ classroomS })
    }

    // firebase.firestore()
    //   .collection('Classroom')
    //   .where('semesterYear', '==', semesterYear)
    //   .where('semester', '==', 'S')
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     // console.log(data);
    //     this.setState({ classroomS: data });
    //   }, err => {
    //     console.log(`Encountered error: ${err}`);
    //   });
  }

  handleSave = async () => {
    const { firebase, doc, semesterYear, semester, classroom } = this.props;
    const { form } = this.state;
    const db = firebase.firestore();

    classroom.map(item => {
      const docRef = db.doc(`Classroom/${item._id}`);
      let stuNum = this.count(item.classSchool)
      const obj = {
        formalNum: stuNum[0],
        listenNum: stuNum[1],
        totalNum: stuNum[2]
      };
      docRef.set(obj, { merge: true });
    })
  }

  count = (classSchool) => {
    const { students, classroom } = this.props
    const monthArr = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];
    let classDate = classroom.find(item => item.classSchool === classSchool)
    let formal = classDate.formalNum ? [...classDate.formalNum] : []
    let listen = classDate.listenNum ? [...classDate.listenNum] : []
    let total = classDate.totalNum ? [...classDate.totalNum] : []
    // const studentsSemes = getStudentsBySemester(students, semester);
    const studentsSemes = students.filter(item => (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
      (item.applyResult === '1' || item.studentType === "2"))
    // console.log(studentsSemes)
    const studentSchool = studentsSemes.filter(stu => stu.classSchool === classSchool)
    // monthArr.map(item => {
    if (formal.length > 0 && ((new Date().getMonth() + 1) === monthArr[formal.length - 1] || (new Date().getMonth() + 1) === monthArr[formal.length + 5])) {
      formal[formal.length - 1] = (studentSchool.filter(stu => stu.studentType === '1').length)
      listen[formal.length - 1] = (studentSchool.filter(stu => stu.studentType === '2').length)
      total[formal.length - 1] = (studentSchool.length)
    } else {
      formal.push(studentSchool.filter(stu => stu.studentType === '1').length)
      listen.push(studentSchool.filter(stu => stu.studentType === '2').length)
      total.push(studentSchool.length)
    }
    return [formal, listen, total]
  }

  customizedText = (studentsInSameSemester) => {
    // const studentsInSameSemester = getStudentsBySemester(students, semester);
    const nationalityArr = getNationalityArr(studentsInSameSemester);
    const studentsUnknownNation = studentsInSameSemester.filter(item => nationalityArr.indexOf(item.nationality) === -1);

    return (
      studentsInSameSemester.length && nationalityArr.length ?
        nationalityArr.reduce((text, nationality, index) => {
          text = text + '\n';
          let studentsInSameNation = studentsInSameSemester.filter(item => item.nationality === nationality);
          // console.log(text)

          return index === nationalityArr.length - 1 ?
            text + `${getOptionTextByValue(this.props.optSet, 'nationality', nationality)} ${studentsInSameNation.length}人(男 ${studentsInSameNation.filter(item => item.gender === '2').length} 女 ${studentsInSameNation.filter(item => item.gender === '1').length}) 未知 ${studentsUnknownNation.length}人 `
            : text + `${getOptionTextByValue(this.props.optSet, 'nationality', nationality)} ${studentsInSameNation.length} 人(男 ${studentsInSameNation.filter(item => item.gender === '2').length} 女 ${studentsInSameNation.filter(item => item.gender === '1').length})`
        }, '') : '未知'
    )
  }

  studentCount = (semesterArr, entry) => {
    const { students } = this.props;
    let semesterStudentArr = [];
    semesterArr.forEach(item => {
      let obj = {};
      obj.semesterYear = item.semesterYear;
      obj.semester = item.semester;
      const studentsCurrent = students.filter(item => (item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
        (item.applyResult === '1' || item.studentType === "2"))
      obj.semesterStudent = entry ? studentsCurrent.filter(item2 => item2.semesterYear === obj.semesterYear && item2.semester === obj.semester) ://入学期當期学生
        studentsCurrent.filter(item2 => item2.semesterYear === ((Number(obj.semesterYear) - 1) + '') || (obj.semester === 'A' ? item2.semesterYear === obj.semesterYear : item2.semesterYear === obj.semesterYear && item2.semester === obj.semester))//学期中所有学生
      obj.nationality = this.customizedText(obj.semesterStudent)
      obj.maleNum = obj.semesterStudent.filter(item2 => item2.gender === '2').length;
      obj.femaleNum = obj.semesterStudent.filter(item2 => item2.gender === '1').length;
      obj.gender = `男 ${obj.maleNum} / 女 ${obj.femaleNum}`
      obj.formalNum = obj.semesterStudent.filter(item4 => item4.studentType === '1').length;
      obj.listeningNum = obj.semesterStudent.length - obj.formalNum;

      semesterStudentArr.push(obj);
    })
    return semesterStudentArr;
  }
  render() {
    const { firebase, classroom, match, history, formSet, classroomS, students, semester, semesterYear, companyObj } = this.props;
    const { monthly } = this.state;
    // const semesterArr = ['2019,A', '2019,S', '2018,A', '2018,S']
    const semesterArr = getActiveSemesterArr(semesterYear, semester);
    // const semesterEnterArr = [...getActiveSemesterArr((Number(semesterYear) - 1) + '', semester), ...semesterArr]
    // console.log(ActiveSemester)
    const monthArr = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];
    let students1 = students.filter(item => item.studentType === '1')
    let students2 = students.filter(item => item.studentType === '2')
    let firstS = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    let firsts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    let firstA = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    let firsta = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    let secondS = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    let seconds = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    // let secondA = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    // let seconda = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    return (
      <>

        <div style={{ ...style.flexCenter }}>
          <Header as='h2' style={{ margin: 0 }}>推移表</Header>
          <div style={{ ...style.flexCenter, marginLeft: 10, minWidth: 220 }}>
            {/* <ReactToPrint
                    trigger={(e) => <Button
                      // floated='right'
                      content='印刷'
                      // label='&nbsp;'
                      color='orange' icon='print'
                      size='small'
                    />}
                    content={() => this.componentRef}
                    pageStyle={"@page { size: auto;  margin:5mm; }*{font-family: 'Noto Serif JP', serif;}"}
                  /> */}
            <Button inverted={companyObj.inverted} color='blue' onClick={this.handleSave} icon='file' content='当月登録' />
            <ExportTableToExcelButton /*floated='right'*/ size='small' />
            <ModalPdf
              {...this.props}
              // ref={(ref) => { this.pdfModal = ref }}
              codekey="transitionReportPdf"
              params={this.studentCount(semesterArr, true)}
            />
          </div>
        </div>
        <div id='excel' style={{ maxWidth: '100vw', overflowX: 'scroll', marginTop: 25 }}>
          {/* <div style={{ ...style.flexCenter, marginTop: 15 }}>
              <Header as='h3'>推移表</Header>
            </div> */}
          <Table celled size='small' selectable>
            <Table.Header>
              <Table.Row textAlign='center'>
                {/* <Table.HeaderCell style={style.textEllipse} colSpan={13}>{Number(semesterYear) - 1}</Table.HeaderCell> */}
                <Table.HeaderCell style={style.textEllipse} colSpan={13}>{semesterYear}</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse} colSpan={13}>{Number(semesterYear) + 1}</Table.HeaderCell>
              </Table.Row>
              <Table.Row textAlign='center'>
                {['', ''].map(item => <>
                  <Table.HeaderCell style={style.textEllipse}></Table.HeaderCell>
                  <Table.HeaderCell style={style.textEllipse}>4</Table.HeaderCell>
                  <Table.HeaderCell style={style.textEllipse}>5</Table.HeaderCell>
                  <Table.HeaderCell style={style.textEllipse}>6</Table.HeaderCell>
                  <Table.HeaderCell style={style.textEllipse}>7</Table.HeaderCell>
                  <Table.HeaderCell style={style.textEllipse}>8</Table.HeaderCell>
                  <Table.HeaderCell style={style.textEllipse}>9</Table.HeaderCell>
                  <Table.HeaderCell style={style.textEllipse}>10</Table.HeaderCell>
                  <Table.HeaderCell style={style.textEllipse}>11</Table.HeaderCell>
                  <Table.HeaderCell style={style.textEllipse}>12</Table.HeaderCell>
                  <Table.HeaderCell style={style.textEllipse}>1</Table.HeaderCell>
                  <Table.HeaderCell style={style.textEllipse}>2</Table.HeaderCell>
                  <Table.HeaderCell style={style.textEllipse}>3</Table.HeaderCell></>)}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(semester === 'A' ? classroomS : classroom)
                .filter(classRoom => classRoom.entryYear === (Number(semesterYear - 1) + '') && classRoom.entryMonth === '4')
                // .sort((a, b) => ('' + a.entryYear).localeCompare(b.entryYear))
                .sort((a, b) => ('' + a.classSchool).localeCompare(b.classSchool))
                .map((item, index) => <>
                  {monthArr.map((month, index) => {
                    firstS[index] += this.count(item.classSchool)[0][index] || 0
                    firsts[index] += this.count(item.classSchool)[1][index] || 0
                  })}
                  <Table.Row key={'a' + index}>
                    <Table.Cell style={style.textEllipse} rowSpan={2}>{item.entryYear}S {item.classSchool}</Table.Cell>
                    {this.count(item.classSchool)[0].map(num =>
                      <Table.Cell style={style.textEllipse}>{num}</Table.Cell>
                    )}
                    {/* {monthArr.map(month =>
                        <Table.Cell style={style.textEllipse}>{students1.filter(stu => stu.semesterYear === Number(item.entryYear) && stu.classSchool === item.classSchool).length}</Table.Cell>)} */}
                    {/* {monthArr.map(month =>
                        <Table.Cell style={style.textEllipse}>{monthly && monthly.filter(stu => stu.year === ((month === 1 || month === 2 || month === 3) ? Number(item.entryYear) + 1 : item.entryYear) && stu.classname === item.classSchool && stu.month === month).length}</Table.Cell>)}
                      <Table.Cell style={style.textEllipse}>{}</Table.Cell>
                      {monthArr.map(month =>
                        <Table.Cell style={style.textEllipse}>{monthly && monthly.filter(stu => stu.year === ((month === 1 || month === 2 || month === 3) ? Number(item.entryYear) + 2 : Number(item.entryYear) + 1) && stu.classname === item.classSchool && stu.month === month).length}</Table.Cell>)}
                      <Table.Cell style={style.textEllipse}>{}</Table.Cell> */}
                  </Table.Row>
                  <Table.Row key={'b' + index}>
                    {this.count(item.classSchool)[1].map(num =>
                      <Table.Cell style={style.textEllipse}>{num}</Table.Cell>
                    )}
                    {/* {monthArr.map(month =>
                        <Table.Cell style={style.textEllipse}>{students2.filter(stu => stu.semesterYear === Number(item.entryYear) && stu.classSchool === item.classSchool).length}</Table.Cell>)} */}
                  </Table.Row>
                  {/* <Table.Row key={'c' + index}>
                      {this.count('2017S', item.classSchool)[2].map(num =>
                        <Table.Cell style={style.textEllipse}>{num}</Table.Cell>
                      )}
                    </Table.Row> */}
                </>)}
              <Table.Row style={{ backgroundColor: '#D7E9F3' }}>
                <Table.Cell style={style.textEllipse}>{'正規生'}</Table.Cell>
                {firstS.map(num =>
                  <Table.Cell style={style.textEllipse}>{num}</Table.Cell>
                )}
              </Table.Row>
              <Table.Row style={{ backgroundColor: '#D7E9F3' }}>
                <Table.Cell style={style.textEllipse}>{'聽講生'}</Table.Cell>
                {firsts.map(num =>
                  <Table.Cell style={style.textEllipse}>{num}</Table.Cell>
                )}
              </Table.Row>
              <Table.Row style={{ backgroundColor: '#D7E9F3' }}>
                <Table.Cell style={style.textEllipse}>{'小計'}</Table.Cell>
                {firstS.map((num, index) =>
                  <Table.Cell style={style.textEllipse}>{firstS[index] + firsts[index]}</Table.Cell>
                )}
              </Table.Row>
              <Table.Row style={{ backgroundColor: 'black' }}>
                <Table.Cell colSpan={39} style={style.textEllipse}></Table.Cell>
              </Table.Row>
              {classroom && classroom
                .filter(classRoom => classRoom.entryYear === (Number(semesterYear - 1) + '') && classRoom.entryMonth === '10')
                // .sort((a, b) => ('' + a.entryYear).localeCompare(b.entryYear))
                .sort((a, b) => ('' + a.classSchool).localeCompare(b.classSchool))
                .map((item, index) => <>
                  {monthArr.map((month, index) => {
                    firstA[index] += this.count(item.classSchool)[0][index] || 0
                    firsta[index] += this.count(item.classSchool)[1][index] || 0
                  })}
                  <Table.Row key={'a' + index}>
                    <Table.Cell style={style.textEllipse} rowSpan={2}>{item.entryYear}A {item.classSchool}</Table.Cell>
                    {this.count(item.classSchool)[0].map(num =>
                      <Table.Cell style={style.textEllipse}>{num}</Table.Cell>
                    )}
                    {/* {monthArr.map(month =>
                        <Table.Cell style={style.textEllipse}>{monthly && monthly.filter(stu => stu.year === ((month === 1 || month === 2 || month === 3) ? Number(item.entryYear) + 1 : item.entryYear) && stu.classname === item.classSchool && stu.month === month).length}</Table.Cell>)}
                      <Table.Cell style={style.textEllipse}>{}</Table.Cell>
                      {monthArr.map(month =>
                        <Table.Cell style={style.textEllipse}>{monthly && monthly.filter(stu => stu.year === ((month === 1 || month === 2 || month === 3) ? Number(item.entryYear) + 2 : Number(item.entryYear) + 1) && stu.classname === item.classSchool && stu.month === month).length}</Table.Cell>)}
                      <Table.Cell style={style.textEllipse}>{}</Table.Cell> */}
                  </Table.Row>
                  <Table.Row key={'b' + index}>
                    {this.count(item.classSchool)[1].map(num =>
                      <Table.Cell style={style.textEllipse}>{num}</Table.Cell>
                    )}
                    {/* {monthArr.map(month =>
                        <Table.Cell style={style.textEllipse}>{students2.filter(stu => stu.semesterYear === Number(item.entryYear) && stu.classSchool === item.classSchool).length}</Table.Cell>)} */}
                  </Table.Row>
                </>)}
              <Table.Row style={{ backgroundColor: '#D7E9F3' }}>
                <Table.Cell style={style.textEllipse}>{'正規生'}</Table.Cell>
                {firstA.map(num =>
                  <Table.Cell style={style.textEllipse}>{num}</Table.Cell>
                )}
              </Table.Row>
              <Table.Row style={{ backgroundColor: '#D7E9F3' }}>
                <Table.Cell style={style.textEllipse}>{'聽講生'}</Table.Cell>
                {firsta.map(num =>
                  <Table.Cell style={style.textEllipse}>{num}</Table.Cell>
                )}
              </Table.Row>
              <Table.Row style={{ backgroundColor: '#D7E9F3' }}>
                <Table.Cell style={style.textEllipse}>{'小計'}</Table.Cell>
                {firstS.map((num, index) =>
                  <Table.Cell style={style.textEllipse}>{firstA[index] + firsta[index]}</Table.Cell>
                )}
              </Table.Row>
              <Table.Row style={{ backgroundColor: 'black' }}>
                <Table.Cell colSpan={39} style={style.textEllipse}></Table.Cell>
              </Table.Row>
              {classroom && classroom
                .filter(classRoom => classRoom.entryYear === (Number(semesterYear) + ''))
                // .sort((a, b) => ('' + a.entryYear).localeCompare(b.entryYear))
                .sort((a, b) => ('' + a.classSchool).localeCompare(b.classSchool))
                .map((item, index) => <>
                  {monthArr.map((month, index) => {
                    secondS[index] += this.count(item.classSchool)[0][index] || 0
                    seconds[index] += this.count(item.classSchool)[1][index] || 0
                  })}
                  <Table.Row key={'a' + index}>
                    <Table.Cell style={style.textEllipse} rowSpan={2}>{item.entryYear}S {item.classSchool}</Table.Cell>
                    {this.count(item.classSchool)[0].map(num =>
                      <Table.Cell style={style.textEllipse}>{num}</Table.Cell>
                    )}
                  </Table.Row>
                  <Table.Row key={'b' + index}>
                    {this.count(item.classSchool)[1].map(num =>
                      <Table.Cell style={style.textEllipse}>{num}</Table.Cell>
                    )}
                  </Table.Row>
                  {item.entryMonth === '10' && item.entryYear === semesterYear ? <><Table.Row key={'a' + index}>
                    {['', '', '', '', '', ''].map((emp, index) =>
                      <Table.Cell style={style.textEllipse}>{}</Table.Cell>)}
                    <Table.Cell style={style.textEllipse} rowSpan={2}>{item.entryYear}A {item.classSchool}</Table.Cell>
                    {this.count(item.classSchool)[0].map(num =>
                      <Table.Cell style={style.textEllipse}>{num}</Table.Cell>
                    )}
                  </Table.Row>
                    <Table.Row key={'b' + index}>
                      {['', '', '', '', '', ''].map((emp, index) =>
                        <Table.Cell style={style.textEllipse}>{}</Table.Cell>)}
                      {this.count(item.classSchool)[1].map(num =>
                        <Table.Cell style={style.textEllipse}>{num}</Table.Cell>
                      )}
                    </Table.Row></> : null}
                </>)}
              <Table.Row style={{ backgroundColor: '#D7E9F3' }}>
                <Table.Cell style={style.textEllipse}>{'正規生'}</Table.Cell>
                {secondS.map(num =>
                  <Table.Cell style={style.textEllipse}>{num}</Table.Cell>
                )}
              </Table.Row>
              <Table.Row style={{ backgroundColor: '#D7E9F3' }}>
                <Table.Cell style={style.textEllipse}>{'聽講生'}</Table.Cell>
                {seconds.map(num =>
                  <Table.Cell style={style.textEllipse}>{num}</Table.Cell>
                )}
              </Table.Row>
              <Table.Row style={{ backgroundColor: '#D7E9F3' }}>
                <Table.Cell style={style.textEllipse}>{'在籍生総計'}</Table.Cell>
                {secondS.map((num, index) =>
                  <Table.Cell style={style.textEllipse}>{secondS[index] + seconds[index]}</Table.Cell>
                )}
              </Table.Row>
              <Table.Row style={{ backgroundColor: 'black' }}>
                <Table.Cell colSpan={39} style={style.textEllipse}></Table.Cell>
              </Table.Row>
              <Table.Row style={{ backgroundColor: '#D7E9F3' }}>
                <Table.Cell style={style.textEllipse}>{'正規生'}</Table.Cell>
                {monthArr.map((num, index) =>
                  <Table.Cell style={style.textEllipse}>{firstS[index] + firstA[index] + secondS[index]}</Table.Cell>
                )}
              </Table.Row>
              <Table.Row style={{ backgroundColor: '#D7E9F3' }}>
                <Table.Cell style={style.textEllipse}>{'聽講生'}</Table.Cell>
                {monthArr.map((num, index) =>
                  <Table.Cell style={style.textEllipse}>{firsts[index] + firsta[index] + seconds[index]}</Table.Cell>
                )}
              </Table.Row>
              <Table.Row style={{ backgroundColor: '#D7E9F3' }}>
                <Table.Cell style={style.textEllipse}>{'小計'}</Table.Cell>
                {monthArr.map((num, index) =>
                  <Table.Cell style={style.textEllipse}>{firstS[index] + firstA[index] + secondS[index] + firsts[index] + firsta[index] + seconds[index]}</Table.Cell>
                )}
              </Table.Row>
            </Table.Body>
          </Table>
          <div style={{ ...style.flexCenter, marginTop: 15 }}>
            <Header as='h3'>入学期推移表</Header>
          </div>
          <Table celled striped size='small' selectable>
            <Table.Header>
              <Table.Row textAlign='center'>
                <Table.HeaderCell style={style.textEllipse}>学期</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>総人数</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>国籍</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>男女</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>正規生/聽講生</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.studentCount(semesterArr, true).map((item, index) => (<Table.Row key={item.index}>
                <Table.Cell style={style.textEllipse}>{item.semesterYear},{item.semester}</Table.Cell>
                <Table.Cell style={style.textEllipse}>{item.semesterStudent.length}</Table.Cell>
                <Table.Cell style={style.textEllipse}>{item.nationality}</Table.Cell>
                <Table.Cell style={style.textEllipse}>{item.gender}</Table.Cell>
                <Table.Cell style={style.textEllipse}>{item.formalNum} / {item.listeningNum}</Table.Cell>
                {/* <Table.Cell style={style.textEllipse}>{studentStatus50}</Table.Cell>
                    <Table.Cell style={style.textEllipse}>{this.customizedText(row.original.students, '50')}</Table.Cell> */}
              </Table.Row>))}
            </Table.Body>
          </Table>
          <div style={{ ...style.flexCenter, marginTop: 15 }}>
            <Header as='h3'>学期推移表（含卒業及修了）</Header>
          </div>
          <Table celled striped size='small' selectable>
            <Table.Header>
              <Table.Row textAlign='center'>
                <Table.HeaderCell style={style.textEllipse}>入学期</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>総人数</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>国籍</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>男女</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>正規生/聴講生</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.studentCount(semesterArr).map((item, index) => (<Table.Row key={item.index}>
                <Table.Cell style={style.textEllipse}>
                  <p>{item.semesterYear},{item.semester}</p>
                    (入学期: {showCurrentClass(item.semesterYear, item.semester)})</Table.Cell>
                <Table.Cell style={style.textEllipse}>{item.semesterStudent.length}</Table.Cell>
                <Table.Cell style={style.textEllipse}>{item.nationality}</Table.Cell>
                <Table.Cell style={style.textEllipse}>{item.gender}</Table.Cell>
                <Table.Cell style={style.textEllipse}>{item.formalNum} / {item.listeningNum}</Table.Cell>
                {/* <Table.Cell style={style.textEllipse}>{studentStatus50}</Table.Cell>
                    <Table.Cell style={style.textEllipse}>{this.customizedText(row.original.students, '50')}</Table.Cell> */}
              </Table.Row>))}
            </Table.Body>
          </Table>
        </div>

      </>
    )
  }
}
export default TransitionReport