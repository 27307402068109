import React, { Component } from 'react';
import { Tab, ListGroup, Container, Form, Icon } from 'semantic-ui-react';

import DormInfo from './DormInfo';
import DormContract from './DormContract';

const size = 'small';
const TabRender = ({ component, ...rest }) => <Tab.Pane><Form size={size}>{React.createElement(component, { ...rest })}</Form></Tab.Pane>;
const menuTitle = (str1, str2 = '', icon = 'tty', LEN = 4) =>
  <>
    {/* <div><Icon name={icon} /></div> */}

    {/* <div style={{ marginBottom: '8px' }}>{str1}</div> */}
    {str1}
    {str2 ? <>{str2.substr(0, LEN)}</> : null}
    {str2.length >= LEN ? <><br />{str2.substr(LEN, LEN)}</> : null}
    {str2.length >= LEN * 2 ? <><br />{str2.substr(LEN * 2, LEN)}</> : null}
    {str2.length >= LEN * 3 ? <><br />{str2.substr(LEN * 3, LEN)}</> : null}
    {str2.length >= LEN * 4 ? <><br />{str2.substr(LEN * 4, LEN)}</> : null}
  </>;
export default class ListTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: props.match.params.activeIndex || 0,
      studentObj: props.doc
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.doc !== this.props.doc) {
      // console.log(nextProps.doc)
      this.setState({ studentObj: nextProps.doc });
    }
  }

  handleTabChange = (e, { activeIndex }) => {
    const { match, dormObj, roomObj } = this.props
    const { list = "1" } = match.params;
    console.log(dormObj)
    console.log(roomObj)
    this.props.history.push(`/admin/DormPage/${dormObj.objectId}/${roomObj.objectId}/${activeIndex}`);
    // const studentObj = [];
    // studentObj.push(doc);
    this.setState({ activeIndex })
  }

  panes = [
    { menuItem: { key: 'listtabmenu1', content: menuTitle('5-1-①', 'マスター情報') }, render: () => <TabRender {...this.props} component={DormInfo} /> },
    { menuItem: { key: 'listtabmenu2', content: menuTitle('5-1-②', '合約一覽') }, render: () => <TabRender {...this.props} component={DormContract} /> },
    // { menuItem: '出願書類', render: () => <TabRender {...this.props} component={CheckSheetStudent} />},
  ]

  render() {
    const { companyObj } = this.props
    const { activeIndex } = this.state;
    return (<Tab
      // fluid
      activeIndex={activeIndex} onTabChange={this.handleTabChange}
      menu={{ pointing: true, inverted: companyObj.inverted }}
      panes={this.panes} />);
  }
}