import React, { Component } from 'react'
import { Button, Icon } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import ReactFileReader from 'react-file-reader';
import { Select, MenuItem } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { RowImage } from 'views/web/RowImage';
import ModalUpdateFile from 'views/web/ModalUpdateFile'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'widget/ckeditor';
import { imgBtnCol, imgPreviewCol, exportPdf } from 'views/web/CustomMaterialTable';
import { Input, TextField } from "@material-ui/core";

const YNSel = { 'Y': 'Y', 'N': 'N' }
const verSel = { '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', '6': '6', '7': '7' }
const answerSel = { 'A': 'A', 'B': 'B', 'C': 'C', 'D': 'D' }
const parSel = { '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', '6': '6', '7': '7' }
const partOpt = [
  { key: '1', text: '1', value: '1' },
  { key: '2', text: '2', value: '2' },
  { key: '3', text: '3', value: '3' },
  { key: '4', text: '4', value: '4' },
  { key: '5', text: '5', value: '5' },
  { key: '6', text: '6', value: '6' },
  { key: '7', text: '7', value: '7' },
]
const readSel = { 'topic': '文章', 'question': '題目' }


class TestBankImport2 extends Component {
  state = {
    activeItem: 'bio',
    dataArr: [],
    exDataArr: [],
    dataObjArr: [],
    rowData: {},
    loading: true,
    loadingData: true,
    show: false,
    isFile: false,
    loading2: false,
    preview: false,
    loadingSave: false,
  }

  componentDidMount() {
    this.handleFetch()
  }

  componentWillReceiveProps(nextprops) {
    this.handleFetch(nextprops)
  }

  handleFetch = async (props = this.props) => {
    const { newData } = this.state;
    const { companyObj, verId } = props;
    this.setState({ loadingData: true })
    const testExBank = await Parse.queryData('testExBank', { companyId: companyObj.objectId, verId: verId });
    const sort = testExBank.sort((a, b) => a.number - b.number)
    this.setState({ exDataArr: sort, loadingData: false });
  }

  handleAdd = async () => {
    const { newData } = this.state;
    await Parse.saveData('testExBank', newData);
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    await Parse.saveData('testExBank', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    await Parse.deleteData('testExBank', oldData);
    this.setState({ oldData: {} })
  }

  // handleChange = (e, data) => {
  //   if (e.target.value === '1' || e.target.value === '3' || e.target.value === '4') {
  //     this.setState({ isFile: true })
  //   } else {
  //     this.setState({ isFile: false })
  //   }
  // }

  handleFiles = async (files) => {
    const dataObjArr = []
    this.setState({ previewLoading: true, preview: true });

    let reader = new FileReader();
    reader.onload = (e) => {
      const objArr = reader.result.split("\r\n\"")
      objArr.splice(0, 1);
      objArr.forEach(item => {
        const array1 = item.split('\"')
        const objArr = array1.filter(item => item !== ',')
        const obj = {
          number: objArr[0] || '',
          part: objArr[1] || '',
          topic: objArr[2] || '',
          optionA: objArr[3] || '',
          optionB: objArr[4] || '',
          optionC: objArr[5] || '',
          optionD: objArr[6] || '',
          answer: objArr[7] || '',
          group: objArr[8] || '',
        }

        dataObjArr.push({ ...obj })
      })
      this.setState({ dataObjArr, previewLoading: false });
    }
    reader.readAsBinaryString(files[0]);
  }

  handleImport = async () => {
    const { companyObj } = this.props
    const { dataObjArr, funArr, funNameArr, loading2 } = this.state
    const companyId = companyObj.objectId

    if (window.confirm('確認上傳？')) {
      this.setState({ loading2: true })
      const portfolios = await Parse.queryData('portfolios', { companyId })
      const fundConfigure = await Parse.queryData('fundConfigure', { companyId, PriceDate: dataObjArr[0].PriceDate })

      const updateObjArr = []; // 要新增到 fundConfigure 的資料
      dataObjArr.forEach(item => {
        if (fundConfigure.find(item2 =>
          (item.PriceDate === item2.PriceDate &&
            item.ExtractTime === item2.ExtractTime &&
            item.UnitType === item2.UnitType &&
            item.FundCurrencyValue === item2.FundCurrencyValue)
          && (item.LifePlanReference === item2.accountNum || item.PolicyId === item2.accountNum2))) {
          console.log('重複的資料')
        } else if (updateObjArr.indexOf(item) === -1) {
          updateObjArr.push(item)
        }
      })

      const promises = [];
      updateObjArr.forEach(() => {
        const db = Parse.Object.extend("fundConfigure");
        const newDoc = new db();
        promises.push(newDoc.save());
      })
      const docArr = await Promise.all(promises).catch(error => console.log("error", error));

      const promises2 = [];
      docArr.forEach((doc, i) => {
        const portfolioRef = portfolios.find(item => updateObjArr[i].LifePlanReference === item.account_num || updateObjArr[i].PolicyId === item.account_num2)
        // console.log(portfolioRef)
        delete updateObjArr[i].PolicyId
        const obj = {
          ...updateObjArr[i],
          id: doc.id,
          _id: doc.id,
          objectId: doc.id,
          accountNum: portfolioRef.account_num || '',
          accountNum2: portfolioRef.account_num2 || '',
          companyId: companyObj.objectId || '',
          companyName: companyObj.name || '',
          // bgId: portfolioRef.bgId || '',
          // bgText: portfolioRef.bgText || '',
          order: i,
          clientId: portfolioRef.client_id || '',
          portfoliosId: portfolioRef.objectId || '',
        }
        // console.log(obj)
        promises2.push(Parse.saveData('fundConfigure', obj));
      })
      await Promise.all(promises2).catch(error => console.log("error", error));

      // 上面已經把所有該存到 fundConfigure 的東西，都存進去了
      // return;
      const fund1 = await Parse.queryData('fund1', { companyId: companyObj.objectId });
      const fund2 = await Parse.queryData('fund2', { companyId: companyObj.objectId, PriceDate: dataObjArr[0].PriceDate });

      // console.log('fund1', fund1)
      // console.log('fund2', fund2)
      // console.log('funNameArr', funNameArr)
      // console.log('funArr', funArr)

      // 把新的 FundDescription 寫到 fund1 裡面
      if (funNameArr.length) {
        const promises = [];
        funNameArr.forEach(item => {
          if (!fund1.find(item2 => item2.FundDescription === item)) {
            const db = Parse.Object.extend("fund1");
            const newDoc = new db();
            promises.push(newDoc.save().then(async (doc) => {
              const obj = {
                id: doc.id,
                _id: doc.id,
                objectId: doc.id,
                companyId: companyObj.objectId || '',
                companyName: companyObj.name || '',
                FundDescription: item
              }
              await Parse.saveData('fund1', obj);
            }));
          }
        });
        await Promise.all(promises).catch(error => console.log("error", error));
      }

      // 把新的 fundConfigure 寫到 fund2 裡面
      if (funArr.length) {
        const promises = [];
        funArr.forEach(item => {
          if (!fund2.find(item2 => item2.FundDescription === item.FundDescription && item2.PriceDate === item.PriceDate)) {
            const db = Parse.Object.extend("fund2");
            const newDoc = new db();
            promises.push(newDoc.save().then(async (doc) => {
              const obj = {
                id: doc.id,
                _id: doc.id,
                objectId: doc.id,
                companyId: companyObj.objectId || '',
                companyName: companyObj.name || '',
                ...item
              }
              await Parse.saveData('fund2', obj);
            }));
          }
        });
        await Promise.all(promises).catch(error => console.log("error", error));
        this.setState({
          dataObjArr: [],
          funArr: [],
          funNameArr: [],
          loading: false,
          loading2: false
        }, () => { window.alert('上傳成功') })
      }
    } else {
      this.setState({ loading: false })
    }
  }

  handleClose = () => {
    this.setState({
      preview: false,
      show: false,
    });
  }

  handleSave = () => {
    this.setState({ show: false, loadingSave: false })
  }

  handleCancel = () => {
    const { rowData } = this.state
    this.state.onRowDataChange({ ...rowData, article: rowData.article })
    this.setState({ show: false, loadingSave: false })
  }

  handleBlur = (name, value) => {
    // const { rowData } = this.state
    this.setState({ [name]: value })
  }

  render() {
    const { exDataArr, loadingData, loading2, dataObjArr, preview, previewLoading, show, rowData, loadingSave } = this.state
    const { companyObj, verId } = this.props
    return (
      <>
        <MaterialTable
          isLoading={loadingData}
          localization={localization()}
          components={{
            Toolbar: props => (<div>
              <div style={{ width: '200px', float: 'right', textAlign: 'right', padding: '15px' }}>
                <Button.Group>
                  <ReactFileReader handleFiles={this.handleFiles} fileTypes={'.csv'}>
                    <Button color='orange' size='small' content='讀檔' loading={previewLoading}></Button>
                  </ReactFileReader>
                </Button.Group>
              </div>
              <MTableToolbar {...props} />
              <div style={{ clear: 'both' }}></div>
            </div>),
          }}
          columns={[
            { width: 80, title: '題號', field: 'number' },
            {
              width: 80, title: 'Part', field: 'part', lookup: parSel,
              render: rowData => <>{rowData.part}</>,
              editComponent: props => {
                console.log(props.rowData, props.rowData.part)
                return <Select
                  disabled={props.rowData.verId !== undefined ? true : false}
                  value={props.rowData.part}
                  onChange={e => {
                    const partId = e.target.value
                    // this.handleChange(e)
                    if (partId === '2') {
                      props.onRowDataChange({ ...props.rowData, part: partId, topic: 'Mark your answer on your answer sheet.' })
                    } else {
                      props.onRowDataChange({ ...props.rowData, part: partId })
                    }
                  }}
                >
                  {partOpt.map((item, index) => (<MenuItem key={item.key} value={item.value}>{item.value}</MenuItem>))}
                </Select>
              }
            },
            {
              width: 80, title: '', field: 'file1',
              headerStyle: { textAlign: 'center' },
              render: rowData => <></>,
              editComponent: props => {
                if (props.rowData.part === '1' || props.rowData.part === '3' || props.rowData.part === '4') {
                  return <><ModalUpdateFile isImage='1' fieldname={'file1'} rowData={props.rowData} dbname='testExBank' onRowDataChange={props.onRowDataChange} /></>
                }
                else if (props.rowData.part === '6' || props.rowData.part === '7') {
                  return <><IconButton onClick={e => this.setState({ show: true, onRowDataChange: props.onRowDataChange, rowData: props.rowData })}><Menu size='small' /></IconButton></>
                } else {
                  return <></>
                }
              }
            },
            {
              width: 80, title: '圖片', field: 'file1',
              render: rowData => {
                if ((rowData.part === '1' || rowData.part === '3' || rowData.part === '4' || rowData.part === '6' || rowData.part === '7') && rowData.file1) {
                  return <><RowImage rowData={rowData} field={'file1'} /></>
                }
                // else if (rowData.part === '6' || rowData.part === '7') {
                //   return <><TextField value={rowData.article} multiline disabled={true} /></>
                // }
                else {
                  return <></>
                }
              },
              editComponent: props => {
                if ((props.rowData.part === '1' || props.rowData.part === '3' || props.rowData.part === '4' || props.rowData.part === '6' || props.rowData.part === '7') && props.rowData.file1) {
                  return <><RowImage rowData={props.rowData} field={'file1'} /></>
                }
                // else if (props.rowData.part === '6' || props.rowData.part === '7') {
                //   return <><TextField value={props.rowData.article} multiline disabled={true} /></>
                // }
                else {
                  return <></>
                }
              }
            },

            {
              width: 80, title: '', field: 'file2',
              headerStyle: { textAlign: 'center' },
              render: rowData => <></>,
              editComponent: props => {
                if (props.rowData.part === '1' || props.rowData.part === '2' || props.rowData.part === '3' || props.rowData.part === '4') {
                  return <><ModalUpdateFile isImage='2' fieldname='file2' rowData={props.rowData} dbname='testExBank' onRowDataChange={props.onRowDataChange} /></>
                } else {
                  return <></>
                }
              }
            },
            {
              width: 80, title: '音檔', field: 'file2',
              render: rowData => {
                if ((rowData.part === '1' || rowData.part === '2' || rowData.part === '3' || rowData.part === '4') && rowData.file2) {
                  if (rowData && rowData.file2) {
                    return <><a href={rowData.file2._url || rowData.file2.url} target="_blank" rel="noopener noreferrer">{rowData.file2_name}</a></>
                  } else {
                    return <></>
                  }
                } else {
                  return <></>
                }
              },
              editComponent: props => {
                if ((props.rowData.part === '1' || props.rowData.part === '2' || props.rowData.part === '3' || props.rowData.part === '4') && props.rowData.file2) {
                  if (props.rowData && props.rowData.file2) {
                    return <><a href={props.rowData.file2._url || props.rowData.file2.url} target="_blank" rel="noopener noreferrer">{props.rowData.file2_name}</a></>
                  } else {
                    return <></>
                  }

                } else {
                  return <></>
                }
              }
            },

            {
              width: 80, title: '', field: 'article',
              headerStyle: { textAlign: 'center' },
              render: rowData => <></>,
              editComponent: props => {
                if (props.rowData.part === '2' || props.rowData.part === '3' || props.rowData.part === '4' || props.rowData.part === '6' || props.rowData.part === '7') {
                  return <><IconButton onClick={e => this.setState({ show: true, onRowDataChange: props.onRowDataChange, rowData: props.rowData })}><Menu size='small' /></IconButton></>
                } else {
                  return <></>
                }
              }
            },
            {
              width: 80, title: '文章', field: 'article',
              render: rowData => {
                if ((rowData.part === '2' || rowData.part === '3' || rowData.part === '4' || rowData.part === '6' || rowData.part === '7') && rowData.article) {
                  if (rowData && rowData.article) {
                    return <><TextField value={rowData.article} multiline disabled={true} /></>
                  } else {
                    return <></>
                  }
                } else {
                  return <></>
                }
              },
              editComponent: props => {
                if ((props.rowData.part === '2' || props.rowData.part === '3' || props.rowData.part === '4' || props.rowData.part === '6' || props.rowData.part === '7') && props.rowData.article) {
                  if (props.rowData && props.rowData.article) {
                    return <><TextField value={props.rowData.article} multiline disabled={true} /></>
                  } else {
                    return <></>
                  }

                } else {
                  return <></>
                }
              }
            },
            // imgPreviewCol({ title: '檔案', field: 'file1' }),
            {
              width: 80, title: '題幹', field: 'topic',
              render: rowData => <>{rowData.part === '1' ? <><RowImage rowData={rowData} field={'file1'} /></> : <TextField value={rowData.topic} multiline disabled={true} />}</>,
              editComponent: props => {
                console.log(props.rowData)
                if (props.rowData.part === '1') {
                  return <><RowImage rowData={props.rowData} field={'file1'} /></>
                } else if (props.rowData.part === '2') {
                  return 'Mark your answer on your answer sheet.'
                } else if (props.rowData.part === '3') {
                  return <><TextField value={props.rowData.topic} onChange={e => props.onRowDataChange({ ...props.rowData, topic: e.target.value })} multiline /></>
                } else if (props.rowData.part === '4') {
                  return <><TextField value={props.rowData.topic} onChange={e => props.onRowDataChange({ ...props.rowData, topic: e.target.value })} multiline /></>
                } else if (props.rowData.part === '5') {
                  return <><TextField value={props.rowData.topic} onChange={e => props.onRowDataChange({ ...props.rowData, topic: e.target.value })} multiline /></>
                } else if (props.rowData.part === '6') {
                  return <></>
                } else if (props.rowData.part === '7') {
                  return <><TextField value={props.rowData.topic} onChange={e => props.onRowDataChange({ ...props.rowData, topic: e.target.value })} multiline /></>
                } else {
                  return <></>
                }
              }
            },
            {
              width: 80, title: '(A)', field: 'optionA',
              render: rowData => <><TextField value={rowData.optionA} multiline disabled={true} /></>,
              editComponent: props => <><TextField value={props.rowData.optionA} onChange={e => props.onRowDataChange({ ...props.rowData, optionA: e.target.value })} multiline /></>
            },
            {
              width: 80, title: '(B)', field: 'optionB',
              render: rowData => <><TextField value={rowData.optionB} multiline disabled={true} /></>,
              editComponent: props => <><TextField value={props.rowData.optionB} onChange={e => props.onRowDataChange({ ...props.rowData, optionB: e.target.value })} multiline /></>
            },
            {
              width: 80, title: '(C)', field: 'optionC',
              render: rowData => <><TextField value={rowData.optionC} multiline disabled={true} /></>,
              editComponent: props => <><TextField value={props.rowData.optionC} onChange={e => props.onRowDataChange({ ...props.rowData, optionC: e.target.value })} multiline /></>
            },
            {
              width: 80, title: '(D)', field: 'optionD',
              render: rowData => <><TextField value={rowData.optionD} multiline disabled={true} /></>,
              editComponent: props => <><TextField value={props.rowData.optionD} onChange={e => props.onRowDataChange({ ...props.rowData, optionD: e.target.value })} multiline /></>
            },
            { width: 40, title: '答案', field: 'answer', lookup: answerSel },
            // { width: 80, title: 'groupLead', field: 'groupLead', lookup: YNSel },
            { width: 40, title: 'group', field: 'group' },
            // ...columns(isFile, this.handleFile)
          ]}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportPdf: (columns, data) => exportPdf(columns, data, '題目總覽'),
            pageSize: 10,
            search: false,
            tableLayout: 'fixed'
          }}
          // parentChildData={(row, rows) => {
          //   if (row.groupLead === 'N' && rows.find(item => item.group === row.group)) {
          //     return rows.find(item => item.group === row.group)
          //   }
          // }}
          data={exDataArr}
          title="題目總覽"
          // actions={[
          //   {
          //     icon: 'view_headline',
          //     tooltip: '查看',
          //     onClick: (event, rowData) => this.handleData(rowData)
          //   }
          // ]}
          editable={{
            onRowAdd: newData =>
              new Promise(async (resolve, reject) => {
                const data = [...exDataArr];
                const db = Parse.Object.extend("testExBank");
                const newDoc = new db();
                const doc = await newDoc.save();
                console.log(newData)
                let obj = {
                  ...newData,
                  id: doc.id,
                  _id: doc.id,
                  objectId: doc.id,
                  companyId: companyObj.objectId,
                  companyName: companyObj.name,
                  verId,
                }
                data.push(obj);
                this.setState({ exDataArr: data, newData: obj }, () => resolve());
              }).then(() => this.handleAdd()),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const data = [...exDataArr];
                const index = data.indexOf(oldData);
                data[index] = newData;
                // handleState('people0', data);
                this.setState({ exDataArr: data, newData, oldData }, () => resolve());
              }).then(() => this.handleUpdate()),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                let data = [...exDataArr];
                const index = data.indexOf(oldData);
                data.splice(index, 1);
                // handleState('people0', data);
                this.setState({ exDataArr: data, oldData }, () => resolve());
              }).then(() => this.handleDelete()),
          }}
        />
        <br />
        <br />
        <Modal
          isOpen={preview}
          toggle={this.handleClose}
          size="lg"
        // className="mh-90 h-90 mw-100 w-90"
        // style={{ paddingTop: '0px' }}
        // style={{ maxWidth: '1600px', width: '80%' }}
        >
          <ModalHeader className="justify-content-center">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
              <span aria-hidden="true">×</span>
            </button>
              預覽
        </ModalHeader>
          <ModalBody>
            <MaterialTable
              localization={localization()}
              columns={[
                { width: 80, title: '題號', field: 'number', type: 'numeric' },
                {
                  width: 80, title: 'Part', field: 'part', lookup: parSel,
                  render: rowData => <>{rowData.part}</>,
                  editComponent: props => {
                    console.log(props.rowData, props.rowData.part)
                    return <Select
                      disabled={props.rowData.verId !== undefined ? true : false}
                      value={props.rowData.part}
                      onChange={e => {
                        const partId = e.target.value
                        // this.handleChange(e)
                        if (partId === '2') {
                          props.onRowDataChange({ ...props.rowData, part: partId, topic: 'Mark your answer on your answer sheet.' })
                        } else {
                          props.onRowDataChange({ ...props.rowData, part: partId })
                        }
                      }}
                    >
                      {partOpt.map((item, index) => (<MenuItem key={item.key} value={item.value}>{item.value}</MenuItem>))}
                    </Select>
                  }
                },
                // imgBtnCol({ field: 'file1', }),
                imgPreviewCol({ title: '檔案', field: 'file1', }),
                {
                  width: 80, title: '題幹', field: 'topic',
                  render: rowData => <>{rowData.part === '1' ? <></> : <TextField value={rowData.topic} multiline disabled={true} />}</>,
                  editComponent: props => {
                    console.log(props.rowData)
                    if (props.rowData.part === '1') {
                      return <></>
                    } else if (props.rowData.part === '2') {
                      return 'Mark your answer on your answer sheet.'
                    } else if (props.rowData.part === '3') {
                      return <><Input value={props.rowData.topic} onChange={e => props.onRowDataChange({ ...props.rowData, topic: e.target.value })} multiline /></>
                    } else if (props.rowData.part === '4') {
                      return <><TextField value={props.rowData.topic} onChange={e => props.onRowDataChange({ ...props.rowData, topic: e.target.value })} multiline /></>
                    } else if (props.rowData.part === '5') {
                      return <><TextField value={props.rowData.topic} onChange={e => props.onRowDataChange({ ...props.rowData, topic: e.target.value })} multiline /></>
                    } else if (props.rowData.part === '6') {
                      return <></>
                    } else if (props.rowData.part === '7') {
                      return <><TextField value={props.rowData.topic} onChange={e => props.onRowDataChange({ ...props.rowData, topic: e.target.value })} multiline /></>
                    } else {
                      return <></>
                    }
                  }
                },
                {
                  width: 80, title: '(A)', field: 'optionA',
                  render: rowData => <><TextField value={rowData.optionA} multiline disabled={true} /></>,
                  editComponent: props => <><TextField value={props.rowData.optionA} onChange={e => props.onRowDataChange({ ...props.rowData, optionA: e.target.value })} multiline /></>
                },
                {
                  width: 80, title: '(B)', field: 'optionB',
                  render: rowData => <><TextField value={rowData.optionB} multiline disabled={true} /></>,
                  editComponent: props => <><TextField value={props.rowData.optionB} onChange={e => props.onRowDataChange({ ...props.rowData, optionB: e.target.value })} multiline /></>
                },
                {
                  width: 80, title: '(C)', field: 'optionC',
                  render: rowData => <><TextField value={rowData.optionC} multiline disabled={true} /></>,
                  editComponent: props => <><TextField value={props.rowData.optionC} onChange={e => props.onRowDataChange({ ...props.rowData, optionC: e.target.value })} multiline /></>
                },
                {
                  width: 80, title: '(D)', field: 'optionD',
                  render: rowData => <><TextField value={rowData.optionD} multiline disabled={true} /></>,
                  editComponent: props => <><TextField value={props.rowData.optionD} onChange={e => props.onRowDataChange({ ...props.rowData, optionD: e.target.value })} multiline /></>
                },
                { width: 40, title: '答案', field: 'answer', lookup: answerSel },
                // { width: 80, title: 'groupLead', field: 'groupLead', lookup: YNSel },
                { width: 40, title: 'group', field: 'group' },
                // ...columns(isFile, this.handleFile)
              ]}
              options={{
                addRowPosition: "first",
                exportButton: false,
                pageSize: 20,
                search: false,
                tableLayout: 'fixed'
              }}
              // parentChildData={(row, rows) => {
              //   if (row.groupLead === 'N' && rows.find(item => item.group === row.group)) {
              //     return rows.find(item => item.group === row.group)
              //   }
              // }}
              data={dataObjArr}
              title="題庫匯入預覽"
            // actions={[
            //   {
            //     icon: 'view_headline',
            //     tooltip: '查看',
            //     onClick: (event, rowData) => this.handleData(rowData)
            //   }
            // ]}
            />
          </ModalBody>
          <ModalFooter style={{ padding: '16px' }}>
            <Button loading={loading2} color='blue' size='small' content='匯入' style={{ marginLeft: '5px' }}
              disabled={dataObjArr.length === 0}
              onClick={this.handleImport}
            />
            <Button icon='x' onClick={this.handleClose}>關閉</Button>
          </ModalFooter>
        </Modal>


        <Modal isOpen={show} toggle={this.handleClose} centered={false}>
          <ModalHeader>文章內容
           <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
          </ModalHeader>
          <ModalBody>
            {/* <JoditEditor
               // ref={editor}
               config={{
                 height: 400,
                 readonly: !isEdit,
                 uploader: { insertImageAsBase64URI: true }
               }}
               name='Body'
               value={rowData ? rowData.Body : null}
               // config={config}
               tabIndex={2} // tabIndex of textarea
               onBlur={newContent => this.handleBlur("Body", newContent)}
             /> */}
            <CKEditor
              editor={ClassicEditor}
              data={rowData ? rowData.article : null}
              onInit={editor => {
                // You can store the "editor" and use when it is needed.
                console.log('Editor is ready to use!', editor);
                // editor.isReadOnly = true;
                // this.setState({ editor })
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                // this.handleBlur("article", data)
                this.state.onRowDataChange({ ...rowData, article: data })
                // console.log({ event, editor, data });
              }}
            // onBlur={(event, editor) => {
            //   console.log('Blur.', editor);
            // }}
            // onFocus={(event, editor) => {
            //   console.log('Focus.', editor);
            // }}
            />
          </ModalBody>
          <ModalFooter style={{ padding: '16px' }}>
            <Button inverted color='green' icon='save' content='儲存' onClick={() => this.setState({ loadingSave: true }, () => this.handleSave())} loading={loadingSave} />
            <Button inverted color='red' icon='delete' content='取消' onClick={() => this.setState({ loadingSave: true }, () => this.handleCancel())} loading={loadingSave} />
          </ModalFooter>
        </Modal>
      </>);
  }
}

export default TestBankImport2;