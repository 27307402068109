import React, { Component } from 'react'
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
import { Container, Header, Form, Icon, Table, Button, Select } from 'semantic-ui-react'
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';
import { semesterArr } from '../../../widget/define/define'
import Parse from '../../../widget/parse'

export default class RecommenderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterForm: {
        searchText: '',
        selClass: '0',
        year: (props.semester === 'A' ? `${Number(props.semesterYear) + 1}` : props.semesterYear),
        semes: (props.semester === 'A' ? 'S' : 'A'),
        yearsemester: (props.semester === 'A' ? `${Number(props.semesterYear) + 1},S` : `${props.semesterYear},A`),
        // year: '2019',
        // semes: 'A',
        // yearsemester: '2019,A',
        applyResult: '0',
      },
      students: []

    };
  }
  async componentDidMount() {
    // console.log('componentDidMount');
    const { companyObj } = this.props
    const data = await Parse.queryData('Students', { companyId: companyObj.objectId });
    this.setState({ students: data });

  }

  handleChange = (e, { name, value }) => {
    const { match, history } = this.props
    const { filterForm } = this.state
    const { _id, list } = match.params;

    if (name === 'yearsemester') {
      const year = value.substring(0, 4);
      const semes = value.substring(5);
      const yearsemester = value;
      this.setState({ filterForm: { ...filterForm, year, semes, selClass: '0', yearsemester, searchText: '' } });
      this.setState({ value })
    }
  }

  render() {
    // const { students } = this.props;
    const { filterForm, students } = this.state
    const { yearsemester, applyResult, selClass, searchText } = filterForm;
    console.log(students)
    const result = [...new Set(students.map(item => item.agentCenterName))];

    const arr = result.reduce((result, agentCenterName) => {
      const stu = students.filter(item => item.agentCenterName === agentCenterName && item.agentCenterName && item.agentCenterName !== '無');
      if (stu.length) {
        const stuArr = stu.map(item => ({
          _id: item._id,
          recruitNum: item.recruitNum,
          studentId: item.studentId,
          semesterYear: item.semesterYear.substr(-2),
          semester: item.semester,
          jpnName: item.jpnName
        }))

        const stuData = stuArr.map(item =>  // pdf 印刷撈資料
          `${item.semesterYear}(${item.semester})${item.recruitNum} ${item.jpnName}`
        ).join('\n')

        result.push({
          agentCenterName,
          agentCenterNumber: stu[0].agentCenterNumber || '',
          agentCenterCount: stu.length,
          stuArr,
          stuData  // pdf 印刷
        })
      }
      return result;
    }, [])

    const arrPdf = arr.reduce((result, item) => {
      if (item.stuArr.length) {
        result.push(item)
      }
      return result
    }, []).filter(item => item.stuArr.every(item1 => item1.semesterYear === filterForm.year.substring(2) && item1.semester === filterForm.semes)).map(item => item)

    return (<div style={{ padding: '10px', overflowX: 'scroll' }}>
      <div style={{ marginBottom: '16px' }}>
        {/* <ReactToPrint
          trigger={(e) => <Button
            floated='right'
            content='印刷'
            color='orange' icon='print'
            size='small'
          />}
          content={() => this.componentRef}
          pageStyle={"@page { size: auto;  margin:5mm; }*{font-family: 'Noto Serif JP', serif;}"}
        /> */}
        <ModalPdf
          {...this.props}
          codekey='recommendResultListPdf'
          params={arrPdf}
          yearsemester={yearsemester}
        />
        <ExportTableToExcelButton floated='right' />
        <Select style={{
          marginBottom: '4px',
          maxWidth: '100px',
        }} compact
          floated='right'
          fluid
          options={semesterArr}
          onChange={this.handleChange}
          // disabled={isEdit}
          value={yearsemester}
          name='yearsemester' />
      </div>
      <h2 style={{ float: 'left', marginTop: 0 }}>{'推薦先実績一覧表'}</h2>

      <div className="container">
        <Table id="excel" sortable celled structured size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>会社名</Table.HeaderCell>
              <Table.HeaderCell>編号</Table.HeaderCell>
              <Table.HeaderCell>計数</Table.HeaderCell>
              <Table.HeaderCell>学生</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {arr.reduce((result, item) => {
              if (item.stuArr.length) {
                result.push(item)
              }
              return result
            }, []).map((item, index) => item.stuArr.every(item1 => item1.semesterYear === filterForm.year.substring(2) && item1.semester === filterForm.semes) ? <Table.Row key={`arrmap${index}`}>
              <Table.Cell>{item.agentCenterName}</Table.Cell>
              <Table.Cell>{item.agentCenterNumber}</Table.Cell>
              <Table.Cell>{item.agentCenterCount}</Table.Cell>
              <Table.Cell>
                {item.stuArr.length ? item.stuArr.map((item2, key2) =>
                  item2.semesterYear === filterForm.year.substring(2) && item2.semester === filterForm.semes ?
                    <span key={`stuarr${key2}`}>
                      {item2.semesterYear}({item2.semester}){item2.recruitNum} {item2.jpnName}&nbsp;&nbsp;&nbsp;
                  </span> : null) : null}
              </Table.Cell>
            </Table.Row>
              : null)}
            {arr.reduce((result, item) => {
              if (item.stuArr.length && item.stuArr.every(item1 => item1.semesterYear === filterForm.year.substring(2) && item1.semester === filterForm.semes)) {
                result.push(item.stuArr)
              }
              return result
            }, []).length === 0 ? <Table.Row><Table.Cell textAlign='center' colSpan='4'>(資料無し)</Table.Cell></Table.Row> : null}
          </Table.Body>
        </Table>

      </div>
    </div>)
  }
}