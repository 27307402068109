import React, { Component } from 'react';
import { Confirm } from 'semantic-ui-react'
import { DragDropContext } from 'react-beautiful-dnd';
import BeautifulDnd from './BeautifulDnd'

import '../../../widget/define/array';
import FormSettingsModal from './FormSettingsModal';

export default class HorizontalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSet: props.formSet,
      unsub: null,
      open: false,
      setting: {}, // 原始的東西
      parentFolderObj: {},
      confirm: false,
      loading: false
    };
  }

  onDragEnd = (result) => {
    if (!result || !result.destination) {
      return;
    }

    if (result.source.index === result.destination.index &&
      result.source.droppableId === result.destination.droppableId) { }
    else {
      console.log("onDragEnd", result);
      /*
        I20180813-00:44:32.751(8)? onDragEnd
        I20180813-00:44:32.751(8)? { draggableId: 'fFP7firxxdfTC3Syq',
        I20180813-00:44:32.752(8)?   type: 'DEFAULT',
        I20180813-00:44:32.752(8)?   source: { index: 0, droppableId: 'root' },
        I20180813-00:44:32.752(8)?   destination: { droppableId: 'root', index: 1 },
        I20180813-00:44:32.752(8)?   reason: 'DROP' }
      */

      const { firebase } = this.props;
      const { formSet } = this.state;
      const formSetNew = [...formSet];

      var promises = [];
      const db = firebase.firestore()
      var batch = db.batch();

      let settingArr = [];
      //  一定是不一樣的時候 才會進來的
      if (result.destination.droppableId === result.source.droppableId) {
        // 如果是在同一個目錄下
        if (result.destination.droppableId === 'root') {
          settingArr = formSet.filter(item => item.level === 0).sort((a, b) => a.orderId - b.orderId);
        } else {
          settingArr = formSet.filter(item => item.parentFolderId === result.source.droppableId).sort((a, b) => a.orderId - b.orderId);
        }
        settingArr.arraymove(result.source.index, result.destination.index);

        for (let i = 0; i < settingArr.length; i++) {
          const setting = settingArr[i];

          // 同一個目錄的話 只要調順序就好 level不用調整
          const ref = db.doc(`/FormSettings/${setting._id}`);
          batch.update(ref, { orderId: i });

          const index = formSetNew.findIndex((item => item._id === setting._id));
          formSetNew[index].orderId = i;
          // console.log(setting._id, setting.label, 'orderId', i);
        }
      } else {
        // 不同目錄的
        const item = formSet.find(item => item._id === result.draggableId);
        if (result.destination.droppableId === 'root') {
          item.parentFolderId = '';
          item.level = 0;
          settingArr = formSet.filter(item => item.level === 0).sort((a, b) => a.orderId - b.orderId);
        } else {
          const parent = formSet.find(item => item._id === result.destination.droppableId);
          item.parentFolderId = result.destination.droppableId;
          item.level = parent.level + 1;
          settingArr = formSet.filter(item => item.parentFolderId === result.destination.droppableId).sort((a, b) => a.orderId - b.orderId);
        }
        settingArr.splice(result.destination.index, 0, item);

        for (let i = 0; i < settingArr.length; i++) {
          const setting = settingArr[i];

          const ref = db.doc(`/FormSettings/${setting._id}`);
          batch.update(ref, {
            level: item.level,
            parentFolderId: item.parentFolderId,
            orderId: i,
          });

          const index = formSetNew.findIndex((item => item._id === setting._id));
          formSetNew[index].level = item.level;
          formSetNew[index].parentFolderId = item.parentFolderId;
          formSetNew[index].orderId = i;
          // console.log(setting._id, setting.label, 'orderId', i, 'level', item.level, 'parentFolderId', item.parentFolderId);
        }
      }

      this.setState({ formSet: formSetNew });

      var promise = batch.commit();
      promises.push(promise);
      // batch = db.batch();

      Promise.all(promises).then(results => {
        // console.log('all promises were done')
      }).catch(error => { console.log(error) });
    }
  };

  handleSet = (form) => {
    const { firebase } = this.props;
    const db = firebase.firestore()

    const { formSet } = this.state;
    const formSetNew = [...formSet];

    if (form && form._id) { // update
      const index = formSetNew.findIndex((item => item._id === form._id));
      formSetNew[index] = { ...formSetNew[index], ...form };
      this.setState({ loading: true, setting: form });
      db.doc(`/FormSettings/${form._id}`).update(form).then(() => this.setState({ formSet: formSetNew, open: false, loading: false }));
    } else { // create
      let ref = db.collection('/FormSettings').doc();
      if (ref.id) {
        // console.log(ref.id)
        form._id = ref.id;

        if (form.level === 0) {
          form.orderId = formSet.filter(item => item.level === 0).length;
        } else {
          form.orderId = formSet.filter(item => item.parentFolderId === form.parentFolderId).length;
        }
        console.log('handleSet', form);
        formSetNew.push(form);
        db.doc(`/FormSettings/${form._id}`).set(form).then(() => this.setState({ formSet: formSetNew, setting: form, open: false, loading: false }));
      }
    }
  }

  handleRemove = () => {
    const { firebase } = this.props
    const { setting, formSet } = this.state;
    const db = firebase.firestore()

    const formSetNew = [...formSet];
    const index = formSetNew.findIndex(item => item._id === setting._id);
    formSetNew.splice(index, 1);

    db.doc(`/FormSettings/${setting._id}`).delete()
      .then(() => this.setState({ formSet: formSetNew, setting: {}, confirm: false, open: false, loading: false }));
  }

  handleDelete = () => {
    this.setState({ confirm: true });
  }

  hideConfirm = () => this.setState({ confirm: false });

  handleOpen = (open, setting, parentFolderObj) => {
    this.setState({ open, setting, parentFolderObj, loading: false });
  }

  render() {
    const { formSet, open, setting, parentFolderObj, loading } = this.state

    return (<>
      <section className="d-flex flex-row" style={{ padding: '5px' }}>
        <DragDropContext onDragEnd={this.onDragEnd}  >
          <BeautifulDnd
            {...this.props}
            formSet={formSet}
            level={0}
            parentFolderObj={{}}
            handleOpen={this.handleOpen}
          />
        </DragDropContext>
      </section>
      <FormSettingsModal
        {...this.props}
        open={open}
        onHide={() => this.handleOpen(false)}
        setting={setting}
        parentFolderObj={parentFolderObj}
        handleSet={this.handleSet}
        handleDelete={this.handleDelete}
        loading={loading}
      />
      <Confirm
        header='削除確認'
        content='削除確認?'
        cancelButton='いいえ'
        confirmButton='はい'
        open={this.state.confirm}
        onCancel={this.hideConfirm}
        onConfirm={this.handleRemove}
        size='mini'
        centered={false} />
    </>);
  }
}