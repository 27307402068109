import React from 'react'
import { withCookies } from 'react-cookie';
// import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

import PageHeader from '../webcomponent/PageHeader';
import Common0 from '../webcomponent/Common0';
import AlbumTest from '../webcomponent/AlbumTest';
import QuickTest from '../webcomponent/QuickTest';
import Test from '../webcomponent/Test';
import FileDownload from '../webcomponent/FileDownload';
import Cooperation from '../webcomponent/Cooperation';
import RecordInquire from '../webcomponent/RecordInquire';
import TransactionRecord from '../webcomponent/TransactionRecord';
import Toeic from '../webcomponent/Toeic';
import Toeic1 from '../webcomponent/Toeic1';
import SectionCourse from '../webcomponent/SectionCourse';
import SectionSlider from '../webcomponent/SectionSlider';
import SectionYearPlan from '../webcomponent/SectionYearPlan';
import SectionHeader1 from '../webcomponent/SectionHeader1';
import SectionHeader2 from '../webcomponent/SectionHeader2';
import SectionHeader3 from '../webcomponent/SectionHeader3';
import SectionHeader4 from '../webcomponent/SectionHeader4';
import SectionHeader5 from '../webcomponent/SectionHeader5';
import SectionFeature1 from '../webcomponent/SectionFeature1';
import SectionFeature2 from '../webcomponent/SectionFeature2';
import SectionFeature3 from '../webcomponent/SectionFeature3';
import SectionFeature4 from '../webcomponent/SectionFeature4';
import SectionFeature5 from '../webcomponent/SectionFeature5';
import SectionBlog1 from '../webcomponent/SectionBlog1';
import SectionBlog2 from '../webcomponent/SectionBlog2';
import SectionBlog3 from '../webcomponent/SectionBlog3';
import SectionBlog4 from '../webcomponent/SectionBlog4';
import SectionBlog5 from '../webcomponent/SectionBlog5';
import SectionBlog6 from '../webcomponent/SectionBlog6';
import SectionTeam1 from '../webcomponent/SectionTeam1';
import SectionTeam2 from '../webcomponent/SectionTeam2';
import SectionTeam3 from '../webcomponent/SectionTeam3';
import SectionTeam4 from '../webcomponent/SectionTeam4';
import SectionTeam5 from '../webcomponent/SectionTeam5';
import SectionProject1 from '../webcomponent/SectionProject1';
import SectionProject2 from '../webcomponent/SectionProject2';
import SectionProject3 from '../webcomponent/SectionProject3';
import SectionProject4 from '../webcomponent/SectionProject4';
import SectionPricing1 from '../webcomponent/SectionPricing1';
import SectionPricing2 from '../webcomponent/SectionPricing2';
import SectionPricing3 from '../webcomponent/SectionPricing3';
import SectionPricing4 from '../webcomponent/SectionPricing4';
import SectionPricing5 from '../webcomponent/SectionPricing5';
import SectionTestimonials1 from '../webcomponent/SectionTestimonials1';
import SectionTestimonials2 from '../webcomponent/SectionTestimonials2';
import SectionTestimonials3 from '../webcomponent/SectionTestimonials3';
import SectionContactUs1 from '../webcomponent/SectionContactUs1';
import SectionContactUs2 from '../webcomponent/SectionContactUs2';
import SectionFooterAreas1 from '../webcomponent/SectionFooterAreas1';
import SectionFooterAreas2 from '../webcomponent/SectionFooterAreas2';
import SectionFooterAreas3 from '../webcomponent/SectionFooterAreas3';
import SectionFooterAreas4 from '../webcomponent/SectionFooterAreas4';
import SectionFooterAreas5 from '../webcomponent/SectionFooterAreas5';
import SectionFooterAreas6 from '../webcomponent/SectionFooterAreas6';
import SectionFooterAreas7 from '../webcomponent/SectionFooterAreas7';
import FloorMaps from '../webcomponent/FloorMaps';
import FooterBlack from '../webcomponent/FooterBlack';
import StartUp from '../webcomponent/StartUp';
import StartUp1 from '../webcomponent/StartUp1';
import StartUp2 from '../webcomponent/StartUp2';
import StartUp3 from '../webcomponent/StartUp3';
import StartUp4 from '../webcomponent/StartUp4';
import StartUp1Edit from '../webcomponent/StartUp1Edit';
import StartUpEdit from '../webcomponent/StartUpEdit';
import SectionTable from '../webcomponent/SectionTable';

import { transContext } from '../../widget/multilang'

const WebContent = (props) => {
  const { cookies, langArr, form } = props;
  const { componentId = '' } = form;
  const dbLang = cookies.get('dbLang') || 'name';

  const text1 = transContext(langArr, dbLang, form.text1 || '');
  const extProps = { ...form, dbLang, text1 }
  // console.log(componentId, extProps);
  return (<>
    {componentId === 'PageHeader' && <PageHeader {...props} form={extProps} />}
    {componentId === 'Common0' && <Common0 {...props} form={extProps} />}
    {componentId === 'AlbumTest' && <AlbumTest {...props} form={extProps} />}
    {componentId === 'QuickTest' && <QuickTest {...props} form={extProps} />}
    {componentId === 'Test' && <Test {...props} form={extProps} />}
    {componentId === 'FileDownload' && <FileDownload {...props} form={extProps} />}
    {componentId === 'Cooperation' && <Cooperation {...props} form={extProps} />}
    {componentId === 'RecordInquire' && <RecordInquire {...props} form={extProps} />}
    {componentId === 'TransactionRecord' && <TransactionRecord {...props} form={extProps} />}
    {componentId === 'Toeic' && <Toeic {...props} form={extProps} />}
    {componentId === 'Toeic1' && <Toeic1 {...props} form={extProps} />}
    {componentId === 'SectionCourse' && <SectionCourse {...props} form={extProps} />}
    {componentId === 'Slider' && <SectionSlider {...props} form={extProps} />}
    {componentId === 'SectionYearPlan' && <SectionYearPlan {...props} form={extProps} />}
    {componentId === 'Header1' && <SectionHeader1 {...props} form={extProps} />}
    {componentId === 'Header2' && <SectionHeader2 {...props} form={extProps} />}
    {componentId === 'Header3' && <SectionHeader3 {...props} form={extProps} />}
    {componentId === 'Header4' && <SectionHeader4 {...props} form={extProps} />}
    {componentId === 'Header5' && <SectionHeader5 {...props} form={extProps} />}
    {componentId === 'Feature1' && <SectionFeature1 {...props} form={extProps} />}
    {componentId === 'Feature2' && <SectionFeature2 {...props} form={extProps} />}
    {componentId === 'Feature3' && <SectionFeature3 {...props} form={extProps} />}
    {componentId === 'Feature4' && <SectionFeature4 {...props} form={extProps} />}
    {componentId === 'Feature5' && <SectionFeature5 {...props} form={extProps} />}
    {componentId === 'Blog1' && <SectionBlog1 {...props} form={extProps} />}
    {componentId === 'Blog2' && <SectionBlog2 {...props} form={extProps} />}
    {componentId === 'Blog3' && <SectionBlog3 {...props} form={extProps} />}
    {componentId === 'Blog4' && <SectionBlog4 {...props} form={extProps} />}
    {componentId === 'Blog5' && <SectionBlog5 {...props} form={extProps} />}
    {componentId === 'Blog6' && <SectionBlog6 {...props} form={extProps} />}
    {componentId === 'Team1' && <SectionTeam1 {...props} form={extProps} />}
    {componentId === 'Team2' && <SectionTeam2 {...props} form={extProps} />}
    {componentId === 'Team3' && <SectionTeam3 {...props} form={extProps} />}
    {componentId === 'Team4' && <SectionTeam4 {...props} form={extProps} />}
    {componentId === 'Team5' && <SectionTeam5 {...props} form={extProps} />}
    {componentId === 'Project1' && <SectionProject1 {...props} form={extProps} />}
    {componentId === 'Project2' && <SectionProject2 {...props} form={extProps} />}
    {componentId === 'Project3' && <SectionProject3 {...props} form={extProps} />}
    {componentId === 'Project4' && <SectionProject4 {...props} form={extProps} />}
    {componentId === 'Pricing1' && <SectionPricing1 {...props} form={extProps} />}
    {componentId === 'Pricing2' && <SectionPricing2 {...props} form={extProps} />}
    {componentId === 'Pricing3' && <SectionPricing3 {...props} form={extProps} />}
    {componentId === 'Pricing4' && <SectionPricing4 {...props} form={extProps} />}
    {componentId === 'Pricing5' && <SectionPricing5 {...props} form={extProps} />}
    {componentId === 'Testimonials1' && <SectionTestimonials1 {...props} form={extProps} />}
    {componentId === 'Testimonials2' && <SectionTestimonials2 {...props} form={extProps} />}
    {componentId === 'Testimonials3' && <SectionTestimonials3 {...props} form={extProps} />}
    {componentId === 'ContactUs1' && <SectionContactUs1 {...props} form={extProps} />}
    {componentId === 'ContactUs2' && <SectionContactUs2 {...props} form={extProps} />}
    {componentId === 'FooterBlack' && <FooterBlack {...props} form={extProps} />}
    {componentId === 'Footer1' && <SectionFooterAreas1 {...props} form={extProps} />}
    {componentId === 'Footer2' && <SectionFooterAreas2 {...props} form={extProps} />}
    {componentId === 'Footer3' && <SectionFooterAreas3 {...props} form={extProps} />}
    {componentId === 'Footer4' && <SectionFooterAreas4 {...props} form={extProps} />}
    {componentId === 'Footer5' && <SectionFooterAreas5 {...props} form={extProps} />}
    {componentId === 'Footer6' && <SectionFooterAreas6 {...props} form={extProps} />}
    {componentId === 'Footer7' && <SectionFooterAreas7 {...props} form={extProps} />}
    {componentId === 'FloorMaps' && <FloorMaps {...props} form={extProps} />}
    {componentId === 'SectionTable' && <SectionTable {...props} form={extProps} />}
    {componentId === 'StartUp' && <StartUp {...props} form={extProps} />}
    {componentId === 'StartUp1' && <StartUp1 {...props} form={extProps} />}
    {componentId === 'StartUp2' && <StartUp2 {...props} form={extProps} />}
    {componentId === 'StartUp3' && <StartUp3 {...props} form={extProps} />}
    {componentId === 'StartUp4' && <StartUp4 {...props} form={extProps} />}
    {componentId === 'StartUp1Edit' && <StartUp1Edit {...props} form={extProps} />}
    {componentId === 'StartUpEdit' && <StartUpEdit {...props} form={extProps} />}
  </>)
}

export default withCookies(WebContent);
