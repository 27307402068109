
import React, { Component } from 'react'
import { Container, Table, Form, Button, Tab } from 'semantic-ui-react';
import Statistic1 from './Statistic1';
import Statistic2 from './Statistic2';
import Statistic3 from './Statistic3';

// import RowsDnd from './RowsDnd'

// reactstrap components

// const style = {
//   flex: {
//     display: 'flex'
//   },
//   flexCenter: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center'
//   }
// }

class Statistic extends Component {
  state = {
    loading: true,
    loadingBtn: false,
  }

  render() {
    // const { companyObj } = this.props
    const props = this.props;

    const panes = [
      { menuItem: '法會', render: () => <Tab.Pane>{<Statistic1 {...props} />}</Tab.Pane> },
      { menuItem: '點燈', render: () => <Tab.Pane>{<Statistic2 {...props} />}</Tab.Pane> },
      { menuItem: '點數', render: () => <Tab.Pane>{<Statistic3 {...props} />}</Tab.Pane> },
    ]

    return (
      <>
        <div className="content">
          {/* <Form inverted={companyObj.inverted} style={{ position: 'relative', zIndex: 99, }}> */}
          <Form style={{ position: 'relative', zIndex: 99, }}>
            {/* <Tab menu={{ inverted: companyObj.inverted }} panes={panes} {...props} /> */}
            <Tab panes={panes} {...props} />
          </Form>
        </div>
      </>
    );
  }
}

export default Statistic;
