import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Row, Col } from 'reactstrap';

import { Stage, Layer, Rect, Text, Line, Circle } from 'react-konva';
import Konva from 'konva';

import PreloadFile from 'views/web/PreloadFile';
import { richLayout } from './defLine';
import { fetchData } from 'widget/http2';

const CELL_WIDTH = 134;
const CELL_HEIGHT = 120;
const WIDTH = CELL_WIDTH * 3;

const downloadURI = (uri, name) => {
  const link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default class ModalRichMenuLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      nextForm: {},
      oriForm: {},
      chgForm: {},
      imageFile: null
    };
    this.konvaRef = React.createRef();
  }

  async componentDidMount() {
    this.handleFetch()
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps)
  }

  handleFetch = async (props = this.props) => {
    const { dataArr, bgId, parentId, rankId, parentNum, level } = props;
    const tempForm = dataArr && dataArr.find(item =>
      item.bgId === bgId &&
      item.parentId === parentId &&
      item.parentNum === parentNum &&
      item.rankId === rankId
    )
    // console.log(tempForm)
    const form = tempForm || { bgId, rankId, parentId, parentNum, level, chatBarText: "開啟智能選單" };

    // '\uf063'
    this.setState({ oriForm: form, chgForm: form })
  }

  toggle = () => {
    this.setState(state => ({ modal: !state.modal }))
  }

  handleClick = (nextForm, parentNum) => {
    this.setState({ nextForm: { ...nextForm, parentNum } })
  }

  onChange = (e, index, type) => {
    const { id, name, value } = e.target
    if (id === 'autoBg') {
      this.setState({ chgForm: { ...this.state.chgForm, [id]: Boolean(Number(value)) } });
    } else {
      this.setState({ chgForm: { ...this.state.chgForm, [id]: value } });
    }
  }

  handleChangeFile = async (imageFile) => {
    // console.log(imageFile)
    this.setState({ imageFile });
  }

  handleSaveImage = event => {
    event.preventDefault();
    const dataURL = this.konvaRef.current.toDataURL({
      mimeType: "image/png",
      quality: 0,
      pixelRatio: 2
    });
    downloadURI(dataURL, "test");
  }

  render() {
    const {
      buttonLabel = '編輯',
      className,
      btnColor = 'danger',
      bgText,
      bgId,
      rankText,
      rankId,
      handleSave,
      // parentId,
      // parentNum,
    } = this.props
    const { modal, nextForm, oriForm, chgForm } = this.state
    const layoutArr = chgForm.layoutId === '11' ? ['0', '1', '2'] : ['0', '1', '2', '3', '4', '5'];
    const contentRM = layoutArr.map((item) => ({ icon: chgForm[`richIcon${item}`] || '', text: chgForm[`richItem${item}`] || '' }));

    const HEIGHT = chgForm.layoutId === '11' ? CELL_HEIGHT : CELL_HEIGHT * 2;

    return (<div style={{ clear: 'both' }}>
      <h4>{!oriForm.parentId ? '主選單' : `子選單 ${Number(oriForm.parentNum) + 1}`}
        {/* 開通碼：{oriForm.objectId} */}
      </h4>
      {oriForm.objectId ? richLayout(oriForm, null, this.handleClick) : null}
      <Button color={btnColor} onClick={this.toggle} size="sm" style={{ float: 'right' }}>{buttonLabel}</Button>
      {/* <Button color={'link'} onClick={() => this.refreshRichMenu(bgId, rankId)} size="sm">更新Line伺服器</Button> */}
      { nextForm.objectId ? <ModalRichMenuLayout {...this.props} level={Number(this.props.level || 0) + 1} parentId={oriForm.objectId} parentNum={nextForm.parentNum} /> : null}
      <Modal isOpen={modal} toggle={this.toggle} className={className}>
        <ModalHeader style={{ display: 'flex' }} toggle={this.toggle}>
          編輯 {bgText} {rankText} Rich Menu 版面</ModalHeader>
        <ModalBody>
          <FormGroup>
            <legend>版面</legend>
            {/* <Label for="layoutId">版面</Label> */}
            <Input type="select" name="layoutId" id="layoutId" onChange={this.onChange} value={chgForm.layoutId}>
              {/* <option value=''>--- 請選擇 ---</option> */}
              <option disabled>─────雙排─────</option>
              <option value='21'>上3 下3</option>
              <option value='22'>上2 下2</option>
              <option value='23'>上1 下3</option>
              <option value='24'>左1 右2</option>
              <option value='25'>上1 下1</option>
              <option value='26'>左1 右1</option>
              <option value='27'>全部1</option>
              <option disabled>─────單排─────</option>
              <option value='11'>3小</option>
              {/* <option value='12'>左小 右大</option>
              <option value='13'>左大 右小</option>
              <option value='14'>左1 右1</option>
              <option value='15'>全部1</option> */}
            </Input>
          </FormGroup>
          <FormGroup tag="fieldset">
            <legend>背景圖片</legend>
            <FormGroup check>
              <Label check>
                <Input type="radio" id='autoBg' name="autoBg" value={0} checked={!chgForm.autoBg} onChange={this.onChange} />{' '}手動上傳 (PNG檔)</Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="radio" id='autoBg' name="autoBg" value={1} checked={chgForm.autoBg} onChange={this.onChange} />{' '}自動生成 (FontAwesome) </Label>
            </FormGroup>
          </FormGroup>
          {!chgForm.autoBg ? <>
            <br />
            {richLayout(chgForm, this.onChange)}
            <br />
            <PreloadFile isImage='1' handleChangeFile={this.handleChangeFile} />
            <div style={{ clear: 'both' }}></div>
          </> : <>
              {/* <button onClick={this.handleSaveImage}>Save Image</button> */}
              <Stage width={WIDTH} height={HEIGHT} ref={this.konvaRef}>
                <Layer>
                  <Rect x={0} y={0} width={WIDTH} height={HEIGHT} fill='#ffffff' />
                  {/* 直的1 */}
                  <Line x={0} y={0} points={[CELL_WIDTH, 0, CELL_WIDTH, HEIGHT]} stroke="#f3f3f3" strokeWidth="1" />
                  {/* 直的2 */}
                  <Line x={0} y={0} points={[CELL_WIDTH * 2, 0, CELL_WIDTH * 2, HEIGHT]} stroke="#f3f3f3" strokeWidth="1" />
                  {/* 橫的 */}
                  {chgForm.layoutId === '11' ? null : <Line x={0} y={0} points={[0, CELL_HEIGHT, WIDTH, CELL_HEIGHT]} stroke="#f3f3f3" strokeWidth="1" />}
                  {chgForm.layoutId === '11' ? contentRM.map(({ icon, text }, i) => <React.Fragment key={`rf11${i}`}>
                    {icon ? <Circle x={CELL_WIDTH * i + (CELL_WIDTH / 2)} y={48} radius={22} fill='#69779c' /> : null}
                    {/* icon */}
                    <Text x={CELL_WIDTH * i} y={39} width={CELL_WIDTH} fontFamily="FontAwesome" fontSize={20} align='center' fill='#fefefe' text={String.fromCharCode(parseInt(icon, 16))} />
                    {/* text */}
                    <Text x={CELL_WIDTH * i} y={80} width={CELL_WIDTH} align='center' fontSize={12} fontFamily="'Noto Sans TC'" fill='#767676' text={text} />
                  </React.Fragment>)
                    : [[...contentRM.slice(0, 3)], [...contentRM.slice(3)]].map((arr, i) => <React.Fragment key={`rf1${i}`}>
                      {arr.map(({ icon, text }, j) => <React.Fragment key={`rf2${j}`}>
                        {icon ? <Circle x={CELL_WIDTH * j + (CELL_WIDTH / 2)} y={CELL_HEIGHT * i + 48} radius={22} fill='#69779c' /> : null}
                        {/* icon */}
                        <Text x={CELL_WIDTH * j} y={CELL_HEIGHT * i + 39} width={CELL_WIDTH} fontFamily="FontAwesome" fontSize={20} align='center' fill='#fefefe' text={String.fromCharCode(parseInt(icon, 16))} />
                        {/* text */}
                        <Text x={CELL_WIDTH * j} y={CELL_HEIGHT * i + 80} width={CELL_WIDTH} align='center' fontSize={12} fontFamily="'Noto Sans TC'" fill='#767676' text={text} />
                      </React.Fragment>)}
                    </React.Fragment>)}
                  {/* <Text x={150} y={200} text="差勤 / 打卡" fontSize={30} fontFamily="'Noto Serif TC'" /> */}
                </Layer>
              </Stage>
              <br />
            </>}
          <Row form>
            {layoutArr.map((item, index) => <Col key={`la${index}`} md={4}>
              {chgForm.autoBg ?
                <FormGroup>
                  <Label for={`richIcon${index}`}>{index + 1}. Icon</Label>
                  <Input
                    type="text"
                    name={`richIcon`}
                    id={`richIcon${index}`}
                    value={chgForm[`richIcon${index}`] || ''}
                    onChange={(e) => this.onChange(e, index, '1')}
                  />
                </FormGroup> : null}
              <FormGroup>
                <Label for={`richItem${index}`}>{index + 1}. Text</Label>
                <Input
                  type="text"
                  name={`richItem`}
                  id={`richItem${index}`}
                  value={chgForm[`richItem${index}`] || ''}
                  onChange={(e) => this.onChange(e, index, '2')}
                />
              </FormGroup>
            </Col>)}
          </Row>
          {/* <br /> */}
          <legend>選單設定</legend>
          {/* <br /> */}
          <Input type="text" name="chatBarText" id="chatBarText" placeholder="按鈕" value={chgForm.chatBarText || ''} onChange={this.onChange} />
          <br />
          <Input type="text" name="ps" id="ps" placeholder="備註" value={chgForm.ps || ''} onChange={this.onChange} />
          <br />
        </ModalBody>
        <ModalFooter style={{ padding: '1em' }}>
          <Button color="secondary" onClick={this.toggle}>取消</Button>{' '}
          <Button color="primary" onClick={async (e) => {
            // console.log(chgForm)
            const imageFile = !chgForm.autoBg ? this.state.imageFile : this.konvaRef.current.toDataURL({
              mimeType: "image/png",
              quality: 0,
              pixelRatio: 2
            });
            await handleSave(e, chgForm, imageFile);
            this.toggle();
          }}>儲存</Button>
        </ModalFooter>
      </Modal>
    </div >)
  }
}