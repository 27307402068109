import React, { Component } from 'react'
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
import { Container, Header, Form, Icon, Button } from 'semantic-ui-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import PageHeader from 'components/Headers/PageHeader';
import { ListGroup, ListGroupItem } from 'reactstrap';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import PreloadFile from '../../views/web/PreloadFile'
import { readFileAsURL } from '../../views/web/RowImage';

export class ModalEditButton extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
      show: false,
      imagePreviewUrlArr: [],
      photo: '',
      form: {}
    };
  }


  // handleChange = (e, { value }) => this.setState({ value })
  closeEdit = () => { this.setState({ show: false }) }
  onEdit = () => { this.setState({ show: true }) }
  //   handleChange = (e) => {
  //     // console.log(e);
  //     this.setState({
  //       [e.target.id]: e.target.value
  //     })
  //   }

  //   handleSubmit = (e) => {
  //     // console.log(e);
  //     e.preventDefault();
  //     this.props.createProject(this.state);
  //     this.props.history.push('/');
  //   }

  handleChange = (id) => {
    const { form } = this.state
    const input = document.getElementById(id)
    if (id === 'target') {
      this.setState({ form: { ...form, [id]: (input && input.checked) || '' } })
    } else {
      this.setState({ form: { ...form, [id]: (input && input.value) || '' } })
    }
  }
  render() {
    const { handleButton, id } = this.props
    const { show, form } = this.state
    //     if (!auth.uid) return <Redirect to='/signin' />
    // const { value } = this.state
    return (<>
      <a className="j-pop-image" onClick={this.onEdit}>編輯</a>
      <Modal
        isOpen={show}
        toggle={this.closeEdit}
        size="lg"
        style={{ maxWidth: '1200px', width: '70%', margin: '10px auto' }}
      // className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader >
          <Icon name='close' onClick={this.closeEdit} style={{ cursor: 'pointer', margin: 0 }}></Icon>
        </ModalHeader>
        <ModalBody>
          <div className="white-popup" id="popup-buttonsetting">
            <form className="setBox" data-parsley-required-message="尚未填寫" >
              <div className="top_title">
                <h3>按鈕設定</h3>
              </div>
              <div className="fillinBox edit_button">
                <ul className="clearfix">
                  <li>
                    <label><p className="label_title">連結網址</p></label>
                    <input id='src' type="text" name="src" onChange={() => this.handleChange('src')} list="gotolist" data-parsley-trigger="change" data-parsley-pattern="^(?:(?:http:|https:|)\/\/[^\.]+\..*[^\.]+|mailto:[a-zA-Z0-9.!#$%&amp;'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*|tel:\+?[0-9]+(?:\#[0-9]+)?|#goto\d+)$" data-parsley-pattern-message="格式錯誤" />
                    <datalist id="gotolist"><option value="#goto278962">Main</option><option value="#goto278963">PHOTOGRAPHY</option><option value="#goto278964">ABOUT JACK</option><option value="#goto278965">Text</option><option value="#goto278966">TEAM LEADER</option><option value="#goto278967">CONTACT US</option><option value="#goto278968">FOLLOW US</option></datalist>
                  </li>
                  <li>
                    <label><p className="label_title">按鈕文字</p></label>
                    <input id='text' type="text" name="text" onChange={() => this.handleChange('text')} />
                  </li>
                </ul>
                <p className="target_blank"><label><input type="checkbox" id='target' name="target" data-parsley-multiple="target" onChange={() => this.handleChange('target')} />使用新分頁打開？</label></p>
                <p className="the_reminder">(1) 連結網址：輸入完整網址。例如：https://www.example.com<br />(2) 連結信箱：使用前綴字“mailto:”加上 Email 即可連結到指定的信箱。例如：mailto:user@example.com<br />(3) 連結電話：使用前綴字“tel:”加上電話號碼即可撥打。例如：tel:0900123456、tel:+886987654321<br />(4) 連結版塊：使用前綴字“#goto”加上版塊編號即可連結到指定的版塊。例如：#goto12345</p>
              </div>
            </form>
          </div>
        </ModalBody>
        <ModalFooter style={{ padding: '16px', justifyContent: 'center' }}>
          <Button style={{ backgroundColor: '#27d0ca', color: '#fff' }} content='套用' onClick={() => { handleButton(id, form); this.closeEdit() }} />
        </ModalFooter>
      </Modal>
    </>)
  }
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     createProject: (project) => dispatch(createProject(project))
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HostIndex)
export default ModalEditButton