import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select, Icon } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'

import { Row, Col } from "reactstrap";
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { mgyearOpt, exportPdf, rowReOrder } from 'views/web/CustomMaterialTable';
import RoomInformation from './RoomInformation';

const roomType = { '0': '單人房', '1': '雙人房' };

const columns = () => ([
  { width: 100, title: '社區名稱', field: 'buildingName' },
  { width: 250, title: '地址', field: 'address' },
  { width: 100, title: '預設房租', field: 'defRent' },
]);

const columns2 = (rowData) => ([
  {
    title: '社區', field: 'buildingName', editable: 'never'
    // , render: rowData.buildingName || '' 
  },
  { title: '房型', field: 'roomType', lookup: roomType, initialEditValue: '0' },
  { title: '房號', field: 'roomNum' },
  { title: '每月租金', field: 'rent', initialEditValue: rowData.defRent || '0' },
]);

const d = new Date()
class Managegames extends Component {
  state = {
    activeItem: 'bio',
    data1: [],
    data2: [],
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
    rowData: {},
    roomObj: {},
    buildingObj: {}
  }

  componentDidMount = () => {
    this.handleFetch()
  }

  handleFetch = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data1 = await Parse.queryData('building', { companyId });

    this.setState({ data1 })
  }
  handleAdd = async (type) => {
    const { newData, newData1 } = this.state;
    if (type === '1') {
      await Parse.saveData('building', newData);
    } else {
      await Parse.saveData('room', newData1);
    }
  }

  handleUpdate = async (type) => {
    const { newData, newData1 } = this.state;
    if (type === '1') {
      await Parse.saveData('building', newData);
    } else {
      await Parse.saveData('room', newData1);
    }
  }

  handleDelete = async (type) => {
    const { oldData, oldData1 } = this.state
    if (type === '1') {
      await Parse.deleteData('building', oldData);
    } else {
      await Parse.deleteData('room', oldData1);
    }
  }
  handleData = async (rowData, type) => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    if (type === '1') {
      const data2 = await Parse.queryData('room', { companyId, buildingId: rowData.objectId });
      this.setState({ buildingObj: rowData, data2 })
    } else {
      const data = await Parse.queryData('paying1', { companyId, roomId: rowData.objectId });
      this.setState({ roomObj: rowData, roomArr: data })
    }

    this.setState({ rowData })
  }
  handleState = (dataArr, arrName = 'dataArr') => {
    this.setState({ [arrName]: dataArr })
  }
  render() {
    const { companyObj } = this.props
    const { data1, data2, roomObj, buildingObj, rowData, roomArr } = this.state
    return (<div className="content">
      <Header as='h2' inverted={companyObj.inverted}>房間管理 </Header>
      <Row>
        <Col md='6'>
          <MaterialTable
            localization={localization()}
            columns={columns()}
            options={{
              addRowPosition: "first",
              exportButton: true,
              exportPdf: (columns, data) => exportPdf(columns, data, '社區'),
              pageSize: 5,
              search: false,
              tableLayout: 'fixed'
            }}
            data={data1}
            title="社區"
            actions={[
              {
                icon: 'view_headline',
                tooltip: '查看',
                onClick: (event, rowData) => this.handleData(rowData, '1')
              }
            ]}
            onRowClick={(e, rowData) => this.handleData(rowData, '1')}
            components={{
              Row: props => rowReOrder(props, 'building', 'orderId', data1, 'data1', this.handleState)
            }}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  const data = [...data1];
                  const obj = {
                    ...newData,
                    companyId: companyObj.objectId
                  }
                  data.push(obj);
                  // handleState('people0', data);
                  this.setState({ data1: data, newData: obj }, () => resolve());
                }).then(() => this.handleAdd('1')),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const data = [...data1];
                  const index = data.indexOf(oldData);
                  data[index] = newData;
                  // handleState('people0', data);
                  this.setState({ data1: data, newData, oldData }, () => resolve());
                }).then(() => this.handleUpdate('1')),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  let data = [...data1];
                  const index = data.indexOf(oldData);
                  data.splice(index, 1);
                  // handleState('people0', data);
                  this.setState({ data1: data, oldData }, () => resolve());
                }).then(() => this.handleDelete('1')),
            }}
          />
        </Col>
        <Col md='6'>
          {buildingObj && buildingObj.objectId ?
            <MaterialTable
              localization={localization()}
              columns={columns2(rowData)}
              options={{
                addRowPosition: "first",
                exportButton: true,
                exportPdf: (columns, data) => exportPdf(columns, data, '桌遊'),
                pageSize: 5,
                search: false,
                tableLayout: 'fixed'
              }}
              data={data2}
              title={rowData.buildingName + ' 房源一覽'}
              // actions={[{
              //   icon: 'view_headline',
              //   tooltip: '查看',
              //   onClick: (event, rowData) => this.handleData(rowData, '2')
              // }]}
              components={{
                Row: props => rowReOrder(props, 'room', 'orderId', data2, 'data2', this.handleState)
              }}
              editable={{
                onRowAdd: newData1 =>
                  new Promise((resolve, reject) => {
                    const data = [...data2];
                    const obj = {
                      ...newData1,
                      companyId: companyObj.objectId,
                      buildingId: rowData.objectId,
                      buildingName: rowData.buildingName
                    }
                    data.push(obj);
                    // handleState('people0', data);
                    this.setState({ data2: data, newData1: obj }, () => resolve());
                  }).then(() => this.handleAdd('2')),
                onRowUpdate: (newData1, oldData1) =>
                  new Promise((resolve, reject) => {
                    const data = [...data2];
                    const index = data.indexOf(oldData1);
                    data[index] = newData1;
                    // handleState('people0', data);
                    this.setState({ data2: data, newData1, oldData1 }, () => resolve());
                  }).then(() => this.handleUpdate('2')),
                onRowDelete: oldData1 =>
                  new Promise((resolve, reject) => {
                    let data = [...data2];
                    const index = data.indexOf(oldData1);
                    data.splice(index, 1);
                    // handleState('people0', data);
                    this.setState({ data2: data, oldData1 }, () => resolve());
                  }).then(() => this.handleDelete('2')),
              }}
            /> : null}
        </Col>
      </Row>
      <br />
      <br />
      {roomObj && roomObj.objectId ? <RoomInformation {...this.props} roomObj={roomObj} roomArr={roomArr} /> : null}
    </div >);
  }
}

export default Managegames;