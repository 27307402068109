import React, { Component } from 'react';
import { Container, Header, Form, Icon, Grid, Button, Select, Checkbox, Input, Modal, Label, Confirm } from 'semantic-ui-react'

import SchoolHour from '../../widgets/SchoolHour.js';
import List2Teacher from './teacherList/List2Teacher';
import Parse from '../../../widget/parse'

import { setHomeroomTeacher, getMedianBirthdayOfTeachers, setAllTeacher } from './AutoTimetableAlgo';
import { MdSave, MdPlayForWork, MdModeEdit, MdSettings, MdDelete, MdGridOn, MdLockOutline, MdLockOpen } from "react-icons/md";

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export default class ClassGeneration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // filter: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      filter: ['1', '2', '3', '4', '5', '9'],
      // useSelTimetable: false,
      currentSelArr: [],
      selValue: "0",
      teachers: [],
      teachersEdited: [],
      teachersObj: {},
      classroom: [],
      loadingClassroom: true,
      optionsArr: [],
      form: {},
      isEdit: false,
      teachersArrange: this.props.teachersArrange,
      yearsemester: `${this.props.semesterYear},${this.props.semester}`,
      semesterYear: this.props.semesterYear,
      semester: this.props.semester,
      confirm: false,
    };
    // this.statNonTeachTeacher = this.statNonTeachTeacher.bind(this);
    // this.statTeacher = this.statTeacher.bind(this);
  }

  async componentWillMount() {
    const { semester, semesterYear, companyObj } = this.props;
    // const { teachersArrange } = this.state;
    const teachersArrange = await Parse.queryData('TeachersArrange', { companyId: companyObj.objectId });
    const obj = teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester && item.isUse === true && item.selArr);

    if (!obj) {
      return;
    }
    const selValue = obj._id;
    const currentSelArr = teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester && item.isUse === true && item.selArr).selArr;
    this.setState({ currentSelArr, selValue });
    // console.log(props);
  }
  componentDidMount = async () => {
    this.handleFetch();

    const { firebase, companyObj } = this.props
    // const db = firebase.firestore();
    // db.collection("Teachers").orderBy('teacherNum')
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }));
    //     // console.log(data)
    //     this.setState({ teachers: data }, () => this.getOptionArr());
    //   });
    // db.collection("Classroom")
    //   // .where('semesterYear', '==', semesterYear)
    //   // .where('semester', '==', semester)
    //   .where('isEnableClass', '==', true)
    //   .orderBy('classSchool')
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }));
    //     // console.log(data)
    //     this.setState({ classroom: data, loadingClassroom: false }, () => this.refreshTimeTableBySelect(this.props));
    //   });
    const data = await Parse.queryData('Classroom', { companyId: companyObj.objectId, isEnableClass: true, }, { orderBy: 'classSchool' });
    this.setState({ classroom: data, loadingClassroom: false }, () => this.refreshTimeTableBySelect(this.props));


  }

  componentWillReceiveProps(nextProps) {
    const { semesterYear, semester, teachersArrange } = nextProps;
    // let currentSelArr = teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester && item.isUse === true && item.selArr) ?
    //   teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester && item.isUse === true && item.selArr).selArr : [];
    // if (nextProps.semesterYear !== this.props.semesterYear || nextProps.semester !== this.props.semester)
    // if (this.state.selValue === '0') {
    //   currentSelArr = []
    // }
    // this.setState({ currentSelArr });

    // this.getOptionArr(nextProps)
    // this.refreshTimeTableBySelect(nextProps);
    if (nextProps.semesterYear !== this.props.semesterYear || nextProps.semester !== this.props.semester || nextProps.teachersArrangeL !== this.props.teachersArrangeL || nextProps.teachersL !== this.props.teachersL)
      this.handleFetch(nextProps);
  };

  handleFetch = async (props = this.props) => {
    const { semesterYear, semester, companyObj } = props;
    // if (!teachersArrangeL) {
    //   handleLazyLoad('teachersArrange');
    // } else {
    const teachersArrange = await Parse.queryData('TeachersArrange', { companyId: companyObj.objectId });
    const currentSelArr = teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester && item.isUse === 1 && item.selArr).selArr;
    this.setState({ teachersArrange, currentSelArr })
    // handleLazyLoad('teachers');


    const isHaveUsed = teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester && item.isUse === true) ? '-1' : '0'
    this.setState({ teachersArrange, selValue: isHaveUsed }, () => this.refreshTimeTableBySelect(props))
    this.getOptionArr(props)
    // }
    // if (!teachersL) {
    //   handleLazyLoad('teachers');
    // } else {
    const teachers = await Parse.queryData('Teachers', { companyId: companyObj.objectId });
    let teacherArr = teachers.sort((a, b) => a.teacherNum - b.teacherNum)
    this.setState({ teachers: teacherArr })
    // }
  }

  getOptionArr = (props = this.props) => {
    const { semester, semesterYear } = props;
    const { teachersArrange } = this.state;

    let options = teachersArrange.filter(item => item.semesterYear === semesterYear && item.semester === semester)
      .map(({ _id, title, isUse }) => ({ key: _id, value: _id, text: (isUse ? `[公表]` : '') + (title ? title : " (名前を入力して下さい)") }))
    const optionsArr = [
      { key: '-1', text: '目前配当', value: '-1' },
      { key: '0', text: '(新配当)', value: '0' },
      { key: 'dd', text: '===========', value: 'dd', disabled: true },
      ...options
    ]
    this.setState({ optionsArr })
  }
  lockAll = () => {
    const { currentSelArr } = this.state;
    // if (confirm(`確定要鎖定全部已選老師嗎?`))
    for (let i = 0; i < currentSelArr.length; i++) {
      if (currentSelArr[i].teacherId && currentSelArr[i].disabled === false)
        currentSelArr[i].disabled = true;
    }
    this.setState({ currentSelArr });
  };
  unlockAll = () => {
    const { currentSelArr } = this.state;
    // if (confirm(`確定要鎖定全部已選老師嗎?`))
    for (let i = 0; i < currentSelArr.length; i++) {
      if (currentSelArr[i].teacherId && currentSelArr[i].disabled === true)
        currentSelArr[i].disabled = false;
    }
    this.setState({ currentSelArr });
  };
  clearUnlock = () => {
    const { currentSelArr } = this.state;
    // if (noConfirm || confirm(`確定要清空全部未鎖定嗎?`))
    // if (confirm(`確定要清空全部未鎖定嗎?`))
    for (let i = 0; i < currentSelArr.length; i++) {
      if (currentSelArr[i].disabled === false)
        currentSelArr[i].teacherId = '';
    }
    this.setState({ currentSelArr });
  };
  handleSelectTeacher = (event, data) => { // 選老師的時候要怎麼做
    const { currentSelArr, classroom } = this.state;
    const [teacherId, classroomId, period, weekday] = data.value.split(',');
    // console.log(teacherId, classroomId, period, weekday);

    // 設定此班此時段給此老師

    const findArr = currentSelArr.filter(item => item.classroomId === classroomId
      && item.weekday === weekday
      && (item.period === period || item.period === (Number(period) + 1) + ''));

    const classroomObj = classroom.find(item => item.classroomId === classroomId);
    // console.log('classroomObj', classroomObj)
    let obj = {};
    if (findArr.length) { // 找到該堂課 應該有兩堂
      // console.log('findArr', findArr)
      findArr.forEach(item2 => item2.teacherId = teacherId);
    } else {
      // console.log(classroomObj);
      if (classroomObj) {
        obj = {
          yearSchool: classroomObj.semesterYear,
          semester: classroomObj.semester,
          classSchool: classroomObj.classSchool,
          period,
          weekday,
          teacherId,
          classroomId,
          disabled: false,
          homeroomTeacherId: classroomObj.teacher,
        }
        // console.log('obj', obj)
      }
    }

    // 削除其他班同時段有選此老師的
    currentSelArr
      .filter(item => item.classroomId !== classroomId
        && item.weekday === weekday
        && (item.period === period || item.period === (Number(period) + 1) + '')
        && item.teacherId === teacherId)
      .forEach(item2 => item2.teacherId = '');

    if (obj.classSchool) {
      currentSelArr.push(obj, { ...obj, period: (Number(period) + 1) + '' });
    }
    this.setState({ currentSelArr });
  };
  handleClickLockIcon = (event) => {
    const { currentSelArr } = this.state;
    const [classroomId, period, weekday] = event.currentTarget.value.split(',');
    // console.log(classroomId, period, weekday)
    // 設定此クラス此時段為現在鎖定状態的相反
    currentSelArr
      .filter(item => item.classroomId === classroomId
        && item.weekday === weekday
        && (item.period === period || item.period === (Number(period) + 1) + ''))
      .forEach(item2 => item2.disabled = !item2.disabled);

    this.setState({ currentSelArr });
  };
  accTeacher = (arr, teacherId) => { // 統計每一個老師的上課時間
    const { semesterYear, semester } = this.props;
    const { teachers } = this.state
    const teacher = teachers.find(item => item._id === teacherId);
    if (!teacher) {
      return;
    }

    const index = arr.findIndex(item => item.teacherId === teacherId);
    if (index === -1) {
      const { nameTeacher, partTimeTeacherText, partTimeTeacher, arrangeArr } = teacher;

      //從未提供排課時數
      if (!teacher.arrangeArr) {
        arr.push({ teacherId, nameTeacher, partTimeTeacherText, partTimeTeacher, count: 0, maxWeekday: 0, maxWeekdayText: '00000', arrangeArr })
        return;
      }
      const t = teacher.arrangeArr.find(item => (item.semesterYear === semesterYear && item.semester === semester));
      if (!t) {//此学期未提供排課時數
        arr.push({ teacherId, nameTeacher, partTimeTeacherText, partTimeTeacher, count: 0, maxWeekday: 0, maxWeekdayText: '00000', arrangeArr })
        return;
      }

      const maxWeekdayArr = t.hasOwnProperty('maxWeekdayArr') ? t.maxWeekdayArr : [0, 0, 0, 0, 0];
      const maxWeekday = maxWeekdayArr.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue));
      const maxWeekdayText = maxWeekdayArr.reduce((accumulator, currentValue) => accumulator + (currentValue + ''));

      arr.push({ teacherId, nameTeacher, partTimeTeacherText, partTimeTeacher, count: 1, maxWeekday, maxWeekdayText, arrangeArr })
    } else {
      arr[index].count++;
    }
  }
  statAllClassTeacherForAutoTimetable = () => {
    const { currentSelArr } = this.state;
    // 找出有排課的老師
    const teacherAllClassArr = currentSelArr && currentSelArr.filter(item => item.teacherId);

    // 算每一個老師上課的時数
    const accArr = [];
    teacherAllClassArr.forEach(element => { this.accTeacher(accArr, element.teacherId); });

    return accArr.sort((a, b) => b.count - a.count).sort((a, b) => a.partTimeTeacher - b.partTimeTeacher);
  }
  statNonTeachTeacher = (accArr) => { // 找出所有沒有排課的老師
    const { teachers } = this.state;
    const t = teachers.filter(item => item.staffStatus === '1' && (item.teacherTitle === "3" || item.teacherTitle === "4" || item.teacherTitle === "6")).filter(item => {
      return accArr.find(item2 => item2.teacherId === item._id) ? false : true;
    }).sort((a, b) => b.teacherNum - a.teacherNum);

    const arr = [];
    t.map(item => this.accTeacher(arr, item._id));
    arr.map(item => item.count = 0);
    return arr.sort((a, b) => a.partTimeTeacher - b.partTimeTeacher);
  }
  statTeacher = (type) => {
    const { currentSelArr } = this.state;
    if (type === 1) { // 需排課時間
      return currentSelArr.length;
    } else if (type === 2) { // 已鎖定老師時間
      return currentSelArr.filter(item => item.teacherId && item.disabled).length;
    } else if (type === 3) { // 未鎖定老師時間
      return currentSelArr.filter(item => item.teacherId && !item.disabled).length;
    } else if (type === 4) { // 未安排老師時間
      return currentSelArr.filter(item => !item.teacherId).length;
    }
  }
  generateClassInfo = (classroom) => {
    return (
      classroom.map(({ _id }) => (
        {
          classroomId: _id,
          teacherRoster: [],
        }
      ))
    );
  };
  generateTeachersInfo = (teachers, classroom) => {
    const { semester, semesterYear } = this.props;
    return (
      teachers.map(({ _id, genderTeacher, birthdayTeacher, partTimeTeacher, arrangeArr }) => {
        const gender = genderTeacher || '';
        const birthday = birthdayTeacher ? Number(birthdayTeacher.split('-')[0]) : '';
        const contract = partTimeTeacher || '';
        const arrangement = arrangeArr && !!arrangeArr.find(item => item.semesterYear === semesterYear && item.semester === semester) ? arrangeArr.find(item => item.semesterYear === semesterYear && item.semester === semester) : {};
        const nonconsecutive = arrangement && arrangement.nonconsecutive && arrangement.nonconsecutive === true ? true : false;
        const hrTeacherNum = classroom.filter(({ teacher }) => teacher === _id) ? classroom.filter(({ teacher }) => teacher === _id).length : 0;
        const maxShift = partTimeTeacher === '1' ? 20 : 16;

        return (
          {
            teacherId: _id,
            gender,
            birthday,
            contract,
            arrangement,
            nonconsecutive,
            hrTeacherNum,
            maxShift,
            shiftNum: 0,
          });
      })
    );
  };
  generateBlankTimetable = (classroom) => {
    let weekday = ['0', '1', '2', '3', '4'];
    let period = [['0', '1', '2', '3'], ['4', '5', '6', '7']];
    let arr = [];

    classroom
      .forEach(({ _id, semesterYear, semester, classTime, classSchool, teacher }) => weekday
        .forEach(weekday => period[Number(classTime) - 1]
          .forEach(period => arr.push(
            {
              classroomId: _id,
              semesterYear,
              semester,
              weekday,
              period,
              teacherId: '',
              homeroomTeacherId: teacher,
              classSchool,
              "disabled": false,
            }
          ))
        )
      );

    return arr;
  };
  getActiveClassroom = (classroom) => {
    const { semester, semesterYear } = this.props;
    return classroom.filter(item => item.semesterYear === semesterYear && item.semester === semester);
  };
  autoTimeTable = () => {
    const { teachers, filter, currentSelArr, loadingClassroom, classroom } = this.state;
    // const { classroom } = this.props;

    // console.log(currentSelArr);
    // 取出所選取年份跟学期之當下的開課クラス(所以2018秋季時候，會包含2017春/2017秋/2018春/2018秋 共四種不同時期入学的クラス)
    const activeClassroom = !loadingClassroom ? this.getActiveClassroom(classroom) : []; // 把目前的クラス給找出來
    // console.log(activeClassroom);

    // 如果都沒有任何開課クラス或是沒有任何老師無法產生配当表
    if (!activeClassroom || !teachers) return;

    // 產生クラス資訊表，記録各クラス級目前為止的任教老師リスト
    const classInfo = this.generateClassInfo(activeClassroom);
    // console.log(classInfo);
    // 產生老師資訊表，記録老師目前已有時数或是其為専任或兼職等資訊
    const teachersInfo = this.generateTeachersInfo(teachers, activeClassroom);
    // console.log(teachersInfo);
    // 產生空白配当表
    // const tempSelArr = this.generateBlankTimetable(activeClassroom);
    // console.log("blankTimetable", blankTimetable);

    // true: 從資料庫讀出資料 或 false: 不從資料庫讀出資料(產生全新配当表)
    // if (useSelTimetable) {
    // let selTimetableArr = this.getSelTimetable(teachersArrange); // 這個可以不用
    // console.log("selTimetableArr", selTimetableArr);
    // Step0. 先讀出資料庫內已経鎖定的老師，並更新相關資訊表
    // for (let i = 0; i < tempSelArr.length; i++) {
    //   const { classroomId, weekday, period } = tempSelArr[i];

    //   if (selTimetableArr
    //     && selTimetableArr.find(item => item.classroomId === classroomId && item.weekday === weekday && item.period === period)
    //     && selTimetableArr.find(item => item.classroomId === classroomId && item.weekday === weekday && item.period === period).teacherId
    //     && selTimetableArr.find(item => item.classroomId === classroomId && item.weekday === weekday && item.period === period).disabled) {
    //     const teacherId = selTimetableArr.find(item => item.classroomId === classroomId && item.weekday === weekday && item.period === period).teacherId;
    //     const teacherObj = teachersInfo.find(item => item.teacherId === teacherId);
    //     const classObj = classInfo.find(item => item.classroomId === classroomId);

    //     tempSelArr[i].teacherId = teacherId;
    //     tempSelArr[i].disabled = true;
    //     teachersInfo.find(item => item === teacherObj).shiftNum++;
    //     if (classObj.teacherRoster.find(item => item.teacherId === teacherId) === undefined) {
    //       classInfo.find(item => item === classObj).teacherRoster
    //         .push(
    //           {
    //             teacherId: teacherObj.teacherId,
    //             gender: teacherObj.gender,
    //             birthday: teacherObj.birthday,
    //           }
    //         );
    //     }
    //   }
    // }
    // }

    // 全新配當先清空
    // if (filter.indexOf('0') !== -1) {
    // }
    // this.clearUnlock(1);
    for (let i = 0; i < currentSelArr.length; i++) {
      if (currentSelArr[i].disabled === false)
        currentSelArr[i].teacherId = '';
    }

    // Step1. 先分配時段給導師
    setHomeroomTeacher(currentSelArr, teachersInfo, classInfo, activeClassroom, filter);
    // console.log("setHomeroomTeacher", currentSelArr);
    // // Step2. 再分配剩下的時段給所有老師(也包含身兼導師的老師)

    // // 算出老師們的年齡中位数
    const medianBirthday = getMedianBirthdayOfTeachers(teachers);
    // // console.log(medianBirthday);
    setAllTeacher(currentSelArr, teachersInfo, classInfo, medianBirthday, filter);
    // // Step3. 調整以符合其他設定要求
    // const selArr3 = adjustTimetable(selArr2, teachersInfo);

    this.setState({ currentSelArr });
  };
  updateAutoTimetable = () => {
    // if (confirm(`確定要保存此配当表進資料庫嗎?`)) {
    alert("此配当表已保存");
    const { firebase } = this.props;
    const { currentSelArr, selValue, teachersArrange } = this.state;

    const currentArrange = teachersArrange.find(item => item._id === selValue);
    const findIndex = teachersArrange.findIndex(item => item._id === selValue)
    const obj = {
      ...currentArrange,
      selArr: currentSelArr,
    }

    teachersArrange[findIndex] = { ...teachersArrange[findIndex], selArr: currentSelArr }
    console.log(obj)
    firebase.firestore().doc(`TeachersArrange/${selValue}`).update(obj).then(
      // console.log(`Document updated`)
      this.setState({ currentSelArr, teachersArrange })
    );
  };
  insertAutoTimetable = () => {
    const title = prompt("請輸入新配当名");
    if (!title) {
      return;
    }

    const { semesterYear, semester, firebase } = this.props;
    const { currentSelArr, optionsArr, teachersArrange } = this.state;
    let newteachersArrange = [...teachersArrange]
    const obj = {
      title,
      selArr: currentSelArr,
      semesterYear: semesterYear,
      semester,
    }
    firebase.firestore().collection('TeachersArrange').add(obj).then(documentReference => {
      console.log(`Added document with id: ${documentReference.id}`);
      firebase.firestore().collection('TeachersArrange').doc(documentReference.id).update({ _id: documentReference.id })
      let _id = documentReference.id;
      newteachersArrange.push({ _id, ...obj })
      let options = [...optionsArr, { key: _id, value: _id, text: (title ? title : " (名前を入力して下さい)") }]
      this.setState({ optionsArr: options, currentSelArr, selValue: _id, teachersArrange: newteachersArrange })

    });
  };
  renameAutoTimetable = (ori = "(未命名)") => {
    const { firebase } = this.props;
    const { selValue, optionsArr } = this.state;
    ori = optionsArr.find(item => item.key === selValue).text;
    console.log(ori)
    const title = prompt("原配当名: " + ori + ", 請輸入新配当名");
    if (!title) {
      return;
    }

    firebase.firestore().doc(`TeachersArrange/${selValue}`).update({ title: title }).then(
      console.log(`Document renamed`)
    );
    let options = [...optionsArr]
    let index = options.findIndex((item => item.key === selValue))
    options[index].text = title;
    this.setState({ optionsArr: options })
  };

  showConfirm = () => this.setState({ confirm: true });
  hideConfirm = () => this.setState({ confirm: false });

  removeAutoTimetable = () => {
    // if (confirm(`確定要刪除此配当表嗎?`)) {
    const { firebase } = this.props;
    const { selValue, optionsArr } = this.state;
    firebase.firestore().doc(`TeachersArrange/${selValue}`).delete().then(documentReference => {
      console.log(`successfully deleted.`);
    });
    let options = [...optionsArr]
    let index = options.findIndex((item => item.key === selValue))
    options.splice(index, 1);
    this.setState({ optionsArr: options, selValue: '0' }, () => this.refreshTimeTableBySelect(this.props))
    this.hideConfirm();
  };
  async toDefaultAutoTimetable() {
    // if (!confirm(`確定要設定此配当表為公開配当?`)) {
    //   return;
    // }

    const { semesterYear, semester, firebase, } = this.props;
    const { selValue, optionsArr, teachersArrange } = this.state;

    let options = [...optionsArr]
    let old = teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester && item.isUse === true) && teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester && item.isUse === true)._id;
    if (old) {
      let oldIndex = teachersArrange.findIndex(item => item._id === old);
      let index2 = options.findIndex((item => item.key === old))
      options[index2].text = options[index2].text.substring(4);
      teachersArrange[oldIndex].isUse = false
      firebase.firestore().doc(`TeachersArrange/${old}`).update({ isUse: false, multi: true }).then(
        console.log(`Document set`)
      );
    }
    firebase.firestore().doc(`TeachersArrange/${selValue}`).update({ isUse: true }).then(
      console.log(`Document isUsed`)
    );
    const findIndex = teachersArrange.findIndex(item => item._id === selValue);
    teachersArrange[findIndex].isUse = true;
    let index = options.findIndex((item => item.key === selValue))
    options[index].text = `[公表]` + (options[index].text);
    this.setState({ optionsArr: options, teachersArrange })
  };
  onChangeFilter = (e, data) => { // 配当策略選択
    const { filter } = this.state;
    let tempFilter = filter;
    const value = data.value
    const index = filter.indexOf(value);

    if (index === -1) {
      tempFilter.push(value);
      this.setState({ filter: tempFilter });
    }
    else {
      tempFilter.splice(index, 1);
      this.setState({ filter: tempFilter });
    }
  };

  getSelTimetable = (teachersArrange) => {
    const { semesterYear, semester, classroom } = this.props;
    return (
      teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester && item.selArr) &&
      teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester && item.selArr).selArr
    );
  };

  refreshTimeTableBySelect = (props) => {
    let currentSelArr = [];
    const { semesterYear, semester } = this.props;
    const { selValue, classroom, teachersArrange } = this.state;

    if (selValue === "0") {
      const activeClassroom = this.getActiveClassroom(classroom); // 把目前的クラス給找出來
      currentSelArr = this.generateBlankTimetable(activeClassroom);
    } else if (selValue === '-1') {
      currentSelArr = teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester && item.isUse === true) ? teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester && item.isUse === true).selArr : null
    } else if (selValue) {
      if (teachersArrange.find(item => item._id === selValue))
        currentSelArr = teachersArrange.find(item => item._id === selValue).selArr;
    }
    this.setState({ currentSelArr });
  }
  changeSelect = (event, data) => {
    const selValue = data.value;
    this.setState({ selValue }, () => this.refreshTimeTableBySelect(this.props));
  }
  saveArrangement(arrangeArr) {
    this.setState({ form: { arrangeArr } });
  }
  saveUpdateSetting = (_id) => {
    const { firebase, semester, semesterYear } = this.props;
    const { form, teachersEdited, teachersObj } = this.state;
    const { arrangeArr } = form;
    teachersObj.arrangeArr = arrangeArr

    form._id = _id;
    form.maxWeekday = arrangeArr.find(item => item.semester === semester && item.semesterYear === semesterYear).maxWeekdayArr.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue));
    form.maxWeekdayText = arrangeArr.find(item => item.semester === semester && item.semesterYear === semesterYear).maxWeekdayArr.reduce((accumulator, currentValue) => accumulator + (currentValue + ''));
    const findIndex = teachersEdited.findIndex(teacher => teacher._id === _id)
    if (findIndex !== -1) {
      teachersEdited[findIndex] = form
    } else {
      teachersEdited.push(form)
    }
    // console.log(form);
    firebase.firestore().collection("Teachers").doc(form._id)
      .update(form).then(docRef => {
        this.setState({ isEdit: false, doc: form });
      });
    // console.log(teachersEdited)
    this.setState({ teachersEdited, teachersObj })
  }
  cancelUpdateSetting = () => {
    const { form } = this.state;
    this.setState({ isEdit: false, form });
  }
  handleOpen = (obj) => {
    const { teachersEdited } = this.state;
    let teachersObj = {}
    teachersObj = teachersEdited.find(teacher => teacher._id === obj.teacherId) ? teachersEdited.find(teacher => teacher._id === obj.teacherId) : obj
    // console.log(teachersObj)

    this.setState({ teachersObj, form: teachersObj })
  }
  // handleChange = (e, { name, value }) => {
  //   // console.log(e);
  //   let semesterYear, semester = ''
  //   if (name === 'yearsemester') {
  //     semesterYear = value.substring(0, 4);
  //     semester = value.substring(5);
  //   }
  //   this.setState({ [name]: value, semesterYear, semester, selValue: '0' })
  // }
  render() {
    const { semesterYear, semester, formSet, optSet, students, companyObj } = this.props;
    const { filter, teachersArrange, currentSelArr, teachers, classroom, selValue, form, isEdit, teachersObj, teachersEdited, yearsemester } = this.state;
    const strategy = [
      { value: '1', description: '各クラスに置いて同じ先生が10時間以上入らないようにする' },// 每班同位老師不超過10小時
      { value: '2', description: '専任は最多20時間以内、非常勤は最多16時間以内とする' }, //専任最多20小時，非常勤最多16小時
      // { value: '3', description: '檢査老師在該堂課同一天其他時段是否已経有課' },
      { value: '5', description: '先生の一日の希望時間数を超えないようにする' }, //依照老師每日希望上課時数上限排課
      { value: '6', description: '連続で授業を行うか間を空けるか希望に沿って並べる' }, // 依照老師連續/非連續意願排課
      { value: '7', description: '一つのクラスに6人以上の先生が入らないようにする' }, //每班最多6位老師
      { value: '8', description: '各クラスの先生の性別と年齢の比率を合わせる' } //每班老師性別年齡各半
      // { value: '4', description: '檢査老師在不同堂課同一時段是否已経有上課' },
    ];
    // let optionsData = teachersArrange.filter(item => item.semesterYear === semesterYear && item.semester === semester)
    //   .map(({ _id, title, isUse }) => ({ key: _id, value: _id, text: (isUse ? `[公表]` : '') + (title ? title : " (名前を入力して下さい)") }))

    // const optionsArr = [
    //   { key: '-1', text: '目前配当', value: '-1' },
    //   { key: '0', text: '(新配当)', value: '0' },
    //   { key: 'dd', text: '===========', value: 'dd', disabled: true },
    //   ...optionsData
    // ]

    // 各クラスに置いて同じ先生が8時間以上入らないようにする。
    // 専任は最多20時間以内、非常勤は最多16時間以内とする。
    // 連続で授業を行うか間を空けるか希望に沿って並べる。

    // 統計時数的
    const statTeacherArr = this.statAllClassTeacherForAutoTimetable();
    const statNonTeacherArr = this.statNonTeachTeacher(statTeacherArr);
    // console.log(statTeacherArr)
    // const availTeacherTime = this.availTeacherTime(semesterYear, semester);
    let availTeacherTime = 0;
    statTeacherArr.forEach(item => availTeacherTime += item.maxWeekday);
    statNonTeacherArr.forEach(item => availTeacherTime += item.maxWeekday);

    let isUse = false;
    if (selValue !== '0') {
      const currentArrange = teachersArrange.find(item => item._id === selValue);
      // console.log('currentArrange', currentArrange)
      // console.log('teachersArrange', teachersArrange)
      if (currentArrange) isUse = currentArrange.isUse;
    }

    const numYS = Number(semesterYear + (semester === 'S' ? '04' : '10'));
    const currentTeacher = teachers.filter(item => (!item.endSemesterNum || numYS <= item.endSemesterNum) && (!item.startSemesterNum || numYS >= item.startSemesterNum));
    // console.log(currentTeacher);

    return (<>
      <div className='content'>
        <div style={{ ...style.flexCenter }} >
          <Header as='h2'>{semesterYear}{semester} 時間割配当表の作成</Header>
          {/* <Form.Field floated='right' >
            <Input labelPosition='left'>
              <Label color={'pink'}> <Icon name='calendar' />学期</Label>
              <Select compact options={semesterArr} onChange={this.handleChange} value={yearsemester} name='yearsemester' id='yearsemester' />
            </Input>
          </Form.Field> */}
        </div>
        <Form.Field className='no-print' style={{ paddingBottom: 30, borderBottom: '1px solid #ddd' }}>

          <Grid>
            <Grid.Column width={6}>
              <Header as='h3'>配当方式</Header>
              <div style={{ ...style.flex, margin: '10px 0' }}>
                <Select
                  style={{ minWidth: '60%' }}
                  value={selValue}
                  onChange={this.changeSelect}
                  options={this.state.optionsArr} />
                {/* <option value="-1">目前配当</option>
                  <option value="0">(新配当)</option>
                  <option disabled>===========</option>
                  {teachersArrange.filter(item => item.semesterYear === semesterYear && item.semester === semester)
                    .map(({ _id, title, isUse }) => (<option key={_id} value={_id}>{isUse ? `[公表]` : ''} {title ? title : "(名前を入力して下さい)"}</option>))} */}
                <Button type="button" color="blue" style={{ ...style.flexCenter, marginLeft: 30 }} onClick={() => this.updateAutoTimetable()} disabled={selValue === '0'} icon='save' content='保存' />
              </div>
              <div style={style.flex}>
                <Button inverted={companyObj.inverted} size="small" color="green" style={style.flexCenter} onClick={() => this.insertAutoTimetable()}><MdPlayForWork size='1.2em' />名前を付けて保存</Button>
                <Button inverted={companyObj.inverted} size="small" color="blue" style={style.flexCenter} onClick={() => this.renameAutoTimetable()}><MdModeEdit size='1.2em' />名称の変更</Button>
                <Button inverted={companyObj.inverted} size="small" color="orange" style={style.flexCenter} onClick={() => this.toDefaultAutoTimetable()} disabled={isUse || selValue === '0'}><MdSettings size='1.2em' />決定阪を公表する</Button>
                <Button inverted={companyObj.inverted} size="small" color="red" style={style.flexCenter} onClick={this.showConfirm} icon='delete' content='削除' />
              </div>
            </Grid.Column>
            <Grid.Column width={6}>
              <Header as='h3'>配当条件</Header>
              {strategy.map(item =>
                <Grid.Row key={item.value}>
                  {/* <Form.Check
                  type='checkbox'
                  value={item.value}
                  onChange={this.onChangeFilter}
                  checked={filter.indexOf(item.value) !== -1}
                  label={item.description}
                /> */}

                  <Form.Field
                    control={Checkbox}
                    label={{ children: item.description }}
                    onChange={this.onChangeFilter}
                    value={item.value} />

                </Grid.Row>)
              }
            </Grid.Column>
            <Grid.Column width={4}>
              <Header as='h3'>配当操作</Header>
              <Button color="green" onClick={() => this.autoTimeTable()}><MdGridOn size='1em' /> 時間割表自動作成</Button>
              <Button inverted={companyObj.inverted} color="blue" size="small" primary onClick={() => this.clearUnlock()}><MdLockOpen size='1.2em' /> クリア未確定個所</Button>{' '}
              <br />
              <br />
              <Button inverted={companyObj.inverted} color='green' size="small" onClick={() => this.unlockAll()}><MdLockOutline size='1.2em' /> 全部取消確定個所</Button>{' '}
              <Button inverted={companyObj.inverted} color='orange' size="small" onClick={() => this.lockAll()}><MdLockOutline size='1.2em' /> 全部確定個所</Button>{' '}
            </Grid.Column>
          </Grid>
        </Form.Field>
        <Header as='h3'>配当時間数</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as='h5'>配当済みの先生 <span style={{ fontSize: '12px' }}>配当時間/配当可能時間&nbsp;&nbsp;(月火水木金 配当可能時間)</span></Header>
              <Grid>
                <Grid.Row columns={4}>
                  {statTeacherArr.map((item, index) =>
                    <Grid.Column key={item.teacherId}>
                      ({index + 1}) {item.partTimeTeacherText}
                      {/* {item.nameTeacher} */}
                      <Modal trigger={<Label as='a' onClick={() => this.handleOpen(item)}>{item.nameTeacher}</Label>} closeIcon onClose={() => this.setState({ isEdit: false })}>
                        <Modal.Header>{item.nameTeacher} 時間割配当</Modal.Header>
                        <Modal.Content>
                          <Form.Field
                          //  floated='right' 
                          >
                            <Button.Group style={{ marginLeft: 20 }} floated='right'>
                              {isEdit ? <Button positive onClick={() => this.saveUpdateSetting(item.teacherId)} icon='save' content='保存' /> : <Button color='green' onClick={() => this.setState({ isEdit: true })} icon='edit' className="no-print" content="編集" />}
                              {isEdit ? <Button basic onClick={this.cancelUpdateSetting} icon='remove' content='キャンセル' /> : null}
                              {/* <Button variant="danger" onClick={() => handleRemove(form._id, history)} icon='delete' content='削除' /> */}
                              {/* <Button onClick={() => this.printDiv()} className="">印刷</Button> */}
                            </Button.Group>
                          </Form.Field>
                          <List2Teacher
                            {...this.props}
                            teachersObj={item}
                            _id={item.teacherId}
                            isEdit={isEdit}
                            semester={semester}
                            semesterYear={semesterYear}
                            saveArrangement={this.saveArrangement.bind(this)}
                            arrangeArr={teachersObj.arrangeArr} />
                        </Modal.Content>
                      </Modal>
                      &nbsp;
              <span style={item.count !== item.maxWeekday ? { color: '#ff9800' } : {}}>
                        {item.count}/{teachersEdited.find(teacher => teacher._id === item.teacherId) ? teachersEdited.find(teacher => teacher._id === item.teacherId).maxWeekday : item.maxWeekday}</span> ({teachersEdited.find(teacher => teacher._id === item.teacherId) ? teachersEdited.find(teacher => teacher._id === item.teacherId).maxWeekdayText : item.maxWeekdayText})
              </Grid.Column>)}
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as='h5'>未配当の先生 <span style={{ fontSize: '12px' }}>配当時間/配当可能時間&nbsp;&nbsp;(月火水木金 配当可能時間)</span></Header>
              <Grid>
                <Grid.Row columns={4}>
                  {statNonTeacherArr.map((item, index) =>
                    <Grid.Column md={3} sm={4} xs={4} key={'A' + item.teacherId}>
                      ({index + 1}) {item.partTimeTeacherText}
                      <Modal trigger={<Label as='a' onClick={() => this.handleOpen(item)}>{item.nameTeacher}</Label>} closeIcon onClose={() => this.setState({ isEdit: false })}>
                        <Modal.Header>{item.nameTeacher} 時間割配当</Modal.Header>
                        <Modal.Content>
                          <Button.Group style={{ marginLeft: 20 }} floated='right'>
                            {isEdit ? <Button positive onClick={() => this.saveUpdateSetting(item.teacherId)} icon='save' content='保存' /> : <Button color='green' onClick={() => this.setState({ isEdit: true })} icon='edit' className="no-print" content="編集" />}
                            {isEdit ? <Button basic onClick={this.cancelUpdateSetting} icon='remove' content='キャンセル' /> : null}
                            {/* <Button variant="danger" onClick={() => handleRemove(form._id, history)} icon='delete' content='削除' /> */}
                            {/* <Button onClick={() => this.printDiv()} className="">印刷</Button> */}
                          </Button.Group>
                          <List2Teacher
                            {...this.props}
                            teachersObj={item}
                            _id={item.teacherId}
                            isEdit={isEdit}
                            semester={semester}
                            semesterYear={semesterYear}
                            saveArrangement={this.saveArrangement.bind(this)}
                            arrangeArr={teachersObj.arrangeArr} />
                        </Modal.Content>
                      </Modal>&nbsp;
                      <span style={item.count !== item.maxWeekday ? { color: '#ff9800' } : {}}>
                        {item.count}/{teachersEdited.find(teacher => teacher._id === item.teacherId) ? teachersEdited.find(teacher => teacher._id === item.teacherId).maxWeekday : item.maxWeekday}</span> ({teachersEdited.find(teacher => teacher._id === item.teacherId) ? teachersEdited.find(teacher => teacher._id === item.teacherId).maxWeekdayText : item.maxWeekdayText})
              </Grid.Column>)}
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <p>
          <span style={{ fontSize: '18px', color: '#292929', fontWeight: 'bold' }}>配当時間数統計</span> (時間)：配当可能時間&nbsp;&nbsp;
          <span style={{ fontSize: '24px', color: 'blue', fontWeight: 'bold' }}>{availTeacherTime}</span>&nbsp;&nbsp;- 必要配当時間&nbsp;&nbsp;
          <span style={{ fontSize: '24px', color: 'black', fontWeight: 'bold' }}>{this.statTeacher(1)}</span>&nbsp;&nbsp;(確定個所&nbsp;&nbsp;
          <span style={{ fontSize: '24px', color: 'green', fontWeight: 'bold' }}>{this.statTeacher(2)}</span>&nbsp;&nbsp;+ 未確定個所&nbsp;&nbsp;
          <span style={{ fontSize: '24px', color: 'brown', fontWeight: 'bold' }}>{this.statTeacher(3)}</span>&nbsp;&nbsp;+ 未配当個所&nbsp;&nbsp;
          <span style={{ fontSize: '24px', color: 'red', fontWeight: 'bold' }}>{this.statTeacher(4)}</span>&nbsp;&nbsp;) = 余り&nbsp;&nbsp;
          <span style={{ fontSize: '24px', color: 'purple', fontWeight: 'bold' }}>{availTeacherTime - this.statTeacher(1)}</span>
        </p>
        <br className='no-print' />
        <SchoolHour
          {...this.props}
          // history={history}
          classroom={classroom}
          // teachersArrange={this.state.currentSelArr}
          selArr={currentSelArr}
          teachers={currentTeacher}
          semesterYear={semesterYear}
          semester={semester}
          isEdit={true}
          onSelect={this.handleSelectTeacher}
          onClick={this.handleClickLockIcon}
        // students={students}
        />
        <Confirm
          header='削除'
          content='確定要削除此配当表嗎?'
          cancelButton='いいえ'
          confirmButton='はい'
          open={this.state.confirm}
          onCancel={this.hideConfirm}
          onConfirm={() => this.removeAutoTimetable()}
          size='mini'
          centered={false} />
      </div>
    </>);
  }
}
