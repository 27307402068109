import React, { Component } from 'react'
import { Form } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from 'reactstrap';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import FileCenter1 from './FileCenter1'
import { fileBtnCol, filePreviewCol, exportPdf } from './CustomMaterialTable';
import ModalUploadFiles from './ModalUploadFiles';

const columns = ({ f1Sel = {}, isFile1, isFile2, isFile3, isFile4, isDocPs, isWorkdays }) => ([
  { width: 60, title: '類別', field: 'fileCenter1Id', lookup: f1Sel },
  fileBtnCol({ field: 'file1', hidden: !isFile1 }),
  filePreviewCol({ title: '檔案', field: 'file1', hidden: !isFile1 }),
  fileBtnCol({ field: 'file2', hidden: !isFile2 }),
  filePreviewCol({ title: '申請檔案', field: 'file2', hidden: !isFile2 }),
  fileBtnCol({ field: 'file3', hidden: !isFile3 }),
  filePreviewCol({ title: '空白檔案', field: 'file3', hidden: !isFile3 }),
  fileBtnCol({ field: 'file4', hidden: !isFile4 }),
  filePreviewCol({ title: '範例檔案', field: 'file4', hidden: !isFile4 }),
  { title: '附加文件提醒', field: 'docPs', hidden: !isDocPs },
  { width: 60, title: '工作天數', field: 'workdays', hidden: !isWorkdays },
  // { title: 'file1_url', field: 'file1_url' },
  // { title: 'file2_url', field: 'file2_url' },
  { width: 120, title: '名稱(繁體中文)', field: 'name' },
  { width: 120, title: '名稱(简体中文)', field: 'zh-hant' },
  { width: 120, title: '名稱(日本語)', field: 'ja' },
  { width: 120, title: '名稱(English)', field: 'en' },
  { width: 120, title: '名稱(Vietnam)', field: 'vi' },
  { width: 120, title: '備註(繁體中文)', field: 'psname' },
  { width: 120, title: '備註(简体中文)', field: 'pszh-hant' },
  { width: 120, title: '備註(日本語)', field: 'psja' },
  { width: 120, title: '備註(English)', field: 'psen' },
  { width: 120, title: '備註(Vietnam)', field: 'psvi' },
]);

export default class FileCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginUser: this.props.user.profile.toJSON(),
      f1Arr: [],
      f1Opt: [],
      f1Obj: {},
      f2Arr: [],
      selType: '',
      fileOpt: [],
      loading1: true,
      loading2: true,
    }
    // this.tableRef = React.createRef();
  }

  componentDidMount() {
    const { loginUser } = this.state
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const authPage = window.location.pathname.split('/')[2]
    const authUser = companyId + '_' + authPage

    let auth = {}
    if ((loginUser.authAdminA && loginUser.authAdminA.indexOf(companyId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
      auth = {
        edit: true,
      }
    } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
      auth = {
        edit: false,
      }
    } else {
      auth = {  //為避免其他用戶的文件中心無權限設定，故無此欄位者，權限開啟
        edit: true,
      }
    }

    this.setState({ auth }, () => this.handleFetch(this.props))
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, match } = props;
    const companyId = companyObj.objectId
    const { path } = match
    const pathArr = path.split('/')
    let type = 'common'
    if (pathArr[pathArr.length - 1] !== 'FileCenter') {
      type = pathArr[pathArr.length - 1]
    }
    this.setState({ loading1: true, type });
    const f1Arr = await Parse.queryData('fileCenter1', { companyId, type });
    const f1Opt = [];
    let f1Sel = {}
    if (f1Arr.length) {
      f1Arr.forEach(item => f1Opt.push({ key: item.objectId, value: item.objectId, text: item.name }))
    } else {
      f1Opt.push({ key: '0', value: '0', text: '(無)' })
    }
    f1Arr.forEach(item => f1Sel = { ...f1Sel, [item.objectId]: item.name })

    const selType = f1Arr && f1Arr.length ? f1Arr[0].objectId : '0';
    const f1Obj = f1Arr[0];

    this.setState({ f1Arr, f1Opt, f1Sel, f1Obj, selType, loading1: false }, () => this.handleFetch2())
  }

  handleFetch2 = async (props = this.props) => {
    const { companyObj } = props;
    const { f1Obj, selType } = this.state
    const companyId = companyObj.objectId;

    this.setState({ loading2: true });
    const fileOpt = [];
    f1Obj && Object.keys(f1Obj).forEach(item => {
      if (item === 'isFile1' && f1Obj.isFile1) { fileOpt.push({ key: 'file1', value: 'file1', text: '檔案' }) }
      else if (item === 'isFile2' && f1Obj.isFile2) { fileOpt.push({ key: 'file2', value: 'file2', text: '申請檔案' }) }
      else if (item === 'isFile3' && f1Obj.isFile3) { fileOpt.push({ key: 'file3', value: 'file3', text: '空白檔案' }) }
      else if (item === 'isFile4' && f1Obj.isFile4) { fileOpt.push({ key: 'file4', value: 'file4', text: '範例檔案' }) }
    });

    const f2Arr = await Parse.queryData('fileCenter2', { companyId, fileCenter1Id: selType });
    this.setState({ fileOpt, f2Arr, loading2: false });
  }

  handleChange = (e, { name, value }) => {
    const { f1Arr } = this.state
    const f1Obj = f1Arr.find(item => item.objectId === value);
    this.setState({ f1Obj, [name]: value }, () => this.handleFetch2())
  }

  handleAdd = async (id) => {
    const { newData } = this.state;
    this.setState({ loading2: true });
    await Parse.saveData('fileCenter2', newData)
    this.setState({ loading2: false });
  }

  handleUpdate = async (id) => {
    const { newData } = this.state
    this.setState({ loading2: true });
    await Parse.saveData('fileCenter2', newData);
    this.setState({ loading2: false });
  }

  handleDelete = async (id) => {
    const { oldData } = this.state
    this.setState({ loading2: true });
    await Parse.deleteData('fileCenter2', oldData);
    this.setState({ loading2: false, oldData: {} })
  }

  render() {
    const { f1Opt, f1Sel, f1Obj = {}, f2Arr, selType, loading1, loading2, fileOpt, type, auth = {} } = this.state;

    return (<div className="content">
      <Row>
        <Col md={6}>
          <div style={{ display: 'flex', position: 'relative', zIndex: 100, paddingTop: '11px' }}>
            {f1Opt && f1Opt.length ? <Form.Select style={{ marginRight: '5px' }} loading={loading1}
              options={f1Opt} onChange={this.handleChange} name='selType' value={selType} disabled={selType === '0' ? true : false} /> : <span style={{ marginRight: '5px' }}>請先新增文件類別</span>}
            {!loading1 && auth.edit ? <FileCenter1 {...this.props} handleData={this.handleFetch} type={type} /> : null}
          </div>
        </Col>
        {f1Opt && f1Opt.length ? <Col md={6} style={{ textAlign: 'right' }}>
          {!loading1 && selType !== '0' && auth.edit && (f1Obj.isFile1 || f1Obj.isFile2 || f1Obj.isFile3 || f1Obj.isFile4) ? <ModalUploadFiles {...this.props} isImage='0' fileOpt={fileOpt} rowData={f1Obj} handleData={this.handleFetch2} /> : null}
        </Col> : null}
      </Row>
      <br />
      <MaterialTable
        isLoading={loading2}
        localization={localization()}
        columns={columns({ ...f1Obj, f1Sel })}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, (f1Obj && f1Obj.name) || ''),
          pageSize: f2Arr.length || 10,
          search: false,
        }}
        tableRef={this.tableRef}
        data={f2Arr}
        title={(f1Obj && f1Obj.name) || ''}
        editable={{
          isEditHidden: () => !auth.edit,
          isDeleteHidden: () => !auth.edit,
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = f2Arr;
              const index = data.indexOf(oldData);
              data[index] = newData;
              let obj = {
                ...newData,
                fc1Text: f1Sel[newData.fc1Id] || ''
              }
              // handleState('people0', data);
              this.setState({ f2Arr: data, newData: obj, oldData, loaiding: true }, () => resolve());
            }).then(() => this.handleUpdate("2")),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = f2Arr;
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              // handleState('people0', data);
              this.setState({ f2Arr: data, oldData, loaiding: true }, () => resolve());
            }).then(() => this.handleDelete("2")),
        }}
      />
      <br />
    </div>)
  }
}