import React, { Component } from 'react'
// import { Container, Header, Modal, Button, Table, Form, Grid, Image, Input, Checkbox } from 'semantic-ui-react';
// import Moment from 'moment';
import MaterialTable from 'material-table'
// import { Select, MenuItem } from '@material-ui/core';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
// import ModalNamelist from './ModalNamelist'
// import ModalNamelist3 from './ModalNamelist3'
import { dateCol, typeCurrency, exportPdf } from 'views/web/CustomMaterialTable';

// 1 月報表
// 結清日期	編號	借款日期	合件	姓名	主	 金額 	車號	 部份還本 	 結餘金額 	備註	身分證號碼	手機號	保人手機號
const columns2 = () => ([
  { width: 80, title: '計劃繳息日期', field: 'planDate', editable: 'never' },
  { width: 80, title: '本金結餘', field: 'principalRest', editable: 'never', ...typeCurrency },
  { width: 80, title: '應收利息', field: 'interestPlan', editable: 'never', ...typeCurrency },
  dateCol({ title: '還本日期', field: 'principalPaidDate' }),
  { width: 80, title: '本金還本', field: 'principalPaid', headerStyle: { textAlign: 'left' }, ...typeCurrency },
  dateCol({ title: '繳息日期', field: 'interestPaidDate' }),
  { width: 80, title: '繳息金額', field: 'interestPaid', headerStyle: { textAlign: 'left' }, ...typeCurrency },
  { width: 180, title: '備註', field: 'ps' },
]);

export default class ClientTab3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      newData: {},
      oldData: {},
      loading: true,
    }
    this.tableRef = React.createRef();
  }

  componentDidMount = async () => {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { match, companyObj } = props;
    const { clientId } = match.params;
    if (!clientId) {
      return;
    }
    // const companyId = companyObj.objectId;
    this.setState({ loading: true })
    const data = await Parse.queryData('pawning2', { companyId: companyObj.objectId, clientId });
    this.setState({ data, loading: false });
  }

  render() {
    const { clientObj } = this.props
    const { data, loading } = this.state
    return (<>
      {/* 可以選 借款/繳息/繳息 - 已繳/繳息 - 未繳/還款<br />
      日期	編號	借款 合件	主	 金額 	車號	 部份還本 	 結餘金額 	備註<br /> */}
      <MaterialTable
        loading={loading}
        localization={localization()}
        columns={columns2()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${clientObj.name} 往來記錄`),
          pageSize: data.length < 5 ? 5 : data.length + 1,
          search: false,
          tableLayout: 'fixed',
        }}
        data={data}
        tableRef={this.tableRef}
        title={`${clientObj.name} 往來記錄`}
      // editable={{
      //   onRowAdd: newData =>
      //     new Promise(async (resolve, reject) => {
      //       const data = [...data];
      //       const db = Parse.Object.extend("pawning2");
      //       const newDoc = new db();
      //       const doc = await newDoc.save();

      //       const obj = {
      //         ...pawning1Obj,
      //         _id: doc.id,
      //         id: doc.id,
      //         objectId: doc.id,
      //         pawning1Id: objectId,
      //         companyId: companyObj.objectId,
      //         ...newData,
      //       };

      //       data.push(obj);
      //       this.setState({ data: data, newData: obj }, () => resolve());
      //     }).then(() => this.handleUpdate()),
      //   onRowUpdate: (newData, oldData) =>
      //     new Promise((resolve, reject) => {
      //       const data = [...data];
      //       const index = data.indexOf(oldData);
      //       const obj = { ...newData }

      //       data[index] = obj;
      //       this.setState({ data: data, newData: obj, oldData }, () => resolve());
      //     }).then(() => this.handleUpdate()),
      //   onRowDelete: oldData =>
      //     new Promise((resolve, reject) => {
      //       let data = [...data];
      //       const index = data.indexOf(oldData);
      //       data.splice(index, 1);
      //       this.setState({ data: data, oldData }, () => resolve());
      //     }).then(() => this.handleDelete()),
      // }}
      />
    </>)
  }
}