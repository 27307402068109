import React, { Component } from 'react'
import { Icon, Header, Menu, Tab, Segment, Form, Input, Select, Label } from 'semantic-ui-react'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { semesterArr } from '../../widget/define/define';
import PhotoList from '../schoolhost/statistics/PhotoList';
import ClassContact from '../schoolhost/statistics/ClassContact';
// import StudentsList from './StudentsList';
import StuBike from '../schoolhost/statistics/StuBike';
// import StuBikeLost from '../schoolhost/statistics/StuBikeLost';
import ParttimeInfo from '../schoolhost/statistics/ParttimeInfo';
import Reception from '../schoolhost/recruitment/Reception';

import RollCall from '../schoolhost/academicAffair/RollCall';
import ClassRollBook from '../schoolhost/academicAffair/ClassRollcallPage';
import ClassRollcallYearly from '../schoolhost/academicAffair/ClassRollcallYearly';
import AttendancePredictList from '../schoolhost/academicAffair/AttendancePredictList';
// import AttendanceWeekly from '../schoolhost/studentPage/List/AttendanceWeekly';
import AttendanceRate from '../schoolhost/enrollment/AttendanceRate';
import AttendanceManagement from '../schoolhost/enrollment/AttendanceManagement';
import AttendanceReport from '../schoolhost/enrollment/AttendanceReport';
import AttendanceList from '../schoolhost/enrollment/AttendanceList';

import GraduateInvest from '../schoolhost/GraduateSection/GraduateInvest';
import GraduateCollegeInvest from '../schoolhost/GraduateSection/GraduateCollegeInvest';
// import GraduateTestInvest from '../schoolhost/GraduateSection/GraduateTestInvest';
import CounselingSummary from '../schoolhost/GraduateSection/CounselingSummary';
import GraduateAward from '../schoolhost/GraduateSection/GraduateAward';
import TransitionReport from '../schoolhost/GraduateSection/TransitionReport';
import GraduateStatus from '../schoolhost/GraduateSection/GraduateStatus';
import GraduateRecord from '../schoolhost/GraduateSection/GraduateRecord';

import ClassReport from '../schoolhost/academicAffair/ClassReport';
import ClassTimetable from '../schoolhost/academicAffair/ClassTimetable';
import ClassProgressionPage from '../schoolhost/academicAffair/ClassProgressionPage';
import ClassBulletin from '../schoolhost/academicAffair/ClassBulletin';
import ClassExcel from '../schoolhost/academicAffair/ClassExcel';
import ClassSummary from '../schoolhost/academicAffair/ClassSummary';
// import InterviewStatistics from '../recruitment/xInterviewStatistics';
import StudentsList from '../schoolhost/statistics/StudentsList';
import Template from '../schoolhost/statistics/Template';
import RecommenderList from '../schoolhost/recruitment/RecommenderList';
import StudentsTable from '../schoolhost/recruitment/StudentsTable';

import StreamingManagement from '../schoolhost/Teacher/StreamingManagement';
import ClassGeneration from '../schoolhost/Teacher/ClassGeneration';
import CourseName from '../schoolhost/Teacher/CourseName';
// import CourseClass from '../schoolhost/Teacher/CourseClass';
import TeacherRoster from '../schoolhost/Teacher/TeacherRoster';
import TeacherAccount from '../schoolhost/Teacher/TeacherAccount';
import HealthyTeacher from '../schoolhost/Teacher/HealthyTeacher';

import Guidance from '../schoolhost/generalAffair/Guidance';
import Applications from '../schoolhost/generalAffair/Applications';
import ArrangeDorm from '../schoolhost/generalAffair/ArrangeDorm';
import Arrangement from '../schoolhost/generalAffair/Arrangement';
import ArrangeCondition from '../schoolhost/generalAffair/dormPage/ArrangeCondition';
import Fee from '../schoolhost/generalAffair/Fee';
import DormContact from '../schoolhost/statistics/DormContact';
import ForeignInsurance from '../schoolhost/statistics/ForeignInsurance';
import ExamList from '../schoolhost/studentPage/List/ExamList';
import Table39List from '../schoolhost/studentPage/List/Table39List';
import Instruction from '../schoolhost/studentPage/List/Instruction';
import StudentStatistics from '../schoolhost/studentPage/List/StudentStatistics';
import GraduateStatistics from '../schoolhost/GraduateSection/GraduateStatistics';
import AttendanceWeekly from '../schoolhost/studentPage/List/AttendanceWeekly';
import AttendanceWeeklyAll from '../schoolhost/studentPage/List/AttendanceWeeklyAll';

const size = 'tiny';
const TabRender = ({ component, params, ...rest }) => <Tab.Pane><Form size={size}>{React.createElement(component, { ...rest, params })}</Form></Tab.Pane>;
const TabRender2 = ({ component, params, ...rest }) => <Form size={size}>{React.createElement(component, { ...rest, ...params })}</Form>;

const menuTitle = (str1, str2 = '', LEN = 4) =>
  <>
    <div style={{ marginBottom: '8px' }}>{str1}</div>
    {str2 ? <>{str2.substr(0, LEN)}</> : null}
    {str2.length >= LEN ? <><br />{str2.substr(LEN, LEN)}</> : null}
    {str2.length >= LEN * 2 ? <><br />{str2.substr(LEN * 2, LEN)}</> : null}
    {str2.length >= LEN * 3 ? <><br />{str2.substr(LEN * 3, LEN)}</> : null}
    {str2.length >= LEN * 4 ? <><br />{str2.substr(LEN * 4, LEN)}</> : null}
  </>;

export default class SectionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list1: props.match.params.list1 || '0',
      list2: props.match.params.list2 || '0',
      classId: '-1',
      yearsemester: `${this.props.semesterYear},${this.props.semester}`,
      semesterYear: this.props.semesterYear,
      semester: this.props.semester,
      students: [],
      classroom: []
    };
  }

  async componentDidMount() {

    const { companyObj } = this.props
    const students = await Parse.queryData('Students', { companyId: companyObj.objectId });
    const classroom = await Parse.queryData('Classroom', { companyId: companyObj.objectId });
    const teachers = await Parse.queryData('Teachers', { companyId: companyObj.objectId });
    const teachersArrange = await Parse.queryData('TeachersArrange', { companyId: companyObj.objectId });
    const calendar = await Parse.queryData('Calendar', { companyId: companyObj.objectId });
    this.setState({ students, classroom, calendar, studentsL: true, classroomL: true, teachers, teachersArrange }, () => this.handleFetch())
  }

  componentWillReceiveProps(nextProps) {
    const { match } = nextProps
    const { list0 = "0" } = match.params;
    if (nextProps.match.params.list0 !== this.props.match.params.list0) {
      this.setState({ list1: '0' })
      //   if (list0 === '3') {
      //     this.setState({
      //       year: (this.props.semester === 'A' ? `${Number(this.props.semesterYear) + 1}` : this.props.semesterYear),
      //       semes: (this.props.semester === 'A' ? 'S' : 'A'),
      //       yearsemester: (this.props.semester === 'A' ? `${Number(this.props.semesterYear) + 1},S` : `${this.props.semesterYear},A`),
      //     })
      //   } else if (list0 === '17') {
      //     this.setState({
      //       year: `${Number(this.props.semesterYear) + 1}`,
      //       semes: this.props.semester,
      //       yearsemester: `${Number(this.props.semesterYear) + 1},${this.props.semester}`,
      //     })
      //   } else {
      //     this.setState({
      //       year: this.props.semesterYear,
      //       semes: this.props.semester,
      //       yearsemester: `${this.props.semesterYear},${this.props.semester}`,
      //     })
      //   }
    } else if (nextProps.semesterYear !== this.props.semesterYear || nextProps.semester !== this.props.semester)
      this.setState({ classId: '-1' }, () => this.handleFetch(nextProps))
    else
      this.handleFetch(nextProps);
  };

  handleFetch = (props = this.props) => {
    const { classroom, gradeL, teachersArrangeL, classroomL, classroomSeatL, studentsL, teachersL, dormsL, handleLazyLoad } = props;

    if (!studentsL) {
      this.handleLazyLoad('Students', 'student');
      this.setState({ studentsL: true })
    }
    // if (!gradeL) {
    //   handleLazyLoad('grade');
    // }
    if (!teachersL) {
      this.handleLazyLoad('Teachers', 'teachers');
      this.setState({ teachersL: true })
    }
    // // if (!dormsL) {
    // //   handleLazyLoad('dormsL');
    // // }
    if (!classroomL) {
      this.handleLazyLoad('Classroom', 'classroom');
      this.setState({ classroomL: true })
    }
  }

  handleLazyLoad = async (db, stateName) => {
    const { companyObj } = this.props
    const data = await Parse.queryData(db, { companyId: companyObj.objectId });
    this.setState({ [db]: data })
  }

  handleTabChange = (e, { activeindex }) => {
    const { match, history, doc } = this.props
    const { list0 = "0" } = match.params;
    history.push(`/admin/section/${list0}/${activeindex}`);
    this.setState({ list1: activeindex + '', list2: '0' });
  }

  handleTab2Change = (e, { activeIndex }) => {
    const { match, history, doc } = this.props
    const { list0 = "1" } = match.params;
    const { list1 } = this.state
    this.setState({ list2: activeIndex + '' }, () => history.push(`/admin//section/${list0}/${list1}/${activeIndex}`));
  }

  panes = [
    [ // list0: 0
      // { // list1: 0
      //   name: '111', icon: 'address card', text: '1-1 名簿管理', menu: [
      //     { menuItem: '1-1-① 名刺手帳', render: () => <TabRender {...this.props} component={Reception} params={{ dbKey: 'rcptNamecard' }} /> },
      //   ]
      // },
      {
        name: 'appointment', icon: 'group', text: '1-1 接客管理', menu: [
          { menuItem: '1-1-① 名刺手帳', render: () => <TabRender {...this.props} component={Reception} params={{ dbKey: 'rcptNamecard' }} /> },
          { menuItem: '1-1-② 電話応対記録表', render: () => <TabRender {...this.props} component={Reception} params={{ dbKey: 'rcptTelelist' }} /> },
          { menuItem: '1-1-③ 来客予定表', render: () => <TabRender {...this.props} component={Reception} params={{ dbKey: 'rcptGuest1' }} /> },
          { menuItem: '1-1-④ 接客記録表', render: () => <TabRender {...this.props} component={Reception} params={{ dbKey: 'rcptGuest2' }} /> },
        ]
      },
      {
        name: 'receptMail', icon: 'mail', text: '1-2 受発信管理', menu: [
          { menuItem: '1-2-① 受信管理表', render: () => <TabRender {...this.props} component={Reception} params={{ dbKey: 'rcptMail1' }} /> },
          { menuItem: '1-2-② 発信管理表', render: () => <TabRender {...this.props} component={Reception} params={{ dbKey: 'rcptMail2' }} /> },
        ]
      },
      // {
      //   name: '', icon: 'mail square', text: '1-4 発信管理', menu: [
      //   ]
      // },
      {
        name: 'shipManagement', icon: 'shipping', text: '1-3 出入荷管理', menu: [
          { menuItem: '1-3-① 入荷管理表 ', render: () => <TabRender {...this.props} component={Reception} params={{ dbKey: 'rcptParcel1' }} /> },
          { menuItem: '1-3-② 出荷管理表', render: () => <TabRender {...this.props} component={Reception} params={{ dbKey: 'rcptParcel2' }} /> },
        ]
      },
      // {
      //   name: '', icon: 'shipping', text: '1-6 出荷管理', menu: [
      //     { menuItem: '1-6-① 出荷管理表', render: () => <TabRender {...this.props} component={Reception} params={{ dbKey: 'rcptParcel2' }} /> },
      //   ]
      // },
      {
        name: 'prManagement', icon: 'television', text: '1-4 広報管理', menu: [
          { menuItem: '1-4-① ネット 広報管理表', render: () => <TabRender {...this.props} component={Reception} params={{ dbKey: 'rcptInternetAd' }} /> },
          { menuItem: '1-4-② 新聞雑誌広告管理表', render: () => <TabRender {...this.props} component={Reception} params={{ dbKey: 'rcptAd' }} /> },
          { menuItem: '1-4-③ Pamphlet管理表 ', render: () => <TabRender {...this.props} component={Reception} params={{ dbKey: 'rcptPamphlet' }} /> },
          { menuItem: '1-4-④ 内外学校案内説明会', render: () => <TabRender {...this.props} component={Reception} params={{ dbKey: 'rcptIntro' }} /> },
          { menuItem: '1-4-⑤ 推薦者、募集センタ一覧表', render: () => <TabRender {...this.props} component={Reception} params={{ dbKey: 'rcptAgent' }} /> },
        ]
      },
      {
        name: 'inquiryResponse', icon: 'conversation', text: '1-5 問合対応', menu: [
          { menuItem: '1-5-① 入学問合管理表', render: () => <TabRender {...this.props} component={Reception} params={{ dbKey: 'rcptInquiry' }} /> },
        ]
      },
    ],
    [ // list0: 1
      { name: 'PhotoList', icon: 'wordpress forms', text: '2-1-①', text2: '入学願書', component: Template },
      { name: 'PhotoList', icon: 'thumbs up outline', text: '2-1-②', text2: '学院長宛入学保証書', component: Template },
      { name: 'PhotoList', icon: 'clipboard outline', text: '2-1-③', text2: '学生調査票', component: Template },
      { name: 'PhotoList', icon: 'table', text: '2-1-④', text2: '入学願書・面接選考表', component: Template },
      { name: 'PhotoList', icon: 'certificate', text: '2-1-⑤', text2: '入学許可発給台帳', component: Template },
    ],
    [
      { name: 'PhotoList', icon: 'users', text: '2-2-①', text2: '在留資格申請書類管理表', component: Template },
      { name: 'PhotoList', icon: 'student', text: '2-2-②', text2: '入学予定者一覧', component: Template },
      { name: 'PhotoList', icon: 'file alternate outline', text: '2-2-③', text2: '在留資格認定証明書交付申請書', component: Template },
      { name: 'PhotoList', icon: 'list alternate outline', text: '2-2-④', text2: '履歴書(その１～３)', component: Template },
      { name: 'PhotoList', icon: 'pencil alternate', text: '2-2-⑤', text2: '質問書', component: Template },
      { name: 'PhotoList', icon: 'money', text: '2-2-⑥', text2: '経費支弁', component: Template },
      { name: 'PhotoList', icon: 'plus square outline', text: '2-2-⑦', text2: '資格申請資料追加願', component: Template },
      { name: 'PhotoList', icon: 'undo', text: '2-2-⑧', text2: '資格申請取り下げ願', component: Template },
      { name: 'PhotoList', icon: 'add user', text: '2-2-⑨', text2: '在留資格認定書交付・受渡一覧', component: Template },
      { name: 'PhotoList', icon: 'user delete', text: '2-2-⑩', text2: '在留資格認定不交付・受渡一覧', component: Template },
      { name: 'PhotoList', icon: 'times rectangle', text: '2-2-⑪', text2: '領事査証拒否等不入学者一覧', component: Template },
      { name: 'PhotoList', icon: 'tablet alternate', text: '2-2-⑫', text2: '入管電子届出システム登録届書', component: Template },
      { name: 'PhotoList', icon: 'file alternate', text: '2-2-⑬', text2: '申請等取次申出書', component: Template },
      { name: 'PhotoList', icon: 'newspaper outline', text: '2-2-⑭', text2: '年度申請取次事績報告', component: Template },
    ],
    // [
    //   { name: 'inquiry', icon: 'tty', text: '2-3-①', text2: '入学問合', component: StudentsTable, params: { tablename: 'inquiry', title: '入学問合' } },
    //   { name: 'selection', icon: 'file alternate', text: '2-3-②', text2: ' 選考結果', component: StudentsTable, params: { tablename: 'selection', title: '選考結果' } }, // 有
    //   { name: 'immigrationApplication', icon: 'university', text: '2-3-③', text2: '入管申請', component: StudentsTable, params: { tablename: 'immigrationApplication', title: '入管申請' } },
    //   { name: 'admissionPermit', icon: 'window restore', text: '2-3-④', text2: '入学許可書', component: StudentsTable, params: { tablename: 'admissionPermit', title: '入学許可書' } }, // 有
    //   { name: 'attendanceList', icon: 'plane', text: '2-3-⑤', text2: '来日予定', component: StudentsTable, params: { tablename: 'attendanceList', title: '来日予定' } }, // 有
    //   { name: 'applyFail', icon: 'user x', text: '2-3-⑥', text2: '在留資格認定不合格', component: StudentsTable, params: { tablename: 'applyFail', title: '在留資格認定不合格' } },
    //   { name: 'certainlyEntryList', icon: 'pencil alternate', text: '2-3-⑦', text2: '入学予定申請者', component: StudentsTable, params: { tablename: 'certainlyEntryList', title: '入学予定申請者' } },
    //   { name: 'applyEntryList', icon: 'pencil alternate', text: '2-3-⑧', text2: '受入れリスト', component: StudentsTable, params: { tablename: 'applyEntryList', title: '受入れリスト' } },
    //   { name: 'applyResultList', icon: 'pencil alternate', text: '2-3-⑨', text2: '申請結果記録表', component: StudentsTable, params: { tablename: 'applyResultList', title: '申請結果記録表' } },
    //   { name: 'allRecommendList', icon: 'handshake outline', text: '2-3-⑩', text2: '推薦学生', component: StudentsTable, params: { tablename: 'allRecommendList', title: '推薦学生' } },
    //   { name: 'delstudents', icon: 'delete', text: '2-3-⑪', text2: '削除学生', component: StudentsTable, params: { tablename: 'delstudents', title: '削除学生' } },
    //   { name: 'interviewStatistics', icon: 'user', text: '2-3-⑫', text2: '面接紀錄一覧表', component: StudentsTable, params: { tablename: 'interviewAll', title: '面接紀錄一覧表' } },
    //   { name: 'recommenderList', icon: 'user', text: '2-3-⑬', text2: '推薦先実績一覧表', component: RecommenderList },
    // ],
    [
      // { name: 'inquiry', icon: 'tty', text: '2-1-①', text2: '入学問合', component: StudentsTable, params: { tablename: 'inquiry', title: '入学問合' } },
      // { name: 'selection', icon: 'file alternate', text: '2-1-②', text2: ' 選考結果', component: StudentsTable, params: { tablename: 'selection', title: '選考結果' } }, // 有
      // { name: 'admissionPermit', icon: 'window restore', text: '2-1-③', text2: '入学許可書', component: StudentsTable, params: { tablename: 'admissionPermit', title: '入学許可書' } }, // 有
      // { name: 'immigrationApplication', icon: 'university', text: '2-1-④', text2: '入管申請', component: StudentsTable, params: { tablename: 'immigrationApplication', title: '入管申請' } },
      // { name: 'attendanceList', icon: 'plane', text: '2-1-⑤', text2: '来日予定', component: StudentsTable, params: { tablename: 'attendanceList', title: '来日予定' } }, // 有
      // { name: 'certainlyEntryList', icon: 'pencil alternate', text: '2-1-⑥', text2: '入学予定申請者', component: StudentsTable, params: { tablename: 'certainlyEntryList', title: '入学予定申請者' } },
      // { name: 'applyFail', icon: 'user x', text: '2-1-⑦', text2: '在留資格認定不合格', component: StudentsTable, params: { tablename: 'applyFail', title: '在留資格認定不合格' } },
      // { name: 'applyEntryList', icon: 'pencil alternate', text: '2-1-⑧', text2: '受入れリスト', component: StudentsTable, params: { tablename: 'applyEntryList', title: '受入れリスト' } },
      // { name: 'applyResultList', icon: 'pencil alternate', text: '2-1-⑨', text2: '申請結果記録表', component: StudentsTable, params: { tablename: 'applyResultList', title: '申請結果記録表' } },
      // { name: 'allRecommendList', icon: 'handshake outline', text: '2-1-⑩', text2: '推薦学生', component: StudentsTable, params: { tablename: 'allRecommendList', title: '推薦学生' } },
      // { name: 'delstudents', icon: 'delete', text: '2-1-⑪', text2: '削除学生', component: StudentsTable, params: { tablename: 'delstudents', title: '削除学生' } },
      // { name: 'interviewStatistics', icon: 'user', text: '2-1-⑫', text2: '面接紀錄一覧表', component: StudentsTable, params: { tablename: 'interviewAll', title: '面接紀錄一覧表' } },
      // { name: 'recommenderList', icon: 'user', text: '2-1-⑬', text2: '推薦先実績一覧表', component: RecommenderList },
      // { name: 'recommenderList', icon: 'user', text: '2-1-⑭', text2: '学生名簿', component: StudentsTable, params: { tablename: 'studentList', title: '学生名簿' } },
      // { name: 'recommenderList', icon: 'user', text: '2-1-⑮', text2: '[入管] 申請者一覧', component: StudentsTable, params: { tablename: 'applicantList', title: '申請者一覧' } },

      { name: 'selection', icon: 'file alternate', text: '2-1-①', text2: ' 選考結果許可証発給一覧表', component: StudentsTable, params: { tablename: 'selection', title: '選考結果許可証発給一覧表', semesterStatus: 'recruit' } }, // 有
      // { name: 'admissionPermit', icon: 'window restore', text: '2-1-②', text2: '入学許可書', component: StudentsTable, params: { tablename: 'admissionPermit', title: '入学許可書' } }, // 有
      { name: 'recommenderList', icon: 'user', text: '2-1-②', text2: '[入管] 申請者一覧表', component: StudentsTable, params: { tablename: 'applicantList', title: '申請者一覧表', semesterStatus: 'recruit' } },
      { name: 'immigrationApplication', icon: 'university', text: '2-1-③', text2: '在留資格認定交付者一覧表', component: StudentsTable, params: { tablename: 'immigrationApplication', title: '在留資格認定交付者一覧表', semesterStatus: 'recruit' } },
      { name: 'arrivalList', icon: 'plane', text: '2-1-④', text2: '来日予定', component: StudentsTable, params: { tablename: 'arrivalList', title: '来日予定一覧表', semesterStatus: 'recruit' } }, // 有
      // { name: 'certainlyEntryList', icon: 'pencil alternate', text: '2-1-⑤', text2: '入学予定申請者', component: StudentsTable, params: { tablename: 'certainlyEntryList', title: '入学予定申請者' } },
      { name: 'applyFail', icon: 'user x', text: '2-1-⑥', text2: '在留資格認定不交付者一覧表', component: StudentsTable, params: { tablename: 'applyFail', title: '在留資格認定不交付者一覧表', semesterStatus: 'recruit' } },
      // { name: 'applyEntryList', icon: 'pencil alternate', text: '2-1-⑦', text2: '受入れリスト', component: StudentsTable, params: { tablename: 'applyEntryList', title: '受入れリスト' } },
      // { name: 'applyResultList', icon: 'pencil alternate', text: '2-1-⑧', text2: '申請結果記録表', component: StudentsTable, params: { tablename: 'applyResultList', title: '申請結果記録表' } },
      { name: 'allRecommendList', icon: 'handshake outline', text: '2-1-⑨', text2: '学生推薦先一覧表', component: StudentsTable, params: { tablename: 'allRecommendList', title: '学生推薦先一覧表', semesterStatus: 'recruit' } },
      { name: 'delstudents', icon: 'delete', text: '2-1-⑩', text2: '削除学生一覧表', component: StudentsTable, params: { tablename: 'delstudents', title: '削除学生一覧表', semesterStatus: 'recruit' } },
      { name: 'interviewStatistics', icon: 'user', text: '2-1-⑪', text2: '面接紀錄一覧表', component: StudentsTable, params: { tablename: 'interviewAll', title: '面接紀錄一覧表', semesterStatus: 'recruit' } },
      { name: 'recommenderList', icon: 'user', text: '2-1-⑫', text2: '推薦先実績一覧表', component: RecommenderList },
      { name: 'recommenderList', icon: 'user', text: '2-1-⑬', text2: '学生名簿', component: StudentsTable, params: { tablename: 'studentList', title: '学生名簿', semesterStatus: 'recruit' } },
    ],
    [
      { name: 'PhotoList', icon: 'calendar alternate outline', text: '3-1-①', text2: '来日予定表', component: Template },
      { name: 'PhotoList', icon: 'table', text: '3-1-②', text2: '入学受付管理表', component: Template },
    ],
    [
      { name: 'PhotoList', icon: 'file alternate outline', text: '3-2-①', text2: '資格外活動申請書', component: Template },
      { name: 'PhotoList', icon: 'hand paper outline', text: '3-2-②', text2: 'クラス資格外活動調査表', component: Template },
      { name: 'PhotoList', icon: 'list alternate outline', text: '3-2-③', text2: '資格外活動調査一覧表', component: Template },
      { name: 'PhotoList', icon: 'bullhorn', text: '3-2-④', text2: '学生休暇届', component: Template },
      { name: 'PhotoList', icon: 'clipboard outline', text: '3-2-⑤', text2: '欠席願(届)', component: Template },
      { name: 'PhotoList', icon: 'list alternate', text: '3-2-⑥', text2: '学生欠席休暇届一覧表', component: Template },
      { name: 'PhotoList', icon: 'th list', text: '3-2-⑦', text2: '出席率等にかかる日本教機関からの報告', component: Template },
      { name: 'PhotoList', icon: 'edit outline', text: '3-2-⑧', text2: '在留期間更新申請書', component: Template },
      { name: 'PhotoList', icon: 'home', text: '3-2-⑨', text2: '滞在費に関する申告書', component: Template },
      { name: 'PhotoList', icon: 'window close outline', text: '3-2-⑩', text2: '休学・自主退学申請書', component: Template },
      { name: 'PhotoList', icon: 'address card outline', text: '3-2-⑪', text2: '休学・自主退学一覧表', component: Template },
      { name: 'PhotoList', icon: 'file outline', text: '3-2-⑫', text2: '復学後の在籍予定報告書', component: Template },
      { name: 'PhotoList', icon: 'file', text: '3-2-⑬', text2: '除籍処分稟議書', component: Template },
    ],
    [
      { name: 'PhotoList', icon: 'file', text: '3-3-①', text2: '除籍・退学者に係る日本教育機関からの報告', component: Template },
      { name: 'PhotoList', icon: 'address card outline', text: '3-3-②', text2: '定期報告刑事犯特別犯の概要', component: Template },
      { name: 'PhotoList', icon: 'file alternate outline', text: '3-3-③', text2: '資格外活動の概要', component: Template },
      { name: 'PhotoList', icon: 'user', text: '3-3-④', text2: '所在不明となった学生の概要に関する報告', component: Template },
      { name: 'PhotoList', icon: 'user outline', text: '3-3-⑤', text2: '中長期在留者の受け入れに関する報告', component: Template },
      { name: 'PhotoList', icon: 'clipboard outline', text: '3-3-⑥', text2: '設置者‣校長・教職員の変更提出報告', component: Template },
      { name: 'PhotoList', icon: 'list alternate', text: '3-3-⑦', text2: '校長・主任・教員の個人調書', component: Template },
    ],
    [
      // { name: 'registeredStudents', icon: 'address book', text: '3-4-①', text2: '在籍生一覧', component: StudentsTable, params: { tablename: 'registeredStudents', title: '在籍生一覧' } },
      // { name: 'studentCard', icon: 'id card', text: '3-4-②', text2: '学生証', component: StudentsTable, params: { tablename: 'studentCard', title: '学生証' } },
      // { name: 'insurance', icon: 'info circle', text: '3-4-③', text2: '区役所、保険', component: StudentsTable, params: { tablename: 'insurance', title: '区役所、保険' } },
      // { name: 'parttime', icon: 'briefcase', text: '3-4-④', text2: 'アルバイト', component: StudentsTable, params: { tablename: 'parttime', title: 'アルバイト' } },
      // // { name: 'bike', icon: 'bicycle', text: '3-4-⑤', text2: '自転車', component: StudentsTable, params: { tablename: 'bike' } },
      // // { name: 'classContact', icon: 'pen square', text: '3-4-⑥', text2: '学生連絡簿', component: StudentsTable, params: { tablename: 'classContact' } },
      // { name: 'instruction', icon: 'conversation', text: '3-4-⑤', text2: '相談記録', component: StudentsTable, params: { tablename: 'instruction', title: '相談記録' } },
      // { name: 'dropoutList', icon: 'calendar times outline', text: '3-4-⑥', text2: '自主退学', component: StudentsTable, params: { tablename: 'dropoutList', title: '自主退学' } },
      // { name: 'dropoutList2', icon: 'box', text: '3-4-⑦', text2: '除籍処分', component: StudentsTable, params: { tablename: 'dropoutList2', title: '除籍処分' } },
      // { name: 'extentDate', icon: 'ticket', text: '3-4-⑧', text2: '簽証延期', component: StudentsTable, params: { tablename: 'extentDate', title: '簽証延期' } },
      // { name: 'applyEndList', icon: 'calendar alternate', text: '3-4-⑨', text2: '受入／終了リスト', component: StudentsTable, params: { tablename: 'applyEndList', title: '受入／終了リスト' } },
      // { name: 'graduateSummary', icon: 'graduation cap', text: '3-4-⑩', text2: '卒業生一覧', component: StudentsTable, params: { tablename: 'graduateSummary', title: '卒業生一覧' } },
      // // { name: '', icon: '', text: '' },
      { name: 'registeredStudents', icon: 'address book', text: '3-1-①', text2: '在籍生一覧表', component: StudentsTable, params: { tablename: 'registeredStudents', title: '在籍生一覧表' } },
      { name: 'attendanceList', icon: 'plane', text: '3-1-②', text2: '来日予定一覧表', component: StudentsTable, params: { tablename: 'pickUp', title: '来日予定一覧表' } }, // 有
      { name: 'studentCard', icon: 'id card', text: '3-1-③', text2: '学生証期限一覽表', component: StudentsTable, params: { tablename: 'studentCard', title: '学生証期限一覽表' } },
      { name: 'insurance', icon: 'info circle', text: '3-1-④', text2: '区役所、保険一覧表', component: StudentsTable, params: { tablename: 'insurance', title: '区役所、保険一覧表' } },
      { name: 'parttime', icon: 'briefcase', text: '3-1-⑤', text2: 'アルバイト一覧表', component: StudentsTable, params: { tablename: 'parttime', title: 'アルバイト一覧表' } },
      // { name: 'bike', icon: 'bicycle', text: '3-4-⑤', text2: '自転車', component: StudentsTable, params: { tablename: 'bike' } },
      // { name: 'classContact', icon: 'pen square', text: '3-4-⑥', text2: '学生連絡簿', component: StudentsTable, params: { tablename: 'classContact' } },
      // { name: 'instruction', icon: 'conversation', text: '3-1-⑥', text2: '相談記録一覧表', component: StudentsTable, params: { tablename: 'instruction', title: '相談記録一覧表' } },
      { name: 'dropoutList', icon: 'calendar times outline', text: '3-1-⑦', text2: '自主退学一覧表', component: StudentsTable, params: { tablename: 'dropoutList', title: '自主退学一覧表' } },
      { name: 'dropoutList2', icon: 'box', text: '3-1-⑧', text2: '除籍処分一覧表', component: StudentsTable, params: { tablename: 'dropoutList2', title: '除籍処分一覧表' } },
      { name: 'extentDate', icon: 'ticket', text: '3-1-⑨', text2: '簽証延期一覧表', component: StudentsTable, params: { tablename: 'extentDate', title: '簽証延期一覧表' } },
      { name: 'applyEndList', icon: 'calendar alternate', text: '3-1-⑩', text2: '受入／終了リスト一覧表', component: StudentsTable, params: { tablename: 'applyEndList', title: '受入／終了リスト一覧表' } },
      { name: 'graduateSummary', icon: 'graduation cap', text: '3-1-⑪', text2: '卒業生一覧表', component: StudentsTable, params: { tablename: 'graduateSummary', title: '卒業生一覧表' } },
      { name: 'enrollBeforeAfterList', icon: 'graduation cap', text: '3-1-⑫', text2: '入学前後日本語一覽表', component: StudentsTable, params: { tablename: 'enrollBeforeAfterList', title: '入学前後日本語一覽表' } },
      // { name: '', icon: '', text: '' },
    ],
    [
      // { name: 'StudentsList', icon: 'list', text: '3-5-①', text2: 'クラス名簿', component: StudentsList },
      // { name: 'PhotoList', icon: 'list alternate', text: '3-3-②', text2: '学生住所一覧', component: DormContact },
      // { name: 'ClassContact', icon: 'list', text: '3-5-③', text2: 'クラス別連絡簿', component: ClassContact },
      // { name: 'ParttimeInfo', icon: 'list', text: '3-5-④', text2: 'クラス資格外活動情報一覧表', component: ParttimeInfo },
      // { name: 'PhotoList', icon: 'picture', text: '3-5-⑤', text2: '生徒写真一覧表', component: PhotoList },
      // { name: 'StuBike', icon: 'bicycle', text: '3-5-⑥', text2: '自転車一覧', component: StuBike },
      // // { name: 'StuBikeLost', icon: 'bicycle', text: '3-5-⑦', text2: '自転車紛失一覧', component: StuBikeLost },
      // // { name: 'PhotoList', icon: 'list alternate', text: '3-5-⑧', text2: 'ビザ．外国人登録．健康保険証一覧表', component: ForeignInsurance },
      // { name: 'PhotoList', icon: 'list alternate', text: '3-5-⑦', text2: 'ビザ．外国人登録．健康保険証一覧表', component: ForeignInsurance },
      // // { name: 'PhotoList', icon: 'list alternate', text: '3-3-', text2: '出席率出力', component: Template },
      // // { name: 'PhotoList', icon: 'list alternate', text: '3-5-⑨', text2: '校外リスト一覧', component: ExamList },
      // // { name: 'PhotoList', icon: 'list alternate', text: '3-5-⑩', text2: '特別指導学生一覧', component: Table39List },
      // { name: 'PhotoList', icon: 'list alternate', text: '3-5-⑧', text2: '校外テスト一覧', component: ExamList },
      // { name: 'PhotoList', icon: 'list alternate', text: '3-5-⑨', text2: '特別指導学生一覧', component: Table39List },
      { name: 'StudentsList', icon: 'list', text: '3-2-①', text2: '在学生名簿', component: StudentsList },
      { name: 'PhotoList', icon: 'list alternate', text: '3-2-②', text2: '学生住所一覧表', component: DormContact },
      { name: 'ClassContact', icon: 'list', text: '3-2-③', text2: 'クラス別連絡簿', component: ClassContact },
      { name: 'ParttimeInfo', icon: 'list', text: '3-2-④', text2: 'クラス資格外活動情報一覧表', component: ParttimeInfo },
      { name: 'PhotoList', icon: 'picture', text: '3-2-⑤', text2: '生徒写真一覧表', component: PhotoList },
      { name: 'StuBike', icon: 'bicycle', text: '3-2-⑥', text2: '自転車一覧表', component: StuBike },
      // { name: 'StuBikeLost', icon: 'bicycle', text: '3-5-⑦', text2: '自転車紛失一覧', component: StuBikeLost },
      // { name: 'PhotoList', icon: 'list alternate', text: '3-5-⑧', text2: 'ビザ．外国人登録．健康保険証一覧表', component: ForeignInsurance },
      { name: 'PhotoList', icon: 'list alternate', text: '3-2-⑦', text2: '在留期限・在留カード・健康保険証一覧表', component: ForeignInsurance },
      // { name: 'PhotoList', icon: 'list alternate', text: '3-3-', text2: '出席率出力', component: Template },
      // { name: 'PhotoList', icon: 'list alternate', text: '3-5-⑨', text2: '校外リスト一覧', component: ExamList },
      // { name: 'PhotoList', icon: 'list alternate', text: '3-5-⑩', text2: '特別指導学生一覧', component: Table39List },
      { name: 'PhotoList', icon: 'list alternate', text: '3-2-⑧', text2: '校外テスト一覧表', component: ExamList },
      { name: 'Instruction', icon: 'conversation', text: '3-2-⑨', text2: '相談記録一覧表', component: Instruction },
      { name: 'PhotoList', icon: 'list alternate', text: '3-2-⑩', text2: '特別指導学生一覧表', component: Table39List },
      { name: 'PhotoList', icon: 'pie chart', text: '3-2-⑪', text2: '在籍生統計', component: StudentStatistics },
    ],
    [
      { name: 'rollCall', icon: 'buromobelexperte', text: '4-1-①', text2: '出欠確認', component: RollCall },
      { name: 'attendanceManagement', icon: 'window restore', text: '4-1-②', text2: 'クラス出席数一覽表', component: AttendanceManagement, params: {} },
      { name: 'attendanceList', icon: 'pencil alternate', text: '4-1-③', text2: '出席狀況(週別)一覽表', component: AttendanceList, params: {} },
      { name: 'attendanceRate', icon: 'list alternate', text: '4-1-④', text2: '出席率(トータル)', component: AttendanceRate, params: { type: '1' } },
      { name: 'attendanceRate2', icon: 'list alternate outline', text: '4-1-⑤', text2: '出席率(月別)', component: AttendanceRate, params: { type: '2' } },
      { name: 'attendanceRate3', icon: 'list alternate outline', text: '4-1-⑥', text2: '出席率(週別)', component: AttendanceRate, params: { type: '3' } },
      { name: 'attendanceRate4', icon: 'list alternate outline', text: '4-1-⑦', text2: '出席率(期間指定)', component: AttendanceRate, params: { type: '4' } },
      // { name: 'attendancePrediction', icon: 'question circle', text: '4-1-⑧', text2: '出席率予測', component: AttendancePrediction, params: {} },
      // { name: 'rollCall2', icon: 'file alternate', text: '4-1-⑨', text2: '出席編集', component: Template, params: {} },
      { name: 'classRollBook', icon: 'table', text: '4-1-⑧', text2: '出席簿(週別)', component: ClassRollBook, params: { type: '1' } },
      // { name: 'classRollBook', icon: 'table', text: '4-1-⑨', text2: '出席簿(年別)', component: ClassRollcallYearly, params: { type: '1' } },
      { name: 'attendancePredict', icon: 'table', text: '4-1-⑨', text2: '個人出席率予測', component: AttendancePredictList, params: { type: '1' } },
      { name: 'attendanceReport', icon: 'pencil alternate', text: '4-1-⑩', text2: '学生出席状況報告書', component: AttendanceReport },
      // { name: 'classRollBook2', icon: 'table', text: '4-1-⑪', text2: '聴講生出席簿(週別)', component: ClassRollBook, params: { type: '2' } },
      // { name: 'attendanceWeekly', icon: 'id card', text: '4-1-⑫', text2: '個人票', component: AttendanceWeekly, params: {} },
    ],
    [
      { name: 'classReport', icon: 'pencil alternate', text: '4-3-①', text2: 'クラス状況報告', component: ClassReport, params: {} },
      { name: 'classTimetable', icon: 'table', text: '4-3-②', text2: '配当表', component: ClassTimetable, params: {} },
      { name: 'classProgression', icon: 'comments', text: '4-3-③', text2: '進度連絡', component: ClassProgressionPage, params: {} },
      { name: 'classSummary', icon: 'th', text: '4-3-④', text2: '授業時間配当表', component: ClassSummary, params: { type: '1' } },
      { name: 'classBulletin', icon: 'bullhorn', text: '4-3-⑤', text2: '学校掲示板', component: ClassBulletin, params: {} },
      { name: 'AttendanceWeeklyAll', icon: 'table', text: '4-3-⑥', text2: 'クラス出席率個人票', component: AttendanceWeeklyAll, params: {} },
      { name: 'ClassExcel', icon: 'list alternate', text: '4-3-⑦', text2: 'クラス \nEXCEL', component: ClassExcel, params: {} },
    ],
    [
      { name: 'graduateTestInvest', icon: 'university', text: '4-4-①', text2: '進学受験結果一覧表', component: GraduateInvest, params: {} },
      // { name: 'counselingSummary', icon: 'list alternate', text: '4-4-②', text2: '進路結果一覧表', component: CounselingSummary, params: {} },
      { name: 'graduateCollegeInvest', icon: 'clipboard', text: '4-4-②', text2: '進路学校調査一覧表', component: GraduateCollegeInvest, params: {} },
      // { name: 'graduateInvest', icon: 'clipboard', text: '4-4-', text2: '進路希望調査', component: GraduateInvest, params: {} },
      { name: 'graduateAward', icon: 'winner', text: '4-4-③', text2: '卒業生受賞一覧表', component: GraduateAward, params: {} },
      { name: 'transitionReport', icon: 'list alternate outline', text: '4-4-④', text2: '推移表', component: TransitionReport, params: {} },
      { name: 'graduateStatus', icon: 'graduation cap', text: '4-4-⑤', text2: '卒業生の進路状況一覧表', component: GraduateStatus, params: {} },
      { name: 'GraduateStatistics', icon: 'pie chart', text: '4-4-⑥', text2: '卒業生の進路分析表', component: GraduateStatistics, params: {} },
      { name: 'GraduateRecord', icon: 'list alternate outline', text: '4-4-⑦', text2: '大学進学調查', component: GraduateRecord, params: {} },
    ],
    [
      { name: 'PhotoList', icon: 'dollar', text: '5-2-①', text2: '資産管理表', component: Template },
      { name: 'PhotoList', icon: 'warehouse', text: '5-2-②', text2: '倉庫管理表', component: Template },
      { name: 'PhotoList', icon: 'recycle', text: '5-2-③', text2: '清掃管理表', component: Template },
      { name: 'PhotoList', icon: 'boxes', text: '5-2-④', text2: '備品購入申請表', component: Template },
      { name: 'PhotoList', icon: 'wrench', text: '5-2-⑤', text2: '修繕記録表', component: Template },
      { name: 'PhotoList', icon: 'list alternate outline', text: '5-2-⑥', text2: '学生入寮申込', component: Template },
      { name: 'PhotoList', icon: 'file alternate outline', text: '5-2-⑦', text2: '契約書', component: Template },
      { name: 'PhotoList', icon: 'edit', text: '5-2-⑧', text2: '調査表', component: Template },
      { name: 'PhotoList', icon: 'envelope outline', text: '5-2-⑨', text2: '入寮案内', component: Template },
      { name: 'PhotoList', icon: 'picture', text: '5-2-⑩', text2: 'OOO', component: Template },
      { name: 'PhotoList', icon: 'list alternate', text: '5-2-⑪', text2: '管理表', component: Template },
      { name: 'PhotoList', icon: 'clipboard outline', text: '5-2-⑫', text2: '備品一覧表', component: Template },
      { name: 'PhotoList', icon: 'clipboard', text: '5-2-⑬', text2: '定期点検表', component: Template },
      { name: 'PhotoList', icon: 'file alternate', text: '5-2-⑭', text2: '退居管理表', component: Template },
    ],
    [
      { name: 'guidance', icon: 'book', text: '5-3-①', text2: '案内', component: Guidance },
      { name: 'applications', icon: 'list alternate outline', text: '5-3-②', text2: '受付', component: Applications },
      { name: 'arrangeDorm', icon: 'hand point right outline', text: '5-3-③', text2: '予想', component: ArrangeDorm },
      { name: 'arrangeDorm', icon: 'hand point right outline', text: '5-3-③', text2: '予想狀況', component: ArrangeCondition },
      { name: 'arrangement', icon: 'calendar plus outline', text: '5-3-④', text2: '安排', component: Arrangement },
      { name: 'fee', icon: 'credit card outline', text: '5-3-⑤', text2: '収費', component: Fee },
      // { name: 'guidance', icon: 'picture', text: '5-2-①', text2: '案内', component: Template },
      // { name: 'applications', icon: 'picture', text: '5-2-②', text2: '受付', component: Template },
      // { name: 'arrangeDorm', icon: 'picture', text: '5-2-③', text2: '予想', component: Template },
      // { name: 'arrangement', icon: 'picture', text: '5-2-④', text2: '安排', component: Template },
      // { name: 'fee', icon: 'picture', text: '5-2-⑤', text2: '収費', component: Template },
    ],
    [
      { name: 'PhotoList', icon: 'list alternate outline', text: '5-3-①', text2: '学生寮一覧表', component: Template },
    ],
    [ //9
      { name: 'PhotoList', icon: 'users', text: '6-1-①', text2: 'クラス編成', component: StreamingManagement },
      { name: 'PhotoList', icon: 'list alternate', text: '6-1-②', text2: '配当表作成', component: ClassGeneration },
      { name: 'PhotoList', icon: 'address book outline', text: '6-1-③', text2: '授業管理', component: CourseName },
      // { name: 'PhotoList', icon: 'list', text: '6-1-④', text2: '授業配当管理', component: CourseClass },
    ],
    [
      { name: 'PhotoList', icon: 'address card outline', text: '6-3-①', text2: '教員情報', component: TeacherRoster },
      { name: 'PhotoList', icon: 'plus square outline', text: '6-3-②', text2: '健康診断', component: HealthyTeacher },
      { name: 'TeacherAccount', icon: 'address card outline', text: '6-3-②', text2: '教員帳號', component: TeacherAccount },
    ],
    [
      { name: 'inquiry', icon: 'tty', text: '1-1-①', text2: '問合せ一覧表', component: StudentsTable, params: { tablename: 'inquiry', title: '問合せ一覧表' } },
    ]
    //   [
    // ],
  ]

  title = [
    '1 受付案内', //0
    '2-1 入学選考',
    '2-2 資格申請',
    // '2-3 募集生一覧',
    '2-1 募集選考一覧',
    '3-1 入学受付',
    '3-2 在学管理',
    '3-3 入管報告',//
    // '3-4 在籍生一覧',
    '3-1 在籍生一覧',
    // '3-5 各種書類印刷',
    '3-2 各種書類印刷',
    '4-1 出席率管理',
    '4-3 クラス管理',
    '4-4 進学管理',
    // '5-1 寮基本情報', // 
    // '5-1 部屋基本情報', // 
    '5-2 学生寮管理',
    '5-3 学生寮入居状況',
    '',
    '6-1 時間割管理',
    // '6-2 教員基本情報',
    '6-3 教員情報一覧',
    '1-2 入学問合一覧',
    'test'
  ]

  handleStudentSel = (event, data) => {
    let fleldVal = data.value;
    const { match, students, history } = this.props

    const obj = students.find(item => item._id === fleldVal) || {};
    this.setState({ doc: obj });
  };

  handleChange = (event, data) => {
    const { classroomAll } = this.state
    // console.log('handleChange')
    let fieldName = data.name;
    let fleldVal = data.value;

    if (fieldName === 'classId') { // 更新クラス的時候
      const { classroom } = this.state;
      const classObj = classroom.find(item => item.classSchool === fleldVal);
      const period = classObj ? (classObj.classTime === '1' ? "0" : "4") : "";
      this.setState({
        [fieldName]: fleldVal,
        period,
      });
    } else if (fieldName === 'yearsemester') {
      let semesterYear = fleldVal.substring(0, 4);
      let semester = fleldVal.substring(5);
      // const classArr = classroomAll.filter(item => item.semesterYear === semesterYear && item.semester === semester);
      console.log(semesterYear, semester)
      this.setState({ [fieldName]: fleldVal, semesterYear, semester })
    } else {
      this.setState({ [fieldName]: fleldVal });
    }
  }

  render() {
    const { match, companyObj } = this.props
    const { list0 = "0" } = match.params;
    const { list1 = '0', list2 = '0', classId, studentsL, classroomL, teachers } = this.state;
    const { activeItem, docId, classroom, doc, yearsemester, semester, semesterYear, students, calendar, teachersArrange } = this.state

    let currentStudents = students.sort((a, b) => a.semesterYear - b.semesterYear);//studentsList.sort((a, b) => a.semester - b.semester).sort((a, b) => a.semesterYear - b.semesterYear);
    let optionStudents
    optionStudents = currentStudents.sort((a, b) => ('' + a.studentId).localeCompare(b.studentId))
      .map(item => ({
        key: item.objectId, text: `${item.studentId ? `${item.semesterYear.substr(-2)}(${item.semester})${item.studentId}` : '(なし)'} ${item.jpnName}`, value: item.objectId
      }));

    let selClassArr = [];
    if (classroom.length) {
      if (!classroom.length) {
        selClassArr.push({ key: 'empty', text: '-- 無クラス --', value: '' })
      } else {
        classroom.forEach(({ objectId, classSchool, teacherText, entryYear, entryText }) =>
          selClassArr.push({ key: objectId, text: `${classSchool}クラス ${entryYear}年${entryText} ${teacherText || ''}`, value: objectId }))
        if (list0 === '9' && (list1 === '3' || list1 === '4' || list1 === '5' || list1 === '6'))
          selClassArr.unshift({ key: 'all', text: '全校', value: 'all' })
        selClassArr.unshift({ key: '000', text: '  --  選択  --  ', value: '-1' })
      }
    }
    // console.log(list0, list1, list2);
    const list1Obj = this.panes[list0][list1];
    // console.log(list2Obj)

    return (<div
      className="content"
      style={{ padding: '10px' }} >
      <h3>{this.title[list0]}</h3>
      {/* <Header as='h3'>{this.title[list0]}</Header> */}
      <Menu icon='labeled' compact pointing inverted={companyObj.inverted}>
        {this.panes[list0].map(({ name, icon, text, text2 }, index) =>
          <Menu.Item
            activeindex={index}
            key={index}
            name={name}
            active={list1 === index + ''}
            onClick={this.handleTabChange}
          >
            <Icon name={icon} size='mini' />
            {menuTitle(text, text2, 5)}
          </Menu.Item>)}
      </Menu>
      &nbsp;&nbsp;&nbsp;
      {(list0 === '9' && (list1 === '0' || list1 === '1' || list1 === '3' || list1 === '4' || list1 === '5' || list1 === '6' || list1 === '7')) ||
        (list0 === '10' && (list1 === '0' || list1 === '1' || list1 === '2' || list1 === '4' || list1 === '5' || list1 === '6')) ? <>
          <Input labelPosition='left' >
            <Label color={'blue'}> <Icon name='building' />クラス</Label>
            <Select
              style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', width: '250px' }}
              compact options={selClassArr} onChange={this.handleChange} value={classId} name='classId' />
          </Input>
        </> :
        (list0 === '15' && (list1 === '0' || list1 === '1')) ?
          <>
            <Input labelPosition='left'>
              <Label color={'pink'}> <Icon name='calendar' />学期</Label>
              <Select compact options={semesterArr} onChange={this.handleChange} value={yearsemester} name='yearsemester' />
            </Input>
          </> :
          null}
      <br />
      {this.panes[list0] && list1 && list1Obj && list1Obj.menu ? <Form>
        <br />
        <Tab
          className='no-print'
          activeIndex={list2}
          onTabChange={this.handleTab2Change}
          menu={{ pointing: true, inverted: true }}
          panes={list1Obj.menu} />
      </Form>
        : <Segment>
          {activeItem === 'attendancePrediction' || activeItem === 'attendanceWeekly' ? <>
            <Form>
              <Form.Select
                // label='学生'
                scrolling
                style={{ width: '400px', float: 'right' }}
                // fluid
                search
                options={optionStudents}
                onChange={this.handleStudentSel}
                value={docId} size="tiny" />
            </Form>
            <br />
            <br />
          </> : null}
          {classId === '-1' && ((list0 === '9' && (list1 === '0' || list1 === '1' || list1 === '3' || list1 === '4' || list1 === '5' || list1 === '6' || list1 === '7')) ||
            (list0 === '10' && (list1 === '0' || list1 === '1' || list1 === '2' || list1 === '4' || list1 === '5' || list1 === '6'))) ?
            <div style={{ minHeight: '100px' }}><Header as='h2' floated='left'>クラスを選択して下さい</Header></div> :
            <TabRender2
              {...this.props}
              // title={list1Obj.text + list1Obj.text2 ? list1Obj.text2 : ''}
              handleLazyLoad={this.handleLazyLoad}
              teachersArrange={teachersArrange}
              studentsL={studentsL}
              classroomL={classroomL}
              teachers={teachers}
              classroom={classroom}
              students={students}
              calendar={calendar}
              classId={classId}
              semester={semester}
              semesterYear={semesterYear}
              component={list1Obj.component}
              params={list1Obj.params}
            />} </Segment>}
    </div >)
  }
}
