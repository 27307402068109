import React, { Component } from 'react';
import { Form, Tabs, Tab, Card, Container, Grid, Select, Divider, Header } from 'semantic-ui-react';
import { Row, Col } from "reactstrap";

// import UploadPhoto from '../../../components/UploadPhoto/UploadPhoto';
// import UploadPhoto64 from '../../../components/UploadPhoto/UploadPhoto64';
// import UploadPhoto64B from '../../../components/UploadPhoto/UploadPhoto64B';
import UploadPhoto from '../../../widgets/UploadPhoto';

import { showField, showFieldText, getAvailblePeriodArr, getDate, getOptionsByKey } from 'views/widgets/FieldsRender';

export default class SignStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { doc, optSet, isEdit } = this.props;
    // console.log(this.props)

    // const classArr = getOptionsByKey(optSet, "classSchool").map(item => ({ key: item._id, text: item.label, value: item.value }));
    // classArr.unshift({ key: 'empty', text: '-- 選択 --', value: '' });

    return (<>
      <Header as='h2' dividing
        content='マスター情報'
        style={{ backgroundColor: 'lavender' }}
      // subheader='調査時間：2017年4月から 2018年12月 まで'
      />
      {/* <Grid columns='three' divided> */}
      <Row>
        <Col>
          <Card fluid>
            <Card.Content header='学生身分情報' className='card-title' />
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "homeName")}</Col>
                <Col>{showField(this.props, "gender")}</Col>
                {/* <Col md={3}>{showField(this.props, "courseType")}</Col> */}
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "nationality")}</Col>
                <Col>{showField(this.props, "isMarried")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "birthday")}</Col>
                <Col >{showField(this.props, "bornPlace")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col >{showField(this.props, "bornPlaceOther")}</Col>
                <Col >{showField(this.props, "bornPlace2")}</Col>
                <Col >{showField(this.props, "regionalDistinction")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "career")}</Col>
                {/* <Col>{showField(this.props, "7Country")}</Col> */}
                <Col>{showField(this.props, "scheduleVis")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "hasRecordOfEntry")}</Col>
                <Col>{showField(this.props, "historyInout")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "historyIn")}</Col>
                <Col>{showField(this.props, "historyOut")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "noGiveHistory")}</Col>
                <Col>{showField(this.props, "sceduleAfterscho")}</Col>
                {/* <Col md={6}>{showField(this.props, "homeAddr")}</Col> */}

                {/* <Col>{showField(this.props, "outputAllList")}</Col> */}
                {/* <Col>{showField(this.props, "expectedDateOfEntry")}</Col> */}
                {/* </Grid> */}
              </Row>
              {doc.sceduleAfterscho === '2' ?
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col>{showField(this.props, "wishSchoolName")}</Col>
                  <Col>{showField(this.props, "wishSubject")}</Col>
                  {/* </Grid> */}
                </Row> : null}
            </Card.Content>
          </Card>
        </Col>
        <Col>
          {/* <Card fluid>
              <Card.Content>
                <Row>
                  <Grid columns='equal'>
                    <Col>{showField(this.props, "semesterYear")}</Col>
                    <Col>{showField(this.props, "semester")}</Col>
                    <Col>{showField(this.props, "applyResult")}</Col>
                  </Grid>
                </Row>
                <Row>
                  <Grid columns='equal'>
                    <Col >{showField(this.props, "ps")}</Col>
                  </Grid>
                </Row>
              </Card.Content>
            </Card> */}
          <Card fluid>
            <Card.Content header='学生識別情報' className='card-title' />
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "semesterYear")}</Col>
                <Col>{showField(this.props, "semester")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "recruitNum")}</Col>
                <Col>{showField(this.props, "admissionLiceNum")}</Col>
                <Col>{showField(this.props, "studentId")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "jpnName")}</Col>
                <Col>{showField(this.props, "engName")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "katakanaName")}</Col>
                <Col>{showField(this.props, "applyResult")}</Col>
                {/* </Grid> */}
              </Row>
              {doc.applyResult === '2' || doc.applyResult === '3' || doc.applyResult === '4' || doc.applyResult === '7' || doc.applyResult === '6' ?
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col>{showField(this.props, "notPassReason")}</Col>
                  {/* </Grid> */}
                </Row> : null}
            </Card.Content>
          </Card>
          <Card fluid>
            <Card.Content header='学生連絡情報' className='card-title' />
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "homeCellphone")}</Col>
                <Col>{showField(this.props, "homeTel")}</Col>
                <Col>{showField(this.props, "weChat")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "qq")}</Col>
                <Col>{showField(this.props, "line")}</Col>
                <Col>{showField(this.props, "skype")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col >{showField(this.props, "facebook")}</Col>
                <Col >{showField(this.props, "email")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col sm={4}>{showField(this.props, "homePostcode")}</Col>
                <Col>{showField(this.props, "homeAddr")}</Col>
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
        </Col>
        <Col>
          <Card fluid>
            <Card.Content header='滯在費の支付方法等(2012版26項)' className='card-title' />
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col sm='2'>(1) </Col>
                <Col sm='5'>{showField(this.props, "payMyself")}</Col>
                <Col sm='5'>{showField(this.props, "outPay")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col >{showField(this.props, "payInjap")}</Col>
                <Col>{showField(this.props, "scholarship")}</Col>
                <Col>{showField(this.props, "othher")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col sm='2'>(2) </Col>
                <Col sm='5'>{showField(this.props, "carryForeignCount")}</Col>
                <Col sm='5'>{showField(this.props, "carrier")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "carryPoriod")}</Col>
                <Col>{showField(this.props, "remitTanceFromFore")}</Col>
                <Col>{showField(this.props, "otherr")}</Col>
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
          <Card fluid>
            <Card.Content header='旅券情報' className='card-title' />
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "passportNum")}</Col>
                <Col>{showField(this.props, "passportExpireDate")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "passportIssueDate")}</Col>
                <Col>{showField(this.props, "psptIssuingAuthority")}</Col>
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
          <Card fluid>
            <Card.Content header='在日連絡情報' className='card-title' />
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "jpnAddr")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "jpnTel")}</Col>
                <Col>{showField(this.props, "jpnCellphone")}</Col>
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
        </Col>
      </Row>
      {/* </Grid> */}
    </>);
  }
}