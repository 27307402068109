
import React, { Component } from 'react'
import { Container, Header, Modal, Button, Table, Form, Grid, Image, Input, Checkbox, Divider, Tab } from 'semantic-ui-react';

// reactstrap components
const webtypeSel = {
  'official': '官網', 'temple': '佛教', 'dao': '道教', 'school': '日語學校',
  'hanbury': '漢栢略', 'pawnshop': '當舖', 'dorm': '出租', 'streaming': '網路電視', 'develop': '開發用',
  'publishing': '出版社', "onlinetest": '線上測驗', 'autorepair': '修車廠', 'salesInventory': '銷貨庫存',
  'crm': '客戶關係管理', 'restaurant': '餐廳'
};

class LineMessage extends Component {
  state = {
  }

  render() {
    // const { } = this.state

    return (<div className="content">
      <Header as='h2'>
        自動回覆
        {/* <Header.Subheader>您可建立具有視覺效果的互動型選單，於聊天室中提供優惠券、網址連結及相關行銷資訊以進行宣傳。</Header.Subheader> */}
      </Header>

    </div>);
  }
}

export default LineMessage;
