
import React, { Component } from 'react'
import intl from 'react-intl-universal';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import Parse from "widget/parse";

class Register extends Component {
  state = {
    username: '',
    password: '',
    email: '',
  }
  componentDidMount() {
    document.body.classList.toggle("register-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("register-page");
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  handleSubmit = async (e) => {
    const { history, companyObj } = this.props
    const { username, password, email } = this.state
    e.preventDefault();
    if ((await Parse.signupUser(username, password, username, companyObj.objectId)) === "1") {
      history.push('/auth/login');
    }
  }

  render() {
    return (
      <div className="register-page">
        <Container>
          <Row>
            <Col className="ml-auto" lg="5" md="5">
              <div className="info-area info-horizontal mt-5">
                <div className="icon icon-primary">
                  <i className="nc-icon nc-tv-2" />
                </div>
                <div className="description">
                  <h5 className="info-title">{intl.get('雲端管理系統')}</h5>
                  <p className="description">
                    {intl.get('免安裝、免自備伺服器，帳號登入即可使用')}
                  </p>
                </div>
              </div>
              <div className="info-area info-horizontal">
                <div className="icon icon-primary">
                  <i className="nc-icon nc-html5" />
                </div>
                <div className="description">
                  <h5 className="info-title">{intl.get('HTTPS+資料庫加密')}</h5>
                  <p className="description">
                    {intl.get('我們使用HTML5和CSS3開發該網站。 客戶端可以使用GitHub訪問代碼。')}
                  </p>
                </div>
              </div>
              <div className="info-area info-horizontal">
                <div className="icon icon-info">
                  <i className="nc-icon nc-atom" />
                </div>
                <div className="description">
                  <h5 className="info-title">{intl.get('全平台支援')}</h5>
                  <p className="description">
                    {intl.get('各廠牌手機、平台、電腦，皆可使用')}
                  </p>
                </div>
              </div>
            </Col>
            <Col className="mr-auto" lg="4" md="6">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">{intl.get('Register')}</CardTitle>
                  {/* <div className="social">
                    <Button className="btn-icon btn-round" color="twitter">
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button className="btn-icon btn-round" color="dribbble">
                      <i className="fa fa-dribbble" />
                    </Button>
                    <Button className="btn-icon btn-round" color="facebook">
                      <i className="fa fa-facebook-f" />
                    </Button>
                    <p className="card-description">{intl.get('or be classical')}</p>
                  </div> */}
                </CardHeader>
                <CardBody>
                  <Form action="" className="form" method="">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      {/* <Input name='username' placeholder={intl.get('First Name')} type="text" onChange={this.handleChange} /> */}
                      <Input name='username' placeholder={intl.get('Email')} type="text" onChange={this.handleChange} />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name='password' type='password' placeholder={intl.get('Password')} onChange={this.handleChange} />
                    </InputGroup>
                    {/* <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name='email' placeholder={intl.get('Email')} type="email" onChange={this.handleChange} />
                    </InputGroup> */}
                    <FormGroup check className="text-left">
                      <Label check>
                        <Input defaultChecked type="checkbox" />
                        <span className="form-check-sign" />{intl.get('I agree to the')} {" "}
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          {intl.get('terms and conditions')}
                        </a>
                        .
                      </Label>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    className="btn-round"
                    color="info"
                    href="#pablo"
                    onClick={this.handleSubmit}
                  >
                    {intl.get('confirmtoregister')}
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/jan-sendereks.jpg")})`
          }}
        />
      </div>
    );
  }
}

export default Register;
