// import React, { Component } from 'react'
import Moment from 'moment';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

export const payTypeSel = { '1': '前扣', '2': '後扣', '3': '分期' };

export const ReorgPawning12 = async ({ companyObj, pawning1Obj }) => {
  if (!companyObj || !companyObj.objectId || !pawning1Obj || !pawning1Obj.objectId || !pawning1Obj.startDate) {
    console.log(companyObj, pawning1Obj);
    console.log('companyObj or pawning1Obj is empty')
    return { p1: {}, p2Arr: [] };
  }
  const companyId = companyObj.objectId;
  const pawning1Id = pawning1Obj.objectId;
  const ordernum = pawning1Obj.ordernum
  const startDateText = Moment(pawning1Obj.startDate ? pawning1Obj.startDate.iso : pawning1Obj.startDate).format('YYYY-MM-DD') || '';
  const diffMonth = Math.ceil(Moment().diff(startDateText, 'months', true));
  const { clientName, clientId, pawnTypeText, joint, proportion, jointArr, jointObjArr } = pawning1Obj

  const objP2Basic = { companyId, companyName: companyObj.name, pawning1Id, ordernum }
  const oriP2 = await Parse.queryData('pawning2', { companyId, pawning1Id }, { orderBy: 'planDate_ascending' });

  const planDateArr = []
  const payTypeId = pawning1Obj.payTypeId;

  if (payTypeId === '1') { // 先扣
    planDateArr.push(Moment(startDateText).format('YYYY-MM-DD'));
  }
  if (payTypeId === '1' || payTypeId === '2') {
    for (let i = 1; i <= diffMonth; i++) {
      planDateArr.push(Moment(startDateText).add(i, 'M').subtract(1, "days").format('YYYY-MM-DD'));
    }
  } else {
    for (let i = 1; i <= Number(pawning1Obj.periodsText); i++) {
      planDateArr.push(Moment(startDateText).add(i, 'M').subtract(1, "days").format('YYYY-MM-DD'));
    }
  }



  // 先把到今天的每一個 pawning2 都建起來，再去算裡面的內容
  const newP2 = [...oriP2];
  const promises = [];
  // console.log(planDateArr)
  planDateArr.forEach(planDate => {
    if (!oriP2.find(item => item.planDate === planDate)) { // 在資料庫沒有的話
      const db = Parse.Object.extend("pawning2");
      const newDoc = new db();

      promises.push(
        newDoc.save().then(async (doc) => {
          const obj = {
            ...objP2Basic,
            _id: doc.id,
            id: doc.id,
            objectId: doc.id,
            planDate,
            month: String(new Date(planDate).getMonth() + 1),
            year: String(new Date(planDate).getFullYear() - 1911),
          }
          newP2.push(obj)
          await Parse.saveData('pawning2', obj);
        }))
    }
  })
  await Promise.all(promises).catch(error => console.error(error));

  let principalRest = Number(pawning1Obj.principal); // 一開始的本金
  let principalPaidTotal = 0
  let principalRestTotal = 0
  let interestPlanTotal = 0
  let interestPaidTotal = 0
  let interestWaitTotal = 0

  const promises2 = [];
  const p2Arr = planDateArr.map((planDate, index) => {
    const pawning2Obj = newP2.find(item => item.planDate === planDate) || {};
    let interestPlan = principalRest * Number(pawning1Obj.interest) / 100; // 應收利息
    principalRest = principalRest - Number(pawning2Obj.principalPaid || 0);  // 本金結餘
    // console.log(principalRest)

    let interestPaidDate = pawning2Obj.interestPaidDate || '';
    let interestPaid = pawning2Obj.interestPaid || 0;
    if (payTypeId === '1' && startDateText === planDate) {
      interestPaidDate = planDate
      interestPaid = interestPlan;
    }

    if (payTypeId === '1' && principalRest === 0) {
      interestPlan = 0;
    }

    const p2Obj = {
      ...pawning2Obj,
      clientId, jointArr, jointObjArr, joint, proportion, clientName, pawnTypeText,
      principalRest, // 本金結餘
      interestPlan, // 應收利息
      interestPaidDate,
      interestPaid
    }

    principalPaidTotal += Number(p2Obj.principalPaid || 0);
    interestPlanTotal += Number(p2Obj.interestPlan || 0);
    interestPaidTotal += Number(p2Obj.interestPaid || 0);
    interestWaitTotal = interestPlanTotal - interestPaidTotal

    promises.push(Parse.saveData('pawning2', p2Obj));
    return p2Obj
  })
  await Promise.all(promises2).catch(error => console.error(error));

  // console.log(pawning1Obj)
  const startDate = new Date(pawning1Obj.startDate ? pawning1Obj.startDate.iso : pawning1Obj.startDate)
  const p1Obj = {
    ...pawning1Obj,
    companyId,
    companyName: companyObj.name,
    principalRestTotal: String(Number(pawning1Obj.principal) - Number(principalPaidTotal)),
    principalPaidTotal,
    interestPlanTotal,
    interestPaidTotal,
    interestWaitTotal,
    startDateText,
    month: String(startDate.getMonth() + 1),
    year: String(startDate.getFullYear() - 1911)
  }
  // console.log(p1Obj)
  await Parse.saveData('pawning1', p1Obj);

  await Promise.all(promises).catch(error => console.error(error));
  // console.log(p1Obj, p2Arr)
  return { p1Obj, p2Arr }
}