import React, { Component, createRef } from 'react'
import { Button, Form, Icon, Card, Container, Segment, Header, Input, Label, Select, Ref, Grid, Sticky, Checkbox, Divider } from 'semantic-ui-react';
import Parse from 'widget/parse.js'

const userStatusArr = [
  { key: 'UserStatus0', text: '老師', value: '0' },
  { key: 'UserStatus1', text: '學生', value: '1' },
]
const userStatusOpt = [
  { key: 'UserStatusAll', text: '全部', value: 'all' },
  ...userStatusArr
]
const statusOpt = [
  { key: '', value: '-1', text: '-- 選択 --' },
  { key: 'statusOpt1', text: '學生', value: '1' },
  { key: 'statusOpt0', text: '老師', value: '0' },
]

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export default class UserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false, // props.isEdit,
      doc: {}, // 原本資料
      form: {},  // 更改處
      usersObj: {}, // 原本資料
      selClass: '0',
      user: [],
      optUsers: [],
      resUsers: [],
      searchText: '',
      show: false,
      loading: false,
      isReadOnly: false,

    };
  }

  async componentDidMount() {
    const { companyObj } = this.props
    const students = await Parse.queryData('Students', { companyId: companyObj.objectId })
    const teachers = await Parse.queryData('Teachers', { companyId: companyObj.objectId })
    this.setState({ students, teachers }, () => this.handleFetch())
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { match, history, users } = props
    const { isEdit } = this.state;
    const { _id } = match.params;

    // console.log(users)
    if (!isEdit) {
      let obj = {};
      if (_id) {
        obj = users.find(item => item.uid === _id) || {};
      } else {
        obj = users[0];
        obj && history.push(`/UserPage/${users[0]._id}`);
      }
      let optUsers = users.map(item => ({
        key: item.uid, text: `${item.displayName || "(なし)"}`, value: item.uid, /*userStatus: item.userStatus*/
      }));
      this.setState({ doc: obj, form: obj, optUsers, resUsers: optUsers, usersObj: obj });
    }
  }

  // isEmpty = (teachersObj) => {
  //   for (var key in teachersObj) {
  //     if (teachersObj.hasOwnProperty(key))
  //       return false;
  //   }
  //   return true;
  // }

  handleChange = (event, data) => {
    const { form, students, teachers } = this.state
    const { name, value, className, checked } = data;
    // console.log(name, value, className, checked)
    if (name === 'role') {
      this.setState({
        form: {
          ...form,
          [name]: value,
          realName: ' ',
          realStatus: ' '
        }
      })
    } else if (name === 'realStatus') {
      let obj = {}
      obj = form.role === '0' ? teachers.find(item => item.objectId === value) : students.find(item => item.objectId === value)
      console.log(obj)
      this.setState({
        form: {
          ...form,
          [name]: obj ? obj.objectId : ' ',
          realName: obj ? (form.role === '0' ? obj.nameTeacher : obj.jpnName || obj.homeName) : ' ',
        }
      })
    } else if (className === 'auth') {
      const authArr = form.authArr && Array.isArray(form.authArr) ? form.authArr : [];

      const findIndex = authArr.indexOf(name);
      if (checked === true) {
        // 加到form.auth的陣列中
        if (findIndex === -1) {
          authArr.push(name);
        }
      } else {
        // 從form.auth中拿掉
        if (findIndex !== -1) {
          authArr.splice(findIndex, 1);
        }
      }
      // console.log(authArr)
      this.setState({
        form: {
          ...form,
          authArr,
        }
      })
    } else {
      this.setState({
        form: {
          ...form,
          [name]: value,
        }
      })
    }
  }


  saveUpdateSetting = () => {
    const { match, refetch } = this.props;
    const { form, isEdit } = this.state;

    // return;
    form.objectId = match.params._id;
    // console.log(form);
    // firebase.firestore().collection("users").doc(form._id)
    //   .update(form).then(docRef => {
    //     this.setState({ isEdit: false, doc: form, usersObj: form });
    //     refetch('users')
    //   });

  }

  cancelUpdateSetting = () => {
    const { form, usersObj, doc } = this.state;
    this.setState({ isEdit: false, form: doc });
  }
  // handleSubmit = () => {
  //   const {collection, list, pageTitleText, examKey, refetch } = this.props;
  //   this.setState({ loading: true })
  //   // console.log(form);
  //   // return;
  //   const obj = {
  //   }

  //   const db = firebase.firestore();
  //   let ref = db.collection('Teachers').doc();
  //   if (ref.id) {
  //     obj._id = ref.id;
  //     ref.set(obj).then(ref => {
  //       // console.log('Added document with ID: ', ref.id);
  //       refetch('Teachers')
  //       this.setState({ loading: false, show: false })
  //       this.props.history.push(`/TeacherPage/${obj._id}`);
  //     })
  //   }
  // }


  handleClick = (event, d) => {
    let fleldVal = event.target.value;
    const { users, history } = this.props
    history.push(`/UserPage/${fleldVal}`);

    const obj = users.find(item => item.uid === fleldVal) || {};
    this.setState({ doc: obj, form: obj, searchText: '' });
  }

  contextRef = createRef()

  handleChangeStatus = (e, { name, value }) => {
    const { users } = this.props
    const { optUsers } = this.state
    if (value === 'all') {
      this.setState({ resUsers: optUsers })
    } else {
      const userFilter = users.filter(item => item.role === value)
      const result = []
      optUsers.forEach(item => userFilter.forEach(item1 => {
        if (item1.displayName === item.text) {
          result.push(item)
        }
      }))
      console.log(result)
      this.setState({ resUsers: result })
    }
  }

  handleSearch = (e, { value }) => {
    const { optUsers } = this.state;

    if (value) {
      // 搜尋值中間可以插入任意值 例如搜尋'程雪原' 可以找到 '程 雪原'
      const looseSearchValue = RegExp(value.split('').reduce((pattern, item) => pattern + item + '.*', '.*'));

      const resUsers = optUsers.filter(item => item.text.match(looseSearchValue));
      this.setState({ resUsers, searchText: value });
      // console.log(res)
    } else {
      this.setState({ resUsers: optUsers, searchText: value });
    }
  }

  render() {
    const { optSet, formSet, match, history, semesterYear, semester, systemObj, users } = this.props;
    const { doc, form, isEdit, resUsers, searchText, show, loading, usersObj, formModal, teachers = [], students = [] } = this.state;
    console.log(form)
    const stuArr = students.sort((a, b) => a.studentId - b.studentId).map(item => ({ key: item.objectId, value: item.objectId, text: `${item.studentId} ${item.jpnName || item.homeName}` }))
    const stuOpt = [
      { key: 'stuOptNull', value: ' ', text: '-- 選択 --' },
      ...stuArr
    ]

    const teacherArr = teachers.sort((a, b) => a.teacherNum - b.teacherNum).map(item => ({ key: item.objectId, value: item.objectId, text: `${item.teacherNum} ${item.nameTeacher}` }))
    const teacherOpt = [
      { key: 'teacherOptNull', value: ' ', text: '-- 選択 --' },
      ...teacherArr
    ]
    return (<div style={{ padding: '10px' }} className='content'>
      <Ref innerRef={this.contextRef}>
        <Grid>
          <Grid.Column width={2}>
            <Sticky context={this.contextRef}>
              <Header as='h3'>User管理</Header>
              <Form size='small'>
                {/* <Input labelPosition='left' > */}
                {/* <Label color={'teal'}> <Icon name='newspaper' />表示</Label> */}
                <Select style={{
                  minWidth: '100px',
                }} compact
                  options={userStatusOpt}
                  onChange={this.handleChangeStatus}
                  disabled={isEdit}
                  defaultValue='all'
                  name='userFilter' />

                <Form.Input
                  style={{ marginTop: '4px', marginBottom: '4px' }}

                  placeholder='Search...'
                  disabled={isEdit}
                  onChange={this.handleSearch}
                  value={searchText}
                />
              </Form>
              <select multiple className="form-control ui form fluid search dropdown"
                style={{ height: '600px', width: '100%', fontSize: '11px' }} name="userSel"
                onChange={this.handleClick}
                // value={[doc._id]}
                disabled={isEdit}
              >
                {resUsers && resUsers.map(item => <option key={item.key} className="item" value={item.value}>{item.text}</option>)}
              </select>
            </Sticky>
          </Grid.Column>
          <Grid.Column width={14}>
            <Segment
              clearing
              vertical
            >
              <Header floated='left' as='h2'>
                <Header.Content>
                  {form && form.displayName}
                  <Header.Subheader>
                    {/* {form && form.teacherNum ? <><span className="text-muted">{getLabelName2(formSet, 'teacherNum')}:</span>&nbsp;{form.teacherNum}&nbsp;&nbsp;</> : ''} */}
                    {form && form.email ? <><span className="text-muted">email:</span>&nbsp;{form.email}&nbsp;</> : ''}
                  </Header.Subheader>
                  <div style={{ marginTop: '15px' }}>
                    {isEdit ? <Button size='small' icon='save' color='blue' content='保存' onClick={this.saveUpdateSetting} /> : <Button primary size='small' onClick={() => this.setState({ isEdit: true })} icon='edit' content="編集" />}
                    {isEdit ? <Button basic size='small' onClick={this.cancelUpdateSetting} icon='remove' content='キャンセル' /> : null}
                  </div>
                </Header.Content>
              </Header>
            </Segment>
            <br />
            <div style={{ border: '1px solid #d4d4d5', padding: '1em 1em', background: '#FFFFFB' }}>
              <Form>
                <div style={style.flexCenter}>
                  <Header as='h2' content='マスター情報' />
                  {/* {isEdit ? <Button inverted size='small' icon='save' color='blue' content='保存' onClick={this.handleSubmit} loading={loading} /> : null} */}
                </div>
                <Divider />
                <Grid columns='equal' divided>
                  {/* {user.map(item => item.uid === match.params._id ?  */}
                  <React.Fragment key={doc && doc.uid}>
                    <Grid.Column >
                      <Card fluid>
                        <Card.Content header='基本情報' />
                        <Card.Content>
                          <Grid.Row>
                            <Grid columns='equal'>
                              <Grid.Column>
                                {/* <Form.Input label='userName' value={form.displayName || ''} name='displayName' readOnly={!isEdit} onChange={this.handleChange} /> */}
                              </Grid.Column>
                              <Grid.Column>
                                {/* <Form.Input label='email' value={form.email || ''} name='email' readOnly disabled={isEdit} onChange={this.handleChange} /> */}
                              </Grid.Column>
                              <Grid.Column>
                                {/* <Form.Select options={statusOpt} label='role' defaultValue={form.role || '-1'} name='role' onChange={this.handleChange} disabled={!isEdit} /> */}
                              </Grid.Column>
                              <Grid.Column>
                                {/* <Form.Select options={form.role === '1' ? stuOpt : teacherOpt} label='realStatus' defaultValue={form.realStatus || ' '} name='realStatus' onChange={this.handleChange} disabled={!isEdit} /> */}
                              </Grid.Column>
                            </Grid>
                          </Grid.Row>
                        </Card.Content>
                      </Card>
                      <Card fluid>
                        <Card.Content header='權限' />
                        <Card.Content>
                          <Grid.Row>
                            <Grid columns='equal'>
                              <Grid.Row>
                                <Grid.Column>
                                  {/* <Checkbox label='受付' className='auth' name='auth0' value='0' disabled={!isEdit} onClick={this.handleChange} defaultChecked={form.authArr && form.authArr.indexOf('auth0') !== -1} /> */}
                                </Grid.Column>
                                <Grid.Column>
                                  {/* <Checkbox label='募集' className='auth' name='auth1' value='1' disabled={!isEdit} onClick={this.handleChange} defaultChecked={form.authArr && form.authArr.indexOf('auth1') !== -1} /> */}
                                </Grid.Column>
                                <Grid.Column>
                                  {/* <Checkbox label='在籍' className='auth' name='auth2' value='2' disabled={!isEdit} onClick={this.handleChange} defaultChecked={form.authArr && form.authArr.indexOf('auth2') !== -1} /> */}
                                </Grid.Column>
                                <Grid.Column>
                                  {/* <Checkbox label='教務' className='auth' name='auth3' value='3' disabled={!isEdit} onClick={this.handleChange} defaultChecked={form.authArr && form.authArr.indexOf('auth3') !== -1} /> */}
                                </Grid.Column>
                                <Grid.Column>
                                  {/* <Checkbox label='総務' className='auth' name='auth4' value='4' disabled={!isEdit} onClick={this.handleChange} defaultChecked={form.authArr && form.authArr.indexOf('auth4') !== -1} /> */}
                                </Grid.Column>
                                <Grid.Column>
                                  {/* <Checkbox label='人事' className='auth' name='auth5' value='5' disabled={!isEdit} onClick={this.handleChange} defaultChecked={form.authArr && form.authArr.indexOf('auth5') !== -1} /> */}
                                </Grid.Column>
                                <Grid.Column>
                                  {/* <Checkbox label='システム' className='auth' name='auth6' value='6' disabled={!isEdit} onClick={this.handleChange} defaultChecked={form.authArr && form.authArr.indexOf('auth6') !== -1} /> */}
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Grid.Row>
                        </Card.Content>
                      </Card>
                    </Grid.Column>

                  </React.Fragment>
                  {/* : null)} */}
                </Grid>
              </Form>
            </div>
          </Grid.Column>
        </Grid>
      </Ref>

    </div >);
  }
}
