
import React, { Component } from 'react'

import ColorNavbar from "components/Navbars/ColorNavbar.js";
import WhiteNavbar from "components/Navbars/WhiteNavbar.js";
import WebContent from 'views/webadmin/WebContent.jsx';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

const updateView = () => {
  var contentSections = document.getElementsByClassName("cd-section");
  var navigationItems = document
    .getElementById("cd-vertical-nav")
    .getElementsByTagName("a");

  // console.log(contentSections)
  // console.log(navigationItems)

  for (let i = 0; i < navigationItems.length; i++) {
    var activeSection =
      parseInt(navigationItems[i].getAttribute("data-number"), 10) - 1;
    if (
      contentSections[i].offsetTop - window.innerHeight / 2 <
      window.pageYOffset &&
      contentSections[i].offsetTop +
      contentSections[i].scrollHeight -
      window.innerHeight / 2 >
      window.pageYOffset
    ) {
      navigationItems[activeSection].classList.add("is-selected");
    } else {
      navigationItems[activeSection].classList.remove("is-selected");
    }
  }
};

class IndexOfficial extends Component {
  state = {
    pageObj: {},
    pageId: '',
    displayArr: [],
    footerArr: [],
    announceCenterArr: [],
  }

  async componentDidMount() {
    // console.log('componentDidMount') const { companyObj } = this.props
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const announceCenterArr = await Parse.queryData('announceCenter', { companyId });

    this.setState({ announceCenterArr }, () => this.handleLoadPage())
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps')
    this.handleLoadPage(nextProps)
  }

  componentWillUnmount() {
    this.handleUnSection();
  }

  componentDidUpdate(prevProps) {
    // console.log('componentDidUpdate')
    var hrefId = window.location.href.substring(
      window.location.href.lastIndexOf("/#") + 2
    );
    // var hrefId = href.substring(href.lastIndexOf("#") + 1);
    // console.log(hrefId)
    // if (href.lastIndexOf("#") > 0) {
    if (hrefId && document.getElementById(hrefId)) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  }

  handleSection = () => {
    document.documentElement.classList.remove("nav-open");
    // React.useEffect(() => {
    document.body.classList.add("section-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    var hrefId = window.location.href.substring(
      window.location.href.lastIndexOf("/#") + 2
    );
    // var hrefId = href.substring(href.lastIndexOf("#") + 1);
    // console.log(hrefId)
    // if (href.lastIndexOf("#") > 0) {
    if (hrefId && document.getElementById(hrefId)) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
    window.addEventListener("scroll", updateView);
  }

  handleUnSection = () => {
    document.body.classList.remove("section-page");
    window.removeEventListener("scroll", updateView);
  }

  // const pageType = { '1': '頁面', '2': '目錄', '3': '一頁式', '4': '頁腳', '5':'非選單頁面', undefined:'一般頁面' }
  handleLoadPage = async (props = this.props) => {
    const { match, webnavArr, webpageArr } = props;
    const { webnav = '' } = match.params;
    let pageObj = {}
    if (webnav) { // 非首頁
      pageObj = webnavArr.find(item => item.path2 === webnav) || webnavArr.find(item => item.path1 === webnav) || webnavArr.find(item => item.objectId === webnav) || {};
    } else { // 首頁，找第一個出來
      const indexObj = webnavArr.filter(item => item.level === '1' && (item.pageType === '1' || item.pageType === '2' || item.pageType === '3'));
      pageObj = (indexObj.length && indexObj[0]) || {};
    }
    const pageId = pageObj.objectId;

    const displayArr = []
    if (pageObj.pageType === '1' || !pageObj.pageType) { // 一般頁面
      this.handleUnSection();
      displayArr.push({
        ...pageObj,
        subComponentsArr: webpageArr.filter(item => item.pageId === pageId).sort((a, b) => a.order - b.order)
      })
    } else if (pageObj.pageType === '3') { // 一頁式
      this.handleSection();
      webnavArr.filter(item => item.nav1Id === pageId && item.isOpen).sort((a, b) => a.order - b.order).forEach(element => {
        displayArr.push({
          ...element,
          subComponentsArr: webpageArr.filter(item => item.pageId === element.objectId).sort((a, b) => a.order - b.order)
        })
      });
    }

    const footerObj = webnavArr.find(item => item.pageType === '4') || {}
    const footerArr = webpageArr.filter(item => item.pageId === footerObj.objectId).sort((a, b) => a.order - b.order)
    this.setState({ pageId, pageObj, displayArr, footerArr })
  }

  handleAnnounceCenterArr = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const announceCenterArr = await Parse.queryData('announceCenter', { companyId });
    this.setState({ announceCenterArr })
  }

  render() {
    const { companyObj } = this.props
    const { pageObj, displayArr, footerArr, announceCenterArr } = this.state;
    // console.log(companyObj)
    return (<>
      {companyObj.whiteNavbar ? <WhiteNavbar {...this.props} /> : <ColorNavbar {...this.props} />}

      {displayArr.length === 1 ? displayArr[0].subComponentsArr.map(item => <WebContent key={item.objectId} {...this.props} pageObj={pageObj} form={item} />) : null}
      {displayArr.length > 1 ? <>
        {displayArr.map(item =>
          <div
            key={"sec" + item.objectId} id={item.objectId}
            className={"section section-" + item.objectId + " cd-section"}
          >
            {item.subComponentsArr && item.subComponentsArr.map(item2 =>
              <WebContent key={item2.objectId} {...this.props} pageObj={pageObj} form={item2} announceCenterArr={announceCenterArr} handleAnnounceCenterArr={this.handleAnnounceCenterArr} />
            )}
            <br />
            <br />
          </div>)}

        <nav id="cd-vertical-nav">
          <ul>
            {displayArr.map((item, index) =>
              <li key={"li" + item.objectId}>
                <a
                  data-number={(index + 1) + ''}
                  href={"#" + item.objectId}
                  onClick={e => {
                    e.preventDefault();
                    // console.log((index + 1), item.objectId);
                    document.getElementById(item.objectId).scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "nearest"
                    });
                  }}
                >
                  {/* <span className="cd-dot" /> */}
                  {/* <span className="cd-label">{item.path2 || ''}</span> */}
                </a>
              </li>
            )}
          </ul>
        </nav>
      </> : () => this.handleUnSection()}
      {footerArr.map(item => <WebContent key={item.objectId} {...this.props} pageObj={pageObj} form={item} />)}
    </>);
  }
}

export default IndexOfficial;