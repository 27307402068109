import React, { Component } from 'react'
import { Header, Modal, Button, Table, Form, Grid, Checkbox, Icon } from 'semantic-ui-react';

import Parse, { funcPad } from '../../widget/parse'
import localization from 'widget/MaterialTableOpt'

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}
const mBg = { backgroundColor: 'aliceblue' }

const funcName2Arr = (name2Str = '', peopleName = '', arr) => {
  return name2Str.split(',').filter(item2 => item2 !== peopleName).map(item2 => arr.find(item1 => item1.peopleName === item2)).filter(item => item);
}

export default class ModalLighting2 extends Component {
  state = {
    show: false,
    data1: [],
    data2: this.props.data2.sort((a, b) => a.index - b.index),
    newData1: {},
    newData2: {},
    oldData1: {},
    oldData2: {},
    newData: {},
    p1Num: '',  //  光明燈燈號
    p3Num: '',  //  藥師燈燈號
    p5Num: '',  //  光明大燈燈號
    p7Num: '',  //  藥師大燈燈號
    lightArr: [], // 燈號設置 light1 裡的資料
    lightArr2: [],  // 燈號設置 light2 裡的資料
    loadingNum: false,
    addNewModal: false,  //  新增資料的 Modal
    form: {},
    lightNumArr: [],
    numInput: {},
    mId: '',
    mType: '',
    btnDisable: true,
  }

  componentDidMount() {
    this.fetchLight(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.fetchLight(nextProps)
  }

  fetchLight = async (props = this.props) => {
    const { companyObj } = props
    const companyId = companyObj.objectId;
    const opt = { orderBy: 'order_id' }
    const data = await Parse.queryData('light1', { companyId }, opt);
    this.setState({ lightArr: data })

    const p1Num = data.find(item => item.value === '光明燈').now_id;
    const p3Num = data.find(item => item.value === '藥師燈').now_id;
    const p5Num = data.find(item => item.value === '光明大燈').now_id;
    const p7Num = data.find(item => item.value === '藥師大燈').now_id;
    this.setState({ p1Num, p3Num, p5Num, p7Num });

    const data1 = await Parse.queryData('light2', { companyId }, opt);
    this.setState({ lightArr2: data1 })
  }

  handleMultiAdd = async (rowData, nameArr) => {
    const { people1, prayingtype, pray2, familyId, companyObj, data2, lighting1Obj } = this.props
    const { l1ObjId } = lighting1Obj
    // var promises = [];

    const lighting2 = await Parse.queryData('lighting2', { companyId: companyObj.objectId, lighting1Id: l1ObjId, familyId });

    const obj = {
      ...lighting1Obj,
      lighting1Id: lighting1Obj.objectId,
      ...rowData,
    };

    if (!!obj.type && typeof obj.type !== 'undefined') {
      obj.typeText = prayingtype[obj.type];
    }
    if (!!obj.pray2Id) {
      obj.pray2Text = pray2.find(item => item.objectId === obj.pray2Id).value;
    }

    nameArr.forEach(item => {
      const p = people1.find(item2 => item2.objectId === item);
      const obj2 = {
        ...obj,
        objectId: lighting2.objectId,
        id: lighting2.objectId,
        livename_text: p.name,
        addr: p.addr
      }

      data2.push(obj2)
    });

    // const db = firebase.firestore()
    // var batch = db.batch();

    // const obj = {
    //   ...lighting1Obj,
    //   lighting1Id: lighting1Obj.id,
    //   ...rowData,
    //   createdAt: new Date()
    // };

    // if (!!obj.type && typeof obj.type !== 'undefined') {
    //   obj.typeText = prayingtype[obj.type];
    // }
    // if (!!obj.pray2Id) {
    //   obj.pray2Text = pray2.find(item => item.id === obj.pray2Id).value;
    // }

    // nameArr.forEach(item => {
    //   const p = people1.find(item2 => item2.id === item);
    //   const docRef = db.collection(`company/${companyObj._id}/family/${familyId}/lighting1/${lighting1Obj.id}/lighting2`).doc();

    //   const obj2 = {
    //     ...obj,
    //     _id: docRef.id,
    //     id: docRef.id,
    //     livename_text: p.name,
    //     addr: p.addr
    //   }

    //   batch.set(docRef, obj2);
    //   data2.push(obj2)
    // });

    // this.setState({ data2 });

    // var promise = batch.commit();
    // promises.push(promise);
    // Promise.all(promises).then(results => { }).catch(error => { console.log(error) });

    this.setState({ data2 });
  }

  // openNewModal = () => this.setState({ addNewModal: true })
  // closeNewModal = () => this.setState({ addNewModal: false, form: {} })
  // changeInput = (e, { name, value }) => this.setState({ form: { ...this.state.form, [name]: value } })

  addNewData = async () => {
    const { familyId, lighting1Obj, companyObj } = this.props
    const { l1ObjId } = lighting1Obj
    const { form, data2 } = this.state
    const newData2 = [...data2]
    const lighting2 = await Parse.queryData('lighting2', { companyId: companyObj.objectId, lighting1Id: l1ObjId, familyId });
    const obj = {
      ...lighting1Obj,
      lighting1Id: lighting1Obj.objectId,
      objectId: lighting2.objectId,
      id: lighting2.objectId,
      ...form,
      companyId: companyObj.objectId,
      companyName: companyObj.name,
    };
    // await docRef.set(obj);
    newData2.push(obj);
    this.setState({ data2: newData2 })
    this.closeNewModal()
  }

  getNextLightNum = (type = "1", now_id = "") => {
    const { lightArr2 } = this.state
    let light2Arr = [];

    if (type === "1") {
      light2Arr = lightArr2.filter(item => item.value === '光明燈').sort((a, b) => a.name.localeCompare(b.name))
    } else if (type === "3") {
      light2Arr = lightArr2.filter(item => item.value === '藥師燈').sort((a, b) => a.name.localeCompare(b.name))
    } else if (type === "5") {
      light2Arr = lightArr2.filter(item => item.value === '光明大燈').sort((a, b) => a.name.localeCompare(b.name))
    } else if (type === "7") {
      light2Arr = lightArr2.filter(item => item.value === '藥師大燈').sort((a, b) => a.name.localeCompare(b.name))
    }

    let num = ""
    if (now_id === '') {
      num = `${light2Arr[0].name}-${funcPad(light2Arr[0].start_num, 3)}`
      return num;
    }

    console.log(now_id);
    const [nowName, nowNum] = now_id.split('-');
    let obj2 = light2Arr.find(item => item.name === nowName); // 這邊的 是排序過的
    if (nowNum < Number(obj2.end_num)) {
      num = `${obj2.name}-${funcPad(Number(nowNum) + 1, 3)}`
    } else { // 超過目前的話 就用下一個
      const newObj = light2Arr[(light2Arr.findIndex(item => item.name === nowName) + 1) % (light2Arr.length)]
      num = `${newObj.name}-${funcPad(newObj.start_num, 3)}`
    }
    return num;
  }

  handleChange = (e, { name, value, item, disabled }) => { // 打勾後自動寫入
    if (disabled) {
      return;
    }

    const { data2, mId, mType } = this.state;
    // console.log(item, name, value, mId, mType)
    const arr = [...data2]
    const index = arr.findIndex((element => element.objectId === item.objectId));

    let checked = "";
    if (name === 'p1Name2' || name === 'p3Name2' || name === 'p5Name2' || name === 'p7Name2' || name === 'p1Num' || name === 'p3Num' || name === 'p5Num' || name === 'p7Num') {
      arr[index][name] = value;
    } else {
      if (arr[index][name] === "-1") {
        arr[index][name] = "0";
        checked = "0";
      } else {
        arr[index][name] = "-1";
        checked = "-1";
      }
    }

    const key = name.charAt(1);
    if (name === 'p1' || name === 'p3' || name === 'p5' || name === 'p7') {
      const name2 = 'p' + key + '_is' + key;
      arr[index][name2] = "0";
      this.setState({ mId: item.objectId, mType: key });
    } else if (name === 'p1Allfamily') {
      arr[index].p1Isp1 = "0";

      if (checked === "-1") {
        arr[index].p1Name2 = "闔家";
      } else {
        arr[index].p1Name2 = "";
      }
      this.setState({ mId: '', mType: '' });
    } else if (name === 'p1Isp1' || name === 'p3Isp3' || name === 'p5Isp5' || name === 'p7Isp7') { // 選副的時候
      if (!mType || !mId) {
        window.alert("請點選一個主")
      }
      if (name === 'p1Isp1') {
        arr[index].p1Allfamily = "0";
      }
      const name2 = 'p' + key + 'Name2';
      arr[index][name2] = '';

      const index2 = arr.findIndex((element => element.objectId === mId)); // 找現在要加在哪一列的主
      if (index2 === -1) {
        window.alert("請點選一個主")
        return;
      }

      const arrStr = arr[index2][name2] ? arr[index2][name2].split(',') : [];
      const arrindex = arrStr.indexOf(item.peopleName);
      if (checked === '-1') { // 加進來
        if (arrindex === -1) { // 原本沒有 要加進來
          arrStr.push(item.peopleName);
          arr[index2][name2] = arrStr.join(",");
        }
      } else {
        if (arrindex !== -1) { // 原本有 要拿掉
          arrStr.splice(arrindex, 1);
          arr[index2][name2] = arrStr.join(",");
        }
      }
    } else {
      arr[index].p1Name2 = "";
    }

    this.setState({ data2: arr, btnDisable: false });
  }

  handleClickM = (mType, mId) => {
    if (!this.state.mType) {
      this.setState({ mType, mId })
    } else {
      this.setState({ mType: '', mId: '' })
    }
  }

  handleParseSave = async () => {
    const { companyObj } = this.props
    let { lightArr, p1Num, p3Num, p5Num, p7Num, data2 } = this.state;
    const p1Id = lightArr.find(item => item.value === '光明燈').objectId;
    const p3Id = lightArr.find(item => item.value === '藥師燈').objectId;
    const p5Id = lightArr.find(item => item.value === '光明大燈').objectId;
    const p7Id = lightArr.find(item => item.value === '藥師大燈').objectId;

    // console.log(p1Num)
    // console.log(p3Num)
    // console.log(p1Num, p3Num, p5Num, p7Num)

    const companyId = companyObj.objectId;
    if (p1Num) {
      const obj = { objectId: p1Id, companyId, now_id: p1Num }
      Parse.saveData('light1', obj); // 光明燈
    }
    if (p3Num) {
      const obj = { objectId: p3Id, companyId, now_id: p3Num }
      Parse.saveData('light1', obj); // 藥師燈
    }
    if (p5Num) {
      const obj = { objectId: p5Id, companyId, now_id: p5Num }
      Parse.saveData('light1', obj); // 光明大燈
    }
    if (p7Num) {
      const obj = { objectId: p7Id, companyId, now_id: p7Num }
      Parse.saveData('light1', obj); // 藥師大燈
    }

    // console.log(data2)
    if (data2) {
      data2.forEach(async (item) => {
        const obj = {
          ...item,
          objectId: item.objectId,
          id: item.objectId,
          companyName: companyObj.name,
          companyId,
        }
        Object.keys(obj).forEach(key => {
          if (typeof obj[key] === 'undefined') {
            delete obj[key];
          }
        });
        Parse.saveData('lighting2', obj);
      })
    }

    this.setState({ btnDisable: true });
  }


  handleDelete = async (e, { value }) => {
    if (!window.confirm("確認要刪除此筆嗎？")) {
      return false;
    }
    const { familyId, lighting1Obj, companyObj } = this.props
    const obj = { objectId: value, companyId: companyObj.objectId, familyId, lighting1Id: lighting1Obj.objectId }
    // const db = firebase.firestore()
    // await db.collection(`company/${companyObj._id}/family/${familyId}/lighting1/${lighting1Obj._id}/lighting2`).doc(value).delete();
    Parse.deleteData('lighting2', obj)
    const { data2 } = this.state;
    let data = [...data2];
    const index = data2.findIndex(item => item.objectId === value);
    data.splice(index, 1);
    this.setState({ data2: data, }, () => { window.alert('刪除完成'); });
  }

  handleSave = () => {
    const { lighting1Obj } = this.props
    const { data2 } = this.state;
    let { p1Num, p3Num, p5Num, p7Num } = this.state;
    const arr = [...data2];

    const { year, ordernum } = lighting1Obj;
    // 針對空白的欄位，自動編號

    arr.forEach(({ p1, p3, p5, p7, ...item }, index) => {
      arr[index].year = year + '' || '';
      arr[index].ordernum = ordernum || '';
      if (p1 === "-1") {
        if (!item.p1Num) {
          p1Num = this.getNextLightNum("1", p1Num);
          arr[index].p1Num = p1Num;
        }
        if (!!arr[index].p1Name2) {
          arr[index].p1name2Arr = funcName2Arr(arr[index].p1Name2, arr[index].peopleName, arr);
        }
      }
      if (p3 === "-1") {
        if (!item.p3Num) {
          p3Num = this.getNextLightNum("3", p3Num);
          arr[index].p3Num = p3Num;
        }
        if (!!arr[index].p3Name2) {
          arr[index].p3name2Arr = funcName2Arr(arr[index].p3Name2, arr[index].peopleName, arr);
        }
      }
      if (p5 === "-1") {
        if (!item.p5Num) {
          p5Num = this.getNextLightNum("5", p5Num);
          arr[index].p5Num = p5Num;
        }
        if (!!arr[index].p5Name2) {
          arr[index].p5name2Arr = funcName2Arr(arr[index].p5Name2, arr[index].peopleName, arr);
        }
      }
      if (p7 === "-1") {
        if (!item.p7Num) {
          p7Num = this.getNextLightNum("7", p7Num);
          arr[index].p7Num = p7Num;
        }
        if (!!arr[index].p7Name2) {
          arr[index].p7name2Arr = funcName2Arr(arr[index].p7Name2, arr[index].peopleName, arr);
        }
      }
    });
    this.setState({ data2: arr, p1Num, p3Num, p5Num, p7Num }, () => this.handleParseSave());
  }

  lightField = (item, mId, mType, p, type) => (<React.Fragment key={`lightfield${type}`}>
    <Table.Cell onClick={p === "-1" ? () => this.handleClickM(type, item.objectId) : null}><Checkbox name={`p${type}`} item={item} checked={p === "-1"} onChange={this.handleChange} /></Table.Cell>
    {type !== '1' ? null : <Table.Cell>{p === "-1" ? <Checkbox name='p1Allfamily' item={item} disabled={item.p1Isp1 === "-1"} checked={item.p1Allfamily === "-1"} onChange={this.handleChange} /> : null}</Table.Cell>}
    <Table.Cell onClick={p === "-1" ? () => this.handleClickM(type, item.objectId) : null} style={item.objectId === mId && mType === type ? mBg : {}}>
      {p === "-1" ? <Form.Input name={`p${type}Name2`} item={item} compact="true" value={item[`p${type}Name2`]} onChange={this.handleChange} fluid />
        : <Checkbox name={`p${type}Isp${type}`} item={item} disabled={p === "-1" || !mType || mType !== type} checked={item[`p${type}Isp${type}`] === "-1"} onChange={this.handleChange} />}</Table.Cell>
    <Table.Cell>{p === "-1" ? <Form.Input fluid name={`p${type}Num`} item={item} compact="true" value={item[`p${type}Num`]} onChange={this.handleChange} /> : null}</Table.Cell>
  </React.Fragment>)

  render() {
    const { lighting1Obj } = this.props
    const { ordernum = '' } = lighting1Obj;
    const { data2, mId, mType, btnDisable } = this.state;
    // console.log(data2)
    return (<>
      <div style={{ ...style.flexCenter }}>
        <Header as='h3' content={`服務內容：${ordernum}`} style={{ margin: 0 }} />
        <div style={{ ...style.flex }}>
          <Button icon='save' content='儲存' positive disabled={btnDisable} onClick={this.handleSave} />
        </div>
      </div>
      <Table celled structured>
        <Table.Header>
          <Table.Row >
            {/* <Table.HeaderCell rowSpan='2' style={{ width: '40px' }}>操作</Table.HeaderCell> */}
            <Table.HeaderCell rowSpan='2' style={{ width: '150px' }}>名字</Table.HeaderCell>
            <Table.HeaderCell colSpan='4'>光明燈</Table.HeaderCell>
            <Table.HeaderCell colSpan='3'>藥師燈</Table.HeaderCell>
            <Table.HeaderCell colSpan='3'>光明大燈</Table.HeaderCell>
            <Table.HeaderCell colSpan='3'>藥師大燈</Table.HeaderCell>
            <Table.HeaderCell>生時</Table.HeaderCell>
            {/* <Table.HeaderCell>建立時間</Table.HeaderCell> */}
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell style={{ width: '40px' }}>主</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '40px' }}>闔家</Table.HeaderCell>
            <Table.HeaderCell style={{}}>副</Table.HeaderCell>
            <Table.HeaderCell>燈號</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '40px' }}>主</Table.HeaderCell>
            <Table.HeaderCell style={{}}>副</Table.HeaderCell>
            <Table.HeaderCell>燈號</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '40px' }}>主</Table.HeaderCell>
            <Table.HeaderCell style={{}}>副</Table.HeaderCell>
            <Table.HeaderCell>燈號</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '40px' }}>主</Table.HeaderCell>
            <Table.HeaderCell style={{}}>副</Table.HeaderCell>
            <Table.HeaderCell>燈號</Table.HeaderCell>
            <Table.HeaderCell style={{ width: '200px' }}>地址</Table.HeaderCell>
            {/* <Table.HeaderCell>修改時間</Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!!data2 && data2.length ? data2.map(({ bornYear = '吉', bornMonth = '吉', bornDay = '吉', bornTimeText = '吉', p1, p3, p5, p7, ...item }, index) => (
            <Table.Row key={item.objectId}>
              {/* <Table.Cell>
                <Button icon='delete' basic size='tiny' onClick={this.handleDelete} value={item.objectId} />
              </Table.Cell> */}
              <Table.Cell>[{index + 1}] {item.peopleName}</Table.Cell>
              {this.lightField(item, mId, mType, p1, '1')}
              {this.lightField(item, mId, mType, p3, '3')}
              {this.lightField(item, mId, mType, p5, '5')}
              {this.lightField(item, mId, mType, p7, '7')}
              <Table.Cell>{`民${bornYear || '吉'}年${bornMonth || '吉'}月${bornDay || '吉'}日${bornTimeText || '吉'}時`}<br />{item.addr}</Table.Cell>
              {/* <Table.Cell>{item.createdAt && Moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}<br />
                {item.updatedAt && Moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell> */}
            </Table.Row>
          )) : null}
        </Table.Body>
      </Table>
    </>);
  }
}