import React, { Component } from 'react'
import { Container, Icon, Table, Grid, Button, Modal, Header, Form, Pagination, Dropdown, Confirm } from 'semantic-ui-react'
import _ from 'lodash'
import { Link } from 'react-router-dom';
import MyModalTable from '../../widgets/MyModalTable';
import { getStartDate, getEndtDate } from 'views/widgets/FieldsRender';
import Parse from '../../../widget/parse'

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipse: {
    // boxSizong: 'border-box',
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis'
  }
}

export default class TeacherRoster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      confirm: false,
      readOnly: false,
      unsub: null,
      teacher: [],
      form: {},
      workStatus: '1',
      author: '',
      number: 0,
    }
  }
  componentDidMount = async () => {
    const { firebase, teachers, companyObj } = this.props
    const { currentSubscription } = this.state
    // const { _id } = match.params;

    // if (teachers._id) {
    // if (this.state.unsub) {
    //   this.state.unsub();
    // }
    // const data = await Parse.queryData('Teachers', { companyId: companyObj.objectId });
    // this.setState({ teacher: data });

    const query = new Parse.Query('Teachers');
    query.limit(10000);
    const snap = await query.find();
    const teacher = snap.map(data => data.toJSON());
    this.setState({ teacher })
    if (currentSubscription) {
      // console.log('currentSubscription.unsubscribe')
      await currentSubscription.unsubscribe();
      this.setState({ currentSubscription: null })
    }
    let subscription = await query.subscribe().catch(error => console.error(error));
    subscription.on('create', (data) => {
      const teacher = [...this.state.teacher, data.toJSON()];
      this.setState({ teacher })
    });
    subscription.on('update', (data) => {
      const teacher = [...this.state.teacher, data.toJSON()];
      this.setState({ teacher })
    });
    subscription.on('delete', (data) => {
      const teacher = [...this.state.teacher, data.toJSON()];
      this.setState({ teacher })
    });
    this.setState({ currentSubscription: subscription })
    // const unsub = firebase.firestore()
    //   .collection(`Teachers`)
    //   // .orderBy('relationship')
    //   // .where('eventDate', '==', eventDate).where('classname', '==', className)
    //   .onSnapshot(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     console.log(data);
    //     this.setState({ teacher: data });

    //   }, err => {
    //     console.log(`Encountered error: ${err}`);
    //   });
    // this.setState({ unsub });
    // }
  }

  async componentWillReceiveProps(nextProps) {
    // this.setState({ doc: nextProps.doc });
    const { firebase, teachers } = nextProps;
    const { currentSubscription } = this.state
    // const { _id } = match.params;

    // if (teachers._id) {
    // if (this.state.unsub) {
    //   this.state.unsub();
    //   console.log('unsub worked!')
    // }
    const query = new Parse.Query('Teachers');
    query.limit(10000);
    const snap = await query.find();
    const teacher = snap.map(data => data.toJSON());
    this.setState({ teacher })
    if (currentSubscription) {
      // console.log('currentSubscription.unsubscribe')
      await currentSubscription.unsubscribe();
      this.setState({ currentSubscription: null })
    }
    let subscription = await query.subscribe().catch(error => console.error(error));
    subscription.on('create', (data) => {
      const teacher = [...this.state.teacher, data.toJSON()];
      this.setState({ teacher })
    });
    subscription.on('update', (data) => {
      const teacher = [...this.state.teacher, data.toJSON()];
      this.setState({ teacher })
    });
    subscription.on('delete', (data) => {
      const teacher = [...this.state.teacher, data.toJSON()];
      this.setState({ teacher })
    });
    this.setState({ currentSubscription: subscription })

    // const unsub = firebase.firestore()
    //   .collection(`Teachers`)
    //   // .orderBy('relationship')
    //   // .where('eventDate', '==', eventDate).where('classname', '==', className)
    //   .onSnapshot(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     console.log(data);
    //     this.setState({ teacher: data }, () => console.log(teacher));
    //   }, err => {
    //     console.log(`Encountered error: ${err}`);
    //   });
    // this.setState({ unsub, /*form: { inquiryDate: this.getToday() }*/ });
    // }
  }

  handleSubmit = () => {
    // const { firebase, teachers } = this.props
    // const { form } = this.state
    // // const setting = this.state.form;

    // // setting.studentId = this.props.doc._id;
    // // setting.studentJpnName = this.props.doc.jpnName;
    // this.setState({ loading: true })
    // // console.log(form._id);
    // if (!form._id) {
    //   firebase.firestore().collection(`Teachers`).add(form)
    //     .then((docRef) => {
    //       firebase.firestore().collection("Teachers").doc(docRef.id)
    //         .update({ _id: docRef.id })
    //         .then(snap => this.setState({ form: { ...form, _id: docRef.id }, loading: false }, () => this.handleClose()))
    //     })
    // } else {
    //   firebase.firestore().collection(`Teachers`).doc(form._id)
    //     .update(form).then(snap => {
    //       this.setState({ loading: false }, () => this.handleClose());
    //     });
    // }
  }

  handleEdit = (teacherObj) => {
    this.setState({ show: true, form: { ...teacherObj } });
    console.log(teacherObj);
  }

  handleOpen = (teacherObj) => {
    // const { doc } = this.props
    const { teacher } = this.state
    this.setState({ show: true, form: {} });
    console.log(teacher);
  }

  handleClose = () => {
    this.setState({
      show: false,
      isDelete: false,
    });
  }

  handleRemove = () => {
    // const { firebase } = this.props
    // const { form } = this.state;
    // firebase.firestore().collection("Teachers").doc(form._id).delete()
    // // console.log(form._id);
    // this.handleClose();
    // this.hideConfirm();
  }

  showConfirm = () => this.setState({ confirm: true });
  hideConfirm = () => this.setState({ confirm: false, form: {} });

  changeWorkStatus = (e, data) => {
    this.setState({ workStatus: data.value })
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    const { form } = this.state
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    })
  }

  // fields = [
  //   {
  //     id: 'teacherNum',
  //     title: '教員番号'
  //   },
  //   {
  //     // id: 'photoTeacher',
  //     title: '写真'
  //   },
  //   {
  //     id: '',
  //     title: '氏名 (全名)(生年月日)'
  //   },
  //   {
  //     id: '',
  //     title: '専任/非専任(入社日)(年資)'
  //   },
  //   {
  //     id: 'teacherTitleText',
  //     title: '職位'
  //   },
  //   {
  //     id: 'startSemesterNum',
  //     title: '就職時期'
  //   },
  //   {
  //     id: 'endSemesterNum',
  //     title: '離職時期'
  //   },
  //   {
  //     // id: '???',
  //     title: '前期配当表案'
  //   },
  //   {
  //     // id: '???',
  //     title: '當前配当表案'
  //   },
  //   {
  //     // id: '???',
  //     title: '下期配当表案'
  //   },
  //   {
  //     // id: '???',
  //     title: '担当クラス名(授業担当科目)'
  //   },
  //   {
  //     id: 'lessonsubject',
  //     title: '授業科目内容等'
  //   },
  //   {
  //     id: 'finaldegree',
  //     title: '最終学歷/学校名/学  部'
  //   },
  //   {
  //     id: 'major',
  //     title: '主専攻課程修了'
  //   },
  //   {
  //     id: 'minor',
  //     title: '副専攻課程修了'
  //   },
  //   {
  //     id: 'jpnLanEduc',
  //     title: '日本語教育能力検定試験 合格'
  //   },
  //   {
  //     id: 'trainingCourse',
  //     title: '養成講座420時間以上もの'
  //   },
  //   {
  //     id: 'jpnagc',
  //     title: '機関への就任年月'
  //   },
  //   {
  //     id: 'telTeacher',
  //     title: '電話'
  //   },
  //   {
  //     id: 'addrTeacher',
  //     title: '住所'
  //   },
  //   {
  //     id: '',
  //     title: ''
  //   },
  // ]

  render() {
    const { firebase, teachers, teachersObj, companyObj } = this.props;
    const { teacher, readOnly, loading, form, workStatus } = this.state

    const isWorkArr = [
      { key: 'isWorkOption1', text: '在勤', value: '1' },
      { key: 'isWorkOption2', text: '離職', value: '2' },
    ]
    const isWorkArrMain = [
      { key: 'isWorkOption0', text: '全部', value: '0' },
      ...isWorkArr
    ]
    return (
      <>
        <div>
          <Header as='h2' style={{ margin: 0 }}>教員情報一覧表</Header>
          <div style={{ ...style.flexCenter, marginTop: 15 }}>
            <div>
              <label>状態</label>
              <Form.Select options={isWorkArrMain} defaultValue='1' style={{ marginTop: 10 }} onChange={this.changeWorkStatus} />
            </div>
          </div>
          <div style={{ maxWidth: '100%', /*overflowX: 'scroll',*/ marginTop: 25 }}>
            <Table celled striped size='small' selectable>
              <Table.Header>
                <Table.Row textAlign='center'>
                  {/* <Table.Cell></Table.Cell> */}
                  <Table.HeaderCell>教員番号</Table.HeaderCell>
                  <Table.HeaderCell>写真</Table.HeaderCell>
                  <Table.HeaderCell>
                    <p>氏名 (全名)</p>
                    <p>(生年月日)</p>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <p>専任/非専任</p>
                    <p>(入社日)</p>
                    <p>(年資)</p>
                  </Table.HeaderCell>
                  <Table.HeaderCell>職位</Table.HeaderCell>
                  <Table.HeaderCell>就職時期</Table.HeaderCell>
                  <Table.HeaderCell>離職時期</Table.HeaderCell>
                  <Table.HeaderCell>前期配当表案</Table.HeaderCell>
                  <Table.HeaderCell>當前配当表案</Table.HeaderCell>
                  <Table.HeaderCell>下期配当表案</Table.HeaderCell>
                  <Table.HeaderCell>希望時間</Table.HeaderCell>
                  <Table.HeaderCell>
                    <p>担当クラス名</p>
                    <p>(授業担当科目)</p>
                  </Table.HeaderCell>
                  <Table.HeaderCell>授業科目内容等</Table.HeaderCell>
                  <Table.HeaderCell>
                    <p>最終学歷</p>
                    <p>学校名</p>
                    <p>学  部</p>
                  </Table.HeaderCell>
                  <Table.HeaderCell>主専攻課程修了</Table.HeaderCell>
                  <Table.HeaderCell>副専攻課程修了</Table.HeaderCell>
                  <Table.HeaderCell>日本語教育能力検定試験 合格</Table.HeaderCell>
                  <Table.HeaderCell>養成講座420時間以上もの</Table.HeaderCell>
                  <Table.HeaderCell>機関への就任年月</Table.HeaderCell>
                  <Table.HeaderCell>電話</Table.HeaderCell>
                  <Table.HeaderCell>住所</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {teacher.sort((a, b) => a.teacherNum - b.teacherNum).map((item, index) => (workStatus === '0' ? <Table.Row key={item._id}>
                  {/* <Link to={`/TeacherPage/${item._id}`}> */}
                  {/* <Table.Cell>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                      <Link to={`/TeacherPage/${item._id}`}><Button inverted={companyObj.inverted} color='yellow' size='small' style={{ margin: 15 }}>詳細</Button></Link>
                      <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} style={{ margin: 15 }}icon='edit' content='修正' />
                    </div>
                  </Table.Cell> */}
                  <Table.Cell><Link to={`/TeacherPage/${item._id}`}>{item.teacherNum}</Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}></Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>
                    <p>{item.nameTeacher}</p>
                    <p>{item.nameTeacherFull}</p>
                    <p>{item.birthdayTeacher ? item.birthdayTeacher.split('T')[0] : null}</p>
                  </Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.partTimeTeacherText}</Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.teacherTitleText}</Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.startSemesterNum}</Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.endSemesterNum}</Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}></Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}></Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}></Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}></Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}></Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.lessonsubject}</Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.finaldegree}</Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.major}</Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.minor}</Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.jpnLanEduc}</Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.traningCourse}</Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.jpnagc}</Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.telTeacher}</Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.addrTeacher}</Link></Table.Cell>
                  <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>
                    <Button inverted={companyObj.inverted} color='red' size='small' onClick={() => this.showConfirm(item)} style={{ margin: 15 }} icon='delete' /*content='削除'*/ />
                  </Link></Table.Cell>
                  {/* </Link> */}
                </Table.Row>
                  : item.staffStatus === workStatus ? <Table.Row key={item._id}>
                    {/* <Link to={`/TeacherPage/${item._id}`}> */}
                    {/* <Table.Cell>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                          <Link to={`/TeacherPage/${item._id}`}><Button inverted={companyObj.inverted} color='yellow' size='small' style={{ margin: 15 }}>詳細</Button></Link>
                          <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} style={{ margin: 15 }}icon='edit' content='修正' />
                        </div>
                      </Table.Cell> */}
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.teacherNum}</Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}></Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>
                      <p>{item.nameTeacher}</p>
                      <p>{item.nameTeacherFull}</p>
                      <p>{item.birthdayTeacher ? item.birthdayTeacher.split('T')[0] : null}</p>
                    </Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.partTimeTeacherText}</Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.teacherTitleText}</Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.startSemesterNum}</Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.endSemesterNum}</Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}></Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}></Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}></Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}></Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}></Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.lessonsubject}</Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.finaldegree}</Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.major}</Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.minor}</Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.jpnLanEduc}</Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.traningCourse}</Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.jpnagc}</Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.telTeacher}</Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>{item.addrTeacher}</Link></Table.Cell>
                    <Table.Cell><Link style={{ color: '#000' }} to={`/TeacherPage/${item._id}`}>
                      <Button inverted={companyObj.inverted} color='red' size='small' onClick={() => this.showConfirm(item)} style={{ margin: 15 }} icon='delete' /*content='削除'*/ />
                    </Link></Table.Cell>
                    {/* </Link> */}
                  </Table.Row> : null))}
              </Table.Body>
            </Table>
          </div>
          {/* <MyModalTable
            pageTitleText='教員情報一覧表'
            firebase={firebase} fields={this.fields}
            collection='Teachers'
            {...this.props}
          /> */}
        </div>
        <Confirm
          header='削除確認'
          content='削除確認?'
          cancelButton='いいえ'
          confirmButton='はい'
          open={this.state.confirm}
          onCancel={this.hideConfirm}
          onConfirm={this.handleRemove}
          size='mini'
          centered={false} />
      </>
    )
  }
}
// export default TeacherRoster