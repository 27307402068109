import React, { Component } from 'react'
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
import { Container, Header, Form, Icon, Button } from 'semantic-ui-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import PageHeader from 'components/Headers/PageHeader';
import { ListGroup, ListGroupItem } from 'reactstrap';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import PreloadFile from '../../views/web/PreloadFile'
import { readFileAsURL } from '../../views/web/RowImage';

export class ModalEditShare extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
      show: false,
      imagePreviewUrlArr: [],
      photo: '',
      form: { facebook: false, line: false, linkedin: false, pinterest: false, telegram: false, tumblr: false, twitter: false, whatsapp: false }
    };
  }


  // handleChange = (e, { value }) => this.setState({ value })
  closeEdit = () => { this.setState({ show: false, form: {} }) }
  onEdit = () => { this.setState({ show: true }) }
  //   handleChange = (e) => {
  //     // console.log(e);
  //     this.setState({
  //       [e.target.id]: e.target.value
  //     })
  //   }

  //   handleSubmit = (e) => {
  //     // console.log(e);
  //     e.preventDefault();
  //     this.props.createProject(this.state);
  //     this.props.history.push('/');
  //   }

  handleChange = (id) => {
    const { form } = this.state
    const input = document.getElementById(id)
    this.setState({ form: { ...form, [id]: (input && input.checked) || false } })
  }
  render() {
    const { handleShare, id } = this.props
    const { show, form } = this.state
    //     if (!auth.uid) return <Redirect to='/signin' />
    // const { value } = this.state
    return (<>
      <a className="j-pop-image" onClick={this.onEdit}>編輯</a>
      <Modal
        isOpen={show}
        toggle={this.closeEdit}
        size="lg"
        style={{ maxWidth: '1200px', width: '70%', margin: '10px auto' }}
      // className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader >
          <Icon name='close' onClick={this.closeEdit} style={{ cursor: 'pointer', margin: 0 }}></Icon>
        </ModalHeader>
        <ModalBody>
          <div className="white-popup" id="popup-sharesetting">
            <form className="setBox" data-parsley-required-message="尚未填寫">
              <div className="top_title">
                <h3>分享設定</h3>
                <p>讓訪客輕鬆快速分享您的網站</p>
              </div>
              <div className="fillinBox">
                <ul className="shareBox_list clearfix" style={{ display: 'block' }}>
                  <li>
                    <label>
                      <input type="checkbox" name="share" id="facebook" value="facebook" onChange={() => this.handleChange('facebook')} />
                      <p className="facebook">
                        <i className="fab fa-facebook-square"></i><span>Facebook</span>
                      </p>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="checkbox" name="share" id="line" value="line" onChange={() => this.handleChange('line')} />
                      <p className="line">
                        <i className="fab fa-line"></i><span>LINE</span>
                      </p>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="checkbox" name="share" id="linkedin" value="linkedin" onChange={() => this.handleChange('linkedin')} />
                      <p className="linkedin">
                        <i className="fab fa-linkedin"></i><span>LinkedIn</span>
                      </p>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="checkbox" name="share" id="pinterest" value="pinterest" onChange={() => this.handleChange('pinterest')} />
                      <p className="pinterest">
                        <i className="fab fa-pinterest"></i><span>Pinterest</span>
                      </p>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="checkbox" name="share" id="telegram" value="telegram" onChange={() => this.handleChange('telegram')} />
                      <p className="telegram"><i className="fab fa-telegram-plane"></i><span>Telegram</span>
                      </p>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="checkbox" name="share" id="tumblr" value="tumblr" onChange={() => this.handleChange('tumblr')} />
                      <p className="tumblr"><i className="fab fa-tumblr-square"></i><span>Tumblr</span>
                      </p>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="checkbox" name="share" id="twitter" value="twitter" onChange={() => this.handleChange('twitter')} />
                      <p className="twitter">
                        <i className="fab fa-twitter"></i><span>Twitter</span>
                      </p>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="checkbox" name="share" id="whatsapp" value="whatsapp" onChange={() => this.handleChange('whatsapp')} />
                      <p className="whatsapp">
                        <i className="fab fa-whatsapp"></i><span>WhatsApp</span>
                      </p>
                    </label>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </ModalBody>
        <ModalFooter style={{ padding: '16px', justifyContent: 'center' }}>
          <Button style={{ backgroundColor: '#27d0ca', color: '#fff' }} content='套用' onClick={() => { handleShare(form); this.closeEdit() }} />
        </ModalFooter>
      </Modal>
    </>)
  }
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     createProject: (project) => dispatch(createProject(project))
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HostIndex)
export default ModalEditShare