
import React, { Component } from 'react'
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";
import Parse from "widget/parse";
import { logout } from '../../store/actions/auth';

class UserChangePW extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: props.user.profile.toJSON() || {},
      password: '',
      password2: '',
      password3: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.user && nextProps.user.profile && nextProps.user.profile.toJSON) {
      this.setState({ profile: nextProps.user.profile.toJSON() || {} })
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  logout = () => {
    window.alert("已修改成功，請重新登入")
    const toUrl = '/auth/login';
    this.props.logout(this.props.history, toUrl);
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    // const { history, loginUser } = this.props
    const { password, password2, password3, profile } = this.state
    const { username } = profile;
    // const res = await signinUser(email, password);
    // console.log(res)
    // if (res === '1') {
    //   history.push('/admin/dashboard');
    // }
    // if (this.isValid()) {
    //   this.setState({ errors: {}, isLoading: true });
    //   loginUser(this.state, history);
    // }

    if (!password2 || !password3) {
      window.alert('請輸入新密碼');
      return;
    }
    if (password2 !== password3) {
      window.alert('兩次密碼不一樣，請重新確認');
      return;
    }

    console.log(username, password, password2, password3)
    const user = await Parse.User.logIn(username, password).catch(error => {
      window.alert("目前密碼錯誤，請重新輸入")
      console.error(error.message);
    });
    if (user) {
      const res = await Parse.changeUserPassword(username, password, password2)
        .catch((error) => { console.error(error.code + " " + error.message) });
      console.log(res)
      if (res === "1") {
        // window.alert("密碼成功變更，請重新登入")
        this.logout()
      }
      // console.log(user)
      // user.setPassword(password2);
      // user.save(null, {
      //   // useMasterKey: true,
      //   success: this.logout(),
      //   error: (obj, error) => {
      //     console.error(error.message);
      //   }
      // });
    }
  }

  render() {
    // console.log(this.props)
    const { companyObj = {}, } = this.props
    const { profile } = this.state;;
    // console.log(profile)
    const { username, name } = profile;
    return (
      <>
        <div className="content">
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col md="8">
                <Card>
                  <CardHeader>
                    <h5 className="title">修改密碼</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="pr-1" md="5">
                        <FormGroup>
                          <label>Company (disabled)</label>
                          <Input
                            value={companyObj.name}
                            disabled
                            placeholder="Company"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col className="px-1" md="3">
                        <FormGroup>
                          <label>Username</label>
                          <Input
                            defaultValue="michael23"
                            placeholder="Username"
                            type="text"
                          />
                        </FormGroup>
                      </Col> */}
                      <Col className="pl-1" md="7">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">
                            Email address
                          </label>
                          <Input
                            disabled
                            placeholder="Email" type="email" value={username}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>目前密碼</label>
                          <Input
                            name='password'
                            defaultValue=""
                            // placeholder="Home Address"
                            type="password"
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>新密碼</label>
                          <Input
                            name='password2'
                            defaultValue=""
                            // placeholder="Home Address"
                            type="password"
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>再次輸入新密碼</label>
                          <Input
                            name='password3'
                            defaultValue=""
                            // placeholder="Home Address"
                            type="password"
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter style={{ textAlign: 'right' }}>
                    <Button className="btn-round" color="success" type="submit">
                      變更密碼
                  </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

// export default UserChangePW;

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps, { logout })(withRouter(withCookies(UserChangePW)));