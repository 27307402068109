import React, { Component } from 'react'
import { Container, Button, Dimmer, Loader, Image, Segment, Header, Form } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import { Select, MenuItem } from '@material-ui/core';
import Moment from 'moment';
import Parse from 'widget/parse'
// import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';
import localization from 'widget/MaterialTableOpt'
import Client1c from './Client1c';

const columns = ({ roomSel = {}, roomId, paymentTypeOpt, paymentTypeSel }) => ([
  // { width: 80, title: '房號', field: 'roomId', lookup: roomSel, initialEditValue: roomId, },
  { width: 100, title: '類別', field: 'payType', lookup: { '1': '收入', '2': '支出', '3': '其他', }, initialEditValue: '1' },
  {
    width: 120, title: '項目', field: 'paymentType', initialEditValue: '5', //lookup: { '1': '訂金', '2': '押金', '3': '預繳電費', '4': '門鎖押金', '5': '房租', '6': '電費', '7': '其他', }
    render: rowData => paymentTypeSel[rowData.paymentType],
    editComponent: props => {

      // const thisPray2 = pray2.filter(item => item.pray1Id === props.rowData.pray1Id).sort((a, b) => a.order - b.order)
      // const p2use = thisPray2.find(item => item.now_use === '-1') // 找預設的
      // const thisPray2Id = (p2use && p2use.objectId) || (thisPray2.length && thisPray2[0].objectId); // 沒有預設 就用第一個
      // if (!thisPray2.find(item => item.objectId === props.rowData.pray2Id)) { // 有換 pray1 就重設pray2id
      //   const pray2Obj = thisPray2.find(item => item.objectId === thisPray2Id);
      //   const pray2Id = pray2Obj.objectId;
      //   const pray2Text = pray2Obj.value;
      //   const pray2OrderId = pray2Obj.order;
      //   const accReceivable = pray2Obj.price;
      //   const serial = funcPad((Number(pray2Obj.now_num || 0) + 1) + '', Number(pray2numLen));
      //   props.onRowDataChange({ ...props.rowData, pray2Id, pray2Text, serial, pray2OrderId, accReceivable })
      // }
      return <Select
        value={props.rowData.paymentType}
        onChange={e => {
          const paymentType = e.target.value;
          // console.log(paymentType)
          let price = ''
          if (paymentType === 1) {
            price = '12000'
          } else if (paymentType === 2) {
            price = '3000'
          } else if (paymentType === 3) {
            price = '1000'
          }
          // const pray2Obj = pray2.find(item => item.objectId === pray2Id);
          // const serial = funcPad((Number(pray2Obj.now_num || 0) + 1) + '', Number(pray2numLen));
          // const pray2Text = pray2Obj.value;
          // const pray2OrderId = pray2Obj.order;
          // const accReceivable = pray2Obj.price;
          props.onRowDataChange({ ...props.rowData, paymentType, price })
        }}>
        {paymentTypeOpt.map((item, index) => <MenuItem key={index + 1} value={index + 1}>{item.text}</MenuItem>)}
      </Select>
    }
  },
  // dateCol({ title: '開始日期', field: 'startDate' }),
  // dateCol({ title: '結束日期', field: 'endDate' }),
  { title: '開始日期', field: 'startDate' },
  { title: '結束日期', field: 'endDate' },
  { width: 150, title: '應繳金額', field: 'payable' },
  { width: 150, title: '金額', field: 'price', initialEditValue: '', },
  // { title: '電度開始', field: 'eleMeterStart' },
  // { title: '電度結束', field: 'eleMeterEnd' },
  // dateCol({ title: '繳費日期', field: 'payDate' }),
  { title: '繳費日期', field: 'payDate' },
  { title: '繳費期限', field: 'deadlineDate' },
  // { width: 80, title: '應收', field: 'accReceivable', editable: 'never' },
  // { width: 80, title: '已收', field: 'accReceived', editable: 'never' },
  // { width: 80, title: '未收', field: 'accPendingPay', editable: 'never' },
  { title: '備註', field: 'ps' },
]);

// const columns2 = () => ([
//   { title: '繳款方式', field: 'type', lookup: { '0': '現金', '1': '匯款', '2': '支票' } },
//   { title: '繳費日期', field: 'date', type: 'date' },
//   { title: '繳費金額', field: 'receiveNum' },
//   { title: '備註', field: 'ps' },
// ]);
const paymentTypeOpt = [
  { key: '1', value: '1', text: '訂金' },
  { key: '2', value: '2', text: '押金' },
  { key: '3', value: '3', text: '預繳電費' },
  { key: '4', value: '4', text: '門鎖押金' },
  { key: '5', value: '5', text: '房租' },
  { key: '6', value: '6', text: '電費' },
  { key: '7', value: '7', text: '其他' },
]
const paymentTypeSel = { '1': '訂金', '2': '押金', '3': '預繳電費', '4': '門鎖押金', '5': '房租', '6': '電費', '7': '其他', }

const DateDiff = (sDate1, sDate2) => { // sDate1 和 sDate2 是 2016-06-18 格式
  const oDate1 = new Date(sDate1);
  const oDate2 = new Date(sDate2);
  const iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24); // 把相差的毫秒數轉換為天數
  return iDays;
};
export default class Client1b extends Component {
  state = {
    dataArr: [],
    paying1Arr: [],
    paying2Arr: [],
    paying1Obj: {},
    loading: true,
  }

  async componentDidMount() {
    // const { calendar } = this.props
    // this.setState({ dataArr: calendar })
    // Moment(startDateText).add(i, 'M').subtract(1, "days").format('YYYY-MM-DD')
    const { companyObj, roomArr, clientObj } = this.props
    const companyId = companyObj.objectId
    const people = await Parse.queryData('clients', { companyId });
    const paying1Arr = await Parse.queryData('paying1', { companyId, paymentType: '5' });
    const promises = [];
    people.forEach(item => {
      const paying1Obj = paying1Arr.filter(item2 => item2.clientId === item.objectId).sort((a, b) => new Date(b.deadlineDate) - new Date(a.deadlineDate))[0]
      if (paying1Obj && !((Date.parse(new Date(paying1Obj.endDate))).valueOf() > new Date().setDate(25).valueOf())) {
        const endDate = new Date(paying1Obj.endDate)
        const nextStartDate = endDate.setDate((endDate).getDate() + 1);
        // console.log(new Date(a))
        const days = DateDiff(`${paying1Obj.endDate}`, `${paying1Obj.startDate}`)
        const months = Math.round(days / 30)
        const addMonth = new Date(nextStartDate).setMonth(new Date(nextStartDate).getMonth() + months);
        const nextEndDate = new Date(addMonth).setDate(new Date(addMonth).getDate() - 1);
        console.log(`${item.roomNum}:${Moment(new Date(nextStartDate)).format('YYYY-MM-DD')}~${Moment(new Date(nextEndDate)).format('YYYY-MM-DD')}`)
        console.log(item.roomNum)
        const db = Parse.Object.extend("paying1");
        const newDoc = new db();
        promises.push(
          newDoc.save().then(async (doc) => {
            const obj = {
              ...paying1Obj,
              objectId: doc.id,
              id: doc.id,
              _id: doc.id,
              startDate: Moment(new Date(nextStartDate)).format('YYYY-MM-DD'),
              endDate: Moment(new Date(nextEndDate)).format('YYYY-MM-DD'),
              payDate: '',
              price: '',
              deadlineDate: Moment(new Date(nextStartDate).setDate(new Date(nextStartDate).getDate() - 1)).format('YYYY-MM-DD')
            }
            // console.log(obj)
            await Parse.saveData('paying1', obj);
          }))
      }
    })
    await Promise.all(promises).catch(error => console.error(error));

    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleData = async (paying1Obj) => {
    const { companyObj, clientObj } = this.props;
    const data = await Parse.queryData('paying2', { companyId: companyObj.objectId, clientId: clientObj.objectId });
    this.setState({ paying2Arr: data, loading: false, paying1Obj: { ...paying1Obj, roomNum: clientObj.roomNum, name: clientObj.name } });
    // firebase.firestore().collection(`company/${companyObj.id}/client/${clientObj.id}/paying1/${paying1Obj.id}/paying2`)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ id: item.id, ...item.data() }));
    //     this.setState({ paying2Arr: data, loading: false });
    //     // console.log(data)
    //   }, err => { console.log(`Encountered error: ${err}`); });

    // this.setState({ paying1Obj });
  }

  // handleData = (paying1Obj) => {
  //   this.setState({ paying1Obj });
  // }

  handleFetch = async () => {
    const { companyObj, clientObj, roomSel } = this.props;
    const data = await Parse.queryData('paying1', { companyId: companyObj.objectId, clientId: clientObj.objectId });
    this.setState({ paying1Arr: data, loading: false });

    // await Promise.all(promises).catch(error => console.log("error", error));
    // firebase.firestore().collection(`company/${companyObj.id}/client/${clientObj.id}/paying1`)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({
    //       id: item.id, ...item.data(),
    //       eleStartDate: (item.data().eleStartDate && new Date(item.data().eleStartDate.seconds * 1000)) || null,
    //       eleEndDate: (item.data().eleEndDate && new Date(item.data().eleEndDate.seconds * 1000)) || null,
    //       rentStartDate: (item.data().rentStartDate && new Date(item.data().rentStartDate.seconds * 1000)) || null,
    //       rentEndDate: (item.data().rentEndDate && new Date(item.data().rentEndDate.seconds * 1000)) || null,
    //       payDeadlineDate: (item.data().payDeadlineDate && new Date(item.data().payDeadlineDate.seconds * 1000)) || null
    //     }));
    //     this.setState({ paying1Arr: data, loading: false });
    //     // console.log(data)
    //   }, err => { console.log(`Encountered error: ${err}`); });
  }

  handleUpdateAcc = async (newData) => {
    // const { companyObj, clientObj } = this.props;
    const { paying1Arr } = this.state
    Parse.saveData('paying1', newData);
    // const docRef1 = db.collection(`/company/${companyObj._id}/client/${clientObj.id}/paying1/`).doc(newData._id);
    // await docRef1.update(newData);

    const data = paying1Arr;
    const index = data.findIndex(item => item.objectId === newData.objectId);
    if (index !== -1) {
      data[index] = newData;
      this.setState({ paying1Arr: data });
    }
  }

  handleAdd = async () => {
    const { newData, paying1Obj } = this.state
    Parse.saveData('paying1', newData);
  }

  handleUpdate = async () => {
    const { newData } = this.state
    Parse.saveData('paying1', newData);
  }

  handleDelete = async () => {
    const { oldData, paying1Obj } = this.state
    Parse.deleteData('paying1', oldData);
    this.setState({ oldData: {} })
  }

  // handleYear = async () => {
  //   const { companyObj } = this.props
  //   const data1 = await Parse.queryData('paying1', { companyId: companyObj.objectId });
  //   const promises = []
  //   data1.forEach(item => {
  //     if (item.payDate) {
  //       const arr = item.payDate && item.payDate.split('-')
  //       const data2 = arr[0]
  //       const data3 = arr[1]
  //       const data4 = arr[2]
  //       // console.log(`${Number(data2) + 1911}-${data3}-${data4}`)
  //       promises.push(Parse.saveData('paying1', {
  //         ...item,
  //         payDate: `${Number(data2) + 1911}-${data3}-${data4}`
  //       }))
  //     }
  //   })
  // }
  render() {
    const { companyObj, clientObj, roomSel, roomArr } = this.props
    const { paying1Arr, paying2Arr, paying1Obj, loading } = this.state;
    // console.log(roomArr)
    // console.log(clientObj)

    return (<>
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns({ roomSel, roomId: clientObj.roomId, paymentTypeOpt, paymentTypeSel })}
        options={{
          pageSize: 5,
          search: false,
          tableLayout: "fixed"
        }}
        data={paying1Arr}
        title={`${clientObj.roomNum}房 ${clientObj.name}`}
        // actions={[
        //   {
        //     icon: 'view_headline',
        //     tooltip: '查看',
        //     onClick: (event, paying1Obj) => this.handleData(paying1Obj)
        //   }
        // ]}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const data = [...paying1Arr];
              const db = Parse.Object.extend("paying1");
              const newDoc = new db();
              const doc = await newDoc.save();
              const roomObj = roomArr.find(item => item.objectId === clientObj.roomId) || {};
              const obj = {
                id: doc.id,
                _id: doc.id,
                companyId: companyObj.objectId,
                companyName: companyObj.name,
                clientName: clientObj.name,
                clientId: clientObj.objectId,
                buildingName: roomObj.buildingName,
                buildingId: roomObj.buildingId,
                roomId: roomObj.objectId,
                roomNum: roomObj.roomNum,
                ...newData,
                paymentType: String(newData.paymentType)

                // eleStartDateText: (newData.eleStartDate && Moment(newData.eleStartDate).format('YYYY-MM-DD')) || '',
                // eleEndDateText: (newData.eleEndDate && Moment(newData.eleEndDate).format('YYYY-MM-DD')) || '',
                // rentStartDateText: (newData.rentStartDate && Moment(newData.rentStartDate).format('YYYY-MM-DD')) || '',
                // rentEndDateText: (newData.rentEndDate && Moment(newData.rentEndDate).format('YYYY-MM-DD')) || '',
                // accReceivable: Number(newData.deposit1 || 0) + Number(newData.deposit2 || 0) + Number(newData.deposit3 || 0) + Number(newData.deposit4 || 0) + Number(newData.elePrice || 0) + Number(newData.rentPrice || 0),
                // accReceived: Number(newData.accReceived || 0),
                // accPendingPay: Number(newData.accReceivable || 0) - Number(newData.accReceived || 0)
              }
              // data.push(obj);
              data.push(obj);
              this.setState({ paying1Arr: data, newData: obj }, () => resolve());
            }).then(() => this.handleAdd()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const roomObj = roomArr.find(item => item.objectId === clientObj.roomId) || {};
              const newData2 = {
                ...newData,
                companyId: companyObj.objectId,
                companyName: companyObj.name,
                clientId: clientObj.objectId,
                buildingId: roomObj.buildingId,
                buildingName: roomObj.buildingName,
                roomId: roomObj.objectId,
                roomNum: roomObj.roomNum,
                paymentType: String(newData.paymentType)

                // eleStartDateText: (newData.eleStartDate && Moment(newData.eleStartDate).format('YYYY-MM-DD')) || '',
                // eleEndDateText: (newData.eleEndDate && Moment(newData.eleEndDate).format('YYYY-MM-DD')) || '',
                // rentStartDateText: (newData.rentStartDate && Moment(newData.rentStartDate).format('YYYY-MM-DD')) || '',
                // rentEndDateText: (newData.rentEndDate && Moment(newData.rentEndDate).format('YYYY-MM-DD')) || '',
                // accReceivable: Number(newData.deposit1 || 0) + Number(newData.deposit2 || 0) + Number(newData.deposit3 || 0) + Number(newData.deposit4 || 0) + Number(newData.rentPrice || 0),
                // accReceived: Number(newData.accReceived || 0),
                // accPendingPay: Number(newData.accReceivable || 0) - Number(newData.accReceived || 0),
              }
              const data = [...paying1Arr];
              const index = data.indexOf(oldData);
              data[index] = newData2;
              // handleState('people0', data);
              this.setState({ paying1Arr: data, newData: newData2, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...paying1Arr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              // handleState('people0', data);
              this.setState({ paying1Arr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
      <br />
      <br />
      {/* {paying1Obj && paying1Obj.objectId ? <Client1c {...this.props} paying1Obj={paying1Obj} companyObj={companyObj} handleUpdateAcc={this.handleUpdateAcc} /> : null} */}
    </>)
  }
}