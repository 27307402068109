import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select, Form, TextArea } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import {
  Button,
  ButtonGroup,
  Label,
  FormGroup,
  Row,
  Col,
  UncontrolledTooltip,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { mgyearOpt, monthOpt, exportPdf } from 'views/web/CustomMaterialTable';

const columns = (employeeObj) => ([
  // { width: 40, title: '代號', field: 'code', lookup: code },
  { width: 80, title: '客戶姓名', field: 'clientName' },
  { width: 80, title: '當物類別', field: 'pawnTypeText' },
  { width: 80, title: '(總)本金結餘', field: 'principalRest' },
  { width: 80, title: '(總)應收利息', field: 'interestPlan' },
  { width: 80, title: '(總)繳息金額', field: 'interestPaid' },
  { width: 10, title: '', field: 'aaa', },
  { width: 80, title: '計劃繳息日期', field: 'planDate', editable: 'never' },
  { width: 80, title: '合件', field: 'joint' },
  { width: 80, title: '比例', field: 'proportion', editable: 'never' },
  { width: 80, title: '業績', field: 'performance' },
  { width: 80, title: '應收利息', field: 'interestPlanSelf' },
  { width: 80, title: '實收利息', field: 'interestPaidSelf' },
  { width: 80, title: '佣金', field: 'commission' },
  { width: 180, title: '備註', field: 'ps' }
]);

const timeSel = [
  { text: '08:00~10:00', value: '08:00~10:00', key: '1' },
  { text: '10:00~12:00', value: '10:00~12:00', key: '2' },
  { text: '13:00~15:00', value: '13:00~15:00', key: '3' },
  { text: '15:00~17:00', value: '15:00~17:00', key: '4' },
]

const seatsSel = [
  { text: '一樓', value: '1', key: '1' },
  { text: '二樓', value: '2', key: '2' },
  { text: '包廂', value: '3', key: '3' },
]

const seatsOpt = { '1': '一樓', '2': '二樓', '3': '包廂' }
const d = new Date()
class Reserve extends Component {
  state = {
    activeItem: 'bio',
    data: [],
    open: false,
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
    form: {},
    eventArr: []
  }
  componentDidMount = () => {
    this.handleFetch()
  }
  handleFetch = async () => {
    const { companyObj } = this.props
    const { monthSel, mgyearSel } = this.state
    const companyId = companyObj.objectId
    // console.log(monthSel);
    // console.log(mgyearSel);
    const data = await Parse.queryData('reserveData', { companyId });
    const eventArr = []
    data.map(item => {
      eventArr.push({
        title: `${item.reserveName}：${item.reserveTime}   ${item.reservePs ? `(${item.reservePs})` : ''}`,
        start: `${item.reserveDate}`
      })
    })
    const data1 = await Parse.queryData('pawning2', { companyId, month: monthSel, year: mgyearSel });

    // console.log(eventArr);
    this.setState({ data: data1, eventArr })
  }
  // handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  // handleChange = (e, { name, value }) => {
  //   const { form } = this.state
  //   this.setState({ [name]: value }, () => this.hanldeFilter())
  // }

  handleChange1 = (e, { name, value }) => {
    const { form } = this.state
    this.setState({ form: { ...form, [name]: value } })
  }

  // hanldeFilter = async () => {
  //   const { companyObj } = this.props
  //   const { monthSel, mgyearSel } = this.state
  //   const companyId = companyObj.objectId
  //   const data1 = await Parse.queryData('pawning2', { companyId, monthSel, mgyearSel });

  //   this.setState({ data: data1 })
  // }

  handledateClick = (info) => {
    const { form } = this.state
    if ((Date.parse(d)).valueOf() > (Date.parse(new Date(info.dateStr))).valueOf()) {
      alert("此日無法預約");
    } else if (new Date(info.dateStr).getDay() === 6 || new Date(info.dateStr).getDay() === 0) {
      alert("此日無法預約");
    } else {
      this.setState({ form: { ...form, reserveDate: info.dateStr }, open: true })
    }
  }

  close = () => { this.setState({ open: false }) }

  handleReserve = async () => {
    const { companyObj } = this.props
    const { form, eventArr } = this.state
    const obj = {
      ...form,
      companyId: companyObj.objectId,
      // reserveTableText: seatsOpt[form.reserveTable]
    }
    await Parse.saveData('reserveData', obj);
    eventArr.push({
      title: `${obj.reserveName}：${obj.reserveTime}   ${obj.reservePs ? `(${obj.reservePs})` : ''}`,
      start: `${obj.reserveDate}`
    })
    this.setState({ open: false, form: {} })
  }

  render() {
    const { companyObj } = this.props
    const { form, monthSel, mgyearSel, open, eventArr } = this.state
    const { reserveDate, reservePs, reserveNum, reserveName, reserveTime = '-1' } = form
    return (<div className="content">
      <Header as='h2' inverted={companyObj.inverted}>預約</Header>
      <Row>
        <Col md={{ size: 8 }}>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            dateClick={this.handledateClick}
            events={eventArr}
            // locales: allLocales
            locale='zh-tw'
          />
        </Col>
      </Row>
      <Modal
        isOpen={open}
        size='md'
        toggle={this.close}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggle}>
            <span aria-hidden="true">×</span>
          </button>
        預約
      </ModalHeader>
        <ModalBody><>
          <h3 style={{ textAlign: 'center' }}>{reserveDate}</h3>
          預約時段<Form.Select fluid name='reserveTime' value={reserveTime} options={[{ key: 'aa', value: '-1', text: '請選擇' }].concat(timeSel)} onChange={this.handleChange1} />
          {/* 預約時間 <Form.Input fluid type="time" name='reserveTime' onChange={this.handleChange1} /> */}
          <br />
          公司名 <Form.Input fluid type="text" name='reserveName' value={reserveName} onChange={this.handleChange1} />
          <br />
          聯絡電話 <Form.Input fluid type="text" name='reserveNum' value={reserveNum} onChange={this.handleChange1} />
          <br />
          備註<Form.TextArea name='reservePs' value={reservePs} placeholder='備註' onChange={this.handleChange1} />
        </>
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button color='danger' onClick={this.close} >關閉</Button>
          <Button color='success' onClick={this.handleReserve}>預約</Button>
        </ModalFooter>
      </Modal>

    </div >);
  }
}

export default Reserve;