
import React, { Component } from 'react'
// import ModalPdf from 'widget/pdf/ModalPdf';
import MaterialTable from 'material-table'
// import { closeSync } from 'fs';
import { Button, Grid, Form } from 'semantic-ui-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

// import RowsDnd from './RowsDnd'
import { dateCol, mgyearOpt, monthOpt, codeOpt } from 'views/web/CustomMaterialTable';
import ModalPdf from 'widget/pdf/ModalPdf';
import { FormGroup } from '@material-ui/core';

const columns = () => ([
  { width: 80, title: '單號', field: 'ordernum' },
  { width: 80, title: '年度', field: 'year' },
  { width: 80, title: '法會名稱', field: 'pray1Text' },
  { width: 80, title: '序號', field: 'serial' },
  // { width: 80, title: '建立日期', field: 'applyDate' },
  { width: 80, title: '應繳', field: 'accReceivable' },
  // { width: 80, title: '申請人', field: 'whoapply_text' }
]);

const payStatusOpt = [
  { key: 'all', text: '全部', value: '-1' },
  { key: 'paid', text: '已繳清', value: '0' },
  { key: 'unpaid', text: '未繳清', value: '1' },
]

class Cashier extends Component {
  state = {
    dataArr: [],
    show: false,
    mgyearId: "109",
    pray1Id: '-1',
    paystatusId: '-1',
    accReceivableTotal: 0,
    accReceivedTotal: 0,
    accPendingTotal: 0,
    loading: false,
    loadingBtn: false,
    pray1Opt: []
  }
  async componentDidMount() {
    // const { calendar } = this.props
    // this.setState({ dataArr: calendar })
    const { companyObj } = this.props
    const data = await Parse.queryData('pray1', { companyId: companyObj.objectId }, { name: 'name', sort: 'name' });
    const pray1Opt = []
    data.forEach(item => {
      pray1Opt.push({ text: item.value, value: item.objectId, key: item.objectId })
    })
    // const pray1Obj = await Parse.getSelection('pray1', { companyId: companyObj.objectId }, { name: 'name', sort: 'name' });

    // this.setState({ pray1Obj }, () => this.handleFetch())
    this.setState({ pray1Opt })
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async () => {
    const { companyObj } = this.props;
    const { paystatusId, pray1Id, mgyearId } = this.state
    console.log(pray1Id);
    this.setState({ loading: true })
    const db = Parse.Object.extend("praying1");
    const query = new Parse.Query(db);
    query.equalTo("companyId", companyObj.objectId);
    query.equalTo("year", mgyearId);

    if (pray1Id !== '-1') {
      query.equalTo("pray1Id", pray1Id);
    }
    // if (paystatusId === '0') { // 已繳清
    //   // ref = ref.where('accPending', '==', 0);
    // query.equalTo("accReceived", 0);

    // } else if (paystatusId === '1') { // 未繳清
    // ref = ref.where('accPending', '>', 0)
    // }
    query.limit(10000);

    const checkNum = (value) => !isNaN(value) ? value : 0;
    const arrAccNum = (arr, fleld) => arr.reduce((a, b) => Number(a) + Number(b[fleld]), 0);

    const snap = await query.find();
    const data1 = snap.map(data => data.toJSON());
    const arr = data1.map(item => ({
      ...item,
      // accReceivable: typeof item.accReceivable === 'number' ? item.accReceivable : 0,
      accReceivable: checkNum(item.accReceivable),
      accReceived: checkNum(item.accReceived),
      accPending: checkNum(item.accPending),
    }))
    // console.log(data1);

    const accReceivableTotal = arrAccNum(arr, 'accReceivable');
    const accReceivedTotal = arrAccNum(arr, 'accReceived');
    const accPendingTotal = arrAccNum(arr, 'accPending');
    this.setState({ dataArr: arr.filter(item => item.accReceivable !== 0 && (item.accReceivable === item.accPending)).sort((a, b) => a.serial - b.serial), accReceivableTotal, accReceivedTotal, accPendingTotal, loading: false });
  }

  handlePay = () => {
    this.setState({ show: true })
  }

  handleClosePdf = () => {
    this.setState({ show: false });
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value }, () => this.handleFetch());
  }

  render() {
    const { dataArr, value, mgyearId, pray1Id, paystatusId, loading, show, pray1Opt } = this.state;
    // const pray1Opt = pray1Obj.opt || [];

    return (<div className="content">
      <Button color='green' onClick={this.handlePay}  >顯示應繳</Button>
      <br />
      <Modal
        isOpen={show}
        toggle={this.handleClosePdf}
        size="lg"
        className="mh-90 h-90 mw-100 w-90"
        style={{ paddingTop: '0px' }}
        scrollable={true}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggle}>
            <span aria-hidden="true" onClick={this.handleClosePdf}>×</span>
          </button>
           顯示應繳

        </ModalHeader>
        <ModalBody style={{ padding: "20px 30px" }}>
          <Grid>
            <Grid.Row columns='equal'>
              <Grid.Column width={10}>
                <Form style={{ position: 'relative', zIndex: 99, }}>
                  <Form.Group inline>
                    <Form.Select label='年度' options={mgyearOpt} value={mgyearId} name='mgyearId' onChange={this.handleChange} />
                    <Form.Select label='法會' options={[{ key: 'aa', value: '-1', text: '全部' }].concat(pray1Opt)} value={pray1Id} name='pray1Id' onChange={this.handleChange} />
                  </Form.Group>
                </Form>

                <MaterialTable
                  isLoading={loading}
                  localization={localization()}
                  columns={columns()}
                  options={{
                    pageSize: 20,
                    tableLayout: 'fixed',
                    search: false
                  }}
                  data={dataArr}
                  title=''
                />
              </Grid.Column>
              <Grid.Column width={6}>
                <ModalPdf {...this.props} title='繳款單' nowform={'80'} /> {/* params={{ people1, people0, praying1Obj, companyObj: companyObjLive }}*/}
                <ModalPdf {...this.props} title='收據' nowform={'81'} />{/*params={{ people1, people0, praying1Obj, companyObj: companyObjLive }} */}
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button onClick={this.handleClosePdf} icon='checkmark' content='關閉' />
        </ModalFooter>
      </Modal>
    </div>);
  }
}

export default Cashier;
