import React, { Component } from 'react'
import { Form, Segment, Menu } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
// import _ from 'underscore'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

import ModalNamelist from './ModalNamelist';
import ModalAutoAddr from './ModalAutoAddr'

const columns = ({ people1, people0, companyObj }) => ([
  { width: 60, title: '位置', field: 'sideId', lookup: { '1': '東', '2': '西' } },
  { width: 100, title: '編號', field: 'number' },
  { title: '價錢', field: 'rowTotal' },
  {
    width: 60, title: '選擇', field: 'live_select',
    headerStyle: { textAlign: 'center' },
    render: rowData => <></>,
    editComponent: props => <ModalNamelist cardslot='1' rowData={props.rowData} people1={people1} isLive='1' onRowDataChange={props.onRowDataChange} />
  },
  { width: 300, title: '供奉陽上人', field: 'livename' },
  {
    width: 60, title: '選擇', field: 'pass_select',
    headerStyle: { textAlign: 'center' },
    render: rowData => <></>,
    editComponent: props => <ModalNamelist cardslot='2' rowData={props.rowData} people1={people1} people0={people0} isLive='0' onRowDataChange={props.onRowDataChange} />
  },
  { width: 300, title: '供奉亡者', field: 'passname' },
  {
    width: 60, title: '選擇', field: 'postcode_select',
    headerStyle: { textAlign: 'center' },
    render: rowData => <></>,
    editComponent: props => <ModalAutoAddr {...companyObj} type='0' rowData={props.rowData} onRowDataChange={props.onRowDataChange} />
  },
  { width: 300, title: '地址', field: 'addr' },
  { width: 120, title: '備註', field: 'ps' },
]);

export default class TableCardslot extends Component {
  state = {
    dataArr: [],
    loading: false,
    newData: {},
    oldData: {},
    // rowData: {}
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, familyId } = props;
    const companyId = companyObj.objectId;
    const data = await Parse.queryData("cardslot", { companyId, familyId })
    this.setState({ dataArr: data.sort((a, b) => a.order - b.order), loading: false })
  }

  handleAdd = async () => {
    const { newData } = this.state;
    Parse.saveData('cardslot', newData);
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    Parse.saveData('cardslot', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    Parse.deleteData('cardslot', oldData);
    this.setState({ oldData1: {} })
  }

  // handleData = (rowData) => {
  //   this.setState({ rowData });
  // }

  // handleChange = (e, { name, value }) => {
  //   const { rowData } = this.state;
  //   this.setState({ rowData: { ...rowData, [name]: value } })
  // }

  // handleSaveCardslot = async () => {
  // const { companyObj, familyId } = this.props
  // const { rowData } = this.state;
  // const db = firebase.firestore();
  // const docRef = db.collection(`company/${companyObj._id}/family/${familyId}/cardslot`).doc(rowData.id);
  // await docRef.update(rowData);
  // window.alert('已儲存');
  // }

  render() {
    const { companyObj, familyId, people1, people0, } = this.props;
    const { dataArr, loading, rowData } = this.state;

    return (<>
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns({ people1, people0, companyObj })}
        options={{
          pageSize: 5,
          search: true,
          tableLayout: 'fixed',
          // searchFieldStyle: {
          //   width: 120,
          // },
        }}
        data={dataArr}
        // actions={[
        //   {
        //     icon: 'view_headline', tooltip: '查看',
        //     onClick: (event, rowData) => this.handleData(rowData)
        //   }
        // ]}
        title={'永久牌位'}
        editable={{
          onRowAdd: (newData) =>
            new Promise(async (resolve, reject) => {
              const data = [...dataArr];
              const db = Parse.Object.extend("cardslot");
              const newDoc = new db();
              const doc = await newDoc.save();
              newData.objectId = doc.id;
              newData.id = doc.id;
              newData._id = doc.id;
              newData.companyId = companyObj.objectId;
              newData.familyId = familyId;

              data.push(newData);
              this.setState({ dataArr: data, newData }, () => resolve());
            }).then(() => this.handleAdd()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...dataArr];
              const index = data.indexOf(oldData);
              data[index] = newData;

              const obj = {
                ...newData,
              }
              this.setState({ dataArr: data, newData: obj, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...dataArr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              this.setState({ dataArr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
      <br />
      {/* {rowData && rowData.number ? <Segment>
        <Form>
          <Form.Group widths='equal'>
            <Form.Select fluid label='位置' name='sideId' value={rowData.sideId} onChange={this.handleChange} />
            <Form.Input fluid label='編號' name='number' value={rowData.number} onChange={this.handleChange} />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Input fluid label='供奉陽上人' name='livename' value={rowData.livename} onChange={this.handleChange} />
            <Form.Input fluid label='供奉亡者' name='passname' value={rowData.passname} onChange={this.handleChange} />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Input fluid abel='備註' name='ps' value={rowData.ps} onChange={this.handleChange} />
          </Form.Group>
          <Form.Button color='blue' onClick={this.handleSaveCardslot}>儲存</Form.Button>
        </Form>
      </Segment> : null} */}
      <br />
      <br />
    </>)
  }
}