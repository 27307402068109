import React, { Component } from 'react'
// import { Container, Button, Form, Header } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import printType from './printType'
import { dateCol, exportPdf, rowReOrder } from 'views/web/CustomMaterialTable';

const objPaperSize = { "0": "自訂", "1": "無牌位", "a3": "a3", "b4": "b4", "a4": "a4" };
const objPaperLayout = { "1": "直式", "2": "橫式" };
const objOilPrayX = { "0": "", "1": "1", "2": "2", "3": "3", "4": "4", "5": "5", "6": "6" };
const objOilPrayY = { "0": "", "1": "1", "2": "2", "3": "3", "4": "4", "5": "5", "6": "6" };
// const objPrintType = { "0": "不需印", "1": "一般牌位 消", "2": "一般牌位 拔", "3": "油燈牌位", "4": "功德名條", "5": "功德名條+桌牌" };
// let objPrintType = {};

const columns = (webtypeId, pray1Sel = {}, pray2Sel = {}, objPrintType = {}) => ([
  { title: '順序', field: 'order', type: 'numeric' },
  // { title: '預設', field: 'now_use' },
  { title: '名稱', field: 'value' },
  { title: '列印樣式', field: 'print_type', lookup: objPrintType },
  { title: '紙張大小', field: 'oilpray_paper', lookup: objPaperSize, hidden: !webtypeId },
  { title: '紙張方向', field: 'oilpray_layout', lookup: objPaperLayout, hidden: !webtypeId },
  { title: '欄 數量', field: 'oilpray_x', lookup: objOilPrayX, hidden: !webtypeId },
  { title: '列 數量', field: 'oilpray_y', lookup: objOilPrayY, hidden: !webtypeId },
  { title: '專用法會', field: 'only_pray', lookup: pray1Sel, hidden: !webtypeId },
  { title: '法會項目', field: 'field_type', lookup: pray2Sel, hidden: webtypeId },
  { title: '價錢', field: 'price' },
  { title: '備註', field: 'ps' },
]);

class LightPraytype1 extends Component {
  state = {
    data1: [],
    loading: true,
    rowData: {},
    visible: true,
    pray1: {},
    pray2: {}
  }
  componentDidMount() {
    this.handleFetch(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps)
  }
  handleFetch = async (props = this.props) => {
    const { companyObj } = props;

    const pray1 = await Parse.getSelection("pray1", { companyId: companyObj.objectId }, { name: 'value', sort: 'name' })
    const pray2 = await Parse.getSelection("pray2", { companyId: companyObj.objectId }, { name: 'value', sort: 'name' })
    let sel = {}
    pray2 && pray2.arr.sort((a, b) => a.order - b.order).forEach((item, index) => {
      sel = { ...sel, [index]: item.value }
    })
    const data = await Parse.queryData("praytype", { companyId: companyObj.objectId }, { name: 'name', sort: 'name' })
    this.setState({ pray1, pray2Sel: sel, data1: data.sort((a, b) => a.order - b.order), loading: false })
  }

  handleAdd = async () => {
    const { newData } = this.state;
    Parse.saveData('praytype', newData);
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    Parse.saveData('praytype', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    Parse.deleteData('praytype', oldData);
    this.setState({ oldData: {} })
  }

  handleState = (dataArr, arrName = 'dataArr') => {
    this.setState({ [arrName]: dataArr })
  }

  render() {
    const { companyObj } = this.props
    const { data1, loading, pray1, pray2Sel } = this.state
    // console.log(pray1)
    const printtype = printType(companyObj.webtypeId).sel || {}
    return (<div className="content">
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns(companyObj.webtypeId === 'temple', pray1.sel, pray2Sel, printtype)} //thatprops: this.props,
        options={{
          pageSize: data1.length || 30,
          pageSizeOptions: [10, 20, 30, 50],
          search: false
        }}
        components={{
          Row: props => rowReOrder(props, 'praytype', 'order', data1, 'data1', this.handleState)
        }}
        data={data1}
        title="牌位種類"
        // actions={[
        //   {
        //     icon: 'view_headline',
        //     tooltip: '列印',
        //     // hidden: rowData.dayoff_status !== '1',
        //     onClick: (event, rowData) => this.handleData(rowData),
        //   },
        // ]}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const data = [...data1];
              const db = Parse.Object.extend("praytype");
              const newDoc = new db();
              const doc = await newDoc.save();
              newData.objectId = doc.id;
              newData.id = doc.id;
              newData._id = doc.id;
              newData.companyId = companyObj.objectId
              newData.companyName = companyObj.name

              data.push(newData);
              this.setState({ data1: data, newData }, () => resolve());
            }).then(() => this.handleAdd()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...data1];
              const index = data.indexOf(oldData);
              data[index] = newData;
              this.setState({ data1: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...data1];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              this.setState({ data1: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
    </div>)
  }
}

export default LightPraytype1;


