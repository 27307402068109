import React, { Component } from 'react'
import { Select, Input, Button, Label } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Moment from 'moment';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { typeCurrency, exportPdf } from 'views/web/CustomMaterialTable';



// const columns = () => ([
//   { title: '摘要', field: 'content' },
//   { title: '預估收入', field: 'income', ...typeCurrency },
//   { title: '預估支出', field: 'outcome', ...typeCurrency },
//   { title: '備註', field: 'ps' }
// ]);

export default class ClockIn extends Component {
  state = {
    loginUser: this.props.user.profile.toJSON(),
    dataArr: [],
    predictArr: [],
    deleteConfirm: false,
    loading: true,
    bankaccSel: '',
    preBalance: '',
    nextBalance: '',
    preBalanceArr: [],
    nextBalanceArr: [],
    monthSel: Moment(new Date()).format('YYYY-MM') || '',
    show: false,
  }

  async componentDidMount() {
    this.handleFetch()
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props, type = 1) => {
  }

  handleClockIn = async (e, data) => {
    const { companyObj } = this.props
    const { loginUser } = this.state
    const latestClockIn = await Parse.queryData('attendanceList', { companyId: companyObj.objectId, userId: loginUser.objectId, offWorkAt: undefined }, { orderBy: 'createdAt', limit: 1 })
    // const sortClockIn = clockIn.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    // const latestClockIn = sortClockIn.find(item => item.offWorkAt === undefined)
    let now = new Date()
    let year = String(now.getFullYear())
    let month = ''
    if ((now.getMonth() + 1) < 10) {
      month = '0' + String(now.getMonth() + 1)
    } else {
      month = String(now.getMonth() + 1)
    }
    let situation = ''
    if (now.getHours() <= 9 || (now.getHours() === 9 && now.getMinutes() <= 30)) {
      situation = '準時'
    } else if ((now.getHours() === 9 && now.getMinutes() > 30) || now.getHours() > 9) {
      situation = '遲到'
    }

    if (latestClockIn.length > 0) {
      window.alert('已有上班紀錄，若前次下班忘記打卡請洽人資處理')
    } else {
      const db = Parse.Object.extend("attendanceList");
      const newDoc = new db();
      const docRef = await newDoc.save();
      const obj = {
        objectId: docRef.id,
        id: docRef.id,
        _id: docRef.id,
        companyId: companyObj.objectId,
        companyName: companyObj.name,
        userId: loginUser.objectId,
        name: loginUser.name || '',
        jobNum: loginUser.jobNum || '',
        situation,
        onWorkAt: now,
        year,
        month,
      };
      await Parse.saveData('attendanceList', obj);
      this.setState({ show: true })
    }

  }

  handleClockOut = async (e, data) => {
    const { companyObj } = this.props
    const { loginUser, } = this.state

    const latestClockIn = await Parse.queryData('attendanceList', { companyId: companyObj.objectId, userId: loginUser.objectId, offWorkAt: undefined }, { orderBy: 'createdAt', limit: 1 })
    // const sortClockIn = clockIn.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    // const latestClockIn = sortClockIn.find(item => item.offWorkAt === undefined)


    if (latestClockIn[0]) {
      let onWorkTime = new Date(latestClockIn[0].onWorkAt.iso).getTime() //計算上班時數
      let offWorkTime = new Date().getTime()
      let workTime = offWorkTime - onWorkTime
      // let milliseconds = parseInt((workTime % 1000) / 100),
      // seconds = Math.floor((workTime / 1000) % 60),
      // minutes = Math.floor((workTime / (1000 * 60)) % 60),
      let hours = Math.floor((workTime / (1000 * 60 * 60)));
      // hours = (hours < 10) ? "0" + hours : hours;
      // minutes = (minutes < 10) ? "0" + minutes : minutes;
      // seconds = (seconds < 10) ? "0" + seconds : seconds;
      // let str = hours + ":" + minutes + ":" + seconds + "." + milliseconds;

      let salary = hours * 158

      const obj = {
        ...latestClockIn[0],
        offWorkAt: new Date(),
        workTime: Number(hours),
        salary,
      };
      await Parse.saveData('attendanceList', obj);
      this.setState({ show: true })
    } else {
      window.alert('無最新上班打卡記錄，若上班忘記打卡請洽人資處理')
    }
  }

  handleUpdate = async () => {
    const { newData, monthSel, bankaccSel, bankaccArr } = this.state
    const { companyObj } = this.props
    const [year, month] = monthSel.split('-');
    // let bankaccName = ''
    // if (bankaccArr && bankaccSel) {
    //   bankaccName = bankaccArr.find(item => item.value === bankaccSel).text
    // } else {
    //   bankaccName = '無資料'
    // }

    if (!year || !month) {
      return;
    }

    if (newData.showFlag === 0) {
      let obj = {
        ...newData,
        preBalanceIn: newData.income || '0',
        preBalanceOut: newData.outcome || '0',
      }
      await Parse.saveData('balance', obj);
    } else {
      const obj = { ...newData };
      await Parse.saveData('predict', obj);
    }
    this.handleCalculate()
  }

  handleDelete = async () => {
    const { oldData, monthSel, bankaccSel } = this.state
    const [year, month] = monthSel.split('-');
    if (!year || !month) {
      return;
    }
    if (oldData.showFlag === 0) {
      this.setState({ loading: false })
      return console.log("OO");
    } else {
      await Parse.deleteData('predict', oldData);
      this.handleCalculate()
      this.setState({ oldData: {} })
    }
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    this.setState({ [name]: value, loading: true }, () => this.handleFetch(this.props, 0));
  }

  render() {
    const { companyObj } = this.props
    const { dataArr, loading, bankaccArr, bankaccSel, monthSel, predictArr, show, loginUser } = this.state;
    const [year, month] = monthSel.split('-') || ['', ''];
    let bankaccName = ''
    bankaccArr && bankaccSel ? bankaccName = bankaccArr.find(item => item.value === bankaccSel).text : bankaccName = '無資料'
    console.log(this.props)

    return (<>
      {/* 資金需求預估表 */}
      <div className="content">
        <br />
        {/* <Input
          type="month"
          name="monthSel"
          value={monthSel}
          onChange={this.handleChange}
        /> */}

        {/* <Button content='上班打卡' onClick={e => this.setState({ show: true })} /> */}
        <Button content='上班打卡' onClick={this.handleClockIn} />
        <Button content='下班打卡' onClick={this.handleClockOut} />
        {show ? <Label>{`${loginUser.name}於${new Date()}打卡成功`}</Label> : null}
        {/* <Select style={{
          position: 'relative', zIndex: 99,
          float: 'right',
          minWidth: '200px',
        }}
          compact
          name="bankaccSel"
          options={bankaccArr}
          value={bankaccSel}
          onChange={this.handleChange}
        /> */}
        <br />
        <br />
        {/* {!year ? null : <>
          <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns()}            
             options={{ 
                             addRowPosition: "first",
exportButton: true,
              exportPdf: (columns, data) => exportPdf(columns, data, `${year} 年 ${month} 月 ${bankaccName} 資金預估表`),
              pageSize: predictArr.length || 10,
              search: false
            }}
            data2={bankaccSel}
            data={predictArr}
            title={`${year} 年 ${month} 月 ${bankaccName} 資金預估表`}
            editable={editable}
          />
        </>} */}

        <br />
        <br />
      </div>
    </>)
  }
}