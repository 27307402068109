import React, { Component } from 'react'
// import { Container, Header, Modal, Button, Table, Form, Grid, Image, Input, Checkbox } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

const receiveType = { "0": "無", "1": "現金", "2": "匯款", "3": "信用卡", "4": "點數" };
const receiveTypeOpt = ["無", "現金", "匯款", "信用卡", "點數"]
const columns = (pray1) => ([
  // { title: '預設', field: 'now_use' },
  { title: '收取方式', field: 'receiveType', lookup: receiveType, initialEditValue: '1' },
  { title: '已收金額', field: 'receiveNum', type: 'numeric' },
  // { title: '收取日期', field: 'payDate' },
  // { title: '列 數量', field: 'oilpray_x', lookup: objOilPrayX },
  // { title: '欄 數量', field: 'oilpray_y', lookup: objOilPrayY },
  // { title: '專用法會', field: 'only_pray', lookup: pray1 },
  // { title: '價錢', field: 'price' },
  { title: '備註', field: 'ps' },
]);

export default class ModalReceivable extends Component {
  state = {
    data1: [],
  }

  componentDidMount() {
    this.handleFetch(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps)
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, praying1Obj } = props;
    // const { t1Id } = match.params;
    const data1 = await Parse.queryData('received', { companyId: companyObj.objectId, praying1Id: praying1Obj.objectId });

    this.setState({ data1 });
  }

  open = () => this.setState({ open: true })

  close = () => this.setState({ open: false })

  handleAdd = async () => {
    const { handleUpdateAcc, praying1Obj, familyId } = this.props
    const { newData, data1 } = this.state
    await Parse.saveData('received', newData);

    let accReceived = 0;
    data1.forEach(item => accReceived = accReceived + Number(item.receiveNum));
    const accPending = Number(praying1Obj.accReceivable) - accReceived;

    handleUpdateAcc({ ...praying1Obj, accReceived, accPending })

    if (newData.receiveType === '4') {
      const familyObj = await Parse.fetchDocument('family', familyId)
      if (((familyObj.point || 0) - Number(newData.receiveNum)) > 0) {
        await Parse.saveData('family', { ...familyObj, point: (familyObj.point || 0) - Number(newData.receiveNum) });
      } else {
        alert('點數餘額不足')
      }
    }
  }

  handleUpdate = async () => {
    const { handleUpdateAcc, praying1Obj, familyId } = this.props
    const { newData, oldData, data1 } = this.state
    await Parse.saveData('received', newData);

    let accReceived = 0;
    data1.forEach(item => accReceived = accReceived + Number(item.receiveNum));
    const accPending = Number(praying1Obj.accReceivable) - accReceived;

    handleUpdateAcc({ ...praying1Obj, accReceived, accPending })
    if (newData.receiveType === '4') {
      const familyObj = await Parse.fetchDocument('family', familyId)
      if (((familyObj.point || 0) + Number(oldData.receiveNum) - Number(newData.receiveNum)) > 0) {
        await Parse.saveData('family', { ...familyObj, point: (familyObj.point || 0) + Number(oldData.receiveNum) - Number(newData.receiveNum) });
      } else {
        alert('點數餘額不足')
      }
    }
  }

  handleDelete = async () => {
    const { handleUpdateAcc, praying1Obj, familyId } = this.props
    const { oldData, data1 } = this.state;
    await Parse.deleteData('received', oldData);
    this.setState({ oldData: {} })

    let accReceived = 0;
    data1.forEach(item => accReceived = accReceived + Number(item.receiveNum));
    const accPending = Number(praying1Obj.accReceivable) - accReceived;

    handleUpdateAcc({ ...praying1Obj, accReceived, accPending })

    if (oldData.receiveType === '4') {
      const familyObj = await Parse.fetchDocument('family', familyId)
      await Parse.saveData('family', { ...familyObj, point: (familyObj.point || 0) + Number(oldData.receiveNum) });
    }
  }

  render() {
    const { praying1Obj, companyObj } = this.props
    const { open, data1 } = this.state

    // console.log(rowData)
    // console.log(praying1Obj)
    return (<MaterialTable
      localization={localization()}
      columns={columns()}
      options={{
        addRowPosition: "first",
        exportButton: true,
        exportPdf: (columns, data) => exportPdf(columns, data, `${praying1Obj.ordernum} 收款記錄`),
        pageSize: data1.length || 3,
        search: false,
        tableLayout: 'fixed',
      }}
      data={data1}
      title={`${praying1Obj.ordernum} 收款記錄`}

      editable={{
        onRowAdd: newData =>
          new Promise(async (resolve, reject) => {
            const data = [...data1];
            const db = Parse.Object.extend("received");
            const newDoc = new db();
            const doc = await newDoc.save();
            newData.objectId = doc.id;
            newData.id = doc.id;
            newData._id = doc.id;
            newData.companyId = companyObj.objectId
            newData.companyName = companyObj.name
            newData.praying1Id = praying1Obj.objectId
            newData.ordernum = praying1Obj.ordernum
            newData.receiveTypeText = receiveTypeOpt[newData.receiveType]

            data.push(newData);
            this.setState({ data1: data, newData }, () => resolve());
          }).then(() => this.handleAdd()),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const data = [...data1];
            const index = data.indexOf(oldData);
            data[index] = newData;
            this.setState({ data1: data, newData, oldData }, () => resolve());
          }).then(() => this.handleUpdate()),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            let data = [...data1];
            const index = data.indexOf(oldData);
            data.splice(index, 1);
            this.setState({ data1: data, oldData }, () => resolve());
          }).then(() => this.handleDelete()),
      }}
    />)
  }
}
