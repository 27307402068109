import React, { Component } from 'react';
import { Container, Header, Form, Icon, Image, Grid, Segment, Button, Modal, Progress, Input, Divider, Confirm } from 'semantic-ui-react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import Parse from 'parse/lib/browser/Parse';

const style = {
  label: {
    display: 'block',
    margin: '0 0 .28571429rem 0',
    color: 'rgba(0,0,0,.87)',
    fontSize: '.92857143em',
    fontWeight: 700,
    textTransform: 'none'
  }
}

export default class DocumentTeacher extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      show: false,
      isReadOnly: false,
      form: {},
      loading: false,
      confirm: false,
      document: [],
      readOnly: false,
      filenames: [],
      downloadURLs: [],
      fileObj: {},
      filesData: [],
      disabled: false,
      error: false
    };
  }

  componentDidMount() {
    this.handleFetch();
  }
  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, match } = props;
    const { _id } = match.params

    // firebase.firestore().collection(`Teachers/${_id}/Document`).get().then(snap => {
    //   console.log(snap)
    //   const data = [];
    //   snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //   // const data = { _id: snap.id, ...snap.data() };
    //   this.setState({ document: data });
    // }, err => {
    //   console.log(`Encountered error: ${err}`);
    // })
    const document = await Parse.queryData('Document', { companyId: companyObj.objectId, teacherId: _id })
    this.setState({ document })
  }

  handleSubmit = async () => {
    const { firebase, match } = this.props
    const { form, document } = this.state
    const { _id } = match.params

    const newDoc = [...document];
    if (!form._id) {
      const docRef = firebase.firestore().collection(`Teachers/${_id}/Document`).doc();
      const obj = {
        ...form,
        _id: docRef.id,
        createdAt: new Date()
      };
      await docRef.set(obj);
      newDoc.push(obj);
    } else {
      await firebase.firestore().collection(`Teachers/${_id}/Document`).doc(form._id).update(form);
      let index = newDoc.findIndex((item => item._id === form._id));
      newDoc[index] = form;
    }

    this.setState({ document: newDoc });
  }

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0, disabled: true });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false, error: true });
    console.error(error);
  };

  handleUploadSuccess = async filename => {
    const { form } = this.state

    const downloadURL = await this.props.firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL();

    this.setState(oldState => ({
      filenames: [...oldState.filenames, filename],
      downloadURLs: [...oldState.downloadURLs, downloadURL],
      uploadProgress: 100,
      isUploading: false,
      form: { ...form, logoSrc: downloadURL, logoFilename: filename },
      disabled: false,
    }), () => this.handleSubmit());
  };

  handleRemove = () => {
    const { firebase, doc } = this.props
    const { document, file } = this.state

    if (file._id) {
      // let newDocument = document.filter(item => item._id !== file._id)
      firebase.firestore().collection(`Teachers/${doc._id}/Document`).doc(file._id).delete()
        .then(snap => {
          let newDocument = document.filter(item => item._id !== file._id)
          this.setState({ document: newDocument })
        })
    }
    this.hideConfirm();
  }

  showConfirm = (file) => {
    this.setState({ confirm: true, file });
  }
  hideConfirm = () => this.setState({ confirm: false, file: {} });

  render() {
    const { document, error } = this.state
    return (
      <div>
        {this.state.isUploading && <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 10000,
          width: '100vw'
        }}>
          <Progress percent={this.state.progress} indicating error={error} progress />
        </div>}
        <Header as='h2' content='教員ファイル' />
        <Row columns='equal'>
          {<Col>
            {/* <label style={style.label}>更換封面</label> */}
            <div>
              <Button as='label'
                // content={<>上傳檔案<FileUploader
                //   hidden
                //   accept="file_extension"
                //   name="avatar"
                //   // randomizeFilename
                //   storageRef={this.props.firebase.storage().ref("images")}
                //   onUploadStart={this.handleUploadStart}
                //   onUploadError={this.handleUploadError}
                //   onUploadSuccess={this.handleUploadSuccess}
                //   onProgress={this.handleProgress}
                // /></>}
                icon={<Icon name='file image' />}
                color='yellow'
              />
            </div>
          </Col>}
        </Row>
        <ol style={{ margin: '2rem auto' }}>
          {document.length ? document.map((item, index) => <li key={`doc${index}`} style={{ fontSize: '1.25rem' }}>
            <a href={item.logoSrc} target="_blank" rel="noopener noreferrer" style={{ marginRight: '1.25rem' }}>檔案{index + 1}</a>
            <Icon name='close' style={{ cursor: 'pointer', margin: 0 }} onClick={() => this.showConfirm(item)} />
          </li>) : null}
        </ol>
        <Modal open={this.state.isUploading} basic content='Document uploading...' style={{ fontSize: 75, textAlign: 'center', cursor: 'wait' }} />
        <Confirm
          header='削除確認'
          content='削除確認?'
          cancelButton='いいえ'
          confirmButton='はい'
          open={this.state.confirm}
          onCancel={this.hideConfirm}
          onConfirm={this.handleRemove}
          size='mini'
          centered={false} />
      </div>
    );
  }
}