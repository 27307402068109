import React, { Component } from 'react'
import { Container, Icon, Table, Button, Modal, Header, Form, Input, Label } from 'semantic-ui-react'
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';
import Parse from '../../../widget/parse'

import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import { getOptionsSelByKey } from 'views/widgets/FieldsRender';
import SelectWeek from '../../widgets/SelectWeek';
import { getWeekNumber, getYearWeek, getWeekdayRangeArr, getDateOfISOWeek } from '../../../widget/define/week';
// import { weekAndDay } from '../../define/calendar';

export class AttendanceManagement extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
      yearWeek: getYearWeek(),
      // classname: 'A',
      attendanceRate: []
    }
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.semesterYear !== this.props.semesterYear ||
      nextProps.semester !== this.props.semester ||
      nextProps.classId !== this.props.classId) {
      this.handleFetch(nextProps)
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    // console.log('shouldComponentUpdate');
    // console.log(this.state, nextState)
    if (JSON.stringify(this.state) !== JSON.stringify(nextState)) {
      this.handleFetch(nextProps, nextState);
      return true;
    }
    return false;
  }

  handleFetch = async (props = this.props, state = this.state) => {
    const { companyObj, match, students, classId, classroom } = props;
    const { yearWeek } = state;
    if (classId && classId !== '-1' && classId !== 'all') {
      const classObj = classroom.find(item => item.objectId === classId);
      const className = classObj.classSchool;

      const tmp = students.filter(item =>
        (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
        (item.applyResult === '1' || item.studentType === "2"));
      const arrStu = tmp.filter(item => item.classSchool === className); // 找到這班的所有人
      const y_w = yearWeek.replace(/[^\d.-]/g, '')
      Parse.queryData('Weekly', { companyId: companyObj.objectId, yearweek: y_w }).then(snap => {
        const data = [];
        snap.forEach(doc => {
          const studentId = doc.objectId;
          if (arrStu.find(item => item.objectId === studentId)) {
            data.push(doc)
          }
        });
        // console.log(data);
        this.setState({ graduates: data.sort((a, b) => (a.studentNum).localeCompare(b.studentNum)), classObj, className });
      }, err => {
        console.log(`Encountered error: ${err}`);
      });
      // firebase.firestore()
      //   .collectionGroup('Weekly')
      //   .where('yearweek', '==', y_w)
      //   .get().then(snap => {
      //     const data = [];

      //     snap.forEach(doc => {
      //       const studentId = doc.data().studentId;
      //       if (arrStu.find(item => item._id === studentId)) {
      //         data.push({ _id: studentId, ...doc.data() })
      //       }
      //     });
      //     // console.log(data);
      //     this.setState({ graduates: data.sort((a, b) => (a.studentNum).localeCompare(b.studentNum)), classObj, className });
      //   }, err => {
      //     console.log(`Encountered error: ${err}`);
      //   });
    }
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  handleWeekChange = (e, data) => {
    let yearWeek = data;
    if (typeof data !== 'string') {
      yearWeek = data.value
    }
    const [year, Wweek] = yearWeek && yearWeek.split('-'); // 2019-W10 => 2019, W10
    const week = Wweek.slice(1); // W10 => 10
    const weekStartDate = getDateOfISOWeek(year, week, 0);
    const weekDateRange = getWeekdayRangeArr(weekStartDate);
    const startArr = weekDateRange[0].split('-');
    const month = startArr[1];
    this.setState({ yearWeek, year: Number(year), week: Number(week), month, weekDateRange, loading: 1 });
  };

  render() {
    const { optSet } = this.props;

    const { yearWeek, minWeek, maxWeek, isAll, classname, graduates, year, week, month, weekDateRange } = this.state
    return (<>
      <div ref={el => (this.componentRef = el)} className="" >
        {/* <Input labelPosition='left' style={{ marginRight: 20 }}>
        <Label color='blue'><Icon name='archive' />类別</Label>
        <Form.Select
          // options={attendanceTypeOpt}
          defaultValue='1'
          onChange={this.handleChange}
        />
      </Input> */}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Header as='h2' style={{ margin: 0 }}>クラス出席数一覽表</Header>
          <div style={{ display: 'flex' }}>
            <SelectWeek
              yearWeek={yearWeek}
              // minWeek={minWeek}
              // maxWeek={maxWeek}
              handleWeekChange={this.handleWeekChange}
            />
            {/* <Input labelPosition='left' style={{ margin: '0 20px' }}>
              <Label color='violet'><Icon name='building' />クラス</Label>
              <Form.Select
                style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
                value={classname}
                name='classname'
                options={classSchool}
                onChange={this.handleChange} />
            </Input> */}
            <ExportTableToExcelButton size='small' />
            <ModalPdf
              {...this.props}
              // ref={(ref) => { this.pdfModal = ref }}
              codekey="att1"
              params={graduates}
            />
            {/* <ReactToPrint
            trigger={(e) => <Button
              content='印刷'
              // label='&nbsp;'
              color='orange' icon='print'
              size='small'
              floated='right'
              className='no-print'
            />}
            content={() => this.componentRef}
            pageStyle={"@page { size: auto;  margin:5mm; }*{font-family: 'Noto Serif JP', serif;}"}
          /> */}
          </div>
        </div>
        {/* <h2>{classname} CLASS {year}年{month}月 第{weekAndDay(weekDateRange[0])}週 {`${month}月${weekDateRange[0].getDate()}日`} ~ {`${weekDateRange[4].getMonth() + 1}月${weekDateRange[4].getDate()}日`}</h2> */}
        {/* <Button inverted={companyObj.inverted} color='blue' onClick={this.handleOpen} icon='add' content='追加' /> */}
        <div style={{ maxWidth: '100vw', overflowX: 'scroll', marginTop: 25 }}>
          <Table celled striped selectable id='excel' className='tdPadding2px thPadding2px'>
            <Table.Header>
              <Table.Row textAlign='center'>
                {/* <Table.Cell></Table.Cell> */}
                <Table.HeaderCell>学籍番号</Table.HeaderCell>
                <Table.HeaderCell>氏名</Table.HeaderCell>
                <Table.HeaderCell>年</Table.HeaderCell>
                <Table.HeaderCell>月</Table.HeaderCell>
                <Table.HeaderCell>週</Table.HeaderCell>
                {/* <Table.HeaderCell>出席情況</Table.HeaderCell> */}
                <Table.HeaderCell>週出席日数</Table.HeaderCell>
                <Table.HeaderCell>週出席時間</Table.HeaderCell>
                <Table.HeaderCell>週授業日数</Table.HeaderCell>
                <Table.HeaderCell>週授業時間</Table.HeaderCell>
                {/* <Table.HeaderCell>週修正出席日数</Table.HeaderCell> */}
                {/* <Table.HeaderCell>週修正出席時間</Table.HeaderCell> */}
                <Table.HeaderCell>学期</Table.HeaderCell>
                {/* <Table.HeaderCell></Table.HeaderCell> */}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {graduates && graduates.length ? graduates.map((item, index) => (<React.Fragment key={index}>
                <Table.Row>
                  {/* {isEdit ? <Table.Cell><Button size='small' onClick={() => this.editRowModal2()} icon='edit' /></Table.Cell> : null} */}
                  <Table.Cell>{item.studentNum}</Table.Cell>
                  <Table.Cell>{item.jpnName}</Table.Cell>
                  <Table.Cell>{item.year}</Table.Cell>
                  <Table.Cell>{item.month}</Table.Cell>
                  <Table.Cell>{item.week}</Table.Cell>
                  <Table.Cell>{item.weeklyRealDay}</Table.Cell>
                  <Table.Cell>{item.weeklyRealHours}</Table.Cell>
                  <Table.Cell>{item.weeklyExpectedDay}</Table.Cell>
                  <Table.Cell>{item.weeklyExpectedHours}</Table.Cell>
                  {/* <Table.Cell>{0}</Table.Cell>
                <Table.Cell>{0}</Table.Cell> */}
                  <Table.Cell>{item.semesterNum}</Table.Cell>
                </Table.Row>
              </React.Fragment>)) : <Table.Row>
                  <Table.Cell colSpan='15' textAlign='center' >(資料無し)</Table.Cell>
                </Table.Row>}
            </Table.Body>
          </Table>
        </div>
      </div>
    </>)
  }
}
export default AttendanceManagement