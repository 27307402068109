import _ from 'lodash'

import prayCandle2png from '../../views/temple/png/prayCandle2png'
import prayLight1png from '../../views/temple/png/prayLight1png'
import prayLight2png from '../../views/temple/png/prayLight2png'
// import praynote_2png from './png/praynote_2png';
// import QRCode from 'qrcode'

import logoBase64 from '../../views/temple/png/logo'
import {
  commaToSpace, cutStrToArr, cutStrToArr_1, cutStrToArr2, toStraight, a4pageHeight, a4pageWidth,
  printTitle, printPageNum, newPage, a4pageWidthHalf2, a4pageWidthHalf, getLightType, dateTW, funcPad,
  splice, digitUppercase, Blength, strChtEngMix2
} from '../pdf/defPdf';
import { getArrMiddle2_18ab, printMiddleBig, printMiddleSmall, printMiddle2Small, printMiddleTitle, printLeft, printLeftSmall } from '../pdf/defPdf1';
import str from '../../views/temple/png/prayCandle2png'

export const prayPage1 = (doc, str_top1, str_top2, str_left, str_middle, str_right, noMorePage, entry, companyObj) => {
  console.log('prayPage1')
  if (entry.nowformText === '蓮花2 (超度)') {
    doc.addImage(prayLight2png, 'PNG', 180, 30, 240, 790);
  } else {
    doc.addImage(prayLight1png, 'PNG', 180, 30, 240, 790);
  }

  doc.setFont('msjh').setFontSize(14);
  doc.text("|", 174, 15);
  doc.text("|", 424, 15);
  doc.text("|", 174, a4pageHeight - 8);
  doc.text("|", 424, a4pageHeight - 8);
  doc.text(str_top1 + " " + str_top2, 255, 15);

  let topMiddle = 220;
  const isPass = entry.passname || entry.passname1 || entry.passname2 || entry.passname3 || entry.passname4;
  // const isPass = entry.praytypeText && entry.praytypeText.indexOf("拔") >= 0;
  printMiddleTitle(doc, isPass, 266, topMiddle, 663, 24);

  //////////////  中間  ///////////////
  str_middle = commaToSpace(str_middle);
  topMiddle += 65;
  const arrMiddle = cutStrToArr(str_middle, 13);
  // console.log(arrMiddle)
  printMiddleBig(doc, arrMiddle, topMiddle)

  //////////////  左邊  ///////////////
  let topLeft = 250;
  const size = 22;
  doc.setFontSize(size).setFont('BiauKai');
  if (isPass) {
    str_left = commaToSpace(str_left);
    doc.text(toStraight("拜薦"), 180, topLeft + 450);
    if (entry.praytypeText.indexOf("冤親") >= 0) {
      doc.text(toStraight("解冤者"), 180, topLeft);
      topLeft += size * 4;
    } else {
      doc.text(toStraight("陽上報恩"), 180, topLeft);
      topLeft += size * 5;
    }
    printLeft(doc, str_left, topLeft, 15, 22)
  }

  //////////////  右邊  ///////////////
  let topRight = 230;
  strChtEngMix2(doc, toStraight(str_right), 402, topRight, 22);
  if (!noMorePage) doc.addPage();
}

export const prayPage1a = (doc, pos_num, str_top1, str_top2, str_left, str_middle, str_right, noMorePage, entry) => {
  console.log('prayPage1a')
  let x_shift = 20;
  let topMiddle = 155;
  let topLeft = 150;
  let topRight = 140;
  let topTitle = 15;

  x_shift = x_shift + 210 * pos_num;

  if (entry.praytypeText === '蓮花2 (超度)') {
    doc.addImage(prayLight2png, 'PNG', x_shift, 20, 180, 550);
  } else {
    doc.addImage(prayLight1png, 'PNG', x_shift, 20, 180, 550);
  }

  doc.setFont('msjh').setFontSize(10);
  if (pos_num !== 3) {
    doc.text("|", x_shift + 190, topTitle);
    doc.text("|", x_shift + 190, 560);
  }
  doc.text(str_top1 + ' ' + str_top2, 30 + x_shift, topTitle);
  topMiddle = 145;
  printMiddleTitle(doc, str_left, 65 + x_shift, topMiddle, 454, 18);

  //////////////  中間  ///////////////
  topMiddle += 50;
  // console.log(str_middle)
  str_middle = str_middle.replace(",", " ");
  console.log(str_middle+",字串長度："+str_middle.length)
  if (str_middle.length <= 6) { //字串長度小於6的時候，字型大小為28
    strChtEngMix2(doc, toStraight(str_middle), 74 + x_shift, topMiddle + 14, 28);
  } else if (str_middle.length <= 13) {
    strChtEngMix2(doc, toStraight(str_middle), 77 + x_shift, topMiddle, 20);
  } else if (str_middle.length <= 20) {
    strChtEngMix2(doc, toStraight(str_middle), 77 + x_shift, topMiddle, 18);
  } else if (str_middle.length <= 34){
    // console.log(str_middle)
    var regExp = /[a-z]/;
    // console.log(regExp.test(str_middle))
    if (!regExp.test(str_middle)){  //判斷是否包含英文
      if(str_middle.charAt(17) !==' '){
        strChtEngMix2(doc, toStraight(str_middle.substring(0,16)), 74 + x_shift, topMiddle, 16);
        strChtEngMix2(doc, toStraight(str_middle.substring(16,str_middle.length)), 99 + x_shift, topMiddle, 16);
      }else if(str_middle.charAt(17) !==' '&&str_middle.charAt(16) !==' '){
        strChtEngMix2(doc, toStraight(str_middle.substring(0,15)), 74 + x_shift, topMiddle, 16);
        strChtEngMix2(doc, toStraight(str_middle.substring(15,str_middle.length)), 99 + x_shift, topMiddle, 16);
      }else if(str_middle.charAt(17) !==' '&&str_middle.charAt(16) !==' '&&str_middle.charAt(15) !==' '){
        strChtEngMix2(doc, toStraight(str_middle.substring(0,14)), 74 + x_shift, topMiddle, 16);
        strChtEngMix2(doc, toStraight(str_middle.substring(14,str_middle.length)), 99 + x_shift, topMiddle, 16);
      }else{
        strChtEngMix2(doc, toStraight(str_middle.substring(0,17)), 74 + x_shift, topMiddle, 16);
        strChtEngMix2(doc, toStraight(str_middle.substring(17,str_middle.length)), 99 + x_shift, topMiddle, 16);
      }
    }else {  //字串包含英文
      strChtEngMix2(doc, toStraight(str_middle.substring(0,21)), 74 + x_shift, topMiddle, 16);
      strChtEngMix2(doc, toStraight(str_middle.substring(21,str_middle.length)), 99 + x_shift, topMiddle, 16);
    }
  }else if(str_middle.length <= 63) { //字串小於等於63則3行
      strChtEngMix2(doc, toStraight(str_middle.substring(0,18)), 62 + x_shift, topMiddle, 14);
      strChtEngMix2(doc, toStraight(str_middle.substring(18,36)), 87 + x_shift, topMiddle, 14);
      strChtEngMix2(doc, toStraight(str_middle.substring(36,str_middle.length)), 112 + x_shift, topMiddle, 14);
  }else{  //字串大於63則4行
    strChtEngMix2(doc, toStraight(str_middle.substring(0,20)), 58 + x_shift, topMiddle, 14);
    strChtEngMix2(doc, toStraight(str_middle.substring(20,39)), 76 + x_shift, topMiddle, 14);
    strChtEngMix2(doc, toStraight(str_middle.substring(39,58)), 94 + x_shift, topMiddle, 14);
    strChtEngMix2(doc, toStraight(str_middle.substring(58,str_middle.length)), 112 + x_shift, topMiddle, 14);
  }
    //origin
//   if (str_middle.length <= 6) {
//     strChtEngMix2(doc, toStraight(str_middle), 74 + x_shift, topMiddle + 14, 28);
//  } else {
//     strChtEngMix2(doc, toStraight(str_middle), 77 + x_shift, topMiddle, 22);
//  }
  //////////////  左邊  /////////////// 名字
  doc.setFontSize(16).setFont('BiauKai');
  if (!!str_left) {
    str_left = str_left.replace(",", " ");
    if (str_middle.indexOf("冤") !== -1) {
      doc.text(toStraight("解冤者 " + str_left), 0 + x_shift, topLeft);
    } else {
      doc.text(toStraight("陽上報恩 " + str_left), 0 + x_shift, topLeft);
    }
    doc.text(toStraight("拜薦"), 0 + x_shift, 465);
  }
  //////////////  右邊  /////////////// 地址
  strChtEngMix2(doc, toStraight(str_right), x_shift + 162, topRight, 16);
}

// 吉祥ok 如意ok 一大張 密宗（阿貲達）
export const prayPage18 = (doc, str_top1, str_top2, str_left, str_middle, str_right, noMorePage, entry) => {
  doc.addImage(prayLight2png, 'PNG', 180, 30, 240, 790);

  doc.setFont('msjh').setFontSize(14);
  doc.text("|", 174, 15);
  doc.text("|", 424, 15);
  doc.text("|", 174, a4pageHeight - 8);
  doc.text("|", 424, a4pageHeight - 8);
  doc.text(str_top1 + " " + str_top2, 255, 15);

  //////////////  中間  ///////////////
  let topMiddle = 210;
  str_middle = commaToSpace(str_middle);
  const isPass = entry.passname || entry.passname1 || entry.passname2 || entry.passname3 || entry.passname4;
  printMiddleTitle(doc, isPass, 266, topMiddle, 670, 24);

  const arrMiddle = cutStrToArr(str_middle.replace(/,/g, " "), 13);
  const arrMiddle2 = getArrMiddle2_18ab(entry, 24);
  topMiddle += 65;

  // console.log("prayPage18", arrMiddle, arrMiddle2);
  if (arrMiddle2.length === 0) {
    printMiddleBig(doc, arrMiddle, topMiddle);
  } else {
    printMiddle2Small(doc, arrMiddle2, topMiddle, a4pageWidth / 2, 3);
  }

  doc.setFontSize(30);
  //////////////  左邊  ///////////////
  if (isPass) {
    let topLeft = 250;
    str_left = commaToSpace(str_left);

    doc.setFont("BiauKai").setFontSize(32);
    topLeft = 400;
    doc.text(toStraight("陽上"), 170, topLeft);
    topLeft += 70;
    doc.text(toStraight("叩薦"), 170, topLeft + 180);

    // printLeft(doc, str_left, topLeft,15,22)

    let arrLeft = cutStrToArr(str_left, 8);
    if (str_left.length > 14) {
      arrLeft = cutStrToArr(str_left, 8);
    } else if (str_left.length > 8) { arrLeft = cutStrToArr(str_left, 16); }
    if (str_left.match(/[a-z]/g)) {
      arrLeft = cutStrToArr2(str_left, 12);
    }
    doc.setFontSize(24);
    if (arrLeft.length === 1) {
      topLeft -= 5;
      if (arrLeft[0].length === 8) {
        strChtEngMix2(doc, toStraight(arrLeft[0]), 175, topLeft, 18);
      }
      else if (arrLeft[0].length > 8) {
        strChtEngMix2(doc, toStraight(arrLeft[0]), 175, topLeft, 16);
      } else {
        strChtEngMix2(doc, toStraight(arrLeft[0]), 170, topLeft, 24);
      }
    } else if (arrLeft.length === 2) {
      topLeft -= 5;
      strChtEngMix2(doc, toStraight(arrLeft[0]), 185, topLeft, 16);
      strChtEngMix2(doc, toStraight(arrLeft[1]), 170, topLeft, 16);
    } else if (arrLeft.length === 3) {
      topLeft -= 5;
      // doc.setFontSize(16);
      let size = 16;
      if (arrLeft[2].length > 12 || arrLeft[1].length > 12 || arrLeft[0].length > 12) { size = 10; }
      strChtEngMix2(doc, toStraight(arrLeft[2]), 190 - 2 * size, topLeft, size);
      strChtEngMix2(doc, toStraight(arrLeft[1]), 190 - size, topLeft, size);
      strChtEngMix2(doc, toStraight(arrLeft[0]), 190, topLeft, size);

    } else if (arrLeft.length === 4) {
      topLeft -= 5;
      // doc.setFontSize(16);
      let size = 16;
      if (arrLeft[3].length > 12 || arrLeft[2].length > 12 || arrLeft[1].length > 12 || arrLeft[0].length > 12) { size = 10; }
      strChtEngMix2(doc, toStraight(arrLeft[2]), 190 - 3 * size, topLeft, size);
      strChtEngMix2(doc, toStraight(arrLeft[2]), 190 - 2 * size, topLeft, size);
      strChtEngMix2(doc, toStraight(arrLeft[1]), 190 - size, topLeft, size);
      strChtEngMix2(doc, toStraight(arrLeft[0]), 190, topLeft, size);
    }
  }
}

// 個人ok 1列4欄
export const prayPage18a = (doc, pos_num, str_top1, str_top2, str_left, str_middle = '', str_right, noMorePage, entry, prt) => {
  console.log('prayPage18a')
  let x_shift = 10;
  let topMiddle = 140;
  // let topLeft = 150;
  let topTitle = 15;

  x_shift = x_shift + 215 * pos_num;
  let x_middle = x_shift + 180 / 2

  doc.addImage(prayLight2png, 'PNG', x_shift, 30, 180, 565);

  doc.setFont('msjh');
  doc.setFontSize(10);
  if (pos_num !== 3) {
    doc.text("|", x_shift + 190, topTitle);
    doc.text("|", x_shift + 190, 560);
  }
  doc.text(str_top1 + ' ' + str_top2, 65 + x_shift, topTitle); // 總功德主

  topMiddle = 160;
  printMiddleTitle(doc, str_left, 65 + x_shift, topMiddle, 480, 18);

  //////////////  中間  ///////////////
  topMiddle += 40;
  x_shift += 2;

  const arrMiddle = cutStrToArr(str_middle.replace(/,/g, " "), 13);
  const arrMiddle2 = getArrMiddle2_18ab(entry.entry, 13);

  // console.log('prayPage18a', arrMiddle, arrMiddle2);
  if (arrMiddle2.length === 0) { // 只有陽上的
    printMiddleSmall(doc, arrMiddle, topMiddle, x_middle, 1)
  } else {
    printMiddle2Small(doc, arrMiddle2, topMiddle, x_middle, 1)
  }

  //////////////  左邊  /////////////// 名字
  doc.setFontSize(16);
  if (str_left) {
    printLeftSmall(doc, str_left, 280, 190, x_shift, 16)
  }
}

// 圓滿OK 總功德主OK 福慧OK 1列2欄
export const prayPage18b = (doc, pos_num, str_top1, str_top2, str_left, str_middle = '', str_right, noMorePage, entry, prt) => {
  // console.log('prayPage18b', pos_num, str_top1, str_top2)
  let x_shift = 60;
  let topMiddle = 140;
  let topTitle = 20;

  x_shift = x_shift + 300 * pos_num;
  let x_middle = x_shift + 220 / 2
  doc.addImage(prayLight2png, 'PNG', x_shift, 30, 220, 780);

  doc.setFont('msjh');
  doc.setFontSize(14);
  if (pos_num !== 3) {
    doc.text("|", 300, topTitle);
    doc.text("|", 300, 810);
  }

  doc.text(str_top1 + " " + str_top2, 10 + x_shift, topTitle); // 總功德主

  topMiddle = 210;
  printMiddleTitle(doc, str_left, x_middle - 27.5, topMiddle, 660, 22);

  //////////////  中間  ///////////////
  const arrMiddle = cutStrToArr(str_middle.replace(/,/g, " "), 13);
  const arrMiddle2 = getArrMiddle2_18ab(entry.entry, 24);
  // console.log('prayPage18b', arrMiddle, arrMiddle2);

  topMiddle += 50;
  if (arrMiddle2.length === 0) { // 只有陽上的
    printMiddleSmall(doc, arrMiddle, topMiddle, x_middle)
  } else {
    printMiddle2Small(doc, arrMiddle2, topMiddle, x_middle, 2)
  }

  //////////////  左邊  /////////////// 名字
  if (str_left) {
    printLeftSmall(doc, str_left, 380, 285, x_shift - 10, 28)
  }
}

export const prayPage2 = (doc, printType, arrObj) => {
  // console.log('prayPage2', arrObj)
  // initial definition 直的幾個 橫的幾個
  const x_num = Number(printType.oilpray_x);
  const y_num = Number(printType.oilpray_y);

  // 每個燈牌的大小 (給底圖用的)
  let each_height, each_width;

  // 不要印到紙張邊界
  const width_shift_init = 10;

  if (printType.oilpray_layout === "1") { // 直的
    // 不要印到紙張邊界
    let a4pageHeight2 = a4pageHeight - 10;
    let a4pageWidth2 = a4pageWidth - 20;

    each_height = Math.round(a4pageHeight2 / y_num, 2);
    each_width = Math.round(a4pageWidth2 / x_num, 2);
  } else {
    // 不要印到紙張邊界
    let a4pageHeight2 = a4pageHeight - 20;
    let a4pageWidth2 = a4pageWidth - 10;

    each_height = Math.round(a4pageWidth2 / y_num, 2);
    each_width = Math.round(a4pageHeight2 / x_num, 2);
  }
  // 每一個項目的偏移量
  // let width_shift = 0;
  let width_shift = width_shift_init;
  let height_shift = 0;

  let text_height_shift1 = 0.0809 * each_height; //8; 上面文字的位置
  let text_height_shift2 = (7 / 9) * each_height;//77;  下面文字的位置

  // 文字的細微的偏移量
  let text_width_shift_modify = 0;
  let text_height_shift_modify = 0;

  // 預留要打孔、掛標籤的位置
  let upper_empty_shift = 25;

  let useFontSize = 14;
  // let useNameLength = 8;

  //  根據寬多少個來調整文字大小
  // console.log(printType, printType.oilpray_layout, x_num, y_num)
  if (printType.oilpray_layout === "1") { // 直的
    if (x_num === 2) {
      useFontSize = 24;
    } else if (x_num === 3) {
      useFontSize = 16;
      text_height_shift_modify = 12;
    } else if (x_num === 4) {
      useFontSize = 14;
      if (y_num === 4) {
        text_height_shift_modify = 8;
      }
    } else if (x_num === 5) {
      useFontSize = 14;
    } else if (x_num === 6) {
      useFontSize = 14;
      text_height_shift_modify = 10;
      // text_width_shift_modify = 5;
    }
  } else { // 橫的
    if (x_num === 2) {
      useFontSize = 36;
    } else if (x_num === 3) {
      if (y_num === 1) {
        useFontSize = 30;
        // text_height_shift_modify = 10;
      }
    } else if (x_num === 4) {
    } else if (x_num === 5) {
    } else if (x_num === 6) {
    }
  }

  let text_width_shift = 0.5 * each_width - (useFontSize) + width_shift_init; // 文字在中間的位置
  let i = 0; // 已印寬的數量
  let j = 0; // 已印長的數量

  // 每一筆資料開始跑
  for (let ii = 0; ii < arrObj.length; ii++) {
    let entry = arrObj[ii];
    // console.log(entry.serial, entry.livename, entry.praytypeText)

    // console.log("x_num: " + x_num + " y_num: " + y_num);
    // console.log("text_width_shift: "+text_width_shift);

    if (i === x_num && j === y_num - 1) {
      // doc.addPage();
      if (printType.oilpray_layout === "1") { // 直的
        doc.addPage({ size: printType.oilpray_paper, margin: 1 });
      }
      else {
        doc.addPage({ size: printType.oilpray_paper, margin: 1, orientation: "l" });
      }
    }
    if (i === x_num) { // 寬印滿 -> 高往下跑
      i = 0;

      // 重設偏移量
      text_width_shift = 0.5 * each_width - (useFontSize) + width_shift_init;
      //text_width_shift=0.4*each_width;//3x3:0.4;;
      width_shift = width_shift_init;

      // 高往下跑
      text_height_shift1 += each_height;
      text_height_shift2 += each_height;
      height_shift += each_height;

      j++;
    }
    if (j === y_num) { // 高印滿 -> 寬重設
      j = 0;
      height_shift = 0;
      text_height_shift1 = 0.0809 * each_height;//8;
      text_height_shift2 = (7 / 9) * each_height;//77;
    }

    // console.log(i, j, width_shift, height_shift);
    doc.addImage(prayCandle2png, 'PNG', width_shift, height_shift + upper_empty_shift, each_width, each_height - upper_empty_shift);
    text_width_shift = text_width_shift + text_width_shift_modify;

    printMiddleTitle(doc, 0, text_width_shift, text_height_shift1 + text_height_shift_modify + upper_empty_shift - 3, text_height_shift2 + text_height_shift_modify + 3, useFontSize);

    // console.log(arrObj);
    // console.log(entry.serial + " " + entry.livename);

    //  印名字
    let dataArr2 = entry.livename ? entry.livename.split(",") : [''];
    // console.log(dataArr2);

    // 切斷名字
    let nowsrtlen = dataArr2[0].length;
    if (nowsrtlen > 6) {
      let tmpArr = [];
      tmpArr.push = dataArr2[0].substr(0, 8).trim();
      tmpArr.push = dataArr2[0].substr(8, nowsrtlen - 8).trim();
      dataArr2 = tmpArr;
    }

    // // 姓名的列數
    let name_text_height_shift = text_height_shift1 + useFontSize * 3 + upper_empty_shift;
    if (dataArr2.length === 1) {
      // name_text_height_shift = name_text_height_shift + useFontSize*2;
      doc.text(toStraight(dataArr2[0]), text_width_shift + useFontSize / 2 + 2, name_text_height_shift);
    } else if (dataArr2.length === 2) {
      name_text_height_shift = name_text_height_shift + useFontSize * 1;
      doc.text(toStraight(dataArr2[0]), text_width_shift, name_text_height_shift);
      doc.text(toStraight(dataArr2[1]), text_width_shift + useFontSize * 2, name_text_height_shift);
    } else if (dataArr2.length === 3 || dataArr2.length === 6) {
      if (dataArr2.length === 3) {
        name_text_height_shift = name_text_height_shift + useFontSize * 1;
      }
      doc.text(toStraight(dataArr2[0]), text_width_shift + useFontSize, name_text_height_shift);
      doc.text(toStraight(dataArr2[1]), text_width_shift - useFontSize * 0.6, name_text_height_shift);
      doc.text(toStraight(dataArr2[2]), text_width_shift + useFontSize * 2.5, name_text_height_shift);
      if (dataArr2.length === 6) {
        name_text_height_shift = name_text_height_shift + useFontSize * 4.5;
        doc.text(toStraight(dataArr2[3]), text_width_shift + useFontSize, name_text_height_shift);
        doc.text(toStraight(dataArr2[4]), text_width_shift - useFontSize * 0.6, name_text_height_shift);
        doc.text(toStraight(dataArr2[5]), text_width_shift + useFontSize * 2.5, name_text_height_shift);
      }
    } else if (dataArr2.length === 4 || dataArr2.length === 7 || dataArr2.length === 8) {
      if (dataArr2.length === 4) {
        name_text_height_shift = name_text_height_shift + useFontSize * 1;
      }
      doc.text(toStraight(dataArr2[0]), text_width_shift, name_text_height_shift);
      doc.text(toStraight(dataArr2[1]), text_width_shift + useFontSize * 1.8, name_text_height_shift);
      doc.text(toStraight(dataArr2[2]), text_width_shift - useFontSize * 1.8, name_text_height_shift);
      doc.text(toStraight(dataArr2[3]), text_width_shift + useFontSize * 3.6, name_text_height_shift);
      if (dataArr2.length === 7 || dataArr2.length === 8) {
        name_text_height_shift = name_text_height_shift + useFontSize * 4.5;
        doc.text(toStraight(dataArr2[4]), text_width_shift, name_text_height_shift);
        doc.text(toStraight(dataArr2[5]), text_width_shift + useFontSize * 1.8, name_text_height_shift);
        doc.text(toStraight(dataArr2[6]), text_width_shift - useFontSize * 1.8, name_text_height_shift);
        if (dataArr2.length === 8)
          doc.text(toStraight(dataArr2[7]), text_width_shift + useFontSize * 3.6, name_text_height_shift);
      }
    } else if (dataArr2.length === 5 || dataArr2.length === 9 || dataArr2.length === 10) {
      if (dataArr2.length === 5) {
        name_text_height_shift = name_text_height_shift + useFontSize * 1;
      }
      doc.text(toStraight(dataArr2[0]), text_width_shift + useFontSize, name_text_height_shift);
      doc.text(toStraight(dataArr2[1]), text_width_shift - useFontSize * 0.6, name_text_height_shift);
      doc.text(toStraight(dataArr2[2]), text_width_shift + useFontSize * 2.5, name_text_height_shift);
      doc.text(toStraight(dataArr2[3]), text_width_shift - useFontSize * 2.2, name_text_height_shift);
      doc.text(toStraight(dataArr2[4]), text_width_shift + useFontSize * 4, name_text_height_shift);
      if (dataArr2.length === 9 || dataArr2.length === 10) {
        name_text_height_shift = name_text_height_shift + useFontSize * 4.5;
        doc.text(toStraight(dataArr2[5]), text_width_shift + useFontSize, name_text_height_shift);
        doc.text(toStraight(dataArr2[6]), text_width_shift - useFontSize * 0.6, name_text_height_shift);
        doc.text(toStraight(dataArr2[7]), text_width_shift + useFontSize * 2.5, name_text_height_shift);
        doc.text(toStraight(dataArr2[8]), text_width_shift - useFontSize * 2.2, name_text_height_shift);
        if (dataArr2.length === 10)
          doc.text(toStraight(dataArr2[9]), text_width_shift + useFontSize * 4, name_text_height_shift);
      }
    } else if (dataArr2.length > 10) {
      let usesetsetFontSize2 = useFontSize - 6;
      doc.setFontSize(usesetsetFontSize2);

      text_width_shift += 9;
      doc.text(toStraight(dataArr2[0]), text_width_shift + usesetsetFontSize2, name_text_height_shift);
      doc.text(toStraight(dataArr2[1]), text_width_shift - usesetsetFontSize2 * 0.4, name_text_height_shift);
      doc.text(toStraight(dataArr2[2]), text_width_shift + usesetsetFontSize2 * 2.3, name_text_height_shift);
      doc.text(toStraight(dataArr2[3]), text_width_shift - usesetsetFontSize2 * 1.8, name_text_height_shift);
      doc.text(toStraight(dataArr2[4]), text_width_shift + usesetsetFontSize2 * 3.6, name_text_height_shift);
      doc.text(toStraight(dataArr2[5]), text_width_shift - usesetsetFontSize2 * 3.2, name_text_height_shift);
      doc.text(toStraight(dataArr2[6]), text_width_shift + usesetsetFontSize2 * 5.0, name_text_height_shift);

      name_text_height_shift += usesetsetFontSize2 * 4.5;
      doc.text(toStraight(dataArr2[7]), text_width_shift + usesetsetFontSize2, name_text_height_shift);
      doc.text(toStraight(dataArr2[8]), text_width_shift - usesetsetFontSize2 * 0.4, name_text_height_shift);
      doc.text(toStraight(dataArr2[9]), text_width_shift + usesetsetFontSize2 * 2.3, name_text_height_shift);
      if (!!dataArr2[10]) doc.text(toStraight(dataArr2[10]), text_width_shift - usesetsetFontSize2 * 1.8, name_text_height_shift);
      if (!!dataArr2[11]) doc.text(toStraight(dataArr2[11]), text_width_shift + usesetsetFontSize2 * 3.6, name_text_height_shift);
      if (!!dataArr2[12]) doc.text(toStraight(dataArr2[12]), text_width_shift - usesetsetFontSize2 * 3.2, name_text_height_shift);
      if (!!dataArr2[13]) doc.text(toStraight(dataArr2[13]), text_width_shift + usesetsetFontSize2 * 5.0, name_text_height_shift);

      name_text_height_shift += usesetsetFontSize2 * 4.5;
      if (!!dataArr2[14]) doc.text(toStraight(dataArr2[14]), text_width_shift + usesetsetFontSize2, name_text_height_shift);
      if (!!dataArr2[15]) doc.text(toStraight(dataArr2[15]), text_width_shift - usesetsetFontSize2 * 0.4, name_text_height_shift);
      if (!!dataArr2[16]) doc.text(toStraight(dataArr2[16]), text_width_shift + usesetsetFontSize2 * 2.3, name_text_height_shift);
      if (!!dataArr2[17]) doc.text(toStraight(dataArr2[17]), text_width_shift - usesetsetFontSize2 * 1.8, name_text_height_shift);
      if (!!dataArr2[18]) doc.text(toStraight(dataArr2[18]), text_width_shift + usesetsetFontSize2 * 3.6, name_text_height_shift);
      if (!!dataArr2[19]) doc.text(toStraight(dataArr2[19]), text_width_shift - usesetsetFontSize2 * 3.2, name_text_height_shift);
      if (!!dataArr2[20]) doc.text(toStraight(dataArr2[20]), text_width_shift + usesetsetFontSize2 * 5.0, name_text_height_shift);
    }

    //  印編號
    doc.setFont('msjh');
    doc.setFontSize(useFontSize - 4);
    const tt = entry.praytypeText.charAt(0) === '[' ? entry.praytypeText.charAt(5) : entry.praytypeText.charAt(0);
    // doc.setFont('BiauKai');
    doc.text(tt + '\n' + (Number(entry.serial) + '' || ''),
      text_width_shift - ((useFontSize - 2) * 1.5),
      upper_empty_shift + text_height_shift1 + (useFontSize));

    width_shift += each_width;
    text_width_shift += each_width;
    i++;
  }
}
export const prayPage3 = (doc, arrObj, printDate, praytype, companyObj) => {
  let nowPage = 1;
  // let totalPage = Math.ceil(arrObj.length/26);
  let year = arrObj[0].year;
  let pray1 = arrObj[0].pray1Text;
  let pray2 = "";
  let pray3 = "";
  let old_pray2 = "1";
  let old_pray3 = "1";

  printTitle(doc, printDate, arrObj[0], companyObj);
  let now_y = 60;
  for (let i = 0; i < arrObj.length; i++) {
    let entry = arrObj[i];
    // console.log(entry)
    if (!entry.praytypeId || (!entry.livename && !entry.passname)) {
      continue;
    }

    let next_y = 0;
    let passname_x = 410;
    // let passname_x = 310;
    // let addr_x = 570;
    let addr_x = 475;
    // let addr_width = 300;
    let addr_width = 360;
    let addr_pass = "";
    // 換頁
    if (now_y > 500 || year !== entry.year || pray1 !== entry.pray1Text) {
      printPageNum(doc, nowPage);
      newPage(doc, printDate, entry, companyObj);
      // newPage(doc, printDate, entry, nowPage, rowPage, colPage, companyObj);

      nowPage++;
      pray2 = "";
      pray3 = "";
      now_y = 60;
    }
    year = entry.year;
    pray1 = entry.pray1Text;

    if (entry.praytypeText === '小燈' || entry.praytypeText === '吉祥' || entry.praytypeText === '如意') {
      addr_x = 300;
      addr_width = 500;
    } else if (entry.praytypeText === '燈主') {
      addr_x = 420;
      addr_width = 500;
    } else if (entry.pray2Text === "初亡") {
      addr_x = 565;
      addr_width = 360;
    }
    /*   else if(entry.praytypeText === '初亡'){
      addr_x = 475;
      addr_width = 360;
    }*/

    // 每次新的小標
    pray2 = entry.pray2Text;
    // pray3 = entry.praytypeText;
    pray3 = '';//PrayingType.findOne(entry.praytypeId).value;
    if (!!entry.passname) pray3 = "拔";
    else if (!!entry.passname1) pray3 = "拔-歷代祖先";
    else if (!!entry.passname2) pray3 = "拔-冤親債主";
    else if (!!entry.passname3) pray3 = "拔-地基主";
    else if (!!entry.passname4) pray3 = "拔-嬰靈";

    // console.log("entry");
    // console.log(entry);

    // 每段標題
    if (pray2 !== old_pray2 || pray3 !== old_pray3) {
      // console.log("new title");
      now_y += 8;
      old_pray2 = pray2;
      old_pray3 = pray3;

      doc.setFont('BiauKai');
      if (!!pray2) {
        doc.setFontSize(14);
        // console.log(pray2, now_y)
        doc.text(pray2 + '', 15, now_y);

        doc.setFontSize(18);
        now_y += 20;
      }

      // console.log(praytype);
      const ptObj = praytype.find(item => item.id === entry.praytypeId);
      if (!ptObj) {
        console.log(ptObj, entry.praytypeId, entry)
      }
      if (ptObj && ptObj.printTypeId === 1) { // 消
        passname_x = 120;
      } else {
        // doc.text("陽上", 120, now_y);
        doc.text("陽上", 50, now_y);
        // passname_x = 410;
        passname_x = 325;
      }

      if (!!pray3 && entry.pray2Text === "初亡") {
        passname_x = 270;
        doc.text(pray3, passname_x, now_y);
        doc.text("生卒時", passname_x + 100, now_y);
      } else if (!!pray3 && entry.praytypeText !== '燈主' && entry.praytypeText !== '小燈' && entry.praytypeText !== '吉祥' && entry.praytypeText !== '如意') {
        // 印各種類型的拔
        // doc.text(pray3.replace(/ /g, ""), passname_x, now_y);
        // doc.text(pray3, passname_x, now_y);
        // console.log(pray3, passname_x, now_y)
        doc.setFontSize(18);
        doc.text(pray3, passname_x - 10, now_y);
      }

      doc.setFont('BiauKai');
      doc.text("地址", addr_x, now_y);
      doc.line(10, now_y + 5, 820, now_y + 5);
      now_y = now_y + 24;
      // doc.line(10, now_y + 1,); // 劃線
    }

    // 內容
    doc.setFontSize(18).setFont('Times', 'Roman');
    if (!!entry.serial) {
      doc.text(Number(entry.serial || 0) + '', 15, now_y);
    } else {
      doc.text("0", 15, now_y);
    }

    // 地址
    // let addr = entry.addr;
    let addr_next_y = now_y;

    let addr = "";
    if (!!addr_pass) {
      addr = addr_pass;
    } else {
      addr = entry.addr || '';
    }
    if (addr_x > 500) {
      let strLength = 15;
      if (addr.length > strLength) {
        let tmp_length = addr.length;
        let tmp_pos = strLength;
        while (tmp_length > strLength) {
          // console.log(addr)
          addr = splice(addr, tmp_pos, 0, "\n");
          next_y += 20;
          addr_next_y += 20;

          tmp_pos += strLength;
          tmp_length -= strLength;
        }
      }
    } else {
      let strLength = 22;
      // console.log(addr)
      if (addr && addr.length > strLength) {
        let tmp_length = addr.length;
        let tmp_pos = strLength;
        while (tmp_length > strLength) {
          addr = splice(addr, tmp_pos, 0, "\n");
          next_y += 20;
          addr_next_y += 20;

          tmp_pos += strLength;
          tmp_length -= strLength;
        }
      }
    }
    doc.setFont('BiauKai');
    doc.setFontSize(18).text(addr, addr_x, now_y); // 列印地址

    let livename = entry.livename && entry.livename.replace(/\,/g, " ");
    doc.setFont('BiauKai');
    if (praytype.find(item => item.id === entry.praytypeId) && praytype.find(item => item.id === entry.praytypeId).printTypeId !== "2") { // 不是拔
      if (!!livename) {
        const LEN = 23;
        if (livename.length < LEN) {
          doc.text(livename, 50, now_y); // 太長就需要斷行
          now_y = now_y + 14;
        } else {
          let tmp_length = livename.length;
          let tmp_pos = LEN;
          let tmp_now_y = now_y;

          while (tmp_length > LEN) {
            const strIndex = livename.substring(tmp_pos).indexOf(' ');
            livename = splice(livename, tmp_pos + strIndex, 1, "\n");
            tmp_pos = tmp_pos + LEN;
            tmp_length = tmp_length - LEN;
            // console.log(strIndex, tmp_pos, tmp_length)

            tmp_now_y += LEN;
            next_y += LEN;
          }
          doc.text(livename, 50, now_y); // 
          now_y = tmp_now_y + 8;
        }
      }
    } else { // 所有的拔
      // console.log(livename, now_y)
      doc.text(livename || '', 50, now_y);
      next_y = now_y + 14;// now_y;

      // 拔渡者
      let passname = entry.passname;
      if (!!entry.passname1) passname = entry.passname1;
      else if (!!entry.passname2) passname = entry.passname2;
      else if (!!entry.passname3) passname = entry.passname3;
      else if (!!entry.passname4) passname = entry.passname4;

      // console.log("livename", livename, "passname", passname);
      if (!!passname) { //.replace(/\,/g, "\n")
        let tmp = passname;
        if (tmp.indexOf(",") === -1) {
          if (passname.length > 8) {
            tmp = splice(passname, 8, 0, "\n");
            if (passname.length > 16) {
              tmp = splice(passname, 16, 0, "\n");
              next_y = now_y + 18;
            }
            next_y += 18;
          }
        } else {
          tmp = passname.replace(/\,/g, "\n");
        }

        if (entry.pray2Text === "初亡") {
          // passname_x = 260;
          doc.text(tmp, 270, now_y);
          passname_x = 270 + 100;

          //mic1202 People.findOne({ familyId: p1.familyId, name: passname, isLive: 0 });
          let passObj = (entry.people0 && entry.people0.length && entry.people0[0]) || '';
          // addr_pass = passObj.addr;
          /*
          bornYear: '28',
          bornMonth: '10',
          bornDay: '15',
          bornTime: '12',
          bornTimeText: '亥',
          passYear: '104',
          passMonth: '10',
          passDay: '4',
          passTime: '7',
          passTimeText: '午',
           */
          // console.log(passObj)
          if (!!passObj) {
            let d1 = "", d2 = "";
            if (passObj.bornYear && passObj.bornMonth && passObj.bornDay) {
              d1 = "農" + passObj.bornYear + "年" + passObj.bornMonth + "月" + passObj.bornDay + "日";
              if (passObj.bornTimeText) {
                d1 = d1 + passObj.bornTimeText + "時";
              }
            }
            if (passObj.passYear && passObj.passMonth && passObj.passDay) {
              d2 = "農" + passObj.passYear + "年" + passObj.passMonth + "月" + passObj.passDay + "日";
              if (passObj.passTimeText) {
                d2 = d2 + passObj.passTimeText + "時";
              }
            }
            doc.setFontSize(16).text("生 " + d1, passname_x, now_y);
            next_y += 18;
            doc.setFontSize(16).text("卒 " + d2, passname_x, now_y + 16);

          }
        } else {
          doc.text(tmp, 325, now_y);
        }
      }
      if (now_y > next_y) {
        next_y = now_y + 14;//now_y;
      }
    }

    if (now_y > next_y) {
      next_y = now_y;
    }
    if (addr_next_y > next_y) {
      next_y = addr_next_y + 18;
    }
    now_y = next_y + 6;

    if (i === arrObj.length - 1) {
      printPageNum(doc, nowPage);
      nowPage++;
    }
  }
}
export const prayPage4 = (doc, arrObj) => {
  let nowMember = 0;
  let nowPage = 1;
  let rowPage = 16;
  let totalPage = Math.ceil(arrObj.length / rowPage);

  // 整個頁面的loop
  let width_num = 12
  let table_x = 14;
  let table_y = 24;
  let table_width = 810;
  let cell_width = table_width / width_num;
  let table_height = 550;

  while (nowMember < arrObj.length) {
    doc.rect(table_x, table_y, table_width, table_height);

    let left_shift = table_x;
    let top_shift = table_y;

    for (let i = 0; i < width_num; i++) {
      let entry = arrObj[nowMember];
      // console.log(entry);

      // doc.line(left_shift, table_y,left_shift, table_y + table_height);
      doc.line(left_shift, table_y, left_shift, table_y + table_height);

      if (typeof entry !== "undefined") {

        let type = "功德";
        if (entry.praytypeText.length > 2) {
          type = "供養三寶";
        }
        doc.setFontSize(26);
        doc.text(toStraight(type), left_shift + 15 + 4, table_y + 2);

        doc.setFontSize(36);
        let name = entry.livename;
        if (name.length > 4) {
          doc.setFontSize(26);
        }
        doc.text(toStraight(name), left_shift + 15, 135 + 2);
        let donate = "5000";

        doc.setFontSize(36);
        doc.text(toStraight(donate), left_shift + 15, 350 + 2);

        doc.setFontSize(36);
        doc.setFont('BiauKai');
        doc.text(toStraight("大德     元"), left_shift + 15, 282);
      }

      left_shift += cell_width;
      nowMember++;
    }

    if (nowMember < arrObj.length) {
      doc.addPage();
      // nowMember+=23;
    } else {
      break;
    }
  }
}
export const prayPage5 = (doc, arrObj) => {

  doc.setFontSize(32);
  // doc.setFont('BiauKai');
  doc.text("123 測試", 100, 100);
  // doc.rotate(-180, { origin: [150, 70] }).text("普施圓桌", -260, -260);
  // doc.text("測試一 測試二 測試三 測試四", doc.x, now_y + 60);

  // console.log(doc.x +" "+now_y);

  // 弄正的
  // doc.rotate(-180, { origin: [150, 70] });
  // doc.text("普施圓桌", 50, 450);
  // doc.text("測試a 測試b 測試c 測試d", 50, 550);
}
export const prayPage80 = async (doc, params) => {
  const { people1 = [], praying1Obj = {}, companyObj, qrcode = '' } = params;
  const COLOR = "#B22222";
  let count = 0;
  const interval = 425;
  doc.setTextColor(COLOR);
  doc.setDrawColor(COLOR);
  doc.setLineWidth(2);
  while (count <= 1) {
    doc.rect(15, 25 + (count * interval), 550, 350);

    doc.setFont('BiauKai');
    doc.setFontSize(26);
    doc.text(companyObj.companyTitle || companyObj.name || '', 160, 55 + (count * interval)); // companyObj.companyTitle || 
    doc.line(15, 70 + (count * interval), 565, 70 + (count * interval));
    doc.setFontSize(20);
    // const pray1 = "";
    // if(Pray1.findOne(queryVar.prayname)){
    //   pray1 = Pray1.findOne(queryVar.prayname).value;
    // }
    // doc.text("傳授護國 "+ pray1 +" 繳款單", 180, 70);
    doc.setFontSize(16);
    doc.text("中華民國     年    月    日", 350, 95 + (count * interval));
    // doc.setFont('BiauKai').text("感謝狀", 100, 100);
    // doc.text("繳款單", 130, 100);
    doc.setFontSize(16);
    // console.log(praying1Obj)
    let name = "", addr = "";
    if (praying1Obj.whoapply && people1.find(item => item.objectId === praying1Obj.whoapply)) {
      const p = praying1Obj.whoapply && people1.find(item => item.objectId === praying1Obj.whoapply);
      name = p.name;
      addr = p.addr;
    }
    doc.text("茲承 ", 40, 120 + (count * interval));
    doc.text("大德", 490, 120 + (count * interval));
    doc.setFontSize(20);
    doc.text(name, 90, 121 + (count * interval));

    doc.setFontSize(16);
    doc.text("住址", 40, 160 + (count * interval));
    doc.text(addr, 90, 160 + (count * interval));
    doc.text("護戒功德金新台幣     萬     仟     佰     拾     元整", 40, 200 + (count * interval));
    doc.setFontSize(18);
    doc.text("護持戒法 功德無量 謹以感恩之心 誠致謝意 並祝 福慧增長", 45, 260 + (count * interval));
    doc.setFontSize(16);
    doc.text("桃園市政府 53.3.7   第01603號核准設立財團法人", 45, 283 + (count * interval));
    doc.setFontSize(14);
    doc.text("地址：桃園市桃園區朝陽街12號", 40, 308 + (count * interval));
    doc.text("電話：", 40, 330 + (count * interval));
    doc.text(`${companyObj.phoneNum}`, 90, 330 + (count * interval));
    doc.text("傳真：", 240, 330 + (count * interval));
    doc.text(`${companyObj.fax}`, 290, 330 + (count * interval));
    doc.line(15, 100 + (count * interval), 565, 100 + (count * interval));
    doc.text("統一編號：", 40, 350 + (count * interval));
    doc.text(`${companyObj.uniformNum}`, 115, 350 + (count * interval));
    doc.text("郵撥帳號：", 240, 350 + (count * interval));
    doc.text(`${companyObj.postalAccount}`, 315, 350 + (count * interval));
    doc.line(15, 130 + (count * interval), 565, 130 + (count * interval));
    doc.addImage(qrcode, 'PNG', 430, 288 + (count * interval), 80, 80);
    doc.text(count === 0 ? "收" : "存", 570, 325 + (count * interval));
    doc.text(count === 0 ? "執" : "根", 570, 345 + (count * interval));
    doc.text("聯", 570, 365 + (count * interval));
    count += 1
  }
  // doc.text("桃園市政府", 50, 346);
  // doc.text("核准設立財團法人", 50, 358);
}

export const prayPage81 = async (doc, params) => {
  const { people1 = [], praying1Obj = {}, companyObj, qrcode = '' } = params;
  console.log(companyObj)
  console.log(praying1Obj)
  console.log(people1)

  const COLOR = "#B22222";
  // doc.setTextColor(COLOR);
  // doc.setDrawColor(COLOR);
  doc.setLineWidth(2);
  let count = 0;
  const interval = 408;
  while (count <= 1) {
    doc.rect(25, 205 + (count * interval), 530, 180)

    doc.setFont('BiauKai');
    doc.setFontSize(22);
    doc.text(`${companyObj.companyTitle || companyObj.name || ''}`, 30, 53 + (count * interval));
    doc.text("收 據", 250, 157 + (count * interval));
    // doc.line(25, 70, 480, 70);

    doc.setFontSize(12);
    doc.text("桃園市政府 53.3.7 第01603號核准設立財團法人", 30, 70 + (count * interval));
    doc.text("地址：", 30, 85 + (count * interval));
    doc.text(`${companyObj.address}`, 70, 85 + (count * interval));
    doc.text("電話：", 30, 100 + (count * interval));
    doc.text(`${companyObj.phoneNum}`, 70, 100 + (count * interval));
    doc.text("傳真：", 150, 100 + (count * interval));
    doc.text(`${companyObj.fax}`, 190, 100 + (count * interval));
    doc.text("郵撥帳號：", 30, 115 + (count * interval));
    doc.text(`${companyObj.postalAccount}`, 90, 115 + (count * interval));
    doc.text("統一編號：", 150, 115 + (count * interval));
    doc.text(`${companyObj.uniformNum}`, 210, 115 + (count * interval));


    // doc.text("桃園市政府 53.3.7   第01603號核准設立財團法人", 55, 283);
    // doc.text("地 址", 40, 89); // 12號
    // doc.text("桃園市桃園區朝陽街12號", 110, 89); // 12號
    // doc.text(`${companyObj.addr || ''}`, 110, 89); // 12號

    // doc.text("電話", 323, 89);
    // doc.setFont('Times', 'Roman');
    // doc.text("(03) 3397021-2", 365, 89);

    // doc.setFont('Times', 'Roman');
    // doc.text("12", 253, 79 + 8); // 12號
    // doc.line(25, 100, 575, 100);

    // doc.setFont('BiauKai');
    // doc.text("捐款別", 40, 120);
    // doc.line(25, 130, 575, 130);

    // 直的線
    // doc.line(100, 70, 100, 260);
    // doc.line(310, 70, 310, 130);
    // doc.line(480, 25, 480, 100);

    // doc.line(400, 130, 400, 200);
    // doc.line(448, 130, 448, 200);

    // doc.text("班別", 410, 153);
    // doc.line(400, 165, 575, 165);
    // doc.text("號碼", 410, 187);
    /*    const pray1 = "";
        if(Pray1.findOne(queryVar.prayname)){
          pray1 = Pray1.findOne(queryVar.prayname).value;
        }
        doc.text("傳授護國 "+ pray1 +" 感謝狀", 180, 70);
    */
    // doc.setFontSize(16);
    // doc.text("收款日期   年  月  日", 323, 120);
    // doc.setFont('BiauKai').text("感謝狀", 100, 100);
    // doc.text("繳款單", 130, 100);
    doc.setFontSize(16);
    doc.text("中華民國     年     月     日 ", 30, 195 + (count * interval));
    doc.text("收據編號：", 275, 195 + (count * interval));
    doc.text(`${praying1Obj.ordernum}`, 353, 195 + (count * interval));

    let name = "", addr = "";
    if (praying1Obj.whoapply && people1.find(item => item.objectId === praying1Obj.whoapply)) {
      const p = praying1Obj.whoapply && people1.find(item => item.objectId === praying1Obj.whoapply);
      name = p.name;
      addr = p.addr;
    }

    doc.text("捐款芳名：", 35, 223 + (count * interval));
    doc.text(`${name}`, 120, 223 + (count * interval));
    doc.text("統一編號： ", 35, 243 + (count * interval));
    doc.line(25, 255 + (count * interval), 555, 255 + (count * interval));



    doc.text("捐款金額：", 35, 273 + (count * interval));
    doc.text(`新台幣${digitUppercase(praying1Obj.accReceived)}。`, 120, 273 + (count * interval)); //    萬   仟   佰   拾   

    doc.line(25, 340 + (count * interval), 555, 340 + (count * interval));

    doc.text("備註：", 40, 365 + (count * interval));

    doc.text("董事長", 30, 405 + (count * interval));
    doc.text("主辦會計", 220, 405 + (count * interval));
    doc.text("經手人", 410, 405 + (count * interval));
    doc.addImage(qrcode, 'PNG', 400, 40 + (count * interval), 80, 80);
    doc.text(count === 0 ? "收" : "存", 560, 275 + (count * interval));
    doc.text(count === 0 ? "執" : "根", 560, 295 + (count * interval));
    doc.text('聯', 560, 315 + (count * interval));
    count += 1
  }
}
export const familyPage = (doc, { people1, people0, companyObj = {} }) => {
  if (!people1 || !people1.length) {
    return "無資料";
  }

  doc.setFont('BiauKai').setFontSize(20);
  doc.text(`${companyObj.companyTitle || companyObj.name || ''} 家庭成員一覽`, 240, 30);

  let setFontSize = 12;
  let setFontSize2 = setFontSize + 4;
  let x = 20;
  let y = 50;

  // console.log(people1, people0)

  let pp = people1.find(item => item.mainPerson === '-1' || item.mainPerson === -1);
  if (pp && pp.name) {
    doc.setFontSize(12);
    doc.text("戶長：" + (pp.name || ''), x, y + 5);
  }

  y = y + setFontSize2 * 2;

  let arrP1field = ['name', '', 'post5code', 'addr', 'templeClass_text', 'templeLevel_text', 'memberId'];
  let arrP1text = ['陽上', '農曆生日', '區號', '地址', '班別', '身份', '社員編號'];
  let arrP1width = [100, 110, 40, 365, 50, 50, 50];

  let x1 = x;
  arrP1text.forEach((str, i) => {
    doc.text(str || '', x1, y);
    x1 += arrP1width[i];
  })

  y = y + setFontSize2;
  let yy = y;
  let yy_i = 0;

  let str = '';
  for (let i = 0; i < people1.length; i++) {
    let entry = people1[i];
    let xx = x;
    yy = y + yy_i * setFontSize2;

    for (let j = 0; j < arrP1field.length; j++) {
      str = entry[arrP1field[j]] || "";

      if (j === 1) {
        str = "";
        if (!!entry.bornYear) str = entry.bornYear + "年";
        if (!!entry.bornMonth) str = str + entry.bornMonth + "月";
        if (!!entry.bornDay) str = str + entry.bornDay + "日";
        if (!!entry.bornTimeText) str = str + entry.bornTimeText + "時";
      }

      if (!isNaN(str)) {
        str = str.toString();
      }

      doc.setFontSize(setFontSize).text(str || '', xx, yy);
      xx += arrP1width[j];
    }
    // console.log(entry[arrP1field[0]], yy);
    yy_i++;
    if (yy > 550) {
      doc.addPage();
      yy_i = 0;
      y = 50;
    }
  }
  y = yy + setFontSize2;

  arrP1field = ['name', '', '', 'lifeYear', 'addr'];
  arrP1text = ['拔渡', '農曆生日', '農曆卒日', '享壽', '地址'];
  arrP1width = [120, 125, 125, 60, 360];

  doc.setFont('BiauKai');
  x1 = x;
  y = y + setFontSize2 * 2;
  arrP1text.forEach((str, i) => {
    doc.text(str || '', x1, y);
    x1 += arrP1width[i];
  })
  // doc.text("拔渡", x, y);
  y = y + setFontSize2;

  yy_i = 0;
  yy = y;
  for (let i = 0; i < people0.length; i++) {
    let entry = people0[i];
    // console.log(entry);
    let xx = x;
    yy = y + yy_i * setFontSize2;
    for (let j = 0; j < arrP1field.length; j++) {
      str = entry[arrP1field[j]] || "";

      if (j === 1) {
        str = "";
        if (!!entry.bornYear) str = entry.bornYear + "年";
        if (!!entry.bornMonth) str = str + entry.bornMonth + "月";
        if (!!entry.bornDay) str = str + entry.bornDay + "日";
        if (!!entry.bornTimeText) str = str + entry.bornTimeText + "時";
      } else if (j === 2) {
        str = "";
        if (!!entry.passYear) str = entry.passYear + "年";
        if (!!entry.passMonth) str = str + entry.passMonth + "月";
        if (!!entry.passDay) str = str + entry.passDay + "日";
        if (!!entry.passTimeText) str = str + entry.passTimeText + "時";
      }

      if (!isNaN(str)) {
        str = str.toString();
      }
      doc.setFontSize(setFontSize);
      doc.text(str || '', xx, yy);
      xx += arrP1width[j];
    }
    yy_i++;
    if (yy > 550) {
      doc.addPage();
      yy_i = 0;
      y = 50;
    }
  }
}
export const lightPage1 = (doc, arrObj, printDate, lightType) => {
  const eachHeight = 52;
  let offset = 5, yOffset = 8;
  let j = 0, x = 0, y = 35;

  for (let i = 0; i < arrObj.length; i++) {
    const entry = arrObj[i];
    if (!entry.peopleName) {
      continue;
    }

    let n1 = entry.peopleName.trim();
    let n2 = (entry[`p${lightType}Name2`] && entry[`p${lightType}Name2`].trim()) || '';
    const num = entry[`p${lightType}Num`] || '';
    const sizeType = (lightType === '1' || lightType === '3') ? 1 : 2; // 2 big

    yOffset = 8;
    doc.setFontSize(14);
    if (y > a4pageHeight - eachHeight) {
      doc.addPage();
      y = 35;
      j = 0;
    }

    doc.setFont('BiauKai');
    let n1_x_shift = 0;
    let n2_x_shift = 0;
    let font_size_cht = 0;
    if (sizeType === 1) { // 小張的
      x = (j % 4 === 0) ? 0 : x += a4pageWidthHalf2;
      doc.rect(x, y - 6, a4pageWidthHalf2, eachHeight, 'S');

      // 整理文字
      if (n1.length > 7 && !n2) {
        n2 = n1.substring(6).trim();
        n1 = n1.substring(0, 6).trim();
      }

      if (!!n2) {
        doc.setFontSize(14);
        n1_x_shift = (a4pageWidthHalf2 - n1.length * 14) / 2;
        n2_x_shift = (a4pageWidthHalf2 - n2.length * 14) / 2;
        doc.text(n1, x + n1_x_shift, y + offset + yOffset - 6);//.
        doc.text(n2, x + n2_x_shift, y + offset + yOffset + 10);//.
      } else {
        doc.setFontSize(16);
        n1_x_shift = (a4pageWidthHalf2 - n1.length * 16) / 2; //  - n1_space*(font_size_cht/2)
        doc.text(n1, x + n1_x_shift, y + offset + yOffset + 2);//.
      }

      doc.setFontSize(12).setFont('Times', 'Roman');
      const num_x_shift = (a4pageWidthHalf2 - num.length * 6) / 2;
      doc.text(num, x + num_x_shift, y + offset + yOffset + 26);

      if (j % 4 === 3) {
        y += eachHeight;
      }
    } else { // 大張的
      x = (j % 2 === 0) ? 0 : x += a4pageWidthHalf;
      doc.rect(x, y - 6, a4pageWidthHalf, eachHeight, 'S');

      const n = (n1 + " " + n2.replace(/,/g, ' ')).trim();  // 整理文字
      if (n.length < 11) {
        n1 = n;
      } else {
        const cut_pos = n.indexOf(" ", Math.ceil(n.length / 2) - 2);
        n1 = n.substring(0, cut_pos).trim();
        n2 = n.substring(cut_pos).trim();
      }

      font_size_cht = 15
      doc.setFontSize(font_size_cht);
      n1_x_shift = (a4pageWidthHalf - n1.length * font_size_cht) / 2;
      if (!!n2) {
        n2_x_shift = (a4pageWidthHalf - n2.length * font_size_cht) / 2;
        doc.text(n1, x + n1_x_shift, y + offset + yOffset);
        if (n2.length > 16) {
          const size = Math.ceil((a4pageWidthHalf - 4) / (n2.length + 2));
          n2_x_shift = (a4pageWidthHalf - n2.length * size - 4) / 2;
        }
        doc.text(n2, x + n2_x_shift, y + offset + yOffset + 16);
      } else {
        doc.text(n1, x + n1_x_shift, y + offset + yOffset + 9);
      }

      doc.setFont('Times', 'Roman');
      doc.setFontSize(14);
      const num_x_shift = (a4pageWidthHalf - num.length * 7) / 2;
      doc.text(num, x + num_x_shift, y + offset + yOffset + 31);

      if (j % 2 === 1) {
        y += eachHeight;
      }
    }
    j++;
  };
}
export const lightPage2 = (doc, arrObj, printDate, lightType, companyObj) => {
  const printTitleTable = (doc, printDate, entry, nowPage, rowPage, colPage, companyObj) => {
    const title_y = 34;
    const title_right_text_shift = 610;
    let ctx = doc.context2d;

    doc.setFont('BiauKai').setFontSize(14).text("財團法人", 60, title_y);
    doc.setFontSize(20).text((companyObj.companyTitle || companyObj.name || '') + "  " + entry.year + " 年度 " + getLightType(lightType) + " 簡易疏文", 140, title_y);
    doc.setFontSize(14);
    dateTW(doc, title_right_text_shift + 20, title_y + 4, printDate);

    let left_shift = table_x;
    let top_shift = table_y;
    ctx.beginPath(); // 外框
    ctx.moveTo(left_shift + cell_width * colPage, table_y);
    ctx.lineTo(left_shift + cell_width * colPage, table_y + table_height);
    ctx.moveTo(table_x, top_shift + cell_height * rowPage);
    ctx.lineTo(table_x + table_width, top_shift + cell_height * rowPage);
    ctx.stroke();
    ctx.closePath();

    for (let i = 0; i < colPage; i++) {// 畫直的線
      ctx.beginPath();
      ctx.moveTo(left_shift, table_y);
      ctx.lineTo(left_shift, table_y + table_height);
      ctx.stroke();
      ctx.closePath();
      left_shift += cell_width;
    }

    for (let i = 0; i < rowPage; i++) {   // 畫橫的線
      ctx.beginPath();
      ctx.moveTo(table_x, top_shift);
      ctx.lineTo(table_x + table_width, top_shift);
      ctx.stroke();
      ctx.closePath();
      top_shift += cell_height;
    }
  }
  const printPage = (entry, rowPage, colPage) => {
    const arrTmp = arrObj.filter(item => item.year === entry.year && item.light_text === entry.light_text)
    const totalPage = Math.ceil(arrTmp.length / (rowPage * colPage));
    const footer_width_shift = table_x + 350;
    const footer_height_shift = table_y + table_height + 15;
    doc.setFontSize(12).setFont('BiauKai').text("第 " + nowPage + " 頁，共 " + totalPage + " 頁", footer_width_shift, footer_height_shift);
  }
  const newPage = (doc, printDate, entry, nowPage, rowPage, colPage, companyObj) => {
    doc.addPage();
    printTitleTable(doc, printDate, entry, nowPage, rowPage, colPage, companyObj);
    printPage(entry, rowPage, colPage);
  }

  let x = 0, y = 0;
  let nowPage = 1;
  let rowPage = 4;
  let colPage = 7;

  // 整個頁面的loop
  const table_x = 15, table_y = 50;
  const table_width = 810, table_height = 500;

  if (lightType === "1" || lightType === "3") { // 光明燈 // 藥師燈
    rowPage = 10;
    colPage = 8;
  } else if (lightType === "5" || lightType === "7") { // 光明大燈   // 藥師大燈  
    rowPage = 4;
    colPage = 7;
  }
  let cell_height = table_height / rowPage;
  let cell_width = table_width / colPage;

  printTitleTable(doc, printDate, arrObj[0], nowPage, rowPage, colPage, companyObj);
  printPage(arrObj[0], rowPage, colPage);

  for (let i = 0; i < arrObj.length; i++) {
    const entry = arrObj[i];
    if (!entry.peopleName) {
      continue;
    }

    const arr_n2 = ((entry[`p${lightType}Name2`] && entry[`p${lightType}Name2`].trim()) || '').split(",") || []; // n2 太長要斷行
    for (let j = 0; j < arr_n2.length; j++) {
      if (arr_n2[j].length > 6) {
        arr_n2[j] = arr_n2[j].slice(0, 6) + '\n' + arr_n2[j].slice(6, arr_n2[j].length);
      }
    }

    cell_height = table_height / rowPage;
    cell_width = table_width / colPage;
    const xx = table_x + x * cell_width + 2;
    const yy = table_y + y * cell_height + 12 + 3;
    let n1 = entry.peopleName ? entry.peopleName.trim() : '';
    if (n1.length > 6) {
      n1 = n1.slice(0, 6) + '\n' + n1.slice(6, n1.length)
    }

    let n2 = arr_n2.toString();
    doc.setFont('BiauKai').setFontSize(16).text(n1, xx, yy);
    if (!!n2) { // "姓名"
      n2 = n2.replace(/,/g, "\n");
      doc.text(n2, xx, yy + 17);
    }

    if (x === colPage - 1) { // 如果到每列的最後一個
      x = 0;
      y++; // 下一列
    } else {
      x++; // 往前進一個
    }

    if (y === rowPage) {
      nowPage++;
      newPage(doc, printDate, entry, nowPage, rowPage, colPage, companyObj);
      x = 0;
      y = 0;
    }
  }
}
export const lightPage3 = (doc, arrObj, printDate, lightType, companyObj) => {
  const printTitleTable = (doc, printDate, entry, nowPage, totalPage, companyObj) => {
    const title_y = 37;
    const title_right_text_shift = 610;
    let ctx = doc.context2d;
    doc.setFont('BiauKai').setFontSize(14).text("財團法人", 40, title_y);
    console.log(entry.year)
    doc.setFontSize(20).text((companyObj.name || companyObj.companyTitle || '') + "  " + entry.year + " 年度 " + getLightType(lightType) + " 詳細疏文", 120, title_y);
    doc.setFontSize(14);
    dateTW(doc, title_right_text_shift, title_y + 4, printDate);
    const table_x = 15;
    const table_y = 50;
    const cell_height = 29;

    let left_shift = table_x;
    let top_shift = table_y;

    ctx.beginPath();
    for (let i = 0; i < 18; i++) { // 每一列的橫線
      ctx.moveTo(table_x, top_shift + cell_height * i);
      ctx.lineTo(table_x + table_width, top_shift + cell_height * i);
    }
    ctx.moveTo(left_shift + table_width, table_y); // 表格右邊的直線
    ctx.lineTo(left_shift + table_width, table_y + table_height);
    ctx.stroke();
    ctx.closePath();

    for (let i = 0; i < col_width.length; i++) { // 畫直的線 & 標題
      ctx.beginPath();
      ctx.moveTo(left_shift, table_y);
      ctx.lineTo(left_shift, table_y + table_height);
      ctx.stroke();
      ctx.closePath();
      doc.setFontSize(16).setFillColor("#000");
      doc.text(col_title[i], left_shift + 2, table_y + 19, { maxWidth: col_width[i] });
      left_shift += col_width[i];
    }

    // footer
    const footer_width_shift = table_x + 350;
    const footer_height_shift = table_y + table_height + 15;

    doc.setFontSize(12).setFont('BiauKai').text("第 " + nowPage + " 頁，共 " + totalPage + " 頁", footer_width_shift, footer_height_shift);
  }
  const newPage = (doc, printDate, entry, nowPage, totalPage, companyObj) => {
    doc.addPage();
    printTitleTable(doc, printDate, entry, nowPage, totalPage, companyObj);
  }
  const printRow = (doc, num, name, birthday, addr) => {
    doc.setFont('Times', 'Roman').setFontSize(16).text(num, left_shift + 5, top_shift + col_data_y_shift[0]); // "編號"
    left_shift += col_width[0];

    doc.setFont('BiauKai').setFontSize(16);
    doc.text(name || '', left_shift + 5, top_shift + col_data_y_shift[1], { maxWidth: col_width[1] - 5 }); // "姓名"
    left_shift += col_width[1];

    doc.text(birthday, left_shift + 3, top_shift + col_data_y_shift[2], { maxWidth: col_width[2] }); // "出生年月日"
    left_shift += col_width[2];

    doc.text(addr || '', left_shift + 3, top_shift + col_data_y_shift[3]); // "住址"
  }

  // 整個頁面的loop
  let nowMember = 0;
  let nowPage = 1;

  const table_x = 15;
  const table_y = 50;
  const table_width = 810;
  const table_height = 493; // (rowPage+1)*cell_height;
  const col_width = [70, 200, 165, 385];
  const col_title = ["燈號", "姓名", "生辰", "地址"];
  const col_data_y_shift = [19, 19, 19, 19];

  const reducer = (acc, item) => acc + (item[`p${lightType}Name2`] && item[`p${lightType}Name2`] !== '闔家' && item[`p${lightType}Name2`].length ? item[`p${lightType}Name2`].split(",").length : 0);
  const totalPage = Math.ceil(arrObj.reduce(reducer, arrObj.length) / 16);
  printTitleTable(doc, printDate, arrObj[0], nowPage, totalPage, companyObj);
  if (arrObj.length === 0) { return };

  const cell_height = 29;
  let left_shift = table_x;
  let top_shift = table_y + cell_height;

  while (nowMember < arrObj.length) {
    const entry = arrObj[nowMember];
    if (!entry) {
      continue;
    }

    let n2 = "";
    let num = "";
    if (entry[`p${lightType}`] === '-1') {
      n2 = (entry[`p${lightType}Name2`] && entry[`p${lightType}Name2`].trim()) || '';
      num = entry[`p${lightType}Num`];
    }

    let arr_names = [];
    let name2Arr = []
    if (n2 !== "" && n2 !== "闔家") {
      entry.names = entry.peopleName + "," + n2;
      arr_names = entry.names.split(",");
      name2Arr = entry[`p${lightType}name2Arr`] || [];
    } else {
      arr_names.push(entry.names);
    }
    left_shift = table_x;
    for (let j = 0; j < arr_names.length; j++) { // 印出一列的每一個名字
      const name = arr_names[j];

      if (j === 0 && n2 === "闔家") { // 一列的話
        const { bornYear = '吉', bornMonth = '吉', bornDay = '吉', bornTimeText = '吉' } = entry;
        printRow(doc, num, entry.peopleName + " 闔家", `民${bornYear}年${bornMonth}月${bornDay}日${bornTimeText}時`, entry.addr);
      }
      else if (j === 0 && n2 !== "闔家") { // 一列的話
        // console.log(entry)
        const { bornYear = '吉', bornMonth = '吉', bornDay = '吉', bornTimeText = '吉' } = entry;
        printRow(doc, num, entry.peopleName, `民${bornYear}年${bornMonth}月${bornDay}日${bornTimeText}時`, entry.addr);
      } else { // 第二個人以上
        const p = (name2Arr && name2Arr.length && name2Arr.find(item => item.peopleName === name)) || 0;
        // console.log(p)
        let birth = "民吉年吉月吉日吉時";
        if (p) {
          birth = "民" + ((p.bornYear) ? p.bornYear + "年" : "吉年") + ((p.bornMonth) ? p.bornMonth + "月" : "吉月") + ((p.bornDay) ? p.bornDay + "日" : "吉日") + ((p.bornTimeText) ? p.bornTimeText + "時" : "吉時");
        }
        // printRow(doc, "", name, birth, (p && p.addr) || '');
        printRow(doc, "", name, birth, '');
      }

      top_shift += cell_height;
      left_shift = table_x;

      if (top_shift > table_height + 29) {
        nowPage++;
        newPage(doc, printDate, entry, nowPage, totalPage, companyObj);
        top_shift = table_y + 29;//cell_height;
      }
    }
    nowMember++;

    if (top_shift > 543) {
      top_shift = table_y;
      nowPage++;
      newPage(doc, printDate, entry, nowPage, totalPage, companyObj);
    }
  }
}
export const memberPage1 = (doc, arrObj, printDate, companyObj) => { // 簽到名冊
  let nowMember = 0;
  let nowPage = 1;
  let totalPage = Math.ceil(arrObj.length / 26);

  // console.log(printDate);
  // 整個頁面的loop
  const table_x = 55;
  const table_y = 55;
  const table_width = 790;
  const table_height = 488;
  const cell_height = 35;
  const col_width = [35, 35, 75, 37, 43, 90, 70];
  const col_title = ["市府\n編號", "班別", "姓名", "性別", "蓮社\n編號", "簽到", "備註"];
  const col_title_x_shift = [2, -3, 0, -3, 0, 0, 0];
  const col_title_y_shift = [13, 20, 20, 20, 13, 20, 20];
  const col_title_setFontSize = [12, 16, 16, 16, 12, 16, 16];
  const col_data_y_shift = [9, 8, 8, 8, 9, 10, 10];
  const footer_width_shift = table_x + 295;
  let ctx = doc.context2d;

  while (nowMember < arrObj.length) {
    doc.setFont('BiauKai').setFontSize(14).text("財團法人", 105, 38);
    doc.setFontSize(20).text((companyObj.name || '') + "社員大會出席簽到名冊", 243, 35);

    doc.setFontSize(14);
    dateTW(doc, 617, 40, printDate);
    let left_shift = table_x;
    let top_shift = table_y + 13;

    for (let h = 0; h < 2; h++) {
      for (let i = 0; i < col_width.length; i++) {
        // doc.line(left_shift, table_y,left_shift, table_y + table_height);
        // doc.rect(left_shift, table_y, left_shift, table_y + table_height);
        ctx.beginPath();
        ctx.moveTo(left_shift + col_title_x_shift[i] - 2, table_y);
        ctx.lineTo(left_shift + col_title_x_shift[i] - 2, table_y + table_height);
        ctx.stroke();
        ctx.closePath();
        doc.setFontSize(col_title_setFontSize[i]).setFillColor("#000");
        doc.text(col_title[i], left_shift + col_title_x_shift[i], table_y + col_title_y_shift[i], { maxWidth: col_width[i] });
        left_shift += col_width[i];
      }
      ctx.beginPath();
      ctx.moveTo(table_x, table_y);
      ctx.lineTo(table_width, table_y);
      ctx.moveTo(table_x, table_y + cell_height);
      ctx.lineTo(table_width, table_y + cell_height);
      ctx.stroke();
      ctx.closePath();
    }
    ctx.beginPath();
    ctx.moveTo(table_width, table_y);
    ctx.lineTo(table_width, table_y + table_height);
    ctx.stroke();
    ctx.closePath();

    const sumColWidth = _.reduce(col_width, function (sum, el) { return sum + el }, 0);

    for (let i = 0; i < 13; i++) {
      top_shift += cell_height;

      // doc.line(table_x, top_shift,table_x + table_width, top_shift);
      // doc.rect(table_x, top_shift, table_x + table_width, top_shift);
      for (let j = 0; j < 2; j++) {
        let tmpMember = {};
        if (j === 0) {
          tmpMember = nowMember;
          left_shift = table_x + 10;
        }
        else if (j === 1) {
          tmpMember = nowMember + 13;
          left_shift = table_x + 7 + sumColWidth;
        }
        ctx.beginPath();
        ctx.moveTo(table_x, top_shift + 20);
        ctx.lineTo(table_width, top_shift + 20);
        ctx.stroke();
        ctx.closePath();
        if (!!arrObj[tmpMember]) {
          doc.setFont('Times', 'Roman').setFontSize(14);
          doc.text(tmpMember + 1 + '', left_shift, top_shift + col_data_y_shift[0], { maxWidth: col_width[0] });
          left_shift += col_width[0];

          doc.setFont('BiauKai').setFontSize(16);
          if (arrObj[tmpMember].templeClass_text)
            doc.text(arrObj[tmpMember].templeClass_text.charAt(0), left_shift - 5, top_shift + col_data_y_shift[1], { maxWidth: col_width[1] });
          left_shift += col_width[1];

          // console.log(arrObj[tmpMember]);
          // let name = arrObj[tmpMember].name.trim();
          let name = arrObj[tmpMember].name ? arrObj[tmpMember].name.trim() : '';
          if (name.indexOf("(") !== -1) {
            name = name.substr(0, name.indexOf("(")).trim();
          }
          doc.text(name, left_shift - 7, top_shift + col_data_y_shift[2], { maxWidth: col_width[2] });
          left_shift += col_width[2] - 3;

          if (!!arrObj[tmpMember].sexualText)
            doc.text(arrObj[tmpMember].sexualText, left_shift - 1, top_shift + col_data_y_shift[3], { maxWidth: col_width[3] });
          left_shift += col_width[3];

          doc.setFont('Times', 'Roman').setFontSize(14);
          if (!!arrObj[tmpMember].memberId)
            doc.text(arrObj[tmpMember].memberId + '', left_shift - 4, top_shift + col_data_y_shift[4], { maxWidth: col_width[4] });
        }
      }
      nowMember++;
    }

    doc.setFontSize(14);
    doc.setFont('BiauKai').text("第 " + nowPage + " 頁，共 " + totalPage + " 頁", footer_width_shift, table_y + table_height + 15);

    nowPage++;

    if (nowMember + 13 < arrObj.length) {
      doc.addPage();
      nowMember += 13;
    } else {
      break;
    }
  }
}
export const memberPage2 = (doc, data, printDate) => { // 封面
  // console.log(logoBase64)
  doc.addImage(logoBase64, 'PNG', 100, 130, 70, 70);
  doc.setFont('BiauKai').setFontSize(20);
  doc.text(data.strTitle1a + "\n" + data.strTitle1b, 183, 158);
  doc.setFontSize(32);
  doc.text(data.strTitle2, 240, 171);//, { maxWidth: 40, characterSpacing: 5 });
  doc.text("社員(信徒)名冊", 180, 330);
  doc.setFont('BiauKai');

  const date_x = 170;
  const date_y = 715;
  doc.setFontSize(24);
  dateTW(doc, 130, 670, printDate);
  doc.text(`${data.strPhone1} ${data.strPhone2}`, date_x, date_y);
}
export const memberPage3 = (doc, arrObj, printDate, companyObj, inputVar) => { // 信徒名冊
  let nowMember = 0;
  let nowPage = 1;
  const totalPage = Math.ceil(arrObj.length / 23);
  console.log(companyObj)
  // 整個頁面的loop
  const table_x = 25;
  const table_y = 65;
  const table_width = 540;
  const table_height = 720;
  const cell_height = 30;
  const col_width = [35, 27, 58, 25, 60, 165, 36, 64, 70];
  const col_title = ["市府\n編號", "班別", "姓名", "性別", "出生年月日", "住址", "蓮社\n編號", "電話", "身分證字號"];
  const col_title_x_shift = [5, 0, 5, 0, 0, 5, 0, 5, 5];
  const col_title_y_shift = [12, 17, 17, 17, 17, 17, 12, 17, 17];
  const col_title_setFontSize = [10, 10, 10, 10, 10, 10, 10, 10, 10];
  const col_data_y_shift = [8, 8, 8, 8, 8, 8, 11, 11, 11];
  const footer_width_shift = table_x + 200;
  const footer_height_shift = table_y + table_height + 25;

  const title_right_text_shift = 410;
  let ctx = doc.context2d;

  while (nowMember < arrObj.length) {
    doc.setFont('BiauKai').setFontSize(14).text("財團法人", 45, 35);
    // doc.setFontSize(18).text("桃園佛教蓮社社員(信徒)名冊", 120, 25);

    doc.setFont('BiauKai').setFontSize(18);
    doc.setFontSize(18).text((companyObj.name || companyObj.companyTitle || '') + "社員", 150, 35);

    doc.setFont('Times', 'Roman').setFontSize(18);
    doc.setFontSize(18).text("(         )", 295, 35);

    doc.setFont('BiauKai');
    doc.setFontSize(18).text("信徒", 303, 35);
    doc.setFontSize(18).text("名冊", 350, 35);

    doc.setFontSize(10).text("府民字第        冊", title_right_text_shift + 10, 20);
    // console.log(inputVar);
    // doc.setFontSize(10).text("造冊人：" + inputVar.member_manager, title_right_text_shift, 30);
    doc.setFontSize(10);
    dateTW(doc, title_right_text_shift + 10, 50, printDate, 10);

    doc.rect(table_x, table_y, table_width, cell_height);
    doc.rect(table_x, table_y, table_width, table_height);

    let left_shift = table_x;
    let top_shift = table_y + 10;

    for (let i = 0; i < col_width.length; i++) {
      // doc.line(left_shift, table_y,left_shift, table_y + table_height);
      ctx.beginPath();
      ctx.moveTo(left_shift, table_y);
      ctx.lineTo(left_shift, table_y + table_height);
      ctx.stroke();
      ctx.closePath();
      doc.setFontSize(col_title_setFontSize[i]).setFillColor("#000");
      doc.text(col_title[i], left_shift + col_title_x_shift[i], table_y + col_title_y_shift[i], { maxWidth: col_width[i] });
      left_shift += col_width[i];
    }

    for (let i = 0; i < 23; i++) {
      top_shift += cell_height;
      left_shift = table_x;

      ctx.beginPath();
      ctx.moveTo(table_x, top_shift + 20);
      ctx.lineTo(table_width + 25, top_shift + 20);
      ctx.stroke();
      ctx.closePath();

      if (!!arrObj[nowMember]) {
        //"縣府\n編號"
        doc.setFont('Times', 'Roman').setFontSize(14);
        doc.text(nowMember + 1 + '', nowMember > 8 ? left_shift + 8 : left_shift + 11, top_shift + col_data_y_shift[0], { maxWidth: col_width[0] });
        left_shift += col_width[0];

        // "班別"
        doc.setFont('BiauKai').setFontSize(12);
        if (arrObj[nowMember].templeClass_text)
          doc.text(arrObj[nowMember].templeClass_text.charAt(0), left_shift + 4, top_shift + col_data_y_shift[1], { maxWidth: col_width[1] });
        left_shift += col_width[1];

        // "姓名"
        doc.setFont('BiauKai').setFontSize(12);
        let name = arrObj[nowMember].name.trim();
        if (name.indexOf("(") !== -1) {
          name = name.substr(0, name.indexOf("(")).trim();
        }
        doc.text(name, left_shift + 2, top_shift + col_data_y_shift[2], { maxWidth: col_width[2] });
        left_shift += col_width[2];

        // "性別"
        doc.setFont('BiauKai').setFontSize(12);
        if (!!arrObj[nowMember].sexualText)
          doc.text(arrObj[nowMember].sexualText, left_shift + 4, top_shift + col_data_y_shift[3], { maxWidth: col_width[3] });
        left_shift += col_width[3];

        // "出生年月日"
        let tmp = "";
        /*  if(!!arrObj[nowMember].bornYear && !!arrObj[nowMember].bornMonth && !!arrObj[nowMember].bornDay){
            doc.text("民", left_shift+3, top_shift+col_data_y_shift[4], { lineBreak: false, continued: "yes", maxWidth: col_width[4]});
            tmp = arrObj[nowMember].bornYear+"."+arrObj[nowMember].bornMonth+"."+arrObj[nowMember].bornDay;
          }*/
        if (!!arrObj[nowMember].birthday) {
          doc.text("民", left_shift + 2, top_shift + col_data_y_shift[4], { lineBreak: false, continued: "yes", maxWidth: col_width[4] });
          tmp = arrObj[nowMember].birthday;
          // tmp = typeof tmp === 'string' ? tmp : ''
        }
        // doc.setFont('Times', 'Roman').setFontSize(12).text(tmp, doc.x, doc.y + 2);
        doc.setFont('Times', 'Roman').setFontSize(12).text(tmp, left_shift + 17, top_shift + col_data_y_shift[4], { maxWidth: col_width[4] });
        left_shift += col_width[4];

        // 地址
        /*doc.setFont('BiauKai').setFontSize(12);
        const addr = arrObj[nowMember].addr, topshift = top_shift+col_data_y_shift[5];
        if(addr.length > 13){
          addr = addr.splice(13, 0, "\n");  
          topshift -= 5;
        }*/
        const mix_str = (doc, str, left_shift, topshift, colwidth) => {
          let shift = 0;
          let break_char = 0;
          if (str) {
            for (let x = 0; x < str.length; x++) {
              const c = str.charAt(x);
              if (isNaN(c)) { // 字串
                shift += 12;
              } else { // 數字
                shift += 8;
              }
              if (shift >= colwidth) {
                break_char = x;
                topshift -= 5.8;
                break;
              }
            }
          }

          shift = 0;
          if (str) {
            for (let x = 0; x < str.length; x++) {
              const c = str.charAt(x);

              if (x !== 0 && x === break_char) {
                topshift += 12.6;
                shift = 0;
                if (!isNaN(c)) shift = 1;
              }

              if (isNaN(c)) { // 字串
                doc.setFont('BiauKai').setFontSize(12);
                doc.text(c, left_shift + 3 + shift, topshift + 1, { lineBreak: true, maxWidth: 30 });
                shift += 12;
              } else { // 數字
                doc.setFont('Times', 'Roman').setFontSize(12);
                doc.text(c, left_shift + 3 + shift, topshift + 3.1, { lineBreak: true, maxWidth: 30 });
                shift += 6;
              }
            }
          }

        }
        const addr = arrObj[nowMember].addr, topshift = top_shift + col_data_y_shift[5];
        // doc.text(addr, left_shift+3, topshift, { maxWidth: col_width[5]});
        mix_str(doc, addr, left_shift, topshift, col_width[5] - 2);
        // mix_str(doc, addr, left_shift, topshift, 150);
        left_shift += col_width[5];

        // "蓮社\n編號"
        doc.setFont('Times', 'Roman').setFontSize(12);
        if (!!arrObj[nowMember].memberId)
          doc.text(arrObj[nowMember].memberId + '', left_shift + 2, top_shift + col_data_y_shift[6], { maxWidth: col_width[6] });
        left_shift += col_width[6];

        // "電話"
        doc.setFont('Times', 'Roman').setFontSize(12);
        doc.text(arrObj[nowMember].telephone ? arrObj[nowMember].telephone : '', left_shift + 4, top_shift + col_data_y_shift[7], { maxWidth: col_width[7] });
        left_shift += col_width[7];

        // "身分證字號"
        doc.setFont('Times', 'Roman').setFontSize(12);
        if (!!arrObj[nowMember].identity)
          doc.text(arrObj[nowMember].identity, left_shift + 3, top_shift + col_data_y_shift[8], { maxWidth: col_width[8] });
        // left_shift += col_width[8];
      }
      nowMember++;
    }
    doc.setFontSize(12);
    doc.setFont('BiauKai').text("第 " + nowPage + " 頁，共 " + totalPage + " 頁", footer_width_shift, footer_height_shift);

    nowPage++;

    if (nowMember < arrObj.length) {
      doc.addPage();
      // nowMember+=23;
    } else {
      break;
    }
  }
}
export const memberPage4 = (doc, arrObj, row_num, isBound, isPaddingMid) => {  // 地址
  const mix_str = (doc, str, left_shift, top_shift) => {
    let shift = 0;
    const f_size = 13;

    let str2 = "";
    if (str.length > 18) {
      str2 = str.substr(18);
      str = str.substr(0, 18);
      // console.log(str2);
    }

    for (let x = 0; x < str.length; x++) {
      const c = str.charAt(x);
      if (isNaN(c)) { // 字串
        doc.setFont('BiauKai').setFontSize(f_size);
        doc.text(c, left_shift + 30 + shift, top_shift + 13, { lineBreak: true, maxWidth: 30 });
        shift += f_size;
      } else { // 數字
        doc.setFont('Times', 'Roman').setFontSize(f_size);
        doc.text(c, left_shift + 30 + shift, top_shift + 13, { lineBreak: true, maxWidth: 30 });
        shift += (f_size / 2);
      }
    }
    if (!!str2) {
      shift = 0;
      for (let x = 0; x < str2.length; x++) {
        const c = str2.charAt(x);
        if (isNaN(c)) { // 字串
          doc.setFont('BiauKai').setFontSize(f_size);
          doc.text(c, left_shift + 30 + shift, top_shift + 13 + f_size, { lineBreak: true, maxWidth: 30 });
          shift += f_size;
        } else { // 數字
          doc.setFont('Times', 'Roman').setFontSize(f_size);
          doc.text(c, left_shift + 30 + shift, top_shift + 13 + f_size, { lineBreak: true, maxWidth: 30 });
          shift += (f_size / 2);
        }
      }
    }
  }
  let ctx = doc.context2d
  let nowMember = 0;

  // 整個頁面的loop
  const table_x = 25;
  /*  const table_y = 5;
    if(isBound){
      table_y = 40;
    }
  */
  // console.log(row_num);
  let table_y = 0; // row = 10的
  if (row_num === 7) {
    table_y = 48;
  }

  const cell_height = (a4pageHeight - (table_y * 2)) / row_num; //35;
  // console.log(a4pageWidth, a4pageHeight, a4pageWidth / 2, cell_height);
  // ctx.beginPath();
  // ctx.moveTo(a4pageWidth / 2, table_y - 10);
  // ctx.lineTo(a4pageWidth / 2, 813.89);
  // ctx.moveTo(0, table_y - 10);
  // ctx.lineTo(a4pageWidth, table_y - 10);
  // ctx.stroke();
  // ctx.closePath();
  while (nowMember < arrObj.length) {
    let left_shift = table_x;
    let top_shift = table_y + 12;
    const sumColWidth = a4pageWidthHalf - table_x; //_.reduce(col_width, function(sum, el) {return sum + el }, 0);
    // let line_x1 = 0
    // let line_x2 = a4pageWidth / 2

    for (let i = 0; i < row_num; i++) {
      for (let j = 0; j < 2; j++) { // 每一行
        let tmpMember = {};
        if (j === 0) { // 靠左邊的
          tmpMember = nowMember;
          left_shift = table_x;
          // line_x2 = a4pageWidth / 2
          // line_x1 = 0
        } else if (j === 1) { // 靠右邊的
          tmpMember = nowMember + row_num;
          left_shift = sumColWidth + 40;
          // line_x2 = a4pageWidth
          // line_x1 = a4pageWidth / 2
        }
        if (!!arrObj[tmpMember] && arrObj[tmpMember].name && arrObj[tmpMember].addr) {
          doc.setFont('Times', 'Roman').setFontSize(13);
          // 郵遞區號

          doc.text(!!arrObj[tmpMember].post5code ? arrObj[tmpMember].post5code : arrObj[tmpMember].postcode || '', left_shift - 6, top_shift + 13, { maxWidth: 40 });

          const addr = arrObj[tmpMember].addr.trim();
          mix_str(doc, addr, left_shift + 0, top_shift);

          // 姓名
          let setFontSize = 16;
          if (row_num === 7) {
            setFontSize = 16;
          }
          doc.setFontSize(setFontSize);

          const str = arrObj[tmpMember].name.trim();
          const x = left_shift + 25 + (str.length + 1) * setFontSize;
          //console.log(str + " " + str.length);
          doc.setFont('BiauKai').text(str, left_shift + 25, top_shift + 45);
          // doc.setFont('Times', 'Roman').text(" ", doc.x, doc.y);
          // doc.setFont('BiauKai').text("大德", x, doc.y + 1);
          doc.setFont('Times', 'Roman').text(" ", table_x, top_shift + 45);
          doc.setFont('BiauKai').text("大德", x, top_shift + 45);

          // 編號
          doc.setFont('Times', 'Roman').setFontSize(12);
          let num = arrObj[tmpMember].memberId;

          if (arrObj[tmpMember].templeLevel === "3eN5CH9g8N") {
            num = "1" + funcPad(num, 6);
          } else if (arrObj[tmpMember].templeLevel === "8nmf3bZZeC") {
            num = "2" + funcPad(num, 6);
          } else {
            num = "";
          }

          // doc.text(num, left_shift + 160, doc.y + 18);
          doc.text(num, left_shift + 160, top_shift + 45);
          // ctx.beginPath();
          // ctx.moveTo(line_x1, top_shift + cell_height - 20);
          // ctx.lineTo(line_x2, top_shift + cell_height - 20);
          // ctx.stroke();
          // ctx.closePath();
        }
      }
      top_shift += cell_height;
      nowMember++;
    }
    if (nowMember + row_num < arrObj.length) {
      doc.addPage();
      // ctx.beginPath();
      // ctx.moveTo(a4pageWidth / 2, table_y - 10);
      // ctx.lineTo(a4pageWidth / 2, top_shift - 20);
      // ctx.moveTo(0, table_y - 10);
      // ctx.lineTo(a4pageWidth, table_y - 10);
      // ctx.stroke();
      // ctx.closePath();
      nowMember += row_num;
    } else {
      break;
    }
  }
}
export const memberPage5 = (doc, arrObj) => {
  const left_shift = 100;
  const top_shift = 10;
  for (let i in arrObj) {
    if (!!arrObj[i] && arrObj[i].name && arrObj[i].addr) {
      // 郵遞區號
      doc.setFont('Times', 'Roman').setFontSize(26);
      doc.text(arrObj[i].post5code.substr(0, 1), left_shift + 90, top_shift + 40, { maxWidth: 40 });
      doc.text(arrObj[i].post5code.substr(1, 1), left_shift + 110, top_shift + 40, { maxWidth: 40 });
      doc.text(arrObj[i].post5code.substr(2, 1), left_shift + 130, top_shift + 40, { maxWidth: 40 });

      doc.text(arrObj[i].post5code.substr(3, 1), left_shift + 155, top_shift + 40, { maxWidth: 40 });
      doc.text(arrObj[i].post5code.substr(4, 1), left_shift + 175, top_shift + 40, { maxWidth: 40 });

      // 地址
      doc.setFont('BiauKai').setFontSize(16);
      const addr = arrObj[i].addr.trim();
      doc.text(toStraight(addr), left_shift + 140, top_shift + 100, { lineBreak: true, maxWidth: 60 });

      // 姓名
      doc.setFont('BiauKai').setFontSize(36);
      const name = arrObj[i].name.trim() + " 大德";
      doc.text(toStraight(name), left_shift + 25, top_shift + 150, { lineBreak: true, maxWidth: 60 });

      // 編號
      doc.setFont('Times', 'Roman').setFontSize(12);
      let num = arrObj[i].memberId;
      if (arrObj[i].templeLevel_text === "社員") {
        num = "1" + funcPad(num, 6);
      } else if (arrObj[i].templeLevel_text === "護法") {
        num = "2" + funcPad(num, 6);
      } else {
        num = "";
      }
      doc.rotate(-90, { origin: [100, 400] }).text(num, left_shift + 70, top_shift + 480);
    }
    if (i < arrObj.length - 1) {
      doc.addPage();
    }
  }
}

export const cloudwin501 = (doc, arrObj) => {
  // const COLOR = "#B22222";
  // doc.setTextColor(COLOR);
  // doc.setDrawColor(COLOR);
  doc.setLineWidth(1);

  doc.rect(25, 95, 550, 350)

  doc.setFont('msjh');
  doc.setFontSize(15);
  doc.text("財團法人桃園佛教蓮社捐款收據", 25, 61);
  doc.setFontSize(9);
  doc.text("桃園市桃園區朝陽街12號", 25, 76);
  doc.text("TEL:(03)3211234", 25, 87);
  doc.text("/", 96, 87);
  doc.text("FAX:12343412", 101, 87);

  doc.setFontSize(18);
  doc.text("銷貨單", 270, 88);
  doc.line(25, 155, 575, 155);  // 聯絡電話下水平直線

  doc.setFontSize(13);
  doc.text("單據日期：", 30, 113);
  doc.text("2020/03/21", 95, 113);
  doc.text("客戶編號：", 30, 130);
  doc.text("B001", 95, 130);
  doc.text("聯絡電話：", 30, 147);
  doc.text("(03)3211234", 95, 147);

  doc.line(185, 95, 185, 155);  // 單據號碼左邊直線

  doc.text("單據號碼：", 190, 113);
  doc.text("發票種類：", 335, 113);
  doc.text("免開", 400, 113);
  doc.text("發票號碼：", 435, 113);
  doc.text("客戶名稱：", 190, 130);
  doc.text("B001", 255, 130);
  doc.text("聯絡地址：", 190, 147);
  doc.text("(12345)桃園市中壢區實踐路123號12樓", 255, 147);
  doc.setFontSize(12);
  doc.text("0200317002", 255, 113);
  doc.text("AJ-78720067", 498, 113);  // 發票號碼

  doc.line(25, 175, 575, 175);

  doc.line(155, 155, 155, 375); //中間4條直線
  doc.line(350, 155, 350, 375);
  doc.line(425, 155, 425, 375);
  doc.line(500, 155, 500, 445);

  doc.text("產品編號", 65, 169);
  doc.text("產品名稱", 230, 169);
  doc.text("數量", 375, 169);
  doc.text("單價", 450, 169);
  doc.text("小計", 525, 169);

  doc.line(25, 375, 575, 375);
  doc.line(465, 375, 465, 445);

  doc.line(25, 398, 575, 398);
  doc.line(465, 422, 575, 422);

  doc.setFontSize(13);
  doc.text("加扣款：", 30, 392);
  doc.text("單價：", 30, 418);
  doc.text("負責業務：", 30, 437);
  doc.text("客戶簽收：", 180, 437);
  doc.text("出貨人員：", 320, 437);

  doc.setFontSize(12);
  doc.text("合計", 470, 392);
  doc.text("稅額", 470, 415);
  doc.text("總計", 470, 438);

  doc.text("A001-2", 30, 188);
  doc.text("皮鞋", 160, 188);
  doc.text("1", 355, 188);
  doc.text("500", 430, 188);
  doc.text("500", 505, 188);

  doc.text("0", 85, 392);
  doc.text("500", 503, 392);
  doc.text("0", 503, 415);
  doc.text("500", 503, 438);

}

export const cloudwin502 = (doc, arrObj) => {
  // const COLOR = "#B22222";
  // doc.setTextColor(COLOR);
  // doc.setDrawColor(COLOR);
  doc.setFont('msjh');
  doc.setLineWidth(1);

  doc.setFontSize(24);
  doc.text("進銷貨明細表", 230, 50);
  doc.line(25, 105, 575, 105);  // 聯絡電話下水平直線

  doc.setFontSize(12);
  doc.text("出貨倉庫：", 25, 95);
  doc.text("台北", 87, 95);

  doc.text("日期範圍：", 375, 95);
  doc.text("2020/03/13", 435, 95);
  doc.text("~", 500, 95);
  doc.text("2020/03/15", 510, 95);
  doc.text("對象編號：", 25, 119);
  doc.text("000", 87, 119);
  doc.text("對象名稱：", 150, 119);
  doc.text("門市客", 210, 119);

  doc.line(25, 125, 575, 125);  // 聯絡電話下水平直線

  doc.text("類別", 25, 139);
  doc.text("日期", 95, 139);
  doc.text("單號", 165, 139);
  doc.text("稅別", 245, 139);
  doc.text("發票", 295, 139);
  doc.text("費用", 395, 139);
  doc.text("小計", 445, 139);
  doc.text("稅金", 495, 139);
  doc.text("合計", 545, 139);

  doc.line(25, 145, 575, 145);  // 聯絡電話下水平直線

  doc.text("銷貨", 25, 159);
  doc.text("2020/03/17", 95, 159);
  doc.text("O200317001", 165, 159);
  doc.text("免開", 245, 159);
  doc.text("", 295, 159);
  doc.text("0", 395, 159);
  doc.text("2455", 445, 159);
  doc.text("0", 495, 159);
  doc.text("2455", 545, 159);

  doc.line(25, 165, 575, 165);  // 聯絡電話下水平直線

  doc.setFontSize(14);

  doc.text("小計", 495, 183);
  doc.text("2455", 540, 183);

  doc.line(25, 190, 575, 190);  // 聯絡電話下水平直線

  doc.text("總計", 495, 210);
  doc.text("2455", 540, 210);
}

export const cloudwin503 = (doc, arrObj) => {
  // const COLOR = "#B22222";
  // doc.setTextColor(COLOR);
  // doc.setDrawColor(COLOR);
  doc.setLineWidth(1);

  doc.rect(25, 95, 550, 350)

  doc.setFont('msjh');
  doc.setFontSize(15);
  doc.text("財團法人桃園佛教蓮社捐款收據", 25, 61);
  doc.setFontSize(9);
  doc.text("桃園市桃園區朝陽街12號", 25, 76);
  doc.text("TEL:(03)3211234", 440, 61);
  doc.text("/", 510, 61);
  doc.text("FAX:12343412", 516, 61);
  doc.text("網址：", 440, 81);
  doc.text("https://123123123.com", 470, 81);
  doc.setFontSize(18);
  doc.text("會員預收款明細表", 240, 88);
  doc.line(25, 155, 575, 155);  // 聯絡電話下水平直線

  doc.setFontSize(13);
  doc.text("收款日期：", 30, 113);
  doc.text("2020/03/21", 95, 113);
  doc.text("收款單號：", 30, 130);
  doc.text("B001", 95, 130);

  doc.line(185, 95, 185, 135);  // 單據號碼左邊直線

  doc.text("會員編號：", 190, 113);
  doc.text("預收款金額：", 440, 113);
  doc.text("會員名稱：", 190, 130);
  doc.text("黃主任", 255, 130);
  doc.setFontSize(12);
  doc.text("0200317002", 255, 113);
  doc.text("10000", 520, 113);  // 發票號碼

  doc.line(25, 135, 575, 135);

  doc.line(135, 135, 135, 375); //中間3條直線
  doc.line(255, 135, 255, 375);
  doc.line(475, 135, 475, 445);

  doc.text("消費日期", 55, 149);
  doc.text("服務單號", 170, 149);
  doc.text("備註", 350, 149);
  doc.text("扣抵金額", 505, 149);

  doc.line(25, 375, 575, 375);
  doc.line(475, 398, 575, 398);

  doc.setFontSize(13);
  doc.text("備註：", 30, 392);
  doc.text("剩餘金額", 500, 392);
  doc.text("500", 478, 410);
}

export const cloudwin601 = (doc, arrObj) => {
  // const COLOR = "#B22222";
  // doc.setTextColor(COLOR);
  // doc.setDrawColor(COLOR);
  doc.setLineWidth(1);

  doc.rect(25, 55, 550, 730)
  doc.setFont('msjh');
  doc.setFontSize(15);
  doc.text("僧", 25, 30);
  doc.line(45, 19, 55, 19);
  doc.line(45, 29, 55, 29);
  doc.line(55, 19, 55, 29);
  doc.line(45, 19, 45, 29);
  doc.text("東", 80, 30);
  doc.line(105, 19, 115, 19);
  doc.line(105, 29, 115, 29);
  doc.line(115, 19, 115, 29);
  doc.line(105, 19, 105, 29);
  doc.text("東", 250, 30);
  doc.line(275, 19, 285, 19);
  doc.line(275, 29, 285, 29);
  doc.line(285, 19, 285, 29);
  doc.line(275, 19, 275, 29);
  doc.text("第          班之 ", 130, 40);
  doc.text("第            籤    第            壇之", 300, 40);
  doc.text("尼", 25, 50);
  doc.line(45, 39, 55, 39);
  doc.line(45, 49, 55, 49);
  doc.line(55, 39, 55, 49);
  doc.line(45, 39, 45, 49);
  doc.text("西", 80, 50);
  doc.line(105, 39, 115, 39);
  doc.line(105, 49, 115, 49);
  doc.line(115, 39, 115, 49);
  doc.line(105, 39, 105, 49);
  doc.text("西", 250, 50);
  doc.line(275, 39, 285, 39);
  doc.line(275, 49, 285, 49);
  doc.line(285, 39, 285, 49);
  doc.line(275, 39, 275, 49);
  doc.text("(編號：          )", 500, 50);

  doc.setFont('msjh');
  doc.setFontSize(20);
  doc.text("寶乘山桃園佛教蓮社三壇大戒報名表", 130, 80);
  doc.setFontSize(15);
  doc.text("年           月           日填寫", 410, 105);
  doc.line(25, 110, 575, 110);

  doc.text("法 號", 47, 128);
  doc.text("(內號)", 45, 145);
  doc.text("中文:", 113, 128);
  doc.text("英文:", 283, 128);
  doc.line(25, 150, 450, 150);

  doc.text("字 號", 47, 167);
  doc.text("(外號)", 45, 185);
  doc.text("中文:", 113, 168);
  doc.text("英文:", 283, 168);
  doc.line(25, 190, 450, 190);

  doc.text("貼二吋半身", 473, 167);
  doc.text("相片一張", 478, 185);
  doc.text("俗 名", 47, 213);
  doc.text("中文:", 113, 208);
  doc.text("英文:", 283, 208);
  doc.line(25, 230, 575, 230);

  doc.text("求戒類別", 38, 263);
  doc.setFontSize(13);
  doc.line(115, 236, 125, 236);
  doc.line(115, 246, 125, 246);
  doc.line(125, 236, 125, 246);
  doc.line(115, 236, 115, 246);
  doc.text("比丘(尼)戒", 130, 246);
  doc.line(115, 252, 125, 252);
  doc.line(115, 262, 125, 262);
  doc.line(125, 252, 125, 262);
  doc.line(115, 252, 115, 262);
  doc.text("沙彌(尼)戒", 130, 262);
  doc.line(115, 267, 125, 267);
  doc.line(115, 277, 125, 277);
  doc.line(125, 267, 125, 277);
  doc.line(115, 267, 115, 277);
  doc.text("增益戒", 130, 277);

  doc.text("性                     年                  出生", 203, 251);
  doc.text("別                     齡                  日期", 203, 273);
  doc.text("年            月            日", 445, 261);
  doc.line(25, 285, 575, 285);
  doc.line(200, 230, 200, 285); //垂直
  doc.line(220, 230, 220, 285);//垂直
  doc.line(300, 230, 300, 285);//垂直

  doc.setFontSize(15);
  doc.text("出生地", 44, 310);
  doc.text("中文:", 113, 300);
  doc.text("英文:", 354, 300);
  doc.line(25, 325, 575, 325);

  doc.text("國 籍", 47, 350);
  doc.text("中文:", 113, 340);
  doc.text("英文:", 354, 340);
  doc.line(25, 365, 575, 365);
  doc.text("戶籍地址", 40, 390);
  doc.text("中文:", 113, 380);
  doc.text("英文:", 354, 380);
  doc.line(25, 405, 575, 405);
  doc.text("剃度師長", 38, 423);
  doc.text("德號", 49, 440);
  doc.text("中文:", 113, 420);
  doc.text("英文:", 354, 420);
  doc.line(25, 445, 575, 445);

  doc.text("剃度常住", 38, 470);
  doc.text("中文:", 113, 460);
  doc.text("英文:", 354, 460);
  doc.line(25, 485, 575, 485);

  doc.text("剃度日期:       年       月       日", 29, 510);
  doc.text("現任職務：", 224, 510);
  doc.text("身份證號碼：", 354, 510);
  doc.line(220, 485, 220, 525);
  doc.line(25, 525, 575, 525);

  doc.text("現住寺院", 38, 542);
  doc.text("名稱", 49, 559);
  doc.text("中文:", 113, 540);
  doc.text("英文:", 354, 540);
  doc.line(25, 565, 575, 565);

  doc.text("現住寺院", 38, 602);
  doc.text("地址：", 49, 619);
  doc.text("中文：", 113, 588);
  doc.text("英文：", 113, 626);
  doc.text("電話：", 385, 580);
  doc.text("傳真：", 385, 600);
  doc.text("E-mail：", 385, 626);
  doc.line(380, 565, 380, 635); //垂直
  doc.line(110, 605, 575, 605);

  doc.text("剃度師長簽名：                      蓋章：", 36, 666);
  doc.text("師長受戒處", 355, 654);
  doc.text("師長受戒日期       年       月       日", 355, 681);
  doc.line(25, 635, 575, 635);
  doc.line(350, 661, 575, 661);

  doc.text("袈 裟", 47, 725);
  doc.text("(長)       尺       寸 (寬)        尺        寸", 113, 725);
  doc.text("身高：", 355, 710);
  doc.text("體重：", 355, 740);
  doc.line(350, 720, 575, 720);
  doc.line(25, 690, 575, 690);

  doc.text("世學學歷：", 38, 772);
  doc.line(25, 750, 575, 750);

  doc.text("初審", 24, 803);
  doc.text("審核", 280, 803);
  doc.text("◎填報名表之前，請詳閱背面附註欄", 24, 830);
  doc.line(110, 110, 110, 485); //垂直
  doc.line(280, 110, 280, 285); //垂直
  doc.line(450, 110, 450, 230); //垂直

  doc.line(110, 525, 110, 635); //垂直
  doc.line(110, 690, 110, 750); //垂直

  doc.line(350, 230, 350, 565); //垂直
  doc.line(350, 635, 350, 750); //垂直

  doc.addPage();

  doc.setLineWidth(1);

  doc.rect(25, 55, 550, 730)
  doc.setFont('msjh');
  doc.setFontSize(15);
  doc.text("未出家前之經歷：", 28, 78);
  doc.line(25, 185, 575, 185);
  doc.text("出家之因緣與願望：", 28, 210);
  doc.line(25, 380, 575, 380);
  doc.text("附:1、本表所列各項，中英文請均以正體字填妥(不會寫英文者，本會可以代填)，", 28, 400);
  doc.text("須附戶籍騰本、護照及佛教會會員證(或收據)影印本，如有配偶而未辦離婚", 68, 424);
  doc.text("手續者，附配偶同意書。限於109年國曆11月1日(農曆9月16日)以前， 郵寄", 68, 448);
  doc.text("330 桃園市桃園區朝陽街12號 「桃園佛教蓮社傳戒委員會」收。", 68, 472);
  doc.text("須附戶籍騰本、護照及佛教會會員證(或收據)影印本，如有配偶而未辦離婚", 68, 496);
  doc.text("2、本會收到報名表，經審查後，於戒會開始10天前，通知前來受戒。未接到", 47, 520);
  doc.text("通知者，請勿前來報到。", 68, 544);
  doc.text("3、本表須經剃度師長簽名蓋章，否則不予受理。", 47, 568);
  doc.line(25, 575, 575, 575);
  doc.text("審查意見：", 28, 600);
  doc.line(25, 730, 575, 730);
  doc.line(225, 730, 225, 785);//垂直
  doc.line(400, 730, 400, 785);//垂直
  doc.text("報到經辦人：", 28, 765);
  doc.text("超薦費：                      元", 230, 765);
  doc.text("洗衣費：                      元", 405, 765);


}

export const cloudwin602 = (doc, arrObj) => {
  // const COLOR = "#B22222";
  // doc.setTextColor(COLOR);
  // doc.setDrawColor(COLOR);
  doc.setLineWidth(1);

  doc.rect(25, 55, 550, 660)
  doc.setFont('msjh');
  doc.setFontSize(15);

  doc.text("菩 薩 戒", 25, 30);
  doc.line(105, 19, 115, 19);
  doc.line(105, 29, 115, 29);
  doc.line(115, 19, 115, 29);
  doc.line(105, 19, 105, 29);
  doc.text("男", 230, 30);
  doc.line(255, 19, 265, 19);
  doc.line(255, 29, 265, 29);
  doc.line(265, 19, 265, 29);
  doc.line(255, 19, 255, 29);

  doc.text("第                 班之             (編號：                 )", 300, 40);

  doc.text("五      戒", 25, 50);
  doc.line(105, 39, 115, 39);
  doc.line(105, 49, 115, 49);
  doc.line(115, 39, 115, 49);
  doc.line(105, 39, 105, 49);
  doc.text("女", 230, 50);
  doc.line(255, 39, 265, 39);
  doc.line(255, 49, 265, 49);
  doc.line(265, 39, 265, 49);
  doc.line(255, 39, 255, 49);

  doc.setFont('msjh');
  doc.setFontSize(20);
  doc.text("寶乘山桃園佛教蓮社三壇大戒 在家五戒、菩薩戒報名表", 75, 80);
  doc.setFontSize(15);
  doc.text("年           月           日填寫", 410, 105);
  doc.line(25, 110, 575, 110);

  doc.text("姓名", 37, 137);
  doc.text("法名", 198, 137);
  doc.text("性別", 348, 137);
  doc.line(190, 110, 190, 190);  //法名垂直
  doc.line(237, 110, 237, 150); //法名垂直
  doc.line(340, 110, 340, 150); //性別垂直
  doc.line(385, 110, 385, 150); //性別垂直
  doc.line(25, 150, 450, 150);

  doc.text("出生地", 31, 175);
  doc.text("身份證號", 207, 175);
  doc.line(290, 150, 290, 190); //身份證號垂直
  doc.line(25, 190, 450, 190);

  doc.text("貼二吋半身", 473, 167);
  doc.text("相片一張", 478, 185);
  doc.text("出生", 37, 208);
  doc.text("日期", 37, 225);
  doc.text("年            月              日生", 135, 215);
  doc.text("年齡", 348, 215);
  doc.line(340, 190, 340, 405); //年齡垂直
  doc.line(385, 190, 385, 285); //年齡垂直
  doc.line(25, 230, 575, 230);

  doc.text("學歷", 38, 263);
  doc.text("現職", 198, 263);
  doc.text("經歷", 348, 263);
  doc.line(25, 285, 575, 285);
  doc.line(190, 230, 190, 285); //垂直
  doc.line(240, 230, 240, 285);//垂直

  doc.text("皈依師", 35, 318);
  doc.text("長德號", 35, 335);
  doc.text("上                 下", 115, 330);
  doc.setFontSize(24);
  doc.text("師 長", 280, 330);
  doc.setFontSize(15);
  doc.text("皈依日期           年        月           日", 348, 330);
  doc.line(25, 355, 575, 355);

  doc.text("皈依", 38, 380);
  doc.text("寺院", 38, 397);
  doc.text("寺院", 294, 380);
  doc.text("地址", 294, 397);
  doc.line(280, 355, 280, 405);
  doc.line(25, 405, 575, 405);

  doc.text("求戒", 38, 440);
  doc.text("類別", 38, 457);
  doc.line(122, 432, 142, 432);
  doc.line(122, 452, 142, 452);
  doc.line(142, 432, 142, 452);
  doc.line(122, 432, 122, 452);
  doc.setFontSize(24);
  doc.text("五  戒", 153, 450);
  doc.line(297, 432, 317, 432);
  doc.line(297, 452, 317, 452);
  doc.line(317, 432, 317, 452);
  doc.line(297, 432, 297, 452);
  doc.text("菩薩戒", 337, 450);
  doc.line(25, 485, 575, 485);

  doc.setFontSize(15);
  doc.text("戶籍", 38, 503);
  doc.text("地址", 38, 520);
  doc.text("電話", 350, 510);
  doc.line(340, 485, 340, 574); //電話手機垂直
  doc.line(385, 485, 385, 574); //電話手機垂直
  doc.line(25, 529, 575, 529);

  doc.text("通訊", 38, 550);
  doc.text("地址", 38, 567);
  doc.text("手機", 350, 558);
  doc.line(25, 574, 575, 574);

  doc.text("附註：", 33, 590);
  doc.text("1.請將本表填妥，連同皈依證書影印本，限於國曆109年12月8日(農曆10月24日)", 33, 612);
  doc.text("以前，郵寄 330 桃園市桃園區朝陽街12號 「桃園佛教蓮社傳戒委員會」收。", 47, 634);
  doc.text("2.本寺接到報名表後，另函通知前來受戒。", 33, 656);
  doc.line(25, 670, 575, 670);

  doc.text("報到經辦人：", 30, 700);
  doc.text("超薦費                     元", 195, 700);
  doc.text("戒牒費            元", 345, 700);
  doc.text("洗衣費            元", 465, 700);
  doc.text("審查：", 30, 740);

  doc.line(190, 670, 190, 715); //垂直
  doc.line(340, 670, 340, 715); //垂直
  doc.line(458, 670, 458, 715); //垂直

  doc.line(450, 110, 450, 230); //垂直
  doc.line(80, 110, 80, 574); //垂直
}