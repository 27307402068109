import { common, develop } from './routesCommon'

import Pray from "views/temple/Pray";
import Light from "views/temple/Light";
import LightPraytype from "views/temple/LightPraytype";
import Account from "views/temple/Account";
import Year from "views/temple/Year";
import Class from "views/temple/Class";
import Identity from "views/temple/Identity";
import Family from "views/temple/Family";
import Buddhist from "views/temple/Buddhist";
import Temple from "views/temple/Temple";
import FriendTemple from "views/temple/FriendTemple";
import BorrowGod from "views/temple/BorrowGod";
import Statistic from "views/temple/Statistic";
import Print from "views/temple/Print";
import Cardslot from "views/temple/Cardslot";
import Cashier from "views/temple/Cashier";
import Donate from "views/temple/Donate.jsx"
// schoolhost
import StudentPage from 'views/schoolhost/studentPage/StudentPage';
import StudentPrint from 'views/schoolhost/studentPrint/StudentPrint';
// import Exam from 'views/schoolhost/academicAffair/Exam';
import ExamTable from 'views/schoolhost/academicAffair/ExamTable';
import DormPage from 'views/schoolhost/generalAffair/dormPage/DormPage';
import TeacherPage from 'views/schoolhost/Teacher/TeacherPage';
// import QueryList from "views/schoolhost/QueryList";
// import ReceptInfo from "views/schoolhost/ReceptInfo";
// import RecruitInfo from "views/schoolhost/RecruitInfo";
// import RecruitSelList from "views/schoolhost/RecruitSelList";
// import RecruitDocPrint from "views/schoolhost/RecruitDocPrint";
// import CurStudentInfo from "views/schoolhost/CurStudentInfo";
// import CurStudentList from "views/schoolhost/CurStudentList";
// import CurStudentPrint1 from "views/schoolhost/CurStudentPrint1";
// import CurStudentPrint2 from "views/schoolhost/CurStudentPrint2";
// import AttendManage from "views/schoolhost/AttendManage";
// import PeriodicTest from "views/schoolhost/PeriodicTest";
// import TestManage from "views/schoolhost/TestManage";
// import AdmissionManage from "views/schoolhost/AdmissionManage";
// import GraduateStudentInfo from "views/schoolhost/GraduateStudentInfo";
// import RoomInfo from "views/schoolhost/RoomInfo";
// import DormitoryManage from "views/schoolhost/DormitoryManage";
// import DormitoryCheckIn from "views/schoolhost/DormitoryCheckIn";
// import TimetableManage from "views/schoolhost/TimetableManage";
// import TeacherInfo from "views/schoolhost/TeacherInfo";
// import TeacherInfoList from "views/schoolhost/TeacherInfoList";
// import NewsTopics from "views/schoolhost/NewsTopics";
// import ScheduleManage from "views/schoolhost/ScheduleManage";
// import SchoolUsers from "views/schoolhost/SchoolUsers";
// import DevelopPrint from "views/schoolhost/DevelopPrint";
// import DevelopForm from "views/schoolhost/DevelopForm";
// import DevelopSelect from "views/schoolhost/DevelopSelect";
// import DevelopSetting from "views/schoolhost/DevelopSetting";
import AgentList from "views/schoolhost/recruitment/AgentList";
// import SchoolSystem from 'views/schoolhost/SchoolSystem';
// import News from 'views/schoolhost/News';

import SectionPage from 'views/widgets/SectionPage';
import SchoolPrintPage from 'views/widgets/SchoolPrintPage';

// import Album from 'views/common/Album';
import AnnualPlan from 'views/system/AnnualPlan';
import FormSettings from 'views/system/FormSettings';
import OptionSettings from 'views/system/OptionSettings';
import Setting from 'views/system/Setting';
import UserPage from 'views/User/UserPage';

//hanbury
import Financial1 from 'views/hanbury/Financial1';
import Hr1 from 'views/hanbury/Hr1';
import Hr2 from 'views/hanbury/Hr2';
import Hr4 from 'views/hanbury/Hr4';
import Hr5 from 'views/hanbury/Hr5';
import Hr7 from 'views/hanbury/Hr7';
import Client2 from 'views/hanbury/Client2';
import Client3 from 'views/hanbury/Client3';
import After1 from 'views/hanbury/After1';
import After3 from 'views/hanbury/After3';
import After4 from 'views/hanbury/After4';
import After5 from 'views/hanbury/After5';
import After6 from 'views/hanbury/After6';
import After7 from 'views/hanbury/After7';
import Product1 from 'views/hanbury/Product1';
import Product2 from 'views/hanbury/Product2';
import Web4 from 'views/hanbury/Web4';
import Web7 from 'views/hanbury/Web7';
import Web10 from 'views/hanbury/Web10';
import ClientsPage from 'views/hanbury/ClientsPage';
import EmployeePage from 'views/hanbury/EmployeePage';
// import BusinessPage from 'views/hanbury/BusinessPage';
// import Business2 from 'views/hanbury/Business2';

//pawnshop
import PawnticketSearch from 'views/pawnshop/PawnticketSearch';
// import RevenueTable from 'views/pawnshop/RevenueTable';
// import MonthlyReport from 'views/pawnshop/MonthlyReport';
import PawnOrder from 'views/pawnshop/PawnOrder';
import Client from 'views/pawnshop/Client';
import Employee from 'views/pawnshop/Employee';
import PawnSetting from 'views/pawnshop/PawnSetting';
import GoldMaster from 'views/pawnshop/GoldMaster';
// import Reminder from 'views/pawnshop/Reminder';
import Overdue from "views/pawnshop/Overdue";
// import TotalAccount from "views/pawnshop/TotalAccount";
import Margin from "views/pawnshop/Margin";
// import SalaryList from "views/pawnshop/SalaryList";
// import IncomeStatement from "views/pawnshop/IncomeStatement";
// import Cash from "views/pawnshop/Cash";
import ABaccount from "views/pawnshop/ABaccount";
// import Statistic1 from "views/pawnshop/Statistic1";
// import SubjectSetting from "views/pawnshop/SubjectSetting";

//SalesInventory
import SalesInventory from "views/salesInventory/SalesInventory";
import Products from "views/salesInventory/Products";

import LiveOptions from "views/streaming/LiveOptions";
import NewsList from "views/streaming/NewsList";
import PrayNews from "views/streaming/PrayNews";
import ActivityNews from "views/streaming/ActivityNews";
import DigitalLibrary from "views/streaming/DigitalLibrary";
import SupportList from "views/streaming/SupportList";
import DonateSupport from "views/streaming/DonateSupport";
import ProgramList from "views/streaming/ProgramList";
import DonaterList from "views/streaming/DonaterList";
import Users from "views/system/Users";

//dasin
import TestBankDesign from "views/dasin/TestBankDesign";
import AnswerDesign from "views/dasin/AnswerDesign";
import TestBankImport from "views/dasin/TestBankImport";
import UserGradesSearch from "views/dasin/UserGradesSearch";
import OrderMoneyStat from "views/dasin/OrderMoneyStat";
import UserAccount from "views/dasin/UserAccount";
import UserOrder from "views/dasin/UserOrder";
import UserPoints from "views/dasin/UserPoints";
import DasinUser from "views/dasin/DasinUser";

//autorepair
import autorepairCreate from "views/autorepair/autorepairCreate";
import BusinessDaily from "views/autorepair/BusinessDaily";
import CarCalendar from "views/autorepair/CarCalendar";
import CheckoutReceipt from "views/autorepair/CheckoutReceipt";
import InformationQuery from "views/autorepair/InformationQuery";
import MaintenanceRecordInventory from "views/autorepair/MaintenanceRecordInventory";
import MaintenanceRecordsRecords from "views/autorepair/MaintenanceRecordsRecords";
import PartsChange from "views/autorepair/PartsChange";
import PartsInventory from "views/autorepair/PartsInventory";
import VehicleDelivery from "views/autorepair/VehicleDelivery";

//Crm
import CrmClient from "views/crm/CrmClient";

//common
import FileCenter from 'views/web/FileCenter';

//accounting
import Cash from 'views/accounting/Cash';
import SalaryList from "views/accounting/SalaryList";
import IncomeStatement from "views/accounting/IncomeStatement";
import Statistic1 from "views/accounting/Statistic1";
import SubjectSetting from "views/accounting/SubjectSetting";

//restaurant
import Boardgame from 'views/restaurant/Boardgame';
import Borrowgame from 'views/restaurant/Borrowgame';
import CrewAllorder from 'views/restaurant/CrewAllorder';
import CrewBorrow from 'views/restaurant/CrewBorrow';
import CrewOpentable from 'views/restaurant/CrewOpentable';
import CrewOrder from 'views/restaurant/CrewOrder';
import CrewStorage from 'views/restaurant/CrewStorage';
import CrewTablestatus from 'views/restaurant/CrewTablestatus';
import CrewWarehouse from 'views/restaurant/CrewWarehouse';
import Members from 'views/restaurant/Members';
import Orderinner from 'views/restaurant/Orderinner';
import Orderoutter from 'views/restaurant/Orderoutter';
import Reserve from 'views/restaurant/Reserve';
import Managefood from 'views/restaurant/Managefood';
import Managegames from 'views/restaurant/Managegames';
import Managetables from 'views/restaurant/Managetables';
import CrewService from 'views/restaurant/CrewService';

//dorm
import dormClient from 'views/dorm/dormClient';
import C13 from 'views/dorm/C13';
import ManageRoom from 'views/dorm/ManageRoom';
import Expenditure from 'views/dorm/Expenditure';
import EveryMonth from 'views/dorm/EveryMonth';
import CheckLineLink from 'views/dorm/CheckLineLink';
import MeterList from './views/dorm/MeterList';
import ClientCalendar from './views/dorm/ClientCalendar';

import ReserveLine from 'views/Sweety/ReserveLine'
import ReserveCalendar from 'views/Sweety/ReserveCalendar'
import Cal from './views/ziWeiDouShu/Cal';

//doubleC
import DoubleCreserve from 'views/doubleC/DoubleCreserve';

const routes = [
  {
    webtypeArr: ['temple', 'dao'],
    collapse: true,
    name: "1 建檔",
    icon: "nc-icon nc-single-copy-04",
    state: "CreateCollapse",
    views: [
      {
        path: "/Family",
        params: '/:familyId?/:t1?/:t1Id?',
        name: "信眾",
        mini: "1-1",
        component: Family,
        layout: "/admin"
      },
      {
        path: "/Buddhist",
        params: '/:objectId?/:t1?/:t1Id?',
        name: "法師",
        mini: "1-2",
        component: Buddhist,
        layout: "/admin"
      },
      {
        path: "/Temple",
        params: '/:objectId?/:t1?/:t1Id?',
        name: "寺院",
        mini: "1-3",
        component: Temple,
        layout: "/admin"
      },
      {
        path: "/FriendTemple",
        name: "友宮",
        mini: "1-4",
        component: FriendTemple,
        layout: "/admin"
      },
      {
        path: "/BorrowGod",
        name: "借神",
        mini: "1-5",
        component: BorrowGod,
        layout: "/admin"
      },
      {
        path: "/Donate",
        name: "捐款",
        mini: "1-6",
        component: Donate,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['temple', 'dao'],
    collapse: true,
    name: "2 服務",
    icon: "nc-icon nc-tap-01",
    state: "ServeCollapse",
    views: [
      {
        path: "/Print",
        name: "列印",
        mini: "2-1",
        component: Print,
        layout: "/admin"
      },
      {
        path: "/Cardslot",
        name: "永久牌位",
        mini: "2-2",
        component: Cardslot,
        layout: "/admin"
      },
      {
        path: "/Cashier",
        name: "出納",
        mini: "2-3",
        component: Cashier,
        layout: "/admin"
      },
      {
        path: "/Statistic",
        name: "統計",
        mini: "2-4",
        component: Statistic,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['temple'],
    collapse: true,
    name: "3 設定",
    icon: "nc-icon nc-settings-gear-65",
    state: "SetCollapse",
    views: [
      {
        path: "/pray",
        name: "法會",
        mini: "3-1",
        component: Pray,
        layout: "/admin"
      },
      {
        path: "/light",
        name: "點燈",
        mini: "3-2",
        component: Light,
        layout: "/admin"
      },
      {
        path: "/LightPraytype",
        name: "牌位",
        mini: "3-3",
        component: LightPraytype,
        layout: "/admin"
      },
      {
        path: "/Account",
        name: "會計",
        mini: "3-4",
        component: Account,
        layout: "/admin"
      },
      {
        path: "/Year",
        name: "年度",
        mini: "3-5",
        component: Year,
        layout: "/admin"
      },
      {
        path: "/Class",
        name: "班別",
        mini: "3-6",
        component: Class,
        layout: "/admin"
      },
      {
        path: "/Identity",
        name: "身份",
        mini: "3-7",
        component: Identity,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['dao'],
    collapse: true,
    name: "3 設定",
    icon: "nc-icon nc-settings-gear-65",
    state: "SetCollapse",
    views: [
      {
        path: "/pray",
        name: "法會",
        mini: "3-1",
        component: Pray,
        layout: "/admin"
      },
      {
        path: "/LightPraytype",
        name: "牌位",
        mini: "3-2",
        component: LightPraytype,
        layout: "/admin"
      },
      // {
      //   path: "/Account",
      //   name: "會計",
      //   mini: "3-3",
      //   component: Account,
      //   layout: "/admin"
      // },
      {
        path: "/Year",
        name: "年度",
        mini: "3-3",
        component: Year,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['school'],
    collapse: true,
    name: "1 受付",
    icon: "nc-icon nc-bank",
    state: "receptCollapse",
    views: [
      {
        path: "/StudentPage",
        defaultPath: '/0',
        params: "/:list/:_id?/:activeIndex1?/:activeIndex2?",
        name: "入学問合せ管理帳",
        mini: "1-1",
        component: StudentPage,
        layout: "/admin"
      },
      {
        path: "/section",
        defaultPath: '/17',
        params: '/:list0/:list1?/:list2?',
        name: "問合せ一覧表",
        mini: "1-2",
        component: SectionPage,
        layout: "/admin"
      },
      // {
      //   path: "/schoolhost/ReceptInfo",
      //   name: "受付案内",
      //   mini: "1-3",
      //   component: ReceptInfo,
      //   layout: "/admin"
      // },
      {
        path: "/AgentList",
        name: "囑託募集先",
        mini: "1-3",
        component: AgentList,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['school'],
    collapse: true,
    name: "2 募集",
    icon: "nc-icon nc-tap-01",
    state: "recruitCollapse",
    views: [
      {
        path: "/StudentPage",
        defaultPath: '/1',
        params: "/:list/:_id?/:activeIndex1?/:activeIndex2?",
        name: "募集基本情報",
        mini: "2-1",
        component: StudentPage,
        layout: "/admin"
      },
      {
        path: "/section",
        defaultPath: '/3',
        params: '/:list0/:list1?/:list2?',
        name: "募集選考一覧",
        mini: "2-2",
        component: SectionPage,
        layout: "/admin"
      },
      {
        path: "/StudentPrint",
        defaultPath: '/1',
        params: '/:list',
        name: "個人書類印刷",
        mini: "2-3",
        component: StudentPrint,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['school'],
    collapse: true,
    name: "3 在籍",
    icon: "nc-icon nc-satisfied",
    state: "curStudentCollapse",
    views: [
      {
        path: "/StudentPage",
        defaultPath: '/2',
        params: "/:list/:_id?/:activeIndex1?/:activeIndex2?",
        name: "在籍基本情報",
        mini: "3-1",
        component: StudentPage,
        layout: "/admin"
      },
      {
        path: "/section",
        defaultPath: '/7',
        params: '/:list0/:list1?/:list2?',
        name: "在籍生一覧",
        mini: "3-2",
        component: SectionPage,
        layout: "/admin"
      },
      {
        path: "/section",
        defaultPath: '/8',
        params: '/:list0/:list1?/:list2?',
        name: "各種書類印刷",
        mini: "3-3",
        component: SectionPage,
        layout: "/admin"
      },
      {
        path: "/StudentPrint",
        defaultPath: '/2',
        params: '/:list',
        name: "個人書類印刷",
        mini: "3-4",
        component: StudentPrint,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['school'],
    collapse: true,
    name: "4 教務",
    icon: "nc-icon nc-tv-2",
    state: "academicAffairsCollapse",
    views: [
      {
        path: "/section",
        defaultPath: '/9',
        params: '/:list0/:list1?/:list2?',
        name: "出席率管理",
        mini: "4-1",
        component: SectionPage,
        layout: "/admin"
      },
      {
        path: "/ExamTable",
        name: "定期テスト",
        mini: "4-2",
        component: ExamTable,
        layout: "/admin"
      },
      {
        path: "/section",
        defaultPath: '/10',
        params: '/:list0/:list1?/:list2?',
        name: "クラス管理",
        mini: "4-3",
        component: SectionPage,
        layout: "/admin"
      },
      {
        path: "/section",
        defaultPath: '/11',
        params: '/:list0/:list1?/:list2?',
        name: "進学管理",
        mini: "4-4",
        component: SectionPage,
        layout: "/admin"
      },
      {
        path: "/StudentPage",
        defaultPath: '/3',
        params: "/:list/:_id?/:activeIndex1?/:activeIndex2?",
        name: "卒業中退除籍基本情報",
        mini: "4-5",
        component: StudentPage,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['school'],
    collapse: true,
    name: "5 総務",
    icon: "nc-icon nc-support-17",
    state: "generalAffairsCollapse",
    views: [
      {
        path: "/DormPage",
        params: '/:_id?/:roomid?/:activeIndex?',
        name: "部屋基本情報",
        mini: "5-1",
        component: DormPage,
        layout: "/admin"
      },
      {
        path: "/section",
        defaultPath: '/12',
        params: '/:list0/:list1?/:list2?',
        name: "学生寮管理",
        mini: "5-2",
        component: SectionPage,
        layout: "/admin"
      },
      {
        path: "/section",
        defaultPath: '/13',
        params: '/:list0/:list1?/:list2?',
        name: "学生寮入居状況",
        mini: "5-3",
        component: SectionPage,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['school'],
    collapse: true,
    name: "6 人事",
    icon: "nc-icon nc-circle-10",
    state: "personnelCollapse",
    views: [
      {
        path: "/section",
        defaultPath: '/15',
        params: '/:list0/:list1?/:list2?',
        name: "時間割管理",
        mini: "6-1",
        component: SectionPage,
        layout: "/admin"
      },
      {
        path: "/TeacherPage",
        params: "/:_id?",
        name: "教員基本情報",
        mini: "6-2",
        component: TeacherPage,
        layout: "/admin"
      },
      {
        path: "/section",
        defaultPath: '/16',
        params: '/:list0/:list1?/:list2?',
        name: "教員情報一覧",
        mini: "6-3",
        component: SectionPage,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['school'],
    collapse: true,
    name: "システム",
    icon: "nc-icon nc-settings",
    state: "testlCollapse",
    views: [
      // {
      //   path: "/album",
      //   name: "Album",
      //   mini: "1",
      //   component: Album,
      //   layout: "/admin"
      // },
      // {
      //   path: "/news",
      //   name: "NEWS & TOPICS",
      //   mini: "2",
      //   component: News,
      //   layout: "/admin"
      // },
      {
        path: "/AnnualPlan",
        name: "行事予定管理帳",
        mini: "3",
        component: AnnualPlan,
        layout: "/admin"
      },
      {
        path: "/UserPage",
        name: "使用者",
        mini: "4",
        component: UserPage,
        layout: "/admin"
      },
      // {
      //   path: "/SchoolSystem",
      //   name: "システム",
      //   mini: "5",
      //   component: SchoolSystem,
      //   layout: "/admin"
      // },
      {
        path: "/SchoolPrintPage",
        name: "開発用印刷",
        mini: "6",
        component: SchoolPrintPage,
        layout: "/admin"
      },
      {
        path: "/FormSettings",
        name: "開発用欄位",
        mini: "7",
        component: FormSettings,
        layout: "/admin"
      },
      {
        path: "/OptionSettings",
        name: "開発用選項",
        mini: "8",
        component: OptionSettings,
        layout: "/admin"
      },
      {
        path: "/Setting",
        name: "開発用Setting",
        mini: "9",
        component: Setting,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['hanbury'],
    collapse: true,
    name: "1 客戶",
    icon: "nc-icon nc-badge",
    state: "clientCollapse",
    views: [
      {
        path: "/clientsPage",
        params: '/:listType?/:clientId?/:tabId?/:portfolioId?',
        name: "客戶列表",
        mini: "1-1",
        component: ClientsPage,
        layout: "/admin"
      },
      {
        path: "/Client2",
        name: "客戶投資內容",
        mini: "1-2",
        component: Client2,
        layout: "/admin"
      },
      // {
      //   path: "/Client3",
      //   name: "新件進度",
      //   mini: "1-3",
      //   component: Client3,
      //   layout: "/admin"
      // },
    ]
  },
  {
    webtypeArr: ['hanbury'],
    collapse: true,
    name: "2 售後",
    icon: "nc-icon nc-paper",
    state: "afterCollapse",
    views: [
      {
        path: "/After1",
        name: "電子郵件",
        mini: "2-1",
        component: After1,
        layout: "/admin"
      },
      {
        path: "/After3",
        name: "案件追蹤",
        mini: "2-2",
        component: After3,
        layout: "/admin"
      },
      {
        path: "/After4",
        name: "繳費提醒",
        mini: "2-3",
        component: After4,
        layout: "/admin"
      },
      {
        path: "/After5",
        name: "生日提醒",
        mini: "2-4",
        component: After5,
        layout: "/admin"
      },
      {
        path: "/After6",
        name: "基金淨值更新",
        mini: "2-5",
        component: After6,
        layout: "/admin"
      },
      {
        path: "/After7",
        name: "配息條件【客戶配息】",
        mini: "2-6",
        component: After7,
        layout: "/admin"
      },
      // {
      //   path: "/After9",
      //   name: "表單中心",
      //   mini: "2-7",
      //   component: After9,
      //   layout: "/admin"
      // },
      // {
      //   path: "/After9",
      //   name: "業績報表",
      //   mini: "2-8",
      //   component: After9,
      //   layout: "/admin"
      // },
      {
        path: "/Web10",
        name: "電子郵件管理",
        mini: "2-9",
        component: Web10,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['hanbury'],
    collapse: true,
    name: "3 產品",
    icon: "nc-icon nc-bag-16",
    state: "productCollapse",
    views: [
      {
        path: "/Product1",
        name: "產品資訊",
        mini: "3-1",
        component: Product1,
        layout: "/admin"
      },
      {
        path: "/Product2",
        name: "產品中心",
        mini: "3-2",
        component: Product2,
        layout: "/admin"
      },
      // {
      //   path: "/BusinessPage",
      //   name: "業務列表",
      //   mini: "3-3",
      //   component: BusinessPage,
      //   layout: "/admin"
      // },
      // {
      //   path: "/Business2",
      //   name: "業務總覽",
      //   mini: "3-3",
      //   component: Business2,
      //   layout: "/admin"
      // },
      {
        path: "/Web7",
        name: "供應商管理",
        mini: "3-3",
        component: Web7,
        layout: "/admin"
      },
      {
        path: "/Web4",
        name: "產品管理",
        mini: "3-4",
        component: Web4,
        layout: "/admin"
      },
      // {
      //   path: "/hanProductFile/product",
      //   name: "產品文件",
      //   mini: "3-5",
      //   component: FileCenter,
      //   layout: "/admin"
      // },
    ]
  },
  {
    webtypeArr: ['hanbury'],
    collapse: true,
    name: "4 人資",
    icon: "nc-icon nc-tap-01",
    state: "peopleCollapse",
    views: [
      {
        path: "/hanHRFile/HR",
        name: "人事規章",
        mini: "4-1",
        component: FileCenter,
        layout: "/admin"
      },
      // {
      //   path: "/Hr2",
      //   name: "空白表單",
      //   mini: "4-2",
      //   component: Hr2,
      //   layout: "/admin"
      // },
      {
        path: "/Hr4",
        name: "差勤申請",
        mini: "4-2",
        component: Hr4,
        layout: "/admin"
      },
      {
        path: "/Hr5",
        name: "差勤總覽",
        mini: "4-3",
        component: Hr5,
        layout: "/admin"
      },
      {
        path: "/Hr7",
        name: "差勤設定",
        mini: "4-4",
        component: Hr7,
        layout: "/admin"
      },
      {
        path: "/employeePage",
        params: '/:tabId?/:employeeId?',
        name: "員工列表",
        mini: "4-5",
        component: EmployeePage,
        layout: "/admin"
      },
      {
        path: "/hanUsers",
        name: "權限設定",
        mini: "4-6",
        component: Users,
        layout: "/admin"
      },
      // {
      //   path: "/Hr10",
      //   name: "隸屬事業群設定",
      //   mini: "4-8",
      //   component: Hr10,
      //   layout: "/admin"
      // }
    ]
  },
  {
    webtypeArr: ['hanbury'],
    collapse: true,
    name: "5 財務",
    icon: "nc-icon nc-money-coins",
    state: "accountCollapse",
    views: [
      {
        path: "/Financial1",
        defaultPath: '/fin0',
        params: "/:activeItem?/:activeItem2?",
        name: "資料輸入",
        mini: "5-1",
        component: Financial1,
        layout: "/admin"
      },
      {
        path: "/Financial1",
        defaultPath: '/fin2',
        params: "/:activeItem?/:activeItem2?",
        name: "財務報表",
        mini: "5-2",
        component: Financial1,
        layout: "/admin"
      },
      {
        path: "/Financial1",
        defaultPath: '/fin1',
        params: "/:activeItem?/:activeItem2?",
        name: "設定",
        mini: "5-3",
        component: Financial1,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['autorepair'],
    collapse: true,
    name: "1 建檔",
    icon: "nc-icon nc-tap-01",
    state: "ServeCollapse",
    views: [
      {
        path: "/autorepairCreate",
        name: "建檔",
        mini: "1-1",
        component: autorepairCreate,
        layout: "/admin"
      },
      {
        path: "/BusinessDaily",
        name: "營業日報",
        mini: "1-2",
        component: BusinessDaily,
        layout: "/admin"
      },
      {
        path: "/CarCalendar",
        name: "車籍車歷",
        mini: "1-3",
        component: CarCalendar,
        layout: "/admin"
      },
      {
        path: "/CheckoutReceipt",
        name: "結帳收款",
        mini: "1-4",
        component: CheckoutReceipt,
        layout: "/admin"
      },
      {
        path: "/VehicleDelivery",
        name: "車輛交修",
        mini: "1-5",
        component: VehicleDelivery,
        layout: "/admin"
      },
      {
        path: "/InformationQuery",
        name: "資料查詢",
        mini: "1-6",
        component: InformationQuery,
        layout: "/admin"
      },
      {
        path: "/MaintenanceRecordInventory",
        name: "維修紀錄-庫存",
        mini: "1-7",
        component: MaintenanceRecordInventory,
        layout: "/admin"
      },
      {
        path: "/MaintenanceRecordsRecords",
        name: "維修紀錄-紀錄",
        mini: "1-8",
        component: MaintenanceRecordsRecords,
        layout: "/admin"
      },
      {
        path: "/PartsChange",
        name: "零件異動",
        mini: "1-9",
        component: PartsChange,
        layout: "/admin"
      },
      {
        path: "/PartsInventory",
        name: "零件庫存",
        mini: "1-10",
        component: PartsInventory,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['crm'],
    collapse: true,
    name: "1 建檔",
    icon: "nc-icon nc-tap-01",
    state: "ServeCollapse",
    views: [
      {
        path: "/CrmClient",
        params: '/:clientId?/:t1?/:t1Id?',
        name: "建檔",
        mini: "1-1",
        component: CrmClient,
        layout: "/admin"
      }
    ]
  },
  // {
  //   webtypeArr: ['hanbury'],
  //   collapse: true,
  //   name: "6 網站",
  //   icon: "nc-icon nc-circle-10",
  //   state: "internetCollapse",
  //   views: [
  //     {
  //       path: "/Web1",
  //       name: "首頁輪播照片",
  //       mini: "6-1",
  //       component: Web1,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/Web4",
  //       name: "網站導覽",
  //       mini: "6-2",
  //       component: Web4,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/Web8",
  //       name: "公告管理",
  //       mini: "6-3",
  //       component: Web8,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  {
    webtypeArr: ['pawnshop'],
    collapse: true,
    name: "1 建檔",
    icon: "nc-icon nc-single-copy-04",
    state: "CreateCollapse",
    views: [
      {
        path: "/Client",
        // params: '/:objectId?/:t1?/:t1Id?',
        params: '/:clientId?/:t1?/:t1Id?',
        name: "客戶",
        mini: "1-1",
        component: Client,
        layout: "/admin"
      },
      {
        path: "/Employee",
        params: '/:employeeId?/:t1?/:t1Id?',
        name: "職員",
        mini: "1-2",
        component: Employee,
        layout: "/admin"
      },
      // {
      //   path: "/Cash",
      //   name: "雜支",
      //   mini: "1-3",
      //   component: Cash,
      //   layout: "/admin"
      // },
    ]
  },
  {
    webtypeArr: ['pawnshop'],
    collapse: true,
    name: "2 服務",
    icon: "nc-icon nc-tap-01",
    state: "ServeCollapse",
    views: [
      {
        path: "/PawnOrder",
        name: "當票",
        mini: "2-1",
        component: PawnOrder,
        layout: "/admin"
      },
      {
        path: "/Overdue",
        name: "收款",
        mini: "2-2",
        component: Overdue,
        layout: "/admin"
      },
      // {
      //   path: "/PawnticketSearch",
      //   name: "業績",
      //   mini: "2-3",
      //   component: PawnticketSearch,
      //   layout: "/admin"
      // },
      {
        path: "/ABaccount",
        name: "業績",
        mini: "2-3",
        component: ABaccount,
        layout: "/admin"
      },
      {
        path: "/Margin",
        name: "保證金",
        mini: "2-4",
        component: Margin,
        layout: "/admin"
      },
      // {
      //   path: "/SalaryList",
      //   name: "薪資表",
      //   mini: "2-6",
      //   component: SalaryList,
      //   layout: "/admin"
      // },
      // {
      //   path: "/IncomeStatement",
      //   name: "損益",
      //   mini: "2-7",
      //   component: IncomeStatement,
      //   layout: "/admin"
      // },
      // {
      //   path: "/Statistic1",
      //   name: "統計",
      //   mini: "2-8",
      //   component: Statistic1,
      //   layout: "/admin"
      // },
      // {
      //   path: "/Margin",
      //   name: "員工資料",
      //   mini: "1-1",
      //   component: Margin,
      //   layout: "/admin"
      // },
      // {
      //   path: "/Reminder",
      //   name: "催繳資料表",
      //   mini: "1-7",
      //   component: Reminder,
      //   layout: "/admin"
      // },
      // {
      //   path: "/TotalAccount",
      //   name: "總帳",
      //   mini: "2-6",
      //   component: TotalAccount,
      //   layout: "/admin"
      // },
      // {
      //   path: "/RevenueTable",
      //   name: "營收表",
      //   mini: "2-9",
      //   component: RevenueTable,
      //   layout: "/admin"
      // },
      // {
      //   path: "/MonthlyReport",
      //   name: "月報表",
      //   mini: "1-3",
      //   component: MonthlyReport,
      //   layout: "/admin"
      // },
    ]
  },
  {
    webtypeArr: ['pawnshop'],
    collapse: true,
    name: "3 設定",
    icon: "nc-icon nc-settings-gear-65",
    state: "SetCollapse",
    views: [
      {
        path: "/PawnSetting",
        name: "典當品",
        mini: "3-1",
        component: PawnSetting,
        layout: "/admin"
      },
      {
        path: "/GoldMaster",
        name: "金主",
        mini: "3-2",
        component: GoldMaster,
        layout: "/admin"
      },
      // {
      //   path: "/SubjectSetting",
      //   name: "雜支類別",
      //   mini: "3-3",
      //   component: SubjectSetting,
      //   layout: "/admin"
      // },
    ]
  },
  {
    webtypeArr: ['salesInventory'],
    collapse: true,
    name: "管理",
    icon: "nc-icon nc-single-copy-04",
    state: "CreateCollapse",
    views: [
      {
        path: "/SalesInventory",
        // params: '/:employeeId?/:t1?/:t1Id?',
        params: '/:clientId?/:t1?/:t1Id?',
        name: "訂單",
        mini: "1-1",
        component: SalesInventory,
        layout: "/admin"
      },
      {
        path: "/Products",
        // params: '/:employeeId?/:t1?/:t1Id?',
        name: "庫存",
        mini: "1-2",
        component: Products,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['dorm'],
    collapse: true,
    name: "建檔",
    icon: "nc-icon nc-single-copy-04",
    state: "dormCreate",
    views: [
      {
        path: "/dormClient",
        // params: '/:employeeId?/:t1?/:t1Id?',
        params: '/:clientId?/:t1?/:t1Id?',
        name: "房客",
        mini: "➤",
        component: dormClient,
        layout: "/admin"
      },
      {
        path: "/ManageRoom",
        // params: '/:employeeId?/:t1?/:t1Id?',
        name: "房間",
        mini: "➤",
        component: ManageRoom,
        layout: "/admin"
      },
      {
        path: "/Expenditure",
        // params: '/:employeeId?/:t1?/:t1Id?',
        name: "支出",
        mini: "➤",
        component: Expenditure,
        layout: "/admin"
      },
      {
        path: "/MeterList",
        // params: '/:employeeId?/:t1?/:t1Id?',
        name: "水電錶",
        mini: "➤",
        component: MeterList,
        layout: "/admin"
      },
      {
        path: "/ClientCalendar",
        // params: '/:employeeId?/:t1?/:t1Id?',
        name: "客人總表",
        mini: "➤",
        component: ClientCalendar,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['dorm'],
    collapse: true,
    name: "統計",
    icon: "nc-icon nc-chart-pie-36",
    state: "dormStat",
    views: [
      {
        path: "/EveryMonth",
        // params: '/:employeeId?/:t1?/:t1Id?',
        name: "每月統計",
        mini: "➤",
        component: EveryMonth,
        layout: "/admin"
      },
      {
        path: "/CheckLineLink",
        // params: '/:employeeId?/:t1?/:t1Id?',
        name: "確認房間連結",
        mini: "➤",
        component: CheckLineLink,
        layout: "/admin"
      },
      // {
      //   path: "/IncomeStatement",
      //   name: "損益",
      //   mini: "➤",
      //   component: IncomeStatement,
      //   layout: "/admin"
      // },
      // {
      //   path: "/dormClient",
      //   // params: '/:employeeId?/:t1?/:t1Id?',
      //   params: '/:clientId?/:t1?/:t1Id?',
      //   name: "客戶資料",
      //   mini: "1-1",
      //   component: dormClient,
      //   layout: "/admin"
      // },
      // {
      //   path: "/C13",
      //   // params: '/:employeeId?/:t1?/:t1Id?',
      //   name: "客房管理",
      //   mini: "1-2",
      //   component: C13,
      //   layout: "/admin"
      // },
    ]
  },
  {
    webtypeArr: ['dorm'],
    collapse: true,
    name: "設定",
    icon: "nc-icon nc-settings-gear-65",
    state: "dormSetting",
    views: [
      // {
      //   path: "/dormClient",
      //   // params: '/:employeeId?/:t1?/:t1Id?',
      //   params: '/:clientId?/:t1?/:t1Id?',
      //   name: "客戶資料",
      //   mini: "1-1",
      //   component: dormClient,
      //   layout: "/admin"
      // },
      // {
      //   path: "/ManageRoom",
      //   // params: '/:employeeId?/:t1?/:t1Id?',
      //   name: "房間管理",
      //   mini: "1-1",
      //   component: ManageRoom,
      //   layout: "/admin"
      // },
    ]
  },
  {
    webtypeArr: ['onlinetest'],
    auth: false,
    collapse: true,
    name: "1 題庫",
    icon: "nc-icon nc-settings-gear-65",
    state: "ExamCollapse",
    views: [
      // {
      //   path: "/TestBankDesign",
      //   name: "題庫設計",
      //   mini: "1-1",
      //   component: TestBankDesign,
      //   layout: "/admin"
      // },
      // {
      //   path: "/AnswerDesign",
      //   name: "解答設計",
      //   mini: "1-2",
      //   component: AnswerDesign,
      //   layout: "/admin"
      // },
      {
        path: "/TestBankImport",
        name: "題庫匯入",
        mini: "1-1",
        component: TestBankImport,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['onlinetest'],
    collapse: true,
    name: "2 服務",
    icon: "nc-icon nc-settings-gear-65",
    state: "ServiceCollapse",
    views: [
      {
        path: "/UserGradesSearch",
        name: "使用者成績查詢",
        mini: "2-1",
        component: UserGradesSearch,
        layout: "/admin"
      },
      {
        path: "/OrderMoneyStat",
        name: "歷史成績",
        mini: "2-2",
        component: OrderMoneyStat,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['onlinetest'],
    collapse: true,
    name: "3 管理",
    icon: "nc-icon nc-settings-gear-65",
    state: "ManagermentCollapse",
    views: [
      {
        path: "/UserPoints",
        name: "測驗統計",
        mini: "3-1",
        component: UserPoints,
        layout: "/admin"
      },
      {
        path: "/UserOrder",
        name: "班級",
        mini: "3-2",
        component: UserOrder,
        layout: "/admin"
      },
      // {
      //   path: "/UserAccount",
      //   name: "學校",
      //   mini: "3-3",
      //   component: UserAccount,
      //   layout: "/admin"
      // },
      {
        path: "/DasinUser",
        params: '/:clientId?/:t1?/:t1Id?',
        name: "學校",
        mini: "3-3",
        component: DasinUser,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['ziwei'],
    auth: false,
    collapse: true,
    name: "建檔",
    icon: "nc-icon nc-tap-01",
    state: "ActCollapse",
    views: [
      {
        path: "/Cal",
        name: "排盤",
        mini: "➤",
        component: Cal,
        layout: "/admin"
      },
      // {
      //   path: "/Orderoutter",
      //   name: "收銀顯示",
      //   mini: "➤",
      //   component: Orderoutter,
      //   layout: "/admin"
      // },
      // {
      //   path: "/Orderinner",
      //   name: "出餐顯示",
      //   mini: "➤",
      //   component: Orderinner,
      //   layout: "/admin"
      // },
      // {
      //   path: "/CrewService",
      //   params: '/:ordering1Id?',
      //   name: "店員服務",
      //   mini: "➤",
      //   component: CrewService,
      //   layout: "/admin",
      //   hidden: true
      // }
    ]
  },
  {
    // webtypeArr: ['temple', 'dao', 'school', 'pawnshop', 'dorm', 'publishing', "onlinetest", 'autorepair', 'salesInventory', 'crm'],
    webtypeArr: ['useAccount'],
    collapse: true,
    name: "會計",
    icon: "nc-icon nc-money-coins",
    state: "accountingCollapse",
    views: [
      {
        path: "/Cash",
        name: "雜支",
        mini: "➤",
        component: Cash,
        layout: "/admin"
      },
      {
        path: "/SalaryList",
        name: "薪資表",
        mini: "➤",
        component: SalaryList,
        layout: "/admin"
      },
      {
        path: "/IncomeStatement",
        name: "損益",
        mini: "➤",
        component: IncomeStatement,
        layout: "/admin"
      },
      {
        path: "/Statistic1",
        name: "統計",
        mini: "➤",
        component: Statistic1,
        layout: "/admin"
      },
      {
        path: "/SubjectSetting",
        name: "雜支類別",
        mini: "➤",
        component: SubjectSetting,
        layout: "/admin"
      }
    ]
  },
  {
    webtypeArr: ['restaurant'],
    auth: false,
    // params: '/:clientId?/:t1?/:t1Id?',
    collapse: true,
    name: "開桌",
    icon: "nc-icon nc-tap-01",
    state: "OpenCollapse",
    views: [
      {
        path: "/CrewOpentable",
        params: '/:ordering1Id?',
        name: "開桌",
        mini: "➤",
        component: CrewOpentable,
        layout: "/admin"
      },
      {
        path: "/Reserve",
        name: "預約",
        mini: "➤",
        component: Reserve,
        layout: "/admin"
      },
      // {
      //   path: "/CrewOrder",
      //   params: '/:ordering1Id?',
      //   name: "詳細訂單",
      //   mini: "➤",
      //   component: CrewOrder,
      //   layout: "/admin"
      // },
    ]
  },
  {
    webtypeArr: ['restaurant'],
    auth: false,
    collapse: true,
    name: "服務",
    icon: "nc-icon nc-tap-01",
    state: "ServiceCollapse",
    views: [
      {
        path: "/CrewBorrow",
        name: "出租",
        mini: "➤",
        component: CrewBorrow,
        layout: "/admin"
      },
      {
        path: "/CrewWarehouse",
        name: "出售",
        mini: "➤",
        component: CrewWarehouse,
        layout: "/admin"
      },
      {
        path: "/CrewStorage",
        name: "進貨",
        mini: "➤",
        component: CrewStorage,
        layout: "/admin"
      },
      {
        path: "/Members",
        params: '/:clientId?/:t1?/:t1Id?',
        name: "會員",
        mini: "➤",
        component: Members,
        layout: "/admin"
      }
    ]
  },
  {
    webtypeArr: ['restaurant'],
    auth: false,
    collapse: true,
    name: "行動顯示",
    icon: "nc-icon nc-tap-01",
    state: "ActCollapse",
    views: [
      {
        path: "/CrewTablestatus",
        name: "手機點餐",
        mini: "➤",
        component: CrewTablestatus,
        layout: "/admin"
      },
      // {
      //   path: "/Orderoutter",
      //   name: "收銀顯示",
      //   mini: "➤",
      //   component: Orderoutter,
      //   layout: "/admin"
      // },
      // {
      //   path: "/Orderinner",
      //   name: "出餐顯示",
      //   mini: "➤",
      //   component: Orderinner,
      //   layout: "/admin"
      // },
      {
        path: "/CrewService",
        params: '/:ordering1Id?',
        name: "店員服務",
        mini: "➤",
        component: CrewService,
        layout: "/admin",
        hidden: true
      }
    ]
  },
  {
    webtypeArr: ['restaurant'],
    auth: false,
    collapse: true,
    name: "統計",
    icon: "nc-icon nc-tap-01",
    state: "StatCollapse",
    views: [
      {
        path: "/CrewAllorder",
        name: "訂單",
        mini: "➤",
        component: CrewAllorder,
        layout: "/admin"
      }
    ]
  },
  {
    webtypeArr: ['restaurant'],
    auth: false,
    collapse: true,
    name: "設定",
    icon: "nc-icon nc-settings-gear-65",
    state: "restaurantSettingCollapse",
    views: [
      {
        path: "/Managetables",
        name: "桌位",
        mini: "➤",
        component: Managetables,
        layout: "/admin"
      },
      {
        path: "/Managefood",
        name: "餐點",
        mini: "➤",
        component: Managefood,
        layout: "/admin"
      },
      {
        path: "/Managegames",
        name: "桌遊",
        mini: "➤",
        component: Managegames,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['makeup'],
    collapse: true,
    name: "預約",
    icon: "nc-icon nc-diamond",
    state: "BonusCollapse",
    views: [
      {
        path: "/ReserveLine",
        params: '/:clientId?/:t1?/:t1Id?',
        name: "預約管理",
        mini: "1",
        component: ReserveLine,
        layout: "/admin"
      },
      {
        path: "/ReserveCalendar",
        params: '/:clientId?/:t1?/:t1Id?',
        name: "預約日期",
        mini: "1",
        component: ReserveCalendar,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['healthy'],
    collapse: true,
    name: "預約",
    icon: "nc-icon nc-diamond",
    state: "BonusCollapse",
    views: [
      {
        path: "/DoubleCreserve",
        params: '/:clientId?/:t1?/:t1Id?',
        name: "預約管理",
        mini: "1",
        component: DoubleCreserve,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['streaming'],
    collapse: true,
    name: "管理",
    icon: "nc-icon nc-laptop",
    state: "systemCollapse",
    views: [
      {
        path: "/LiveOptions",
        name: "線上直播管理",
        mini: "➤",
        component: LiveOptions,
        layout: "/admin"
      },
      {
        path: "/NewsList",
        name: "最新消息列表",
        mini: "➤",
        component: NewsList,
        layout: "/admin"
      },
      {
        path: "/PrayNews",
        name: "法會訊息列表",
        mini: "➤",
        component: PrayNews,
        layout: "/admin"
      },
      {
        path: "/ActivityNews",
        name: "活動報導列表",
        mini: "➤",
        component: ActivityNews,
        layout: "/admin"
      },
      {
        path: "/DigitalLibrary",
        name: "數位圖書館",
        mini: "➤",
        component: DigitalLibrary,
        layout: "/admin"
      },
      {
        path: "/SupportList",
        name: "護持功德主列表",
        mini: "➤",
        component: SupportList,
        layout: "/admin"
      },
      {
        path: "/DonateSupport",
        name: "捐款護持",
        mini: "➤",
        component: DonateSupport,
        layout: "/admin"
      },
      {
        path: "/ProgramList",
        name: "每週節目表",
        mini: "➤",
        component: ProgramList,
        layout: "/admin"
      },
      {
        path: "/DonaterList",
        name: "捐款護持列表",
        mini: "➤",
        component: DonaterList,
        layout: "/admin"
      },
    ]
  },
  ...common,
  ...develop
];

export default routes;
