import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select, Form } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'

import { Row, Col, Input } from "reactstrap";
import Moment from 'moment';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { exportPdf } from 'views/web/CustomMaterialTable';
import MembersTable from './MembersTable';
import BoardgameTable from './BoardgameTable';

const columns = () => ([
  { width: 80, title: '出租日', field: 'borrowDate' },
  { width: 80, title: '會員姓名', field: 'name' },
  { width: 80, title: '會員電話', field: 'phone' },
  { width: 80, title: '租金', field: 'rent' },
  { width: 80, title: '歸還日', field: 'returnDate' },
  {
    width: 80, title: '桌遊', field: 'boardgame',
    render: rowData => <>
      {(rowData && rowData.boardgame && rowData.boardgame.length && rowData.boardgame.map((item, index) =>
        <span>{index !== 0 ? ',' : null}{item}</span>)) || null}
    </>
  },
  { width: 80, title: '收取押金', field: 'deposit' },
  { width: 80, title: '備註', field: 'ps' },
]);
const DateDiff = (sDate1, sDate2) => { // sDate1 和 sDate2 是 2016-06-18 格式
  const oDate1 = new Date(sDate1);
  const oDate2 = new Date(sDate2);
  const iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24); // 把相差的毫秒數轉換為天數
  return iDays;
};
const d = new Date()
class CrewBorrow extends Component {
  state = {
    activeItem: 'bio',
    data: [],
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
    rowData: {},
    memberObj: {},
    boardgameObj: {},
    boardgameArr: [],
    gameDeposit: 0,
    borrowObj: {}
  }
  componentDidMount = () => {
    this.handleFetch()
  }
  handleFetch = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data1 = await Parse.queryData('borrow', { companyId });

    this.setState({ dataArr: data1 })
  }
  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  // handleChange = (e, { name, value }) => {
  //   this.setState({ [name]: value }, () => this.hanldeFilter())
  // }

  // hanldeFilter = async () => {
  //   const { companyObj } = this.props
  //   const companyId = companyObj.objectId
  //   const data1 = await Parse.queryData('borrow', { companyId });

  //   this.setState({ dataArr: data1 })
  // }
  handleClickName = (rowData, type) => {
    const { boardgameArr, gameDeposit } = this.state
    if (type === 'member') {
      this.setState({ memberObj: rowData })
    } else {
      boardgameArr.push(rowData)
      const number = gameDeposit + Number(rowData.gameDeposit)
      this.setState({ boardgameArr, gameDeposit: number })
    }
  }
  handleChange1 = (e, { name, value }) => {
    const { borrowObj } = this.state
    this.setState({ borrowObj: { ...borrowObj, [name]: value } })
  }
  savaBorrow = async () => {
    const { companyObj } = this.props
    const { dataArr, borrowObj, memberObj, boardgameArr, gameDeposit } = this.state
    const nameArr = []

    boardgameArr.forEach(item => {
      nameArr.push(item.name)
    })
    let obj = {
      ...borrowObj,
      companyId: companyObj.objectId,
      borrowDate: Moment(d).format('YYYY-MM-DD') || '',
      name: memberObj.name,
      phone: memberObj.cellnum,
      rent: String(Number(gameDeposit) * DateDiff(Moment(d).format('YYYY-MM-DD'), borrowObj.returnDate) / 2),
      boardgame: nameArr,
      deposit: String(gameDeposit)
    }
    // console.log(sel);
    await Parse.saveData('borrow', obj);
    this.handleFetch()
  }
  handleUpdate = async () => {
    const { newData } = this.state;
    await Parse.saveData('borrow', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    await Parse.deleteData('borrow', oldData);
    this.setState({ oldData: {} })
  }
  render() {
    const { companyObj } = this.props
    const { dataArr, monthSel, rowData, memberObj, boardgameArr, gameDeposit, ps, returnDate } = this.state
    // console.log(rowData);
    return (<div className="content">
      <Header as='h2' inverted={companyObj.inverted}>出租</Header>
      <Row style={{ backgroundColor: '#fff' }}>
        <Col md='6'>
          <h4>1.選擇會員</h4>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>會員類別</Table.HeaderCell>
                <Table.HeaderCell>姓名</Table.HeaderCell>
                <Table.HeaderCell>手機</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>{memberObj.memberType}</Table.Cell>
                <Table.Cell>{memberObj.name}</Table.Cell>
                <Table.Cell>{memberObj.cellnum}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table >
          <br />
          <h4>2.輸入桌遊條碼</h4>
          <Form>
            <Form.Group widths='equal' inline>
              <Form.Input fluid />
              <Form.Button content='Submit' />
            </Form.Group>
          </Form>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>名稱</Table.HeaderCell>
                <Table.HeaderCell>押金</Table.HeaderCell>
                <Table.HeaderCell>租金/2天</Table.HeaderCell>
                <Table.HeaderCell>條碼</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {boardgameArr.map((item, index) =>
                <Table.Row key={index}>
                  <Table.Cell>{item.name}</Table.Cell>
                  <Table.Cell>{item.gameDeposit}</Table.Cell>
                  <Table.Cell>{item.gameRent}</Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table >
          <br />
          <h4>3.收取押金：NTD. {gameDeposit}</h4>
          <Form>
            <Form.Group >
              <Form.Input label='歸還日' width={8} type='date' name='returnDate' value={returnDate} onChange={this.handleChange1} />
              <Form.Input label='備註' width={8} name='ps' value={ps} onChange={this.handleChange1} />
            </Form.Group>
            <br />
            <Form.Button color='blue' content='確認出租' onClick={this.savaBorrow} />
          </Form>
        </Col>
        <Col md='6'>
          <MembersTable {...this.props} handleClickName={this.handleClickName} />
          <BoardgameTable {...this.props} handleClickName={this.handleClickName} />
        </Col>
      </Row>
      <br />
      <br />
      <MaterialTable
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '出租'),
          pageSize: 10,
          search: false
        }}
        data={dataArr}
        title="出租"
        actions={[
          {
            icon: 'view_headline',
            tooltip: '查看',
            onClick: (event, rowData) => this.handleData(rowData)
          }
        ]}
        editable={{
          //   onRowAdd: newData =>
          //     new Promise((resolve, reject) => {
          //       const data = [...dataArr];
          //       data.push(newData);
          //       // handleState('people0', data);
          //       this.setState({ dataArr: data, newData }, () => resolve());
          //     }).then(() => this.handleAdd(0, 0)),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...dataArr];
              const index = data.indexOf(oldData);
              data[index] = newData;
              // handleState('people0', data);
              this.setState({ dataArr: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate(0)),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...dataArr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              // handleState('people0', data);
              this.setState({ dataArr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete(0)),
        }}
      />
      <br />
      <br />
      {/* <MaterialTable
          localization={localization()}
          columns={columns()}
          options={{
            addRowPosition: "first",
exportButton: true,
            pageSize: 10,
            search: false
          }}
          data={dataArr}
          title="出租"
          actions={[
            {
              icon: 'view_headline',
              tooltip: '查看',
              onClick: (event, rowData) => this.handleData(rowData)
            }
          ]}
          editable={{
            //   onRowAdd: newData =>
            //     new Promise((resolve, reject) => {
            //       const data = [...dataArr];
            //       data.push(newData);
            //       // handleState('people0', data);
            //       this.setState({ dataArr: data, newData }, () => resolve());
            //     }).then(() => this.handleAdd(0, 0)),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const data = [...dataArr];
                const index = data.indexOf(oldData);
                data[index] = newData;
                // handleState('people0', data);
                this.setState({ dataArr: data, newData, oldData }, () => resolve());
              }).then(() => this.handleUpdate(0)),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                let data = [...dataArr];
                const index = data.indexOf(oldData);
                data.splice(index, 1);
                // handleState('people0', data);
                this.setState({ dataArr: data, oldData }, () => resolve());
              }).then(() => this.handleDelete(0)),
          }}
        /> */}
    </div >);
  }
}

export default CrewBorrow;