import React, { Component } from 'react'
import { Container, Header, Button, Form, Grid, Select, Dimmer, Loader, Image, Segment } from 'semantic-ui-react';
// import JoditEditor from "jodit-react";
import { DimmerImg } from '../../layouts/Dimmer';
import Parse from 'widget/parse'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'widget/ckeditor';

import intl from 'react-intl-universal';

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export default class Web10 extends Component {
  state = {
    loginUser: this.props.user.profile.toJSON(),
    dataArr: [],
    dataArr2: [],
    dataArr3: [],
    emailauto: [],
    bgObj: {},
    loading: true
  }

  componentDidMount() {
    const { loginUser } = this.state
    const { companyObj } = this.props
    const authPage = window.location.pathname.split('/')[2]
    const authUser = companyObj.objectId + '_' + authPage
    let auth = {}

    if ((loginUser.authAdminA && loginUser.authAdminA.indexOf(companyObj.objectId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
      auth = {
        edit: true,
      }
    } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
      auth = {
        edit: false,
      }
    } else {
      auth = {
        edit: false,
      }
    }
    this.setState({ auth }, () => this.handleFetch())
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { match, companyObj } = props;
    const companyId = companyObj.objectId;
    const emailauto = await Parse.queryData('emailauto', { companyId });
    // console.log(emailauto)
    this.setState({ dataArr: emailauto, loading: false, emailauto })
  }
  handleSave = async () => {
    const { dataArr } = this.state
    this.setState({ loading: true })
    for (let i = 0; i < dataArr.length; i++) {
      if (dataArr.find(item => (item.objectId === dataArr[i].objectId))) {
        let obj = {}
        obj = dataArr.find(item => item.objectId === dataArr[i].objectId)
        await Parse.saveData('emailauto', obj)
      } else
        continue
    }

    this.setState({ loading: false }, () => window.alert("儲存成功"))
  }

  handleChange = (e, { name, value, id }) => {
    const { dataArr } = this.state

    const objArr = dataArr.map(item => {
      let obj = {}
      if (item.objectId === id) {
        obj = {
          ...item,
          [name]: value
        }
        return obj
      }
      return item
    })
    this.setState({ dataArr: objArr })
  }

  handleBlur = (name, value, data) => {
    const { dataArr } = this.state
    const objArr = dataArr.map(item => {
      let obj = {}
      if (item.objectId === data.id) {
        obj = {
          ...item,
          [name]: value
        }
        return obj
      }
      return item
    })
    this.setState({ dataArr: objArr })
  }

  showTable = (id) => {
    const { companyObj } = this.props
    const { dataArr } = this.state

    if (id === "CustomerBirthdayReminder") {
      const data = dataArr.find(item => (item.objectId === id)) || {}
      return (<>
        <div className='content'>
          {/* <Header as='h3' style={{ margin: '1rem auto' }}>2-9-1 生日提醒</Header> */}
          <Grid>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>寄件人</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                <Form.Input name="" value="notice@hanburyifa.com" readOnly={true} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>輸入副本信箱(逗號分隔)</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                <Form.Input name="cc" id={data.objectId} value={(data && data.cc) || ""} onChange={this.handleChange} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>輸入密件副本信箱(逗號分隔)</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                <Form.Input name="bcc" id={data.objectId} value={(data && data.bcc) || ""} onChange={this.handleChange} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>主旨</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16} style={{ ...style.flexCenter }}>
                <Form.Input name="subject" id={data.objectId} value={(data && data.subject) || ""} onChange={this.handleChange} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>內文</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                {/* < JoditEditor
                  // ref={editor}
                  name='context'
                  value={(data && data.context) || ""}
                  // config={config}
                  config={{
                    uploader: { insertImageAsBase64URI: true }
                  }}
                  tabIndex={2} // tabIndex of textarea
                  onBlur={newContent => this.handleBlur("context", newContent, data)} /> */}
                <CKEditor
                  editor={ClassicEditor}
                  data={(data && data.context) || ""}
                  onInit={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const inputData = editor.getData();
                    this.handleBlur("context", inputData, data)
                    // console.log({ event, editor, data });
                  }}
                // onBlur={(event, editor) => {
                //   console.log('Blur.', editor);
                // }}
                // onFocus={(event, editor) => {
                //   console.log('Focus.', editor);
                // }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </>
      )
    } else if (id === "CustomerPaymentReminder") {
      const data = dataArr.find(item => (item.objectId === id)) || {}
      return (<>
        <div className='content'>
          <Header inverted={companyObj.inverted} as='h3' style={{ margin: '1rem auto' }}>2-9-2 繳費提醒</Header>
          <Grid>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>寄件人</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                <Form.Input name="" value="notice@hanburyifa.com" readOnly={true} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>輸入副本信箱(逗號分隔)</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                <Form.Input name="cc" id={data.objectId} value={(data && data.cc) || ""} onChange={this.handleChange} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>輸入密件副本信箱(逗號分隔)</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                <Form.Input name="bcc" id={data.objectId} value={(data && data.bcc) || ""} onChange={this.handleChange} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>主旨</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16} style={{ ...style.flexCenter }}>
                <Form.Input name="subject" id={data.objectId} value={(data && data.subject) || ""} onChange={this.handleChange} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>內文</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                {/* < JoditEditor
                  // ref={editor}
                  name='context'
                  value={(data && data.context) || ""}
                  // config={config}
                  config={{
                    uploader: { insertImageAsBase64URI: true }
                  }}
                  tabIndex={2} // tabIndex of textarea
                  onBlur={newContent => this.handleBlur("context", newContent, data)} /> */}
                <CKEditor
                  editor={ClassicEditor}
                  data={(data && data.context) || ""}
                  onInit={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const inputData = editor.getData();
                    this.handleBlur("context", inputData, data)
                    // console.log({ event, editor, data });
                  }}
                // onBlur={(event, editor) => {
                //   console.log('Blur.', editor);
                // }}
                // onFocus={(event, editor) => {
                //   console.log('Focus.', editor);
                // }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </>)
    } else if (id.split("_")[0] === "LeaveReview") {
      const data1 = dataArr.find(item => (item.objectId === "LeaveReview_AgentReview")) || {}
      const data2 = dataArr.find(item => (item.objectId === "LeaveReview_SupervisorReview")) || {}
      const data3 = dataArr.find(item => (item.objectId === "LeaveRevie")) || {}
      const data4 = dataArr.find(item => (item.objectId === "LeaveReview_LeavePassed")) || {}
      return (<>
        <div className='content'>
          <Header inverted={companyObj.inverted} as='h3' style={{ margin: '1rem auto' }}>2-9-5 差勤申請</Header>
          <Grid>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>寄件人</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                <Form.Input name="" value="notice@hanburyifa.com" readOnly={true} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>輸入副本信箱(逗號分隔)</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                <Form.Input name="cc" id={data1.objectId} value={(data1 && data1.cc) || ""} onChange={this.handleChange} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>輸入密件副本信箱(逗號分隔)</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                <Form.Input name="bcc" id={data1.objectId} value={(data1 && data1.bcc) || ""} onChange={this.handleChange} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>主旨</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16} style={{ ...style.flexCenter }}>
                <Form.Input name="subject" id={data1.objectId} value={(data1 && data1.subject) || ""} onChange={this.handleChange} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>內文</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                {/* < JoditEditor
                  // ref={editor}
                  name='context1'
                  value={(data1 && data1.context) || ""}
                  // config={config}
                  config={{
                    uploader: { insertImageAsBase64URI: true }
                  }}
                  tabIndex={2} // tabIndex of textarea
                  onBlur={newContent => this.handleBlur("context1", newContent, data1)} /> */}
                <CKEditor
                  editor={ClassicEditor}
                  data={(data1 && data1.context) || ""}
                  onInit={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const inputData = editor.getData();
                    this.handleBlur("context", inputData, data1)
                    // console.log({ event, editor, data });
                  }}
                // onBlur={(event, editor) => {
                //   console.log('Blur.', editor);
                // }}
                // onFocus={(event, editor) => {
                //   console.log('Focus.', editor);
                // }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>主管 主旨</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16} style={{ ...style.flexCenter }}>
                <Form.Input name="subject" id={data2.objectId} value={data2.subject} onChange={this.handleChange} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>主管 內文</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                {/* < JoditEditor
                  // ref={editor}
                  name='context'
                  value={data2.context}
                  // config={config}
                  config={{
                    uploader: { insertImageAsBase64URI: true }
                  }}
                  tabIndex={2} // tabIndex of textarea
                  onBlur={newContent => this.handleBlur("context", newContent, data2)} /> */}
                <CKEditor
                  editor={ClassicEditor}
                  data={(data2 && data2.context) || ""}
                  onInit={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const inputData = editor.getData();
                    this.handleBlur("context", inputData, data2)
                    // console.log({ event, editor, data });
                  }}
                // onBlur={(event, editor) => {
                //   console.log('Blur.', editor);
                // }}
                // onFocus={(event, editor) => {
                //   console.log('Focus.', editor);
                // }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>人資 主旨</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16} style={{ ...style.flexCenter }}>
                <Form.Input name="subject" id={data3.objectId} value={data3.subject} onChange={this.handleChange} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>人資 內文</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                {/* < JoditEditor
                  // ref={editor}
                  name='context'
                  value={data3.context}
                  // config={config}
                  config={{
                    uploader: { insertImageAsBase64URI: true }
                  }}
                  tabIndex={2} // tabIndex of textarea
                  onBlur={newContent => this.handleBlur("context", newContent, data3)} /> */}
                <CKEditor
                  editor={ClassicEditor}
                  data={(data3 && data3.context) || ""}
                  onInit={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const inputData = editor.getData();
                    this.handleBlur("context", inputData, data3)
                    // console.log({ event, editor, data });
                  }}
                // onBlur={(event, editor) => {
                //   console.log('Blur.', editor);
                // }}
                // onFocus={(event, editor) => {
                //   console.log('Focus.', editor);
                // }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>申請成功 主旨</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16} style={{ ...style.flexCenter }}>
                <Form.Input name="subject" id={data4.objectId} value={data4.subject} onChange={this.handleChange} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>申請成功 內文</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                {/* < JoditEditor
                  // ref={editor}
                  name='context'
                  value={data4.context}
                  // config={config}
                  config={{
                    uploader: { insertImageAsBase64URI: true }
                  }}
                  tabIndex={2} // tabIndex of textarea
                  onBlur={newContent => this.handleBlur("context", newContent, data4)} /> */}
                <CKEditor
                  editor={ClassicEditor}
                  data={(data4 && data4.context) || ""}
                  onInit={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const inputData = editor.getData();
                    this.handleBlur("context", inputData, data4)
                    // console.log({ event, editor, data });
                  }}
                // onBlur={(event, editor) => {
                //   console.log('Blur.', editor);
                // }}
                // onFocus={(event, editor) => {
                //   console.log('Focus.', editor);
                // }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </>)
    } else if (id === "NewCustomerProgress") {
      const data = dataArr.find(item => (item.objectId === id))
      return (<>
        <div className='content'>
          <Header inverted={companyObj.inverted} as='h3' style={{ margin: '1rem auto' }}>2-9-4 新件進度</Header>
          <Grid>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>寄件人</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                <Form.Input name="" value="notice@hanburyifa.com" readOnly={true} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>輸入副本信箱(逗號分隔)</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                <Form.Input name="cc" id={data.objectId} value={(data && data.cc) || ""} onChange={this.handleChange} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>輸入密件副本信箱(逗號分隔)</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                <Form.Input name="bcc" id={data.objectId} value={(data && data.bcc) || ""} onChange={this.handleChange} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>主旨</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16} style={{ ...style.flexCenter }}>
                <Form.Input name="subject" value={(data && data.subject) || ""} onChange={this.handleChange} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>內文</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                {/* < JoditEditor
                  // ref={editor}
                  name='context'
                  value={(data && data.context) || ""}
                  // config={config}
                  config={{
                    uploader: { insertImageAsBase64URI: true }
                  }}
                  tabIndex={2} // tabIndex of textarea
                  onBlur={newContent => this.handleBlur("context", newContent, data)} /> */}
                <CKEditor
                  editor={ClassicEditor}
                  data={(data && data.context) || ""}
                  onInit={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const inputData = editor.getData();
                    this.handleBlur("context", inputData, data)
                    // console.log({ event, editor, data });
                  }}
                // onBlur={(event, editor) => {
                //   console.log('Blur.', editor);
                // }}
                // onFocus={(event, editor) => {
                //   console.log('Focus.', editor);
                // }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </>)
    } else if (id === "CustomerCa") {
      const data = dataArr.find(item => (item.objectId === id)) || {}
      return (<>
        <div className='content'>
          <Header inverted={companyObj.inverted} as='h3' style={{ margin: '1rem auto' }}>2-9-3 案件追蹤</Header>
          <Grid>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>寄件人</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                <Form.Input name="" value="notice@hanburyifa.com" readOnly={true} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>輸入副本信箱(逗號分隔)</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                <Form.Input name="cc" id={data.objectId} value={(data && data.cc) || ""} onChange={this.handleChange} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>輸入密件副本信箱(逗號分隔)</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                <Form.Input name="bcc" id={data.objectId} value={(data && data.bcc) || ""} onChange={this.handleChange} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>主旨</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16} style={{ ...style.flexCenter }}>
                <Form.Input name="subject" value={(data && data.subject) || ""} onChange={this.handleChange} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3} computer={3} tablet={3} mobile={16}>
                <label>內文</label>
              </Grid.Column>
              <Grid.Column width={13} computer={13} tablet={13} mobile={16}>
                {/* < JoditEditor
                  // ref={editor}
                  name='context'
                  value={(data && data.context) || ""}
                  // config={config}
                  config={{
                    uploader: { insertImageAsBase64URI: true }
                  }}
                  tabIndex={2} // tabIndex of textarea
                  onBlur={newContent => this.handleBlur("context", newContent, data)} /> */}
                <CKEditor
                  editor={ClassicEditor}
                  data={(data && data.context) || ""}
                  onInit={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event, editor) => {
                    const inputData = editor.getData();
                    this.handleBlur("context", inputData, data)
                    // console.log({ event, editor, data });
                  }}
                // onBlur={(event, editor) => {
                //   console.log('Blur.', editor);
                // }}
                // onFocus={(event, editor) => {
                //   console.log('Focus.', editor);
                // }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </>)
    }
  }

  render() {
    const { companyObj } = this.props
    const { loading, auth } = this.state;
    return (<div className="content">
      {loading ? <DimmerImg /> :
        <Container>
          <Button content={intl.get('儲存')} floated="right" positive onClick={() => { this.handleSave(); this.setState({ loading: true }); }} disabled={!auth.edit} />
          <Header inverted={companyObj.inverted} as='h2' style={{ margin: '2rem auto' }}>2-9 電子郵件管理</Header>
          <Form inverted={companyObj.inverted}>
            {this.showTable("CustomerBirthdayReminder")}
            {this.showTable("CustomerPaymentReminder")}
            {this.showTable("CustomerCa")}
            {this.showTable("NewCustomerProgress")}
            {this.showTable("LeaveReview_AgentReview")}
          </Form>
          <br />
          <br />
        </Container>}
    </div>)
  }
}