import React, { Component } from 'react'
import { Container, Header, Button, Dimmer, Form, Grid, Image, Checkbox, Divider, Loader, Segment, Icon } from 'semantic-ui-react';
import ColorPicker from './ColorPicker'
import { Row, Col, FormGroup, Input } from "reactstrap";

import intl from 'react-intl-universal';

export default class ThemeColor extends Component {
  state = {
  }

  render() {
    const { companyObj, handleSaveCompany } = this.props

    return (<>
      <Header as='h3' dividing inverted={companyObj.inverted}>背景顏色</Header>
      <Row>
        <Col md={2} sm={3}>
          <Header as='h4' inverted={companyObj.inverted}>顏色1</Header>
          <FormGroup>
            <ColorPicker {...this.props} field='color1' />
          </FormGroup>
        </Col>
        <Col md={2} sm={3}>
          <Header as='h4' inverted={companyObj.inverted}>顏色2</Header>
          <FormGroup>
            <ColorPicker {...this.props} field='color2' />
          </FormGroup>
        </Col>
        <Col md={2} sm={3}>
          <Header as='h4' inverted={companyObj.inverted}>顏色3</Header>
          <FormGroup>
            <ColorPicker {...this.props} field='color3' />
          </FormGroup>
        </Col>
        <Col md={2} sm={3}>
          <Header as='h4' inverted={companyObj.inverted}>登入背景色</Header>
          <FormGroup>
            <ColorPicker {...this.props} field='loginBgColor' />
          </FormGroup>
        </Col>
        <Col md={2} sm={3}>
          <Header as='h4' inverted={companyObj.inverted}>介面背景色</Header>
          <FormGroup>
            <ColorPicker {...this.props} field='bgColor' />
          </FormGroup>
        </Col>
        <Col md={2} sm={3}>
          <label>&nbsp;</label>
          <br />
          <br />
          <FormGroup>
            <Button size='medium' color='green' onClick={handleSaveCompany}>{intl.get('儲存')}</Button>
          </FormGroup>
        </Col>
      </Row>
    </>)
  }
}
