import React, { Component } from 'react'
import HorizontalComponent from './OptionSettings/HorizontalComponent';

const OptionSettings = (props) => (<div className='content'>
  <h2 style={{ margin: '0 20px' }}>OptionSettings 選項設定</h2>
  <div style={{ margin: '0 20px', overflowX: 'auto' }}>
    <HorizontalComponent {...props} />
  </div>
</div>)

export default OptionSettings