import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  // NavItem,
  // NavLink,
  // Nav,
  // TabContent,
  // TabPane,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function SectionPricing(props = {}) {
  const { form } = props;
  // console.log(form)
  const { text2 = 'text2', text3 = 'text3', text4 = 'text4', text5 = 'text5', text6 = 'text6',
    text7 = 'text7', text8 = 'text8', text9 = 'text9', text10 = 'text10', text11 = 'text11', text12 = 'text12',
    text13 = 'text13', text14 = 'text14', text15 = 'text15', text16 = 'text16', text17 = 'text17',
    text18 = 'text18', text19 = 'text19', text20 = 'text20', text21 = 'text21', text22 = 'text22' } = form;
  const [activePill, setActivePill] = React.useState("1");
  // pills for the last pricing
  // const [pillActive, setPillActive] = React.useState("personal");
  return (
    <>
      <div className="section section-pricing cd-section" id="pricing">
        {/* ********* PRICING 3 ********* */}
        <div
          className="pricing-3 section-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/sections/daniel-olahs.jpg") + ")"
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">{text2}</h2>
                <h5 className="description">
                  {text3}
                </h5>
              </Col>
            </Row>
            <div className="space-top" />
            <Row>
              <Col className="ml-auto mr-auto" md="3">
                <Card className="card-pricing">
                  <CardBody>
                    <h6 className="card-category">{text4}</h6>
                    <div className="card-icon">
                      <i className="nc-icon nc-user-run" />
                    </div>
                    <CardTitle tag="h3">{text5}</CardTitle>
                    <p className="card-description">
                      {text6}
                    </p>
                    <CardFooter>
                      <Button
                        className="btn-round card-link"
                        color="info"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        {text7}
                      </Button>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col className="mr-auto" md="4">
                <Card
                  className="card-pricing"
                  data-background="color"
                  data-color="blue"
                >
                  <CardBody>
                    <h6 className="card-category">{text8}</h6>
                    <div className="card-icon">
                      <i className="nc-icon nc-air-baloon" />
                    </div>
                    <CardTitle tag="h3">{text9}</CardTitle>
                    <p className="card-description">
                      {text10}
                    </p>
                    <CardFooter>
                      <Button
                        className="btn-neutral btn-round card-link"
                        color="default"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        {text11}
                      </Button>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col className="mr-auto" md="3">
                <Card className="card-pricing">
                  <CardBody>
                    <h6 className="card-category">{text12}</h6>
                    <div className="card-icon">
                      <i className="nc-icon nc-istanbul" />
                    </div>
                    <CardTitle tag="h3">{text13}</CardTitle>
                    <p className="card-description">
                      {text14}
                    </p>
                    <CardFooter>
                      <Button
                        className="btn-round card-link"
                        color="info"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        {text15}
                      </Button>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END PRICING 3 ********* */}
      </div>
    </>
  );
}

export default SectionPricing;
