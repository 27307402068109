import React, { Component } from 'react';
import { Tab, Form } from 'semantic-ui-react';

import List1Client from './List1Client';
import List2Client from './List2Client';
import List3Client from './List3Client';
import List5Client from './List5Client';

const size = 'small';
const TabRender = ({ component, ...rest }) =>
  <Tab.Pane
    style={{ backgroundColor: 'lavender' }}
  ><Form size={size}
    style={{ backgroundColor: 'lavender' }}
  >
      {React.createElement(component, { ...rest })}</Form></Tab.Pane>;
const menuTitle = (str1, str2 = '', icon = 'tty', LEN = 4) =>
  <>
    {str1}
    {str2 ? <>{str2.substr(0, LEN)}</> : null}
    {str2.length >= LEN ? <><br />{str2.substr(LEN, LEN)}</> : null}
    {str2.length >= LEN * 2 ? <><br />{str2.substr(LEN * 2, LEN)}</> : null}
    {str2.length >= LEN * 3 ? <><br />{str2.substr(LEN * 3, LEN)}</> : null}
    {str2.length >= LEN * 4 ? <><br />{str2.substr(LEN * 4, LEN)}</> : null}
  </>;

export default class ClientsViewTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: props.match.params.tabId || 0,
    };
  }
  handleTabChange = (e, { activeIndex }) => {
    const { history, doc, isEdit } = this.props

    if (!isEdit) {
      history.push(`/admin/clientsPage/1/${doc.objectId}/${activeIndex}`);
      this.setState({ activeIndex })
    }
  }

  panes = [
    { menuItem: { content: menuTitle('', '基本資料') }, render: () => <TabRender {...this.props} component={List1Client} /> },
    { menuItem: { content: menuTitle('', '投資內容') }, render: () => <TabRender {...this.props} component={List2Client} /> },
    { menuItem: { content: menuTitle('', '電子報表') }, render: () => <TabRender {...this.props} component={List3Client} isEdit={true} /> },
    { menuItem: { content: menuTitle('', '客服事項') }, render: () => <TabRender {...this.props} component={List5Client} /> },
  ]

  render() {
    const { isEdit } = this.props;
    const { activeIndex } = this.state;
    return (
      <Form>
        <Tab
          fluid
          activeIndex={activeIndex}
          onTabChange={this.handleTabChange}
          menu={{ pointing: true, disabled: isEdit, stackable: true }}
          panes={this.panes} />
      </Form>
    );
  }
}