// dpi 220
// import as1_1 from './as/FPI_1_1.png'
// import as1_2 from './as/FPI_1_2.png'
// import as1_3 from './as/FPI_1_3.png'
// import as1_4 from './as/FPI_1_4.png'
// VOYA_WF = basePath+'./as/Voya_Wells_Fargo.png';
// import FPI_68_2 from './as/FPI-68/02a. FPI - BSO Chi & Eng.png';
// import FPI_68_3 from './as/FPI-68/FPI - Direct Charge Authority-1.png';
// import FPI_68_4 from './as/FPI-68/FPI - Direct Charge Authority-2.png';
// import FPI_70_4 from './as/FPI-70/3. PIC Statement-1.png';
// import FPI_70_5 from './as/FPI-70/3. PIC Statement-2.png';
// import FPI_70_6 from './as/FPI-70/3. PIC Statement-3.png';
// import FPI_70_7 from './as/FPI-70/3. PIC Statement-4.png';
// import FPI_70_8 from './as/FPI-70/3. PIC Statement-5.png';
// import FPI_70_9 from './as/FPI-70/3. PIC Statement-6.png';

const wd_1 = 'WD/WithdrawalDocument2_1.png';
const wd_2 = 'WD/WithdrawalDocument2_2.png';
const wd_3 = 'WD/WithdrawalDocument2_3.png';
const wd_4 = 'WD/WithdrawalDocument2_4.png';
const wd_5 = 'WD/WithdrawalDocument2_5.png';
const wd_6 = 'WD/WithdrawalDocument2_6.png';
const wd_7 = 'WD/WithdrawalDocument2_7.png';
const wd_8 = 'WD/WithdrawalDocument2_8.png';
const paidUp = 'FPI-Paid-UpFormbilingual.png';
const premium = 'FPI-PremiumHolidayFormbilingual.png';
const Reinstatement = 'FPI-ReinstatementFormbilingual_2015.png';
const blank = 'FPI_blank.png';
const BSO = 'FPI-BSOChi&Eng.png';
const CancelBSO = 'FPI-InstructiontoCancelBSOform.png';
const NEW_1 = 'FPI_NEW_1.png';
const NEW_2 = 'FPI_NEW_2.png';
const VOYA_WF = 'Voya_Wells_Fargo_2.png';
const gt_1 = 'GT/GT-ClientNeedsAnalysis_1.png';
const gt_2 = 'GT/GT-ClientNeedsAnalysis_2.png';
const gt_3 = 'GT/GT-ClientNeedsAnalysis_3.png';
const gt_4 = 'GT/GT-ClientNeedsAnalysis_4.png';
const gt_5 = 'GT/GT-ClientNeedsAnalysis_5.png';
const gt_6 = 'GT/GT-ClientNeedsAnalysis_6.png';
const gt_7 = 'GT/GT-ClientNeedsAnalysis_7.png';
const gt_8 = 'GT/GT-ClientNeedsAnalysis_8.png';
const gt_9 = 'GT/GT-ClientNeedsAnalysis_9.png';
const gt_10 = 'GT/GT-ClientNeedsAnalysis_10.png';
const gt_11 = 'GT/GT-ClientNeedsAnalysis_11.png';
const gt_12 = 'GT/GT-ClientNeedsAnalysis_12.png';
const gt_13 = 'GT/GT-ClientNeedsAnalysis_13.png';
const gt_14 = 'GT/GT-ClientNeedsAnalysis_14.png';
const gt_15 = 'GT/GT-ClientNeedsAnalysis_15.png';
const gt_16 = 'GT/GT-ClientNeedsAnalysis_16.png';
const gt_17 = 'GT/GT-ClientNeedsAnalysis_17.png';
const TOPUP_1 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_1.png';
const TOPUP_2 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_2.png';
const TOPUP_3 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_3.png';
const TOPUP_4 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_4.png';
const TOPUP_5 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_5.png';
const TOPUP_6 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_6.png';
const TOPUP_7 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_7.png';
const TOPUP_8 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_8.png';
const TOPUP_9 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_9.png';
const TOPUP_10 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_10.png';
const TOPUP_11 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_11.png';
const TOPUP_12 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_12.png';
const TOPUP_13 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_13.png';
const TOPUP_14 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_14.png';
const TOPUP_15 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_15.png';
const TOPUP_16 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_16.png';
const TOPUP_17 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_17.png';
const TOPUP_18 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_18.png';
const TOPUP_19 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_19.png';
const TOPUP_20 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_20.png';
const TOPUP_21 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_21.png';
const TOPUP_22 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_22.png';
const TOPUP_23 = 'TOPUP/TOPUPformforHongKong_DAHK_XP_TOPUP_23.png';
const FNA_1 = 'FNA/FNAform_1.png';
const FNA_2 = 'FNA/FNAform_2.png';
const FNA_3 = 'FNA/FNAform_3.png';
const FNA_4 = 'FNA/FNAform_4.png';
const FNA_5 = 'FNA/FNAform_5.png';
const FNA_6 = 'FNA/FNAform_6.png';
const FNA_7 = 'FNA/FNAform_7.png';
const FPI_RPBWS_1 = 'FPI-RPBWS/FPI-RPBWS-1.png';
const FPI_RPBWS_2 = 'FPI-RPBWS/FPI-RPBWS-2.png';
const FPI_RPBWS_3 = 'FPI-RPBWS/FPI-RPBWS-3.png';
const FPI_RPBWS_4 = 'FPI-RPBWS/FPI-RPBWS-4.png';
const FPI_RPBWS_5 = 'FPI-RPBWS/FPI-RPBWS-5.png';
const FPI_RPBWS_6 = 'FPI-RPBWS/FPI-RPBWS-6.png';
const FPI_RPBWS_7 = 'FPI-RPBWS/FPI-RPBWS-7.png';
const FPI_RPBWS_8 = 'FPI-RPBWS/FPI-RPBWS-8.png';
const FPI_CCCA_1 = 'FPI-CCCA/FPI-CCCA-1.png';
const FPI_CCCA_2 = 'FPI-CCCA/FPI-CCCA-2.png';
const FPI_CCCA_3 = 'FPI-CCCA/FPI-CCCA-3.png';
const FPI_CCCA_4 = 'FPI-CCCA/FPI-CCCA-4.png';
const FPI_CCCA_5 = 'FPI-CCCA/FPI-CCCA-5.png';
const FPI_AOTPAP_1 = 'FPI-AOTPAP/FPI-AOTPAP-1.png';
const FPI_AOTPAP_2 = 'FPI-AOTPAP/FPI-AOTPAP-2.png';
const FPI_AOTPAP_3 = 'FPI-AOTPAP/FPI-AOTPAP-3.png';
const FPI_AOTPAP_4 = 'FPI-AOTPAP/FPI-AOTPAP-4.png';
const FPI_AOTPAP_5 = 'FPI-AOTPAP/FPI-AOTPAP-5.png';
const FPI_AOTPAP_6 = 'FPI-AOTPAP/FPI-AOTPAP-6.png';
const CFPI = 'Change_of_Personal_Information.png';
const ChangeEmail = 'FPI_Change_Email.png';
const ChangeAdd = 'FPI-Letter_for_address_amendment.png';
const ChangeInv = 'FPI-change-Investors.png';
const ChangeNum = 'FPI-Request_for_Mobile_Number_Change.png';
const NEW_Third_1 = 'NEW_Third/NEW_Third_1.png';
const NEW_Third_2 = 'NEW_Third/NEW_Third_2.png';
const NEW_Third_3 = 'NEW_Third/NEW_Third_3.png';
const NEW_Third_4 = 'NEW_Third/NEW_Third_4.png';
const FPI_68_1 = 'FPI-68/1-FPI - Letter of change premium contribution.png';
const FPI_70_1 = 'FPI-70/1. Top-up Instruction Template.png';
const FPI_70_2 = 'FPI-70/2. Commission Disclosure Declaration-1.png';
const FPI_70_3 = 'FPI-70/2. Commission Disclosure Declaration-2.png';
const FPI_CMEA = 'FPI-Confirmation_of_Monetary_Exchange_Assignment.png';
const FPI_CSA = 'FPI_Change_Servciing_Agent.png';
const FPI_Self = 'FPI-Self_Certification_for_FATCA.png';
const FPI_Adj = 'FPI-Adjustment.png';
const FPI_CPM = 'FPI-changePaymentMode.png';
const FPI_TT = 'FPI-Telegraphic-Transfer.png';
const FPI_TPB = 'FPI-ThirdPaymentBank.png';
const FPI_TPC = 'FPI-ThirdPaymentCredit.png';
const CancelOMA_1 = 'oma_alteration-1.png';
const CancelOMA_2 = 'oma_alteration-2.png';
const ASS_1 = 'assignment/assignment_of_life_policy_1.png';
const ASS_2 = 'assignment/assignment_of_life_policy_2.png';
const ASS_3 = 'assignment/assignment_of_life_policy_3.png';
const ASS_4 = 'assignment/assignment_of_life_policy_4.png';
const ASS_5 = 'assignment/assignment_of_life_policy_5.png';
const ASS_6 = 'assignment/assignment_of_life_policy_6.png';
const BIL_1 = 'BIL/BIL_LIFE_1.png';
const BIL_2 = 'BIL/BIL_LIFE_2.png';
const BIL_3 = 'BIL/BIL_LIFE_3.png';
const BIL_4 = 'BIL/BIL_LIFE_4.png';
const FPI_Lossor_1 = 'FPI-Lossor/FPI-LossorNonReceiptPolicyDocument_1.png';
const FPI_Lossor_2 = 'FPI-Lossor/FPI-LossorNonReceiptPolicyDocument_2.png';
const FPI_Lossor_3 = 'FPI-Lossor/FPI-LossorNonReceiptPolicyDocument_3.png';
const FPI_Lossor_4 = 'FPI-Lossor/FPI-LossorNonReceiptPolicyDocument_4.png';
const FPI_OMA_1 = 'FPI-OMA/FPI-OMA_1.png';
const FPI_OMA_2 = 'FPI-OMA/FPI-OMA_2.png';
const FPI_OMA_3 = 'FPI-OMA/FPI-OMA_3.png';
const FPI_OMA_4 = 'FPI-OMA/FPI-OMA_4.png';
const FPI_Broker = 'FPI-RequestforSendPolicyEndorsementtoBroker.png';
const FPI_ReName = 'FPI-RequestforNameChangeLetter.png';
const GT_Madison = 'GT_Madison_ChangeRequestForm.png';
const GT_FNAC = 'GT-FinancialNeedsAnalysisCover.png';
const VOYA_CPI = 'Name&AddressChangeRequest_Voya_IULGlobalChoice.png';
const Sign_0 = 'specimenSignature-0.png';
const Sign_1 = 'specimenSignature-1.png';
const Sign_2 = 'specimenSignature-2.png';
const Sign_3 = 'specimenSignature-3.png';
const VOYA_TO_1 = 'TransferofOwnership_Voya_IULGlobalChoice-1.png';
const VOYA_TO_2 = 'TransferofOwnership_Voya_IULGlobalChoice-2.png';
const VOYA_TO_3 = 'TransferofOwnership_Voya_IULGlobalChoice-3.png';
const VOYA_BDV_1 = 'VOYA_BDV/BeneficiaryDesignation_Voya_IULGlobalChoice-1.png';
const VOYA_BDV_2 = 'VOYA_BDV/BeneficiaryDesignation_Voya_IULGlobalChoice-2.png';
const VOYA_BDV_3 = 'VOYA_BDV/BeneficiaryDesignation_Voya_IULGlobalChoice-3.png';
const VOYA_BDV_4 = 'VOYA_BDV/BeneficiaryDesignation_Voya_IULGlobalChoice-4.png';
const VOYA_BDV_5 = 'VOYA_BDV/BeneficiaryDesignation_Voya_IULGlobalChoice-5.png';
const IFS_P_1 = 'IFS/IFS_Premier_1.png';
const IFS_P_2 = 'IFS/IFS_Premier_2.png';
const IFS_P_3 = 'IFS/IFS_Premier_3.png';
const IFS_P_4 = 'IFS/IFS_Premier_4.png';
const IFS_P_5 = 'IFS/IFS_Premier_5.png';
const IFS_P_6 = 'IFS/IFS_Premier_6.png';
const IFS_P_7 = 'IFS/IFS_Premier_7.png';
const IFS_P_8 = 'IFS/IFS_Premier_8.png';
const IFS_P_9 = 'IFS/IFS_Premier_9.png';
const IFS_P_10 = 'IFS/IFS_Premier_10.png';
const IFS_P_11 = 'IFS/IFS_Premier_11.png';
const IFS_P_12 = 'IFS/IFS_Premier_12.png';
const IFS_P_U_1 = 'IFS_P_Ultra/8.IFS_Premier ultra_1.png';
const IFS_P_U_2 = 'IFS_P_Ultra/8.IFS_Premier ultra_2.png';
const IFS_P_U_3 = 'IFS_P_Ultra/8.IFS_Premier ultra_3.png';
const IFS_P_U_4 = 'IFS_P_Ultra/8.IFS_Premier ultra_4.png';
const IFS_P_U_5 = 'IFS_P_Ultra/8.IFS_Premier ultra_5.png';
const IFS_P_U_6 = 'IFS_P_Ultra/8.IFS_Premier ultra_6.png';
const IFS_P_U_7 = 'IFS_P_Ultra/8.IFS_Premier ultra_7.png';
const IFS_P_U_8 = 'IFS_P_Ultra/8.IFS_Premier ultra_8.png';
const IFS_P_U_9 = 'IFS_P_Ultra/8.IFS_Premier ultra_9.png';
const IFS_P_U_10 = 'IFS_P_Ultra/8.IFS_Premier ultra_10.png';
const IFS_P_U_11 = 'IFS_P_Ultra/8.IFS_Premier ultra_11.png';
const IFS_P_U_12 = 'IFS_P_Ultra/8.IFS_Premier ultra_12.png';
const update_54_1 = '54_1_update/54_1_update_1.png';
const update_54_2 = '54_1_update/54_1_update_2.png';
const update_54_3 = '54_1_update/54_1_update_3.png';
const update_54_4 = '54_1_update/54_1_update_4.png';
const update_54_5 = '54_1_update/54_1_update_5.png';
const signature_1 = '61_1_signature/61_1_signature_1.png';
const signature_2 = '61_1_signature/61_1_signature_2.png';
const signature_3 = '61_1_signature/61_1_signature_3.png';
const signature_4 = '61_1_signature/61_1_signature_4.png';
const signature_5 = '61_1_signature/61_1_signature_5.png';
const signature_6 = '61_1_signature/61_1_signature_6.png';
const policy_92_1 = '92_policy/92_policy_1.png';
const policy_92_2 = '92_policy/92_policy_2.png';
const policy_92_3 = '92_policy/92_policy_3.png';
const policy_92_4 = '92_policy/92_policy_4.png';
const policy_92_5 = '92_policy/92_policy_5.png';
const policy_92_6 = '92_policy/92_policy_6.png';
const policy_92_7 = '92_policy/92_policy_7.png';
const policy_92_8 = '92_policy/92_policy_8.png';
const policy_92_9 = '92_policy/92_policy_9.png';
const policy_92_10 = '92_policy/92_policy_10.png';
const policy_92_11 = '92_policy/92_policy_11.png';
const policy_92_12 = '92_policy/92_policy_12.png';
const policy_92_13 = '92_policy/92_policy_13.png';

const URL = 'https://hanbury.s3-ap-northeast-1.amazonaws.com/as/';

const a4pageHeight = 841.89;
const a4pageWidth = 595.28;
const a4pageWidthHalf = a4pageWidth / 2;
const a4pageWidthHalf2 = a4pageWidth / 4;
// const basePath = './as';

const getDataUri = (url) => {
  return new Promise(resolve => {
    var image = new Image();
    image.crossOrigin = 'Anonymous';
    image.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = this.naturalWidth;
      canvas.height = this.naturalHeight;
      //next three lines for white background in case png has a transparent background
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = '#fff';  /// set white fill style
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      canvas.getContext('2d').drawImage(this, 0, 0);
      resolve(canvas.toDataURL('image/jpeg'));
      // resolve(canvas.toDataURL('image/png'));
    };
    image.src = URL + url;
  })
}

export const pdfas1 = async (doc, pf) => {
  const wd_1_64 = await getDataUri(wd_1);
  doc.addImage(wd_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFont('Courier');
  // doc.text("Q1234321aaa", 150, 97, { width: 380, align: 'left'} );
  // doc.setFont('BiauKai');
  // doc.text("YU, MEI HUA", 150, 146, { width: 380, align: 'left'} );
  // doc.text("5F, No. 400, Sec.1, Keelung Road, Xinyi District, Taipei City 110, Taiwan", 150, 170, { width: 480, align: 'left'} );
  doc.setFontSize(12);
  doc.text(pf.name_eng || '', 190, 348);
  doc.text(pf.addr1_eng || '', 190, 410, {
    maxWidth: 380,
    align: 'left'
  });
  // Api.strChtEngMix(doc, decodeURI(pf.addr1_eng), 190, 400, 12);
  // doc.setFont('Courier');
  // doc.text(decodeURI(pf.addr1_eng), 190, 400, { width: 380, align: 'left'} );
  doc.text("v", 218, 490);
  doc.text(decodeURI(pf.insertedBy || ''), 190, 565, 12);
  // doc.text(pf.insertedBy || '', 190, 565, { width: 380, align: 'left'} );
  doc.text(pf.account_num || '', 190, 600);
  if (pf.email.length > 20) {
    doc.text(pf.email || '', 190, 635);
  } else {
    doc.text(pf.email || '', 190, 635, {
      maxWidth: 380,
      align: 'left',
      charSpace: 11.2
    });
  };
  doc.text(pf.contactnum || '', 190, 665, {
    maxWidth: 380,
    align: 'left'
  });
  doc.text("info@hanburyifa.com", 190, 725, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.2
  });
  doc.addPage();

  const wd_2_64 = await getDataUri(wd_2);
  doc.addImage(wd_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.withdrawlamount || '', 420, 233);
  // doc.text("v", 190, 297, { width: 380, align: 'left'} );
  /*doc.text("v", 250, 297, { width: 380, align: 'left'} );
  doc.text("v", 325, 297, { width: 380, align: 'left'} );
  doc.text("v", 402, 297, { width: 380, align: 'left'} );
  doc.text("v", 480, 297, { width: 380, align: 'left'} );*/
  doc.text(pf.with_reason || '', 194, 645);
  const wd_3_64 = await getDataUri(wd_3);
  doc.addPage();

  doc.addImage(wd_3_64, 0, 0, a4pageWidth, a4pageHeight);
  /*doc.text("v", 186, 83, { width: 380, align: 'left'} );*/
  // doc.text("v", 186, 128, { width: 380, align: 'left'} );
  doc.text(pf.wdba_bankname || '', 186, 200);
  doc.text(pf.wdba_bankaddr || '', 186, 226);
  doc.text(pf.name_eng || '', 186, 313);
  doc.text(pf.wdba_holdernum || '', 186, 338);
  doc.text(pf.wdba_swift_bic || '', 186, 386);
  doc.text(pf.add_imfo || '', 70, 533);
  const wd_4_64 = await getDataUri(wd_4);
  doc.addPage();

  doc.addImage(wd_4_64, 0, 0, a4pageWidth, a4pageHeight);
  const wd_5_64 = await getDataUri(wd_5);
  doc.addPage();

  doc.addImage(wd_5_64, 0, 0, a4pageWidth, a4pageHeight);
  const wd_6_64 = await getDataUri(wd_6);
  doc.addPage();

  doc.addImage(wd_6_64, 0, 0, a4pageWidth, a4pageHeight);
  const wd_7_64 = await getDataUri(wd_7);
  doc.addPage();

  doc.addImage(wd_7_64, 0, 0, a4pageWidth, a4pageHeight);
  const wd_8_64 = await getDataUri(wd_8);
  doc.addPage();

  doc.addImage(wd_8_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas2 = async (doc, pf) => {
  const wd_1_64 = await getDataUri(wd_1);
  doc.addImage(wd_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFont('Courier');
  // doc.text("Q1234321aaa", 150, 97, { width: 380, align: 'left'} );
  // doc.setFont('BiauKai');
  // doc.text("YU, MEI HUA", 150, 146, { width: 380, align: 'left'} );
  // doc.text("5F, No. 400, Sec.1, Keelung Road, Xinyi District, Taipei City 110, Taiwan", 150, 170, { width: 480, align: 'left'} );
  doc.setFontSize(12);
  doc.text(pf.name_eng || '', 190, 348);
  doc.text(pf.addr1_eng || '', 190, 410, {
    maxWidth: 340,
    align: 'left'
  });
  doc.setFont('Courier');
  // doc.text(decodeURI(pf.addr1_eng), 190, 400, { width: 380, align: 'left'} );
  doc.text("v", 218, 490);
  // doc.text(pf.insertedBy || '', 190, 565, { width: 380, align: 'left'} );
  doc.text(decodeURI(pf.insertedBy || ''), 190, 573);
  doc.text(pf.account_num || '', 190, 605);
  if (pf.email.length > 20) {
    doc.text(pf.email || '', 190, 635, {
      maxWidth: 380,
      align: 'left'
    });
  } else {
    doc.text(pf.email || '', 190, 635, {
      maxWidth: 380,
      align: 'left',
      charSpace: 11.2
    });
  };
  doc.text(pf.contactnum || '', 190, 665);
  doc.text("info@hanburyifa.com", 190, 723, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.2
  });
  doc.addPage();

  const wd_2_64 = await getDataUri(wd_2);
  doc.addImage(wd_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.withdrawlamount || '', 420, 232);
  // doc.text("v", 190, 297, { width: 380, align: 'left'} );
  // doc.text(pf.with_reason || '', 194, 637, { width: 380, align: 'left'} );
  doc.text("As the policy reach its maturity,", 194, 645);
  doc.text("I wish to withdraw all my money for my retirement fund.", 194, 658, {
    maxWidth: 380,
    align: 'left',
  });
  doc.addPage();

  const wd_3_64 = await getDataUri(wd_3);
  doc.addImage(wd_3_64, 0, 0, a4pageWidth, a4pageHeight);
  /*doc.text("v", 186, 83, { width: 380, align: 'left'} );*/
  // doc.text("v", 186, 128, { width: 380, align: 'left'} );
  doc.text(pf.wdba_bankname || '', 186, 200);
  doc.text(pf.wdba_bankaddr || '', 186, 228);
  doc.text(pf.name_eng || '', 186, 313);
  doc.text(pf.wdba_holdernum || '', 186, 338);
  doc.text(pf.wdba_swift_bic || '', 186, 386);
  doc.text(pf.add_imfo || '', 70, 535);
  const wd_4_64 = await getDataUri(wd_4);
  doc.addPage();

  doc.addImage(wd_4_64, 0, 0, a4pageWidth, a4pageHeight);
  const wd_5_64 = await getDataUri(wd_5);
  doc.addPage();

  doc.addImage(wd_5_64, 0, 0, a4pageWidth, a4pageHeight);
  const wd_6_64 = await getDataUri(wd_6);
  doc.addPage();

  doc.addImage(wd_6_64, 0, 0, a4pageWidth, a4pageHeight);
  const wd_7_64 = await getDataUri(wd_7);
  doc.addPage();

  doc.addImage(wd_7_64, 0, 0, a4pageWidth, a4pageHeight);
  const wd_8_64 = await getDataUri(wd_8);
  doc.addPage();

  doc.addImage(wd_8_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas02 = async (doc, pf) => {
  const wd_1_64 = await getDataUri(wd_1);
  doc.addImage(wd_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFont('Courier');
  // doc.text("Q1234321aaa", 150, 97, { width: 380, align: 'left'} );
  // doc.setFont('BiauKai');
  // doc.text("YU, MEI HUA", 150, 146, { width: 380, align: 'left'} );
  // doc.text("5F, No. 400, Sec.1, Keelung Road, Xinyi District, Taipei City 110, Taiwan", 150, 170, { width: 480, align: 'left'} );
  doc.setFontSize(12);
  doc.text(pf.name_eng || '', 190, 348);
  doc.text(pf.addr1_eng || '', 190, 410, {
    maxWidth: 340,
    align: 'left'
  });
  doc.setFont('Courier');
  // doc.text(decodeURI(pf.addr1_eng), 190, 400, { width: 380, align: 'left'} );
  doc.text("v", 218, 490);
  // doc.text(pf.insertedBy || '', 190, 565, { width: 380, align: 'left'} );
  doc.text(decodeURI(pf.insertedBy), 190, 573);
  doc.text(pf.account_num || '', 190, 605);
  if (pf.email.length > 20) {
    doc.text(pf.email || '', 190, 635, {
      maxWidth: 380,
      align: 'left'
    });
  } else {
    doc.text(pf.email || '', 190, 635, {
      maxWidth: 380,
      align: 'left',
      charSpace: 11.2
    });
  };
  doc.text(pf.contactnum || '', 190, 662);
  doc.text("info@hanburyifa.com", 190, 723, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.2
  });
  doc.addPage();

  const wd_2_64 = await getDataUri(wd_2);
  doc.addImage(wd_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.withdrawlamount || '', 420, 232);
  // doc.text("v", 190, 297, { width: 380, align: 'left'} );
  /*doc.text("v", 250, 297, { width: 380, align: 'left'} );
  doc.text("v", 325, 297, { width: 380, align: 'left'} );
  doc.text("v", 402, 297, { width: 380, align: 'left'} );
  doc.text("v", 480, 297, { width: 380, align: 'left'} );*/
  doc.text(pf.with_reason || '', 194, 645);
  doc.addPage();

  const wd_3_64 = await getDataUri(wd_3);
  doc.addImage(wd_3_64, 0, 0, a4pageWidth, a4pageHeight);
  /*doc.text("v", 186, 83, { width: 380, align: 'left'} );*/
  // doc.text("v", 186, 128, { width: 380, align: 'left'} );
  doc.text(pf.wdba_bankname || '', 186, 200);
  doc.text(pf.wdba_bankaddr || '', 186, 228);
  doc.text(pf.name_eng || '', 186, 313);
  doc.text(pf.wdba_holdernum || '', 186, 338);
  doc.text(pf.wdba_swift_bic || '', 186, 386);
  doc.text(pf.add_imfo || '', 70, 535);
  const wd_4_64 = await getDataUri(wd_4);
  doc.addPage();

  doc.addImage(wd_4_64, 0, 0, a4pageWidth, a4pageHeight);
  const wd_5_64 = await getDataUri(wd_5);
  doc.addPage();

  doc.addImage(wd_5_64, 0, 0, a4pageWidth, a4pageHeight);
  const wd_6_64 = await getDataUri(wd_6);
  doc.addPage();

  doc.addImage(wd_6_64, 0, 0, a4pageWidth, a4pageHeight);
  const wd_7_64 = await getDataUri(wd_7);
  doc.addPage();

  doc.addImage(wd_7_64, 0, 0, a4pageWidth, a4pageHeight);
  const wd_8_64 = await getDataUri(wd_8);
  doc.addPage();

  doc.addImage(wd_8_64, 0, 0, a4pageWidth, a4pageHeight);
  /*
      doc.addPage();
  
      doc.addImage(as1_3, 0, 0, {width: a4pageWidth});
      doc.text("Q1234321aaa", 150, 97, { width: 380, align: 'left'} );
      // doc.setFont('BiauKai');
      doc.text("YU, MEI HUA", 150, 146, { width: 380, align: 'left'} );
      doc.text("5F, No. 400, Sec.1, Keelung Road, Xinyi District, Taipei City 110, Taiwan", 150, 170, { width: 480, align: 'left'} );

      doc.addPage();
  
      doc.addImage(as1_4, 0, 0, {width: a4pageWidth});
  */
}
export const pdfas3 = async (doc, pf) => {
  doc.setFont('Courier');
  const BSO_64 = await getDataUri(BSO);
  doc.addImage(BSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text("HSBC", 170, 172);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 95, 190);
  doc.text(pf.hsbc_account_num || '', 50, 276);
  doc.text(pf.policy_currency_type || '', 50, 316);
  doc.text("HSBCHKHHHKH", 340, 316);
  doc.text(pf.name_eng || '', 50, 348);
  // doc.text("IBAN", 340, 340, { width: 380, align: 'left'} );
  doc.text(pf.fpi_now_same_payment || '', 140, 430);
  doc.setFontSize(9);
  doc.text(pf.fpi_now_same_payment_words || '', 340, 430);
  doc.setFontSize(12);
  doc.text(pf.check || '', 50, 472);
  // doc.text("v", 50, 483, { width: 380, align: 'left'} );
  // doc.text("v", 50, 502, { width: 380, align: 'left'} );
  // doc.text("v", 50, 521, { width: 380, align: 'left'} );
  // doc.text("v", 140, 565, { width: 380, align: 'left'} );
  // doc.text("v", 218, 565, { width: 380, align: 'left'} );
  // doc.text("v", 295, 565, { width: 380, align: 'left'} );
  // doc.text("v", 372, 565, { width: 380, align: 'left'} );
  // doc.text(pf.addr1_eng || '', 150, 710, { width: 380, align: 'left'} );
  doc.text(pf.addr1_eng || '', 150, 715, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 50, 812, {
    maxWidth: 380,
    align: 'left',
    charSpace: 14
  });
  doc.addPage();

  const CancelBSO_64 = await getDataUri(CancelBSO);
  doc.addImage(CancelBSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HSBC", 55, 158);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 55, 208);
  doc.text(pf.name_eng || '', 55, 296);
  doc.text(pf.hsbc_account_num || '', 55, 345);
  doc.text(pf.fpi_now_same_payment || '', 430, 397);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 55, 633);
  doc.setFontSize(12);
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 54, 726, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
}
export const pdfas4 = async (doc, pf) => {
  doc.setFont('Courier');
  const BSO_64 = await getDataUri(BSO);
  doc.addImage(BSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text("HSBC", 170, 172);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 95, 190);
  doc.text(pf.hsbc_account_num || '', 50, 276);
  doc.text(pf.policy_currency_type || '', 50, 316);
  doc.text("HSBCHKHHHKH", 340, 316);
  doc.text(pf.name_eng || '', 50, 348);
  // doc.text("IBAN", 340, 340, { width: 380, align: 'left'} );
  doc.text(pf.fpi_now_same_payment || '', 140, 430);
  doc.setFontSize(9);
  doc.text(pf.fpi_now_same_payment_words || '', 340, 430);
  doc.setFontSize(12);
  doc.text(pf.check || '', 50, 472);
  // doc.text("v", 50, 462, { width: 380, align: 'left'} );
  // doc.text("v", 50, 483, { width: 380, align: 'left'} );
  // doc.text("v", 50, 502, { width: 380, align: 'left'} );
  // doc.text("v", 50, 521, { width: 380, align: 'left'} );
  // doc.text("v", 140, 565, { width: 380, align: 'left'} );
  // doc.text("v", 218, 565, { width: 380, align: 'left'} );
  // doc.text("v", 295, 565, { width: 380, align: 'left'} );
  // doc.text("v", 372, 565, { width: 380, align: 'left'} );
  // doc.text(pf.addr1_eng || '', 150, 710, { width: 380, align: 'left'} );
  doc.text(pf.addr1_eng || '', 150, 715, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 50, 812, {
    maxWidth: 380,
    align: 'left',
    charSpace: 14
  });
}
export const pdfas5 = async (doc, pf) => {
  doc.setFont('Courier');
  const CancelBSO_64 = await getDataUri(CancelBSO);
  doc.addImage(CancelBSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HSBC", 55, 158);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 55, 208);
  doc.text(pf.name_eng || '', 55, 296);
  doc.text(pf.hsbc_account_num || '', 55, 345);
  doc.text(pf.fpi_now_same_payment || '', 430, 397);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 55, 633);
  doc.setFontSize(12);
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 54, 726, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
}
export const pdfas6 = async (doc, pf) => {
  doc.setFont('Courier');
  const NEW_1_64 = await getDataUri(NEW_1);
  doc.addImage(NEW_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text(pf.creditcard_bank || '', 223, 315);
  doc.text(pf.creditcard_bankname || '', 223, 338);
  doc.text(pf.creditcard_num || '', 223, 360, {
    maxWidth: 380,
    align: 'left',
    charSpace: 8.5
  });
  doc.text(pf.creditcard_term_month || '', 223, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.5
  });
  doc.text(pf.creditcard_term_year || '', 300, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.5
  });
  doc.text(pf.fpi_now_same_payment || '', 223, 405);
  doc.text(pf.fpi_now_same_payment_words || '', 223, 425);
  doc.text(pf.policy_currency_type || '', 223, 445);
  doc.text(pf.account_num || '', 223, 468);
  doc.text(pf.name_eng || '', 223, 538);
  // Api.strChtEngMix(doc, decodeURI(pf.addr1_eng), 223, 555, 12);
  // doc.text(pf.addr1_eng || '', 223, 560, { width: 320, align: 'left'} );
  doc.text(pf.addr1_eng || '', 223, 563, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.contactnum || '', 223, 638);
  doc.text(pf.email || '', 223, 662);
  doc.addPage();

  const NEW_2_64 = await getDataUri(NEW_2);
  doc.addImage(NEW_2_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas7 = async (doc, pf) => {
  doc.setFont('Courier');
  const NEW_1_64 = await getDataUri(NEW_1);
  doc.addImage(NEW_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text(pf.creditcard_bank || '', 223, 315);
  doc.text(pf.creditcard_bankname || '', 223, 338);
  doc.text(pf.creditcard_num || '', 223, 360, {
    maxWidth: 380,
    align: 'left',
    charSpace: 8.5
  });
  doc.text(pf.creditcard_term_month || '', 223, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.5
  });
  doc.text(pf.creditcard_term_year || '', 300, 382, {
    maxWidth: 380,
    align: 'left',
    characterSpacing: 11.5
  });
  doc.text(pf.fpi_now_same_payment || '', 223, 405);
  doc.text(pf.fpi_now_same_payment_words || '', 223, 425);
  doc.text(pf.policy_currency_type || '', 223, 445);
  doc.text(pf.account_num || '', 223, 468);
  doc.text(pf.name_eng || '', 223, 538);
  // Api.strChtEngMix(doc, decodeURI(pf.addr1_eng), 223, 555, 12);
  doc.text(pf.addr1_eng || '', 223, 563, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.contactnum || '', 223, 638);
  doc.text(pf.email || '', 223, 662);
  doc.addPage();

  const NEW_2_64 = await getDataUri(NEW_2);
  doc.addImage(NEW_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const CancelBSO_64 = await getDataUri(CancelBSO);
  doc.addImage(CancelBSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HSBC", 55, 158);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 55, 208);
  doc.text(pf.name_eng || '', 55, 296);
  doc.text(pf.hsbc_account_num || '', 55, 345);
  doc.text(pf.fpi_now_same_payment || '', 430, 397);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 55, 633);
  doc.setFontSize(12);
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 54, 726, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
}
export const pdfas8 = async (doc, pf) => {
  doc.setFont('Courier');
  doc.setFontSize(10);
  const BIL_1_64 = await getDataUri(BIL_1);
  doc.addImage(BIL_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const BIL_2_64 = await getDataUri(BIL_2);
  doc.addImage(BIL_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("Hong Kong & Shanghai Banking Corporation Limited", 220, 205);
  doc.text("1 Queen's Road Central, Hong Kong", 220, 225);
  doc.text(pf.hsbc_account_num || '', 220, 271);
  doc.text(pf.hsbc_account_name || '', 220, 288);
  doc.text(pf.policy_currency_type || '', 220, 317);
  // doc.text("54" + " " + "12" + " " + "34", 220, 330, { width: 380, align: 'left', characterSpacing: 9} );
  doc.text("HSBCHKHHHKH", 220, 356);
  // doc.text("7", 220, 370, { width: 380, align: 'left'} );
  doc.text("v", 162, 500);
  doc.text(pf.fpi_now_same_payment || '', 220, 500);
  doc.text(pf.fpi_now_same_payment_words || '', 220, 525);
  doc.text(pf.account_num2 || '', 47, 582, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
  doc.text(pf.addr1_eng || '', 220, 704, {
    maxWidth: 300,
    align: 'left',
    lineHeightFactor: 1.8
  });
  doc.addPage();

  const BIL_3_64 = await getDataUri(BIL_3);
  doc.addImage(BIL_3_64, 0, 0, a4pageWidth, a4pageHeight);
  /*doc.text("1", 220, 198, { width: 380, align: 'left'} );
  doc.text("2", 220, 216, { width: 380, align: 'left'} );
  doc.text("3", 220, 261, { width: 380, align: 'left'} );
  doc.text("4", 220, 278, { width: 380, align: 'left'} );
  doc.text("5", 220, 308, { width: 380, align: 'left'} );
  doc.text("54" + " " + "12" + " " + "34", 220, 325, { width: 380, align: 'left', characterSpacing: 5.6} );
  doc.text("6", 220, 346, { width: 380, align: 'left'} );
  doc.text("7", 220, 366, { width: 380, align: 'left'} );
  doc.text("vvvv", 65, 477, { width: 380, align: 'left', characterSpacing: 42} );
  doc.text("8", 260, 477, { width: 380, align: 'left'} );
  doc.text("9", 260, 500, { width: 380, align: 'left'} );
  doc.text("v", 220, 542, { width: 380, align: 'left'} );
  doc.text("v", 498, 542, { width: 380, align: 'left'} );
  doc.text("v", 220, 565, { width: 380, align: 'left'} );
  doc.text("v", 498, 565, { width: 380, align: 'left'} );
  doc.text("12345678901234", 47, 607, { width: 380, align: 'left', characterSpacing: 7.8} );*/
  doc.addPage();

  const BIL_4_64 = await getDataUri(BIL_4);
  doc.addImage(BIL_4_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas9 = async (doc, pf) => {
  doc.setFont('Courier');
  const Reinstatement_64 = await getDataUri(Reinstatement);
  doc.addImage(Reinstatement_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text(pf.account_num || '', 140, 318);
  doc.text("USD 100,000", 165, 350);
  doc.text(pf.email || '', 110, 508);
  doc.text(pf.contactnum || '', 220, 533);
  doc.text(pf.name_eng || '', 160, 702);
  doc.addPage();

  const BSO_64 = await getDataUri(BSO);
  doc.addImage(BSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text("HSBC", 170, 172);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 95, 190);
  doc.text(pf.hsbc_account_num || '', 50, 276);
  doc.text(pf.policy_currency_type || '', 50, 316);
  doc.text("HSBCHKHHHKH", 340, 316);
  doc.text(pf.name_eng || '', 50, 348);
  // doc.text("IBAN", 340, 340, { width: 380, align: 'left'} );
  doc.text(pf.fpi_now_same_payment || '', 140, 430);
  doc.setFontSize(9);
  doc.text(pf.fpi_now_same_payment_words || '', 340, 430);
  doc.setFontSize(12);
  doc.text(pf.check || '', 50, 472);
  // doc.text("v", 50, 462, { width: 380, align: 'left'} );
  // doc.text("v", 50, 483, { width: 380, align: 'left'} );
  // doc.text("v", 50, 502, { width: 380, align: 'left'} );
  // doc.text("v", 50, 521, { width: 380, align: 'left'} );
  // doc.text("v", 140, 565, { width: 380, align: 'left'} );
  // doc.text("v", 218, 565, { width: 380, align: 'left'} );
  // doc.text("v", 295, 565, { width: 380, align: 'left'} );
  // doc.text("v", 372, 565, { width: 380, align: 'left'} );
  // doc.text(pf.addr1_eng || '', 150, 710, { width: 380, align: 'left'} );
  doc.text(pf.addr1_eng || '', 150, 715, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 50, 812, {
    maxWidth: 380,
    align: 'left',
    charSpace: 14
  });
}
export const pdfas10 = async (doc, pf) => {
  doc.setFont('Courier');
  const Reinstatement_64 = await getDataUri(Reinstatement);
  doc.addImage(Reinstatement_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text(pf.account_num || '', 140, 318);
  doc.text("USD 100,000", 165, 350);
  doc.text(pf.email || '', 110, 508);
  doc.text(pf.contactnum || '', 220, 533);
  doc.text(pf.name_eng || '', 160, 702);
  doc.addPage();

  const NEW_1_64 = await getDataUri(NEW_1);
  doc.addImage(NEW_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.creditcard_bank || '', 223, 315);
  doc.text(pf.creditcard_bankname || '', 223, 338);
  doc.text(pf.creditcard_num || '', 223, 360, {
    maxWidth: 380,
    align: 'left',
    charSpace: 8.5
  });
  doc.text(pf.creditcard_term_month || '', 223, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.5
  });
  doc.text(pf.creditcard_term_year || '', 300, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.5
  });
  doc.text(pf.fpi_now_same_payment || '', 223, 405);
  doc.text(pf.fpi_now_same_payment_words || '', 223, 425);
  doc.text(pf.policy_currency_type || '', 223, 445);
  doc.text(pf.account_num || '', 223, 468);
  doc.text(pf.name_eng || '', 223, 538);
  // Api.strChtEngMix(doc, decodeURI(pf.addr1_eng), 223, 555, 12);
  doc.text(pf.addr1_eng || '', 223, 563, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.contactnum || '', 223, 638);
  doc.text(pf.email || '', 223, 662);
  doc.addPage();

  const NEW_2_64 = await getDataUri(NEW_2);
  doc.addImage(NEW_2_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas11 = async (doc, pf) => {
  doc.setFont('Courier');
  const Reinstatement_64 = await getDataUri(Reinstatement);
  doc.addImage(Reinstatement_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text(pf.account_num || '', 140, 318);
  doc.text("USD 100,000", 165, 350);
  doc.text(pf.email || '', 110, 508);
  doc.text(pf.contactnum || '', 220, 533);
  doc.text(pf.name_eng || '', 160, 702);
  doc.addPage();

  const BIL_1_64 = await getDataUri(BIL_1);
  doc.addImage(BIL_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(10);
  doc.addPage();

  const BIL_2_64 = await getDataUri(BIL_2);
  doc.addImage(BIL_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("Hong Kong & Shanghai Banking Corporation Limited", 220, 205);
  doc.text("1 Queen's Road Central, Hong Kong", 220, 225);
  doc.text(pf.hsbc_account_num || '', 220, 271);
  doc.text(pf.hsbc_account_name || '', 220, 288);
  doc.text(pf.policy_currency_type || '', 220, 317);
  // doc.text("54" + " " + "12" + " " + "34", 220, 330, { width: 380, align: 'left', characterSpacing: 9} );
  doc.text("HSBCHKHHHKH", 220, 356);
  // doc.text("7", 220, 370, { width: 380, align: 'left'} );
  doc.text("v", 162, 500);
  doc.text(pf.fpi_now_same_payment || '', 220, 500);
  doc.text(pf.fpi_now_same_payment_words || '', 220, 525);
  doc.text(pf.account_num2 || '', 47, 582, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
  doc.text(pf.addr1_eng || '', 220, 704, {
    maxWidth: 300,
    align: 'left',
    lineHeightFactor: 1.8
  });
  doc.addPage();

  const BIL_3_64 = await getDataUri(BIL_3);
  doc.addImage(BIL_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const BIL_4_64 = await getDataUri(BIL_4);
  doc.addImage(BIL_4_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas12 = async (doc, pf) => {
  doc.setFont('Courier');
  const Reinstatement_64 = await getDataUri(Reinstatement);
  doc.addImage(Reinstatement_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text(pf.account_num || '', 140, 318);
  doc.text("USD 100,000", 165, 350);
  doc.text(pf.email || '', 110, 508);
  doc.text(pf.contactnum || '', 220, 533);
  doc.text(pf.name_eng || '', 160, 702);
  doc.addPage();

  const NEW_1_64 = await getDataUri(NEW_1);
  doc.addImage(NEW_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.creditcard_bank || '', 223, 315);
  doc.text(pf.creditcard_bankname || '', 223, 338);
  doc.text(pf.creditcard_num || '', 223, 360, {
    maxWidth: 380,
    align: 'left',
    charSpace: 8.5
  });
  doc.text(pf.creditcard_term_month || '', 223, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.5
  });
  doc.text(pf.creditcard_term_year || '', 300, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.5
  });
  doc.text(pf.fpi_now_same_payment || '', 223, 405);
  doc.text(pf.fpi_now_same_payment_words || '', 223, 425);
  doc.text(pf.policy_currency_type || '', 223, 445);
  doc.text(pf.account_num || '', 223, 468);
  doc.text(pf.name_eng || '', 223, 538);
  // Api.strChtEngMix(doc, decodeURI(pf.addr1_eng), 223, 555, 12);
  doc.text(pf.addr1_eng || '', 223, 563, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.contactnum || '', 223, 638);
  doc.text(pf.email || '', 223, 662);
  doc.addPage();

  const NEW_2_64 = await getDataUri(NEW_2);
  doc.addImage(NEW_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const BIL_1_64 = await getDataUri(BIL_1);
  doc.addImage(BIL_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(10);
  doc.addPage();

  const BIL_2_64 = await getDataUri(BIL_2);
  doc.addImage(BIL_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("Hong Kong & Shanghai Banking Corporation Limited", 220, 205);
  doc.text("1 Queen's Road Central, Hong Kong", 220, 225);
  doc.text(pf.hsbc_account_num || '', 220, 271);
  doc.text(pf.hsbc_account_name || '', 220, 288);
  doc.text(pf.policy_currency_type || '', 220, 317);
  // doc.text("54" + " " + "12" + " " + "34", 220, 330, { width: 380, align: 'left', characterSpacing: 9} );
  doc.text("HSBCHKHHHKH", 220, 356);
  // doc.text("7", 220, 370, { width: 380, align: 'left'} );
  doc.text("v", 162, 500);
  doc.text(pf.fpi_now_same_payment || '', 220, 500);
  doc.text(pf.fpi_now_same_payment_words || '', 220, 525);
  doc.text(pf.account_num2 || '', 47, 582, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
  doc.text(pf.addr1_eng || '', 220, 704, {
    maxWidth: 300,
    align: 'left',
    lineHeightFactor: 1.8
  });
  doc.addPage();

  const BIL_3_64 = await getDataUri(BIL_3);
  doc.addImage(BIL_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const BIL_4_64 = await getDataUri(BIL_4);
  doc.addImage(BIL_4_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas13 = async (doc, pf) => {
  doc.setFont('Courier');
  const Reinstatement_64 = await getDataUri(Reinstatement);
  doc.addImage(Reinstatement_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text(pf.account_num || '', 140, 318);
  doc.text("USD 100,000", 165, 350);
  doc.text(pf.email || '', 110, 508);
  doc.text(pf.contactnum || '', 220, 533);
  doc.text(pf.name_eng || '', 160, 702);
  doc.addPage();

  const NEW_1_64 = await getDataUri(NEW_1);
  doc.addImage(NEW_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.creditcard_bank || '', 223, 315);
  doc.text(pf.creditcard_bankname || '', 223, 338);
  doc.text(pf.creditcard_num || '', 223, 360, {
    maxWidth: 380,
    align: 'left',
    charSpace: 8.5
  });
  doc.text(pf.creditcard_term_month || '', 223, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.5
  });
  doc.text(pf.creditcard_term_year || '', 300, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.5
  });
  doc.text(pf.fpi_now_same_payment || '', 223, 405);
  doc.text(pf.fpi_now_same_payment_words || '', 223, 425);
  doc.text(pf.policy_currency_type || '', 223, 445);
  doc.text(pf.account_num || '', 223, 468);
  doc.text(pf.name_eng || '', 223, 538);
  // Api.strChtEngMix(doc, decodeURI(pf.addr1_eng), 223, 555, 12);
  doc.text(pf.addr1_eng || '', 223, 563, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.contactnum || '', 223, 638);
  doc.text(pf.email || '', 223, 662);
  doc.addPage();

  const NEW_2_64 = await getDataUri(NEW_2);
  doc.addImage(NEW_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const BIL_1_64 = await getDataUri(BIL_1);
  doc.addImage(BIL_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(10);
  doc.addPage();

  const BIL_2_64 = await getDataUri(BIL_2);
  doc.addImage(BIL_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("Hong Kong & Shanghai Banking Corporation Limited", 220, 205);
  doc.text("1 Queen's Road Central, Hong Kong", 220, 225);
  doc.text(pf.hsbc_account_num || '', 220, 271);
  doc.text(pf.hsbc_account_name || '', 220, 288);
  doc.text(pf.policy_currency_type || '', 220, 317);
  // doc.text("54" + " " + "12" + " " + "34", 220, 330, { width: 380, align: 'left', characterSpacing: 9} );
  doc.text("HSBCHKHHHKH", 220, 356);
  // doc.text("7", 220, 370, { width: 380, align: 'left'} );
  doc.text("v", 162, 500);
  doc.text(pf.fpi_now_same_payment || '', 220, 500);
  doc.text(pf.fpi_now_same_payment_words || '', 220, 525);
  doc.text(pf.account_num2 || '', 47, 582, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
  doc.text(pf.addr1_eng || '', 220, 704, {
    maxWidth: 300,
    align: 'left',
    lineHeightFactor: 1.8
  });
  doc.addPage();

  const BIL_3_64 = await getDataUri(BIL_3);
  doc.addImage(BIL_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const BIL_4_64 = await getDataUri(BIL_4);
  doc.addImage(BIL_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const CancelBSO_64 = await getDataUri(CancelBSO);
  doc.addImage(CancelBSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HSBC", 55, 158);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 55, 208);
  doc.text(pf.name_eng || '', 55, 296);
  doc.text(pf.hsbc_account_num || '', 55, 345);
  doc.text(pf.fpi_now_same_payment || '', 430, 397);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 55, 633);
  doc.setFontSize(12);
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 54, 726, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
}
export const pdfas14 = async (doc, pf) => {
  doc.setFont('Courier');
  const paidUp_64 = await getDataUri(paidUp);
  doc.addImage(paidUp_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text(pf.account_num || '', 185, 330);
  // doc.text("12/1999", 185, 343, { width: 380, align: 'left'} );
  doc.text("personal reason", 170, 375);
  doc.text(pf.email || '', 130, 450);
  doc.text(pf.contactnum || '', 360, 465);
  doc.text(pf.name_eng || '', 160, 572);
  doc.addPage();

  doc.setFont('Courier');
  const CancelBSO_64 = await getDataUri(CancelBSO);
  doc.addImage(CancelBSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HSBC", 55, 158);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 55, 208);
  doc.text(pf.name_eng || '', 55, 296);
  doc.text(pf.hsbc_account_num || '', 55, 345);
  doc.text(pf.fpi_now_same_payment || '', 430, 397);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 55, 633);
  doc.setFontSize(12);
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 54, 726, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
}
export const pdfas15 = async (doc, pf) => {
  doc.setFont('Courier');
  const paidUp_64 = await getDataUri(paidUp);
  doc.addImage(paidUp_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text(pf.account_num || '', 185, 330);
  // doc.text("12/1999", 185, 343, { width: 380, align: 'left'} );
  doc.text(pf.custom_1 || '', 170, 375);
  doc.text(pf.email || '', 130, 450);
  doc.text(pf.contactnum || '', 360, 465);
  doc.text(pf.name_eng || '', 160, 572);
}
export const pdfas16 = async (doc, pf) => {
  /*  const doc = new PDFDocument({
      size: 'A4',
      margin: 1
    });
    doc.registerFont('BiauKai', BiauKaiTTF);
    doc.registerFont('msjh', msjhTTF); */
  doc.setFont('Courier');
  const premium_64 = await getDataUri(premium);
  doc.addImage(premium_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFont('Helvetica');
  doc.setFontSize(12);
  doc.text(pf.account_num || '', 180, 295);
  doc.text(pf.email || '', 130, 500);
  doc.text(pf.contactnum || '', 360, 513);
  doc.text(pf.name_eng || '', 160, 610);
  doc.addPage();

  doc.setFont('Courier');
  const CancelBSO_64 = await getDataUri(CancelBSO);
  doc.addImage(CancelBSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HSBC", 55, 158);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 55, 208);
  doc.text(pf.name_eng || '', 55, 296);
  doc.text(pf.hsbc_account_num || '', 55, 345);
  doc.text(pf.fpi_now_same_payment || '', 430, 397);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 55, 633);
  doc.setFontSize(12);
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 54, 726, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
}
export const pdfas17 = async (doc, pf) => {
  /*  const doc = new PDFDocument({
      size: 'A4',
      margin: 1
    });
    doc.registerFont('BiauKai', BiauKaiTTF);
    doc.registerFont('msjh', msjhTTF); */
  doc.setFont('Courier');
  const premium_64 = await getDataUri(premium);
  doc.addImage(premium_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFont('Helvetica');
  doc.setFontSize(12);
  doc.text(pf.account_num || '', 180, 295);
  doc.text(pf.email || '', 130, 500);
  doc.text(pf.contactnum || '', 360, 513);
  doc.text(pf.name_eng || '', 160, 610);
}
export const pdfas18 = async (doc, pf) => {
  doc.setFont('Courier');
  const blank_64 = await getDataUri(blank);
  doc.addImage(blank_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(13);
  doc.text(pf.account_num || '', 355, 308);
  doc.text(pf.custom_1 || '', 440, 338);
  doc.text(pf.custom_2 || '', 290, 366);
  doc.text(pf.fpi_now_same_payment || '', 430, 366);
  doc.text(pf.name_eng || '', 150, 723);
  doc.text(pf.account_num || '', 150, 743);
  doc.addPage();

  const NEW_1_64 = await getDataUri(NEW_1);
  doc.addImage(NEW_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.creditcard_bank || '', 223, 315);
  doc.text(pf.creditcard_bankname || '', 223, 338);
  doc.text(pf.creditcard_num || '', 223, 360, {
    maxWidth: 380,
    align: 'left',
    charSpace: 8.5
  });
  doc.text(pf.creditcard_term_month || '', 223, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.5
  });
  doc.text(pf.creditcard_term_year || '', 300, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.5
  });
  doc.text(pf.fpi_now_same_payment || '', 223, 405);
  doc.text(pf.fpi_now_same_payment_words || '', 223, 425);
  doc.text(pf.policy_currency_type || '', 223, 445);
  doc.text(pf.account_num || '', 223, 468);
  doc.text(pf.name_eng || '', 223, 538);
  // Api.strChtEngMix(doc, decodeURI(pf.addr1_eng), 223, 555, 12);
  doc.text(pf.addr1_eng || '', 223, 563, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.contactnum || '', 223, 638);
  doc.text(pf.email || '', 223, 662);
  doc.addPage();

  const NEW_2_64 = await getDataUri(NEW_2);
  doc.addImage(NEW_2_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas19 = async (doc, pf) => {
  doc.setFont('Courier');
  const blank_64 = await getDataUri(blank);
  doc.addImage(blank_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(13);
  doc.text(pf.account_num || '', 355, 308);
  doc.text(pf.custom_1 || '', 440, 338);
  doc.text(pf.custom_2 || '', 290, 366);
  doc.text(pf.fpi_now_same_payment || '', 430, 366);
  doc.text(pf.name_eng || '', 150, 723);
  doc.text(pf.account_num || '', 150, 743);
  doc.addPage();

  const NEW_1_64 = await getDataUri(NEW_1);
  doc.addImage(NEW_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.creditcard_bank || '', 223, 315);
  doc.text(pf.creditcard_bankname || '', 223, 338);
  doc.text(pf.creditcard_num || '', 223, 360, {
    maxWidth: 380,
    align: 'left',
    charSpace: 8.5
  });
  doc.text(pf.creditcard_term_month || '', 223, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.5
  });
  doc.text(pf.creditcard_term_year || '', 300, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.5
  });
  doc.text(pf.fpi_now_same_payment || '', 223, 405);
  doc.text(pf.fpi_now_same_payment_words || '', 223, 425);
  doc.text(pf.policy_currency_type || '', 223, 445);
  doc.text(pf.account_num || '', 223, 468);
  doc.text(pf.name_eng || '', 223, 538);
  // Api.strChtEngMix(doc, decodeURI(pf.addr1_eng), 223, 555, 12);
  doc.text(pf.addr1_eng || '', 223, 563, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.contactnum || '', 223, 638);
  doc.text(pf.email || '', 223, 662);
  doc.addPage();

  const NEW_2_64 = await getDataUri(NEW_2);
  doc.addImage(NEW_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const CancelBSO_64 = await getDataUri(CancelBSO);
  doc.addImage(CancelBSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HSBC", 55, 158);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 55, 208);
  doc.text(pf.name_eng || '', 55, 296);
  doc.text(pf.hsbc_account_num || '', 55, 345);
  doc.text(pf.fpi_now_same_payment || '', 430, 397);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 55, 633);
  doc.setFontSize(12);
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 54, 726, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
}
export const pdfas20 = async (doc, pf) => {
  doc.setFont('Courier');
  const blank_64 = await getDataUri(blank);
  doc.addImage(blank_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(13);
  doc.text(pf.account_num || '', 355, 308);
  doc.text(pf.custom_1 || '', 440, 338);
  doc.text(pf.custom_2 || '', 290, 366);
  doc.text(pf.fpi_now_same_payment || '', 430, 366);
  doc.text(pf.name_eng || '', 150, 723);
  doc.text(pf.account_num || '', 150, 743);
  doc.addPage();

  doc.setFont('Courier');
  const BSO_64 = await getDataUri(BSO);
  doc.addImage(BSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text("HSBC", 170, 172);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 95, 190);
  doc.text(pf.hsbc_account_num || '', 50, 276);
  doc.text(pf.policy_currency_type || '', 50, 316);
  doc.text("HSBCHKHHHKH", 340, 316);
  doc.text(pf.name_eng || '', 50, 348);
  // doc.text("IBAN", 340, 340, { width: 380, align: 'left'} );
  doc.text(pf.fpi_now_same_payment || '', 140, 430);
  doc.setFontSize(9);
  doc.text(pf.fpi_now_same_payment_words || '', 340, 430);
  doc.setFontSize(12);
  doc.text(pf.check || '', 50, 472);
  // doc.text("v", 50, 462, { width: 380, align: 'left'} );
  // doc.text("v", 50, 483, { width: 380, align: 'left'} );
  // doc.text("v", 50, 502, { width: 380, align: 'left'} );
  // doc.text("v", 50, 521, { width: 380, align: 'left'} );
  // doc.text("v", 140, 565, { width: 380, align: 'left'} );
  // doc.text("v", 218, 565, { width: 380, align: 'left'} );
  // doc.text("v", 295, 565, { width: 380, align: 'left'} );
  // doc.text("v", 372, 565, { width: 380, align: 'left'} );
  // doc.text(pf.addr1_eng || '', 150, 710, { width: 380, align: 'left'} );
  doc.text(pf.addr1_eng || '', 150, 715, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 50, 812, {
    maxWidth: 380,
    align: 'left',
    charSpace: 14
  });
  doc.addPage();

  const CancelBSO_64 = await getDataUri(CancelBSO);
  doc.addImage(CancelBSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HSBC", 55, 158);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 55, 208);
  doc.text(pf.name_eng || '', 55, 296);
  doc.text(pf.hsbc_account_num || '', 55, 345);
  doc.text(pf.fpi_now_same_payment || '', 430, 397);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 55, 633);
  doc.setFontSize(12);
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 54, 726, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
}
export const pdfas21 = async (doc, pf) => {
  doc.setFont('Courier');
  const blank_64 = await getDataUri(blank);
  doc.addImage(blank_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(13);
  doc.text(pf.account_num || '', 355, 308);
  doc.text(pf.custom_1 || '', 440, 339);
  doc.text(pf.custom_2 || '', 290, 366);
  doc.text(pf.fpi_now_same_payment || '', 430, 366);
  doc.text(pf.name_eng || '', 150, 723);
  doc.text(pf.account_num || '', 150, 743);
  doc.addPage();

  const CancelBSO_64 = await getDataUri(CancelBSO);
  doc.addImage(CancelBSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HSBC", 55, 158);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 55, 208);
  doc.text(pf.name_eng || '', 55, 296);
  doc.text(pf.hsbc_account_num || '', 55, 345);
  doc.text(pf.fpi_now_same_payment || '', 430, 398);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 55, 625);
  doc.setFontSize(12);
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 54, 726, {
    width: 380,
    align: 'left',
    charSpace: 11
  });
}
export const pdfas22 = async (doc, pf) => {
  doc.setFont('Courier');
  const blank_64 = await getDataUri(blank);
  doc.addImage(blank_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(13);
  doc.text(pf.account_num || '', 355, 308);
  doc.text(pf.custom_1 || '', 440, 339);
  doc.text(pf.custom_2 || '', 290, 366);
  doc.text(pf.fpi_now_same_payment || '', 430, 366);
  doc.text(pf.name_eng || '', 150, 723);
  doc.text(pf.account_num || '', 150, 743);
  doc.addPage();

  doc.setFont('Courier');
  const BSO_64 = await getDataUri(BSO);
  doc.addImage(BSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text("HSBC", 170, 172);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 95, 190);
  doc.text(pf.hsbc_account_num || '', 50, 276);
  doc.text(pf.policy_currency_type || '', 50, 316);
  doc.text("HSBCHKHHHKH", 340, 316);
  doc.text(pf.name_eng || '', 50, 348);
  // doc.text("IBAN", 340, 340, { width: 380, align: 'left'} );
  doc.text(pf.fpi_now_same_payment || '', 140, 430);
  doc.setFontSize(9);
  doc.text(pf.fpi_now_same_payment_words || '', 340, 430);
  doc.setFontSize(12);
  doc.text(pf.check || '', 50, 472);
  // doc.text("v", 50, 462, { width: 380, align: 'left'} );
  // doc.text("v", 50, 483, { width: 380, align: 'left'} );
  // doc.text("v", 50, 502, { width: 380, align: 'left'} );
  // doc.text("v", 50, 521, { width: 380, align: 'left'} );
  // doc.text("v", 140, 565, { width: 380, align: 'left'} );
  // doc.text("v", 218, 565, { width: 380, align: 'left'} );
  // doc.text("v", 295, 565, { width: 380, align: 'left'} );
  // doc.text("v", 372, 565, { width: 380, align: 'left'} );
  // doc.text(pf.addr1_eng || '', 150, 710, { width: 380, align: 'left'} );
  doc.text(pf.addr1_eng || '', 150, 715, {
    width: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 50, 812, {
    width: 380,
    align: 'left',
    charSpace: 14
  });
}
export const pdfas23 = async (doc, pf) => {
  doc.setFont('Courier');
  const blank_64 = await getDataUri(blank);
  doc.addImage(blank_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(13);
  doc.text(pf.account_num || '', 355, 308);
  doc.text(pf.custom_1 || '', 440, 339);
  doc.text(pf.custom_2 || '', 290, 366);
  doc.text(pf.fpi_now_same_payment || '', 430, 366);
  doc.text(pf.name_eng || '', 150, 723);
  doc.text(pf.account_num || '', 150, 743);
}
export const pdfas24 = async (doc, pf) => {
  doc.setFont('Courier');
  doc.setFontSize(8);
  const ChangeAdd_64 = await getDataUri(ChangeAdd);
  doc.addImage(ChangeAdd_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 105, 264);
  doc.text(pf.account_num || '', 188, 264);
  // doc.text(pf.addr1_eng || '', 95, 340, { width: 380, align: 'left'} );
  doc.text(decodeURI(pf.addr1_eng), 95, 350);
  // doc.text(pf.addr1_eng || '', 95, 410, { width: 380, align: 'left'} );
  doc.text(pf.name_eng || '', 95, 640);
}
export const pdfas25 = async (doc, pf) => {
  doc.setFont('Courier');
  doc.setFontSize(12);
  const ChangeNum_64 = await getDataUri(ChangeNum);
  doc.addImage(ChangeNum_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.account_num || '', 190, 261);
  doc.text(pf.name_eng || '', 190, 297);
  doc.text(pf.contactnum || '', 190, 369);
}
export const pdfas26 = async (doc, pf) => {
  doc.setFont('Courier');
  doc.setFontSize(20);
  const CFPI_64 = await getDataUri(CFPI);
  doc.addImage(CFPI_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text(pf.last_name || '', 155, 420, { width: 380, align: 'left'} );
  doc.text(pf.last_name || '', 155, 425);
  doc.text(pf.first_name || '', 370, 425);
  doc.setFont('Courier');
  // Api.strChtEngMix(doc, decodeURI(pf.last_name), 155, 420, 12);
  // doc.text(pf.first_name || '', 370, 420, { width: 380, align: 'left'} );
  doc.text(pf.email || '', 155, 465);
  doc.text(pf.contactnum || '', 155, 505);
  doc.text(pf.birthday || '', 155, 540);
  doc.text(pf.identify || '', 370, 540);
}
export const pdfas27 = async (doc, pf) => {
  doc.setFont('Courier');
  doc.setFontSize(12);
  const GT_Madison_64 = await getDataUri(GT_Madison);
  doc.addImage(GT_Madison_64, 0, 0, a4pageWidth, a4pageHeight);

  doc.text(pf.name_eng || '', 215, 220);
  doc.text(pf.insertedBy_CE || '', 215, 257);
  doc.setFont('Courier');
  doc.text(pf.account_num || '', 215, 275);
  doc.text(pf.beneficiary_bank_name || '', 215, 368);
  doc.text(pf.name_eng || '', 215, 385);
  doc.text(pf.beneficiary_account_number || '', 215, 402);
  doc.text(pf.swift_code || '', 215, 421);
  doc.text(pf.beneficiary_bank_address || '', 215, 457);
  doc.text(pf.custom_1 || '', 215, 546);
  doc.text(pf.custom_2 || '', 215, 583);
}
export const pdfas28 = async (doc, pf) => {
  doc.setFont('Courier');
  doc.setFontSize(10);
  const ChangeEmail_64 = await getDataUri(ChangeEmail);
  doc.addImage(ChangeEmail_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || "(empty)", 107, 298);
  doc.text(pf.account_num || '', 298, 298);
  doc.text(pf.email || "(empty)", 185, 319);
  doc.text(pf.account_num || '', 175, 696);
  doc.text(pf.name_eng || "(empty)", 175, 715);
}
export const pdfas29 = async (doc, pf) => {
  doc.setFont('Courier');
  doc.setFontSize(10);
  const CancelOMA_1_64 = await getDataUri(CancelOMA_1);
  doc.addImage(CancelOMA_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 220, 302);
  doc.text(pf.insertedBy || '', 220, 348);
  // doc.text(pf.account_num || '', 220, 387, { width: 380, align: 'left'} );
  doc.text(pf.account_num || '', 220, 371);
  doc.addPage();

  const CancelOMA_2_64 = await getDataUri(CancelOMA_2);
  doc.addImage(CancelOMA_2_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas30 = async (doc, pf) => {
  doc.setFont('Courier');
  doc.setFontSize(10);
  const FPI_Lossor_1_64 = await getDataUri(FPI_Lossor_1);
  doc.addImage(FPI_Lossor_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_Lossor_2_64 = await getDataUri(FPI_Lossor_2);
  doc.addImage(FPI_Lossor_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.account_num || '', 150, 100, {
    maxWidth: 420,
    align: 'left'
  });
  doc.text(pf.name_eng || '', 150, 153);
  doc.text(pf.addr1_eng || "add", 150, 176);
  // Api.strChtEngMix(doc, decodeURI(pf.addr1_eng), 150, 170, 12);
  doc.addPage();

  doc.setFont('Courier');
  const FPI_Lossor_3_64 = await getDataUri(FPI_Lossor_3);
  doc.addImage(FPI_Lossor_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.account_num || '', 150, 101, {
    maxWidth: 420,
    align: 'left'
  });
  doc.text(pf.name_eng || '', 150, 150);
  doc.text(pf.addr1_eng || "add", 150, 175, {
    maxWidth: 420,
    align: 'left'
  });
  // Api.strChtEngMix(doc, decodeURI(pf.addr1_eng), 150, 167, 12);
  doc.text(pf.name_eng || '', 150, 209);
  doc.text(pf.addr1_eng || "add", 150, 233, {
    maxWidth: 420,
    align: 'left'
  });
  // Api.strChtEngMix(doc, decodeURI(pf.addr1_eng), 150, 225, 12);
  doc.addPage();

  const FPI_Lossor_4_64 = await getDataUri(FPI_Lossor_4);
  doc.addImage(FPI_Lossor_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_Broker_64 = await getDataUri(FPI_Broker);
  doc.addImage(FPI_Broker_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.account_num || '', 190, 260);
  doc.text(pf.name_eng || '', 190, 296);
}
export const pdfas31 = async (doc, pf) => {
  doc.setFont('Courier');
  doc.setFontSize(18);
  const FPI_OMA_1_64 = await getDataUri(FPI_OMA_1);
  doc.addImage(FPI_OMA_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 280, 309);
  // doc.text(pf.insertedBy || '', 280, 341, { width: 380, align: 'left'} );
  doc.text(decodeURI(pf.insertedBy), 280, 350);
  doc.text(pf.account_num || '', 280, 391);
  doc.addPage();

  const FPI_OMA_2_64 = await getDataUri(FPI_OMA_2);
  doc.addImage(FPI_OMA_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_OMA_3_64 = await getDataUri(FPI_OMA_3);
  doc.addImage(FPI_OMA_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_OMA_4_64 = await getDataUri(FPI_OMA_4);
  doc.addImage(FPI_OMA_4_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas32 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_ReName_64 = await getDataUri(FPI_ReName);
  doc.addImage(FPI_ReName_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas33 = async (doc, pf) => {
  doc.setFont('Courier');
  doc.setFontSize(12);
  const Sign_0_64 = await getDataUri(Sign_0);
  doc.addImage(Sign_0_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.account_num || '', 190, 260);
  doc.text(pf.custom_1 || '', 190, 296);
  doc.text(pf.custom_1 || '', 220, 366);
  doc.text(pf.name_eng || '', 220, 404);
  doc.text("Person Reason", 220, 442);
  doc.addPage();

  const Sign_1_64 = await getDataUri(Sign_1);
  doc.addImage(Sign_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.account_num || '', 180, 611);
  doc.text(pf.name_eng || '', 180, 647);
  doc.text(pf.addr1_eng || '', 180, 670);
  doc.addPage();

  const Sign_2_64 = await getDataUri(Sign_2);
  doc.addImage(Sign_2_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text(pf.last_name || '', 190, 390, { width: 380, align: 'left'} );
  // doc.text(pf.first_name || '', 190, 410, { width: 380, align: 'left'} );
  doc.text(pf.last_name || '', 190, 394);
  doc.text(pf.first_name || '', 190, 415, {
    maxWidth: 180,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.addr1_eng || '', 190, 452, {
    maxWidth: 180,
    align: 'left'
  });
  doc.addPage();

  const Sign_3_64 = await getDataUri(Sign_3);
  doc.addImage(Sign_3_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas34 = async (doc, pf) => {
  doc.setFont('Courier');
  doc.setFontSize(12);
  const ASS_1_64 = await getDataUri(ASS_1);
  doc.addImage(ASS_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.policy_firstperson || '', 225, 429);
  // doc.text("2", 225, 440, { width: 380, align: 'left'} );
  // doc.text(pf.addr1_eng || '', 225, 460, { width: 380, align: 'left'} );
  doc.setFontSize(10);
  doc.text(pf.addr1_eng || '', 225, 468, {
    maxWidth: 140,
    align: 'left'
  });

  doc.setFontSize(12);
  doc.text(pf.account_num || '', 225, 521);
  // doc.text("5", 225, 537, { width: 380, align: 'left'} );
  doc.text(pf.policy_secondperson || '', 400, 429);
  // doc.text("7", 400, 440, { width: 380, align: 'left'} );
  doc.setFontSize(10);
  doc.text(pf.addr1_eng || '', 400, 468, {
    maxWidth: 140,
    align: 'left'
  });
  doc.setFontSize(12);
  doc.text(pf.account_num || '', 400, 521);
  // doc.text("0", 400, 537, { width: 380, align: 'left'} );
  doc.text(pf.custom_1 || '', 225, 621);
  // doc.text("2", 225, 633, { width: 380, align: 'left'} );
  doc.text(pf.custom_3 || '', 225, 659);
  // doc.text("4", 225, 708, { width: 380, align: 'left'} );
  doc.text("v", 237, 737);
  // doc.text("v", 282, 730, { width: 380, align: 'left'} );
  // doc.text("5", 225, 753, { width: 380, align: 'left'} );
  doc.text(pf.custom_2 || '', 400, 621);
  // doc.text("7", 400, 633, { width: 380, align: 'left'} );
  doc.text(pf.custom_4 || '', 400, 659);
  // doc.text("9", 400, 708, { width: 380, align: 'left'} );
  // doc.text("v", 412, 730, { width: 380, align: 'left'} );
  // doc.text("v", 458, 730, { width: 380, align: 'left'} );
  // doc.text("0", 400, 753, { width: 380, align: 'left'} );
  doc.addPage();

  const ASS_2_64 = await getDataUri(ASS_2);
  doc.addImage(ASS_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.custom_5 || '', 225, 166);
  doc.text(pf.custom_6 || '', 267, 191);
  doc.text(pf.custom_7 || '', 297, 214);
  // doc.text("4", 265, 230, { width: 380, align: 'left'} );
  // doc.text("5", 300, 252, { width: 380, align: 'left'} );
  // doc.text("6", 265, 274, { width: 380, align: 'left'} );
  // doc.text("7", 300, 296, { width: 380, align: 'left'} );
  // doc.text("1", 400, 160, { width: 380, align: 'left'} );
  // doc.text("2", 440, 185, { width: 380, align: 'left'} );
  // doc.text("3", 475, 208, { width: 380, align: 'left'} );
  // doc.text("4", 440, 230, { width: 380, align: 'left'} );
  // doc.text("5", 475, 252, { width: 380, align: 'left'} );
  // doc.text("6", 440, 274, { width: 380, align: 'left'} );
  // doc.text("7", 475, 296, { width: 380, align: 'left'} );
  doc.text(pf.custom_6 || '', 265, 398);
  doc.text(pf.custom_7 || '', 297, 420);
  // doc.text("4", 265, 435, { width: 380, align: 'left'} );
  // doc.text("5", 300, 457, { width: 380, align: 'left'} );
  // doc.text("6", 265, 479, { width: 380, align: 'left'} );
  // doc.text("7", 300, 501, { width: 380, align: 'left'} );
  // doc.text("2", 440, 390, { width: 380, align: 'left'} );
  // doc.text("3", 475, 413, { width: 380, align: 'left'} );
  // doc.text("4", 440, 435, { width: 380, align: 'left'} );
  // doc.text("5", 475, 457, { width: 380, align: 'left'} );
  // doc.text("6", 440, 479, { width: 380, align: 'left'} );
  // doc.text("7", 475, 501, { width: 380, align: 'left'} );
  doc.text(pf.custom_8 || '', 225, 569);
  // doc.text("asd", 400, 560, { width: 380, align: 'left'} );
  doc.addPage();

  const ASS_3_64 = await getDataUri(ASS_3);
  doc.addImage(ASS_3_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("1", 320, 108, { width: 380, align: 'left'} );
  // doc.text("2", 430, 108, { width: 380, align: 'left'} );
  // doc.text("3", 500, 108, { width: 380, align: 'left'} );
  doc.text(pf.policy_firstperson || '', 220, 198);
  doc.text(pf.policy_secondperson || '', 400, 198);
  doc.text(pf.custom_1 || '', 222, 312);
  doc.text(pf.custom_2 || '', 400, 312);
  doc.text(pf.custom_3 || '', 136, 474);
  doc.text("5F., No.400, Sec. 1, Keelung Rd., Xinyi Dist., Taipei City 110, Taiwan (R.O.C.)", 135, 498, {
    maxWidth: 400,
    lineHeightFactor: 1.4,
    align: 'left'
  });
  doc.addPage();

  const ASS_4_64 = await getDataUri(ASS_4);
  doc.addImage(ASS_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.custom_9 || '', 220, 153);
  doc.text(pf.custom_10 || '', 220, 209);
  // doc.text("v", 238, 230, { width: 380, align: 'left'} );
  // doc.text("v", 281, 230, { width: 380, align: 'left'} );
  // doc.text("123", 420, 230, { width: 380, align: 'left'} );
  doc.text("v", 220, 365);
  doc.text(pf.custom_11 || '', 425, 365);
  doc.text(pf.custom_12 || '', 485, 365);
  doc.text(pf.custom_13 || '', 390, 388);
  doc.text(pf.custom_14 || '', 390, 412);
  doc.text(pf.custom_15 || '', 390, 468);
  // doc.text("v", 220, 488, { width: 380, align: 'left'} );
  // doc.text("1", 390, 488, { width: 380, align: 'left'} );
  // doc.text("2", 390, 528, { width: 380, align: 'left'} );
  // doc.text("12" + "   " + "12", 430, 550, { width: 380, align: 'left', characterSpacing: 7.3} );
  // doc.text("3", 428, 573, { width: 380, align: 'left'} );
  // doc.text("4", 490, 573, { width: 380, align: 'left'} );
  // doc.text("12" + " " + "12" + " " + "2012", 384, 596, { width: 380, align: 'left', characterSpacing: 7.2} );
  // doc.text("v", 220, 616, { width: 380, align: 'left'} );
  // doc.text("5", 260, 616, { width: 380, align: 'left'} );
  doc.addPage();

  const ASS_5_64 = await getDataUri(ASS_5);
  doc.addImage(ASS_5_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("v", 238, 160, { width: 380, align: 'left'} );
  // doc.text("v", 280, 160, { width: 380, align: 'left'} );
  // doc.text("v", 390, 178, { width: 380, align: 'left'} );
  // doc.text("v", 491, 178, { width: 380, align: 'left'} );
  // doc.text("v", 238, 252, { width: 380, align: 'left'} );
  // doc.text("v", 280, 252, { width: 380, align: 'left'} );
  // doc.text("v", 390, 268, { width: 380, align: 'left'} );
  // doc.text("v", 491, 268, { width: 380, align: 'left'} );
  // doc.text("v", 390, 290, { width: 380, align: 'left'} );
  // doc.text("v", 491, 290, { width: 380, align: 'left'} );
  // doc.text("v", 238, 325, { width: 380, align: 'left'} );
  // doc.text("v", 280, 325, { width: 380, align: 'left'} );
  // doc.text("v", 390, 344, { width: 380, align: 'left'} );
  // doc.text("v", 491, 344, { width: 380, align: 'left'} );
  // doc.text("v", 390, 368, { width: 380, align: 'left'} );
  // doc.text("123", 225, 395, { width: 380, align: 'left'} );
  // doc.text("456", 225, 451, { width: 380, align: 'left'} );
  // doc.text("v", 235, 549, { width: 380, align: 'left'} );
  // doc.text("v", 445, 549, { width: 380, align: 'left'} );
  // doc.text("v", 495, 549, { width: 380, align: 'left'} );
  // doc.text("v", 235, 574, { width: 380, align: 'left'} );
  // doc.text("v", 445, 574, { width: 380, align: 'left'} );
  // doc.text("v", 495, 574, { width: 380, align: 'left'} );
  doc.addPage();

  const ASS_6_64 = await getDataUri(ASS_6);
  doc.addImage(ASS_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  doc.setFontSize(15);
  const FPI_CMEA_64 = await getDataUri(FPI_CMEA);
  doc.addImage(FPI_CMEA_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("12" + " " + "12" + " " + "2012", 80, 75, { width: 380, align: 'left', characterSpacing: 7.3} );
  doc.text(pf.account_num || '', 190, 241);
  doc.text(pf.policy_firstperson + "/" + pf.policy_secondperson, 190, 276);
  doc.text(pf.custom_1 || '', 190, 314);
  doc.text(pf.custom_2 || '', 265, 422);
  doc.text(pf.custom_3 || '', 265, 457);
  // doc.text("789", 95, 485, { width: 380, align: 'left'} );
  doc.addPage();

  const FPI_Self_64 = await getDataUri(FPI_Self);
  doc.addImage(FPI_Self_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text(pf.custom_1 || '', 165, 121);
  doc.text(pf.account_num || '', 165, 134);
  doc.setFontSize(15);
  doc.text(pf.custom_2 || '', 150, 224);
  // doc.text("2", 150, 244, { width: 380, align: 'left'} );
  // doc.text("3", 150, 268, { width: 380, align: 'left'} );
  // doc.text("4", 150, 292, { width: 380, align: 'left'} );
  doc.text(pf.custom_3 || '', 310, 224);
  // doc.text("6", 310, 244, { width: 380, align: 'left'} );
  // doc.text("7", 310, 268, { width: 380, align: 'left'} );
  // doc.text("8", 310, 292, { width: 380, align: 'left'} );
  doc.text(pf.custom_2 || '', 150, 394);
  // doc.text("2", 150, 414, { width: 380, align: 'left'} );
  // doc.text("3", 150, 438, { width: 380, align: 'left'} );
  // doc.text("4", 150, 462, { width: 380, align: 'left'} );
  doc.text(pf.custom_2 || '', 310, 394);
  // doc.text("6", 310, 414, { width: 380, align: 'left'} );
  // doc.text("7", 310, 438, { width: 380, align: 'left'} );
  // doc.text("8", 310, 462, { width: 380, align: 'left'} );
  doc.text(pf.name_eng || '', 190, 596);
}
export const pdfas35 = async (doc, pf) => {
  doc.setFont('Courier');
  const ChangeInv_64 = await getDataUri(ChangeInv);
  doc.addImage(ChangeInv_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.payperiod_fpi || '', 233, 226);
  doc.text(pf.custom_1 || '', 185, 245);
  doc.text(pf.custom_2 || '', 230, 245);
  doc.text(pf.name_eng || '', 120, 657);
  doc.text(pf.account_num || '', 120, 677);
  doc.addPage();

  const FPI_TT_64 = await getDataUri(FPI_TT);
  doc.addImage(FPI_TT_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.account_num || '', 300, 419);
  doc.text(pf.name_eng || '', 300, 450);
  doc.text(pf.fpi_now_same_payment || '', 330, 477);
  doc.text(String((Number(pf.fpi_now_same_payment) + 20)) || '', 510, 477);
  doc.text(pf.fpi_now_same_payment || '', 350, 498);
}
export const pdfas36 = async (doc, pf) => {
  doc.setFont('Courier');
  const ChangeInv_64 = await getDataUri(ChangeInv);
  doc.addImage(ChangeInv_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.payperiod_fpi || '', 235, 228);
  doc.text(pf.custom_1 || '', 185, 248);
  doc.text(pf.custom_2 || '', 230, 248);
  doc.text(pf.name_eng || '', 120, 657);
  doc.text(pf.account_num || '', 120, 677);
  doc.addPage();

  const FPI_TT_64 = await getDataUri(FPI_TT);
  doc.addImage(FPI_TT_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.account_num || '', 300, 418);
  doc.text(pf.name_eng || '', 300, 448);
  doc.text(pf.fpi_now_same_payment || '', 330, 476);
  doc.text(String((Number(pf.fpi_now_same_payment) + 20)) || '', 510, 477);
  doc.text(pf.fpi_now_same_payment || '', 350, 497);
  doc.addPage();

  const CancelBSO_64 = await getDataUri(CancelBSO);
  doc.addImage(CancelBSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HSBC", 55, 154);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 55, 206);
  doc.text(pf.name_eng || '', 55, 295);
  doc.text(pf.hsbc_account_num || '', 55, 345);
  doc.text(pf.fpi_now_same_payment || '', 430, 399);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 55, 627);
  doc.setFontSize(12);
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 54, 725, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
}
export const pdfas37 = async (doc, pf) => {
  doc.setFont('Courier');
  const ChangeInv_64 = await getDataUri(ChangeInv);
  doc.addImage(ChangeInv_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.payperiod_fpi || '', 235, 229);
  doc.text(pf.custom_1 || '', 185, 248);
  doc.text(pf.custom_2 || '', 230, 248);
  doc.text(pf.name_eng || '', 120, 657);
  doc.text(pf.account_num || '', 120, 677);
}
export const pdfas38 = async (doc, pf) => {
  doc.setFont('Courier');
  const ChangeInv_64 = await getDataUri(ChangeInv);
  doc.addImage(ChangeInv_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.payperiod_fpi || '', 234, 229);
  doc.text(pf.custom_1 || '', 185, 248);
  doc.text(pf.custom_2 || '', 230, 248);
  doc.text(pf.name_eng || '', 120, 656);
  doc.text(pf.account_num || '', 120, 676);
  doc.addPage();

  const BSO_64 = await getDataUri(BSO);
  doc.addImage(BSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text("HSBC", 170, 173);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 95, 191);
  doc.text(pf.hsbc_account_num || '', 50, 275);
  doc.text(pf.policy_currency_type || '', 50, 314);
  doc.text("HSBCHKHHHKH", 340, 315);
  doc.text(pf.name_eng || '', 50, 345);
  // doc.text("IBAN", 340, 340, { width: 380, align: 'left'} );
  doc.text(pf.fpi_now_same_payment || '', 136, 427);
  doc.setFontSize(9);
  doc.text(pf.fpi_now_same_payment_words || '', 340, 427);
  doc.setFontSize(12);
  doc.text(pf.check || '', 50, 470);
  // doc.text("v", 50, 462, { width: 380, align: 'left'} );
  // doc.text("v", 50, 483, { width: 380, align: 'left'} );
  // doc.text("v", 50, 502, { width: 380, align: 'left'} );
  // doc.text("v", 50, 521, { width: 380, align: 'left'} );
  // doc.text("v", 140, 565, { width: 380, align: 'left'} );
  // doc.text("v", 218, 565, { width: 380, align: 'left'} );
  // doc.text("v", 295, 565, { width: 380, align: 'left'} );
  // doc.text("v", 372, 565, { width: 380, align: 'left'} );
  // doc.text(pf.addr1_eng || '', 150, 710, { width: 380, align: 'left'} );
  doc.text(pf.addr1_eng || '', 150, 712, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 48, 812, {
    maxWidth: 380,
    align: 'left',
    charSpace: 14
  });
}
export const pdfas39 = async (doc, pf) => {
  doc.setFont('Courier');
  const ChangeInv_64 = await getDataUri(ChangeInv)
  doc.addImage(ChangeInv_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.payperiod_fpi || '', 233, 227);
  doc.text(pf.custom_1 || '', 185, 247);
  doc.text(pf.custom_2 || '', 230, 247);
  doc.text(pf.name_eng || '', 120, 657);
  doc.text(pf.account_num || '', 120, 677);
  doc.addPage();

  const BSO_64 = await getDataUri(BSO);
  doc.addImage(BSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text("HSBC", 170, 173);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 95, 191);
  doc.text(pf.hsbc_account_num || '', 50, 275);
  doc.text(pf.policy_currency_type || '', 50, 315);
  doc.text("HSBCHKHHHKH", 340, 315);
  doc.text(pf.name_eng || '', 49, 346);
  // doc.text("IBAN", 340, 340, { width: 380, align: 'left'} );
  doc.text(pf.fpi_now_same_payment || '', 137, 427);
  doc.setFontSize(9);
  doc.text(pf.fpi_now_same_payment_words || '', 340, 427);
  doc.setFontSize(12);
  doc.text(pf.check || '', 50, 470);
  // doc.text("v", 50, 462, { width: 380, align: 'left'} );
  // doc.text("v", 50, 483, { width: 380, align: 'left'} );
  // doc.text("v", 50, 502, { width: 380, align: 'left'} );
  // doc.text("v", 50, 521, { width: 380, align: 'left'} );
  // doc.text("v", 140, 565, { width: 380, align: 'left'} );
  // doc.text("v", 218, 565, { width: 380, align: 'left'} );
  // doc.text("v", 295, 565, { width: 380, align: 'left'} );
  // doc.text("v", 372, 565, { width: 380, align: 'left'} );
  // doc.text(pf.addr1_eng || '', 150, 710, { width: 380, align: 'left'} );
  doc.text(pf.addr1_eng || '', 150, 712, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 49, 812, {
    maxWidth: 380,
    align: 'left',
    charSpace: 14
  });
  doc.addPage();

  const CancelBSO_64 = await getDataUri(CancelBSO);
  doc.addImage(CancelBSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HSBC", 55, 152);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 55, 203);
  doc.text(pf.name_eng || '', 55, 293);
  doc.text(pf.hsbc_account_num || '', 55, 344);
  doc.text(pf.fpi_now_same_payment || '', 430, 398);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 55, 626);
  doc.setFontSize(12);
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 54, 725, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
}
export const pdfas40 = async (doc, pf) => {
  doc.setFont('Courier');
  const ChangeInv_64 = await getDataUri(ChangeInv);
  doc.addImage(ChangeInv_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.payperiod_fpi || '', 231, 227);
  doc.text(pf.custom_1 || '', 185, 247);
  doc.text(pf.custom_2 || '', 230, 247);
  doc.text(pf.name_eng || '', 120, 657);
  doc.text(pf.account_num || '', 120, 677);
  doc.addPage();

  const NEW_1_64 = await getDataUri(NEW_1);
  doc.addImage(NEW_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.creditcard_bank || '', 223, 311);
  doc.text(pf.creditcard_bankname || '', 223, 334);
  doc.text(pf.creditcard_num || '', 222, 358, {
    maxWidth: 380,
    align: 'left',
    charSpace: 7
  });
  doc.text(pf.creditcard_term_month || '', 222, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 7
  });
  doc.text(pf.creditcard_term_year || '', 300, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 7
  });
  doc.text(pf.fpi_now_same_payment || '', 223, 404);
  doc.text(pf.fpi_now_same_payment_words || '', 223, 424);
  doc.text(pf.policy_currency_type || '', 223, 444);
  doc.text(pf.account_num || '', 223, 466);
  doc.text(pf.name_eng || '', 223, 536);
  // Api.strChtEngMix(doc, decodeURI(pf.addr1_eng), 223, 555, 12);
  doc.text(pf.addr1_eng || '', 223, 562, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.contactnum || '', 223, 637);
  doc.text(pf.email || '', 223, 660, {
    align: 'left',
    charSpace: 10,
    lineHeightFactor: 1.6,
    maxWidth: 150
  });
  doc.addPage();

  const NEW_2_64 = await getDataUri(NEW_2);
  doc.addImage(NEW_2_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas41 = async (doc, pf) => {
  doc.setFont('Courier');
  const ChangeInv_64 = await getDataUri(ChangeInv);
  doc.addImage(ChangeInv_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.payperiod_fpi || '', 233, 227);
  doc.text(pf.custom_1 || '', 185, 247);
  doc.text(pf.custom_2 || '', 230, 247);
  doc.text(pf.name_eng || '', 120, 657);
  doc.text(pf.account_num || '', 120, 677);
  doc.addPage();

  const NEW_1_64 = await getDataUri(NEW_1);
  doc.addImage(NEW_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.creditcard_bank || '', 223, 312);
  doc.text(pf.creditcard_bankname || '', 223, 335);
  doc.text(pf.creditcard_num || '', 222, 359, {
    maxWidth: 380,
    align: 'left',
    charSpace: 7
  });
  doc.text(pf.creditcard_term_month || '', 222, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 7
  });
  doc.text(pf.creditcard_term_year || '', 299, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 7
  });
  doc.text(pf.fpi_now_same_payment || '', 223, 404);
  doc.text(pf.fpi_now_same_payment_words || '', 223, 424);
  doc.text(pf.policy_currency_type || '', 223, 444);
  doc.text(pf.account_num || '', 223, 466);
  doc.text(pf.name_eng || '', 223, 537);
  // Api.strChtEngMix(doc, decodeURI(pf.addr1_eng), 223, 555, 12);
  doc.text(pf.addr1_eng || '', 223, 562, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.contactnum || '', 223, 637);
  doc.text(pf.email || '', 221, 661, {
    align: 'left',
    charSpace: 10,
    lineHeightFactor: 1.6,
    maxWidth: 150
  });
  doc.addPage();

  const NEW_2_64 = await getDataUri(NEW_2);
  doc.addImage(NEW_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const CancelBSO_64 = await getDataUri(CancelBSO);
  doc.addImage(CancelBSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HSBC", 55, 152);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 55, 205);
  doc.text(pf.name_eng || '', 55, 294);
  doc.text(pf.hsbc_account_num || '', 55, 346);
  doc.text(pf.fpi_now_same_payment || '', 430, 399);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 55, 625);
  doc.setFontSize(12);
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 54, 725, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
}
export const pdfas42 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_Adj_64 = await getDataUri(FPI_Adj);
  doc.addImage(FPI_Adj_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(10);
  doc.text(pf.custom_1 || '', 490, 369);
  doc.text(pf.custom_2 || '', 95, 387);
  doc.text(pf.fpi_now_same_payment || '', 190, 387);
  doc.text(pf.payperiod_fpi || '', 250, 387);
  doc.text(pf.custom_3 || '', 80, 405);
  doc.text(pf.custom_4 || '', 140, 405);
  doc.text(pf.name_eng || '', 157, 730);
  doc.text(pf.account_num || '', 157, 749);
  doc.setFontSize(12);
}
export const pdfas43 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_Adj_64 = await getDataUri(FPI_Adj);
  doc.addImage(FPI_Adj_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(10);
  doc.text(pf.custom_1 || '', 490, 369);
  doc.text(pf.custom_2 || '', 95, 387);
  doc.text(pf.fpi_now_same_payment || '', 190, 387);
  doc.text(pf.payperiod_fpi || '', 250, 387);
  doc.text(pf.custom_3 || '', 80, 405);
  doc.text(pf.custom_4 || '', 140, 405);
  doc.text(pf.name_eng || '', 155, 730);
  doc.text(pf.account_num || '', 155, 749);
  doc.setFontSize(12);
  doc.addPage();

  const NEW_1_64 = await getDataUri(NEW_1);
  doc.addImage(NEW_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.creditcard_bank || '', 223, 311);
  doc.text(pf.creditcard_bankname || '', 223, 334);
  doc.text(pf.creditcard_num || '', 223, 358, {
    maxWidth: 380,
    align: 'left',
    charSpace: 9
  });
  doc.text(pf.creditcard_term_month || '', 223, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 8
  });
  doc.text(pf.creditcard_term_year || '', 300, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 8
  });
  doc.text(pf.fpi_now_same_payment || '', 223, 404);
  doc.text(pf.fpi_now_same_payment_words || '', 223, 424);
  doc.text(pf.policy_currency_type || '', 223, 444);
  doc.text(pf.account_num || '', 223, 466);
  doc.text(pf.name_eng || '', 223, 537);
  // Api.strChtEngMix(doc, decodeURI(pf.addr1_eng), 223, 555, 12);
  doc.text(pf.addr1_eng || '', 223, 562, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.contactnum || '', 223, 637);
  doc.text(pf.email || '', 223, 661, {
    maxWidth: 150,
    align: 'left',
    charSpace: 10,
    lineHeightFactor: 1.6
  });
  doc.addPage();

  const NEW_2_64 = await getDataUri(NEW_2);
  doc.addImage(NEW_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const CancelBSO_64 = await getDataUri(CancelBSO);
  doc.addImage(CancelBSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HSBC", 55, 152);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 55, 205);
  doc.text(pf.name_eng || '', 55, 294);
  doc.text(pf.hsbc_account_num || '', 55, 346);
  doc.text(pf.fpi_now_same_payment || '', 430, 399);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 55, 625);
  doc.setFontSize(12);
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 54, 725, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
}
export const pdfas44 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_Adj_64 = await getDataUri(FPI_Adj);
  doc.addImage(FPI_Adj_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(10);
  doc.text(pf.custom_1 || '', 490, 369);
  doc.text(pf.custom_2 || '', 95, 387);
  doc.text(pf.fpi_now_same_payment || '', 190, 387);
  doc.text(pf.payperiod_fpi || '', 250, 387);
  doc.text(pf.custom_3 || '', 80, 405);
  doc.text(pf.custom_4 || '', 140, 405);
  doc.text(pf.name_eng || '', 155, 730);
  doc.text(pf.account_num || '', 155, 749);
  doc.setFontSize(12);
  doc.addPage();

  const NEW_1_64 = await getDataUri(NEW_1);
  doc.addImage(NEW_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.creditcard_bank || '', 223, 311);
  doc.text(pf.creditcard_bankname || '', 223, 334);
  doc.text(pf.creditcard_num || '', 223, 358, {
    maxWidth: 380,
    align: 'left',
    charSpace: 8.5
  });
  doc.text(pf.creditcard_term_month || '', 223, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 10.5
  });
  doc.text(pf.creditcard_term_year || '', 300, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 10.5
  });
  doc.text(pf.fpi_now_same_payment || '', 223, 404);
  doc.text(pf.fpi_now_same_payment_words || '', 223, 424);
  doc.text(pf.policy_currency_type || '', 223, 444);
  doc.text(pf.account_num || '', 223, 466);
  doc.text(pf.name_eng || '', 223, 537);
  // Api.strChtEngMix(doc, decodeURI(pf.addr1_eng), 223, 555, 12);
  doc.text(pf.addr1_eng || '', 223, 560, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.contactnum || '', 223, 637);
  doc.text(pf.email || '', 223, 662, {
    maxWidth: 150,
    lineHeightFactor: 1.6,
    charSpace: 10,
    align: 'left'
  });
  doc.addPage();

  const NEW_2_64 = await getDataUri(NEW_2);
  doc.addImage(NEW_2_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas45 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_Adj_64 = await getDataUri(FPI_Adj);
  doc.addImage(FPI_Adj_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(10);
  doc.text(pf.custom_1 || '', 490, 369);
  doc.text(pf.custom_2 || '', 95, 388);
  doc.text(pf.fpi_now_same_payment || '', 190, 388);
  doc.text(pf.payperiod_fpi || '', 250, 388);
  doc.text(pf.custom_3 || '', 80, 405);
  doc.text(pf.custom_4 || '', 140, 405);
  doc.text(pf.name_eng || '', 155, 729);
  doc.text(pf.account_num || '', 155, 748);
  doc.setFontSize(12);
  doc.addPage();

  const BSO_64 = await getDataUri(BSO);
  doc.addImage(BSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text("HSBC", 170, 173);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 95, 191);
  doc.text(pf.hsbc_account_num || '', 50, 275);
  doc.text(pf.policy_currency_type || '', 50, 315);
  doc.text("HSBCHKHHHKH", 340, 315);
  doc.text(pf.name_eng || '', 50, 346);
  // doc.text("IBAN", 340, 340, { width: 380, align: 'left'} );
  doc.text(pf.fpi_now_same_payment || '', 140, 427);
  doc.setFontSize(9);
  doc.text(pf.fpi_now_same_payment_words || '', 340, 427);
  doc.setFontSize(12);
  doc.text(pf.check || '', 50, 470);
  // doc.text("v", 50, 462, { width: 380, align: 'left'} );
  // doc.text("v", 50, 483, { width: 380, align: 'left'} );
  // doc.text("v", 50, 502, { width: 380, align: 'left'} );
  // doc.text("v", 50, 521, { width: 380, align: 'left'} );
  // doc.text("v", 140, 565, { width: 380, align: 'left'} );
  // doc.text("v", 218, 565, { width: 380, align: 'left'} );
  // doc.text("v", 295, 565, { width: 380, align: 'left'} );
  // doc.text("v", 372, 565, { width: 380, align: 'left'} );
  // doc.text(pf.addr1_eng || '', 150, 710, { width: 380, align: 'left'} );
  doc.text(pf.addr1_eng || '', 150, 712, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 50, 812, {
    maxWidth: 380,
    align: 'left',
    charSpace: 13
  });
  doc.addPage();

  const CancelBSO_64 = await getDataUri(CancelBSO);
  doc.addImage(CancelBSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HSBC", 55, 152);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 55, 205);
  doc.text(pf.name_eng || '', 55, 294);
  doc.text(pf.hsbc_account_num || '', 55, 346);
  doc.text(pf.fpi_now_same_payment || '', 430, 399);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 55, 625);
  doc.setFontSize(12);
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 54, 725, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
}
export const pdfas46 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_Adj_64 = await getDataUri(FPI_Adj);
  doc.addImage(FPI_Adj_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(10);
  doc.text(pf.custom_1 || '', 490, 369);
  doc.text(pf.custom_2 || '', 95, 388);
  doc.text(pf.fpi_now_same_payment || '', 190, 388);
  doc.text(pf.payperiod_fpi || '', 250, 388);
  doc.text(pf.custom_3 || '', 80, 405);
  doc.text(pf.custom_4 || '', 140, 405);
  doc.text(pf.name_eng || '', 155, 729);
  doc.text(pf.account_num || '', 155, 748);
  doc.setFontSize(12);
  doc.addPage();

  const BSO_64 = await getDataUri(BSO);
  doc.addImage(BSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text("HSBC", 170, 173);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 95, 191);
  doc.text(pf.hsbc_account_num || '', 50, 275);
  doc.text(pf.policy_currency_type || '', 50, 315);
  doc.text("HSBCHKHHHKH", 340, 315);
  doc.text(pf.name_eng || '', 50, 346);
  // doc.text("IBAN", 340, 340, { width: 380, align: 'left'} );
  doc.text(pf.fpi_now_same_payment || '', 140, 427);
  doc.setFontSize(9);
  doc.text(pf.fpi_now_same_payment_words || '', 340, 427);
  doc.setFontSize(12);
  doc.text(pf.check || '', 50, 470);
  // doc.text("v", 50, 462, { width: 380, align: 'left'} );
  // doc.text("v", 50, 483, { width: 380, align: 'left'} );
  // doc.text("v", 50, 502, { width: 380, align: 'left'} );
  // doc.text("v", 50, 521, { width: 380, align: 'left'} );
  // doc.text("v", 140, 565, { width: 380, align: 'left'} );
  // doc.text("v", 218, 565, { width: 380, align: 'left'} );
  // doc.text("v", 295, 565, { width: 380, align: 'left'} );
  // doc.text("v", 372, 565, { width: 380, align: 'left'} );
  // doc.text(pf.addr1_eng || '', 150, 710, { width: 380, align: 'left'} );
  doc.text(pf.addr1_eng || '', 150, 712, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 50, 812, {
    maxWidth: 380,
    align: 'left',
    charSpace: 14
  });
}
export const pdfas47 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_Adj_64 = await getDataUri(FPI_Adj);
  doc.addImage(FPI_Adj_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(10);
  // doc.text(490, 360, pf.custom_1);
  // doc.text(95, 380, pf.custom_2);
  // doc.text(190, 380, pf.fpi_now_same_payment);
  // doc.text(250, 380, pf.payperiod_fpi);
  // doc.text(80, 397, pf.custom_3);
  // doc.text(140, 397, pf.custom_4);
  // doc.text(155, 722, pf.name_eng);
  // doc.text(155, 742, pf.account_num);
  doc.setFontSize(12);
  // doc.addPage();

  // // doc.addImage(FPI_TT, 0, 0, a4pageWidth, a4pageHeight);
  // doc.addImage(FPI_TT, 'PNG', 0, 0, a4pageWidth, a4pageHeight + 10);
  // doc.text(pf.account_num || '', 300, 415);
  // doc.text(pf.name_eng || '', 300, 445);
  // doc.text(pf.fpi_now_same_payment || '', 330, 470);
  // doc.text((Number(pf.fpi_now_same_payment) + 20), 510, 470);
  // doc.text(pf.fpi_now_same_payment || '', 350, 490);
}
export const pdfas48 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_Adj_64 = await getDataUri(FPI_Adj);
  doc.addImage(FPI_Adj_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(10);
  doc.text(pf.custom_1 || '', 490, 369);
  doc.text(pf.custom_2 || '', 95, 388);
  doc.text(pf.fpi_now_same_payment || '', 190, 388);
  doc.text(pf.payperiod_fpi || '', 250, 388);
  doc.text(pf.custom_3 || '', 80, 405);
  doc.text(pf.custom_4 || '', 140, 405);
  doc.text(pf.name_eng || '', 155, 729);
  doc.text(pf.account_num || '', 155, 748);
  doc.setFontSize(12);
  doc.addPage();

  const FPI_TT_64 = await getDataUri(FPI_TT);
  doc.addImage(FPI_TT_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.account_num || '', 300, 419);
  doc.text(pf.name_eng || '', 300, 450);
  doc.text(pf.fpi_now_same_payment || '', 330, 477);
  doc.text(String((Number(pf.fpi_now_same_payment) + 20)) || '', 510, 477);
  doc.text(pf.fpi_now_same_payment || '', 350, 498);
  doc.addPage();

  const CancelBSO_64 = await getDataUri(CancelBSO);
  doc.addImage(CancelBSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HSBC", 55, 152);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 55, 205);
  doc.text(pf.name_eng || '', 55, 294);
  doc.text(pf.hsbc_account_num || '', 55, 346);
  doc.text(pf.fpi_now_same_payment || '', 430, 399);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 55, 625);
  doc.setFontSize(12);
  doc.setFont('Courier');
  doc.text(pf.account_num || '', 54, 725, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
}
export const pdfas49 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_CPM_64 = await getDataUri(FPI_CPM);
  doc.addImage(FPI_CPM_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(9);
  doc.text(pf.name_eng || '', 275, 298);
  doc.text(pf.paymethod_fpi || '', 378, 316);
  doc.text(pf.account_num || '', 150, 335);
  doc.text(pf.custom_1 || '', 347, 335);
  doc.text(pf.custom_2 || '', 403, 335);
  doc.text(pf.name_eng || '', 100, 710);
  doc.text(pf.account_num || '', 100, 730);
  doc.setFontSize(12);
  doc.addPage();

  const FPI_TT_64 = await getDataUri(FPI_TT);
  doc.addImage(FPI_TT_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.account_num || '', 300, 419);
  doc.text(pf.name_eng || '', 300, 450);
  doc.text(pf.fpi_now_same_payment || '', 330, 476);
  doc.text(String((Number(pf.fpi_now_same_payment) + 20)) || '', 510, 476);
  doc.text(pf.fpi_now_same_payment || '', 350, 496);
  doc.addPage();

  const CancelBSO_64 = await getDataUri(CancelBSO);
  doc.addImage(CancelBSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HSBC", 55, 152);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 55, 205);
  doc.text(pf.name_eng || '', 55, 294);
  doc.text(pf.hsbc_account_num || '', 55, 346);
  doc.text(pf.fpi_now_same_payment || '', 430, 399);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 55, 625);
  doc.setFontSize(12);
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 54, 725, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
}
export const pdfas50 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_CPM_64 = await getDataUri(FPI_CPM);
  doc.addImage(FPI_CPM_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(9);
  doc.text(pf.name_eng || '', 275, 298);
  doc.text(pf.paymethod_fpi || '', 380, 316);
  doc.text(pf.account_num || '', 150, 335);
  doc.text(pf.custom_1 || '', 347, 335);
  doc.text(pf.custom_2 || '', 403, 335);
  doc.text(pf.name_eng || '', 100, 710);
  doc.text(pf.account_num || '', 100, 730);
  doc.setFontSize(12);
  doc.addPage();

  const FPI_TT_64 = await getDataUri(FPI_TT);
  doc.addImage(FPI_TT_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.account_num || '', 300, 419);
  doc.text(pf.name_eng || '', 300, 450);
  doc.text(pf.fpi_now_same_payment || '', 330, 476);
  doc.text(String((Number(pf.fpi_now_same_payment) + 20)) || '', 510, 476);
  doc.text(pf.fpi_now_same_payment || '', 350, 496);
}
export const pdfas51 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_CPM_64 = await getDataUri(FPI_CPM);
  doc.addImage(FPI_CPM_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(9);
  doc.text(pf.name_eng || '', 275, 298);
  doc.text(pf.paymethod_fpi || '', 380, 316);
  doc.text(pf.account_num || '', 150, 335);
  doc.text(pf.custom_1 || '', 347, 335);
  doc.text(pf.custom_2 || '', 403, 335);
  doc.text(pf.name_eng || '', 100, 710);
  doc.text(pf.account_num || '', 100, 730);
  doc.setFontSize(12);
  doc.addPage();

  const NEW_1_64 = await getDataUri(NEW_1);
  doc.addImage(NEW_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.creditcard_bank || '', 223, 311);
  doc.text(pf.creditcard_bankname || '', 223, 334);
  doc.text(pf.creditcard_num || '', 223, 358, {
    width: 380,
    align: 'left',
    charSpace: 10
  });
  doc.text(pf.creditcard_term_month || '', 223, 382, {
    width: 380,
    align: 'left',
    charSpace: 10.5
  });
  doc.text(pf.creditcard_term_year || '', 300, 382, {
    width: 380,
    align: 'left',
    charSpace: 10.5
  });
  doc.text(pf.fpi_now_same_payment || '', 223, 404);
  doc.text(pf.fpi_now_same_payment_words || '', 223, 424);
  doc.text(pf.policy_currency_type || '', 223, 444);
  doc.text(pf.account_num || '', 223, 466);
  doc.text(pf.name_eng || '', 223, 537);
  // Api.strChtEngMix(doc, decodeURI(pf.addr1_eng), 223, 555, 12);
  doc.text(pf.addr1_eng || '', 223, 560, {
    width: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.contactnum || '', 223, 637);
  doc.text(pf.email || '', 223, 662, {
    maxWidth: 150,
    lineHeightFactor: 1.6,
    charSpace: 10,
    align: 'left'
  });
  doc.addPage();

  const NEW_2_64 = await getDataUri(NEW_2);
  doc.addImage(NEW_2_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas52 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_CPM_64 = await getDataUri(FPI_CPM);
  doc.addImage(FPI_CPM_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(9);
  doc.text(pf.name_eng || '', 275, 298);
  doc.text(pf.paymethod_fpi || '', 380, 316);
  doc.text(pf.account_num || '', 150, 335);
  doc.text(pf.custom_1 || '', 347, 335);
  doc.text(pf.custom_2 || '', 403, 335);
  doc.text(pf.name_eng || '', 100, 710);
  doc.text(pf.account_num || '', 100, 730);
  doc.setFontSize(12);
  doc.addPage();

  const NEW_1_64 = await getDataUri(NEW_1);
  doc.addImage(NEW_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.creditcard_bank || '', 223, 311);
  doc.text(pf.creditcard_bankname || '', 223, 334);
  doc.text(pf.creditcard_num || '', 223, 358, {
    maxWidth: 380,
    align: 'left',
    charSpace: 8.5
  });
  doc.text(pf.creditcard_term_month || '', 223, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 10.5
  });
  doc.text(pf.creditcard_term_year || '', 300, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 10.5
  });
  doc.text(pf.fpi_now_same_payment || '', 223, 404);
  doc.text(pf.fpi_now_same_payment_words || '', 223, 424);
  doc.text(pf.policy_currency_type || '', 223, 444);
  doc.text(pf.account_num || '', 223, 466);
  doc.text(pf.name_eng || '', 223, 537);
  // Api.strChtEngMix(doc, decodeURI(pf.addr1_eng), 223, 555, 12);
  doc.setFont('Courier');
  doc.text(pf.contactnum || '', 223, 637);
  doc.text(pf.email || '', 223, 662, {
    maxWidth: 150,
    lineHeightFactor: 1.6,
    charSpace: 10,
    align: 'left'
  });
  doc.addPage();

  const NEW_2_64 = await getDataUri(NEW_2);
  doc.addImage(NEW_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const CancelBSO_64 = await getDataUri(CancelBSO);
  doc.addImage(CancelBSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HSBC", 55, 152);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 55, 205);
  doc.text(pf.name_eng || '', 55, 294);
  doc.text(pf.hsbc_account_num || '', 55, 346);
  doc.text(pf.fpi_now_same_payment || '', 430, 399);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 55, 625);
  doc.setFontSize(12);
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 54, 725, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
}
export const pdfas53 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_CPM_64 = await getDataUri(FPI_CPM);
  doc.addImage(FPI_CPM_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(9);
  doc.text(pf.name_eng || '', 275, 298);
  doc.text(pf.paymethod_fpi || '', 380, 316);
  doc.text(pf.account_num || '', 150, 335);
  doc.text(pf.custom_1 || '', 347, 335);
  doc.text(pf.custom_2 || '', 403, 335);
  doc.text(pf.name_eng || '', 100, 710);
  doc.text(pf.account_num || '', 100, 730);
  doc.setFontSize(12);
  doc.addPage();

  const BSO_64 = await getDataUri(BSO);
  doc.addImage(BSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text("HSBC", 170, 173);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 95, 191);
  doc.text(pf.hsbc_account_num || '', 50, 275);
  doc.text(pf.policy_currency_type || '', 50, 315);
  doc.text("HSBCHKHHHKH", 340, 315);
  doc.text(pf.name_eng || '', 50, 346);
  // doc.text("IBAN", 340, 340, { width: 380, align: 'left'} );
  doc.text(pf.fpi_now_same_payment || '', 137, 427);
  doc.setFontSize(9);
  doc.text(pf.fpi_now_same_payment_words || '', 340, 427);
  doc.setFontSize(12);
  doc.text(pf.check || '', 50, 470);
  // doc.text("v", 50, 462, { width: 380, align: 'left'} );
  // doc.text("v", 50, 483, { width: 380, align: 'left'} );
  // doc.text("v", 50, 502, { width: 380, align: 'left'} );
  // doc.text("v", 50, 521, { width: 380, align: 'left'} );
  // doc.text("v", 140, 565, { width: 380, align: 'left'} );
  // doc.text("v", 218, 565, { width: 380, align: 'left'} );
  // doc.text("v", 295, 565, { width: 380, align: 'left'} );
  // doc.text("v", 372, 565, { width: 380, align: 'left'} );
  // doc.text(pf.addr1_eng || '', 150, 710, { width: 380, align: 'left'} );
  doc.text(pf.addr1_eng || '', 150, 712, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 50, 812, {
    maxWidth: 380,
    align: 'left',
    charSpace: 14
  });
}
export const pdfas54 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_CPM_64 = await getDataUri(FPI_CPM);
  doc.addImage(FPI_CPM_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(9);
  doc.text(pf.name_eng || '', 275, 298);
  doc.text(pf.paymethod_fpi || '', 380, 316);
  doc.text(pf.account_num || '', 150, 335);
  doc.text(pf.custom_1 || '', 347, 335);
  doc.text(pf.custom_2 || '', 403, 335);
  doc.text(pf.name_eng || '', 100, 710);
  doc.text(pf.account_num || '', 100, 730);
  doc.setFontSize(12);
  doc.addPage();

  const BSO_64 = await getDataUri(BSO);
  doc.addImage(BSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text("HSBC", 170, 173);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 95, 191);
  doc.text(pf.hsbc_account_num || '', 50, 275);
  doc.text(pf.policy_currency_type || '', 50, 315);
  doc.text("HSBCHKHHHKH", 340, 315);
  doc.text(pf.name_eng || '', 50, 346);
  // doc.text("IBAN", 340, 340, { width: 380, align: 'left'} );
  doc.text(pf.fpi_now_same_payment || '', 137, 427);
  doc.setFontSize(9);
  doc.text(pf.fpi_now_same_payment_words || '', 340, 427);
  doc.setFontSize(12);
  doc.text(pf.check || '', 50, 470);
  // doc.text("v", 50, 462, { width: 380, align: 'left'} );
  // doc.text("v", 50, 483, { width: 380, align: 'left'} );
  // doc.text("v", 50, 502, { width: 380, align: 'left'} );
  // doc.text("v", 50, 521, { width: 380, align: 'left'} );
  // doc.text("v", 140, 565, { width: 380, align: 'left'} );
  // doc.text("v", 218, 565, { width: 380, align: 'left'} );
  // doc.text("v", 295, 565, { width: 380, align: 'left'} );
  // doc.text("v", 372, 565, { width: 380, align: 'left'} );
  // doc.text(pf.addr1_eng || '', 150, 710, { width: 380, align: 'left'} );
  doc.text(pf.addr1_eng || '', 150, 712, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 50, 812, {
    width: 380,
    align: 'left',
    charSpace: 14
  });
  doc.addPage();

  const CancelBSO_64 = await getDataUri(CancelBSO);
  doc.addImage(CancelBSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HSBC", 55, 152);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 55, 205);
  doc.text(pf.name_eng || '', 55, 294);
  doc.text(pf.hsbc_account_num || '', 55, 346);
  doc.text(pf.fpi_now_same_payment || '', 430, 399);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 55, 625);
  doc.setFontSize(12);
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 54, 725, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
}
export const pdfas55 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_CPM_64 = await getDataUri(FPI_CPM);
  doc.addImage(FPI_CPM_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(9);
  doc.text(pf.name_eng || '', 275, 298);
  doc.text(pf.paymethod_fpi || '', 380, 316);
  doc.text(pf.account_num || '', 150, 335);
  doc.text(pf.custom_1 || '', 347, 335);
  doc.text(pf.custom_2 || '', 403, 335);
  doc.text(pf.name_eng || '', 100, 710);
  doc.text(pf.account_num || '', 100, 730);
  doc.setFontSize(12);
  doc.addPage();

  const CancelBSO_64 = await getDataUri(CancelBSO);
  doc.addImage(CancelBSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HSBC", 55, 152);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 55, 205);
  doc.text(pf.name_eng || '', 55, 294);
  doc.text(pf.hsbc_account_num || '', 55, 346);
  doc.text(pf.fpi_now_same_payment || '', 430, 399);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 55, 625);
  doc.setFontSize(12);
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 54, 725, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11
  });
}
export const pdfas56 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_CPM_64 = await getDataUri(FPI_CPM);
  doc.addImage(FPI_CPM_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(9);
  doc.text(pf.name_eng || '', 275, 298);
  doc.text(pf.paymethod_fpi || '', 380, 316);
  doc.text(pf.account_num || '', 150, 335);
  doc.text(pf.custom_1 || '', 347, 335);
  doc.text(pf.custom_2 || '', 403, 335);
  doc.text(pf.name_eng || '', 100, 710);
  doc.text(pf.account_num || '', 100, 730);
  doc.setFontSize(12);
}
export const pdfas57 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_TPB_64 = await getDataUri(FPI_TPB);
  doc.addImage(FPI_TPB_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng + " " + pf.account_num, 210, 281);
  doc.text(pf.insertedBy || '', 230, 300);
  doc.text(pf.custom_1 || '', 300, 335);
  doc.text(pf.custom_2 || '', 258, 354);
  doc.text(pf.custom_1 || '', 100, 588);
  doc.text(pf.name_eng || '', 100, 678);
}
export const pdfas58 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_TPC_64 = await getDataUri(FPI_TPC);
  doc.addImage(FPI_TPC_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng + " " + pf.account_num, 200, 281);
  doc.text(pf.insertedBy || '', 255, 300);
  doc.text(pf.custom_1 || '', 300, 335);
  doc.text(pf.custom_2 || '', 258, 354);
  doc.text(pf.custom_1 || '', 100, 588);
  doc.text(pf.name_eng || '', 100, 640);
}
export const pdfas59 = async (doc, pf) => {
  doc.setFont('Courier');
  doc.setFontSize(9);
  const TOPUP_1_64 = await getDataUri(TOPUP_1);
  doc.addImage(TOPUP_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_2_64 = await getDataUri(TOPUP_2);
  doc.addImage(TOPUP_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.insertedBy || '', 220, 279);
  doc.text(pf.account_num || '', 220, 307);
  doc.text("Grandtag Financial Consultancy & Insurance Brokers Limited", 220, 376);
  doc.text("9800042", 220, 403);
  // doc.text("5", 220, 448, { width: 380, align: 'left'} );
  // doc.text("6", 220, 476, { width: 380, align: 'left'} );
  doc.text("investments@grandtag.com", 220, 510);
  doc.addPage();

  const TOPUP_3_64 = await getDataUri(TOPUP_3);
  doc.addImage(TOPUP_3_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("v", 240, 163, { width: 380, align: 'left'} );
  // doc.text("v", 282, 163, { width: 380, align: 'left'} );
  // doc.text("v", 325, 163, { width: 380, align: 'left'} );
  // doc.text("v", 368, 163, { width: 380, align: 'left'} );
  // doc.text("v", 415, 163, { width: 380, align: 'left'} );
  // doc.text("v", 458, 163, { width: 380, align: 'left'} );
  // doc.text("v", 500, 163, { width: 380, align: 'left'} );
  // doc.text("v", 543, 163, { width: 380, align: 'left'} );
  // doc.text("1", 270, 188, { width: 380, align: 'left'} );
  // doc.text("2", 440, 188, { width: 380, align: 'left'} );
  doc.text(pf.last_name || '', 220, 231);
  // doc.text("4", 400, 225, { width: 380, align: 'left'} );
  doc.text(pf.first_name || '', 220, 266);
  // doc.text("6", 400, 260, { width: 380, align: 'left'} );
  doc.text(pf.apply_passport || '', 220, 311);
  // doc.text("8", 400, 305, { width: 380, align: 'left'} );
  doc.text(pf.addr1_eng || '', 220, 346, {
    maxWidth: 180,
    align: 'left'
  });
  // doc.text("9qwerqwerqwe rqwerqwreqwe rqwerqwer", 400, 340, { width: 180, align: 'left'} );
  // doc.text("1", 250, 410, { width: 380, align: 'left'} );
  // doc.text("2", 360, 410, { width: 380, align: 'left'} );
  // doc.text("3", 425, 410, { width: 380, align: 'left'} );
  // doc.text("4", 535, 410, { width: 380, align: 'left'} );
  // doc.text("qweqweqweqwe qwqwerqwerqw erqwereqwer", 220, 455, { width: 180, align: 'left'} );
  // doc.text("9qwerqwerqwe rqwerqwreqwe rqwerqwer", 400, 455, { width: 180, align: 'left'} );
  doc.text(pf.apply_telephone || '', 220, 556);
  // doc.text("2", 400, 550, { width: 380, align: 'left'} );
  // doc.text("3", 220, 575, { width: 380, align: 'left'} );
  // doc.text("4", 400, 575, { width: 380, align: 'left'} );
  doc.text(pf.apply_cellphone || '', 220, 607);
  // doc.text("6", 400, 600, { width: 380, align: 'left'} );
  doc.text(pf.email || '', 220, 633);
  // doc.text("8", 400, 625, { width: 380, align: 'left'} );
  doc.text(pf.custom_1 || '', 220, 670);
  // doc.text("2", 400, 665, { width: 380, align: 'left'} );
  // doc.text("3", 220, 700, { width: 380, align: 'left'} );
  // doc.text("4", 400, 700, { width: 380, align: 'left'} );
  doc.addPage();

  const TOPUP_4_64 = await getDataUri(TOPUP_4);
  doc.addImage(TOPUP_4_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("v", 240, 196, { width: 380, align: 'left'} );
  // doc.text("v", 282, 196, { width: 380, align: 'left'} );
  // doc.text("v", 325, 196, { width: 380, align: 'left'} );
  // doc.text("v", 368, 196, { width: 380, align: 'left'} );
  doc.text(pf.fpi_now_same_payment || '', 220, 231);
  doc.text(pf.custom_2 || '', 220, 258);
  // doc.text("24" + " " + "12" + " " + "1234" , 220, 277, { width: 380, align: 'left', characterSpacing: 5.4} );
  // doc.text("v", 220, 460, { width: 380, align: 'left'} );
  // doc.text("v", 220, 522, { width: 380, align: 'left'} );
  // doc.text("v", 220, 584, { width: 380, align: 'left'} );
  // doc.text("v", 220, 638, { width: 380, align: 'left'} );
  // doc.text("v", 220, 678, { width: 380, align: 'left'} );
  // doc.text("v", 220, 740, { width: 380, align: 'left'} );
  doc.addPage();

  const TOPUP_5_64 = await getDataUri(TOPUP_5);
  doc.addImage(TOPUP_5_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("v", 240, 208, { width: 380, align: 'left'} );
  // doc.text("v", 282, 208, { width: 380, align: 'left'} );
  // doc.text("v", 325, 208, { width: 380, align: 'left'} );
  // doc.text("v", 368, 208, { width: 380, align: 'left'} );
  // doc.text("1", 220, 235, { width: 380, align: 'left'} );
  // doc.text("v", 220, 318, { width: 380, align: 'left'} );
  // doc.text("v", 220, 353, { width: 380, align: 'left'} );
  // doc.text("v", 220, 393, { width: 380, align: 'left'} );
  // doc.text("24" + " " + "12" + " " + "34" , 220, 532, { width: 380, align: 'left', characterSpacing: 6.2} );
  // doc.text("1", 220, 560, { width: 380, align: 'left'} );
  // doc.text("2", 220, 590, { width: 380, align: 'left'} );
  // doc.text("3", 220, 620, { width: 380, align: 'left'} );
  // doc.text("4", 220, 650, { width: 380, align: 'left'} );
  // doc.text("5", 220, 680, { width: 380, align: 'left'} );
  // doc.text("6", 220, 710, { width: 380, align: 'left'} );
  doc.addPage();

  const TOPUP_6_64 = await getDataUri(TOPUP_6);
  doc.addImage(TOPUP_6_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("1", 220, 173, { width: 380, align: 'left'} );
  // doc.text("2", 220, 197, { width: 380, align: 'left'} );
  // doc.text("3", 220, 221, { width: 380, align: 'left'} );
  // doc.text("4", 220, 245, { width: 380, align: 'left'} );
  // doc.text("1", 75, 563, { width: 380, align: 'left'} );
  // doc.text("2", 170, 563, { width: 380, align: 'left'} );
  // doc.text("3", 505, 563, { width: 380, align: 'left'} );
  // doc.text("1", 75, 585, { width: 380, align: 'left'} );
  // doc.text("2", 170, 585, { width: 380, align: 'left'} );
  // doc.text("3", 505, 585, { width: 380, align: 'left'} );
  doc.addPage();

  const TOPUP_7_64 = await getDataUri(TOPUP_7);
  doc.addImage(TOPUP_7_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("1", 60, 265, { width: 380, align: 'left'} );
  // doc.text("2", 130, 265, { width: 380, align: 'left'} );
  // doc.text("3", 210, 265, { width: 380, align: 'left'} );
  // doc.text("4", 300, 265, { width: 380, align: 'left'} );
  // doc.text("1", 60, 288, { width: 380, align: 'left'} );
  // doc.text("2", 130, 288, { width: 380, align: 'left'} );
  // doc.text("3", 210, 288, { width: 380, align: 'left'} );
  // doc.text("4", 300, 288, { width: 380, align: 'left'} );
  doc.addPage();

  const TOPUP_8_64 = await getDataUri(TOPUP_8);
  doc.addImage(TOPUP_8_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("v", 185, 184, { width: 380, align: 'left'} );
  // doc.text("1", 384, 184, { width: 380, align: 'left'} );
  // doc.text("2", 456, 184, { width: 380, align: 'left'} );
  // doc.text("3", 340, 208, { width: 380, align: 'left'} );
  // doc.text("4", 340, 230, { width: 380, align: 'left'} );
  // doc.text("5", 340, 285, { width: 380, align: 'left'} );
  // doc.text("v", 185, 332, { width: 380, align: 'left'} );
  // doc.text("1", 384, 332, { width: 380, align: 'left'} );
  // doc.text("2", 456, 332, { width: 380, align: 'left'} );
  // doc.text("3", 340, 356, { width: 380, align: 'left'} );
  // doc.text("4", 340, 378, { width: 380, align: 'left'} );
  // doc.text("54" + " " + "12" + " " + "1234", 338, 403, { width: 380, align: 'left', characterSpacing: 5.3} );
  // doc.text("v", 185, 450, { width: 380, align: 'left'} );
  // doc.text("1", 340, 450, { width: 380, align: 'left'} );
  // doc.text("2", 340, 495, { width: 380, align: 'left'} );
  // doc.text("16", 368, 518, { width: 380, align: 'left', characterSpacing: 5.3} );
  // doc.text("12", 457, 518, { width: 380, align: 'left', characterSpacing: 5.3} );
  // doc.text("1", 384, 540, { width: 380, align: 'left'} );
  // doc.text("2", 456, 540, { width: 380, align: 'left'} );
  // doc.text("54" + " " + "12" + " " + "1234", 338, 564, { width: 380, align: 'left', characterSpacing: 5.3} );
  // doc.text("v", 185, 612, { width: 380, align: 'left'} );
  // doc.text("1", 340, 612, { width: 380, align: 'left'} );
  // doc.text("54" + " " + "12" + " " + "1234", 338, 668, { width: 380, align: 'left', characterSpacing: 5.3} );
  // doc.text("1", 384, 690, { width: 380, align: 'left'} );
  // doc.text("2", 456, 690, { width: 380, align: 'left'} );
  doc.addPage();

  const TOPUP_9_64 = await getDataUri(TOPUP_9);
  doc.addImage(TOPUP_9_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("v", 186, 136, { width: 380, align: 'left'} );
  // doc.text("1", 340, 136, { width: 380, align: 'left'} );
  // doc.text("2", 340, 161, { width: 380, align: 'left'} );
  // doc.text("3", 340, 215, { width: 380, align: 'left'} );
  // doc.text("4", 384, 241, { width: 380, align: 'left'} );
  // doc.text("5", 456, 241, { width: 380, align: 'left'} );
  // doc.text("v", 186, 288, { width: 380, align: 'left'} );
  // doc.text("54" + " " + "12" + " " + "1234", 340, 288, { width: 380, align: 'left', characterSpacing: 5.3} );
  // doc.text("1", 340, 313, { width: 380, align: 'left'} );
  // doc.text("2", 340, 336, { width: 380, align: 'left'} );
  // doc.text("3", 340, 359, { width: 380, align: 'left'} );
  // doc.text("4", 340, 413, { width: 380, align: 'left'} );
  // doc.text("5", 340, 436, { width: 380, align: 'left'} );
  // doc.text("6", 340, 469, { width: 380, align: 'left'} );
  // doc.text("7", 340, 491, { width: 380, align: 'left'} );
  // doc.text("v", 186, 535, { width: 380, align: 'left'} );
  // doc.text("asd", 213, 537, { width: 380, align: 'left'} );
  doc.addPage();

  const TOPUP_10_64 = await getDataUri(TOPUP_10);
  doc.addImage(TOPUP_10_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_11_64 = await getDataUri(TOPUP_11);
  doc.addImage(TOPUP_11_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_12_64 = await getDataUri(TOPUP_12);
  doc.addImage(TOPUP_12_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_13_64 = await getDataUri(TOPUP_13);
  doc.addImage(TOPUP_13_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HONG KONG", 350, 450);
  doc.text("HONG KONG", 350, 488);
  doc.text(pf.name_eng || '', 220, 627);
  doc.addPage();

  const TOPUP_14_64 = await getDataUri(TOPUP_14);
  doc.addImage(TOPUP_14_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("1", 220, 195, { width: 380, align: 'left'} );
  // doc.text("2", 220, 213, { width: 380, align: 'left'} );
  // doc.text("3", 220, 258, { width: 380, align: 'left'} );
  // doc.text("4", 220, 275, { width: 380, align: 'left'} );
  // doc.text("5", 220, 305, { width: 380, align: 'left'} );
  // doc.text("54" + " " + "12" + " " + "34", 220, 322, { width: 380, align: 'left', characterSpacing: 5.3} );
  // doc.text("6", 220, 341, { width: 380, align: 'left'} );
  // doc.text("7", 220, 361, { width: 380, align: 'left'} );
  // doc.text("vvvv", 69, 460, { width: 380, align: 'left', characterSpacing: 43} );
  // doc.text("8", 260, 460, { width: 380, align: 'left'} );
  // doc.text("vvvv", 69, 483, { width: 380, align: 'left', characterSpacing: 43} );
  // doc.text("9", 260, 483, { width: 380, align: 'left'} );
  // doc.text("v", 220, 528, { width: 380, align: 'left'} );
  // doc.text("v", 507, 528, { width: 380, align: 'left'} );
  // doc.text("v", 220, 550, { width: 380, align: 'left'} );
  // doc.text("v", 507, 550, { width: 380, align: 'left'} );
  // doc.text("1", 225, 573, { width: 380, align: 'left'} );
  // doc.text("2", 330, 573, { width: 380, align: 'left'} );
  // doc.text("3", 483, 573, { width: 380, align: 'left'} );
  // doc.text("v", 265, 595, { width: 380, align: 'left'} );
  // doc.text("v", 360, 595, { width: 380, align: 'left'} );
  // doc.text("v", 460, 595, { width: 380, align: 'left'} );
  // doc.text("v", 543, 595, { width: 380, align: 'left'} );
  // doc.text("1234567890123", 47, 660, { width: 380, align: 'left', characterSpacing: 9.2} );
  doc.addPage();

  const TOPUP_15_64 = await getDataUri(TOPUP_15);
  doc.addImage(TOPUP_15_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("1", 220, 195, { width: 380, align: 'left'} );
  // doc.text("2", 220, 213, { width: 380, align: 'left'} );
  // doc.text("3", 220, 258, { width: 380, align: 'left'} );
  // doc.text("4", 220, 275, { width: 380, align: 'left'} );
  // doc.text("5", 220, 305, { width: 380, align: 'left'} );
  // doc.text("54" + " " + "12" + " " + "34", 220, 322, { width: 380, align: 'left', characterSpacing: 5.3} );
  // doc.text("6", 220, 341, { width: 380, align: 'left'} );
  // doc.text("7", 220, 361, { width: 380, align: 'left'} );
  // doc.text("vvvv", 69, 448, { width: 380, align: 'left', characterSpacing: 43} );
  // doc.text("8", 260, 448, { width: 380, align: 'left'} );
  // doc.text("vvvv", 69, 470, { width: 380, align: 'left', characterSpacing: 43} );
  // doc.text("9", 260, 470, { width: 380, align: 'left'} );
  // doc.text("v", 232, 514, { width: 380, align: 'left'} );
  // doc.text("v", 485, 514, { width: 380, align: 'left'} );
  // doc.text("v", 232, 542, { width: 380, align: 'left'} );
  // doc.text("v", 485, 542, { width: 380, align: 'left'} );
  // doc.text("1", 225, 577, { width: 380, align: 'left'} );
  // doc.text("2", 330, 577, { width: 380, align: 'left'} );
  // doc.text("3", 483, 577, { width: 380, align: 'left'} );
  // doc.text("v", 265, 596, { width: 380, align: 'left'} );
  // doc.text("v", 360, 596, { width: 380, align: 'left'} );
  // doc.text("v", 460, 596, { width: 380, align: 'left'} );
  // doc.text("v", 543, 596, { width: 380, align: 'left'} );
  // doc.text("1234567890123", 47, 660, { width: 380, align: 'left', characterSpacing: 9.2} );
  doc.addPage();

  const TOPUP_16_64 = await getDataUri(TOPUP_16);
  doc.addImage(TOPUP_16_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("1", 220, 195, { width: 380, align: 'left'} );
  // doc.text("2", 220, 213, { width: 380, align: 'left'} );
  // doc.text("3", 220, 258, { width: 380, align: 'left'} );
  // doc.text("4", 220, 275, { width: 380, align: 'left'} );
  // doc.text("5", 220, 305, { width: 380, align: 'left'} );
  // doc.text("54" + " " + "12" + " " + "34", 220, 322, { width: 380, align: 'left', characterSpacing: 5.3} );
  // doc.text("6", 220, 341, { width: 380, align: 'left'} );
  // doc.text("7", 220, 361, { width: 380, align: 'left'} );
  // doc.text("vvvv", 69, 494, { width: 380, align: 'left', characterSpacing: 43} );
  // doc.text("8", 260, 494, { width: 380, align: 'left'} );
  // doc.text("vvvv", 69, 517, { width: 380, align: 'left', characterSpacing: 43} );
  // doc.text("9", 260, 517, { width: 380, align: 'left'} );
  // doc.text("v", 220, 575, { width: 380, align: 'left'} );
  // doc.text("v", 507, 575, { width: 380, align: 'left'} );
  // doc.text("v", 220, 595, { width: 380, align: 'left'} );
  // doc.text("v", 507, 595, { width: 380, align: 'left'} );
  // doc.text("1234567890123", 47, 649, { width: 380, align: 'left', characterSpacing: 9.2} );
  doc.addPage();

  const TOPUP_17_64 = await getDataUri(TOPUP_17);
  doc.addImage(TOPUP_17_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("1", 220, 195, { width: 380, align: 'left'} );
  // doc.text("2", 220, 213, { width: 380, align: 'left'} );
  // doc.text("3", 220, 258, { width: 380, align: 'left'} );
  // doc.text("4", 220, 275, { width: 380, align: 'left'} );
  // doc.text("5", 220, 305, { width: 380, align: 'left'} );
  // doc.text("54" + " " + "12" + " " + "34", 220, 322, { width: 380, align: 'left', characterSpacing: 5.3} );
  // doc.text("6", 220, 341, { width: 380, align: 'left'} );
  // doc.text("7", 220, 361, { width: 380, align: 'left'} );
  // doc.text("vvvv", 69, 490, { width: 380, align: 'left', characterSpacing: 43} );
  // doc.text("8", 260, 490, { width: 380, align: 'left'} );
  // doc.text("vvvv", 69, 512, { width: 380, align: 'left', characterSpacing: 43} );
  // doc.text("9", 260, 512, { width: 380, align: 'left'} );
  // doc.text("v", 232, 565, { width: 380, align: 'left'} );
  // doc.text("v", 488, 565, { width: 380, align: 'left'} );
  // doc.text("v", 232, 595, { width: 380, align: 'left'} );
  // doc.text("v", 488, 595, { width: 380, align: 'left'} );
  // doc.text("1234567890123", 47, 653, { width: 380, align: 'left', characterSpacing: 9.2} );
  doc.addPage();

  const TOPUP_18_64 = await getDataUri(TOPUP_18);
  doc.addImage(TOPUP_18_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("1", 220, 195, { width: 380, align: 'left'} );
  // doc.text("2", 220, 213, { width: 380, align: 'left'} );
  // doc.text("3", 220, 258, { width: 380, align: 'left'} );
  // doc.text("4", 220, 275, { width: 380, align: 'left'} );
  // doc.text("5", 220, 305, { width: 380, align: 'left'} );
  // doc.text("54" + " " + "12" + " " + "34", 220, 322, { width: 380, align: 'left', characterSpacing: 5.3} );
  // doc.text("6", 220, 341, { width: 380, align: 'left'} );
  // doc.text("7", 220, 361, { width: 380, align: 'left'} );
  // doc.text("v", 220, 500, { width: 380, align: 'left'} );
  // doc.text("v", 507, 500, { width: 380, align: 'left'} );
  // doc.text("v", 220, 520, { width: 380, align: 'left'} );
  // doc.text("v", 507, 520, { width: 380, align: 'left'} );
  // doc.text("vvvv", 69, 552, { width: 380, align: 'left', characterSpacing: 43} );
  // doc.text("8", 260, 552, { width: 380, align: 'left'} );
  // doc.text("vvvv", 69, 575, { width: 380, align: 'left', characterSpacing: 43} );
  // doc.text("9", 260, 575, { width: 380, align: 'left'} );
  // doc.text("1234567890123", 47, 627, { width: 380, align: 'left', characterSpacing: 9.2} );
  doc.addPage();

  const TOPUP_19_64 = await getDataUri(TOPUP_19);
  doc.addImage(TOPUP_19_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("1", 220, 195, { width: 380, align: 'left'} );
  // doc.text("2", 220, 213, { width: 380, align: 'left'} );
  // doc.text("3", 220, 258, { width: 380, align: 'left'} );
  // doc.text("4", 220, 275, { width: 380, align: 'left'} );
  // doc.text("5", 220, 305, { width: 380, align: 'left'} );
  // doc.text("54" + " " + "12" + " " + "34", 220, 322, { width: 380, align: 'left', characterSpacing: 5.3} );
  // doc.text("6", 220, 341, { width: 380, align: 'left'} );
  // doc.text("7", 220, 361, { width: 380, align: 'left'} );
  // doc.text("vvvv", 69, 538, { width: 380, align: 'left', characterSpacing: 43} );
  // doc.text("8", 260, 538, { width: 380, align: 'left'} );
  // doc.text("vvvv", 69, 561, { width: 380, align: 'left', characterSpacing: 43} );
  // doc.text("9", 260, 561, { width: 380, align: 'left'} );
  // doc.text("1234567890123", 47, 612, { width: 380, align: 'left', characterSpacing: 9.2} );
  doc.addPage();

  const TOPUP_20_64 = await getDataUri(TOPUP_20);
  doc.addImage(TOPUP_20_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("1", 48, 365, { width: 380, align: 'left'} );
  // doc.text("123456789012345", 312, 365, { width: 380, align: 'left', characterSpacing: 7.6} );
  // doc.text("2", 48, 410, { width: 380, align: 'left'} );
  // doc.text("3", 312, 410, { width: 380, align: 'left'} );
  // doc.text("v", 108, 430, { width: 380, align: 'left'} );
  // doc.text("v", 176, 430, { width: 380, align: 'left'} );
  // doc.text("4", 48, 455, { width: 380, align: 'left'} );
  // doc.text("5", 312, 452, { width: 380, align: 'left'} );
  // doc.text("12345678", 48, 487, { width: 380, align: 'left', characterSpacing: 10} );
  // doc.text("6", 48, 550, { width: 380, align: 'left'} );
  // doc.text("123456789", 312, 550, { width: 380, align: 'left', characterSpacing: 8} );
  // doc.text("54" + " " + "12" + " " + "3456", 312, 581, { width: 380, align: 'left', characterSpacing: 5.3} );
  doc.addPage();

  const TOPUP_21_64 = await getDataUri(TOPUP_21);
  doc.addImage(TOPUP_21_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("v", 220, 242, { width: 380, align: 'left'} );
  // doc.text("v", 330, 242, { width: 380, align: 'left'} );
  doc.text(pf.creditcard_bank || '', 220, 283);
  doc.text(pf.creditcard_bankname || '', 220, 308);
  doc.text(pf.creditcard_num || '', 218, 334, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.5
  });
  doc.text(pf.creditcard_term_month || '', 218, 358, {
    maxWidth: 380,
    align: 'left',
    charSpace: 12
  });
  doc.text(pf.creditcard_term_year || '', 288, 358, {
    maxWidth: 380,
    align: 'left',
    charSpace: 12
  });
  doc.text(pf.custom_2 || '', 220, 382);
  doc.text(pf.custom_3 || '', 220, 402);
  // doc.text("vvvv", 238, 419, { width: 380, align: 'left', characterSpacing: 48} );
  doc.text(pf.account_num || '', 220, 453);
  // doc.text("6", 220, 480, { width: 380, align: 'left'} );
  // doc.text("7", 320, 480, { width: 380, align: 'left'} );
  // doc.text("8", 480, 480, { width: 380, align: 'left'} );
  // doc.text("v", 220, 505, { width: 380, align: 'left'} );
  // doc.text("v", 315, 505, { width: 380, align: 'left'} );
  // doc.text("v", 400, 505, { width: 380, align: 'left'} );
  // doc.text("v", 490, 505, { width: 380, align: 'left'} );
  doc.text(pf.name_eng || '', 220, 555);
  doc.text(pf.addr1_eng || '', 220, 580);
  doc.text(pf.contactnum || '', 220, 655);
  doc.text(pf.email || '', 220, 699);
  doc.addPage();

  const TOPUP_22_64 = await getDataUri(TOPUP_22);
  doc.addImage(TOPUP_22_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("v", 534, 382, { width: 380, align: 'left'} );
  // doc.text("v", 534, 412, { width: 380, align: 'left'} );
  // doc.text("v", 534, 442, { width: 380, align: 'left'} );
  // doc.text("v", 534, 472, { width: 380, align: 'left'} );
  // doc.text("v", 534, 502, { width: 380, align: 'left'} );
  // doc.text("v", 534, 532, { width: 380, align: 'left'} );
  // doc.text("v", 534, 562, { width: 380, align: 'left'} );
  // doc.text("v", 534, 592, { width: 380, align: 'left'} );
  // doc.text("v", 534, 622, { width: 380, align: 'left'} );
  // doc.text("v", 534, 652, { width: 380, align: 'left'} );
  // doc.text("v", 534, 682, { width: 380, align: 'left'} );
  doc.addPage();

  const TOPUP_23_64 = await getDataUri(TOPUP_23);
  doc.addImage(TOPUP_23_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_1_64 = await getDataUri(IFS_P_1);
  doc.addImage(IFS_P_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 100, 630);
  doc.addPage();

  const IFS_P_2_64 = await getDataUri(IFS_P_2);
  doc.addImage(IFS_P_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_3_64 = await getDataUri(IFS_P_3);
  doc.addImage(IFS_P_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 100, 620);
  doc.addPage();

  const IFS_P_4_64 = await getDataUri(IFS_P_4);
  doc.addImage(IFS_P_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 100, 500);
  doc.addPage();

  const IFS_P_5_64 = await getDataUri(IFS_P_5);
  doc.addImage(IFS_P_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 100, 665);
  doc.addPage();

  const IFS_P_6_64 = await getDataUri(IFS_P_6);
  doc.addImage(IFS_P_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(7);
  doc.text("Grandtag Financial Consultancy & Insurance Brokers Limited", 218, 205);
  doc.setFontSize(9);
  doc.text("M189", 250, 223);
  doc.text(pf.name_eng || '', 100, 511);
  doc.addPage();

  const IFS_P_7_64 = await getDataUri(IFS_P_7);
  doc.addImage(IFS_P_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 100, 194);
  doc.addPage();

  const IFS_P_8_64 = await getDataUri(IFS_P_8);
  doc.addImage(IFS_P_8_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 100, 660);
  doc.addPage();

  const IFS_P_9_64 = await getDataUri(IFS_P_9);
  doc.addImage(IFS_P_9_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_10_64 = await getDataUri(IFS_P_10);
  doc.addImage(IFS_P_10_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_11_64 = await getDataUri(IFS_P_11);
  doc.addImage(IFS_P_11_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_12_64 = await getDataUri(IFS_P_12);
  doc.addImage(IFS_P_12_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const GT_FNAC_64 = await getDataUri(GT_FNAC);
  doc.addImage(GT_FNAC_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 260, 473);
  doc.text(pf.insertedBy || '', 260, 604);
  doc.text(pf.account_num || '', 260, 653);

  doc.setFontSize(12);
  doc.addPage();

  const gt_1_64 = await getDataUri(gt_1);
  doc.addImage(gt_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 255, 474);
  // doc.text("21/12/16", 255, 465, { width: 380, align: 'left'} );
  // doc.text("name", 255, 545, { width: 380, align: 'left'} );
  doc.addPage();

  const gt_2_64 = await getDataUri(gt_2);
  doc.addImage(gt_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_3_64 = await getDataUri(gt_3);
  doc.addImage(gt_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 190, 150);
  // doc.text("male", 190, 180, { width: 380, align: 'left'} );
  doc.text(pf.birthday || '', 375, 203);
  // doc.text("taiwan", 190, 215, { width: 380, align: 'left'} );
  // doc.text("job", 375, 215, { width: 380, align: 'left'} );
  // doc.text("2", 190, 330, { width: 380, align: 'left'} );
  doc.text(pf.contactnum || '', 375, 367);
  doc.text(pf.addr1_eng || '', 190, 367);
  // doc.text("name", 190, 430, { width: 380, align: 'left'} );
  // doc.text("male", 190, 495, { width: 380, align: 'left'} );
  // doc.text("10/10", 375, 495, { width: 380, align: 'left'} );
  // doc.text("taiwan", 190, 530, { width: 380, align: 'left'} );
  // doc.text("job", 375, 530, { width: 380, align: 'left'} );
  // doc.text("2", 190, 645, { width: 380, align: 'left'} );
  // doc.text("212-1232", 375, 645, { width: 380, align: 'left'} );
  // doc.text("address", 190, 680, { width: 380, align: 'left'} );
  doc.addPage();

  const gt_4_64 = await getDataUri(gt_4);
  doc.addImage(gt_4_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("USD", 420, 85, { width: 380, align: 'left'} );
  // doc.text("123", 280, 145, { width: 380, align: 'left'} );
  // doc.text("234", 280, 195, { width: 380, align: 'left'} );
  // doc.text("345", 280, 235, { width: 380, align: 'left'} );
  // doc.text("456", 280, 280, { width: 380, align: 'left'} );
  // doc.text("567", 280, 315, { width: 380, align: 'left'} );
  // doc.text("678", 415, 350, { width: 380, align: 'left'} );
  // doc.text("789", 415, 385, { width: 380, align: 'left'} );
  // doc.text("11", 415, 420, { width: 380, align: 'left'} );
  // doc.text("22", 415, 455, { width: 380, align: 'left'} );
  // doc.text("33", 280, 515, { width: 380, align: 'left'} );
  // doc.text("44", 280, 550, { width: 380, align: 'left'} );
  // doc.text("55", 280, 585, { width: 380, align: 'left'} );
  // doc.text("66", 280, 620, { width: 380, align: 'left'} );
  // doc.text("77", 280, 655, { width: 380, align: 'left'} );
  // doc.text("88", 415, 690, { width: 380, align: 'left'} );
  doc.addPage();

  const gt_5_64 = await getDataUri(gt_5);
  doc.addImage(gt_5_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("1", 280, 135, { width: 380, align: 'left'} );
  // doc.text("2", 280, 175, { width: 380, align: 'left'} );
  // doc.text("3", 420, 210, { width: 380, align: 'left'} );
  // doc.text("4", 420, 250, { width: 380, align: 'left'} );
  // doc.text("5", 280, 315, { width: 380, align: 'left'} );
  // doc.text("6", 280, 350, { width: 380, align: 'left'} );
  // doc.text("7", 280, 385, { width: 380, align: 'left'} );
  // doc.text("8", 280, 420, { width: 380, align: 'left'} );
  // doc.text("9", 280, 455, { width: 380, align: 'left'} );
  // doc.text("10", 420, 490, { width: 380, align: 'left'} );
  doc.addPage();

  const gt_6_64 = await getDataUri(gt_6);
  doc.addImage(gt_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_7_64 = await getDataUri(gt_7);
  doc.addImage(gt_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_8_64 = await getDataUri(gt_8);
  doc.addImage(gt_8_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.setFontSize(14);
  // doc.text("1", 95, 123, { width: 380, align: 'left'} );
  // doc.text("2", 230, 123, { width: 380, align: 'left'} );
  // doc.text("3", 95, 139, { width: 380, align: 'left'} );
  // doc.text("4", 230, 139, { width: 380, align: 'left'} );
  doc.text(pf.name_eng || '', 235, 463);
  doc.addPage();

  const gt_9_64 = await getDataUri(gt_9);
  doc.addImage(gt_9_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_10_64 = await getDataUri(gt_10);
  doc.addImage(gt_10_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("1", 445, 127, { width: 380, align: 'left'} );
  // doc.text("2", 445, 145, { width: 380, align: 'left'} );
  // doc.text("3", 445, 163, { width: 380, align: 'left'} );
  // doc.text("4", 445, 181, { width: 380, align: 'left'} );
  // doc.text("5", 445, 199, { width: 380, align: 'left'} );
  // doc.text("6", 475, 235, { width: 380, align: 'left'} );
  doc.addPage();

  const gt_11_64 = await getDataUri(gt_11);
  doc.addImage(gt_11_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 200, 506);
  doc.addPage();

  const gt_12_64 = await getDataUri(gt_12);
  doc.addImage(gt_12_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("1", 200, 160, { width: 380, align: 'left'} );
  // doc.text("2", 200, 190, { width: 380, align: 'left'} );
  // doc.text("3", 400, 225, { width: 380, align: 'left'} );
  // doc.text("4", 170, 285, { width: 380, align: 'left'} );
  // doc.text("5", 170, 315, { width: 380, align: 'left'} );
  // doc.text("6", 170, 350, { width: 380, align: 'left'} );
  // doc.text("7", 170, 375, { width: 380, align: 'left'} );
  // doc.text("8", 170, 393, { width: 380, align: 'left'} );
  // doc.text("9", 170, 510, { width: 380, align: 'left'} );
  doc.addPage();

  const gt_13_64 = await getDataUri(gt_13);
  doc.addImage(gt_13_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 170, 277);
  doc.addPage();

  const gt_14_64 = await getDataUri(gt_14);
  doc.addImage(gt_14_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 220, 649);
  doc.addPage();

  const gt_15_64 = await getDataUri(gt_15);
  doc.addImage(gt_15_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_16_64 = await getDataUri(gt_16);
  doc.addImage(gt_16_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 210, 357);
  doc.addPage();

  const gt_17_64 = await getDataUri(gt_17);
  doc.addImage(gt_17_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FNA_1_64 = await getDataUri(FNA_1);
  doc.addImage(FNA_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 150, 220);
  // doc.text(pf.name_cht || '', 370, 190, { width: 380, align: 'left'} );
  // Api.strChtEngMix(doc, pf.name_cht, 370, 190, 12);
  doc.text(pf.last_name || '', 370, 220);
  doc.setFont('Courier');
  doc.text(pf.birthday || '', 150, 255);
  // doc.text("Taiwn", 370, 225, { width: 380, align: 'left'} );
  // doc.text("v", 475, 217, { width: 380, align: 'left'} );
  // doc.text("v", 475, 226, { width: 380, align: 'left'} );
  doc.setFontSize(8.5);
  doc.text(pf.contactnum || '', 185, 255);
  // doc.text("6", 313, 255, { width: 380, align: 'left'} );
  // doc.text("7", 455, 255, { width: 380, align: 'left'} );
  doc.setFontSize(8.5);
  doc.text(pf.email || '', 150, 310);
  doc.setFontSize(12);
  // doc.text("v", 365, 269, { width: 380, align: 'left'} );
  // doc.text("v", 458, 269, { width: 380, align: 'left'} );
  // doc.text("v", 365, 280, { width: 380, align: 'left'} );
  // doc.text("v", 458, 280, { width: 380, align: 'left'} );
  doc.text(pf.addr1_eng || '', 150, 330, {
    maxWidth: 140,
    align: 'left'
  });
  // doc.text("9", 370, 300, { width: 380, align: 'left'} );
  // doc.text("v", 148, 358, { width: 380, align: 'left'} );
  // doc.text("v", 282, 360, { width: 380, align: 'left'} );
  // doc.text("v", 416, 360, { width: 380, align: 'left'} );
  // doc.text("v", 148, 381, { width: 380, align: 'left'} );
  // doc.text("v", 350, 381, { width: 380, align: 'left'} );
  // doc.text("v", 148, 400, { width: 380, align: 'left'} );
  // doc.text("222",273 , 400, { width: 380, align: 'left'} );
  // doc.text("v", 377, 400, { width: 380, align: 'left'} );
  // doc.text("111", 430, 400, { width: 380, align: 'left'} );
  // doc.text("v", 148, 419, { width: 380, align: 'left'} );
  // doc.text("v", 282, 419, { width: 380, align: 'left'} );
  // doc.text("v", 416, 419, { width: 380, align: 'left'} );
  // doc.text("v", 148, 440, { width: 380, align: 'left'} );
  // doc.text("v", 282, 436, { width: 380, align: 'left'} );
  // doc.text("v", 416, 436, { width: 380, align: 'left'} );
  // doc.text("v", 148, 463, { width: 380, align: 'left'} );
  // doc.text("v", 282, 463, { width: 380, align: 'left'} );
  // doc.text("v", 416, 463, { width: 380, align: 'left'} );
  // doc.text("333", 465, 463, { width: 380, align: 'left'} );
  // doc.text("1", 150, 485, { width: 380, align: 'left'} );
  // doc.text("2", 370, 485, { width: 380, align: 'left'} );
  // doc.text("3", 295, 537, { width: 380, align: 'left'} );
  // doc.text("4", 295, 552, { width: 380, align: 'left'} );
  // doc.text("5", 73, 655, { width: 380, align: 'left'} );
  // doc.text("6", 206, 645, { width: 380, align: 'left'} );
  // doc.text("7", 206, 672, { width: 380, align: 'left'} );
  // doc.text("8", 252, 645, { width: 380, align: 'left'} );
  // doc.text("9", 252, 672, { width: 380, align: 'left'} );
  doc.addPage();

  const FNA_2_64 = await getDataUri(FNA_2);
  doc.addImage(FNA_2_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("100,000", 210, 118, { width: 380, align: 'left'} );
  // doc.text("100,000", 210, 160, { width: 380, align: 'left'} );
  // doc.text("100,000", 210, 202, { width: 380, align: 'left'} );
  // doc.text("100,000", 210, 244, { width: 380, align: 'left'} );
  // doc.text("100,000", 210, 294, { width: 380, align: 'left'} );
  // doc.text("200,000", 465, 115, { width: 380, align: 'left'} );
  // doc.text("200,000", 465, 142, { width: 380, align: 'left'} );
  // doc.text("200,000", 465, 169, { width: 380, align: 'left'} );
  // doc.text("200,000", 465, 196, { width: 380, align: 'left'} );
  // doc.text("200,000", 465, 223, { width: 380, align: 'left'} );
  // doc.text("200,000", 465, 250, { width: 380, align: 'left'} );
  // doc.text("200,000", 465, 294, { width: 380, align: 'left'} );
  // doc.text("200,000", 465, 326, { width: 380, align: 'left'} );
  // doc.text("300,000", 210, 383, { width: 380, align: 'left'} );
  // doc.text("300,000", 210, 410, { width: 380, align: 'left'} );
  // doc.text("300,000", 210, 437, { width: 380, align: 'left'} );
  // doc.text("300,000", 210, 464, { width: 380, align: 'left'} );
  // doc.text("300,000", 210, 498, { width: 380, align: 'left'} );
  // doc.text("300,000", 210, 542, { width: 380, align: 'left'} );
  // doc.text("300,000", 210, 569, { width: 380, align: 'left'} );
  // doc.text("300,000", 210, 596, { width: 380, align: 'left'} );
  // doc.text("300,000", 210, 630, { width: 380, align: 'left'} );
  // doc.text("400,000", 465, 387, { width: 380, align: 'left'} );
  // doc.text("400,000", 465, 441, { width: 380, align: 'left'} );
  // doc.text("400,000", 465, 507, { width: 380, align: 'left'} );
  // doc.text("400,000", 465, 572, { width: 380, align: 'left'} );
  // doc.text("400,000", 465, 633, { width: 380, align: 'left'} );
  // doc.text("400,000", 465, 668, { width: 380, align: 'left'} );
  doc.addPage();

  const FNA_3_64 = await getDataUri(FNA_3);
  doc.addImage(FNA_3_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("100,000", 225, 85, { width: 380, align: 'left'} );
  // doc.text("200,000", 225, 107, { width: 380, align: 'left'} );
  // doc.text("300,000", 225, 130, { width: 380, align: 'left'} );
  // doc.text("1", 280, 153, { width: 380, align: 'left'} );
  // doc.text("2", 60, 208, { width: 380, align: 'left'} );
  // doc.text("3", 225, 208, { width: 380, align: 'left'} );
  // doc.text("4", 60, 238, { width: 380, align: 'left'} );
  // doc.text("5", 225, 238, { width: 380, align: 'left'} );
  doc.text(pf.name_eng || '', 90, 703);
  doc.addPage();

  const FNA_4_64 = await getDataUri(FNA_4);
  doc.addImage(FNA_4_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("v", 80, 164, { width: 380, align: 'left'} );
  // doc.text("v", 80, 202, { width: 380, align: 'left'} );
  // doc.text("v", 80, 232, { width: 380, align: 'left'} );
  // doc.text("v", 290, 164, { width: 380, align: 'left'} );
  // doc.text("v", 290, 202, { width: 380, align: 'left'} );
  // doc.text("v", 290, 232, { width: 380, align: 'left'} );
  // doc.text("123", 405, 242, { width: 380, align: 'left'} );
  // doc.text("v", 80, 310, { width: 380, align: 'left'} );
  // doc.text("v", 80, 330, { width: 380, align: 'left'} );
  // doc.text("v", 80, 350, { width: 380, align: 'left'} );
  // doc.text("v", 80, 380, { width: 380, align: 'left'} );
  // doc.text("v", 80, 410, { width: 380, align: 'left'} );
  // doc.text("123", 240, 410, { width: 380, align: 'left'} );
  // doc.text("v", 80, 477, { width: 380, align: 'left'} );
  // doc.text("v", 217, 477, { width: 380, align: 'left'} );
  // doc.text("v", 357, 477, { width: 380, align: 'left'} );
  // doc.text("v", 80, 490, { width: 380, align: 'left'} );
  // doc.text("v", 217, 490, { width: 380, align: 'left'} );
  // doc.text("v", 357, 490, { width: 380, align: 'left'} );
  // doc.text("v", 95, 589, { width: 380, align: 'left'} );
  // doc.text("123", 138, 611, { width: 380, align: 'left'} );
  // doc.text("v", 296, 589, { width: 380, align: 'left'} );
  // doc.text("v", 311, 602, { width: 380, align: 'left'} );
  // doc.text("v", 311, 615, { width: 380, align: 'left'} );
  // doc.text("v", 311, 628, { width: 380, align: 'left'} );
  // doc.text("v", 311, 640, { width: 380, align: 'left'} );
  // doc.text("v", 311, 653, { width: 380, align: 'left'} );
  doc.addPage();

  const FNA_5_64 = await getDataUri(FNA_5);
  doc.addImage(FNA_5_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("v", 136, 113, { width: 380, align: 'left'} );
  // doc.text("v", 258, 113, { width: 380, align: 'left'} );
  // doc.text("v", 382, 113, { width: 380, align: 'left'} );
  // doc.text("v", 136, 133, { width: 380, align: 'left'} );
  // doc.text("v", 258, 133, { width: 380, align: 'left'} );
  // doc.text("v", 382, 133, { width: 380, align: 'left'} );
  // doc.text("v", 136, 153, { width: 380, align: 'left'} );
  // doc.text("123", 277, 156, { width: 380, align: 'left'} );
  // doc.text("456,678", 177, 178, { width: 380, align: 'left'} );
  // doc.text("v", 96, 493, { width: 380, align: 'left'} );
  // doc.text("v", 225, 493, { width: 380, align: 'left'} );
  // doc.text("v", 347, 493, { width: 380, align: 'left'} );
  // doc.text("v", 96, 507, { width: 380, align: 'left'} );
  // doc.text("v", 225, 507, { width: 380, align: 'left'} );
  // doc.text("v", 347, 507, { width: 380, align: 'left'} );
  // doc.text("v", 96, 575, { width: 380, align: 'left'} );
  // doc.text("v", 225, 575, { width: 380, align: 'left'} );
  // doc.text("v", 347, 575, { width: 380, align: 'left'} );
  // doc.text("v", 96, 589, { width: 380, align: 'left'} );
  // doc.text("v", 225, 589, { width: 380, align: 'left'} );
  // doc.text("v", 347, 589, { width: 380, align: 'left'} );
  // doc.text("v", 96, 641, { width: 380, align: 'left'} );
  // doc.text("v", 225, 641, { width: 380, align: 'left'} );
  // doc.text("v", 347, 641, { width: 380, align: 'left'} );
  // doc.text("v", 96, 651, { width: 380, align: 'left'} );
  // doc.text("v", 225, 651, { width: 380, align: 'left'} );
  // doc.text("123", 330, 663, { width: 380, align: 'left'} );
  doc.addPage();

  const FNA_6_64 = await getDataUri(FNA_6);
  doc.addImage(FNA_6_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("1", 106, 153, { width: 380, align: 'left'} );
  // doc.text("2", 206, 153, { width: 380, align: 'left'} );
  // doc.text("3", 306, 153, { width: 380, align: 'left'} );
  // doc.text("4", 426, 153, { width: 380, align: 'left'} );
  // doc.text("1", 106, 173, { width: 380, align: 'left'} );
  // doc.text("2", 206, 173, { width: 380, align: 'left'} );
  // doc.text("3", 306, 173, { width: 380, align: 'left'} );
  // doc.text("4", 426, 173, { width: 380, align: 'left'} );
  doc.text(pf.name_eng || '', 100, 695);
  doc.addPage();

  const FNA_7_64 = await getDataUri(FNA_7);
  doc.addImage(FNA_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 110, 468);
}
export const pdfas60 = async (doc, pf) => {
  doc.setFont('Courier');
  doc.setFontSize(9);
  const TOPUP_1_64 = await getDataUri(TOPUP_1);
  doc.addImage(TOPUP_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_2_64 = await getDataUri(TOPUP_2);
  doc.addImage(TOPUP_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 220, 279);
  doc.text(pf.account_num || '', 220, 307);
  doc.text("Grandtag Financial Consultancy & Insurance Brokers Limited", 220, 376);
  doc.text("9800042", 220, 403);
  doc.text("investments@grandtag.com", 220, 509);
  doc.addPage();

  const TOPUP_3_64 = await getDataUri(TOPUP_3);
  doc.addImage(TOPUP_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.last_name || '', 220, 229);
  doc.text(pf.first_name || '', 220, 265);
  doc.text(pf.apply_passport || '', 220, 310);
  doc.text(pf.addr1_eng || '', 220, 345, {
    maxWidth: 180,
    align: 'left'
  });
  doc.text(pf.apply_telephone || '', 220, 555);
  doc.text(pf.apply_cellphone || '', 220, 605);
  doc.text(pf.email || '', 220, 630);
  doc.text(pf.custom_1 || '', 220, 668);
  doc.addPage();

  const TOPUP_4_64 = await getDataUri(TOPUP_4);
  doc.addImage(TOPUP_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.fpi_now_same_payment || '', 220, 230);
  doc.text(pf.custom_2 || '', 220, 259);
  // doc.text("1", 220, 225);
  // doc.text("2", 220, 252);
  doc.addPage();

  const TOPUP_5_64 = await getDataUri(TOPUP_5);
  doc.addImage(TOPUP_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_6_64 = await getDataUri(TOPUP_6);
  doc.addImage(TOPUP_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_7_64 = await getDataUri(TOPUP_7);
  doc.addImage(TOPUP_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_8_64 = await getDataUri(TOPUP_8);
  doc.addImage(TOPUP_8_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_9_64 = await getDataUri(TOPUP_9);
  doc.addImage(TOPUP_9_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_10_64 = await getDataUri(TOPUP_10);
  doc.addImage(TOPUP_10_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_11_64 = await getDataUri(TOPUP_11);
  doc.addImage(TOPUP_11_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_12_64 = await getDataUri(TOPUP_12);
  doc.addImage(TOPUP_12_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_13_64 = await getDataUri(TOPUP_13);
  doc.addImage(TOPUP_13_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HONG KONG", 350, 450);
  doc.text("HONG KONG", 350, 487);
  doc.text(pf.name_eng || '', 220, 625);
  doc.addPage();

  const TOPUP_14_64 = await getDataUri(TOPUP_14);
  doc.addImage(TOPUP_14_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_15_64 = await getDataUri(TOPUP_15);
  doc.addImage(TOPUP_15_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_16_64 = await getDataUri(TOPUP_16);
  doc.addImage(TOPUP_16_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_17_64 = await getDataUri(TOPUP_17);
  doc.addImage(TOPUP_17_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_18_64 = await getDataUri(TOPUP_18);
  doc.addImage(TOPUP_18_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_19_64 = await getDataUri(TOPUP_19);
  doc.addImage(TOPUP_19_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_20_64 = await getDataUri(TOPUP_20);
  doc.addImage(TOPUP_20_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_21_64 = await getDataUri(TOPUP_21);
  doc.addImage(TOPUP_21_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.creditcard_bank || '', 220, 283);
  doc.text(pf.creditcard_bankname || '', 220, 308);
  doc.text(pf.creditcard_num || '', 218, 333, {
    maxWidth: 380,
    align: 'left',
    charSpace: 10
  });
  doc.text(pf.creditcard_term_month || '', 218, 357, {
    maxWidth: 380,
    align: 'left',
    charSpace: 12
  });
  doc.text(pf.creditcard_term_year || '', 288, 357, {
    maxWidth: 380,
    align: 'left',
    charSpace: 12
  });
  doc.text(pf.custom_2 || '', 220, 382);
  doc.text(pf.custom_3 || '', 220, 402);
  doc.text(pf.account_num || '', 220, 451);
  doc.text(pf.name_eng || '', 220, 554);
  doc.text(pf.addr1_eng || '', 220, 579, {
    maxWidth: 380,
    lineHeightFactor: 1.6,
    align: 'left'

  });
  doc.text(pf.contactnum || '', 220, 655);
  doc.text(pf.email || '', 220, 699);
  doc.addPage();

  const TOPUP_22_64 = await getDataUri(TOPUP_22);
  doc.addImage(TOPUP_22_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_23_64 = await getDataUri(TOPUP_23);
  doc.addImage(TOPUP_23_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_1_64 = await getDataUri(IFS_P_U_1);
  doc.addImage(IFS_P_U_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_2_64 = await getDataUri(IFS_P_U_2);
  doc.addImage(IFS_P_U_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_3_64 = await getDataUri(IFS_P_U_3);
  doc.addImage(IFS_P_U_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_4_64 = await getDataUri(IFS_P_U_4);
  doc.addImage(IFS_P_U_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_5_64 = await getDataUri(IFS_P_U_5);
  doc.addImage(IFS_P_U_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_6_64 = await getDataUri(IFS_P_U_6);
  doc.addImage(IFS_P_U_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_7_64 = await getDataUri(IFS_P_U_7);
  doc.addImage(IFS_P_U_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_8_64 = await getDataUri(IFS_P_U_8);
  doc.addImage(IFS_P_U_8_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_9_64 = await getDataUri(IFS_P_U_9);
  doc.addImage(IFS_P_U_9_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_10_64 = await getDataUri(IFS_P_U_10);
  doc.addImage(IFS_P_U_10_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_11_64 = await getDataUri(IFS_P_U_11);
  doc.addImage(IFS_P_U_11_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_12_64 = await getDataUri(IFS_P_U_12);
  doc.addImage(IFS_P_U_12_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.addPage();

  // doc.addImage(IFS_P_1, 0, 0, {width: a4pageWidth});
  // doc.text(pf.name_eng || '', 100, 630, { width: 380, align: 'left'} );
  // doc.addPage();

  // doc.addImage(IFS_P_2, 0, 0, {width: a4pageWidth});
  // doc.addPage();

  // doc.addImage(IFS_P_3, 0, 0, {width: a4pageWidth});
  // doc.text(pf.name_eng || '', 100, 620, { width: 380, align: 'left'} );
  // doc.addPage();

  // doc.addImage(IFS_P_4, 0, 0, {width: a4pageWidth});
  // doc.text(pf.name_eng || '', 100, 500, { width: 380, align: 'left'} );
  // doc.addPage();

  // doc.addImage(IFS_P_5, 0, 0, {width: a4pageWidth});
  // doc.text(pf.name_eng || '', 100, 660, { width: 380, align: 'left'} );
  // doc.addPage();

  // doc.addImage(IFS_P_6, 0, 0, {width: a4pageWidth});
  // doc.setFontSize(7);
  // doc.text("Grandtag Financial Consultancy & Insurance Brokers Limited", 215, 205, { width: 380, align: 'left'} );
  // doc.setFontSize(9);
  // doc.text("M189", 250, 220, { width: 380, align: 'left'} );
  // doc.text(pf.name_eng || '', 100, 510, { width: 380, align: 'left'} );
  // doc.addPage();

  // doc.addImage(IFS_P_7, 0, 0, {width: a4pageWidth});
  // doc.text(pf.name_eng || '', 100, 190, { width: 380, align: 'left'} );
  // doc.addPage();

  // doc.addImage(IFS_P_8, 0, 0, {width: a4pageWidth});
  // doc.text(pf.name_eng || '', 100, 660, { width: 380, align: 'left'} );
  // doc.addPage();

  // doc.addImage(IFS_P_9, 0, 0, {width: a4pageWidth});
  // doc.addPage();

  // doc.addImage(IFS_P_10, 0, 0, {width: a4pageWidth});
  // doc.addPage();

  // doc.addImage(IFS_P_11, 0, 0, {width: a4pageWidth});
  // doc.addPage();

  // doc.addImage(IFS_P_12, 0, 0, {width: a4pageWidth});
  doc.addPage();

  const GT_FNAC_64 = await getDataUri(GT_FNAC);
  doc.addImage(GT_FNAC_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 260, 467);
  doc.text(pf.name_eng || '', 260, 603);
  doc.text(pf.account_num || '', 260, 651);

  doc.setFontSize(12);
  doc.addPage();

  const gt_1_64 = await getDataUri(gt_1);
  doc.addImage(gt_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 255, 471);
  doc.addPage();

  const gt_2_64 = await getDataUri(gt_2);
  doc.addImage(gt_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_3_64 = await getDataUri(gt_3);
  doc.addImage(gt_3_64, 0, 0, a4pageWidth, a4pageHeight);
  // const gt_3_64 = await getDataUri(gt_3);
  doc.addImage(gt_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 190, 155);
  doc.text(pf.birthday || '', 375, 207);
  doc.text(pf.contactnum || '', 375, 365);
  doc.text(pf.addr1_eng || '', 190, 365);
  doc.addPage();

  const gt_4_64 = await getDataUri(gt_4);
  doc.addImage(gt_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_5_64 = await getDataUri(gt_5);
  doc.addImage(gt_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_6_64 = await getDataUri(gt_6);
  doc.addImage(gt_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_7_64 = await getDataUri(gt_7);
  doc.addImage(gt_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_8_64 = await getDataUri(gt_8);
  doc.addImage(gt_8_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 230, 461);
  doc.addPage();

  const gt_9_64 = await getDataUri(gt_9);
  doc.addImage(gt_9_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_10_64 = await getDataUri(gt_10);
  doc.addImage(gt_10_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_11_64 = await getDataUri(gt_11);
  doc.addImage(gt_11_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 205, 509);
  doc.addPage();

  const gt_12_64 = await getDataUri(gt_12);
  doc.addImage(gt_12_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_13_64 = await getDataUri(gt_13);
  doc.addImage(gt_13_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 170, 270);
  doc.addPage();

  const gt_14_64 = await getDataUri(gt_14);
  doc.addImage(gt_14_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 220, 649);
  doc.addPage();

  const gt_15_64 = await getDataUri(gt_15);
  doc.addImage(gt_15_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_16_64 = await getDataUri(gt_16);
  doc.addImage(gt_16_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 210, 350);
  doc.addPage();

  const gt_17_64 = await getDataUri(gt_17);
  doc.addImage(gt_17_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FNA_1_64 = await getDataUri(FNA_1);
  doc.addImage(FNA_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 150, 220);
  doc.text(pf.last_name || '', 370, 220);
  doc.setFont('Courier');
  doc.text(pf.birthday || '', 150, 258);
  doc.setFontSize(8.5);
  doc.text(pf.contactnum || '', 185, 275);
  doc.setFontSize(8.5);
  doc.text(pf.email || '', 150, 310);
  doc.setFontSize(12);
  doc.text(pf.addr1_eng || '', 150, 331);
  doc.addPage();

  const FNA_2_64 = await getDataUri(FNA_2);
  doc.addImage(FNA_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FNA_3_64 = await getDataUri(FNA_3);
  doc.addImage(FNA_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 90, 700);
  doc.addPage();

  const FNA_4_64 = await getDataUri(FNA_4);
  doc.addImage(FNA_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FNA_5_64 = await getDataUri(FNA_5);
  doc.addImage(FNA_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FNA_6_64 = await getDataUri(FNA_6);
  doc.addImage(FNA_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 100, 695);
  doc.addPage();

  const FNA_7_64 = await getDataUri(FNA_7);
  doc.addImage(FNA_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 110, 467);
}
export const pdfas61 = async (doc, pf) => {
  doc.setFont('Courier');
  doc.setFontSize(12);
  // console.log('VOYA_CPI', VOYA_CPI)
  const VOYA_CPI_64 = await getDataUri(VOYA_CPI);
  // console.log(VOYA_CPI_64)
  doc.addImage(VOYA_CPI_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 140, 252);
  doc.text(pf.account_num || '', 380, 252);
  doc.text(pf.trust_name || '', 200, 290);
  doc.text(pf.name_eng || '', 120, 450);
  doc.text(pf.custom_1 || '', 380, 450);
  doc.text(pf.email || '', 370, 600);
  doc.text(pf.custom_2 || '', 100, 635);
  doc.text(pf.custom_3 || '', 100, 750);
}
export const pdfas62 = async (doc, pf) => {
  doc.setFont('Courier');
  doc.setFontSize(12);
  const VOYA_TO_1_64 = await getDataUri(VOYA_TO_1);
  doc.addImage(VOYA_TO_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 140, 527);
  doc.text(pf.account_num || '', 480, 527);
  doc.text(pf.trust_grantor || '', 140, 558);
  doc.text(pf.custom_1 || '', 140, 645);
  doc.text(pf.custom_2 || '', 480, 645);
  doc.text(pf.custom_3 || '', 220, 670);
  doc.text(pf.custom_4 || '', 180, 700);
  doc.addPage();

  const VOYA_TO_2_64 = await getDataUri(VOYA_TO_2);
  doc.addImage(VOYA_TO_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(10);
  doc.text(pf.trust_name || '', 80, 97);
  doc.setFontSize(12);
  doc.text(pf.trust_trustee || '', 100, 125);
  doc.text(pf.custom_2 || '', 140, 150);
  doc.addPage();

  const VOYA_TO_3_64 = await getDataUri(VOYA_TO_3);
  doc.addImage(VOYA_TO_3_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas63 = async (doc, pf) => {
  doc.setFont('Courier');
  doc.setFontSize(12);
  const VOYA_BDV_1_64 = await getDataUri(VOYA_BDV_1);
  doc.addImage(VOYA_BDV_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const VOYA_BDV_2_64 = await getDataUri(VOYA_BDV_2);
  doc.addImage(VOYA_BDV_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const VOYA_BDV_3_64 = await getDataUri(VOYA_BDV_3);
  doc.addImage(VOYA_BDV_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 140, 310);
  doc.text(pf.account_num || '', 470, 310);
  doc.setFontSize(8);
  doc.text(pf.trust_name || '', 128, 335);
  doc.setFontSize(11);
  doc.text(pf.beneficial1_name || '', 60, 455);
  doc.text(pf.beneficial1_percent || '', 480, 455);
  doc.text(pf.beneficial2_name || '', 60, 505);
  doc.text(pf.beneficial2_percent || '', 480, 505);
  doc.text(pf.beneficial3_name || '', 60, 555);
  doc.text(pf.beneficial3_percent || '', 480, 555);
  doc.text(pf.beneficial4_name || '', 60, 605);
  doc.text(pf.beneficial4_percent || '', 480, 605);
  doc.setFontSize(12);
  doc.addPage();

  const VOYA_BDV_4_64 = await getDataUri(VOYA_BDV_4);
  doc.addImage(VOYA_BDV_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.trust_name || '', 100, 390);
  doc.text(pf.trust_trustee || '', 100, 420);
  doc.text(pf.trust_ssnitin || '', 330, 420);
  doc.text(pf.trust_lawyer || '', 100, 450);
  doc.addPage();

  const VOYA_BDV_5_64 = await getDataUri(VOYA_BDV_5);
  doc.addImage(VOYA_BDV_5_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas64 = async (doc, pf) => {
  /*  const doc = new PDFDocument({
      size: 'A4',
      margin: 1
    });
    doc.registerFont('BiauKai', BiauKaiTTF);
    doc.registerFont('msjh', msjhTTF); */
  doc.setFont('Courier');
  doc.setFontSize(12);
  const FPI_TT_64 = await getDataUri(FPI_TT);
  doc.addImage(FPI_TT_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.account_num || '', 300, 418);
  doc.text(pf.name_eng || '', 300, 448);
  doc.text(pf.fpi_now_same_payment || '', 330, 477);
  doc.text(String((Number(pf.fpi_now_same_payment) + 20)) || '', 510, 477);
  doc.text(pf.fpi_now_same_payment || '', 350, 497);
}
export const pdfas65 = async (doc, pf) => {
  /*  const doc = new PDFDocument({
      size: 'A4',
      margin: 1
    });
    doc.registerFont('BiauKai', BiauKaiTTF);
    doc.registerFont('msjh', msjhTTF); */
  doc.setFont('Helvetica-Bold');
  doc.setFontSize(12);
  const VOYA_WF_64 = await getDataUri(VOYA_WF);
  doc.addImage(VOYA_WF_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.fpi_now_same_payment || '', 240, 488, { width: 380, align: 'left' });
  doc.text((Number(pf.fpi_now_same_payment) + 35) || '', 450, 488);
  doc.text(pf.account_num || '', 330, 525);
  doc.text(pf.name_eng || '', 330, 555);
}
export const pdfas66 = async (doc, pf) => {
  doc.setFont('Courier');
  doc.setFontSize(14);
  const NEW_Third_1_64 = await getDataUri(NEW_Third_1);
  doc.addImage(NEW_Third_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.policy_firstperson || '', 220, 335);
  // doc.text("2", 220, 350, { width: 380, align: 'left'} );
  doc.text(pf.email || '', 220, 428);
  // doc.text("4", 220, 419, { width: 380, align: 'left'} );
  doc.text(pf.insertedBy || '', 220, 449);
  // doc.text("6", 220, 465, { width: 380, align: 'left'} );
  doc.text(pf.account_num || '', 220, 495);
  doc.text(pf.beneficial1_name2 || '', 220, 637);
  doc.text(pf.beneficial1_name2 || '', 220, 660);
  doc.text(pf.beneficial1_percent || '', 523, 660);
  doc.text(pf.beneficial1_relationship || '', 220, 683);
  doc.text(pf.beneficial1_relationship || '', 220, 706);
  doc.text(pf.custom_1 || '', 220, 728);
  doc.text(pf.custom_2 || '', 220, 750);
  doc.text(pf.custom_3 || '', 220, 773);
  doc.setFontSize(12);
  doc.text(pf.custom_4 || '', 220, 795, {
    width: 320,
    align: 'left'
  });
  doc.setFontSize(14);
  doc.addPage();

  const NEW_Third_2_64 = await getDataUri(NEW_Third_2);
  doc.addImage(NEW_Third_2_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.text("1", 220, 108, { width: 380, align: 'left'} );
  // doc.text("2", 220, 131, { width: 380, align: 'left'} );
  // doc.text("3", 523, 131, { width: 380, align: 'left'} );
  // doc.text("4", 220, 154, { width: 380, align: 'left'} );
  // doc.text("5", 220, 177, { width: 380, align: 'left'} );
  // doc.text("6", 220, 200, { width: 380, align: 'left'} );
  // doc.text("7", 220, 223, { width: 380, align: 'left'} );
  // doc.text("8", 220, 246, { width: 380, align: 'left'} );
  // doc.text("9", 220, 269, { width: 380, align: 'left'} );
  // doc.text("1", 220, 340, { width: 380, align: 'left'} );
  // doc.text("2", 220, 363, { width: 380, align: 'left'} );
  // doc.text("3", 523, 363, { width: 380, align: 'left'} );
  // doc.text("4", 220, 385, { width: 380, align: 'left'} );
  // doc.text("5", 220, 408, { width: 380, align: 'left'} );
  // doc.text("6", 220, 431, { width: 380, align: 'left'} );
  // doc.text("7", 220, 453, { width: 380, align: 'left'} );
  // doc.text("8", 220, 476, { width: 380, align: 'left'} );
  // doc.text("9", 220, 499, { width: 380, align: 'left'} );
  doc.text("Hanbury IFA Corporation Limited", 220, 670);
  doc.text("+886 2 2758 2239", 220, 693);
  doc.text("info@hanburyifa.com", 220, 716);
  doc.setFontSize(12);
  doc.text("5F., No. 400, Sec.1, Keelung Road., Xinyi District., Taipei City 11051, Taiwan", 220, 738);
  doc.setFontSize(14);
  doc.addPage();

  const NEW_Third_3_64 = await getDataUri(NEW_Third_3);
  doc.addImage(NEW_Third_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const NEW_Third_4_64 = await getDataUri(NEW_Third_4);
  doc.addImage(NEW_Third_4_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas67 = async (doc, pf) => {
  doc.setFont('Courier');
  doc.setFontSize(14);
  const FPI_CSA_64 = await getDataUri(FPI_CSA);
  doc.addImage(FPI_CSA_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.account_num || '', 270, 269);
  doc.text(pf.name_eng || '', 270, 305);
}
export const pdfas18a = (doc, pf) => {
  doc.setFont('Courier');
  doc.setFontSize(15);
  doc.addImage(FPI_CMEA, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("12" + " " + "12" + " " + "2012", 80, 75, {
    width: 380,
    align: 'left',
    characterSpacing: 7.3
  });
  doc.text("123", 190, 233);
  doc.text("456", 190, 270);
  doc.text("789", 190, 307);
  doc.text("123", 265, 415);
  doc.text("456", 265, 450);
  doc.text("789", 95, 485);
}
export const pdfas19a = (doc, pf) => {
  doc.setFont('Courier');
  doc.addImage(FPI_Self, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text("asd", 165, 114);
  doc.text("zxc", 165, 127);
  doc.setFontSize(15);
  doc.text("1", 150, 220);
  doc.text("2", 150, 244);
  doc.text("3", 150, 268);
  doc.text("4", 150, 292);
  doc.text("5", 310, 220);
  doc.text("6", 310, 244);
  doc.text("7", 310, 268);
  doc.text("8", 310, 292);
  doc.text("1", 150, 390);
  doc.text("2", 150, 414);
  doc.text("3", 150, 438);
  doc.text("4", 150, 462);
  doc.text("5", 310, 390);
  doc.text("6", 310, 414);
  doc.text("7", 310, 438);
  doc.text("8", 310, 462);
}
export const pdfas81 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_RPBWS_1_64 = await getDataUri(FPI_RPBWS_1);
  doc.addImage(FPI_RPBWS_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_2_64 = await getDataUri(FPI_RPBWS_2);
  doc.addImage(FPI_RPBWS_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_3_64 = await getDataUri(FPI_RPBWS_3);
  doc.addImage(FPI_RPBWS_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_4_64 = await getDataUri(FPI_RPBWS_4);
  doc.addImage(FPI_RPBWS_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_5_64 = await getDataUri(FPI_RPBWS_5);
  doc.addImage(FPI_RPBWS_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_6_64 = await getDataUri(FPI_RPBWS_6);
  doc.addImage(FPI_RPBWS_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_7_64 = await getDataUri(FPI_RPBWS_7);
  doc.addImage(FPI_RPBWS_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_8_64 = await getDataUri(FPI_RPBWS_8);
  doc.addImage(FPI_RPBWS_8_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas82 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_RPBWS_1_64 = await getDataUri(FPI_RPBWS_1);
  doc.addImage(FPI_RPBWS_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_2_64 = await getDataUri(FPI_RPBWS_2);
  doc.addImage(FPI_RPBWS_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_3_64 = await getDataUri(FPI_RPBWS_3);
  doc.addImage(FPI_RPBWS_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_4_64 = await getDataUri(FPI_RPBWS_4);
  doc.addImage(FPI_RPBWS_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_5_64 = await getDataUri(FPI_RPBWS_5);
  doc.addImage(FPI_RPBWS_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_6_64 = await getDataUri(FPI_RPBWS_6);
  doc.addImage(FPI_RPBWS_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_7_64 = await getDataUri(FPI_RPBWS_7);
  doc.addImage(FPI_RPBWS_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_8_64 = await getDataUri(FPI_RPBWS_8);
  doc.addImage(FPI_RPBWS_8_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas83 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_RPBWS_1_64 = await getDataUri(FPI_RPBWS_1);
  doc.addImage(FPI_RPBWS_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_2_64 = await getDataUri(FPI_RPBWS_2);
  doc.addImage(FPI_RPBWS_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_3_64 = await getDataUri(FPI_RPBWS_3);
  doc.addImage(FPI_RPBWS_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_4_64 = await getDataUri(FPI_RPBWS_4);
  doc.addImage(FPI_RPBWS_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_5_64 = await getDataUri(FPI_RPBWS_5);
  doc.addImage(FPI_RPBWS_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_6_64 = await getDataUri(FPI_RPBWS_6);
  doc.addImage(FPI_RPBWS_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_7_64 = await getDataUri(FPI_RPBWS_7);
  doc.addImage(FPI_RPBWS_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_8_64 = await getDataUri(FPI_RPBWS_8);
  doc.addImage(FPI_RPBWS_8_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas84 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_CCCA_1_64 = await getDataUri(FPI_CCCA_1);
  doc.addImage(FPI_CCCA_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.addPage();

  const FPI_CCCA_2_64 = await getDataUri(FPI_CCCA_2);
  doc.addImage(FPI_CCCA_2_64, 0, 0, a4pageWidth, a4pageHeight);

  doc.text(pf.custom_1 || '', 225, 233);
  doc.text(pf.custom_2 || '', 225, 258);
  doc.text(pf.custom_3 || '', 225, 332);
  doc.text(pf.custom_4 || '', 225, 358);
  doc.text("v", 365, 382);
  doc.text(pf.account_num + "/" + pf.account_num2, 220, 408);
  doc.text(pf.creditcard_name || '', 220, 512);
  doc.text(pf.creditcard_addr || '', 220, 554, {
    maxWidth: 380,
    align: 'left',
    lineHeightFactor: 5
  });
  doc.text(pf.contactnum || '', 220, 612);
  doc.text(pf.email || '', 220, 637);
  doc.addPage();

  const FPI_CCCA_3_64 = await getDataUri(FPI_CCCA_3);
  doc.addImage(FPI_CCCA_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_CCCA_4_64 = await getDataUri(FPI_CCCA_4);
  doc.addImage(FPI_CCCA_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_CCCA_5_64 = await getDataUri(FPI_CCCA_5);
  doc.addImage(FPI_CCCA_5_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas85 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_AOTPAP_1_64 = await getDataUri(FPI_AOTPAP_1);
  doc.addImage(FPI_AOTPAP_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 225, 342);
  doc.text(pf.email || '', 225, 411);
  doc.text(pf.insertedBy || '', 225, 457);
  doc.text(pf.account_num + "/" + pf.account_num2, 225, 501);
  doc.text(pf.custom_1 || '', 225, 647);
  doc.text(pf.custom_2 || '', 225, 669);
  doc.text(pf.custom_3 || '', 225, 692);
  doc.text(pf.custom_4 || '', 225, 714);
  doc.text(pf.custom_5 || '', 225, 737);
  doc.text(pf.custom_6 || '', 225, 759);
  doc.text(pf.custom_7 || '', 225, 782);
  doc.text(pf.custom_8 || '', 225, 804);
  doc.text(pf.custom_9 || '', 523, 670);
  doc.addPage();

  const FPI_AOTPAP_2_64 = await getDataUri(FPI_AOTPAP_2);
  doc.addImage(FPI_AOTPAP_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("Hanbury IFA Corporation Limited", 220, 679);
  doc.text("+886 2 2758 2239", 220, 702);
  doc.text("info@hanburyifa.com", 220, 725);
  doc.text("5F., No. 400, Sec.1, Keelung Road., Xinyi District., Taipei City 11051, Taiwan", 220, 747, {
    maxWidth: 380,
    align: 'left',
    lineGap: 8
  });
  doc.addPage();

  const FPI_AOTPAP_3_64 = await getDataUri(FPI_AOTPAP_3);
  doc.addImage(FPI_AOTPAP_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_AOTPAP_4_64 = await getDataUri(FPI_AOTPAP_4);
  doc.addImage(FPI_AOTPAP_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_AOTPAP_5_64 = await getDataUri(FPI_AOTPAP_5);
  doc.addImage(FPI_AOTPAP_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_AOTPAP_6_64 = await getDataUri(FPI_AOTPAP_6);
  doc.addImage(FPI_AOTPAP_6_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas86 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_RPBWS_1_64 = await getDataUri(FPI_RPBWS_1);
  doc.addImage(FPI_RPBWS_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.policy_firstperson || '', 220, 320);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 220, 363, {
    maxWidth: 320,
    align: 'left',
    lineHeightFactor: 1.8
  });
  doc.setFontSize(12);
  doc.text(pf.insertedBy || '', 220, 447);
  doc.text(pf.account_num + "/" + pf.account_num2, 220, 470);
  doc.text(pf.email || '', 220, 493);
  doc.text(pf.contactnum || '', 220, 516);
  doc.text("info@hanburyifa.com", 220, 562);
  doc.text("Personal Use", 220, 667);

  doc.addPage();

  const FPI_RPBWS_2_64 = await getDataUri(FPI_RPBWS_2);
  doc.addImage(FPI_RPBWS_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("V", 265, 302);
  doc.addPage();

  const FPI_RPBWS_3_64 = await getDataUri(FPI_RPBWS_3);
  doc.addImage(FPI_RPBWS_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("V", 222, 158);
  doc.text(pf.policy_firstperson || '', 225, 283);

  doc.addPage();

  const FPI_RPBWS_4_64 = await getDataUri(FPI_RPBWS_4);
  doc.addImage(FPI_RPBWS_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_5_64 = await getDataUri(FPI_RPBWS_5);
  doc.addImage(FPI_RPBWS_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_6_64 = await getDataUri(FPI_RPBWS_6);
  doc.addImage(FPI_RPBWS_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_7_64 = await getDataUri(FPI_RPBWS_7);
  doc.addImage(FPI_RPBWS_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_8_64 = await getDataUri(FPI_RPBWS_8);
  doc.addImage(FPI_RPBWS_8_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas87 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_RPBWS_1_64 = await getDataUri(FPI_RPBWS_1);
  doc.addImage(FPI_RPBWS_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.policy_firstperson || '', 220, 320);
  doc.setFontSize(9);
  doc.text(pf.addr1_eng || '', 220, 363, {
    maxWidth: 320,
    align: 'left',
    lineHeightFactor: 1.8
  });
  doc.setFontSize(12);
  doc.text(pf.insertedBy || '', 220, 447);
  doc.text(pf.account_num + "/" + pf.account_num2, 220, 470);
  doc.text(pf.email || '', 220, 493);
  doc.text(pf.contactnum || '', 220, 516);
  doc.text("info@hanburyifa.com", 220, 562);
  doc.text("Personal Use", 220, 667);

  doc.addPage();

  const FPI_RPBWS_2_64 = await getDataUri(FPI_RPBWS_2);
  doc.addImage(FPI_RPBWS_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("V", 265, 302);
  doc.addPage();

  const FPI_RPBWS_3_64 = await getDataUri(FPI_RPBWS_3);
  doc.addImage(FPI_RPBWS_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("V", 222, 158);
  doc.text("Wells Fargo Bank", 225, 229);
  doc.text("420 Montgomery, San Francisco, CA 94104 U.S.A", 225, 252, {
    maxWidth: 320,
    align: 'left',
    lineHeightFactor: 1.5
  });
  doc.text("Security Life of Denver", 225, 290);
  doc.text("413-2697-228", 225, 315);
  doc.text("WFBIUS6S", 225, 365);
  doc.text("12100248", 225, 450);
  doc.text("Policy Number: " + pf.custom_1, 50, 705);
  doc.text("Policy Holder: " + pf.custom_2, 50, 718);
  doc.text("Amount: " + pf.custom_3, 50, 731);

  doc.addPage();

  const FPI_RPBWS_4_64 = await getDataUri(FPI_RPBWS_4);
  doc.addImage(FPI_RPBWS_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_5_64 = await getDataUri(FPI_RPBWS_5);
  doc.addImage(FPI_RPBWS_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_6_64 = await getDataUri(FPI_RPBWS_6);
  doc.addImage(FPI_RPBWS_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_7_64 = await getDataUri(FPI_RPBWS_7);
  doc.addImage(FPI_RPBWS_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_RPBWS_8_64 = await getDataUri(FPI_RPBWS_8);
  doc.addImage(FPI_RPBWS_8_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas88 = async (doc, pf) => {
  doc.setFont('Courier');
  const update_54_1_64 = await getDataUri(update_54_1);
  doc.addImage(update_54_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.account_num + "/" + pf.account_num2, 220, 373);
  doc.text(pf.name_eng || '', 220, 424);
  doc.setFontSize(9);
  doc.text(pf.email || '', 220, 716);
  doc.setFontSize(9);
  doc.addPage();

  const update_54_2_64 = await getDataUri(update_54_2);
  doc.addImage(update_54_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const update_54_3_64 = await getDataUri(update_54_3);
  doc.addImage(update_54_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const update_54_4_64 = await getDataUri(update_54_4);
  doc.addImage(update_54_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const update_54_5_64 = await getDataUri(update_54_5);
  doc.addImage(update_54_5_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas89 = async (doc, pf) => {
  doc.setFont('Courier');
  const signature_1_64 = await getDataUri(signature_1);
  doc.addImage(signature_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const signature_2_64 = await getDataUri(signature_2);
  doc.addImage(signature_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const signature_3_64 = await getDataUri(signature_3);
  doc.addImage(signature_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const signature_4_64 = await getDataUri(signature_4);
  doc.addImage(signature_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const signature_5_64 = await getDataUri(signature_5);
  doc.addImage(signature_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const signature_6_64 = await getDataUri(signature_6);
  doc.addImage(signature_6_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas90 = async (doc, pf) => {
  doc.setFont('Courier');
  const policy_92_1_64 = await getDataUri(policy_92_1);
  doc.addImage(policy_92_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.account_num + "/" + pf.account_num2, 220, 366);
  doc.text(pf.name_eng || '', 220, 388);
  doc.text("V", 173, 433);
  doc.text(pf.email || '', 220, 595);
  doc.addPage();

  const policy_92_2_64 = await getDataUri(policy_92_2);
  doc.addImage(policy_92_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const policy_92_3_64 = await getDataUri(policy_92_3);
  doc.addImage(policy_92_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const policy_92_4_64 = await getDataUri(policy_92_4);
  doc.addImage(policy_92_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const policy_92_5_64 = await getDataUri(policy_92_5);
  doc.addImage(policy_92_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const policy_92_6_64 = await getDataUri(policy_92_6);
  doc.addImage(policy_92_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const policy_92_7_64 = await getDataUri(policy_92_7);
  doc.addImage(policy_92_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const policy_92_8_64 = await getDataUri(policy_92_8);
  doc.addImage(policy_92_8_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const policy_92_9_64 = await getDataUri(policy_92_9);
  doc.addImage(policy_92_9_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const policy_92_10_64 = await getDataUri(policy_92_10);
  doc.addImage(policy_92_10_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const policy_92_11_64 = await getDataUri(policy_92_11);
  doc.addImage(policy_92_11_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const policy_92_12_64 = await getDataUri(policy_92_12);
  doc.addImage(policy_92_12_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const policy_92_13_64 = await getDataUri(policy_92_13);
  doc.addImage(policy_92_13_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas68 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_68_1_64 = await getDataUri(FPI_68_1);
  doc.addImage(FPI_68_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const BSO_64 = await getDataUri(BSO);
  doc.addImage(BSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text("HSBC", 170, 173);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 95, 191);
  doc.text(pf.hsbc_account_num || '', 50, 275);
  doc.text(pf.policy_currency_type || '', 50, 315);
  doc.text("HSBCHKHHHKH", 340, 315);
  doc.text(pf.name_eng || '', 50, 346);
  doc.text(pf.fpi_now_same_payment || '', 137, 427);
  doc.setFontSize(9);
  doc.text(pf.fpi_now_same_payment_words || '', 340, 427);
  doc.setFontSize(12);
  doc.text(pf.check || '', 50, 470);
  doc.text(pf.addr1_eng || '', 150, 712, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 50, 812, {
    maxWidth: 380,
    align: 'left',
    charSpace: 14
  });
  doc.addPage();

  const NEW_1_64 = await getDataUri(NEW_1);
  doc.addImage(NEW_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.creditcard_bank || '', 223, 311);
  doc.text(pf.creditcard_bankname || '', 223, 334);
  doc.text(pf.creditcard_num || '', 223, 358, {
    maxWidth: 380,
    align: 'left',
    charSpace: 8.5
  });
  doc.text(pf.creditcard_term_month || '', 223, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 10.5
  });
  doc.text(pf.creditcard_term_year || '', 300, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 10.5
  });
  doc.text(pf.fpi_now_same_payment || '', 223, 404);
  doc.text(pf.fpi_now_same_payment_words || '', 223, 424);
  doc.text(pf.policy_currency_type || '', 223, 444);
  doc.text(pf.account_num || '', 223, 466);
  doc.text(pf.name_eng || '', 223, 537);
  doc.text(pf.addr1_eng || '', 223, 560, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.contactnum || '', 223, 637);
  doc.text(pf.email || '', 223, 662, {
    maxWidth: 380,
    lineHeightFactpr: 1.6,
    charSpace: 10,
    align: 'left'
  });
  doc.addPage();

  const NEW_2_64 = await getDataUri(NEW_2);
  doc.addImage(NEW_2_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas69 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_68_1_64 = await getDataUri(FPI_68_1);
  doc.addImage(FPI_68_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const BSO_64 = await getDataUri(BSO);
  doc.addImage(BSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text("HSBC", 170, 173);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 95, 191);
  doc.text(pf.hsbc_account_num || '', 50, 275);
  doc.text(pf.policy_currency_type || '', 50, 315);
  doc.text("HSBCHKHHHKH", 340, 315);
  doc.text(pf.name_eng || '', 50, 346);
  doc.text(pf.fpi_now_same_payment || '', 137, 427);
  doc.setFontSize(9);
  doc.text(pf.fpi_now_same_payment_words || '', 340, 427);
  doc.setFontSize(12);
  doc.text(pf.check || '', 50, 470);
  doc.text(pf.addr1_eng || '', 150, 712, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 50, 812, {
    maxWidth: 380,
    align: 'left',
    charSpace: 14
  });
  doc.addPage();

  const NEW_1_64 = await getDataUri(NEW_1);
  doc.addImage(NEW_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.creditcard_bank || '', 223, 311);
  doc.text(pf.creditcard_bankname || '', 223, 334);
  doc.text(pf.creditcard_num || '', 223, 358, {
    maxWidth: 380,
    align: 'left',
    charSpace: 8.5
  });
  doc.text(pf.creditcard_term_month || '', 223, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 10.5
  });
  doc.text(pf.creditcard_term_year || '', 300, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 10.5
  });
  doc.text(pf.fpi_now_same_payment || '', 223, 404);
  doc.text(pf.fpi_now_same_payment_words || '', 223, 424);
  doc.text(pf.policy_currency_type || '', 223, 444);
  doc.text(pf.account_num || '', 223, 466);
  doc.text(pf.name_eng || '', 223, 537);
  doc.text(pf.addr1_eng || '', 223, 560, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.contactnum || '', 223, 637);
  doc.text(pf.email || '', 223, 662, {
    maxWidth: 380,
    align: 'left',
    charSpace: 10,
    lineHeightFactor: 1.6
  });
  doc.addPage();

  const NEW_2_64 = await getDataUri(NEW_2);
  doc.addImage(NEW_2_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas70 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_70_1_64 = await getDataUri(FPI_70_1);
  doc.addImage(FPI_70_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_70_2_64 = await getDataUri(FPI_70_2);
  doc.addImage(FPI_70_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_70_3_64 = await getDataUri(FPI_70_3);
  doc.addImage(FPI_70_3_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.addPage();

  // doc.addImage(FPI_70_4, 0, 0, a4pageWidth, a4pageHeight);
  // doc.addPage();

  // doc.addImage(FPI_70_5, 0, 0, a4pageWidth, a4pageHeight);
  // doc.addPage();

  // doc.addImage(FPI_70_6, 0, 0, a4pageWidth, a4pageHeight);
  // doc.addPage();

  // doc.addImage(FPI_70_7, 0, 0, a4pageWidth, a4pageHeight);
  // doc.addPage();

  // doc.addImage(FPI_70_8, 0, 0, a4pageWidth, a4pageHeight);
  // doc.addPage();

  // doc.addImage(FPI_70_9, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const BSO_64 = await getDataUri(BSO);
  doc.addImage(BSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text("HSBC", 170, 173);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 95, 191);
  doc.text(pf.hsbc_account_num || '', 50, 275);
  doc.text(pf.policy_currency_type || '', 50, 315);
  doc.text("HSBCHKHHHKH", 340, 315);
  doc.text(pf.name_eng || '', 50, 346);
  doc.text(pf.fpi_now_same_payment || '', 137, 427);
  doc.setFontSize(9);
  doc.text(pf.fpi_now_same_payment_words || '', 340, 427);
  doc.setFontSize(12);
  doc.text(pf.check || '', 50, 470);
  doc.text(pf.addr1_eng || '', 150, 712, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.account_num2 || '', 50, 812, {
    maxWidth: 380,
    align: 'left',
    charSpace: 14
  });
  doc.addPage();

  const NEW_1_64 = await getDataUri(NEW_1);
  doc.addImage(NEW_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.creditcard_bank || '', 223, 311);
  doc.text(pf.creditcard_bankname || '', 223, 334);
  // doc.setCharSpace(8.5)
  doc.text(pf.creditcard_num || '', 223, 358, {
    maxWidth: 380,
    align: 'left',
    charSpace: 9.5
  });
  doc.text(pf.creditcard_term_month || '', 223, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.5
  });
  doc.text(pf.creditcard_term_year || '', 300, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.5
  });
  doc.text(pf.fpi_now_same_payment || '', 223, 404);
  doc.text(pf.fpi_now_same_payment_words || '', 223, 424);
  doc.text(pf.policy_currency_type || '', 223, 444);
  doc.text(pf.account_num || '', 223, 466);
  doc.text(pf.name_eng || '', 223, 537);
  doc.text(pf.addr1_eng || '', 223, 560, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text(pf.contactnum || '', 223, 637);
  doc.text(pf.email || '', 223, 662, {
    maxWidth: 150,
    lineHeightFactor: 1.6,
    charSpace: 10,
    align: 'left'
  });
  doc.addPage();

  const NEW_2_64 = await getDataUri(NEW_2);
  doc.addImage(NEW_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FNA_1_64 = await getDataUri(FNA_1);
  doc.addImage(FNA_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 150, 215);
  doc.text(pf.last_name || '', 370, 215);
  doc.setFont('Courier');
  doc.text(pf.birthday || '', 150, 253);
  doc.setFontSize(8.5);
  doc.text(pf.contactnum || '', 185, 283);
  doc.setFontSize(8.5);
  doc.text(pf.email || '', 150, 305);
  doc.setFontSize(12);
  doc.text(pf.addr1_eng || '', 150, 331, {
    maxWidth: 120,
    align: 'left'
  });
  doc.addPage();

  const FNA_2_64 = await getDataUri(FNA_2);
  doc.addImage(FNA_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FNA_3_64 = await getDataUri(FNA_3);
  doc.addImage(FNA_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 90, 700);
  doc.addPage();

  const FNA_4_64 = await getDataUri(FNA_4);
  doc.addImage(FNA_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FNA_5_64 = await getDataUri(FNA_5);
  doc.addImage(FNA_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FNA_6_64 = await getDataUri(FNA_6);
  doc.addImage(FNA_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 100, 695);
  doc.addPage();

  const FNA_7_64 = await getDataUri(FNA_7);
  doc.addImage(FNA_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 110, 460);
  doc.setFontSize(12);
  doc.addPage();

  const gt_1_64 = await getDataUri(gt_1);
  doc.addImage(gt_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 255, 466);
  doc.addPage();

  const gt_2_64 = await getDataUri(gt_2);
  doc.addImage(gt_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_3_64 = await getDataUri(gt_3);
  doc.addImage(gt_3_64, 0, 0, a4pageWidth, a4pageHeight);
  // const gt_3_64 = await getDataUri(gt_3);
  doc.addImage(gt_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 190, 150);
  doc.text(pf.birthday || '', 375, 207);
  doc.text(pf.contactnum || '', 375, 365);
  doc.text(pf.addr1_eng || '', 190, 365);
  doc.addPage();

  const gt_4_64 = await getDataUri(gt_4);
  doc.addImage(gt_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_5_64 = await getDataUri(gt_5);
  doc.addImage(gt_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_6_64 = await getDataUri(gt_6);
  doc.addImage(gt_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_7_64 = await getDataUri(gt_7);
  doc.addImage(gt_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_8_64 = await getDataUri(gt_8);
  doc.addImage(gt_8_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 230, 460);
  doc.addPage();

  const gt_9_64 = await getDataUri(gt_9);
  doc.addImage(gt_9_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_10_64 = await getDataUri(gt_10);
  doc.addImage(gt_10_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_11_64 = await getDataUri(gt_11);
  doc.addImage(gt_11_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 200, 498);
  doc.addPage();

  const gt_12_64 = await getDataUri(gt_12);
  doc.addImage(gt_12_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_13_64 = await getDataUri(gt_13);
  doc.addImage(gt_13_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 170, 278);
  doc.addPage();

  const gt_14_64 = await getDataUri(gt_14);
  doc.addImage(gt_14_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 220, 650);
  doc.addPage();

  const gt_15_64 = await getDataUri(gt_15);
  doc.addImage(gt_15_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_16_64 = await getDataUri(gt_16);
  doc.addImage(gt_16_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 210, 347);
  doc.addPage();

  const gt_17_64 = await getDataUri(gt_17);
  doc.addImage(gt_17_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const GT_FNAC_64 = await getDataUri(GT_FNAC);
  doc.addImage(GT_FNAC_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 260, 475);
  doc.text(pf.insertedBy || '', 260, 605);
  doc.text(pf.account_num || '', 260, 653);
  doc.addPage();

  const IFS_P_1_64 = await getDataUri(IFS_P_1);
  doc.addImage(IFS_P_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 100, 630);
  doc.addPage();

  const IFS_P_2_64 = await getDataUri(IFS_P_2);
  doc.addImage(IFS_P_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_3_64 = await getDataUri(IFS_P_3);
  doc.addImage(IFS_P_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 100, 620);
  doc.addPage();

  const IFS_P_4_64 = await getDataUri(IFS_P_4);
  doc.addImage(IFS_P_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 100, 500);
  doc.addPage();

  const IFS_P_5_64 = await getDataUri(IFS_P_5);
  doc.addImage(IFS_P_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 100, 660);
  doc.addPage();

  const IFS_P_6_64 = await getDataUri(IFS_P_6);
  doc.addImage(IFS_P_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(7);
  doc.text("Grandtag Financial Consultancy & Insurance Brokers Limited", 215, 206);
  doc.setFontSize(9);
  doc.text("M189", 250, 223);
  doc.text(pf.name_eng || '', 100, 510);
  doc.addPage();

  const IFS_P_7_64 = await getDataUri(IFS_P_7);
  doc.addImage(IFS_P_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 100, 190);
  doc.addPage();

  const IFS_P_8_64 = await getDataUri(IFS_P_8);
  doc.addImage(IFS_P_8_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 100, 660);
  doc.addPage();

  const IFS_P_9_64 = await getDataUri(IFS_P_9);
  doc.addImage(IFS_P_9_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_10_64 = await getDataUri(IFS_P_10);
  doc.addImage(IFS_P_10_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_11_64 = await getDataUri(IFS_P_11);
  doc.addImage(IFS_P_11_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_12_64 = await getDataUri(IFS_P_12);
  doc.addImage(IFS_P_12_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(9);
  const TOPUP_1_64 = await getDataUri(TOPUP_1);
  doc.addImage(TOPUP_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_2_64 = await getDataUri(TOPUP_2);
  doc.addImage(TOPUP_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.name_eng || '', 220, 278);
  doc.text(pf.account_num || '', 220, 306);
  doc.text("Grandtag Financial Consultancy & Insurance Brokers Limited", 220, 374);
  doc.text("9800042", 220, 402);
  doc.text("investments@grandtag.com", 220, 508);
  doc.addPage();

  const TOPUP_3_64 = await getDataUri(TOPUP_3);
  doc.addImage(TOPUP_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.last_name || '', 220, 229);
  doc.text(pf.first_name || '', 220, 264);
  doc.text(pf.apply_passport || '', 220, 309);
  doc.text(pf.addr1_eng || '', 220, 344, {
    maxWidth: 180,
    align: 'left'
  });
  doc.text(pf.apply_telephone || '', 220, 554);
  doc.text(pf.apply_cellphone || '', 220, 604);
  doc.text(pf.email || '', 220, 629);
  doc.text(pf.custom_1 || '', 220, 669);
  doc.addPage();

  const TOPUP_4_64 = await getDataUri(TOPUP_4);
  doc.addImage(TOPUP_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.fpi_now_same_payment || '', 220, 229);
  doc.text(pf.custom_2 || '', 220, 256);
  // doc.text("1", 220, 225);
  // doc.text("2", 220, 252);
  doc.addPage();

  const TOPUP_5_64 = await getDataUri(TOPUP_5);
  doc.addImage(TOPUP_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_6_64 = await getDataUri(TOPUP_6);
  doc.addImage(TOPUP_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_7_64 = await getDataUri(TOPUP_7);
  doc.addImage(TOPUP_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_8_64 = await getDataUri(TOPUP_8);
  doc.addImage(TOPUP_8_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_9_64 = await getDataUri(TOPUP_9);
  doc.addImage(TOPUP_9_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_10_64 = await getDataUri(TOPUP_10);
  doc.addImage(TOPUP_10_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_11_64 = await getDataUri(TOPUP_11);
  doc.addImage(TOPUP_11_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_12_64 = await getDataUri(TOPUP_12);
  doc.addImage(TOPUP_12_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_13_64 = await getDataUri(TOPUP_13);
  doc.addImage(TOPUP_13_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HONG KONG", 350, 450);
  doc.text("HONG KONG", 350, 487);
  doc.text(pf.name_eng || '', 220, 625);
  doc.addPage();

  const TOPUP_14_64 = await getDataUri(TOPUP_14);
  doc.addImage(TOPUP_14_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_15_64 = await getDataUri(TOPUP_15);
  doc.addImage(TOPUP_15_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_16_64 = await getDataUri(TOPUP_16);
  doc.addImage(TOPUP_16_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_17_64 = await getDataUri(TOPUP_17);
  doc.addImage(TOPUP_17_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_18_64 = await getDataUri(TOPUP_18);
  doc.addImage(TOPUP_18_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_19_64 = await getDataUri(TOPUP_19);
  doc.addImage(TOPUP_19_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_20_64 = await getDataUri(TOPUP_20);
  doc.addImage(TOPUP_20_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_21_64 = await getDataUri(TOPUP_21);
  doc.addImage(TOPUP_21_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text(pf.creditcard_bank || '', 220, 282);
  doc.text(pf.creditcard_bankname || '', 220, 307);
  doc.text(pf.creditcard_num || '', 218, 332, {
    maxWidth: 380,
    align: 'left',
    charSpace: 10
  });
  doc.text(pf.creditcard_term_month || '', 218, 355, {
    maxWidth: 380,
    align: 'left',
    charSpace: 10.5
  });
  doc.text(pf.creditcard_term_year || '', 288, 355, {
    maxWidth: 380,
    align: 'left',
    charSpace: 10.5
  });
  doc.text(pf.custom_2 || '', 220, 382);
  doc.text(pf.custom_3 || '', 220, 402);
  doc.text(pf.account_num || '', 220, 451);
  doc.text(pf.name_eng || '', 220, 553);
  doc.text(pf.addr1_eng || '', 220, 578);
  doc.text(pf.contactnum || '', 220, 654);
  doc.text(pf.email || '', 220, 699, {
    maxWidth: 320,
    lineHeightFactor: 1.6,
    align: 'left'
  });
  doc.addPage();

  const TOPUP_22_64 = await getDataUri(TOPUP_22);
  doc.addImage(TOPUP_22_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_23_64 = await getDataUri(TOPUP_23);
  doc.addImage(TOPUP_23_64, 0, 0, a4pageWidth, a4pageHeight);
}
export const pdfas71 = async (doc, pf) => {
  doc.setFont('Courier');
  const FPI_70_1_64 = await getDataUri(FPI_70_1);
  doc.addImage(FPI_70_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_70_2_64 = await getDataUri(FPI_70_2);
  doc.addImage(FPI_70_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FPI_70_3_64 = await getDataUri(FPI_70_3);
  doc.addImage(FPI_70_3_64, 0, 0, a4pageWidth, a4pageHeight);
  // doc.addPage();

  // doc.addImage(FPI_70_4, 0, 0, a4pageWidth, a4pageHeight);
  // doc.addPage();

  // doc.addImage(FPI_70_5, 0, 0, a4pageWidth, a4pageHeight);
  // doc.addPage();

  // doc.addImage(FPI_70_6, 0, 0, a4pageWidth, a4pageHeight);
  // doc.addPage();

  // doc.addImage(FPI_70_7, 0, 0, a4pageWidth, a4pageHeight);
  // doc.addPage();

  // doc.addImage(FPI_70_8, 0, 0, a4pageWidth, a4pageHeight);
  // doc.addPage();

  // doc.addImage(FPI_70_9, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const BSO_64 = await getDataUri(BSO);
  doc.addImage(BSO_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(12);
  doc.text("HSBC", 170, 173);
  doc.text("HSBC Main Building 1 Queen's Road Central, HK", 95, 191);
  doc.text("pf.hsbc_account_num" || '', 50, 275);
  doc.text("pf.policy_currency_type" || '', 50, 315);
  doc.text("HSBCHKHHHKH", 340, 315);
  doc.text("pf.name_eng" || '', 50, 346);
  doc.text("pf.fpi_now_same_payment" || '', 140, 427);
  doc.setFontSize(9);
  doc.text("pf.fpi_now_same_payment_words" || '', 340, 427);
  doc.setFontSize(12);
  doc.text("pf.check" || '', 50, 470);
  doc.text("pf.addr1_eng" || '', 150, 712, {
    maxWidth: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text("pf.account_num2" || '', 50, 812, {
    maxWidth: 380,
    align: 'left',
    charSpace: 14
  });
  doc.addPage();

  const NEW_1_64 = await getDataUri(NEW_1);
  doc.addImage(NEW_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("pf.creditcard_bank" || '', 223, 311);
  doc.text("pf.creditcard_bankname" || '', 223, 334);
  doc.text("pf.creditcard_num" || '', 223, 358, {
    maxWidth: 380,
    align: 'left',
    charSpace: 8.5
  });
  doc.text("pf.creditcard_term_month" || '', 223, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.5
  });
  doc.text("pf.creditcard_term_year" || '', 300, 382, {
    maxWidth: 380,
    align: 'left',
    charSpace: 11.5
  });
  doc.text("pf.fpi_now_same_payment" || '', 223, 404);
  doc.text("pf.fpi_now_same_payment_words" || '', 223, 424);
  doc.text("pf.policy_currency_type" || '', 223, 444);
  doc.text("pf.account_num" || '', 223, 466);
  doc.text("pf.name_eng" || '', 223, 537);
  doc.text("pf.addr1_eng" || '', 223, 560, {
    width: 320,
    align: 'left'
  });
  doc.setFont('Courier');
  doc.text("pf.contactnum" || '', 223, 637);
  doc.text("pf.email" || '', 223, 662, {
    maxWidth: 380,
    charSpace: 10,
    lineHeightFactor: 1.6,
    align: 'left'
  });
  doc.addPage();

  const NEW_2_64 = await getDataUri(NEW_2);
  doc.addImage(NEW_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FNA_1_64 = await getDataUri(FNA_1);
  doc.addImage(FNA_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("pf.name_eng" || '', 150, 220);
  doc.text("pf.last_name" || '', 370, 220);
  doc.setFont('Courier');
  doc.text("pf.birthday" || '', 150, 253);
  doc.setFontSize(8.5);
  doc.text("pf.contactnum" || '', 185, 280);
  doc.setFontSize(8.5);
  doc.text("pf.email" || '', 150, 308);
  doc.setFontSize(12);
  doc.text("pf.addr1_eng" || '', 150, 332);
  doc.addPage();

  const FNA_2_64 = await getDataUri(FNA_2);
  doc.addImage(FNA_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FNA_3_64 = await getDataUri(FNA_3);
  doc.addImage(FNA_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("pf.name_eng" || '', 90, 705);
  doc.addPage();

  const FNA_4_64 = await getDataUri(FNA_4);
  doc.addImage(FNA_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FNA_5_64 = await getDataUri(FNA_5);
  doc.addImage(FNA_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const FNA_6_64 = await getDataUri(FNA_6);
  doc.addImage(FNA_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("pf.name_eng" || '', 100, 700);
  doc.addPage();

  const FNA_7_64 = await getDataUri(FNA_7);
  doc.addImage(FNA_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("pf.name_eng" || '', 110, 470);
  doc.setFontSize(12);
  doc.addPage();

  const gt_1_64 = await getDataUri(gt_1);
  doc.addImage(gt_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("pf.name_eng" || '', 255, 470);
  doc.addPage();

  const gt_2_64 = await getDataUri(gt_2);
  doc.addImage(gt_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_3_64 = await getDataUri(gt_3);
  doc.addImage(gt_3_64, 0, 0, a4pageWidth, a4pageHeight);
  // const gt_3_64 = await getDataUri(gt_3);
  doc.addImage(gt_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("pf.name_eng" || '', 190, 155);
  doc.text("pf.birthday" || '', 375, 205);
  doc.text("pf.contactnum" || '', 375, 365);
  doc.text("pf.addr1_eng" || '', 190, 365);
  doc.addPage();

  const gt_4_64 = await getDataUri(gt_4);
  doc.addImage(gt_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_5_64 = await getDataUri(gt_5);
  doc.addImage(gt_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_6_64 = await getDataUri(gt_6);
  doc.addImage(gt_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_7_64 = await getDataUri(gt_7);
  doc.addImage(gt_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_8_64 = await getDataUri(gt_8);
  doc.addImage(gt_8_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("pf.name_eng" || '', 230, 460);
  doc.addPage();

  const gt_9_64 = await getDataUri(gt_9);
  doc.addImage(gt_9_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_10_64 = await getDataUri(gt_10);
  doc.addImage(gt_10_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_11_64 = await getDataUri(gt_11);
  doc.addImage(gt_11_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("pf.name_eng" || '', 208, 510);
  doc.addPage();

  const gt_12_64 = await getDataUri(gt_12);
  doc.addImage(gt_12_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_13_64 = await getDataUri(gt_13);
  doc.addImage(gt_13_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("pf.name_eng" || '', 170, 350);
  doc.addPage();

  const gt_14_64 = await getDataUri(gt_14);
  doc.addImage(gt_14_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("pf.name_eng" || '', 220, 655);
  doc.addPage();

  const gt_15_64 = await getDataUri(gt_15);
  doc.addImage(gt_15_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const gt_16_64 = await getDataUri(gt_16);
  doc.addImage(gt_16_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("pf.name_eng" || '', 210, 350);
  doc.addPage();

  const gt_17_64 = await getDataUri(gt_17);
  doc.addImage(gt_17_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const GT_FNAC_64 = await getDataUri(GT_FNAC);
  doc.addImage(GT_FNAC_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("pf.name_eng" || '', 260, 475);
  doc.text("pf.insertedBy" || '', 260, 605);
  doc.text("pf.account_num" || '', 260, 655);
  doc.addPage();

  const IFS_P_U_1_64 = await getDataUri(IFS_P_U_1);
  doc.addImage(IFS_P_U_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_2_64 = await getDataUri(IFS_P_U_2);
  doc.addImage(IFS_P_U_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_3_64 = await getDataUri(IFS_P_U_3);
  doc.addImage(IFS_P_U_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_4_64 = await getDataUri(IFS_P_U_4);
  doc.addImage(IFS_P_U_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_5_64 = await getDataUri(IFS_P_U_5);
  doc.addImage(IFS_P_U_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_6_64 = await getDataUri(IFS_P_U_6);
  doc.addImage(IFS_P_U_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_7_64 = await getDataUri(IFS_P_U_7);
  doc.addImage(IFS_P_U_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_8_64 = await getDataUri(IFS_P_U_8);
  doc.addImage(IFS_P_U_8_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_9_64 = await getDataUri(IFS_P_U_9);
  doc.addImage(IFS_P_U_9_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_10_64 = await getDataUri(IFS_P_U_10);
  doc.addImage(IFS_P_U_10_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_11_64 = await getDataUri(IFS_P_U_11);
  doc.addImage(IFS_P_U_11_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const IFS_P_U_12_64 = await getDataUri(IFS_P_U_12);
  doc.addImage(IFS_P_U_12_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.setFontSize(9);
  const TOPUP_1_64 = await getDataUri(TOPUP_1);
  doc.addImage(TOPUP_1_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_2_64 = await getDataUri(TOPUP_2);
  doc.addImage(TOPUP_2_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("pf.name_eng" || '', 220, 281);
  doc.text("pf.account_num" || '', 220, 309);
  doc.text("Grandtag Financial Consultancy & Insurance Brokers Limited", 220, 377);
  doc.text("9800042", 220, 405);
  doc.text("investments@grandtag.com", 220, 511);
  doc.addPage();

  const TOPUP_3_64 = await getDataUri(TOPUP_3);
  doc.addImage(TOPUP_3_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("pf.last_name" || '', 220, 231);
  doc.text("pf.first_name" || '', 220, 266);
  doc.text("pf.apply_passport" || '', 220, 311);
  doc.text("pf.addr1_eng" || '', 220, 346, {
    maxWidth: 180,
    lineHeightFactor: 1.6,
    align: 'left'
  });
  doc.text("pf.apply_telephone" || '', 220, 557);
  doc.text("pf.apply_cellphone" || '', 220, 607);
  doc.text("pf.email" || '', 220, 632);
  doc.text("pf.custom_1" || '', 220, 669);
  doc.addPage();

  const TOPUP_4_64 = await getDataUri(TOPUP_4);
  doc.addImage(TOPUP_4_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("pf.fpi_now_same_payment" || '', 220, 231);
  doc.text("pf.custom_2" || '', 220, 258);
  // doc.text("1", 220, 225);
  // doc.text("2", 220, 252);
  doc.addPage();

  const TOPUP_5_64 = await getDataUri(TOPUP_5);
  doc.addImage(TOPUP_5_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_6_64 = await getDataUri(TOPUP_6);
  doc.addImage(TOPUP_6_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_7_64 = await getDataUri(TOPUP_7);
  doc.addImage(TOPUP_7_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_8_64 = await getDataUri(TOPUP_8);
  doc.addImage(TOPUP_8_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_9_64 = await getDataUri(TOPUP_9);
  doc.addImage(TOPUP_9_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_10_64 = await getDataUri(TOPUP_10);
  doc.addImage(TOPUP_10_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_11_64 = await getDataUri(TOPUP_11);
  doc.addImage(TOPUP_11_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_12_64 = await getDataUri(TOPUP_12);
  doc.addImage(TOPUP_12_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_13_64 = await getDataUri(TOPUP_13);
  doc.addImage(TOPUP_13_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("HONG KONG", 350, 451);
  doc.text("HONG KONG", 350, 489);
  doc.text("pf.name_eng" || '', 220, 626);
  doc.addPage();

  const TOPUP_14_64 = await getDataUri(TOPUP_14);
  doc.addImage(TOPUP_14_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_15_64 = await getDataUri(TOPUP_15);
  doc.addImage(TOPUP_15_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_16_64 = await getDataUri(TOPUP_16);
  doc.addImage(TOPUP_16_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_17_64 = await getDataUri(TOPUP_17);
  doc.addImage(TOPUP_17_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_18_64 = await getDataUri(TOPUP_18);
  doc.addImage(TOPUP_18_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_19_64 = await getDataUri(TOPUP_19);
  doc.addImage(TOPUP_19_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_20_64 = await getDataUri(TOPUP_20);
  doc.addImage(TOPUP_20_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_21_64 = await getDataUri(TOPUP_21);
  doc.addImage(TOPUP_21_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.text("pf.creditcard_bank" || '', 220, 283);
  doc.text("pf.creditcard_bankname" || '', 220, 308);
  doc.text("pf.creditcard_num" || '', 218, 333, {
    maxWidth: 380,
    align: 'left',
    charSpace: 9.8
  });
  doc.text("pf.creditcard_term_month" || '', 218, 358, {
    maxWidth: 380,
    align: 'left',
    charSpace: 12
  });
  doc.text("pf.creditcard_term_year" || '', 288, 358, {
    maxWidth: 380,
    align: 'left',
    charSpace: 12
  });
  doc.text("pf.custom_2" || '', 220, 383);
  doc.text("pf.custom_3" || '', 220, 403);
  doc.text("pf.account_num" || '', 220, 453);
  doc.text("pf.name_eng" || '', 220, 555);
  doc.text("pf.addr1_eng" || '', 220, 580, {
    maxWidth: 380,
    align: 'left',
    lineHeightFactor: 1.6
  });
  doc.text("pf.contactnum" || '', 220, 656);
  doc.text("pf.email" || '', 220, 701);
  doc.addPage();

  const TOPUP_22_64 = await getDataUri(TOPUP_22);
  doc.addImage(TOPUP_22_64, 0, 0, a4pageWidth, a4pageHeight);
  doc.addPage();

  const TOPUP_23_64 = await getDataUri(TOPUP_23);
  doc.addImage(TOPUP_23_64, 0, 0, a4pageWidth, a4pageHeight);
}
