import React, { Component } from 'react'
// import { useParams } from "react-router-dom";
import { Header, Form, Grid, Menu, Input, Dimmer, Loader, Image, Button, Checkbox, FormGroup } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import {
  // Container,
  // Table,
  Row,
  Col,
  // Badge,
  // Button,
  // Card,
  // CardBody,
  // CardTitle,
} from "reactstrap";
import { DelayInput } from 'react-delay-input';
import { Select, MenuItem } from '@material-ui/core';

// import MaterialTable, { MTableToolbar } from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
// import ModalNewDonate from './ModalNewDonate';
import ModalAutoAddr from './ModalAutoAddr';
// import RowsDnd from './RowsDnd'
import MyDimmer from '../../layouts/Dimmer';
// import TablePeople from './TablePeople';
// import fetchUrl from '../../widget/http';
import { typeCurrency, rowReOrder } from 'views/web/CustomMaterialTable';
import ModalMultiInput from './ModalMultiInput';

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}
// const url = 'https://restful.infowin.com.tw/api/people2';
let checked = false

const unitOpt = { '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', '6': '6', '7': '7', '8': '8', '9': '9', '10': '10' }
const payStatusOpt = { '1': '分期', '2': '募資', '3': '付清', '4': '匯款', '5': '劃撥', '6': '外幣' }
const payeeOpt = { '1': '唐秋如', '2': '釋妙逸', '3': '紀謝碧雲', '4': '陳雪', '5': '釋覺玥', '6': '林淑吟', '7': '楊素芬', '8': '釋覺勝', '9': '許允', '10': '林麗琴', '11': '呂陳乖', '12': '吳淑女', '13': '陳好樣' }
const advisePeopleOpt = { '1': '龍雲寺', '2': '紀謝碧雲', '3': '白全利', '4': '蓮社', '5': '陳好樣', '6': '釋覺文', '7': '福慧共修會' }
const columns = (pray2Obj, pray2Arr, praytypeObj, thatprops) => ([
  // {
  //   width: 80, title: '捐款項目', field: 'pray2Text',
  //   render: rowData => rowData.pray2Text,
  //   editComponent: props => {
  //     return <Select
  //       value={props.rowData.pray2Id}
  //       onChange={e => {
  //         const acc2Id = e.target.value;
  //         const acc2Obj = pray2Obj.arr.find(item => item.objectId === acc2Id);
  //         const acc2Text = (acc2Obj && acc2Obj.value) || '';
  //         props.onRowDataChange({ ...props.rowData, pray2Id: acc2Id, pray2Text: acc2Text })
  //       }}
  //     >
  //       {
  //         pray2Arr.map((item, index) =>
  //           (<MenuItem key={'a' + item.objectId + index} value={item.objectId}>{item.value}</MenuItem>))}
  //     </Select>
  //   }
  // },
  // { width: 90, title: '發心日期', field: 'donateDate' },
  // { width: 80, title: '付款狀況', field: 'payStatusId', lookup: payStatusOpt },
  // { width: 80, title: '單位', field: 'unit', lookup: unitOpt },
  // { width: 100, title: '備註', field: 'ps' },
  // { width: 120, title: '小計', field: 'price', ...typeCurrency },
  // {
  //   width: 80, title: '收款人', field: 'payeeId', lookup: payeeOpt,
  //   render: rowData => <>{rowData.payee}</>,
  // },
  // {
  //   width: 80, title: '勸募人', field: 'advisePeopleId', lookup: advisePeopleOpt,
  //   render: rowData => <>{rowData.advisePeople}</>,
  // },
  // { width: 80, title: '帳本', field: 'ledger' },
  // { width: 80, title: '收據號碼', field: 'receiptNum' },
  { width: 150, title: '認捐人姓名', field: 'name' },
  {
    width: 100, title: '捐款項目', field: 'pray2Id',
    render: rowData => pray2Obj.sel[rowData.pray2Id],
    editComponent: props => {
      return <Select
        value={props.rowData.pray2Id}
        onChange={e => {
          const acc2Id = e.target.value;
          const acc2Obj = pray2Obj.arr.find(item => item.objectId === acc2Id);
          const acc2Text = (acc2Obj && acc2Obj.value) || '';
          props.onRowDataChange({ ...props.rowData, pray2Id: acc2Id, pray2Text: acc2Text })
        }}
      >
        {
          pray2Arr.map((item, index) =>
            (<MenuItem key={'a' + item.objectId + index} value={item.objectId}>{item.value}</MenuItem>))}
      </Select>
    }
  },
  { width: 90, title: '發心日期', field: 'donateDate' },
  { width: 80, title: '付款狀況', field: 'payStatusId', lookup: payStatusOpt },
  { width: 80, title: '單位', field: 'unit', lookup: unitOpt },
  { width: 100, title: '備註', field: 'ps' },
  { width: 120, title: '小計', field: 'price', ...typeCurrency },
  // { width: 80, title: '收款人', field: 'payee' },
  {
    width: 80, title: '收款人', field: 'payeeId', lookup: payeeOpt,
    render: rowData => <>{rowData.payee}</>,
  },
  {
    width: 80, title: '勸募人', field: 'advisePeopleId', lookup: advisePeopleOpt,
    render: rowData => <>{rowData.advisePeople}</>,
  },
  { width: 80, title: '帳本', field: 'ledger' },
  { width: 80, title: '收據號碼', field: 'receiptNum' },
  {
    width: 100, title: '選擇(代入地址)', field: 'postcode_select',
    headerStyle: { textAlign: 'center' },
    render: rowData => <></>,
    editComponent: props => <ModalAutoAddr {...thatprops} field='addr' type="2" rowData={props.rowData} onRowDataChange={props.onRowDataChange} />
  },
  { width: 200, title: '地址', field: 'addr' },
  { width: 130, title: '牌位', field: 'praytypeId', lookup: praytypeObj.sel },
  {
    width: 80, title: '列印', field: 'isPrint',
    render: rowData => <Checkbox checked={Number(rowData.isPrint)} />,
    editComponent: props => <Checkbox checked={Number(props.rowData.isPrint)} onChange={(e, data) => {
      if (data.checked) {
        props.onRowDataChange({ ...props.rowData, isPrint: '1' })
      } else {
        props.onRowDataChange({ ...props.rowData, isPrint: '0' })
      }
    }
    } />
  },
]);

const fetchPeople = async (companyObj, params, searchText) => {
  const { page = 1, pageSize = 5 } = params
  const companyId = companyObj.objectId;

  // console.log(companyId,
  //   searchText,
  //   page,
  //   pageSize)
  if (checked) {
    return await Parse.Cloud.run("findPeople", {
      companyId,
      searchText,
      page,
      pageSize,
    });
  } else {
    // console.log(params, searchText)
    let query = new Parse.Query('donate');

    if (searchText) {
      const p0 = new Parse.Query("donate");
      p0.startsWith("name", searchText);

      const p1 = new Parse.Query("donate");
      p1.startsWith("receiptNum", searchText);

      const p2 = new Parse.Query("donate");
      p2.startsWith("ledger", searchText);

      const p3 = new Parse.Query("donate");
      p3.startsWith("ps", searchText);

      query = Parse.Query.or(p0, p1, p2, p3);
    }


    query.equalTo("companyId", companyId);

    query.exists("name")
    query.notEqualTo("name", '')
    // query.select(['name', 'donateDate', 'payStatus', 'unit',]);
    const totalCount = await query.count();

    // if (params.sorter) {
    //   const s = params.sorter.split('_');

    //   if (s[1] === 'descend') {
    //     query.descending(s[0]);
    //   } else {
    //     query.ascending(s[0]);
    //   }

    //   // Sorts the results in descending order by the score field
    // }
    query.limit(pageSize);
    query.skip((Number(page)) * (pageSize));

    const snap = await query.find();
    const data = snap.map(data => data.toJSON()).sort((a, b) => a.receiptNum - b.receiptNum);
    //.sort((a, b) => ('' + a.receiptNum).localeCompare(b.receiptNum))
    // .sort((a, b) => a.receiptNum - b.receiptNum);
    return {
      data, //sort((a, b) => a.ledger.substring(1, 4) - b.ledger.substring(1, 4))
      page,
      totalCount,
    }
  }
}

class Family extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loading1: true,
      familyId: '',
      searchValue: '',
      people: [],
      templeclassSel: {},
      selectData: {},
      pray2Obj: {},
      data2: [],
      praytypeObj: {}
    }
    this.tableRef = React.createRef();
  }

  async componentDidMount() {
    // const { params, companyObj } = this.props;
    // const familyId = params.familyId || '';
    // this.setState({ familyId })
    const { companyObj } = this.props
    const companyId = companyObj.objectId;
    const pray2Obj = await Parse.getSelection('pray2', { companyId, pray1Id: '1pzegsRGTy' }, { name: 'value', sort: 'order' });
    console.log(pray2Obj);
    const praytypeObj = await Parse.getSelection('praytype', { companyId }, { name: 'value', sort: 'name' });
    // let templeclassObj = await Parse.getSelection('templeclass', { companyId }, { name: 'value', sort: 'order' });
    // let templelevelObj = await Parse.getSelection('templelevel', { companyId }, { name: 'value', sort: 'order' });
    // const praytypeObj = await Parse.getSelection('praytype', { companyId }, { name: 'value', sort: 'order' });
    // const pray1Obj = await Parse.getSelection('pray1', { companyId }, { name: 'value', sort: 'order' });
    // Parse.fetchDocument('company', companyId).then(companyObjLive => this.setState({ companyObjLive }));
    // templeclassObj.sel = { '-1': '請選擇', ...templeclassObj.sel }
    // templelevelObj.sel = { '-1': '請選擇', ...templelevelObj.sel }
    // const selectData = { templeclassObj, templelevelObj, praytypeObj, pray1Obj }
    // this.setState({ selectData }, () => this.handleFetch())
    this.setState({ praytypeObj, pray2Obj, loading: false, loading1: false }, () => this.handleFetch())
  }

  // componentWillReceiveProps(nextProps) {
  //   // console.log(nextProps);
  //   if (!nextProps.params || nextProps.params.familyId !== this.props.params.familyId) {
  //     const familyId = nextProps.params.familyId || '';
  //     this.setState({ familyId, loading: true }, () => this.handleFetch(nextProps));
  //   }
  //   if (typeof nextProps.params.familyId === 'undefined') {
  //     this.setState({ loading: false, familyId: '', people: [] })
  //   }
  // }

  handleFetch = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const { searchValue } = this.state
    const searchText = searchValue.trim()
    let query = new Parse.Query('donate');

    if (searchText) {
      const p0 = new Parse.Query("donate");
      p0.startsWith("name", searchText);

      const p1 = new Parse.Query("donate");
      p1.startsWith("receiptNum", searchText);

      const p2 = new Parse.Query("donate");
      p2.startsWith("ledger", searchText);

      const p3 = new Parse.Query("donate");
      p3.startsWith("ps", searchText);

      query = Parse.Query.or(p0, p1, p2, p3);
    }


    query.equalTo("companyId", companyId);

    query.exists("name")
    query.doesNotExist("donate1Id")
    query.notEqualTo("name", '')
    query.limit(30000)
    const snap = await query.find();
    // const data = snap.map(data => data.toJSON())
    const data = snap.map(data => data.toJSON()).sort((a, b) => ('' + a.ledger).localeCompare(b.ledger));
    this.setState({ data1: data })
  }

  handleClickName = async (rowData) => {
    this.setState({ rowData: {} })
    const data2 = await Parse.queryData('donate', { donate1Id: rowData.objectId })
    this.setState({ rowData, data2 })
  }

  // handleLoadFamily = async (familyId) => {
  //   if (!familyId) {
  //     return;
  //   }
  //   const { companyObj } = this.props;
  //   const companyId = companyObj.objectId;
  //   const pp = await Parse.queryData("people", { companyId, familyId })
  //   const people = pp.sort((a, b) => ('' + a.name).localeCompare(b.name))
  //   // console.log(people)
  //   this.setState({ loading: false, familyId, people })
  // }

  handleChange = async (e, { name, value }) => {
    // this.setState({ [name]: value.trim() }, () => this.tableRef.current.onQueryChange());
    this.setState({ [name]: value.trim() }, () => this.handleFetch());
  }

  clear = () => {
    this.ref.inputRef.current.value = '';
    this.setState({ searchValue: '' }, () => this.tableRef.current.onQueryChange())
  }

  handleAdd = async (type) => {
    const { newData, newData1 } = this.state
    if (type === '1') {
      await Parse.saveData('donate', newData);
    } else {
      await Parse.saveData('donate', newData1);
    }
  }

  handleUpdate = async (type) => {
    const { newData, newData1 } = this.state
    if (type === '1') {
      await Parse.saveData('donate', newData);
    } else {
      await Parse.saveData('donate', newData1);
    }
  }

  handleDelete = async (type) => {
    const { oldData, oldData1 } = this.state
    if (type === '1') {
      await Parse.deleteData('donate', oldData);
    } else {
      await Parse.deleteData('donate', oldData1);
    }
    this.setState({ oldData: {} })
  }

  render() {
    const { companyObj } = this.props
    const { familyId, searchValue, loading, loading1, people, dataArr, pray2Obj, data1, data2, rowData, praytypeObj } = this.state;
    const pray2Sel = pray2Obj.sel || {}
    const pray2Arr = pray2Obj.arr || []
    return (<>
      <div className="content">
        {!loading1 ? <>
          <div style={{ ...style.flexCenter, margin: '2rem auto' }}>
            <Row>
              <Col xs="8" sm="10">
                <Form>
                  <FormGroup widths='equal'>
                    {/* <DelayInput
                    inputRef={ref => { this.ref = ref; }}
                    name='searchValue'
                    placeholder='請輸入信眾姓名、電話、手機'
                    style={{ width: '100%', minWidth: 350, float: 'left' }}
                    onChange={(e) => this.handleChange(e, { name: 'searchValue', value: e.target.value })}
                    // minLength={1}
                    delayTimeout={500}
                    action={{ icon: 'cancel', onClick: this.clear }}
                    element={Input}
                  /> */}
                    <DelayInput
                      inputRef={ref => { this.ref = ref; }}
                      name='searchValue'
                      placeholder='請輸入信眾姓名、電話、手機'
                      style={{ width: '100%', minWidth: 350, float: 'left' }}
                      onChange={(e) => this.handleChange(e, { name: 'searchValue', value: e.target.value })}
                      // minLength={1}
                      delayTimeout={500}
                      action={{ icon: 'cancel', onClick: this.clear }}
                      element={Input}
                    />
                    <Checkbox label='模糊搜尋' style={{ minWidth: '100px', marginLeft: '50px', paddingTop: '8px' }} onClick={(e, data) => checked = data.checked} />
                    <Button.Group style={{ marginLeft: '15px' }}>
                      {/* <ModalNewDonate  {...this.props} /> */}
                    </Button.Group>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            {/* <div style={{ marginTop: '12px' }}> */}
            {/* <Header as='h4' disabled> {!searchValue ? '請輸入2個字以上 來搜尋信眾' : null}</Header> */}
            {/* <Header as='h4' disabled>  請輸入2個字以上 來搜尋信眾 </Header> */}
            {/* </div> */}
          </div>
          <Row>
            <Col xs="12" sm="12" md="12">
              <MaterialTable
                localization={localization()}
                tableRef={this.tableRef}
                title="搜尋結果，點擊載入全家名單"
                options={{
                  search: false,
                  pageSize: 50,
                  tableLayout: 'fixed',
                  paginationType: "stepped"
                }}
                columns={[
                  { width: 150, title: '認捐人姓名', field: 'name' },
                  {
                    width: 100, title: '捐款項目', field: 'pray2Id',
                    render: rowData => pray2Obj.sel[rowData.pray2Id],
                    editComponent: props => {
                      return <Select
                        value={props.rowData.pray2Id}
                        onChange={e => {
                          const acc2Id = e.target.value;
                          const acc2Obj = pray2Obj.arr.find(item => item.objectId === acc2Id);
                          const acc2Text = (acc2Obj && acc2Obj.value) || '';
                          props.onRowDataChange({ ...props.rowData, pray2Id: acc2Id, pray2Text: acc2Text })
                        }}
                      >
                        {
                          pray2Arr.map((item, index) =>
                            (<MenuItem key={'a' + item.objectId + index} value={item.objectId}>{item.value}</MenuItem>))}
                      </Select>
                    }
                  },
                  { width: 90, title: '發心日期', field: 'donateDate' },
                  { width: 80, title: '付款狀況', field: 'payStatusId', lookup: payStatusOpt },
                  { width: 80, title: '單位', field: 'unit', lookup: unitOpt },
                  { width: 100, title: '備註', field: 'ps' },
                  { width: 120, title: '小計', field: 'price', ...typeCurrency },
                  // { width: 80, title: '收款人', field: 'payee' },
                  {
                    width: 80, title: '收款人', field: 'payeeId', lookup: payeeOpt,
                    render: rowData => <>{rowData.payee}</>,
                  },
                  {
                    width: 80, title: '勸募人', field: 'advisePeopleId', lookup: advisePeopleOpt,
                    render: rowData => <>{rowData.advisePeople}</>,
                  },
                  { width: 80, title: '帳本', field: 'ledger' },
                  { width: 80, title: '收據號碼', field: 'receiptNum' },
                  {
                    width: 100, title: '選擇(代入地址)', field: 'postcode_select',
                    headerStyle: { textAlign: 'center' },
                    render: rowData => <></>,
                    editComponent: props => <ModalAutoAddr {...this.props} field='addr' type="2" rowData={props.rowData} onRowDataChange={props.onRowDataChange} />
                  },
                  { width: 200, title: '地址', field: 'addr' },
                  { width: 130, title: '牌位', field: 'praytypeId', lookup: praytypeObj.sel },
                  {
                    width: 80, title: '列印', field: 'isPrint',
                    render: rowData => <Checkbox checked={Number(rowData.isPrint)} />,
                    editComponent: props => <Checkbox checked={Number(props.rowData.isPrint)} onChange={(e, data) => {
                      if (data.checked) {
                        props.onRowDataChange({ ...props.rowData, isPrint: '1' })
                      } else {
                        props.onRowDataChange({ ...props.rowData, isPrint: '0' })
                      }
                    }
                    } />
                  },
                  // { width: 80, title: '地址', field: 'addr' },
                ]}
                // data={query =>
                //   new Promise((resolve, reject) => {
                //     fetchPeople(companyObj, query, searchValue.trim()).then(result => resolve(result))
                //   })
                // }
                data={data1}
                onRowClick={(e, rowData) => this.handleClickName(rowData)}
                editable={{
                  onRowAdd: newData =>
                    new Promise(async (resolve, reject) => {
                      const data = [...data1];
                      const db = Parse.Object.extend("donate");
                      const newDoc = new db();
                      const doc = await newDoc.save();
                      newData.objectId = doc.id;
                      newData.id = doc.id;
                      newData._id = doc.id;
                      newData.companyId = companyObj.objectId;
                      newData.companyName = companyObj.name;
                      newData.pray1Id = '1pzegsRGTy';
                      newData.pray1Text = '重建祈福消災法會';
                      newData.praytypeText = (praytypeObj.arr.find(item => item.objectId === newData.praytypeId) && praytypeObj.arr.find(item => item.objectId === newData.praytypeId).value) || ''
                      newData.payee = payeeOpt[newData.payeeId]
                      newData.advisePeople = advisePeopleOpt[newData.advisePeopleId]
                      data.push(newData);

                      this.setState({ data1: data, newData }, () => resolve());
                    }).then(() => this.handleAdd('1')),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      const data = [...data1];
                      const index = data.indexOf(oldData);
                      data[index] = newData;
                      if (newData.payeeId) {
                        newData.payee = payeeOpt[newData.payeeId]
                      }
                      if (newData.advisePeopleId) {
                        newData.advisePeople = advisePeopleOpt[newData.advisePeopleId]
                      }
                      // console.log(data1)
                      newData.praytypeText = (praytypeObj.arr.find(item => item.objectId === newData.praytypeId) && praytypeObj.arr.find(item => item.objectId === newData.praytypeId).value) || ''
                      this.setState({ data1: data, newData, oldData }, () => resolve());
                    }).then(() => this.handleUpdate('1')),
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      let data = [...data1];
                      const index = data.indexOf(oldData);
                      data.splice(index, 1);
                      this.setState({ data1: data, oldData }, () => resolve());
                    }).then(() => this.handleDelete('1')),
                }}
              // actions={[
              //   {
              //     icon: 'group', // view_headline
              //     tooltip: '顯示家庭',
              //     onClick: (event, rowData) => this.handleClickName(rowData.familyId)
              //   },
              // ]}
              />
            </Col>
          </Row>
          <Row>
            <br />
            <br />
            <Col xs="12" sm="12" md="12">
              {rowData && rowData.objectId ?
                <MaterialTable
                  // isLoading={loading}
                  localization={localization()}
                  columns={columns(pray2Obj, pray2Arr, praytypeObj, this.props)}
                  options={{
                    pageSize: 5,
                    search: false,
                    tableLayout: 'fixed',
                    paginationType: "stepped"

                  }}
                  components={{
                    Toolbar: props => (<div>
                      <div style={{ float: 'right', textAlign: 'right', padding: '22px 16px 22px 4px' }}>
                        <ModalMultiInput {...this.props} rowData={rowData} handleData={this.handleClickName} columns={props.columns} condition={{ companyId: companyObj.objectId, companyName: companyObj.name }} dbName='donate' />
                      </div>
                      <MTableToolbar {...props} />
                      <div style={{ clear: 'both' }}></div>
                    </div>),
                    Row: props => rowReOrder(props, 'donate', 'order', data2, 'data2', this.handleState)
                  }}
                  // components={{
                  //   Toolbar: props => (
                  //     <div>
                  //       <div style={{ width: '130px', float: 'right', textAlign: 'right', padding: '15px' }}>
                  //         <RowsDnd {...this.props} orderField='orderId' columns={columns()} dataArr={dataArr} handleReorder={this.handleReorder} />
                  //       </div>
                  //       <MTableToolbar {...props} />
                  //       <div style={{ clear: 'both' }}></div>
                  //     </div>
                  //   ),
                  // }}
                  // actions={[
                  //   {
                  //     icon: 'file_copy',
                  //     tooltip: '複製',
                  //     onClick: (event, rowData) => this.handleDuplicate(rowData, rowData)
                  //   }
                  // ]}
                  data={data2}
                  title={rowData.name}
                  editable={{
                    onRowAdd: newData1 =>
                      new Promise(async (resolve, reject) => {
                        const data = [...data2];
                        const db = Parse.Object.extend("donate");
                        const newDoc = new db();
                        const doc = await newDoc.save();
                        newData1.objectId = doc.id;
                        newData1.id = doc.id;
                        newData1._id = doc.id;
                        newData1.companyId = companyObj.objectId;
                        newData1.companyName = companyObj.name;
                        newData1.donate1Id = rowData.objectId
                        newData1.payee = payeeOpt[newData1.payeeId]
                        newData1.advisePeople = advisePeopleOpt[newData1.advisePeopleId]
                        data.push(newData1);

                        this.setState({ data2: data, newData1 }, () => resolve());
                      }).then(() => this.handleAdd('2')),
                    onRowUpdate: (newData1, oldData1) =>
                      new Promise((resolve, reject) => {
                        const data = [...data2];
                        const index = data.indexOf(oldData1);
                        data[index] = newData1;
                        if (newData1.payeeId) {
                          newData1.payee = payeeOpt[newData1.payeeId]
                        }
                        if (newData1.advisePeopleId) {
                          newData1.advisePeople = advisePeopleOpt[newData1.advisePeopleId]
                        }
                        // console.log(data1)
                        console.log(data);
                        this.setState({ data2: data, newData1, oldData1 }, () => resolve());
                      }).then(() => this.handleUpdate('2')),
                    onRowDelete: oldData1 =>
                      new Promise((resolve, reject) => {
                        let data = [...data2];
                        const index = data.indexOf(oldData1);
                        data.splice(index, 1);
                        this.setState({ data2: data, oldData1 }, () => resolve());
                      }).then(() => this.handleDelete('2')),
                  }}
                /> : null}
            </Col>
          </Row>
          {/* {!loading && people.length ? <>
            <TablePeople {...this.props}
              familyId={familyId}
              people1={people1}
              people0={people0}
              selectData={selectData}
              handlePeopleAdd={this.handlePeopleAdd}
              handlePeopleUpdate={this.handlePeopleUpdate}
              handlePeopleDelete={this.handlePeopleDelete}
              onRowDataChange={this.handleFamilysAddrChange}
              handleFamilyChange={this.handleChange}
            />
          </> : null} */}
        </> : <><div style={{ color: 'grey' }}> <MyDimmer /></div></>}
      </div>
    </>);
  }
}

export default Family;
