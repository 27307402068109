import React, { Component } from 'react';
import { Form, Tabs, Table, Button, Select, Grid, Header, Divider } from 'semantic-ui-react';
import ModalPdf from 'widget/pdf/ModalPdf';
import { Pie } from 'react-chartjs-2';
import { semesterArr } from '../../../../widget/define/define'
import { isEmpty } from 'react-redux-firebase';
import { getActiveSemesterArr } from 'views/widgets/FieldsRender';
import Parse from '../../../../widget/parse'

// import { showField, showFieldText, getAvailblePeriodArr, getOptionsByKey } from 'views/widgets/FieldsRender';
// const statXLabel = new Array(40).fill('').map((item, index) => (`${index * 10 && index * 10 + 1}-${(index + 1) * 10}`));
// const semesterArr = [
//   // { key: 'empty', text: '- 学期選択 -', value: '0' },
//   { key: '2020,S', text: '2020S', value: '2020,S' },
//   { key: '2019,A', text: '2019A', value: '2019,A' },
//   { key: '2019,S', text: '2019S', value: '2019,S' },
//   { key: '2018,A', text: '2018A', value: '2018,A' },
//   { key: '2018,S', text: '2018S', value: '2018,S' },
//   { key: '2017,A', text: '2017A', value: '2017,A' },
//   { key: '2017,S', text: '2017S', value: '2017,S' },
// ]
const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}
let d = new Date().toLocaleDateString()
let now = new Date()
export default class StudentStatistics extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      filterForm: {
        year: props.semesterYear,
        semes: props.semester,
        yearsemester: `${props.semesterYear},${props.semester}`,
      },
      allStu: [],
      imgURL: '',
      imgURL2: '',
      imgURL3: '',
      imgURL4: '',
      students: []
    };
  }

  componentDidMount = async () => {
    const { companyObj } = this.props
    const data = await Parse.queryData('Students', { companyId: companyObj.objectId });
    this.setState({ students: data })
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.semesterYear !== this.props.semesterYear ||
      nextProps.semester !== this.props.semester)
      this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { firebase } = props;
    const { students } = this.state

    let allStu = students.filter(item => (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
      (item.applyResult === '1' && item.studentType === "1")) // 所有在籍
    this.setState({ allStu }, () => this.convertImg())
  }

  handleChange = (event, { name, value }) => {
    const { filterForm } = this.state
    if (name === 'yearsemester') {
      if (value === 'all') {
        this.setState({ filterForm: { yearsemester: 'all' } })
      } else {
        const year = value.substring(0, 4);
        const semes = value.substring(5);
        const yearsemester = value;
        this.setState({
          filterForm: {
            year,
            semes,
            yearsemester
          }
        }, () => this.convertImg());
      }
    }
  }

  convertImg = () => {
    let canvas = document.getElementById('canvas');
    let canvas2 = document.getElementById('canvas2');
    let canvas3 = document.getElementById('canvas3');
    let canvas4 = document.getElementById('canvas4');
    if (canvas) {
      let imgURL = canvas.toDataURL();
      this.setState({ imgURL }, () => console.log(imgURL))
    } else {
      this.setState({ imgURL: '' })
    }
    if (canvas2) {
      let imgURL2 = canvas2.toDataURL();
      this.setState({ imgURL2 }, () => console.log(imgURL2))
    } else {
      this.setState({ imgURL2: '' })
    }
    if (canvas3) {
      let imgURL3 = canvas3.toDataURL();
      this.setState({ imgURL3 }, () => console.log(imgURL3))
    } else {
      this.setState({ imgURL3: '' })
    }
    if (canvas4) {
      let imgURL4 = canvas4.toDataURL();
      this.setState({ imgURL4 }, () => console.log(imgURL4))
    } else {
      this.setState({ imgURL4: '' })
    }
  }


  render() {
    const { semesterYear, semester } = this.props
    const { allStu, filterForm, imgURL, imgURL2, imgURL3, imgURL4 } = this.state
    const { yearsemester } = filterForm
    const activeSemesterArr = getActiveSemesterArr(semesterYear, semester);
    const activeSemesterMap = activeSemesterArr.map(({
      semesterYear,
      semester
    }) => {
      return semesterYear + '年' + (semester === 'S' ? '4月生' : '10月生');
    });

    let stuArr = yearsemester === 'all' ? allStu : allStu.filter(item => item.semesterYear === filterForm.year && item.semester === filterForm.semes)
    let nationArr = []
    let nationLabels = []
    let nationCount = []
    let nationColor = []
    let genderArr = []
    let genderLabels = []
    let genderCount = []
    let genderColor = []
    let finalEduArr = []
    let finalEduLabels = []
    let finalEduCount = []
    let finalEduColor = []
    let ageArr = [{ ageText: '20才以下', count: 0 }, { ageText: '20才~30才', count: 0 }, { ageText: '30才以上', count: 0 }]
    let ageLabels = ['20才以下', '20才~30才', '30才以上']
    let ageCount = []
    let ageColor = []
    // let bornPlaceArr = []
    // let bornPlaceLabels = []
    // let bornPlaceCount = []
    // let bornPlaceColor = []
    const semesArr = [{ key: '-1', text: 'ALL', value: 'all' }, ...semesterArr]
    // .unshift({ key: '-1', text: 'ALL', value: 'all' })
    stuArr.forEach(item => {
      let findNation = nationLabels.findIndex(nationItem => nationItem === item.nationalityText)
      let findGender = genderLabels.findIndex(genderItem => genderItem === item.genderText)
      let findFinalEdu = finalEduLabels.findIndex(finalEduItem => finalEduItem === item.schoDisText)
      let findAge = ageLabels.findIndex(ageItem => ageItem === item.ageText)
      // let findBornPlace = bornPlaceLabels.findIndex(bornPlaceItem => bornPlaceItem === item.bornPlaceText)
      if (findNation === -1) {
        nationLabels.push(item.nationalityText)
        nationArr.push({ nationalityText: item.nationalityText, count: 1 })
      } else {
        nationArr.forEach(item2 => {
          if (item.nationalityText === item2.nationalityText) {
            item2.count++
          }
        })
      }
      if (findGender === -1) {
        genderLabels.push(item.genderText)
        genderArr.push({ genderText: item.genderText, count: 1 })
      } else {
        genderArr.forEach(item2 => {
          if (item.genderText === item2.genderText) {
            item2.count++
          }
        })
      }
      if (findFinalEdu === -1) {
        finalEduLabels.push(item.schoDisText)
        finalEduArr.push({ schoDisText: item.schoDisText, count: 1 })
      } else {
        finalEduArr.forEach(item2 => {
          if (item.schoDisText === item2.schoDisText) {
            item2.count++
          }
        })
      }
      if (item.age) {
        if (item.age < 20) {
          ageArr.forEach(item2 => {
            if (item2.ageText === '20才以下') {
              item2.count++
            }
          })
        } else if (item.age > 30) {
          ageArr.forEach(item2 => {
            if (item2.ageText === '30才以上') {
              item2.count++
            }
          })
        } else if (item.age > 19 && item.age < 31) {
          ageArr.forEach(item2 => {
            if (item2.ageText === '20才~30才') {
              item2.count++
            }
          })
          // } else {
          //   ageArr.forEach(item2 => {
          //     if (item2.ageText === '未知') {
          //       item2.count++
          //     }
          //   })
        }
      } else {
        ageArr.forEach(item2 => {
          if (item2.ageText === '未知') {
            item2.count++
          }
        })
      }
      // if (findBornPlace === -1) {
      //   bornPlaceLabels.push(item.bornPlaceText)
      //   bornPlaceArr.push({ bornPlaceText: item.bornPlaceText, count: 1 })
      // } else {
      //   bornPlaceArr.forEach(item2 => {
      //     if (item.bornPlaceText === item2.bornPlaceText) {
      //       item2.count++
      //     }
      //   })
      // }
    })
    nationCount = [...nationArr.map(item => item.count)]
    genderCount = [...genderArr.map(item => item.count)]
    finalEduCount = [...finalEduArr.map(item => item.count)]
    ageCount = [...ageArr.map(item => item.count)]
    // bornPlaceCount = [...bornPlaceArr.map(item => item.count)]
    let newNationLabels = []
    let newGenderLabels = []
    let newFinalEduLabels = []
    let newAgeLabels = []
    // let newBornPlaceLabels = []
    nationArr.forEach(item => {
      if (item.nationalityText === undefined)
        newNationLabels.push(`${'未知'} ${item.count}人(${(Math.round((item.count / stuArr.length) * 10000)) / 100}%)`)
      else
        newNationLabels.push(`${item.nationalityText} ${item.count}人(${(Math.round((item.count / stuArr.length) * 10000)) / 100}%)`)
    })
    genderArr.forEach(item => {
      if (item.genderText === undefined)
        newGenderLabels.push(`${'未知'} ${item.count}人(${(Math.round((item.count / stuArr.length) * 10000)) / 100}%)`)
      else
        newGenderLabels.push(`${item.genderText} ${item.count}人(${(Math.round((item.count / stuArr.length) * 10000)) / 100}%)`)
    })
    finalEduArr.forEach(item => {
      if (item.schoDisText === undefined)
        newFinalEduLabels.push(`${'未知'} ${item.count}人(${(Math.round((item.count / stuArr.length) * 10000)) / 100}%)`)
      else
        newFinalEduLabels.push(`${item.schoDisText} ${item.count}人(${(Math.round((item.count / stuArr.length) * 10000)) / 100}%)`)
    })
    ageArr.forEach(item => {
      if (item.ageText === '未知')
        newAgeLabels.push(`${'未知'} ${item.count}人(${(Math.round((item.count / stuArr.length) * 10000)) / 100}%)`)
      else
        newAgeLabels.push(`${item.ageText} ${item.count}人(${(Math.round((item.count / stuArr.length) * 10000)) / 100}%)`)
    })
    // bornPlaceArr.forEach(item => {
    //   if (item.bornPlaceText === undefined)
    //     newBornPlaceLabels.push(`${'未知'} ${item.count}人(${(Math.round((item.count / stuArr.length) * 10000)) / 100}%)`)
    //   else
    //     newBornPlaceLabels.push(`${item.bornPlaceText} ${item.count}人(${(Math.round((item.count / stuArr.length) * 10000)) / 100}%)`)
    // })
    nationArr.forEach(item => nationColor.push(`rgb(${Math.floor(Math.random() * 200)},${Math.floor(Math.random() * 200)},${Math.floor(Math.random() * 200)})`))
    genderArr.forEach(item => genderColor.push(`rgb(${Math.floor(Math.random() * 200)},${Math.floor(Math.random() * 200)},${Math.floor(Math.random() * 200)})`))
    finalEduArr.forEach(item => finalEduColor.push(`rgb(${Math.floor(Math.random() * 200)},${Math.floor(Math.random() * 200)},${Math.floor(Math.random() * 200)})`))
    ageArr.forEach(item => ageColor.push(`rgb(${Math.floor(Math.random() * 200)},${Math.floor(Math.random() * 200)},${Math.floor(Math.random() * 200)})`))
    // bornPlaceArr.forEach(item => bornPlaceColor.push(`rgb(${Math.floor(Math.random() * 200)},${Math.floor(Math.random() * 200)},${Math.floor(Math.random() * 200)})`))
    const arrPdf = [{
      filterForm,
      stuArr,
      imgURL,
      imgURL2,
      imgURL3,
      imgURL4
    }]

    return (
      <>
        <div style={{ ...style.flexCenter }}>
          <Header as='h2'
            content='在籍生統計'
            style={{ margin: '0' }}
          />
          <div style={{ ...style.flexCenter, minWidth: 335 }}>
            <Select style={{
              maxWidth: '100px',
            }} compact
              floated='right'
              fluid
              options={semesArr}
              onChange={this.handleChange}
              // disabled={isEdit}
              value={yearsemester}
              name='yearsemester' />
            <Button size='small' content='convertImg' style={{ marginLeft: 10 }} onClick={() => this.convertImg()} />
            <ModalPdf
              {...this.props}
              // ref={(ref) => { this.pdfModal = ref }}
              codekey="studentStatisticsPdf"
              params={arrPdf}
            />
          </div>
        </div>
        <div style={{ ...style.flexCenter }}>
          <Header content={yearsemester === 'all' ? `${activeSemesterMap}` : `${filterForm.year}年${filterForm.semes === 'S' ? '4月' : '10月'}(${stuArr.length})名`} style={{ margin: '15px 0 0' }} />
          {/* <Header content={`${d} 現在`} style={{ margin: '15px 0 0' }} /> */}
          <Header content={`${now.getFullYear()} 年 ${now.getMonth() + 1} 月 ${now.getDate()} 日  現在`} style={{ margin: '15px 0 0' }} />
        </div>
        <Divider />
        <Grid>
          <Grid.Row columns='equal'>
            <Grid.Column>
              <Header as='h3' content='国籍別' />
              {newNationLabels && newNationLabels.length ? <Pie
                id='canvas'
                data={{
                  datasets: [
                    {
                      data: nationCount,
                      backgroundColor: nationColor
                    }
                  ],

                  labels: newNationLabels
                }}
                options={{
                  legend: {
                    position: 'right'
                  }
                }}
              /> : <div style={{ margin: '30px 0' }}>(資料無し)</div>}
            </Grid.Column>
            <Grid.Column>
              <Header as='h3' content='性別別' />
              {newGenderLabels && newGenderLabels.length ? <Pie
                id='canvas2'
                data={{
                  datasets: [
                    {
                      data: genderCount,
                      backgroundColor: genderColor
                    }
                  ],

                  labels: newGenderLabels
                }}
                options={{
                  legend: {
                    position: 'right'
                  }
                }}
              /> : <div style={{ margin: '30px 0' }}>(資料無し)</div>}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns='equal'>
            <Grid.Column>
              <Header as='h3' content='最終学歴別' />
              {newFinalEduLabels && newFinalEduLabels.length ? <Pie
                id='canvas3'
                data={{
                  datasets: [
                    {
                      data: finalEduCount,
                      backgroundColor: finalEduColor
                    }
                  ],

                  labels: newFinalEduLabels
                }}
                options={{
                  legend: {
                    position: 'right'
                  }
                }}
              /> : <div style={{ margin: '30px 0' }}>(資料無し)</div>}
            </Grid.Column>
            <Grid.Column>
              <Header as='h3' content='年齢別' />
              {newAgeLabels && newAgeLabels.length ? <Pie
                id='canvas4'
                data={{
                  datasets: [
                    {
                      data: ageCount,
                      backgroundColor: ageColor
                    }
                  ],

                  labels: newAgeLabels
                }}
                options={{
                  legend: {
                    position: 'right'
                  }
                }}
              /> : <div style={{ margin: '30px 0' }}>(資料無し)</div>}
            </Grid.Column>
            {/* <Grid.Column>
              <Header as='h3' content='地区区分' />
              {newBornPlaceLabels && newBornPlaceLabels.length ? <Pie
                id='canvas4'
                data={{
                  datasets: [
                    {
                      data: bornPlaceCount,
                      backgroundColor: bornPlaceColor
                    }
                  ],

                  labels: newBornPlaceLabels
                }}
                options={{
                  legend: {
                    position: 'right'
                  }
                }}
              /> : <div style={{ margin: '30px 0' }}>(資料無し)</div>}
            </Grid.Column> */}
          </Grid.Row>
        </Grid>

      </>
    );
  }
}