
import React, { Component, useState } from 'react'
import { Image, Icon } from 'semantic-ui-react';
import {
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from 'reactstrap';
import intl from 'react-intl-universal';

import WebContent from './WebContent';
import EditWebMaterialTable from './EditWebMaterialTable';
import { WebComponentOpt } from './WebComponentOpt';
import Editor from '../../widget/Editor';
import PreloadFile from '../web/PreloadFile';

const colInput = (id, value, handleChange) => <Col key={`textaa${id}`} md={3}>
  <FormGroup >
    <Label for={`text${id}`}>text{id}</Label>
    <Input type="text" id={`text${id}`} value={value} onChange={handleChange} />
  </FormGroup>
</Col>;


const EditWebPage = (props) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const { form, file, files, handleChange, handleEditorChange, handleChangeState, fileCenter1Arr } = props;
  const { order, componentId = '--', flieType = '--' } = form;

  const selObj = WebComponentOpt.find(item => item.id === componentId) || {}
  const textArr = selObj.textNum ? Array(selObj.textNum).fill(0) : [];
  return (<>
    <Row>
      <Col>
        <Form inline>
          <FormGroup className="mb-1 mr-sm-1 mb-sm-0">
            <Label for="exampleEmail" className="mr-sm-2">{intl.get('順序')}</Label>
            <Input type="number" name="order" id="order" placeholder={intl.get('順序')} value={order} onChange={handleChange} />
          </FormGroup>
          <FormGroup className="mb-4 mr-sm-4 mb-sm-0">
            <Label for="componentId">{intl.get('版型')}</Label>
            <Input type="select" name="select" id="componentId" value={componentId} onChange={handleChange} >
              <option value='--'>-- {intl.get('請選擇')} --</option>
              {WebComponentOpt.map(({ id, value }) => <option key={id} value={id}>{value}</option>)}
            </Input>
          </FormGroup>
        </Form>
      </Col>
    </Row>
    {componentId !== '--' ? <><hr />
      {componentId === 'FileDownload' ?
        <Row>
          <Col>
            <Form inline>
              <FormGroup className="mb-4 mr-sm-4 mb-sm-0">
                <Label for="componentId">選擇檔案類別</Label>
                <Input type="select" name="select" id="flieType" value={flieType} onChange={handleChange} >
                  <option value='--'>請選擇</option>
                  {fileCenter1Arr.map((item, { id, value }) => <option key={id} value={id}>{item.name}</option>)}
                </Input>
              </FormGroup>
            </Form>
          </Col>
        </Row> : null}
      <Form>
        <Row form>
          {textArr.map((item, index) => colInput(`${index + 2}`, form[`text${index + 2}`], handleChange))}
        </Row>
        {selObj.showImages ?
          <FormGroup style={{ marginTop: '10px' }} className="mb-2 mr-sm-2 mb-sm-0">
            <Label for="componentId">{intl.get('圖片')}</Label>
            <PreloadFile multiple={true} handleChangeFile={handleChangeState} files={files} />
          </FormGroup> : null}
        {selObj.showImage ?
          <FormGroup style={{ marginTop: '10px' }} className="mb-2 mr-sm-2 mb-sm-0">
            <Label for="componentId">{intl.get('封面圖片')}</Label>
            <PreloadFile files={file} handleChangeFile={handleChangeState} setImagePreviewUrl={setImagePreviewUrl} />
          </FormGroup> : null}
      </Form>
      <br />
      {selObj.showTable ? <EditWebMaterialTable {...props} showColumns={selObj.columns} /> : null}
      {selObj.showEditor ? <Editor field='text1' content={form.text1} handleEditorChange={handleEditorChange} /> : null}
      <br />
      <hr />
      <h3>{intl.get('頁面預覽')}</h3>
      <WebContent {...props} imagePreviewUrl={imagePreviewUrl} />
    </> : null}
  </>);
}

export default EditWebPage;