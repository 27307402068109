
import React, { Component } from 'react'
import { Container, Grid, Header, Form, Segment, Image } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import { Select, MenuItem } from '@material-ui/core';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ModalMultiInput from 'views/web/ModalMultiInput';
import { dateCol, exportPdf, rowReOrder } from 'views/web/CustomMaterialTable';

const columns = () => ([
  { width: 60, title: '預設', field: 'now_use', lookup: { '-1': '是', '0': '否' } },
  { width: 60, title: '順序', field: 'order', type: 'numeric' },
  { title: '法會名稱', field: 'value' },
  { title: '備註', field: 'ps' },
]);

const columns2 = (praytypeSel, webTypeTemple, praytypeObj, pray2) => ([
  { width: 60, title: '預設', field: 'now_use', lookup: { '-1': '是', '0': '否' } },
  { width: 60, title: `${webTypeTemple ? '類別' : '順序'}`, field: 'order', type: 'numeric' },
  // { title: '法會名稱', field: 'text', editable: 'never' },
  { title: '法會項目', field: 'value' },
  { title: '目前編號', field: 'now_num' },
  { title: '價錢', field: 'price', hidden: webTypeTemple },
  {
    title: '預設燈牌', field: 'defPraytypeId', lookup: praytypeSel,
    render: rowData => praytypeSel[rowData.defPraytypeId],
    editComponent: props => {
      const praytypeArr = praytypeObj.arr || []
      const arr = praytypeArr.filter(item => item.field_type === String(props.rowData.order))
      return <Select
        value={props.rowData.defPraytypeId}
        onChange={e => {
          const praytypeId = e.target.value;
          console.log(praytypeId);
          // const serial = funcPad((Number(pray2Obj.now_num || 0) + 1) + '', Number(pray2numLen));
          // const pray2Text = pray2Obj.value;
          // const pray2OrderId = pray2Obj.order;
          // const accReceivable = pray2Obj.price;
          props.onRowDataChange({ ...props.rowData, defPraytypeId: praytypeId })
        }}>
        {arr.map((item, index) => <MenuItem key={'a' + item.objectId + index} value={item.objectId}>{item.value}</MenuItem>)}
      </Select>
    }
  },
]);

class Pray extends Component {
  state = {
    pray1Id: '',
    data1: [],
    data2: [],
    newData1: {},
    newData2: {},
    loading: true, //true,
    loading2: false,
    rowData: {},
    praytypeSel: {},
    praytypeObj: {}
  }

  async componentDidMount() {
    this.handleFetch()
  }

  handleFetch = async () => {
    const { companyObj, match } = this.props
    this.setState({ loading: true, pray1Id: '' })
    // const { familyId } = match.params;
    const companyId = companyObj.objectId;
    const data = await Parse.queryData("pray1", { companyId })
    const data2 = await Parse.queryData("pray2", { companyId })
    const praytypeObj = await Parse.getSelection('praytype', { companyId }, { name: 'value', sort: 'name' });
    const praytypeSel = praytypeObj.sel
    this.setState({ data1: data.sort((a, b) => a.order - b.order), praytypeSel, praytypeObj, data2, loading: false })
  }

  handleAdd = async (key) => {
    this.handleUpdate(key);
  }

  handleUpdate = async (key) => {
    const { companyObj } = this.props;
    const { data1, data2 } = this.state;

    if (key === "1") {
      const { newData1 } = this.state;
      data1.forEach((item, i) => Parse.saveData('pray1', data1[i]));
      // Parse.saveData('pray1', newData1);
      Parse.saveData('company', { objectId: companyObj.objectId, pray1IdDef: newData1.objectId })
    } else {
      const { newData2 } = this.state;
      data2.forEach((item, i) => Parse.saveData('pray2', data2[i]));
      Parse.saveData('pray2', newData2);
    }
  }

  handleDelete = async (key) => {
    if (key === "1") {
      const { oldData1 } = this.state
      Parse.deleteData('pray1', oldData1);
      this.setState({ oldData1: {}, pray1Id: '' })
    } else {
      const { oldData2 } = this.state
      Parse.deleteData('pray2', oldData2);
      this.setState({ oldData2: {} })
    }
  }

  handleData = async (rowData) => {
    const pray1Id = rowData.objectId
    this.setState({ pray1Id: '', rowData, loading2: true });
    if (pray1Id) {
      const data2 = await Parse.queryData('pray2', { pray1Id });
      this.setState({ data2, pray1Id, loading2: false });
    }
  }

  handleState = (dataArr, arrName = 'dataArr') => {
    this.setState({ [arrName]: dataArr })
  }

  render() {
    const { companyObj = {} } = this.props
    const { data1, data2, pray1Id, loading, loading2, rowData, praytypeSel, praytypeObj } = this.state
    return (<>
      <div className="content">
        <Grid>
          <Grid.Row columns='equal'>
            <Grid.Column width={7} computer={7} tablet={7} mobile={16}>
              {companyObj.webtypeId === 'temple' ? <MaterialTable
                isLoading={loading}
                localization={localization()}
                columns={columns()}
                options={{
                  addRowPosition: "first",
                  exportButton: true,
                  exportPdf: (columns, data) => exportPdf(columns, data, '法會名稱'),
                  pageSize: data1.length || 5,
                  search: false
                }}
                components={{
                  Toolbar: props => (<div>
                    <div style={{ float: 'right', textAlign: 'right', padding: '22px 16px 22px 4px' }}>
                      <ModalMultiInput {...this.props} handleData={this.handleFetch} columns={props.columns} condition={{ companyId: companyObj.objectId, companyName: companyObj.name }} dbName='pray1' />
                    </div>
                    <MTableToolbar {...props} />
                    <div style={{ clear: 'both' }}></div>
                  </div>),
                  Row: props => rowReOrder(props, 'pray1', 'order', data1, 'data1', this.handleState)
                }}
                data={data1}
                title="法會名稱"
                actions={
                  [
                    {
                      icon: 'view_headline',
                      tooltip: '查看',
                      onClick: (event, rowData) => this.handleData(rowData)
                    }
                  ]}
                onRowClick={(event, rowData) => this.handleData(rowData)}
                editable={{
                  onRowAdd: newData1 =>
                    new Promise(async (resolve, reject) => {
                      const data = [...data1];
                      const db = Parse.Object.extend("pray1");
                      const newDoc = new db();
                      const doc = await newDoc.save();
                      newData1.companyId = companyObj.id;
                      newData1.companyName = companyObj.name;
                      newData1.objectId = doc.id;
                      newData1.id = doc.id;
                      newData1._id = doc.id;
                      if (newData1.now_use === "-1") {
                        data.forEach((item, i) => data[i].now_use = '0');
                      }
                      data.push(newData1);
                      this.setState({ data1: data, newData1 }, () => resolve());
                    }).then(() => this.handleAdd("1")),
                  onRowUpdate: (newData1, oldData1) =>
                    new Promise((resolve, reject) => {
                      const data = [...data1];
                      const index = data.indexOf(oldData1);
                      if (newData1.now_use === "-1") {
                        data.forEach((item, i) => data[i].now_use = '0');
                      }
                      data[index] = newData1;
                      this.setState({ data1: data, newData1, oldData1 }, () => resolve());
                    }).then(() => this.handleUpdate("1")),
                  onRowDelete: oldData1 =>
                    new Promise((resolve, reject) => {
                      let data = [...data1];
                      const index = data.indexOf(oldData1);
                      data.splice(index, 1);
                      this.setState({ data1: data, oldData1 }, () => resolve());
                    }).then(() => this.handleDelete("1")),
                }}
              /> : <MaterialTable
                  isLoading={loading}
                  localization={localization()}
                  columns={columns()}
                  options={{
                    addRowPosition: "first",
                    exportButton: true,
                    exportPdf: (columns, data) => exportPdf(columns, data, '法會名稱'),
                    pageSize: data1.length || 5,
                    search: false
                  }}
                  components={{
                    Toolbar: props => (<div>
                      <div style={{ float: 'right', textAlign: 'right', padding: '22px 16px 22px 4px' }}>
                        <ModalMultiInput {...this.props} handleData={this.handleFetch} columns={props.columns} condition={{ companyId: companyObj.objectId, companyName: companyObj.name }} dbName='pray1' />
                      </div>
                      <MTableToolbar {...props} />
                      <div style={{ clear: 'both' }}></div>
                    </div>),
                    Row: props => rowReOrder(props, 'pray1', 'order', data1, 'data1', this.handleState)
                  }}
                  data={data1}
                  title="法會名稱"
                  // actions={
                  //   [
                  //     {
                  //       icon: 'view_headline',
                  //       tooltip: '查看',
                  //       onClick: (event, rowData) => this.handleData(rowData)
                  //     }
                  //   ]}
                  // onRowClick={(event, rowData) => this.handleData(rowData)}
                  editable={{
                    onRowAdd: newData1 =>
                      new Promise(async (resolve, reject) => {
                        const data = [...data1];
                        const db = Parse.Object.extend("pray1");
                        const newDoc = new db();
                        const doc = await newDoc.save();
                        newData1.companyId = companyObj.id;
                        newData1.companyName = companyObj.name;
                        newData1.objectId = doc.id;
                        newData1.id = doc.id;
                        newData1._id = doc.id;
                        if (newData1.now_use === "-1") {
                          data.forEach((item, i) => data[i].now_use = '0');
                        }
                        data.push(newData1);
                        this.setState({ data1: data, newData1 }, () => resolve());
                      }).then(() => this.handleAdd("1")),
                    onRowUpdate: (newData1, oldData1) =>
                      new Promise((resolve, reject) => {
                        const data = [...data1];
                        const index = data.indexOf(oldData1);
                        if (newData1.now_use === "-1") {
                          data.forEach((item, i) => data[i].now_use = '0');
                        }
                        data[index] = newData1;
                        this.setState({ data1: data, newData1, oldData1 }, () => resolve());
                      }).then(() => this.handleUpdate("1")),
                    onRowDelete: oldData1 =>
                      new Promise((resolve, reject) => {
                        let data = [...data1];
                        const index = data.indexOf(oldData1);
                        data.splice(index, 1);
                        this.setState({ data1: data, oldData1 }, () => resolve());
                      }).then(() => this.handleDelete("1")),
                  }}
                />}
            </Grid.Column>
            <Grid.Column width={9} computer={9} tablet={9} mobile={16}>
              {companyObj.webtypeId === 'temple' ? <>
                {!pray1Id ? null : <><MaterialTable
                  isLoading={loading2}
                  localization={localization()}
                  columns={columns2(praytypeSel, companyObj.webtypeId === 'temple', praytypeObj, data2)}
                  options={{
                    addRowPosition: "first",
                    exportButton: true,
                    exportPdf: (columns, data) => exportPdf(columns, data, rowData.value + " 法會項目"),
                    pageSize: data2.length < 5 ? 5 : data2.length + 5,
                    search: false,
                  }}
                  components={{
                    Toolbar: props => (<div>
                      <div style={{ float: 'right', textAlign: 'right', padding: '22px 16px 22px 4px' }}>
                        <ModalMultiInput {...this.props} handleData={this.handleFetch} columns={props.columns} condition={{ companyId: companyObj.objectId, companyName: companyObj.name, pray1Id: pray1Id, pray1Name: rowData.value }} dbName='pray2' />
                      </div>
                      <MTableToolbar {...props} />
                      <div style={{ clear: 'both' }}></div>
                    </div>),
                    Row: props => rowReOrder(props, 'pray2', 'order', data2, 'data2', this.handleState)
                  }}
                  data={data2}
                  title={rowData.value + " 法會項目"}
                  editable={{
                    onRowAdd: newData2 =>
                      new Promise(async (resolve, reject) => {
                        const data = [...data2];
                        const db = Parse.Object.extend("pray2");
                        const newDoc = new db();
                        const doc = await newDoc.save();
                        newData2.objectId = doc.id;
                        newData2.companyId = companyObj.id;
                        newData2.companyName = companyObj.name;
                        newData2.id = doc.id;
                        newData2._id = doc.id;
                        newData2.pray1Id = pray1Id
                        newData2.pray1Name = rowData.value

                        data.push(newData2);
                        this.setState({ data2: data, newData2 }, () => resolve());
                      }).then(() => this.handleAdd("2")),
                    onRowUpdate: (newData2, oldData2) =>
                      new Promise((resolve, reject) => {
                        const data = [...data2];
                        const index = data.indexOf(oldData2);
                        if (newData2.now_use === "-1") {
                          data.forEach((item, i) => data[i].now_use = '0');
                        }
                        data[index] = newData2;
                        this.setState({ data2: data, newData2, oldData2 }, () => resolve());
                      }).then(() => this.handleUpdate("2")),
                    onRowDelete: oldData2 =>
                      new Promise((resolve, reject) => {
                        let data = [...data2];
                        const index = data.indexOf(oldData2);
                        data.splice(index, 1);
                        this.setState({ data2: data, oldData2 }, () => resolve());
                      }).then(() => this.handleDelete("2")),
                  }}
                />
                  <br />
                  {/* <Header as='h3' dividing>法會項目輸入說明</Header>
                  法會項目：純文字，不同的項目，不同的價錢
                  <br />
                  目前編號：純數字，一開始輸入 0 就好，會自動跳號
                  <br />
                  價錢：純數字，為應收金額
                  <br />
                  <br />
                  以上欄位，在法會 -> 新增申辦服務 時，會使用到。如下所示。
                  <br /> */}
                  {/* <Image src='/pray1.png' /> */}
                </>}
              </> : null}
              {companyObj.webtypeId === 'dao' ? <MaterialTable
                isLoading={loading}
                localization={localization()}
                columns={columns2(praytypeSel, companyObj.webtypeId === 'dao', praytypeObj, data2)}
                options={{
                  addRowPosition: "first",
                  exportButton: true,
                  exportPdf: (columns, data) => exportPdf(columns, data, rowData.value + " 法會項目"),
                  pageSize: data2.length < 5 ? 5 : data2.length + 5,
                  search: false,
                }}
                // components={{
                //   Toolbar: props => (<div>
                //     <div style={{ float: 'right', textAlign: 'right', padding: '22px 16px 22px 4px' }}>
                //       <ModalMultiInput {...this.props} handleData={this.handleFetch} columns={props.columns} condition={{ companyId: companyObj.objectId, companyName: companyObj.name, pray1Id: pray1Id, pray1Name: rowData.value }} dbName='pray2' />
                //     </div>
                //     <MTableToolbar {...props} />
                //     <div style={{ clear: 'both' }}></div>
                //   </div>),
                //   Row: props => rowReOrder(props, 'pray2', 'order', data2, 'data2', this.handleState)
                // }}
                data={data2}
                title={"法會項目"}
                editable={{
                  onRowAdd: newData2 =>
                    new Promise(async (resolve, reject) => {
                      const data = [...data2];
                      const db = Parse.Object.extend("pray2");
                      const newDoc = new db();
                      const doc = await newDoc.save();
                      newData2.objectId = doc.id;
                      newData2.companyId = companyObj.id;
                      newData2.companyName = companyObj.name;
                      newData2.id = doc.id;
                      newData2._id = doc.id;
                      // newData2.pray1Id = pray1Id
                      // newData2.pray1Name = rowData.value

                      data.push(newData2);
                      this.setState({ data2: data, newData2 }, () => resolve());
                    }).then(() => this.handleAdd("2")),
                  onRowUpdate: (newData2, oldData2) =>
                    new Promise((resolve, reject) => {
                      const data = [...data2];
                      const index = data.indexOf(oldData2);
                      if (newData2.now_use === "-1") {
                        data.forEach((item, i) => data[i].now_use = '0');
                      }
                      data[index] = newData2;
                      this.setState({ data2: data, newData2, oldData2 }, () => resolve());
                    }).then(() => this.handleUpdate("2")),
                  onRowDelete: oldData2 =>
                    new Promise((resolve, reject) => {
                      let data = [...data2];
                      const index = data.indexOf(oldData2);
                      data.splice(index, 1);
                      this.setState({ data2: data, oldData2 }, () => resolve());
                    }).then(() => this.handleDelete("2")),
                }}
              /> : null}
              <br />
              <br />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <br />
        <br />
      </div>
    </>);
  }
}

export default Pray;
