import React, { Component } from 'react';
import { Form, Button, Label } from 'semantic-ui-react'
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';

import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import { showField, showFieldText, getStudentNumBySemester, getActiveSemesterArr, getOptionsSelByKey, getStudentCountryText } from 'views/widgets/FieldsRender';
import ViewStudentList from './ViewStudentList';
import Parse from '../../../widget/parse'

export default class StudentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentType: '0',
      students: []
    };
  }
  async componentDidMount() {
    // console.log('componentDidMount');
    const { companyObj } = this.props
    const students = await Parse.queryData('Students', { companyId: companyObj.objectId });
    this.setState({ students });
  }

  handleChange = (event, { name, value }) => {
    const { match, history } = this.props
    const { students } = this.state
    const { _id, list } = match.params
    this.setState({ [name]: value });
  }
  render() {
    const { semesterYear, semester, formSet, optSet, classroom, grade2 } = this.props;
    // const { eventDate, loading } = this.state;
    const { studentType, students } = this.state;
    const activeSemesterArr = getActiveSemesterArr(semesterYear, semester);
    // console.log(activeSemesterArr)
    // const banner = {
    //   backgroundColor: '#f9fafb',
    // }
    const studentTypeArr = [
      { key: '0', text: '- ALL -', value: '0' },
    ].concat(getOptionsSelByKey(optSet, 'studentType'))

    let studentsArr = students.filter(item =>
      (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
      (item.applyResult === '1' || item.studentType === "2")
    );; // 所有学生
    if (studentType === '1') { // 正規生
      // studentsArr = students.filter(item => item.studentId && !item.studentId.match(/[A-Z]/g));
      studentsArr = students.filter(item =>
        (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
        (item.applyResult === '1' && item.studentType === "1")
      );
    } else if (studentType === '2') { //聽講生
      studentsArr = students.filter(item =>
        (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
        (item.studentType === "2")
      );
    }
    const activeSemesterMap = activeSemesterArr.map(({
      semesterYear,
      semester
    }) => {
      return semesterYear + '年' + (semester === 'S' ? '4月生' : '10月生') + '(' + getStudentNumBySemester(studentsArr, semesterYear, semester) + '名)';
    });
    return (
      <>
        <div style={{ float: 'right' }}>
          {/* <ReactToPrint
            trigger={(e) => <Button
              content='印刷'
              // label='&nbsp;'
              color='orange' icon='print'
              size='small'
              floated='right'
              className='no-print'
            />}
            content={() => this.componentRef}
            pageStyle={"@page { size: auto;  margin:5mm; }*{font-family: 'Noto Serif JP', serif;}"}
          /> */}
          <ModalPdf
            {...this.props}
            // ref={(ref) => { this.pdfModal = ref }}
            codekey="table373"
            params={{ color: 1, studentsArr }}//有色
          />
          <Label color='teal' floating>
            カラ
          </Label>
          <ModalPdf
            {...this.props}
            // ref={(ref) => { this.pdfModal = ref }}
            codekey="table373"
            params={{ color: 0, studentsArr }}
          />
          <ExportTableToExcelButton floated='right' size='small' name='StudentsList' />
          <Form.Select
            label="学生別"
            options={studentTypeArr}
            placeholder="学生別"
            onChange={this.handleChange}
            name="studentType"
            value={studentType}
          />
        </div>
        <div ref={el => (this.componentRef = el)} className="" >
          <div style={{ float: 'right', textAlign: 'right' }}>
            <Label circular color={'yellow'} empty key={'1'} />大學院&nbsp;
            <Label circular color={'green'} empty key={'2'} />大學&nbsp;
            <Label circular color={'blue'} empty key={'3'} />専門学校&nbsp;
            <Label circular color={'orange'} empty key={'4'} />就職&nbsp;
            <Label circular color={'grey'} empty key={'6'} />研究生&nbsp;
            ○その他&nbsp;&nbsp;&nbsp;
            {/* <span>在学生数: {studentsArr.length}名 &nbsp;&nbsp;入学期生別: {activeSemesterArr.map(({ semesterYear, semester }) => <span key={`ss${semesterYear}${semester}`}>{semesterYear}年{semester} {this.getStudentNumBySemester(studentsArr, semesterYear, semester)}  </span>)}&nbsp;&nbsp;</span><br /> */}
            <span>在学生数: {studentsArr.length}名 &nbsp;&nbsp;入学期生別: {activeSemesterMap}</span><br />
            <span>{getStudentCountryText(optSet, studentsArr)}</span>
          </div>
          <h2>愛知国際学院 在学生名簿</h2>
          <div style={{ clear: 'both' }}></div>
          <div style={{ padding: '1px' }}>
            <ViewStudentList {...this.props} studentsArr={studentsArr} />
          </div>
        </div>
      </>
    );
  }
}