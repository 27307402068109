import React, { Component } from 'react'
import { Header, } from 'semantic-ui-react';
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import {
  Row,
  Col,
} from "reactstrap";

export default class ThemeImg extends Component {
  render() {
    const { companyObj } = this.props;

    return (<>
      <Header as='h3' dividing inverted={companyObj.inverted}>上傳圖片</Header>
      <Row>
        <Col md="3" sm="8">
          <Header as='h4' inverted={companyObj.inverted}>1 Icon</Header>
          <ImageUpload {...this.props} type={"3"} avatar={companyObj.img2File && companyObj.img2File.url} />
        </Col>
        <Col md="3" sm="8">
          <Header as='h4' inverted={companyObj.inverted}>2 Logo</Header>
          <ImageUpload {...this.props} type={"2"} avatar={companyObj.img1File && companyObj.img1File.url} />
        </Col>
        <Col md="3" sm="8">
          <Header as='h4' inverted={companyObj.inverted}>3 登入背景</Header>
          <ImageUpload {...this.props} type={"1"} avatar={companyObj.img0File && companyObj.img0File.url} />
        </Col>
        <Col md="3" sm="8">
          <Header as='h4' inverted={companyObj.inverted}>4 後台背景</Header>
          <ImageUpload {...this.props} type={"4"} avatar={companyObj.img3File && companyObj.img3File.url} />
        </Col>
      </Row>
    </>)
  }
}
