import React from 'react';
import { Input, Label, Form, Icon, Button } from 'semantic-ui-react';
// import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

const setShiftWeek = (yearWeek, handleWeekChange, shift) => {
  const [year, Wweek] = yearWeek.split('-'); // 2019-W10 => 2019, W10
  const week = Number(Wweek.slice(1)) + shift; // W10 => 10
  const str = `${year}-W${week}`
  handleWeekChange({}, { name: 'yearWeek2', value: str });
};

const SelectWeek = ({ handleWeekChange, yearWeek, minWeek = '', maxWeek = '' }) =>
  (<Input labelPosition='left'  >
    <Label color={'teal'}> <Icon name='calendar alternate' />年週</Label>
    <Input labelPosition='left' type='text' placeholder=''>
      <Label style={{ cursor: 'pointer', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }} onClick={() => setShiftWeek(yearWeek, handleWeekChange, -1)}>
        <Icon size='small' name='angle double left' style={{ fontSize: '1.2em', margin: '0 0 0 0' }} /></Label>
      <Form.Input
        type='week'
        className="inputCls"
        onChange={handleWeekChange}
        name='yearWeek'
        value={yearWeek} min={minWeek} max={maxWeek} onKeyPress={(e) => e.preventDefault()}
      />
      <Label style={{ cursor: 'pointer', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }} onClick={() => setShiftWeek(yearWeek, handleWeekChange, 1)} >
        <Icon size='mini' name='angle double right' style={{ fontSize: '1.2em', margin: '0 0 0 0' }} /></Label>
    </Input>
  </Input>);

export default SelectWeek;
