import React, { Component } from 'react'
import { Grid, Header, Form, Segment, Menu, Container } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
// import { Row, Col } from 'reactstrap';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import WebSiteShow from './WebSiteShow';
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import PageHeader from 'components/Headers/PageHeader';

import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";

import Profile1 from "./Profile1";
import Profile2 from "./Profile2";
import Profile3 from "./Profile3";

const columns = () => ([
  { width: 180, title: '帳號', field: 'username', editable: 'never' },
  { width: 80, title: '工號', field: 'jobNum' },
  { width: 120, title: '姓名', field: 'name' },
  { width: 80, title: '資息(%)', field: 'interest' },
  { width: 80, title: '結餘', field: 'balance', editable: 'never' },
]);

export default class Profile extends Component {
  state = {
    // activeItem: 'bio',
    // dataArr: [],
    typeId: '',
    data: [],
    data2: [],
    newData: {},
    loading1: true,
    loading2: false,
    rowData: {},
    rowData2: {},
    pageObj: {},
    loading: true,
    tabId: "p1",
  }

  componentDidMount = async () => {
    const { companyObj } = this.props;
    const data = await Parse.queryData('webnav', { companyId: companyObj.objectId })
    this.setState({ data, loading1: false })
    this.handleParams()
  }

  componentWillReceiveProps(nextProps) {
    this.handleParams(nextProps);
  }

  handleParams = async (props = this.props) => {
    const { match, category, companyObj } = props;
    const { t1 } = match.params;
    if (typeof t1 !== 'undefined'
      //  && t1 === category
    ) {
      this.setState({ tabId: t1, ordering1Id: '', data2: [], loading: true }
        // , () => this.handleFetch(props)
      );
    }

    // const companyId = companyObj.objectId;
    // const companyObjLive = await Parse.fetchDocument('company', companyId,
    //   ['yearDef', 'orderalphaDef', 'ordernumDef', 'pray1IdDef']);
    // // console.log(companyObjLive)
    // this.setState({ companyObjLive });
  }

  handleAdd = async () => {
    //   const { newData } = this.state;
    //   Parse.saveData('pawnRecord', newData);
  }

  handleUpdate = async (key) => {
    const { newData } = this.state;
    Parse.saveData('webnav', newData);
    // const { newData } = this.state;
    // Parse.saveData('pawnRecord', newData);
  }

  handleDelete = async () => {
    // const { oldData } = this.state
    // Parse.deleteData('pawnRecord', oldData);
    // this.setState({ oldData: {} })
  }

  handleItemClick = (e, { name }) =>
    this.setState({
      activeItem: name
    })

  handleData = async (rowData) => {
    this.setState({ rowData, loading2: false });
  }
  handleData2 = async (rowData2) => {
    this.setState({ rowData2 });
  }

  render() {
    const { history, profileId, loading, profileObj } = this.props;
    const { tabId, data = [], loading1, loading2, rowData, rowData2 } = this.state
    const data1 = data.filter(item => item.level === '1' && (item.pageType === '1' || item.pageType === '2' || item.pageType === '3'));
    const data2 = data.filter(item => item.level === '2' && item.nav1Id === rowData.objectId);
    const data4 = data.filter(item => item.pageType === '4');

    return (<>
      <div className="content">
        <ColorNavbar {...this.props} data={data} data1={data1} />
        <PageHeader text='個人資料' url='/images/blog/full/19.jpg' />
      </div>
      <Container>
        <div className="nav-tabs-navigation">
          <div className="nav-tabs-wrapper">
            <br />
            <br />
            <br />
            <Nav id="tabs" role="tablist" tabs>
              <NavItem>
                <NavLink
                  aria-expanded={tabId === "p1"}
                  data-toggle="tab"
                  href="#"
                  role="tab"
                  className={
                    tabId === "p1"
                      ? "active"
                      : ""
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    this.setState({ tabId: "p1" })
                    // history.push(`/admin/Profile/${profileId}/p1`)
                  }
                  }
                >
                  基本資料
                          </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-expanded={
                    tabId === "p2"
                  }
                  data-toggle="tab"
                  href="#"
                  role="tab"
                  className={
                    tabId === "p2"
                      ? "active"
                      : ""
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    this.setState({ tabId: "p2" })
                    // history.push(`/admin/Profile/${profileId}/p2`)
                  }
                  }
                >
                  保證金
                          </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-expanded={
                    tabId === "p3"
                  }
                  data-toggle="tab"
                  href="#"
                  role="tab"
                  className={
                    tabId === "p3"
                      ? "active"
                      : ""
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    this.setState({ tabId: "p3" })
                    // history.push(`/admin/Profile/${profileId}/p3`)
                  }
                  }
                >
                  薪資條
                          </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
        <TabContent
          className="text-center"
          id="my-tab-content"
          activeTab={tabId}
        >
          <TabPane tabId="p1" role="tabpanel">
            <Profile1 {...this.props} />
          </TabPane>
          <TabPane tabId="p2" role="tabpanel">
            <Profile2 {...this.props} profileObj={profileObj} />
          </TabPane>
          <TabPane tabId="p3" role="tabpanel">
            <Profile3 {...this.props} />
          </TabPane>
        </TabContent>
        <br />
      </Container>
    </>

    )
  }
}