
import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { withCookies } from 'react-cookie';
import { Loader, Icon } from 'semantic-ui-react'

import avatar from "assets/img/user.png";
import logo from "assets/img/react-logo.png";
import { getUser } from "widget/parse";
const hostname = window.location.hostname;

var ps;

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = this.getCollapseStates(props.routes);
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path + (routes[i].defaultPath || '')) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    const { companyObj, menuFontSize, profile } = this.props
    const { authObj = {}, authAdminA = [], infowin = false } = profile
    const webtypeId = companyObj.webtypeId || ''
    return routes.map((prop, key) => {
      let flag = ''
      if (prop.redirect) {
        return null;
      }
      if (prop.state === 'SystemCollapse' && authAdminA.indexOf(companyObj.objectId) === -1 && !infowin) {
        return null;
      }
      if (prop.collapse) { // 下拉式的選單
        // if (!profile[companyObj.webtypeId + '_' + prop.state] && profile.authAdminA.indexOf(companyObj.objectId) === -1) {
        // if (profile.authObj[companyObj.webtypeId + '_' + prop.state] === '0') {
        // return null
        // flag = '0'
        // }
        if (prop.webtypeArr && prop.webtypeArr.length) {
          // else {
          if (prop.webtypeArr.indexOf('useLine') !== -1 && companyObj.useLine) {
          } else if (prop.webtypeArr.indexOf('useOfficial') !== -1 && companyObj.useOfficial) {
            // 如果useOfficial有勾 那就顯示 官方網站的區塊
          } else if (prop.webtypeArr.indexOf('useAttendance') !== -1 && companyObj.useAttendance) {
            // 如果useAttendance有勾 那就顯示 官方網站的區塊
          } else if (prop.webtypeArr.indexOf('useMLM') !== -1 && companyObj.useMLM) {
            // 如果useMLM有勾 那就顯示 官方網站的區塊
          } else if (prop.webtypeArr.indexOf('useResource') !== -1 && companyObj.useResource) {
            // 如果useResource有勾 那就顯示 官方網站的區塊
          } else if (prop.webtypeArr.indexOf('useAccount') !== -1 && companyObj.useAccount) {
            // 如果useAccount有勾 那就顯示 官方網站的區塊
          } else if ((hostname !== 'localhost' && prop.webtypeArr.indexOf(webtypeId) === -1) ||
            (prop.webtypeArr.indexOf('localhost') === -1 && prop.webtypeArr.indexOf(webtypeId) === -1)
          ) {
            return null;
          }
          // }
        }
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        // 這邊是目錄
        return (
          <li key={key} className={this.getCollapseInitialState(prop.views) ? "active" : ""}>
            <a
              style={{ fontSize: `${menuFontSize}px` }}
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (<>
                <i className={prop.icon} />
                <p>
                  {prop.name}
                  <b className="caret" />
                </p>
              </>) : (<>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">
                  {prop.name}
                  <b className="caret" />
                </span>
              </>)}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      const page = prop.defaultPath ? prop.path.split('/')[1] + '_' + prop.defaultPath.split('/')[1] : prop.path.split('/')[1]
      if (authObj[companyObj.objectId + '_' + page] && authObj[companyObj.objectId + '_' + page] === '0'
        && authAdminA.indexOf(companyObj.objectId) === -1
      ) {
        flag = '0'   //如果使用者無管理者的權限，或權限為無，flag為'0' sidebar顯示無權限
      }

      if (prop.webtypeArr && prop.webtypeArr.length) {
        if (prop.webtypeArr.indexOf('useLine') !== -1 && companyObj.useLine) {
        } else if (prop.webtypeArr.indexOf('useOfficial') !== -1 && companyObj.useOfficial) {
          // 如果useOfficial有勾 那就顯示 官方網站的區塊
        } else if (prop.webtypeArr.indexOf('useAttendance') !== -1 && companyObj.useAttendance) {
          // 如果useAttendance有勾 那就顯示 官方網站的區塊
        } else if (prop.webtypeArr.indexOf('useMLM') !== -1 && companyObj.useMLM) {
          // 如果useMLM有勾 那就顯示 官方網站的區塊
        } else if (prop.webtypeArr.indexOf('useResource') !== -1 && companyObj.useResource) {
          // 如果useResource有勾 那就顯示 官方網站的區塊
        } else if (prop.webtypeArr.indexOf('useAccount') !== -1 && companyObj.useAccount) {
          // 如果useAccount有勾 那就顯示 官方網站的區塊
        } else if ((hostname !== 'localhost' && prop.webtypeArr.indexOf(webtypeId) === -1) ||
          (prop.webtypeArr.indexOf('localhost') === -1 && prop.webtypeArr.indexOf(webtypeId) === -1)
        ) {
          return null;
        }
      }
      // 這邊是目錄下的連結
      return (
        <li className={this.activeRoute(prop.layout + prop.path + (prop.defaultPath || ''))} key={key}>
          {/* {prop.hidden ? null :  */}
          <NavLink
            style={{ fontSize: `${menuFontSize}px` }}
            to={flag === '0' ? '#' : (prop.layout + prop.path + (prop.defaultPath || ''))} activeClassName="">
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">{flag === '0' ? prop.name + '(無權限)' : prop.name}</span>
                </>
              )}
          </NavLink>
          {/* } */}
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
    getUser()
  }

  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
    const { companyObj, user, profile, menuFontSize } = this.props;
    // const companyObj = cookies.get('companyObj');
    // console.log(this.props.routes)
    // console.log(companyObj.webtypeId)

    const [name = '', host] = user.isAuthenticated ? profile.username && profile.username.split('@') : ['', '']

    return (<div
      className="sidebar"
      data-color={this.props.bgColor}
      data-active-color={this.props.activeColor}
    >
      <div className="logo">
        <a
          href="/admin/dashboard"
          className="simple-text logo-mini"
        >
          <div className="logo-img">
            <img
              // src={logo} 
              src={(companyObj && companyObj.img2File && companyObj.img2File.url) || logo}
              alt="react-logo" />
          </div>
        </a>
        <a
          href="/admin/dashboard"
          className="simple-text logo-normal"
        >
          {/* {companyObj && companyObj.img2 ? <div className="logo-img"><img
              src={companyObj.img2}
              alt="react-logo" /></div> : null} */}
          {companyObj.name}
        </a>
      </div>

      <div className="sidebar-wrapper" ref="sidebar">
        <div className="user">
          <div className="photo">
            <img src={(profile && profile.profilePic && profile.profilePic.url) || avatar} alt="Avatar" />
          </div>
          <div className="info">
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state.openAvatar}
              onClick={() => this.setState({ openAvatar: !this.state.openAvatar })}
            >
              <span
              // style={{ height: '20px' }}
              >
                {name}
                <b className="caret" />
              </span>
            </a>
            <Collapse isOpen={this.state.openAvatar}>
              <ul className="nav">
                <li>
                  <NavLink
                    style={{ fontSize: `${menuFontSize}px` }}
                    to="/admin/user-profile" activeClassName="">
                    <span className="sidebar-mini-icon">MP</span>
                    <span className="sidebar-normal">個人資料</span>
                    {/* My Profile */}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    style={{ fontSize: `${menuFontSize}px` }}
                    to="/admin/user-changepw" activeClassName="">
                    <span className="sidebar-mini-icon">MP</span>
                    <span className="sidebar-normal">修改密碼</span>
                    {/* My Profile */}
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    style={{ fontSize: `${menuFontSize}px` }}
                    to="/admin/user-profile" activeClassName="">
                    <span className="sidebar-mini-icon">EP</span>
                    <span className="sidebar-normal">權限設定</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    style={{ fontSize: `${menuFontSize}px` }}
                    to="/admin/user-profile" activeClassName="">
                    <span className="sidebar-mini-icon">S</span>
                    <span className="sidebar-normal">操作記錄</span>
                  </NavLink>
                </li> */}
              </ul>
            </Collapse>
          </div>
        </div>
        <Nav>{companyObj && companyObj.objectId ? this.createLinks(this.props.routes) : <Loader active inverted inline size='small' />}</Nav>
      </div>
    </div>);
  }
}

// export default Sidebar;
export default withCookies(Sidebar);
