import React, { Component } from 'react';

export default class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillMount() {
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const { semesterYear, semester, title } = this.props;

    return (
      <>
        {/* <h3>{title}</h3> */}
        <h3>確認する</h3>
      </>
    );
  }
}