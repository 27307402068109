import Moment from 'moment';
import { set0String } from './date'
// const { set0String } = require('./date');

export const weekAndDay = (date) => {
  // module.exports.weekAndDay = weekAndDay(date) = {
  return Math.ceil(date.getDate() / 7);// + ' ' + days[date.getDay()];
}
// db.getCollection('Calendar').find({status:{$eq:null},semesterYear:"2018"})
// .forEach(function(doc){doc.eventDate = new ISODate(doc.eventDate).getTime() - 1000 * 3600 * 24 * 365;db.getCollection('Calendar').save(doc);})

// db.getCollection('Calendar').find({}).forEach(function(doc){

//   var str = typeof doc.eventDate  == "string" && doc.eventDate.length==13? Number(doc.eventDate):doc.eventDate;
//  // doc.str = str;
//   var d = new Date(str);
//   var year = d.getFullYear()+"";
//   var month = (d.getMonth() + 1)<10?"0"+(d.getMonth() + 1):(d.getMonth() + 1)+"";
//   var day = (d.getDate() )<10?"0"+(d.getDate() ):(d.getDate() )+"";

//   doc.status=doc.status=="3"?"3":"1";
// //  doc.d=d;
//   doc.eventDate =year+"-"+month+"-"+day;
//   doc.year = year;
//   doc.month = month;
//   doc.day = day;
//   print(doc);
//   db.getCollection('Calendar').save(doc);
// });

// 檢査是否在某時間內有event，只要有重疊到一天都算(因為一個事件可能有好幾天)
export const isEventInBetween = (eventObj, start, end) => {
  const eventDate = new Date(`${eventObj.eventDate}T00:00:00`);
  // const eventDate = new Date(eventObj.eventDate);
  // console.log(eventObj.eventDate, eventDate, start, end)
  if (eventObj.eventDate === Moment(start).format('YYYY-MM-DD')) {
    // console.log(eventObj.eventDate, eventDate, start, end)
    // console.log(Moment(start).format('YYYY-MM-DD'))
    return true;
  }
  if (eventDate > end || eventDate < start) {
    return false;
  }
  return true;
};

export const getSemesterNum = function (semesterYear, semester, eventDate) {
  // return Math.ceil(date.getDate() / 7);// + ' ' + days[date.getDay()];
  // const { semesterYear, semester } = studentObj;
  const d = new Date(eventDate);

  let semesterEndDate = '';
  if (semester === 'S') {
    semesterEndDate = new Date(semesterYear + "-09-30");
  } else {
    semesterEndDate = new Date(Number(semesterYear) + 1 + "-03-31");
  }
  // console.log('semesterEndDate', semesterEndDate);
  if (d <= semesterEndDate) { return "I"; }

  if (semester === 'S') {
    semesterEndDate = new Date(Number(semesterYear) + 1 + "-03-31");
  } else {
    semesterEndDate = new Date(Number(semesterYear) + 1 + "-09-30");
  }
  // console.log('semesterEndDate', semesterEndDate);
  if (d <= semesterEndDate) { return "II"; }

  if (semester === 'S') {
    semesterEndDate = new Date(`${Number(semesterYear) + 1}-09-30`);
    // semesterEndDate3 = new Date(Number(semesterYear + 1) + "-09-30");
  } else {
    semesterEndDate = new Date(Number(semesterYear) + 2 + "-03-31");
  }
  // console.log('semesterEndDate', semesterEndDate);
  if (d <= semesterEndDate) { return "III"; }

  if (semester === 'S') {
    semesterEndDate = new Date(Number(semesterYear) + 2 + "-03-31");
  } else {
    return "";
  }
  // console.log('semesterEndDate', semesterEndDate);
  if (d <= semesterEndDate) { return "IV"; }

  return "";
}

export const getYearMonthDayFormat = isoDate =>
  // console.log(isoDate.getFullYear() + '-' + isoDate.getMonth() + '-' + isoDate.getDate());
  `${isoDate.getFullYear()}-${isoDate.getMonth()}-${isoDate.getDate()}`
  ;

// 算兩個日付之間差幾天
export const getDiffDays = (date1, date2) =>
  // if (!date1 || !date2) return 0;
  Math.floor((Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) - Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())) / (1000 * 60 * 60 * 24)) + 1
  ;

// 回傳這段期間內應上課或上班天数（用期間內全部天数減去不需上課或上班的天数）
export const getExpectedAttndDays = (calendar = [], startDate, endDate) => {
  const [date1, date2] = [new Date(startDate), new Date(endDate)];
  let eventArr = [];
  // status:
  //  3: 老師出席学生不出席

  // 找到期間內所有的event
  eventArr = calendar.filter(eventObj => eventObj.status == '3' && isEventInBetween(eventObj, date1, date2));
  const uniqleDateArr = [];

  eventArr.forEach((item) => {
    const eventDate = new Date(`${item.eventDate}T00:00:00`);// Moment(new Date(date)).format('YYYY-MM-DD');
    // const eventDate = new Date(item.eventDate);// Moment(new Date(date)).format('YYYY-MM-DD');
    if (item.eventDate === Moment(startDate).format('YYYY-MM-DD') || (eventDate >= date1 && eventDate <= date2 && !(uniqleDateArr.includes(item.eventDate)))) {
      uniqleDateArr.push(item.eventDate);
    }
  });

  // console.log(startDate, endDate, '日數:', getDiffDays(date1, date2), '放假數:', uniqleDateArr.length);
  // uniqleDateArr.forEach(item => {
  //   console.log(item);
  // });

  return getDiffDays(date1, date2) - uniqleDateArr.length;
};
export const getTypeDays = (calendar = [], startDate, endDate, type) => {
  const [date1, date2] = [new Date(startDate), new Date(endDate)];
  let eventArr = [];
  // status:
  //  3: 老師出席学生不出席

  const tt = `${type}`;
  // 找到期間內所有的event
  // console.log(startDate, endDate, tt, eventArr.length, eventArr);
  eventArr = calendar.filter(eventObj => eventObj.type == tt && isEventInBetween(eventObj, date1, date2));
  // eventArr.forEach(item => {
  // if (item.title)
  // console.log(item.eventDate, item.title || "土 日");
  // });

  // return getDiffDays(date1, date2) - eventArr.length;
  return eventArr.length;
};

export const getExpectedAttndDaysInMonth = (calendar, year, month) =>
  // return getExpectedAttndDays(calendar, new Date(year, month - 1), new Date(year, month, 1));
  getExpectedAttndDays(calendar, new Date(year, month - 1), new Date(year, month, 0, 0, 0, 0));

export const getJpnDay = (year, month, day) => {
  // Japenese weekday representation from Sunday to Saturday
  const jpnDay = ['日', '月', '火', '水', '木', '金', '土'];
  // month - 1 since the month index is 0-based (0 = January)
  const weekday = (new Date(year, month - 1, day)).getDay();

  return jpnDay[weekday];
};

export const isNextYear = month => (
  !!(month == 1 || month == 2 || month == 3)
);

export const getCorrespondingYear = (year, month) => (isNextYear(month) ? (Number(year) + 1) + '' : year + '');

export const isValidDate = (year, month, day) => {
  // month - 1 since the month index is 0-based (0 = January)
  let date = new Date(year, month - 1, day);

  if ((date.getFullYear() == year) && (date.getMonth() + 1 == month) && (date.getDate() == day)) {
    return true;
  }

  return false;
};

export const getEventArr = (calendar = [], year, month, day) => {
  year += '';
  month = set0String(month);
  day = set0String(day);
  return calendar.filter(item => item.year == year && item.month == month && item.day == day);
};

export const isHoliday = (calendar = [], year, month, day) => {
  month = set0String(month);
  day = set0String(day);
  // console.log(year, month, day);
  if (calendar.filter(item => item.year == year && item.month == month && item.day == day && item.status == '3').length) {
    // console.log(calendar.filter(item => item.year  ==  year && item.month  ==  month && item.day  ==  day && item.status  ==  "3"));
    return 1;
  }
  return 0;
};

/*
getDaysBetween(eventStart, eventEnd, year, month) {
  // The number of milliseconds in one day
  let ONE_DAY = 1000 * 60 * 60 * 24;
  let startDate, endDate;
  let eventStartMonth = eventStart.getMonth() + 1;
  let eventEndMonth = eventEnd.getMonth() + 1;

  // ex. Date(year, month, day) => Date(2018, 1, 5) 是 2018/2/5 00:00:00, 因為month起始值為0
  (eventStartMonth != month) ? startDate = new Date(year, month - 1) : startDate = eventStart;
  // 要設定成隔月的第一天是因為計算天数時候是算區間, 例如 2018/3/31 00:00:00 - 2018/4/1 00:00:00 是算一天
  (eventEndMonth != month) ? endDate = new Date(year, month, 1) : endDate = eventEnd;

  // Convert both dates to milliseconds
  let startDate_ms = startDate.getTime();
  let endDate_ms = endDate.getTime();

  // Calculate the difference in milliseconds
  let difference_ms = Math.abs(startDate_ms - endDate_ms);

  // Convert back to days and return
  return Math.round(difference_ms / ONE_DAY);
}
  // 把事件包含的每一日付存成一個陣列
  getDateInEventArr = (eventObj) => {
    let dateArr = [];
    let daysInEvent = this.getDaysInEvent(eventObj);

    for (let i = 0; i < daysInEvent; i++) {
      // 每次新創造一個currentDate，不然會都reference到同一個
      let currentDate = new Date(eventObj.start.getFullYear(), eventObj.start.getMonth(), eventObj.start.getDate());
      currentDate.setDate(currentDate.getDate() + i);
      dateArr.push(currentDate);
      // console.log('\nloop:', i, '\neventObj:', eventObj, '\ncurrentDate:', currentDate, '\ndateArr:', dateArr);
    }

    return dateArr;
  };

  // 算事件總共有幾天
  getDaysInEvent = (eventObj) => {
    // getDiffDays是算兩日付間差幾天，之所以剛好等於事件天数，是因為event裡面記録一天的start跟end就是剛好就差一個整天
    // (例如2018/10/4有事件，事件中紀録的start值為Thu Oct 04 2018 00:00:00 GMT+0800 (台北標準時間)，end值為Fri Oct 05 2018 00:00:00 GMT+0800 (台北標準時間))
    return getDiffDays(eventObj.start, eventObj.end);
  };

*/
