
import React, { Component } from 'react'
import { Container, Grid, Header, Form, Segment, Menu } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
// import _ from 'underscore'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

// import RowsDnd from './RowsDnd'

// reactstrap components

const columns = () => ([
  { title: 'NO', field: 'no', editable: false },
  { title: '標題', field: 'title' },
  { title: '操作時間', field: 'updatetime', editable: false },
  { title: '最後操作者', field: 'lastuser' },
  { title: '狀態', field: 'state', lookup: { '1': '尚未發布', '2': '發布中' } },
]);

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

class SupportList extends Component {
  state = {
    newData: {},
    oldData: {},
    data1: [],
    loading: true,
  }

  async componentDidMount() {
    const data1 = await Parse.queryData('meritorlist');
    data1.map((item, index) => item.no = index + 1);
    this.setState({ data1, loading: false });
  }

  handleNo = () => {
    const { data1 } = this.state;
    data1.map((item, index) => item.no = index + 1);
    this.setState({ data1 });
  }

  handleAdd = async () => {
    const { newData } = this.state;
    Parse.saveData('meritorlist', newData);
    this.handleNo();
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    Parse.saveData('meritorlist', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    Parse.deleteData('meritorlist', oldData);
    this.setState({ oldData: {} })
    this.handleNo();
  }

  handleData = (rowData, selId) => {
    this.setState({ companyObj: rowData, selId });
  }

  render() {
    const { data1, loading } = this.state;

    return (
      <>
        <div className="content">
          <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns()} options={{
              addRowPosition: "first",
              pageSize: 5,
              search: false,
            }}
            data={data1}
            // actions={[
            //   {
            //     icon: 'view_headline',
            //     tooltip: '查看',
            //     onClick: (event, rowData) => this.handleData(rowData)
            //   }
            // ]}
            onRowClick={(event, rowData) => this.handleData(rowData)}
            title={'護持功德主'}
            editable={{
              onRowAdd: newData =>
                new Promise(async (resolve, reject) => {
                  const data = [...data1];
                  const db = Parse.Object.extend("meritorlist");
                  const newDoc = new db();
                  const doc = await newDoc.save();
                  newData.objectId = doc.id;
                  newData.id = doc.id;
                  newData._id = doc.id;

                  if (!newData.state) {  //沒選擇狀態就是預設尚未發布
                    newData.state = '1';
                  }

                  data.push(newData);
                  this.setState({ data1: data, newData }, () => resolve());
                }).then(() => this.handleAdd()),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const data = [...data1];
                  const index = data.indexOf(oldData);
                  data[index] = newData;
                  this.setState({ data1: data, newData, oldData }, () => resolve());
                }).then(() => this.handleUpdate()),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  let data = [...data1];
                  const index = data.indexOf(oldData);
                  data.splice(index, 1);
                  this.setState({ data1: data, oldData }, () => resolve());
                }).then(() => this.handleDelete()),
            }}
          />
          <br />
          {/* {rowData && rowData.number ?
        <Segment>
          <Form>
            <Form.Group widths='equal'>
              <Form.Select fluid label='位置' name='sideId' value={rowData.sideId} onChange={this.handleChange} />
              <Form.Input fluid label='編號' name='number' value={rowData.number} onChange={this.handleChange} />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Input fluid label='供奉陽上人' name='livename' value={rowData.livename} onChange={this.handleChange} />
              <Form.Input fluid label='供奉亡者' name='passname' value={rowData.passname} onChange={this.handleChange} />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Input fluid abel='備註' name='ps' value={rowData.ps} onChange={this.handleChange} />
            </Form.Group>
            <Form.Button color='blue' onClick={this.handleSaveCardslot}>儲存</Form.Button>
          </Form>
        </Segment>
        : null}
      <br /> */}
          <br />
        </div>
      </>
    );
  }
}

export default SupportList;
