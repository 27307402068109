import React, { Component } from 'react';
import { Table, Header, Button } from 'semantic-ui-react';
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton'
import { getHoursCount, getDaysCount, getWeekRangeArr } from '../../../../widget/define/week';
import { getOptionsByKey, handleClickStudent } from 'views/widgets/FieldsRender';
import Parse from '../../../../widget/parse'

const weekdayArr = [1, 2, 3, 4, 5]; // sun: 0, mon: 1, tues: 2, wed: 3, thurs: 4, friday: 5, sat: 6

const breakSlash = (text) => {
  const arr = text.split('/') || ['', ''];
  // return <>{arr[0] || ''}<br />/{arr[1] || ''}</>;
  const number = (arr[0] && arr[1]) ? Number(Number(arr[0]) / Number(arr[1])) : 0;
  const rate = Math.round(number * 1000) / 10;
  return <>{arr[0] || ''}/{arr[1] || ''}<br />{rate || '0'}%</>;
}

const lineBreak = (text) => {
  const pos = 6;
  if (text.length < pos)
    return text;
  else {
    const str0 = text.substring(0, pos);
    const str1 = text.substring(pos);
    return <>{str0}<br />{str1}</>;
  }
}

export default class AttendanceDaily extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selStudentObj: '',
      selCourseName: '',
      selWeekday: '',
      selPeriod: '',
      selDate: '',
      showModal: false,
      formArr: [],
      // loading: true,
      unsub: null,
      rollcallArr: []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
  }

  componentDidMount() {
    this.handleFetch()
    this.getDaily();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps', nextProps);
    if (this.props.yearsemester !== nextProps.yearsemester) {
      this.handleFetch(nextProps)
    }
    if (this.props.doc !== nextProps.doc)
      this.getDaily(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { firebase, yearsemester, match, companyObj } = props;
    const { year, semes } = yearsemester;

    const data = await Parse.queryData('Classroom', { companyId: companyObj.objectId });
    this.setState({ classroom: data });
    // firebase.firestore().collection("Classroom")
    //   .get().then(snap => {
    //     const classroom = [];
    //     snap.forEach(doc => classroom.push({ _id: doc.id, ...doc.data() }));
    //     // console.log(data)
    //     // console.lo)
    //     this.setState({ classroom });
    //   });
  }

  getDaily = async (props = this.props) => {
    const { firebase, calendar, doc } = props;
    const { currentUnsub } = this.state;

    if (!doc || !doc.objectId) {
      return;
    }

    // if (this.state.unsub) {
    //   this.state.unsub();
    //  console.log('unsub worked!')
    // }

    const query = new Parse.Query('Rollcall');
    query.equalTo('studentId', doc.objectId);
    query.limit(10000)
    const snap = await query.find();
    const rollcallArr = snap.map(data => data.toJSON());
    console.log(rollcallArr)
    this.setState({ rollcallArr })
    if (currentUnsub) {
      await currentUnsub.unsubscribe();
      this.setState({ currentUnsub: null })
    }
    let unsub = await query.subscribe().catch(error => console.error(error));
    unsub.on('create', (data) => {
      const rollcallArr = [...this.state.rollcallArr, data.toJSON()];
      this.setState({ rollcallArr })
    });
    unsub.on('update', (data) => {
      const rollcallArr = [...this.state.rollcallArr, data.toJSON()];
      this.setState({ rollcallArr })
    });
    unsub.on('delete', (data) => {
      const rollcallArr = [...this.state.rollcallArr, data.toJSON()];
      this.setState({ rollcallArr })
    });
    this.setState({ currentUnsub: unsub })

    // const unsub = firebase.firestore().collectionGroup('Rollcall')
    //   .where('studentId', '==', doc._id)
    //   .onSnapshot(snap => {
    //     const data = [];
    //     snap.forEach(doc => data.push({ ...doc.data() }));
    //     // console.log(data);

    //     this.setState({ rollcallArr: data });
    //   }, err => {
    //     console.log(`Encountered error: ${err}`);
    //   });
    // this.setState({ unsub });
  }

  getStatus(statusArr, period) {
    // 找出事件符合 此日期 且 此時段 
    const status = statusArr.find(item => item.period === (period + ''));
    // console.log(statusArr, period, status);
    return (
      status ?
        status.attendStatus === '1' ?
          '○' : status.attendStatus === '8' ?
            '✕' : status.attendStatus === '2' ?
              '⦰' : '' : ''
    );
    // return (
    //   status ?
    //     status.attendStatus === '1' ?
    //       <span style={{ fontSize: '22px' }}>○</span> : status.attendStatus === '8' ?
    //         'x' : <p style={{ paddingTop: '1px' }}>⦰</p>
    //     : ''
    // );
  }

  getClass(statusArr, period) {
    // 找出事件符合 此日期 且 此時段 
    const status = statusArr.find(item => item.period === (period + ''));
    // console.log(statusArr, period, status);
    return (
      status ? status.classname : ''
    );
  }

  getRollcallObj(statusArr, period) {
    // 找出事件符合 此日期 且 此時段 
    const status = statusArr.find(item => item.period === (period + ''));
    // console.log(statusArr, period, status);
    return (
      status ? status : null
    );
  }

  // handleClickCell = (studentObj, date, period) => {
  //   // console.log(studentObj, date, period);
  //   const weekdayArrCN = ['日', '月', '火', '水', '木', '金', '土'];
  //   const periodArrCN = ['ㄧ', '二', '三', '四', '五', '六', '七', '八'];

  //   // console.log(date);
  //   // console.log(Moment(date).format('YYYY-MM-DD'));
  //   // const selDate = Moment(date).format('YYYY-MM-DD');

  //   this.setState({
  //     selStudentObj: studentObj,
  //     // ex. 月一, 火三
  //     selCourseName: weekdayArrCN[new Date(date).getDay()] + periodArrCN[period],
  //     selWeekday: (new Date(date).getDay() - 1) + '', // 0 ~ 4
  //     selPeriod: period + '',   // 1 ~ 7
  //     selDate: Moment(new Date(date)).format('YYYY-MM-DD'),
  //     showModal: true,
  //   });
  // };

  handleChange(event) {
    // console.log(event);
    // let fieldName = event.target.name;
    // let fleldVal = event.target.value;
    // this.setState({ form: { ...this.state.form, [fieldName]: fleldVal } });

    const { formArr } = this.state;
    let index = formArr.findIndex(item => item.objectId === event.objectId);
    if (index === -1) {
      const studentId = this.props.doc.objectId;
      formArr.push({ ...event, studentId })
    } else {
      // console.log(newSel[index]);
      const newObj = { ...formArr[index], ...event };
      formArr[index] = newObj;
    }
    this.setState({ formArr });
  }

  handleSaveForm = () => {
    // const { eventGrade } = this.props;
    // const { formArr } = this.state;
  }

  handleClickCell = async (e) => {
    // console.log('handleClickCell', e);
    const [studentId, eventDate, period] = e.currentTarget.id.split(',');
    const { students, multiSelect } = this.props;
    const { multiStudent } = this.state;
    const selStudentObj = students.find(item => item.objectId === studentId) || {};
    if (multiSelect === true) {
      let findIndex = multiStudent.findIndex(item => studentId === item.selStudentId &&
        eventDate === item.selDate &&
        period === item.selPeriod)
      if (findIndex === -1) {
        multiStudent.push({
          selPeriod: period,
          selDate: eventDate,
          selStudentObj,
          selStudentId: selStudentObj.objectId
        })
      } else {
        multiStudent.splice(findIndex, 1);
      }
      // console.log(multiStudent)
      this.setState({
        multiStudent,
        selStudentObj: {},
        // showModal: true 
      })
    } else if (multiSelect === false) {
      this.setState({
        showModal: true,
        selPeriod: period,
        selDate: eventDate,
        selStudentObj,
        selStudentId: selStudentObj.objectId
      })
    }
  }

  render() {
    const { formSet, optSet, doc = {}, calendar = [], isEdit, firebase } = this.props;
    const { rollcallArr, classroom } = this.state;

    const yearSchoolArr = getOptionsByKey(optSet, 'semesterYear');

    let classTime = ''
    if (doc.classSchool) {//按照班級上課時間
      // db.collection('Classroom')
      //   .where('classSchool', '==', doc.classSchool)
      //   .where('semesterYear', '==', nowSemesterYear)
      //   .where('semester', '==', nowSemester)
      //   .get().then(snap => {
      //     snap.forEach(doc => classTime = doc.data().classTime);
      //   });
      classTime = classroom && classroom.find(item => item.classSchool === doc.classSchool && item.entryYear === doc.semesterYear) && classroom.find(item => item.classSchool === doc.classSchool && item.entryYear === doc.semesterYear).classTime

    } else if (!doc.classSchool) {//無班級則用入學年判斷
      classTime = yearSchoolArr.find(item => item.value === doc.semesterYear)
        && yearSchoolArr.find(item => item.value === doc.semesterYear).note1;// === '2' ? '午後' : '午前'
    }

    // const headerArr = crItem.classTime === '1' ? [1, 2, 3, 4] : [5, 6, 7, 8]; // 午前顯示: [1, 2, 3, 4], 午後顯示[5, 6, 7, 8]
    const headerArr = classTime === "1" ? [1, 2, 3, 4] : [5, 6, 7, 8]; // 午前顯示: [1, 2, 3, 4], 午後顯示[5, 6, 7, 8]
    const periodArr = headerArr.slice().map(v => v - 1); // 午前: [0, 1, 2, 3], 午後: [4, 5, 6, 7]
    const weekRangeArr = getWeekRangeArr(doc);

    const staticArr = [];
    if (weekRangeArr && weekRangeArr.length) {

      let dayTotal0 = 0;
      let dayTotal1 = 0;
      let hoursTotal0 = 0;
      let hoursTotal1 = 0;
      let startDate = doc.lessonStartDate || doc.courseStartDate
      // console.log(weekRangeArr);
      // 每週
      weekRangeArr.forEach(item => {
        let minus = 0
        // item.map(day => {
        //   if (new Date(day) < new Date(doc.courseStartDate)) {
        //     minus++
        //   }
        // })
        // console.log(minus)

        // let di = item.findIndex(d => new Date(d) < new Date(doc.lessonStartDate || doc.courseStartDate))
        // if (di !== -1) {
        //   // console.log(item)
        //   item.splice(di, 1)
        // }

        let count = item.filter(d => new Date(d) < new Date(startDate))

        const day = getDaysCount(rollcallArr, item, periodArr, calendar, startDate, count.length);
        const hours = getHoursCount(rollcallArr, item, periodArr, calendar, startDate, count.length);

        const arrDay = day.split("/");
        const arrHours = hours.split("/");

        dayTotal0 += Number(arrDay[0]);
        dayTotal1 += Number(arrDay[1]);
        hoursTotal0 += Number(arrHours[0]);
        hoursTotal1 += Number(arrHours[1]);

        const dayAcc = dayTotal0 + "/" + dayTotal1;
        const hoursAcc = hoursTotal0 + "/" + hoursTotal1;

        const obj = { day, hours, dayAcc, hoursAcc };
        staticArr.push(obj);
      });
    }
    const arrPdf = {
      ...doc,
      classTime,
      rollcallArr
    }
    // console.log(weekRangeArr)

    return (<>
      <div ref={el => (this.componentRef = el)} className="" >
        <Header as='h2' dividing
          content='週別出席一覧表'
          style={{ backgroundColor: 'lavender' }}
        // subheader='調査時間：2017年4月から 2018年12月 まで'
        />
        {/* <ReactToPrint
          trigger={(e) => <Button
            content='印刷'
            // label='&nbsp;'
            color='orange' icon='print'
            floated='right'
            size='small'
          />}
          content={() => this.componentRef}
          pageStyle={"@page { size: auto;  margin: 10mm; }*{font-family: 'Noto Serif JP', serif;}"}
        /> */}
        <ModalPdf
          {...this.props}
          // ref={(ref) => { this.pdfModal = ref }}
          codekey="attendanceDailyPdf"
          params={arrPdf}
          floated='right'
        />
        <ExportTableToExcelButton floated='right' size='small' />

        <br />
        <br />
        <Table size="small" id='excel' striped structured celled style={{ textAlign: 'center' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ padding: '2px' }}></Table.HeaderCell>
              <Table.HeaderCell style={{ padding: '2px' }} colSpan={4}>(月)</Table.HeaderCell>
              <Table.HeaderCell style={{ padding: '2px' }} colSpan={4}>(火)</Table.HeaderCell>
              <Table.HeaderCell style={{ padding: '2px' }} colSpan={4}>(水)</Table.HeaderCell>
              <Table.HeaderCell style={{ padding: '2px' }} colSpan={4}>(木)</Table.HeaderCell>
              <Table.HeaderCell style={{ padding: '2px' }} colSpan={4}>(金)</Table.HeaderCell>
              <Table.HeaderCell style={{ padding: '2px' }} rowSpan={2}>日<br />数<br />計</Table.HeaderCell>
              <Table.HeaderCell style={{ padding: '2px' }} rowSpan={2}>時<br />間<br />計</Table.HeaderCell>
              <Table.HeaderCell style={{ padding: '2px' }} rowSpan={2}>累<br />日<br />計</Table.HeaderCell>
              <Table.HeaderCell style={{ padding: '2px' }} rowSpan={2}>累<br />時<br />計</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              {/* <Table.Cell style={{ textAlign: 'left' }} ></Table.Cell> */}
              <Table.HeaderCell style={{ padding: '2px' }}></Table.HeaderCell>
              {weekdayArr.map((item, index) =>
                <React.Fragment key={`${item}_${index}`}>
                  <Table.HeaderCell style={{ padding: '2px' }}>{headerArr[0]}</Table.HeaderCell>
                  <Table.HeaderCell style={{ padding: '2px' }}>{headerArr[1]}</Table.HeaderCell>
                  <Table.HeaderCell style={{ padding: '2px' }}>{headerArr[2]}</Table.HeaderCell>
                  <Table.HeaderCell style={{ padding: '2px' }}>{headerArr[3]}</Table.HeaderCell>
                </React.Fragment>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>{weekRangeArr.length ? weekRangeArr.map((weekArr, i) =>
            <Table.Row key={i}>
              <Table.Cell style={{ textAlign: 'left', padding: '2px' }}>{weekArr[0]}~<br />{weekArr.length < 5 ? weekArr[weekArr.length - 1] : weekArr[4]}</Table.Cell>
              {/* {weekArr.length < 5 ? //開學前*1，若有好幾天需再加表格
                <Table.Cell key={"no" + i} colSpan={4 * (5 - weekArr[i].length)} style={{ backgroundColor: 'pink', padding: '1px' }} >
                  {<div style={{ color: '#999', fontSize: '12px' }}>
                    {'未開講'}</div>}
                </Table.Cell> : null} */}
              {weekArr.map((eventDate, index) => {
                const statusArr = rollcallArr.length ?
                  rollcallArr.filter(item => item.eventDate === eventDate) : [];
                // console.log(statusArr)
                const arrCalendar = calendar.filter(item => item.eventDate === eventDate && item.status === "3");

                if (new Date(eventDate) < new Date(doc.lessonStartDate || doc.courseStartDate)) {
                  return (<Table.Cell key={"arr" + index} colSpan={4} style={{ backgroundColor: 'pink', padding: '1px' }} >
                    {arrCalendar.map(item2 => <div key={item2.objectId} style={{ color: '#999', fontSize: '12px' }}>
                      {item2.title ? lineBreak(item2.title) : '(休)'}</div>)}
                  </Table.Cell>);
                } else if (arrCalendar.length) {
                  return (<Table.Cell key={"arr" + index} colSpan={4} style={{ backgroundColor: 'pink', padding: '1px' }} >
                    {arrCalendar.map(item2 => <div key={item2.objectId} style={{ color: '#999', fontSize: '12px' }}>
                      {item2.title ? lineBreak(item2.title) : '(休)'}</div>)}
                  </Table.Cell>);
                } else {
                  let rollcallObjArr = []
                  let a = [0, 1, 2, 3].map(i => {
                    if (this.getRollcallObj(statusArr, periodArr[i]))
                      rollcallObjArr.push(this.getRollcallObj(statusArr, periodArr[i]))
                    else
                      rollcallObjArr.push({
                        objectId: doc.objectId,
                        jpnName: doc.jpnName,
                        studentId: doc.objectId,
                        studentNum: doc.studentId,
                        attendStatus: "-1",
                        attendStatusText: "",
                        classname: doc.classSchool,
                        period: periodArr[i] + '',
                        weekday: index + '',
                        courseName: ['月', '火', '水', '木', '金'][index] + headerArr[i],
                        eventDate,
                      })
                  })
                  // console.log(statusArr)
                  return (
                    <React.Fragment key={index}>
                      <Table.Cell style={{ padding: '2px', cursor: isEdit ? 'pointer' : '' }} onClick={isEdit ? () => handleClickStudent(firebase, optSet, rollcallObjArr[0]) : () => void 0}>{this.getClass(statusArr, periodArr[0])}<br />{this.getStatus(statusArr, periodArr[0])}</Table.Cell>
                      <Table.Cell style={{ padding: '2px', cursor: isEdit ? 'pointer' : '' }} onClick={isEdit ? () => handleClickStudent(firebase, optSet, rollcallObjArr[1]) : () => void 0}>{this.getClass(statusArr, periodArr[1])}<br />{this.getStatus(statusArr, periodArr[1])}</Table.Cell>
                      <Table.Cell style={{ padding: '2px', cursor: isEdit ? 'pointer' : '' }} onClick={isEdit ? () => handleClickStudent(firebase, optSet, rollcallObjArr[2]) : () => void 0}>{this.getClass(statusArr, periodArr[2])}<br />{this.getStatus(statusArr, periodArr[2])}</Table.Cell>
                      <Table.Cell style={{ padding: '2px', cursor: isEdit ? 'pointer' : '' }} onClick={isEdit ? () => handleClickStudent(firebase, optSet, rollcallObjArr[3]) : () => void 0}>{this.getClass(statusArr, periodArr[3])}<br />{this.getStatus(statusArr, periodArr[3])}</Table.Cell>
                    </React.Fragment>
                  )
                }
              })}
              <Table.Cell style={{ padding: '2px', textAlign: 'right' }}>{(staticArr[i].day)}</Table.Cell>
              <Table.Cell style={{ padding: '2px', textAlign: 'right' }}>{(staticArr[i].hours)}</Table.Cell>
              <Table.Cell style={{ padding: '2px', textAlign: 'right' }}>{breakSlash(staticArr[i].dayAcc)}</Table.Cell>
              <Table.Cell style={{ padding: '2px', textAlign: 'right' }}>{breakSlash(staticArr[i].hoursAcc)}</Table.Cell>
            </Table.Row>
          ) : null}
          </Table.Body>
        </Table>
      </div>
    </>);
  }
}