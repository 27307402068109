import { a4pageWidthHalf } from "../pdf/defPdf";


export const repairList1 = (doc, arrObj) => {

  doc.setFontSize(20);
  doc.setFont('BiauKai');
  doc.text('維 修 清 單', a4pageWidthHalf, 95, null, null, "center");
  doc.setFontSize(10);
  doc.text('客戶名稱： 陳正治', 15, 115)
  doc.text('牌照號碼： AYB- 3172', 15, 125)
  doc.text('引擎號碼： ', 15, 135)
  doc.text('車身號碼： ', 15, 145)
  doc.text('連絡地址： ', 15, 155)
  doc.text('維修部份： ', 15, 165)

  doc.text('車    型: 威曲 2.0 J 手自排/休旅車', a4pageWidthHalf, 115, null, null, "center")
  doc.text('里    程: 164,547', 255, 125, null, null, "center")
  doc.text('進廠時間: 2020/06/24 PM 02:19', 287, 135, null, null, "center")
  doc.text('出廠時間: ', 239, 145, null, null, "center")

  doc.text('工作單號：  K120060072', 420, 115)
  doc.text('服務廠別： 凱瑞汽車', 420, 125)
  doc.text('掛牌照日： 2019/10/17', 420, 135)
  doc.text('統一編號： ', 420, 145)
  doc.text('印表日期： 2020/06/30 AM 11:30', 420, 155)

  doc.text('項次', 20, 175)
  doc.text('收費碼', 60, 175)
  doc.text('工作', 202, 175)
  doc.text('折扣', 400, 175)
  doc.text('免費金額', 430, 175)
  doc.text('應收金額', 485, 175)
  doc.text('技師', 550, 175)

  doc.line(15, 178, 580, 178)

  doc.text('1', 27, 188)
  doc.text('2', 27, 198)

  doc.text('3', 72, 188)
  doc.text('3', 72, 198)

  doc.text('更換水箱', 100, 188)
  doc.text('更換變速箱冷卻器', 100, 198)

  doc.text('0 %', 410, 188)
  doc.text('0 %', 410, 198)

  doc.text('0 ', 470, 188)
  doc.text('0 ', 470, 198)

  doc.text('0 ', 524, 188)
  doc.text('0 ', 524, 198)

  doc.text('KE021', 548, 188)
  doc.text('KE021', 548, 198)

  doc.line(477, 202, 530, 202)

  doc.text('工資小計:', 430, 210)
  doc.text('0', 524, 210)

  doc.text('零件部份：', 15, 225)

  doc.text('項次', 20, 237)
  doc.text('收費碼', 60, 237)
  doc.text('零件名稱', 190, 237)
  doc.text('折扣', 400, 237)
  doc.text('免費金額', 430, 237)
  doc.text('應收金額', 485, 237)
  doc.text('技師', 550, 237)

  doc.line(15, 240, 580, 240)

  doc.text('1', 27, 250)
  doc.text('2', 27, 260)
  doc.text('3', 27, 270)

  doc.text('3', 72, 250)
  doc.text('3', 72, 260)
  doc.text('3', 72, 270)

  doc.text('WISH 水箱-鋁', 100, 250)
  doc.text('WISH 副水桶', 100, 260)
  doc.text('WISH 變速箱冷卻器（正廠）', 100, 270)

  doc.text('100 %', 400, 250)
  doc.text('100 %', 400, 260)
  doc.text('100 %', 400, 270)

  doc.text('0 ', 470, 250)
  doc.text('0 ', 470, 260)
  doc.text('0 ', 470, 270)

  doc.text('3,500 ', 507, 250)
  doc.text('1,000 ', 507, 260)
  doc.text('7,500 ', 507, 270)

  doc.text('KE030', 548, 250)
  doc.text('KE021', 548, 260)
  doc.text('KE021', 548, 270)

  doc.line(477, 274, 530, 274)
  doc.text('零件小計:', 430, 282)
  doc.text('12,000', 502, 282)

  doc.text('小計', 400, 294)
  doc.text('工資:', 450, 294)
  doc.text('0', 524, 294)

  doc.text('零件:', 450, 304)
  doc.text('12,000', 502, 304)

  doc.line(477, 309, 530, 310)

  doc.text('金額:', 450, 318)
  doc.text('總計:', 400, 318)
  doc.text('12,000', 502, 318)

  doc.text('祝 您行車平安！', 15, 350)

  doc.text('備   註：', 27, 700)
  doc.text('建議事項：', 27, 730)

  doc.text('現金:______________', 32, 768)
  doc.rect(20, 760, 10, 10)

  doc.text('刷卡:______________', 175, 768)
  doc.rect(163, 760, 10, 10)

  doc.text('主管:____________', 300, 768)
  doc.text('出納:____________', 400, 768)
  doc.text('客戶:____________', 500, 768)

}

export const repairList2 = (doc, arrObj) => {

  doc.setFontSize(14);
  doc.setFont('BiauKai');
  doc.text('車輛委修單', a4pageWidthHalf, 30, null, null, "center");


  doc.setFontSize(12);
  doc.text('油料', 422, 120)
  doc.line(418, 123, 455, 123)


  doc.setFontSize(8);
  doc.text('F    -', 425, 133)
  doc.text('3/4   -', 422, 151)
  doc.text('1/2   -', 422, 168)
  doc.text('1/4   -', 422, 186)
  doc.text('E    -', 425, 204)


  doc.setFontSize(10);
  doc.text('車身外觀紀錄', 488, 120)


  doc.setFontSize(10);
  doc.text('服務廠別： 凱瑞汽車', 20, 40)
  doc.text('預計交車： 2020/06/25  14:19', 410, 40)
  doc.text('工作單號： K120060072', 20, 50)
  doc.text('列印日期： 2020/06/24  14:21:21', 410, 50)

  doc.line(15, 55, 580, 55)

  doc.text('牌照號碼： AYB-3172', 15, 65)
  doc.text('廠    牌： TOYOTA', 15, 75)
  doc.text('出廠年月： 2013/10', 15, 85)
  doc.text('掛牌照日： 2019/10/17', 15, 95)
  doc.text('車身號碼： ', 15, 105)


  doc.text('年    份： 2013', 160, 65)
  doc.text('車型代碼：', 160, 75)
  doc.text('引擎號碼：', 160, 85)
  doc.text('變 速 箱：', 160, 95)

  doc.text('車    型： 威曲 2.0 J 手自排/休旅車', 295, 65)
  doc.text('內裝代碼：', 295, 75)
  doc.text('顏色代碼：', 295, 85)
  doc.text('本次里程： 164,547', 295, 105)

  doc.text('進廠時間： 2020/06/24  14：19', 410, 75)
  doc.text('出廠時間： ', 410, 85)
  doc.text('上次進廠： 2019/11/01  00：00', 410, 95)
  doc.text('上次里程： 164,547', 410, 105)

  doc.line(15, 108, 580, 108)

  doc.text('車主名稱： 陳正治', 15, 118)
  doc.text('行動電話： 0910-054-843', 15, 128)
  doc.text('公司電話： ', 15, 138)
  doc.text('住所號碼： ', 15, 148)
  doc.text('統一編號： ', 15, 158)
  doc.text('地址資料： ', 15, 168)
  doc.text('備    註： ', 15, 178)

  doc.text('使用人： 陳政治', 215, 118)
  doc.text('E-MAIL： ', 215, 158)

  doc.line(15, 183, 410, 183)

  doc.text('預約', 32, 193)
  doc.ellipse(22, 191, 4, 4)

  doc.text('報價', 82, 193)
  doc.ellipse(72, 191, 4, 4)

  doc.text('取送車', 128, 193)
  doc.ellipse(118, 191, 4, 4)

  doc.text('代步車', 182, 193)
  doc.ellipse(172, 191, 4, 4)

  doc.text('保險', 232, 193)
  doc.ellipse(222, 191, 4, 4)

  doc.text('接待人員： 鐘曉芬', 295, 193)

  doc.line(15, 198, 410, 198)
  doc.text('交通型態：', 15, 208)

  doc.text('現等', 85, 208)
  doc.ellipse(80, 206, 4, 4)

  doc.text('完工通知', 120, 208)
  doc.ellipse(115, 206, 4, 4)

  doc.text('當日交車', 176, 208)
  doc.ellipse(171, 206, 4, 4)

  doc.text('業代開回', 230, 208)
  doc.ellipse(225, 206, 4, 4)

  doc.text('完工交理賠', 284, 208)
  doc.ellipse(279, 206, 4, 4)

  doc.text('完工時交接待', 345, 208)
  doc.ellipse(340, 206, 4, 4)

  doc.line(15, 213, 580, 213)
  doc.line(15, 228, 580, 228)

  doc.text('項次 交修代碼', 20, 225)
  doc.text('客戶交修問題敘述', 128, 225)
  doc.text('維修單號', 435, 225)
  doc.text('Pay_Code', 520, 225)

  doc.text('1   99', 27, 238)
  doc.text('2   99', 27, 248)

  doc.text('更換水箱', 128, 238)
  doc.text('更換變速箱冷卻器', 128, 248)

  doc.text('01', 450, 238)
  doc.text('01', 450, 248)

  doc.text('3', 538, 238)
  doc.text('3', 538, 248)

  doc.text('顧客須知:', 15, 268)
  doc.text('待修說明資料:', 300, 268)

  doc.text('1.天災，不可抗拒外力，導致車輛受損，貴公司協助修護，車', 20, 293)
  doc.text(' 主及實際使用人不得有他請求', 20, 305)
  doc.text('2.委修人確定同意以上客戶須知內容，請於客戶委修說明。', 20, 315)

  doc.text('建議事項:', 20, 690)
  doc.text('顧客須知:', 20, 730)

  doc.text('預估費用：＿＿＿＿＿＿＿', 295, 690)
  doc.text('預估工時：＿＿＿＿＿＿＿＿＿', 440, 690)
  doc.text('品    管：＿＿＿＿＿＿＿', 295, 710)
  doc.text('技    師：＿＿＿＿＿＿＿＿＿', 440, 710)
  doc.text('顧客須知：＿＿＿＿＿＿', 440, 830)


  doc.setFontSize(7);
  doc.setFont('BiauKai');
  doc.text('1.本人授權貴公司對本單所開車輛做以上各種之檢修與更換必須知材料及零件，並准許貴公司所屬之員工為執行本單內容得行使該車至街市、公路及其他地方進行檢測與調整，若因此', 20, 740)
  doc.text(' 造成意外事故發生時，所生之損壞由貴公司負責修復，車主及實際使用人不得有其他請求。', 20, 750)
  doc.text('2.自貴公司通知領車三日後若不取車，貴公司得視情況加以處理，並斟酌收停車費NT$500元/月;若超過30日以上，貴公司不負保管責任。', 20, 760)
  doc.text('3.車內貴重物品本人負責取出自行保管，若必須委由貴公司代為保管時，應事先通知過公司接待人員並註明本單中，否則若有遺失、毀損之情事發生，貴公司概不負責。', 20, 770)
  doc.text('4.本車若為保險或保險公司不予理賠時，維修費用由本人自行負責。', 20, 780)
  doc.text('5.本人同意貴公司得將本人之個人資料經電腦處理後，為貴公司內部或供集團授權之特約經銷及服務體系及所屬集團暨其所指定之人等為提升客戶服務品質、', 20, 790)
  doc.text(' 提供維修保固等相關服務、車輛資訊之提供及推廣客戶關懷活動而使用。', 20, 800)
  doc.text('6.因本單中所產生之任何爭議，雙方同意以台灣台北地方法院為第一審管轄法院。', 20, 810)

}
export const repairList3 = (doc, arrObj) => {

  doc.setFontSize(16);
  doc.setFont('BiauKai');
  doc.text('人事調任公告單', a4pageWidthHalf, 30, null, null, "center");


  doc.setFontSize(10);
  doc.text('記錄編號： ___ - ___ - ___ ', 440, 40)
  doc.text('填寫日期：民國   /   /   ', 440, 60)

  doc.line(15, 70, 580, 70)

  doc.setFontSize(13);
  doc.text('申請人', 50, 100)
  doc.text('調任委員', 50, 120)
  doc.text('原任職稱', 50, 140)
  doc.text('新任職稱', 50, 160)
  doc.text('備註', 50, 190)
  doc.text('申請部門', 350, 100)
  doc.text('生效日期', 350, 120)
  doc.text('原任部門', 350, 140)
  doc.text('新任部門', 350, 160)


  // doc.text('年    份： 2013', 160, 65)
  // doc.text('車型代碼：', 160, 75)
  // doc.text('引擎號碼：', 160, 85)
  // doc.text('變 速 箱：', 160, 95)

  // doc.text('車    型： 威曲 2.0 J 手自排/休旅車', 295, 65)
  // doc.text('內裝代碼：', 295, 75)
  // doc.text('顏色代碼：', 295, 85)
  // doc.text('本次里程： 164,547', 295, 105)

  // doc.text('進廠時間： 2020/06/24  14：19', 410, 75)
  // doc.text('出廠時間： ', 410, 85)
  // doc.text('上次進廠： 2019/11/01  00：00', 410, 95)
  // doc.text('上次里程： 164,547', 410, 105)

  // doc.line(15, 108, 580, 108)

  // doc.text('車主名稱： 陳正治', 15, 118)
  // doc.text('行動電話： 0910-054-843', 15, 128)
  // doc.text('公司電話： ', 15, 138)
  // doc.text('住所號碼： ', 15, 148)
  // doc.text('統一編號： ', 15, 158)
  // doc.text('地址資料： ', 15, 168)
  // doc.text('備    註： ', 15, 178)

  // doc.text('使用人： 陳政治', 215, 118)
  // doc.text('E-MAIL： ', 215, 158)

  // doc.line(15, 183, 410, 183)

  // doc.text('預約', 32, 193)
  // doc.ellipse(22, 191, 4, 4)

  // doc.text('報價', 82, 193)
  // doc.ellipse(72, 191, 4, 4)

  // doc.text('取送車', 128, 193)
  // doc.ellipse(118, 191, 4, 4)

  // doc.text('代步車', 182, 193)
  // doc.ellipse(172, 191, 4, 4)

  // doc.text('保險', 232, 193)
  // doc.ellipse(222, 191, 4, 4)

  // doc.text('接待人員： 鐘曉芬', 295, 193)

  // doc.line(15, 198, 410, 198)
  // doc.text('交通型態：', 15, 208)

  // doc.text('現等', 85, 208)
  // doc.ellipse(80, 206, 4, 4)

  // doc.text('完工通知', 120, 208)
  // doc.ellipse(115, 206, 4, 4)

  // doc.text('當日交車', 176, 208)
  // doc.ellipse(171, 206, 4, 4)

  // doc.text('業代開回', 230, 208)
  // doc.ellipse(225, 206, 4, 4)

  // doc.text('完工交理賠', 284, 208)
  // doc.ellipse(279, 206, 4, 4)

  // doc.text('完工時交接待', 345, 208)
  // doc.ellipse(340, 206, 4, 4)

  // doc.line(15, 213, 580, 213)
  // doc.line(15, 228, 580, 228)

  // doc.text('項次 交修代碼', 20, 225)
  // doc.text('客戶交修問題敘述', 128, 225)
  // doc.text('維修單號', 435, 225)
  // doc.text('Pay_Code', 520, 225)

  // doc.text('1   99', 27, 238)
  // doc.text('2   99', 27, 248)

  // doc.text('更換水箱', 128, 238)
  // doc.text('更換變速箱冷卻器', 128, 248)

  // doc.text('01', 450, 238)
  // doc.text('01', 450, 248)

  // doc.text('3', 538, 238)
  // doc.text('3', 538, 248)

  // doc.text('顧客須知:', 15, 268)
  // doc.text('待修說明資料:', 300, 268)

  // doc.text('1.天災，不可抗拒外力，導致車輛受損，貴公司協助修護，車', 20, 293)
  // doc.text(' 主及實際使用人不得有他請求', 20, 305)
  // doc.text('2.委修人確定同意以上客戶須知內容，請於客戶委修說明。', 20, 315)

  // doc.text('建議事項:', 20, 690)
  // doc.text('顧客須知:', 20, 730)

  // doc.text('預估費用：＿＿＿＿＿＿＿', 295, 690)
  // doc.text('預估工時：＿＿＿＿＿＿＿＿＿', 440, 690)
  // doc.text('品    管：＿＿＿＿＿＿＿', 295, 710)
  // doc.text('技    師：＿＿＿＿＿＿＿＿＿', 440, 710)
  // doc.text('顧客須知：＿＿＿＿＿＿', 440, 830)


  // doc.setFontSize(7);
  // doc.setFont('BiauKai');
  // doc.text('1.本人授權貴公司對本單所開車輛做以上各種之檢修與更換必須知材料及零件，並准許貴公司所屬之員工為執行本單內容得行使該車至街市、公路及其他地方進行檢測與調整，若因此', 20, 740)
  // doc.text(' 造成意外事故發生時，所生之損壞由貴公司負責修復，車主及實際使用人不得有其他請求。', 20, 750)
  // doc.text('2.自貴公司通知領車三日後若不取車，貴公司得視情況加以處理，並斟酌收停車費NT$500元/月;若超過30日以上，貴公司不負保管責任。', 20, 760)
  // doc.text('3.車內貴重物品本人負責取出自行保管，若必須委由貴公司代為保管時，應事先通知過公司接待人員並註明本單中，否則若有遺失、毀損之情事發生，貴公司概不負責。', 20, 770)
  // doc.text('4.本車若為保險或保險公司不予理賠時，維修費用由本人自行負責。', 20, 780)
  // doc.text('5.本人同意貴公司得將本人之個人資料經電腦處理後，為貴公司內部或供集團授權之特約經銷及服務體系及所屬集團暨其所指定之人等為提升客戶服務品質、', 20, 790)
  // doc.text(' 提供維修保固等相關服務、車輛資訊之提供及推廣客戶關懷活動而使用。', 20, 800)
  // doc.text('6.因本單中所產生之任何爭議，雙方同意以台灣台北地方法院為第一審管轄法院。', 20, 810)

}

//
//
export const repairList4 = (doc, arrObj) => {
  doc.setFontSize(20);
  doc.setFont('BiauKai');
  doc.text('簽呈單', a4pageWidthHalf, 50, null, null, "center");

  doc.setFontSize(12);
  doc.text('填寫日期：民國  /     /', 400, 100)
  doc.line(20, 110, 560, 110)
  doc.text('提簽人', 27, 125)
  doc.text('所屬部門', 307, 125)
  doc.line(300, 110, 300, 154)
  doc.line(20, 132, 560, 132)
  doc.text('機密等級', 27, 147)
  doc.text('時限', 307, 147)

  doc.rect(124, 138, 10, 10)
  doc.text('一般', 140, 147)
  doc.rect(174, 138, 10, 10)
  doc.text('機密', 190, 147)
  doc.rect(224, 138, 10, 10)
  doc.text('極機密', 240, 147)
  doc.rect(404, 138, 10, 10)
  doc.text('普通', 420, 147)
  doc.rect(450, 138, 10, 10)
  doc.text('速件', 470, 147)
  doc.rect(500, 138, 10, 10)
  doc.text('最速件', 516, 147)


  doc.line(400, 110, 400, 154)
  doc.line(20, 154, 560, 154)
  doc.text('主旨', 27, 173)
  doc.line(20, 185, 560, 185)
  doc.text('說明', 27, 240)
  doc.line(20, 290, 560, 290)
  doc.text('部門主管批示', 27, 317)
  doc.line(20, 339, 560, 339)
  doc.text('總經理批示', 27, 366)
  doc.line(20, 388, 560, 388)
  doc.text('會辦單位', 27, 420)
  doc.line(20, 447, 560, 447)
  doc.text('會辦單位批示', 27, 479)
  doc.line(20, 506, 560, 506)

  doc.line(20, 110, 20, 506)
  doc.line(560, 110, 560, 506)

  doc.line(120, 110, 120, 506)

  doc.line(120, 408, 560, 408)
  doc.line(120, 428, 560, 428)
  doc.line(120, 467, 560, 467)
  doc.line(120, 487, 560, 487)

  doc.text('1.', 125, 402)
  doc.text('2.', 125, 422)
  doc.text('3.', 125, 441)
  doc.text('1.', 125, 461)
  doc.text('2.', 125, 481)
  doc.text('3.', 125, 500)

  doc.text('[注意事項]', 27, 520)
  doc.text('1. 時限：普通三天內完成，速件兩天內完成，最速件當天完成。', 27, 536)

}

export const repairList5 = (doc, arrObj) => {
  doc.setFontSize(20);
  doc.setFont('BiauKai');
  doc.text('會議記錄', a4pageWidthHalf, 50, null, null, "center");

  doc.setFontSize(12);
  doc.text('填寫日期：民國  /     /', 400, 100)

  doc.line(20, 110, 560, 110)
  doc.text('主題', 27, 125)
  doc.line(20, 132, 560, 132)
  doc.text('時間', 27, 147)
  doc.line(20, 154, 560, 154)
  doc.text('地點', 27, 169)
  doc.line(20, 176, 560, 176)
  doc.text('主席', 27, 191)
  doc.line(20, 198, 560, 198)
  doc.text('紀錄', 27, 213)
  doc.line(20, 220, 560, 220)
  doc.text('出席人員', 27, 250)
  doc.line(20, 265, 560, 265)
  doc.text('會議內容', a4pageWidthHalf, 280, null, null, "center");
  doc.line(20, 287, 560, 287)
  doc.text('議程', 27, 317)
  doc.line(20, 332, 560, 332)
  doc.text('結論事項', 27, 362)
  doc.line(20, 377, 560, 377)
  doc.text('追蹤事項', 27, 407)
  doc.line(20, 422, 560, 422)
  doc.text('備註', 27, 452)
  doc.line(20, 467, 560, 467)

  doc.line(20, 110, 20, 467)
  doc.line(560, 110, 560, 467)
  doc.line(120, 110, 120, 265)
  doc.line(120, 287, 120, 467)

  doc.text('[注意事項]', 27, 495)

  doc.text('總經理：', 20, 700)
  doc.text('主管：', 240, 700)
  doc.text('記錄人：', 400, 700)


}




export const repairList6 = (doc, arrObj) => {
  doc.setFontSize(20);
  doc.setFont('BiauKai');
  doc.text('開會通知單', a4pageWidthHalf, 50, null, null, "center");

  doc.setFontSize(12);
  doc.text('受文者：', 27, 125)

  doc.setFontSize(10);
  doc.text('發文日期：', 27, 145)
  doc.text('發文字號：', 27, 155)
  doc.text('速別：', 27, 165)
  doc.text('密等及解密條件：', 27, 175)
  doc.text('附件：議程', 27, 185)

  doc.setFontSize(12);
  doc.text('開會事由：', 27, 205)






}