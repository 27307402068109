import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ModalPdf from 'widget/pdf/ModalPdf';

const columns = () => ([
  { title: '交修單號', field: 'deliveryOrderNum' },
  { title: '交修日期', field: 'deliveryDate' },
  { title: '交修類別', field: 'deliveryType' },
  { title: '牌照號碼', field: 'licenseNum' },
  { title: '車主名稱', field: 'ownersName' },
  { title: '接待人員', field: 'receptionist' },
  { title: '零件金額', field: 'partsAmount' },
  { title: '工資金額', field: 'salaryAmount' },
  { title: '交修金額', field: 'amountPaid' },
  { title: '預定交車日期', field: 'scheduledDeliveryDate' },
  { title: '預定交車時間', field: 'scheduledDeliveryTime' },
  { title: '交修摘要', field: 'deliverySummary' },
]);
const columns2 = () => ([
  { title: '', field: 'serialNum' },
  { title: '', field: 'deliveryCategory' },
  { title: '', field: 'partNum' },
  { title: '', field: 'partName' },
  { title: '', field: 'partLevel' },
  { title: '', field: 'unitOfMeasurement' },
  { title: '', field: 'inventoryStorage' },
  { title: '', field: 'actualUnitPrice' },
  { title: '', field: 'quantity' },
  { title: '', field: 'amount' },
  { title: '', field: 'maintenanceTechnician' },
  { title: '', field: 'handoverBonus' },
  { title: '', field: 'handoverAnalysis' },
  { title: '', field: 'whetherToPrint' },
  { title: '', field: 'whetherToPrintAccount' },
]);

export default class Hr1 extends Component {
  state = {
    dataArr: [],
    filenames: [],
    downloadURLs: [],
    docs: [],
    fileArr: [],
    fileArrCheck: [],
    show: false,
    // loading: true,
    obj: {}
  }

  // componentDidMount() {
  //   this.handleFetch();
  // }

  // componentWillReceiveProps(nextProps) {
  //   this.handleFetch(nextProps);
  // }

  // handleFetch = async (props = this.props) => {
  //   const { companyObj } = props;

  //   const companyId = companyObj.objectId;
  //   const data = await Parse.queryData('hrform_management', { companyId });
  //   this.setState({ dataArr: data, loading: false });
  // }

  // handleUpdate = async () => {
  //   const { newData } = this.state
  //   await Parse.saveData('hrform_management', newData);
  // }

  render() {
    const { companyObj } = this.props
    const { dataArr, loading } = this.state;

    return (<div className='content'>
      {/* <Container> */}
      {/* <div style={{ ...style.flexCenter, position: 'relative', zIndex: 100 }}>
          <Header as='h2' style={{ margin: '2rem 0' }}>4-1 人事規章</Header>
        </div> */}
      <ModalPdf {...this.props} title='簽呈' nowform={'4'} params={{ companyObj }} />
      <ModalPdf {...this.props} title='會議記錄' nowform={'5'} params={{ companyObj }} />
      <ModalPdf {...this.props} title='開會通知單' nowform={'6'} params={{ companyObj }} />
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          pageSize: 5,
          search: true,
          searchFieldStyle: {
            width: 90,
          }
        }}
        data={dataArr}
        title={'基本資料'}

      />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns2()}
        options={{
          pageSize: 5,
          search: true,
          searchFieldStyle: {
            width: 90,
          }
        }}
        data={dataArr}
        title={'交修零件'}
      />

      <br />
      <br />
    </div>)
  }
}