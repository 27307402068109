
import React, { Component } from 'react'
import { withCookies } from 'react-cookie';
import { Link } from "react-router-dom";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";

const hostname = window.location.hostname;

class DropdownLang2 extends Component {
  componentDidMount() {
    const { cookies } = this.props;
    const docLang = cookies.get('docLang')
    if (docLang) {
      document.documentElement.lang = docLang;
    } else {
      if (hostname === 'aichi.infowin.com.tw') {
        document.documentElement.lang = 'jp';
        cookies.set('docLang', 'jp', { path: '/' });
      }
    }
  }

  changeLang = (lang, docLang = 'en', dbLang) => {
    const { cookies } = this.props;
    cookies.set('lang', lang, { path: '/' });
    cookies.set('docLang', docLang, { path: '/' });
    cookies.set('dbLang', dbLang, { path: '/' });
    document.documentElement.lang = docLang;
    window.location.reload(true);
  }

  render() {
    // const { cookies } = this.props;
    // console.log(cookies.get('lang'))
    return (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle color="default" caret nav>
          <i className="fa fa-globe" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-danger" right>
          <DropdownItem onClick={() => this.changeLang('ja-JP', 'ja', 'ja')} to='#' tag={Link}>
            {/* <i className="nc-icon nc-bank" /> */}
            🇯🇵 日本語
          </DropdownItem>
          <DropdownItem onClick={() => this.changeLang('zh-CN', 'zh-Hans', 'zhHant')} to='#' tag={Link}>
            {/* <i className="nc-icon nc-bank" /> */}
            🇨🇳 简体中文
          </DropdownItem>
          <DropdownItem onClick={() => this.changeLang('zh-TW', 'zh-Hant', 'name')} to='#' tag={Link}>
            {/* <i className="nc-icon nc-bank" /> */}
            🇹🇼 繁體中文
          </DropdownItem>
          <DropdownItem onClick={() => this.changeLang('en-US', 'en', 'en')} to='#' tag={Link}>
            {/* <i> <span className="flag-icon flag-icon-gr"></span></i> */}
            🇺🇲 English
          </DropdownItem>
          <DropdownItem onClick={() => this.changeLang('vi-VN', 'vi', 'vi')} to='#' tag={Link}>
            {/* <i> <span className="flag-icon flag-icon-gr"></span></i> */}
            🇻🇳 Vietnam
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}


// export default DropdownLang;
export default withCookies(DropdownLang2);
