import React, { Component } from 'react'
import { Dropdown, Header, Segment, Tab, Select } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ModalMultiInput from 'views/web/ModalMultiInput';
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

// import RowsDnd from './RowsDnd'

// reactstrap components
import { Row, Col, Form, FormGroup, Input, Label, Button } from "reactstrap";
import DasinUserTab from './DasinUserTab';
// import CurStudentInfo from "./CurStudentInfo";

// const columns = () => ([
//   { width: 80, title: '使用者名稱', field: 'userName' },
//   { width: 80, title: '帳號', field: 'account' },
//   { width: 80, title: '密碼', field: 'password' },
//   { width: 80, title: '手機', field: 'cellphone' },
//   { width: 80, title: '地址', field: 'address' },
//   { width: 80, title: 'Email', field: 'email' },
//   { width: 80, title: '備註', field: 'ps' },
// ]);

class UserAccount extends Component {
  state = {
    activeItem: 'bio',
    dataArr: [],
    rowData: {},
    loading: true,
    score: false,
  }

  async componentDidMount() {
    const { companyObj } = this.props
    const testBank = await Parse.queryData('testBank');
    const verArr = []
    testBank.forEach(item => {
      verArr.push({ key: item.objectId, value: item.objectId, text: item.version })
    })
    this.setState({ verArr });
    this.handleFetch()
  }

  handleFetch = async () => {
    const { rowData } = this.state;
    const { companyObj } = this.props;
    const clients = await Parse.queryData('clients', { companyId: companyObj.objectId, schoolId: undefined });
    // const dataArr=clients.filter(item=>item.schoolId===undefined)
    // const schoolClass=clients.filter(item=>item.schoolId===rowData)

    this.setState({ loading: false, dataArr: clients, clients, show: false })
  }


  handleAdd = async () => {
    const { newData } = this.state;
    await Parse.saveData('clients', newData);
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    await Parse.saveData('clients', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    await Parse.deleteData('clients', oldData);
    this.setState({ oldData: {} })
  }

  handleData = async (rowData) => {
    const { companyObj } = this.props
    const schoolClass = await Parse.queryData('clients', { companyId: companyObj.objectId, schoolId: rowData.objectId });
    const users = await Parse.queryData('User', { authLoginA: companyObj.objectId, schoolId: rowData.objectId })
    const userArr = []
    users.forEach(item => {
      userArr.push({ key: item.objectId, value: item.objectId, text: item.name })
    })
    this.setState({ schoolClass, show: true, rowData, userArr, loadingData: false, score: false });
  }

  handleScore = async (scoreData) => {
    this.setState({ score: true, scoreData });
  }

  render() {

    const { companyObj } = this.props
    const { dataArr, rowData, loading, verArr, show, schoolClass, userArr, loadingData, score, scoreData } = this.state
    return (<div className="content">
      <Row>
        <Col><legend>3-3 學校管理</legend></Col>
        {/* <Dropdown placeholder='Skills' fluid multiple selection options={options} onChange={(e, data) => console.log(e, data)} style={{ zIndex: 99 }} /> */}
      </Row>
      <br />
      {/* {loading ? <DimmerImg /> :  */}
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={[
          { width: 80, title: '中文名稱', field: 'name_cht' },
          { width: 80, title: '英文名稱', field: 'name_eng' },
          {
            width: 200, title: '題庫', field: 'testExBankID',
            render: rowData => <>
              <Dropdown placeholder='題庫' multiple selection options={verArr} defaultValue={rowData.testExBankID} style={{ zIndex: 99 }} disabled />
            </>,
            editComponent: props => <>
              <Dropdown placeholder='題庫' multiple selection options={verArr} onChange={(e, data) => props.onRowDataChange({ ...props.rowData, testExBankID: data.value })} style={{ zIndex: 99 }} defaultValue={props.rowData.testExBankID} />
            </>,
          },
        ]}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '學校設定'),
          pageSize: 10,
          search: false,
          tableLayout: 'fixed'
        }}
        data={dataArr}
        title="學校設定"
        actions={[
          {
            icon: 'view_headline',
            tooltip: '查看',
            onClick: (event, rowData) => this.setState({ loadingData: true }, () => this.handleData(rowData))
          }
        ]}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const data = [...dataArr];
              const db = Parse.Object.extend("clients");
              const newDoc = new db();
              const doc = await newDoc.save();
              // newData.objectId = doc.id;
              // newData.id = doc.id;
              // newData._id = doc.id;
              let obj = {
                ...newData,
                objectId: doc.id,
                id: doc.id,
                _id: doc.id,
                companyId: companyObj.objectId,
                companyName: companyObj.name,
              }
              data.push(obj);
              this.setState({ dataArr: data, newData: obj }, () => resolve());
            }).then(() => this.handleAdd()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...dataArr];
              const index = data.indexOf(oldData);
              data[index] = newData;
              // handleState('people0', data);
              this.setState({ dataArr: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...dataArr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              // handleState('people0', data);
              this.setState({ dataArr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
      <br />
      <br />
      {/* <Row>
          {show ?
            <Col md='6'>
              <MaterialTable
                isLoading={loadingData}
                localization={localization()}
                // columns={columns()}
                columns={[
                  // { width: 120, title: '系所', field: 'department' },
                  // { width: 100, title: '學年度', field: 'schoolYear' },
                  // { width: 100, title: '課程', field: 'course' },
                  // { width: 100, title: '班級', field: 'class' },
                  // {
                  //   width: 150, title: '權限', field: 'teacherAuth',
                  //   render: rowData => <>
                  //     <Dropdown placeholder='權限' multiple selection options={userArr} defaultValue={rowData.teacher} style={{ zIndex: 99 }} disabled />
                  //   </>,
                  //   editComponent: props => <>
                  //     <Dropdown placeholder='權限' multiple selection options={userArr} onChange={(e, data) => props.onRowDataChange({ ...props.rowData, teacher: data.value })} style={{ zIndex: 99 }} defaultValue={props.rowData.teacher} />
                  //   </>,
                  // },
                  { width: 80, title: '姓名', field: 'name' },
                ]}
                options={{
                  addRowPosition: "first",
exportButton: true,
                  pageSize: 10,
                  search: false,
                  tableLayout: 'fixed'
                }}
                components={{
                  Toolbar: props => (<div>
                    <div style={{ float: 'right', textAlign: 'right', padding: '22px 16px 22px 4px' }}>
                      <ModalMultiInput {...this.props} handleData={this.handleFetch} columns={props.columns} condition={{ companyId: companyObj.objectId, companyName: companyObj.name, schoolId: rowData.objectId, schoolName: rowData.name_cht }} dbName='clients' />
                    </div>
                    <MTableToolbar {...props} />
                    <div style={{ clear: 'both' }}></div>
                  </div>),
                }}
                data={schoolClass}
                title={`${rowData.name_cht} 使用者管理`}
                actions={[
                  {
                    icon: 'view_headline',
                    tooltip: '查看',
                    onClick: (event, rowData) => this.handleData(rowData)
                  }
                ]}
                editable={{
                  onRowAdd: newData =>
                    new Promise(async (resolve, reject) => {
                      const data = [...schoolClass];
                      const db = Parse.Object.extend("clients");
                      const newDoc = new db();
                      const doc = await newDoc.save();
                      // newData.objectId = doc.id;
                      // newData.id = doc.id;
                      // newData._id = doc.id;
                      let obj = {
                        ...newData,
                        objectId: doc.id,
                        id: doc.id,
                        _id: doc.id,
                        companyId: companyObj.objectId,
                        companyName: companyObj.name,
                        schoolId: rowData.objectId,
                        schoolName: rowData.name_cht,
                      }
                      data.push(obj);
                      this.setState({ schoolClass: data, newData: obj }, () => resolve());
                    }).then(() => this.handleAdd()),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      const data = [...schoolClass];
                      const index = data.indexOf(oldData);
                      data[index] = newData;
                      // handleState('people0', data);
                      this.setState({ schoolClass: data, newData, oldData }, () => resolve());
                    }).then(() => this.handleUpdate()),
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      let data = [...schoolClass];
                      const index = data.indexOf(oldData);
                      data.splice(index, 1);
                      // handleState('people0', data);
                      this.setState({ schoolClass: data, oldData }, () => resolve());
                    }).then(() => this.handleDelete()),
                }}
              />
            </Col> : null}
          <Col md='6'>
            {show ? <MaterialTable
              isLoading={loadingData}
              localization={localization()}
              // columns={columns()}
              columns={[
                { width: 120, title: '系所', field: 'department' },
                { width: 100, title: '學年度', field: 'schoolYear' },
                { width: 100, title: '課程', field: 'course' },
                { width: 100, title: '班級', field: 'class' },
                {
                  width: 150, title: '權限', field: 'teacherAuth',
                  render: rowData => <>
                    <Dropdown placeholder='權限' multiple selection options={userArr} defaultValue={rowData.teacher} style={{ zIndex: 99 }} disabled />
                  </>,
                  editComponent: props => <>
                    <Dropdown placeholder='權限' multiple selection options={userArr} onChange={(e, data) => props.onRowDataChange({ ...props.rowData, teacher: data.value })} style={{ zIndex: 99 }} defaultValue={props.rowData.teacher} />
                  </>,
                },
              ]}
              options={{
                addRowPosition: "first",
exportButton: true,
                pageSize: 10,
                search: false,
                tableLayout: 'fixed'
              }}
              components={{
                Toolbar: props => (<div>
                  <div style={{ float: 'right', textAlign: 'right', padding: '22px 16px 22px 4px' }}>
                    <ModalMultiInput {...this.props} handleData={this.handleFetch} columns={props.columns} condition={{ companyId: companyObj.objectId, companyName: companyObj.name, schoolId: rowData.objectId, schoolName: rowData.name_cht }} dbName='clients' />
                  </div>
                  <MTableToolbar {...props} />
                  <div style={{ clear: 'both' }}></div>
                </div>),
              }}
              data={schoolClass}
              title={`測驗統計`}
              actions={[
                {
                  icon: 'view_headline',
                  tooltip: '查看',
                  onClick: (event, rowData) => this.handleScore(scoreData)
                }
              ]}
            // editable={{
            //   onRowAdd: newData =>
            //     new Promise(async (resolve, reject) => {
            //       const data = [...schoolClass];
            //       const db = Parse.Object.extend("clients");
            //       const newDoc = new db();
            //       const doc = await newDoc.save();
            //       // newData.objectId = doc.id;
            //       // newData.id = doc.id;
            //       // newData._id = doc.id;
            //       let obj = {
            //         ...newData,
            //         objectId: doc.id,
            //         id: doc.id,
            //         _id: doc.id,
            //         companyId: companyObj.objectId,
            //         companyName: companyObj.name,
            //         schoolId: rowData.objectId,
            //         schoolName: rowData.name_cht,
            //       }
            //       data.push(obj);
            //       this.setState({ schoolClass: data, newData: obj }, () => resolve());
            //     }).then(() => this.handleAdd()),
            //   onRowUpdate: (newData, oldData) =>
            //     new Promise((resolve, reject) => {
            //       const data = [...schoolClass];
            //       const index = data.indexOf(oldData);
            //       data[index] = newData;
            //       // handleState('people0', data);
            //       this.setState({ schoolClass: data, newData, oldData }, () => resolve());
            //     }).then(() => this.handleUpdate()),
            //   onRowDelete: oldData =>
            //     new Promise((resolve, reject) => {
            //       let data = [...schoolClass];
            //       const index = data.indexOf(oldData);
            //       data.splice(index, 1);
            //       // handleState('people0', data);
            //       this.setState({ schoolClass: data, oldData }, () => resolve());
            //     }).then(() => this.handleDelete()),
            // }}
            /> : null}
          </Col>
        </Row> */}
      {rowData && rowData.objectId ? <DasinUserTab {...this.props} rowData={rowData} /> : null}
      <br />
      <br />
    </div>
    );
  }
}

export default UserAccount;