import React, { Component } from "react";
import { Segment } from 'semantic-ui-react'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col
} from "reactstrap";
import SectionSchool from "./SectionSchool";

// core components

export class SectionFeature1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  handleData = (num) => {
    this.setState({ show: true, num })
  }

  render() {
    const { cookies, form, webimageArr, companyObj } = this.props;
    const companyId = companyObj.objectId
    const { text2 = '', text3 = '' } = form;
    const { show, num } = this.state
    const dbLang = cookies.get('dbLang') || 'name';
    let arr = []
    arr = webimageArr.filter(item => item.navId === form.objectId)
    if (!arr.length) {
      arr = [{ icon: "nc-app", title1name: "標題", content1name: '內文' },
      { icon: "nc-app", title1name: "標題", content1name: '內文' },
      { icon: "nc-app", title1name: "標題", content1name: '內文' }]
    }
    return (
      <>
        <div className="section section-feature cd-section" style={{ margin: '200px 0px' }} id="features">
          {/* ********* FEATURES 1 ********* */}
          <div className="features-1">
            <Container>
              <Row >
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">{text2}</h2>
                  <h5 className="description">
                    {text3}
                  </h5>
                </Col>
              </Row>
              <br />
              <br />
              <br />
              <br />
              <Row>
                {arr.map((item, index) =>
                  <Col md="4">
                    <div className="info">
                      <div className="card-icon" style={{
                        backgroundImage:
                          `url(${(item.file1 && item.file1.url) || require("assets/img/bg/rawpixel-comm.jpg")})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        height: '200px',
                        width: '200px',
                        margin: '0px auto',
                        borderRadius: '5%'
                      }}>
                      </div>
                      <div className="description">
                        <h4 className="info-title" style={{ color: '#866155', fontWeight: 400 }}>{item['title1' + dbLang] || ''}</h4>
                        <p className="description">
                          {item['content1' + dbLang] || ''}
                        </p>
                        <Button
                          className="btn-link"
                          style={{ color: '#866155', fontWeight: 400 }}
                          // color="danger"

                          onClick={() => this.handleData(index + 1)}
                        >
                          See more
                  </Button>
                      </div>
                    </div>
                  </Col>
                )}
                {/* 
              <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-bulb-63" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">{text6}</h4>
                    <p>
                      {text7}
                    </p>
                    <Button
                      className="btn-link"
                      color="danger"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      See more
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-chart-bar-32" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">{text8}</h4>
                    <p>
                      {text9}
                    </p>
                    <Button
                      className="btn-link"
                      color="danger"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      See more
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-sun-fog-29" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">{text10}</h4>
                    <p>
                      {text11}
                    </p>
                    <Button
                      className="btn-link"
                      color="danger"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      See more
                    </Button>
                  </div>
                </div>
              </Col> */}
              </Row>
              <br />
              <br />
              <br />
              <br />
              {companyId === 'Wuwrcczn0Z' && show ? <SectionSchool {...this.props} num={num} /> : null}
            </Container>
          </div>
          {/* ********* END FEATURES 1 ********* */}
        </div>
      </>
    );
  }
}

export default SectionFeature1;
