import React, { Component } from 'react';
import { Icon, Input, Label, Form, Dimmer, Loader, Image, Segment, Table } from 'semantic-ui-react'
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';

import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import { getOptionsSelByKey, getOptionTextByValue } from 'views/widgets/FieldsRender';
import Parse from '../../../widget/parse'

const style = {
  tableShow: {
    display: 'table',
  },
  tableHide: {
    display: 'none',
  }
}

export default class ClassContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrRow: [],
      loading: true,
      classSchool: 'A',
      bikeData: [],
      parttimeData: [],
      students: []
    };
  }

  componentDidMount = async () => {
    // console.log('componentDidMount');
    const { companyObj } = this.props
    const data = await Parse.queryData('Students', { companyId: companyObj.objectId });
    this.setState({ students: data }, () => this.handleFetch());

  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, match } = props
    const { classSchool, students } = this.state

    const tmp = students.filter(item =>
      (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
      (item.applyResult === '1' || item.studentType === "2"));
    const arrRow = tmp.filter(item => item.classSchool === classSchool); // 找到這班的所有人
    this.setState({ arrRow, loading: false });

    // const db = firebase.firestore();
    const promises = [];
    const promises2 = [];
    const promises3 = [];
    arrRow.forEach(doc => {
      const promise = Parse.queryData('Bicycle', { companyId: companyObj.objectId, studentId: doc._id, condition: '1' })
        .then(snap2 => {
          const data = [];
          snap2.forEach(bike => data.push(bike))
          return data;
        });

      //   var promise = db.collection(`Students/${doc._id}/Bicycle`)
      //     .where('condition', '==', '1')
      //     .get().then(snap2 => {
      //       const data = [];
      //       snap2.forEach(bike => data.push({ _id: bike.id, ...bike.data() }))
      //       return data;
      // })
      promises.push(promise);
      const promise2 = Parse.queryData('Parttime', { companyId: companyObj.objectId, studentId: doc._id, condition: '1' })
        .then(snap2 => {
          const data = [];
          snap2.forEach(parttime => data.push(parttime))
          return data;
        });
      //   var promise2 = db.collection(`Students/${doc._id}/Parttime`)
      //     .get().then(snap2 => {
      //       const data = [];
      //       snap2.forEach(parttime => data.push({ _id: parttime.id, ...parttime.data() }))
      //       return data;
      //     })
      promises2.push(promise2);
      const promise3 = Parse.queryData('Graduate', { companyId: companyObj.objectId, studentId: doc._id, isEnter: '1' })
        .then(snap2 => {
          const data = [];
          snap2.forEach(grduation => data.push(grduation))
          return data;
        });
      //   var promise3 = db.collection(`Students/${doc._id}/Graduate`)
      //     .where('isEnter', '==', '1')
      //     .get().then(snap2 => {
      //       const data = [];
      //       snap2.forEach(grduation => data.push({ _id: grduation.id, ...grduation.data() }))
      //       return data;
      //     })
      promises3.push(promise3);
    });

    Promise.all(promises).then(results => {
      // console.log(results);
      const bikeData = results.filter(item => item.length);
      this.setState({ bikeData, loading: true })
    }).catch(error => {
      console.log(error);
    });

    Promise.all(promises2).then(results => {
      // console.log(results);
      const parttimeData = results.filter(item => item.length);
      this.setState({ parttimeData, loading: true })
    }).catch(error => {
      console.log(error);
    });

    Promise.all(promises3).then(results => {
      // console.log(results);
      const graduationData = results.filter(item => item.length);
      this.setState({ graduationData, loading: true }, () => this.getPrintData())
    }).catch(error => {
      console.log(error);
    });
  }

  handleChange = (event, { value }) => this.setState({ classSchool: value }, () => this.handleFetch())

  getPrintData = () => {
    const { doc } = this.props;
    const { arrRow, bikeData, parttimeData, graduationData } = this.state;
    let obj = {};
    let params = [];
    arrRow.map((item, index) => {//目前只抓班級中的學生
      const parttime = parttimeData.find(item2 => item2[0].studentId === item._id) || {};
      const permitDateArr = parttime.length && parttime[0].permitDate ? parttime[0].permitDate.split('-') : ['', '', ''];
      const graduaion = graduationData && graduationData.find(item2 => item2[0].studentId === item._id) || [];
      const bike = bikeData.find(item2 => item2[0].studentId === item.studentId) || {};
      obj.storeName = !parttime.length ? '' : parttime[0].storeName
      obj.storeAddress = !parttime.length ? '' : parttime[0].storeAddress
      obj.storetel = !parttime.length ? '' : parttime[0].storetel
      obj.workday = !parttime.length ? '' : parttime[0].workday
      obj.worktime = !parttime.length ? '' : parttime[0].worktime
      obj.draft = !parttime.length ? '' : parttime[0].draft
      obj.permitDateYear = !parttime.length ? '' : permitDateArr[0]
      obj.permitDateMonth = !parttime.length ? '' : permitDateArr[1]
      obj.permitDateDay = !parttime.length ? '' : permitDateArr[2]
      obj.bike = !bike.length ? '' : bike.map((bike) => `${bike.number}/${bike.colorText}`)
      obj.targetSchoolText = !graduaion.length ? '' : graduaion[0].targetSchoolText
      let doc = { ...item, ...obj }
      params.push(doc)
      obj = {}
    })
    this.setState({ params, loading: false })
  }

  render() {
    const { history, match, optSet, companyObj } = this.props;
    const { arrRow, loading, classSchool, bikeData, parttimeData, graduationData, params } = this.state
    const classSchoolOpt = getOptionsSelByKey(optSet, "classSchool");
    // console.log(parttimeData)
    return (<div ref={el => (this.componentRef = el)} className="">
      <h2 style={{ margin: 0, float: 'left' }}>{classSchool}クラス連絡簿</h2>
      <div style={{ float: 'right' }}>
        {/* <ReactToPrint
          trigger={(e) => <Button
            content='印刷'
            // label='&nbsp;'
            color='orange' icon='print'
            size='small'
            floated='right'
            className='no-print'
          />}
          content={() => this.componentRef}
          pageStyle={"@page { size: auto;  margin:5mm; }*{font-family: 'Noto Serif JP', serif;}"}
        /> */}
        <Label circular color={'yellow'} empty key={'1'} />大學院&nbsp;
        <Label circular color={'green'} empty key={'2'} />大學&nbsp;
        <Label circular color={'blue'} empty key={'3'} />専門学校&nbsp;
        <Label circular color={'orange'} empty key={'4'} />就職&nbsp;
        <Label circular color={'grey'} empty key={'6'} />研究生&nbsp;○その他


        <ModalPdf
          {...this.props}
          // ref={(ref) => { this.pdfModal = ref }}
          codekey="table18"
          params={params}
        />
        <ExportTableToExcelButton floated='right' size='small' />
        <Input labelPosition='left' style={{ marginRight: 20 }}>
          <Label color='violet'><Icon name='building' />クラス</Label>
          <Form.Select
            style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
            name='classroom'
            value={classSchool}
            options={classSchoolOpt}
            onChange={this.handleChange} />
        </Input>
      </div>
      <div style={{ clear: 'both' }}></div>
      <Table celled striped compact
        className="thPadding2px tdPadding2px" id='excel'>
        <Table.Header >
          <Table.Row textAlign='center'>
            <Table.HeaderCell rowSpan='4'>学籍番号</Table.HeaderCell>
            <Table.HeaderCell rowSpan='' colSpan='2'>氏名</Table.HeaderCell>
            <Table.HeaderCell rowSpan='4'>電話番号</Table.HeaderCell>
            <Table.HeaderCell rowSpan='4'>携帯電話番号</Table.HeaderCell>
            <Table.HeaderCell rowSpan='4'>連絡先</Table.HeaderCell>
            <Table.HeaderCell colSpan='3' >資格外活動</Table.HeaderCell>
            <Table.HeaderCell rowSpan='4'>進路予定</Table.HeaderCell>
            <Table.HeaderCell rowSpan='4'>喫煙</Table.HeaderCell>
            <Table.HeaderCell rowSpan='4'>自転車</Table.HeaderCell>
          </Table.Row>
          <Table.Row textAlign='center'>
            <Table.HeaderCell colSpan='2' style={{ borderLeft: '1px solid rgba(34,36,38,.1)' }}>学生</Table.HeaderCell>
            <Table.HeaderCell >店名</Table.HeaderCell>
            <Table.HeaderCell>電話</Table.HeaderCell>
            <Table.HeaderCell rowSpan='3'>期限</Table.HeaderCell>
          </Table.Row>
          <Table.Row textAlign='center'>
            <Table.HeaderCell colSpan='2' style={{ borderLeft: '1px solid rgba(34,36,38,.1)' }}>推薦者</Table.HeaderCell>
            <Table.HeaderCell >曜日</Table.HeaderCell>
            <Table.HeaderCell>時間</Table.HeaderCell>
          </Table.Row>
          <Table.Row textAlign='center'>
            <Table.HeaderCell colSpan='2' style={{ borderLeft: '1px solid rgba(34,36,38,.1)' }}>本国</Table.HeaderCell>
            <Table.HeaderCell >場所</Table.HeaderCell>
            <Table.HeaderCell>案種</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {arrRow.length ? arrRow.map((item, index) => {
            const bike = bikeData.find(item2 => item2[0].studentId === item.studentId) || [];
            const parttime = parttimeData.find(item2 => item2[0].studentId === item._id) || [];
            const graduaion = graduationData && graduationData.find(item2 => item2[0].studentId === item._id) || [];
            const permitDateArr = parttime.length && parttime[0].permitDate ? parttime[0].permitDate.split('-') : ['', '', ''];
            // console.log(parttime, permitDateArr);
            return <React.Fragment key={index}>
              <Table.Row textAlign='center'>
                <Table.Cell style={{
                  borderRight: '1px solid rgba(34,36,38,.1)'
                }} rowSpan='3' > {item.studentId}</Table.Cell>
                < Table.Cell style={{ borderLeft: '0px solid #fff' }} colSpan='2'>{item.jpnName}</Table.Cell>
                <Table.Cell>{item.telephone || item.homeTel || ''}</Table.Cell>
                <Table.Cell>{item.cellphone || item.jpnCellphone || ''}</Table.Cell>
                <Table.Cell>{item.jpnAddr || ''}</Table.Cell>
                <Table.Cell>{!parttime.length ? '' : parttime[0].storeName}</Table.Cell>
                <Table.Cell>{!parttime.length ? '' : parttime[0].storetel}</Table.Cell>
                <Table.Cell>{permitDateArr[0]}</Table.Cell>
                {/* <Table.Cell rowSpan='3'>{item.plannedWayOut && item.plannedWayOut.map(way => getOptionTextByValue(optSet, "plannedWayOut", way))} */}
                <Table.Cell rowSpan='3'>{item.plannedWayOut1 &&
                  item.plannedWayOut1 === '1' ? <Label circular color={'yellow'} empty key={'1'} /> :
                  item.plannedWayOut1 === '2' ? <Label circular color={'green'} empty key={'2'} /> :
                    item.plannedWayOut1 === '3' ? <Label circular color={'blue'} empty key={'3'} /> :
                      item.plannedWayOut1 === '4' ? <Label circular color={'orange'} empty key={'4'} /> :
                        item.plannedWayOut1 === '6' ? <Label circular color={'grey'} empty key={'6'} /> :
                          null}
                  {item.plannedWayOut2 &&
                    item.plannedWayOut2 === '1' ? <Label circular color={'yellow'} empty key={'11'} /> :
                    item.plannedWayOut2 === '2' ? <Label circular color={'green'} empty key={'22'} /> :
                      item.plannedWayOut2 === '3' ? <Label circular color={'blue'} empty key={'33'} /> :
                        item.plannedWayOut2 === '4' ? <Label circular color={'orange'} empty key={'44'} /> :
                          item.plannedWayOut2 === '6' ? <Label circular color={'grey'} empty key={'66'} /> :
                            null}
                  <br />{!graduaion.length ? '' : graduaion[0].targetSchoolText}</Table.Cell>
                <Table.Cell rowSpan='3'>{item.smokingText || ''}</Table.Cell>
                <Table.Cell rowSpan='3'>
                  {bike.map(({ number, colorText }, index2) => <div key={'22' + index + index2}>{number}<br />{colorText} </div>)}
                </Table.Cell>
              </Table.Row>
              <Table.Row textAlign='center'>
                <Table.Cell>{item.recommName || '(無)'}</Table.Cell>
                <Table.Cell>{item.recommRelationshipText || ''}</Table.Cell>
                <Table.Cell>{item.recommTelephone || ''}</Table.Cell>
                <Table.Cell>{item.recommCellphone || ''}</Table.Cell>
                <Table.Cell>{item.recommHomeAddress || ''}</Table.Cell>
                <Table.Cell>{!parttime.length ? '' : parttime[0].workday}</Table.Cell>
                <Table.Cell>{!parttime.length ? '' : parttime[0].worktime}</Table.Cell>
                <Table.Cell>{permitDateArr[1]}</Table.Cell>
              </Table.Row>
              <Table.Row textAlign='center'>
                <Table.Cell>{item.supportName || '(無)'}</Table.Cell>
                <Table.Cell>{item.supportRelationshipText || ''}</Table.Cell>
                <Table.Cell>{item.supportTel || ''}</Table.Cell>
                <Table.Cell>{item.supportCellphone || ''}</Table.Cell>
                <Table.Cell>{item.supportAddr || ''}</Table.Cell>
                <Table.Cell>{!parttime.length ? '' : parttime[0].storeAddress}</Table.Cell>
                <Table.Cell>{!parttime.length ? '' : parttime[0].draft}</Table.Cell>
                <Table.Cell>{permitDateArr[2]}</Table.Cell>
              </Table.Row>
            </React.Fragment>
          }) : <Table.Row><Table.Cell rowSpan='3' colSpan='15' textAlign='center'>(資料無し)</Table.Cell></Table.Row>}
        </Table.Body>
      </Table>
      {loading ? <div style={{ justifyContent: 'center', margin: '30px 0' }}>
        <Segment>
          <Dimmer active inverted={companyObj.inverted}>
            <Loader inverted={companyObj.inverted}>Loading</Loader>
          </Dimmer>
          <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        </Segment>
      </div> : null}
    </div>);
  }
}