import React, { Component } from 'react';
import AliceCarousel from 'react-alice-carousel';
import Carousel, { ModalGateway, Modal } from 'react-images';
import { Container } from 'semantic-ui-react'
import { Button, FormGroup, Row, Col } from "reactstrap";
import 'react-alice-carousel/lib/alice-carousel.css';
import Parse from 'widget/parse'
const imgStyle = {
  margin: 0,
  padding: 0,
  verticalAlign: 'middle',
  border: 0,
  maxWidth: '100%',
  height: 'auto'
}
export class SectionYearPlan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0,
      lightboxIsOpen: false,
      photos: [],
      album: [],
      rowData: {},
      isOpen: false
    };
  }

  componentDidMount = async () => {
    const { companyObj } = this.props
    // const companyId = companyObj.objectId
    const data = await Parse.queryData('album', { companyId: 'aKDM0xR8py', isopen: '1' }, { sort: 'date_descend' });
    this.setState({ album: data })
  }

  handlePhotos = async (rowData, albumId) => {
    // const { companyObj } = this.props
    // const companyId = companyObj.objectId
    const photos = await Parse.queryData('webimage', { companyId: 'aKDM0xR8py', albumId });
    this.setState({ photos, rowData, isOpen: true })
    console.log(rowData)
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  };

  render() {
    const { form, cookies, webimageArr } = this.props;
    const { text2 = '標題', text3 = '', img1File = {} } = form;
    // const dbLang = cookies.get('dbLang') || 'name';
    // const arr = webimageArr.filter(item => item.navId === form.objectId)
    const { album, isOpen, photos, field = 'img1File2', rowData } = this.state

    return (
      <>
        <Container style={{ margin: '200px 0px' }}>
          <br />
          <br />
          <div class="contentWrap">
            <h3 class="h3com01 mb-35">豊富なイベントで学内交流</h3>
            <p class="pb-60 text-content">
              1年を通して、さまざまなイベントが行われることも本校の魅力のひとつ。教員や周りの学生との親睦を深め、充実した学生生活を楽しんでください。
            </p>

            <h3 class="h3com03">
              <span class="text01">4月</span>
              <span class="text02">入学オリエンテーション</span>
            </h3>

            <h3 class="h3com03">
              <span class="text01">5月</span>
              <span class="text02">ゴールデンウィーク</span>
            </h3>

            <h3 class="h3com03">
              <span class="text01">6月</span>
              <span class="text02">定期試験・春見学旅行日本留学試験(EJU)</span>
            </h3>

            <h3 class="h3com03">
              <span class="text01">7月</span>
              <span class="text02">大学見学・夏期休暇   日本語能力試験(JLPT)</span>
            </h3>

            <h3 class="h3com03">
              <span class="text01">8月</span>
              <span class="text02">夏期休暇・短期研修(JLPT)</span>
            </h3>

            <h3 class="h3com03">
              <span class="text01">9月</span>
              <span class="text02">定期試験</span>
            </h3>

            <h3 class="h3com03">
              <span class="text01">10月</span>
              <span class="text02">入学オリエンテーション</span>
            </h3>

            <h3 class="h3com03">
              <span class="text01">11月</span>
              <span class="text02">秋見学旅行日本留学試験(EJU)</span>
            </h3>

            <h3 class="h3com03">
              <span class="text01">12月</span>
              <span class="text02">日本語能力試験(JLPT)</span>
            </h3>

            <h3 class="h3com03">
              <span class="text01">1月</span>
              <span class="text02">冬季休暇</span>
            </h3>

            <h3 class="h3com03">
              <span class="text01">2月</span>
              <span class="text02">期末試験・大学入試</span>
            </h3>
            <h3 class="h3com03">
              <span class="text01">3月</span>
              <span class="text02">卒業式・春期休暇</span>
            </h3>
          </div>

        </Container>
      </>
    );
  }
}
export default SectionYearPlan;