import React, { Component } from 'react'
import { Container, Input, Dimmer, Loader, Segment, Image } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import { Select, MenuItem } from '@material-ui/core';
import Moment from 'moment';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf, typeCurrency } from 'views/web/CustomMaterialTable';

let flowOpt = { '1': '流動', '0': '非流動' }

const columns = (bankaccOpt = {}, acc1Opt = {}, acc2Arr = [], acc3Arr = []) => ([
  // {
  //   title: '日期', field: 'eventDate', type: 'date',
  // },
  dateCol({ title: '日期', field: 'eventDate' },),
  // { title: '帳戶', field: 'bankaccId', lookup: bankaccOpt }, // 資料庫下拉式
  { title: '類別', field: 'acc1Id', lookup: acc1Opt }, // 資料庫下拉式
  {
    title: '性質', field: 'acc2Id',
    render: rowData => rowData.acc2Text,
    editComponent: props => {
      return <Select
        value={props.rowData.acc2Id}
        onChange={e => {
          const acc2Id = e.target.value;
          const acc2Obj = acc2Arr.find(item => item.objectId === acc2Id);
          const acc2Text = (acc2Obj && acc2Obj.value) || '';
          props.onRowDataChange({ ...props.rowData, acc2Id, acc2Text })
        }}
      >
        {acc2Arr.filter(item => item.a1_id === props.rowData.acc1Id).length ?
          acc2Arr.filter(item => item.a1_id === props.rowData.acc1Id).map((item, index) =>
            (<MenuItem key={'a' + item.objectId + index} value={item.objectId}>{item.value}</MenuItem>)) :
          <MenuItem key={'b'} value="">(無資料)</MenuItem>}
      </Select>
    }
  }, // 資料庫下拉式
  { title: '說明', field: 'detail', }, // 資料庫下拉式
  { title: '流動性質', field: 'flow', lookup: flowOpt },
  { title: '發票/收據號碼', field: 'invoiceNumber' },
  { title: '金額', field: 'price', ...typeCurrency },
  { title: '備註', field: 'ps' },
]);

export default class Fin1117a2 extends Component {
  state = {
    bankaccArr: [],
    dataArr: [],
    deleteConfirm: false,
    loading: true,
    bankaccSel: '',
    yearmonth: Moment(new Date()).format('YYYY-MM') || '',
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { yearmonth } = this.state
    const { match, companyObj } = props;
    const companyId = companyObj.objectId;
    this.setState({ loading: true });
    // const bankacc = await Parse.queryData('bankacc', { companyId });
    // let bankaccOpt = {}
    // bankacc.forEach(item => {
    //   bankaccOpt = { ...bankaccOpt, [item.objectId]: item.value };
    // })
    // const bankaccArr = bankacc.filter(item => item.value).map(item => ({
    //   key: item.objectId, text: `${item.value}`, value: item.objectId
    // }));
    // const bankaccSel = bankacc && bankacc.length ? bankacc[0].objectId : '';
    // this.setState({ bankaccArr, bankaccSel, bankaccOpt });
    Parse.queryData('cashbook', { companyId, yearmonth, balance: '1' }, { orderBy: 'eventDate_descend' }).then(cashbook => {
      // const dataArr = cashbook.filter(item => item.acc1Id === 'vtvfMREPEJ' || item.acc1Id === 'XZb3Gy9eLg' || item.acc1Id === 'b4zzTZiWmQ')
      this.setState({ loading: false, dataArr: cashbook, cashbook });
    })
    const account1 = await Parse.queryData('account1', { companyId, balance: '1' });
    let acc1Opt = {}
    account1.forEach(item => {
      // if (item.objectId === 'vtvfMREPEJ' || item.objectId === 'XZb3Gy9eLg' || item.objectId === 'b4zzTZiWmQ') {
      acc1Opt = { ...acc1Opt, [item.objectId]: item.value };
      // }
    });
    this.setState({ acc1Opt, account1 });
    const account2 = await Parse.queryData('account2', { companyId });
    const acc2Arr = account2
    this.setState({ acc2Arr, account2 });
  }

  handleAdd = async () => {
    const { companyObj } = this.props
    const { newData, yearmonth } = this.state
    const obj = {
      ...newData,
      yearmonth,
      // eventDate: new Date(newData.eventDate.iso || newData.eventDate),
    };
    await Parse.saveData('cashbook', obj).then(() => this.handleFetch());
  }

  handleUpdate = async () => {
    const { companyObj } = this.props
    const { newData } = this.state
    const obj = {
      ...newData,
      // eventDate: (newData.eventDate && new Date(newData.eventDate.iso || newData.eventDate)) || new Date()
      eventDate: newData.eventDate ? (new Date(newData.eventDate.iso || newData.eventDate)) || new Date() : null,
    };
    await Parse.saveData('cashbook', obj);
  }

  handleDelete = async () => {
    const { oldData, yearmonth } = this.state
    await Parse.deleteData('cashbook', oldData);
    this.setState({ oldData: {} })
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    this.setState({ [name]: value, loading: true }, () => this.handleFetch());
  }

  render() {
    const { companyObj, auth } = this.props
    const { dataArr, loading, yearmonth, bankaccOpt, acc1Opt, acc2Arr, acc3Arr } = this.state;
    const [year, month] = yearmonth.split('-');
    // console.log(bankaccSel)

    const editable = {
      isEditHidden: () => !auth.edit,
      isDeleteHidden: () => !auth.edit,
      onRowAdd: newData =>
        new Promise(async (resolve, reject) => {
          const db = Parse.Object.extend("cashbook");
          const newDoc = new db();
          const doc = await newDoc.save();
          const data = [...dataArr];
          const obj = {
            _id: doc.id,
            id: doc.id,
            objectId: doc.id,
            companyId: companyObj.objectId || '',
            companyName: companyObj.name || '',
            year,
            month,
            // eventDate: new Date(newData.eventDate.iso || newData.eventDate),
            acc1Text: acc1Opt[newData.acc1Id] || "",
            balance: '1',
            // bankaccText: bankaccOpt[newData.bankaccId] || "",
            ...newData
          }
          // data.push(obj);
          data.push(obj);
          this.setState({ dataArr: data, newData: obj }, () => resolve());
        }).then(() => this.handleAdd()),
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          const data = [...dataArr];
          const index = data.indexOf(oldData);
          // console.log(index, newData);
          const obj = {
            ...newData,
            year,
            month,
            // eventDate: new Date(newData.eventDate.iso || newData.eventDate),
            acc1Text: acc1Opt[newData.acc1Id] || "",
            // bankaccText: bankaccOpt[newData.bankaccId] || "",
          };
          data[index] = obj;
          // handleState('people0', data);
          this.setState({ dataArr: data, newData: obj, oldData }, () => resolve());
        }).then(() => this.handleUpdate()),
      onRowDelete: oldData =>
        new Promise((resolve, reject) => {
          let data = [...dataArr];
          const index = data.indexOf(oldData);
          data.splice(index, 1);
          // handleState('people0', data);
          this.setState({ dataArr: data, oldData }, () => resolve());
        }).then(() => this.handleDelete()),
    }

    if (auth.edit === false) {
      delete editable.onRowAdd
    }

    return (<>
      {/* 零用金及銀行帳戶明細表 */}
      <br />
      <Input
        type="month"
        name="yearmonth"
        value={yearmonth}
        onChange={this.handleChange}
      />
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns(bankaccOpt, acc1Opt, acc2Arr, acc3Arr)}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => {
            const dataArr = data.map(item => ({ ...item, acc1Id: acc1Opt[item.acc1Id] || '', acc2Id: (acc2Arr.find(item2 => item2.objectId === item.acc2Id) && acc2Arr.find(item2 => item2.objectId === item.acc2Id).value) || '', flow: flowOpt[item.flow] }))
            return exportPdf(columns, dataArr, `${year}年${month}月 資產負債 紀錄`)
          },
          pageSize: dataArr.length < 10 ? 10 : dataArr.length,
          search: false
        }}
        data={dataArr}
        title={`${year}年${month}月 資產負債 紀錄`}
        editable={editable}
      />
    </>)
  }
}