import React, { Component, } from 'react';
import { Form, Button, Table, Grid, Header, Icon, Confirm, Select } from 'semantic-ui-react'
import { getOptionsByKey } from 'views/widgets/FieldsRender';
import Moment from 'moment';
import Parse from '../../../../widget/parse'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}

export default class Parttime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      show: false,
      parttime: [],
      confirm: false,
      loading: false,
    };
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { firebase, match, listType, companyObj } = props;
    // const { filesData } = this.state
    const { parttime } = this.state
    const { _id } = match.params;

    const data = await Parse.queryData('Parttime', { companyId: companyObj.objectId, studentId: _id });

    // var promises = [];
    // snap.forEach(item => {
    //   var promise = firebase.firestore().collection(`Students/${_id}/Parttime/${item.id}/files`)
    //     .get().then(snapshot => {
    //       // console.log(snapshot.size);
    //       const filesData = []
    //       snapshot.forEach(fileItem => filesData.push({ _id: fileItem.id, ...fileItem.data(), parttimeId: item.id }));
    //       return { _id: item.id, ...item.data(), filesData };
    //     });
    //   promises.push(promise);
    // });
    // Promise.all(promises).then(results => {
    //   this.setState({ parttime: results })
    // }).catch(error => {
    //   console.log(error);
    // });
    this.setState({ parttime: data })

  }

  handleSubmit = async () => {
    const { companyObj, doc, semesterYear, semester } = this.props;
    const { form, parttime } = this.state;
    // const db = firebase.firestore();

    this.setState({ loading: true });
    const newParttime = [...parttime];
    if (!form.objectId) {
      // const docRef = db.collection(`Students/${doc.objectId}/Parttime`).doc();
      const db = Parse.Object.extend('Parttime');
      const newDoc = new db();
      const docRef = await newDoc.save();
      const obj = {
        ...form,
        objectId: docRef.id,
        studentId: doc.objectId,
        studentIdKey: doc.studentId,
        studentName: doc.jpnName,
        semesterYear,
        semester,
        companyId: companyObj.objectId,
        companyName: companyObj.name,
        // createdAt: new Date()
      };
      // await docRef.set(obj);
      await Parse.saveData('Parttime', obj)
      newParttime.unshift(obj);
    } else {
      // await db.collection(`Students/${doc.objectId}/Parttime`).doc(form.objectId).update(form);
      await Parse.saveData('Parttime', form)
      let index = newParttime.findIndex((item => item.objectId === form.objectId));
      newParttime[index] = form;
    }
    // if (form.during === true) { //取代現在
    //   let old = parttime.find(item => item.during === true) && parttime.find(item => item.during === true)._id;
    //   if (old) {
    //     let oldIndex = parttime.findIndex(item => item._id === old);
    //     parttime[oldIndex].during = false
    //     firebase.firestore().doc(`Students/${doc._id}/Parttime/${old}`).update({ during: false }).then(
    //       console.log(`Document set`)
    //     );
    //   }
    // }

    this.setState({ parttime: newParttime, loading: false });
    this.handleClose();
  }

  handleAddNew = async () => {
    const { companyObj, doc, semesterYear, semester } = this.props;
    const { form, parttime } = this.state;
    // const db = firebase.firestore();

    this.setState({ loading: true });
    const newParttime = [...parttime];
    // const docRef = db.collection(`Students/${doc.objectId}/Parttime`).doc();
    const db = Parse.Object.extend('Parttime');
    const newDoc = new db();
    const docRef = await newDoc.save();
    const obj = {
      ...form,
      objectId: docRef.id,
      studentId: doc.objectId,
      studentIdKey: doc.studentId,
      studentName: doc.jpnName,
      semesterYear,
      semester,
      companyId: companyObj.objectId,
      companyName: companyObj.name,
      // createdAt: new Date()
    };
    await Parse.saveData('Parttime', obj)
    // await docRef.set(obj);
    newParttime.unshift(obj);

    this.setState({ parttime: newParttime, loading: false });
    this.handleClose();
  }

  handleChange = (event, data) => {
    const { name, value, checked } = data
    if (name === 'during') {
      this.setState({
        form: {
          ...this.state.form,
          [name]: checked
        }
      })
    } else
      this.setState({
        form: {
          ...this.state.form,
          [name]: value
        }
      })
  }

  handleOpen = () => {
    const { parttime } = this.state
    if (parttime.length)
      this.setState({ show: true, form: { permitDate: parttime[0].permitDate, permitNum: parttime[0].permitNum } })
    else
      this.setState({ show: true })
  }

  handleEdit = (parttimeObj) => {
    this.setState({ show: true, form: { ...parttimeObj } });
  }

  handleClose = () => {
    this.setState({ show: false, form: {}, readOnly: false, fileObj: {} });
  }

  handleRemove = async () => {
    const { firebase, doc } = this.props
    const { form, parttime } = this.state;
    // await firebase.firestore().collection(`Students/${doc.objectId}/Parttime`).doc(form.objectId).delete();
    await Parse.deleteData('Parttime', form)
    const newParttime = [...parttime];
    let index = newParttime.findIndex((item => item.objectId === form.objectId));
    newParttime.splice(index, 1);
    this.setState({ parttime: newParttime });

    this.handleClose();
    this.hideConfirm();
  }

  showConfirm = (parttimeObj) => {
    this.setState({ confirm: true, form: { ...parttimeObj } });
  }
  hideConfirm = () => this.setState({ confirm: false, form: {} });

  render() {
    const { isEdit, doc, optSet, companyObj } = this.props
    const { parttime, form, disabled, readOnly, loading, show, confirm, } = this.state
    const isHas = getOptionsByKey(optSet, "isHas");
    const isHasArr = isHas.sort((a, b) => a.orderId - b.orderId).map(item => ({ key: item._id, value: item.value, text: item.label }))
    return (<>
      <Header as='h2' dividing
        content='資格外活動'
        style={{ backgroundColor: 'lavender' }}
      // subheader='調査時間：2017年4月から 2018年12月 まで'
      />
      <Button disabled={!isEdit} color='blue' onClick={this.handleOpen} icon='add' content='新規' />
      <Table celled structured>
        <Table.Header>
          <Table.Row>
            {isEdit ? <Table.HeaderCell rowSpan="2">#</Table.HeaderCell> : null}
            <Table.HeaderCell rowSpan="2">現在</Table.HeaderCell>
            <Table.HeaderCell rowSpan="2">調查年月日</Table.HeaderCell>
            <Table.HeaderCell rowSpan="2">店名</Table.HeaderCell>
            <Table.HeaderCell rowSpan="2">店の担当者</Table.HeaderCell>
            <Table.HeaderCell rowSpan="2">時給</Table.HeaderCell>
            <Table.HeaderCell rowSpan="2">曜日</Table.HeaderCell>
            <Table.HeaderCell rowSpan="2">時間</Table.HeaderCell>
            <Table.HeaderCell rowSpan="2">店の住所・電話番号</Table.HeaderCell>
            <Table.HeaderCell rowSpan="2">業種</Table.HeaderCell>
            <Table.HeaderCell>許可日期</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            {/* <Table.HeaderCell>連絡電話番号</Table.HeaderCell> */}
            <Table.HeaderCell>許可番号</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {parttime.length ? parttime.sort((a, b) => Moment(b.investDate) - Moment(a.investDate)).map((item, index) => (<React.Fragment key={index}>
            <Table.Row>
              {isEdit ? <Table.Cell rowSpan="2" style={{ padding: 10 }}>
                <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} icon='edit' /><br /> <br />
                <Button inverted={companyObj.inverted} color='red' size='small' onClick={() => this.showConfirm(item)} icon='delete' />
              </Table.Cell> : null}
              <Table.Cell rowSpan="2">{item.during ? 'V' : ''}</Table.Cell>
              <Table.Cell rowSpan="2">{item.investDate}</Table.Cell>
              <Table.Cell rowSpan="2">{item.storeName}</Table.Cell>
              <Table.Cell rowSpan="2">{item.personincharge}</Table.Cell>
              <Table.Cell rowSpan="2">{item.salary}</Table.Cell>
              <Table.Cell rowSpan="2">{item.workday}</Table.Cell>
              <Table.Cell rowSpan="2">{item.worktime}</Table.Cell>
              <Table.Cell rowSpan="2">{item.storeAddressAndTel}</Table.Cell>
              <Table.Cell rowSpan="2">{item.draft}</Table.Cell>
              <Table.Cell>{item.permitDate}</Table.Cell>
            </Table.Row>
            <Table.Row>
              {/* <Table.Cell>{item.storetel}</Table.Cell> */}
              <Table.Cell>{item.permitNum}</Table.Cell>
            </Table.Row>
          </React.Fragment>)) : <Table.Row><Table.Cell colSpan={isEdit ? 11 : 10} textAlign='center'>(資料無し)</Table.Cell></Table.Row>}
        </Table.Body>
      </Table>

      {/* <Modal open={show} onClose={this.handleClose} size='small'> */}
      <Modal
        isOpen={show}
        toggle={this.handleClose}
        // size="sm"
        className="mh-80 h-80 mw-80 w-80"
        style={{ paddingTop: '0px' }}
        scrollable={true}
      >
        <ModalHeader style={style.flexCenter}>
          <h2 style={{ margin: 0 }}> {form.objectId ? '修正' : '追加'}記録</h2>
          <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
        </ModalHeader>
        <ModalBody>
          <Form>
            <Grid>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <Form.Input
                    label='調查年月日'
                    type='date'
                    name='investDate'
                    value={form.investDate || ''}
                    onChange={this.handleChange}
                    readOnly={readOnly}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Checkbox
                    label='現在'
                    name='during'
                    checked={form.during || false}
                    onChange={this.handleChange}
                    readOnly={readOnly}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <Form.Select
                    label='資格外活動許可証'
                    name='permit'
                    options={isHasArr}
                    value={form.permit || ''}
                    onChange={this.handleChange}
                    readOnly={readOnly}
                  />
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <Form.Input
                    label='資格外活動許可期限'
                    type='date'
                    name='permitDate'
                    value={form.permitDate || ''}
                    onChange={this.handleChange}
                    readOnly={readOnly}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    label='資格外活動許可番号'
                    name='permitNum'
                    value={form.permitNum || ''}
                    onChange={this.handleChange}
                    readOnly={readOnly}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <Form.Input
                    label='アルバイト店名'
                    name='storeName'
                    value={form.storeName || ''}
                    onChange={this.handleChange}
                    readOnly={readOnly}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    label='業種'
                    name='draft'
                    value={form.draft || ''}
                    onChange={this.handleChange}
                    readOnly={readOnly}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <Form.Input
                    label='アルバイト先担当者'
                    name='personincharge'
                    value={form.personincharge || ''}
                    onChange={this.handleChange}
                    readOnly={readOnly}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    label='アルバイト時給'
                    name='salary'
                    value={form.salary || ''}
                    onChange={this.handleChange}
                    readOnly={readOnly}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <Form.Input
                    label='アルバイト曜日'
                    name='workday'
                    value={form.workday || ''}
                    onChange={this.handleChange}
                    readOnly={readOnly}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    label='アルバイト時間'
                    name='worktime'
                    value={form.worktime || ''}
                    onChange={this.handleChange}
                    readOnly={readOnly}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                {/* <Grid.Column>
                  <Form.Input
                    label='連絡電話番号'
                    name='storetel'
                    value={form.storetel || ''}
                    onChange={this.handleChange}
                    readOnly={readOnly}
                  />
                </Grid.Column> */}
                <Grid.Column>
                  <Form.Input
                    label='アルバイト住所・電話番号'
                    name='storeAddressAndTel'
                    value={form.storeAddressAndTel || ''}
                    onChange={this.handleChange}
                    readOnly={readOnly}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </ModalBody>
        <ModalFooter style={{ ...style.flex, justifyContent: 'flex-end', padding: '16px' }}>
          {form.objectId ? <Button onClick={this.handleAddNew} inverted={companyObj.inverted} color='green' loading={loading} icon='save' content={'另存新調查'} /> : null}
          <Button onClick={this.handleSubmit} inverted={companyObj.inverted} color='green' loading={loading} icon='checkmark' content={form.objectId ? '保存' : '新規'} />
          <Button onClick={this.handleClose} inverted={companyObj.inverted} color='red' icon='delete' content='閉じる' />
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={this.state.confirm}
        toggle={this.hideConfirm}
        size="lg"
        className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader>削除</ModalHeader>
        <ModalBody>
          削除してもいいですか？
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button onClick={this.handleRemove} inverted color='red' icon='delete' content='はい' />
          <Button onClick={this.hideConfirm} content='いいえ' />
        </ModalFooter>
      </Modal>
      {/* <Confirm
        header='削除'
        content='削除してもいいですか?'
        cancelButton='いいえ'
        confirmButton='はい'
        open={this.state.confirm}
        onCancel={this.hideConfirm}
        onConfirm={this.handleRemove}
        size='mini'
        centered={false} /> */}
    </>)
  }
}  