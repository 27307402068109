import React from 'react';
import { Input, Label, Form, Icon } from 'semantic-ui-react';
import Moment from 'moment';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { LocaleUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

const setShiftDay = (eventDate, handleDayChange, shift) => {
  const shiftedDay = new Date(eventDate);
  shiftedDay.setDate(shiftedDay.getDate() + shift);
  const str = Moment(shiftedDay).format('YYYY-MM-DD');
  handleDayChange(str);
};

const SelectDate = ({ eventDate, handleDayChange, isDisabled = 0 }) =>
  (<Input labelPosition='left'  >
    <Label color={'teal'}> <Icon name='calendar alternate' />日付</Label>
    <Input labelPosition='right' type='text' placeholder=''>
      <Label style={{ cursor: 'pointer', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }} onClick={() => setShiftDay(eventDate, handleDayChange, -1)}>
        <Icon size='small' name='angle double left' style={{ fontSize: '1.2em', margin: '0 0 0 0' }} /></Label>
      <DayPickerInput
        onDayChange={(date) => setShiftDay(date, handleDayChange, 0)}
        inputProps={{ className: "form-control", name: 'eventDate', style: { borderRadius: '0rem' } }}
        localeUtils={LocaleUtils} locale="id"
        value={eventDate}
        isDisabled={isDisabled}
      />
      <Label style={{ cursor: 'pointer', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }} onClick={() => setShiftDay(eventDate, handleDayChange, 1)} >
        <Icon size='mini' name='angle double right' style={{ fontSize: '1.2em', margin: '0 0 0 0' }} /></Label>
    </Input>
  </Input>);

export default SelectDate;
