import React, { Component } from 'react'
import { Form, Grid, } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Moment from 'moment';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

const columns = (workdays) => ([
  { title: 'Client', field: 'name_cht' },
  { title: 'Agent', field: 'agent_text' },
  {
    title: 'Applied Date', field: 'insertedAt', render: rowData => {
      if (rowData.insertedAt) {
        if (rowData.insertedAt.$date) {
          return Moment(rowData.insertedAt.$date).format('YYYY-MM-DD')
        }
        return Moment(rowData.insertedAt).format('YYYY-MM-DD')
      }
      return ""
    }
  },
  { title: 'Policy No.', field: 'account_num' },
  { title: '投資項目', field: 'product4_text' },
  { title: '進度', field: 'checkstatus' },
  { title: 'Current Status', field: 'current_Status', render: rowData => workdays.find(item => item.objectId === rowData.current_Status) ? workdays.find(item => item.objectId === rowData.current_Status).status : null },
  { title: 'Current Days', field: 'current_status_days', render: rowData => workdays.find(item => item.objectId === rowData.current_Status) ? workdays.find(item => item.objectId === rowData.current_Status).work_days : null },
  { title: 'Next Status', field: 'next_Status', render: rowData => workdays.find(item => item.objectId === rowData.next_Status) ? workdays.find(item => item.objectId === rowData.next_Status).status : null },
  { title: 'Status', field: 'status' },
  { title: 'Remark', field: 'remark' },
  { title: 'Owner', field: 'owner' },
  { title: 'Record', field: 'record' },
]);

const typeOpt = [
  { key: 'typeAll', value: '0', text: '所有' },
  { key: 'type1', value: '1', text: 'Pru Life' },
  { key: 'type2', value: '2', text: 'VOYA' },
  { key: 'type3', value: '3', text: 'Colonial' },
]

const nowphaseOpt = [
  { key: 'nowphaseAll', value: '0', text: '所有' },
  { key: 'nowphase1', value: '1', text: '申請中' },
  { key: 'nowphase2', value: '2', text: '已生效' },
  { key: 'nowphase3', value: '3', text: '無效' },
]

export default class Client3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      type1: '',
      type2: '',
      dataArr: [],
      dataArrOri: [],
      agentSel: [],
      agentId: '0',
      typeId: '0',
      nowphaseId: '1',
      workdays: [],
      loading: true
    }
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    const { companyObj } = this.props;
    const { nowphaseId } = this.state
    const companyId = companyObj.objectId;
    let data = []
    Parse.queryData('portfolios', { companyId }).then(portfolios => {
      let copyData = portfolios.map(item => item = { ...item, copy: 1 })
      data = copyData.filter(item => item.nowphase !== undefined)
      const dataArr = data.filter(item => item.nowphase === nowphaseId)
      this.setState({ dataArr, portfolios, dataArrOri: data });
    });
    this.handleFetch()
  }

  handleFetch = async (props = this.props) => {
    const { companyObj } = this.props;
    const companyId = companyObj.objectId;
    const workdays = await Parse.queryData('workdays', { companyId })

    this.setState({ loading: false, workdays })
  }

  handleChange = (e, { name, value }) => {
    const { dataArrOri, nowphaseId, typeId, agentId } = this.state

    if (name === 'typeId') {
      if (value === '0' && nowphaseId === '0' && agentId === '0') {
        this.setState({ dataArr: dataArrOri, [name]: value }, () => this.tableRef.current.onChangePage(null, 0))
      } else if (value === '0' && nowphaseId !== '0' && agentId === '0') {
        const data = dataArrOri.filter(item => item.nowphase === nowphaseId)
        this.setState({ dataArr: data, [name]: value }, () => this.tableRef.current.onChangePage(null, 0))
      } else if (value !== '0' && nowphaseId === '0' && agentId === '0') {
        const data = dataArrOri.filter(item => item.currency_type === value)
        this.setState({ dataArr: data, [name]: value }, () => this.tableRef.current.onChangePage(null, 0))
      } else if (value !== '0' && nowphaseId !== '0' && agentId === '0') {
        const data = dataArrOri.filter(item => item.currency_type === value && item.nowphase === nowphaseId)
        this.setState({ dataArr: data, [name]: value }, () => this.tableRef.current.onChangePage(null, 0))
      }
    } else if (name === 'nowphaseId') {
      if (value === '0' && typeId !== '0' && agentId === '0') {
        const data = dataArrOri.filter(item => item.currency_type === typeId)
        this.setState({ dataArr: data, [name]: value }, () => this.tableRef.current.onChangePage(null, 0))
      } else if (value === '0' && typeId === '0' && agentId === '0') {
        this.setState({ dataArr: dataArrOri, [name]: value }, () => this.tableRef.current.onChangePage(null, 0))
      } else if (value !== '0' && typeId === '0' && agentId === '0') {
        const data = dataArrOri.filter(item => item.nowphase === value)
        this.setState({ dataArr: data, [name]: value }, () => this.tableRef.current.onChangePage(null, 0))
      } else if (value !== '0' && typeId !== '0' && agentId === '0') {
        const data = dataArrOri.filter(item => item.nowphase === value && item.currency_type === typeId)
        this.setState({ dataArr: data, [name]: value }, () => this.tableRef.current.onChangePage(null, 0))
      }
    }
  }

  render() {
    const { companyObj } = this.props
    const { typeId, dataArr, loading, nowphaseId, workdays } = this.state;

    return (<div className="content">
      {/* <Container> */}
      {/* <div style={{ ...style.flexCenter, margin: '2rem auto' }}>
          <Header as='h2' style={{ margin: 0 }}>1-3 新件進度</Header>
        </div> */}
      <Form inverted={companyObj.inverted}>
        <Grid style={{ margin: '2rem auto' }}>
          <Grid.Row columns='equal'>
            <Grid.Column style={{}} width={4} computer={4} tablet={8} mobile={16}>
              <Form.Select style={{ position: 'relative', zIndex: 100 }} label='新件種類' options={typeOpt} onChange={this.handleChange} name='typeId' value={typeId} />
            </Grid.Column>
            <Grid.Column width={4} computer={4} tablet={8} mobile={16} >
              <Form.Select style={{ position: 'relative', zIndex: 98 }} options={nowphaseOpt} label='Status' name='nowphaseId' value={nowphaseId} onChange={this.handleChange} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
      <div style={{ maxWidth: '100%', marginBottom: '2rem' }}>
        <MaterialTable
          isLoading={loading}
          localization={{ ...localization(), toolbar: { searchPlaceholder: '客戶編號/中文名/英文名' } }}
          tableRef={this.tableRef}
          columns={columns(workdays)}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportAllData: true,
            exportPdf: (columns, data) => exportPdf(columns, data, '新件進度'),
            pageSize: 20,
            search: false,
            doubleHorizontalScroll: true,
          }}
          data={dataArr}
          title="新件進度"
        />
      </div>
      <br />
      <br />
    </div>)
  }
}