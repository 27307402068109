import React, { Component } from "react";
import moment from "moment";

import { Select, Form } from 'semantic-ui-react';
import {
  Row,
  Col,
  Button
} from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader
} from "react-calendar-timeline";
import Parse from 'widget/parse'
import 'react-calendar-timeline/lib/Timeline.css'

const keys = {
  groupIdKey: "id",
  groupTitleKey: "title",
  groupRightTitleKey: "rightTitle",
  itemIdKey: "id",
  itemTitleKey: "title",
  itemDivTitleKey: "title",
  itemGroupKey: "group",
  itemTimeStartKey: "start",
  itemTimeEndKey: "end",
  groupLabelKey: "title"
};
const paymentTypeOpt = [
  { key: '1', value: '1', text: '訂金' },
  { key: '2', value: '2', text: '押金' },
  { key: '3', value: '3', text: '預繳電費' },
  { key: '4', value: '4', text: '門鎖押金' },
  { key: '5', value: '5', text: '房租' },
  { key: '6', value: '6', text: '電費' },
  { key: '7', value: '7', text: '其他' },
]
const paymentTypeSel = { '1': '訂金', '2': '押金', '3': '預繳電費', '4': '門鎖押金', '5': '房租', '6': '電費', '7': '其他', }
const paytypeOpt = [
  { key: '1', value: '1', text: '收入' },
  { key: '2', value: '2', text: '支出' },
  { key: '3', value: '3', text: '其他' }
]
const DateDiff = (sDate1, sDate2) => { // sDate1 和 sDate2 是 2016-06-18 格式
  const oDate1 = new Date(sDate1);
  const oDate2 = new Date(sDate2);
  const iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24); // 把相差的毫秒數轉換為天數
  return iDays;
};
export default class ClientCalendar extends Component {
  constructor(props) {
    super(props);
    const groups = [
      { id: '1', title: 'name' },
      // { id: '2', title: 'name' },
      // { id: '3', title: 'name' },
      // { id: '4', title: 'name' },
      // { id: '5', title: 'name' },
    ]

    // const items = [
    // { id: '1000', title: '12345123124314513432456356746785789689ptfghavzcvbdfhjetyjew', group: '1', start: 1599400000000, end: 1610497508828, className: '', itemProps: { "data-tip": '123' } },
    // ]
    const defaultTimeStart = moment().startOf("year").toDate()

    const defaultTimeEnd = moment().startOf("year").add(1, "year").toDate();

    this.state = {
      buildingSel: '-1',
      groups,
      items: [],
      defaultTimeStart,
      defaultTimeEnd,
      show: false,
      paying1Obj: {},
      form: {},
      paying1Arr: []
    };
  }

  async componentDidMount() {
    const { companyObj } = this.props
    const buildingOpt = [{ value: '-1', key: 'aa', text: '全部' }]
    const data = await Parse.queryData('building', { companyId: companyObj.objectId });
    data.forEach(item => {
      buildingOpt.push({ value: item.objectId, text: item.buildingName, key: item.objectId })
    })
    this.setState({ buildingOpt })
    const { buildingSel } = this.state

    ///groups
    const groups = []
    if (buildingSel === '-1') {
      const data = await Parse.queryData('room', { companyId: companyObj.objectId });
      data.sort((a, b) => ('' + a.roomNum).localeCompare(b.roomNum)).forEach((item, index) => {
        groups.push({ id: `${index + 1}`, title: `${item.buildingName}  ${item.roomNum}`, roomNum: item.roomNum })
        // if ((data.length - 1) === index) {
        //   groups.push({ id: `${index + 2}`, title: '總計', roomNum: 'total' })
        // }
      })
      groups.push({ id: `${data.length + 1}`, title: '總計租金收入' })
    } else {
      const data = await Parse.queryData('room', { companyId: companyObj.objectId, buildingId: buildingSel });
      data.sort((a, b) => a.roomNum - b.roomNum).forEach((item, index) => {
        groups.push({ id: `${index + 1} `, title: `${item.buildingName}  ${item.roomNum}`, roomNum: item.roomNum })
      })
      groups.push({ id: `${data.length + 1}`, title: '總計租金收入' })
    }
    // console.log(groups)
    ///item
    const items = []
    const paying1Arr = await Parse.queryData('paying1', { companyId: companyObj.objectId, paymentType: '5' });
    paying1Arr.forEach((item, index) => {
      if (item.payDate === '') {
        items.push({
          objectId: item.objectId,
          id: `${index}`,
          title: `$${item.payable}(~${item.endDate})`, //'$36,000 (~2020-12/31)'
          group: (groups.find(item2 => item2.roomNum === item.roomNum) && groups.find(item2 => item2.roomNum === item.roomNum).id) || '',
          start: Date.parse(new Date(item.startDate)),
          end: Date.parse(new Date(item.endDate)),
          bgColor: 'rgba(255, 0, 0, 0.6)',
          // selectedBgColor: 'rgba(225, 166, 244, 0.6)',
          // color: 'rgba(225, 166, 244, 0.6)',
        })
      } else {
        items.push({
          objectId: item.objectId,
          id: `${index}`,
          title: `$${item.price}(~${item.endDate})`, //'$36,000 (~2020-12/31)'
          group: (groups.find(item2 => item2.roomNum === item.roomNum) && groups.find(item2 => item2.roomNum === item.roomNum).id) || '',
          start: Date.parse(new Date(item.startDate)),
          end: Date.parse(new Date(item.endDate)),
          bgColor: 'rgba(33, 150, 243, 0.6)',
          // selectedBgColor: 'rgba(225, 166, 244, 0.6)',
          // color: 'rgba(225, 166, 244, 0.6)',
        })
      }

    })
    // console.log(paying1Arr)
    const total = []
    paying1Arr.filter(item => item.payDate !== '').forEach((item, index) => {
      const days = DateDiff(`${item.endDate}`, `${item.startDate}`)
      const months = Math.round(days / 30)
      if (months !== 0) {
        if (total.find(item2 => item2.start === item.startDate)) {
          for (let i = 0; i < months; i++) {
            const start = moment(new Date(new Date(item.startDate).setMonth(new Date(item.startDate).getMonth() + i))).format('YYYY-MM-DD')

            const totalObj = total.find(item2 => item2.start === start)
            const index = total.indexOf(totalObj);
            total[index] = {
              ...totalObj,
              total: String(Number(totalObj.total) + (Number(item.price) / months))
            };
          }
        } else {
          for (let i = 0; i < months; i++) {
            const start = moment(new Date(new Date(item.startDate).setMonth(new Date(item.startDate).getMonth() + i))).format('YYYY-MM-DD')
            // console.log(start)
            const addMonth = new Date(start).setMonth(new Date(start).getMonth() + 1);
            const end = moment(new Date(new Date(addMonth).setDate(new Date(addMonth).getDate() - 1))).format('YYYY-MM-DD');
            if (total.find(item2 => item2.start === start)) {
              const totalObj = total.find(item2 => item2.start === start)
              // console.log(totalObj)
              const index = total.indexOf(totalObj);
              total[index] = { ...totalObj, total: String(Number(totalObj.total) + (Number(item.price) / months)) };
            } else {
              total.push({
                start,
                end,
                total: String(Number(item.price) / months)
              })
            }
          }
        }
      }
      // console.log(index, total)
    })
    // const obj = groups.find(item => item.title === '總計')
    const length = items.length
    total.forEach((item, index) => {
      items.push({
        // objectId: item.objectId,
        id: length + index,
        title: `$${item.total}`, //'$36,000 (~2020-12/31)'
        group: (groups.find(item => item.title === '總計租金收入').id) || '',
        start: Date.parse(new Date(item.start)),
        end: Date.parse(new Date(item.end)),
        bgColor: 'rgba(255, 98, 1, 0.6)'
        // selectedBgColor: 'rgba(225, 166, 244, 0.6)',
        // color: 'rgba(225, 166, 244, 0.6)',
      })
    })
    // console.log(items.filter(item => item.group === '49'))
    this.setState({ groups, items, paying1Arr })
  }

  // handleFetch = async () => {
  //   const { companyObj } = this.props
  //   const { buildingSel } = this.state

  //   const groups = []
  //   if (buildingSel === '-1') {
  //     const data = await Parse.queryData('room', { companyId: companyObj.objectId });
  //     data.sort((a, b) => ('' + a.roomNum).localeCompare(b.roomNum)).forEach((item, index) => {
  //       groups.push({ id: `${index + 1}`, title: item.roomNum })
  //     })
  //   } else {
  //     const data = await Parse.queryData('room', { companyId: companyObj.objectId, buildingId: buildingSel });
  //     data.sort((a, b) => a.roomNum - b.roomNum).forEach((item, index) => {
  //       groups.push({ id: `${index + 1} `, title: item.roomNum })
  //     })
  //   }
  //   this.setState({ groups })
  // }

  // handleItemMove = (itemId, dragTime, newGroupOrder) => {
  //   const { items, groups } = this.state;

  //   const group = groups[newGroupOrder];

  //   this.setState({
  //     items: items.map(item =>
  //       item.id === itemId
  //         ? Object.assign({}, item, {
  //           start: dragTime,
  //           end: dragTime + (item.end - item.start),
  //           group: group.id
  //         })
  //         : item
  //     )
  //   });

  //   console.log("Moved", itemId, dragTime, newGroupOrder);
  // };

  // handleItemResize = (itemId, time, edge) => {
  //   const { items } = this.state;

  //   this.setState({
  //     items: items.map(item =>
  //       item.id === itemId
  //         ? Object.assign({}, item, {
  //           start: edge === "left" ? time : item.start,
  //           end: edge === "left" ? item.end : time
  //         })
  //         : item
  //     )
  //   });

  //   console.log("Resized", itemId, time, edge);
  // };

  handleChange = (e, { name, value }) => {
    const { form } = this.state
    this.setState({ form: { ...form, [name]: value } })
  }

  onCanvasClick = (groupId, time, e) => {
    console.log(groupId, time, e)
  }
  onItemClick = async (itemId, e, time) => {
    // console.log(itemId, e, time)
    const { items } = this.state
    // console.log(items[itemId])
    const paying1Obj = await Parse.findDoc('paying1', { objectId: items[itemId].objectId })
    this.setState({ show: true, paying1Obj, form: { ...paying1Obj } })
  }

  save = () => {
    const { form } = this.state
    Parse.saveData('paying1', form);
    this.setState({ show: false })
  }
  close = () => this.setState({ show: false })

  delete = () => {
    const { form } = this.state
    Parse.deleteData('paying1', form);
    this.setState({ show: false })
  }

  itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const backgroundColor = itemContext.selected ? (itemContext.dragging ? "red" : item.selectedBgColor) : item.bgColor;
    const borderColor = itemContext.resizing ? "red" : item.color;
    return (
      <div
        {...getItemProps({
          style: {
            backgroundColor,
            color: item.color,
            borderColor,
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 4,
            borderLeftWidth: itemContext.selected ? 3 : 1,
            borderRightWidth: itemContext.selected ? 3 : 1
          },
          onMouseDown: () => {
            console.log("on item click", item);
          }
        })}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: "hidden",
            paddingLeft: 3,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}
        >
          {itemContext.title}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    );
  };

  render() {
    const { groups, items, defaultTimeStart, defaultTimeEnd, paying1Obj, show, form, paying1Arr } = this.state;
    const { payType, startDate, endDate, paymentType, deadlineDate, payDate, price, payable, ps } = form
    const unPaidArr = paying1Arr.filter(item => item.payDate === '')
    return (<div className='content'>
      {/* <Select name="buildingSel" style={{ position: 'relative', zIndex: 99, marginRight: '8px' }} options={buildingOpt} value={buildingSel} onChange={this.handleChange} /> */}
      {/* <br /> */}
      {unPaidArr.sort((a, b) => ('' + a.roomNum).localeCompare(b.roomNum)).map((item, index) => <><h6>{index + 1}. {item.roomNum}房：{item.startDate}~{item.endDate} 應繳：{item.payable}</h6></>)}
      <h6>未繳租金數：{unPaidArr.length}</h6>
      未繳合計：{unPaidArr && unPaidArr.length && unPaidArr.map(item => Number(item.payable)).reduce((p, c) => p + c)}
      <br />
      <br />
      <Row>
        <Col md={10}>
          <Timeline
            groups={groups}
            items={items}
            keys={keys}
            fullUpdate
            itemTouchSendsClick={false}
            stackItems
            itemHeightRatio={0.75}
            // canMove={true}
            minZoom={365 * 24 * 60 * 60 * 1000}
            maxZoom={3 * 365.24 * 86400 * 1000}
            // canResize={"both"}
            defaultTimeStart={defaultTimeStart}
            defaultTimeEnd={defaultTimeEnd}
            onItemClick={this.onItemClick}
            itemRenderer={this.itemRenderer}
          // onItemMove={this.handleItemMove}
          // onItemResize={this.handleItemResize}
          // onCanvasClick={this.onCanvasClick}
          />
        </Col>
      </Row>
      <Modal
        isOpen={show}
        size='lg'
        toggle={this.close}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.close}>
            <span aria-hidden="true">×</span>
          </button>
          {paying1Obj.roomNum}號房
        </ModalHeader>
        <ModalBody>
          <Form>
            <Form.Group widths='equal'>
              <Form.Select fluid label='類別' name='payType' value={payType} options={paytypeOpt} onChange={this.handleChange} />
              <Form.Input fluid label='開始日期' name='startDate' value={startDate} onChange={this.handleChange} />
              <Form.Input fluid label='結束日期' name='endDate' value={endDate} onChange={this.handleChange} />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Select fluid label='項目' name='paymentType' value={paymentType} options={paymentTypeOpt} onChange={this.handleChange} />
              <Form.Input fluid label='應繳金額' name='payable' value={payable} onChange={this.handleChange} />
              <Form.Input fluid label='金額' name='price' value={price} onChange={this.handleChange} />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Input fluid label='繳費日期' name='payDate' value={payDate} onChange={this.handleChange} />
              <Form.Input fluid label='繳費期限' name='deadlineDate' value={deadlineDate} onChange={this.handleChange} />
              <Form.Input fluid label='備註' name='ps' value={ps} onChange={this.handleChange} />
            </Form.Group>
          </Form>
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button color='success' onClick={this.save}>確定</Button>
          <Button color='danger' onClick={this.delete}>刪除</Button>
          <Button onClick={this.close} >關閉</Button>
        </ModalFooter>
      </Modal>
    </div>
    );
  }
}
