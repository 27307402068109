import React, { Component } from 'react'
import { Container, Form, Header, Button, Icon, Table, Input, Label, Select, Grid } from 'semantic-ui-react'
import Moment from 'moment';
import { Link } from 'react-router-dom'

import MyModalTable from '../../widgets/MyModalTable';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import { getOptionsByKey } from 'views/widgets/FieldsRender';
import Parse from '../../../widget/parse'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { DimmerImg } from '../../../layouts/Dimmer';

export default class ExamPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      listType: '0',
      xlsList: [],
      form: {
        eventDate: Moment(new Date()).format('YYYY/MM/DD'),
        semesterYear: props.semesterYear,
        semester: props.semester,
      },
      students: []
    }
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.students !== nextProps.students ||
      this.state.grade !== nextProps.grade) {
      this.handleFetch(nextProps)
    }
  }

  handleFetch = async (props = this.props) => {
    const { firebase, classId, companyObj } = props;
    // if (!gradeL) {
    //   handleLazyLoad('grade');
    // } else {
    const grade = await Parse.queryData('Grade1', { companyId: companyObj.objectId });

    let gradeType = grade
      .sort((a, b) => ('' + b.testCode).localeCompare(a.testCode))
      .sort((a, b) => ('' + b.semesterYear).localeCompare(a.semesterYear));

    this.setState({ gradeType })
    // }
    // if (!studentsL) {
    //   handleLazyLoad('students');
    // }
    const data = await Parse.queryData('Students', { companyId: companyObj.objectId });
    this.setState({ students: data, loading: false });

  }

  handleOpen = () => {
    this.setState({ show: true })
  }

  handleEdit = (gradeObj) => {
    this.setState({ show: true, form: { ...gradeObj } });
  }

  handleClose = () => {
    this.setState({
      show: false,
      form: {
        ps: '',
        testCode: '',
        testLevel: '',
        testDate: Moment(new Date()).format('YYYY/MM/DD'),
        semesterYear: this.props.semesterYear,
        semester: this.props.semester,
      },
    });
  }

  handleChange = (e, { name, value }) => {
    const { semesterYear, semester } = this.props;
    const { students = [] } = this.state
    if (name === 'listType') {
      const tmp = students.filter(item =>
        (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
        (item.applyResult === '1' || item.studentType === "2"));

      let xlsList = [];
      if (value === '1') {// 新入生
        xlsList = tmp.filter(item => item.semesterYear === semesterYear).sort((a, b) => (a.studentId).localeCompare(b.studentId));
      } else if (value === '2') { // 畢業生
        xlsList = tmp.filter(item => (Number(item.semesterYear) === Number(semesterYear) - 1)).sort((a, b) => (a.studentId).localeCompare(b.studentId));
      } else if (value === '3') { // 在籍生
        xlsList = tmp.sort((a, b) => (a.studentId).localeCompare(b.studentId));
      } else {
        if (value.indexOf(',') !== -1) {
          var arr = value.split(',');
          xlsList = tmp.filter(item => item.semesterYear === arr[0] && item.semester === arr[1]).sort((a, b) => (a.studentId).localeCompare(b.studentId));
        }
      }
      this.setState({ xlsList, [name]: value });
    } else {
      this.setState({
        form: {
          ...this.state.form,
          [name]: value
        }
      })
    }
  }

  handleSubmit = async () => {
    const { firebase, refetch } = this.props
    const { title, form, gradeType } = this.state
    this.setState({ loading: true });

    const db = firebase.firestore();
    const newGrade = [...gradeType];
    if (!form._id) {
      const docRef = db.collection(`Grade`).doc();
      const obj = {
        ...form,
        _id: docRef.id,
        createdAt: new Date()
      };
      await docRef.set(obj).then(ref => { refetch('Grade') })
      newGrade.unshift(obj);

    } else {
      await db.collection(`Grade`).doc(form._id).update(form);
      let index = newGrade.findIndex((item => item._id === form._id));
      newGrade[index] = form;
    }
    // if (form.during === true) { //取代現在
    //   let old = parttime.find(item => item.during === true) && parttime.find(item => item.during === true)._id;
    //   if (old) {
    //     let oldIndex = parttime.findIndex(item => item._id === old);
    //     parttime[oldIndex].during = false
    //     firebase.firestore().doc(`Students/${doc._id}/Parttime/${old}`).update({ during: false }).then(
    //       console.log(`Document set`)
    //     );
    //   }
    // }

    this.setState({ gradeType: newGrade, loading: false });
    this.handleClose();
  }

  // fieldsTerm = [
  //   {
  //     id: 'semesterYear',
  //     title: '学年'
  //   },
  //   {
  //     id: 'semester',
  //     title: '学期'
  //   },
  //   {
  //     id: 'testCode',
  //     title: 'テストコード'
  //   },
  //   {
  //     id: 'testLevel',
  //     title: '試験レべル'
  //   },
  //   {
  //     id: 'testDate',
  //     title: '試験日'
  //   },
  //   {
  //     id: 'ps',
  //     title: '備考'
  //   },
  // ]

  render() {
    const { grade, match, semester, semesterYear, optSet, companyObj } = this.props
    const { isEdit, gradeType, xlsList, listType, show, form, loading } = this.state
    const { examKey } = match.params;
    // let fields = [];
    // let label = '';

    // label = '定期テスト結果入力';
    // fields = this.fieldsTerm;

    // const columns = fields.map(item => ({ name: item.id, ...item }));
    const semesterYearArr = getOptionsByKey(optSet, 'semesterYear').map(item => ({ key: item._id, text: item.label, value: item.value }));
    const semestersArr = getOptionsByKey(optSet, 'semester').map(item => ({ key: item._id, text: item.label, value: item.value }));


    const options = semester === 'S' ? [
      { key: '0', text: '-- 選択 --', value: '0' },
      { key: 'thisS', text: `${semesterYear}S`, value: `${semesterYear},S` },
      { key: 'lastA', text: `${semesterYear - 1}A`, value: `${semesterYear - 1},A` },
      { key: 'lastS', text: `${semesterYear - 1}S`, value: `${semesterYear - 1},S` },
      { key: '1', text: `新入生 (${semesterYear}S)`, value: '1' },
      { key: '2', text: `卒業生 (${semesterYear - 1}S+${semesterYear - 1}A)`, value: '2' },
      { key: '3', text: `在籍生 (${semesterYear - 1}S+${semesterYear - 1}A+${semesterYear}S)`, value: '3' }
    ] :
      [
        { key: '0', text: '-- 選択 --', value: '0' },
        { key: 'thisA', text: `${semesterYear}A`, value: `${semesterYear},A` },
        { key: 'thisS', text: `${semesterYear}S`, value: `${semesterYear},S` },
        { key: 'lastA', text: `${semesterYear - 1}A`, value: `${semesterYear - 1},A` },
        { key: 'lastS', text: `${semesterYear - 1}S`, value: `${semesterYear - 1},S` },
        { key: '1', text: `新入生 (${semesterYear}S+${semesterYear}A)`, value: '1' },
        { key: '2', text: `卒業生 (${semesterYear - 1}S+${semesterYear - 1}A)`, value: '2' },
        { key: '3', text: `在籍生 (${semesterYear - 1}S+${semesterYear - 1}A+${semesterYear}S+${semesterYear}A)`, value: '3' }
      ];

    return (
      <div className='content'>

        <Header as='h2' floated='left'>定期テスト結果入力</Header>
        <Button floated='right' color='green' onClick={this.handleOpen} icon='add' content='追加' />
        {/* {isEdit ? <Button positive onClick={() => this.saveUpdateSetting()} icon='save' content='保存' /> : */}
        {!isEdit ? <Button floated='right' primary onClick={() => this.setState({ isEdit: true })} icon="edit" content="編集" /> :
          <Button floated='right' basic onClick={() => this.setState({ isEdit: false })} icon='times' content='編集を終了' />}
        {/* <MyModalTable
        {...this.props}
        pageTitleText={label}
        // fields={fields}
        rows={isEdit,}
        columns={columns}
        type='grade'
        examKey={examKey}
      /> */}
        <br />
        {loading ? <DimmerImg style={{ marginTop: '80px' }} /> : <Table celled striped selectable>
          <Table.Header>
            <Table.Row>
              {isEdit ? <Table.HeaderCell style={{ width: '100px' }}>#</Table.HeaderCell> : null}
              <Table.HeaderCell>学年</Table.HeaderCell>
              <Table.HeaderCell>学期</Table.HeaderCell>
              <Table.HeaderCell>テストコード</Table.HeaderCell>
              <Table.HeaderCell>試験レべル</Table.HeaderCell>
              <Table.HeaderCell>試験日</Table.HeaderCell>
              <Table.HeaderCell>備考</Table.HeaderCell>
              {!isEdit ? <Table.HeaderCell></Table.HeaderCell> : null}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {gradeType && gradeType.map((item, index) => (<Table.Row key={`td${index}`}>
              {isEdit ? <Table.Cell style={{ padding: 10 }}>
                <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} icon='edit' />
                {/* <Button inverted={companyObj.inverted} color='red' size='small' onClick={() => this.showConfirm(item)} icon='delete' /> */}
              </Table.Cell> : null}
              {/* <Link style={{ color: '#000' }} to={`/ExamTable/${item._id}`}> */}
              <Table.Cell>{item.semesterYear}</Table.Cell>
              <Table.Cell>{item.semester}</Table.Cell>
              <Table.Cell>{item.testCode}</Table.Cell>
              <Table.Cell>{item.testLevel}</Table.Cell>
              <Table.Cell>{item.testDate}</Table.Cell>
              <Table.Cell>{item.ps}</Table.Cell>
              {!isEdit ? <Table.Cell><Button content='詳細' color='green' size='small' as={Link} to={`/admin/ExamTable/${item.objectId}`} /></Table.Cell> : null}
              {/* </Link> */}
            </Table.Row>
            ))}
            {!gradeType || !gradeType.length ? <Table.Row>
              <Table.Cell colSpan={isEdit ? 7 : 6} textAlign='center' >(資料無し)</Table.Cell>
            </Table.Row> : null}
          </Table.Body>
        </Table>}

        <br />
        {/* {examKey === 'termExam' ? */}
        <Grid>
          <Grid.Row>
            <Grid.Column width={12}>
              <Input labelPosition='left' >
                <Label color={'blue'}> <Icon name='file alternate outline' />テスト学生別リスト</Label>
                <Select
                  style={{
                    minWidth: '280px',
                    borderTopLeftRadius: '0',
                    borderBottomLeftRadius: '0'
                  }}
                  selection
                  options={options} onChange={this.handleChange} value={listType} name='listType' />
              </Input>&nbsp;&nbsp;&nbsp;
            {listType && xlsList && xlsList.length ? <ExportTableToExcelButton /> : null}
              {/* <br /> */}
              <br />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={10}>
              {xlsList.length ? xlsList.length + '名' : ''}
              {xlsList && xlsList.length ?
                <Table id="excel" striped size="small">
                  <Table.Header>
                    <Table.Row>
                      {/* <Table.HeaderCell></Table.HeaderCell> */}
                      <Table.HeaderCell style={{ width: '120px' }}>#</Table.HeaderCell>
                      <Table.HeaderCell>日本語氏名</Table.HeaderCell>
                      {/* <Table.HeaderCell>英語表記</Table.HeaderCell> */}
                      <Table.HeaderCell>カタカナ</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {xlsList.map((item, index) => (
                      <Table.Row key={item._id}>
                        {/* <Table.Cell>{index}</Table.Cell> */}
                        <Table.Cell>{item.classSchool}0{item.studentId}</Table.Cell>
                        <Table.Cell>{item.jpnName}</Table.Cell>
                        {/* <Table.Cell>{item.engName}</Table.Cell> */}
                        <Table.Cell>{item.katakanaName}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table> : ''}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {/* <Modal open={show} onClose={this.handleClose} size='tiny'> */}
        <Modal
          isOpen={show}
          toggle={this.handleClose}
          // size="sm"
          className="mh-80 h-80 mw-80 w-80"
          style={{ paddingTop: '0px' }}
          scrollable={true}
        >
          <ModalHeader className="justify-content-center">
            <h2 style={{ margin: 0 }}> 定期テスト</h2>
            {/* <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon> */}
          </ModalHeader>
          <ModalBody style={{ padding: "20px 30px" }}>
            <Grid>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>学年</label>
                  <Form.Select onChange={this.handleChange} name='semesterYear' defaultValue={form.semesterYear}
                    options={semesterYearArr} />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>学期</label>
                  <Form.Select onChange={this.handleChange} name='semester' defaultValue={form.semester}
                    options={semestersArr} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>テストコード</label>
                  <Form.Input
                    value={form.testCode} onChange={this.handleChange} style={{ minWidth: 196 }} name='testCode'
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>試験レべル</label>
                  <Form.Input
                    value={form.testLevel} onChange={this.handleChange} style={{ minWidth: 196 }} name='testLevel'
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>試験日</label>
                  <Form.Input
                    type='date'
                    name='testDate'
                    value={form.testDate}
                    onChange={this.handleChange}
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>備考</label>
                  <Form.Input
                    value={form.ps} onChange={this.handleChange} style={{ minWidth: 196 }} name='ps'
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </ModalBody>
          <ModalFooter style={{ padding: '16px' }}>
            <Button onClick={this.handleSubmit} inverted={companyObj.inverted} color='green' loading={loading} icon='checkmark' content={form._id ? '保存' : '新規'} />
            <Button onClick={this.handleClose} inverted={companyObj.inverted} color='red' icon='delete' content='閉じる' />
          </ModalFooter>
        </Modal>

      </div >)
  }
}