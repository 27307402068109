import React, { Component } from 'react'
import { Dropdown, Container, Header, Modal, Button, Table, Form, Grid, Image, Input, Checkbox } from 'semantic-ui-react';
// import Moment from 'moment';
import MaterialTable, { MTableToolbar } from 'material-table'
// import { Select, MenuItem } from '@material-ui/core';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
// import ModalNamelist from './ModalNamelist'
// import ModalNamelist3 from './ModalNamelist3'
import { dateCol, typeCurrency, exportPdf } from 'views/web/CustomMaterialTable';
import ModalMultiInput from 'views/web/ModalMultiInput';

export default class ClientTab3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataArr: [],
      newData: {},
      oldData: {},
      loading: true,
      userArr: []
    }
    this.tableRef = React.createRef();
  }

  componentDidMount = async () => {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { match, companyObj, rowData } = props;
    const { clientId } = match.params;
    if (!clientId) {
      return;
    }
    // const companyId = companyObj.objectId;
    this.setState({ loading: true })
    const data = await Parse.queryData('User', { companyId: companyObj.objectId, schoolId: rowData.objectId, type: 'teacher' });
    // const data1 = await Parse.queryData('class', { companyId: companyObj.objectId, schoolId: rowData.objectId });
    const userArr = []
    data.forEach(item => {
      userArr.push({ key: item.objectId, value: item.objectId, text: item.name })
    })
    this.setState({ dataArr: data, userArr, loading: false });
  }

  handleAdd = async () => {
    const { newData } = this.state;
    Parse.saveData('User', newData);
    // this.handleFetch();
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    Parse.saveData('User', newData);
    this.handleFetch();
  }

  handleDelete = async () => {
    const { oldData } = this.state
    Parse.deleteData('User', oldData);
    this.setState({ oldData: {} })
    this.handleFetch();
  }

  render() {
    const { companyObj, rowData } = this.props
    const { dataArr, loading, userArr } = this.state
    return (<>
      <MaterialTable
        // isLoading={loadingData}
        localization={localization()}
        // columns={columns()}
        columns={[
          // { width: 120, title: '系所', field: 'department' },
          // { width: 100, title: '學年度', field: 'schoolYear' },
          // { width: 100, title: '課程', field: 'course' },
          // { width: 100, title: '班級', field: 'class' },
          // {
          //   width: 150, title: '權限', field: 'teacherAuth',
          //   render: rowData => <>
          //     <Dropdown placeholder='權限' multiple selection options={userArr} defaultValue={rowData.teacher} style={{ zIndex: 99 }} disabled />
          //   </>,
          //   editComponent: props => <>
          //     <Dropdown placeholder='權限' multiple selection options={userArr} onChange={(e, data) => props.onRowDataChange({ ...props.rowData, teacher: data.value })} style={{ zIndex: 99 }} defaultValue={props.rowData.teacher} />
          //   </>,
          // },
          { width: 80, title: '姓名', field: 'name' },
          {
            width: 150, title: '權限', field: 'teacherAuth',
            render: rowData => <>
              <Dropdown placeholder='權限' multiple selection options={userArr} defaultValue={rowData.teacher} style={{ zIndex: 99 }} disabled />
            </>,
            editComponent: props => <>
              <Dropdown placeholder='權限' multiple selection options={userArr} onChange={(e, data) => props.onRowDataChange({ ...props.rowData, teacher: data.value })} style={{ zIndex: 99 }} defaultValue={props.rowData.teacher} />
            </>,
          },
        ]}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${rowData.name_cht} 使用者管理`),
          pageSize: 10,
          search: false,
          tableLayout: 'fixed'
        }}
        components={{
          Toolbar: props => (<div>
            <div style={{ float: 'right', textAlign: 'right', padding: '22px 16px 22px 4px' }}>
              <ModalMultiInput {...this.props} handleData={this.handleFetch} columns={props.columns} condition={{ companyId: companyObj.objectId, companyName: companyObj.name, schoolId: rowData.objectId, schoolName: rowData.name_cht, type: 'teacher' }} dbName='User' />
            </div>
            <MTableToolbar {...props} />
            <div style={{ clear: 'both' }}></div>
          </div>),
        }}
        data={dataArr}
        title={`${rowData.name_cht} 使用者管理`}
        actions={[
          {
            icon: 'view_headline',
            tooltip: '查看',
            onClick: (event, rowData) => this.handleData(rowData)
          }
        ]}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const data = [...dataArr];
              const db = Parse.Object.extend("User");
              const newDoc = new db();
              const doc = await newDoc.save();
              // newData.objectId = doc.id;
              // newData.id = doc.id;
              // newData._id = doc.id;
              let obj = {
                ...newData,
                objectId: doc.id,
                id: doc.id,
                _id: doc.id,
                companyId: companyObj.objectId,
                companyName: companyObj.name,
                schoolId: rowData.objectId,
                schoolName: rowData.name_cht,
                type: 'teacher'
              }
              data.push(obj);
              this.setState({ dataArr: data, newData: obj }, () => resolve());
            }).then(() => this.handleAdd()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...dataArr];
              const index = data.indexOf(oldData);
              data[index] = newData;
              // handleState('people0', data);
              this.setState({ dataArr: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...dataArr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              // handleState('people0', data);
              this.setState({ dataArr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
    </>)
  }
}