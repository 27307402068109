import React, { Component } from 'react'
import { Button, Form, Grid, Select } from 'semantic-ui-react';

import PrintPdf from './PrintPdf';

// const js_beautify = require('js-beautify').js;
// ref. https://github.com/securingsincity/react-ace/blob/master/docs/Ace.md
// pdf ref. https://sphilee.github.io/jsPDF-CustomFonts-support/

const Editor = (props) => {
  const Ace = require('react-ace').default;
  require('brace/mode/javascript');
  require('brace/theme/monokai');
  return <Ace {...props} />
}

export default class PrintEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfsrc: '',
      codeForm: props.codeForm || '',
      code: props.codeForm.code || '',
      format: props.codeForm.format || '',
      orientation: props.codeForm.orientation || '',
      ps: props.codeForm.ps || '',

      oricode: props.codeForm.code || '',

      diffCode: '',
      doc: null,
      error: '',
      arr: [],
      fontSize: 14,
      isShowPDF: false,
      activeTab2: props.activeTab2,
      obj: {},
      relative: [],
    };
  }
  componentWillReceiveProps(nextProps) {
    // console.log("componentWillReceiveProps(nextProps)");
    const { dataArr, selectedOption, showForm } = nextProps;
    const { arr, activeTab2, codeDB } = this.state;

    // //  如果別人有改到同一份PDF的情況 (codeDB是資料庫裡最原始的code，因為在constructor時就建立了)
    // if (codeDB !== nextProps.codeForm.code) {
    //   this.setState({ codeForm: nextProps.codeForm || { code: `doc.setFont('NotoSansCJKjp');\ndoc.text(15, 15, '尚未輸入程式碼');` }, codeDB: nextProps.code, isShowPDF: false });

    // 切換Tab情況
    if (activeTab2 !== nextProps.activeTab2) {
      this.setState({
        codeForm: nextProps.codeForm || {},
        code: nextProps.codeForm.code || '',
        oricode: nextProps.codeForm.code || '',
        format: nextProps.codeForm.format || '',
        orientation: nextProps.codeForm.orientation || '',
        ps: nextProps.codeForm.ps || '',
        activeTab2: nextProps.activeTab2,
        isShowPDF: false
      });
      nextProps.refetch('FormSettings');
    }

    // 先不要這個 不要這個的話切換不同PDF沒辦法刷新！
    // if (code !== nextProps.code) {
    //   this.setState({ code: nextProps.code || `doc.setFont('NotoSansCJKjp');\ndoc.text(15, 15, '尚未輸入程式碼');`, isShowPDF: false });
  }

  onChange = (code) => {
    this.setState({ code });
  }

  handleSelChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  }

  handlePreview = () => {
    let { codeForm, code, format, orientation, ps } = this.state;
    let newcode = window.js_beautify(code, { indent_size: 2, space_in_empty_paren: true });
    const form = { ...codeForm, code: newcode, format, orientation, ps };
    this.setState({ codeForm: form, isShowPDF: true });
  }

  handleSubmit = () => {
    const { activeTab2, activeTab2key } = this.props;
    const { codeForm, code, format, orientation, ps } = this.state;

    let newcode = window.js_beautify(code, { indent_size: 2, space_in_empty_paren: true });

    const form = { ...codeForm, code: newcode, format, orientation, ps };
    // firebase.firestore().doc(`FormSettings/${activeTab2}`).update(form).then(() => {
    //   //  alert('saved ok!')
    //   this.setState({
    //     codeForm: form,
    //     code: form.code,
    //     oricode: form.code,
    //     format,
    //     orientation,
    //     ps
    //   });
    // });
  }

  render() {
    const { loading, user, formSet, optSet, activeTab2, activeTab2key } = this.props;
    const { showForm, dataArr } = this.props;
    const { codeForm, code, oricode, format, orientation, ps, isShowPDF } = this.state;

    return (
      <Grid style={{ padding: '5px' }}>
        <Grid.Row> {showForm.indexOf('2') !== -1 ?
          <Grid.Column width={showForm.indexOf('5') !== -1 ? 16 : 8} style={{ position: "relative", zIndex: 0 }}>
            <h4>模版編輯 (リスト變数: arr)</h4>
            <Form style={{ marginBottom: '5px' }}>
              <Form.Group>
                <Form.Field
                  name='format'
                  control={Select}
                  options={[{ key: 'ff', value: '', text: '--- 請選擇 ---' }, { key: 'a4', value: 'a4', text: 'a4' }, { key: 'a3', value: 'a3', text: 'a3' }, { key: 'B5', value: 'B5', text: 'b5' }]}
                  label='紙張大小'
                  inline
                  onChange={this.handleSelChange}
                  value={format}
                  width={4}
                />
                <Form.Field
                  name='orientation'
                  control={Select}
                  options={[{ key: 'oo', value: '', text: '--- 請選擇 ---' }, { key: 'l', value: 'l', text: '橫向 (L)' }, { key: 'p', value: 'p', text: '直向 (P)' }]}
                  label='方向'
                  inline
                  onChange={this.handleSelChange}
                  value={orientation}
                  width={4}
                />
                <Form.Input
                  name='ps'
                  label='備考'
                  inline
                  onChange={this.handleSelChange}
                  value={ps}
                  width={5}
                />
                <Form.Field
                  control={Button}
                  label='儲存'
                  size='tiny'
                  // floated='right'
                  // fluid 
                  disabled={code === oricode && format === codeForm.format && orientation === codeForm.orientation && ps === codeForm.ps}
                  primary onClick={this.handleSubmit} icon='save'
                />
                <Form.Field
                  control={Button}
                  // content=''
                  label='プレビュ—'
                  size='tiny'
                  // floated='right'
                  fluid
                  disabled={showForm.indexOf('0') !== -1}
                  positive
                  onClick={this.handlePreview}
                  icon='file alternate'
                />
                {/* <Button floated='right' positive onClick={this.handlePreviewAndSubmit}>プレビュ—&儲存</Button> */}
              </Form.Group>
            </Form>
            <Editor
              mode="javascript"
              theme="monokai"
              name="blah2"
              onLoad={this.onLoad}
              onChange={this.onChange}
              width='100%'
              // height="650px"
              height={showForm.indexOf('7') !== -1 ? "1200px" : "650px"}
              // fontSize={14}
              fontSize={Number(this.state.fontSize)}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              value={code}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
            {/* {this.state.pdfsrc && this.state.diffCode && this.state.diffCode.length ?  <Grid.Row>
                  <Grid.Column sm={12}>
                    <h5>非日本漢字確認</h5>
                    {this.state.diffCode ? this.state.diffCode.map(({ line, a, b }) => (<div key={line}>Line:{line}<br />目前:{a}<br />日本:{b}</div>)) : '無'}
                  </Grid.Column>
                </Grid.Row> : ''} */}
          </Grid.Column> : ''}
          {isShowPDF ? <Grid.Column width={showForm.indexOf('5') !== -1 ? 16 : 8}>
            <PrintPdf
              {...this.props}
              // formSet={formSet}
              // optSet={optSet}
              // dataArr={dataArr}
              codeForm={codeForm}
              height={showForm.indexOf('6') !== -1 ? "1200px" : "750px"}
            />
          </Grid.Column> : ''}
        </Grid.Row >
        {/* <Grid.Row>
          <Grid.Column sm={3}>
            <Form.Label>字體大小</Form.Label>{'  '}
            <Form.Group>
              <Form.Control as="select" placeholder="select" name="fontSize"
                      onChange={this.handleSelChange} value={this.state.fontSize}
                    >
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                    </Form.Control>
            </Form.Group>
          </Grid.Column>
        </Grid.Row> */}
      </Grid>
    );
  }
}