import React, { Component } from 'react'
import { Header, Segment, Dimmer, Loader, Image, Icon } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Moment from 'moment';

import { dateTimeCol, exportPdf, dateCol } from 'views/web/CustomMaterialTable';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import user from 'store/reducers/user';

const clearObj = {
  From: "notice@hanburyifa.com",
  to: [],
  To2: '',
  cc: [],
  bcc: [],
  subject: '',
  html: '',
  Portfolio: '',
}

const mailObj = {
  from: "notice@hanburyifa.com",
  to: '',
  cc: [],
  bcc: [],
  subject: '',
  html: '',
  Portfolio: '',
}

const objDay_off_HoursSel = {
  "0": "0",
  //  "1": "1", "2": "2", "3": "3", 
  "4": "4",
  // "5": "5", "6": "6", "7": "7",
};

const TH = [
  { text: '' },
  { text: '權限' },
  { text: '帳號' },
  { text: '電子郵件' },
  { text: '建立日期' }
]

// const objDay_off_status_first = { '0': '已拒絕', "1": "填寫中", "2": "代理人覆核", "3": '主管覆核', "4": '人資覆核', '5': '已通過', '6': '總經理覆核' };

const columns1 = () => ([
  // { title: '類別 ', field: 'type_id', lookup: { '1': 'Pru Life', '2': 'VOYA', '3': 'Colonial' } },
  { title: '年度', field: 'year', editable: 'never' },
  // { title: '有效日期（起）', field: 'start_date' },
  { title: '有效日期(迄)', field: 'end_date', editable: 'never' },
  { title: '今年特休(天)', field: 'availableDay', render: rowData => `${rowData.availableDay}天`, type: 'numeric' },
  // { title: '可用特休(時)', field: 'availableHour', render: rowData => `${rowData.availableHour}時`, lookup: objDay_off_HoursSel, },
  { title: '去年特休(天)', field: 'preavailableDay', render: rowData => `${rowData.preavailableDay}天`, type: 'numeric' },
  { title: '去年特休(時)', field: 'preavailableHour', render: rowData => `${rowData.preavailableHour}時`, lookup: objDay_off_HoursSel, },
  { title: '已用特休', field: 'used', editable: 'never', render: rowData => `已使用 ${rowData.usedSpecialDay}天 ${rowData.usedSpecialHour}時` },
  {
    title: '剩餘特休', field: 'last', editable: 'never', render: rowData => {
      let lastSpecialDay = 0
      let lastSpecialHour = 0
      lastSpecialDay = Number(rowData.availableDay) - rowData.usedSpecialDay + Number(rowData.preavailableDay)
      lastSpecialHour = Number(rowData.availableHour) - rowData.usedSpecialHour + Number(rowData.preavailableHour)
      if (lastSpecialHour < 0 && lastSpecialDay > 0) {
        lastSpecialDay -= 1
        lastSpecialHour += 8
      } else if (parseInt(lastSpecialHour / 8) > 0) {
        lastSpecialDay = lastSpecialDay + parseInt(lastSpecialHour / 8)
        lastSpecialHour = lastSpecialHour - parseInt(lastSpecialHour / 8) * 8
      }
      return `剩餘 ${lastSpecialDay}天 ${lastSpecialHour}時`
    }
  },
  { title: '已用補休', field: 'day_off', editable: 'never', render: rowData => `已使用 ${rowData.usedCompDay}天 ${rowData.usedCompHour}時` },
  { title: '剩餘補休', field: 'day_off', editable: 'never', render: rowData => `剩餘 ${rowData.addCompDay}天 ${rowData.addCompHour}時` },
]);
const columns2 = (objDay_offSel, auth_substitutegoSel, auth_supervisorgoSel, auth_hrgoSel, auth_generalmanagergoSel, objDay_off_status_first) => ([
  { title: '狀態', field: 'dayoff_status', lookup: objDay_off_status_first, editable: 'never' },
  { title: '請假類別', field: 'day_off_class', lookup: objDay_offSel },
  // {
  //   title: '開始時間', field: 'start_time', type: 'datetime',
  //   // render: rowData => (rowData.start_time && rowData.start_time.seconds && new Date(Number(rowData.start_time.seconds + '000')).toISOString()) || ''
  //   render: rowData => {
  //     if (rowData.start_time && rowData.start_time.seconds) {
  //       const d = new Date(rowData.start_time.seconds * 1000)
  //       return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
  //     }
  //     return '';
  //   }
  // },
  // { title: '結束時間', field: 'end_time', type: 'datetime' },
  // { width: 100, title: '開始時間', field: 'eventStartAt', type: 'datetime', render: rowData => console.log(rowData) },
  dateTimeCol({ title: '開始時間', field: 'eventStartAt', width: 90 }),
  dateTimeCol({ title: '結束時間', field: 'eventEndAt', width: 90 }),
  // { width: 100, title: '開始時間', field: 'eventStartAt', type: 'datetime', render: rowData => console.log(rowData) },
  // { width: 100, title: '結束時間', field: 'eventEndAt', type: 'datetime', render: rowData => Moment(rowData.eventEndAt).format('YYYY-MM-DD HH:mm:ss') },

  // {
  //   title: '請假時長', field: 'total_time', render: rowData => {
  //     let start = new Date(rowData.eventStartAt).getTime()
  //     let end = new Date(rowData.eventEndAt).getTime()
  //     // let total = end - start
  //     let hours = (end - start) / 1000 / 60 / 60
  //     let totalDay = hours / 24
  //     let totalhour = 0
  //     totalDay = parseInt(totalDay)
  //     if ((end - start - (totalDay * 24 * 60 * 60 * 1000)) > 0) {
  //       totalhour = (end - start - (totalDay * 24 * 60 * 60 * 1000)) / 1000 / 60 / 60
  //     }
  //     totalhour = parseInt(totalhour)
  //     return `${totalDay}天 ${totalhour}小時`
  //   }
  //   , editable: 'never'
  // },
  { title: '請假天數', field: 'total_hours1', type: 'numeric', render: rowData => `${rowData.total_hours1}天` },
  { title: '請假時數', field: 'total_hours2', lookup: objDay_off_HoursSel, render: rowData => `${rowData.total_hours2}時` },
  { title: '事由', field: 'reason' },
  { title: '職務代理人', field: 'substitute', lookup: auth_substitutegoSel },
  { title: '主管', field: 'supervisor', lookup: auth_supervisorgoSel },
  { title: '總經理', field: 'generalmanager', lookup: auth_generalmanagergoSel },
  { title: '人資', field: 'hr', lookup: auth_hrgoSel },
]);
const columns2_2 = (objDay_offSel2, auth_substitutegoSel, auth_supervisorgoSel, auth_hrgoSel, auth_generalmanagergoSel, objDay_off_status_first) => ([
  { title: '狀態', field: 'dayoff_status', lookup: objDay_off_status_first, editable: 'never' },
  { title: '加班/補修類別', field: 'day_off_class', lookup: objDay_offSel2 },
  // {
  //   title: '開始時間', field: 'start_time', type: 'datetime',
  //   // render: rowData => (rowData.start_time && rowData.start_time.seconds && new Date(Number(rowData.start_time.seconds + '000')).toISOString()) || ''
  //   render: rowData => {
  //     if (rowData.start_time && rowData.start_time.seconds) {
  //       const d = new Date(rowData.start_time.seconds * 1000)
  //       return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
  //     }
  //     return '';
  //   }
  // },
  // { title: '結束時間', field: 'end_time', type: 'datetime' },
  // { title: '開始時間', field: 'eventStartAt', type: 'datetime', render: rowData => Moment(rowData.eventStartAt).format('YYYY-MM-DD HH:mm:ss') },
  // { title: '結束時間', field: 'eventEndAt', type: 'datetime', render: rowData => Moment(rowData.eventEndAt).format('YYYY-MM-DD HH:mm:ss') },
  dateTimeCol({ title: '開始時間', field: 'eventStartAt', width: 90 }),
  dateTimeCol({ title: '結束時間', field: 'eventEndAt', width: 90 }),

  // {
  //   title: '請假時長', field: 'total_time', render: rowData => {
  //     let start = new Date(rowData.eventStartAt).getTime()
  //     let end = new Date(rowData.eventEndAt).getTime()
  //     // let total = end - start
  //     let hours = (end - start) / 1000 / 60 / 60
  //     let totalDay = hours / 24
  //     let totalhour = 0
  //     totalDay = parseInt(totalDay)
  //     if ((end - start - (totalDay * 24 * 60 * 60 * 1000)) > 0) {
  //       totalhour = (end - start - (totalDay * 24 * 60 * 60 * 1000)) / 1000 / 60 / 60
  //     }
  //     totalhour = parseInt(totalhour)
  //     return `${totalDay}天 ${totalhour}小時`
  //   }
  //   , editable: 'never'
  // },
  { title: '天數', field: 'total_hours1', type: 'numeric', render: rowData => `${rowData.total_hours1}天` },
  { title: '時數', field: 'total_hours2', lookup: objDay_off_HoursSel, render: rowData => `${rowData.total_hours2}時` },
  { title: '事由', field: 'reason' },
  // { title: '職務代理人', field: 'substitute', lookup: auth_substitutegoSel },
  { title: '主管', field: 'supervisor', lookup: auth_supervisorgoSel },
  { title: '總經理', field: 'generalmanager', lookup: auth_generalmanagergoSel },
  { title: '人資', field: 'hr', lookup: auth_hrgoSel },
]);
const columns3 = () => ([
  // { title: '類別 ', field: 'type_id', lookup: { '1': 'Pru Life', '2': 'VOYA', '3': 'Colonial' } },
  { title: '狀態', field: 'dayoff_status' },
  { title: '公假', field: 'public' },
  { title: '公傷病假', field: 'all' },
  { title: '事假', field: 'event' },
  { title: '病假', field: 'sick' },
  { title: '傷假', field: 'injury' },
  { title: '婚假', field: 'merry' },
  { title: '產假', field: 'maternity' },
  { title: '生理假', field: 'physiological' },
  { title: '陪產', field: 'paternity' },
  { title: '育嬰假', field: 'childcare' },
  { title: '補休假', field: 'day_off' }
]);
const columns4 = (objDay_offSel, auth_allSel_show, objDay_off_status_first) => ([
  // { title: '類別 ', field: 'type_id', lookup: { '1': 'Pru Life', '2': 'VOYA', '3': 'Colonial' } },
  { title: '狀態', field: 'dayoff_status', lookup: objDay_off_status_first, editable: 'never' },
  { title: '請假類別', field: 'day_off_class', editable: 'never', lookup: objDay_offSel },
  // { title: '開始時間', field: 'eventStartAt', type: 'datetime', render: rowData => Moment(rowData.eventStartAt).format('YYYY-MM-DD HH:mm:ss') },
  // { title: '結束時間', field: 'eventEndAt', type: 'datetime', render: rowData => Moment(rowData.eventEndAt).format('YYYY-MM-DD HH:mm:ss') },
  dateTimeCol({ title: '開始時間', field: 'eventStartAt', width: 90 }),
  dateTimeCol({ title: '結束時間', field: 'eventEndAt', width: 90 }),
  { title: '請假天數', field: 'total_hours1', editable: 'never' },
  { title: '請假時數', field: 'total_hours2', editable: 'never' },
  { title: '事由', field: 'reason', editable: 'never' },
  { title: '職務代理人', field: 'substitute', lookup: auth_allSel_show, editable: 'never' },
  { title: '主管', field: 'supervisor', lookup: auth_allSel_show, editable: 'never' },
  { title: '總經理', field: 'generalmanager', lookup: auth_allSel_show, editable: 'never' },
  { title: '人資', field: 'hr', lookup: auth_allSel_show, editable: 'never' },
]);

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

let nowYear = String(new Date().getFullYear())

export default class Hr4a extends Component {
  state = {
    loading: true,
    dataArr: [],
    dataArr1: [],
    dataArr2: [],
    dataArr3: [],
    dataArr4: [],
    dataArr5: [],
    leaveArr: [],
    applyArr: [],
    avalibleArr: [],
    historyArr: [],
    show: false,
    staffArr: [{ key: 'stafArrnull', text: '-- 選擇員工 --', value: '-1' }],
    staffVal: '-1',
    deleteConfirm: false,
    form: {},
    yearVal1: nowYear,
    yearVal2: 'all',
    activeItem: 'hr4a',
    employee: [],
    employeeObj: {},
    dayoffArr: [],
    comptimeArr: [],
    special_day_offArr: [],
    usedSpecialDay: '',
    usedSpecialHour: '',
    addCompDay: '',
    addCompHour: '',
    usedCompDay: '',
    usedCompHour: '',
    auth_substitutegoSel: {},
    auth_generalmanagergoSel: {},
    // dataArr1: [],
    // dataArr5: [],
  }

  componentDidMount() {
    this.handleFetch();
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ loading: true }, () => this.handleFetch(nextProps))
  }
  handleFetch = async (props = this.props) => {
    const { match, employeeObj, employee, yearVal1, companyObj } = props;
    let annualLeave = 0
    let preAnnualLeaveDay = 0
    let preAnnualLeaveHour = 0
    if (employeeObj && employeeObj.annualLeaveObj && employeeObj.annualLeaveObj[yearVal1]) {
      annualLeave = employeeObj.annualLeaveObj[yearVal1].annualLeave
      preAnnualLeaveDay = employeeObj.annualLeaveObj[yearVal1].preAnnualLeaveDay
      preAnnualLeaveHour = employeeObj.annualLeaveObj[yearVal1].preAnnualLeaveHour
    }
    const companyId = companyObj.objectId;
    if (employeeObj !== {} && employee.length > 0) {
      const dayoffArr = await Parse.queryData('dayoff', { companyId, year: yearVal1, employeeId: employeeObj.objectId });
      const comptimeArr = await Parse.queryData('comptime', { companyId, year: yearVal1, employeeId: employeeObj.objectId });
      const data1 = dayoffArr.filter(item => item.dayoff_status !== '5' && item.dayoff_status !== '0');
      const data5 = dayoffArr.filter(item => item.dayoff_status === '5' || item.dayoff_status === '0');
      const data6 = comptimeArr.filter(item => item.dayoff_status !== '5' && item.dayoff_status !== '0');
      const data7 = comptimeArr.filter(item => item.dayoff_status === '5' || item.dayoff_status === '0');
      const dataAll = data5.concat(data7)
      const dataArr5 = dataAll.sort((a, b) => new Date(b.eventStartAt.iso) - new Date(a.eventStartAt.iso))
      // 
      this.setState({ dataArr1: data1, dataArr5, dayoffArr, comptimeArr, dataArr3: data6 })

      const auth_substitutegoArr = employee.filter(item => item.auth_substitutego === '1' && item.authLoginA && item.authLoginA.indexOf(companyObj.objectId) !== -1); // 代理人權限
      const auth_supervisorgoArr = employee.filter(item => item.auth_supervisorgo === '1' && item.authLoginA && item.authLoginA.indexOf(companyObj.objectId) !== -1); // 放行主管權限
      const auth_generalmanagergoArr = employee.filter(item => item.auth_generalmanagergo === '1' && item.authLoginA && item.authLoginA.indexOf(companyObj.objectId) !== -1); // 放行總經理權限
      const auth_hrgoArr = employee.filter(item => item.auth_hrgo === '1' && item.authLoginA && item.authLoginA.indexOf(companyObj.objectId) !== -1); // 放行人資權限

      let auth_substitutegoSel = {};
      auth_substitutegoArr.forEach(item => {
        if (item.objectId === employeeObj.objectId) {
          return;
        } else {
          auth_substitutegoSel = { ...auth_substitutegoSel, [item.objectId]: item.name }
        }
      });
      let auth_supervisorgoSel = {};
      auth_supervisorgoArr.forEach(item => {
        if (item.objectId === employeeObj.objectId) {
          return;
        } else {
          auth_supervisorgoSel = { ...auth_supervisorgoSel, [item.objectId]: item.name }
        }
      });
      let auth_generalmanagergoSel = {};
      auth_generalmanagergoArr.forEach(item => {
        if (item.objectId === employeeObj.objectId) {
          return;
        } else {
          auth_generalmanagergoSel = { ...auth_generalmanagergoSel, [item.objectId]: item.name }
        }
      });
      let auth_hrgoSel = {};
      auth_hrgoArr.forEach(item => {
        if (item.objectId === employeeObj.objectId) {
          return;
        } else {
          auth_hrgoSel = { ...auth_hrgoSel, [item.objectId]: item.name }
        }
      });
      let auth_allSel_show = {
        'none': '無',
        '9PyvxCkuww': '莊隆霖',
        'gq4vBuXvn5': '盧琟蓁',
        'ao4wgmJnNk': '李采亭',
        'eJYTQxLo4H': '楊仕豪',
        'zAfFJD62Yo': '陳怡帆',
        'XHpE9ouyw6': '鄧智陽',
        'sL2RLa7i2y': '林濬萱',
        'KQNft7RR4u': '秦國芳',
        'S35ofJfB3b': '鍾嘉莉',
        'Qq6om8JEYZ': '江岱瑾',
        'b4z6cmGTSi': '方偉丞',
        'iHfBDNnbCv': '張語樵',
      }
      employee.forEach(item => auth_allSel_show = { ...auth_allSel_show, [item.objectId]: item.name })
      this.setState({ auth_substitutegoSel, auth_supervisorgoSel, auth_generalmanagergoSel, auth_hrgoSel, auth_allSel_show });

      let special_day_offArr = []
      const special_dayoffdataArr = dayoffArr.filter(item => item.dayoff_status === '5' && item.day_off_class === '10');
      const comptimeAddArr = comptimeArr.filter(item => item.dayoff_status === '5' && item.day_off_class === '14');
      const comptimeUsedArr = dayoffArr.filter(item => item.dayoff_status === '5' && item.day_off_class === '12');
      let usedSpecialDay = 0
      let usedSpecialHour = 0
      let addCompDay = 0
      let addCompHour = 0
      let usedCompDay = 0
      let usedCompHour = 0
      let lastSpecialDay = 0
      let lastSpecialHour = 0
      special_dayoffdataArr.forEach(item => {
        usedSpecialDay = usedSpecialDay + Number(item.total_hours1)
        usedSpecialHour = usedSpecialHour + Number(item.total_hours2)
        if (parseInt(usedSpecialHour / 8) > 0) {
          usedSpecialDay = usedSpecialDay + parseInt(usedSpecialHour / 8)
          usedSpecialHour = usedSpecialHour - parseInt(usedSpecialHour / 8) * 8
        }
      })
      comptimeAddArr.forEach(item => {
        addCompDay = addCompDay + Number(item.total_hours1)
        addCompHour = addCompHour + Number(item.total_hours2)
        if (parseInt(addCompHour / 8) > 0) {
          addCompDay = addCompDay + parseInt(addCompHour / 8)
          addCompHour = addCompHour - parseInt(addCompHour / 8) * 8
        }
      })
      comptimeUsedArr.forEach(item => {
        usedCompDay = usedCompDay + Number(item.total_hours1)
        usedCompHour = usedCompHour + Number(item.total_hours2)
        if (parseInt(usedCompHour / 8) > 0) {
          usedCompDay = usedCompDay + parseInt(usedCompHour / 8)
          usedCompHour = usedCompHour - parseInt(usedCompHour / 8) * 8
        }
      })
      addCompDay = addCompDay - usedCompDay
      addCompHour = addCompHour - usedCompHour
      if (addCompHour < 0 && addCompDay > 0) {
        addCompDay -= 1
        addCompHour += 8
      }


      ///  以下處理特休假

      let special_day_offObj = {}
      let availableDay = Number(annualLeave) || '0'
      let availableHour = 0
      lastSpecialDay = availableDay - usedSpecialDay
      lastSpecialHour = availableHour - usedSpecialHour
      if (lastSpecialHour < 0 && lastSpecialDay > 0) {
        lastSpecialDay -= 1
        lastSpecialHour += 8
      }

      let preObj = {
        preavailableDay: Number(preAnnualLeaveDay) || '0',
        preavailableHour: Number(preAnnualLeaveHour) || '0',
      }

      special_day_offObj = {
        // ...item,
        ...preObj,
        availableDay,
        availableHour,
        usedSpecialDay,
        usedSpecialHour,
        addCompDay,
        addCompHour,
        usedCompDay,
        usedCompHour,
        year: yearVal1,
        end_date: `${yearVal1}/12/31`,
        // availableHour: item.availableHour || '0',
        // preavailableDay: item.preavailableDay || '0',
        // preavailableHour: item.preavailableHour || '0',
      }
      // }
      special_day_offArr.push(special_day_offObj)
      this.setState({ special_day_offArr, })
      //  usedSpecialDay: String(usedSpecialDay), usedSpecialHour: String(usedSpecialHour), addCompDay: String(addCompDay), addCompHour: String(addCompHour), usedCompDay: String(usedCompDay), usedCompHour: String(usedCompHour) })
      this.setState({ loading: false })
    }

  }

  handleAdd = async () => {
    const { employee, companyObj, employeeObj } = this.props
    const { newData } = this.state
    if (newData.flag === 1) {
      const obj = {
        ...newData,
      };
      await Parse.saveData('dayoff', obj);
    } else if (newData.flag === 2) {
      const obj = {
        ...newData,
      };
      await Parse.saveData('comptime', obj);
    }
    this.setState({ newData: {}, loading: false });
  }

  handleUpdate = async () => {
    // const { employeeObj, companyObj, employee } = this.props
    const { newData } = this.state
    let obj = {}
    if (newData.flag === 1) {
      // if (newData.eventStartAt === '' || newData.eventEndAt === '') {
      obj = {
        ...newData,
        eventStartAt: (newData.eventStartAt && new Date(newData.eventStartAt.iso || newData.eventStartAt)) || new Date(),
        eventEndAt: (newData.eventEndAt && new Date(newData.eventEndAt.iso || newData.eventEndAt)) || new Date(),
        // eventStartAt: (newData.eventStartAt !== '' && newData.eventStartAt !== null && typeof newData.eventStartAt === 'object' && newData.eventStartAt.toISOString()) || (newData.eventStartAt !== '' && newData.eventStartAt !== null && typeof newData.eventStartAt === 'string' && newData.eventStartAt) || '',
        // eventEndAt: (newData.eventEndAt !== '' && newData.eventEndAt !== null && typeof newData.eventEndAt === 'object' && newData.eventEndAt.toISOString()) || (newData.eventEndAt !== '' && newData.eventEndAt !== null && typeof newData.eventEndAt === 'string' && newData.eventEndAt) || '',
      }
      // } else {
      //   obj = {
      //     ...newData,
      //     eventStartAt: newData.eventStartAt && typeof newData.eventStartAt === "string" ? newData.eventStartAt : newData.eventStartAt.toISOString(),
      //     eventEndAt: newData.eventEndAt && typeof newData.eventEndAt === "string" ? newData.eventEndAt : newData.eventEndAt.toISOString(),
      //   };
      // }
      // const docRef = db.collection(`users/${employeeObj._id}/dayoff`).doc(obj.id);
      // await docRef.update(obj);
      await Parse.saveData('dayoff', obj);
    } else if (newData.flag === 2) {
      console.log(newData)
      // if (newData.eventStartAt === '' || newData.eventEndAt === '') {
      obj = {
        ...newData,
        eventStartAt: (newData.eventStartAt && new Date(newData.eventStartAt.iso || newData.eventStartAt)) || new Date(),
        eventEndAt: (newData.eventEndAt && new Date(newData.eventEndAt.iso || newData.eventEndAt)) || new Date(),
        // eventStartAt: (newData.eventStartAt !== '' && newData.eventStartAt !== null && typeof newData.eventStartAt === 'object' && newData.eventStartAt.toISOString()) || (newData.eventStartAt !== '' && newData.eventStartAt !== null && typeof newData.eventStartAt === 'string' && newData.eventStartAt) || '',
        // eventEndAt: (newData.eventEndAt !== '' && newData.eventEndAt !== null && typeof newData.eventEndAt === 'object' && newData.eventEndAt.toISOString()) || (newData.eventEndAt !== '' && newData.eventEndAt !== null && typeof newData.eventEndAt === 'string' && newData.eventEndAt) || '',
      }
      // } else {
      //   obj = {
      //     ...newData,
      //     eventStartAt: newData.eventStartAt && typeof newData.eventStartAt === "string" ? newData.eventStartAt : newData.eventStartAt.toISOString(),
      //     eventEndAt: newData.eventEndAt && typeof newData.eventEndAt === "string" ? newData.eventEndAt : newData.eventEndAt.toISOString(),
      //   };
      // }
      // const docRef = db.collection(`users/${employeeObj._id}/comptime`).doc(obj.id);
      // await docRef.update(obj);
      await Parse.saveData('comptime', obj);
    }
    this.setState({ oldData: {}, newData: {} })
  }

  handleDelete = async () => {
    const { employeeObj, companyObj, employee } = this.props
    const { oldData } = this.state
    // const db = firebase.firestore()
    if (oldData.flag === 1) {
      // await db.collection(`users/${employeeObj._id}/dayoff`).doc(oldData.id).delete();
      await Parse.deleteData('dayoff', oldData);
    } else if (oldData.flag === 2) {
      await Parse.deleteData('comptime', oldData);
      // await db.collection(`users/${employeeObj._id}/comptime`).doc(oldData.id).delete();
    }
    this.setState({ oldData: {} })
  }

  handleSend = async (rowData) => {
    const { employeeObj, objDay_off_status_first, employee, companyObj } = this.props
    const { dataArr1, dataArr3, special_day_offArr } = this.state
    const tableData = { editing: '' }
    let special_day = special_day_offArr[0]
    if (rowData.day_off_class === '10' && (Number(special_day.availableDay) - Number(rowData.total_hours1)) < 0 && (Number(special_day.availableHour) - Number(rowData.total_hours2)) + ((Number(special_day.availableDay) - Number(rowData.total_hours1)) * 8) < 0) {
      window.alert('剩餘特休時間不足')
    } else if (rowData.day_off_class === '12' && (Number(special_day.addCompDay) - Number(rowData.total_hours1)) < 0 && ((Number(special_day.addCompHour) - Number(rowData.total_hours2)) + (Number(special_day.addCompDay) - Number(rowData.total_hours1)) * 8) < 0) {
      window.alert('剩餘補休時間不足')
    } else {
      if ((rowData.day_off_class && rowData.eventStartAt && rowData.eventEndAt && (rowData.total_hours1 > 0 || rowData.total_hours2 > 0) && rowData.substitute && rowData.supervisor && rowData.generalmanager && rowData.hr && rowData.flag === 1) || (rowData.day_off_class && rowData.eventStartAt && rowData.eventEndAt && (rowData.total_hours1 > 0 || rowData.total_hours2 > 0) && rowData.supervisor && rowData.generalmanager && rowData.hr && rowData.flag === 2)) {
        let obj = {}
        if (rowData.day_off_class !== '13' && rowData.day_off_class !== '14' && rowData.flag === 1) {
          obj = {
            ...rowData,
            eventStartAt: (rowData.eventStartAt && new Date(rowData.eventStartAt.iso || rowData.eventStartAt)) || new Date(),
            eventEndAt: (rowData.eventEndAt && new Date(rowData.eventEndAt.iso || rowData.eventEndAt)) || new Date(),
            dayoff_status: '2',
            dayoff_statusText: objDay_off_status_first[2] || '',
            tableData,
          }
        } else if ((rowData.day_off_class === '13' || rowData.day_off_class === '14') && rowData.flag === 2) {
          // if (rowData.day_off_class === '13' && rowData.substitute !== 'none') {
          //   window.alert('"補修申請" 不需要代理人，已更改為 "無"')
          // }
          obj = {
            ...rowData,
            eventStartAt: (rowData.eventStartAt && new Date(rowData.eventStartAt.iso || rowData.eventStartAt)) || new Date(),
            eventEndAt: (rowData.eventEndAt && new Date(rowData.eventEndAt.iso || rowData.eventEndAt)) || new Date(),
            dayoff_status: '3',
            dayoff_statusText: objDay_off_status_first[3] || '',
            substitute: 'none',
            substituteText: '無',
            tableData,
          }
        }
        if (obj.flag === 1) {
          const data = [...dataArr1];
          const index = data.indexOf(rowData);
          data[index] = obj
          await Parse.saveData('dayoff', obj);
          let employeeName = employee.find(item => item.objectId === rowData.employeeId).name
          if (rowData.substitute !== undefined && rowData.substitute !== '') {
            const db = Parse.Object.extend("email");
            const newDoc = new db();
            const docRef = await newDoc.save();
            let emailObj = {
              ...mailObj,
              id: docRef.id,
              _id: docRef.id,
              objectId: docRef.id,
              to: employee.find(item => item.objectId === rowData.substitute).email,
              companyId: companyObj.objectId || '',
              companyName: companyObj.name || '',
              subject: '[請假審核] 代理人審核',
              html: `<img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/rY66GsLbHnTm9Tvrx-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Header.png" /><br /><br /><br />申請人：${employeeName}<br /><br />申請假別 ${rowData.day_off_classText}，<br />於${Moment(rowData.eventStartAt).format('YYYY-MM-DD HH:mm:ss')}~${Moment(rowData.eventEndAt).format('YYYY-MM-DD HH:mm:ss')}，共${rowData.total_hours1}天${rowData.total_hours2}時。<br /><br />請代理人至系統簽核查收，謝謝您<br />https://system.hanburyifa.com/auth/login
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/yKNhmTQoRepiYtRSj-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Footer.png" />`,
              To_name_cht: employee.find(item => item.objectId === rowData.substitute).name || employee.find(item => item.objectId === rowData.substitute).email,
              status: "99",
              status_text: "未寄出",
              type: 'dayoff',
            }
            await Parse.sendmail(emailObj).then(async (e) => {
              const nextObj = {
                ...emailObj,
                status: "2",
                status_text: "已寄出",
              }
              await Parse.saveData('email', nextObj);
            })
          }
          this.setState({ dataArr1: data })
        } else if (obj.flag === 2) {
          const data = [...dataArr3];
          const index = data.indexOf(rowData);
          data[index] = obj
          // const docRef = db.collection(`users/${employeeObj._id}/comptime`).doc(obj.id);
          // await docRef.update(obj);
          await Parse.saveData('comptime', obj);
          let employeeName = employee.find(item => item.objectId === rowData.employeeId).name
          if (rowData.supervisor !== undefined && rowData.supervisor !== '') {
            // const docRef = db.collection(`company/${companyObj._id}/bg/${employeeObj.bgId}/sendEmail`).doc()
            const db = Parse.Object.extend("email");
            const newDoc = new db();
            const docRef = await newDoc.save();
            let emailObj = {
              ...mailObj,
              id: docRef.id,
              _id: docRef.id,
              to: employee.find(item => item.objectId === rowData.supervisor).email,
              companyId: companyObj.objectId || '',
              companyName: companyObj.name || '',
              subject: '[補休/加班 審核] 主管審核',
              html: `<img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/rY66GsLbHnTm9Tvrx-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Header.png" /><br /><br /><br />申請人：${employeeName}<br /><br />申請加班/補修 ${rowData.day_off_classText}，<br />於${Moment(rowData.eventStartAt).format('YYYY-MM-DD HH:mm:ss')}~${Moment(rowData.eventEndAt).format('YYYY-MM-DD HH:mm:ss')}，共${rowData.total_hours1}天${rowData.total_hours2}時。<br /><br />請主管至系統簽核查收，謝謝您<br />https://system.hanburyifa.com/auth/login
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/yKNhmTQoRepiYtRSj-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Footer.png" />`,
              To_name_cht: employee.find(item => item.objectId === rowData.supervisor).name || employee.find(item => item.objectId === rowData.supervisor).email,
              status: "99",
              status_text: "未寄出",
              type: 'dayoff',
            }
            console.log(emailObj)
            await Parse.sendmail(emailObj).then(async (e) => {
              const nextObj = {
                ...emailObj,
                status: "2",
                status_text: "已寄出",
              }
              await Parse.saveData('email', nextObj);
            })
          }
          this.setState({ dataArr3: data })
        }
        window.alert('申請,申請信件已寄出')
      } else if (Number(rowData.total_hours1) < 0) {
        window.alert('天數不可為負數')
      } else {
        if (rowData.total_hours1 > 0 || rowData.total_hours2 > 0) {
          window.alert(`請假時數最少為4小時`)
        }
        window.alert(`有欄位尚未填寫`)
      }
    }
  }

  render() {
    const { objDay_offSel, employeeObj, objDay_off_status_first, yearVal1, objDay_offSel2, companyObj, employee, auth } = this.props
    const { dayoffArr, auth_substitutegoSel, auth_supervisorgoSel, auth_hrgoSel, auth_generalmanagergoSel, loading, auth_allSel_show,
      special_day_offArr } = this.state;
    // const { bg } = 
    const { dataArr, dataArr1, dataArr2, dataArr3, dataArr5 } = this.state;
    let objDay_offSel_copy = {
      ...objDay_offSel,
      ...objDay_offSel2,
    }
    // const objDay_offSelAll = Object.assign(objDay_offSel_copy, objDay_offSel2)
    const editableB = {
      isEditHidden: rowData => rowData.dayoff_status !== '1',
      isDeleteHidden: rowData => rowData.dayoff_status !== '1',
      onRowAdd: newData =>
        new Promise(async (resolve, reject) => {
          const db = Parse.Object.extend("dayoff");
          const newDoc = new db();
          const docRef = await newDoc.save();
          const obj = {
            id: docRef.id,
            _id: docRef.id,
            objectId: docRef.id,
            companyId: companyObj.objectId || '',
            companyName: companyObj.name || '',
            employeeId: employeeObj.objectId || '',
            employeeText: employeeObj.name || '',
            employeeEmail: employeeObj.email || '',
            total_hours1: newData.total_hours1 || 0,
            total_hours2: newData.total_hours2 || '0',
            total_hours: (Number(newData.total_hours1 || 0) * 8 + Number(newData.total_hours2 || 0)) + '',
            dayoff_status: '1',
            dayoff_statusText: objDay_off_status_first[1],
            day_off_classText: (newData.day_off_class && objDay_offSel[newData.day_off_class]) || '',
            substituteText: (newData.substitute && auth_substitutegoSel[newData.substitute]) || '',
            supervisorText: (newData.supervisor && auth_supervisorgoSel[newData.supervisor]) || '',
            generalmanagerText: (newData.generalmanager && auth_generalmanagergoSel[newData.generalmanager]) || '',
            hrText: (newData.hr && auth_hrgoSel[newData.hr]) || '',
            ps: '',
            year: yearVal1,
            flag: 1,
            ...newData,
          };
          dataArr1.push(obj);
          this.setState({ newData: obj, dayoffArr, loading: true }, () => resolve());
        }).then(() => this.handleAdd()),
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          const data = [...dataArr1];
          const index = data.indexOf(oldData);
          newData.total_hours = (Number(newData.total_hours1) * 8 + Number(newData.total_hours2)) + '';
          newData.dayoff_statusText = (newData.dayoff_status && objDay_off_status_first[newData.dayoff_status]) || '';
          newData.day_off_classText = (newData.day_off_class && objDay_offSel[newData.day_off_class]) || '';
          newData.substituteText = (newData.substitute && auth_substitutegoSel[newData.substitute]) || '';
          newData.supervisorText = (newData.supervisor && auth_supervisorgoSel[newData.supervisor]) || '';
          newData.generalmanagerText = (newData.generalmanager && auth_generalmanagergoSel[newData.generalmanager]) || '';
          newData.hrText = (newData.hr && auth_hrgoSel[newData.hr]) || '';
          newData.ps = '';
          data[index] = newData;
          this.setState({ dataArr1: data, newData, oldData }, () => resolve());
        }).then(() => this.handleUpdate()),
      onRowDelete: oldData =>
        new Promise((resolve, reject) => {
          let data = dataArr1;
          const index = data.indexOf(oldData);
          data.splice(index, 1);
          this.setState({ oldData }, () => resolve());
        }).then(() => this.handleDelete()),
    }

    const editableC = {
      isEditHidden: rowData => rowData.dayoff_status !== '1',
      isDeleteHidden: rowData => rowData.dayoff_status !== '1',
      onRowAdd: newData =>
        new Promise(async (resolve, reject) => {
          const db = Parse.Object.extend("comptime");
          const newDoc = new db();
          const docRef = await newDoc.save();
          const obj = {
            id: docRef.id,
            _id: docRef.id,
            companyId: companyObj.objectId || '',
            companyName: companyObj.name || '',
            employeeId: employeeObj.objectId || '',
            employeeText: employeeObj.name || '',
            employeeEmail: employeeObj.email || '',
            total_hours1: newData.total_hours1 || 0,
            total_hours2: newData.total_hours2 || '0',
            total_hours: (Number(newData.total_hours1 || 0) * 8 + Number(newData.total_hours2 || 0)) + '',
            dayoff_status: '1',
            dayoff_statusText: objDay_off_status_first[1],
            day_off_classText: (newData.day_off_class && objDay_offSel2[newData.day_off_class]) || '',
            substituteText: (newData.substitute && auth_substitutegoSel[newData.substitute]) || '',
            supervisorText: (newData.supervisor && auth_supervisorgoSel[newData.supervisor]) || '',
            generalmanagerText: (newData.generalmanager && auth_generalmanagergoSel[newData.generalmanager]) || '',
            hrText: (newData.hr && auth_hrgoSel[newData.hr]) || '',
            ps: '',
            year: yearVal1,
            flag: 2,
            ...newData,
          };
          dataArr3.push(obj);                // this.handleAdd(obj)
          this.setState({ newData: obj, loading: true }, () => resolve());
        }).then(() => this.handleAdd()),
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          const data = [...dataArr3];
          const index = data.indexOf(oldData);
          newData.total_hours = (Number(newData.total_hours1) * 8 + Number(newData.total_hours2)) + '';
          newData.dayoff_statusText = (newData.dayoff_status && objDay_off_status_first[newData.dayoff_status]) || '';
          newData.day_off_classText = (newData.day_off_class && objDay_offSel2[newData.day_off_class]) || '';
          newData.substituteText = (newData.substitute && auth_substitutegoSel[newData.substitute]) || '';
          newData.supervisorText = (newData.supervisor && auth_supervisorgoSel[newData.supervisor]) || '';
          newData.generalmanagerText = (newData.generalmanager && auth_generalmanagergoSel[newData.generalmanager]) || '';
          newData.hrText = (newData.hr && auth_hrgoSel[newData.hr]) || '';
          newData.ps = '';
          data[index] = newData;
          this.setState({ dataArr3: data, newData, oldData, }, () => resolve());
        }).then(() => this.handleUpdate()),
      onRowDelete: oldData =>
        new Promise((resolve, reject) => {
          const data = [...dataArr3];
          const index = data.indexOf(oldData);
          data.splice(index, 1);
          // handleState('people0', data);
          this.setState({ oldData }, () => resolve());
        }).then(() => this.handleDelete()),
    }

    // if (auth.edit === false) {
    //   delete editableB.onRowAdd
    //   delete editableC.onRowAdd
    // }

    return (<>
      <MaterialTable
        // isLoading={loading}
        localization={localization()}
        tableRef={this.tableRef}
        columns={columns1()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data, 'a. 個人特休/補休天數'),
          pageSize: special_day_offArr.length || 1,
          search: false,
          // toolbar: false,
          paging: false,
        }}
        data={special_day_offArr}
        title="a. 個人特休/補休天數"
      // editable={{
      //   onRowUpdate: (newData, oldData) =>
      //     new Promise((resolve, reject) => {
      //       const data = special_day_offArr;
      //       const index = data.indexOf(oldData);
      //       data[index] = newData;
      //       // handleState('people0', data);
      //       this.setState({ special_day_offArr: data, newData, oldData, loading: true }, () => resolve());
      //     }).then(() => this.handleSpecialUpdate()),
      // }}
      />
      <br />
      <br />
      {/* <Header as='h3' content='b. 請假申請' style={{ margin: 0 }} /> */}
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        tableRef={this.tableRef}
        columns={columns2(objDay_offSel, auth_substitutegoSel, auth_supervisorgoSel, auth_hrgoSel, auth_generalmanagergoSel, objDay_off_status_first)}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data, 'b. 請假申請'),
          pageSize: dataArr1.length || 5,
          search: false,
          // toolbar: false
          // showFirstLastPageButtons: true
        }}
        actions={[
          rowData => ({
            icon: 'send',
            tooltip: '送出申請',
            hidden: rowData.dayoff_status !== '1',
            onClick: (event, rowData) => window.confirm('確定送出申請？') ? this.handleSend(rowData) : null,
          }),
        ]}
        // actions={[ 
        // {  icon: 'save', 
        //      tooltip: 'Save User',
        //      onClick: (event, rowData) => alert("You saved " + rowData.name) 
        //      },
        //  rowData => ({ 
        //            icon: 'delete', tooltip: 'Delete User', 
        //             onClick: (event, rowData) => confirm("You want to delete " + rowData.name), disabled: rowData.birthYear < 2000 
        //              })
        //  ]}
        data={dataArr1}
        title="b. 請假申請"
        editable={editableB}
      />
      <br />
      <br />
      {/* <Header as='h3' content='c. 加班/補修申請' style={{ margin: 0 }} /> */}
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        tableRef={this.tableRef}
        columns={columns2_2(objDay_offSel2, auth_substitutegoSel, auth_supervisorgoSel, auth_hrgoSel, auth_generalmanagergoSel, objDay_off_status_first)}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data, 'c. 加班/補修申請'),
          pageSize: dataArr3.length || 5,
          search: false,
          // toolbar: false
          // showFirstLastPageButtons: true
        }}
        actions={[
          rowData => ({
            icon: 'send',
            tooltip: '送出申請',
            hidden: rowData.dayoff_status !== '1',
            onClick: (event, rowData) => window.confirm('確定送出申請？') ? this.handleSend(rowData) : null,
          }),
        ]}
        data={dataArr3}
        title="c. 加班/補修申請"
        editable={editableC}
      />
      <br />
      <br />
      {/* <Header as='h3' content='d. 可用假別一覽' style={{ margin: 0 }} />
      <MaterialTable
          isLoading={loading}
          localization={localization()}
          tableRef={this.tableRef}
          columns={columns3()}
          options={{
            addRowPosition: "first",
exportButton: true,
            pageSize: dataArr2.length || 1,
            search: false,
            toolbar: false,
            paging: false,
            // showFirstLastPageButtons: true
          }}
          // actions={[
          //   {
          //     icon: 'view_headline',
          //     tooltip: '查看',
          //     onClick: (event, rowData) => this.handleData(rowData)
          //   }
          // ]}
          data={dataArr2}
          title="d. 可用假別一覽"
          editable={{
            // onRowAdd: newData =>
            //   new Promise((resolve, reject) => {
            //     const db = firebase.firestore();
            //     const docRef = db.collection(`company/${companyObj._id}/workdays`).doc();
            //     const obj = {
            //       id: docRef.id,
            //       _id: docRef.id,
            //       ...newData,
            //     };
            //     const data = [...dataArr];
            //     data.push(obj);
            //     // handleState('people0', data);
            //     this.setState({ dataArr: data, newData: obj }, () => resolve());
            //   }).then(() => this.handleAdd(0, 0)),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const data = [...dataArr2];
                const index = data.indexOf(oldData);
                data[index] = newData;
                // handleState('people0', data);
                this.setState({ dataArr2: data, newData, oldData }, () => resolve());
              }).then(() => this.handleUpdate(0)),
            // onRowDelete: oldData =>
            //   new Promise((resolve, reject) => {
            //     let data = [...dataArr];
            //     const index = data.indexOf(oldData);
            //     data.splice(index, 1);
            //     // handleState('people0', data);
            //     this.setState({ dataArr: data, oldData }, () => resolve());
            //   }).then(() => this.handleDelete(0)),
          }}
        />}
      <br />
      <br /> */}
      {/* <Header as='h3' content='e. 歷年請假紀錄' style={{ margin: 0 }} /> */}
      {/* {loading ? <DimmerImg /> : */}
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        tableRef={this.tableRef}
        columns={columns4(objDay_offSel_copy, auth_allSel_show, objDay_off_status_first)}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data, 'd. 請假紀錄'),
          pageSize: dataArr5.length || 5,
          search: false,
        }}
        data={dataArr5}
        title="d. 請假紀錄"
      />
      <br />
      <br />
    </>)
  }
}