
/*eslint-disable*/
import React, { Component } from 'react'
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";
// //import 'assets/css/paper-dashboard.min.scoped.css'
// import css from 'assets/css/paper-dashboard.min.module.css';

class Footer extends Component {
  render() {
    return (<>
      {/* <style jsx>{`${css}`}</style> */}
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <Row>
            <nav className="footer-nav">
              <ul>
                <li>
                  <a href="#">About</a>
                </li>
                <li>
                  <a href="#">Blog</a>
                </li>
                <li>
                  <a
                    href="#"
                    target="_blank"
                  >
                    Licenses
                  </a>
                </li>
              </ul>
            </nav>
            <div className="credits ml-auto">
              <span className="copyright">
                &copy; {1900 + new Date().getYear()}, made with{" "}
                <i className="fa fa-heart heart" /> by Infowin Technology Co., Ltd.
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    </>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
