import React, { Component, createRef } from 'react'
import { Button, Form, Icon, Image, Container, Segment, Header, Input, Label, Select, Ref, Grid, Sticky, Modal, Progress } from 'semantic-ui-react';

import { getLabelName2, getOptionsSelByKey, getOptionTextByValue } from 'views/widgets/FieldsRender';
import { Col, Row } from 'reactstrap'
import { selType, statusArr, semesterArr } from '../../../widget/define/define'
import ListTabs from './ListTabs';
import Parse from '../../../widget/parse'

// import { cjkConv } from '../../../modules/cjk-convert';
// import cjkConv from 'cjk-conv';
// import { cjk2jp } from 'cjk-conv';
// const cjkConv = require("cjk-conv");

export default class StudentPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false, // props.isEdit,
      isLock: true,
      doc: {}, // 原本資料
      jpForm: {},
      selTypeId: props.match.params.list || '1',
      students: [],
      optStudents: [],
      resStudents: [],
      extStudents: [],
      loading: false,
      // classroom: [],
      filterForm: {
        searchText: '',
        selClass: '0',
        year: props.match.params.list === '2' ? props.semesterYear :
          props.semester === 'A' ?
            `${Number(props.semesterYear) + 1}` : props.semesterYear,
        semes: props.match.params.list === '1' ? props.semester === 'A' ?
          'S' : 'A' : props.semester,
        yearsemester: props.match.params.list === '2' ? `${props.semesterYear},${props.semester}` :
          props.semester === 'A' ?
            `${Number(props.semesterYear) + 1},S` : `${props.semesterYear},A`,
        applyResult: props.match.params.list === '1' ? '-1' : '0',
        studentStatus3: '0'
      },
    };
    this.studentLock = this.studentLock.bind(this);
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { match, history, companyObj } = props
    const { list } = match.params;
    const { isEdit, extStudents, year, semes, filterForm } = this.state;

    const data = await Parse.queryData('Students', { companyId: companyObj.objectId });
    this.setState({ students: data });
    if (!isEdit) {
      const { match } = props;
      const { students } = this.state
      const { _id } = match.params;
      let obj = {};
      if (_id) {
        obj = students.find(item => item.objectId === _id) || {};
        // } else {
        //   obj = students[0];
        //   history.push(`/StudentPage/${list}/${students[0]._id}`);
      }

      // this.setState({ doc: obj, form: obj, filterForm: { ...filterForm, selClass: '0' }, selTypeId: list }, this.studentSelect(props));
      this.setState({ doc: obj, selTypeId: list }, this.studentSelect(props));
    }
  }

  studentSelect = (props = this.props) => {
    const { match } = props;
    const { list } = match.params;
    const { extStudents, filterForm, students } = this.state;
    const { year, semes, applyResult, selClass, studentStatus3 } = filterForm;

    let currentStudents = students.concat(extStudents).sort((a, b) => a.semesterYear - b.semesterYear);//studentsList.sort((a, b) => a.semester - b.semester).sort((a, b) => a.semesterYear - b.semesterYear);
    let optStudents; // 沒有搜尋時的選單
    let resStudents; // 有加搜尋的東西 resStudents

    if (list === "1") { // 募集
      let tmp = [];
      tmp = currentStudents.sort((a, b) => ('' + a.recruitNum).localeCompare(b.recruitNum))
        // tmp = currentStudents.sort((a, b) => ('' + a.studentId).localeCompare(b.studentId))
        .filter(item => item.semesterYear === year && item.semester === semes)
      //  && item.studentType === '1'); // 在籍生&& item.studentType === '1' 聽講生不用募集
      if (applyResult === '-1') { // applyResult 0 全部，-1 未定
        tmp = tmp.filter(item => !item.applyResult || item.applyResult === '-1'); // 在籍生
      } else if (applyResult === '1' || applyResult === '2') {
        tmp = tmp.filter(item => item.applyResult === applyResult); // 在籍生
      }

      optStudents = tmp.map(item => ({
        // [${item.studentStatusText && item.studentStatusText.substr(0, 2)}] 
        key: item.objectId,
        text: `[${!!item.applyResult ? (item.applyResultText && item.applyResultText.substr(0, 2)) || '' : '未定'}] ${item.recruitNum || "----"} 学${item.studentId ? `${item.semesterYear.substr(-2)}(${item.semester})${item.studentId}` : '----'} ${item.jpnName}`,
        value: item.objectId, classroom: item.classSchool, semester: item.semester, semesterYear: item.semesterYear, studentId: item.studentId
      }));
      // console.log(optStudents)
      // currentStudents = currentStudents.map(item => ({ ...item, text: `${item.recruitNum || "(なし)"} 学${item.studentId ? `${item.semesterYear.substr(-2)}(${item.semester})${item.studentId}` : '(なし)'} ${item.jpnName}` }));
    } else if (list === '2') { // 在籍
      let tmp = currentStudents.sort((a, b) => ('' + a.studentId).localeCompare(b.studentId)).filter(item =>
        (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
        (item.applyResult === '1' || item.studentType === "2") //在學 || 聽講
      )

      if (selClass && selClass !== '0' && selClass !== "-1") {
        tmp = tmp.filter(item => item.classSchool === selClass); // 在籍生
      } else if (selClass === "-1") {
        // console.log('-1')
        tmp = tmp.filter(item => !item.classSchool); // 找出所有還沒編班的
      }

      optStudents = tmp.map(item => ({
        key: item.objectId,
        text: `[${item.classSchool || '-'}] ${item.studentId ? `${item.semesterYear.substr(-2)}(${item.semester})${item.studentId}` : '----'} ${item.jpnName}`,
        value: item.objectId, classroom: item.classSchool, semester: item.semester, semesterYear: item.semesterYear, studentId: item.studentId
      }));
    } else if (list === '3') {
      let tmp = currentStudents
        .sort((a, b) => ('' + a.studentId).localeCompare(b.studentId))
        .filter(item =>
          item.semesterYear === year && item.semester === semes &&
          (item.studentStatus === '50' || item.studentStatus === '51' || item.studentStatus === '31' || item.studentStatus === '32' || item.studentStatus === '0')
        )

      if (selClass && selClass !== '0' && selClass !== "-1") {
        tmp = tmp.filter(item => item.classSchool === selClass); // 在籍生
      } else if (selClass === "-1") {
        // console.log('-1')
        tmp = tmp.filter(item => !item.classSchool); // 找出所有還沒編班的
      }

      if (studentStatus3 !== '0') {
        tmp = tmp.filter(item => item.studentStatus === studentStatus3);
      }

      optStudents = tmp.map(item => ({
        key: item.objectId,
        text: `[${item.studentStatusText.substr(0, 2) || ''}] [${item.classSchool || '-'}] ${item.studentId ? `${item.semesterYear.substr(-2)}(${item.semester})${item.studentId}` : '----'} ${item.jpnName}`,
        value: item.objectId, classroom: item.classSchool, semester: item.semester, semesterYear: item.semesterYear, studentId: item.studentId
      }));
    }
    if (!optStudents.length) {
      optStudents.push({ key: '-1', text: '(無)', value: '' })
    }
    resStudents = optStudents;

    this.setState({ resStudents, optStudents });
  }

  cancelUpdateSetting = () => {
    const { form, doc } = this.state;
    this.setState({ isEdit: false, form: doc });
  }

  handleChangeJpn = (event) => {
    const fieldName = event.target.name;
    const fleldVal = event.target.value;
    this.setState({ jpForm: { ...this.state.jpForm, [fieldName]: fleldVal } });
  }

  handleChange = (event, { name, value }) => {
    const { match, history } = this.props
    const { filterForm, students } = this.state
    const { _id, list } = match.params;

    if (name === 'yearsemester') {
      const year = value.substring(0, 4);
      const semes = value.substring(5);
      const yearsemester = value;
      this.setState({ filterForm: { ...filterForm, year, semes, selClass: '0', yearsemester, searchText: '' } }, () => this.studentSelect());
    } else if (name === 'selClass') {
      const selClass = value;
      this.setState({ filterForm: { ...filterForm, selClass, searchText: '' } }, () => this.studentSelect());
    } else if (name === 'applyResult') {
      const applyResult = value;
      this.setState({ filterForm: { ...filterForm, applyResult, searchText: '' } }, () => this.studentSelect());
    } else if (name === 'studentStatus3') {
      const studentStatus3 = value;
      this.setState({ filterForm: { ...filterForm, studentStatus3, searchText: '' } }, () => this.studentSelect());
    } else if (name === 'selTypeId') { // 選募集/在籍
      history.push(`/StudentPrint/${value}`);
      this.setState({ filterForm: { ...filterForm, searchText: '' }, selTypeId: value }, () => this.studentSelect());
    }
  };

  handleClick = (event, d) => {
    let fleldVal = event.target.value;//d.value;
    const { match, students, history } = this.props
    const { list = "1" } = match.params;
    const { filterForm } = this.state
    // history.push(`/StudentPage/${list}/${fleldVal}`);
    const options = event.target.options;
    const value = [];
    const obj = []
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    console.log(value)
    this.setState({ value });
  }

  contextRef = createRef()

  handleSearch = (e, { value }) => {
    const { optStudents } = this.state;
    const { filterForm } = this.state

    if (value) {  // 搜尋值中間可以插入任意值 例如搜尋'程雪原' 可以找到 '程 雪原'
      const looseSearchValue = RegExp(value.split('').reduce((pattern, item) => pattern + item + '.*', '.*'));

      const resStudents = optStudents.filter(item => item.text.match(looseSearchValue));
      this.setState({ resStudents, filterForm: { ...filterForm, searchText: value } });
    } else {
      this.setState({ resStudents: optStudents, filterForm: { ...filterForm, searchText: '' } });
    }
  }

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0, disabled: true });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false, error: true });
    console.error(error);
  };

  studentLock(activeindex) {
    if (activeindex !== '-1')
      this.setState({ isLock: false, value: [] })
  }

  render() {
    const { optSet, formSet, match, history, systemObj } = this.props;
    const { doc, form, isEdit, selTypeId, resStudents, show, formModal, filterForm, activeIndex } = this.state;
    const { yearsemester, applyResult, selClass, searchText, studentStatus3, year, semes } = filterForm;

    const { statusKey = "", list = "1" } = match.params;
    const semester = { year, semes }//往下傳至PDF

    const classSchoolArr = [{ key: '0', text: '- クラス選択 -', value: '0' }, { key: '-1', text: 'ALL', value: '-1' }].concat(getOptionsSelByKey(optSet, 'classSchool'));
    const applyResultArr = [{ key: '0', text: '- 申請結果 -', value: '0' }].concat(getOptionsSelByKey(optSet, 'applyResult'));

    const ssArr = getOptionsSelByKey(optSet, 'studentStatus');//.slice(1, -1);
    const studentStatusArr = [{ key: '0', text: '- 学生状態 -', value: '0' }].concat(ssArr.slice(1, ssArr.length - 1));

    return (<div style={{ padding: '10px' }} className="content">
      <Ref innerRef={this.contextRef}>
        {/* <Grid> */}
        <Row>
          <Col sm={2}>
            <Sticky context={this.contextRef}>
              <Header as='h3'>{list === '3' ? '卒業中退除籍' : list === '1' ? '2-3 募集' : '3-4 在籍'}個人書類印刷</Header>
              <Form size='small'>
                <Select style={{
                  minWidth: '100px',
                  marginBottom: '4px',
                }} compact
                  options={selType}
                  onChange={this.handleChange}
                  disabled={isEdit}
                  value={selTypeId}
                  name='selTypeId' />
                <br />
                {list !== '2' ? <Select style={{
                  marginBottom: '4px',
                }} compact
                  fluid
                  options={semesterArr}
                  onChange={this.handleChange}
                  disabled={isEdit}
                  value={yearsemester}
                  name='yearsemester' /> : null}
                {list === '1' ? <Select style={{
                  marginBottom: '4px',
                }} compact
                  fluid
                  options={applyResultArr}
                  onChange={this.handleChange}
                  disabled={isEdit}
                  value={applyResult}
                  name='applyResult' /> : null}
                {list !== '1' ? <Select style={{
                  minWidth: '100px',
                  marginBottom: '4px',
                }} compact
                  fluid
                  // options={optionsClass}
                  options={classSchoolArr}
                  onChange={this.handleChange}
                  disabled={isEdit}
                  value={selClass}
                  name='selClass' /> : null}
                {list === '3' ? <Select style={{
                  marginBottom: '4px',
                }} compact
                  fluid
                  options={studentStatusArr}
                  onChange={this.handleChange}
                  value={studentStatus3}
                  name='studentStatus3' /> : null}
                <Form.Input
                  style={{ marginBottom: '4px' }}
                  placeholder='Search...'
                  disabled={isEdit}
                  onChange={this.handleSearch}
                  value={searchText}
                />
              </Form>
              <select multiple className="form-control ui form fluid search dropdown"
                style={{ height: '600px', width: '100%', fontSize: '11px', overflowX: 'auto' }} name="studentSel"
                onChange={this.handleClick}
                value={this.state.value}
                disabled={this.state.isLock}
              >
                {resStudents.map(item => <option key={item.key} className="item" value={item.value}>{item.text}</option>)}
              </select>
              <p style={{ textAlign: 'right' }}>{resStudents.length}名</p>
              <Button size="small" onClick={() => { this.setState({ value: [] }) }} icon='delete' content='クリア' /> <br />
              <span style={{ fontSize: '12px', color: '#999' }}>(複数を選択したい時Ctrlを押す)</span>
            </Sticky>
          </Col>
          <Col sm={10}>
            <ListTabs
              {...this.props}
              // isEdit={isEdit}
              renderFieldNoLabel={this.renderFieldNoLabel}
              // activeIndex1={activeIndex}
              studentSelected={this.state.value}
              studentLock={this.studentLock}
              yearsemester={semester}
            />
          </Col>
        </Row>
        {/* </Grid> */}
      </Ref>
      {this.state.isUploading && <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 10000,
        width: '100vw',
        height: '100vh',
        background: 'rgba(0,0,0,0.87)',
        overflow: 'hodden'
      }}>
        <Progress percent={this.state.progress} indicating error={this.state.error} progress />
      </div>}
    </div >);
  }
}