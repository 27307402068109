import React, { Component } from 'react'
import { Container, Header, Modal, Confirm, Grid } from 'semantic-ui-react';
// import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { Col, Row } from "reactstrap";
import MaterialTable from 'material-table'

import localization from 'widget/MaterialTableOpt'
// import { DimmerImg } from '../../layouts/Dimmer';
import Parse from 'widget/parse'
import { dateCol, exportPdf, rowReOrder } from 'views/web/CustomMaterialTable';

const columns = () => ([
  // { title: '預設', field: 'now_use' },
  { title: '順序', field: 'order' },
  { title: '產品類別', field: 'productsType' },
]);

const columns2 = () => ([
  // { title: '預設', field: 'now_use' },
  // // { title: '順序', field: 'orderId' },
  { title: '順序', field: 'order' },
  { title: '產品名稱', field: 'productsName' },
  { title: '產品單價', field: 'productsUnitPrice' },
  { title: '產品庫存', field: 'productsInventory' }
]);

export default class Web4 extends Component {
  state = {

    product1: [],
    product2: [],
    data1: [],
    data2: [],
    sel1: '',
    sel2: '',
    title1: '',
    title2: '',
    loading: true,
    // sel4: '',
  }

  componentDidMount() {
    // this.props.refetch('Product')
    this.handleFetch()
  }
  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps)
  }

  handleFetch = async (props = this.props, type = 1) => {
    const { companyObj } = props;
    const product1 = await Parse.queryData('product1', { companyId: companyObj.objectId });
    const product2 = await Parse.queryData('product2', { companyId: companyObj.objectId });

    const data1 = product1.sort((a, b) => ('' + a.order).localeCompare(b.order));
    const data2 = product2.sort((a, b) => ('' + a.order).localeCompare(b.order));
    // if (type === 1) {
    this.setState({
      product1, product2,
      data1, data2, loading: false,
    });
    // } else if (type === 0) {
    //   this.setState({
    //     product1, product2, product3, product4,
    //     data1: product1, data2: product1, data3: product1, data4: product1,
    //   });
    // }
  }

  handleAdd = async (id) => {
    const { companyObj, refetch } = this.props;
    const { newData1, newData2, newData3, newData4 } = this.state;
    // this.setState({ sel1: '', sel2: '', sel3: '', sel4: '', })
    if (id === "1") {
      await Parse.saveData('product1', newData1);
    } else if (id === "2") {
      await Parse.saveData('product2', newData2);
    }
    // this.handleData();
    this.setState({ loading: false })
  }

  handleUpdate = async (id) => {
    const { newData1, newData2, newData3, newData4 } = this.state
    // this.setState({ loading: true, sel1: '', sel2: '', sel3: '', sel4: '', })
    if (id === "1") {
      await Parse.saveData('product1', newData1);
    } else if (id === "2") {
      await Parse.saveData('product2', newData2);
    }
    // this.handleData();
    this.setState({ loading: false })
  }

  handleDelete = async (id) => {
    const { oldData1, oldData2, oldData3, oldData4 } = this.state
    if (id === "1") {
      Parse.deleteData('product1', oldData1);
      this.setState({ oldData1: {} })
    } else if (id === "2") {
      Parse.deleteData('product2', oldData2);
      this.setState({ oldData2: {} })
    }
    // this.handleFetch()
    // this.handleData();
    this.setState({ loading: false })
  }

  handleData = (id, rowData) => {
    const { } = this.props;
    const { product1, product2, product3, product4 } = this.state;
    const sel = rowData.objectId;
    if (id === "1") {
      this.setState({ sel1: sel, sel2: '', sel3: '', title1: rowData.productsType, data2: product2.filter(item => item.product1_id === sel) })
    } else if (id === "2") {
      this.setState({ sel2: sel, sel3: '', title2: rowData.name_cht, data3: product3.filter(item => item.product2_id === sel) })
    }
  }

  handleState = (dataArr, arrName = 'dataArr') => {
    this.setState({ [arrName]: dataArr })
  }

  render() {
    const { companyObj } = this.props;
    const { dataArr, deleteConfirm, columnDefs, rowData, product1, product2, loading,
      data1, data2, title1, sel1, } = this.state;

    return (<>
      <div className='content'>
        {/* <div style={{ ...style.flexCenter, position: 'relative', zIndex: 100 }}>
          <Header as='h2' style={{ margin: '2rem 0' }}>6-2 產品管理</Header>
        </div> */}
        <br />
        <br />
        <Row>
          {/* offset: 1 */}
          <Col md={{ size: 4, }}>
            {/* {loading && data1 ? <DimmerImg /> : */}
            <MaterialTable
              isLoading={loading}
              localization={localization()}
              columns={columns()}
              options={{
                addRowPosition: "first",
                exportButton: true,
                exportPdf: (columns, data) => exportPdf(columns, data, '產品類別'),
                pageSize: data1.length || 5,
                search: false
              }}
              // onColumnDragged={}
              data={data1}
              title="產品類別"
              actions={[
                {
                  icon: 'view_headline',
                  tooltip: '查看',
                  onClick: (event, rowData) => this.handleData("1", rowData)
                }
              ]}
              components={{
                Row: props => rowReOrder(props, 'product1', 'order', data1, 'data1', this.handleState)
              }}
              onRowClick={(event, rowData) => this.handleData("1", rowData)}
              editable={{
                onRowAdd: newData1 =>
                  new Promise(async (resolve, reject) => {
                    // const db = firebase.firestore();
                    // const docRef = db.collection('product1').doc();
                    const data = [...data1];
                    const db = Parse.Object.extend("product1");
                    const newDoc = new db();
                    const doc = await newDoc.save();

                    const obj = {
                      _id: doc.id,
                      id: doc.id,
                      objectId: doc.id,
                      companyId: companyObj.objectId || '',
                      companyName: companyObj.name || '',
                      ...newData1
                    }

                    data.push(obj);

                    this.setState({ data1: data, newData1: obj, loading: true }, () => resolve());
                  }).then(() => this.handleAdd("1")),

                onRowUpdate: (newData1, oldData1) =>
                  new Promise((resolve, reject) => {
                    const data = [...data1];
                    const index = data.indexOf(oldData1);
                    data[index] = newData1;
                    // console.log(data1)
                    this.setState({ data1: data, newData1, oldData1, loading: true }, () => resolve());
                  }).then(() => this.handleUpdate("1")),
                onRowDelete: oldData1 =>
                  new Promise((resolve, reject) => {
                    let data = [...data1];
                    const index = data.indexOf(oldData1);
                    data.splice(index, 1);
                    this.setState({ data1: data, oldData1, loading: true }, () => resolve());
                  }).then(() => this.handleDelete("1")),
              }}
            />
          </Col>
          <Col sm="8">
            {sel1 ? <MaterialTable
              isLoading={loading}
              isLoading={loading}
              localization={localization()}
              columns={columns2()}
              options={{
                addRowPosition: "first",
                exportButton: true,
                exportPdf: (columns, data) => exportPdf(columns, data, title1),
                pageSize: data2.length || 5,
                search: false
              }}
              // onColumnDragged={}
              data={data2}
              title={title1}
              components={{
                Row: props => rowReOrder(props, 'product2', 'order', data2, 'data2', this.handleState)
              }}
              // actions={[
              //   {
              //     icon: 'view_headline',
              //     tooltip: '查看',
              //     onClick: (event, rowData) => this.handleData("2", rowData)
              //   }
              // ]}
              // onRowClick={(event, rowData) => this.handleData("2", rowData)}
              editable={{
                onRowAdd: newData2 =>
                  new Promise(async (resolve, reject) => {
                    const data = [...data2];
                    const db = Parse.Object.extend("product2");
                    const newDoc = new db();
                    const doc = await newDoc.save();

                    const obj = {
                      _id: doc.id,
                      id: doc.id,
                      companyId: companyObj.objectId || '',
                      companyName: companyObj.name || '',
                      product1_id: sel1,
                      product1_text: (product1.find(item => item.objectId === sel1) && product1.find(item => item.objectId === sel1).value) || '',
                      ...newData2
                    }
                    data.push(obj);
                    this.setState({ data2: data, newData2: obj, loading: true }, () => resolve());
                  }).then(() => this.handleAdd("2")),
                onRowUpdate: (newData2, oldData2) =>
                  new Promise((resolve, reject) => {
                    const data = [...data2];
                    const index = data.indexOf(oldData2);
                    data[index] = newData2;
                    // console.log(data1)
                    this.setState({ data2: data, newData2, oldData2, loading: true }, () => resolve());
                  }).then(() => this.handleUpdate("2")),
                onRowDelete: oldData2 =>
                  new Promise((resolve, reject) => {
                    let data = [...data2];
                    const index = data.indexOf(oldData2);
                    data.splice(index, 1);
                    this.setState({ data2: data, oldData2, loading: true }, () => resolve());
                  }).then(() => this.handleDelete("2")),
              }}
            /> : null}
          </Col>
        </Row>
      </div>
    </>)
  }
}