import React, { Component } from 'react'
import { Container, Button, Dimmer, Loader, Image, Segment, Header, Grid } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import localization from 'widget/MaterialTableOpt'
import Parse from 'widget/parse'
import ModalMultiInput from 'views/web/ModalMultiInput';
import { dateCol, exportPdf, rowReOrder } from 'views/web/CustomMaterialTable';

const columns = () => ([
  { title: '日期', field: 'date' },
  // { title: '水錶度數', field: 'water' },
  // { title: '使用度數', field: 'usedWater' },
  // { title: '金額', field: 'costWater' },
]);

const columns2 = () => ([
  // { title: '日期', field: 'dateElectic' },
  { title: '房號', field: 'roomNum' },
  { title: '電錶度數', field: 'value' },
  // { title: '使用度數', field: 'usedkWh' },
  // { title: '金額', field: 'costElectric' },
]);

export default class MeterList extends Component {
  state = {
    loading: true,
    meter1Id: '',
    data1: [],
    data2: [],
    newData1: {},
    newData2: {},
    loading2: false,
    rowData: {},
  }

  async componentDidMount() {
    this.handleFetch()
  }

  handleFetch = async () => {
    const { companyObj, match } = this.props
    this.setState({ loading: true, meter1Id: '' })
    // const { familyId } = match.params;
    const companyId = companyObj.objectId;
    const data = await Parse.queryData("meter1", { companyId })

    data.sort((a, b) => ('' + b.date.split('-')[0]).localeCompare(a.date.split('-')[0]))
    data.sort((a, b) => ('' + b.date.split('-')[1]).localeCompare(a.date.split('-')[1]))
    data.sort((a, b) => ('' + b.date.split('-')[2]).localeCompare(a.date.split('-')[2]))

    this.setState({ data1: data, loading: false })
  }


  handleAdd = async (key) => {
    this.handleUpdate(key);
  }

  handleUpdate = async (key) => {
    const { companyObj } = this.props;
    const { data1, data2 } = this.state;

    if (key === "1") {
      const { newData1 } = this.state;
      data1.forEach((item, i) => Parse.saveData('meter1', data1[i]));
      // Parse.saveData('meter1', newData1);
      Parse.saveData('company', { objectId: companyObj.objectId, meter1IdDef: newData1.objectId })
    } else {
      const { newData2 } = this.state;
      data2.forEach((item, i) => Parse.saveData('meter2', data2[i]));
      Parse.saveData('meter2', newData2);
    }
  }

  handleDelete = async (key) => {
    if (key === "1") {
      const { oldData1 } = this.state
      Parse.deleteData('meter1', oldData1);
      await Parse.destroyData('meter2', { meter1Id: oldData1.objectId });
      this.setState({ oldData1: {}, meter1Id: '' })
    } else {
      const { oldData2 } = this.state
      Parse.deleteData('meter2', oldData2);
      this.setState({ oldData2: {} })
    }
  }

  handleData = async (rowData) => {
    const meter1Id = rowData.objectId
    console.log(rowData)
    this.setState({ meter1Id: '', rowData, loading2: true });
    if (meter1Id) {
      const data2 = await Parse.queryData('meter2', { meter1Id });
      this.setState({ data2, meter1Id, loading2: false });
    }
  }

  render() {
    const { companyObj = {} } = this.props
    const { data1, data2, meter1Id, loading, loading2, rowData } = this.state

    return (<>
      <div className="content">
        <Grid>
          <Grid.Row columns='equal'>
            <Grid.Column width={7} computer={7} tablet={7} mobile={16}>
              <MaterialTable
                isLoading={loading}
                localization={localization()}
                columns={columns()}
                options={{
                  addRowPosition: "first",
                  exportButton: true,
                  exportPdf: (columns, data) => exportPdf(columns, data, '抄錶日'),
                  pageSize: data1.length || 5,
                  search: false
                }}
                components={{
                  Toolbar: props => (<div>
                    <div style={{ float: 'right', textAlign: 'right', padding: '22px 16px 22px 4px' }}>
                      <ModalMultiInput {...this.props} handleData={this.handleFetch} columns={props.columns} condition={{ companyId: companyObj.objectId, companyName: companyObj.name }} dbName='meter1' />
                    </div>
                    <MTableToolbar {...props} />
                    <div style={{ clear: 'both' }}></div>
                  </div>),
                  Row: props => rowReOrder(props, 'meter1', 'order', data1, 'data1', this.handleState)
                }}
                data={data1}
                title="抄錶日"
                actions={
                  [
                    {
                      icon: 'view_headline',
                      tooltip: '查看',
                      onClick: (event, rowData) => this.handleData(rowData)
                    }
                  ]}
                onRowClick={(event, rowData) => this.handleData(rowData)}
                editable={{
                  onRowAdd: newData1 =>
                    new Promise(async (resolve, reject) => {
                      const data = [...data1];
                      const db = Parse.Object.extend("meter1");
                      const newDoc = new db();
                      const doc = await newDoc.save();
                      newData1.companyId = companyObj.id;
                      newData1.companyName = companyObj.name;
                      newData1.objectId = doc.id;
                      newData1.id = doc.id;
                      newData1._id = doc.id;

                      data.push(newData1);
                      this.setState({ data1: data, newData1 }, () => resolve());
                    }).then(() => this.handleAdd("1")),
                  onRowUpdate: (newData1, oldData1) =>
                    new Promise((resolve, reject) => {
                      const data = [...data1];
                      const index = data.indexOf(oldData1);
                      // if (newData1.now_use === "-1") {
                      //   data.forEach((item, i) => data[i].now_use = '0');
                      // }
                      data[index] = newData1;
                      this.setState({ data1: data, newData1, oldData1 }, () => resolve());
                    }).then(() => this.handleUpdate("1")),
                  onRowDelete: oldData1 =>
                    new Promise((resolve, reject) => {
                      let data = [...data1];
                      const index = data.indexOf(oldData1);
                      data.splice(index, 1);
                      this.setState({ data1: data, oldData1 }, () => resolve());
                    }).then(() => this.handleDelete("1")),
                }}
              />
            </Grid.Column>
            <Grid.Column width={9} computer={9} tablet={9} mobile={16}>
              {!meter1Id ? null : <><MaterialTable
                isLoading={loading2}
                localization={localization()}
                columns={columns2()}
                options={{
                  addRowPosition: "first",
                  exportButton: true,
                  exportPdf: (columns, data) => exportPdf(columns, data, rowData.value + " 法會項目"),
                  pageSize: data2.length < 5 ? 5 : data2.length + 5,
                  search: false,
                }}
                components={{
                  Toolbar: props => (<div>
                    <div style={{ float: 'right', textAlign: 'right', padding: '22px 16px 22px 4px' }}>
                      <ModalMultiInput {...this.props} handleData={this.handleFetch} columns={props.columns} condition={{ companyId: companyObj.objectId, companyName: companyObj.name, meter1Id: meter1Id, meter1Name: rowData.value }} dbName='meter2' />
                    </div>
                    <MTableToolbar {...props} />
                    <div style={{ clear: 'both' }}></div>
                  </div>),
                  Row: props => rowReOrder(props, 'meter2', 'order', data2, 'data2', this.handleState)
                }}
                data={data2}
                title={rowData.date + " 度數"}
                editable={{
                  onRowAdd: newData2 =>
                    new Promise(async (resolve, reject) => {
                      const data = [...data2];
                      const db = Parse.Object.extend("meter2");
                      const newDoc = new db();
                      const doc = await newDoc.save();
                      newData2.objectId = doc.id;
                      newData2.companyId = companyObj.id;
                      newData2.companyName = companyObj.name;
                      newData2.id = doc.id;
                      newData2._id = doc.id;
                      newData2.meter1Id = meter1Id
                      newData2.meter1Name = rowData.value

                      data.push(newData2);
                      this.setState({ data2: data, newData2 }, () => resolve());
                    }).then(() => this.handleAdd("2")),
                  onRowUpdate: (newData2, oldData2) =>
                    new Promise((resolve, reject) => {
                      const data = [...data2];
                      const index = data.indexOf(oldData2);
                      if (newData2.now_use === "-1") {
                        data.forEach((item, i) => data[i].now_use = '0');
                      }
                      data[index] = newData2;
                      this.setState({ data2: data, newData2, oldData2 }, () => resolve());
                    }).then(() => this.handleUpdate("2")),
                  onRowDelete: oldData2 =>
                    new Promise((resolve, reject) => {
                      let data = [...data2];
                      const index = data.indexOf(oldData2);
                      data.splice(index, 1);
                      this.setState({ data2: data, oldData2 }, () => resolve());
                    }).then(() => this.handleDelete("2")),
                }}
              />
                <br />
              </>}
              <br />
              <br />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <br />
        <br />
      </div>
    </>)
  }
}