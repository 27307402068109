import React, { Component } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { Container } from 'semantic-ui-react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Row,
  Col,
  Table
} from "reactstrap";
import 'react-alice-carousel/lib/alice-carousel.css';
import Parse from 'widget/parse'
const contentCss = {
  maxWidth: '1170px',
  margin: '200px auto',
  flexDirection: 'column',
}
const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};
const arr = [
  { name: '愛知県立芸術大学', school: '国公立大学大学院' },
  { name: '名古屋大学', school: '国公立大学大学院' },
  { name: '名古屋工業大学', school: '国公立大学大学院' },
  { name: '名古屋市立大学', school: '国公立大学大学院' },
  { name: '北海道大学', school: '国公立大学大学院' },
  { name: '大阪大学', school: '国公立大学大学院' },
  { name: '三重大学', school: '国公立大学大学院' },
  { name: '早稲田大学', school: '私立大学大学院' },
  { name: '愛知工業大学', school: '私立大学大学院' },
  { name: '名古屋芸術大学', school: '私立大学大学院' },
  { name: '名古屋商科大学', school: '私立大学大学院' },
  { name: '名城大学', school: '私立大学大学院' },
  { name: '中部大学', school: '私立大学大学院' },
  { name: '中京大学', school: '私立大学大学院' },
  { name: '中央大学', school: '私立大学大学院' },
  { name: '愛知学院大学', school: '私立大学大学院' },
  { name: '岐阜聖徳学園大学', school: '私立大学大学院' },
  { name: '名古屋大学', school: '国公立大学' },
  { name: '名古屋工業大学', school: '国公立大学' },
  { name: '名古屋市立大学', school: '国公立大学' },
  { name: '愛知教育大学', school: '国公立大学' },
  { name: '愛知県立大学', school: '国公立大学' },
  { name: '岐阜大学', school: '国公立大学' },
  { name: '三重大学', school: '国公立大学' },
  { name: '静岡大学', school: '国公立大学' },
  { name: '信州大学', school: '国公立大学' },
  { name: '豊橋技術科学大学', school: '国公立大学' },
  { name: '愛知学院大学', school: '私立大学' },
  { name: '愛知学泉大学', school: '私立大学' },
  { name: '愛知工業大学', school: '私立大学' },
  { name: '愛知淑徳大学', school: '私立大学' },
  { name: '愛知大学', school: '私立大学' },
  { name: '愛知東邦大学', school: '私立大学' },
  { name: '愛知文教大学', school: '私立大学' },
  { name: '朝日大学', school: '私立大学' },
  { name: '亜細亜大学', school: '私立大学' },
  { name: '江戸川大学', school: '私立大学' },
  { name: '桜花学園大学', school: '私立大学' },
  { name: '大阪国際大学', school: '私立大学' },
  { name: '岡山商科大学', school: '私立大学' },
  { name: '岐阜協立大学', school: '私立大学' },
  { name: '京都情報大学院大学', school: '私立大学' },
  { name: '城西国際大学', school: '私立大学' },
  { name: '湘南工科大学', school: '私立大学' },
  { name: '椙山女学園大学', school: '私立大学' },
  { name: '鈴鹿大学', school: '私立大学' },
  { name: '大同大学', school: '私立大学' },
  { name: '中京学院大学', school: '私立大学' },
  { name: '至学館大学', school: '私立大学' },
  { name: '中京大学', school: '私立大学' },
  { name: '中部大学', school: '私立大学' },
  { name: '帝塚山大学', school: '私立大学' },
  { name: '東京情報大学', school: '私立大学' },
  { name: '東京福祉大学', school: '私立大学' },
  { name: '東方学園大学', school: '私立大学' },
  { name: '同朋大学', school: '私立大学' },
  { name: '豊橋創造大学', school: '私立大学' },
  { name: '名古屋音楽大学', school: '私立大学' },
  { name: '名古屋外国語大学', school: '私立大学' },
  { name: '名古屋学院大学', school: '私立大学' },
  { name: '名古屋経済大学', school: '私立大学' },
  { name: '名古屋産業大学', school: '私立大学' },
  { name: '名古屋商科大学', school: '私立大学' },
  { name: '名古屋女子大学', school: '私立大学' },
  { name: '南山大学', school: '私立大学' },
  { name: '日本福祉大学', school: '私立大学' },
  { name: '中京学院大学短期大学部', school: '短期大学' },
  { name: '中日本自動車短期大学', school: '短期大学' },
  { name: '名古屋経営短期大学', school: '短期大学' },
  { name: '名古屋文化短期大学', school: '短期大学' },
  { name: '光陵女子短期大学', school: '短期大学' },
  { name: '東海工業専門学校', school: '専門学校' },
  { name: '明美文化服装専門学校', school: '専門学校' },
  { name: '安城文化服装専門学校', school: '専門学校' },
  { name: '専門学校エール学園', school: '専門学校' },
  { name: '専門学校エクラ', school: '専門学校' },
  { name: '京都コンピュータ学院', school: '専門学校' },
  { name: '高桑服装専門学校', school: '専門学校' },
  { name: '広告デザイン専門学校', school: '専門学校' },
  { name: '国際観光専門学校', school: '専門学校' },
  { name: '国際デュアルビジネス専門学校', school: '専門学校' },
  { name: 'コンピュータ教育学院', school: '専門学校' },
  { name: '駿台外語＆ビジネス専門学校', school: '専門学校' },
  { name: '駿台観光＆外語ビジネス専門学校', school: '専門学校' },
  { name: '中央工学校', school: '専門学校' },
  { name: '東海工業専門学校', school: '専門学校' },
  { name: 'トヨタ名古屋自動車大学校', school: '専門学校' },
  { name: 'トライデントコンピュータ専門学校', school: '専門学校' },
  { name: '名古屋外語・ホテル・ブライダル専門学校', school: '専門学校' },
  { name: '名古屋経営会計専門学校', school: '専門学校' },
  { name: '名古屋コミュニケーションアート専門学校', school: '専門学校' },
  { name: '名古屋製菓専門学校', school: '専門学校' },
  { name: '保育・介護・ビジネス大名古屋専門学校', school: '専門学校' },
  { name: 'ELICビジネス＆公務員専門学校', school: '専門学校' },
  { name: 'ニチエイ調理専門学校', school: '専門学校' },
  { name: '日商簿記三鷹福祉専門学校', school: '専門学校' },
  { name: '日本デザイナー芸術学院', school: '専門学校' },
  { name: '布池外語専門学校', school: '専門学校' },
  { name: '名古屋モード学園', school: '専門学校' },
]
export class SectionSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0,
      lightboxIsOpen: false,
      photos: [],
      album: [],
      rowData: {},
      isOpen: false,
      schoolArr: []
    };
  }

  componentDidMount = async () => {
    const { companyObj } = this.props
    // const companyId = companyObj.objectId
    const data = await Parse.queryData('album', { companyId: 'aKDM0xR8py', isopen: '1' }, { sort: 'date_descend' });
    this.setState({ album: data })
  }

  handleData = async (name, albumId) => {
    // const { companyObj } = this.props
    // const companyId = companyObj.objectId
    // const photos = await Parse.queryData('webimage', { companyId: 'aKDM0xR8py', albumId });
    // this.setState({ photos, rowData, isOpen: true })
    const schoolArr = arr.filter(item => item.school === name)
    this.setState({ schoolArr })
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  };
  render() {
    const { form, cookies, webimageArr } = this.props;
    const { text2 = '標題', text3 = '', img1File = {} } = form;
    // const dbLang = cookies.get('dbLang') || 'name';
    // const arr = webimageArr.filter(item => item.navId === form.objectId)
    const { album, isOpen, photos, field = 'img1File2', rowData, schoolArr } = this.state
    const items = () => {
      // const style = {
      //   width: '95%', height: '300px', backgroundImage:
      //     `url(${(item.img1File2 && item.img1File2.url) || require("assets/img/bg/rawpixel-comm.jpg")})`,
      //   backgroundSize: 'cover',
      //   backgroundPosition: 'center center',
      //   backgroundRepeat: 'no-repeat',
      // };
      return (<>
        <Table bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Username</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Larry</td>
              <td>the Bird</td>
              <td>@twitter</td>
            </tr>
          </tbody>
        </Table>
      </>)
    };

    return (
      <>
        <Container style={contentCss} >
          <h2 style={{ textAlign: 'center' }}>{text2}</h2>
          <br />
          <br />
          <br />
          <br />
          <Row>
            <Col md='4'>
              <Card style={{ cursor: 'pointer' }} onClick={() => this.handleData('国公立大学大学院')}>
                <CardBody style={{
                  backgroundColor: '#f1f2f4',
                  opacity: 0.9
                }}>
                  {/* <div className="card-icon" style={{
                    backgroundImage:
                      `url(${require("assets/img/bg/rawpixel-comm.jpg")})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    height: '200px'
                  }}>
                  </div>
                  <br />
                  <br /> */}
                  <h5 className="card-category" style={{ textAlign: 'center', color: '#66615B', fontSize: '1rem', fontWeight: 600 }}>国公立大学大学院</h5>

                  {/* <CardFooter style={{ textAlign: 'center' }}>
                    <br />
                    <Button
                      className="btn-neutral"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-book mr-1" />
                    Show more
                    </Button>
                  </CardFooter> */}
                </CardBody>
              </Card>
            </Col>
            <Col md='4'>
              <Card style={{ cursor: 'pointer' }} onClick={() => this.handleData('私立大学大学院')}>
                <CardBody style={{
                  backgroundColor: '#f1f2f4',
                  opacity: 0.9
                }}>
                  {/* <div className="card-icon" style={{
                    backgroundImage:
                      `url(${require("assets/img/bg/rawpixel-comm.jpg")})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    height: '200px'
                  }}>
                  </div>
                  <br />
                  <br /> */}
                  <h5 className="card-category" style={{ textAlign: 'center', color: '#66615B', fontSize: '1rem', fontWeight: 600 }}>私立大学大学院</h5>

                  {/* <CardFooter style={{ textAlign: 'center' }}>
                    <br />
                    <Button
                      className="btn-neutral"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-book mr-1" />
                    Show more
                    </Button>
                  </CardFooter> */}
                </CardBody>
              </Card>
            </Col>
            <Col md='4'>
              <Card style={{ cursor: 'pointer' }} onClick={() => this.handleData('国公立大学')}>
                <CardBody style={{
                  backgroundColor: '#f1f2f4',
                  opacity: 0.9
                }}>
                  {/* <div className="card-icon" style={{
                    backgroundImage:
                      `url(${require("assets/img/bg/rawpixel-comm.jpg")})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    height: '200px'
                  }}>
                  </div>
                  <br />
                  <br /> */}
                  <h5 className="card-category" style={{ textAlign: 'center', color: '#66615B', fontSize: '1rem', fontWeight: 600 }}>国公立大学</h5>

                  {/* <CardFooter style={{ textAlign: 'center' }}>
                    <br />
                    <Button
                      className="btn-neutral"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-book mr-1" />
                    Show more
                    </Button>
                  </CardFooter> */}
                </CardBody>
              </Card>
            </Col>
            <Col md='4'>
              <Card style={{ cursor: 'pointer' }} onClick={() => this.handleData('私立大学')}>
                <CardBody style={{
                  backgroundColor: '#f1f2f4',
                  opacity: 0.9
                }}>
                  {/* <div className="card-icon" style={{
                    backgroundImage:
                      `url(${require("assets/img/bg/rawpixel-comm.jpg")})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    height: '200px'
                  }}>
                  </div>
                  <br />
                  <br /> */}
                  <h5 className="card-category" style={{ textAlign: 'center', color: '#66615B', fontSize: '1rem', fontWeight: 600 }}>私立大学</h5>

                  {/* <CardFooter style={{ textAlign: 'center' }}>
                    <br />
                    <Button
                      className="btn-neutral"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-book mr-1" />
                    Show more
                    </Button>
                  </CardFooter> */}
                </CardBody>
              </Card>
            </Col>
            <Col md='4'>
              <Card style={{ cursor: 'pointer' }} onClick={() => this.handleData('短期大学')}>
                <CardBody style={{
                  backgroundColor: '#f1f2f4',
                  opacity: 0.9
                }}>
                  {/* <div className="card-icon" style={{
                    backgroundImage:
                      `url(${require("assets/img/bg/rawpixel-comm.jpg")})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    height: '200px'
                  }}>
                  </div>
                  <br />
                  <br /> */}
                  <h5 className="card-category" style={{ textAlign: 'center', color: '#66615B', fontSize: '1rem', fontWeight: 600 }}>短期大学</h5>

                  {/* <CardFooter style={{ textAlign: 'center' }}>
                    <br />
                    <Button
                      className="btn-neutral"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-book mr-1" />
                    Show more
                    </Button>
                  </CardFooter> */}
                </CardBody>
              </Card>
            </Col>
            <Col md='4'>
              <Card style={{ cursor: 'pointer' }} onClick={() => this.handleData('専門学校')}>
                <CardBody style={{
                  backgroundColor: '#f1f2f4',
                  opacity: 0.9,
                  cursor: 'pointer'
                }}>
                  {/* <div className="card-icon" style={{
                    backgroundImage:
                      `url(${require("assets/img/bg/rawpixel-comm.jpg")})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    height: '200px'
                  }}>
                  </div>
                  <br />
                  <br /> */}
                  <h5 className="card-category" style={{ textAlign: 'center', color: '#66615B', fontSize: '1rem', fontWeight: 600 }}>専門学校</h5>

                  {/* <CardFooter style={{ textAlign: 'center' }}>
                    <br />
                    <Button
                      className="btn-neutral"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-book mr-1" />
                    Show more
                    </Button>
                  </CardFooter> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: '10%' }}>
            {schoolArr.map(item =>
              <Col md='2'>
                <Card>
                  <CardBody style={{
                    backgroundColor: '#F6F3E0',
                    opacity: 0.9
                  }}>
                    {/* <div className="card-icon" style={{
                    backgroundImage:
                      `url(${require("assets/img/bg/rawpixel-comm.jpg")})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    height: '200px'
                  }}>
                  </div>
                  <br />
                  <br /> */}
                    <h5 className="card-category" style={{ textAlign: 'center', color: '#66615B', fontSize: '1rem', fontWeight: 600 }}>{item.name}</h5>

                    {/* <CardFooter style={{ textAlign: 'center' }}>
                    <br />
                    <Button
                      className="btn-neutral"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-book mr-1" />
                    Show more
                    </Button>
                  </CardFooter> */}
                  </CardBody>
                </Card>
              </Col>)}

          </Row>
        </Container>
      </>
    );
  }
}
export default SectionSlider;