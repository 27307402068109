import React, { Component } from 'react'
import MaterialTable, { MTableBodyRow } from 'material-table'

import localization from 'widget/MaterialTableOpt'
import Parse from 'widget/parse'
import { dateCol, exportPdf, rowReOrder } from 'views/web/CustomMaterialTable';

const columns = () => ([
  { title: '順序', field: 'orderId', type: 'numeric' },
  { title: '中文名稱', field: 'name_cht' },
  { title: '英文名稱', field: 'name_eng' },
]);

const DrageState = {
  row: -1,
  dropIndex: -1,
}

export default class Web7 extends Component {
  state = {
    loginUser: this.props.user.profile.toJSON(),
    dataArr: []
  }

  componentDidMount = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId;
    const { loginUser } = this.state
    const dataArr = await Parse.queryData('provider', { companyId }, { orderBy: 'orderId' });

    const authPage = window.location.pathname.split('/')[2]
    const authUser = companyObj.objectId + '_' + authPage
    let auth = {}

    if ((loginUser.authAdminA && loginUser.authAdminA.indexOf(companyObj.objectId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
      auth = {
        edit: true,
      }
    } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
      auth = {
        edit: false,
      }
    } else {
      auth = {
        edit: false,
      }
    }

    this.setState({ auth, dataArr })
  }

  handleAdd = async () => { // praying1 add
    const { newData } = this.state;
    const obj = {
      ...newData,
    };
    await Parse.saveData('provider', obj);
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    await Parse.saveData('provider', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state;
    Parse.deleteData('provider', oldData);
    this.setState({ oldData: {} })
  }

  handleState = (dataArr, arrName = 'dataArr') => {
    this.setState({ [arrName]: dataArr })
  }

  editable = {
    isEditHidden: () => !this.state.auth.edit,
    isDeleteHidden: () => !this.state.auth.edit,
    onRowAdd: newData =>
      new Promise(async (resolve, reject) => {
        const db = Parse.Object.extend("provider");
        const newDoc = new db();
        const docRef = await newDoc.save();
        const data = [...this.state.dataArr];
        const obj = {
          _id: docRef.id,
          id: docRef.id,
          companyId: this.props.companyObj.objectId || '',
          companyName: this.props.companyObj.name || '',
          ...newData
        }
        data.push(obj);
        this.setState({ dataArr: data, newData: obj }, () => resolve());
      }).then(() => this.handleAdd()),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        const data = [...this.state.dataArr];
        const index = data.indexOf(oldData);
        data[index] = newData;
        this.setState({ dataArr: data, newData, oldData }, () => resolve());
      }).then(() => this.handleUpdate()),
    onRowDelete: oldData =>
      new Promise((resolve, reject) => {
        let data = [...this.state.dataArr];
        const index = data.indexOf(oldData);
        data.splice(index, 1);
        this.setState({ dataArr: data, oldData }, () => resolve());
      }).then(() => this.handleDelete()),
  }

  render() {
    const { companyObj } = this.props;
    const { dataArr, auth = {} } = this.state;



    if (auth.edit === false) {
      delete this.editable.onRowAdd
    }

    return (<div className='content'>

      <MaterialTable
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '供應商管理'),
          pageSize: dataArr.length || 20,
          search: false
        }}
        components={{
          Row: (props) => rowReOrder(props, 'provider', 'orderId', dataArr, 'dataArr', this.handleState)
        }}
        data={dataArr}
        title="供應商管理"
        editable={this.editable}
      />
    </div>)
  }
}