import React, { Component, createRef } from 'react'
import {
  Button,
  Form,
  Icon,
  Container,
  Header,
  Input,
  Label,
  Select,
  Ref,
  Grid,
  Sticky,
  Progress,
  Dimmer,
  Loader,
  Image,
  Segment,
} from 'semantic-ui-react'

import StudentViewTabs from './StudentViewTabs'
import {
  getLabelName2,
  getOptionsSelByKey,
  getOptionTextByValue,
} from '../../widgets/FieldsRender'
import FileUploader from 'react-firebase-file-uploader'
import { getWeekRangeArr, getWeekNumber } from '../../../widget/define/week'
import { set0String } from '../../../widget/define/date'

import { selType, statusArr, semesterArr } from '../../../widget/define/define'
import Moment from 'moment'
import Parse from 'widget/parse.js'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { DimmerImg } from '../../../layouts/Dimmer';
import { async } from 'parse/lib/browser/Storage'

// import { cjkConv } from '../../../modules/cjk-convert';
// import cjkConv from 'cjk-conv';
// import { cjk2jp } from 'cjk-conv';
// const cjkConv = require("cjk-conv");

export default class StudentPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEdit: false, // props.isEdit,
      doc: {}, // 原本資料
      form: {}, // 更改處
      jpForm: {},
      selTypeId: props.match.params.list || '1',
      students: [],
      optStudents: [],
      resStudents: [],
      extStudents: [],
      show: false,
      loading: true,
      formModal: props.match.params.list === '0' ? {
        enquiryDate: Moment(new Date()).format('YYYY/MM/DD'),
        // enquiryNum: '',
        studentType: '1',
        semesterYear: props.semester === 'S' ? `${Number(props.semesterYear)}` : `${Number(props.semesterYear) + 1}`,
        semester: props.semester === 'S' ? 'A' : 'S'
      } : {
          // 新規作成
          value0: '',
          value1: '',
          semesterYear: props.match.params.list === '1' ?
            props.semester === 'A' ?
              `${Number(props.semesterYear) + 1}` : props.semesterYear
            : props.semesterYear,
          semester: props.match.params.list === '1' ?
            props.semester === 'A' ?
              'S' :
              'A' : props.semester,
          studentStatus: props.match.params.list === '1' ?
            '10' : props.match.params.list === '2' ?
              '30' : '',
          studentType: '1',
        },
      filenames: [],
      downloadURLs: [],
      // classroom: [],
      filterForm: {
        searchText: '',
        selClass: '0',
        selStudentType: '0',
        year: props.match.params.list === '3' ? `${Number(props.semesterYear) - 2}` :
          props.match.params.list === '2' ?
            props.semesterYear :
            props.semester === 'A' ?
              `${Number(props.semesterYear) + 1}` : props.semesterYear,
        semes: props.match.params.list === '1'
          || props.match.params.list === '0' ?
          props.semester === 'A' ?
            'S' :
            'A' : props.semester,
        yearsemester: props.match.params.list === '3' ? `${Number(props.semesterYear) - 2},${props.semester}` :
          props.match.params.list === '2' ?
            `${props.semesterYear},${props.semester}` :
            props.semester === 'A' ?
              `${Number(props.semesterYear) + 1},S` : `${props.semesterYear},A`,
        applyResult: '0',
        studentStatus3: '0',
        orderOption: ' ',
      },
      picModal: false,
      semesterYear: new Date().getFullYear(),
    }
  }

  componentDidMount() {
    this.handleFetch()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.list !== nextProps.match.params.list) {
      this.setState({
        filterForm: {
          searchText: '',
          selClass: '0',
          year: nextProps.match.params.list === '3' ? `${Number(nextProps.semesterYear) - 2}` :
            // nextProps.match.params.list === '0' ?
            //   `${Number(nextProps.semesterYear) + 1}` : 
            nextProps.semester === 'A' ?
              `${Number(nextProps.semesterYear) + 1}` : nextProps.semesterYear,
          semes: nextProps.match.params.list === '1'
            || nextProps.match.params.list === '0' ?
            nextProps.semester === 'A' ?
              'S' :
              'A' : nextProps.semester,
          yearsemester: nextProps.match.params.list === '3' ? `${Number(nextProps.semesterYear) - 2},${nextProps.semester}` :
            // nextProps.match.params.list === '0' ?
            //   `${Number(nextProps.semesterYear) + 1},${nextProps.semester}` :
            nextProps.semester === 'A' ?
              `${Number(nextProps.semesterYear) + 1},S` : `${nextProps.semesterYear},A`,
          applyResult: '0',
          studentStatus3: '0',
          orderOption: ' ',
          selStudentType: '0',
        },
      },
        () => this.handleFetch(nextProps),
      )
    } else if (this.props.students !== nextProps.students) { //削除学生
      this.handleFetch(nextProps)
    } else if (this.props.match.params._id !== nextProps.match.params._id) { //削除学生

      this.handleFetch(nextProps)
    }
  }

  handleFetch = async (props = this.props) => {
    const {
      match,
      history,
      handleLazyLoad,
      firebase
    } = props
    const { list } = match.params
    const { isEdit, year, semes, filterForm, students } = this.state
    // console.log(studentsL)
    // handleLazyLoad('classroom')
    const { companyObj } = this.props
    const { semesterYear, semester } = this.state
    const data = await Parse.queryData('Classroom', { companyId: companyObj.objectId, semesterYear: semesterYear, semester: semester, isEnableClass: true, });
    this.setState({ classroom: data, classroomL: true });

    // db.collection("Classroom")
    //   .where('semesterYear', '==', semesterYear)
    //   .where('semester', '==', semester)
    //   .where('isEnableClass', '==', true)
    //   .orderBy('classSchool')
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }));
    //     this.setState({ classroom: data, classroomL: true });
    //   })
    const data1 = await Parse.queryData('Grade1', { companyId: companyObj.objectId });
    this.setState({ grade: data1, gradeL: true });
    // handleLazyLoad('grade')
    // db.collection("Grade")
    //   .orderBy('testDate', 'desc')
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }));
    //     this.setState({ grade: data, gradeL: true });
    //   });
    const data3 = await Parse.queryData('Students', { companyId: companyObj.objectId });
    this.setState({ students: data3, studentsL: true });

    const Calendar = await Parse.queryData('Calendar', { companyId: companyObj.objectId });
    // handleLazyLoad('students')
    // db.collection("Students")
    //   // .where('semesterYear', '==', semesterYear)
    //   // .where('semester', '==', semester)
    //   // .orderBy('studentId')
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }));
    //     // console.log(data)
    //     this.setState({ students: data, studentsL: true });
    //   });
    // if (!isEdit) {
    const { _id } = match.params
    // firebase.firestore().doc(`/Students/${_id}/Monthly/${Moment(new Date()).format('YYYY-MM')}`).get().then(snap => {
    //   if (snap.data())
    //     this.setState({ semesterNum: snap.data().semesterNum })
    //   // console.log(snap.data().semesterNum)
    // })
    let obj = {}
    if (_id) {
      obj = students.find(item => item.objectId === _id) || {}
      // } else {
      //   obj = students[0];
      //   history.push(`/StudentPage/${list}/${students[0].objectId}`);
    }
    // this.setState({ doc: obj, form: obj, filterForm: { ...filterForm, selClass: '0' }, selTypeId: list }, this.studentSelect(props));
    this.setState({ doc: obj, form: obj, addNum: obj.addNum || 1, selTypeId: list, isEdit: false, Calendar },
      this.studentSelect(props),
    )
    // }
  }

  refetch = async (dbName, stateName) => {
    const data = await Parse.queryData(dbName, { companyId: this.props.companyObj.objectId });
    this.setState({ [stateName]: data })
  }

  studentSelect = (props = this.props) => {
    const { match } = props
    const { list, _id } = match.params
    const { extStudents, filterForm, students } = this.state
    const {
      year,
      semes,
      applyResult,
      orderOption,
      selClass,
      studentStatus3,
      selStudentType,
    } = filterForm
    let currentStudents = students
      .concat(extStudents)
      .sort((a, b) => a.semesterYear - b.semesterYear) //studentsList.sort((a, b) => a.semester - b.semester).sort((a, b) => a.semesterYear - b.semesterYear);
    let optStudents // 沒有搜尋時的選單
    let resStudents // 有加搜尋的東西 resStudents
    let tmp = []
    if (list === '1') {
      // 募集
      tmp = currentStudents
        .filter(item => item.applyResult !== '5' && item.applyResult !== '6' && item.applyResult !== '3')
        .filter(item => item.studentType === '1')
        .sort((a, b) =>
          (
            '' + a[orderOption === ' ' ? 'recruitNum' : orderOption]
          ).localeCompare(b[orderOption === ' ' ? 'recruitNum' : orderOption]),
        )
        // tmp = currentStudents.sort((a, b) => ('' + a.studentId).localeCompare(b.studentId))
        .filter(
          item => item.semesterYear === year && item.semester === semes,
          // && item.studentType === '1' // 在籍生&& item.studentType === '1' 聽講生不用募集
        )
      if (applyResult === '000') {
        // applyResult 0 全部，000 未定
        tmp = tmp.filter(item => !item.applyResult || item.applyResult === '') // 未定
        // } else if (applyResult === '1' || applyResult === '2') {
        //   tmp = tmp.filter(item => item.applyResult === applyResult); // 在籍生
        // } else if (applyResult === '5') {
      } else if (applyResult !== '0') {
        tmp = tmp.filter(item => item.applyResult === applyResult) // 不是申請中的
      }
      // console.log(tmp)
      optStudents = tmp.map(item => ({
        // [${item.studentStatusText && item.studentStatusText.substr(0, 2)}]
        key: item.objectId,
        text: `[${!!item.applyResult
          ? (item.applyResultText && item.applyResultText.substr(0, 2)) || ''
          : '未定'
          }] ${item.recruitNum || '----'} 
          ${item.studentId ? `学${item.studentId
            ? `${item.semesterYear.substr(-2)}(${item.semester})${item.studentId
            }`
            : '----'
            } ` : ''}
          ${item.jpnName || ''}`,
        value: item.objectId,
        classroom: item.classSchool,
        semester: item.semester,
        semesterYear: item.semesterYear,
        studentId: item.studentId,
      }))
      // console.log(optStudents)
      // currentStudents = currentStudents.map(item => ({ ...item, text: `${item.recruitNum || "(なし)"} 学${item.studentId ? `${item.semesterYear.substr(-2)}(${item.semester})${item.studentId}` : '(なし)'} ${item.jpnName}` }));
    } else if (list === '2') {
      // 在籍
      let tmp = currentStudents
        .sort((a, b) => ('' + a.studentId).localeCompare(b.studentId))
        .filter(
          item =>
            item.studentStatus !== '50' &&
            item.studentStatus !== '51' &&
            item.studentStatus !== '31' &&
            item.studentStatus !== '32' &&
            item.studentStatus !== '0' &&
            (item.applyResult === '1' || item.studentType === '2'), //在學 || 聽講
        )

      if (selStudentType && selStudentType !== '0' && selStudentType !== '-1' && selStudentType !== '3') {
        tmp = tmp.filter(item => item.studentType === selStudentType) // 在籍生
      }

      if (selClass && selClass !== '0' && selClass !== '-1') {
        tmp = tmp.filter(item => item.classSchool === selClass) // 在籍生
      } else if (selClass === '-1') {
        // console.log('-1')
        tmp = tmp.filter(item => !item.classSchool) // 找出所有還沒編班的
      }

      optStudents = tmp.map(item => ({
        key: item.objectId,
        text: `[${item.classSchool || '-'}] ${item.studentId
          ? `${item.semesterYear.substr(-2)}(${item.semester})${item.studentId
          }`
          : '----'
          } ${item.jpnName || ''}`,
        value: item.objectId,
        classroom: item.classSchool,
        semester: item.semester,
        semesterYear: item.semesterYear,
        studentId: item.studentId,
      }))
    } else if (list === '3') {
      //卒業
      let tmp = currentStudents
        .sort((a, b) => ('' + a.studentId).localeCompare(b.studentId))
        .filter(
          item =>
            item.semesterYear === year &&
            item.semester === semes &&
            (item.studentStatus === '50' ||
              item.studentStatus === '51' ||
              item.studentStatus === '31' ||
              item.studentStatus === '32' ||
              item.studentStatus === '0'),
        )

      if (selStudentType && selStudentType !== '0' && selStudentType !== '-1' && selStudentType !== '3') {
        tmp = tmp.filter(item => item.studentType === selStudentType) // 在籍生
      }

      if (selClass && selClass !== '0' && selClass !== '-1') {
        tmp = tmp.filter(item => item.classSchool === selClass) // 在籍生
      } else if (selClass === '-1') {
        // console.log('-1')
        tmp = tmp.filter(item => !item.classSchool) // 找出所有還沒編班的
      }

      if (studentStatus3 !== '0') {
        tmp = tmp.filter(item => item.studentStatus === studentStatus3)
      }

      optStudents = tmp.map(item => ({
        key: item.objectId,
        text: `[${item.studentStatusText.substr(0, 2) ||
          ''}] [${item.classSchool || '-'}] ${item.studentId
            ? `${item.semesterYear.substr(-2)}(${item.semester})${item.studentId
            }`
            : '----'
          } ${item.jpnName}`,
        value: item.objectId,
        classroom: item.classSchool,
        semester: item.semester,
        semesterYear: item.semesterYear,
        studentId: item.studentId,
      }))
    } else if (list === '0') {
      // 問合
      let tmp = []
      tmp = currentStudents
        // .sort((a, b) => ('' + a[orderOption === ' ' ? 'enquiryNum' : orderOption]).localeCompare(b[orderOption === ' ' ? 'enquiryNum' : orderOption]))
        .filter(
          item =>
            item.semesterYear === year &&
            item.semester === semes
          // &&item.applyResult === '5',
        ).sort((a, b) => ('' + a.recruitNum).localeCompare(b.recruitNum))
      optStudents = tmp.map(item => ({
        // [${item.studentStatusText && item.studentStatusText.substr(0, 2)}]
        key: item.objectId,
        text: `[${!!item.applyResult
          ? (item.applyResultText && item.applyResultText.substr(0, 2)) || ''
          : '未定'
          }] ${item.recruitNum || '----'} ${item.homeName || ''}`,
        value: item.objectId,
        semester: item.semester,
        semesterYear: item.semesterYear,
      }))
    }
    if (!optStudents.length) {
      optStudents.push({ key: '-1', text: '(無)', value: '' })
    }
    resStudents = optStudents
    let selIndex = optStudents.findIndex(item => item.key === _id)
    this.setState({ resStudents, optStudents, selIndex, loading: false })
  }

  isEmpty = obj => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false
    }
    return true
  }

  handleUpdateSetting = (event, data) => {
    const { form, doc } = this.state
    const { name, value } = data
    // console.log(name, value)
    if (this.isEmpty(form)) {
      this.setState({ form: { ...doc, [name]: value } })
    } else {
      this.setState({ form: { ...form, [name]: value } })
    }
    // graDate選用份重新render
    if (name === 'graDate') {
      this.setState({ form: { ...form, [name]: value } }, this.forceUpdate())
      // } else if (name === 'plannedWayOut1') {
      //   if (value === form.plannedWayOut1) {
      //     this.setState({ form: { ...form, [name]: -1 } }, this.forceUpdate());
      //   } else
      //     this.setState({ form: { ...form, [name]: value } }, this.forceUpdate());
    } else
      this.setState({ form: { ...form, [name]: value } }, this.forceUpdate())
  }

  addAddr = () => {
    const { form, doc, addNum } = this.state
    let newForm = { ...form }
    if (addNum > 1)
      [2, 3, 4, 5].map((item, index) => {
        if (index < addNum - 1) {
          newForm[`jpnPostcode${item}`] = form[`jpnPostcode${item - 1}`]
          newForm[`jpnAddr${item}`] = form[`jpnAddr${item - 1}`]
        }
      })
    newForm.jpnPostcode1 = form.jpnPostcode
    newForm.jpnAddr1 = form.jpnAddr
    newForm.jpnPostcode = ''
    newForm.jpnAddr = ''

    // console.log(addNum, `jpnAddr${addNum}`, newForm[`jpnAddr${addNum}`])

    this.setState({ form: { ...newForm }, addNum: newForm[`jpnAddr${addNum}`] ? addNum + 1 : addNum })
  }

  deleteAddr = (num) => {
    const { form, doc, addNum } = this.state
    let newForm = { ...form }
    // console.log(num, addNum, `jpnAddr${addNum}`, newForm[`jpnAddr${addNum}`])
    if (num === 1) {
      if (addNum === 2) {
        newForm[`jpnPostcode`] = form[`jpnPostcode1`];
        newForm[`jpnAddr`] = form[`jpnAddr1`];
      } else {
        newForm[`jpnPostcode1`] = form[`jpnPostcode2`];
        newForm[`jpnAddr1`] = form[`jpnAddr2`];
        [2, 3, 4, 5].map((item, index) => {
          if (item < addNum - 1) {
            newForm[`jpnPostcode${item}`] = form[`jpnPostcode${item + 1}`]
            newForm[`jpnAddr${item}`] = form[`jpnAddr${item + 1}`]
          }
        })
      }
    }
    if (num > 1) {
      // let findIndex = [2, 3, 4, 5].findIndex(item => item === num)
      [2, 3, 4, 5].map((item, index) => {
        if (item >= num && item < addNum - 1) {
          newForm[`jpnPostcode${item}`] = form[`jpnPostcode${item + 1}`]
          newForm[`jpnAddr${item}`] = form[`jpnAddr${item + 1}`]
        }
      })
    }
    this.setState({ form: { ...newForm }, addNum: addNum - 1 })
  }

  setRelativeObj = setting => {
    const { form, doc } = this.state
    this.setState({ isEdit: false }, () => {
      this.setState({ form: { ...form, ...setting } }, () => {
        this.setState({ isEdit: true })
      })
    })
  }

  saveUpdateSetting = async () => {
    const {
      firebase,
      match,
      updateStudents,
      formSet,
      optSet,
      calendar,
      systemObj,
    } = this.props
    const { form, addNum, doc } = this.state
    const { semesterYear, semester, _id } = form

    // form._id = match.params._id

    if (!doc.lodgingCost) {
      form.lodgingCost =
        form.payMyself && form.payMyself !== ''
          ? '1'
          : form.remitTanceFromFore && form.remitTanceFromFore !== ''
            ? '2'
            : form.payInjap && form.payInjap !== ''
              ? '3'
              : form.scholarship && form.scholarship !== ''
                ? '4'
                : '5'
    }
    // form.courseStartDate = form.semesterYear === '2020' && form.semester === 'S' ? systemObj.currentStartDate : ''

    for (const key in form) {
      const element = formSet.find(item => item.key === key)
      if (element && element.type === 'select') {
        form[`${key}Text`] = getOptionTextByValue(
          optSet,
          element.value,
          form[key],
        )
        // console.log("student method 508 ", element.value, form[key], getOptionTextByValue(optSet, element.value, form[key]));
      }

      form.addNum = addNum
      if (addNum < doc.addNum) {
        for (let i = addNum; i < doc.addNum + 1; i++) {
          delete form[`jpnPostcode${i}`]
          delete form[`jpnAddr${i}`]
        }
      }

      if (key === 'applyResult' && doc.applyResult !== form.applyResult && form.applyResult === '1') {
        form.studentStatus = '30'
      }

      if (key === 'courseEndDate' && doc.courseEndDate !== form.courseEndDate) {
        //若設定卒業予定日刪除其後的出席紀錄
        console.log('courseEndDate', form.courseEndDate)
        if (form.courseEndDate) {
          const weekRangeArr = getWeekRangeArr({ semesterYear, semester })
          for (const week of weekRangeArr) {
            const d = new Date(week[4])
            if (d < new Date(form.courseEndDate)) {
              continue
            }
            for (let k = 0; k < week.length; k++) {
              const d = new Date(week[k])
              const startDate = new Date(week[0])
              // if (calendar.filter(eventObj =>eventObj.eventDate === week[k] && eventObj.status === '3',).length) {
              //   continue // 如果這天學校沒上課的話，就跳過
              // }
              if (d <= new Date(form.courseEndDate)) {
                continue
              }
              [0, 1, 2, 3].map(item =>
                // firebase.firestore().doc(`/Students/${_id}/Daily/${week[k]}/Rollcall/${item}`).delete() //刪除錯誤節数
                console.log(item)
              )
              // firebase.firestore().doc(`/Students/${_id}/Daily/${week[k]}`).delete()
              //刪除中退後的月出席
              if ((startDate.getMonth() > new Date(form.courseEndDate).getMonth() && startDate.getFullYear() === new Date(form.courseEndDate).getFullYear()) ||
                startDate.getFullYear() > new Date(form.courseEndDate).getFullYear()) {
                // let refff = firebase.firestore().doc(`/Students/${form._id}/Monthly/${startDate.getFullYear()}-${set0String(startDate.getMonth() + 1)}`)
                let refff = true
                if (refff)
                  // refff.delete()//刪除錯誤節数
                  console.log('delete', `${startDate.getFullYear()}-${set0String(startDate.getMonth() + 1)}`)
              }
              //刪除中退後的週出席
              if (startDate > new Date(form.courseEndDate)) {
                const yw = getWeekNumber(startDate);
                const yearweek = `${yw[0]}-${set0String(yw[1])}`;
                // let refff = firebase.firestore().doc(`/Students/${form._id}/Weekly/${yearweek}`)
                let refff = true
                if (refff) {
                  // refff.delete()//刪除錯誤節数
                  console.log('delete', yearweek)
                }
              }
              console.log('delete', week[k])
            }
          }
        }
      }

      // if (key === 'lessonStartDate') {
      //   //若設定授業日刪除其前的出席紀錄
      //   console.log('lessonStartDate', form.lessonStartDate)
      //   if (form.lessonStartDate) {
      //     const weekRangeArr = getWeekRangeArr({ semesterYear, semester })
      //     for (const week of weekRangeArr) {
      //       const d = new Date(week[0])
      //       if (d > new Date(form.lessonStartDate)) {
      //         continue
      //       }
      //       for (let k = 0; k < week.length; k++) {
      //         const startDate = new Date(week[k])
      //         if (
      //           calendar.filter(
      //             eventObj =>
      //               eventObj.eventDate === week[k] && eventObj.status === '3',
      //           ).length
      //         ) {
      //           continue // 如果這天學校沒上課的話，就跳過
      //         }
      //         if (startDate >= new Date(form.lessonStartDate)) {
      //           continue
      //         }
      //         ;[0, 1, 2, 3].map(item =>
      //           firebase
      //             .firestore()
      //             .doc(`/Students/${_id}/Daily/${week[k]}/Rollcall/${item}`)
      //             .delete()
      //             .then //刪除錯誤節数
      //             (),
      //         )
      //         firebase
      //           .firestore()
      //           .doc(`/Students/${_id}/Daily/${week[k]}`)
      //           .delete() //刪除錯誤節数
      //         console.log('delete', week[k])
      //       }
      //     }
      //   }
      // }
    }
    // firebase
    //   .firestore()
    //   .collection('Students')
    //   .doc(form._id)
    //   .update(form)
    //   .then(docRef => {
    //     this.setState({ isEdit: false, doc: form })
    //     updateStudents(form)
    //   })
    await Parse.saveData('Students', form).then(() => this.setState({ isEdit: false, doc: form }))
  }

  cancelUpdateSetting = () => {
    const { form, doc } = this.state
    this.setState({ isEdit: false, form: doc })
  }

  printDiv = () => {
    window.print() // 這是印整個頁面的
  }

  handleClose = () => {
    this.setState({
      show: false,
      // , form: { classSchool: '-1', teacher: '-1' }
    })
  }

  handleShow = () => {
    const jpForm = {}
    // Object.keys(form).filter(docObjName => formSet.find(item => item.key === docObjName)).forEach((obj) => {
    //   jpForm[obj] = cjkConv.cjk2jp(form[obj]);
    // });
    // console.log('handleShow', jpForm);
    this.setState({ show: true, jpForm })
  }

  handleChangeJpn = event => {
    const fieldName = event.target.name
    const fleldVal = event.target.value
    this.setState({ jpForm: { ...this.state.jpForm, [fieldName]: fleldVal } })
  }

  handleSubmit = async () => {
    const { companyObj, match, refetch } = this.props
    const { list = '1' } = match.params
    const { formModal } = this.state
    const {
      value0,
      value1,
      studentStatus,
      semesterYear,
      semester,
      studentType,
      recruitNum,
      enquiryDate,
    } = formModal
    this.setState({ loading: true })
    const obj =
      list === '0'
        ? {
          recruitNum,
          enquiryDate,
          semesterYear,
          semester,
          semesterText: semester === 'S' ? '春' : '秋',
          studentStatus: ' ',
          studentStatus2: '10',
          studentType: studentType || '1',
          applyResult: '5',
          applyResultText: '問合せ',
          companyId: companyObj.objectId,
          companyName: companyObj.name,
        }
        : {
          semesterYear,
          semester,
          semesterText: semester === 'S' ? '春' : '秋',
          studentStatus,
          studentStatus2: studentStatus === '10' ? '11' : studentStatus,
          recruitNum: value0 || '',
          studentId: value1 || '',
          studentType: studentType || '1',
          stayQualify: studentType === '1' ? '11' : '-1',
          applyResult:
            studentStatus === '10' ? '-1' : studentStatus === ' ' ? '5' : '1',
          applyResultText:
            studentStatus === '10'
              ? '入管申請'
              : studentStatus === ' '
                ? '問合せ'
                : '交付 (在籍)',
          companyId: companyObj.objectId,
          companyName: companyObj.name,
          // createAt: new Date(),
        }

    // const db = firebase.firestore()
    // let ref = db.collection('Students').doc()
    const db = Parse.Object.extend("Students");
    const newDoc = new db();
    const ref = await newDoc.save();
    if (ref.id) {
      obj.objectId = ref.id
      // .set(obj)
      Parse.saveData('Students', obj)
        .then(async (ref) => {
          // console.log('Added document with ID: ', ref.id);
          await this.refetch('Students', 'students')
          this.setState({
            loading: false,
            show: false,
            formModal: {
              // 新規作成
              ...formModal,
              value0: '',
              value1: '',
              studentStatus:
                list === '0'
                  ? ' '
                  : list === '1'
                    ? '10'
                    : list === '2'
                      ? '30'
                      : '',
              recruitNum: '',
              enquiryDate: Moment(new Date()).format('YYYY/MM/DD'),
              studentType: '1',
            },
          })
          this.props.history.push(
            `/admin/StudentPage/${studentStatus === '10' ? '1' : studentStatus === '30' ? '2' : '0'
            }/${obj.objectId}`,
          )
        })
    }
    // if (studentStatus === '10') {
    //   db.doc('Units/aiaso').set({ recruitNum: value0 }, { merge: true })
    // } else if (studentStatus === '30' && studentType === '1') {
    //   db.doc('Units/aiaso').set({ studentId1: value1 }, { merge: true })
    // } else if (studentStatus === '30' && studentType === '2') {
    //   db.doc('Units/aiaso').set({ studentId2: value1 }, { merge: true })
    // }
  }

  handleGoto = (event, { name, value }) => {
    const { match, history } = this.props
    const { list = '1' } = match.params
    const { filterForm, students } = this.state
    history.push(`/admin/StudentPage/${list}/${value}`)

    const obj = students.find(item => item.objectId === value) || {}
    this.setState({
      doc: obj,
      form: obj,
      filterForm: { ...filterForm, searchText: '' },
    })
  }

  onChangeRadio = (e, data) => {
    const { form } = this.state
    const { name, value } = data;
    this.setState({ form: { ...form, [name]: value } })
  }

  handleChange = (event, { name, value }) => {
    const { match, history } = this.props
    const { filterForm, students } = this.state
    const { _id, list } = match.params

    if (name === 'yearsemester') {
      const year = value.substring(0, 4)
      const semes = value.substring(5)
      const yearsemester = value
      this.setState(
        {
          filterForm: {
            ...filterForm,
            year,
            semes,
            selClass: '0',
            yearsemester,
            searchText: '',
          },
        },
        () => this.studentSelect(),
      )
    } else if (name === 'selClass') {
      const selClass = value
      this.setState(
        { filterForm: { ...filterForm, selClass, searchText: '' } },
        () => this.studentSelect(),
      )
    } else if (name === 'selStudentType') {
      const selStudentType = value
      this.setState(
        { filterForm: { ...filterForm, selStudentType, searchText: '' } },
        () => this.studentSelect(),
      )
    } else if (name === 'applyResult') {
      const applyResult = value
      this.setState(
        { filterForm: { ...filterForm, applyResult, searchText: '' } },
        () => this.studentSelect(),
      )
    } else if (name === 'orderOption') {
      const orderOption = value
      this.setState(
        { filterForm: { ...filterForm, orderOption, searchText: '' } },
        () => this.studentSelect(),
      )
    } else if (name === 'studentStatus3') {
      const studentStatus3 = value
      this.setState(
        { filterForm: { ...filterForm, studentStatus3, searchText: '' } },
        () => this.studentSelect(),
      )
    } else if (name === 'selTypeId') {
      // 選募集/在籍/卒業
      history.push(
        `/admin/StudentPage/${value}/${_id ||
        (students.length && students[0].objectId) ||
        ''}`,
      )
      this.setState(
        { filterForm: { ...filterForm, searchText: '' }, selTypeId: value },
        () => this.studentSelect(),
      )
    }
  }

  handleChangeModal = (event, { name, value }) => {
    const { formModal, form } = this.state
    // if (name === '_value0') {
    //   this.setState({
    //     formModal: { ...formModal, [name]: value, value0: value },
    //   })
    // } else if (name === '_value1') {
    //   this.setState({
    //     formModal: { ...formModal, [name]: value, value1: value },
    //   })
    // } else 
    if (name === 'studentStatus') {
      this.setState({ formModal: { ...formModal, [name]: value } })
    } else {
      this.setState({ formModal: { ...formModal, [name]: value } })
    }
  }

  handleClick = (event, d) => {
    let fleldVal = event.target.value //d.value;
    console.log(fleldVal)
    const { match, history } = this.props
    const { list = '1' } = match.params
    const { optStudents, students } = this.state
    history.push(`/admin/StudentPage/${list}/${fleldVal}`)
    const obj = students.find(item => item.objectId === fleldVal) || {}
    const selIndex = optStudents.findIndex(item => item.key === fleldVal) || ''
    this.setState({ doc: obj, form: obj, selIndex })
  }

  contextRef = createRef()

  handleSearch = (e, { value }) => {
    // const { students } = this.props
    const { optStudents, students, filterForm } = this.state
    // const { filterForm } = this.state
    let allStudents = students.map(item => ({
      key: item.objectId,
      text: `[${item.classSchool || '-'}] ${item.studentId
        ? `${item.semesterYear.substr(-2)}(${item.semester})${item.studentId
        }`
        : '----'
        } ${item.jpnName || ''}`,
      value: item.objectId,
      classroom: item.classSchool,
      semester: item.semester,
      semesterYear: item.semesterYear,
      studentId: item.studentId,
    }))
    if (value) {
      // 搜尋值中間可以插入任意值 例如搜尋'程雪原' 可以找到 '程 雪原'
      const looseSearchValue = RegExp(
        value.split('').reduce((pattern, item) => pattern + item + '.*', '.*'),
      )

      const resStudents = allStudents.filter(item =>
        item.text.match(looseSearchValue),
      )
      this.setState({
        resStudents,
        filterForm: { ...filterForm, searchText: value },
      })
    } else {
      this.setState({
        resStudents: optStudents,
        filterForm: { ...filterForm, searchText: '' },
      })
    }
  }

  handleUploadStart = () =>
    this.setState({ isUploading: true, progress: 0, disabled: true })
  handleProgress = progress => this.setState({ progress })
  handleUploadError = error => {
    this.setState({ isUploading: false, error: true })
    console.error(error)
  }

  handleUploadSuccess = async filename => {
    const { firebase, match } = this.props
    const { form, downloadURLs, filenames } = this.state

    const downloadURL = await firebase
      .storage()
      .ref('images')
      .child(filename)
      .getDownloadURL()

    this.setState(oldState => ({
      filenames: [...oldState.filenames, filename],
      downloadURLs: [...oldState.downloadURLs, downloadURL],
      uploadProgress: 100,
      isUploading: false,
      form: { ...form, photo: downloadURL, photos: filename },
      disabled: false,
      picModal: false,
    }))
  }

  openPicModal = () => this.setState({ picModal: true })

  closePicModal = () => this.setState({ picModal: false })

  removePic = () => {
    const { form } = this.state
    const newForm = { ...form }
    this.setState({
      form: { ...newForm, photo: '', photos: '' },
      picModal: false,
    })
  }
  getToday = () => {
    return new Date().toISOString()
  }
  render() {
    const { optSet, formSet, match, history, systemObj, companyObj } = this.props
    const { doc, form, isEdit, selTypeId, resStudents, show, formModal, filterForm, semesterNum, selIndex, Calendar, classroom, grade, students } = this.state
    const { value0, value1, studentStatus, studentType, loading, recruitNum, enquiryDate } = formModal
    const { yearsemester, applyResult, orderOption, selClass, selStudentType, searchText, studentStatus3, year, semes } = filterForm
    const { statusKey = '', list = '1' } = match.params
    const semester = { year, semes } //往下傳至PDF
    // const options0 = [{ key: '0', text: '-- 選択 --', value: '' }]
    // const options1 = [{ key: '0', text: '-- 選択 --', value: '' }]

    // if (systemObj && systemObj.recruitNum) {
    //   options0.push({
    //     key: 'a1',
    //     text: `既：${systemObj.recruitNum} 現：${Number(systemObj.recruitNum) +
    //       1}`,
    //     value: (Number(systemObj.recruitNum) + 1).toString(),
    //   })
    // }
    // if (systemObj && systemObj.studentId1) {
    //   options1.push({
    //     key: 'a2',
    //     text: `既：${systemObj.studentId1} 現：${Number(systemObj.studentId1) +
    //       1}`,
    //     value: (Number(systemObj.studentId1) + 1).toString(),
    //   })
    // }
    // if (systemObj && systemObj.studentId2) {
    //   options1.push({
    //     key: 'a3',
    //     text: `既：${systemObj.studentId2} 現：A${Number(
    //       systemObj.studentId2.substr(1),
    //     ) + 1}`,
    //     value: 'A' + (Number(systemObj.studentId2.substr(1)) + 1),
    //   })
    // }

    const semesterYearArr = getOptionsSelByKey(optSet, 'semesterYear')
    const semestersArr = getOptionsSelByKey(optSet, 'semester')
    const classSchoolArr = [
      { key: '0', text: '- クラス選択 -', value: '0' },
      { key: '-1', text: 'ALL', value: '-1' },
    ].concat(getOptionsSelByKey(optSet, 'classSchool'))
    const applyResultArr = [
      { key: '0', text: '- 申請結果 -', value: '0' },
      { key: '000', text: 'ALL', value: '000' },
    ].concat(getOptionsSelByKey(optSet, 'applyResult'))
    applyResultArr.splice(6, 1) // remove 問合
    const orderOptionArr = [
      { key: '0', text: '- 選択 -', value: ' ' },
      { key: '1', text: '募集番号', value: 'recruitNum' },
      { key: '2', text: '学籍番号', value: 'studentId' },
      { key: '3', text: '入力時間', value: 'createAt' },
    ]
    const studentTypeArr = [
      { key: '0', text: '- 学生別 -', value: '0' },
    ].concat(getOptionsSelByKey(optSet, 'studentType'))

    const selStudentTypeArr = [
      { key: '0', text: '- 学生別 -', value: '0' },
      { key: '3', text: 'ALL', value: '3' },
    ].concat(getOptionsSelByKey(optSet, 'studentType'))

    const ssArr = getOptionsSelByKey(optSet, 'studentStatus') //.slice(1, -1);
    const studentStatusArr = [
      { key: '0', text: '- 学生状態 -', value: '0' },
    ].concat(ssArr.slice(1, ssArr.length - 1))
    return (
      <div className='content'>
        <div
        // style={{ padding: '10px' }}
        >
          <Ref innerRef={this.contextRef}>
            <Grid>
              <Grid.Column width={2}>
                <Sticky context={this.contextRef}>
                  <Header as="h3">
                    {list === '3'
                      ? '卒業中退除籍'
                      : list === '1'
                        ? '2-0 募集'
                        : list === '0'
                          ? '1-0 問合'
                          : '3-0 在籍'}
                  学生情報
                </Header>
                  <Form size="small">
                    {list !== '0' ? (
                      <Select
                        style={{
                          minWidth: '100px',
                          marginBottom: '4px',
                        }}
                        compact
                        options={selType}
                        onChange={this.handleChange}
                        disabled={isEdit}
                        value={selTypeId}
                        name="selTypeId"
                      />
                    ) : null}
                    {!isEdit && list !== '3' ? (
                      <Button
                        style={{ marginBottom: '4px' }}
                        size="tiny"
                        primary
                        onClick={() => this.setState({ show: true })}
                        icon="add user"
                        content="新規作成"
                      />
                    ) : null}{' '}
                    <br />
                    {list !== '2' ? (
                      <Select
                        style={{
                          marginBottom: '4px',
                        }}
                        compact
                        fluid
                        options={semesterArr}
                        onChange={this.handleChange}
                        disabled={isEdit}
                        value={yearsemester}
                        name="yearsemester"
                      />
                    ) : null}
                    {list === '1' ? (
                      <Select
                        style={{
                          marginBottom: '4px',
                        }}
                        compact
                        fluid
                        options={applyResultArr}
                        onChange={this.handleChange}
                        disabled={isEdit}
                        value={applyResult}
                        name="applyResult"
                      />
                    ) : null}
                    {list === '1' ? (
                      <Select
                        style={{
                          marginBottom: '4px',
                        }}
                        compact
                        fluid
                        options={orderOptionArr}
                        onChange={this.handleChange}
                        disabled={isEdit}
                        value={orderOption}
                        name="orderOption"
                      />
                    ) : null}
                    {list !== '1' && list !== '0' ? (
                      <Select
                        style={{
                          minWidth: '100px',
                          marginBottom: '4px',
                        }}
                        compact
                        fluid
                        // options={optionsClass}
                        options={selStudentTypeArr}
                        onChange={this.handleChange}
                        disabled={isEdit}
                        value={selStudentType}
                        name="selStudentType"
                      />
                    ) : null}
                    {list !== '1' && list !== '0' ? (
                      <Select
                        style={{
                          minWidth: '100px',
                          marginBottom: '4px',
                        }}
                        compact
                        fluid
                        // options={optionsClass}
                        options={classSchoolArr}
                        onChange={this.handleChange}
                        disabled={isEdit}
                        value={selClass}
                        name="selClass"
                      />
                    ) : null}
                    {list === '3' ? (
                      <Select
                        style={{
                          marginBottom: '4px',
                        }}
                        compact
                        fluid
                        options={studentStatusArr}
                        onChange={this.handleChange}
                        value={studentStatus3}
                        name="studentStatus3"
                      />
                    ) : null}
                    <Form.Input
                      style={{ marginBottom: '4px' }}
                      placeholder="Search..."
                      disabled={isEdit}
                      onChange={this.handleSearch}
                      value={searchText}
                    />
                  </Form>
                  {loading ? <DimmerImg /> : <select
                    multiple
                    className="form-control ui form fluid search dropdown"
                    style={{
                      height: '600px',
                      width: '100%',
                      fontSize: '11px',
                      overflowX: 'auto',
                    }}
                    name="studentSel"
                    onChange={this.handleClick}
                    // value={[doc.objectId]}
                    disabled={isEdit}
                  >
                    {resStudents.map(item => (
                      <option key={item.key} className="item" value={item.value}>
                        {item.text}
                      </option>
                    ))}
                  </select>}
                  <p style={{ textAlign: 'right' }}>{resStudents.length}名</p>
                </Sticky>
              </Grid.Column>
              {doc && doc.objectId ? (
                <Grid.Column width={14}>
                  <Segment clearing vertical>
                    <Header
                      floated="right"
                      className=" no-print"
                      style={{ margin: '0px' }}
                    >
                      <Form>
                        {/* <Form.Select
                  scrolling
                  style={{ minWidth: '400px' }}
                  fluid
                  // search 
                  options={optionStudents}
                  disabled={isEdit}
                  onChange={this.handleGoto}
                  value={match.params._id} size="tiny" />&nbsp;&nbsp;&nbsp; */}
                        {/* <Button basic onClick={() => history.goBack()}><Icon name='reply' /> 前のページ</Button> */}
                        {/* {!isEdit && loadingAll ? <Segment>
                    <Dimmer active inverted={companyObj.inverted}>
                      <Loader inverted={companyObj.inverted}>Loading</Loader>
                    </Dimmer>
                    <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                  </Segment> : null} */}
                        {/* {this.state.isEdit ?
                  <Button floated='right'  color='orange' onClick={this.handleShow} style={{ marginRight: '10px' }}
                  >日本語漢字変換</Button> : null} */}
                        {/* &nbsp;&nbsp;<Button floated='right' basic size="small" onClick={() => history.push(`/${statusKey}`)}>前のページ</Button> */}
                        {/* &nbsp;&nbsp;&nbsp; */}
                        {/* <Button.Group floated='right'>
                    {isEdit ? <Button positive onClick={this.saveUpdateSetting} icon='save' content='保存' /> : <Button color='green' onClick={() => this.setState({ isEdit: true })} icon='edit' content="編集" />}
                    {isEdit ? <Button basic onClick={this.cancelUpdateSetting} icon='remove' content='キャンセル' /> : null}
                  </Button.Group> */}
                        {/* <br /> */}
                      </Form>
                    </Header>
                    {isEdit ? (
                      <Button
                        floated="left"
                        as="label"
                        basic
                        inverted={companyObj.inverted}
                        onClick={this.openPicModal}
                        content={
                          <>
                            <div style={{ position: 'relative' }}>
                              <Image
                                src={
                                  form.photo ||
                                  'https://react.semantic-ui.com/images/wireframe/square-image.png'
                                }
                                size="tiny"
                                style={{ marginBottom: '0px' }}
                              />
                              {isEdit ? (
                                <Icon
                                  name="edit"
                                  style={{
                                    color: '#000',
                                    position: 'absolute',
                                    bottom: 5,
                                    right: 0,
                                    fontSize: 18,
                                    background: 'rgba(255,255,255,0.7)',
                                  }}
                                />
                              ) : null}
                            </div>
                          </>
                        }
                      />
                    ) : (
                        <Image
                          floated="left"
                          src={
                            form.photo ||
                            'https://react.semantic-ui.com/images/wireframe/square-image.png'
                          }
                          size="tiny"
                          style={{ marginBottom: '0px' }}
                        />
                      )}
                    <Header floated="left" as="h2">
                      <Header.Content>
                        {form && form.jpnName}
                        <Header.Subheader>
                          {form.semesterYear ? (
                            <>
                              <span className="text-muted">
                                {getLabelName2(formSet, 'semesterYear')}:
                            </span>
                            &nbsp;{form.semesterYear}&nbsp;&nbsp;
                          </>
                          ) : ''}
                          {form.semester ? (
                            <>
                              <span className="text-muted">
                                {getLabelName2(formSet, 'semester')}:
                            </span>
                            &nbsp;{form.semester}&nbsp;&nbsp;
                          </>
                          ) : ''}
                          {list === '2' ? null : form.recruitNum ? (
                            <>
                              <span className="text-muted">
                                {getLabelName2(formSet, 'recruitNum')}:
                            </span>
                            &nbsp;{form.recruitNum}&nbsp;&nbsp;
                          </>
                          ) : ''}
                          {form.studentId ? (
                            <>
                              <span className="text-muted">
                                {getLabelName2(formSet, 'studentId')}:
                              </span>
                              &nbsp;{form.studentId}&nbsp;&nbsp;
                            </>
                          ) : ''}
                          {list === '2' && semesterNum ? (
                            <>
                              <span className="text-muted">
                                第
                                </span>
                                &nbsp;{semesterNum}&nbsp;&nbsp;期
                              </>
                          ) : ''}
                        </Header.Subheader>
                        {/* <br /> */}
                        <div style={{ marginTop: '15px' }}>
                          {isEdit ? (
                            <Button
                              positive
                              size="small"
                              onClick={this.saveUpdateSetting}
                              icon="save"
                              loading={loading}
                              content="保存"
                            />
                          ) : (
                              <Button
                                primary
                                size="small"
                                onClick={() => this.setState({ isEdit: true })}
                                icon="edit"
                                className="no-print"
                                content="編集"
                              />
                            )}
                          {isEdit ? (
                            <Button
                              basic
                              size="small"
                              onClick={this.cancelUpdateSetting}
                              icon="remove"
                              content="キャンセル"
                            />
                          ) : null}
                          {selIndex + 1} / {resStudents.length}
                        </div>
                      </Header.Content>
                    </Header>
                  </Segment>
                  <br />
                  <StudentViewTabs
                    onChangeRadio={this.onChangeRadio}
                    refetch={this.refetch}
                    students={students}
                    calendar={Calendar}
                    grade={grade}
                    classroom={classroom}
                    {...this.props}
                    doc={form}
                    list={list}
                    statusKey={statusKey}
                    isEdit={isEdit}
                    addNum={this.state.addNum}
                    addAddr={this.addAddr}
                    deleteAddr={this.deleteAddr}
                    handleUpdateSetting={this.handleUpdateSetting}
                    setRelativeObj={this.setRelativeObj}
                    yearsemester={semester}
                    semesterNum={semesterNum}
                  />
                </Grid.Column>
              ) : (
                  <div style={{ minHeight: '100px', padding: '100px' }}>
                    <Header as="h2" floated="left">
                      学生を選択して下さい
                </Header>
                  </div>
                )}
            </Grid>
          </Ref>
          {/* <Modal
            size="tiny"
            open={show}
            onClose={this.handleClose}
            centered={false}
          > */}
          <Modal
            isOpen={show}
            toggle={this.handleClose}
            // size="sm"
            className="mh-80 h-80 mw-80 w-80"
            style={{ paddingTop: '0px' }}
            scrollable={true}
          >
            <ModalHeader className="justify-content-center">新規作成</ModalHeader>
            <ModalBody style={{ padding: "20px 30px" }}>
              {list === '0' ? ( //問合Modal
                <Form>
                  <Form.Group widths="equal">
                    <Form.Select
                      label="希望入学年"
                      fluid
                      name="semesterYear"
                      options={semesterYearArr}
                      value={this.state.formModal.semesterYear}
                      onChange={this.handleChangeModal}
                    />
                    <Form.Select
                      label="希望入学期"
                      fluid
                      name="semester"
                      options={semestersArr}
                      value={this.state.formModal.semester}
                      onChange={this.handleChangeModal}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    {/* <Form.Field control={Select} label='類別' options={statusArr} placeholder='類別' onChange={this.handleChangeModal} name='studentStatus' value={studentStatus} /> */}
                    <Form.Field
                      control={Select}
                      label="学生別"
                      options={studentTypeArr}
                      placeholder="学生別"
                      onChange={this.handleChangeModal}
                      name="studentType"
                      value={studentType}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Input
                      type="date"
                      label="初回問合日付"
                      placeholder="初回問合日付"
                      onChange={this.handleChangeModal}
                      name="enquiryDate"
                      value={enquiryDate}
                    />
                    <Form.Field
                      control={Input}
                      label="問合募集番号"
                      placeholder="問合募集番号"
                      onChange={this.handleChangeModal}
                      name="recruitNum"
                      value={recruitNum}
                    />
                  </Form.Group>
                </Form>
              ) : (
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Select
                        label="学年"
                        fluid
                        name="semesterYear"
                        options={semesterYearArr}
                        value={this.state.formModal.semesterYear}
                        onChange={this.handleChangeModal}
                      />
                      <Form.Select
                        label="学期"
                        fluid
                        name="semester"
                        options={semestersArr}
                        value={this.state.formModal.semester}
                        onChange={this.handleChangeModal}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Field
                        control={Select}
                        label="類別"
                        options={statusArr}
                        placeholder="類別"
                        onChange={this.handleChangeModal}
                        name="studentStatus"
                        value={studentStatus}
                      />
                      <Form.Field
                        control={Select}
                        label="学生別"
                        options={studentTypeArr}
                        placeholder="学生別"
                        onChange={this.handleChangeModal}
                        name="studentType"
                        value={studentType}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      {/* <Form.Field
                      control={Select}
                      label="選択募集番号"
                      options={options0}
                      placeholder="選択募集番号"
                      onChange={this.handleChangeModal}
                      name="_value0"
                      value={_value0}
                    /> */}
                      <Form.Field
                        control={Input}
                        label="問合募集番号"
                        placeholder="問合募集番号"
                        onChange={this.handleChangeModal}
                        name="value0"
                        value={value0}
                      />
                      <Form.Field
                        control={Input}
                        label="学籍番号"
                        placeholder="学籍番号"
                        onChange={this.handleChangeModal}
                        name="value1"
                        value={value1}
                      />
                    </Form.Group>
                    {/* <Form.Group widths="equal">
                    <Form.Field
                      control={Select}
                      label="選択学籍番号"
                      options={options1}
                      placeholder="選択学籍番号"
                      onChange={this.handleChangeModal}
                      name="_value1"
                      value={_value1}
                    />
                    <Form.Field
                      control={Input}
                      label="学籍番号"
                      placeholder="学籍番号"
                      onChange={this.handleChangeModal}
                      name="value1"
                      value={value1}
                    />
                  </Form.Group> */}
                  </Form>
                )}
            </ModalBody>
            <ModalFooter style={{ padding: '16px' }}>
              <Button onClick={this.handleClose}>閉じる</Button>
              <Button
                primary
                icon="checkmark"
                labelPosition="right"
                onClick={this.handleSubmit}
                content="新規作成"
                loading={loading}
              />
            </ModalFooter>
          </Modal>
          <Modal
            size="mini"
            open={this.state.picModal}
            onClose={this.closePicModal}
            centered={false}
          >
            <ModalHeader
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <h2 style={{ margin: 0 }}> Photo Edit</h2>
              <Icon
                name="close"
                onClick={this.closePicModal}
                style={{ cursor: 'pointer', margin: 0 }}
              ></Icon>
            </ModalHeader>
            <ModalBody
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                as="label"
                content={
                  <>
                    上傳新封面
                  <FileUploader
                      hidden
                      accept="image/*"
                      name="avatar"
                      randomizeFilename
                      // storageRef={this.props.firebase.storage().ref('images')}
                      onUploadStart={this.handleUploadStart}
                      onUploadError={this.handleUploadError}
                      onUploadSuccess={this.handleUploadSuccess}
                      onProgress={this.handleProgress}
                    />
                  </>
                }
                icon={<Icon name="file image" />}
                color="yellow"
              />
              <Button
                icon="trash alternate"
                color="red"
                content="削除封面"
                onClick={this.removePic}
                style={{ marginLeft: '10%' }}
              />
            </ModalBody>
          </Modal>
          {this.state.isUploading && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 10000,
                width: '100vw',
                height: '100vh',
                background: 'rgba(0,0,0,0.87)',
                overflow: 'hodden',
              }}
            >
              <Progress
                percent={this.state.progress}
                indicating
                error={this.state.error}
                progress
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}