import React, { Component } from 'react'
import { Container, Button, Dimmer, Loader, Image, Segment, Header, Form } from 'semantic-ui-react';
import MaterialTable from 'material-table'

import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';
import Parse from 'widget/parse'

const columns2 = () => ([
  { title: '繳款方式', field: 'type', lookup: { '0': '現金', '1': '匯款', '2': '支票' } },
  dateCol({ title: '繳費日期', field: 'date', type: 'date' }),
  { title: '繳費金額', field: 'receiveNum' },
  { title: '備註', field: 'ps' },
]);

// const options = [
//   { key: 'm', text: '全部', value: '全部' },
// ]

const d = new Date()

export default class Client1b extends Component {
  state = {
    paying1Arr: [],
    paying2Arr: [],
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
    loading: true
  }

  componentDidMount() {
    // const { calendar } = this.props
    // this.setState({ dataArr: calendar })
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async () => {
    const { companyObj, clientObj, paying1Obj } = this.props;
    const data = await Parse.queryData('paying2', { companyId: companyObj.objectId, paying1Id: paying1Obj.objectId });
    this.setState({ paying2Arr: data, loading: false });
    // firebase.firestore().collection(`company/${companyObj.id}/client/${clientObj.id}/paying1/${paying1Obj.id}/paying2`)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ id: item.id, ...item.data() }));
    //     this.setState({ paying2Arr: data, loading: false });
    //     // console.log(data)
    //   }, err => { console.log(`Encountered error: ${err}`); });
  }

  handleAdd = async () => {
    const { companyObj, paying1Obj, handleUpdateAcc } = this.props
    const { newData, paying2Arr } = this.state
    Parse.saveData('paying2', newData);
    // // console.log(obj)
    // const db = firebase.firestore();
    // const docRef = db.collection(`company/${companyObj.id}/client/${obj.clientId}/paying1/${obj.paying1Id}/paying2`).doc(obj.id);
    // await docRef.set(obj);
    let accReceived = 0;
    paying2Arr.forEach(item => accReceived = accReceived + Number(item.receiveNum));
    const accPending = Number(paying1Obj.accReceivable) - Number(paying1Obj.accReceived);

    handleUpdateAcc({ ...paying1Obj, accReceived, accPending })
  }

  handleUpdate = async () => {
    const { companyObj, clientObj, paying1Obj, handleUpdateAcc } = this.props
    const { newData, paying2Arr } = this.state
    Parse.saveData('paying2', newData);
    let accReceived = 0;
    paying2Arr.forEach(item => accReceived = accReceived + Number(item.receiveNum));
    const accPending = Number(paying1Obj.accReceivable) - Number(paying1Obj.accReceived);

    handleUpdateAcc({ ...paying1Obj, accReceived, accPending })
  }

  handleDelete = async () => {
    const { companyObj, clientObj, paying1Obj, handleUpdateAcc } = this.props
    const { oldData, paying2Arr } = this.state
    Parse.deleteData('paying2', oldData);
    this.setState({ oldData: {} })
    let accReceived = 0;
    paying2Arr.forEach(item => accReceived = accReceived + Number(item.receiveNum));
    const accPending = Number(paying1Obj.accReceivable) - Number(paying1Obj.accReceived);

    handleUpdateAcc({ ...paying1Obj, accReceived, accPending })
  }

  render() {
    const { companyObj, clientObj, paying1Obj } = this.props
    const { paying1Arr, paying2Arr, mgyearSel, monthSel, loading } = this.state;
    return (<>
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns2()}
        options={{
          pageSize: 5,
          search: false
        }}
        data={paying2Arr}
        title={paying1Obj.paymentType + '收款紀錄'}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const data = [...paying2Arr];
              const db = Parse.Object.extend("paying2");
              const newDoc = new db();
              const doc = await newDoc.save();
              const obj = {
                // ...paying1Obj,
                id: doc.id,
                _id: doc.id,
                companyId: companyObj.objectId,
                clientId: clientObj.objectId,
                paying1Id: paying1Obj.objectId,
                receiveNum: paying1Obj.receiveNum,
                accReceived: Number(newData.receiveNum || 0),
                roomNum: paying1Obj.roomNum,
                ...newData,
                year: mgyearSel,
                month: monthSel,
              }
              data.push(obj);
              this.setState({ paying2Arr: data, newData: obj }, () => resolve());
            }).then(() => this.handleAdd()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = paying2Arr;
              const newData2 = {
                ...newData,
                companyId: companyObj.objectId,
                clientId: clientObj.objectId,
                paying1Id: paying1Obj.id,
              }
              const index = data.indexOf(oldData);
              data[index] = newData2;
              // handleState('people0', data);
              this.setState({ paying2Arr: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = paying2Arr;
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              // handleState('people0', data);
              this.setState({ paying2Arr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
    </>)
  }
}