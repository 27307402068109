
import React, { Component } from 'react'
import { Form, } from 'semantic-ui-react';
import MaterialTable from 'material-table'
// import { closeSync } from 'fs';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

// import RowsDnd from './RowsDnd'
import { dateCol, mgyearOpt, monthOpt, codeOpt } from 'views/web/CustomMaterialTable';

const columns = () => ([
  { width: 80, title: '單號', field: 'ordernum' },
  { width: 80, title: '年度', field: 'year' },
  { width: 80, title: '建立日期', field: 'date' },
  { width: 80, title: '服務金額', field: 'accReceivable' },
  { width: 80, title: '已收金額', field: 'accReceived' },
  { width: 80, title: '未付金額', field: 'accPending' },
]);

const payStatusOpt = [
  { key: 'all', text: '全部', value: '-1' },
  { key: 'paid', text: '已繳清', value: '0' },
  { key: 'unpaid', text: '未繳清', value: '1' },
]

const lightType = [
  { key: '0', text: '-- 全選 --', value: '0' },
  { key: '1', text: '藥師大燈', value: '1' },
  { key: '2', text: '光明大燈', value: '2' },
  { key: '3', text: '光明燈', value: '3' },
  { key: '4', text: '藥師燈', value: '4' },
]

class Cashier extends Component {
  state = {
    dataArr: [],
    mgyearId: "109",
    pray1Id: '0',
    paystatusId: '-1',
    accReceivableTotal: 0,
    accReceivedTotal: 0,
    accPendingTotal: 0,
    loading: false,
    loadingBtn: false,
  }
  componentDidMount() {
    // const { calendar } = this.props
    // this.setState({ dataArr: calendar })
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async () => {
    const { companyObj } = this.props;
    const { paystatusId, pray1Id, mgyearId } = this.state
    const db = Parse.Object.extend("lighting1");
    const query = new Parse.Query(db);
    query.equalTo("companyId", companyObj.objectId);
    query.equalTo("year", mgyearId);

    if (paystatusId === '0') { // 已繳清
      // ref = ref.where('accPending', '==', 0);
      query.equalTo("accPending", 0);

    } else if (paystatusId === '1') { // 未繳清
      // ref = ref.where('accPending', '>', 0)
    }

    const checkNum = (value) => !isNaN(value) ? value : 0;
    const arrAccNum = (arr, fleld) => arr.reduce((a, b) => Number(a) + Number(b[fleld]), 0);

    const snap = await query.find();
    const data1 = snap.map(data => data.toJSON());
    const arr = data1.map(item => ({
      ...item,
      // accReceivable: typeof item.accReceivable === 'number' ? item.accReceivable : 0,
      accReceivable: checkNum(item.accReceivable),
      accReceived: checkNum(item.accReceived),
      accPending: checkNum(item.accPending),
    }))

    const accReceivableTotal = arrAccNum(arr, 'accReceivable');
    const accReceivedTotal = arrAccNum(arr, 'accReceived');
    const accPendingTotal = arrAccNum(arr, 'accPending');
    // console.log(accReceivableTotal, accReceivedTotal, accPendingTotal)
    this.setState({ dataArr: arr, accReceivableTotal, accReceivedTotal, accPendingTotal, loading: false });
  }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value }, () => this.handleFetch());
  }

  render() {
    // const {  = {} } = this.props
    const { dataArr, value, mgyearId, pray1Id, paystatusId, loading, loadingBtn, accReceivableTotal, accReceivedTotal, accPendingTotal } = this.state;
    // const { mgyearOpt, pray1Opt } = ;
    const params = ''

    return (
      <>
        <div className="content">
          <Form style={{ position: 'relative', zIndex: 99, }}>
            <Form.Group inline>
              <Form.Select label='年度' options={mgyearOpt} value={mgyearId} name='mgyearId' onChange={this.handleChange} />
              <Form.Select label='付款狀況' options={payStatusOpt} value={paystatusId} name='paystatusId' onChange={this.handleChange} />
              {/* <ModalPdf
                {...this.props}
                // ref={(ref) => { this.pdfModal = ref }}
                codekey='printTest'
                params={params}
              /> */}
              <Form.Button color='green' onClick={this.handleStatPray1} loading={loadingBtn} >重整資料</Form.Button>
            </Form.Group>
            <br />
            <Form.Group inline>
              <Form.Input label='應付' placeholder='應付' value={accReceivableTotal} name='accReceivableTotal' readOnly />
              <Form.Input label='應收' placeholder='應收' value={accReceivedTotal} name='accReceivedTotal' readOnly />
              <Form.Input label='未付' placeholder='未付' value={accPendingTotal} name='accPendingTotal' readOnly />
            </Form.Group>
          </Form>
          <br />
          <br />
          <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns()} options={{
              addRowPosition: "first",
              pageSize: 20,
              tableLayout: 'fixed',
              search: false
            }}
            data={dataArr}
            title={`查詢結果`}
          />
          {/* <Button icon='print' content='產生PDF' color='blue' /> */}
          {/* <Table celled textAlign='center' style={{ margin: '2rem auto' }}>
          <Table.Header>
            <Table.Row>
              {TH.map((item, index) => <Table.HeaderCell key={`dataTh${index}`}>{item.text}</Table.HeaderCell>)}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {dataArr.length ? dataArr.map((item, index) => <Table.Row key={`dataTr${index}`}>
              <Table.Cell></Table.Cell>
            </Table.Row>)
              : <Table.Row><Table.Cell colSpan={TH.length}>暫無資料</Table.Cell></Table.Row>}
          </Table.Body>
        </Table> */}
        </div>
      </>
    );
  }
}

export default Cashier;
