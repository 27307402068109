import React, { Component } from 'react'
import { Button, Form, Icon } from 'semantic-ui-react';
import MaterialTable from 'material-table'

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ModalAnnounce from './ModalAnnounce'
// import { readFileAsURL } from './RowImage';
import { dateCol, exportPdf } from './CustomMaterialTable';
import { RowImage } from './RowImage';

const categorySel = [
  { key: '0', text: '全部', value: '0' },
  { key: '1', text: '內部', value: '1' },
  { key: '2', text: '外部', value: '2' },
]
const columns = (AnnounceCenterObj, photos, handleFetch) => ([
  { title: '公告類別', field: 'category', lookup: { '0': '全部', '1': '內部', '2': '外部' } },
  {
    width: 50, title: '內文', field: 'postcode_select',
    headerStyle: { textAlign: 'center' },
    render: rowData => rowData && rowData.objectId ? <ModalAnnounce fieldname='content' isEdit='0' AnnounceCenterObj={AnnounceCenterObj} rowData={rowData} showContent={true} photos={photos} /> : null,
    editComponent: props => props.rowData && props.rowData.objectId ? <ModalAnnounce fieldname='content' isEdit='1' AnnounceCenterObj={AnnounceCenterObj} rowData={props.rowData} onRowDataChange={props.onRowDataChange} showContent={false} /> : null
  },
  {
    width: 120, title: '封面', field: 'img1File', filtering: false,
    render: rowData => <><RowImage rowData={rowData} field='img1File' /></>,
    editComponent: props => <><RowImage rowData={props.rowData} field='img1File' /></>
  },
  dateCol({ title: '公告日期', field: 'date' }),
  { title: '對象', field: 'to', editable: 'never' },
  { title: '標題', field: 'title', editable: 'never' },
  { title: '內容', field: 'content', editable: 'never' },
  { title: '備註', field: 'ps' },
]);

export default class AnnounceCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginUser: this.props.user.profile.toJSON(),
      show3: false,
      dataTypeArr: [],
      TypeArr: [],
      dataArr: [],
      dataArrOri: [],
      selType: '0',
      fileArr: [],
      fileArrCheck: [],
      filenames: [],
      downloadURLs: [],
      loading: true,
      show: false,
      show2: false,
      modalForm: {},
      modalFile: {},
      modalloading: false,
      loadingFile: false
    }
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    // const { companyObj } = this.props
    // const { loginUser } = this.state
    // const companyId = companyObj.objectId
    // const authPage = window.location.pathname.split('/')[2]
    // const authUser = companyId + '_' + authPage
    // let auth = {}
    // if ((loginUser.authAdminA&&loginUser.authAdminA.indexOf(companyId) !== -1 )|| (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
    //   auth = {
    //     edit: true,
    //   }
    // } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
    //   auth = {
    //     edit: false,
    //   }
    // } else {
    //   auth = {
    //     edit: false,
    //   }
    // }
    let auth = {
      edit: true
    }
    this.setState({ auth }, () => this.handleFetch(this.props))
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj } = props;
    const data = await Parse.queryData('announceCenter', { companyId: companyObj.objectId });
    const photos = await Parse.queryData('webimage', { companyId: companyObj.objectId });
    this.setState({ loading: false, dataArr: data, dataArrOri: data, photos })
  }

  handleChange = (e, { name, value }) => {
    const { dataArrOri, selType, TypeArr } = this.state
    if (name === 'selType') {
      if (value === '0') {
        let pageSize = { target: { value: dataArrOri.length } }
        this.tableRef.current.onChangeRowsPerPage(pageSize)
        this.setState({ [name]: value, dataArr: dataArrOri })
      } else {
        const dataArr = dataArrOri.filter(item => item.category === value)
        let pageSize = { target: { value: dataArr.length } }
        this.tableRef.current.onChangeRowsPerPage(pageSize)
        this.setState({ [name]: value, dataArr })
      }
      // console.log(TypeArr.filter(item => item.value === selType)[0])
    }
  }

  handleChange1 = (name, value) => {
    this.setState({ [name]: value })
  }

  handleAdd = async (id) => {
    const { companyObj } = this.props;
    const { newData } = this.state;
    let obj = {
      ...newData,
      date: (newData.date && new Date(newData.date.iso || newData.date)) || new Date()
    }
    this.setState({ loading: true });
    await Parse.saveData('announceCenter', obj);
    this.setState({ loading: false });
  }

  handleUpdate = async (id) => {
    const { companyObj } = this.props
    const { newData } = this.state
    const tableData = { editing: '' }
    let obj = {
      ...newData,
      date: (newData.date && new Date(newData.date.iso || newData.date)) || new Date(),
      tableData,
    }
    this.setState({ loading: true });
    await Parse.saveData('announceCenter', obj);
    this.handleFetch()
  }

  handleDelete = async (id) => {
    const { companyObj } = this.props
    const { oldData } = this.state
    this.setState({ loading: true });
    // const promises = []
    // const dataArr = await Parse.queryData('webimage', { companyId: companyObj.objectId, pageId: oldData.objectId });
    // dataArr.forEach(item => promises.push(Parse.deleteData('webimage', item)))
    await Parse.destroyData('webimage', { companyId: companyObj.objectId, pageId: oldData.objectId })
    // await Promise.all(promises).catch(error => console.log("error", error));
    await Parse.deleteData('announceCenter', oldData);
    this.setState({ loading: false, oldData: {} })
  }

  handleData = async (rowData) => {
    this.setState({ rowData });
  }

  handleFileAdd = async (rowData) => {
    const { companyObj, pageObj, type } = this.props; // type === '1' 新增
    const { form, file, multiple, fileArr, category, announce_date, announceContent } = this.state;
    this.setState({ loading: true });

    const db = Parse.Object.extend("announceCenter");
    const newDoc = new db();
    const doc = await newDoc.save();

    const obj = {
      objectId: doc.id,
      id: doc.id,
      _id: doc.id,
      companyId: companyObj.objectId,
      companyName: companyObj.name,
      pageId: rowData.objectId,
      category,
      announce_date,
      announceContent
    };
    await Parse.saveData('announceCenter', obj);

    console.log(rowData)
    const promises = [];
    for (let i = 0; i < fileArr.length; i++) {
      const file = fileArr[i];
      promises.push(Parse.putFile(file));
    }

    const theFiles = await Promise.all(promises).catch(error => console.log("error", error));
    const promises2 = [];
    theFiles.forEach(async (theFile) => {
      const db = Parse.Object.extend("webimage");
      const newDoc = new db();
      const doc = await newDoc.save();

      const element = {
        objectId: doc.id,
        id: doc.id,
        _id: doc.id,
        companyId: companyObj.objectId,
        companyName: companyObj.name,
        pageId: rowData.objectId,
        theFile
      };
      promises2.push(Parse.saveData('webimage', element));
    })
    await Promise.all(promises2).catch(error => console.log("error", error));
    this.setState({ rowData: {}, loading: false })
  }

  render() {
    const { companyObj } = this.props;
    const { selType, dataArr, TypeArr, loading, auth = {}, photos } = this.state;
    let modalTypeArr = TypeArr.concat()
    modalTypeArr.splice(0, 1)

    const editable = {
      isEditHidden: () => !auth.edit,
      isDeleteHidden: () => !auth.edit,
      onRowAdd: newData =>
        new Promise(async (resolve, reject) => {
          const db = Parse.Object.extend("announceCenter");
          const newDoc = new db();
          const docRef = await newDoc.save();
          const data = [...dataArr];
          let obj = {
            ...newData,
            id: docRef.id,
            _id: docRef.id,
            objectId: docRef.id,
            companyId: companyObj.objectId,
            companyName: companyObj.name,
          }
          data.push(obj);
          // handleState('people0', data);
          this.setState({ dataArr: data, newData: obj }, () => resolve());
        }).then(() => this.handleAdd()),
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          const data = [...dataArr];
          const index = data.indexOf(oldData);
          data[index] = newData;
          let obj = {
            ...newData,
          }
          // handleState('people0', data);
          this.setState({ dataArr: data, newData: obj, oldData }, () => resolve());
        }).then(() => this.handleUpdate("2")),
      onRowDelete: oldData =>
        new Promise((resolve, reject) => {
          let data = [...dataArr];
          const index = data.indexOf(oldData);
          data.splice(index, 1);
          // handleState('people0', data);
          this.setState({ dataArr: data, oldData }, () => resolve());
        }).then(() => this.handleDelete("2")),
    }

    if (auth.edit === false) {
      delete editable.onRowAdd
    }

    return (<>
      <div className="content">
        <div>
          <Form.Select options={categorySel} onChange={this.handleChange} name='selType' value={selType} style={{ zIndex: 99 }} />
        </div>
        <br />
        <MaterialTable
          isLoading={loading}
          localization={localization()}
          columns={columns(this.props, photos, this.handleFetch)}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportPdf: (columns, data) => exportPdf(columns, data),
            pageSize: dataArr.length || 10,
            search: false,
          }}
          tableRef={this.tableRef}
          data={dataArr}
          title=""
          // actions={[
          //   {
          //     icon: 'view_headline',
          //     tooltip: '查看',
          //     onClick: (event, rowData) => this.handleData(rowData)
          //   }
          // ]}
          editable={editable}
        />
        <br />
        <br />
      </div>
    </>)
  }
}
