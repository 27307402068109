import React, { Component } from 'react'
// import { Table, Header, Segment, Tab, Select } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

import { Row, Col, Form, FormGroup, Input, Label, Button } from "reactstrap";

// const columns = () => ([
//   { width: 80, title: '使用者名稱', field: 'userName' },
//   { width: 80, title: '點數餘額', field: 'pointsBalance' },
//   { width: 80, title: '總儲值點數', field: 'totolPoints' },
// ]);

class UserPoints extends Component {
  state = {
    activeItem: 'bio',
    dataArr: [],
    rowData: {},
    loading: true
  }

  async componentDidMount() {
    // const data1 = await Parse.queryData('testBank');
    // this.setState({ dataArr: data1, loading: false });
    this.setState({ loading: false })
  }

  handleAdd = async () => {
    const { newData } = this.state;
    Parse.saveData('testBank', newData);
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    Parse.saveData('testBank', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    Parse.deleteData('testBank', oldData);
    this.setState({ oldData: {} })
  }

  handleItemClick = (e, { name }) =>
    this.setState({
      activeItem: name
    })

  handleData = (rowData) => {
    this.setState({ rowData });
  }

  render() {
    const { dataArr, loading } = this.state
    return (<div className="content">
      <Row>
        <Col><legend>3-3 測驗統計</legend></Col>
      </Row>
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        // columns={columns()}
        columns={[
          { width: 120, title: '系所', field: 'department' },
          { width: 100, title: '學年度', field: 'schoolYear' },
          { width: 100, title: '課程', field: 'course' },
          { width: 100, title: '班級', field: 'class' },
          // {
          //   width: 150, title: '權限', field: 'teacherAuth',
          //   render: rowData => <>
          //     <Dropdown placeholder='權限' multiple selection options={userArr} defaultValue={rowData.teacher} style={{ zIndex: 99 }} disabled />
          //   </>,
          //   editComponent: props => <>
          //     <Dropdown placeholder='權限' multiple selection options={userArr} onChange={(e, data) => props.onRowDataChange({ ...props.rowData, teacher: data.value })} style={{ zIndex: 99 }} defaultValue={props.rowData.teacher} />
          //   </>,
          // },
        ]}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '測驗統計'),
          pageSize: 10,
          search: false,
          tableLayout: 'fixed'
        }}
        // components={{
        //   Toolbar: props => (<div>
        //     <div style={{ float: 'right', textAlign: 'right', padding: '22px 16px 22px 4px' }}>
        //       <ModalMultiInput {...this.props} handleData={this.handleFetch} columns={props.columns} condition={{ companyId: companyObj.objectId, companyName: companyObj.name, schoolId: rowData.objectId, schoolName: rowData.name_cht }} dbName='clients' />
        //     </div>
        //     <MTableToolbar {...props} />
        //     <div style={{ clear: 'both' }}></div>
        //   </div>),
        // }}
        // data={schoolClass}
        title={`測驗統計`}
      // actions={[
      //   {
      //     icon: 'view_headline',
      //     tooltip: '查看',
      //     onClick: (event, rowData) => this.handleScore(scoreData)
      //   }
      // ]}
      // editable={{
      //   onRowAdd: newData =>
      //     new Promise(async (resolve, reject) => {
      //       const data = [...schoolClass];
      //       const db = Parse.Object.extend("clients");
      //       const newDoc = new db();
      //       const doc = await newDoc.save();
      //       // newData.objectId = doc.id;
      //       // newData.id = doc.id;
      //       // newData._id = doc.id;
      //       let obj = {
      //         ...newData,
      //         objectId: doc.id,
      //         id: doc.id,
      //         _id: doc.id,
      //         companyId: companyObj.objectId,
      //         companyName: companyObj.name,
      //         schoolId: rowData.objectId,
      //         schoolName: rowData.name_cht,
      //       }
      //       data.push(obj);
      //       this.setState({ schoolClass: data, newData: obj }, () => resolve());
      //     }).then(() => this.handleAdd()),
      //   onRowUpdate: (newData, oldData) =>
      //     new Promise((resolve, reject) => {
      //       const data = [...schoolClass];
      //       const index = data.indexOf(oldData);
      //       data[index] = newData;
      //       // handleState('people0', data);
      //       this.setState({ schoolClass: data, newData, oldData }, () => resolve());
      //     }).then(() => this.handleUpdate()),
      //   onRowDelete: oldData =>
      //     new Promise((resolve, reject) => {
      //       let data = [...schoolClass];
      //       const index = data.indexOf(oldData);
      //       data.splice(index, 1);
      //       // handleState('people0', data);
      //       this.setState({ schoolClass: data, oldData }, () => resolve());
      //     }).then(() => this.handleDelete()),
      // }}
      />
      <br />
      <br />
    </div>
    );
  }
}

export default UserPoints;