import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

const columns = () => ([
  { title: '零件編號', field: 'partNum' },
  { title: '零件名稱', field: 'partName' },
  { title: '簡碼編號', field: 'shortcodeNum' },
  { title: '計算單位', field: 'unitOfMeasurement' },
  { title: '零件單價', field: 'unitPrice' },
  { title: '顯示註記', field: 'showNotes' },
  { title: '數量', field: 'quantity' },
  { title: '安全存量', field: 'safetyStock' },
  { title: '零件規格', field: 'partSpecifications' },
  { title: '參考編號', field: 'referenceNum' },
  { title: '經銷單價', field: 'distributionUnitPrice' },
  { title: '索賠單價', field: 'claimUnitPrice' },
]);
const columns2 = () => ([
  { title: '進貨日期', field: 'purchaseDate' },
  { title: '類別', field: 'type' },
  { title: '零件級別', field: 'partLevel' },
  { title: '廠商名稱', field: 'tradeName' },
  { title: '單價', field: 'unitPrice' },
  { title: '數量', field: 'quantity' },
  { title: '金額', field: 'amount' },
  { title: '備註說明', field: 'instructionManual' },
  { title: '單號', field: 'singleNum' },
  { title: '序號', field: 'serialNum' },
  { title: '零件名稱', field: 'partName' },

]);

export default class Hr1 extends Component {
  state = {
    dataArr: [],
    filenames: [],
    downloadURLs: [],
    docs: [],
    fileArr: [],
    fileArrCheck: [],
    show: false,
    // loading: true,
    obj: {}
  }

  // componentDidMount() {
  //   this.handleFetch();
  // }

  // componentWillReceiveProps(nextProps) {
  //   this.handleFetch(nextProps);
  // }

  // handleFetch = async (props = this.props) => {
  //   const { companyObj } = props;

  //   const companyId = companyObj.objectId;
  //   const data = await Parse.queryData('hrform_management', { companyId });
  //   this.setState({ dataArr: data, loading: false });
  // }

  // handleUpdate = async () => {
  //   const { newData } = this.state
  //   await Parse.saveData('hrform_management', newData);
  // }

  render() {
    const { dataArr, loading } = this.state;

    return (<div className='content'>
      {/* <Container> */}
      {/* <div style={{ ...style.flexCenter, position: 'relative', zIndex: 100 }}>
          <Header as='h2' style={{ margin: '2rem 0' }}>4-1 人事規章</Header>
        </div> */}
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          pageSize: 5,
          search: true,
          searchFieldStyle: {
            width: 90,
          }
        }}
        data={dataArr}
        title={'零件庫存'}
      />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns2()}
        options={{
          pageSize: 5,
          search: true,
          searchFieldStyle: {
            width: 90,
          }
        }}
        data={dataArr}
        title={'零件級別儲位'}
      />
      <br />
      <br />
    </div>)
  }
}