import React, { Component } from 'react'
import { Container, Dimmer, Loader, Image, Segment, Select, Input, Table, Label, Grid } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton'
// import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { mgyearOpt, exportPdf } from 'views/web/CustomMaterialTable';

const columns = () => ([
  // { title: '發佈對象 ', field: 'towhom', lookup: { '0': '', '1': '內部', '2': '外部' } },
  { title: '項目', field: 'accountText' },
  { title: '說明', field: 'title' },
  { title: '12月', field: 'price12' },
  { title: '1月', field: 'price1' },
  { title: '2月', field: 'price2' },
  { title: '3月', field: 'price3' },
  { title: '4月', field: 'price4' },
  { title: '5月', field: 'price5' },
  { title: '6月', field: 'price6' },
  { title: '7月', field: 'price7' },
  { title: '8月', field: 'price8' },
  { title: '9月', field: 'price9' },
  { title: '10月', field: 'price10' },
  { title: '11月', field: 'price11' },
  { title: '總計', field: 'priceTotal' },
  { title: '％', field: 'totalr' },
]);

const d = new Date()
export default class Fin1117 extends Component {
  state = {
    dataArr: [],
    dataArr_2: [],
    data2Arr: [],
    data2Arr_2: [],
    data3Arr: [],
    ac2DataArr: [],
    ac2Data2Arr: [],
    deleteConfirm: false,
    loading: false,
    // bankaccSel: '',
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
    mgyearArr: [],
    // monthSel: Moment(new Date()).format('YYYY-MM') || '',
  }

  render() {
    const { dataArr, dataArr_2, data2Arr, data2Arr_2, data3Arr, loading, mgyearSel } = this.state;

    return (<>
      <br />
      <br />
      <ExportTableToExcelButton floated='right' name='資產負債表' id='excell' />
      <Select style={{
        position: 'relative', zIndex: 99,
        float: 'left',
        minWidth: '200px',
        // borderTopLeftRadius: '0',
        // borderBottomLeftRadius: '0'
      }}
        compact
        name="mgyearSel"
        options={mgyearOpt}
        value={mgyearSel}
        onChange={this.handleChange}
      />
      <br />
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearSel} 年度 流動資產項目`),
          pageSize: 3,
          search: false
        }}
        // data2={bankaccSel}
        data={dataArr_2}
        title={`${mgyearSel} 年度 流動資產項目`}
      />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearSel} 年度 非流動資產項目`),
          pageSize: 3,
          search: false
        }}
        // data2={bankaccSel}
        data={dataArr}
        title={`${mgyearSel} 年度 非流動資產項目`}
      />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearSel} 年度 流動負債科目`),
          pageSize: 3,
          search: false
        }}
        // data2={bankaccSel}
        data={data2Arr_2}
        title={`${mgyearSel} 年度 流動負債科目`}
      />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearSel} 年度 非流動負債科目`),
          pageSize: 3,
          search: false
        }}
        // data2={bankaccSel}
        data={data2Arr}
        title={`${mgyearSel} 年度 非流動負債科目`}
      />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearSel} 年度 權益科目`),
          pageSize: 3,
          search: false
        }}
        // data2={bankaccSel}
        data={data3Arr}
        title={`${mgyearSel} 年度 權益科目`}
      />
    </>)
  }
}