import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

// import RowsDnd from './RowsDnd'

// reactstrap components
import { Row, Col, Form, FormGroup, Input, Label, Button } from "reactstrap";
// import CurStudentInfo from "./CurStudentInfo";

const columns = () => ([
  { width: 180, title: '寺院名', field: 'name' },
  { width: 80, title: '法師', field: 'buddhist' },
  { width: 80, title: '郵遞區號', field: 'postcode' },
  { width: 280, title: '寺院地址', field: 'addr' },
  { width: 80, title: '寺院電話', field: 'phone' },
  { width: 80, title: '寺院傳真', field: 'fax' },
  { width: 80, title: '寺院電郵', field: 'email' },
  { width: 280, title: '寺院網址', field: 'website' },
  { width: 80, title: '寺院規模', field: 'scale', lookup: { '0': '1', '1': '2', '2': '3', '3': '4', '4': '5' } },
  { width: 80, title: '郵寄連絡寺院', field: 'mailTemple' },
  { width: 280, title: '郵寄連絡地址', field: 'mailAddr' },
  { width: 80, title: '郵寄連絡電話', field: 'mailPhone' },
  { width: 80, title: '備註', field: 'ps' },
]);


class Pawnticket extends Component {
  state = {
    activeItem: 'bio',
    dataArr: [],
    rowData: {},
    loading: true
  }

  async componentDidMount() {
    const { objectId } = this.props
    const data1 = await Parse.queryData('temple', { objectId });
    this.setState({ dataArr: data1, loading: false });
  }

  // handleAdd = async () => {
  //   const { newData } = this.state;
  //   Parse.saveData('pawnRecord', newData);
  // }

  // handleUpdate = async () => {
  //   const { newData } = this.state;
  //   Parse.saveData('pawnRecord', newData);
  // }

  // handleDelete = async () => {
  //   const { oldData } = this.state
  //   Parse.deleteData('pawnRecord', oldData);
  //   this.setState({ oldData: {} })
  // }

  handleItemClick = (e, { name }) =>
    this.setState({
      activeItem: name
    })

  handleData = (rowData) => {
    this.setState({ rowData });
  }

  render() {
    const { objectId, templeData, loading } = this.props
    const { dataArr } = this.state
    return (<div className="content">
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '寺廟資訊'),
          pageSize: 2,
          search: false,
          tableLayout: 'fixed'
        }}
        data={templeData || dataArr}
        title="寺廟資訊"
        actions={[
          {
            icon: 'view_headline',
            tooltip: '查看',
            onClick: (event, rowData) => this.handleData(rowData)
          }
        ]}
      // editable={{
      //   onRowAdd: newData =>
      //     new Promise(async (resolve, reject) => {
      //       const data = [...dataArr];
      //       const db = Parse.Object.extend("pawnRecord");
      //       const newDoc = new db();
      //       const doc = await newDoc.save();
      //       newData.objectId = doc.id;
      //       newData.id = doc.id;
      //       newData._id = doc.id;
      //       newData.clientId = objectId

      //       data.push(newData);
      //       this.setState({ dataArr: data, newData }, () => resolve());
      //     }).then(() => this.handleAdd()),
      //   onRowUpdate: (newData, oldData) =>
      //     new Promise((resolve, reject) => {
      //       const data = [...dataArr];
      //       const index = data.indexOf(oldData);
      //       data[index] = newData;
      //       // handleState('people0', data);
      //       this.setState({ dataArr: data, newData, oldData }, () => resolve());
      //     }).then(() => this.handleUpdate()),
      //   onRowDelete: oldData =>
      //     new Promise((resolve, reject) => {
      //       let data = [...dataArr];
      //       const index = data.indexOf(oldData);
      //       data.splice(index, 1);
      //       // handleState('people0', data);
      //       this.setState({ dataArr: data, oldData }, () => resolve());
      //     }).then(() => this.handleDelete()),
      // }}
      />
      <br />
      <br />
    </div>
    );
  }
}

export default Pawnticket;