import React, { createRef, Component } from 'react';
import { Grid, Icon, Table, Button, Form, Input, Label, Sticky, Ref, Select, Modal, Confirm, Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
// import Moment from 'moment';
import Dropzone from 'react-dropzone'
import XLSX from 'xlsx';
// import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';
import { Bar } from 'react-chartjs-2';

import { getOptionsByKey } from 'views/widgets/FieldsRender';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import { objectEquals } from '../../../widget/define/object';
import Parse from 'parse/node';

const abcdArr = [
  { key: '0', text: '', value: '' },
  { key: 'A', text: 'A', value: 'A' },
  { key: 'B', text: 'B', value: 'B' },
  { key: 'C', text: 'C', value: 'C' },
  { key: 'D', text: 'D', value: 'D' },
];
const a2zArr = [
  { key: '0', text: '', value: '' },
  { key: 'A', text: 'A', value: 'A' },
  { key: 'B', text: 'B', value: 'B' },
  { key: 'C', text: 'C', value: 'C' },
  { key: 'D', text: 'D', value: 'D' },
  { key: 'E', text: 'E', value: 'E' },
  { key: 'F', text: 'F', value: 'F' },
  { key: 'G', text: 'G', value: 'G' },
  { key: 'H', text: 'H', value: 'H' },
  { key: 'I', text: 'I', value: 'I' },
  { key: 'J', text: 'J', value: 'J' },
  { key: 'K', text: 'K', value: 'K' },
  { key: 'L', text: 'L', value: 'L' },
  { key: 'M', text: 'M', value: 'M' },
  { key: 'N', text: 'N', value: 'N' },
  { key: 'O', text: 'O', value: 'O' },
  { key: 'P', text: 'P', value: 'P' },
  { key: 'Q', text: 'Q', value: 'Q' },
  { key: 'R', text: 'R', value: 'R' },
  { key: 'S', text: 'S', value: 'S' },
  { key: 'T', text: 'T', value: 'T' },
  { key: 'U', text: 'U', value: 'U' },
  { key: 'V', text: 'V', value: 'V' },
  { key: 'W', text: 'W', value: 'W' },
  { key: 'X', text: 'X', value: 'X' },
  { key: 'Y', text: 'Y', value: 'Y' },
  { key: 'Z', text: 'Z', value: 'Z' }
];

const statXLabel = new Array(40).fill('').map((item, index) => (`${index * 10 && index * 10 + 1}-${(index + 1) * 10}`));


const calMeanScores = (grade2Arr, objG1, students) => {
  // console.log('calMeanScores')
  let score1 = Number(0);
  let score2 = Number(0);
  let score3 = Number(0);
  let score4 = Number(0);
  let score5 = Number(0);
  let score6 = Number(0);
  let score7 = Number(0);
  let score8 = Number(0);

  const statArr = new Array(40).fill(0);;
  // console.log(statArr)

  const _arrG2 = grade2Arr.map(item => {
    const totalScoreNum = Number(item.vocabularyScore || 0) + Number(item.listeningScore || 0) + Number(item.grammarScore || 0) + Number(item.readingScore || 0);
    const totalScore = totalScoreNum.toString();

    const interval = Math.ceil(totalScoreNum / 10);
    // console.log(totalScoreNum, interval)
    statArr[interval]++;

    // ====原始分数加總====
    score1 += Number(item.grammarScore || 0);
    score2 += Number(item.listeningScore || 0);
    score3 += Number(item.readingScore || 0);
    score4 += Number(item.vocabularyScore || 0);
    // ====100点換算=====
    score5 = Math.round(Number(item.grammarScore) / 5) * 5 || 0;
    score6 = Math.round(Number(item.listeningScore) / 5) * 5 || 0;
    score7 = Math.round(Number(item.readingScore) / 5) * 5 || 0;
    score8 = Math.round(Number(item.vocabularyScore) / 5) * 5 || 0;

    const resGrammarScore = score5.toString();
    const resListeningScore = score6.toString();
    const resReadingScore = score7.toString();
    const resVocabularyScore = score8.toString();
    // const resTotalScore = (score5 + score6 + score7 + score8).toString();
    const resTotalScore = (Number(item.resGrammarScore) + Number(item.resListeningScore) + Number(item.resReadingScore) + Number(item.resVocabularyScore)).toString();

    const obj = students.find(item2 => item2._id === item.studentId);
    let jpnName = item.jpnName;
    let studentNum = item.studentNum;
    let semester = item.semester;
    let semesterYear = item.semesterYear;
    let studentFullId = item.studentFullId;
    if (obj && obj.jpnName) {
      jpnName = obj.jpnName;
      studentNum = obj.studentId;
      semester = obj.semester;
      semesterYear = obj.semesterYear;
      studentFullId = `${obj.semesterYear.substr(-2)}(${obj && obj.semester})${obj.studentId}`;
    }

    let testCode = objG1.testCode;
    let testDate = objG1.testDate;
    let testLevel = objG1.testLevel || '';

    return {
      ...item, jpnName, studentNum, semester, semesterYear, studentFullId,
      testCode, testDate, testLevel,
      totalScoreNum, totalScore, resTotalScore
      // , resGrammarScore, resListeningScore, resReadingScore, resVocabularyScore
    }
  });
  ///
  // console.log(statArr)

  const arrG2 = _arrG2.sort((a, b) => b.totalScoreNum - a.totalScoreNum).map((item, i) => {
    if (i > 0) {
      //Get our previous list item
      var prevItem = _arrG2[i - 1];
      if (prevItem.totalScoreNum === item.totalScoreNum) {
        //Same score = same rank
        item.rank = prevItem.rank;
      } else {
        //Not the same score, give em the current iterated index + 1
        item.rank = i + 1;
      }
    } else {
      //First item takes the rank 1 spot
      item.rank = 1;
    }
    item.rankTotal = _arrG2.length;
    return item;
  });

  score1 /= grade2Arr.length;
  score2 /= grade2Arr.length;
  score3 /= grade2Arr.length;
  score4 /= grade2Arr.length;

  score1 = isNaN(score1) ? 0 : score1;
  score2 = isNaN(score2) ? 0 : score2;
  score3 = isNaN(score3) ? 0 : score3;
  score4 = isNaN(score4) ? 0 : score4;


  const grammarAvg = (Math.round(score1 * 100) / 100);
  const listeningAvg = (Math.round(score2 * 100) / 100);
  const readingAvg = (Math.round(score3 * 100) / 100);
  const vocabularyAvg = (Math.round(score4 * 100) / 100);
  const totalAvg = (Math.round((grammarAvg + listeningAvg + readingAvg + vocabularyAvg) * 100) / 100);

  const avg = {
    grammarAvg: grammarAvg + '',
    listeningAvg: listeningAvg + '',
    readingAvg: readingAvg + '',
    vocabularyAvg: vocabularyAvg + '',
    totalAvg: totalAvg + ''
  };

  return {
    objG1: { ...objG1, avg, statArr }, arrG2
  };
}

const getScoreField = (scoreName, item, handleUpdateSetting, type = "text") => {
  if (type === 'select') {
    return <Select
      fluid compact
      name={scoreName}
      item2={item}
      onChange={handleUpdateSetting}
      options={abcdArr}
      value={item[scoreName] ? item[scoreName] : ''}
    />
  } else if (type === 'classroom') {
    return <Select
      fluid compact
      name={scoreName}
      item2={item}
      onChange={handleUpdateSetting}
      options={a2zArr}
      value={item[scoreName] ? item[scoreName] : ''}
    />
  } else {
    return <Form.Input
      fluid
      name={scoreName}
      item2={item}
      onChange={handleUpdateSetting}
      value={item[scoreName] ? item[scoreName] : ''}
    />
  }
}

const ScoreTable = ({ isEdit, grade2Form, eventDate, selClassnameArr, onSort, removeRow, handleUpdateSetting, loading, contextRef, companyObj }) =>
  (<Table size="small" striped celled id='excel' className='thPadding2px tdPadding2px'>
    <Table.Header>
      <Table.Row>
        {isEdit ? <Table.HeaderCell style={{ width: "20px" }} rowSpan={2}></Table.HeaderCell> : null}
        <Table.HeaderCell style={{ width: "20px" }} rowSpan={2} onClick={e => onSort(e, 'rank', 'number')}>#</Table.HeaderCell>
        <Table.HeaderCell style={{ width: "120px" }} rowSpan={2} onClick={e => onSort(e, 'studentNum')}>学籍番号</Table.HeaderCell>
        <Table.HeaderCell style={{ width: "120px" }} rowSpan={2} onClick={e => onSort(e, 'jpnName')}>氏名 </Table.HeaderCell>
        <Table.HeaderCell colSpan={!isEdit ? 7 : 6}>素点</Table.HeaderCell>
        <Table.HeaderCell colSpan={2}>評価</Table.HeaderCell>
        <Table.HeaderCell style={{ width: "60px" }} rowSpan={2} onClick={e => onSort(e, 'oriClass')}>現クラス</Table.HeaderCell>
        <Table.HeaderCell style={{ width: "100px" }} rowSpan={2} onClick={e => onSort(e, 'newClass')}>新クラス</Table.HeaderCell>
        <Table.HeaderCell colSpan={5} >100点換算</Table.HeaderCell>
        {/* {!isEdit ? <Table.HeaderCell colSpan={5} >100点換算</Table.HeaderCell> : null} */}
      </Table.Row>
      <Table.Row>
        <Table.HeaderCell style={{ width: "100px" }} onClick={e => onSort(e, 'vocabularyScore')}>文字語彙</Table.HeaderCell>
        <Table.HeaderCell style={{ width: "100px" }} onClick={e => onSort(e, 'listeningScore')}>聴解</Table.HeaderCell>
        <Table.HeaderCell style={{ width: "100px" }} onClick={e => onSort(e, 'grammarScore')}>文法</Table.HeaderCell>
        <Table.HeaderCell style={{ width: "100px" }} onClick={e => onSort(e, 'readingScore')}>読解</Table.HeaderCell>
        {!isEdit ? <Table.HeaderCell style={{ width: "70px" }} onClick={e => onSort(e, 'totalScore')}>合計</Table.HeaderCell> : null}
        <Table.HeaderCell style={{ width: "100px" }} onClick={e => onSort(e, 'writingOriScore')}>作文</Table.HeaderCell>
        <Table.HeaderCell style={{ width: "100px" }} onClick={e => onSort(e, 'conversationOriScore')}>会話</Table.HeaderCell>
        <Table.HeaderCell style={{ width: "100px" }} onClick={e => onSort(e, 'writingScore')}>作文</Table.HeaderCell>
        <Table.HeaderCell style={{ width: "100px" }} onClick={e => onSort(e, 'conversationScore')}>会話</Table.HeaderCell>
        {/* {!isEdit ? <> */}
        <Table.HeaderCell style={{ width: "100px" }} onClick={e => onSort(e, 'resVocabularyScore')}>文字語彙</Table.HeaderCell>
        <Table.HeaderCell style={{ width: "100px" }} onClick={e => onSort(e, 'resListeningScore')}>聴解</Table.HeaderCell>
        <Table.HeaderCell style={{ width: "100px" }} onClick={e => onSort(e, 'resGrammarScore')}>文法</Table.HeaderCell>
        <Table.HeaderCell style={{ width: "100px" }} onClick={e => onSort(e, 'resReadingScore')}>読解</Table.HeaderCell>
        {!isEdit ? <Table.HeaderCell style={{ width: "70px" }} onClick={e => onSort(e, 'resTotalScore')}>合計</Table.HeaderCell> : null}
        {/* </> : null} */}
      </Table.Row>
    </Table.Header>
    {!loading && !grade2Form.length ? <Table.Body><Table.Cell textAlign="center" colSpan={16}>(資料無し)</Table.Cell></Table.Body> : null}
    {loading ? <Table.Body><Table.Row><Table.Cell textAlign="center" colSpan={16}><Segment>
      <Dimmer active inverted={companyObj.inverted}>
        <Loader inverted={companyObj.inverted}>Loading</Loader>
      </Dimmer>
      <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
    </Segment></Table.Cell></Table.Row></Table.Body> :
      <Table.Body>{grade2Form && grade2Form.map((item, index) => {
        return <Table.Row key={'g2' + item.studentNum + index}>
          {isEdit ? <Table.Cell>
            <Button icon='times' size='tiny' onClick={() => removeRow(item)} />
          </Table.Cell> : null}
          {/* <Table.Cell>{index + 1}</Table.Cell> */}
          <Table.Cell>{item.rank || ''}/{item.rankTotal || ''}</Table.Cell>
          <Table.Cell>{item.studentFullId}</Table.Cell>
          <Table.Cell>{item.jpnName}</Table.Cell>
          {/* <Table.Cell><a href={`/studentPage/2/${item.studentId}/1/1`} target="_blank" rel="noopener noreferrer" >{item.jpnName}</a></Table.Cell> */}
          <Table.Cell>{isEdit ? getScoreField('vocabularyScore', item, handleUpdateSetting, "number") : item.vocabularyScore}</Table.Cell>
          <Table.Cell>{isEdit ? getScoreField('listeningScore', item, handleUpdateSetting, "number") : item.listeningScore}</Table.Cell>
          <Table.Cell>{isEdit ? getScoreField('grammarScore', item, handleUpdateSetting, "number") : item.grammarScore}</Table.Cell>
          <Table.Cell>{isEdit ? getScoreField('readingScore', item, handleUpdateSetting, "number") : item.readingScore}</Table.Cell>
          {!isEdit ? <Table.Cell>{item.totalScore}</Table.Cell> : null}
          <Table.Cell>{isEdit ? getScoreField('writingOriScore', item, handleUpdateSetting, 'number') : item.writingOriScore}</Table.Cell>
          <Table.Cell>{isEdit ? getScoreField('conversationOriScore', item, handleUpdateSetting, 'number') : item.conversationOriScore}</Table.Cell>
          <Table.Cell>{isEdit ? getScoreField('writingScore', item, handleUpdateSetting, 'select') : item.writingScore}</Table.Cell>
          <Table.Cell>{isEdit ? getScoreField('conversationScore', item, handleUpdateSetting, 'select') : item.conversationScore}</Table.Cell>
          {/* <Table.Cell>{isEdit ? getScoreField('oriClass', item, handleUpdateSetting, 'classroom') : item.oriClass}</Table.Cell> */}
          <Table.Cell>{item.oriClass}</Table.Cell>
          <Table.Cell>{isEdit ? getScoreField('newClass', item, handleUpdateSetting, 'classroom') : item.newClass}</Table.Cell>

          <Table.Cell>{isEdit ? getScoreField('resVocabularyScore', item, handleUpdateSetting, "number") : item.resVocabularyScore}</Table.Cell>
          <Table.Cell>{isEdit ? getScoreField('resListeningScore', item, handleUpdateSetting, "number") : item.resListeningScore}</Table.Cell>
          <Table.Cell>{isEdit ? getScoreField('resGrammarScore', item, handleUpdateSetting, "number") : item.resGrammarScore}</Table.Cell>
          <Table.Cell>{isEdit ? getScoreField('resReadingScore', item, handleUpdateSetting, "number") : item.resReadingScore}</Table.Cell>
          {!isEdit ? <Table.Cell>{item.resTotalScore}</Table.Cell> : null}
          {/* {!isEdit ? <>
            <Table.Cell>{item.resVocabularyScore}</Table.Cell>
            <Table.Cell>{item.resListeningScore}</Table.Cell>
            <Table.Cell>{item.resGrammarScore}</Table.Cell>
            <Table.Cell>{item.resReadingScore}</Table.Cell>
            <Table.Cell>{item.resTotalScore}</Table.Cell></> : null} */}
        </Table.Row>
      })}
      </Table.Body>}
  </Table>);

export default class Exam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '',
      form: {
        testName: '0',
        selClass1: '',
        selClass2: '',
      },
      checkedBoxes: [],
      grade2: [],
      grade2Form: [],
      grade2FormAdd: [],
      sort: {},
      selGradeArr: [],
      selGradeId: '',
      isEdit: false,
      eventDate: '',
      gradeObj: {},
      classnameArr: [],
      // arrNeedToCreate: {},
      // arrOverRange: {},
      remindModal: false,
      loading: 1,
      avg: {},
      isCn: '0'
      // statArr:[],
    };
    this.removeRow = this.removeRow.bind(this);
  }

  async componentDidMount() {
    const { companyObj } = this.props
    const data = await Parse.queryData('Grade1', { companyId: companyObj.objectId });
    this.setState({ grade: data });

    const data1 = await Parse.queryData('Students', { companyId: companyObj.objectId });
    this.setState({ students: data1 }, () => this.handleFetch());

  }

  componentWillReceiveProps(nextProps) {
    if (this.props.students !== nextProps.students ||
      this.props.grade !== nextProps.grade) {
      this.handleFetch(nextProps)
    }
  }

  handleFetch = async (props = this.props) => {
    const { firebase, match, companyObj } = props;
    const { grade, students } = this.state
    // const { grade2, grade2Form, eventDate, loading } = this.state;
    // const db = firebase.firestore();
    const gradeId = match.params.gradeid;

    const selGradeArr = [];
    // if (!gradeL) {
    //   handleLazyLoad('grade');
    // } else {
    grade.forEach(({ _id, testDate, testCode, testLevel }) =>
      selGradeArr.push({ key: _id, text: `#${testCode || ''}  ${testLevel} (${testDate})`, value: _id }));

    const gradeObj = grade.find(item => item.objectId === gradeId);
    const obj = gradeObj ? { eventDate: gradeObj.testDate, gradeObj, avg: gradeObj.avg } : {};
    this.setState({ ...obj, selGradeArr, selGradeId: gradeId });
    // }
    this.setState({ loading: 1 });

    // if (!studentsL) {
    //   handleLazyLoad('students');
    // } else
    // db.collectionGroup('Grade').where('gradeId', '==', gradeId).orderBy('studentNum')
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(doc => data.push(doc.data()));
    const data = await Parse.queryData('Grade1', { companyId: companyObj.objectId, gradeId }, { orderBy: 'studentNum' });
    data.sort((a, b) => b.totalScoreNum - a.totalScoreNum);

    // data.forEach(item => console.log((students.find(item2 => item2._id === item.studentId) && students.find(item2 => item2._id === item.studentId).classSchool) || ''));
    // const grade2Form = data && data.length && data.map(item => ({ ...item, oriClass: students.find(item2 => item2._id === item.studentId).classSchool || '' }))
    const grade2Form = data && data.length ? data.map(item => ({ ...item, oriClass: (students.find(item2 => item2._id === item.studentId) && students.find(item2 => item2._id === item.studentId).classSchool) || '' })) : []
    this.setState({ grade2: data, grade2Form, loading: 0 }, () => this.convertImg());

  }

  closeModal = () => this.setState({ remindModal: false })

  handleAddStudent = (event, { name, value }) => {
    const { students } = this.props
    const { gradeObj, grade2FormAdd } = this.state;

    const stuObj = students.find(item => item._id === value)
    if (stuObj && stuObj.studentId && stuObj.jpnName) {
      const obj = {
        gradeId: gradeObj._id,
        semester: gradeObj.semester,
        semesterYear: gradeObj.semesterYear,
        testDate: gradeObj.testDate,
        testCode: gradeObj.testCode,
        // testType: gradeObj.testType,
        testLevel: gradeObj.testLevel || '',
        jpnName: stuObj.jpnName,
        studentId: value,
        studentNum: stuObj.studentId,
        studentFullId: `${stuObj.semesterYear.substr(-2)}(${stuObj && stuObj.semester})${stuObj.studentId}`,
        oriClass: stuObj.classSchool,
        newClass: stuObj.classSchool,
      }
      grade2FormAdd.push(obj);
      this.setState({ grade2FormAdd })
    }
  }

  handleChange = (event, data) => {
    const { companyObj, history, grade } = this.props;

    let fieldName = data.name;
    let fleldVal = data.value;

    if (fieldName === 'gradeId') {
      history.push("/admin/ExamTable/" + fleldVal);

      // firebase.firestore().collectionGroup('Grade')
      //   .where('gradeId', '==', fleldVal)
      //   // .orderBy('studentNum')
      //   .get().then(snap => {
      //     const data = [];
      //     snap.forEach(doc => data.push(doc.data()));
      //     // console.log(data);

      //     // console.log(fleldVal)
      //     const gradeObj = grade.find(item => item._id === fleldVal);
      //     this.setState({
      //       eventDate: gradeObj.testDate, gradeObj,
      //       selGradeId: fleldVal, grade2: data, grade2Form: data
      //     }, () => this.convertImg());
      //   }, err => {
      //     console.log(`Encountered error: ${err}`);
      //   });
      Parse.queryData('Grade1', { companyId: companyObj.objectId, gradeId: fleldVal }).then(snap => {
        const data = [];
        snap.forEach(doc => data.push(doc));
        const gradeObj = grade.find(item => item.objectId === fleldVal);
        this.setState({
          eventDate: gradeObj.testDate, gradeObj,
          selGradeId: fleldVal, grade2: data, grade2Form: data
        }, () => this.convertImg());
      }, err => {
        console.log(`Encountered error: ${err}`);
      });

      // } else     if (fieldName === 'gradeId') {
    } else if (fieldName === 'isCn') {
      this.setState({ [fieldName]: fleldVal })
    } else {
      this.setState({ form: { ...this.state.form, [fieldName]: fleldVal } })
    }
  }

  handleFile = (file/*:File*/) => {
    const { form, grade2Form, gradeObj } = this.state;
    const { match, students, semester, semesterYear } = this.props;
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Set range */
      var range = XLSX.utils.decode_range(ws['!ref']);
      range.s.c = 0; // 0 ===XLSX.utils.decode_col("A")
      range.e.c = 21; // 6 ===XLSX.utils.decode_col("G")
      var new_range = XLSX.utils.encode_range(range);
      // var excelInJSON = xlsx.utils.sheet_to_json(workbook.Sheets[firstSheet], { blankRows: false, defval: '', range: new_range });
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 'A', range: new_range });
      /* Update state */
      // this.setState({ data: data, cols: make_cols(ws['!ref']) });
      this.setState({ data });
      const arrNeedToCreate = [];
      const arrOverRange = [];
      const arr = [...grade2Form];

      for (let i = 2; i < data.length; i++) {
        const element = data[i];
        const studentNum = element.C;
        // const studentNum = Number(element.A.substr(1)) + "" || element.A.substr(1);
        let studentObj = students.find(item => item.studentId === (studentNum + ''));

        if (!studentObj) { // 資料庫找不到這個人，要新增到students中
          arrNeedToCreate.push(element.A + element.B);
          continue;
        }
        else if (studentObj) {
          if (!(studentObj.semesterYear === (Number(semesterYear) - 1) + ""
            || (semesterYear === studentObj.semesterYear && studentObj.semester === "S")
            || (semesterYear === studentObj.semesterYear && studentObj.semester === semester)
          )) {  // 這個人不是這個学期內應該出現的人
            arrOverRange.push(element.C);
            continue;
          } else {
            const doc = {};
            // const res = (Math.round(Number(element.N) / 5) * 5);
            // doc[form.testName] = element.N;
            if (form.testName === 'vocabularyScore') {
              doc[form.testName] = element.F;
              doc.resVocabularyScore = element.N;
            }
            if (form.testName === 'listeningScore') {
              doc[form.testName] = element.G;
              doc.resListeningScore = element.O;
            }
            if (form.testName === 'grammarScore') {
              doc[form.testName] = element.H;
              doc.resGrammarScore = element.P;
            }
            if (form.testName === 'readingScore') {
              doc[form.testName] = element.I;
              doc.resReadingScore = element.Q;
            }
            if (form.testName === 'writingScore') {
              doc[form.testName] = element.J || '';
            }
            if (form.testName === 'conversationScore') {
              doc[form.testName] = element.K || '';
            }


            // doc.oriClass = element.A.substr(0, 1);
            // doc.newClass = element.A.substr(0, 1);
            doc.isDel = "0";

            // 看是不是有這一筆
            const grade2Index = arr.findIndex(item => item.studentId === studentObj._id);
            if (grade2Index === -1) {
              doc.studentId = studentObj._id;
              doc.jpnName = studentObj.jpnName;
              doc.gradeId = gradeObj._id;
              doc.studentNum = studentNum;
              doc.studentFullId = `${studentObj.semesterYear.substr(-2)}(${studentObj && studentObj.semester})${studentObj.studentId}`;

              // doc.testType = gradeObj.testType;
              doc.testLevel = gradeObj.testLevel || '';
              doc.testCode = gradeObj.testCode;
              doc.testDate = gradeObj.testDate;
              doc.semesterYear = gradeObj.semesterYear;
              doc.semester = gradeObj.semester;
              arr.push(doc);
            } else {
              if (grade2Index !== -1) {
                const obj = arr[grade2Index];
                arr[grade2Index] = { ...obj, ...doc }
              }
            }
          }
        }
      }
      // console.log('arrNeedToCreate', arrNeedToCreate)
      // console.log('arrOverRange', arrOverRange)
      // console.log('arr', arr)
      this.setState({ grade2Form: arr, form: { testName: '0' }, remindModal: true, arrNeedToCreate, arrOverRange });
    };
    if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    // console.log('acceptedFiles', acceptedFiles);
    // console.log('rejectedFiles', rejectedFiles);
    this.handleFile(acceptedFiles[0]);
  }

  onSort = (event, sortKey, type = 'string') => {
    const { grade2Form, sort } = this.state;
    if (sort[sortKey]) {
      if (type === 'string') {
        grade2Form.sort((a, b) => a[sortKey] && a[sortKey].localeCompare(b[sortKey]))
      } else {
        grade2Form.sort((a, b) => a.totalScoreNum - b.totalScoreNum)
      }
    } else {
      if (type === 'string') {
        grade2Form.sort((a, b) => b[sortKey] && b[sortKey].localeCompare(a[sortKey]))
      } else {
        grade2Form.sort((a, b) => b.totalScoreNum - a.totalScoreNum)
      }
    }
    this.setState({ grade2Form, sortKey, sort: { ...sort, [sortKey]: sort[sortKey] ? !sort[sortKey] : true } });
  }

  saveUpdateSetting = () => {
    // console.log('saveUpdateSetting')
    const { firebase, students, refetch } = this.props;
    const { grade2, grade2Form, eventDate, gradeObj } = this.state;

    var promises = [];
    const db = firebase.firestore()
    var batch = db.batch();

    // let index = 0;
    let objG1 = { ...gradeObj };
    // const _arrG2 = [...grade2Form];
    const resObj = calMeanScores(grade2Form, objG1, students)
    objG1 = resObj.objG1;
    const arrG2 = resObj.arrG2;//.map(item)
    this.setState({ gradeObj: objG1 });
    firebase.firestore().doc(`/Grade/${gradeObj._id}`).set({ ...objG1 });

    // 舊、新
    // 先以舊的為主，在新的裡面沒有的話，就是要刪掉
    // console.log(grade2[0], grade2Form[0], arrG2[0]);
    grade2.forEach(item => {
      const obj = arrG2.find(item2 => item.studentId && item.studentId === item2.studentId);
      if (!obj) { // 新的沒有，就刪
        // /Students/268cR28tNmksGPHNG/Grade/2018-11-14
        // console.log('del', item.studentNum, `/Students/${item.studentId}/Grade/${item.testDate}`, item, obj);
        const ref = db.doc(`/Students/${item.studentId}/Grade/${item.testDate}`);
        batch.delete(ref);
      }
    });
    // 以新的為主，一樣的就pass，沒有或不一樣的話 就新增
    arrG2.forEach(item => {
      const obj = grade2.find(item2 => item.studentId === item2.studentId);
      if (!obj) { // 舊的沒有 就新增
        // /Students/268cR28tNmksGPHNG/Grade/2018-11-14
        // console.log('set1', item.studentNum, `/Students/${item.studentId}/Grade/${item.testDate}`);
        const ref = db.doc(`/Students/${item.studentId}/Grade/${item.testDate}`);
        batch.set(ref, item);
      } else { // 舊的有, 判斷一下一不一樣，一樣就跳過，不一樣就新增
        if (!objectEquals(item, obj)) {
          // classroom有被動到的時候，也要更新クラス
          // if (item.classname !== obj.classname) {
          // console.log('update classname', item.classname, obj.classname, eventDate);
          // db.doc(`/Students/268cR28tNmksGPHNG/Daily/2018-10-02`).set({ classname: item.classname });
          // db.doc(`/Students/${item.studentId}/Daily/${eventDate}`).set({
          //   studentId: item.studentId,
          //   classname: item.classname,
          //   eventDate
          // });
          // db.collection(`/Students/${item.studentId}/Daily/${eventDate}/Rollcall`)
          //   // db.collection(`/Students/268cR28tNmksGPHNG/Daily/2018-10-02/Rollcall`)
          //   .get().then(snap => {
          //     // console.log('collection Rollcall')
          //     if (snap.size) {
          //       snap.forEach(doc => {
          //         // console.log(doc.id, doc.data());
          //         doc.ref.update({ classname: item.classname });
          //       });
          //     }
          //   });
          // if (eventDate === Moment(new Date()).format('YYYY-MM-DD')) {
          // console.log('update today classname', item.classname);

          // console.log('set2', item.studentNum, `/Students/${item.studentId}/Grade/${item.testDate}`);
          const ref = db.doc(`/Students/${item.studentId}/Grade/${item.testDate}`);
          batch.set(ref, item);
        }
      }
    })

    var promise = batch.commit();
    promises.push(promise);
    Promise.all(promises).then(results => {
      this.setState({
        gradeObj: objG1, grade2Form: arrG2, isEdit: false,
        arrNeedToCreate: [], arrOverRange: [], avg: objG1.avg
      }, () => refetch('Grade'))
      console.log('all promises were done')
      // console.log(results)
    })
      .catch(error => { console.log(error) });
  }

  handleSetNewClass = () => {

    const { firebase } = this.props;
    const { grade2Form } = this.state;

    var promises = [];
    const db = firebase.firestore()
    var batch = db.batch();
    if (grade2Form && grade2Form.length) {
      grade2Form.forEach(item => {
        if (item.newClass) {
          // item
          // console.log(item);
          const ref = db.doc(`/Students/${item.studentId}`);
          batch.update(ref, {
            classSchool: item.newClass,
            classSchoolText: item.newClass
          });
        }
      });

      var promise = batch.commit();
      promises.push(promise);
      Promise.all(promises).then(results => {
        console.log('class update done')
        alert('登録完了')
      }).catch(error => { alert('登録できませんでした') });
    } else {
      alert('情報なし')
    }
  }

  saveAddSetting = () => {
    const { firebase } = this.props;
    const { grade2, grade2Form, grade2FormAdd, eventDate, gradeObj } = this.state;

    var promises = [];
    const db = firebase.firestore()
    var batch = db.batch();

    const objG1 = { ...gradeObj };
    const arrG2 = [...grade2Form];
    calMeanScores(grade2, objG1, arrG2);

    // 以新的為主，一樣的就pass，沒有或不一樣的話 就新增
    grade2FormAdd.forEach(item => {
      const obj = grade2.find(item2 => item.studentId === item2.studentId);
      arrG2.push(item);
      if (!obj) { // 舊的沒有 就新增
        // /Students/268cR28tNmksGPHNG/Grade/2018-11-14
        // console.log('set1', item.studentNum, `/Students/${item.studentId}/Grade/${item.testDate}`);
        const ref = db.doc(`/Students/${item.studentId}/Grade/${item.testDate}`);
        batch.set(ref, item);
      } else { // 舊的有, 判斷一下一不一樣，一樣就跳過，不一樣就新增
        if (!objectEquals(item, obj)) {
          // classroom有被動到的時候，也要更新クラス
          if (item.newClass !== obj.newClass) {
            // console.log('update classname', item.classname, obj.classname, eventDate);

            // db.doc(`/Students/268cR28tNmksGPHNG/Daily/2018-10-02`).set({ classname: item.classname });
            db.doc(`/Students/${item.studentId}/Daily/${eventDate}`).set({
              studentId: item.studentId,
              classname: item.newClass,
              eventDate
            });
            db.collection(`/Students/${item.studentId}/Daily/${eventDate}/Rollcall`)
              // db.collection(`/Students/268cR28tNmksGPHNG/Daily/2018-10-02/Rollcall`)
              .get().then(snap => {
                // console.log('collection Rollcall')
                if (snap.size) {
                  snap.forEach(doc => {
                    // console.log(doc.id, doc.data());
                    doc.ref.update({ classname: item.newClass });
                  });
                }
              });
            // if (eventDate === Moment(new Date()).format('YYYY-MM-DD')) {
            //   // console.log('update today classname', item.classname);
            //   db.doc(`/Students/${item.studentId}`).update({ classSchool: item.newClass });
            // }
          }
          // console.log('set2', item.studentNum, `/Students/${item.studentId}/Grade/${item.testDate}`);
          const ref = db.doc(`/Students/${item.studentId}/Grade/${item.testDate}`);
          batch.set(ref, item);
        }
      }
    })

    var promise = batch.commit();
    promises.push(promise);
    Promise.all(promises).then(results => {
      this.setState({ gradeObj: objG1, grade2FormAdd: [], grade2Form: arrG2 })
      // console.log('all promises were done')
    }).catch(error => { console.log(error) });
  }

  handleUpdateSetting = (event, data) => {
    const { grade2Form } = this.state
    let fieldName = data.name;
    let fleldVal = data.value;

    const arr = [...grade2Form];
    const findIndex = arr.findIndex(item => item.studentId === data.item2.studentId);
    if (findIndex !== -1) {
      const obj = arr[findIndex];
      arr[findIndex] = { ...obj, [fieldName]: fleldVal }
      this.setState({ grade2Form: arr });
    }
  }

  handleAddSetting = (event, data) => {
    const { grade2FormAdd } = this.state
    let fieldName = data.name;
    let fleldVal = data.value;

    const arr = [...grade2FormAdd];
    const findIndex = arr.findIndex(item => item.studentId === data.item2.studentId);
    if (findIndex !== -1 && data.item2.studentId) {
      const obj = arr[findIndex];
      arr[findIndex] = { ...obj, [fieldName]: fleldVal }
      this.setState({ grade2FormAdd: arr });
    }
  }

  removeRow = (doc) => {
    const { grade2Form } = this.state
    // if (!confirm("確認要削除嗎?")) {
    //   return;
    // }
    const arr = [...grade2Form]
    const findIndex = grade2Form.findIndex(item => item.studentId === doc.studentId);
    if (findIndex !== -1) {
      arr.splice(findIndex, 1);
      this.setState({ grade2Form: arr });
    }
    return;
  }

  removeAddRow = (doc) => {
    const { grade2FormAdd } = this.state
    // if (!confirm("確認要削除嗎?")) {
    //   return;
    // }
    const arr = [...grade2FormAdd]
    const findIndex = grade2FormAdd.findIndex(item => item.studentId === doc.studentId);
    if (findIndex !== -1) {
      arr.splice(findIndex, 1);
      this.setState({ grade2FormAdd: arr });
    }
    return;
  }

  removeTest = async () => {
    // if (confirm(`確定要刪除此配当表嗎?`)) {
    const { firebase, refetch } = this.props;
    const { selGradeId, grade2Form } = this.state;
    var promises = [];
    const db = firebase.firestore()
    var batch = db.batch();
    grade2Form.forEach(item => {
      const ref = db.doc(`/Students/${item.studentId}/Grade/${item.testDate}`);
      batch.delete(ref);
    });
    const test = db.doc(`Grade/${selGradeId}`);
    batch.delete(test);

    var promise = batch.commit();
    promises.push(promise);
    await Promise.all(promises).then(results => {
      this.setState({ isEdit: false })
      console.log('all promises were done')
    }).catch(error => { console.log(error) });
    refetch('Grade');
    this.props.history.push(`/ExamTable`)
    this.hideConfirm();
  };
  showConfirm = () => this.setState({ confirm: true }); //確認刪除
  showSetConfirm = () => this.setState({ confirmSetClass: true }); //確認登録班級
  hideConfirm = () => this.setState({ confirm: false, confirmSetClass: false, form: {} });

  contextRef = createRef();

  convertImg = () => {
    let canvas = document.getElementById('chartBar');
    if (canvas) {
      let imgURL = canvas.toDataURL();
      this.setState({ imgURL }
        // , () => console.log(imgURL)
      )
    } else {
      this.setState({ imgURL: '' })
    }
  }

  render() {
    const { history, formSet, optSet, grade, classroom, students, match, companyObj } = this.props;
    const { selGradeArr, selGradeId, isEdit, isCn, eventDate, gradeObj, grade2, grade2Form, grade2FormAdd,
      // statArr,
      classnameArr, arrNeedToCreate, arrOverRange, loading, imgURL, avg } = this.state;

    const classSchoolArr = getOptionsByKey(optSet, 'classSchool');
    const testArr = getOptionsByKey(optSet, 'isTest');

    const selTestArr = testArr.sort((a, b) => b.value - a.value).map(item => ({ key: item._id, text: item.label, value: item.value }))
    selTestArr.unshift({ key: 'empty', text: '- 選択 -', value: '0' });

    const selClassnameArr = classSchoolArr.map(item => ({ key: item._id, text: item.label, value: item.label }))
    selClassnameArr.unshift({ key: 'empty', text: '- 選択 -', value: '' });

    const newClassStat = classSchoolArr.map(({ label }) =>
      ({ classroom: label, count0: grade2Form && grade2Form.filter(item2 => item2.oriClass === label).length, count: grade2Form && grade2Form.filter(item2 => item2.newClass === label).length }))
      .filter(item3 => item3.count0 || item3.count);

    const currentStudents = students.filter(item =>
      (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
      (item.applyResult === '1' || item.studentType === "2"));

    let exceptStudents = [...currentStudents];//新增選單排除已在名單的学生

    var pattern2 = new RegExp("[A-Za-z]+");

    // console.log(isCn)
    let gradeFilter = (isCn === '1' ? grade2Form.filter(item => !pattern2.test(item.jpnName)) :
      isCn === '2' ? grade2Form.filter(item => pattern2.test(item.jpnName)) :
        grade2Form)

    grade2Form && grade2Form.forEach(({ studentId }) => {
      const existIndex = exceptStudents.findIndex(item => item._id === studentId);
      if (existIndex !== -1) {
        exceptStudents.splice(existIndex, 1);
      }
      if (grade2FormAdd) {
        grade2FormAdd.forEach(({ studentId }) => {
          const chooseIndex = exceptStudents.findIndex(item => item._id === studentId);
          if (chooseIndex !== -1) {
            exceptStudents.splice(chooseIndex, 1);
          }
        })
      }
    })

    const optionStudents = exceptStudents.sort((a, b) => ('' + a.studentId).localeCompare(b.studentId))
      .map(item => ({
        key: item._id, text: `学${item.studentId ? `${item.semesterYear.substr(-2)}(${item.semester})${item.studentId}` : '(なし)'} ${item.jpnName}`, value: item._id
      }));
    optionStudents.unshift({ key: 'empty', text: '- 学生追加選択 -', value: '0' });

    const isCnArr = [{ key: 'empty', text: '- ALL -', value: '0' }
      , { key: 'yes', text: '漢字圈', value: '1' }
      , { key: 'not', text: '非漢字圈', value: '2' }]

    const arrPdf = [{
      gradeObj,
      newClassStat,
      avg,
      grade2Form,
      imgURL
    }];

    return <div style={{ padding: '0 2% 2% 2%' }} >
      {gradeObj ? <div style={{ float: 'right' }}>
        <Input labelPosition='left' >
          <Label color={'blue'}> <Icon name='file alternate outline' />テスト</Label>
          <Select style={{
            minWidth: '300px',
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0'
          }} compact
            disabled={isEdit}
            options={selGradeArr} onChange={this.handleChange} value={selGradeId} name='gradeId' />
        </Input>
        &nbsp;&nbsp;&nbsp;
        {isEdit ? <Button positive onClick={() => this.saveUpdateSetting()} icon='save' content='保存' /> :
          <Button size='medium' color='green' onClick={() => this.setState({ isEdit: true })} icon='edit' content="編集" />}
        {isEdit ? <Button basic onClick={() => this.setState({ grade2Form: grade2, isEdit: false, arrNeedToCreate: [], arrOverRange: [] })} icon='times' content='キャンセル' /> : null}
        <Button basic onClick={() => history.push(`/ExamTable`)}><Icon name='reply' /> 前のページ</Button>
      </div> : null}
      <div ref={el => (this.componentRef = el)}>
        <div style={{ float: 'left' }}>
          {/* {match.params.examKey === 'termExam' ? */}
          <h2>定期テスト結果入力</h2>
          {/* : <h2>校外試験結果入力</h2>} */}
          <h4 style={{ margin: '0' }}>{gradeObj ? `#${gradeObj.testCode || ''} ${gradeObj.testLevel || ''} (${gradeObj.testDate || ''})` : null}</h4>
        </div>
        <br />

        <div style={{ clear: 'both' }}></div>
        <br />
        <Grid>
          {isEdit ?
            <Grid.Row>
              <Grid.Column width={3}>
                <Form.Group >
                  <label>科目xlsをログインする</label>{'  '}
                  <Form.Select
                    name="testName"
                    scrolling={false}
                    options={selTestArr}
                    disabled={!isEdit}
                    value={this.state.form.testName}
                    onChange={this.handleChange} />
                </Form.Group>
              </Grid.Column>
              <Grid.Column width={3}>
                {this.state.form.testName ?
                  <Form.Group >
                    <label>添付ファイル</label>{'  '}
                    <br />
                    <Dropzone onDrop={acceptedFiles => this.onDrop(acceptedFiles)}>
                      {/* <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}> */}
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>click to select files</p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </Form.Group>
                  : ''}
              </Grid.Column>
              <Grid.Column width={10}>
              </Grid.Column>
            </Grid.Row>
            : null}
        </Grid>
        {!isEdit ? <Grid>
          <Grid.Column width={4}>
            <h3>クラス編成</h3>
            <Table striped size="small" className='thPadding2px tdPadding2px'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>現</Table.HeaderCell>
                  <Table.HeaderCell>新</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {newClassStat.map(({ classroom, count0, count }, index) =>
                  <Table.Row key={"new" + classroom}><td>{classroom}</td><td>{count0}</td><td>{count}</td></Table.Row>)}
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column width={8}>
            {gradeObj.statArr ?
              <Bar
                id="chartBar"
                data={{
                  labels: statXLabel,
                  datasets: [
                    {
                      label: '人数',
                      backgroundColor: 'rgba(255,99,132,0.2)',
                      borderColor: 'rgba(255,99,132,1)',
                      borderWidth: 1,
                      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                      hoverBorderColor: 'rgba(255,99,132,1)',
                      data: gradeObj.statArr
                    }
                  ]
                }}
                width={400}
                height={220}
                options={{
                  maintainAspectRatio: false
                }}
              /> : null}
          </Grid.Column>
          <Grid.Column width={4}>
            {/* <ReactToPrint
              trigger={(e) => <Button
                content='印刷'
                // label='&nbsp;'
                color='orange' icon='print'
                size='small'
                floated='right'
                className='no-print'
              />}
              content={() => this.componentRef}
              pageStyle={"@page { size: auto;  margin:5mm; }*{font-family: 'Noto Serif JP', serif;}"}
            /> */}
            {/* <Button floated='right' content='convertImg' onClick={() => this.convertImg()} /> */}
            <ModalPdf
              floated='right'
              {...this.props}
              // ref={(ref) => { this.pdfModal = ref }}
              codekey="examPdf"
              params={arrPdf}
            />
            <ExportTableToExcelButton floated='right' size='small' />
            <div style={{ clear: 'both' }}></div>
            <Table size="small" striped celled className='thPadding2px tdPadding2px'>
              <Table.Header >
                <Table.Row>
                  <Table.HeaderCell style={{ width: "60px" }} ></Table.HeaderCell>
                  <Table.HeaderCell style={{ width: "60px" }} >文字語彙</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: "60px" }} >聴解</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: "60px" }} >文法</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: "60px" }} >読解</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: "60px" }} >合計</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>平均</Table.Cell>
                  <Table.Cell>{this.state.avg ? this.state.avg.vocabularyAvg : 0}</Table.Cell>
                  <Table.Cell>{this.state.avg ? this.state.avg.listeningAvg : 0}</Table.Cell>
                  <Table.Cell>{this.state.avg ? this.state.avg.grammarAvg : 0}</Table.Cell>
                  <Table.Cell>{this.state.avg ? this.state.avg.readingAvg : 0}</Table.Cell>
                  <Table.Cell>{this.state.avg ? this.state.avg.totalAvg : 0}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <br />
            <Button color='brown' floated='right' size='small' onClick={() => this.showSetConfirm()}><Icon name='check' /> 新クラスを登録する</Button>
            <Input labelPosition='left' >
              <Label color={'blue'}> <Icon name='building' />漢字圈</Label>
              <Select
                style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', width: '150px' }}
                compact options={isCnArr} onChange={this.handleChange} value={isCn} name='isCn' />
            </Input>
          </Grid.Column>
        </Grid> : null}
        {gradeObj ?
          <Ref innerRef={this.contextRef}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form>
                    <ScoreTable
                      isEdit={isEdit}
                      onSort={this.onSort}
                      removeRow={this.removeRow}
                      eventDate={eventDate}
                      grade2Form={gradeFilter}
                      selClassnameArr={selClassnameArr}
                      handleUpdateSetting={this.handleUpdateSetting}
                      contextRef={this.contextRef}
                      loading={loading}
                      companyObj={companyObj}
                    // fields={fields}
                    />
                  </Form>
                  <br />
                  <br />
                  {isEdit ? <Form>
                    <div style={{ float: 'left' }}>
                      <Form.Select
                        style={{ width: '300px' }}
                        search
                        options={optionStudents}
                        onChange={this.handleAddStudent}
                        value='0'
                        size="tiny" />
                    </div>
                    <Button floated='right' primary onClick={() => this.saveAddSetting()} icon="add" content="追加確認" />
                    <div style={{ clear: 'both' }}></div>
                    <ScoreTable
                      isEdit={isEdit}
                      onSort={this.onSort}
                      removeRow={this.removeAddRow}
                      eventDate={eventDate}
                      grade2Form={grade2FormAdd}
                      selClassnameArr={selClassnameArr}
                      handleUpdateSetting={this.handleAddSetting}
                    />
                    <Button floated='right' color='red' onClick={() => this.showConfirm()} icon="delete" content="削除テスト" />
                  </Form> : null}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6}>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Ref> : <><br /><br /><Segment>
            <Dimmer active inverted={companyObj.inverted}>
              <Loader inverted={companyObj.inverted}>Loading</Loader>
            </Dimmer>
            <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
          </Segment></>}
        <Confirm
          header='削除'
          content='削除してもいいですか?'
          cancelButton='いいえ'
          confirmButton='はい'
          open={this.state.confirm}
          onCancel={this.hideConfirm}
          onConfirm={this.removeTest}
          size='mini'
          centered={false} />
      </div>
      <Modal open={this.state.remindModal}>
        <Modal.Header icon='archive' content='Score import reminder' />
        <Modal.Content>
          <p>在学生名單にない:</p>
          <p>{arrNeedToCreate + ' '}</p>
          {/* <p>本学期之学生: */}
          {/* {arrOverRange ? (arrOverRange + ' ') : 'None'}</p> */}
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={this.closeModal}>
            <Icon name='checkmark' /> OK
          </Button>
        </Modal.Actions>
      </Modal>
      <Confirm
        header='登録確認'
        content='登録確認?'
        cancelButton='いいえ'
        confirmButton='はい'
        open={this.state.confirmSetClass}
        onCancel={this.hideConfirm}
        onConfirm={this.handleSetNewClass}
        size='mini'
        centered={false} />
    </div>
  }
}