import React, { Component } from 'react'
import { Container, Dimmer, Loader, Image, Segment, Select } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import ModalUploadFiles from 'views/web/ModalUploadFiles'
import Moment from 'moment';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';
// import { portfolio } from 'views/formDef/object';

const objInsuranceStatus = [
  { value: 1, text: '生效 In force' },
  { value: 2, text: '解約 Surrender' },
];

const objNowPhase = [
  { value: 1, text: '申請中' },
  { value: 2, text: '已生效' },
  { value: 3, text: '無效' },
];

// const objHalfYear = [
//   { value: "1", text: "一次" },
//   { value: "2", text: "一年" },
//   { value: "3", text: "上半年" },
//   { value: "4", text: "下半年" },
// ];

const funcObjFind2 = (obj, id) => {
  var ret = "";
  obj.forEach((entry) => {
    // console.log(entry);
    if (Number(entry.value) === id) {
      ret = entry.text;
    }
  });
  return ret;
}

const columns = () => ([
  // { title: '發佈對象 ', field: 'towhom', lookup: { '0': '', '1': '內部', '2': '外部' } },
  // { title: '月份', field: 'jobNum', editable: 'never' },
  { title: '檔案名稱', field: 'name', editable: 'never', render: rowData => <a href={rowData.file1.url} target="_blank" rel="noopener noreferrer">{rowData.name}</a> },
  // { title: '12月', field: 'amount12' },
  // { title: '1月', field: 'amount1' },
  // { title: '2月', field: 'amount2' },
  // { title: '3月', field: 'amount3' },
  // { title: '4月', field: 'amount4' },
  // { title: '5月', field: 'amount5' },
  // { title: '6月', field: 'amount6' },
  // { title: '7月', field: 'amount7' },
  // { title: '8月', field: 'amount8' },
  // { title: '9月', field: 'amount9' },
  // { title: '10月', field: 'amount10' },
  // { title: '11月', field: 'amount11' },
  // { title: '薪資合計', field: 'amountTotal' },
  // { title: '代收稅款', field: 'amountTax' },
  // { title: '公告時間', field: 'publishAt_time' },
  // { title: '新增時間', field: 'insertedAt_time' }, 
  // { title: '地址', field: 'addr', cellStyle: { width: '250px' }, headerStyle: { width: '250px' } },
]);

export default class List3Client extends Component {
  state = {
    dataArr: [],
    deleteConfirm: false,
    loading: true,
    mgyearId: '',
    mgyearArr: [],
    porArr: [],
    porId: '',
    monthSel: Moment(new Date()).format('YYYY-MM') || '',
    obj: {},
  }
  async componentDidMount() {
    const { companyObj } = this.props
    const { monthSel } = this.state
    const [year, month] = monthSel.split('-')
    const companyId = companyObj.objectId;
    const mgyear = await Parse.queryData('mgyear', { companyId });
    const mgyearArr = mgyear.filter(item => item.value).map(item => ({
      key: item.objectId, text: `${item.value_AD}`, value: item.value_AD
    }));
    const mgyearId = (mgyearArr.find(item => item.value === year) && mgyearArr.find(item => item.value === year).value) || ''
    this.setState({ mgyearArr, mgyearId }, () => this.handleFetch());
  }
  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }
  handleFetch = async (props = this.props, type = 1) => {
    const { doc, portfolios } = props;
    if (portfolios) {
      const porArr = portfolios.filter(item => item.client_id === doc.objectId).map(item => {
        if (!item.account_num) {
          return { key: item.objectId, text: `${item.product3_text}`, value: item.objectId }
        } else {
          return { key: item.objectId, text: `${item.product3_text + " " + item.account_num + " " + funcObjFind2(objInsuranceStatus, item.insurance_status) + " " + funcObjFind2(objNowPhase, item.nowphase)}`, value: item.objectId }
        }
      })
      if (porArr.length <= 0) {
        porArr.unshift({ key: 'selRelNo', text: '(無)', value: '0', disabled: true })
        this.setState({ porArr, porId: '0', loading: false })
      } else {
        this.setState({ porArr, porId: porArr && porArr.length && porArr[0].value }, () => this.handleFileData())
      }
    }
  }


  handleFileData = async () => {
    const { companyObj } = this.props
    const { porId, mgyearId } = this.state
    const dataArr = await Parse.queryData('fileCenter2', { companyId: companyObj.objectId, fileCenter1Id: porId, year: mgyearId })
    this.setState({ dataArr, loading: false })
  }


  handleDelete = async () => {
    const { oldData } = this.state
    await Parse.deleteData('fileCenter2', oldData);
    this.setState({ oldData: {} })
  }
  // handleFilesChange = async (e) => {
  //   const fileArr = e.target.files;
  //   if (!fileArr || !fileArr.length) {
  //     return
  //   }
  //   const { porId } = this.state
  //   const { companyObj, doc } = this.props
  //   const promises = [];
  //   for (let i = 0; i < fileArr.length; i++) {
  //     const file = fileArr[i];
  //     promises.push(Parse.putFile(file));
  //   }
  //   const theFiles = await Promise.all(promises).catch(error => console.log("error", error));

  //   const promises2 = [];
  //   theFiles.forEach(async (theFile) => {
  //     const db = Parse.Object.extend("clientFile");
  //     const newDoc = new db();
  //     const docRef = await newDoc.save();

  //     const element = {
  //       objectId: docRef.id,
  //       id: docRef.id,
  //       _id: docRef.id,
  //       companyId: companyObj.objectId,
  //       companyName: companyObj.name,
  //       clientId: doc.objectId,
  //       portfolioId: porId,
  //       type: 'electronicReport',
  //       theFile
  //     };
  //     promises2.push(Parse.saveData('clientFile', element));
  //   })
  //   await Promise.all(promises2).catch(error => console.log("error", error));
  //   window.alert('上傳成功')
  // }

  handleChange = (event, data) => {
    const { name, value } = data;
    this.setState({ [name]: value, loading: true }, () => this.handleFileData());
  }
  render() {
    const { doc, auth } = this.props
    const { dataArr, loading, mgyearArr, mgyearId, porArr, porId } = this.state;
    const porName = (porArr && porId && porArr.find(item => item.value === porId) && porArr.find(item => item.value === porId).text) || ''
    return (
      <Container>
        {/* 資金需求預估表 */}
        <br />
        <Select style={{
          position: 'relative', zIndex: 99,
          float: 'left',
          minWidth: '200px',
        }}
          compact
          name="porId"
          options={porArr}
          value={porId}
          onChange={this.handleChange}
        />
        <Select style={{
          position: 'relative', zIndex: 99,
          float: 'left',
          minWidth: '200px',
          // borderTopLeftRadius: '0',
          // borderBottomLeftRadius: '0'
        }}
          compact
          name="mgyearId"
          options={mgyearArr}
          value={mgyearId}
          onChange={this.handleChange}
        />
        <br />
        <br />
        <br />
        <MaterialTable
          isLoading={loading}
          localization={localization()}
          columns={columns()}
          components={{
            Toolbar: props => (<div>
              <div style={{ width: '220px', float: 'right', textAlign: 'right', padding: '15px' }}>

                {/* <Button as='label'
                  color='orange'
                  size='small'
                  content={<>選擇檔案（多選)</>}
                  labelPosition="left"
                  icon={<Icon name='arrow alternate circle up' />}
                /> */}
                {porArr.length > 0 && porId !== '0' && auth.edit ? <ModalUploadFiles {...this.props} isImage='0' handleData={this.handleFileData} dbName='fileCenter2' fieldName='file1' condition={{ fileCenter1Id: porId, fileCenter1Text: (porArr.find(item => item.value === porId) && porArr.find(item => item.value === porId).text) || '', type: 'report', year: mgyearId }} /> : null}
              </div>
              <MTableToolbar {...props} />
              <div style={{ clear: 'both' }}></div>
            </div>),
          }}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportAllData: true,
            exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearId} 年 ${porName} 電子報表`),
            pageSize: dataArr.length || 10,
            search: false
          }}
          data={dataArr}
          title={`${mgyearId} 年 ${porName} 電子報表`}
          editable={{
            isDeleteHidden: () => !auth.edit,
            // onRowAdd: newData1 =>
            //   new Promise(async (resolve, reject) => {
            //     // console.log(data)
            //     // const db = firebase.firestore();
            //     // const docRef = db.collection('acc1').doc();
            //     const db = Parse.Object.extend("clientFile");
            //     const newDoc = new db();
            //     const doc = await newDoc.save();
            //     const data = [...dataArr];
            //     const obj = {
            //       _id: doc.objectId || '',
            //       id: doc.objectId || '',
            //       objectId: doc.objectId || '',
            //       companyId: companyObj.objectId || '',
            //       companyName: companyObj.name || '',
            //       client_id: doc.objectId,
            //       type: 'electronicReport',
            //       ...newData1
            //     }
            //     data.push(obj);
            //     // let objww=obj===obj?true:false
            //     this.setState({ data1: data, newData1: obj, loading: true }, () => resolve());
            //   }).then(() => this.handleAdd("1")),
            // onRowUpdate: (newData1, oldData1) =>
            //   new Promise((resolve, reject) => {
            //     const data = [...dataArr];
            //     const index = data.indexOf(oldData1);
            //     data[index] = newData1;
            //     this.setState({ data1: data, newData1, oldData1, loading: true }, () => resolve());
            //   }).then(() => this.handleUpdate("1")),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                let data = [...dataArr];
                const index = data.indexOf(oldData);
                data.splice(index, 1);
                this.setState({ dataArr: data, oldData }, () => resolve());
              }).then(() => this.handleDelete()),
          }}
        />
        <br />
        <br />
      </Container>)
  }
}