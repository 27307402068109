import React, { Component } from 'react'
import { Menu } from 'semantic-ui-react';

import After1a from './After1a';
import After1b from './After1b';
import After1c from './After1c';
import Parse from 'widget/parse'

const menu = [
  { id: 'after1a', content: '2-1-1 新增郵件' },
  { id: 'after1b', content: '2-1-2 排程郵件' },
  { id: 'after1c', content: '2-1-2 寄件備份' },
]

export default class After1 extends Component {
  state = {
    loginUser: this.props.user.profile.toJSON(),
    activeItem: 'after1a'
  }

  componentDidMount = async () => {
    const { loginUser } = this.state
    const { companyObj } = this.props
    const companyId = companyObj.objectId;
    const authPage = window.location.pathname.split('/')[2]
    const authUser = companyObj.objectId + '_' + authPage
    let auth = {}
    let isAgent = ''

    if (loginUser.authAdminA && loginUser.authAdminA.indexOf(companyObj.objectId) === -1 && loginUser.role && loginUser.role.value === '1') {
      isAgent = loginUser.objectId
    }

    if ((loginUser.authAdminA && loginUser.authAdminA.indexOf(companyObj.objectId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
      auth = {
        edit: true,
      }
    } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
      auth = {
        edit: false,
      }
    } else {
      auth = {
        edit: false,
      }
    }

    if (isAgent && isAgent.length) {
      Parse.queryData('portfolios', { companyId, agent_id: isAgent }).then(async (portfolios) => {
        const clients = await Parse.queryData('clients', { companyId, agent_id: isAgent });
        const clientOpt = [];
        clients.forEach(item => clientOpt.push({ key: item.objectId, value: item.objectId, text: `${item.name_cht} (${item.name_eng}) [${item.email}]` }));
        let copyData = portfolios.map(item => item = { ...item, copy: 1 })
        this.setState({ copyData, auth, clientOpt, clients });
      });
    } else {
      Parse.queryData('portfolios', { companyId }).then(async (portfolios) => {
        const clients = await Parse.queryData('clients', { companyId });
        const clientOpt = [];
        clients.forEach(item => clientOpt.push({ key: item.objectId, value: item.objectId, text: `${item.name_cht} (${item.name_eng}) [${item.email}]` }));
        let copyData = portfolios.map(item => item = { ...item, copy: 1 })
        this.setState({ copyData, auth, clientOpt, clients });
      });
    }
  }



  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name })
  }

  render() {
    const { activeItem, auth, isAgent, copyData, clientOpt, clients } = this.state;

    return (<div className='content'>
      <Menu pointing>
        {menu.map(({ id, content }) =>
          <Menu.Item
            key={id}
            name={id}
            content={content}
            active={activeItem === id}
            onClick={this.handleItemClick}
          />)}
      </Menu>
      <br />
      {activeItem === 'after1a' ? <After1a {...this.props} auth={auth} isAgent={isAgent} portfolios={copyData} clientOpt={clientOpt} clients={clients} /> : null}
      {activeItem === 'after1b' ? <After1b {...this.props} auth={auth} isAgent={isAgent} portfolios={copyData} clientOpt={clientOpt} clients={clients} /> : null}
      {activeItem === 'after1c' ? <After1c {...this.props} auth={auth} isAgent={isAgent} portfolios={copyData} clientOpt={clientOpt} clients={clients} /> : null}
    </div>)
  }
}