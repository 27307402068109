import React, { Component } from 'react'
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
// import { Container, Header, Form, Icon } from 'semantic-ui-react'
import { Container, Header, Form, Icon, Grid, Input, Label, Checkbox, Select, Button, Table, Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
import { getOptionsByKey } from 'views/widgets/FieldsRender';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import ModalPdf from 'widget/pdf/ModalPdf';
// import ReactToPrint from "react-to-print";
import Parse from '../../../widget/parse'
import { Row, Col } from 'reactstrap'


const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: ' center'
  }
}
export class StreamingManagement extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      isEdit: false,
      doc: [],
      teachers: [],
      classroom: [],
      showEditPeriod: false,
      classTime: '1',
      classArr: [],
      yearsemester: `${this.props.semesterYear},${this.props.semester}`,
      semesterYear: this.props.semesterYear,
      semester: this.props.semester,
      loading: true,
    };
  }

  componentDidMount() {
    this.handleFetch()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.semesterYear !== this.props.semesterYear || nextProps.semester !== this.props.semester)
      this.setState({ loading: true }, () => this.handleFetch(nextProps))
  }

  handleFetch = async (props = this.props) => {
    const { firebase, semester, semesterYear, companyObj } = props;
    // const { semester, semesterYear } = this.state;

    // firebase.firestore().collection("Teachers")
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }));
    //     // console.log(data)
    //     this.setState({ teachers: data });
    //   });
    const data = await Parse.queryData('Teachers', { companyId: companyObj.objectId });
    this.setState({ teachers: data });

    // firebase.firestore().collection("Classroom")
    //   // .where('semesterYear', '==', semesterYear)
    //   // .where('semester', '==', semester)
    //   // .where('isEnableClass', '==', true)
    //   // .orderBy('classSchool')
    //   .get().then(snap => {
    //     const classroom = [];
    //     snap.forEach(doc => classroom.push({ _id: doc.id, ...doc.data() }));
    //     // console.log(data)
    //     const classArr = classroom.filter(item => item.semesterYear === semesterYear && item.semester === semester);
    //     // console.log(classArr)
    //     this.setState({ classroom, classArr, doc: classArr, loading: false }, () => this.getPrintData());
    //   });
    const classroom = await Parse.queryData('Classroom', { companyId: companyObj.objectId });
    const classArr = classroom.filter(item => item.semesterYear === semesterYear && item.semester === semester);

    this.setState({ classroom, classArr, doc: classArr, loading: false }, () => this.getPrintData());

  }

  showCurrentSemester() {
    const { semesterYear, semester } = this.props;
    const arr = [];

    if (semester === 'A') {
      arr.push({ id: '1', semesterYear, semester, semesterText: semester === 'A' ? '秋' : '春', entryYear: semesterYear, entryMonth: '4', entryText: '10月生', value: semesterYear + ',' + '10' });
    }
    arr.push({ id: '2', semesterYear, semester, semesterText: semester === 'A' ? '秋' : '春', entryYear: semesterYear, entryMonth: '4', entryText: '4月生', value: semesterYear + ',' + '4' });

    const pre = (Number(semesterYear) - 1) + '';
    arr.push({ id: '3', semesterYear, semester, semesterText: semester === 'A' ? '秋' : '春', entryYear: pre, entryMonth: '10', entryText: '10月生', value: pre + ',' + '10' });
    arr.push({ id: '4', semesterYear, semester, semesterText: semester === 'A' ? '秋' : '春', entryYear: pre, entryMonth: '4', entryText: '4月生', value: pre + ',' + '4' });

    return arr;
  }

  handleChangeSel = (e, { name, value }) => {
    const { classroom } = this.state
    const fieldName = name;
    const fieldValue = value;
    if (fieldValue === 'clear') {
      this.setState({ doc: [] })
    } else {
      const lastClassArr = classroom.filter(item => item.semesterYear === fieldValue && item.semester === 'S');
      this.setState({ doc: lastClassArr })
    }
  }

  handleChange = (e, { name, value }) => {
    // console.log(e);
    const { classroom } = this.state;
    let semesterYear, semester = ''
    if (name === 'yearsemester') {
      semesterYear = value.substring(0, 4);
      semester = value.substring(5);
    }
    const classArr = classroom.filter(item => item.semesterYear === semesterYear && item.semester === semester);

    this.setState({ [name]: value, semesterYear, semester, classArr, doc: classArr })
  }

  handleChangeClass = (event, data) => {
    const { firebase, optSet, semesterYear, semester } = this.props;
    const { classroom } = this.state;
    const changeArr = data.id.split(",");
    const name = changeArr[0];
    const arr = [...this.state.doc];
    const obj = {
      ...arr.find(item => item.classSchool === changeArr[1]),
      semesterYear,
      // classTime,
      // classTimeText: classTime === '2' ? '午後' : '午前',
      semester,
      semesterText: semester === 'A' ? '秋' : '春',
      classSchool: changeArr[1],
    };

    if (name === 'check') { // 選クラス
      // console.log(data.checked);
      obj.isEnableClass = data.checked;
    } else if (name === 'teacher') { // 選老師
      // console.log(data.value);
      // console.log(data.options[data.selectedIndex].text);
      if (data.value === "0") {
        obj.teacher = "0";
        obj.teacherTitleText = "";
        obj.teacherText = "";
      } else {
        const strArr = data.options.find(option => option.value === data.value).text.split(" ");
        obj.teacher = data.value;
        if (strArr.length === 1) {
          obj.teacherTitleText = '';
          obj.teacherText = strArr[0];
        } else {
          obj.teacherTitleText = strArr[0];
          obj.teacherText = strArr[1];
        }
      }
    } else if (name === 'teacher2') { // 選老師
      // console.log(data.value);
      // console.log(data.options[data.selectedIndex].text);
      if (data.value === "0") {
        obj.teacher2 = "0";
        obj.teacherTitleText2 = "";
        obj.teacherText2 = "";
      } else {
        const strArr = data.options.find(option => option.value === data.value).text.split(" ");
        obj.teacher2 = data.value;
        if (strArr.length === 1) {
          obj.teacherTitleText2 = '';
          obj.teacherText2 = strArr[0];
        } else {
          obj.teacherTitleText2 = strArr[0];
          obj.teacherText2 = strArr[1];
        }
      }
    } else if (name === 'entry') {
      // console.log(data.name); // entry,A
      console.log(data.value); //2017,10

      const valueArr = data.value.split(",");
      obj.entryYear = valueArr[0];
      obj.entrySemester = valueArr[1] === '4' ? 'S' : 'A';
      obj.entryMonth = valueArr[1];
      obj.entryText = valueArr[1] + '月生';
      obj.entryValue = data.value;
      const yearSchoolArr = getOptionsByKey(optSet, 'semesterYear');
      const note1 = yearSchoolArr.find(item => item.value === obj.entryYear) ? yearSchoolArr.find(item => item.value === obj.entryYear).note1 : '1';
      obj.classTime = note1;
      obj.classTimeText = note1 === '2' ? '午後' : '午前';

      if (data.value === '0') {
        obj.entryYear = '';
        obj.entryMonth = '';
        obj.entryText = '';
        obj.entrySemester = '';
        obj.classTime = '';
        obj.classTimeText = '';
      }

    } else if (name === 'number') {
      obj.number = data.value;
    } else if (name === 'level') {
      obj.level = data.value;
    } else if (name === 'classroom') {
      obj.classroom = data.value;
    }

    const findIndex = arr.findIndex(item => item.classSchool === obj.classSchool);
    if (findIndex !== -1) {
      arr[findIndex] = obj
      // arr[findIndex].isEnableClass = obj.isEnableClass || arr[findIndex].isEnableClass || false;
      // arr[findIndex].number = obj.number || arr[findIndex].number || '';
      // arr[findIndex].level = obj.level || arr[findIndex].level || '';
      // arr[findIndex].classroom = obj.classroom || arr[findIndex].classroom || '';
      // arr[findIndex].entryValue = obj.entryValue || arr[findIndex].entryValue || '0';
      // arr[findIndex].teacher = obj.teacher || arr[findIndex].teacher || '0';
      // arr[findIndex].teacher2 = obj.teacher2 || arr[findIndex].teacher2 || '0';
    } else if (findIndex === -1) {
      arr.push(obj)
      // firebase.firestore().collection("Classroom").add(obj).then(
      //   console.log('add', obj)
      // );
    }
    this.setState({ doc: arr })

    // console.log(arr)
  }

  cancelEdit = () => {
    const { classArr } = this.state
    console.log(classArr)
    this.setState({ isEdit: false, doc: classArr });
  }

  handleSubmit = () => {
    // if (confirm(`確定要保存此配当表進資料庫嗎?`)) {
    alert("已保存");
    const { firebase } = this.props;
    const { doc } = this.state
    const { semesterYear, semester, classSchool } = doc;

    doc.map(classroom => {
      if (classroom.objectId)
        firebase.firestore().collection("Classroom").doc(classroom.objectId)
          // .where('semesterYear', '==', semesterYear)
          // .where('semester', '==', semester)
          // .where('classSchool', '==', classSchool)
          // .get().then(querySnapshot => {
          //   querySnapshot.forEach(documentSnapshot => {
          //     firebase.firestore().doc(documentSnapshot.ref.path)
          .set(classroom, { merge: true }).then(
            // console.log('success', classroom)
          );
      else {
        firebase.firestore().collection("Classroom").add(classroom).then(querySnapshot => {
          classroom.objectId = querySnapshot.id
          console.log('add', classroom)
        });
      }
    })
    this.setState({ isEdit: false, classArr: doc }, () => this.getPrintData());

    return;
  };

  getPrintData = () => {
    const { optSet } = this.props
    const { doc, teachers } = this.state
    let classArr = doc
    const classSchoolArr = getOptionsByKey(optSet, 'classSchool');
    const currentTeacher = teachers
    console.log(currentTeacher)
    // .filter(item => (!item.endSemesterNum || numYS <= item.endSemesterNum) && (!item.startSemesterNum || numYS >= item.startSemesterNum));
    const arrPdf = classSchoolArr.sort((a, b) => a.orderId - b.orderId).map(({ _id, value, label, number }, index) => ({
      checked: classArr.find(item => item.classSchool === label) ? "✓" : '',
      class: label,
      classSchool: `${classArr.find(item => item.classSchool === label) ? "✓" : ''} ${label}`,
      number: classArr.find(item => item.classSchool === label) ? classArr.find(item => item.classSchool === label).number : '',
      level: classArr.find(item => item.classSchool === label) ? classArr.find(item => item.classSchool === label).level : '',
      classroom: classArr.find(item => item.classSchool === label) ? classArr.find(item => item.classSchool === label).classroom : '',
      entry: classArr.find(item => item.classSchool === label) ? `${classArr.find(item => item.classSchool === label).entryYear}年${classArr.find(item => item.classSchool === label).entryText}` : '',
      teacher1: classArr.find(item => item.classSchool === label) ?
        `${currentTeacher.find(item =>
          item.objectId === (classArr.find(item1 => item1.classSchool === label).teacher)) ?
          currentTeacher.find(item => item.objectId === (classArr.find(item1 => item1.classSchool === label).teacher)).teacherTitleText : ''} ${currentTeacher.find(item =>
            item.objectId === (classArr.find(item1 => item1.classSchool === label).teacher)) ?
            currentTeacher.find(item => item.objectId === (classArr.find(item1 => item1.classSchool === label).teacher)).nameTeacher : ''}` : '',
      teacher2: classArr.find(item => item.classSchool === label) ?
        `${currentTeacher.find(item =>
          item.objectId === (classArr.find(item1 => item1.classSchool === label).teacher2)) ?
          currentTeacher.find(item => item.objectId === (classArr.find(item1 => item1.classSchool === label).teacher2)).teacherTitleText : ''} ${currentTeacher.find(item =>
            item.objectId === (classArr.find(item1 => item1.classSchool === label).teacher2)) ?
            currentTeacher.find(item => item.objectId === (classArr.find(item1 => item1.classSchool === label).teacher2)).nameTeacher : ''}` : '',
    }))
    this.setState({ arrPdf })
  }

  render() {
    const { formSet, optSet, history, match, semesterYear, semester, companyObj } = this.props;
    const { classroom, isEdit, doc, arrPdf, loading, teachers } = this.state;
    // console.log(this.props, this.state);
    let classArr = doc
    // const yearSchoolArr = getOptionsByKey(optSet, 'semesterYear');
    const classSchoolArr = getOptionsByKey(optSet, 'classSchool');
    const classTimeArr = getOptionsByKey(optSet, 'classTime');
    // const semestersArr = getOptionsByKey(optSet, 'semester');
    // console.log(classSchoolArr)

    const numYS = Number(semesterYear + (semester === 'S' ? '04' : '10'));
    const currentTeacher = teachers.filter(item => (!item.endSemesterNum || numYS <= item.endSemesterNum) && (!item.startSemesterNum || numYS >= item.startSemesterNum));
    let classOrder = []
    classSchoolArr.sort((a, b) => a.orderId - b.orderId).map((item, index) => {
      let cl = classArr.find(item2 => item2.classSchool === item.label) || { classSchool: item.label }
      classOrder.push(cl)
    })
    // console.log(doc)
    // options of current teacher
    let currentTeacherArr = [{ value: '0', text: '-- 選択 --' }]
    currentTeacher.sort((a, b) => a.teacherNum - b.teacherNum).map(({ objectId, nameTeacher, teacherTitleText, label }) => currentTeacherArr.push({ key: objectId, value: objectId, text: `${teacherTitleText} ${nameTeacher}` }))

    // options of current class semester
    let CurrentSemesterArr = [{ value: '0', text: '-- 選択 --' }]
    this.showCurrentSemester().map(({ id, entryYear, entryText, value }) => CurrentSemesterArr.push({ key: id, value: value, text: `${entryYear}年${entryText}` }))

    const options = [
      { key: 'sel', text: '-- 選択 --', value: ' ' },
      { key: 'clear', text: '-- クリア --', value: 'clear' },
      { key: 'lastSemes', text: `${semesterYear}S`, value: semesterYear },
    ]

    return (<div ref={el => (this.componentRef = el)} className="">
      <div>
        <div style={{ ...style.flexCenter, margin: '20px 0' }}>
          <Header as='h2' style={{ margin: 0 }}>{semesterYear}{semester} クラス編成</Header>
          <div style={{ ...style.flexCenter }}>
            {/* <Input labelPosition='left'>
              <Label color={'pink'}> <Icon name='calendar' />学期</Label>
              <Select compact options={semesterArr} onChange={this.handleChange} value={yearsemester} name='yearsemester' id='yearsemester' />
            </Input>
            &nbsp;&nbsp;&nbsp; */}
            {/* <Input labelPosition='left'>
              <Label color={'orange'}> <Icon name='clock' />授業時間</Label>
              <Select compact options={classTimeArr.sort((a, b) => a.orderId - b.orderId).map(({ _id, value, label }) => ({ key: _id, value: value, text: label }))}
                onChange={this.handleChangeClass} value={this.state.classTime} name='classTime' id='classTime' />
            </Input> */}
            <div style={{ float: 'right' }}>
              {isEdit ?
                <>
                  {semester === 'A' ?
                    <Input labelPosition='left'>
                      <Label color={'orange'}>情報参照</Label>
                      <Select name='referLastSemester' defaultValue=' ' options={options} onChange={this.handleChangeSel} />
                    </Input> : null}&nbsp;&nbsp;&nbsp;
                  <Button positive onClick={this.handleSubmit} icon='save' content='保存' />
                  <Button basic onClick={this.cancelEdit} icon='times' content='キャンセル' />
                  {/* <Button negative onClick={this.clearEdit} icon='times' content='クリア' /> */}
                </>
                : <>
                  <Button positive onClick={() => this.setState({ isEdit: true, doc: [...classArr] })} icon="edit" className="no-print" content="編集" />
                  <ModalPdf
                    {...this.props}
                    // ref={(ref) => { this.pdfModal = ref }}
                    codekey="streamingManagementPdf"
                    params={arrPdf}
                  />
                  <ExportTableToExcelButton floated='right' />
                </>}
            </div>
            {/* <div style={{ ...style.flexCenter, marginLeft: 10, minWidth: 220 }}>
              <ExportTableToExcelButton size='small' />
              <ModalPdf
                {...this.props}
                codekey="streamingManagementPdf"
                params={arrPdf}
              />
            </div> */}
          </div>
        </div>
        {/* <Form.Label>{semesterYear} 授業時間</Form.Label>{'  '}
              {this.state.showEditPeriod ?
                <>
                  <Form.Control as="select" placeholder="select" name="classTime"
                    onChange={this.handleChangeClass} value={this.state.classTime} >
                    {classTimeArr.sort((a, b) => a.orderId - b.orderId).map(({ _id, value, label }) => (<option key={_id} value={value}>{label}</option>))}
                  </Form.Control>
                  <span style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.setState({ showEditPeriod: false })}>閉じる</span>
                </> :
                <>
                  {yearSchoolArr.find(item => item.value === semesterYear)
                    && yearSchoolArr.find(item => item.value === semesterYear).note1 === '2' ? '午後' : '午前'}{' '}
                  <span style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.setState({ showEditPeriod: true })}>編集</span>
                </>} */}
        <hr />
        {loading ? <Segment>
          <Dimmer active inverted={companyObj.inverted}>
            <Loader inverted={companyObj.inverted}>Loading</Loader>
          </Dimmer>
          <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        </Segment> : <>
            {/* <Grid columns='equal'> */}
            <Row>
              <Col width={1}>
                クラス
            </Col>
              <Col width={1} >
                No.
            </Col>
              <Col width={1}>
                レベル
            </Col>
              <Col width={2}>
                教室
            </Col>
              <Col >
                期生別
            </Col>
              <Col >
                担任教師1
            </Col>
              <Col >
                担任教師2
            </Col>
            </Row>
            {classOrder.map((item, index) => (
              <Row key={'r' + index}>
                <Col width={1} verticalAlign={'middle'}>
                  <Form.Field
                    readOnly={!isEdit}
                    key={'checked' + index}
                    control={Checkbox}
                    checked={item.isEnableClass || false}
                    label={item.classSchool}
                    onChange={this.handleChangeClass}
                    id={'check,' + item.classSchool}>
                  </Form.Field>
                </Col>
                <Col width={1} >
                  <Form.Field
                    readOnly={!isEdit}
                    fluid
                    // width={2}
                    key={'number' + index}
                    control={Input}
                    onChange={this.handleChangeClass}
                    value={item.number || ''}
                    id={'number,' + item.classSchool} />
                </Col>
                <Col width={2}>
                  <Form.Field
                    readOnly={!isEdit}
                    fluid
                    key={'level,' + index}
                    control={Input}
                    onChange={this.handleChangeClass}
                    value={item.level || ''}
                    id={'level,' + item.classSchool} />
                </Col>
                <Col width={2}>
                  <Form.Field
                    readOnly={!isEdit}
                    fluid
                    key={'classroom,' + index}
                    control={Input}
                    onChange={this.handleChangeClass}
                    value={item.classroom || ''}
                    id={'classroom,' + item.classSchool} />
                </Col>
                <Col >
                  <Form.Group >
                    <Form.Field
                      disabled={!isEdit}
                      key={'entry,' + index}
                      control={Select}
                      onChange={this.handleChangeClass}
                      value={item.entryValue || '0'}
                      id={'entry,' + item.classSchool}
                      options={CurrentSemesterArr}
                    />
                  </Form.Group>
                </Col>
                <Col >
                  <Form.Group >
                    <Form.Field
                      disabled={!isEdit}
                      key={'teacher,' + index}
                      control={Select}
                      onChange={this.handleChangeClass}
                      value={item.teacher || '0'}
                      id={'teacher,' + item.classSchool}
                      options={currentTeacherArr}
                    />
                  </Form.Group>
                </Col>
                <Col >
                  <Form.Group >
                    <Form.Field
                      disabled={!isEdit}
                      key={'teacher2,' + index}
                      control={Select}
                      onChange={this.handleChangeClass}
                      value={item.teacher2 || ''}
                      id={'teacher2,' + item.classSchool}
                      options={currentTeacherArr}
                    />
                  </Form.Group>
                </Col>
              </Row>
            ))}
          </>
          // </Grid>
        }
        <Table celled id='excel' style={{ display: 'none' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>クラス</Table.HeaderCell>
              <Table.HeaderCell>No.</Table.HeaderCell>
              <Table.HeaderCell>レベル</Table.HeaderCell>
              <Table.HeaderCell>教室</Table.HeaderCell>
              <Table.HeaderCell>期生別</Table.HeaderCell>
              <Table.HeaderCell>担任教師1</Table.HeaderCell>
              <Table.HeaderCell>担任教師2</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {classSchoolArr.sort((a, b) => a.orderId - b.orderId).map(({ _id, value, label, number }, index) => (
              <Table.Row key={'rr' + _id}>
                <Table.Cell>
                  <Form.Field
                    key={'check2,' + _id}
                    control={Checkbox}
                    checked={classArr.find(item => item.classSchool === label) ? classArr.find(item => item.classSchool === label).isEnableClass : false}
                    label={label}
                    onChange={this.handleChangeClass}
                    id={'check,' + label}>
                  </Form.Field>
                </Table.Cell>
                <Table.Cell>
                  <Form.Field
                    fluid
                    // width={2}
                    key={'number2,' + _id}
                    control={Input}
                    onChange={this.handleChangeClass}
                    defaultValue={classArr.find(item => item.classSchool === label) ? classArr.find(item => item.classSchool === label).number : ''}
                    id={'number,' + label} />
                </Table.Cell>
                <Table.Cell>
                  <Form.Field
                    fluid
                    key={'level2,' + _id}
                    control={Input}
                    onChange={this.handleChangeClass}
                    defaultValue={classArr.find(item => item.classSchool === label) ? classArr.find(item => item.classSchool === label).level : ''}
                    id={'level,' + label} />
                </Table.Cell>
                <Table.Cell>
                  <Form.Field
                    fluid
                    key={'classroom2,' + _id}
                    control={Input}
                    onChange={this.handleChangeClass}
                    defaultValue={classArr.find(item => item.classSchool === label) ? classArr.find(item => item.classSchool === label).classroom : ''}
                    id={'classroom,' + label} />
                </Table.Cell>
                <Table.Cell>
                  <Form.Field
                    key={'entry2,' + _id}
                    control={Select}
                    onChange={this.handleChangeClass}
                    defaultValue={classArr.find(item => item.classSchool === label) ? classArr.find(item => item.classSchool === label).entryValue : '0'}
                    id={'entry,' + label}
                    options={CurrentSemesterArr}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Field
                    key={'teacher3,' + _id}
                    control={Select}
                    onChange={this.handleChangeClass}
                    defaultValue={classArr.find(item => item.classSchool === label) ? classArr.find(item => item.classSchool === label).teacher : '0'}
                    id={'teacher,' + label}
                    options={currentTeacherArr}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Field
                    key={'teacher4,' + _id}
                    control={Select}
                    onChange={this.handleChangeClass}
                    defaultValue={classArr.find(item => item.classSchool === label) ? classArr.find(item => item.classSchool === label).teacher2 : '0'}
                    id={'teacher2,' + label}
                    options={currentTeacherArr}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div >
    </div>)
  }
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     createProject: (project) => dispatch(createProject(project))
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HostIndex)
export default StreamingManagement