import jsPDF from '../pdf/JspdfMyFonts';

import {
  prayPage1, prayPage1a, prayPage18, prayPage18a, prayPage18b, prayPage2, prayPage3, prayPage4, prayPage5,
  familyPage, lightPage1, lightPage2, lightPage3, memberPage1, memberPage2, memberPage3, memberPage4, memberPage5,
  prayPage80, prayPage81, cloudwin501, cloudwin502, cloudwin503, cloudwin601, cloudwin602
} from './pdfTemple';
import QRCode from 'qrcode'

// const d = new Date();
// const nowDate = `${d.getFullYear() - 1911}年${d.getMonth() + 1}月${d.getDate()}日`;

const data = async (props) => {
  const { arrObj, companyObj, nowform, params, printDate, lightType, selectData } = props;
  const { praytypeObj = {} } = selectData
  const praytypeObjArr = praytypeObj.arr || []
  let doc = new jsPDF({ format: 'a4', unit: 'pt' });

  if (nowform === "11" || nowform === "12" || nowform === "13" || nowform === "14") {  // 牌位
    // let doc = new jsPDF({ format, unit: 'pt', orientation });      
    // console.log(nowform, arrObj.length);
    // let data = "";
    let noMorePage = 0;            // 讀每一個資料，並傳送到製作頁面的地方

    let arr_small_data1 = []; // 小牌冤親的資料
    let arr_small_data2 = []; // 小牌其他的資料

    // console.log(arrObj)
    for (let i = 0; i < arrObj.length; i++) {
      let entry = arrObj[i];
      const isPass = entry.passname || entry.passname1 || entry.passname2 || entry.passname3 || entry.passname4;

      if (!!entry.praytypeText && !!entry.serial) { //&& !!entry.pray2Text
        let left = "", right = "", center = "";
        let top1 = (entry.pray2Text || entry.praytypeText);
        let top2 = entry.serial + '';
        // console.log(entry);

        // 小牌開始
        if (!!entry.praytypeText && entry.praytypeText.indexOf("小牌") !== -1) { // 拔渡有人的時候 這個要分成每一個人一個牌位
          if (!!entry.passname2) { // 拔渡有人的時候 這個要分成每一個人一個牌位
            if (nowform === 14) continue; // 如果選了冤親，是其他的就跳掉
            let dataArr2 = entry.livename.split(",");
            center = entry.passname2;
            if (!!entry.addr) right = entry.addr;

            for (let j = 0; j < dataArr2.length; j++) { // 投每一個陽上去生牌位
              left = dataArr2[j];

              if (i === arrObj.length - 1 && j === dataArr2.length - 1) {
                noMorePage = 1;
              }
              let obj = { top1, top2, left, center, right, noMorePage, entry };
              arr_small_data1.push(obj);
            };
          } else {
            if (nowform === 13) { continue }; // 如果選了其他，是冤親的就跳掉
            if (!!entry.praytypeText && (entry.praytypeText.indexOf("拔") >= 0 || entry.praytypeText.indexOf("亡") >= 0)) {
              let passname = "";

              if (entry.passname) passname = entry.passname;
              else if (entry.passname1) passname = entry.passname1;
              // else if(entry.passname2) passname = entry.passname2;
              else if (entry.passname3) passname = entry.passname3;
              else if (entry.passname4) passname = entry.passname4;

              left = entry.livename;
              // center = entry.passname;
              center = passname;
            } else {
              left = "";
              center = entry.livename;
            }
            if (!!entry.addr) right = entry.addr;

            if (i === arrObj.length - 1) {
              noMorePage = 1;
            }
            let obj = { top1, top2, left, center, right, noMorePage, entry };
            arr_small_data2.push(obj);
          }

        } // 小牌結束
        /*   else if(!!entry.praytypeText && !!entry.passname2){ // 拔渡有人的時候 這個要分成每一個人一個牌位
             let dataArr2 = entry.livename.split(",");
             center = entry.passname2;
             if(!!entry.addr) right = entry.addr;
   
             for (let j = 0;  j < dataArr2.length ; j++) { // 投每一個陽上去生牌位
               left = dataArr2[j];
   
               if(i === arrObj.length -1 && j === dataArr2.length -1){
                 noMorePage = 1;
               }
               prayPage1(doc, top1, top2, left, center, right, noMorePage, entry); 
             };
           }*/
        else { // 一般的，一筆一個牌位

          // if (!!entry.praytypeText && (entry.praytypeText.indexOf("拔") >= 0 || entry.praytypeText.indexOf("亡") >= 0)) {
          if (isPass) {
            let passname = "";
            if (entry.passname) { passname = entry.passname }
            else if (entry.passname1) { passname = entry.passname1 }
            else if (entry.passname2) { passname = entry.passname2 }
            else if (entry.passname3) { passname = entry.passname3 }
            else if (entry.passname4) { passname = entry.passname4 }

            left = entry.livename;
            center = passname;
          } else {
            left = "";
            center = entry.livename;
          }
          if (!!entry.addr) right = entry.addr;

          if (i === arrObj.length - 1) {
            noMorePage = 1;
          }
          // console.log(top1, top2, left, center, right)
          prayPage1(doc, top1, top2, left, center, right, noMorePage, entry, companyObj);
        }
      }
    };

    // console.log("arr_small_data1: " + arr_small_data1.length);
    // console.log("arr_small_data2: " + arr_small_data2.length);
    // console.log(arr_small_data);
    if (arr_small_data1.length || arr_small_data2.length) {
      doc = new jsPDF({ format: 'a4', orientation: "l", unit: 'pt' });
      doc.setFont('BiauKai');

      arr_small_data1.forEach((item, i) => {
        if (i > 3 && i % 4 === 0) {//&& i < arr_small_data1.length)
          doc.addPage()
        }
        prayPage1a(doc, i % 4, item.top1, item.top2, item.left, item.center, item.right, 0, item);
      });

      if (arr_small_data2.length && arr_small_data1.length) {
        doc.addPage()
      }

      arr_small_data2.forEach((item, i) => {
        if (i > 3 && i % 4 === 0)// && i < arr_small_data2.length)
          doc.addPage()
        prayPage1a(doc, i % 4, item.top1, item.top2, item.left, item.center, item.right, 0, item);
      });
    }
  } else if (nowform === "18") { // 合併
    // let prt = PrayingType.findOne(queryVar.type);
    // console.log(arrObj)
    // console.log(nowform)
    // console.log(selectData);
    if (!arrObj || !arrObj.length || !arrObj[0].praytypeId) {
      window.alert('資料尚未讀取完，請再試一次')
      return;
    }
    let prt = praytypeObjArr.find(item => item.objectId === arrObj[0].praytypeId); // mic1202 PrayingType.find({ 'print_type': "3" }, { sort: { order: 1 } }).fetch();  // 要印的油燈牌
    // console.log(prt);

    if (prt.oilpray_layout === "1") { // 直的
      // doc = new PDFDocument({
      //   size: prt.oilpray_paper,
      //   margin: 1
      // });
      doc = new jsPDF({ format: prt.oilpray_paper, unit: 'pt' });;
    } else if (prt.oilpray_layout === "2") { // 橫的
      // doc = new PDFDocument({
      //   size: prt.oilpray_paper,
      //   margin: 1,
      //   layout: "landscape"
      // });
      doc = new jsPDF({ format: prt.oilpray_paper, orientation: "l", unit: 'pt' });;
    }

    let left = "";
    let right = "";
    let center = "";
    let noMorePage = 0;

    // 讀每一個資料，並傳送到製作頁面的地方
    let arr_big_data1 = []; // 大牌冤親的資料
    let arr_big_data2 = []; // 大牌其他的資料
    let arr_small_data1 = []; // 小牌冤親的資料
    let arr_small_data2 = []; // 小牌其他的資料

    let X_NUM = prt.oilpray_x;
    let Y_NUM = prt.oilpray_y;

    if (X_NUM === "1" && Y_NUM === "1") { // 一個一張
      for (let i = 0; i < arrObj.length; i++) {
        let entry = arrObj[i];
        const isPass = entry.passname || entry.passname1 || entry.passname2 || entry.passname3 || entry.passname4;

        left = right = center = "";
        // top1 = entry.prayitem_text;
        let top1 = entry.praytypeText;
        let top2 = entry.serial;

        // if (pt.nowform_table == "拔") {
        if (isPass) {
          let passname = "";

          if (entry.passname) { passname = entry.passname }
          else if (entry.passname1) { passname = entry.passname1 }
          else if (entry.passname2) { passname = entry.passname2 }
          else if (entry.passname3) { passname = entry.passname3 }
          else if (entry.passname4) { passname = entry.passname4 }

          left = entry.livename;
          // center = entry.passname;
          center = passname;
        } else { // 消
          left = "";
          center = entry.livename;
        }
        if (!!entry.addr) right = entry.addr;

        if (i === arrObj.length - 1) {
          noMorePage = 1;
        }
        // console.log(entry);
        // console.log(left);
        if (left !== "") {
          const obj = { top1, top2, left, center, right, noMorePage, entry }
          arr_big_data1.push(obj);
        } else {
          const obj = { top1, top2, left, center, right, noMorePage, entry }
          arr_big_data2.push(obj);
        }
      }

      // console.log(arr_big_data1);
      // console.log(arr_big_data2);
      for (let i = 0; i < arr_big_data1.length; i++) {
        let item = arr_big_data1[i];
        if (i > 0 && i % 1 === 0 && i < arr_big_data1.length) {
          doc.addPage();
        }
        // console.log(item.entry);
        prayPage18(doc, item.top1, item.top2, item.left, item.center, item.right, item.noMorePage, item.entry);
      }
      if (arr_big_data1.length > 0) {
        doc.addPage();
      }
      for (let i = 0; i < arr_big_data2.length; i++) {
        let item = arr_big_data2[i];
        if (i > 0 && i % 1 === 0 && i < arr_big_data2.length) {
          doc.addPage();
        }
        prayPage18(doc, item.top1, item.top2, item.left, item.center, item.right, item.noMorePage, item.entry);
      }
    } else if (X_NUM === "2" && Y_NUM === "1") { // 小牌開始
      for (let i = 0; i < arrObj.length; i++) {
        let entry = arrObj[i];
        const isPass = entry.passname || entry.passname1 || entry.passname2 || entry.passname3 || entry.passname4;

        left = right = center = "";
        let top1 = entry.praytypeText || "";
        let top2 = entry.serial || "";

        if (isPass) { // 拔 渡有人的時候 這個要分成每一個人一個牌位
          // if (nowform == 1) continue; // 如果選了冤親，是其他的就跳掉
          // let dataArr2 = entry.livename.split(",");
          center = entry.passname2;
          if (!!entry.addr) right = entry.addr;

          // for (let j = 0; j < dataArr2.length; j++) { // 投每一個陽上去生牌位
          left = entry.livename

          if (i === arrObj.length - 1) {
            noMorePage = 1;
          }
          const obj = { top1, top2, left, center, right, noMorePage, entry }
          arr_small_data1.push(obj);
          // };
        } else {
          // if (nowform == 13) continue; // 如果選了其他，是冤親的就跳掉
          if (entry.passname || entry.passname1 || entry.passname2 || entry.passname3 || entry.passname4) {
            let passname = "";

            if (entry.passname) passname = entry.passname;
            else if (entry.passname1) passname = entry.passname1;
            // else if(entry.passname2) passname = entry.passname2;
            else if (entry.passname3) passname = entry.passname3;
            else if (entry.passname4) passname = entry.passname4;

            left = entry.livename;
            center = passname;
          } else {
            left = "";
            center = entry.livename;
          }
          if (!!entry.addr) right = entry.addr;

          if (i === arrObj.length - 1) {
            noMorePage = 1;
          }
          const obj = { top1, top2, left, center, right, noMorePage, entry }
          arr_small_data2.push(obj);
        }
      }
      for (let i = 0; i < arr_small_data1.length; i++) {
        let item = arr_small_data1[i];
        if (i > 1 && i % 2 === 0 && i < arr_small_data1.length) {
          doc.addPage();
        }
        prayPage18b(doc, i % 2, item.top1, item.top2, item.left, item.center, item.right, 0, item, prt);
      }
      if (arr_small_data1.length > 0) {
        doc.addPage();
      }
      for (let i = 0; i < arr_small_data2.length; i++) {
        let item = arr_small_data2[i];
        if (i > 1 && i % 2 === 0 && i < arr_small_data2.length) {
          doc.addPage();
        }
        prayPage18b(doc, i % 2, item.top1, item.top2, item.left, item.center, item.right, 0, item, prt);
      }
    } else {
      for (let i = 0; i < arrObj.length; i++) {
        let entry = arrObj[i];
        const isPass = entry.passname || entry.passname1 || entry.passname2 || entry.passname3 || entry.passname4;

        left = right = center = "";
        let top1 = entry.praytypeText || "";
        let top2 = entry.serial || "";

        if (!!entry.passname || !!entry.passname1 || !!entry.passname2 || !!entry.passname3 || !!entry.passname4) { // 拔 渡有人的時候 這個要分成每一個人一個牌位
          // if (nowform == 1) continue; // 如果選了冤親，是其他的就跳掉
          // let dataArr2 = entry.livename.split(",");
          center = entry.passname2;
          if (!!entry.addr) right = entry.addr;

          // for (let j = 0; j < dataArr2.length; j++) { // 投每一個陽上去生牌位
          //     left = dataArr2[j];

          //     if (i == arrObj.length - 1 && j == dataArr2.length - 1) {
          //         noMorePage = 1;
          //     }
          //     let obj = {
          //         top1: top1,
          //         top2: top2,
          //         left: left,
          //         center: center,
          //         right: right,
          //         noMorePage: noMorePage,
          //         entry: entry,
          //     }
          //     arr_small_data1.push(obj);
          // };
          left = entry.livename;
          const obj = { top1, top2, left, center, right, noMorePage, entry }
          arr_small_data1.push(obj);
        } else {
          // if (nowform == 13) continue; // 如果選了其他，是冤親的就跳掉
          if (isPass) {
            let passname = "";

            if (entry.passname) { passname = entry.passname; }
            else if (entry.passname1) { passname = entry.passname1; }
            // else if(entry.passname2) passname = entry.passname2;
            else if (entry.passname3) { passname = entry.passname3; }
            else if (entry.passname4) { passname = entry.passname4; }

            left = entry.livename;
            center = passname;
          } else {
            left = "";
            center = entry.livename;
          }
          if (!!entry.addr) right = entry.addr;

          if (i === arrObj.length - 1) {
            noMorePage = 1;
          }
          const obj = { top1, top2, left, center, right, noMorePage, entry }
          // arr_small_data2.push(obj);
          if (obj.left !== "") {
            arr_small_data1.push(obj);
          } else {
            arr_small_data2.push(obj);
          }
        }
      };
      // console.log(left);
      // console.log("arr_small_data1: " + arr_small_data1.length);
      // console.log(arr_small_data1);
      // console.log("arr_small_data2: " + arr_small_data2.length);
      // console.log(arr_small_data2);

      for (let i = 0; i < arr_small_data1.length; i++) {
        let item = arr_small_data1[i];
        if (i > 3 && i % 4 === 0 && i < arr_small_data1.length) {
          doc.addPage();
        }
        prayPage18a(doc, i % 4, item.top1, item.top2, item.left, item.center, item.right, 0, item, prt);
      }

      if (arr_small_data1.length > 0) {
        doc.addPage();
      }
      for (let i = 0; i < arr_small_data2.length; i++) {
        let item = arr_small_data2[i];
        if (i > 3 && i % 4 === 0 && i < arr_small_data2.length) {
          doc.addPage();
        }
        prayPage18a(doc, i % 4, item.top1, item.top2, item.left, item.center, item.right, 0, item, prt);
      }
    }
  } else if (nowform === "2") { // 油燈牌
    let isAddPage = 0;
    let nowPage = 0;
    let tempArr = praytypeObjArr.filter(item => item.printTypeId === "3"); // mic1202 PrayingType.find({ 'print_type': "3" }, { sort: { order: 1 } }).fetch();  // 要印的油燈牌
    for (let i = 0; i < tempArr.length; i++) { // 每一種要印的油燈牌
      const printType = tempArr[i];
      const arrObj2 = arrObj.filter(item => item.praytypeId === printType.objectId); // 尋找目前有登記此類的項目

      if (arrObj2.length > 0) {
        if (isAddPage) { // 每一項 都有自已的頁面，這邊第二項時 才會進來
          if (printType.oilpray_layout === "1") { // 直的
            doc.addPage({ format: printType.oilpray_paper, orientation: 'p', unit: 'pt' });
          } else {
            doc.addPage({ format: printType.oilpray_paper, orientation: "l", unit: 'pt' });
          }
          isAddPage--;
          nowPage++;
        }
        if (nowPage === 0) {
          if (printType.oilpray_layout === "1") { // 直的
            doc = new jsPDF({ format: printType.oilpray_paper, orientation: 'p', unit: 'pt' });
          } else {
            doc = new jsPDF({ format: printType.oilpray_paper, orientation: "l", unit: 'pt' });
          }
          nowPage++;
        }

        prayPage2(doc, printType, arrObj2);
        isAddPage++;
      }
    }
  } else if (nowform === "3") { // 疏文
    doc = new jsPDF({ format: 'a4', orientation: "l", unit: 'pt' });
    prayPage3(doc, arrObj, printDate, praytypeObjArr, companyObj);
  } else if (nowform === "4") { // 功德名條
    doc = new jsPDF({ format: 'a4', orientation: "l", unit: 'pt' });
    prayPage4(doc, arrObj);
  } else if (nowform === "5") { // 普施圓桌
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    prayPage5(doc, arrObj);
  } else if (nowform === "20") { // 家庭資料
    doc = new jsPDF({ format: 'a4', unit: 'pt', orientation: "l" });
    familyPage(doc, params);
  } else if (nowform === '51') { // 
    doc = new jsPDF({ format: 'a4', unit: 'pt', });
    lightPage1(doc, arrObj, printDate, lightType);//, type); 
  } else if (nowform === '53') { // 
    doc = new jsPDF({ format: 'a4', unit: 'pt', orientation: "l" });
    lightPage2(doc, arrObj, printDate, lightType, companyObj);//, printDate, type); 
  } else if (nowform === '55') { // 
    doc = new jsPDF({ format: 'a4', unit: 'pt', orientation: "l" });
    lightPage3(doc, arrObj, printDate, lightType, companyObj);//, printDate, type); 
  } else if (nowform === '71') { // 簽到名冊
    doc = new jsPDF({ format: 'a4', unit: 'pt', orientation: "l" });
    memberPage1(doc, arrObj, printDate, companyObj);
  } else if (nowform === '72') {
    const data = {
      strTitle1a: "財團",
      strTitle1b: "法人",
      strTitle2: "桃園佛教蓮社",
      strTitle3: "社員(信徒)名冊",
      strDate: "中華民國 ", //+printDate.yyy+"年 "+printDate.mm+"月 "+printDate.dd+"日";
      strPhone1: "電話:",
      strPhone2: "(03) 339-7021",
    };
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    memberPage2(doc, data, printDate);
  } else if (nowform === '73') { // 社員名冊
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    memberPage3(doc, arrObj, printDate, companyObj); // inputVar
  } else if (nowform === '74' || nowform === '75' || nowform === '76') { //郵寄標籤
    const len = arrObj.length;
    const size = 7000;
    let mypage = 0;
    let start = 0, end = size;
    // console.log("len: " + len);

    let row = 0;
    let isPaddingMid = 0;
    if (nowform === '74') row = 7;
    else if (nowform === '75') row = 10;
    else if (nowform === '76') { // 中空
      row = 7;
      isPaddingMid = 1;
    }

    let isBound = 0; // MIC 1050902 強制拿掉這個

    if (len <= size) { // 一般情況
      doc = new jsPDF({ format: 'a4', unit: 'pt' });
      memberPage4(doc, arrObj, row, isBound, isPaddingMid);
    } else {// 如果是要分開pdf的話
      let arr_obj = [];
      let mypage = 0;

      // 準備列印陣列
      let i = 0;
      while (end <= len + size) {
        const e = (end >= len) ? len : end; // end超過長度的話 就用len的
        // console.log(i+ " start: " + start + " end: " + e + " len: " + len);
        //arr_obj.push(arrObj.slice(start, e));

        if (mypage === i) {
          arr_obj = arrObj.slice(start, e);
          break;
        }

        start = start + size + 1;
        end += size;
        i++;
      };
      doc = new jsPDF({ format: 'a4', unit: 'pt' });
      memberPage4(doc, arr_obj, row, isBound, isPaddingMid);
    }
  } else if (nowform === '76') { //信封標籤
    const len = arrObj.length;
    const size = 700;
    let start = 0, end = size;
    let mypage = 0;

    if (len <= size) { // 一般情況
      doc = new jsPDF({ format: 'a4', unit: 'pt' });
      memberPage5(doc, arrObj);
    } else { // 如果是要分開pdf的話
      let arr_obj = [];

      // 準備列印陣列
      let i = 0;
      while (end <= len + size) {
        const e = (end >= len) ? len : end; // end超過長度的話 就用len的
        // console.log(i+ " start: " + start + " end: " + e + " len: " + len);
        // arr_obj.push(arrObj.slice(start, e));

        if (mypage === i) {
          arr_obj = arrObj.slice(start, e);
          break;
        }
        start = start + size + 1;
        end += size;
        i++;
      };
      doc = new jsPDF({ format: 'a4', unit: 'pt' });
      memberPage5(doc, arr_obj);
    }
  } else if (nowform === "80") { // 繳款單
    const { praying1Obj = {} } = params
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    let opts = {
      margin: 1,
    }
    const qrcode = await QRCode.toCanvas(`https://tybl.infowin.com.tw/admin/Family/${praying1Obj.familyId}/p1/${praying1Obj.objectId}`, opts) || ''
    await prayPage80(doc, { ...params, qrcode });
  } else if (nowform === "81") { // 捐款收據
    const { praying1Obj = {} } = params
    doc = new jsPDF({ format: 'a4', unit: 'pt' });
    let opts = {
      margin: 1,
    }
    const qrcode = await QRCode.toCanvas(`https://tybl.infowin.com.tw/admin/Family/${praying1Obj.familyId}/p1/${praying1Obj.objectId}`, opts) || ''
    await prayPage81(doc, { ...params, qrcode });
  } else if (nowform === "501") { // 繳款單
    doc = new jsPDF({ format: 'a4', orientation: "p", unit: 'pt' });
    doc.setFont('BiauKai');
    cloudwin501(doc, printDate);
  } else if (nowform === "502") {
    doc = new jsPDF({ format: 'a4', orientation: "p", unit: 'pt' });
    doc.setFont('BiauKai');
    cloudwin502(doc, printDate);
  } else if (nowform === "503") {
    doc = new jsPDF({ format: 'a4', orientation: "p", unit: 'pt' });
    doc.setFont('BiauKai');
    cloudwin503(doc, printDate);
  } else if (nowform === "601") {
    doc = new jsPDF({ format: 'a4', orientation: "p", unit: 'pt' });
    doc.setFont('BiauKai');
    cloudwin601(doc, printDate);
  } else if (nowform === "602") {
    doc = new jsPDF({ format: 'a4', orientation: "p", unit: 'pt' });
    doc.setFont('BiauKai');
    cloudwin602(doc, printDate);
  }
  return doc
}
export default data;