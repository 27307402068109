import AuthService from "../../services/AuthService";
import { setCurrentUser } from "./actionCreators";

/**
 * Get user profile based on token
 */
export function getProfile() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const token = AuthService.getToken();
      // console.log('getProfile token', token)
      if (token) {
        AuthService.getProfile().then(resp => {
          // console.log(resp)
          if (resp.id) {
            dispatch(setCurrentUser(resp));
          }
          resolve();
        });
      } else {
        resolve();
      }
    });
  }
}

