import React, { Component } from 'react'
import { Header } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Moment from 'moment';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateTimeCol, exportPdf } from 'views/web/CustomMaterialTable';

const mailObj = {
  from: "notice@hanburyifa.com",
  to: '',
  cc: [],
  bcc: [],
  subject: '',
  html: '',
  Portfolio: '',
}
// const objDay_off_status_second = { "1": "填寫中", "2": "請代理人覆核" };
const objDay_off_status_third = { "2": "代理人覆核中", "3": "送交主管簽核", "1": "退回給申請人", "0": "取消本申請" };
const objDay_off_status_fourth = { "3": "主管簽核中", "6": "送交總經理簽核", "1": "退回給申請人", "0": "取消本申請" };
const objDay_off_status_fiveth = { "4": "人資簽核中", "5": "提交已生效", "1": "退回給申請人", "0": "取消本申請" };
const objDay_off_status_sixth = { "3": "總經理簽核中", "4": "送交人資簽核", "1": "退回給申請人", "0": "取消本申請" };

const columns1 = (auth_substitutegoSel, objDay_offSel) => ([
  { title: '狀態', field: 'dayoff_status', lookup: objDay_off_status_third },
  { title: '審核回覆', field: 'ps', },
  { title: '申請人姓名', field: 'employeeText', editable: 'never', },
  { title: '請假類別', field: 'day_off_class', lookup: objDay_offSel, editable: 'never' },
  // { title: '開始時間', field: 'eventStartAt', type: 'datetime', render: rowData => Moment(rowData.eventStartAt).format('YYYY-MM-DD HH:mm:ss'), editable: 'never' },
  // { title: '結束時間', field: 'eventEndAt', type: 'datetime', render: rowData => Moment(rowData.eventEndAt).format('YYYY-MM-DD HH:mm:ss'), editable: 'never' },
  dateTimeCol({ title: '開始時間', field: 'eventStartAt', width: 90, editable: 'never' }),
  dateTimeCol({ title: '結束時間', field: 'eventEndAt', width: 90, editable: 'never' }),
  { title: '總時數', field: 'total_hours', editable: 'never' },
  { title: '事由', field: 'reason', editable: 'never' },
  { title: '職務代理人', field: 'substitute', lookup: auth_substitutegoSel, editable: 'never' },
]);
const columns2 = (auth_substitutegoSel, objDay_offSel) => ([
  // { title: '類別 ', field: 'type_id', lookup: { '1': 'Pru Life', '2': 'VOYA', '3': 'Colonial' } },
  { title: '狀態', field: 'dayoff_status', lookup: objDay_off_status_fourth },
  { title: '審核回覆', field: 'ps' },
  { title: '申請人姓名', field: 'employeeText', editable: 'never' },
  { title: '請假類別', field: 'day_off_class', lookup: objDay_offSel, editable: 'never' },
  // { title: '開始時間', field: 'eventStartAt', type: 'datetime', render: rowData => Moment(rowData.eventStartAt).format('YYYY-MM-DD HH:mm:ss'), editable: 'never' },
  // { title: '結束時間', field: 'eventEndAt', type: 'datetime', render: rowData => Moment(rowData.eventEndAt).format('YYYY-MM-DD HH:mm:ss'), editable: 'never' },
  dateTimeCol({ title: '開始時間', field: 'eventStartAt', width: 90, editable: 'never' }),
  dateTimeCol({ title: '結束時間', field: 'eventEndAt', width: 90, editable: 'never' }),
  { title: '總時數', field: 'total_hours', editable: 'never' },
  { title: '事由', field: 'reason', editable: 'never' },
  { title: '職務代理人', field: 'substitute', lookup: auth_substitutegoSel, editable: 'never' },
]);
const columns3 = (auth_substitutegoSel, objDay_offSel) => ([
  // { title: '類別 ', field: 'type_id', lookup: { '1': 'Pru Life', '2': 'VOYA', '3': 'Colonial' } },
  { title: '狀態', field: 'dayoff_status', lookup: objDay_off_status_sixth },
  { title: '審核回覆', field: 'ps' },
  { title: '申請人姓名', field: 'employeeText', editable: 'never', },
  { title: '請假類別', field: 'day_off_class', lookup: objDay_offSel, editable: 'never' },
  // { title: '開始時間', field: 'eventStartAt', type: 'datetime', render: rowData => Moment(rowData.eventStartAt).format('YYYY-MM-DD HH:mm:ss'), editable: 'never' },
  // { title: '結束時間', field: 'eventEndAt', type: 'datetime', render: rowData => Moment(rowData.eventEndAt).format('YYYY-MM-DD HH:mm:ss'), editable: 'never' },
  dateTimeCol({ title: '開始時間', field: 'eventStartAt', width: 90, editable: 'never' }),
  dateTimeCol({ title: '結束時間', field: 'eventEndAt', width: 90, editable: 'never' }),
  { title: '總時數', field: 'total_hours', editable: 'never' },
  { title: '事由', field: 'reason', editable: 'never' },
  { title: '職務代理人', field: 'substitute', lookup: auth_substitutegoSel, editable: 'never' },
]);
const columns4 = (auth_substitutegoSel, objDay_offSel) => ([
  // { title: '類別 ', field: 'type_id', lookup: { '1': 'Pru Life', '2': 'VOYA', '3': 'Colonial' } },
  { title: '狀態', field: 'dayoff_status', lookup: objDay_off_status_fiveth },
  { title: '審核回覆', field: 'ps' },
  { title: '申請人姓名', field: 'employeeText', editable: 'never', },
  { title: '請假類別', field: 'day_off_class', lookup: objDay_offSel, editable: 'never' },
  // { title: '開始時間', field: 'eventStartAt', type: 'datetime', render: rowData => Moment(rowData.eventStartAt).format('YYYY-MM-DD HH:mm:ss'), editable: 'never' },
  // { title: '結束時間', field: 'eventEndAt', type: 'datetime', render: rowData => Moment(rowData.eventEndAt).format('YYYY-MM-DD HH:mm:ss'), editable: 'never' },
  dateTimeCol({ title: '開始時間', field: 'eventStartAt', width: 90, editable: 'never' }),
  dateTimeCol({ title: '結束時間', field: 'eventEndAt', width: 90, editable: 'never' }),
  { title: '總時數', field: 'total_hours', editable: 'never' },
  { title: '事由', field: 'reason', editable: 'never' },
  { title: '職務代理人', field: 'substitute', lookup: auth_substitutegoSel, editable: 'never' },
]);

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export default class Hr4b extends Component {
  state = {
    dayoffArr: [],
    dataArr2: [],
    dataArr3: [],
    dataArr4: [],
    dataArr6: [],
    loading: true,
  }

  componentDidMount() {
    this.handleFetch();
  }
  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { employeeObj, employee, companyObj } = props;
    const companyId = companyObj.objectId;
    // const db = firebase.firestore();
    // const email = firebase.auth().currentUser.email;

    // const snap = await db.collectionGroup(`employee`).get();
    // const employee = [];
    // snap.forEach(item => employee.push({ _id: item.objectId, ...item.data() }));
    // const employeeObj = employee.find(item => item.email === email) || {};
    if (employeeObj !== {} && employee.length > 0) {
      // const snap2 = await db.collection(`users/${employee._id}/dayoff`).get();
      // const dayoffArr = [];
      // snap2.forEach(item => dayoffArr.push({ _id: item.objectId, ...item.data() }));
      // const snap3 = await db.collection(`users/${employee._id}/comptime`).get();
      // // const dayoffArr = [];
      // snap3.forEach(item => dayoffArr.push({ _id: item.objectId, ...item.data() }));
      // const data2 = dayoffArr.filter(item => item.dayoff_status === '2');
      // const data3 = dayoffArr.filter(item => item.dayoff_status === '3');
      // const data4 = dayoffArr.filter(item => item.dayoff_status === '4');
      // const data6 = dayoffArr.filter(item => item.dayoff_status === '6');

      // const data1 = dayoffArr.filter(item => item.dayoff_status !== '2' && item.dayoff_status !== '3' && item.dayoff_status !== '4' && item.dayoff_status !== '5');
      // const data5 = dayoffArr.filter(item => item.dayoff_status === '5');
      // this.setState({ dataArr2: data2, dataArr3: data3, dataArr4: data4, dataArr6: data6, dayoffArr })
      // this.setState({ dayoffArr })

      const auth_substitutegoArr = employee.filter(item => item.auth_substitutego === '1' && item.is_auth && item.is_auth.value === '1'); // 代理人權限
      // const auth_supervisorgoArr = employee.filter(item => item.auth_supervisorgo === '1' && item.is_auth === '1'); // 放行主管權限
      // const auth_generalmanagergoArr = employee.filter(item => item.auth_generalmanagergo === '1' && item.is_auth === '1'); // 放行總經理權限
      // const auth_hrgoArr = employee.filter(item => item.auth_substitutego === '1' && item.is_auth === '1'); // 放行人資權限

      let auth_substitutegoSel = { 'none': '無' };
      auth_substitutegoArr.forEach(item => {
        if (item.objectId === employeeObj.objectId) {
          return;
        } else {
          auth_substitutegoSel = { ...auth_substitutegoSel, [item.objectId]: item.name }
        }
      });

      this.setState({ auth_substitutegoSel })
      if (employeeObj.auth_substitutego === '1') {
        const data = await Parse.queryData('dayoff', { companyId, dayoff_status: '2', substitute: employeeObj.objectId });
        // await db.collectionGroup('dayoff').where('companyId', '==', companyObj.objectId)
        // .where('gradeId', '==', gradeId)
        // .where('dayoff_status', '==', '2').where('substitute', '==', employeeObj.id).get().then(snap => {
        // snap.forEach(doc => data.push(doc.data()));
        // }, err => {
        // console.log(`Encountered error: ${err}`);
        // });
        this.setState({ dataArr2: data });
      }
      if (employeeObj.auth_supervisorgo === '1') {
        const data = await Parse.queryData('dayoff', { companyId, dayoff_status: '3', supervisor: employeeObj.objectId });
        // await db.collectionGroup('dayoff').where('companyId', '==', companyObj.objectId)
        //   // .where('gradeId', '==', gradeId)
        //   .where('dayoff_status', '==', '3').where('supervisor', '==', employeeObj.id).get().then(snap => {
        //     snap.forEach(doc => data.push(doc.data()));
        //   }, err => {
        //     console.log(`Encountered error: ${err}`);
        //   });
        const data2 = await Parse.queryData('comptime', { companyId, dayoff_status: '3', supervisor: employeeObj.objectId });
        // await db.collectionGroup('comptime').where('companyId', '==', companyObj.objectId)
        // .where('gradeId', '==', gradeId)
        // .where('dayoff_status', '==', '3').where('supervisor', '==', employeeObj.id).get().then(snap => {
        //   snap.forEach(doc => data.push(doc.data()));
        // }, err => {
        //   console.log(`Encountered error: ${err}`);
        // });
        const dataArr3 = data.concat(data2)
        this.setState({ dataArr3 });
      }
      if (employeeObj.auth_hrgo === '1') {
        const data = await Parse.queryData('dayoff', { companyId, dayoff_status: '4', hr: employeeObj.objectId });
        // await db.collectionGroup('dayoff').where('companyId', '==', companyObj.objectId)
        // .where('gradeId', '==', gradeId)
        // .where('dayoff_status', '==', '4').where('hr', '==', employeeObj.id).get().then(snap => {
        // snap.forEach(doc => data.push(doc.data()));
        // }, err => {
        // console.log(`Encountered error: ${err}`);
        // });
        const data2 = await Parse.queryData('comptime', { companyId, dayoff_status: '4', hr: employeeObj.objectId });
        // await db.collectionGroup('comptime').where('companyId', '==', companyObj.objectId)
        //   // .where('gradeId', '==', gradeId)
        //   .where('dayoff_status', '==', '4').where('hr', '==', employeeObj.id).get().then(snap => {
        //     snap.forEach(doc => data.push(doc.data()));
        //   }, err => {
        //     console.log(`Encountered error: ${err}`);
        //   });
        const dataArr4 = data.concat(data2)
        this.setState({ dataArr4 });
      }
      if (employeeObj.auth_generalmanagergo === '1') {
        const dayoff = await Parse.queryData('dayoff', { companyId, dayoff_status: '6', generalmanager: employeeObj.objectId });
        const data = dayoff.map(item => ({ ...item, dayoff_status: '3' }))
        // await db.collectionGroup('dayoff').where('companyId', '==', companyObj.objectId)
        //   // .where('gradeId', '==', gradeId)
        //   .where('dayoff_status', '==', '6').where('generalmanager', '==', employeeObj.id).get().then(snap => {
        //     snap.forEach(doc => data.push(doc.data()));
        //   }, err => {
        //     console.log(`Encountered error: ${err}`);
        //   });
        const comptime = await Parse.queryData('comptime', { companyId, dayoff_status: '6', generalmanager: employeeObj.objectId });
        const data2 = comptime.map(item => ({ ...item, dayoff_status: '3' }))
        // await db.collectionGroup('comptime').where('companyId', '==', companyObj.objectId)
        //   // .where('gradeId', '==', gradeId)
        //   .where('dayoff_status', '==', '6').where('generalmanager', '==', employeeObj.id).get().then(snap => {
        //     snap.forEach(doc => data.push(doc.data()));
        //   }, err => {
        //     console.log(`Encountered error: ${err}`);
        //   });
        const dataArr6 = data.concat(data2)
        this.setState({ dataArr6 });
      }

      this.setState({ loading: false })

    }

  }

  toEmailObj = async (newData, flag, who = '') => {
    const { employeeObj, objDay_off_status_first, employee, companyObj } = this.props
    let employeeName = employee.find(item => item.objectId === newData.employeeId).name
    let emailObj = {}
    const db = Parse.Object.extend("email");
    const newDoc = new db();
    const docRef = await newDoc.save();
    if (flag === 1) {
      switch (newData.dayoff_status) {
        case '3':
          emailObj = {
            ...mailObj,
            id: docRef.id,
            objectId: docRef.id,
            to: employee.find(item => item.objectId === newData.supervisor).email,
            _id: docRef.id,
            companyId: companyObj.objectId || '',
            companyName: companyObj.name || '',
            subject: '[請假審核] 主管審核',
            html: `<img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/rY66GsLbHnTm9Tvrx-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Header.png" /><br /><br /><br />申請人：${employeeName}<br /><br />申請假別 ${newData.day_off_classText}，<br />於${Moment(newData.eventStartAt).format('YYYY-MM-DD HH:mm:ss')}~${Moment(newData.eventEndAt).format('YYYY-MM-DD HH:mm:ss')}，共${newData.total_hours1}天${newData.total_hours2}時。<br /><br />請主管至系統簽核查收，謝謝您<br />https://system.hanburyifa.com/auth/login
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/yKNhmTQoRepiYtRSj-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Footer.png" />`,
            To_name_cht: employee.find(item => item.objectId === newData.supervisor).name || employee.find(item => item.objectId === newData.supervisor).email,
            status: "99",
            status_text: "未寄出",
            type: 'dayoff',
          }
          return emailObj
        // // break;
        case '6':
          return emailObj = {
            ...mailObj,
            id: docRef.id,
            objectId: docRef.id,
            to: employee.find(item => item.objectId === newData.generalmanager).email,
            _id: docRef.id,
            companyId: companyObj.objectId || '',
            companyName: companyObj.name || '',
            subject: '[請假審核] 總經理審核',
            html: `<img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/rY66GsLbHnTm9Tvrx-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Header.png" /><br /><br /><br />申請人：${employeeName}<br /><br />申請假別 ${newData.day_off_classText}，<br />於${Moment(newData.eventStartAt).format('YYYY-MM-DD HH:mm:ss')}~${Moment(newData.eventEndAt).format('YYYY-MM-DD HH:mm:ss')}，共${newData.total_hours1}天${newData.total_hours2}時。<br /><br />請總經理至系統簽核查收，謝謝您<br />https://system.hanburyifa.com/auth/login
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/yKNhmTQoRepiYtRSj-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Footer.png" />`,
            To_name_cht: employee.find(item => item.objectId === newData.generalmanager).name || employee.find(item => item.objectId === newData.generalmanager).email,
            status: "99",
            status_text: "未寄出",
            type: 'dayoff',
          }
        // // break;
        case '4':
          return emailObj = {
            ...mailObj,
            id: docRef.id,
            objectId: docRef.id,
            to: employee.find(item => item.objectId === newData.hr).email,
            _id: docRef.id,
            companyId: companyObj.objectId || '',
            companyName: companyObj.name || '',
            subject: '[請假審核] 人資審核',
            html: `<img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/rY66GsLbHnTm9Tvrx-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Header.png" /><br /><br /><br />申請人：${employeeName}<br /><br />申請假別 ${newData.day_off_classText}，<br />於${Moment(newData.eventStartAt).format('YYYY-MM-DD HH:mm:ss')}~${Moment(newData.eventEndAt).format('YYYY-MM-DD HH:mm:ss')}，共${newData.total_hours1}天${newData.total_hours2}時。<br /><br />請人資主管至系統簽核查收，謝謝您<br />https://system.hanburyifa.com/auth/login
          <br /><br /><br /><br /><br /><br /><br /><br /><br /><img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/yKNhmTQoRepiYtRSj-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Footer.png" />`,
            To_name_cht: employee.find(item => item.objectId === newData.hr).name || employee.find(item => item.objectId === newData.hr).email,
            status: "99",
            status_text: "未寄出",
            type: 'dayoff',
          }
        // break;
        case '5':
          return emailObj = {
            ...mailObj,
            id: docRef.id,
            objectId: docRef.id,
            to: newData.employeeEmail,
            _id: docRef.id,
            companyId: companyObj.objectId || '',
            companyName: companyObj.name || '',
            subject: '[請假成功] 申請通過',
            html: `<img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/rY66GsLbHnTm9Tvrx-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Header.png" /><br /><br /><br />申請人：${employeeName}<br /><br />申請假別 ${newData.day_off_classText}，<br />於${Moment(newData.eventStartAt).format('YYYY-MM-DD HH:mm:ss')}~${Moment(newData.eventEndAt).format('YYYY-MM-DD HH:mm:ss')}，共${newData.total_hours1}天${newData.total_hours2}時。<br /><br />已經由主管及人資主管簽核批准，謝謝您。
          <br /><br /><br /><br /><br /><br /><br /><br /><br /><img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/yKNhmTQoRepiYtRSj-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Footer.png" />`,
            To_name_cht: employee.find(item => item.objectId === newData.employeeId).name || employee.find(item => item.objectId === newData.employeeId).email,
            status: "99",
            status_text: "未寄出",
            type: 'dayoff',
          }
        // break;
        case '1':
          return emailObj = {
            ...mailObj,
            id: docRef.id,
            objectId: docRef.id,
            to: newData.employeeEmail,
            _id: docRef.id,
            companyId: companyObj.objectId || '',
            companyName: companyObj.name || '',
            subject: '[請假失敗] 申請退回',
            html: `<img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/rY66GsLbHnTm9Tvrx-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Header.png" /><br /><br /><br />申請人：${employeeName}<br /><br />申請假別 ${newData.day_off_classText}，<br />於${Moment(newData.eventStartAt).format('YYYY-MM-DD HH:mm:ss')}~${Moment(newData.eventEndAt).format('YYYY-MM-DD HH:mm:ss')}，共${newData.total_hours1}天${newData.total_hours2}時。<br /><br />已經由${who}退回，回覆如下：<br />${newData.ps ? newData.ps : ''}
          <br /><br /><br /><br /><br /><br /><br /><br /><br /><img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/yKNhmTQoRepiYtRSj-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Footer.png" />`,
            To_name_cht: employee.find(item => item.objectId === newData.employeeId).name || employee.find(item => item.objectId === newData.employeeId).email,
            status: "99",
            status_text: "未寄出",
            type: 'dayoff',
          }
        // break;
        case '0':
          return emailObj = {
            ...mailObj,
            id: docRef.id,
            objectId: docRef.id,
            to: newData.employeeEmail,
            _id: docRef.id,
            companyId: companyObj.objectId || '',
            companyName: companyObj.name || '',
            subject: '[請假失敗] 申請拒絕',
            html: `<img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/rY66GsLbHnTm9Tvrx-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Header.png" /><br /><br /><br />申請人：${employeeName}<br /><br />申請假別 ${newData.day_off_classText}，<br />於${Moment(newData.eventStartAt).format('YYYY-MM-DD HH:mm:ss')}~${Moment(newData.eventEndAt).format('YYYY-MM-DD HH:mm:ss')}，共${newData.total_hours1}天${newData.total_hours2}時。<br /><br />已經由${who}拒絕，回覆如下：<br />${newData.ps ? newData.ps : ''}
          <br /><br /><br /><br /><br /><br /><br /><br /><br /><img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/yKNhmTQoRepiYtRSj-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Footer.png" />`,
            To_name_cht: employee.find(item => item.objectId === newData.employeeId).name || employee.find(item => item.objectId === newData.employeeId).email,
            status: "99",
            status_text: "未寄出",
            type: 'dayoff',
          }
        // break;
        default:
      }
    } else if (flag === 2) {
      switch (newData.dayoff_status) {
        case '3':
          return emailObj = {
            ...mailObj,
            id: docRef.id,
            objectId: docRef.id,
            to: employee.find(item => item.objectId === newData.supervisor).email,
            _id: docRef.id,
            companyId: companyObj.objectId || '',
            companyName: companyObj.name || '',
            subject: '[補休/加班 審核] 主管審核',
            html: `<img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/rY66GsLbHnTm9Tvrx-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Header.png" /><br /><br /><br />申請人：${employeeName}<br /><br />申請加班/補休 ${newData.day_off_classText}，<br />於${Moment(newData.eventStartAt).format('YYYY-MM-DD HH:mm:ss')}~${Moment(newData.eventEndAt).format('YYYY-MM-DD HH:mm:ss')}，共${newData.total_hours1}天${newData.total_hours2}時。<br /><br />請主管至系統簽核查收，謝謝您<br />https://system.hanburyifa.com/auth/login
          <br /><br /><br /><br /><br /><br /><br /><br /><br /><img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/yKNhmTQoRepiYtRSj-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Footer.png" />`,
            To_name_cht: employee.find(item => item.objectId === newData.supervisor).name || employee.find(item => item.objectId === newData.supervisor).email,
            status: "99",
            status_text: "未寄出",
            type: 'dayoff',
          }
        case '6':
          return emailObj = {
            ...mailObj,
            id: docRef.id,
            objectId: docRef.id,
            to: employee.find(item => item.objectId === newData.generalmanager).email,
            _id: docRef.id,
            companyId: companyObj.objectId || '',
            companyName: companyObj.name || '',
            subject: '[補休/加班 審核] 總經理審核',
            html: `<img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/rY66GsLbHnTm9Tvrx-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Header.png" /><br /><br /><br />申請人：${employeeName}<br /><br />申請加班/補休 ${newData.day_off_classText}，<br />於${Moment(newData.eventStartAt).format('YYYY-MM-DD HH:mm:ss')}~${Moment(newData.eventEndAt).format('YYYY-MM-DD HH:mm:ss')}，共${newData.total_hours1}天${newData.total_hours2}時。<br /><br />請總經理至系統簽核查收，謝謝您<br />https://system.hanburyifa.com/auth/login
          <br /><br /><br /><br /><br /><br /><br /><br /><br /><img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/yKNhmTQoRepiYtRSj-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Footer.png" />`,
            To_name_cht: employee.find(item => item.objectId === newData.generalmanager).name || employee.find(item => item.objectId === newData.generalmanager).email,
            status: "99",
            status_text: "未寄出",
            type: 'dayoff',
          }
        // break;
        case '4':
          return emailObj = {
            ...mailObj,
            id: docRef.id,
            objectId: docRef.id,
            to: employee.find(item => item.objectId === newData.hr).email,
            _id: docRef.id,
            companyId: companyObj.objectId || '',
            companyName: companyObj.name || '',
            subject: '[補休/加班 審核] 人資審核',
            html: `<img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/rY66GsLbHnTm9Tvrx-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Header.png" /><br /><br /><br />申請人：${employeeName}<br /><br />申請加班/補休 ${newData.day_off_classText}，<br />於${Moment(newData.eventStartAt).format('YYYY-MM-DD HH:mm:ss')}~${Moment(newData.eventEndAt).format('YYYY-MM-DD HH:mm:ss')}，共${newData.total_hours1}天${newData.total_hours2}時。<br /><br />請人資主管至系統簽核查收，謝謝您<br />https://system.hanburyifa.com/auth/login
          <br /><br /><br /><br /><br /><br /><br /><br /><br /><img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/yKNhmTQoRepiYtRSj-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Footer.png" />`,
            To_name_cht: employee.find(item => item.objectId === newData.hr).name || employee.find(item => item.objectId === newData.hr).email,
            status: "99",
            status_text: "未寄出",
            type: 'dayoff',
          }
        // break;
        case '5':
          return emailObj = {
            ...mailObj,
            id: docRef.id,
            objectId: docRef.id,
            to: newData.employeeEmail,
            _id: docRef.id,
            companyId: companyObj.objectId || '',
            companyName: companyObj.name || '',
            subject: '[補休/加班 審核] 申請通過',
            html: `<img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/rY66GsLbHnTm9Tvrx-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Header.png" /><br /><br /><br />申請人：${employeeName}<br /><br />申請加班/補休 ${newData.day_off_classText}，<br />於${Moment(newData.eventStartAt).format('YYYY-MM-DD HH:mm:ss')}~${Moment(newData.eventEndAt).format('YYYY-MM-DD HH:mm:ss')}，共${newData.total_hours1}天${newData.total_hours2}時。<br /><br />已經由主管及人資主管簽核批准，謝謝您。
          <br /><br /><br /><br /><br /><br /><br /><br /><br /><img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/yKNhmTQoRepiYtRSj-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Footer.png" />`,
            To_name_cht: employee.find(item => item.objectId === newData.employeeId).name || employee.find(item => item.objectId === newData.employeeId).email,
            status: "99",
            status_text: "未寄出",
            type: 'dayoff',
          }
        // break;
        case '1':
          return emailObj = {
            ...mailObj,
            id: docRef.id,
            objectId: docRef.id,
            to: newData.employeeEmail,
            _id: docRef.id,
            companyId: companyObj.objectId || '',
            companyName: companyObj.name || '',
            subject: '[補休/加班 審核] 申請退回',
            html: `<img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/rY66GsLbHnTm9Tvrx-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Header.png" /><br /><br /><br />申請人：${employeeName}<br /><br />申請加班/補休 ${newData.day_off_classText}，<br />於${Moment(newData.eventStartAt).format('YYYY-MM-DD HH:mm:ss')}~${Moment(newData.eventEndAt).format('YYYY-MM-DD HH:mm:ss')}，共${newData.total_hours1}天${newData.total_hours2}時。<br /><br />已經由${who}退回，回覆如下：<br />${newData.ps ? newData.ps : ''}
          <br /><br /><br /><br /><br /><br /><br /><br /><br /><img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/yKNhmTQoRepiYtRSj-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Footer.png" />`,
            To_name_cht: employee.find(item => item.objectId === newData.employeeId).name || employee.find(item => item.objectId === newData.employeeId).email,
            status: "99",
            status_text: "未寄出",
            type: 'dayoff',
          }
        // break;
        case '0':
          return emailObj = {
            ...mailObj,
            id: docRef.id,
            objectId: docRef.id,
            to: newData.employeeEmail,
            _id: docRef.id,
            companyId: companyObj.objectId || '',
            companyName: companyObj.name || '',
            subject: '[補休/加班 審核] 申請拒絕',
            html: `<img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/rY66GsLbHnTm9Tvrx-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Header.png" /><br /><br /><br />申請人：${employeeName}<br /><br />申請加班/補休 ${newData.day_off_classText}，<br />於${Moment(newData.eventStartAt).format('YYYY-MM-DD HH:mm:ss')}~${Moment(newData.eventEndAt).format('YYYY-MM-DD HH:mm:ss')}，共${newData.total_hours1}天${newData.total_hours2}時。<br /><br />已經由${who}拒絕，回覆如下：<br />${newData.ps ? newData.ps : ''}
          <br /><br /><br /><br /><br /><br /><br /><br /><br /><img style="width:100%" src="https://s3-ap-northeast-1.amazonaws.com/hanbury/upload/images/yKNhmTQoRepiYtRSj-%E6%A0%B8%E5%BF%83%E7%B3%BB%E7%B5%B1%E7%B0%BD%E5%90%8D%E6%AA%94_Footer.png" />`,
            To_name_cht: employee.find(item => item.objectId === newData.employeeId).name || employee.find(item => item.objectId === newData.employeeId).email,
            status: "99",
            status_text: "未寄出",
            type: 'dayoff',
          }
        // break;
        default:
      }
    }
  }


  handleUpdate = async (id) => {
    const { employeeObj, objDay_off_status_first, employee, companyObj } = this.props
    const { newData, oldData } = this.state
    let obj = {}
    if (newData.dayoff_status !== oldData.dayoff_status) {
      obj = {
        ...newData,
        eventStartAt: (newData.eventStartAt && new Date(newData.eventStartAt.iso || newData.eventStartAt)) || new Date(),
        eventEndAt: (newData.eventEndAt && new Date(newData.eventEndAt.iso || newData.eventEndAt)) || new Date(),
        dayoff_statusText: (newData.dayoff_status && objDay_off_status_first[newData.dayoff_status]) || '',
      }
    } else if (id === '6' && newData.dayoff_status === oldData.dayoff_status) {
      obj = {
        ...newData,
        eventStartAt: (newData.eventStartAt && new Date(newData.eventStartAt.iso || newData.eventStartAt)) || new Date(),
        eventEndAt: (newData.eventEndAt && new Date(newData.eventEndAt.iso || newData.eventEndAt)) || new Date(),
        dayoff_status: id,
      }
    } else {
      obj = {
        ...newData,
        eventStartAt: (newData.eventStartAt && new Date(newData.eventStartAt.iso || newData.eventStartAt)) || new Date(),
        eventEndAt: (newData.eventEndAt && new Date(newData.eventEndAt.iso || newData.eventEndAt)) || new Date(),
      }
    }
    // const email = await this.toEmailObj(obj, 1)
    // console.log(email)
    if (obj.flag === 1) {
      if (obj.dayoff_status !== oldData.dayoff_status) {
        if (obj.dayoff_status === '3' && obj.supervisor !== undefined && obj.supervisor !== '') {
          const emailObj = await this.toEmailObj(obj, 1)
          await Parse.sendmail(emailObj).then(async (e) => {
            const nextObj = {
              ...emailObj,
              status: "2",
              status_text: "已寄出",
            }
            await Parse.saveData('email', nextObj);
          })
        }
        if (obj.dayoff_status === '6' && obj.generalmanager !== undefined && obj.generalmanager !== '') {
          const emailObj = await this.toEmailObj(obj, 1)
          await Parse.sendmail(emailObj).then(async (e) => {
            const nextObj = {
              ...emailObj,
              status: "2",
              status_text: "已寄出",
            }
            await Parse.saveData('email', nextObj);
          })
        }
        if (obj.dayoff_status === '4' && obj.hr !== undefined && obj.hr !== '') {
          const emailObj = await this.toEmailObj(obj, 1)
          await Parse.sendmail(emailObj).then(async (e) => {
            const nextObj = {
              ...emailObj,
              status: "2",
              status_text: "已寄出",
            }
            await Parse.saveData('email', nextObj);
          })
        }
        if (obj.dayoff_status === '5') {
          const emailObj = await this.toEmailObj(obj, 1)
          await Parse.sendmail(emailObj).then(async (e) => {
            const nextObj = {
              ...emailObj,
              status: "2",
              status_text: "已寄出",
            }
            await Parse.saveData('email', nextObj);
          })
        }
        if (obj.dayoff_status === '1') {
          let whoReturn = ''
          if (oldData.dayoff_status === '2') {
            whoReturn = '代理人 ' + obj.substituteText
          } else if (oldData.dayoff_status === '3') {
            whoReturn = '主管 ' + obj.supervisorText
          } else if (oldData.dayoff_status === '6') {
            whoReturn = '總經理 ' + obj.generalmanagerText
          } else if (oldData.dayoff_status === '4') {
            whoReturn = '人資主管 ' + obj.hrText
          }
          const emailObj = await this.toEmailObj(obj, 1, whoReturn)
          await Parse.sendmail(emailObj).then(async (e) => {
            const nextObj = {
              ...emailObj,
              status: "2",
              status_text: "已寄出",
            }
            await Parse.saveData('email', nextObj);
          })
        }
        if (obj.dayoff_status === '0') {
          let whoReject = ''
          if (oldData.dayoff_status === '2') {
            whoReject = '代理人 ' + obj.substituteText
          } else if (oldData.dayoff_status === '3') {
            whoReject = '主管 ' + obj.supervisorText
          } else if (oldData.dayoff_status === '6') {
            whoReject = '總經理 ' + obj.generalmanagerText
          } else if (oldData.dayoff_status === '4') {
            whoReject = '人資主管 ' + obj.hrText
          }
          const emailObj = await this.toEmailObj(obj, 1, whoReject)
          await Parse.sendmail(emailObj).then(async (e) => {
            const nextObj = {
              ...emailObj,
              status: "2",
              status_text: "已寄出",
            }
            await Parse.saveData('email', nextObj);
          })
        }
        await Parse.saveData('dayoff', obj);
      } else {
        await Parse.saveData('dayoff', obj);
      }
    } else if (obj.flag === 2) {
      if (obj.dayoff_status !== oldData.dayoff_status) {
        if (obj.dayoff_status === '3' && obj.supervisor !== undefined && obj.supervisor !== '') {
          const emailObj = await this.toEmailObj(obj, 2)
          await Parse.sendmail(emailObj).then(async (e) => {
            const nextObj = {
              ...emailObj,
              status: "2",
              status_text: "已寄出",
            }
            await Parse.saveData('email', nextObj);
          })
        }
        if (obj.dayoff_status === '6' && obj.generalmanager !== undefined && obj.generalmanager !== '') {
          const emailObj = await this.toEmailObj(obj, 2)
          await Parse.sendmail(emailObj).then(async (e) => {
            const nextObj = {
              ...emailObj,
              status: "2",
              status_text: "已寄出",
            }
            await Parse.saveData('email', nextObj);
          })
        }
        if (obj.dayoff_status === '4' && obj.hr !== undefined && obj.hr !== '') {
          const emailObj = await this.toEmailObj(obj, 2)
          await Parse.sendmail(emailObj).then(async (e) => {
            const nextObj = {
              ...emailObj,
              status: "2",
              status_text: "已寄出",
            }
            await Parse.saveData('email', nextObj);
          })
        }
        if (obj.dayoff_status === '5') {
          const emailObj = await this.toEmailObj(obj, 2)
          await Parse.sendmail(emailObj).then(async (e) => {
            const nextObj = {
              ...emailObj,
              status: "2",
              status_text: "已寄出",
            }
            await Parse.saveData('email', nextObj);
          })
        }
        if (obj.dayoff_status === '1') {
          let whoReturn = ''
          if (oldData.dayoff_status === '2') {
            whoReturn = '代理人 ' + obj.substituteText
          } else if (oldData.dayoff_status === '3') {
            whoReturn = '主管 ' + obj.supervisorText
          } else if (oldData.dayoff_status === '6') {
            whoReturn = '總經理 ' + obj.generalmanagerText
          } else if (oldData.dayoff_status === '4') {
            whoReturn = '人資主管 ' + obj.hrText
          }
          const emailObj = await this.toEmailObj(obj, 2, whoReturn)
          await Parse.sendmail(emailObj).then(async (e) => {
            const nextObj = {
              ...emailObj,
              status: "2",
              status_text: "已寄出",
            }
            await Parse.saveData('email', nextObj);
          })
        }
        if (obj.dayoff_status === '0') {
          let whoReject = ''
          if (oldData.dayoff_status === '2') {
            whoReject = '代理人 ' + obj.substituteText
          } else if (oldData.dayoff_status === '3') {
            whoReject = '主管 ' + obj.supervisorText
          } else if (oldData.dayoff_status === '6') {
            whoReject = '總經理 ' + obj.generalmanagerText
          } else if (oldData.dayoff_status === '4') {
            whoReject = '人資主管 ' + obj.hrText
          }
          const emailObj = await this.toEmailObj(obj, 2, whoReject)
          await Parse.sendmail(emailObj).then(async (e) => {
            const nextObj = {
              ...emailObj,
              status: "2",
              status_text: "已寄出",
            }
            await Parse.saveData('email', nextObj);
          })
        }
        // const docRef = db.collection(`users/${obj.employeeId}/comptime`).doc(obj._id);
        await Parse.saveData('comptime', obj);
      } else {
        await Parse.saveData('comptime', obj);
      }
    }
    this.setState({ oldData: {}, newData: {} })
    this.handleFetch()
  }


  render() {
    const { companyObj, employeeObj } = this.props
    const { dayoffArr, auth_substitutegoSel, loading, dataArr2, dataArr3, dataArr4, dataArr6 } = this.state;
    const objDay_offSel = Object.assign(this.props.objDay_offSel, this.props.objDay_offSel2)

    // const dataArr2 = dayoffArr.filter(item => item.dayoff_status === '2');
    // const dataArr3 = dayoffArr.filter(item => item.dayoff_status === '3');
    // const dataArr4 = dayoffArr.filter(item => item.dayoff_status === '4');
    // const dataArr6 = dayoffArr.filter(item => item.dayoff_status === '6');

    return (<>
      {employeeObj.auth_substitutego === '1' ? <>
        <Header inverted={companyObj.inverted} as='h3' content='a. 代理人審核回覆' style={{ margin: 0 }} />
        <MaterialTable
          isLoading={loading}
          localization={localization()}
          tableRef={this.tableRef}
          columns={columns1(auth_substitutegoSel, objDay_offSel)}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportAllData: true,
            exportPdf: (columns, data) => exportPdf(columns, data),
            pageSize: dataArr2.length || 5,
            search: false,
            toolbar: false
            // showFirstLastPageButtons: true
          }}
          // actions={[
          //   {
          //     icon: 'view_headline',
          //     tooltip: '查看',
          //     onClick: (event, rowData) => this.handleData(rowData)
          //   }
          // ]}
          data={dataArr2}
          title=""
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const data = [...dataArr2];
                const index = data.indexOf(oldData);
                data[index] = newData;
                this.setState({ dataArr2: data, newData, oldData, loading: true }, () => resolve());
              }).then(() => this.handleUpdate()),
          }}
        /></> : null}
      <br />
      <br />
      {employeeObj.auth_supervisorgo === '1' ? <>
        <Header inverted={companyObj.inverted} as='h3' content='b. 主管審核回覆' style={{ margin: 0 }} />
        <MaterialTable
          isLoading={loading}
          localization={localization()}
          tableRef={this.tableRef}
          columns={columns2(auth_substitutegoSel, objDay_offSel)}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportAllData: true,
            exportPdf: (columns, data) => exportPdf(columns, data),
            pageSize: dataArr3.length || 5,
            search: false,
            toolbar: false
            // showFirstLastPageButtons: true
          }}
          // actions={[
          //   {
          //     icon: 'view_headline',
          //     tooltip: '查看',
          //     onClick: (event, rowData) => this.handleData(rowData)
          //   }
          // ]}
          data={dataArr3}
          title=""
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const data = [...dataArr3];
                const index = data.indexOf(oldData);
                data[index] = newData;
                this.setState({ dataArr3: data, newData, oldData, loading: true }, () => resolve());
              }).then(() => this.handleUpdate()),
          }}
        /> </> : null}
      <br />
      <br />

      {employeeObj.auth_generalmanagergo === '1' ? <>
        <Header inverted={companyObj.inverted} as='h3' content='c. 總經理審核回覆' style={{ margin: 0 }} />
        <MaterialTable
          isLoading={loading}
          localization={localization()}
          tableRef={this.tableRef}
          columns={columns3(auth_substitutegoSel, objDay_offSel)}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportAllData: true,
            exportPdf: (columns, data) => exportPdf(columns, data),
            pageSize: dataArr6.length || 5,
            search: false,
            toolbar: false
            // showFirstLastPageButtons: true
          }}
          // actions={[
          //   {
          //     icon: 'view_headline',
          //     tooltip: '查看',
          //     onClick: (event, rowData) => this.handleData(rowData)
          //   }
          // ]}
          data={dataArr6}
          title=""
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const data = dataArr6;
                const index = data.indexOf(oldData);
                data[index] = newData;
                this.setState({ dataArr6: data, newData, oldData, loading: true }, () => resolve());
              }).then(() => this.handleUpdate('6')),
          }}
        /></> : null}
      <br />
      <br />
      {employeeObj.auth_hrgo === '1' ? <>
        <Header inverted={companyObj.inverted} as='h3' content='d. 人資審核回覆' style={{ margin: 0 }} />
        <MaterialTable
          isLoading={loading}
          localization={localization()}
          tableRef={this.tableRef}
          columns={columns4(auth_substitutegoSel, objDay_offSel)}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportAllData: true,
            exportPdf: (columns, data) => exportPdf(columns, data),
            pageSize: dataArr4.length || 5,
            search: false,
            toolbar: false
            // showFirstLastPageButtons: true
          }}
          // actions={[
          //   {
          //     icon: 'view_headline',
          //     tooltip: '查看',
          //     onClick: (event, rowData) => this.handleData(rowData)
          //   }
          // ]}
          data={dataArr4}
          title=""
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const data = [...dataArr4];
                const index = data.indexOf(oldData);
                data[index] = newData;
                this.setState({ dataArr4: data, newData, oldData, loading: true }, () => resolve());
              }).then(() => this.handleUpdate()),
          }}
        /></> : null}
      <br />
      <br />
    </>)
  }
}