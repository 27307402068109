import React, { Component } from 'react'
import { Container, Form, Header, Button, Icon, Table, Input, Label, Select, Grid } from 'semantic-ui-react'
import Moment from 'moment';
import { Link } from 'react-router-dom'

import MyModalTable from '../../widgets/MyModalTable';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import { getOptionsByKey } from 'views/widgets/FieldsRender';
import Parse from '../../../widget/parse'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class AgentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      listType: '0',
      xlsList: [],
      confirm: false,
      form: {
        // eventDate: Moment(new Date()).format('YYYY/MM/DD'),
        // semesterYear: props.semesterYear,
        // semester: props.semester,
      }
    }
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { firebase, semester, semesterYear, companyObj } = props;
    const { agent } = this.state
    // if (!agentL) {
    // handleLazyLoad('agent');
    const data = await Parse.queryData('Agent', { companyId: companyObj.objectId });
    this.setState({ agent: data });
    // } else {
    let agentData = data
      .sort((a, b) => ('' + b.testCode).localeCompare(a.testCode))
      .sort((a, b) => ('' + b.semesterYear).localeCompare(a.semesterYear)) || []

    this.setState({ agentData })

    const data1 = await Parse.queryData('Students', { companyId: companyObj.objectId });
    this.setState({ students: data1 });
    // }
    // if (!studentsL) {
    // handleLazyLoad('students');
    // }
  }

  handleOpen = () => {
    this.setState({ show: true })
  }

  handleEdit = (agentObj) => {
    this.setState({ show: true, form: { ...agentObj } });
  }

  handleClose = () => {
    this.setState({
      show: false,
      form: {
      },
    });
  }

  handleChange = (e, { name, value }) => {
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    })
  }

  showConfirm = (relativeObj) => {
    this.setState({ confirm: true, form: { ...relativeObj } });
  }

  hideConfirm = () => {
    this.setState({ confirm: false, form: {} });
  }

  handleRemove = async () => {
    const { form, agentData } = this.state
    await Parse.deleteData('Agent', form)
    let findIndex = agentData.findIndex(item => item.objectId === form.objectId)
    agentData.splice(findIndex, 1)
    this.hideConfirm()
  }

  handleSubmit = async () => {

    const { companyObj } = this.props
    const { title, form, agentData } = this.state
    this.setState({ loading: true });

    // const db = firebase.firestore();
    const newAgent = [...agentData];
    if (!form.objectId) {
      // const docRef = db.collection(`Agent`).doc();
      const db = Parse.Object.extend("Agent");
      const newDoc = new db();
      const docRef = await newDoc.save();
      const obj = {
        ...form,
        objectId: docRef.id,
        companyId: companyObj.objectId,
        companyName: companyObj.name,
      };
      // await docRef.set(obj);
      await Parse.saveData('Agent', obj)
      newAgent.unshift(obj);
    } else {
      // await db.collection(`Agent`).doc(form.objectId).update(form);
      await Parse.saveData('Agent', form)
      let index = newAgent.findIndex((item => item.objectId === form.objectId));
      newAgent[index] = form;
    }
    // if (form.during === true) { //取代現在
    //   let old = parttime.find(item => item.during === true) && parttime.find(item => item.during === true)._id;
    //   if (old) {
    //     let oldIndex = parttime.findIndex(item => item._id === old);
    //     parttime[oldIndex].during = false
    //     firebase.firestore().doc(`Students/${doc._id}/Parttime/${old}`).update({ during: false }).then(
    //       console.log(`Document set`)
    //     );
    //   }
    // }

    this.setState({ agentData: newAgent, loading: false });
    this.handleClose();
  }

  // fieldsTerm = [
  //   {
  //     id: 'semesterYear',
  //     title: '学年'
  //   },
  //   {
  //     id: 'semester',
  //     title: '学期'
  //   },
  //   {
  //     id: 'testCode',
  //     title: 'テストコード'
  //   },
  //   {
  //     id: 'testLevel',
  //     title: '試験レべル'
  //   },
  //   {
  //     id: 'testDate',
  //     title: '試験日'
  //   },
  //   {
  //     id: 'ps',
  //     title: '備考'
  //   },
  // ]

  render() {
    const { grade, match, semester, semesterYear, optSet, companyObj } = this.props
    const { isEdit, agentData, xlsList, listType, show, form, loading } = this.state
    const { examKey } = match.params;

    const semesterYearArr = getOptionsByKey(optSet, 'semesterYear').map(item => ({ key: item._id, text: item.label, value: item.value }));
    const semestersArr = getOptionsByKey(optSet, 'semester').map(item => ({ key: item._id, text: item.label, value: item.value }));

    return (<div className="content" style={{ overflow: 'scroll' }}>
      <>
        <Header as='h2' floated='left'>囑託募集先</Header>
        <Button floated='right' color='green' onClick={this.handleOpen} icon='add' content='追加' />
        {/* {isEdit ? <Button positive onClick={() => this.saveUpdateSetting()} icon='save' content='保存' /> : */}
        {!isEdit ? <Button floated='right' primary onClick={() => this.setState({ isEdit: true })} icon="edit" content="編集" /> :
          <Button floated='right' basic onClick={() => this.setState({ isEdit: false })} icon='times' content='編集を終了' />}
        {/* <MyModalTable
        {...this.props}
        pageTitleText={label}
        // fields={fields}
        rows={isEdit,}
        columns={columns}
        type='grade'
        examKey={examKey}
      /> */}
        <ExportTableToExcelButton floated='right' size='small' />
        <br />
        <Table id='excel' celled striped selectable>
          <Table.Header>
            <Table.Row>
              {isEdit ? <Table.HeaderCell style={{ width: '100px' }}>#</Table.HeaderCell> : null}
              <Table.HeaderCell>会社名</Table.HeaderCell>
              <Table.HeaderCell>登録番号</Table.HeaderCell>
              <Table.HeaderCell>会社電話</Table.HeaderCell>
              <Table.HeaderCell>会社Eメ一ル</Table.HeaderCell>
              <Table.HeaderCell>会社ホームページ</Table.HeaderCell>
              <Table.HeaderCell>会社住所</Table.HeaderCell>
              {/* <Table.HeaderCell>会社SNS</Table.HeaderCell> */}
              <Table.HeaderCell>担当者氏名</Table.HeaderCell>
              <Table.HeaderCell>担当者電話</Table.HeaderCell>
              <Table.HeaderCell>担当者Eメ一ル</Table.HeaderCell>
              <Table.HeaderCell>担当者weChat</Table.HeaderCell>
              <Table.HeaderCell>担当者QQ</Table.HeaderCell>
              <Table.HeaderCell>担当者LINE</Table.HeaderCell>
              {/* {!isEdit ? <Table.HeaderCell></Table.HeaderCell> : null} */}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {agentData && agentData.map((item, index) => (<Table.Row key={`td${index}`}>
              {isEdit ? <Table.Cell style={{ padding: 10 }}>
                <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} icon='edit' />
                <Button inverted={companyObj.inverted} color='red' size='small' onClick={() => this.showConfirm(item)} icon='delete' />
              </Table.Cell> : null}
              {/* <Link style={{ color: '#000' }} to={`/ExamTable/${item._id}`}> */}
              <Table.Cell>{item.agentCoName}</Table.Cell>{/*agentCenterName*/}
              <Table.Cell>{item.agentCenterNumber}</Table.Cell>
              <Table.Cell>{item.agentCoTel}</Table.Cell>
              <Table.Cell>{item.agentCoEmail}</Table.Cell>
              <Table.Cell>{item.agentCoUrl}</Table.Cell>
              <Table.Cell>{item.agentCoAddr}</Table.Cell>
              {/* <Table.Cell>{}</Table.Cell> */}
              <Table.Cell>{item.agentName}</Table.Cell>
              <Table.Cell>{item.agentTel}</Table.Cell>
              <Table.Cell>{item.agentEmail}</Table.Cell>
              <Table.Cell>{item.agentWechat}</Table.Cell>
              <Table.Cell>{item.agentQq}</Table.Cell>
              <Table.Cell>{item.agentLine}</Table.Cell>
              {/* {!isEdit ? <Table.Cell><Button content='詳細' color='green' size='small' as={Link} to={`/ExamTable/${item._id}`} /></Table.Cell> : null} */}
              {/* </Link> */}
            </Table.Row>
            ))}
            {!agentData || !agentData.length ? <Table.Row>
              <Table.Cell colSpan={isEdit ? 13 : 12} textAlign='center' >(資料無し)</Table.Cell>
            </Table.Row> : null}
          </Table.Body>
        </Table>

        <br />

        {/* <Modal open={show} onClose={this.handleClose} size='tiny'> */}
        {/* <Modal.Header>
          <h2 style={{ margin: 0 }}> 定期テスト</h2>
        </Modal.Header> */}
        <Modal
          isOpen={show}
          toggle={this.handleClose}
          // size="sm"
          className="mh-80 h-80 mw-80 w-80"
          style={{ paddingTop: '0px' }}
          scrollable={true}
        >
          <ModalBody style={{ padding: "20px 30px" }}>
            <Grid>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>会社名</label>
                  <Form.Input
                    value={form.agentCoName} onChange={this.handleChange} style={{ minWidth: 196 }} name='agentCoName'
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>編号</label>
                  <Form.Input
                    value={form.agentCenterNumber} onChange={this.handleChange} style={{ minWidth: 196 }} name='agentCenterNumber'
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>会社電話</label>
                  <Form.Input
                    value={form.agentCoTel} onChange={this.handleChange} style={{ minWidth: 196 }} name='agentCoTel'
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>会社Eメ一ル</label>
                  <Form.Input
                    value={form.agentCoEmail} onChange={this.handleChange} style={{ minWidth: 196 }} name='agentCoEmail'
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>会社ホームページ</label>
                  <Form.Input
                    value={form.agentCoUrl} onChange={this.handleChange} style={{ minWidth: 196 }} name='agentCoUrl'
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>会社住所</label>
                  <Form.Input
                    value={form.agentCoAddr} onChange={this.handleChange} style={{ minWidth: 196 }} name='agentCoAddr'
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>担当者氏名</label>
                  <Form.Input
                    value={form.agentName} onChange={this.handleChange} style={{ minWidth: 196 }} name='agentName'
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>担当者電話</label>
                  <Form.Input
                    value={form.agentTel} onChange={this.handleChange} style={{ minWidth: 196 }} name='agentTel'
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>担当者Eメ一ル</label>
                  <Form.Input
                    value={form.agentEmail} onChange={this.handleChange} style={{ minWidth: 196 }} name='agentEmail'
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>担当者LINE</label>
                  <Form.Input
                    value={form.agentLine} onChange={this.handleChange} style={{ minWidth: 196 }} name='agentLine'
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>担当者weChat</label>
                  <Form.Input
                    value={form.agentWechat} onChange={this.handleChange} style={{ minWidth: 196 }} name='agentWechat'
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>担当者QQ</label>
                  <Form.Input
                    value={form.agentQqER} onChange={this.handleChange} style={{ minWidth: 196 }} name='agentQq'
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </ModalBody>
          <ModalFooter style={{ padding: '16px' }}>
            <Button onClick={this.handleSubmit} inverted={companyObj.inverted} color='green' loading={loading} icon='checkmark' content={form.objectId ? '保存' : '新規'} />
            <Button onClick={this.handleClose} inverted={companyObj.inverted} color='red' icon='delete' content='閉じる' />
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.confirm}
          toggle={this.hideConfirm}
          size="lg"
          className="mh-100 h-90 mw-100 w-90"
        >
          <ModalHeader>削除</ModalHeader>
          <ModalBody>
            削除してもいいですか？
        </ModalBody>
          <ModalFooter style={{ padding: '16px' }}>
            <Button onClick={this.handleRemove} inverted color='red' icon='delete' content='はい' />
            <Button onClick={this.hideConfirm} content='いいえ' />
          </ModalFooter>
        </Modal>
      </>
    </div>)
  }
}