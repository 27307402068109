import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup } from 'reactstrap';

const UNIT_NUM = 120;
const WIDTH_NUM = UNIT_NUM * 3;
const HEIGHT_NUM = UNIT_NUM * 2;

const WIDTH1_1 = `${WIDTH_NUM}px`;
const WIDTH1_2 = `${WIDTH_NUM / 2}px`;
const WIDTH1_3 = `${WIDTH_NUM / 3}px`;
const WIDTH2_3 = `${WIDTH_NUM / 3 * 2}px`;

const HEIGHT1_1 = `${HEIGHT_NUM}px`;
const HEIGHT1_2 = `${HEIGHT_NUM / 2}px`;

const layoutObj = {
  '21': [
    { width: WIDTH1_3, height: HEIGHT1_2 },
    { width: WIDTH1_3, height: HEIGHT1_2 },
    { width: WIDTH1_3, height: HEIGHT1_2 },
    { width: WIDTH1_3, height: HEIGHT1_2 },
    { width: WIDTH1_3, height: HEIGHT1_2 },
    { width: WIDTH1_3, height: HEIGHT1_2 },
  ],
  '22': [
    { width: WIDTH1_2, height: HEIGHT1_2 },
    { width: WIDTH1_2, height: HEIGHT1_2 },
    { width: WIDTH1_2, height: HEIGHT1_2 },
    { width: WIDTH1_2, height: HEIGHT1_2 },
  ],
  '23': [
    { width: WIDTH1_1, height: HEIGHT1_2 },
    { width: WIDTH1_3, height: HEIGHT1_2 },
    { width: WIDTH1_3, height: HEIGHT1_2 },
    { width: WIDTH1_3, height: HEIGHT1_2 },
  ],
  '24': [
    { width: WIDTH1_2, height: HEIGHT1_1 },
    { width: WIDTH1_2, height: HEIGHT1_2 },
    { width: WIDTH1_2, height: HEIGHT1_2 },
  ],
  '25': [
    { width: WIDTH1_1, height: HEIGHT1_2 },
    { width: WIDTH1_1, height: HEIGHT1_2 },
  ],
  '26': [
    { width: WIDTH1_2, height: HEIGHT1_1 },
    { width: WIDTH1_2, height: HEIGHT1_1 },
  ],
  '27': [
    { width: WIDTH1_1, height: HEIGHT1_1 },
  ],
  '11': [
    { width: WIDTH1_3, height: HEIGHT1_2 },
    { width: WIDTH1_3, height: HEIGHT1_2 },
    { width: WIDTH1_3, height: HEIGHT1_2 },
  ],
  '12': [
    { width: WIDTH1_3, height: HEIGHT1_2 },
    { width: WIDTH2_3, height: HEIGHT1_2 },
  ],
  '13': [
    { width: WIDTH2_3, height: HEIGHT1_2 },
    { width: WIDTH1_3, height: HEIGHT1_2 },
  ],
  '14': [
    { width: WIDTH1_2, height: HEIGHT1_2 },
    { width: WIDTH1_2, height: HEIGHT1_2 },
  ],
  '15': [
    { width: WIDTH1_1, height: HEIGHT1_2 },
  ],
}

const commonCss = {
  border: '1px #ccc solid', float: 'left', padding: '2px',
  position: 'relative',
  // textAlign: 'center'
}

export const richItem = ({ width, height }, index, form, onChange, handleClick) =>
  (<div key={`richItem${index}`}
    style={{ width, height, ...commonCss, cursor: onChange ? 'auto' : 'pointer' }}
    onClick={onChange ? null : () => handleClick(form, `${index}`)} >
    {/* {onChange ?
    <Input
      type="text"
      // name={`${index}`}
      name={`richItem`}
      id={`richItem${index}`}
      style={{
        position: 'absolute',
        bottom: '2px',
        left: 0,
      }}
      value={form[`richItem${index}`] || ''}
      onChange={onChange}
    /> : <div style={{
      // position: 'absolute',
      // top: '0px'
      // bottom: '2px',
      // left: 0,
    }}>
    <span style={{ backgroundColor: '#eee' }}>{index + 1} {form[`richItem${index}`]}</span>
  </div>} */}
    {/* <div style={{ fontSize: '26px', marginTop: '20px', color: '#666' }}>{index + 1}</div> */}
  </div >)


export const richLayout = (form, onChange, handleClick) => {
  const type = form.layoutId || '21';
  if (!type || !form.bgId) {
    return null;
  }
  // console.log(form)

  const content = <>{layoutObj[type].map((item, index) => richItem(item, index, form, onChange, handleClick))}</>
  // console.log(form.image1File && typeof form.image1File.url)

  let backgroundImage = '';
  if (form.image1File) {
    if (typeof form.image1File.url === 'string') {
      backgroundImage = form.image1File.url
    } else if (typeof form.image1File.url === 'function') {
      backgroundImage = form.image1File.url()
    }
  }

  if (type === '11' || type === '12' || type === '13' || type === '14' || type === '15') {
    return (<div style={{
      width: WIDTH1_1, height: HEIGHT1_2,
      padding: '0px',
      backgroundColor: 'white',
      display: 'table-cell',
      verticalAlign: 'bottom',
      clear: 'both',
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
      {content}
      {/* <br />按鈕：{form.chatBarText || ''}
      <br />備註：{form.ps || ''} */}
    </div>)
  } else { // 兩排的
    return (<div style={{
      width: WIDTH1_1, height: HEIGHT1_1,
      padding: '0px',
      backgroundColor: '#ccc',
      clear: 'both',
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
      {content}
      {/* <br />按鈕：{form.chatBarText || ''}
      <br />備註：{form.ps || ''} */}
    </div>)
  }
}
