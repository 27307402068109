import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  // NavItem,
  // NavLink,
  // Nav,
  // TabContent,
  // TabPane,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function SectionPricing(props = {}) {
  const { form } = props;
  // console.log(form)
  const { text2 = 'text2', text3 = 'text3', text4 = 'text4', text5 = 'text5', text6 = 'text6',
    text7 = 'text7', text8 = 'text8', text9 = 'text9', text10 = 'text10', text11 = 'text11', text12 = 'text12',
    text13 = 'text13', text14 = 'text14', text15 = 'text15', text16 = 'text16', text17 = 'text17',
    text18 = 'text18', text19 = 'text19', text20 = 'text20' } = form;
  const [activePill, setActivePill] = React.useState("1");
  // pills for the last pricing
  // const [pillActive, setPillActive] = React.useState("personal");
  return (
    <>
      <div className="section section-pricing cd-section" id="pricing">
        {/* ********* PRICING 1 ********* */}
        <div className="pricing-1 section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">{text2}</h2>
                <h5 className="description">
                  {text3}
                </h5>
              </Col>
            </Row>
            <div className="space-top" />
            <Row>
              <Col md="3">
                <Card className="card-pricing" data-color="orange">
                  <CardBody>
                    <div className="card-icon">
                      <span className="icon-simple">
                        <i className="nc-icon nc-basket" />
                      </span>
                    </div>
                    <CardTitle tag="h3">{text4}</CardTitle>
                    <p className="card-description">
                      {text5}
                    </p>
                    <CardFooter>
                      <Button
                        className="btn-neutral btn-round"
                        color="default"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        {text6}
                      </Button>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card className="card-pricing" data-color="yellow">
                  <CardBody>
                    <div className="card-icon">
                      <span className="icon-simple">
                        <i className="nc-icon nc-watch-time" />
                      </span>
                    </div>
                    <CardTitle tag="h3">{text7}</CardTitle>
                    <p className="card-description">
                      {text8}
                    </p>
                    <CardFooter>
                      <Button
                        className="btn-neutral btn-round"
                        color="default"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        {text9}
                      </Button>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card className="card-pricing" data-color="green">
                  <CardBody>
                    <div className="card-icon">
                      <span className="icon-simple">
                        <i className="nc-icon nc-settings" />
                      </span>
                    </div>
                    <CardTitle tag="h3">{text10}</CardTitle>
                    <p className="card-description">
                      {text11}
                    </p>
                    <CardFooter>
                      <Button
                        className="btn-neutral btn-round"
                        color="default"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        {text12}
                      </Button>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card className="card-pricing" data-color="blue">
                  <CardBody>
                    <div className="card-icon">
                      <span className="icon-simple">
                        <i className="nc-icon nc-istanbul" />
                      </span>
                    </div>
                    <CardTitle tag="h3">{text13}</CardTitle>
                    <p className="card-description">
                      {text14}
                    </p>
                    <CardFooter>
                      <Button
                        className="btn-neutral btn-round"
                        color="default"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        {text15}
                      </Button>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END PRICING 1 ********* */}
      </div>
    </>
  );
}

export default SectionPricing;
