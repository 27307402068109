import React, { Component } from 'react';
import { Form, Button, Modal, Table, Grid, Icon, Header, Confirm, TextArea, Divider } from 'semantic-ui-react';
import List4Employee from './List4Employee';
import { Row, Col } from 'reactstrap';
import {
  Card, FormGroup, Input, CardBody, CardHeader
} from "reactstrap";
import { user } from "views/formDef/object";
import { fieldCol } from "views/formDef/function";

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export default class List1Employee extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      show: false,
      isReadOnly: false,
      // form: {},
      loading: false,
      confirm: false,
      unsub: null,
      isSpread2: false,
      // teacher: [],
    };
  }

  handleIsSpread2 = () => {
    if (this.state.isSpread2 === false) {
      this.setState({ isSpread2: true })
    } else {
      this.setState({ isSpread2: false })
    }
  }

  render() {
    const { doc, form, isEdit, } = this.props
    const { isSpread2 } = this.state;
    return (<>
      <div style={style.flexCenter}>
        <Header as='h2' content='基本資料' />
      </div>
      <Divider />
      <Row >
        {/* <Grid columns='equal' > */}
        {/* <Col sm={4}>{showField(this.props, "is_auth")}</Col>
        <Col sm={4}>{showField(this.props, "onbroad_date")}</Col>
        <Col sm={4}>{showField(this.props, "leave_date")}</Col> */}
        {fieldCol(user.is_auth, "pr-1", "4", doc.is_auth, this.props.handleChangeField, !isEdit)}
        {fieldCol(user.onbroad_date, "pr-1", "4", doc.onbroad_date, this.props.handleChangeField, !isEdit)}
        {fieldCol(user.leave_date, "pr-1", "4", doc.leave_date, this.props.handleChangeField, !isEdit)}
      </Row>
      <br />
      <br />
      <Row>
        <Col>
          <Card outline color='secondary' fluid style={{}} >
            {/* <Card.Content header='本人基本情報' /> */}
            <CardHeader tag='h4' style={{ backgroundColor: '#f07b51' }} >
              本人基本情報
            </CardHeader>
            <CardBody>
              <Row>
                {/* <Grid columns='equal'> */}
                {/* <Col sm='3'>{showField(this.props, "name")}</Col>
                <Col sm='3'>{showField(this.props, "jobNum")}</Col>
                <Col sm='3'>{showField(this.props, "engname")}</Col>
                <Col sm='3'>{showField(this.props, "tw_id")}</Col> */}
                {fieldCol(user.name, "pr-1", "3", doc.name, this.props.handleChangeField, !isEdit)}
                {fieldCol(user.jobNum, "pr-1", "3", doc.jobNum, this.props.handleChangeField, !isEdit)}
                {fieldCol(user.name_eng, "pr-1", "3", doc.name_eng, this.props.handleChangeField, !isEdit)}
                {fieldCol(user.tw_id, "pr-1", "3", doc.tw_id, this.props.handleChangeField, !isEdit)}

                {/* <Col><Form.Input fluid label='護照姓名' placeholder='Last name' value={form.profile && form.profile.passport_name || ''} /></Col> */}

                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                {/* <Col>{showField(this.props, "onbroad_date")}</Col>
                <Col>{showField(this.props, "leave_date")}</Col>
                <Col>{showField(this.props, "jobyear")}</Col>
                <Col>{showField(this.props, "jobTitle")}</Col> */}
                {fieldCol(user.onbroad_date, "pr-1", "3", doc.onbroad_date, this.props.handleChangeField, !isEdit)}
                {fieldCol(user.leave_date, "pr-1", "3", doc.leave_date, this.props.handleChangeField, !isEdit)}
                {fieldCol(user.jobyear, "pr-1", "3", doc.jobyear, this.props.handleChangeField, true)}
                {/* <Col className={'pr-1'} md={3}>
                  <FormGroup>
                    <label htmlFor="fieldGroup">年資</label>
                    <Input
                      id={'fieldFormGroup' + '_' + 'jobyear'}
                      name={'jobyear'}
                      // placeholder={placeholder || label}
                      type='text'
                      value={`${seniorityYear}年${seniorityMonth}月`}
                      disabled={true}
                    />
                  </FormGroup>
                </Col> */}
                {fieldCol(user.jobTitle, "pr-1", "3", doc.jobTitle, this.props.handleChangeField, !isEdit)}
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                {/* <Col sm='3'>{showField(this.props, "passport_name")}</Col>
                <Col sm='3'>{showField(this.props, "birthday_date")}</Col>
                <Col sm='3'>{showField(this.props, "email")}</Col>
                <Col sm='3'>{showField(this.props, "phone")}</Col> */}
                {fieldCol(user.passport_name, "pr-1", "3", doc.passport_name, this.props.handleChangeField, !isEdit)}
                {fieldCol(user.birthday_date, "pr-1", "3", doc.birthday_date, this.props.handleChangeField, !isEdit)}
                {fieldCol(user.email, "pr-1", "3", doc.email, this.props.handleChangeField, !isEdit)}
                {fieldCol(user.phone, "pr-1", "3", doc.phone, this.props.handleChangeField, !isEdit)}
                {/* <Col>{showField(this.props, "ps")}</Col> */}
                {/* <Col>
                          <Form.Select fluid
                            label='隸屬事業群'
                            disabled={!isEdit}
                            options={bg.map(item => ({ key: item._id, value: item.id, text: item.value }))}
                            // defaultValue={doc && doc.department_id} 
                            value={doc && doc.department_id}
                            name="department_id" onChange={handleUpdateSetting} />
                        </Col> */}
                {/*  <Col>
                             <Form.Input label='教員番号' defaultValue={item.teacherNum || ''} name='teacherNum' readOnly={!isEdit} onChange={this.handleChange} />
                             </Col>
                             <Col>
                             <Form.Input label='職名' defaultValue={item.teacherTitleText || ''} name='teacherTitleText' readOnly={!isEdit} onChange={this.handleChange} />
                             </Col>
                             <Col>
                             <Form.Input label='性別' defaultValue={item.genderTeacherText || ''} name='genderTeacherText' readOnly={!isEdit} onChange={this.handleChange} /> 
                            </Col>*/}
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                {/* <Col sm={6}>{showField(this.props, "aan")}</Col> */}
                {fieldCol(user.role, "pr-1", "4", doc.role, this.props.handleChangeField, !isEdit)}
                {fieldCol(user.aan, "pr-1", "4", doc.aan, this.props.handleChangeField, !isEdit)}
                {/* <Col sm={8}>{showField(this.props, "department_id")}</Col> */}
                {/* <Col sm={6}>
                  <Form.TextArea label='備註' placeholder='備註' onChange={handleUpdateSetting} name='ps' readOnly={!isEdit} value={doc.ps || ''} />
                </Col> */}
                {fieldCol(user.ps, "pr-1", "4", doc.ps, this.props.handleChangeField, !isEdit)}
                {/* <Col sm={3}>{showField(this.props, "addr1_phone")}</Col>
                        <Col sm={3}>{showField(this.props, "agent_id")}</Col> */}
                {/* </Grid> */}
              </Row>
              {/* <Row>
                      <Grid columns='equal'>
                        <Col>{showField(this.props, "addr2_eng")}</Col>
                        <Col>{showField(this.props, "addr1_eng")}</Col>
                      </Grid>
                    </Row> */}
              {/* <Row>
                      <Grid columns='equal'>
                      </Grid>
                    </Row>
                    <br />
                    <br /> */}
            </CardBody>
          </Card>
          {/* </Grid> */}

          {/* <Row>
            <Grid columns='equal'>
              <Col>
                <Card fluid>
                  <Card.Content header='公司 & 財務' />
                  <Card.Content>
                    <Row>
                      <Grid columns='equal'>
                        <Col>{showField(this.props, "fin_com_name")}</Col>
                        <Col>{showField(this.props, "fin_com_name_eng")}</Col>
                        <Col>{showField(this.props, "fin_title")}</Col>
                      </Grid>
                    </Row>
                    <Row>
                      <Grid columns='equal'>
                        <Col>{showField(this.props, "fin_com_tele")}</Col>
                        <Col>{showField(this.props, "fin_com_fax")}</Col>
                        <Col>{showField(this.props, "fin_com_addrcht")}</Col>
                        <Col>{showField(this.props, "fin_com_addreng")}</Col>
                      </Grid>
                    </Row>
                    <Row>
                      <Grid columns='equal'>
                        <Col>{showField(this.props, "fin_invest_exp")}</Col>
                        <Col>{showField(this.props, "fin_invest_purpose")}</Col>
                        <Col>{showField(this.props, "fin_ps")}</Col>
                      </Grid>
                    </Row>
                    <Row>
                      <Grid columns='equal'>
                        <Col>{showField(this.props, "is_periodmail")}</Col>
                        <Col>{showField(this.props, "periodmail2")}</Col>
                        <Col>{showField(this.props, "periodmail3")}</Col>
                        <Col>{showField(this.props, "periodmail4")}</Col>
                        <Col>{showField(this.props, "periodmail5")}</Col>
                        <Col>{showField(this.props, "periodmail6")}</Col>
                        <Col>{showField(this.props, "periodmail7")}</Col>
                        <Col>{showField(this.props, "periodmail7")}</Col> */}
          {/* <Col>
                              <Form.Input label='電話' defaultValue={item.telTeacher || ''} name='telTeacher' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col>
                            <Col>
                              <Form.Input label='email' defaultValue={item.emailTeacher || ''} name='emailTeacher' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col>
                            <Col>
                              <Form.Input label='通勤時間' defaultValue={''} name='' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col> */}
          {/* </Grid>
                    </Row>
                    <Row>
                      <Grid columns='equal'> */}
          {/* <Col>
                              <Form.Input label='通勤交通費' defaultValue={''} name='' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col>
                            <Col>
                              <Form.Input label='開始学期' defaultValue={item.startSemester || ''} name='startSemester' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col>
                            <Col>
                              <Form.Input label='結束学期' defaultValue={item.endSemester || ''} name='endSemester' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col> */}
          {/* </Grid>
                    </Row>
                    <Row>
                      <Grid columns='equal'> */}
          {/* <Col>
                              <Form.Input label='住所' defaultValue={item.addrTeacher || ''} name='addrTeacher' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col> */}
          {/* </Grid>
                    </Row>
                  </Card.Content>
                </Card>
                <br />
                <br />
              </Col>
            </Grid>
          </Row> */}
        </Col>
      </Row>
      <Row>
        <Col>
          <Card outline color='secondary' fluid style={{}}>
            <a style={{ cursor: 'pointer' }} onClick={this.handleIsSpread2}>
              <CardHeader tag='h4' style={{ backgroundColor: '#f07b51' }}>
                附加檔案
                </CardHeader>
            </a>
            {isSpread2 ? <CardBody ><List4Employee {...this.props} /></CardBody> : null}
            {/* <Card.Content header='附加檔案' onClick={this.handleIsSpread2} style={{ backgroundColor: '#f07b51' }} /> */}
            {/* {isSpread2 ? <CardBody ><List4Employee {...this.props} /></CardBody> : null} */}
          </Card>
        </Col>
      </Row>
    </>
    )
  }
}