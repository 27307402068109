import React, { Component } from 'react'
import AnnualCalendar from '../widgets/AnnualCalendar';
import { Grid, Segment } from 'semantic-ui-react';
import { SyncLoader } from 'react-spinners';

export class Annual extends Component {
  state = {
    title: '',
    content: ''
  }

  render() {
    const { semesterYear, optSet, calendar, loadingCalendar } = this.props;

    return (
      <div className="container">
        <p style={{ textAlign: 'center' }} className="no-print">
          <img src="logo.png" style={{ maxHeight: '120px', maxWidth: '100%' }} alt="" />
        </p>

        <Segment basic>
          <h3 style={{ textAlign: 'center' }}>{semesterYear} 年度 行事予定管理帳</h3>
          {loadingCalendar ?
            <SyncLoader
              sizeUnit="px"
              size={18}
              color='#4A90E2'
            /> :
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <AnnualCalendar
                    {...this.props}
                    calendar={calendar}
                    semesterYear={semesterYear}
                    semester={"S"}
                    optSet={optSet}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <AnnualCalendar
                    {...this.props}
                    calendar={calendar}
                    semesterYear={semesterYear}
                    semester={"A"}
                    optSet={optSet}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>}
        </Segment>
      </div>
    )
  }
}

export default Annual