import React, { Component } from 'react'
import MaterialTable from 'material-table'

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

const objICPeriod = {
  "0": '不固定',
  "1": 'Half-yearly(6月底/12月底)',
  // "2":'Half-yearly(生效日)',
  "3": 'Yearly(12月底)',
  "4": 'Yearly(生效日)'
}

const objICPayDate = {
  "1": '預計1月/7月',
  "2": '預計1月',
  "3": '生效日'
};

const objICLeave = {
  "1": 'end term return',
  "2": 'buy back',
  "3": 'no buy back',
  "4": 'none'
};



const columns = () => ([
  { width: 50, title: '類別', field: 'class' },
  { width: 60, title: '配息條件', field: 'bornMonth' },
  { width: 100, title: '項目', field: 'item' },
  { width: 100, title: 'Policy No/Room No.', field: 'policyroomno' },
  { width: 60, title: '檢查機制(總投資額)', field: 'checkcondition' },
  { width: 160, title: '配息週期', field: 'interest_period', lookup: objICPeriod },
  { width: 50, title: '第1期 (%)', field: 'y1' },
  { width: 50, title: '第2期 (%)', field: 'y2' },
  { width: 50, title: '第3期 (%)', field: 'y3' },
  { width: 50, title: '第4期 (%)', field: 'y4' },
  { width: 50, title: '第5期 (%)', field: 'y5' },
  { width: 50, title: '第6期 (%)', field: 'y6' },
  { width: 50, title: '第7期 (%)', field: 'y7' },
  { width: 50, title: '第8期 (%)', field: 'y8' },
  { width: 50, title: '第9期 (%)', field: 'y9' },
  { width: 50, title: '第10期 (%)', field: 'y10' },
  { width: 50, title: '第11期 (%)', field: 'y11' },
  { width: 50, title: '第12期 (%)', field: 'y12' },
  { width: 50, title: '第13期 (%)', field: 'y13' },
  { width: 50, title: '第14期 (%)', field: 'y14' },
  { width: 50, title: '第15期 (%)', field: 'y15' },
  { width: 90, title: '利息配發日期 (月初)', field: 'intereststart_date', lookup: objICPayDate },
  { width: 90, title: '離場機制(參考備註)', field: 'leavecondition', lookup: objICLeave },
]);

export default class After7 extends Component {
  state = {
    loginUser: this.props.user.profile.toJSON(),
    productType2Opt: [{ key: 'productType20', text: '無', value: 'productType20' }],
    productType3Opt: [{ key: 'productType30', text: '無', value: 'productType30' }],
    productType4Opt: [{ key: 'productType40', text: '無', value: 'productType40' }],
    disabled: true,
    disabled2: true,
    disabled3: true,
    type1: '',
    type2: '',
    value1: 'productType10',
    value2: 'productType20',
    value3: 'productType30',
    value4: 'productType40',
    loading: true,
    dataArr: [],
  }

  componentDidMount() {
    const { loginUser } = this.state
    const { companyObj } = this.props
    const authPage = window.location.pathname.split('/')[2]
    const authUser = companyObj.objectId + '_' + authPage
    let auth = {}

    if ((loginUser.authAdminA && loginUser.authAdminA.indexOf(companyObj.objectId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
      auth = {
        edit: true,
      }
    } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
      auth = {
        edit: false,
      }
    } else {
      auth = {
        edit: false,
      }
    }
    this.setState({ auth }, () => this.handleFetch())
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async () => {
    const { companyObj } = this.props;
    const companyId = companyObj.objectId;

    const data = await Parse.queryData('interestcondition', { companyId });
    this.setState({ dataArr: data, loading: false })
  }

  handleAdd = async () => {
    const { newData } = this.state;
    let obj = {
      ...newData,
    }
    await Parse.saveData('interestcondition', obj);

    this.setState({ loading: false })
  }

  handleUpdate = async () => {
    const { newData } = this.state
    let obj = {
      ...newData,
    }
    await Parse.saveData('interestcondition', obj);
  }

  handleDelete = async () => {
    const { oldData } = this.state

    Parse.deleteData('interestcondition', oldData);
    this.setState({ oldData: {}, loading: false })
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  render() {
    const { companyObj } = this.props
    const { dataArr, loading, auth = {} } = this.state;

    const editable = {
      isEditHidden: () => !auth.edit,
      isDeleteHidden: () => !auth.edit,
      onRowAdd: newData => auth.edit ?
        new Promise(async (resolve, reject) => {
          const data = [...dataArr];
          const db = Parse.Object.extend("interestcondition");
          const newDoc = new db();
          const doc = await newDoc.save();
          newData.objectId = doc.id;
          newData.id = doc.id;
          newData._id = doc.id;
          newData.companyId = companyObj.objectId;
          newData.companyName = companyObj.name;

          data.push(newData);
          this.setState({ dataArr: data, newData, loading: true }, () => resolve());
        }).then(() => this.handleAdd(0, 0))
        : undefined,
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          const data = [...dataArr];
          const index = data.indexOf(oldData);
          data[index] = newData;
          this.setState({ dataArr: data, newData, oldData }, () => resolve());
        }).then(() => this.handleUpdate(0)),
      onRowDelete: oldData =>
        new Promise((resolve, reject) => {
          let data = [...dataArr];
          const index = data.indexOf(oldData);
          data.splice(index, 1);
          this.setState({ dataArr: data, oldData, loading: true }, () => resolve());
        }).then(() => this.handleDelete(0)),
    }
    if (auth.edit === false) {
      delete editable.onRowAdd
    }

    return (<div className="content">
      {/* <Container>
        <div style={{ ...style.flexCenter, margin: '2rem auto' }}>
          <Header as='h2' style={{ margin: 0 }}>2-6 配息條件【客戶配息】</Header>
          <div style={{ ...style.flex, alignItems: 'flex-end' }}>
            <Button content='寄案件追蹤給Agent' />
            <Form><Form.Select options={[]} label='Agent' /></Form>
          </div>
        </div>
        <br />
        <br />
      </Container> */}
      <div style={{ maxWidth: '100%', padding: '2%' }}>
        <MaterialTable
          isLoading={loading}
          localization={localization()}
          columns={columns()}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportAllData: true,
            exportPdf: (columns, data) => exportPdf(columns, data, '配息條件【客戶配息】'),
            pageSize: dataArr.length || 10,
            search: false,
            // doubleHorizontalScroll: true,
            tableLayout: 'fixed',
          }}
          data={dataArr}
          title="配息條件【客戶配息】"
          editable={editable}
        />
      </div>
    </div>)
  }
}