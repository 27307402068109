import React, { Component } from 'react';
import { Form, Button, Table, Grid, Header, Icon, Confirm, Select, Card } from 'semantic-ui-react'
import { showField, showFieldText } from 'views/widgets/FieldsRender';
import Parse from 'widget/parse.js'
import { Row, Col } from 'reactstrap'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const examTypeArr = [
  { key: 'examTypeArr0', value: '0', text: '実用日本語検定J-TEST' },
  { key: 'examTypeArr1', value: '1', text: '日本留学試験EJU' },
  { key: 'examTypeArr2', value: '2', text: '日本語能力試験JLPT' },
  { key: 'examTypeArr3', value: '3', text: '英語試験' },
]

const JLPTTypeArr = [
  { key: 'JLPT0', value: '0', text: '-- 選択 --' },
  { key: 'JLPT1', value: '1', text: 'N1' },
  { key: 'JLPT2', value: '2', text: 'N2' },
  { key: 'JLPT3', value: '3', text: 'N3' },
  { key: 'JLPT4', value: '4', text: 'N4' },
  { key: 'JLPT5', value: '5', text: 'N5' },
]
const JTESTTypeArr = [
  { key: 'JTEST0', value: '0', text: '-- 選択 --' },
  { key: 'JTEST1', value: '1', text: 'A-C' },
  { key: 'JTEST2', value: '2', text: 'D-E' },
]

const roundArr = [
  { key: 'first', value: '1', text: '第一回' },
  { key: 'second', value: '2', text: '第二回' },
]
const yearArr = [
  { key: '2019', value: '2019', text: '2019年度' },
  { key: '2020', value: '2020', text: '2020年度' },
  { key: '2021', value: '2021', text: '2021年度' },
  { key: '2022', value: '2022', text: '2022年度' },
]

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}

export default class ExamStudent extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      form: { testYear: (new Date().getFullYear() + ''), testRound: '1' },
      show: false,
      exam: [],
      EJU: [],
      JLPT: [],
      ENG: [],
      JTEST: [],
      confirm: false,
      loading: false,
      disabled: false,
      examType: '1',
      examTypeTitle: '日本留学試験結果',
      JLPTType: '1'
      // vocabularyScore: 0,
      // listeningScore: 0,
      // grammarScore: 0,
      // isPass: ''
    };
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, match } = props;
    const { _id } = match.params;
    const JTEST = await Parse.queryData('JTEST', { companyId: companyObj.objectId, studentId: _id });
    // firebase.firestore()
    //   .collection(`Students/${_id}/JTEST`)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ JTEST: data });
    //   }, err => { console.log(`Encountered error: ${err}`); });
    const EJU = await Parse.queryData('EJU', { companyId: companyObj.objectId, studentId: _id });
    // firebase.firestore()
    //   .collection(`Students/${_id}/EJU`)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ EJU: data });
    //   }, err => { console.log(`Encountered error: ${err}`); });
    const JLPT = await Parse.queryData('JLPT', { companyId: companyObj.objectId, studentId: _id });
    // firebase.firestore()
    //   .collection(`Students/${_id}/JLPT`)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ JLPT: data });
    //   }, err => { console.log(`Encountered error: ${err}`); });
    const ENG = await Parse.queryData('ENG', { companyId: companyObj.objectId, studentId: _id });
    // firebase.firestore()
    //   .collection(`Students/${_id}/ENG`)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ ENG: data });
    //   }, err => { console.log(`Encountered error: ${err}`); });
    this.setState({ JTEST, EJU, JLPT, ENG })
  }

  handleSubmit = async () => {
    const { companyObj, doc, semesterYear, semester } = this.props;
    const { form, exam, examType, examTypeTitle } = this.state;
    // const db = firebase.firestore();

    let dbName = 'EJU'
    if (examType === '3') {
      dbName = 'ENG'
    } else if (examType === '1') {
      form.jpTotalScore = (Number(form.jpListeningScore) + Number(form.jpReadingScore)) || ''
    } else if (examType === '2') {
      dbName = 'JLPT'
    } else if (examType === '0') {
      dbName = 'JTEST'
    }
    if (form.jtestLevel && ((!form.pictureScore || !form.listenReadScore || !form.answerScore || !form.conversationScore || !form.grammarScore || !form.readingScore || !form.kanjiScore || !form.writingScore)
      || (form.pictureScore === '' || form.listenReadScore === '' || form.answerScore === '' || form.conversationScore === '' || form.grammarScore === '' || form.readingScore === '' || form.kanjiScore === '' || form.writingScore === ''))) {
      this.showConfirmErr()
    } else {
      form.listenTotalScore = Number(form.pictureScore) + Number(form.listenReadScore) + Number(form.answerScore) + Number(form.conversationScore)
      form.readTotalScore = Number(form.grammarScore) + Number(form.readingScore) + Number(form.kanjiScore) + Number(form.writingScore)
      let totalScore = Number(form.listenTotalScore) + Number(form.readTotalScore)

      if (examType === '0') {
        form.totalScore = totalScore
        switch (form.jtestLevel) {
          case '1':
          default:
            form.jtestLevelText = 'A-C'
            if (totalScore) {
              if (form.totalScore >= 930) {
                form.levelScore = '1'
                form.levelScoreText = '特A級'
              } else if (form.totalScore >= 900) {
                form.levelScore = '2'
                form.levelScoreText = 'A級'
              } else if (form.totalScore >= 850) {
                form.levelScore = '3'
                form.levelScoreText = '準A級'
              } else if (form.totalScore >= 800) {
                form.levelScore = '4'
                form.levelScoreText = 'B級'
              } else if (form.totalScore >= 700) {
                form.levelScore = '5'
                form.levelScoreText = '準B級'
              } else if (form.totalScore >= 600) {
                form.levelScore = '6'
                form.levelScoreText = 'C級'
              } else if (form.totalScore < 600) {
                form.levelScore = '7'
                form.levelScoreText = '認定なし'
              }
            }
            break;
          case '2':
            form.jtestLevelText = 'D-E'
            if (totalScore) {
              if (form.totalScore >= 500) {
                form.levelScore = '8'
                form.levelScoreText = 'D級'
              } else if (form.totalScore >= 350) {
                form.levelScore = '9'
                form.levelScoreText = 'E級'
              } else if (form.totalScore < 350) {
                form.levelScore = '10'
                form.levelScoreText = '認定なし'
              }
            }
            break;
        }
      }
    }
    if (form.grade && (form.grade === '4' || form.grade === '5')
      && ((!form.vocabularyScore45 || !form.listeningScore)
        || (form.vocabularyScore45 === '' || form.listeningScore === ''))) {
      this.showConfirmErr()
    } else if (form.grade && (form.grade === '1' || form.grade === '2' || form.grade === '3')
      && ((!form.vocabularyScore || !form.grammarScore || !form.listeningScore)
        || (form.vocabularyScore === '' || form.grammarScore === '' || form.listeningScore === ''))) {
      this.showConfirmErr()
    } else {
      let totalScore = form.grade && (form.grade === '4' || form.grade === '5') ?
        Number(form.vocabularyScore45) + Number(form.listeningScore)
        : Number(form.vocabularyScore) + Number(form.listeningScore) + Number(form.grammarScore)

      if (examType === '2') {
        form.totalScore = totalScore
        switch (form.grade) {
          case '1':
          default:
            form.gradeText = 'N1'
            form.vocabularyScore45 = ''
            if (totalScore) {
              if (form.vocabularyScore >= 19 && form.listeningScore >= 19 && form.grammarScore >= 19 && form.totalScore >= 100) {
                form.isPass = '1'
                form.isPassText = '合格'
              } else {
                form.isPass = '0'
                form.isPassText = '不合格'
              }
            }
            break;
          case '2':
            form.gradeText = 'N2'
            form.vocabularyScore45 = ''
            if (totalScore) {
              if (form.vocabularyScore >= 19 && form.listeningScore >= 19 && form.grammarScore >= 19 && form.totalScore >= 90) {
                form.isPass = '1'
                form.isPassText = '合格'
              } else {
                form.isPass = '0'
                form.isPassText = '不合格'
              }
            }
            break;
          case '3':
            form.gradeText = 'N3'
            form.vocabularyScore45 = ''
            if (totalScore) {
              if (form.vocabularyScore >= 19 && form.listeningScore >= 19 && form.grammarScore >= 19 && form.totalScore >= 95) {
                form.isPass = '1'
                form.isPassText = '合格'
              } else {
                form.isPass = '0'
                form.isPassText = '不合格'
              }
            }
            break;
          case '4':
            form.gradeText = 'N4'
            form.vocabularyScore = ''
            form.grammarScore = ''
            if (totalScore) {
              if (form.vocabularyScore45 >= 38 && form.listeningScore >= 19 && form.totalScore >= 90) {
                form.isPass = '1'
                form.isPassText = '合格'
              } else {
                form.isPass = '0'
                form.isPassText = '不合格'
              }
            }
            break;
          case '5':
            form.gradeText = 'N5'
            form.vocabularyScore = ''
            form.grammarScore = ''
            if (totalScore) {
              if (form.vocabularyScore45 >= 38 && form.listeningScore >= 19 && form.totalScore >= 80) {
                form.isPass = '1'
                form.isPassText = '合格'
              } else {
                form.isPass = '0'
                form.isPassText = '不合格'
              }
            }
            break;
        }
      }
      this.setState({ loading: true });
      const newExam = [...exam];
      if (!form.objectId) {
        // const docRef = db.collection(`Students/${doc._id}/${dbName}`).doc();
        const db = Parse.Object.extend(dbName);
        const newDoc = new db();
        const docRef = await newDoc.save();
        const obj = {
          ...form,
          objectId: docRef.id,
          studentId: doc.objectId,
          studentIdKey: doc.studentId,
          studentName: doc.jpnName,
          semesterYear,
          semester,
          createdAt: new Date(),
          examType,
          examTypeTitle,
          classSchool: doc.classSchool,
          companyId: companyObj.objectId,
          companyName: companyObj.name,
        };
        // await docRef.set(obj);
        await Parse.saveData(dbName, obj)
        newExam.push(obj);
      } else {
        // await db.collection(`Students/${doc._id}/${dbName}`).doc(form._id).update(form);
        await Parse.saveData(dbName, form)
        let index = newExam.findIndex((item => item.objectId === form.objectId));
        newExam[index] = form;
      }

      this.setState({ exam: newExam, loading: false });
      this.handleClose();
      this.handleFetch();
    }
  }

  handleChange = (event, data) => {
    const { name, value } = data

    if (name === 'examType') {
      if (value === '1') {
        this.setState({ examType: value, examTypeTitle: '日本留学試験' })
      } else if (value === '2') {
        this.setState({ examType: value, examTypeTitle: '日本語能力試験' })
      } else if (value === '3') {
        this.setState({ examType: value, examTypeTitle: '英語試験' })
      } else if (value === '0') {
        this.setState({ examType: value, examTypeTitle: '実用日本語検定' })
      }
    } else if (name === 'grade') {
      this.setState({
        JLPTType: value,
        form: {
          ...this.state.form,
          [name]: value
        }
      })
    } else {
      this.setState({
        form: {
          ...this.state.form,
          [name]: value
        }
      })
    }
  }

  handleOpen = () => {
    this.setState({ show: true })
  }

  handleEdit = (examObj) => {
    this.setState({
      examTypeTitle: examObj.examTypeTitle,
      examType: examObj.examType,
      show: true,
      form: { ...examObj }
    });
  }

  handleClose = () => {
    this.setState({ show: false, form: {}, readOnly: false, fileObj: {}, examType: '1' });
  }

  handleRemove = async () => {
    const { firebase, doc } = this.props
    const { form, JTEST, EJU, JLPT, ENG } = this.state;
    let dbName = 'EJU'
    if (form.examType === '2') {
      dbName = 'JLPT'
    } else if (form.examType === '3') {
      dbName = 'ENG'
    } else if (form.examType === '0') {
      dbName = 'JTEST'
    }
    console.log(dbName, form)
    // await firebase.firestore().collection(`Students/${doc._id}/${dbName}`).doc(form._id).delete();
    await Parse.deleteData(dbName, form)

    if (form.examType === '1') {
      const newEJU = [...EJU];
      let index = newEJU.findIndex((item => item._id === form._id));
      newEJU.splice(index, 1);
      this.setState({ EJU: newEJU });
    } else if (form.examType === '2') {
      const newJLPT = [...JLPT];
      let index = newJLPT.findIndex((item => item._id === form._id));
      newJLPT.splice(index, 1);
    } else if (form.examType === '3') {
      const newENG = [...ENG];
      let index = newENG.findIndex((item => item._id === form._id));
      newENG.splice(index, 1);
    } else if (form.examType === '0') {
      const newJTEST = [...JTEST];
      let index = newJTEST.findIndex((item => item._id === form._id));
      newJTEST.splice(index, 1);
    }

    this.handleClose();
    this.hideConfirm();
    this.handleFetch();
  }

  showConfirm = (examObj) => {
    this.setState({ confirm: true, form: { ...examObj } });
  }
  hideConfirm = () => this.setState({ confirm: false, form: {} });

  renderFrom = (examType) => {
    const { form, readOnly, JLPTType } = this.state
    switch (examType) {

      case '2':
        return (<>
          <Row columns='equal'>
            <Col>
              <Form.Select
                label='試験年'
                options={yearArr}
                name='testYear'
                value={form.testYear || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Form.Select
                label='試験回'
                options={roundArr}
                name='testRound'
                value={form.testRound || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row columns='equal'>
            <Col>
              <Form.Select
                label='受験級'
                options={JLPTTypeArr}
                name='grade'
                value={form.grade || JLPTType}
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            {form.grade === '4' || form.grade === '5' ?
              <Col>
                <Form.Input
                  label='言語知識（文字・語彙・文法）・読解'
                  type='number'
                  name='vocabularyScore45'
                  value={form.vocabularyScore45 || ''}
                  onChange={this.handleChange}
                  readOnly={readOnly}
                  placeholder='0～120点'
                  max='60'
                  min='0'
                />
              </Col> : <>
                <Col>
                  <Form.Input
                    label='言語知識（文字・語彙・文法）'
                    type='number'
                    name='vocabularyScore'
                    value={form.vocabularyScore || ''}
                    onChange={this.handleChange}
                    readOnly={readOnly}
                    placeholder='0～60点'
                    max='60'
                    min='0'
                  />
                </Col>
                <Col>
                  <Form.Input
                    label='言語知識（読解）'
                    type='number'
                    name='grammarScore'
                    value={form.grammarScore || ''}
                    onChange={this.handleChange}
                    readOnly={readOnly}
                    placeholder='0～60点'
                    max='60'
                    min='0'
                  />
                </Col></>}
            <Col>
              <Form.Input
                label='聴解'
                type='number'
                name='listeningScore'
                value={form.listeningScore || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
                placeholder='0～60点'
                max='60'
                min='0'
              />
            </Col>
          </Row>
        </>)
      case '3':
        return (<>
          <Row columns='equal'>
            <Col>
              <Form.Input
                label='試験年月日'
                type='date'
                name='testDate'
                value={form.testDate || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Form.Input
                label='TOEIC'
                name='TOEIC'
                type='number'
                value={form.TOEIC || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Form.Input
                label='TOEFL'
                name='TOEFL'
                type='number'
                value={form.TOEFL || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </>)
      case '1':
        return (<>
          <Row columns='equal'>
            <Col>
              <Form.Select
                label='試験年'
                options={yearArr}
                name='testYear'
                value={form.testYear || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Form.Select
                label='試験回'
                options={roundArr}
                name='testRound'
                value={form.testRound || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            {/* <Form.Input
                label='テストコード'
                name='testCode'
                value={form.testCode || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
              /> */}
          </Row>
          <Row columns='equal'>
            <Col>
              <Form.Input
                label='聴解(読解)'
                name='jpListeningScore'
                type='number'
                value={form.jpListeningScore || ''}
                placeholder='0～120点'
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Form.Input
                label='読解'
                name='jpReadingScore'
                type='number'
                value={form.jpReadingScore || ''}
                placeholder='0～160点'
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Form.Input
                label='日本語合計点'
                name='jpTotalScore'
                type='number'
                placeholder='0～400点'
                value={(Number(form.jpListeningScore) + Number(form.jpReadingScore)) || ''}
                onChange={this.handleChange}
                readOnly={true}
              />
            </Col>
            <Col>
              <Form.Input
                label='記述'
                name='totalDescription'
                type='number'
                value={form.totalDescription || ''}
                placeholder='0～50点'
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row columns='equal'>
            <Col>
              <Form.Input
                label='総合科目'
                name='generalScore'
                type='number'
                placeholder='0～200点'
                value={form.generalScore || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Form.Input
                label='数学 I'
                name='math1Score'
                type='number'
                value={form.math1Score || ''}
                placeholder='0～200点'
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            <Col>
            </Col>
            <Col>
            </Col>
          </Row>
          <Row columns='equal'>
            <Col>
              <Form.Input
                label='数学 II'
                name='math2Score'
                type='number'
                value={form.math2Score || ''}
                placeholder='0～200点'
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Form.Input
                label='化学'
                name='chemistryScore'
                type='number'
                value={form.chemistryScore || ''}
                placeholder='0～100点'
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Form.Input
                label='物理'
                name='physicsScore'
                type='number'
                value={form.physicsScore || ''}
                placeholder='0～100点'
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Form.Input
                label='生物'
                name='biologyScore'
                type='number'
                value={form.biologyScore || ''}
                placeholder='0～100点'
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </>)
      case '0':
        return (<>
          <Row columns='equal'>
            <Col>
              <Form.Input
                label='試験年月'
                name='testYear'
                value={form.testYear || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
                type="month"
              />
            </Col>
            <Col>
              <Form.Select
                label='受験級'
                options={JTESTTypeArr}
                name='jtestLevel'
                value={form.jtestLevel || '0'}
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            {/* <Form.Input
                label='テストコード'
                name='testCode'
                value={form.testCode || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
              /> */}
          </Row>
          <Row columns='equal'>
            <Col>
              <Form.Input
                label='写真'
                name='pictureScore'
                type='number'
                placeholder={form.jtestLevel === '1' ? '0～50点' : form.testLevel === '2' ? '0～30点' : ''}
                value={form.pictureScore || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Form.Input
                label='聴読解'
                name='listenReadScore'
                type='number'
                placeholder={form.jtestLevel === '1' ? '0～150点' : form.testLevel === '2' ? '0～60点' : ''}
                value={form.listenReadScore || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Form.Input
                label='応答'
                name='answerScore'
                type='number'
                placeholder={form.jtestLevel === '1' ? '0～150点' : form.testLevel === '2' ? '0～160点' : ''}
                value={form.answerScore || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Form.Input
                label='会話・說明'
                name='conversationScore'
                type='number'
                placeholder={form.jtestLevel === '1' ? '0～150点' : form.testLevel === '2' ? '0～100点' : ''}
                value={form.conversationScore || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Form.Input
                label='聴読解合計'
                name='listenTotalScore'
                type='number'
                value={(Number(form.pictureScore) + Number(form.listenReadScore) + Number(form.answerScore) + Number(form.conversationScore)) || ''}
                onChange={this.handleChange}
                readOnly={true}
              />
            </Col>
          </Row>
          <Row columns='equal'>
            <Col>
              <Form.Input
                label='文法語彙'
                name='grammarScore'
                type='number'
                placeholder={form.jtestLevel === '1' ? '0～200点' : form.testLevel === '2' ? '0～150点' : ''}
                value={form.grammarScore || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Form.Input
                label='読解'
                name='readingScore'
                type='number'
                placeholder={form.jtestLevel === '1' ? '0～120点' : form.testLevel === '2' ? '0～80点' : ''}
                value={form.readingScore || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Form.Input
                label='漢字'
                name='kanjiScore'
                type='number'
                placeholder={form.jtestLevel === '1' ? '0～120点' : form.testLevel === '2' ? '0～80点' : ''}
                value={form.kanjiScore || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Form.Input
                label='記述'
                name='writingScore'
                type='number'
                placeholder={form.jtestLevel === '1' ? '0～60点' : form.testLevel === '2' ? '0～40点' : ''}
                value={form.writingScore || ''}
                onChange={this.handleChange}
                readOnly={readOnly}
              />
            </Col>
            <Col>
              <Form.Input
                label='読解、記述合計'
                name='readTotalScore'
                type='number'
                value={(Number(form.grammarScore) + Number(form.readingScore) + Number(form.kanjiScore) + Number(form.writingScore)) || ''}
                onChange={this.handleChange}
                readOnly={true}
              />
            </Col>
          </Row>
        </>)
      default:
    }
  }

  showConfirmErr = () => this.setState({ confirmErr: true });
  hideConfirmErr = () => {
    // const { attendDay, absentDay } = this.state
    this.setState({ confirmErr: false })
    // if (attendDay === '' && absentDay === '') {
    //   this.setState({ error1: true, error2: true })
    // } else if (attendDay === '') {
    //   this.setState({ error1: true })
    // } else if (absentDay === '') {
    //   this.setState({ error2: true })
    // }
  };

  render() {
    const { isEdit, doc } = this.props
    const { exam, form, disabled, readOnly, loading, show, confirm, examType, examTypeTitle, EJU, JLPT, ENG, JTEST } = this.state
    return (<>
      <Header as='h2' dividing
        content='校外試験成績管理'
        style={{ backgroundColor: 'lavender' }}
      // subheader='調査時間：2017年4月から 2018年12月 まで'
      />
      <Button disabled={!isEdit} inverted color='blue' onClick={this.handleOpen} icon='add' content='追加' />
      <br />
      <br />
      <Row>
        {/* <Grid columns='equal' style={{ marginTop: 5 }}> */}
        <Col>
          <Card fluid>
            <Card.Content header='実用日本語検定(J-TEST)' />
            <Card.Content>
              <Table celled structured>
                <Table.Header>
                  <Table.Row>
                    {isEdit ? <Table.HeaderCell style={{ width: 100 }} rowSpan='2'>#</Table.HeaderCell> : null}
                    <Table.HeaderCell rowSpan='2'>試験年月</Table.HeaderCell>
                    <Table.HeaderCell rowSpan='2'>受験級</Table.HeaderCell>
                    <Table.HeaderCell colSpan='5'>聴読解試験</Table.HeaderCell>
                    <Table.HeaderCell colSpan='5'>読解、記述試験</Table.HeaderCell>
                    <Table.HeaderCell rowSpan='2'>総合点</Table.HeaderCell>
                    <Table.HeaderCell rowSpan='2'>級</Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell>写真問題</Table.HeaderCell>
                    <Table.HeaderCell>聴読解問題</Table.HeaderCell>
                    <Table.HeaderCell>応答問題</Table.HeaderCell>
                    <Table.HeaderCell>会話・說明問題</Table.HeaderCell>
                    <Table.HeaderCell>聴読解合計</Table.HeaderCell>
                    <Table.HeaderCell>文法語彙問題</Table.HeaderCell>
                    <Table.HeaderCell>読解問題</Table.HeaderCell>
                    <Table.HeaderCell>漢字問題</Table.HeaderCell>
                    <Table.HeaderCell>記述問題</Table.HeaderCell>
                    <Table.HeaderCell>読解、記述合計</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {JTEST.length ? JTEST.sort((a, b) => a.testRound - b.testRound)
                    .sort((a, b) => a.testYear - b.testYear).map((item, index) => (<React.Fragment key={index}>
                      <Table.Row>
                        {isEdit ? <Table.Cell style={{ padding: 10 }}>
                          <Button inverted color='green' size='small' onClick={() => this.handleEdit(item)} icon='edit' />
                          <Button inverted color='red' size='small' onClick={() => this.showConfirm(item)} icon='delete' />
                        </Table.Cell> : null}
                        <Table.Cell>{item.testYear}</Table.Cell>
                        <Table.Cell>{item.jtestLevelText}</Table.Cell>
                        <Table.Cell>{item.pictureScore + (item.jtestLevel === '1' ? ' /50点' : item.jtestLevel === '2' ? ' /30点' : '')}</Table.Cell>
                        <Table.Cell>{item.listenReadScore + (item.jtestLevel === '1' ? ' /150点' : item.jtestLevel === '2' ? ' /60点' : '')}</Table.Cell>
                        <Table.Cell>{item.answerScore + (item.jtestLevel === '1' ? ' /150点' : item.jtestLevel === '2' ? ' /160点' : '')}</Table.Cell>
                        <Table.Cell>{item.conversationScore + (item.jtestLevel === '1' ? ' /150点' : item.jtestLevel === '2' ? ' /100点' : '')}</Table.Cell>
                        <Table.Cell>{item.listenTotalScore + (item.jtestLevel === '1' ? ' /500点' : item.jtestLevel === '2' ? ' /350点' : '')}</Table.Cell>
                        <Table.Cell>{item.grammarScore + (item.jtestLevel === '1' ? ' /200点' : item.jtestLevel === '2' ? ' /150点' : '')}</Table.Cell>
                        <Table.Cell>{item.readingScore + (item.jtestLevel === '1' ? ' /120点' : item.jtestLevel === '2' ? ' /80点' : '')}</Table.Cell>
                        <Table.Cell>{item.kanjiScore + (item.jtestLevel === '1' ? ' /120点' : item.jtestLevel === '2' ? ' /80点' : '')}</Table.Cell>
                        <Table.Cell>{item.writingScore + (item.jtestLevel === '1' ? ' /60点' : item.jtestLevel === '2' ? ' /40点' : '')}</Table.Cell>
                        <Table.Cell>{item.readTotalScore + (item.jtestLevel === '1' ? ' /500点' : item.jtestLevel === '2' ? ' /350点' : '')}</Table.Cell>
                        <Table.Cell>{item.totalScore + (item.jtestLevel === '1' ? ' /1000点' : item.jtestLevel === '2' ? ' /700点' : '')}</Table.Cell>
                        <Table.Cell>{item.levelScoreText}</Table.Cell>
                      </Table.Row>
                    </React.Fragment>)) : <Table.Row><Table.Cell colSpan={isEdit ? 15 : 14} textAlign='center'>(資料無し)</Table.Cell></Table.Row>}
                </Table.Body>
              </Table>
            </Card.Content>
          </Card>
        </Col>
        {/* </Grid> */}
      </Row>
      <br />
      <Row>
        {/* <Grid columns='equal' style={{ marginTop: 5 }}> */}
        <Col>
          <Card fluid>
            <Card.Content header='日本留学試験(EJU)' />
            <Card.Content>
              <Table celled structured>
                <Table.Header>
                  <Table.Row>
                    {isEdit ? <Table.HeaderCell style={{ width: 100 }} rowSpan='2'>#</Table.HeaderCell> : null}
                    <Table.HeaderCell rowSpan='2'>試験年・回</Table.HeaderCell>
                    {/* <Table.HeaderCell rowSpan='2'>試験年月日</Table.HeaderCell> */}
                    <Table.HeaderCell rowSpan='2'>聴解 /120点</Table.HeaderCell>
                    <Table.HeaderCell rowSpan='2'>聴読解 /120点</Table.HeaderCell>
                    <Table.HeaderCell rowSpan='2'>読解 /160点</Table.HeaderCell>
                    <Table.HeaderCell rowSpan='2'>記述 /50点</Table.HeaderCell>
                    <Table.HeaderCell rowSpan='2'>日本語合計点 /450点</Table.HeaderCell>
                    <Table.HeaderCell colSpan='2'>文系</Table.HeaderCell>
                    <Table.HeaderCell colSpan='4'>理系</Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell>総合科目 /200点</Table.HeaderCell>
                    <Table.HeaderCell>数学 I /200点</Table.HeaderCell>
                    <Table.HeaderCell>数学 II /200点</Table.HeaderCell>
                    <Table.HeaderCell>化学 /100点</Table.HeaderCell>
                    <Table.HeaderCell>物理 /100点</Table.HeaderCell>
                    <Table.HeaderCell>生物 /100点</Table.HeaderCell>
                    {/* <Table.HeaderCell>文法</Table.HeaderCell> //old
                      <Table.HeaderCell>論理</Table.HeaderCell>
                      <Table.HeaderCell>記述</Table.HeaderCell> 
                      <Table.HeaderCell>物理</Table.HeaderCell>
                      <Table.HeaderCell>生物</Table.HeaderCell>
                      <Table.HeaderCell>化学</Table.HeaderCell>
                      <Table.HeaderCell>総合科目</Table.HeaderCell>
                      <Table.HeaderCell>数学 I</Table.HeaderCell>
                      <Table.HeaderCell>数学 II</Table.HeaderCell>*/}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {EJU.length ? EJU.sort((a, b) => a.testRound - b.testRound)
                    .sort((a, b) => a.testYear - b.testYear).map((item, index) => (<React.Fragment key={index}>
                      <Table.Row>
                        {isEdit ? <Table.Cell style={{ padding: 10 }}>
                          <Button inverted color='green' size='small' onClick={() => this.handleEdit(item)} icon='edit' />
                          <Button inverted color='red' size='small' onClick={() => this.showConfirm(item)} icon='delete' />
                        </Table.Cell> : null}
                        <Table.Cell>{item.testYear && (item.testYear + '年度' + (item.testRound && roundArr.find(round => round.value === item.testRound).text))}</Table.Cell>
                        {/* <Table.Cell>{item.testDate}</Table.Cell> */}
                        <Table.Cell>{item.jpListeningScore}</Table.Cell>
                        <Table.Cell>{item.jpListenReadScore}</Table.Cell>
                        <Table.Cell>{item.jpReadingScore}</Table.Cell>
                        <Table.Cell>{item.totalDescription}</Table.Cell>
                        <Table.Cell>{item.jpTotalScore}</Table.Cell>
                        <Table.Cell>{item.generalScore}</Table.Cell>
                        <Table.Cell>{item.math1Score}</Table.Cell>
                        <Table.Cell>{item.math2Score}</Table.Cell>
                        <Table.Cell>{item.chemistryScore}</Table.Cell>
                        <Table.Cell>{item.physicsScore}</Table.Cell>
                        <Table.Cell>{item.biologyScore}</Table.Cell>
                        {/* <Table.Cell>{item.jpGrammarScore}</Table.Cell> //old
                        <Table.Cell>{item.logicScore}</Table.Cell>
                        <Table.Cell>{item.totalDescription}</Table.Cell>
                        <Table.Cell>{item.physicsScore}</Table.Cell>
                        <Table.Cell>{item.biologyScore}</Table.Cell>
                        <Table.Cell>{item.chemistryScore}</Table.Cell>
                        <Table.Cell>{item.generalScore}</Table.Cell>
                        <Table.Cell>{item.math1Score}</Table.Cell>
                        <Table.Cell>{item.math2Score}</Table.Cell> */}
                      </Table.Row>
                    </React.Fragment>)) : <Table.Row><Table.Cell colSpan={isEdit ? 14 : 13} textAlign='center'>(資料無し)</Table.Cell></Table.Row>}
                </Table.Body>
              </Table>
            </Card.Content>
          </Card>
        </Col>
        {/* </Grid> */}
      </Row>
      <br />
      <Row>
        {/* <Grid columns='equal'> */}
        <Col>
          <Card fluid>
            <Card.Content header='日本語能力試験(JLPT)' />
            <Card.Content>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    {isEdit ? <Table.HeaderCell style={{ width: 100 }}>#</Table.HeaderCell> : null}
                    <Table.HeaderCell>試験年・回</Table.HeaderCell>
                    {/* <Table.HeaderCell>試験年月日</Table.HeaderCell> */}
                    <Table.HeaderCell>受験級</Table.HeaderCell>
                    <Table.HeaderCell>言語知識（文字・語彙・文法） /60点</Table.HeaderCell>
                    <Table.HeaderCell>言語知識（読解） /60点</Table.HeaderCell>
                    {/* <Table.HeaderCell>言語知識（文字・語彙）</Table.HeaderCell> //old
                      <Table.HeaderCell>言語知識（文法・読解）</Table.HeaderCell> */}
                    <Table.HeaderCell>聴解 /60点</Table.HeaderCell>
                    <Table.HeaderCell>総合得点 /180点</Table.HeaderCell>
                    <Table.HeaderCell>合否</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {JLPT.length ? JLPT
                    // .sort((a, b) => a.testRound - b.testRound)
                    //   .sort((a, b) => a.testYear - b.testYear)
                    .map((item, index) => (<React.Fragment key={index}>
                      <Table.Row >
                        {isEdit ? <Table.Cell style={{ padding: 10 }}>
                          <Button inverted color='green' size='small' onClick={() => this.handleEdit(item)} icon='edit' />
                          <Button inverted color='red' size='small' onClick={() => this.showConfirm(item)} icon='delete' />
                        </Table.Cell> : null}
                        <Table.Cell>{item.testYear && (item.testYear + '年度' + (item.testRound && roundArr.find(round => round.value === item.testRound).text))}</Table.Cell>
                        {/* <Table.Cell>{item.testDate}</Table.Cell> */}
                        <Table.Cell>{item.gradeText}</Table.Cell>
                        {/* <Table.Cell>{item.vocNGraScore}</Table.Cell>
                        <Table.Cell>{item.readingScore}</Table.Cell> */}
                        {item.grade === '4' || item.grade === '5' ?
                          <Table.Cell colSpan='2' error={item.vocabularyScore45 < 38}>{item.vocabularyScore45} / 120</Table.Cell> : <>
                            <Table.Cell error={item.vocabularyScore < 19}>{item.vocabularyScore} / 60</Table.Cell>
                            <Table.Cell error={item.grammarScore < 19}>{item.grammarScore} / 60</Table.Cell></>}
                        <Table.Cell error={item.listeningScore < 19}>{item.listeningScore} / 60</Table.Cell>
                        <Table.Cell error={(item.grade === '1' && item.totalScore < 100) ||
                          (item.grade === '2' && item.totalScore < 90) ||
                          (item.grade === '3' && item.totalScore < 95) ||
                          (item.grade === '4' && item.totalScore < 90) ||
                          (item.grade === '5' && item.totalScore < 80)}>{item.totalScore || ''} / 180</Table.Cell>
                        <Table.Cell>{item.isPassText}</Table.Cell>
                      </Table.Row>
                    </React.Fragment>)) : <Table.Row><Table.Cell colSpan={isEdit ? 7 : 6} textAlign='center'>(資料無し)</Table.Cell></Table.Row>}
                </Table.Body>
              </Table>
            </Card.Content>
          </Card>
        </Col>
        {/* </Grid> */}
      </Row>
      <br />
      <Row>
        {/* <Grid columns='equal'> */}
        <Col>
          <Card fluid>
            <Card.Content header='英語試験' />
            <Card.Content>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    {isEdit ? <Table.HeaderCell style={{ width: 100 }}>#</Table.HeaderCell> : null}
                    <Table.HeaderCell>試験年月日</Table.HeaderCell>
                    <Table.HeaderCell>TOEIC</Table.HeaderCell>
                    <Table.HeaderCell>TOEFL</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {ENG.length ? ENG.map((item, index) => (<React.Fragment key={index}>
                    <Table.Row>
                      {isEdit ? <Table.Cell style={{ padding: 10 }}>
                        <Button inverted color='green' size='small' onClick={() => this.handleEdit(item)} icon='edit' />
                        <Button inverted color='red' size='small' onClick={() => this.showConfirm(item)} icon='delete' />
                      </Table.Cell> : null}
                      <Table.Cell>{item.testDate}</Table.Cell>
                      <Table.Cell>{item.TOEIC}</Table.Cell>
                      <Table.Cell>{item.TOEFL}</Table.Cell>
                    </Table.Row>
                  </React.Fragment>)) : <Table.Row><Table.Cell colSpan={isEdit ? 3 : 2} textAlign='center'>(資料無し)</Table.Cell></Table.Row>}
                </Table.Body>
              </Table>
              {/* <Modal open={show} onClose={this.handleClose}/* size='small'> */}

              <Modal
                isOpen={show}
                toggle={() => this.setState({ confirmClose: true })}
                // size="sm"
                className="mh-80 h-80 mw-80 w-80"
                style={{ paddingTop: '0px' }}
                scrollable={true}
              >
                <ModalHeader style={style.flexCenter}>
                  <h2 style={{ margin: 0 }}> {form.objectId ? '修正' : '追加'}記録</h2>
                  <Icon name='close' onClick={() => this.setState({ confirmClose: true })} style={{ cursor: 'pointer', margin: 0 }}></Icon>
                </ModalHeader>
                <ModalBody>
                  <Form>
                    <Grid>
                      <Row columns='equal'>
                        <Col>
                          <h2>{examTypeTitle}</h2>
                        </Col>
                        <Col>
                          {!form.objectId ? <Form.Select
                            options={examTypeArr}
                            defaultValue='1'
                            name='examType'
                            onChange={this.handleChange}
                          // disabled={form._id}
                          /> : null}
                        </Col>
                      </Row>
                      <Row columns='equal'>
                        <Col>
                          <Form.Input
                            label='氏名'
                            name='jpnName'
                            value={doc.jpnName || ''}
                            onChange={this.handleChange}
                            readOnly={true}
                          />
                        </Col>
                        <Col>
                          <Form.Input
                            label='学籍番号'
                            name='studentId'
                            value={doc.studentId || ''}
                            onChange={this.handleChange}
                            readOnly={true}
                          />
                        </Col>
                        {/* <Col>
                            <Form.Input
                              label='試験年月日'
                              type='date'
                              name='testDate'
                              value={form.testDate || ''}
                              onChange={this.handleChange}
                              readOnly={readOnly}
                            />
                          </Col> */}
                      </Row>
                      {this.renderFrom(examType)}
                    </Grid>
                  </Form>
                </ModalBody>
                <ModalFooter style={{ ...style.flex, justifyContent: 'flex-end', padding: '10px' }}>
                  <Button onClick={() => this.setState({ confirmSubmit: true })} inverted color='green' loading={loading} icon='add' content={form.objectId ? '保存' : '追加'} />
                  <Button onClick={() => this.setState({ confirmClose: true })} inverted color='red' icon='delete' content='閉じる' />
                </ModalFooter>
              </Modal>

              <Modal
                isOpen={this.state.confirmClose}
                toggle={() => this.setState({ confirmClose: false })}
                size="lg"
                className="mh-100 h-90 mw-100 w-90"
              >
                <ModalHeader>確認閉じる</ModalHeader>
                <ModalBody>
                  データは保存されません、確認閉じる？
        </ModalBody>
                <ModalFooter style={{ padding: '16px' }}>
                  <Button onClick={() => this.setState({ confirmClose: false }, this.handleClose)} inverted color='red' icon='delete' content='はい' />
                  <Button onClick={() => this.setState({ confirmClose: false })} content='いいえ' />
                </ModalFooter>
              </Modal>
              {/* <Confirm
                content='データは保存されません、確認閉じる？'
                cancelButton='いいえ'
                confirmButton='はい'
                open={this.state.confirmClose}
                onCancel={() => this.setState({ confirmClose: false })}
                onConfirm={() => this.setState({ confirmClose: false }, this.handleClose)}
                size='mini'
                centered={false} /> */}
              <Modal
                isOpen={this.state.confirmSubmit}
                toggle={() => this.setState({ confirmSubmit: false })}
                size="lg"
                className="mh-100 h-90 mw-100 w-90"
              >
                <ModalHeader>保存</ModalHeader>
                <ModalBody>
                  確認保存?
        </ModalBody>
                <ModalFooter style={{ padding: '16px' }}>
                  <Button onClick={() => this.setState({ confirmSubmit: false }, this.handleSubmit)} inverted color='green' icon='checkmark' content='はい' />
                  <Button onClick={() => this.setState({ confirmSubmit: false })} content='いいえ' />
                </ModalFooter>
              </Modal>
              {/* <Confirm
                content='確認保存?'
                cancelButton='いいえ'
                confirmButton='はい'
                open={this.state.confirmSubmit}
                onCancel={() => this.setState({ confirmSubmit: false })}
                onConfirm={() => this.setState({ confirmSubmit: false }, this.handleSubmit)}
                size='mini'
                centered={false} /> */}
              <Modal
                isOpen={this.state.confirm}
                toggle={this.hideConfirm}
                size="lg"
                className="mh-100 h-90 mw-100 w-90"
              >
                <ModalHeader>削除</ModalHeader>
                <ModalBody>
                  削除してもいいですか？
        </ModalBody>
                <ModalFooter style={{ padding: '16px' }}>
                  <Button onClick={this.handleRemove} inverted color='red' icon='delete' content='はい' />
                  <Button onClick={this.hideConfirm} content='いいえ' />
                </ModalFooter>
              </Modal>
              {/* <Confirm
                header='削除'
                content='削除してもいいですか?'
                cancelButton='いいえ'
                confirmButton='はい'
                open={this.state.confirm}
                onCancel={this.hideConfirm}
                onConfirm={this.handleRemove}
                size='mini'
                centered={false} /> */}
            </Card.Content>
          </Card>
        </Col>
        {/* </Grid> */}
      </Row>
      <Modal
        isOpen={this.state.confirmErr}
        toggle={this.hideConfirm}
        size="lg"
        className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader>点數欄位不得為空</ModalHeader>
        <ModalBody>
          点數欄位不得為空
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button onClick={this.hideConfirmErr} inverted color='red' content='閉じる' />
          {/* <Button onClick={this.hideConfirm} content='いいえ' /> */}
        </ModalFooter>
      </Modal>
      {/* <Confirm
        header='点數欄位不得為空'
        content='点數欄位不得為空'
        cancelButton={null}
        confirmButton='閉じる'
        open={this.state.confirmErr}
        // onCancel={this.hideConfirm}
        onConfirm={this.hideConfirmErr}
        size='mini'
        centered={false} /> */}
    </>);
  }
}