import React, { Component } from 'react';
import { Container, Card, Grid, Header } from 'semantic-ui-react';
import { showField, showFieldText, getAvailblePeriodArr, getDate } from 'views/widgets/FieldsRender';
import { Row, Col } from "reactstrap";

export default class StudyStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <>
        <Header as='h2' dividing
          content='学習歴'
          style={{ backgroundColor: 'lavender' }}
        // subheader='調査時間：2017年4月から 2018年12月 まで'
        />

        {/* <Grid columns='equal'> */}
        <Row>
          <Col sm='6'>
            <Card fluid>
              <Card.Content header='学習歴' className='card-title' />
              <Card.Content>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col>{showField(this.props, "finEduGra")}</Col>
                  {/* </Grid> */}
                </Row>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col sm={2}>最終学歷</Col>
                  <Col>{showField(this.props, "finEduEnrol")}</Col>
                  <Col>{showField(this.props, "schoDis")}</Col>
                  {/* </Grid> */}
                </Row>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col sm={2}></Col>
                  <Col>{showField(this.props, "graSchoolName")}</Col>
                  <Col>{showField(this.props, "graDate")}</Col>
                  {/* </Grid> */}
                </Row>
              </Card.Content>
            </Card>
          </Col>
          {/* <Col> */}
          {/* <Card fluid>
              <Card.Content>
                <Row>
                  <Grid columns='equal'>
                    <Col >{showField(this.props, "hasJlptCertificate")}</Col>
                    <Col >{showField(this.props, "entryJlptLevel")}</Col>
                    <Col >{showField(this.props, "lvAnScore")}</Col>
                  </Grid>
                </Row>
                <Row>
                  <Grid columns='equal'>
                    <Col >{showField(this.props, "entryEjuPoint")}</Col>
                    <Col >{showField(this.props, "jpBjf")}</Col>
                  </Grid>
                </Row>
                <Row>
                  <Grid columns='equal'>
                    <Col>{showField(this.props, "jpObjective")}</Col>
                  </Grid>
                </Row>
              </Card.Content>
            </Card> */}
          {/* </Col> */}
          {/* </Grid> */}
          {/* <Grid columns='equal'> */}
          <Col sm='6'>
            <Card fluid>
              <Card.Content header='日本語能力(2012版24項)' className='card-title' />
              <Card.Content>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col sm={3}>1 試験による証明</Col>
                  <Col>{showField(this.props, "tesNam")}</Col>
                  <Col>{showField(this.props, "lvAnScore")}</Col>
                  {/* </Grid> */}
                </Row>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col sm={3}>2 日本語教育を受けた教育機關</Col>
                  <Col>{showField(this.props, "eduComName")}</Col>
                  <Col>{showField(this.props, "fromWhen")}</Col>
                  <Col>{showField(this.props, "until")}</Col>
                  {/* <Col md={4}>{showField(this.props, "japLangInstituteName1")}</Col>
              <Col md={2}>{showField(this.props, "japLangStartDate1")}</Col>
              <Col md={2}>{showField(this.props, "japLangEndDate1")}</Col> */}
                  {/* </Grid> */}
                </Row>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col sm={3}>3 その他</Col>
                  <Col>{showField(this.props, "otherText")}</Col>
                  <Col></Col>
                  {/* </Grid> */}
                </Row>
              </Card.Content>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card fluid>
              <Card.Content header='日本語学習歴(2012版25項)' className='card-title' />
              <Card.Content>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col>{showField(this.props, "japLangInstituteName2")}</Col>
                  <Col>{showField(this.props, "japLangStartDate2")}</Col>
                  <Col>{showField(this.props, "japLangEndDate2")}</Col>
                  {/* </Grid> */}
                </Row>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col>{showField(this.props, "japLangPostCode2")}</Col>
                  <Col>{showField(this.props, "japLangTel2")}</Col>
                  <Col>{showField(this.props, "japLangFax2")}</Col>
                  {/* </Grid> */}
                </Row>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col>{showField(this.props, "japLangEmail2")}</Col>
                  <Col>{showField(this.props, "japLangHomepage2")}</Col>
                  <Col>{showField(this.props, "japLangInstituteAddress2")}</Col>
                  {/* </Grid> */}
                </Row>
              </Card.Content>
            </Card>
          </Col>
        </Row>
        {/* </Grid> */}
      </>
    );
  }
}

