import _ from 'lodash'

import { chineseNumber, currentdateD, dateTW, toStraight, digitLowercase, arrChineseYear, arrTime, funcObjFind, objChineseYear2, objSexual2, digit, intToChineseNumberString, funcPad, funcAddObjLabelValue, funcIsObjFindLabel, funcStrcatObjLabelValue, digitUppercase } from "../pdf/defPdf";

export const Borrowgod11 = (doc, arrObj, printDate) => {
  doc.setFont('BiauKai');

  console.log(arrObj.length)
  let nowMember = 0;
  let nowPage = 1;
  let totalPage = Math.ceil(arrObj.length / 13);

  // 整個頁面的loop
  let table_x = 30;
  let table_y = 55;
  let table_width = 780;
  let table_height = 488;
  let cell_height = 35;
  let col_width = [20, 50, 60, 70, 60, 60, 200, 70, 70, 60, 60];
  let col_title = ["", "狀態", "神祇名稱", "善信編號", "姓名", "電話", "地址", "請出日期", "歸還日期", "壓爐金", "油香"]; //, "請神編號\n身分證號", "請神名稱"
  let col_title_y_shift = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10];
  let col_title_fontsize = [14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14];
  let col_data_y_shift = [12, 10, 10, 12, 10, 12, 10, 12, 12, 12, 12];
  let col_data_x_shift = 2;
  let footer_width_shift = table_x + 315;

  doc.setFontSize(20).text("龍潭慈惠堂 借神尊出入表", 200, 15);
  while (nowMember < arrObj.length) {
    doc.setFont('BiauKai').setFontSize(14).text("製表日期", 560, 20);
    doc.setFontSize(14);
    dateTW(doc, 630, 20, printDate);

    doc.rect(table_x, table_y, table_width, cell_height);
    doc.rect(table_x, table_y, table_width, table_height);

    let left_shift = table_x;
    let top_shift = table_y;
    const COLOR = "#000";
    for (let i = 0; i < col_width.length; i++) {
      // doc.line(left_shift, table_y,left_shift, table_y + table_height);
      doc.line(left_shift, table_y, left_shift, table_y + table_height);
      doc.setFontSize(col_title_fontsize[i]);
      doc.setTextColor(COLOR);
      doc.text(col_title[i], left_shift, table_y + col_title_y_shift[i]);
      left_shift += col_width[i];
    }

    // let sumColWidth = _.reduce((col_width, sum, el) => { return sum + el }, 0);
    for (let i = 0; i < 13; i++) {
      top_shift += cell_height;

      doc.line(table_x, top_shift, table_x + table_width, top_shift);
      left_shift = table_x;

      if (!!arrObj[nowMember]) {
        // "狀態", "神祇名稱", "善信編號", "姓名", "電話", "地址", "請出日期", "經辦人", "歸還日期", "經辦人"
        /*
    "_id" : "XeroMHgg55Sbbgf3W",
    "god_name" : "dfdf",
    "people_name" : "周福干",
    "who_phone" : "aaa",
    "who_addr" : "新北市泰山區福德街一六巷七號",
    "client_id" : "",
    "type" : "1",
    "status" : "",
    "updatedAt" : ISODate("2016-10-24T00:22:11.485Z")
         */
        doc.setFont('Times', 'Roman').setFontSize(12);
        // doc.text(nowMember+1, left_shift, top_shift+col_data_y_shift[0], { width: col_width[0], align: 'center'});
        // doc.text(arrObj[nowMember].order_id, left_shift, top_shift + col_data_y_shift[0]);
        left_shift += col_width[0];

        doc.setFont('BiauKai').setFontSize(12);
        if (arrObj[nowMember].type === "1") {
          doc.text("出借中", left_shift, top_shift + col_data_y_shift[1]);
        } else if (arrObj[nowMember].type === "2") {
          doc.text("已歸還", left_shift, top_shift + col_data_y_shift[1]);
        }
        left_shift += col_width[1];

        if (arrObj[nowMember].god_name) {
          doc.text(arrObj[nowMember].god_name, left_shift + col_data_x_shift, top_shift + col_data_y_shift[2]);
        }
        left_shift += col_width[2];

        doc.setFont('Times', 'Roman').setFontSize(12);
        if (!!arrObj[nowMember].people_id) {
          doc.text(arrObj[nowMember].people_id, left_shift, top_shift + col_data_y_shift[3]);
        }
        left_shift += col_width[3];

        doc.setFont('BiauKai').setFontSize(12);
        if (!!arrObj[nowMember].people_name)
          doc.text(arrObj[nowMember].people_name, left_shift + col_data_x_shift, top_shift + col_data_y_shift[4], { width: col_width[4], align: 'left' });
        left_shift += col_width[4];

        doc.setFont('Times', 'Roman').setFontSize(12);
        if (!!arrObj[nowMember].who_phone) {
          if (arrObj[nowMember].who_phone.length > 10) {
            doc.text(arrObj[nowMember].who_phone, left_shift + col_data_x_shift, top_shift + 4);
          } else {
            doc.text(arrObj[nowMember].who_phone, left_shift + col_data_x_shift, top_shift + col_data_y_shift[5]);
          }
        }
        left_shift += col_width[5];

        doc.setFont('BiauKai').setFontSize(12);
        if (!!arrObj[nowMember].who_addr) {
          let addr = arrObj[nowMember].who_addr;
          let now_y = col_data_y_shift[6];
          if (addr.length > 15) {
            now_y = 4;
            addr = addr.splice(15, 0, "\n");
          }
          doc.text(addr, left_shift + col_data_x_shift, top_shift + now_y);
        }
        left_shift += col_width[6];

        doc.setFont('Times', 'Roman').setFontSize(12);
        // doc.setFont('BiauKai').setFontSize(14);
        if (!!arrObj[nowMember].insertedDate)
          doc.text(currentdateD(new Date(arrObj[nowMember].insertedDate)), left_shift + col_data_x_shift, top_shift + col_data_y_shift[7]);
        left_shift += col_width[7];

        doc.setFont('Times', 'Roman').setFontSize(12);
        if (!!arrObj[nowMember].updatedDate)
          doc.text(currentdateD(new Date(arrObj[nowMember].updatedDate)), left_shift + col_data_x_shift, top_shift + col_data_y_shift[8]);
        left_shift += col_width[8];

        doc.setFont('Times', 'Roman').setFontSize(12);
        if (!!arrObj[nowMember].borrow_money)
          doc.text(arrObj[nowMember].borrow_money, left_shift + col_data_x_shift, top_shift + col_data_y_shift[9]);
        left_shift += col_width[9];

        doc.setFont('Times', 'Roman').setFontSize(12);
        if (!!arrObj[nowMember].return_money)
          doc.text(arrObj[nowMember].return_money, left_shift + col_data_x_shift, top_shift + col_data_y_shift[10]);
        left_shift += col_width[10];

        // doc.setFont('Times', 'Roman').setFontSize(14);
        // if(!!arrObj[nowMember].memberId)
        //   doc.text(arrObj[nowMember].memberId, left_shift, top_shift+col_data_y_shift[4], { width: col_width[4], align: 'center'});
      }
      nowMember++;
    }

    doc.setFontSize(14);
    doc.setFont('BiauKai').text("第", footer_width_shift, table_y + table_height + 15);
    doc.setFont('Times', 'Roman').text(nowPage.toString(), footer_width_shift + 20, table_y + table_height + 16);
    doc.setFont('BiauKai').text("頁，共", footer_width_shift + 50, table_y + table_height + 15);
    doc.setFont('Times', 'Roman').text(totalPage.toString(), footer_width_shift + 102, table_y + table_height + 16);
    doc.setFont('BiauKai').text("頁", footer_width_shift + 135, table_y + table_height + 15);

    nowPage++;

    if (nowMember + 13 < arrObj.length) {
      doc.addPage();
      nowMember += 13;
    } else {
      break;
    }
  }
}

export const prayPage121 = (doc, arrObj = [], meetname) => {
  //標題文字外框
  doc.setLineWidth(6);
  // doc.strokeOpacity(0.5);
  doc.setDrawColor(6, 6, 6)
  doc.rect(920, 30, 65, 550);

  //標題文字 外框線
  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)
  doc.rect(915, 25, 75, 560);

  //內文字外框
  doc.setLineWidth(2);
  doc.setDrawColor(0, 0, 0)
  doc.rect(450, 20, 370, 665);

  doc.setFont('BiauKai');
  doc.setFontSize(53);
  doc.text(toStraight("禳燈消災植福疏文"), 925, 95);

  doc.setFontSize(20);
  doc.text(toStraight("伏  以"), 885, 105);
  doc.text(toStraight("大教遠流 示一乘歸元之路  神功叵測 開眾生方便之門   今據"), 865, 20);
  doc.text(toStraight(arrObj.length && arrObj[0].addr), 845, 20);  //地址
  doc.text(toStraight("吉宅居住"), 845, 565);
  doc.text(toStraight("植福延禧 誦經消災  祈安延壽信"), 825, 20);
  doc.text(toStraight("暨合家人等同誠焚香叩首叩拜"), 420, 420);
  doc.text(toStraight("諸佛菩薩"), 400, 20);
  doc.text(toStraight("列位仙真座前消災解厄"), 400, 120);
  doc.text(toStraight("添福延壽 祈求凶事脫出"), 400, 330 + 30);
  doc.text(toStraight("吉事降臨"), 400, 580 + 80);
  doc.text(toStraight(("身體平安康泰上天好生之德 聖神愍物之慈 欣逢 " + meetname)), 380, 20);
  doc.text(toStraight("聖教敢不傾心是以涓向今歲次"), 360, 20);
  doc.text(toStraight(arrObj[0].birthDay), 360, 290 + 40); // 年
  // doc.text(toStraight("年"), 360, 340 + 20);
  // doc.text(toStraight("口口"), 360, 370 + 20); // 月
  // doc.text(toStraight("月"), 360, 420 + 20);
  // doc.text(toStraight("口口"), 360, 450 + 20); // 日
  // doc.text(toStraight("日"), 360, 500 + 20);
  doc.text(toStraight("吉旦 恭就"), 360, 560 + 20);

  doc.text(toStraight("龍潭慈惠堂  聖神座前  舖設淨壇  延仗聖門       諷誦"), 340, 20);
  doc.text(toStraight("諸佛秘章真言"), 320, 20);
  doc.text(toStraight("虔備香花果品  妙供                   上奉"), 300, 40);
  doc.text(toStraight("無極瑤池大聖西王金母大天尊"), 280, 20);
  doc.text(toStraight("玄穹高上帝玉皇大天尊"), 260, 20);
  doc.text(toStraight("南無無上虛空地母尊佛"), 240, 20);
  doc.text(toStraight("十方三寶"), 220, 20);
  doc.text(toStraight("萬德千尊 並及諸天護法神祗光降供筵 慈悲納受        伏願"), 200, 20);
  doc.text(toStraight("神光普照信"), 180, 20);
  doc.text(toStraight(arrObj[0].livename), 180, 135 + 10); // 姓名
  doc.text(toStraight("毫光遠暨 將暗室而重光 法雨普沾 使枯枝而再潤"), 180, 205 + 40);
  doc.text(toStraight("頓出塵勞之海 高登正覺之場 六根清淨 本來面目重明 三業全消 自"), 160, 20);

  doc.text(toStraight("性靈光復顯 災殃散盡 福壽綿長 家門昌盛以長春 眷屬和睦於永歲"), 140, 20);
  doc.text(toStraight("愿行善積德以植福 更祈 合家迪吉 人口平安 四時無災 八節有慶"), 120, 20);
  doc.text(toStraight("男添百福 女納千祥 所求如意 大降吉祥         謹疏以"), 100, 20);
  doc.text(toStraight("聞"), 80, 25);

  // doc.text(toStraight("天運歲次"), 60, 20);
  // doc.text(toStraight(arrObj[0].birthDay), 60, 125); // 年
  doc.text(toStraight("天運歲次" + meetname + "信" + arrObj[0].livename + "具疏上申"), 60, 20);
  // doc.text(toStraight("年"), 60, 170);
  // doc.text(toStraight("口口"), 60, 195); // 月
  // doc.text(toStraight("月"), 60, 240);
  // doc.text(toStraight("口口"), 60, 265); // 日
  // doc.text(toStraight("日"), 60, 310);
  // doc.text(toStraight("信"), 60, 345);
  // doc.text(toStraight(arrObj[0].livename), 60, 480 - 50); // 姓名
  // doc.text(toStraight("具疏上申"), 60, 580);
}
export const prayPage22 = (doc, arrObj, meetname) => {
  // let doc = new PDFDocument({size: 'B4', margin: 1, layout: "landscape"});
  // doc.registerFont('BiauKai', BiauKaiTTF);

  //標題文字外框
  doc.setLineWidth(6);
  // doc.strokeOpacity(0.5);
  doc.strokeColor("#666");
  doc.rect(920, 30, 65, 550);

  //標題文字 外框線
  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)
  doc.rect(915, 25, 75, 560);

  //內文字外框
  doc.setLineWidth(2);
  doc.setDrawColor(0, 0, 0)
  doc.rect(450, 20, 370, 665);

  doc.setFont('BiauKai');
  doc.setFontSize(55);
  doc.text(toStraight("消災補運文疏"), 925, 130);

  doc.setFontSize(20);
  doc.text(toStraight("伏  以"), 885, 105);
  doc.text(toStraight("大教遠流 示一乘歸元之路  神功叵測 開眾生方便之門   今據"), 865, 20);
  doc.text(toStraight("口口口口口口口口口口口口口口口口口口口"), 845, 20);  //地址
  doc.text(toStraight("吉宅居住"), 845, 565);
  doc.text(toStraight("植福延禧 誦經消災  祈安延壽信"), 825, 20);
  doc.text(toStraight("暨合家人等同誠焚香叩首叩拜"), 420, 420);
  doc.text(toStraight("諸佛菩薩"), 400, 20);
  doc.text(toStraight("列位仙真座前消災解厄"), 400, 120);
  doc.text(toStraight("添福延壽 祈求凶事脫出"), 400, 330);
  doc.text(toStraight("吉事降臨"), 400, 580);
  doc.text(toStraight(("身體平安康泰上天好生之德 聖神愍物之慈 欣逢 " + meetname)), 380, 20);
  doc.text(toStraight("聖教敢不傾心是以涓向今歲次"), 360, 20);
  doc.text(toStraight("口口"), 360, 290); // 年
  doc.text(toStraight("年"), 360, 340);
  doc.text(toStraight("口口"), 360, 370); // 月
  doc.text(toStraight("月"), 360, 420);
  doc.text(toStraight("口口"), 360, 450); // 日
  doc.text(toStraight("日"), 360, 500);
  doc.text(toStraight("吉旦 恭就"), 360, 560);

  doc.text(toStraight("龍潭慈惠堂  聖神座前  舖設淨壇  延仗聖門       諷誦"), 340, 20);
  doc.text(toStraight("諸佛秘章真言"), 320, 20);
  doc.text(toStraight("虔備香花果品  妙供                   上奉"), 300, 40);
  doc.text(toStraight("無極瑤池大聖西王金母大天尊"), 280, 20);
  doc.text(toStraight("玄穹高上帝玉皇大天尊"), 260, 20);
  doc.text(toStraight("南無無上虛空地母尊佛"), 240, 20);
  doc.text(toStraight("十方三寶"), 220, 20);
  doc.text(toStraight("萬德千尊 並及諸天護法神祗光降供筵 慈悲納受        伏願"), 200, 20);
  doc.text(toStraight("神光普照信"), 180, 20);
  doc.text(toStraight("口口口"), 180, 135); // 姓名
  doc.text(toStraight("毫光遠暨 將暗室而重光 法雨普沾 使枯枝而再潤"), 180, 205);
  doc.text(toStraight("頓出塵勞之海 高登正覺之場 六根清淨 本來面目重明 三業全消 自"), 160, 20);

  doc.text(toStraight("性靈光復顯 災殃散盡 福壽綿長 家門昌盛以長春 眷屬和睦於永歲"), 140, 20);
  doc.text(toStraight("愿行善積德以植福 更祈 合家迪吉 人口平安 四時無災 八節有慶"), 120, 20);
  doc.text(toStraight("男添百福 女納千祥 所求如意 大降吉祥         謹疏以"), 100, 20);
  doc.text(toStraight("聞"), 80, 25);

  doc.text(toStraight("天運歲次"), 60, 20);
  doc.text(toStraight("口口"), 60, 125); // 年
  doc.text(toStraight("年"), 60, 170);
  doc.text(toStraight("口口"), 60, 195); // 月
  doc.text(toStraight("月"), 60, 240);
  doc.text(toStraight("口口"), 60, 265); // 日
  doc.text(toStraight("日"), 60, 310);
  doc.text(toStraight("吉旦"), 60, 330);
  doc.text(toStraight("沐恩信主"), 60, 390);
  doc.text(toStraight("口口口"), 60, 480); // 姓名
  doc.text(toStraight("叩拜上申"), 60, 560);
}

export const prayPage132 = (doc, objP1, objP2, in_date = '') => {  // 靈寶大法司

  /*  let Input_date = in_date;
    let Input_date_value = [];
  
    if(Input_date){
      Input_date_value = Input_date.split("/");
    }*/

  doc.setFont('BiauKai');
  doc.setFontSize(48);
  doc.text(toStraight("靈寶大法司   為"), 700, 75);

  let str_size = 28;
  doc.setFontSize(str_size);
  doc.text(toStraight("據陽人"), 620, 120);

  if (!!objP2.livename) {
    doc.text(toStraight(objP2.livename), 620, 220); // 報名人
  } else {
    doc.text(toStraight(objP1.whoapplyText), 620, 220); // 報名人
  }

  // let str = "伏為故 " + objP2.show_name + " 靈魂茲逢普度之辰焚寄冥箱乙具內貯金銀疋帛課錠等項交付 " + objP2.show_name + " 該魂冥中收用沿途毋得阻截須至引者";
  let str = "伏為故 ";
  let pname = "";

  doc.setFontSize(22);
  if (!!objP2.lastname1) {
    let l1 = "公";
    if (!!objP2.lastname2) {
      l1 = "媽";
    }
    doc.text(l1, 558, 238);
    doc.text(l1, 458, 360);
    doc.text(l1, 332, 345);
    pname = objP2.lastname1 + " ";
  }
  if (!!objP2.lastname2) {
    pname = pname + objP2.lastname2 + " ";
    if (!!objP2.lastname1) {
      doc.text("氏", 558, 303);
      doc.text("氏", 458, 417);
      doc.text("氏", 332, 402);
    } else {
      doc.text("氏", 558, 238);
      doc.text("氏", 458, 360);
      doc.text("氏", 332, 345);
    }
  }
  doc.setFontSize(28);

  pname = pname + objP2.passname;
  str = str + pname + " 靈魂";

  let length = 16;
  let width = 50;
  let start = 0;

  let i = 0;
  while (start < str.length) {
    doc.text(toStraight(str.substr(start, length)), 550 - (i * width), 80);

    start += length;
    i++;
  }

  i = 0;
  start = 0;
  str = "茲逢普度之辰焚寄冥箱乙具內貯金銀疋帛課錠等項交付 " + ('0' || pname) + " 該魂冥中收用沿途毋得阻截須至引者";
  let left = 550 - width;
  while (start < str.length) {
    doc.text(toStraight(str.substr(start, length)), left - (i * width), 80);

    start += length;
    i++;
  }

  left = 350 - width * 1.5;
  start = 0;
  length = 13;
  // let str1 = "右引給付 " + objP2.show_name + " 靈魂收執" ;
  let str1 = "右引給付 " + ('0' || pname);
  // doc.text(str1.substr(0).toStraight(), left, 130);

  i = 0;
  while (start < str1.length) {
    doc.text(toStraight(str1.substr(start, length)), left - (i * width), 120 + str_size * 2);
    start += length;
    i++;
  }
  doc.text(toStraight("靈魂收執"), left - (i * width), 120 + str_size * 2);

  left = 220 - width * 2;
  doc.text(toStraight(("天運 " + in_date + " 給")), left, 120);
  left = 130 - width * 2;
  doc.setFontSize(48);
  doc.text(toStraight("司"), left, 120);
}
export const taisuiPage151 = function (doc, objP2, main_god, in_date) { // 太歲星君植福疏文
  // Input_date = in_date;
  // let Input_date_value = in_date.split("/");
  // let y = yearToTGDG(Input_date_value[0]);
  // let m = dateDigit[Number(Input_date_value[1])];
  // let dd = dateDigit[Number(Input_date_value[2])];
  // if(dd.length ===  1) dd = "初"+dd;
  let name_convert = "";

  doc.setLineWidth(10);
  // doc.strokeOpacity(0.5);
  doc.setDrawColor(0, 0, 0)
  doc.rect(870, 70, 70, 510);

  doc.setLineWidth(5);
  doc.setDrawColor(0, 0, 0)
  doc.rect(863, 65, 84, 522);

  doc.setFont('BiauKai');

  doc.setFontSize(48);
  doc.text(toStraight("太歲星君植福疏文"), 880, 130);

  doc.setFontSize(17);
  doc.text(toStraight("伏  以"), 815, 130);
  doc.text(toStraight("聖德洋洋解千災禍厄"), 770, 45);
  doc.text(toStraight("聖德洋洋解千災禍厄"), 735, 45);

  if (objP2.addr)
    doc.text(toStraight(objP2.addr + " 吉"), 675, 45); //輸入地址變數

  // 要顯示的名字
  if (!!objP2.livename) {
    name_convert = objP2.livename.replace(",", " ").substr(0, 4); //姓名變數最多只取1個人(4字元)
  }
  doc.text(toStraight("宅居住奉"), 640, 45);
  doc.text(toStraight("道立疏奉安當年太歲星君" + main_god + "星辰祈安植福信士"), 600, 45);
  // doc.text("管仲".toStraight(), 600, 340); // 太歲星君-姓名
  // doc.text(toStraight(main_god), 600, 340); // 太歲星君-姓名
  // doc.text(toStraight("星辰祈安植福信士"), 600, 395);

  doc.text(toStraight(name_convert), 600, 590);  //輸入姓名變數
  doc.text(toStraight("暨合家人等同誠焚香叩首叩拜"), 570, 320);
  doc.text(toStraight("聖造具呈意者伏念現厄"), 540, 45);

  doc.text(toStraight((name_convert + " 本命")), 540, 300);  //輸入姓名變數

  let bd = objP2.birthDay;
  if (!!bd) {
    if (bd.indexOf("時") === -1) {
      bd = bd + "吉時";
    }
  }
  doc.text(toStraight(bd), 500, 45); // 年
  doc.text(toStraight("瑞生"), 500, 366);

  doc.text(toStraight((digitLowercase(objP2.live_year || '') + "歲")), 500, 430);
  doc.text(toStraight("切見今年現季"), 500, 530);

  doc.text(toStraight("以來 星辰不順 月令多端 命內恐犯天災地曜 凶邪惡鬼 亡"), 470, 45);
  doc.text(toStraight("神劫煞 無情關限 交迎脫運 金木水火土煞入命中作災 以"), 440, 45);

  let date_nospace = '0' || in_date.replace(/ /g, "");

  doc.text(toStraight(("致歲月欠安 合家人等心中欲求平安 謹涓" + date_nospace)), 410, 45);
  // doc.text((y + "年"+ m + "月"+ dd + "日 ").toStraight(), 410, 485); //年月日-日期 變數
  // doc.text((in_date).toStraight(), 410, 485); //年月日-日期 變數

  doc.text(toStraight("吉旦 仗"), 380, 45);
  doc.text(toStraight("龍潭慈惠堂 立疏 虔備香花果品 天金高錢財帛等式 誠心"), 350, 45);
  doc.text(toStraight("恭對 龍潭慈惠堂"), 320, 45);
  doc.text(toStraight("無極瑤池大聖西王金母大天尊懿前"), 290, 45);
  doc.text(toStraight("昊天金闕玉皇大天尊玄穹高上帝玉陛下"), 260, 45);
  doc.text(toStraight(("奉安太歲星君 " + main_god + " 合壇尊神座前  消災改厄 惟祈庇佑")), 230, 45);

  doc.text(toStraight((name_convert + " 自今以後 凶眚退位 吉宿進宮 星辰高照 命運亨通")), 200, 45); //姓名
  // doc.text("自今以後 凶眚退位 吉宿進宮 星辰高照 命運亨通".toStraight(), 200, 125);
  doc.text(toStraight("四時無災"), 170, 45);
  doc.text(toStraight("八節有慶"), 170, 145);
  doc.text(toStraight("移凶化吉"), 170, 245);
  doc.text(toStraight("解禍成祥"), 170, 345);
  doc.text(toStraight("合家迪吉"), 170, 460);
  doc.text(toStraight("男女老幼"), 170, 575);

  doc.text(toStraight("均安 男添百福 女納千祥 所求如意 大降吉祥 丁財大進"), 140, 45);
  doc.text(toStraight("富貴綿長 求者如意 百事亨通等 因右具照格 謹疏以"), 110, 45);
  doc.text(toStraight("聞"), 80, 45);
  // doc.text(("天運歲次 " + y + "年"+ m + "月"+ dd + "日 ").toStraight(), 50, 45); //年月日-日期 變數
  doc.text(toStraight(("天運歲次 " + in_date + " 右具文疏 叩拜上申")), 50, 45); //年月日-日期 變數

  // doc.text("右 具 文 疏 叩拜上申".toStraight(), 50, 360);
}
export const prayPage133 = (doc, objP1 = [], in_date = '') => { // 財神燈文疏
  // let Input_date = in_date;
  // let Input_date_value = in_date.split("/");

  doc.setLineWidth(2);
  doc.setDrawColor(0, 0, 0)
  doc.rect(690, 20, 103, 665); //沐恩信主外框線
  doc.setLineWidth(1);

  doc.setFont('BiauKai');
  doc.setFontSize(20);
  let word_width = 0;
  /*    for (let i = 0; i < 5; i++) { //依序增加5筆資料
        doc.text("王大姐姐".toStraight(), 772 - word_width, 25); // 測試家庭成員 資料 (資料筆數5)
        doc.text("甲午年○二月三十日吉時生五十八歲".toStraight(), 772 - word_width, 145); // 測試家庭成員 資料
        word_width += 20 ;
      }
  */
  if (objP1.length <= 5) {
    doc.line(690, 120, 792, 120); //第1條 橫線 (資料筆數5)
    doc.setFontSize(20);
    for (let i = 0; i < objP1.length; i++) {
      doc.text(toStraight(objP1[i].livename), 772 - word_width, 25); // 測試家庭成員 資料 (資料筆數5)

      const bd = (objP1[i].birthDay) + "生 " + digitLowercase(objP1[i].live_year || '') + "歲";
      doc.text(toStraight(bd), 772 - word_width, 145); // 測試家庭成員 資料
      word_width += 20;
    }
  } else {
    doc.line(690, 100, 792, 100); //第1條 橫線 (資料筆數10)
    doc.line(690, 350, 792, 350); //第2條 橫線 (資料筆數10)
    doc.line(690, 430, 792, 430); //第3條 橫線 (資料筆數10)

    doc.setFontSize(15);
    for (let i = 0; i < 5; i++) {
      doc.text(toStraight(objP1[i].livename), 775 - word_width, 25 + 15); // 測試家庭成員 資料 (資料筆數10)
      let bd = (objP1[i].birthDay) + "生 " + digitLowercase(objP1[i].live_year || '') + "歲";
      doc.text(toStraight(bd), 775 - word_width, 105 + 20); // 測試家庭成員 資料

      if (i + 5 < objP1.length && objP1[i + 5].livename) {
        doc.text(toStraight(objP1[i + 5].livename), 775 - word_width, 355 + 15); // 測試家庭成員 資料 (下面) (資料筆數10)
        bd = (objP1[i + 5].birthDay) + "生 " + digitLowercase(objP1[i].live_year || '') + "歲";
        doc.text(toStraight(bd), 775 - word_width, 435 + 20); // 測試家庭成員 資料(下面)
      }
      word_width += 20;
    }
  }

  doc.setFont('BiauKai');
  doc.setFontSize(72);
  doc.text(toStraight("財神燈文疏"), 910, 170);

  doc.setFontSize(18);
  doc.text(toStraight("伏  以"), 895, 70);
  doc.text(toStraight("手執金鞭神光凜凜常進寶"), 875, 20);
  doc.text(toStraight("身乘黑虎聖相森森廣招財"), 855, 20);
  doc.text(toStraight("今  據"), 835, 140);

  doc.text(toStraight((objP1.length && objP1[0].addr) || ''), 815, 30); //輸入地址變數
  doc.text(toStraight("吉宅居住"), 815, 550);
  doc.text(toStraight("沐恩信主"), 795, 50);
  doc.text(toStraight("暨合家人等一同  恭向"), 665, 270);
  doc.text(toStraight("龍潭慈惠堂"), 645, 20);
  doc.text(toStraight("正一龍虎玄壇真君玄化財神趙大元帥座前叩許良福一宗彩結星垣"), 645, 130);
  // doc.text(toStraight("座前叩許良福一宗"), 645, 455);
  // doc.text(toStraight("彩結星垣"), 645, 625);
  doc.text(toStraight("禮 請"), 625, 140);
  doc.text(toStraight("趙大元帥星光普照逐蒼黎之八難"), 605, 70);
  doc.text(toStraight("仙佛聖神祗奧映耀掃眾庶之三災"), 585, 70);
  doc.text(toStraight("謹以啟設道場禮斗祈安禳燈消災植福"), 565, 20);
  doc.text(toStraight("全 望"), 545, 140);
  doc.text(toStraight("合壇真宰欣賜民福沐恩信主恭對星垣之下肅整規儀舖陳供養寶爐香篆"), 525, 20);
  doc.text(toStraight("祈禳本命元辰光彩消災解厄合家平安錫福千祥發心善報酬隆恩於萬一"), 505, 20);
  doc.text(toStraight("懇 祈"), 485, 120);
  doc.text(toStraight("奏事使者代呈"), 465, 80);
  doc.text(toStraight("無極瑤池大聖西王金母大天尊   之 懿 前"), 445, 20);
  doc.text(toStraight("中皇大天尊   玄靈高上帝   之 御 前"), 425, 20);
  doc.text(toStraight("正一龍虎玄壇趙大元帥      之 座 前"), 405, 20);
  doc.text(toStraight("招財進寶利市納珍仙官      之 位 前"), 385, 20);
  doc.text(toStraight("龍虎玄壇侍衛黑虎將軍      之 位 前      暨"), 365, 20);

  doc.text(toStraight("本堂眾等列位神尊座前洞鑒轉奏"), 345, 20);
  doc.text(toStraight("昊天金闕玉皇大天尊玄穹高上帝玉陛下"), 325, 20);
  doc.text(toStraight("大慈大聖 大悲大願 網開隆恩 三面宥赦 沐恩信主 已往之愆尤 恩賜"), 305, 20);
  doc.text(toStraight("增長 將來之福果 本命元辰 無有刑剋 三災不染 八難無侵 錫福吉祥"), 285, 20);

  doc.text(toStraight("伏冀"), 265, 80);
  doc.text(toStraight("神通赫赫 聖德昭彰 利澤施人 聲名和事"), 245, 20);
  doc.text(toStraight("求財亨通 五福駢臻 凡于旦夕 悉獲蔭扶"), 225, 20);
  doc.text(toStraight("求財者財源廣進  求利者利路亨通"), 205, 20);
  doc.text(toStraight("事業如意以豐榮  三多本願而稱慶"), 185, 20);

  doc.text(toStraight("所有希求"), 145, 60);
  doc.text(toStraight("全叨巨庇"), 120, 80);
  doc.text(toStraight("聞                           恭疏 上"), 100, 60);

  // let y = yearToTGDG(Input_date_value[0]);
  // let m = dateDigit[Number(Input_date_value[1])];
  // let dd = dateDigit[Number(Input_date_value[2])];
  // if(dd.length ===  1) dd = "初"+dd;

  //姓名變數最多只取1個人(4字元)
  let name_convert = '';
  if (objP1.length) {
    name_convert = (!!objP1[0].whoapplyText) ? objP1[0].whoapplyText.replace(",", " ").substr(0, 4) : objP1[0].livename.replace(",", " ").substr(0, 4);
  }

  //輸入-日期，姓名 變數
  // doc.text(("天運歲次 "+ y + "年"+ m + "月"+ dd + "日 吉旦" + " 沐恩信主 " + name_convert + " 叩拜上申").toStraight(), 60, 20);
  doc.text(toStraight(("天運歲次 " + in_date + " 吉旦 沐恩信主 " + name_convert + " 叩拜上申")), 60, 20);

  //     return Api.returnPDF(doc);
  // }});
}
export const prayPage134 = (doc, objP1, in_date) => {  // 禮斗文疏 平安斗

  // let Input_date = in_date;
  // let Input_date_value = in_date.split("/");

  //doc.setLineWidth(10);
  //doc.strokeOpacity(0.5);
  //doc.strokeColor("#666");
  //doc.rect(870, 70, 70, 510);

  doc.setLineWidth(2);
  doc.setDrawColor(0, 0, 0)
  doc.rect(690, 20, 103, 665);

  doc.setLineWidth(1);

  doc.setFont('BiauKai');
  doc.setFontSize(65);
  doc.text(toStraight("禮斗文疏"), 910, 65);

  // let name_do = (objP1[0].praytypeText.substr(7));
  let name_do = (objP1[0].praytypeText);
  if (name_do.length <= 3) {
    doc.setFontSize(65);
    doc.text(toStraight(name_do), 910, 400);
  } else if (name_do.length === 4) {
    doc.setFontSize(54);
    doc.text(toStraight(name_do), 920, 400);
  } else if (name_do.length >= 5) {
    doc.setFontSize(46);
    doc.text(toStraight(name_do), 925, 395);
  }

  doc.setFont('BiauKai');
  let word_width = 0;

  if (objP1.length <= 5) {
    doc.line(690, 120, 792, 120); //第1條 橫線 (資料筆數5)
    doc.setFontSize(20);
    for (let i = 0; i < objP1.length; i++) {
      doc.text(toStraight(objP1[i].livename || 0), 772 - word_width, 53); // 測試家庭成員 資料 (資料筆數5)

      // let bd = (objP1[i].birthDay) + "生 " + digitLowercase(objP1[i].live_year ||'') + "歲";
      let bd = (objP1[i].birthDay || 0) + "生 " + digitLowercase(objP1[i].live_year || '' || 0) + "歲";
      doc.text(toStraight(bd), 772 - word_width, 140); // 測試家庭成員 資料
      word_width += 20;
    }
  } else {
    doc.line(690, 80, 792, 80); //第1條 橫線 (資料筆數10)
    doc.line(690, 350, 792, 350); //第2條 橫線 (資料筆數10)
    doc.line(690, 410, 792, 410); //第3條 橫線 (資料筆數10)

    doc.setFontSize(14.5);
    for (let i = 0; i < 5; i++) {  //依序增加10筆資料
      doc.text(toStraight(objP1[i].livename), 775 - word_width, 37); // 測試家庭成員 資料 (資料筆數10)
      let bd = (objP1[i].birthDay) + "生 " + digitLowercase(objP1[i].live_year || '') + "歲";
      doc.text(toStraight(bd), 775 - word_width, 93); // 測試家庭成員 資料

      if (!!objP1[i + 5] && !!objP1[i + 5].livename) {
        doc.text(toStraight(objP1[i + 5].livename), 775 - word_width, 367); // 測試家庭成員 資料 (下面) (資料筆數10)
        bd = (objP1[i + 5].birthDay) + "生 " + digitLowercase(objP1[i].live_year || '') + "歲";
        doc.text(toStraight(bd), 775 - word_width, 424); // 測試家庭成員 資料(下面)
      }
      word_width += 20;
    }
  }

  doc.setFont('BiauKai');
  doc.setFontSize(18);
  doc.text(toStraight("伏   以"), 895, 220);
  doc.text(toStraight("五宮齊映光輝受星君普照之德"), 875, 20);
  doc.text(toStraight("斗內盡耀燦爛蒙列宿庇護之恩"), 855, 20);
  doc.text(toStraight("今  據"), 835, 220);

  if (!!objP1[0].addr)
    doc.text(toStraight(objP1[0].addr || '0'), 815, 20); //輸入地址變數

  doc.text(toStraight("吉宅居住"), 815, 565);
  doc.text(toStraight("沐恩信主"), 795, 50);
  doc.text(toStraight("暨合家人等一同  恭向"), 665, 270);
  doc.text(toStraight("龍潭慈惠堂"), 645, 20);
  doc.text(toStraight("無極瑤池金母大天尊"), 645, 130);
  doc.text(toStraight("懿前叩許良福一宗"), 645, 320);
  doc.text(toStraight("彩結星垣"), 645, 495);
  doc.text(toStraight("禮 請"), 625, 140);
  doc.text(toStraight("五斗星君星光普照逐蒼之八難"), 605, 70);
  doc.text(toStraight("仙佛聖神祗奧映耀掃眾庶之三災"), 585, 70);
  doc.text(toStraight("謹以啟設道場禮斗祈安禳燈消災植福"), 565, 20);
  doc.text(toStraight("全 望"), 545, 140);
  doc.text(toStraight("合壇真宰欣賜民福沐恩信主恭對星垣之下肅整規儀舖陳供養寶爐香篆"), 525, 20);
  doc.text(toStraight("祈禳本命元辰光彩消災解厄合家平安錫福千祥發心善報酬隆恩於萬一"), 505, 20);
  doc.text(toStraight("懇 祈"), 485, 120);
  doc.text(toStraight("奏事使者代呈"), 465, 80);
  doc.text(toStraight("無極瑤池大聖西王金母大天尊   之 懿 前"), 445, 20);
  doc.text(toStraight("中皇大天尊   玄靈高上帝   之 御 前"), 425, 20);
  doc.text(toStraight("斗  姆  元  君      之 尊 前"), 405, 20);
  doc.text(toStraight("五  斗  星  君      之 度 前"), 385, 20);
  doc.text(toStraight("本  命  星  君      之 度 前      暨"), 365, 20);

  doc.text(toStraight("本堂眾等列位神尊座前洞鑒轉奏"), 345, 20);
  doc.text(toStraight("昊天金闕玉皇大天尊玄穹高上帝玉陛下"), 325, 20);
  doc.text(toStraight("大慈大聖 大悲大願 網開隆恩 三面宥赦 沐恩信主 已往之愆尤 恩賜"), 305, 20);
  doc.text(toStraight("增長 將來之福果 本命元辰 無有刑剋 三災不染 八難無侵 錫福吉祥"), 285, 20);

  doc.text(toStraight("伏冀"), 265, 95);
  doc.text(toStraight("天 心 眷 顧   聖 德 巍 峨"), 245, 20);
  doc.text(toStraight("鴻 慈 隆 恩   再 賜 禎 祥"), 225, 20);
  doc.text(toStraight("求財者財源廣進   求利者利路亨通"), 205, 20);
  doc.text(toStraight("事業如意以豐榮   三多本願而稱慶"), 185, 20);

  doc.text(toStraight("所有希求"), 145, 60);
  doc.text(toStraight("全叨巨庇"), 120, 80);
  doc.text(toStraight("聞                     恭疏 上"), 100, 60);

  // let y = yearToTGDG(Input_date_value[0]);
  // let m = dateDigit[Number(Input_date_value[1])];
  // let dd = dateDigit[Number(Input_date_value[2])];
  // if(dd.length ===  1) dd = "初"+dd;

  let name_convert = "";
  if (!!objP1[0].whoapplyText) {
    name_convert = objP1[0].whoapplyText.replace(",", " ").substr(0, 4); //姓名變數最多只取1個人(4字元)
  } else {
    name_convert = objP1[0].livename.replace(",", " ").substr(0, 4); //姓名變數最多只取1個人(4字元)
  }

  // doc.text(("天運歲次 "+ y + "年"+ m + "月"+ dd + "日 吉旦" + " 沐恩信主 " + name_convert + " 叩拜上申").toStraight(), 60, 20);
  doc.text(toStraight(("天運歲次 " + in_date + " 吉旦 沐恩信主 " + name_convert + " 叩拜上申")), 60, 20);

  //doc.text("口口口".toStraight(), 60, 480); // 姓名
  //doc.text("叩拜上申".toStraight(), 60, 560);

  doc.setFont('Times', 'Roman');
  doc.setFontSize(70);
  // doc.rotate(90, { origin: [180, 170] }).text("(            )", 390, -630);
  doc.text("(            )", 390, -630, null, 90)
  // doc.rotate(360, {origin: [180, 470]}).text(")", 180, 380, { width: 100, align: "justify"});
}
export const prayPage135 = (doc, objP1 = [], in_date,) => { // 消災補運文疏
  let queryVar, sortVar = {}, pageVar = {}, meetname = [];

  // let Input_date = in_date;
  // let Input_date_value = in_date.split("/");
  // let y = yearToTGDG(Input_date_value[0]);
  // let m = dateDigit[Number(Input_date_value[1])];
  // let dd = dateDigit[Number(Input_date_value[2])];
  // if(dd.length ===  1) dd = "初"+dd;

  //標題文字外框
  doc.setLineWidth(6);
  // doc.strokeOpacity(0.5);
  doc.setDrawColor(0, 0, 0); //666
  doc.rect(920, 30, 65, 550);

  //標題文字 外框線
  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0);
  doc.rect(915, 25, 75, 560);

  //內文字外框
  doc.setLineWidth(2);
  doc.setDrawColor(0, 0, 0)
  doc.rect(450, 20, 370, 665);

  doc.setLineWidth(1);

  doc.setFont('BiauKai');
  doc.setFontSize(22);

  /*    for (let i = 0; i < 14; i++) {
      doc.text("王大姐姐".toStraight(), 813 - word_width, 28); // 測試家庭成員 資料 (資料筆數5)
      doc.text("甲午年○二月三十日吉時生五十八歲".toStraight(), 813 - word_width, 150); // 測試家庭成員 資料
      word_width += 25;
    }
  */

  if (objP1.length < 14) {
    doc.line(450, 130, 820, 130); //第1條 橫線 (資料筆數14)

    let word_width = 25;
    doc.setFontSize(20);
    for (let i = 0; i < objP1.length; i++) { //依序增加14筆資料

      doc.text(toStraight(objP1[i].livename), 813 - word_width, 40); // 測試家庭成員 資料 (資料筆數5)
      let bd = (objP1[i].birthDay) + "生 " + digitLowercase(objP1[i].live_year || '') + "歲";
      doc.text(toStraight(bd), 813 - word_width, 150); // 測試家庭成員 資料
      word_width += 25;
    }
  } else {
    doc.line(450, 100, 820, 100); //第1條 橫線 (資料筆數36)
    doc.line(450, 350, 820, 350); //第2條 橫線 (資料筆數36)
    doc.line(450, 430, 820, 430); //第3條 橫線 (資料筆數36)

    let word_width = 20;
    doc.setFontSize(15);
    for (let i = 0; i < objP1.length; i++) {  //依序增加36筆資料
      doc.text(toStraight(objP1[i].livename), 818 - word_width, 25); // 測試家庭成員 資料 (資料筆數10)
      let bd = (objP1[i].birthDay) + "生 " + digitLowercase(objP1[i].live_year || '') + "歲";
      doc.text(toStraight(bd), 818 - word_width, 105); // 測試家庭成員 資料

      if (objP1[i + 18].livename) {
        toStraight(doc.text(objP1[i + 18].livename), 818 - word_width, 355); // 測試家庭成員 資料 (資料筆數10)
        bd = (objP1[i + 18].birthDay) + "生 " + digitLowercase(objP1[i].live_year || '') + "歲";
        doc.text(toStraight(bd), 818 - word_width, 435); // 測試家庭成員 資料
      }
      word_width += 20;
    }
  }
  doc.setFont('BiauKai');
  doc.setFontSize(55);

  let title = "";
  if (objP1[0].praytypeText && objP1[0].praytypeText.indexOf("智慧") !== -1) {
    title = "開智慧文疏";
    doc.text(toStraight(title), 925, 170);
  } else {
    title = "消災補運文疏";
    doc.text(toStraight(title), 925, 130);
  }

  doc.text(toStraight((objP1.length && objP1.praytypeText) || ''), 925, 170);
  doc.text(toStraight((objP1.length && objP1.praytypeText) || ''), 925, 130);
  doc.setFontSize(20);
  doc.text(toStraight("伏  以"), 885, 105);
  doc.text(toStraight("大教遠流 示一乘歸元之路  神功叵測 開眾生方便之門   今據"), 865, 20);

  if (!!objP1[0].addr) {
    doc.text(toStraight(objP1[0].addr), 845, 20); //輸入地址變數
  }

  //doc.text("口口口口口口口口口口口口口口口口口口口".toStraight(), 845, 20);  //地址
  doc.text(toStraight("吉宅居住"), 845, 565);
  doc.text(toStraight("植福延禧 誦經消災  祈安延壽信"), 825, 20);
  doc.text(toStraight("暨合家人等同誠焚香叩首叩拜"), 420, 420);
  doc.text(toStraight("諸佛菩薩"), 400, 20);
  doc.text(toStraight("列位仙真座前消災解厄"), 400, 120);
  doc.text(toStraight("添福延壽 祈求凶事脫出"), 400, 360 + 10);
  doc.text(toStraight("吉事降臨"), 400, 580 + 60);
  doc.text(toStraight(("身體平安康泰上天好生之德 聖神愍物之慈 欣逢 " + meetname)), 380, 20);
  doc.text(toStraight("聖教敢不傾心是以涓向今歲次 "), 360, 20);
  // doc.text((y + "年"+ m + "月"+ dd + "日 吉旦 恭就").toStraight(), 360, 290); //年月日-日期 變數
  doc.text(toStraight((in_date + " 吉旦 恭就")), 360, 290 + 30); //年月日-日期 變數

  doc.text(toStraight("龍潭慈惠堂  聖神座前  舖設淨壇  延仗聖門       諷誦"), 340, 20);
  doc.text(toStraight("諸佛秘章真言"), 320, 20);
  doc.text(toStraight("虔備香花果品  妙供                   上奉"), 300, 40);
  doc.text(toStraight("無極瑤池大聖西王金母大天尊"), 280, 20);
  doc.text(toStraight("玄穹高上帝玉皇大天尊"), 260, 20);
  doc.text(toStraight("南無無上虛空地母尊佛"), 240, 20);
  doc.text(toStraight("十方三寶"), 220, 20);
  doc.text(toStraight("萬德千尊 並及諸天護法神祗光降供筵 慈悲納受        伏願"), 200, 20);
  doc.text(toStraight("神光普照信"), 180, 20);

  let name_convert = "";
  if (!!objP1.livename) {
    name_convert = objP1.livename.replace(",", " ").substr(0, 4); //姓名變數最多只取1個人(4字元)
  }

  doc.text(toStraight(name_convert), 180, 130); // 姓名變數

  //doc.text("口口口".toStraight(), 180, 135); // 姓名
  doc.text(toStraight("毫光遠暨 將暗室而重光 法雨普沾 使枯枝而再潤"), 180, 215);
  doc.text(toStraight("頓出塵勞之海 高登正覺之場 六根清淨 本來面目重明 三業全消 自"), 160, 20);

  doc.text(toStraight("性靈光復顯 災殃散盡 福壽綿長 家門昌盛以長春 眷屬和睦於永歲"), 140, 20);
  doc.text(toStraight("愿行善積德以植福 更祈 合家迪吉 人口平安 四時無災 八節有慶"), 120, 20);
  doc.text(toStraight("男添百福 女納千祥 所求如意 大降吉祥         謹疏以"), 100, 20);
  doc.text(toStraight("聞"), 80, 25);

  // doc.text(("天運歲次 "+ y + "年"+ m + "月"+ dd + "日 吉旦" + " 沐恩信主 " + name_convert + " 叩拜上申").toStraight(), 60, 20);
  doc.text(toStraight(("天運歲次 " + in_date + " 吉旦" + " 沐恩信主 " + name_convert + " 叩拜上申")), 60, 20);
}
// 禳燈消災植福疏文 光明燈
export const prayPage36 = (doc, objP1, in_date, meetname) => {  // 禳燈消災植福疏文

  //標題文字外框
  doc.setLineWidth(6);
  // doc.strokeOpacity(0.5);
  doc.strokeColor("#666");
  doc.rect(920, 30, 65, 550);

  //標題文字 外框線
  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)
  doc.rect(915, 25, 75, 560);

  //內文字外框
  doc.setLineWidth(2);
  doc.setDrawColor(0, 0, 0)
  doc.rect(450, 20, 370, 665);

  doc.setFont('BiauKai');
  doc.setFontSize(53);
  doc.text(toStraight("禳燈消災植福疏文"), 925, 95);

  doc.setFontSize(20);
  doc.text(toStraight("伏  以"), 885, 105);
  doc.text(toStraight("大教遠流 示一乘歸元之路  神功叵測 開眾生方便之門   今據"), 865, 20);

  if (!!objP1[0].addr)
    doc.text(toStraight(objP1[0].addr), 845, 20); //輸入地址變數

  //doc.text("口口口口口口口口口口口口口口口口口口口".toStraight(), 845, 20);  //地址
  doc.text(toStraight("吉宅居住"), 845, 565);
  doc.text(toStraight("植福延禧 誦經消災  祈安延壽信"), 825, 20);

  doc.setLineWidth(1);

  doc.setFont('BiauKai');

  if (objP1.length < 14) {
    doc.line(450, 130, 820, 130); //第1條 橫線 (資料筆數14)
    let word_width = 25;
    doc.setFontSize(20);
    for (let i = 0; i < objP1.length; i++) { //依序增加14筆資料

      doc.text(toStraight(objP1[i].livename), 813 - word_width, 28); // 測試家庭成員 資料 (資料筆數5)
      let bd = (objP1[i].birthDay) + "生 " + digitLowercase(objP1[i].live_year || '') + "歲";
      doc.text(toStraight(bd), 813 - word_width, 150); // 測試家庭成員 資料
      word_width += 25;
    }
  } else {
    doc.line(450, 100, 820, 100); //第1條 橫線 (資料筆數36)
    doc.line(450, 350, 820, 350); //第2條 橫線 (資料筆數36)
    doc.line(450, 430, 820, 430); //第3條 橫線 (資料筆數36)

    let word_width = 20;
    doc.setFontSize(15);
    for (let i = 0; i < objP1.length; i++) {  //依序增加36筆資料
      doc.text(toStraight(objP1[i].livename), 818 - word_width, 25); // 測試家庭成員 資料 (資料筆數10)
      let bd = (objP1[i].birthDay) + "生 " + digitLowercase(objP1[i].live_year || '') + "歲";
      doc.text(toStraight(bd), 818 - word_width, 105); // 測試家庭成員 資料

      if (objP1[i + 18].livename) {
        doc.text(toStraight(objP1[i + 18].livename), 818 - word_width, 355); // 測試家庭成員 資料 (資料筆數10)
        bd = (objP1[i + 18].birthDay) + "生 " + digitLowercase(objP1[i].live_year || '') + "歲";
        doc.text(toStraight(bd), 818 - word_width, 435); // 測試家庭成員 資料
      }
      word_width += 20;
    }
  }

  doc.setFontSize(20);
  doc.text(toStraight("暨合家人等同誠焚香叩首叩拜"), 420, 420);
  doc.text(toStraight("諸佛菩薩"), 400, 20);
  doc.text(toStraight("列位仙真座前消災解厄"), 400, 120);
  doc.text(toStraight("添福延壽 祈求凶事脫出"), 400, 330);
  doc.text(toStraight("吉事降臨"), 400, 580);
  doc.text(toStraight(("身體平安康泰上天好生之德 聖神愍物之慈 欣逢 " + meetname)), 380, 20);
  doc.text(toStraight("聖教敢不傾心是以涓向今歲次"), 360, 20);

  // doc.text((y + "年"+ m + "月"+ dd + "日 ").toStraight(), 360, 290); //年月日-日期 變數
  doc.text(toStraight((in_date + " 吉旦 恭就")), 360, 290); //年月日-日期 變數
  //doc.text((y + "年"+ m + "月"+ dd + "日 吉旦 恭就").toStraight(), 360, 290); //年月日-日期 變數

  /*doc.text(toStraight("口口"), 360, 290); // 年
  doc.text(toStraight("年"), 360, 340);
  doc.text(toStraight("口口"), 360, 370); // 月
  doc.text(toStraight("月"), 360, 420);
  doc.text(toStraight("口口"), 360, 450); // 日
  doc.text(toStraight("日"), 360, 500);*/
  // doc.text("吉旦 恭就".toStraight(), 360, 560);

  doc.text(toStraight("龍潭慈惠堂  聖神座前  舖設淨壇  延仗聖門       諷誦"), 340, 20);
  doc.text(toStraight("諸佛秘章真言"), 320, 20);
  doc.text(toStraight("虔備香花果品  妙供                   上奉"), 300, 40);
  doc.text(toStraight("無極瑤池大聖西王金母大天尊"), 280, 20);
  doc.text(toStraight("玄穹高上帝玉皇大天尊"), 260, 20);
  doc.text(toStraight("南無無上虛空地母尊佛"), 240, 20);
  doc.text(toStraight("十方三寶"), 220, 20);
  doc.text(toStraight("萬德千尊 並及諸天護法神祗光降供筵 慈悲納受        伏願"), 200, 20);
  doc.text(toStraight("神光普照信"), 180, 20);

  let name_convert = "";
  if (!!objP1[0].whoapplyText) {
    name_convert = objP1[0].whoapplyText.replace(",", " ").substr(0, 4); //姓名變數最多只取1個人(4字元)
  } else {
    name_convert = objP1[0].livename.replace(",", " ").substr(0, 4); //姓名變數最多只取1個人(4字元)
  }

  doc.text(toStraight(name_convert), 180, 130); // 姓名變數
  //doc.text("口口口".toStraight(), 180, 135); // 姓名
  doc.text(toStraight("毫光遠暨 將暗室而重光 法雨普沾 使枯枝而再潤"), 180, 215);
  doc.text(toStraight("頓出塵勞之海 高登正覺之場 六根清淨 本來面目重明 三業全消 自"), 160, 20);

  doc.text(toStraight("性靈光復顯 災殃散盡 福壽綿長 家門昌盛以長春 眷屬和睦於永歲"), 140, 20);
  doc.text(toStraight("愿行善積德以植福 更祈 合家迪吉 人口平安 四時無災 八節有慶"), 120, 20);
  doc.text(toStraight("男添百福 女納千祥 所求如意 大降吉祥         謹疏以"), 100, 20);
  doc.text(toStraight("聞"), 80, 25);

  // doc.text(("天運歲次 "+ y + "年"+ m + "月"+ dd + "日 信  " + name_convert + "        具疏上申").toStraight(), 60, 20);
  doc.text(toStraight(("天運歲次 " + in_date + " 信  " + name_convert + "        具疏上申")), 60, 20);
  //doc.text(("天運歲次 "+ y + " 年 "+ m + " 月 "+ dd + " 日 ").toStraight(), 60, 20); //年月日-日期 變數

  /*doc.text("天運歲次".toStraight(), 60, 20);
  doc.text(toStraight("口口"), 60, 125); // 年
  doc.text(toStraight("年"), 60, 170);
  doc.text(toStraight("口口"), 60, 195); // 月
  doc.text(toStraight("月"), 60, 240);
  doc.text(toStraight("口口"), 60, 265); // 日
  doc.text(toStraight("日"), 60, 310);*/
  //doc.text("信".toStraight(), 60, 385);
  //doc.text("口口口".toStraight(), 60, 480); // 姓名
  //doc.text("具疏上申".toStraight(), 60, 580);
}
export const memberPage191 = (doc, arrObj) => { // 郵寄正面

  // let black = "#000";
  // doc.strokeColor(black);
  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)
  doc.rect(25, 25, 550, 350 + 300);
  // doc.fillColor(black);

  doc.setLineWidth(6);
  doc.setDrawColor(0, 0, 0)
  doc.rect(140 + 130, 50 + 50, 75, 400);

  doc.setLineWidth(1);  // 郵遞區號- 方框1
  doc.rect(117, 456, 13, 23);
  doc.setLineWidth(1);  // 郵遞區號- 方框2
  doc.rect(102, 456, 13, 23);
  doc.setLineWidth(1);  //郵遞區號- 方框3
  doc.rect(84, 456, 13, 23);
  doc.setLineWidth(1);  // 郵遞區號- 方框4
  doc.rect(65, 456, 13, 23);
  doc.setLineWidth(1);  // 郵遞區號- 方框5
  doc.rect(50, 456, 13, 23);
  doc.setLineWidth(1);  // 郵遞區號- 方框6
  doc.rect(35, 456, 13, 23);

  // for (let i = 0; i < objP1.length; i++) {
  for (let i = 0; i < arrObj.length; i++) {
    let entry = arrObj[i];

    if (!entry.name || typeof entry.name === "undefined") {
      continue;
    }
    // console.log(entry);

    doc.setFont('BiauKai');
    doc.setFontSize(60);
    doc.text(toStraight("請柬"), 230 + 130, 40 + 30);
    doc.setFontSize(40);
    doc.text(toStraight("大德"), 155 + 50, 340 + 100);

    let name = entry.name;
    if (name.length <= 3) {
      doc.text(toStraight(name), 155 + 130, 55 + 50); //收件人 姓名(3字)
    } else if (name.length > 6) {
      doc.setFontSize(23);
      doc.text(toStraight(name), 155 + 130, 75 + 50); //收件人 姓名(公司名稱)
    } else {
      doc.text(toStraight(name), 155 + 130, 100 + 50); //收件人 姓名(4字)
    }

    //doc.setFontSize(26);
    //doc.text("龍潭慈惠堂興建委員會 緘".toStraight(), 105, 140);

    //doc.setFontSize(14);
    //doc.text("桃園市龍潭區楊銅路一段三二〇巷一五〇號".toStraight(), 80, 185); //寄件人 地址
    //doc.text("電話： 〇三 四八九四二八八".toStraight(), 65, 185);
    //doc.text("九".toStraight(), 65, 400);
    //doc.text("傳真： 〇三 四七〇七三三〇".toStraight(), 50, 185);
    //doc.text("郵政劃撥：".toStraight(), 35, 185);
    doc.setFontSize(15);

    if (!!entry.post5code) {
      let code = entry.post5code;
      if (code.length <= 3) {
        doc.text(code, 275, 195); //郵遞區號
      }
      else {
        doc.text(code, 255, 175); //郵遞區號
      }
    }

    if (!!entry.addr) {
      doc.text(toStraight(entry.addr.substr(0, 16)), 290 + 200, 215); //收件人 地址
      doc.text(toStraight(entry.addr.substr(16)), 270 + 200, 215); //收件人 地址
    }
    doc.addPage();

    if (i === 300) break;
  }
  //doc.setFont('Times', 'Roman');
  //doc.setFontSize(17);
  //doc.rotate(90, {origin: [67, 260]}).text("16868316", 67, 260);
  //doc.rotate(-90, {origin: [67, 260]});

  //doc.setFontSize(20);
  //doc.text("3 2 5  -  4 3", 37, 460); //寄件人 地址
  //doc.text("0 0 0  -  0 0", 240, 170); //收件人 地址

  //doc.setFont('BiauKai')
  //doc.setFontSize(14);
  //doc.text("戶名：曾鴻昌".toStraight(), 35, 345);
  //doc.rotate(90, {origin: [64, 230]});
  //doc.text("(  )", 64, 230);  //電話
  //doc.text("(  )", 64, 215);  //傳真
  //doc.text("(      )", 170, 245);  //郵政劃撥
  //doc.text("~", 220, 221);  //郵政劃撥

  //doc.text("( 16868316 )".toStraight(), 40, 270);
  //doc.text("口口口口".toStraight(), 540, 220); // 拔渡
  //doc.text("靈魂茲逢普度".toStraight(), 540, 335);
}
export const memberPage192 = (doc, arrObj, in_date) => {  // 請柬--背面

  // let red = "#B22222";
  doc.setDrawColor(2, 2, 2);
  // doc.fillColor(red);
  doc.setFont('BiauKai');
  doc.setFontSize(14);
  doc.text(toStraight("時維臘月、節屆季冬、恭維閤府履祺納福、財源亨通、為祝為頌。"), 310, 35); //寄件人 地址
  doc.text(toStraight("祈求風調雨順、國泰民安、元辰光彩、閤家迪吉、萬事如意、敬請諸"), 230, 35); //寄件人 地址
  doc.text(toStraight("大德如需禮點光明燈及安奉太歲星君、拜斗等善信請即日起提早前來"), 210, 35); //寄件人 地址
  doc.text(toStraight("本堂報名登記，以便整理呈疏。 順 祝 "), 190, 35); //寄件人 地址

  // doc.text(toStraight("中華民國"), 40, 90);
  doc.text(toStraight("中華民國" + in_date + "吉日"), 40, 90);
  // doc.text(toStraight("口口口"), 40, 155); // 年
  // doc.text(toStraight("年"), 40, 200);
  // doc.text(toStraight("口口"), 40, 220); // 月
  // doc.text(toStraight("月"), 40, 255);
  // doc.text(toStraight("口口"), 40, 280); // 日
  // doc.text(toStraight("日"), 40, 320);
  // doc.text(toStraight("吉 日"), 40, 365);

  doc.setFont('BiauKai');
  doc.setFontSize(20);
  doc.text(toStraight("閤府康健  萬事如意"), 165, 35);

  doc.setFont('BiauKai');
  doc.setFontSize(15);
  doc.text(toStraight("本堂爰例於"), 290, 33);
  doc.text(toStraight("星期日上午九時，為感謝瑤池金母及五斗星君"), 290, 175 + 50);
  doc.text(toStraight("◎請備辦水果前來本堂誠心答謝。"), 145, 33);
  doc.text(toStraight("◎禮點光明燈及安奉太歲 牛‧羊 、犯天狗、白虎者請即日"), 125, 33);
  doc.text(toStraight("起，來本堂報名登記整理 呈疏 謝謝。"), 105, 33);
  doc.text(toStraight("★十一點團拜"), 85, 33);
  doc.text(toStraight("龍潭慈惠堂興建委員會 敬啟"), 70, 230);

  doc.setFont('BiauKai');
  doc.setFontSize(16);
  doc.text(toStraight("之庇佑，舉行謝平安 謝斗 典禮，並於一〇四年"), 270, 33);
  doc.text(toStraight("星期日為慶祝玉皇大天尊聖誕，舉行祈安禮斗"), 250, 33);
  doc.text(toStraight("開燈點斗"), 250, 358 + 60);
  doc.text(toStraight("大法會"), 250, 426 + 90);

  doc.setFont('BiauKai');
  doc.setFontSize(10);
  doc.text(toStraight("國曆一月廿五日"), 298, 107 + 20);  //國曆 日期
  doc.text(toStraight("農曆十月廿七日"), 288, 107 + 20);  //農曆 日期

  doc.text(toStraight("國曆一月廿五日"), 278, 400 + 50);  //國曆 日期
  doc.text(toStraight("農曆十月廿七日"), 268, 400 + 50);  //農曆 日期

  doc.text(toStraight({ rotate: '90deg' }), 283, 180);
  doc.text("(    )", 283, 180);  //舉行典禮 括號
  doc.text("(      )", 455, 200);  //大法會 括號
  doc.text("(     )", 305, 325);  //牛羊 括號
}
export const memberPage193 = (doc, arrObj = [], printDate) => {  // 敬邀--正面

  doc.setLineWidth(1);
  // let red = "#B22222";
  doc.setDrawColor(2, 2, 2);
  doc.rect(25, 25, 550, 350 + 300);
  // doc.fillColor(red);

  doc.setLineWidth(6);
  doc.setDrawColor(0, 0, 0)
  doc.rect(140 + 130, 50 + 50, 75, 400);

  doc.setFont('BiauKai');
  doc.setFontSize(52);
  doc.text(toStraight("敬邀"), 260 + 250, 40 + 30);
  doc.setFontSize(40);
  doc.text(toStraight((arrObj.length && arrObj[0].name) || ''), 155 + 130, 100 + 50); //收件人 姓名

  doc.setFontSize(17);
  doc.text(toStraight("桃園市龍潭區高原里泥橋子  號"), 95, 165); //寄件人 地址
  doc.text(toStraight("電話："), 65, 185 + 250);
  doc.text(toStraight("傳真："), 46, 185 + 250);

  doc.setFontSize(16);
  doc.text(toStraight((arrObj.length && arrObj[0].addr) || ''), 290 + 200, 190); //收件人 地址
  // doc.text(toStraight("口口口口"), 270, 190); //收件人 地址
  doc.setFont('Times', 'Roman');
  doc.setFontSize(17);
  doc.text(toStraight({ rotate: '90deg' }), 67, 260);
  doc.text("(03)   4894288", 52, 246 + 300);
  doc.text("(03)   4707330", 52, 266 + 300);
  doc.text("5-8", 182 - 87, 217 + 190);

  doc.text(toStraight({ rotate: '-90deg' }), 67, 260);
  doc.setFontSize(18);
  doc.text("325", 90, 145); //寄件人 地址
  doc.text("0 0 0  -  0 0", 240 + 200, 170); //收件人 地址

  doc.setFont('BiauKai')
  doc.setFontSize(14);
  doc.text(toStraight({ rotate: '90deg' }), 62, 230);
  // doc.text("( )", 67, 210);  //傳真
  // doc.text("( )", 67, 230);  //電話
}
export const prayPage131 = (doc, arrObj, printDate) => {
  // console.log(this.request);
  // console.log(this.queryParams);
  // console.log(this.urlParams);

  /*
  { date: '2016/6/1',
    date_submit: '',
    year: '105',
    whoapply: 'xAjpAHv6qaBSuoPup',
    prayname: 'nqnw6qAPFwJHbYR5L',
    prayitem: 'WC46TzrPgupuwpTAk',
    serial: '15',
    cash: '50000' }
   */
  doc.setLineWidth(2);
  doc.setDrawColor(0, 0, 0)
  doc.rect(50, 45, 490, 750);

  doc.setFont('BiauKai');
  doc.setFontSize(45);
  doc.text(toStraight("報 名 表 格"), 543, 80);

  doc.setFont('BiauKai');
  doc.setFontSize(22);
  doc.text(toStraight("報名善信"), 512, 74);
  doc.text(toStraight("電話"), 512, 554);

  doc.text(toStraight("地 址"), 478, 85);
  doc.text(toStraight("原籍"), 477, 554);

  doc.text(toStraight("被超度者"), 442, 194);
  doc.text(toStraight("生卒年月日時 詳細錄此 "), 442, 353);
  doc.text(toStraight("財寶箱"), 442, 717);
  doc.text(toStraight("歷代祖先"), 390, 74);
  doc.text(toStraight("先亡魂儀"), 355, 74);
  doc.text(toStraight("解冤釋結"), 300, 74);
  doc.text(toStraight("前生父母"), 270, 74);
  doc.text(toStraight("嬰  靈"), 215, 74);
  doc.text(toStraight("冤親債主"), 92, 74);
  doc.text(toStraight("普度孤幽"), 57, 74);
  doc.setFontSize(20);
  doc.text(toStraight("拜懺"), 145, 65);
  doc.text(toStraight("水 懺"), 160, 108);
  doc.text(toStraight("血湖懺"), 130, 108);
  doc.setFont('BiauKai');
  doc.text(toStraight("項目"), 438, 63);
  doc.text(toStraight("詳細"), 448, 137);

  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)
  doc.line(505, 46, 505, 796); //直欄 1 直線
  doc.line(470, 46, 470, 796); //直欄 2 直線
  doc.line(435, 46, 435, 796); //直欄 3 直線
  doc.line(470, 46, 435, 165);   //直欄 3 斜線
  doc.line(400, 165, 400, 796); //直欄 4 直線
  doc.line(365, 165, 365, 796); //直欄 5 直線
  doc.line(330, 46, 330, 796); //直欄 6 直線
  doc.line(295, 165, 295, 796); //直欄 7 直線
  doc.line(260, 46, 260, 796); //直欄 8 直線
  doc.line(225, 165, 225, 796); //直欄 9 直線
  doc.line(190, 46, 190, 796); //直欄 10 直線
  doc.line(155, 165, 155, 796); //直欄 11 直線
  doc.line(120, 46, 120, 796); //直欄 12 直線
  doc.line(85, 46, 85, 796); //直欄 13 直線

  doc.line(50, 165, 540, 165); //橫列 1 橫線
  doc.line(50, 285, 540, 285); //橫列 2 橫線
  doc.line(470, 520, 540, 520); //橫列 3 橫線
  doc.line(470, 600, 540, 600); //橫列 4 橫線

  doc.setLineWidth(2);
  doc.setDrawColor(0, 0, 0)
  doc.line(50, 680, 470, 680); //橫列 5 橫線

  doc.setFont('BiauKai')
  doc.setFontSize(25);
  doc.text("(        )", 461, 626, 90);
}
export const prayPage137 = (doc, arrAll = [], start) => { // 圓桌名條
  // console.log(this.request);
  // console.log(this.queryParams);
  // console.log(this.urlParams);

  // doc.setLineWidth(2);
  doc.setDrawColor(0, 0, 0)
  // doc.rect(50, 45, 490, 750);
  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)
  doc.setFont('BiauKai');

  let bottom1 = 429;
  let top = 0;
  let top_city = 0;
  let top_name = 0;
  let width = 0;
  let x_width = 120;
  let x_width1 = bottom1;
  let height = 709;
  // console.log("arrAll");
  // console.log(arrAll);
  let name_convert = ''
  for (let i = 0; i < arrAll.length; i++) {
    if (!!arrAll[start + i].livename) {
      if (arrAll[start + i].livename.length <= 2) { //當姓名為3字
        doc.setFontSize(65); //姓名
        doc.text(toStraight(arrAll[start + i].livename, 4), width + 15, top_name + 25 + 65);
      }
      else if (arrAll[start + i].livename.length <= 3) { //當姓名為3字
        doc.setFontSize(65); //姓名
        doc.text(toStraight(arrAll[start + i].livename, 2), width + 15, top_name + 25 + 65);
      }
      else if (arrAll[start + i].livename.length <= 4) { //當姓名為4字
        doc.setFontSize(65); //姓名
        doc.text(arrAll[start + i].livename.charAt(0), width + 30, top_name + 25 + 65);
        doc.text(arrAll[start + i].livename.charAt(1), width + 30, top_name + 25 + 65 + 65 + (65 / 3));
        doc.text(arrAll[start + i].livename.charAt(2), width + 30, top_name + 25 + 65 + 65 + (65 / 3) + (65 / 3) + 65);
        doc.text(arrAll[start + i].livename.charAt(3), width + 30, top_name + 25 + 65 + 65 + 65 + 65 + 65);
        // doc.text(arrAll[start+i].livename.toStraight(), width+30, top_name+90, { width: 70, align: 'justify'});
      }
      else if (arrAll[start + i].livename.length <= 5) { //當姓名為3字
        doc.setFontSize(65); //姓名
        doc.text(toStraight(arrAll[start + i].livename), width + 30, top_name + 25 + 65);
      }
      else if (arrAll[start + i].livename.length <= 6) { //當姓名為3字
        doc.setFontSize(55); //姓名
        doc.text(toStraight(arrAll[start + i].livename), width + 30, top_name + 25 + 65);
      }
      else if (arrAll[start + i].livename.length <= 7) { // 當姓名小於8字
        name_convert = arrAll[start + i].livename.replace(",", " ").substr(0, 7);
        doc.setFontSize(50); //姓名
        doc.text(toStraight(name_convert), width + 30, top_name + 68);
      }
      else if (arrAll[start + i].livename.length <= 8) { // 當姓名小於8字
        name_convert = arrAll[start + i].livename.replace(",", " ").substr(0, 8);
        doc.setFontSize(41); //姓名
        doc.text(toStraight(name_convert), width + 30, top_name + 68);
      }
      else if (arrAll[start + i].livename.length <= 9) { // 當姓名小於9字
        name_convert = arrAll[start + i].livename.replace(",", " ").substr(0, 9);
        doc.setFontSize(39); //姓名
        doc.text(toStraight(name_convert), width + 30, top_name + 68);
      }
      else if (arrAll[start + i].livename.length <= 10) { // 當姓名小於9字
        name_convert = arrAll[start + i].livename.replace(",", " ").substr(0, 10);
        doc.setFontSize(35); //姓名
        doc.text(toStraight(name_convert), width + 30 + 6, top_name + 68);
      }
      else { // 當姓名大於8字
        name_convert = arrAll[start + i].livename.replace(",", " ").substr(0, 14);
        doc.setFontSize(25); //姓名
        doc.text(toStraight(name_convert), width + 30, top_name + 68);
      }
      doc.setFontSize(28); //地點
      doc.text(toStraight(arrAll[start + i].addr), width + 83, top_city + 25);
    }
    //doc.setFontSize(65); //姓名
    //doc.text(arrAll[start+i].livename.toStraight(), width+30, top_name+25);
    doc.line(width, top, width, bottom1); //直欄 1~8 直線
    width += x_width;
  }
  doc.line(width, top, width, bottom1); //直欄 8 直線
  doc.line(0, bottom1, width, bottom1); //橫列 1 橫線

  width = 0;
  x_width = 120;

  // if(!arrAll[8].livename || !arrAll[9].livename || !arrAll[10].livename || !arrAll[11].livename )
  for (let i = 0; i < 3; i++) {
    doc.line(width, 469, width, 709); //直欄 9~10 直線
    doc.line(top, height, 858, height); //直欄 2~3 直線
    width += x_width1;
    height -= x_width;
  }

  let arrX = [130, 15, 130, 15];
  let arrY = [680, 680, 1110, 1110];
  for (let i = 0; i < 4; i++) {
    if (!arrAll[start + i + 8].livename) continue;

    // doc.rotate(-90);

    let str = arrAll[start + i + 8].livename;

    if (str.length <= 2) {  //當姓名為3字
      doc.setFontSize(65); // 旋轉180度 -姓名
      doc.text(toStraight(str, 4), arrX[i], arrY[i] + 65, -90);
    } else if (str.length <= 3) {  //當姓名為3字
      doc.setFontSize(65); // 旋轉180度 -姓名
      doc.text(toStraight(str, 2), arrX[i], arrY[i] + 60, -90);
    } else if (str.length <= 4) {  //當姓名為4字
      doc.setFontSize(65); // 旋轉180度 -姓名
      doc.text(str.charAt(0), arrX[i], arrY[i] + 65, -90);
      doc.text(str.charAt(1), arrX[i], arrY[i] + 65 + 65 + (65 / 3), -90);
      doc.text(str.charAt(2), arrX[i], arrY[i] + 65 + 65 + (65 / 3) + (65 / 3) + 65, -90);
      doc.text(str.charAt(3), arrX[i], arrY[i] + 65 + 65 + 65 + 65 + 65, -90);
      // doc.text(toStraight(str), arrX[i], arrY[i]+65, { width: 70, align: "justify"});
    } else if (str.length <= 5) {  //當姓名為3字
      doc.setFontSize(65); // 旋轉180度 -姓名
      doc.text(toStraight(str), arrX[i], arrY[i] + 65, -90);
    } else if (str.length <= 6) {  //當姓名為3字
      doc.setFontSize(55); // 旋轉180度 -姓名
      doc.text(toStraight(str), arrX[i], arrY[i] + 65, -90);
    } else if (str.length <= 7) { // 當姓名超過4字，但8字以下
      let name_convert = arrAll[start + i + 8].livename.replace(",", " ").substr(0, 8);
      doc.setFontSize(50); // 旋轉180度 -姓名
      doc.text(toStraight(name_convert), arrX[i], arrY[i] + 53, -90);
    } else if (str.length <= 8) { // 當姓名超過4字，但8字以下
      let name_convert = arrAll[start + i + 8].livename.replace(",", " ").substr(0, 8);
      doc.setFontSize(41); // 旋轉180度 -姓名
      doc.text(toStraight(name_convert), arrX[i], arrY[i] + 55, -90);
    } else if (str.length <= 9) { // 當姓名超過4字，但8字以下
      let name_convert = arrAll[start + i + 8].livename.replace(",", " ").substr(0, 9);
      doc.setFontSize(39); // 旋轉180度 -姓名
      doc.text(toStraight(name_convert), arrX[i], arrY[i] + 55, -90);
    } else if (str.length <= 10) { // 當姓名超過4字，但8字以下
      let name_convert = arrAll[start + i + 8].livename.replace(",", " ").substr(0, 10);
      doc.setFontSize(35); // 旋轉180度 -姓名
      doc.text(toStraight(name_convert), arrX[i] + 6, arrY[i] + 55, -90);
    } else { // 當姓名超過8字
      let name_convert = arrAll[start + i + 8].livename.replace(",", " ").substr(0, 14);
      doc.setFontSize(25); // 旋轉180度 -姓名
      doc.text(toStraight(name_convert), arrX[i], arrY[i] + 55, -90);
    }
    //doc.text(toStraight(str), arrX[i], arrY[i]);
    // doc.text(arrAll[start+9].livename.toStraight(), 140, 680);
    // doc.text(arrAll[start+10].livename.toStraight(), 140, 1110);
    // doc.text(arrAll[start+11].livename.toStraight(), 15, 1110);
    // doc.rotate(90);
  }

  doc.setFontSize(30);  // 旋轉180度 -地點
  // doc.rotate(-90, { origin: [20, 510] });

  // if (arrAll[start + 8].addr.length > 0)
  doc.text(toStraight(arrAll[start + 8].addr), 20, 510, -90);
  if (arrAll[start + 9].addr.length > 0)
    doc.text(toStraight(arrAll[start + 9].addr), -100, 510, -90);
  // doc.text(arrAll[start+9].toStraight(addr), 20, 940);
  if (arrAll[start + 10].addr.length > 0)
    doc.text(toStraight(arrAll[start + 10].addr), 20, 940, -90);
  // doc.text(arrAll[start+10].toStraight(addr), -100, 510);
  if (arrAll[start + 11].addr.length > 0)
    doc.text(toStraight(arrAll[start + 11].addr), -100, 940, -90);
}
export const prayPage138 = (doc, objP1 = [], start, prayname, total, in_date = '', lastNum) => { //法會名冊
  doc.setLineWidth(2);
  doc.setDrawColor(0, 0, 0)
  doc.rect(20, 45, 555, 780);

  let collum_bottom = 825;
  let collum_bottom1 = 774;
  let row_width = 77;  // 各橫列寬度為 25
  let data_width = 16;  // 各橫列-文字資料 高度間距為 6

  doc.setFont('BiauKai');
  doc.setFontSize(25);
  doc.text("龍潭慈惠堂 " + prayname + "名冊", 130, 30);

  // doc.setFont('Times', 'Roman'); //數字資料
  doc.setFont('BiauKai');
  doc.setFontSize(14);
  // doc.text(in_date  ,420 , 30, { width: 150, align: 'right'}); // 右上角顯示列印日期
  doc.text(in_date, 480, 30); // 右上角顯示列印日期

  doc.setFont('BiauKai');
  doc.setFontSize(16);

  // 欄位名稱
  doc.text("序號", 28, 67);
  doc.text("種類", 93, 67);
  doc.text("姓名", 174, 67);

  doc.text("地址", 352, 67);
  doc.text("金額", 522, 67);

  let subTotal = 0; //小計
  let total_num = total; //合計

  for (let i = 0; (i < objP1.length) && (i < 28); i++) {
    // console.log("objP1[i+start].whoapplyText");
    // console.log(objP1[i+start].whoapplyText);

    // 欄位文字資斛
    doc.setFont('Times', 'Roman'); //數字資料
    doc.setFontSize(12);
    doc.text(objP1[i + start].serial, 41, row_width + data_width); //序號變數

    //doc.text("0051", 25, row_width + data_width); //序號變數
    doc.text(objP1[i + start].cash, 530, row_width + data_width); //金額變數
    subTotal += Number(objP1[i + start].cash);
    //doc.text("12000", 515, row_width + data_width); //金額變數

    doc.setFont('BiauKai');
    doc.setFontSize(10.5);

    doc.text(objP1[i + start].field_type_text, 98, row_width + data_width); //種類變數
    doc.text(objP1[i + start].whoapplyText, 175, row_width + data_width); //輸入姓名變數
    doc.text(objP1[i + start].addr, 235, row_width + data_width); //輸入地址變數

    doc.setLineWidth(1);
    doc.line(20, row_width, 575, row_width); //各資料列-橫線

    row_width += 25;
  }
  //total = subTotal.toString();
  // 最後列欄位名稱
  doc.setFontSize(17);
  doc.text("小計", 25, 794);

  if (lastNum === 0) {
    doc.text("接下頁", 275, 805);

  }

  if (lastNum === 1) { //當為最後一頁時，顯示 總計欄位 金額
    doc.text("總計", 25, 818);
    doc.setFont('Times', 'Roman'); //數字資料
    doc.text(total_num, 523, 818); //總計金額 變數
  }

  doc.setFont('Times', 'Roman'); //數字資料
  doc.text(subTotal.toString(), 523, 794);   //小計金額 變數
  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)

  doc.line(500, 46, 500, collum_bottom1); //直欄 1 直線
  //doc.line(290, 46,290, collum_bottom1); //直欄 2 直線
  doc.line(230, 46, 230, collum_bottom1); //直欄 2 直線
  doc.line(150, 46, 150, collum_bottom1); //直欄 3 直線
  doc.line(70, 46, 70, collum_bottom1); //直欄 4 直線

  doc.line(20, row_width, 575, row_width); //欄位名稱-橫線

  doc.setLineWidth(2);
  doc.setDrawColor(0, 0, 0)
  doc.line(20, 777, 575, 777); //底部欄位(小計) 橫線
  doc.line(20, 800, 575, 800); //底部欄位(總計) 橫線
}

// const godhate = [
//   { rat: ["劍鋒、伏屍、歲駕", "將星"] },
//   { bull: ["天空、晦氣、歲合", "扳鞍、天乙"] },
//   { tiger: ["地雌、地喪、地猬", "孤辰、驛馬、遊奕", "天廚"] },
//   { rabbit: ["貫索、歲刑、勾神", "紅鸞、飛刃、唐符"] },
//   { dragon: ["年符、飛符、黃旛", "披頭、華蓋、三台", "空亡、國印"] },
//   { snack: ["小耗、破碎、的殺", "劫殺、枝德、月德"] },
//   { horse: ["闌干、大耗、天哭", "災殺"] },
//   { sheep: ["天厄、暴敗、歲害", "天殺、紫微、地解", "玉堂"] },
//   { monkey: ["天雄、飛廉、大殺", "指背、擎天、祿勳"] },
//   { chicken: ["絞殺、卷舌、桃花", "咸池、天德、天喜", "楊刃"] },
//   { dog: ["浮沉、血刃、豹尾", "寡宿、八座、天解", "孤虛"] },
//   { pig: ["越、亡神、文昌"] },
// ]

export const taisuiPage152 = (doc, arrObj, printDate) => {  // 太歲流年表
  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)
  doc.rect(50, 45, 610, 890);

  let collum_bottom = 935;
  let top = 46;
  let collum_width = 100;  // 各橫列寬度為 25
  let data_width = 0;  // 各橫列-文字資料 高度間距為
  let data_height = 0;  // 各橫列-文字資料 高度間距為
  let row_width = 45;
  doc.setFont('BiauKai');
  doc.setLineWidth(1);

  for (let i = 0; i < 11; i++) { // 畫出直欄各直線
    doc.line(collum_width, top, collum_width, collum_bottom); // 畫直線
    collum_width += 51;
  }

  for (let i = 0; i < 12; i++) { // 畫出橫列 各橫線
    if (i === 9) {
      row_width += 130;
    } else if (i === 11) {
      row_width += 90;
    } else {
      row_width += 51;
    }

    doc.line(50, row_width, 660, row_width); //各資料列-橫線
  }
  let nowyear = 2020;    //從今天開始算
  for (let i = 0; i < 8; i++) { // 8x12 天干地支 文字資料（該迴圈只適用鼠年）
    for (let j = 0; j < 12; j++) {
      let tempyear = 0;

      if (nowyear >= 1984) {
        tempyear = nowyear - 1984 + 1;
      } else {
        tempyear = nowyear - 1924 + 1;
      }
      let chineseNum = 1;
      let arrtimeNum = 1;

      for (let x = 1; x <= 60; x++) {     // 從甲子開始算，該歲數的天干地支是什麼
        if (tempyear === x) {
          break;
        }

        if (chineseNum === 10) {
          chineseNum = 1;
        } else {
          chineseNum += 1;
        }
        if (arrtimeNum === 12) {
          arrtimeNum = 1;
        } else {
          arrtimeNum += 1;
        }

      }

      doc.setFontSize(19);
      doc.text((chineseNumber[chineseNum] + arrTime[arrtimeNum]), 56 + data_width, 117 + data_height);
      doc.text(String((2020 - nowyear) + 1), 62 + data_width, 139 + data_height);

      if (data_width === 0) {
        nowyear -= 11;
      } else {
        nowyear += 1;
      }

      data_width += 51;
    }

    data_width = 0;
    data_height += 51;
    nowyear -= 12;
  }

  collum_width = 103;
  let starttime = 23;       //文字資料裡的起始時間

  for (let i = 0; i < 12; i++) { //其他欄位文字資料
    doc.setFontSize(40);
    doc.text(arrChineseYear[i + 1], collum_width - 48, 81);
    doc.text(arrTime[i + 1], collum_width - 48, 671);

    doc.setFontSize(14);
    if (i === 0) {     //鼠
      doc.text(toStraight("劍鋒、伏屍、歲駕"), collum_width - 50, 518);
      doc.text(toStraight("將星"), collum_width - 35, 518);
    } else if (i === 1) {     //牛
      doc.text(toStraight("天空、晦氣、歲合"), collum_width - 50, 518);
      doc.text(toStraight("扳鞍、天乙"), collum_width - 35, 518);
    } else if (i === 2) {    //虎
      doc.text(toStraight("地雌、地喪、地猬"), collum_width - 50, 518);
      doc.text(toStraight("孤辰、驛馬、遊奕"), collum_width - 35, 518);
      doc.text(toStraight("天廚"), collum_width - 20, 518);
    } else if (i === 3) {     //兔
      doc.text(toStraight("貫索、歲刑、勾神"), collum_width - 50, 518);
      doc.text(toStraight("紅鸞、飛刃、唐符"), collum_width - 35, 518);
    } else if (i === 4) {     //龍
      doc.text(toStraight("年符、飛符、黃旛"), collum_width - 50, 518);
      doc.text(toStraight("披頭、華蓋、三台"), collum_width - 35, 518);
      doc.text(toStraight("空亡、國印"), collum_width - 20, 518);
    } else if (i === 5) {     //蛇
      doc.text(toStraight("小耗、破碎、的殺"), collum_width - 50, 518);
      doc.text(toStraight("劫殺、枝德、月德"), collum_width - 35, 518);
    } else if (i === 6) {     //馬
      doc.text(toStraight("闌干、大耗、天哭"), collum_width - 50, 518);
      doc.text(toStraight("災殺"), collum_width - 35, 518);
    } else if (i === 7) {     //羊
      doc.text(toStraight("天厄、暴敗、歲害"), collum_width - 50, 518);
      doc.text(toStraight("天殺、紫微、地解"), collum_width - 35, 518);
      doc.text(toStraight("玉堂"), collum_width - 20, 518);
    } else if (i === 8) {    //猴
      doc.text(toStraight("天雄、飛廉、大殺"), collum_width - 50, 518);
      doc.text(toStraight("指背、擎天、祿勳"), collum_width - 35, 518);
    } else if (i === 9) {    //雞
      doc.text(toStraight("絞殺、卷舌、桃花"), collum_width - 50, 518);
      doc.text(toStraight("咸池、天德、天喜"), collum_width - 35, 518);
      doc.text(toStraight("楊刃"), collum_width - 20, 518);
    } else if (i === 10) {    //狗
      doc.text(toStraight("浮沉、血刃、豹尾"), collum_width - 50, 518);
      doc.text(toStraight("寡宿、八座、天解"), collum_width - 35, 518);
      doc.text(toStraight("孤虛"), collum_width - 20, 518);
    } else if (i === 11) {   //豬
      doc.text(toStraight("驀越、亡神、文昌"), collum_width - 50, 518);
    }

    let endtime = starttime + 2;   //一時辰2小時
    if (endtime > 24) endtime -= 24; //24小時制,超過24點就-24

    doc.text(String(starttime) + ":00", collum_width - 45, 715);
    doc.text(toStraight("|"), collum_width - 35, 735);
    doc.text(String(endtime) + ":00", collum_width - 45, 755);

    starttime += 2;
    if (starttime > 24) starttime -= 24;

    doc.text(toStraight("天亡、白虎、五鬼"), collum_width - 35, 801);

    collum_width += 51;
  }
  console.log('123')
}
export const prayPage139 = (doc, obj1, obj2, in_date) => {  // 財庫封條
  // console.log("obj1");
  // console.log(obj1);
  // console.log("obj2");
  // console.log(obj2);

  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)
  doc.setFont('BiauKai');

  let bottom = 1190;
  let top = 0;
  let width = 210;
  let x_width = 211;
  let x_width2 = 210;
  let data_width = 0;

  let obj = {};
  for (let i = 0; i < 4; i++) {
    doc.line(width, top, width, bottom); //直欄 1~4 直線
    doc.setFontSize(85);
    doc.text(toStraight("靈寶大法司"), 65 + data_width, 80);
    doc.text(toStraight("封"), 65 + data_width, 1160);

    doc.setFontSize(34);
    doc.text(toStraight("付故"), 90 + data_width, 535);

    if (i < 2) {
      obj = obj1; // 0 1
    } else {
      obj = obj2; // 2 3
    }
    let fontsize1 = 34;
    let fontsize2 = fontsize1 - fontsize1 / 5;
    let l1_height = 640;
    doc.setFontSize(fontsize2);
    let pname = "";
    if (!!obj.lastname1) {
      let l1 = "公";
      if (!!obj.lastname2) {
        l1 = "媽";
      }
      doc.text((l1), 91 + data_width + 8, l1_height + fontsize1 + 4);
      pname = obj.lastname1 + " ";
    }
    if (!!obj.lastname2) {
      pname = pname + obj.lastname2 + " ";
      let l2_height = 0;
      if (!!obj.lastname1) { // 如果上面有 那就下面一點
        l2_height = l1_height + fontsize1 * 3 + 15;
      } else {
        l2_height = l1_height + fontsize1 + 15;
      }
      doc.text("氏", 91 + data_width + 8, l2_height);
    }
    doc.setFontSize(fontsize1);
    pname = pname + obj.passname;
    doc.text(toStraight(pname), 90 + data_width, l1_height);

    doc.setFontSize(34);
    doc.text(toStraight("乙位正魂"), 90 + data_width, 962);
    doc.text(toStraight(("天運 " + in_date)), 160 + data_width, 525);

    doc.text(toStraight(("陽世 " + obj.livename)), 20 + data_width, 525);
    doc.text(toStraight(" 敬奉"), 20 + data_width, 1045);

    width += x_width;
    data_width += x_width2;
  }
}
export const taisuiPage153 = (doc, objP1, start, total, printdate, lastNum = 0) => { // 太歲清單

  doc.setDrawColor(0, 0, 0)
  doc.rect(20, 50, 555, 775);

  let collum_bottom = 825;
  let collum_top = 50;
  let row_width = 70;  // 各橫列寬度為

  doc.setFont('BiauKai');
  doc.setFontSize(25);
  doc.text("龍潭慈惠堂太歲清單", 180, 36);  // 表格名稱

  // doc.setFont('Times', 'Roman');
  doc.setFont('BiauKai');
  doc.setFontSize(15);
  // doc.text(printdate, 440, 30); // 右上角顯示列印日期
  //doc.text("0105/02/02",500 , 30, { width: 80, align: 'justify'});  // 製作日期

  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)
  doc.line(20, collum_top, 575, collum_top); //頂部標題名稱 橫線
  // doc.line(20, row_width,575, row_width); //欄位名稱-橫線

  let arrWidth = [30, 90, 40, 120, 40, 170, 40]; // 1~8 各直欄-直線欄寬
  let collum_width = 30;

  let arr_col_name = ["編號", "姓名", "性別", "農曆生辰", "生肖", "地址", "金額"]; // 1~9 各欄位名稱
  let arrCol_name_width = [26, 91, 156, 221, 316, 418, 534]; // 1~8 各欄位名稱-文字欄寬
  let col_name_width = 0;

  doc.setLineWidth(1);
  doc.setFont('BiauKai');
  doc.setFontSize(14);

  for (let i = 0; i < arr_col_name.length; i++) {

    doc.text(arr_col_name[i], arrCol_name_width[i], 65);  // 1~9 各欄位名稱

    collum_width += arrWidth[i]; //從陣列中 取第2個欄位 寬度間距 數值
    col_name_width += arrWidth[i]; //從陣列中 取第2個欄位 寬度間距 數值

    if (i !== arr_col_name.length - 1) {
      doc.line(collum_width, collum_top, collum_width, collum_bottom); //直欄 1~10 各直線
    }
  }

  // let arrData_name_width = [90, 90, 60, 45, 60, 40, 45, 70, 80]; // 1~9 各資料列-文字欄寬
  // let data_name_width = 0;
  let data_width = 13;

  let subTotal = 0; //小計
  let total_num = total; //合計

  for (let i = 0; i < 28 && i < objP1.length; i++) {
    if (!!objP1[i + start].livename) {
      const { serial = '', cash = '', livename = '', sexualId = '', birthDay = '', zodiacId = '', addr = '' } = objP1[i + start]
      // 欄位文字資斛
      doc.setFont('Times', 'Roman'); //數字資料
      doc.setFontSize(14);
      doc.text(serial || '', 35, row_width + data_width + 3); //序號變數

      //doc.text("0051", 25, row_width + data_width); //序號變數
      doc.text(cash, 537, row_width + data_width + 3); //金額變數
      subTotal += Number(cash);
      //doc.text("12000", 515, row_width + data_width); //金額變數

      doc.setFont('BiauKai');
      doc.setFontSize(13);

      doc.text(livename, 85, row_width + data_width + 3); //輸入姓名變數
      doc.text(funcObjFind(objSexual2, sexualId), 163, row_width + data_width + 3); //性別
      doc.text(birthDay, 194, row_width + data_width + 3); //輸入生辰
      doc.text(funcObjFind(objChineseYear2, zodiacId), 323, row_width + data_width + 3); //輸入生肖
      doc.setFontSize(8);
      doc.text(addr, 354, row_width + data_width + 3); //輸入地址變數
      doc.setFontSize(11);
    }
    doc.setLineWidth(1);
    doc.line(20, row_width, 575, row_width); //各資料列-橫線

    row_width += 25;
  }
  //total = subTotal.toString();
  // 最後列欄位名稱
  doc.setFontSize(17);
  doc.text("小計", 24, 818);

  if (lastNum === 0) {
    doc.setFontSize(12);
    doc.text("接下頁", 275, 837);
    doc.setFontSize(17);
  }

  if (lastNum === 1) { //當為最後一頁時，顯示 總計欄位 金額
    doc.text("總計", 25, 805);
    doc.setFont('Times', 'Roman'); //數字資料
    doc.text(total_num, 490, 808); //總計金額 變數
  }

  doc.setFont('Times', 'Roman'); //數字資料
  doc.text(subTotal.toString(), 532, 818);   //小計金額 變數
  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)

  doc.line(20, row_width, 575, row_width); //欄位名稱-橫線

  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)
  // doc.line(20, 775,575, 775); //底部欄位(小計) 橫線
  doc.line(20, 800, 575, 800); //底部欄位(總計) 橫線
}
export const prayPage140 = (doc, arrObj, arrObj2, in_date) => {
  // const addRoute('page16', {authRequired: false},{  // 正度附度甲狀
  //   get: function () {

  const loadP2Data = (arrP2 = []) => {
    // 要顯示名稱欄
    const arrName = [];
    for (let i = 0; i < arrP2.length; i++) {
      const entry = arrP2[i];
      if (entry.praytypeText.indexOf("其他 - ") !== -1) {
        continue;
      }

      // console.log(entry);
      let obj = {};
      obj.show_name = "";
      obj.show_name2 = "";

      if (!!entry.passname && entry.passname.indexOf("歷代祖先") !== -1) {
        obj.show_name = entry.passname;
        entry.passname = "";
      } else {
        if (!!entry.people_num && !isNaN(Number(entry.people_num))) { // 有幾名就顯示幾名
          const pp_num = Number(entry.people_num);
          let num_str = "";
          if (pp_num < 20) {
            num_str = digit[pp_num];
          } else {
            num_str = intToChineseNumberString(pp_num, true);
          }

          // obj.show_name = entry.praytypeText + " " + digit[Number(entry.people_num)] + " 名";
          obj.show_name = entry.praytypeText + " " + num_str + " 名";
        } else if (!!entry.passname) {
          obj.show_name = entry.praytypeText;

          if (!!entry.relation_text) {
            obj.show_name = obj.show_name + " " + entry.relation_text + "";
          }
        }
      }

      // obj.show_name = entry.show_name;
      /*  if(obj.show_name.length > 10){
          obj.show_name2 = obj.show_name.substr(10);
          obj.show_name = obj.show_name.substr(0, 10);
        }*/
      obj.live_year = "";
      if (!!entry.live_year || '') {
        obj.live_year = digitLowercase(Number(entry.live_year || ''));
        if (obj.live_year || ''.length === 1) {
          obj.live_year = " " + obj.live_year || '' + " ";
        }
        if (obj.live_year || ''.length === 2) {
          obj.live_year = " " + obj.live_year || '';
        }
      }

      obj.boxNum = "○";
      if (!!entry.boxNum) {
        obj.boxNum = digitLowercase(Number(entry.boxNum));
      }
      if (!!entry.birthDay) {
        obj.birthDay = "民國" + entry.birthDay;
      }
      if (!!entry.passDay) {
        obj.passDay = "民國" + entry.passDay;
      }
      obj.livename = entry.livename;
      obj.passname = entry.passname;
      obj.lastname1 = entry.lastname1;
      obj.lastname2 = entry.lastname2;
      obj.passname_gender_text = entry.passname_gender_text;
      arrName.push(obj);
    }
    return arrName;
  }
  const checkNextPage = (page, left) => {
    if (page.data_width > left) {
      doc.addPage();
      page.data_width = -160;
      // return 10;
    }
  }

  let page = {};
  page.data_width = 0;

  // let Input_date = in_date;
  // let Input_date_value = in_date.split("/");

  doc.setFont('BiauKai');
  doc.setFontSize(58);
  doc.text(toStraight("正度附度甲狀"), 1110, 200);
  doc.text(toStraight("靈寶大法司"), 1050, 55);
  doc.setFontSize(35);
  doc.text(toStraight("今具正度附度男女亡魂姓名生卒月日時于後"), 1010, 60);

  let loc1 = 960; // 957 第一排
  let loc2 = 934; // 930 求薦
  let loc4 = 932; // 932 生
  let loc3 = 912; // 910 卒

  for (let i = 0; i < arrObj.length; i++) {
    const entry = arrObj[i];
    //Praying2.find({ listId: entry._id }).fetch();
    const arrP2 = arrObj2.filter(item => item.praying1Id === entry.objectId);
    let arrP2data = loadP2Data(arrP2);
    console.log("arrP2");
    console.log(arrP2);
    console.log("arrP2data");
    console.log(arrP2data);

    if (arrP2data.length === 0) {
      continue;
    }

    doc.setFontSize(22);
    doc.text(toStraight(funcPad(entry.serial, 4)), loc1 - page.data_width, 28); // 編號-變數
    doc.text(toStraight(entry.whoapplyText).replace(",", " "), loc1 - page.data_width, 120); //  姓名-變數

    // console.log(entry.serial + " " + entry.addr + " " + isChineseStr(entry.addr) + " " + page.data_width);
    if (!!entry.addr) {
      if (entry.addr.length <= 24) {
        doc.text(toStraight(entry.addr), loc1 - page.data_width, 305); //  地址-變數
      } else {
        const addr1 = entry.addr.substr(0, 24);
        const addr2 = entry.addr.substr(24);
        doc.text(toStraight(addr1), loc1 - page.data_width, 305); //  地址-變數
        page.data_width += 20;
        doc.text(toStraight(addr2), loc1 - page.data_width, 305); //  地址-變數

      }
      // if(isChineseStr(entry.addr)){
      //   doc.rotate(-180, {origin: [150, 70]})
      //   doc.text(entry.toStraight(addr), page.data_width, 300); //  地址-變數
      //   doc.rotate(-180, {origin: [150, 70]});
      // }
      // else{
      //   // 英文的 要橫著印
      //   doc.setFont('Times', 'Roman');
      //   doc.rotate(-180, {origin: [150, 70]});
      //   doc.text(entry.addr, -260, -260, { width: 500, align: "left"});
      //   doc.rotate(-180, {origin: [150, 70]});
      //   doc.setFont('BiauKai');
      // }
    }

    doc.setFontSize(20);
    doc.text(toStraight("求薦"), loc2 - page.data_width, 30); //  地址-變數

    let j = 0
    for (; j < arrP2data.length; j++) {
      let obj2 = arrP2data[j];

      if (typeof obj2 === "undefined") {
        continue;
      }

      doc.setFontSize(20);

      if (!obj2.lastname1 && !obj2.lastname2) {
        doc.text(toStraight((obj2.show_name + " " + obj2.passname)), loc3 - page.data_width, 30);  //法會名稱 變數
      } else {
        // if(!!obj2.birthDay){
        //   page.data_width += 10;  
        // }

        doc.text(toStraight(obj2.show_name), loc3 - page.data_width, 30);  //法會名稱 變數

        if (!!obj2.lastname1) {
          doc.text(toStraight(obj2.lastname1), loc3 - page.data_width, doc.y);  //法會名稱 變數
          doc.setFontSize(16);
          let l1 = "公";
          if (!!obj2.lastname2) l1 = "媽";
          else if (obj2.passname_gender_text === "女") l1 = "媽";

          doc.text(l1, loc3 - page.data_width + 8, doc.y - 18);  //法會名稱 變數
          doc.x -= 8;
          doc.setFontSize(20);
        }
        if (!!obj2.lastname2) {
          doc.text(toStraight(obj2.lastname2), loc3 - page.data_width, doc.y);  //法會名稱 變數
          doc.setFontSize(16);
          doc.text("氏", loc3 - page.data_width + 8, doc.y - 18);  //法會名稱 變數
          doc.x -= 8;
          doc.setFontSize(20);
        }
        doc.text(toStraight(obj2.passname), loc3 - page.data_width, doc.y);  //法會名稱 變數
      }

      if (!!obj2.birthDay) {
        doc.text(toStraight(("生于 " + obj2.birthDay)), loc4 - 10 - page.data_width, 320);
      }
      if (!!obj2.passDay) {
        doc.text(toStraight(("卒于 " + obj2.passDay)), loc3 - 10 - page.data_width, 320);
      }

      if (!!obj2.live_year || '') {
        doc.text(toStraight((" 陽壽" + obj2.live_year || '' + "歲")), loc3 - page.data_width, 670);
      }
      if (!!obj2.birthDay) {
        page.data_width += 50;
      }
      if (!obj2.birthDay) {
        page.data_width += 40;
      }
      // page.data_width += 70;
      if (i === arrObj.length - 1 && j === arrP2data.length - 1) {
        checkNextPage(page, 800);
      }
      else {
        checkNextPage(page, 850);
      }
      // console.log(page.data_width);
    }
    page.data_width += 70;
    if (i === arrObj.length - 1 && j === arrP2data.length - 1) {
      checkNextPage(page, 800);
    } else {
      checkNextPage(page, 850);
    }
    // console.log(page.data_width);
  }

  // let y = yearToTGDG(Input_date_value[0]);
  // let m = dateDigit[Number(Input_date_value[1])];
  // let dd = dateDigit[Number(Input_date_value[2])];
  // if(dd.length ===  1) dd = "初"+dd;

  doc.setFontSize(38);

  doc.text(toStraight("右具如前"), 130, 110);
  // doc.text(("天運 "+ y + "年"+ m + "月"+ dd + "日 吉旦").toStraight(), 80, 60);
  doc.text(toStraight(("天運 " + in_date + " 吉旦")), 80, 65);

  doc.text(toStraight("太上三五都功經錄斗府校善仙官 余大宏"), 30, 35);

  doc.setFontSize(31);
  doc.text(toStraight("臣"), 50, 640);
  doc.text(toStraight("具"), 15, 825);
}

export const prayPage141 = (doc, objP1, arrP2, index) => { //報名申報內容

  doc.setLineWidth(2);
  doc.rect(50, 45, 490, 750);

  let top = 46;
  let bottom = 796;
  let collom_width = 0;
  let data_width = 0;

  doc.setFont('BiauKai');
  doc.setFontSize(12);
  // doc.text(objP1.ordernum.toStraight(), 543, 43);  //編號變數
  // let str2 = objP1.year+ " " +objP1.prayname_text+ " " + funcPad( objP1.serial, 5);
  // let str2 = objP1.year + " " + objP1.prayname_text + " " + objP1.serial;
  // doc.text(toStraight(str2), 543, 43);  //編號變數
  doc.text(toStraight(''), 543, 43);

  // doc.text(("申報種類："+objP1.field_type_text).toStraight(), 543, 163);  //編號變數
  doc.text(toStraight(("申報種類：" + objP1.fieldTypeText)), 543, 263);  //編號變數

  doc.setFontSize(22);
  doc.text(toStraight("申報人地址"), 512, 72);

  if (!!objP1.addr)
    doc.text(toStraight(objP1.addr), 510, 210); //輸入地址變數

  doc.text(toStraight("申報關係人"), 477, 72);

  if (!!objP1.whoapplyText) {
    let name_convert = objP1.whoapplyText.replace(",", " ");
    doc.text(toStraight(name_convert), 476, 210);  //輸入姓名變數
  }
  // 後面備註欄的內容
  let ps1 = "";
  let ps2 = "";
  ////// 以上是共用的版面

  let fieldTypeId = objP1.fieldTypeId;
  let longlife_text = "";

  if (fieldTypeId === 1) { // 超度
    doc.setFontSize(20);
    doc.text(toStraight("財寶箱"), 478, 617);
    doc.text(toStraight("出財寶箱名"), 478, 692);

    // doc.setLineWidth(1);
    doc.setDrawColor(0, 0, 0)
    // 財寶箱上的橫線
    doc.line(98, 600, 505, 600); //橫列 3 橫線

    let other_obj = [];
    for (let i = 0; i < arrP2.length; i++) {
      let entry = arrP2[i];
      if (entry.praytypeText.indexOf("其他 - ") !== -1) {

        let label = entry.praytypeText.substr(5);

        if (!funcIsObjFindLabel(other_obj, label)) { // 如果沒有找到的話，就新增
          other_obj.push({ label, value: Number(entry.rowTotal) });
        } else { // 有找到之前的，就類加
          funcAddObjLabelValue(other_obj, label, entry.rowTotal);
        }

        if (label.indexOf("長生燈") !== -1) {
          longlife_text = longlife_text + " " + entry.livename;
        }
        // console.log("funcIsObjFindLabel ret: "+funcIsObjFindLabel(other_obj, label));
      }
      else {
        let label = "金紙";
        let label2 = "工本費";

        let fee = Number(entry.rowTotal) - Number(entry.boxNum) * 500;
        let gold_paper_fee = Number(entry.rowTotal) - fee;

        if (!funcIsObjFindLabel(other_obj, label)) { // 如果沒有找到的話，就新增
          other_obj.push({ label: label2, value: Number(fee) });
          other_obj.push({ label: label, value: gold_paper_fee });
        }
        else { // 有找到之前的，就類加
          funcAddObjLabelValue(other_obj, label2, Number(fee));
          funcAddObjLabelValue(other_obj, label, gold_paper_fee);
        }
      }
    }
    // console.log("other_obj");
    // console.log(other_obj);
    // console.log("objP1");
    // console.log(objP1);
    // console.log("arrP2");
    // console.log(arrP2);

    ps1 = funcStrcatObjLabelValue(other_obj, longlife_text);

    // console.log("ps1");
    // console.log(ps1);
    // 要顯示名稱欄
    let arrName = [];
    for (let i = 0; i < arrP2.length; i++) {
      let entry = arrP2[i];

      if (entry.praytypeText.indexOf("其他 - ") !== -1) {
        continue;
      }

      // console.log(entry);
      let obj = {};
      obj.show_name = "";
      obj.show_name2 = "";
      if (entry.passname.indexOf("歷代祖先") !== -1) {
        obj.show_name = entry.passname;
        entry.passname = "";
      } else {
        if (!!entry.people_num && !isNaN(Number(entry.people_num))) { // 有幾名就顯示幾名
          let pp_num = Number(entry.people_num);
          if (pp_num < 20) {
            obj.show_name = entry.praytypeText + " " + digit[pp_num] + " 名";
          } else {
            obj.show_name = entry.praytypeText + " " + intToChineseNumberString(pp_num, true) + " 名";
          }
        } else if (!!entry.passname) {
          obj.show_name = entry.praytypeText;

          if (!!entry.relation_text) {
            obj.show_name = obj.show_name + " " + entry.relation_text + "";
          }
        }
      }

      obj.live_year = digitLowercase(Number(entry.live_year || ''));

      obj.boxNum = "○";
      if (!!entry.boxNum) {
        // obj.boxNum = digitLowercase(Number(entry.boxNum));
        let bb_num = Number(entry.boxNum);
        if (bb_num < 20) {
          obj.boxNum = digit[bb_num];
        } else {
          obj.boxNum = intToChineseNumberString(bb_num, true);
        }
      }
      if (!!entry.birthDay) {
        obj.birthDay = "民國" + entry.birthDay;
      }
      if (!!entry.passDay) {
        obj.passDay = "民國" + entry.passDay;
      }
      obj.livename = entry.livename;
      obj.passname = entry.passname;
      obj.lastname1 = entry.lastname1;
      obj.lastname2 = entry.lastname2;
      obj.passname_gender_text = entry.passname_gender_text;
      arrName.push(obj);
    }
    console.log(arrName);
    // doc.setFont('BiauKai');
    // doc.text("先亡魂儀 大伯".toStraight(), 430, 50);  //法會名稱 變數
    // doc.text("陳公望".toStraight(), 430, 200);  //法會 姓名變數
    // doc.text("普度孤幽 一名".toStraight(), 370, 50);

    for (let i = 0; i < arrName.length; i++) { //列出各欄的資料內容
      let entry = arrName[i];

      doc.setFontSize(16);
      if (!entry.show_name2) {
        if (!entry.lastname1 && !entry.lastname2) {
          doc.text(toStraight((entry.show_name + " " + entry.passname)), 430 - data_width, 50);  //法會名稱 變數
        } else {
          if (entry.show_name) {
            doc.text(toStraight(entry.show_name), 430 - data_width, 50);  //法會名稱 變數
          }

          if (!!entry.lastname1) {
            doc.text(toStraight(entry.lastname1), 430 - data_width, 90);  //法會名稱 變數
            doc.setFontSize(14);
            let l1 = "公";
            if (!!entry.lastname2) { l1 = "媽"; }
            else if (entry.passname_gender_text === "女") { l1 = "媽" };

            doc.text(l1, 430 - data_width + 8, 140);  //法會名稱 變數
            doc.x -= 8;
            doc.setFontSize(18);
          }
          else if (!!entry.lastname2) {
            doc.text(toStraight(entry.lastname2), 430 - data_width, 90);  //法會名稱 變數
            doc.setFontSize(14);
            doc.text("氏", 430 - data_width + 8, 140);  //法會名稱 變數
            doc.x -= 8;
            doc.setFontSize(18);
          }
          doc.text(toStraight(entry.passname), 430 - data_width, 190);  //法會名稱 變數

        }
      } else {
        doc.text(toStraight(entry.show_name), 430 - data_width + 12, 50);  //法會名稱 變數
        doc.text(toStraight(entry.show_name2), 430 - data_width - 12, 50);  //法會名稱 變數
      }

      if (!!entry.birthDay) {
        doc.text(toStraight("生"), 445 - data_width, 310);
      }
      if (!!entry.passDay) {
        doc.text(toStraight("卒"), 415 - data_width, 310);
      }
      if (!!entry.birthDay || !!entry.passDay) {
        doc.text(toStraight("于"), 430 - data_width, 330);
      }

      doc.setFontSize(14);
      if (!!entry.birthDay) {
        doc.text(toStraight(entry.birthDay), 445 - data_width, 345);  // 年-變數
      }
      if (!!entry.passDay) {
        doc.text(toStraight(entry.passDay), 417 - data_width, 345);  // 年-變數
      }

      doc.setFontSize(14);
      if (!!entry.live_year || '' && !!entry.passDay) {
        let str = entry.live_year || '' + "歲";
        doc.text(toStraight("享壽"), 432 - data_width + 9, 545);
        doc.text(toStraight(str), 432 - data_width - 9, 545);
      }

      if (!!entry.boxNum) { //財寶箱數量 變數
        doc.setFontSize(22);
        if (entry.boxNum.length <= 2) {
          doc.text(toStraight(entry.boxNum), 430 - data_width, 645);  //序號變數
        } else {
          doc.text(toStraight(entry.boxNum), 430 - data_width, 620 - 20);  //序號變數
        }
      }

      // 出財寶箱名
      doc.setFontSize(18);
      if (entry.livename.length <= 4) { // 當出財寶箱名為4字以下 --顯示單行
        doc.text(toStraight(entry.livename), 430 - data_width, 690);  //輸入 出財寶箱名
      } else { // 當出財寶箱名字超過4字或兩人以上 --顯示兩行
        let arrLivename = entry.livename.split(",");
        doc.setFontSize(18);

        if (arrLivename.length === 2) {
          doc.text(toStraight(arrLivename[0]), 430 - data_width + 9, 685);
          doc.text(toStraight(arrLivename[1]), 430 - data_width - 9, 685);
        } else if (arrLivename.length === 3) {
          doc.text(toStraight(arrLivename[0]), 430 - data_width + 18, 685);
          doc.text(toStraight(arrLivename[1]), 430 - data_width, 685);
          doc.text(toStraight(arrLivename[2]), 430 - data_width - 18, 685);
        } else if (arrLivename.length === 4) {
          doc.setFontSize(14);
          doc.text(toStraight(arrLivename[0]), 430 - data_width + 24, 675);
          doc.text(toStraight(arrLivename[1]), 430 - data_width + 9, 675);
          doc.text(toStraight(arrLivename[2]), 430 - data_width - 7, 675);
          doc.text(toStraight(arrLivename[3]), 430 - data_width - 22, 675);
        } else if (arrLivename.length >= 5) {
          doc.setFontSize(12);
          data_width = data_width - 2;
          doc.text(toStraight(arrLivename[0]), 430 - data_width + 24, 675);
          doc.text(toStraight(arrLivename[1]), 430 - data_width + 12, 675);
          doc.text(toStraight(arrLivename[2]), 430 - data_width, 675);
          doc.text(toStraight(arrLivename[3]), 430 - data_width - 12, 675);
          doc.text(toStraight(arrLivename[4]), 430 - data_width - 24, 675);

          let col2height = 63;
          if (!!arrLivename[0 + 5]) { doc.text(toStraight(arrLivename[0 + 5]), 430 - data_width + 24, 675 + col2height) };
          if (!!arrLivename[1 + 5]) { doc.text(toStraight(arrLivename[1 + 5]), 430 - data_width + 12, 675 + col2height) };
          if (!!arrLivename[2 + 5]) { doc.text(toStraight(arrLivename[2 + 5]), 430 - data_width, 675 + col2height) };
          if (!!arrLivename[3 + 5]) { doc.text(toStraight(arrLivename[3 + 5]), 430 - data_width - 12, 675 + col2height) };
          if (!!arrLivename[4 + 5]) { doc.text(toStraight(arrLivename[4 + 5]), 430 - data_width - 24, 675 + col2height) };
        }
      }
      data_width += 62;
    }

  } else if (0) { // 其他的申報結果
    let other_obj = [];
    for (let i = 0; i < arrP2.length; i++) {
      let entry = arrP2[i];

      let praytypeText = entry.praytypeText.replace(" - ", " ");

      if (praytypeText.indexOf("(") !== -1) {
        praytypeText = praytypeText.substr(0, praytypeText.indexOf("(")).trim();
      }
      let label = praytypeText;

      if (!funcIsObjFindLabel(other_obj, label)) { // 如果沒有找到的話，就新增
        other_obj.push({ label: label, value: Number(entry.rowTotal) });
      } else { // 有找到之前的，就類加
        funcAddObjLabelValue(other_obj, label, entry.rowTotal);
      }
    }

    ps1 = funcStrcatObjLabelValue(other_obj, longlife_text);
    doc.setFontSize(22).text(toStraight("小  計"), 476, 690);
    for (let i = 0; i < arrP2.length; i++) { //列出各欄的資料內容
      const entry = arrP2[i];

      let praytypeText = entry.praytypeText.replace(" - ", " ");
      if (praytypeText.indexOf("(") !== -1) {
        praytypeText = praytypeText.substr(0, praytypeText.indexOf("(")).trim();
      }
      doc.setFontSize(16);
      const allname = praytypeText + "：" + entry.livename;
      if (allname.length < 12) {
        doc.text(toStraight((allname)), 430 - data_width, 50);  //法會名稱 變數
      } else {
        doc.text(toStraight((praytypeText + "：")), 445 - data_width, 50);  //法會名稱 變數
        doc.text(toStraight(entry.livename), 415 - data_width, 50);  //法會名稱 變數
      }

      if (!!entry.birthDay) {
        doc.text(toStraight(("生于 " + entry.birthDay)), 445 - data_width, 300);  // 年-變數
      }

      if (!!entry.addr) {
        doc.text(toStraight(("地址 " + entry.addr)), 415 - data_width, 300);
      }

      if (!!entry.live_year || '') {
        let str = entry.live_year + "歲";
        doc.text(toStraight(str), 445 - data_width, 505);
      }

      doc.text(toStraight(digitLowercase(entry.rowTotal)), 430 - data_width, 685);  //輸入 出財寶箱名
      data_width += 62;
    }

  }

  // console.log("other_obj");
  // console.log(other_obj);

  doc.setFont('BiauKai').setFontSize(17);
  doc.text("備註", 57, 70);

  if (objP1.ps) {
    ps1 = ps1 + objP1.ps;
  }
  if (ps1.length > 26) {
    let last = ps1.substr(0, 26).lastIndexOf(" ");
    ps2 = ps1.substr(last).trim();
    ps1 = ps1.substr(0, last).trim();
  }

  doc.setFontSize(13);
  if (!ps2) {
    // 當資料筆數為一行的 (預設)
    doc.text(toStraight(ps1), 68, 102);
  } else {
    // 當資料筆數為兩行的
    doc.text(toStraight(ps1), 78, 102);
    doc.text(toStraight(ps2), 58, 102);
  }

  doc.setFont('BiauKai').setFontSize(17);
  doc.text(toStraight("法會總額"), 65, 456);
  doc.text(toStraight(digitLowercase(objP1.cash || 0)), 65, 550);  //總額金額-變數
  doc.text(toStraight("欠入金"), 65, 637);
  let cash_balance = objP1.cash_balance;
  if (objP1.cash !== objP1.cash_paid) {
    cash_balance = Number(objP1.cash) - Number(objP1.cash_paid);
    // console.log("cash_balance: " + cash_balance)
  }
  // doc.text(digitLowercase(objP1.cash_balance).toStraight(), 65, 692, { width: 26, align: 'justify'});  //欠入金額-變數
  doc.text(toStraight(digitLowercase(cash_balance || 0)), 65, 720);  //欠入金額-變數

  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)
  for (let i = 0; i < 8; i++) { //畫出各直欄的直線
    doc.line(505 - collom_width, top, 505 - collom_width, bottom); //表格直欄直線依序填寫
    if (i === 1 || i === 2 || i === 3 || i === 4 || i === 5 || i === 6) {
      collom_width += 27;
    }
    collom_width += 35;
  }
  doc.line(470, 185, 540, 185); //橫列 1 橫線 上面右邊短的
  //  doc.line(98, 265,470, 265); //橫列 2 橫線
  doc.line(98, 285, 470, 285); //橫列 2 橫線
  doc.line(98, 670, 505, 670); //橫列 4 橫線

  // 備註這邊的
  doc.line(50, 85, 98, 85); //橫列 5 橫線
  doc.line(50, 440, 98, 440); //橫列 6 橫線
  doc.line(50, 520, 98, 520); //橫列 7 橫線
  doc.line(50, 620, 98, 620); //橫列 8 橫線
  doc.line(50, 685, 98, 685); //橫列 9 橫線
}

export const prayPage161 = (doc, objP1 = [], in_date) => {  // 祈福業障

  // Input_date = in_date;
  // let Input_date_value = in_date.split("/");
  // let y = yearToTGDG(Input_date_value[0]);
  // let yt = digitLowercase(Number(Input_date_value[0])-1911);
  // let m = dateDigit[Number(Input_date_value[1])];
  // let dd = dateDigit[Number(Input_date_value[2])];
  // if(dd.length ===  1) dd = "初"+dd;

  let name_convert = "";

  doc.setFont('BiauKai');
  doc.setFontSize(22);
  doc.text(toStraight("伏  以"), 945, 180);
  doc.text(toStraight("瑤宮諸聖護眾生 池映瑞氣顯神靈"), 905, 70);
  doc.text(toStraight("金科玉律勤修証 母賜慈惠助道成"), 865, 70);
  doc.text(toStraight("今  據"), 825, 130);
  doc.setFontSize(20);
  // doc.text(("中華民國 " + yt + " 年 歲次 " + y + " 農曆 " + m +"月"+dd+"日 大吉良辰").toStraight(), 745, 30); //日期變數
  doc.text(toStraight(in_date + " 大吉良辰"), 745, 30); //日期變數
  if (!!objP1.addr) {
    doc.text(toStraight("住址：" + (objP1.length && objP1.addr || '')), 720, 30); //輸入地址變數
  };

  if (!!objP1.livename) {
    name_convert = objP1.livename.replace(",", " "); //姓名變數取所有「陽上」姓名
  }

  // let year_olds = digitLowercase(objP1.live_year ||'');
  doc.text(toStraight("信徒：" + ((objP1.length && objP1[0].livename) || '') + " 出生歲次 " + ((objP1.length && objP1[0].birthDay) || '') + " " + ((objP1.length && objP1[0].year_olds) || '') + " 歲瑞生"), 695, 30);  //輸入姓名, 出生日期，年紀變數

  if (!!objP1.rowTotal) { //金額變數
    doc.text(toStraight("業障庫銀現金 " + digitUppercase(objP1.rowTotal) + " 奉請"), 645, 30);
    doc.text(("奉交業障庫銀 " + digitUppercase(objP1.rowTotal)).toStraight(), 325, 30);
  }
  doc.text(toStraight("桃園市龍潭區 龍潭慈惠堂"), 605, 30); //金額變數
  doc.text(toStraight("無極瑤池大聖西王金母大天尊 做主"), 565, 30); //金額變數
  doc.text(toStraight("信徒： " + objP1[0].name_convert + " 誠心誠意 叩拜於 龍潭慈惠堂"), 525, 30); //姓名變數取所有「陽上」姓名
  doc.text(toStraight("無極瑤池大聖西王金母大天尊之座前"), 485, 30);
  doc.text(toStraight("玉皇大天尊 玄靈高上帝 之尊前"), 445, 30);
  doc.text(toStraight("幽冥教主 地藏王菩薩 座前"), 405, 30);
  doc.text(toStraight("閻羅天子 包公 座前"), 365, 30);
  doc.text(toStraight("敬 祈"), 300, 150);
  doc.text(toStraight("消業除疾 前世因果 業障因果 功德普化 陰德輪迴 善事光陰"), 275, 30);
  doc.text(toStraight("身體健康 婚姻和合 業障因果 逢凶化吉 貴人相助 萬事如意"), 235, 30);
  doc.text(toStraight("身中莫蔭健春在 體明富貴齊備來 健康四季入春海 身體健康永萬哉"), 195, 30);
  doc.text(toStraight("恭請 龍潭慈惠堂 五路財神 福德正神 伯公祖 帶領業障庫銀交旨"), 155, 30);

  doc.text(toStraight("幫助信徒 " + objP1[0].name_convert + " 完成心願 身體健康 事業順利 功德圓滿"), 115, 30, { width: 120, align: 'justify' }); //姓名變數取所有「陽上」姓名
  doc.text(toStraight("祈恩弟子 " + objP1[0].name_convert + " 叩拜上申"), 90, 350); //姓名變數取所有「陽上」姓名
  // doc.text(("天運歲次 " + y + " 年 " + m + " 月 " + dd + " 日立").toStraight(), 65, 30);  //年月日變數
  doc.text(toStraight("天運歲次 " + in_date + " 立"), 65, 30);  //年月日變數
}
export const prayPage162 = (doc, objP1 = [], in_date) => {  // 祈福事業

  // Input_date = in_date;
  // let Input_date_value = in_date.split("/");
  // let y = yearToTGDG(Input_date_value[0]);
  // let yt = digitLowercase(Number(Input_date_value[0])-1911);
  // let m = dateDigit[Number(Input_date_value[1])];
  // let dd = dateDigit[Number(Input_date_value[2])];
  // if(dd.length ===  1) dd = "初"+dd;
  let name_convert = "";

  doc.setFont('BiauKai');
  doc.setFontSize(22);
  doc.text(toStraight("伏   以"), 945, 180);
  doc.text(toStraight("瑤宮諸聖護眾生 池映瑞氣顯神靈"), 905, 70);
  doc.text(toStraight("金科玉律勤修証 母賜慈惠助道成"), 865, 70);
  doc.text(toStraight("今  據"), 825, 130);
  doc.setFontSize(20);
  // doc.text(("中華民國 " + yt + " 年 歲次 " + y + " 農曆 " + m +"月"+dd+"日 大吉良辰").toStraight(), 745, 30); //日期變數
  doc.text(in_date + toStraight(" 大吉良辰"), 745, 30); //日期變數

  if (!!objP1[0].addr) {
    doc.text(toStraight("住址 " + (objP1.length && objP1[0].addr) || ''), 695, 30); //輸入地址變數
  };

  if (!!objP1[0].livename) {
    name_convert = objP1[0].livename.replace(",", " "); //姓名變數取所有「陽上」姓名
  }

  // let year_olds = digitLowercase(objP1.live_year ||'');
  doc.text(toStraight("信徒 " + ((objP1.length && objP1[0].livename) || '') + " 出生 " + (objP1.length && objP1[0].birthDay || '') + " " + (objP1.length && objP1[0].year_olds || '') + " 歲"), 645, 30);  //輸入姓名, 出生日期，年紀變數
  doc.text(toStraight("業障庫銀現金 " + digitUppercase(objP1.length && objP1[0].rowTotal || '') + " 奉請"), 605, 30);
  doc.text(toStraight("閻羅天子 包公 座前奉交事業庫銀 " + digitUppercase(objP1.length && objP1[0].rowTotal || '')), 325, 30);

  doc.text(toStraight("桃園市龍潭區 龍潭慈惠堂"), 565, 30); //金額變數
  doc.text(toStraight("無極瑤池大聖西王金母大天尊 做主"), 525, 30); //金額變數
  doc.text(toStraight("信徒 " + ((objP1.length && objP1[0].name_convert) || '') + " 誠心誠意 叩拜於龍潭慈惠堂"), 485, 30); //姓名變數
  doc.text(toStraight("無極瑤池大聖西王金母大天尊 之座前"), 445, 30);
  doc.text(toStraight("玉皇大天尊玄靈高上帝 尊前"), 405, 30);
  doc.text(toStraight("幽冥教主 地藏王菩薩 座前"), 365, 30);
  doc.text(toStraight("敬 祈"), 300, 150);
  doc.text(toStraight("事業順利 招財進寶 生意興隆 萬事如意 功德回報輪因果"), 275, 30);
  doc.text(toStraight("四季平安入春道 天官賜福財進寶"), 235, 30);
  doc.text(toStraight("恭請 龍潭慈惠堂 五路財神 福德正神 伯公祖 帶領業障庫銀交旨"), 195, 30);
  doc.text(toStraight("幫助信徒 " + ((objP1.length && objP1[0].name_convert) || '') + " 完成心願 功德圓滿"), 155, 30);
  doc.text(toStraight("祈恩弟子 " + ((objP1.length && objP1[0].name_convert) || '') + " 叩拜上申"), 115, 350);
  // doc.text(("天運歲次 " + y + " 年 " + m + " 月 " + dd + " 日立").toStraight(), 65, 30, { width: 80, align: 'justify'});  //年月日變數
  doc.text(toStraight("天運歲次 " + in_date + " 立"), 65, 30);  //年月日變數

}
export const prayPage163 = (doc, objP1 = [], in_date) => {  // 祈福身體
  // Input_date = in_date;
  // let Input_date_value = in_date.split("/");
  // let y = yearToTGDG(Input_date_value[0]);
  // let yt = digitLowercase(Number(Input_date_value[0])-1911);
  // let m = dateDigit[Number(Input_date_value[1])];
  // let dd = dateDigit[Number(Input_date_value[2])];
  // if(dd.length ===  1) dd = "初"+dd;
  let name_convert = "";

  doc.setFont('BiauKai');
  doc.setFontSize(22);
  doc.text(toStraight("伏  以"), 945, 180);
  doc.text(toStraight("瑤宮諸聖護眾生 池映瑞氣顯神靈"), 905, 70);
  doc.text(toStraight("金科玉律勤修証 母賜慈惠助道成"), 865, 70);
  doc.text(toStraight("今  據"), 825, 130);
  doc.setFontSize(19);
  // doc.text(("中華民國 " + yt + " 年 歲次 " + y + " 農曆 " + m +"月"+dd+"日 大吉良辰").toStraight(), 745, 30); //日期變數
  doc.text(toStraight(in_date + " 大吉良辰"), 745 + 40, 30); //日期變數

  if (!!objP1[0].addr) {
    doc.text(toStraight("住址：" + (objP1.length && objP1[0].addr) || ''), 720 + 20, 30); //輸入地址變數
  };

  if (!!objP1[0].livename) {
    name_convert = objP1[0].livename.replace(",", " "); //姓名變數取所有「陽上」姓名
  }

  //呼叫Function
  //useLowerType，中文數字分大小寫，true為小寫，false為大寫
  // let year_olds = intToChineseNumberString(objP1.live_year ||'', true);

  doc.text(toStraight("信徒：" + ((objP1.length && objP1[0].name_convert) || '') + " " + (objP1.length && objP1[0].year_olds || '') + "歲 歲次 " + (objP1.length && objP1[0].birthDay || '') + " 健生 "), 695, 30);  //輸入姓名, 出生日期，年紀變數

  doc.text(toStraight("祈求身體因果庫銀現金 折禮斗法會誦經費用 奉請"), 645, 30); //祈福身體變數
  doc.text(toStraight("桃園市龍潭區 龍潭慈惠堂"), 605, 30);
  doc.text(toStraight("無極瑤池大聖西王金母大天尊 做主"), 565, 30);
  doc.text(toStraight("信徒：" + ((objP1.length && objP1[0].name_convert) || '') + " 誠心誠意 叩拜於龍潭慈惠堂"), 525, 30); //姓名變數
  doc.text(toStraight("無極瑤池大聖西王金母大天尊 之懿前"), 485, 30);
  doc.text(toStraight("玉皇大天尊玄靈高上帝 御前"), 445, 30);
  doc.text(toStraight("幽冥教主 地藏王菩薩 座前"), 405, 30);
  doc.text(toStraight("閻羅天子 包公 座前"), 365, 30);
  doc.text(toStraight("奉交祈求身體因果庫銀 折禮斗法會誦經費用"), 325, 30);
  doc.text(toStraight("敬 祈"), 300, 150);
  doc.text(toStraight("消業除疾 身體健康 婚姻圓滿 業障因果 逢凶化吉 貴人相助 萬事如意"), 275, 30);
  doc.text(toStraight("身中莫蔭健春在 體明富貴齊備來 健康四季入春海 身體健康永萬哉"), 235, 30);
  doc.text(toStraight("恭請 龍潭慈惠堂 五路財神 福德正神 伯公祖 帶領身體因果庫銀交旨"), 195, 30);
  doc.text(toStraight("幫助信徒 " + ((objP1.length && objP1[0].name_convert) || '') + " 完成心願 功德圓滿"), 155, 30);  //姓名變數
  doc.text(toStraight("祈恩弟子 " + ((objP1.length && objP1[0].name_convert) || '') + " 叩拜上申"), 115, 350);  //姓名變數
  // doc.text(("天運歲次 " + y + " 年 " + m + " 月 " + dd + " 日立").toStraight(), 65, 30);  //年月日變數
  doc.text(toStraight("天運歲次 " + in_date + " 立"), 65, 30);  //年月日變數

};

export const prayPage164 = (doc, objP1, start, prayname, total, in_date, lastNum) => { //斗燈清單
  doc.setLineWidth(2);
  doc.setDrawColor(0, 0, 0)
  doc.rect(20, 45, 555, 780);

  // let collum_bottom = 825;
  let collum_bottom1 = 774;
  let row_width = 75;  // 各橫列寬度為 25
  let data_width = 16;  // 各橫列-文字資料 高度間距為 6

  doc.setFont('BiauKai');
  doc.setFontSize(25);
  doc.text("龍潭慈惠堂 " + prayname + " 斗燈清單", 60, 30);

  // doc.setFont('Times', 'Roman'); //數字資料
  doc.setFont('BiauKai');
  doc.setFontSize(14);
  doc.text(in_date, 420, 30); // 右上角顯示列印日期

  doc.setFont('BiauKai');
  doc.setFontSize(16);

  // 欄位名稱
  doc.text("序號", 27, 65);
  //doc.text("法會名稱", 72, 50, { width: 80, align: 'justify'});
  doc.text("種類", 92, 65);
  //doc.text("種類", 168, 50);
  doc.text("姓名", 174, 65);

  //doc.text("姓名", 240, 50);
  doc.text("地址", 350, 65);
  doc.text("金額", 520, 65);

  /*let name_convert = "";
  if(!!objP1.whoapplyText){
     name_convert = objP1.whoapplyText.replace(","," ").substr(0,8); //姓名變數最多只取2個人
 
    //doc.text(toStraight(name_convert), 60, 440);  //輸入姓名變數
  }
  //輸入-日期，姓名 變數
  doc.text(("天運歲次 "+ y + "年"+ m + "月"+ dd + "日 吉旦" + " 沐恩信主 " + name_convert + " 叩拜上申").toStraight(), 60, 20);*/
  //console.log(objP1[0].addr);

  let subTotal = 0; //小計
  let total_num = total; //合計

  for (let i = 0; i < 28 && i < objP1.length; i++) {
    const { serial = '', cash = '', livename = '', field_type_text = '', addr = '' } = objP1[i + start]

    if (livename) {
      // 欄位文字資斛
      doc.setFont('Times', 'Roman'); //數字資料
      doc.setFontSize(14);
      doc.text(serial, 37, row_width + data_width); //序號變數

      //doc.text("0051", 25, row_width + data_width); //序號變數
      doc.text(cash, 528, row_width + data_width); //金額變數
      subTotal += Number(cash);
      //doc.text("12000", 515, row_width + data_width); //金額變數

      doc.setFont('BiauKai');
      doc.setFontSize(12);

      //if(!!objP1.addr && objP1.addr !== "undefined"){
      //doc.text("補運", 72, row_width + data_width);  //法會名稱變數
      //doc.text(objP1[i].prayname_text, 85, row_width + data_width); //法會名稱變數
      doc.text(field_type_text, 97, row_width + data_width); //種類變數

      // console.log(objP1[i+start]);
      // doc.text(objP1[i+start].whoapplyText, 170, row_width + data_width); //輸入姓名變數
      doc.text(livename, 172, row_width + data_width); //輸入姓名變數
      //doc.text("陳口口", 238, row_width + data_width); //姓名變數

      doc.text(addr, 235, row_width + data_width); //輸入地址變數
      //doc.text("桃園市中壢區新中北路400號5樓", 295, row_width + data_width);
    }

    doc.setLineWidth(1);
    doc.line(20, row_width, 575, row_width); //各資料列-橫線

    row_width += 25;
  }
  //total = subTotal.toString();
  // 最後列欄位名稱
  doc.setFontSize(17);
  doc.text("小計", 25, 792);

  if (lastNum === 0) {
    doc.text("接下頁", 275, 805);

  }

  if (lastNum === 1) { //當為最後一頁時，顯示 總計欄位 金額
    doc.text("總計", 25, 805);
    doc.setFont('Times', 'Roman'); //數字資料
    doc.text(total_num, 490, 808); //總計金額 變數
  }

  doc.setFont('Times', 'Roman'); //數字資料
  doc.text(subTotal.toString(), 523, 792);   //小計金額 變數
  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)

  doc.line(500, 46, 500, collum_bottom1); //直欄 1 直線
  //doc.line(290, 46,290, collum_bottom1); //直欄 2 直線
  doc.line(230, 46, 230, collum_bottom1); //直欄 2 直線
  doc.line(150, 46, 150, collum_bottom1); //直欄 3 直線
  doc.line(70, 46, 70, collum_bottom1); //直欄 4 直線

  doc.line(20, row_width, 575, row_width); //欄位名稱-橫線

  doc.setLineWidth(2);
  doc.setDrawColor(0, 0, 0)
  doc.line(20, 775, 575, 775); //底部欄位(小計) 橫線
  doc.line(20, 800, 575, 800); //底部欄位(總計) 橫線
}

export const prayPageA02 = (doc, objP1, start, total, printdate, lastNum = 0) => { // 祈福清單

  doc.setDrawColor(0, 0, 0)
  doc.rect(20, 50, 555, 775);

  let collum_bottom = 825;
  let collum_top = 50;
  let row_width = 70;  // 各橫列寬度為

  doc.setFont('BiauKai');
  doc.setFontSize(25);
  doc.text("龍潭慈惠堂祈福清單", 180, 36);  // 表格名稱

  // doc.setFont('Times', 'Roman');
  doc.setFont('BiauKai');
  doc.setFontSize(15);
  // doc.text(printdate, 440, 30); // 右上角顯示列印日期
  //doc.text("0105/02/02",500 , 30, { width: 80, align: 'justify'});  // 製作日期

  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)
  doc.line(20, collum_top, 575, collum_top); //頂部標題名稱 橫線
  // doc.line(20, row_width,575, row_width); //欄位名稱-橫線

  let arrWidth = [30, 90, 40, 120, 40, 170, 40]; // 1~8 各直欄-直線欄寬
  let collum_width = 30;

  let arr_col_name = ["編號", "姓名", "性別", "農曆生辰", "生肖", "地址", "金額"]; // 1~9 各欄位名稱
  let arrCol_name_width = [26, 91, 156, 221, 316, 418, 534]; // 1~8 各欄位名稱-文字欄寬
  let col_name_width = 0;

  doc.setLineWidth(1);
  doc.setFont('BiauKai');
  doc.setFontSize(14);

  for (let i = 0; i < arr_col_name.length; i++) {

    doc.text(arr_col_name[i], arrCol_name_width[i], 65);  // 1~9 各欄位名稱

    collum_width += arrWidth[i]; //從陣列中 取第2個欄位 寬度間距 數值
    col_name_width += arrWidth[i]; //從陣列中 取第2個欄位 寬度間距 數值

    if (i !== arr_col_name.length - 1) {
      doc.line(collum_width, collum_top, collum_width, collum_bottom); //直欄 1~10 各直線
    }
  }

  // let arrData_name_width = [90, 90, 60, 45, 60, 40, 45, 70, 80]; // 1~9 各資料列-文字欄寬
  // let data_name_width = 0;
  let data_width = 13;

  let subTotal = 0; //小計
  let total_num = total; //合計

  for (let i = 0; i < 28 && i < objP1.length; i++) {
    if (!!objP1[i + start].livename) {
      const { serial = '', cash = '', livename = '', sexualId = '', birthDay = '', zodiacId = '', addr = '' } = objP1[i + start]
      // 欄位文字資斛
      doc.setFont('Times', 'Roman'); //數字資料
      doc.setFontSize(14);
      doc.text(serial || '', 35, row_width + data_width + 3); //序號變數

      //doc.text("0051", 25, row_width + data_width); //序號變數
      doc.text(cash, 537, row_width + data_width + 3); //金額變數
      subTotal += Number(cash);
      //doc.text("12000", 515, row_width + data_width); //金額變數

      doc.setFont('BiauKai');
      doc.setFontSize(13);

      doc.text(livename, 85, row_width + data_width + 3); //輸入姓名變數
      doc.text(funcObjFind(objSexual2, sexualId), 163, row_width + data_width + 3); //性別
      doc.text(birthDay, 205, row_width + data_width + 3); //輸入生辰
      doc.text(funcObjFind(objChineseYear2, zodiacId), 323, row_width + data_width + 3); //輸入生肖
      doc.setFontSize(10);
      doc.text(addr, 357, row_width + data_width + 3); //輸入地址變數
      doc.setFontSize(11);
    }
    doc.setLineWidth(1);
    doc.line(20, row_width, 575, row_width); //各資料列-橫線

    row_width += 25;
  }
  //total = subTotal.toString();
  // 最後列欄位名稱
  doc.setFontSize(17);
  doc.text("小計", 24, 818);

  if (lastNum === 0) {
    doc.setFontSize(12);
    doc.text("接下頁", 275, 837);
    doc.setFontSize(17);
  }

  if (lastNum === 1) { //當為最後一頁時，顯示 總計欄位 金額
    doc.text("總計", 25, 805);
    doc.setFont('Times', 'Roman'); //數字資料
    doc.text(total_num, 490, 808); //總計金額 變數
  }

  doc.setFont('Times', 'Roman'); //數字資料
  doc.text(subTotal.toString(), 532, 818);   //小計金額 變數
  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)

  doc.line(20, row_width, 575, row_width); //欄位名稱-橫線

  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)
  // doc.line(20, 775,575, 775); //底部欄位(小計) 橫線
  doc.line(20, 800, 575, 800); //底部欄位(總計) 橫線
}

export const prayPageD00 = (doc, objP1, start, total, printdate, lastNum = 0) => { // 光明燈清單
  doc.setDrawColor(0, 0, 0)
  doc.rect(20, 50, 555, 775);

  let collum_bottom = 825;
  let collum_top = 50;
  let row_width = 70;  // 各橫列寬度為

  doc.setFont('BiauKai');
  doc.setFontSize(25);
  doc.text("龍潭慈惠堂光明燈清單", 180, 36);  // 表格名稱

  // doc.setFont('Times', 'Roman');
  doc.setFont('BiauKai');
  doc.setFontSize(15);
  // doc.text(printdate, 440, 30); // 右上角顯示列印日期
  //doc.text("0105/02/02",500 , 30, { width: 80, align: 'justify'});  // 製作日期

  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)
  doc.line(20, collum_top, 575, collum_top); //頂部標題名稱 橫線
  // doc.line(20, row_width,575, row_width); //欄位名稱-橫線

  let arrWidth = [30, 90, 40, 120, 40, 170, 40]; // 1~8 各直欄-直線欄寬
  let collum_width = 30;

  let arr_col_name = ["編號", "姓名", "性別", "農曆生辰", "生肖", "地址", "金額"]; // 1~9 各欄位名稱
  let arrCol_name_width = [26, 91, 156, 221, 316, 418, 534]; // 1~8 各欄位名稱-文字欄寬
  let col_name_width = 0;

  doc.setLineWidth(1);
  doc.setFont('BiauKai');
  doc.setFontSize(14);

  for (let i = 0; i < arr_col_name.length; i++) {

    doc.text(arr_col_name[i], arrCol_name_width[i], 65);  // 1~9 各欄位名稱

    collum_width += arrWidth[i]; //從陣列中 取第2個欄位 寬度間距 數值
    col_name_width += arrWidth[i]; //從陣列中 取第2個欄位 寬度間距 數值

    if (i !== arr_col_name.length - 1) {
      doc.line(collum_width, collum_top, collum_width, collum_bottom); //直欄 1~10 各直線
    }
  }

  // let arrData_name_width = [90, 90, 60, 45, 60, 40, 45, 70, 80]; // 1~9 各資料列-文字欄寬
  // let data_name_width = 0;
  let data_width = 13;

  let subTotal = 0; //小計
  let total_num = total; //合計

  for (let i = 0; i < 28 && i < objP1.length; i++) {
    if (!!objP1[i + start].livename) {
      const { serial = '', cash = '', livename = '', sexualId = '', birthDay = '', zodiacId = '', addr = '' } = objP1[i + start]
      // 欄位文字資斛
      doc.setFont('Times', 'Roman'); //數字資料
      doc.setFontSize(14);
      doc.text(serial || '', 35, row_width + data_width + 3); //序號變數

      //doc.text("0051", 25, row_width + data_width); //序號變數
      doc.text(cash, 537, row_width + data_width + 3); //金額變數
      subTotal += Number(cash);
      //doc.text("12000", 515, row_width + data_width); //金額變數

      doc.setFont('BiauKai');
      doc.setFontSize(13);

      doc.text(livename, 85, row_width + data_width + 3); //輸入姓名變數
      doc.text(funcObjFind(objSexual2, sexualId), 163, row_width + data_width + 3); //性別
      doc.text(birthDay, 205, row_width + data_width + 3); //輸入生辰
      doc.text(funcObjFind(objChineseYear2, zodiacId), 323, row_width + data_width + 3); //輸入生肖
      doc.setFontSize(10);
      doc.text(addr, 357, row_width + data_width + 3); //輸入地址變數
      doc.setFontSize(11);
    }
    doc.setLineWidth(1);
    doc.line(20, row_width, 575, row_width); //各資料列-橫線

    row_width += 25;
  }
  //total = subTotal.toString();
  // 最後列欄位名稱
  doc.setFontSize(17);
  doc.text("小計", 24, 818);

  if (lastNum === 0) {
    doc.setFontSize(12);
    doc.text("接下頁", 275, 837);
    doc.setFontSize(17);
  }

  if (lastNum === 1) { //當為最後一頁時，顯示 總計欄位 金額
    doc.text("總計", 25, 805);
    doc.setFont('Times', 'Roman'); //數字資料
    doc.text(total_num, 490, 808); //總計金額 變數
  }

  doc.setFont('Times', 'Roman'); //數字資料
  doc.text(subTotal.toString(), 532, 818);   //小計金額 變數
  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)

  doc.line(20, row_width, 575, row_width); //欄位名稱-橫線

  doc.setLineWidth(1);
  doc.setDrawColor(0, 0, 0)
  // doc.line(20, 775,575, 775); //底部欄位(小計) 橫線
  doc.line(20, 800, 575, 800); //底部欄位(總計) 橫線
}