import React, { Component } from 'react'
import { Container, Icon, Table, Button, Modal, Header, Form, Pagination, Dropdown, Confirm } from 'semantic-ui-react'
import Moment from 'moment';
import _ from 'lodash'
import SelectWeek from '../../widgets/SelectWeek';
import { getOptLabelName2 } from 'views/widgets/FieldsRender';
import Parse from '../../../widget/parse'

import { /*getWeekNumber,*/ getYearWeek, getWeekdayRangeArr, getDateOfISOWeek } from '../../../widget/define/week';


const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}
export default class AttendanceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yearWeek: getYearWeek(),
      unsub: null,
      doc0: [],
      doc1: [],
      doc2: [],
      doc3: [],
      doc4: [],
      weekDateRange: [],

      column: null,
      direction: null,
    }
  }

  componentDidMount() {
    // this.handleFetch(this.props)
  }

  // componentWillReceiveProps(nextProps) {
  //   this.handleFetch(nextProps)
  // }

  handleFetch = (props = this.props) => {
    const { companyObj, weekday, eventDate } = props
    const { yearWeek } = this.state
    const [year, Wweek] = yearWeek && yearWeek.split('-'); // 2019-W10 => 2019, W10
    const week = Wweek.slice(1); // W10 => 10
    const weekStartDate = getDateOfISOWeek(year, week, 0);
    const weekDateRange = getWeekdayRangeArr(weekStartDate);
    const startArr = weekDateRange[0].split('-');
    const month = startArr[1];
    // const db = firebase.firestore()
    this.setState({ yearWeek, year: Number(year), week: Number(week), month, weekDateRange, loading: 1, column: null, direction: null });

    Parse.queryData('Rollcall', { companyId: companyObj.objectId, eventDate: weekDateRange[0] }).then(snap => {
      const ps = [];
      snap.forEach(item2 =>//obj取有true的授業態度
        (item2.reasonKnown && Object.values(item2.reasonKnown).find(item => item === true)) ||
          (item2.inclassGoodStatus && Object.values(item2.inclassGoodStatus).find(item => item === true)) ||
          (item2.inclassBadStatus && Object.values(item2.inclassBadStatus).find(item => item === true)) ||
          item2.ps ? ps.push(item2) : null)
      this.setState({ doc0: ps })
    })
    // db.collectionGroup('Rollcall')
    //   // .where('eventDate', '<=', weekDateRange[4])
    //   // .where('eventDate', '>=', weekDateRange[0])
    //   .where('eventDate', '==', weekDateRange[0])
    //   .get().then(snap => {
    //     const ps = [];
    //     snap.forEach(item2 =>//obj取有true的授業態度
    //       (item2.data().reasonKnown && Object.values(item2.data().reasonKnown).find(item => item === true)) ||
    //         (item2.data().inclassGoodStatus && Object.values(item2.data().inclassGoodStatus).find(item => item === true)) ||
    //         (item2.data().inclassBadStatus && Object.values(item2.data().inclassBadStatus).find(item => item === true)) ||
    //         item2.data().ps ? ps.push({ _id: item2.id, ...item2.data() }) : null)
    //     this.setState({ doc0: ps })
    //   })
    Parse.queryData('Rollcall', { companyId: companyObj.objectId, eventDate: weekDateRange[1] }).then(snap => {
      const ps = [];
      snap.forEach(item2 => //obj取有true的授業態度
        (item2.reasonKnown && Object.values(item2.reasonKnown).find(item => item === true)) ||
          (item2.inclassGoodStatus && Object.values(item2.inclassGoodStatus).find(item => item === true)) ||
          (item2.inclassBadStatus && Object.values(item2.inclassBadStatus).find(item => item === true)) ||
          item2.ps ? ps.push(item2) : null)
      this.setState({ doc1: ps })
    })
    // db.collectionGroup('Rollcall')
    //   .where('eventDate', '==', weekDateRange[1])
    //   .get().then(snap => {
    //     const ps = [];
    //     snap.forEach(item2 => //obj取有true的授業態度
    //       (item2.data().reasonKnown && Object.values(item2.data().reasonKnown).find(item => item === true)) ||
    //         (item2.data().inclassGoodStatus && Object.values(item2.data().inclassGoodStatus).find(item => item === true)) ||
    //         (item2.data().inclassBadStatus && Object.values(item2.data().inclassBadStatus).find(item => item === true)) ||
    //         item2.data().ps ? ps.push({ _id: item2.id, ...item2.data() }) : null)
    //     this.setState({ doc1: ps })
    //   })
    Parse.queryData('Rollcall', { companyId: companyObj.objectId, eventDate: weekDateRange[2] }).then(snap => {
      const ps = [];
      snap.forEach(item2 => //obj取有true的授業態度
        (item2.reasonKnown && Object.values(item2.reasonKnown).find(item => item === true)) ||
          (item2.inclassGoodStatus && Object.values(item2.inclassGoodStatus).find(item => item === true)) ||
          (item2.inclassBadStatus && Object.values(item2.inclassBadStatus).find(item => item === true)) ||
          item2.ps ? ps.push(item2) : null)
      this.setState({ doc2: ps })
    })
    // db.collectionGroup('Rollcall')
    //   .where('eventDate', '==', weekDateRange[2])
    //   .get().then(snap => {
    //     const ps = [];
    //     snap.forEach(item2 => //obj取有true的授業態度
    //       (item2.data().reasonKnown && Object.values(item2.data().reasonKnown).find(item => item === true)) ||
    //         (item2.data().inclassGoodStatus && Object.values(item2.data().inclassGoodStatus).find(item => item === true)) ||
    //         (item2.data().inclassBadStatus && Object.values(item2.data().inclassBadStatus).find(item => item === true)) ||
    //         item2.data().ps ? ps.push({ _id: item2.id, ...item2.data() }) : null)
    //     this.setState({ doc2: ps })
    //   })
    Parse.queryData('Rollcall', { companyId: companyObj.objectId, eventDate: weekDateRange[3] }).then(snap => {
      const ps = [];
      snap.forEach(item2 => //obj取有true的授業態度
        (item2.reasonKnown && Object.values(item2.reasonKnown).find(item => item === true)) ||
          (item2.inclassGoodStatus && Object.values(item2.inclassGoodStatus).find(item => item === true)) ||
          (item2.inclassBadStatus && Object.values(item2.inclassBadStatus).find(item => item === true)) ||
          item2.ps ? ps.push(item2) : null)
      this.setState({ doc3: ps })
    })
    // db.collectionGroup('Rollcall')
    //   .where('eventDate', '==', weekDateRange[3])
    //   .get().then(snap => {
    //     const ps = [];
    //     snap.forEach(item2 => //obj取有true的授業態度
    //       (item2.data().reasonKnown && Object.values(item2.data().reasonKnown).find(item => item === true)) ||
    //         (item2.data().inclassGoodStatus && Object.values(item2.data().inclassGoodStatus).find(item => item === true)) ||
    //         (item2.data().inclassBadStatus && Object.values(item2.data().inclassBadStatus).find(item => item === true)) ||
    //         item2.data().ps ? ps.push({ _id: item2.id, ...item2.data() }) : null)
    //     this.setState({ doc3: ps })
    //   })
    Parse.queryData('Rollcall', { companyId: companyObj.objectId, eventDate: weekDateRange[4] }).then(snap => {
      const ps = [];
      snap.forEach(item2 => //obj取有true的授業態度
        (item2.reasonKnown && Object.values(item2.reasonKnown).find(item => item === true)) ||
          (item2.inclassGoodStatus && Object.values(item2.inclassGoodStatus).find(item => item === true)) ||
          (item2.inclassBadStatus && Object.values(item2.inclassBadStatus).find(item => item === true)) ||
          item2.ps ? ps.push(item2) : null)
      this.setState({ doc4: ps })
    })
    // db.collectionGroup('Rollcall')
    //   .where('eventDate', '==', weekDateRange[4])
    //   .get().then(snap => {
    //     const ps = [];
    //     snap.forEach(item2 => //obj取有true的授業態度
    //       (item2.data().reasonKnown && Object.values(item2.data().reasonKnown).find(item => item === true)) ||
    //         (item2.data().inclassGoodStatus && Object.values(item2.data().inclassGoodStatus).find(item => item === true)) ||
    //         (item2.data().inclassBadStatus && Object.values(item2.data().inclassBadStatus).find(item => item === true)) ||
    //         item2.data().ps ? ps.push({ _id: item2.id, ...item2.data() }) : null)
    //     this.setState({ doc4: ps })
    //   })
    // weekDateRange.forEach(eventDate => {
    // firebase.firestore().collectionGroup("Progress")
    //   .where('eventDate', '==', weekDateRange[0])
    //   .onSnapshot(snap => {
    //     const doc = []
    //     snap.forEach(item => doc.push({ _id: item.id, ...item.data() }))
    //     this.setState({ doc0: doc });
    //   })
    // firebase.firestore().collectionGroup("Progress")
    //   .where('eventDate', '==', weekDateRange[1])
    //   .onSnapshot(snap => {
    //     const doc = []
    //     snap.forEach(item => doc.push({ _id: item.id, ...item.data() }))
    //     this.setState({ doc1: doc });
    //   })
    // firebase.firestore().collectionGroup("Progress")
    //   .where('eventDate', '==', weekDateRange[2])
    //   .onSnapshot(snap => {
    //     const doc = []
    //     snap.forEach(item => doc.push({ _id: item.id, ...item.data() }))
    //     this.setState({ doc2: doc });
    //   })
    // firebase.firestore().collectionGroup("Progress")
    //   .where('eventDate', '==', weekDateRange[3])
    //   .onSnapshot(snap => {
    //     const doc = []
    //     snap.forEach(item => doc.push({ _id: item.id, ...item.data() }))
    //     this.setState({ doc3: doc });
    //   })
    // firebase.firestore().collectionGroup("Progress")
    //   .where('eventDate', '==', weekDateRange[4])
    //   .onSnapshot(snap => {
    //     const doc = []
    //     snap.forEach(item => doc.push({ _id: item.id, ...item.data() }))
    //     this.setState({ doc4: doc });
    //   })
    // })
  }

  handleWeekChange = (e, data) => {
    let yearWeek = data;
    if (typeof data !== 'string') {
      yearWeek = data.value
    }
    const [year, Wweek] = yearWeek && yearWeek.split('-'); // 2019-W10 => 2019, W10
    const week = Wweek.slice(1); // W10 => 10
    const weekStartDate = getDateOfISOWeek(year, week, 0);
    const weekDateRange = getWeekdayRangeArr(weekStartDate);
    const startArr = weekDateRange[0].split('-');
    const month = startArr[1];
    this.setState({ yearWeek, year: Number(year), week: Number(week), month, weekDateRange, loading: 1 }, () => this.handleFetch());
  };

  handleSort = (clickedColumn, dataName) => () => {
    const { column, direction } = this.state
    let data = dataName
    if (column !== clickedColumn) {
      let sorted = data.sort((a, b) => ('' + a[clickedColumn.substr(0, clickedColumn.length - 1)]).localeCompare('' + b[clickedColumn.substr(0, clickedColumn.length - 1)]))
      this.setState({
        column: clickedColumn,
        data: sorted,
        direction: 'ascending',
      })
      return
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  }

  render() {
    const { optSet } = this.props
    const { yearWeek, doc0, doc1, doc2, doc3, doc4, weekDateRange, column, direction } = this.state

    let weekArr = [doc4, doc3, doc2, doc1, doc0]
    return (
      <div>
        <div style={{ ...style.flexCenter }}>
          <Header as='h2' content='出席狀況' />
          <SelectWeek
            yearWeek={yearWeek}
            // minWeek={minWeek}
            // maxWeek={maxWeek}
            handleWeekChange={this.handleWeekChange}
          />
        </div>
        {
          weekArr.map((doc, index) => //日期降冪
            new Date(weekDateRange[4 - index]) > new Date() ? null : //之後的日期不顯示
              <Table celled sortable key={'table' + index}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan='6' textAlign='center'>{weekDateRange[4 - index]}</Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell width={2} textAlign='center'
                      sorted={column === `classname${4 - index}` ? direction : null}
                      onClick={this.handleSort(`classname${4 - index}`, doc)}>班級</Table.HeaderCell>
                    <Table.HeaderCell width={3} textAlign='center'
                      sorted={column === `studentNum${4 - index}` ? direction : null}
                      onClick={this.handleSort(`studentNum${4 - index}`, doc)}>学籍番號</Table.HeaderCell>
                    <Table.HeaderCell width={3} textAlign='center'>学生</Table.HeaderCell>
                    <Table.HeaderCell width={3} textAlign='center'
                      sorted={column === `courseName${4 - index}` ? direction : null}
                      onClick={this.handleSort(`courseName${4 - index}`, doc)}>時限</Table.HeaderCell>
                    <Table.HeaderCell width={3} textAlign='center'
                      sorted={column === `attendStatusText${4 - index}` ? direction : null}
                      onClick={this.handleSort(`attendStatusText${4 - index}`, doc)}>出席狀況</Table.HeaderCell>
                    <Table.HeaderCell width={5} textAlign='center'>授業態度(備考)</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {doc.length ? doc.map((item, index) =>
                    <Table.Row key={`11111doc0${index}`}>
                      <Table.Cell width={2}>{item.classname || ''}</Table.Cell>
                      <Table.Cell width={3}>{item.studentNum || ''}</Table.Cell>
                      <Table.Cell width={3}>{item.jpnName || ''}</Table.Cell>
                      <Table.Cell width={3}>{item.courseName || ''}</Table.Cell>
                      <Table.Cell width={3}>{item.attendStatusText || ''}</Table.Cell>
                      <Table.Cell width={5}>{(item.reasonKnown &&
                        Object.values(item.reasonKnown).find(item2 => item2 === true) &&
                        getOptLabelName2(optSet, Object.keys(item.reasonKnown)[Object.values(item.reasonKnown).findIndex(item2 => item2 === true)]) + `\n`) || ''}
                        {item.absentReason ? `理由: ${item.absentReason}` : null}
                        {(item.inclassGoodStatus &&
                          Object.values(item.inclassGoodStatus).find(item2 => item2 === true) &&
                          getOptLabelName2(optSet, Object.keys(item.inclassGoodStatus)[Object.values(item.inclassGoodStatus).findIndex(item2 => item2 === true)]) + `\n`) || ''}
                        {(item.inclassBadStatus &&
                          Object.values(item.inclassBadStatus).find(item2 => item2 === true) &&
                          getOptLabelName2(optSet, Object.keys(item.inclassBadStatus)[Object.values(item.inclassBadStatus).findIndex(item2 => item2 === true)]) + `\n`) || ''}
                        {item.ps ? `備考: ${item.ps}` : null}</Table.Cell>
                    </Table.Row>)
                    : <Table.Row><Table.Cell colSpan='5' textAlign='center'>資料無し</Table.Cell></Table.Row>}
                </Table.Body>
              </Table>
          )
        }
      </div >)
  }
}
