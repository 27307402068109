import React, { Component } from 'react';
import { Form, Button, Modal, Table, Grid, Icon, Header, Confirm, Select, Divider } from 'semantic-ui-react';
import { showField } from 'views/widgets/FieldsRender';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, CardHeader, CardLink
} from "reactstrap";

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export default class List1Teacher extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      show: false,
      isReadOnly: false,
      form: {},
      loading: false,
      confirm: false,
    };
  }

  // componentDidMount() {
  //   const { firebase, match, teachers } = this.props
  //   const { _id } = match.params;

  //   if (_id) {
  //     if (this.state.unsub) {
  //       this.state.unsub();
  //     }
  //     const unsub = firebase.firestore()
  //       .collection(`Teachers`)
  //       // .orderBy('relationship')
  //       // .where('eventDate', '==', eventDate).where('classname', '==', className)
  //       .get(snap => {
  //         const data = [];
  //         snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
  //         // console.log(data);
  //         this.setState({ teacher: data });

  //       }, err => {
  //         console.log(`Encountered error: ${err}`);
  //       });
  //     this.setState({ unsub });
  //   }
  // }

  // componentWillReceiveProps(nextProps) {
  //   // this.setState({ doc: nextProps.doc });
  //   const { firebase, match, teachers } = nextProps;
  //   const { teacher } = this.state
  //   const { _id } = match.params;
  //   // console.log(match)
  //   // console.log(_id)

  //   if (_id) {
  //     if (this.state.unsub) {
  //       this.state.unsub();
  //       // console.log('unsub worked!')
  //     }
  //     const unsub = firebase.firestore()
  //       .collection(`Teachers`)
  //       // .orderBy('relationship')
  //       // .where('eventDate', '==', eventDate).where('classname', '==', className)
  //       .onSnapshot(snap => {
  //         const data = [];
  //         snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
  //         // console.log(data);
  //         this.setState({ teacher: data });
  //       }, err => {
  //         console.log(`Encountered error: ${err}`);
  //       });
  //     this.setState({ unsub, /*form: { inquiryDate: this.getToday() }*/ });
  //   }
  // }

  handleChange = (event, data) => {
    const { name, value } = data;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    })
  }

  handleSubmit = () => {
    const { firebase, teachers, match } = this.props
    const { form } = this.state
    const { _id } = match.params;
    this.setState({ loading: true })

    firebase.firestore().collection(`Teachers`).doc(_id)
      .update(form).then(snap => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { teachers, teacherObj, match, isEdit, doc } = this.props
    const { form, teacher, loading } = this.state
    const semesterArr = [
      { key: '2020,A', text: '2020A (20.10.1~21.3.31)', value: '2020,A' },
      { key: '2020,S', text: '2020S (20.4.1~20.9.30)', value: '2020,S' },
      { key: '2019,A', text: '2019A (19.10.1~20.3.31)', value: '2019,A' },
      { key: '2019,S', text: '2019S (19.4.1~19.9.30)', value: '2019,S' },
      { key: '2018,A', text: '2018A (18.10.1~19.3.31)', value: '2018,A' },
      { key: '2018,S', text: '2018S (18.4.1~18.9.30)', value: '2018,S' },
      { key: '2017,A', text: '2017A (17.10.1~18.3.31)', value: '2017,A' },
      { key: '2017,S', text: '2017S (17.4.1~17.9.30)', value: '2017,S' },
      { key: '2016,A', text: '2016A (16.10.1~17.3.31)', value: '2016,A' },
      { key: '2016,S', text: '2016S (16.4.1~16.9.30)', value: '2016,S' },
      { key: '2015,A', text: '2015A (15.10.1~16.3.31)', value: '2015,A' },
      { key: '2015,S', text: '2015S (15.4.1~15.9.30)', value: '2015,S' },
      { key: '2014,A', text: '2014A (14.10.1~15.3.31)', value: '2014,A' },
      { key: '2014,S', text: '2014S (14.4.1~14.9.30)', value: '2014,S' },
      { key: '2013,A', text: '2013A (13.10.1~14.3.31)', value: '2013,A' },
      { key: '2013,S', text: '2013S (13.4.1~13.9.30)', value: '2013,S' },
      { key: '2012,A', text: '2012A (12.10.1~13.3.31)', value: '2012,A' },
      { key: '2012,S', text: '2012S (12.4.1~12.9.30)', value: '2012,S' },
      { key: '2011,A', text: '2011A (11.10.1~12.3.31)', value: '2011,A' },
      { key: '2011,S', text: '2011S (11.4.1~11.9.30)', value: '2011,S' },
      { key: '1990,A', text: '1990A (90.10.1~91.3.31)', value: '1990,A' },
      { key: '1990,S', text: '1990S (90.4.1~90.9.30)', value: '1990,S' },
    ]
    const semesterArrStart = [
      { key: 'semesterArrIn0', text: '-- 選択 --', value: ' ' },
      ...semesterArr
    ]
    const semesterArrEnd = [
      { key: 'semesterArrOut0', text: '尚未離職', value: ' ' },
      ...semesterArr
    ]
    return (
      <>
        <div style={style.flexCenter}>
          <Header as='h2' content='マスター情報' />
          {/* {isEdit ? <Button inverted={companyObj.inverted} color='blue' content='修正' onClick={this.handleSubmit} loading={loading} /> : null} */}
        </div>
        <Divider />
        {/* <Grid columns='equal' divided> */}
        {/* {teacher.map(item => item._id === match.params._id ? <React.Fragment key={item._id}> */}
        <Row>
          <Col sm='6'>
            {/* <Row > */}
            {/* <Grid columns='equal' > */}
            {/* <Col > */}
            <Card fluid>
              <CardHeader tag="h5" > 本人基本情報</CardHeader>
              {/* <CardBody header='本人基本情報' /> */}
              <CardBody>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col sm='4'>{showField(this.props, "teacherNum")}</Col>
                  <Col sm='4'>{showField(this.props, "teacherTitle")}</Col>
                  <Col sm='4'>{showField(this.props, "genderTeacher")}</Col>
                  {/* <Col>
                              <Form.Input label='教員番号' defaultValue={item.teacherNum || ''} name='teacherNum' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col>
                            <Col>
                              <Form.Input label='職名' defaultValue={item.teacherTitleText || ''} name='teacherTitleText' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col>
                            <Col>
                              <Form.Input label='性別' defaultValue={item.genderTeacherText || ''} name='genderTeacherText' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col> */}
                  {/* </Grid> */}
                </Row>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col sm='4'>{showField(this.props, "nameTeacher")}</Col>
                  <Col sm='4'>{showField(this.props, "nameTeacherFull")}</Col>
                  <Col sm='4'>{showField(this.props, "birthdayTeacher")}</Col>
                  {/* <Col>
                              <Form.Input label='氏名' defaultValue={item.nameTeacher || ''} name='nameTeacher' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col>
                            <Col>
                              <Form.Input label='氏名 (全名)' defaultValue={item.nameTeacherFull || ''} name='nameTeacherFull' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col>
                            <Col>
                              <Form.Input label='生年月日' defaultValue={item.birthdayTeacher ? item.birthdayTeacher.split('T')[0] : ''} name='birthdayTeacher' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col> */}
                  {/* </Grid> */}
                </Row>
              </CardBody>
            </Card>
            <Card fluid>
              <CardHeader tag="h5" > 本人聯絡情報</CardHeader>
              {/* <CardBody header='本人聯絡情報' /> */}
              <CardBody>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col sm='6'>{showField(this.props, "telTeacher")}</Col>
                  <Col sm='6'>{showField(this.props, "emailTeacher")}</Col>
                  {/* </Grid> */}
                </Row>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col sm='6'>{showField(this.props, "transferTime")}</Col>
                  <Col sm='6'>{showField(this.props, "transferFee")}</Col>
                  {/* </Grid> */}
                </Row>
                <Row>
                  {/* <Grid columns='equal'> */}
                  {/* <Col>{showField(this.props, "startSemesterNum")}</Col>
                          <Col>{showField(this.props, "endSemesterNum")}</Col> */}
                  <Col sm='6'>
                    <Form.Field control={Select} disabled={!isEdit} label='就職学期' options={semesterArrStart} onChange={this.handleChangeModal} name='startSemesterNum' defaultValue={doc.startSemesterNum || ''} />
                  </Col>
                  <Col sm='6'>
                    <Form.Field control={Select} disabled={!isEdit} label='離職学期' options={semesterArrEnd} onChange={this.handleChangeModal} name='endSemesterNum' defaultValue={doc.endSemester || ''} />
                  </Col>
                  {/* </Grid> */}
                </Row>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col>{showField(this.props, "addrTeacher")}</Col>
                  {/* <Col>
                              <Form.Input label='住所' defaultValue={item.addrTeacher || ''} name='addrTeacher' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col> */}
                  {/* </Grid> */}
                </Row>
              </CardBody>
            </Card>
            {/* </Col> */}
            {/* </Grid> */}
            {/* </Row> */}
          </Col>
          <Col sm='6'>
            {/* <Row> */}
            {/* <Grid columns='equal'> */}
            {/* <Col> */}
            <Card fluid>
              <CardHeader tag="h5" > Title</CardHeader>
              {/* <CardBody header='Title' /> */}
              <CardBody>
                <Row>
                  {/* <Grid columns='1'> */}
                  <Col>{showField(this.props, "staffStatus")}</Col>
                  {/* </Grid> */}
                </Row>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col sm='4'>{showField(this.props, "partTimeTeacher")}</Col>
                  <Col sm='4'>{showField(this.props, "serve")}</Col>
                  <Col sm='4'>{showField(this.props, "lessonsubject")}</Col>
                  {/* <Col>
                              <Form.Input label='専任非常勤の別' defaultValue={item.partTimeTeacherText || ''} name='partTimeTeacherText' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col>
                            <Col>
                              <Form.Input label='担当クラス名(授業担当科目)' defaultValue={item.serve || ''} name='serve' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col>
                            <Col>
                              <Form.Input label='授業科目内容等' defaultValue={item.lessonsubject || ''} name='lessonsubject' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col> */}
                  {/* </Grid> */}
                </Row>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col sm='4'>{showField(this.props, "finaldegree")}</Col>
                  <Col sm='4'>{showField(this.props, "major")}</Col>
                  <Col sm='4'>{showField(this.props, "minor")}</Col>
                  {/* <Col>
                              <Form.Input label='最終学歷 (学校名.学部)' defaultValue={item.finaldegree || ''} name='finaldegree' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col>
                            <Col>
                              <Form.Input label='主専攻課程修了' defaultValue={item.major || ''} name='major' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col>
                            <Col>
                              <Form.Input label='副専攻課程修了' defaultValue={item.minor || ''} name='minor' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col> */}
                  {/* </Grid> */}
                </Row>
              </CardBody>
            </Card>
            {/* </Col> */}
            {/* </Grid> */}
            {/* </Row> */}
            {/* <Row> */}
            {/* <Grid columns='equal'> */}
            {/* <Col> */}
            <Card fluid>
              <CardHeader tag="h5" > Title</CardHeader>
              {/* <CardBody header='Title' /> */}
              <CardBody>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col sm='6'>{showField(this.props, "jpnLanEduc")}</Col>
                  <Col sm='6'>{showField(this.props, "trainingCourse")}</Col>
                  {/* <Col>
                              <Form.Input label='日本語教育能力検定試験合格' defaultValue={item.jpnLanEduc || ''} name='jpnLanEduc' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col>
                            <Col>
                              <Form.Input label='養成講座(420時間以上もの)' defaultValue={item.trainingCourse || ''} name='trainingCourse' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col> */}
                  {/* </Grid> */}
                </Row>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col sm='6'>{showField(this.props, "jpnexp")}</Col>
                  <Col sm='6'>{showField(this.props, "jpnagc")}</Col>
                  {/* <Col>
                              <Form.Input label='日本語教育歷 年.月' defaultValue={item.jpnexp || ''} name='jpnexp' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col>
                            <Col>
                              <Form.Input label='日本語教育機関への就任年月' defaultValue={item.jpnagc || ''} name='jpnagc' readOnly={!isEdit} onChange={this.handleChange} />
                            </Col> */}
                  {/* </Grid> */}
                </Row>
              </CardBody>
            </Card>
            {/* </Col> */}
            {/* </Grid> */}
            {/* </Row> */}
          </Col>
          {/* <Col></Col> */}
          {/* </React.Fragment> : null)} */}
          {/* </Grid> */}
        </Row>
      </>
    )
  }
}