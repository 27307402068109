
import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ModalUpdateFile from '../web/ModalUpdateFile'
import { RowImage } from '../web/RowImage'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

const iconSel = {
  'nc-air-baloon': 'nc-air-baloon',
  'nc-album-2': 'nc-album-2',
  'nc-alert-circle-i': 'nc-alert-circle-i',
  'nc-align-center': 'nc-align-center',
  'nc-align-left-2': 'nc-align-left-2',
  'nc-ambulance': 'nc-ambulance',
  'nc-app': 'nc-app',
  'nc-atom': 'nc-atom',
  'nc-badge': 'nc-badge',
  'nc-bag-16': 'nc-bag-16',
  'nc-bank': 'nc-bank',
  'nc-basket': 'nc-basket',
  'nc-bell-55': 'nc-bell-55',
  'nc-bold': 'nc-bold',
  'nc-book-bookmark': 'nc-book-bookmark',
  'nc-bookmark-2': 'nc-bookmark-2',
  'nc-box-2': 'nc-box-2',
  'nc-box': 'nc-box',
  'nc-briefcase-24': 'nc-briefcase-24',
  'nc-bulb-63': 'nc-bulb-63',
  'nc-bullet-list-67': 'nc-bullet-list-67',
  'nc-bus-front-12': 'nc-bus-front-12',
  'nc-button-pause': 'nc-button-pause',
  'nc-button-play': 'nc-button-play',
  'nc-button-power': 'nc-button-power',
  'nc-calendar-60': 'nc-calendar-60',
  'nc-camera-compact': 'nc-camera-compact',
  'nc-caps-small': 'nc-caps-small',
  'nc-cart-simple': 'nc-cart-simple',
  'nc-chart-bar-32': 'nc-chart-bar-32',
  'nc-chart-pie-36': 'nc-chart-pie-36',
  'nc-chat-33': 'nc-chat-33',
  'nc-check-2': 'nc-check-2',
  'nc-circle-10': 'nc-circle-10',
  'nc-cloud-download-93': 'nc-cloud-download-93',
  'nc-cloud-upload-94': 'nc-cloud-upload-94',
  'nc-compass-05': 'nc-compass-05',
  'nc-controller-modern': 'nc-controller-modern',
  'nc-credit-card': 'nc-credit-card',
  'nc-delivery-fast': 'nc-delivery-fast',
  'nc-diamond': 'nc-diamond',
  'nc-email-85': 'nc-email-85',
  'nc-favourite-28': 'nc-favourite-28',
  'nc-glasses-2': 'nc-glasses-2',
  'nc-globe-2': 'nc-globe-2',
  'nc-globe': 'nc-globe',
  'nc-hat-3': 'nc-hat-3',
  'nc-headphones': 'nc-headphones',
  'nc-html5': 'nc-html5',
  'nc-image': 'nc-image',
  'nc-istanbul': 'nc-istanbul',
  'nc-key-25': 'nc-key-25',
  'nc-laptop': 'nc-laptop',
  'nc-layout-11': 'nc-layout-11',
  'nc-lock-circle-open': 'nc-lock-circle-open',
  'nc-map-big': 'nc-map-big',
  'nc-minimal-down': 'nc-minimal-down',
  'nc-minimal-left': 'nc-minimal-left',
  'nc-minimal-right': 'nc-minimal-right',
  'nc-minimal-up': 'nc-minimal-up',
  'nc-mobile': 'nc-mobile',
  'nc-money-coins': 'nc-money-coins',
  'nc-note-03': 'nc-note-03',
  'nc-palette': 'nc-palette',
  'nc-paper': 'nc-paper',
  'nc-pin-3': 'nc-pin-3',
  'nc-planet': 'nc-planet',
  'nc-refresh-69': 'nc-refresh-69',
  'nc-ruler-pencil': 'nc-ruler-pencil',
  'nc-satisfied': 'nc-satisfied',
  'nc-scissors': 'nc-scissors',
  'nc-send': 'nc-send',
  'nc-settings-gear-65': 'nc-settings-gear-65',
  'nc-settings': 'nc-settings',
  'nc-share-66': 'nc-share-66',
  'nc-shop': 'nc-shop',
  'nc-simple-add': 'nc-simple-add',
  'nc-simple-delete': 'nc-simple-delete',
  'nc-simple-remove': 'nc-simple-remove',
  'nc-single-02': 'nc-single-02',
  'nc-single-copy-04': 'nc-single-copy-04',
  'nc-sound-wave': 'nc-sound-wave',
  'nc-icon nc-spaceship': 'nc-icon nc-spaceship',
  'nc-sun-fog-29': 'nc-sun-fog-29',
  'nc-support-17': 'nc-support-17',
  'nc-tablet-2': 'nc-tablet-2',
  'nc-tag-content': 'nc-tag-content',
  'nc-tap-01': 'nc-tap-01',
  'nc-tie-bow': 'nc-tie-bow',
  'nc-tile-56': 'nc-tile-56',
  'nc-time-alarm': 'nc-time-alarm',
  'nc-touch-id': 'nc-touch-id',
  'nc-trophy': 'nc-trophy',
  'nc-tv-2': 'nc-tv-2',
  'nc-umbrella-13': 'nc-umbrella-13',
  'nc-user-run': 'nc-user-run',
  'nc-vector': 'nc-vector',
  'nc-watch-time': 'nc-watch-time',
  'nc-world-2': 'nc-world-2',
  'nc-zoom-split': 'nc-zoom-split',
  // 'nc-atom': 'nc-atom',
  // 'nc-atom': 'nc-atom',
  // 'nc-atom': 'nc-atom',
}

const columns = ({
  showIcon = false, showAlign = false,
  showFile1 = false,
  showTitle1 = false, showContent1 = false, showUrl = false,
  showCover = false
  // showTitle2 = false, showContent2 = false,
}) => ([
  { width: 80, title: '封面', field: 'iscover', hidden: !showCover, type: 'boolean', initialEditValue: false },
  {
    width: 50, title: '', field: 'img0sel', hidden: !showFile1, filtering: false, headerStyle: { textAlign: 'center' }, render: rowData => <></>,
    editComponent: props => <ModalUpdateFile fieldname='file1' rowData={props.rowData} onRowDataChange={props.onRowDataChange} />
  },
  {
    width: 120, title: '圖片/檔案', field: 'file1', hidden: !showFile1, filtering: false,
    render: rowData => <><RowImage rowData={rowData} field='file1' />{rowData.name}</>, editComponent: props => <><RowImage rowData={props.rowData} field='file1' />{props.rowData.name}</>
  },
  { width: 160, title: '文字位置', field: 'align', hidden: !showAlign, lookup: { '0': '左邊', '1': '中間', '2': '右邊' } },
  { width: 60, title: 'ICON', field: 'icon', hidden: !showIcon, lookup: iconSel },
  { width: 160, title: '標題1(繁體中文)', field: 'title1name', hidden: !showTitle1 },
  { width: 160, title: '標題1(简体中文)', field: 'title1zh-hant', hidden: !showTitle1 },
  { width: 160, title: '標題1(日本語)', field: 'title1ja', hidden: !showTitle1 },
  { width: 160, title: '標題1(English)', field: 'title1en', hidden: !showTitle1 },
  { width: 160, title: '標題1(Vietnam)', field: 'title1vi', hidden: !showTitle1 },
  { width: 160, title: '內文1(繁體中文)', field: 'content1name', hidden: !showContent1 },
  { width: 160, title: '內文1(简体中文)', field: 'content1zh-hant', hidden: !showContent1 },
  { width: 160, title: '內文1(日本語)', field: 'content1ja', hidden: !showContent1 },
  { width: 160, title: '內文1(English)', field: 'content1en', hidden: !showContent1 },
  { width: 160, title: '內文1(Vietnam)', field: 'content1vi', hidden: !showContent1 },
  { width: 160, title: '連結', field: 'url', hidden: !showUrl },

  // { width: 160, title: '標題2(繁體中文)', field: 'title2name', hidden: !showTitle2 },
  // { width: 160, title: '標題2(简体中文)', field: 'title2ZhHant', hidden: !showTitle2 },
  // { width: 160, title: '標題2(日本語)', field: 'title2Ja', hidden: !showTitle2 },
  // { width: 160, title: '標題2(English)', field: 'title2En', hidden: !showTitle2 },
  // { width: 160, title: '標題2(Vietnam)', field: 'title2Vi', hidden: !showTitle2 },
  // { width: 160, title: '內文2(繁體中文)', field: 'content2Name', hidden: !showContent2 },
  // { width: 160, title: '內文2(简体中文)', field: 'content2ZhHant', hidden: !showContent2 },
  // { width: 160, title: '內文2(日本語)', field: 'content2Ja', hidden: !showContent2 },
  // { width: 160, title: '內文2(English)', field: 'content2En', hidden: !showContent2 },
  // { width: 160, title: '內文2(Vietnam)', field: 'content2Vi', hidden: !showContent2 },
  // { width: 160, title: '標題3(繁體中文)', field: 'title3Name' },
  // { width: 160, title: '標題3(简体中文)', field: 'title3ZhHant' },
  // { width: 160, title: '標題3(日本語)', field: 'title3Ja' },
  // { width: 160, title: '標題3(English)', field: 'title3En' },
  // { width: 160, title: '標題3(Vietnam)', field: 'title3Vi' },
]);
class EditWebMaterialTable extends Component {
  state = {
    data1: [],
    loading: false
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps)
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, form } = props
    if (form.type === 'announce') {
      this.setState({ loading: true })
      this.setState({ data1: props.data1, loading: false })
    } else {
      const companyId = companyObj.objectId
      const data1 = await Parse.queryData('webimage', { companyId, pageId: form.pageId, componentId: form.componentId });
      this.setState({ data1 })
    }

  }

  handleAdd = async (type) => {
    const { form, onRowDataChange } = this.props
    const { newData, data1 } = this.state;

    if (newData.iscover === true) {
      const promises = [];
      const obj = {
        ...form,
        img1File: newData.file1,
        // img1File2: newData.img1File2
      }

      promises.push(Parse.saveData('announceCenter', obj));
      data1.forEach((item, i) => promises.push(Parse.saveData('webimage', data1[i])));
      await Promise.all(promises).catch(error => console.log("error", error));
      // await Parse.saveData('webimage', newData);
      onRowDataChange(obj)
      // this.setState({ loading: false });
    } else {
      await Parse.saveData('webimage', newData)
      this.setState({ loading: false });
    }
  }

  handleUpdate = async (type) => {
    const { form, onRowDataChange } = this.props
    const { newData, data1 } = this.state
    if (newData.iscover === true) {
      const promises = [];
      const obj = {
        ...form,
        img1File: newData.file1,
        // img1File2: newData.img1File2
      }
      promises.push(Parse.saveData('announceCenter', obj));
      data1.forEach((item, i) => promises.push(Parse.saveData('webimage', data1[i])));
      await Promise.all(promises).catch(error => console.log("error", error));
      // await Parse.saveData('webimage', newData);
      onRowDataChange(obj)
      // this.setState({ loading: false });
      // this.props.handleChangeFile(newData.file1, data1)
    } else {
      await Parse.saveData('webimage', newData)
      this.setState({ loading: false });
    }
  }
  handleDelete = async (type) => {
    const { form, onRowDataChange } = this.props
    const { oldData } = this.state
    this.setState({ loading: true });
    let obj = {
      ...form,
      img1File: null
    }
    await Parse.deleteData('webimage', oldData);
    if (oldData.iscover === true) {
      await Parse.saveData('announceCenter', obj);
      onRowDataChange(obj)
    }
    this.setState({ oldData: {}, loading: false })
  }

  render() {
    const { form, pageObj, showColumns = {}, showContent, companyObj } = this.props
    const { pageId } = form
    // const { companyObj } = AnnounceCenterObj
    const { data1, loading } = this.state
    // console.log(companyObj)
    return (
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns(showColumns)}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, form.componentId),
          pageSize: 5,
          search: false,
          tableLayout: 'fixed'
        }}
        // tableRef={this.tableRef}
        data={data1}
        title={form.componentId}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const db = Parse.Object.extend("webimage");
              const newDoc = new db();
              const docRef = await newDoc.save();
              let data = [...data1];
              let obj = {
                ...newData,
                id: docRef.id,
                _id: docRef.id,
                objectId: docRef.id,
                companyId: companyObj.objectId,
                companyName: companyObj.name,
                pageId: pageId,
                componentId: form.componentId,
                navId: form.objectId,
              }
              if (newData.iscover === true) {
                data = data.map(item => ({ ...item, iscover: false }))
              }
              data.push(obj);
              // handleState('people0', data);
              this.setState({ data1: data, newData: obj, loading: true }, () => resolve());
            }).then(() => this.handleAdd()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              let data = [...data1];
              const index = data.indexOf(oldData);
              if (newData.iscover === true) {
                data = data1.map(item => ({ ...item, iscover: false }))
              }
              data[index] = newData;
              let obj = { ...newData, }
              // handleState('people0', data);
              this.setState({ data1: data, newData: obj, oldData, loading: true }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...data1];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              // handleState('people0', data);
              this.setState({ data1: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
    );
  }
}

export default EditWebMaterialTable;
