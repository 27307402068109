import React, { Component } from 'react';
import { Form, Button } from 'semantic-ui-react'
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';

import ExportTableToExcelButton from 'widget/ExportTableToExcelButton'
// import { showField, showFieldText, getActiveSemesterArr, getOptionsByKey, getStudentCountryText } from 'views/widgets/FieldsRender';
import ViewArrangeList from './ViewArrangeList';
import Parse from '../../../../widget/parse'

export default class ArrangeCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: { studentType: "" },
    };
  }

  componentDidMount() {
    this.handleFetch();
  }

  handleFetch = async (props = this.props) => {
    const { firebase, companyObj } = props
    const { isEdit, doc } = this.state;

    // firebase.firestore()
    //   .collection(`Dormitory`)
    //   .orderBy('dormitoryFrom', 'asc')
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ dorms: data });
    //     console.log(data)
    //   }, err => {
    //     console.log(`Encountered error: ${err}`);
    //   });

    const data = await Parse.queryData('Dormitory', { companyId: companyObj.objectId }, { orderBy: 'dormitoryFrom' });
    this.setState({ dorms: data });


  }

  render() {
    const { semesterYear, semester, formSet, optSet, classroom, grade2, students } = this.props;
    const { eventDate, dorms, loading } = this.state;
    const { studentType } = this.state.form;

    let roomNumSchool = 0
    let roomNumRent = 0
    let bedNumSchool = 0
    let bedNumRent = 0
    dorms && dorms.map((dorm => {
      if (dorm.dormitoryFrom === '1') {
        roomNumSchool += dorm.roomData.length;
        bedNumSchool += Number(dorm.roomData.reduce((acc, cur) =>
          ({ roomType: Number(acc.roomType) + Number(cur.roomType) })).roomType)
      } else {
        roomNumRent += dorm.roomData.length;
        bedNumRent += Number(dorm.roomData.reduce((acc, cur) =>
          ({ roomType: Number(acc.roomType) + Number(cur.roomType) })).roomType)
      }
    }))

    return (
      <>
        <div style={{ float: 'right' }}>
          <ReactToPrint
            trigger={(e) => <Button
              content='印刷'
              // label='&nbsp;'
              color='orange' icon='print'
              size='small'
              floated='right'
              className='no-print'
            />}
            content={() => this.componentRef}
            pageStyle={"@page { size: auto;  margin:5mm; }*{font-family: 'Noto Serif JP', serif;}"}
          />
          <ModalPdf
            {...this.props}
            // ref={(ref) => { this.pdfModal = ref }}
            codekey="table37"
          // params={params}
          />
          <ExportTableToExcelButton floated='right' size='small' name='StudentsList' />
        </div>
        <div ref={el => (this.componentRef = el)} className="" >
          <h2>予想狀況一覽表</h2>
          <div style={{ float: 'right', textAlign: 'right' }}>
            <span style={{ color: 'red' }}>★新入生</span>&nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ color: 'blue' }}>△移住者</span>
          </div>
          <div style={{ float: 'left', textAlign: 'left' }}>
            <span>学校の寮：{dorms && dorms.filter(item => item.dormitoryFrom === '1').map((dorm => <span key={`${dorm._id}`}>{dorm.dormitoryName}:{dorm.roomData.length}部屋&nbsp;&nbsp;</span>))}計{roomNumSchool}部屋（キャパシティ最大{bedNumSchool}人）</span><br />
            <span>賃貸の寮：{dorms && dorms.filter(item => item.dormitoryFrom === '2').map((dorm => <span key={`${dorm._id}`}>{dorm.dormitoryName}:{dorm.roomData.length}部屋&nbsp;&nbsp;</span>))}計{roomNumRent}部屋（キャパシティ最大{bedNumRent}人）</span>
          </div>
          <div style={{ clear: 'both' }}></div>
          <div style={{ padding: '1px' }}>
            <ViewArrangeList {...this.props} dorms={dorms} />
          </div>
        </div>
      </>
    );
  }
}