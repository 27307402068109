import React, { Component } from 'react'
import { Container, Header, Modal, Table, Form, Grid, Image, Input, Checkbox, Divider, Tab, GridColumn, Segment, List, Label, Message, TextArea } from 'semantic-ui-react';
import Moment from 'moment';
import { Button } from "reactstrap";

import Parse from "widget/parse";
import { fetchData } from 'widget/http2';

const nameListItem = ({ name, lineId, lineDisplayName, pictureUrl, lineStatusMessage, displayName, statusMessage }, handleClickListItem) => (
  <List.Item key={'nli' + lineId} style={{ padding: '15px' }} onClick={handleClickListItem} lineid={lineId}>
    <Image avatar src={pictureUrl} />
    <List.Content >
      {/* <List.Header as='a'>{name} ({lineDisplayName})</List.Header> */}
      <List.Header as='a'>{displayName} ({displayName})</List.Header>
      <List.Description>{statusMessage}
        {/* <br />{lineId} */}
      </List.Description>
    </List.Content>
  </List.Item>)

const topListItem = ({ name, lineId, lineDisplayName, linePictureUrl, lineStatusMessage }) => (
  <List key={'tli' + lineId} size={'large'} style={{ backgroundColor: '#F0F0F0' }}>
    <List.Item style={{ padding: '10px' }}>
      <Image avatar src={linePictureUrl} />
      <List.Content>
        <List.Header as='a'>{name} ({lineDisplayName})</List.Header>
        <List.Description>{lineStatusMessage}
          {/* <br />{lineId} */}
        </List.Description>
      </List.Content>
    </List.Item>
  </List >)

const fromMessage = (userObj, { createdAt, text }, index) => <React.Fragment key={'fm' + userObj.objectId + index}>
  <List.Item style={{ padding: '5px' }} >
    <List.Content floated='left'>{userObj.name} {Moment(createdAt).format('HH:mm:ss YYYY-MM-DD')}</List.Content>
  </List.Item>
  <List.Item >
    <Message style={{ width: "80%", float: 'left' }} content={text} />
  </List.Item>
</React.Fragment>;

const replyMessage = (userObj, { createdAt, text, sendLineDisplayName }, index) => <React.Fragment key={'rm' + userObj.objectId + index}>
  <List.Item style={{ padding: '5px' }} >
    <List.Content floated='right'>{sendLineDisplayName} {Moment(createdAt).format('HH:mm:ss YYYY-MM-DD')}</List.Content>
  </List.Item>
  <List.Item >
    <Message style={{ width: "80%", float: 'right' }} content={text} />
  </List.Item>
</React.Fragment>;


class LineChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lineUserList: [],
      chatUserObj: null,
      chatMessageArr: [],
      subscription: null,
      pushValue: ''
    }
    this.mesRef = React.createRef();
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  scrollToBottom = () => {
    // console.log(this.mesRef)
    if (this.mesRef && this.mesRef.scrollIntoView) {
      this.mesRef.scrollIntoView({ behavior: 'smooth' });
    }
  }

  showChatMessage = ({ obj = {}, arr = [] }) => {
    return (<React.Fragment>
      {arr && arr.map((item, index) => item.direction === '1' ? fromMessage(obj, item, index) : replyMessage(obj, item, index))}
      {!arr.length ? "(無歷史訊息)" : null}
    </React.Fragment>);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj } = props
    const arr = await Parse.queryData('lineUsers', { companyId: companyObj.objectId })
    const lineUserList = arr.filter(item => item.lineId)
    // console.log(lineUserList)

    this.setState({ lineUserList })
  }

  handleClickListItem = async (e, { lineid }) => {
    const { lineUserList, currentSubscription } = this.state
    // console.log(e, data);
    const chatUserObj = lineUserList.find(item => item.lineId === lineid);
    // const chatMessageArr = await Parse.queryData('LineMessage', { sourceId: lineId });

    const query = new Parse.Query('LineMessage');
    // const query = new Parse.Query('Post');
    query.equalTo('sourceId', lineid);
    // query.ascending('createdAt').limit(5);
    query.descending('createdAt').limit(10);
    const snap = await query.find();
    const chatMessageArr = snap.map(data => data.toJSON()).reverse();
    this.setState({ chatUserObj, chatMessageArr }, () => this.scrollToBottom())

    // const subscription = client.subscribe(query);
    // subscription.on('create', newData => {
    // });

    // console.log('subscription start')
    if (currentSubscription) {
      // console.log('currentSubscription.unsubscribe')
      await currentSubscription.unsubscribe();
      this.setState({ currentSubscription: null })
    }

    let subscription = await query.subscribe().catch(error => console.error(error));
    // console.log(subscription)

    // subscription.on('open', (data) => {
    //   console.log('object opened', data);
    // });

    // subscription.on('update', (data) => {
    //   console.log('object updated', data);
    // });

    subscription.on('create', (data) => {
      console.log('object created', data);
      const chatMessageArr = [...this.state.chatMessageArr, data.toJSON()];
      this.setState({ chatMessageArr }, () => this.scrollToBottom())
    });
    this.setState({ currentSubscription: subscription })
  }

  handlePush = async () => {
    const { chatUserObj, pushValue } = this.state
    const data = {
      lineid: chatUserObj.lineId,
      type: 'image',
      originalContentUrl: "https://example.com/original.jpg",
      previewImageUrl: "https://example.com/preview.jpg"
    }

    const res = await fetchData(`https://parse.infowin.com.tw/push/${chatUserObj.channelId}`, data)
      .catch((err) => {
        window.alert('錯誤 ' + err);
        console.error(err);
      });

    console.log(res);
    if (res && res.ok) {
      Parse.saveData('LineMessage', { channelId: chatUserObj.channelId, direction: '1', sourceId: chatUserObj.lineId, type: 'text', text: pushValue, });
      // Parse.saveData('LineMessage', { channelId: '1654652629', direction: '2', sourceId: chatUserObj.lineId, type: 'text', text: pushValue, sendId: chatUserObj.lineId, sendLineDisplayName: chatUserObj.lineDisplayName })
      this.setState({ pushValue: '' })
    }
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  render() {
    const { lineUserList, chatUserObj, chatMessageArr, pushValue } = this.state

    // console.log(chatMessageArr)
    return (<div className="content">
      <Grid>
        <Grid.Row>
          <GridColumn width={4} >
            <Segment.Group>
              <Segment>
                <Header as='h5'>好友名單</Header>
              </Segment>
              <Segment style={{ padding: '0px', overflow: 'auto', maxHeight: 750 }} inverted color='grey' >
                <List selection verticalAlign='middle' size={"large"} inverted >
                  <Input placeholder='搜尋...' style={{ width: '100%', padding: '15px' }} />
                  {lineUserList.map(item => nameListItem(item, this.handleClickListItem))}
                  <List.Item style={{ padding: '5px' }} />
                </List>
              </Segment>
            </Segment.Group>
          </GridColumn>
          <GridColumn width={12}>
            {chatUserObj ? <>
              <Segment.Group>
                <Segment>
                  <Header as='h5'>訊息記錄</Header>
                </Segment>
                <Segment>
                  {topListItem(chatUserObj)}
                  <List style={{ backgroundColor: '#F0F0F0', padding: '5px', overflow: 'auto', maxHeight: 500 }}>
                    {this.showChatMessage({ obj: chatUserObj, arr: chatMessageArr })}
                    <div ref={(ref) => this.mesRef = ref} />
                  </List>
                </Segment>
                <Segment>
                  <TextArea
                    name='pushValue'
                    placeholder='輸入訊息...'
                    style={{ width: "100%", minHeight: 100 }}
                    value={pushValue}
                    onChange={this.handleChange}
                  />
                  <Button
                    // className="btn-round btn-icon"
                    color="success"
                    size="md"
                    onClick={() => this.handlePush()}
                  >
                    <i className="nc-icon nc-send" /> 送出
                  </Button>
                </Segment>
              </Segment.Group>
            </> : <>請點選左側名單</>}
          </GridColumn>
        </Grid.Row>
      </Grid>
    </div>);
  }
}

export default LineChat;
