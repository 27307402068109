
import React, { Component } from 'react'
// import { useParams } from "react-router-dom";
import { Header, Form, Grid, Menu, Input, Dimmer, Loader, Image, Button, Checkbox, FormGroup } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import {
  // Container,
  // Table,
  Row,
  Col,
  // Badge,
  // Button,
  // Card,
  // CardBody,
  // CardTitle,
} from "reactstrap";
import { DelayInput } from 'react-delay-input';
// import MaterialTable, { MTableToolbar } from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ModalNewFamily from './ModalNewFamily';
// import RowsDnd from './RowsDnd'
import { DimmerImg } from '../../layouts/Dimmer';
import TablePeople from './TablePeople';
// import fetchUrl from '../../widget/http';

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}
// const url = 'https://restful.infowin.com.tw/api/people2';
let checked = false

const fetchPeople = async (companyObj, params, searchText) => {
  const { page = 1, pageSize = 5 } = params
  const companyId = companyObj.objectId;

  // console.log(companyId,
  //   searchText,
  //   page,
  //   pageSize)
  if (checked) {
    return await Parse.Cloud.run("findPeople", {
      companyId,
      searchText,
      page,
      pageSize,
    });
  } else {
    // console.log(params, searchText)
    let query = new Parse.Query('people');

    if (searchText) {
      const p0 = new Parse.Query("people");
      p0.startsWith("name", searchText);

      const p1 = new Parse.Query("people");
      p1.startsWith("cellphone", searchText);

      const p2 = new Parse.Query("people");
      p2.startsWith("telephone", searchText);

      const p3 = new Parse.Query("people");
      p3.startsWith("addr", searchText);

      query = Parse.Query.or(p0, p1, p2, p3);
    }


    query.equalTo("companyId", companyId);
    query.equalTo("isLive", "1");

    query.select(['name', 'addr', 'cellphone', 'telephone', 'familyId', 'mainPerson', 'sexualId']);
    const totalCount = await query.count();

    // if (params.sorter) {
    //   const s = params.sorter.split('_');

    //   if (s[1] === 'descend') {
    //     query.descending(s[0]);
    //   } else {
    //     query.ascending(s[0]);
    //   }

    //   // Sorts the results in descending order by the score field
    // }
    query.limit(pageSize);
    query.skip((Number(page)) * (pageSize));

    const snap = await query.find();
    const data = snap.map(data => data.toJSON());
    // console.log(data)
    return {
      data,
      page,
      totalCount,
    }
  }
}

class Family extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      familyId: '',
      searchValue: '',
      people: [],
      templeclassSel: {},
      selectData: {},
    }
    this.tableRef = React.createRef();
  }

  async componentDidMount() {
    const { match, companyObj } = this.props;
    const familyId = match.params.familyId || '';
    this.setState({ familyId })

    const companyId = companyObj.objectId;
    let templeclassObj = await Parse.getSelection('templeclass', { companyId }, { name: 'value', sort: 'order' });
    let templelevelObj = await Parse.getSelection('templelevel', { companyId }, { name: 'value', sort: 'order' });
    const praytypeObj = await Parse.getSelection('praytype', { companyId }, { name: 'value', sort: 'order' });
    const pray1Obj = await Parse.getSelection('pray1', { companyId }, { name: 'value', sort: 'order' });
    Parse.fetchDocument('company', companyId).then(companyObjLive => this.setState({ companyObjLive }));
    templeclassObj.sel = { '-1': '請選擇', ...templeclassObj.sel }
    templelevelObj.sel = { '-1': '請選擇', ...templelevelObj.sel }
    const selectData = { templeclassObj, templelevelObj, praytypeObj, pray1Obj }
    this.setState({ selectData }, () => this.handleFetch())
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.match.params || nextProps.match.params.familyId !== this.props.match.params.familyId) {
      const familyId = nextProps.match.params.familyId || '';
      this.setState({ familyId, loading: true }, () => this.handleFetch(nextProps));
    }
    if (typeof nextProps.match.params.familyId === 'undefined') {
      this.setState({ loading: false, familyId: '', people: [] })
    }
  }

  handleFetch = (props = this.props) => {
    const { match } = props;
    const { familyId } = match.params;
    if (familyId) {
      this.handleLoadFamily(familyId);
    } else {
      this.setState({ loading: false });
    }
  }

  handleClickName = (familyId) => {
    const { history } = this.props;
    history.push(`/admin/Family/${familyId}`);
  }

  handleLoadFamily = async (familyId) => {
    if (!familyId) {
      return;
    }
    const { companyObj } = this.props;
    const companyId = companyObj.objectId;
    const pp = await Parse.queryData("people", { companyId, familyId })
    const people = pp.sort((a, b) => ('' + a.name).localeCompare(b.name))
    // console.log(people)
    this.setState({ loading: false, familyId, people })
  }

  handleChange = async (e, { name, value }) => {
    this.setState({ [name]: value.trim() }, () => this.tableRef.current.onQueryChange());
  }

  clear = () => {
    this.ref.inputRef.current.value = '';
    this.setState({ searchValue: '' }, () => this.tableRef.current.onQueryChange())
  }

  handlePeopleAdd = async (newData) => {
    // console.log(newData)
    const { people } = this.state;
    const data = [...people];
    data.push(newData)
    Parse.saveData('people', newData);
    // console.log(data);
    return new Promise((resolve, reject) => this.setState({ people: data }, () => resolve()));
  }

  handlePeopleUpdate = async ({ newData, oldData }) => {
    // const { companyObj } = this.props
    const { people } = this.state

    // const db = firebase.firestore();
    const data = people;
    const index = data.indexOf(oldData);

    let saveAll = 0;
    if (newData.mainPerson === '-1') {
      data.forEach(item => item.mainPerson = '0');
      saveAll = 1;
    }
    data[index] = newData;

    // if (saveAll === 0) {
    //   // const ref = db.collection(`company/${companyObj._id}/family/${newData.familyId}/people`).doc(newData.id)
    //   // await ref.update(newData).catch((e) => console.error(e));
    //   Parse.saveData('people', newData);
    // } else {
    //   // const batch = db.batch()
    //   // data.forEach(item => {
    //   //   const ref = db.collection(`company/${companyObj._id}/family/${item.familyId}/people`).doc(item.id)
    //   //   batch.update(ref, item)
    //   // });
    //   // await batch.commit();
    // }
    Parse.saveData('people', newData);

    return new Promise((resolve, reject) => this.setState({ people: data }, () => resolve()));
  }

  handlePeopleDelete = async (oldData) => {
    const { people } = this.state
    let data = people;
    const index = data.indexOf(oldData);
    data.splice(index, 1);

    Parse.deleteData('people', oldData);
    return new Promise((resolve, reject) => this.setState({ people: data, oldData: {} }, () => resolve()));
  }

  handleFamilysAddrChange = async (obj) => {
    const { companyObj } = this.props;
    const { people } = this.state;
    const people1 = people.filter(item => item.isLive === '1');
    if (!people1.length) { return 0; }
    const companyId = companyObj.objectId;
    const familyId = people[0].familyId;
    Parse.saveall('people', { companyId, familyId, isLive: "1" }, obj)

    const arr = people.map(item => item.isLive === '1' ? { ...item, ...obj } : item);
    this.setState({ people: arr })
    return 1;
  }

  render() {
    const { companyObj } = this.props
    const { familyId, searchValue, loading, people, selectData } = this.state;
    const people1 = people.filter(item => item.isLive === "1");
    const people0 = people.filter(item => item.isLive !== "1");
    return (<>
      <div className="content">
        <div style={{ ...style.flexCenter, margin: '2rem auto' }}>
          <Row>
            <Col xs="8" sm="10">
              <Form>
                <FormGroup widths='equal'>
                  <DelayInput
                    inputRef={ref => { this.ref = ref; }}
                    name='searchValue'
                    placeholder='請輸入信眾姓名、電話、手機'
                    style={{ width: '100%', minWidth: 350, float: 'left' }}
                    onChange={(e) => this.handleChange(e, { name: 'searchValue', value: e.target.value })}
                    // minLength={1}
                    delayTimeout={500}
                    action={{ icon: 'cancel', onClick: this.clear }}
                    element={Input}
                  />
                  <Checkbox label='模糊搜尋' style={{ minWidth: '100px', marginLeft: '50px', paddingTop: '8px' }} onClick={(e, data) => checked = data.checked} />
                  <Button.Group style={{ marginLeft: '15px' }}>
                    <ModalNewFamily  {...this.props} />
                  </Button.Group>
                </FormGroup>
              </Form>
            </Col>
          </Row>
          {/* <div style={{ marginTop: '12px' }}> */}
          {/* <Header as='h4' disabled> {!searchValue ? '請輸入2個字以上 來搜尋信眾' : null}</Header> */}
          {/* <Header as='h4' disabled>  請輸入2個字以上 來搜尋信眾 </Header> */}
          {/* </div> */}
        </div>
        <Row>
          <Col xs="12" sm="10">
            <MaterialTable
              localization={localization()}
              tableRef={this.tableRef}
              title="搜尋結果，點擊載入全家名單"
              options={{
                search: false,
              }}
              columns={[
                { width: 50, title: '戶長', field: 'mainPerson', lookup: { '0': '否', '-1': '是' }, },
                { width: 80, title: '姓名', field: 'name' },
                { width: 50, title: '姓別', field: 'sexualId', lookup: { '1': '男', '2': '女' } },
                { width: 250, title: '地址', field: 'addr', cellStyle: { width: '250px' }, headerStyle: { width: '250px' } },
                { width: 80, title: '手機', field: 'cellphone' },
                { width: 80, title: '電話', field: 'telephone' },
              ]}
              data={query =>
                new Promise((resolve, reject) => {
                  fetchPeople(companyObj, query, searchValue.trim()).then(result => resolve(result))
                })
              }
              onRowClick={(e, rowData) => this.handleClickName(rowData.familyId)}
              actions={[
                {
                  icon: 'group', // view_headline
                  tooltip: '顯示家庭',
                  onClick: (event, rowData) => this.handleClickName(rowData.familyId)
                },
              ]}
            />
          </Col>
        </Row>
        <br />
        {!loading && people.length ? <>
          <TablePeople {...this.props}
            familyId={familyId}
            people1={people1}
            people0={people0}
            selectData={selectData}
            handlePeopleAdd={this.handlePeopleAdd}
            handlePeopleUpdate={this.handlePeopleUpdate}
            handlePeopleDelete={this.handlePeopleDelete}
            onRowDataChange={this.handleFamilysAddrChange}
            handleFamilyChange={this.handleChange}
          />
        </> : null}
        {familyId && loading && <div style={{ color: 'grey' }}> <DimmerImg /></div>}
      </div>
    </>);
  }
}

export default Family;
