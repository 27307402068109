
import React, { Component } from 'react'
import {
  Grid,
  //  Container, Header, Form, Segment, Image
} from 'semantic-ui-react';
import MaterialTable
  // , { MTableToolbar } 
  from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

// import RowsDnd from './RowsDnd'

// reactstrap components
// import {
//   Card,
//   CardHeader,
//   CardBody,
//   CardTitle,
//   Table,
//   Row,
//   Col
// } from "reactstrap";

const columns = () => ([
  // { title: '預設', field: 'now_use' },
  { title: '順序', field: 'orderId' },
  { title: '項目', field: 'value' },
]);
const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

class Account extends Component {
  state = {
    account1: [],
    account2: [],
    account3: [],
    data1: [{ value: 1234, id: 156 }],
    data2: [{ value: 5512, id: 123 }],
    data3: [{ value: 1234, id: 1267 }],
    sel1: '',
    sel2: '',
    sel3: '',
    title1: '',
    title2: '',
    title3: '',
    loading: true,
    obj: {},
    rowData: {}
  }
  async componentDidMount() {
    const { companyObj } = this.props
    const data1 = await Parse.queryData('account', { companyId: companyObj.objectId })

    this.setState({ data1, loading: false })
  }
  render() {
    // const { firebase = {}, companyObj = {} } = this.props
    const {
      // dataArr, deleteConfirm, columnDefs, rowData, account1, account2, account3,
      data1, data2, data3, title1, title2, title3, sel1, sel2, sel3, obj, loading } = this.state;
    return (
      <>
        <div className="content">
          <div style={{ ...style.flexCenter, position: 'relative', zIndex: 100 }}>
            {/* <Header as='h2' style={{ margin: '2rem 0' }}>會計</Header> */}
          </div>
          <br />
          <Grid>
            <Grid.Row>
              <Grid.Column computer={5} tablet={16} mobile={16}>
                <MaterialTable
                  localization={localization()}
                  columns={columns()}
                  options={{
                    addRowPosition: "first",
                    exportButton: true,
                    exportPdf: (columns, data) => exportPdf(columns, data, '會計分類1'),
                    pageSize: 5,
                    search: false
                  }}
                  data={data1}
                  title="會計分類1"
                  actions={[
                    {
                      icon: 'view_headline',
                      tooltip: '查看',
                      onClick: (event, rowData) => this.handleData("1", rowData)
                    }
                  ]}
                  onRowClick={(event, rowData) => this.handleData(rowData)}
                // editable={{
                //   onRowAdd: newData1 =>
                //     new Promise((resolve, reject) => {
                //       // console.log(data)
                //       const db = firebase.firestore();
                //       const docRef = db.collection('account1').doc();
                //       const data = [...data1];

                //       const obj = {
                //         _id: docRef.id,
                //         id: docRef.id,
                //         templeId: systemObj.id,
                //         // product1_id: sel1,
                //         // product1_text: (account1.find(item => item._id === sel1) && account1.find(item => item._id === sel1).value) || '',
                //         ...newData1
                //       }
                //       data.push(obj);

                //       this.setState({ data1: data, newData1: obj }, () => resolve());
                //     }).then(() => this.handleAdd("1")),
                //   onRowUpdate: (newData1, oldData1) =>
                //     new Promise((resolve, reject) => {
                //       const data = [...data1];
                //       const index = data.indexOf(oldData1);
                //       data[index] = newData1;
                //       // console.log(data1)
                //       this.setState({ data1: data, newData1, oldData1 }, () => resolve());
                //     }).then(() => this.handleUpdate("1")),
                //   onRowDelete: oldData1 =>
                //     new Promise((resolve, reject) => {
                //       let data = [...data1];
                //       const index = data.indexOf(oldData1);
                //       data.splice(index, 1);
                //       this.setState({ data1: data, oldData1 }, () => resolve());
                //     }).then(() => this.handleDelete("1")),
                // }}
                />
              </Grid.Column>
              <Grid.Column computer={5} tablet={16} mobile={16}>
                {sel1 ?
                  <MaterialTable
                    localization={localization()}
                    columns={columns()}
                    options={{
                      addRowPosition: "first",
                      exportButton: true,
                      exportPdf: (columns, data) => exportPdf(columns, data, "會計分類2 " + title1),
                      pageSize: 5,
                      search: false
                    }}
                    // onColumnDragged={}
                    data={data2}
                    title={"會計分類2 " + title1}
                    actions={[
                      {
                        icon: 'view_headline',
                        tooltip: '查看',
                        onClick: (event, rowData) => this.handleData("2", rowData)
                      }
                    ]}
                    onRowClick={(event, rowData) => this.handleData("2", rowData)}
                  // editable={{
                  //   onRowAdd: newData2 =>
                  //     new Promise((resolve, reject) => {
                  //       const db = firebase.firestore();
                  //       const docRef = db.collection('account1/').doc();
                  //       const data = [...data2];

                  //       const obj = {
                  //         _id: docRef.id,
                  //         id: docRef.id,
                  //         templeId: systemObj.id,
                  //         account1Id: sel1,
                  //         account1Text: (account1.find(item => item._id === sel1) && account1.find(item => item._id === sel1).value) || '',
                  //         ...newData2
                  //       }
                  //       data.push(obj);

                  //       this.setState({ data2: data, newData2: obj }, () => resolve());
                  //     }).then(() => this.handleAdd("2")),
                  //   onRowUpdate: (newData2, oldData2) =>
                  //     new Promise((resolve, reject) => {
                  //       const data = [...data2];
                  //       const index = data.indexOf(oldData2);
                  //       data[index] = newData2;
                  //       // console.log(data1)
                  //       this.setState({ data2: data, newData2, oldData2 }, () => resolve());
                  //     }).then(() => this.handleUpdate("2")),
                  //   onRowDelete: oldData2 =>
                  //     new Promise((resolve, reject) => {
                  //       let data = [...data2];
                  //       const index = data.indexOf(oldData2);
                  //       data.splice(index, 1);
                  //       this.setState({ data2: data, oldData2 }, () => resolve());
                  //     }).then(() => this.handleDelete("2")),
                  // }}
                  /> : null}
              </Grid.Column>
              <Grid.Column computer={5} tablet={16} mobile={16}>
                {sel2 ? <MaterialTable
                  localization={localization()}
                  columns={columns()}
                  options={{
                    addRowPosition: "first",
                    exportButton: true,
                    exportPdf: (columns, data) => exportPdf(columns, data, "會計分類3 " + title2),
                    pageSize: 5,
                    search: false
                  }}
                  data={data3}
                  title={"會計分類3 " + title2}
                // editable={{
                //   onRowAdd: newData3 =>
                //     new Promise((resolve, reject) => {
                //       const db = firebase.firestore();
                //       const docRef = db.collection('account1').doc();
                //       const data = [...data3];

                //       const obj = {
                //         _id: docRef.id,
                //         id: docRef.id,
                //         templeId: systemObj.id,
                //         account1Id: sel1,
                //         account1Text: (account1.find(item => item._id === sel1) && account1.find(item => item._id === sel1).value) || '',
                //         account2Id: sel2,
                //         account2Text: (account2.find(item => item._id === sel2) && account2.find(item => item._id === sel2).value) || '',
                //         ...newData3
                //       }
                //       data.push(obj);
                //       this.setState({ data3: data, newData3: obj }, () => resolve());
                //     }).then(() => this.handleAdd("3")),

                //   onRowUpdate: (newData3, oldData3) =>
                //     new Promise((resolve, reject) => {
                //       const data = [...data3];
                //       const index = data.indexOf(oldData3);
                //       data[index] = newData3;
                //       // console.log(data1)
                //       this.setState({ data3: data, newData3, oldData3 }, () => resolve());
                //     }).then(() => this.handleUpdate("3")),
                //   onRowDelete: oldData3 =>
                //     new Promise((resolve, reject) => {
                //       let data = [...data3];
                //       const index = data.indexOf(oldData3);
                //       data.splice(index, 1);
                //       this.setState({ data3: data, oldData3 }, () => resolve());
                //     }).then(() => this.handleDelete("3")),
                // }}
                /> : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <br />
          <br />
        </div>
      </>
    );
  }
}

export default Account;
