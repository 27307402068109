import React, { Component } from 'react';
import { Icon, Input, Label, Form, Dimmer, Loader, Image, Segment, Table, Button } from 'semantic-ui-react'
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';
import { getSemesterNum } from '../../../widget/define/calendar';

import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import { semesterArr } from '../../../widget/define/define'
import moment from 'moment';
import Parse from '../../../widget/parse'

const style = {
  tableShow: {
    display: 'table',
  },
  tableHide: {
    display: 'none',
  }
}
const months = [['4', '5', '6', '7', '8', '9', '合計'], ['10', '11', '12', '1', '2', '3', '合計']]

export default class AttendanceReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrRow: [],
      loading: true,
      yearsemester: `${Number(props.semesterYear) - 1},${props.semester}`,
      year: Number(props.semesterYear) - 1 + '',
      semes: props.semester,
      monthSel: 'A',
      monthlyData: [],
    };
  }

  async componentDidMount() {
    // console.log('componentDidMount');
    const { companyObj } = this.props
    const data = await Parse.queryData('Students', { companyId: companyObj.objectId });
    this.setState({ students: data }, () => this.handleFetch());
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    if (nextProps.students !== this.props.students)
      this.handleFetch(nextProps);
  }
  // download_csv(csv, filename) {
  //   var csvFile;
  //   var downloadLink;

  //   // CSV FILE
  //   csvFile = new Blob([csv], { type: "text/csv" });

  //   // Download link
  //   downloadLink = document.createElement("a");

  //   // File name
  //   downloadLink.download = filename;

  //   // We have to create a link to the file
  //   downloadLink.href = window.URL.createObjectURL(csvFile);

  //   // Make sure that the link is not displayed
  //   downloadLink.style.display = "none";

  //   // Add the link to your DOM
  //   document.body.appendChild(downloadLink);

  //   // Lanzamos
  //   downloadLink.click();
  // }

  // export_table_to_csv(html, filename) {
  //   var csv = [];
  //   var rows = document.querySelectorAll("table tr");

  //   for (var i = 0; i < rows.length; i++) {
  //     var row = [], cols = rows[i].querySelectorAll("td, th");

  //     for (var j = 0; j < cols.length; j++)
  //       row.push(cols[j].innerText);

  //     csv.push(row.join(","));
  //   }

  //   // Download CSV
  //   this.download_csv(csv.join("\n"), filename);
  // }

  handleFetch = (props = this.props) => {
    const { companyObj, semesterYear } = props
    const { year, semes, monthSel, students = [] } = this.state
    const tmp = students.filter(item =>
      (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
      (item.applyResult === '1' || item.studentType === "2"));
    const arrRow = tmp.filter(item => item.semesterYear === year && item.semester === semes); // 找到這班的所有人
    this.setState({ arrRow, loading: 1 });

    // const db = firebase.firestore();
    const promises = [];
    let allRealDay = 0
    let oneExpectedDay = 0
    let semesDay = monthSel === 'S' ? `${semesterYear}-05-01` : `${semesterYear}-02-01`
    arrRow.forEach(doc => {
      let weeklyExpectedDay = 0
      let weeklyExpectedHours = 0
      let weeklyRealDay = 0
      let weeklyRealHours = 0
      const semesterNum = getSemesterNum(doc.semesterYear, doc.semester, semesDay)
      const promise = Parse.queryData('Monthly', { companyId: companyObj.objectId, semesterNum, studentId: doc.objectId }).then(snap2 => {
        const data = [];
        snap2.forEach(monthly => {
          weeklyExpectedDay += Number(monthly.weeklyExpectedDay)
          weeklyExpectedHours += Number(monthly.weeklyExpectedHours)
          weeklyRealDay += Number(monthly.weeklyRealDay)
          weeklyRealHours += Number(monthly.weeklyRealHours)
          data.push({ objectId: monthly.objectId, ...monthly })
        })
        const daysRate = Number(parseFloat(weeklyRealDay / weeklyExpectedDay * 100).toFixed(1))
        const hoursRate = Number(parseFloat(weeklyRealHours / weeklyExpectedHours * 100).toFixed(1))
        data.push({ weeklyExpectedDay, weeklyExpectedHours, weeklyRealDay, weeklyRealHours, daysRate, hoursRate })
        allRealDay += weeklyRealDay
        oneExpectedDay = weeklyExpectedDay
        return data;
      })
      promises.push(promise);
      // var promise = db.collection(`Students/${doc._id}/Monthly`)
      //   .where('semesterNum', '==', semesterNum)
      //   .get().then(snap2 => {
      //     const data = [];
      //     snap2.forEach(monthly => {
      //       weeklyExpectedDay += Number(monthly.data().weeklyExpectedDay)
      //       weeklyExpectedHours += Number(monthly.data().weeklyExpectedHours)
      //       weeklyRealDay += Number(monthly.data().weeklyRealDay)
      //       weeklyRealHours += Number(monthly.data().weeklyRealHours)
      //       data.push({ _id: monthly.id, ...monthly.data() })
      //     })
      //     const daysRate = Number(parseFloat(weeklyRealDay / weeklyExpectedDay * 100).toFixed(1))
      //     const hoursRate = Number(parseFloat(weeklyRealHours / weeklyExpectedHours * 100).toFixed(1))
      //     data.push({ weeklyExpectedDay, weeklyExpectedHours, weeklyRealDay, weeklyRealHours, daysRate, hoursRate })
      //     allRealDay += weeklyRealDay
      //     oneExpectedDay = weeklyExpectedDay
      //     return data;
      //   })
      // promises.push(promise);
    });

    Promise.all(promises).then(results => {
      // console.log(results);
      const monthlyData = results.filter(item => item.length);
      // console.log(monthlyData)
      this.setState({ monthlyData, loading: 0 })
      this.setState({ allRealDay, oneExpectedDay, totalExpectedDay: oneExpectedDay * arrRow.length })
    }).catch(error => {
      console.log(error);
    });
  }

  handleChange = (event, { name, value }) => {
    if (name === 'yearsemester') {
      const year = value.substring(0, 4);
      const semes = value.substring(5);
      this.setState({ [name]: value, year, semes }, () => this.handleFetch());
    } else {
      this.setState({ [name]: value }, () => this.handleFetch());
    }
  }
  render() {
    const { semesterYear, companyObj } = this.props;
    const { allRealDay, oneExpectedDay, totalExpectedDay, yearsemester, year, semes, monthSel, arrRow, loading, monthlyData } = this.state
    const monthsSelArr = [{ key: 'S', text: '4月～9月', value: 'S' }, { key: 'A', text: '10月～3月', value: 'A' }]
    var pattern2 = new RegExp("[A-Za-z]+")
    return (<div ref={el => (this.componentRef = el)} className="">
      <h2 style={{ margin: 0, float: 'left' }}>
        {year}年{semes === 'S' ? '4' : '10'}月生 学生出席状況報告書（期間：{monthSel === 'S' ? `${semesterYear}年4月～${semesterYear}年9月` : `${Number(semesterYear) - 1}年10月～${semesterYear}年3月`}）
        </h2>
      <div style={{ float: 'right' }}>
        {/* <ModalPdf
          {...this.props}
          // ref={(ref) => { this.pdfModal = ref }}
          codekey="table18"
          params={params}
        /> */}
        {/* <Button content='123' onClick={() => this.export_table_to_csv(document.getElementById('excel'), "table.csv")} /> */}
        <ExportTableToExcelButton floated='right' size='small' />&nbsp;
        <Input labelPosition='left'>
          <Label color='violet'><Icon name='calendar' />印刷期間</Label>
          <Form.Select style={{
            maxWidth: '200px',
          }} compact
            fluid
            options={monthsSelArr}
            onChange={this.handleChange}
            // disabled={isEdit}
            value={monthSel}
            name='monthSel' />
        </Input>&nbsp;&nbsp;
        <Input labelPosition='left'>
          <Label color='teal'><Icon name='building' />期生</Label>
          <Form.Select style={{
            maxWidth: '200px',
          }} compact
            fluid
            options={semesterArr}
            onChange={this.handleChange}
            // disabled={isEdit}
            value={yearsemester}
            name='yearsemester' />
        </Input>
      </div>
      <Table striped celled className='thPadding2px tdPadding2px'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>合計6か月授業日</Table.HeaderCell>
            <Table.HeaderCell>学生合計6か月授業日</Table.HeaderCell>
            <Table.HeaderCell>学生合計總出席日</Table.HeaderCell>
            <Table.HeaderCell>学生合計出席率</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.HeaderCell>{oneExpectedDay}</Table.HeaderCell>
            <Table.HeaderCell>{totalExpectedDay}</Table.HeaderCell>
            <Table.HeaderCell>{allRealDay}</Table.HeaderCell>
            <Table.HeaderCell>{Number(parseFloat(allRealDay / totalExpectedDay * 100).toFixed(1)) + '%'}</Table.HeaderCell>
          </Table.Row>
        </Table.Body>
      </Table>
      <div style={{ clear: 'both' }}></div>
      {!loading ? <Table celled compact
        className="thPadding2px tdPadding2px" id='excel'>
        <Table.Header >
          <Table.Row textAlign='center'>
            <Table.HeaderCell>NO.</Table.HeaderCell>
            <Table.HeaderCell>名前</Table.HeaderCell>
            <Table.HeaderCell colSpan='24'>月別出席率</Table.HeaderCell>
            <Table.HeaderCell colSpan='4'>6か月合計</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {arrRow.length ? arrRow.map((item, index) => {
            const monthly = monthlyData[index]
            // console.log(monthly)
            let monData = []
            return <React.Fragment key={index}>
              <Table.Row textAlign='center'>
                < Table.Cell rowSpan='3'>{index + 1}</Table.Cell>
                < Table.Cell rowSpan='3' width='2'>{item.jpnName}<br />{pattern2.test(item.jpnName) ? null : item.engName}<br />{item.nationalityText}   {item.genderText}   {moment(new Date(item.birthday)).format('YYYY/MM/DD')}</Table.Cell>
                {months[monthSel === 'S' ? 0 : 1].map((month, index2) => {
                  if (monthly.find(item => item.month === month))
                    monData.push(monthly.find(item => item.month === month))
                  else if (index2 === 6)
                    monData.push(monthly[monthly.length - 1])
                  else
                    monData.push({})
                  // console.log(monData)
                  return <React.Fragment key={index2}>
                    <Table.Cell>{index2 === 6 ? '合計' : month + '月'}</Table.Cell>
                    <Table.Cell>{'授業'}</Table.Cell>
                    <Table.Cell>{'出席'}</Table.Cell>
                    <Table.Cell>{'出席率'}</Table.Cell>
                  </React.Fragment>
                })}
              </Table.Row>
              <Table.Row textAlign='center'>
                {monthly && months[monthSel === 'S' ? 0 : 1].map((month, index2) => {
                  const monthAtt = monData[index2]
                  return <React.Fragment key={index2}>
                    <Table.Cell>{'日数'}</Table.Cell>
                    <Table.Cell>{monthAtt ? monthAtt.weeklyExpectedDay : ''}</Table.Cell>
                    <Table.Cell>{monthAtt ? monthAtt.weeklyRealDay : ''}</Table.Cell>
                    <Table.Cell>{monthAtt && monthAtt.daysRate ? (monthAtt.daysRate + '%') : ''}</Table.Cell>
                  </React.Fragment>
                })}
              </Table.Row>
              <Table.Row textAlign='center'>
                {monthly && months[monthSel === 'S' ? 0 : 1].map((month, index2) => {
                  const monthAtt = monData[index2]
                  return <React.Fragment key={index2}>
                    <Table.Cell>{'時間数'}</Table.Cell>
                    <Table.Cell>{monthAtt ? monthAtt.weeklyExpectedHours : ''}</Table.Cell>
                    <Table.Cell>{monthAtt ? monthAtt.weeklyRealHours : ''}</Table.Cell>
                    <Table.Cell>{monthAtt && monthAtt.daysRate ? (monthAtt.hoursRate + '%') : ''}</Table.Cell>
                  </React.Fragment>
                })}
              </Table.Row>
            </React.Fragment>
          }) : <Table.Row><Table.Cell rowSpan='3' colSpan='15' textAlign='center'>(資料無し)</Table.Cell></Table.Row>}
        </Table.Body>
      </Table>
        : <div style={{ justifyContent: 'center', margin: '30px 0' }}>
          <Segment>
            <Dimmer active inverted={companyObj.inverted}>
              <Loader inverted={companyObj.inverted}>Loading</Loader>
            </Dimmer>
            <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
          </Segment>
        </div>}
    </div>);
  }
}