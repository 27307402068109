import React, { Component } from 'react'
import { Container, Header, Modal, Button, Dimmer, Loader, Image, Form, Select } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Moment from 'moment';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateTimeCol, exportPdf } from 'views/web/CustomMaterialTable';



const statusSel = [
  { key: 'statusAll', value: '-1', text: '所有' },
  { key: 'status0', value: '0', text: '已拒絕' },
  { key: 'status1', value: '1', text: '填寫中' },
  { key: 'status2', value: '2', text: '代理人覆核中' },
  { key: 'status3', value: '3', text: '主管簽核中' },
  { key: 'status4', value: '4', text: '人資簽核中' },
  { key: 'status5', value: '5', text: '已通過' },
  { key: 'status6', value: '6', text: '已取消' },
]

const leaveSel = [
  { key: 'leaveAll', value: '0', text: '所有' },
  { key: 'leave1', value: '1', text: '公假' },
  { key: 'leave2', value: '2', text: '公傷病假' },
  { key: 'leave3', value: '3', text: '事假' },
  { key: 'leave4', value: '4', text: '病假' },
  { key: 'leave5', value: '5', text: '婚假' },
  { key: 'leave6', value: '6', text: '傷假' },
  { key: 'leave7', value: '7', text: '產假' },
  { key: 'leave8', value: '8', text: '生理假' },
  { key: 'leave9', value: '9', text: '陪產假' },
  { key: 'leave10', value: '10', text: '特休假' },
  { key: 'leave11', value: '11', text: '育嬰假' },
  { key: 'leave12', value: '12', text: '補休假' },
  { key: 'leave13', value: '13', text: '加班申請' },
  { key: 'leave14', value: '14', text: '補修申請' },
]

const objDay_offSel = {
  "1": "公假", "2": "公傷病假", "3": "事假", "4": "病假",
  "5": "婚假", "6": "傷假", "7": "產假", "8": "生理假", "9": "陪產假", "10": "特休假",
  "11": "育嬰假", "12": "補休假", "13": "加班申請", "14": "補休申請"
};

const objDay_off_HoursSel = {
  "0": "0", "1": "1", "2": "2", "3": "3", "4": "4",
  "5": "5", "6": "6", "7": "7",
};

const objDay_off_status_first = { '0': '已拒絕', "1": "填寫中", "2": "代理人覆核", "3": '主管覆核', "4": '人資覆核', '5': '已通過', '6': '總經理覆核' };

const columns = (usersAllSel) => ([
  // { title: '類別 ', field: 'type_id', lookup: { '1': 'Pru Life', '2': 'VOYA', '3': 'Colonial' } },
  { title: '狀態', field: 'dayoff_status', lookup: objDay_off_status_first },
  { title: '申請人姓名', field: 'employeeText' },
  { title: '請假類別', field: 'day_off_class', lookup: objDay_offSel },
  // { title: '開始時間', field: 'eventStartAt', type: 'datetime', render: rowData => Moment(rowData.eventStartAt).format('YYYY-MM-DD HH:mm:ss') },
  // { title: '結束時間', field: 'eventEndAt', type: 'datetime', render: rowData => Moment(rowData.eventEndAt).format('YYYY-MM-DD HH:mm:ss') },
  dateTimeCol({ title: '開始時間', field: 'eventStartAt', width: 120 }),
  dateTimeCol({ title: '結束時間', field: 'eventEndAt', width: 120 }),
  // { title: '請假天數', field: 'total_hours1', type: 'numeric', render: rowData => `${rowData.total_hours1}天` },
  { title: '請假時數', field: 'total_hours', render: rowData => `${rowData.total_hours}時` },
  { title: '事由', field: 'reason' },
  { title: '審核回覆', field: 'ps' },
  { title: '職務代理人', field: 'substituteText' },
  { title: '主管', field: 'supervisorText' },
  { title: '總經理', field: 'generalmanagerText' },
  { title: '人資', field: 'hrText' },
]);

export default class Hr5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginUser: this.props.user.profile.toJSON(),
      dayoffArr: [],
      dayoffArrOri: [],
      typeId: '0',
      statusId: '-1',
      leaveId: '0',
      loading: true,
      deleteConfirm: false,
      usersSel: [],
      usersId: '0',
      auth_substitutegoSel: {},
      auth_supervisorgoSel: {},
      auth_hrgoSel: {},
      auth_generalmanagergoSel: {},
      usersAllSel: {},
      userId: '0',
      userSel: [],
      comptimeArr: [],
      comptimeArrOri: [],
      yearAll: [],
      yearId: ''
    }
    this.tableRef = React.createRef();
  }

  async componentDidMount() {
    const { companyObj } = this.props
    const { loginUser } = this.state
    const companyId = companyObj.objectId
    const authPage = window.location.pathname.split('/')[2]
    const authUser = companyId + '_' + authPage
    let auth = {}
    if ((loginUser.authAdminA && loginUser.authAdminA.indexOf(companyId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
      auth = {
        edit: true,
      }
    } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
      auth = {
        edit: false,
      }
    } else {
      auth = {
        edit: false,
      }
    }
    // const { bg } = 
    // const bgSel = bg.filter(item => item.value).map(item => ({
    //   key: item._id, text: `${item.value}`, value: item._id
    // }));
    const year = await Parse.queryData('mgyear', { companyId })
    // snap2.forEach(item => year.push({ _id: item.id, ...item.data() }));
    const yearAll = year.map(item => ({ key: item.objectId, value: item.value_AD, text: `${item.value_AD}` }))
    let now = String(new Date().getFullYear())
    const yearId = yearAll.find(item => item.value === now) && yearAll.find(item => item.value === now).value
    const users = await Parse.queryData('User', { authUserA: companyObj.objectId })
    let usersAllSel = {
      '9PyvxCkuww': '莊隆霖',
      'gq4vBuXvn5': '盧琟蓁',
      'ao4wgmJnNk': '李采亭',
      'eJYTQxLo4H': '楊仕豪',
      'zAfFJD62Yo': '陳怡帆',
      'XHpE9ouyw6': '鄧智陽',
      'sL2RLa7i2y': '林濬萱',
      'KQNft7RR4u': '秦國芳',
      'S35ofJfB3b': '鍾嘉莉',
      'Qq6om8JEYZ': '江岱瑾',
      'b4z6cmGTSi': '方偉丞',
      'iHfBDNnbCv': '張語樵',
      'none': '無',
    }
    const usersSel = users.map(item => {
      usersAllSel = { ...usersAllSel, [item.objectId]: item.name }
      return ({ key: item.objectId, text: `${item.name || '未輸入姓名'}`, value: item.objectId })
    });
    usersSel.unshift({ key: '0', text: `所有`, value: '0' })
    this.setState({ usersSel, users, auth, yearAll, yearId, usersAllSel }, () => this.handleFetch())
    // this.setState({ bgSel: bg && bg.length && bg[0]._id }, () => this.handleFetch());
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj } = props;
    const { yearId, statusId, userId, leaveId, usersAllSel } = this.state
    const companyId = companyObj.objectId;
    const dayoff = await Parse.queryData('dayoff', { companyId, year: yearId })
    const comptime = await Parse.queryData('comptime', { companyId, year: yearId })
    const data = dayoff.concat(comptime)
    const printData = data.map(item => ({ ...item, dayoff_status: objDay_off_status_first[item.dayoff_status], day_off_class: objDay_offSel[item.day_off_class], substituteText: usersAllSel[item.substitute] || '', supervisorText: usersAllSel[item.supervisor] || '', generalmanagerText: usersAllSel[item.generalmanager] || '', hrText: usersAllSel[item.hr] || '' }))
    const dayoffArrOri = data.sort((a, b) => new Date(b.eventStartAt.iso) - new Date(a.eventStartAt.iso))
    const data2 = data.map(item => ({ ...item, substituteText: usersAllSel[item.substitute] || '', supervisorText: usersAllSel[item.supervisor] || '', generalmanagerText: usersAllSel[item.generalmanager] || '', hrText: usersAllSel[item.hr] || '', eventStartAt: (item.eventStartAt && (item.eventStartAt.iso || item.eventStartAt) && Moment(item.eventStartAt.iso || item.eventStartAt).format('YYYY-MM-DD hh:mm:ss')), eventEndAt: (item.eventEndAt && (item.eventEndAt.iso || item.eventEndAt) && Moment(item.eventEndAt.iso || item.eventEndAt).format('YYYY-MM-DD hh:mm:ss')) }))
    const dayoffArr = data2.filter(item => (statusId === item.dayoff_status || statusId === '-1') && (userId === item.employeeId || userId === '0') && (leaveId === item.day_off_class || leaveId === '0'))

    this.setState({ dayoffArr, dayoffArrOri, printData, loading: false })
  }

  handleDelete = async () => {
    const { companyObj } = this.props
    const { oldData } = this.state
    if (oldData.flag === 1) {
      await Parse.deleteData('dayoff', oldData);
    } else if (oldData.flag === 2) {
      await Parse.deleteData('comptime', oldData);
    }
    this.setState({ oldData: {} })
  }
  // if (name === 'statusId') {
  //   statusId = value
  //   const dayoffArr = data.filter(item => (statusId === item.dayoff_status || statusId === '-1') && (userId === item.employeeId || userId === '0') && (leaveId === item.day_off_class || leaveId === '0'))
  //   this.setState({ dayoffArr, [name]: value })
  // } else if (name === 'userId') {
  //   userId = value
  //   const dayoffArr = data.filter(item => (statusId === item.dayoff_status || statusId === '-1') && (userId === item.employeeId || userId === '0') && (leaveId === item.day_off_class || leaveId === '0'))
  //   this.setState({ dayoffArr, [name]: value })
  // } else if (name === 'leaveId') {
  //   leaveId = value
  //   const dayoffArr = data.filter(item => (statusId === item.dayoff_status || statusId === '-1') && (userId === item.employeeId || userId === '0') && (leaveId === item.day_off_class || leaveId === '0'))
  //   this.setState({ dayoffArr, [name]: value })
  // } else if (name === 'yearId') {
  //   yearId = value
  //   this.setState({ [name]: value }, () => this.handleFetch())
  // }
  handleChange = (e, { name, value }) => {
    const { dayoffArrOri } = this.state
    let { comptimeArrOri, statusId, userId, leaveId, yearId } = this.state

    if (name === 'yearId') {
      yearId = value
      this.setState({ [name]: value }, () => this.handleFetch())
    } else {
      if (name === 'statusId') {
        statusId = value
      } else if (name === 'userId') {
        userId = value
      } else if (name === 'leaveId') {
        leaveId = value
      }
      const dayoffArr = dayoffArrOri.filter(item => (statusId === item.dayoff_status || statusId === '-1') && (userId === item.employeeId || userId === '0') && (leaveId === item.day_off_class || leaveId === '0'))
      this.setState({ dayoffArr, [name]: value })
    }

  }
  render() {
    const { companyObj } = this.props
    const { dayoffArr, deleteConfirm, loading, statusId, leaveId, usersSel, userId, printData, usersAllSel, auth = {}, dayoffArrOri, yearAll, yearId } = this.state;

    return (<>
      <div className="content">
        <Container>
          {/* <div style={{ ...style.flexCenter, position: 'relative', zIndex: 100 }}> */}
          {/* <Header as='h2' style={{ color: '#902d8c', margin: '2rem 0' }}>Hanbury IFA</Header> */}
          {/* <Header as='h2' style={{ margin: '2rem 0', marginLeft: "20px" }}>4-4 差勤總覽</Header> */}
          {/* <Form.Select options={typeOpt} onChange={this.handleChange} name='typeId' value={typeId} /> */}
          {/* </div> */}
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center', position: 'relative', zIndex: 100
          }}>
            {/* <Button content="下載" /> */}
            <Form inverted={companyObj.inverted}>
              <Form.Group inline>
                <Form.Select label='年度' options={yearAll} name='yearId' onChange={this.handleChange} value={yearId} />
                <Form.Select label='狀態' options={statusSel} name='statusId' onChange={this.handleChange} value={statusId} />
                <Form.Select label='申請人姓名' options={usersSel} name='userId' onChange={this.handleChange} value={userId} />
                <Form.Select label='假別' options={leaveSel} name='leaveId' onChange={this.handleChange} value={leaveId} />
              </Form.Group>
              {/* <Select options={bgSel} name='bgId' onChange={this.handleChange} value={bgId} /> */}
            </Form>
          </div>
        </Container>
        <br />
        <MaterialTable
          isLoading={loading}
          localization={localization()}
          tableRef={this.tableRef}
          columns={columns(usersAllSel)}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportAllData: true,
            exportPdf: (columns, data) => exportPdf(columns, printData, '請假總覽', 'dateTime'),
            pageSize: 10,
            search: false,
            // showFirstLastPageButtons: true
          }}
          data={dayoffArr}
          title="請假總覽"
          editable={{
            isDeleteHidden: () => !auth.edit,
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                let data = [...dayoffArr];
                let data2 = [...dayoffArrOri];
                const index = data.indexOf(oldData);
                const index2 = data2.indexOf(oldData);
                data.splice(index, 1);
                data2.splice(index2, 1);
                // handleState('people0', data);
                this.setState({ dayoffArr: data, oldData, dayoffArrOri: data2 }, () => resolve());
              }).then(() => this.handleDelete(0)),
          }}
        />
        <br />
        <br />
        <Modal
          open={deleteConfirm}
          size='tiny'
          centered={false}
          header='刪除公告'
          content='確定要刪除這則公告嗎？'
          actions={[{ key: 'canceldeletebtn', content: '取消', onClick: () => this.setState({ deleteConfirm: false }) }, { key: 'confirmdeletebtn', content: '刪除', color: 'red', onClick: this.handleDelete }]}
        />
      </div>
    </>)
  }
}