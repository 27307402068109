import React, { Component } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import { showField } from 'views/widgets/FieldsRender';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, CardHeader, CardLink
} from "reactstrap";

export default class Dorm2Student extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // dormitorys: []
    };
  }

  // componentDidMount() {
  //   // console.log('componentDidMount');
  //   this.handleFetch();
  // }

  // componentWillReceiveProps(nextProps) {
  //   // console.log('componentWillReceiveProps');
  //   this.handleFetch(nextProps);
  // }

  // handleFetch = (props = this.props) => {
  //   const { firebase } = this.props
  //   firebase.firestore().collection("Dormitorys")
  //     .get().then(snap => {
  //       const data = [];
  //       snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }));
  //       // console.log(data)
  //       this.setState({ dormitorys: data });
  //     });
  // }

  render() {
    // const { semesterYear, semester } = this.props;

    return (
      <>
        <Header as='h2' dividing
          style={{ backgroundColor: 'lavender' }}
          content='寮管理'
        // subheader='調査時間：2017年4月から 2018年12月 まで'
        />
        {/* <Grid.Row>
          <Grid columns='equal'>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Grid.Row>
                    <Grid columns='equal'>
                      <Grid.Column>{showField(this.props, "hopeDorm")}</Grid.Column>
                    </Grid>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid columns='equal'>
                      <Grid.Column>{showField(this.props, "hope1Dorm")}</Grid.Column>
                      <Grid.Column>{showField(this.props, "type1Dorm")}</Grid.Column>
                    </Grid>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid columns='equal'>
                      <Grid.Column>{showField(this.props, "hope2Dorm")}</Grid.Column>
                      <Grid.Column>{showField(this.props, "type2Dorm")}</Grid.Column>
                    </Grid>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid columns='equal'>
                      <Grid.Column>{showField(this.props, "hope3Dorm")}</Grid.Column>
                      <Grid.Column>{showField(this.props, "type3Dorm")}</Grid.Column>
                    </Grid>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid columns='equal'>
                      <Grid.Column>{showField(this.props, "psDorm")}</Grid.Column>
                    </Grid>
                  </Grid.Row>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid>
        </Grid.Row> */}
        <Row>
          {/* <Grid columns='equal'> */}
          <Col>
            <Card fluid>
              <CardBody>
                <Row>
                  <Col>{showField(this.props, "hopeDorm")}</Col>
                </Row>
                <Row>

                  <Col>{showField(this.props, "hope1Dorm")}</Col>
                  <Col>{showField(this.props, "type1Dorm")}</Col>
                </Row>
                <Row>
                  <Col>{showField(this.props, "hope2Dorm")}</Col>
                  <Col>{showField(this.props, "type2Dorm")}</Col>
                </Row>
                <Row>
                  <Col>{showField(this.props, "hope3Dorm")}</Col>
                  <Col>{showField(this.props, "type3Dorm")}</Col>
                </Row>
                <Row>
                  {/* <Grid columns='equal'> */}
                  <Col>{showField(this.props, "psDorm")}</Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {/* </Grid> */}
        </Row>
      </>
    );
  }
}