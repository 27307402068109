import React, { Component } from 'react'
import { Container, Segment, Divider, Button, Image, Grid, Header, Input } from 'semantic-ui-react';
import MaterialTable from 'material-table'

const columns = () => ([
  { title: 'FundCurrencyValue', field: 'FundCurrencyValue' },
  { title: 'ValuationCurrencyValue', field: 'ValuationCurrencyValue' },
]);
const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}
export default class Billing extends Component {
  state = {
    dataArr: [],
  }

  render() {
    const { dataArr, loading } = this.state;

    return (<Container>
      <Grid>
        <Grid.Row>
          <Grid.Column width={6}>
            <br />
            <label style={{ fontSize: '15px' }}>帳單資訊</label>
          </Grid.Column>
          <Grid.Column width={10}>
            <br />
            <Segment clearing textAlign='left'>這個帳戶沒有信用卡。
                <Button size='mini' floated='right' style={{ backgroundColor: '#ac8ece', color: '#fff' }}>新增信用卡</Button>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <br />
        {/* <Grid.Row>
            <Grid.Column width={6}>
            </Grid.Column>
            <Grid.Column width={5}>
              <label>當前用法</label>
            </Grid.Column>
            <Grid.Column width={5}>
              <label>不可使用</label>
            </Grid.Column>
          </Grid.Row>
          <Divider fitted />
          <Grid.Row>
            <Grid.Column width={6}>
            </Grid.Column>
            <Grid.Column width={5}>
              <label>平台信用</label>
            </Grid.Column>
            <Grid.Column width={5}>
              <label>＄0.00</label>
            </Grid.Column>
          </Grid.Row>
          <br /> */}
      </Grid>
    </Container>)
  }
}