import React, { Component } from 'react'
import { Container, Icon, Table, Button, Modal, Header, Form, Pagination, Dropdown, Confirm, Label, Input, Select, TextArea, Grid, Divider } from 'semantic-ui-react'
import _ from 'lodash'
import MyModalTable from '../../widgets/MyModalTable';
import { getOptionsSelByKey, getOptionTextByValue } from 'views/widgets/FieldsRender';
import Moment from 'moment';
import Parse from 'widget/parse'

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipse: {
    // boxSizong: 'border-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}

export default class ClassBulletin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      author: '',
      confirmOpen: false,
      number: 0,
      classBulletin: [],
      show: false,
      form: {
        eventDate: this.getToday()
      },
      confirm: false,
      classList: [],
      // classSchool: 'A',
      // classId: 'vw3QAoEMNNA3swTJk',
    }
  }

  componentDidMount() {
    this.handleFetch();
  }
  // getSnapshotBeforeUpdate(prevProps, prevState) {
  //   // console.log('getSnapshotBeforeUpdate');
  //   if (prevState.classSchool !== this.state.classSchool) {
  //     console.log('change');
  //     this.handleFetch(prevProps);
  //     return {};
  //   }
  //   return null
  // }
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   // console.log('componentDidUpdate');
  //   if (snapshot !== null) {
  //     this.handleFetch(prevProps);
  //   }
  // }
  componentWillReceiveProps(nextProps) {
    if (nextProps.semesterYear !== this.props.semesterYear ||
      nextProps.semester !== this.props.semester ||
      nextProps.classId !== this.props.classId) {
      this.handleFetch(nextProps)
    }
  }

  handleFetch = (props = this.props) => {
    const { companyObj, classId, classroom, classroomL, teachersL, handleLazyLoad, semester, semesterYear } = props;
    if (!classroomL) {
      handleLazyLoad('classroom');
    } else {
      if (classId !== '-1') {
        const classObj = classroom.find(item => item.objectId === classId)
        Parse.queryData('Bulletin', { companyId: companyObj.objectId, classId }, { orderBy: 'eventDate_descend' }).then(snapshot => {
          const classData = []
          snapshot.forEach(fileItem => classData.push({ ...classObj, objectId: fileItem.objectId, ...fileItem }));
          this.setState({ classSchool: classObj.classSchool, classData, loading: false })
        });
        // firebase.firestore().collection(`Classroom/${classId}/Bulletin`)
        //   .get().then(snapshot => {
        //     const classData = []
        //     snapshot.forEach(fileItem => classData.push({ ...classObj, _id: fileItem.id, ...fileItem.data() }));
        //     classData.sort((a, b) => Moment(b.eventDate) - Moment(a.eventDate))
        //     this.setState({ classSchool: classObj.classSchool, classData, loading: false })
        //   });
      }
    }
    if (!teachersL) {
      handleLazyLoad('teachers');
    }
  }

  handleChange = (event, data) => {
    const { name, value, options } = data
    if (name === 'teacherId') {
      const teacherId = options.find(item => item.value === value).key
      this.setState({ form: { ...this.state.form, nameTeacher: value, teacherId } })
    } else {
      this.setState({ form: { ...this.state.form, [name]: value } })
    }
  }

  handleEdit = (classReportObj) => {
    console.log(classReportObj);
    this.setState({ show: true, form: { ...classReportObj } });
  }

  handleOpen = (classReportObj) => this.setState({ show: true, form: { eventDate: this.getToday(), classSchool: this.state.classSchool } });

  handleClose = () => this.setState({ show: false, form: { eventDate: this.getToday(), classSchool: this.state.classSchool } });

  showConfirm = (classBulletinObj) => this.setState({ confirm: true, form: { ...classBulletinObj } });

  hideConfirm = () => this.setState({ confirm: false, form: { eventDate: this.getToday() } });

  handleSubmit = async () => {
    const { firebase, classroom, semesterYear, semester, classId } = this.props;
    const { form, classData, classSchool } = this.state;
    const db = firebase.firestore();
    this.setState({ loading: true });
    const newRows = [...classData];
    if (!form.objectId) {
      const docRef = db.collection(`Classroom/${classId}/Bulletin`).doc();
      const obj = {
        ...form,
        objectId: docRef.id,
        classId,
        classSchool,
        semesterYear,
        semester,
        createdAt: new Date()
      };
      newRows.push(obj);
      await docRef.set(obj);
    } else {
      const updObj = {
        ...form,
        updatedAt: new Date()
      }
      await db.collection(`Classroom/${classId}/Bulletin`).doc(form.objectId).update(updObj);
      let index = newRows.findIndex((item => item.objectId === form.objectId));
      newRows[index] = form;
    }

    this.setState({ classData: newRows, loading: false });
    this.handleClose();
    this.handleFetch();
  }

  handleRemove = async () => {
    const { firebase, classId } = this.props
    const { form, classData } = this.state;
    await firebase.firestore().collection(`Classroom/${classId}/Bulletin`).doc(form.objectId).delete();
    const newRows = [...classData];
    let index = newRows.findIndex((item => item.objectId === form.objectId));
    newRows.splice(index, 1);
    this.setState({ classData: newRows });

    this.handleClose();
    this.hideConfirm();
    this.handleFetch();
  }

  getToday = () => {
    return new Date().toISOString().split('T')[0];
  };

  render() {
    const { firebase, optSet, classId, teachers, classroom, semesterYear, semester, companyObj } = this.props;
    const { selClassArr, classBulletin, form, loading, classData, classSchool } = this.state
    const authRoleOpt = getOptionsSelByKey(optSet, 'role');
    const selClassroom = classId ? classroom.find(item => item.objectId === classId) : '';
    const courseArr = [];

    if (selClassroom) {
      const classTimeArr1 = selClassroom.classTime === '1' ? ["一", "二", "三", "四"] : ["五", "六", "七", "八"];
      const classTimeArr2 = selClassroom.classTime === '1' ? [0, 1, 2, 3] : [4, 5, 6, 7];
      const w = ["月", "火", "水", "木", "金"];

      w.forEach((item, index) => {
        classTimeArr1.forEach((item2, index2) => {
          courseArr.push({
            courseName: item + item2,
            weekday: index,
            period: index2,
            slot: 'course' + index + classTimeArr2[index2],
          })
        });
      });
    }
    return (<>
      <div>
        <div>
          <Header as='h2' floated='left'>学校掲示板-{classSchool} クラス</Header>
          <div style={{ ...style.flexCenter, float: 'right' }}>
            {/* <Input labelPosition='left' style={{ marginRight: 15 }}>
              <Label color={'blue'}> <Icon name='building' />クラス</Label>
              <Select style={{
                minWidth: '300px',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0'
              }} compact options={selClassArr} onChange={this.handleChange} value={classSchool} name='classId' />
            </Input> */}
            <Button color='blue' inverted={companyObj.inverted} icon='add' content='追加' size='tiny' onClick={this.handleOpen} style={{ marginLeft: 15 }} />
          </div>
          <div style={{ clear: 'both' }}></div>
        </div>

        <Table celled structured style={{ marginBottom: 25 }} textAlign='center'>
          <Table.Header>
            <Table.Row textAlign='center'>
              <Table.HeaderCell width={4}>要事日付</Table.HeaderCell>
              <Table.HeaderCell width={4}>閲覧可能者</Table.HeaderCell>
              <Table.HeaderCell width={4}>記録先生</Table.HeaderCell>
              <Table.HeaderCell width={4}>適用課堂</Table.HeaderCell>
              <Table.HeaderCell rowSpan='2' width={2} style={{ minWidth: 75 }}>操作</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell colSpan='4'>要事內容</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {classData && classData.length ? classData.map((item1, index1) => <React.Fragment key={`trr${index1}`}>
              <Table.Row>
                <Table.Cell width={4}>{item1.eventDate}</Table.Cell>
                <Table.Cell width={4}>{item1.authorizedRole && item1.authorizedRole.map(role => getOptionTextByValue(optSet, 'role', role)) + ' '}</Table.Cell>
                <Table.Cell width={4}>{item1.nameTeacher}</Table.Cell>
                <Table.Cell width={4}>{item1.courseName}</Table.Cell>
                <Table.Cell width={2} textAlign='center' rowSpan='2'>
                  {/* <Button inverted={companyObj.inverted} color='green' size='mini' onClick={this.handleOpen}>詳細</Button> */}
                  <Button style={{ margin: 5 }} inverted={companyObj.inverted} color='green' size='mini' icon='edit' onClick={() => this.handleEdit(item1)} />
                  <Button style={{ margin: 5 }} inverted={companyObj.inverted} color='red' size='mini' icon='delete' onClick={() => this.showConfirm(item1)} />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell colSpan='4' textAlign='center'>
                  <div style={{ maxWidth: '100%', maxHeight: '76px', overflowY: 'auto', wordBreak: 'break-all' }}>{item1.note}</div>
                </Table.Cell>
              </Table.Row>
            </React.Fragment>) : <Table.Row><Table.Cell rowSpan='2' colSpan='5' textAlign='center'>(資料無し)</Table.Cell></Table.Row>}
          </Table.Body>
        </Table>
        <Modal open={this.state.show} onClose={this.handleClose}>
          <Modal.Header style={style.flexCenter}>
            <h2 style={{ margin: 0 }}>{form.objectId ? '修正' : '追加'}報告</h2>
            <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
          </Modal.Header>
          <Modal.Content>
            <Form>
              <Grid>
                <Grid.Row columns='equal'>
                  <Grid.Column>
                    <Form.Input
                      label='要事日付'
                      type='date'
                      name='eventDate'
                      onChange={this.handleChange}
                      value={form.eventDate || ''}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Select
                      multiple
                      options={authRoleOpt}
                      label='觀看權限'
                      name='authorizedRole'
                      onChange={this.handleChange}
                      value={form.authorizedRole || ''}
                      placeholder='-- 選択 --'
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Select
                      options={
                        teachers.sort((a, b) => a.teacherNum - b.teacherNum).map(item => ({ key: item.objectId, value: item.nameTeacher, text: `${item.teacherTitleText} ${item.nameTeacher}` }))
                      }
                      label='紀録先生'
                      name='teacherId'
                      onChange={this.handleChange}
                      value={form.nameTeacher || ''}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Select
                      options={
                        courseArr.map(item => ({ key: item.slot + item.index, value: item.courseName, text: item.courseName }))
                      }
                      label='適用課堂'
                      name='courseName'
                      onChange={this.handleChange}
                      value={form.courseName || ''}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                  <Grid.Column>
                    <Form.Field
                      label='要事內容'
                      control={TextArea}
                      name='note'
                      onChange={this.handleChange}
                      value={form.note || ''}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button color='green' content='保存' icon='save' loading={loading} onClick={this.handleSubmit} />
            <Button basic content='閉じる' icon='close' onClick={this.handleClose} />
          </Modal.Actions>
        </Modal>
        <Confirm
          header='削除'
          content='削除してもいいですか?'
          cancelButton='いいえ'
          confirmButton='はい'
          open={this.state.confirm}
          onCancel={this.hideConfirm}
          onConfirm={this.handleRemove}
          size='mini'
          centered={false} />
      </div>
    </>)
  }
}