import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import { Container, Header, Button, Form, Icon, Dimmer, Loader, Image, Segment, Grid } from 'semantic-ui-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { exportPdf, rowReOrder } from 'views/web/CustomMaterialTable';


const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

const modalcolumns = () => ([
  { width: 60, title: '順序', field: 'order', type: 'numeric' },
  { title: '名稱', field: 'name' },
  { title: '檔案', field: 'isFile1', type: 'boolean' },
  { title: '申請檔案', field: 'isFile2', type: 'boolean' },
  { title: '空白檔案', field: 'isFile3', type: 'boolean' },
  { title: '範例檔案', field: 'isFile4', type: 'boolean' },
  { title: '附加文件提醒', field: 'isDocPs', type: 'boolean' },
  { title: '工作天數', field: 'isWorkdays', type: 'boolean' },
])

export default class FileCenter1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataArr: [],
      loading: true,
      show: false,
    }
    // this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, type } = props;
    const companyId = companyObj.objectId;
    this.setState({ loading: true })
    const dataArr = await Parse.queryData('fileCenter1', { companyId, type });
    this.setState({ loading: false, dataArr })
  }

  handleAdd = async (id) => {
    const { newData } = this.state;
    this.setState({ loading: true });
    await Parse.saveData('fileCenter1', newData);
    this.setState({ loading: false })
  }

  handleUpdate = async (id) => {
    const { companyObj } = this.props;
    const { newData } = this.state
    const companyId = companyObj.objectId;
    this.setState({ loading: true })
    await Parse.saveData('fileCenter1', newData);
    await Parse.saveall('fileCenter2',
      { companyId, fileCenter1Id: newData.objectId },
      { fileCenter1Text: newData.name })

    this.setState({ loading: false })
  }

  handleDelete = async (id) => {
    const { oldData } = this.state
    this.setState({ loading: true })
    await Parse.destroyData('fileCenter2', { fileCenter1Id: oldData.objectId })
    await Parse.deleteData('fileCenter1', oldData);
    this.setState({ loading: false, oldData: {} })
  }

  handleClose = () => {
    const { handleData } = this.props
    this.setState({ show: false, loading: false });
    handleData()
  }

  handleState = (dataArr, arrName = 'dataArr') => {
    this.setState({ [arrName]: dataArr })
  }

  render() {
    const { companyObj, type } = this.props
    const { dataArr, show, loading } = this.state;

    // console.log(dataArr)
    return (<>
      <Button content='文件類別管理' icon='book' color='blue' onClick={() => this.setState({ show: true })} />
      <Modal
        isOpen={show}
        toggle={this.handleClose}
        size="lg"
      // className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader style={style.flexCenter}>
          文件類別管理
          <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
        </ModalHeader>
        <ModalBody>
          <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={modalcolumns()}
            options={{
              addRowPosition: "first",
              exportButton: true,
              exportPdf: (columns, data) => exportPdf(columns, data),
              pageSize: 5,
              search: false,
            }}
            components={{
              Row: props => rowReOrder(props, 'fileCenter1', 'order', dataArr, 'dataArr', this.handleState)
            }}
            data={dataArr}
            title=""
            editable={{
              onRowAdd: newData =>
                new Promise(async (resolve) => {
                  const data = [...dataArr];
                  const db = Parse.Object.extend("fileCenter1");
                  const newDoc = new db();
                  const docRef = await newDoc.save();
                  const obj = {
                    _id: docRef.id,
                    id: docRef.id,
                    objectId: docRef.id,
                    companyId: companyObj.objectId || '',
                    companyName: companyObj.name || '',
                    type,
                    ...newData
                  }
                  data.push(obj);
                  this.setState({ dataArr: data, newData: obj }, () => resolve());
                }).then(() => this.handleAdd("1")),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  const data = [...dataArr];
                  const index = data.indexOf(oldData);
                  data[index] = newData;
                  this.setState({ dataArr: data, newData, oldData }, () => resolve());
                }).then(() => this.handleUpdate("1")),
              onRowDelete: oldData =>
                new Promise((resolve) => {
                  let data = [...dataArr];
                  const index = data.indexOf(oldData);
                  data.splice(index, 1);
                  this.setState({ dataArr: data, oldData }, () => resolve());
                }).then(() => this.handleDelete("1")),
            }}
          />
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button icon='x' content='關閉' onClick={this.handleClose} />
        </ModalFooter>
      </Modal>
    </>)
  }
}