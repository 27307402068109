import React, { Component } from 'react'
import { Modal, Button, Grid, Form, Divider, Menu, Checkbox, Segment, Header, Select, TextArea, Input, Icon, Label } from 'semantic-ui-react';
import _ from 'lodash';
import Parse from '../../../widget/parse'

import { getOptionsByKey, handleClickModalAttend } from '../../widgets/FieldsRender'

const minOption = [];
for (let i = 0; i < 60; i++) {
  minOption.push({
    key: `${i}`,
    text: `${i}`,
    value: `${i}`,
  })
}
const style = {
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
}

export class RollcallModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attendObj: {
        activeA: '',
        lateMin: '0',
        ps: '',
        inclassStatus1: {},
        inclassStatus2: {},
        reasonKnown: {},
        absentReason: ''
      }
    };
  }

  componentDidMount() {
    this.handleInitial()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.modalObj !== this.props.modalObj ||
      nextProps.eventDate !== this.props.eventDate ||
      nextProps.period !== this.props.period) {
      this.handleInitial(nextProps)
    }
    if (nextProps.multiSelect !== this.props.multiSelect) {
      this.setState({
        attendObj: {
          activeA: '',
          lateMin: '0',
          ps: '',
          inclassStatus1: {},
          inclassStatus2: {},
          reasonKnown: {},
          absentReason: ''
        }
      })
    }
  }

  handleInitial = async (props = this.props) => {
    const { firebase, modalObj, eventDate, period, optSet } = props;
    if (modalObj && eventDate && period) {
      // firebase.firestore().doc(`/Students/${modalObj._id}/Daily/${eventDate}/Rollcall/${period}`).get()
      //   .then((doc) => {
      //     // console.log('handleInitial', doc.data())
      //     if (doc.data()) {
      //       this.setState({
      //         attendObj: {
      //           activeA: doc.data()['attendStatus'] || '',
      //           lateMin: doc.data().lateMin || '0',
      //           ps: doc.data().ps || '',
      //           inclassGoodStatus: doc.data().inclassGoodStatus || {},
      //           inclassBadStatus: doc.data().inclassBadStatus || {},
      //           reasonKnown: doc.data().reasonKnown || {},
      //           absentReason: doc.data().absentReason || '',
      //         }
      //       })
      //     } else {
      //       this.setState({
      //         attendObj: {
      //           activeA: '',
      //           lateMin: '0',
      //           ps: '',
      //           inclassStatus1: {},
      //           inclassStatus2: {},
      //           reasonKnown: {},
      //           absentReason: ''
      //         }
      //       })
      //     }
      //   })
      //   .catch((e) => console.log(e))
      Parse.queryData('RollCall', { studentId: modalObj._id, eventDate, period }).then((doc) => {
        if (doc) {
          this.setState({
            attendObj: {
              activeA: doc['attendStatus'] || '',
              lateMin: doc.lateMin || '0',
              ps: doc.ps || '',
              inclassGoodStatus: doc.inclassGoodStatus || {},
              inclassBadStatus: doc.inclassBadStatus || {},
              reasonKnown: doc.reasonKnown || {},
              absentReason: doc.absentReason || '',
            }
          })
        } else {
          this.setState({
            attendObj: {
              activeA: '',
              lateMin: '0',
              ps: '',
              inclassStatus1: {},
              inclassStatus2: {},
              reasonKnown: {},
              absentReason: ''
            }
          })
        }
      })
    }
  }

  handleAClick = (e, { value }) => {
    const { single, firebase, modalObj, eventDate, period, optSet, classObj, multiStudent, multiSelect, companyObj } = this.props;
    const stuId = modalObj._id;
    if (multiSelect) {
      console.log(multiStudent)
      this.props.handleAttandChange(value)
      this.setState({ attendObj: { activeA: value } })
      multiStudent.map(item => {
        // firebase.firestore().doc(`/Students/${item.selStudentId}`).get()
        //   .then(async (doc) => {
        //     const data = doc.data() ? doc.data() : {}
        //     await handleClickModalAttend(firebase, optSet, doc.data(), value, item.selStudentId, item.selDate, item.selPeriod, classObj)
        //   }).catch(
        //     (e) => console.error(e)
        //   )
        Parse.queryData('Students', { companyId: companyObj.objectId, objectId: item.selStudentId }).then(async (doc) => {
          const data = doc ? doc : {}
          await handleClickModalAttend(firebase, optSet, data, value, item.selStudentId, item.selDate, item.selPeriod, classObj)
        }).catch(
          (e) => console.error(e)
        )
      })
    } else if (single === true) {
      this.setState({ attendObj: { activeA: value } })
      // firebase.firestore().doc(`/Students/${stuId}`).get()
      //   .then(async (doc) => {
      //     const data = doc.data() ? doc.data() : {}
      //     await handleClickModalAttend(firebase, optSet, doc.data(), value, stuId, eventDate, period, classObj)
      //   }).catch(
      //     (e) => console.error(e)
      //   )
      Parse.queryData('Students', { companyId: companyObj.objectId, objectId: stuId }).then(async (doc) => {
        const data = doc ? doc : {}
        await handleClickModalAttend(companyObj, optSet, data, value, stuId, eventDate, period, classObj)
      }).catch(
        (e) => console.error(e)
      )
    } else {
      this.props.handleAttandChange(value)
      this.setState({ attendObj: { activeA: value } })
      // firebase.firestore().doc(`/Students/${stuId}`).get()
      //   .then(async (doc) => {
      //     const data = doc.data() ? doc.data() : {}
      //     await handleClickModalAttend(companyObj, optSet, doc.data(), value, stuId, eventDate, period, classObj)
      //   }).catch(
      //     (e) => console.error(e)
      //   )
      Parse.queryData('Students', { companyId: companyObj.objectId, objectId: stuId }).then(async (doc) => {
        const data = doc ? doc : {}
        await handleClickModalAttend(companyObj, optSet, data, value, stuId, eventDate, period, classObj)
      }).catch(
        (e) => console.error(e)
      )
    }
  }

  handleChange = (e, { value, name }) => {
    const { firebase, modalObj, eventDate, period } = this.props;
    const { attendObj } = this.state;

    this.setState({ attendObj: { ...attendObj, [name]: value } })
    // firebase.firestore().doc(`/Students/${modalObj._id}/Daily/${eventDate}/Rollcall/${period}`).update({ [name]: value })
    //   .then((doc) => {
    //   }).catch(
    //     (e) => console.error(e)
    //   )
  }

  handleSubmit = () => {
    const { firebase, modalObj, eventDate, period } = this.props;
    const { attendObj } = this.state
    firebase.firestore().doc(`/Students/${modalObj._id}/Daily/${eventDate}/Rollcall/${period}`).set(attendObj, { merge: true })
      .then(
        this.props.closeModal()
      ).catch(
        (e) => console.error(e)
      )
  }

  handleSelChange = (e, { id, checked, name }) => {
    const { firebase, modalObj, eventDate, period } = this.props;
    const { attendObj } = this.state;

    this.setState({ attendObj: { ...attendObj, [name]: { [id]: checked } } })
    // firebase.firestore().doc(`/Students/${modalObj._id}/Daily/${eventDate}/Rollcall/${period}`).set(
    //   { [name]: { [id]: checked } },
    //   { merge: true }
    // )
    //   .then((doc) => {
    //     this.setState({ [name]: { ...this.state[name], [id]: checked } })
    //   }).catch(
    //     (e) => console.log(e)
    //   )
  }

  render() {
    const { optSet, modalObj, showSettingsModal, multiSelect, multiStudent } = this.props;
    const { attendObj } = this.state
    const { activeA, reasonKnown, inclassGoodStatus, inclassBadStatus, attendStatus, inclassStatus1, inclassStatus2, ps, absentReason, lateMin } = attendObj
    const status1Arr = getOptionsByKey(optSet, 'inClassAbsence');
    const status2Arr = getOptionsByKey(optSet, 'goodInClassroom');
    const status3Arr = getOptionsByKey(optSet, 'badInClassroom');

    const attentArr = getOptionsByKey(optSet, 'attentInClassroom');

    // console.log(activeA)
    return (<Segment raised style={{ backgroundColor: 'aliceblue', paddingBottom: '40px' }}>
      <Header as='h3' floated='left'>学生：
      {multiSelect ? `${multiStudent.map(item => item.selStudentObj['jpnName'])}`
          : `${modalObj.studentId || ''} ${modalObj.jpnName || ''}`}
        {multiSelect ?
          <Button onClick={() => this.setState({ activeA: -1 }, () => this.props.handleClear())} color='orange' icon='delete' content='クリア' />
          : null}
      </Header>
      <div style={{ float: 'right' }}>
        <Icon name='close' size='big' style={{ 'paddingRight': '10px', cursor: 'pointer' }} onClick={() => this.props.closeModal()} />
      </div>
      {attentArr.length ?
        <Menu
          // inverted={companyObj.inverted} 
          size='tiny'
          widths={attentArr.length}>
          {attentArr.map(item => (
            <Menu.Item
              key={item._id}
              value={item.value}
              name={item.label}
              active={item.value === activeA}
              color={item.note1 || ''}
              onClick={this.handleAClick}
            />
          ))}
        </Menu> : null}
      <Divider />
      {/* <Form> */}
      {multiSelect ? null :
        <Form.Group>
          {activeA === "2" ?
            <Grid>
              <Grid.Column width='4'>
                <Form.Field
                  control={Select}
                  label='遅刻 (分)'
                  options={minOption}
                  // placeholder='--選擇--'
                  width='3'
                  value={lateMin}
                  onChange={this.handleChange}
                  name='lateMin'
                />
              </Grid.Column>
              <Grid.Column width='12'>
                <label>授業態度</label>
                <Grid>
                  <Grid.Row>
                    {status2Arr.map((item, index) => <Checkbox style={{ marginRight: '6px' }} key={item._id} label={item.label + '  '} id={item._id} name='inclassGoodStatus' onClick={this.handleSelChange} checked={inclassGoodStatus ? inclassGoodStatus[item._id] : false} />)}
                  </Grid.Row>
                  <Grid.Row>
                    {status3Arr.map((item, index) => <Checkbox style={{ marginRight: '6px' }} key={item._id} label={item.label} id={item._id} name='inclassBadStatus' onClick={this.handleSelChange} checked={inclassBadStatus ? inclassBadStatus[item._id] : false} />)}
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid>
            : null}
          {activeA === "1" ? <Form.Field>
            <label>授業態度</label>
            <Grid>
              <Grid.Row>
                {status2Arr.map((item, index) => <Checkbox style={{ marginRight: '6px' }} key={item._id} label={item.label} id={item._id} name='inclassGoodStatus' onClick={this.handleSelChange} checked={inclassGoodStatus ? inclassGoodStatus[item._id] : false} />)}
              </Grid.Row>
              <Grid.Row>
                {status3Arr.map((item, index) => <Checkbox style={{ marginRight: '6px' }} key={item._id} label={item.label} id={item._id} name='inclassBadStatus' onClick={this.handleSelChange} checked={inclassBadStatus ? inclassBadStatus[item._id] : false} />)}
              </Grid.Row>
            </Grid>
          </Form.Field> : null}
          {activeA === "8" ? <Form.Field>
            <label>欠席理由</label>
            {status1Arr.map((item, index) =>
              <React.Fragment key={item._id} >
                <Checkbox style={{ marginLeft: '6px', marginRight: '6px' }} label={item.label} id={item._id} name='reasonKnown' onClick={this.handleSelChange} checked={reasonKnown ? reasonKnown[item._id] : false} />
              </React.Fragment>
            )}
            {reasonKnown && reasonKnown.length && reasonKnown[0].checked === true ?
              <Form.Field
                control={Input}
                label='理由'
                name='absentReason'
                width='4'
                value={absentReason}
                onChange={this.handleChange}
              /> : null}
          </Form.Field> : null}
          {typeof activeA !== 'undefined' && activeA && activeA !== '-1' ? <Form.Field
            control={Input}
            label='備考'
            name='ps'
            width='4'
            value={ps}
            onChange={this.handleChange}
          /> : null}
        </Form.Group>}
      {multiSelect ? null : <div style={{ ...style.flexCenter, float: 'right' }} >
        <Button positive size="small" onClick={this.handleSubmit} icon="save" content="保存" />
      </div>}
    </Segment>)
  }
}

export default RollcallModal;
