import React, { Component } from 'react';
import { Form, Tabs, Table, Button, Select, Grid, Header, Divider, Input, Label, Icon } from 'semantic-ui-react';
import ModalPdf from 'widget/pdf/ModalPdf';
import { Pie } from 'react-chartjs-2';
import { getOptionsByKey } from 'views/widgets/FieldsRender';

// import { showField, showFieldText, getAvailblePeriodArr, getOptionsByKey } from 'views/widgets/FieldsRender';
// const statXLabel = new Array(40).fill('').map((item, index) => (`${index * 10 && index * 10 + 1}-${(index + 1) * 10}`));
const semesterArr = [
  // { key: 'empty', text: '- 学期選択 -', value: '0' },
  { key: '2020,S', text: '2020S', value: '2020,S' },
  { key: '2019,A', text: '2019A', value: '2019,A' },
  { key: '2019,S', text: '2019S', value: '2019,S' },
  { key: '2018,A', text: '2018A', value: '2018,A' },
  { key: '2018,S', text: '2018S', value: '2018,S' },
  { key: '2017,A', text: '2017A', value: '2017,A' },
  { key: '2017,S', text: '2017S', value: '2017,S' },
]
const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}
let d = new Date().toLocaleDateString()
let now = new Date()
export default class GraduateStatistics extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      show: false,
      filterForm: {
        searchText: '',
        selClass: '0',
        year: props.semesterYear,
        semes: props.semester,
        yearsemester: `${props.semesterYear},${props.semester}`,
        applyResult: '0'
      },
      graduateYear: `${Number(this.props.semesterYear) + 1}`,
      allStu: [],
      imgURL: '',
    };
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = (props = this.props) => {
    const { firebase, students, studentsL, handleLazyLoad } = props;
    if (!studentsL) {
      handleLazyLoad('Students', 'students');
    } else {
      let allStu = students
      // let allStu = students.filter(item => (item.studentStatus === '50' || item.studentStatus === '51' || item.studentStatus === '31' || item.studentStatus === '32')) // 所有卒業
      this.setState({ allStu })
    }
  }

  handleChange = (event, { name, value }) => {
    const { filterForm } = this.state
    if (name === 'graduateYear') {
      const year = value.substring(0, 4);
      const semes = value.substring(5);
      const yearsemester = value;
      this.setState({
        graduateYear: value,
        filterForm: { ...filterForm, year, semes, selClass: '0', yearsemester, searchText: '' }
      }, () => this.convertImg());
    }
  }

  convertImg = () => {
    let canvas = document.getElementById('canvas');

    if (canvas) {
      let imgURL = canvas.toDataURL();
      this.setState({ imgURL }, () => console.log(imgURL))
    } else {
      this.setState({ imgURL: '' })
    }
  }

  render() {
    const { allStu, filterForm, imgURL, graduateYear } = this.state
    const { optSet } = this.props
    const { yearsemester } = filterForm
    const semesterYearArr = getOptionsByKey(optSet, 'semesterYear').map(item => ({ key: item._id, text: item.label, value: item.value }));
    let stuArr = allStu.filter(item => item.semesterYear === (Number(graduateYear) - 2) + '')
    let wayOutSituationArr = []
    let wayOutSituationLabels = []
    let wayOutSituationCount = []
    let wayOutSituationColor = []

    stuArr.forEach(item => {
      let findWayOutSituation = wayOutSituationLabels.findIndex(wayOutSituationItem => wayOutSituationItem === item.wayOutSituationText)
      if (findWayOutSituation === -1) {
        wayOutSituationLabels.push(item.wayOutSituationText)
        wayOutSituationArr.push({ wayOutSituationText: item.wayOutSituationText, count: 1 })
      } else {
        wayOutSituationArr.forEach(item2 => {
          if (item.wayOutSituationText === item2.wayOutSituationText) {
            item2.count++
          }
        })
      }
    })
    wayOutSituationCount = [...wayOutSituationArr.map(item => item.count)]
    let newWayOutSituationLabels = []
    wayOutSituationArr.forEach(item => {
      if (item.wayOutSituationText === undefined)
        newWayOutSituationLabels.push(`${'未知'} ${item.count}人(${(Math.round((item.count / stuArr.length) * 10000)) / 100}%)`)
      else
        newWayOutSituationLabels.push(`${item.wayOutSituationText} ${item.count}人(${(Math.round((item.count / stuArr.length) * 10000)) / 100}%)`)
    })
    wayOutSituationArr.forEach(item => wayOutSituationColor.push(`rgb(${Math.floor(Math.random() * 200)},${Math.floor(Math.random() * 200)},${Math.floor(Math.random() * 200)})`))
    const arrPdf = [{
      filterForm,
      stuArr,
      imgURL
    }]
    return (
      <>
        <div style={{ ...style.flexCenter }}>
          <Header as='h2'
            content='卒業生統計'
            style={{ margin: '0' }}
          />
          <div style={{ ...style.flexCenter, minWidth: 335 }}>
            {/* <Select style={{
              maxWidth: '100px',
            }} compact
              floated='right'
              fluid
              options={semesterArr}
              onChange={this.handleChange}
              // disabled={isEdit}
              value={yearsemester}
              name='yearsemester' /> */}
            <Input labelPosition='left' >
              <Label color={'blue'}> <Icon name='file alternate outline' />卒業年</Label>
              <Select style={{
                minWidth: '120px',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
                marginRight: 10
              }} compact
                options={semesterYearArr} onChange={this.handleChange} value={graduateYear} name='graduateYear' />
            </Input>
            <Button size='small' content='convertImg' style={{ marginLeft: 10 }} onClick={() => this.convertImg()} />
            <ModalPdf
              {...this.props}
              // ref={(ref) => { this.pdfModal = ref }}
              codekey="graduateStatisticsPdf"
              params={arrPdf}
            />
          </div>
        </div>
        <div style={{ ...style.flexCenter }}>
          <Header content={`${graduateYear}年卒業(${stuArr.length})人`} style={{ margin: '15px 0 0' }} />
          <Header content={`${now.getFullYear()} 年 ${now.getMonth() + 1} 月 ${now.getDate()} 日  現在`} style={{ margin: '15px 0 0' }} />
        </div>
        <Divider />
        <Grid>
          <Grid.Row columns='equal'>
            {/* <Grid.Column>
              <Table celled>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>123</Table.Cell>
                    <Table.Cell>333</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column> */}
            <Grid.Column>
              <Header as='h3' content='グラフタイトル' />
              {newWayOutSituationLabels && newWayOutSituationLabels.length ? <Pie
                id='canvas'
                data={{
                  datasets: [
                    {
                      data: wayOutSituationCount,
                      backgroundColor: wayOutSituationColor
                    }
                  ],

                  labels: newWayOutSituationLabels
                }}
              // options={{
              //   legend: {
              //     position: 'right'
              //   }
              // }}
              />
                : <div style={{ margin: '30px 0' }}>(資料無し)</div>}
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </>
    );
  }
}