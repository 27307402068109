import React, { Component } from 'react'
import { Select, Header, Button, Grid, Icon, Form, Checkbox, Message, Loader } from 'semantic-ui-react';
// import fetchJson from '../widgets/http';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Parse from "widget/parse";
import _ from 'underscore'

const style = {
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  layout: {
    flexDirection: 'column',
    // minHeight: '100vh'
  },
}
const getAuthArray = (company, user, flag) => user[flag] ? _.union(user[flag + 'A'], [company.objectId]) : _.without(user[flag + 'A'], company.objectId);

const genderOptions = [
  { key: 'm', text: 'SuperAdmin', value: 'SuperAdmin' },
  { key: 'f', text: 'Admin', value: 'Admin' },
  { key: 'o', text: 'Staff', value: 'Staff' },
  { key: 'c', text: 'Client', value: 'Client' },
]

export default class RoleUserAdd extends Component {
  state = {
    form: {
      email: '',
      authUser: true,
      authLogin: true,
      authAdmin: false,
      // phoneNumber: '0900000000'
    },
    open: false,
    isLoading: false
  }

  handleChange = (e, data) => {
    const { name, value, checked } = data
    const { form } = this.state
    if (name === 'email') {
      this.setState({ form: { ...form, [name]: value }, msgError: '' })
    } else {
      this.setState({ form: { ...form, [name]: checked }, msgError: '' })
    }
  }

  handleSubmit = async () => {
    const { roleObj } = this.props;
    const { form, error } = this.state;
    const { email, authAdmin, authUser, authLogin } = form;

    if (!email) {
      window.alert('請輸入 E-Mail')
      return;
    }
    this.setState({ isLoading: true });

    // const userArr = await Parse.queryData("User", { email });

    var query = new Parse.Query(Parse.User);
    query.equalTo('email', email);
    query.limit(1);
    const userArr = await query.find();
    // console.log(userArr)

    // let password = '';
    // console.log(userArr)
    if (userArr.length) { // 資料庫裡面有這個使用者了
      const user = userArr[0];
      // console.log(user)
      // const roleACL = new Parse.ACL();
      // roleACL.setPublicReadAccess(true);
      // roleACL.setWriteAccess(user, true);

      // const role = new Parse.Role("Client");
      const role = roleObj.raw;
      // console.log(role)
      role.getUsers().add(user);
      await role.save().catch(error => console.error(error));
      // console.log(role.id)
      // return

      // console.log(userObj)
      // await Parse.saveData("User", userObj)
      this.setState({ isLoading: false });
      this.setState({ open: false }, () => window.alert("已成功新增角色帳號"));

      this.props.refetch(roleObj);
      this.close();
    } else {
      window.alert('錯誤，此帳號尚未被註冊')
    }
    // const res = await Parse.sendmail({ from: "測試 <aaa@gmain.com>", to: form.email, subject: "111re哈哈set password", html: "t321est" }
    // await Parse.sendmail({
    //   from: `${companyObj.name} <mai8info@gmain.com>`,
    //   to: email,
    //   subject: `新增使用權限 ${companyObj.name}`,
    //   html: `您的帳號：${email}<br /> ${password && `您的密碼：${password}`}<br /><br />登入網址：https://${companyObj.url}`
    // });
  }

  close = () => this.setState({ open: false })
  render() {
    const { roleObj } = this.props
    const { form, open, error, isLoading } = this.state;
    const { email, authLogin, authAdmin, } = form;

    return (<>
      <Button basic color='green' size='tiny' onClick={() => this.setState({ open: true })}>
        <Icon name='user plus' />新增帳號</Button>
      <Modal
        isOpen={open}
        //  onOpen={this.open}
        //  onClose={this.close}
        // isOpen={this.state.modalDemo}
        toggle={this.close}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggle}>
            <span aria-hidden="true">×</span>
          </button>
          角色{roleObj.name} 新增帳號
        </ModalHeader>
        <ModalBody>
          <Grid>
            <Grid.Row>
              <Grid.Column width={5}>登入信箱</Grid.Column>
              <Grid.Column width={11}>
                <Form.Input
                  id="email"
                  required
                  fluid icon='mail'
                  iconPosition='left'
                  placeholder='登入信箱'
                  onChange={this.handleChange}
                  name='email'
                  value={email}
                // error={!email && written ? { content: '必填', pointing: 'below' } : false}
                />
              </Grid.Column>
            </Grid.Row>
            {/* <Grid.Row>
                  <Grid.Column width={5}>連絡電話/手機</Grid.Column>
                  <Grid.Column width={11}>
                    <Form.Input
                      id="phoneNumber"
                      required
                      fluid icon='phone'
                      iconPosition='left'
                      placeholder='連絡電話/手機'
                      onChange={this.handleChange}
                      name='phoneNumber'
                      value={phoneNumber}
                    // error={!email && written ? { content: '必填', pointing: 'below' } : false}
                    />
                  </Grid.Column>
                </Grid.Row> */}
            {/* <Grid.Row>
              <Grid.Column width={5}>
                角色
              </Grid.Column>
              <Grid.Column width={11} style={{ ...style.flexCenter }}>
                <Form.Field
                  control={Select}
                  options={genderOptions}
                  // label={{ children: '角色', htmlFor: 'form-select-control-gender' }}
                  placeholder='角色'
                />
                <span>&nbsp;</span>
              </Grid.Column>
            </Grid.Row> */}
          </Grid>

          {error ? <Message negative>
            <Message.Header>錯誤</Message.Header>
            <p>{JSON.stringify(error, null, 2)}</p>
          </Message> : null}
          <br />
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button negative basic onClick={() => this.setState({ open: false })}>關閉</Button>
          <Button
            positive
            // icon='user plus'
            // labelPosition='right'
            onClick={this.handleSubmit}
          >
            {!isLoading
              ? "新增"
              : <Loader active inverted inline size='small' />}
          </Button>
        </ModalFooter>
      </Modal>
    </>)
  }
}