import React, { Component, createRef } from 'react'
import { Container, Header, Form, Icon, Image, Select, Divider, Segment, Button, Sticky, Ref } from 'semantic-ui-react'
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, FormGroup, Input, Row, Col, CustomInput,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import _ from 'lodash'
import Timer from 'react-timer-wrapper';

import Timecode from 'react-timecode';
import { DimmerImg } from '../../layouts/Dimmer';
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import PageHeader from 'components/Headers/PageHeader';
import { ListGroup, ListGroupItem } from 'reactstrap';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

const directions = {
  '1': `LISTENING TEST
  PART 1
  Directions: In this part, you're presented with photographs and four sentences about the photographs. Choose the one sentence that best describes the situation in the photograph. Mark your choice (A), (B), (C), or (D) on your answer sheet. The sentences will not be printed in your test book, and you will hear them only one time.`,
  '2': ` 
  PART 2
  Directions: In this part, you will hear a question or a statement and three responses only one time. They will not be printed in your test book. Choose the best response to the question or statement and mark your choice (A), (B), or (C) on your answer sheet.`,
  '3': `Directions: In this part, you will hear some conversations between two or three people.You will also hear three questions about the conversations only one time. The conversations will not be printed in your test book. Choose the best answer to the questions and mark your choice (A), (B), (C), or (D) on your answer sheet.`,
  '4': `Directions: In this part, you will hear some talks by one speaker.You will also hear three questions about the talks only one time. The talks will not be printed in your test book. Choose the best answer to the questions and mark your choice (A), (B), (C), or (D) on your answer sheet.`,
  '5': `Directions: In this part, a word or phrase is missing in each of the sentences. Four answer choices are given below each sentence. Select the best answer to complete the sentence. Then mark your choice (A), (B), (C) or (D) on your answer sheet.`,
  '6': `Directions: In this part, words, phrases, or sentences are missing from each text. Four answer choices for each question are given below the text. Select the best answer to complete the text. Then mark your choice (A), (B), (C), or (D) on your answer sheet.`,
  '7': `Directions: In this part, you will read a selection of texts, such as e-mails, instant messages, and articles. Each text or set of texts is followed by several questions. Select the best answer for each question and mark your choice (A), (B), (C), or (D) on your answer sheet.`,
}

const partArr = ['1', '2', '3', '4', '5', '6', '7']
// let groupCount = 'NONE'
const content = {
  maxWidth: '1170px',
  margin: '80px auto 110px',
  flexDirection: 'column',
}

export class Toeic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data1: [],
      versionsArr: [],
      version: "-1",
      loading: false,
      answer: [],
      start: false,
      show: false,
      end: false,
      testAnswer: [],
      audioArr: [],
      currentMusicIndex: 0,
      gif: -1,
      // groupCount: 'NONE',
    };
  }

  async componentDidMount() {
    this.handleFetch()
  }

  handleFetch = async () => {
    const { newData } = this.state;
    // this.setState({ loading: true })
    const data = await Parse.queryData('testBank');
    const versionsArr = []
    data.forEach(item => {
      versionsArr.push({ key: item.objectId, value: item.version, text: item.version })
    })
    // console.log(data)

    this.setState({ data, versionsArr });
  }

  handleChange = async (e, { name, value }) => {
    this.setState({ loading: true })
    const data1 = await Parse.queryData('testExBank', { verId: value })
    console.log(data1)
    // let testAnswer = {}
    const audioArr = []
    data1.forEach(item => {
      if (item.file2) {
        audioArr.push({ url: item.file2 && item.file2.url })
      }
    })

    this.setState({ data1, [name]: value, loading: false, answer: [], show: true, audioArr })
  }

  handleAnswer = (e) => {
    const { answer } = this.state
    // this.setState({ answer: { ...this.state.answer, [e.target.name]: e.target.id.split('_')[0] } })
    answer.push({ [e.target.name]: e.target.id.split('_')[0] })
    this.setState({ answer })
  }

  handleJump = (e, data) => {
    window.location.hash = data.id
  }

  handleTest = () => {
    console.log('@@')
  }

  onTimerUpdate = ({ time, duration }) => {
    const a = time / 1000
    console.log(a)
    if (a > 67) {
      const b = (a - 67) / 26
      this.setState({ gif: Number(parseInt(b)) })
    }
    // console.log(b)

    this.setState({ time, duration });
  }

  handleStart = () => { this.setState({ start: true, show: false }); }
  handleClose = () => { this.setState({ show: false, end: false }) }
  handleStop = () => {
    const { time, data1 } = this.state
    const testAnswer = []
    data1.forEach(item => {
      testAnswer.push({ [item.number]: item.answer })
    })
    const a = new Date(time).getHours()
    const b = new Date(time).getMinutes()
    const c = new Date(time).getSeconds()
    const wasteTime = `${(Number(a) - 8)}時${b}分${c}秒`
    this.setState({ testAnswer, wasteTime, start: false }, () => this.handleScroe())
  }
  handleScroe = () => {
    const { testAnswer, answer } = this.state
    let score = 0
    // console.log(testAnswer)
    // console.log(answer)
    answer.forEach(item => {
      testAnswer.forEach(item2 => {
        if (_.isEqual(item, item2)) {
          score += 1
        }
      })
    })
    // console.log(bingo)
    this.setState({ score: score * 2, end: true })
  }
  contextRef = createRef()
  handleEnd = () => {
    const { audioArr, currentMusicIndex } = this.state
    if (currentMusicIndex < audioArr.length) {
      this.setState({ currentMusicIndex: currentMusicIndex + 1 })
    }
  }

  render() {
    const { currentMusicIndex, audioArr, data1, versionsArr, version, loading, score, duration, time, start, show, end, wasteTime, gif } = this.state
    // console.log(gif)
    return (<>
      <Ref innerRef={this.contextRef}>
        <Container style={content}>
          <br />
          {!end ? <>
            {!start ? <>
              <Segment style={{ fontSize: '18px' }} color='black'>模擬測驗<br />可選擇回數、年份之類的，進行考場擬真的測驗</Segment>
              <br />
              <lebel>請選擇版本</lebel>
              <Select
                name="version"
                options={[{ key: '-1', value: '-1', text: '請選擇' }].concat(versionsArr)}
                value={version}
                style={{ position: 'relative', zIndex: 99, marginBottom: '40px', marginLeft: '20px' }}
                label='版本'
                onChange={this.handleChange}
              />
            </> : null}
            {start ? <>
              <div style={{ position: 'absolute', left: '400px' }}>
                <Sticky context={this.contextRef} offset={100}>
                  <Timer active={start} duration={120 * 60 * 1000} onTimeUpdate={this.onTimerUpdate} />
                  <Timecode style={{ fontSize: '5rem' }} time={duration - time} />
                  {/* <Timecode active={start} style={{ fontSize: '5rem' }} time={duration - time} /> */}
                  {/* <Timer active={start} duration={120 * 60 * 1000} >
                    <Timecode style={{ fontSize: '5rem' }} />
                  </Timer> */}
                </Sticky>
              </div>
              <br />
              <Image
                // href={'#'}
                src='https://parse.infowin.com.tw/parse/files/parseServer/7a318223a689fbe69b5b9f557d93fcb5_1.jpeg'
                // src='/image17.png'
                style={{
                  height: '100%',
                  width: '100%',
                }}
              />
              {/* 快速導覽： */}
              {/* <a href="#1">Part1</a>{' '} */}
              {/* <Button content='Part1' id='#1' color='twitter' onClick={this.handleJump} />
              <Button content='Part2' id='#2' color='twitter' onClick={this.handleJump} />
              <Button content='Part3' id='#3' color='twitter' onClick={this.handleJump} />
              <Button content='Part4' id='#4' color='twitter' onClick={this.handleJump} />
              <Button content='Part5' id='#5' color='twitter' onClick={this.handleJump} />
              <Button content='Part6' id='#6' color='twitter' onClick={this.handleJump} />
              <Button content='Part7' id='#7' color='twitter' onClick={this.handleJump} /> */}
              {/* <a href="#2">Part2</a>{' '}
        <a href="#3">Part3</a>{' '}
        <a href="#4">Part4</a>{' '}
        <a href="#5">Part5</a>{' '}
        <a href="#6">Part6</a>{' '}
        <a href="#7">Part7</a>{' '} */}
              <br />
              <br />
              <AudioPlayer
                autoPlay
                src={audioArr[currentMusicIndex].url || ''}
                autoPlayAfterSrcChange={true}
                onEnded={this.handleEnd}
                customIcons={<><Icon name='sound' src='' link onClick={this.handleAudio} /></>}
                onPlay={e => console.log('play')}
                showJumpControls={false}
                customVolumeControls={[]}
                customAdditionalControls={[]}
                customProgressBarSection={[]}
                style={{ padding: '0', display: 'none', width: '100px', boxShadow: 'none', lineHeight: 0 }}
              // other props here
              />
              {loading ? <DimmerImg /> :
                partArr.map(item => {
                  let groupCount = 'NONE'
                  return <>
                    <div id={item} style={{ fontWeight: 500, fontSize: '18px' }}>
                      {/* <Card body
          // style={{ backgroundColor: '#F2D5B9' }}
          >
            <CardTitle style={{ fontSize: 22, fontWeight: 500, }}>{`Part ${item}`}</CardTitle>
            <CardTitle style={{ fontSize: 18, fontWeight: 500 }}>{directions[item]}</CardTitle>
          </Card> */}
                      <Segment color='black' style={{ fontWeight: 500, fontSize: '18px' }}>{`Part ${item}`}<br />{directions[item]}</Segment>
                      {data1.filter(item2 => item2.part === item).map((item2, index) => {
                        const handleGroup = (group, type = 1) => {
                          if (group !== groupCount && groupCount !== 'NONE') {
                            groupCount = group
                            return <>
                              <Divider horizontal>
                                <Icon name='circle' />
                              </Divider>
                              <br />
                            </>
                          } else {
                            groupCount = group
                            return <></>
                          }
                        }
                        return <>
                          {item2.group ? handleGroup(item2.group) : null}
                          <FormGroup tag="fieldset" style={{ marginLeft: '40px' }}>
                            {(item2.part === '3' || item2.part === '4' || item2.part === '6' || item2.part === '7') && item2.file1 ? <>
                              <Image
                                // href={'#'}
                                src={item2 && item2.file1 && item2.file1.url}
                                style={{
                                  maxHeight: '400px',
                                  maxWidth: '400px',
                                }}
                              />
                              <br />
                              <br />
                            </> : null}
                            {(item2.part === '6' || item2.part === '7') && item2.article ?
                              <CustomInput
                                type='textarea'
                                value={item2.article}
                              /> : null}
                            <Row>

                              {/* <Icon name='sound' src='' link onClick={this.handleAudio} /> */}
                              {item2.part !== '5' && item2.part !== '6' && item2.part !== '7' ? <>
                                {gif !== index && item2.number !== gif ? <Image style={{ width: '60px', height: '50px', marginRight: '20px' }} src={'https://parse.infowin.com.tw/parse/files/parseServer/527c1257b7263d21c9b54a2683f1103b_1.jpeg'} />
                                  : <Image style={{ width: '60px', height: '50px', marginRight: '20px' }} src={'https://php.mmc.school.nz/archived/2015/101/robertcalvert/AS91073%20%28Website%29/images/speaker.gif'} />}
                              </> : null}
                              {item2.part !== '1' ? item2.number + '.' : null}
                              {item2.part === '1' ?
                                <FormGroup check >
                                  {item2.number + '.'}
                                  <CustomInput type="radio" name={item2.number} id={"A_" + item2.number} label={'(A) ' + (item2.optionA || '')} onChange={this.handleAnswer} />
                                  <CustomInput type="radio" name={item2.number} id={"B_" + item2.number} label={'(B) ' + (item2.optionB || '')} onChange={this.handleAnswer} />
                                  <CustomInput type="radio" name={item2.number} id={"C_" + item2.number} label={'(C) ' + (item2.optionC || '')} onChange={this.handleAnswer} />
                                  <CustomInput type="radio" name={item2.number} id={"D_" + item2.number} label={'(D) ' + (item2.optionD || '')} onChange={this.handleAnswer} />
                                </FormGroup> : null}
                              <Col>
                                <FormGroup check>
                                  {/* <Label check> */}
                                  {/* <Input type="radio" name="radio" />{' '} */}
                                  {/* <Segment>Pellentesque habitant morbi tristique senectus.</Segment> */}
                                  {item2.part === '1' ? <>
                                    <Image
                                      // href={'#'}
                                      src={item2 && item2.file1 && item2.file1.url}
                                      style={{
                                        maxHeight: '400px',
                                        maxWidth: '400px',
                                      }}
                                    />
                                  </> : item2.topic}   {/*  topic */}
                                  {/* </Label> */}
                                </FormGroup>
                                {item2.part !== '1' ? <FormGroup check >
                                  {/* <Label check> */}
                                  {/* {item2.part === '1' || item2.part === '2' ? <></> : <> */}
                                  <CustomInput type="radio" name={item2.number} id={"A_" + item2.number} label={'(A) ' + (item2.optionA || '')} onChange={this.handleAnswer} />
                                  {/* {'(A)'} */}
                                  {/* {item2.optionA} */}
                                  {/* </>}    optionA */}
                                  {/* </Label> */}
                                  {/* </FormGroup> */}
                                  {/* <FormGroup check> */}
                                  {/* <Label check> */}
                                  {/* {item2.part === '1' || item2.part === '2' ? <></> : */}
                                  {/* <> */}
                                  <CustomInput type="radio" name={item2.number} id={"B_" + item2.number} label={'(B) ' + (item2.optionB || '')} onChange={this.handleAnswer} />
                                  {/* {' '} */}
                                  {/* {'(B) ' + item2.optionB} */}
                                  {/* </>}    optionB */}
                                  {/* </Label> */}
                                  {/* </FormGroup> */}
                                  {/* <FormGroup check > */}
                                  {/* <Label check> */}
                                  {/* {item2.part === '1' || item2.part === '2' ? <></> : */}
                                  {/* <> */}
                                  <CustomInput type="radio" name={item2.number} id={"C_" + item2.number} label={'(C) ' + (item2.optionC || '')} onChange={this.handleAnswer} />
                                  {/* {' '} */}
                                  {/* {'(C) ' + item2.optionC} */}
                                  {/* </>}    optionC */}
                                  {/* </Label> */}
                                  {/* </FormGroup> */}
                                  {/* <FormGroup check > */}
                                  {/* <Label check> */}
                                  {/* {item2.part === '1' || item2.part === '2' ? <></> : */}
                                  {/* <> */}
                                  <CustomInput type="radio" name={item2.number} id={"D_" + item2.number} label={'(D) ' + (item2.optionD || '')} onChange={this.handleAnswer} />
                                  {/* {'(D) ' + item2.optionD}</>}     optionD */}
                                  {/* </Label> */}
                                </FormGroup> : null}
                              </Col>
                            </Row>
                          </FormGroup>
                          <br />
                          <br />
                        </>
                      })}
                      <br />
                      <br />
                      {/* <Divider /> */}
                    </div>
                  </>
                })}
            </> : null}
            <Row>
              {start ? <Button floated='right' primary size='large' onClick={this.handleStop}>提交</Button> : null}
            </Row>
          </> : <Segment style={{ textAlign: 'center', fontWeight: 500, fontSize: '18px' }}>
              恭喜完成測驗<br />
              本次測驗耗時：{wasteTime}<br />
              分數：{score}分
            </Segment>
          }
        </Container>
      </Ref>
      <Modal
        isOpen={show}
        toggle={this.handleClose}
        size="lg"
        // className="mh-80 h-80 mw-80 w-80"
        style={{ paddingTop: '0px' }}
        scrollable={true}
      >
        <ModalHeader className="justify-content-center">測驗注意</ModalHeader>
        <ModalBody style={{ padding: "20px 30px" }}>
          <Segment style={{ fontSize: '18px', textAlign: 'center' }} >歡迎參加多益測驗，<br />
              本次測試時間為2個小時，<br />
              在開始測試驗之前，<br />
              請確保您有完成測試的足夠時間，<br />
              分成聽力和閱讀兩個部分，<br />
              請確保您的電腦的音效功能正常運作。<br />
              祝您考試測驗順利！<br />
          </Segment>
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'center', padding: '16px' }}>
          <Button
            primary
            onClick={this.handleStart}
            content="測驗開始"
          // loading={loading}
          />
        </ModalFooter>
      </Modal>
    </>)
  }
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     createProject: (project) => dispatch(createProject(project))
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HostIndex)
export default Toeic