import React, { Component } from 'react';
import { Tab, ListGroup, Form } from 'semantic-ui-react';

import List1Student from './List/List1Student';
import List2Student from './List/List2Student';
import List3Student from './List/List3Student';
import List4Student from './List/List4Student';
// import List5Student from './List/List5Student';
import List6Student from './List/List6Student';
// import List6Student_2 from './List/List6Student.2';
// import List15Student from './List/List15Student';
import TestStudent from './List/TestStudent';
// import ExamStudent from './List/ExamStudent';
import Jpn22 from './List/Jpn22';
import Table13 from './List/Table13';
import Table39 from './List/Table39';
import Table39List from './List/Table39List';
import DocumentStudent from './List/DocumentStudent';
import GraduateStudent from './List/GraduateStudent';
import InquiryStudent from './List/InquiryStudent';
// import TestReport from './List/TestReport';
// import StudentRecord2 from './List/StudentRecord2';
import AttendanceDaily from './List/AttendanceDaily';
import StudentRecord from './List/StudentRecord';
// import AttendanceCert from './List/AttendanceCert';
import Dorm2Student from './List/Dorm2Student';

import SupportStudent from './List/SupportStudent';
import RelativeStudent from './List/RelativeStudent';
import AgentStudent from './List/AgentStudent';
// import Transcript from './List/Transcript';
import TranscriptAndAttendance from './List/TranscriptAndAttendance';
// import Attendance from './List/Attendance';
import Parttime from './List/Parttime';
import AttendanceWeekly from './List/AttendanceWeekly';
import AttendancePrediction from './List/AttendancePrediction';
import HostPrint from '../../widgets/HostPrint';
// import ExamPage from '../academicAffair/ExamTable';
import ExamStudent from './List/ExamStudent';
// import StudentRecord2 from './List/StudentRecord2';
import DormStudent from './List/DormStudent';
// import StudentStatus from './List/StudentStatus';
// import StayPlan from './List/StayPlan';
// import ExamList from './List/ExamList';
import TestChart from './List/TestChart';
// import PagePrint from './../../components/ModalPdf/PagePrint';

const size = 'small';
const TabRender = ({ component, ...rest }) => <Tab.Pane><Form size={size}>{React.createElement(component, { ...rest })}</Form></Tab.Pane>;

export default class ListTabs2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: props.match.params.activeIndex2 || '0',
      activeIndex1: props.activeIndex1
    };
  }

  componentWillReceiveProps(nextProps) {
    const { activeIndex1 } = nextProps
    if (nextProps.activeIndex1 !== this.state.activeIndex1) {
      this.setState({ activeIndex: '0', activeIndex1 });
    }
    if (nextProps.doc !== this.props.doc) {
      // console.log(nextProps.doc)
      this.setState({ studentObj: nextProps.doc });
    }
  }

  handleTabChange = (e, { activeIndex }) => {
    const { match, doc, activeIndex1 = '0' } = this.props
    const { list = "1" } = match.params;
    activeIndex = activeIndex.toString()
    if (activeIndex) {
      this.props.history.push(`/admin/StudentPage/${list}/${doc.objectId}/${activeIndex1}/${activeIndex}`);
    }
    this.setState({ activeIndex })
  }
  panes = [
    [
      { menuItem: 'a 本人基本情報', render: () => <TabRender {...this.props} component={List1Student} /> },
      { menuItem: 'b 旅券&在留登録', render: () => <TabRender {...this.props} component={List2Student} /> },
      { menuItem: 'c 学歴/日本語学習歴', render: () => <TabRender {...this.props} component={List3Student} /> },
      { menuItem: 'd 親族', render: () => <TabRender {...this.props} component={RelativeStudent} /> },
      { menuItem: 'e 経費支弁者', render: () => <TabRender {...this.props} component={SupportStudent} /> },
      { menuItem: 'f 推薦者', render: () => <TabRender {...this.props} component={AgentStudent} /> },
      // { menuItem: '試験結果', render: () => <TabRender {...this.props} component={ExamPage} /> },
      // { menuItem: 'j 進路調查/受験進学', render: () => <TabRender {...this.props} component={GraduateStudent} /> },
      // { menuItem: 'k 定期テスト/飛び級状況', render: () => <TabRender {...this.props} component={TestStudent} /> },
      { menuItem: 'g 備考', render: () => <TabRender {...this.props} component={List6Student} /> },
    ], [
      { menuItem: 'a 定期テスト', render: () => <TabRender {...this.props} component={TestStudent} /> },
      { menuItem: 'b 校外試験', render: () => <TabRender {...this.props} component={ExamStudent} /> },
      { menuItem: 'c 進路調查', render: () => <TabRender {...this.props} component={GraduateStudent} /> },
      { menuItem: 'd 学籍名簿', render: () => <TabRender {...this.props} component={StudentRecord} /> },
    ], [
      { menuItem: 'a 資格外活動', render: () => <TabRender {...this.props} component={Parttime} /> },
      { menuItem: 'b 自転車', render: () => <TabRender {...this.props} component={List4Student} /> },
      { menuItem: 'c 週別出席一覧表', render: () => <TabRender {...this.props} component={AttendanceDaily} /> },
      { menuItem: 'd 出席率予測', render: () => <TabRender {...this.props} component={AttendancePrediction} /> },
      { menuItem: 'e 出席率個人票', render: () => <TabRender {...this.props} component={AttendanceWeekly} /> },
    ], [
      { menuItem: 'a 学業成績及び出席状況報告書', render: () => <TabRender {...this.props} component={Jpn22} /> },
      { menuItem: 'b 学生試験成績及び出席率一覧表', render: () => <TabRender {...this.props} component={Table13} /> },
      { menuItem: 'c 成績・出席証明書', render: () => <TabRender {...this.props} component={TranscriptAndAttendance} /> },
      { menuItem: 'd 成績．出席率通知表', render: () => <TabRender {...this.props} component={TestChart} /> },
      // { menuItem: 'd 学生指導票', render: () => <TabRender {...this.props} component={StudentRecord2} /> },
      // ], [
      //   { menuItem: 'a 在籍状況', render: () => <TabRender {...this.props} component={StudentStatus} /> },
      // ], [
    ], [
      { menuItem: 'a 特別指導学生週間報告書', render: () => <TabRender {...this.props} component={Table39} /> },
      // { menuItem: 'b 特別指導学生一覧', render: () => <TabRender {...this.props} component={Table39List} /> },
    ], [
      //   { menuItem: 'a 校外リスト一覧', render: () => <TabRender {...this.props} component={ExamList} /> },
      // ], [
      //   { menuItem: 'a 在留予定一覧', render: () => <TabRender {...this.props} component={StayPlan} /> },
      // ], [
      { menuItem: 'a 相談記録', render: () => <TabRender {...this.props} component={InquiryStudent} /> },
      { menuItem: 'b 学生ファイル', render: () => <TabRender {...this.props} component={DocumentStudent} /> },
    ], [
      { menuItem: 'a 予想', render: () => <TabRender {...this.props} component={DormStudent} /> },
      { menuItem: 'b 安排', render: () => <TabRender {...this.props} component={Dorm2Student} /> },
      { menuItem: 'c 収費', render: () => <TabRender {...this.props} component={Dorm2Student} /> },
    ], [
      { menuItem: 'a 学務個人書類印刷', render: () => <TabRender {...this.props} component={HostPrint} dirkey="studentP" studentObj={this.props.doc} /> },
      { menuItem: 'a 教務個人書類印刷', render: () => <TabRender {...this.props} component={HostPrint} dirkey="studentPTeach" studentObj={this.props.doc} /> },
      // { menuItem: 'b 教務個人書類印刷', render: () => <TabRender {...this.props} component={HostPrint} dirkey="studentPTeach" studentObj={this.props.doc} /> },
      // { menuItem: 'b 学生証', render: () => <TabRender {...this.props} component={List6Student} /> },
    ]
  ];
  render() {
    const { activeIndex1, companyObj } = this.props;
    const { activeIndex } = this.state;

    // console.log(this.panes[activeIndex1].length, Number(activeIndex), activeIndex.toString())
    return (
      // <Form>
      <Tab
        // grid={{ paneWidth: 13, tabWidth: 3 }}
        // activeIndex={this.panes[activeIndex1].length > Number(activeIndex) ? "0" : activeIndex} 
        activeIndex={activeIndex}
        onTabChange={this.handleTabChange}
        // menu={{ fluid: true, vertical: true }}
        menu={{ pointing: true, inverted: companyObj.inverted }}
        panes={this.panes[activeIndex1]} />
      // </Form >
    );
  }
}