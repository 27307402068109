/*eslint-disable*/
import React from "react";
import { withCookies } from 'react-cookie';

// reactstrap components
import { Container, Row } from "reactstrap";


const FooterBlack = (props = {}) => {
  const { form, webimageArr, cookies } = props
  const { text2 } = form
  const data = webimageArr.filter(item => item.componentId === form.componentId)
  const dbLang = cookies.get('dbLang') || 'name';
  // console.log(data)
  return (<>
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              {data.map((item, index) =>
                <li>
                  <a
                    href={item.url}
                    target="_blank"
                    className="mr-1"
                  >
                    {item[`title1${dbLang}`]}
                  </a>
                </li>
              )}
            </ul>
          </nav>
          <div className="credits ml-auto">
            <nav className="footer-nav">
              <ul>
                {/* <li>
                    &nbsp;
                  </li> */}
                {/* <li>
                    <span className="" onClick={() => changeLang('en-US')}>
                      English
              </span>
                  </li>
                  <li>
                    <span className="" onClick={() => changeLang('ja-JP')}>
                      日本語
                      </span>
                  </li>
                  <li>
                    <span className="" onClick={() => changeLang('zh-TW')}>
                      繁體中文
              </span>
                  </li>
                  <li>
                    <span className="" onClick={() => changeLang('zh-CN')}>
                      簡體中文
              </span>
                  </li> */}
                <li>
                  <span className="copyright">
                    © {new Date().getFullYear()}
                    {text2}
                    <i className="fa fa-heart heart" />
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </Row>
      </Container>
    </footer>
  </>
  );
}

// export default FooterBlack;
export default withCookies(FooterBlack);