import React, { Component } from 'react'
import { Container, Button, Grid, Form } from 'semantic-ui-react';
// import MaterialTable from 'material-table'

import { Stage, Layer, Rect, Text } from 'react-konva';
import Konva from 'konva';

// import localization from 'widget/MaterialTableOpt'
// import Parse from 'widget/parse'
// import { dateCol, typeCurrency, monthOpt, mgyearOpt, exportPdf } from "views/web/CustomMaterialTable";
import ZiWei from './ziweicore';
import ZwKonva from './ZwKonva';
import { ZhiGB } from './lunar'
// import { EarthlyBranches } from './ziweistar'

const d = new Date();

const options = [
  { key: 'M', text: '男', value: 'M' },
  { key: 'F', text: '女', value: 'F' },
]

const addOption = (id, a, b) => {
  const arr = [];
  for (let i = a; i <= b; i++) {
    arr.push({ key: id + i, text: i, value: i })
  }
  return arr
}

export default class Cal extends Component {
  state = {
    // dataArr: [],
    // dataArr1: [],
    // dataArr2: [],
    // rowData: {},
    // roomObj: {},
    // loading: true,
    // monthSel: String(d.getMonth() + 1),
    // mgyearSel: String(d.getFullYear() - 1911),
    Place12: null,
    ziWei: {},
    year: 1984,
    month: 6,
    day: 18,
    hour: '午',
    gender: 'M'
  }
  componentDidMount() {
    this.handleFetch();

    this.calZiWei()
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async () => {
    const { companyObj } = this.props;
  }

  calZiWei = () => {
    const { year, month, day, hour, gender } = this.state;

    const ziWei = new ZiWei()
    // console.log(ziWei.computeZiWei(year, month, day, hour, gender));
    // console.log(ziWei.getYinYangGender());
    // console.log(ziWei.getSolarDay());
    // console.log(ziWei.getLunarDay());
    // console.log(ziWei.getDaShian());
    // console.log(ziWei.getFiveElement());

    this.setState({
      ziWei: {
        arr: ziWei.computeZiWei(year, month, day, hour, gender),
        gender: ziWei.getYinYangGender(),
        solarDay: ziWei.getSolarDay(),
        lunarDay: ziWei.getLunarDay(),
        daShian: ziWei.getDaShian(),
        five: ziWei.getFiveElement()
      }
    })
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    this.setState({ [name]: value }, () => this.calZiWei());
  }

  render() {
    // const { firebase, companyObj } = this.props;
    const { ziWei, year, month, day, hour, gender } = this.state;

    console.log(ZhiGB)

    return (<div className="content">
      {/* <Header as='h2' style={{ margin: '2rem 0' }}>客房資料</Header> */}
      {/* 1234 */}
      {/* <Button onClick={this.calZiWei} >test</Button> */}
      {/* <br /> */}
      {/* <br /> */}
      <Form size='small' style={{ width: '800px' }}>
        <Form.Group widths='equal'>
          {/* <Form.Input fluid label='First name' placeholder='First name' /> */}
          {/* <Form.Input fluid label='Last name' placeholder='Last name' /> */}
          <Form.Select
            fluid
            label='國曆 年'
            options={addOption('year', 1900, 2049)}
            placeholder='年'
            name="year"
            value={year}
            onChange={this.handleChange}
          />
          <Form.Select
            fluid
            label='月'
            options={addOption('month', 1, 12)}
            placeholder='月'
            name="month"
            value={month}
            onChange={this.handleChange}
          />
          <Form.Select
            fluid
            label='日'
            options={addOption('day', 1, 31)}
            placeholder='日'
            name="day"
            value={day}
            onChange={this.handleChange}
          />
          <Form.Select
            fluid
            label='時'
            options={ZhiGB.map(item => ({ key: item, text: item, value: item }))}
            placeholder='時'
            name="hour"
            value={hour}
            onChange={this.handleChange}
          />
          <Form.Select
            fluid
            label='姓別'
            options={options}
            placeholder='姓別'
            name="gender"
            value={gender}
            onChange={this.handleChange}
          />
        </Form.Group>
        {/* <Form.Button>Submit</Form.Button> */}
      </Form>
      {/* <br /> */}
      <ZwKonva ziWei={ziWei} />
    </div>)
  }
}