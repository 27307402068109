
import React, { Component } from 'react'
import { Grid, Form, Table, Divider, Select } from 'semantic-ui-react';
// import MaterialTable from 'material-table'
import { Bar } from 'react-chartjs-2';
// import Parse from 'parse';
import { dateCol, mgyearOpt, monthOpt, codeOpt } from 'views/web/CustomMaterialTable';

// const payStatusOpt = [
//   { key: 'all', text: '全部', value: '-1' },
//   { key: 'paid', text: '已繳清', value: '0' },
//   { key: 'unpaid', text: '未繳清', value: '1' },
// ]

const pray1StatData1 = (label, data, receivedSel) => ({
  labels: label,
  datasets: [
    {
      label: receivedType[receivedSel] || '總收入',
      backgroundColor: 'rgba(87, 171, 255, 1)',
      borderColor: 'rgba(87, 171, 255, 1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(87, 171, 255,1)',
      hoverBorderColor: 'rgba(87, 171, 255,1)',
      data
    }
  ]
})

const receivedOpt = [
  { key: '1', value: '1', text: '應收' },
  { key: '2', value: '2', text: '已收' },
  { key: '3', value: '3', text: '未收' },
]
const receivedType = { '1': '應收', '2': '已收', '3': '未收' }

class Statistic1 extends Component {
  state = {
    mgyearId: '-1',
    pray1Id: '-1',
    paystatusId: '-1',
    arrStat: [],

    data1: [],
    data2: [],
    prayArr1: [],
    prayArr2: [],
    praySel: [],
    yearArr: [],
    yearSel: [],
    prayYearSelValue: '-1',
    praySelValue1: '-1',
    praySelValue2: '-1',

    allReceivableLabel: [],
    allReceivableData: [],
    allReceivableLabel2: [],
    allReceivableData2: [],

    receivedSel: '-1',
    mgyearSel: '-1'


  }
  async componentDidMount() {
    const { companyObj } = this.props;

    /*

      // const query = new Parse.Query("praying1");

      // 下面 db.getCollection('praying1') 可能要換成 const data1 = query

      db.getCollection('praying1').aggregate([
      { $match : { companyId : { $eq : "IaFBUo2J16" } } },    //  過濾數據(符合companyId) 操作符種類=> == : $eq , > : $gt , >= : $gte , < : $lt ,  <= : $lte , != : $ne       IaFBUo2J16 是阿貲達顯的companyId
      { $group: { _id: "$year", totalAccReceivable: { $sum: "$accReceivable" } } },
      { $sort : { _id : 1} }
      ])

    */

    const data1 = [ // 暫時的數據
      {
        "_id": "103",
        "totalAccReceivable": 680900
      },
      {
        "_id": "104",
        "totalAccReceivable": 680900
      },
      {
        "_id": "105",
        "totalAccReceivable": 680900
      },
      {
        "_id": "106",
        "totalAccReceivable": 680900
      },
      {
        "_id": "107",
        "totalAccReceivable": 1393300
      },
      {
        "_id": "108",
        "totalAccReceivable": 1641350
      },
      {
        "_id": "109",
        "totalAccReceivable": 205201
      }
    ];

    const yearSel = [{ key: '-1', text: '全部', value: '-1' }]
    data1.forEach((item, index) => yearSel.push({ key: index + '', text: item._id, value: index + '' }));


    /*

      const query = new Parse.Query("praying1");

      下面 db.getCollection('praying1') 可能要換成 const data2 = query

      db.getCollection('praying1').aggregate([
        { $match : { companyId : { $eq : "IaFBUo2J16" } } },   
        { $group: { _id: "$pray2Text", totalAccReceivable: { $sum: "$accReceivable" } } },
        { $sort : { _id : 1} }
        ])

    */

    const data2 = [ // 暫時的數據
      {
        "_id": "供花果",
        "totalAccReceivable": 1200
      },
      {
        "_id": "個人",
        "totalAccReceivable": 348970
      },
      {
        "_id": "吉祥",
        "totalAccReceivable": 25000
      },
      {
        "_id": "吉祥功德主",
        "totalAccReceivable": 371800
      },
      {
        "_id": "圓滿",
        "totalAccReceivable": 24001
      },
      {
        "_id": "圓滿功德主",
        "totalAccReceivable": 651280
      },
      {
        "_id": "如意",
        "totalAccReceivable": 5000
      },
      {
        "_id": "如意功德主",
        "totalAccReceivable": 411800
      },
      {
        "_id": "福慧",
        "totalAccReceivable": 50000
      },
      {
        "_id": "福慧功德主",
        "totalAccReceivable": 815100
      },
      {
        "_id": "總功德主",
        "totalAccReceivable": 1216500
      },
      {
        "_id": "點燈",
        "totalAccReceivable": 100
      },
    ];

    /*

      // const query = new Parse.Query("pray1");

      // 下面 db.getCollection('pray1') 可能要換成 query
      db.getCollection('pray1').find({ companyId : "IaFBUo2J16" })  // IaFBUo2J16 是阿貲達顯的companyId

    */

    const prayArr = [
      {
        "_id": "6ccghnRECs",
        "now_num": "",
        "now_use": "0",
        "companyId": "IaFBUo2J16",
        "value": "大悲救苦自解脫超度法會",
        "id": "6ccghnRECs",
        "ps": "",
        "companyName": "阿貲達顯密佛學會",
        "order": 1
      },
      {
        "_id": "q2Zp2bkTC9",
        "companyId": "IaFBUo2J16",
        "value": "藥師佛",
        "id": "q2Zp2bkTC9",
        "ps": "",
        "companyName": "阿貲達顯密佛學會",
        "now_num": 139,
        "now_use": "0",
        "order": 2,
        "_updated_at": "2020-06-02T02:53:29.830Z",
        "tableData": {
          "id": 1
        }
      }
    ]

    const praySel = [{ key: '-1', text: '全部', value: '-1' }];
    prayArr.forEach((item, index) => praySel.push({ key: index + '', text: item.value, value: index + '' }));

    this.setState({ data1, data2, yearSel, prayArr, praySel, loading: false }, () => this.handleShowGraph());

    console.log(yearSel)

    //======= 之前寫的 ======start

    // const companyId = companyObj.objectId
    // const data1 = await Parse.queryData('praying1', { companyId });

    // const db = Parse.Object.extend("praying1");

    // const query = new Parse.Query(db);
    // query.equalTo("companyId", companyObj.objectId);

    // const total = await query.count();

    // if (params.sorter) {
    //   const s = params.sorter.split('_');

    //   if (s[1] === 'descend') {
    //     query.descending(s[0]);
    //   } else {
    //     query.ascending(s[0]);
    //   }

    //   // Sorts the results in descending order by the score field
    // }
    // query.limit(pageSize);
    // query.skip(((current) - 1) * (pageSize));
    // const snap = await query.find();
    // const data1 = snap.map(data => data.toJSON());

    //======= 之前寫的 ======end

    // this.setState({ yearData: data1, loading: false }, () => this.handleShowGraph());

  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value, loading: true }, () => this.handleData());
  }

  handleData = () => {

    const { prayYearSelValue, praySelValue1, praySelValue2, praySel, mgyearSel } = this.state

    //這邊會依照select去更新data1和data2的資料（從資料庫直接抓） 再到handleShowGraph去把圖表要的資料抽出來



    if (mgyearSel === '-1') {    // 沒選擇法會

      // const query = new Parse.Query("praying1");

      // 下面 db.getCollection('praying1') 可能要換成 const data1 = query

      // db.getCollection('praying1').aggregate([
      //   { $match : { companyId : { $eq : "IaFBUo2J16" } } },   
      //   { $group: { _id: "$year", totalAccReceivable: { $sum: "$accReceivable" } } },
      //   { $sort : { _id : 1} }
      //   ])
      const data1 = [ // 暫時的數據
        {
          "_id": "103",
          "totalAccReceivable": 680900
        },
        {
          "_id": "104",
          "totalAccReceivable": 680900
        },
        {
          "_id": "105",
          "totalAccReceivable": 680900
        },
        {
          "_id": "106",
          "totalAccReceivable": 680900
        },
        {
          "_id": "107",
          "totalAccReceivable": 1393300
        },
        {
          "_id": "108",
          "totalAccReceivable": 1641350
        },
        {
          "_id": "109",
          "totalAccReceivable": 205201
        }
      ];
      this.setState({ data1, loading: false }, () => this.handleShowGraph());
    }
    else {      // 有選擇法會

      const data1 = [ // 暫時的數據
        {
          "_id": "一月",
          "totalAccReceivable": 680900
        },
        {
          "_id": "二月",
          "totalAccReceivable": 680900
        },
        {
          "_id": "三月",
          "totalAccReceivable": 680900
        },
        {
          "_id": "四月",
          "totalAccReceivable": 680900
        },
        {
          "_id": "五月",
          "totalAccReceivable": 1393300
        },
        {
          "_id": "六月",
          "totalAccReceivable": 1641350
        },
        {
          "_id": "七月",
          "totalAccReceivable": 205201
        },
        {
          "_id": "八月",
          "totalAccReceivable": 1641350
        },
        {
          "_id": "九月",
          "totalAccReceivable": 1641350
        },
        {
          "_id": "十月",
          "totalAccReceivable": 1641350
        },
        {
          "_id": "十一月",
          "totalAccReceivable": 1641350
        },
        {
          "_id": "十二月",
          "totalAccReceivable": 1641350
        }
      ];
      this.setState({ data1, loading: false }, () => this.handleShowGraph());
      // const query = new Parse.Query("praying1");

      // 下面 db.getCollection('praying1') 可能要換成 const data1 = query

      // db.getCollection('praying1').aggregate([
      //   { $match: { companyId: { $eq: "IaFBUo2J16" }, pray1Text: { $eq: praySel[praySelValue1].text } } },
      //   { $group: { _id: "$year", totalAccReceivable: { $sum: "$accReceivable" } } },
      //   { $sort: { _id: 1 } }
      // ])


    }

    const data2 = [  // 暫時的資料
      {
        "_id": "個人",
        "totalAccReceivable": 139070
      },
      {
        "_id": "吉祥功德主",
        "totalAccReceivable": 150900
      },
      {
        "_id": "圓滿功德主",
        "totalAccReceivable": 221380
      },
      {
        "_id": "如意功德主",
        "totalAccReceivable": 178500
      },
      {
        "_id": "福慧功德主",
        "totalAccReceivable": 250000
      },
      {
        "_id": "總功德主",
        "totalAccReceivable": 701500
      }
    ];

    if (praySelValue2 == '-1' && prayYearSelValue == '-1') { // 都沒選擇

      // const query = new Parse.Query("praying1");

      // 下面 db.getCollection('praying1') 可能要換成 const data2 = query

      // db.getCollection('praying1').aggregate([
      //   { $match : { companyId : { $eq : "IaFBUo2J16" } } },   
      //   { $group: { _id: "$pray2Text", totalAccReceivable: { $sum: "$accReceivable" } } },
      //   { $sort : { _id : 1} }
      //   ])

    }
    else if (praySelValue2 != '-1') {  // 有選擇法會
      // const query = new Parse.Query("praying1");

      // 下面 db.getCollection('praying1') 可能要換成 const data2 = query

      // db.getCollection('praying1').aggregate([
      //   { $match : { companyId : { $eq : "IaFBUo2J16" }, pray1Text : { $eq : praySel[praySelValue2].text } } },   
      //   { $group: { _id: "$pray2Text", totalAccReceivable: { $sum: "$accReceivable" } } },
      //   { $sort : { _id : 1} }
      //   ])
    }
    else if (prayYearSelValue != '-1') { // 有選擇年度
      // const query = new Parse.Query("praying1");

      // 下面 db.getCollection('praying1') 可能要換成 const data2 = query

      // db.getCollection('praying1').aggregate([
      //   { $match : { companyId : { $eq : "IaFBUo2J16" }, year : { $eq : yearSel[prayYearSelValue].text } } },   
      //   { $group: { _id: "$pray2Text", totalAccReceivable: { $sum: "$accReceivable" } } },
      //   { $sort : { _id : 1} }
      //   ])
    }
    else {   // 都有做選擇

      // const query = new Parse.Query("praying1");

      // 下面 db.getCollection('praying1') 可能要換成 const data2 = query

      // db.getCollection('praying1').aggregate([
      //   { $match : { companyId : { $eq : "IaFBUo2J16" }, year : { $eq : yearSel[prayYearSelValue].text }, pray1Text : { $eq : praySel[praySelValue2].text } } },   
      //   { $group: { _id: "$pray2Text", totalAccReceivable: { $sum: "$accReceivable" } } },
      //   { $sort : { _id : 1} }
      //   ])

    }

    // this.setState({ data1, data2, loading: false }, () => this.handleShowGraph());

  }

  handleShowGraph = () => {
    const { data1, data2, mgyearId, prayYearSelValue, praySelValue1, praySelValue2, } = this.state
    const allObj = {};

    // // 整理各年度加總的object
    // pray1Arr.forEach(item => {
    //   if (item.accReceivable) {
    //     const obj = item.accReceivable;
    //     Object.keys(obj).forEach(year => {
    //       allObj[year + ''] = (allObj[year + ''] || 0) + obj[year + ''];
    //     })
    //   }
    // })

    const allReceivableLabel = [];
    const allReceivableData = [];

    data1.forEach(item => {
      allReceivableLabel.push(item._id);
      allReceivableData.push(item.totalAccReceivable);
    })

    const allReceivableLabel2 = [];
    const allReceivableData2 = [];

    data2.forEach(item => {
      allReceivableLabel2.push(item._id);
      allReceivableData2.push(item.totalAccReceivable);
    })

    this.setState({ allReceivableLabel, allReceivableData, allReceivableLabel2, allReceivableData2 })


    // if (Object.keys(allObj).length) {
    //   // console.log(allObj);
    //   // allObj = {103: 176404000, 104: 8799000, 105: 7577500, 106: 5374000, 107: 5745000, 108: 4668500, 109: 740500}

    //   const allReceivableLabel = [];
    //   const allReceivableData = [];

    //   // 整理各年度陣列,金額陣列
    //   Object.keys(allObj).sort((a, b) => a - b).forEach(item => {
    //     allReceivableLabel.push(item + '')
    //     allReceivableData.push(allObj[item + ''])
    //   });

    //   this.setState({ allReceivableLabel: allReceivableLabel, allReceivableData: allReceivableData })
    //   /////////////////

    //   const arrStat = []
    //   pray1Arr.forEach(pray1Obj => {
    //     const receivableLabel = [];
    //     const receivableData = []
    //     Object.keys(pray1Obj.accReceivable).sort((a, b) => a - b).forEach(item => {
    //       receivableLabel.push(item + '')
    //       receivableData.push(pray1Obj.accReceivable[item])
    //     });
    //     arrStat.push({ pray1Name: pray1Obj.value, receivableLabel, receivableData })
    //   })

    //   this.setState({ arrStat })
    // }

  }
  // handleShowGraph = () => {
  //   const { pray1Arr, mgyearId } = this.state
  //   if (mgyearId === '-1' && pray1Arr.length) {
  //     const allObj = {};
  //     // 整理各年度加總的object
  //     pray1Arr.forEach(item => {
  //       if (item.accReceivable) {
  //         const obj = item.accReceivable;
  //         Object.keys(obj).forEach(year => {
  //           allObj[year + ''] = (allObj[year + ''] || 0) + obj[year + ''];
  //         })
  //       }
  //     })

  //     if (Object.keys(allObj).length) {
  //       // console.log(allObj);
  //       // allObj = {103: 176404000, 104: 8799000, 105: 7577500, 106: 5374000, 107: 5745000, 108: 4668500, 109: 740500}

  //       const allReceivableLabel = [];
  //       const allReceivableData = [];
  //       // 整理各年度陣列,金額陣列
  //       Object.keys(allObj).sort((a, b) => a - b).forEach(item => {
  //         allReceivableLabel.push(item + '')
  //         allReceivableData.push(allObj[item + ''])
  //       });

  //       this.setState({ allReceivableLabel: allReceivableLabel, allReceivableData: allReceivableData })
  //       /////////////////

  //       const arrStat = []
  //       pray1Arr.forEach(pray1Obj => {
  //         const receivableLabel = [];
  //         const receivableData = []
  //         Object.keys(pray1Obj.accReceivable).sort((a, b) => a - b).forEach(item => {
  //           receivableLabel.push(item + '')
  //           receivableData.push(pray1Obj.accReceivable[item])
  //         });
  //         arrStat.push({ pray1Name: pray1Obj.value, receivableLabel, receivableData })
  //       })

  //       this.setState({ arrStat })
  //     }
  //   } else {
  //     const allReceivableLabel = [];
  //     const allReceivableData = [];

  //     pray1Arr.forEach(pray1Obj => {
  //       if (pray1Obj.value) {
  //         allReceivableLabel.push(pray1Obj.value)
  //       }
  //       if (pray1Obj.accReceivable && pray1Obj.accReceivable[mgyearId]) {
  //         allReceivableData.push(pray1Obj.accReceivable[mgyearId])
  //       }
  //     })
  //     console.log(allReceivableData)
  //     this.setState({ allReceivableLabel, allReceivableData, arrStat: [] })
  //   }
  // }

  render() {
    const { mgyearId, pray1Id, paystatusId, loading, loadingBtn, allReceivableLabel, allReceivableData,
      arrStat, allReceivableLabel2, allReceivableData2, yearSel, praySel, praySelValue1, praySelValue2,
      prayYearSelValue, mgyearSel, receivedSel } = this.state;

    return (<div className="content">
      <Grid columns={2} divided style={{ margin: '20px' }}>
        <Grid.Row>
          <Grid.Column width={16}>統計圖表</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Form>
              <Form.Group inline>
                {/* <Form.Select label='年度' options={[{ key: 'aa', value: '-1', text: '全部' }].concat(mgyearOpt)} value={mgyearId} name='mgyearId' onChange={this.handleChange} />
                      <Form.Select label='法會' options={[{ key: 'aa', value: '-1', text: '全部' }].concat(pray1Opt)} value={pray1Id} name='pray1Id' onChange={this.handleChange} disabled='true' />
                      <Form.Select label='付款狀況' options={payStatusOpt} value={paystatusId} name='paystatusId' onChange={this.handleChange} disabled='true' />
                      <Form.Button color='blue' loading={loadingBtn} >重整資料</Form.Button> */}
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <h3>{mgyearId === '-1' ? '所有' : mgyearId}年度總收入</h3>
            <Select name="mgyearSel" style={{ position: 'relative', zIndex: 99 }} options={[{ key: 'aa', value: '-1', text: '全部' }].concat(mgyearOpt)} value={mgyearSel} onChange={this.handleChange} />
            <Select name="receivedSel" style={{ position: 'relative', zIndex: 99 }} options={[{ key: 'aa', value: '-1', text: '總收入' }].concat(receivedOpt)} value={receivedSel} onChange={this.handleChange} />

            <br />

            <Bar
              data={pray1StatData1(allReceivableLabel, allReceivableData, receivedSel)}
              height={200}
            />
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Table size='small'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>年度</Table.HeaderCell>
                  <Table.HeaderCell>{receivedType[receivedSel] || '總收入'}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {allReceivableLabel.length &&
                  allReceivableLabel.map((item, index) => (<Table.Row key={'arl' + index}>
                    <Table.Cell>{item}</Table.Cell>
                    <Table.Cell>{allReceivableData[index]}</Table.Cell>
                  </Table.Row>))}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {arrStat.map(item =>
            <Grid.Column computer={8} mobile={16}>
              <h2>{item.pray1Name}</h2>
              <Bar
                data={pray1StatData1(item.receivableLabel, item.receivableData, receivedSel)}
                height={200}
              />
            </Grid.Column>)}
        </Grid.Row>
      </Grid>
    </div>);
  }
}

export default Statistic1;
