import React, { Component } from 'react';
import { Form, Tabs, Table, Button, Modal, Grid, Header } from 'semantic-ui-react';
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';
import { Bar, Radar } from 'react-chartjs-2';
import Moment from 'moment';

import { showField, showFieldText, getAvailblePeriodArr, getOptionsByKey } from 'views/widgets/FieldsRender';
import Parse from '../../../../widget/parse'
const statXLabel = new Array(40).fill('').map((item, index) => (`${index * 10 && index * 10 + 1}-${(index + 1) * 10}`));

export default class TestChart extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      show: false,
      form: {},
      modalTitle: '',
      grade2: [],
      gradeObj: {},
      grade2Obj: {}
    };
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { match, companyObj } = props;
    const { _id } = match.params;

    const data = await Parse.queryData('Grade2', { companyId: companyObj.objectId, studentId: _id });
    this.setState({ grade2: data });

    // firebase.firestore()
    //   .collection(`Students/${_id}/Grade`)
    //   // .orderBy('relationship')
    //   // .where('eventDate', '==', eventDate).where('classname', '==', className)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ grade2: data });
    //   }, err => { console.log(`Encountered error: ${err}`); });
  }

  handleSubmit = async () => {
    const { firebase, doc, semesterYear, semester } = this.props;
    const { form, test } = this.state;
    const db = firebase.firestore();

    this.setState({ loading: true });
    const newTest = [...test];
    if (!form._id) {
      const docRef = db.collection(`Students/${doc._id}/Grade`).doc();
      const obj = {
        ...form,
        _id: docRef.id,
        studentId: doc._id,
        studentIdKey: doc.studentId,
        studentName: doc.jpnName,
        semesterYear,
        semester,
        createdAt: new Date()
      };
      await docRef.set(obj);
      newTest.push(obj);
    } else {
      await db.collection(`Students/${doc._id}/Grade`).doc(form._id).update(form);
      let index = newTest.findIndex((item => item._id === form._id));
      newTest[index] = form;
    }

    this.setState({ test: newTest, loading: false });
    this.handleClose();
  }

  handleClose = () => {
    this.setState({ show: false, form: {} });
  }

  handleShow = () => {
    this.setState({ show: true, modalTitle: '追加' });
  }
  editRowModal = (form) => {
    this.setState({ show: true, modalTitle: '保存', form });
  }

  handleChange = (event) => {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    this.setState({ form: { ...this.state.form, [fieldName]: fleldVal } })
  }

  handleSubmit = () => {
    // const method = this.state.form._id ? 'user.updateGrade2' : 'user.addGrade';
    const method = 'user.updateGrade2';
    const setting = this.state.form;
    setting.testType = '2';
  }

  removeRow = (doc) => {
    // if (!confirm("確認要削除嗎?")) {
    //   return;
    // }
  }

  convertImg = () => {
    let canvas = document.getElementById('chartBar');
    let canvas2 = document.getElementById('chartRadar');
    if (canvas && canvas2) {
      let imgURL = canvas.toDataURL();
      let imgURL2 = canvas2.toDataURL();
      this.setState({ imgURL, imgURL2 }, () => console.log(imgURL, imgURL2))
    } else {
      this.setState({ imgURL: '', imgURL2: '' })
    }

  }


  render() {
    const { formSet, optSet, isEdit, grade, doc } = this.props;
    const { grade2, gradeObj, grade2Obj, imgURL, imgURL2 } = this.state
    const classArr = getOptionsByKey(optSet, 'classSchool');
    const d = new Date();
    const arrPdf = [{
      grade2,
      gradeObj,
      grade2Obj,
      imgURL,
      imgURL2
    }]
    //console.log(arrPdf)
    return (
      <>
        <Header as='h2' dividing
          content='成績．出席率通知表'
          style={{ backgroundColor: 'lavender' }}
        // subheader='調査時間：2017年4月から 2018年12月 まで'
        />
        {/* <Button primary style={{ float: 'right' }} onClick={this.handleShow} icon='add'> 追加成績</Button> */}
        <Table celled>
          <Table.Header>
            <Table.Row textAlign='center'>
              <Table.HeaderCell></Table.HeaderCell>
              {/* <Table.HeaderCell style={{ minWidth: 120 }}></Table.HeaderCell> */}
              {/* <Table.HeaderCell style={{ minWidth: 120 }}></Table.HeaderCell> */}
              <Table.HeaderCell style={{}}>#</Table.HeaderCell>
              <Table.HeaderCell style={{}}>試験日</Table.HeaderCell>
              <Table.HeaderCell style={{}}>テストコード</Table.HeaderCell>
              <Table.HeaderCell style={{}}>試験レべル</Table.HeaderCell>
              <Table.HeaderCell style={{}}>文字語彙</Table.HeaderCell>
              <Table.HeaderCell style={{}}>聴解</Table.HeaderCell>
              <Table.HeaderCell style={{}}>文法</Table.HeaderCell>
              <Table.HeaderCell style={{}}>読解</Table.HeaderCell>
              <Table.HeaderCell style={{}}>作文</Table.HeaderCell>
              <Table.HeaderCell style={{}}>会話</Table.HeaderCell>
              <Table.HeaderCell style={{}}>作文評価</Table.HeaderCell>
              <Table.HeaderCell style={{}}>会話評価</Table.HeaderCell>
              <Table.HeaderCell style={{}}>元のクラス</Table.HeaderCell>
              <Table.HeaderCell style={{}}>新しいクラス</Table.HeaderCell>
              {/* <Table.HeaderCell style={{}}>飛び級状況</Table.HeaderCell> */}
              {/* <Table.HeaderCell></Table.HeaderCell> */}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {grade2.length ? grade2.map((item, index) => (<React.Fragment key={index}>
              <Table.Row>
                <Table.Cell style={{ padding: 5 }}>
                  <Button primary size='small'
                    onClick={() => this.setState({ grade2Obj: item, gradeObj: grade.find(item2 => item.gradeId === item2._id) })}
                    icon='file alternate' />
                </Table.Cell>
                <Table.Cell>{item.rank || ''}/{item.rankTotal || ''}</Table.Cell>
                <Table.Cell>{item.testDate}</Table.Cell>
                <Table.Cell>{item.testCode}</Table.Cell>
                <Table.Cell>{item.testLevel}</Table.Cell>
                <Table.Cell>{item.vocabularyScore}</Table.Cell>
                <Table.Cell>{item.listeningScore}</Table.Cell>
                <Table.Cell>{item.grammarScore}</Table.Cell>
                <Table.Cell>{item.readingScore}</Table.Cell>
                <Table.Cell>{item.writingOriScore}</Table.Cell>
                <Table.Cell>{item.conversationOriScore}</Table.Cell>
                <Table.Cell>{item.writingScore}</Table.Cell>
                <Table.Cell>{item.conversationScore}</Table.Cell>
                <Table.Cell>{item.oriClass}</Table.Cell>
                <Table.Cell>{item.newClass}</Table.Cell>
                {/* <Table.Cell>{item.isSkipGrade}</Table.Cell> */}
              </Table.Row>
            </React.Fragment>)) : <Table.Row>
                <Table.Cell colSpan='15' textAlign='center' >(資料無し)</Table.Cell>
              </Table.Row>}
          </Table.Body>
        </Table>
        {gradeObj && gradeObj._id ? <>
          {/* <ReactToPrint
            trigger={(e) => <Button
              content='印刷'
              // label='&nbsp;'
              floated='right'
              color='orange' icon='print'
              size='small'
            />}
            content={() => this.componentRef}
            pageStyle={"@page { size: auto;  margin: 10mm; }*{font-family: 'Noto Serif JP', serif;}"}
          /> */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div></div>
            <div style={{ display: 'flex' }}>
              <Button content='convertImg' onClick={() => this.convertImg()} />
              <label onClickCapture={this.convertImg}>
                <ModalPdf
                  {...this.props}
                  // ref={(ref) => { this.pdfModal = ref }}
                  codekey="testChartPdf"
                  params={arrPdf}
                  chart={true}
                />
                <div style={{ clear: 'both' }}></div>
              </label>
            </div>
          </div>

          <br />
          <div style={{ backgroundColor: '#fff', padding: '16px', marginTop: '10px' }}
            ref={el => (this.componentRef = el)} className="" >
            <Header as='h1' textAlign='center'>《愛知国際学院 {new Date(grade2Obj.testDate).getFullYear()} 年 {new Date(grade2Obj.testDate).getMonth() + 1}月 校内定期テスト成績．出席率通知表 》</Header>
            <Grid>
              <Grid.Row width="equal">
                <Grid.Column width={8}>
                  <div style={{ textAlign: 'center', fontSize: '20px', width: '80%', backgroundColor: '#ccc', padding: '16px', border: '1px solid #000' }}>
                    テスト成績(*1): {grade2Obj.totalScore} 点/400点満点
                    </div>
                </Grid.Column>
                <Grid.Column width={8}>
                  <div style={{ textAlign: 'center', fontSize: '20px', width: '80%', backgroundColor: '#ccc', padding: '16px', border: '1px solid #000' }}>
                    成績順位(*2): {grade2Obj.rank || ''} 位 / {grade2Obj.rankTotal || ''} 名中
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Header as='h3' >【各テスト成績】</Header>
                  <Table celled>
                    <Table.Header>
                      <Table.Row textAlign='center'>
                        <Table.HeaderCell style={{}}>クラス<br />(現在)</Table.HeaderCell>
                        <Table.HeaderCell style={{}}>学生番号</Table.HeaderCell>
                        <Table.HeaderCell style={{}}>名前</Table.HeaderCell>
                        <Table.HeaderCell style={{}}>文字語彙<br />(100点満点)</Table.HeaderCell>
                        <Table.HeaderCell style={{}}>聴解<br />(100点満点)</Table.HeaderCell>
                        <Table.HeaderCell style={{}}>文法<br />(100点満点)</Table.HeaderCell>
                        <Table.HeaderCell style={{}}>読解<br />(100点満点)</Table.HeaderCell>
                        <Table.HeaderCell style={{}}>合計<br />(400点満点)</Table.HeaderCell>
                        <Table.HeaderCell style={{}}>会話<br />(A～D)</Table.HeaderCell>
                        <Table.HeaderCell style={{}}>作文<br />(A～D)</Table.HeaderCell>
                        <Table.HeaderCell style={{}}>新クラス</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row textAlign='center'>
                        <Table.Cell>{grade2Obj.oriClass}</Table.Cell>
                        <Table.Cell>{doc.studentId}</Table.Cell>
                        <Table.Cell>{doc.jpnName}</Table.Cell>
                        <Table.Cell>{grade2Obj.vocabularyScore}</Table.Cell>
                        <Table.Cell>{grade2Obj.listeningScore}</Table.Cell>
                        <Table.Cell>{grade2Obj.grammarScore}</Table.Cell>
                        <Table.Cell>{grade2Obj.readingScore}</Table.Cell>
                        <Table.Cell>{grade2Obj.totalScore}</Table.Cell>
                        <Table.Cell>{grade2Obj.conversationScore || '-'}</Table.Cell>
                        <Table.Cell>{grade2Obj.writingScore || '-'}</Table.Cell>
                        <Table.Cell>{grade2Obj.newClass}</Table.Cell>
                      </Table.Row>
                      <Table.Row textAlign='center'>
                        <Table.Cell colSpan={3}>平均点</Table.Cell>
                        <Table.Cell>{gradeObj.avg.grammarAvg}</Table.Cell>
                        <Table.Cell>{gradeObj.avg.listeningAvg}</Table.Cell>
                        <Table.Cell>{gradeObj.avg.readingAvg}</Table.Cell>
                        <Table.Cell>{gradeObj.avg.vocabularyAvg}</Table.Cell>
                        <Table.Cell>{gradeObj.avg.totalAvg}</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                        <Table.Cell>A～Z</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Column width={11} style={{ height: '250px' }}>
                <Header as='h3' >【得点分布表】</Header>
                {gradeObj.statArr ?
                  <Bar
                    data={{
                      labels: statXLabel,
                      datasets: [
                        {
                          label: '人数',
                          backgroundColor: 'rgba(255,99,132,0.2)',
                          borderColor: 'rgba(255,99,132,1)',
                          borderWidth: 1,
                          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                          hoverBorderColor: 'rgba(255,99,132,1)',
                          data: gradeObj.statArr
                        }
                      ]
                    }}
                    width={400}
                    height={250}
                    options={{
                      maintainAspectRatio: false
                    }}
                    id='chartBar'
                  /> : null}
              </Grid.Column>
              <Grid.Column width={5}>
                <Header as='h3' >【成績評価チャート】</Header>
                <Radar
                  id='chartRadar'
                  width={300}
                  height={300}
                  data={{
                    labels: ['文字語彙', '聴解', '文法', '読解'],
                    datasets: [
                      {
                        label: '得点',
                        backgroundColor: 'rgba(219,237,252,0.4)',
                        borderColor: 'rgba(55,161,235,1)',
                        pointBackgroundColor: 'rgba(55,161,235,1)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(55,161,235,1)',
                        data: [grade2Obj.vocabularyScore, grade2Obj.listeningScore, grade2Obj.grammarScore, grade2Obj.readingScore]
                      }, {
                        label: '平均点',
                        backgroundColor: 'rgba(255,99,132,0.2)',
                        borderColor: 'rgba(255,99,132,1)',
                        pointBackgroundColor: 'rgba(255,99,132,1)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(255,99,132,1)',
                        data: [
                          gradeObj.avg.grammarAvg,
                          gradeObj.avg.listeningAvg,
                          gradeObj.avg.readingAvg,
                          gradeObj.avg.vocabularyAvg]
                      }, {
                        label: '満点',
                        backgroundColor: 'rgba(179,181,198,0.1)',
                        borderColor: 'rgba(179,181,198,1)',
                        pointBackgroundColor: 'rgba(179,181,198,1)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(179,181,198,1)',
                        data: [100, 100, 100, 100]
                      }
                    ],
                  }} />
              </Grid.Column>
            </Grid>
            {/* 成績 */}
          </div>
        </> : null}
        {/* </div> */}
        <Modal open={this.state.show} onClose={this.handleClose} size="small">
          <Modal.Header>{this.state.modalTitle}成績</Modal.Header>
          <Modal.Content>
            {/* <Form.Group >
              <label>項目</label>{'  '}
              <Form.Input as="select" placeholder="select" name="testName"
                onChange={this.handleChange} value={this.state.form.testName} >
                <option value="">-- 選択 --</option>
                {testStudentArr.sort((a, b) => b.value - a.value).map(({ _id, value, label }) => (<option key={_id} value={value}>{label}</option>))}
              </Form.Field>
            </Form.Group> */}
            {/* <Form.Group >
              <label>項目</label>{'  '}
              <Form.Input name="testName"
                onChange={this.handleChange} value={this.state.form.testName} >
              </Form.Field>
            </Form.Group> */}
            {/* <Form.Group >
              <label>文字語彙</label>
              <Form.Input name="vocabularyScore"
                onChange={this.handleChange} value={this.state.form.vocabularyScore} />
            </Form.Group>
            <Form.Group >
              <label>聴解</label>
              <Form.Input name="listeningScore"
                onChange={this.handleChange} value={this.state.form.listeningScore} />
            </Form.Group>
            <Form.Group >
              <label>文法</label>
              <Form.Input name="grammarScore"
                onChange={this.handleChange} value={this.state.form.grammarScore} />
            </Form.Group>
            <Form.Group >
              <label>読解</label>
              <Form.Input name="readingScore"
                onChange={this.handleChange} value={this.state.form.readingScore} />
            </Form.Group>
            <Form.Group >
              <label>作文評価</label>
              <Form.Input name="writingScore"
                onChange={this.handleChange} value={this.state.form.writingScore} />
            </Form.Group>
            <Form.Group >
              <label>会話評価</label>
              <Form.Input name="conversationScore"
                onChange={this.handleChange} value={this.state.form.conversationScore} />
            </Form.Group> */}
            {/* <Form.Group >
              <label>元のクラス</label>{'  '}
              <Form.Field as="select" placeholder="select" name="oriClass"
                onChange={this.handleChange} value={this.state.form.oriClass} >
                <option value="">-- 選択 --</option>
                {classArr.sort((a, b) => a.orderId - b.orderId).map(({ _id, label }) => (<option key={_id} value={label}>{label}</option>))}
              </Form.Field>
            </Form.Group> */}
            <Form.Group >
              <label>新しいクラス</label>{'  '}
              <Form.Field as="select" placeholder="select" name="newClass"
                onChange={this.handleChange} value={this.state.form.newClass} >
                <option value="">-- 選択 --</option>
                {classArr.sort((a, b) => a.orderId - b.orderId).map(({ _id, label }) => (<option key={_id} value={label}>{label}</option>))}
              </Form.Field>
            </Form.Group>
            <Form.Group >
              <label>飛び級状況</label>
              <Form.Input name="isSkipGrade"
                onChange={this.handleChange} value={this.state.form.isSkipGrade} />
            </Form.Group>

            {/* <Form.Group >
              <label>作文成績</label>{'  '}
              <Form.Field as="select" placeholder="select" name="courseTeacher"
                onChange={this.handleChange} value={this.state.form.courseTeacher} >
                <option value="">-- 選択 --</option>
                <option value="-1">(尚未安排)</option>
                {teachers.sort((a, b) => a.orderId - b.orderId).map(({ _id, nameTeacher, teacherTitleText, label }) => (<option key={_id} value={_id}>{teacherTitleText} {nameTeacher}</option>))}
              </Form.Field>
            </Form.Group> */}

            {/* <Form.Group >
              <label>自動機能</label>{'  '}
              <Form.Field as="select" placeholder="select" name="courseTeacher"
                onChange={this.handleChange} value={this.state.form.courseTeacher} >
                <option value="">-- 選択 --</option>
                <option value="-1">(尚未安排)</option>
                {teachers.sort((a, b) => a.orderId - b.orderId).map(({ _id, nameTeacher, teacherTitleText, label }) => (<option key={_id} value={_id}>{teacherTitleText} {nameTeacher}</option>))}
              </Form.Field>
            </Form.Group> */}

          </Modal.Content>
          <Modal.Content>
            <Button onClick={this.handleSubmit}>{this.state.modalTitle}</Button>
            <Button onClick={this.handleClose} icon='remove' content='キャンセル' />
          </Modal.Content>
        </Modal>
      </>
    );
  }
}