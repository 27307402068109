import React, { Component } from 'react';
import { Table, Container, Grid, Header, Form, Input, Label, Icon, Select } from 'semantic-ui-react';
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';
import AttendanceWeekly from './AttendanceWeekly';

const style = {
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px'
  }
}

export default class AttendanceWeeklyAll extends Component {
  constructor(props) {
    super(props);
    const nowTimestamp = Date.now()
    const nowObj = new Date();
    let nowYear = nowObj.getFullYear()
    let nowMonth = nowObj.getMonth() + 1  // getMonth()為0-11，所以要+1
    let lastMonth = nowMonth - 1 > 0 ? nowMonth - 1 : 12
    let lastMonthYear = lastMonth > nowMonth ? nowYear - 1 : nowYear
    let lastMonth2 = lastMonth - 1 > 0 ? lastMonth - 1 : 12
    let lastMonthYear2 = lastMonth2 > lastMonth ? lastMonthYear - 1 : lastMonthYear
    let onejan = new Date(nowObj.getFullYear(), 0, 1);
    let nowYearWeek = Math.ceil((((nowObj - onejan) / 86400000) + onejan.getDay() + 1) / 7);
    this.state = {
      classArr: [],
      selClassArr: [],
      printData: [],
      form: {},
      attendWeek: [],
      nowToISO: new Date(nowTimestamp).toISOString(),
      nowYear,
      nowMonth,
      lastMonth,
      lastMonthYear,
      lastMonth2,
      lastMonthYear2,
      countRelDay: 0,
      countRelHour: 0,
      countExpDay: 0,
      countExpHour: 0,
      lastWeekData: [],
      nowMonthData: [],
      lastMonthData: [],
      lastMonthData2: [],
      nowYearWeek,
    };
    // this.dailyAttendanceRate = [];
    // this.monthAttendanceRate = [];
  }

  componentDidMount() {
    // console.log('componentDidMount');
    // this.dailyAttendanceRate.forEach(item => {
    //   let rateNum = item.outerText.slice(0, -1);
    //   if (item && Number(rateNum) < 95) {
    //     item.bgColor = 'red';
    //   }
    // })
    // this.monthAttendanceRate.forEach(item => {
    //   let rateNum = item.outerText.slice(0, -1);
    //   if (item && Number(rateNum) < 90) {
    //     item.bgColor = 'red';
    //   }
    // })
    this.handleFetch();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.semesterYear !== this.props.semesterYear ||
      nextProps.semester !== this.props.semester ||
      nextProps.classId !== this.props.classId) {
      this.handleFetch(nextProps)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.printData !== this.state.printData) {
      return false
    }
    return true
  }

  componentDidUpdate() {
    // this.dailyAttendanceRate.forEach(item => {
    //   let rateNum = item.outerText.slice(0, -1);
    //   if (item && Number(rateNum) < 95) {
    //     item.bgColor = 'red';
    //   }
    // })
    // this.monthAttendanceRate.forEach(item => {
    //   let rateNum = item.outerText.slice(0, -1);
    //   if (item && Number(rateNum) < 90) {
    //     item.bgColor = 'red';
    //   }
    // })
  }

  handleFetch = (props = this.props) => {
    // const { classSchool } = this.state
    const { classroom, semesterYear, semester, students, classId } = props;
    const classObj = classroom.find(item => item.objectId === classId);
    const className = classObj.classSchool;
    // const classArr = classroom
    //   .filter(item => item.isEnableClass === true && item.semesterYear === semesterYear && item.semester === semester)
    //   .sort((a, b) => ('' + a.classSchool).localeCompare(b.classSchool));

    // if (classArr.length) {
    //   // const classId = classArr[0]._id;
    //   const selClassArr = [/*{ key: 'selClassNull', text: '-- 選択 --', value: ' ' }*/];
    //   if (!classArr.length) {
    //     selClassArr.push({ key: 'empty', text: '-- 無クラス --', value: '' })
    //   } else {
    //     classArr.forEach(({ _id, classSchool, teacherText, entryYear, entryText }) =>
    //       selClassArr.push({ key: _id, text: `${classSchool}クラス ${entryYear}年${entryText} ${teacherText || ''}`, value: classSchool }))
    //   }

    const classStudent = students.filter(item =>
      (item.classSchool === className && item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
      (item.applyResult === '1' || item.studentType === "2"));
    // console.log(selClassArr);
    this.setState({ classStudent })
    // , () => this.handleClassroom(props));
    // }
  }

  getPrintData = (studentObj) => {
    const { firebase, students, classSchool } = this.props;
    const { printData, classStudent, nowToISO, nowYear, nowMonth, lastMonth, lastMonthYear, lastMonth2, lastMonthYear2, nowYearWeek } = this.state
    let findIndex = printData.findIndex(item => item.objectId === studentObj.objectId)
    if (findIndex === -1)
      printData.push(studentObj)
    // if (classStudent.length === printData.length) {
    //   // this.setState({ printData })
    //   console.log(printData)
    // }
  }

  handleChange = (event, data) => {
    const { name, value, options } = data
    if (name === 'classId') {
      const classId = options.find(item => item.value === value).key
      this.setState({ classSchool: value, classId })
    } else {
      this.setState({ form: { ...this.state.form, [name]: value } })
      console.log(this.state.form);
    }
  }

  render() {
    // const { semesterYear, semester, doc, calendar, studentsInClass, students } = this.props;
    const { printData, classStudent, classSchool, selClassArr } = this.state

    return (<>
      <div ref={el => (this.componentRef = el)} className="" style={{ borderRadius: '.28571429rem', padding: '5px 0' }}>
        <Header as='h2' dividing
          content='クラス出席率個人票'
          style={{ backgroundColor: 'lavender' }}
        // subheader='調査時間：2017年4月から 2018年12月 まで'
        />
        <Grid >
          <Grid.Row>
            {/* <div style={{ ...style.flexCenter, float: 'right' }}> */}
            <Grid.Column style={{ float: 'right' }}>
              {/* <Input labelPosition='left' >
                <Label color={'blue'}> <Icon name='building' />クラス</Label>
                <Select style={{
                  minWidth: '300px',
                  borderTopLeftRadius: '0',
                  borderBottomLeftRadius: '0'
                }} compact options={selClassArr} onChange={this.handleChange} value={classSchool} name='classId' />
              </Input> */}
              <ModalPdf
                {...this.props}
                // ref={(ref) => { this.pdfModal = ref }}
                codekey="attendanceWeeklyAll"
                params={printData}
              />
              {/* </div> */}
            </Grid.Column>
          </Grid.Row>
          {classStudent && classStudent.map((item, index) => (
            <Grid.Row columns={"equal"} key={index}>
              <Grid.Column>
                <AttendanceWeekly
                  {...this.props}
                  _id={item._id}
                  studentId={item.studentId}
                  jpnName={item.jpnName}
                  all={true}
                  getPrintData={this.getPrintData}
                />
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      </div>
    </>);
  }
}