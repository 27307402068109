import React, { Component } from 'react'
import { Header, Form, Grid, Menu, Input, Dimmer, Loader, Image, Button, Confirm, FormGroup } from 'semantic-ui-react';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MaterialTable from 'material-table'
import {
  // Container,
  // Table,
  Row,
  Col,
} from "reactstrap";
import { DelayInput } from 'react-delay-input';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ModalNewClient from './ModalNewClient';
import ModalHandle from './ModalHandle';
import ClientTab from './ClientTab';

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

const fetchPeople = async (companyObj, params, searchText, user, employee) => {
  const { page = 1, pageSize = 5 } = params
  const { profile } = user
  // console.log(params, searchText)

  let query = new Parse.Query('clients');

  if (searchText) {
    const p0 = new Parse.Query("clients");
    // p0.fullText("name", searchText);
    p0.startsWith("name", searchText);

    const p1 = new Parse.Query("clients");
    p1.startsWith("cellnum", searchText);

    const p2 = new Parse.Query("clients");
    p2.startsWith("peopleIdNumber", searchText);

    query = Parse.Query.or(p0, p1, p2);
  }
  // query.select(['uid', 'name', 'residentialAddr', 'cellnum', 'residentialPhone', 'sexualId']);
  query.equalTo("companyId", companyObj.objectId);

  //抓自己經手的客戶  有管理者權限就不抓
  if (!employee.filter(item => item.authAdminA.find(item => item === companyObj.objectId))) {
    query.equalTo("employeeArr", profile.id);
  }
  const totalCount = await query.count();

  // if (params.sorter) {
  //   const s = params.sorter.split('_');

  //   if (s[1] === 'descend') {
  //     query.descending(s[0]);
  //   } else {
  //     query.ascending(s[0]);
  //   }

  //   // Sorts the results in descending order by the score field
  // }
  query.limit(pageSize);
  query.skip((Number(page)) * (pageSize));

  const snap = await query.find();
  const data = snap.map(data => data.toJSON());
  return { data, page, totalCount, }
}

class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      searchValue: '',
      objectId: '',
      clientObj: {},
      userAll: [],
      employee: []
    }
    this.tableRef = React.createRef();
  }

  async componentDidMount() {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data = await Parse.queryData('User', { authLoginA: companyId });
    this.setState({ employee: data }, () => this.handleFetch())

  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.match.params || nextProps.match.params.clientId !== this.props.match.params.clientId) {
      this.handleFetch(nextProps);
    }
  }

  handleFetch = async (props = this.props) => {
    const { match } = props;
    const { clientId } = match.params;
    if (clientId) {
      const clientObj = await Parse.fetchDocument("clients", clientId);
      this.setState({ clientObj })
    } else {
      this.setState({ clientObj: {} })
    }
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    this.setState({ loading: true });
    await Parse.saveData('clients', newData);
    this.setState({ loading: false }, () => this.tableRef.current.onQueryChange());
  }

  handleDelete = async () => {
    const { oldData } = this.state
    const clientId = oldData.objectId;
    this.setState({ loading: true });
    await Parse.deleteData('clients', oldData);
    await Parse.destroyData('pawning1', { clientId });
    await Parse.destroyData('pawning2', { clientId });
    this.setState({ loading: false, oldData: {} }, () => this.tableRef.current.onQueryChange())
  }

  handleChange = async (e, { name, value }) => {
    this.setState({ [name]: value }, () => this.tableRef.current.onQueryChange());
  }

  handleClickName = async (clientId) => {
    const { history, match } = this.props;
    const { t1 = 'p2' } = match.params;
    history.push(`/admin/Client/${clientId}/${t1}`)
  }

  clear = () => {
    this.ref.inputRef.current.value = '';
    this.setState({ searchValue: '' }, () => this.tableRef.current.onQueryChange())
  }

  render() {
    const { companyObj, user } = this.props
    const { searchValue, loading, clientObj, userAll, employee } = this.state;
    const userSel = userAll.sel || {}
    return (<>
      <div className="content">
        <div style={{ ...style.flexCenter, margin: '2rem auto' }}>
          <Row>
            <Col xs="8" sm="10">
              <Form>
                <FormGroup widths='equal'>
                  <DelayInput
                    inputRef={ref => { this.ref = ref; }}
                    name='searchValue'
                    placeholder='請輸入客戶姓名、身份證字號、手機'
                    style={{ width: '100%', minWidth: 350, float: 'left' }}
                    onChange={(e) => this.handleChange(e, { name: 'searchValue', value: e.target.value })}
                    // minLength={1}
                    delayTimeout={300}
                    action={{ icon: 'cancel', onClick: this.clear }}
                    element={Input}
                  />
                  <Button.Group style={{ marginLeft: '50px' }}>
                    <ModalNewClient  {...this.props} />
                  </Button.Group>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs="12" sm="10">
            <MaterialTable
              isLoading={loading}
              tableRef={this.tableRef}
              title="搜尋結果，點擊載入客戶名單"
              options={{
                search: false,
                tableLayout: 'fixed',
                pageSize: 10
              }}
              columns={[
                { width: 120, title: '客戶編號', field: 'uid' },
                { width: 50, title: '姓名', field: 'name' },
                // { width: 80, title: '性別', field: 'sexualId' },
                { width: 80, title: '手機', field: 'cellnum' },
                { width: 180, title: '地址', field: 'residentialAddr', cellStyle: { width: '250px' }, headerStyle: { width: '250px' } },
                {
                  width: 20, title: '選擇', field: 'live_select',
                  headerStyle: { textAlign: 'right' },
                  render: rowData => <></>,
                  editComponent: props => <ModalHandle rowData={props.rowData} employee={employee} onRowDataChange={props.onRowDataChange} />
                },
                {
                  width: 100, title: '業務', field: 'handle',
                  render: rowData => <>
                    {(rowData && rowData.jointObjArr && rowData.jointObjArr.length && rowData.jointObjArr.map(({ objectId, main, jobNum }, index) =>
                      <span key={objectId} >{index !== 0 ? ',' : null}{jobNum}</span>)) || null}</>,
                  editComponent: props => <>
                    {(props.rowData && props.rowData.jointObjArr && props.rowData.jointObjArr.length && props.rowData.jointObjArr.map(({ objectId, main, jobNum }, index) =>
                      <span key={objectId} >{index !== 0 ? ',' : null}{jobNum}</span>)) || null}</>
                },
              ]}
              data={query => {
                this.setState({ loading: true });
                return new Promise((resolve, reject) => {
                  fetchPeople(companyObj, query, searchValue, user, employee).then(result => {
                    this.setState({ loading: false }, () => resolve(result));
                  })
                })
              }}
              onRowClick={(e, rowData) => this.handleClickName(rowData.objectId)}
              actions={[
                {
                  icon: 'group', // view_headline
                  tooltip: '顯示',
                  onClick: (e, rowData) => this.handleClickName(rowData.objectId)
                }
              ]}
              editable={{
                //   onRowAdd: newData =>
                //     new Promise(async (resolve, reject) => {
                //       const data = [...dataArr];
                //       const db = Parse.Object.extend("clients");
                //       const newDoc = new db();
                //       const doc = await newDoc.save();
                //       newData.objectId = doc.id;
                //       newData.id = doc.id;
                //       newData._id = doc.id;
                //       newData.companyId = companyObj.objectId
                //       data.push(newData);
                //       this.setState({ dataArr: data, newData }, () => resolve());
                //     }).then(() => this.handleAdd()),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    // const data = [...dataArr];
                    // const index = data.indexOf(oldData);
                    // data[index] = newData;
                    // console.log(newData)
                    this.setState({ newData, oldData }, () => resolve());
                  }).then(() => this.handleUpdate()),
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    // let data = [...dataArr];
                    // const index = data.indexOf(oldData);
                    // data.splice(index, 1);
                    this.setState({ oldData }, () => resolve());
                  }).then(() => this.handleDelete()),
              }}
            />
          </Col>
        </Row>
        <br />
        {clientObj && clientObj.objectId ? <ClientTab {...this.props} clientObj={clientObj} /> : null}
      </div>
    </>);
  }
}

export default Client;