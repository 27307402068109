import React, { Component } from 'react'
import { Grid, Form, Tabs, Tab, Nav, Card, Modal, Container, Input, Select, Button, Label, Icon, Header, Dimmer, Loader, Image, Segment } from 'semantic-ui-react';
import Moment from 'moment';
import SelectDate from '../../widgets/SelectDate';
import RollCallSeats from './RollCallSeats';
import Parse from '../../../widget/parse'
import RollCallProgress from './RollCallProgress';

const weekdayArr2 = ['月', '火', '水', '木', '金'];

export default class RollCallPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSettingsModal: false,
      modalTitle: '追加',
      selClassArr: [],
      // classId: '',
      className: '',
      classStudentsArr: [],
      loadingClassStu: true,
      classObj: {},
      weekday: '',
      period: '',
      periodArr1: [],
      periodArr2: [],
      selPeriodArr: [],
      courseName: '',
      calendarHolidayArr: [],
      eventDate: Moment(new Date()).format('YYYY-MM-DD'),
      attendStatusArr: [],
      seatArr: [],
      unsub: null,
    };
  }

  async componentDidMount() {
    // console.log('componentDidMount')
    this.handleClassroom()
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.semesterYear !== this.props.semesterYear ||
    //   nextProps.semester !== this.props.semester ||
    //   nextProps.classId !== this.props.classId) {
    //   this.handleClassroom(nextProps)
    // }
    this.handleClassroom(nextProps)
  }

  handleClassroom = async (props = this.props) => {
    const { classId, handleLazyLoad, companyObj, studentsL, classroomL, classroom } = props;
    const { eventDate } = this.state
    if (!classroomL || !studentsL) {
      // const classroom = await Parse.queryData('Classroom', { companyId: companyObj.objectId });
      // const students = await Parse.queryData('Students', { companyId: companyObj.objectId });
      handleLazyLoad('Students', 'students');
      handleLazyLoad('Classroom', 'classroom');
      // handleLazyLoad('classroom');
      // handleLazyLoad('students');
      // this.setState({ classroom, students })
    } else if (classId && classId !== '-1' && classId !== 'all') {
      const classObj = classroom.find(item => item.objectId === classId);

      const className = classObj.classSchool;
      const period = classObj.classTime === '1' ? '0' : '4';

      const periodArr1 = classObj ? (classObj.classTime === '1' ? [0, 1, 2, 3] : [4, 5, 6, 7]) : [];
      const periodArr2 = classObj ? (classObj.classTime === '1' ? ['1', '2', '3', '4'] : ['5', '6', '7', '8']) : [];

      const weekday = eventDate && (new Date(eventDate).getDay() - 1) + "";
      const courseName = eventDate && period ? weekdayArr2[Number(weekday)] + periodArr2[period % 4] : '';

      const selPeriodArr = [];
      periodArr1.forEach((item, index) => selPeriodArr.push({ key: "p1" + index, text: periodArr2[index], value: item.toString() }));
      this.setState({ className, classObj, weekday, period, periodArr1, periodArr2, selPeriodArr, courseName }, () => this.handleDailyRollcall());
    }
  }

  handleDailyRollcall = async (props = this.props) => {
    const { companyObj, handleLazyLoad, calendar, studentsL, students } = props;
    const { eventDate, className, classObj, currentSubscription } = this.state;
    const calendarHolidayArr = calendar.filter(item => item.eventDate === eventDate && item.status === "3");
    this.setState({ calendarHolidayArr });

    if (!studentsL) {
      // handleLazyLoad('students');
      const students = await Parse.queryData('Students', { companyId: companyObj.objectId });
      this.setState({ students, studentsL: true })
    } else {
      const tmp = students.filter(item =>
        (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
        (item.applyResult === '1' || item.studentType === "2"));

      const arrStu = tmp.filter(item => item.classSchool === className); // 找到這班的所有人
      const tmpSeatArr = classObj.seatArr || arrStu.map(item => item.studentId); // 找出座位名單
      // console.log(tmpSeatArr)

      // 把坐位裡沒有的学生 拿掉
      const seatArr = tmpSeatArr.map(item => arrStu.find(item2 => item === item2.studentId) ? item : '');
      // console.log(seatArr)

      // 確定每一個学生 都有在陣列裡面
      arrStu.forEach(item => {
        if (!seatArr.find(item2 => item2 === item.studentId)) {
          const emptyId = seatArr.indexOf('');
          if (emptyId === -1) {
            seatArr.push(item.studentId)
          } else {
            seatArr[emptyId] = item.studentId
          }
        }
      })

      const emptyNum = 30 - seatArr.length;
      for (let i = 0; i < emptyNum; i++) {
        seatArr.push('');
      }
      this.setState({ classStudentsArr: arrStu, loadingClassStu: false, seatArr });

      if (arrStu.length && eventDate && className) {
        if (this.state.unsub) {
          this.state.unsub();
          //  console.log('unsub worked!')
        }
        // const unsub = firebase.firestore().collectionGroup('Rollcall')
        //   .where('eventDate', '==', eventDate)
        //   .onSnapshot(snap => {
        //     const data = [];
        //     snap.forEach(doc => {
        //       const studentId = doc.data().studentId;
        //       if (arrStu.find(item => item._id === studentId)) {
        //         data.push({ _id: studentId, period: doc.id, ...doc.data() })
        //       }
        //     });
        //     this.setState({ attendStatusArr: data });
        //     // console.log(data);
        //   }, err => {
        //     console.log(`Encountered error: ${err}`);
        //   });
        // this.setState({ unsub });
        const query = new Parse.Query('Rollcall');
        query.equalTo('eventDate', eventDate)
        query.limit(10000);
        const snap = await query.find();
        const data = []
        snap.forEach(doc => {
          const studentId = doc.studentId;
          if (arrStu.find(item => item.objectId === studentId)) {
            data.push({ objectId: studentId, period: doc.objectId, ...doc })
          }
        });
        this.setState({ attendStatusArr: data });
        if (currentSubscription) {
          // console.log('currentSubscription.unsubscribe')
          await currentSubscription.unsubscribe();
          this.setState({ currentSubscription: null })
        }
        let subscription = await query.subscribe().catch(error => console.error(error));
        subscription.on('create', (data) => {
          const attendStatusArr = [...this.state.attendStatusArr, data.toJSON()];
          this.setState({ attendStatusArr })
        });
        subscription.on('update', (data) => {
          const attendStatusArr = [...this.state.attendStatusArr, data.toJSON()];
          this.setState({ attendStatusArr })
        });
        subscription.on('delete', (data) => {
          const attendStatusArr = [...this.state.attendStatusArr, data.toJSON()];
          this.setState({ attendStatusArr })
        });
        this.setState({ currentSubscription: subscription })
      }
    }
  }

  handleDayChange = (eventDate) => {
    // console.log('handleDayChange', eventDate);
    // return;
    const weekday = eventDate && (new Date(eventDate).getDay() - 1) + "";
    this.setState({ eventDate, weekday }, () => this.handleDailyRollcall());
  };

  handleChange = (event, data) => {
    // console.log('handleChange')
    let fieldName = data.name;
    let fleldVal = data.value;

    if (fieldName === 'classId') { // 更新クラス的時候
      const { classroom } = this.props;
      const classObj = classroom.find(item => item.classSchool === fleldVal);
      const period = classObj ? (classObj.classTime === '1' ? "0" : "4") : "";
      this.setState({
        [fieldName]: fleldVal,
        period,
      }, () => this.handleClassroom());
    } else {
      this.setState({ [fieldName]: fleldVal });
    }
  }

  render() {
    const { semesterYear, semester, teachersArrange, classId, companyObj, classroom } = this.props;
    const { classObj, weekday, period, selPeriodArr, courseName, eventDate, calendarHolidayArr,
      classStudentsArr, loadingClassStu, attendStatusArr, seatArr } = this.state;
    // console.log(seatArr)
    //    const selArr = teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester && item.isUse) ?
    //   teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester && item.isUse).selArr : [];
    // progression
    // const selArr = teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester && item.isUse) ?
    //   teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester && item.isUse).selArr : [];
    // const courseArrs = classObj && classObj.courseArrs ? classObj.courseArrs : [];    
    return (
      <div>
        <Header as='h2' floated='left'>
          {/* {eventDate}  */}
        出欠確認</Header>
        <div style={{ float: 'right' }}>
          {/* <Input labelPosition='left' >
          <Label color={'blue'}> <Icon name='building' />クラス</Label>
          <Select
            style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
            compact options={selClassArr} onChange={this.handleChange} value={classId} name='classId' />
        </Input>&nbsp;&nbsp;&nbsp; */}
          <SelectDate
            eventDate={eventDate}
            handleDayChange={this.handleDayChange}
          />&nbsp;&nbsp;&nbsp;
        <Input labelPosition='left' disabled={!classId} >
            <Label color={'orange'}> <Icon name='clock' />時限</Label>
            <Select
              style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
              disabled={!classId} compact options={selPeriodArr} onChange={this.handleChange} value={period} name='period' />
          </Input>
        </div>
        <div style={{ clear: 'both' }}></div>
        {classroom.length ? null : <a href="/streamingManagement">クラスを追加して下さい</a>}
        {
          calendarHolidayArr.length ?
            <><h1>{eventDate} 休日</h1>{calendarHolidayArr.map(item => <h3 key={item.objectId}>{item.title}</h3>)}</> :
            <> {loadingClassStu ? <Segment>
              <Dimmer active inverted={companyObj.inverted}>
                <Loader inverted={companyObj.inverted}>Loading</Loader>
              </Dimmer>
              <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
            </Segment> : <>
                <RollCallSeats
                  {...this.props}
                  weekday={weekday}
                  period={period}
                  courseName={courseName}
                  classObj={classObj}
                  seatArr={seatArr}
                  eventDate={eventDate}
                  attendStatusArr={attendStatusArr}
                  classStudentsArr={classStudentsArr}
                />
                <RollCallProgress
                  {...this.props}
                  weekday={weekday}
                  period={period}
                  courseName={courseName}
                  eventDate={eventDate}
                  classId={classId}
                  // classObj={classObj}
                  handleStudentList={this.handleStudentList}
                // arrangement={arrangement}
                // courseArrs={courseArrs}
                />
              </>}
            </>
        }
      </div >);
  }
};
