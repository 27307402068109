
import React, { Component } from 'react'
import { Container, Table, Form, Button, Tab } from 'semantic-ui-react';
import IncomeStatement1 from './IncomeStatement1';
import IncomeStatement2 from './IncomeStatement2';

class IncomeStatement extends Component {
  state = {
    loading: true,
    loadingBtn: false,
  }

  render() {
    const props = this.props;

    const panes = [
      { menuItem: '年度損益表', render: () => <Tab.Pane>{<IncomeStatement1 {...props} />}</Tab.Pane> },
      { menuItem: '資產負債表', render: () => <Tab.Pane>{<IncomeStatement2 {...props} />}</Tab.Pane> },
    ]

    return (<div className="content">
      {/* <Form inverted={companyObj.inverted} style={{ position: 'relative', zIndex: 99, }}> */}
      <Form style={{ position: 'relative', zIndex: 99, }}>
        {/* <Tab menu={{ inverted: companyObj.inverted }} panes={panes} {...props} /> */}
        <Tab panes={panes} {...props} />
      </Form>
    </div>);
  }
}

export default IncomeStatement;

