
import React, { Component } from 'react'
// import { Container, Table, Form, Button, Tab } from 'semantic-ui-react';
import {
  // Form,
  // Collapse,
  Navbar,
  // NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  // NavbarText,
  // Button,
  // FormGroup,
  // Label,
  // Input
} from 'reactstrap';

// import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
// import EditOnePage from './EditOnePage';
import WebPage from './WebPage';

class WebSiteShow extends Component {
  state = {
    loading: true,
    loadingBtn: false,
    pageObj: {},
    pageId: ''
  }

  componentDidMount() {
    this.handleFetch(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps)

  }
  handleFetch = async (props = this.props) => {
    const { pageId = '' } = props;
    if (pageId) {
      this.handleSetPage(props, pageId);
    }
  }

  handleNavClick = (e) => {
    e.preventDefault()
    const pageId = e.target.id;
    this.handleSetPage(this.props, pageId);
  }

  handleSetPage = (props, pageId) => {
    if (!pageId) {
      return;
    }
    const { data } = props
    const pageObj = data.find(item => item.objectId === pageId);
    this.setState({ pageId, pageObj })
  }

  render() {
    const { companyObj, data1, data
      // , data4 = [] 
    } = this.props
    const { pageId = '', pageObj } = this.state;

    return (<>
      <div className="content">
        {/* 上面的導覽列 */}
        <Navbar light expand="md">
          {/* 公司名 */}
          <NavbarBrand id={'home'} href="#" onClick={this.handleNavClick}>{companyObj.name}</NavbarBrand>
          {/* 每一個項目 */}
          <Nav className="mr-auto" navbar>
            {data1.filter(item => item.isOpen).map(item => (item.pageType === '2' || item.pageType === '3') ?
              <UncontrolledDropdown nav inNavbar key={item.objectId} >
                <DropdownToggle color="default" nav caret>{item.name}</DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  {data
                    .filter(item2 => item2.isOpen && item2.level === '2' && item2.nav1Id === item.objectId)
                    .map(element =>
                      <DropdownItem href="#" key={element.objectId} id={element.objectId} onClick={this.handleNavClick}>
                        {element.name}
                      </DropdownItem>)}
                  {/* // <DropdownItem divider /> */}
                </DropdownMenu>
              </UncontrolledDropdown> :
              <NavItem key={item.objectId} >
                <NavLink id={item.objectId} href="#" onClick={this.handleNavClick}>{item.name}</NavLink>
              </NavItem>
            )}
          </Nav>
          {/* 頁腳 */}
          {/* <Nav className="" navbar>
            {data4.map(item => <NavLink key={item.objectId} id={item.objectId} href="#" onClick={this.handleNavClick}>{item.name}</NavLink>)}
          </Nav> */}
        </Navbar>
        <br />
        {pageId ? <WebPage {...this.props} pageObj={pageObj} /> : null}
      </div>
    </>);
  }
}

export default WebSiteShow;
