import React, { Component } from 'react'
import { Button, Grid } from 'semantic-ui-react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MaterialTable from 'material-table'

import { DimmerImg } from '../../layouts/Dimmer';
import ModalLighting2 from './ModalLighting2'
import Parse, { funcPad } from '../../widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';


const columns = (year, ordernum, mgyearSel, mgyearDefault) => ([
  { width: 60, title: '單號', field: 'ordernum', initialEditValue: ordernum, defaultSort: 'desc' },
  { width: 60, title: '年度', field: 'year', initialEditValue: year || mgyearDefault, lookup: mgyearSel },
  { width: 60, title: '應收金額', field: 'price_total' },
  { width: 90, title: '建立時間', editable: 'never', field: 'createdAt', type: 'datetime' },
  { width: 90, title: '修改時間', editable: 'never', field: 'updatedAt', type: 'datetime' },
]);

const checkNeedToUpdate = (src, des) => {
  if ((!src || typeof src === 'undefined' || src === '0' || src === '吉') && (!des || typeof des === 'undefined' || des === '0' || des === '吉')) {
    return false;
  } else if (src === des) {
    return false;
  }
  return true
}

export default class ModalLighting1 extends Component {
  state = {
    show: false,
    data1: [],
    data2: [],
    ordering1Id: '',
    newData1: {},
    newData2: {},
    oldData1: {},
    oldData2: {},
    loading: false,
    companyObjLive: {},
    companyObj: {},
  }

  componentDidMount = async () => {
    const { companyObj } = this.props
    const mgyear = await Parse.queryData('mgyear', { companyId: companyObj.objectId });
    let mgyearSel = {}
    let mgyearDefault = ''
    mgyear.forEach(item => {
      mgyearSel = { ...mgyearSel, [item.value]: item.value }
      if (item.now_use === '-1') {
        mgyearDefault = item.value
      }
    })
    this.setState({ mgyearSel, mgyearDefault }, () => this.handleParams());
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps')
    this.setState({ loading: true });
    this.handleParams(nextProps);
  }

  handleParams = async (props = this.props) => {
    const { match, companyObj } = props;
    const { t1 } = match.params;

    const companyObjLive = await Parse.findDoc('company', { objectId: companyObj.objectId });
    this.setState({ companyObjLive })

    if (typeof t1 !== 'undefined' && t1 === 'l1') {
      this.setState({ show: true, ordering1Id: '', data2: [] }, () => this.handleFetch(props));
    }
  }

  handleFetch = async (props = this.props) => {
    const { familyId, match, companyObj, people1 } = props;
    const { t1Id } = match.params;

    const companyId = companyObj.objectId
    if (familyId) {
      const data1 = await Parse.queryData('lighting1', { familyId });
      // console.log(data1)
      if (typeof t1Id !== 'undefined' && data1.length) {
        this.setState({ loading: true }, async () => {
          const lighting1Obj = data1.find(item => item.objectId === t1Id);
          // console.log(lighting1Obj.objectId)
          if (lighting1Obj && lighting1Obj.objectId) {
            const data2 = await Parse.queryData('lighting2', { companyId, familyId, lighting1Id: lighting1Obj.objectId });
            // console.log(data2)
            // console.log(people1)
            // 判斷姓名有沒有找到，沒有就新增
            // 判斷生日和地址有沒有不一樣，沒有就更新
            const promises = [];
            people1.forEach(async (item) => {
              // const index = data2.findIndex(item2 => item.objectId === item2.peopleId)
              const index = data2.findIndex(item2 => item.name === item2.peopleName)
              // console.log(item.name, index)
              if (index !== -1) { // 如果有找到，就更新資料
                // console.log(item)
                // console.log(data2[index])
                // console.log(data2[index].bornYear, item.bornYear, data2[index].bornMonth, item.bornMonth, data2[index].bornDay, item.bornDay, data2[index].bornTimeText, item.bornTimeText, data2[index].addr, item.addr)

                let updateFlag = false;
                let bornYear = data2[index].bornYear;
                let bornMonth = data2[index].bornMonth;
                let bornDay = data2[index].bornDay;
                let bornTimeText = data2[index].bornTimeText;
                let addr = data2[index].addr;

                if (checkNeedToUpdate(item.bornYear, bornYear)) {
                  // console.log("bornYear", bornYear, item.bornYear)
                  bornYear = item.bornYear;
                  updateFlag = true;
                }
                if (checkNeedToUpdate(item.bornMonth, bornMonth)) {
                  // console.log("bornMonth", bornMonth, item.bornMonth)
                  bornMonth = item.bornMonth;
                  updateFlag = true;
                }
                if (checkNeedToUpdate(item.bornDay, bornDay)) {
                  // console.log("bornDay", bornDay, item.bornDay)
                  bornDay = item.bornDay;
                  updateFlag = true;
                }
                if (checkNeedToUpdate(item.bornTimeText, bornTimeText)) {
                  // console.log("bornTimeText-" + bornTimeText + "-" + item.bornTimeText)
                  bornTimeText = item.bornTimeText;
                  updateFlag = true;
                }
                if (checkNeedToUpdate(item.addr, addr)) {
                  // console.log("addr", addr, item.addr)
                  addr = item.addr;
                  updateFlag = true;
                }

                if (updateFlag) {
                  const obj2 = {
                    ...data2[index],
                    bornYear,
                    bornMonth,
                    bornDay,
                    bornTimeText,
                    addr
                  }
                  delete obj2.isLive;
                  delete obj2.mainPerson;
                  delete obj2.bornTime;
                  delete obj2.sexual;
                  delete obj2.memberId;
                  delete obj2.zodiac_id;
                  // console.log("save", obj2)
                  // console.log(data2[index].bornYear, obj2.bornYear, data2[index].bornMonth, obj2.bornMonth, data2[index].bornDay, obj2.bornDay, data2[index].bornTimeText, obj2.bornTimeText, data2[index].addr, obj2.addr)
                  data2[index] = obj2;
                  promises.push(Parse.saveData('lighting2', obj2));
                }
              } else { // 沒找到，就新增資料
                const { name, bornYear, bornMonth, bornDay, bornTimeText, addr } = item;
                const db = Parse.Object.extend("lighting2");
                const newDoc = new db();
                const doc2 = await newDoc.save();
                const obj2 = {
                  companyId: companyObj.objectId || '',
                  companyName: companyObj.name || '',
                  familyId,
                  bornYear, bornMonth, bornDay, bornTimeText, addr,
                  peopleName: name,
                  peopleId: item.objectId,
                  ordernum: lighting1Obj.ordernum,
                  lighting1Id: lighting1Obj.objectId,
                  year: lighting1Obj.year + '',
                  _id: doc2.objectId,
                  id: doc2.objectId,
                  objectId: doc2.id,
                }
                // console.log('add', obj2)
                data2.push(obj2)
                promises.push(Parse.saveData('lighting2', obj2));
              }
            })

            Promise.all(promises).then(res => {
              this.setState({ loading: false, lighting1Obj, data2, ordering1Id: t1Id });
            })
          }
        })
      }
      this.setState({ data1, ordering1Id: '', data2: [], loading: false });
    }
  }

  handleClosePdf = () => {
    const { history, familyId } = this.props
    history.push(`/admin/Family/${familyId}`)
    this.setState({ show: false });
  }

  handleData = (rowData) => {
    const { history, familyId } = this.props;
    this.setState({ ordering1Id: '' }, () => {
      if (rowData.objectId) {
        history.push(`/admin/Family/${familyId}/l1/${rowData.objectId}`);
      }
    })
  }

  handleAdd = async () => { // lighting1 add
    const { familyId, companyObj, people1 } = this.props;
    const { newData1 } = this.state
    this.setState({ loading: false });
    // console.log(people1)
    if (newData1.ordernum) {
      Parse.saveData("company", { objectId: companyObj.objectId, ordernumDef2: newData1.ordernum.substr(1) })
    }
    Parse.saveData('lighting1', newData1)
    const promises = []
    people1.forEach(async (item) => {
      const db = Parse.Object.extend("lighting2");
      const newDoc = new db();
      const doc2 = await newDoc.save();

      const { name, bornYear, bornMonth, bornDay, bornTimeText, addr } = item;
      const obj2 = {
        // ...item,
        companyId: companyObj.objectId || '',
        companyName: companyObj.name || '',
        familyId,
        bornYear, bornMonth, bornDay, bornTimeText, addr,
        peopleName: name,
        peopleId: item.objectId,
        ordernum: newData1.ordernum,
        lighting1Id: newData1.objectId,
        year: newData1.year + '',
        _id: doc2.objectId,
        id: doc2.objectId,
        objectId: doc2.id,
      }
      // console.log(obj2.peopleName)
      promises.push(Parse.saveData('lighting2', obj2));
    })
    Promise.all(promises).then(res => this.handleData(newData1))
  }

  handleUpdate = async () => {
    const { newData1 } = this.state
    Parse.saveData('lighting1', newData1);
    this.setState({ oldData1: {}, newData1: {} })
  }

  handleDelete = async () => {
    const { history, familyId } = this.props
    const { oldData1 } = this.state;
    await Parse.destroyData('lighting2', { lighting1Id: oldData1.objectId });
    await Parse.deleteData('lighting1', oldData1);
    this.setState({ oldData1: {}, ordering1Id: '' }, () => history.push(`/admin/Family/${familyId}/l1`));
  }

  handleDuplicate = (rowData) => {
    if (!window.confirm("確認要複製嗎")) {
      return;
    }

    // const { people1, pray1, pray2, prayingtype } = this.props
    // const { companyObjLive } = this.state
    // const { yearDef, orderalphaDef2, ordernumDef2, pray1IdDef } = companyObjLive;

    // const ordernum = orderalphaDef2 + funcPad(Number(ordernumDef2) + 1, 8);
    // const familyId = people1[0].familyId
    /*
    const db = firebase.firestore();
    const docRef = db.collection(`company/${companyObj._id}/family/${familyId}/lighting1`).doc();
    const obj = {
      ...rowData,
      id: docRef.id,
      _id: docRef.id,
      ordernum,
      // lighting1Obj,
      // familyId,
      createdAt: new Date()
    };
    const docRef2 = db.collection(`company/${companyObj._id}/family/${familyId}/lighting1/${obj.id}/lighting2`).doc();
    db.collection(`company/${companyObj._id}/family/${familyId}/lighting1/${rowData.id}/lighting2`)
      .get().then(snap => {
        const data = []
        snap.forEach(doc => data.push({ _id: doc.data().id, ...doc.data() }))
        data.forEach(item => {

          const obj2 = {
            ...obj,
            ...item,
            _id: docRef.id,
            id: docRef.id,
            lighting1Id: obj.id,
            ordernum,
            createdAt: new Date()
          }
          if (obj.pray1Id) {
            obj.pray1Text = pray1[obj.pray1Id];
          }

          if (obj.ordernum) {
            db.doc(`company/${companyObj.id}`).update({ ordernumDef2: obj.ordernum.substr(1) });
          }

          // if (obj.prayserial) {
          //   // console.log(`/pray1/${obj.pray1Id}/pray2/${obj.pray2Id}`)
          //   db.doc(`/pray1/${obj.pray1Id}/pray2/${obj.pray2Id}`).update({ now_num: obj.prayserial })
          // }
          // if (!!obj2.type && typeof obj2.type !== 'undefined') {
          //   // obj.pray1Text = pray1.find(item => item.id === newData1.pray1Id).value;
          //   obj2.typeText = prayingtype[obj.type];
          // }
          // if (!!obj2.pray2Id) {
          //   obj2.pray2Text = pray2.find(item => item.id === obj2.pray2Id).value;
          //   obj2.pray2_orderid = pray2.find(item => item.id === obj2.pray2Id).order_id;
          // }
          // console.log(obj, obj2)
          docRef.set(obj);
          docRef2.set(obj2)
          this.handleData(obj);
          data1.push(obj)
          this.setState({ data1 })
          // console.log(`${obj.id} added`);
        })
      })
      */
  }

  handleClickModalBtn = () => {
    const { history, familyId } = this.props;
    history.push(`/admin/Family/${familyId}/l1`);
  }

  render() {
    const { pray1, pray2, familyId } = this.props
    const { show, data1, data2, ordering1Id, lighting1Obj, loading, companyObjLive, mgyearSel = {}, mgyearDefault = '' } = this.state;
    const { title, db, color, icon } = this.props.obj;
    const { yearDef2, orderalphaDef2, ordernumDef2, pray1IdDef } = companyObjLive;

    const ordernum = orderalphaDef2 + funcPad(Number(ordernumDef2) + 1, 8);
    return (<>
      <Button icon={icon} content={title} color={color} size='small' floated='right'
        onClick={() => this.setState({ show: true, ordering1Id: '', data2: [] }, () => this.handleClickModalBtn())} />
      <Modal
        isOpen={show}
        toggle={this.handleClosePdf}
        size="lg"
        className="mh-90 h-90 mw-100 w-90"
        style={{ paddingTop: '0px' }}
        scrollable={true}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClosePdf}>
            <span aria-hidden="true">×</span>
          </button>
          {title} 管理
        </ModalHeader>
        <ModalBody style={{ padding: "20px 30px" }}>
          <Grid divided='vertically'>
            <Grid.Row columns='equal'>
              <Grid.Column width={10}>
                <MaterialTable
                  localization={localization()}
                  // columns={columns(pray1, pray2, yearDef, ordernum, pray1IdDef)}
                  columns={columns(yearDef2, ordernum, mgyearSel, mgyearDefault)}
                  options={{
                    addRowPosition: "first",
                    exportButton: true,
                    exportPdf: (columns, data) => exportPdf(columns, data, '申辦服務'),
                    pageSize: 5,
                    search: false,
                    tableLayout: 'fixed',
                  }}
                  data={data1}
                  title="申辦服務"
                  actions={[
                    {
                      icon: 'view_headline',
                      tooltip: '查看',
                      onClick: (event, rowData) => this.handleData(rowData)
                    },
                    // {
                    //   icon: 'file_copy',
                    //   tooltip: '複製',
                    //   onClick: (event, rowData) => this.handleDuplicate(rowData)
                    // }
                  ]}
                  onRowClick={(event, rowData) => this.handleData(rowData)}
                  editable={{
                    onRowAdd: newData1 =>
                      new Promise(async (resolve, reject) => {
                        const data = [...data1];

                        const db = Parse.Object.extend("lighting1");
                        const newDoc = new db();
                        const doc = await newDoc.save();
                        newData1._id = doc.id;
                        newData1.id = doc.id;
                        newData1.objectId = doc.id;
                        newData1.companyId = companyObjLive.objectId;
                        newData1.companyName = companyObjLive.name;
                        newData1.familyId = this.props.familyId

                        data.push(newData1);
                        this.setState({ data1: data, newData1 }, () => resolve());
                      }).then(() => this.handleAdd()),
                    onRowUpdate: (newData1, oldData1) =>
                      new Promise((resolve, reject) => {
                        if (newData1.pray1Id) {
                          newData1.pray1Text = pray1[newData1.pray1Id];
                        }
                        if (newData1.pray2Id) {
                          newData1.pray2Text = pray2.find(item => item.objectId === newData1.pray2Id).value;
                        }
                        const data = [...data1];
                        const index = data.indexOf(oldData1);
                        data[index] = newData1;
                        // console.log(newData1)
                        this.setState({ data1: data, newData1, oldData1 }, () => resolve());
                      }).then(() => this.handleUpdate()),
                    onRowDelete: oldData1 =>
                      new Promise((resolve, reject) => {
                        let data = [...data1];
                        const index = data.indexOf(oldData1);
                        data.splice(index, 1);
                        this.setState({ data1: data, oldData1 }, () => resolve());
                      }).then(() => this.handleDelete()),
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {loading ? <DimmerImg /> : null}
          {ordering1Id ? <div style={{ maxWidth: '100%' }}>
            <ModalLighting2 {...this.props} lighting1Obj={lighting1Obj} data1={data1} data2={data2} />
          </div> : null}
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button
            onClick={this.handleClosePdf}
            icon='checkmark'
            content='關閉'
          />
        </ModalFooter>
      </Modal>
    </>)
  }
}