
import React, { Component } from 'react'
import intl from 'react-intl-universal';
import Parse from "widget/parse";
// import { connect } from 'react-redux';
// reactstrap components
import { Message, Loader } from 'semantic-ui-react'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";

// import { signinUser } from "widget/parse";

// import validateInput from "validators/login";
// import { loginUser } from 'store/actions/auth';

class ResetPassword extends Component {
  state = {
    // username: '',
    // password: '',
    email: '',
    errors: {},
    isLoading: false
  }

  componentDidMount() {
    const { history, user } = this.props
    if (user.isAuthenticated) {
      history.push('/');
    }
    document.body.classList.toggle("login-page");
  }

  componentWillReceiveProps(nextProps) {
    const { history } = this.props
    if (nextProps.user && nextProps.user.isAuthenticated) {
      history.push('/');
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
        isLoading: false
      });
    }
  }

  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    // const { history, loginUser } = this.props
    const { email } = this.state
    // const res = await signinUser(email, password);
    // console.log(res)
    // if (res === '1') {
    //   history.push('/admin/dashboard');
    // }
    // if (this.isValid()) {
    this.setState({ errors: {}, isLoading: true });
    // loginUser(this.state, history);
    const res = await Parse.resetpw(email)
    alert(intl.get('重設密碼信件已寄出'))

    // const res = await Parse.sendmail({ from: "test <aaa@gmain.com>", to: email, subject: "reset password", html: "test" })

    if (res === '1') {
      // window.alert("成功");
      this.setState({ errors: {}, isLoading: false });
    } else {
      this.setState({ errors: res, isLoading: false });
    }
    // }
  }

  // isValid = () => {
  //   const { errors, isValid } = validateInput(this.state);
  //   if (!isValid) {
  //     this.setState({ errors });
  //   }
  //   return isValid;
  // }

  render() {
    const { companyObj } = this.props
    const { errors, isLoading } = this.state
    // console.log(companyObj.name)
    // console.log(this.props)
    return (
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form action="" className="form" method="">
                <Card className="card-login">
                  <CardHeader>
                    <CardHeader>
                      {/* <h3 className="header text-center">{intl.get('Login')}</h3> */}
                      <h3 className="header text-center">{intl.get('Reset Password')}</h3>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          {/* <i className="nc-icon nc-single-02" /> */}
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      {/* <Input placeholder={intl.get('First Name')} type="text" /> */}
                      <Input name='email' placeholder={intl.get('Email')} type="email" onChange={this.handleChange} />
                    </InputGroup>
                    {/* <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name='password'
                        placeholder={intl.get('Password')}
                        type="password"
                        autoComplete="off"
                        onChange={this.handleChange}
                      />
                    </InputGroup> */}
                    {/* <br /> */}
                    {/* <FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            defaultChecked
                            defaultValue=""
                            type="checkbox"
                          />
                          <span className="form-check-sign" />
                          {intl.get('Subscribe to newsletter')}
                        </Label>
                      </FormGroup>
                    </FormGroup> */}
                  </CardBody>
                  <CardFooter>
                    {errors.loginError ? <Message error content={errors.loginError} /> : null}
                    <Button
                      block
                      className="btn-round mb-3"
                      color="success"
                      href="#submit"
                      // onClick={e => e.preventDefault()}
                      onClick={this.handleSubmit}
                    >
                      {/* Get Started */}
                      {!isLoading
                        ? intl.get('Reset Password')
                        // ? intl.get('confirmtologin')
                        // ? '重設密碼'
                        : <Loader active inverted inline size='small' />}
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            // backgroundImage: `url(${require("assets/img/bg/fabio-mangione.jpg")})`
            // backgroundImage: `url(${companyObj.img3 || require("assets/img/bg/fabio-mangione.jpg")})`
            backgroundImage: `url(${(companyObj.img3File && companyObj.img3File.url) || require("assets/img/bg/rawpixel-comm.jpg")})`
          }}
        />
      </div>
    );
  }
}

// export default Login;

// LoginForm.propTypes = {
//   errors: PropTypes.object.isRequired
// }

// const mapStateToProps = state => ({
//   user: state.user,
//   errors: state.errors
// })

export default ResetPassword;
// export default connect(mapStateToProps, { loginUser })(Login)
