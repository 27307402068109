import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper';
import { Button, Divider, Image, Form, Modal, Icon } from 'semantic-ui-react';
import { EditingState, SortingState, IntegratedSorting, PagingState, IntegratedPaging, } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, TableEditRow, PagingPanel, TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash'
import { getOptionsSelByKey } from './FieldsRender';
import { Link } from 'react-router-dom'

const LookupEditCellBase = ({
  availableColumnValues, value, onValueChange, classes,
}) => (<TableCell className={classes.lookupEditCell}>
  <Select
    value={value} onChange={event => onValueChange(event.target.value)}
    input={(<Input classes={{ root: classes.inputRoot }} />)}
  >
    <MenuItem key='empty' value=''>
      -- 選擇 --
        </MenuItem>
    {availableColumnValues.map(item => (
      <MenuItem key={item.key} value={item.text}>
        {item.text}
      </MenuItem>
    ))}
  </Select>
</TableCell>);
const styles = theme => ({
  lookupEditCell: {
    padding: theme.spacing(1),
  },
  dialog: {
    width: 'calc(100% - 16px)',
  },
  inputRoot: {
    width: '100%',
  },
});

export const LookupEditCell = withStyles(styles)(LookupEditCellBase);
const Cell = (props) => {
  if (props.column.name === 'photo') {
    return <Image floated='left' src={props.value || 'http://via.placeholder.com/30'} size='tiny' circular style={{ marginBottom: '0px' }} />;
    // return <img alt='' src={props.value || 'http://via.placeholder.com/30'} style={{ margin: '0px' }} />;
  }
  return <Table.Cell {...props} />;
};

const getRowId = row => row._id;

export default class MyTables2Editable extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      // columns: [],
      // rows: [],
      pageSizes: [15, 20, 50, 100],
      // sorting: [{ columnName: props.columns[0].name, direction: 'asc' }],
      sorting: [],
      editingRowIds: [],
      // addedRows: [],
      rowChanges: {},
      editingStateColumnExtensions: [
        { columnName: 'recruitNum', editingEnabled: false },
        { columnName: 'studentId', editingEnabled: false },
        { columnName: 'Photo', editingEnabled: false },
        { columnName: 'semesterYear', editingEnabled: false },
        { columnName: 'semester', editingEnabled: false },
        { columnName: 'jpnName', editingEnabled: false },
        { columnName: 'nationalityText', editingEnabled: false },
        { columnName: 'classSchool', editingEnabled: false },
        { columnName: 'birthday', editingEnabled: false },
        { columnName: 'genderText', editingEnabled: false },
        { columnName: 'action', editingEnabled: false },
      ],
      // form: {},
    };
    this.changeSorting = sorting => this.setState({ sorting });
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // const { columns, rows } = nextProps;
    // // const columns = fields.map(item => ({ name: item.id, ...item }))
    // if (!columns || !columns.length || !columns[0].id) {
    //   return;
    // }
    // this.setState({ sorting: [{ columnName: columns[0].id }], rows });
    this.handleFetch(nextProps);

  }

  handleFetch = (props = this.props) => {
    const { rows } = props;
    this.setState({ rows });
  }

  changeEditingRowIds = (editingRowIds) => {
    this.setState({ editingRowIds });
  }

  changeRowChanges = (rowChanges) => {
    // console.log(rowChanges);
    this.setState({ rowChanges });
  }

  saveUpdateSetting = () => {
    const { receptObj, rows = [] } = this.props;
    const { rowChanges } = this.state;
    let collection = receptObj && receptObj.key ? receptObj.key : 'Grade'

    let rowupdate = [...rows];
    let rowChangesArr = Object.entries(rowChanges);
    // rowChangesArr.map(item => {
    //   firebase.firestore().collection(`${collection}`).doc(item[0])
    //     .update(item[1]).then()
    //   rowupdate = rowupdate.map(row => (item[0] === row._id ? { ...row, ...item[1] } : row));
    // });
    // this.setState({ editingRowIds: [], rows: rowupdate, isEdit: false });
  }

  handleDelete = (_id) => {
    const { receptObj, handleRowDelete } = this.props
    let collection = receptObj.key

    handleRowDelete(_id)
    // firebase.firestore().doc(`${collection}/${_id}`).delete().then(() => {
    //   console.log(`'Document successfully deleted.'${_id}`);
    // });
  }

  render() {
    const { rows = [], columns = [], list, type } = this.props
    const { editingRowIds, rowChanges, sorting, editingStateColumnExtensions, pageSizes, isEdit = false } = this.state;

    let RowId = rows.map(item => item._id);
    let column = isEdit && type !== 'grade' ? [...columns, { name: "action", title: "操作" }] : columns;
    const nationalityArr = this.props.optSet ? getOptionsSelByKey(this.props.optSet, 'nationality') : [];
    const recruitStatusArr = this.props.optSet ? getOptionsSelByKey(this.props.optSet, 'recruitStatus') : [];
    const studentRelationshipArr = this.props.optSet ? getOptionsSelByKey(this.props.optSet, 'studentRelationship') : [];
    const isTrueArr = this.props.optSet ? getOptionsSelByKey(this.props.optSet, 'isTrue') : [];
    const isHasArr = this.props.optSet ? getOptionsSelByKey(this.props.optSet, 'isHas') : [];

    const EditCell = (props) => {
      const { column } = props;
      //select選項
      // console.log(props)
      let availableValues = {
        recruitStatusText: recruitStatusArr,
        whetherPickedUpText: isTrueArr,
        accommodationsText: isTrueArr,
        testResultText: isTrueArr,
        interviewResultText: isTrueArr,
        preEduSeleResultText: isTrueArr,
        admissionLiceResultText: isTrueArr,
        resultOfCertificateOfEligibilityText: isTrueArr,
        visaResultText: isTrueArr,
        enrollmentSituationText: isTrueArr,
        theResultAdmissionPermitText: isTrueArr,
        schoolagreeText: isTrueArr,
        agentNationalityText: nationalityArr,
        agentRelationshipText: studentRelationshipArr,
        copyResident1Text: isHasArr,
        copyResident2Text: isHasArr,
        copyResident3Text: isHasArr,
        nationality: nationalityArr
      };
      const availableColumnValues = availableValues[column.name];
      if (column.name === 'photo') {
        return <Image floated='left' src={props.value || 'http://via.placeholder.com/30'} circular style={{ marginBottom: '0px', width: '30px' }} />;
        // return <img alt='' src={props.value || 'http://via.placeholder.com/30'} style={{ margin: '0px' }} />;
      } else if (type !== 'grade' && column.name === 'action') {
        return <Button floated='right' size='small' color='red' onClick={() => this.handleDelete(props.row._id)}> <Icon name='delete' />刪除</Button >;
      }
      else if (availableColumnValues) {
        return <LookupEditCell {...props} availableColumnValues={availableColumnValues} />;
      }
      return <TableEditRow.Cell {...props} />;
    };

    //navigate to個人頁面by row
    const Row = (props) => {
      if (this.props.type === 'grade') {
        // return <Table.Row {...props} component={Link} to={`/ExamTable/${this.props.examKey}/${props.row._id}`} hover={true} />
        return <Table.Row {...props} component={Link} to={`/ExamTable/${props.row._id}`} hover={true} />
      } else {
        return <Table.Row {...props}
        // component={Link} to={`/StudentPage/${list}/${props.row._id}`} hover={true}
        />
      }
    };
    return <>
      <div style={{ float: 'right' }}>
        {isEdit ? <Button positive onClick={() => this.saveUpdateSetting()} icon='save' content='儲存' /> :
          <Button positive onClick={() => this.setState({ editingRowIds: RowId, isEdit: true })} icon="edit" content="編輯" />}
        {isEdit ? <Button basic onClick={() => this.setState({ editingRowIds: [], rowChanges: {}, isEdit: false })} icon='times' content='取消' /> : null}
      </div>
      <div style={{ clear: 'both' }}></div>
      <br />
      <Paper>
        <Grid
          rows={this.state.rows ? this.state.rows : rows}
          columns={column}
          getRowId={getRowId}
        >
          <PagingState
            defaultCurrentPage={0}
            defaultPageSize={20}
          />
          <IntegratedPaging />
          <SortingState
            sorting={sorting}
            onSortingChange={this.changeSorting}
          />
          <IntegratedSorting />
          <EditingState
            editingRowIds={editingRowIds}
            onEditingRowIdsChange={this.changeEditingRowIds}
            rowChanges={rowChanges}
            onRowChangesChange={this.changeRowChanges}
            columnExtensions={editingStateColumnExtensions}
          // onCommitChanges={this.commitChanges}
          />
          <Table
            // columnExtensions={tableColumnExtensions}
            rowComponent={Row}
            cellComponent={Cell}
          />
          <TableHeaderRow showSortingControls />
          <PagingPanel
            pageSizes={pageSizes}
          />
          <TableEditRow
            cellComponent={EditCell} />
          {/* {isEdit ? <TableEditColumn
            // showAddCommand={!addedRows.length}
            // showEditCommand
            showDeleteCommand
          /> : ''} */}
        </Grid>
      </Paper>
    </>
  }
}