import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function SectionHeader4(props = {}) {
  const { cookies, form, webimageArr, imagePreviewUrl } = props;
  // console.log(form)
  const { text2 = '小標題', text3 = '內文', text4 = '大標題', img1File } = form;
  const dbLang = cookies.get('dbLang') || 'name';
  let arr = []
  arr = webimageArr.filter(item => item.navId === form.objectId)
  if (!arr.length) {
    arr = [{ icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' }]
  }
  const picUrl = (img1File && img1File.url) || imagePreviewUrl;
  return (
    <>
      <div className="section section-feature cd-section" style={{ margin: '50px 0px' }} id="features">
        {/* ********* FEATURES 4 ********* */}
        <div className="features-4">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                {/* <h2 className="title">{text2}</h2> */}
                <h5 className="description">
                  {/* {text3} */}
                </h5>
              </Col>
            </Row>
            <Row>
              <Col lg="5" md="12" xs='12'>
                <div >
                  <h4 style={{
                    position: 'absolute', top: '35%', left: '-26%', fontWeight: 600, fontFamily: 'serif'
                  }}>{text4}</h4>
                  <img
                    alt="..."
                    src={picUrl || require("assets/img/sections/ipad.png")}
                    style={{ marginTop: '20%', width: '100%' }}
                  />
                  <br />
                  <br />
                  <br />
                  <br />
                  <h5 style={{ textAlign: 'center' }}>{text2}</h5>
                  <h4 style={{ textAlign: 'center' }}>{text3}</h4>
                </div>
              </Col>
              <Col lg="6" md="12" xs='12' style={{ marginLeft: '5%' }}>
                {arr.map((item) => <>
                  <div className="info-horizontal">
                    <div className="icon icon-info">
                      <i aria-hidden={true} className={`nc-icon ${item.icon}`} />
                    </div>
                    <div className="description" >
                      <h4 className="info-title" style={{ marginTop: '10px' }}>{item['title1' + dbLang] || ''}</h4>
                      <br />
                      <p style={{ fontSize: '13px' }}>
                        {item['content1' + dbLang] || ''}
                      </p>
                    </div>
                  </div>
                </>)}
              </Col>

            </Row>
          </Container>
        </div>
        {/* ********* END FEATURES 4 ********* */}
      </div>
    </>
  );
}

export default SectionHeader4;
