import React, { Component } from 'react'
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
import { Container, Header, Form, Icon, Grid, Image } from 'semantic-ui-react'
import { ModalGateway, Modal } from 'react-images';
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import PageHeader from 'components/Headers/PageHeader';
import { Col, Row, CardImg } from 'reactstrap';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ModalEditPic from './ModalEditPic'
import ModalEditText from './ModalEditText'
import ModalEditPhotoes from './ModalEditPhotoes'
import ModalEditButton from './ModalEditButton'
import ModalEditContact from './ModalEditContact'
import ModalEditSocial from './ModalEditSocial'
import ModalEditShare from './ModalEditShare'
import ModalEditBackground from './ModalEditBackground'

// import './css/startup1.css'
export class StartUp extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
      isOpen: false,
      show: false,
      collapse: true,
      photo1: '',
      text: '',
      data1: [],
      socialArr: [],
      shareArr: [],
      obj: {
        photo: [],
        text: [],
        background: [],
        button: [],
        social: [],
        share: []
      }
    };
  }

  componentDidMount = () => {
    // const { companyObj } = this.props
    // const { loginUser } = this.state
    // const companyId = companyObj.objectId
    // const authPage = window.location.pathname.split('/')[2]
    // const authUser = companyId + '_' + authPage
    // let auth = {}
    // if ((loginUser.authAdminA&&loginUser.authAdminA.indexOf(companyId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
    //   auth = {
    //     edit: true,
    //   }
    // } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
    //   auth = {
    //     edit: false,
    //   }
    // } else {
    //   auth = {
    //     edit: false,
    //   }
    // }
    this.handleFetch(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, pageObj } = this.props
    const companyId = companyObj.objectId
    this.setState({ loading1: true })
    const data = await Parse.queryData('webimage', { companyId, pageId: pageObj.objectId });
    // console.log(data, rowData.objectId)
    this.setState({ loading1: false, data1: data });
  }

  handleChangeFile = (file, photo) => {
    const { obj } = this.state
    const textChange = document.getElementById(photo)
    textChange.innerHTML = `<img id='${photo}' src="${file}" alt='' />`
    obj.photo.push({ [photo]: file })
    this.setState({ obj })
  }

  handleChangeBackground = (file, background) => {
    const { obj } = this.state
    const backgroundChange = document.getElementById(background)
    backgroundChange.style.backgroundImage = `url(${file})`
    // textChange.innerHTML = `<img id='${photo}' src="${file}" alt='' />`
    obj.background.push({ [background]: file })
    this.setState({ obj })
  }

  handleUse = (text, id, content) => {
    const { obj } = this.state
    const textChange = document.getElementById(id)
    textChange.innerHTML = text

    obj.text.push({ [id]: text, title: id, content })
    this.setState({ obj })
  }

  handlePhotoDelete = (oldData) => {
    const { data1 } = this.state
    let data = [...data1];
    const index = data.indexOf(oldData);
    data.splice(index, 1);
    this.setState({ data1: data })
  }

  handleButton = (id, form) => {
    const { obj } = this.state
    const button = document.getElementById(id)
    button.innerHTML = `<a href="${form.src}" ${form.target === true ? 'target="_blank"' : ''}>${form.text}</a>`
    obj.button.push({ [id]: form })
    this.setState({ obj })
  }

  handleContact = (form) => {
    const { obj } = this.state
    document.getElementById('address').innerHTML = form.newAddress || ''
    document.getElementById('phone').innerHTML = form.newPhone || ''
    document.getElementById('time').innerHTML = form.newTime || ''
    document.getElementById('email').innerHTML = form.newEmail || ''
    const newObj = { ...obj, contact: form }
    this.setState({ obj: newObj })
  }

  handleSocial = (form) => {
    const { obj } = this.state
    // this.setState({ socialArr: [] })
    const data = []
    data.push(
      { url: form.facebook, icon: 'fa-facebook-square', className: 'facebook' },
      { url: form.instagram, icon: 'fa-instagram', className: 'instagram' },
      { url: form.line, icon: 'fa-line', className: 'line' },
      { url: form.linkedin, icon: 'fa-linkedin', className: 'linkedin' },
      { url: form.pinterest, icon: 'fa-pinterest', className: 'pinterest' },
      { url: form.telegram, icon: 'fa-telegram-plane', className: 'telegram' },
      { url: form.tumblr, icon: 'fa-tumblr', className: 'tumblr' },
      { url: form.twitter, icon: 'fa-twitter', className: 'twitter' },
      { url: form.weibo, icon: 'fa-weibo', className: 'weibo' },
      { url: form.weixin, icon: 'fa-weixin', className: 'weixin' },
      { url: form.whatsapp, icon: 'fa-whatsapp', className: 'whatsapp' },
      { url: form.youtube, icon: 'fa-youtube', className: 'youtube' },
    )
    obj.social.push(data)
    this.setState({ obj, socialArr: data })
  }

  handleShare = (form) => {
    const { obj } = this.state
    const data = []
    data.push(
      { checked: form.facebook, icon: 'fa-facebook-square', className: 'facebook' },
      { checked: form.line, icon: 'fa-line', className: 'line' },
      { checked: form.linkedin, icon: 'fa-linkedin', className: 'linkedin' },
      { checked: form.pinterest, icon: 'fa-pinterest', className: 'pinterest' },
      { checked: form.telegram, icon: 'fa-telegram-plane', className: 'telegram' },
      { checked: form.tumblr, icon: 'fa-tumblr-square', className: 'tumblr' },
      { checked: form.twitter, icon: 'fa-twitter', className: 'twitter' },
      { checked: form.weibo, icon: 'fa-weibo', className: 'weibo' },
      { checked: form.weixin, icon: 'fa-weixin', className: 'weixin' },
      { checked: form.whatsapp, icon: 'fa-whatsapp', className: 'whatsapp' },
    )
    obj.social.push(data)
    this.setState({ obj, shareArr: data })
  }

  handleCollapse = () => {
    const { collapse } = this.state
    const sideMenu = document.getElementById('sideMenu')
    const rightEditBox = document.getElementById('rightEditBox')
    const btn_sideMenu = document.getElementById('btn_sideMenu')
    if (!collapse) {
      sideMenu.classList.add('menu-left')
      rightEditBox.classList.remove('rightEditBox_w100')
      btn_sideMenu.innerHTML = '展開'
      this.setState({ collapse: true })
    } else {
      sideMenu.classList.remove('menu-left')
      rightEditBox.classList.add('rightEditBox_w100')
      btn_sideMenu.innerHTML = '收合'
      this.setState({ collapse: false })
    }
  }

  handlePreview = () => {

  }

  handleClose = () => { this.setState({ isOpen: false }) };

  handleOpen = () => { this.setState({ isOpen: true }) };

  handleChange = (e, { value }) => this.setState({ value })
  // handlePhotoAdd = (data) => {
  //   const { data1 } = this.state
  //   data.forEach(item => {
  //     data1.push(item)
  //   })
  //   this.setState({ data1 })
  // }
  //   handleChange = (e) => {
  //     // console.log(e);
  //     this.setState({
  //       [e.target.id]: e.target.value
  //     })
  //   }

  //   handleSubmit = (e) => {
  //     // console.log(e);
  //     e.preventDefault();
  //     this.props.createProject(this.state);
  //     this.props.history.push('/');
  //   }


  render() {
    const { isOpen, show, data1, socialArr, shareArr, obj } = this.state
    //     const { auth } = this.props;
    //     if (!auth.uid) return <Redirect to='/signin' />
    // const { value } = this.state
    console.log(obj)
    return (<>
      {/* <Container style={content}> */}
      <section id="rightEditBox" className="j-n-edit-box rightEditBox">
        <div className="section-wrapper first-section" id="goto278962">
          <div className="section-editor-tools">
            <div className="btn_item clearfix">
              <a className="j-n-set-layout">佈局 <i className="fas fa-undo"></i></a>
              <ModalEditBackground {...this.props} background='background1' handleChangeBackground={this.handleChangeBackground} originPic={'//img.holkee.com/site/theme/unknown/78534b40eea0ed80a9bbe4e49d777430_double.jpg'} />
            </div>
          </div>
          <div className="j-elm-background section-base mainBox background-setting" id='background1' style={{ backgroundImage: 'url(//img.holkee.com/site/theme/unknown/78534b40eea0ed80a9bbe4e49d777430_double.jpg)' }}>
            <div className="ContentsBox style-one">
              <div className="the_editBox f-edit-box show_lightBox">
                <div className="edit_LightBox">
                  {/* <a className="j-pop-image" onClick={this.onEdit}>編輯</a> */}
                  <ModalEditPic {...this.props} handleChangeFile={this.handleChangeFile} photo={'photo1'} originPic={'//img.holkee.com/site/theme/unknown/6caf44ea63578b440ab6aac66cd7783d_double.png'} />
                  <a className="j-pop-delete right_delete" data-popup="#popup-confirm" data-element="image" data-title="刪除確認" data-content="確定要刪除此項目嗎？"><i className="fas fa-times"></i></a>
                </div>
                <div id='photo1' className="j-elm-image imgBox">
                  <img src="//img.holkee.com/site/theme/unknown/6caf44ea63578b440ab6aac66cd7783d_double.png" alt='' />
                </div>
                {/* <a className="j-pop-image add_new_btn hide" data-popup="#popup-imagesetting">新增圖片</a> */}
              </div>
              {/* <div className="the_editBox show_lightBox">
            <div className="edit_LightBox">
              <a className="j-pop-text" data-popup="#popup-textsetting">編輯</a>
            </div>
            <p className="j-elm-content sub_title f-edit-text" placeholder="按一下以新增文字"></p>
          </div>
          <div className="aBox">
            <div className="f-btn-add">
              <div className="edit_LightBox">
                <a className="j-pop-button" data-popup="#popup-buttonsetting">編輯</a>
                <a className="j-pop-btn-delete right_delete" data-popup="#popup-confirm" data-element="button" data-title="刪除確認" data-content="確定要刪除此項目嗎？"><i className="fas fa-times"></i></a>
              </div>
              <p className="j-elm-button btn" style={{ display: 'none' }}><a></a></p>
              <a className="j-pop-button add_new_btn" data-popup="#popup-buttonsetting">新增按鈕</a>
            </div>
            <div className="f-btn-add">
              <div className="edit_LightBox">
                <a className="j-pop-button" data-popup="#popup-buttonsetting">編輯</a>
                <a className="j-pop-btn-delete right_delete" data-popup="#popup-confirm" data-element="button" data-title="刪除確認" data-content="確定要刪除此項目嗎？"><i className="fas fa-times"></i></a>
              </div>
              <p className="j-elm-button btn" style={{ display: 'none' }}><a></a></p>
              <a className="j-pop-button add_new_btn" data-popup="#popup-buttonsetting">新增按鈕</a>
            </div>
          </div> */}
            </div>
          </div>
        </div>
        <div className="section-wrapper" id="goto278963">
          <div className="section-editor-tools">
            <div className="btn_item clearfix">
              <a className="j-n-set-layout">佈局 <i className="fas fa-undo"></i></a>
              <ModalEditBackground {...this.props} background='goto278963' handleChangeBackground={this.handleChangeBackground} />
            </div>
          </div>
          <div className="j-elm-background section-base galleryBox" >
            <div className="ContentsBox clearfix">
              <div className="aBox">
                <div className="the_editBox show_lightBox">
                  <div className="edit_LightBox">
                    <ModalEditText {...this.props} id={'title1'} handleUse={this.handleUse} obj={obj} />
                  </div>
                  <h3 id='title1' className="j-elm-title main_title f-edit-text" placeholder="按一下以新增文字">PHOTOGRAPHY</h3>
                </div>
                <div className="the_editBox show_lightBox">
                  <div className="edit_LightBox">
                    <ModalEditText {...this.props} id={'title2'} handleUse={this.handleUse} obj={obj} />
                  </div>
                  <p id='title2' className="j-elm-content sub_title f-edit-text" placeholder="按一下以新增文字" style={{ fontWeight: 'bold' }}>Jack studio 成立於2015年，一直以來以數位藝術與設計作為不變的創作主軸。<br />最專業的人像/商品/美食/品牌形象攝影團隊。</p>
                </div>
              </div>
              <div className="aBox">
                <div className="the_editBox show_lightBox">
                  <div className="edit_LightBox">
                    <ModalEditPhotoes {...this.props} group={'album'} handlePhotoDelete={this.handlePhotoDelete} handleFetch={this.handleFetch} />
                  </div>
                  <div className="j-elm-gallery photo_list clearfix" style={{ alignItems: 'flex-end' }}>
                    {data1 && data1.length ?
                      data1.filter(item => item.group === 'album').map(item =>
                        <div className="grid_container">
                          <figure className="j-n-image photo">
                            <img src={(item.img1File && item.img1File.url) || ''} width="260" height="260" alt='' />
                            <figcaption className="j-n-caption" ><span>{item.description}</span></figcaption>
                          </figure>
                        </div>)
                      : <>
                        <div className="grid_container">
                          <figure className="j-n-image photo">
                            <img src="//img.holkee.com/site/theme/unknown/5ed97cfac8f9f9ba7fbee0bd3aaa674f_double.jpg" width="260" height="260" alt='' />
                            <figcaption className="j-n-caption" style={{ display: 'none' }}><span></span></figcaption>
                          </figure>
                        </div>
                        <div className="grid_container">
                          <figure className="j-n-image photo">
                            <img src="//img.holkee.com/site/theme/unknown/b4ad1464fd4f3535c6ed2532bb02dc24_double.jpg" width="260" height="260" alt='' />
                            <figcaption className="j-n-caption" style={{ display: 'none' }}><span></span></figcaption>
                          </figure>
                        </div>
                        <div className="grid_container">
                          <figure className="j-n-image photo">
                            <img src="//img.holkee.com/site/theme/unknown/fcb3b1a3b7dd8ddc96e76b2358e7e399_double.jpg" width="260" height="260" alt='' />
                            <figcaption className="j-n-caption" style={{ display: 'none' }}><span></span></figcaption>
                          </figure>
                        </div>
                        <div className="grid_container">
                          <figure className="j-n-image photo">
                            <img src="//img.holkee.com/site/theme/unknown/8018d290a985eab3d6f47c630a9295a2_double.jpg" width="260" height="260" alt='' />
                            <figcaption className="j-n-caption" style={{ display: 'none' }}><span></span></figcaption>
                          </figure>
                        </div>
                        <div className="grid_container">
                          <figure className="j-n-image photo">
                            <img src="//img.holkee.com/site/theme/unknown/ff2463a15c739851f10407a2e677afed_double.jpg" width="260" height="260" alt='' />
                            <figcaption className="j-n-caption" style={{ display: 'none' }}><span></span></figcaption>
                          </figure>
                        </div>
                        <div className="grid_container">
                          <figure className="j-n-image photo">
                            <img src="//img.holkee.com/site/theme/unknown/7dc07c0aca3527f8a0c4649419b7afea_double.jpg" width="260" height="260" alt='' />
                            <figcaption className="j-n-caption" style={{ display: 'none' }}><span></span></figcaption>
                          </figure>
                        </div>
                        <div className="grid_container">
                          <figure className="j-n-image photo">
                            <img src="//img.holkee.com/site/theme/unknown/efb4efac5e7c9d7b8f69075ea948951c_double.jpg" width="260" height="260" alt='' />
                            <figcaption className="j-n-caption" style={{ display: 'none' }}><span></span></figcaption>
                          </figure>
                        </div>
                        <div className="grid_container">
                          <figure className="j-n-image photo">
                            <img src="//img.holkee.com/site/theme/unknown/f89cf56a5b5391146b3a17dab2227893_double.jpg" width="260" height="260" alt='' />
                            <figcaption className="j-n-caption" style={{ display: 'none' }}><span></span></figcaption>
                          </figure>
                        </div>
                        <div className="grid_container">
                          <figure className="j-n-image photo">
                            <img src="//img.holkee.com/site/theme/unknown/e4bc1dd4c904d2faa66beced9982d5a9_double.jpg" width="260" height="260" alt='' />
                            <figcaption className="j-n-caption" style={{ display: 'none' }}><span></span></figcaption>
                          </figure>
                        </div>
                        <div className="grid_container">
                          <figure className="j-n-image photo">
                            <img src="//img.holkee.com/site/theme/unknown/63228355096376b2d297a31823816ce9_double.jpg" width="260" height="260" alt='' />
                            <figcaption className="j-n-caption" style={{ display: 'none' }}><span></span></figcaption>
                          </figure>
                        </div>
                        <div className="grid_container">
                          <figure className="j-n-image photo">
                            <img src="//img.holkee.com/site/theme/unknown/c29b985900c02b6ee4fe10b12ef8fa05_double.jpg" width="260" height="260" alt='' />
                            <figcaption className="j-n-caption" style={{ display: 'none' }}><span></span></figcaption>
                          </figure>
                        </div>
                        <div className="grid_container">
                          <figure className="j-n-image photo">
                            <img src="//img.holkee.com/site/theme/unknown/4a960ecc8acedc3aabb1eddb36e9f63e_double.jpg" width="260" height="260" alt='' />
                            <figcaption className="j-n-caption" style={{ display: 'none' }}><span></span></figcaption>
                          </figure>
                        </div>
                      </>}
                  </div>
                  {/* <p className="f-text-not-set"><span>尚未設定</span></p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-wrapper" id="goto278964">
          <div className="section-editor-tools">
            <div className="btn_item clearfix">
              <a className="j-n-set-layout">佈局 <i className="fas fa-undo"></i></a>
              <ModalEditBackground {...this.props} background='goto278964' handleChangeBackground={this.handleChangeBackground} />
            </div>
          </div>
          <div className="j-elm-background section-base textBox" id="goto278964">
            <div className="ContentsBox clearfix">
              <div className="aBox fl">
                <div className="the_editBox f-edit-box show_lightBox">
                  <div className="edit_LightBox">
                    {/* <a className="j-pop-image" data-popup="#popup-imagesetting">編輯</a> */}
                    <ModalEditPic {...this.props} handleChangeFile={this.handleChangeFile} photo={'photo2'} originPic={'////img.holkee.com/site/theme/unknown/3e521fcd4be65aef7413e43120a00888_double.jpg'} />
                    <a className="j-pop-delete right_delete" data-popup="#popup-confirm" data-element="image" data-title="刪除確認" data-content="確定要刪除此項目嗎？"><i className="fas fa-times"></i></a>
                  </div>
                  <div id='photo2' className="j-elm-image imgBox">
                    <img src="//img.holkee.com/site/theme/unknown/3e521fcd4be65aef7413e43120a00888_double.jpg" alt='' />
                  </div>
                  {/* <a className="j-pop-image add_new_btn hide" data-popup="#popup-imagesetting">新增圖片</a> */}
                </div>
              </div>
              <div className="aBox fr">
                <div className="the_editBox show_lightBox">
                  <div className="edit_LightBox">
                    <a className="j-pop-text" data-popup="#popup-textsetting">編輯</a>
                    <ModalEditText {...this.props} id={'title3'} handleUse={this.handleUse} obj={obj} />
                  </div>
                  <h3 id='title3' className="j-elm-title main_title f-edit-text" placeholder="按一下以新增文字">Jack studio</h3>
                </div>
                <div className="the_editBox show_lightBox">
                  <div className="edit_LightBox">
                    {/* <a className="j-pop-text" data-popup="#popup-textsetting">編輯</a> */}
                    <ModalEditText {...this.props} id={'title4'} handleUse={this.handleUse} obj={obj} />
                  </div>
                  <p id='title4' className="j-elm-content sub_title f-edit-text" placeholder="按一下以新增文字" style={{ textAlign: 'center' }}>《 服務項目 》<br /><br />廣告人像攝影<br /><br />建築室內空間攝影<br /><br />藝術品攝影<br /><br />商業攝<br /><br />企業形像攝影<br /><br />婚禮紀錄<br /><br />微電影拍製<br /><br />全家福攝影<br /><br />工商活動攝影<br /><br />行銷影片拍製</p>
                </div>
                <div className="the_editBox f-edit-box show_lightBox">
                  <div className="edit_LightBox">
                    <ModalEditButton {...this.props} id='contactUs' handleButton={this.handleButton} />
                    <a className="j-pop-delete right_delete" data-popup="#popup-confirm" data-element="button" data-title="刪除確認" data-content="確定要刪除此項目嗎？"><i className="fas fa-times"></i></a>
                  </div>
                  <p className="j-elm-button btn" id='contactUs'><a>聯繫我們</a></p>
                  {/* <a className="j-pop-button add_new_btn hide" data-popup="#popup-buttonsetting">新增按鈕</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-wrapper" id="goto278965">
          <div className="section-editor-tools">
            <div className="btn_item clearfix">
              <a className="j-n-set-layout">佈局 <i className="fas fa-undo"></i></a>
              <ModalEditBackground {...this.props} background='goto278965' handleChangeBackground={this.handleChangeBackground} />
            </div>
          </div>
          <div className="j-elm-background section-base textBox" id="goto278965">
            <div className="ContentsBox style-two clearfix">
              <div className="aBox">
                <div className="the_editBox f-edit-box show_lightBox">
                  <div className="edit_LightBox">
                    <ModalEditPic {...this.props} handleChangeFile={this.handleChangeFile} photo={'photo3'} originPic={'//img.holkee.com/site/theme/unknown/b60f8f4d657e39805c20ecb6ae7aa752_double.png'} />
                    <a className="j-pop-delete right_delete" data-popup="#popup-confirm" data-element="image" data-title="刪除確認" data-content="確定要刪除此項目嗎？"><i className="fas fa-times"></i></a>
                  </div>
                  <div id='photo3' className="j-elm-image imgBox">
                    <img src="//img.holkee.com/site/theme/unknown/b60f8f4d657e39805c20ecb6ae7aa752_double.png" alt='' />
                  </div>
                  {/* <a className="j-pop-image add_new_btn hide" data-popup="#popup-imagesetting">新增圖片</a> */}
                </div>
              </div>
              <div className="aBox">
                <div className="the_editBox show_lightBox">
                  <div className="edit_LightBox">
                    <ModalEditText {...this.props} id={'title5'} handleUse={this.handleUse} obj={obj} />
                  </div>
                  <h3 id='title5' className="j-elm-title main_title f-edit-text" placeholder="按一下以新增文字"></h3>
                </div>
                <div className="the_editBox show_lightBox">
                  <div className="edit_LightBox">
                    <ModalEditText {...this.props} id={'title6'} handleUse={this.handleUse} obj={obj} />
                  </div>
                  <p id='title6' className="j-elm-content sub_title f-edit-text" placeholder="按一下以新增文字"></p>
                </div>
                <div className="f-btn-add">
                  <div className="edit_LightBox">
                    <a className="j-pop-button" data-popup="#popup-buttonsetting">編輯</a>
                    <a className="j-pop-delete right_delete" data-popup="#popup-confirm" data-element="button" data-title="刪除確認" data-content="確定要刪除此項目嗎？"><i className="fas fa-times"></i></a>
                  </div>
                  <p className="j-elm-button btn" style={{ display: 'none' }}><a></a></p>
                  {/* <a className="j-pop-button add_new_btn" data-popup="#popup-buttonsetting">新增按鈕</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-wrapper" id="goto278966">
          <div className="section-editor-tools">
            <div className="btn_item clearfix">
              <a className="j-n-set-layout">佈局 <i className="fas fa-undo"></i></a>
              <ModalEditBackground {...this.props} background='goto278966' handleChangeBackground={this.handleChangeBackground} />
            </div>
          </div>
          <div className="j-elm-background section-base slidesBox" id="goto278966">
            <div className="ContentsBox style-one">
              <div className="text-box">
                <div className="the_editBox show_lightBox">
                  <div className="edit_LightBox">
                    <ModalEditText {...this.props} id={'title7'} handleUse={this.handleUse} obj={obj} />
                  </div>
                  <h3 id='title7' className="j-elm-title main_title f-edit-text" placeholder="按一下以新增文字">TEAM LEADER</h3>
                </div>
                <div className="the_editBox show_lightBox">
                  <div className="edit_LightBox">
                    <ModalEditText {...this.props} id={'title8'} handleUse={this.handleUse} obj={obj} />
                  </div>
                  <p id='title8' className="j-elm-content sub_title f-edit-text" placeholder="按一下以新增文字"></p>
                </div>
              </div>
              <div className="the_editBox show_lightBox">
                <div className="edit_LightBox">
                  <ModalEditPhotoes {...this.props} group={'teamLeader'} handlePhotoDelete={this.handlePhotoDelete} handleFetch={this.handleFetch} />
                </div>
                <div className="j-elm-slides slider-box" style={{ display: 'block' }}>
                  <ul className="data-box clearfix">
                    {data1.length ?
                      data1.filter(item => item.group === 'teamLeader').map(item =>
                        <li className="content-box">
                          <div className="j-n-image photo">
                            <img src={(item.img1File && item.img1File.url) || ''} alt='' />
                          </div>
                          <div className="info">
                            <div className="j-n-description description"><p>{item.description}</p>
                            </div>
                            <div className="j-n-button" style={{ display: 'none' }}><p><a className="btn" href="#"></a></p>
                            </div>
                          </div>
                        </li>)
                      : <>
                        <li className="content-box">
                          <div className="j-n-image photo">
                            <img src="//img.holkee.com/site/theme/unknown/723e15f48c280184067f61da38610596_double.jpg" alt='' />
                          </div>
                          <div className="info">
                            <div className="j-n-description description"><p>STEVE</p>
                            </div>
                            <div className="j-n-button" style={{ display: 'none' }}><p><a className="btn" href="#"></a></p>
                            </div>
                          </div>
                        </li>
                        <li className="content-box">
                          <div className="j-n-image photo">
                            <img src="//img.holkee.com/site/theme/unknown/c47bc2930cbcea7fe5c6568f9b825806_double.jpg" alt='' />
                          </div>
                          <div className="info">
                            <div className="j-n-description description"><p>ANN</p>
                            </div>
                            <div className="j-n-button" style={{ display: 'none' }}><p><a className="btn" href="#"></a></p>
                            </div>
                          </div>
                        </li>
                        <li className="content-box">
                          <div className="j-n-image photo">
                            <img src="//img.holkee.com/site/theme/unknown/bf28990d883349fa6745e0ab63da1618_double.jpg" alt='' />
                          </div>
                          <div className="info">
                            <div className="j-n-description description"><p>JESSICA</p>
                            </div>
                            <div className="j-n-button" style={{ display: 'none' }}><p><a className="btn" href="#"></a></p>
                            </div>
                          </div>
                        </li>
                        <li className="content-box">
                          <div className="j-n-image photo">
                            <img src="//img.holkee.com/site/theme/unknown/e53ddbeb4079b54031619d6c0823a80a_double.jpg" alt='' />
                          </div>
                          <div className="info">
                            <div className="j-n-description description"><p>CECILIA</p>
                            </div>
                            <div className="j-n-button" style={{ display: 'none' }}><p><a className="btn" href="#"></a></p>
                            </div>
                          </div>
                        </li>
                        <li className="content-box">
                          <div className="j-n-image photo">
                            <img src="//img.holkee.com/site/theme/unknown/b0aa967460b665c3262254e831176ffe_double.jpg" alt='' />
                          </div>
                          <div className="info">
                            <div className="j-n-description description"><p>KIM HEECHUL</p>
                            </div>
                            <div className="j-n-button" style={{ display: 'none' }}><p><a className="btn" href="#"></a></p>
                            </div>
                          </div>
                        </li>
                        <li className="content-box">
                          <div className="j-n-image photo">
                            <img src="//img.holkee.com/site/theme/unknown/2686d4107829e98e7eecf92849916c85_double.jpg" alt='' />
                          </div>
                          <div className="info">
                            <div className="j-n-description description"><p>ABNER</p>
                            </div>
                            <div className="j-n-button" style={{ display: 'none' }}><p><a className="btn" href="#"></a></p>
                            </div>
                          </div>
                        </li>
                        <li className="content-box">
                          <div className="j-n-image photo">
                            <img src="//img.holkee.com/site/theme/unknown/c4f1b68dc36d22e1b2c78ad41f0e84a2_double.jpg" alt='' />
                          </div>
                          <div className="info">
                            <div className="j-n-description description"><p>HARDY</p>
                            </div>
                            <div className="j-n-button" style={{ display: 'none' }}><p><a className="btn" href="#"></a></p>
                            </div>
                          </div>
                        </li>
                      </>}
                  </ul>
                </div>
                {/* <p className="f-text-not-set"><span>尚未設定</span></p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="section-wrapper" id="goto278967">
          <div className="section-editor-tools">
            <div className="btn_item clearfix">
              <a className="j-n-set-layout">佈局 <i className="fas fa-undo"></i></a>
              <ModalEditBackground {...this.props} background='goto278967' handleChangeBackground={this.handleChangeBackground} />
            </div>
          </div>
          <div className="j-elm-background section-base contactBox" id="goto278967">
            <div className="ContentsBox clearfix">
              <div className="aBox">
                <div className="mapBox">
                  <div className="j-elm-code map_default">
                    <p className="f-text-not-set"><span>請以網頁預覽為準</span></p>
                  </div>
                </div>
              </div>
              <div className="aBox">
                <div className="the_editBox show_lightBox">
                  <div className="edit_LightBox">
                    <ModalEditText {...this.props} id={'title9'} handleUse={this.handleUse} obj={obj} />
                  </div>
                  <h3 id='title9' className="j-elm-title main_title f-edit-text" placeholder="按一下以新增文字">Jack studio</h3>
                </div>
                <div className="the_editBox show_lightBox">
                  <div className="edit_LightBox">
                    <ModalEditText {...this.props} id={'title10'} handleUse={this.handleUse} obj={obj} />
                  </div>
                  <p id='title10' className="j-elm-content sub_title f-edit-text" placeholder="按一下以新增文字">歡迎各種拍攝與合作邀約</p>
                </div>
                <div className="the_editBox show_lightBox">
                  <div className="edit_LightBox">
                    <ModalEditContact {...this.props} handleContact={this.handleContact} />
                  </div>
                  <ul className="j-elm-info info">
                    <li><p className="address" id="address">台北市信義路五段7號</p></li><li><p className="phone" id="phone">0908-000-1234</p></li><li><p className="time" id="time">10:00 AM - 11:30 PM</p></li><li><p className="email" id="email">service@example.com</p></li></ul>
                  {/* <p className="f-text-not-set"><span>尚未設定</span></p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-wrapper" id="goto278968">
          <div className="section-editor-tools">
            <div className="btn_item clearfix">
              <a className="j-n-set-layout">佈局 <i className="fas fa-undo"></i></a>
              <ModalEditBackground {...this.props} background='goto278968' handleChangeBackground={this.handleChangeBackground} originPic={'//img.holkee.com/site/theme/unknown/7b4a4d229ba25f7ed9a0b484c9c7eb59_double.jpg'} />
            </div>
          </div>
          <div className="j-elm-background section-base socialBox background-setting" id="goto278968" style={{ backgroundImage: 'url(//img.holkee.com/site/theme/unknown/7b4a4d229ba25f7ed9a0b484c9c7eb59_double.jpg)' }}>
            <div className="ContentsBox">
              <div className="the_editBox show_lightBox">
                <div className="edit_LightBox">
                  <ModalEditText {...this.props} id={'title11'} handleUse={this.handleUse} obj={obj} />
                </div>
                <h3 id='title11' className="j-elm-title main_title f-edit-text" placeholder="按一下以新增文字">FOLLOW US</h3>
              </div>
              <div className="the_editBox show_lightBox">
                <div className="edit_LightBox">
                  <ModalEditText {...this.props} id={'title12'} handleUse={this.handleUse} obj={obj} />
                </div>
                <p id='title12' className="j-elm-content sub_title f-edit-text" placeholder="按一下以新增文字" style={{ fontWeight: 'bold' }}>關注我們的 Facebook 粉絲頁或追蹤我們的 Instagram，以關注我們的最新動態！</p>
              </div>
              <div className="the_editBox show_lightBox">
                <div className="edit_LightBox">
                  <ModalEditSocial {...this.props} handleSocial={this.handleSocial} />
                </div>
                <ul className="j-elm-follow follow_us_icon">
                  {socialArr.length ?
                    socialArr.filter(item => item.url).map(item =>
                      <li>
                        <a className={item.className} href={item.url} style={{ backgroundColor: "rgb(0, 0, 0)" }}>
                          <i className={`fab ${item.icon}`}></i>
                        </a>
                      </li>
                    ) : <>
                      <li>
                        <a className='facebook' href="#" style={{ backgroundColor: "rgb(0, 0, 0)" }}>
                          <i className="fab fa-facebook-square"></i>
                        </a>
                      </li>
                      <li>
                        <a className='instagram' href="#" style={{ backgroundColor: "rgb(0, 0, 0)" }}>
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a className='line' href="#" style={{ backgroundColor: "rgb(0, 0, 0)" }}>
                          <i className="fab fa-line"></i>
                        </a>
                      </li>
                      <li>
                        <a className='linkedin' href="#" style={{ backgroundColor: "rgb(0, 0, 0)" }}>
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a className='pinterest' href="#" style={{ backgroundColor: "rgb(0, 0, 0)" }}>
                          <i className="fab fa-pinterest"></i>
                        </a>
                      </li>
                    </>}
                </ul>
                {/* <p className="f-text-not-set"><span>尚未設定</span></p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="s-footerBox">
          <div className="ContentsBox clearfix">
            <div className="the_editBox show_lightBox">
              <div className="edit_LightBox">
                <ModalEditShare {...this.props} handleShare={this.handleShare} />
              </div>
              <ul className="j-elm-share share-tools">
                {shareArr.length ?
                  shareArr.filter(item => item.checked === true).map(item =>
                    <li>
                      <label id={`new${item.className}`} className={item.className}><i className={`fab ${item.icon}`}></i><span>{item.className}</span></label>
                    </li>
                  )
                  : <>
                    <li>
                      <label className="facebook"><i className="fab fa-facebook-square"></i><span>Facebook</span></label>
                    </li>
                    <li><label className="pinterest"><i className="fab fa-pinterest"></i><span>Pinterest</span></label>
                    </li>
                    <li><label className="twitter"><i className="fab fa-twitter"></i><span>Twitter</span></label>
                    </li>
                  </>}
              </ul>
              {/* <p className="f-text-not-set"><span>尚未設定</span></p> */}
            </div>
            <div className="the_editBox show_lightBox">
              <div className="edit_LightBox">
                <ModalEditText {...this.props} id={'title13'} handleUse={this.handleUse} obj={obj} />
              </div>
              <p id='title13' className="j-elm-content copyrights f-edit-text" placeholder="按一下以新增文字">Copyrights All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </section>
      <section id="sideMenu" className="j-n-menu-left sideMenu dLeft menu-left">
        <div className="menu_container">
          <a id="btn_sideMenu" className="j-n-function-menu btn_sideMenu" onClick={this.handleCollapse}><p>收合</p></a>
          <ul className="other_tool clearfix">
            <li><p><a className="j-n-preview" onClick={this.handlePreview}><i className="fas fa-eye"></i>預覽</a></p></li>
            <li><p><a className="j-pop-publish"><i className="fas fa-check"></i>儲存</a></p></li>
          </ul>
          {/* <div className="top_headline">
            <p>版塊總覽 <a className="f-tip"><i className="far fa-question-circle"></i><span style={{ display: 'none' }}>提醒您，最多只能新增 5 個版塊</span></a></p>
          </div>
          <div className="rollingBox">
            <div className="j-n-menusection"><ul className="mainMenu"><li className="f-daggle the_hide">
              <div className="the_section clearfix">
                <i className="icon_move fas fa-bars"></i>
                <div className="section_name clearfix">
                  <h3 className="j-n-goto">Main</h3>
                  <i className="icon_eye_hide far fa-eye-slash" title="已在選單中設定為隱藏" style={{ display: 'inline' }}></i>
                  <a className="j-n-more"><i className="more_function fas fa-angle-down"></i></a>
                </div>
                <div className="edit_title clearfix" style={{ display: 'none' }}>
                  <input type="text" name="text" placeholder="請輸入版塊名稱" maxlength="30" />
                  <a className="j-n-update-menu-text"><i className="fas fa-check"></i></a>
                </div>
              </div>
              <div className="subBox">
                <ul className="clearfix">
                  <li><p><a className="j-n-change-visible"><i className="far fa-eye"></i><span>顯示</span></a></p></li>
                  <li><p><a className="j-n-edit-menu-text"><i className="fas fa-pencil-alt"></i>命名</a></p></li>
                  <li><p><a className="j-n-copy-section"><i className="far fa-copy"></i>複製</a></p></li>
                  <li><p><a className="j-pop-delete" data-popup="#popup-confirm" data-title="刪除確認" data-content="確定要刪除此版塊嗎？"><i className="far fa-trash-alt"></i>刪除</a></p></li>
                </ul>
              </div>
            </li>
              <li className="f-daggle">
                <div className="the_section clearfix">
                  <i className="icon_move fas fa-bars"></i>
                  <div className="section_name clearfix">
                    <h3 className="j-n-goto">PHOTOGRAPHY</h3>
                    <i className="icon_eye_hide far fa-eye-slash" title="已在選單中設定為隱藏" style={{ display: 'none' }}></i>
                    <a className="j-n-more"><i className="more_function fas fa-angle-down"></i></a>
                  </div>
                  <div className="edit_title clearfix" style={{ display: 'none' }}>
                    <input type="text" name="text" placeholder="請輸入版塊名稱" maxlength="30" />
                    <a className="j-n-update-menu-text"><i className="fas fa-check"></i></a>
                  </div>
                </div>
                <div className="subBox">
                  <ul className="clearfix">
                    <li><p><a className="j-n-change-visible"><i className="far fa-eye-slash"></i><span>隱藏於選單</span></a></p></li>
                    <li><p><a className="j-n-edit-menu-text"><i className="fas fa-pencil-alt"></i>命名</a></p></li>
                    <li><p><a className="j-n-copy-section"><i className="far fa-copy"></i>複製</a></p></li>
                    <li><p><a className="j-pop-delete" data-popup="#popup-confirm" data-title="刪除確認" data-content="確定要刪除此版塊嗎？"><i className="far fa-trash-alt"></i>刪除</a></p></li>
                  </ul>
                </div>
              </li>
              <li className="f-daggle">
                <div className="the_section clearfix">
                  <i className="icon_move fas fa-bars"></i>
                  <div className="section_name clearfix">
                    <h3 className="j-n-goto">ABOUT JACK</h3>
                    <i className="icon_eye_hide far fa-eye-slash" title="已在選單中設定為隱藏" style={{ display: 'none' }}></i>
                    <a className="j-n-more"><i className="more_function fas fa-angle-down"></i></a>
                  </div>
                  <div className="edit_title clearfix" style={{ display: 'none' }}>
                    <input type="text" name="text" placeholder="請輸入版塊名稱" maxlength="30" />
                    <a className="j-n-update-menu-text"><i className="fas fa-check"></i></a>
                  </div>
                </div>
                <div className="subBox">
                  <ul className="clearfix">
                    <li><p><a className="j-n-change-visible"><i className="far fa-eye-slash"></i><span>隱藏於選單</span></a></p></li>
                    <li><p><a className="j-n-edit-menu-text"><i className="fas fa-pencil-alt"></i>命名</a></p></li>
                    <li><p><a className="j-n-copy-section"><i className="far fa-copy"></i>複製</a></p></li>
                    <li><p><a className="j-pop-delete" data-popup="#popup-confirm" data-title="刪除確認" data-content="確定要刪除此版塊嗎？"><i className="far fa-trash-alt"></i>刪除</a></p></li>
                  </ul>
                </div>
              </li>
              <li className="f-daggle the_hide">
                <div className="the_section clearfix">
                  <i className="icon_move fas fa-bars"></i>
                  <div className="section_name clearfix">
                    <h3 className="j-n-goto">Text</h3>
                    <i className="icon_eye_hide far fa-eye-slash" title="已在選單中設定為隱藏" style={{ display: 'inline' }}></i>
                    <a className="j-n-more"><i className="more_function fas fa-angle-down"></i></a>
                  </div>
                  <div className="edit_title clearfix" style={{ display: 'none' }}>
                    <input type="text" name="text" placeholder="請輸入版塊名稱" maxlength="30" />
                    <a className="j-n-update-menu-text"><i className="fas fa-check"></i></a>
                  </div>
                </div>
                <div className="subBox">
                  <ul className="clearfix">
                    <li><p><a className="j-n-change-visible"><i className="far fa-eye"></i><span>顯示</span></a></p></li>
                    <li><p><a className="j-n-edit-menu-text"><i className="fas fa-pencil-alt"></i>命名</a></p></li>
                    <li><p><a className="j-n-copy-section"><i className="far fa-copy"></i>複製</a></p></li>
                    <li><p><a className="j-pop-delete" data-popup="#popup-confirm" data-title="刪除確認" data-content="確定要刪除此版塊嗎？"><i className="far fa-trash-alt"></i>刪除</a></p></li>
                  </ul>
                </div>
              </li>
              <li className="f-daggle selected">
                <div className="the_section clearfix">
                  <i className="icon_move fas fa-bars"></i>
                  <div className="section_name clearfix">
                    <h3 className="j-n-goto">TEAM LEADER</h3>
                    <i className="icon_eye_hide far fa-eye-slash" title="已在選單中設定為隱藏" style={{ display: 'none' }}></i>
                    <a className="j-n-more"><i className="more_function fas fa-angle-down"></i></a>
                  </div>
                  <div className="edit_title clearfix" style={{ display: 'none' }}>
                    <input type="text" name="text" placeholder="請輸入版塊名稱" maxlength="30" />
                    <a className="j-n-update-menu-text"><i className="fas fa-check"></i></a>
                  </div>
                </div>
                <div className="subBox">
                  <ul className="clearfix">
                    <li><p><a className="j-n-change-visible"><i className="far fa-eye-slash"></i><span>隱藏於選單</span></a></p></li>
                    <li><p><a className="j-n-edit-menu-text"><i className="fas fa-pencil-alt"></i>命名</a></p></li>
                    <li><p><a className="j-n-copy-section"><i className="far fa-copy"></i>複製</a></p></li>
                    <li><p><a className="j-pop-delete" data-popup="#popup-confirm" data-title="刪除確認" data-content="確定要刪除此版塊嗎？"><i className="far fa-trash-alt"></i>刪除</a></p></li>
                  </ul>
                </div>
              </li>
              <li className="f-daggle">
                <div className="the_section clearfix">
                  <i className="icon_move fas fa-bars"></i>
                  <div className="section_name clearfix">
                    <h3 className="j-n-goto">CONTACT US</h3>
                    <i className="icon_eye_hide far fa-eye-slash" title="已在選單中設定為隱藏" style={{ display: 'none' }}></i>
                    <a className="j-n-more"><i className="more_function fas fa-angle-down"></i></a>
                  </div>
                  <div className="edit_title clearfix" style={{ display: 'none' }}>
                    <input type="text" name="text" placeholder="請輸入版塊名稱" maxlength="30" />
                    <a className="j-n-update-menu-text"><i className="fas fa-check"></i></a>
                  </div>
                </div>
                <div className="subBox">
                  <ul className="clearfix">
                    <li><p><a className="j-n-change-visible"><i className="far fa-eye-slash"></i><span>隱藏於選單</span></a></p></li>
                    <li><p><a className="j-n-edit-menu-text"><i className="fas fa-pencil-alt"></i>命名</a></p></li>
                    <li><p><a className="j-n-copy-section"><i className="far fa-copy"></i>複製</a></p></li>
                    <li><p><a className="j-pop-delete" data-popup="#popup-confirm" data-title="刪除確認" data-content="確定要刪除此版塊嗎？"><i className="far fa-trash-alt"></i>刪除</a></p></li>
                  </ul>
                </div>
              </li>
              <li className="f-daggle">
                <div className="the_section clearfix">
                  <i className="icon_move fas fa-bars"></i>
                  <div className="section_name clearfix">
                    <h3 className="j-n-goto">FOLLOW US</h3>
                    <i className="icon_eye_hide far fa-eye-slash" title="已在選單中設定為隱藏" style={{ display: 'none' }}></i>
                    <a className="j-n-more"><i className="more_function fas fa-angle-down"></i></a>
                  </div>
                  <div className="edit_title clearfix" style={{ display: 'none' }}>
                    <input type="text" name="text" placeholder="請輸入版塊名稱" maxlength="30" />
                    <a className="j-n-update-menu-text"><i className="fas fa-check"></i></a>
                  </div>
                </div>
                <div className="subBox">
                  <ul className="clearfix">
                    <li><p><a className="j-n-change-visible"><i className="far fa-eye-slash"></i><span>隱藏於選單</span></a></p></li>
                    <li><p><a className="j-n-edit-menu-text"><i className="fas fa-pencil-alt"></i>命名</a></p></li>
                    <li><p><a className="j-n-copy-section"><i className="far fa-copy"></i>複製</a></p></li>
                    <li><p><a className="j-pop-delete" data-popup="#popup-confirm" data-title="刪除確認" data-content="確定要刪除此版塊嗎？"><i className="far fa-trash-alt"></i>刪除</a></p></li>
                  </ul>
                </div>
              </li>
            </ul></div>
            <p className="add_section"><a className="j-pop-add" data-popup="#popup-addsection" style={{ display: 'none' }}><i className="fas fa-plus"></i>新增版塊</a></p>
            <div className="top_headline"><p>外部連結</p></div>
            <div className="j-n-menuextlink"></div>
            <p className="add_section"><a className="j-n-add-extlink"><i className="fas fa-plus"></i>新增連結</a></p>
          </div>
          <div className="other_assistance clearfix">
            <div className="top_headline">
              <p>輔助功能開關 <a className="f-tip"><i className="far fa-question-circle"></i><span>快速顯示或隱藏「選單」或「分享」區塊</span></a></p>
            </div>
            <div className="btn_switch clearfix">
              <p>選單</p>
              <div className="switch_type"><input id="switch-menu" type="checkbox" /><label className="button" for="switch-menu"></label></div>
            </div>
            <div className="btn_switch clearfix">
              <p>分享</p>
              <div className="switch_type"><input id="switch-share" type="checkbox" /><label className="button" for="switch-share"></label></div>
            </div>
          </div> */}
          <p style={{ marginTop: '50px' }} className="copyrights">Copyrights Ⓒ 2020 All Rights Reserved.</p>
        </div>
      </section>
      <ModalGateway>
        {isOpen ? (
          <Modal onClose={this.handleClose}>
            <img src='https://img.holkee.com/site/upload/5f927880-1818ap0ab-0ee3-9284e4fc/59dc4041abc7b57f5e7d9587bd8d89d4_double.jpg' alt='' />
          </Modal>
        ) : null}
      </ModalGateway>
      {/* </Container> */}
    </>)
  }
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     createProject: (project) => dispatch(createProject(project))
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HostIndex)
export default StartUp