import React, { Component } from 'react'
import { Icon, Table, Input, Label, Form, Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';
import { Link } from 'react-router-dom';

import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import { getOptionsSelByKey } from 'views/widgets/FieldsRender';
import Parse from '../../../widget/parse'

// const style = {
//   tableShow: {
//     display: 'table',
//   },
//   tableHide: {
//     display: 'none',
//   }
// }

export default class ParttimeInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // activeIndex: props.match.params.activeIndex1 || '0',
      // doc: props.students[0],
      // docId: props.students[0]._id,
      arrRow: [],
      loading: true,
      show: false,
      classSchool: 'A',
      parttimeData: [],
      students: [],
      parttime: []
    };
  }

  async componentDidMount() {
    // console.log('componentDidMount');
    const { companyObj } = this.props
    const data = await Parse.queryData('Students', { companyId: companyObj.objectId });
    this.setState({ students: data });

    const data1 = await Parse.queryData('Parttime', { companyId: companyObj.objectId });
    this.setState({ parttime: data1 }, () => this.handleFetch());
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = (props = this.props) => {
    // const { firebase } = props
    const { classSchool, students, parttime } = this.state

    const tmp = students.filter(item =>
      (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
      (item.applyResult === '1' || item.studentType === "2"));
    const arrRow = tmp.filter(item => item.classSchool === classSchool); // 找到這班的所有人
    this.setState({ arrRow, loading: 1 });

    // const db = firebase.firestore();
    const promises2 = [];
    arrRow.forEach(doc => {

      promises2.push(parttime);
    });

    Promise.all(promises2).then(results => {
      // console.log('all promises were done')
      // console.log(results);
      const parttimeData = results.filter(item => item.length)
        .sort((a, b) => new Date(b.investDate) - new Date(a.investDate));
      this.setState({ parttimeData, loading: 0 }, () => this.getPrintData())
    }).catch(error => {
      console.log(error);
    });
  }

  handleChange = (event, data) => this.setState({ classSchool: data.value }, () => this.handleFetch())

  getPrintData = () => {
    // const { students } = this.props;
    const { parttimeData, arrRow, students } = this.state;
    let obj = {};
    let params = [];
    arrRow.map((item, index) => {//目前只抓班級中的學生
      const part = parttimeData.find(item2 => item2[0].studentId === item._id) &&
        parttimeData.find(item2 => item2[0].studentId === item._id).filter(data => data.during === true)
      part && part.map(parttime => {
        obj.permitNum = !part.length ? '' : parttime.permitNum
        obj.permitDate = !part.length ? '' : parttime.permitDate
        obj.storeName = !part.length ? '' : parttime.storeName
        obj.storeAddressAndTel = !part.length ? '' : parttime.storeAddressAndTel
        // obj.storetel = !part.length ? '' : parttime.storetel
        obj.workday = !part.length ? '' : parttime.workday
        obj.worktime = !part.length ? '' : parttime.worktime
        obj.salary = !part.length ? '' : parttime.salary
        obj.personincharge = !part.length ? '' : parttime.personincharge
        obj.partLength = part.length || 0
        let doc = { ...item, ...obj }
        params.push(doc)
        obj = {}
      })
    })
    this.setState({ params })
  }

  render() {
    const { history, match, optSet, companyObj } = this.props;
    const { arrRow, loading, classSchool, parttimeData, params } = this.state
    const classSchoolOpt = getOptionsSelByKey(optSet, "classSchool");

    // console.log(parttimeData)
    return (<div ref={el => (this.componentRef = el)} className="">
      <h2 style={{ float: 'left' }}>{classSchool}クラス資格外活動情報一覧表</h2>
      <div style={{ float: 'right' }}>
        {/* <ReactToPrint
          trigger={(e) => <Button
            content='印刷'
            // label='&nbsp;'
            color='orange' icon='print'
            size='small'
            floated='right'
            className='no-print'
          />}
          content={() => this.componentRef}
          pageStyle={"@page { size: auto;  margin:5mm; }*{font-family: 'Noto Serif JP', serif;}"}
        /> */}
        <ModalPdf
          {...this.props}
          // ref={(ref) => { this.pdfModal = ref }}
          codekey="table05"
          params={params}
        />
        <ExportTableToExcelButton floated='right' size='small' />
        <Input labelPosition='left' style={{ marginRight: 20 }}>
          <Label color='violet'><Icon name='building' />クラス</Label>
          <Form.Select
            style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
            name='classroom'
            value={classSchool}
            options={classSchoolOpt}
            onChange={this.handleChange} />
        </Input>
      </div>
      <div style={{ clear: 'both' }}></div>
      <Table celled striped compact structured
        className="thPadding2px tdPadding2px" id='excel'>
        <Table.Header >
          <Table.Row textAlign='center'>
            <Table.HeaderCell >学籍番号</Table.HeaderCell>
            <Table.HeaderCell >氏名</Table.HeaderCell>
            <Table.HeaderCell >国籍</Table.HeaderCell>
            <Table.HeaderCell >許可番号</Table.HeaderCell>
            <Table.HeaderCell >許可期限</Table.HeaderCell>
            <Table.HeaderCell >店名</Table.HeaderCell>
            <Table.HeaderCell >店の住所・電話番号</Table.HeaderCell>
            {/* <Table.HeaderCell >店の住所</Table.HeaderCell>
            <Table.HeaderCell >聯絡電話番号</Table.HeaderCell> */}
            <Table.HeaderCell >曜日</Table.HeaderCell>
            <Table.HeaderCell >時間</Table.HeaderCell>
            <Table.HeaderCell >時給</Table.HeaderCell>
            <Table.HeaderCell >店の担当者</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body >
          {arrRow.length ? arrRow.map((item, index) => {
            const parttime = (parttimeData.find(item2 => item2[0].studentId === item._id) &&
              parttimeData.find(item2 => item2[0].studentId === item._id).filter(data => data.during === true)) || [];
            return <><Table.Row key={index}>
              <Table.Cell rowSpan={parttime.length || 1}>{item.studentId}</Table.Cell>
              <Table.Cell rowSpan={parttime.length || 1}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item._id}/2/0`}>{item.jpnName}</Link></Table.Cell>
              <Table.Cell rowSpan={parttime.length || 1}>{item.nationalityText}</Table.Cell>
              <Table.Cell rowSpan={parttime.length || 1}>{!parttime.length ? '' : parttime[0].permitNum}</Table.Cell>
              <Table.Cell rowSpan={parttime.length || 1}>{!parttime.length ? '' : parttime[0].permitDate}</Table.Cell>
              <Table.Cell>{!parttime.length ? '' : parttime[0].storeName}</Table.Cell>
              <Table.Cell>{!parttime.length ? '' : parttime[0].storeAddressAndTel}</Table.Cell>
              {/* <Table.Cell>{!parttime.length ? '' : parttime[0].storeAddress}</Table.Cell>
              <Table.Cell>{!parttime.length ? '' : parttime[0].storetel}</Table.Cell> */}
              <Table.Cell>{!parttime.length ? '' : parttime[0].workday}</Table.Cell>
              <Table.Cell>{!parttime.length ? '' : parttime[0].worktime}</Table.Cell>
              <Table.Cell>{!parttime.length ? '' : parttime[0].salary}</Table.Cell>
              <Table.Cell>{!parttime.length ? '' : parttime[0].personincharge}</Table.Cell>
            </Table.Row>
              {parttime.length > 1 && parttime.filter(item3 => item3.during === true).map((part, index2) =>
                index2 > 0 ?
                  <Table.Row key={index2}>
                    <Table.Cell>{!parttime.length ? '' : part.storeName}</Table.Cell>
                    <Table.Cell>{!parttime.length ? '' : part.storeAddressAndTel}</Table.Cell>
                    <Table.Cell>{!parttime.length ? '' : part.workday}</Table.Cell>
                    <Table.Cell>{!parttime.length ? '' : part.worktime}</Table.Cell>
                    <Table.Cell>{!parttime.length ? '' : part.salary}</Table.Cell>
                    <Table.Cell>{!parttime.length ? '' : part.personincharge}</Table.Cell>
                  </Table.Row> : null)}
            </>
          }) : (<Table.Row><Table.Cell colSpan='12' textAlign='center'>(資料無し)</Table.Cell></Table.Row>)}
        </Table.Body>
      </Table>
      {loading ? <Segment>
        <Dimmer active inverted={companyObj.inverted}>
          <Loader inverted={companyObj.inverted}>Loading</Loader>
        </Dimmer>
        <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
      </Segment> : null}
    </div>)
  }
}
