export const userOpt = {
  is_auth: [
    { value: '0', label: '離職' },
    { value: '1', label: '在職' },
  ],
  role: [
    { value: '0', label: '一般' },
    { value: '1', label: 'agent' },
  ],
}

export const clientHanburyOpt = {
  sexual_text: [
    { value: '0', label: '女' },
    { value: '1', label: '男' },
  ],
  marriage: [
    { value: '0', label: '未婚' },
    { value: '1', label: '已婚' },
    { value: '2', label: '同居' },
    { value: '3', label: '分居' },
    { value: '4', label: '離婚' },
  ],
}

export const portfolioOpt = {
  is_trust: [
    { value: '1', label: 'Y' },
    { value: '2', label: 'N' },
  ],
  trust_isrevoke: [
    { value: '1', label: '可撤銷' },
    { value: '2', label: '不可撤銷' },
  ],
  bodychecktype1: [
    { value: '1', label: 'SPNT' },
    { value: '2', label: 'PNT' },
    { value: '3', label: 'SNT' },
    { value: '4', label: 'PT' },
    { value: '5', label: 'ST' },
  ],
  bodychecktype2: [
    { value: '1', label: 'Table 0' },
    { value: '2', label: 'Table A' },
    { value: '3', label: 'Table B' },
    { value: '4', label: 'Table C' },
    { value: '5', label: 'Table D' },
  ],
  bodychecktype3: [
    { value: '1', label: 'Preferred Best' },
    { value: '2', label: 'Preferred Non-Tobacco' },
    { value: '3', label: 'Non-Somker Plus' },
    { value: '4', label: 'Non-Smoker' },
    { value: '5', label: 'Preferred Smoker' },
    { value: '6', label: 'Smoker' },
  ],
  nowphase: [
    { value: '1', label: '申請中' },
    { value: '2', label: '已生效' },
    { value: '3', label: '無效' },
  ],
  currency_type: [
    { value: '0', label: '台幣NTD' },
    { value: '1', label: '港幣HKD' },
    { value: '2', label: '人民幣RMB' },
    { value: '3', label: '美金USD' },
    { value: '4', label: '英鎊GBP' },
    { value: '5', label: '歐元EUR' },
    { value: '6', label: '日圓JPY' },
  ],
  stockitem: [
    { value: '1', label: 'Hanburfore & Colonial Realty Co., Ltd' },
    { value: '2', label: 'Hanburfore & Colonial II Realty Co., Ltd' },
    { value: '3', label: 'Hanburfore & Colonial III Realty Co., Ltd' },
  ],
  irs_w7_status: [
    { value: '1', label: '申請中' },
    { value: '2', label: '已完成' },
    { value: '3', label: '待處理' },
  ],
  itin_status: [
    { value: '1', label: '申請中' },
    { value: '2', label: '已完成' },
    { value: '3', label: '待處理' },
  ],
  giveinterest_period: [
    { value: '1', label: '一次性' },
    { value: '2', label: '半年' },
    { value: '3', label: '一年' },
  ],
  certpassport_status: [
    { value: '1', label: '申請中' },
    { value: '2', label: '已完成' },
    { value: '3', label: '待處理' },
  ],
  payMonthNum: [
    { value: '0', label: '0月' },
    { value: '1', label: '1月' },
    { value: '2', label: '2月' },
    { value: '3', label: '3月' },
    { value: '4', label: '4月' },
    { value: '5', label: '5月' },
    { value: '6', label: '6月' },
    { value: '7', label: '7月' },
    { value: '8', label: '8月' },
    { value: '9', label: '9月' },
    { value: '10', label: '10月' },
    { value: '11', label: '11月' },
    { value: '12', label: '12月' },
  ],
  assumeleave_year: [
    { value: '2000', label: '2000' },
    { value: '2001', label: '2001' },
    { value: '2002', label: '2002' },
    { value: '2003', label: '2003' },
    { value: '2004', label: '2004' },
    { value: '2005', label: '2005' },
    { value: '2006', label: '2006' },
    { value: '2007', label: '2007' },
    { value: '2008', label: '2008' },
    { value: '2009', label: '2009' },
    { value: '2010', label: '2010' },
    { value: '2011', label: '2011' },
    { value: '2012', label: '2012' },
    { value: '2013', label: '2013' },
    { value: '2014', label: '2014' },
    { value: '2015', label: '2015' },
    { value: '2016', label: '2016' },
    { value: '2017', label: '2017' },
    { value: '2018', label: '2018' },
    { value: '2019', label: '2019' },
    { value: '2020', label: '2020' },
    { value: '2021', label: '2021' },
    { value: '2022', label: '2022' },
    { value: '2023', label: '2023' },
    { value: '2024', label: '2024' },
    { value: '2025', label: '2025' },
    { value: '2026', label: '2026' },
    { value: '2027', label: '2027' },
    { value: '2028', label: '2028' },
    { value: '2029', label: '2029' },
    { value: '2030', label: '2030' },
    { value: '2031', label: '2031' },
    { value: '2032', label: '2032' },
    { value: '2033', label: '2033' },
    { value: '2034', label: '2034' },
    { value: '2035', label: '2035' },
    { value: '2036', label: '2036' },
    { value: '2037', label: '2037' },
    { value: '2038', label: '2038' },
    { value: '2039', label: '2039' },
    { value: '2040', label: '2040' },
    { value: '2041', label: '2041' },
    { value: '2042', label: '2042' },
    { value: '2043', label: '2043' },
    { value: '2044', label: '2044' },
    { value: '2045', label: '2045' },
    { value: '2046', label: '2046' },
    { value: '2047', label: '2047' },
    { value: '2048', label: '2048' },
    { value: '2049', label: '2049' },
    { value: '2050', label: '2050' },
  ],
  assumeleave_season: [
    { value: '1', label: '第一季' },
    { value: '2', label: '第二季' },
    { value: '3', label: '第三季' },
    { value: '4', label: '第四季' },
  ],
  p5Country: [
    { value: '1', label: '美國' },
    { value: '2', label: '加拿大' },
  ],
  insurance_status: [
    { value: '1', label: '生效 In force' },
    { value: '2', label: '解約 Surrender' },
  ],
  payperiod_fpi: [
    { value: '1', label: 'Annually' },
    { value: '2', label: 'Six Monthly' },
    { value: '3', label: 'Quarterly' },
    { value: '4', label: 'Monthly' },
    { value: '5', label: 'Single Premium' },
  ],
  paymethod_fpi: [
    { value: '1', label: 'Credit Card' },
    { value: '2', label: 'BSO' },
    { value: '3', label: 'Direct Paying' },
  ],
  policy_period_month: [
    { value: '0', label: '0月' },
    { value: '1', label: '1月' },
    { value: '2', label: '2月' },
    { value: '3', label: '3月' },
    { value: '4', label: '4月' },
    { value: '5', label: '5月' },
    { value: '6', label: '6月' },
    { value: '7', label: '7月' },
    { value: '8', label: '8月' },
    { value: '9', label: '9月' },
    { value: '10', label: '10月' },
    { value: '11', label: '11月' },
    { value: '12', label: '12月' },
  ],
  insurancepay_status: [
    { value: '1', label: '繳款中Premium paying' },
    { value: '2', label: '終止繳費Paid up' },
  ],
  policy_currency_type: [
    { value: '0', label: '台幣NTD' },
    { value: '1', label: '港幣HKD' },
    { value: '2', label: '人民幣RMB' },
    { value: '3', label: '美金USD' },
    { value: '4', label: '英鎊GBP' },
    { value: '5', label: '歐元EUR' },
    { value: '6', label: '日圓JPY' },
  ],
  company_type: [
    { value: '1', label: 'LP' },
    { value: '2', label: 'LLC' },
    { value: '3', label: '無' },
  ],
  invest_type: [
    { value: '1', label: '產權' },
    { value: '2', label: '股權' },
    { value: '3', label: '無' },
  ],
  is_leave: [
    { value: '1', label: 'Y' },
    { value: '2', label: 'N' },
  ]
}

export const clientPawnOpt = {
  sexualId: [
    { value: '0', label: '女' },
    { value: '1', label: '男' },
  ],
  marriageState: [
    { value: '0', label: '未婚' },
    { value: '1', label: '已婚' },
    { value: '2', label: '其他' },
  ],
  childNum: [
    { value: '0', label: '0人' },
    { value: '1', label: '1人' },
    { value: '2', label: '2人' },
    { value: '3', label: '3人' },
    { value: '4', label: '4人' },
    { value: '5', label: '5人' },
    { value: '6', label: '6人' },
    { value: '7', label: '7人' },
    { value: '8', label: '8人' },
    { value: '9', label: '9人' },
    { value: '10', label: '10人' },
  ],
  education: [
    { value: '0', label: '博士' },
    { value: '1', label: '碩士' },
    { value: '2', label: '大學' },
    { value: '3', label: '專科' },
    { value: '4', label: '高中（職）' },
    { value: '5', label: '國中以下' },
  ],
  livingState: [
    { value: '0', label: '自有或配偶所有' },
    { value: '1', label: '親友所有' },
    { value: '2', label: '父母所有' },
    { value: '3', label: '租賃' },
    { value: '4', label: '宿舍' },
  ],
  contactPerson1_relationship: [
    { value: '0', label: '父' },
    { value: '1', label: '母' },
    { value: '2', label: '兄' },
    { value: '3', label: '姊' },
    { value: '4', label: '弟' },
    { value: '5', label: '妹' },
    { value: '6', label: '子' },
    { value: '7', label: '女' },
  ],
  contactPerson2_relationship: [
    { value: '0', label: '父' },
    { value: '1', label: '母' },
    { value: '2', label: '兄' },
    { value: '3', label: '姊' },
    { value: '4', label: '弟' },
    { value: '5', label: '妹' },
    { value: '6', label: '子' },
    { value: '7', label: '女' },
    { value: '8', label: '其他' },
  ],
}

export const employeePawnOpt = {
  sexualId: [
    { value: '0', label: '女' },
    { value: '1', label: '男' },
  ],
}

export const userPawnOpt = {
  sexualId: [
    { value: '0', label: '女' },
    { value: '1', label: '男' },
  ],
}