import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, typeCurrency, exportPdf } from 'views/web/CustomMaterialTable';

// 1 月報表
const columns2 = (payTypeId = '') => ([
  { width: 80, title: '期數', field: 'period', editable: 'never', hidden: payTypeId !== '3' },
  { width: 80, title: '計劃繳息日期', field: 'planDate', editable: 'never' },
  { width: 80, title: '本金結餘', field: 'principalRest', editable: 'never', ...typeCurrency },
  { width: 80, title: '應收利息', field: 'interestPlan', editable: 'never', ...typeCurrency },
  dateCol({ title: '還本日期', field: 'principalPaidDate' }),
  { width: 80, title: '本金還本', field: 'principalPaid', headerStyle: { textAlign: 'left' }, ...typeCurrency },
  dateCol({ title: '繳息日期', field: 'interestPaidDate' }),
  { width: 80, title: '繳息金額', field: 'interestPaid', headerStyle: { textAlign: 'left' } },
  { width: 180, title: '備註', field: 'ps' },
]);

export default class ClientPawning2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data2: [],
      newData: {},
      oldData: {},
    }
    this.tableRef = React.createRef();
  }

  componentDidMount = () => {
    this.setState({ data2: this.props.data2 });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data2: nextProps.data2 });
  }

  handleAdd = async () => {
    const { newData } = this.state
    await Parse.saveData('pawning2', newData);
    await this.props.reorgPawning12()
  }

  handleUpdate = async () => {
    const { newData } = this.state
    await Parse.saveData('pawning2', newData);
    await this.props.reorgPawning12()
  }

  handleDelete = async () => {
    const { oldData } = this.state
    await Parse.deleteData('pawning2', oldData);
    await this.props.reorgPawning12()
    this.setState({ oldData: {} })
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  render() {
    const { pawning1Obj, loading2 } = this.props;
    const { data2 } = this.state
    const { ordernum = '', payTypeId = '3' } = pawning1Obj;
    return (<>
      <MaterialTable
        isLoading={loading2}
        localization={localization()}
        columns={columns2(payTypeId)}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `服務內容：${ordernum}`),
          pageSize: 20,// data2.length < 5 ?  : data2.length + 1,
          search: false,
          tableLayout: 'fixed',
        }}
        data={data2}
        tableRef={this.tableRef}
        title={`服務內容：${ordernum}`}
        editable={{
          // onRowAdd: newData =>
          //   new Promise(async (resolve, reject) => {
          //     const data = [...data2];
          //     const db = Parse.Object.extend("pawning2");
          //     const newDoc = new db();
          //     const doc = await newDoc.save();

          //     const obj = {
          //       ...pawning1Obj,
          //       _id: doc.id,
          //       id: doc.id,
          //       objectId: doc.id,
          //       pawning1Id: pawning1Obj.objectId,
          //       ...newData,
          //       // currentBalance: String(Number(newData.principal || 0) - Number(newData.repaymentMoney || 0)),
          //     };

          //     data.push(obj);
          //     this.setState({ data2: data, newData: obj }, () => resolve());
          //   }).then(() => this.handleUpdate()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...data2];
              const index = data.indexOf(oldData);
              const obj = {
                ...newData,
                interestPaid: Number(newData.interestPaid)
              }

              data[index] = obj;
              this.setState({ data2: data, newData: obj, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...data2];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              this.setState({ data2: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
    </>)
  }
}