import React, { Component } from 'react';
import { Form, Button, Table, Grid, Icon, Header, Confirm, TextArea, Progress, Image } from 'semantic-ui-react';
// import Dropzone from 'react-dropzone'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { LocaleUtils } from 'react-day-picker';
import Moment from 'moment';
import Parse from 'widget/parse.js'
import { showField, showFieldText, getOptionsByKey } from 'views/widgets/FieldsRender';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { async } from 'parse/lib/browser/Storage';
import { readFileAsURL } from 'views/web/RowImage';
import ModalUploadFiles from 'views/web/ModalUploadFiles';

// import { hidden } from 'ansi-colors';

// import MyScrollBSTable from '../../../components/GridTable/MyScrollBSTable2';
const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-wrap'
  }
}

export default class InquiryStudent extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      userData: this.props.user.profile.toJSON(),
      show: false,
      isReadOnly: false,
      form: { inquiryDate: this.getToday() },// 所有的 資料都先更新在這邊 保存時再一併寫回去
      loading: false,
      confirm: false,
      inquiry: [],
      readOnly: false,
      filenames: [],
      downloadURLs: [],
      fileObj: {},//新增的檔案
      files: [],//備份的檔案
      filesData: [],
      disabled: false,
      error: false
    };

    this.attachmentRef = [];
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, match, listType } = props;
    // const { filesData } = this.state
    const { _id, list } = match.params;

    if (!_id) { return; }
    let dbName = 'Consult';
    if (listType === '1') {
      dbName = 'Inquiry';
    }
    if (listType === '1') {
      const inquiry = await Parse.queryData(dbName, { companyId: companyObj.objectId, studentId: _id });
      // firebase.firestore().collection(`Students/${_id}/${dbName}`)
      //   .get().then(snap => {
      //     const data = [];
      //     snap.forEach(doc => data.push(doc.data()));
      //     this.setState({ inquiry: data });
      //   }, err => {
      //     console.log(`Encountered error: ${err}`);
      //   });
      this.setState({ inquiry })
    } else {
      const inquiry = await Parse.queryData(dbName, { companyId: companyObj.objectId, studentId: _id });
      this.setState({ inquiry })
      // firebase.firestore().collection(`Students/${_id}/${dbName}`).get().then(snap => {
      //   var promises = [];
      //   snap.forEach(item => {
      //     var promise = firebase.firestore().collection(`Students/${_id}/${dbName}/${item.id}/files`)
      //       .get().then(snapshot => {
      //         // console.log(snapshot.size);
      //         const filesData = []
      //         snapshot.forEach(fileItem => filesData.push({ ...fileItem.data() }));
      //         return { _id: item.id, ...item.data(), filesData };
      //       });
      //     promises.push(promise);
      //   });
      //   Promise.all(promises).then(results => {
      //     // console.log('all promises were done')
      //     // console.log(results);
      //     this.setState({ inquiry: results })
      //   }).catch(error => {
      //     console.log(error);
      //   });

      // }, err => {
      //   console.log(`Encountered error: ${err}`);
      // });
    }
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    const { form } = this.state
    this.setState({
      form: {
        ...form,
        [name]: value
      }
    })
  }

  handleSubmit = async () => {
    const { doc, listType, companyObj } = this.props
    const { form, fileObj, userData } = this.state
    const setting = { ...this.state.form }
    let dbName = ''
    setting.inquiryDate = new Date(setting.inquiryDate)
    if (listType === '1') {
      dbName = 'Inquiry';
      setting.studentId = doc.objectId;
      setting.recruitNum = doc.recruitNum;
      setting.homeName = doc.homeName || '';
      setting.handlerName = userData.name;
      setting.companyId = companyObj.objectId
      setting.companyName = companyObj.name
    } else {
      dbName = 'Consult';
      setting.studentId = doc.objectId;
      setting.studentJpnName = doc.jpnName;
      setting.handlerName = userData.name;
      setting.companyId = companyObj.objectId
      setting.companyName = companyObj.name
    }


    if (!form.objectId) {
      const db = Parse.Object.extend(dbName);
      const newDoc = new db();
      const ref = await newDoc.save();
      await Parse.saveData(dbName, { ...setting, objectId: ref.id }).then(() => this.setState({ form: { ...form, objectId: ref.id, inquiryDate: this.getToday() }, loading: false }, () => this.handleClose()))
      // firebase.firestore().collection(`Students/${doc._id}/${dbName}`).add(setting)
      //   .then((docRef) => {
      //     if (fileObj && fileObj.length)
      //       firebase.firestore().collection(`Students/${doc._id}/${dbName}/${docRef.id}/files`).add({ ...fileObj, inquiryId: docRef.id })
      //         .then((file) => {
      //           firebase.firestore().collection(`Students/${doc._id}/${dbName}/${docRef.id}/files`).doc(file.id)
      //             .update({ _id: file.id })
      //             .then(snap => this.setState({ fileObj: { ...fileObj, _id: file.id } }))
      //         })
      //     // firebase.firestore().collection(`Students/${doc._id}/${dbName}/${docRef.id}/files`).add(fileObj)
      //     //   this.setState(oldState => ({
      //     //   filenames: [...oldState.filenames, filename],
      //     //   downloadURLs: [...oldState.downloadURLs, downloadURL],
      //     //   uploadProgress: 100,
      //     //   isUploading: false
      //     // }));
      //     firebase.firestore().collection(`Students/${doc._id}/${dbName}`).doc(docRef.id)
      //       .update({ _id: docRef.id })
      //       .then(snap => this.setState({ form: { ...form, _id: docRef.id }, loading: false }, () => this.handleClose()))
      //   })
    } else {
      await Parse.saveData(dbName, setting).then(() => this.setState({ loading: false, form: { inquiryDate: this.getToday() } }, () => this.handleClose()))
      // firebase.firestore().collection(`Students/${doc._id}/${dbName}`).doc(form.objectId)
      //   .update(form).then(snap => {
      //     files && files.map(file => {
      //       if (file._id && form.filesData.find(item => item._id !== file._id)) {
      //         firebase.firestore().collection(`Students/${doc._id}/${dbName}/${form.objectId}/files`).doc(file._id).delete()
      //       }
      //     })

      //     if (fileObj.hasOwnProperty('src') && fileObj.hasOwnProperty('filename')) {
      //       firebase.firestore().collection(`Students/${doc._id}/${dbName}/${form.objectId}/files`).add({ ...fileObj, inquiryId: form.objectId })
      //         .then((file) => {
      //           firebase.firestore().collection(`Students/${doc._id}/${dbName}/${form.objectId}/files`).doc(file.id)
      //             .update({ _id: file.id })
      //             .then(snap2 => this.setState({ loading: false }, () => this.handleClose()))
      //         })
      //     } else {
      //       this.setState({ loading: false }, () => this.handleClose())
      //     }
      //   });
    }
    this.handleFetch();
  }

  handleEdit = (relativeObj) => {
    const { filesData } = relativeObj;
    // console.log(relativeObj)
    this.setState({ show: true, form: { ...relativeObj }, files: filesData });
  }

  handleOpen = () => {
    this.setState({ show: true })
  }

  handleClose = () => {
    this.setState({ show: false, form: { inquiryDate: this.getToday() }, readOnly: false, fileObj: {} });
  }

  handleRemove = async () => {
    const { firebase, doc, listType } = this.props
    const { form, fileObj, inquiry } = this.state;

    let dbName = 'Consult';
    if (listType === '1') {
      dbName = 'Inquiry';
    }

    // form.filesData && form.filesData.length && form.filesData.forEach(item => {
    //   firebase.storageRef.child(`images/${item.filename}`).delete();
    //   firebase.firestore().collection(`Students/${doc._id}/${dbName}/${form.objectId}/files`).doc(item._id).delete()
    // })
    // firebase.firestore().collection(`Students/${doc._id}/${dbName}`).doc(form.objectId).delete().then(

    // )
    console.log(form)
    await Parse.deleteData(dbName, form)
    let findIndex = inquiry.findIndex(item => item.objectId === form.objectId)
    let newRow = inquiry.splice(findIndex, 1)
    this.setState({ inquiry: newRow })
    this.handleClose();
    this.hideConfirm();
    this.handleFetch();
  }

  isValidDate = (date) => {
    return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
  }

  handleDayChange = (date) => {
    if (this.isValidDate(date))
      this.setState({ form: { ...this.state.form, inquiryDate: date.toISOString() } });
  }

  deleteAttachment = (index) => {
    const { form } = this.state;
    const attachmentArr = form.attachment;

    attachmentArr.splice(index, 1);

    this.setState({ form: { ...form, attachment: attachmentArr } });
  }

  getToday = () => {
    // return new Date()
    return new Date().toISOString();
  };

  showConfirm = (relativeObj) => {
    this.setState({ confirm: true, form: { ...relativeObj } });
  }
  hideConfirm = () => this.setState({ confirm: false, form: {} });

  // handleUploadStart = () => this.setState({ isUploading: true, progress: 0, disabled: true });
  // handleProgress = progress => this.setState({ progress });
  // handleUploadError = error => {
  //   this.setState({ isUploading: false, disabled: false, error: true });
  //   console.error(error);
  // };
  // handleUploadSuccess = filename => {
  //   this.setState({ avatar: filename, progress: 100, isUploading: false });
  //   firebase
  //     .storage()
  //     .ref("images")
  //     .child(filename)
  //     .getDownloadURL()
  //     .then(url => this.setState({ avatarURL: url }));
  // };
  // handleUploadSuccess = async filename => {
  //   const { firebase, match, listType } = this.props;
  //   const { fileObj, form } = this.state

  //   const downloadURL = await this.props.firebase
  //     .storage()
  //     .ref("images")
  //     .child(filename)
  //     .getDownloadURL();

  //   // console.log(filename);

  //   // firebase.firestore().collection(`albums/${match.params.id}/photos`).add({ src: downloadURL, filename });
  //   this.setState(oldState => ({
  //     fileObj: { src: downloadURL, filename },
  //     filenames: [...oldState.filenames, filename],
  //     downloadURLs: [...oldState.downloadURLs, downloadURL],
  //     uploadProgress: 100,
  //     isUploading: false,
  //     form: { ...form, src: downloadURL, filename },
  //     disabled: false
  //   }));
  // };

  clearFileObj = () => this.setState({ fileObj: {} })
  handleRemoveFile = (file) => {
    const { firebase, doc } = this.props
    const { form } = this.state

    // let data = form.filesData.filter(item => item._id !== file._id)
    // form.filesData = data
    // this.setState({ form })

    if (file._id) {
      firebase.storageRef.child(`images/${file.filename}`) &&
        firebase.storageRef.child(`images/${file.filename}`).delete();
      firebase.firestore().collection(`Students/${doc._id}/Consult/${form.objectId}/files`).doc(file._id).delete()
        .then(snap => {
          let data = form.filesData.filter(item => item._id !== file._id)
          form.filesData = data
          if (form.src === file.src) {
            form.src = ''
            form.filename = ''
          }
          this.setState({ form }, () => firebase.firestore().collection(`Students/${doc._id}/Consult`).doc(form.objectId).update(form))
          // firebase.firestore().collection(`Students/${doc._id}/${dbName}`).doc(form.objectId).update(form)
        })
    }
  }
  // getPrintData = () => {
  //   const { inquiry } = this.state
  //   const { formSet, optSet, doc } = this.props
  //   let printData = [];
  //   inquiry.map(item => {
  //     if (item) {
  //       let obj = { ...item }
  //       obj.studentId = doc.studentId;
  //       obj.classSchool = doc.classSchool;
  //       obj.inquiryDate = Moment(item.inquiryDate.split('T')[0]).format('YYYY/MM/DD');
  //       obj.inquiryType = showFieldText(formSet, optSet, "inquiryType", item);
  //       obj.contactMethod = showFieldText(formSet, optSet, "inquiryWay", item);
  //       printData.push(obj)
  //     }
  //   })
  //   this.setState({ printData })
  // }


  handleFileChange = async (e) => {
    const { handleChangeFile, multiple, setImagePreviewUrl } = this.props
    e.preventDefault();

    const files = e.target.files;
    if (!files || !files.length) {
      return
    }
    const promises = [];
    for (let i = 0; i < files.length; i++) {
      const item = files[i];
      if (item.type && item.type.indexOf('image') !== -1) {
        const element = readFileAsURL(item)
        promises.push(element);
      } else {
        promises.push({ type: 'file', filename: item.name });
      }
      const file = await Parse.putFile(item)
      console.log(file, item.name)
    }

    const imagePreviewUrlArr = await Promise.all(promises).catch(error => console.log("error", error));
    console.log(imagePreviewUrlArr)
    this.setState({ form: { filesData: imagePreviewUrlArr } })
    // if (multiple) {
    //   handleChangeFile(files)
    // }
  }

  render() {
    const { teacher, optSet, formSet, doc, listType, isEdit, companyObj } = this.props;
    const { form, isReadOnly, loading, inquiry, readOnly, filesData, fileObj, disabled, error } = this.state;

    let inquiryWayArr = getOptionsByKey(optSet, 'inquiryWay');
    let enquiryResultArr = getOptionsByKey(optSet, 'enquiryResult');
    let inquiryTypeArr = getOptionsByKey(optSet, 'inquiryType');
    let enquiryWayArr = getOptionsByKey(optSet, 'enquiryWay');

    const inquiryWayArrOp = inquiryWayArr.sort((a, b) => a.value - b.value).map(({ _id, value, label }) => ({ key: _id, value: value, text: label }))
    const inquiryWayArrOpArr = [
      { key: 'sel', text: '-- 選択 --', value: ' ', disabled: false },
      ...inquiryWayArrOp
    ]
    const enquiryWayArrOp = enquiryWayArr.sort((a, b) => a.value - b.value).map(({ _id, value, label }) => ({ key: _id, value: value, text: label }))
    const enquiryWayArrOpArr = [
      { key: 'sel', text: '-- 選択 --', value: ' ', disabled: false },
      ...enquiryWayArrOp
    ]
    const enquiryResultArrOp = enquiryResultArr.sort((a, b) => a.value - b.value).map(({ _id, value, label }) => ({ key: _id, value: value, text: label }))
    const enquiryResultArrOpArr = [
      { key: 'sel', text: '-- 選択 --', value: ' ', disabled: false },
      ...enquiryResultArrOp
    ]

    const inquiryTypeArrOp = inquiryTypeArr.sort((a, b) => b.value - a.value).map(({ _id, value, label }) => ({ key: _id, value: value, text: label }))
    const inquiryTypeArrOpArr = [
      { key: 'sel', text: '-- 選択 --', value: ' ', disabled: false },
      ...inquiryTypeArrOp
    ]

    return (<>
      {this.state.isUploading && <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 10000,
        width: '100vw'
      }}>
        <Progress percent={this.state.progress} indicating error={error} progress />
      </div>}
      {listType === "1" ? null : <Header as='h2' dividing
        content="相談記録"
      // subheader='調査時間：2017年4月から 2018年12月 まで'
      />}
      <Button disabled={!isEdit} inverted={companyObj.inverted} color='blue' onClick={this.handleOpen} icon='add' content='追加' />
      <Table celled structured>
        {listType === "1" ?
          <Table.Header>
            <Table.Row>
              {inquiry.length && isEdit ? <Table.HeaderCell></Table.HeaderCell> : null}
              <Table.HeaderCell width={2}>日付</Table.HeaderCell>
              {/* <Table.HeaderCell>問合せ法</Table.HeaderCell> */}
              <Table.HeaderCell>內容</Table.HeaderCell>
              {/* <Table.HeaderCell>返答</Table.HeaderCell>
              <Table.HeaderCell>担当者</Table.HeaderCell> */}
              {/* <Table.HeaderCell>進行狀況</Table.HeaderCell> */}
              {/* {tableHeadArr.map((item, index) => (<Table.HeaderCell key={`th${index}`}>{item.text}</Table.HeaderCell>))} */}
            </Table.Row>
          </Table.Header>
          : <Table.Header>
            <Table.Row>
              {inquiry.length ? <Table.HeaderCell rowSpan="2"></Table.HeaderCell> : null}
              <Table.HeaderCell rowSpan="2">相談日</Table.HeaderCell>
              <Table.HeaderCell>相談法</Table.HeaderCell>
              <Table.HeaderCell rowSpan="2">相談內容</Table.HeaderCell>
              <Table.HeaderCell>学生連絡法</Table.HeaderCell>
              <Table.HeaderCell rowSpan="2">添付ファイル</Table.HeaderCell>
              <Table.HeaderCell rowSpan="2">経辦人</Table.HeaderCell>
              {/* {tableHeadArr.map((item, index) => (<Table.HeaderCell key={`th${index}`}>{item.text}</Table.HeaderCell>))} */}
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>相談類別</Table.HeaderCell>
              <Table.HeaderCell>学生連絡資訊</Table.HeaderCell>
            </Table.Row>
          </Table.Header>}
        {listType === "1" ? //問合
          <Table.Body>
            {!inquiry.length ? <Table.Row>
              <Table.Cell colSpan={isEdit ? 7 : 6} textAlign='center' >(資料無し)</Table.Cell>
            </Table.Row> : null}
            {inquiry.map((item, index) => (<React.Fragment key={`td${index}`}>
              <Table.Row>
                {isEdit ?
                  <Table.Cell style={{ padding: 0, maxWidth: 50 }}>
                    {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}> */}
                    <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} icon='edit' />
                    <Button inverted={companyObj.inverted} color='red' size='small' onClick={() => this.showConfirm(item)} icon='delete' />
                    {/* </div> */}
                  </Table.Cell> : null}
                <Table.Cell >{item.inquiryDate ? item.inquiryDate.iso ? item.inquiryDate.iso.split('T')[0] : item.inquiryDate.toISOString().split('T')[0] : ''}</Table.Cell>
                {/* <Table.Cell style={style.textEllipsis}>{showFieldText(formSet, optSet, "enquiryWay", item)}</Table.Cell> */}
                <Table.Cell style={{ ...style.textEllipsis, boxSizing: 'border-box', textOverflow: 'linebreak' }}>{`${item.content}`}</Table.Cell>
                {/* <Table.Cell style={{ ...style.textEllipsis, boxSizing: 'border-box' }}>{item.answer}</Table.Cell>
                <Table.Cell style={style.textEllipsis}>{item.handlerName}</Table.Cell> */}
                {/* <Table.Cell style={style.textEllipsis}>{showFieldText(formSet, optSet, "enquiryResult", item)}</Table.Cell> */}
                {/* <Table.Cell style={{ ...style.textEllipsis, boxSizing: 'border-box', maxWidth: 50 }}>{item.enquiryResult}</Table.Cell> */}
              </Table.Row>
            </React.Fragment>
            ))}
          </Table.Body>
          //相談
          : <Table.Body>
            {!inquiry.length ? <Table.Row>
              <Table.Cell colSpan={isEdit ? 7 : 6} textAlign='center' >(資料無し)</Table.Cell>
            </Table.Row> : null}
            {inquiry.map((item, index) => (<React.Fragment key={`td${index}`}>
              <Table.Row>
                {isEdit ?
                  <Table.Cell rowSpan="2" style={{ ...style.textEllipsis, padding: 10, maxWidth: 40 }}>
                    {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}> */}
                    <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} icon='edit' />
                    <Button inverted={companyObj.inverted} color='red' size='small' onClick={() => this.showConfirm(item)} icon='delete' />
                    {/* </div> */}
                  </Table.Cell> :
                  <Table.Cell rowSpan='2' style={{ ...style.textEllipsis, padding: 0, maxWidth: 40 }}>
                    {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}> */}
                    <Button inverted={companyObj.inverted} color='yellow' size='small' onClick={() => this.setState({ readOnly: true }, () => this.handleEdit(item))}>詳細</Button>
                    {/* </div> */}
                  </Table.Cell>
                }
                <Table.Cell rowSpan="2">{item.inquiryDate ? item.inquiryDate.iso ? item.inquiryDate.iso.split('T')[0] : item.inquiryDate.split('T')[0] : ''}</Table.Cell>
                <Table.Cell style={style.textEllipsis}>{showFieldText(formSet, optSet, "inquiryWay", item)}</Table.Cell>

                <Table.Cell rowSpan="2" style={{ ...style.textEllipsis, boxSizing: 'border-box', maxWidth: 126 }}>{item.content}</Table.Cell>
                <Table.Cell style={{ ...style.textEllipsis, boxSizing: 'border-box', maxWidth: 126 }}>{showFieldText(formSet, optSet, "contactMethod", item)}</Table.Cell>

                <Table.Cell rowSpan="2" style={style.textEllipsis}>
                  {/* {filesData.length ? filesData.map((item2, index2) => <Image key={index2} src={item2.src} style={{ maxWidth: 120, display: 'block', margin: '0 auto' }} />) : null} */}
                  {item.filesData && item.filesData.length ? item.filesData.map((item2, index2) => <a href={item2.src} target="_blank" rel="noopener noreferrer" key={index2}><Image src={item2.src} style={{ maxWidth: 120, display: 'block', margin: '0 auto' }} /></a>) : null}
                </Table.Cell>
                <Table.Cell rowSpan="2" style={style.textEllipsis}>{item.handlerName}</Table.Cell>

              </Table.Row>
              <Table.Row>
                <Table.Cell style={{ ...style.textEllipsis, boxSizing: 'border-box', maxWidth: 126 }}>{showFieldText(formSet, optSet, "inquiryType", item)}</Table.Cell>
                <Table.Cell style={{ ...style.textEllipsis, boxSizing: 'border-box', maxWidth: 126 }}>{item.contactInfo}</Table.Cell>
              </Table.Row>
            </React.Fragment>
            ))}
          </Table.Body>}
      </Table>
      {/* {listType === "1" ?
        <Grid.Row>
          <Grid columns='4'>
            <Grid.Column >{showField(this.props, "enquiryResult")}</Grid.Column>
          </Grid>
        </Grid.Row> : null} */}
      {/* <Modal open={this.state.show} onClose={this.handleClose}> */}
      <Modal
        isOpen={this.state.show}
        toggle={this.handleClose}
        size="lg"
        className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader style={style.flexCenter}>
          <h2 style={{ margin: 0 }}> {!isEdit ? (listType === "1" ? "問合せ" : "相談") : (form.objectId ? '修正' : '追加')}記録</h2>
          <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
        </ModalHeader>
        <ModalBody>
          <Grid>
            <Grid.Row columns='equal'>
              <Grid.Column>
                <label style={{ display: 'block', marginBottom: 5 }}>{listType === "1" ? "日付" : '相談日付'}</label>
                <DayPickerInput
                  onDayChange={this.handleDayChange} style={{ display: 'block' }} localeUtils={LocaleUtils} locale="id"
                  value={form.inquiryDate ? form.inquiryDate.iso ? form.inquiryDate.iso.split('T')[0] : form.inquiryDate.split('T')[0] : ''}
                  inputProps={{
                    className: "form-control",
                    disabled: readOnly,
                    style: {
                      border: '1px solid rgba(34,36,38,.15)',
                      borderRadius: '.3rem',
                      minWidth: 196,
                      minHeight: 38,
                      padding: '.7rem',
                      outline: 'none'
                    }
                  }}
                />
              </Grid.Column>

              <Grid.Column>
                {/* <label style={{ display: 'block', marginBottom: 5 }}>{listType === "1" ? '問合せ法' : '相談法'}</label> */}
                <label style={{ display: 'block', marginBottom: 5 }}>{listType === "1" ? '' : '相談法'}</label>
                {listType === "1" ? null :
                  // <Form.Select
                  //   options={enquiryWayArrOpArr}
                  //   defaultValue={form.objectId ? form.enquiryWay : " "}
                  //   name="enquiryWay"
                  //   onChange={this.handleChange}
                  //   disabled={readOnly}
                  // /> :
                  <Form.Select
                    options={inquiryWayArrOpArr}
                    defaultValue={form.objectId ? form.inquiryWay : " "}
                    name="inquiryWay"
                    onChange={this.handleChange}
                    disabled={readOnly}
                  />}
              </Grid.Column>
              {listType === "1" ? null
                : <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>相談類別</label>
                  <Form.Select
                    options={inquiryTypeArrOpArr}
                    defaultValue={form.objectId ? form.inquiryType : " "}
                    name="inquiryType"
                    onChange={this.handleChange}
                    disabled={readOnly}
                  />
                </Grid.Column>}
            </Grid.Row>
            {listType === "1" ? null
              :
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>学生連絡法</label>
                  <Form.Select
                    options={inquiryWayArrOpArr}
                    defaultValue={form.objectId ? form.contactMethod : " "}
                    name="contactMethod"
                    onChange={this.handleChange}
                    disabled={readOnly}
                  />
                </Grid.Column>

                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>学生連絡資訊</label>
                  <Form.Input name="contactInfo" style={{ minWidth: '196px' }}
                    onChange={this.handleChange} value={form.contactInfo ? form.contactInfo : ''} readOnly={readOnly} />
                </Grid.Column>

                <Grid.Column>
                  {/* <ModalUploadFiles {...this.props} isImage='0' handleData={this.handleFetch} dbName='Inquiry' fieldName='theFile' condition={{ studentId: doc.objectId }} /> */}
                  <label style={{ display: 'block', marginBottom: 5 }}>添付ファイル</label>
                  <ModalUploadFiles {...this.props} isImage='0' handleData={this.handleFetch} dbName='userFile' fieldName='theFile' condition={{ employeeId: doc.objectId }} />
                  {/* <Button as='label'
                    content={<>ファイルを選択してください
                    <input id="files" type="file" multiple={true}
                        onChange={this.handleFileChange} style={{ display: 'none' }} />
                    </>}
                    labelPosition="left"
                    icon={<Icon name='file' />}
                    style={{ minWidth: 196 }}
                    disabled={!isEdit}
                  /> */}
                  {fileObj && fileObj.filename ? <div style={{ ...style.flexCenter, marginTop: 10 }}>
                    <p style={{ ...style.textEllipsis, width: 196, direction: 'rtl', margin: 0 }}>新しい-{fileObj.filename}</p>
                    <Icon name='delete' style={{ cursor: 'pointer' }} onClick={this.clearFileObj} />
                  </div> : null}

                  {form.filesData ? form.filesData.map((item2, index2) => <div key={index2} style={{ ...style.flexCenter, marginTop: 10 }}>
                    <p style={{ ...style.textEllipsis, width: 196, direction: 'rtl', margin: 0 }}>{item2.filename}</p>
                    {isEdit ? <Icon name='delete' style={{ cursor: 'pointer' }} onClick={() => this.handleRemoveFile(item2)} /> : null}
                  </div>) : null}
                </Grid.Column>
              </Grid.Row>}

            <Grid.Row>
              <Grid.Column>
                <label style={{ display: 'block', marginBottom: 5 }}>{listType === "1" ? '內容' : '相談內容'}</label>
                <Form.Field control={TextArea} placeholder="入力してください"
                  readOnly={readOnly}
                  name="content"
                  onChange={this.handleChange} value={form.content ? form.content : ''}
                  style={{ height: 200, width: '100%', borderRadius: '.3rem', outline: 'none', border: "1px solid rgba(34,36,38,.15)", padding: '1rem' }}>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            {/* {listType === "1" ?
              <Grid.Row>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>返答</label>
                  <Form.Field control={TextArea} placeholder="入力してください"
                    readOnly={readOnly}
                    name="answer"
                    onChange={this.handleChange} value={form.answer ? form.answer : ''}
                    style={{ height: 200, width: '100%', borderRadius: '.3rem', outline: 'none', border: "1px solid rgba(34,36,38,.15)", padding: '1rem' }}>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row> : null} */}
            {/* {listType === "1" ?
              <Grid.Row>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>問合進行狀況</label>
                  <Form.Select
                    options={enquiryResultArrOpArr}
                    defaultValue={form.enquiryResult ? form.enquiryResult : " "}
                    name="enquiryResult"
                    onChange={this.handleChange}
                    disabled={readOnly}
                  />
                </Grid.Column>
              </Grid.Row> : null} */}
            <Grid.Row>
              <Grid.Column style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {isEdit ? <>
                  <Button size='small' onClick={this.handleSubmit} inverted={companyObj.inverted} color='green' loading={loading} disabled={disabled} icon='checkmark' content={form.objectId ? '保存' : '追加'} />{' '}
                  <Button size='small' onClick={this.handleClose} inverted={companyObj.inverted} color='red' disabled={disabled} icon='delete' content='キャンセル' />
                </> : <Button size='small' onClick={this.handleClose} basic>閉じる</Button>}

              </Grid.Column>
            </Grid.Row>
          </Grid>

        </ModalBody>
      </Modal>
      {/* <Confirm
        header='削除'
        content='削除してもいいですか?'
        cancelButton='いいえ'
        confirmButton='はい'
        open={this.state.confirm}
        onCancel={this.hideConfirm}
        onConfirm={this.handleRemove}
        size='mini'
        centered={false} /> */}
      <Modal
        isOpen={this.state.confirm}
        toggle={this.hideConfirm}
        size="lg"
        className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader>削除</ModalHeader>
        <ModalBody>
          削除してもいいですか？
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button onClick={this.handleRemove} inverted color='red' icon='delete' content='はい' />
          <Button onClick={this.hideConfirm} content='いいえ' />
        </ModalFooter>
      </Modal>
    </>);
  }
}