import React from 'react'
import { Container } from 'semantic-ui-react'
import PageHeader from 'components/Headers/PageHeader';

const contentCss = {
  maxWidth: '1170px',
  margin: '300px auto',
  flexDirection: 'column',
}

const Common0 = (props) => {
  const { pageObj, form, imagePreviewUrl } = props
  const { text1, img1File, dbLang } = form

  const picUrl = (img1File && img1File.url) || imagePreviewUrl || '/hp1/24.jpg';
  return (<>
    {/* <PageHeader url={picUrl} text={pageObj[dbLang]} /> */}
    <Container style={contentCss} >
      <div dangerouslySetInnerHTML={{ __html: text1 }} />
    </Container>
  </>)
}

export default Common0