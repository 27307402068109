import React from 'react'
import { Icon } from 'semantic-ui-react';
import Moment from 'moment';
import { MuiPickersUtilsProvider, DatePicker, DateTimePicker } from '@material-ui/pickers'; // TimePicker 
import DateFnsUtils from '@date-io/date-fns';
import tw from 'date-fns/locale/zh-TW'

import MaterialTable, { MTableBodyRow } from 'material-table'
import ModalUpdateFile from './ModalUpdateFile'
import { RowImage } from './RowImage';
import jsPDF from 'widget/pdf/JspdfMyFonts';
import { fetchGz2Txt } from 'widget/http2';
import { openPDF } from 'widget/pdf/loadCore'
import Parse from 'widget/parse'

export const codeOpt = {
  'A': 'A', 'B': 'B', 'C': 'C', 'D': 'D', 'E': 'E', 'F': 'F', 'G': 'G', 'H': 'H',
  'I': 'I', 'J': 'J', 'K': 'K', 'L': 'L', 'M': 'M', 'N': 'N', 'O': 'O', 'P': 'P', 'Q': 'Q', 'R': 'R',
  'S': 'S', 'T': 'T', 'U': 'U', 'V': 'V', 'W': 'W', 'X': 'X', 'Y': 'Y', 'Z': 'Z'
}

export const mgyearOpt = [
  { key: '8', value: '110', text: '110' },
  { key: '7', value: '109', text: '109' },
  { key: '6', value: '108', text: '108' },
  { key: '5', value: '107', text: '107' },
  { key: '4', value: '106', text: '106' },
  { key: '3', value: '105', text: '105' },
  { key: '2', value: '104', text: '104' },
  { key: '1', value: '103', text: '103' },
]

export const monthOpt = [
  { key: '1', value: '1', text: '一月' },
  { key: '2', value: '2', text: '二月' },
  { key: '3', value: '3', text: '三月' },
  { key: '4', value: '4', text: '四月' },
  { key: '5', value: '5', text: '五月' },
  { key: '6', value: '6', text: '六月' },
  { key: '7', value: '7', text: '七月' },
  { key: '8', value: '8', text: '八月' },
  { key: '9', value: '9', text: '九月' },
  { key: '10', value: '10', text: '十月' },
  { key: '11', value: '11', text: '十一月' },
  { key: '12', value: '12', text: '十二月' }
]

export const monthSel = {
  '1': '一月',
  '2': '二月',
  '3': '三月',
  '4': '四月',
  '5': '五月',
  '6': '六月',
  '7': '七月',
  '8': '八月',
  '9': '九月',
  '10': '十月',
  '11': '十一月',
  '12': '十二月',
}

export const mgyearSel = {
  '1': '109',
  '2': '108',
  '3': '107',
  '4': '106',
  '5': '105',
  '6': '104',
  '7': '103',
}

export const typeCurrency = { type: "currency", currencySetting: { currencyCode: 'TWD', minimumFractionDigits: 0 } }

const rowFile = (rowData, field) => {
  // console.log(rowData, field)
  return rowData && rowData[field] && (rowData[field]._url || rowData[field].url) ?
    <a href={rowData[field]._url || rowData[field].url} target='_blank' rel="noopener noreferrer" ><Icon name='file' size='large' /></a> : null;
}

// 寫進去的文字欄位 可以如下：
// dateText: newData && newData.date ? Moment(newData.date).format('YYYY-MM-DD') : ''
export const dateCol = ({ title, field, width = 100, editable }) => ({
  title, field, width, editable,
  initialEditValue: new Date(),
  editComponent: props => (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={tw}
    >
      <DatePicker
        format='yyyy-MM-dd'
        value={props.value ? (props.value.iso || props.value) : null}
        onChange={e => {
          // console.log(e)
          props.onChange(e === null ? null : new Date(e))
        }}
        clearable
        InputProps={{
          style: {
            fontSize: 13
          }
        }}
      />
    </MuiPickersUtilsProvider>
  ),
  render: rowData => rowData[field] ? Moment(rowData[field].iso || rowData[field]).format('YYYY-MM-DD') : '',
})

export const dateTimeCol = ({ title, field, width = 100, editable }) => ({
  title, field, width, editable,
  initialEditValue: new Date(),
  editComponent: props => (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={tw}
    >
      <DateTimePicker
        format='yyyy-MM-dd HH:mm'
        value={props.value ? (props.value.iso || props.value) : null}
        onChange={e => {
          // console.log(e)
          props.onChange(e === null ? null : new Date(e))
        }}
        clearable
        InputProps={{
          style: {
            fontSize: 13
          }
        }}
      />
    </MuiPickersUtilsProvider>
  ),
  render: rowData => {
    return rowData[field] ? Moment(rowData[field].iso || rowData[field]).format('YYYY-MM-DD HH:mm') : ''
  },
})

export const timeCol = ({ title, field, width = 100, editable }) => ({
  title, field, width, editable,
  initialEditValue: new Date(),
  editComponent: props => (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={tw}
    >
      <DateTimePicker
        format='HH:mm'
        value={props.value ? (props.value.iso || props.value) : null}
        onChange={e => {
          // console.log(e)
          props.onChange(e === null ? null : new Date(e))
        }}
        clearable
        InputProps={{
          style: {
            fontSize: 13
          }
        }}
      />
    </MuiPickersUtilsProvider>
  ),
  render: rowData => {
    return rowData[field] ? Moment(rowData[field].iso || rowData[field]).format('HH:mm') : ''
  },
})

export const imgBtnCol = ({ title = '', field, width = 50, hidden = false }) => ({
  width, title, field, hidden,
  filtering: false,
  headerStyle: { textAlign: 'center' },
  render: rowData => <></>,
  editComponent: props => <ModalUpdateFile isImage='1' fieldname={field} rowData={props.rowData} onRowDataChange={props.onRowDataChange} />
})

export const imgPreviewCol = ({ title, field, width = 120, hidden = false, photos }) => ({
  width, title, field, hidden,
  filtering: false,
  // editable: 'never',
  render: rowData => <RowImage rowData={rowData} field={field + '2'} photos={photos} />,
  editComponent: props => <RowImage rowData={props.rowData} field={field + '2'} photos={photos} />
})

export const fileBtnCol = ({ title = '', field, width = 50, hidden = false }) => ({
  width, title, field, hidden,
  filtering: false,
  headerStyle: { textAlign: 'center' },
  render: rowData => <></>,
  editComponent: props => <ModalUpdateFile isImage='0' fieldname={field} rowData={props.rowData} onRowDataChange={props.onRowDataChange} />
})

export const filePreviewCol = ({ title, field, width = 80, hidden = false }) => ({
  width, title, field, hidden,
  filtering: false,
  // editable: 'never',
  render: rowData => rowFile(rowData, field),
  editComponent: props => rowFile(props.rowData, field)
})

// checkFontLoaded = () => {
//   if (window.jsPDF.loaded && window.jsPDF.loaded.msjh && window.jsPDF.loaded.BiauKai) {
//     // console.log('got fonts')
//     this.setState({ fontLoaded: true });
//   } else {
//     // console.log('waiting to get fonts')
//     setTimeout(
//       () => {
//         this.checkFontLoaded()
//       },
//       1000
//     );
//   }
// }

export const exportPdf = async (columns = [], data = [], title = '', timeType) => {
  // const hostname = window.location.hostname;
  // console.log(columns, data, title)
  const path1 = window.location.pathname.split('/')[1];
  if (path1 === 'admin') {
    // fetchGz2Txt('https://hanbury.s3-ap-northeast-1.amazonaws.com/mai8/biaukai.txt.gz').then(res => {
    //   jsPDF.API.events.push(['addFonts', function () {
    //     this.addFileToVFS('BiauKai-normal.ttf', res);
    //     this.addFont('BiauKai-normal.ttf', 'BiauKai', 'normal');
    //     // console.log('BiauKai-normal ok')
    //   }])
    //   // console.log('BiauKai-normal pushed')
    //   window.jsPDF.loaded.BiauKai = 1;
    // })
    fetchGz2Txt('https://hanbury.s3-ap-northeast-1.amazonaws.com/mai8/msjh.txt.gz').then(res => {
      jsPDF.API.events.push(['addFonts', function () {
        this.addFileToVFS('msjh-normal.ttf', res);
        this.addFont('msjh-normal.ttf', 'msjh', 'normal');
        // console.log('msjh-normal ok')
      }])

      window.jsPDF.loaded.msjh = 1;

      const content = {
        startY: 35,
        head: [columns.map(function (columnDef) {
          return columnDef.title;
        })],
        body: data.map(item => columns.map(function (columnDef) {
          if (item[columnDef.field]) {
            const fieldData = item[columnDef.field]
            const dateData = item[columnDef.field]
            if (Object.prototype.toString.call(dateData) === "[object Date]" || (dateData.iso && Object.prototype.toString.call(new Date(dateData.iso)) === "[object Date]")) {
              // if (isNaN(item[columnDef.field].getTime()) || isNaN(new Date(item[columnDef.field]).getTime())) {  // d.valueOf() could also work
              //   return item[columnDef.field]
              // } else {
              if (timeType === 'dateTime') {
                return (dateData && (dateData.iso || dateData) && Moment(dateData.iso || dateData).format('YYYY-MM-DD hh:mm:ss'))
              } else {
                return (dateData && (dateData.iso || dateData) && Moment(dateData.iso || dateData).format('YYYY-MM-DD'))
              }
              // }
            } else {
              return fieldData
            }
          } else {
            return ''
          }
        })

        ),
        styles: { font: "msjh", fontStyle: "normal" },
        headerStyles: { font: "msjh", fontStyle: "normal" },
      };
      const unit = "pt";
      const size = "A4";
      const orientation = "landscape";
      let doc = new jsPDF(orientation, unit, size);
      doc.setFont('msjh').setFontSize(15);
      doc.text(title, 40, 25);
      doc.autoTable(content);
      doc.save(title + ".pdf");
      // console.log('msjh pushed')
    })
  }
  // this.checkFontLoaded()

  // const content = {
  //   startY: 50,
  //   head: [columns.map(function (columnDef) {
  //     return columnDef.title;
  //   })],
  //   body: data.map(item => columns.map(function (columnDef) {
  //     return (item[columnDef.field] && item[columnDef.field].iso && Moment(item[columnDef.field].iso).format('YYYY-MM-DD')) || item[columnDef.field] || ''
  //     //若有iso輸出時間的格式，若無就直接將資料輸出
  //   })
  //   ),
  //   styles: { font: "msjh", fontStyle: "normal" },
  //   headerStyles: { font: "msjh", fontStyle: "normal" },
  // };
  // const unit = "pt";
  // const size = "A4";
  // const orientation = "landscape";
  // let doc = new jsPDF(orientation, unit, size);
  // doc.setFont('msjh').setFontSize(15);
  // doc.text(title, 40, 40);
  // doc.autoTable(content);
  // doc.save(title + ".pdf");
  // openPDF(doc)

}

const reodering = async (drag, row, dbName, dbField, dataArr, arrName, handleState) => {
  let arr = [...dataArr]
  const element = arr[drag];
  arr.splice(drag, 1);
  arr.splice(row, 0, element);
  const newDataArr = arr.map((item, index) => ({ ...item, [dbField]: index + 1 }))
  const promises = []
  newDataArr.forEach(item => {
    promises.push(Parse.saveData(dbName, item))
  })
  await Promise.all(promises).catch(error => console.log("error", error));
  // this.setState({ dataArr: newDataArr })
  handleState(newDataArr, arrName);
}

let DrageState = {
  row: -1,
  dropIndex: -1,
}

export const rowReOrder = (props, dbName, dbField, dataArr, arrName, handleState) => {
  return (<MTableBodyRow {...props}
    draggable="true"
    onDragStart={e => {
      // console.log(DrageState)
      // console.log('onDragStart');
      // console.log(props.data.tableData.id)
      // console.log(props.data)
      DrageState.row = props.data.tableData.id
    }}

    onDragEnter={e => {
      // console.log('onDragEnter')
      e.preventDefault();
      if (props.data.tableData.id !== DrageState.row) {
        DrageState.dropIndex = props.data.tableData.id;
      }
      // console.log(props, e, rowData)
      // console.log(props.data.tableData.id)
      // console.log(props.data)
    }}

    onDragEnd={e => {
      // console.log(`onDragEnd`);
      if (DrageState.dropIndex !== -1 && DrageState.row !== DrageState.dropIndex) {
        //  待處理，若拖移至其他格子將記錄有拖移，但回原位依舊進行重新排序。應處理為移動後若又回了原位，將不進行重新排序。
        reodering(DrageState.row, DrageState.dropIndex, dbName, dbField, dataArr, arrName, handleState)
      }
      // console.log(props.data)
      DrageState.row = -1;
      DrageState.dropIndex = -1;
    }}
  />)
}
