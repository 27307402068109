import React, { Component } from 'react'
import { Container, Button, Dimmer, Loader, Image, Segment, Header, Tab, Form } from 'semantic-ui-react';
import MaterialTable from 'material-table'

import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';
import Parse from 'widget/parse'
import C13a from './C13a';

const roomType = { '0': '單人房', '1': '雙人房' };

const columns = () => ([
  { width: 80, title: '房號', field: 'roomNum' },
  { width: 80, title: '訂金', field: 'deposit1' },
  { width: 80, title: '押金', field: 'deposit2' },
  { width: 90, title: '預繳電費', field: 'deposit3' },
  { width: 90, title: '門鎖押金', field: 'deposit4' },
  dateCol({ width: 80, title: '電費開始日期', field: 'eleStartDate' }),
  dateCol({ width: 80, title: '電費結束日期', field: 'eleEndDate' }),
  { width: 90, title: '電錶開始', field: 'eleMeterStart' },
  { width: 90, title: '電錶結束', field: 'eleMeterEnd' },
  { width: 80, title: '電費', field: 'elePrice' },
  { width: 80, title: '房租', field: 'rentPrice' },
  dateCol({ width: 90, title: '入住開始', field: 'rentStartDate' }),
  dateCol({ width: 90, title: '入住結束', field: 'rentEndDate' }),
  dateCol({ width: 80, title: '繳費期限', field: 'payDeadlineDate' }),
  { width: 80, title: '其他', field: 'otherPrice' },
  { width: 80, title: '應收', field: 'accReceivable', editable: 'never' },
  { width: 80, title: '已收', field: 'accReceived', editable: 'never' },
  { width: 80, title: '未收', field: 'accPendingPay', editable: 'never' },
  { width: 80, title: '備註', field: 'ps' },
]);

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}
export default class Client1 extends Component {
  state = {
    dataArr: [],
    dataArr1: [],
    dataArr2: [],
    rowData: {},
    roomObj: {}
  }
  componentDidMount() {
    // const { calendar } = this.props
    // this.setState({ dataArr: calendar })
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = () => {

  }

  render() {
    const { roomObj, roomArr, roomSel } = this.props;
    const { dataArr, dataArr1, rowData, dataArr2, loading } = this.state;
    const roomId = roomObj.objectId
    return (<>
      <div style={{ ...style.flexCenter, position: 'relative', zIndex: 100 }}>
        {/* <Header as='h2' style={{ margin: '2rem 0' }}>客房資料</Header> */}
      </div>
      <Form>
        <MaterialTable
          isLoading={loading}
          localization={localization()}
          columns={columns()}
          options={{
            pageSize: 5,
            search: false
          }}
          data={roomArr}
          title={roomObj.roomNum + '入住資訊'}
        // actions={[
        //   {
        //     icon: 'view_headline',
        //     tooltip: '查看',
        //     onClick: (event, rowData) => this.handleData(rowData)
        //   }
        // ]}
        // editable={{
        //   onRowAdd: newData =>
        //     new Promise(async (resolve, reject) => {
        //       const data = [...dataArr];
        //       const db = Parse.Object.extend("");
        //       const newDoc = new db();
        //       const doc = await newDoc.save();
        //       const obj = {
        //         id: doc.id,
        //         _id: doc.id,
        //         companyId: companyObj.id,
        //         companyName: companyObj.companyName,
        //         ...newData
        //       }
        //       // data.push(obj);
        //       data.push(obj);
        //       this.setState({ dataArr: data, newData: obj }, () => resolve());
        //     }).then(() => this.handleAdd(1)),
        //   onRowUpdate: (newData, oldData) =>
        //     new Promise((resolve, reject) => {
        //       const data = dataArr;
        //       const index = data.indexOf(oldData);
        //       data[index] = newData;
        //       // handleState('people0', data);
        //       this.setState({ dataArr: data, newData, oldData }, () => resolve());
        //     }).then(() => this.handleUpdate(1)),
        //   onRowDelete: oldData =>
        //     new Promise((resolve, reject) => {
        //       let data = dataArr;
        //       const index = data.indexOf(oldData);
        //       data.splice(index, 1);
        //       // handleState('people0', data);
        //       this.setState({ dataArr: data, oldData }, () => resolve());
        //     }).then(() => this.handleDelete(1)),
        // }}
        />
      </Form>
    </>)
  }
}