import React, { Component } from 'react'
import { Button, Form, Modal, Tab, Table, Grid, Menu, Dropdown, Segment, Header } from 'semantic-ui-react';

import PrintEdit from './PrintEdit';

export default class PrintTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      pdfUrl: '',
      iframeHeight: 0,
      activeTab1: '',//'table12',
      activeTab2: '',//'table12',
      activeTab2key: '',//'table12',
      activeTab2Text: '',//'1 面接紀録表',
      level1Arr: [],
      level2Arr: [],
      dataArr: [],
      selectedOption: 'oqNLeQSHCtH2M2qmj',
      students: [],
      codeForm: {},
    };
  }

  componentDidMount() {
    const { formSet } = this.props;

    const level0Obj = (formSet ? formSet.find(item => item.key === 'print') : '');
    let level1Arr = [];
    if (level0Obj && level0Obj._id) {
      level1Arr = formSet.filter(item => (item.parentFolderId === level0Obj._id)).sort((a, b) => a.orderId - b.orderId);
    }
    this.setState({ level1Arr });
    console.log(level1Arr)
    // const activeTab1 = level1Arr[0]._id;
    const activeTab1 = (!level1Arr) ? level1Arr[0]._id : '';
    this.setLevel2(activeTab1);

    const dataArr = [];
    const { students } = this.props
    const obj = students.find(item => item._id === 'oqNLeQSHCtH2M2qmj');
    if (obj) {
      dataArr.push(obj);
    }
    this.setState({ dataArr });
  }

  // componentWillReceiveProps(nextProps) {
  //   // console.log("componentWillReceiveProps(nextProps)");
  //   // console.log(nextProps);
  //   const { formSet } = nextProps;
  //   const { activeTab1, activeTab2 } = this.state;

  //   const codeForm = formSet.find(item => item._id === activeTab2) || {code: `doc.setFont('NotoSansCJKjp');\ndoc.text(15, 15, '尚未輸入程式碼');`};
  //   if (activeTab2 && code !== this.state.code) {
  //     // this.setState({ code }, this.updateCodeToIFrame);
  //     const level2Arr = formSet.filter(item => (item.parentFolderId === activeTab1)).sort((a, b) => a.orderId - b.orderId);
  //     this.setState({ code, level2Arr });
  //   }
  // }

  getNowTab(url) {
    this.setState({ showTab: url });
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  handleShow() {
    this.setState({
      show: true,
      pdfUrl: `/api/${this.state.showTab}`,
    });
  }

  handleClose() {
    this.setState({ show: false });
  }

  printIframePdf() {
    // window.print(); // 這是印整個頁面的
    const pri = document.getElementById('pdfIframe').contentWindow;
    pri.print();
  }

  handleSelect1(activeTab1) {
    this.setLevel2(activeTab1);
  }

  setLevel2(activeTab1) {
    const { formSet } = this.props;
    const level2Arr = formSet.filter(item => (item.parentFolderId === activeTab1)).sort((a, b) => a.orderId - b.orderId);
    // const activeTab2 = level2Arr[0]._id;
    const activeTab2 = (!level2Arr) ? level2Arr[0]._id : '';

    const label = (!level2Arr) ? level2Arr[0].label : '';
    const key = (!level2Arr) ? level2Arr[0].key : '';
    const activeTab2Text = 1 + ' ' + label + ' ' + key;
    const activeTab2key = key;
    // const activeTab2Text = 1 + ' ' + level2Arr[0].label + ' ' + level2Arr[0].key;
    // const activeTab2key = level2Arr[0].key;
    // const activeTab2Text = 1 + ' ' + level2Arr[0].label + ' ' + level2Arr[0].key;
    // const activeTab2key = level2Arr[0].key;
    const codeForm = formSet.find(item => item._id === activeTab2) || { code: `doc.setFont('NotoSansCJKjp');\ndoc.text(15, 15, '尚未輸入程式碼');` };
    this.setState({ level2Arr, activeTab1, activeTab2, activeTab2Text, activeTab2key, codeForm });
  }

  handleSelect2(activeTab2) {
    this.setState({ activeTab2 })
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  handleSelChange = (e, { name, value }) => {
    const dataArr = [];

    const { students } = this.props
    const obj = students.find(item => item._id === value);
    if (obj) {
      dataArr.push(obj);
    }
    this.setState({ dataArr, [name]: value });

    // relative 
  }

  render() {
    const { formSet, optSet, showForm, students } = this.props;
    const { level1Arr, level2Arr, activeTab1, activeTab2, activeTab2key, activeTab2Text, codeForm, dataArr, selectedOption, activeItem } = this.state;

    const currentStudents = students.sort((a, b) => a.semesterYear - b.semesterYear);//studentsList.sort((a, b) => a.semester - b.semester).sort((a, b) => a.semesterYear - b.semesterYear);
    const optionStudents = currentStudents.sort((a, b) => ('' + a.recruitNum).localeCompare(b.recruitNum)).map(item => ({
      key: item._id, text: `募${item.recruitNum || "(なし)"} 学${item.studentId ? `${item.semesterYear.substr(-2)}(${item.semester})${item.studentId}` : '(なし)'} ${item.jpnName}`, value: item._id
    }));

    return (
      <>
        <div style={{ padding: '5px' }} >
          <Menu>
            {level1Arr ? level1Arr.map(({ _id, label, key }, index) => (
              <Dropdown text={`${String.fromCharCode(65 + index)} ${label}`} pointing
                key={_id}
                name={_id}
                // active={activeItem === _id}
                onClick={this.handleItemClick}
                className='link item'>
                <Dropdown.Menu>
                  {formSet.filter(item => (item.parentFolderId === _id)).sort((a, b) => a.orderId - b.orderId).map((item2, index2) => (
                    <Dropdown.Item key={item2._id}
                      onClick={() => this.setState({
                        activeTab2: item2._id,
                        activeTab2key: item2.key,
                        activeTab2Text: (index2 + 1) + ' ' + item2.label + ' ' + item2.key,
                        // code: item2.code || ''
                        codeForm: item2
                      })}
                      active={activeItem === item2._id}
                    >{index2 + 1} {item2.label} {item2.ps ? <div style={{ color: '#ccc' }}>{item2.format} {item2.orientation} {item2.ps}</div> : null}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )) : null}
          </Menu>
        </div>
        <br />
        <Header as='h1'>{activeTab2Text}</Header>
        <Form.Select
          name='selectedOption'
          label='学生資料'
          search
          options={optionStudents}
          // disabled={isEdit}
          onChange={this.handleSelChange}
          value={selectedOption} size="tiny" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Form>
        </Form>
        <br />
        <PrintEdit
          {...this.props}
          activeTab2={activeTab2} activeTab2key={activeTab2key} activeTab2Text={activeTab2Text}
          dataArr={dataArr}
          codeForm={codeForm}
          showForm={showForm}
        />
        <Grid>
          {showForm.indexOf('3') !== -1 ?
            <Grid.Row>
              <Grid.Column width={6}>
                <Segment>
                  jsPDF 筆記
                  <Table striped bordered >
                    <thead>
                      <tr>
                        <th>語法</th>
                        <th>功能</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>nowDate.yyy + "-" + nowDate.mm + "-" + nowDate.dd</td>
                        <td>今天日付</td>
                      </tr>
                      <tr>
                        <td>doc.setFont('Kaiu');</td>
                        <td>設定字體(標楷體)</td>
                      </tr>
                      <tr>
                        <td>doc.setFont('NotoSansCJKjp');</td>
                        <td>設定字體(黑體)</td>
                      </tr>
                      <tr>
                        <td>doc.addPage('A4', 'p');</td>
                        <td>新增一頁(p是直式，可換成l是橫式)</td>
                      </tr>
                      <tr>
                        <td>doc.setLineWidth(2);</td>
                        <td>設定線條寬度</td>
                      </tr>
                      <tr>
                        <td>doc.setFontSize(20);</td>
                        <td>設定字體大小</td>
                      </tr>
                      <tr>
                        <td>pdfkit2jspdf(doc, 'こんにちは。はじめまして。', 15, 15, 30);<br />等同於以下兩行<br />doc.setFontSize(30);<br />doc.text(15, 15, 'こんにちは。はじめまして。');</td>
                        <td>寫字</td>
                      </tr>
                      <tr>
                        <td>doc.context2d.moveTo(200, 390);<br />doc.context2d.lineTo(460, 390);<br />doc.context2d.stroke();</td>
                        <td>畫線<br />在PrintEdit.js裡有定義ctx=doc.context2d所以可以用ctx.moveTo(200, 390);</td>
                      </tr>
                      <tr>
                        <td>doc.setLineJoin('miter').rect(165, 345, 8, 8);</td>
                        <td>畫格子</td>
                      </tr>
                    </tbody>
                  </Table>
                </Segment>
              </Grid.Column>
              <Grid.Column width={6}>
                <Segment>
                  AcroForm 筆記
              <br />
                  Panel content
              </Segment>
              </Grid.Column>
            </Grid.Row> : null}
        </Grid>
      </>
    );
  }
}