const str = [
  {
    "_id": "dpVRGn0ozCdZMe57F0b1",
    "isEnable": true,
    "key": "applyDocOver",
    "label": "出願書類状況海外",
    "level": 2,
    "orderId": 14,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "LtHDGdP3wB7IMuywRRLl",
    "isEnable": true,
    "key": "applyDocStu",
    "label": "出願書類状況本人",
    "level": 2,
    "orderId": 13,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "LikD9NoJqzIWiYNHKL8k",
    "isEnable": true,
    "key": "applyDocJap",
    "label": "出願書類状況日本",
    "level": 2,
    "orderId": 15,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "0qP1cbLebaEzxBpknR33",
    "isEnable": true,
    "label": "ビザ拒否（在外公館）",
    "level": 3,
    "orderId": 7,
    "parentFolderId": "VQcmpvi8a979GpmMA5JC",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "23En9cAkKQitnC6Bh",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "B",
    "labelCht": "B",
    "labelEng": "B",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "B"
  },
  {
    "_id": "246Qvg8Tes8MPbKyZ",
    "isEnable": true,
    "key": "option",
    "label": "3月",
    "labelCht": "3月",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "BmvvCvqqpB4QYe7sm",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "2B2H7PPOuE2MpJ3Es6tq",
    "isEnable": true,
    "label": "初級前期",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "OVFWdKTKF2nBabxpgt1D",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "2BWvRA37XFizzNbnZ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "O",
    "level": 2,
    "orderId": 14,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "O"
  },
  {
    "_id": "2D2Mk8Nxqupjuis4c",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "母",
    "labelCht": "母",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "tBuxP8MZRxbcnMf5s",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "2E6Te5puNo9zFyYqa",
    "childNum": 6,
    "isEnable": true,
    "key": "3rdKind",
    "label": "区分3",
    "labelCht": "第3類",
    "labelEng": "3rd kind",
    "level": 1,
    "orderId": 1,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder"
  },
  {
    "_id": "2FsrHk6f3QXcRJRFT",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "4年制大学",
    "labelCht": "4年制大学",
    "labelEng": "4 year university",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "BkaebxeYePdnJ36Fu",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "2GPy98LdFzWWMkbKQ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "L",
    "labelCht": "L",
    "labelEng": "L",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "L"
  },
  {
    "_id": "2JohgE2atAMTDvwAD",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "興行歷が有リ",
    "labelCht": "與行程有差異的",
    "labelEng": "Different schedules",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "msaKtv3iaFn6EBiQv",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "2PdFecKoMcPA67Hfu",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "中退",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "s9fj2Hh8XqrL5WS9y",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "2Su9hNHcYgWXCYrC6",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "S",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "zcF6vqWsqmqhvddxr",
    "type": "string",
    "value": "10"
  },
  {
    "_id": "2TtwoCAut92zfZXxW",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "愛知文教大学",
    "labelCht": "愛知文教大学",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "11"
  },
  {
    "_id": "2YtRAABe9NG99xqQj",
    "isEnable": true,
    "key": "option",
    "label": "TANAHUN",
    "labelCht": "TANAHUN",
    "labelEng": "TANAHUN",
    "level": 3,
    "orderId": 67,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "45"
  },
  {
    "_id": "2bt3hz9hxKQRerL39",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "代表取締役",
    "labelCht": "代表董事",
    "labelEng": "CEO",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "pvgeMtS2sGZpp5568",
    "type": "number",
    "value": "10"
  },
  {
    "_id": "2eWJpwXJdLfmzGBCs",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "ガーナ",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "boQWTSYGfYnWMf5sK",
    "type": "string",
    "value": "9"
  },
  {
    "_id": "2ffEgWbnfE9JskS56",
    "childNum": 5,
    "isEnable": true,
    "key": "travelPurpose",
    "label": "旅行目的",
    "labelCht": "旅行目的",
    "labelEng": "purpose of travel",
    "level": 1,
    "orderId": 3,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "2qZePK3NJsXZtfuGk",
    "isEnable": true,
    "key": "option",
    "label": "SRI LANKA",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "boQWTSYGfYnWMf5sK",
    "type": "string",
    "value": "11"
  },
  {
    "_id": "2qfFkXbDqhEdMRvzX",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "韓国",
    "labelCht": "韓國",
    "labelEng": "korea",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "boQWTSYGfYnWMf5sK",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "2tTr8JcDrY5n7T5dx",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "内向的",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "qN7FRR6q3QXfkXNZ4",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "34PHi7JMH63sLRAM5",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "朝日大学",
    "labelCht": "朝日大学",
    "level": 2,
    "orderId": 42,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "42"
  },
  {
    "_id": "3CDHh7FMeKx5hmFcN",
    "isEnable": true,
    "key": "option",
    "label": "KANCHANPUR",
    "labelCht": "KANCHANPUR",
    "labelEng": "KANCHANPUR",
    "level": 3,
    "orderId": 50,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "46"
  },
  {
    "_id": "3YiabS53AugpkHLFZ",
    "isEnable": true,
    "key": "option",
    "label": "2020",
    "labelCht": "2020",
    "labelEng": "2020",
    "level": 2,
    "note1": "2",
    "orderId": 1,
    "parentFolderId": "rLDtawHZT5AWhySev",
    "type": "string",
    "value": "2020"
  },
  {
    "_id": "3Ypr8ucQSMN7ocmfK",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "大学院(博士)",
    "labelCht": "大学院(博士)",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "cxpsyi4PEyoy5ChzA",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "3adtk7P3dfJLerNfK",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "親族",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "HXT9aD3XGeoqxXnjr",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "3bdutRp9Kr3nTo8A2",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "公欠",
    "level": 2,
    "note1": "green",
    "note2": "#8fe792",
    "orderId": 8,
    "parentFolderId": "69HlkaS4w9jOFrxQCqHL",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "3cSdv6dK698q2nsyD",
    "isEnable": true,
    "key": "option",
    "label": "22",
    "labelCht": "22",
    "labelEng": "22",
    "level": 2,
    "orderId": 21,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "22"
  },
  {
    "_id": "3jKuHc4bConKyjuhY",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "遅刻",
    "level": 2,
    "note1": "yellow",
    "note2": "#f5e55a",
    "orderId": 1,
    "parentFolderId": "Q7v3BFZiByG5oP484",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "3osDDHcpBmqxucRjN",
    "childNum": 3,
    "isEnable": true,
    "key": "periodOfTime",
    "label": "每月 ・ 半年ごと ・ 年間",
    "labelCht": "每月 ・ 半年ごと ・ 年間",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "3phhrBHmBmp3GGDBd",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "16",
    "labelCht": "16",
    "level": 2,
    "orderId": 15,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "16"
  },
  {
    "_id": "3vDvJkBfMWf8apRo9",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "6月",
    "labelCht": "六月",
    "labelEng": "June",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "nfpgEp3kq2YEoZJqs",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "3vvku7SxR9kyjHNq5",
    "isEnable": true,
    "key": "option",
    "label": "9",
    "labelCht": "9",
    "labelEng": "9",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "9"
  },
  {
    "_id": "3wCJ8vyqhRprSwdKf",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "其他",
    "labelCht": "其他",
    "labelEng": "other",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "2ffEgWbnfE9JskS56",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "3xxxs3YRozBj63BRv",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "日本",
    "labelCht": "日本",
    "labelEng": "jpn",
    "level": 2,
    "orderId": 12,
    "parentFolderId": "boQWTSYGfYnWMf5sK",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "463M6PoxMu36NFZ6v",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "空欄（なぜ？）",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "HpYxwK3JHgSZwcZMR",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "48MvnnJqF6BZMhNfK",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "[数学 Ⅰ ]",
    "labelCht": "[数学 Ⅰ ]",
    "labelEng": "[Mathematics I]",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "tcbMYc3xbaP3isZKN",
    "type": "string",
    "value": "mathScore1"
  },
  {
    "_id": "49BrZlEAUP5NR4m56XJF",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "その他",
    "labelCht": "その他",
    "labelEng": "the other",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "6FQ5JWq38TJytcnT4",
    "type": "number",
    "value": "10"
  },
  {
    "_id": "4B83SzrnE6kh7P9J2",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "自営業",
    "labelCht": "量販業",
    "labelEng": "Volume industry",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "pvgeMtS2sGZpp5568",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "4FTJWyQ88Xuiw26RN",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "広州市",
    "labelCht": "広州市",
    "labelEng": "Quzhou City",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "WoNy9iaGLSFuWgBMd",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "4PBKQmuRZ8m2aoBHT",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "査証来日",
    "level": 2,
    "note1": "10",
    "note2": "indexStudent,checkSheetStudent,interviewStudent,applicationStudent,inquiryStudent,",
    "orderId": 6,
    "parentFolderId": "cqygzTh26W5g2zQuA",
    "type": "string",
    "value": "21"
  },
  {
    "_id": "4S32WyiJA84vMs5HC",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "6月",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "BmvvCvqqpB4QYe7sm",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "4Sb5FKDd6jovvGGP7",
    "childNum": 2,
    "isEnable": true,
    "key": "englishExam",
    "label": "英語試験結果",
    "labelCht": "英語試驗結果",
    "labelEng": "EJU study test result",
    "level": 1,
    "orderId": 16,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder"
  },
  {
    "_id": "4Sy5EdoA5cKkkLC2t",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "勉学意思は比較的薄い、もしくは別の目的で留学を申請する。",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "AJC7gw3RrAXMbTxbu",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "4WGgbceKxts6SJEZ3",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "短期大学",
    "labelCht": "大專",
    "labelEng": "Junior college",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "qLp95bMxAuc5sY8A4",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "4XSahgtBJi4J3AELK",
    "isEnable": true,
    "key": "option",
    "label": "VINH LONG",
    "labelCht": "VINH LONG",
    "labelEng": "VINH LONG",
    "level": 3,
    "orderId": 72,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "65"
  },
  {
    "_id": "4bqvqHG8M9imAyCLr",
    "isEnable": true,
    "key": "option",
    "label": "フィリピン",
    "labelCht": "菲力賓",
    "level": 3,
    "orderId": 16,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "string",
    "value": "17"
  },
  {
    "_id": "4hizo2vYrsyGhbWQJ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "一般",
    "labelCht": "一般",
    "labelEng": "general",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "zRc6iLsG2ePTg8kz6",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "4kSbnWEQugx4Z4RNh",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "名古屋経済大学",
    "labelCht": "名古屋経濟大学",
    "level": 2,
    "orderId": 24,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "24"
  },
  {
    "_id": "4m5itXzQxTvaLM5DF",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "HO CHI MINH",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "WoNy9iaGLSFuWgBMd",
    "type": "number",
    "value": "11"
  },
  {
    "_id": "4ne2eLEAXFNhdnC9o",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "名古屋女子大学",
    "labelCht": "名古屋女子大学",
    "level": 2,
    "orderId": 31,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "31"
  },
  {
    "_id": "4pZNpZ2PDaJ5Hz3nY",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "三重大学(国立)",
    "labelCht": "三重大学(国立)",
    "level": 2,
    "orderId": 20,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "20"
  },
  {
    "_id": "4sNruQM9NCHPkuMgp",
    "isEnable": true,
    "key": "option",
    "label": "7",
    "labelCht": "7",
    "labelEng": "7",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "4tfjnCj9syNEuPKyF",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "資料郵送済み",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "zcF6vqWsqmqhvddxr",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "4zTcdp9bLsSN8Kp8f",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "椙山女学園大学",
    "labelCht": "椙山女学園大学",
    "level": 2,
    "orderId": 50,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "50"
  },
  {
    "_id": "57tgNAEyqNbeHYcPC",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "D",
    "labelCht": "D",
    "labelEng": "D",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "D"
  },
  {
    "_id": "5D7YoBxT3CxwSWSq6",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "台湾",
    "labelCht": "台湾",
    "labelEng": "Taiwan",
    "level": 3,
    "orderId": 11,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "5DKToKJ23pLDyf6ni",
    "isEnable": true,
    "key": "option",
    "label": "18",
    "labelCht": "18",
    "labelEng": "18",
    "level": 2,
    "orderId": 17,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "18"
  },
  {
    "_id": "5Di6iGPYqsF5K5ZTM",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "成績が良く",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "69HlkaS4w9jOFrxQCqHL",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "5FPaqCvzYXTz5T3BF",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "8",
    "labelCht": "8",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "8"
  },
  {
    "_id": "5HboJtniwQ4PcsEWN",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "1",
    "labelCht": "一",
    "labelEng": "one",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "RjT2jTY5CpxZEZpTG",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "5Hpd6tNrSQqeFh9C6",
    "isEnable": true,
    "key": "option",
    "label": "26",
    "labelCht": "26",
    "labelEng": "26",
    "level": 2,
    "orderId": 25,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "26"
  },
  {
    "_id": "5IrfCNDbKJCI1pd9Ta3c",
    "isEnable": true,
    "key": "studentStatus4",
    "label": "学生状態4",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "niMW6dizwqnorXBhQ",
    "type": "folder"
  },
  {
    "_id": "5JBw7rjCZAXAE6F5S",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "計画年休",
    "labelCht": "計畫年休",
    "level": 2,
    "note1": "staff",
    "note2": "#f67e6f",
    "orderId": 4,
    "parentFolderId": "tvA288k3wppxGPQyx",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "5MiJXXM6Kn7wgbdXv",
    "isEnable": true,
    "key": "option",
    "label": "ARGHAKHANCHI",
    "labelCht": "ARGHAKHANCHI",
    "labelEng": "ARGHAKHANCHI",
    "level": 3,
    "orderId": 37,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "67"
  },
  {
    "_id": "5Rt37F6WxtmRwkW4p",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "有",
    "labelCht": "有",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "p729qSXFYLnHZRqGw",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "5WBGzDsB8shS94X6c",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "一般友人",
    "labelCht": "一般友人",
    "labelEng": "General friend",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "actFCjDexwbdhJAFL",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "5cEYJ3BA7r8epyaY4",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "人文学",
    "labelCht": "人文学",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "i4PzFrvC2mf9Yuich",
    "type": "string",
    "value": "8"
  },
  {
    "_id": "5cqR3sZRsFtE3KdAe",
    "isEnable": true,
    "key": "option",
    "label": "技能実習2号イ",
    "labelEng": "Study abroad",
    "level": 3,
    "orderId": 13,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "27"
  },
  {
    "_id": "5m3cbKNtCcrY342Zs",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "N3～N2",
    "level": 3,
    "orderId": 4,
    "parentFolderId": "S2xGCLTCAQDsQykoh",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "5op9CCGsz8uSkD7sj",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "江西省",
    "labelCht": "江西省",
    "labelEng": "江西省",
    "level": 3,
    "orderId": 16,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "14"
  },
  {
    "_id": "5sRrCsobiHfaZpWWE",
    "childNum": 2,
    "isEnable": true,
    "key": "staffStatus",
    "label": "状態",
    "level": 1,
    "orderId": 7,
    "parentFolderId": "rWHGNFjFi5TmTgFhA",
    "type": "folder"
  },
  {
    "_id": "5ttfk2XGiho8ZWv9n",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "内蒙古",
    "level": 3,
    "orderId": 27,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "34"
  },
  {
    "_id": "5uzhXjHiZnoRdmyQs",
    "isEnable": true,
    "key": "option",
    "label": "12",
    "labelCht": "12",
    "labelEng": "12",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "12"
  },
  {
    "_id": "5ysGkC2k6QXiyXoce",
    "childNum": 3,
    "isEnable": true,
    "key": "1stKind",
    "label": "区分1",
    "labelCht": "第1類",
    "labelEng": "1st kind",
    "level": 1,
    "orderId": 10,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder"
  },
  {
    "_id": "63CjBXyx8KaZiOxMjWXu",
    "isEnable": true,
    "key": "inquiryLearningPurpose",
    "label": "学習目的",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "67csCf4xmnAYxvxCk",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "豊橋技術科学大学(国立)",
    "labelCht": "豐橋技術科学大学(国立)",
    "level": 2,
    "orderId": 18,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "18"
  },
  {
    "_id": "68BgCgsCobRQFApBz",
    "isEnable": true,
    "key": "option",
    "label": "専修",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "HG46JunDucyZHDqRa",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "69HlkaS4w9jOFrxQCqHL",
    "isEnable": true,
    "key": "attitudeDelete",
    "label": "delete",
    "level": 1,
    "orderId": 19,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder"
  },
  {
    "_id": "6CGiGteD764Dcbo7E",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "化学",
    "labelCht": "化学",
    "labelEng": "Chemical",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "tcbMYc3xbaP3isZKN",
    "type": "string",
    "value": "chemistryScore"
  },
  {
    "_id": "6Ceyqvfxwpb8ed0mCA8H",
    "isEnable": true,
    "label": "その他",
    "level": 3,
    "orderId": 5,
    "parentFolderId": "qrfPtSIWzvPkinLlAjJ3",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "6EXgpyPF6DPFatsXn",
    "isEnable": true,
    "key": "option",
    "label": "KAVREPALANCHOK",
    "labelCht": "KAVREPALANCHOK",
    "labelEng": "KAVREPALANCHOK",
    "level": 3,
    "orderId": 52,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "43"
  },
  {
    "_id": "6FQ5JWq38TJytcnT4",
    "childNum": 4,
    "isEnable": true,
    "key": "wayOutSituation",
    "label": "進路状況",
    "labelCht": "出路狀況",
    "labelEng": "Way out situation",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "6MFKo9h39xCjdJ6Xk",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "中部学院大学",
    "labelCht": "中部学院大学",
    "level": 2,
    "orderId": 41,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "41"
  },
  {
    "_id": "6Q7HcHDaPFftTGGDR",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "主婦",
    "labelCht": "家庭主婦",
    "labelEng": "housewife",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "pvgeMtS2sGZpp5568",
    "type": "number",
    "value": "11"
  },
  {
    "_id": "6Xr36Ss7u4xRpi4Kk",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "河南省",
    "level": 3,
    "orderId": 24,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "33"
  },
  {
    "_id": "6ZS4zGQyrHANyqEeq",
    "childNum": 53,
    "isEnable": true,
    "key": "hopeSchoolName",
    "label": "進路希望校名",
    "labelCht": "進路希望校名",
    "labelEng": "Hope school name",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "6ZtAZsdAR6F5geKZd",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "調理師",
    "labelCht": "調理師",
    "labelEng": "Conditioner",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "pvgeMtS2sGZpp5568",
    "type": "number",
    "value": "9"
  },
  {
    "_id": "6ashtJYyEEuKTAN4r",
    "isEnable": true,
    "key": "option",
    "label": "SYANGJA",
    "labelCht": "SYANGJA",
    "labelEng": "SYANGJA",
    "level": 3,
    "orderId": 66,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "47"
  },
  {
    "_id": "6b4QYDBnZgf7QwCaK",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "未修者",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "S2xGCLTCAQDsQykoh",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "6cHaE53LHbMY4sTZ4",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "上海市",
    "labelCht": "上海市",
    "labelEng": "Shanghai city",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "6dFSwQpyJk725mghq",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "11",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "7vANGTAAD9sqEfnSs",
    "type": "number",
    "value": "9"
  },
  {
    "_id": "6eghNa2FsFnEg2TFH",
    "isEnable": true,
    "key": "option",
    "label": "JHAPA",
    "labelCht": "JHAPA",
    "labelEng": "JHAPA",
    "level": 3,
    "orderId": 49,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "57"
  },
  {
    "_id": "6emW8jV8aXlwZ8ruCsUH",
    "isEnable": true,
    "label": "天津市",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "string",
    "value": "21"
  },
  {
    "_id": "6ggNH5km98cohCXBB",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "西北地区",
    "labelCht": "西北地区",
    "labelEng": "西北地区",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "string",
    "value": "8"
  },
  {
    "_id": "6pi43hsSBo82pqf2d",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "就職",
    "labelCht": "就職",
    "labelEng": "Finding employment",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "6FQ5JWq38TJytcnT4",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "6qBTdHPwJbs7wjrGm",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "完成済",
    "labelCht": "確認無誤",
    "level": 2,
    "note1": "#48cc92",
    "orderId": 3,
    "parentFolderId": "PK99CZPNooY3HToEm",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "6sbXcYY4nqraMPMMD",
    "isEnable": true,
    "key": "option",
    "label": "技能実習3号ロ",
    "level": 3,
    "orderId": 16,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "30"
  },
  {
    "_id": "6t4z2FeVxyr0HUTDqL9p",
    "isEnable": true,
    "label": "専修",
    "level": 3,
    "orderId": 5,
    "parentFolderId": "OVFWdKTKF2nBabxpgt1D",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "6wY2HjvowJT7Ejh9c",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "法学",
    "labelCht": "法学",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "i4PzFrvC2mf9Yuich",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "6xjg4ppgLuAvXWMXa",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "係長",
    "labelCht": "助理經理",
    "labelEng": "Assistant manager",
    "level": 2,
    "orderId": 12,
    "parentFolderId": "EMx476y5qT97xamPW",
    "type": "number",
    "value": "12"
  },
  {
    "_id": "74Re2sckfilfO0K662Je",
    "isEnable": true,
    "label": "仲介業者",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "TX3dNmIr4dMMrnBwh3CL",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "78uNFKkZMBMmxWnuu",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "農業",
    "labelCht": "農業",
    "labelEng": "agriculture",
    "level": 2,
    "orderId": 15,
    "parentFolderId": "pvgeMtS2sGZpp5568",
    "type": "number",
    "value": "16"
  },
  {
    "_id": "79PRE5sZm7NvcrfFW",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "女",
    "labelCht": "女",
    "labelEng": "female",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "9K6FiwbpLpiCJHoTd",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "7BwZcTsvRchydguoc",
    "isEnable": true,
    "key": "option",
    "label": "初級",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "HG46JunDucyZHDqRa",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "7HStEeqCuLbjwHB9y",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "センターの先生に勧められた",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "dDKkutfJES2iPd586",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "7JTSK2mK7daf8YF4K",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "審査中",
    "level": 2,
    "note1": "pink",
    "orderId": 1,
    "parentFolderId": "PK99CZPNooY3HToEm",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "7NFGJbQosonpfACEH",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "無",
    "labelCht": "無",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "JGnTdm4MGmAynLHmh",
    "type": "number",
    "value": "0"
  },
  {
    "_id": "7W2egcwdWdQrtPOsr3Ov",
    "isEnable": true,
    "label": "澳門",
    "level": 3,
    "orderId": 9,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "80"
  },
  {
    "_id": "7X69ERv7jmdQNJLsN",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "\b卒業状態2",
    "level": 2,
    "note1": "50",
    "note2": "indexStudent,master,affairStudent,testStudent,examStudent,graduateStudent,commentOnTeacher,admissionStatus,inquiryStudent,",
    "orderId": 13,
    "parentFolderId": "cqygzTh26W5g2zQuA",
    "type": "string",
    "value": "53"
  },
  {
    "_id": "7YSejGp6xFSbK6jZR",
    "isEnable": true,
    "key": "option",
    "label": "5",
    "labelCht": "5",
    "labelEng": "5",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "7eXHnM8TwAR76f5ox",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "非常に良い",
    "labelCht": "非常に良い",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "yXPFuyumZM7gdFG5a",
    "type": "number",
    "value": "0"
  },
  {
    "_id": "7gJEw7tQMCezrAR6E",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "理学",
    "labelCht": "理学",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "i4PzFrvC2mf9Yuich",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "7gh4M4TKkKof4uaoE",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "N",
    "labelCht": "N",
    "labelEng": "N",
    "level": 2,
    "orderId": 13,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "N"
  },
  {
    "_id": "7hvP56ZPgYtyR8P7t",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "名古屋音楽大学",
    "labelCht": "名古屋音楽大学",
    "level": 2,
    "orderId": 22,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "22"
  },
  {
    "_id": "7oimNBs6YSkXxMSzM",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "V",
    "labelCht": "V",
    "labelEng": "V",
    "level": 2,
    "orderId": 21,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "V"
  },
  {
    "_id": "7pGcoZ23kbTvXY9Bq",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "指定校",
    "labelCht": "指定學校",
    "labelEng": "Designated school",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "zRc6iLsG2ePTg8kz6",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "7tdC5FfaTNsk8yzHL",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "N2～N1",
    "level": 3,
    "orderId": 5,
    "parentFolderId": "S2xGCLTCAQDsQykoh",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "7twXu9Kn6eTrDfWSJ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "送貨",
    "labelCht": "送貨",
    "labelEng": "Currency",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "gyyFBx9L8NHTdDoQg",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "7vANGTAAD9sqEfnSs",
    "childNum": 9,
    "isEnable": true,
    "key": "yearsOfSchooling",
    "label": "修学年数",
    "labelCht": "修学年数",
    "labelEng": "Years of schooling",
    "level": 2,
    "orderId": 28,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "7wmR8cdAccv4b6JpM",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "文法",
    "labelCht": "文法",
    "labelEng": "grammar",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "tcbMYc3xbaP3isZKN",
    "type": "string",
    "value": "ejuGrammarScore"
  },
  {
    "_id": "7yZTmathqcMKnorKQ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "1.3年",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "BmvvCvqqpB4QYe7sm",
    "type": "string",
    "value": "15"
  },
  {
    "_id": "82eNPRxWpHCgfZ5Ae",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "1回",
    "labelCht": "1回",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "gHwK4vnYExL5WCDzw",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "88KcZdpK2gGSwD4Ed",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "取引関係者、現地企？",
    "labelCht": "取引關係者、現地企？",
    "level": 2,
    "orderId": 15,
    "parentFolderId": "tBuxP8MZRxbcnMf5s",
    "type": "number",
    "value": "16"
  },
  {
    "_id": "8ATwLZqycnYtXCNMF",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "岐阜協立大学",
    "labelCht": "岐阜協立大学",
    "level": 2,
    "orderId": 44,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "44"
  },
  {
    "_id": "8FHcZS6HpQpxzmZoT",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "4",
    "labelCht": "四",
    "labelEng": "four",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "RjT2jTY5CpxZEZpTG",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "8FafNwBRD7yR9HKyK",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "否",
    "labelCht": "否",
    "labelEng": "false",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "o7xTYXNZybstYNYMS",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "8Fga4dMbBQYbAhSbf",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "0",
    "labelCht": "零",
    "labelEng": "zero",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "RjT2jTY5CpxZEZpTG",
    "type": "number",
    "value": "0"
  },
  {
    "_id": "8Rpk4sdDZPzokdBfA",
    "isEnable": true,
    "key": "option",
    "label": "THAI NGUYEN",
    "labelCht": "THAI NGUYEN",
    "labelEng": "THAI NGUYEN",
    "level": 3,
    "orderId": 68,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "51"
  },
  {
    "_id": "8WJM5rvAvwqY5paqF",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "日本での進学",
    "labelCht": "在日本留學",
    "labelEng": "Studying in Japan",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "BEcYusFEnsFbRxFgi",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "8aewKxTBAKr3qnYbY",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "暴飲、ヘビ？スモ？？",
    "labelCht": "酗酒，蛇？相撲？",
    "labelEng": "alcoholism, snake? Sumo?",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "Dan7bTZgSgkSewy7x",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "8jMRYTQ6kuvnvFtHH",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "年度後期",
    "labelCht": "財政年度末",
    "labelEng": "Late Fiscal Year",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "JGF8rTr32EC36i4WJ",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "8jbwDfC7n2FknKJa8",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "優秀賞",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "ehmkyM9wefvYZZccs",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "8nJaoRt63F8KerXAj",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "2016",
    "labelCht": "2016",
    "labelEng": "2016",
    "level": 2,
    "note1": "2",
    "orderId": 5,
    "parentFolderId": "rLDtawHZT5AWhySev",
    "type": "string",
    "value": "2016"
  },
  {
    "_id": "8orH4NgzT4Z5M3Ewx",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "北京市",
    "labelCht": "北京市",
    "labelEng": "Beijing",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "WoNy9iaGLSFuWgBMd",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "8sAYNLjfwL5zhXQsR",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "重慶市",
    "labelCht": "重慶市",
    "labelEng": "Chongqing",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "WoNy9iaGLSFuWgBMd",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "8stZjiLayRbmNEbSe",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "23",
    "labelCht": "23",
    "level": 2,
    "orderId": 23,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "23"
  },
  {
    "_id": "8x8NSoWjwBotBrh5D",
    "isEnable": true,
    "key": "option",
    "label": "23",
    "labelCht": "23",
    "labelEng": "23",
    "level": 2,
    "orderId": 22,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "23"
  },
  {
    "_id": "8xMMAdRcpxQf5pAmQ",
    "isEnable": true,
    "key": "option",
    "label": "THAI BINH",
    "labelCht": "THAI BINH",
    "labelEng": "THAI BINH",
    "level": 3,
    "orderId": 69,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "60"
  },
  {
    "_id": "8zCgCWWn34TYJ53Z3",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "岐阜聖徳学園大学",
    "labelCht": "岐阜聖德学園大学",
    "level": 2,
    "orderId": 45,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "45"
  },
  {
    "_id": "91yZ2wuDGEanyjCP4lWh",
    "isEnable": true,
    "label": "出願済",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "Sx44U3PhaBGOlrg23FrZ",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "92PGQi8iZZmJ5QPjz",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "その他",
    "labelCht": "其他",
    "labelEng": "Other",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "actFCjDexwbdhJAFL",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "95JcZB7KzcSuAPzmu",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "2012",
    "labelCht": "2012",
    "labelEng": "2012",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "rLDtawHZT5AWhySev",
    "type": "string",
    "value": "2012"
  },
  {
    "_id": "95we4gHsQbevNoafX",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "国立",
    "labelCht": "国立",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "Pr5TCgZr8YdTKHmGK",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "96mzFikvhy72tFZwz",
    "isEnable": true,
    "key": "option",
    "label": "中途退学",
    "labelCht": "中途退学",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "vXMFsq82XWcqvraGd",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "98Yf9FFFQR0ntmow1Udv",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "大学院",
    "labelCht": "大学院",
    "labelEng": "graduate institute",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "6FQ5JWq38TJytcnT4",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "9Anpmf5rSqtcgkGfG",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "電話問合せ",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "zcF6vqWsqmqhvddxr",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "9G5k6nRoPiNu5Qtqd",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "学部生",
    "labelCht": "本科生",
    "labelEng": "Undergraduate",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "2E6Te5puNo9zFyYqa",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "9HC7ycLrzhtGTWAqr",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "1000円",
    "labelCht": "1000日元",
    "labelEng": "1000 yen",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "Zfd285m2TeoHBqKmY",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "9Hoqhbfse6D22iSm2",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "3.5",
    "labelCht": "3.5",
    "labelEng": "3.5",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "WoPJf6A9dJT2cp3hr",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "9Hso4Wtt44rY3bRvn",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "在籍老師(単選)",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "e6PTu4uCLHcD5qe9i",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "9K0blwdo2JOk9cInF8jd",
    "isEnable": true,
    "key": "enquirySnsWay",
    "label": "社群連絡方式",
    "level": 2,
    "orderId": 38,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "9K6FiwbpLpiCJHoTd",
    "childNum": 2,
    "isEnable": true,
    "key": "gender",
    "label": "性別",
    "labelCht": "性別",
    "labelEng": "gender",
    "level": 1,
    "orderId": 2,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder",
    "value": "folder"
  },
  {
    "_id": "9MJ72HD6AfaG4RR2g",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "書類審査",
    "level": 2,
    "note1": "10",
    "note2": "indexStudent,checkSheetStudent,interviewStudent,applicationStudent,inquiryStudent,",
    "orderId": 1,
    "parentFolderId": "cqygzTh26W5g2zQuA",
    "type": "string",
    "value": "11"
  },
  {
    "_id": "9SprCRdxtHiGg6sbM",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "親自面談",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "tg7exip2uGhi9KJQJ",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "9WmWkGgKFqeuz3kux",
    "childNum": 2,
    "isEnable": true,
    "key": "partTimeTeacher",
    "label": "専任非常勤の別",
    "labelCht": "專職的兼職工作",
    "labelEng": "Dedicated part-time work",
    "level": 1,
    "orderId": 3,
    "parentFolderId": "rWHGNFjFi5TmTgFhA",
    "type": "folder"
  },
  {
    "_id": "9YP9YmnLcq9oXYfNezIL",
    "isEnable": true,
    "label": "初中級",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "OVFWdKTKF2nBabxpgt1D",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "9dhXscKQ6LEDWBHEi",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "27",
    "labelCht": "27",
    "level": 2,
    "orderId": 27,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "27"
  },
  {
    "_id": "9nQonmfRDK8JXpErJ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "19",
    "labelCht": "19",
    "level": 2,
    "orderId": 19,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "19"
  },
  {
    "_id": "9nRLLZKac0ViOCdBXcPn",
    "isEnable": true,
    "label": "就勞",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "63CjBXyx8KaZiOxMjWXu",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "9pgX997HiqMGfseko",
    "isEnable": true,
    "key": "option",
    "label": "介護",
    "labelCht": "無",
    "labelEng": "no",
    "level": 3,
    "orderId": 29,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "22"
  },
  {
    "_id": "9rVLO46T4VFlg0tI9nWd",
    "isEnable": true,
    "label": "浙江省",
    "level": 3,
    "orderId": 13,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "73"
  },
  {
    "_id": "9vHKrapi8fnvygLXw",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "中長期在留者の受入れの終了",
    "labelCht": "中長期在留者の受入れの終了",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "tQKqCf6gdoPMjChn8",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "9w7eyeSua773wLgh8",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "留学",
    "labelCht": "留學",
    "labelEng": "study",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "2ffEgWbnfE9JskS56",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "9y4G34vYbuY8DomdY",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "商用",
    "labelCht": "商用",
    "labelEng": "business",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "2ffEgWbnfE9JskS56",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "A5fjTiPZRpedGxjvQ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "工厂",
    "labelCht": "工業廠房",
    "labelEng": "Industrial plants",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "gyyFBx9L8NHTdDoQg",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "AAFj8AvKSjddRr7yS",
    "isEnable": true,
    "key": "option",
    "label": "CAN THO",
    "labelCht": "CAN THO",
    "labelEng": "CAN THO",
    "level": 3,
    "orderId": 41,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "42"
  },
  {
    "_id": "ADXH4vjc66m4EvNqw",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "従兄妹",
    "labelCht": "從兄妹",
    "level": 2,
    "orderId": 16,
    "parentFolderId": "tBuxP8MZRxbcnMf5s",
    "type": "number",
    "value": "17"
  },
  {
    "_id": "ADeamQDcDQNv7rEt2",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "短期大学",
    "labelCht": "大專",
    "labelEng": "Junior college",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "BkaebxeYePdnJ36Fu",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "AEuR3BM2R5zthwttT",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "華東地区",
    "labelCht": "華東地区",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "AGmdPmeKGCo3uuS8u",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "会社経営",
    "labelCht": "公司經營",
    "labelEng": "Company",
    "level": 2,
    "orderId": 13,
    "parentFolderId": "pvgeMtS2sGZpp5568",
    "type": "number",
    "value": "14"
  },
  {
    "_id": "AGtkJprfmwME5PgQY",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "愛知？？？大学",
    "labelCht": "愛知？？？大学",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "12"
  },
  {
    "_id": "AJC7gw3RrAXMbTxbu",
    "childNum": 4,
    "isEnable": true,
    "key": "studyPurpose",
    "label": "学習意欲、目的、計画",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "SgDgrMYXKfNcWFZFe",
    "type": "folder"
  },
  {
    "_id": "AKZGERLbEpwvExFXu",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "無",
    "labelCht": "無",
    "labelEng": "no",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "pvgeMtS2sGZpp5568",
    "type": "number",
    "value": "0"
  },
  {
    "_id": "AQD6Mdjr6qydub2AC",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "その他",
    "level": 3,
    "orderId": 4,
    "parentFolderId": "HXT9aD3XGeoqxXnjr",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "AYXkgeNp9XQD8Q7kc",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "妻",
    "labelCht": "妻子",
    "labelEng": "wife",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "13"
  },
  {
    "_id": "AaNPkAeWvHnFXGkQk",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "学院長賞",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "ehmkyM9wefvYZZccs",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "AfzFYCqo9tyFLB3jF",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "25",
    "labelCht": "25",
    "level": 2,
    "orderId": 25,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "25"
  },
  {
    "_id": "Akv9ccxLYSjv6Wsrz",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "Y",
    "labelCht": "Y",
    "labelEng": "Y",
    "level": 2,
    "orderId": 24,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "Y"
  },
  {
    "_id": "AqnPEmtTb4KfmYxYx",
    "childNum": 6,
    "isEnable": true,
    "key": "jlptExam",
    "label": "日本語能力試験結果",
    "labelCht": "日本語能力試驗結果",
    "labelEng": "Japanese language proficiency test results",
    "level": 1,
    "orderId": 12,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder"
  },
  {
    "_id": "AroHutSjuXPwSoLSZ",
    "isEnable": true,
    "key": "option",
    "label": "NGHE AN",
    "labelCht": "NGHE AN",
    "labelEng": "NGHE AN",
    "level": 3,
    "orderId": 58,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "66"
  },
  {
    "_id": "As6tJSkHphzkfrdet",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "午前",
    "labelCht": "上午",
    "labelEng": "morning",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "wPJviTp2wWhboF4T7",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "AvrqTJRwkj6Fg9ucj",
    "isEnable": true,
    "key": "option",
    "label": "5年",
    "labelCht": "5年",
    "level": 3,
    "orderId": 5,
    "parentFolderId": "BmvvCvqqpB4QYe7sm",
    "type": "number",
    "value": "60"
  },
  {
    "_id": "B4TFBuK2N9k6q626C",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "HA  NOI",
    "labelCht": "HA  NOI",
    "labelEng": "HA  NOI",
    "level": 3,
    "orderId": 34,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "20"
  },
  {
    "_id": "B8bJAg3zM7SanH8pb",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "母の友人",
    "labelCht": "母の友人",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "tBuxP8MZRxbcnMf5s",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "BADjNRfGqScqTld79y0g",
    "isEnable": true,
    "label": "電話",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "fTHUM3yAl5BL4CtUegyk",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "BBiNgSWRTAnsMtJB8",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "広告",
    "labelCht": "廣告",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "MhufSEi6zWp38naDe",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "BEYYCyCLpfpBej3aB",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "奨学金",
    "labelCht": "獎學金",
    "labelEng": "scholarship",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "jSuvxuEeKGYReZogc",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "BEcYusFEnsFbRxFgi",
    "childNum": 4,
    "isEnable": true,
    "key": "learningPurpose",
    "label": "卒業後予定",
    "labelCht": "卒業後予定",
    "labelEng": "Learning purpose",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "BGtvX8bLobve3m6jz",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "受入教育機関",
    "labelCht": "受入教育機關",
    "labelEng": "into the educatio",
    "level": 3,
    "orderId": 11,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "14"
  },
  {
    "_id": "BHahYrNqRNSDyK8Dr",
    "isEnable": true,
    "key": "option",
    "label": "文化活動",
    "labelCht": "無",
    "labelEng": "no",
    "level": 3,
    "orderId": 24,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "17"
  },
  {
    "_id": "BLkpgd6PZSWm5SrXt",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "一年",
    "labelCht": "一年",
    "labelEng": "One year",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "Yh3mfYk5Ba2wk36nu",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "BMcZ8Wnr6MyXQtuFp",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "早退が多い",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "69HlkaS4w9jOFrxQCqHL",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "BN5NBhsgj9ogKgyoK",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "取引関係者、現地企業等職員",
    "labelCht": "取引関係者、現地企業等職員",
    "labelEng": "Commercial entrustment",
    "level": 3,
    "orderId": 14,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "10"
  },
  {
    "_id": "BNMwqAPg3G93Tr3rW",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "センター",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "gGaXSHkzsM3bm4cWu",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "BNoiAzvdfs8qKPpm6",
    "childNum": 9,
    "isEnable": true,
    "key": "residenceQualify",
    "label": "在留資格",
    "level": 2,
    "orderId": 36,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "BOMdl2bkLqQmAwXLaRVr",
    "isEnable": true,
    "key": "enquiryResult",
    "label": "問合進行狀況",
    "level": 2,
    "orderId": 39,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "BRHXpit5AKwnBiT86",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "叔父",
    "labelCht": "叔父",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "tBuxP8MZRxbcnMf5s",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "BTipGLwwpivwnPcCh",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "取下げ",
    "labelCht": "取下げ",
    "labelEng": "Withdrawl",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "ez7K2htashvgpJ6bk",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "BWnfRqWTyk6Exd6cF",
    "isEnable": true,
    "key": "option",
    "label": "D",
    "labelCht": "D",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "Qsiqo48JuGJtWQ5QH",
    "type": "string",
    "value": "D"
  },
  {
    "_id": "BYKBQ3k75pWsFzZ8h",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "HA  TINH",
    "labelCht": "HA  TINH",
    "labelEng": "HA  TINH",
    "level": 3,
    "orderId": 47,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "25"
  },
  {
    "_id": "BZWLGCFo74ybf92XN",
    "isEnable": true,
    "key": "option",
    "label": "自主中退（退学）",
    "labelCht": "自主中退（退学）",
    "level": 3,
    "orderId": 4,
    "parentFolderId": "HaSH5PobxGEvgzMgA",
    "type": "string",
    "value": "31"
  },
  {
    "_id": "BdQLd9XuWWTEvAXSS",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "叔母",
    "labelCht": "叔母",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "tBuxP8MZRxbcnMf5s",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "BkaebxeYePdnJ36Fu",
    "childNum": 5,
    "isEnable": true,
    "key": "2ndKind",
    "label": "区分2",
    "labelCht": "第2類",
    "labelEng": "2nd kind",
    "level": 1,
    "orderId": 4,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder"
  },
  {
    "_id": "BmvvCvqqpB4QYe7sm",
    "childNum": 3,
    "isEnable": true,
    "key": "residenceDuring",
    "label": "在留期間",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "BpcRPjd7TySy3sHer",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "未提出",
    "level": 2,
    "note1": "red",
    "orderId": 0,
    "parentFolderId": "PK99CZPNooY3HToEm",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "Bq6nyDPanXnREqwsF",
    "childNum": 3,
    "isEnable": true,
    "key": "bicycleCondition",
    "label": "自転車状況",
    "labelCht": "腳踏車狀況",
    "labelEng": "Bicycle condition",
    "level": 1,
    "orderId": 14,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "BwQn34S2aZdAKsRqW",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "欠席",
    "labelCht": "曠課",
    "level": 2,
    "note1": "red",
    "note2": "#f17e7e",
    "orderId": 2,
    "parentFolderId": "Q7v3BFZiByG5oP484",
    "type": "string",
    "value": "8"
  },
  {
    "_id": "By7jCKKrjAKWAeToL",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "F",
    "labelCht": "F",
    "labelEng": "F",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "F"
  },
  {
    "_id": "C4mHpCmzBrQHW5t4j",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "経営・管理",
    "labelEng": "Permanent inhabitant",
    "level": 3,
    "orderId": 17,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "C6M56b2ozy8awp8my",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "合格",
    "labelCht": "合格",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "ckDELkrtdHcK7HB5H",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "C8XLxvjxc6DNMiQ2g",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "半年ごと",
    "labelCht": "半年ごと",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "3osDDHcpBmqxucRjN",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "C8baQxjRqWnFtu6da",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "11月",
    "labelCht": "十一月",
    "labelEng": "November",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "nfpgEp3kq2YEoZJqs",
    "type": "number",
    "value": "11"
  },
  {
    "_id": "C9YGEjbPDFMztsnVDE0S",
    "isEnable": true,
    "label": "その他",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "u3HP2LbW6EEifBydZx0V",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "CFKBj992wwgkm2qcj",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "江蘇省",
    "labelCht": "江蘇省",
    "level": 3,
    "orderId": 15,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "CHfqvr2SDbGPtGFX5",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "妻",
    "labelCht": "妻",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "tBuxP8MZRxbcnMf5s",
    "type": "number",
    "value": "8"
  },
  {
    "_id": "CKa3zPRzA6JuptkAh",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "作文評価",
    "labelCht": "作文評価",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "L4mvofGTyCascbtaP",
    "type": "string",
    "value": "writingScore"
  },
  {
    "_id": "CPcHUc58FIZ66sSzQSbE",
    "isEnable": true,
    "label": "青海省",
    "level": 3,
    "orderId": 31,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "75"
  },
  {
    "_id": "CRXwnsLh9jD3kTjWi",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "良い",
    "labelCht": "良い",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "yXPFuyumZM7gdFG5a",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "CT7NHRWBDk2FBoJQr",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "22",
    "labelCht": "22",
    "level": 2,
    "orderId": 22,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "22"
  },
  {
    "_id": "CXg2ZefKDfECPp4GQ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "私立",
    "labelCht": "私立",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "Pr5TCgZr8YdTKHmGK",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "CZTnggXc9wokFmXfq",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "全額支弁者復負担",
    "labelCht": "全額支弁者復負担",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "ENgSjJvxsiRnHqnAC",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "CbGKHQ9kSFWTz2CQj",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "7月",
    "labelCht": "七月",
    "labelEng": "July",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "nfpgEp3kq2YEoZJqs",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "Cg7uNu3rWQtQcPn8F",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "5",
    "labelCht": "五",
    "labelEng": "fives",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "RjT2jTY5CpxZEZpTG",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "CigGyJutNs4yoMkzT",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "私立",
    "labelCht": "私立",
    "labelEng": "private",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "5ysGkC2k6QXiyXoce",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "CjS24IgcLdQ6TFqtE1lM",
    "isEnable": true,
    "label": "文科系統",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "O8hDoECoSQXwsr5d44nN",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "CkTPfyXP33rsiDwhk",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "短期大学",
    "labelCht": "短期大学",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "cxpsyi4PEyoy5ChzA",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "Covtcq5NxfWGbNpwo",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "每月",
    "labelCht": "每月",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "3osDDHcpBmqxucRjN",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "CuqaA3X2esARSnjtC",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "TUYEN QUANG",
    "labelCht": "TUYEN QUANG",
    "labelEng": "TUYEN QUANG",
    "level": 3,
    "orderId": 71,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "22"
  },
  {
    "_id": "CvZ87j8peKegsUwlg8AA",
    "isEnable": true,
    "key": "isAdmitted",
    "label": "申請合否",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "Cyj8t8Lba8Shphmnk",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "大学院(修士)",
    "labelCht": "大学院(修士)",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "cxpsyi4PEyoy5ChzA",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "D4rubFSvgGgneHWmq",
    "isEnable": true,
    "key": "option",
    "label": "HUNG YEN",
    "labelCht": "HUNG YEN",
    "labelEng": "HUNG YEN",
    "level": 3,
    "orderId": 48,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "61"
  },
  {
    "_id": "DENnuHaXHjGkgLyw4",
    "isEnable": true,
    "key": "option",
    "label": "父母",
    "labelCht": "父母",
    "labelEng": "parent",
    "level": 3,
    "orderId": 4,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "18"
  },
  {
    "_id": "DEh2XwPAZJkbsoQXy",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "14",
    "labelCht": "14",
    "level": 2,
    "orderId": 13,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "14"
  },
  {
    "_id": "DEtkPxkRErC4zFzRc",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "論理",
    "labelCht": "論理",
    "labelEng": "logic",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "tcbMYc3xbaP3isZKN",
    "type": "string",
    "value": "ejuLogicScore"
  },
  {
    "_id": "DL6kNT2EpvixKbYoY",
    "isEnable": true,
    "key": "option",
    "label": "18",
    "labelCht": "18",
    "labelEng": "18",
    "level": 3,
    "orderId": 11,
    "parentFolderId": "7vANGTAAD9sqEfnSs",
    "type": "number",
    "value": "11"
  },
  {
    "_id": "DN3568faqMwAw8KfF",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "兄弟姉妹",
    "labelCht": "兄弟姊妹",
    "labelEng": "brothers and sisters",
    "level": 3,
    "orderId": 9,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "DQ8pbBd3HxnGwBM9g",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "是",
    "labelCht": "是",
    "labelEng": "true",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "o7xTYXNZybstYNYMS",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "DQQLI3vN0xtv3ZDecB2X",
    "isEnable": true,
    "label": "Facebook",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "9K0blwdo2JOk9cInF8jd",
    "type": "string",
    "value": "Facebook"
  },
  {
    "_id": "DRvTRRbG73u7oiFmH",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "病欠",
    "level": 2,
    "note1": "red",
    "note2": "#f17e7e",
    "orderId": 10,
    "parentFolderId": "69HlkaS4w9jOFrxQCqHL",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "DT2fhor2fYZvBPNxv",
    "isEnable": true,
    "key": "option",
    "label": "QUANG NAM",
    "labelCht": "QUANG NAM",
    "labelEng": "QUANG NAM",
    "level": 3,
    "orderId": 63,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "54"
  },
  {
    "_id": "DX9Qc6d2e7ey4Sd3G",
    "isEnable": true,
    "key": "option",
    "label": "DOLAKHA",
    "labelCht": "DOLAKHA",
    "labelEng": "DOLAKHA",
    "level": 3,
    "orderId": 43,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "71"
  },
  {
    "_id": "DZ743KMkFikaB9khp",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "日本福祉大学",
    "labelCht": "日本福祉大学",
    "level": 2,
    "orderId": 36,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "36"
  },
  {
    "_id": "Dan7bTZgSgkSewy7x",
    "childNum": 6,
    "isEnable": true,
    "key": "hobbies",
    "label": "趣味",
    "labelCht": "嗜好",
    "labelEng": "hobbies",
    "level": 2,
    "orderId": 34,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "DcYJkXHqByiiF3nva",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "台北市",
    "labelCht": "台北中日交流協会",
    "labelEng": "Taipei China-Japan Exchange Association",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "WoNy9iaGLSFuWgBMd",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "De6ga4p2wLXuZyf4X",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "普通",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "WTCMNPWByB7EbH3uK",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "Depp7jJ4g2bazcCPg",
    "isEnable": true,
    "key": "option",
    "label": "高度専門職１号ロ",
    "level": 3,
    "orderId": 9,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "DfjzQvyy6sx8ske6f",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "入管申請",
    "labelCht": "入管申請",
    "level": 2,
    "note1": "10",
    "note2": "indexStudent,checkSheetStudent,interviewStudent,applicationStudent,inquiryStudent,",
    "orderId": 4,
    "parentFolderId": "cqygzTh26W5g2zQuA",
    "type": "string",
    "value": "16"
  },
  {
    "_id": "DhAw5mDH3BJuPeN6T",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "在日親族",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "HXT9aD3XGeoqxXnjr",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "DkZYjnAsBw9akZ6dp",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "教授",
    "level": 3,
    "orderId": 20,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "Dvbm3pzNf8xJzrW7G",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "中退",
    "level": 2,
    "note1": "30",
    "note2": "indexStudent,master,affairStudent,testStudent,examStudent,graduateStudent,commentOnTeacher,admissionStatus,inquiryStudent,jpn22",
    "orderId": 8,
    "parentFolderId": "cqygzTh26W5g2zQuA",
    "type": "string",
    "value": "31"
  },
  {
    "_id": "DxgBJaKzttJAcDYui",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "4回",
    "labelCht": "4回",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "gHwK4vnYExL5WCDzw",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "E6uqs4hiXG7Fxtcqk",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "在学中",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "s9fj2Hh8XqrL5WS9y",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "EA3ieqgnvTEHr45KH",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "上海市",
    "labelCht": "上海市",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "EBoqBtGMvQqYX56Wv",
    "isEnable": true,
    "key": "option",
    "label": "NINH THUAN",
    "labelCht": "NINH THUAN",
    "labelEng": "NINH THUAN",
    "level": 3,
    "orderId": 59,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "55"
  },
  {
    "_id": "ECmdKwHxq9shtDsQZ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "7",
    "labelCht": "7",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "EKCb6mjyY6G7nA7EB",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "普通",
    "labelCht": "普通",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "yXPFuyumZM7gdFG5a",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "EM7bkntNchx7zMshf",
    "isEnable": true,
    "key": "option",
    "label": "報道・【研究（転勤）】・【企業内転勤】",
    "labelCht": "無",
    "labelEng": "no",
    "level": 3,
    "orderId": 23,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "16"
  },
  {
    "_id": "EMsiyGTGwjgN73JHn",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "会社員",
    "labelCht": "工作人員",
    "labelEng": "Working crew",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "pvgeMtS2sGZpp5568",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "EMx476y5qT97xamPW",
    "childNum": 14,
    "isEnable": true,
    "key": "position",
    "label": "役職",
    "labelCht": "崗位",
    "labelEng": "position",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "ENgSjJvxsiRnHqnAC",
    "childNum": 3,
    "isEnable": true,
    "key": "tuitionPayment",
    "label": "学費支弁",
    "labelCht": "學費支付",
    "labelEng": "Tuition payment",
    "level": 2,
    "orderId": 13,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "EWwJR4peEwcnNAXku",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "S",
    "labelCht": "S",
    "labelEng": "S",
    "level": 2,
    "orderId": 18,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "S"
  },
  {
    "_id": "EYGMuCFYPdHbTt2dm",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "西南地区",
    "labelCht": "西南地区",
    "labelEng": "西南地区",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "string",
    "value": "11"
  },
  {
    "_id": "EZALoFojNgarKaMZa",
    "isEnable": true,
    "key": "option",
    "label": "技能",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "10"
  },
  {
    "_id": "Ef78y4Gtf43Br3pcS",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "全額自己負担",
    "labelCht": "全額自己負担",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "ENgSjJvxsiRnHqnAC",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "EhPs2DdECguG5rZcs",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "NEPAL",
    "labelCht": "尼泊爾",
    "labelEng": "Nepal",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "boQWTSYGfYnWMf5sK",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "Em8GW5D8x2z9RarFz",
    "isEnable": true,
    "key": "option",
    "label": "10",
    "labelCht": "10",
    "labelEng": "10",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "10"
  },
  {
    "_id": "EmaPvKKZ6KNbWsgFW",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "文法",
    "labelCht": "文法",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "L4mvofGTyCascbtaP",
    "type": "string",
    "value": "grammarScore"
  },
  {
    "_id": "EmzZkpsjHK7u63AF4",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "湖北省",
    "labelCht": "湖北省",
    "labelEng": "湖北省",
    "level": 3,
    "orderId": 25,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "26"
  },
  {
    "_id": "EvWq4bF4iCaEdfBrE",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "J",
    "labelCht": "J",
    "labelEng": "J",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "J"
  },
  {
    "_id": "Ez4B6S7J54k6WrPfZ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "多益成績",
    "labelCht": "多益成績",
    "labelEng": "Toeic Score",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "4Sb5FKDd6jovvGGP7",
    "type": "string",
    "value": "toeicScore"
  },
  {
    "_id": "F8rA4taQRskUuFCYQTYL",
    "isEnable": true,
    "label": "検討",
    "labelCht": "檢討",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "Sx44U3PhaBGOlrg23FrZ",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "FELhE9NwmxNiqTKJo",
    "isEnable": true,
    "key": "option",
    "label": "JAKARTA",
    "level": 3,
    "orderId": 17,
    "parentFolderId": "WoNy9iaGLSFuWgBMd",
    "type": "number",
    "value": "18"
  },
  {
    "_id": "FEo7oiifT8MGzx6DG",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "会話評価",
    "labelCht": "会話評価",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "L4mvofGTyCascbtaP",
    "type": "string",
    "value": "conversationScore"
  },
  {
    "_id": "FL42GFHw6uxGtu5bw",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "申請資料準備中",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "zcF6vqWsqmqhvddxr",
    "type": "string",
    "value": "8"
  },
  {
    "_id": "FMECE7pNrSk8MbK8k",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "愛知学泉大学",
    "labelCht": "愛知学泉大学",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "FRwBM74RxbYjk7X7u",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "普通",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "xaH5ScLzTa9s6dSLk",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "FT2QSiArocsnqxLZA",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "教室のルールを遵守",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "mCSyjN9ezwmtQ8Ydr",
    "type": "number",
    "value": "10"
  },
  {
    "_id": "FTszgEo1bQqzGRKo3X46",
    "isEnable": true,
    "label": "在日知人",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "qrfPtSIWzvPkinLlAjJ3",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "FZPWfgRBKxu3QTSLe",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "入管法違反が有リ",
    "labelCht": "違反了“移民管制法”",
    "labelEng": "Illegal stay",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "msaKtv3iaFn6EBiQv",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "FcgzeLa2yG6hLYFD6",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "大連市",
    "labelCht": "大連市",
    "labelEng": "Dalian",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "WoNy9iaGLSFuWgBMd",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "FdyDAzTfdPzxLZEgK",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "情報文化学",
    "labelCht": "情報文化学",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "i4PzFrvC2mf9Yuich",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "Ffx6sh4cs8q4xaFav",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "個人申請",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "gGaXSHkzsM3bm4cWu",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "FpaK5gRbyciYPAXhq",
    "isEnable": true,
    "key": "option",
    "label": "VINH PHUC",
    "labelCht": "VINH PHUC",
    "labelEng": "VINH PHUC",
    "level": 3,
    "orderId": 73,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "41"
  },
  {
    "_id": "G9GiznptrZkYNxyHp",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "2.5",
    "labelCht": "2.5",
    "labelEng": "2.5",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "WoPJf6A9dJT2cp3hr",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "GB8NkfKFoAFEJqBfi",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "新入生",
    "labelCht": "新生",
    "labelEng": "freshman",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "uEnJwyNSwmKZRNtLj",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "GD9drzsYeZN9zukZC",
    "isEnable": true,
    "key": "option",
    "label": "14",
    "labelCht": "14",
    "labelEng": "14",
    "level": 2,
    "orderId": 13,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "14"
  },
  {
    "_id": "GK32kPmLtoYSJnnBg",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "紛失",
    "labelCht": "紛失",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "Bq6nyDPanXnREqwsF",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "GNCRXMfcnozDr8CTJ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "名古屋工業大学(国立)",
    "labelCht": "名古屋工業大学(国立)",
    "level": 2,
    "orderId": 27,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "27"
  },
  {
    "_id": "GNYQq67Zzu9etrurh",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "BINH PHUOC",
    "labelCht": "BINH PHUOC",
    "labelEng": "BINH PHUOC",
    "level": 3,
    "orderId": 40,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "29"
  },
  {
    "_id": "GRX25uqKHIS9MMc2BiBw",
    "isEnable": true,
    "label": "取り止め",
    "level": 3,
    "orderId": 4,
    "parentFolderId": "Sx44U3PhaBGOlrg23FrZ",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "GTraENiPW6vXM2v4w",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "卒業",
    "labelCht": "7-3、強制退学(除籍)",
    "level": 2,
    "note1": "50",
    "note2": "indexStudent,master,affairStudent,testStudent,examStudent,graduateStudent,commentOnTeacher,admissionStatus,inquiryStudent,",
    "orderId": 10,
    "parentFolderId": "cqygzTh26W5g2zQuA",
    "type": "string",
    "value": "50"
  },
  {
    "_id": "GYkf3FeF3M9nLZwQo",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "無",
    "labelCht": "無",
    "labelEng": "no",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "msaKtv3iaFn6EBiQv",
    "type": "number",
    "value": "0"
  },
  {
    "_id": "Gh88u9kSdNKiZpZuv",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "公立",
    "labelCht": "公立",
    "labelEng": "Public",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "5ysGkC2k6QXiyXoce",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "GhsSsBvwaYACBwdvN",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "無",
    "labelCht": "無",
    "labelEng": "no",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "Dan7bTZgSgkSewy7x",
    "type": "number",
    "value": "0"
  },
  {
    "_id": "GmZ9xyBkrLFMQT5md",
    "isEnable": true,
    "key": "option",
    "label": "卒業",
    "labelCht": "卒業",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "vXMFsq82XWcqvraGd",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "Gu5Z9jyiq5h6Gy6xa",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "成績優等賞",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "ehmkyM9wefvYZZccs",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "H2qatry2Z47w8wisQ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "20",
    "labelCht": "20",
    "level": 2,
    "orderId": 20,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "20"
  },
  {
    "_id": "H3Qu5ZsgAhmySzJH5",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "デザイナー",
    "labelCht": "設計師",
    "labelEng": "Designer",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "pvgeMtS2sGZpp5568",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "H6Rf2BgYHvBGEC8cP",
    "childNum": 38,
    "isEnable": true,
    "key": "placeOfBirth",
    "label": "出生地",
    "labelCht": "出生地",
    "labelEng": "Place of birth",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "H8HzQqgenfwifiAL3",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "Z",
    "labelCht": "Z",
    "labelEng": "Z",
    "level": 2,
    "orderId": 25,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "Z"
  },
  {
    "_id": "H9zJxJJjQWLibEa28",
    "isEnable": true,
    "key": "option",
    "label": "ネパール",
    "level": 3,
    "orderId": 14,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "string",
    "value": "16"
  },
  {
    "_id": "HCYEp7dvjfNtheAeQ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "修正中",
    "level": 2,
    "note1": "yellow",
    "orderId": 2,
    "parentFolderId": "PK99CZPNooY3HToEm",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "HF89cEDBzbyWoomRg",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "電子郵件",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "tg7exip2uGhi9KJQJ",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "HFHpgPXzGh5qAtnon",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "多収",
    "labelCht": "多收",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "nWQHp45PAXXAMxdry",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "HG46JunDucyZHDqRa",
    "isEnable": true,
    "key": "studyProgress",
    "label": "進度",
    "level": 1,
    "orderId": 6,
    "parentFolderId": "jPvXpapnFPui4AGKY",
    "type": "folder"
  },
  {
    "_id": "HHLYBXm78ekFqqaCY",
    "isEnable": true,
    "key": "option",
    "label": "山西省",
    "labelCht": "山西省",
    "level": 3,
    "orderId": 29,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "40"
  },
  {
    "_id": "HIiikJsg7sHYMF7dLNkr",
    "isEnable": true,
    "label": "郵便",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "s2D9ZuE2ghWxdO8CFvID",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "HPlVqeDBcbVReOUBRZE1",
    "isEnable": true,
    "label": "出願",
    "level": 3,
    "note1": "",
    "orderId": 2,
    "parentFolderId": "VQcmpvi8a979GpmMA5JC",
    "type": "string",
    "value": "8"
  },
  {
    "_id": "HQqMKmPgHosNiLxDy",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "学業に専念",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "mCSyjN9ezwmtQ8Ydr",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "HXT9aD3XGeoqxXnjr",
    "childNum": 6,
    "isEnable": true,
    "key": "supportInterview",
    "label": "経費支弁者",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "SgDgrMYXKfNcWFZFe",
    "type": "folder"
  },
  {
    "_id": "HYMlLhcO2DtuyiXWYXS0",
    "isEnable": true,
    "label": "帰国",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "63CjBXyx8KaZiOxMjWXu",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "HZtBSm8GnHg2oJFm2",
    "isEnable": true,
    "key": "option",
    "label": "中級",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "HG46JunDucyZHDqRa",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "HaSH5PobxGEvgzMgA",
    "childNum": 3,
    "isEnable": true,
    "key": "studentStatus",
    "label": "学生状態",
    "labelCht": "學生情況",
    "labelEng": "Student situation",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "niMW6dizwqnorXBhQ",
    "type": "folder"
  },
  {
    "_id": "HgvxHLHdBcTFAigc7",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "父",
    "labelCht": "父",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "tBuxP8MZRxbcnMf5s",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "HkGu5chvNM4yycsRK",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "その他",
    "labelCht": "其他",
    "labelEng": "other",
    "level": 2,
    "orderId": 19,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "HokrqEfBnTv3PHgfR",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "中京学院大学",
    "labelCht": "中京学院大学",
    "level": 2,
    "orderId": 39,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "39"
  },
  {
    "_id": "HpYxwK3JHgSZwcZMR",
    "childNum": 4,
    "isEnable": true,
    "key": "appearance",
    "label": "外見",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "SgDgrMYXKfNcWFZFe",
    "type": "folder"
  },
  {
    "_id": "HuNQGuwQNS3Q7p9KA",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "双人間",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "Mu7xqf8oEtLsqhvGX",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "HwBJe7HCaT2qBXEot",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "不交付",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "cqygzTh26W5g2zQuA",
    "type": "string",
    "value": "19"
  },
  {
    "_id": "HxZ4oGNkqrgifatDJ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "河北省",
    "labelCht": "河北省",
    "level": 3,
    "orderId": 28,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "16"
  },
  {
    "_id": "I6sgRpWYUgFz7ZgZiZFS",
    "isEnable": true,
    "label": "交付 (在籍)",
    "level": 3,
    "orderId": 5,
    "parentFolderId": "VQcmpvi8a979GpmMA5JC",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "IOq7siR3WOZ790uVpPPA",
    "isEnable": true,
    "label": "QQ",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "9K0blwdo2JOk9cInF8jd",
    "type": "string",
    "value": "QQ"
  },
  {
    "_id": "Ih9fvIltQA8bhtWnLFyc",
    "isEnable": true,
    "label": "決定",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "J4Lwvgwrg4f9PfalCkPX",
    "isEnable": true,
    "label": "募集",
    "labelCht": "募集",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "HaSH5PobxGEvgzMgA",
    "type": "string",
    "value": "10"
  },
  {
    "_id": "J4Y8f3iBNFvHqtqui",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "高雄市",
    "labelCht": "高雄中日交流協会",
    "labelEng": "Kaohsiung Sino-Japanese Exchange Association",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "WoNy9iaGLSFuWgBMd",
    "type": "number",
    "value": "19"
  },
  {
    "_id": "J8Xbn32pMCgwPXs4R",
    "isEnable": true,
    "key": "option",
    "label": "3年",
    "labelCht": "3年",
    "level": 3,
    "orderId": 4,
    "parentFolderId": "BmvvCvqqpB4QYe7sm",
    "type": "number",
    "value": "36"
  },
  {
    "_id": "J9Kgr8tYuqjzahmrq",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "入学問合",
    "level": 2,
    "note1": "10",
    "note2": "indexStudent,checkSheetStudent,interviewStudent,applicationStudent,inquiryStudent,",
    "orderId": 0,
    "parentFolderId": "cqygzTh26W5g2zQuA",
    "type": "string",
    "value": "10"
  },
  {
    "_id": "JE76u3hDuD7JK7XM7",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "N4～N3",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "S2xGCLTCAQDsQykoh",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "JGF8rTr32EC36i4WJ",
    "childNum": 2,
    "isEnable": true,
    "key": "term",
    "label": "期間",
    "labelCht": "期別",
    "labelEng": "term",
    "level": 1,
    "orderId": 4,
    "parentFolderId": "rWHGNFjFi5TmTgFhA",
    "type": "folder"
  },
  {
    "_id": "JGnTdm4MGmAynLHmh",
    "childNum": 6,
    "isEnable": true,
    "key": "jlptLevel",
    "label": "JLPT級数",
    "labelCht": "JLPT級数",
    "labelEng": "jpnLevel",
    "level": 1,
    "orderId": 12,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "JP3MPENovK6qqhiNM",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "副社長",
    "labelCht": "副社長",
    "labelEng": "vice-president",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "EMx476y5qT97xamPW",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "JPDLaLHgm3bBYBAGS",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "1月",
    "labelCht": "一月",
    "labelEng": "January",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "nfpgEp3kq2YEoZJqs",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "JR6paamHnzNwX5iuE",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "N3",
    "labelCht": "3級",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "JGnTdm4MGmAynLHmh",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "JRTKCMeBRySSgqYY4",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "性格",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "osrKZifMJuiyDz8n6",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "JTyhjggXaCMpw4Cdz",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "台湾",
    "level": 3,
    "orderId": 4,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "37"
  },
  {
    "_id": "JmOmdOvhqLmz4gZfylzU",
    "isEnable": true,
    "label": "その他",
    "level": 3,
    "orderId": 5,
    "parentFolderId": "fTHUM3yAl5BL4CtUegyk",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "JqLYeAbBizArtZ7cg",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "2014",
    "labelCht": "2014",
    "labelEng": "2014",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "rLDtawHZT5AWhySev",
    "type": "string",
    "value": "2014"
  },
  {
    "_id": "Jymxnr6zBby2ZFenSm80",
    "isEnable": true,
    "label": "わかってる",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "u3HP2LbW6EEifBydZx0V",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "JzO5SOJTviwgRwJoLm12",
    "isEnable": true,
    "label": "合格",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "CvZ87j8peKegsUwlg8AA",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "Jzi6GnDOr47dnuVvJ0Wu",
    "isEnable": true,
    "label": "居眠り",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "oYRlH6kk1Mkvmz1BCuj8",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "K6y69fxt9qeoM2Q4N",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "国立",
    "labelCht": "國立",
    "labelEng": "National",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "5ysGkC2k6QXiyXoce",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "K8ykmFfgZrkmK28JT",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "印象が悪い",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "HpYxwK3JHgSZwcZMR",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "KDGHqHbRs9vfC7GYA",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "松阪大学",
    "labelCht": "松阪大学",
    "level": 2,
    "orderId": 38,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "38"
  },
  {
    "_id": "KEMALgrQjeCuNYTKd",
    "isEnable": true,
    "key": "option",
    "label": "C",
    "labelCht": "C",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "Qsiqo48JuGJtWQ5QH",
    "type": "string",
    "value": "C"
  },
  {
    "_id": "KEtN1tnmCcEvuXnYMm7S",
    "isEnable": true,
    "label": "入管申請",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "VQcmpvi8a979GpmMA5JC",
    "type": "string",
    "value": "-1"
  },
  {
    "_id": "KF7SFyQDQiYMeRSAs",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "除籍",
    "labelCht": "除籍",
    "labelEng": "expelled",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "gXWftsRK8PNwdYTCh",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "KH7RXfsd2ZaWem7r2",
    "isEnable": true,
    "key": "option",
    "label": "1",
    "labelCht": "1",
    "labelEng": "1",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "KP8ZmGCwKqFwzY65P9zc",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "大学",
    "labelCht": "大学",
    "labelEng": "university",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "6FQ5JWq38TJytcnT4",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "KPxjYhsxeE3cJ7JfS",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "私語せず",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "69HlkaS4w9jOFrxQCqHL",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "Ka6PCGnDbcALX6BS6",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "SEOUL",
    "labelCht": "首爾市",
    "labelEng": "Seoul City",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "WoNy9iaGLSFuWgBMd",
    "type": "number",
    "value": "9"
  },
  {
    "_id": "KadPLB8pM7jqJCGMg",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "高等専門学校",
    "labelCht": "職業技術學院",
    "labelEng": "Technical College",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "qLp95bMxAuc5sY8A4",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "KejXHJ35fMgo8cjST",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "50万日円以下",
    "labelCht": "50万日円以下",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "u44m8ojJMZDkEvhRw",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "KjdK9zCZyeuwWmNYW",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "DHAKA",
    "level": 3,
    "orderId": 14,
    "parentFolderId": "WoNy9iaGLSFuWgBMd",
    "type": "number",
    "value": "15"
  },
  {
    "_id": "KochpArCuDZZWGddM",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "上海市",
    "labelCht": "上海市",
    "labelEng": "Shanghai",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "WoNy9iaGLSFuWgBMd",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "KqN9dt77xQxsDBt5E",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "良好趣味か有リ",
    "labelCht": "有良好的興趣",
    "labelEng": "Have good interest",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "Dan7bTZgSgkSewy7x",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "KrM5fA7MSERH6tQBG",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "空欄",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "bZv9NfF5E7u5sb74X",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "KsAJKkxS4ebRNmzkQ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "集中力に欠く",
    "labelCht": "集中力は欠く",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "dWDcptmLf962XkWrZ",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "KuPEjunjQhAexjD7i",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "31",
    "labelCht": "31",
    "level": 2,
    "orderId": 31,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "31"
  },
  {
    "_id": "KzTSemgXfo3pEvd8u",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "3",
    "labelCht": "3",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "L4mvofGTyCascbtaP",
    "childNum": 6,
    "isEnable": true,
    "key": "isTest",
    "label": "校内成績",
    "labelCht": "校內成績",
    "level": 1,
    "orderId": 14,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder"
  },
  {
    "_id": "L7yZT47thhYxmuAJR",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "緑",
    "labelCht": "綠",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "gt3SCaikqeyTh86n5",
    "type": "number",
    "value": "9"
  },
  {
    "_id": "LELe4yqZCGLGDnqHY",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "3年次編入",
    "labelCht": "第三年轉移",
    "labelEng": "Third year  transfer",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "2E6Te5puNo9zFyYqa",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "LRzP6FpcgP95KbkyY",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "聴解",
    "labelCht": "聴解",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "L4mvofGTyCascbtaP",
    "type": "string",
    "value": "listeningScore"
  },
  {
    "_id": "LSDPTNDdE97uNmoZo",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "1200円",
    "labelCht": "1200日元",
    "labelEng": "1200 yen",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "Zfd285m2TeoHBqKmY",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "LaPnLkvJkvZZAndri",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "空欄（詳しい学習目的や学習計画、）",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "AJC7gw3RrAXMbTxbu",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "Lf4KqTukQDyBNaB8P",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "5月1日における受入れ状況",
    "labelCht": "5月1日における受入れ狀況",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "tQKqCf6gdoPMjChn8",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "LnBddmz3riD3282uH",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "観光",
    "labelCht": "觀光",
    "labelEng": "tourism",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "2ffEgWbnfE9JskS56",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "LrYysgZ7B47uonJ2G",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "26",
    "labelCht": "26",
    "level": 2,
    "orderId": 26,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "26"
  },
  {
    "_id": "MBvefEWvSa9rbw4b5",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "在日経費支弁者負担",
    "labelCht": "在日经費支弁者負担",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "jSuvxuEeKGYReZogc",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "MDXEHBzRaHRsKLRmL",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "その他",
    "labelCht": "その他",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "cxpsyi4PEyoy5ChzA",
    "type": "number",
    "value": "9"
  },
  {
    "_id": "MEMlgkLW74POVatMGgzz",
    "isEnable": true,
    "label": "西藏",
    "level": 3,
    "orderId": 20,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "72"
  },
  {
    "_id": "MK5rWJRRMc3cwZv49",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "YEN  BAI",
    "labelCht": "YEN  BAI",
    "labelEng": "YEN  BAI",
    "level": 3,
    "orderId": 74,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "21"
  },
  {
    "_id": "MM4unMy8YjBvtFyLk",
    "isEnable": true,
    "key": "option",
    "label": "3",
    "labelCht": "3",
    "labelEng": "3",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "MX7EAoWLECqukHS2E",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "高等学校",
    "labelCht": "高等學校",
    "labelEng": "高等學校",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "cxpsyi4PEyoy5ChzA",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "Md7MeJ2nqWMSHpMLW",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "聴解",
    "labelCht": "聽解",
    "labelEng": "Comprehension",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "AqnPEmtTb4KfmYxYx",
    "type": "string",
    "value": "jlptListeningScore"
  },
  {
    "_id": "Me5RtdqRZa2LdvKBL",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "非多次短期滞在者",
    "labelCht": "非多次短期停留者",
    "labelEng": "Non-multiple short stayers",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "msaKtv3iaFn6EBiQv",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "Mf72SaXNmCb69iYSD",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "離職",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "5sRrCsobiHfaZpWWE",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "MhufSEi6zWp38naDe",
    "childNum": 2,
    "isEnable": true,
    "key": "advertisement",
    "label": "広告",
    "labelCht": "廣告",
    "level": 1,
    "orderId": 17,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "MntCX4oppTAeWq6SJ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "申請資料チェック中",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "zcF6vqWsqmqhvddxr",
    "type": "string",
    "value": "9"
  },
  {
    "_id": "MraXxe8RPdt5gfWqQscO",
    "defaultValue": "4",
    "isEnable": true,
    "label": "その他",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "5IrfCNDbKJCI1pd9Ta3c",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "Mu7xqf8oEtLsqhvGX",
    "childNum": 4,
    "isEnable": true,
    "key": "roomType",
    "label": "寮型",
    "level": 1,
    "orderId": 5,
    "parentFolderId": "mgfbBDamqaqvzeJ4z",
    "type": "folder"
  },
  {
    "_id": "MxeBZCdpjJtDqQFPr",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "技術・人文知識・国際業務",
    "labelCht": "技能",
    "labelEng": "Skill",
    "level": 3,
    "orderId": 19,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "MxxHmSeCY96nfSxs5",
    "isEnable": true,
    "key": "option",
    "label": "NAM DINH",
    "labelCht": "NAM DINH",
    "labelEng": "NAM DINH",
    "level": 3,
    "orderId": 57,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "56"
  },
  {
    "_id": "N9ou3hVBE6Gu19EVBMYI",
    "isEnable": true,
    "label": "手渡",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "s2D9ZuE2ghWxdO8CFvID",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "N9udJb8NRfoChPD3N",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "良好",
    "labelCht": "良好",
    "labelEng": "good",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "dNdTrpwYafkZoHn3e",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "NBusfEhX38kH5PWbK",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "精勤賞",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "ehmkyM9wefvYZZccs",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "NCYLK9ebQw3TJAaGv",
    "childNum": 0,
    "isEnable": true,
    "key": "common",
    "label": "共同",
    "labelCht": "共同",
    "labelEng": "common",
    "level": 0,
    "orderId": 6,
    "parentFolderId": "",
    "type": "folder"
  },
  {
    "_id": "NCgFQFNr3bJ9v6tcx",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "医者",
    "labelCht": "醫生",
    "labelEng": "doctor",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "pvgeMtS2sGZpp5568",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "NDaQm6GMf2kwCQZxa",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "R",
    "labelCht": "R",
    "labelEng": "R",
    "level": 2,
    "orderId": 17,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "R"
  },
  {
    "_id": "NEtR42wkbtfee7DyM",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "白",
    "labelCht": "白",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "gt3SCaikqeyTh86n5",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "NFbKxHfSLorTYWxz9",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "18",
    "labelCht": "18",
    "level": 2,
    "orderId": 17,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "18"
  },
  {
    "_id": "NHJyNhwN3hB4nuqHR",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "婦国",
    "labelCht": "婦國",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "n5JA32PzthNYg895F",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "NJTZ7srFLcRypfykB",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "出席",
    "level": 2,
    "note1": "green",
    "note2": "#8fe792",
    "orderId": 0,
    "parentFolderId": "Q7v3BFZiByG5oP484",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "NMEK9rsH9g7wNpPmE",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "13",
    "labelCht": "13",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "7vANGTAAD9sqEfnSs",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "NO69Wh7iGDHSboXS5RFL",
    "isEnable": true,
    "label": "出願準備中",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "Sx44U3PhaBGOlrg23FrZ",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "NWhuPzmHnYD5KcCaH",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "外向的",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "qN7FRR6q3QXfkXNZ4",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "NZdacaBx7gtNsF97J",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "卒業",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "s9fj2Hh8XqrL5WS9y",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "NaWAjzDB75ELBSMZB",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "A",
    "labelCht": "A",
    "labelEng": "A",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "A"
  },
  {
    "_id": "Nb1xgoqe2fUfcPpup1hi",
    "isEnable": true,
    "label": "LINE",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "9K0blwdo2JOk9cInF8jd",
    "type": "string",
    "value": "LINE"
  },
  {
    "_id": "NhCbFYt8Dtdoyv3YA",
    "isEnable": true,
    "key": "option",
    "label": "29",
    "labelCht": "29",
    "labelEng": "29",
    "level": 2,
    "orderId": 28,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "29"
  },
  {
    "_id": "NkdWSMendJJL4eDbN",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "沈陽市",
    "labelCht": "沈陽市",
    "labelEng": "Shenyang City",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "WoNy9iaGLSFuWgBMd",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "NoCumKSkfSFhYBZt7",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "工学",
    "labelCht": "工学",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "i4PzFrvC2mf9Yuich",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "NokruztvkpSDyn43R",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "名古屋大学(国立)",
    "labelCht": "名古屋大学(国立)",
    "level": 2,
    "orderId": 34,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "34"
  },
  {
    "_id": "Npq88f8SuwGdyeytq",
    "isEnable": true,
    "key": "option",
    "label": "2",
    "labelCht": "2",
    "labelEng": "2",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "Ntz2N2rT4DzuYaDGL",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "愛知淑徳大学",
    "labelCht": "愛知淑德大学",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "9"
  },
  {
    "_id": "NwNMmsftFdoPD594S",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "9月",
    "labelCht": "九月",
    "labelEng": "September",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "nfpgEp3kq2YEoZJqs",
    "type": "number",
    "value": "9"
  },
  {
    "_id": "O8hDoECoSQXwsr5d44nN",
    "isEnable": true,
    "key": "futureOutWay",
    "label": "志向",
    "level": 2,
    "orderId": 40,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "OVFWdKTKF2nBabxpgt1D",
    "isEnable": true,
    "key": "studentLevel",
    "label": "レベル",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "niMW6dizwqnorXBhQ",
    "type": "folder"
  },
  {
    "_id": "P2CGLSpPEPD9M2jBQ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "広西",
    "labelCht": "広西壮族自治区",
    "labelEng": "広西壮族自治区",
    "level": 3,
    "orderId": 23,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "32"
  },
  {
    "_id": "P4cJvtB49mZpkAq6S",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "20 万円以上",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "WzbRntLC6b4AZKv7s",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "P5YAWN9RhF7z7LS8C",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "祖母",
    "labelCht": "祖母",
    "labelEng": "grandmother",
    "level": 3,
    "orderId": 6,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "P7gv5futG9XQuTLtw",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "人間環境大学",
    "labelCht": "人間環境大学",
    "level": 2,
    "orderId": 37,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "37"
  },
  {
    "_id": "PK99CZPNooY3HToEm",
    "childNum": 4,
    "isEnable": true,
    "key": "statusOfCheckSheet",
    "label": "出願書状態",
    "labelCht": "出願書狀態",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "niMW6dizwqnorXBhQ",
    "type": "folder"
  },
  {
    "_id": "PLfue7uXQGvz5WfWE",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "四人間",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "Mu7xqf8oEtLsqhvGX",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "PLjE5wmx6y6Nqv8rQ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "\b卒業状態1",
    "level": 2,
    "note1": "50",
    "note2": "indexStudent,master,affairStudent,testStudent,examStudent,graduateStudent,commentOnTeacher,admissionStatus,inquiryStudent,",
    "orderId": 12,
    "parentFolderId": "cqygzTh26W5g2zQuA",
    "type": "string",
    "value": "52"
  },
  {
    "_id": "PMkk89bHi7FXkuQgk",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "東海学園大学",
    "labelCht": "東海学園大学",
    "level": 2,
    "orderId": 13,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "14"
  },
  {
    "_id": "PNnd8LAm46tWsLhni",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "一部負担",
    "labelCht": "一部負担",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "ENgSjJvxsiRnHqnAC",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "PSCKH7dJR3qEoCpmj",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "留学センター(会社)",
    "labelCht": "國際研究中心",
    "labelEng": "Center for International Studies",
    "level": 3,
    "orderId": 17,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "16"
  },
  {
    "_id": "PXjWNzHndHrkWCRAu",
    "isEnable": true,
    "key": "option",
    "label": "QUANG NINH",
    "labelCht": "QUANG NINH",
    "labelEng": "QUANG NINH",
    "level": 3,
    "orderId": 65,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "59"
  },
  {
    "_id": "PZ9naQTHqmQc2uZtW",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "早退",
    "level": 2,
    "note1": "yellow",
    "note2": "#f5e55a",
    "orderId": 7,
    "parentFolderId": "69HlkaS4w9jOFrxQCqHL",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "Pa42pAKHzGEmay79R",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "delivery",
    "labelCht": "delivery",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "gFQwZn9PWmDZNR5ug",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "PaStSPX6i3aBXSYjz",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "X",
    "labelCht": "X",
    "labelEng": "X",
    "level": 2,
    "orderId": 23,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "X"
  },
  {
    "_id": "PkKieNtLxJq9RKqA8",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "6",
    "labelCht": "6",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "7vANGTAAD9sqEfnSs",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "PkX48nbRSiQ8Hh2wx",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "社員",
    "labelCht": "員工",
    "labelEng": "Employee",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "EMx476y5qT97xamPW",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "PkeAMD32JxZNgaQ4K",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "芸術",
    "labelEng": "Japanese",
    "level": 3,
    "orderId": 22,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "9"
  },
  {
    "_id": "PmFRyevm9GEBXAWFP",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "学業",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "qXE4LZeyWvrDrQz6r",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "PnBGZgkJ3EwnbP82p",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "畜産業",
    "level": 2,
    "orderId": 17,
    "parentFolderId": "pvgeMtS2sGZpp5568",
    "type": "string",
    "value": "18"
  },
  {
    "_id": "PndQmJb8ioJjPrtRa",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "低い（苦手）",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "xaH5ScLzTa9s6dSLk",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "PpbNrDfLYDrqmsgfw",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "可",
    "labelCht": "尚可",
    "labelEng": "normal",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "dNdTrpwYafkZoHn3e",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "Pr5TCgZr8YdTKHmGK",
    "childNum": 2,
    "isEnable": true,
    "key": "schoolproperty",
    "label": "学校属性",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "Prj5NX4a94oYPrRHH",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "E",
    "labelCht": "E",
    "labelEng": "E",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "E"
  },
  {
    "_id": "Pt2kjZtzcxGv4Gd6d",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "金城学院大学",
    "labelCht": "金城学院大学",
    "level": 2,
    "orderId": 48,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "48"
  },
  {
    "_id": "PwYPNkFnBkMThjm58",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "16",
    "labelCht": "16",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "7vANGTAAD9sqEfnSs",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "Q1lJhsGWrgiHGFwpHMbU",
    "isEnable": true,
    "label": "研究生",
    "level": 3,
    "orderId": 4,
    "parentFolderId": "aeVfmJdSFVXgadjZy0CZ",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "Q59Gs68H3aIz3kDwY0Pu",
    "isEnable": true,
    "label": "決定",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "kxYZfrN0om5qcfmq6DSP",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "Q5ak7QH7SMMpv7FoG",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "＜中国＞会考認証、学歴認証",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "g2EWYARWXmnBYcPHY",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "Q7v3BFZiByG5oP484",
    "childNum": 8,
    "isEnable": true,
    "key": "attentInClassroom",
    "label": "出席情況",
    "level": 1,
    "orderId": 6,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder"
  },
  {
    "_id": "QE2sEmauDQFkxbkBc",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "BAC  NINH",
    "labelCht": "BAC  NINH",
    "labelEng": "BAC  NINH",
    "level": 3,
    "orderId": 39,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "28"
  },
  {
    "_id": "QGGrkAYHXkHMdqs5K",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "伯父(叔父)、伯母(叔母)",
    "labelCht": "伯父(叔父)、伯母(叔母)",
    "labelEng": "uncle and auntie",
    "level": 3,
    "orderId": 10,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "QJY8yhpgXanYuQxfy",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "専門学校",
    "labelCht": "職業學校",
    "labelEng": "Vocational school",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "BkaebxeYePdnJ36Fu",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "QSLzaPBhjnnZuYBur",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "空欄（備考）",
    "level": 3,
    "orderId": 6,
    "parentFolderId": "S2xGCLTCAQDsQykoh",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "QYGAKGHkLnFQdNJEA",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "空欄（性格に対する評価）",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "qN7FRR6q3QXfkXNZ4",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "QmCeGewHTQF6izbYS",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "良い",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "WTCMNPWByB7EbH3uK",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "Qr3EGBRiC9wMsaTEy",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "中国",
    "labelCht": "中國",
    "labelEng": "chn",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "boQWTSYGfYnWMf5sK",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "Qsiqo48JuGJtWQ5QH",
    "isEnable": true,
    "key": "abcd",
    "label": "ABCD",
    "level": 1,
    "orderId": 21,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "QuaTBGyRTohfWhBwL",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "其他管道",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "tg7exip2uGhi9KJQJ",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "QvvzPvN9J53EGGg6Q",
    "isEnable": true,
    "key": "option",
    "label": "AN GIANG",
    "labelCht": "AN GIANG",
    "labelEng": "AN GIANG",
    "level": 3,
    "orderId": 36,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "64"
  },
  {
    "_id": "QyqrKwDSP4YigJeAz",
    "isEnable": true,
    "key": "option",
    "label": "技能実習1号イ",
    "labelEng": "Study abroad",
    "level": 3,
    "orderId": 11,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "251"
  },
  {
    "_id": "QzqrQaXpJkcEPEx9B",
    "isEnable": true,
    "key": "option",
    "label": "13",
    "labelCht": "13",
    "labelEng": "13",
    "level": 2,
    "orderId": 12,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "13"
  },
  {
    "_id": "R5DkkBvmEr88EgEZX",
    "isEnable": true,
    "key": "option",
    "label": "PHILIPPINES",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "boQWTSYGfYnWMf5sK",
    "type": "string",
    "value": "12"
  },
  {
    "_id": "R7HrureaNAkwn2S6p",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "HA NOI",
    "labelCht": "HA NOI",
    "labelEng": "HA NOI",
    "level": 2,
    "orderId": 13,
    "parentFolderId": "WoNy9iaGLSFuWgBMd",
    "type": "number",
    "value": "13"
  },
  {
    "_id": "R88vGKeBpoJwLsHcN",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "通訊軟体",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "tg7exip2uGhi9KJQJ",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "RABP6GAANpPeq64ph",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "受入教育機関",
    "labelCht": "受入教育機関",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "tBuxP8MZRxbcnMf5s",
    "type": "number",
    "value": "9"
  },
  {
    "_id": "RCLmsyBeY2PyfANPK",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "文学",
    "labelCht": "文学",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "i4PzFrvC2mf9Yuich",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "RMC7Hyh2CGJfraWZX",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "進学",
    "labelCht": "進学",
    "labelEng": "Admission",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "6FQ5JWq38TJytcnT4",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "RWAkPCGn3X6tfZ2LH",
    "childNum": 0,
    "isEnable": true,
    "key": "multipleOptions",
    "label": "複選",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "e6PTu4uCLHcD5qe9i",
    "type": "folder"
  },
  {
    "_id": "Rb6acfqvPPJ2huBbe",
    "childNum": 3,
    "isEnable": true,
    "key": "courseType",
    "label": "課程別",
    "labelCht": "課程類別",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "niMW6dizwqnorXBhQ",
    "type": "folder"
  },
  {
    "_id": "Rd6RSzWyNdBg3KrQN",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "三人間",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "Mu7xqf8oEtLsqhvGX",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "Rfk4920YkoAoFJEir7WB",
    "isEnable": true,
    "label": "その他",
    "level": 3,
    "orderId": 5,
    "parentFolderId": "aeVfmJdSFVXgadjZy0CZ",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "RggnGxcbEq8b7K6kQ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "経営学",
    "labelCht": "經營學",
    "level": 2,
    "orderId": 12,
    "parentFolderId": "i4PzFrvC2mf9Yuich",
    "type": "string",
    "value": "13"
  },
  {
    "_id": "RhcLm9fv4Rsh7PgrY",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "空欄（その他、職業、収入見込み）",
    "level": 3,
    "orderId": 5,
    "parentFolderId": "HXT9aD3XGeoqxXnjr",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "RizLyRJmyMZUv9YJopqx",
    "isEnable": true,
    "label": "就職",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "aeVfmJdSFVXgadjZy0CZ",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "RjT2jTY5CpxZEZpTG",
    "childNum": 7,
    "isEnable": true,
    "key": "immigrationExperienceBeforeEntry",
    "label": "入学前の出入国歷",
    "labelCht": "入境前移民的經歷",
    "labelEng": "Immigration experience before entry",
    "level": 2,
    "orderId": 26,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "Rnq7e8ZTZYnwcaAEE",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "12",
    "labelCht": "12",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "12"
  },
  {
    "_id": "RopAyF9BvFtTmpzny",
    "isEnable": true,
    "key": "option",
    "label": "4",
    "labelCht": "4",
    "labelEng": "4",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "RsWQo6gouRLdpfXYF",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "[聴解(日本語)]",
    "labelCht": "[聽力（日語）]",
    "labelEng": "[Listening (Japanese)]",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "tcbMYc3xbaP3isZKN",
    "type": "string",
    "value": "ejuListeningScore"
  },
  {
    "_id": "RtiYtcS7WjHxE97v3",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "院長",
    "labelCht": "院長",
    "labelEng": "principal",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "e9NyKnpkStDpsMacQ",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "RvQZhcDmhk9T9hsPb",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "P",
    "level": 2,
    "orderId": 15,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "P"
  },
  {
    "_id": "RzptbtNHww8Fvj6Dh",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "募集学生(複選)",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "pem5s29SZy8SBG379",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "S2xGCLTCAQDsQykoh",
    "childNum": 7,
    "isEnable": true,
    "key": "jpnAbilityInterview",
    "label": "日本語能力（面接の測定）",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "SgDgrMYXKfNcWFZFe",
    "type": "folder"
  },
  {
    "_id": "S3sxv4xovoBY2JDNa",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "自主退学",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "e2mykSsn3Dy6Tzv3g",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "S7ozYnGPsJvDmrkTn",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "副主任",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "e9NyKnpkStDpsMacQ",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "SAJfb4TFJTuidda3N",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "支店長",
    "labelCht": "分公司經理",
    "labelEng": "Branch manager",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "EMx476y5qT97xamPW",
    "type": "number",
    "value": "8"
  },
  {
    "_id": "SFiQJh7jP5HZ7c6QA",
    "isEnable": true,
    "key": "option",
    "label": "20",
    "labelCht": "20",
    "labelEng": "20",
    "level": 2,
    "orderId": 19,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "20"
  },
  {
    "_id": "SG4omYyeqiyfFKH6C",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "QUANG NGAI",
    "labelCht": "QUANG NGAI",
    "labelEng": "QUANG NGAI",
    "level": 3,
    "orderId": 64,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "23"
  },
  {
    "_id": "SGSJ7sAEJyzBaQxy5",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "合計",
    "labelCht": "合計",
    "labelEng": "Total Score",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "AqnPEmtTb4KfmYxYx",
    "type": "string",
    "value": "jlptTotalScore"
  },
  {
    "_id": "SN2gKWsMDRck3Wv7B",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "3",
    "labelCht": "三",
    "labelEng": "three",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "RjT2jTY5CpxZEZpTG",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "SN47Z5zqXRbatDD3L",
    "isEnable": true,
    "key": "option",
    "label": "HO CHI MINH",
    "labelCht": "HO CHI MINH",
    "labelEng": "HO CHI MINH",
    "level": 3,
    "orderId": 35,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "52"
  },
  {
    "_id": "SPeZDb2LcYPmk6w7q",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "福建省",
    "level": 3,
    "orderId": 14,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "35"
  },
  {
    "_id": "SRiiFftH8YqXKRerw",
    "isEnable": true,
    "key": "option",
    "label": "15",
    "labelCht": "15",
    "labelEng": "15",
    "level": 2,
    "orderId": 14,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "15"
  },
  {
    "_id": "STQzbKuqawRTpmsQQ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "優秀卒業生",
    "labelCht": "優秀卒業生",
    "labelEng": "Excellent graduates",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "actFCjDexwbdhJAFL",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "SW83gbJmJqJbFRapw",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "進学希望ですが、自分の考えはまだ明白ではない状態。",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "AJC7gw3RrAXMbTxbu",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "SXRZqWZxxk4H5Ew6M",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "中京女子大学",
    "labelCht": "中京女子大学",
    "level": 2,
    "orderId": 40,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "40"
  },
  {
    "_id": "SYgpMeYKTtcnr642E",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "8月",
    "labelCht": "八月",
    "labelEng": "August",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "nfpgEp3kq2YEoZJqs",
    "type": "number",
    "value": "8"
  },
  {
    "_id": "SdXXevFa3cvseXzbv",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "社長",
    "labelCht": "社長",
    "labelEng": "president",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "EMx476y5qT97xamPW",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "SfkfvJgNY8HdoLyTn",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "9",
    "labelCht": "9",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "9"
  },
  {
    "_id": "Sg9HQb8FJc8pZFGmw",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "病気",
    "labelCht": "病氣",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "n5JA32PzthNYg895F",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "SgDgrMYXKfNcWFZFe",
    "childNum": 0,
    "isEnable": true,
    "key": "interviewSel",
    "label": "面接",
    "level": 1,
    "orderId": 2,
    "parentFolderId": "jPvXpapnFPui4AGKY",
    "type": "folder"
  },
  {
    "_id": "ShAoGQACenqHvJs6z",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "17",
    "labelCht": "17",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "7vANGTAAD9sqEfnSs",
    "type": "number",
    "value": "8"
  },
  {
    "_id": "Sjqg6TAjK2pGnCwKW",
    "isEnable": true,
    "key": "minute",
    "label": "分",
    "labelCht": "分",
    "labelEng": "分",
    "level": 1,
    "orderId": 22,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "Skqrt6foBhZyQaKTJ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "800円",
    "labelCht": "800日元",
    "labelEng": "800 yen",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "Zfd285m2TeoHBqKmY",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "Sx44U3PhaBGOlrg23FrZ",
    "isEnable": true,
    "key": "applyProcess",
    "label": "進学進度",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder",
    "value": ""
  },
  {
    "_id": "T3dGWwTNt8WDAe9nG",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "豊田工業大学",
    "labelCht": "豐田工業大学",
    "level": 2,
    "orderId": 17,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "17"
  },
  {
    "_id": "T6nGQu7C55HTGhb8h",
    "isEnable": true,
    "key": "option",
    "label": "17",
    "labelCht": "17",
    "labelEng": "17",
    "level": 2,
    "orderId": 16,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "17"
  },
  {
    "_id": "T7do8NzWAvFEfkJi4",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "学院長輔佐",
    "labelCht": "助理秘書長",
    "labelEng": "Assistant secretary general",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "e9NyKnpkStDpsMacQ",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "TAhvPeayWSguzgY5V4y4",
    "isEnable": true,
    "label": "来校",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "fTHUM3yAl5BL4CtUegyk",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "TCeaFPw86jtTXCJWn",
    "isEnable": true,
    "key": "option",
    "label": "その他",
    "labelCht": "その他",
    "level": 3,
    "orderId": 6,
    "parentFolderId": "HaSH5PobxGEvgzMgA",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "TKQEqZnvqAFwDWX3A",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "50万-100万日円",
    "labelCht": "50万-100万日円",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "u44m8ojJMZDkEvhRw",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "TN4JF9ZQCCLYtBi5Z",
    "isEnable": true,
    "key": "option",
    "label": "BAC GIANG",
    "labelCht": "BAC GIANG",
    "labelEng": "BAC GIANG",
    "level": 3,
    "orderId": 38,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "62"
  },
  {
    "_id": "TSMyuGtYTRMTLzWRv",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "愛知大学",
    "labelCht": "愛知大学",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "10"
  },
  {
    "_id": "TX3dNmIr4dMMrnBwh3CL",
    "isEnable": true,
    "key": "relation",
    "label": "簡易学生との関係",
    "level": 2,
    "orderId": 17,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "TXtbHCqKHnbcdN3vG",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "ミャンマー",
    "labelCht": "緬甸",
    "labelEng": "Myanmar",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "boQWTSYGfYnWMf5sK",
    "type": "string",
    "value": "8"
  },
  {
    "_id": "TY3DEeAhFgeeukSqFXzO",
    "isEnable": true,
    "label": "事假",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "u3HP2LbW6EEifBydZx0V",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "Ta6wNme2hdfbCvEaN",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "科目履修生",
    "labelCht": "課程重修生",
    "labelEng": "Course rehabilitative student",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "2E6Te5puNo9zFyYqa",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "TfKieKNcwfFeeLiri",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "30",
    "labelCht": "30",
    "level": 2,
    "orderId": 30,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "30"
  },
  {
    "_id": "TgbsZKqoKyLrYqG8u",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "6",
    "labelCht": "6",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "Thv9HCi8T5xmtK6e7",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "美術",
    "labelCht": "美術",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "i4PzFrvC2mf9Yuich",
    "type": "string",
    "value": "12"
  },
  {
    "_id": "Tk73ebgKksqjZ4T4B",
    "isEnable": true,
    "key": "option",
    "label": "特定技能",
    "labelCht": "無",
    "labelEng": "no",
    "level": 3,
    "orderId": 28,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "21"
  },
  {
    "_id": "TuaCjnKk4QKw5pMe9",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "入学許可",
    "labelCht": "3-2、入管申請失敗",
    "level": 2,
    "note1": "10",
    "note2": "inquiryStudent,",
    "orderId": 3,
    "parentFolderId": "cqygzTh26W5g2zQuA",
    "type": "string",
    "value": "15"
  },
  {
    "_id": "UqLl8TjzjwV69Yaogoca",
    "isEnable": true,
    "label": "囑託先",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "TX3dNmIr4dMMrnBwh3CL",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "V5N3sH00lC6tI8UOQJWw",
    "isEnable": true,
    "label": "入学辞退",
    "level": 3,
    "orderId": 8,
    "parentFolderId": "VQcmpvi8a979GpmMA5JC",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "VQcmpvi8a979GpmMA5JC",
    "isEnable": true,
    "key": "applyResult",
    "label": "結果",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "niMW6dizwqnorXBhQ",
    "type": "folder"
  },
  {
    "_id": "VSegmM5OJ6nkIoxUQcN9",
    "isEnable": true,
    "label": "中級",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "OVFWdKTKF2nBabxpgt1D",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "VyyMSYTdJ8L1Rg3CBSHL",
    "isEnable": true,
    "label": "公假",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "u3HP2LbW6EEifBydZx0V",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "WCmEsMexBKGQdqHPc",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "会長",
    "labelCht": "會長",
    "labelEng": "President",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "EMx476y5qT97xamPW",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "WP36RBjgrsWtv9kM4",
    "isEnable": true,
    "key": "option",
    "label": "8",
    "labelCht": "8",
    "labelEng": "8",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "8"
  },
  {
    "_id": "WRYui3XlXP7xO3T0X8U2",
    "isEnable": true,
    "label": "決定",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "WTCMNPWByB7EbH3uK",
    "childNum": 4,
    "isEnable": true,
    "key": "attitude",
    "label": "態度",
    "level": 2,
    "orderId": 12,
    "parentFolderId": "SgDgrMYXKfNcWFZFe",
    "type": "folder"
  },
  {
    "_id": "WXPZrqvphsRJNGrpb",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "儀表",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "osrKZifMJuiyDz8n6",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "WfHZNrrioLxiSt3q2",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "天津市",
    "labelCht": "天津",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "12"
  },
  {
    "_id": "Wk9wh7PdzNGXnkzPq",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "灰",
    "labelCht": "灰",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "gt3SCaikqeyTh86n5",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "WmpRxdDF5zAT4tcww",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "その他",
    "labelCht": "其他",
    "labelEng": "Other",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "msaKtv3iaFn6EBiQv",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "WmrRDTSPz6p3md4fd",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "校方",
    "labelCht": "校方",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "dvFQoxKGx6GQuHz7K",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "WoNy9iaGLSFuWgBMd",
    "childNum": 17,
    "isEnable": true,
    "key": "scheduledVisitLocation",
    "label": "査証予定地",
    "labelCht": "預定的訪問地點",
    "labelEng": "Scheduled visit location",
    "level": 2,
    "orderId": 30,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "WoPJf6A9dJT2cp3hr",
    "childNum": 7,
    "isEnable": true,
    "key": "seniority",
    "label": "継続勤務年数",
    "labelCht": "年資",
    "labelEng": "seniority",
    "level": 1,
    "orderId": 1,
    "parentFolderId": "rWHGNFjFi5TmTgFhA",
    "type": "folder"
  },
  {
    "_id": "WsnGCPzJJdTTuo66F",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "従兄妹",
    "labelCht": "從兄妹",
    "level": 3,
    "orderId": 19,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "Wx7X4btnL2WAG94mp",
    "isEnable": true,
    "key": "option",
    "label": "特定活動（研究活動等）",
    "labelCht": "無",
    "labelEng": "no",
    "level": 3,
    "orderId": 25,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "18"
  },
  {
    "_id": "WzbRntLC6b4AZKv7s",
    "childNum": 4,
    "isEnable": true,
    "key": "monthmoney",
    "label": "生活費月額",
    "level": 2,
    "orderId": 20,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "X4yexxDaF2HX7bGwk",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "1",
    "labelCht": "1",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "X5YBPbzfkgstcPEKu",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "私語が多い",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "dWDcptmLf962XkWrZ",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "X7SHngCxeiiEo5aLG",
    "isEnable": true,
    "key": "option",
    "label": "28",
    "labelCht": "28",
    "labelEng": "28",
    "level": 2,
    "orderId": 27,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "28"
  },
  {
    "_id": "XFMC2dXwyZvvYHdZF",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "愛知学院大学",
    "labelCht": "愛知学院大学",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "XL7duk9ow3PFZK2KB",
    "isEnable": true,
    "key": "option",
    "label": "インドネシア",
    "labelCht": "印尼",
    "level": 3,
    "orderId": 17,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "string",
    "value": "18"
  },
  {
    "_id": "XLQgsbrT69TLB2SBP",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "11",
    "labelCht": "11",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "11"
  },
  {
    "_id": "XZQysF3oYDRKQP7s3",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "態度",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "osrKZifMJuiyDz8n6",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "Xavhj4cmbwQfH7JGj",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "新聞",
    "labelCht": "新聞",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "MhufSEi6zWp38naDe",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "XesNYSfyfksxTkTxx",
    "isEnable": true,
    "key": "option",
    "label": "MAKAWANPUR",
    "labelCht": "MAKAWANPUR",
    "labelEng": "MAKAWANPUR",
    "level": 3,
    "orderId": 55,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "70"
  },
  {
    "_id": "XgNAKjMjAxfA6L8NY",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "150万-200万日円",
    "labelCht": "150万-200万日円",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "u44m8ojJMZDkEvhRw",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "XguOqtIp8o4jS9ckNzWG",
    "isEnable": true,
    "label": "理科系統",
    "level": 3,
    "orderId": 8,
    "parentFolderId": "",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "XhZqdknEDKBMLXZjs",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "友人、知人",
    "labelCht": "友人、熟人",
    "labelEng": "Friend, acquaintance",
    "level": 3,
    "orderId": 12,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "8"
  },
  {
    "_id": "Xm3G7htdRhg3tm7cK",
    "isEnable": true,
    "key": "delete",
    "label": "封存",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "niMW6dizwqnorXBhQ",
    "type": "folder"
  },
  {
    "_id": "XpReAYX35yXqzy4fw",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "南山大学",
    "labelCht": "南山大学",
    "level": 2,
    "orderId": 35,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "35"
  },
  {
    "_id": "Xu5Y2VR93dbnpD4tkkbV",
    "isEnable": true,
    "label": "大学",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "aeVfmJdSFVXgadjZy0CZ",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "XujdFRNRK2njv8BT7",
    "childNum": 0,
    "isEnable": true,
    "isPrintForm": true,
    "key": "option",
    "label": "その他",
    "labelCht": "其他",
    "labelEng": "Other",
    "level": 2,
    "orderId": 19,
    "parentFolderId": "pvgeMtS2sGZpp5568",
    "type": "number",
    "value": "13"
  },
  {
    "_id": "XvdtArf4hL4Anyqik",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "BANGLADESH",
    "labelCht": "孟加拉",
    "labelEng": "Bangladesh",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "boQWTSYGfYnWMf5sK",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "Xw5fRuhRDpNWJ5hf7",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "10月",
    "labelCht": "十月",
    "labelEng": "October",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "nfpgEp3kq2YEoZJqs",
    "type": "number",
    "value": "10"
  },
  {
    "_id": "Xx9KXQJTX22MgtrtZ",
    "isEnable": true,
    "key": "option",
    "label": "KIEN GANG",
    "labelCht": "KIEN GANG",
    "labelEng": "KIEN GANG",
    "level": 3,
    "orderId": 53,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "63"
  },
  {
    "_id": "Y3fC55XnjXfixEfyF",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "未修者～N5",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "S2xGCLTCAQDsQykoh",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "Y48Ex9fLhN5SJidoc",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "茶",
    "labelCht": "茶",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "gt3SCaikqeyTh86n5",
    "type": "number",
    "value": "8"
  },
  {
    "_id": "Y8eh8RobwdsXx7PAz",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "COLOMBO",
    "level": 2,
    "orderId": 16,
    "parentFolderId": "WoNy9iaGLSFuWgBMd",
    "type": "number",
    "value": "17"
  },
  {
    "_id": "YCSFJNEyHaoxpfHYF",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "大人しい",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "qN7FRR6q3QXfkXNZ4",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "YEvNkhWRpyoYfc4gd",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "友人・知人の親族",
    "labelCht": "友人・知人の親族",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "tBuxP8MZRxbcnMf5s",
    "type": "number",
    "value": "10"
  },
  {
    "_id": "YGuFvNt7MAYKPFS6k",
    "isEnable": true,
    "key": "option",
    "label": "北京市",
    "labelCht": "北京市",
    "labelEng": "Beijing city",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "number",
    "value": "13"
  },
  {
    "_id": "YHxgNrzYPC5pokrGx",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "copy",
    "labelCht": "copy",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "gFQwZn9PWmDZNR5ug",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "YLQRQCg273wdTAtCR",
    "isEnable": true,
    "key": "option",
    "label": "特定活動（ＥＰＡ家族）",
    "labelCht": "無",
    "labelEng": "no",
    "level": 3,
    "orderId": 27,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "20"
  },
  {
    "_id": "YNDYTk267Jj8ygBkS",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "質問にちゃんと返事",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "69HlkaS4w9jOFrxQCqHL",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "YPPkOFytgajtsUjN9Kyk",
    "isEnable": true,
    "label": "甘肅省",
    "level": 3,
    "orderId": 30,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "74"
  },
  {
    "_id": "YY93KNCs6Jke2q7Xc",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "募集",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "Xm3G7htdRhg3tm7cK",
    "type": "string",
    "value": "10"
  },
  {
    "_id": "Yh3mfYk5Ba2wk36nu",
    "childNum": 2,
    "isEnable": true,
    "key": "oneOrHalfYear",
    "label": "一年半年",
    "labelCht": "第一次更新",
    "labelEng": "First update",
    "level": 1,
    "orderId": 6,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "YjRXxxnjrerbHEA3T",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "T",
    "labelCht": "T",
    "labelEng": "T",
    "level": 2,
    "orderId": 19,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "T"
  },
  {
    "_id": "Yjea2bAWwcuoY7Moh",
    "isEnable": true,
    "key": "option",
    "label": "INDONESIA",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "boQWTSYGfYnWMf5sK",
    "type": "string",
    "value": "13"
  },
  {
    "_id": "YjnC86WTP25yqgZx5",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "IT",
    "labelCht": "IT",
    "level": 2,
    "orderId": 14,
    "parentFolderId": "i4PzFrvC2mf9Yuich",
    "type": "string",
    "value": "15"
  },
  {
    "_id": "YjnwhaodJWeQ4YKA2",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "15",
    "labelCht": "15",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "7vANGTAAD9sqEfnSs",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "YmP8hsh6JaYw6GYaw",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "主任",
    "labelCht": "主任",
    "labelEng": "director",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "e9NyKnpkStDpsMacQ",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "Yod6ojmvQfXWS4oGz",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "父",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "HXT9aD3XGeoqxXnjr",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "YsYq5SSomDMhmtWY8",
    "childNum": 2,
    "isEnable": true,
    "key": "classStatus",
    "label": "開班状態",
    "labelCht": "開班狀態",
    "level": 1,
    "orderId": 5,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder"
  },
  {
    "_id": "ZAAnfcCX4g8D6AN6T",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "6.5以上",
    "labelCht": "6.5以上",
    "labelEng": "6.5以上",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "WoPJf6A9dJT2cp3hr",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "ZABq3jma3xKLxGK9Y",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "S",
    "labelCht": "S",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "rcFvLqYSKCS5kt3Sm",
    "type": "string",
    "value": "S"
  },
  {
    "_id": "ZGstj2rmEh4kPB7ij",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "合否",
    "labelCht": "合否",
    "labelEng": "Result",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "AqnPEmtTb4KfmYxYx",
    "type": "string",
    "value": "jlptResult"
  },
  {
    "_id": "ZHS9uohdY5t5PouFE",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "友人、知人の親族",
    "labelCht": "朋友，熟人的親戚",
    "labelEng": "Friend,Acquaintance relatives",
    "level": 3,
    "orderId": 13,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "9"
  },
  {
    "_id": "ZPfS9qJE34kSxw7Pc",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "補欠",
    "labelCht": "備取",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "ckDELkrtdHcK7HB5H",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "ZQjj7gMWMpTn8Z7Xv",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "公務員",
    "labelCht": "公務員",
    "labelEng": "Civil servant",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "pvgeMtS2sGZpp5568",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "ZTqomxsN58YR6PFna",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "ナイジェリア",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "boQWTSYGfYnWMf5sK",
    "type": "string",
    "value": "10"
  },
  {
    "_id": "ZXDUHe2srWpgpG8o4LpT",
    "isEnable": true,
    "label": "問合せ",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "VQcmpvi8a979GpmMA5JC",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "ZXzB2kDdzkUpi22FalDy",
    "isEnable": true,
    "label": "礼儀が正しい",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "mCSyjN9ezwmtQ8Ydr",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "Zcqv5Rae9hKHZzFdR",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "文法",
    "labelCht": "文法",
    "labelEng": "grammar",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "AqnPEmtTb4KfmYxYx",
    "type": "string",
    "value": "jlptGrammarScore"
  },
  {
    "_id": "Zfd285m2TeoHBqKmY",
    "childNum": 4,
    "isEnable": true,
    "key": "workPartTimePerHour",
    "label": "アルバイト時給",
    "labelCht": "每小時工作兼職",
    "labelEng": "Work part-time per hour",
    "level": 2,
    "orderId": 14,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "Ziwe92QMk9cDcaE7m",
    "isEnable": true,
    "key": "option",
    "label": "その他",
    "level": 3,
    "orderId": 76,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "-1"
  },
  {
    "_id": "ZrDjuPe8fiRohQMut",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "G",
    "labelCht": "G",
    "labelEng": "G",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "G"
  },
  {
    "_id": "Zu3hdC43F868StLNr",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "支店長",
    "labelCht": "分公司經理",
    "labelEng": "Branch manager",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "EMx476y5qT97xamPW",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "a2AqQAdsDjnDFqCba",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "鈴鹿大学",
    "labelCht": "鈴鹿大学",
    "level": 2,
    "orderId": 51,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "51"
  },
  {
    "_id": "a6EaJWDQLX8F9Etd9",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "THANH HOA",
    "labelCht": "THANH HOA",
    "labelEng": "THANH HOA",
    "level": 3,
    "orderId": 70,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "30"
  },
  {
    "_id": "a9qFtskD23MhTG4yG",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "同窓と仲良し",
    "labelCht": "クラスメート",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "mCSyjN9ezwmtQ8Ydr",
    "type": "number",
    "value": "9"
  },
  {
    "_id": "aCEemwAACnqs9zptc",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "高い（自信あり）",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "xaH5ScLzTa9s6dSLk",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "aEn8F6fyvHihDh6JM",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "中長期在留者受入開始",
    "level": 2,
    "parentFolderId": "ADDNW8aagYtw3QEdr",
    "type": "string"
  },
  {
    "_id": "aFahu48CH24EjceE9",
    "isEnable": true,
    "key": "option",
    "label": "6",
    "labelCht": "6",
    "labelEng": "6",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "aK7xqKrbzWxgjtcLe",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "喫煙者、飲酒者",
    "labelCht": "吸煙者，飲酒者",
    "labelEng": "Smoker, drinker",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "Dan7bTZgSgkSewy7x",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "aKbqaGhszNYePv5PN",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "その他",
    "labelCht": "其他",
    "labelEng": "Other",
    "level": 3,
    "orderId": 20,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "11"
  },
  {
    "_id": "actFCjDexwbdhJAFL",
    "childNum": 5,
    "isEnable": true,
    "key": "relativeInJapan",
    "label": "在日親友",
    "labelCht": "在日親友",
    "labelEng": "Relative in Japan",
    "level": 2,
    "orderId": 35,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "aeVfmJdSFVXgadjZy0CZ",
    "isEnable": true,
    "key": "plannedWayOut",
    "label": "進路希望",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "agG8wQYvXKoZQ2Bwi",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "祖父",
    "labelCht": "祖父",
    "labelEng": "grandfather",
    "level": 3,
    "orderId": 5,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "ahBuEcPsrZ3oeZGTT",
    "isEnable": true,
    "key": "option",
    "label": "技能実習2号ロ",
    "level": 3,
    "orderId": 14,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "28"
  },
  {
    "_id": "ajXfFF96LYbLo55ec",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "不許可",
    "labelCht": "不許可",
    "labelEng": "not license",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "ez7K2htashvgpJ6bk",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "av9tKtrKfAwqhoE5N",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "愛知工科大学",
    "labelCht": "愛知工科大学",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "avuX43gwTgG9Ya3pF",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "重慶市",
    "labelCht": "重慶市",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "13"
  },
  {
    "_id": "axKuJYDrjcXPgxDED",
    "isEnable": true,
    "key": "option",
    "label": "30",
    "labelCht": "30",
    "labelEng": "30",
    "level": 2,
    "orderId": 29,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "30"
  },
  {
    "_id": "aysnJidTEiDDg5xY4",
    "isEnable": true,
    "key": "option",
    "label": "上級",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "HG46JunDucyZHDqRa",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "azCdZAQhxfwi7rfbj",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "中長期在留者受終了",
    "labelCht": "中長期在留者受終了",
    "level": 2,
    "parentFolderId": "ADDNW8aagYtw3QEdr",
    "type": "string"
  },
  {
    "_id": "b8kCqpZyjsQLcZBos",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "台湾",
    "labelCht": "台灣",
    "labelEng": "tw",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "boQWTSYGfYnWMf5sK",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "b8uhFQQ8zSy9TNx8T",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "造形学研究科",
    "labelCht": "造形学研究科",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "i4PzFrvC2mf9Yuich",
    "type": "string",
    "value": "9"
  },
  {
    "_id": "bEGYeedJfqxiZNWHy",
    "isEnable": true,
    "key": "option",
    "label": "21",
    "labelCht": "21",
    "labelEng": "21",
    "level": 2,
    "orderId": 20,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "21"
  },
  {
    "_id": "bSgnZAK3KctR6mThM",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "東北地区",
    "labelCht": "東北地区",
    "labelEng": "North-east area",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "bTdSkT5mxaHwjQw7i",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "0回",
    "labelCht": "0回",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "gHwK4vnYExL5WCDzw",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "bWn7WubaKxdXHb4X3",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "除籍学生(単選)",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "e6PTu4uCLHcD5qe9i",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "bX4iKpfafG68FfcC2",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "文字語彙",
    "labelCht": "文字語彙",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "L4mvofGTyCascbtaP",
    "type": "string",
    "value": "vocabularyScore"
  },
  {
    "_id": "bZDPThmGcSmzbFrb5",
    "isEnable": true,
    "key": "option",
    "label": "留学",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "11"
  },
  {
    "_id": "bZv9NfF5E7u5sb74X",
    "childNum": 3,
    "isEnable": true,
    "key": "workExperience",
    "label": "仕事経歴",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "SgDgrMYXKfNcWFZFe",
    "type": "folder"
  },
  {
    "_id": "behwP2t4SHnp56Chz",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "特技か有リ",
    "labelCht": "有不錯的特殊技能",
    "labelEng": "Have good special skills",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "Dan7bTZgSgkSewy7x",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "bjHsMHpmq8be4FSKr",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "短期遊学",
    "labelCht": "日本語科 短期遊學",
    "level": 2,
    "note1": "0",
    "orderId": 2,
    "parentFolderId": "Rb6acfqvPPJ2huBbe",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "bjpnKBX8HiAQ3KNxv",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "夏期特別休暇",
    "labelCht": "夏期特別休暇",
    "level": 1,
    "note1": "student",
    "note2": "#ebd781",
    "orderId": 1,
    "parentFolderId": "mgfbBDamqaqvzeJ4z",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "bnNv27AuspXXoRsPM",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "記述",
    "labelCht": "說明",
    "labelEng": "Description total",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "tcbMYc3xbaP3isZKN",
    "type": "string",
    "value": "ejuWritingScore"
  },
  {
    "_id": "boQWTSYGfYnWMf5sK",
    "childNum": 10,
    "isEnable": true,
    "key": "nationality",
    "label": "国籍",
    "labelCht": "國籍",
    "labelEng": "nationality",
    "level": 1,
    "orderId": 0,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "bq7EKus6fcAvD9cwD",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "非常勤",
    "labelCht": "兼職",
    "labelEng": "Part time",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "9WmWkGgKFqeuz3kux",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "btcN7dgBuXWchTHfg",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "北京市",
    "labelCht": "北京市",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "bveaYtMFPRmM38Eth",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "漢字レッスン 努力賞",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "ehmkyM9wefvYZZccs",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "c3xZxfuqm2jC64XKY",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "K",
    "labelCht": "K",
    "labelEng": "K",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "K"
  },
  {
    "_id": "c4hXLcwkxXjJK7LApZWw",
    "isEnable": true,
    "label": "親族",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "TX3dNmIr4dMMrnBwh3CL",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "cAiiHovJ2CDp7Nmh5",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "黒竜江省",
    "labelCht": "黑龍江省",
    "level": 3,
    "orderId": 5,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "9"
  },
  {
    "_id": "cEbU0gMSTTrXbCRT069r",
    "isEnable": true,
    "label": "不合格",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "CvZ87j8peKegsUwlg8AA",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "cRRARFxEgf6kk4ZsL",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "5月",
    "labelCht": "五月",
    "labelEng": "May",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "nfpgEp3kq2YEoZJqs",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "cRwZFrWjgvYT3wqy8",
    "isEnable": true,
    "key": "option",
    "label": "DONG NAI",
    "labelCht": "DONG NAI",
    "labelEng": "DONG NAI",
    "level": 3,
    "orderId": 44,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "58"
  },
  {
    "_id": "cZkRPWYDjetiHXTB6",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "2018",
    "labelCht": "2018",
    "labelEng": "2018",
    "level": 2,
    "note1": "2",
    "orderId": 3,
    "parentFolderId": "rLDtawHZT5AWhySev",
    "type": "string",
    "value": "2018"
  },
  {
    "_id": "cbTfum7bjeYBbRxJX25N",
    "isEnable": true,
    "label": "2021",
    "level": 2,
    "note1": "1",
    "orderId": 0,
    "parentFolderId": "rLDtawHZT5AWhySev",
    "type": "string",
    "value": "2021"
  },
  {
    "_id": "ccWg7uyMNByEvHY76",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "高度専門職１号ハ",
    "labelEng": "Humanities knowledge, international business",
    "level": 3,
    "orderId": 10,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "cfy7axtivyTDak35v",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "4月",
    "labelCht": "四月",
    "labelEng": "April",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "nfpgEp3kq2YEoZJqs",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "ckDELkrtdHcK7HB5H",
    "childNum": 4,
    "isEnable": true,
    "key": "isPass",
    "label": "合格/不合格",
    "labelCht": "合格/不合格",
    "labelEng": "Pass/fail",
    "level": 1,
    "orderId": 8,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "coYcbqfSnMtZRcstv",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "24",
    "labelCht": "24",
    "level": 2,
    "orderId": 24,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "24"
  },
  {
    "_id": "cqygzTh26W5g2zQuA",
    "childNum": 15,
    "isEnable": true,
    "key": "studentStatus2",
    "label": "学生状態2",
    "labelCht": "學生情況2",
    "labelEng": "Student situation",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "niMW6dizwqnorXBhQ",
    "type": "folder"
  },
  {
    "_id": "crAmrDqTyeA6wQr2s",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "5回",
    "labelCht": "5回",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "gHwK4vnYExL5WCDzw",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "crtzmCKvJvwjNqozQ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "特にない",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "HpYxwK3JHgSZwcZMR",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "cu59AyfN2w9x468bgnKe",
    "isEnable": true,
    "label": "否",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "kxYZfrN0om5qcfmq6DSP",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "cxpsyi4PEyoy5ChzA",
    "childNum": 8,
    "isEnable": true,
    "key": "finalEducation",
    "label": "最終学歷",
    "labelCht": "最終学歷",
    "labelEng": "Final education",
    "level": 2,
    "orderId": 33,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "d0SA0JVEAB82Sdq7Ud5a",
    "isEnable": true,
    "label": "新疆",
    "level": 3,
    "orderId": 33,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "77"
  },
  {
    "_id": "d3Qk9DCDy8vDWfYzL",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "留学センター",
    "labelCht": "出國留學中心",
    "labelEng": "Study Abroad Center",
    "level": 2,
    "orderId": 14,
    "parentFolderId": "pvgeMtS2sGZpp5568",
    "type": "number",
    "value": "15"
  },
  {
    "_id": "d7qtTJgdPaeMk5BKm",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "多次短期滞在者",
    "labelCht": "多次短期停留者",
    "labelEng": "Multiple short stayers",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "msaKtv3iaFn6EBiQv",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "d8w2oN7DmFz84fvnE",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "2017",
    "labelCht": "2017",
    "labelEng": "2017",
    "level": 2,
    "note1": "1",
    "orderId": 4,
    "parentFolderId": "rLDtawHZT5AWhySev",
    "type": "string",
    "value": "2017"
  },
  {
    "_id": "dBeRoqpFBaFqMMGGQ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "学生",
    "labelCht": "學生",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "dvFQoxKGx6GQuHz7K",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "dDKkutfJES2iPd586",
    "childNum": 4,
    "isEnable": true,
    "key": "enterPurpose",
    "label": "本校に入学の誌望動機",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "SgDgrMYXKfNcWFZFe",
    "type": "folder"
  },
  {
    "_id": "dHQyXidwYApd2r73F",
    "childNum": 3,
    "isEnable": true,
    "key": "jpnAbility",
    "label": "日本語能力証明",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "SgDgrMYXKfNcWFZFe",
    "type": "folder"
  },
  {
    "_id": "dK4FKxBTZjyAQzG5v",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "名古屋商科大学",
    "labelCht": "名古屋商科大学",
    "level": 2,
    "orderId": 29,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "29"
  },
  {
    "_id": "dNdTrpwYafkZoHn3e",
    "childNum": 4,
    "isEnable": true,
    "key": "health",
    "label": "健康",
    "labelCht": "健康",
    "labelEng": "health",
    "level": 2,
    "orderId": 21,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "dTLuHWfuuz32z2r2a",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "主任",
    "labelCht": "主任",
    "labelEng": "director",
    "level": 2,
    "orderId": 13,
    "parentFolderId": "EMx476y5qT97xamPW",
    "type": "number",
    "value": "13"
  },
  {
    "_id": "dVJOzCzHHWKZ6UaRisoM",
    "isEnable": true,
    "label": "その他",
    "level": 3,
    "orderId": 4,
    "parentFolderId": "63CjBXyx8KaZiOxMjWXu",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "dWDcptmLf962XkWrZ",
    "childNum": 10,
    "isEnable": true,
    "key": "badInClassroom",
    "label": "上課 惡",
    "level": 1,
    "orderId": 9,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder"
  },
  {
    "_id": "daoHGsxtbj2CabS44",
    "isEnable": true,
    "key": "option",
    "label": "養母",
    "labelCht": "養母",
    "labelEng": "Adoptive mother",
    "level": 3,
    "orderId": 8,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "19"
  },
  {
    "_id": "dbxeXdbv7zhtoWTQT",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "電話聯繫",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "tg7exip2uGhi9KJQJ",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "dmTQGE8zC7QPRA3GJ",
    "isEnable": true,
    "key": "option",
    "label": "MANILA",
    "level": 3,
    "orderId": 15,
    "parentFolderId": "WoNy9iaGLSFuWgBMd",
    "type": "number",
    "value": "16"
  },
  {
    "_id": "dsWGNdNvjEbfFRFDd",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "M",
    "labelCht": "M",
    "labelEng": "M",
    "level": 2,
    "orderId": 12,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "M"
  },
  {
    "_id": "dtMrrBAkhNEu7RYkv",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "名古屋市立大学(公立)",
    "labelCht": "名古屋市立大学(公立)",
    "level": 2,
    "orderId": 30,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "30"
  },
  {
    "_id": "dvFQoxKGx6GQuHz7K",
    "childNum": 3,
    "isEnable": true,
    "key": "role",
    "label": "対象",
    "labelCht": "對象",
    "level": 1,
    "orderId": 16,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "dxes6DyTEMKgDoaqX",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "1.5",
    "labelCht": "1.5",
    "labelEng": "1.5",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "WoPJf6A9dJT2cp3hr",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "e2m4Dwq3iDsobYcgH",
    "isEnable": true,
    "key": "option",
    "label": "B",
    "labelCht": "B",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "Qsiqo48JuGJtWQ5QH",
    "type": "string",
    "value": "B"
  },
  {
    "_id": "e2mykSsn3Dy6Tzv3g",
    "childNum": 2,
    "isEnable": true,
    "key": "dropoutSel",
    "label": "自主退学/除籍処分",
    "level": 1,
    "orderId": 5,
    "parentFolderId": "jPvXpapnFPui4AGKY",
    "type": "folder"
  },
  {
    "_id": "e3hxHB4ehLyvmrC4jym2",
    "isEnable": true,
    "label": "理科系統",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "O8hDoECoSQXwsr5d44nN",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "e4fpbdb6hTZhJAJb3",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "至学館大学",
    "labelCht": "至学館大学",
    "level": 2,
    "orderId": 49,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "49"
  },
  {
    "_id": "e6PTu4uCLHcD5qe9i",
    "childNum": 3,
    "isEnable": true,
    "key": "pdfObject",
    "label": "列印対象",
    "level": 1,
    "orderId": 19,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "e9NyKnpkStDpsMacQ",
    "childNum": 5,
    "isEnable": true,
    "key": "teacherTitle",
    "label": "職名",
    "labelCht": "職稱",
    "labelEng": "job title",
    "level": 1,
    "orderId": 2,
    "parentFolderId": "rWHGNFjFi5TmTgFhA",
    "type": "folder"
  },
  {
    "_id": "eDtPDTZpGPo5esZaQ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "本人負担",
    "labelCht": "主要負擔",
    "labelEng": "Main burden",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "jSuvxuEeKGYReZogc",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "eHxjELrAEgJsKfgQq",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "28",
    "labelCht": "28",
    "level": 2,
    "orderId": 28,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "28"
  },
  {
    "_id": "ePeEBjXukhCwXyDbY",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "空欄（態度の評価）",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "WTCMNPWByB7EbH3uK",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "ePunbPZ449ZQReSMk",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "大学",
    "labelCht": "大学",
    "labelEng": "University",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "qLp95bMxAuc5sY8A4",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "eSjRMAacsWGcPKFEp",
    "isEnable": true,
    "key": "option",
    "label": "卒業見込",
    "labelCht": "卒業見込",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "vXMFsq82XWcqvraGd",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "eh57NcYqhBbSafatg",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "養父",
    "labelCht": "養父",
    "labelEng": "Adoptive father",
    "level": 3,
    "orderId": 7,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "ehmkyM9wefvYZZccs",
    "childNum": 6,
    "isEnable": true,
    "key": "gradPrice",
    "label": "卒業賞",
    "level": 1,
    "orderId": 20,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "eqfJyKDJk4yCd6hfL",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "空欄（連絡先",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "gGaXSHkzsM3bm4cWu",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "es9vKAKSTLhynbN9o",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "遅刻早退せず",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "mCSyjN9ezwmtQ8Ydr",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "etzgYZdAt6Kv3ekEk",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "[数学 Ⅱ ]",
    "labelCht": "[数学 Ⅱ ]",
    "labelEng": "[Mathematics II]",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "tcbMYc3xbaP3isZKN",
    "type": "string",
    "value": "mathScore2"
  },
  {
    "_id": "euB8ah2LzV3VzGdb6eU4",
    "isEnable": true,
    "label": "専門学校",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "aeVfmJdSFVXgadjZy0CZ",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "euIjoJCGln59x2aUB5AM",
    "isEnable": true,
    "label": "不申請",
    "level": 3,
    "orderId": 4,
    "parentFolderId": "BOMdl2bkLqQmAwXLaRVr",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "ez7K2htashvgpJ6bk",
    "childNum": 3,
    "isEnable": true,
    "key": "result",
    "label": "許可/不許可",
    "labelCht": "結果",
    "labelEng": "result",
    "level": 1,
    "orderId": 9,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "fFNRYBRqDm7SHcriN",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "在籍",
    "labelCht": "5、來日予定、入學手續、考試、編班",
    "level": 2,
    "note1": "30",
    "note2": "indexStudent,master,affairStudent,testStudent,examStudent,graduateStudent,commentOnTeacher,admissionStatus,inquiryStudent,jpn22,table13,table39,documentStudent",
    "orderId": 7,
    "parentFolderId": "cqygzTh26W5g2zQuA",
    "type": "string",
    "value": "30"
  },
  {
    "_id": "fRfM6hZeqbcS7oLLf",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "帰国",
    "labelCht": "帰国",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "BEcYusFEnsFbRxFgi",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "fSkZeBw6CfF8k5vL8",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "？？大学(国立)",
    "labelCht": "？？大学(国立)",
    "level": 2,
    "orderId": 47,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "47"
  },
  {
    "_id": "fTHUM3yAl5BL4CtUegyk",
    "isEnable": true,
    "key": "enquiryWay",
    "label": "問合せ方法",
    "level": 2,
    "orderId": 37,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "fTP7YAKs5ZnTLcLj3",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "卒業",
    "labelCht": "卒業",
    "level": 2,
    "note2": "indexStudent,master,affairStudent,testStudent,examStudent,graduateStudent,commentOnCourse,instructStudent,admissionStatus",
    "orderId": 2,
    "parentFolderId": "HaSH5PobxGEvgzMgA",
    "type": "string",
    "value": "50"
  },
  {
    "_id": "fV9SIMGsY8taURXki6kz",
    "isEnable": true,
    "label": "取り下げ",
    "level": 3,
    "orderId": 4,
    "parentFolderId": "VQcmpvi8a979GpmMA5JC",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "fXsCeqzG6bC8c6nuj",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "大学院",
    "labelCht": "研究生院",
    "labelEng": "graduate school",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "BkaebxeYePdnJ36Fu",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "fdYobkcQCYGxpxHFi",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "結婚",
    "labelCht": "結婚",
    "labelEng": "get married",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "6FQ5JWq38TJytcnT4",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "fdhVmenLcVQRJZGVmMjl",
    "isEnable": true,
    "label": "不交付",
    "level": 3,
    "orderId": 6,
    "parentFolderId": "VQcmpvi8a979GpmMA5JC",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "fp8MDGGFiNnqDLyda",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "華南地区",
    "labelCht": "華南地区",
    "labelEng": "South China",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "fpttHYq7DxorHqwhL",
    "childNum": 12,
    "isEnable": true,
    "key": "regionalDistinction",
    "label": "地区区分",
    "labelCht": "地区区分",
    "labelEng": "Regional distinction",
    "level": 2,
    "orderId": 31,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "frYExJTAv8M8A7FPM",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "N4",
    "labelCht": "4級",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "JGnTdm4MGmAynLHmh",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "fvbpHvjuwwhdu39Wa",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "研究生",
    "labelCht": "研究生",
    "labelEng": "Postgraduate",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "2E6Te5puNo9zFyYqa",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "fywwaC2TTYD5Haj9M",
    "isEnable": true,
    "key": "option",
    "label": "24",
    "labelCht": "24",
    "labelEng": "24",
    "level": 2,
    "orderId": 23,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "24"
  },
  {
    "_id": "g2EWYARWXmnBYcPHY",
    "childNum": 4,
    "isEnable": true,
    "key": "schoolCertificate",
    "label": "学歴認証書の有無確認",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "SgDgrMYXKfNcWFZFe",
    "type": "folder"
  },
  {
    "_id": "g4s6i9gHj3i5hNQXF",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "無",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "ckDELkrtdHcK7HB5H",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "gAEqW65LzbEQHtrNG",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "PHU THO",
    "labelCht": "PHU THO",
    "labelEng": "PHU THO",
    "level": 3,
    "orderId": 61,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "24"
  },
  {
    "_id": "gB9WAR2e42EJdEzzw",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "おば",
    "labelCht": "姑媽",
    "labelEng": "aunt",
    "level": 3,
    "orderId": 16,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "17"
  },
  {
    "_id": "gDpdeRLjdunfHnCuX",
    "isEnable": true,
    "key": "option",
    "label": "KASKI",
    "labelCht": "KASKI",
    "labelEng": "KASKI",
    "level": 3,
    "orderId": 51,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "44"
  },
  {
    "_id": "gFQwZn9PWmDZNR5ug",
    "childNum": 4,
    "isEnable": true,
    "key": "adStatus",
    "label": "広告状態",
    "labelCht": "廣告狀態",
    "level": 1,
    "orderId": 18,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "gGBaum28jBmtbS9dR",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "名古屋産業大学",
    "labelCht": "名古屋產業大学",
    "level": 2,
    "orderId": 28,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "28"
  },
  {
    "_id": "gGaXSHkzsM3bm4cWu",
    "childNum": 4,
    "isEnable": true,
    "key": "recommendInterview",
    "label": "推薦保証者",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "SgDgrMYXKfNcWFZFe",
    "type": "folder"
  },
  {
    "_id": "gGd3kCArBKxpCS7fF",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "6",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "RjT2jTY5CpxZEZpTG",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "gHwK4vnYExL5WCDzw",
    "childNum": 6,
    "isEnable": true,
    "key": "preEntryImmigrationHistory",
    "label": "入学前の出入国歷",
    "labelCht": "入境前移民歷史",
    "labelEng": "Pre-entry immigration history",
    "level": 1,
    "orderId": 0,
    "parentFolderId": "mgfbBDamqaqvzeJ4z",
    "type": "folder"
  },
  {
    "_id": "gJv4P2QGZoS8EhffA",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "無",
    "labelCht": "無",
    "labelEng": "no",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "EMx476y5qT97xamPW",
    "type": "number",
    "value": "0"
  },
  {
    "_id": "gLV9E39iChjEkZOTaDKr",
    "isEnable": true,
    "label": "留学センター(個人)",
    "level": 3,
    "orderId": 18,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "18"
  },
  {
    "_id": "gMLlsagyDt9qpOz2RY0B",
    "isEnable": true,
    "label": "在外知人",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "qrfPtSIWzvPkinLlAjJ3",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "gNEKAWbJx4KPTSNZE",
    "isEnable": true,
    "key": "option",
    "label": "永住者",
    "labelCht": "永住者",
    "level": 3,
    "orderId": 7,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "25"
  },
  {
    "_id": "gRsp5ystEF3eGQGWC",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "華北地区",
    "labelCht": "華北地区",
    "labelEng": "North China",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "gSZTC2BjukX9hxGJm",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "土·日·祝",
    "labelCht": "土·日·祝",
    "level": 2,
    "note1": "student,staff",
    "note2": "#f67e6f",
    "orderId": 0,
    "parentFolderId": "tvA288k3wppxGPQyx",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "gT64SX1KHktrPLqoXrmx",
    "isEnable": true,
    "label": "その他",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "oYRlH6kk1Mkvmz1BCuj8",
    "type": "string"
  },
  {
    "_id": "gWAr99sNmS4pzp4eq",
    "isEnable": true,
    "key": "option",
    "label": "無",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "gX7xNcWum4nWjibpQ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "愛情",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "qXE4LZeyWvrDrQz6r",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "gXWftsRK8PNwdYTCh",
    "childNum": 4,
    "isEnable": true,
    "key": "reasonEndAcceptance",
    "label": "受入終了理由",
    "labelEng": "reason of end of acceptance",
    "level": 2,
    "orderId": 24,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "gbAe2JDq8asXtvynt",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "同朋大学",
    "labelCht": "同朋大学",
    "level": 2,
    "orderId": 16,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "16"
  },
  {
    "_id": "gdWv4JrJ6Em4HTvGi",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "愛知工業大学",
    "labelCht": "愛知工業大学",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "gsfTywRZLv4NAsWNN",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "200万-250万日円",
    "labelCht": "200万-250万日円",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "u44m8ojJMZDkEvhRw",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "gt3SCaikqeyTh86n5",
    "childNum": 9,
    "isEnable": true,
    "key": "bicycleColor",
    "label": "自転車色",
    "labelCht": "腳踏車顏色",
    "labelEng": "Bicycle color",
    "level": 1,
    "orderId": 13,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "gu5qMZIbFxQiq1xLrZl6",
    "isEnable": true,
    "label": "問合せ中",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "BOMdl2bkLqQmAwXLaRVr",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "gyLvs2PhM44hzgHWw",
    "isEnable": true,
    "key": "option",
    "label": "海南省",
    "labelCht": "海南省",
    "labelEng": "海南省",
    "level": 3,
    "orderId": 22,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "48"
  },
  {
    "_id": "gyyFBx9L8NHTdDoQg",
    "childNum": 3,
    "isEnable": true,
    "key": "partTimeWork",
    "label": "アルバイト業種",
    "labelCht": "兼職工作業",
    "labelEng": "Part-time work",
    "level": 2,
    "orderId": 12,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "h3B93ApHHn3nS9Pj8",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "会社役員",
    "labelCht": "公司的官員",
    "labelEng": "Company official",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "pvgeMtS2sGZpp5568",
    "type": "number",
    "value": "8"
  },
  {
    "_id": "h4nK9WB2DOOYat3DBf9F",
    "isEnable": true,
    "label": "大学院",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "aeVfmJdSFVXgadjZy0CZ",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "h6utTTxcYmpGY9prp",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "夫",
    "labelCht": "丈夫",
    "labelEng": "husband",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "12"
  },
  {
    "_id": "h8GvA4dgJGj7SLC4n",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "3月",
    "labelCht": "三月",
    "labelEng": "March",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "nfpgEp3kq2YEoZJqs",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "hCDYPHikryYgTRntc",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "募集学生(単選)",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "pem5s29SZy8SBG379",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "hLL6bz2nuyNLsbEZj",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "3回",
    "labelCht": "3回",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "gHwK4vnYExL5WCDzw",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "hMKhHdaWzeGB7t4Ga",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "携帯不当使用",
    "labelCht": "携帯電話の使用が多い",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "dWDcptmLf962XkWrZ",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "hQDvCNoEeqrKRfczu",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "専門学校",
    "labelCht": "専門学校",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "cxpsyi4PEyoy5ChzA",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "hStC9cXotHyKmp2wk",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "兄弟姉妹",
    "labelCht": "兄弟姉妹",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "tBuxP8MZRxbcnMf5s",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "hWxGM6S8KhaCPvkKM",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "祖父",
    "labelCht": "祖父",
    "level": 2,
    "orderId": 12,
    "parentFolderId": "tBuxP8MZRxbcnMf5s",
    "type": "number",
    "value": "13"
  },
  {
    "_id": "hX7StjN1kVj7ZKLzHMHL",
    "isEnable": true,
    "label": "病気",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "u3HP2LbW6EEifBydZx0V",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "hakXqTucJfR5vaXHv",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "10",
    "labelCht": "10",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "10"
  },
  {
    "_id": "hb7RJZiJhwSmpL7c3",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "日本語科 二年進学",
    "labelCht": "日本語科 兩年進学",
    "level": 2,
    "note1": "2",
    "orderId": 0,
    "parentFolderId": "Rb6acfqvPPJ2huBbe",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "hibWhNmpukzNfR4fw",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "17",
    "labelCht": "17",
    "level": 2,
    "orderId": 16,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "17"
  },
  {
    "_id": "hjYJXa4hnnWGLfYEk",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "教育",
    "labelEng": "Japanese spouse, etc.",
    "level": 3,
    "orderId": 21,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "8"
  },
  {
    "_id": "hmoVUb25dir29d44D2Ju",
    "isEnable": true,
    "label": "Eメール",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "fTHUM3yAl5BL4CtUegyk",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "hotizeDwDP4dhWGFc",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "部長",
    "labelCht": "部長",
    "labelEng": "Manager",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "EMx476y5qT97xamPW",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "hxgHwsNccsXFJ6frJ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "2",
    "labelCht": "2",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "hz8TZ3Np954aG7J2g",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "外国からの送金",
    "labelCht": "來自外國的匯款",
    "labelEng": "Remittance from a foreign country",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "jSuvxuEeKGYReZogc",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "hznZzGHydAbX5z9Bg",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "HAI  DUONG",
    "labelCht": "HAI  DUONG",
    "labelEng": "HAI  DUONG",
    "level": 3,
    "orderId": 45,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "19"
  },
  {
    "_id": "i4JdxJjSnK2xd8CFD",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "ある",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "dHQyXidwYApd2r73F",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "i4PzFrvC2mf9Yuich",
    "childNum": 15,
    "isEnable": true,
    "key": "academicSpecialization",
    "label": "専攻",
    "labelCht": "專攻",
    "labelEng": "Academic specialization",
    "level": 2,
    "orderId": 32,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "i9sap9enXYCbNih5S",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "空欄（理由）",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "dDKkutfJES2iPd586",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "iA7Qv9owYKcJbXqAX",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "吉林省",
    "labelCht": "吉林省",
    "level": 3,
    "orderId": 6,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "iF8noku8whnFNbRD6",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "特別休暇",
    "labelCht": "特別休暇",
    "level": 2,
    "note1": "student,staff",
    "note2": "#f67e6f",
    "orderId": 3,
    "parentFolderId": "tvA288k3wppxGPQyx",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "iJZy8bh98YLrtqjSIn9z",
    "isEnable": true,
    "label": "囑託募集先",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "qrfPtSIWzvPkinLlAjJ3",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "iSprXXDNQ6T8vo2RG",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "生活",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "qXE4LZeyWvrDrQz6r",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "iYHooGJcofAjuAkGW",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "親戚に勧められた",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "dDKkutfJES2iPd586",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "iYT36m2pvBQfmXZ7H",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "受付問合せ",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "zcF6vqWsqmqhvddxr",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "ic8FbzoPSp6sLsEe5",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "在学",
    "labelCht": "在学",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "HaSH5PobxGEvgzMgA",
    "type": "string",
    "value": "30"
  },
  {
    "_id": "icSp6ZCncvJni3aGr",
    "isEnable": true,
    "key": "option",
    "label": "技能実習1号ロ",
    "level": 3,
    "orderId": 12,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "26"
  },
  {
    "_id": "icxMjyTEQ2mujS3hH",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "課長",
    "labelCht": "部門經理",
    "labelEng": "Department manager",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "EMx476y5qT97xamPW",
    "type": "number",
    "value": "10"
  },
  {
    "_id": "iedRRyzt5BtWpiLch",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "2月",
    "labelCht": "二月",
    "labelEng": "February",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "nfpgEp3kq2YEoZJqs",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "ikjiA6YpusjuRuwcs",
    "childNum": 18,
    "isEnable": true,
    "key": "relationship",
    "label": "学生との関係",
    "labelCht": "與學生的關係",
    "labelEng": "relationship",
    "level": 2,
    "orderId": 16,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "ioHx4n3Cg47RkoLT5",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "全部",
    "labelCht": "全部",
    "level": 2,
    "note1": "50",
    "note2": "indexStudent,checkSheetStudent,interviewStudent,applicationStudent,recruitment,master,affairStudent,testStudent,examStudent,graduateStudent,commentOnTeacher,admissionStatus,inquiryStudent,",
    "orderId": 14,
    "parentFolderId": "cqygzTh26W5g2zQuA",
    "type": "string",
    "value": "99"
  },
  {
    "_id": "iovPaxwnq8S4cmeBj",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "赴国",
    "labelCht": "赴國",
    "labelEng": "Back to the country",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "6FQ5JWq38TJytcnT4",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "ixGmSN5mCAuHPeTjS",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "院生",
    "labelCht": "院生",
    "labelEng": "Graduate student",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "2E6Te5puNo9zFyYqa",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "ixcwtzS7xTRZ7PwKb",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "N5～N4",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "S2xGCLTCAQDsQykoh",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "ixhKjcE5ZfsrJYZ9g",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "卒業生",
    "labelCht": "畢業生",
    "labelEng": "graduate",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "uEnJwyNSwmKZRNtLj",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "j2tgsefX5dYEkEL5S",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "14",
    "labelCht": "14",
    "labelEng": "14",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "7vANGTAAD9sqEfnSs",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "j4FbJnqTQwHQwcNzJ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "無",
    "labelCht": "無",
    "labelEng": "no",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "actFCjDexwbdhJAFL",
    "type": "number",
    "value": "0"
  },
  {
    "_id": "j75ZL7gE8iSbrhPxG",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "HA NAM",
    "labelCht": "HA NAM",
    "labelEng": "HA NAM",
    "level": 3,
    "orderId": 46,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "27"
  },
  {
    "_id": "j8hZB95Az4jZA2PTEovg",
    "isEnable": true,
    "label": "上級",
    "level": 3,
    "orderId": 4,
    "parentFolderId": "OVFWdKTKF2nBabxpgt1D",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "jBa4WfWzEvoYaqsHR",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "升学",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "qXE4LZeyWvrDrQz6r",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "jCfcqbABjzGrWbtud",
    "isEnable": true,
    "key": "option",
    "label": "MYAGDI",
    "labelCht": "MYAGDI",
    "labelEng": "MYAGDI",
    "level": 3,
    "orderId": 56,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "69"
  },
  {
    "_id": "jHu3yGv9viPm25Dhi",
    "isEnable": true,
    "key": "option",
    "label": "修了",
    "labelCht": "修了",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "vXMFsq82XWcqvraGd",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "jPvXpapnFPui4AGKY",
    "childNum": 0,
    "isEnable": true,
    "key": "student",
    "label": "学生",
    "labelCht": "學生",
    "labelEng": "student",
    "level": 0,
    "orderId": 0,
    "parentFolderId": "",
    "type": "folder",
    "value": ""
  },
  {
    "_id": "jSuvxuEeKGYReZogc",
    "childNum": 5,
    "isEnable": true,
    "key": "lodgingCost",
    "label": "住宿費用",
    "labelCht": "住宿費用",
    "labelEng": "Lodging cost",
    "level": 1,
    "orderId": 3,
    "parentFolderId": "mgfbBDamqaqvzeJ4z",
    "type": "folder"
  },
  {
    "_id": "jWGKt72hB4hEoNC9B",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "四川省",
    "labelCht": "四川省",
    "level": 3,
    "orderId": 19,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "jaPwfkSbA6TfvE9TM",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "5",
    "labelCht": "5",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "jdYPeE8yC6dzgmb3X",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "聴講生",
    "labelCht": "聽講生",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "xGWLD9hAL6LAbieMw",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "jqcBHQi4YLXde8iGo",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "CG",
    "labelCht": "CG",
    "level": 2,
    "orderId": 13,
    "parentFolderId": "i4PzFrvC2mf9Yuich",
    "type": "string",
    "value": "14"
  },
  {
    "_id": "jrkaQpRBKLJRPHEMf",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "I",
    "labelCht": "I",
    "labelEng": "I",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "I"
  },
  {
    "_id": "jti8PN7otFuwWhFNf",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "[読解(日本語)]",
    "labelCht": "[閱讀（日語）]",
    "labelEng": "[Introduction (Japanese)]",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "tcbMYc3xbaP3isZKN",
    "type": "string",
    "value": "ejuReadingScore"
  },
  {
    "_id": "k34jF2xH5NifQ9M4t",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "10-20 万円",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "WzbRntLC6b4AZKv7s",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "k4GygJcLzdhhxBd3S",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "広東省",
    "labelCht": "広東省",
    "level": 3,
    "orderId": 21,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "15"
  },
  {
    "_id": "k7atDk4nCkpggZNui",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "養父",
    "labelCht": "養父",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "tBuxP8MZRxbcnMf5s",
    "type": "number",
    "value": "11"
  },
  {
    "_id": "kBbWhoYYGg8MZKmp4",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "21",
    "labelCht": "21",
    "level": 2,
    "orderId": 21,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "21"
  },
  {
    "_id": "kE7Y56vWa9LELv5Cj",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "講師",
    "labelCht": "講師",
    "labelEng": "lecturer",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "e9NyKnpkStDpsMacQ",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "kWo5L7WqHbWrqqeDL",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "2015",
    "labelCht": "2015",
    "labelEng": "2015",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "rLDtawHZT5AWhySev",
    "type": "string",
    "value": "2015"
  },
  {
    "_id": "kXCjF7fqnYqKxf84F",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "遼寧省",
    "labelCht": "遼寧省",
    "level": 3,
    "orderId": 7,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "10"
  },
  {
    "_id": "kb4hGAqZAfo7pXwwY",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "愛知教育大学(国立)",
    "labelCht": "愛知教育大学(国立)",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "kgT7a6c6WfDGhAbKq",
    "isEnable": true,
    "key": "option",
    "label": "A",
    "labelCht": "A",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "Qsiqo48JuGJtWQ5QH",
    "type": "string",
    "value": "A"
  },
  {
    "_id": "kqt54pwHnMn6WPrtT",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "名古屋学院大学",
    "labelCht": "名古屋学院大学",
    "level": 2,
    "orderId": 23,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "23"
  },
  {
    "_id": "krL6tCys2ahC2jywm",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "なし（認証できない）",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "g2EWYARWXmnBYcPHY",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "kxYZfrN0om5qcfmq6DSP",
    "isEnable": true,
    "key": "decision",
    "label": "決定",
    "level": 1,
    "orderId": 23,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "lBoV22zuS7WnKMuopzDm",
    "isEnable": true,
    "label": "初期前期",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "m4SQgWaXRxWzqyuhK",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "教育学",
    "labelCht": "教育学",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "i4PzFrvC2mf9Yuich",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "mAHkpYsMPQA2tchq4",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "退学",
    "labelCht": "退学",
    "labelEng": "dropped out",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "gXWftsRK8PNwdYTCh",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "mAf2Zr4ZJ28sR3HH7",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "在勤",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "5sRrCsobiHfaZpWWE",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "mCSyjN9ezwmtQ8Ydr",
    "childNum": 10,
    "isEnable": true,
    "key": "goodInClassroom",
    "label": "上課 良",
    "level": 1,
    "orderId": 8,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder"
  },
  {
    "_id": "mFyXMSYJs7qm4SjyR",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "老師",
    "labelCht": "老師",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "dvFQoxKGx6GQuHz7K",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "mJKHYyvKxnq2j8ChW",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "N2",
    "labelCht": "2級",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "JGnTdm4MGmAynLHmh",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "mQmsyTsDH3K7HdFiz",
    "isEnable": true,
    "key": "option",
    "label": "べトナム",
    "level": 3,
    "orderId": 13,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "string",
    "value": "15"
  },
  {
    "_id": "mSkPZLa2uDNHrL4PR",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "午後",
    "labelCht": "下午",
    "labelEng": "afternoon",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "wPJviTp2wWhboF4T7",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "mYD6THoNkt7KxsZFi",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "親族",
    "labelCht": "親族",
    "labelEng": "relatives",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "actFCjDexwbdhJAFL",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "mfmXduF0il3WIJLF0Hg6",
    "isEnable": true,
    "label": "SNS",
    "level": 3,
    "orderId": 4,
    "parentFolderId": "fTHUM3yAl5BL4CtUegyk",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "mgJA8mBrsRXJrtfSG",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "2011",
    "labelCht": "2011",
    "labelEng": "2011",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "rLDtawHZT5AWhySev",
    "type": "string",
    "value": "2011"
  },
  {
    "_id": "mgfbBDamqaqvzeJ4z",
    "childNum": 0,
    "isEnable": true,
    "key": "school",
    "label": "学校",
    "labelCht": "學校",
    "labelEng": "school",
    "level": 0,
    "orderId": 3,
    "parentFolderId": "",
    "type": "folder"
  },
  {
    "_id": "mhBERdjcgQ9cGxWYf",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "大同工業大学",
    "labelCht": "大同工業大学",
    "level": 2,
    "orderId": 52,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "52"
  },
  {
    "_id": "mjlaK9aGMenTS934Ji36",
    "isEnable": true,
    "label": "香港",
    "level": 3,
    "orderId": 10,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "79"
  },
  {
    "_id": "mmguTiq24N7P6bPRY",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "250万-300万日円",
    "labelCht": "250万-300万日円",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "u44m8ojJMZDkEvhRw",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "mnLTbFGcPKF8JQJyX",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "職涯",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "qXE4LZeyWvrDrQz6r",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "mnhK2JuETQ6by8hb4",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "休学中",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "s9fj2Hh8XqrL5WS9y",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "mqueQJxZNpzdY2Ltf",
    "isEnable": true,
    "key": "option",
    "label": "その他",
    "level": 1,
    "orderId": 1,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "string",
    "value": "-1"
  },
  {
    "_id": "msaKtv3iaFn6EBiQv",
    "childNum": 6,
    "isEnable": true,
    "key": "numberOfDays",
    "label": "x来日歷",
    "labelCht": "在日天數",
    "labelEng": "Number of days",
    "level": 2,
    "orderId": 27,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "myLLYXpFxQKawSm8z",
    "isEnable": true,
    "key": "option",
    "label": "11",
    "labelCht": "11",
    "labelEng": "11",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "11"
  },
  {
    "_id": "mztnXLbCtGroRawnv",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "エンジニア",
    "labelCht": "工程師",
    "labelEng": "engineer",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "pvgeMtS2sGZpp5568",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "n53yFn3RtufYWvBZQ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "A",
    "labelCht": "A",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "rcFvLqYSKCS5kt3Sm",
    "type": "string",
    "value": "A"
  },
  {
    "_id": "n5JA32PzthNYg895F",
    "childNum": 3,
    "isEnable": true,
    "key": "reasonForAbsence",
    "label": "欠席理由",
    "labelCht": "缺席的原因",
    "labelEng": "Reason for absence",
    "level": 1,
    "orderId": 0,
    "parentFolderId": "rWHGNFjFi5TmTgFhA",
    "type": "folder"
  },
  {
    "_id": "n6n4NuG7z9HFjgLuo",
    "isEnable": true,
    "key": "option",
    "label": "定住者",
    "labelCht": "無",
    "labelEng": "no",
    "level": 3,
    "orderId": 6,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "15"
  },
  {
    "_id": "n7fLPQkfPNw6Q4W4g",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "已畢業",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "YsYq5SSomDMhmtWY8",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "nCC2u4wTqunGTHoiJ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "N1",
    "labelCht": "1級",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "JGnTdm4MGmAynLHmh",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "nCZNXcQxevfedcdQ9",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "12月",
    "labelCht": "十二月",
    "labelEng": "December",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "nfpgEp3kq2YEoZJqs",
    "type": "number",
    "value": "12"
  },
  {
    "_id": "nFC9iGrLmjSDA5L5N",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "BUSAN",
    "labelCht": "釜山市",
    "labelEng": "Busan City",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "WoNy9iaGLSFuWgBMd",
    "type": "number",
    "value": "8"
  },
  {
    "_id": "nLQJq7RuYKLhu4qMt",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "2回",
    "labelCht": "2回",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "gHwK4vnYExL5WCDzw",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "nMo34b7xMAPxaivF4",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "自分で選んだ",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "dDKkutfJES2iPd586",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "nQ2fbyKS7MQhWrzgHR1W",
    "isEnable": true,
    "label": "WeChat",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "9K0blwdo2JOk9cInF8jd",
    "type": "string",
    "value": "WeChat"
  },
  {
    "_id": "nWQHp45PAXXAMxdry",
    "childNum": 4,
    "isEnable": true,
    "key": "payment",
    "label": "繳費状況",
    "labelCht": "繳費狀況",
    "level": 2,
    "orderId": 25,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "nWj6QrWuZwHnrfuCF",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "9",
    "labelCht": "9",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "7vANGTAAD9sqEfnSs",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "ncj7NYmnE8jXEwg9L",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "父の友人",
    "labelCht": "父の友人",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "tBuxP8MZRxbcnMf5s",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "neGWv3TC2p5KnqANh",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "遅刻が多い",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "69HlkaS4w9jOFrxQCqHL",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "nfpgEp3kq2YEoZJqs",
    "childNum": 12,
    "isEnable": true,
    "key": "month",
    "label": "月份1~12",
    "labelCht": "月份",
    "labelEng": "month",
    "level": 1,
    "orderId": 11,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "niMW6dizwqnorXBhQ",
    "childNum": 0,
    "isEnable": true,
    "key": "basicSel",
    "label": "学生系統資料",
    "level": 1,
    "orderId": 0,
    "parentFolderId": "jPvXpapnFPui4AGKY",
    "type": "folder"
  },
  {
    "_id": "njRvBn3RcnH5FXR3L",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "愛知産業大学",
    "labelCht": "愛知產業大学",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "8"
  },
  {
    "_id": "nrqzA8RkXNi54z46R",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "あり",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "bZv9NfF5E7u5sb74X",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "ntcRJohdfrcRtF7nc",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "1年",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "BmvvCvqqpB4QYe7sm",
    "type": "string",
    "value": "12"
  },
  {
    "_id": "nyG2bH2PAZJ3P4ReF",
    "isEnable": true,
    "key": "option",
    "label": "修了",
    "labelCht": "修了",
    "level": 3,
    "note2": "indexStudent,master,affairStudent,testStudent,examStudent,graduateStudent,commentOnCourse,instructStudent,admissionStatus",
    "orderId": 3,
    "parentFolderId": "HaSH5PobxGEvgzMgA",
    "type": "string",
    "value": "51"
  },
  {
    "_id": "o4FwCtZmjhxQPcKfr",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "中国研究科",
    "labelCht": "中國研究科",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "i4PzFrvC2mf9Yuich",
    "type": "string",
    "value": "10"
  },
  {
    "_id": "o7xTYXNZybstYNYMS",
    "childNum": 2,
    "isEnable": true,
    "key": "isTrue",
    "label": "是否",
    "labelCht": "是否",
    "labelEng": "is true",
    "level": 1,
    "orderId": 5,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "oBMDfwnFbWocqmC8c",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "面接予定",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "zcF6vqWsqmqhvddxr",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "oENxakNtgJHt8fLDz",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "空欄（レベル及び試験種類の記入）",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "dHQyXidwYApd2r73F",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "oEygr5pMrSoqYSebR",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "容貌",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "osrKZifMJuiyDz8n6",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "oGqf3qae6Xqsu3krX",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "29",
    "labelCht": "29",
    "level": 2,
    "orderId": 29,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "29"
  },
  {
    "_id": "oSHKWFAEx2uDvrz6n",
    "isEnable": true,
    "key": "option",
    "label": "27",
    "labelCht": "27",
    "labelEng": "27",
    "level": 2,
    "orderId": 26,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "27"
  },
  {
    "_id": "oW9owWhJLgaseda7P",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "在籍学生(単選)",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "pem5s29SZy8SBG379",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "oYJoFYumhLeT3aRRS",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "豊橋創造大学",
    "labelCht": "豐橋創造大学",
    "level": 2,
    "orderId": 19,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "19"
  },
  {
    "_id": "oYRlH6kk1Mkvmz1BCuj8",
    "isEnable": true,
    "key": "inClassAttitude",
    "label": "授業態度",
    "level": 1,
    "orderId": 18,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder"
  },
  {
    "_id": "ogn6358CAZ6ki9Fb5",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "皆勤賞",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "ehmkyM9wefvYZZccs",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "ohcIEIkg8PRnQlignlgA",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "短大",
    "labelCht": "短大",
    "labelEng": "junior college",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "6FQ5JWq38TJytcnT4",
    "type": "number",
    "value": "8"
  },
  {
    "_id": "ojQ0D2X6hSO7tXn2RXxJ",
    "defaultValue": "1",
    "isEnable": true,
    "label": "卒業",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "5IrfCNDbKJCI1pd9Ta3c",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "ojiwkD7cKsu3pp2oj",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "教職員 休日出勤",
    "labelCht": "教職員特別土曜出勤",
    "level": 1,
    "note1": "staff",
    "note2": "#8896e2",
    "orderId": 2,
    "parentFolderId": "mgfbBDamqaqvzeJ4z",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "opYjckXGQTK3RigxH",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "大学",
    "labelCht": "大学",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "cxpsyi4PEyoy5ChzA",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "osrKZifMJuiyDz8n6",
    "childNum": 4,
    "isEnable": true,
    "key": "impression",
    "label": "印象",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "SgDgrMYXKfNcWFZFe",
    "type": "folder"
  },
  {
    "_id": "otYiSv6b5NTvFmojC",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "宿題不提出",
    "labelCht": "宿題を提出しない",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "dWDcptmLf962XkWrZ",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "p729qSXFYLnHZRqGw",
    "childNum": 2,
    "isEnable": true,
    "key": "isHas",
    "label": "有無",
    "labelCht": "有無",
    "labelEng": "has or not",
    "level": 1,
    "orderId": 7,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "p844RTuEfwrUX25miRB3",
    "isEnable": true,
    "label": "初級後期",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "OVFWdKTKF2nBabxpgt1D",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "pDZ3rrDPq2Ec4jDHs",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "年間",
    "labelCht": "年間",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "3osDDHcpBmqxucRjN",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "pFoZqWt5dKgZLoYbz",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "12",
    "labelCht": "12",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "7vANGTAAD9sqEfnSs",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "pKryMC6orrS4wmCxt",
    "isEnable": true,
    "key": "option",
    "label": "特定活動（研究活動等家族）",
    "labelCht": "無",
    "labelEng": "no",
    "level": 3,
    "orderId": 26,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "19"
  },
  {
    "_id": "pQPTkpM7x5GdEKDye",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "教師",
    "labelCht": "教師",
    "labelEng": "teacher",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "pvgeMtS2sGZpp5568",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "pRfE4Zc9fv5TEddXR",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "父",
    "labelCht": "父",
    "labelEng": "father",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "pSZazpviqsdY3JKht",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "メール問合せ",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "zcF6vqWsqmqhvddxr",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "pXuL596TAnndor4uv",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "面接合格済み",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "zcF6vqWsqmqhvddxr",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "pZ7hiBNxsCqnsxRLw",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "その他",
    "labelCht": "其他",
    "labelEng": "Other",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "BEcYusFEnsFbRxFgi",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "pcLD3QzZdYNjrRMd7",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "其他",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "qXE4LZeyWvrDrQz6r",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "pem5s29SZy8SBG379",
    "childNum": 4,
    "isEnable": true,
    "key": "singleOption",
    "label": "単選",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "e6PTu4uCLHcD5qe9i",
    "type": "folder"
  },
  {
    "_id": "pnKRzTzePydF2WPgk",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "生物",
    "labelCht": "生物",
    "labelEng": "creature",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "tcbMYc3xbaP3isZKN",
    "type": "string",
    "value": "biologyScore"
  },
  {
    "_id": "pnPkTLRMhjiZ7G3wa",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "大学院",
    "labelCht": "研究生院",
    "labelEng": "Graduate School",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "qLp95bMxAuc5sY8A4",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "popXPWFk8RuupgoaA",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "名古屋外国語大学",
    "labelCht": "名古屋外国語大学",
    "level": 2,
    "orderId": 25,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "25"
  },
  {
    "_id": "prvto7FPN9RyzeQff",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "除籍学生(複選)",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "e6PTu4uCLHcD5qe9i",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "pvgeMtS2sGZpp5568",
    "childNum": 19,
    "isEnable": true,
    "key": "career",
    "label": "職業",
    "labelCht": "職業",
    "labelEng": "career",
    "level": 2,
    "orderId": 19,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "q5mTSER53zJ9o59nz",
    "isEnable": true,
    "key": "option",
    "label": "重慶市",
    "labelCht": "重慶市",
    "labelEng": "Chongqing city",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "number",
    "value": "14"
  },
  {
    "_id": "q5wG226e5ptyeE4xu",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "桜花学園大学",
    "labelCht": "櫻花学園大学",
    "level": 2,
    "orderId": 43,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "43"
  },
  {
    "_id": "qBypSSqhTsiTTg8x5",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "青",
    "labelCht": "青",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "gt3SCaikqeyTh86n5",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "qFw6cuavQrxCeuvpP",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "日本語科 一年半進学",
    "labelCht": "日本語科 一年半進学",
    "level": 2,
    "note1": "1.5",
    "orderId": 1,
    "parentFolderId": "Rb6acfqvPPJ2huBbe",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "qK6R8CqgaNF6x4sy9",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "自己推薦",
    "labelCht": "自己推薦",
    "labelEng": "Recommend yourself",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "zRc6iLsG2ePTg8kz6",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "qLp95bMxAuc5sY8A4",
    "childNum": 5,
    "isEnable": true,
    "key": "advancedCourse",
    "label": "進学類",
    "labelCht": "進学類",
    "labelEng": "Advanced course",
    "level": 1,
    "orderId": 15,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder"
  },
  {
    "_id": "qN7FRR6q3QXfkXNZ4",
    "childNum": 4,
    "isEnable": true,
    "key": "personality",
    "label": "性格",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "SgDgrMYXKfNcWFZFe",
    "type": "folder"
  },
  {
    "_id": "qNFPVKEWJE6RY4Zfs7Po",
    "isEnable": true,
    "label": "已入住",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "veIkJdidf3Ojo3JSM9B4",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "qTmby2T2FpuWrSb7a",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "order",
    "labelCht": "order",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "gFQwZn9PWmDZNR5ug",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "qXE4LZeyWvrDrQz6r",
    "childNum": 7,
    "isEnable": true,
    "key": "inquiryType",
    "label": "諮詢類別",
    "level": 1,
    "orderId": 6,
    "parentFolderId": "rWHGNFjFi5TmTgFhA",
    "type": "folder"
  },
  {
    "_id": "qcYsMNJTSvaHqnbFT",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "面接審査",
    "level": 2,
    "note1": "10",
    "note2": "indexStudent,checkSheetStudent,interviewStudent,applicationStudent,inquiryStudent,",
    "orderId": 2,
    "parentFolderId": "cqygzTh26W5g2zQuA",
    "type": "string",
    "value": "12"
  },
  {
    "_id": "qdnYrQpkxQ7fsfTzt",
    "isEnable": true,
    "key": "option",
    "label": "永住者の配偶者等",
    "labelCht": "無",
    "labelEng": "no",
    "level": 3,
    "orderId": 5,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "14"
  },
  {
    "_id": "qeN3ccKP5fudhWTbt",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "Q",
    "level": 2,
    "orderId": 16,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "Q"
  },
  {
    "_id": "qjBnqKqLDB86f8qyH",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "中長期在留者の受入れの開始",
    "labelCht": "中長期在留者の受入れの開始",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "tQKqCf6gdoPMjChn8",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "qooh428MuQiH2qF6B",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "なし",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "dHQyXidwYApd2r73F",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "qpnY9im9H9ARQz29B",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "家族滞在",
    "labelCht": "無",
    "labelEng": "no",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "12"
  },
  {
    "_id": "qr5EaxJx6R7Sg6PiE",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "修了",
    "labelCht": "結業",
    "level": 2,
    "note1": "50",
    "note2": "indexStudent,master,affairStudent,testStudent,examStudent,graduateStudent,commentOnTeacher,admissionStatus,inquiryStudent,",
    "orderId": 11,
    "parentFolderId": "cqygzTh26W5g2zQuA",
    "type": "string",
    "value": "51"
  },
  {
    "_id": "qrfPtSIWzvPkinLlAjJ3",
    "isEnable": true,
    "key": "relationshipOfSchool",
    "label": "学校との関係",
    "level": 2,
    "orderId": 18,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "qtDkv8idokq5Jdkiu",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "大学別科",
    "labelCht": "大學系",
    "labelEng": "College department",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "BkaebxeYePdnJ36Fu",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "qxBimFiT4vcoK3GDs",
    "isEnable": true,
    "key": "option",
    "label": "宗教",
    "labelCht": "無",
    "labelEng": "no",
    "level": 3,
    "orderId": 30,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "23"
  },
  {
    "_id": "r2ACWRCrxZn8peEDJ",
    "childNum": 32,
    "isEnable": true,
    "key": "date",
    "label": "日期1~31",
    "labelCht": "日期",
    "labelEng": "date",
    "level": 1,
    "orderId": 10,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "r4iybtFvSn7JrwK6n",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "年度前期",
    "labelCht": "財政年度初",
    "labelEng": "Early fiscal year",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "JGF8rTr32EC36i4WJ",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "r9FPYbundxKCk5DxA",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "QUANG  BINH",
    "labelCht": "QUANG  BINH",
    "labelEng": "QUANG  BINH",
    "level": 3,
    "orderId": 62,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "31"
  },
  {
    "_id": "rKsbXcsvWNIOKgoSsHkq",
    "isEnable": true,
    "label": "本校生",
    "level": 3,
    "orderId": 4,
    "parentFolderId": "qrfPtSIWzvPkinLlAjJ3",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "rLDtawHZT5AWhySev",
    "childNum": 10,
    "isEnable": true,
    "key": "semesterYear",
    "label": "学年度",
    "labelCht": "學年度",
    "labelEng": "year",
    "level": 1,
    "orderId": 0,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder",
    "value": "folder"
  },
  {
    "_id": "rWHGNFjFi5TmTgFhA",
    "childNum": 0,
    "isEnable": true,
    "key": "teacher",
    "label": "教師",
    "labelCht": "教師",
    "labelEng": "teacher",
    "level": 0,
    "orderId": 2,
    "parentFolderId": "",
    "type": "folder"
  },
  {
    "_id": "rcFvLqYSKCS5kt3Sm",
    "childNum": 2,
    "isEnable": true,
    "key": "semester",
    "label": "学期別",
    "labelCht": "學期別",
    "level": 1,
    "orderId": 2,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder"
  },
  {
    "_id": "rktn6ipdg8wWB63m3",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "私用",
    "labelCht": "事假",
    "level": 2,
    "note1": "red",
    "note2": "#f17e7e",
    "orderId": 11,
    "parentFolderId": "69HlkaS4w9jOFrxQCqHL",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "rma2cm4oqLjWNCnwk",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "養母",
    "labelCht": "養母",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "tBuxP8MZRxbcnMf5s",
    "type": "number",
    "value": "12"
  },
  {
    "_id": "rn7z5FYbmdJZ4iaeT",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "託福成績",
    "labelCht": "托福成績",
    "labelEng": "Toefl Score",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "4Sb5FKDd6jovvGGP7",
    "type": "string",
    "value": "toeflScore"
  },
  {
    "_id": "rusEAjieCpe6xXAuW",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "日本での就職",
    "labelCht": "在日本就業",
    "labelEng": "Employment in Japan",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "BEcYusFEnsFbRxFgi",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "rvU63oBkSQ9NQZi3rzM3",
    "isEnable": true,
    "label": "尚未入住",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "veIkJdidf3Ojo3JSM9B4",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "rz287beQH3WpkhJMM",
    "isEnable": true,
    "key": "option",
    "label": "バングラディシュ",
    "level": 3,
    "orderId": 18,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "string",
    "value": "20"
  },
  {
    "_id": "s2D9ZuE2ghWxdO8CFvID",
    "isEnable": true,
    "key": "informWay",
    "label": "通知方式",
    "level": 2,
    "orderId": 12,
    "parentFolderId": "niMW6dizwqnorXBhQ",
    "type": "folder"
  },
  {
    "_id": "s5u4pMWyShvjhKxdE",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "取引関係者、現地企業の親族",
    "labelCht": "商業夥伴，當地公司",
    "labelEng": "Commercial entrustment",
    "level": 3,
    "orderId": 15,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "15"
  },
  {
    "_id": "s6c9YFQpTtznCnr24",
    "childNum": 2,
    "isEnable": true,
    "key": "graduationRuleTime",
    "label": "卒業規定時間",
    "labelCht": "畢業規定時間",
    "labelEng": "graduation rule time",
    "level": 2,
    "orderId": 23,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "s9fj2Hh8XqrL5WS9y",
    "childNum": 4,
    "isEnable": true,
    "key": "stayStatus",
    "label": "在籍状況",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "niMW6dizwqnorXBhQ",
    "type": "folder"
  },
  {
    "_id": "sEDb73RJNNNPMyKBD",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "少",
    "labelCht": "少",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "nWQHp45PAXXAMxdry",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "sH4GeEkhnRPuFw9XT",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "身心障害、伝染病者",
    "labelCht": "身心障礙、傳染病者",
    "labelEng": "Disabled person、Infectious patient",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "dNdTrpwYafkZoHn3e",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "sJhXfm3HmttspC6vs",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "悪い",
    "labelCht": "惡い",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "yXPFuyumZM7gdFG5a",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "sNEWgB3BaFrkiBc49",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "VIET NAM",
    "labelCht": "越南",
    "labelEng": "Vietnam",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "boQWTSYGfYnWMf5sK",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "sNgTGsDaABo9bsMr2",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "資料請求中",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "zcF6vqWsqmqhvddxr",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "sPxN2J2oBHMJLQtDq",
    "isEnable": true,
    "key": "option",
    "label": "韓国",
    "labelCht": "韓國",
    "level": 3,
    "orderId": 12,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "string",
    "value": "19"
  },
  {
    "_id": "sSmSs69bui5K3vDMo",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "2",
    "labelCht": "二",
    "labelEng": "two",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "RjT2jTY5CpxZEZpTG",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "sZGsF8fXduN6zFhhr",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "11月1日における受入れ状況",
    "labelCht": "11月1日における受入れ狀況",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "tQKqCf6gdoPMjChn8",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "sbgYkSSooEq94zZvi",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "単人間",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "Mu7xqf8oEtLsqhvGX",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "sememnb6xL3YTb6cQ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "取引関係者、現地企？",
    "labelCht": "取引關係者、現地企？",
    "level": 2,
    "orderId": 14,
    "parentFolderId": "tBuxP8MZRxbcnMf5s",
    "type": "number",
    "value": "15"
  },
  {
    "_id": "sgsKPPGGLfDLaA4y4",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "居眠が多い",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "dWDcptmLf962XkWrZ",
    "type": "number",
    "value": "9"
  },
  {
    "_id": "skJGpTEYb7SL53b4W",
    "isEnable": true,
    "key": "option",
    "label": "25",
    "labelCht": "25",
    "labelEng": "25",
    "level": 2,
    "orderId": 24,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "25"
  },
  {
    "_id": "sqmBQskXX7kvTiNuA",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "教室規則を守らず",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "dWDcptmLf962XkWrZ",
    "type": "string",
    "value": "10"
  },
  {
    "_id": "styKhjELyDAPWbqGx",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "4.5",
    "labelCht": "4.5",
    "labelEng": "4.5",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "WoPJf6A9dJT2cp3hr",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "sxRbNpmxBmabHSGLB",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "愛知県立芸術大学(公立)",
    "labelCht": "愛知県立芸術大学(公立)",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "sxyHPdiMLZo4rFesh",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "300万日円以上",
    "labelCht": "300万日円以上",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "u44m8ojJMZDkEvhRw",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "t4r43yE9AE6M8pdh5",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "人際関係",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "qXE4LZeyWvrDrQz6r",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "t5FG9tyfB9YPmARNk",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "半年",
    "labelCht": "半年",
    "labelEng": "Half a year",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "Yh3mfYk5Ba2wk36nu",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "tBuxP8MZRxbcnMf5s",
    "childNum": 19,
    "isEnable": true,
    "key": "studentRelationship",
    "label": "学生との関係",
    "labelCht": "學生的關係",
    "labelEng": "Student relationship",
    "level": 2,
    "orderId": 15,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "tCadi8oi3ty2K5FquWt2",
    "isEnable": true,
    "label": "仲介業者",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "qrfPtSIWzvPkinLlAjJ3",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "tFuumgjBkgo6Y2Ds5",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "N5",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "JGnTdm4MGmAynLHmh",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "tGqD9eNttyFX56WCd",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "C",
    "labelCht": "C",
    "labelEng": "C",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "C"
  },
  {
    "_id": "tHpYJfmpR6n5oNrrt",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "微信",
    "labelCht": "QQ",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "tg7exip2uGhi9KJQJ",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "tJZjXfmzLeje6Zuta",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "中部大学",
    "labelCht": "中部大学",
    "level": 2,
    "orderId": 12,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "13"
  },
  {
    "_id": "tKhuimL473sFLGazw",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "専修学校専門課程",
    "labelCht": "專業課程",
    "labelEng": "Professional Courses",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "qLp95bMxAuc5sY8A4",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "tL4XPB7MksBmxrCAr",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "母",
    "labelCht": "母",
    "labelEng": "mother",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "ikjiA6YpusjuRuwcs",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "tMCBDeDNXhZjGootT",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "愛知県立大学",
    "labelCht": "愛知県立大学",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "tQKqCf6gdoPMjChn8",
    "childNum": 4,
    "isEnable": true,
    "key": "longtern",
    "label": "中長期在留者",
    "level": 2,
    "orderId": 22,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "tWhTLyBSXCsBbYs7H",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "U",
    "labelCht": "U",
    "labelEng": "U",
    "level": 2,
    "orderId": 20,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "U"
  },
  {
    "_id": "tXHEP44ePcpScTCos",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "H",
    "labelCht": "H",
    "labelEng": "H",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "H"
  },
  {
    "_id": "tZqwQ5ox5cAyC2hHS",
    "childNum": 26,
    "isEnable": true,
    "key": "classSchool",
    "label": "クラス",
    "labelCht": "班級",
    "labelEng": "class",
    "level": 1,
    "orderId": 13,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder"
  },
  {
    "_id": "tcbMYc3xbaP3isZKN",
    "childNum": 11,
    "isEnable": true,
    "key": "ejuExam",
    "label": "日本留学試験結果",
    "labelCht": "日本留學試驗結果",
    "labelEng": "EJU study test result",
    "level": 1,
    "orderId": 3,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder"
  },
  {
    "_id": "tcu35BdbNoXPRyDiL",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "開課中",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "YsYq5SSomDMhmtWY8",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "tdEn248ZcF2kRY5BY",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "名城大学",
    "labelCht": "名城大学",
    "level": 2,
    "orderId": 21,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "21"
  },
  {
    "_id": "teHcfJ0HVNRJ5TSKjA51",
    "isEnable": true,
    "label": "意向ある",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "BOMdl2bkLqQmAwXLaRVr",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "tg7exip2uGhi9KJQJ",
    "childNum": 7,
    "isEnable": true,
    "key": "inquiryWay",
    "label": "諮詢管道",
    "level": 1,
    "orderId": 5,
    "parentFolderId": "rWHGNFjFi5TmTgFhA",
    "type": "folder"
  },
  {
    "_id": "tiPtRaLroPY7CruSr",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "所長",
    "labelCht": "所長",
    "labelEng": "chief",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "EMx476y5qT97xamPW",
    "type": "number",
    "value": "11"
  },
  {
    "_id": "tmcdPQJcjZrJB4S45",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "W",
    "labelCht": "W",
    "labelEng": "W",
    "level": 2,
    "orderId": 22,
    "parentFolderId": "tZqwQ5ox5cAyC2hHS",
    "type": "string",
    "value": "W"
  },
  {
    "_id": "tsafpxd4BPyZaNnih",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "母",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "HXT9aD3XGeoqxXnjr",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "ttLEwaHrFdJpW6tNN",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "個人紹介経由",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "gGaXSHkzsM3bm4cWu",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "tvA288k3wppxGPQyx",
    "childNum": 6,
    "isEnable": true,
    "key": "calendarEvent",
    "label": "事件",
    "labelCht": "事件",
    "level": 1,
    "orderId": 4,
    "parentFolderId": "mgfbBDamqaqvzeJ4z",
    "type": "folder"
  },
  {
    "_id": "tvAzrrX39XdQ5u88L",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "1年半",
    "labelCht": "1年半",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "s6c9YFQpTtznCnr24",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "tygakB4NvnWnWPnjj",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "未繳",
    "labelCht": "未繳",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "nWQHp45PAXXAMxdry",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "u3HP2LbW6EEifBydZx0V",
    "isEnable": true,
    "key": "inClassAbsence",
    "label": "欠席理由",
    "level": 1,
    "orderId": 7,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder"
  },
  {
    "_id": "u44m8ojJMZDkEvhRw",
    "childNum": 7,
    "isEnable": true,
    "key": "annualIncome",
    "label": "年収",
    "labelCht": "年收入",
    "labelEng": "Annual income",
    "level": 1,
    "orderId": 15,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "u6D8js5tw3HbhnMSB",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "貴州省",
    "labelCht": "貴州省",
    "level": 3,
    "orderId": 18,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "11"
  },
  {
    "_id": "u6Jx9OjQqKRiZitdfps2",
    "isEnable": true,
    "label": "出願決定",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "BOMdl2bkLqQmAwXLaRVr",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "uDGERTGgLt6kq2Jwn",
    "isEnable": true,
    "key": "option",
    "label": "強制退学（除籍）",
    "labelCht": "強制退学（除籍）",
    "level": 3,
    "orderId": 5,
    "parentFolderId": "HaSH5PobxGEvgzMgA",
    "type": "string",
    "value": "32"
  },
  {
    "_id": "uEnJwyNSwmKZRNtLj",
    "childNum": 2,
    "isEnable": true,
    "key": "graduatefreshman",
    "label": "卒業生新入生",
    "level": 2,
    "orderId": 29,
    "parentFolderId": "voQ8BPhgfTB3YBqMG",
    "type": "folder"
  },
  {
    "_id": "uLHKREMipZFtWXEs4",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "KATHMANDU",
    "level": 2,
    "orderId": 12,
    "parentFolderId": "WoNy9iaGLSFuWgBMd",
    "type": "number",
    "value": "12"
  },
  {
    "_id": "uMw2YPzdzg4Jxp2St",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "apply",
    "labelCht": "apply",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "gFQwZn9PWmDZNR5ug",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "uPv4mjbh88cxrFFQR",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "経営政策",
    "labelCht": "經營政策",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "i4PzFrvC2mf9Yuich",
    "type": "string",
    "value": "11"
  },
  {
    "_id": "uYRdzPeGwVY95zZTWbAg",
    "isEnable": true,
    "label": "わからない、又は連絡なし",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "u3HP2LbW6EEifBydZx0V",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "uYwyTYg2T8PSie8hS",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "1-5 万円",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "WzbRntLC6b4AZKv7s",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "uZu5uqaxLq8PPuYCs",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "100万-150万日円",
    "labelCht": "100万-150万日円",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "u44m8ojJMZDkEvhRw",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "ucEt2GEDBRRXfBKzN",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "明確な進学意思が持つ、それなりに準備してきた。",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "AJC7gw3RrAXMbTxbu",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "ucqdk2XQ2B49R8Bbc",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "2013",
    "labelCht": "2013",
    "labelEng": "2013",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "rLDtawHZT5AWhySev",
    "type": "string",
    "value": "2013"
  },
  {
    "_id": "ui86HMrnA4bjnZnv9",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "山東省",
    "labelCht": "山東省",
    "level": 3,
    "orderId": 11,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "8"
  },
  {
    "_id": "ujcpW5kuK7hoBpJHT",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "1100円",
    "labelCht": "1100日元",
    "labelEng": "1100 yen",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "Zfd285m2TeoHBqKmY",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "ukCfu5kjk6W6CSY6x",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "赤",
    "labelCht": "赤",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "gt3SCaikqeyTh86n5",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "ulKHfUkvNst4fCdCOGVR",
    "isEnable": true,
    "label": "インターネット",
    "labelEng": "Internet",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "s2D9ZuE2ghWxdO8CFvID",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "uoGwoChRLgxiL7fvE",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "除籍",
    "level": 2,
    "note1": "30",
    "note2": "indexStudent,master,affairStudent,testStudent,examStudent,graduateStudent,commentOnTeacher,admissionStatus,inquiryStudent,jpn22",
    "orderId": 9,
    "parentFolderId": "cqygzTh26W5g2zQuA",
    "type": "string",
    "value": "32"
  },
  {
    "_id": "uwzZHY2b8wQeegdg6",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "祖母",
    "labelCht": "祖母",
    "level": 2,
    "orderId": 13,
    "parentFolderId": "tBuxP8MZRxbcnMf5s",
    "type": "number",
    "value": "14"
  },
  {
    "_id": "v2pRzBKLZj2LHCz5z",
    "isEnable": true,
    "key": "option",
    "label": "19",
    "labelCht": "19",
    "labelEng": "19",
    "level": 3,
    "orderId": 10,
    "parentFolderId": "7vANGTAAD9sqEfnSs",
    "type": "number",
    "value": "10"
  },
  {
    "_id": "v5GP7fEiHZEPYmsqq",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "悪い",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "WTCMNPWByB7EbH3uK",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "v88nBiugMNdpKni67",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "PALLEBEDDA",
    "level": 3,
    "orderId": 60,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "38"
  },
  {
    "_id": "v9XhFagXLmSgrA8J4",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "2年",
    "labelCht": "2年",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "s6c9YFQpTtznCnr24",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "vBGY8cNsDfhP7phWF",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "礼儀が悪い",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "dWDcptmLf962XkWrZ",
    "type": "number",
    "value": "8"
  },
  {
    "_id": "vCBjFFnaqDYihHx5n",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "18",
    "labelCht": "18",
    "level": 2,
    "orderId": 18,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "18"
  },
  {
    "_id": "vCMubuuctxvXraNp5",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "優易",
    "labelCht": "優良",
    "labelEng": "Excellent",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "dNdTrpwYafkZoHn3e",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "vFgsWrWdB2iQTdyvs",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "おば",
    "labelCht": "姑媽",
    "labelEng": "aunt",
    "level": 2,
    "orderId": 18,
    "parentFolderId": "tBuxP8MZRxbcnMf5s",
    "type": "string",
    "value": "19"
  },
  {
    "_id": "vHAOhDDhKiExcA3PIYQx",
    "isEnable": true,
    "label": "進学",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "63CjBXyx8KaZiOxMjWXu",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "vKc8c5vHXPmmYFygq",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "使用中",
    "labelCht": "使用中",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "Bq6nyDPanXnREqwsF",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "vL6xzjEijK5uhapi1FxT",
    "isEnable": true,
    "label": "考慮中",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "BOMdl2bkLqQmAwXLaRVr",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "vN4djfcDxjhvZxeEu",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "黒",
    "labelCht": "黑",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "gt3SCaikqeyTh86n5",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "vPpikCDQmvKOU1yUw7C8",
    "defaultValue": "2",
    "isEnable": true,
    "label": "卒業見込",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "5IrfCNDbKJCI1pd9Ta3c",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "vWzGxNTgfGWZFYeHD",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "経済学",
    "labelCht": "経濟学",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "i4PzFrvC2mf9Yuich",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "vX9o9S7GmoFsdxoTy",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "＜中国＞学位認証",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "g2EWYARWXmnBYcPHY",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "vXMFsq82XWcqvraGd",
    "isEnable": true,
    "key": "studentStatus3",
    "label": "学生状態3",
    "labelCht": "學生情況3",
    "labelEng": "Student situation",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "niMW6dizwqnorXBhQ",
    "type": "folder"
  },
  {
    "_id": "vXwPFt9TKHQ7jsPsH",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "使用しない",
    "labelCht": "使用しない",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "Bq6nyDPanXnREqwsF",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "vZRvRAkfysoq9qWbu",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "0.5",
    "labelCht": "0.5",
    "labelEng": "0.5",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "WoPJf6A9dJT2cp3hr",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "vbZa5f9MwAkRTEkea",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "2019",
    "level": 2,
    "note1": "1",
    "orderId": 2,
    "parentFolderId": "rLDtawHZT5AWhySev",
    "type": "string",
    "value": "2019"
  },
  {
    "_id": "veIkJdidf3Ojo3JSM9B4",
    "isEnable": true,
    "key": "contractStatus",
    "label": "合約狀態",
    "level": 1,
    "orderId": 6,
    "parentFolderId": "mgfbBDamqaqvzeJ4z",
    "type": "folder"
  },
  {
    "_id": "vg4meZu7dWiDagEvD",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "受験級",
    "labelCht": "考試成績",
    "labelEng": "Examination grade",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "AqnPEmtTb4KfmYxYx",
    "type": "string",
    "value": "jlptLevel"
  },
  {
    "_id": "vo3PivW8khtHQv4WK",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "宿題をきちんと提出",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "mCSyjN9ezwmtQ8Ydr",
    "type": "number",
    "value": "8"
  },
  {
    "_id": "voLF7F1FDHcpAQhEjcZZ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "専修",
    "labelCht": "専修",
    "labelEng": "special training school",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "6FQ5JWq38TJytcnT4",
    "type": "number",
    "value": "9"
  },
  {
    "_id": "voQ8BPhgfTB3YBqMG",
    "childNum": 0,
    "isEnable": true,
    "key": "studentSel",
    "label": "学生資料",
    "level": 1,
    "orderId": 4,
    "parentFolderId": "jPvXpapnFPui4AGKY",
    "type": "folder"
  },
  {
    "_id": "vp2ydwbSyD4RdSdd9",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "東海女子大学",
    "labelCht": "東海女子大学",
    "level": 2,
    "orderId": 15,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "15"
  },
  {
    "_id": "vqeejyB6ZDAdsZvu8",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "次長",
    "labelCht": "副將長",
    "labelEng": "Deputy general",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "EMx476y5qT97xamPW",
    "type": "number",
    "value": "9"
  },
  {
    "_id": "vrMCb5QCR8jzBPE88",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "13",
    "labelCht": "13",
    "level": 2,
    "orderId": 12,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "13"
  },
  {
    "_id": "vwpF6SdzQCnesZQxZ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "名古屋芸術大学",
    "labelCht": "名古屋芸術大学",
    "level": 2,
    "orderId": 26,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "26"
  },
  {
    "_id": "vx09aJmjM4p2lW8f52Ay",
    "defaultValue": "3",
    "isEnable": true,
    "label": "中途退学",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "5IrfCNDbKJCI1pd9Ta3c",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "vydNNeqDAbpYpzpp6",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "その他の理由",
    "labelCht": "その他の理由",
    "labelEng": "other reason",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "gXWftsRK8PNwdYTCh",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "vyf5raZobPN45mFBS",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "4",
    "labelCht": "4",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "4"
  },
  {
    "_id": "w3udSioqNyHWeZSGa",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "研究",
    "labelEng": "Settlers",
    "level": 3,
    "orderId": 18,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "wLFrvBuYPejAirRf6",
    "isEnable": true,
    "key": "option",
    "label": "技能実習3号イ",
    "labelEng": "Study abroad",
    "level": 3,
    "orderId": 15,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "29"
  },
  {
    "_id": "wMpsV63wdXXZlluJxSOg",
    "isEnable": true,
    "label": "寧夏",
    "level": 3,
    "orderId": 32,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "76"
  },
  {
    "_id": "wPJviTp2wWhboF4T7",
    "childNum": 2,
    "isEnable": true,
    "key": "classTime",
    "label": "上課時段",
    "labelCht": "上課時段",
    "labelEng": "class time",
    "level": 1,
    "orderId": 17,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder"
  },
  {
    "_id": "wPh4BhvbbNzKmFe5i",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "私用",
    "labelCht": "私用",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "n5JA32PzthNYg895F",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "wkMWqAYedqWhGSQZ6",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "学生",
    "labelCht": "學生",
    "labelEng": "student",
    "level": 2,
    "orderId": 12,
    "parentFolderId": "pvgeMtS2sGZpp5568",
    "type": "number",
    "value": "12"
  },
  {
    "_id": "wmjMA4qQyLXdcLswL",
    "isEnable": true,
    "key": "option",
    "label": "雲南省",
    "labelCht": "雲南省",
    "labelEng": "雲南省",
    "level": 3,
    "orderId": 17,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "50"
  },
  {
    "_id": "wnFRoKuo4pucFkChr",
    "isEnable": true,
    "key": "option",
    "label": "LAMJUNG",
    "labelCht": "LAMJUNG",
    "labelEng": "LAMJUNG",
    "level": 3,
    "orderId": 54,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "68"
  },
  {
    "_id": "wwhdRn2wX7Q7yWKE8",
    "isEnable": true,
    "key": "option",
    "label": "日本人の配偶者等",
    "labelCht": "無",
    "labelEng": "no",
    "level": 3,
    "orderId": 4,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "13"
  },
  {
    "_id": "wx7mcwDMpYzM78hYD",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "在籍学生(複選)",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "pem5s29SZy8SBG379",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "x3Bk7uwvXNDwRYJxB",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "なし",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "bZv9NfF5E7u5sb74X",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "x3tuf5CETN4RX3imj",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "事故",
    "level": 2,
    "note1": "red",
    "note2": "#f17e7e",
    "orderId": 9,
    "parentFolderId": "69HlkaS4w9jOFrxQCqHL",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "x4sRreEYq3Cn5Rz4F",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "安徽省",
    "labelCht": "安徽省",
    "level": 3,
    "orderId": 12,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "17"
  },
  {
    "_id": "x6gk5dAhtDrggHfyr",
    "childNum": 0,
    "isEnable": true,
    "key": "classroom",
    "label": "教室",
    "labelCht": "教室",
    "labelEng": "classroom",
    "level": 0,
    "orderId": 1,
    "parentFolderId": "",
    "type": "folder",
    "value": ""
  },
  {
    "_id": "xEAatWKCFQ8dXsLrS",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "＜ベトナム＞VN-NARIC",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "g2EWYARWXmnBYcPHY",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "xGWLD9hAL6LAbieMw",
    "childNum": 2,
    "isEnable": true,
    "key": "studentType",
    "label": "学生別",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "niMW6dizwqnorXBhQ",
    "type": "folder"
  },
  {
    "_id": "xJRLrW99qfbWCuQYe",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "華中地区",
    "labelCht": "華中地区",
    "labelEng": "華中地区",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "string",
    "value": "9"
  },
  {
    "_id": "xM25WSkzM8Hug22e5",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "その他",
    "labelCht": "其他",
    "labelEng": "Other",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "Dan7bTZgSgkSewy7x",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "xPpqWDRrmGMnyKDnC",
    "isEnable": true,
    "key": "option",
    "label": "19",
    "labelCht": "19",
    "labelEng": "19",
    "level": 2,
    "orderId": 18,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "19"
  },
  {
    "_id": "xU7lRZKJg0X3MOswBDUR",
    "isEnable": true,
    "label": "試験済",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "CvZ87j8peKegsUwlg8AA",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "xZebBuDD6SvQsAoYP",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "黄",
    "labelCht": "黃",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "gt3SCaikqeyTh86n5",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "xaH5ScLzTa9s6dSLk",
    "childNum": 3,
    "isEnable": true,
    "key": "engAbilityInterview",
    "label": "英語能力",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "SgDgrMYXKfNcWFZFe",
    "type": "folder"
  },
  {
    "_id": "xb6pQ6Yzn8JjQa5sF",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "その他",
    "labelCht": "其他",
    "labelEng": "Other",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "jSuvxuEeKGYReZogc",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "xc6KSPoOvtzC92VPAbL7",
    "isEnable": true,
    "label": "友人、知人",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "TX3dNmIr4dMMrnBwh3CL",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "xcnPrdLHeebaHT9GS",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "DA NANG",
    "labelCht": "DA NANG",
    "labelEng": "DA NANG",
    "level": 3,
    "orderId": 42,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "string",
    "value": "18"
  },
  {
    "_id": "xdQtTPY448CGSBY54",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "文字語彙",
    "labelCht": "文字語彙",
    "labelEng": "vocabulary",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "AqnPEmtTb4KfmYxYx",
    "type": "string",
    "value": "jlptVocabularyScore"
  },
  {
    "_id": "xisNeftQB3dKt67DyPbY",
    "childNum": "0",
    "isEnable": true,
    "key": "option",
    "label": "専門",
    "labelCht": "専門",
    "labelEng": "vocational college",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "6FQ5JWq38TJytcnT4",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "xixp3cLYzbdcWBCMA",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "紫",
    "labelCht": "紫",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "gt3SCaikqeyTh86n5",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "xntYiLxRdFabzDXHN",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "不合格",
    "labelCht": "不合格",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "ckDELkrtdHcK7HB5H",
    "type": "number",
    "value": "3"
  },
  {
    "_id": "xoZzPJIShGRbGDixZ4PG",
    "isEnable": true,
    "label": "スマホ使用",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "oYRlH6kk1Mkvmz1BCuj8",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "xqMvd2Gqb87QLuhss",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "読解",
    "labelCht": "読解",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "L4mvofGTyCascbtaP",
    "type": "string",
    "value": "readingScore"
  },
  {
    "_id": "y6HiC6DGH2ZNCwYHr",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "？？女子大学",
    "labelCht": "？？女子大学",
    "level": 2,
    "orderId": 46,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "46"
  },
  {
    "_id": "y8KbsCRptgVWRJ4yI11h",
    "isEnable": true,
    "label": "就職",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "63CjBXyx8KaZiOxMjWXu",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "yACmRofczTrDczkPd",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "除籍処分",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "e2mykSsn3Dy6Tzv3g",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "yDGuf5gnE3Mg3yrCQ",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "予習する",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "mCSyjN9ezwmtQ8Ydr",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "yJhhRNyRQeygu9djK",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "名古屋造型芸術大学",
    "labelCht": "名古屋造型芸術大学",
    "level": 2,
    "orderId": 32,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "32"
  },
  {
    "_id": "yNPYczZCCcxr6YnfC",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "その他",
    "labelCht": "その他",
    "level": 2,
    "orderId": 17,
    "parentFolderId": "tBuxP8MZRxbcnMf5s",
    "type": "number",
    "value": "18"
  },
  {
    "_id": "yR2sfjft8R5K7SYj4",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "スリランカ",
    "level": 2,
    "orderId": 15,
    "parentFolderId": "fpttHYq7DxorHqwhL",
    "type": "string",
    "value": "12"
  },
  {
    "_id": "yS6sXkeDmq72Lt4PY",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "物理",
    "labelCht": "物理",
    "labelEng": "Physics",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "tcbMYc3xbaP3isZKN",
    "type": "string",
    "value": "physicsScore"
  },
  {
    "_id": "yTbL76ZZ7ExYggT9u",
    "isEnable": true,
    "key": "option",
    "label": "10",
    "labelCht": "10",
    "labelEng": "10",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "7vANGTAAD9sqEfnSs",
    "type": "number",
    "value": "12"
  },
  {
    "_id": "yTuZPcBfM97Hw29SN",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "高度専門職１号イ",
    "labelEng": "Study abroad",
    "level": 3,
    "orderId": 8,
    "parentFolderId": "BNoiAzvdfs8qKPpm6",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "yWobmNERzz2xhciFp",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "許可",
    "labelCht": "許可",
    "labelEng": "license",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "ez7K2htashvgpJ6bk",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "yWove4sCkYChpCaix",
    "isEnable": true,
    "key": "option",
    "label": "日本",
    "labelCht": "日本",
    "level": 3,
    "orderId": 75,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "39"
  },
  {
    "_id": "yXPFuyumZM7gdFG5a",
    "childNum": 4,
    "isEnable": true,
    "key": "command",
    "label": "評価",
    "labelCht": "評價",
    "level": 1,
    "orderId": 4,
    "parentFolderId": "NCYLK9ebQw3TJAaGv",
    "type": "folder"
  },
  {
    "_id": "yaak2X3jqVD5IdGSy1nj",
    "isEnable": true,
    "label": "試験済結果待ち",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "Sx44U3PhaBGOlrg23FrZ",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "yeAGnkvRqMvMg6qHf",
    "isEnable": true,
    "key": "option",
    "label": "湖南省",
    "labelCht": "湖南省",
    "labelEng": "湖南省",
    "level": 3,
    "orderId": 26,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "49"
  },
  {
    "_id": "ygwBmqQY23JtgxAzg",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "支社員",
    "labelCht": "分公司的工人",
    "labelEng": "Branch worker",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "EMx476y5qT97xamPW",
    "type": "number",
    "value": "6"
  },
  {
    "_id": "yiqK5GiYpG5ExmY6D",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "同窓と仲悪い",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "69HlkaS4w9jOFrxQCqHL",
    "type": "number",
    "value": "7"
  },
  {
    "_id": "yoFckgTuTFxqfw5N8",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "無",
    "labelCht": "無",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "p729qSXFYLnHZRqGw",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "yrbYX3Moi9xQGpQwX",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "5.5",
    "labelCht": "5.5",
    "labelEng": "5.5",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "WoPJf6A9dJT2cp3hr",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "ywWAhwRtBYagjRj28",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "総合科目",
    "labelCht": "總合科目",
    "labelEng": "General course",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "tcbMYc3xbaP3isZKN",
    "type": "string",
    "value": "comprehensiveScore"
  },
  {
    "_id": "yy0J6GIKsWu5iVlgF9JF",
    "isEnable": true,
    "label": "已退租",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "veIkJdidf3Ojo3JSM9B4",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "z2a9PK92dXedvChLy",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "料理店",
    "labelCht": "餐廳",
    "labelEng": "Restaurant",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "gyyFBx9L8NHTdDoQg",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "z2twawnqufXcRrbDb",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "親族訪問",
    "labelCht": "親族訪問",
    "labelEng": "visit relative",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "2ffEgWbnfE9JskS56",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "z5RfcqTuQXuxdPEcg",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "QQ",
    "labelCht": "QQ",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "tg7exip2uGhi9KJQJ",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "z6PPbzTottJXqomMD",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "専任",
    "labelCht": "專任",
    "labelEng": "Full-time",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "9WmWkGgKFqeuz3kux",
    "type": "number",
    "value": "1"
  },
  {
    "_id": "z6TtiActiXxZxTQw4",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "1990",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "rLDtawHZT5AWhySev",
    "type": "string",
    "value": "1990"
  },
  {
    "_id": "z7JXq35KhJcJCFPHk",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "印象がいい",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "HpYxwK3JHgSZwcZMR",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "z95dhtucaMLQueR5JtcJ",
    "isEnable": true,
    "label": "保留",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "VQcmpvi8a979GpmMA5JC",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "zEgXoYaqqr5MCz4dX",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "名古屋文理大学",
    "labelCht": "名古屋文理大学",
    "level": 2,
    "orderId": 33,
    "parentFolderId": "6ZS4zGQyrHANyqEeq",
    "type": "number",
    "value": "33"
  },
  {
    "_id": "zJyxfaakLBXrxSBXD",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "ok",
    "labelCht": "ok",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "nWQHp45PAXXAMxdry",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "zKsHAgZfzF77qmou8",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "5-10 万円",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "WzbRntLC6b4AZKv7s",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "zLBWwCcoZJtwWjgwB",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "正規生",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "xGWLD9hAL6LAbieMw",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "zRc6iLsG2ePTg8kz6",
    "childNum": 3,
    "isEnable": true,
    "key": "4thKind",
    "label": "区分4",
    "labelCht": "第4類",
    "labelEng": "4th kind",
    "level": 1,
    "orderId": 11,
    "parentFolderId": "x6gk5dAhtDrggHfyr",
    "type": "folder"
  },
  {
    "_id": "zcF6vqWsqmqhvddxr",
    "childNum": 10,
    "isEnable": true,
    "key": "recruitStatus",
    "label": "申込み状況",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "niMW6dizwqnorXBhQ",
    "type": "folder"
  },
  {
    "_id": "zeWmbonkd68s65e54",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "陜西省",
    "labelCht": "陜西省",
    "level": 3,
    "orderId": 8,
    "parentFolderId": "H6Rf2BgYHvBGEC8cP",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "zjKGFRMFEHYGJc92g",
    "isEnable": true,
    "key": "option",
    "label": "16",
    "labelCht": "16",
    "labelEng": "16",
    "level": 2,
    "orderId": 15,
    "parentFolderId": "Sjqg6TAjK2pGnCwKW",
    "type": "number",
    "value": "16"
  },
  {
    "_id": "zjLBAxoRfKayjpYZA",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "卒業",
    "labelCht": "畢業",
    "labelEng": "graduation",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "gXWftsRK8PNwdYTCh",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "zkripR4ydthikg8L6",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "2年次編入",
    "labelCht": "第二年轉移",
    "labelEng": "Second year transfer",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "2E6Te5puNo9zFyYqa",
    "type": "number",
    "value": "5"
  },
  {
    "_id": "znXYA4X3aSccpwesy",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "15",
    "labelCht": "15",
    "level": 2,
    "orderId": 14,
    "parentFolderId": "r2ACWRCrxZn8peEDJ",
    "type": "number",
    "value": "15"
  },
  {
    "_id": "zore7CDMHwYZ4DAec",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "男",
    "labelCht": "男",
    "labelEng": "male",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "9K6FiwbpLpiCJHoTd",
    "type": "number",
    "value": "2"
  },
  {
    "_id": "zpFFbBqfRr7x9NZD7",
    "childNum": 0,
    "isEnable": true,
    "key": "option",
    "label": "早退せず",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "69HlkaS4w9jOFrxQCqHL",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "5RbDmBTNwcJIX4z6PzFw",
    "isEnable": true,
    "label": "所得証明書",
    "level": 3,
    "orderId": 5,
    "parentFolderId": "LikD9NoJqzIWiYNHKL8k",
    "type": "string",
    "value": "incomeJap"
  },
  {
    "_id": "7364qchxV1uam9n4foaM",
    "isEnable": true,
    "label": "住民票",
    "level": 3,
    "orderId": 6,
    "parentFolderId": "LikD9NoJqzIWiYNHKL8k",
    "type": "string",
    "value": "residenceJap"
  },
  {
    "_id": "9WPyADqfKvinQ0oQYHWN",
    "isEnable": true,
    "label": "会社謄本(役員)",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "LikD9NoJqzIWiYNHKL8k",
    "type": "string",
    "value": "companyJap"
  },
  {
    "_id": "BO8rLLT7mbx8CICey23H",
    "isEnable": true,
    "label": "職業証明書",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "LikD9NoJqzIWiYNHKL8k",
    "type": "string",
    "value": "occupationJap"
  },
  {
    "_id": "KDpIGXFs24rQbl6XLNwR",
    "isEnable": true,
    "label": "保証書",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "LikD9NoJqzIWiYNHKL8k",
    "type": "string",
    "value": "guarantee"
  },
  {
    "_id": "QDOB8mFWtG1dlmgDBJ5i",
    "isEnable": true,
    "label": "営業許可(自営業)",
    "level": 3,
    "orderId": 4,
    "parentFolderId": "LikD9NoJqzIWiYNHKL8k",
    "type": "string",
    "value": "businessJap"
  },
  {
    "_id": "WYYQlPQ63jpA6fuaWaGI",
    "isEnable": true,
    "label": "預金殘高証明書",
    "level": 3,
    "orderId": 8,
    "parentFolderId": "LikD9NoJqzIWiYNHKL8k",
    "type": "string",
    "value": "bankJpn"
  },
  {
    "_id": "ebjis3RrZNrb0G1NoPXu",
    "isEnable": true,
    "label": "外國人登録済証明書",
    "level": 3,
    "orderId": 7,
    "parentFolderId": "LikD9NoJqzIWiYNHKL8k",
    "type": "string",
    "value": "registerJap"
  },
  {
    "_id": "h1wgG2Jw7zSOXhIVI97C",
    "isEnable": true,
    "label": "経費支弁書",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "LikD9NoJqzIWiYNHKL8k",
    "type": "string",
    "value": "payment"
  },
  {
    "_id": "4kc0zPR17yepFnwFW6yJ",
    "isEnable": true,
    "label": "履歷書",
    "level": 3,
    "orderId": 5,
    "parentFolderId": "LtHDGdP3wB7IMuywRRLl",
    "type": "string",
    "value": "resume"
  },
  {
    "_id": "5CLpsvvBNTWsKch7Leji",
    "isEnable": true,
    "label": "日本語学習証明書",
    "level": 3,
    "orderId": 11,
    "parentFolderId": "LtHDGdP3wB7IMuywRRLl",
    "type": "string",
    "value": "jpnlearn"
  },
  {
    "_id": "64KCqkQalWfX0VKyiOTg",
    "isEnable": true,
    "label": "卒業証書",
    "level": 3,
    "orderId": 8,
    "parentFolderId": "LtHDGdP3wB7IMuywRRLl",
    "type": "string",
    "value": "graduation"
  },
  {
    "_id": "BKa3Dp0ImDxIsdOySTwl",
    "isEnable": true,
    "label": "成績證明書",
    "level": 3,
    "orderId": 10,
    "parentFolderId": "LtHDGdP3wB7IMuywRRLl",
    "type": "string",
    "value": "grade"
  },
  {
    "_id": "GI9rTYOfPxpP9oUsCDIv",
    "isEnable": true,
    "label": "日本語能力合格証書",
    "level": 3,
    "orderId": 12,
    "parentFolderId": "LtHDGdP3wB7IMuywRRLl",
    "type": "string",
    "value": "jlptDoc"
  },
  {
    "_id": "YWBGgXnw5TkVTAT3JTDf",
    "isEnable": true,
    "label": "卒業証明書",
    "level": 3,
    "orderId": 7,
    "parentFolderId": "LtHDGdP3wB7IMuywRRLl",
    "type": "string",
    "value": "graduationDoc"
  },
  {
    "_id": "YWBGgXnw5TkVTAT3JTDf",
    "isEnable": true,
    "label": "卒業証明書",
    "level": 3,
    "orderId": 7,
    "parentFolderId": "LtHDGdP3wB7IMuywRRLl",
    "type": "string",
    "value": "graduationDoc"
  },
  {
    "_id": "aBvpuRUVRIOg6EGMY1Fy",
    "isEnable": true,
    "label": "質問書",
    "level": 3,
    "orderId": 6,
    "parentFolderId": "LtHDGdP3wB7IMuywRRLl",
    "type": "string",
    "value": "quesionnaire"
  },
  {
    "_id": "gYhMk71GhGgw4C2vp3Si",
    "isEnable": true,
    "label": "パスポート",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "LtHDGdP3wB7IMuywRRLl",
    "type": "string",
    "value": "passport"
  },
  {
    "_id": "nCWbONTku1KUeWrQ7N21",
    "isEnable": true,
    "label": "本人学位証書",
    "level": 3,
    "orderId": 9,
    "parentFolderId": "LtHDGdP3wB7IMuywRRLl",
    "type": "string",
    "value": "degree"
  },
  {
    "_id": "nfqSHD9TRS7A4azNo4Gc",
    "isEnable": true,
    "label": "学生連絡票",
    "level": 3,
    "orderId": 4,
    "parentFolderId": "LtHDGdP3wB7IMuywRRLl",
    "type": "string",
    "value": "investigation"
  },
  {
    "_id": "rtZLlFABBx4AUT87JKGP",
    "isEnable": true,
    "label": "戶籍簿写し",
    "level": 3,
    "orderId": 13,
    "parentFolderId": "LtHDGdP3wB7IMuywRRLl",
    "type": "string",
    "value": "household"
  },
  {
    "_id": "uKNTK47lTvksDWGZZ1vI",
    "isEnable": true,
    "label": "在留資格申請書",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "LtHDGdP3wB7IMuywRRLl",
    "type": "string",
    "value": "stayAppliance"
  },
  {
    "_id": "vP02vfkhwED0Rr4xIxJW",
    "isEnable": true,
    "label": "入学願書",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "LtHDGdP3wB7IMuywRRLl",
    "type": "string",
    "value": "applianceDoc"
  },
  {
    "_id": "zaUW1ZfSjSxhPtBfjG8C",
    "isEnable": true,
    "label": "写真",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "LtHDGdP3wB7IMuywRRLl",
    "type": "string",
    "value": "photoDoc"
  },
  {
    "_id": "JmYZtyoMPrzDvQVOHbbr",
    "isEnable": true,
    "label": "営業許可(自営業)",
    "level": 3,
    "orderId": 5,
    "parentFolderId": "dpVRGn0ozCdZMe57F0b1",
    "type": "string",
    "value": "business"
  },
  {
    "_id": "KQMxzKG2mNW8qnCH9urg",
    "isEnable": true,
    "label": "預金殘高証明書",
    "level": 3,
    "orderId": 6,
    "parentFolderId": "dpVRGn0ozCdZMe57F0b1",
    "type": "string",
    "value": "bank"
  },
  {
    "_id": "S1WQMEWgASIqL9bc55GV",
    "isEnable": true,
    "label": "親族公証書",
    "level": 3,
    "orderId": 2,
    "parentFolderId": "dpVRGn0ozCdZMe57F0b1",
    "type": "string",
    "value": "relative"
  },
  {
    "_id": "aaA65tYyNYgS13IPezMj",
    "isEnable": true,
    "label": "職業証明書(勤務者)",
    "level": 3,
    "orderId": 3,
    "parentFolderId": "dpVRGn0ozCdZMe57F0b1",
    "type": "string",
    "value": "occupation"
  },
  {
    "_id": "bcTPtTQn6VnZ4S1yF9R9",
    "isEnable": true,
    "label": "保証書",
    "level": 3,
    "orderId": 1,
    "parentFolderId": "dpVRGn0ozCdZMe57F0b1",
    "type": "string",
    "value": "guarantee"
  },
  {
    "_id": "ehKALfNE1YWfkSv8QWNR",
    "isEnable": true,
    "label": "收入証明書",
    "level": 3,
    "orderId": 4,
    "parentFolderId": "dpVRGn0ozCdZMe57F0b1",
    "type": "string",
    "value": "salary"
  },
  {
    "_id": "jnZvqJhcoXEMW9MQXUEa",
    "isEnable": true,
    "label": "経費支弁書",
    "level": 3,
    "orderId": 0,
    "parentFolderId": "dpVRGn0ozCdZMe57F0b1",
    "type": "string",
    "value": "payment"
  },
  {
    "_id": "zqV32wG5Lvf3NNnKPEMJ",
    "isEnable": true,
    "label": "不動產証明書",
    "level": 3,
    "orderId": 7,
    "parentFolderId": "dpVRGn0ozCdZMe57F0b1",
    "type": "string",
    "value": "property"
  }
];
export const str2 = [
  {
    "_id": "01SFEGZ7ns7KOaqy5c6a",
    "isEnable": true,
    "label": "2003",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2003"
  },
  {
    "_id": "06Iu9gkACiLRxcdvUcRH",
    "isEnable": true,
    "label": "2035",
    "level": 2,
    "orderId": 35,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2035"
  },
  {
    "_id": "091MUJzO3B7BPr61vJJR",
    "isEnable": true,
    "key": "insurancepaystatus",
    "label": "InsurancePayStatus",
    "level": 1,
    "orderId": 2,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "0BH0lYvy5ZQhGMuHz8it",
    "isEnable": true,
    "key": "season",
    "label": "Season",
    "level": 1,
    "orderId": 9,
    "parentFolderId": "kVLuNYSuO6Z8SyqsZVjv",
    "type": "folder"
  },
  {
    "_id": "0Ibtugyu0RE3B254mKK9",
    "isEnable": true,
    "label": "生理假",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "UgRNHBM4upvcOa9ikKKi",
    "type": "string",
    "value": "8"
  },
  {
    "_id": "0Q4cVZwPWT1oAbZ577lJ",
    "isEnable": true,
    "label": "A3",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "HohtX3A0efekc9n64Vig",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "0VDoJgSjY9iGGMYUxOe6",
    "isEnable": true,
    "label": "先生",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "RioTJI6ZjJV0xgnPveX0",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "0VSSrILDzACyb8oUZAax",
    "isEnable": true,
    "label": "16年",
    "level": 2,
    "orderId": 15,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "16"
  },
  {
    "_id": "0eHwiRDsEe2zgVqkNPLS",
    "isEnable": true,
    "key": "cmsstatus",
    "label": "CmsStatus",
    "level": 1,
    "orderId": 10,
    "parentFolderId": "9iLvnZvb6Flg656vKg1p",
    "type": "folder"
  },
  {
    "_id": "0eYSJttiuHCyDmSRPoKz",
    "isEnable": true,
    "label": "允許",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "hhh2ip159c6WrTMPhHgI",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "1D8EZoAgYF0GR3mVvzzQ",
    "isEnable": true,
    "label": "2030",
    "level": 2,
    "orderId": 30,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2030"
  },
  {
    "_id": "1GqPcMZPZCC23fhQG0w3",
    "isEnable": true,
    "label": "無",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "3TTmeHjiWD0RFQ78fZuK",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "1H8tfjwhcYf55YAYtzCl",
    "isEnable": true,
    "label": "Gold",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "KLXON0dFCEjTLmF72J2h",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "1Nq7MLzdOcwS78d4EbGY",
    "isEnable": true,
    "label": "匯款日 (可能有多個)",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "C6WYcUGfK9wLcrN7lQyT",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "1QH981GopmmUJz9rx1O1",
    "isEnable": true,
    "key": "optionPersonal",
    "label": "個人資料",
    "level": 0,
    "orderId": 1,
    "parentFolderId": "",
    "type": "folder"
  },
  {
    "_id": "1REglK2qlGRLIUs0ZH0U",
    "isEnable": true,
    "label": "傷假",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "g026G91IpBnrik927Ij7",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "1XzBqmRNiSyPfTFOwx7H",
    "isEnable": true,
    "label": "不可撤銷",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "aGHxadEHfDvFyEoiqLmk",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "1fcl7w3rUMMdk02Aczr1",
    "isEnable": true,
    "key": "invpurpose",
    "label": "InvPurpose",
    "level": 1,
    "orderId": 9,
    "parentFolderId": "1QH981GopmmUJz9rx1O1",
    "type": "folder"
  },
  {
    "_id": "1hylvZKACzLmp0ofHTjh",
    "isEnable": true,
    "label": "每年",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "LGSusAH0kV95Kdxpowgc",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "1ijrQRyX0pWKm3KI0n1w",
    "isEnable": true,
    "label": "婉拒(擔憂市場風險)",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "s28P6IZDghb0FkAqqw6m",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "1nLPmnuH3sHZpBx0aO5q",
    "isEnable": true,
    "label": "人民幣RMB",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "WAD0WiACxX5YyP9gy1XD",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "1ugXWaaC5pSP7AsQ7PAu",
    "isEnable": true,
    "label": "大學",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "ZRHYyDtCUwU1Du6DO29Y",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "1xbDRXSyPYxPaoFWpfxG",
    "isEnable": true,
    "key": "paytype",
    "label": "PayType",
    "level": 1,
    "orderId": 5,
    "parentFolderId": "FR7A6EMd5sbjGj5cMrL5",
    "type": "folder"
  },
  {
    "_id": "1yElcl1i0X1ZrzyIibUc",
    "isEnable": true,
    "label": "2006",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2006"
  },
  {
    "_id": "26oClXyQmTAw1tz3Dcxw",
    "isEnable": true,
    "label": "2017",
    "level": 2,
    "orderId": 17,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2017"
  },
  {
    "_id": "27oLEdoyyg9rh9kcvp8e",
    "isEnable": true,
    "label": "公傷病假",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "g026G91IpBnrik927Ij7",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "2C9sHOWAQTuK2VIjDX4Q",
    "isEnable": true,
    "label": "生效日",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "ZZAqUG88UhFEyXou9rCM",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "2Vst0PTSdzqPKMK9f6gO",
    "isEnable": true,
    "key": "portfoliotemplate",
    "label": "PortfolioTemplate",
    "level": 1,
    "orderId": 7,
    "parentFolderId": "kVLuNYSuO6Z8SyqsZVjv",
    "type": "folder"
  },
  {
    "_id": "2ZSqN6a8UjNHOI0U2vdL",
    "isEnable": true,
    "key": "auth1",
    "label": "Auth1",
    "level": 1,
    "orderId": 2,
    "parentFolderId": "y2m5huz3s2I2QVULc5nU",
    "type": "folder"
  },
  {
    "_id": "2ruMao73xzlEQeJ7z7tz",
    "isEnable": true,
    "label": "博士",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "RioTJI6ZjJV0xgnPveX0",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "3TTmeHjiWD0RFQ78fZuK",
    "isEnable": true,
    "key": "paymentfreqyear",
    "label": "PaymentFreqYear",
    "level": 1,
    "orderId": 21,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "3VjFqPdXfpeRmgQR5Wcb",
    "isEnable": true,
    "label": "成交",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "s28P6IZDghb0FkAqqw6m",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "3xNRlXAQE98TZFvIlkTw",
    "isEnable": true,
    "label": "失敗",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "Npf1jOzkwaftYgmkiVBQ",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "4BQu5LkYvCteSMRG9MeI",
    "isEnable": true,
    "label": "取消",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "ywtFvCsvf8AgjGBuiijM",
    "type": "string",
    "value": "8"
  },
  {
    "_id": "4JQRyokje0GAxQJ9UUze",
    "isEnable": true,
    "label": "公傷病假",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "UgRNHBM4upvcOa9ikKKi",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "4OUCdTrn3y7rQ4plRZ4K",
    "isEnable": true,
    "key": "optionAS",
    "label": "AfterService售後服務",
    "level": 0,
    "orderId": 6,
    "parentFolderId": "",
    "type": "folder"
  },
  {
    "_id": "4eR4RWdCRIqjv9KO3f6c",
    "isEnable": true,
    "key": "paymethod",
    "label": "PayMethod",
    "level": 1,
    "orderId": 17,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "4kpYs8mrRuqGal72vO1S",
    "isEnable": true,
    "label": "2026",
    "level": 2,
    "orderId": 26,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2026"
  },
  {
    "_id": "4vbMn0Kl3AzQO17giqGv",
    "isEnable": true,
    "label": "未婚",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "udOuC5Os60L5Sy19ehIV",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "50nV6X1ktyXG4RYy4wEx",
    "isEnable": true,
    "label": "草稿",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "ywtFvCsvf8AgjGBuiijM",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "51DczguxtBKJilcrGX4I",
    "isEnable": true,
    "label": "GrandTag",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "GHyIuOmNWA4pN62gxDmJ",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "55NATcglcI0hIbxnd4ts",
    "isEnable": true,
    "label": "2018",
    "level": 2,
    "orderId": 18,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2018"
  },
  {
    "_id": "5CogawNngn7w4LoOnBE8",
    "isEnable": true,
    "label": "Table 0",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "6x1RouTiTqicZIoNE3sx",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "5D5S9SgtwfXR9ZJSqGx1",
    "isEnable": true,
    "label": "Table B",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "6x1RouTiTqicZIoNE3sx",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "5JdmPmatpckhgMyAWIY4",
    "isEnable": true,
    "label": "N",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "v2STZ2mwKniebYOyOOJx",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "5SvoFjAoDTutCtJsET2x",
    "isEnable": true,
    "key": "authority",
    "label": "Authority",
    "level": 1,
    "orderId": 6,
    "parentFolderId": "y2m5huz3s2I2QVULc5nU",
    "type": "folder"
  },
  {
    "_id": "5YeKCKEftIZiOhY19VEV",
    "isEnable": true,
    "label": "半年",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "3TTmeHjiWD0RFQ78fZuK",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "63BmyzcpSYio5l0jYiwv",
    "isEnable": true,
    "label": "請代理人覆核",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "DVcEiO0fXJpRI5f6K72g",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "649ubbcDY5DDXM7tbVci",
    "isEnable": true,
    "key": "department2",
    "label": "Department2",
    "level": 1,
    "orderId": 4,
    "parentFolderId": "9iLvnZvb6Flg656vKg1p",
    "type": "folder"
  },
  {
    "_id": "6EpdlnK9i0jnTMpdzDPt",
    "isEnable": true,
    "label": "GBP",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "jXwaQM2dYng5RlSbURH6",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "6GuEBGuCRwXixBBbXbsk",
    "isEnable": true,
    "label": "讀取+編輯",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "CoDgfQmAefoDAIL5zq1U",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "6VV3TlDFetRB659DCxvP",
    "isEnable": true,
    "label": "end term return",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "a0VnVScNncIexgqawO4t",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "6ZpIl092crS1easQWik1",
    "isEnable": true,
    "label": "房產",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "TyzopuSOL4bgOcZI62RL",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "6fmdVvcq7BsqYcc9NNbI",
    "isEnable": true,
    "label": "生理假",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "g026G91IpBnrik927Ij7",
    "type": "string",
    "value": "8"
  },
  {
    "_id": "6ibxTKHrRxxV8Lyw0KNQ",
    "isEnable": true,
    "label": "4年",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "6iwOhVaMMTlVq5irqGgD",
    "isEnable": true,
    "label": "不扣薪",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "98Ai2w2UxKNPfuQPeLzz",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "6x1RouTiTqicZIoNE3sx",
    "isEnable": true,
    "key": "bodychecktype2",
    "label": "BodyCheckType2",
    "level": 1,
    "orderId": 13,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "71LbYdOBvf2rh054S3Gw",
    "isEnable": true,
    "label": "待處理",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "FROlrfDUnGyLWY4HzGEC",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "71t2VF8HDQYFfKh70bBD",
    "isEnable": true,
    "label": "台幣NTD",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "WAD0WiACxX5YyP9gy1XD",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "79KB7RjdRMTciGz3nGe3",
    "isEnable": true,
    "key": "optionDir",
    "label": "define",
    "level": 0,
    "orderId": 0,
    "parentFolderId": "",
    "type": "folder"
  },
  {
    "_id": "79dCyQGcL0kuRytJqdGg",
    "isEnable": true,
    "label": "NTD",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "jXwaQM2dYng5RlSbURH6",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "7EJ36ZCb7H1SZFH0DSZF",
    "isEnable": true,
    "label": "I",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "Lpury4Jxi9QDAbJvjmRr",
    "type": "string",
    "value": "I"
  },
  {
    "_id": "7GVmr2Hdj9pBmNBXX293",
    "isEnable": true,
    "label": "保險-Pru Life",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "2Vst0PTSdzqPKMK9f6gO",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "7OMn35H42Hu0Yt8bxsLR",
    "isEnable": true,
    "label": "在職",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "dw14F41gsXn3WiUQGSJi",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "7Q8icX8ZVx87cLe6xP6z",
    "isEnable": true,
    "label": "第三季",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "0BH0lYvy5ZQhGMuHz8it",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "7b9GinvuMV0LSTsI6Qs4",
    "isEnable": true,
    "key": "paymentfreq",
    "label": "PaymentFreq",
    "level": 1,
    "orderId": 16,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "7jP8UGNgot8usH5AVjXZ",
    "isEnable": true,
    "label": "10%以下",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "e1cVBCJxc3kZcvszpR1x",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "7jXhk1yQBZFavoBm31oR",
    "isEnable": true,
    "label": "一年",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "3TTmeHjiWD0RFQ78fZuK",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "7suOZarTml7a2KMYVmqs",
    "isEnable": true,
    "label": "處理中 (Hanbury)",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "ywtFvCsvf8AgjGBuiijM",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "81B79uKy3unebWgs5pLA",
    "isEnable": true,
    "label": "等待中",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "Npf1jOzkwaftYgmkiVBQ",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "88XqsqDS78m0HlkvQrn1",
    "isEnable": true,
    "label": "港幣HKD",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "mVvuuf85PRSksvOtDqib",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "8A6PJBl4tXCr8LGwx6Sk",
    "isEnable": true,
    "label": "已生效",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "lLwmxE1E8ZEOQ9zQjhu6",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "8BrbqXGZXUr5GwjzWIFn",
    "isEnable": true,
    "label": "2032",
    "level": 2,
    "orderId": 32,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2032"
  },
  {
    "_id": "8GqJTnA6hbc567G7qgcj",
    "isEnable": true,
    "label": "禁止",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "CoDgfQmAefoDAIL5zq1U",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "8NEcsexS8KsP50BupY8h",
    "isEnable": true,
    "label": "Hanbursing",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "n7ZWMsPwEsf28yaDJGcb",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "8QiYhicH2HiuWOY02rdV",
    "isEnable": true,
    "label": "JPY",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "jXwaQM2dYng5RlSbURH6",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "8RkqseFYVsF0GCpp7xgm",
    "isEnable": true,
    "label": "3 客戶管理",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "5SvoFjAoDTutCtJsET2x",
    "type": "string",
    "value": "auth_cus"
  },
  {
    "_id": "8eSphGcKED3Lm5ZZmLqw",
    "isEnable": true,
    "key": "nowphase",
    "label": "NowPhase",
    "level": 1,
    "orderId": 5,
    "parentFolderId": "kVLuNYSuO6Z8SyqsZVjv",
    "type": "folder"
  },
  {
    "_id": "8fVem1xxhHHdqtElcJV0",
    "isEnable": true,
    "label": "美金USD",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "mVvuuf85PRSksvOtDqib",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "8sEOLkiLxjqY7JO3nKnQ",
    "isEnable": true,
    "label": "其他",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "1fcl7w3rUMMdk02Aczr1",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "91nZw0yCyw8831qMn0Ok",
    "isEnable": true,
    "label": "業務",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "649ubbcDY5DDXM7tbVci",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "91u2EyNX4nO8Tw5EAtbU",
    "isEnable": true,
    "label": "申請中",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "8eSphGcKED3Lm5ZZmLqw",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "954umFOO8N415XBaX2HJ",
    "isEnable": true,
    "label": "關閉",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "VbO76bRadWX3okZvMXqC",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "98Ai2w2UxKNPfuQPeLzz",
    "isEnable": true,
    "key": "deductions",
    "label": "Deductions",
    "level": 1,
    "orderId": 8,
    "parentFolderId": "9iLvnZvb6Flg656vKg1p",
    "type": "folder"
  },
  {
    "_id": "9KgeSJbdDKVfoFXE9NMQ",
    "isEnable": true,
    "label": "8年",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "8"
  },
  {
    "_id": "9LGVyx9sS1r9VvRWXV9B",
    "isEnable": true,
    "label": "Agent Leader",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "xD8JURe8xQMPdzTFq6Mr",
    "type": "string",
    "value": "20"
  },
  {
    "_id": "9YY1pwuh3SMtiH9Cea7Y",
    "isEnable": true,
    "label": "女",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "jPxx4IL20ft8SgFc9zik",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "9ZwYMbLArSX76Ybj9D54",
    "isEnable": true,
    "label": "英國",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "MxUfjvFRkhHHsxzyXS3B",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "9cTKI13QDwSjBtFDuhG0",
    "isEnable": true,
    "label": "送交主管簽核",
    "level": 2,
    "note1": "",
    "orderId": 1,
    "parentFolderId": "cEx6utSS7u95FPuZb6oE",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "9d7Z4oZeVxSzMXxgOgq5",
    "isEnable": true,
    "key": "talktype",
    "label": "TalkType",
    "level": 1,
    "orderId": 12,
    "parentFolderId": "1QH981GopmmUJz9rx1O1",
    "type": "folder"
  },
  {
    "_id": "9eTsyqLmhIjAFS6QIYD8",
    "isEnable": true,
    "label": "Credit Card",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "mQtPQGdmmWrThA7l7nWy",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "9iLvnZvb6Flg656vKg1p",
    "isEnable": true,
    "key": "optionHR",
    "label": "人事",
    "level": 0,
    "orderId": 4,
    "parentFolderId": "",
    "type": "folder"
  },
  {
    "_id": "9jy04LsnlI5o181VBN7Q",
    "isEnable": true,
    "label": "讀取+編輯+刪除",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "CoDgfQmAefoDAIL5zq1U",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "9lPaxM0BHlnCsYiPDdS6",
    "isEnable": true,
    "label": "11月",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "C0LV6T0JyglB8po4VcPC",
    "type": "string",
    "value": "11"
  },
  {
    "_id": "9tgTypt8urC0Q8Av6AX4",
    "isEnable": true,
    "label": "24",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "h5YgGWPEhnbpcLG1U4Q9",
    "type": "string",
    "value": "24"
  },
  {
    "_id": "AIgprgOC8EWHxqcibJXo",
    "isEnable": true,
    "label": "RMB",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "jXwaQM2dYng5RlSbURH6",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "ASDWeiIg1YI77zcp6Ahw",
    "isEnable": true,
    "key": "payperiodfpi",
    "label": "PayPeriodFPI",
    "level": 1,
    "orderId": 10,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "AXtnJQLeduMm1TpC5glY",
    "isEnable": true,
    "key": "insurancestatus",
    "label": "InsuranceStatus",
    "level": 1,
    "orderId": 0,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "AncyLy3Zo8RcsvqmtMym",
    "isEnable": true,
    "label": "2031",
    "level": 2,
    "orderId": 31,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2031"
  },
  {
    "_id": "BFg3wUSqtOaNFZceZtK4",
    "isEnable": true,
    "label": "取消排程",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "OMxJTImT5pftkUO4eENU",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "BNLUBxGHgjOlkdATgpeO",
    "isEnable": true,
    "label": "SPNT",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "YoLU5My0pyWDMeQFNgow",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "BlJmDtpHpEcKpiA5b1sK",
    "isEnable": true,
    "label": "陪產假",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "UgRNHBM4upvcOa9ikKKi",
    "type": "string",
    "value": "9"
  },
  {
    "_id": "C0LV6T0JyglB8po4VcPC",
    "isEnable": true,
    "key": "paymonthnum",
    "label": "PayMonthNum",
    "level": 1,
    "orderId": 22,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "C5OGOz9hPwI6DRHuNA2R",
    "isEnable": true,
    "label": "5月",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "C0LV6T0JyglB8po4VcPC",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "C6WYcUGfK9wLcrN7lQyT",
    "isEnable": true,
    "key": "icstartday",
    "label": "ICStartDay",
    "level": 1,
    "orderId": 5,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "CEOxlcdAeAknP66TFVXZ",
    "isEnable": true,
    "label": "12月",
    "level": 2,
    "orderId": 12,
    "parentFolderId": "C0LV6T0JyglB8po4VcPC",
    "type": "string",
    "value": "12"
  },
  {
    "_id": "CFiDHmJ0Ps07jENR1iqP",
    "isEnable": true,
    "label": "其他",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "puDSUdLzfTM3Mb75NbJI",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "CU803Pduuof1MDMGV3rP",
    "isEnable": true,
    "label": "客群經營 - 餐費",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "HpBSrWCCqjaWJrXb2ULO",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "CXFtSe5DN4WSZCAM5A2c",
    "isEnable": true,
    "label": "Table A",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "6x1RouTiTqicZIoNE3sx",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "CXfQz0XsYwxKzjbwDakh",
    "isEnable": true,
    "key": "day_off_status_fourth",
    "label": "Day_off_status_fourth",
    "level": 1,
    "orderId": 7,
    "parentFolderId": "9iLvnZvb6Flg656vKg1p",
    "type": "folder"
  },
  {
    "_id": "CZ9IJxClTuNepRiayNUb",
    "isEnable": true,
    "label": "4-1 案件追蹤",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "5SvoFjAoDTutCtJsET2x",
    "type": "string",
    "value": "auth_as1"
  },
  {
    "_id": "CoDgfQmAefoDAIL5zq1U",
    "isEnable": true,
    "key": "auth4",
    "label": "Auth4",
    "level": 1,
    "orderId": 5,
    "parentFolderId": "y2m5huz3s2I2QVULc5nU",
    "type": "folder"
  },
  {
    "_id": "CtkwGyROoBDForUhl3Im",
    "isEnable": true,
    "label": "2月",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "aF96UbqIOzd4TSEs1Yum",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "Cvda3FWah63g0kDd8KBR",
    "isEnable": true,
    "label": "五年以上",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "mE2qqmOGhlKgQRS6O4TD",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "CvmdrbWex9QgOC1KkUrt",
    "isEnable": true,
    "label": "加拿大",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "ZopEwsQUxKgfzFlZO30L",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "Cvs4MC73zY9Oy459eCEJ",
    "isEnable": true,
    "label": "填寫中",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "lLwmxE1E8ZEOQ9zQjhu6",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "CxpmHxIYMLqlB9E9QskN",
    "isEnable": true,
    "label": "英國房產-產權",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "2Vst0PTSdzqPKMK9f6gO",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "D24pRVmN5O1p0xofmBLI",
    "isEnable": true,
    "label": "1月",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "C0LV6T0JyglB8po4VcPC",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "DK4PMa3MDlFaU05rNz2S",
    "isEnable": true,
    "label": "無",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "HpBSrWCCqjaWJrXb2ULO",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "DN89fXIlr7hTF0UOzy8U",
    "isEnable": true,
    "label": "未繳費",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "puDSUdLzfTM3Mb75NbJI",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "DTHEsD9vkIGyp1X5g9MI",
    "isEnable": true,
    "label": "2007",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2007"
  },
  {
    "_id": "DVcEiO0fXJpRI5f6K72g",
    "isEnable": true,
    "key": "day_off_status_first",
    "label": "Day_off_status_first",
    "level": 1,
    "orderId": 2,
    "parentFolderId": "9iLvnZvb6Flg656vKg1p",
    "type": "folder"
  },
  {
    "_id": "DgEwt9jN9vSxoRl83SBu",
    "isEnable": true,
    "label": "行政主管",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "xD8JURe8xQMPdzTFq6Mr",
    "type": "string",
    "value": "40"
  },
  {
    "_id": "Dx2Ne7s0k86aVvP96T5S",
    "isEnable": true,
    "label": "20%以上",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "wouj1gsi1dw1K5e4yyVZ",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "E6LGYecGuHUltFBRYF7d",
    "isEnable": true,
    "label": "碩士",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "ZRHYyDtCUwU1Du6DO29Y",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "EByYc1pnBbwg685BrzlS",
    "isEnable": true,
    "label": "2016",
    "level": 2,
    "orderId": 16,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2016"
  },
  {
    "_id": "EUPrAAGZ6BSNTBIN6rnl",
    "isEnable": true,
    "label": "公假",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "UgRNHBM4upvcOa9ikKKi",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "EUSvJ19INamfsd0lav1b",
    "isEnable": true,
    "key": "statusres",
    "label": "StatusRes",
    "level": 1,
    "orderId": 11,
    "parentFolderId": "kVLuNYSuO6Z8SyqsZVjv",
    "type": "folder"
  },
  {
    "_id": "EfhK3nOimjAAaMqw2bA2",
    "isEnable": true,
    "label": "男",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "jPxx4IL20ft8SgFc9zik",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "EqxeaRv4rqUIUPLtQgPZ",
    "isEnable": true,
    "label": "提交已生效",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "CXfQz0XsYwxKzjbwDakh",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "EuYKiw96ItqtHVYVxDHS",
    "isEnable": true,
    "label": "未發佣",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "0eHwiRDsEe2zgVqkNPLS",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "F38VuTf9JfjoXurvzGk5",
    "isEnable": true,
    "label": "已生效",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "EUSvJ19INamfsd0lav1b",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "F8cCzDm84RDMFJ8nrpdG",
    "isEnable": true,
    "label": "6 網站管理",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "5SvoFjAoDTutCtJsET2x",
    "type": "string",
    "value": "auth_web"
  },
  {
    "_id": "FLC4s3KgIMrwh3jXiS9L",
    "isEnable": true,
    "label": "系統開發者",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "xD8JURe8xQMPdzTFq6Mr",
    "type": "string",
    "value": "95"
  },
  {
    "_id": "FR7A6EMd5sbjGj5cMrL5",
    "isEnable": true,
    "key": "optionAccount",
    "label": "會計相關",
    "level": 0,
    "orderId": 2,
    "parentFolderId": "",
    "type": "folder"
  },
  {
    "_id": "FROlrfDUnGyLWY4HzGEC",
    "isEnable": true,
    "key": "statusp5",
    "label": "StatusP5",
    "level": 1,
    "orderId": 6,
    "parentFolderId": "79KB7RjdRMTciGz3nGe3",
    "type": "folder"
  },
  {
    "_id": "FkxVuz1NwRYfE2ZzcbgQ",
    "isEnable": true,
    "label": "代理人覆核中",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "cEx6utSS7u95FPuZb6oE",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "FwYqLly1imj7dDUOrIAg",
    "isEnable": true,
    "label": "有",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "pQnZagIfmOR5kH6MMDMi",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "Fx73vwsVgMqD8sDNFJMO",
    "isEnable": true,
    "label": "特休假",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "g026G91IpBnrik927Ij7",
    "type": "string",
    "value": "10"
  },
  {
    "_id": "GF5trsd9HGua1whU7m5s",
    "isEnable": true,
    "label": "Table C",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "6x1RouTiTqicZIoNE3sx",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "GHyIuOmNWA4pN62gxDmJ",
    "isEnable": true,
    "key": "asactionowner",
    "label": "ASActionOwner",
    "level": 1,
    "orderId": 2,
    "parentFolderId": "4OUCdTrn3y7rQ4plRZ4K",
    "type": "folder"
  },
  {
    "_id": "Gh3X65HYMIzhZtjRKaUu",
    "isEnable": true,
    "label": "取消本申請",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "cEx6utSS7u95FPuZb6oE",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "GyyqTRLQ64jcHaslXYGx",
    "isEnable": true,
    "label": "台灣",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "LfABgDIYpY0d5rrg5rLU",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "HCO8iGyxhnh9q2kB3qSj",
    "isEnable": true,
    "label": "讀取",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "2ZSqN6a8UjNHOI0U2vdL",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "HGYi9aVo1S9PVLZmyLjw",
    "isEnable": true,
    "label": "客群經營 - 贈禮(個人特殊節日)",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "HpBSrWCCqjaWJrXb2ULO",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "HJL7co9hGYSnaIZDSFBt",
    "isEnable": true,
    "label": "Preferred Best",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "izq7BzxYYNkkl2aQZ8Ix",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "HV7zAI5baXEpbJM553VY",
    "isEnable": true,
    "label": "女士",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "HgkDNENhnicoaSjhLwbf",
    "isEnable": true,
    "label": "4-2 保費提醒",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "5SvoFjAoDTutCtJsET2x",
    "type": "string",
    "value": "auth_as2"
  },
  {
    "_id": "HohtX3A0efekc9n64Vig",
    "isEnable": true,
    "key": "pagesize",
    "label": "PageSize",
    "level": 1,
    "orderId": 20,
    "parentFolderId": "79KB7RjdRMTciGz3nGe3",
    "type": "folder"
  },
  {
    "_id": "HpBSrWCCqjaWJrXb2ULO",
    "isEnable": true,
    "key": "costtype",
    "label": "CostType",
    "level": 1,
    "orderId": 7,
    "parentFolderId": "FR7A6EMd5sbjGj5cMrL5",
    "type": "folder"
  },
  {
    "_id": "Hv2z6hC7ZqLK80LRVX5Y",
    "isEnable": true,
    "label": "HKD",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "jXwaQM2dYng5RlSbURH6",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "IDNiICbsifsceg4jXGv8",
    "isEnable": true,
    "label": "人資簽核中",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "CXfQz0XsYwxKzjbwDakh",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "ITxS1hnJKAhJIT7KXg3n",
    "isEnable": true,
    "label": "1年",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "IXutIfCjj8NnTerhVcyq",
    "isEnable": true,
    "label": "股票",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "2Vst0PTSdzqPKMK9f6gO",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "IahwsL0NOiS6Y7g3DzFB",
    "isEnable": true,
    "label": "事假",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "g026G91IpBnrik927Ij7",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "IfMUJvxRa8zEmdJEMskV",
    "isEnable": true,
    "key": "asaction",
    "label": "ASAction",
    "level": 1,
    "orderId": 4,
    "parentFolderId": "4OUCdTrn3y7rQ4plRZ4K",
    "type": "folder"
  },
  {
    "_id": "JAqQL1rpZEcGceq512XY",
    "isEnable": true,
    "label": "2027",
    "level": 2,
    "orderId": 27,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2027"
  },
  {
    "_id": "JM6TFg4kIjsvDL5mrNtx",
    "isEnable": true,
    "label": "TT",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "j7BlDnzkt35FytFJyRKZ",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "Jd7OEZSAjUJ0ytHX8Nb3",
    "isEnable": true,
    "label": "每半年",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "LGSusAH0kV95Kdxpowgc",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "JguIQElEam2PwNgpH8Os",
    "isEnable": true,
    "label": "填寫中",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "DVcEiO0fXJpRI5f6K72g",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "JkxL7W6yf3TWJ1sZYvzZ",
    "isEnable": true,
    "label": "0月",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "C0LV6T0JyglB8po4VcPC",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "KIHtncdFFF0Ks0PRVfQ7",
    "isEnable": true,
    "key": "halfyear",
    "label": "HalfYear",
    "level": 1,
    "orderId": 10,
    "parentFolderId": "kVLuNYSuO6Z8SyqsZVjv",
    "type": "folder"
  },
  {
    "_id": "KLXON0dFCEjTLmF72J2h",
    "isEnable": true,
    "key": "commissionlevel",
    "label": "CommissionLevel",
    "level": 1,
    "orderId": 12,
    "parentFolderId": "9iLvnZvb6Flg656vKg1p",
    "type": "folder"
  },
  {
    "_id": "KTmZ3vL4ZS2XbZtOVRmn",
    "isEnable": true,
    "label": "讀取+編輯",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "2ZSqN6a8UjNHOI0U2vdL",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "KUUA4e5Marcm5QhpoKGQ",
    "isEnable": true,
    "label": "3月",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "aF96UbqIOzd4TSEs1Yum",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "KWGuqgAuLFXXfpYZsvNh",
    "isEnable": true,
    "label": "9月",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "C0LV6T0JyglB8po4VcPC",
    "type": "string",
    "value": "9"
  },
  {
    "_id": "KbIPko3cGPDKYxLWZtkw",
    "isEnable": true,
    "label": "財務",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "649ubbcDY5DDXM7tbVci",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "KbQUottOu7VjPNG0YMqL",
    "isEnable": true,
    "label": "產權",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "dnmfVY9pzREMP4WzhcHO",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "KdvEMn81ZzFoGlE2RBlU",
    "isEnable": true,
    "label": "電話",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "9d7Z4oZeVxSzMXxgOgq5",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "KrRMYrxhUtV8CtHLu04V",
    "isEnable": true,
    "label": "申請中",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "V6gcD6UyFNHbLfmC2KaR",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "KtWujDTUCiEybz3lYTYF",
    "isEnable": true,
    "label": "第三方資產管理",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "TyzopuSOL4bgOcZI62RL",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "L0cu1uco1AoewgVafSln",
    "isEnable": true,
    "label": "台幣NTD",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "mVvuuf85PRSksvOtDqib",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "L2odXF9eUQEqWmbpsgaU",
    "isEnable": true,
    "label": "病假",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "g026G91IpBnrik927Ij7",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "LCNLPJxdl1DkRcJUQFWY",
    "isEnable": true,
    "label": "無效",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "EUSvJ19INamfsd0lav1b",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "LF22HqbU7RkSnDxKKoZn",
    "isEnable": true,
    "label": "已生效",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "8eSphGcKED3Lm5ZZmLqw",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "LGSusAH0kV95Kdxpowgc",
    "isEnable": true,
    "key": "icperiod2",
    "label": "ICPeriod2",
    "level": 1,
    "orderId": 7,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "LHkDaZ0oTjIxCe6tSyiO",
    "isEnable": true,
    "label": "小姐",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "RioTJI6ZjJV0xgnPveX0",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "LIPwCBYGkgitU0N7h0UF",
    "isEnable": true,
    "label": "AVIVA/Zurich/Standardlife/MassMutual/AIA",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "2Vst0PTSdzqPKMK9f6gO",
    "type": "string",
    "value": "8"
  },
  {
    "_id": "LSxsjHQi7EahsdmsZsHz",
    "isEnable": true,
    "label": "Yearly(12月底)",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "sM1qbvLFAEUBzb0SxD4v",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "LWsk6UwfyP4yKnQNTRRR",
    "isEnable": true,
    "label": "Aaron",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "vXF6bRwR1njuif2mVAeu",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "LaCwxycD5Cuv3qTCaIkm",
    "isEnable": true,
    "label": "11年",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "11"
  },
  {
    "_id": "LfABgDIYpY0d5rrg5rLU",
    "isEnable": true,
    "key": "countries",
    "label": "Countries",
    "level": 1,
    "orderId": 5,
    "parentFolderId": "1QH981GopmmUJz9rx1O1",
    "type": "folder"
  },
  {
    "_id": "LgV27oE0AGOlx313e4qE",
    "isEnable": true,
    "label": "GBP",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "agLKH7v9Ir0OdPsMXrei",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "LjFWz2qDUV2CoIcv3oIo",
    "isEnable": true,
    "label": "已發佣",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "0eHwiRDsEe2zgVqkNPLS",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "Lpury4Jxi9QDAbJvjmRr",
    "isEnable": true,
    "key": "unittype",
    "label": "UnitType",
    "level": 1,
    "orderId": 3,
    "parentFolderId": "79KB7RjdRMTciGz3nGe3",
    "type": "folder"
  },
  {
    "_id": "LyWAFlzvsvasA0zaSZK8",
    "isEnable": true,
    "label": "no buy back",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "a0VnVScNncIexgqawO4t",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "MGCVPoWK6BfRppilorJt",
    "isEnable": true,
    "label": "補休假",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "UgRNHBM4upvcOa9ikKKi",
    "type": "string",
    "value": "12"
  },
  {
    "_id": "MQLJ6upv49JOdwy1fg8q",
    "isEnable": true,
    "label": "財務行政主管",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "xD8JURe8xQMPdzTFq6Mr",
    "type": "string",
    "value": "42"
  },
  {
    "_id": "MnZ54rFyiDSVX5csD5X9",
    "isEnable": true,
    "label": "僅維繫客戶",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "s28P6IZDghb0FkAqqw6m",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "MxUfjvFRkhHHsxzyXS3B",
    "isEnable": true,
    "key": "countries2",
    "label": "Countries2",
    "level": 1,
    "orderId": 6,
    "parentFolderId": "1QH981GopmmUJz9rx1O1",
    "type": "folder"
  },
  {
    "_id": "MxbM7nybiZy06uoBBGyh",
    "isEnable": true,
    "label": "每月",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "LGSusAH0kV95Kdxpowgc",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "MywwUwz61lwKZI0zgItS",
    "isEnable": true,
    "label": "人民幣RMB",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "mVvuuf85PRSksvOtDqib",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "NY7dPGMyZbwG3ZINjAm0",
    "isEnable": true,
    "key": "stockitem",
    "label": "StockItem",
    "level": 1,
    "orderId": 18,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "NYWqjKn8wFnifRr8lwu1",
    "isEnable": true,
    "label": "1 財務&績效管理",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "5SvoFjAoDTutCtJsET2x",
    "type": "string",
    "value": "auth_fin"
  },
  {
    "_id": "NdyLW51DSgYQXwxmdnxl",
    "isEnable": true,
    "label": "2004",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2004"
  },
  {
    "_id": "NhEbRIP9g0dC5qxFuAcu",
    "isEnable": true,
    "label": "申請中",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "FROlrfDUnGyLWY4HzGEC",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "NmljubSwp0DGYj3qhmUq",
    "isEnable": true,
    "label": "美金USD",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "WAD0WiACxX5YyP9gy1XD",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "Npf1jOzkwaftYgmkiVBQ",
    "isEnable": true,
    "key": "mailsendresult",
    "label": "Mailsendresult",
    "level": 1,
    "orderId": 7,
    "parentFolderId": "4OUCdTrn3y7rQ4plRZ4K",
    "type": "folder"
  },
  {
    "_id": "O0W8bjcM6jHQtupcMQg4",
    "isEnable": true,
    "label": "管理者",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "xD8JURe8xQMPdzTFq6Mr",
    "type": "string",
    "value": "99"
  },
  {
    "_id": "O1lvwq0oLjw6Uk955809",
    "isEnable": true,
    "label": "已完成",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "V6gcD6UyFNHbLfmC2KaR",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "OCPl6vDcVjeuXYDbM0uW",
    "isEnable": true,
    "label": "佣金管理者",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "xD8JURe8xQMPdzTFq6Mr",
    "type": "string",
    "value": "90"
  },
  {
    "_id": "OMxJTImT5pftkUO4eENU",
    "isEnable": true,
    "key": "mailsended",
    "label": "Mailsended",
    "level": 1,
    "orderId": 3,
    "parentFolderId": "4OUCdTrn3y7rQ4plRZ4K",
    "type": "folder"
  },
  {
    "_id": "OaBOnCQp6tkmCPlo1HTS",
    "isEnable": true,
    "label": "遺產規劃",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "1fcl7w3rUMMdk02Aczr1",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "OiNWI2MeP5Vn05tEXkES",
    "isEnable": true,
    "label": "6月",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "C0LV6T0JyglB8po4VcPC",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "OjpuCNJJtFNjdjQrGGFl",
    "isEnable": true,
    "label": "客群經營 - 住宿",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "HpBSrWCCqjaWJrXb2ULO",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "PCSAtACHyC8ygohkBK9e",
    "isEnable": true,
    "label": "4-3 產品配息調整",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "5SvoFjAoDTutCtJsET2x",
    "type": "string",
    "value": "auth_as3"
  },
  {
    "_id": "PEiDNc0NJIBAn3fZPFiu",
    "isEnable": true,
    "label": "日圓JPY",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "mVvuuf85PRSksvOtDqib",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "PSII8Zt9L0D6IpWJxZ1A",
    "isEnable": true,
    "label": "直接寄送",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "egPhwVMchwT4A3gqvAGh",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "PVF4NGJZ8La7Dxf2FwFM",
    "isEnable": true,
    "label": "E-Mail",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "9d7Z4oZeVxSzMXxgOgq5",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "PbWbAdxj0a9NZL6pXeTN",
    "isEnable": true,
    "label": "USD",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "jXwaQM2dYng5RlSbURH6",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "PnRW5MlDxWvikuyYrvwk",
    "isEnable": true,
    "label": "讀取",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "loWPiOcQxFvCArrgOTjT",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "PxgLtgQDRNxkpSnFvoql",
    "isEnable": true,
    "label": "Single Premium",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "ASDWeiIg1YI77zcp6Ahw",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "QNSGWzoIN9W02V36u6lF",
    "isEnable": true,
    "label": "無",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "pQnZagIfmOR5kH6MMDMi",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "QTfpTnIx8jBD4S7DimEP",
    "isEnable": true,
    "label": "Diamond",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "KLXON0dFCEjTLmF72J2h",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "QYKlpGalVAVmag4clRgF",
    "isEnable": true,
    "label": "上海",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "MxUfjvFRkhHHsxzyXS3B",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "QaZU0g95CyhFUuEVkk0E",
    "isEnable": true,
    "label": "RMB",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "agLKH7v9Ir0OdPsMXrei",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "Qe8kmOmZSMimI9rlrhFh",
    "isEnable": true,
    "label": "6月",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "aF96UbqIOzd4TSEs1Yum",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "QlVFOuMFSK3taWMBn1Gc",
    "isEnable": true,
    "label": "Monthly",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "ASDWeiIg1YI77zcp6Ahw",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "QswAKWa2kdwtWjhIA58z",
    "isEnable": true,
    "label": "英國房產-股權",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "2Vst0PTSdzqPKMK9f6gO",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "R3lTQmLZtsMAnNmOrRCr",
    "isEnable": true,
    "label": "售後服務",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "",
    "type": "string",
    "value": "9"
  },
  {
    "_id": "R4yyLhfAxCS7hfJgyO1V",
    "isEnable": true,
    "label": "日圓JPY",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "WAD0WiACxX5YyP9gy1XD",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "R6JoKVOE1R2V3T3bO73B",
    "isEnable": true,
    "label": "PNT",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "YoLU5My0pyWDMeQFNgow",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "R6g1AieGhfhFrw6cpi4y",
    "isEnable": true,
    "label": " ",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "h5YgGWPEhnbpcLG1U4Q9",
    "type": "string",
    "value": " "
  },
  {
    "_id": "R7vLjNjvG14S8JvBawoH",
    "isEnable": true,
    "label": "台灣",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "MxUfjvFRkhHHsxzyXS3B",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "R8xclZjGHN0rc33Nfhzn",
    "isEnable": true,
    "label": "顯示樣版無定義",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "2Vst0PTSdzqPKMK9f6gO",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "RBpX5Bee55dCWDaBUnfX",
    "isEnable": true,
    "label": "2001",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2001"
  },
  {
    "_id": "RDFlgFdaniMvqWTbKC8Y",
    "isEnable": true,
    "label": "排程",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "egPhwVMchwT4A3gqvAGh",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "RV50hqShbdaugYM4pAng",
    "isEnable": true,
    "label": "申請中",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "RdMJkka5hsPbLaAC3Y0L",
    "isEnable": true,
    "label": "禁止",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "loWPiOcQxFvCArrgOTjT",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "RioTJI6ZjJV0xgnPveX0",
    "isEnable": true,
    "key": "title",
    "label": "Title稱謂",
    "level": 1,
    "orderId": 2,
    "parentFolderId": "1QH981GopmmUJz9rx1O1",
    "type": "folder"
  },
  {
    "_id": "RpkkPNPuPS91YiitMJrz",
    "isEnable": true,
    "label": "男",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "dN4v0mbk2joUHwR23E3O",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "SSrdtcCw8AD7FwXdbPZn",
    "isEnable": true,
    "label": "已完成",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "ywtFvCsvf8AgjGBuiijM",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "SU09XSmSIfTeB0N8vMsl",
    "isEnable": true,
    "label": "繳款中Premium paying",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "091MUJzO3B7BPr61vJJR",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "SUz4PZiiJ5JRlHMzdxva",
    "isEnable": true,
    "label": "終止繳費Paid up",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "091MUJzO3B7BPr61vJJR",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "SZeKApQ7b7f2ROauVi4X",
    "isEnable": true,
    "label": "英磅GBP",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "mVvuuf85PRSksvOtDqib",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "ScmGQzlIfFqZg1EWjSuz",
    "isEnable": true,
    "label": "加幣",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "SwsadbBPzVGoZHRex6u4",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "SeUu2giB1EVLFSQAz0So",
    "isEnable": true,
    "label": "BSO",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "mQtPQGdmmWrThA7l7nWy",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "SgA3AemDCKUOOY4AAN4Y",
    "isEnable": true,
    "label": "每季",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "LGSusAH0kV95Kdxpowgc",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "SkeWZ9tY43ta9xcokBU9",
    "isEnable": true,
    "label": "18年",
    "level": 2,
    "orderId": 17,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "18"
  },
  {
    "_id": "Sr7IsrdMgRs6niwa7VqA",
    "isEnable": true,
    "label": "安養院",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "TyzopuSOL4bgOcZI62RL",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "SwsadbBPzVGoZHRex6u4",
    "isEnable": true,
    "key": "p5dollar",
    "label": "P5Dollar",
    "level": 1,
    "orderId": 8,
    "parentFolderId": "79KB7RjdRMTciGz3nGe3",
    "type": "folder"
  },
  {
    "_id": "TGRFzVwr42J5zFXE741h",
    "isEnable": true,
    "label": "上半年",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "KIHtncdFFF0Ks0PRVfQ7",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "TKJPIKZr72W5JSCYVbi5",
    "isEnable": true,
    "label": "傷假",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "UgRNHBM4upvcOa9ikKKi",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "TUKD9l7R5Y8XBVzXGRsr",
    "isEnable": true,
    "label": "Yearly(生效日)",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "sM1qbvLFAEUBzb0SxD4v",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "TdFepIIxC5vppp4WheNG",
    "isEnable": true,
    "label": "公司-零用金",
    "level": 2,
    "note1": "",
    "orderId": 3,
    "parentFolderId": "vXF6bRwR1njuif2mVAeu",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "TdIN5RyFgRmAEA4U9Kfs",
    "isEnable": true,
    "label": "2005",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2005"
  },
  {
    "_id": "TyzopuSOL4bgOcZI62RL",
    "isEnable": true,
    "key": "productclass",
    "label": "ProductClass",
    "level": 1,
    "orderId": 11,
    "parentFolderId": "79KB7RjdRMTciGz3nGe3",
    "type": "folder"
  },
  {
    "_id": "U5MvHx9ZdM0pFFmWBoFr",
    "isEnable": true,
    "label": "2028",
    "level": 2,
    "orderId": 28,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2028"
  },
  {
    "_id": "UATeXz8fZqYPeN7Co2kL",
    "isEnable": true,
    "label": "buy back",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "a0VnVScNncIexgqawO4t",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "UGgqw8KtgCwguxI4i9Ly",
    "isEnable": true,
    "label": "Table D",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "6x1RouTiTqicZIoNE3sx",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "UPfxVUwv6gnwkL8uAo9A",
    "isEnable": true,
    "label": "Active",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "tXy1PcrY6jYsWkLbqT7Y",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "UWAEfe0cPUYoieBUYJrS",
    "isEnable": true,
    "label": "NTD",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "agLKH7v9Ir0OdPsMXrei",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "UePMs1BmrRITcNl5zTEp",
    "isEnable": true,
    "label": "公司-富邦",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "vXF6bRwR1njuif2mVAeu",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "UgRNHBM4upvcOa9ikKKi",
    "isEnable": true,
    "key": "day_off_set",
    "label": "Day_off_set",
    "level": 1,
    "orderId": 1,
    "parentFolderId": "9iLvnZvb6Flg656vKg1p",
    "type": "folder"
  },
  {
    "_id": "Uhj5KlspLY3Ej3RY0HxQ",
    "isEnable": true,
    "label": "高中",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "ZRHYyDtCUwU1Du6DO29Y",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "UnjKNsyyDYpWl0qxtNOg",
    "isEnable": true,
    "label": "美國",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "MxUfjvFRkhHHsxzyXS3B",
    "type": "string",
    "value": "9"
  },
  {
    "_id": "UpM07klFCO1tT7OFhEVO",
    "isEnable": true,
    "label": "離職",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "dw14F41gsXn3WiUQGSJi",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "Uq8PK10QXmprADfRWhnd",
    "isEnable": true,
    "label": "Y",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "v2STZ2mwKniebYOyOOJx",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "UvTp7t1NY2LshB8qX2fN",
    "isEnable": true,
    "key": "payyearnum",
    "label": "PayYearNum",
    "level": 1,
    "orderId": 20,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "V6gcD6UyFNHbLfmC2KaR",
    "isEnable": true,
    "key": "asphase",
    "label": "ASPhase",
    "level": 1,
    "orderId": 6,
    "parentFolderId": "4OUCdTrn3y7rQ4plRZ4K",
    "type": "folder"
  },
  {
    "_id": "V7QWNCEknDrmWkwpS6Ss",
    "isEnable": true,
    "label": "主管簽核中",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "VNW8X2gtAREGrn4Dk8ZT",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "VBWgCFL0okoiDZI7o2E9",
    "isEnable": true,
    "label": "一次性",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "7b9GinvuMV0LSTsI6Qs4",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "VDjtHG2AzxZkQjodoJXc",
    "isEnable": true,
    "label": "3月",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "C0LV6T0JyglB8po4VcPC",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "VH1axrheWouLiAyR4o2H",
    "isEnable": true,
    "label": "10月",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "C0LV6T0JyglB8po4VcPC",
    "type": "string",
    "value": "10"
  },
  {
    "_id": "VNW8X2gtAREGrn4Dk8ZT",
    "isEnable": true,
    "key": "day_off_status_third",
    "label": "Day_off_status_third",
    "level": 1,
    "orderId": 6,
    "parentFolderId": "9iLvnZvb6Flg656vKg1p",
    "type": "folder"
  },
  {
    "_id": "VPf0SSLu7InDsvd2oYke",
    "isEnable": true,
    "label": "送交人資簽核",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "VNW8X2gtAREGrn4Dk8ZT",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "VW0FWSNLQGoK1LwpX2UZ",
    "isEnable": true,
    "label": "5年",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "VXL1e6CgXN3Mk9qA61of",
    "isEnable": true,
    "label": "投資",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "TyzopuSOL4bgOcZI62RL",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "VbO76bRadWX3okZvMXqC",
    "isEnable": true,
    "key": "isopen",
    "label": "IsOpen",
    "level": 1,
    "orderId": 0,
    "parentFolderId": "kVLuNYSuO6Z8SyqsZVjv",
    "type": "folder"
  },
  {
    "_id": "Ve2mxZmTXsLEIkzrQEa3",
    "isEnable": true,
    "label": "退休準備",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "1fcl7w3rUMMdk02Aczr1",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "Vq6OUsviIAd3wdO4OCtK",
    "isEnable": true,
    "label": "全體同仁",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "649ubbcDY5DDXM7tbVci",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "W7u1zUyAJewvBlBWqmLr",
    "isEnable": true,
    "label": "Agent",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "GHyIuOmNWA4pN62gxDmJ",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "WAD0WiACxX5YyP9gy1XD",
    "isEnable": true,
    "key": "salarycash3",
    "label": "SalaryCash3",
    "level": 1,
    "orderId": 2,
    "parentFolderId": "FR7A6EMd5sbjGj5cMrL5",
    "type": "folder"
  },
  {
    "_id": "WDHF33klsC6i7WYpjeoE",
    "isEnable": true,
    "label": "2013",
    "level": 2,
    "orderId": 13,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2013"
  },
  {
    "_id": "WMHJHmdCF2Jxuidi1PGz",
    "isEnable": true,
    "label": "Non-Smoker",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "j7BlDnzkt35FytFJyRKZ",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "WWHyHHOJzS0vUjggkN88",
    "isEnable": true,
    "label": "香港",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "MxUfjvFRkhHHsxzyXS3B",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "WqYxNxaBVIjE61REdn6F",
    "isEnable": true,
    "label": "英國",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "LfABgDIYpY0d5rrg5rLU",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "WqlAs1NA804zJY8vV0hr",
    "isEnable": true,
    "label": "Preferred Non-Tobacco",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "izq7BzxYYNkkl2aQZ8Ix",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "WtcMWB8hgEQQ7CYBVY6C",
    "isEnable": true,
    "label": "營運",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "649ubbcDY5DDXM7tbVci",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "X5WnJZAr2KUKtosaPmBQ",
    "isEnable": true,
    "label": "Hanburfore & Colonial II Realty Co., Ltd",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "NY7dPGMyZbwG3ZINjAm0",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "X6fMCjDPYTJuPpYfRIML",
    "isEnable": true,
    "label": "半年",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "7b9GinvuMV0LSTsI6Qs4",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "XBuFrkfFWHqaYEQOfmX2",
    "isEnable": true,
    "label": "Half-yearly(6月底/12月底)",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "sM1qbvLFAEUBzb0SxD4v",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "XIRjGle4fuR5VT6Pj8MU",
    "isEnable": true,
    "label": "一般行政",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "xD8JURe8xQMPdzTFq6Mr",
    "type": "string",
    "value": "30"
  },
  {
    "_id": "XMaWqC0cLKET26CmTgMe",
    "isEnable": true,
    "label": "公假",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "g026G91IpBnrik927Ij7",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "Xjp6qaca06R4vVzxDlJT",
    "isEnable": true,
    "label": "A",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "Lpury4Jxi9QDAbJvjmRr",
    "type": "string",
    "value": "A"
  },
  {
    "_id": "Y2bOywnMDmLcMu3Z9EBR",
    "isEnable": true,
    "label": "第一季",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "0BH0lYvy5ZQhGMuHz8it",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "YBd80nX6CFSg6jnQTo4Q",
    "isEnable": true,
    "label": "退回給申請人",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "VNW8X2gtAREGrn4Dk8ZT",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "YI3p3t3eWSEK0EmIg3vU",
    "isEnable": true,
    "label": "8月",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "C0LV6T0JyglB8po4VcPC",
    "type": "string",
    "value": "8"
  },
  {
    "_id": "YMTLnexj4IvlxXx9jO4G",
    "isEnable": true,
    "label": "不固定",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "sM1qbvLFAEUBzb0SxD4v",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "YMZB9nrKPBXQRGONBi3A",
    "isEnable": true,
    "label": "19年",
    "level": 2,
    "orderId": 18,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "19"
  },
  {
    "_id": "YSg74Q8oXsLCceisDZrv",
    "isEnable": true,
    "label": "HKD",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "agLKH7v9Ir0OdPsMXrei",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "YSu0b3MGJYpi54nVmwTz",
    "isEnable": true,
    "label": "2月",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "C0LV6T0JyglB8po4VcPC",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "YYG2EQaDoMP8CSfks6YA",
    "isEnable": true,
    "label": "美國",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "ZopEwsQUxKgfzFlZO30L",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "YbuNBOJWEmv9Or2bhlx6",
    "isEnable": true,
    "label": "客群經營 - 贈禮(年節)",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "HpBSrWCCqjaWJrXb2ULO",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "YkZiVF7ppUqopnFX8vcH",
    "isEnable": true,
    "label": "一次",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "KIHtncdFFF0Ks0PRVfQ7",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "YoLU5My0pyWDMeQFNgow",
    "isEnable": true,
    "key": "bodychecktype1",
    "label": "BodyCheckType1",
    "level": 1,
    "orderId": 12,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "YoqRffGDy2KvsDLJAPpo",
    "isEnable": true,
    "label": "11%~20%",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "e1cVBCJxc3kZcvszpR1x",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "YqJMccmstN0ytWi0tvvY",
    "isEnable": true,
    "label": "婚假",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "g026G91IpBnrik927Ij7",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "YrYEFpZAa3EUNfnEC5dI",
    "isEnable": true,
    "label": "Annually",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "ASDWeiIg1YI77zcp6Ahw",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "YxuX8ORRli8tWmNPYGAP",
    "isEnable": true,
    "label": "Smoker",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "izq7BzxYYNkkl2aQZ8Ix",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "YzPQdu0tX9PU8DIxixy2",
    "isEnable": true,
    "label": "Pending",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "V6gcD6UyFNHbLfmC2KaR",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "ZPVwzfVHJ5b2hnYE168Y",
    "isEnable": true,
    "label": "排程中",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "OMxJTImT5pftkUO4eENU",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "ZQ9ugV8SPVwl5sUmaFIZ",
    "isEnable": true,
    "label": "發佣",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "xeWCYqnXlCHVFQ24TlSs",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "ZRHYyDtCUwU1Du6DO29Y",
    "isEnable": true,
    "key": "graduate",
    "label": "Graduate學歷",
    "level": 1,
    "orderId": 0,
    "parentFolderId": "1QH981GopmmUJz9rx1O1",
    "type": "folder"
  },
  {
    "_id": "ZRmGnnTpKH5KIIANDpBP",
    "isEnable": true,
    "label": "開啟",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "VbO76bRadWX3okZvMXqC",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "ZZAqUG88UhFEyXou9rCM",
    "isEnable": true,
    "key": "iccutoff",
    "label": "ICCutOff",
    "level": 1,
    "orderId": 8,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "ZgheNltR9x8QL69sQYtq",
    "isEnable": true,
    "label": "JPY",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "agLKH7v9Ir0OdPsMXrei",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "ZnikuKoXZDU72IZrGovm",
    "isEnable": true,
    "label": "支票",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "1xbDRXSyPYxPaoFWpfxG",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "ZokQ43I4xMx8sPDbOtSH",
    "isEnable": true,
    "label": "Grandtag",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "mYSTXVj2H1PzsX8JKpX8",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "ZopEwsQUxKgfzFlZO30L",
    "isEnable": true,
    "key": "p5country",
    "label": "P5Country",
    "level": 1,
    "orderId": 7,
    "parentFolderId": "79KB7RjdRMTciGz3nGe3",
    "type": "folder"
  },
  {
    "_id": "ZvApY4nL6D0hBIQgbojc",
    "isEnable": true,
    "label": "美國、加拿大房產",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "2Vst0PTSdzqPKMK9f6gO",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "Zvy07D6XsxGrMfe7bqQd",
    "isEnable": true,
    "label": "已收件",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "ywtFvCsvf8AgjGBuiijM",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "ZwYR0ZCKyNXqkjpISAVJ",
    "isEnable": true,
    "label": "Breidin",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "n7ZWMsPwEsf28yaDJGcb",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "a0VnVScNncIexgqawO4t",
    "isEnable": true,
    "key": "icleave",
    "label": "ICLeave",
    "level": 1,
    "orderId": 4,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "aGHxadEHfDvFyEoiqLmk",
    "isEnable": true,
    "key": "isrevoke",
    "label": "IsRevoke",
    "level": 1,
    "orderId": 6,
    "parentFolderId": "kVLuNYSuO6Z8SyqsZVjv",
    "type": "folder"
  },
  {
    "_id": "aJfbuqtDTRiVyKckaUxX",
    "isEnable": true,
    "label": "處理中 (廣達)",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "ywtFvCsvf8AgjGBuiijM",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "aRqlDD068PHGYmFBdGoN",
    "isEnable": true,
    "label": "5 產品中心",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "5SvoFjAoDTutCtJsET2x",
    "type": "string",
    "value": "auth_prod"
  },
  {
    "_id": "agLKH7v9Ir0OdPsMXrei",
    "isEnable": true,
    "key": "salarycash2",
    "label": "SalaryCash2",
    "level": 1,
    "orderId": 1,
    "parentFolderId": "FR7A6EMd5sbjGj5cMrL5",
    "type": "folder"
  },
  {
    "_id": "aqezJjqqTz0jCiQPDaQJ",
    "isEnable": true,
    "label": "2020",
    "level": 2,
    "orderId": 20,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2020"
  },
  {
    "_id": "b3d7zMsaYVeHxpbRsPgT",
    "isEnable": true,
    "label": "Direct Paying",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "mQtPQGdmmWrThA7l7nWy",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "bDGbfiYLXTzKGJOCTn5d",
    "isEnable": true,
    "label": "3年",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "bHfVrkRugCOimR6c4xhV",
    "isEnable": true,
    "label": "美國",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "LfABgDIYpY0d5rrg5rLU",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "bTNGgFhEzAAeKEEyBlIe",
    "isEnable": true,
    "label": "杭州",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "MxUfjvFRkhHHsxzyXS3B",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "bTdyVYpwGm3x19XzHvic",
    "isEnable": true,
    "label": "關閉",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "hhh2ip159c6WrTMPhHgI",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "bVuxboPkFYvyrrOueXXV",
    "isEnable": true,
    "label": "其他",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "1xbDRXSyPYxPaoFWpfxG",
    "type": "string",
    "value": "10"
  },
  {
    "_id": "brNlKvz1duPMvYWf7FGy",
    "isEnable": true,
    "label": "女",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "dN4v0mbk2joUHwR23E3O",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "bvRmXo1xZc44rFl0M88q",
    "isEnable": true,
    "label": "生效",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "4eR4RWdCRIqjv9KO3f6c",
    "type": "string",
    "value": "90"
  },
  {
    "_id": "cEx6utSS7u95FPuZb6oE",
    "isEnable": true,
    "key": "day_off_status_second",
    "label": "Day_off_status_second",
    "level": 1,
    "orderId": 5,
    "parentFolderId": "9iLvnZvb6Flg656vKg1p",
    "type": "folder"
  },
  {
    "_id": "cVz3QinhfLgOs2f8D8S9",
    "isEnable": true,
    "label": "FPI提款",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "j7BlDnzkt35FytFJyRKZ",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "cghj2hNePJ5qWssU6qAO",
    "isEnable": true,
    "label": "Operation",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "mYSTXVj2H1PzsX8JKpX8",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "ckHphsY4KUo5UTerL5Z8",
    "isEnable": true,
    "label": "郵寄",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "9d7Z4oZeVxSzMXxgOgq5",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "dN4v0mbk2joUHwR23E3O",
    "isEnable": true,
    "key": "sexual4",
    "label": "Sexual4",
    "level": 1,
    "orderId": 3,
    "parentFolderId": "1QH981GopmmUJz9rx1O1",
    "type": "folder"
  },
  {
    "_id": "dRPY66rNL4K19zIk2i4U",
    "isEnable": true,
    "label": "生效日",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "C6WYcUGfK9wLcrN7lQyT",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "dgZfytihvbSdz5EEpAnz",
    "isEnable": true,
    "label": "美金",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "SwsadbBPzVGoZHRex6u4",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "dhAeqWUN1BOfMoACubGh",
    "isEnable": true,
    "label": "客群經營 - 雜項",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "HpBSrWCCqjaWJrXb2ULO",
    "type": "string",
    "value": "8"
  },
  {
    "_id": "dj6ijcI6fChGXh8CwqNA",
    "isEnable": true,
    "label": "中國",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "LfABgDIYpY0d5rrg5rLU",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "dnmfVY9pzREMP4WzhcHO",
    "isEnable": true,
    "key": "investtype",
    "label": "InvestType",
    "level": 1,
    "orderId": 8,
    "parentFolderId": "kVLuNYSuO6Z8SyqsZVjv",
    "type": "folder"
  },
  {
    "_id": "companyObj",
    "isEnable": true,
    "key": "companytype",
    "label": "CompanyType",
    "level": 1,
    "orderId": 8,
    "parentFolderId": "kVLuNYSuO6Z8SyqsZVjv",
    "type": "folder"
  },
  {
    // "_id": "company1",
    "isEnable": true,
    "label": "LP",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "companyObj",
    "type": "string",
    "value": "1"
  },
  {
    // "_id": "eNEjwjiimmGgCbEXmZ66",
    "isEnable": true,
    "label": "LLC",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "companyObj",
    "type": "string",
    "value": "2"
  },
  {
    // "_id": "eNEjwjiimmGgCbEXmZ66",
    "isEnable": true,
    "label": "無",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "companyObj",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "dv6mcXO9sW1dbakBaks9",
    "isEnable": true,
    "label": "13年",
    "level": 2,
    "orderId": 12,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "13"
  },
  {
    "_id": "dw14F41gsXn3WiUQGSJi",
    "isEnable": true,
    "key": "auth",
    "label": "權限",
    "level": 1,
    "orderId": 12,
    "parentFolderId": "9iLvnZvb6Flg656vKg1p",
    "type": "folder"
  },
  {
    "_id": "e1cVBCJxc3kZcvszpR1x",
    "isEnable": true,
    "key": "invrisk",
    "label": "InvRisk",
    "level": 1,
    "orderId": 8,
    "parentFolderId": "1QH981GopmmUJz9rx1O1",
    "type": "folder"
  },
  {
    "_id": "eDj5IQ62xy2zXx1a2GBA",
    "isEnable": true,
    "label": "保險-VOYA",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "2Vst0PTSdzqPKMK9f6gO",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "eNEjwjiimmGgCbEXmZ66",
    "isEnable": true,
    "label": "無",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "dnmfVY9pzREMP4WzhcHO",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "eb9NXYcf3iaPw3hMQAhR",
    "isEnable": true,
    "label": "Client",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "mYSTXVj2H1PzsX8JKpX8",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "egPhwVMchwT4A3gqvAGh",
    "isEnable": true,
    "key": "mailsending",
    "label": "Mailsending",
    "level": 1,
    "orderId": 1,
    "parentFolderId": "4OUCdTrn3y7rQ4plRZ4K",
    "type": "folder"
  },
  {
    "_id": "eiM9woPMxatNN4tSLPba",
    "isEnable": true,
    "label": "20%以上",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "e1cVBCJxc3kZcvszpR1x",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "f1wtVoTwlhfMZ8Om6AOr",
    "isEnable": true,
    "label": "Stephy",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "vXF6bRwR1njuif2mVAeu",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "fDwxyi2IoPq9gsjvpJ6f",
    "isEnable": true,
    "label": "2009",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2009"
  },
  {
    "_id": "fSSf0y4DP9JfljJuXkSy",
    "isEnable": true,
    "label": "SNT",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "YoLU5My0pyWDMeQFNgow",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "fbdctEiW34kq7tpFvwGu",
    "isEnable": true,
    "label": "已發佣",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "fnUlOYv9AUNNq8WFJpUz",
    "isEnable": true,
    "label": "第二季",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "0BH0lYvy5ZQhGMuHz8it",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "g026G91IpBnrik927Ij7",
    "isEnable": true,
    "key": "day_off",
    "label": "Day_off",
    "level": 1,
    "orderId": 0,
    "parentFolderId": "9iLvnZvb6Flg656vKg1p",
    "type": "folder"
  },
  {
    "_id": "g0T1ks6Qk3xdpRoEUzRf",
    "isEnable": true,
    "label": "博士",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "ZRHYyDtCUwU1Du6DO29Y",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "g0YjbEsk0yfTjMED7ThA",
    "isEnable": true,
    "label": "EUR",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "jXwaQM2dYng5RlSbURH6",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "g7PIVfZYTngPiofthFiV",
    "isEnable": true,
    "label": "Sliver",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "KLXON0dFCEjTLmF72J2h",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "g7RRFcMzD83xMf8wJRYZ",
    "isEnable": true,
    "label": "歐元EUR",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "WAD0WiACxX5YyP9gy1XD",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "gDTxb70amYirh9cmxShH",
    "isEnable": true,
    "label": "補休申請",
    "level": 2,
    "orderId": 12,
    "parentFolderId": "g026G91IpBnrik927Ij7",
    "type": "string",
    "value": "13"
  },
  {
    "_id": "gTgQ9OVdHN18Lpri8qNP",
    "isEnable": true,
    "label": "病假",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "UgRNHBM4upvcOa9ikKKi",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "gV7cehQiPwbro0m04Nfs",
    "isEnable": true,
    "label": "2015",
    "level": 2,
    "orderId": 15,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2015"
  },
  {
    "_id": "gczoPipVdM0GwtwkvkXr",
    "isEnable": true,
    "label": "半薪",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "98Ai2w2UxKNPfuQPeLzz",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "gsDI0n6TrW00MzbLPAm1",
    "isEnable": true,
    "label": "育嬰假",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "UgRNHBM4upvcOa9ikKKi",
    "type": "string",
    "value": "11"
  },
  {
    "_id": "h0sMnT7MP2nysXoeO9sr",
    "isEnable": true,
    "label": "港幣HKD",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "WAD0WiACxX5YyP9gy1XD",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "h3osgM7TXFRo3GlKKjeG",
    "isEnable": true,
    "label": "7月",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "aF96UbqIOzd4TSEs1Yum",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "h5YgGWPEhnbpcLG1U4Q9",
    "isEnable": true,
    "key": "lockmonth",
    "label": "LockMonth",
    "level": 1,
    "orderId": 15,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "h5fCVpQTjDDMicvnW8f7",
    "isEnable": true,
    "label": "保險",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "TyzopuSOL4bgOcZI62RL",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "h7umqrJ7X1ixwh3pC7BD",
    "isEnable": true,
    "label": "歐元EUR",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "mVvuuf85PRSksvOtDqib",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "hH5cBmjCOPEhjQkmdox8",
    "isEnable": true,
    "label": "2011",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2011"
  },
  {
    "_id": "hTYgVCBSso0SsTlokjPu",
    "isEnable": true,
    "label": "特休假",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "UgRNHBM4upvcOa9ikKKi",
    "type": "string",
    "value": "10"
  },
  {
    "_id": "hcZUQWHD9cBRW9EKqcqi",
    "isEnable": true,
    "label": "可撤銷",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "aGHxadEHfDvFyEoiqLmk",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "hdzRm1hx2VTpFlWDvS5K",
    "isEnable": true,
    "label": "讀取+開帳",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "2ZSqN6a8UjNHOI0U2vdL",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "hhUUm6QRla8kPMTkZgaq",
    "isEnable": true,
    "label": "5%至10%",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "wouj1gsi1dw1K5e4yyVZ",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "hhh2ip159c6WrTMPhHgI",
    "isEnable": true,
    "key": "auth0",
    "label": "Auth0",
    "level": 1,
    "orderId": 1,
    "parentFolderId": "y2m5huz3s2I2QVULc5nU",
    "type": "folder"
  },
  {
    "_id": "hoErmZdm2gu760ebnLJd",
    "isEnable": true,
    "label": "一年以下",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "mE2qqmOGhlKgQRS6O4TD",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "hpxaOM9OMQlGlRYNOtZD",
    "isEnable": true,
    "label": "Hanbury",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "n7ZWMsPwEsf28yaDJGcb",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "i6IvJQjtsGKSmdAW9EO0",
    "isEnable": true,
    "label": "一年",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "KIHtncdFFF0Ks0PRVfQ7",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "iIoxJojs1jAOZ7Cr51gQ",
    "isEnable": true,
    "label": "Provider",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "GHyIuOmNWA4pN62gxDmJ",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "iYljDzJT22QaAOHq20y0",
    "isEnable": true,
    "label": "男",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "io4HrTnOzUk9rUp0RqMd",
    "isEnable": true,
    "label": "已取消",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "V6gcD6UyFNHbLfmC2KaR",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "ivb5K0JmrqYIlrKwPNkV",
    "isEnable": true,
    "label": "Awaiting for Provider's confirmation",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "IfMUJvxRa8zEmdJEMskV",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "izq7BzxYYNkkl2aQZ8Ix",
    "isEnable": true,
    "key": "bodychecktype3",
    "label": "BodyCheckType3",
    "level": 1,
    "orderId": 14,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "j7BlDnzkt35FytFJyRKZ",
    "isEnable": true,
    "key": "prupaymethod",
    "label": "PruPayMethod",
    "level": 1,
    "orderId": 19,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "j7XEm9iQjDLEvBlolBGe",
    "isEnable": true,
    "label": "11%至20%",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "wouj1gsi1dw1K5e4yyVZ",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "jAp7UuKpdWhcv5562PXd",
    "isEnable": true,
    "label": "2 人資管理",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "5SvoFjAoDTutCtJsET2x",
    "type": "string",
    "value": "auth_hr"
  },
  {
    "_id": "jDbSpYKznKqdGfL6uFzO",
    "isEnable": true,
    "label": "客群經營 - 贈禮(中秋)",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "HpBSrWCCqjaWJrXb2ULO",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "jITI283w1m6jsBQhl17B",
    "isEnable": true,
    "label": "補貼",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "xeWCYqnXlCHVFQ24TlSs",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "jLnGPFhehya14i5Tswre",
    "isEnable": true,
    "label": "14年",
    "level": 2,
    "orderId": 13,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "14"
  },
  {
    "_id": "jPxx4IL20ft8SgFc9zik",
    "isEnable": true,
    "key": "sexual",
    "label": "Sexual性別",
    "level": 1,
    "orderId": 1,
    "parentFolderId": "1QH981GopmmUJz9rx1O1",
    "type": "folder"
  },
  {
    "_id": "jXwaQM2dYng5RlSbURH6",
    "isEnable": true,
    "key": "salarycash_eng",
    "label": "SalaryCash_eng",
    "level": 1,
    "orderId": 3,
    "parentFolderId": "FR7A6EMd5sbjGj5cMrL5",
    "type": "folder"
  },
  {
    "_id": "jgpFaCuAzEhDUlObn4tG",
    "isEnable": true,
    "label": "Hanbury Operation",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "GHyIuOmNWA4pN62gxDmJ",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "jmg4bAMraMDJqLHbOuhJ",
    "isEnable": true,
    "label": "1月",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "aF96UbqIOzd4TSEs1Yum",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "jnq7UXreGl1xz8MFaz6O",
    "isEnable": true,
    "label": "成功",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "Npf1jOzkwaftYgmkiVBQ",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "jvYLsssu09JLoPRTsAGV",
    "isEnable": true,
    "label": "Hanburfore & Colonial III Realty Co., Ltd",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "NY7dPGMyZbwG3ZINjAm0",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "k6CRI6Jzc2hNGvzgBn0F",
    "isEnable": true,
    "label": "儲蓄",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "1fcl7w3rUMMdk02Aczr1",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "k8czJIh6mI36t87ieOHK",
    "isEnable": true,
    "label": "Smoker",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "j7BlDnzkt35FytFJyRKZ",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "kOHTIJxIDNepUXYtaOCF",
    "isEnable": true,
    "label": "Preferred Smoker",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "j7BlDnzkt35FytFJyRKZ",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "kVLuNYSuO6Z8SyqsZVjv",
    "isEnable": true,
    "key": "optionCom",
    "label": "共同",
    "level": 0,
    "orderId": 5,
    "parentFolderId": "",
    "type": "folder"
  },
  {
    "_id": "klP87fnRRD5MbFw5NvHQ",
    "isEnable": true,
    "label": "一年",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "7b9GinvuMV0LSTsI6Qs4",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "kmJMQp2PNUVRhBVATF7f",
    "isEnable": true,
    "label": "讀取+編輯",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "loWPiOcQxFvCArrgOTjT",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "kzPC8fybtiHp1xswq2N8",
    "isEnable": true,
    "label": "Non-Somker Plus",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "izq7BzxYYNkkl2aQZ8Ix",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "lAIrrsGuTXWi1TbyWPpY",
    "isEnable": true,
    "label": "面談",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "9d7Z4oZeVxSzMXxgOgq5",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "lGibuWb5n4PrELJHE9uG",
    "isEnable": true,
    "label": "生效 In force",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "AXtnJQLeduMm1TpC5glY",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "lJ8cighWZnzBqm6eJy4i",
    "isEnable": true,
    "label": "2023",
    "level": 2,
    "orderId": 23,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2023"
  },
  {
    "_id": "lK0AkEWpxbDBmNWkH8jX",
    "isEnable": true,
    "label": "2038",
    "level": 2,
    "orderId": 38,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2038"
  },
  {
    "_id": "lLwmxE1E8ZEOQ9zQjhu6",
    "isEnable": true,
    "key": "day_off_status",
    "label": "Day_off_status",
    "level": 1,
    "orderId": 3,
    "parentFolderId": "9iLvnZvb6Flg656vKg1p",
    "type": "folder"
  },
  {
    "_id": "lTBVWedgTDgvYdXvbUE1",
    "isEnable": true,
    "label": "2034",
    "level": 2,
    "orderId": 34,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2034"
  },
  {
    "_id": "lXfOAAFhyW6HLrQAEMce",
    "isEnable": true,
    "label": "客群經營 - 交通",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "HpBSrWCCqjaWJrXb2ULO",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "lgQkEFD7tk3RXopciqr4",
    "isEnable": true,
    "label": "2002",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2002"
  },
  {
    "_id": "ljSfwm2WZywKjkosD8PY",
    "isEnable": true,
    "label": "陪產假",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "g026G91IpBnrik927Ij7",
    "type": "string",
    "value": "9"
  },
  {
    "_id": "loWPiOcQxFvCArrgOTjT",
    "isEnable": true,
    "key": "auth2",
    "label": "Auth2",
    "level": 1,
    "orderId": 3,
    "parentFolderId": "y2m5huz3s2I2QVULc5nU",
    "type": "folder"
  },
  {
    "_id": "lqZ0f6UgLt2Hinp1tfLQ",
    "isEnable": true,
    "label": "英磅GBP",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "WAD0WiACxX5YyP9gy1XD",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "lvmG2qxlrl677Lbt76kz",
    "isEnable": true,
    "label": "考慮中(入場時機點)",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "s28P6IZDghb0FkAqqw6m",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "m07LeiucNY5bk7bLWWes",
    "isEnable": true,
    "label": "EUR",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "agLKH7v9Ir0OdPsMXrei",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "m1zwpWE3BlulRScKvyOg",
    "isEnable": true,
    "label": "產假",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "g026G91IpBnrik927Ij7",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "m63qrViW5FZy8CvKnvVZ",
    "isEnable": true,
    "label": "解約 Surrender",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "AXtnJQLeduMm1TpC5glY",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "mE2qqmOGhlKgQRS6O4TD",
    "isEnable": true,
    "key": "invexp",
    "label": "InvExp",
    "level": 1,
    "orderId": 7,
    "parentFolderId": "1QH981GopmmUJz9rx1O1",
    "type": "folder"
  },
  {
    "_id": "mQtPQGdmmWrThA7l7nWy",
    "isEnable": true,
    "key": "paymethodfpi",
    "label": "PayMethodFPI",
    "level": 1,
    "orderId": 9,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "mTLadLXXTuEU65bpzh8Z",
    "isEnable": true,
    "label": "Awaiting for Agent's Instruction",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "IfMUJvxRa8zEmdJEMskV",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "mVvuuf85PRSksvOtDqib",
    "isEnable": true,
    "key": "salarycash",
    "label": "SalaryCash",
    "level": 1,
    "orderId": 0,
    "parentFolderId": "FR7A6EMd5sbjGj5cMrL5",
    "type": "folder"
  },
  {
    "_id": "mYSTXVj2H1PzsX8JKpX8",
    "isEnable": true,
    "key": "follownewcaseowner",
    "label": "FollownewcaseOwner",
    "level": 1,
    "orderId": 5,
    "parentFolderId": "4OUCdTrn3y7rQ4plRZ4K",
    "type": "folder"
  },
  {
    "_id": "mmQONBgBpcvGTd8IbdMg",
    "isEnable": true,
    "label": "Hanbury",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "smy7XLNVjoDSz4bzXW3O",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "mvlds1VN9IdMb93Gyzkx",
    "isEnable": true,
    "label": "2037",
    "level": 2,
    "orderId": 37,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2037"
  },
  {
    "_id": "mzW5ZVnlTCL9qf4IPMZP",
    "isEnable": true,
    "label": "4月",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "C0LV6T0JyglB8po4VcPC",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "n7ZWMsPwEsf28yaDJGcb",
    "isEnable": true,
    "key": "department",
    "label": "Department",
    "level": 1,
    "orderId": 4,
    "parentFolderId": "kVLuNYSuO6Z8SyqsZVjv",
    "type": "folder"
  },
  {
    "_id": "nJZcKmh51kFAXoSiq91c",
    "isEnable": true,
    "key": "optionPortfolio",
    "label": "投資相關",
    "level": 0,
    "orderId": 3,
    "parentFolderId": "",
    "type": "folder"
  },
  {
    "_id": "nNaReQIpnJ5FFKzg7JHf",
    "isEnable": true,
    "label": "香港",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "LfABgDIYpY0d5rrg5rLU",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "nzhqM4lQDcwiPW1qlcT8",
    "isEnable": true,
    "label": "薪資",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "xeWCYqnXlCHVFQ24TlSs",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "oHQQfXjKvGJYBvgIIyFr",
    "isEnable": true,
    "label": "Non-Smoker",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "izq7BzxYYNkkl2aQZ8Ix",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "oiFo7ZVFXZcu83rafUNQ",
    "isEnable": true,
    "label": "30",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "h5YgGWPEhnbpcLG1U4Q9",
    "type": "string",
    "value": "30"
  },
  {
    "_id": "olPXFvyj6GxCPTmAbRDg",
    "isEnable": true,
    "label": "2040",
    "level": 2,
    "orderId": 40,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2040"
  },
  {
    "_id": "ov37qsTr8l4edZ8c80E5",
    "isEnable": true,
    "label": "大陸",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "MxUfjvFRkhHHsxzyXS3B",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "ox68egiAo1iinwSLSGPA",
    "isEnable": true,
    "label": "HSBC 取款單",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "j7BlDnzkt35FytFJyRKZ",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "p6IKJ1Zr9pDALptmW4fD",
    "isEnable": true,
    "label": "補休假",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "g026G91IpBnrik927Ij7",
    "type": "string",
    "value": "12"
  },
  {
    "_id": "p6z2yj4uBKaeygb7JEny",
    "isEnable": true,
    "label": "B4",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "HohtX3A0efekc9n64Vig",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "p7Vf57pYPMX5SFBH6jzh",
    "isEnable": true,
    "label": "女士",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "RioTJI6ZjJV0xgnPveX0",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "pFe61E8uxEf2zfSuqZkL",
    "isEnable": true,
    "label": "4 售後服務",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "5SvoFjAoDTutCtJsET2x",
    "type": "string",
    "value": "auth_as"
  },
  {
    "_id": "pGdtR6wgzkMshRRUFbqI",
    "isEnable": true,
    "label": "10年",
    "level": 2,
    "orderId": 9,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "10"
  },
  {
    "_id": "pJIy5L39aqBzWTx9xgRk",
    "isEnable": true,
    "level": 2,
    "orderId": 0,
    "parentFolderId": "HohtX3A0efekc9n64Vig",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "pOdlddBvCO8f2YxrB0HR",
    "isEnable": true,
    "label": "股權",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "dnmfVY9pzREMP4WzhcHO",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "pQnZagIfmOR5kH6MMDMi",
    "isEnable": true,
    "key": "auth3",
    "label": "Auth3",
    "level": 1,
    "orderId": 4,
    "parentFolderId": "y2m5huz3s2I2QVULc5nU",
    "type": "folder"
  },
  {
    "_id": "pTlK8wqYbSLhYIt1Hz1r",
    "isEnable": true,
    "label": "第四季",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "0BH0lYvy5ZQhGMuHz8it",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "pZO75kYoufDN0CJyDG2P",
    "isEnable": true,
    "label": "2012",
    "level": 2,
    "orderId": 12,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2012"
  },
  {
    "_id": "pffJC7rD0uUO2VXxhnza",
    "isEnable": true,
    "label": "已交付文件",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "ywtFvCsvf8AgjGBuiijM",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "ph0qeHjQSYd48xeSpL65",
    "isEnable": true,
    "label": "FPI",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "2Vst0PTSdzqPKMK9f6gO",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "pjEWMTLMLndAuuDj6EUo",
    "isEnable": true,
    "label": "2022",
    "level": 2,
    "orderId": 22,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2022"
  },
  {
    "_id": "pku4UWlVK85KPNFaiLat",
    "isEnable": true,
    "label": "A4",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "HohtX3A0efekc9n64Vig",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "pmbFjNPUiKbg5sl0cb0J",
    "isEnable": true,
    "label": "18",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "h5YgGWPEhnbpcLG1U4Q9",
    "type": "string",
    "value": "18"
  },
  {
    "_id": "puDSUdLzfTM3Mb75NbJI",
    "isEnable": true,
    "key": "pay_status",
    "label": "Pay_status",
    "level": 1,
    "orderId": 3,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "puzPFem9WOHy8AZN3etI",
    "isEnable": true,
    "label": "2039",
    "level": 2,
    "orderId": 39,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2039"
  },
  {
    "_id": "qBxFmnuUNbZN7YjAAWxs",
    "isEnable": true,
    "label": "全薪",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "98Ai2w2UxKNPfuQPeLzz",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "qKT3eXkGI3Ihg8WKbnFT",
    "isEnable": true,
    "label": "讀取",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "CoDgfQmAefoDAIL5zq1U",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "qblY5rgB9kLXyU5GJNJC",
    "isEnable": true,
    "label": "2000",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2000"
  },
  {
    "_id": "qpOFa5LaPCT0sjIKfouS",
    "isEnable": true,
    "label": "代理人覆核中",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "lLwmxE1E8ZEOQ9zQjhu6",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "qsSQaLlwhyzwSW5GChBD",
    "isEnable": true,
    "label": "Hanburfore & Colonial Realty Co., Ltd",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "NY7dPGMyZbwG3ZINjAm0",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "qv4U20Xmk2pPHpfUIZPN",
    "isEnable": true,
    "label": "國中",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "ZRHYyDtCUwU1Du6DO29Y",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "rAfebdstSwPOJsT6oPNi",
    "isEnable": true,
    "label": "2033",
    "level": 2,
    "orderId": 33,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2033"
  },
  {
    "_id": "rDzh20NZ4jSmb9TK5wTj",
    "isEnable": true,
    "label": "9年",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "9"
  },
  {
    "_id": "rJItnvjfSYKuIf4Zhzkg",
    "isEnable": true,
    "label": "20年",
    "level": 2,
    "orderId": 19,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "20"
  },
  {
    "_id": "rKRlzl7G6kFcLmCfJ0sU",
    "isEnable": true,
    "label": "Agent",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "mYSTXVj2H1PzsX8JKpX8",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "rMosShlqRqWk1ltFuQbJ",
    "isEnable": true,
    "label": "15年",
    "level": 2,
    "orderId": 14,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "15"
  },
  {
    "_id": "rNv10MD2VM8laRBWb9wy",
    "isEnable": true,
    "label": "財務主管",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "xD8JURe8xQMPdzTFq6Mr",
    "type": "string",
    "value": "41"
  },
  {
    "_id": "rRBCASsXfn1HNlREcjMQ",
    "isEnable": true,
    "label": "婉拒(已擁有太多相似產品)",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "s28P6IZDghb0FkAqqw6m",
    "type": "string",
    "value": "8"
  },
  {
    "_id": "rRU26EVbjzNIs1eCvGKo",
    "isEnable": true,
    "label": "已繳費",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "puDSUdLzfTM3Mb75NbJI",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "rSbrk6rj2Hcr6X7hIVjv",
    "isEnable": true,
    "label": "已建檔",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "ywtFvCsvf8AgjGBuiijM",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "rT7M4VjeCpyF8FPFcLh5",
    "isEnable": true,
    "label": "終止繳費",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "4eR4RWdCRIqjv9KO3f6c",
    "type": "string",
    "value": "99"
  },
  {
    "_id": "rVBl6qUweat6nPSkrr3y",
    "isEnable": true,
    "label": "申請中",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "EUSvJ19INamfsd0lav1b",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "raowa62fi171ueVsMih4",
    "isEnable": true,
    "label": "退回給申請人",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "cEx6utSS7u95FPuZb6oE",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "rbdjKQerpjtfG9qeR0bs",
    "isEnable": true,
    "label": "Agent",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "xD8JURe8xQMPdzTFq6Mr",
    "type": "string",
    "value": "10"
  },
  {
    "_id": "rmRiZiwjZMeAlB2rUqdX",
    "isEnable": true,
    "label": "6年",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "rn4vlf3nElHlv6mxUTEV",
    "isEnable": true,
    "label": "12年",
    "level": 2,
    "orderId": 11,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "12"
  },
  {
    "_id": "ryidpNcPelESnnPcCHKc",
    "isEnable": true,
    "label": "Green",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "KLXON0dFCEjTLmF72J2h",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "rzHDcYZ3h2k1b9ZyZk2e",
    "isEnable": true,
    "label": "2019",
    "level": 2,
    "orderId": 19,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2019"
  },
  {
    "_id": "s28P6IZDghb0FkAqqw6m",
    "isEnable": true,
    "key": "talkresult",
    "label": "TalkResult",
    "level": 1,
    "orderId": 13,
    "parentFolderId": "1QH981GopmmUJz9rx1O1",
    "type": "folder"
  },
  {
    "_id": "s8VtGS7mgTG0EQ6vp9OD",
    "isEnable": true,
    "label": "none",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "a0VnVScNncIexgqawO4t",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "sGQuPM6bauHBooR82cTm",
    "isEnable": true,
    "label": "17年",
    "level": 2,
    "orderId": 16,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "17"
  },
  {
    "_id": "sM1qbvLFAEUBzb0SxD4v",
    "isEnable": true,
    "key": "icperiod",
    "label": "ICPeriod",
    "level": 1,
    "orderId": 6,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "sVXWemwhnIBgXVklC1ei",
    "isEnable": true,
    "label": "歐洲",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "LfABgDIYpY0d5rrg5rLU",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "scLWLMVNllDdLDvgTVBN",
    "isEnable": true,
    "label": "2010",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2010"
  },
  {
    "_id": "sfYCRk70rGiyYxxulhK6",
    "isEnable": true,
    "label": "取消本申請",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "VNW8X2gtAREGrn4Dk8ZT",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "smy7XLNVjoDSz4bzXW3O",
    "isEnable": true,
    "key": "managedteam",
    "label": "ManagedTeam",
    "level": 1,
    "orderId": 18,
    "parentFolderId": "79KB7RjdRMTciGz3nGe3",
    "type": "folder"
  },
  {
    "_id": "sxfDxUu2XwnHEzbjmWhx",
    "isEnable": true,
    "label": "無效",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "8eSphGcKED3Lm5ZZmLqw",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "tVtKgJ31YRyz7f5DuFqK",
    "isEnable": true,
    "label": "下半年",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "KIHtncdFFF0Ks0PRVfQ7",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "tXy1PcrY6jYsWkLbqT7Y",
    "isEnable": true,
    "key": "nowstatus",
    "label": "NowStatus",
    "level": 1,
    "orderId": 11,
    "parentFolderId": "nJZcKmh51kFAXoSiq91c",
    "type": "folder"
  },
  {
    "_id": "tfJbPrxEFv3aINYNSc6E",
    "isEnable": true,
    "label": "一至五年",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "mE2qqmOGhlKgQRS6O4TD",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "tgEiVAUipfkkuo4wIKMx",
    "isEnable": true,
    "label": "售後服務",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "s28P6IZDghb0FkAqqw6m",
    "type": "string",
    "value": "9"
  },
  {
    "_id": "tlhbRZJPfUqSpHf6ikVW",
    "isEnable": true,
    "label": "已取消",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "lLwmxE1E8ZEOQ9zQjhu6",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "to3fzclrFTw8TRDELjBw",
    "isEnable": true,
    "key": "yearnum",
    "label": "YearNum",
    "level": 1,
    "orderId": 2,
    "parentFolderId": "kVLuNYSuO6Z8SyqsZVjv",
    "type": "folder"
  },
  {
    "_id": "tog5mYFzR6QzW9Yrmzr0",
    "isEnable": true,
    "label": "主管簽核中",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "lLwmxE1E8ZEOQ9zQjhu6",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "tzWkYmtQpUkyZbglxqlL",
    "isEnable": true,
    "label": "Quarterly",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "ASDWeiIg1YI77zcp6Ahw",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "uM5rAjZNEriUYMrBw2W1",
    "isEnable": true,
    "label": "5月",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "aF96UbqIOzd4TSEs1Yum",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "udOuC5Os60L5Sy19ehIV",
    "isEnable": true,
    "key": "marriage",
    "label": "Marriage婚姻",
    "level": 1,
    "orderId": 4,
    "parentFolderId": "1QH981GopmmUJz9rx1O1",
    "type": "folder"
  },
  {
    "_id": "urIX8V5boMHlNNI5eVcA",
    "isEnable": true,
    "label": "婉拒(家人反對)",
    "level": 2,
    "orderId": 5,
    "parentFolderId": "s28P6IZDghb0FkAqqw6m",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "urXyXsgH5i2m8VDpScSP",
    "isEnable": true,
    "label": "Lapse",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "tXy1PcrY6jYsWkLbqT7Y",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "uwZ00f52u1Xjs4DtUn4z",
    "isEnable": true,
    "label": "產假",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "UgRNHBM4upvcOa9ikKKi",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "uzQX34ZbIXvdZH5XFvao",
    "isEnable": true,
    "label": "事假",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "UgRNHBM4upvcOa9ikKKi",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "v2STZ2mwKniebYOyOOJx",
    "isEnable": true,
    "key": "yn",
    "label": "YN",
    "level": 1,
    "orderId": 1,
    "parentFolderId": "kVLuNYSuO6Z8SyqsZVjv",
    "type": "folder"
  },
  {
    "_id": "v4ltNWadH882iNp4dsYe",
    "isEnable": true,
    "label": "2008",
    "level": 2,
    "orderId": 8,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2008"
  },
  {
    "_id": "vACZdIpY6XqIXa7wvKcw",
    "isEnable": true,
    "label": "婚假",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "UgRNHBM4upvcOa9ikKKi",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "vBRoZnuBtoosoK23gQ6n",
    "isEnable": true,
    "label": "公司-上海",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "vXF6bRwR1njuif2mVAeu",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "vDd9xIENSw2opv4cKdSL",
    "isEnable": true,
    "label": "7月",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "C0LV6T0JyglB8po4VcPC",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "vM6HRo2OJSgcohgwdbcx",
    "isEnable": true,
    "label": "ST",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "YoLU5My0pyWDMeQFNgow",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "vNppCkR7McoskykDZDlS",
    "isEnable": true,
    "label": "2年",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "vOsHhoZ6HbCasboK1J9i",
    "isEnable": true,
    "label": "禁止",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "2ZSqN6a8UjNHOI0U2vdL",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "vXF6bRwR1njuif2mVAeu",
    "isEnable": true,
    "key": "bankaccount",
    "label": "BankAccount",
    "level": 1,
    "orderId": 4,
    "parentFolderId": "FR7A6EMd5sbjGj5cMrL5",
    "type": "folder"
  },
  {
    "_id": "vcaHylS1OzutxDDbytpS",
    "isEnable": true,
    "label": "日本",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "MxUfjvFRkhHHsxzyXS3B",
    "type": "string",
    "value": "8"
  },
  {
    "_id": "w8dytvxAA2Q7iYZ8oOTE",
    "isEnable": true,
    "label": "公司",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "vXF6bRwR1njuif2mVAeu",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "wDXF2Tx2vQeGaYRahbKw",
    "isEnable": true,
    "label": "2036",
    "level": 2,
    "orderId": 36,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2036"
  },
  {
    "_id": "wDw3JO1CwI292HhKEnVD",
    "isEnable": true,
    "label": "12月底",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "ZZAqUG88UhFEyXou9rCM",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "wRzNckqKxpB4SsvM8Wps",
    "isEnable": true,
    "label": "資本增值",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "1fcl7w3rUMMdk02Aczr1",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "wYIUrh11F8PwEjIHwxDv",
    "isEnable": true,
    "label": "2025",
    "level": 2,
    "orderId": 25,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2025"
  },
  {
    "_id": "wZJE1IkMh5TD7h3xoARw",
    "isEnable": true,
    "label": "其他",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "xeWCYqnXlCHVFQ24TlSs",
    "type": "string",
    "value": "10"
  },
  {
    "_id": "wa43f6ldb0godh7hQ8Pc",
    "isEnable": true,
    "label": "2024",
    "level": 2,
    "orderId": 24,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2024"
  },
  {
    "_id": "wal43A5z9Zd7mba5jdvU",
    "isEnable": true,
    "label": "2029",
    "level": 2,
    "orderId": 29,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2029"
  },
  {
    "_id": "wcaf4xaBqHSSo04IwFcb",
    "isEnable": true,
    "label": "4月",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "aF96UbqIOzd4TSEs1Yum",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "wouj1gsi1dw1K5e4yyVZ",
    "isEnable": true,
    "key": "invpurret",
    "label": "InvPurRet",
    "level": 1,
    "orderId": 10,
    "parentFolderId": "1QH981GopmmUJz9rx1O1",
    "type": "folder"
  },
  {
    "_id": "wvyxHAMsJWVjsPh1b8qY",
    "isEnable": true,
    "label": "已寄送",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "OMxJTImT5pftkUO4eENU",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "wwLCWAE3rKqsxu8QogEr",
    "isEnable": true,
    "label": "PT",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "YoLU5My0pyWDMeQFNgow",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "x9lqOnoioZvsFfygzmPM",
    "isEnable": true,
    "label": "補休申請",
    "level": 2,
    "orderId": 12,
    "parentFolderId": "UgRNHBM4upvcOa9ikKKi",
    "type": "string",
    "value": "13"
  },
  {
    "_id": "xD8JURe8xQMPdzTFq6Mr",
    "isEnable": true,
    "key": "userrole",
    "label": "UserRole",
    "level": 1,
    "orderId": 5,
    "parentFolderId": "79KB7RjdRMTciGz3nGe3",
    "type": "folder"
  },
  {
    "_id": "xFwScD8uKuN0eiNqJwJh",
    "isEnable": true,
    "label": "Panorama",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "GHyIuOmNWA4pN62gxDmJ",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "xIU5dU6xZ2bAH7ZQTvTf",
    "isEnable": true,
    "label": "育嬰假",
    "level": 2,
    "orderId": 10,
    "parentFolderId": "g026G91IpBnrik927Ij7",
    "type": "string",
    "value": "11"
  },
  {
    "_id": "xRsdi3083ysyMinuQFNx",
    "isEnable": true,
    "label": "教育",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "1fcl7w3rUMMdk02Aczr1",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "xeWCYqnXlCHVFQ24TlSs",
    "isEnable": true,
    "key": "salarytype",
    "label": "SalaryType",
    "level": 1,
    "orderId": 9,
    "parentFolderId": "9iLvnZvb6Flg656vKg1p",
    "type": "folder"
  },
  {
    "_id": "xuUFu34aSawccEWAONrg",
    "isEnable": true,
    "label": "一次",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "3TTmeHjiWD0RFQ78fZuK",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "y0Dqw4SGeHHeYwA1hzkc",
    "isEnable": true,
    "label": "取消本申請",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "CXfQz0XsYwxKzjbwDakh",
    "type": "string",
    "value": "6"
  },
  {
    "_id": "y2m5huz3s2I2QVULc5nU",
    "isEnable": true,
    "key": "optionAuth",
    "label": "權限相關",
    "level": 0,
    "orderId": 7,
    "parentFolderId": "",
    "type": "folder"
  },
  {
    "_id": "y3wCmnUpiFU9AZePXw90",
    "isEnable": true,
    "label": "國小",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "ZRHYyDtCUwU1Du6DO29Y",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "y4FXiedqaTsEV156aNIW",
    "isEnable": true,
    "label": "USD",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "agLKH7v9Ir0OdPsMXrei",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "yDTSjA4jcWI1j1V2dBrm",
    "isEnable": true,
    "label": "2021",
    "level": 2,
    "orderId": 21,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2021"
  },
  {
    "_id": "yFPVTXaFdbjBZb0EO1GE",
    "isEnable": true,
    "label": "沒有",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "mE2qqmOGhlKgQRS6O4TD",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "yFp9aVnZKOmqzwwxcFmy",
    "isEnable": true,
    "label": "Six Monthly",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "ASDWeiIg1YI77zcp6Ahw",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "yQf4DNsiw9gF1gZ1H8QR",
    "isEnable": true,
    "label": "歐洲",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "MxUfjvFRkhHHsxzyXS3B",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "ySxu2H5JVg8J6Q07Wz7Q",
    "isEnable": true,
    "label": "電匯",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "1xbDRXSyPYxPaoFWpfxG",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "yXbl1fdHlpD7c9MILQeG",
    "isEnable": true,
    "label": "考慮中(產品不熟悉)",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "s28P6IZDghb0FkAqqw6m",
    "type": "string",
    "value": "3"
  },
  {
    "_id": "ylItEwV3NbEYvNLTT7ZJ",
    "isEnable": true,
    "label": "Preferred Smoker",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "izq7BzxYYNkkl2aQZ8Ix",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "ymvZntPOqj5BCeCWkdCz",
    "isEnable": true,
    "label": "已覆核",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "ywtFvCsvf8AgjGBuiijM",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "yqVCCZhucP39B6p4KFtD",
    "isEnable": true,
    "label": "7年",
    "level": 2,
    "orderId": 6,
    "parentFolderId": "UvTp7t1NY2LshB8qX2fN",
    "type": "string",
    "value": "7"
  },
  {
    "_id": "yrSDjsBIGdqClwpG89rO",
    "isEnable": true,
    "label": "總監",
    "level": 2,
    "orderId": 7,
    "parentFolderId": "xD8JURe8xQMPdzTFq6Mr",
    "type": "string",
    "value": "50"
  },
  {
    "_id": "ywtFvCsvf8AgjGBuiijM",
    "isEnable": true,
    "key": "reviewres",
    "label": "ReviewRes",
    "level": 1,
    "orderId": 8,
    "parentFolderId": "4OUCdTrn3y7rQ4plRZ4K",
    "type": "folder"
  },
  {
    "_id": "z3kVkhhhI1ak5ga1HHRu",
    "isEnable": true,
    "label": "已婚",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "udOuC5Os60L5Sy19ehIV",
    "type": "string",
    "value": "2"
  },
  {
    "_id": "z6BihAIGJQlKvuxnZgt8",
    "isEnable": true,
    "label": "考慮中(資金不夠)",
    "level": 2,
    "orderId": 4,
    "parentFolderId": "s28P6IZDghb0FkAqqw6m",
    "type": "string",
    "value": "5"
  },
  {
    "_id": "zA5GU0MnXNWczkVwOZd6",
    "isEnable": true,
    "label": "現金",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "1xbDRXSyPYxPaoFWpfxG",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "zCdU4c5w08lCq9qPQcFp",
    "isEnable": true,
    "label": "0月",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "aF96UbqIOzd4TSEs1Yum",
    "type": "string",
    "value": "0"
  },
  {
    "_id": "zKRmX2JmJweSQluOIQSI",
    "isEnable": true,
    "label": "人資簽核中",
    "level": 2,
    "orderId": 3,
    "parentFolderId": "lLwmxE1E8ZEOQ9zQjhu6",
    "type": "string",
    "value": "4"
  },
  {
    "_id": "zL2i0f2sk7uV1xDzOnPE",
    "isEnable": true,
    "label": "訪客",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "xD8JURe8xQMPdzTFq6Mr",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "zVHd8umbgPU94B1TNlmq",
    "isEnable": true,
    "label": "退回給申請人",
    "level": 2,
    "orderId": 2,
    "parentFolderId": "CXfQz0XsYwxKzjbwDakh",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "zXJdKOGKG83mU3Hnmddk",
    "isEnable": true,
    "label": "2014",
    "level": 2,
    "orderId": 14,
    "parentFolderId": "to3fzclrFTw8TRDELjBw",
    "type": "string",
    "value": "2014"
  },
  {
    "_id": "zm0w60YLwyggAcewKLoC",
    "isEnable": true,
    "label": "6月底/12月底",
    "level": 2,
    "orderId": 0,
    "parentFolderId": "ZZAqUG88UhFEyXou9rCM",
    "type": "string",
    "value": "1"
  },
  {
    "_id": "zruu4QXzfCtCEpDAtK3u",
    "isEnable": true,
    "label": "已完成",
    "level": 2,
    "orderId": 1,
    "parentFolderId": "FROlrfDUnGyLWY4HzGEC",
    "type": "string",
    "value": "2"
  }
];
const strAll = str.concat(str2)
export default strAll;
// export default strAll;