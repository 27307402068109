const data = {
  temple: [
    {
      "_id": "0",
      "name": "不需印",
      "order_id": 0,
      "id": "0"
    },
    {
      "_id": "1",
      "field_type": "6",
      "nowform": "11",
      "nowform_list": "15",
      "id": "1",
      "nowform_label": "16",
      "name": "一般牌位 (蓮花1)",
      "order": 1
    },
    // {
    //   "_id": "2",
    //   "nowform": "12",
    //   "nowform_list": "15",
    //   "id": "2",
    //   "nowform_label": "16",
    //   "name": "超渡牌 (蓮花1)",
    //   "field_type": "6",
    //   "order": 2
    // },
    {
      "_id": "3",
      "field_type": "6",
      "nowform": "13",
      "nowform_list": "15",
      "id": "3",
      "nowform_label": "16",
      "name": "一般牌位 (蓮花2)",
      "order": 3
    },
    // {
    //   "_id": "4",
    //   "field_type": "6",
    //   "nowform": "14",
    //   "nowform_list": "15",
    //   "id": "4",
    //   "nowform_label": "16",
    //   "name": "超渡牌 (蓮花2)",
    //   "order": 4
    // },
    {
      "_id": "4",
      "id": "4",
      "name": "油燈牌",
      "order": 4
    },
    {
      "_id": "5",
      "field_type": "6",
      "name": "桌牌",
      "id": "5",
      "order": 5
    },
    {
      "_id": "6",
      "name": "功德名條",
      "id": "6",
      "order": 6
    },
    {
      "_id": "7",
      "name": "功德名條+桌牌",
      "id": "7",
      "order": 7
    },
    {
      "_id": "8",
      "id": "8",
      "field_type": "5",
      "name": "光明燈 (大)",
      "order": 8
    },
    {
      "_id": "9",
      "id": "9",
      "field_type": "5",
      "name": "光明燈 (小)",
      "order": 9
    },
    // {
    //   "_id": "jSyFHy7gcE",
    //   "name": "卍字",
    //   "field_type": "6",
    //   "isopen": "1",
    //   "religion_type": "1",
    //   "nowform": "2",
    //   "id": "jSyFHy7gcE",
    //   "order": 5
    // },
  ],
  dao: [
    {
      "_id": "0",
      "name": "不需印",
      "order_id": 0,
      "id": "0"
    },
    {
      "_id": "2Yo2hdoDuJ",
      "field_type": "4",
      "nowform": "35",
      "nowform_list": "64",
      "id": "2Yo2hdoDuJ",
      "name": "消災補運",
      "order": 23,
    },
    {
      "_id": "JY8ouHbK8K",
      "field_type": "3",
      "nowform": "51",
      "nowform_list": "53",
      "id": "JY8ouHbK8K",
      "name": "太歲星君植福",
      "order": 29,
    },
    {
      "_id": "5wM6A3Kmp9",
      "field_type": "4",
      "nowform": "66",
      "nowform_list": "64",
      "id": "5wM6A3Kmp9",
      "name": "財利燈",
      "order": 22,
    },
    {
      "_id": "PG6mXdjzNd",
      "name": "元神燈(只有清單)",
      "field_type": "4",
      "nowform": "30",
      "nowform_list": "64",
      "id": "PG6mXdjzNd",
      "order": 19,
    },
    {
      "_id": "CCLSZRmrMS",
      "field_type": "2",
      "nowform": "34",
      "nowform_list": "64",
      "id": "CCLSZRmrMS",
      "name": "禮斗文疏",
      "order": 30,
    },
    {
      "_id": "2ARqT5H8s6",
      "nowform": "61",
      "nowform_list": "64",
      "id": "2ARqT5H8s6",
      "name": "祈福 - 業障",
      "field_type": "4",
      "order": 25,
    },
    {
      "_id": "L7bTYCXQBC",
      "field_type": "4",
      "nowform": "63",
      "nowform_list": "64",
      "id": "L7bTYCXQBC",
      "name": "祈福 - 身體",
      "order": 26,
    },
    {
      "_id": "gZuoCeBfZs",
      "id": "gZuoCeBfZs",
      "field_type": "6",
      "name": "功德名牌",
      "order": 6
    },
    {
      "_id": "X3pmLDYFNB",
      "nowform": "80",
      "nowform_list": "64",
      "id": "X3pmLDYFNB",
      "nowform_label": "71",
      "name": "禳燈消災植福",
      "field_type": "5",
      "nowform_table": "73",
      "order": 31,
    },
    {
      "_id": "f53NjXi4hm",
      "field_type": "4",
      "nowform": "36",
      "nowform_list": "64",
      "id": "f53NjXi4hm",
      "name": "智慧燈",
      "order": 20,
    },
    {
      "_id": "mJ7JNybss3",
      "nowform": "33",
      "nowform_list": "64",
      "id": "mJ7JNybss3",
      "name": "財神燈",
      "field_type": "4",
      "order": 21,
    },
    {
      "_id": "paR2rsc269",
      "field_type": "3",
      "nowform": "52",
      "nowform_list": "64",
      "id": "paR2rsc269",
      "name": "太歲流年表",
      "order": 28,
    },
    {
      "_id": "rBcvHGskSa",
      "field_type": "4",
      "nowform": "62",
      "nowform_list": "64",
      "id": "rBcvHGskSa",
      "name": "祈福 - 事業",
      "order": 24,
    },
    {
      "_id": "wS4gE6mw6m",
      "id": "wS4gE6mw6m",
      "field_type": "6",
      "name": "功德名牌+桌牌",
      "order": 7
    },
    {
      "_id": "vemyD5nCTS",
      "nowform": "65",
      "nowform_list": "64",
      "id": "vemyD5nCTS",
      "name": "祈福 - 文昌",
      "field_type": "4",
      "order": 27,
    },
    {
      "_id": "rkF3t9yfXz",
      "nowform": "31",
      "id": "rkF3t9yfXz",
      "name": "申報表格(空白)",
      "field_type": "1",
      "order": 16,
    },
    {
      "_id": "zNtm8FMe6M",
      "nowform_label": "32",
      "name": "正度附度甲狀",
      "field_type": "1",
      "nowform_table": "39",
      "nowform": "40",
      "nowform_list": "38",
      "id": "zNtm8FMe6M",
      "nowform_card": "37",
      "order": 11,
    }
  ]
}

const res = (type = 'temple') => {
  const arr = data[type];
  let sel = {}
  arr.sort((a, b) => a.order - b.order).forEach(({ _id, name }) => sel = { ...sel, [_id]: name })

  return {
    arr,
    sel
  }
}

export default res