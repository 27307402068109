import React, { Component } from 'react'
import Moment from 'moment';

import { Container, Header, Form, Icon, Modal, Grid } from 'semantic-ui-react'

export default class Fee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {}
    };
  }


  render() {
    const { form } = this.state
    return (
      <>
        <Header as='h3'>学(寮)費管理システム</Header>
        <Form >
          <Form.Select
            label='物件'
            name="dormitoryId"
            value={form.dormitoryId || ''}
            onChange={this.handleChange}
            options={[]}
          />
          {/* {dormitorys.map(item => (<option key={item._id} value={item._id}>{item.dormitoryName}</option>))} */}
          <Form.Select
            label='部屋'
            name="roomId"
            value={form.roomId || ''}
            onChange={this.handleChange}
            options={[]}
          />
          {/* {room.filter(item => item.dormitoryId === dormitoryId).map(item => (<option key={item._id} value={item._id}>{item.roomFloor} {item.roomName}</option>))} */}
          <Form.Select
            label='顯示年份'
            name="showYearNum"
            value={form.showYearNum || ''}
            onChange={this.handleChange}
            options={[]}
          />
          {/* <option value='1'>一年</option>
                <option value='2'>兩年</option> */}
        </Form>
        <br />
        {/* {
          dormitoryId ?
            <ViewTuitionSystem {...this.props} contracts={contracts} payments={payments} showYearNum={showYearNum} />
            : '物件選択'
        } */}
      </>
    )
  }
}