import React, { Component, createRef } from 'react'
import { Button, Form, Icon, Image, Confirm, Segment, Header, Input, Label, Select, Ref, Grid, Sticky, Modal, Progress } from 'semantic-ui-react';

import { getLabelName2, getOptionsByKey, getOptionTextByValue } from 'views/widgets/FieldsRender';
import TeacherListTabs from './TeacherListTabs';
import Parse from '../../../widget/parse'

export default class TeacherPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false, // props.isEdit,
      doc: {}, // 原本資料
      form: {},  // 更改處
      teachersObj: {}, // 原本資料
      selTypeId: '1',
      optTeachers: [],
      resTeachers: [],
      teachers: [],
      searchText: '',
      formModal: {
        staffStatus: '',
        teacherNum: '',
        nameTeacher: '',
        nameTeacherFull: '',
        partTimeTeacher: '',
        startSemesterNum: '',
        endSemesterNum: '',
      },
      open: false,
      show: false,
      loading: false,
    };
    this.saveArrangement = this.saveArrangement.bind(this);
  }

  componentDidMount = async () => {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { match, history } = props
    const { isEdit, selTypeId } = this.state;
    const { _id } = match.params;
    // console.log(match, history)
    // if (!teachersL) {
    //   handleLazyLoad('teachers');
    // } else {

    const { companyObj } = this.props
    const teachers = await Parse.queryData('Teachers', { companyId: companyObj.objectId });
    this.setState({ teachers });

    if (!isEdit) {
      // const { match, teachers } = props;
      // const { _id } = match.params;
      let obj = {};
      if (_id) {
        obj = teachers.find(item => item.objectId === _id) || {};
      } else {
        console.log(teachers)
        obj = teachers[0];
        obj && history.push(`/admin/TeacherPage/${obj.objectId}`);
      }
      this.setState({ doc: obj, form: obj, teachersObj: obj }, () => this.teacherSelect(props));
    }
    // }
  }

  teacherSelect = (props = this.props) => {
    // const { match, history } = props
    const { selTypeId, teachers } = this.state;
    console.log(teachers)
    let currentTeachers = teachers.sort((a, b) => ('' + a.teacherNum).localeCompare(b.teacherNum));
    let optTeachers = currentTeachers.map(item => ({
      key: item.objectId, text: `${item.teacherNum || "(なし)"} ${item.teacherTitleText} ${item.nameTeacher}`, value: item.objectId, staffStatus: item.staffStatus
    }));
    let resTeachers = selTypeId !== '0' ? optTeachers.filter(item => item.staffStatus === selTypeId) : optTeachers
    // console.log(optTeachers)
    this.setState({ optTeachers, resTeachers });
  }

  isEmpty = (teachersObj) => {
    for (var key in teachersObj) {
      if (teachersObj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  handleUpdateSetting = (event, data) => {
    // console.log('handleUpdateSetting');
    // console.log(event, data);
    const { form, doc } = this.state;
    const { name, value } = data;
    // return;
    // doc[setting.key] = setting.value;

    if (this.isEmpty(form)) {
      this.setState({ form: { ...doc, [name]: value } });
    }
    else {
      this.setState({ form: { ...form, [name]: value } });
    }
    if (name === 'staffStatus') {
      console.log(value)
      this.handleFetch(this.props)
    }
  }

  setRelativeObj = (setting) => {
    const { form, teachersObj } = this.state;
    // this.setState({ form: { ...form, ...setting } });
    this.setState({ isEdit: false },
      () => {
        // this.setState({ doc: { ...doc, ...setting }, form: { ...form, ...setting } },
        this.setState({ teachersObj: { ...teachersObj, ...setting }, form: { ...form, ...setting } },
          () => { this.setState({ isEdit: true }) })
      });
  }

  saveUpdateSetting = () => {
    const { firebase, match, refetch } = this.props;
    const { form, isEdit } = this.state;

    // return;
    form._id = match.params._id;
    // console.log(form);
    firebase.firestore().collection("Teachers").doc(form._id)
      .update(form).then(docRef => {
        this.setState({ isEdit: false, doc: form });
        refetch('Teachers')
      });
  }

  cancelUpdateSetting = () => {
    const { form, teachersObj } = this.state;
    this.setState({ isEdit: false, form: teachersObj });
  }

  saveArrangement(arrangeArr) {
    this.setState({ form: { ...this.state.form, arrangeArr } });
  }

  handleClose = () => {
    this.setState({ show: false, form: { classSchool: '-1', teacher: '-1' } });
  }

  handleSubmit = () => {
    const { firebase, collection, list, optSet, formSet, refetch } = this.props;
    const { formModal, optTeachers } = this.state
    this.setState({ loading: true })
    // console.log(form);
    // return;
    const obj = {
      ...formModal
    }
    for (const key in obj) {
      const element = formSet.find(item => item.key === key);
      if (element && element.type === 'select') {
        obj[`${key}Text`] = getOptionTextByValue(optSet, element.value, obj[key]);
        // console.log("student method 508 ", element.value, form[key], getOptionTextByValue(optSet, element.value, form[key]));
      }
    }
    let newRow = [...optTeachers]
    const db = firebase.firestore();
    let ref = db.collection('Teachers').doc();
    if (ref.id) {
      obj._id = ref.id;
      obj.arrangeArr = []
      newRow.push({
        key: obj.objectId, text: `${obj.teacherNum || "(なし)"} ${obj.teacherTitleText} ${obj.nameTeacher}`, value: obj.objectId, staffStatus: obj.staffStatus
      })

      ref.set(obj).then(ref => {
        // console.log('Added document with ID: ', ref.id);
        refetch('Teachers')
        this.setState({
          loading: false, show: false,
          formModal: {
            staffStatus: '',
            teacherNum: '',
            nameTeacher: '',
            nameTeacherFull: '',
            partTimeTeacher: '',
            startSemesterNum: '',
            endSemesterNum: '',
          },
          optTeachers: newRow, resTeachers: newRow, teachersObj: obj, doc: obj, form: obj,
        })
        this.props.history.push(`/admin/TeacherPage/${obj.objectId}`);
      })
    }
  }

  // handleGoto = (event, data) => {
  //   // let fieldName = data.name;
  //   let fleldVal = data.value;
  //   const { match, students, history } = this.props
  //   const { list = "1" } = match.params;
  //   history.push(`/StudentPage/${list}/${fleldVal}`);

  //   const obj = students.find(item => item._id === fleldVal) || {};
  //   // console.log(obj)
  //   this.setState({ doc: obj, form: obj, searchText: '' });
  // };

  handleChange = (event, { name, value }) => {
    const { match, history } = this.props
    const { work, optTeachers } = this.state

    // if(name==='')
    const { _id } = match.params;
    let resTeachers;
    if (name === 'selTypeId') {
      if (value === '0') {
        resTeachers = optTeachers
        this.setState({ resTeachers })
      } else
        resTeachers = optTeachers.filter(item => item.staffStatus === value)
      this.setState({ resTeachers })
    }
    this.setState({ [name]: value })
  };

  handleChangeModal = (event, { name, value }) => {
    const { formModal } = this.state
    // this.setState({ [fieldName]: fleldVal });
    if (name === '_value0') {
      this.setState({ formModal: { ...formModal, [name]: value, value0: value } })
    } else if (name === '_value1') {
      this.setState({ formModal: { ...formModal, [name]: value, value1: value } })
    } else {
      this.setState({ formModal: { ...formModal, [name]: value } })
    }
  };

  handleClick = (event, d) => {
    let fleldVal = event.target.value;
    const { match, teachers, history } = this.props
    history.push(`/admin/TeacherPage/${fleldVal}`);

    const obj = teachers.find(item => item.objectId === fleldVal) || {};
    this.setState({ doc: obj, form: obj, searchText: '', teachersObj: obj });
  }

  contextRef = createRef()

  handleSearch = (e, { value }) => {
    const { optTeachers } = this.state;

    if (value) {
      // 搜尋值中間可以插入任意值 例如搜尋'程雪原' 可以找到 '程 雪原'
      const looseSearchValue = RegExp(value.split('').reduce((pattern, item) => pattern + item + '.*', '.*'));

      const resTeachers = optTeachers.filter(item => item.text.match(looseSearchValue));
      this.setState({ resTeachers, searchText: value });
      // console.log(res)
    } else {
      this.setState({ resTeachers: optTeachers, searchText: value });
    }
  }

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0, disabled: true });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false, error: true });
    console.error(error);
  };

  handleUploadSuccess = async filename => {
    const { firebase, match } = this.props;
    const { form, downloadURLs, filenames } = this.state

    // const downloadURL = this.props.firebase
    const downloadURL = await this.props.firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL();

    this.setState(oldState => ({
      filenames: [...oldState.filenames, filename],
      downloadURLs: [...oldState.downloadURLs, downloadURL],
      uploadProgress: 100,
      isUploading: false,
      form: { ...form, photo: downloadURL, photos: filename },
      disabled: false
    }))

  };
  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  removeRow = () => {
    const { firebase, match, history, refetch, teachers } = this.props
    const { optTeachers } = this.state
    const { _id } = match.params;
    let newRow = [...optTeachers]
    this.setState({ loading: true })

    firebase.firestore().collection('Teachers').doc(_id).delete().then(() => {
      this.close();

      refetch('Teachers');
      let findIndex = newRow.findIndex(item => item.key === _id)
      newRow.splice(findIndex, 1);
      // console.log(newRow)
      this.setState({ doc: teachers[0], form: teachers[0], teachersObj: teachers[0], isEdit: false, resTeachers: newRow, loading: false }, () => {
        history.push(`/admin/TeacherPage/${teachers[0]._id}`);
      })
    })
  }
  render() {
    const { optSet, formSet, match, history, semesterYear, semester, systemObj, teachers } = this.props;
    const { doc, form, isEdit, selTypeId, resTeachers, searchText, show, loading, teachersObj, formModal } = this.state;

    const isWorkArr = [
      { key: 'isWorkOption1', text: '在勤', value: '1' },
      { key: 'isWorkOption2', text: '離職', value: '2' },
    ]
    const isWorkArrMain = [
      { key: 'isWorkOption0', text: '全部', value: '0' },
      ...isWorkArr
    ]
    const isWorkArrModal = [
      { key: 'isWorkOption00', text: '-- 選択 --', value: ' ' },
      ...isWorkArr
    ]

    const staffNameArr = [
      { key: 'staffName0', text: '-- 選択 --', value: ' ' },
      { key: 'staffName1', text: '院長', value: '1' },
      { key: 'staffName2', text: '学院長輔佐', value: '2' },
      { key: 'staffName3', text: '主任', value: '3' },
      { key: 'staffName4', text: '副主任', value: '6' },
      { key: 'staffName5', text: '講師', value: '4' },
    ]
    const staffProArr = [
      { key: 'staffPro0', text: '-- 選択 --', value: ' ' },
      { key: 'staffPro1', text: '専任', value: '1' },
      { key: 'staffPro2', text: '非常勤', value: '2' }
    ]

    const semesterArr = [
      { key: '2020,A', text: '2020A (20.10.1~21.3.31)', value: '2020,A' },
      { key: '2020,S', text: '2020S (20.4.1~20.9.30)', value: '2020,S' },
      { key: '2019,A', text: '2019A (19.10.1~20.3.31)', value: '2019,A' },
      { key: '2019,S', text: '2019S (19.4.1~19.9.30)', value: '2019,S' },
      { key: '2018,A', text: '2018A (18.10.1~19.3.31)', value: '2018,A' },
      { key: '2018,S', text: '2018S (18.4.1~18.9.30)', value: '2018,S' },
      { key: '2017,A', text: '2017A (17.10.1~18.3.31)', value: '2017,A' },
      { key: '2017,S', text: '2017S (17.4.1~17.9.30)', value: '2017,S' },
      { key: '2016,A', text: '2016A (16.10.1~17.3.31)', value: '2016,A' },
      { key: '2016,S', text: '2016S (16.4.1~16.9.30)', value: '2016,S' },
      { key: '2015,A', text: '2015A (15.10.1~16.3.31)', value: '2015,A' },
      { key: '2015,S', text: '2015S (15.4.1~15.9.30)', value: '2015,S' },
      { key: '2014,A', text: '2014A (14.10.1~15.3.31)', value: '2014,A' },
      { key: '2014,S', text: '2014S (14.4.1~14.9.30)', value: '2014,S' },
      { key: '2013,A', text: '2013A (13.10.1~14.3.31)', value: '2013,A' },
      { key: '2013,S', text: '2013S (13.4.1~13.9.30)', value: '2013,S' },
      { key: '2012,A', text: '2012A (12.10.1~13.3.31)', value: '2012,A' },
      { key: '2012,S', text: '2012S (12.4.1~12.9.30)', value: '2012,S' },
      { key: '2011,A', text: '2011A (11.10.1~12.3.31)', value: '2011,A' },
      { key: '2011,S', text: '2011S (11.4.1~11.9.30)', value: '2011,S' },
      { key: '1990,A', text: '1990A (90.10.1~91.3.31)', value: '1990,A' },
      { key: '1990,S', text: '1990S (90.4.1~90.9.30)', value: '1990,S' },
    ]

    const semesterArrStart = [
      { key: 'semesterArrIn0', text: '-- 選択 --', value: ' ' },
      ...semesterArr
    ]
    const semesterArrEnd = [
      { key: 'semesterArrOut0', text: '尚未離職', value: ' ' },
      ...semesterArr
    ]

    return (
      <div className='content'>
        <div style={{ padding: '10px' }}>
          <Ref innerRef={this.contextRef}>
            <Grid>
              <Grid.Column width={2}>
                <Sticky context={this.contextRef}>
                  <Header as='h3'>教員基本情報</Header>
                  <Form size='small'>
                    {/* <Input labelPosition='left' > */}
                    {/* <Label color={'teal'}> <Icon name='newspaper' />表示</Label> */}
                    <Select style={{
                      minWidth: '100px',
                      // borderTopLeftRadius: '0',
                      // borderBottomLeftRadius: '0'
                    }} compact
                      options={isWorkArrMain}
                      onChange={this.handleChange}
                      disabled={isEdit}
                      value={selTypeId}
                      name='selTypeId' />
                    {/* </Input>{' '} */}
                    <br />
                    {/* <Input labelPosition='left' > */}
                    {/* <Label color={'green'}> <Icon name='file alternate outline' />学期</Label> */}

                    <br />
                    {!isEdit ?
                      <Button
                        style={{ marginTop: '4px' }}
                        // floated='right' 
                        size='tiny' primary onClick={() => this.setState({ show: true })} icon="add user" content="新規作成" /> : null}{' '}
                    <br />
                    <Form.Input
                      style={{ marginTop: '4px', marginBottom: '4px' }}

                      placeholder='Search...'
                      disabled={isEdit}
                      onChange={this.handleSearch}
                      value={searchText}
                    />
                  </Form>
                  <select multiple className="form-control ui form fluid search dropdown"
                    style={{ height: '600px', width: '100%', fontSize: '11px' }} name="teacherSel"
                    onChange={this.handleClick}
                    // value={[doc._id]}
                    disabled={isEdit}
                  >
                    {resTeachers && resTeachers.map(item => <option key={item.key} className="item" value={item.value}>{item.text}</option>)}
                  </select>
                </Sticky>
              </Grid.Column>
              <Grid.Column width={14}>
                <Segment
                  clearing
                  vertical
                >
                  <Button floated='left' as='label' basic inverted
                    content={<>
                      {/* {isEdit ? <FileUploader
                        hidden
                        accept="image/*"
                        name="avatar"
                        randomizeFilename
                        storageRef={this.props.firebase.storage().ref("images")}
                        onUploadStart={this.handleUploadStart}
                        onUploadError={this.handleUploadError}
                        onUploadSuccess={this.handleUploadSuccess}
                        onProgress={this.handleProgress}
                      /> : null} */}
                      <div style={{ position: 'relative' }}>
                        <Image src={form && form.photo || 'https://react.semantic-ui.com/images/wireframe/square-image.png'} size='tiny'
                          style={{ marginBottom: '0px' }} />
                        {isEdit ? <Icon name='edit' style={{
                          color: '#000',
                          position: 'absolute',
                          bottom: 5,
                          right: 0,
                          fontSize: 18,
                          background: 'rgba(255,255,255,0.7)'
                        }} /> : null}
                      </div>
                    </>}
                  />
                  <Header floated='left' as='h2'>
                    <Header.Content>
                      {form && form.nameTeacher}
                      <Header.Subheader>
                        {form && form.teacherNum ? <><span className="text-muted">{getLabelName2(formSet, 'teacherNum')}:</span>&nbsp;{form.teacherNum}&nbsp;&nbsp;</> : ''}
                        {form && form.teacherTitleText ? <><span className="text-muted">職位:</span>&nbsp;{form.teacherTitleText}&nbsp;&nbsp;</> : ''}
                        {form && form.partTimeTeacherText ? <><span className="text-muted"></span>&nbsp;{form.partTimeTeacherText}&nbsp;&nbsp;</> : ''}
                      </Header.Subheader>
                      {/* <br /> */}
                      <div style={{ marginTop: '15px' }}>
                        {isEdit ? <Button positive size='small' onClick={this.saveUpdateSetting} icon='save' content='保存' /> : <Button primary size='small' onClick={() => this.setState({ isEdit: true })} icon='edit' content="編集" />}
                        {isEdit ? <Button basic size='small' onClick={this.cancelUpdateSetting} icon='remove' content='キャンセル' /> : null}
                    &nbsp;&nbsp;&nbsp;&nbsp;{isEdit ? <Button negative size='small' onClick={this.open} icon='delete' content='削除' /> : null}
                        <Confirm content='削除してもいいですか？' confirmButton='はい' cancelButton='いいえ' open={this.state.open} onCancel={this.close} onConfirm={this.removeRow} loading={loading} />
                      </div>
                    </Header.Content>
                  </Header>
                </Segment>
                <br />
                <TeacherListTabs
                  teacher={teachers}
                  {...this.props}
                  doc={form}
                  teachersObj={teachersObj}
                  isEdit={isEdit}
                  saveArrangement={this.saveArrangement}
                  handleUpdateSetting={this.handleUpdateSetting}
                  arrangeArr={form.arrangeArr ? form.arrangeArr : []}
                />
              </Grid.Column>
            </Grid>
          </Ref>

          <Modal size='tiny' open={show} onClose={this.handleClose} centered={false}>
            <Modal.Header>新規作成</Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Group widths='equal'>
                  <Form.Select label='状態' name='staffStatus' options={isWorkArrModal} defaultValue={formModal._id ? formModal.staffStatus : ' '} onChange={this.handleChangeModal} />
                  <Form.Field control={Input} label='教員番号' placeholder='教員番号' onChange={this.handleChangeModal} name='teacherNum' value={formModal.teacherNum ? formModal.teacherNum : ''} />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Field control={Input} label='氏名 (簡稱)' placeholder='氏名 (簡稱)' onChange={this.handleChangeModal} name='nameTeacher' value={formModal.nameTeacher ? formModal.nameTeacher : ''} />
                  <Form.Field control={Input} label='氏名 (全名)' placeholder='氏名 (全名)' onChange={this.handleChangeModal} name='nameTeacherFull' value={formModal.nameTeacherFull || ''} />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Field control={Select} label='職名' options={staffNameArr} onChange={this.handleChangeModal} name='teacherTitle' defaultValue={formModal._id ? formModal.teacherTitle : ''} />
                  <Form.Field control={Select} label='専任/非専任' options={staffProArr} onChange={this.handleChangeModal} name='partTimeTeacher' defaultValue={formModal._id ? formModal.partTimeTeacher : ''} />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Field control={Select} label='就職学期' options={semesterArrStart} onChange={this.handleChangeModal} name='startSemesterNum' defaultValue={formModal.startSemesterNum || ''} />
                  <Form.Field control={Select} label='離職学期' options={semesterArrEnd} onChange={this.handleChangeModal} name='endSemesterNum' defaultValue={formModal.endSemester || ''} />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.handleClose} >キャンセル</Button>
              <Button primary icon='checkmark' labelPosition='right' onClick={this.handleSubmit} content='保存' loading={loading} />
            </Modal.Actions>
          </Modal>
          {this.state.isUploading && <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 10000,
            width: '100vw',
            height: '100vh',
            background: 'rgba(0,0,0,0.87)',
            overflow: 'hodden'
          }}>
            <Progress percent={this.state.progress} indicating error={this.state.error} progress />
          </div>}
        </div >
      </div>);
  }
}
