import React, { Component } from 'react'
import { Header, Form, Icon, Button, Checkbox } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Moment from 'moment';
import CKEditor from '@ckeditor/ckeditor5-react';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ClassicEditor from 'widget/ckeditor';

const columns = () => ([
  { title: '主旨', field: 'subject' },
  { title: '收件人', field: 'To_name_cht', editable: 'never' },
  {
    title: '副本(逗號分隔)', field: 'cc', render: rowData => {
      let str = ''
      if (rowData.cc.length) {
        const arr = rowData.cc
        arr.forEach(item => str = str + item + ',')
        return <>{str}</>
      } else {
        return <></>
      }
    }
  },
  {
    title: '密件副本(逗號分隔)', field: 'bcc', render: rowData => {
      let str = ''
      if (rowData.bcc.length) {
        const arr = rowData.bcc
        arr.forEach(item => str = str + item + ',')
        return <>{str}</>
      } else {
        return <></>
      }
    }
  },
  { title: '狀態', field: 'status_text', editable: 'never' },
  {
    title: '排程時間', field: 'scheduleAt',
    render: rowData => Moment(rowData.scheduleAt.iso).format('YYYY-MM-DD HH:mm:ss'), editable: 'never'
  },
  {
    title: '附件', field: 'attachments', render: rowData => {
      let showArr = []
      if (rowData && rowData.attachments) {
        rowData.attachments.forEach(item => {
          showArr.push(<a href={item.path} target="_blank" rel="noopener noreferrer">{item.filename}</a>)
          showArr.push(<br />)
        })
        return showArr
      }
    },
    editable: 'never'
  },
]);

let editor = {}

export default class After1b extends Component {
  state = {
    dataArr: [],
    show: false,
    isEdit: false,
    rowData: {},
    selDataArr: [],
    selDelete: false,
    rows: [],
  }

  componentDidMount() {
    this.handleFetch();
  }

  handleFetch = async () => {
    const { companyObj } = this.props;
    const companyId = companyObj.objectId;
    const email = await Parse.queryData('email', { companyId, status: '99', type: 'timeEmail' }, { orderBy: 'scheduleAt_descend' });
    this.setState({ dataArr: email, email })
  }

  handleBlur = (name, value) => {
    const { rowData } = this.state
    this.setState({ rowData: { ...rowData, [name]: value } })
  }

  handleSave = async () => {
    const { rowData } = this.state
    let obj = rowData
    await Parse.saveData('email', obj);
    window.alert("儲存成功")
  }

  handleClose = () => {
    this.handleFetch()
    this.setState({ show: false });
  }

  handleIsEdit = () => {
    editor.isReadOnly = false
    this.setState({ isEdit: true });
  }

  handleIsNotEdit = () => {
    editor.isReadOnly = true
    this.setState({ isEdit: false });
  }

  handleUpdate = async () => {
    const { newData, monthSel, bankaccSel } = this.state

    await Parse.saveData('email', newData);
    this.setState({ oldData: {}, newData: {} })
  }

  handleDelete = async () => {
    const { oldData, monthSel, bankaccSel, user, rows } = this.state
    let data = rows;
    const promises = []
    for (let i = 0; i < oldData.attachments.length; i++) {
      let obj = {
        objectId: oldData.attachments[i].id,
      }
      promises.push(Parse.deleteData('clientFile', obj));
    }
    await Promise.all(promises).catch(error => console.log("error", error));
    await Parse.deleteData('email', oldData);
    const index = data.indexOf(oldData);
    if (index !== -1) {
      data.splice(index, 1);
    }
    this.setState({ oldData: {}, rows })
  }

  handleSelDelete = async () => {
    const { rows, user, dataArr } = this.state

    let data = []
    const promises = []
    if (window.confirm('確定刪除?')) {
      data = dataArr;
      rows.forEach(item => {
        const index = data.indexOf(item);
        data.splice(index, 1);
        for (let i = 0; i < item.attachments.length; i++) {
          let obj = {
            objectId: item.attachments[i].id,
          }
          promises.push(Parse.deleteData('clientFile', obj));
        }
        Parse.deleteData('email', item);
      })
      await Promise.all(promises).catch(error => console.log("error", error));
      window.alert('刪除成功')
      this.setState({ rows: [], dataArr: data })
    }
  }

  handleCheck = (e, { name, value, checked, rowData }) => {
    const { rows } = this.state
    if (name === "schedule") {
      if (checked === true) {
        rows.push(rowData)
        this.setState({ rows })
      } else {
        let data = rows;
        const index = data.indexOf(rowData);
        data.splice(index, 1);
        this.setState({ rows: data })
      }
    }
  }


  render() {
    const { companyObj, auth = {} } = this.props
    const { dataArr, rowData, show, isEdit, rows } = this.state;

    return (<>
      <Header inverted={companyObj.inverted} as='h3' style={{ margin: '1rem auto' }}>2-1-2 排程郵件</Header>
      <br />
      <br />
      <MaterialTable
        components={{
          Toolbar: props => (<div>
            <div style={{ width: '150px', float: 'right', textAlign: 'right', padding: '15px' }}>
              <Button content='複選刪除' size='small' disabled={rows.length > 0 ? false : true} onClick={this.handleSelDelete} />
            </div>
            <MTableToolbar {...props} />
            <div style={{ clear: 'both' }}></div>
          </div>),
        }}
        localization={localization()}
        style={{ marginBottom: '2rem' }}
        columns={columns(this.props)}
        options={{
          pageSize: dataArr.length || 10,
          search: true,
          // selection: true
        }}
        // onSelectionChange={this.handleSel}
        actions={[
          {
            icon: 'view_headline',
            tooltip: '查看',
            hidden: !auth.edit,
            onClick: (e, Data) => this.setState({ show: true, rowData: Data })
          },
          rowData => ({
            icon: () => <Checkbox onChange={this.handleCheck} name="schedule" rowData={rowData} checked={rows.indexOf(rowData) !== -1} />,
            hidden: !auth.edit,
          })
        ]}
        data={dataArr}
        title="排程郵件"
        editable={{
          isEditHidden: () => !auth.edit,
          isDeleteHidden: () => !auth.edit,
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...dataArr];
              const index = data.indexOf(oldData);
              let cc = newData.cc.length ? newData.cc : []
              let bcc = newData.bcc.length ? newData.bcc : []

              if (newData.cc.length && typeof newData.cc === 'string') {
                cc = newData.cc.split(',')
              }
              if (newData.bcc.length && typeof newData.bcc === 'string') {
                bcc = newData.bcc.split(',')
              }

              const obj = {
                ...newData,
                cc,
                bcc,
              }
              data[index] = obj;
              // handleState('people0', data);
              this.setState({ dataArr: data, newData: obj, oldData }, () => resolve());
            }).then(() => this.handleUpdate(0)),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...dataArr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              // handleState('people0', data);
              this.setState({ dataArr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete(0)),
        }}
      />
      <br />
      <br />
      <Modal isOpen={show} toggle={this.handleClose} centered={false}>
        <ModalHeader>郵件內容
           <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
        </ModalHeader>
        <ModalBody><Form>
          <CKEditor
            editor={ClassicEditor}
            data={rowData ? rowData.html : null}
            onInit={iniEditor => {
              // You can store the "editor" and use when it is needed.
              console.log('Editor is ready to use!', iniEditor);
              iniEditor.isReadOnly = true;
              editor = iniEditor
              // this.setState({ editor })
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              this.handleBlur("html", data)
              // console.log({ event, editor, data });
            }}
          // onBlur={(event, editor) => {
          //   console.log('Blur.', editor);
          // }}
          // onFocus={(event, editor) => {
          //   console.log('Focus.', editor);
          // }}
          />
        </Form>
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button onClick={!isEdit ? this.handleIsEdit : this.handleSave} inverted color='green' icon={!isEdit ? 'edit' : 'save'} content={!isEdit ? '編輯' : '儲存'} disabled={!auth.edit} />
          {!isEdit ? <Button onClick={this.handleClose} inverted color='red' icon='delete' content='返回' />
            : <Button onClick={this.handleIsNotEdit} inverted color='red' icon='delete' content='取消' />}
        </ModalFooter>
      </Modal>
    </>)
  }
}