
import React, { Component } from 'react'
import { Header, Form, Checkbox } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Moment from 'moment';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { Row, Col, } from "reactstrap";

import {
  columns1, columns2, columns3,
  columns11, columns12, columns13, columns14, columns15, columns16, columns17, FormRadio
} from './PrintDef';
import ModalPdf from 'widget/pdf/ModalPdf';
import { dateCol, mgyearOpt, monthOpt, exportPdf } from 'views/web/CustomMaterialTable';
import ckeditor from '@ckeditor/ckeditor5-react';
const TODAY = Moment(new Date()).format('YYYY-MM-DD');
class Print extends Component {
  state = {
    dataArr: [],
    dataArr2: [],
    templelevel: [],
    loading: false,
    value: "11",
    value2: '81',
    yearSel: '110', // 法會年度
    yearSel2: '110', // 點燈年度
    pray1OptId: '1',
    pray2OptId: '-1',
    praytypeId: '-1',
    pray2Opt: [],
    type: '1',
    columns: [],
    // value3a: 'ArNNHi', // CwvwiY2ZP2h
    value3b: '3eN5CH9g8N', // 9g8Ny5nsc6r
    printDate: TODAY,
    disabled: false,
    lightType: '1',
    createdAt1D: '',
    createdAt1T: '00:00',
    createdAt2D: '',
    createdAt2T: '23:59',
    ptypeOpt: [],
    year: 'mgyearall',
    visiblePray1Id: [],
    praytypevisible: false,
    selectData: {},
    loadingPray1: true,
    daoPrayOptId: '1',
    serviceTypeOpt: [],
    serviceTypeId: '-1',
    fieldTypeId: '1',
    mailThis1: true,
    post5code1: true,
    postcode1: true,
    addr1: true,
    cellphone1: true,
    telephone1: true,
    email1: true,
    templeClass1: true,
    templeLevel1: true,
    memberId1: true,
    longLive1: true,
    lotus1: true,
    lotus21: true,
  }

  async componentDidMount() {
    const { companyObj } = this.props
    const companyId = companyObj.objectId;

    if (companyObj.webtypeId === "dao") {
      const value = '140'
      const type = "4";
      // const yearSel = '110'
      const columns = columns11();

      // const serviceTypeOpt = [
      //   { key: '1', value: 'xqZKGby4M8', text: '元神燈' },
      //   { key: '2', value: 'kjqdZdQGfx', text: '智慧燈' },
      //   { key: '3', value: '3DuyPpq6Af', text: '財神燈' },
      //   { key: '4', value: 'RqRzFpmEdi', text: '消災補運' }, // 135
      //   { key: '5', value: 'QtPLM7eo26', text: '業障' }, //161
      //   { key: '6', value: 'RuCnXptgGz', text: '事業' }, //162
      //   { key: '7', value: 'HGqzFb5bSj', text: '身體' }, //163
      // ]
      // const serviceTypeId = 'xqZKGby4M8';
      this.setState({
        type, value, columns,
        // serviceTypeOpt, serviceTypeId
      });
    }

    const pray1Obj = await Parse.getSelection('pray1', { companyId }, { name: 'value', sort: 'order' });
    const pray2Obj = await Parse.getSelection('pray2', { companyId }, { name: 'name', sort: 'name' });
    const praytypeObj = await Parse.getSelection('praytype', { companyId }, { name: 'value', sort: 'name' });
    const templelevelObj = await Parse.getSelection('templelevel', { companyId }, { name: 'value', sort: 'name' });
    templelevelObj.opt.unshift({ key: 'all', value: 'all', text: '全部' })
    const selectData = { pray1Obj, pray2Obj, praytypeObj, templelevelObj }
    const pray1OptId = (companyObj && companyObj.pray1IdDef) || (pray1Obj.arr.length && pray1Obj.arr[0].objectId) || ''
    this.setState({ selectData, pray1OptId, loadingPray1: false }, () => this.handleFetch());
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = (props = this.props) => { // 抓要列印的內容
    this.handlePraying2Sel()
  }

  handleSelect = (e, { name, value }) => {
    this.setState({ [name]: value }, () => this.handleCheckbox(0));
  }

  handleFilter = (e, { name, value }) => {
    if (value === 'TaeXPxaaIa') {    //選到法會
      this.setState({ praytypevisible: true });
    } else {
      this.setState({ praytypevisible: false });
    }
    console.log(name, value);
    this.setState({ [name]: value }, () => this.handlePraying2Sel());//, () => this.handleFetch())
  }

  datetimeLocalChange = (field, e) => {
    this.setState({ [field]: e.target.value }, () => this.handlePraying2Sel());
  };

  handlePraying2Sel = async () => {
    const { companyObj, } = this.props;
    const { pray1OptId, columns, selectData, prayOptId } = this.state
    const { pray1Obj = {}, pray2Obj = {}, praytypeObj = {} } = selectData

    if (!columns.length) {
      this.setState({ columns: columns1(praytypeObj.sel) })
    }
    const praytypeObjArr = praytypeObj.arr || []
    const pray2ObjArr = pray2Obj.arr || []
    const pray1Id = pray1OptId || (companyObj && companyObj.pray1IdDef) || (pray1Obj.arr.length && pray1Obj.arr[0].objectId) || '';
    // console.log('handlePraying2Sel', pray1Id, praytypeArr);
    if (pray1Id) {
      const ptypeOpt = praytypeObjArr.sort((a, b) => a.order - b.order).map(item => {
        if (!item.only_pray) {
          return { key: item.objectId, value: item.objectId, text: item.value }
        } else if (item.only_pray === pray1Id) {
          return { key: item.objectId, value: item.objectId, text: '[專用] ' + item.value };
        } else {
          return false;
        }
      }).filter(item => item);
      // console.log(ptypeOpt)

      if (companyObj.webtypeId === "dao") {
        this.fetchPrintList()
      } else {
        const pray2Opt = [{ key: '-1', value: '-1', text: '全部' }]
          .concat(pray2ObjArr.filter(item => item.pray1Id === pray1Id).sort((a, b) => a.order - b.order)
            .map(item => ({ key: item.objectId, value: item.objectId, text: item.value })));
        this.setState({ pray2Opt, ptypeOpt }, () => this.fetchPrintList());
      }
    } else {
      this.fetchPrintList();
    }
  }

  handleChange = (e, { name, value }) => {
    const { companyObj, templeclass, templelevel } = this.props
    const { selectData, mailThis1, post5code1, postcode1, addr1, cellphone1, telephone1, email1, templeClass1, templeLevel1, memberId1, longLive1, lotus1, lotus21 } = this.state
    const praytypeId = selectData && selectData.praytypeObj && selectData.praytypeObj.sel
    // console.log(praytype);//, templeclass, templelevel)
    // console.log(templeclass, templelevel);
    let serviceTypeId = '-1';
    let type = '0', columns = [];
    if (companyObj.webtypeId === "temple") {
      if (value === '11' || value === '18' || value === '2' || value === '3') { // 法會
        type = '1';
        columns = columns1(praytypeId);
      } else if (value === '51' || value === '53' || value === '55') { // 燈號
        type = '2';
        columns = columns2();
      } else if (value === '71' || value === '75') { // 名冊
        type = '3';
        columns = columns3(templeclass, templelevel);
      } else if (value === '73') { // 名冊
        type = '3';
        columns = columns3(templeclass, templelevel, { mailThis1, post5code1, postcode1, addr1, cellphone1, telephone1, email1, templeClass1, templeLevel1, memberId1, longLive1, lotus1, lotus21 });
      } else if (value === '72') {
        columns = []
      }
      // else if (value === '75') {
      //   type = '4'
      //   columns = columns3(templeclass, templelevel);
      // }

      if (type === '3') {
        if (value === '71') { // 簽到名冊 護法
          this.setState({ disabled: true, value3b: '8nmf3bZZeC' })
        } else if (value === '73') { // 社員名冊 社員
          this.setState({ disabled: true, value3b: '3eN5CH9g8N' })
        } else {
          this.setState({ disabled: false })
        }
      }

      if (type !== this.state.type || value === '75' || value === '73') {
        this.setState({ value, type, columns, dataArr: [] }, () => this.fetchPrintList());
      } else {
        this.setState({ value, columns })
      }
    } else if (companyObj.webtypeId === "dao") { ///////// 道教 ///////////
      let serviceTypeOpt = [];
      let fieldTypeId = '1';
      if (value === '141' || value === '138' || value === '140') {  // 法會總覽
        fieldTypeId = '1';
        type = '4';
        columns = columns11();
      } else if (value === '132' || value === '139' || value === '137') { // 超度
        fieldTypeId = '1';
        type = '5';
        columns = columns12();
      } else if (value === '134' || value === '164' || value === 'b01') { // 斗燈
        fieldTypeId = '2';
        type = '6';

        // serviceTypeOpt.push({ key: '0', value: '-1', text: '-- 全部 --' });
        console.log(selectData);
        serviceTypeOpt = selectData.praytypeObj.arr
          .filter(item => item.field_type === '2')
          .sort((a, b) => a.order - b.order)
          // .forEach(({ objectId, value }) => serviceTypeOpt.push({ key: objectId, value: objectId, text: value }))
          .map(({ objectId, value }) => ({ key: objectId, value: objectId, text: value }))

        columns = columns13();
        serviceTypeId = serviceTypeOpt[0].key;
      } else if (value === '151' || value === '152' || value === '153') { // 太歲
        fieldTypeId = '3'; //??
        type = '7';
        if (value === '152') {
          columns = [];
        } else {
          columns = columns14();
        }
        // } else if (value === '133' || value === '135' || value === '161' || value === '162' || value === '163') { // 祈福
      } else if (value === 'a01' || value === 'a02' || value === 'a03') { // 祈福
        fieldTypeId = '4';
        type = '8';
        const arr = [
          // { key: '0', value: '-1', text: '-- 全部 --' },
          { key: '2', value: 'kjqdZdQGfx', text: '智慧燈' },
          { key: '3', value: '3DuyPpq6Af', text: '財神燈' },
          { key: '4', value: 'RqRzFpmEdi', text: '消災補運' }, // 135
          { key: '5', value: 'QtPLM7eo26', text: '業障' }, //161
          { key: '6', value: 'RuCnXptgGz', text: '事業' }, //162
          { key: '7', value: 'HGqzFb5bSj', text: '身體' }]; //163]
        if (value === 'a01') { // 疏文
          serviceTypeOpt = [...arr]
          serviceTypeId = 'kjqdZdQGfx';
        } else {
          serviceTypeOpt = [{ key: '1', value: 'xqZKGby4M8', text: '元神燈' }, ...arr]
          serviceTypeId = 'xqZKGby4M8';
        }

        columns = columns15();
      } else if (value === '121' || value === 'd00') { // 光明燈
        fieldTypeId = '5';
        type = '9';
        columns = columns16();
      } else if (value === '191' || value === '192' || value === '193') { // 名冊
        fieldTypeId = '0';
        type = '10';
        columns = columns17();
      } else if (value === '131') { // 131
        type = '99';
        columns = [];
      } else if (value === 'b01' || value === 'c01' || value === 'd01') {  //申報結果
        type = '11';
        if (value === 'b01') {
          fieldTypeId = '2';
        } else if (value === 'c01') {
          fieldTypeId = '3';
        } else if (value === 'd01') {
          fieldTypeId = '5';
        }
        columns = columns15();

      }

      if (type !== this.state.type) {
        this.setState({ fieldTypeId, serviceTypeId, serviceTypeOpt, value, type, columns, dataArr: [] }, () => this.fetchPrintList());
      } else {
        this.setState({ serviceTypeId, serviceTypeOpt, value })
      }
    }
  }

  handleChange2 = (e, { name, value }) => { // 更換點燈的
    // const { companyObj } = this.props
    if (name === 'value2') {
      let lightType = '1'
      switch (value) {
        case '81':
        default:
          lightType = '1'
          break;
        case '82':
          lightType = '3'
          break;
        case '83':
          lightType = '5'
          break;
        case '84':
          lightType = '7'
          break;
      }
      this.setState({ lightType })
    }

    // console.log('handleChange2', name, value)
    this.setState({ [name]: value }, () => this.fetchPrintList());
  }

  handleDateChange = (e, { value }) => this.setState({ printDate: value })  // 自訂內容

  fetchPrintList = async () => {
    const { companyObj } = this.props
    const { type, value3b, daoPrayOptId, yearSel, yearSel2, pray1OptId, pray2OptId, fieldTypeId, serviceTypeId,
      praytypeId, ptypeOpt, createdAt1D, createdAt2D, createdAt1T, createdAt2T, value, value2, } = this.state;
    const year = yearSel || (companyObj && companyObj.yearDef) || '';

    // console.log('fetchPrintList', year, 'type', type, 'fieldTypeId', fieldTypeId, 'pray1OptId', pray1OptId);
    const companyId = companyObj.objectId;
    this.setState({ dataArr: [], dataArr2: [], loading: true });
    if (companyObj.webtypeId === "dao") {
      // let data = []
      // if (fieldTypeId !== '0') {
      if (type === '4' || type === '11') { // 撈法會總覽
        // console.log(fieldTypeId);

        const dataArr = await Parse.queryData('praying1', { fieldTypeId, companyId, year, pray1Id: pray1OptId })
        const dataArr2 = await Parse.queryData('praying2', { fieldTypeId, companyId, year, pray1Id: pray1OptId })
        console.log('year', year, 'pray1Id', pray1OptId, 'praytypeId', praytypeId,);

        this.setState({ dataArr, dataArr2, loading: false })
      } else if (type === '5' || type === '7' || type === '9') {
        const dataArr2 = await Parse.queryData('praying2', { fieldTypeId, companyId, year, pray1Id: pray1OptId })
        console.log('year', year, 'pray1Id', pray1OptId, 'praytypeId', fieldTypeId,);

        this.setState({ dataArr2, loading: false })
      } else if (type === '6' || type === '8') { // type === '8' 祈福
        const praytypeId = serviceTypeId;;
        console.log('year', year, 'pray1Id', pray1OptId, 'praytypeId', praytypeId,);
        const dataArr = await Parse.queryData('praying1', { companyId, year }); //  serviceTypeId: daoPrayOptId
        const dataArr2 = await Parse.queryData('praying2', { companyId, year, praytypeId, pray1Id: pray1OptId })

        this.setState({ dataArr, dataArr2, loading: false })
      } else if (0) {
        // data = await Parse.queryData('praying2', { fieldTypeId, companyId, year }); //  serviceTypeId: daoPrayOptId
        // console.log(data);
        const db1 = Parse.Object.extend("praying2");
        const query = new Parse.Query(db1);
        // const query = new Parse.Query("praying2");
        // if (pray2OptId && pray2OptId !== '-1') {
        //   query.equalTo("pray2Id", pray2OptId);
        // }
        // if (praytypeId && praytypeId !== '-1') {
        //   query.equalTo("praytypeId", praytypeId);
        //   // console.log(praytypeId)
        // }
        let d1 = null;
        if (createdAt1D && createdAt1T) {
          d1 = new Date(createdAt1D + 'T' + createdAt1T + ':00.000Z')
          //   query.greaterThanOrEqualTo('createdAt', d)
          //   console.log('greaterThanOrEqualTo', d)
        }
        let d2 = null;
        if (createdAt2D && createdAt2T) {
          d2 = new Date(createdAt2D + 'T' + createdAt2T + ':00.000Z')
          //   query.lessThanOrEqualTo('updatedAt', d);
          //   console.log('lessThanOrEqualTo', d)
        }
        // console.log("praying2", companyObj.objectId, year, pray1OptId)
        if (pray1OptId !== '1') {
          query.equalTo("pray1Id", pray1OptId);
        }
        query.equalTo("companyId", companyId);
        query.equalTo("year", year);
        query.equalTo("fieldTypeId", fieldTypeId);
        query.limit(10000);
        const snap = await query.find();
        const data1 = snap.map(data => data.toJSON());

        const dataArr = data1.filter(item => {
          const res1 = d1 ? new Date(item.createdAt) >= d1 : true;
          const res2 = d2 ? new Date(item.createdAt) <= d2 : true;
          return res1 && res2
        })

        // console.log(dataArr)
        // this.setState({ dataArr: data1, loading: false })
        this.setState({ dataArr, loading: false })
      }

      // console.log(data);
    } else { // 佛教
      if (type === '1') { // 法會
        const pray1Id = pray1OptId || (companyObj && companyObj.pray1IdDef) || '';
        //pray1OptId 1pzegsRGTy
        if (pray1Id === '1pzegsRGTy') {
          this.setState({ dataArr: [], loading: true }, async () => {
            const db1 = Parse.Object.extend("donate");
            const query = new Parse.Query(db1);

            query.equalTo("companyId", companyId);
            // query.equalTo("year", year);

            query.equalTo("pray1Id", pray1Id);
            query.equalTo("isPrint", "1")
            query.limit(10000);
            const snap = await query.find();
            const data1 = snap.map(data => data.toJSON());
            // console.log(data1)
            const test = []
            data1.forEach((item, index) => {
              let newYear = ''
              if ((item.donateDate && item.donateDate.slice(0, 4)) || '' === '2020') {
                newYear = '109'
              } else {
                newYear = '110'
              }
              const obj = {
                year: newYear,
                ordernum: `R00000${index}`,
                pray1Id: item.pray1Id || '',
                pray1Text: item.pray1Text || '',
                pray2Id: item.pray2Id || '',
                pray2Text: item.pray2Text || '',
                // pray2OrderId: praytypeId === 'eKDN75SQuG' ? 3 : 1,
                // praytypeOrderId: praytypeId === 'eKDN75SQuG' ? 3 : 1,
                // praytypeId: praytypeId === 'eKDN75SQuG' ? 'eKDN75SQuG' : "tFMQN7fYA1",
                // praytypeText: praytypeId === 'eKDN75SQuG' ? '消災(小牌)' : '消災',
                praytypeId: item.praytypeId || '',
                praytypeText: item.praytypeText || '',
                livename: item.name || '',
                serial: `00${index}`

              }
              test.push(obj)
            })
            if (pray2OptId && pray2OptId !== '-1' && praytypeId === '-1') {
              this.setState({ dataArr: test.filter(item => item.pray2Id === pray2OptId && item.year === year), loading: false })
            } else if (pray2OptId && pray2OptId === '-1' && praytypeId !== '-1') {
              this.setState({ dataArr: test.filter(item => item.praytypeId === praytypeId && item.year === year), loading: false })
            } else if (pray2OptId && pray2OptId !== '-1' && praytypeId !== '-1') {
              this.setState({ dataArr: test.filter(item => item.pray2Id === pray2OptId && item.praytypeId === praytypeId && item.year === year), loading: false })
            } else {
              this.setState({ dataArr: test.filter(item => item.year === year), loading: false })
            }
          })
        } else {

          if (year && pray1Id) {
            this.setState({ dataArr: [], loading: true }, async () => {
              const db1 = Parse.Object.extend("praying2");
              const query = new Parse.Query(db1);
              // const query = new Parse.Query("praying2");

              if (pray2OptId && pray2OptId !== '-1') {
                query.equalTo("pray2Id", pray2OptId);
              }
              if (praytypeId && praytypeId !== '-1') {
                query.equalTo("praytypeId", praytypeId);
                // console.log(praytypeId)
              }
              let d1 = null;
              if (createdAt1D && createdAt1T) {
                d1 = new Date(createdAt1D + 'T' + createdAt1T + ':00.000Z')
                //   query.greaterThanOrEqualTo('createdAt', d)
                //   console.log('greaterThanOrEqualTo', d)
              }
              let d2 = null;
              if (createdAt2D && createdAt2T) {
                d2 = new Date(createdAt2D + 'T' + createdAt2T + ':00.000Z')
                //   query.lessThanOrEqualTo('updatedAt', d);
                //   console.log('lessThanOrEqualTo', d)
              }
              // console.log("praying2", companyObj.objectId, year, pray1Id)
              query.equalTo("companyId", companyId);
              query.equalTo("year", year);
              query.equalTo("pray1Id", pray1Id);
              query.limit(10000);
              const snap = await query.find();
              const data1 = snap.map(data => data.toJSON());
              // console.log(data1)

              const dataArr = data1.filter(item => {
                const res1 = d1 ? new Date(item.createdAt) >= d1 : true;
                const res2 = d2 ? new Date(item.createdAt) <= d2 : true;
                return res1 && res2
              }).sort((a, b) => a.serial.localeCompare(b.serial))

              // this.setState({ dataArr: data1, loading: false })
              this.setState({ dataArr, loading: false })
            })
          }
        }
      } else if (type === '2') { // 點燈
        const year = yearSel2 || (companyObj && companyObj.yearDef2) || '';
        const db1 = Parse.Object.extend("lighting2");
        const query = new Parse.Query(db1);
        query.equalTo("companyId", companyId);
        query.equalTo("year", year);

        if (value2 === '81') {
          query.equalTo("p1", '-1');
        } else if (value2 === '82') {
          query.equalTo("p3", '-1');
        } else if (value2 === '83') {
          query.equalTo("p5", '-1');
        } else if (value2 === '84') {
          query.equalTo("p7", '-1');
        }

        let d1 = null;
        if (createdAt1D && createdAt1T) {
          d1 = new Date(createdAt1D + 'T' + createdAt1T + ':00.000Z')
          console.log(createdAt1D + 'T' + createdAt1T)
          // query.greaterThanOrEqualTo('createdAt', new Date(createdAt1D + 'T' + createdAt1T))
        }
        let d2 = null;
        if (createdAt2D && createdAt2T) {
          d2 = new Date(createdAt2D + 'T' + createdAt2T + ':00.000Z')
          // query.lessThanOrEqualTo('createdAt', new Date(createdAt2D + 'T' + createdAt2T));
        }
        query.limit(10000);
        const snap = await query.find();
        const data1 = snap.map(data => data.toJSON());
        if (value2 === '81') {
          data1.sort((a, b) => a.p1Num.localeCompare(b.p1Num));
        } else if (value2 === '82') {
          data1.sort((a, b) => a.p3Num.localeCompare(b.p3Num));
        } else if (value2 === '83') {
          data1.sort((a, b) => a.p5Num.localeCompare(b.p5Num));
        } else if (value2 === '84') {
          data1.sort((a, b) => a.p7Num.localeCompare(b.p7Num));
        }

        const dataArr = data1.filter(item => {
          const res1 = d1 ? new Date(item.createdAt) >= d1 : true;
          const res2 = d2 ? new Date(item.createdAt) <= d2 : true;
          return res1 && res2
        })
        this.setState({ dataArr, loading: false })
      } else if (type === '3') { //名冊/郵寄
        if (companyId === "efnvfE1gJS") {
          let query = new Parse.Query('donate');
          query.equalTo("companyId", companyId);

          query.exists("name")
          query.exists("addr")
          query.notEqualTo("name", '')
          query.notEqualTo("addr", '')
          query.limit(30000);
          const snap = await query.find();
          const data = snap.map(data => data.toJSON())
          this.setState({ dataArr: data, loading: false })
        } else {
          const db1 = Parse.Object.extend("people");
          const query = new Parse.Query(db1);
          query.equalTo("companyId", companyId);

          if (companyId === 'efnvfE1gJS') {

          } else {
            query.equalTo("mailThis", '-1');
            if (value3b === 'TaeXPxaaIa' || value3b === 'all') { // 法會

            } else {
              query.equalTo("templeLevel", value3b);
            }
          }
          // if (value3b === 'all') {
          //   const db1 = Parse.Object.extend("people");
          //   const query = new Parse.Query(db1);
          //   query.equalTo("companyId", companyId);
          //   query.equalTo("mailThis", '-1');
          // }
          query.limit(30000);
          if (value === '75' && value3b !== 'all') { // 沒有全部的名單
            const dbPray = Parse.Object.extend("praying1");
            const queryPray = new Parse.Query(dbPray);
            queryPray.equalTo("companyId", companyId);
            query.equalTo("mailThis", '-1');
            queryPray.containedIn("year", [yearSel, String(Number(yearSel) - 1), String(Number(yearSel) - 2)]);
            queryPray.limit(30000);
            const snapPray = await queryPray.find()
            const dataPray = snapPray.map(data => data.toJSON());
            const allFamilyId = dataPray.map(item => item.familyId)
            const familyIdUnique = Array.from(new Set(allFamilyId));
            query.containedIn("familyId", familyIdUnique);
            // query.equalTo("mailThis", '-1');
            const snap = await query.find();
            const data1 = snap.map(data => data.toJSON());
            this.setState({ dataArr: data1, dataArr2: data1, loading: false })
          } else {
            const snap = await query.find();
            const data1 = snap.map(data => data.toJSON());
            this.setState({ dataArr: data1, dataArr2: data1, loading: false })
          }
        }
      }
      //  else if (type === '4') {
      //   const db1 = Parse.Object.extend("praying1");
      //   const query = new Parse.Query(db1);
      //   query.equalTo("companyId", companyId);
      //   query.containedIn("year", [yearSel, String(Number(yearSel) - 1), String(Number(yearSel) - 2)]);
      //   query.limit(30000);
      //   const snap = await query.find()
      //   const data1 = snap.map(data => data.toJSON());
      //   const allFamilyId = data1.map(item => item.familyId)
      //   const familyIdUnique = Array.from(new Set(allFamilyId));
      //   const db2 = Parse.Object.extend("people");
      //   const query2 = new Parse.Query(db2);
      //   query2.equalTo("mailThis", '-1');
      //   query2.containedIn("familyId", familyIdUnique);
      //   query2.limit(30000);
      //   const snap2 = await query2.find()
      //   const dataArr = snap2.map(data => data.toJSON());
      //   this.setState({ dataArr, loading: false })
      // }
    }
  }

  handleUpdate = async () => {
    // const { companyObj } = this.props
    const { newData, type, pray2 } = this.state
    if (type === '1') {
      console.log(type, newData)
      await Parse.saveData('praying2', newData);


      // db.collectionGroup('people').where('familyId', '==', newData.familyId)
      //   .get().then(snap => {
      //     const data = [];
      //     snap.forEach(doc => {
      //       const id = doc.data().id;
      //       data.push({ _id: id, ...doc.data() })
      //     });

      //     const people1 = data.filter(item => item.isLive === 1);
      //     const people0 = data.filter(item => item.isLive === 0);

      //     // console.log(people1, people0);
      //     this.setState({ people0, people1 });
      //     let livenameArr = newData.livename_text ? newData.livename_text.split(',') : []
      //     let livenameData = []
      //     if (livenameArr && livenameArr.length) {
      //       livenameArr.forEach(item => {
      //         people1.forEach(item1 => {
      //           if (item1.name === item) {
      //             livenameData.push(item1)
      //           }
      //         })
      //       })
      //       newData.people1 = livenameData
      //     }
      //     let passnameArr = newData.passname_text ? newData.passname_text.split(',') : []
      //     let passnameData = []
      //     if (passnameArr && passnameArr.length) {
      //       passnameArr.forEach(item => {
      //         people0.forEach(item1 => {
      //           if (item1.name === item) {
      //             passnameData.push(item1)
      //           }
      //         })
      //       })
      //       newData.people0 = passnameData
      //     }
      //   }, err => {
      //     console.log(`Encountered error: ${ err }`);
      //   });

      // // console.log(newData)
      // await docRef.update(newData);

    } else if (type === '2') {
      const { newData } = this.state;
      await Parse.saveData('lighting2', newData);
    } else if (type === '3') {
      const { newData } = this.state;
      await Parse.saveData('people', newData);
    }
  }

  handleDelete = async () => {
    const { oldData, type } = this.state

    if (type === "1") {
      await Parse.deleteData('praying2', oldData);
    } else if (type === '2') {
      await Parse.deleteData('lighting2', oldData);
    }
    this.setState({ oldData: {} }, () => { window.alert("已刪除") })
  }

  handleCheckbox = async (value) => {
    const { visiblePray1Id, year } = this.state
    const typeArr = [...visiblePray1Id];

    if (value !== 0) {
      const index = typeArr.indexOf(value)
      if (index !== -1) {
        typeArr.splice(index, 1);
      } else {
        typeArr.push(value);
      }
    }

    if (!typeArr || !typeArr.length) {
      return
    }
    const db1 = Parse.Object.extend("praying1");
    const query = new Parse.Query(db1);
    query.equalTo("year", year);
    query.equalTo("pray1Id", typeArr);
    const snap = await query.find();
    const dataArr = snap.map(data => data.toJSON());

    this.setState({ dataArr, loading: false, visiblePray1Id: typeArr });
  }

  handleCheck = (e, { name, checked }) => {
    this.setState({ [name]: checked }, () => this.handleColumns())
  }

  handleColumns = () => {
    const { templeclass, templelevel } = this.props
    const { mailThis1, post5code1, postcode1, addr1, cellphone1, telephone1, email1, templeClass1, templeLevel1, memberId1, longLive1, lotus1, lotus21 } = this.state
    const columns = columns3(templeclass, templelevel, { mailThis1, post5code1, postcode1, addr1, cellphone1, telephone1, email1, templeClass1, templeLevel1, memberId1, longLive1, lotus1, lotus21 });
    this.setState({ columns })
  }

  handleCheck1 = (e, { name, checked }) => {
    this.setState({ [name]: checked }, () => this.handleCheckFilter())
  }

  handleCheckFilter = () => {
    const { dataArr2, mailThis, post5code, postcode, addr, cellphone, telephone, email } = this.state
    let data = []
    if (mailThis || post5code || postcode || addr || cellphone || telephone || email) {
      data = dataArr2
      if (mailThis) {
        data = data.filter(item => item.mailThis === '-1')
      }
      if (postcode) {
        data = data.filter(item => item.postcode)
      }
      if (post5code) {
        data = data.filter(item => item.post5code)
      }
      if (addr) {
        data = data.filter(item => item.addr)
      }
      if (cellphone) {
        data = data.filter(item => item.cellphone)
      }
      if (telephone) {
        data = data.filter(item => item.telephone)
      }
      if (email) {
        data = data.filter(item => item.email)
      }
    } else {
      data = dataArr2
    }
    this.setState({ dataArr: data, loading: false })
  }

  render() {
    const { companyObj } = this.props
    const { dataArr, yearSel, yearSel2, pray1OptId, pray2OptId, loadingPray1, serviceTypeOpt, serviceTypeId,
      loading, createdAt1D, createdAt2D, createdAt1T, createdAt2T, value, value2, type, columns, value3b,
      printDate, disabled, lightType, praytypeId, year, selectData, pray2Opt, ptypeOpt, dataArr2 } = this.state;
    const { pray1Obj = {}, templelevelObj = {} } = selectData
    const pray1Opt = pray1Obj.opt || [];
    const templelevelOpt = templelevelObj.opt || [];
    const style = { color: companyObj.inverted ? 'rgba(255,255,255,.9)' : '#2c2c2c' };
    // console.log(type, serviceTypeId)
    return (<div className="content">
      <Form inverted={!!companyObj.inverted}>
        {companyObj.webtypeId === "temple" ? <>
          <h4 style={style}>列印種類</h4>
          <Row>
            <Col lg="3" md="3" sm='12'>
              <h6 style={style}>法會</h6>
              {[
                { id: '11', label: '大、小牌位' },
                { id: '18', label: '大、小牌位(合併)' },
                { id: '2', label: '油燈牌' },
                { id: '3', label: '疏文' },
              ].map(item => <FormRadio key={`r${item.id} `} {...item} value={value} onChange={this.handleChange} />)}
            </Col>
            <Col lg="3" md="3" sm='12'>
              <h6 style={style}>點燈</h6>
              {[
                { id: '51', label: '標籤' },
                { id: '53', label: '簡易疏文' },
                { id: '55', label: '詳細疏文' },
              ].map(item => <FormRadio key={`r${item.id} `} {...item} value={value} onChange={this.handleChange} />)}
            </Col>
            <Col lg="3" md="3" sm='12'>
              <h6 style={style}>名冊</h6>
              {[
                { id: '72', label: '名冊封面' },
                { id: '71', label: '簽到名冊' },
                { id: '73', label: '社員名冊' },
              ].map(item => <FormRadio key={`r${item.id} `} {...item} value={value} onChange={this.handleChange} />)}
            </Col>
            <Col lg="3" md="3" sm='12'>
              <h6 style={style}>郵寄</h6>
              {[
                // { id: '74', label: '郵寄貼紙 7x2' },
                { id: '75', label: '郵寄貼紙 10x2' },
                // { id: '76', label: '郵寄信封' },
              ].map(item => <FormRadio key={`r${item.id} `} {...item} value={value} onChange={this.handleChange} />)}
            </Col>
          </Row>
          <Row columns='equal' style={{ position: 'relative', zIndex: 99 }}>
            <Col md="3">
              <h4 style={style}>篩選條件</h4>
              {type === '1' ? <>
                <Form.Select
                  label='法會年度'
                  // options={[{ key: 'yearAll', value: 'mgyearall', text: '全部' }].concat(mgyearOpt)}
                  options={mgyearOpt}
                  name='yearSel'//103~109
                  onChange={this.handleFilter}
                  value={yearSel}
                />
                <Form.Select // pray1
                  loading={loadingPray1}
                  label='法會名稱'
                  options={pray1Opt}
                  name='pray1OptId'
                  onChange={this.handleFilter}
                  value={pray1OptId || (companyObj && companyObj.pray1IdDef)}
                />
                <Form.Select //pray2
                  loading={loadingPray1}
                  label='法會項目'
                  options={pray2Opt}
                  name='pray2OptId'
                  onChange={this.handleFilter}
                  value={pray2OptId}
                />
                <Form.Select //praytype
                  label='牌位種類'
                  options={[{ key: '-1', value: '-1', text: '全部' }].concat(ptypeOpt)}
                  name='praytypeId'
                  onChange={this.handleFilter}
                  value={praytypeId || '-1'}
                />
              </> : null}
              {type === '2' ? <>
                <Form.Select
                  label='點燈年度'
                  // options={[{ key: 'yearAll', value: 'mgyearall', text: '全部' }].concat(mgyearOpt)}
                  options={mgyearOpt}
                  name='yearSel2'//103~109
                  onChange={this.handleFilter}
                  value={yearSel2 || (companyObj && companyObj.yearDef2)}
                />
                <Form.Group inline>
                  <Form.Radio
                    label='光明燈'
                    value='81'
                    checked={value2 === '81'}
                    name='value2'
                    onChange={this.handleChange2}
                  />
                </Form.Group>
                <Form.Group inline>
                  <Form.Radio
                    label='藥師燈'
                    value='82'
                    checked={value2 === '82'}
                    name='value2'
                    onChange={this.handleChange2}
                  />
                </Form.Group>
                <Form.Group inline>
                  <Form.Radio
                    label='光明大燈'
                    value='83'
                    checked={value2 === '83'}
                    name='value2'
                    onChange={this.handleChange2}
                  />
                </Form.Group>
                <Form.Group inline>
                  <Form.Radio
                    label='藥師大燈'
                    value='84'
                    checked={value2 === '84'}
                    name='value2'
                    onChange={this.handleChange2}
                  />
                </Form.Group>
              </> : null}
              {type === '3' ? <>
                {/* <Form.Select
                  label='班別'
                  // options={[{ key: '-1', value: '-1', text: '全部' }].concat(templeclassOpt)}
                  options={templeclassOpt}
                  name='value3a'
                  onChange={this.handleFilter}
                  value={value3a}
                /> */}
                {companyObj.objectId === 'efnvfE1gJS' && (value === '73') ? <></>
                  : <Form.Select // pray1
                    label='類別'
                    // options={[{ key: '-1', value: '-1', text: '全部' }].concat(templelevelOpt)}
                    disabled={disabled}
                    options={templelevelOpt}
                    name='value3b'
                    onChange={this.handleFilter}
                    value={value3b}
                  />}
                {companyObj.objectId === 'efnvfE1gJS' && (value === '73') ? <>
                  <h6 style={style}>資料篩選</h6>
                  <Checkbox label='郵寄' style={{ marginRight: '15px' }} name='mailThis' onChange={this.handleCheck1} checked={this.state.mailThis} />
                  <Checkbox label='郵遞區號5碼' style={{ marginRight: '15px' }} name='post5code' onChange={this.handleCheck1} checked={this.state.post5code} />
                  <Checkbox label='郵遞區號' style={{ marginRight: '15px' }} name='postcode' onChange={this.handleCheck1} checked={this.state.postcode} />
                  <Checkbox label='地址' style={{ marginRight: '15px' }} name='addr' onChange={this.handleCheck1} checked={this.state.addr} />
                  <Checkbox label='手機' style={{ marginRight: '15px' }} name='cellphone' onChange={this.handleCheck1} checked={this.state.cellphone} />
                  <Checkbox label='電話' style={{ marginRight: '15px' }} name='telephone' onChange={this.handleCheck1} checked={this.state.telephone} />
                  <Checkbox label='Email' style={{ marginRight: '15px' }} name='email' onChange={this.handleCheck1} checked={this.state.email} />
                </> : null}
                {/* <Form.Select // pray1
                      label='年份'
                      // options={[{ key: '-1', value: '-1', text: '全部' }].concat(templelevelOpt)
                      options={[{ key: 'yearAll', value: 'mgyearall', text: '全部' }].concat(mgyearOpt)}
                      name='year'
                      onChange={this.handleSelect}
                      value={year}
                    /> */}
                {/* {praytypevisible && pray1Opt.map(item => <div><Checkbox label={item.text} onClick={() => this.handleCheckbox(item.key)} /></div>)} */}
              </> : null}
            </Col>
            <Col md="3">
              {companyObj.objectId === 'efnvfE1gJS' && (value === '73') ? <>
                <br />
                <h6 style={style}>欄位選擇</h6>
                <Checkbox label='郵寄' style={{ marginRight: '15px' }} name='mailThis1' onChange={this.handleCheck} checked={this.state.mailThis1} />
                <Checkbox label='郵遞區號5碼' style={{ marginRight: '15px' }} name='post5code1' onChange={this.handleCheck} checked={this.state.post5code1} />
                <Checkbox label='郵遞區號' style={{ marginRight: '15px' }} name='postcode1' onChange={this.handleCheck} checked={this.state.postcode1} />
                <Checkbox label='地址' style={{ marginRight: '15px' }} name='addr1' onChange={this.handleCheck} checked={this.state.addr1} />
                <Checkbox label='手機' style={{ marginRight: '15px' }} name='cellphone1' onChange={this.handleCheck} checked={this.state.cellphone1} />
                <Checkbox label='電話' style={{ marginRight: '15px' }} name='telephone1' onChange={this.handleCheck} checked={this.state.telephone1} />
                <Checkbox label='Email' style={{ marginRight: '15px' }} name='email1' onChange={this.handleCheck} checked={this.state.email1} />
                <Checkbox label='班別' style={{ marginRight: '15px' }} name='templeClass1' onChange={this.handleCheck} checked={this.state.templeClass1} />
                <Checkbox label='身份' style={{ marginRight: '15px' }} name='templeLevel1' onChange={this.handleCheck} checked={this.state.templeLevel1} />
                <Checkbox label='社員編號' style={{ marginRight: '15px' }} name='memberId1' onChange={this.handleCheck} checked={this.state.memberId1} />
                <Checkbox label='功德堂' style={{ marginRight: '15px' }} name='longLive1' onChange={this.handleCheck} checked={this.state.longLive1} />
                <Checkbox label='往生堂' style={{ marginRight: '15px' }} name='lotus1' onChange={this.handleCheck} checked={this.state.lotus1} />
                <Checkbox label='淨土堂' style={{ marginRight: '15px' }} name='lotus21' onChange={this.handleCheck} checked={this.state.lotus21} />
              </> : null}
              {type === '1' || type === '2' ? <>
                <h4 style={style}>建立時間</h4>
                <Form.Input
                  name='createdAt1'
                  type="date"
                  label='開始日期'
                  value={createdAt1D}
                  onChange={e => this.datetimeLocalChange('createdAt1D', e)}
                />
                <Form.Input
                  name='createdAt1'
                  type="time"
                  label='開始時間'
                  value={createdAt1T}
                  onChange={e => this.datetimeLocalChange('createdAt1T', e)}
                />
                <Form.Input
                  name='createdAt2'
                  type="date"
                  label='結束日期'
                  value={createdAt2D}
                  onChange={e => this.datetimeLocalChange('createdAt2D', e)}
                />
                <Form.Input
                  name='createdAt2'
                  type="time"
                  label='結束時間'
                  value={createdAt2T}
                  onChange={e => this.datetimeLocalChange('createdAt2T', e)}
                />
              </> : null}
            </Col>
            <Col md="3">
              <h4 style={style}>自訂內容</h4>
              <Form.Input
                name='printDate'
                type='date'
                label='列印日期'
                value={printDate}
                onChange={this.handleDateChange}
              />
            </Col>
            <Col md="3">
              <h4 style={style}>產生文件</h4>
              <ModalPdf
                {...this.props}
                // codekey='praypage1'
                params={{ companyObj }}
                selectData={selectData}
                arrObj={dataArr}
                nowform={value}
                type={type}
                printDate={printDate}
                lightType={lightType}
                btnStyle={{ float: 'right' }}
              />
            </Col>
          </Row>
        </> : null}
        {companyObj.webtypeId === "dao" ? <>
          <h4 style={style}>列印種類</h4>
          <Row>
            <Col lg="3" md="3" sm='12'>
              <h6 style={style}>法會</h6>
              {[
                { id: '140', label: '正度附度甲狀' },
                { id: '141', label: '申報結果' },
                { id: '138', label: '法會名冊' },
                { id: '132', label: '靈寶大法司' },
                { id: '139', label: '財庫封條' },
                { id: '137', label: '普渡桌名條' },
                { id: '131', label: '申報表格（空白）' },
              ].map(item => <FormRadio key={`r${item.id} `} {...item} value={value} onChange={this.handleChange} />)}
            </Col>
            <Col lg="3" md="3" sm='12'>
              <h6 style={style}>祈福</h6>
              {[
                // { id: 'bbb', label: '元神燈' },
                // { id: 'bbb', label: '智慧燈' },
                // { id: '133', label: '財神燈' },
                // { id: '135', label: '消災補運' },
                // { id: '161', label: '業障' },
                // { id: '162', label: '事業' },
                // { id: '163', label: '身體' },
                // { id: 'ccc', label: '清單' },
                { id: 'a01', label: '疏文' },
                { id: 'a02', label: '清單' },
                { id: 'a03', label: '申報結果' },
              ].map(item => <FormRadio key={`r${item.id} `} {...item} value={value} onChange={this.handleChange} />)}
              <h6 style={style}>斗燈</h6>
              {[
                { id: '134', label: '禮斗文疏' },
                { id: '164', label: '清單' },
                { id: 'b01', label: '申報結果' },
              ].map(item => <FormRadio key={`r${item.id} `} {...item} value={value} onChange={this.handleChange} />)}
            </Col>
            <Col lg="3" md="3" sm='12'>
              <h6 style={style}>太歲</h6>
              {[
                { id: '151', label: '疏文' },
                { id: '153', label: '清單' },
                { id: 'c01', label: '申報結果' },
                { id: '152', label: '流年表' },
              ].map(item => <FormRadio key={`r${item.id} `} {...item} value={value} onChange={this.handleChange} />)}
              <h6 style={style}>名冊</h6>
              {[
                { id: '191', label: '郵寄正面' },
                { id: '192', label: '請柬 背面' },
                { id: '193', label: '敬邀 正面' },
              ].map(item => <FormRadio key={`r${item.id} `} {...item} value={value} onChange={this.handleChange} />)}
            </Col>
            <Col lg="3" md="3" sm='12'>
              <h6 style={style}>光明燈</h6>
              {[
                { id: '121', label: '禳燈消災植福' },
                { id: 'd00', label: '清單' },
                { id: 'd01', label: '申報結果' },
                { id: 'd02', label: '標籤' },
                { id: 'd03', label: '燈位表' },
              ].map(item => <FormRadio key={`r${item.id} `} {...item} value={value} onChange={this.handleChange} />)}
            </Col>
          </Row>
          <Row columns='equal' style={{ position: 'relative', zIndex: 99 }}>
            <Col md="3">
              <h4 style={style}>篩選條件</h4>
              <Form.Select
                label='年度'
                options={mgyearOpt.sort((a, b) => b.value - a.value)}
                name='yearSel'//103~109
                onChange={this.handleChange2}
                value={yearSel}
              />
              <Form.Select // pray1
                loading={loadingPray1}
                label='法會名稱'
                options={pray1Opt}
                name='pray1OptId'
                onChange={this.handleChange2}
                value={pray1OptId || (companyObj && companyObj.pray1IdDef)}
              />
              {/* <Form.Select //pray2
                loading={loadingPray1}
                label='法會項目'
                options={pray2Opt}
                name='pray2OptId'
                onChange={this.handleFilter}
                value={pray2OptId}
              /> */}
              {type === '4' || type === '5' || type === '6' || type === '7' || type === '8' ? <>
                {type === '6' || type === '8' ? <Form.Select //praytype
                  label='申辦項目'
                  options={serviceTypeOpt}
                  name='serviceTypeId'
                  onChange={this.handleChange2}
                  value={serviceTypeId}
                /> : null}
                {/* <Form.Select //praytype
                  label='牌位種類'
                  options={[{ key: '-1', value: '-1', text: '全部' }].concat(ptypeOpt)}
                  name='praytypeId'
                  onChange={this.handleFilter}
                  value={praytypeId || '-1'}
                /> */}
              </> : null}
            </Col>
            <Col md="3">
              <h4 style={style}>建立時間</h4>
              <Form.Input
                name='createdAt1'
                type="date"
                label='開始日期'
                value={createdAt1D}
                onChange={e => this.datetimeLocalChange('createdAt1D', e)}
              />
              <Form.Input
                name='createdAt1'
                type="time"
                label='開始時間'
                value={createdAt1T}
                onChange={e => this.datetimeLocalChange('createdAt1T', e)}
              />
              <Form.Input
                name='createdAt2'
                type="date"
                label='結束日期'
                value={createdAt2D}
                onChange={e => this.datetimeLocalChange('createdAt2D', e)}
              />
              <Form.Input
                name='createdAt2'
                type="time"
                label='結束時間'
                value={createdAt2T}
                onChange={e => this.datetimeLocalChange('createdAt2T', e)}
              />
            </Col>
            <Col md="3">
              <h4 style={style}>自訂內容</h4>
              <Form.Input
                name='printDate'
                // type='date'
                label='欣逢'
                // value={printDate}
                onChange={this.handleDateChange}
              />
              <Form.Input
                name='printDate'
                // type='date'
                label='太歲'
                // value={printDate}
                onChange={this.handleDateChange}
              />
              <Form.Input
                name='printDate'
                type='date'
                label='列印日期'
                value={printDate}
                onChange={this.handleDateChange}
              />
            </Col>
            <Col md="3">
              <h4 style={style}>製作文件</h4>
              <ModalPdf
                {...this.props}
                arrObj={dataArr}
                nowform={value}
                type={type}
                printDate={printDate}
                lightType={lightType}
                btnStyle={{ float: 'right' }}
                params={{ companyObj, serviceTypeId, arrObj2: dataArr2 }}
                selectData={selectData}
              />
            </Col>
          </Row>
        </> : null}
      </Form>
      <br />
      <br />
      {columns.length ? <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '服務內容'),
          pageSizeOptions: [5, 10, 20, 50, 100, 250, 500, 1000],
          // pageSize: dataArr.length > 50 ? 50 : 20,
          pageSize: 20,
          search: false,
          tableLayout: 'fixed',
          exportAllData: true
        }}
        data={companyObj.webtypeId === 'temple' ? dataArr : dataArr2}
        title={`服務內容`}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              // if (!!newData.type && typeof newData.type !== 'undefined') {
              //   newData.praytypeText = praytypeSel[newData.type];
              // }
              // if (!!newData.pray2Id && typeof newData.pray2Id !== 'undefined') {
              //   const { pray2 } = this.props;
              //   const pray2Obj = pray2.find(item => item.id === newData.pray2Id);
              //   newData.pray2Text = pray2Obj.value;
              //   newData.pray2OrderId = pray2Obj.order;
              // }

              const data = [...dataArr];
              const index = data.indexOf(oldData);
              data[index] = newData;
              console.log(newData)
              this.setState({ dataArr: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...dataArr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              this.setState({ dataArr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      /> : null}
    </div>);
  }
}

export default Print;
