import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
const MapWrapper = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: 44.445248, lng: 26.099672 }}
      defaultOptions={{
        styles: [
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#e9e9e9"
              },
              {
                lightness: 17
              }
            ]
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5"
              },
              {
                lightness: 20
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff"
              },
              {
                lightness: 17
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#ffffff"
              },
              {
                lightness: 29
              },
              {
                weight: 0.2
              }
            ]
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff"
              },
              {
                lightness: 18
              }
            ]
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff"
              },
              {
                lightness: 16
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5"
              },
              {
                lightness: 21
              }
            ]
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#dedede"
              },
              {
                lightness: 21
              }
            ]
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                visibility: "on"
              },
              {
                color: "#ffffff"
              },
              {
                lightness: 16
              }
            ]
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                saturation: 36
              },
              {
                color: "#333333"
              },
              {
                lightness: 40
              }
            ]
          },
          {
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [
              {
                color: "#f2f2f2"
              },
              {
                lightness: 19
              }
            ]
          },
          {
            featureType: "administrative",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#fefefe"
              },
              {
                lightness: 20
              }
            ]
          },
          {
            featureType: "administrative",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#fefefe"
              },
              {
                lightness: 17
              },
              {
                weight: 1.2
              }
            ]
          }
        ],
        scrollwheel: false //we disable de scroll over the map, it is a really annoing when you scroll through page
      }}
    >
      <Marker position={{ lat: 44.445248, lng: 26.099672 }} />
    </GoogleMap>
  ))
);

function SectionContactUs2(props = {}) {
  const { form, imagePreviewUrl } = props;
  // console.log(form)
  const { img1File, text2 = 'text2', text3 = 'Give us a call', text4 = 'Name', text5 = 'Phone', text6 = 'ContactTime',
    text7 = 'Find us at the office', text8 = 'Address', text9 = 'Address', text10 = 'text10', text11 = '', text12 = '' } = form;
  // const { name, } = pageObj;
  const picUrl = imagePreviewUrl || (img1File && img1File.url) || '/hp1/24.jpg';
  // const picUrl = imagePreviewUrl || img1Url || '/hp1/24.jpg';
  return (
    <>
      <div className="section section-contactus cd-section" id="contact-us"
        style={{
          backgroundImage:
            "url(" + picUrl + ")"
        }}>
        {/* ********* CONTACT US 2 ********* */}
        <div className="contactus-2" >
          <div className="big-map" id="contactUsMap2" >
            {/* <MapWrapper
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=YOUR_KEY_HERE"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            /> */}
          </div>
          <Container >
            <Col md="6">
              <Card className="card-contact card-raised">
                <CardHeader className="header-raised header-primary text-center">
                  <CardTitle tag="h4">{text2}</CardTitle>
                </CardHeader>
                <Form id="contact-form2" method="post" role="form">
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <div className="info info-horizontal">
                          <div className="icon icon-info">
                            <i className="nc-icon nc-badge" />
                          </div>
                          <div className="description">
                            <h5 className="info-title">{text3}</h5>
                            <p>
                              {text4} <br />
                              {text5} <br />
                              {text6}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="info info-horizontal">
                          <div className="icon icon-danger">
                            <i className="nc-icon nc-pin-3" />
                          </div>
                          <div className="description">
                            <h5 className="info-title">
                              {text7}
                            </h5>
                            <p>
                              {text8} <br />
                              {text9}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="control-label">Name</label>
                          <Input
                            name="name"
                            placeholder="Full name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="control-label">Email</label>
                          <Input
                            name="email"
                            placeholder="Email"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <label className="control-label">Message</label>
                      <Input
                        id="messages"
                        name="message"
                        placeholder="Message"
                        type="textarea"
                        rows="6"
                      />
                    </FormGroup>
                    <Row>
                      <Col md="6">
                        <FormGroup check>
                          <Label check>
                            <Input defaultValue="" type="checkbox" />
                            {text10}{" "}
                            <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <Button
                          className="btn-rose pull-right"
                          color="default"
                          type="submit"
                        >
                          {text11}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
        {/* ********* END CONTACT US 2 ********* */}
      </div>
    </>
  );
}

export default SectionContactUs2;
