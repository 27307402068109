import React, { Component } from 'react'
import { Container, Icon, Table, Grid, Button, Modal, Header, Form, Pagination, Dropdown, Confirm, Input, Label, Select, Divider } from 'semantic-ui-react'
import _ from 'lodash'
import MyModalTable from '../../widgets/MyModalTable';
import { showCurrentClass } from 'views/widgets/FieldsRender';
import ViewCourseClass from '../Teacher/ViewCourseClass';
import ModalPdf from 'widget/pdf/ModalPdf';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import ReactToPrint from "react-to-print";
import Parse from 'widget/parse'

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}
export default class ClassTimetable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      author: '',
      confirmOpen: false,
      number: 0
    }
  }

  async componentDidMount() {
    const { companyObj } = this.props;
    const course = await Parse.queryData('Course', { companyId: companyObj.objectId })
    this.setState({ course })
    // firebase.firestore().collection("Course")
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }));
    //     // console.log(data)
    //     this.setState({ course: data });
    //   });

  }

  handleChange = (event, data) => {
    let fieldName = data.name;
    let fleldVal = data.value;
    this.setState({ [fieldName]: fleldVal });
  }

  fields = [
    {
      id: '#',
      title: '#'
    },
    {
      id: '',
      title: '月'
    },
    {
      id: '',
      title: '火'
    },
    {
      id: '',
      title: '水'
    },
    {
      id: '',
      title: '木'
    },
    {
      id: '',
      title: '金'
    },
  ]

  render() {
    const { classroom, optSet, semesterYear, semester, firebase, classId } = this.props;
    const { course } = this.state;

    const selClassroom = classId ? classroom.find(item => item.objectId === classId) : '';

    return (<div ref={el => (this.componentRef = el)} className="">

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <h2 style={{ margin: 0 }}>配当表-{selClassroom.classSchool} クラス</h2>
          <p style={{ fontSize: 16 }}>(入学期: {showCurrentClass(semesterYear, semester)})</p>
        </div>
        <div style={{ ...style.flexCenter, justifyContent: 'flex-end' }}>
          {/* <Input labelPosition='left' >
              <Label color={'blue'}> <Icon name='file alternate outline' />クラス</Label>
              <Select style={{
                minWidth: '250px',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0'
              }} compact value={classroomId} 
                options={optionsClass} onChange={this.handleChange} name='classroomId' />
            </Input> */}
          {/* <div style={{ ...style.flexCenter, minWidth: 190, marginLeft: 10 }}>
              <ModalPdf
                {...this.props}
                // ref={(ref) => { this.pdfModal = ref }}
                codekey="classTimeTablePdf"
              // params={}
              />
              <ReactToPrint
                trigger={(e) => <Button
                  content='印刷'
                  // label='&nbsp;'
                  color='orange' icon='print'
                  size='small'
                  className='no-print' style={{ margin: 0 }}
                />}
                content={() => this.componentRef}
                pageStyle={"@page { size: auto;  margin:5mm; }*{font-family: 'Noto Serif JP', serif;}"}
              />
              <ExportTableToExcelButton size='small' />
            </div> */}
        </div>
      </div>
      <Divider />
      {selClassroom ? <ViewCourseClass {...this.props} optSet={optSet} firebase={firebase} classroomObj={selClassroom} course={course} semesterYear={semesterYear} semester={semester} /> : 'クラス選択'}
      {/* <MyModalTable
          pageTitleText='配当表'
          firebase={firebase} fields={this.fields}
          collection='Calendar'
        /> */}

    </div >)
  }
}