import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import { Select } from 'semantic-ui-react';
import { Row, Col } from "reactstrap";

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { mgyearOpt, monthOpt } from 'views/web/CustomMaterialTable';
import { dateCol, typeCurrency, exportPdf } from 'views/web/CustomMaterialTable';

const columns = () => ([
  { width: 80, title: '計劃繳息日期', field: 'planDate', editable: 'never' },
  { width: 80, title: '客戶姓名', field: 'clientName', editable: 'never' },
  { width: 80, title: '本金結餘', field: 'principalRest', editable: 'never', ...typeCurrency },
  { width: 80, title: '應收利息', field: 'interestPlan', editable: 'never', ...typeCurrency },
  dateCol({ title: '還本日期', field: 'principalPaidDate' }),
  { width: 80, title: '本金還本', field: 'principalPaid', headerStyle: { textAlign: 'left' }, ...typeCurrency },
  dateCol({ title: '繳息日期', field: 'interestPaidDate' }),
  { width: 80, title: '繳息金額', field: 'interestPaid', headerStyle: { textAlign: 'left' }, ...typeCurrency },
  { width: 180, title: '備註', field: 'ps' },
]);

const d = new Date()
class Overdue extends Component {
  state = {
    dataArr: [],
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
    loading: true
  }

  async componentDidMount() {
    this.handleFetch()
  }

  handleFetch = async () => {
    const { companyObj } = this.props
    const { mgyearSel, monthSel } = this.state
    const companyId = companyObj.objectId

    this.setState({ loading: true });
    const dataArr = await Parse.queryData('pawning2', { companyId, year: mgyearSel, month: monthSel });
    this.setState({ dataArr, loading: false });
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value, loading: true }, () => this.handleFetch())
  }

  render() {
    const { loading, dataArr, mgyearSel, monthSel } = this.state

    return (<div className="content">
      <Row>
        <Col><legend>2-1 收款</legend></Col>
      </Row>
      <Select name="mgyearSel" style={{ position: 'relative', zIndex: 99, marginRight: '8px' }} options={mgyearOpt} value={mgyearSel} onChange={this.handleChange} />
      <Select name="monthSel" style={{ position: 'relative', zIndex: 99 }} options={monthOpt} value={monthSel} onChange={this.handleChange} />
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearSel}年 ${monthSel}月`),
          pageSize: 10,
          search: false,
          tableLayout: 'fixed',
        }}
        data={dataArr}
        title={`${mgyearSel}年 ${monthSel}月`}
      />
      <br />
      <br />
    </div>);
  }
}

export default Overdue;