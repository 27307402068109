import React from 'react'

import pinyin from "chinese-to-pinyin";
import japanese from "japanese";

export const str2pinyin = (str) => {
  const res1 = japanese.romanize(str)
  const res2 = res1.replace(/[(+=)]/g, '')
  return pinyin(res2, { removeTone: true, keepRest: true, removeSpace: true })
}

const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, 'g'), replace);
}

export const transContext = (langArr, dbLang = 'name', content = '') => {
  if (!content) {
    return '';
  }
  const matchArr = content.match(/#[^#\s]+#/g)
  if (!matchArr || !matchArr.length) { // 沒有可以換的字串
    return content;
  }

  let newContent = content;


  matchArr.forEach(item => { // 找所有符合的字串
    const find = item.substring(1, item.length - 1);
    const langObj = langArr.find(item2 => item2.objectId === find);
    const replace = (langObj && langObj[dbLang]) || null;
    if (find && replace) {
      newContent = replaceAll(newContent, item, replace) // 每次都換全部的
    }
  })
  return newContent;
}

