import React, { Component } from 'react';
import { Form, Grid, Header, Card } from 'semantic-ui-react';
import { showField, getOptionTextByValue } from 'views/widgets/FieldsRender';
import Parse from 'widget/parse.js'
import { Row, Col } from 'reactstrap';


export default
  class AgentStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      relative: [],
      referRelative: ' ',
    };
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { firebase, match, companyObj } = props;
    const { _id } = match.params;
    const data = await Parse.queryData('Relative', { companyId: companyObj.objectId, studentId: _id });
    this.setState({ relative: data });

    // firebase.firestore()
    //   .collection(`Students/${_id}/Relative`)
    //   // .orderBy('relationship')
    //   // .where('eventDate', '==', eventDate).where('classname', '==', className)
    //   .get().then(snap => {
    //     // console.log(snap)
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ relative: data });
    //   }, err => { console.log(`Encountered error: ${err}`); });

  }


  handleChangeSel = (e, { name, value }) => {
    const fieldName = name;
    const fieldValue = value;

    // 親戚 -> 推薦者 ok
    let mappingArr = [
      { from: 'name', to: 'agentName' },
      { from: 'nationality', to: 'agentNationality' },
      { from: 'relationship', to: 'agentRelationship' },
      { from: 'relationshipText', to: 'agentRelationshipText' },
      { from: 'relationshipOther', to: 'agentRelationshipOther' },
      { from: 'birthday', to: 'agentBirthday' },
      { from: 'residenceQualify', to: 'agentResidenceQualify' },
      { from: 'tel', to: 'agentTel' },
      { from: 'cellphone', to: 'agentCellphone' },
      { from: 'wechat', to: 'agentWechat' },
      { from: 'qq', to: 'agentQq' },
      { from: 'facebook', to: 'agentFb' },
      { from: 'email', to: 'agentEmail' },
      { from: 'postcode', to: 'agentPostcode' },
      { from: 'addr', to: 'agentAddr' },
      { from: 'workName', to: 'agentCoName' },
      { from: 'workTel', to: 'agentCoTel' },
      { from: 'workEmail', to: 'agentCoEmail' },
      { from: 'workUrl', to: 'agentCoUrl' },
      { from: 'workPostcode', to: 'agentCoPostcode' },
      { from: 'workAddr', to: 'agentCoAddr' },
    ];


    if (fieldValue === "fromSupport") {
      // 支付者 -> 推薦者 ok
      mappingArr = [
        { from: 'supportName', to: 'agentName' },
        { from: 'supportNationality', to: 'agentNationality' },
        { from: 'supportRelationship', to: 'agentRelationship' },
        { from: 'supportRelationshipText', to: 'agentRelationshipText' },
        { from: 'supportRelationshipOther', to: 'agentRelationshipOther' },
        { from: 'supportBirthday', to: 'agentBirthday' },
        // { from: 'supportCareer', to: 'agentResidenceQualify' },
        { from: 'supportResidenceQualify', to: 'agentResidenceQualify' },
        { from: 'supportTel', to: 'agentTel' },
        { from: 'supportCellphone', to: 'agentCellphone' },
        { from: 'supportWechat', to: 'agentWechat' },
        { from: 'supportQq', to: 'agentQq' },
        { from: 'supportFacebook', to: 'agentFb' },
        { from: 'supportEmail', to: 'agentEmail' },
        { from: 'supportPostcode', to: 'agentPostcode' },
        { from: 'supportAddr', to: 'agentAddr' },
        { from: 'supportWorkName', to: 'agentCoName' },
        { from: 'supportWorkTel', to: 'agentCoTel' },
        { from: 'supportWorkEmail', to: 'agentCoEmail' },
        { from: 'supportWorkUrl', to: 'agentCoUrl' },
        { from: 'supportWorkPostcode', to: 'agentCoPostcode' },
        { from: 'supportWorkAddr', to: 'agentCoAddr' },
      ];
    } else if (fieldValue === "fromCoSupport") {
      // 共同支付者 -> 推薦者 ok
      mappingArr = [
        { from: 'coSupportName', to: 'agentName' },
        { from: 'coSupportRelationship', to: 'agentRelationship' },
        { from: 'coSupportRelationshipText', to: 'agentRelationshipText' },
        { from: 'coSupportRelationshipOther', to: 'agentRelationshipOther' },
        { from: 'coSupportTel', to: 'agentTel' },
        { from: 'coSupportCellphone', to: 'agentCellphone' },
        { from: 'coSupportAddr', to: 'agentAddr' },
        { from: 'coSupportWorkName', to: 'agentCoName' },
        { from: 'coSupportWorkTel', to: 'agentCoTel' },
        { from: 'coSupportWorkPostcode', to: 'agentCoPostcode' },
        { from: 'coSupportWorkAddr', to: 'agentCoAddr' },
      ];
    }

    let form = { _id: this.props.doc._id };
    // 清空表單
    if (fieldValue === 'clear') {
      mappingArr.forEach(({ to }) => {
        const setting = { [to]: '' };
        form = { ...form, ...setting };
      })
      // 將参照親族資料寫入表單
    } else if (fieldValue !== "fromSupport" && fieldValue !== "fromCoSupport") {
      const { relative, agentData } = this.state;
      const referRelative = relative.find(item => item._id === fieldValue);
      const referAgent = agentData.find(item => item._id === fieldValue);

      if (referRelative) {
        mappingArr.forEach(({ from, to }) => {
          if (referRelative[from]) {
            const setting = { [to]: referRelative[from] };
            form = { ...form, ...setting };
          }
        });
      }
      if (referAgent) {
        form = { ...referAgent, agentCenterName: referAgent.agentCoName, agentRelation: '3', agentRelationshipText: '仲介業者', agentResidenceQualify: '0', agentResidenceQualifyText: '無' }

      }
    } else {
      mappingArr.forEach(({ from, to }) => {
        const setting = this.props.doc[from] && { [to]: this.props.doc[from] };
        form = { ...form, ...setting };
      });
    }
    this.props.setRelativeObj(form);
    this.setState({ [fieldName]: fieldValue })
  };

  render() {
    const { optSet, agents, isEdit, doc } = this.props;
    const { relative, referRelative, agentData = [] } = this.state;
    const primaryOptions = relative.sort((a, b) => a.relationship - b.relationship).map(item => ({ key: item._id, value: item._id, text: /*[{getOptionTextByValue(optSet, 'relationship', item.relationship)}]*/ item.name }))
    const agentArr = agentData.sort((a, b) => ('' + a.agentNationality).localeCompare(b.agentNationality)).map(item => ({ key: item._id, value: item._id, text: item.agentCoName }))
    const options = [
      { key: 'sel', text: '-- 選択 --', value: ' ', disabled: false },
      { key: 'clear', text: '-- クリア --', value: 'clear', disabled: false },
      { key: 'fromSupport', text: `[経費支弁者] ${doc.supportName ? doc.supportName : '(無)'}`, value: 'fromSupport', disabled: doc.supportName ? false : true },
      // { key: 'fromAgent', text: `[推薦者] ${doc.agentName ? doc.agentName : '(無)'}`, value: 'fromAgent', disabled: doc.agentName ? false : true },
      { key: 'selRel', text: '===親族===', value: '', disabled: true },
      ...primaryOptions,
      { key: 'selAgent', text: '===業者===', value: '', disabled: true },
      ...agentArr
    ]
    return (<>
      <Header as='h2'
        style={{ backgroundColor: 'lavender' }}
        dividing>
        推薦者
        </Header>
      {isEdit ? <Form.Select width={4} label='情報導入' defaultValue={referRelative} name='referRelative' onChange={this.handleChangeSel} options={options} /> : null}
      {/* <Grid columns='equal' divided> */}
      <Row>
        <Col>
          <Card fluid>
            <Card.Content header='基本情報' className='card-title' />
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "agentName")}</Col>
                <Col>{showField(this.props, "agentNationality")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "agentRelation")}</Col>
                <Col >{showField(this.props, "agentRelationshipText")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='2'> */}
                {/* <Col>{showField(this.props, "agentRelationshipOther")}</Col> */}
                {/* <Col>{showField(this.props, "agentBirthday")}</Col> */}
                {/* <Col md={3}>{showField(this.props, "agentCareer")}</Col> */}
                {/* <Col md={3}>{showField(this.props, "agentCareerOther")}</Col> */}
                {/* <Col md={3}>{showField(this.props, "agentIncome")}</Col> */}
                {/* <Col md={2}>{showField(this.props, "agentIsLiveTogether")}</Col> */}
                <Col>{showField(this.props, "agentResidenceQualify")}</Col>
                {/* <Col md={3}>{showField(this.props, "agentResidenceNum")}</Col> */}
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
        </Col>
        <Col>
          <Card fluid>
            <Card.Content header='連絡先' className='card-title' />
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "agentTel")}</Col>
                <Col>{showField(this.props, "agentCellphone")}</Col>
                <Col>{showField(this.props, "agentWechat")}</Col>
                <Col>{showField(this.props, "agentEmail")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "agentQq")}</Col>
                <Col>{showField(this.props, "agentFb")}</Col>
                <Col width={3}>{showField(this.props, "agentPostcode")}</Col>
                <Col>{showField(this.props, "agentAddr")}</Col>
                {/* <Col md={10}>{showField(this.props, "agentJpnAddr")}</Col> */}
                {/* <Col md={6}>{showField(this.props, "agentFax")}</Col> */}
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
        </Col>
      </Row>
      <br />
      {/* </Grid> */}
      {/* <Grid columns={2} divided> */}
      <Row>
        <Col>
          <Card fluid>
            <Card.Content header='仲介先' className='card-title' />
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "agentCenterName")}</Col>
                {/* <Col md={3}>{showField(this.props, "agentJob")}</Col> */}
                {/* <Col md={3}>{showField(this.props, "recommAnIncome")}</Col> */}
                {/* <Col md={3}>{showField(this.props, "residEnceCerRocomm")}</Col> */}
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "agentCenterNumber")}</Col>
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
        </Col>
        <Col>
          <Card fluid>
            <Card.Content header='勤務先' className='card-title' />
            <Card.Content>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "agentCoName")}</Col>
                <Col>{showField(this.props, "agentCoTel")}</Col>
                <Col>{showField(this.props, "agentCoEmail")}</Col>
                {/* </Grid> */}
              </Row>
              <Row>
                {/* <Grid columns='equal'> */}
                <Col>{showField(this.props, "agentCoUrl")}</Col>
                <Col width={4}>{showField(this.props, "agentCoPostcode")}</Col>
                <Col>{showField(this.props, "agentCoAddr")}</Col>
                {/* <Col md={6}>{showField(this.props, "agentCoFax")}</Col> */}
                {/* </Grid> */}
              </Row>
            </Card.Content>
          </Card>
        </Col>
      </Row>
      {/* </Grid> */}
    </>);
  }
}