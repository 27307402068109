import React, { Component } from 'react';
import { Container, Table, Form, Grid, Header, Button, Radio, TextArea } from 'semantic-ui-react';
import ReactToPrint from "react-to-print";
import { showField, showFieldText, getAvailblePeriodArr, getDate, getOptionsSelByKey, getOptionTextByValue } from 'views/widgets/FieldsRender';
import { showTodayText, set0String } from '../../../../widget/define/date';
import ModalPdf from 'widget/pdf/ModalPdf';
import Moment from 'moment';
import Parse from 'widget/parse.js'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';



import AttendanceCalculation from '../../../widgets/AttendanceCalculation';

const arr2 = ['1', '2'];
const arr4 = ['1', '2', '3', '4']
const arr5 = ['1', '2', '3', '4', '5'];
const arr6 = ['1', '2', '3', '4', '5', '6'];

const kindOptionArr = [
  { key: '1', value: '1', text: '学業成績及び出席状況報告書' },
  { key: '2', value: '2', text: '学業成績報告書' },
  { key: '3', value: '3', text: '出席証明書' }
]
const monthArr = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];

const getCountHours = (periodAll) => {
  let hours = 0;
  periodAll.forEach(doc => {
    if (doc.attendStatus === '1' || doc.attendStatus === '2' || (doc.attendStatus === '8' && doc.reasonKnown && (doc.reasonKnown.TY3DEeAhFgeeukSqFXzO === true || doc.reasonKnown.hX7StjN1kVj7ZKLzHMHL === true || doc.reasonKnown.VyyMSYTdJ8L1Rg3CBSHL === true))) {
      hours++;
    }
  })
  return hours;
};

const getCountDays = (dateRange, periodAll) => {
  let days = 0;
  dateRange.forEach(date => {
    periodAll.every(doc => {
      // console.log('getCountDays', doc)
      if (doc.eventDate === date &&
        (doc.attendStatus === '1' || doc.attendStatus === '2' || (doc.attendStatus === '8' && doc.reasonKnown && (doc.reasonKnown.TY3DEeAhFgeeukSqFXzO === true || doc.reasonKnown.hX7StjN1kVj7ZKLzHMHL === true || doc.reasonKnown.VyyMSYTdJ8L1Rg3CBSHL === true)))) {
        days++;
        return false;
      }
      return true;
    })
  })
  return days;
};

export const getAttendByWeekly = (monthlyArr, year0, month0, y, mon) => {
  const month = month0 + '';
  const year = (month === '1' || month === '2' || month === '3') ? (Number(year0) + 1) + '' : year0 + '';

  const d = new Date();
  if (d.getFullYear() < Number(year) || (d.getFullYear() === Number(year) && (d.getMonth() + 1 <= Number(month)))) {
    // return [0, 0, 0, 0];
    return ['', '', '', ''];
  }
  if (Number(y) < Number(year) || (Number(y) === Number(year) && (mon < Number(month)))) {
    // return [0, 0, 0, 0];
    return ['', '', '', ''];
  }

  let weeklyExpectedDay = 0;
  let weeklyExpectedHours = 0;
  let weeklyRealDay = 0;
  let weeklyRealHours = 0;
  monthlyArr && monthlyArr.filter(item => item.year === year && item.month === month)
    .forEach(item2 => {
      weeklyExpectedDay += Number(item2.weeklyExpectedDay);
      weeklyExpectedHours += Number(item2.weeklyExpectedHours);
      weeklyRealDay += Number(item2.weeklyRealDay);
      weeklyRealHours += Number(item2.weeklyRealHours);
    })

  return [weeklyExpectedDay,
    weeklyExpectedHours,
    weeklyRealDay,
    weeklyRealHours];
}
export const getAllAttendByWeekly = (monthlyArr) => {
  const d = new Date();
  const year = d.getFullYear() + '';
  const month = (d.getMonth() + 1) + '';

  let weeklyExpectedDay = 0;
  let weeklyExpectedHours = 0;
  let weeklyRealDay = 0;
  let weeklyRealHours = 0;

  monthlyArr && monthlyArr
    // .filter(item => Number(item.year) <= Number(year) || (Number(item.year) === Number(year) && Number(item.month) < Number(month)))
    .forEach(item2 => {
      if (new Date(`${item2.endDate}T23:59:59`) < d) {//計算到當月
        weeklyExpectedDay += Number(item2.weeklyExpectedDay);
        weeklyExpectedHours += Number(item2.weeklyExpectedHours);
        weeklyRealDay += Number(item2.weeklyRealDay);
        weeklyRealHours += Number(item2.weeklyRealHours);
      }
    })

  const rate0 = Math.round((Number(weeklyRealDay) / Number(weeklyExpectedDay) * 1000)) / 10;
  const rate1 = Math.round((Number(weeklyRealHours) / Number(weeklyExpectedHours) * 1000)) / 10;


  return [weeklyExpectedDay,
    weeklyExpectedHours,
    weeklyRealDay,
    weeklyRealHours,
    rate0,
    rate1];
}

class NestedModal extends Component {
  state = { open: false }

  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  render() {
    const { arrGrade, grade, isEdit } = this.props;
    const { open } = this.state

    return (
      // <Modal
      //   centered={true}
      //   open={open}
      //   onOpen={this.open}
      //   onClose={this.close}
      //   size='large'
      //   trigger={<Button
      //     fluid icon='list' />}
      // >
      <Modal
        isOpen={open}
        toggle={this.close}
        size="lg"
        className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader>テストコード選択</ModalHeader>
        <ModalBody>
          <Table celled size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell rowSpan={2}>#</Table.HeaderCell>
                <Table.HeaderCell colSpan={2}>テスト</Table.HeaderCell>
                <Table.HeaderCell rowSpan={2}>クラス</Table.HeaderCell>
                <Table.HeaderCell rowSpan={2}>氏名</Table.HeaderCell>
                <Table.HeaderCell rowSpan={2}>学期</Table.HeaderCell>
                <Table.HeaderCell rowSpan={2}>素点</Table.HeaderCell>
                <Table.HeaderCell colSpan={5}>100点</Table.HeaderCell>
                <Table.HeaderCell rowSpan={2}>文字語彙</Table.HeaderCell>
                <Table.HeaderCell rowSpan={2}>聴解</Table.HeaderCell>
                <Table.HeaderCell rowSpan={2}>文法</Table.HeaderCell>
                <Table.HeaderCell rowSpan={2}>読解</Table.HeaderCell>
                <Table.HeaderCell colSpan={2}>得点</Table.HeaderCell>
                <Table.HeaderCell colSpan={2}> 評価</Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>コード</Table.HeaderCell>
                <Table.HeaderCell>レベル</Table.HeaderCell>
                <Table.HeaderCell>文字語彙</Table.HeaderCell>
                <Table.HeaderCell>聴解</Table.HeaderCell>
                <Table.HeaderCell>文法</Table.HeaderCell>
                <Table.HeaderCell>読解</Table.HeaderCell>
                <Table.HeaderCell>文法読解計</Table.HeaderCell>
                <Table.HeaderCell>作文</Table.HeaderCell>
                <Table.HeaderCell>会話</Table.HeaderCell>
                <Table.HeaderCell>作文</Table.HeaderCell>
                <Table.HeaderCell>会話</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {arrGrade && arrGrade.sort((a, b) => ('' + a.testCode).localeCompare(b.testCode)).map((item, index) => {
                const gradeObj = grade.find(item1 => item1.objectId === item.gradeId);

                if (!gradeObj) {
                  return null;
                }
                return (<Table.Row key={`td${index}`}>
                  <Table.Cell style={{ padding: 10 }}>
                    <Button primary size='small'
                      onClick={() => this.setState({ open: false }, () => { this.props.handleClickGrade(this.props.field, gradeObj.testCode) })}
                      icon='tag' />
                  </Table.Cell>
                  <Table.Cell singleLine>{gradeObj.testCode || ''}</Table.Cell>
                  <Table.Cell singleLine>{gradeObj.testLevel}</Table.Cell>
                  <Table.Cell>{item.newClass}</Table.Cell>
                  <Table.Cell>{item.jpnName}</Table.Cell>
                  <Table.Cell>{item.semester}</Table.Cell>

                  <Table.Cell>{item.resTotalScore}</Table.Cell>
                  <Table.Cell>{item.resVocabularyScore}</Table.Cell>
                  <Table.Cell>{item.resListeningScore}</Table.Cell>
                  <Table.Cell>{item.resGrammarScore}</Table.Cell>
                  <Table.Cell>{item.resReadingScore}</Table.Cell>
                  <Table.Cell>{item.resVocabularyScore}</Table.Cell>
                  <Table.Cell>{item.vocabularyScore}</Table.Cell>
                  <Table.Cell>{item.listeningScore}</Table.Cell>
                  <Table.Cell>{item.grammarScore}</Table.Cell>
                  <Table.Cell>{item.readingScore}</Table.Cell>
                  {/* <Table.Cell>{item.totalScore}</Table.Cell> */}
                  <Table.Cell>{item.writingScore}</Table.Cell>
                  <Table.Cell>{item.conversationScore}</Table.Cell>
                  <Table.Cell>{item.writingScore}</Table.Cell>
                  <Table.Cell>{item.conversationScore}</Table.Cell>
                </Table.Row>)
              })}
              {!arrGrade.length ? <Table.Row><Table.Cell colSpan={17} textAlign='center' >(資料無し)</Table.Cell></Table.Row> : null}
            </Table.Body>
          </Table>
        </ModalBody>
        {/* <Modal.Actions>
          <Button icon='check' content='All Done' onClick={this.close} />
        </Modal.Actions> */}
      </Modal>
    )
  }
}

export default class Jpn22 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doc: props.doc,
      activeKey: '1',
      arr: [],
      arrRow: [],
      arrGrade: [],
      show: false,
      form: {},
      loading: false,
      yearmonth0: `${props.doc.semesterYear}-${set0String(new Date(props.doc.courseStartDate).getMonth() + 1)}`,
      yearmonth: new Date() > new Date(props.doc.courseEndDate) ? `${new Date(props.doc.courseEndDate).getFullYear()}-${set0String(new Date(props.doc.courseEndDate).getMonth() === 0 ? 12 : new Date(props.doc.courseEndDate).getMonth())}` : `${new Date().getFullYear()}-${set0String(new Date().getMonth() === 0 ? 12 : new Date().getMonth())}`,
      year0: props.doc.semesterYear,
      mon0: new Date(props.doc.courseStartDate).getMonth() + 1,
      year: new Date() > new Date(props.doc.courseEndDate) ? new Date().getFullYear(props.doc.courseEndDate) : new Date().getFullYear(),
      mon: new Date() > new Date(props.doc.courseEndDate) ? new Date(props.doc.courseEndDate).getMonth() === 0 ? 12 : new Date(props.doc.courseEndDate).getMonth() : new Date().getMonth() === 0 ? 12 : new Date().getMonth(),
      isWant: false,
    };
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('do componentWillReceiveProps');
    this.setState({ doc: nextProps.doc });
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, match, grade } = props;
    const { _id } = match.params;

    // const db = firebase.firestore();
    if (_id) {
      //// Student
      const monthly = await Parse.queryData('Monthly', { companyId: companyObj.objectId, studentId: _id });
      this.setState({ monthly })
      // db.collection(`Students/${_id}/Monthly`)
      //   .get().then(snap => {
      //     const data = [];
      //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }))
      //     // console.log(data);
      //     this.setState({ monthly: data });
      //   }, err => {
      //     console.log(`Encountered error: ${err}`);
      //   });
      const arrRow = await Parse.queryData('Students', { companyId: companyObj.objectId, objectId: _id });
      console.log(arrRow)
      this.setState({ arrRow }, () => this.handleFixRate())
      // db.collection(`Students`).doc(_id)
      //   .get().then(snap => {
      //     const data = [];
      //     data.push({ ...snap.data() })
      //     // snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
      //     // console.log(data);
      //     this.setState({ arrRow: data }, () => this.handleFixRate());
      //   }, err => {
      //     console.log(`Encountered error: ${err}`);
      //   });
      //// Grade
      const data = await Parse.queryData('Students', { companyId: companyObj.objectId, studentId: _id });
      const arrGrade = []
      data.forEach(item => arrGrade.push({
        ...item,
        testCode: (grade.find(item1 => item1.objectId === item.gradeId) && grade.find(item1 => item1.objectId === item.gradeId).testCode) || ''
      }));
      this.setState({ arrGrade }, () => this.getPrintData())
      // db.collection(`Students/${_id}/Grade`)
      //   .get().then(snap => {
      //     const data = [];
      //     snap.forEach(item => data.push({
      //       _id: item.id, ...item.data(),
      //       testCode: (grade.find(item1 => item1._id === item.data().gradeId) && grade.find(item1 => item1._id === item.data().gradeId).testCode) || ''
      //     }));
      //     // console.log(data);
      //     this.setState({ arrGrade: data }, () => this.getPrintData());
      //   }, err => {
      //     console.log(`Encountered error: ${err}`);
      //   });
    }
  }

  handleOpen = (doc) => {
    this.setState({ show: true, form: { ...doc } })
  }

  handleEdit = (form) => {
    this.setState({ show: true, form: { ...form } });
  }

  handleClose = () => {
    this.setState({
      show: false,
      form: {},
    });
  }

  handleFixRate = async () => {
    const { companyObj, match, doc } = this.props;
    const { year0, mon0, year, mon } = this.state;
    const { _id } = match.params;
    const firstDay = doc.courseStartDate || `${year0}-${set0String(mon0)}-01`;
    const lastDay = Moment(`${year}-${mon}-28`).add(Number(mon) === new Date().getMonth() + 1 ? 0 : 1, 'M').set('date', 0).format('YYYY-MM-DD');
    // console.log(firstDay, lastDay)
    const rollcall = [];
    // const rollcallRef = await firebase.firestore().collectionGroup('Rollcall').where('studentId', '==', _id).get();
    const rollcallRef = await Parse.queryData('Rollcall', { companyId: companyObj.objectId, studentId: _id });
    // console.log(rollcallRef.size);
    rollcallRef.forEach(doc => {
      rollcall.push(doc)
      // console.log(doc.id, ' => ', doc.data());
    });

    // var dbDaily = firebase.firestore().collection(`Students/${_id}/Daily`);
    // const dailySnapshot = await dbDaily
    //   .where('eventDate', '>=', firstDay)
    //   .where('eventDate', '<=', lastDay)
    //   .get();
    const dbDaily = new Parse.Query('Daily');
    dbDaily.equalTo('studentId', _id)
    dbDaily.greaterThanOrEqualTo('eventDate', firstDay)
    dbDaily.lessThanOrEqualTo('eventDate', lastDay)
    dbDaily.limit(10000)
    const snap = await dbDaily.find();
    const dailySnapshot = snap.map(data => data.toJSON());

    const dateRangeArr = [];
    dailySnapshot.forEach(doc => {
      // console.log('doc', doc)
      // console.log('dailySnapshot data', doc.data());
      const data = doc
      if (new Date(data.eventDate) < new Date())
        dateRangeArr.push(data.eventDate);
    })
    // console.log(dateRangeArr)
    const rollcallRange = rollcall.filter(item => item.eventDate >= firstDay && item.eventDate <= lastDay);
    // console.log(rollcallRange)
    let eventOff = rollcallRange.filter(item => item.attendStatus === '8' && item.reasonKnown && item.reasonKnown.TY3DEeAhFgeeukSqFXzO === true).length
    let sickOff = rollcallRange.filter(item => item.attendStatus === '8' && item.reasonKnown && item.reasonKnown.hX7StjN1kVj7ZKLzHMHL === true).length
    let schoolOff = rollcallRange.filter(item => item.attendStatus === '8' && item.reasonKnown && item.reasonKnown.VyyMSYTdJ8L1Rg3CBSHL === true).length

    let countHours = getCountHours(rollcallRange);
    let countDays = getCountDays(dateRangeArr, rollcallRange);
    this.setState({ eventOff, sickOff, schoolOff, countHours, countDays })
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    const { form, isWant } = this.state;
    if (name === 'isWant') {
      this.setState({ [name]: !isWant }, () => this.getPrintData());
    } else if (name === 'yearmonth') {
      const [year, month1] = value && value.split('-'); // 2019-W10 => 2019, W10
      const mon = Number(month1).toString(); // W10 => 10
      this.setState({ yearmonth: value, year, mon });
    } else if (name === 'yearmonth0') {
      const [year0, month1] = value && value.split('-'); // 2019-W10 => 2019, W10
      const mon0 = Number(month1).toString(); // W10 => 10
      this.setState({ yearmonth0: value, year0, mon0 });
    } else
      this.setState({ form: { ...form, [name]: value } });
    this.props.handleUpdateSetting(event, data);
  };

  handleClickGrade = (name, value) => {
    // const { name, value } = data;
    const { form } = this.state;
    console.log(name, value)
    this.setState({ form: { ...form, [name]: value } });
  };

  handleSubmit = async () => {
    const { firebase, optSet, doc, semesterYear, semester, match } = this.props;
    const { form, arrRow } = this.state;
    const { _id } = match.params;

    // const db = firebase.firestore();

    this.setState({ loading: true });

    const newRows = [...arrRow];
    if (_id) {
      // console.log(form)
      // console.log('form', form)
      // await db.collection(`Students`).doc(_id).update(form);
      await Parse.saveData('Students', form)
      newRows[0] = form;
    }

    this.setState({ form, arrRow: newRows, loading: false }, () => this.getPrintData());
    this.handleClose();
  }

  addDays = (date, days) => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  getPrintData = () => {
    const { formSet, optSet, doc, classroom } = this.props;
    const { monthly, arrRow, arrGrade, year0, mon0, year, mon, isWant, eventOff, sickOff, schoolOff, countHours, countDays } = this.state;
    let monthlyArr = monthly && monthly.filter(m => new Date(m.startDate) < new Date(`${year}-${set0String(mon)}-30`) && new Date(m.endDate) > new Date(`${year0}-${set0String(mon0)}-01`))
    let classTimeOri = classroom.find(item => item.classSchool === doc.classSchool) ? classroom.find(item => item.classSchool === doc.classSchool).classTime : ''
    let obj = { ExpectedDay: [], ExpectedHours: [], RealDay: [], RealHours: [], ExpectedDay2: [], ExpectedHours2: [], RealDay2: [], RealHours2: [] };
    let testObj = arrGrade.find(grade => grade.testCode === arrRow[0].testcode)
    let writeObj = arrGrade.find(grade => grade.testCode === arrRow[0].writecode)
    let classTime = arrRow.length && arrRow[0].classTime ? showFieldText(formSet, optSet, "classTime", arrRow[0]) : classTimeOri === '2' ? '午後' : '午前'
    monthArr.map(month => {
      obj.ExpectedDay.push(getAttendByWeekly(monthlyArr, Number(doc.semesterYear), month, year, mon)[0])
      obj.ExpectedHours.push(getAttendByWeekly(monthlyArr, Number(doc.semesterYear), month, year, mon)[1])
      obj.RealDay.push(getAttendByWeekly(monthlyArr, Number(doc.semesterYear), month, year, mon)[2])
      obj.RealHours.push(getAttendByWeekly(monthlyArr, Number(doc.semesterYear), month, year, mon)[3])
      obj.ExpectedDay2.push(getAttendByWeekly(monthlyArr, Number(doc.semesterYear) + 1, month, year, mon)[0])
      obj.ExpectedHours2.push(getAttendByWeekly(monthlyArr, Number(doc.semesterYear) + 1, month, year, mon)[1])
      obj.RealDay2.push(getAttendByWeekly(monthlyArr, Number(doc.semesterYear) + 1, month, year, mon)[2])
      obj.RealHours2.push(getAttendByWeekly(monthlyArr, Number(doc.semesterYear) + 1, month, year, mon)[3])
    })
    obj.allExpectDay = getAllAttendByWeekly(monthlyArr)[0]
    obj.allExpectHours = getAllAttendByWeekly(monthlyArr)[1]
    obj.allRealDay = getAllAttendByWeekly(monthlyArr)[2]
    obj.allRealHours = getAllAttendByWeekly(monthlyArr)[3]
    obj.avgRealDay = getAllAttendByWeekly(monthlyArr)[4]
    obj.avgRealHours = getAllAttendByWeekly(monthlyArr)[5]
    // obj.classTime = classTime === '2' ? '午後' : '午前'
    this.setState({ params: { ...obj, ...testObj, ...writeObj, ...doc, studentLevel: arrRow[0].testlevel, reasons: arrRow[0].reasons, classTime, isWant, eventOff, sickOff, schoolOff, countHours, countDays } })
    // console.log(obj)
  }

  render() {
    const { doc, formSet, optSet, classroom } = this.props;
    const { yearmonth0, year0, mon0, yearmonth, year, mon, monthly, arrRow, show, form, loading, arrGrade, params, countHours, countDays } = this.state;
    const monthlyArr = monthly && monthly.filter(m => new Date(m.startDate) < new Date(`${year}-${set0String(mon)}-28`) && new Date(m.endDate) > new Date(`${year0}-${set0String(mon0)}-01`))
    const classTimeArr = getOptionsSelByKey(optSet, "classTime");
    const reportProgress = getOptionsSelByKey(optSet, "studyProgress");
    const arrAttitude = getOptionsSelByKey(optSet, 'abcd');
    let classTime = classroom.find(item => item.classSchool === doc.classSchool) ? classroom.find(item => item.classSchool === doc.classSchool).classTime : ''
    form.classTime = form.classTime || classTime
    doc.courseStartDate = doc.courseStartDate || (doc.semester === 'S' ? `${doc.semesterYear}-04-01` : `${doc.semesterYear}-10-01`);

    let visaIndex = "";
    if (doc.visaExpireDate3) {
      visaIndex = "3";
    } else if (doc.visaExpireDate2) {
      visaIndex = "2";
    } else {
      visaIndex = "1";
    }

    return (<>
      <Header as='h2' dividing
        style={{ backgroundColor: 'lavender' }}
        content='学業成績及び出席状況報告書'
      // subheader='調査時間：2017年4月から 2018年12月 まで'
      />
      {/* <Form.Group> */}
      {/* <Form.Select label='類別' options={kindOptionArr} defaultValue='1' onChange={(e, { value }) => this.setState({ activeKey: value })} /> */}
      {/* <ReactToPrint
        trigger={(e) => <Button
          content='印刷'
          icon='print'
          size='small'
          color='orange'
          floated='right'
          className='no-print'
        />}
        content={() => this.componentRef}
        pageStyle={"@page { size: auto;  margin: 10mm; }*{font-family: 'Noto Serif JP', serif;}"}
      /> */}
      <ModalPdf
        {...this.props}
        // ref={(ref) => { this.pdfModal = ref }}
        codekey="jpn22"
        params={params}
      />
      <Button
        content='編集'
        positive
        // disabled={!isEdit}
        icon='edit'
        size='small'
        floated='left'
        color='green'
        className='no-print'
        onClick={() => this.handleOpen(arrRow[0])}
      />
      {/* </Form.Group> */}
      <div ref={el => (this.componentRef = el)} className="" style={{ padding: '20px' }} >
        {/* <br /> */}
        <Header as='h2'
          textAlign='center'
          content='学業成績及び出席状況報告書'
        />
        <div>調査時間： {year0}年 {mon0}月 から {year}年 {mon === new Date().getMonth() + 1 ? mon - 1 : mon}月 まで
            {/* <div style={{ width: '10%', display: 'inline-table' }}>{showField(this.props, "surveyStart", 0)}</div>から  */}
          {/* <div style={{ width: '10%', display: 'inline-table' }}>{showField(this.props, "surveyEnd", 0)}</div>まで</div> */}</div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <h4>愛知国際学院</h4>
          <p>発行日：{showTodayText()}</p>
        </div>
        {/* <br /> */}
        <Table size="small" celled compact style={{ border: '1px #000 soild' }}>
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan='2'>学籍番号</Table.Cell>
              <Table.Cell colSpan='7'>氏                              名</Table.Cell>
              <Table.Cell colSpan='2'>国          籍</Table.Cell>
              <Table.Cell colSpan='1'>性    別</Table.Cell>
              <Table.Cell colSpan='4'>生     年     月     日</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan='2'>{doc.semesterYear.substr(-2)}({doc.semester}){doc.studentId}</Table.Cell>
              <Table.Cell colSpan='7'>{doc.jpnName}</Table.Cell>
              <Table.Cell colSpan='2'>{showFieldText(formSet, optSet, "nationality", doc)}</Table.Cell>
              <Table.Cell colSpan='1'>{showFieldText(formSet, optSet, "gender", doc)}</Table.Cell>
              <Table.Cell colSpan='4'>{showFieldText(formSet, optSet, "birthday", doc)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan='2'>住所</Table.Cell>
              <Table.Cell colSpan='14' style={{ textAlign: 'left' }}>{doc.jpnAddr}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan='2'>在留資格</Table.Cell>
              <Table.Cell colSpan='5'>在留期間</Table.Cell>
              <Table.Cell colSpan='5'>入国年月日</Table.Cell>
              <Table.Cell colSpan='4'>開講年月日</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan='2'>{showFieldText(formSet, optSet, "stayQualify", doc)}</Table.Cell>
              {/* <Table.Cell colSpan='5'>{showField(this.props, "visaDuring" + visaIndex, 0)} {showField(this.props, "visaExpireDate" + visaIndex, 0)}</Table.Cell> */}
              <Table.Cell colSpan='5'>{showFieldText(formSet, optSet, "visaDuring" + visaIndex, doc)} {showFieldText(formSet, optSet, "visaExpireDate" + visaIndex, doc)}</Table.Cell>
              <Table.Cell colSpan='5'>{showFieldText(formSet, optSet, "landingAdmitDate", doc)}</Table.Cell>
              <Table.Cell colSpan='4'>{showFieldText(formSet, optSet, "courseStartDate", doc)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan='8'>課     程     名</Table.Cell>
              <Table.Cell colSpan='8'>修     学     年     限</Table.Cell>
              {/* <Table.Cell>入国年月日</Table.Cell>
              <Table.Cell>開講年月日</Table.Cell> */}
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan='8'>(日本語科) {doc.semester === "S" ? "2年" : "1.5年"}進学課程&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;昼間</Table.Cell>
              <Table.Cell colSpan='8'>{showFieldText(formSet, optSet, "courseEndDate", doc)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell rowSpan='9' style={{ borderRight: '1px solid rgba(34,36,38,.1)', textAlign: 'center' }}>出<br />席<br />状<br />況</Table.Cell>
              <Table.Cell style={{ borderLeft: '0px solid #fff' }}>月別区分</Table.Cell>
              <Table.Cell>4月</Table.Cell>
              <Table.Cell>5月</Table.Cell>
              <Table.Cell>6月</Table.Cell>
              <Table.Cell>7月</Table.Cell>
              <Table.Cell>8月</Table.Cell>
              <Table.Cell>9月</Table.Cell>
              <Table.Cell>10月</Table.Cell>
              <Table.Cell>11月</Table.Cell>
              <Table.Cell>12月</Table.Cell>
              <Table.Cell>1月</Table.Cell>
              <Table.Cell>2月</Table.Cell>
              <Table.Cell>3月</Table.Cell>
              <Table.Cell colSpan='2'>備考</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>授業日数</Table.Cell>
              {monthArr.map(month => <Table.Cell key={`ttd0${doc.semesterYear}${month}}`}>{getAttendByWeekly(monthlyArr, Number(doc.semesterYear), month, year, mon)[0] !== 0 ? getAttendByWeekly(monthlyArr, Number(doc.semesterYear), month, year, mon)[0] : '/'}</Table.Cell>)}
              {/* {monthArr.map(month => <Table.Cell key={`ttd0${doc.semesterYear}${month}}`}>{getAttendByWeekly(arr, Number(doc.semesterYear), month)[0]}</Table.Cell>)} */}
              <Table.Cell rowSpan='2'>
                {/* {isEdit ? showField(this.props, "reportNoon", 0) : showField(this.props, "reportNoon", 0)} */}
                {/* {classTime === '2' ? '午後' : '午前'} */}
                {arrRow.length ? showFieldText(formSet, optSet, "classTime", arrRow[0]) : ''}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>授業時間数</Table.Cell>
              {monthArr.map(month => <Table.Cell key={`ttd1${doc.semesterYear}${month}}`}>{getAttendByWeekly(monthlyArr, Number(doc.semesterYear), month, year, mon)[1] !== 0 ? getAttendByWeekly(monthlyArr, Number(doc.semesterYear), month, year, mon)[1] : '/'}</Table.Cell>)}
              {/* <Table.Cell colSpan='2'>
                    {isEdit ? '' : "午後"}
                  </Table.Cell> */}
            </Table.Row>
            <Table.Row>
              <Table.Cell>出席日数</Table.Cell>
              {monthArr.map(month => <Table.Cell key={`ttd2${doc.semesterYear}${month}}`}>{getAttendByWeekly(monthlyArr, Number(doc.semesterYear), month, year, mon)[2] !== 0 ? getAttendByWeekly(monthlyArr, Number(doc.semesterYear), month, year, mon)[2] : '/'}</Table.Cell>)}
              <Table.Cell rowSpan='2' verticalAlign='bottom'>計</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>出席時間数</Table.Cell>
              {monthArr.map(month => <Table.Cell key={`ttd3${doc.semesterYear}${month}}`}>{getAttendByWeekly(monthlyArr, Number(doc.semesterYear), month, year, mon)[3] !== 0 ? getAttendByWeekly(monthlyArr, Number(doc.semesterYear), month, year, mon)[3] : '/'}</Table.Cell>)}
            </Table.Row>
            <Table.Row style={{ borderTop: 'double' }}>
              <Table.Cell>授業日数</Table.Cell>
              {/* {monthArr.map(month => this.getSmartTd(Number(doc.semesterYear) + 1, month, 'expectedDays'))} */}
              {monthArr.map(month => <Table.Cell key={`ttd4${doc.semesterYear}${month}}`}>{getAttendByWeekly(monthlyArr, Number(doc.semesterYear) + 1, month, year, mon)[0] !== 0 ? getAttendByWeekly(monthlyArr, Number(doc.semesterYear) + 1, month, year, mon)[0] : '/'}</Table.Cell>)}
              <Table.Cell colSpan='2'>
                {getAllAttendByWeekly(monthlyArr)[0]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>授業時間数</Table.Cell>
              {monthArr.map(month => <Table.Cell key={`ttd5${doc.semesterYear}${month}}`}>{getAttendByWeekly(monthlyArr, Number(doc.semesterYear) + 1, month, year, mon)[1] !== 0 ? getAttendByWeekly(monthlyArr, Number(doc.semesterYear) + 1, month, year, mon)[1] : '/'}</Table.Cell>)}
              <Table.Cell colSpan='2'>
                {getAllAttendByWeekly(monthlyArr)[1]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>出席日数</Table.Cell>
              {monthArr.map(month => <Table.Cell key={`ttd6${doc.semesterYear}${month}}`}>{getAttendByWeekly(monthlyArr, Number(doc.semesterYear) + 1, month, year, mon)[2] !== 0 ? getAttendByWeekly(monthlyArr, Number(doc.semesterYear) + 1, month, year, mon)[2] : '/'}</Table.Cell>)}
              <Table.Cell colSpan='2'>
                {getAllAttendByWeekly(monthlyArr)[2]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>出席時間数</Table.Cell>
              {monthArr.map(month => <Table.Cell key={`ttd7${doc.semesterYear}${month}}`}>{getAttendByWeekly(monthlyArr, Number(doc.semesterYear) + 1, month, year, mon)[3] !== 0 ? getAttendByWeekly(monthlyArr, Number(doc.semesterYear) + 1, month, year, mon)[3] : '/'}</Table.Cell>)}
              <Table.Cell colSpan='2'>
                {getAllAttendByWeekly(monthlyArr)[3]}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell rowSpan='5' colSpan='10' height='100px' style={{ textAlign: 'left', verticalAlign: 'text-top' }}>
                長期欠席者については、その事由及び指導した事項：<br />
                {/* <div>{showField(this.props, "reportPs", 0)} {doc.reportPs ? '' : '(無)'}</div> */}
                <div>{arrRow.length && arrRow[0]['reasons'] ? arrRow[0].reasons : '(無)'}</div><br />
                <Form.Group>
                  <Form.Checkbox type="checkbox" lable='' onChange={this.handleChange} name="isWant" checked={this.state.isWant} />
              修正出席率：事假{this.state.eventOff || 0}日，病假{this.state.sickOff || 0}日，公假{this.state.schoolOff || 0}日，修正出席日數{countDays}日，修正日出席率{Math.round((countDays / getAllAttendByWeekly(monthlyArr)[0] * 1000)) / 10}%，修正出席時間數{countHours}時間，修正時出席率{Math.round((countHours / getAllAttendByWeekly(monthlyArr)[1] * 1000)) / 10}%
            </Form.Group>
              </Table.Cell>
              <Table.Cell rowSpan='5' colSpan='10' height='100px' style={{ textAlign: 'right' }}>
                日計算出席率：{getAllAttendByWeekly(monthlyArr)[4]}%<br />
                時間計算出席：{getAllAttendByWeekly(monthlyArr)[5]}%<br />
                授業開始日：{showFieldText(formSet, optSet, "lessonStartDate", doc)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Table celled>
          <Table.Body>
            <Table.Row>
              <Table.Cell>科目名 日本語科</Table.Cell>
              <Table.Cell>文字・語彙</Table.Cell>
              <Table.Cell>文法</Table.Cell>
              <Table.Cell>読解</Table.Cell>
              <Table.Cell>聴解</Table.Cell>
              <Table.Cell>作文</Table.Cell>
              <Table.Cell>会話</Table.Cell>
              <Table.Cell>進度</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>成績</Table.Cell>
              <Table.Cell>
                {
                  arrRow.length && arrRow[0].testcode && arrGrade.length &&
                  arrGrade.find(item => item.testCode === arrRow[0].testcode) &&
                  arrGrade.find(item => item.testCode === arrRow[0].testcode).resVocabularyScore
                }
              </Table.Cell>
              <Table.Cell>
                {
                  arrRow.length && arrRow[0].testcode && arrGrade.length &&
                  arrGrade.find(item => item.testCode === arrRow[0].testcode) &&
                  arrGrade.find(item => item.testCode === arrRow[0].testcode).resGrammarScore
                }
              </Table.Cell>
              <Table.Cell>
                {
                  arrRow.length && arrRow[0].testcode && arrGrade.length &&
                  arrGrade.find(item => item.testCode === arrRow[0].testcode) &&
                  arrGrade.find(item => item.testCode === arrRow[0].testcode).resReadingScore
                }
              </Table.Cell>
              <Table.Cell>
                {
                  arrRow.length && arrRow[0].testcode && arrGrade.length &&
                  arrGrade.find(item => item.testCode === arrRow[0].testcode) &&
                  arrGrade.find(item => item.testCode === arrRow[0].testcode).resListeningScore
                }
              </Table.Cell>
              <Table.Cell>
                {
                  arrRow.length && arrRow[0].writecode && arrGrade.length &&
                  arrGrade.find(item => item.testCode === arrRow[0].writecode) &&
                  arrGrade.find(item => item.testCode === arrRow[0].writecode).writingScore
                }
              </Table.Cell>
              <Table.Cell>
                {
                  arrRow.length && arrRow[0].writecode && arrGrade.length &&
                  arrGrade.find(item => item.testCode === arrRow[0].writecode) &&
                  arrGrade.find(item => item.testCode === arrRow[0].writecode).conversationScore
                }
              </Table.Cell>
              {/* <Table.Cell>{arrRow.length ? arrRow[0].testlevel : ''}</Table.Cell> */}
              <Table.Cell>{arrRow.length && arrRow[0].testlevel && getOptionTextByValue(optSet, 'studyProgress', arrRow[0].testlevel)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell rowSpan='3'>学  習  態  度<br />(レポ一ト、宿題など<br />の提出状況を含む)</Table.Cell>
              <Table.Cell>意欲</Table.Cell>
              <Table.Cell>{arrRow.length ? arrRow[0].attitude0 : ''}</Table.Cell>
              <Table.Cell>規律性</Table.Cell>
              <Table.Cell>{arrRow.length ? arrRow[0].attitude3 : ''}</Table.Cell>
              <Table.Cell>礼儀正しさ</Table.Cell>
              <Table.Cell>{arrRow.length ? arrRow[0].attitude6 : ''}</Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ borderLeft: '1px solid rgba(34,36,38,.1)' }}>努力</Table.Cell>
              <Table.Cell>{arrRow.length ? arrRow[0].attitude1 : ''}</Table.Cell>
              <Table.Cell>協調性</Table.Cell>
              <Table.Cell>{arrRow.length ? arrRow[0].attitude4 : ''}</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell style={{ borderLeft: '1px solid rgba(34,36,38,.1)' }}>創意工夫</Table.Cell>
              <Table.Cell>{arrRow.length ? arrRow[0].attitude2 : ''}</Table.Cell>
              <Table.Cell>積極性</Table.Cell>
              <Table.Cell>{arrRow.length ? arrRow[0].attitude5 : ''}</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan='8'>A : 非常に優れている&nbsp;&nbsp;&nbsp;B : 優れている&nbsp;&nbsp;&nbsp;C : 普通&nbsp;&nbsp;&nbsp;D : おとる</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
      {/* <Modal open={show} onClose={this.handleClose} size='small' centered={false}> */}
      <Modal
        isOpen={this.state.show}
        toggle={this.handleClose}
        size="lg"
        className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader>
          <h2 style={{ margin: 0 }}> 成績·出席証明書 {form.objectId ? '修正' : '追加'}</h2>
          {/* <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon> */}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Grid columns='equal'>
              {/* <Grid.Column ><Form.Input fluid label='学籍番号' disabled={true} readOnly={true} name="studentId" value={doc.studentId} /></Grid.Column>
              <Grid.Column ><Form.Input fluid label='氏名' disabled={true} readOnly={true} name="jpnName" value={doc.jpnName} /></Grid.Column> */}
              <Grid.Column><Form.Select fluid
                label='午前 / 午後'
                options={classTimeArr}
                name="classTime" value={form.classTime}
                onChange={this.handleChange} />
              </Grid.Column>
              <Grid.Column>
                <Form.Input
                  style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
                  label='調查時間から'
                  name="yearmonth0" type="month" value={yearmonth0} onChange={this.handleChange} />
              </Grid.Column>
              <Grid.Column>
                <Form.Input
                  style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
                  label='調查時間まで'
                  name="yearmonth" type="month" value={yearmonth} onChange={this.handleChange} />
              </Grid.Column>
              {/* <Grid.Column ><Form.Input fluid label='予定日' type="date" name="planDate" value={form.planDate} onChange={this.handleChange} /></Grid.Column> */}
            </Grid>
            <label>成績</label>
            <Table definition size='small'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4} />
                  <Table.HeaderCell width={3}>基本科目</Table.HeaderCell>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                  <Table.HeaderCell width={3}>作文•会話</Table.HeaderCell>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                  <Table.HeaderCell width={3}>レベル</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {/* {arrSemester.map((item, index) => */}
                <Table.Row key={'form'}>
                  {/* <Table.Cell>第{item}期テストコード</Table.Cell> */}
                  <Table.Cell></Table.Cell>
                  <Table.Cell><Form.Input fluid name={'testcode'} value={form['testcode']} onChange={this.handleChange} /></Table.Cell>
                  <Table.Cell><NestedModal {...this.props} arrGrade={arrGrade} field={'testcode'} handleClickGrade={this.handleClickGrade} /></Table.Cell>
                  <Table.Cell><Form.Input fluid name={'writecode'} value={form['writecode']} onChange={this.handleChange} /></Table.Cell>
                  <Table.Cell><NestedModal {...this.props} arrGrade={arrGrade} field={'writecode'} handleClickGrade={this.handleClickGrade} /></Table.Cell>
                  <Table.Cell>
                    <Form.Select fluid
                      options={reportProgress}
                      name={'testlevel'} value={form['testlevel']}
                      onChange={this.handleChange} />
                  </Table.Cell>
                </Table.Row>
                {/* )} */}
              </Table.Body>
            </Table>
            <label>学習態度(レポ一ト、宿題などの提出状況を含む)</label>
            <br />
            <br />
            <Grid columns='equal'>
              <Grid.Column >
                <Form.Select fluid name="attitude0" options={arrAttitude} value={form.attitude0} label='意欲' onChange={this.handleChange} />
                <Form.Select fluid name="attitude1" options={arrAttitude} value={form.attitude1} label='努力' onChange={this.handleChange} />
                <Form.Select fluid name="attitude2" options={arrAttitude} value={form.attitude2} label='創意工夫' onChange={this.handleChange} />
              </Grid.Column>
              <Grid.Column >
                <Form.Select fluid name="attitude3" options={arrAttitude} value={form.attitude3} label='規律' onChange={this.handleChange} />
                <Form.Select fluid name="attitude4" options={arrAttitude} value={form.attitude4} label='協調性' onChange={this.handleChange} />
                <Form.Select fluid name="attitude5" options={arrAttitude} value={form.attitude5} label='積極性' onChange={this.handleChange} />
              </Grid.Column>
              <Grid.Column >
                <Form.Select fluid name="attitude6" options={arrAttitude} value={form.attitude6} label='礼儀正しさ' onChange={this.handleChange} />
                <Form.Field control={TextArea} name="reasons" value={form.reasons} label='その事由及び指導した事項' onChange={this.handleChange} />
              </Grid.Column>
              {/* <Grid.Column >
                <Form.Input fluid label='休学備考' name="suspendPs" value={form.suspendPs} onChange={this.handleChange} />
                <Form.Group inline>
                  <Form.Field
                    name="suspendPeriod"
                    control={Radio}
                    label='無'
                    value='0'
                    checked={form.suspendPeriod === '0'}
                    onChange={this.handleChange}
                  />
                  <Form.Field
                    name="suspendPeriod"
                    control={Radio}
                    label='1期後'
                    value='1'
                    checked={form.suspendPeriod === '1'}
                    onChange={this.handleChange}
                  />
                  <Form.Field
                    name="suspendPeriod"
                    control={Radio}
                    label='2期後'
                    value='2'
                    checked={form.suspendPeriod === '2'}
                    onChange={this.handleChange}
                  />
                  <Form.Field
                    name="suspendPeriod"
                    control={Radio}
                    label='3期後'
                    value='3'
                    checked={form.suspendPeriod === '3'}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Grid.Column> */}
            </Grid>
            <br />
            <label>A : 非常に優れている&nbsp;&nbsp;&nbsp;B : 優れている&nbsp;&nbsp;&nbsp;C : 普通&nbsp;&nbsp;&nbsp;D : おとる</label>
          </Form>
          <Grid>
            <Grid.Row>
              <Grid.Column style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button size='small' onClick={this.handleSubmit} inverted color='green' loading={loading} icon='checkmark' content={form.objectId ? '保存' : '追加'} />
                <Button size='small' onClick={this.handleClose} basic>キャンセル</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ModalBody>
      </Modal>
    </>);
  }
}

