import React, { Component } from 'react';
import { Form, Tabs, Table, Button, Modal, Grid, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom'

// import MyScrollBSTable2 from '../../../components/GridTable/MyScrollBSTable2';
import { showField, showFieldText, getAvailblePeriodArr, getOptionsByKey } from 'views/widgets/FieldsRender';
import Parse from '../../../../widget/parse'

export default class TestStudent extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      show: false,
      form: {},
      modalTitle: '',
      grade2: []
    };
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { firebase, match, companyObj } = props;
    const { _id } = match.params;

    const data1 = await Parse.queryData('Grade2', { companyId: companyObj.objectId, studentId: _id });
    let grade2 = data1.sort((a, b) => ('' + b.testCode).localeCompare(a.testCode))
    this.setState({ grade2 });
  }

  handleSubmit = async () => {
    const { firebase, doc, semesterYear, semester } = this.props;
    const { form, test } = this.state;
    const db = firebase.firestore();

    this.setState({ loading: true });
    const newTest = [...test];
    if (!form._id) {
      const docRef = db.collection(`Students/${doc._id}/Grade`).doc();
      const obj = {
        ...form,
        _id: docRef.id,
        studentId: doc._id,
        studentIdKey: doc.studentId,
        studentName: doc.jpnName,
        semesterYear,
        semester,
        createdAt: new Date()
      };
      await docRef.set(obj);
      newTest.push(obj);
    } else {
      await db.collection(`Students/${doc._id}/Grade`).doc(form._id).update(form);
      let index = newTest.findIndex((item => item._id === form._id));
      newTest[index] = form;
    }

    this.setState({ test: newTest, loading: false });
    this.handleClose();
  }

  handleClose = () => {
    this.setState({ show: false, form: {} });
  }

  handleShow = () => {
    this.setState({ show: true, modalTitle: '追加' });
  }
  editRowModal = (form) => {
    this.setState({ show: true, modalTitle: '保存', form });
  }

  handleChange = (event) => {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    this.setState({ form: { ...this.state.form, [fieldName]: fleldVal } })
  }

  handleSubmit = () => {
    // const method = this.state.form._id ? 'user.updateGrade2' : 'user.addGrade';
    const method = 'user.updateGrade2';
    const setting = this.state.form;
    setting.testType = '2';
  }

  removeRow = (doc) => {
    // if (!confirm("確認要削除嗎?")) {
    //   return;
    // }
  }

  render() {
    const { formSet, optSet, isEdit } = this.props;
    const { grade2 } = this.state

    const classArr = getOptionsByKey(optSet, 'classSchool');
    return (
      <>
        <Header as='h2' dividing
          content='定期テスト/飛び級状況'
          style={{ backgroundColor: 'lavender' }}
        // subheader='調査時間：2017年4月から 2018年12月 まで'
        />
        {/* <Button primary style={{ float: 'right' }} onClick={this.handleShow} icon='add'> 追加成績</Button> */}
        <Table celled>
          <Table.Header>
            <Table.Row textAlign='center'>
              {/* {isEdit ? <Table.HeaderCell></Table.HeaderCell> : null} */}
              {/* <Table.HeaderCell style={{ minWidth: 120 }}></Table.HeaderCell> */}
              {/* <Table.HeaderCell style={{ minWidth: 120 }}></Table.HeaderCell> */}
              <Table.HeaderCell style={{}}>#</Table.HeaderCell>
              <Table.HeaderCell style={{}}>試験日</Table.HeaderCell>
              <Table.HeaderCell style={{}}>テストコード</Table.HeaderCell>
              <Table.HeaderCell style={{}}>試験レべル</Table.HeaderCell>
              <Table.HeaderCell style={{}}>文字語彙</Table.HeaderCell>
              <Table.HeaderCell style={{}}>聴解</Table.HeaderCell>
              <Table.HeaderCell style={{}}>文法</Table.HeaderCell>
              <Table.HeaderCell style={{}}>読解</Table.HeaderCell>
              <Table.HeaderCell style={{}}>作文</Table.HeaderCell>
              <Table.HeaderCell style={{}}>会話</Table.HeaderCell>
              <Table.HeaderCell style={{}}>作文評価</Table.HeaderCell>
              <Table.HeaderCell style={{}}>会話評価</Table.HeaderCell>
              <Table.HeaderCell style={{}}>元のクラス</Table.HeaderCell>
              <Table.HeaderCell style={{}}>新しいクラス</Table.HeaderCell>
              <Table.HeaderCell style={{}}></Table.HeaderCell>
              {/* <Table.HeaderCell style={{}}>飛び級状況</Table.HeaderCell> */}
              {/* <Table.HeaderCell></Table.HeaderCell> */}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {grade2.length ? grade2.map((item, index) => (<React.Fragment key={index}>
              <Table.Row>
                {/* {isEdit ? <Table.Cell><Button size='small' onClick={() => this.editRowModal2()} icon='edit' /></Table.Cell> : null} */}
                <Table.Cell>{item.rank || ''}/{item.rankTotal || ''}</Table.Cell>
                <Table.Cell>{item.testDate}</Table.Cell>
                <Table.Cell>{item.testCode}</Table.Cell>
                <Table.Cell>{item.testLevel}</Table.Cell>
                <Table.Cell>{item.vocabularyScore}</Table.Cell>
                <Table.Cell>{item.listeningScore}</Table.Cell>
                <Table.Cell>{item.grammarScore}</Table.Cell>
                <Table.Cell>{item.readingScore}</Table.Cell>
                <Table.Cell>{item.writingOriScore}</Table.Cell>
                <Table.Cell>{item.conversationOriScore}</Table.Cell>
                <Table.Cell>{item.writingScore}</Table.Cell>
                <Table.Cell>{item.conversationScore}</Table.Cell>
                <Table.Cell>{item.oriClass}</Table.Cell>
                <Table.Cell>{item.newClass}</Table.Cell>
                <Table.Cell><Button content='テスト一覽' color='green' size='tiny' as={Link} to={`/ExamTable/${item.gradeId}`} /></Table.Cell>                {/* <Table.Cell>{item.isSkipGrade}</Table.Cell> */}
              </Table.Row>
            </React.Fragment>)) : <Table.Row>
                <Table.Cell colSpan='15' textAlign='center' >(資料無し)</Table.Cell>
              </Table.Row>}
          </Table.Body>
        </Table>
        {/* </div> */}
        <Modal open={this.state.show} onClose={this.handleClose} size="small">
          <Modal.Header>{this.state.modalTitle}成績</Modal.Header>
          <Modal.Content>
            {/* <Form.Group >
              <label>項目</label>{'  '}
              <Form.Input as="select" placeholder="select" name="testName"
                onChange={this.handleChange} value={this.state.form.testName} >
                <option value="">-- 選択 --</option>
                {testStudentArr.sort((a, b) => b.value - a.value).map(({ _id, value, label }) => (<option key={_id} value={value}>{label}</option>))}
              </Form.Field>
            </Form.Group> */}
            {/* <Form.Group >
              <label>項目</label>{'  '}
              <Form.Input name="testName"
                onChange={this.handleChange} value={this.state.form.testName} >
              </Form.Field>
            </Form.Group> */}
            {/* <Form.Group >
              <label>文字語彙</label>
              <Form.Input name="vocabularyScore"
                onChange={this.handleChange} value={this.state.form.vocabularyScore} />
            </Form.Group>
            <Form.Group >
              <label>聴解</label>
              <Form.Input name="listeningScore"
                onChange={this.handleChange} value={this.state.form.listeningScore} />
            </Form.Group>
            <Form.Group >
              <label>文法</label>
              <Form.Input name="grammarScore"
                onChange={this.handleChange} value={this.state.form.grammarScore} />
            </Form.Group>
            <Form.Group >
              <label>読解</label>
              <Form.Input name="readingScore"
                onChange={this.handleChange} value={this.state.form.readingScore} />
            </Form.Group>
            <Form.Group >
              <label>作文評価</label>
              <Form.Input name="writingScore"
                onChange={this.handleChange} value={this.state.form.writingScore} />
            </Form.Group>
            <Form.Group >
              <label>会話評価</label>
              <Form.Input name="conversationScore"
                onChange={this.handleChange} value={this.state.form.conversationScore} />
            </Form.Group> */}
            {/* <Form.Group >
              <label>元のクラス</label>{'  '}
              <Form.Field as="select" placeholder="select" name="oriClass"
                onChange={this.handleChange} value={this.state.form.oriClass} >
                <option value="">-- 選択 --</option>
                {classArr.sort((a, b) => a.orderId - b.orderId).map(({ _id, label }) => (<option key={_id} value={label}>{label}</option>))}
              </Form.Field>
            </Form.Group> */}
            <Form.Group >
              <label>新しいクラス</label>{'  '}
              <Form.Field as="select" placeholder="select" name="newClass"
                onChange={this.handleChange} value={this.state.form.newClass} >
                <option value="">-- 選択 --</option>
                {classArr.sort((a, b) => a.orderId - b.orderId).map(({ _id, label }) => (<option key={_id} value={label}>{label}</option>))}
              </Form.Field>
            </Form.Group>
            <Form.Group >
              <label>飛び級状況</label>
              <Form.Input name="isSkipGrade"
                onChange={this.handleChange} value={this.state.form.isSkipGrade} />
            </Form.Group>

            {/* <Form.Group >
              <label>作文成績</label>{'  '}
              <Form.Field as="select" placeholder="select" name="courseTeacher"
                onChange={this.handleChange} value={this.state.form.courseTeacher} >
                <option value="">-- 選択 --</option>
                <option value="-1">(尚未安排)</option>
                {teachers.sort((a, b) => a.orderId - b.orderId).map(({ _id, nameTeacher, teacherTitleText, label }) => (<option key={_id} value={_id}>{teacherTitleText} {nameTeacher}</option>))}
              </Form.Field>
            </Form.Group> */}

            {/* <Form.Group >
              <label>自動機能</label>{'  '}
              <Form.Field as="select" placeholder="select" name="courseTeacher"
                onChange={this.handleChange} value={this.state.form.courseTeacher} >
                <option value="">-- 選択 --</option>
                <option value="-1">(尚未安排)</option>
                {teachers.sort((a, b) => a.orderId - b.orderId).map(({ _id, nameTeacher, teacherTitleText, label }) => (<option key={_id} value={_id}>{teacherTitleText} {nameTeacher}</option>))}
              </Form.Field>
            </Form.Group> */}

          </Modal.Content>
          <Modal.Content>
            <Button onClick={this.handleSubmit}>{this.state.modalTitle}</Button>
            <Button onClick={this.handleClose} icon='remove' content='キャンセル' />
          </Modal.Content>
        </Modal>
      </>
    );
  }
}