import React, { Component } from 'react'
import { Container, Button, Dimmer, Loader, Image, Segment, Header, Tab, Form } from 'semantic-ui-react';
import MaterialTable from 'material-table'

import localization from 'widget/MaterialTableOpt'
import Parse from 'widget/parse'
import { dateCol, typeCurrency, monthOpt, mgyearOpt, exportPdf } from "views/web/CustomMaterialTable";

const roomType = { '0': '單人房', '1': '雙人房' };

const columns = () => ([
  dateCol({ width: 80, title: '支出日期', field: 'date' }),
  { width: 80, title: '支出項目', field: 'expenditureItem' },
  { width: 80, title: '金額', field: 'money' },
  { width: 80, title: '備註', field: 'ps' },
]);

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}
const d = new Date()
export default class Client1 extends Component {
  state = {
    dataArr: [],
    dataArr1: [],
    dataArr2: [],
    rowData: {},
    roomObj: {},
    loading: true,
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
  }
  componentDidMount() {
    // const { calendar } = this.props
    // this.setState({ dataArr: calendar })
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async () => {
    const { companyObj } = this.props;
    const companyId = companyObj.objectId

    const data = await Parse.queryData('expenditure', { companyId });

  }

  handleAdd = async (type) => {
    const { newData } = this.state
    await Parse.saveData('expenditure', newData);
  }

  handleUpdate = async (type) => {
    const { newData } = this.state
    await Parse.saveData('expenditure', newData);
  }

  handleDelete = async (type) => {
    const { oldData, oldData1 } = this.state
    await Parse.deleteData('expenditure', oldData);
    this.setState({ oldData: {} })
  }

  render() {
    const { firebase, companyObj } = this.props;
    const { dataArr, mgyearSel, monthSel } = this.state;

    return (<>
      <div className="content">
        <div style={{ ...style.flexCenter, position: 'relative', zIndex: 100 }}>
          {/* <Header as='h2' style={{ margin: '2rem 0' }}>客房資料</Header> */}
        </div>
        <Form>
          <MaterialTable
            // isLoading={loading}
            localization={localization()}
            columns={columns()}
            options={{
              pageSize: 5,
              search: false
            }}
            data={dataArr}
            title={'支出'}
            // actions={[
            //   {
            //     icon: 'view_headline',
            //     tooltip: '查看',
            //     onClick: (event, rowData) => this.handleData(rowData)
            //   }
            // ]}
            editable={{
              onRowAdd: newData =>
                new Promise(async (resolve, reject) => {
                  const data = [...dataArr];
                  const db = Parse.Object.extend("expenditure");
                  const newDoc = new db();
                  const doc = await newDoc.save();
                  const obj = {
                    id: doc.id,
                    _id: doc.id,
                    companyId: companyObj.objectId,
                    companyName: companyObj.companyName,
                    year: mgyearSel,
                    month: monthSel,
                    ...newData
                  }
                  // data.push(obj);
                  data.push(obj);
                  this.setState({ dataArr: data, newData: obj }, () => resolve());
                }).then(() => this.handleAdd(1)),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const data = dataArr;
                  const index = data.indexOf(oldData);
                  data[index] = newData;
                  // handleState('people0', data);
                  this.setState({ dataArr: data, newData, oldData }, () => resolve());
                }).then(() => this.handleUpdate(1)),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  let data = dataArr;
                  const index = data.indexOf(oldData);
                  data.splice(index, 1);
                  // handleState('people0', data);
                  this.setState({ dataArr: data, oldData }, () => resolve());
                }).then(() => this.handleDelete(1)),
            }}
          />
          <br />
          <br />
        </Form>
      </div>
    </>)
  }
}