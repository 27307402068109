import React, { Component } from 'react';
import { Form, Button, Modal, Table, Grid, Icon, Header, Confirm, TextArea, Divider, Card } from 'semantic-ui-react';
import _ from 'underscore'

import intl from 'react-intl-universal';

// import { showField, showFieldText, getAvailblePeriodArr, getOptionsByKey } from 'views/widgets/FieldsRender';

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}
const getAuthArray = (company, user, flag) => company[flag] ? _.union(user[flag + 'A'], [company.id]) : _.without(user[flag + 'A'], company.id);
const getAuth = (id, arr) => (arr.length && arr.indexOf(id) !== -1)
export default class List1Employee extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      show: false,
      isReadOnly: false,
      // form: {},
      loading: false,
      confirm: false,
      unsub: null,
      // teacher: [],
    };
  }

  componentDidMount() {
    // this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    // this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj } = props;

    // const db = firebase.firestore();
    // this.setState({ loading: true });
    // db.collection(`users`).where("authUserA", "array-contains", companyObj._id)
    //   // .where('companyId', '==', _id)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => {
    //       const element = item.data()
    //       data.push({
    //         _id: item.id,
    //         ...element,
    //         authAdmin: getAuth(companyObj._id, element.authAdminA || []),
    //         authHr: getAuth(companyObj._id, element.authHrA || []),
    //         authAccount: getAuth(companyObj._id, element.authAccountA || []),
    //         authUser: getAuth(companyObj._id, element.authUserA || []),
    //         authLogin: getAuth(companyObj._id, element.authLoginA || []),

    //       })
    //     });
    //     this.setState({ dataArr: data, loading: false });
    //     // console.log(data)
    //   }, err => { console.log(`Encountered error: ${err}`); });

    // const snap = await db.collection(`company`).get();
    // const companyArr = []; //'-1': '請選擇' };
    // snap.forEach(doc => companyArr.push({ _id: doc.id, ...doc.data() }));

    // this.setState({ companyArr });
  }
  // handleChange = (event, data) => {
  //   const { name, value } = data;
  //   this.setState({
  //     form: {
  //       ...this.state.form,
  //       [name]: value
  //     }
  //   })
  // }

  // handleSubmit = () => {
  //   const {teachers, match } = this.props
  //   const { form, teacher } = this.state
  //   const { _id } = match.params;
  //   this.setState({ loading: true })

  //   firebase.firestore().collection(`bg/${bgId}/employee`).doc(_id)
  //     .update(form).then(snap => {
  //       this.setState({ loading: false });
  //     });
  // }

  render() {
    const { bg, employeeObj, handleUpdateSetting, doc, form, userObj, handleChange, handleSaveUser } = this.props
    const { is_auth, onbroad_date, leave_date, name, jobNum, engname, tw_id, passport_name, birthday_date, jobyear, jobTitle, email, phone, ps } = userObj
    // const { teacher, loading } = this.state;
    // console.log(form.department_id)
    let now = new Date().getTime()
    let onbroad = new Date(userObj.onbroad_date).getTime()
    let seniorityYear = parseInt((now - onbroad) / 1000 / 60 / 60 / 24 / 365)
    let seniorityMonth = parseInt(((now - onbroad) - (seniorityYear * 86400000 * 365)) / 1000 / 60 / 60 / 24 / 30)
    if (seniorityMonth < 0) {
      seniorityMonth = 0
    }
    return (<>
      <div style={style.flexCenter}>
        <Header as='h2' content='基本資料' />
      </div>
      <Divider />
      <Form>
        <Form.Group widths='equal'>
          {/* <Form.Select label='在職狀態' placeholder='在職狀態' name='is_auth' value={is_auth} onChange={handleChange} /> */}
          <Form.Input label='工號' placeholder='工號' name='jobNum' value={jobNum} onChange={handleChange} />
          <Form.Input label='中文姓名' placeholder='中文姓名' name='name' value={name} onChange={handleChange} />
          <Form.Input label='英文姓名' placeholder='英文姓名' name='engname' value={engname} onChange={handleChange} />
          <Form.Input label='護照姓名' placeholder='護照姓名' name='passport_name' value={passport_name} onChange={handleChange} />
        </Form.Group>
        <br />
        <Form.Group widths='equal'>
          {/* <Form.Input label='就職日期' placeholder='就職日期' name='onbroad_date' value={onbroad_date} onChange={handleChange} /> */}
          {/* <Form.Input label='離職日期' placeholder='離職日期' name='leave_date' value={leave_date} onChange={handleChange} /> */}
          <Form.Input label='就職日期' placeholder='就職日期' name='onbroad_date' value={onbroad_date} onChange={handleChange} />
          <Form.Input label='離職日期' placeholder='離職日期' name='leave_date' value={leave_date} onChange={handleChange} />
          <Form.Input label='年資' placeholder='年資' name='jobyear' value={`${seniorityYear}年${seniorityMonth}月`} onChange={handleChange} />
          <Form.Input label='職稱' placeholder='職稱' name='jobTitle' value={jobTitle} onChange={handleChange} />
        </Form.Group>
        <br />
        <Form.Group widths='equal'>
          <Form.Input label='身分證字號' placeholder='身分證字號' name='tw_id' value={tw_id} onChange={handleChange} />
          <Form.Input label='生日' placeholder='生日' name='birthday_date' value={birthday_date} onChange={handleChange} />
          <Form.Input label='Email' placeholder='Email' name='email' value={email} onChange={handleChange} />
          <Form.Input label='聯絡電話' placeholder='聯絡電話' name='phone' value={phone} onChange={handleChange} />
        </Form.Group>
        <br />
        <Form.Group widths='equal'>
          <Form.Input label='備註' placeholder='備註' name='ps' value={ps} onChange={handleChange} />
        </Form.Group>
        <br />
        <Form.Button color='green' onClick={handleSaveUser}>{intl.get('儲存')}</Form.Button>
      </Form>
    </>
    )
  }
}