import React, { Component } from 'react'
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";

import Ordering1 from './Ordering1'
import SalesInventoryTab2 from "./SalesInventoryTab2";
// import ClientTab3 from "./ClientTab3";
// import ClientTab4 from "./ClientTab4";

class SalesInventoryTab extends Component {
  state = {
    tabId: "p2",
  }

  componentDidMount = async () => {
    this.handleParams()
  }

  componentWillReceiveProps(nextProps) {
    this.handleParams(nextProps);
  }

  handleParams = async (props = this.props) => {
    const { match, } = props;
    const { t1 } = match.params;
    if (typeof t1 !== 'undefined') {
      this.setState({ tabId: t1 });
    }
  }

  // handleItemClick = (e, { name }) =>
  //   this.setState({ activeItem: name })

  render() {
    const { history, clientObj } = this.props;
    const { tabId } = this.state
    return (<div className="content">
      {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 15 }}>
          <Button.Group style={{ marginRight: '5px' }}>
          </Button.Group>
        </div> */}
      <div className="nav-tabs-navigation">
        <div className="nav-tabs-wrapper">
          <Nav id="tabs" role="tablist" tabs>
            <NavItem>
              <NavLink
                aria-expanded={
                  tabId === "p2"
                }
                data-toggle="tab"
                href="#"
                role="tab"
                className={
                  tabId === "p2"
                    ? "active"
                    : ""
                }
                onClick={(e) => {
                  e.preventDefault()
                  // this.setState({ tabId: "p2" })
                  history.push(`/admin/SalesInventory/${clientObj.objectId}/p2`)
                }
                }
              >
                訂單查詢
                          </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-expanded={tabId === "p1"}
                data-toggle="tab"
                href="#"
                role="tab"
                className={
                  tabId === "p1"
                    ? "active"
                    : ""
                }
                onClick={(e) => {
                  e.preventDefault()
                  // this.setState({ tabId: "p1" })
                  history.push(`/admin/SalesInventory/${clientObj.objectId}/p1`)
                }
                }
              >
                基本資料
                          </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                aria-expanded={
                  tabId === "p3"
                }
                data-toggle="tab"
                href="#"
                role="tab"
                className={
                  tabId === "p3"
                    ? "active"
                    : ""
                }
                onClick={(e) => {
                  e.preventDefault()
                  // this.setState({ tabId: "p3" })
                  history.push(`/admin/Client/${clientObj.objectId}/p3`)
                }
                }
              >
                往來記錄
                          </NavLink>
            </NavItem> */}
            {/* <NavItem>
                <NavLink
                  aria-expanded={
                    tabId === "p4"
                  }
                  data-toggle="tab"
                  href="#"
                  role="tab"
                  className={
                    tabId === "p4"
                      ? "active"
                      : ""
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    // this.setState({ tabId: "p4" })
                    history.push(`/admin/Client/${clientObj.objectId}/p4`)
                  }
                  }
                >
                  欠款記錄
                          </NavLink>
              </NavItem> */}
          </Nav>
        </div>
      </div>
      <TabContent
        className="text-center"
        id="my-tab-content"
        activeTab={tabId}
      >
        <TabPane tabId="p1" role="tabpanel">
          <SalesInventoryTab2 {...this.props} />
        </TabPane>
        <TabPane tabId="p2" role="tabpanel">
          <Ordering1 {...this.props} selectData={clientObj} obj={{ title: '案件', subdb1: 'pawning1', subdb2: 'pawning2', color: 'orange', icon: 'fire' }} category='p1' />
        </TabPane>
        {/* <TabPane tabId="p3" role="tabpanel"> */}
        {/* <ClientTab3 {...this.props} /> */}
        {/* </TabPane>
        <TabPane tabId="p4" role="tabpanel" > */}
        {/* <ClientTab4 {...this.props} /> */}
        {/* </TabPane> */}
      </TabContent>
      {/* <div style={{ width: '300px', float: 'left' }}><h3>{clientObj.name}</h3></div> */}
    </div>);
  }
}

export default SalesInventoryTab;