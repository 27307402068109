import React, { Component } from 'react'
import { Container, Header, Dimmer, Loader, Image, Segment } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

const columns = () => ([
  { title: '會計年度(民國)', field: 'value' },
  { title: '會計年度(西元)', field: 'value_AD' },
]);

export default class Fin1104 extends Component {
  state = {
    dataArr: [],
    deleteConfirm: false,
    loading: true
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { match, companyObj } = props;
    // const { clientId } = match.params;
    const companyId = companyObj.objectId;
    const mgyear = await Parse.queryData('mgyear', { companyId });
    this.setState({ dataArr: mgyear, loading: false, mgyear });
  }

  handleAdd = async () => {
    const { newData } = this.state
    const obj = {
      ...newData,
    };
    await Parse.saveData('mgyear', obj);
  }

  handleUpdate = async () => {
    const { newData } = this.state
    const obj = { ...newData };
    await Parse.saveData('mgyear', obj);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    await Parse.deleteData('mgyear', oldData);
    this.setState({ oldData: {} })
  }

  render() {
    const { companyObj, auth } = this.props
    const { dataArr, loading } = this.state;

    const editable = {
      isEditHidden: () => !auth.edit,
      isDeleteHidden: () => !auth.edit,
      onRowAdd: newData =>
        new Promise(async (resolve, reject) => {
          const db = Parse.Object.extend("mgyear");
          const newDoc = new db();
          const doc = await newDoc.save();
          const data = [...dataArr];
          const obj = {
            _id: doc.id,
            id: doc.id,
            objectId: doc.id,
            companyId: companyObj.objectId || '',
            companyName: companyObj.name || '',
            ...newData
          }
          data.push(obj);
          this.setState({ dataArr: data, newData: obj }, () => resolve());
        }).then(() => this.handleAdd()),
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          const data = [...dataArr];
          const index = data.indexOf(oldData);
          data[index] = newData;
          // handleState('people0', data);
          this.setState({ dataArr: data, newData, oldData }, () => resolve());
        }).then(() => this.handleUpdate()),
      onRowDelete: oldData =>
        new Promise((resolve, reject) => {
          let data = [...dataArr];
          const index = data.indexOf(oldData);
          data.splice(index, 1);
          this.setState({ dataArr: data, oldData }, () => resolve());
        }).then(() => this.handleDelete()),
    }

    if (auth.edit === false) {
      delete editable.onRowAdd
    }

    return (<>
      {/* <div style={{ position: 'relative', zIndex: 100 }}>
        <Header inverted={companyObj.inverted} as='h2' style={{ margin: '2rem 0' }}>會計年度</Header>
      </div> */}
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        style={{ width: '600px' }}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '會計年度'),
          pageSize: dataArr.length || 10,
          search: false
        }}
        data={dataArr}
        title="會計年度"
        editable={editable}
      />
      <br />
      <br />
    </>)
  }
}