import React, { Component } from 'react'
import { Container, Header, Button, Form, Grid, Select, Menu } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import './css/customStyle.css'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import PdfModal2 from './PdfModal2';
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  alphabetBtn: {
    cursor: 'pointer',
    margin: 5,
    color: '#902d8c',
    fontSize: 18
  },
  alphabetBtnIsClick: {
    cursor: 'pointer',
    margin: 5,
    color: '#000000',
    textDecorationLine: 'underline',
    fontSize: 18
  }
}

/*
新案申請_保險-Pru Life    1
新案申請_保險-VOYA    2
新案申請_英國房產-股權    3
新案申請_英國房產-產權    4
新案申請_美國.加拿大 5
FPI架構   6
股票  7
AVIVA/Zurich/Standardlife/MassMutual/AIA 架構 8
 */
const objPortfolioTemplate = [
  { key: 'profolioTmp', text: '-- 全選 --', value: '-1' },
  { key: "profolioTmp0", value: "0", text: "顯示樣版無定義" },
  { key: "profolioTmp1", value: "1", text: "保險-Pru Life" }, // ok
  { key: "profolioTmp2", value: "2", text: "保險-VOYA" },     // ok like 1 ok
  { key: "profolioTmp3", value: "3", text: "英國房產-股權" },  // ok
  { key: "profolioTmp4", value: "4", text: "英國房產-產權" },   // ok like 3
  { key: "profolioTmp5", value: "5", text: "美國、加拿大房產" },    // ok like 3
  { key: "profolioTmp6", value: "6", text: "FPI" }, // ok
  { key: "profolioTmp7", value: "7", text: "股票" },                  // ok like 3
  { key: "profolioTmp8", value: "8", text: "AVIVA/Zurich/Standardlife/MassMutual/AIA" }, // ok like 6
  // { id:"9", value:"(AS)Zurich架構"},
  // { id:"10", value:"(AS)AVIVA架構"},
  // { id:"11", value:"(AS)FPI架構"},
];
// {...this.props}
const columns = (handleOpenPdf, auth) => ([
  {
    title: '售後服務 ', field: 'button', width: '100px', render: rowData =>
      <Button style={{ margin: '4px' }} color='olive' basic size='small' onClick={() => handleOpenPdf(rowData)} disabled={!auth.edit}>新申請</Button>
    // <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => handleOpenPdf(rowData)}>新申請</span>
    // <PdfModal2 docs={rowData} />
  },
  // { title: '客戶編號', field: 'client_uid' },
  // { title: '客戶', field: 'name_cht' },
  {
    title: '聯絡', field: 'contactnum', render: rowData =>
      <React.Fragment key={"c" + rowData.objectId}>
        {rowData.client_uid} {rowData.name_cht}<br />
        {rowData.contactnum}<br />
        {rowData.email}
      </React.Fragment>
  },
  // { title: '聯絡電話', field: 'contactnum' },
  // { title: '電子信箱', field: 'email', cellStyle: { width: '100px' } },
  {
    title: '投資項目', field: 'product_text', render: rowData =>
      <React.Fragment key={"p" + rowData.objectId}>
        1. {rowData.product1_text}<br />
        2. {rowData.product2_text}<br />
        3. {rowData.product3_text}<br />
        4. {rowData.product4_text}
      </React.Fragment>
  },
  // { title: '投資項目1', field: 'product1_text' },
  // { title: '投資項目2', field: 'product2_text' },
  // { title: '投資項目3', field: 'product3_text' },
  // { title: '投資項目4', field: 'product4_text' },
  // { title: 'Policy No.', field: 'account_num' },
  {
    title: 'Policy No.', field: 'account_num',
    render: rowData => <a href={`/admin/clientsPage/1/${rowData.client_id}/1/${rowData.objectId}`} target='_blank' rel="noopener noreferrer">{rowData.account_num || "(無編號)"}</a>
  },
  { title: '財務顧問', field: 'agent_text' },
  { title: '供應商', field: 'provider_chttext' },
]);
const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const alphabetArr = alphabet.split('')
alphabetArr.unshift('全選')

export default class Client2 extends Component {
  state = {
    loginUser: this.props.user.profile.toJSON(),
    productType1Opt: [],
    productType2Opt: [{ key: 'productType20', text: '-- 全選 --', value: '-1' }],
    productType3Opt: [{ key: 'productType30', text: '-- 全選 --', value: '-1' }],
    productType4Opt: [{ key: 'productType40', text: '-- 全選 --', value: '-1' }],
    disabled2: true,
    disabled3: true,
    disabled4: true,
    value1: '-1',
    value2: '-1',
    value3: '-1',
    value4: '-1',
    valueButton: '0',
    valueProvider: '-1',
    valueAgent: '-1',
    valueType: '-1',
    dataArr: [],
    dataArr2: [],
    providerOpt: [],
    form: {},
    searchText: '',
    activeItem: '0',
    alpha: '全選',
  }

  componentDidMount = async () => {
    const { companyObj } = this.props;
    const companyId = companyObj.objectId;
    Parse.queryData('product1', { companyId }).then(async (product1) => {
      const product2 = await Parse.queryData('product2', { companyId });
      const product3 = await Parse.queryData('product3', { companyId });
      const product4 = await Parse.queryData('product4', { companyId });
      const provider = await Parse.queryData('provider', { companyId });

      if (product1 && product1.length) {
        const productType1Opt = [{ key: 'productType', text: '-- 全選 --', value: '-1' }].concat(product1.sort((a, b) => a.order - b.order).map(item => ({ key: item.objectId, text: item.value, value: item.objectId })));
        const providerOpt = [{ key: 'providerType', text: '-- 全選 --', value: '-1' }].concat(provider.map(item => ({ key: item.objectId, text: item.name_cht + ' (' + item.name_eng + ')', value: item.objectId })));
        this.setState({ productType1Opt, providerOpt })
      }
      this.setState({ product1, product2, product3, product4, provider });
    });

    const { loginUser } = this.state
    const pageArr = window.location.pathname.split('/')
    const authPage = pageArr[2]
    const authUser = companyId + '_' + authPage
    let auth = {}
    let isAgent = ''

    if (loginUser.authAdminA && loginUser.authAdminA.indexOf(companyId) === -1 && loginUser.role && loginUser.role.value === '1') {
      isAgent = loginUser.objectId
    }

    if ((loginUser.authAdminA && loginUser.authAdminA.indexOf(companyObj.objectId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
      auth = {
        edit: true,
      }
    } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
      auth = {
        edit: false,
      }
    } else {
      auth = {
        edit: false,
      }
    }
    // let filPortfolios = []
    // if (isAgent && isAgent.length) {
    //   filPortfolios = portfolios.filter(item => item.agent_id === isAgent)
    // } else {
    //   filPortfolios = portfolios
    // }
    if (isAgent && isAgent.length) {
      Parse.queryData('portfolios', { companyId, agent_id: isAgent }).then(async (portfolios) => {
        const clients = await Parse.queryData('clients', { companyId, agent_id: isAgent });
        let copyData = portfolios.map(item => item = { ...item, copy: 1 })
        this.setState({ dataArr: copyData, portfolios, clients, auth, isAgent });
      });
    } else {
      Parse.queryData('portfolios', { companyId }).then(async (portfolios) => {
        const clients = await Parse.queryData('clients', { companyId });
        let copyData = portfolios.map(item => item = { ...item, copy: 1 })
        this.setState({ dataArr: copyData, portfolios, clients, auth, isAgent });
      });
    }
  }

  handleChange = (e, { name, value, id }) => {
    const { product2, product3, product4 } = this.state;

    let obj = {}
    if (name === 'productType1') {
      const productType2Opt = [{ key: 'productType20', text: '-- 全選 --', value: '-1' }].concat(product2.filter(item => item.product1_id === value)
        .sort((a, b) => a.orderId - b.orderId).map(item => ({ key: item.objectId, text: item.name_cht + ' (' + item.name_eng + ')', value: item.objectId })));
      obj = { value1: value, value2: '-1', value3: '-1', value4: '-1', disabled2: false, disabled3: true, disabled4: true, productType2Opt };
    } else if (name === 'productType2') {
      const productType3Opt = [{ key: 'productType30', text: '-- 全選 --', value: '-1' }].concat(product3.filter(item => item.product2_id === value)
        .sort((a, b) => a.orderId - b.orderId).map(item => ({ key: item.objectId, text: item.name_cht + ' (' + item.name_eng + ')', value: item.objectId })));
      obj = { value2: value, value3: '-1', value4: '-1', disabled3: false, disabled4: true, productType3Opt };
    } else if (name === 'productType3') {
      const productType4Opt = [{ key: 'productType40', text: '-- 全選 --', value: '-1' }].concat(product4.filter(item => item.product3_id === value)
        .sort((a, b) => a.orderId - b.orderId).map(item => ({ key: item.objectId, text: item.name_cht + ' (' + item.name_eng + ')', value: item.objectId })));
      obj = { value3: value, value4: '-1', disabled4: false, productType4Opt };
    } else if (name === 'productType4') {
      obj = { value4: value };
    } else if (name === 'portfolioType') {
      obj = { valueType: value };
    } else if (name === 'providerType') {
      obj = { valueProvider: value };
    } else if (name === 'nowphase') {
      obj = { valueButton: id };
    } else {
      this.setState({ [name]: value })
    }

    this.setState({ ...obj }, () => this.handleTableData());
  }

  handleTableData = () => {
    const { value1, value2, value3, value4, valueType, valueProvider, valueButton, portfolios, alpha, searchText } = this.state;

    let dataArr = [...portfolios];
    if (searchText) {
      // 搜尋值中間可以插入任意值 例如搜尋'程雪原' 可以找到 '程 雪原'
      const looseSearchValue = RegExp(searchText.split('').reduce((pattern, item) => pattern + item + '.*', '.*'));
      dataArr = dataArr.filter(item => item && item.name_cht && item.name_cht.match(looseSearchValue));
    }
    if (alpha !== '全選') {
      dataArr = dataArr.filter(item => item.name_eng ? item.name_eng.split('')[0] === alpha : '')
    }
    if (value1 !== '-1') {
      dataArr = dataArr.filter(item => item.product1_id === value1);
    }
    if (value2 !== '-1') {
      dataArr = dataArr.filter(item => item.product2_id === value2);
    }
    if (value3 !== '-1') {
      dataArr = dataArr.filter(item => item.product3_id === value3);
    }
    if (value4 !== '-1') {
      dataArr = dataArr.filter(item => item.product4_id === value4);
    }
    if (valueType !== '-1') {
      dataArr = dataArr.filter(item => item.template_id === valueType);
    }
    if (valueProvider !== '-1') {
      dataArr = dataArr.filter(item => item.provider_id === valueProvider);
    }
    if (valueButton !== '0') {
      dataArr = dataArr.filter(item => item.nowphase === valueButton);
    }
    // console.log(dataArr)
    this.setState({ dataArr })
  }

  handleClick = (e) => {
    const { portfolios } = this.state
    const alpha = e.target.id
    this.setState({ alpha }, () => this.handleTableData())
    // if (alpha !== '全選') {
    //   const dataArr = portfolios.filter(item => item.name_eng ? item.name_eng.split('')[0] === alpha : '')
    //   this.setState({ dataArr })
    // } else {
    //   this.setState({ dataArr: portfolios })
    // }
  }

  handleOpenPdf = (rowData) => {
    this.setState({ showPdf: true, form: rowData })
  }

  handleClosePdf = (rowData) => {
    this.setState({ showPdf: false, form: "" })
  }

  handleSearch = (e, { value }) => {
    // const { portfolios } = this.state;
    this.setState({ searchText: value }, () => this.handleTableData())
    // if (value) {
    //   // 搜尋值中間可以插入任意值 例如搜尋'程雪原' 可以找到 '程 雪原'
    //   const looseSearchValue = RegExp(value.split('').reduce((pattern, item) => pattern + item + '.*', '.*'));
    //   const dataArr = portfolios.filter(item => item && item.name_cht && item.name_cht.match(looseSearchValue));
    //   this.setState({ dataArr, searchText: value });
    // } else {
    //   this.setState({ dataArr: portfolios, searchText: value });
    // }
  }

  render() {
    const { companyObj } = this.props
    const { productType1Opt, productType2Opt, productType3Opt, productType4Opt, providerOpt, valueProvider, valueType, activeItem,
      auth, disabled2, disabled3, disabled4, value1, value2, value3, value4, dataArr, showPdf, form, searchText, clients, alpha } = this.state;

    return (<div className="content">
      <PdfModal2 {...this.props} showPdf={showPdf} doc={form} handleClosePdf={this.handleClosePdf} clients={clients} />
      <Container style={{ position: 'relative', zIndex: 100 }}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={2} computer={2} mobile={16}>
              <Header inverted={companyObj.inverted} content='項目狀況' />
            </Grid.Column>
            <Grid.Column width={13} computer={13} mobile={16}>
              <Button.Group color='violet' inverted fluid>
                <Button content='全選' color='purple' inverted={false} onClick={this.handleChange} name='nowphase' id='0' />
                <Button content='申請中' onClick={this.handleChange} name='nowphase' id='1' />
                <Button content='已生效' onClick={this.handleChange} name='nowphase' id='2' />
                <Button content='無效' onClick={this.handleChange} name='nowphase' id='3' />
                <Button content='無產品' onClick={this.handleChange} name='nowphase' id='4' />
              </Button.Group>
              {/* <Menu color={'purple'} inverted compact widths={3} >
                  <Menu.Item
                    name='全選' id='0'
                    active={activeItem === '0'}
                    onClick={this.handleChange}
                  />
                  <Menu.Item
                    name='申請中' id='1'
                    active={activeItem === '1'}
                    onClick={this.handleItehandleChangemClick}
                  />
                  <Menu.Item
                    name='已生效' id='2'
                    active={activeItem === '2'}
                    onClick={this.handleChange}
                  />
                  <Menu.Item
                    name='無效' id='3'
                    active={activeItem === '3'}
                    onClick={this.handleChange}
                  />
                  <Menu.Item
                    name='無產品' id='4'
                    active={activeItem === '4'}
                    onClick={this.handleChange}
                  />
                </Menu> */}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns='equal'>
            <Grid.Column width={2} computer={2} tablet={16} mobile={16}>
              <Header inverted={companyObj.inverted} content='產品類別' />
            </Grid.Column>
            <Grid.Column computer={3} tablet={8} mobile={16}>
              <Form.Select options={productType1Opt} name='productType1' onChange={this.handleChange} value={value1} />
            </Grid.Column>
            <Grid.Column computer={3} tablet={8} mobile={16}>
              <Form.Select options={productType2Opt} name='productType2' onChange={this.handleChange} value={value2} disabled={disabled2} />
            </Grid.Column >
            <Grid.Column computer={3} tablet={8} mobile={16}>
              <Form.Select options={productType3Opt} name='productType3' onChange={this.handleChange} value={value3} disabled={disabled3} />
            </Grid.Column>
            <Grid.Column computer={3} tablet={8} mobile={16}>
              <Form.Select options={productType4Opt} name='productType4' onChange={this.handleChange} value={value4} disabled={disabled4} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns='equal'>
            <Grid.Column width={2} computer={2} tablet={2} mobile={16}>
              <Header inverted={companyObj.inverted} content='SEARCH' />
            </Grid.Column>
            <Grid.Column width={2} computer={2} tablet={2} mobile={16}>
              <Form.Input name='productfilter' placeholder='Search...' value={searchText} onChange={this.handleSearch} style={{ width: '195px' }} />
            </Grid.Column>
            <Grid.Column width={3} computer={3} tablet={16} mobile={16}>
              <Header as='h4' inverted={companyObj.inverted} content='供應商' style={{ paddingLeft: '78px', marginTop: '0px' }} />
            </Grid.Column>
            <Grid.Column width={4} computer={4} tablet={16} mobile={16}>
              <Form.Select options={providerOpt} name='providerType' onChange={this.handleChange} value={valueProvider} style={{ width: '350px' }} />
            </Grid.Column>
            <Grid.Column width={3} computer={3} tablet={16} mobile={16}>
              <Header as='h4' inverted={companyObj.inverted} content='版型' style={{ paddingLeft: '92px', marginTop: '0px' }} />
            </Grid.Column>
            <Grid.Column width={2} computer={2} tablet={16} mobile={16}>
              <Form.Select options={objPortfolioTemplate} name='portfolioType' onChange={this.handleChange} value={valueType} style={{ width: '265px' }} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns='equal'>
            <Grid.Column width={2}>
              <Header inverted={companyObj.inverted} content='客戶' />
            </Grid.Column>
            <Grid.Column style={{ ...style.flexCenter, alignItems: 'flex-start' }} width={13}>
              <div style={{ ...style.flex, flexWrap: 'wrap', background: '#f5f5e6', padding: '4px' }}>
                {alphabetArr.map((item, index) => alpha === item ? <span key={`${index}alphabetArr`} style={{ ...style.alphabetBtnIsClick }} className='alphabetBtn' id={item}>{item}</span>
                  : <span key={`${index}alphabetArr`} style={{ ...style.alphabetBtn }} className='alphabetBtn' onClick={this.handleClick} id={item}>{item}</span>)}
              </div>
              {/* <Button content='客戶' icon='add' color='purple' /> */}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <div style={{ padding: '10px' }}>
        <MaterialTable
          localization={localization()}
          style={{ marginBottom: '2rem' }}
          columns={columns(this.handleOpenPdf, auth)}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportAllData: true,
            exportPdf: (columns, data) => exportPdf(columns, data, '搜尋結果'),
            pageSize: dataArr.length > 50 ? 50 : (dataArr.length || 10),
            search: false,
          }}
          data={dataArr}
          title="搜尋結果"
        /></div>
    </div>)
  }
}