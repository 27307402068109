import React, { Component } from 'react'
import { Stage, Layer, Rect, Text, Line, Star } from 'react-konva';
import Konva from 'konva';

const toStraight = (str = '', num = 1) => {
  let hex, i;

  let result = "";
  for (i = 0; i < str.length; i++) {
    hex = str.charAt(i);
    for (let j = 0; j < num; j++) {
      hex = hex + '\n'
    }
    result += hex;
  }
  return result
}

const X = 0;
const Y = 0;
const X_CELL = 130; // 橫的
const Y_CELL = 155; // 直的

const WIDTH = X_CELL * 4;
const HEIGHT = Y_CELL * 4;

const FONTSIZE = 10;

const palacePosArr = [ // 12個宮位的初始位置
  { x: X + X_CELL * 2, y: Y + Y_CELL * 3 },
  { x: X + X_CELL * 1, y: Y + Y_CELL * 3 },
  { x: X + X_CELL * 0, y: Y + Y_CELL * 3 },

  { x: X + X_CELL * 0, y: Y + Y_CELL * 2 },
  { x: X + X_CELL * 0, y: Y + Y_CELL * 1 },
  { x: X + X_CELL * 0, y: Y + Y_CELL * 0 },

  { x: X + X_CELL * 1, y: Y + Y_CELL * 0 },
  { x: X + X_CELL * 2, y: Y + Y_CELL * 0 },
  { x: X + X_CELL * 3, y: Y + Y_CELL * 0 },

  { x: X + X_CELL * 3, y: Y + Y_CELL * 1 },
  { x: X + X_CELL * 3, y: Y + Y_CELL * 2 },
  { x: X + X_CELL * 3, y: Y + Y_CELL * 3 },
];

const centerPos = { x: X + X_CELL, y: Y + Y_CELL };

class ColoredRect extends Component {
  state = {
    color: 'green'
  };
  handleClick = () => {
    this.setState({
      color: Konva.Util.getRandomColor()
    });
  };
  render() {
    return (<Rect x={X} y={Y} width={WIDTH} height={HEIGHT} stroke="black" strokeWidth="1" />);
  }
}

const showCenter = (ziWei = {}) => {
  const { x, y } = centerPos;
  const { gender, solarDay, lunarDay, five } = ziWei;
  return <>
    <Text key={'gender'} x={x + 10} y={y + 10} text={`${gender || ''} ${five || ''}\n${solarDay || ''}\n${lunarDay || ''}`} />
    {/* <Text key={'solarDay'} x={x} y={y} text={solarDay} /> */}
  </>
}

// MangA: "丙<br/>子"
// MangB: "【命宮】"
// MangC: "【身】"
// Star6: []
// StarA: ["七殺"]
// StarB: []
// StarC: []

const showPalace12 = (ziWei = {}) => {

  if (ziWei && ziWei.arr && ziWei.arr.length) {
    // const { MangA, MangB, MangC, Star6, StarA, StarB, StarC } = ziWei.arr;

    return ziWei.arr.map(({ MangA, MangB, MangC, Star6, StarA, StarB, StarC, StarD, StarE, StarF, StarG }, i) => {

      const { x, y } = palacePosArr[i];
      let eLength = 0
      if (StarE[0].length === 1) {
        eLength = 11
      }
      return <>
        {/* 主星 */}
        {StarA.map((item, j) => <Text key={'StarA' + j} x={x + 3 + (FONTSIZE + 3) * j} y={y + 3} text={toStraight(item)} fill={'#CC0000'} />)}
        {Star6.map((item, j) => <Text key={'Star6' + j} x={x + 3 + (FONTSIZE + 3) * j + StarA.length * (FONTSIZE + 3)} y={y + 3} text={toStraight(item)} fill={'#A20055'} />)}
        {StarB.map((item, j) => <Text key={'StarB' + j} x={x + 3 + (FONTSIZE + 3) * j + (StarA.length + Star6.length) * (FONTSIZE + 3)} y={y + 3} text={toStraight(item)} fill={'#A20055'} />)}
        {StarC.map((item, j) => <Text key={'StarC' + j} x={x + 3 + (FONTSIZE + 3) * j + (StarA.length + Star6.length + StarB.length) * (FONTSIZE + 3)} y={y + 3} text={toStraight(item)} fill={'#4863A0'} />)}
        {StarD.map((item, j) => <Text key={'StarD' + j} x={x + 3 + (FONTSIZE + 3) * j} y={y + 80} text={toStraight(item)} fill={'#000'} />)}
        {StarE.map((item, j) => <Text key={'StarE' + j} x={x + 3 + (FONTSIZE + 3) * j} y={y + 130 + eLength} text={toStraight(item)} fill={'#000'} />)}
        {StarF.map((item, j) => <Text key={'StarF' + j} x={x + 3 + (FONTSIZE + 3) * j + (StarD.length) * (FONTSIZE + 3)} y={y + 80} text={toStraight(item)} fill={'#000'} />)}
        {StarG.map((item, j) => <Text key={'StarF' + j} x={x + 3 + (FONTSIZE + 3) * j + (StarD.length + StarF.length) * (FONTSIZE + 3)} y={y + 80} text={toStraight(item)} fill={'#000'} />)}


        {/* 干支 */}
        <Text key={'text' + i} x={x + X_CELL - FONTSIZE - 6} y={y + Y_CELL - (FONTSIZE * 2) - 6} text={toStraight(MangA)} />

        {/* 大限 */}
        <Text key={'text' + i} x={x} y={y + Y_CELL - (FONTSIZE * 4) - 10} text={`${ziWei.daShian.DShian[i + 1]}\n${ziWei.daShian.SShian[i + 1]}`} align='center' width={X_CELL} fill={'#444444'} />

        {/* 宮名 */}
        <Text key={'text' + i} x={x} y={y + Y_CELL - FONTSIZE - 6} text={MangB} align='center' width={X_CELL} fill={'#CC0000'} />
      </>
    });

    // return palaceArr.map(({ x, y }, index) => {
  } else {
    return null
  }

}

export default class ZwKonva extends Component {
  state = {
  }

  componentDidMount() {
    // const { calendar } = this.props
    // this.setState({ dataArr: calendar })
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async () => {
    // const { companyObj } = this.props;
    // const companyId = companyObj.objectId
  }

  render() {
    const { ziWei } = this.props;
    // const { dataArr, mgyearSel, monthSel } = this.state;

    return (<>
      <Stage width={window.innerWidth} height={window.innerHeight}>
        <Layer key='text'>
          {/* 直的線 */}
          <Line x={0} y={0} points={[X + X_CELL, Y, X + X_CELL, Y + Y_CELL * 4]} stroke="black" strokeWidth="1" />
          <Line x={0} y={0} points={[X + X_CELL * 3, Y, X + X_CELL * 3, Y + Y_CELL * 4]} stroke="black" strokeWidth="1" />
          <Line x={0} y={0} points={[X + X_CELL * 2, Y, X + X_CELL * 2, Y_CELL]} stroke="black" strokeWidth="1" />
          <Line x={0} y={0} points={[X + X_CELL * 2, Y + Y_CELL * 3, X + X_CELL * 2, Y + Y_CELL * 4]} stroke="black" strokeWidth="1" />

          {/* 橫的線 */}
          <Line x={0} y={0} points={[X, Y + Y_CELL, X + X_CELL * 4, Y + Y_CELL]} stroke="black" strokeWidth="1" />
          <Line x={0} y={0} points={[X, Y + Y_CELL * 3, X + X_CELL * 4, Y + Y_CELL * 3]} stroke="black" strokeWidth="1" />
          <Line x={0} y={0} points={[X, Y + Y_CELL * 2, X + X_CELL, Y + Y_CELL * 2]} stroke="black" strokeWidth="1" />
          <Line x={0} y={0} points={[X + X_CELL * 3, Y + Y_CELL * 2, X + X_CELL * 4, Y + Y_CELL * 2]} stroke="black" strokeWidth="1" />
          <ColoredRect />
          {showCenter(ziWei)}
          {showPalace12(ziWei)}

        </Layer>
      </Stage>
    </>)
  }
}