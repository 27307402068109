import React, { Component } from 'react'
import {

  Form, Grid, Input
} from 'semantic-ui-react';
import { Row, Col, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Button, } from 'reactstrap';
import { DelayInput } from 'react-delay-input';
import MaterialTable from 'material-table'
import Parse, { funcPad } from '../../widget/parse'
import localization from 'widget/MaterialTableOpt'
const columns = () => ([
  { width: 80, title: '會員編號', field: 'uid' },
  { width: 80, title: '姓名', field: 'name' },
  { width: 80, title: '手機', field: 'cellnum' },
  { width: 80, title: '地址', field: 'residentialAddr' },

]);
const fetchPeople = async (companyObj, params, searchText, employee) => {
  const { page = 1, pageSize = 5 } = params
  // console.log(params, searchText)

  let query = new Parse.Query('clients');

  if (searchText) {
    const p0 = new Parse.Query("clients");
    // p0.fullText("name", searchText);
    p0.startsWith("name", searchText);

    const p1 = new Parse.Query("clients");
    p1.startsWith("cellnum", searchText);

    const p2 = new Parse.Query("clients");
    p2.startsWith("peopleIdNumber", searchText);

    query = Parse.Query.or(p0, p1, p2);
  }
  // query.select(['uid', 'name', 'residentialAddr', 'cellnum', 'residentialPhone', 'sexualId']);
  query.equalTo("companyId", companyObj.objectId);

  const totalCount = await query.count();

  // if (params.sorter) {
  //   const s = params.sorter.split('_');

  //   if (s[1] === 'descend') {
  //     query.descending(s[0]);
  //   } else {
  //     query.ascending(s[0]);
  //   }

  //   // Sorts the results in descending order by the score field
  // }
  query.limit(pageSize);
  query.skip((Number(page)) * (pageSize));

  const snap = await query.find();
  const data = snap.map(data => data.toJSON());
  return { data, page, totalCount, }
}
class ModalAddMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      form: {
        memberType: '',
        name: '',
        telephone: '',
        cellnum: '',
      },
      rowData: {}
    }
    this.tableRef = React.createRef();
  }
  open = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId;

    this.setState({ open: true });
  }

  close = () => this.setState({ open: false })
  toggle = () => this.setState({ open: !this.state.open })

  handleChange1 = (evt, { name, value }) => {
    this.setState({
      form: { ...this.state.form, [name]: value }
    })
  }

  handleSubmit = async () => {
    // const { match, history, rowDataArr } = this.props
    // const { ordering1Id } = match.params;
    // const { rowData } = this.state
    // console.log(rowDataArr);

    // let obj = {
    //   name: rowData.name,
    //   telephone: rowData.telephone,
    //   uid: rowData.uid,
    //   ordering1Id,
    //   companyId: rowData.companyId
    // }
    // await Parse.saveData('orderingmember1', obj);
    // this.setState({ open: false }, history.push(`/admin/CrewOpentable/${ordering1Id}`))
  }

  handleChange = async (e, { name, value }) => {
    this.setState({ [name]: value }, () => this.tableRef.current.onQueryChange());
  }

  handleClickName = async (rowData) => {
    // await Parse.saveData('orderingmember1', rowData);
    const { rowDataArr, match, history } = this.props
    const { ordering1Id } = match.params;
    if (rowDataArr.length > 1) {
      alert('一次只能一個會員')
    } else {
      let obj = {
        ordering2Id: rowDataArr[0].objectId,
        name: rowData.name,
        telephone: rowData.telephone,
        uid: rowData.uid,
        ordering1Id,
        companyId: rowData.companyId
      }
      await Parse.saveData('ordering2', { ...rowDataArr[0], countMethod: `${rowData.name}(會員價)`, isMember: '1' });
      await Parse.saveData('orderingmember1', obj);
      this.setState({ open: false }, history.push(`/admin/CrewOpentable/${ordering1Id}`))
    }
    // this.setState({ rowData })
  }
  render() {
    const { companyObj } = this.props
    const { open, form, dataArr, searchValue, loading, clientObj, userAll, employee, rowData } = this.state
    // const { name, memberType, cellnum } = form;

    return (<>
      <Button style={{ backgroundColor: '#FF8F59', color: '#000', fontSize: '1.5rem' }} size='lg' onClick={this.open} >使用會員卡</Button>
      <Modal
        isOpen={open}
        size='lg'
        toggle={this.toggle}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggle}>
            <span aria-hidden="true">×</span>
          </button>
          新增會員
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="8" sm="10">
              <Form>
                <FormGroup widths='equal'>
                  <DelayInput
                    inputRef={ref => { this.ref = ref; }}
                    name='searchValue'
                    placeholder='請輸入客戶姓名、手機'
                    style={{ width: '100%', minWidth: 350, float: 'left' }}
                    onChange={(e) => this.handleChange(e, { name: 'searchValue', value: e.target.value })}
                    // minLength={1}
                    delayTimeout={300}
                    action={{ icon: 'cancel', onClick: this.clear }}
                    element={Input}
                  />
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <br />
          <MaterialTable
            // isLoading={loading}
            localization={localization()}
            tableRef={this.tableRef}
            columns={columns()}
            options={{
              pageSize: 5,
              tableLayout: 'fixed',
              search: false
            }}
            data={query => {
              this.setState({ loading: true });
              return new Promise((resolve, reject) => {
                fetchPeople(companyObj, query, searchValue, employee).then(result => {
                  this.setState({ loading: false }, () => resolve(result));
                })
              })
            }}
            onRowClick={(e, rowData) => this.handleClickName(rowData)}
            actions={[
              {
                icon: 'group', // view_headline
                tooltip: '顯示',
                onClick: (e, rowData) => this.handleClickName(rowData)
              }
            ]}
            title={`查詢結果`}
          />
          <br />
          {/* <Form.Input value={rowData.name} readOnly /> */}
        </ModalBody>
        {/* <ModalFooter style={{ padding: '16px' }}> */}
        {/* <Button onClick={this.close} >關閉</Button>
          <Button onClick={this.handleSubmit} >確定</Button> */}
        {/* </ModalFooter> */}
      </Modal>
    </>)
  }
}
export default ModalAddMember;
