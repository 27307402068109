import React, { Component } from 'react'
import { Container, Dimmer, Loader, Image, Segment, Select } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Moment from 'moment';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';


const columns = () => ([
  { title: 'Portfolio', field: 'uid', editable: 'never' },
  // { title: 'Date', field: 'dueDate', editable: 'never' },
  dateCol({ title: 'Date', field: 'dueDate', editable: 'never' }),
  {
    title: 'Service Item	', field: 'as_template_text', editable: 'never',
    render: rowData => rowData.as_template_text || rowData.serviceText
  },
  { title: 'Status', field: 'status_text', editable: 'never' },
  { title: 'Description', field: 'description', editable: 'never' },
  { title: 'Action', field: 'as_action', editable: 'never' },
  { title: 'Action Owner', field: 'as_action_owner', editable: 'never' },
]);

export default class List5Client extends Component {
  state = {
    dataArr: [],
    deleteConfirm: false,
    loading: true,
    mgyearSel: '',
    mgyearArr: [],
    monthSel: Moment(new Date()).format('YYYY-MM') || '',
    obj: {},
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ loading: true })
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props, type = 1) => {
    const { doc, match, companyObj } = props;
    const { clientId, portfolioId = '' } = match.params;
    const companyId = companyObj.objectId;

    const afterservice = await Parse.queryData('afterservice', { companyId, status: '1' });
    const portfolios = await Parse.queryData('portfolios', { companyId, client_id: clientId });
    const afterArr = []
    portfolios.forEach(item => {
      afterservice.forEach(item2 => {
        if (item.objectId === item2.portfolio_id) {
          afterArr.push(item2)
        }
      })
    })
    console.log(afterArr)
    this.setState({ afterArr, loading: false })
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    this.setState({ [name]: value, loading: true }, () => this.handleFetch(this.props, 0));
  }

  render() {
    const { afterArr, loading } = this.state;
    return (<Container>
      {/* 資金需求預估表 */}
      <br />
      {/* <Select style={{
          position: 'relative', zIndex: 99,
          float: 'left',
          minWidth: '200px',
          // borderTopLeftRadius: '0',
          // borderBottomLeftRadius: '0'
        }}
          compact
          name="mgyearSel"
          options={mgyearArr}
          value={mgyearSel}
          onChange={this.handleChange}
        /> */}
      <br />
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '客服跟進事項'),
          pageSize: (afterArr && afterArr.length) || 10,
          search: false
        }}
        // data2={bankaccSel}
        data={afterArr}
        title={`客服跟進事項`}
      />
      <br />
      <br />
    </Container>)
  }
}
