import React, { Component } from 'react'
import { Container, Grid, Header, Form, Segment, Image, Select } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
// import Moment from 'moment';
import {
  Row,
  Col
} from "reactstrap";
// import RowsDnd from './RowsDnd'
import { dateCol, mgyearOpt, monthOpt, exportPdf } from 'views/web/CustomMaterialTable';

const columns = () => ([
  dateCol({ width: 80, title: '收款日期', field: 'paymentDate', type: 'date' }),
  { width: 80, title: '姓名', field: 'employeeName' },
  { width: 80, title: '入資金', field: 'fund' },
  { width: 80, title: '退資金', field: 'refund' },
  { width: 80, title: '業績', field: 'performance' },
  { width: 80, title: '佣金', field: 'commission', editable: 'never' },
  { width: 80, title: '結餘', field: 'balance', editable: 'never' },
  { width: 80, title: '小計', field: 'total', editable: 'never' },
  { width: 80, title: '資保息', field: 'capitalGuaranteed', editable: 'never' },
]);

const d = new Date()
class Margin extends Component {
  state = {
    margining1Id: '',
    data: [],
    dataArr: [],
    newData1: {},
    newData2: {},
    loading: false, //true,
    loading2: false,
    rowData: {},
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
  }
  componentDidMount = () => {
    this.handleFetch()
  }
  handleFetch = async () => {
    const { companyObj } = this.props
    const { monthSel } = this.state
    const companyId = companyObj.objectId
    const data1 = await Parse.queryData('margin', { companyId });
    const data2 = []
    data1.forEach(item => {
      const d1 = new Date(item.paymentDate.iso)
      const month = String(d1.getMonth() + 1)
      if (month === monthSel) {
        data2.push({ ...item })
      }
    })
    this.setState({ dataArr: data1, data: data2 })
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value }, () => this.hanldeFilter())
  }

  hanldeFilter = () => {
    const { dataArr, monthSel } = this.state
    const data1 = []
    dataArr.forEach(item => {
      const d1 = new Date(item.paymentDate.iso)
      const month = String(d1.getMonth() + 1)
      if (month === monthSel) {
        data1.push({ ...item })
      }
    })
    this.setState({ data: data1 })
  }
  // handleData = async (rowData) => {
  //   // console.log(rowData);
  //   const margining1Id = rowData.objectId
  //   // console.log(rowData);
  //   this.setState({ margining1Id: '', rowData, loading2: true });
  //   if (margining1Id) {
  //     const data2 = await Parse.queryData('margining2');
  //     this.setState({ data2: data2.filter(item => item.margining1Id === margining1Id), margining1Id, loading2: false });
  //     // console.log(data2)
  //   }
  // }

  render() {
    // const { firebase = {}, companyObj = {} } = this.props
    const { open, data, data2, margining1Id, loading, loading2, rowData, mgyearSel, monthSel } = this.state

    return (
      <>
        <div className="content">
          <Row style={{ marginBottom: '20px' }}>
            <Col><legend>2-4 保證金一覽</legend></Col>
          </Row>
          <Select name="mgyearSel" style={{ position: 'relative', zIndex: 99, marginRight: '8px' }} options={mgyearOpt} value={mgyearSel} onChange={this.handleChange} />
          <Select name="monthSel" style={{ position: 'relative', zIndex: 99 }} options={monthOpt} value={monthSel} onChange={this.handleChange} />
          <br />
          <br />
          <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns()} options={{
              addRowPosition: "first",
              exportButton: true,
              exportPdf: (columns, data) => exportPdf(columns, data, '查詢結果'),
              pageSize: 5,
              search: false
            }}
            // onColumnDragged={}
            // components={{
            // Toolbar: props => (
            //   <div>
            //     <div style={{ width: '110px', float: 'right', textAlign: 'right', padding: '15px' }}>
            //       <RowsDnd {...this.props} orderField='order_id' columns={columns()} dataArr={data1} handleReorder={this.handleReorder} />
            //     </div>
            //     <MTableToolbar {...props} />
            //     <div style={{ clear: 'both' }}></div>
            //   </div>
            // ),
            // }}
            data={data}
            title="查詢結果"
          // actions={[
          //   {
          //     icon: 'view_headline',
          //     tooltip: '查看',
          //     onClick: (event, rowData) => this.handleData(rowData)
          //   }
          // ]}
          // onRowClick={(event, rowData) => this.handleData(rowData)}
          // editable={{
          //   onRowAdd: newData1 =>
          //     new Promise(async (resolve, reject) => {
          //       const data = [...data1];
          //       const db = Parse.Object.extend("margining1");
          //       const newDoc = new db();
          //       const doc = await newDoc.save();
          //       newData1.objectId = doc.id;
          //       newData1.id = doc.id;
          //       newData1._id = doc.id;

          //       data.push(newData1);
          //       this.setState({ data1: data, newData1 }, () => resolve());
          //     }).then(() => this.handleAdd("1")),
          //   onRowUpdate: (newData1, oldData1) =>
          //     new Promise((resolve, reject) => {
          //       const data = [...data1];
          //       const index = data.indexOf(oldData1);
          //       if (newData1.now_use === "-1") {
          //         data1.forEach((item, i) => data[i].now_use = '0');
          //       }
          //       data[index] = newData1;
          //       this.setState({ data1: data, newData1, oldData1 }, () => resolve());
          //     }).then(() => this.handleUpdate("1")),
          //   onRowDelete: oldData1 =>
          //     new Promise((resolve, reject) => {
          //       let data = [...data1];
          //       const index = data.indexOf(oldData1);
          //       data.splice(index, 1);
          //       this.setState({ data1: data, oldData1 }, () => resolve());
          //     }).then(() => this.handleDelete("1")),
          // }}
          />
          <br />
          <br />
          <br />
        </div>
      </>
    );
  }
}

export default Margin;
