import React, { Component } from 'react';
import { Icon, Loader, Button } from 'semantic-ui-react';
// import { _calculateAge } from './define/date'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { IconButton } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';

import { getPdfsrcAsync, PdfIframe, PdfPanel, PdfButton } from './loadCore';
import { fetchGz2Txt } from '../http2';
import jsPDF from './JspdfMyFonts';

export default class ModalPdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfsrc: null,
      code: '',
      showPdf: false,
      doc: '',
      fontLoaded: false,
      loadingPdf: false,
    };
  }

  componentDidMount() {
    // const hostname = window.location.hostname;
    const path1 = window.location.pathname.split('/')[1];
    if (path1 === 'admin') {
      fetchGz2Txt('https://hanbury.s3-ap-northeast-1.amazonaws.com/mai8/biaukai.txt.gz').then(res => {
        jsPDF.API.events.push(['addFonts', function () {
          this.addFileToVFS('BiauKai-normal.ttf', res);
          this.addFont('BiauKai-normal.ttf', 'BiauKai', 'normal');
          // console.log('BiauKai-normal ok')
        }])
        // console.log('BiauKai-normal pushed')
        window.jsPDF.loaded.BiauKai = 1;
      })
      fetchGz2Txt('https://hanbury.s3-ap-northeast-1.amazonaws.com/mai8/msjh.txt.gz').then(res => {
        jsPDF.API.events.push(['addFonts', function () {
          this.addFileToVFS('msjh-normal.ttf', res);
          this.addFont('msjh-normal.ttf', 'msjh', 'normal');
          // console.log('msjh-normal ok')
        }])
        window.jsPDF.loaded.msjh = 1;
        // console.log('msjh pushed')
      })
    }

    this.checkFontLoaded()
  }

  checkFontLoaded = () => {
    if (window.jsPDF.loaded && window.jsPDF.loaded.msjh && window.jsPDF.loaded.BiauKai) {
      // console.log('got fonts')
      this.setState({ fontLoaded: true });
    } else {
      // console.log('waiting to get fonts')
      setTimeout(
        () => {
          this.checkFontLoaded()
        },
        1000
      );
    }
  }

  handleClosePdf = () => {
    this.setState({ showPdf: false, pdfsrc: null, doc: '' });
  }

  handleShowPdf = () => {
    const { arrObj = [], type, companyObj } = this.props;
    // const companyId = companyObj.objectId
    let arrObjSort = [...arrObj];
    if (type === '1') {
      arrObjSort = arrObj.map(item => ({ ...item }))
        .sort((a, b) => ('' + a.addr).localeCompare(b.addr))
        .sort((a, b) => ('' + a.passname4).localeCompare(b.passname4))
        .sort((a, b) => ('' + a.passname3).localeCompare(b.passname3))
        .sort((a, b) => ('' + a.passname2).localeCompare(b.passname2))
        .sort((a, b) => ('' + a.passname1).localeCompare(b.passname1))
        .sort((a, b) => ('' + a.passname).localeCompare(b.passname))
        .sort((a, b) => ('' + a.praytypeId).localeCompare(b.praytypeId))
        .sort((a, b) => a.pray2OrderId - b.pray2OrderId)
        .sort((a, b) => ('' + a.serial).localeCompare('' + b.serial))

      // console.log(arrObjSort.map(({ serial }) => serial))
    } else if (type === '2') {
      arrObjSort = arrObj.sort((a, b) => a.p1Num - b.p1Num).sort((a, b) => a.p3Num - b.p3Num).sort((a, b) => a.p5Num - b.p5Num).sort((a, b) => a.p7Num - b.p7Num)
    } else if (type === '3') {
      arrObjSort = arrObj.sort((a, b) => a.templeClass - b.templeClass).sort((a, b) => a.templeLevel - b.templeLevel)
    }
    // const codeForm = { code: `doc.text(15, 15, '尚未輸入程式碼');`, format: "a4", orientation: "p" }; // 真正要印的東西
    getPdfsrcAsync({ ...this.props, arrObj: arrObjSort })
      .then(({ pdfsrc, doc }) => {
        if (pdfsrc) {
          this.setState({ pdfsrc, doc, loadingPdf: false });
        } else {
          this.setState({ showPdf: false, loadingPdf: false });
        }
      })
  }

  render() {
    const { title = '產生PDF', preview = '0' } = this.props; // PDF印刷
    const { showPdf, pdfsrc, doc, fontLoaded, loadingPdf } = this.state;

    // console.log('fontLoaded', fontLoaded)
    // console.log('showPdf', showPdf)
    return (<>
      {preview === '1' ? <IconButton><PrintIcon fontSize='small' /></IconButton>
        : <Button
          disabled={!fontLoaded}
          loading={!fontLoaded || loadingPdf}
          // size='small' 
          // block
          color='primary'
          // icon='print'
          // className="btn-round mb-3"
          onClick={() => {
            this.setState({ showPdf: true, pdfsrc: null, loadingPdf: true }, () => this.handleShowPdf());
            // setTimeout(() => {  }, 10);
          }}
        // content={title}
        ><Icon disabled name='print' /> {title}</Button>}
      <Modal
        isOpen={showPdf}
        toggle={this.handleClosePdf}
        size="lg"
        className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClosePdf}>
            <span aria-hidden="true">×</span>
          </button>
          <span style={{ float: 'left', padding: '6px' }}>{title} </span>
          <PdfButton doc={doc} />
        </ModalHeader>
        <ModalBody style={{ minHeight: '400px' }}>
          <PdfIframe pdfsrc={pdfsrc} height={'70vh'} doc={doc} />
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button icon='checkmark' content='關閉' onClick={this.handleClosePdf} />
        </ModalFooter>
      </Modal>
    </>)
  }
}