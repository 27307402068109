import React, { Component } from 'react'
import { Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Header, Loader } from 'semantic-ui-react'
import MaterialTable, { MTableToolbar } from 'material-table'
// import ModalUploadFiles from 'views/web/ModalUploadFiles'
// import { RowImage } from 'views/web/RowImage';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf, rowReOrder } from 'views/web/CustomMaterialTable';
import ModalUpdateFile from 'views/web/ModalUpdateFile';
import { RowImage } from '../web/RowImage';

const columns = () => ([
  { title: '順序', field: 'order' },
  // {
  //   width: 50, title: '', field: 'File',
  //   editComponent: props => {
  //     return <><ModalUploadFiles {...pageProps} isImage='0' handleData={handleFetch} dbName='fileCenter2' fieldName='file1' condition={{ fileCenter1Id: props.rowData.objectId, fileCenter1Text: props.rowData.name_cht || props.rowData.value, type: 'product' }} /></>
  //   }
  // },
  {
    width: 50, title: '', field: 'img0sel', filtering: false, headerStyle: { textAlign: 'center' }, render: rowData => <></>,
    editComponent: props => <ModalUpdateFile isUploadOri={true} fieldname='img0File' rowData={props.rowData} onRowDataChange={props.onRowDataChange} />
  },
  { width: 120, title: '照片', field: 'img0File', filtering: false, render: rowData => <RowImage rowData={rowData} field='img0File' />, editComponent: props => <RowImage rowData={props.rowData} field='img0File' /> },
  { title: '項目', field: 'value' },
  { title: '說明', field: 'value2' },
  // {
  //   title: '檔案', field: '',
  //   render: rowData => {
  //     let showArr = []
  //     if (rowData.attachments !== undefined && rowData.attachments.length > 0) {
  //       rowData.attachments.forEach(item => {
  //         showArr.push(<a href={item.path} target="_blank" rel="noopener noreferrer">{item.filename}</a>)
  //         showArr.push(<br />)
  //       })
  //     }
  //     return showArr
  //   },
  //   editComponent: props => {
  //     let rowData = props.rowData
  //     let showArr = []
  //     if (rowData.attachments !== undefined && rowData.attachments.length > 0) {
  //       rowData.attachments.forEach(item => {
  //         showArr.push(<a href={item.path} target="_blank" rel="noopener noreferrer">{item.filename}</a>)
  //         showArr.push(<br />)
  //       })
  //     }
  //     return showArr
  //   }
  // },
]);
const columns2 = () => ([
  { width: 80, title: '順序', field: 'order' },
  {
    width: 50, title: '', field: 'img0sel', filtering: false, headerStyle: { textAlign: 'center' }, render: rowData => <></>,
    editComponent: props => <ModalUpdateFile isUploadOri={true} fieldname='img0File' rowData={props.rowData} onRowDataChange={props.onRowDataChange} />
  },
  { width: 120, title: '照片', field: 'img0File', filtering: false, render: rowData => <RowImage rowData={rowData} field='img0File' />, editComponent: props => <RowImage rowData={props.rowData} field='img0File' /> },
  { width: 80, title: '冷熱飲', field: 'coldHot', lookup: { '0': '冷飲', '1': '熱飲', '2': '都有' } },
  { width: 80, title: '商品', field: 'commodity' },
  { width: 80, title: '價格', field: 'price' },
  { title: '說明', field: 'value2' },
])
// const columns22 = () => ([
//   { title: '順序', field: 'order' },
//   // {
//   //   width: 50, title: '', field: 'File',
//   //   editComponent: props => {
//   //     // return <><ModalUploadFiles {...pageProps} isImage='0' handleData={handleFetch} dbName='fileCenter2' fieldName='file1' condition={{ fileCenter1Id: props.rowData.objectId }} /></>
//   //     return <><Button color='primary' onClick={e => handleOpen(props.onRowDataChange,props.rowData)}><i className="fa fa-plus" />新增檔案</Button></>
//   //   }
//   // },
//   { title: '項目(中文)', field: 'name_cht' },
//   { title: '項目(英文)', field: 'name_eng' },
//   {
//     title: '檔案', field: '',
//     render: rowData => {
//       let showArr = []
//       if (rowData.attachments !== undefined && rowData.attachments.length > 0) {
//         rowData.attachments.forEach(item => {
//           showArr.push(<a href={item.path} target="_blank" rel="noopener noreferrer">{item.filename}</a>)
//           showArr.push(<br />)
//         })
//       }
//       return showArr
//     },
//     editComponent: props => {
//       let rowData = props.rowData
//       let showArr = []
//       if (rowData.attachments !== undefined && rowData.attachments.length > 0) {
//         rowData.attachments.forEach(item => {
//           showArr.push(<a href={item.path} target="_blank" rel="noopener noreferrer">{item.filename}</a>)
//           showArr.push(<br />)
//         })
//       }
//       return showArr
//     }
//   },
// ]);

// const columns3 = () => ([
//   {
//     title: '預覽', field: 'file1',
//     render: rowData => <><RowImage rowData={rowData} field={'file1'} /></>,
//     editComponent: props => <><RowImage rowData={props.rowData} field={'file1'} /></>,
//   },
//   { title: '名稱', field: 'name' },
// ])

export default class ManageFood extends Component {
  state = {
    loginUser: this.props.user.profile.toJSON(),
    meals1: [],
    meals2: [],
    meals3: [],
    product4: [],
    data1: [],
    data2: [],
    data3: [],
    data4: [],
    sel1: '',
    sel2: '',
    sel3: '',
    sel4: '',
    title1: '',
    title2: '',
    title3: '',
    title4: '',
    // loading: true,
    show: false,
  }

  async componentDidMount() {
    const { loginUser } = this.state
    const { companyObj } = this.props
    const companyId = companyObj.objectId

    const arr1 = await Parse.queryData('meals1', { companyId });
    const arr2 = await Parse.queryData('meals2', { companyId });
    const meals1 = arr1.sort((a, b) => ('' + a.order).localeCompare(b.order));
    const meals2 = arr2.sort((a, b) => ('' + a.order).localeCompare(b.order));
    this.setState({ meals1, meals2 }, () => this.handleFetch())
  }
  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps)
  }

  handleFetch = async (props = this.props) => {
    const { meals1, meals2, meals3, rowData } = this.state
    const { companyObj } = props;
    const companyId = companyObj.objectId;

    this.setState({ loading: true })
    // const data1 = product1.map(item => {
    //   const arr = fileCenter2.filter(item2 => item2.fileCenter1Id === item.objectId)
    //   const attachments = arr.map(item2 => ({ id: item2.objectId, path: item2.file1.url, filename: item2.name }))
    //   const obj = {
    //     ...item,
    //     attachments,
    //   }
    //   return obj
    // })
    const data1 = meals1.map(item => {
      const obj = {
        ...item,
      }
      return obj
    })
    const data2 = meals2.map(item => {
      const obj = {
        ...item,
      }
      return obj
    })
    this.setState({
      data1: meals1, meals2: data2, sel1: '',
      data2, loading: false
    });
  }

  handleAdd = async (id) => {
    const { newData1, newData2 } = this.state;

    if (id === "1") {
      await Parse.saveData('meals1', newData1);
    } else if (id === "2") {
      await Parse.saveData('meals2', newData2);
    }
    this.setState({ loading: false })
  }

  handleUpdate = async (id) => {
    const { newData1, newData2 } = this.state

    if (id === "1") {
      await Parse.saveData('meals1', newData1);
    } else if (id === "2") {
      await Parse.saveData('meals2', newData2);
    }
    this.setState({ loading: false })
  }

  handleDelete = async (id) => {
    const { oldData1, oldData2 } = this.state
    const promises = []
    if (id === "1") {
      await Parse.deleteData('meals1', oldData1);
      this.setState({ oldData1: {} })
    } else if (id === "2") {
      if (oldData2 && oldData2.attachments) {
        oldData2.attachments.forEach(item => promises.push(Parse.deleteData('fileCenter2', item)))
      }
      await Parse.deleteData('meals2', oldData2);
      this.setState({ oldData2: {} })
    }
    await Promise.all(promises).catch(error => console.log("error", error))
    this.setState({ loading: false })
  }

  handleData = (id, rowData) => {
    const { meals2, meals3 } = this.state;
    const sel = rowData.objectId;
    if (id === "1") {
      this.setState({ sel1: sel, sel2: '', sel3: '', title1: rowData.value, data2: meals2.filter(item => item.meals1Id === sel).sort((a, b) => a.order - b.order) })
    } else if (id === "2") {
      this.setState({ sel2: sel, sel3: '', title2: rowData.name_cht, data3: meals3.filter(item => item.product2_id === sel) })
    }
  }


  handleClose = () => {
    this.setState({ show: false })
  }

  handleState = (dataArr, arrName = 'dataArr') => {
    this.setState({ [arrName]: dataArr })
  }

  editable1 = {
    onRowAdd: newData1 =>
      new Promise(async (resolve, reject) => {
        // const db = firebase.firestore();
        // const docRef = db.collection('meals1').doc();
        const data = [...this.state.data1];
        const db = Parse.Object.extend("meals1");
        const newDoc = new db();
        const doc = await newDoc.save();

        const obj = {
          _id: doc.id,
          id: doc.id,
          objectId: doc.id,
          companyId: this.props.companyObj.objectId || '',
          companyName: this.props.companyObj.name || '',
          ...newData1
        }

        data.push(obj);

        this.setState({ data1: data, newData1: obj, loading: true }, () => resolve());
      }).then(() => this.handleAdd("1")),
    onRowUpdate: (newData1, oldData1) =>
      new Promise((resolve, reject) => {
        const data = [...this.state.data1];
        const index = data.indexOf(oldData1);
        data[index] = newData1;
        // console.log(data1)
        this.setState({ data1: data, newData1, oldData1, loading: true }, () => resolve());
      }).then(() => this.handleUpdate("1")),
    onRowDelete: oldData1 =>
      new Promise((resolve, reject) => {
        let data = [...this.state.data1];
        const index = data.indexOf(oldData1);
        data.splice(index, 1);
        this.setState({ data1: data, oldData1, loading: true }, () => resolve());
      }).then(() => this.handleDelete("1")),
  }

  editable2 = {
    onRowAdd: newData2 =>
      new Promise(async (resolve, reject) => {
        const data = [...this.state.data2];
        const db = Parse.Object.extend("meals2");
        const newDoc = new db();
        const doc = await newDoc.save();

        const obj = {
          _id: doc.id,
          id: doc.id,
          objectId: doc.id,
          companyId: this.props.companyObj.objectId || '',
          companyName: this.props.companyObj.name || '',
          meals1Id: this.state.sel1,
          meals1Text: (this.state.meals1.find(item => item.objectId === this.state.sel1) && this.state.meals1.find(item => item.objectId === this.state.sel1).value) || '',
          ...newData2
        }
        data.push(obj);
        this.setState({ data2: data, newData2: obj, loading: true }, () => resolve());
      }).then(() => this.handleAdd("2")),
    onRowUpdate: (newData2, oldData2) =>
      new Promise((resolve, reject) => {
        const data = [...this.state.data2];
        const index = data.indexOf(oldData2);
        data[index] = newData2;
        // console.log(data1)
        this.setState({ data2: data, newData2, oldData2, loading: true }, () => resolve());
      }).then(() => this.handleUpdate("2")),
    onRowDelete: oldData2 =>
      new Promise((resolve, reject) => {
        let data = [...this.state.data2];
        const index = data.indexOf(oldData2);
        data.splice(index, 1);
        this.setState({ data2: data, oldData2, loading: true }, () => resolve());
      }).then(() => this.handleDelete("2")),
  }

  render() {
    const { companyObj } = this.props;
    const { meals1, meals2, meals3, product4, loading, data1, data2, data3, data4, title1, title2, title3, sel1, sel2, sel3, show, fileCenter2, fileData, rowData, auth = {} } = this.state;

    return (<div className='content'>
      <Header as='h2' inverted={companyObj.inverted}>餐點管理</Header>
      <Row>
        <Col md={{ size: 6, }}>
          <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns()}
            options={{
              addRowPosition: "first",
              exportButton: true,
              pageSize: data1.length || 5,
              search: false
            }}
            // onColumnDragged={}
            data={data1}
            title="種類"
            actions={[
              {
                icon: 'view_headline',
                tooltip: '查看',
                onClick: (event, rowData) => this.handleData("1", rowData)
              }
            ]}
            components={{
              Row: props => rowReOrder(props, 'meals1', 'order', data1, 'data1', this.handleState)
            }}
            onRowClick={(event, rowData) => this.handleData("1", rowData)}
            editable={this.editable1}
          />
        </Col>
        <Col sm="6">
          {sel1 ? <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns2()} options={{
              addRowPosition: "first",
              exportButton: true,
              pageSize: data2.length || 5,
              search: false
            }}
            data={data2}
            title={title1 + " 分類"}
            actions={[
              {
                icon: 'attach_file',
                tooltip: '檔案',
                hidden: !auth.edit,
                onClick: (event, rowData) => this.setState({ show: true, rowData, fileData: fileCenter2.filter(item => item.fileCenter1Id === rowData.objectId) })
              },
              // {
              //   icon: 'view_headline',
              //   tooltip: '查看',
              //   onClick: (event, rowData) => this.handleData("2", rowData)
              // }
            ]}
            components={{
              Row: props => rowReOrder(props, 'meals2', 'order', data2, 'data2', this.handleState)
            }}
            onRowClick={(event, rowData) => this.handleData("2", rowData)}
            editable={this.editable2}
          /> : null}
        </Col>
        {/* <Col sm="6">
          {sel2 ? <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns2(this.handleFetch, this.props)} options={{
              addRowPosition: "first",
              exportButton: true,
              pageSize: data3.length || 5,
              search: false
            }}
            data={data3}
            title={title2 + "餐點 "}
            actions={[
              rowData => ({
                icon: 'attach_file',
                tooltip: '檔案',
                hidden: !auth.edit,
                onClick: (event, rowData) => this.setState({ show: true, rowData })
              }),
              {
                icon: 'view_headline',
                tooltip: '查看',
                onClick: (event, rowData) => this.handleData("3", rowData)
              }
            ]}
            components={{
              Row: props => rowReOrder(props, 'meals3', 'order', data3, 'data3', this.handleState)
            }}
            onRowClick={(event, rowData) => this.handleData("3", rowData)}
            editable={this.editable3}
          /> : null}
        </Col> */}
      </Row>
      <br />
      <br />
    </div>)
  }
}