
export const selType = [
  // { key: '0', text: '問合', value: '0' },
  { key: '1', text: '募集', value: '1' },
  { key: '2', text: '在籍', value: '2' },
  { key: '3', text: '卒業', value: '3' },
]
export const statusArr = [
  // { key: '0', text: '問合', value: '0' },
  { key: '1', text: '募集', value: '10' },
  { key: '2', text: '在籍', value: '30' },
]
export const semesterArr = [
  { key: '2021,A', text: '2021A', value: '2021,A' },
  { key: '2021,S', text: '2021S', value: '2021,S' },
  { key: '2020,A', text: '2020A', value: '2020,A' },
  { key: '2020,S', text: '2020S', value: '2020,S' },
  { key: '2019,A', text: '2019A', value: '2019,A' },
  { key: '2019,S', text: '2019S', value: '2019,S' },
  { key: '2018,A', text: '2018A', value: '2018,A' },
  { key: '2018,S', text: '2018S', value: '2018,S' },
  { key: '2017,A', text: '2017A', value: '2017,A' },
  { key: '2017,S', text: '2017S', value: '2017,S' },
]