
import React, { Component } from 'react'
// import localization from 'widget/MaterialTableOpt'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'semantic-ui-react';
import {
  // Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  // CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  // CardImg
} from "reactstrap";

import Parse from 'widget/parse'
import avatar from "assets/img/user.png";
import PreloadFile, { previewUrl } from 'views/web/PreloadFile';
import { RowImage } from 'views/web/RowImage';

import { fieldCol } from "views/formDef/function";
import { user } from "views/formDef/object";

let now = new Date().getTime()

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: props.user.profile.toJSON() || {},
      show: false,
      upfile: {},
      inputType: '',
      rowData: {},
      changeConfirm: true,
      loadingData: false,
    };
  }

  async componentDidMount() {
    // let onbroad = new Date(this.state.profile.onbroad_date).getTime()
    // let seniorityYear = parseInt((now - onbroad) / 1000 / 60 / 60 / 24 / 365)
    // let seniorityMonth = parseInt(((now - onbroad) - (seniorityYear * 86400000 * 365)) / 1000 / 60 / 60 / 24 / 30)
    // if (seniorityMonth < 0) {
    //   seniorityMonth = 0
    // }
    // const profile = {
    //   ...this.state.profile,
    //   jobyear: `${seniorityYear}年${seniorityMonth}月`
    // }
    // this.setState({ profile })
  }

  async componentWillReceiveProps(nextProps) {
    const obj = nextProps.user.profile.toJSON() || {}
    let onbroad = new Date(obj.onbroad_date).getTime()
    let seniorityYear = parseInt((now - onbroad) / 1000 / 60 / 60 / 24 / 365)
    let seniorityMonth = parseInt(((now - onbroad) - (seniorityYear * 86400000 * 365)) / 1000 / 60 / 60 / 24 / 30)
    if (seniorityMonth < 0) {
      seniorityMonth = 0
    }
    const profile = {
      ...this.state.profile,
      jobyear: `${seniorityYear}年${seniorityMonth}月`
    }
    this.setState({ profile })
    // this.setState({ profile:  })
  }

  handleChange = (e, data) => {
    const { profile } = this.state
    if (e.target) {
      this.setState({ profile: { ...profile, [e.target.name]: e.target.value } })
    } else {
      if (typeof profile[data.name] === 'object') {
        this.setState({ profile: { ...profile, [data.name]: e } })
      } else {
        this.setState({ profile: { ...profile, [data.name]: e.value } })
      }
    }
  }

  open = (e, data) => {
    e.preventDefault();
    this.setState({ show: true, rowData: { ...this.state.profile }, inputType: [e.target.id] })
  }

  close = () => { this.setState({ show: false }) }

  handleSubmit = async () => {
    const { file, rowData, inputType, } = this.state
    const res = await Parse.putFile(file, inputType).catch((error) => {
      console.error(error.code + " " + error.message);
    }); // 單純存檔
    this.setState({ profile: { ...rowData, [inputType]: res }, show: false, loadingData: false, changeConfirm: true });
  }

  handleChangeFile = async (file) => {
    this.setState({ file, changeConfirm: false })
  }

  handleSave = async () => {
    const { profile } = this.state;
    if (window.confirm('確認儲存?')) {
      await Parse.saveData('User', profile);
      window.alert('儲存成功')
    }
  }

  lineTest = () => {
    const { profile } = this.state

    const client_id = '1654004417';
    const redirect_uri = 'https://parse.infowin.com.tw/login/callback';
    const userId = profile.objectId

    let link = 'https://access.line.me/oauth2/v2.1/authorize?';
    link += 'response_type=code';
    link += '&client_id=' + client_id;
    link += '&redirect_uri=' + redirect_uri;
    link += '&state=' + userId;
    link += '&scope=openid%20profile%20email';
    // window.location.href = link;

    const win = window.open(link, 'windowName'
      // ,"width=200,height=200,scrollbars=no"
    );
    const timer = setInterval(() => {
      if (win.closed) {
        clearInterval(timer);
        // alert('closed');
        window.location.reload();
      }
    }, 1000);
  }

  render() {
    const { companyObj = {}, } = this.props
    const { profile, rowData, inputType, changeConfirm, loadingData } = this.state;

    let picsrc1 = profile && profile.coverPic && (profile.coverPic._url || profile.coverPic.url)
    let picsrc2 = profile && profile.profilePic && (profile.profilePic._url || profile.profilePic.url)



    return (<>
      <div className="content">
        <Row>
          <Col md="4">
            <Card className="card-user">
              <div className="image">
                <a href="#pablo" onClick={this.open}>
                  <img
                    alt="封面圖片"
                    id="coverPic"
                    // src={require("assets/img/bg/damir-bosnjak.jpg")}
                    src={picsrc1 ? String(picsrc1) : require('assets/img/bg/damir-bosnjak.jpg')}
                  />
                </a>
                {/* <Image src={'https://react.semantic-ui.com/images/wireframe/square-image.png'} size='tiny' /> */}
              </div>
              <CardBody>
                <div className="author">
                  <a href="#pablo" onClick={this.open} >
                    <img
                      alt="個人圖片"
                      id='profilePic'
                      className="avatar border-gray"
                      src={picsrc2 ? String(picsrc2) : avatar}
                    />
                    {/* <CardImg top width="100%" src="/assets/318x180.svg" alt="Card image cap" /> */}
                    {/* <Image src={'https://react.semantic-ui.com/images/wireframe/square-image.png'} size='tiny' /> */}
                  </a>
                  <h5 className="title">{profile.name}</h5>
                  <p className="description">@{profile.username}</p>
                </div>
                <p className="description text-center">
                  {profile.ps}
                </p>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="button-container">
                  <Row>
                    <Col className="ml-auto" lg="3" md="6" xs="6">
                      <h5>
                        12 <br />
                        <small>Files</small>
                      </h5>
                    </Col>
                    <Col className="ml-auto mr-auto" lg="4" md="6" xs="6">
                      <h5>
                        2GB <br />
                        <small>Used</small>
                      </h5>
                    </Col>
                    <Col className="mr-auto" lg="3">
                      <h5>
                        24,6$ <br />
                        <small>Spent</small>
                      </h5>
                    </Col>
                  </Row>
                </div>
              </CardFooter>
            </Card>
            <Card>
              <CardBody>
                <br />
                <label style={{ fontSize: '15px' }}>Line</label>
                <br />
                <br />
                {profile && profile.lineId ? <>
                  {/* lineEmail: <br /> */}
                  lineDisplayName: {profile.lineDisplayName}<br />
                  lineId: {profile.lineId}<br />
                  linePictureUrl: <img src={profile.linePictureUrl} /><br />
                  lineStatusMessage: {profile.lineStatusMessage}<br />
                </> : <>
                    <label style={{ fontSize: '13px' }}>用於登錄，並可使系統直接傳送各項必要資訊給您。</label>
                    <Button basic color='orange' onClick={() => this.lineTest()}>Line 登入</Button>
                  </>}
              </CardBody>
            </Card>
          </Col>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">個人檔案</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    {fieldCol(user.companyName, "pr-1", "6", companyObj.name, this.handleChange, true)}
                    {fieldCol(user.email, "pl-1", "6", profile.email, this.handleChange, true)}
                  </Row>
                  <Row>
                    {fieldCol(user.name, "pr-1", "6", profile.name, this.handleChange)}
                    {fieldCol(user.name_eng, "pl-1", "6", profile.name_eng, this.handleChange)}
                  </Row>
                  <Row>
                    {fieldCol(user.tw_id, "pr-1", "6", profile.tw_id, this.handleChange)}
                    {fieldCol(user.birthday_date, "pl-1", "6", profile.birthday_date, this.handleChange)}
                  </Row>
                  <Row>
                    {fieldCol(user.phone, "pr-1", "6", profile.phone, this.handleChange)}
                    {fieldCol(user.is_auth, "pl-1", "6", profile.is_auth, this.handleChange)}
                  </Row>
                  <Row>
                    {fieldCol(user.jobNum, "pr-1", "6", profile.jobNum, this.handleChange, true)}
                    {fieldCol(user.jobTitle, "pl-1", "6", profile.jobTitle, this.handleChange, true)}
                  </Row>
                  <Row>
                    {fieldCol(user.onbroad_date, "pr-1", "6", profile.onbroad_date, this.handleChange, true)}
                    {fieldCol(user.leave_date, "pl-1", "6", profile.leave_date, this.handleChange, true)}
                  </Row>
                  <Row>
                    {fieldCol(user.jobyear, "pr-1", "6", profile.jobyear, this.handleChange, true)}
                    {/* <Col className={'pr-1'} md={6}>
                      <FormGroup>
                        <label htmlFor="fieldGroup">年資</label>
                        <Input
                          id={'fieldFormGroup' + '_' + 'jobyear'}
                          name={'jobyear'}
                          // placeholder={placeholder || label}
                          type='text'
                          value={profile.jobyear}
                          disabled={true}
                        />
                      </FormGroup>
                    </Col> */}
                    {fieldCol(user.passport_name, "pl-1", "6", profile.passport_name, this.handleChange)}
                  </Row>
                  <Row>
                    {fieldCol(user.aan, "pr-1", "6", profile.aan, this.handleChange)}
                    {fieldCol(user.ps, "pl-1", "6", profile.ps, this.handleChange)}
                  </Row>
                </Form>
              </CardBody>
              <CardFooter style={{ textAlign: 'right' }}>
                <Button className="btn-round"
                  //  color="success" 
                  type="submit"
                  onClick={this.handleSave}
                >
                  儲存
                  </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        isOpen={this.state.show}
        toggle={this.close}
        size="lg"
        className="mh-90 h-90 mw-100 w-90"
        style={{ paddingTop: '0px' }}
      // style={{ maxWidth: '1600px', width: '80%' }}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.close}>
            <span aria-hidden="true">×</span>
          </button>
              更新圖片
          </ModalHeader>
        <ModalBody>
          <PreloadFile isImage='1' handleChangeFile={this.handleChangeFile} />
          {rowData[inputType] ? <h5>目前照片</h5> : null}
          {/* {previewUrl(rowData[inputType])} */}
          <RowImage rowData={profile} field='profilePic' />
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button content={'確認更改?'} onClick={() => this.setState({ loadingData: true }, () => this.handleSubmit())} hidden={changeConfirm} color='green' loading={loadingData} />
          <Button content={'刪除'} hidden={rowData[inputType] ? false : true} color='red' />
          <Button icon='x' content='關閉' onClick={this.close} />
        </ModalFooter>
      </Modal>
    </>);
  }
}

export default UserProfile;
