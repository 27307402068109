import React, { Component } from 'react'
import { Form } from 'semantic-ui-react';
import { Card, CardBody, CardHeader, Row, Col, Toast, ToastHeader, ToastBody } from 'reactstrap';

import Parse from 'widget/parse'
import { fieldCol } from "views/formDef/function";
import { portfolio } from 'views/formDef/object';

export default class Portfolio2 extends Component {
  state = {
    icSel: [],
    icId: '',
    providerSel: [],
    providerId: '',
  }

  async componentDidMount() {
    const { doc, companyObj, ic, provider } = this.props
    const companyId = companyObj.objectId;
    // const ic = await Parse.queryData('interestcondition', { companyId });
    // const provider = await Parse.queryData('provider', { companyId });

    const { agent } = this.props
    const agentSel = agent.map(item => ({ key: item.objectId, text: item.name, value: item.objectId }))
    // this.setState({ agentSel })

    const providerSel = provider.map(item => ({
      key: item.objectId, text: `${item.name_cht}`, value: item.objectId
    }))
    let providerId
    if (provider && provider.length && provider.find(item => (item.objectId === doc.provider_id))) {
      providerId = provider.find(item => (item.objectId === doc.provider_id)).objectId
    } else {
      providerId = ''
    }

    this.setState({ providerSel, providerId, agentSel, provider });
  }

  handleChange = (event, data) => {
    const { handleSelectUpdate } = this.props
    const { name, value } = data;
    handleSelectUpdate(data)
    this.setState({ [name]: value })
  }

  render() {
    const { doc, isEdit, showTable, handleChangeField } = this.props
    const { providerId, providerSel, agentSel } = this.state;

    return (<>
      <Card fluid>
        <CardHeader tag="h5" style={{ backgroundColor: '#f07b51' }}> [保險-VOYA] {doc.product4_text}</CardHeader>
        <CardBody>
          <Row>
            {fieldCol(portfolio.account_num, "pr-1", "3", doc.account_num, handleChangeField, !isEdit)}
            {fieldCol(portfolio.assume_insure_price, "pr-1", "3", doc.assume_insure_price, handleChangeField, !isEdit)}
            {fieldCol(portfolio.confirmed_insure_price, "pr-1", "3", doc.confirmed_insure_price, handleChangeField, !isEdit)}
            {fieldCol(portfolio.pay_year_num, "pr-1", "3", doc.pay_year_num, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.bodycheck_date, "pr-1", "3", doc.bodycheck_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.bodycheck_place, "pr-1", "3", doc.bodycheck_place, handleChangeField, !isEdit)}
            {fieldCol(portfolio.sign_date, "pr-1", "3", doc.sign_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.bodychecktype1, "pr-1", "3", doc.bodychecktype1, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.bodychecktype2, "pr-1", "3", doc.bodychecktype2, handleChangeField, !isEdit)}
            {fieldCol(portfolio.insurance_receive_date, "pr-1", "3", doc.insurance_receive_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.insurance_give_date, "pr-1", "3", doc.insurance_give_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.onlineaccess_account, "pr-1", "3", doc.onlineaccess_account, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.onlineaccess_password, "pr-1", "3", doc.onlineaccess_password, handleChangeField, !isEdit)}
            {fieldCol(portfolio.tp, "pr-1", "3", doc.tp, handleChangeField, !isEdit)}
            <Col sm='3'><Form.Select fluid label='供應商' options={providerSel} name='providerId' onChange={this.handleChange} value={providerId} disabled={!isEdit} /></Col>
            {fieldCol(portfolio.nowphase, "pr-1", "3", doc.nowphase, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.currency_type, "pr-1", "3", doc.currency_type, handleChangeField, !isEdit)}
            {fieldCol(portfolio.effective_date, "pr-1", "3", doc.effective_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.backdate, "pr-1", "3", doc.backdate, handleChangeField, !isEdit)}
            {fieldCol(portfolio.telephone_credit, "pr-1", "3", doc.telephone_credit, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.cpaletter_date, "pr-1", "3", doc.cpaletter_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.is_trust, "pr-1", "3", doc.is_trust, handleChangeField, !isEdit)}
            {fieldCol(portfolio.trust_isrevoke, "pr-1", "3", doc.trust_isrevoke, handleChangeField, !isEdit)}
            {fieldCol(portfolio.trust_lawyer, "pr-1", "3", doc.trust_lawyer, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.trust_name, "pr-1", "3", doc.trust_name, handleChangeField, !isEdit)}
            {fieldCol(portfolio.trust_trustee, "pr-1", "3", doc.trust_trustee, handleChangeField, !isEdit)}
            {fieldCol(portfolio.trust_grantor, "pr-1", "3", doc.trust_grantor, handleChangeField, !isEdit)}
            {fieldCol(portfolio.trust_ssnitin, "pr-1", "3", doc.trust_ssnitin, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.trust_bankaccount, "pr-1", "3", doc.trust_bankaccount, handleChangeField, !isEdit)}
            {fieldCol(portfolio.trust_account, "pr-1", "3", doc.trust_account, handleChangeField, !isEdit)}
            {fieldCol(portfolio.trust_routenum, "pr-1", "3", doc.trust_routenum, handleChangeField, !isEdit)}
            <Col sm='3'><Form.Select fluid label='agent' options={agentSel} id='agent_id' name='agent_text' onChange={this.handleChange} value={doc.agent_id || ''} disabled={!isEdit} /></Col>
          </Row>
          <Row>
            <Col>
              <Card fluid style={{ borderLeft: 'solid', backgroundColor: 'lightgoldenrodyellow', maxWidth: 'inherit' }}>
                <CardHeader style={{ backgroundColor: 'lightgoldenrodyellow', borderBottom: 'double' }}>受益人1</CardHeader>
                <CardBody>
                  <Row>
                    {fieldCol(portfolio.beneficial1_name, "pr-1", "3", doc.beneficial1_name, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.beneficial1_percent, "pr-1", "3", doc.beneficial1_percent, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.beneficial1_relationship, "pr-1", "3", doc.beneficial1_relationship, handleChangeField, !isEdit)}
                  </Row>
                </CardBody>
              </Card>
              {/* <Toast style={{ borderColor: 'black', maxWidth: 'inherit' }}>
                <ToastHeader>受益人1</ToastHeader>
                <ToastBody>
                  <Row>
                    {fieldCol(portfolio.beneficial1_name, "pr-1", "3", doc.beneficial1_name, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.beneficial1_percent, "pr-1", "3", doc.beneficial1_percent, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.beneficial1_relationship, "pr-1", "3", doc.beneficial1_relationship, handleChangeField, !isEdit)}
                  </Row>
                </ToastBody>
              </Toast> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <Card fluid style={{ borderLeft: 'solid', backgroundColor: 'lightgoldenrodyellow', maxWidth: 'inherit' }}>
                <CardHeader style={{ backgroundColor: 'lightgoldenrodyellow', borderBottom: 'double' }}>受益人2</CardHeader>
                <CardBody>
                  <Row>
                    {fieldCol(portfolio.beneficial2_name, "pr-1", "3", doc.beneficial2_name, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.beneficial2_percent, "pr-1", "3", doc.beneficial2_percent, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.beneficial2_relationship, "pr-1", "3", doc.beneficial2_relationship, handleChangeField, !isEdit)}
                  </Row>
                </CardBody>
              </Card>
              {/* <Toast style={{ borderColor: 'black', maxWidth: 'inherit' }}>
                <ToastHeader>受益人2</ToastHeader>
                <ToastBody>
                  <Row>
                    {fieldCol(portfolio.beneficial2_name, "pr-1", "3", doc.beneficial2_name, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.beneficial2_percent, "pr-1", "3", doc.beneficial2_percent, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.beneficial2_relationship, "pr-1", "3", doc.beneficial2_relationship, handleChangeField, !isEdit)}
                  </Row>
                </ToastBody>
              </Toast> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <Card fluid style={{ borderLeft: 'solid', backgroundColor: 'lightgoldenrodyellow', maxWidth: 'inherit' }}>
                <CardHeader style={{ backgroundColor: 'lightgoldenrodyellow', borderBottom: 'double' }}>信託受益人1</CardHeader>
                <CardBody>
                  <Row>
                    {fieldCol(portfolio.trust_beneficial1_name, "pr-1", "3", doc.trust_beneficial1_name, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.trust_beneficial1_percent, "pr-1", "3", doc.trust_beneficial1_percent, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.trust_beneficial1_relationship, "pr-1", "3", doc.trust_beneficial1_relationship, handleChangeField, !isEdit)}
                  </Row>
                </CardBody>
              </Card>
              {/* <Toast style={{ borderColor: 'black', maxWidth: 'inherit' }}>
                <ToastHeader>信託受益人1</ToastHeader>
                <ToastBody>
                  <Row>
                    {fieldCol(portfolio.trust_beneficial1_name, "pr-1", "3", doc.trust_beneficial1_name, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.trust_beneficial1_percent, "pr-1", "3", doc.trust_beneficial1_percent, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.trust_beneficial1_relationship, "pr-1", "3", doc.trust_beneficial1_relationship, handleChangeField, !isEdit)}
                  </Row>
                </ToastBody>
              </Toast> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <Card fluid style={{ borderLeft: 'solid', backgroundColor: 'lightgoldenrodyellow', maxWidth: 'inherit' }}>
                <CardHeader style={{ backgroundColor: 'lightgoldenrodyellow', borderBottom: 'double' }}>信託受益人2</CardHeader>
                <CardBody>
                  <Row>
                    {fieldCol(portfolio.trust_beneficial2_name, "pr-1", "3", doc.trust_beneficial2_name, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.trust_beneficial2_percent, "pr-1", "3", doc.trust_beneficial2_percent, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.trust_beneficial2_relationship, "pr-1", "3", doc.trust_beneficial2_relationship, handleChangeField, !isEdit)}
                  </Row>
                </CardBody>
              </Card>
              {/* <Toast style={{ borderColor: 'black', maxWidth: 'inherit' }}>
                <ToastHeader>信託受益人2</ToastHeader>
                <ToastBody>
                  <Row>
                    {fieldCol(portfolio.trust_beneficial2_name, "pr-1", "3", doc.trust_beneficial2_name, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.trust_beneficial2_percent, "pr-1", "3", doc.trust_beneficial2_percent, handleChangeField, !isEdit)}
                    {fieldCol(portfolio.trust_beneficial2_relationship, "pr-1", "3", doc.trust_beneficial2_relationship, handleChangeField, !isEdit)}
                  </Row>
                </ToastBody>
              </Toast> */}
            </Col>
          </Row>
          {/* <Row>

          </Row> */}
          {/* <Row>

          </Row> */}
        </CardBody>
      </Card>
      {showTable('PaymentRecord')}
    </>)
  }
}
// { fieldCol(portfolio.beneficial3_name, "pr-1", "3", doc.beneficial3_name, handleChangeField, !isEdit) }
// { fieldCol(portfolio.beneficial3_percent, "pr-1", "3", doc.beneficial3_percent, handleChangeField, !isEdit) }
// { fieldCol(portfolio.beneficial3_relationship, "pr-1", "3", doc.beneficial3_relationship, handleChangeField, !isEdit) }
// { fieldCol(portfolio.trust_beneficial3_name, "pr-1", "3", doc.trust_beneficial3_name, handleChangeField, !isEdit) }
// { fieldCol(portfolio.trust_beneficial3_percent, "pr-1", "3", doc.trust_beneficial3_percent, handleChangeField, !isEdit) }
// { fieldCol(portfolio.trust_beneficial3_relationship, "pr-1", "3", doc.trust_beneficial3_relationship, handleChangeField, !isEdit) }
// { fieldCol(portfolio.trust_beneficial4_name, "pr-1", "3", doc.trust_beneficial4_name, handleChangeField, !isEdit) }
// { fieldCol(portfolio.trust_beneficial4_percent, "pr-1", "3", doc.trust_beneficial4_percent, handleChangeField, !isEdit) }
// { fieldCol(portfolio.trust_beneficial4_relationship, "pr-1", "3", doc.trust_beneficial4_relationship, handleChangeField, !isEdit) }


