import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { exportPdf, typeCurrency } from 'views/web/CustomMaterialTable';

// 1 月報表
const columns2 = () => ([
  { width: 80, title: '產品名稱', field: 'productName' },
  { width: 80, title: '產品編號', field: 'productNum' },
  { width: 80, title: '件數', field: 'pieces' },
  { width: 80, title: '單價', field: 'unitPrice', ...typeCurrency },
  {
    width: 80, title: '小計', field: 'subtotal', editable: 'never', ...typeCurrency
    // render: rowData => 
  },
]);

export default class Ordering2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data2: [],
      newData: {},
      oldData: {},
      loading2: true,
    }
    this.tableRef = React.createRef();
  }

  componentDidMount = async () => {
    const { companyObj, match } = this.props
    const { t1, clientId } = match.params;
    const companyId = companyObj.objectId
    const data = await Parse.queryData('ordering2', { companyId, clientId }, { orderBy: 'startDate_descend' }); // 有客戶的id的話 就抓這個人的所有借據
    this.setState({ data2: data, loading2: false });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data2: nextProps.data2 });
  }

  handleAdd = async () => {
    const { newData } = this.state
    await Parse.saveData('ordering2', newData);
    await this.props.reorgPawning12()
  }

  handleUpdate = async () => {
    const { newData } = this.state
    await Parse.saveData('ordering2', newData);
    await this.props.reorgPawning12()
  }

  handleDelete = async () => {
    const { oldData } = this.state
    await Parse.deleteData('ordering2', oldData);
    await this.props.reorgPawning12()
    this.setState({ oldData: {} })
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  render() {
    const { ordering1Obj, loading2 } = this.props;
    const { data2 } = this.state
    const { ordernum = '' } = ordering1Obj;
    return (<>
      <MaterialTable
        isLoading={loading2}
        localization={localization()}
        columns={columns2()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${ordernum} 訂單內容：`),
          pageSize: 5,// data2.length < 5 ?  : data2.length + 1,
          search: false,
          tableLayout: 'fixed',
        }}
        data={data2}
        tableRef={this.tableRef}
        title={`${ordernum} 訂單內容：`}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const data = [...data2];
              const db = Parse.Object.extend("ordering2");
              const newDoc = new db();
              const doc = await newDoc.save();

              const obj = {
                ...ordering1Obj,
                _id: doc.id,
                id: doc.id,
                objectId: doc.id,
                ordering1Id: ordering1Obj.objectId,
                ...newData,
                subtotal: String(Number(newData.pieces || 0) * Number(newData.unitPrice || 0)),
                // currentBalance: String(Number(newData.principal || 0) - Number(newData.repaymentMoney || 0)),
              };

              data.push(obj);
              this.setState({ data2: data, newData: obj }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...data2];
              const index = data.indexOf(oldData);
              const obj = {
                ...newData,
              }

              data[index] = obj;
              this.setState({ data2: data, newData: obj, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...data2];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              this.setState({ data2: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
    </>)
  }
}