import React, { Component } from 'react'
import { Form, Button, Modal, Table, Grid, Header, Icon, Confirm, Select } from 'semantic-ui-react'
import _ from 'lodash'
import MyModalTable from '../../widgets/MyModalTable';
import Parse from '../../../widget/parse'

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}

export default class Applications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      show: false,
      guidance: [],
      confirm: false,
      loading: false,
      showModal: false
    }
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, match } = props;
    // const { filesData } = this.state
    // const { _id } = match.params;

    // firebase.firestore().collection(`Dormitory`).get().then(snap => {
    //   var promises = [];
    //   snap.forEach(item => {
    //     var promise = firebase.firestore().collection(`Dormitory/${_id}/Room/${item.id}/files`)
    //       .get().then(snapshot => {
    //         // console.log(snapshot.size);
    //         const filesData = []
    //         snapshot.forEach(fileItem => filesData.push({ _id: fileItem.id, ...fileItem.data(), parttimeId: item.id }));
    //         return { _id: item.id, ...item.data(), filesData };
    //       });
    //     promises.push(promise);
    //   });
    //   Promise.all(promises).then(results => {
    //     // console.log('all promises were done')
    //     // console.log(results);
    //     this.setState({ guidance: results })
    //   }).catch(error => {
    //     console.log(error);
    //   });

    // }, err => {
    //   console.log(`Encountered error: ${err}`);
    // });

    Parse.queryData('Dormitory', { companyId: companyObj.objectId }).then(snap => {
      let promises = [];
      snap.forEach(doc => {
        let promise = Parse.queryData('Room', { companyId: companyObj.objectId, dormId: doc.objectId })
          .then(snap2 => {
            const roomData = [];
            snap2.forEach(room => roomData.push(room))
            return { objectId: doc.objectId, ...doc, roomData };
          })
        console.log(promise)
        promises.push(promise);
      });
      console.log(promises)
      Promise.all(promises).then(results => {
        // console.log('all promises were done')
        console.log(results);
        this.setState({ guidance: results, show: true, loading: false })
      }).catch(error => {
        console.log(error);
      });
    })

    // firebase.firestore().collection("Dormitory").get().then(snap => {
    //   var promises = [];
    //   snap.forEach(doc => {
    //     var promise = firebase.firestore().collection(`Dormitory/${doc.id}/Room`)
    //       .get().then(snap2 => {
    //         const roomData = [];
    //         snap2.forEach(room => roomData.push({ _id: room.id, ...room.data() }))
    //         return { _id: doc.id, ...doc.data(), roomData };
    //       })
    //     promises.push(promise);
    //   });
    //   Promise.all(promises).then(results => {
    //     // console.log('all promises were done')
    //     console.log(results);
    //     this.setState({ guidance: results, show: true, loading: false })
    //   }).catch(error => {
    //     console.log(error);
    //   });
    // });
  }


  handleSubmit = async () => {
    const { firebase, doc, semesterYear, semester } = this.props;
    const { form, guidance } = this.state;
    const db = firebase.firestore();

    this.setState({ loading: true });
    const newGuidance = [...guidance];
    // if (!form._id) {
    //   const docRef = db.collection(`Dormitory/${doc._id}/Room`).doc();
    //   const obj = {
    //     ...form,
    //     _id: docRef.id,
    //     studentId: doc._id,
    //     studentIdKey: doc.studentId,
    //     studentName: doc.jpnName,
    //     semesterYear,
    //     semester,
    //     createdAt: new Date()
    //   };
    //   await docRef.set(obj);
    //   newGuidance.push(obj);
    // } else {
    //   await db.collection(`Dormitory/${doc._id}/Room`).doc(form._id).update(form);
    //   let index = newGuidance.findIndex((item => item._id === form._id));
    //   newGuidance[index] = form;
    // }

    this.setState({ guidance: newGuidance, loading: false });
    this.handleClose();
  }

  handleChange = (event, data) => {
    const { name, value } = data
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    })
  }

  handleOpen = () => {
    this.setState({ show: true })
  }

  handleEdit = (guidanceObj) => {
    this.setState({ show: true, form: { ...guidanceObj } });
  }

  handleClose = () => {
    this.setState({ show: false, form: {}, readOnly: false, fileObj: {} });
  }

  handleRemove = async () => {
    const { firebase, doc } = this.props
    const { form, guidance } = this.state;
    // await firebase.firestore().collection(`Dormitory/${doc._id}/Room`).doc(form._id).delete();
    const newGuidance = [...guidance];
    let index = newGuidance.findIndex((item => item.objectId === form.objectId));
    newGuidance.splice(index, 1);
    this.setState({ guidance: newGuidance });

    this.handleClose();
    this.hideConfirm();
  }

  showConfirm = (guidanceObj) => {
    this.setState({ confirm: true, form: { ...guidanceObj } });
  }
  hideConfirm = () => this.setState({ confirm: false, form: {} });

  fields = [
    {
      id: 'studentNum',
      title: '学籍番号'
    }, {
      id: 'jpnName',
      title: '氏名'
    },
    {
      id: 'homeName',
      title: '母国語氏名'
    },
    {
      id: 'city',
      title: 'Product Price'
    },
  ]
  render() {
    const { isEdit, doc } = this.props
    const { guidance, form, disabled, readOnly, loading, show, confirm, } = this.state
    return (
      <>
        <Header as='h2' dividing
          content='寮一覧'
        // subheader='調査時間：2017年4月から 2018年12月 まで'
        />
        {/* {isEdit ? <Button inverted={companyObj.inverted} color='blue' onClick={this.handleOpen} icon='add' content='追加' /> : ''} */}
        <Table celled structured>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>学籍番号</Table.HeaderCell>
              <Table.HeaderCell>氏名</Table.HeaderCell>
              <Table.HeaderCell>母国語氏名</Table.HeaderCell>
              <Table.HeaderCell>Product Price</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {guidance.length ? guidance.map((item, index) => (<React.Fragment key={index}>
              <Table.Row>
                <Table.Cell>{item.studentNum}</Table.Cell>
                <Table.Cell>{item.jpnName}</Table.Cell>
                <Table.Cell>{item.homeName}</Table.Cell>
                <Table.Cell>{item.city}</Table.Cell>
              </Table.Row>
            </React.Fragment>)) : <Table.Row><Table.Cell colSpan='4' textAlign='center'>(資料無し)</Table.Cell></Table.Row>}
          </Table.Body>
        </Table>

      </>
    )
  }
}