import jsPDF from '../pdf/JspdfMyFonts';

import { salaryPage } from './pdfPawnshop';

// const d = new Date();
// const nowDate = `${d.getFullYear() - 1911}年${d.getMonth() + 1}月${d.getDate()}日`;

const data = (props) => {
  const { formSet, optSet, value, arrObj, companyObj, codeForm, nowform, params, printDate, lightType, selectData, nowservice, form } = props;

  let doc = new jsPDF({ format: 'a4', orientation: "l", unit: 'pt' });
  salaryPage(doc, arrObj, params);//, datetime);

  return doc
}

export default data;
