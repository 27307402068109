import React, { Component } from 'react'
import { Container, Icon, Table, Grid, Button, Modal, Header, Form, Pagination, Dropdown, Checkbox } from 'semantic-ui-react'
import _ from 'lodash'
import { Link } from 'react-router-dom';
import Parse from '../../../widget/parse'

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipse: {
    // boxSizong: 'border-box',
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis'
  }
}

export default class TeacherAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      confirm: false,
      readOnly: false,
      unsub: null,
      teachersArr: [],
      usersArr: [],
      form: {},
      workStatus: '1',
      author: '',
      number: 0,
      resultArr: [],
      teachers: [],
      users: []
    }
  }
  componentDidMount = async () => {
    const { companyObj } = this.props
    const data = await Parse.queryData('Teachers', { companyId: companyObj.objectId });
    this.setState({ teachers: data });
    const data1 = await Parse.queryData('Users', { companyId: companyObj.objectId });
    this.setState({ users: data1 }, () => this.handleInitial());

  }

  componentWillReceiveProps(nextProps) {
    this.handleInitial(nextProps)
  }

  handleInitial = (props = this.props) => {
    // const { teachers, users } = props
    const { workStatus, teachers, users } = this.state

    const teachersArr = workStatus === '0' ? [...teachers.sort((a, b) => a.teacherNum - b.teacherNum)] : [...teachers.sort((a, b) => a.teacherNum - b.teacherNum).filter(item => item.staffStatus === workStatus)]
    const usersArr = [...users]
    const resultArr = [...teachersArr]

    usersArr.map(item1 => {
      let findIndex = teachersArr.findIndex(item => item._id === item1.realStatus)
      if (findIndex !== -1)
        resultArr[findIndex] = { ...item1, ...teachersArr[findIndex], userId: item1._id }
    })

    this.setState({ teachersArr, usersArr, resultArr })
  }

  changeWorkStatus = (e, data) => {
    this.setState({ workStatus: data.value }, () => this.handleInitial())
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    const { form } = this.state
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    })
  }

  render() {
    const { firebase, teachers, teachersObj, users } = this.props;
    const { teachersArr, readOnly, loading, form, workStatus, usersArr, resultArr } = this.state

    const isWorkArr = [
      { key: 'isWorkOption1', text: '在勤', value: '1' },
      { key: 'isWorkOption2', text: '離職', value: '2' },
    ]
    const isWorkArrMain = [
      { key: 'isWorkOption0', text: '全部', value: '0' },
      ...isWorkArr
    ]

    return (
      <>
        <Header as='h2' style={{ margin: 0 }}>教員帳號一覧表</Header>
        <div style={{ ...style.flexCenter, marginTop: 15 }}>
          <div>
            <label>状態</label>
            <Form.Select options={isWorkArrMain} defaultValue='1' style={{ marginTop: 10 }} onChange={this.changeWorkStatus} />
          </div>
        </div>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>教員番号</Table.HeaderCell>
              <Table.HeaderCell>氏名 (全名)</Table.HeaderCell>
              <Table.HeaderCell>専任/非専任</Table.HeaderCell>
              <Table.HeaderCell>職位</Table.HeaderCell>
              <Table.HeaderCell>account</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>受付</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>募集</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>在籍</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>教務</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>総務</Table.HeaderCell>
              <Table.HeaderCell textAlign='center'>人事</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {/* {teachersArr.map((item, index) =>
              usersArr.map((item1, index1) => <Table.Row key={index}>
                <Table.Cell>{item.teacherNum}</Table.Cell>
                <Table.Cell>{item.nameTeacher}</Table.Cell>
                <Table.Cell>{item.partTimeTeacherText}</Table.Cell>
                <Table.Cell>{item.teacherTitleText}</Table.Cell>
                <Table.Cell>{item._id === item1.realStatus ? item1.displayName : null}</Table.Cell>
                <Table.Cell textAlign='center'>
                  {item._id === item1.realStatus ? <Checkbox className='auth' name='auth0' value='0' disabled onChange={this.handleChange} checked={item1.authArr.indexOf('auth0') !== -1} /> : null}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {item._id === item1.realStatus ? <Checkbox className='auth' name='auth1' value='1' disabled onChange={this.handleChange} checked={item1.authArr.indexOf('auth1') !== -1} /> : null}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {item._id === item1.realStatus ? <Checkbox className='auth' name='auth2' value='2' disabled onChange={this.handleChange} checked={item1.authArr.indexOf('auth2') !== -1} /> : null}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {item._id === item1.realStatus ? <Checkbox className='auth' name='auth3' value='3' disabled onChange={this.handleChange} checked={item1.authArr.indexOf('auth3') !== -1} /> : null}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {item._id === item1.realStatus ? <Checkbox className='auth' name='auth4' value='4' disabled onChange={this.handleChange} checked={item1.authArr.indexOf('auth4') !== -1} /> : null}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {item._id === item1.realStatus ? <Checkbox className='auth' name='auth5' value='5' disabled onChange={this.handleChange} checked={item1.authArr.indexOf('auth5') !== -1} /> : null}
                </Table.Cell>
              </Table.Row>))} */}
            {resultArr.map((item, index) => <Table.Row key={index}>
              <Table.Cell>{item.teacherNum}</Table.Cell>
              <Table.Cell>{item.nameTeacher}</Table.Cell>
              <Table.Cell>{item.partTimeTeacherText}</Table.Cell>
              <Table.Cell>{item.teacherTitleText}</Table.Cell>
              <Table.Cell>{item.email}</Table.Cell>
              <Table.Cell textAlign='center'>
                <Checkbox className='auth' name='auth0' value='0' disabled onChange={this.handleChange} checked={item.authArr && item.authArr.length && item.authArr.indexOf('auth0') !== -1} />
              </Table.Cell>
              <Table.Cell textAlign='center'>
                <Checkbox className='auth' name='auth1' value='1' disabled onChange={this.handleChange} checked={item.authArr && item.authArr.length && item.authArr.indexOf('auth1') !== -1} />
              </Table.Cell>
              <Table.Cell textAlign='center'>
                <Checkbox className='auth' name='auth2' value='2' disabled onChange={this.handleChange} checked={item.authArr && item.authArr.length && item.authArr.indexOf('auth2') !== -1} />
              </Table.Cell>
              <Table.Cell textAlign='center'>
                <Checkbox className='auth' name='auth3' value='3' disabled onChange={this.handleChange} checked={item.authArr && item.authArr.length && item.authArr.indexOf('auth3') !== -1} />
              </Table.Cell>
              <Table.Cell textAlign='center'>
                <Checkbox className='auth' name='auth4' value='4' disabled onChange={this.handleChange} checked={item.authArr && item.authArr.length && item.authArr.indexOf('auth4') !== -1} />
              </Table.Cell>
              <Table.Cell textAlign='center'>
                <Checkbox className='auth' name='auth5' value='5' disabled onChange={this.handleChange} checked={item.authArr && item.authArr.length && item.authArr.indexOf('auth5') !== -1} />
              </Table.Cell>
            </Table.Row>)}
          </Table.Body>
        </Table>

      </>
    )
  }
}
