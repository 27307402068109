import React, { Component } from 'react';
import { Table, Grid, Header } from 'semantic-ui-react';
import ModalPdf from 'widget/pdf/ModalPdf';
import Moment from 'moment';
import Parse from 'widget/parse.js'
import { Row, Col } from 'reactstrap'

export const getSemesterAttendByWeekly = (weeklyArr, semesterNum) => {
  const d = Moment(new Date()).format('YYYY-MM-DD');
  // const year = d.getFullYear() + '';
  // const month = (d.getMonth() + 1) + '';

  let weeklyExpectedDay = 0;
  let weeklyExpectedHours = 0;
  let weeklyRealDay = 0;
  let weeklyRealHours = 0;

  weeklyArr.filter(item => item.semesterNum === semesterNum)
    .forEach(item2 => {
      if (item2.endDate < d) {//計算到當週的前一週
        weeklyExpectedDay += Number(item2.weeklyExpectedDay);
        weeklyExpectedHours += Number(item2.weeklyExpectedHours);
        weeklyRealDay += Number(item2.weeklyRealDay);
        weeklyRealHours += Number(item2.weeklyRealHours);
      }
    })

  const rate0 = (!weeklyRealDay || !weeklyExpectedDay) ? "0" : Math.round((Number(weeklyRealDay) / Number(weeklyExpectedDay) * 1000)) / 10;
  const rate1 = (!weeklyRealHours || !weeklyExpectedHours) ? "0" : Math.round((Number(weeklyRealHours) / Number(weeklyExpectedHours) * 1000)) / 10;
  // console.log(weeklyExpectedDay)
  return [weeklyExpectedDay,
    weeklyExpectedHours,
    weeklyRealDay,
    weeklyRealHours,
    rate0,
    rate1];
}
export const getAllAttendByWeekly = (weeklyArr) => {
  const d = new Date();
  const year = d.getFullYear() + '';
  const month = (d.getMonth() + 1) + '';

  let weeklyExpectedDay = 0;
  let weeklyExpectedHours = 0;
  let weeklyRealDay = 0;
  let weeklyRealHours = 0;

  weeklyArr.filter(item => Number(item.year) < Number(year) || (Number(item.year) === Number(year) && Number(item.month) <= Number(month)))
    .forEach(item2 => {
      weeklyExpectedDay += Number(item2.weeklyExpectedDay);
      weeklyExpectedHours += Number(item2.weeklyExpectedHours);
      weeklyRealDay += Number(item2.weeklyRealDay);
      weeklyRealHours += Number(item2.weeklyRealHours);
    })

  const rate0 = Math.round((Number(weeklyRealDay) / Number(weeklyExpectedDay) * 1000)) / 10;
  const rate1 = Math.round((Number(weeklyRealHours) / Number(weeklyExpectedHours) * 1000)) / 10;


  return [weeklyExpectedDay,
    weeklyExpectedHours,
    weeklyRealDay,
    weeklyRealHours,
    rate0,
    rate1];
}
export default class Table13 extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      show: false,
      doc: props.doc,
      webcamImg: '',
      form: {},
      arr: [],
      grade2: [],
      graduation: [],
    };
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ doc: nextProps.doc });
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, match } = props;
    const { _id } = match.params;

    // const db = firebase.firestore();
    const arr = await Parse.queryData('Relative', { companyId: companyObj.objectId, studentId: _id });
    this.setState({ arr })
    // db.collectionGroup('Weekly')
    //   .where('studentId', '==', _id)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }))
    //     this.setState({ arr: data })
    //   })
    const grade2 = await Parse.queryData('Grade2', { companyId: companyObj.objectId, studentId: _id });
    this.setState({ grade2 });
    // db.collection(`Students/${_id}/Grade`)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ grade2: data });
    //   }, err => { console.log(`Encountered error: ${err}`); });
    const EJU = await Parse.queryData('EJU', { companyId: companyObj.objectId, studentId: _id }, { orderBy: 'createdAt_descend' });
    this.setState({ EJU });
    // db.collection(`Students/${_id}/EJU`)
    //   .orderBy('createdAt', 'desc')
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ EJU: data });
    //   }, err => { console.log(`Encountered error: ${err}`); });
    const JLPT = await Parse.queryData('JLPT', { companyId: companyObj.objectId, studentId: _id }, { orderBy: 'createdAt_descend' });
    this.setState({ JLPT })
    // db.collection(`Students/${_id}/JLPT`)
    //   .orderBy('createdAt', 'desc')
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ JLPT: data });
    //   }, err => { console.log(`Encountered error: ${err}`); });
    const ENG = await Parse.queryData('ENG', { companyId: companyObj.objectId, studentId: _id }, { orderBy: 'createdAt_descend' });
    this.setState({ ENG })
    // db.collection(`Students/${_id}/ENG`)
    //   .orderBy('createdAt', 'desc')
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ ENG: data });
    //   }, err => { console.log(`Encountered error: ${err}`); });
    const Graduate = await Parse.queryData('Graduate', { companyId: companyObj.objectId, studentId: _id }, { orderBy: 'createdAt_descend' });
    this.setState({ graduation: Graduate }, () => this.getPrintData());
    // db.collection(`Students/${_id}/Graduate`)
    //   // .orderBy('relationship')
    //   // .where('eventDate', '==', eventDate).where('classname', '==', className)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ graduation: data }, () => this.getPrintData());
    //   }, err => { console.log(`Encountered error: ${err}`); });
  }

  handleChange = (event) => {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    this.setState({ form: { ...this.state.doc, [fieldName]: fleldVal } }, () => {
      this.props.setRelativeObj(this.state.form);
    })
  }

  rank = (studentArr, score) => {
    const sortedArr = studentArr.sort((a, b) => b.resTotalScore - a.resTotalScore);
    return sortedArr.findIndex(item => item.resTotalScore === score) + 1;
  }

  getPrintData = () => {
    const { doc } = this.props;
    const { arr, graduation, grade2, JLPT, EJU, ENG } = this.state;
    const semesterInRoman = doc.semester === "S" ? ['I', 'II', 'III', 'IV'] : ['I', 'II', 'III'];

    let obj = {
      ExpectedDay: [], ExpectedHours: [], RealDay: [], RealHours: [], avgDayArr: [], avgHoursArr: [],
      targetSchoolText: [], targetSubject: [], isAdmittedText: [], isEnterText: [],
      semes: [], testDate: [], testLevel: [], rank: [], vocabularyScore: [], listeningScore: [],
      grammarScore: [], readingScore: [], totalScore: [], resVocabularyScore: [], resListeningScore: [], resGrammarScore: [],
      resReadingScore: [], resTotalScore: [], writingScore: [], conversationScore: [], gpaAvg: []
    };

    graduation.map(item => {
      obj.targetSchoolText.push(item.targetSchoolText)
      obj.targetSubject.push(item.targetSubject)
      obj.isAdmittedText.push(item.isAdmittedText)
      obj.isEnterText.push(item.isEnterText)
    })
    semesterInRoman.map(item => {
      obj.avgDayArr.push(getSemesterAttendByWeekly(arr, item)[4])
      obj.avgHoursArr.push(getSemesterAttendByWeekly(arr, item)[5])
      obj.RealDay.push(getSemesterAttendByWeekly(arr, item)[2])
      obj.ExpectedDay.push(getSemesterAttendByWeekly(arr, item)[0])
      obj.RealHours.push(getSemesterAttendByWeekly(arr, item)[3])
      obj.ExpectedHours.push(getSemesterAttendByWeekly(arr, item)[1])
    })
    grade2.map(item => {
      obj.semes.push(`${item.semesterYear && item.semesterYear.substr(-2)} ${item.semester}`)
      obj.testDate.push(item.testDate)
      obj.testLevel.push(item.testLevel)
      obj.rank.push(`${item.rank}/${item.rankTotal}`)
      obj.vocabularyScore.push(item.vocabularyScore)
      obj.listeningScore.push(item.listeningScore)
      obj.grammarScore.push(item.grammarScore)
      obj.readingScore.push(item.readingScore)
      obj.totalScore.push((Number(item.vocabularyScore) + Number(item.listeningScore) + Number(item.grammarScore) + Number(item.readingScore)).toString())
      obj.resVocabularyScore.push(item.resVocabularyScore)
      obj.resListeningScore.push(item.resListeningScore)
      obj.resGrammarScore.push(item.resGrammarScore)
      obj.resReadingScore.push(item.resReadingScore)
      obj.resTotalScore.push(item.resTotalScore)
      obj.writingScore.push(item.writingScore)
      obj.conversationScore.push(item.conversationScore)
      obj.gpaAvg.push(item.gpaAvg)
    })
    obj.JLPT = JLPT
    obj.ENG = ENG
    obj.EJU = EJU
    //total的出席率
    // obj.avgDay = getAllAttendByWeekly(arr)[4]
    // obj.avgHours = getAllAttendByWeekly(arr)[5]
    // obj.allRealDay = getAllAttendByWeekly(arr)[2]
    // obj.allExpectedDay = getAllAttendByWeekly(arr)[0]
    // obj.allRealHours = getAllAttendByWeekly(arr)[3]
    // obj.allExpectedHours = getAllAttendByWeekly(arr)[1]
    this.setState({ params: { ...doc, ...obj, semesterInRoman } })
    // console.log(this.state.params)
  }

  render() {
    const { calendar, studentsInClass, grade, optSet, isEdit } = this.props;
    let { form, doc, arr, params, graduation, grade2, ENG, JLPT, EJU } = this.state;
    const semesterInRoman = doc.semester === "S" ? ['I', 'II', 'III', 'IV'] : ['I', 'II', 'III'];
    return (<>
      <Header as='h2' dividing
        content='試験成績及び出席率一覧表'
        style={{ backgroundColor: 'lavender' }}
      // subheader='調査時間：2017年4月から 2018年12月 まで'
      />
      {/* <h1>《学生試験成績及び出席率一覧表》</h1> */}

      {/* <p>調査時間：2017年4月から 2018年12月 まで</p> */}
      {/* <Col md={6} style={{ textAlign: 'right' }}>2018年4月13日現在</Col> */}
      {/* <p style={{ textAlign: 'right' }}>発行日 : 2018年12月4日</p> */}
      {/* <ReactToPrint
        trigger={(e) => <Button
          content='印刷'
          // label='&nbsp;'
          color='orange'
          icon='print'
          size='small'
          floated='right'
          className='no-print'
        />}
        content={() => this.componentRef}
        pageStyle={"@page { size: auto;  margin: 10mm; }*{font-family: 'Noto Serif JP', serif;}"}
      /> */}
      <ModalPdf
        {...this.props}
        // ref={(ref) => { this.pdfModal = ref }}
        codekey="table13"
        params={params}
      />
      <br />
      <div ref={el => (this.componentRef = el)} className="" >
        {/* <Grid style={{ padding: '20px' }}> */}
        <h1>《学生試験成績及び出席率一覧表》</h1>
        <Row>
          <Col sm={4}>
            <Table celled structured size="small" >
              <Table.Body>
                <Table.Row>
                  <Table.Cell active style={{ width: '100px' }}>期生</Table.Cell>
                  {/* <Table.Cell>{doc.semesterYear} {doc.semester ? (doc.semester === 'A' ? '10' : '04') : ''}</Table.Cell> */}
                  <Table.Cell>{doc.semesterYear} {doc.semester}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell active>学籍番号</Table.Cell>
                  <Table.Cell>{doc.studentId}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell active>氏名</Table.Cell>
                  <Table.Cell>{doc.jpnName}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Col>
        </Row>
        <br />
        <Row>
          <Col width={8}>
            <h4>出席率</h4>
            <Table size="small" compact celled style={{ textAlign: 'center', width: '100%' }} >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='3' style={{ textAlign: 'center' }}>出席率 ％</Table.HeaderCell>
                  <Table.HeaderCell colSpan='2' style={{ textAlign: 'center' }}>日数</Table.HeaderCell>
                  <Table.HeaderCell colSpan='2' style={{ textAlign: 'center' }}>時間数</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>学期</Table.Cell>
                  <Table.Cell>日数計算</Table.Cell>
                  <Table.Cell>時間計算</Table.Cell>
                  <Table.Cell>出席日数</Table.Cell>
                  <Table.Cell>授業日数</Table.Cell>
                  <Table.Cell>出席時間</Table.Cell>
                  <Table.Cell>授業時間</Table.Cell>
                </Table.Row>
                {semesterInRoman.map((item, index) => (
                  <Table.Row key={`apa${index}}`}>
                    <Table.Cell>{semesterInRoman[index]}</Table.Cell>
                    <Table.Cell>
                      {getSemesterAttendByWeekly(arr, item)[4] || ''}
                    </Table.Cell>
                    <Table.Cell>
                      {getSemesterAttendByWeekly(arr, item)[5] || ''}
                    </Table.Cell>
                    <Table.Cell>
                      {getSemesterAttendByWeekly(arr, item)[2] || ''}
                    </Table.Cell>
                    <Table.Cell>
                      {getSemesterAttendByWeekly(arr, item)[0] || ''}
                    </Table.Cell>
                    <Table.Cell>
                      {getSemesterAttendByWeekly(arr, item)[3] || ''}
                    </Table.Cell>
                    <Table.Cell>
                      {getSemesterAttendByWeekly(arr, item)[1] || ''}
                    </Table.Cell>
                  </Table.Row>
                ))}
                <Table.Row>
                  <Table.Cell>トータル</Table.Cell>
                  <Table.Cell>
                    {getAllAttendByWeekly(arr)[4] || ''}
                  </Table.Cell>
                  <Table.Cell>
                    {getAllAttendByWeekly(arr)[5] || ''}
                  </Table.Cell>
                  <Table.Cell>
                    {getAllAttendByWeekly(arr)[2] || ''}
                  </Table.Cell>
                  <Table.Cell>
                    {getAllAttendByWeekly(arr)[0] || ''}
                  </Table.Cell>
                  <Table.Cell>
                    {getAllAttendByWeekly(arr)[3] || ''}
                  </Table.Cell>
                  <Table.Cell>
                    {getAllAttendByWeekly(arr)[1] || ''}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Col>
          <Col sm={6}>
            <h4>受験・進学先</h4>
            <Table celled structured size="small" style={{ textAlign: 'center', tableLayout: "auto", width: '100%' }} >
              <Table.Body>
                {graduation && graduation.length ? <>
                  <Table.Row>
                    <Table.Cell active width="5">受験学校</Table.Cell>
                    {graduation.map((item, index) => (
                      <Table.Cell key={'a' + index}>{item.targetSchoolText}</Table.Cell>))}
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell active width="5">学部</Table.Cell>
                    {graduation.map((item, index) => (
                      <Table.Cell key={'b' + index}>{item.targetDepartment}</Table.Cell>))}
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell active width="5">学科</Table.Cell>
                    {graduation.map((item, index) => (
                      <Table.Cell key={'c' + index}>{item.targetSubject}</Table.Cell>))}
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell active width="5">結果</Table.Cell>
                    {graduation.map((item, index) => (
                      <Table.Cell key={'d' + index}>{item.isAdmittedText}</Table.Cell>))}
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell active width="5">先</Table.Cell>
                    {graduation.map((item, index) => (
                      <Table.Cell key={'e' + index}>{item.isEnterText ? 'Ｏ' : ''}</Table.Cell>))}
                  </Table.Row></>
                  : <Table.Row><Table.Cell rowSpan='5' textAlign='center'>(資料無し)</Table.Cell></Table.Row>}
              </Table.Body>
            </Table>
          </Col>
        </Row>
        <br />
        {/* </Grid> */}
        {/* <Grid> */}
        <Row>
          <Col>
            <h4>定期試験</h4>
            <Table celled structured size="small" style={{ textAlign: 'center', tableLayout: "auto", width: '100%' }} >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell rowSpan='2' style={{ textAlign: 'center' }}>学期</Table.HeaderCell>
                  <Table.HeaderCell rowSpan='2' style={{ textAlign: 'center' }}>試験日</Table.HeaderCell>
                  <Table.HeaderCell rowSpan='2' style={{ textAlign: 'center' }}>試験レべル</Table.HeaderCell>
                  <Table.HeaderCell rowSpan='2' style={{ textAlign: 'center' }}>順位</Table.HeaderCell>
                  <Table.HeaderCell colSpan='5' style={{ textAlign: 'center' }}>得点</Table.HeaderCell>
                  <Table.HeaderCell colSpan='5' style={{ textAlign: 'center' }}>100点換算</Table.HeaderCell>
                  <Table.HeaderCell rowSpan='2' style={{ textAlign: 'center' }}>作文評価</Table.HeaderCell>
                  <Table.HeaderCell rowSpan='2' style={{ textAlign: 'center' }}>会話評価</Table.HeaderCell>
                  <Table.HeaderCell rowSpan='2' style={{ textAlign: 'center' }}>係数</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  {/* <Table.Cell>科目名 日本語科</Table.Cell> */}
                  <Table.HeaderCell>文字語彙</Table.HeaderCell>
                  <Table.HeaderCell>聴解</Table.HeaderCell>
                  <Table.HeaderCell>文法</Table.HeaderCell>
                  <Table.HeaderCell>読解</Table.HeaderCell>
                  <Table.HeaderCell>合計</Table.HeaderCell>
                  {/* <Table.HeaderCell>作文</Table.HeaderCell> */}
                  {/* <Table.HeaderCell>会話</Table.HeaderCell> */}
                  {/* <Table.HeaderCell>進度</Table.HeaderCell> */}
                  {/* <Table.HeaderCell>科目名 日本語科</Table.HeaderCell> */}
                  <Table.HeaderCell>文字語彙</Table.HeaderCell>
                  <Table.HeaderCell>聴解</Table.HeaderCell>
                  <Table.HeaderCell>文法</Table.HeaderCell>
                  <Table.HeaderCell>読解</Table.HeaderCell>
                  <Table.HeaderCell>合計</Table.HeaderCell>
                  {/* <Table.HeaderCell>作文</Table.HeaderCell> */}
                  {/* <Table.HeaderCell>会話</Table.HeaderCell> */}
                  {/* <Table.HeaderCell>進度</Table.HeaderCell> */}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {/* {grade2 && grade2.length && !!grade2.filter(obj => obj.studentId === this.props.match.params._id && obj.testType === '2') ? */}
                {/* {grade2 && grade && grade.length && !!grade.filter(obj => obj.testType === '2') && doc.testSemester ? */}
                {grade2 ?
                  // grade.filter(obj => obj.testType === '2')
                  //   .map((itemG, index) => (
                  grade2
                    // grade2.filter(obj => obj.studentId === this.props.match.params._id
                    // && obj.gradeId === itemG._id && obj.testDate && obj.isDel === '0')
                    // && obj.testDate && obj.isDel === '0')
                    // && obj.semesterYear === doc.testSemester.substr(0, 4) && obj.semester === doc.testSemester.substr(-1))
                    .map((item, index) => (
                      <Table.Row key={`g2${index}`}>
                        <Table.Cell>{item.semesterYear && item.semesterYear.substr(-2)}{item.semester}</Table.Cell>
                        <Table.Cell>{item.testDate}</Table.Cell>
                        <Table.Cell>{item.testLevel}</Table.Cell>
                        {/* <Table.Cell>{item.resTotalScore ? this.rank(grade2.filter(obj => obj.gradeId === item.gradeId && obj.semester === item.semester && obj.semesterYear === item.semesterYear), item.resTotalScore) : '0'}/{grade2.filter(obj => obj.gradeId === item.gradeId && obj.studentObj.semester === item.studentObj.semester && obj.studentObj.semesterYear === item.studentObj.semesterYear).length}</Table.Cell> */}
                        <Table.Cell>{item.rank}/{item.rankTotal}</Table.Cell>
                        <Table.Cell>{item.vocabularyScore}</Table.Cell>
                        <Table.Cell>{item.listeningScore}</Table.Cell>
                        <Table.Cell>{item.grammarScore}</Table.Cell>
                        <Table.Cell>{item.readingScore}</Table.Cell>
                        <Table.Cell>{(Number(item.vocabularyScore) + Number(item.listeningScore) + Number(item.grammarScore) + Number(item.readingScore)).toString()}</Table.Cell>
                        <Table.Cell>{item.resVocabularyScore ? item.resVocabularyScore : '0'}</Table.Cell>
                        <Table.Cell>{item.resListeningScore ? item.resListeningScore : '0'}</Table.Cell>
                        <Table.Cell>{item.resGrammarScore ? item.resGrammarScore : '0'}</Table.Cell>
                        <Table.Cell>{item.resReadingScore ? item.resReadingScore : '0'}</Table.Cell>
                        <Table.Cell>{item.resTotalScore ? item.resTotalScore : '0'}</Table.Cell>
                        <Table.Cell>{item.writingScore}</Table.Cell>
                        <Table.Cell>{item.conversationScore}</Table.Cell>
                        <Table.Cell>{item.gpaAvg}</Table.Cell>
                      </Table.Row>
                    ))
                  // ))
                  : <Table.Row>
                    <Table.Cell colSpan='16' textAlign='center'>(資料無し)</Table.Cell>
                  </Table.Row>}
              </Table.Body>
            </Table>
          </Col>
        </Row>
        {/* </Grid> */}
        {/* <Grid> */}
        <br />
        <Row>
          <Col>
            <h4> EJU 日本留学試験</h4>
            <Table size="small" style={{ textAlign: 'center', tableLayout: "auto", width: '100%' }} >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell rowSpan='2' style={{ textAlign: 'center' }}>試験日</Table.HeaderCell>
                  <Table.HeaderCell colSpan='6' style={{ textAlign: 'center' }}>日本語</Table.HeaderCell>
                  <Table.HeaderCell colSpan='6' style={{ textAlign: 'center' }}>その他</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>聴解</Table.HeaderCell>
                  {/* <Table.HeaderCell>聴読解</Table.HeaderCell> */}
                  <Table.HeaderCell>読解</Table.HeaderCell>
                  <Table.HeaderCell>日本語合計</Table.HeaderCell>
                  <Table.HeaderCell>文法</Table.HeaderCell>
                  <Table.HeaderCell>論理</Table.HeaderCell>
                  <Table.HeaderCell>記述</Table.HeaderCell>
                  <Table.HeaderCell>総合科目</Table.HeaderCell>
                  <Table.HeaderCell>数学I</Table.HeaderCell>
                  <Table.HeaderCell>数学II</Table.HeaderCell>
                  <Table.HeaderCell>化学</Table.HeaderCell>
                  <Table.HeaderCell>物理</Table.HeaderCell>
                  <Table.HeaderCell>生物</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {EJU && EJU.length ? EJU.map((item, index) => (
                  <Table.Row key={`rot2${index}`}>
                    <Table.Cell>{item.testDate}</Table.Cell>
                    <Table.Cell>{item.jpListeningScore}</Table.Cell>
                    <Table.Cell>{item.jpReadingScore}</Table.Cell>
                    <Table.Cell>{item.jpTotalScore}</Table.Cell>
                    <Table.Cell>{item.jpGrammarScore}</Table.Cell>
                    <Table.Cell>{item.logicScore}</Table.Cell>
                    <Table.Cell>{item.totalDescription}</Table.Cell>
                    <Table.Cell>{item.generalScore}</Table.Cell>
                    <Table.Cell>{item.math1Score}</Table.Cell>
                    <Table.Cell>{item.math2Score}</Table.Cell>
                    <Table.Cell>{item.physicsScore}</Table.Cell>
                    <Table.Cell>{item.biologyScore}</Table.Cell>
                    <Table.Cell>{item.chemistryScore}</Table.Cell>
                  </Table.Row>
                ))
                  : <Table.Row>
                    <Table.Cell colSpan='13' textAlign='center'>(資料無し)</Table.Cell>
                  </Table.Row>}
              </Table.Body>
            </Table>
          </Col>
        </Row>
        {/* </Grid> */}
        {/* <Grid> */}
        <br />
        <Row>
          <Col>
            <h4>JLPT 日本語能力試験</h4>
            <Table size="small" style={{ textAlign: 'center', tableLayout: "auto", width: '100%' }} >
              <Table.Header>
                {/* <Table.Row>
                    <Table.Cell rowSpan='2' style={{ textAlign: 'center' }}>試験日日</Table.Cell>
                    <Table.Cell colSpan='7' style={{ textAlign: 'center' }}>日本語</Table.Cell>
                    <Table.Cell colSpan='6' style={{ textAlign: 'center' }}>その他</Table.Cell>
                  </Table.Row> */}
                <Table.Row>
                  <Table.HeaderCell>試験日</Table.HeaderCell>
                  <Table.HeaderCell>受験レべル</Table.HeaderCell>
                  <Table.HeaderCell>合否</Table.HeaderCell>
                  <Table.HeaderCell>文字語彙</Table.HeaderCell>
                  <Table.HeaderCell>文法</Table.HeaderCell>
                  <Table.HeaderCell>聴解</Table.HeaderCell>
                  <Table.HeaderCell>合計</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {/* {grade2 && grade2.length && !!grade2.filter(obj => obj.studentId === this.props.match.params._id && obj.testType === '3') ?
                  grade2.filter(obj => obj.studentId === this.props.match.params._id && obj.testType === '3' && obj.testLevel === '日本語能力試験結果')
                    .map((item, index) => ( */}
                {JLPT && JLPT.length ? JLPT.map((item, index) => (
                  <Table.Row key={`g3${index}`}>
                    <Table.Cell>{item.testDate}</Table.Cell>
                    <Table.Cell>{item.gradeText}</Table.Cell>
                    <Table.Cell>{item.isPassText}</Table.Cell>
                    {item.vocabularyScore45 && item.vocabularyScore45 !== '' ? <>
                      <Table.Cell>{item.vocabularyScore45}</Table.Cell>
                      <Table.Cell>/</Table.Cell></> : <>
                        <Table.Cell>{item.vocabularyScore}</Table.Cell>
                        <Table.Cell>{item.grammarScore}</Table.Cell></>}
                    <Table.Cell>{item.listeningScore}</Table.Cell>
                    <Table.Cell>{item.totalScore}</Table.Cell>
                  </Table.Row>
                ))
                  : <Table.Row>
                    <Table.Cell colSpan='7' textAlign='center'>(資料無し)</Table.Cell>
                  </Table.Row>}
              </Table.Body>
            </Table>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <h4>英語能力試験</h4>
            <Table size="small" style={{ textAlign: 'center', tableLayout: "auto", width: '100%' }} >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell style={{ textAlign: 'center' }}>試験日</Table.HeaderCell>
                  <Table.HeaderCell style={{ textAlign: 'center' }}>TOEIC</Table.HeaderCell>
                  <Table.HeaderCell style={{ textAlign: 'center' }}>TOEFL</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {ENG && ENG.length ? ENG.map((item, index) => (
                  <Table.Row key={`g4${index}`}>
                    <Table.Cell>{item.testDate}</Table.Cell>
                    <Table.Cell>{item.TOEIC}</Table.Cell>
                    <Table.Cell>{item.TOEFL}</Table.Cell>
                  </Table.Row>
                )) : <Table.Row>
                    <Table.Cell colSpan='3' textAlign='center'>(資料無し)</Table.Cell>
                  </Table.Row>}
              </Table.Body>
            </Table>
          </Col>
        </Row>
        {/* </Grid> */}
      </div>
    </>);
  }
}