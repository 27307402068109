import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { exportPdf } from 'views/web/CustomMaterialTable';


// import RowsDnd from './RowsDnd'

// reactstrap components
import { Row, Col, Form, FormGroup, Input, Label, Button } from "reactstrap";
// import CurStudentInfo from "./CurStudentInfo";

const columns = () => ([
  { width: 80, title: '班級', field: 'orderNum' },
  { width: 80, title: '測驗名稱', field: 'date' },
  { width: 80, title: '編號', field: 'userName' },
  { width: 80, title: '級數', field: 'money' },
  { width: 80, title: '科別', field: 'payWays' },
  { width: 80, title: '測驗日期', field: 'payWays' },

]);
const orderMoneyOpt = [
  { key: '1', value: '天', text: '天' },
  { key: '2', value: '週', text: '週' },
  { key: '3', value: '月', text: '月' }
]
class OrderMoneyStat extends Component {
  state = {
    activeItem: 'bio',
    dataArr: [],
    rowData: {},
    loading: true,
    orderMoney: '週'
  }

  async componentDidMount() {
    // const data1 = await Parse.queryData('testBank');
    // this.setState({ dataArr: data1, loading: false });
    this.setState({ loading: false })
  }

  // handleAdd = async () => {
  //   const { newData } = this.state;
  //   Parse.saveData('testBank', newData);
  // }

  // handleUpdate = async () => {
  //   const { newData } = this.state;
  //   Parse.saveData('testBank', newData);
  // }

  // handleDelete = async () => {
  //   const { oldData } = this.state
  //   Parse.deleteData('testBank', oldData);
  //   this.setState({ oldData: {} })
  // }

  // handleChange = (e, { name, value }) => {
  //   this.setState({ [name]: value })
  // }

  // handleItemClick = (e, { name }) =>
  //   this.setState({
  //     activeItem: name
  //   })

  // handleData = (rowData) => {
  //   this.setState({ rowData });
  // }

  render() {

    const { dataArr, rowData, loading, orderMoney } = this.state
    return (<div className="content">
      <Row>
        <Col><legend>2-2 歷史成績</legend></Col>
      </Row>
      {/* <Select name="orderMoney" style={{ position: 'relative', zIndex: 99 }} options={orderMoneyOpt} value={orderMoney} onChange={this.handleChange} /> */}
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `歷史成績`),
          pageSize: 10,
          search: false,
          tableLayout: 'fixed'
        }}
        data={dataArr}
        title="歷史成績"
        // actions={[
        //   {
        //     icon: 'view_headline',
        //     tooltip: '查看',
        //     onClick: (event, rowData) => this.handleData(rowData)
        //   }
        // ]}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const data = [...dataArr];
              const db = Parse.Object.extend("testBank");
              const newDoc = new db();
              const doc = await newDoc.save();
              newData.objectId = doc.id;
              newData.id = doc.id;
              newData._id = doc.id;

              data.push(newData);
              this.setState({ dataArr: data, newData }, () => resolve());
            }).then(() => this.handleAdd()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...dataArr];
              const index = data.indexOf(oldData);
              data[index] = newData;
              // handleState('people0', data);
              this.setState({ dataArr: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...dataArr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              // handleState('people0', data);
              this.setState({ dataArr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
      <br />
      <br />
    </div>
    );
  }
}

export default OrderMoneyStat;