import React, { Component } from 'react'
import MaterialTable from 'material-table'
import { Container, Header, Modal, Checkbox } from 'semantic-ui-react';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { monthSel, mgyearSel, exportPdf } from 'views/web/CustomMaterialTable';

// 1 月報表
// 結清日期	編號	借款日期	合件	姓名	主	 金額 	車號	 部份還本 	 結餘金額 	備註	身分證號碼	手機號	保人手機號
// const positionOpt = { '1': '店長', '2': '會計', '3': '櫃檯' }
const columns = (employeeObj = {}) => ([
  { width: 80, title: '年份', field: 'yearId', lookup: mgyearSel },
  { width: 80, title: '月份', field: 'month', lookup: monthSel },
  {
    width: 80, title: '底薪', field: 'baseSalary',
    render: rowData => rowData.baseSalary || 0,
    editComponent: props => employeeObj.baseSalary
  },
  {
    width: 20, title: '', field: 'fullAttendance_check',
    render: rowData => <></>,
    editComponent: props => <Checkbox onChange={(e, data) => {
      if (data.checked) {
        props.onRowDataChange({ ...props.rowData, fullAttendance: '2000' })
      } else {
        props.onRowDataChange({ ...props.rowData, fullAttendance: '0' })
      }
    }
    } />
  },
  { width: 80, title: '全勤', field: 'fullAttendance' },
  { width: 80, title: '工作獎金', field: 'workBonus' },
  {
    width: 20, title: '', field: 'foodStipend_check',
    render: rowData => <></>,
    editComponent: props => <Checkbox onChange={(e, data) => {
      if (data.checked) {
        props.onRowDataChange({ ...props.rowData, foodStipend: '1000' })
      } else {
        props.onRowDataChange({ ...props.rowData, foodStipend: '0' })
      }
    }
    } />
  },
  { width: 80, title: '伙食', field: 'foodStipend' },
  { width: 80, title: '佣金', field: 'commission', editable: 'never' },
  {
    width: 80, title: '資保息', field: 'capitalGuaranteed',
    render: rowData => rowData.capitalGuaranteed || 0,
    editComponent: props => <>{String(Number(employeeObj.marginTotal || 0) * Number(employeeObj.interest || 0) * 0.01)}</>
  },
  {
    width: 20, title: '', field: 'duanwuGift_check',
    render: rowData => <></>,
    editComponent: props => <Checkbox onChange={(e, data) => {
      if (data.checked) {
        props.onRowDataChange({ ...props.rowData, duanwuGift: '1000' })
      } else {
        props.onRowDataChange({ ...props.rowData, duanwuGift: '0' })
      }
    }
    } />
  },
  { width: 80, title: '油資', field: 'oilMoney' },
  { width: 80, title: '代墊', field: 'substitute', editable: 'never' },
  { width: 80, title: '合計', field: 'priceTotal', editable: 'never' },
]);
// const d = new Date()
export default class EmployeeTab4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataArr: [],
      rowData: {},
      loading: true
    }
    this.tableRef = React.createRef();
  }

  componentDidMount = async () => {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, employeeId } = props;
    const data = await Parse.queryData('salaryslip', { companyId: companyObj.objectId, employeeId });
    this.setState({ dataArr: data, loading: false });
  }

  handleAdd = async () => {
    const { newData } = this.state;

    Parse.saveData('salaryslip', newData);
  }

  handleUpdate = async () => {
    const { newData } = this.state;

    Parse.saveData('salaryslip', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    Parse.deleteData('salaryslip', oldData);
    this.setState({ oldData: {} })
  }
  render() {
    const { employeeId, employeeObj, companyObj } = this.props
    const { dataArr, loading } = this.state;
    // console.log(employeeId)
    return (<>
      <MaterialTable
        isLoading={loading}
        // isLoading={loading}
        localization={localization()}
        columns={columns(employeeObj)}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '薪資條'),
          pageSize: dataArr.length < 5 ? 5 : dataArr.length + 1,
          search: false,
          tableLayout: 'fixed',
        }}
        data={dataArr}
        tableRef={this.tableRef}
        title={`薪資條`}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const data = [...dataArr];
              const db = Parse.Object.extend("salaryslip");
              const newDoc = new db();
              const doc = await newDoc.save();

              let performanceTotal = 0
              let interestPlanSelfTotal = 0
              let interestPaidSelfTotal = 0
              let commissionTotal = 0
              const data1 = await Parse.queryData('pawning2', { jointArr: employeeObj.objectId, year: mgyearSel[newData.yearId], month: newData.month });
              console.log(data)
              data1.map(item => {
                const { jointObjArr, principalRest, interestPlan, interestPaid } = item;
                const jointObj = jointObjArr.find(item2 => item2.objectId === employeeObj.objectId)
                if (jointObj) {
                  const { proportion } = jointObj

                  const performance = principalRest ? Number(principalRest) * Number(proportion) / 100 : 0;
                  const interestPlanSelf = interestPlan ? Number(interestPlan) * Number(proportion) / 100 : 0;
                  const interestPaidSelf = interestPaid ? Number(interestPaid) * Number(proportion) / 100 : 0;
                  const commission = interestPaidSelf ? Number(interestPaidSelf) * Number(employeeObj.interest) / 100 : 0;

                  performanceTotal += performance;
                  interestPlanSelfTotal += interestPlanSelf;
                  interestPaidSelfTotal += interestPaidSelf;
                  commissionTotal += commission;

                  return { ...item, performance, interestPlanSelf, interestPaidSelf, commission }
                } else {
                  return item;
                }
              })
              const interestUnPaidSelfTotal = interestPlanSelfTotal - interestPaidSelfTotal;

              const obj = {
                ...newData,
                objectId: doc.id,
                id: doc.id,
                _id: doc.id,
                name: employeeObj.name,
                jobNum: employeeObj.jobNum,
                companyId: companyObj.objectId,
                employeeId: employeeId,
                baseSalary: String(employeeObj.baseSalary || 0),
                capitalGuaranteed: String(Number(employeeObj.marginTotal || 0) * Number(employeeObj.interest || 0) * 0.01),
                year: mgyearSel[newData.yearId],
                commission: String(commissionTotal || 0),
                substitute: String(interestUnPaidSelfTotal || 0),
                priceTotal: String(Number(employeeObj.baseSalary || 0) + Number(newData.fullAttendance || 0) + Number(newData.workBonus || 0) +
                  Number(newData.foodStipend || 0) + Number(newData.commissionTotal || 0) + Number(String(Number(employeeObj.marginTotal || 0) * Number(employeeObj.interest || 0) * 0.01) || 0)
                  + Number(commissionTotal || 0) + Number(newData.oilMoney || 0) - Number(interestUnPaidSelfTotal || 0))
              }
              data.push(obj);

              this.setState({ dataArr: data, newData: obj }, () => resolve());
            }).then(() => this.handleAdd()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...dataArr];
              const index = data.indexOf(oldData);

              data[index] = newData;
              console.log(newData)
              // let price = 0;
              // data[index].forEach(item => { // 計算total
              //   price += Number(item);
              // });
              // data[index][data.length - 1] = price; // 將計算完的結果assign '合計':'priceTotal'

              this.setState({ dataArr: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...dataArr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              this.setState({ dataArr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
    </>)
  }
}