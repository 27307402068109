
import React, { Component } from 'react'
import { Grid } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf, rowReOrder } from 'views/web/CustomMaterialTable';

// import RowsDnd from './RowsDnd'

// reactstrap components
// import {
//   Card,
//   CardHeader,
//   CardBody,
//   CardTitle,
//   Table,
//   Row,
//   Col
// } from "reactstrap";


const columns = () => ([
  { title: '順序', field: 'order', defaultSort: 'asc', type: 'numeric' },
  { width: 60, title: '預設', field: 'now_use', lookup: { '-1': '是', '0': '否' } },
  { title: '年份', field: 'value' },
]);

// const style = {
//   flex: {
//     display: 'flex'
//   },
//   flexCenter: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center'
//   }
// }

class Year extends Component {
  state = {
    annualArr: [],
    loading: false,
  }

  async componentDidMount() {
    // const { annualArr } = this.state
    const { companyObj } = this.props
    const data1 = await Parse.queryData('mgyear', { companyId: companyObj.objectId });
    this.setState({ annualArr: data1, loading: false });
  }

  handleAdd = async () => {
    const { newData1 } = this.state;
    await Parse.saveData('mgyear', newData1);
  }

  handleUpdate = async () => {
    const { newData1, oldData1, annualArr } = this.state;
    if (newData1.now_use !== oldData1.now_use && newData1.now_use === '-1') {
      annualArr.forEach((item, i) => Parse.saveData('mgyear', annualArr[i]));
    } else {
      await Parse.saveData('mgyear', newData1);
    }
  }

  handleDelete = async () => {
    const { oldData1 } = this.state
    await Parse.deleteData('mgyear', oldData1);
    this.setState({ oldData1: {} })
  }

  handleState = (dataArr, arrName = 'dataArr') => {
    this.setState({ [arrName]: dataArr })
  }

  render() {
    const { companyObj } = this.props
    const { annualArr, loading } = this.state;

    return (<div className="content">
      <br />
      <br />
      <Grid>
        <Grid.Row>
          <Grid.Column width={6} computer={6} tablet={16} mobile={16}>
            <MaterialTable
              isLoading={loading}
              localization={localization()}
              columns={columns()}
              options={{
                pageSize: (annualArr.length || 10),
                search: false,
              }}
              components={{
                Row: props => rowReOrder(props, 'mgyear', 'order', annualArr, 'annualArr', this.handleState)
              }}
              // components={{
              //   Toolbar: props => (
              //     <div>
              //       <div style={{ width: '130px', float: 'right', textAlign: 'right', padding: '15px' }}>
              //         <RowsDnd {...this.props} orderField='orderId' columns={columns()} dataArr={annualArr} handleReorder={this.handleReorder} />
              //       </div>
              //       <MTableToolbar {...props} />
              //       <div style={{ clear: 'both' }}></div>
              //     </div>
              //   ),
              // }}
              data={annualArr}
              title="年度清單"
              editable={{
                onRowAdd: newData1 =>
                  new Promise(async (resolve, reject) => {
                    const data = [...annualArr];
                    const db = Parse.Object.extend("mgyear");
                    const newDoc = new db();
                    const doc = await newDoc.save();
                    newData1.objectId = doc.id;
                    newData1.id = doc.id;
                    newData1._id = doc.id;
                    newData1.companyId = companyObj.id;
                    newData1.companyName = companyObj.name;
                    if (newData1.now_use === "-1") {
                      data.forEach((item, i) => data[i].now_use = '0');
                    }
                    data.push(newData1);
                    this.setState({ annualArr: data, newData1 }, () => resolve());
                  }).then(() => this.handleAdd()),
                onRowUpdate: (newData1, oldData1) =>
                  new Promise((resolve, reject) => {
                    const data = [...annualArr];
                    const index = data.indexOf(oldData1);
                    if (newData1.now_use === "-1") {
                      data.forEach((item, i) => data[i].now_use = '0');
                    }
                    data[index] = newData1;
                    // console.log(data1)
                    this.setState({ annualArr: data, newData1, oldData1 }, () => resolve());
                  }).then(() => this.handleUpdate()),
                onRowDelete: oldData1 =>
                  new Promise((resolve, reject) => {
                    let data = [...annualArr];
                    const index = data.indexOf(oldData1);
                    data.splice(index, 1);
                    this.setState({ annualArr: data, oldData1 }, () => resolve());
                  }).then(() => this.handleDelete()),
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>);
  }
}

export default Year;
