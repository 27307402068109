import React, { Component, } from 'react';
import { Form, Grid, Header, Card, Radio } from 'semantic-ui-react';
import { getOptionsByKey, } from 'views/widgets/FieldsRender';
import { Row, Col } from 'reactstrap';

const ProgressCheckbox = ({ name, radioValue, isEdit, onChangeRadio }) => (
  <Form key={name}>
    <Form.Field>
      <Radio
        label='催促中'
        name={name}
        // _id={studentId}
        value='1'
        checked={radioValue === '1'}
        onChange={onChangeRadio}
        disabled={!isEdit}
      />&nbsp;&nbsp;
      {/* </Form.Field>
    <Form.Field> */}
      <Radio
        label='修正中'
        name={name}
        // _id={studentId}
        value='2'
        checked={radioValue === '2'}
        onChange={onChangeRadio}
        disabled={!isEdit}
      />&nbsp;&nbsp;
      {/* </Form.Field>
    <Form.Field> */}
      <Radio
        label='完成'
        name={name}
        // _id={studentId}
        value='3'
        checked={radioValue === '3'}
        onChange={onChangeRadio}
        disabled={!isEdit}
      />
      <Radio
        label='無'
        name={name}
        // _id={studentId}
        value='-1'
        checked={radioValue === '-1'}
        onChange={onChangeRadio}
        disabled={!isEdit}
      />&nbsp;&nbsp;
    </Form.Field>
  </Form>);

export default class applyDocStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { optSet, isEdit, doc } = this.props;
    const { form, graduation, loading } = this.state;

    const applyDocStu = getOptionsByKey(optSet, 'applyDocStu');
    const applyDocStuArr = applyDocStu.sort((a, b) => a.orderId - b.orderId)
      .map(({ _id, value, label }) => ({ key: _id, value: value, text: label }))

    const applyDocOver = getOptionsByKey(optSet, 'applyDocOver');
    const applyDocOverArr = applyDocOver.sort((a, b) => a.orderId - b.orderId)
      .map(({ _id, value, label }) => ({ key: _id, value: value, text: label }))

    const applyDocJap = getOptionsByKey(optSet, 'applyDocJap');
    const applyDocJapArr = applyDocJap.sort((a, b) => a.orderId - b.orderId)
      .map(({ _id, value, label }) => ({ key: _id, value: value, text: label }))

    return (<>
      <Header as='h2' dividing
        content='書類状況'
        style={{ backgroundColor: 'lavender' }}
      />
      <Row>
        {/* <Grid columns='equal' divided> */}
        <Col>
          <Row>
            {/* <Grid columns='equal'> */}
            <Col>
              <Card fluid>
                <Card.Content header='本人提出書類' />
                <Card.Content>
                  {applyDocStuArr.map((item, index) =>
                    <Row key={index}>
                      {/* <Grid columns='equal'> */}
                      <Col >{item.text}</Col>
                      <Col ><ProgressCheckbox
                        // key={item._id}
                        // studentId={doc._id}
                        name={item.value}
                        radioValue={doc[item.value]}
                        isEdit={isEdit}
                        onChangeRadio={this.props.onChangeRadio}
                      /></Col>
                      {/* </Grid> */}
                    </Row>
                  )}
                </Card.Content>
              </Card>
            </Col>
            {/* </Grid> */}
          </Row>
        </Col>
        <Col>
          <Row>
            {/* <Grid columns='equal'> */}
            <Col>
              <Card fluid>
                <Card.Content header='海外在住経費支弁者' />
                <Card.Content>
                  {applyDocOverArr.map((item, index) =>
                    <Row key={index}>
                      {/* <Grid columns='equal'> */}
                      <Col>{item.text}</Col>
                      <Col><ProgressCheckbox
                        // key={item._id}
                        // studentId={doc._id}
                        name={item.value}
                        radioValue={doc[item.value]}
                        isEdit={isEdit}
                        onChangeRadio={this.props.onChangeRadio}
                      /></Col>
                      {/* </Grid> */}
                    </Row>
                  )}
                </Card.Content>
              </Card>
            </Col>
            {/* </Grid> */}
          </Row>
        </Col>
        <Col>
          <Row>
            {/* <Grid columns='equal'> */}
            <Col>
              <Card fluid>
                <Card.Content header='日本在住經費支弁者' />
                <Card.Content>
                  {applyDocJapArr.map((item, index) =>
                    <Row key={index}>
                      {/* <Grid columns='equal'> */}
                      <Col>{item.text}</Col>
                      <Col><ProgressCheckbox
                        // key={item._id}
                        // studentId={doc._id}
                        name={item.value}
                        radioValue={doc[item.value]}
                        isEdit={isEdit}
                        onChangeRadio={this.props.onChangeRadio}
                      /></Col>
                      {/* </Grid> */}
                    </Row>
                  )}
                </Card.Content>
              </Card>
            </Col>
            {/* </Grid> */}
          </Row>
        </Col>
        {/* </Grid> */}
      </Row >
    </>);
  }
}

