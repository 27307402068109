import React, { Component } from 'react'
import { Container, Icon, Table, Button, Header, Form, Pagination, Dropdown, Confirm, Label, Input, Select, TextArea, Grid, Divider } from 'semantic-ui-react'
import _ from 'lodash'
import { getLabelName2, getOptionsSelByKey, getOptionTextByValue } from 'views/widgets/FieldsRender';
import Parse from '../../../../widget/parse'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import MyModalTable from '../../widgets/MyModalTable';
// import ModalPdf from 'widget/pdf/ModalPdf';
// import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
const tenantTypeOpt = [
  { key: 'tenantType1', text: '在籍学生', value: '1' },
  { key: 'tenantType2', text: '其他', value: '2' },
]
const contractStatusOpt = [
  { key: 'contractStatus0', text: '尚未入住', value: '0' },
  { key: 'contractStatus1', text: '已入住', value: '1' },
  { key: 'contractStatus2', text: '已退租', value: '2' },
]

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipse: {
    // boxSizong: 'border-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}

export default class DormContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      author: '',
      confirmOpen: false,
      number: 0,
      classArr: [],
      selClassArr: [],
      classReport: [],
      show: false,
      form: {
        eventDate: this.getToday()
      },
      confirm: false,
      contractList: [],
    }
  }

  componentDidMount() {
    this.handleInitial()
    this.handleFetch();
  }
  // componentWillReceiveProps(nextProps) {
  //   // console.log('componentWillReceiveProps');
  //   this.handleFetch(nextProps);
  // }
  // getSnapshotBeforeUpdate(prevProps, prevState) {
  //   // console.log('getSnapshotBeforeUpdate');
  //   if (prevState.classSchool !== this.state.classSchool) {
  //     console.log('change');
  //     this.handleFetch(prevProps);
  //     return {};
  //   }
  //   return null
  // }
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   // console.log('componentDidUpdate');
  //   if (snapshot !== null) {
  //     this.handleFetch(prevProps);
  //   }
  // }
  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }
  handleInitial = (props = this.props) => {
    // console.log('componentWillReceiveProps');
    const { classroom, semesterYear, semester } = props;

    // console.log(selClassArr);
    // this.setState({ classArr, selClassArr/*, classId*/ })
    // , () => this.handleClassroom(props));

  }

  handleFetch = async (props = this.props) => {
    const { firebase, match, companyObj } = props;

    // firebase.firestore().collection(`Dormitory/${match.params._id}/Room/${match.params.roomid}/Contract`)
    //   .get().then(snapshot => {
    //     const contractData = []
    //     snapshot.forEach(fileItem => contractData.push({ _id: fileItem.id, ...fileItem.data() }));
    //     this.setState({ contractList: contractData })
    //   }).catch(error => {
    //     console.log(error);
    //   });
    const data = await Parse.queryData('Contract', { companyId: companyObj.objectId, dormId: match.params._id, roomId: match.params.roomid });
    this.setState({ contractList: data })


  }

  handleChange = (event, data) => {
    const { students } = this.props
    const { form } = this.state;
    const { name, value, options } = data
    if (name === 'studentData') {
      let obj = {}
      obj = students.find(item => item.objectId === value)
      this.setState({
        form: {
          ...form,
          studentNum: obj && obj.studentId ? obj.studentId : '',
          studentName: obj && obj.jpnName ? obj.jpnName : ''
        }
      }, () => console.log(form))
    } else {
      this.setState({ form: { ...this.state.form, [name]: value } })
      console.log(this.state.form);
    }
  }

  handleEdit = (contractObj) => {
    console.log(contractObj);
    this.setState({ show: true, form: { ...contractObj } });
  }

  handleOpen = (contractObj) => this.setState({ show: true, form: { eventDate: this.getToday() } });

  handleClose = () => this.setState({ show: false, form: { eventDate: this.getToday() } });

  showConfirm = (contractObj) => this.setState({ confirm: true, form: { ...contractObj } });

  hideConfirm = () => this.setState({ confirm: false, form: { eventDate: this.getToday() } });

  handleSubmit = async () => {
    const { firebase, dormObj, match, optSet, formSet } = this.props;
    const { form, contractList, classSchool, classId } = this.state;

    form.dormId = match.params._id;
    form.roomId = match.params.roomid;
    const db = firebase.firestore();
    for (const key in form) {
      const element = formSet.find(item => item.key === key);
      if (element && element.type === 'select') {
        form[`${key}Text`] = getOptionTextByValue(optSet, element.value, form[key]);
        // console.log("student method 508 ", element.value, form[key], getOptionTextByValue(optSet, element.value, form[key]));
      }
    }
    this.setState({ loading: true });
    const newRows = [...contractList];
    if (!form.objectId) {
      const docRef = db.collection(`Dormitory/${form.dormId}/Room/${form.roomId}/Contract`).doc();
      const obj = {
        ...form,
        objectId: docRef.id,
        createdAt: new Date()
      };
      // console.log(classId);
      // console.log(obj);
      await docRef.set(obj);
      newRows.push(obj);
    } else {
      const updObj = {
        ...form,
        updatedAt: new Date()
      }
      await db.collection(`Dormitory/${form.dormId}/Room/${form.roomId}/Contract`).doc(form._id).update(updObj);
      let index = newRows.findIndex((item => item._id === form._id));
      newRows[index] = form;
    }

    this.setState({ contractList: newRows, loading: false });
    this.handleClose();
    this.handleFetch();
  }

  handleRemove = async () => {
    const { firebase } = this.props
    const { form, classId, contractList } = this.state;
    await firebase.firestore().collection(`Dormitory/${form.dormId}/Room/${form.roomId}/Contract`).doc(form._id).delete();
    const newRows = [...contractList];
    let index = newRows.findIndex((item => item._id === form._id));
    newRows.splice(index, 1);
    this.setState({ contractList: newRows });

    this.handleClose();
    this.hideConfirm();
    this.handleFetch();
  }

  getToday = () => {
    return new Date().toISOString().split('T')[0];
  };

  render() {
    const { firebase, students = [], roomObj, companyObj } = this.props;
    const { form, loading, contractList, bedNum } = this.state
    const stuArr = students.sort((a, b) => a.studentId - b.studentId).map(item => ({ key: item.objectId, value: item.objectId, text: `${item.studentId} ${item.jpnName || item.homeName}` }))
    const stuOpt = [
      { key: 'stuOptNull', value: ' ', text: '-- 選択 --' },
      ...stuArr
    ]
    let bedNumOpt = new Array(Number(roomObj.roomType || '')).fill().map((item, i) => ({ key: i, text: `床位${i + 1}`, value: i + 1 }))
    // const classPdf = contractList.filter(item => item.contractData.length > 0).map(item => item.classData)
    return (
      <div>
        <div style={{ border: '1px solid #d4d4d5', padding: '1em 1em', background: '#FFFFFB' }}>
          <div style={style.flexCenter}>
            <Header as='h2' content='合約一覽' />
            {/* {isEdit ? <Button inverted={companyObj.inverted} color='blue' icon='save' content='保存' onClick={this.handleSubmit} loading={loading} /> : null} */}
            <Button color='blue' inverted={companyObj.inverted} icon='add' content='追加' size='small' onClick={this.handleOpen} style={{ marginLeft: 15 }} />
          </div>
          <Divider />
          <Table id='excel' celled structured style={{ marginBottom: 25 }} textAlign='center'>
            <Table.Header>
              <Table.Row textAlign='center'>
                <Table.HeaderCell width={2}>床位</Table.HeaderCell>
                <Table.HeaderCell width={4}>学籍番号</Table.HeaderCell>
                <Table.HeaderCell width={4}>合約開始</Table.HeaderCell>
                <Table.HeaderCell width={2}>每月租金</Table.HeaderCell>
                <Table.HeaderCell rowSpan='2' width={2} style={{ minWidth: 75 }}>操作</Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell width={2}>合約狀態</Table.HeaderCell>
                <Table.HeaderCell width={4}>日本語氏名</Table.HeaderCell>
                <Table.HeaderCell width={4}>合約結束</Table.HeaderCell>
                <Table.HeaderCell width={2}>簽約日付</Table.HeaderCell>
                {/* <Table.HeaderCell colSpan='2' textAlign='center'>担任連絡事項</Table.HeaderCell>
                <Table.HeaderCell colSpan='2' textAlign='center'>校方指示</Table.HeaderCell> */}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {roomObj ? bedNumOpt.map(({ value }, index) => <React.Fragment key={`tr${index}`}>
                {contractList && contractList.length ? contractList.filter(contract => contract.bedNum === value)
                  .map((item1, index1) => <React.Fragment key={`trr${index1}`}>
                    <Table.Row>
                      <Table.Cell width={2}>床位{item1.bedNum}</Table.Cell>
                      <Table.Cell width={4}>{item1.studentNum}</Table.Cell>
                      <Table.Cell width={4}>{item1.contractStart}</Table.Cell>
                      <Table.Cell width={2}>{item1.rent}</Table.Cell>
                      <Table.Cell width={2} textAlign='center' rowSpan='2'>
                        {/* <Button inverted={companyObj.inverted} color='green' size='mini' onClick={this.handleOpen}>詳細</Button> */}
                        <Button style={{ margin: 5 }} inverted={companyObj.inverted} color='green' size='mini' icon='edit' onClick={() => this.handleEdit(item1)} />
                        <Button style={{ margin: 5 }} inverted={companyObj.inverted} color='red' size='mini' icon='delete' onClick={() => this.showConfirm(item1)} />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign='center'>
                        <div style={{ maxWidth: '100%', maxHeight: '76px', overflowY: 'auto', wordBreak: 'break-all' }}>{item1.contractStatusText}</div>
                      </Table.Cell>
                      <Table.Cell textAlign='center'>
                        <div style={{ maxWidth: '100%', maxHeight: '76px', overflowY: 'auto', wordBreak: 'break-all' }}>{item1.studentName}</div>
                      </Table.Cell>
                      <Table.Cell textAlign='center'>
                        <div style={{ maxWidth: '100%', maxHeight: '76px', overflowY: 'auto', wordBreak: 'break-all' }}>{item1.contractEnd}</div>
                      </Table.Cell>
                      <Table.Cell textAlign='center'>
                        <div style={{ maxWidth: '100%', maxHeight: '76px', overflowY: 'auto', wordBreak: 'break-all' }}>{item1.contractSign}</div>
                      </Table.Cell>
                    </Table.Row>
                  </React.Fragment>) : null}
              </React.Fragment>) : null}
              {/* {contractList && contractList.length ? contractList.every(item => !item.contractData.length) ? <Table.Row><Table.Cell rowSpan='2' colSpan='5' textAlign='center'>(資料無し)</Table.Cell></Table.Row> : null : null} */}
            </Table.Body>
          </Table>

          {/* <div style={{ ...style.flexCenter, minWidth: 220 }}>
              <ExportTableToExcelButton size='small' />
              <ModalPdf
                {...this.props}
                // ref={(ref) => { this.pdfModal = ref }}
                codekey="classReportPdf"
                params={classPdf[0]}
              />
            </div> */}
        </div>

        {/* <Modal open={this.state.show} onClose={this.handleClose}> */}
        <Modal
          isOpen={this.state.show}
          toggle={this.handleClose}
          size="lg"
          // className="mh-120 h-90 mw-120 w-90"
          style={{ paddingTop: '0px' }}
          scrollable={true}
        >
          <ModalHeader className="justify-content-center">
            <h2 style={{ margin: 0 }}>{form.objectId ? '修正' : '追加'}報告</h2>
            <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
          </ModalHeader>
          <ModalBody style={{ padding: "20px 30px" }}>
            <Form>
              <Grid>
                <Grid.Row columns='equal'>
                  <Grid.Column>
                    <Form.Select
                      label='床位'
                      options={bedNumOpt}
                      name='bedNum'
                      onChange={this.handleChange}
                      defaultValue={form.bedNum || ''} />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Select
                      label='合約狀態'
                      options={contractStatusOpt}
                      name='contractStatus'
                      onChange={this.handleChange}
                      defaultValue={form.contractStatus || ''} />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      label='簽約日付'
                      type='date'
                      name='contractSign'
                      onChange={this.handleChange}
                      value={form.contractSign || ''}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                  <Grid.Column>
                    <Form.Select
                      label='房客類型'
                      options={tenantTypeOpt}
                      name='tenantType'
                      defaultValue={form.tenantType || '2'}
                      onChange={this.handleChange} />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Select
                      disabled={form.tenantType !== '1'}
                      label='在籍学生' search
                      options={stuOpt}
                      name='studentData'
                      onChange={this.handleChange}
                      defaultValue={' '} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                  <Grid.Column>
                    <Form.Input
                      label='学籍番号'
                      name='studentNum'
                      onChange={this.handleChange}
                      value={form.studentNum || ''} />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      label='日本語氏名'
                      name='studentName'
                      onChange={this.handleChange}
                      value={form.studentName || ''} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                  <Grid.Column>
                    <Form.Input
                      label='合約開始'
                      type='date'
                      name='contractStart'
                      onChange={this.handleChange}
                      value={form.contractStart || ''}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      label='合約結束'
                      type='date'
                      name='contractEnd'
                      onChange={this.handleChange}
                      value={form.contractEnd || ''}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      label='每月租金'
                      name='rent'
                      onChange={this.handleChange}
                      value={form.rent || ''}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </ModalBody>
          <ModalFooter style={{ padding: "20px 30px" }}>
            {form.objectId ? <Button color='green' content='保存' icon='save' onClick={this.handleSubmit} loading={loading} />
              : <Button color='green' content='追加' icon='add' onClick={this.handleSubmit} loading={loading} />}
            <Button basic content='キャンセル' icon='close' onClick={this.handleClose} />
          </ModalFooter>
        </Modal>
        <Confirm
          header='削除'
          content='削除してもいいですか?'
          cancelButton='いいえ'
          confirmButton='はい'
          open={this.state.confirm}
          onCancel={this.hideConfirm}
          onConfirm={this.handleRemove}
          size='mini'
          centered={false} />
      </div>
    )
  }
}