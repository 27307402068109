
import React, { Component } from 'react'
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from 'react-redux';

import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer2 from "components/Footer/Footer2.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import routes from "routes.js";

// import { getUser } from "widget/parse";

var ps;
const getStyle = (companyObj) => {
  let backgroundImage = '';
  let backgroundRepeat = '';
  let backgroundSize = '';
  let backgroundColor = companyObj.bgColor || '#f4f3ef';

  if (companyObj.img3File) {
    backgroundImage = `url(${companyObj.img3File.url})`;
    backgroundRepeat = 'no-repeat';
    if (companyObj.bgCover) {
      // backgroundSize = 'cover';
    } else {
      backgroundSize = '';
    }
  } else {
    backgroundImage = ``;
  }

  return {
    backgroundImage,
    backgroundRepeat,
    backgroundSize,
    backgroundColor
  }
}

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
      menuFontSize: '16',
    };
  }
  componentDidMount = async () => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }

    const { history, user } = this.props
    if (!user.isAuthenticated) {
      // window.location.href = '/auth/login';
      history.push('/auth/login')
    }

    // setBodyStyle(companyObj)
  }

  componentWillReceiveProps(nextProps) {
    const { history, user } = nextProps
    if (!user.isAuthenticated) {
      // window.location.href = '/auth/login';
      history.push('/auth/login')
    }

    // setBodyStyle(companyObj)
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        // console.log(prop.layout + prop.path + (prop.params || ''))
        return (
          <Route
            // exact
            key={key}
            path={prop.layout + prop.path + (prop.params || '')}
            // component={prop.component}
            render={props => (
              React.createElement((prop.component), { ...this.props, ...props })
            )}
          />
        );
      } else {
        return null;
      }
    });
  };

  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };

  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };

  handleFontSizeChange = (menuFontSize) => {
    this.setState({ menuFontSize });
  };

  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  render() {
    const { companyObj, user } = this.props
    const { backgroundColor, activeColor, sidebarMini, menuFontSize } = this.state
    // console.log(companyObj.img3)

    // console.log(user.profile)
    const profile = (user.isAuthenticated && user.profile && user.profile.toJSON()) || {};

    // console.log(css)
    return (!user.isAuthenticated && !companyObj.objectId ? null :
      <div className="wrapper">
        <Sidebar
          {...this.props}
          profile={profile}
          routes={routes}
          bgColor={backgroundColor}
          activeColor={activeColor}
          menuFontSize={menuFontSize}
        />
        <div className="main-panel" ref="mainPanel" style={getStyle(companyObj)}>
          <AdminNavbar
            {...this.props}
            profile={profile}
            handleMiniClick={this.handleMiniClick}
          />
          <Switch>
            {this.getRoutes(routes)}
            {/* <Redirect to="/admin/dashboard" /> */}
          </Switch>
          {// we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
              <Footer2 fluid />
            )}
        </div>
        <FixedPlugin
          bgColor={backgroundColor}
          activeColor={activeColor}
          sidebarMini={sidebarMini}
          menuFontSize={menuFontSize}
          handleActiveClick={this.handleActiveClick}
          handleBgClick={this.handleBgClick}
          handleMiniClick={this.handleMiniClick}
          handleFontSizeChange={this.handleFontSizeChange}
        />
      </div>
    );
  }
}

// export default Admin;

// const StyledAdmin = styled(Admin)`
//     ${vars}
//     /** Additional customization if necessary (e.g. positioning) */
// `
// export default StyledAdmin;

const mapStateToProps = state => ({
  user: state.user,
  errors: state.errors
})

export default connect(mapStateToProps, null)(Admin)
