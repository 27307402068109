import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import { Button, FormGroup, Container, Row, Col } from "reactstrap";

// core components

function SectionFooterAreas(props = {}) {
  const { form, companyObj } = props;
  // console.log(form)
  const { text2 = 'text2', text3 = 'text3', text4 = 'text4', text5 = 'text5', text6 = 'text6',
    text7 = 'text7', text8 = 'text8', text9 = 'text9', text10 = 'text10', text11 = 'text11', text12 = 'text12',
    text13 = 'text13', text14 = 'text14', text15 = 'text15', text16 = 'text16', text17 = 'text17' } = form;
  const [languageSelect, setLanguageSelect] = React.useState({
    value: "en",
    label: "English"
  });
  const [curencySelect, setCurencySelect] = React.useState({
    value: "USD",
    label: "USD"
  });
  return (
    <>
      <div className="separator" />
      <footer className="footer footer-black footer-big">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="4" sm="4">
              <div className="logo text-center">
                <h3>{text2}</h3>
              </div>
            </Col>
            <Col className="offset-md-2" md="6" sm="8">
              <div className="links">
                <ul>
                  <li className="mr-1">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      {text3}
                    </a>
                  </li>
                  <li className="mr-1">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      {text4}
                    </a>
                  </li>
                  <li className="mr-1">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      {text5}
                    </a>
                  </li>
                  <li className="mr-1">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      {text6}
                    </a>
                  </li>
                  <li className="mr-1">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      {text7}
                    </a>
                  </li>
                  <li>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      {text8}
                    </a>
                  </li>
                </ul>
                <hr />
                <div className="copyright">
                  <div className="pull-left">
                    © {new Date().getFullYear()}, made with{" "}
                    <i className="fa fa-heart heart" /> {companyObj.name}
                  </div>
                  <div className="pull-right">
                    <ul>
                      <li className="mr-1">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          {text9}
                        </a>
                      </li>
                      |{" "}
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          {text10}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default SectionFooterAreas;
