import { commaToSpace, cutStrToArr, cutStrToArr_1, cutStrToArr2, toStraight, a4pageWidth, Blength, strChtEngMix2 } from './defPdf';

const longest = (arr) => arr.reduce((a, b) => Blength(a) > Blength(b) ? a : b);

export const calSizeTopmiddle = (arr) => { // 1x2
  const max = Blength(longest(arr)) || 0;
  // console.log('calSizeTopmiddle', arr, max);

  let size = 26;
  let addTopMiddle = 0;
  if (max >= 65) { size = 11; }
  else if (max >= 59) { size = 12; }
  else if (max >= 50) { size = 13; }
  else if (max >= 45) { size = 16; }
  else if (max >= 39) { size = 18; }
  else if (max >= 35) {
    size = 20;
    if (arr.length > 5) {
      size -= 3;
    }
  }
  else if (max >= 24) { size = 21; }
  else if (max >= 19) { size = 24; addTopMiddle = 15; }
  else { addTopMiddle = 35; }

  if (arr.length === 1 && max < 20) {
    size = 32
  }
  return { size, addTopMiddle }
}

export const calSizeTopmiddleSmall = (arr) => { // 1x4
  const max = Blength(longest(arr)) || 0;
  // console.log('calSizeTopmiddleSmall', arr, max);

  let size = 22;
  let addTopMiddle = 0;
  if (max > 70) { size = 6; }
  else if (max > 40) { size = 11; }
  else if (max > 30) { size = 18; }
  else if (max > 20) { size = 20; addTopMiddle = 15; }
  else { addTopMiddle = 15; }

  if (arr.length >= 15) { size = 5.3; }
  else if (arr.length >= 11) { size = 7; }

  return { size, addTopMiddle }
}

export const calSizeTopmiddleBig = (arr) => { // a4一張一個牌位用
  const max = Blength(longest(arr)) || 0;
  // console.log('calSizeTopmiddleBig', arr, max);

  let size = 36;
  let addTopMiddle = 0;
  if (max >= 65) { size = 11; }
  else if (max >= 59) { size = 13; }
  else if (max >= 50) { size = 14; }
  else if (max >= 45) { size = 16; }
  // else if (max >= 39) { size = 18; }
  else if (max >= 32) { size = 17; }
  else if (max >= 28) { size = 21; }
  else if (max >= 24) { size = 25; }
  else if (max > 16) { size = 27; }
  // else if (max >= 10) { size = 30; }
  else { addTopMiddle = 40; }

  if (arr.length === 1 && max < 20) {
    size = 32
  } else if (arr.length > 5) {
    size -= 2;
  }
  // console.log("calSizeTopmiddle", arr, size)
  return { size, addTopMiddle }
}

export const getArrMiddle2_18ab = (entry2 = {}, cut = 13) => {
  const arrMiddle2 = [];
  // let entry2 = entry.entry;
  if (entry2.passname1) {
    arrMiddle2.push(commaToSpace(entry2.passname1));
  }
  if (entry2.passname) {
    let CUTNUM = 9;
    let passname = commaToSpace(entry2.passname);
    if (entry2.passname.length <= CUTNUM) {
      passname = cutStrToArr(passname, 10);
    } else {
      if (arrMiddle2.length === 1) {
        CUTNUM = 12;
        if (entry2.passname.length <= CUTNUM) {
          passname = cutStrToArr(passname, cut);
        } else if (entry2.passname.length > 2 * CUTNUM) {
          passname = cutStrToArr(passname, cut);
        } else {
          passname = cutStrToArr(passname, cut);
        }
      } else {
        passname = cutStrToArr(passname, cut);
      }
    }
    passname.forEach(item => arrMiddle2.push("  " + item));
  }

  if (entry2.passname2) {
    let CUTNUM = 9;
    let passname2 = commaToSpace(entry2.passname2);
    if (entry2.passname2.length <= CUTNUM) {
      passname2 = cutStrToArr_1(passname2, cut);
    } else {
      if (arrMiddle2.length === 2) {
        CUTNUM = 12;
        passname2 = cutStrToArr_1(passname2, cut);
      } else if (arrMiddle2.length === 3) {
        CUTNUM = 20;
        passname2 = cutStrToArr_1(passname2, cut + 2);
      } else if (arrMiddle2.length >= 4) {
        CUTNUM = 20;
        passname2 = cutStrToArr_1(passname2, cut + 8);
      } else {
        passname2 = cutStrToArr_1(passname2, cut);
      }
    }
    // console.log(passname2.length)
    for (let i = 0; i < passname2.length; i++) {
      if (i === passname2.length - 1) {
        arrMiddle2.push("  " + passname2[i] + "之冤親債主");
      } else {
        arrMiddle2.push("  " + passname2[i] + "、");
      }
    }
  }
  if (entry2.passname4) {
    let CUTNUM = 9;
    let passname4 = commaToSpace(entry2.passname4);
    if (entry2.passname4.length <= CUTNUM) {
      passname4 = cutStrToArr_1(passname4, 10);
    } else if (arrMiddle2.length >= 4) {
      CUTNUM = 24;
      passname4 = cutStrToArr_1(passname4, 24);
    } else {
      passname4 = cutStrToArr_1(passname4, 11);
    }
    for (let i = 0; i < passname4.length; i++) {
      if (i === passname4.length - 1) {
        arrMiddle2.push("  " + passname4[i] + "之嬰靈");
      } else {
        arrMiddle2.push("  " + passname4[i] + "、");
      }
    }
  }
  if (entry2.passname3) {
    const passname3 = entry2.passname3 && entry2.passname3.replace(/\d+/g, " $&").split(",");
    for (let i = 0; i < passname3.length; i++) {
      if (i === passname3.length - 1) {
        arrMiddle2.push("  " + passname3[i] + "之地基主");
      } else {
        arrMiddle2.push("  " + passname3[i] + "、");
      }
    }
  }
  return arrMiddle2;
}

export const printMiddleBig = (doc, arrMiddle, topMiddle) => {
  let { size, addTopMiddle } = calSizeTopmiddleBig(arrMiddle);
  topMiddle += addTopMiddle
  doc.setFontSize(size);

  const middle = Math.floor(arrMiddle.length / 2);
  console.log(arrMiddle)
  if (arrMiddle.length === 1) {
    doc.text(toStraight(arrMiddle[0]), 284, topMiddle);
  } else if (arrMiddle.length === 2) {
    doc.text(toStraight(arrMiddle[0]), 254, topMiddle);
    doc.text(toStraight(arrMiddle[1]), 314, topMiddle);
  } else if (arrMiddle.length === 3) {
    doc.text(toStraight(arrMiddle[1]), 244, topMiddle);
    doc.text(toStraight(arrMiddle[0]), 284, topMiddle);
    doc.text(toStraight(arrMiddle[2]), 324, topMiddle);
  } else if (arrMiddle.length === 4) {
    doc.text(toStraight(arrMiddle[2]), 224, topMiddle);
    doc.text(toStraight(arrMiddle[0]), 264, topMiddle);
    doc.text(toStraight(arrMiddle[1]), 304, topMiddle);
    doc.text(toStraight(arrMiddle[3]), 344, topMiddle);
  } else if (arrMiddle.length >= 5) {
    if (arrMiddle.length % 2 === 0) {
      for (let i = 0; i < middle; i++) {
        strChtEngMix2(doc, toStraight(arrMiddle[(middle - 1) - i]), (a4pageWidth / 2 + 0 * size) + (i * size), topMiddle, size);
        strChtEngMix2(doc, toStraight(arrMiddle[middle + i]), (a4pageWidth / 2 - 1 * size) - (i * size), topMiddle, size);
      }
    } else if (arrMiddle.length % 2 === 1) {
      for (let i = 0; i <= middle; i++) {
        if (i === 0) {
          doc.text(toStraight(arrMiddle[middle - i]), (a4pageWidth / 2 - 0.5 * size) + (i * size), topMiddle);
        } else {
          if (arrMiddle[middle - i]) {
            doc.text(toStraight(arrMiddle[middle - i]), (a4pageWidth / 2 - 0.5 * size) + (i * size), topMiddle);
          }
          if (arrMiddle[middle + i]) {
            doc.text(toStraight(arrMiddle[middle + i]), (a4pageWidth / 2 - 0.5 * size) - (i * size), topMiddle);
          }
        }
      }
    }
  }
  // console.log(arrMiddle, size)
}

export const printMiddleSmall = (doc, arrMiddle, topMiddle, x_middle, isSmall) => {
  let { size, addTopMiddle } = isSmall ? calSizeTopmiddleSmall(arrMiddle) : calSizeTopmiddle(arrMiddle);

  topMiddle += addTopMiddle;
  doc.setFontSize(size);

  if (arrMiddle.length === 1) {
    doc.text(toStraight(arrMiddle[0]), x_middle - size / 2, topMiddle);
  } else if (arrMiddle.length === 2) {
    for (let i = 0; i < arrMiddle.length; i++) {
      if (i % 2 === 0) {
        doc.text(toStraight(arrMiddle[i]), x_middle + Math.floor(i / 2) * size, topMiddle + 40);
      } else {
        doc.text(toStraight(arrMiddle[i]), x_middle - (Math.floor(i / 2) + 1) * size, topMiddle + 40);
      }
    }
  } else if (arrMiddle.length === 3) {
    for (let i = 0; i < arrMiddle.length; i++) {
      if (i % 2 === 0) {
        doc.text(toStraight(arrMiddle[i]), (x_middle - size / 2) - (i * size / 2), topMiddle);
      } else {
        doc.text(toStraight(arrMiddle[i]), x_middle + (i * size / 2), topMiddle);
      }
    }
  } else if (arrMiddle.length === 4) {
    for (let i = 0; i < arrMiddle.length; i++) {
      if (i % 2 === 0) {
        doc.text(toStraight(arrMiddle[i]), x_middle + Math.floor(i / 2) * size, topMiddle);
      } else {
        doc.text(toStraight(arrMiddle[i]), x_middle - (Math.floor(i / 2) + 1) * size, topMiddle);
      }
    }
  } else if (arrMiddle.length >= 5) {
    if (arrMiddle.length % 2 === 1) {
      for (let i = 0; i < arrMiddle.length; i++) {
        if (i % 2 === 0) {
          doc.text(toStraight(arrMiddle[i]), (x_middle - size / 2) - (i * size / 2), topMiddle + 25);
        } else {
          doc.text(toStraight(arrMiddle[i]), x_middle + (i * size / 2), topMiddle + 25);
        }
      }
    } else {
      for (let i = 0; i < arrMiddle.length; i++) {
        if (i % 2 === 0) {
          doc.text(toStraight(arrMiddle[i]), x_middle + Math.floor(i / 2) * size, topMiddle);
        } else {
          doc.text(toStraight(arrMiddle[i]), x_middle - (Math.floor(i / 2) + 1) * size, topMiddle);
        }
      }
    }
  }
}

export const printMiddle2Small = (doc, arrMiddle2, topMiddle, x_middle, calSize = 1) => {
  let { size, addTopMiddle } = calSize === 1 ? calSizeTopmiddleSmall(arrMiddle2) : calSize === 2 ? calSizeTopmiddle(arrMiddle2) : calSizeTopmiddleBig(arrMiddle2);
  topMiddle += addTopMiddle;
  doc.setFontSize(size);
  const middle = Math.floor(arrMiddle2.length / 2);

  if (arrMiddle2.length === 1) {
    strChtEngMix2(doc, toStraight(arrMiddle2[0]), x_middle - size / 2, topMiddle, size);
  } else if (arrMiddle2.length === 2) {
    for (let i = 0; i < middle; i++) {
      strChtEngMix2(doc, toStraight(arrMiddle2[(middle - 1) - i]), (x_middle + 0.25 * size) + (i * 1.5 * size), topMiddle, size);
      strChtEngMix2(doc, toStraight(arrMiddle2[middle + i]), (x_middle - 1.25 * size) - (i * 1.5 * size), topMiddle, size);
    }
  } else if (arrMiddle2.length === 3) {
    for (let i = 0; i <= middle; i++) {
      if (i === 0) {
        strChtEngMix2(doc, toStraight(arrMiddle2[middle - i]), (x_middle - 0.5 * size) + i * 1.5 * size, topMiddle, size);
      } else {
        strChtEngMix2(doc, toStraight(arrMiddle2[middle + i]), (x_middle - 0.5 * size) - i * 1.5 * size, topMiddle, size);
        strChtEngMix2(doc, toStraight(arrMiddle2[middle - i]), (x_middle - 0.5 * size) + i * 1.5 * size, topMiddle, size);
      }
    }
  } else if (arrMiddle2.length === 4) {
    for (let i = 0; i < middle; i++) {
      strChtEngMix2(doc, toStraight(arrMiddle2[(middle - 1) - i]), (x_middle + 0.25 * size) + (i * 1.5 * size), topMiddle, size);
      strChtEngMix2(doc, toStraight(arrMiddle2[middle + i]), (x_middle - 1.25 * size) - (i * 1.5 * size), topMiddle, size);
    }
  } else if (arrMiddle2.length === 5) {
    for (let i = 0; i <= middle; i++) {
      if (i === 0) {
        strChtEngMix2(doc, toStraight(arrMiddle2[middle - i]), (x_middle - 0.5 * size) + i * 1.5 * size, topMiddle, size);
      } else {
        strChtEngMix2(doc, toStraight(arrMiddle2[middle + i]), (x_middle - 0.5 * size) - i * 1.5 * size, topMiddle, size);
        strChtEngMix2(doc, toStraight(arrMiddle2[middle - i]), (x_middle - 0.5 * size) + i * 1.5 * size, topMiddle, size);
      }
    }
  } else if (arrMiddle2.length === 6) {
    for (let i = 0; i < middle; i++) {
      strChtEngMix2(doc, toStraight(arrMiddle2[(middle - 1) - i]), (x_middle + 0.25 * size) + (i * 1.5 * size), topMiddle, size);
      strChtEngMix2(doc, toStraight(arrMiddle2[middle + i]), (x_middle - 1.25 * size) - (i * 1.5 * size), topMiddle, size);
    }
  } else if (arrMiddle2.length === 7) {
    for (let i = 0; i <= middle; i++) {
      if (i === 0) {
        strChtEngMix2(doc, toStraight(arrMiddle2[middle - i]), (x_middle - 0.5 * size) + i * 1.5 * size, topMiddle, size);
      } else {
        strChtEngMix2(doc, toStraight(arrMiddle2[middle + i]), (x_middle - 0.5 * size) - i * 1.5 * size, topMiddle, size);
        strChtEngMix2(doc, toStraight(arrMiddle2[middle - i]), (x_middle - 0.5 * size) + i * 1.5 * size, topMiddle, size);
      }
    }
  } else if (arrMiddle2.length === 8) {
    for (let i = 0; i < middle; i++) {
      strChtEngMix2(doc, toStraight(arrMiddle2[(middle - 1) - i]), (x_middle + 0.25 * size) + (i * 1.5 * size), topMiddle, size);
      strChtEngMix2(doc, toStraight(arrMiddle2[middle + i]), (x_middle - 1.25 * size) - (i * 1.5 * size), topMiddle, size);
    }
  } else if (arrMiddle2.length === 9) {
    for (let i = 0; i <= middle; i++) {
      if (i === 0) {
        strChtEngMix2(doc, toStraight(arrMiddle2[middle - i]), (x_middle - 0.5 * size) + i * 1.5 * size, topMiddle, size);
      } else {
        strChtEngMix2(doc, toStraight(arrMiddle2[middle + i]), (x_middle - 0.5 * size) - i * 1.5 * size, topMiddle, size);
        strChtEngMix2(doc, toStraight(arrMiddle2[middle - i]), (x_middle - 0.5 * size) + i * 1.5 * size, topMiddle, size);
      }
    }
  } else if (arrMiddle2.length >= 10) {
    // size = 9.5;
    if (arrMiddle2.length === 11) { size = 9; }
    else if (arrMiddle2.length === 12) { size = 8; }
    else if (arrMiddle2.length === 13) { size = 8; }
    else if (arrMiddle2.length === 14) { size = 7; }
    else if (arrMiddle2.length === 15) { size = 5.3; }

    if (arrMiddle2.length % 2 === 0) {// 偶數行
      for (let i = 0; i < middle; i++) {
        strChtEngMix2(doc, toStraight(arrMiddle2[(middle - 1) - i]), (x_middle + 0.25 * size) + (i * 1.5 * size), topMiddle, size);
        strChtEngMix2(doc, toStraight(arrMiddle2[middle + i]), (x_middle - 1.25 * size) - (i * 1.5 * size), topMiddle, size);
      }
    } else if (arrMiddle2.length % 2 === 1) { // 奇數行
      for (let i = 0; i <= middle; i++) {
        if (i === 0) {
          strChtEngMix2(doc, toStraight(arrMiddle2[middle - i]), (x_middle - 0.5 * size) + i * 1.4 * size, topMiddle, size);
        } else {
          strChtEngMix2(doc, toStraight(arrMiddle2[middle - i]), (x_middle - 0.5 * size) + i * 1.4 * size, topMiddle, size)
          strChtEngMix2(doc, toStraight(arrMiddle2[middle + i]), (x_middle - 0.5 * size) - i * 1.4 * size, topMiddle, size);
        }
      }
    }
  }
}

export const printMiddleTitle = (doc, isPass, x, top, bottom, size = 24) => {
  doc.setFont('BiauKai').setFontSize(size);
  if (isPass) {
    doc.text("超 佛\n薦 力", x, top);
    doc.text("蓮 往\n位 生", x, bottom);
  } else {
    doc.text("注 佛\n照 光", x, top);
    doc.text("祿 長\n位 生", x, bottom);
  }
}

export const printLeft = (doc, str_left, topLeft, cut, size) => {
  const arrLeft = cutStrToArr(str_left, cut);
  doc.setFont('BiauKai').setFontSize(size);

  // fontsize 22
  if (arrLeft.length === 1) {
    doc.text(toStraight(arrLeft[0]), 180, topLeft);
  } else if (arrLeft.length === 2) {
    doc.text(toStraight(arrLeft[0]), 160, topLeft);
    doc.text(toStraight(arrLeft[1]), 180, topLeft);
  } else if (arrLeft.length === 3) {
    doc.text(toStraight(arrLeft[0]), 140, topLeft);
    doc.text(toStraight(arrLeft[1]), 160, topLeft);
    doc.text(toStraight(arrLeft[2]), 180, topLeft);
  }
}

export const printLeftSmall = (doc, str_left, topLeft, bottom, x_shift, size = 28) => {
  str_left = str_left.replace(/,/g, " ");
  // console.log(str_left.length + " " + str_left);
  let arrLeft = cutStrToArr(str_left, 8);
  if (str_left.match(/[a-z]/g)) { arrLeft = cutStrToArr2(str_left, 12); }
  else if (str_left.length > 22) { arrLeft = cutStrToArr(str_left, 16); }
  // else if (str_left.length > 14) { arrLeft = cutStrToArr(str_left, 8); }
  else if (str_left.length > 8) { arrLeft = cutStrToArr(str_left, 8); }

  // console.log(arrLeft.length);
  strChtEngMix2(doc, toStraight("陽上"), x_shift, topLeft, size);
  strChtEngMix2(doc, toStraight("叩薦"), x_shift, topLeft + bottom, size);

  if (arrLeft.length === 1) {
    size = Math.ceil(size * 0.9);
    if (arrLeft[0].length >= 13) {
      size = Math.ceil(size / 2);
    } else if (arrLeft[0].length >= 10) {
      size = Math.ceil(size * 0.6);
    } else if (arrLeft[0].length > 8) {
      size = Math.ceil(size * 0.75);
    }
    strChtEngMix2(doc, toStraight(arrLeft[0]), x_shift, topLeft + size * 3, size);
  } else if (arrLeft.length === 2) {
    size = Math.ceil(size * 0.75);
    if (arrLeft[0].length > 10) {
      size = Math.ceil(size * 0.4);
    } else if (arrLeft[0].length > 8) {
      size = Math.ceil(size * 0.6);
    }
    for (let i = 0; i < arrLeft.length; i++) {
      strChtEngMix2(doc, toStraight(arrLeft[i]), x_shift - 10 - i * size, topLeft + size * 3, size);
    }
  } else if (arrLeft.length === 3) {
    size = Math.ceil(size * 0.75);
    for (let i = 0; i < arrLeft.length; i++) {
      strChtEngMix2(doc, toStraight(arrLeft[i]), x_shift - i * size, topLeft + size * 3, size);
    }
  } else if (arrLeft.length === 4) {
    size = Math.ceil(size * 0.36);
    for (let i = 0; i < arrLeft.length; i++) {
      strChtEngMix2(doc, toStraight(arrLeft[i]), x_shift - i * size, topLeft + size * 3, size);
    }
  }
}
