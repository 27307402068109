import React from 'react'
import { Button } from 'semantic-ui-react'
// import Moment from 'moment';

import MyDimmer from '../../layouts/Dimmer';
import jsPDF from './JspdfMyFonts';
// import QR from "./wxqrcode.js";
// import { pdfkit2jspdf, drawTable } from 'widget/define/jspdf'

import temple from '../pdfCompany/coreTemple';
import hanbury from '../pdfCompany/coreHanbury';
import pawnshop from '../pdfCompany/corePawnshop';
import dao from '../pdfCompany/coreDao';
import autorepair from '../pdfCompany/coreAutorepair';

// window.Moment = Moment;
// window.QR = QR;
// window.pdfkit2jspdf = pdfkit2jspdf;
// window.drawTable = drawTable;
window.jsPDF = jsPDF;
window.jsPDF.loaded = {}

export const getPdfsrcAsync = (props) => {
  const { companyObj } = props;
  return new Promise((resolve, reject) => {

    if (companyObj.webtypeId === 'hanbury') {
      setTimeout(async () => {
        var doc;
        doc = await hanbury(props);

        if (typeof doc !== 'undefined' && doc.output) {
          try {
            if (navigator.msSaveBlob) {
              // let string = doc.output('datauristring');
              // string = 'http://microsoft.com/thisdoesnotexists';
              console.error('Sorry, we cannot show live PDFs in MSIE')
            } else {
              const pdfsrc = doc.output('bloburi');
              resolve({ pdfsrc, doc });
            }
          } catch (e) {
            reject(e)
            console.error('Error ' + e);
          }
        } else {
          window.alert('no pdf generated')
          resolve({ pdfsrc: null, doc: null });
        }
      }, 0);
    } else {
      setTimeout(async () => {
        var doc;

        if (companyObj.webtypeId === 'temple') {
          doc = await temple(props);
        } else if (companyObj.webtypeId === 'pawnshop') {
          doc = pawnshop(props);
        } else if (companyObj.webtypeId === 'dao') {
          doc = dao(props);
        } else if (companyObj.webtypeId === 'autorepair') {
          doc = autorepair(props);
        }

        // try {
        //   // eval(`try {` + firstCode + `} catch(e) { console.error(e.message,e.stack,e); }`);
        // } catch (e) {
        //   if (e instanceof SyntaxError) {
        //     console.log(e)
        //   } else {
        //     throw (e);
        //   }
        // };

        if (typeof doc !== 'undefined' && doc.output) {
          try {
            if (navigator.msSaveBlob) {
              // let string = doc.output('datauristring');
              // string = 'http://microsoft.com/thisdoesnotexists';
              console.error('Sorry, we cannot show live PDFs in MSIE')
            } else {
              const pdfsrc = doc.output('bloburi');
              resolve({ pdfsrc, doc });
            }
          } catch (e) {
            reject(e)
            console.error('Error ' + e);
          }
        } else {
          window.alert('no pdf generated')
          resolve({ pdfsrc: null, doc: null });
        }
      }, 0);
    }

  });
}
export const displayAutoTable = () => {
  let doc = new jsPDF({ format: 'a4', unit: 'pt' });

  doc.addFont('BiauKai-Regular.ttf', 'BiauKai', 'normal');
  doc.setFont('BiauKai');

  let columns = ["学院名", "募集期", "地域區分"];
  let rows = [
    ["愛知国際台灣学院", "2016年4月", "台灣"],
    ["愛知国際櫻花学院", "2016年10月", "名古屋"],
    ["愛知国際大阪学院", "2016年10月", "大阪"],
  ];

  // 要同時符合font以及fontStyle才可以正確顯示，例如addFont是設定normal，所以autoTable styles裡面也要設定fontStyle為normal
  // PS : AutoTable default的font是helvetica，fontStyle是(header是bold，body是normal)
  doc.autoTable(columns, rows,
    {
      styles: { font: "BiauKai", fontStyle: "normal" },
      headerStyles: { font: "BiauKai", fontStyle: "normal" },
    });
  const pdfsrc = doc.output('bloburi');

  return (
    <iframe
      title='pdfIframe1'
      id="pdfIframe"
      className="preview-pane" type="application/pdf" width="100%" height={this.state.showForm.indexOf('6') !== -1 ? "600" : "300"}
      frameBorder="0" style={{ position: "relative", zIndex: 0 }}
      src={pdfsrc}
    />);
};

export const downloadPDF = (props) => {
  const { doc, activeTab2Text = '' } = props;

  const name = activeTab2Text + '_' + new Date().getTime();
  if (typeof doc !== 'undefined') {
    doc.save(name + '.pdf');
  }
}

export const openPDF = (doc) => {
  let string = doc.output('bloburi');
  let embed = "<embed width='100%' height='100%' style='padding:0px' src='" + string + "'/>"
  let x = window.open();
  x.document.open();
  x.document.write(embed);
  x.document.close();
}

export const printPdf = () => {
  const pri = document.getElementById('pdfIframe').contentWindow;
  pri.print();
}

export const PdfButton = ({ doc }) =>
(<div style={{ float: 'right', padding: '5px' }} >
  <Button size='small' onClick={() => openPDF(doc)} icon='window maximize' content="開新分頁" />{' '}
  <Button size='small' positive onClick={() => downloadPDF({ doc })} icon='download' content='PDF下載' />{' '}
  <Button size='small' primary onClick={printPdf} icon='print' content="列印PDF" />{' '}
</div>);

export const PdfIframe = ({ pdfsrc = '', height }) => (pdfsrc ?
  <iframe
    title='pdfIframe2' id="pdfIframe"
    className="preview-pane" type="application/pdf" width="100%"
    // height={height}
    frameBorder="0" style={{ position: "relative", zIndex: 0, minHeight: '50vh', height }}
    src={pdfsrc}
  />
  // <embed title='pdfIframe2' id="pdfIframe" src={pdfsrc} width="100%" style={{ position: "relative", zIndex: 0, minHeight: '50vh', height }} />
  : <MyDimmer text='PDF文件製作中…' />)

export const PdfPanel = ({ pdfsrc, height = 900, doc }) => {
  // console.log(pdfsrc)
  return (<>
    {pdfsrc ? <PdfButton doc={doc} /> : null}
    <PdfIframe pdfsrc={pdfsrc} height={height} />
  </>)
}