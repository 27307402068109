import React, { Component } from 'react';
import { Table, Container, Form, Button, Grid, TextArea, Header, Confirm, Dimmer, Loader, Image, Segment } from 'semantic-ui-react';
import ReactToPrint from "react-to-print";
import Moment from 'moment';
import { getExpectedAttndDays } from '../../../../widget/define/calendar';
import Parse from 'widget/parse.js'

import { Row, Col } from 'reactstrap'
import ModalPdf from 'widget/pdf/ModalPdf';
import AttendanceCalculation from '../../../widgets/AttendanceCalculation';
import { showField, showFieldText, getAvailblePeriodArr, getDate, getThisWeekList } from 'views/widgets/FieldsRender';

const nowTimestamp = Date.now()
const nowObj = new Date();
let nowYear = nowObj.getFullYear()
let nowMonth = nowObj.getMonth() + 1  // getMonth()為0-11，所以要+1
let lastMonth = nowMonth - 1 > 0 ? nowMonth - 1 : 12
let lastMonthYear = lastMonth > nowMonth ? nowYear - 1 : nowYear
let lastMonth2 = lastMonth - 1 > 0 ? lastMonth - 1 : 12
let lastMonthYear2 = lastMonth2 > lastMonth ? lastMonthYear - 1 : lastMonthYear
let onejan = new Date(nowObj.getFullYear(), 0, 1);
let nowYearWeek = Math.ceil((((nowObj - onejan) / 86400000) + onejan.getDay() + 1) / 7);

export default class AttendancePrediction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attendDay: 0,
      absentDay: 0,
      predictionDays: 0,
      predictionHours: 0,
      form: {},
      attendWeek: [],
      // nowToISO: new Date(nowTimestamp).toISOString(),
      nowToISO: new Date().toISOString(),
      nowYear,
      nowMonth,
      lastMonth,
      lastMonthYear,
      lastMonth2,
      lastMonthYear2,
      countRelDay: 0,
      countRelHour: 0,
      countExpDay: 0,
      countExpHour: 0,
      lastWeekData: [],
      nowMonthData: [],
      lastMonthData: [],
      lastMonthData2: [],
      nowYearWeek,
      confirm: false,
      show: false
    };

    this.dailyAttendanceRate = [];
    this.monthAttendanceRate = [];
    // this.setSettingOnChange = this.setSettingOnChange.bind(this);

  }

  componentDidMount() {
    this.dailyAttendanceRate.forEach(item => {
      let rateNum = item.outerText.slice(0, -1);
      if (item && Number(rateNum) < 95) {
        item.bgColor = 'red';
      }
    })
    this.monthAttendanceRate.forEach(item => {
      let rateNum = item.outerText.slice(0, -1);
      if (item && Number(rateNum) < 90) {
        item.bgColor = 'red';
      }
    })
    this.handleFetch()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match !== nextProps.match)
      this.handleFetch(nextProps)
  }

  componentDidUpdate() {
    this.dailyAttendanceRate.forEach(item => {
      let rateNum = item.outerText.slice(0, -1);
      if (item && Number(rateNum) < 95) {
        item.bgColor = 'red';
      }
    })
    this.monthAttendanceRate.forEach(item => {
      let rateNum = item.outerText.slice(0, -1);
      if (item && Number(rateNum) < 90) {
        item.bgColor = 'red';
      }
    })
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, match, calendar } = props;
    const { nowToISO, nowYear, nowMonth, lastMonth, lastMonthYear, lastMonth2, lastMonthYear2, nowYearWeek } = this.state
    const { _id } = match.params;
    this.setState({ loading: true })
    let allHour = 0
    let relHour = 0
    const d = new Date();
    let weeklyExpectedDay = 0;
    let weeklyExpectedHours = 0;
    let weeklyRealDay = 0;
    let weeklyRealHours = 0;

    const data = await Parse.queryData('Monthly', { companyId: companyObj.objectId, studentId: _id });
    // firebase.firestore().collection(`Students/${_id}/Monthly`)
    //   .get().then(snap => {
    //     const data = [];
    data.forEach(item => {
      //       let item2 = item.data()
      if (new Date(item.startDate) <= d) {//計算到當月
        // console.log(item.startDate)
        //當月只計算到當天
        if (d.getMonth() === new Date(item.startDate).getMonth() && nowYear === new Date(item.startDate).getFullYear()) {
          const monthlyExpectedDay = getExpectedAttndDays(calendar, Moment(d).set('date', 1).format('YYYY-MM-DDT00:00:00'), Moment(d).format('YYYY-MM-DDT00:00:00'));
          // console.log(weeklyExpectedDay, Moment(d).set('date', 1).format('YYYY-MM-DD'), d)
          weeklyExpectedDay += Number(monthlyExpectedDay);
          weeklyExpectedHours += (Number(monthlyExpectedDay) * 4);
          weeklyRealDay += Number(item.weeklyRealDay);
          weeklyRealHours += Number(item.weeklyRealHours);
        } else {
          weeklyExpectedDay += Number(item.weeklyExpectedDay);
          weeklyExpectedHours += Number(item.weeklyExpectedHours);
          weeklyRealDay += Number(item.weeklyRealDay);
          weeklyRealHours += Number(item.weeklyRealHours);
        }
      }
    })
    let hoursRate = weeklyRealHours / weeklyExpectedHours
    let daysRate = weeklyRealDay / weeklyExpectedDay
    this.setState({ hoursRate, daysRate, weeklyRealHours, weeklyExpectedHours, weeklyRealDay, weeklyExpectedDay, loading: false });
    // }, err => {
    // console.log(`Encountered error: ${err}`);
    // });
    // firebase.firestore().collection(`Students/${_id}/Daily`).where('eventDate', '<', nowToISO).get().then(snap => {
    //   var promises = [];
    //   snap.forEach(item => {
    //     var promise = firebase.firestore().collection(`Students/${_id}/Daily/${item.id}/Rollcall`)
    //       // .where('attendStatus', "==", '8')
    //       .get().then(snapshot => {
    //         const allData = []
    //         const relData = []
    //         snapshot.forEach(fileItem => {
    //           allData.push({ _id: fileItem.id, ...fileItem.data() })   //  今日前所有應出席時數
    //           // allHour += allData.length
    //           if (fileItem.data().attendStatus === '1' || fileItem.data().attendStatus === '2') {
    //             relData.push({ _id: fileItem.id, ...fileItem.data() })  //  今日前所有實出席時數
    //             // relHour += relData.length
    //           }
    //         });
    //         return { allHour: allData.length, relHour: relData.length, relData, allData };
    //       });
    //     promises.push(promise);
    //   });
    //   Promise.all(promises).then(results => {
    //     // console.log('all promises were done')
    //     let allHour = Number(results[results.length - 1].allHour)
    //     let relHour = Number(results[results.length - 1].relHour)
    //     let allDay = Number(results.length)
    //     let relDay = Number(results.filter(item => item.relData.length !== 0).length)

    //     let hoursRate = relHour / allHour
    //     let daysRate = relDay / allDay
    //     this.setState({ allHour, relHour, allDay, relDay, hoursRate, daysRate, loading: false })
    //     // console.log(relHour, allHour, relDay, allDay, hoursRate, daysRate);
    //   }).catch(error => {
    //     console.log(error);
    //   });

    // }, err => {
    //   console.log(`Encountered error: ${err}`);
    // });

  }

  // handleChange = (event, data) => {
  //   const { name, value } = data
  //   this.setState({ show: false, [name]: Number(value) })
  // }

  // calculatePredition = () => {
  //   const { attendDay, absentDay, allHour, relHour, allDay, relDay } = this.state
  //   this.setState({ error1: false, error2: false })
  //   if (attendDay === '' || absentDay === '') {
  //     this.showConfirm()
  //   } else {
  //     let predictionHours = (relHour + attendDay * 4) / (allHour + attendDay * 4 + absentDay * 4)
  //     let predictionDays = (relDay + attendDay) / (allDay + attendDay + absentDay);
  //     // console.log(relDay, attendDay, allDay, attendDay, absentDay)
  //     this.setState({ predictionHours, predictionDays, show: true })
  //   }
  // }
  handleChange = (event, { name, value }) => {
    this.setState({ error1: false, error2: false, show: false, [name]: Number(value) }, () => {
      const { attendDay, absentDay, weeklyRealHours, weeklyExpectedHours, weeklyRealDay, weeklyExpectedDay, } = this.state

      if (attendDay === '' || absentDay === '') {
        this.showConfirm()
      } else {
        let predictionHours = (weeklyRealHours + attendDay * 4) / (weeklyExpectedHours + attendDay * 4 + absentDay * 4)
        let predictionDays = (weeklyRealDay + attendDay) / (weeklyExpectedDay + attendDay + absentDay);
        // console.log(weeklyRealHours, weeklyExpectedHours, weeklyRealDay, weeklyExpectedDay, attendDay, absentDay)
        this.setState({ predictionHours, predictionDays, show: true })
      }
    })
  }

  showConfirm = () => this.setState({ confirm: true });
  hideConfirm = () => {
    const { attendDay, absentDay } = this.state
    this.setState({ confirm: false })
    if (attendDay === '' && absentDay === '') {
      this.setState({ error1: true, error2: true })
    } else if (attendDay === '') {
      this.setState({ error1: true })
    } else if (absentDay === '') {
      this.setState({ error2: true })
    }
  };


  render() {
    const { semesterYear, semester, doc, calendar, studentsInClass, companyObj } = this.props;
    const { absentDay, attendDay, predictionDays, predictionHours, hoursRate, daysRate, loading } = this.state;
    const arrPdf = [
      {
        studentId: doc.studentId,
        jpnName: doc.jpnName,
        attendDay,
        absentDay,
        dRate: `${Math.round(daysRate * 10000) / 100 || '-'}%`, //日数出席率
        hRate: `${Math.round(hoursRate * 10000) / 100 || '-'}%`, //時間出席率
        dPRate: `${this.state.show === true ? Math.round(predictionDays * 10000) / 100 : Math.round(daysRate * 10000) / 100}%`, //予測日出席率
        hPRate: `${this.state.show === true ? Math.round(predictionHours * 10000) / 100 : Math.round(hoursRate * 10000) / 100}%`, //予測時出席率
      }
    ]

    return (<>
      <div ref={el => (this.componentRef = el)} className="" style={{ borderRadius: '.28571429rem', padding: '5px 0' }} >
        <Header as='h2' dividing
          content='出席率予測'
          style={{ backgroundColor: 'lavender' }}
        // subheader='調査時間：2017年4月から 2018年12月 まで'
        />
        {/* <ReactToPrint
          trigger={(e) => <Form.Button
            content='印刷'
            // label='&nbsp;'
            floated='right'
            color='orange' icon='print'
            size='small'
          />}
          content={() => this.componentRef}
          pageStyle={"@page { size: auto;  margin: 10mm; }*{font-family: 'Noto Serif JP', serif;}"}
        /> */}
        <ModalPdf
          floated='right'
          {...this.props}
          // ref={(ref) => { this.pdfModal = ref }}
          codekey="attPredictionPdf"
          params={arrPdf}
        />
        {loading ? <Segment>
          <Dimmer active inverted={companyObj.inverted}>
            <Loader inverted={companyObj.inverted}>Loading</Loader>
          </Dimmer>
          <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        </Segment> : <>
            {/* // <Grid style={{ margin: '25px 1rem' }}> */}
            <Row >
              <Col>{doc.studentId} {doc.jpnName}</Col>
              <Col></Col>
              {/* <Col>{showField(this.props, "jpnName")}</Col>
            <Col>{showField(this.props, "studentId")}</Col> */}
            </Row>
            <br />
            <Row style={{ alignItems: 'flex-end' }}>
              <Col /*width={7}*/>
                {/* <label>予測出席日数</label> */}
                <Form.Input
                  type='number'
                  error={this.state.error1}
                  label='予測出席日数'
                  name='attendDay'
                  onChange={this.handleChange}
                  value={attendDay ? attendDay : 0} />
              </Col>
              <Col /*width={7}*/>
                {/* <label>予測欠席日数</label> */}
                <Form.Input
                  type='number'
                  error={this.state.error2}
                  label='予測欠席日数'
                  name='absentDay'
                  onChange={this.handleChange}
                  value={absentDay ? absentDay : 0} />
              </Col>
              {/* <Col width={2} textAlign='right'>
              <Button color='green' onClick={() => this.calculatePredition()}>予測</Button>
            </Col> */}
            </Row>
            <br />
            <Row style={{ alignItems: 'center' }}>
              <Col sm={2}><div>今の出席率</div></Col>
              <Col sm={10}>
                <Table celled size="small" >
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={5}>日数出席率&nbsp;&nbsp;&nbsp;&nbsp;</Table.Cell>
                      <Table.Cell width={3}>{Math.round(daysRate * 10000) / 100 || '-'}%</Table.Cell>
                      <Table.Cell width={5}>時間出席率&nbsp;&nbsp;&nbsp;&nbsp;</Table.Cell>
                      <Table.Cell width={3}>{Math.round(hoursRate * 10000) / 100 || '-'}%</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Col>
            </Row>
            <br />
            <Row style={{ alignItems: 'center' }}>
              <Col sm={2}><div>予測出席率</div></Col>
              <Col sm={10}>
                <Table celled size="small" >
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={5}>予測日出席率</Table.Cell>
                      <Table.Cell width={3}>{this.state.show === true ? Math.round(predictionDays * 10000) / 100 : Math.round(daysRate * 10000) / 100}%</Table.Cell>
                      <Table.Cell width={5}>予測時出席率</Table.Cell>
                      <Table.Cell width={3}>{this.state.show === true ? Math.round(predictionHours * 10000) / 100 : Math.round(hoursRate * 10000) / 100}%</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Col>
            </Row>
            {/* /* </Grid> */}
          </>
        }
        <Confirm
          header='必須填寫預測天數'
          content='必須填寫預測天數'
          cancelButton={null}
          confirmButton='閉じる'
          open={this.state.confirm}
          // onCancel={this.hideConfirm}
          onConfirm={this.hideConfirm}
          size='mini'
          centered={false} />
      </div>
    </>);
  }
}