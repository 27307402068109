import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row, Col,
  Form, FormGroup, Input, Label, Button
} from "reactstrap";
import { Form as Form2, TextArea } from 'semantic-ui-react';

import intl from 'react-intl-universal';
// import MaterialTable from 'material-table'
// import TextareaAutosize from "react-textarea-autosize";

import { clientPawn } from "views/formDef/object";
import { fieldCol } from "views/formDef/function";
import ModalAutoAddr from './ModalAutoAddr';
import Parse from "widget/parse";
// import localization from 'widget/MaterialTableOpt'
// import { FormControl } from "@material-ui/core";

class SalesInventoryTab2 extends React.Component {
  state = {
    clientObj: {},
    contactPersonArr: [],
    loading: false
  }

  componentDidMount = async () => {
    this.handleFetch()
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    // const { companyObj, clientObj } = props
    // const companyId = companyObj.objectId;

    // this.setState({ clientObj: {}, contactPersonArr: [], loading: true });
    // const contactPersonArr = await Parse.queryData('people', { companyId, clientId: clientObj.objectId });
    // this.setState({ clientObj, contactPersonArr, loading: false });
  }

  onRowDataChange = (clientObj) => {
    this.setState({ clientObj })
  }

  handleChange = (e, data) => {
    if (e.target) {
      this.setState({ clientObj: { ...this.state.clientObj, [e.target.name]: e.target.value } })
    } else {
      this.setState({ clientObj: { ...this.state.clientObj, [data.name]: e } })
    }
  }

  handleSave = async () => {
    const { clientObj } = this.state;
    await Parse.saveData('clients', clientObj);
    window.alert('已儲存')
  }

  render() {
    const { companyObj } = this.props
    const { clientObj, contactPersonArr, loading } = this.state

    // console.log(clientObj)
    return (<div className="content">
      <Card>
        <CardHeader>
          <CardTitle tag="h4">#{clientObj.uid || ''} {clientObj.name || ''} 基本資料</CardTitle>
        </CardHeader>
        <CardBody>
          <Form >
            <Row form>
              {fieldCol(clientPawn.name, "", "2", clientObj.name, this.handleChange)}
              {fieldCol(clientPawn.sexualId, "", "2", clientObj.sexualId, this.handleChange)}
              {fieldCol(clientPawn.peopleIdNumber, "", "2", clientObj.peopleIdNumber, this.handleChange)}
              {fieldCol(clientPawn.birthday, "", "2", clientObj.birthday, this.handleChange)}
              {fieldCol(clientPawn.cellnum, "", "2", clientObj.cellnum, this.handleChange)}
              {fieldCol(clientPawn.nowStayPhone, "", "2", clientObj.nowStayPhone, this.handleChange)}
            </Row>
            <Row form>

              <Col md={1}>
                <FormGroup>
                  <ModalAutoAddr {...this.props} type='3' rowData={clientObj} field1="nowPostcode" field2="nowAddr" onRowDataChange={this.onRowDataChange} />
                </FormGroup>
              </Col>
              {fieldCol(clientPawn.nowPostcode, "", "1", clientObj.nowPostcode, this.handleChange)}
              {fieldCol(clientPawn.nowAddr, "", "4", clientObj.nowAddr, this.handleChange)}
              <Col md={1}>
                <FormGroup>
                  <ModalAutoAddr {...this.props} type='3' rowData={clientObj} field1="billPostcode" field2="billAddr" onRowDataChange={this.onRowDataChange} />
                </FormGroup>
              </Col>
              {fieldCol(clientPawn.billPostcode, "", "1", clientObj.billPostcode, this.handleChange)}
              {fieldCol(clientPawn.billAddr, "", "4", clientObj.billAddr, this.handleChange)}
            </Row>
          </Form>
        </CardBody>
      </Card>
      <br />
      <br />
      <Card>
        <CardBody>
          <Form>
            <FormGroup>
              <Label for="exampleText">備註</Label>
              <Input type="textarea"
                // row={50}
                // cols={50}
                // size='lg'
                cssModule={{ height: '400px !important' }}
                name="ps" id="ps" value={clientObj.ps || ''} onChange={this.handleChange} />
              {/* <Form2.Field
                useCacheForDOMMeasurements
                control={TextareaAutosize}
                // fluid
                value={clientObj.ps || ''}
                name="ps"
                id='ps'
                // action={{ icon: 'cancel', onClick: () => this.clear('To2') }}
                onChange={this.handleChange}
              // placeholder='請輸入Email，並以逗號分隔'
              /> */}
            </FormGroup>
            <Button onClick={this.handleSave}>{intl.get('儲存')}</Button>
          </Form>
        </CardBody>
      </Card>
    </div>);
  }
}

export default SalesInventoryTab2;
