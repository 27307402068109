import React, { Component } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import EmployeeTab from "./EmployeeTab";
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';


const columns = () => ([
  { width: 180, title: '帳號', field: 'username', editable: 'never' },
  { width: 80, title: '工號', field: 'jobNum' },
  { width: 120, title: '姓名', field: 'name' },
  { width: 80, title: '資息(%)', field: 'interest' },
  { width: 80, title: '結餘', field: 'balance', editable: 'never' },
  { width: 80, title: '底薪', field: 'baseSalary' },
]);

class Employee extends Component {
  state = {
    activeItem: 'bio',
    dataArr: [],
    rowData: {},
    loading: true
  }

  async componentDidMount() {
    const { companyObj } = this.props
    const data = await Parse.queryData('User', { authAllA: companyObj.objectId });
    // console.log(data);
    this.setState({ dataArr: data, loading: false });
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    this.setState({ loading: true });
    await Parse.saveData('User', newData);
    this.setState({ loading: false });
  }

  handleData = (rowData) => {
    this.setState({ rowData });
  }

  render() {
    const { dataArr, rowData, loading } = this.state
    return (<div className="content">
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '職員列表'),
          pageSize: 5,
          search: false,
          tableLayout: 'fixed'
        }}
        data={dataArr}
        title="職員列表"
        onRowClick={(event, rowData) => this.handleData(rowData)}
        actions={[
          {
            icon: 'view_headline',
            tooltip: '查看',
            onClick: (event, rowData) => this.handleData(rowData)
          }
        ]}
        editable={{
          //   onRowAdd: newData =>
          //     new Promise(async (resolve, reject) => {
          //       const data = [...dataArr];
          //       const db = Parse.Object.extend("pawnRecord");
          //       const newDoc = new db();
          //       const doc = await newDoc.save();
          //       newData.objectId = doc.id;
          //       newData.id = doc.id;
          //       newData._id = doc.id;
          //       newData.clientId = objectId

          //       data.push(newData);
          //       this.setState({ dataArr: data, newData }, () => resolve());
          //     }).then(() => this.handleAdd()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...dataArr];
              const index = data.indexOf(oldData);
              data[index] = newData;
              // handleState('people0', data);
              this.setState({ dataArr: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          //   onRowDelete: oldData =>
          //     new Promise((resolve, reject) => {
          //       let data = [...dataArr];
          //       const index = data.indexOf(oldData);
          //       data.splice(index, 1);
          //       // handleState('people0', data);
          //       this.setState({ dataArr: data, oldData }, () => resolve());
          //     }).then(() => this.handleDelete()),
        }}
      />
      <br />
      {rowData && rowData.objectId ? <EmployeeTab {...this.props} employeeObj={rowData} loading={loading} employeeId={rowData.objectId} /> : null}
    </div>);
  }
}

export default Employee;