import React, { Component } from 'react'
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
import { Container, Header, Form, Icon, Button } from 'semantic-ui-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import PageHeader from 'components/Headers/PageHeader';
import { ListGroup, ListGroupItem } from 'reactstrap';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import JoditEditor from "jodit-react";

export class ModalEditPic extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
      show: false,
      text: '',
      orginalText: ''
    };
  }
  componentDidMount = () => {
    const { id, obj } = this.props
    // const orginalText = (obj.text[id] && obj.text.find(item => item.title === id).content) || (id && document.getElementById(id).innerHTML) || ''
    // this.setState({ orginalText })
  }

  handleChange = (e, { value }) => this.setState({ value })
  closeEdit = () => { this.setState({ show: false }) }
  onEdit = () => { this.setState({ show: true }) }

  handleEditorChange = (id, value, content) => {
    this.setState({ [id]: value, content })
  }
  //   handleChange = (e) => {
  //     // console.log(e);
  //     this.setState({
  //       [e.target.id]: e.target.value
  //     })
  //   }

  //   handleSubmit = (e) => {
  //     // console.log(e);
  //     e.preventDefault();
  //     this.props.createProject(this.state);
  //     this.props.history.push('/');
  //   }
  render() {
    const { handleUse, id } = this.props
    const { show, text, orginalText, content } = this.state

    //     if (!auth.uid) return <Redirect to='/signin' />
    // const { value } = this.state
    return (<>
      <a className="j-pop-text" onClick={this.onEdit}>編輯</a>
      <Modal
        isOpen={show}
        toggle={this.closeEdit}
        size="lg"
      // className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader >
          <Icon name='close' onClick={this.closeEdit} style={{ cursor: 'pointer', margin: 0 }}></Icon>
        </ModalHeader>
        <ModalBody>
          <div className="white-popup" id="popup-textsetting">
            <form className="setBox" data-parsley-required-message="尚未填寫">
              <div className="top_title">
                <h3>編輯文字</h3>
              </div>
              <div className="fillinBox text-editbox">
                <JoditEditor
                  config={{
                    height: 400, buttons: ['bold', 'strikethrough', 'underline', 'italic', '|', 'ul', 'ol', '|', 'font', 'fontsize', 'brush', 'paragraph', 'align', 'undo', 'redo'], toolbarAdaptive: false,
                  }}
                  style={{ height: 400 }}
                  name={'text'}
                  value={orginalText}
                  tabIndex={2}
                  onBlur={newContent => this.handleEditorChange('text', newContent.target.innerHTML, newContent.target.innerText)}
                />
              </div>
            </form>
          </div>
        </ModalBody>
        <ModalFooter style={{ padding: '16px', justifyContent: 'center' }}>
          <Button style={{ backgroundColor: '#27d0ca', color: '#fff' }} content='套用' onClick={() => { handleUse(text, id, content); this.closeEdit() }} />
        </ModalFooter>
      </Modal>
    </>)
  }
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     createProject: (project) => dispatch(createProject(project))
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HostIndex)
export default ModalEditPic