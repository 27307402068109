import React, { Component } from 'react'
import { Form } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
} from 'reactstrap';
import { Line, Bar, Pie } from "react-chartjs-2";

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

// import FileCenter1 from './FileCenter1'
import { fileBtnCol, filePreviewCol } from '../web/CustomMaterialTable';
// import ModalUploadFiles from './ModalUploadFiles';

const chartExample9 = {
  data: {
    labels: ["6pm", "9pm", "11pm"],
    datasets: [
      {
        label: "Active Users",
        borderColor: "#f17e5d",
        pointBackgroundColor: "#f17e5d",
        pointRadius: 3,
        pointHoverRadius: 3,
        lineTension: 0,
        fill: false,
        borderWidth: 3,
        data: [200, 250, 300,]
      }
    ]
  },
  options: {
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "#9f9f9f",
            beginAtZero: false,
            maxTicksLimit: 5
          },
          gridLines: {
            drawBorder: false,
            borderDash: [8, 5],
            zeroLineColor: "transparent",
            color: "#9f9f9f"
          }
        }
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            borderDash: [8, 5],
            color: "#9f9f9f",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#9f9f9f"
          }
        }
      ]
    }
  }
};
const columns = () => ([
  // { width: 60, title: '類別', field: 'fileCenter1Id', lookup: f1Sel },
  // { width: 120, title: '名稱', field: 'FundDescription' },
  { width: 120, title: 'PriceDate', field: 'PriceDate' },
  { width: 120, title: 'BidPrice', field: 'BidPrice' },
  { width: 120, title: 'FundCurrencyISO', field: 'FundCurrencyISO' },
  // fileBtnCol({ field: 'file1', hidden: !isFile1 }),
  // filePreviewCol({ title: '檔案', field: 'file1', hidden: !isFile1 }),
  // fileBtnCol({ field: 'file2', hidden: !isFile2 }),
  // filePreviewCol({ title: '申請檔案', field: 'file2', hidden: !isFile2 }),
  // fileBtnCol({ field: 'file3', hidden: !isFile3 }),
  // filePreviewCol({ title: '空白檔案', field: 'file3', hidden: !isFile3 }),
  // fileBtnCol({ field: 'file4', hidden: !isFile4 }),
  // filePreviewCol({ title: '範例檔案', field: 'file4', hidden: !isFile4 }),
  // { title: '附加文件提醒', field: 'docPs', hidden: !isDocPs },
  // { width: 60, title: '工作天數', field: 'workdays', hidden: !isWorkdays },
  // { title: 'file1_url', field: 'file1_url' },
  // { title: 'file2_url', field: 'file2_url' },
  // { title: '備註', field: 'ps' },
]);

export default class FileCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fundArr1: [],
      f1Opt: [],
      f1Obj: {},
      fundArr2: [],
      selType: '',
      fileOpt: [],
      loading1: true,
      loading2: true,
    }
    // this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.handleFetch(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj } = props;
    const companyId = companyObj.objectId

    this.setState({ loading1: true });
    const fundArr1 = await Parse.queryData('fund1', { companyId });
    const f1Opt = [];
    let f1Sel = {}
    fundArr1.forEach(item => f1Opt.push({ key: item.objectId, value: item.FundDescription, text: item.FundDescription }))
    fundArr1.forEach(item => f1Sel = { ...f1Sel, [item.objectId]: item.name })

    const selType = fundArr1 && fundArr1.length ? fundArr1[0].FundDescription : '';
    const f1Obj = fundArr1[0];

    this.setState({ fundArr1, f1Opt, f1Sel, f1Obj, selType, loading1: false }, () => this.handleFetch2())
  }

  handleFetch2 = async (props = this.props) => {
    const { companyObj } = props;
    const { f1Obj, selType } = this.state
    const companyId = companyObj.objectId;
    const fund2 = await Parse.queryData('fund2', { companyId, FundDescription: selType });
    const fundArr2 = fund2.sort((a, b) => new Date(b.PriceDate) - new Date(a.PriceDate))
    // const fundArr2Show = await Parse.queryData('fund2', { companyId, FundDescription: selType }, { orderBy: 'PriceDate' });
    const fundArr2Show = [...fundArr2].reverse()
    // const fundArr2 = fund2.sort((a, b) => ('' + a.PriceDate).localeCompare(b.PriceDate));
    this.setState({ loading2: true });
    let showChart = {}
    let labels = []
    let data = []
    fundArr2Show.forEach(item => {
      labels.push(item.PriceDate)
      data.push(item.BidPrice)
    })

    showChart = {
      start_date: fundArr2Show[0].PriceDate,
      end_date: fundArr2Show[fundArr2Show.length - 1].PriceDate,
      title: fundArr2Show[0].FundDescription,
      data: {
        labels,
        datasets: [
          {
            label: "Active Users",
            borderColor: "#f17e5d",
            pointBackgroundColor: "#f17e5d",
            pointRadius: 3,
            pointHoverRadius: 3,
            lineTension: 0,
            fill: false,
            borderWidth: 3,
            data
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "#9f9f9f",
                beginAtZero: false,
                maxTicksLimit: 5
              },
              gridLines: {
                drawBorder: false,
                borderDash: [8, 5],
                zeroLineColor: "transparent",
                color: "#9f9f9f"
              }
            }
          ],
          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                borderDash: [8, 5],
                color: "#9f9f9f",
                zeroLineColor: "transparent"
              },
              ticks: {
                padding: 20,
                fontColor: "#9f9f9f"
              }
            }
          ]
        }
      }
    };
    this.setState({ fundArr2, loading2: false, showChart });
  }

  handleChange = (e, { name, value }) => {
    const { fundArr1 } = this.state
    const f1Obj = fundArr1.find(item => item.FundDescription === value);
    this.setState({ f1Obj, [name]: value }, () => this.handleFetch2())
  }


  render() {
    const { f1Opt, f1Sel, f1Obj, fundArr2, selType, loading1, loading2, fileOpt, showChart = {} } = this.state;

    return (<div className="content">
      <Row>
        <Col md={6}>
          <div style={{ display: 'flex', position: 'relative', zIndex: 100, paddingTop: '11px' }}>
            <Form.Select style={{ marginRight: '5px' }} loading={loading1}
              options={f1Opt} onChange={this.handleChange} name='selType' value={selType} />
            {/* {!loading1 ? <FileCenter1 {...this.props} handleData={this.handleFetch} /> : null} */}
          </div>
        </Col>
        <Col md={6} style={{ textAlign: 'right' }}>
          {/* {!loading1 ? <ModalUploadFiles {...this.props} isImage='0' fileOpt={fileOpt} rowData={f1Obj} handleData={this.handleFetch2} /> : null} */}
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm='6'>
          <MaterialTable
            isLoading={loading2}
            localization={localization()}
            // columns={columns({ ...f1Obj, f1Sel })}
            columns={columns()} options={{
              addRowPosition: "first",
              exportButton: true,
              sorting: false,
              exportAllData: true,
              exportPdf: (columns, data) => exportPdf(columns, data, f1Obj.FundDescription || ''),
              pageSize: fundArr2.length || 10,
              search: false,
            }}
            tableRef={this.tableRef}
            data={fundArr2}
            title={f1Obj.FundDescription || ''}
          />
          <br />
        </Col>
        <Col sm='6'>
          <Card className="card-chart">
            <CardHeader>
              <CardTitle>{showChart.title}</CardTitle>
              <p className="card-category">{`${showChart.start_date}-${showChart.end_date}`}</p>
            </CardHeader>
            <CardBody>
              <Line
                data={showChart.data}
                options={showChart.options}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>)
  }
}