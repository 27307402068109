
import React, { Component } from 'react'
import { Container, Grid, Header, Form, Segment, Menu, Table, Button, Icon } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

import intl from 'react-intl-universal';

// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// import _ from 'underscore'

// import RowsDnd from './RowsDnd'

// reactstrap components

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

class DonateSupport extends Component {
  state = {
    data: {},
    text1: '',
    text2: '',
    text3: '',
    text4: '',
    text5: '',
    text6: '',
  }

  async componentDidMount() {
    const dataArr = await Parse.queryData('donatetext');
    console.log(dataArr);
    const data = dataArr[0];
    this.setState({
      data,
      text1: data.text1,
      text2: data.text2,
      text3: data.text3,
      text4: data.text4,
      text5: data.text5,
      text6: data.text6,
    });
  }

  handleSave = () => {
    const { data, text1, text2, text3, text4, text5, text6 } = this.state;
    data.text1 = text1;
    data.text2 = text2;
    data.text3 = text3;
    data.text4 = text4;
    data.text5 = text5;
    data.text6 = text6;

    Parse.saveData('donatetext', data);

    alert('儲存成功！')
  }

  render() {
    const { companyObj } = this.props;
    const { text1, text2, text3, text4, text5, text6 } = this.state;

    return (
      <>
        <div className="content">
          <Table celled structured>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan='4'>設定</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell width='3' >進入內容:</Table.Cell>
                <Table.Cell width='13' >
                  {/* <CKEditor
                    editor={ClassicEditor}
                    data={text1}
                    onInit={editor => {
                      // You can store the "editor" and use when it is needed.
                      console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.setState({ text1: data });
                      console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log('Focus.', editor);
                    }}
                  /> */}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width='3' >進入內容(簡):</Table.Cell>
                <Table.Cell width='13' >
                  {/* <CKEditor
                    editor={ClassicEditor}
                    data={text2}
                    onInit={editor => {
                      // You can store the "editor" and use when it is needed.
                      console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.setState({ text2: data });
                      console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log('Focus.', editor);
                    }}
                  /> */}
                </Table.Cell>              </Table.Row>
              <Table.Row>
                <Table.Cell width='3' >線上捐款:</Table.Cell>
                <Table.Cell width='13' >
                  {/* <CKEditor
                    editor={ClassicEditor}
                    data={text3}
                    onInit={editor => {
                      // You can store the "editor" and use when it is needed.
                      console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.setState({ text3: data });
                      console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log('Focus.', editor);
                    }}
                  /> */}
                </Table.Cell>              </Table.Row>
              <Table.Row>
                <Table.Cell width='3' >線上捐款(簡):</Table.Cell>
                <Table.Cell width='13' >
                  {/* <CKEditor
                    editor={ClassicEditor}
                    data={text4}
                    onInit={editor => {
                      // You can store the "editor" and use when it is needed.
                      console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.setState({ text4: data });
                      console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log('Focus.', editor);
                    }}
                  /> */}
                </Table.Cell>              </Table.Row>
              <Table.Row>
                <Table.Cell width='3' >其他捐款:</Table.Cell>
                <Table.Cell width='13' >
                  {/* <CKEditor
                    editor={ClassicEditor}
                    data={text5}
                    onInit={editor => {
                      // You can store the "editor" and use when it is needed.
                      console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.setState({ text5: data });
                      console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log('Focus.', editor);
                    }}
                  /> */}
                </Table.Cell>              </Table.Row>
              <Table.Row>
                <Table.Cell width='3' >其他捐款(簡):</Table.Cell>
                <Table.Cell width='13' >
                  {/* <CKEditor
                    editor={ClassicEditor}
                    data={text6}
                    onInit={editor => {
                      // You can store the "editor" and use when it is needed.
                      console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.setState({ text6: data });
                      console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log('Focus.', editor);
                    }}
                  /> */}
                </Table.Cell>              </Table.Row>
            </Table.Body>
          </Table>
          <Button color='blue' content={intl.get('儲存')} onClick={this.handleSave} floated='right' style={{ width: '100px' }} />
          <br />
        </div>
      </>
    );
  }
}

export default DonateSupport;
