import React, { Component } from 'react'
// import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
// import cookie from 'react-cookies'
// import { Link } from "react-router-dom";
// import { NavLink } from "react-router-dom";

import { Link, withRouter } from 'react-router-dom';
import classnames from "classnames";
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  // Form,
  // Input,
  // InputGroupAddon,
  // InputGroupText,
  // InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap";
import { Image, Select } from 'semantic-ui-react';

import { logout } from '../../store/actions/auth';
import DropdownLang2 from "./DropdownLang2";

// import { useCookies } from 'react-cookie';
const hostname = window.location.hostname;
const typeOpt = [
  { text: '1 上線', value: '1', key: '1' },
  { text: '2 修改', value: '2', key: '2' },
  { text: '3 洽談', value: '3', key: '3' },
  { text: '4 推廣', value: '4', key: '4' },
  { text: '5 關閉', value: '5', key: '5' },
  { text: '6 穩定', value: '6', key: '6' },
]
class AdminNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      typeId: '1'
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
    // user.isAuthenticated
    this.handleColor();
  }

  componentWillReceiveProps(nextProps) {
    // const { match } = nextProps;
    // console.log(match)
    this.handleColor(nextProps);
  }

  handleColor = (props = this.props) => {
    const { companyObj } = props
    // console.log(companyObj)
    if (companyObj.inverted) {
      this.setState({ color: "" });
    } else {
      this.setState({ color: "navbar-transparent" });
    }
  }

  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };

  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };

  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };

  logout = () => {
    // const toUrl = companyObj.useOfficial ? "/" : '/auth/login';
    const toUrl = '/auth/login';
    this.props.logout(this.props.history, toUrl);
  }

  // handleLogout = async (e) => {
  //   e.preventDefault();

  //   // const { history } = this.props
  //   const res = await logoutUser()
  //   // if (res === '1') {
  //   // history.push('/');
  //   // }
  //   window.location.href = '/';
  // }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  render() {
    const { history, companies = [], changeCompany, companyObj } = this.props;
    const { color, typeId } = this.state
    return (<>
      <Navbar
        className={classnames("navbar-absolute fixed-top", color)}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                className="btn-icon btn-round"
                color="default"
                id="minimizeSidebar"
                onClick={this.props.handleMiniClick}
              >
                <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
              </Button>
            </div>
            <div
              className={classnames("navbar-toggle", {
                toggled: this.state.sidebarOpen
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={this.toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
              <span className="d-none d-md-block">
                {/* {companyObj.name}  */}
                {companyObj.img1File ? <Image size="small" src={companyObj.img1File.url} /> : companyObj.name}
                {/* {companyObj.url || ''} */}
              </span>
              <span className="d-block d-md-none">{companyObj.nameAbbr}</span>
            </NavbarBrand>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={this.state.collapseOpen}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            // data-target="#navigation"
            data-toggle="collapse"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse
            className="justify-content-end"
            navbar
            isOpen={this.state.collapseOpen}
          >
            {/* <Form>
              <InputGroup className="no-border">
                <Input defaultValue="" placeholder="Search..." type="text" />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i className="nc-icon nc-zoom-split" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Form> */}
            <Nav navbar>
              <NavItem>
                <NavLink
                  className="btn-magnify"
                  href="/admin/dashboard"
                  onClick={e => {
                    e.preventDefault();
                    history.push('/admin/dashboard')
                  }}
                >
                  <i className="nc-icon nc-layout-11" />
                  <p>
                    <span className="d-lg-none d-md-block">Stats</span>
                  </p>
                </NavLink>
              </NavItem>
              <UncontrolledDropdown className="btn-rotate" nav>
                <DropdownToggle
                  aria-haspopup={true}
                  caret
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  nav
                >
                  <i className="nc-icon nc-bell-55" />
                  <p>
                    <span className="d-lg-none d-md-block">Some Actions</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                  <DropdownItem
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    Action
                    </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    Another action
                    </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    Something else here
                    </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink
                  className="btn-rotate"
                  href="/admin/System"
                  onClick={e => {
                    e.preventDefault();
                    history.push('/admin/System')
                  }}
                >
                  <i className="nc-icon nc-settings-gear-65" />
                  <p>
                    <span className="d-lg-none d-md-block">設定</span>
                  </p>
                </NavLink>
              </NavItem>
              {companyObj.useOfficial ? <NavItem>
                <NavLink
                  className="btn-rotate"
                  href="/"
                  target="_blank"
                // onClick={e => e.preventDefault()}
                >
                  {/* <i className="nc-icon nc-tap-01" /> */}
                  <i className="nc-icon nc-planet" />
                  <p>
                    <span className="d-lg-none d-md-block">官方網站</span>
                  </p>
                </NavLink>
              </NavItem> : null}
              {companyObj.isMultiLang ? <DropdownLang2 {...this.props} /> : null}
              <NavItem>
                <NavLink
                  className="btn-move-right"
                  href="#"
                  onClick={this.logout}
                >
                  <i className="nc-icon nc-user-run" />
                  <p>
                    <span className="d-lg-none d-md-block">登出</span>
                  </p>
                </NavLink>
              </NavItem>
              {hostname === 'localhost' ?
                <UncontrolledDropdown className="btn-rotate" nav>
                  <DropdownToggle
                    aria-haspopup={true}
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink2"
                    nav
                  >
                    <i className="nc-icon nc-tv-2" />
                    <p>
                      <span className="d-lg-none d-md-block">網站</span>
                    </p>
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink2" className="dropdown-danger" right>
                    <DropdownItem header><Select name='typeId' value={typeId} options={typeOpt} onChange={this.handleChange} /></DropdownItem>
                    {companies.filter(item => item.devPhaseId === typeId).map(({ name, objectId }) => (
                      <DropdownItem key={objectId}
                        onClick={e => {
                          e.preventDefault()
                          changeCompany(objectId);
                          history.push('/admin/dashboard')
                        }}
                      >{name}</DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown> : null}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>);
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps, { logout })(withRouter(AdminNavbar));