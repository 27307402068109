import React, { Component } from 'react'
import { Container, Dimmer, Loader, Segment, Image, Select } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { mgyearOpt, monthOpt, exportPdf } from 'views/web/CustomMaterialTable';

const columns = () => ([
  // { title: '發佈對象 ', field: 'towhom', lookup: { '0': '', '1': '內部', '2': '外部' } },
  { title: '項目', field: 'accountText' },
  // { title: '說明', field: 'title' },
  { title: '12月', field: 'price12' },
  { title: '1月', field: 'price1' },
  { title: '2月', field: 'price2' },
  { title: '3月', field: 'price3' },
  { title: '4月', field: 'price4' },
  { title: '5月', field: 'price5' },
  { title: '6月', field: 'price6' },
  { title: '7月', field: 'price7' },
  { title: '8月', field: 'price8' },
  { title: '9月', field: 'price9' },
  { title: '10月', field: 'price10' },
  { title: '11月', field: 'price11' },
  { title: '合計', field: 'priceTotal' },
  // { title: '％', field: 'totalr' },
  // { title: '公告時間', field: 'publishAt_time' },
  // { title: '新增時間', field: 'insertedAt_time' }, 
  // { title: 'pp', field: 'value' },

  // { title: '地址', field: 'addr', cellStyle: { width: '250px' }, headerStyle: { width: '250px' } },
]);

const d = new Date()
export default class IncomeStatement extends Component {
  state = {
    dataArr: [],
    data2Arr: [],
    ac2DataArr: [],
    ac2Data2Arr: [],
    deleteConfirm: false,
    loading: false,
    // bankaccSel: '',
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
    mgyearArr: [],
    // monthSel: Moment(new Date()).format('YYYY-MM') || '',
  }

  async componentDidMount() {
    // const { companyObj, match } = this.props
    // // const { familyId } = match.params;
    // const data = await Parse.queryData("")
    // this.setState({ data1: data, loading: false })
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    this.setState({ [name]: value });
  }

  render() {
    const { bgId } = this.props
    const { dataArr, data2Arr, loading, mgyearArr, mgyearSel } = this.state;
    // const [year, month] = monthSel.split('-');
    return (<>
      <div className='content'>
        {/* 資金需求預估表 */}
        <br />
        <Select style={{
          position: 'relative', zIndex: 99,
          float: 'left',
          minWidth: '200px',
          // borderTopLeftRadius: '0',
          // borderBottomLeftRadius: '0'
        }}
          compact
          name="mgyearSel"
          options={mgyearOpt}
          value={mgyearSel}
          onChange={this.handleChange}
        />
        <br />
        {/* <Select style={{
          position: 'relative', zIndex: 99,
          float: 'right',
          minWidth: '200px',
          // borderTopLeftRadius: '0',
          // borderBottomLeftRadius: '0'
        }}
          compact
          name="bankaccSel"
          options={bankaccArr}
          value={bankaccSel}
          onChange={this.handleChange}
        /> */}
        <br />
        <br />
        {/* // name='bgId'  */}
        <MaterialTable
          isLoading={loading}
          localization={localization()}
          columns={columns()}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearSel} 年度損益表   收入項目`),
            pageSize: 5,
            search: false
          }}
          // data2={bankaccSel}
          data={dataArr}
          title={`${mgyearSel} 年度損益表   收入項目`}
        // editable={{
        //   onRowAdd: newData =>
        //     new Promise((resolve, reject) => {
        //       const db = firebase.firestore();
        //       const docRef = db.collection(`company/${companyObj._id}/bg/${bgId}/mgyear`).doc();
        //       const data = [...dataArr];
        //       const obj = {
        //         _id: docRef.id,
        //         id: docRef.id,
        //         ...newData
        //       }
        //       // data.push(obj);
        //       data.push(obj);
        //       this.setState({ dataArr: data, newData: obj }, () => resolve());
        //     }).then(() => this.handleAdd()),
        //   onRowUpdate: (newData, oldData) =>
        //     new Promise((resolve, reject) => {
        //       const data = [...dataArr];
        //       const index = data.indexOf(oldData);
        //       data[index] = newData;
        //       // handleState('people0', data);
        //       this.setState({ dataArr: data, newData, oldData }, () => resolve());
        //     }).then(() => this.handleUpdate()),
        //   onRowDelete: oldData =>
        //     new Promise((resolve, reject) => {
        //       let data = [...dataArr];
        //       const index = data.indexOf(oldData);
        //       data.splice(index, 1);
        //       // handleState('people0', data);
        //       this.setState({ dataArr: data, oldData }, () => resolve());
        //     }).then(() => this.handleDelete()),
        // }}
        />
        <MaterialTable
          isLoading={loading}
          localization={localization()}
          columns={columns()}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearSel} 年度損益表   支出科目`),
            pageSize: 5,
            search: false
          }}
          // data2={bankaccSel}
          data={data2Arr}
          title={`${mgyearSel} 年度損益表   支出科目`}
        // editable={{
        //   onRowAdd: newData =>
        //     new Promise((resolve, reject) => {
        //       const db = firebase.firestore();
        //       const docRef = db.collection(`company/${companyObj._id}/bg/${bgId}/mgyear`).doc();
        //       const data = [...dataArr];
        //       const obj = {
        //         _id: docRef.id,
        //         id: docRef.id,
        //         ...newData
        //       }
        //       // data.push(obj);
        //       data.push(obj);
        //       this.setState({ dataArr: data, newData: obj }, () => resolve());
        //     }).then(() => this.handleAdd()),
        //   onRowUpdate: (newData, oldData) =>
        //     new Promise((resolve, reject) => {
        //       const data = [...dataArr];
        //       const index = data.indexOf(oldData);
        //       data[index] = newData;
        //       // handleState('people0', data);
        //       this.setState({ dataArr: data, newData, oldData }, () => resolve());
        //     }).then(() => this.handleUpdate()),
        //   onRowDelete: oldData =>
        //     new Promise((resolve, reject) => {
        //       let data = [...dataArr];
        //       const index = data.indexOf(oldData);
        //       data.splice(index, 1);
        //       // handleState('people0', data);
        //       this.setState({ dataArr: data, oldData }, () => resolve());
        //     }).then(() => this.handleDelete()),
        // }}
        />
      </div>
    </>)
  }
}