import React, { Component } from 'react'
import { Select } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Moment from 'moment';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf, typeCurrency } from 'views/web/CustomMaterialTable';

const columns = () => ([
  { title: '員工編號', field: 'jobNum', editable: 'never' },
  { title: '姓名', field: 'name', editable: 'never' },
  { title: '12月', field: 'amount12', ...typeCurrency },
  { title: '1月', field: 'amount1', ...typeCurrency },
  { title: '2月', field: 'amount2', ...typeCurrency },
  { title: '3月', field: 'amount3', ...typeCurrency },
  { title: '4月', field: 'amount4', ...typeCurrency },
  { title: '5月', field: 'amount5', ...typeCurrency },
  { title: '6月', field: 'amount6', ...typeCurrency },
  { title: '7月', field: 'amount7', ...typeCurrency },
  { title: '8月', field: 'amount8', ...typeCurrency },
  { title: '9月', field: 'amount9', ...typeCurrency },
  { title: '10月', field: 'amount10', ...typeCurrency },
  { title: '11月', field: 'amount11', ...typeCurrency },
  { title: '薪資合計', field: 'amountTotal', ...typeCurrency },
  { title: '代收稅款', field: 'amountTax', ...typeCurrency },
]);

export default class Fin1113 extends Component {
  state = {
    dataArr: [],
    deleteConfirm: false,
    loading: true,
    mgyearSel: '',
    mgyearArr: [],
    monthSel: Moment(new Date()).format('YYYY-MM') || '',
  }

  async componentDidMount() {
    const { companyObj } = this.props;
    const companyId = companyObj.objectId;
    const mgyear = await Parse.queryData('mgyear', { companyId });
    const mgyearArr = mgyear.filter(item => item.value_AD).map(item => ({
      key: item.objectId, text: `${item.value_AD}`, value: item.value_AD
    }));
    let now = String(new Date().getFullYear())
    const mgyearSel = (mgyear.find(item => item.value_AD === now) && mgyear.find(item => item.value_AD === now).value_AD) || ''
    this.setState({ mgyearArr, mgyearSel, mgyear });
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props, type = 1) => {
    const { companyObj } = props;
    const companyId = companyObj.objectId;
    const { mgyearSel } = this.state
    if (type === 1) {
      // const companyId = companyObj.objectId;
      const salary = await Parse.queryData('salary', { companyId, year: mgyearSel });
      const nameIdArr = [];
      salary.forEach(item => {
        if (nameIdArr.indexOf(item.userId) === -1) {
          nameIdArr.push(item.userId);
        }
      });
      const dataArr = nameIdArr.map(item => {
        const funcTotal = (saDataArr, type = 0) => {
          const data = saDataArr.filter(item2 => item2.userId === item)
          let totalAmount = 0;
          let totalTax = 0;
          data.forEach(item2 => totalAmount += Number(item2.amount))
          data.forEach(item2 => totalTax += Number(item2.deduct4))
          if (type === 0) {
            return totalAmount
          }
          else if (type === 1) {
            return totalTax
          }
        }
        const funcFindField = (saDataArr, month, field) => {
          return (saDataArr.find(item2 => item2.userId === item && month === item2.month) &&
            saDataArr.find(item2 => item2.userId === item && month === item2.month)[field]) || 0;
        }
        return {
          jobNum: salary.find(item2 => item2.userId === item).jobNum,
          name: salary.find(item2 => item2.userId === item).name,
          amount12: funcFindField(salary, '12', 'amount'),
          amount1: funcFindField(salary, '01', 'amount'),
          amount2: funcFindField(salary, '02', 'amount'),
          amount3: funcFindField(salary, '03', 'amount'),
          amount4: funcFindField(salary, '04', 'amount'),
          amount5: funcFindField(salary, '05', 'amount'),
          amount6: funcFindField(salary, '06', 'amount'),
          amount7: funcFindField(salary, '07', 'amount'),
          amount8: funcFindField(salary, '08', 'amount'),
          amount9: funcFindField(salary, '09', 'amount'),
          amount10: funcFindField(salary, '10', 'amount'),
          amount11: funcFindField(salary, '11', 'amount'),
          amountTotal: funcTotal(salary, 0),
          amountTax: funcTotal(salary, 1),
        }
      })
      let total12 = 0;
      let total1 = 0;
      let total2 = 0;
      let total3 = 0;
      let total4 = 0;
      let total5 = 0;
      let total6 = 0;
      let total7 = 0;
      let total8 = 0;
      let total9 = 0;
      let total10 = 0;
      let total11 = 0;
      let totalAmount = 0;
      let totalTax = 0;
      dataArr.forEach(item2 => total12 += Number(item2.amount12))
      dataArr.forEach(item2 => total1 += Number(item2.amount1))
      dataArr.forEach(item2 => total2 += Number(item2.amount2))
      dataArr.forEach(item2 => total3 += Number(item2.amount3))
      dataArr.forEach(item2 => total4 += Number(item2.amount4))
      dataArr.forEach(item2 => total5 += Number(item2.amount5))
      dataArr.forEach(item2 => total6 += Number(item2.amount6))
      dataArr.forEach(item2 => total7 += Number(item2.amount7))
      dataArr.forEach(item2 => total8 += Number(item2.amount8))
      dataArr.forEach(item2 => total9 += Number(item2.amount9))
      dataArr.forEach(item2 => total10 += Number(item2.amount10))
      dataArr.forEach(item2 => total11 += Number(item2.amount11))
      dataArr.forEach(item2 => totalAmount += Number(item2.amountTotal))
      dataArr.forEach(item2 => totalTax += Number(item2.amountTax))
      // return {
      const totalObj = {
        jobNum: "total",
        name: "總計",
        amount12: total12,// totalAmount12: total12,
        amount1: total1,// totalAmount1: total1,
        amount2: total2,// totalAmount2: total2,
        amount3: total3,// totalAmount3: total3,
        amount4: total4,// totalAmount4: total4,
        amount5: total5,// totalAmount5: total5,
        amount6: total6,// totalAmount6: total6,
        amount7: total7,// totalAmount7: total7,
        amount8: total8,// totalAmount8: total8,
        amount9: total9,// totalAmount9: total9,
        amount10: total10, // totalAmount10: total10,
        amount11: total11,// totalAmount11: total11,
        amountTotal: totalAmount,   // total: totalAmount,
        amountTax: totalTax,// totalTax: totalTax,
      }
      // })
      // console.log(totalObj)
      dataArr.push(totalObj)
      this.setState({ dataArr, loading: false, });
    } else {
      // const [year, month] = mgyearSel.split('-');
      const salary = await Parse.queryData('salary', { companyId, year: mgyearSel });
      const nameIdArr = [];
      salary.forEach(item => {
        if (nameIdArr.indexOf(item.userId) === -1) {
          nameIdArr.push(item.userId);
        }
      });
      const dataArr = nameIdArr.map(item => {
        const funcTotal = (saDataArr, type = 0) => {
          const data = saDataArr.filter(item2 => item2.userId === item)
          let totalAmount = 0;
          let totalTax = 0;
          data.forEach(item2 => totalAmount += Number(item2.amount))
          data.forEach(item2 => totalTax += Number(item2.deduct4))
          if (type === 0) {
            return totalAmount
          } else if (type === 1) {
            return totalTax
          }
        }
        const funcFindField = (saDataArr, month, field) => {
          return (saDataArr.find(item2 => item2.userId === item && month === item2.month) &&
            saDataArr.find(item2 => item2.userId === item && month === item2.month)[field]) || 0;
        }
        return {
          jobNum: salary.find(item2 => item2.userId === item).jobNum,
          name: salary.find(item2 => item2.userId === item).name,
          amount12: funcFindField(salary, '12', 'amount'),
          amount1: funcFindField(salary, '01', 'amount'),
          amount2: funcFindField(salary, '02', 'amount'),
          amount3: funcFindField(salary, '03', 'amount'),
          amount4: funcFindField(salary, '04', 'amount'),
          amount5: funcFindField(salary, '05', 'amount'),
          amount6: funcFindField(salary, '06', 'amount'),
          amount7: funcFindField(salary, '07', 'amount'),
          amount8: funcFindField(salary, '08', 'amount'),
          amount9: funcFindField(salary, '09', 'amount'),
          amount10: funcFindField(salary, '10', 'amount'),
          amount11: funcFindField(salary, '11', 'amount'),
          amountTotal: funcTotal(salary, 0),
          amountTax: funcTotal(salary, 1),
        }
      })
      console.log(dataArr)
      let total12 = 0;
      let total1 = 0;
      let total2 = 0;
      let total3 = 0;
      let total4 = 0;
      let total5 = 0;
      let total6 = 0;
      let total7 = 0;
      let total8 = 0;
      let total9 = 0;
      let total10 = 0;
      let total11 = 0;
      let totalAmount = 0;
      let totalTax = 0;
      dataArr.forEach(item2 => total12 += Number(item2.amount12))
      dataArr.forEach(item2 => total1 += Number(item2.amount1))
      dataArr.forEach(item2 => total2 += Number(item2.amount2))
      dataArr.forEach(item2 => total3 += Number(item2.amount3))
      dataArr.forEach(item2 => total4 += Number(item2.amount4))
      dataArr.forEach(item2 => total5 += Number(item2.amount5))
      dataArr.forEach(item2 => total6 += Number(item2.amount6))
      dataArr.forEach(item2 => total7 += Number(item2.amount7))
      dataArr.forEach(item2 => total8 += Number(item2.amount8))
      dataArr.forEach(item2 => total9 += Number(item2.amount9))
      dataArr.forEach(item2 => total10 += Number(item2.amount10))
      dataArr.forEach(item2 => total11 += Number(item2.amount11))
      dataArr.forEach(item2 => totalAmount += Number(item2.amountTotal))
      dataArr.forEach(item2 => totalTax += Number(item2.amountTax))
      // return {
      const totalObj = {
        jobNum: "total",
        name: "總計",
        amount12: total12,// totalAmount12: total12,
        amount1: total1,// totalAmount1: total1,
        amount2: total2,// totalAmount2: total2,
        amount3: total3,// totalAmount3: total3,
        amount4: total4,// totalAmount4: total4,
        amount5: total5,// totalAmount5: total5,
        amount6: total6,// totalAmount6: total6,
        amount7: total7,// totalAmount7: total7,
        amount8: total8,// totalAmount8: total8,
        amount9: total9,// totalAmount9: total9,
        amount10: total10, // totalAmount10: total10,
        amount11: total11,// totalAmount11: total11,
        amountTotal: totalAmount,   // total: totalAmount,
        amountTax: totalTax,// totalTax: totalTax,
      }
      // })
      // console.log(totalObj)
      dataArr.push(totalObj)
      this.setState({ dataArr, loading: false, });
    }
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    this.setState({ [name]: value, loading: true }, () => this.handleFetch(this.props, 0));
  }

  render() {
    const { dataArr, loading, mgyearArr, mgyearSel } = this.state;
    return (<>
      {/* 資金需求預估表 */}
      <br />
      <Select style={{
        position: 'relative', zIndex: 99,
        float: 'left',
        minWidth: '200px',
        // borderTopLeftRadius: '0',
        // borderBottomLeftRadius: '0'
      }}
        compact
        name="mgyearSel"
        options={mgyearArr}
        value={mgyearSel}
        onChange={this.handleChange}
      />
      <br />
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearSel} 年薪資清單`),
          pageSize: dataArr.length || 10,
          search: false
        }}
        data={dataArr}
        title={`${mgyearSel} 年薪資清單`}
      />
      <br />
      <br />
    </>)
  }
}