import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import Moment from 'moment';

import { Row, Col } from "reactstrap";
import { dateCol, mgyearOpt, monthOpt, codeOpt, exportPdf } from 'views/web/CustomMaterialTable';
import ModalPdf from '../../widget/pdf/ModalPdf';

const positionOpt = { '1': '店長', '2': '會計', '3': '櫃檯' }

const columns = () => ([
  { width: 40, title: '代號', field: 'jobNum', editable: 'never' },
  { width: 40, title: '職位', field: 'position', editable: 'never' },
  { width: 80, title: '姓名', field: 'name', editable: 'never' },
  dateCol({ title: '到職日', field: 'arrivalDay', editable: 'never' }),
  { width: 80, title: '底薪', field: 'baseSalary' },
  { width: 80, title: '全勤', field: 'fullAttendance' },
  { width: 80, title: '工作獎金', field: 'workBonus' },
  { width: 80, title: '伙食', field: 'foodStipend' },
  { width: 80, title: '佣金', field: 'commission', editable: 'never' },
  { width: 80, title: '資保息', field: 'capitalGuaranteed', editable: 'never' },
  { width: 80, title: '油資', field: 'oilMoney' },
  { width: 80, title: '代墊', field: 'interestUnPaidSelf' },
  { width: 80, title: '合計', field: 'priceTotal', editable: 'never' },
]);

const d = new Date()
class SalaryList extends Component {
  state = {
    dataArr: [],
    rowData: {},
    loading: true,
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
    employeeArr: []
  }

  componentDidMount = async () => {
    this.handleFilter();
  }

  componentWillReceiveProps(nextProps) {
    // this.handleFetch(nextProps);
  }

  // handleFetch = async (props = this.props) => {
  //   const { companyObj } = this.props
  //   const companyId = companyObj.objectId
  //   const { employeeArr, mgyearSel, monthSel } = this.state
  //   const data = await Parse.queryData('User', { authLoginA: companyId });

  //   data.forEach(async (employeeData) => {

  //     let performanceTotal = 0
  //     let interestPlanSelfTotal = 0
  //     let interestPaidSelfTotal = 0
  //     let commissionTotal = 0

  //     const data = await Parse.queryData('pawning2', { jointArr: employeeData.objectId, year: mgyearSel, month: monthSel });
  //     const data1 = data.map(item => {
  //       const { jointObjArr, principalRest, interestPlan, interestPaid } = item;
  //       const jointObj = jointObjArr.find(item2 => item2.objectId === employeeData.objectId)
  //       if (jointObj) {
  //         const { proportion } = jointObj

  //         const performance = principalRest ? Number(principalRest) * Number(proportion) / 100 : 0;
  //         const interestPlanSelf = interestPlan ? Number(interestPlan) * Number(proportion) / 100 : 0;
  //         const interestPaidSelf = interestPaid ? Number(interestPaid) * Number(proportion) / 100 : 0;
  //         const commission = interestPaidSelf ? Number(interestPaidSelf) * Number(employeeData.interest) / 100 : 0;

  //         performanceTotal += performance;
  //         interestPlanSelfTotal += interestPlanSelf;
  //         interestPaidSelfTotal += interestPaidSelf;
  //         commissionTotal += commission;

  //         return { ...item, performance, interestPlanSelf, interestPaidSelf, commission }
  //       } else {
  //         return item;
  //       }
  //     })
  //     const interestUnPaidSelfTotal = interestPlanSelfTotal - interestPaidSelfTotal;

  //     const obj = {
  //       objectId: employeeData.objectId,
  //       name: employeeData.name,
  //       jobNum: employeeData.jobNum,
  //       capitalGuaranteed: String(Number(employeeData.marginTotal) * Number(employeeData.interest) * 0.01),
  //       performanceTotal,
  //       interestPlanSelfTotal,
  //       interestPaidSelfTotal,
  //       interestUnPaidSelfTotal,
  //       commissionTotal,
  //       mgyearSel, monthSel
  //     }
  //     employeeArr.push(obj)
  //     this.setState({ employeeArr, dataArr: employeeArr })
  //     // this.setState({
  //     //   dataArr: data1, data1, loading: false,
  //     //   performanceTotal,
  //     //   interestPlanSelfTotal,
  //     //   interestPaidSelfTotal,
  //     //   interestUnPaidSelfTotal,
  //     //   commissionTotal
  //     // });
  //   })

  //   this.setState({ dataArr: data, loading: false });
  // }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value, totalAccId: value, loading: true }, () => this.handleFilter())
  }

  handleFilter = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const { dataArr, totalAccId, mgyearSel, monthSel } = this.state
    const data = await Parse.queryData('salaryslip', { companyId, year: mgyearSel, month: monthSel });

    this.setState({ dataArr: data, loading: false })
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    await Parse.saveData('salaryslip', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    Parse.deleteData('salaryslip', oldData);
    this.setState({ oldData: {} })
  }

  render() {
    const { companyObj } = this.props
    const { dataArr, loading, mgyearSel, monthSel } = this.state;
    console.log(dataArr);
    const arrPdf = [{
      companyObj,
      dataArr
    }]
    return (<>
      <div className="content">
        <Row style={{ marginBottom: '20px' }}>
          <Col><legend>薪資一覽</legend></Col>
        </Row>
        <Select name="mgyearSel" style={{ position: 'relative', zIndex: 99, marginRight: '8px' }} options={mgyearOpt} value={mgyearSel} onChange={this.handleChange} />
        <Select name="monthSel" style={{ position: 'relative', zIndex: 99 }} options={monthOpt} value={monthSel} onChange={this.handleChange} />
        <br />
        <br />
        <ModalPdf
          {...this.props}
          // ref={(ref) => { this.pdfModal = ref }}
          // codekey='printTest'
          nowform={'1'}
          params={arrPdf}
          arrObj={dataArr}
        />
        <br />
        <br />
        <MaterialTable
          isLoading={loading}
          localization={localization()}
          columns={columns()}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportPdf: (columns, data) => exportPdf(columns, data, `查詢結果`),
            pageSize: dataArr.length < 5 ? 5 : dataArr.length + 1,
            search: false,
            tableLayout: 'fixed',
          }}
          data={dataArr}
          tableRef={this.tableRef}
          title={`查詢結果`}
        // editable={{
        // onRowAdd: newData =>
        //   new Promise(async (resolve, reject) => {
        //     const data = [...dataArr];
        //     const db = Parse.Object.extend("salaryslip");
        //     const newDoc = new db();
        //     const doc = await newDoc.save();
        //     newData.objectId = doc.id;
        //     newData.id = doc.id;
        //     newData._id = doc.id;
        //     newData.employeeId = employeeId;

        //     data.push(newData);
        //     newData.priceTotal = Number(newData.baseSalary) + Number(newData.fullAttendance) + Number(newData.postAddition) +
        //       Number(newData.foodStipend) + Number(newData.healthStipend) + Number(newData.duanwuGift) + Number(newData.oilMoney);

        //     this.setState({ dataArr: data, newData }, () => resolve());
        //   }).then(() => this.handleUpdate()),
        // onRowUpdate: (newData, oldData) =>
        //   new Promise((resolve, reject) => {
        //     const data = [...dataArr];
        //     const index = data.indexOf(oldData);
        //     newData.priceTotal = String(Number(newData.baseSalary || 0) + Number(newData.fullAttendance || 0) + Number(newData.workBonus || 0) +
        //       Number(newData.foodStipend || 0) + Number(newData.commissionTotal || 0) + Number(newData.capitalGuaranteed || 0)
        //       + Number(newData.duanwuGift || 0) + Number(newData.oilMoney || 0));
        //     newData.year = mgyearSel
        //     newData.month = monthSel
        //     data[index] = newData;
        //     this.setState({ dataArr: data, newData, oldData }, () => resolve());
        //   }).then(() => this.handleUpdate()),
        // onRowDelete: oldData =>
        //   new Promise((resolve, reject) => {
        //     let data = [...dataArr];
        //     const index = data.indexOf(oldData);
        //     data.splice(index, 1);
        //     this.setState({ dataArr: data, oldData }, () => resolve());
        //   }).then(() => this.handleDelete()),
        // }}
        />
      </div>
    </>)
  }
}

export default SalaryList;