import Buttons from "views/components/Buttons.jsx";
import Calendar from "views/Calendar.jsx";
import Charts from "views/Charts.jsx";
import Dashboard from "views/Dashboard.jsx";
import ExtendedForms from "views/forms/ExtendedForms.jsx";
import ExtendedTables from "views/tables/ExtendedTables.jsx";
import FullScreenMap from "views/maps/FullScreenMap.jsx";
import GoogleMaps from "views/maps/GoogleMaps.jsx";
import GridSystem from "views/components/GridSystem.jsx";
import Icons from "views/components/Icons.jsx";
import LockScreen from "views/pages/LockScreen.jsx";
import Login from "views/pages/Login.jsx";
import ResetPassword from "views/pages/ResetPassword.jsx";
import Notifications from "views/components/Notifications.jsx";
import Panels from "views/components/Panels.jsx";
import ReactTables from "views/tables/ReactTables.jsx";
import Register from "views/pages/Register.jsx";
import RegularForms from "views/forms/RegularForms.jsx";
import RegularTables from "views/tables/RegularTables.jsx";
import SweetAlert from "views/components/SweetAlert.jsx";
import Timeline from "views/pages/Timeline.jsx";
import Typography from "views/components/Typography.jsx";
import UserProfile from "views/pages/UserProfile.jsx";
import UserChangePW from "views/pages/UserChangePW.jsx";
import ValidationForms from "views/forms/ValidationForms.jsx";
import VectorMap from "views/maps/VectorMap.jsx";
import Widgets from "views/Widgets.jsx";
import Wizard from "views/forms/Wizard.jsx";

// import HomepagePost from "views/webadmin/HomepagePost";
// import webDesign from "views/webadmin/webDesign.jsx";
// import Postmanage from "views/webadmin/Postmanage";
// import ShowPicture from "views/webadmin/ShowPicture";

import Bonus from 'views/MLM/Bonus'
import ReserveLine from 'views/Sweety/ReserveLine'

import ClockIn from 'views/clock/ClockIn'
import ClockManage from 'views/clock/ClockManage'
import ClockSalary from 'views/clock/ClockSalary'

import LineNews from "views/line/LineNews";
import LineSetting from "views/line/LineSetting";
import LineMessage from "views/line/LineMessage";
import LineChat from "views/line/LineChat";
import Companies from "views/system/Companies";

//web
import FileCenter from 'views/web/FileCenter';
import AlbumCenter from 'views/web/AlbumCenter';
import AnnounceCenter from 'views/web/AnnounceCenter';

import System from "views/system/System";
import Theme from "views/system/Theme";
import Role from "views/system/Role";
import AllUsers from "views/system/AllUsers";
import PrintPage from "views/temple/PrintPage";
import Users from "views/system/Users";
import LineRichMenu from "views/line/LineRichMenu";

export const common = [
  {
    webtypeArr: ['useMLM'],
    collapse: true,
    name: "紅利",
    icon: "nc-icon nc-diamond",
    state: "BonusCollapse",
    views: [
      {
        path: "/Bonus",
        params: '/:clientId?/:t1?/:t1Id?',
        name: "查詢",
        mini: "1",
        component: Bonus,
        layout: "/admin"
      },
      {
        path: "/ReserveLine",
        params: '/:clientId?/:t1?/:t1Id?',
        name: "預約管理",
        mini: "1",
        component: ReserveLine,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['useAttendance'],
    collapse: true,
    name: "差勤管理",
    icon: "nc-icon nc-watch-time",
    state: "ClockCollapse",
    views: [
      {
        path: "/ClockIn",
        name: "員工打卡",
        mini: "1",
        component: ClockIn,
        layout: "/admin"
      },
      {
        path: "/ClockManage",
        name: "出勤管理",
        mini: "2",
        component: ClockManage,
        layout: "/admin"
      },
      {
        path: "/ClockSalary",
        name: "薪資計算",
        mini: "3",
        component: ClockSalary,
        layout: "/admin"
      },
      // {
      //   path: "/HomepagePost",
      //   name: "年度所得",
      //   mini: "4",
      //   // component: HomepagePost,
      //   layout: "/admin"
      // },
    ]
  },
  {
    webtypeArr: ['useLine'],
    collapse: true,
    name: "Line 訊息",
    icon: "nc-icon nc-chat-33",
    state: "LineCollapse",
    views: [
      {
        path: "/LineNews",
        name: "最新消息",
        mini: "News",
        component: LineNews,
        layout: "/admin"
      },
      {
        path: "/LineChat",
        name: "所有訊息",
        mini: "Chat",
        component: LineChat,
        layout: "/admin"
      },
      {
        path: "/LineMessage",
        name: "自動回覆",
        mini: "Reply",
        component: LineMessage,
        layout: "/admin"
      },
      {
        path: "/LineSetting",
        name: "Line設定",
        mini: "Set",
        component: LineSetting,
        layout: "/admin"
      },
    ]
  },
  {
    webtypeArr: ['useResource'],
    collapse: true,
    name: "檔案",
    icon: "nc-icon nc-circle-10",
    state: "internetCollapse",
    views: [
      {
        path: "/AnnounceCenter",
        name: "公告",
        mini: "6-1",
        component: AnnounceCenter,
        layout: "/admin"
      },
      {
        path: "/AlbumCenter",
        name: "相簿",
        mini: "6-2",
        component: AlbumCenter,
        layout: "/admin"
      },
      {
        path: "/FileCenter",
        name: "文件",
        mini: "6-3",
        component: FileCenter,
        layout: "/admin"
      },
    ]
  },
  {
    collapse: true,
    name: "系統",
    icon: "nc-icon nc-settings",
    state: "SystemCollapse",
    views: [
      {
        path: "/Users",
        name: "使用者",
        mini: "user",
        component: Users,
        layout: "/admin"
      },
      {
        path: "/System",
        name: "設定",
        mini: "設",
        component: System,
        layout: "/admin"
      },
      {
        path: "/Theme",
        name: "主題",
        mini: "主",
        component: Theme,
        layout: "/admin"
      },
      // {
      //   path: "/PrintPage",
      //   name: "列印(開發中)",
      //   mini: "印",
      //   component: PrintPage,
      //   layout: "/admin"
      // },
    ]
  },
  {
    webtypeArr: ['localhost'],
    collapse: true,
    name: "全域系統",
    icon: "nc-icon nc-world-2",
    state: "SettingCollapse",
    views: [
      // {
      //   path: "/Sector",
      //   name: "所有行業",
      //   mini: "行業",
      //   component: Sector,
      //   layout: "/admin"
      // },
      {
        path: "/LineRichMenu",
        name: "Line圖文選單",
        mini: "Rich",
        component: LineRichMenu,
        layout: "/admin"
      },
      {
        path: "/Companies",
        name: "所有公司",
        mini: "1",
        component: Companies,
        layout: "/admin"
      },
      {
        path: "/allusers",
        name: "所有使用者",
        mini: "2",
        component: AllUsers,
        layout: "/admin"
      },
      {
        path: "/role",
        name: "角色",
        mini: "3",
        component: Role,
        layout: "/admin"
      },
      {
        path: "/PrintPage",
        name: "列印",
        mini: "4",
        component: PrintPage,
        layout: "/admin"
      },
    ]
  },

]

export const develop = [
  // {
  //   webtypeArr: ['develop'],
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: "nc-icon nc-bank",
  //   component: Dashboard,
  //   layout: "/admin"
  // },
  {
    webtypeArr: ['develop'],
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin"
  },
  {
    webtypeArr: ['develop'],
    collapse: true,
    name: "Pages",
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    views: [
      {
        path: "/timeline",
        name: "Timeline",
        mini: "T",
        component: Timeline,
        layout: "/admin"
      },
      {
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth"
      },
      {
        path: "/resetpassword",
        name: "ResetPassword",
        mini: "R",
        component: ResetPassword,
        layout: "/auth"
      },
      {
        path: "/register",
        name: "Register",
        mini: "R",
        component: Register,
        layout: "/auth"
      },
      {
        path: "/lock-screen",
        name: "LockScreen",
        mini: "LS",
        component: LockScreen,
        layout: "/auth"
      },
      {
        path: "/user-profile",
        name: "UserProfile",
        mini: "UP",
        component: UserProfile,
        layout: "/admin"
      },
      {
        path: "/user-changepw",
        name: "UserChangePW",
        mini: "UP",
        component: UserChangePW,
        layout: "/admin"
      }
    ]
  },
  {
    webtypeArr: ['develop'],
    collapse: true,
    name: "Components",
    icon: "nc-icon nc-layout-11",
    state: "componentsCollapse",
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        mini: "B",
        component: Buttons,
        layout: "/admin"
      },
      {
        path: "/grid-system",
        name: "Grid System",
        mini: "GS",
        component: GridSystem,
        layout: "/admin"
      },
      {
        path: "/panels",
        name: "Panels",
        mini: "P",
        component: Panels,
        layout: "/admin"
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        mini: "SA",
        component: SweetAlert,
        layout: "/admin"
      },
      {
        path: "/notifications",
        name: "Notifications",
        mini: "N",
        component: Notifications,
        layout: "/admin"
      },
      {
        path: "/icons",
        name: "Icons",
        mini: "I",
        component: Icons,
        layout: "/admin"
      },
      {
        path: "/typography",
        name: "Typography",
        mini: "T",
        component: Typography,
        layout: "/admin"
      }
    ]
  },
  {
    webtypeArr: ['develop'],
    collapse: true,
    name: "Forms",
    icon: "nc-icon nc-ruler-pencil",
    state: "formsCollapse",
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        mini: "RF",
        component: RegularForms,
        layout: "/admin"
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        mini: "EF",
        component: ExtendedForms,
        layout: "/admin"
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        mini: "VF",
        component: ValidationForms,
        layout: "/admin"
      },
      {
        path: "/wizard",
        name: "Wizard",
        mini: "W",
        component: Wizard,
        layout: "/admin"
      }
    ]
  },
  {
    webtypeArr: ['develop'],
    collapse: true,
    name: "Tables",
    icon: "nc-icon nc-single-copy-04",
    state: "tablesCollapse",
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        mini: "RT",
        component: RegularTables,
        layout: "/admin"
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        mini: "ET",
        component: ExtendedTables,
        layout: "/admin"
      },
      {
        path: "/react-tables",
        name: "React Tables",
        mini: "RT",
        component: ReactTables,
        layout: "/admin"
      }
    ]
  },
  {
    webtypeArr: ['develop'],
    collapse: true,
    name: "Maps",
    icon: "nc-icon nc-pin-3",
    state: "mapsCollapse",
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        mini: "GM",
        component: GoogleMaps,
        layout: "/admin"
      },
      {
        path: "/full-screen-map",
        name: "Full Screen Map",
        mini: "FSM",
        component: FullScreenMap,
        layout: "/admin"
      },
      {
        path: "/vector-map",
        name: "Vector Map",
        mini: "VM",
        component: VectorMap,
        layout: "/admin"
      }
    ]
  },
  {
    webtypeArr: ['develop'],
    path: "/widgets",
    name: "Widgets",
    icon: "nc-icon nc-box",
    component: Widgets,
    layout: "/admin"
  },
  {
    webtypeArr: ['develop'],
    path: "/charts",
    name: "Charts",
    icon: "nc-icon nc-chart-bar-32",
    component: Charts,
    layout: "/admin"
  },
  {
    webtypeArr: ['develop'],
    path: "/calendar",
    name: "Calendar",
    icon: "nc-icon nc-calendar-60",
    component: Calendar,
    layout: "/admin"
  }
]