import React, { Component } from 'react';
import { Container, Header, Form, Icon, Grid, Button, Select, Dropdown, Table } from 'semantic-ui-react'
import { FaLock, FaLockOpen } from "react-icons/fa";
import Moment from 'moment';
import ReactToPrint from "react-to-print";

import { getNationalityArr } from '../../widget/define/students'
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton'

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export default class SchoolHour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: { selTeacherId: ' ', },
      selArr: props.selArr ? props.selArr : [],
      isEdit: props.isEdit || false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.getTeacherNameById = this.getTeacherNameById.bind(this);
  }
  componentWillMount() {
    const { semesterYear, semester, teachersArrange, selArr } = this.props;
    if (!selArr && teachersArrange && teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester)) {
      this.setState({ selArr: teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester).selArr });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { semesterYear, semester, teachersArrange, selArr } = nextProps;

    if (selArr) {
      this.setState({ selArr });
    } else if (teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester)) {
      this.setState({ selArr: teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester).selArr });
    } else {
      this.setState({ selArr: [] });
    }
  }

  handleChange = (event, { name, value }) => {
    this.setState({ form: { ...this.state.form, [name]: value } })
  };

  getTeacherNameById = (id) => {
    // const { selArr } = this.state;
    const { teachers } = this.props;
    // console.log(teachers);
    // return teachers.find(item => item._id === id).nameTeacher;
    return (teachers.find(item => item._id === id) ? teachers.find(item => item._id === id).nameTeacher : '');
  }

  // getCurrentSelarr() {
  //   const { teachersArrange, semester, semesterYear } = this.props;

  //   let selArr = [];
  //   // if (teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester
  //   //   || item.semesterYear === semesterYear && item.semester === "S"
  //   //   || item.semesterYear === (Number(semesterYear) - 1) + '')) {
  //   //   selArr = teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester
  //   //     || item.semesterYear === semesterYear && item.semester === "S"
  //   //     || item.semesterYear === (Number(semesterYear) - 1) + '').selArr;
  //   // }
  //   if (teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester)) {
  //     selArr = teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester).selArr;
  //   }
  //   // (item.semesterYear === semesterYear && item.semester === semester
  //   //   || item.semesterYear === semesterYear && item.semester === "S"
  //   //   || item.semesterYear === (Number(semesterYear) - 1) + '')
  //   this.setState({ selArr });
  //   // console.log(selArr);
  // }

  getTeacherBGColor = (setting, period, weekday) => {
    let isDisabled = this.getDisabled(setting, period, weekday);
    // console.log("period", period, "weekday", weekday, "isDisabled", isDisabled);
    let color = {};
    if (!isDisabled) {
      color = { color: '#cc0000' }; // 還沒鎖定的
    }
    return (
      this.getTeacherId(setting, period, weekday) &&
        this.getTeacherId(setting, period, weekday) === this.state.form.selTeacherId ?
        { backgroundColor: 'yellow', ...color } : { cursor: 'pointer', ...color }
    );
  }

  setSelTeacherId = (setting, period, weekday) => {
    let selTeacherId = this.getTeacherId(setting, period, weekday);
    if (this.state.form.selTeacherId === selTeacherId) {
      selTeacherId = '';
    }
    this.setState({ form: { ...this.state.form, selTeacherId } });
  };

  getDisabled = (setting, period, weekday) => {
    const { selArr } = this.state;

    return (
      selArr.find(item => item.classroomId === setting._id && item.period == period && item.weekday == weekday) ?
        selArr.find(item => item.classroomId === setting._id && item.period == period && item.weekday == weekday).disabled : -1
    );
  };

  getTeacherId = (setting, period, weekday) => {
    const { selArr } = this.state;
    // console.log(setting, period, weekday)

    return (
      selArr.find(item => item.classroomId === setting._id && item.period == period && item.weekday == weekday) ?
        selArr.find(item => item.classroomId === setting._id && item.period == period && item.weekday == weekday).teacherId : ''
    );
  };

  isTeacherSelected = (setting, period, weekday) => {
    const { selArr } = this.state;

    // 檢査此班此時段是否有老師被選
    return !!selArr.find(item => item.classroomId === setting._id && item.period === period && item.weekday === weekday && item.teacherId !== '');
  };

  isSelectedAndLocked = (teacherObj, setting, period, weekday) => {
    const { selArr } = this.state;
    // 檢査是否有同時段選擇此老師並鎖定
    return !!selArr.find(item => item.classroomId !== setting._id && item.period === period && item.weekday === weekday && item.disabled === true && item.teacherId === teacherObj._id);
  };

  isDropdownLocked = (setting, period, weekday) => {
    const { selArr } = this.state;

    // 檢査這個時段是否被鎖定了
    return !!selArr.find(item => item.classroomId === setting._id && item.period === period && item.weekday === weekday && item.disabled === true);
  };

  isTeacherAvailable = (teacherObj, setting, period, weekday) => {
    return (
      teacherObj.arrangeArr
        && teacherObj.arrangeArr.find(item => item.semesterYear === setting.semesterYear && item.semester === setting.semester)
        && teacherObj.arrangeArr.find(item => item.semesterYear === setting.semesterYear && item.semester === setting.semester).slotArr
        && teacherObj.arrangeArr.find(item => item.semesterYear === setting.semesterYear && item.semester === setting.semester).slotArr.includes(`${period}${weekday}`)
        && !this.isSelectedAndLocked(teacherObj, setting, period, weekday)
        ? true : false
    );
  };

  getSmartTd = (setting, p, w) => {
    const { teachers, type } = this.props;
    const { isEdit } = this.state;
    const selTeacherId = this.getTeacherId(setting, p, w);
    const selTeacherName = this.getTeacherNameById(selTeacherId);
    const availTeachers = teachers.filter(teacherObj => teacherObj._id !== selTeacherId && this.isTeacherAvailable(teacherObj, setting, p, w));
    // const availTeachersArr = availTeachers.map(({ _id, nameTeacher }) => (
    //   { key: _id, value: `${_id},${setting._id},${p},${w}`, text: nameTeacher }))

    // console.log(setting, p, w, selTeacherName);
    return (
      isEdit ?
        <Table.Cell key={`${p}_${w}`} style={this.getTeacherBGColor(setting, p, w)} onClick={() => this.setSelTeacherId(setting, p, w)}>
          {/* {type !== '1' ? <> */}
          <Button
            className='no-print'
            value={`${setting._id},${p},${w}`} disabled={!this.isTeacherSelected(setting, p, w)}
            onClick={(e) => this.props.onClick(e)}
            size='tiny'
          >
            {this.isDropdownLocked(setting, p, w) ? <FaLock /> : <FaLockOpen style={{ color: '#cc0000' }} />}
          </Button>
          <div className='no-print' style={{ height: '4px' }}></div>
          {
            !this.isDropdownLocked(setting, p, w) ?
              <Dropdown id="button-dropdown" text={selTeacherName} style={{ color: 'black' }}>
                {/* <Dropdown.Toggle size="small" variant="light">{selTeacherName}</Dropdown.Toggle> */}
                <Dropdown.Menu>
                  {selTeacherName ? <Dropdown.Item value={`,${setting._id},${p},${w}`} onClick={(e, data) => this.props.onSelect(e, data)}><span style={{ color: 'red' }} >クリア</span></Dropdown.Item> : null}
                  {availTeachers.map(({ _id, nameTeacher }) =>
                    <React.Fragment key={_id}>
                      <Dropdown.Item value={`${_id},${setting._id},${p},${w}`} onClick={(e, data) => this.props.onSelect(e, data)}>{nameTeacher}</Dropdown.Item>
                    </React.Fragment>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              : selTeacherName
          }
          {/* </> : selTeacherName} */}
        </Table.Cell>
        :
        <Table.Cell key={`${p}_${w}`} style={this.getTeacherBGColor(setting, p, w)} onClick={() => this.setSelTeacherId(setting, p, w)}>
          {selTeacherName}
        </Table.Cell>
    )
  };
  accTeacher = (arr, teacherId) => {
    if (!this.props.teachers.find(item => item._id === teacherId)) {
      return;
    }
    const index = arr.findIndex(item => item.teacherId === teacherId);
    const nameTeacher = this.props.teachers.find(item => item._id === teacherId).nameTeacher || '';
    const partTimeTeacherText = this.props.teachers.find(item => item._id === teacherId).partTimeTeacherText || '';
    if (index == -1) {
      arr.push({ teacherId, nameTeacher, partTimeTeacherText, count: 1 })
    } else {
      arr[index].count++;
    }
  }

  statClassTeacher = (semesterYear, semester, classSchool) => {
    // const obj = this.state.selArr.find(item => item.semesterYear === semesterYear && item.semester === semester && item.classSchool === classSchool);
    // console.log(obj);
    const { selArr } = this.state;
    const teacherSameClassArr = selArr.filter(item => item.semesterYear === semesterYear && item.semester === semester && item.classSchool === classSchool && item.teacherId);
    // console.log(semesterYear, semester, classSchool, teacherSameClassArr);

    const accArr = [];

    teacherSameClassArr.forEach(element => {
      this.accTeacher(accArr, element.teacherId);
    });

    // console.log(accArr)
    let ret = accArr.map(item => item.nameTeacher + ' ' + item.count + '\n');

    // return `${semesterYear} ${semester} ${classSchool}`;
    return accArr.sort((a, b) => b.count - a.count);
  }
  statStudent = () => {
    const { classroom, semesterYear, semester, students } = this.props;
    // const { students } = this.state;
    const classRoomArr = classroom.filter(item => item.isEnableClass === true && item.semesterYear === semesterYear && item.semester === semester);

    const arr = [];
    classRoomArr.forEach(item1 => {
      const { entryYear, entryMonth, classSchool } = item1;
      const entrySemester = entryMonth === '10' ? 'A' : 'S';
      // console.log(entryYear, entrySemester, classSchool);
      const stu = students && students.length && students
        .filter(item2 => (item2.semesterYear === entryYear && item2.semester === entrySemester && item2.classSchool === classSchool)) || [];
      const total = stu.length;
      // console.log(students);
      arr.push({ entryYear, entrySemester, classSchool, total });
    });

    // console.log(arr);
    return arr;
  }
  customizedText = (students) => {
    const nationalityArr = getNationalityArr(students);
    const studentsUnknownNation = students.filter(item => nationalityArr.indexOf(item.nationality) === -1);
  }

  render() {
    const { formSet, optSet, teachers, history, match, classroom, semesterYear, semester, students } = this.props;
    const { isEdit } = this.state;
    const { selTeacherId } = this.state.form;

    const weekday = ['0', '1', '2', '3', '4'];
    const period = [['0', '2'], ['4', '6']];

    const classRoomArr = classroom.filter(item => item.isEnableClass === true && item.semesterYear === semesterYear && item.semester === semester);
    // console.log(classRoomArr)
    const statArr = this.statStudent();
    const optionsData = teachers.map(item => ({ key: item._id, value: item._id, text: item.nameTeacher }))
    const optionsArr = [
      { key: 'aaa', text: '全部', value: ' ' },
      ...optionsData
    ]
    return (<div ref={el => (this.componentRef = el)} className="">
      <div style={style.flexCenter}>
        <Header as='h3'>授業時間配当表 {semesterYear}年{semester === "S" ? '4月1日' : '10月1日'}～{semester === "S" ? semesterYear : (Number(semesterYear) + 1).toString()}年{semester === "S" ? '9月30日' : '3月31日'} </Header>
        <Form floated='right' >
          <ReactToPrint
            trigger={(e) => <Button
              content='印刷'
              // label='&nbsp;'
              color='orange' icon='print'
              // size='small'
              floated='right'
              className='no-print'
            />}
            content={() => this.componentRef}
            pageStyle={"@page { size: auto;  margin:5mm; }*{font-family: 'Noto Serif JP', serif;}"}
          />
          <ExportTableToExcelButton floated='right'
          // size='small'
          />
          <div style={style.flexCenter}>
            <label style={{ minWidth: 35 }}>先生</label>
            {/* <Form
                as="select" placeholder="select" name="selTeacherId"
                onChange={this.handleChange} value={selTeacherId}
              >
                <option value="">全部</option>
                {teachers.map(({ _id, nameTeacher }) => (<option key={_id} value={_id}>{nameTeacher}</option>))}
              </Form> */}
            <Select /*value={selTeacherId}*/ defaultValue={selTeacherId} name="selTeacherId" onChange={this.handleChange} options={optionsArr} />
          </div>
        </Form>
      </div>
      <Table id='excel' size="small" className="thPadding2px tdPadding2px" celled structured style={{ textAlign: 'center', width: '100%' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ textAlign: 'center' }} ></Table.HeaderCell>
            <Table.HeaderCell width="one"></Table.HeaderCell>
            <Table.HeaderCell width="one"></Table.HeaderCell>
            {/* <Table.HeaderCell colSpan='2' style={{ textAlign: 'center' }}>月</Table.HeaderCell>
              <Table.HeaderCell colSpan='2' style={{ textAlign: 'center' }}>火</Table.HeaderCell>
              <Table.HeaderCell colSpan='2' style={{ textAlign: 'center' }}>水</Table.HeaderCell>
              <Table.HeaderCell colSpan='2' style={{ textAlign: 'center' }}>木</Table.HeaderCell>
              <Table.HeaderCell colSpan='2' style={{ textAlign: 'center' }}>金</Table.HeaderCell> */}
            <Table.HeaderCell colSpan='2' style={{ textAlign: 'center' }}>㈪</Table.HeaderCell>
            <Table.HeaderCell colSpan='2' style={{ textAlign: 'center' }}>㈫</Table.HeaderCell>
            <Table.HeaderCell colSpan='2' style={{ textAlign: 'center' }}>㈬</Table.HeaderCell>
            <Table.HeaderCell colSpan='2' style={{ textAlign: 'center' }}>㈭</Table.HeaderCell>
            <Table.HeaderCell colSpan='2' style={{ textAlign: 'center' }}>㈮</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>午前クラス﹒担当</Table.Cell>
            {/* 教室 定員 */}
            <Table.Cell>教室</Table.Cell>
            <Table.Cell>生</Table.Cell>
            <Table.Cell>1 2</Table.Cell>
            <Table.Cell>3 4</Table.Cell>
            <Table.Cell>1 2</Table.Cell>
            <Table.Cell>3 4</Table.Cell>
            <Table.Cell>1 2</Table.Cell>
            <Table.Cell>3 4</Table.Cell>
            <Table.Cell>1 2</Table.Cell>
            <Table.Cell>3 4</Table.Cell>
            <Table.Cell>1 2</Table.Cell>
            <Table.Cell>3 4</Table.Cell>
          </Table.Row>
          {classRoomArr.filter(item => item.classTime === "1" && item.entryMonth === "4").map((setting, index, arr) => (
            <Table.Row key={setting._id} className={index === arr.length - 1 ? "border_bottom" : ''}>
              <Table.Cell>{setting.entryYear.slice(-2)}S-{index + 1}{setting.classSchool}　{setting.teacherText} {setting.teacherTitleText2 ? <>({setting.teacherTitleText2 || ''})</> : ''}</Table.Cell>
              <Table.Cell>{setting.classroom ? setting.classroom : ''}</Table.Cell>
              {/* {index === 0 ? <Table.Cell style={{ 'whiteSpace': 'unset' }}
                  rowSpan={classRoomArr.filter(item => item.classTime === "1" && item.entryMonth === "4").length}>
                </Table.Cell> : <></>} */}
              <Table.Cell>{statArr.find(item => item.classSchool === setting.classSchool) ? statArr.find(item => item.classSchool === setting.classSchool).total : 0}</Table.Cell>
              {weekday.map(w =>
                period[0].map(p =>
                  this.getSmartTd(setting, p, w))
              )}
            </Table.Row>
          ))}
          {/* {classRoomArr.filter(item => item.classTime === "1" && item.entryMonth === "4").length === 0 ? <Table.Row><Table.Cell colSpan="12" className="text-center">データなし</Table.Cell></Table.Row> : <Table.Row><Table.Cell colSpan="12"></Table.Cell></Table.Row>} */}
          {classRoomArr.filter(item => item.classTime === "1" && item.entryMonth === "4").length === 0 ? <Table.Row><Table.Cell colSpan="12" className="text-center">データなし</Table.Cell></Table.Row> : null}
          {classRoomArr.filter(item => item.classTime === "1" && item.entryMonth === "10").map((setting, index) => (
            <Table.Row key={setting._id}>
              <Table.Cell>{setting.entryYear.slice(-2)}A-{index + 1}{setting.classSchool}　{setting.teacherText} {setting.teacherTitleText2 ? <>({setting.teacherTitleText2 || ''})</> : ''}</Table.Cell>
              <Table.Cell>{setting.classroom ? setting.classroom : ''}</Table.Cell>
              {/* {index === 0 ?
                  <Table.Cell style={{ 'whiteSpace': 'unset' }}
                    rowSpan={classRoomArr.filter(item => item.classTime === "1" && item.entryMonth === "10").length}>
                  </Table.Cell> : <></>} */}
              <Table.Cell>{statArr.find(item => item.classSchool === setting.classSchool) ? statArr.find(item => item.classSchool === setting.classSchool).total : 0}</Table.Cell>
              {weekday.map(w =>
                period[0].map(p =>
                  this.getSmartTd(setting, p, w)
                )
              )}
            </Table.Row>
          ))}
          {classRoomArr.length === 0 ? <Table.Row><Table.Cell colSpan="12" className="text-center">データなし</Table.Cell></Table.Row> :
            null}
          {/* <Table.Row><Table.Cell colSpan="12" ></Table.Cell></Table.Row>} */}
          <Table.Row><Table.Cell colSpan="13" className="text-center">表の上部 午前1234時間 下5678午後  具体的な時間は表の下に記載</Table.Cell></Table.Row>
          {/* <Table.Row><Table.Cell colSpan="12"></Table.Cell></Table.Row> */}
          <Table.Row>
            <Table.Cell>午後クラス﹒担当</Table.Cell>
            {/* 教室 定員 */}
            <Table.Cell>教室</Table.Cell>
            <Table.Cell>生</Table.Cell>
            <Table.Cell>5 6</Table.Cell>
            <Table.Cell>7 8</Table.Cell>
            <Table.Cell>5 6</Table.Cell>
            <Table.Cell>7 8</Table.Cell>
            <Table.Cell>5 6</Table.Cell>
            <Table.Cell>7 8</Table.Cell>
            <Table.Cell>5 6</Table.Cell>
            <Table.Cell>7 8</Table.Cell>
            <Table.Cell>5 6</Table.Cell>
            <Table.Cell>7 8</Table.Cell>
          </Table.Row>
          {classRoomArr.filter(item => item.classTime === "2" && item.entryMonth === "4").map((setting, index) => (
            <Table.Row key={setting._id}>
              <Table.Cell>{setting.entryYear.slice(-2)}S-{index + 1}{setting.classSchool}　{setting.teacherText} {setting.teacherTitleText2 ? <>({setting.teacherTitleText2 || ''})</> : ''}</Table.Cell>
              <Table.Cell>{setting.classroom ? setting.classroom : ''}</Table.Cell>
              {/* {index === 0 ? <Table.Cell style={{ 'whiteSpace': 'unset' }}
                  rowSpan={classRoomArr.filter(item => item.classTime === "2" && item.entryMonth === "4").length}>
                </Table.Cell> : <></>} */}
              <Table.Cell>{statArr.find(item => item.classSchool === setting.classSchool) ? statArr.find(item => item.classSchool === setting.classSchool).total : 0}</Table.Cell>
              {weekday.map(w =>
                period[1].map(p =>
                  this.getSmartTd(setting, p, w)
                )
              )}
            </Table.Row>
          ))}
          {/* {classRoomArr.length === 0 ? <Table.Row><Table.Cell colSpan="12" className="text-center">データなし</Table.Cell></Table.Row> : <Table.Row><Table.Cell colSpan="12"></Table.Cell></Table.Row>} */}
          {classRoomArr.length === 0 ? <Table.Row><Table.Cell colSpan="12" className="text-center">データなし</Table.Cell></Table.Row> : null}
          {classRoomArr.filter(item => item.classTime === "2" && item.entryMonth === "10").map((setting, index) => (
            <Table.Row key={setting._id}>
              <Table.Cell>{setting.entryYear.slice(-2)}A-{index + 1}{setting.classSchool}　{setting.teacherText} {setting.teacherTitleText2 ? <>({setting.teacherTitleText2 || ''})</> : ''}</Table.Cell>
              <Table.Cell>{setting.classroom ? setting.classroom : ''}</Table.Cell>
              {/* {index === 0 ? <Table.Cell style={{ 'whiteSpace': 'unset' }}
                  rowSpan={classRoomArr.filter(item => item.classTime === "2" && item.entryMonth === "10").length}>
                </Table.Cell> : <></>} */}
              <Table.Cell>{statArr.find(item => item.classSchool === setting.classSchool) ? statArr.find(item => item.classSchool === setting.classSchool).total : 0}</Table.Cell>
              {weekday.map(w =>
                period[1].map(p =>
                  this.getSmartTd(setting, p, w)
                )
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table >
      <div className="page-break"></div>
      <Table celled structured size="small" className="thPadding2px tdPadding2px">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>クラス</Table.HeaderCell>
            <Table.HeaderCell>担任</Table.HeaderCell>
            <Table.HeaderCell>先生</Table.HeaderCell>
            <Table.HeaderCell>学生</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {classRoomArr.map((element, index) =>
            (<Table.Row key={'cra' + index}>
              <Table.Cell>
                {element.classTime === '1' && element.entryMonth === '4' ?
                  <>{element.entryYear.slice(-2)}S-{index + 1}{element.classSchool}</>
                  : <>{element.entryYear.slice(-2)}A-{index + 1}{element.classSchool}</>}
              </Table.Cell>
              <Table.Cell>{element.teacherText} {element.teacherTitleText2 ? <>({element.teacherTitleText2 || ''})</> : ''}</Table.Cell>
              <Table.Cell>
                {this.statClassTeacher(element.semesterYear, element.semester, element.classSchool).map((item, index2) => (<span key={'sct' + index + index2}>{item.nameTeacher} {item.count} &nbsp;</span>))}
              </Table.Cell>
              <Table.Cell>{students && this.customizedText(students.filter(item => item.semesterYear === element.entryYear && item.semester === (element.entryMonth === '4' ? 'S' : 'A') && item.classSchool === element.classSchool))}</Table.Cell>
            </Table.Row>))}
        </Table.Body>
      </Table>

      {/*  // this.statClassTeacher(element.semesterYear, element.semester, element.classSchool)
  //         .map((item, index) =>
  //           <>
  //           <Table.Cell></Table.Cell>
  //           <Table.Cell></Table.Cell>
  //           <Table.Cell>({index + 1}) {item.partTimeTeacherText} {item.nameTeacher} {item.count}</Table.Cell>
  //           </>) */}

      <Grid>
        <Grid.Row className="show-detail" style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid.Column width={4} style={{ textAlign: 'center' }}>
            <Header as='h4'>授業時間</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="show-detail" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
          <Grid.Column width={5}>
            <p>午前</p>
            <p>1時間目 08:50～ 09:40</p>
            <p>2時間目 09:50～ 10:40</p>
            <p>3時間目 10:50～ 11:40</p>
            <p>4時間目 11:50～ 12:40</p>
          </Grid.Column>
          <Grid.Column width={5}>
            <p>午後</p>
            <p>5時間目 13:10～ 14:00</p>
            <p>6時間目 14:10～ 15:00</p>
            <p>7時間目 15:10～ 16:00</p>
            <p>8時間目 16:10～ 17:00</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>);
  }
}