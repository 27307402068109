import React, { Component } from 'react'
import { Form } from 'semantic-ui-react';
import { Card, CardBody, CardHeader, Row, Col } from 'reactstrap';

import Parse from 'widget/parse'
import { fieldCol } from "views/formDef/function";
import { portfolio } from 'views/formDef/object';

export default class Portfolio7 extends Component {
  state = {
    icSel: [],
    icId: '',
    providerSel: [],
    providerId: '',
  }

  async componentDidMount() {
    const { doc, companyObj, provider } = this.props
    const companyId = companyObj.objectId;
    // const provider = await Parse.queryData('provider', { companyId });

    const { agent } = this.props
    const agentSel = agent.map(item => ({ key: item.objectId, text: item.name, value: item.objectId }))
    // this.setState({  })

    const providerSel = provider.map(item => ({
      key: item.objectId, text: `${item.name_cht}`, value: item.objectId
    }))
    let providerId
    if (provider && provider.length && provider.find(item => (item.objectId === doc.provider_id))) {
      providerId = provider.find(item => (item.objectId === doc.provider_id)).objectId
    } else {
      providerId = ''
    }

    this.setState({ providerSel, providerId, agentSel });
  }

  handleChange = (event, data) => {
    const { handleSelectUpdate } = this.props
    const { name, value } = data;
    handleSelectUpdate(data)
    this.setState({ [name]: value })
  }

  render() {
    const { doc, isEdit, showTable, handleChangeField } = this.props
    const { providerId, providerSel, agentSel } = this.state;

    return (<>
      <Card fluid>
        {/* <Card.Content header={`[股票] ${doc.product4_text}`} /> */}
        <CardHeader tag="h5" style={{ backgroundColor: '#f07b51' }}> [股票] {doc.product4_text}</CardHeader>
        <CardBody>
          <Row>
            {fieldCol(portfolio.certificate_name, "pr-1", "3", doc.certificate_name, handleChangeField, !isEdit)}
            {fieldCol(portfolio.certificate_no, "pr-1", "3", doc.certificate_no, handleChangeField, !isEdit)}
            {fieldCol(portfolio.effective_date, "pr-1", "3", doc.effective_date, handleChangeField, !isEdit)}
            <Col sm='3'><Form><Form.Select fluid label='供應商' options={providerSel} name='providerId' onChange={this.handleChange} value={providerId} disabled={!isEdit} /></Form></Col>
          </Row>
          <Row>
            {fieldCol(portfolio.nowphase, "pr-1", "3", doc.nowphase, handleChangeField, !isEdit)}
            {fieldCol(portfolio.invest_money, "pr-1", "3", doc.invest_money, handleChangeField, !isEdit)}
            {fieldCol(portfolio.payment_date, "pr-1", "3", doc.payment_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.payment_method, "pr-1", "3", doc.payment_method, handleChangeField, !isEdit)}
          </Row>
          <Row>
            {fieldCol(portfolio.receive_money_date, "pr-1", "3", doc.receive_money_date, handleChangeField, !isEdit)}
            {fieldCol(portfolio.stockrightsummit, "pr-1", "3", doc.stockrightsummit, handleChangeField, !isEdit)}
            <Col sm='3'><Form.Select fluid label='agent' options={agentSel} id='agent_id' name='agent_text' onChange={this.handleChange} value={doc.agent_id || ''} disabled={!isEdit} /></Col>
          </Row>
        </CardBody>
      </Card>
      {showTable('Beneficiary Bank')}
      {/* {showTable('GiveInterestRecord')} */}
    </>)
  }
}