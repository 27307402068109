import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row, Col,
  Form, FormGroup, Input, Label, Button
} from "reactstrap";

import Parse from "widget/parse";
import ModalAutoAddr from './ModalAutoAddr';
import { fieldCol } from "views/formDef/function";
import { userPawn } from "views/formDef/object";

class Profile1 extends Component {
  state = {
    // activeItem: 'bio'
    profileObj: this.props.profileObj
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ profileObj: nextProps.profileObj });
  }

  onRowDataChange = (profileObj) => {
    // this.setState({ form: { ...this.state.form, postcode, addr } })
    this.setState({ profileObj })
  }

  handleChange = (e, data) => {
    // console.log(e, data)
    // console.log(typeof e)
    if (e.target) {
      this.setState({ profileObj: { ...this.state.profileObj, [e.target.name]: e.target.value } })
    } else {
      this.setState({ profileObj: { ...this.state.profileObj, [data.name]: e } })
    }
  }

  handleSave = async () => {
    const { profileObj } = this.state;
    await Parse.saveData('User', profileObj);
    window.alert('已儲存')
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render() {
    // const { profileObj } = this.props
    const { profileObj } = this.state
    // const { activeItem } = this.state
    // console.log(profileObj)

    return (<div className="content">
      {/* 排版整理好，用和client一樣的 */}
      <Card>
        {/* <CardHeader>
            P123321 客戶 xxx
          </CardHeader> */}
        <CardBody>
          <Form>
            <Row form>
              {fieldCol(userPawn.name, "", "3", "", this.handleChange)}
              {fieldCol(userPawn.sexualId, "", "3", "", this.handleChange)}
              {fieldCol(userPawn.birthday, "", "3", "", this.handleChange)}
              {/* {fieldCol(userPawn.name, "", "3", profileObj.name, this.handleChange)}
                {fieldCol(userPawn.sexualId, "", "3", profileObj.sexualId, this.handleChange)}
                {fieldCol(userPawn.birthday, "", "3", profileObj.birthday, this.handleChange)} */}
            </Row>
            <Row>
              {fieldCol(userPawn.residentialPhone, "", "3", "", this.handleChange)}
              {/* {fieldCol(userPawn.residentialPhone, "", "3", profileObj.residentialPhone, this.handleChange)} */}
              <Col md={1}>
                <FormGroup>
                  <ModalAutoAddr {...this.props} type='3' rowData={profileObj} field1="residentialPostcode" field2="residentialAddr" onRowDataChange={this.onRowDataChange} />
                </FormGroup>
              </Col>
              {fieldCol(userPawn.residentialAddr, "", "4", "", this.handleChange)}
              {/* {fieldCol(userPawn.residentialAddr, "", "4", profileObj.residentialAddr, this.handleChange)} */}
            </Row>
            <Row>
              {fieldCol(userPawn.censusPhone, "", "3", "", this.handleChange)}
              {/* {fieldCol(userPawn.censusPhone, "", "3", profileObj.censusPhone, this.handleChange)} */}
              <Col md={1}>
                <FormGroup>
                  <ModalAutoAddr {...this.props} type='3' rowData={profileObj} field1="censusPostcode" field2="censusAddr" onRowDataChange={this.onRowDataChange} />
                </FormGroup>
              </Col>
              {fieldCol(userPawn.censusAddr, "", "4", "", this.handleChange)}
              {/* {fieldCol(userPawn.censusAddr, "", "4", profileObj.censusAddr, this.handleChange)} */}
            </Row>
            <hr style={{ margin: '20px' }} />
            <Row form>
            </Row>
            <FormGroup>
              <Label for="exampleText">備註</Label>
              <Input type="textarea" name="ps" id="ps" value={""} />
              {/* <Input type="textarea" name="ps" id="ps" value={profileObj.ps} /> */}
            </FormGroup>
            <Button onClick={this.handleSave}>儲存</Button>
          </Form>
        </CardBody>
      </Card>
    </div>
    );
  }
}

export default Profile1;
