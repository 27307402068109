
Array.prototype.swap = function (index1, index2) {
  if (index1 <= this.length && index2 <= this.length) {
    const temp = this[index2];
    this[index2] = this[index1];
    this[index1] = temp;
  }
};

Array.prototype.arraymove = function (fromIndex, toIndex) {
  const arr = this;
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
};