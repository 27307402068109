
import React, { Component } from 'react'
import classnames from "classnames";
import { NavLink, Link } from "react-router-dom";
import intl from 'react-intl-universal';
// import { withCookies } from 'react-cookie';
import { Image } from 'semantic-ui-react';
// //import 'assets/css/paper-dashboard.min.scoped.css'
// import css from 'assets/css/paper-dashboard.min.module.css';

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  // NavLink,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";
import DropdownLang2 from "./DropdownLang2";
const hostname = window.location.hostname;

class AuthNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent"
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };


  render() {
    const { history, companies = [], changeCompany, companyObj } = this.props;
    // const { companyObj } = this.props
    return (<>
      {/* <style jsx>{`{ ${css} }`}</style> */}
      <Navbar
        className={classnames("navbar-absolute fixed-top", this.state.color)}
        expand="lg"
      >
        <Container>
          <div className="navbar-wrapper">
            <NavbarBrand href="/"
            // onClick={e => e.preventDefault()}
            >
              {/* <NavLink to='/'> */}
              {companyObj.img1File && companyObj.img1File.url ? <Image size="small" src={companyObj.img1File.url} /> : companyObj.name}
              {/* </NavLink> */}
            </NavbarBrand>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-toggle="collapse"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse
            isOpen={this.state.collapseOpen}
            className="justify-content-end"
            navbar
          >
            <Nav navbar>
              {companyObj.useOfficial ? <NavItem>
                <a href="/" replace className="nav-link">
                  <i className="nc-icon nc-planet" />
                  {intl.get('Official Website')}
                </a>
              </NavItem> : null}
              <NavItem>
                <NavLink to="/auth/register" className="nav-link">
                  <i className="nc-icon nc-book-bookmark" />
                  {intl.get('Register')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/login" className="nav-link">
                  <i className="nc-icon nc-tap-01" />
                  {intl.get('Login')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/resetpassword" className="nav-link">
                  <i className="nc-icon nc-key-25" />
                  {intl.get('Reset Password')}
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink to="/admin/dashboard" className="nav-link">
                  <i className="nc-icon nc-layout-11" />
                  {intl.get('Dashboard')}
                </NavLink>
              </NavItem>
            
              <NavItem>
                <NavLink to="/admin/user-profile" className="nav-link">
                  <i className="nc-icon nc-satisfied" />
                  {intl.get('User')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/lock-screen" className="nav-link">
                  <i className="nc-icon nc-key-25" />
                  {intl.get('Lock')}
                </NavLink>
              </NavItem> */}
              {companyObj.isMultiLang ? <DropdownLang2 {...this.props} /> : null}
              {hostname === 'localhost' ?
                <UncontrolledDropdown className="btn-rotate" nav>
                  <DropdownToggle
                    aria-haspopup={true}
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                  >
                    <i className="nc-icon nc-tv-2" />
                    <p>
                      <span className="d-lg-none d-md-block">網站</span>
                    </p>
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="navbarDropdownMenuLink" className="dropdown-danger" right>
                    {companies.map(({ name, objectId }) => (<DropdownItem key={objectId}
                      onClick={e => {
                        e.preventDefault()
                        changeCompany(objectId);
                        history.push('/admin/dashboard')
                      }}
                    >{name}</DropdownItem>))}
                  </DropdownMenu>
                </UncontrolledDropdown> : null}
            </Nav>
          </Collapse>
        </Container>
      </Navbar></>
    );
  }
}

export default AuthNavbar;
// export default withCookies(AuthNavbar);
