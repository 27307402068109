import React, { Component } from 'react'
import { Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Form } from 'semantic-ui-react'
import MaterialTable, { MTableToolbar } from 'material-table'
import ModalUploadFiles from 'views/web/ModalUploadFiles'
import { RowImage } from 'views/web/RowImage';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf, rowReOrder } from 'views/web/CustomMaterialTable';

const objPortfolioTemplate = {
  "1": "保險-Pru Life", // ok
  "2": "保險-VOYA",     // ok like 1 ok
  "3": "英國房產-股權",  // ok
  "4": "英國房產-產權",   // ok like 3
  "5": "美國、加拿大房產",    // ok like 3
  "6": "FPI", // ok
  "7": "股票",                  // ok like 3
  "8": "AVIVA/Zurich/Standardlife/MassMutual/AIA"
  , // ok like 6
  //{ id:"9", value:"(AS)Zurich架構"},
  // { id:"10", value:"(AS)AVIVA架構"},
  // { id:"11", value:"(AS)FPI架構"},
};

const columns = () => ([
  { title: '順序', field: 'order' },
  // {
  //   width: 50, title: '', field: 'File',
  //   editComponent: props => {
  //     return <><ModalUploadFiles {...pageProps} isImage='0' handleData={handleFetch} dbName='fileCenter2' fieldName='file1' condition={{ fileCenter1Id: props.rowData.objectId, fileCenter1Text: props.rowData.name_cht || props.rowData.value, type: 'product' }} /></>
  //   }
  // },
  { title: '項目', field: 'value' },
  // {
  //   title: '檔案', field: '',
  //   render: rowData => {
  //     let showArr = []
  //     if (rowData.attachments !== undefined && rowData.attachments.length > 0) {
  //       rowData.attachments.forEach(item => {
  //         showArr.push(<a href={item.path} target="_blank" rel="noopener noreferrer">{item.filename}</a>)
  //         showArr.push(<br />)
  //       })
  //     }
  //     return showArr
  //   },
  //   editComponent: props => {
  //     let rowData = props.rowData
  //     let showArr = []
  //     if (rowData.attachments !== undefined && rowData.attachments.length > 0) {
  //       rowData.attachments.forEach(item => {
  //         showArr.push(<a href={item.path} target="_blank" rel="noopener noreferrer">{item.filename}</a>)
  //         showArr.push(<br />)
  //       })
  //     }
  //     return showArr
  //   }
  // },
]);

const columns2 = () => ([
  { title: '順序', field: 'order' },
  // {
  //   width: 50, title: '', field: 'File',
  //   editComponent: props => {
  //     // return <><ModalUploadFiles {...pageProps} isImage='0' handleData={handleFetch} dbName='fileCenter2' fieldName='file1' condition={{ fileCenter1Id: props.rowData.objectId }} /></>
  //     return <><Button color='primary' onClick={e => handleOpen(props.onRowDataChange,props.rowData)}><i className="fa fa-plus" />新增檔案</Button></>
  //   }
  // },
  { title: '項目(中文)', field: 'name_cht' },
  { title: '項目(英文)', field: 'name_eng' },
  {
    title: '檔案', field: '',
    render: rowData => {
      let showArr = []
      if (rowData.attachments !== undefined && rowData.attachments.length > 0) {
        rowData.attachments.forEach(item => {
          showArr.push(<a href={item.path} target="_blank" rel="noopener noreferrer">{item.filename}</a>)
          showArr.push(<br />)
        })
      }
      return showArr
    },
    editComponent: props => {
      let rowData = props.rowData
      let showArr = []
      if (rowData.attachments !== undefined && rowData.attachments.length > 0) {
        rowData.attachments.forEach(item => {
          showArr.push(<a href={item.path} target="_blank" rel="noopener noreferrer">{item.filename}</a>)
          showArr.push(<br />)
        })
      }
      return showArr
    }
  },
]);

const columns2_2 = (providerOpt) => ([
  { title: '順序', field: 'order' },
  // {
  //   width: 50, title: '', field: 'File',
  //   editComponent: props => {
  //     // return <><ModalUploadFiles {...pageProps} isImage='0' handleData={handleFetch} dbName='fileCenter2' fieldName='file1' condition={{ fileCenter1Id: props.rowData.objectId }} /></>
  //     return <><Button color='primary' onClick={e => handleOpen(props.onRowDataChange,props.rowData)}><i className="fa fa-plus" />新增檔案</Button></>
  //   }
  // },
  { title: '項目(中文)', field: 'name_cht' },
  { title: '項目(英文)', field: 'name_eng' },
  { title: '顯示樣板', field: 'template_id', lookup: objPortfolioTemplate },
  {
    title: '供應商', field: 'name_eng',
    render: rowData => rowData && rowData.provider_id && providerOpt.find(item => item.value === rowData.provider_id) ? providerOpt.find(item => item.value === rowData.provider_id).text : <></>,
    editComponent: props => <Form.Select options={providerOpt} id='provider_id' name='provider_chttext' name_eng='provider_engtext'
      onChange={(e, data) => {
        props.onRowDataChange({ ...props.rowData, [data.id]: data.value, [data.name]: providerOpt.find(item => item.value === data.value).chtText, [data.name_eng]: providerOpt.find(item => item.value === data.value).engText })
      }}
      value={props.rowData.provider_id} />,
  },
  {
    title: '檔案', field: '',
    render: rowData => {
      let showArr = []
      if (rowData.attachments !== undefined && rowData.attachments.length > 0) {
        rowData.attachments.forEach(item => {
          showArr.push(<a href={item.path} target="_blank" rel="noopener noreferrer">{item.filename}</a>)
          showArr.push(<br />)
        })
      }
      return showArr
    },
    editComponent: props => {
      let rowData = props.rowData
      let showArr = []
      if (rowData.attachments !== undefined && rowData.attachments.length > 0) {
        rowData.attachments.forEach(item => {
          showArr.push(<a href={item.path} target="_blank" rel="noopener noreferrer">{item.filename}</a>)
          showArr.push(<br />)
        })
      }
      return showArr
    }
  },
]);

const columns3 = () => ([
  {
    title: '預覽', field: 'file1',
    render: rowData => <><RowImage rowData={rowData} field={'file1'} /></>,
    editComponent: props => <><RowImage rowData={props.rowData} field={'file1'} /></>,
  },
  { title: '名稱', field: 'name' },
])

export default class Web4 extends Component {
  state = {
    loginUser: this.props.user.profile.toJSON(),
    product1: [],
    product2: [],
    product3: [],
    product4: [],
    data1: [],
    data2: [],
    data3: [],
    data4: [],
    sel1: '',
    sel2: '',
    sel3: '',
    sel4: '',
    title1: '',
    title2: '',
    title3: '',
    title4: '',
    loading: true,
    show: false,
  }

  async componentDidMount() {
    const { loginUser } = this.state
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const authPage = window.location.pathname.split('/')[2]
    const authUser = companyId + '_' + authPage
    let auth = {}
    if ((loginUser.authAdminA && loginUser.authAdminA.indexOf(companyId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
      auth = {
        edit: true,
      }
    } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
      auth = {
        edit: false,
      }
    } else {
      auth = {
        edit: false,
      }
    }

    const provider = await Parse.queryData('provider', { companyId });

    const providerOpt = provider.map(item => ({ key: item.objectId, text: item.name_cht + ' (' + item.name_eng + ')', value: item.objectId, chtText: item.name_cht, engText: item.name_eng }));

    const fileCenter2Arr = await Parse.queryData('fileCenter2', { companyId, type: 'product' })

    const arr1 = await Parse.queryData('product1', { companyId });
    const arr2 = await Parse.queryData('product2', { companyId });
    const arr3 = await Parse.queryData('product3', { companyId });
    const arr4 = await Parse.queryData('product4', { companyId });
    const product1 = arr1.sort((a, b) => ('' + a.order).localeCompare(b.order));
    const product2 = arr2.sort((a, b) => ('' + a.order).localeCompare(b.order));
    const product3 = arr3.sort((a, b) => ('' + a.order).localeCompare(b.order));
    const product4 = arr4.sort((a, b) => ('' + a.order).localeCompare(b.order));
    this.setState({ data1: product1, product2, product3, product4, auth, fileCenter2Arr, providerOpt }, () => this.handleFetchFile())
  }
  componentWillReceiveProps(nextProps) {
    this.handleFetchFile(nextProps)
  }

  handleFetchProduct = async () => {
    const { companyObj } = this.props;
    const companyId = companyObj.objectId;
    const arr1 = await Parse.queryData('product1', { companyId });
    const arr2 = await Parse.queryData('product2', { companyId });
    const arr3 = await Parse.queryData('product3', { companyId });
    const arr4 = await Parse.queryData('product4', { companyId });
    const product1 = arr1.sort((a, b) => ('' + a.order).localeCompare(b.order));
    const product2 = arr2.sort((a, b) => ('' + a.order).localeCompare(b.order));
    const product3 = arr3.sort((a, b) => ('' + a.order).localeCompare(b.order));
    const product4 = arr4.sort((a, b) => ('' + a.order).localeCompare(b.order));
    this.setState({ data1: product1, product1, product2, product3, product4 }, () => this.handleFetchFile())
  }

  handleFetchFile = async (props = this.props, reFile = 0) => {
    const { product2, product3, product4, rowData, sel1, sel2, sel3, fileCenter2Arr } = this.state
    const { companyObj } = props;
    const companyId = companyObj.objectId;
    let fileCenter2 = fileCenter2Arr
    if (reFile === 1) {
      fileCenter2 = await Parse.queryData('fileCenter2', { companyId, type: 'product' })
    }

    // else {
    const data2 = product2.map(item => {
      const arr = fileCenter2.filter(item2 => item2.fileCenter1Id === item.objectId)
      if (arr && arr.length) {
        const attachments = arr.map(item2 => ({ objectId: item2.objectId, path: item2.file1.url, filename: item2.name }))
        const obj = {
          ...item,
          attachments,
        }
        return obj
      } else {
        return item
      }
    })
    const data3 = product3.map(item => {
      const arr = fileCenter2.filter(item2 => item2.fileCenter1Id === item.objectId)
      if (arr && arr.length) {
        const attachments = arr.map(item2 => ({ objectId: item2.objectId, path: item2.file1.url, filename: item2.name }))
        const obj = {
          ...item,
          attachments,
        }
        return obj
      } else {
        return item
      }
    })
    const data4 = product4.map(item => {
      const arr = fileCenter2.filter(item2 => item2.fileCenter1Id === item.objectId)
      if (arr && arr.length) {
        const attachments = arr.map(item2 => ({ objectId: item2.objectId, path: item2.file1.url, filename: item2.name }))
        const obj = {
          ...item,
          attachments,
        }
        return obj
      } else {
        return item
      }
    })
    if (sel1 || sel2 || sel3) {
      this.setState({
        data2: data2.filter(item => item.product1_id === sel1),
        data3: data3.filter(item => item.product2_id === sel2),
        data4: data4.filter(item => item.product3_id === sel3),
        product2: data2,
        product3: data3,
        product4: data4,
        loading: false
      })
    } else {
      this.setState({ data2, data3, data4, product2: data2, product3: data3, product4: data4, loading: false, fileCenter2Arr: fileCenter2 });
    }
    // }

  }

  handleAdd = async (id) => {
    const { newData1, newData2, newData3, newData4 } = this.state;

    if (id === "1") {
      await Parse.saveData('product1', newData1);
      this.setState({ loading: false }, () => this.handleFetchProduct())
    } else if (id === "2") {
      await Parse.saveData('product2', newData2);
      this.setState({ loading: false }, () => this.handleFetchProduct())
    } else if (id === "3") {
      await Parse.saveData('product3', newData3);
      this.setState({ loading: false }, () => this.handleFetchProduct())
    } else if (id === "4") {
      await Parse.saveData('product4', newData4);
      this.setState({ loading: false }, () => this.handleFetchProduct())
    }
  }

  handleUpdate = async (id) => {
    const { newData1, newData2, newData3, newData4 } = this.state

    if (id === "1") {
      await Parse.saveData('product1', newData1);
    } else if (id === "2") {
      delete newData2.attachments
      await Parse.saveData('product2', newData2);
    } else if (id === "3") {
      delete newData3.attachments
      await Parse.saveData('product3', newData3);
    } else if (id === "4") {
      delete newData4.attachments
      await Parse.saveData('product4', newData4);
    }
    this.handleFetchFile(this.props, 0)
  }

  handleDelete = async (id) => {
    const { oldData1, oldData2, oldData3, oldData4, product2, product3, product4 } = this.state
    const promises = []
    if (id === "1") {
      if (oldData1 && oldData1.attachments) {
        promises.push(Parse.destroyData('fileCenter2', { fileCenter1Id: oldData1.objectId }))
      }
      if (product4.find(item => item.product1_id === oldData1.objectId)) {
        let arr = product4.filter(item => item.product1_id === oldData1.objectId && item.attachments)
        arr.forEach(item => promises.push(Parse.destroyData('fileCenter2', { fileCenter1Id: item.objectId })))
        promises.push(Parse.destroyData('product4', { product1_id: oldData1.objectId }))
      }
      if (product3.find(item => item.product1_id === oldData1.objectId)) {
        let arr = product3.filter(item => item.product1_id === oldData1.objectId && item.attachments)
        arr.forEach(item => promises.push(Parse.destroyData('fileCenter2', { fileCenter1Id: item.objectId })))
        promises.push(Parse.destroyData('product3', { product1_id: oldData1.objectId }))
      }
      if (product2.find(item => item.product1_id === oldData1.objectId)) {
        let arr = product2.filter(item => item.product1_id === oldData1.objectId && item.attachments)
        arr.forEach(item => promises.push(Parse.destroyData('fileCenter2', { fileCenter1Id: item.objectId })))
        promises.push(Parse.destroyData('product2', { product1_id: oldData1.objectId }))
      }
      await Parse.deleteData('product1', oldData1);
      await Promise.all(promises).catch(error => console.log("error", error))
      this.setState({ sel1: '', sel2: '', sel3: '', loading: false }, () => this.handleFetchProduct())
    } else if (id === "2") {
      if (oldData2 && oldData2.attachments) {
        promises.push(Parse.destroyData('fileCenter2', { fileCenter1Id: oldData2.objectId }))
      }
      if (product4.find(item => item.product2_id === oldData2.objectId)) {
        let arr = product4.filter(item => item.product2_id === oldData2.objectId && item.attachments)
        arr.forEach(item => promises.push(Parse.destroyData('fileCenter2', { fileCenter1Id: item.objectId })))
        promises.push(Parse.destroyData('product4', { product2_id: oldData2.objectId }))
      }
      if (product3.find(item => item.product2_id === oldData2.objectId)) {
        let arr = product3.filter(item => item.product2_id === oldData2.objectId && item.attachments)
        arr.forEach(item => promises.push(Parse.destroyData('fileCenter2', { fileCenter1Id: item.objectId })))
        promises.push(Parse.destroyData('product3', { product2_id: oldData2.objectId }))
      }
      await Parse.deleteData('product2', oldData2);
      await Promise.all(promises).catch(error => console.log("error", error))
      this.setState({ sel2: '', sel3: '', loading: false }, () => this.handleFetchProduct())
    } else if (id === "3") {
      if (oldData3 && oldData3.attachments) {
        promises.push(Parse.destroyData('fileCenter2', { fileCenter1Id: oldData3.objectId }))
      }
      if (product4.find(item => item.product3_id === oldData3.objectId)) {
        let arr = product4.filter(item => item.product3_id === oldData3.objectId && item.attachments)
        arr.forEach(item => promises.push(Parse.destroyData('fileCenter2', { fileCenter1Id: item.objectId })))
        promises.push(Parse.destroyData('product4', { product3_id: oldData3.objectId }))
      }
      await Parse.deleteData('product3', oldData3);
      await Promise.all(promises).catch(error => console.log("error", error))
      this.setState({ sel3: '', loading: false }, () => this.handleFetchProduct())
    } else if (id === "4") {
      if (oldData4 && oldData4.attachments) {
        promises.push(Parse.destroyData('fileCenter2', { fileCenter1Id: oldData4.objectId }))
      }
      await Parse.deleteData('product4', oldData4);
      await Promise.all(promises).catch(error => console.log("error", error))
      this.setState({ loading: false }, () => this.handleFetchProduct())
    }
  }

  handleFileDelete = async () => {
    const { oldData } = this.state
    await Parse.deleteData('fileCenter2', oldData)
    this.setState({ oldData: {} })
  }

  handleData = (id, rowData) => {
    const { product2, product3, product4 } = this.state;
    const sel = rowData.objectId;
    if (id === "1") {
      this.setState({ sel1: sel, sel2: '', sel3: '', title1: rowData.value, data2: product2.filter(item => item.product1_id === sel) })
    } else if (id === "2") {
      this.setState({ sel2: sel, sel3: '', title2: rowData.name_cht, data3: product3.filter(item => item.product2_id === sel) })
    } else if (id === "3") {
      this.setState({ sel3: sel, title3: rowData.name_cht, data4: product4.filter(item => item.product3_id === sel) })
    }
  }

  handleFileData = async () => {
    this.setState({ loading: true })
    const { companyObj } = this.props
    const { rowData } = this.state
    const fileData = await Parse.queryData('fileCenter2', { companyId: companyObj.objectId, type: 'product', fileCenter1Id: rowData.objectId })
    this.setState({ fileData, loading: false })
  }

  handleClose = () => {
    this.setState({ show: false, loading: true }, () => this.handleFetchFile(this.props, 1))
  }

  handleState = (dataArr, arrName = 'dataArr') => {
    this.setState({ [arrName]: dataArr })
  }

  editable1 = {
    isEditHidden: () => !this.state.auth.edit,
    isDeleteHidden: () => !this.state.auth.edit,
    onRowAdd: newData1 =>
      new Promise(async (resolve, reject) => {
        // const db = firebase.firestore();
        // const docRef = db.collection('product1').doc();
        const data = [...this.state.data1];
        const db = Parse.Object.extend("product1");
        const newDoc = new db();
        const doc = await newDoc.save();

        const obj = {
          _id: doc.id,
          id: doc.id,
          objectId: doc.id,
          companyId: this.props.companyObj.objectId || '',
          companyName: this.props.companyObj.name || '',
          ...newData1
        }

        data.push(obj);

        this.setState({ data1: data, newData1: obj, loading: true }, () => resolve());
      }).then(() => this.handleAdd("1")),
    onRowUpdate: (newData1, oldData1) =>
      new Promise((resolve, reject) => {
        const data = [...this.state.product1];
        const index = data.indexOf(oldData1);
        data[index] = newData1;
        // console.log(data1)
        this.setState({ product1: data, newData1, oldData1, loading: true }, () => resolve());
      }).then(() => this.handleUpdate("1")),
    onRowDelete: oldData1 =>
      new Promise((resolve, reject) => {
        let data = [...this.state.data1];
        const index = data.indexOf(oldData1);
        data.splice(index, 1);
        this.setState({ data1: data, oldData1, loading: true }, () => resolve());
      }).then(() => this.handleDelete("1")),
  }
  editable2 = {
    isEditHidden: () => !this.state.auth.edit,
    isDeleteHidden: () => !this.state.auth.edit,
    onRowAdd: newData2 =>
      new Promise(async (resolve, reject) => {
        const data = [...this.state.data2];
        const db = Parse.Object.extend("product2");
        const newDoc = new db();
        const doc = await newDoc.save();

        const obj = {
          _id: doc.id,
          id: doc.id,
          objectId: doc.id,
          companyId: this.props.companyObj.objectId || '',
          companyName: this.props.companyObj.name || '',
          product1_id: this.state.sel1,
          product1_text: (this.state.data1.find(item => item.objectId === this.state.sel1) && this.state.data1.find(item => item.objectId === this.state.sel1).value) || '',
          ...newData2
        }
        data.push(obj);
        this.setState({ data2: data, newData2: obj, loading: true }, () => resolve());
      }).then(() => this.handleAdd("2")),
    onRowUpdate: (newData2, oldData2) =>
      new Promise((resolve, reject) => {
        const data = [...this.state.product2];
        const index = data.indexOf(oldData2);
        data[index] = newData2;
        // console.log(data1)
        this.setState({ product2: data, newData2, oldData2, loading: true }, () => resolve());
      }).then(() => this.handleUpdate("2")),
    onRowDelete: oldData2 =>
      new Promise((resolve, reject) => {
        let data = [...this.state.data2];
        const index = data.indexOf(oldData2);
        data.splice(index, 1);
        this.setState({ data2: data, oldData2, loading: true }, () => resolve());
      }).then(() => this.handleDelete("2")),
  }
  editable3 = {
    isEditHidden: () => !this.state.auth.edit,
    isDeleteHidden: () => !this.state.auth.edit,
    onRowAdd: newData3 =>
      new Promise(async (resolve, reject) => {
        const data = [...this.state.data3];
        const db = Parse.Object.extend("product3");
        const newDoc = new db();
        const doc = await newDoc.save();

        const obj = {
          _id: doc.id,
          id: doc.id,
          objectId: doc.id,
          companyId: this.props.companyObj.objectId || '',
          companyName: this.props.companyObj.name || '',
          product1_id: this.state.sel1,
          product1_text: (this.state.data1.find(item => item.objectId === this.state.sel1) && this.state.data1.find(item => item.objectId === this.state.sel1).value) || '',
          product2_id: this.state.sel2,
          product2_text: (this.state.data2.find(item => item.objectId === this.state.sel2) && this.state.data2.find(item => item.objectId === this.state.sel2).name_cht) || '',
          ...newData3
        }
        data.push(obj);
        this.setState({ data3: data, newData3: obj, loading: true }, () => resolve());
      }).then(() => this.handleAdd("3")),

    onRowUpdate: (newData3, oldData3) =>
      new Promise((resolve, reject) => {
        const data = [...this.state.product3];
        const index = data.indexOf(oldData3);
        let obj = {
          ...newData3,
          product2_id: this.state.sel2,
          product2_text: (this.state.data2.find(item => item.objectId === this.state.sel2) && this.state.data2.find(item => item.objectId === this.state.sel2).name_cht) || '',
        }
        data[index] = obj;
        // console.log(data1)
        this.setState({ product3: data, newData3: obj, oldData3, loading: true }, () => resolve());
      }).then(() => this.handleUpdate("3")),
    onRowDelete: oldData3 =>
      new Promise((resolve, reject) => {
        let data = [...this.state.data3];
        const index = data.indexOf(oldData3);
        data.splice(index, 1);
        this.setState({ data3: data, oldData3, loading: true }, () => resolve());
      }).then(() => this.handleDelete("3")),
  }
  editable4 = {
    isEditHidden: () => !this.state.auth.edit,
    isDeleteHidden: () => !this.state.auth.edit,
    onRowAdd: newData4 =>
      new Promise(async (resolve, reject) => {
        const db = Parse.Object.extend("product4");
        const newDoc = new db();
        const doc = await newDoc.save();
        const data = [...this.state.data4];

        const obj = {
          _id: doc.id,
          id: doc.id,
          objectId: doc.id,
          companyId: this.props.companyObj.objectId || '',
          companyName: this.props.companyObj.name || '',
          product1_id: this.state.sel1,
          product1_text: (this.state.data1.find(item => item.objectId === this.state.sel1) && this.state.data1.find(item => item.objectId === this.state.sel1).value) || '',
          product2_id: this.state.sel2,
          product2_text: (this.state.data2.find(item => item.objectId === this.state.sel2) && this.state.data2.find(item => item.objectId === this.state.sel2).name_cht) || '',
          product3_id: this.state.sel3,
          product3_text: (this.state.data3.find(item => item.objectId === this.state.sel3) && this.state.data3.find(item => item.objectId === this.state.sel3).name_cht) || '',
          ...newData4
        }
        data.push(obj);
        this.setState({ data4: data, newData4: obj, loading: true }, () => resolve());
      }).then(() => this.handleAdd("4")),
    onRowUpdate: (newData4, oldData4) =>
      new Promise((resolve, reject) => {
        const data = [...this.state.product4];
        const index = data.indexOf(oldData4);
        let obj = {
          ...newData4,
          product2_id: this.state.sel2,
          product2_text: (this.state.data2.find(item => item.objectId === this.state.sel2) && this.state.data2.find(item => item.objectId === this.state.sel2).name_cht) || '',
          product3_id: this.state.sel3,
          product3_text: (this.state.data3.find(item => item.objectId === this.state.sel3) && this.state.data3.find(item => item.objectId === this.state.sel3).name_cht) || '',
        }
        data[index] = obj;
        // console.log(data1)
        this.setState({ product4: data, newData4: obj, oldData4, loading: true }, () => resolve());
      }).then(() => this.handleUpdate("4")),
    onRowDelete: oldData4 =>
      new Promise((resolve, reject) => {
        let data = [...this.state.data4];
        const index = data.indexOf(oldData4);
        data.splice(index, 1);
        this.setState({ data4: data, oldData4, loading: true }, () => resolve());
      }).then(() => this.handleDelete("4")),
  }

  render() {
    const { companyObj } = this.props;
    const { loading, data1, data2, data3, data4, title1, title2, title3, sel1, sel2, sel3, show, fileCenter2Arr, fileData, rowData, auth = {}, providerOpt } = this.state;

    if (auth.edit === false) {
      delete this.editable1.onRowAdd
      delete this.editable2.onRowAdd
      delete this.editable3.onRowAdd
      delete this.editable4.onRowAdd
    }

    return (<div className='content'>
      {/* <div style={{ ...style.flexCenter, position: 'relative', zIndex: 100 }}>
          <Header as='h2' style={{ margin: '2rem 0' }}>6-2 產品管理</Header>
        </div> */}
      <br />
      <br />
      <Row>
        <Col md={{ size: 4, }}>
          <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns()}
            options={{
              addRowPosition: "first",
              exportButton: true,
              exportAllData: true,
              exportPdf: (columns, data) => exportPdf(columns, data, '產品分類1'),
              pageSize: 5,
              search: false
            }}
            // onColumnDragged={}
            data={data1}
            title="產品分類1"
            actions={[
              {
                icon: 'view_headline',
                tooltip: '查看',
                onClick: (event, rowData) => this.handleData("1", rowData)
              }
            ]}
            // components={{ ...components, test: '123' }}
            components={{
              Row: props => rowReOrder(props, 'product1', 'order', data1, 'data1', this.handleState)
            }}
            onRowClick={(event, rowData) => this.handleData("1", rowData)}
            editable={this.editable1}
          />
        </Col>
        <Col sm="8">
          {sel1 ? <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns2()}
            options={{
              addRowPosition: "first",
              exportButton: true,
              exportAllData: true,
              exportPdf: (columns, data) => exportPdf(columns, data, "產品分類2 " + title1),
              pageSize: 5,
              search: false
            }}
            data={data2}
            title={"產品分類2 " + title1}
            actions={[
              {
                icon: 'attach_file',
                tooltip: '檔案',
                hidden: !auth.edit,
                onClick: (event, rowData) => this.setState({ show: true, rowData, fileData: fileCenter2Arr.filter(item => item.fileCenter1Id === rowData.objectId) })
              },
              {
                icon: 'view_headline',
                tooltip: '查看',
                onClick: (event, rowData) => this.handleData("2", rowData)
              }
            ]}
            components={{
              Row: props => rowReOrder(props, 'product2', 'order', data2, 'data2', this.handleState)
            }}
            onRowClick={(event, rowData) => this.handleData("2", rowData)}
            editable={this.editable2}
          /> : null}
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col sm="12">
          {sel2 ? <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns2()}
            options={{
              addRowPosition: "first",
              exportButton: true,
              exportAllData: true,
              exportPdf: (columns, data) => exportPdf(columns, data, "產品分類3 " + title2),
              pageSize: 5,
              search: false
            }}
            data={data3}
            title={"產品分類3 " + title2}
            actions={[
              rowData => ({
                icon: 'attach_file',
                tooltip: '檔案',
                hidden: !auth.edit,
                onClick: (event, rowData) => this.setState({ show: true, rowData, fileData: fileCenter2Arr.filter(item => item.fileCenter1Id === rowData.objectId) })
              }),
              {
                icon: 'view_headline',
                tooltip: '查看',
                onClick: (event, rowData) => this.handleData("3", rowData)
              }
            ]}
            components={{
              Row: props => rowReOrder(props, 'product3', 'order', data3, 'data3', this.handleState)
            }}
            onRowClick={(event, rowData) => this.handleData("3", rowData)}
            editable={this.editable3}
          /> : null}
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col md={{ size: 12, }}>
          {sel3 ? <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns2_2(providerOpt)}
            options={{
              addRowPosition: "first",
              exportButton: true,
              exportAllData: true,
              exportPdf: (columns, data) => exportPdf(columns, data, "產品分類4 " + title3),
              pageSize: 5,
              search: false
            }}
            data={data4}
            title={"產品分類4 " + title3}
            actions={[
              rowData => ({
                icon: 'attach_file',
                tooltip: '檔案',
                hidden: !auth.edit,
                onClick: (event, rowData) => this.setState({ show: true, rowData, fileData: fileCenter2Arr.filter(item => item.fileCenter1Id === rowData.objectId) })
              }),
            ]}
            components={{
              Row: props => rowReOrder(props, 'product4', 'order', data4, 'data4', this.handleState)
            }}
            // actions={[
            //   {
            //     icon: 'view_headline',
            //     tooltip: '查看',
            //     onClick: (event, rowData) => this.handleData(rowData)
            //   }
            // ]}
            editable={this.editable4}
          /> : null}
        </Col>
      </Row>
      <Modal
        isOpen={show}
        toggle={this.handleClose}
        size="lg"
      // className="mh-90 h-90 mw-100 w-90"
      // style={{ paddingTop: '0px' }}
      // style={{ maxWidth: '1600px', width: '80%' }}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}>
            <span aria-hidden="true">×</span>
          </button>
              新增檔案
          </ModalHeader>
        <ModalBody>
          <MaterialTable
            isLoading={loading}
            components={{
              Toolbar: props => (<div>
                <div style={{ width: '220px', float: 'right', textAlign: 'right', padding: '15px' }}>

                  {/* <Button as='label'
                    color='orange'
                    size='small'
                    content={<>選擇檔案（多選)</>}
                    labelPosition="left"
                    icon={<Icon name='arrow alternate circle up' />}
                  /> */}
                  <ModalUploadFiles {...this.props} isImage='0' handleData={this.handleFileData} dbName='fileCenter2' fieldName='file1' condition={{ fileCenter1Id: rowData.objectId, fileCenter1Text: rowData.name_cht || rowData.value, type: 'product' }} />
                </div>
                <MTableToolbar {...props} />
                <div style={{ clear: 'both' }}></div>
              </div>),
            }}
            localization={localization()}
            columns={columns3()} options={{
              addRowPosition: "first",
              exportButton: true,
              exportAllData: true,
              exportPdf: (columns, data) => exportPdf(columns, data),
              pageSize: 5,
              search: false
            }}
            // data2={bankaccSel}
            data={fileData}
            title=''
            editable={{
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  let data = [...fileData];
                  const index = data.indexOf(oldData);
                  data.splice(index, 1);
                  // handleState('people0', data);
                  this.setState({ fileData: data, oldData }, () => resolve());
                }).then(() => this.handleFileDelete()),
            }}
          />
        </ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button icon='x' onClick={this.handleClose}>關閉</Button>
        </ModalFooter>
      </Modal>
    </div>)
  }
}