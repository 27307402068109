import React from "react";

function PageHeader({ url, text }) {
  document.documentElement.classList.remove("nav-open");
  // React.useEffect(() => {
  //   document.body.classList.add("product-page");
  //   return function cleanup() {
  //     document.body.classList.remove("product-page");
  //   };
  // });
  return (
    <>
      <div
        className="page-header page-header-xs"
        style={{
          backgroundImage:
            "url(" +
            url +
            ")",
          // minHeight: '60vh !important'
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <div className="motto">
            {/* <h1 className="text-center">Paper Kit PRO React</h1>
            <h3 className="text-center">Components</h3> */}
            <span className='pageHeaderH3' style={{ fontSize: '50px' }}>{text}</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageHeader;
