import React, { Component } from 'react';
import { Table, Container, Grid, Header, Form } from 'semantic-ui-react';
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';
import { set0String } from '../../../../widget/define/date';
import Parse from '../../../../widget/parse'
import { Row, Col } from 'reactstrap'

export default class AttendanceWeekly extends Component {
  constructor(props) {
    super(props);
    const nowTimestamp = Date.now()
    const nowObj = new Date();
    let nowYear = nowObj.getFullYear()
    let nowMonth = nowObj.getMonth() + 1  // getMonth()為0-11，所以要+1
    let lastMonth = nowMonth - 1 > 0 ? nowMonth - 1 : 12
    let lastMonthYear = lastMonth > nowMonth ? nowYear - 1 : nowYear
    let lastMonth2 = lastMonth - 1 > 0 ? lastMonth - 1 : 12
    let lastMonthYear2 = lastMonth2 > lastMonth ? lastMonthYear - 1 : lastMonthYear
    let onejan = new Date(nowObj.getFullYear(), 0, 1);
    let nowYearWeek = Math.ceil((((nowObj - onejan) / 86400000) + onejan.getDay() + 1) / 7);
    this.state = {
      form: {},
      attendWeek: [],
      nowToISO: new Date(nowTimestamp).toISOString(),
      nowYear,
      nowMonth,
      lastMonth,
      lastMonthYear,
      lastMonth2,
      lastMonthYear2,
      countRelDay: 0,
      countRelHour: 0,
      countExpDay: 0,
      countExpHour: 0,
      lastWeekData: [],
      nowMonthData: [],
      lastMonthData: [],
      lastMonthData2: [],
      nowYearWeek,
    };
    // this.dailyAttendanceRate = [];
    // this.monthAttendanceRate = [];
  }

  componentDidMount() {
    // console.log('componentDidMount');
    // this.dailyAttendanceRate.forEach(item => {
    //   let rateNum = item.outerText.slice(0, -1);
    //   if (item && Number(rateNum) < 95) {
    //     item.bgColor = 'red';
    //   }
    // })
    // this.monthAttendanceRate.forEach(item => {
    //   let rateNum = item.outerText.slice(0, -1);
    //   if (item && Number(rateNum) < 90) {
    //     item.bgColor = 'red';
    //   }
    // })
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  componentDidUpdate() {
    // this.dailyAttendanceRate.forEach(item => {
    //   let rateNum = item.outerText.slice(0, -1);
    //   if (item && Number(rateNum) < 95) {
    //     item.bgColor = 'red';
    //   }
    // })
    // this.monthAttendanceRate.forEach(item => {
    //   let rateNum = item.outerText.slice(0, -1);
    //   if (item && Number(rateNum) < 90) {
    //     item.bgColor = 'red';
    //   }
    // })
  }

  handleFetch = async (props = this.props) => {
    const { firebase, match } = props;
    const { nowToISO, nowYear, nowMonth, lastMonth, lastMonthYear, lastMonth2, lastMonthYear2, nowYearWeek } = this.state
    let _id = match.params._id ? match.params._id : props._id

    let countRelDay = 0
    let countRelHour = 0
    let countExpDay = 0
    let countExpHour = 0
    const db = Parse.Object.extend("Weekly");
    const query = new Parse.Query(db);
    query.lessThan("endDate", nowToISO);
    query.equalTo("studentId", _id);
    const snap = await query.find();
    const data = snap.map(data => data.toJSON());
    data.forEach(item => {
      countRelDay += Number(item.weeklyRealDay)
      countRelHour += Number(item.weeklyRealHours)
      countExpDay += Number(item.weeklyExpectedDay)
      countExpHour += Number(item.weeklyExpectedHours)
    })
    this.setState({ attendWeek: data, countRelDay, countRelHour, countExpDay, countExpHour });

    // firebase.firestore().collection(`Students/${_id}/Weekly`)
    //   .where('endDate', '<', nowToISO)
    //   .get().then(snap => {
    //     const data = [];
    //     let countRelDay = 0
    //     let countRelHour = 0
    //     let countExpDay = 0
    //     let countExpHour = 0
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     data.forEach(item => {
    //       countRelDay += Number(item.weeklyRealDay)
    //       countRelHour += Number(item.weeklyRealHours)
    //       countExpDay += Number(item.weeklyExpectedDay)
    //       countExpHour += Number(item.weeklyExpectedHours)
    //     })
    //     this.setState({ attendWeek: data, countRelDay, countRelHour, countExpDay, countExpHour });
    //   }, err => { console.log(`Encountered error: ${err}`); });

    // console.log('2019-09-10' > nowToISO)  
    // firebase.firestore().collection(`Students/${_id}/Weekly`)  //  當周
    //   .where('startDate', '<=', nowToISO)//.where('endDate', '>=', nowToISO)
    //   .get().then(snap => {
    //     const nowWeekData = []
    //     snap.forEach(item => item.data().endDate > nowToISO ? nowWeekData.push({ _id: item.id, ...item.data() }) : null)
    //     // console.log(nowWeekData);
    //     this.setState({ nowWeekData })
    //   }, err => { console.log(`Encountered error: ${err}`); });
    let lastYearWeek = nowYearWeek - 1 > 0 ? set0String(nowYearWeek - 1) : 52
    let lastYearWeekY = nowYearWeek - 1 > 0 ? nowYear : nowYear - 1
    let lastYearWeekS = `${lastYearWeekY}-${lastYearWeek}`

    const db1 = Parse.Object.extend("Weekly");
    const query1 = new Parse.Query(db1);
    query1.lessThan("endDate", nowToISO);
    query1.equalTo("studentId", _id);
    query1.equalTo("yearweek", lastYearWeekS);
    const snap1 = await query.find();
    const lastWeekData = snap1.map(data => data.toJSON());
    lastWeekData.find(item => item.endDate < nowToISO)
    this.setState({ lastWeekData })

    // firebase.firestore().collection(`Students/${_id}/Weekly`)  //  前一週
    //   .where('endDate', '<', nowToISO).where('yearweek', '==', lastYearWeekS)
    //   .get().then(snap => {
    //     const lastWeekData = []
    //     snap.forEach(item => lastWeekData.push({ _id: item.id, ...item.data() }))
    //     lastWeekData.find(item => item.endDate < nowToISO)
    // console.log(lastWeekData);
    // this.setState({ lastWeekData })
    // }, err => { console.log(`Encountered error: ${err}`); });

    let nowYearS = nowYear + ''
    let nowMonthS = nowMonth + ''
    const db2 = Parse.Object.extend("Weekly");
    const query2 = new Parse.Query(db2);
    query2.equalTo("studentId", _id);
    query2.equalTo("year", nowYearS);
    query2.equalTo("month", nowMonthS);
    const snap2 = await query.find();
    const nowMonthData = snap2.map(data => data.toJSON());
    let nowMRelDay = 0
    let nowMRelHour = 0
    let nowMExpDay = 0
    let nowMExpHour = 0
    nowMonthData.forEach(item => {
      nowMRelDay += Number(item.weeklyRealDay)
      nowMRelHour += Number(item.weeklyRealHours)
      nowMExpDay += Number(item.weeklyExpectedDay)
      nowMExpHour += Number(item.weeklyExpectedHours)
    })
    let nowMDayRate = nowMRelDay / nowMExpDay
    let nowMHourRate = nowMRelHour / nowMExpHour
    this.setState({ nowMonthData, nowMDayRate, nowMHourRate })

    // firebase.firestore().collection(`Students/${_id}/Weekly`)
    //   .where('year', '==', nowYearS).where('month', '==', nowMonthS)
    //   .get().then(snap => {
    //     const nowMonthData = []
    //     let nowMRelDay = 0
    //     let nowMRelHour = 0
    //     let nowMExpDay = 0
    //     let nowMExpHour = 0
    //     snap.forEach(item => nowMonthData.push({ _id: item.id, ...item.data() }))
    //     nowMonthData.forEach(item => {
    //       nowMRelDay += Number(item.weeklyRealDay)
    //       nowMRelHour += Number(item.weeklyRealHours)
    //       nowMExpDay += Number(item.weeklyExpectedDay)
    //       nowMExpHour += Number(item.weeklyExpectedHours)
    //     })
    //     let nowMDayRate = nowMRelDay / nowMExpDay
    //     let nowMHourRate = nowMRelHour / nowMExpHour
    //     this.setState({ nowMonthData, nowMDayRate, nowMHourRate })
    //   }, err => { console.log(`Encountered error: ${err}`); });


    let lastMonthYearS = lastMonthYear + ''
    let lastMonthS = lastMonth + ''
    const db3 = Parse.Object.extend("Weekly");
    const query3 = new Parse.Query(db3);
    query3.equalTo("studentId", _id);
    query3.equalTo("year", lastMonthYearS);
    query3.equalTo("month", lastMonthS);
    const snap3 = await query.find();
    const lastMonthData = snap3.map(data => data.toJSON());

    let lastMRelDay = 0
    let lastMRelHour = 0
    let lastMExpDay = 0
    let lastMExpHour = 0

    lastMonthData.forEach(item => {
      lastMRelDay += Number(item.weeklyRealDay)
      lastMRelHour += Number(item.weeklyRealHours)
      lastMExpDay += Number(item.weeklyExpectedDay)
      lastMExpHour += Number(item.weeklyExpectedHours)
    })
    let lastMDayRate = lastMRelDay / lastMExpDay
    let lastMHourRate = lastMRelHour / lastMExpHour
    this.setState({ lastMonthData, lastMDayRate, lastMHourRate })

    // firebase.firestore().collection(`Students/${_id}/Weekly`)
    //   .where('year', '==', lastMonthYearS).where('month', '==', lastMonthS)
    //   .get().then(snap => {
    //     const lastMonthData = []
    //     let lastMRelDay = 0
    //     let lastMRelHour = 0
    //     let lastMExpDay = 0
    //     let lastMExpHour = 0
    //     snap.forEach(item => lastMonthData.push({ _id: item.id, ...item.data() }))
    //     lastMonthData.forEach(item => {
    //       lastMRelDay += Number(item.weeklyRealDay)
    //       lastMRelHour += Number(item.weeklyRealHours)
    //       lastMExpDay += Number(item.weeklyExpectedDay)
    //       lastMExpHour += Number(item.weeklyExpectedHours)
    //     })
    //     let lastMDayRate = lastMRelDay / lastMExpDay
    //     let lastMHourRate = lastMRelHour / lastMExpHour
    //     this.setState({ lastMonthData, lastMDayRate, lastMHourRate })
    //   }, err => { console.log(`Encountered error: ${err}`); });


    let lastMonthYearS2 = lastMonthYear2 + ''
    let lastMonthS2 = lastMonth2 + ''
    const db4 = Parse.Object.extend("Weekly");
    const query4 = new Parse.Query(db4);
    query4.equalTo("studentId", _id);
    query4.equalTo("year", lastMonthYearS2);
    query4.equalTo("month", lastMonthS2);
    const snap4 = await query.find();
    const lastMonthData2 = snap4.map(data => data.toJSON());

    let lastMRelDay2 = 0
    let lastMRelHour2 = 0
    let lastMExpDay2 = 0
    let lastMExpHour2 = 0

    lastMonthData2.forEach(item => {
      lastMRelDay2 += Number(item.weeklyRealDay)
      lastMRelHour2 += Number(item.weeklyRealHours)
      lastMExpDay2 += Number(item.weeklyExpectedDay)
      lastMExpHour2 += Number(item.weeklyExpectedHours)
    })
    let lastMDayRate2 = lastMRelDay2 / lastMExpDay2
    let lastMHourRate2 = lastMRelHour2 / lastMExpHour2
    this.setState({ lastMonthData2, lastMDayRate2, lastMHourRate2 }, () => this.getPrintData())
    // firebase.firestore().collection(`Students/${_id}/Weekly`)
    //   .where('year', '==', lastMonthYearS2).where('month', '==', lastMonthS2)
    //   .get().then(snap => {
    //     const lastMonthData2 = []
    //     let lastMRelDay2 = 0
    //     let lastMRelHour2 = 0
    //     let lastMExpDay2 = 0
    //     let lastMExpHour2 = 0
    //     snap.forEach(item => lastMonthData2.push({ _id: item.id, ...item.data() }))
    //     lastMonthData2.forEach(item => {
    //       lastMRelDay2 += Number(item.weeklyRealDay)
    //       lastMRelHour2 += Number(item.weeklyRealHours)
    //       lastMExpDay2 += Number(item.weeklyExpectedDay)
    //       lastMExpHour2 += Number(item.weeklyExpectedHours)
    //     })
    //     let lastMDayRate2 = lastMRelDay2 / lastMExpDay2
    //     let lastMHourRate2 = lastMRelHour2 / lastMExpHour2
    //     this.setState({ lastMonthData2, lastMDayRate2, lastMHourRate2 }, () => this.getPrintData())
    //   }, err => { console.log(`Encountered error: ${err}`); });
  }

  getPrintData = () => {
    const { countRelDay, countRelHour, countExpDay, countExpHour,
      nowYear, nowMonth, lastWeekData,
      // nowMonthData, lastMonthData, lastMonthData2,
      lastMonth, lastMonthYear, lastMonth2, lastMonthYear2,
      nowMDayRate, nowMHourRate, lastMDayRate, lastMHourRate, lastMDayRate2, lastMHourRate2 } = this.state
    const arrPdf = [
      {
        _id: this.props.doc ? this.props.doc._id : this.props._id,
        studentId: this.props.doc ? this.props.doc.studentId : this.props.studentId,
        jpnName: this.props.doc ? this.props.doc.jpnName : this.props.jpnName,
        allAttA: countExpDay, //授業があった日数A
        allAttB: countRelDay, //出席した日数B
        allAttBA: `${Math.round(countRelDay / countExpDay * 100) || '0'}%`, //日数出席率B÷A
        allAttC: countExpHour, //授業があった時間C
        allAttD: countRelHour, //出席した時間D
        allAttDC: `${Math.round(countRelHour / countExpHour * 100) || '0'}%`, //時間出席率D÷C
        nowYear,
        nowMonth,
        lastMonthYear,
        lastMonth,
        lastMonthYear2,
        lastMonth2,
        lastWeek: lastWeekData[0] ? lastWeekData[0].week : 0,
        nowAttA: lastWeekData[0] ? lastWeekData[0].weeklyExpectedDay : 0, //授業があった日数A
        nowAttB: lastWeekData[0] ? lastWeekData[0].weeklyRealDay : 0, //出席した日数B
        nowAttBA: `${lastWeekData[0] ? (Math.round(lastWeekData[0].weeklyRealDay / lastWeekData[0].weeklyExpectedDay * 100) || '0') : 0}%`, //日数出席率B÷A
        nowAttC: lastWeekData[0] ? lastWeekData[0].weeklyExpectedHours : 0, //授業があった時間C
        nowAttD: lastWeekData[0] ? lastWeekData[0].weeklyRealHours : 0, //出席した時間D
        nowAttDC: `${lastWeekData[0] ? (Math.round(lastWeekData[0].weeklyRealHours / lastWeekData[0].weeklyExpectedHours * 100) || '0') : 0}%`, //時間出席率D÷C,
        nowMDayAvg: nowMDayRate,
        nowMHourAvg: nowMHourRate,
        lastMDayAvg: lastMDayRate,
        lastMHourAvg: lastMHourRate,
        lastMDayAvg2: lastMDayRate2,
        lastMHourAvg2: lastMHourRate2,
        nowMDayRate: `${Math.round(nowMDayRate * 100) || '0'}%`,
        nowMHourRate: `${Math.round(nowMHourRate * 100) || '0'}%`,
        lastMDayRate: `${Math.round(lastMDayRate * 100) || '0'}%`,
        lastMHourRate: `${Math.round(lastMHourRate * 100) || '0'}%`,
        lastMDayRate2: `${Math.round(lastMDayRate2 * 100) || '0'}%`,
        lastMHourRate2: `${Math.round(lastMHourRate2 * 100) || '0'}%`
      }
    ]
    this.setState({ arrPdf })
    if (this.props.all === true) {
      this.props.getPrintData(...arrPdf)
    }
  }

  render() {
    // const { semesterYear, semester, doc, calendar, studentsInClass } = this.props;

    const {
      arrPdf, countRelDay, countRelHour, countExpDay, countExpHour,
      nowYear, nowMonth, lastWeekData,
      // nowMonthData, lastMonthData, lastMonthData2,
      lastMonth, lastMonthYear, lastMonth2, lastMonthYear2,
      nowMDayRate, nowMHourRate, lastMDayRate, lastMHourRate, lastMDayRate2, lastMHourRate2
    } = this.state
    // console.log(arrPdf)

    return (<>
      <div ref={el => (this.componentRef = el)} className="" style={{ borderRadius: '.28571429rem', padding: '5px 0' }}>
        {this.props.all === true ?
          <Header as='h4' dividing
            content={`学籍番号：${this.props.studentId}   氏名：${this.props.jpnName}`}
          // subheader='調査時間：2017年4月から 2018年12月 まで'
          /> :
          <>
            <Header as='h2' dividing
              content='出席率個人票'
              style={{ backgroundColor: 'lavender' }}
            // subheader='調査時間：2017年4月から 2018年12月 まで'
            />
            <ModalPdf
              floated='right'
              {...this.props}
              // ref={(ref) => { this.pdfModal = ref }}
              codekey="attendanceWeeklyPdf"
              params={arrPdf}
            /></>}
        {/* <Grid style={{ margin: '25px 1rem' }}> */}
        <Row>
          <Col sm={4}>
            <p>全体</p>
            <p>入学～先週</p>
          </Col>
          <Col sm={8}>
            <Table celled size="small" >
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={6}>日数出席率B÷A</Table.Cell>
                  <Table.Cell width={2} style={{ backgroundColor: Math.round(countRelDay / countExpDay * 100) < 95 ? '#ff8383' : '' }}>{Math.round(countRelDay / countExpDay * 100) || '0'}%</Table.Cell>
                  <Table.Cell width={6}>時間出席率D÷C</Table.Cell>
                  <Table.Cell width={2} style={{ backgroundColor: Math.round(countRelHour / countExpHour * 100) < 95 ? '#ff8383' : '' }}>{Math.round(countRelHour / countExpHour * 100) || '0'}%</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={6}>授業があった日数A</Table.Cell>
                  <Table.Cell width={2} >{countExpDay || '0'}</Table.Cell>
                  <Table.Cell width={6}>授業があった時間C</Table.Cell>
                  <Table.Cell width={2}>{countExpHour || '0'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={6}>出席した日数B</Table.Cell>
                  <Table.Cell width={2}>{countRelDay || '0'}</Table.Cell>
                  <Table.Cell width={6}>出席した時間D</Table.Cell>
                  <Table.Cell width={2}>{countRelHour || '0'}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={4}>
            <p>先週一週間</p>
            <p>{nowYear}年{nowMonth}月第{lastWeekData.map(item => item.week)}週</p>
          </Col>
          <Col sm={8}>
            <Table celled size="small" >
              <Table.Body>
                {lastWeekData.map((item, index) => <React.Fragment key={index}>
                  <Table.Row>
                    <Table.Cell width={6}>日数出席率B÷A</Table.Cell>
                    <Table.Cell width={2} style={{ backgroundColor: Math.round(item.weeklyRealDay / item.weeklyExpectedDay * 100) < 95 ? '#ff8383' : '' }}>{Math.round(item.weeklyRealDay / item.weeklyExpectedDay * 100) || '0'}%</Table.Cell>
                    <Table.Cell width={6}>時間出席率D÷C</Table.Cell>
                    <Table.Cell width={2} style={{ backgroundColor: Math.round(item.weeklyRealHours / item.weeklyExpectedHours * 100) < 95 ? '#ff8383' : '' }}>{Math.round(item.weeklyRealHours / item.weeklyExpectedHours * 100) || '0'}%</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={6}>授業があった日数A</Table.Cell>
                    <Table.Cell width={2}>{item.weeklyExpectedDay || '0'}</Table.Cell>
                    <Table.Cell width={6}>授業があった時間C</Table.Cell>
                    <Table.Cell width={2}>{item.weeklyExpectedHours || '0'}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>出席した日数B</Table.Cell>
                    <Table.Cell>{item.weeklyRealDay || '0'}</Table.Cell>
                    <Table.Cell>出席した時間D</Table.Cell>
                    <Table.Cell>{item.weeklyRealHours || '0'}</Table.Cell>
                  </Table.Row>
                </React.Fragment>)}
              </Table.Body>
            </Table>
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={4}>一ケ月每の出席率</Col>
          <Col sm={8}>
            <Table celled size="small" >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>日数</Table.HeaderCell>
                  <Table.HeaderCell>時間</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{nowYear}年{nowMonth}月</Table.Cell>
                  <Table.Cell style={{ backgroundColor: Math.round(nowMDayRate * 100) < 95 ? '#ff8383' : '' }}>{Math.round(nowMDayRate * 100) || '0'}%</Table.Cell>
                  <Table.Cell style={{ backgroundColor: Math.round(nowMHourRate * 100) < 95 ? '#ff8383' : '' }}>{Math.round(nowMHourRate * 100) || '0'}%</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{lastMonthYear}年{lastMonth}月</Table.Cell>
                  <Table.Cell style={{ backgroundColor: Math.round(lastMDayRate * 100) < 95 ? '#ff8383' : '' }}>{Math.round(lastMDayRate * 100) || '0'}%</Table.Cell>
                  <Table.Cell style={{ backgroundColor: Math.round(lastMHourRate * 100) < 95 ? '#ff8383' : '' }}>{Math.round(lastMHourRate * 100) || '0'}%</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{lastMonthYear2}年{lastMonth2}月</Table.Cell>
                  <Table.Cell style={{ backgroundColor: Math.round(lastMDayRate2 * 100) < 95 ? '#ff8383' : '' }}>{Math.round(lastMDayRate2 * 100) || '0'}%</Table.Cell>
                  <Table.Cell style={{ backgroundColor: Math.round(lastMHourRate2 * 100) < 95 ? '#ff8383' : '' }}>{Math.round(lastMHourRate2 * 100) || '0'}%</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Col>
        </Row>
        {/* </Grid> */}
      </div>
    </>);
  }
}