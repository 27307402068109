import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select, Dimmer } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

// import RowsDnd from './RowsDnd'
// import intl from 'react-intl-universal';
import { Row, Col, Form, FormGroup, Input, Label, Button } from "reactstrap";

const columns = () => ([
  { width: 80, title: '當票編號', field: 'ticketNum' },
  { width: 80, title: '借款日期', field: 'loanDate' },
  { width: 80, title: '借款金額', field: 'ladnMoney' },
  { width: 80, title: '典當類別', field: 'pawnType' },
  { width: 80, title: '月息', field: 'monthlyInterest' },
  { width: 80, title: '每期天數', field: 'daysPerPeriod' },
  { width: 80, title: '期息', field: 'periodInterest' },
  { width: 80, title: '滿期月數', field: 'expiryMonths' },
  { width: 80, title: '典當物品', field: 'pawnItems' },
  { width: 80, title: '催帳備註', field: 'dunningPs' },
  { width: 80, title: '存放位置', field: 'storageLocation' },
  { width: 80, title: '備註', field: 'ps' },
  { width: 80, title: '姓名', field: 'name' },
  { width: 80, title: '性別', field: 'sexualId' },
  { width: 80, title: '出生日期', field: 'birthday' },
  { width: 180, title: '居住住址', field: 'residentialAddr' },
  { width: 80, title: '居住電話', field: 'residentialPhone' },
  { width: 80, title: '戶籍地址', field: 'censusAddr' },
  { width: 80, title: '戶籍電話', field: 'censusPhone' },
  { width: 180, title: '服務公司', field: 'serviceCompany' },
  { width: 80, title: '年齡', field: 'age' },
  { width: 80, title: '職務', field: 'position' },
  { width: 80, title: '車號', field: 'carNum' },

]);

class PawnOrder extends Component {
  state = {
    activeItem: 'bio',
    dataArr: [],
    rowData: {},
    loading: true
  }

  async componentDidMount() {
    const data1 = await Parse.queryData('pawnticketOrder');
    this.setState({ dataArr: data1, loading: false });
  }

  handleAdd = async () => {
    const { newData } = this.state;
    Parse.saveData('pawnticketOrder', newData);
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    Parse.saveData('pawnticketOrder', newData);
  }

  handleDelete = async () => {
    const { oldData } = this.state
    Parse.deleteData('pawnticketOrder', oldData);
    this.setState({ oldData: {} })
  }

  handleData = (rowData) => {
    this.setState({ rowData });
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render() {
    const { rowData, dataArr, loading } = this.state
    return (<div className="content">
      <Row>
        <Col><legend>1-4 當票單</legend></Col>
      </Row>
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '當票單'),
          pageSize: 10,
          search: false,
          tableLayout: 'fixed'
        }}
        data={dataArr}
        title="當票單"
        actions={[
          {
            icon: 'view_headline',
            tooltip: '查看',
            onClick: (event, rowData) => this.handleData(rowData)
          }
        ]}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const data = [...dataArr];
              const db = Parse.Object.extend("pawnticketOrder");
              const newDoc = new db();
              const doc = await newDoc.save();
              newData.objectId = doc.id;
              newData.id = doc.id;
              newData._id = doc.id;

              data.push(newData);
              this.setState({ dataArr: data, newData }, () => resolve());
            }).then(() => this.handleAdd()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...dataArr];
              const index = data.indexOf(oldData);
              data[index] = newData;
              // handleState('people0', data);
              this.setState({ dataArr: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...dataArr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              // handleState('people0', data);
              this.setState({ dataArr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
      <br />
      {/* <br />
        {rowData.ticketNum ?
          <Form>
            <Row form>
              <Col md={1}>
                <FormGroup>
                  <Label for="exampleEmail">當票編號</Label>
                  <Input type="text" name="ticketNum" id="ticketNum" value={rowData.ticketNum} placeholder="當票編號" />
                </FormGroup>
              </Col>
              <Col md={1}>
                <FormGroup>
                  <Label for="examplePassword">借款日期</Label>
                  <Input type="date" name="loanDate" id="loanDate" value={rowData.loanDate} placeholder="借款日期" />
                </FormGroup>
              </Col>
              <Col md={1}>
                <FormGroup>
                  <Label for="examplePassword">借款金額</Label>
                  <Input type="text" name="ladnMoney" id="ladnMoney" value={rowData.ladnMoney} placeholder="借款金額" />
                </FormGroup>
              </Col>
              <Col md={1}>
                <FormGroup>
                  <Label for="examplePassword">典當類別</Label>
                  <Input type="select" name="pawnType" id="pawnType" placeholder="典當類別" value={rowData.pawnType} multiple />
                </FormGroup>
              </Col>
              <Col md={1}>
                <FormGroup>
                  <Label for="examplePassword">典當物品</Label>
                  <Input type="text" name="pawnItems" id="pawnItems" placeholder="典當物品" value={rowData.pawnItems} />
                </FormGroup>
              </Col>
              <Col md={1}>
                <FormGroup>
                  <Label for="examplePassword">存放位置</Label>
                  <Input type="text" name="storageLocation" id="storageLocation" placeholder="存放位置" value={rowData.storageLocation} />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={1}>
                <FormGroup>
                  <Label for="examplePassword">月息</Label>
                  <Input type="text" name="monthlyInterest" id="monthlyInterest" placeholder="月息" value={rowData.monthlyInterest} />
                </FormGroup>
              </Col>
              <Col md={1}>
                <FormGroup>
                  <Label for="examplePassword">每期天數</Label>
                  <Input type="text" name="daysPerPeriod" id="daysPerPeriod" placeholder="每期天數" value={rowData.daysPerPeriod} />
                </FormGroup>
              </Col>
              <Col md={1}>
                <FormGroup>
                  <Label for="examplePassword">期息</Label>
                  <Input type="text" name="periodInterest" id="periodInterest" placeholder="期息" value={rowData.periodInterest} />
                </FormGroup>
              </Col>
              <Col md={1}>
                <FormGroup>
                  <Label for="exampleEmail">滿期月數</Label>
                  <Input type="text" name="expiryMonths" id="expiryMonths" placeholder="滿期月數" value={rowData.expiryMonths} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="examplePassword">催帳備註</Label>
                  <Input type="text" name="dunningPs" id="dunningPs" placeholder="催帳備註" value={rowData.dunningPs} />
                </FormGroup>
              </Col>
            </Row>
            <hr style={{ margin: '20px' }} />
            <Row form>
              <Col md={1}>
                <FormGroup>
                  <Label for="examplePassword">姓名</Label>
                  <Input type="text" name="name" id="name" placeholder="姓名" value={rowData.name} />
                </FormGroup>
              </Col>
              <Col md={1}>
                <FormGroup>
                  <Label for="examplePassword">性別</Label>
                  <Input type="select" name="sexualId" id="sexualId" placeholder="性別" value={rowData.sexualId} />
                </FormGroup>
              </Col>
              <Col md={1}>
                <FormGroup>
                  <Label for="examplePassword">出生日期</Label>
                  <Input type="date" name="birthday" id="birthday" placeholder="出生日期" value={rowData.birthday} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="examplePassword">居住地址</Label>
                  <Input type="text" name="residentialAddr" id="residentialAddr" placeholder="居住地址" value={rowData.residentialAddr} />
                </FormGroup>
              </Col>
              <Col md={1}>
                <FormGroup>
                  <Label for="examplePassword">居住電話</Label>
                  <Input type="text" name="residentialPhone" id="residentialPhone" placeholder="居住電話" value={rowData.residentialPhone} />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="examplePassword">戶籍地址</Label>
                  <Input type="text" name="censusAddr" id="censusAddr" placeholder="戶籍地址" value={rowData.censusAddr} />
                </FormGroup>
              </Col>
              <Col md={1}>
                <FormGroup>
                  <Label for="examplePassword">戶籍電話</Label>
                  <Input type="text" name="censusPhone" id="censusPhone" placeholder="戶籍電話" value={rowData.censusPhone} />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={1}>
                <FormGroup>
                  <Label for="examplePassword">服務公司</Label>
                  <Input type="text" name="serviceCompany" id="serviceCompany" placeholder="服務公司" value={rowData.serviceCompany} />
                </FormGroup>
              </Col>
              <Col md={1}>
                <FormGroup>
                  <Label for="examplePassword">年齡</Label>
                  <Input type="text" name="age" id="age" placeholder="年齡" value={rowData.age} />
                </FormGroup>
              </Col>
              <Col md={1}>
                <FormGroup>
                  <Label for="examplePassword">職務</Label>
                  <Input type="text" name="position" id="position" placeholder="職務" value={rowData.position} />
                </FormGroup>
              </Col>
              <Col md={1}>
                <FormGroup>
                  <Label for="examplePassword">車號</Label>
                  <Input type="text" name="carNum" id="carNum" placeholder="車號" value={rowData.carNum} />
                </FormGroup>
              </Col>

            </Row>
            <hr style={{ margin: '20px' }} />
            <FormGroup>
              <Label for="exampleText">備註</Label>
              <Input type="textarea" name="ps" id="ps" value={rowData.ps} />
            </FormGroup>
            <Button>{intl.get('儲存')}</Button>
          </Form>
          : null} */}
    </div>);
  }
}

export default PawnOrder;