import React, { Component } from 'react'
import { Container, Icon, Table, Grid, Button, Modal, Header, Input, Label, Select, Image } from 'semantic-ui-react'
import _ from 'lodash'
import { Link } from 'react-router-dom';

import MyModalTable from '../../widgets/MyModalTable';
import { getLabelName2, getOptionsByKey } from 'views/widgets/FieldsRender';
import ModalPdf from 'widget/pdf/ModalPdf';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import Parse from '../../../widget/parse'
const countRate = (a1, b1) => {
  const a = a1 + '';
  const b = b1 + '';
  if (a === "0" && b === "0") {
    return "0%";
    // } else if (a === "0" && b === "0") {
    //   return '0%';
  }
  return (Math.round((Number(a) / Number(b) * 1000)) / 10) + '%';
}

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipse: {
    // boxSizong: 'border-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}

export class GraduateAward extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      author: '',
      arr: [],
      graduateYear: `${Number(this.props.semesterYear) + 1}`,
      yearsemester: `${this.props.semesterYear},${this.props.semester}`,
      students: []
    }
  }
  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }
  handleFetch = async (props = this.props) => {
    const { firebase, match, studentsL, handleLazyLoad, companyObj } = props
    const { yearsemester, graduateYear } = this.state
    let graStudents = []
    // if (!studentsL) {
    //   handleLazyLoad('students');
    // } else {
    const students = await Parse.queryData('Students', { companyId: companyObj.objectId });

    graStudents = students.filter(item => item.semesterYear === (Number(graduateYear) - 2 + ''));
    this.setState({ graStudents })
    // }

    // let db = firebase.firestore();
    let promises = []
    graStudents.forEach(({ objectId, ...rest }) => {
      // const promise = db.collectionGroup('Weekly')
      //   .where('studentId', '==', _id)
      //   .get().then(snap => {
      //     const data = [];
      //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }))
      //     return { _id, ...rest, data };
      //   })
      // promises.push(promise);
      const promise = Parse.queryData('Weekly', { companyId: companyObj.objectId, studentId: objectId }).then(snap => {
        const data = [];
        snap.forEach(item => data.push(item))
        return { objectId, ...rest, data };
      })
      promises.push(promise);

    })

    Promise.all(promises).then(results => {
      const arrRow = [];
      const d = new Date();
      results.forEach(item => {
        let weeklyExpectedDay = 0;
        let weeklyExpectedHours = 0;
        let weeklyRealDay = 0;
        let weeklyRealHours = 0;

        item.data.forEach(item2 => {
          if (new Date(item2.endDate) < d) {
            // console.log(item2.endDate)
            weeklyExpectedDay += Number(item2.weeklyExpectedDay);
            weeklyExpectedHours += Number(item2.weeklyExpectedHours);
            weeklyRealDay += Number(item2.weeklyRealDay);
            weeklyRealHours += Number(item2.weeklyRealHours);
          }
        })
        arrRow.push({
          ...item.data[0],
          weeklyExpectedDay,
          weeklyExpectedHours,
          weeklyRealDay,
          weeklyRealHours
        })
      })
      this.setState({ arrRow }, () => this.mergeArr())
    }).catch(error => {
      console.log(error);
    });
  }
  handleChange = (event, data) => {
    let fieldName = data.name;
    let fleldVal = data.value;
    this.setState({ [fieldName]: fleldVal });
  }
  mergeArr = () => {
    const { arrRow, graStudents } = this.state
    // console.log(arrRow, graStudents)
    const arr = []
    arrRow.forEach(item => {
      graStudents.forEach(item2 => {
        if (item.studentId === item2.objectId) {
          const weeklyExpectedDay = item.weeklyExpectedDay
          const weeklyExpectedHours = item.weeklyExpectedHours
          const weeklyRealDay = item.weeklyRealDay
          const weeklyRealHours = item.weeklyRealHours
          arr.push({
            ...item2,
            weeklyExpectedDay,
            weeklyExpectedHours,
            weeklyRealDay,
            weeklyRealHours
          })
        }
      })
    })
    // console.log(arr)
    this.setState({ arr })
  }
  render() {
    const { firebase, match, history, formSet, optSet, students, semester, semesterYear } = this.props;
    const { arrRow, yearsemester, arr, graduateYear, graStudents } = this.state;
    const semesterYearArr = getOptionsByKey(optSet, 'semesterYear').map(item => ({ key: item.objectId, text: item.label, value: item.value }));
    const semesterArr = [
      { key: '2019,A', text: '2019A', value: '2019,A' },
      { key: '2019,S', text: '2019S', value: '2019,S' },
      { key: '2018,A', text: '2018A', value: '2018,A' },
      { key: '2018,S', text: '2018S', value: '2018,S' },
      { key: '2017,A', text: '2017A', value: '2017,A' },
      { key: '2017,S', text: '2017S', value: '2017,S' },
    ]
    let year = yearsemester.substring(0, 4);
    let semes = yearsemester.substring(5);
    // console.log(arrRow, graStudents)
    // const arrPdf = [{
    //   arr: graStudents.sort((a, b) => a.studentNum - b.studentNum)
    // }]
    return (
      <>

        <div style={{ ...style.flexCenter }}>
          <Header as='h2' style={{ margin: 0 }}>卒業生受賞一覧</Header>
          <div style={{ ...style.flexCenter }}>
            {/* <Input labelPosition='left' >
                <Label color={'blue'}> <Icon name='file alternate outline' />卒業生入学期</Label>
                <Select style={{
                  minWidth: '250px',
                  borderTopLeftRadius: '0',
                  borderBottomLeftRadius: '0'
                }} compact
                  options={semesterArr} onChange={this.handleChange} value={yearsemester} name='yearsemester' />
              </Input> */}
            <Input labelPosition='left' >
              <Label color={'blue'}> <Icon name='file alternate outline' />卒業年</Label>
              <Select style={{
                minWidth: '120px',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
                marginRight: 10
              }} compact
                options={semesterYearArr} onChange={this.handleChange} value={graduateYear} name='graduateYear' />
            </Input>
            <div style={{ ...style.flexCenter, minWidth: 220, marginLeft: 10 }}>
              <ExportTableToExcelButton size='small' />
              <ModalPdf
                {...this.props}
                // ref={(ref) => { this.pdfModal = ref }}
                codekey="graduateAwardPdf"
                // params={arrPdf}
                params={graStudents && graStudents.sort((a, b) => a.studentNum - b.studentNum)}
              />
            </div>
          </div>
        </div>
        <div style={{ maxWidth: '100vw', overflowX: 'scroll', marginTop: 25 }}>
          <Table id='excel' celled striped size='small' selectable>
            <Table.Header>
              <Table.Row textAlign='center'>
                {/* <Table.Cell></Table.Cell> */}
                <Table.HeaderCell style={style.textEllipse}>学籍番号</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>学年</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>学期</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>クラス</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>写真</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>日本語氏名</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>国籍</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>卒業賞</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>卒業賞2</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>日出席率</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>時出席率</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>日留試6月</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>日留試11月</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>校內試験9月</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>11月</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>1月</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>日能試N1</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>校內賞罰得点</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>備考</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>確認</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {arr.map((item, index) => (<Table.Row key={item.objectId}>
                {/* <Link to={`/TeacherPage/${item._id}`}> */}
                {/* <Table.Cell style={style.textEllipse}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                      <Link to={`/TeacherPage/${item._id}`}><Button inverted={companyObj.inverted} color='yellow' size='small' style={{ margin: 15 }}>詳細</Button></Link>
                      <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} style={{ margin: 15 }}icon='edit' content='修正' />
                    </div>
                  </Table.Cell> */}
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}>{item.studentId}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}>{item.semesterYear}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}>{item.semester}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}>{item.classSchool}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}><Image floated='left' src={item.photo || 'http://via.placeholder.com/30'} circular style={{ marginBottom: '0px', width: '50px' }} /></Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}>{item.jpnName}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}>{item.nationalityText}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}>{item.gradPrice}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}>{item.gradPrice2}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}>{countRate(item.weeklyRealDay, item.weeklyExpectedDay)}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}>{countRate(item.weeklyRealHours, item.weeklyExpectedHours)}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}>{}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}>{}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}>{}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}>{}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}>{}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}>{}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}>{}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}>{}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.objectId}`}>{}</Link></Table.Cell>
                {/* </Link> */}
              </Table.Row>))}
            </Table.Body>
          </Table>
        </div>

        {/* <Modal open={this.state.show} onClose={this.handleClose} size='tiny'>
          <Modal.Header style={style.flexCenter}>
            <h2 style={{ margin: 0 }}> {form._id ? form.teacherTitleText + ' ' + form.nameTeacher + ' 教師' : '教師'}</h2>
            <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
          </Modal.Header>
          <Modal.Content>
            <Grid>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>状態</label>
                  <Form.Select
                    options={isWorkArrModal}
                    defaultValue={form._id ? form.staffStatus : ' '}
                    onChange={this.handleChange}
                    name='staffStatus'
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>教員番号</label>
                  <Form.Input
                    style={{ minWidth: 196 }}
                    name='teacherNum'
                    value={form.teacherNum ? form.teacherNum : ' '}
                    onChange={this.handleChange}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>氏名 (簡稱)</label>
                  <Form.Input
                    style={{ minWidth: 196 }}
                    name='nameTeacher'
                    value={form.nameTeacher ? form.nameTeacher : ''}
                    onChange={this.handleChange}
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>氏名 (全名)</label>
                  <Form.Input
                    style={{ minWidth: 196 }}
                    name='nameTeacherFull'
                    value={form.nameTeacherFull || ''}
                    onChange={this.handleChange}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>職名</label>
                  <Form.Select
                    options={staffNameArr}
                    defaultValue={form._id ? form.teacherTitle : ' '}
                    onChange={this.handleChange}
                    name='teacherTitle'
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>専任/非専任</label>
                  <Form.Select
                    options={staffProArr}
                    defaultValue={form._id ? form.partTimeTeacher : ' '}
                    onChange={this.handleChange}
                    name='partTimeTeacher'
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>就職学期</label>
                  <Form.Select
                    options={semesterArrStart}
                    onChange={this.handleChange}
                    name='startSemesterNum'
                    defaultValue={form.startSemester || ' '}
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>離職学期</label>
                  <Form.Select
                    options={semesterArrEnd}
                    onChange={this.handleChange}
                    name='endSemesterNum'
                    defaultValue={form.endSemester || ' '}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Content style={{ borderTop: '1px solid rgba(34,36,38,.15)', display: 'flex', justifyContent: 'flex-end' }}>
            <Button size='small' onClick={this.handleSubmit} inverted={companyObj.inverted} color='green' loading={loading} icon='checkmark' content={form._id ? '保存' : '追加'} />
            <Button size='small' onClick={this.handleClose} basic>閉じる </Button>
          </Modal.Content>
        </Modal> */}
        {/* <Confirm
          header='削除確認'
          content='削除確認?'
          cancelButton='閉じる'
          confirmButton='削除'
          open={this.state.confirm}
          onCancel={this.hideConfirm}
          onConfirm={this.handleRemove}
          size='mini'
          centered={false} /> */}
      </>
    )
  }
}
export default GraduateAward