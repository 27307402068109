import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor.js';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment.js';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat.js';
import Autosave from '@ckeditor/ckeditor5-autosave/src/autosave.js';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote.js';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js';

// import CKFinderUploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter.js';
// import Code from '@ckeditor/ckeditor5-basic-styles/src/code.js';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js';
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor.js';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily.js';
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize.js';
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js';
import Highlight from '@ckeditor/ckeditor5-highlight/src/highlight.js';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js';
import Image from '@ckeditor/ckeditor5-image/src/image.js';
// import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption.js';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize.js';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle.js';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar.js';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js';
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js';
import Link from '@ckeditor/ckeditor5-link/src/link.js';
import List from '@ckeditor/ckeditor5-list/src/list.js';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed.js';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import Table from '@ckeditor/ckeditor5-table/src/table.js';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar.js';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation.js';

// import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';
// import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import SimpleUploadAdapter from './SimpleUploadAdapter';
import EditorCustomAttribute from './EditorCustomAttribute'

class Editor extends ClassicEditor { }

Editor.defaultConfig = {
  // extraPlugins: [MyCustomUploadAdapterPlugin],
  toolbar: {
    items: [
      // 'code',
      'heading',
      'fontFamily',
      'fontSize',
      'bold',
      'italic',
      'blockQuote',
      'alignment',
      'indent',
      'outdent',
      'numberedList',
      'bulletedList',
      'insertTable',
      '|',
      'link',
      // 'ckfinder',
      'imageUpload',
      'mediaEmbed',
      '|',
      'horizontalLine',
      'fontColor',
      'highlight',
      'fontBackgroundColor',
      'undo',
      'redo'
    ]
  },
  language: 'zh',
  image: {
    toolbar: [
      // 'imageTextAlternative',
      'imageStyle:full',
      'imageStyle:side',
    ],
    resizeUnit: 'px',
    styles: [
      // This option is equal to a situation where no style is applied.
      'full',
      'side',
      // This represents an image aligned to the left.
      'alignLeft',
      // This represents an image aligned to the right.
      'alignRight'
    ]
  },
  table: {
    contentToolbar: [
      'tableColumn',
      'tableRow',
      'mergeTableCells',
      'tableProperties',
      'tableCellProperties',
    ],
  },
  simpleUpload: {
    uploadUrl: 'https://parse.infowin.com.tw/parse/files/aaa.jpg',
    headers: {
      "X-Parse-Application-Id": "parseServer",
      "X-Parse-REST-API-Key": "infowin"
    }
  },
}

Editor.builtinPlugins = [
  Alignment,
  Autoformat,
  Autosave,
  BlockQuote,
  Bold,
  // CKFinderUploadAdapter,
  // Code,
  // CKFinder,
  // MyCustomUploadAdapterPlugin,
  SimpleUploadAdapter,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HorizontalLine,
  Image,
  // ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  Indent,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  PasteFromOffice,
  Table,
  TableProperties,
  TableCellProperties,
  TableToolbar,
  TextTransformation,
  EditorCustomAttribute,
];

export default Editor;
