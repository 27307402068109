import React from "react";
import {
  // Button,
  // Card,
  // CardHeader,
  // CardBody,
  // CardFooter,
  // CardTitle,
  FormGroup,
  // Form,
  Input,
  // Row,
  Col,
  // CardImg
} from "reactstrap";
import Select from "react-select";
import { Checkbox } from 'semantic-ui-react';

import { userOpt, clientPawnOpt, clientHanburyOpt, employeePawnOpt, userPawnOpt, portfolioOpt } from './option';

export const fieldFormGroup = ({ label, type, placeholder, name, option = '', select = '', }, value = '', onChange, disabled, options) => {
  if (type === 'select') {
    if (options) {
      option = options
    }
    let obj = value || {}
    switch (option) {
      case 'userOpt':
        option = userOpt[name]
        break;
      case 'clientPawnOpt':
        option = clientPawnOpt[name]
        break;
      case 'employeePawnOpt':
        option = employeePawnOpt[name]
        break;
      case 'userPawnOpt':
        option = userPawnOpt[name]
        break;
      case 'clientHanburyOpt':
        option = clientHanburyOpt[name]
        break;
      case 'portfolioOpt':
        option = portfolioOpt[name]
        break;
      default:
        console.log(`找不到${name}此欄位選項`);
    }
    if (option && value.label === undefined) {
      obj = {  //select新建資料為object。由於舊資料只有存value的，在此幫助舊資料轉為object使其可顯示。若為新資料進來先比對label在比對value，label成功則為react-select所建之資料為object;value成功則為就資料僅儲存string
        value: (option && option.find(item => item.label === value) && option.find(item => item.label === value).value) || (option && option.find(item => item.value === value) && option.find(item => item.value === value).value) || '',
        label: (option && option.find(item => item.label === value) && option.find(item => item.label === value).label) || (option && option.find(item => item.value === value) && option.find(item => item.value === value).label) || '',
      }
    }
    return (<FormGroup>
      <label htmlFor="fieldGroup">{label}</label>
      <Select
        id={'fieldFormGroup' + '_' + name}
        value={obj}
        onChange={onChange}
        name={name}
        isDisabled={disabled}
        // placeholder={placeholder || label}
        options={option}
      />
    </FormGroup>)
  } else if (type === 'checkbox') {
    return <Checkbox label={label} readOnly={disabled} id={name} onChange={onChange} name={name} checked={value === '1' ? true : false} />
  } else { // 一般的input
    return (<FormGroup>
      <label htmlFor="fieldGroup">{label}</label>
      <Input
        id={'fieldFormGroup' + '_' + name}
        name={name}
        // placeholder={placeholder || label}
        type={type}
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
    </FormGroup>)
  }
}

export const fieldCol = (object = '', className, md, value = '', onChange, disabled, options) => (
  <Col className={className} md={md}>
    {fieldFormGroup(object, value, onChange, disabled, options)}
  </Col>
)
