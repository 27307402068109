import React, { Component } from 'react'
import { Container, Button, Dimmer, Loader, Image, Segment, Header, Tab, Form } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import localization from 'widget/MaterialTableOpt'
import Parse from 'widget/parse'
import C13a from './C13a';

const roomType = { '0': '單人房', '1': '雙人房' };

const columns = () => ([
  { title: '社區名稱', field: 'buildingName' },
  { title: '地址', field: 'address' },
]);

const columns2 = () => ([
  { title: '社區', field: 'buildingName', editable: 'never' },
  { title: '房型', field: 'roomType', lookup: roomType },
  { title: '房號', field: 'roomNum' },
  { title: '每月租金', field: 'rent' },
]);

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}
export default class Client1 extends Component {
  state = {
    dataArr: [],
    dataArr1: [],
    dataArr2: [],
    rowData: {},
    roomObj: {}
  }

  componentDidMount() {
    // const { calendar } = this.props
    // this.setState({ dataArr: calendar })
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleData = (rowData) => {
    // const { firebase, companyObj } = this.props;

    // firebase.firestore().collection(`company/${companyObj.id}/building/${rowData._id}/room`)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ id: item.id, ...item.data() }));
    //     this.setState({ rowData, dataArr1: data, loading: false });
    //     // console.log(data)
    //   }, err => { console.log(`Encountered error: ${err}`); });
  }

  handleData1 = (roomObj) => {
    // const { firebase, companyObj } = this.props;
    // const { rowData } = this.state
    // firebase.firestore().collection(`company/${companyObj.id}/building/${rowData.id}/room/${roomObj.id}/room1`)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ id: item.id, ...item.data() }));
    //     this.setState({ roomObj, dataArr2: data, loading: false });
    //     // console.log(data)
    //   }, err => { console.log(`Encountered error: ${err}`); });

    this.setState({ roomObj });
  }

  handleFetch = () => {
    // const { firebase, companyObj } = this.props;
    // const { id, newData } = this.state
    // firebase.firestore().collection(`company/${companyObj.id}/building/`)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ id: item.id, ...item.data() }));
    //     this.setState({ dataArr: data, loading: false });
    //     // console.log(data)
    //   }, err => { console.log(`Encountered error: ${err}`); });
  }

  handleAdd = async (type) => {
    // const { firebase, companyObj } = this.props
    const { newData, id } = this.state
    const obj = { ...newData, };
    // console.log(obj)
    // const db = firebase.firestore();
    if (type === 1) {
      // const docRef = db.collection(`company/${companyObj.id}/building`).doc(obj.id);
      // await docRef.set(obj);
    } else if (type === 2) {
      // const docRef = db.collection(`company/${companyObj.id}/building/${obj.buildingId}/room`).doc(obj.id);
      // await docRef.set(obj);
    }
  }

  handleUpdate = async (type) => {
    // const { firebase, companyObj } = this.props
    // const { newData, rowData } = this.state
    // const db = firebase.firestore()
    if (type === 1) {
      // const docRef = db.doc(`company/${companyObj.id}/building/${newData.id}`)
      // const obj = { ...newData };
      // await docRef.update(obj);
    } else if (type === 2) {
      // const docRef = db.doc(`company/${companyObj.id}/building/${rowData.id}/room/${newData.id}`)
      // const obj = { ...newData };
      // await docRef.update(obj);
    }

    // this.setState({ oldData: {}, newData: {} })
  }

  handleDelete = async (type) => {
    // const { firebase, companyObj } = this.props
    // const { oldData, rowData, id } = this.state
    // const db = firebase.firestore()
    if (type === 1) {
      // await db.collection(`company/${companyObj.id}/building`).doc(oldData.id).delete();
      this.setState({ oldData: {} })
    } else if (type === 2) {
      // await db.collection(`company/${companyObj.id}/building/${rowData.id}/room`).doc(oldData.id).delete();
      this.setState({ oldData: {} })
    }
  }

  render() {
    const { firebase, companyObj } = this.props;
    const { dataArr, dataArr1, rowData, dataArr2, roomObj, loading } = this.state;

    return (<>
      <Container>
        <div style={{ ...style.flexCenter, position: 'relative', zIndex: 100 }}>
          {/* <Header as='h2' style={{ margin: '2rem 0' }}>客房資料</Header> */}
        </div>
        <Form>
          <MaterialTable
            isLoading={loading}
            localization={localization()}
            columns={columns()}
            options={{
              pageSize: 5,
              search: false
            }}
            data={dataArr}
            title={'支出'}
            actions={[
              {
                icon: 'view_headline',
                tooltip: '查看',
                onClick: (event, rowData) => this.handleData(rowData)
              }
            ]}
            editable={{
              onRowAdd: newData =>
                new Promise(async (resolve, reject) => {
                  const data = [...dataArr];
                  const db = Parse.Object.extend("");
                  const newDoc = new db();
                  const doc = await newDoc.save();
                  const obj = {
                    id: doc.id,
                    _id: doc.id,
                    companyId: companyObj.id,
                    companyName: companyObj.companyName,
                    ...newData
                  }
                  // data.push(obj);
                  data.push(obj);
                  this.setState({ dataArr: data, newData: obj }, () => resolve());
                }).then(() => this.handleAdd(1)),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const data = dataArr;
                  const index = data.indexOf(oldData);
                  data[index] = newData;
                  // handleState('people0', data);
                  this.setState({ dataArr: data, newData, oldData }, () => resolve());
                }).then(() => this.handleUpdate(1)),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  let data = dataArr;
                  const index = data.indexOf(oldData);
                  data.splice(index, 1);
                  // handleState('people0', data);
                  this.setState({ dataArr: data, oldData }, () => resolve());
                }).then(() => this.handleDelete(1)),
            }}
          />
          <br />
          <br />
          {rowData && rowData.id ?
            <MaterialTable
              isLoading={loading}
              localization={localization()}
              columns={columns2()}
              options={{
                pageSize: 5,
                search: false
              }}
              data={dataArr1}
              title={'社區(' + rowData.buildingName + ')  房間'}
              actions={[
                {
                  icon: 'view_headline',
                  tooltip: '查看',
                  onClick: (event, roomObj) => this.handleData1(roomObj)
                }
              ]}
              editable={{
                onRowAdd: newData =>
                  new Promise((resolve, reject) => {
                    const db = firebase.firestore();
                    const docRef = db.collection(`company/${companyObj.id}/building/${rowData._id}/room`).doc();
                    const data = dataArr1;
                    const obj = {
                      id: docRef.id,
                      _id: docRef.id,
                      companyId: companyObj.id,
                      companyName: companyObj.companyName,
                      buildingId: rowData._id,
                      buildingName: rowData.buildingName,
                      roomTypeText: roomType[newData.roomType],
                      ...newData
                    }
                    // data.push(obj);
                    data.push(obj);
                    this.setState({ dataArr1: data, newData: obj }, () => resolve());
                  }).then(() => this.handleAdd(2)),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    const data = dataArr1;
                    const index = data.indexOf(oldData);

                    newData.roomTypeText = roomType[newData.roomType];
                    data[index] = newData;
                    // handleState('people0', data);
                    this.setState({ dataArr1: data, newData, oldData }, () => resolve());
                  }).then(() => this.handleUpdate(2)),
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    let data = dataArr1;
                    const index = data.indexOf(oldData);
                    data.splice(index, 1);
                    // handleState('people0', data);
                    this.setState({ dataArr1: data, oldData }, () => resolve());
                  }).then(() => this.handleDelete(2)),
              }}
            /> : null}
          {roomObj && roomObj._id ? <C13a {...this.props} rowData={rowData} roomObj={roomObj} /> : null}
        </Form>
      </Container>
    </>)
  }
}