import React, { Component } from 'react'
import { Container, Header, Modal, Button, Table, Form, Grid, Image, Input, Checkbox, Divider, Tab } from 'semantic-ui-react';

import Company1 from './Company1';
// import Company2 from './Company2';
// import Users from './Users';
import Billing from './Billing';
// import Theme from './Theme';
// import Parse from 'widget/parse'
// import localization from 'widget/MaterialTableOpt'

class System extends Component {
  state = {
    companyObj: this.props.companyObj
  }

  async componentDidMount() {
    // const { companyObj } = this.props
    // const { familyId } = match.params;
  }

  async componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    // this.setState({ companyObj: nextProps.companyObj })
  }

  handleChange = (e, { name, value }) => {
    const { companyObj } = this.state;
    this.setState({ companyObj: { ...companyObj, [name]: value } })
  }

  // handleSaveCompany = async () => {
  //   const {  } = this.props
  //   const { companyObj } = this.state;
  //   const { pray1 } = ;
  //   const db = firebase.firestore();
  //   const docRef = db.collection(`company`).doc(companyObj.id);

  //   if (this.props.companyObj.pray1IdDef !== companyObj.pray1IdDef) {
  //     const arr = pray1.map((item, i) => ({ ...item, now_use: (companyObj.pray1IdDef === item.id) ? "-1" : '0' }));
  //     const batch = db.batch()
  //     arr.forEach((item, index) => {
  //       const ref = db.doc(`company/${companyObj._id}/pray1/${item.id}`);
  //       batch.update(ref, item);
  //     })
  //     await batch.commit()
  //   }

  //   // console.log(companyObj)
  //   delete companyObj.tableData
  //   await docRef.update(companyObj);
  //   window.alert('已儲存');
  //   // this.handleFetch();
  // }

  // react-color
  handleChangeColorComplete = (color, event, field) => {
    this.setState({ companyObj: { ...this.state.companyObj, [field]: color.hex } });
  };

  handleChangeSector = async (e, { name, checked }) => {
    const { companyObj } = this.state;

    if (checked) { // 原本沒有 被打勾了 就push進來
      if (!companyObj.sectorArr.find(item => item === name)) {
        const sectorArr = companyObj.sectorArr;
        sectorArr.push(name);
        this.setState({ companyObj: { ...this.state.companyObj, sectorArr } });
      }
    } else {
      if (companyObj.sectorArr.find(item => item === name)) {
        const sectorArr = companyObj.sectorArr;
        const findIndex = sectorArr.indexOf(name);
        sectorArr.splice(findIndex, 1);
        this.setState({ companyObj: { ...this.state.companyObj, sectorArr } });
      }
    }
  }


  render() {
    const { companyObj } = this.state

    return (
      <>
        <div className="content">
          {/* <Divider /> */}
          {/* <Header as='h1' dividing>{companyObj.name}</Header> */}
          <Tab
            menu={{
              secondary: true, pointing: true,
              inverted: companyObj.inverted
              // color: 'grey'
            }}
            renderActiveOnly={true} panes={[
              {
                menuItem: '基本',
                render: () => <Tab.Pane attached={false}>
                  <Company1
                    {...this.props}
                    companyObj={companyObj}
                    handleChange={this.handleChange}
                  // handleSaveCompany={this.handleSaveCompany}
                  /></Tab.Pane>,
              }, {
                //   menuItem: '樣式',
                //   render: () => <Tab.Pane attached={false}>
                //     <Theme
                //       {...this.props}
                //       companyObj={companyObj}
                //       handleChange={this.handleChange}
                //       handleSaveCompany={this.handleSaveCompany}
                //       handleChangeColorComplete={this.handleChangeColorComplete}
                //     /></Tab.Pane>,
                // }, {
                // menuItem: '帳號',
                // render: () => <Tab.Pane attached={false}><Users {...this.props} companyObj={companyObj} handleChange={this.handleChange} handleColorClick={this.handleColorClick} handleColorClose={this.handleColorClose} /></Tab.Pane>,
                //   menuItem: '版面',
                //   render: () => <Tab.Pane attached={false}>
                //     <Company2 {...this.props}
                //       companyObj={companyObj}
                //       handleSaveCompany={this.handleSaveCompany}
                //       handleChangeSector={this.handleChangeSector}
                //     /></Tab.Pane>,
                // }, {
                menuItem: '訂閱',
                render: () => <Tab.Pane attached={false}><Billing {...this.props} companyObj={companyObj} /></Tab.Pane>,
              },
            ]} />
          <br />
        </div>
      </>);
  }
}

export default System