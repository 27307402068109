import React, { Component, createRef } from 'react'
import { Button, Form, Icon, Image, Container, Segment, Header, Input, Label, Select, Ref, Grid, Sticky, Progress, Confirm, Popup } from 'semantic-ui-react';
import ListTabs from './ListTabs';
import { getLabelName2, getOptionsSelByKey, getOptionTextByValue } from 'views/widgets/FieldsRender';
// import FileUploader from "react-firebase-file-uploader";
import Parse from '../../../../widget/parse'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export default class DormPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false, // props.isEdit,
      doc: {}, // 原本資料   dorm
      dormForm: {},  // 更改處   dorm
      dormObj: {}, // 原本資料
      optDorms: [],
      resDorms: [],
      guidance: [], // all dorms
      docRoom: {},  // 原本資料   room
      form: {},  // 更改處   room
      roomObj: {},
      optRooms: [],
      resRooms: [],
      rooms: [],  // all rooms
      searchText: '',
      // formModal: {
      //   staffStatus: '',
      //   teacherNum: '',
      //   nameTeacher: '',
      //   nameTeacherFull: '',
      //   partTimeTeacher: '',
      //   startSemesterNum: '',
      //   endSemesterNum: '',
      // },
      show: false,
      loading: false,
      showModal: false,
      confirm: false,
      dorms: []
      // creatNewRoom: true,
    };
    this.handleUpdateSetting = this.handleUpdateSetting.bind(this)
    // this.saveArrangement = this.saveArrangement.bind(this);
  }

  async componentDidMount() {
    const { companyObj } = this.props
    const dorms = await Parse.queryData('Dormitory', { companyId: companyObj.objectId });
    const students = await Parse.queryData('Students', { companyId: companyObj.objectId });
    console.log(dorms)
    this.setState({ dorms, students }, () => this.handleFetch())
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { match, history, companyObj } = props
    const { isEdit, doc, dorms } = this.state;
    const { _id } = match.params;
    // if (!dormsL) {
    //   handleLazyLoad('dorms');
    // } else {
    // db.collection('Dormitory')
    // .get().then(snap => {
    //   const data = []
    //   snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }));
    //   this.setState({ dorms: data, dormsL: true });
    // })

    if (!isEdit) {
      let obj = {};
      if (_id) {
        obj = dorms.find(item => item.objectId === _id) || {};
      } else {
        obj = dorms[0];
        obj && history.push(`/admin/DormPage/${dorms[0].objectId}`);
      }
      let optDorms = dorms.map(item => ({
        key: `optDormsopt${item.objectId}`, text: `${item.dormitoryName || "(なし)"}`, value: item.objectId,
      }));
      this.setState({
        dormForm: obj,
        optDorms,
        resDorms: optDorms,
        dormObj: obj,
        dormitoryName: obj.dormitoryName,
        dormitoryAddr: obj.dormitoryAddr,
        dormitoryPostcode: obj.dormitoryPostcode
      });
    }
    // all room
    const data1 = await Parse.queryData('Room', { companyId: companyObj.objectId, dormId: _id });
    const optRooms = data1.map(item => ({ key: `optDormsdata${item.objectId}`, value: item.objectId, text: `${item.roomFloor} ${item.roomName}` }))
    this.setState({ rooms: data1, optRooms, resRooms: optRooms });

    // firebase.firestore()
    //   .collection(`Dormitory/${_id}/Room`)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     const optRooms = data.map(item => ({ key: `optDormsdata${item._id}`, value: item._id, text: `${item.roomFloor} ${item.roomName}` }))
    //     this.setState({ rooms: data, optRooms, resRooms: optRooms });
    //   }, err => {
    //     console.log(`Encountered error: ${err}`);
    //   });
    // }
  }

  handleSubmit = async () => {
    const { firebase, match, semester } = this.props;
    const { dormForm, guidance, doc, creatNewRoom } = this.state;
    const db = firebase.firestore();

    this.setState({ loading: true });
    const newRoom = [...guidance];
    const docRef = db.collection(`Dormitory/${match.params._id}/Room`).doc();
    const obj = {
      ...dormForm,
      _id: docRef.id,
      dormId: docRef.id,
      createdAt: new Date()
    };
    await docRef.set(obj);
    newRoom.push(obj);

    this.setState({ guidance: newRoom, loading: false });
    this.handleClose();
    this.props.history.push(`/admin/DormPage/${match.params._id}/${docRef.id}`);
  }

  handleOpen = () => {
    this.setState({ dormForm: {}, showModal: true, creatNewRoom: true })
  }

  handleEdit = (guidanceObj) => {
    this.setState({ showModal: true, dormForm: { ...guidanceObj } });
  }

  handleClose = () => {
    this.setState({ showModal: false, dormForm: {}, fileObj: {}, creatNewRoom: false });
  }

  handleRemove = async () => {
    const { firebase } = this.props
    const { dormForm, guidance, doc } = this.state;
    await firebase.firestore().collection(`Dormitory`).doc(doc._id).delete();
    const newGuidance = [...guidance];
    let index = newGuidance.findIndex((item => item._id === doc._id));
    newGuidance.splice(index, 1);
    this.setState({ guidance: newGuidance });

    this.handleClose();
    this.hideConfirm();
  }

  showConfirm = (guidanceObj) => this.setState({ confirm: true, form: { ...guidanceObj } });
  hideConfirm = () => this.setState({ confirm: false, form: {} });

  setRelativeObj = (setting) => {
    const { form, doc } = this.state;
    this.setState({ isEdit: false },
      () => {
        this.setState({ form: { ...form, ...setting } },
          () => { this.setState({ isEdit: true }) })
      });
  }
  saveUpdateSetting = () => {
    const { firebase, match, updateStudents, formSet, optSet } = this.props;
    const { form, isEdit } = this.state;

    form.dormId = match.params._id;
    form.roomId = match.params.roomid;

    for (const key in form) {
      const element = formSet.find(item => item.key === key);
      if (element && element.type === 'select') {
        form[`${key}Text`] = getOptionTextByValue(optSet, element.value, form[key]);
        // console.log("student method 508 ", element.value, form[key], getOptionTextByValue(optSet, element.value, form[key]));
      }
    }
    firebase.firestore().collection(`Dormitory/${form.dormId}/Room`).doc(form.roomId)
      .update(form).then(docRef => {
        this.setState({ isEdit: false, docRoom: form });
        // updateStudents(form);
        const { rooms } = this.state;

        const newArr = [...rooms];
        let index = newArr.findIndex((item => item._id === form._id));
        newArr[index] = form;
        this.setState({ rooms: newArr });
      });
  }
  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }
  handleUpdateSetting = (event, data) => {
    const { students } = this.props
    const { form, doc } = this.state;
    const { name, value } = data;
    if (this.isEmpty(form)) {
      this.setState({ form: { ...doc, [name]: value } });
    } else if (name.substring(0, name.length - 1) === 'clientType') {
      let dataNum = Number(name.substring(10))
      this.setState({
        form: {
          ...form,
          [name]: value,
          [`studentNum${dataNum}`]: '',
          [`studentName${dataNum}`]: ''
        }
      }, () => console.log(form))
    } else if (name.substring(0, name.length - 1) === 'studentData') {
      let dataNum = Number(name.substring(11))
      let obj = {}
      obj = students.find(item => item._id === value)
      console.log(obj)
      this.setState({
        form: {
          ...form,
          [`studentNum${dataNum}`]: obj && obj.studentId ? obj.studentId : '',
          [`studentName${dataNum}`]: obj && obj.jpnName ? obj.jpnName : ''
        }
      }, () => console.log(form))
    } else {
      this.setState({ form: { ...form, [name]: value } });
    }
  }
  cancelUpdateSetting = () => {
    const { form, docRoom } = this.state;
    this.setState({ isEdit: false, form: docRoom });
  }

  handleChange = (event, data) => {
    const { name, value } = data
    this.setState({
      [name]: value,
      dormForm: {
        ...this.state.dormForm,
        [name]: value
      }
    })
  };

  // select dorm
  handleChangeDorm = (event, data) => {
    const { name, value } = data
    const { history } = this.props
    const { doc, dorms } = this.state
    history.push(`/admin/DormPage/${value}`);
    const obj = dorms.find(item => item.objectId === value) || {};
    this.setState({ doc: obj, dormForm: obj, searchText: '', dormitoryName: obj.dormitoryName, dormitoryPostcode: obj.dormitoryPostcode, dormitoryAddr: obj.dormitoryAddr });
  }

  // click room
  handleClick = (event, d) => {
    let fleldVal = event.target.value;
    const { history } = this.props
    const { rooms, doc, dormObj } = this.state
    history.push(`/admin/DormPage/${dormObj.objectId}/${fleldVal}`);

    const obj = rooms.find(item => item.objectId === fleldVal) || {};
    this.setState({ docRoom: obj, form: obj, roomObj: obj, searchText: '' });
  }

  contextRef = createRef()

  handleSearch = (e, { value }) => {
    const { rooms } = this.state;
    const optRooms = rooms.map(item => ({ key: `optRooms${item.objectId}`, value: item.objectId, text: `${item.roomFloor} ${item.roomName}` }))
    if (value) {
      // 搜尋值中間可以插入任意值 例如搜尋'程雪原' 可以找到 '程 雪原'
      const looseSearchValue = RegExp(value.split('').reduce((pattern, item) => pattern + item + '.*', '.*'));
      const resRooms = optRooms.filter(item => item.text.match(looseSearchValue));
      this.setState({ resRooms, searchText: value });
      // console.log(res)
    } else {
      this.setState({ resRooms: optRooms, searchText: value });
    }
  }

  // handle upload photo
  // handleUploadStart = () => this.setState({ isUploading: true, progress: 0, disabled: true });
  // handleProgress = progress => this.setState({ progress });
  // handleUploadError = error => {
  //   this.setState({ isUploading: false, error: true });
  //   console.error(error);
  // };

  // handleUploadSuccess = async filename => {
  //   const { firebase, match } = this.props;
  //   const { form, downloadURLs, filenames } = this.state

  //   // const downloadURL = this.props.firebase
  //   const downloadURL = await this.props.firebase
  //     .storage()
  //     .ref("images")
  //     .child(filename)
  //     .getDownloadURL();

  //   this.setState(oldState => ({
  //     filenames: [...oldState.filenames, filename],
  //     downloadURLs: [...oldState.downloadURLs, downloadURL],
  //     uploadProgress: 100,
  //     isUploading: false,
  //     form: { ...form, photo: downloadURL, photos: filename },
  //     disabled: false
  //   }))

  // };

  render() {
    const { optSet, formSet, match, history, semesterYear, semester, systemObj, users, companyObj } = this.props;
    const { doc, form, isEdit, selTypeId, resDorms, optDorms, searchText, show, loading, dormitoryPostcode, dormitoryName, dormitoryAddr,
      dormObj, formModal, rooms, resRooms, optRooms, showModal, dormForm, creatNewRoom, roomObj, students } = this.state;

    let roomTypeId = optSet.find((item) => (item.type === 'folder' && item.key === 'roomType'))._id
    let roomTypeOpt = optSet.filter(item => item.parentFolderId === roomTypeId)
      .sort((a, b) => a.orderId - b.orderId)
      .map(item => ({ key: item._id, text: item.label, value: item.value }));
    return (<div style={{ padding: '10px' }} className="content">
      <Ref innerRef={this.contextRef}>
        <Grid>
          <Grid.Column width={2}>
            <Sticky context={this.contextRef}>
              <Header as='h3' style={{ margin: 0 }}>5-1 部屋基本情報</Header>
              {/* <div style={{ ...style.flexCenter }}> */}
              <Form.Select
                fluid
                options={optDorms}
                onChange={this.handleChangeDorm}
                disabled={isEdit}
                value={match.params._id}
                name='dormType' />

              <Button content='部屋追加' color='blue' fluid style={{ margin: '7px 0' }} onClick={() => this.handleOpen()} />
              {/* </div> */}
              {/* <Popup
                style={{ zIndex: 999 }}
                trigger={<Button content='部屋操作' color='blue' fluid style={{ margin: '7px 0' }} />}
                position='right center'
                content={<>
                  <Button inverted={companyObj.inverted} color='red' size='small' disabled={isEdit} onClick={() => this.showConfirm(doc)} icon='delete' />
                  <Button inverted={companyObj.inverted} color='green' size='small' disabled={isEdit} onClick={() => this.handleEdit(doc)} icon='edit' />
                  <Button inverted={companyObj.inverted} color='blue' size='small' disabled={isEdit} onClick={() => this.handleOpen()} icon='add' style={{ margin: 0 }} />
                </>}
                on='click'
                hideOnScroll
              /> */}
              {/* <div style={{ ...style.flexCenter, margin: '7px 0', background: 'rgba(255,255,255,1)', borderRadius: '3px', padding: '3px 5px' }}>
                <Header as='h4' style={{ margin: 0 }} content='部屋操作' />
                <div style={{ ...style.flexCenter }}>
                  <Button inverted={companyObj.inverted} color='red' size='small' disabled={isEdit} onClick={() => this.showConfirm(doc)} icon='delete' />
                  <Button inverted={companyObj.inverted} color='green' size='small' disabled={isEdit} onClick={() => this.handleEdit(doc)} icon='edit' />
                  <Button inverted={companyObj.inverted} color='blue' size='small' disabled={isEdit} onClick={() => this.handleOpen()} icon='add' style={{ margin: 0 }} />
                </div>
              </div> */}

              <Form.Input
                style={{ marginTop: '4px', marginBottom: '4px' }}
                fluid
                placeholder='Search...'
                disabled={isEdit}
                onChange={this.handleSearch}
                value={searchText}
              />

              <select multiple className="form-control ui form fluid search dropdown"
                style={{ height: '600px', width: '100%', fontSize: '11px' }} name="userSel"
                onChange={this.handleClick}
                // value={[doc._id]}
                disabled={isEdit}
              >
                {resRooms && resRooms.map((item, index) => <option key={`resRoom${item.key}${index}`} className="item" value={item.value}>{item.text}</option>)}
              </select>
              <p style={{ textAlign: 'right' }}>{rooms.length}間</p>
            </Sticky>
          </Grid.Column>
          <Grid.Column width={14}>
            <Segment
              clearing
              vertical
            >
              {/* <Button floated='left' as='label' basic inverted={companyObj.inverted}
                content={<>
                  {isEdit ? <FileUploader
                    hidden
                    accept="image/*"
                    name="avatar"
                    randomizeFilename
                    storageRef={this.props.firebase.storage().ref("images")}
                    onUploadStart={this.handleUploadStart}
                    onUploadError={this.handleUploadError}
                    onUploadSuccess={this.handleUploadSuccess}
                    onProgress={this.handleProgress}
                  /> : null}
                  <div style={{ position: 'relative' }}>
                    <Image src={form && form.photo || 'https://react.semantic-ui.com/images/wireframe/square-image.png'} size='tiny'
                      style={{ marginBottom: '0px' }} />
                    {isEdit ? <Icon name='edit' style={{
                      color: '#000',
                      position: 'absolute',
                      bottom: 5,
                      right: 0,
                      fontSize: 18,
                      background: 'rgba(255,255,255,0.7)'
                    }} /> : null}
                  </div>
                </>}
              /> */}
              <Header floated='left' as='h2'>
                <Header.Content>
                  {dormitoryName}
                  <Header.Subheader>
                    <div style={{ margin: '5px 0' }}>{dormitoryPostcode ? <><span className="text-muted">郵便番号:</span>&nbsp;{dormitoryPostcode}&nbsp;</> : ''}</div>
                    <div style={{ margin: '5px 0' }}>{dormitoryAddr ? <><span className="text-muted">住所:</span>&nbsp;{dormitoryAddr}&nbsp;</> : ''}</div>
                  </Header.Subheader>
                  <div style={{ marginTop: '10px' }}>
                    {isEdit ? <Button positive size='small' onClick={this.saveUpdateSetting} icon='save' content='保存' /> : <Button primary size='small' onClick={() => this.setState({ isEdit: true })} icon='edit' content="編集" />}
                    {isEdit ? <Button basic size='small' onClick={this.cancelUpdateSetting} icon='remove' content='キャンセル' /> : null}
                  </div>
                </Header.Content>
              </Header>
            </Segment>
            <br />
            <ListTabs
              {...this.props}
              dormObj={dormObj}
              roomObj={roomObj}
              doc={form}
              students={students}
              handleUpdateSetting={this.handleUpdateSetting}
              setRelativeObj={this.setRelativeObj}
              isEdit={isEdit}
            />
          </Grid.Column>
        </Grid>
      </Ref>
      {/* <Modal open={showModal} onClose={this.handleClose}> */}
      <Modal
        isOpen={showModal}
        toggle={this.handleClose}
        size="lg"
        // className="mh-120 h-90 mw-120 w-90"
        style={{ paddingTop: '0px' }}
        scrollable={true}
      >
        <ModalHeader className="justify-content-center">
          <h2 style={{ margin: 0 }}> {creatNewRoom === false ? '修正' : '追加'}記録</h2>
          <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
        </ModalHeader>
        <ModalBody style={{ padding: "20px 30px" }}>
          <Form>
            <Grid>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <Form.Input
                    label='房名'
                    name='roomName'
                    value={dormForm.roomName || ''}
                    onChange={this.handleChange}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    label='位置'
                    name='roomFloor'
                    value={dormForm.roomFloor || ''}
                    onChange={this.handleChange}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Select
                    label='房型'
                    options={roomTypeOpt}
                    name='roomType'
                    onChange={this.handleChange}
                    value={dormForm.roomType || ''} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </ModalBody>
        <ModalFooter style={{ padding: "20px 30px" }}>
          <Button onClick={this.handleSubmit} inverted={companyObj.inverted} color='green' loading={loading} icon='checkmark' content={creatNewRoom === false ? '保存' : '追加'} />
          <Button onClick={this.handleClose} inverted={companyObj.inverted} color='red' icon='delete' content='キャンセル' />
        </ModalFooter>
      </Modal>
      <Confirm
        header='削除'
        content='削除してもいいですか?'
        cancelButton='いいえ'
        confirmButton='はい'
        open={this.state.confirm}
        onCancel={this.hideConfirm}
        onConfirm={this.handleRemove}
        size='mini'
        centered={false} />
      {/* 
      {this.state.isUploading && <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 10000,
        width: '100vw',
        height: '100vh',
        background: 'rgba(0,0,0,0.87)',
        overflow: 'hodden'
      }}>
        <Progress percent={this.state.progress} indicating error={this.state.error} progress />
      </div>} */}
    </div >);
  }
}
