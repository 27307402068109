import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select, Icon } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'

import { Row, Col } from "reactstrap";
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { mgyearOpt, monthOpt, exportPdf } from 'views/web/CustomMaterialTable';

const columns = (employeeObj) => ([
  // { width: 40, title: '代號', field: 'code', lookup: code },
  { width: 80, title: '客戶姓名', field: 'clientName' },
  { width: 80, title: '當物類別', field: 'pawnTypeText' },
  { width: 80, title: '(總)本金結餘', field: 'principalRest' },
  { width: 80, title: '(總)應收利息', field: 'interestPlan' },
  { width: 80, title: '(總)繳息金額', field: 'interestPaid' },
  { width: 10, title: '', field: 'aaa', },
  { width: 80, title: '計劃繳息日期', field: 'planDate', editable: 'never' },
  { width: 80, title: '合件', field: 'joint' },
  { width: 80, title: '比例', field: 'proportion', editable: 'never' },
  { width: 80, title: '業績', field: 'performance' },
  { width: 80, title: '應收利息', field: 'interestPlanSelf' },
  { width: 80, title: '實收利息', field: 'interestPaidSelf' },
  { width: 80, title: '佣金', field: 'commission' },
  { width: 180, title: '備註', field: 'ps' }
]);

const d = new Date()
class Orderinner extends Component {
  state = {
    activeItem: 'bio',
    data: [],
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
  }
  componentDidMount = () => {
    this.handleFetch()
  }
  handleFetch = async () => {
    const { companyObj } = this.props
    const { monthSel, mgyearSel } = this.state
    const companyId = companyObj.objectId
    console.log(monthSel);
    console.log(mgyearSel);
    const data1 = await Parse.queryData('pawning2', { companyId, month: monthSel, year: mgyearSel });

    this.setState({ data: data1 })
  }
  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value }, () => this.hanldeFilter())
  }

  hanldeFilter = async () => {
    const { companyObj } = this.props
    const { monthSel, mgyearSel } = this.state
    const companyId = companyObj.objectId
    const data1 = await Parse.queryData('pawning2', { companyId, monthSel, mgyearSel });

    this.setState({ data: data1 })
  }
  render() {
    const { companyObj } = this.props
    const { data, monthSel, mgyearSel } = this.state

    return (<div className="content">
      <Header as='h2' inverted={companyObj.inverted}>開桌</Header>
      <Select name="mgyearSel" style={{ position: 'relative', zIndex: 99, marginRight: '8px' }} options={mgyearOpt} value={mgyearSel} onChange={this.handleChange} />
      <Select name="monthSel" style={{ position: 'relative', zIndex: 99 }} options={monthOpt} value={monthSel} onChange={this.handleChange} />
      <br />
      <br />
      <MaterialTable
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, '查詢結果'),
          pageSize: 10,
          search: false
        }}
        data={data}
        title="查詢結果"
      // actions={[
      //   {
      //     icon: 'view_headline',
      //     tooltip: '查看',
      //     onClick: (event, rowData) => this.handleData(rowData)
      //   }
      // ]}
      // editable={{
      //   onRowAdd: newData =>
      //     new Promise((resolve, reject) => {
      //       const data = [...dataArr];
      //       data.push(newData);
      //       // handleState('people0', data);
      //       this.setState({ dataArr: data, newData }, () => resolve());
      //     }).then(() => this.handleAdd(0, 0)),
      //   onRowUpdate: (newData, oldData) =>
      //     new Promise((resolve, reject) => {
      //       const data = [...dataArr];
      //       const index = data.indexOf(oldData);
      //       data[index] = newData;
      //       // handleState('people0', data);
      //       this.setState({ dataArr: data, newData, oldData }, () => resolve());
      //     }).then(() => this.handleUpdate(0)),
      //   onRowDelete: oldData =>
      //     new Promise((resolve, reject) => {
      //       let data = [...dataArr];
      //       const index = data.indexOf(oldData);
      //       data.splice(index, 1);
      //       // handleState('people0', data);
      //       this.setState({ dataArr: data, oldData }, () => resolve());
      //     }).then(() => this.handleDelete(0)),
      // }}
      />

    </div >);
  }
}

export default Orderinner;