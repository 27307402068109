import React, { Component } from 'react';
import { Grid, Menu, Button } from 'semantic-ui-react';

import PrintPdf from './PrintPdf';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { Row, Col } from 'reactstrap';
import {
  Card, CardBody
} from "reactstrap";

export default class HostPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      activeId: '',
      codeForm: {},
      selectedObj: props.studentObj || [],
      students: []
    };
  }
  async componentDidMount() {
    // console.log('componentDidMount');
    const { companyObj } = this.props
    const data = await Parse.queryData('Students', { companyId: companyObj.objectId });
    this.setState({ students: data });

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.codeid !== this.props.codeid) {
      this.setState({ activeId: '', selectedObj: [] });
    }
    if (nextProps.studentObj !== this.props.studentObj) {
      this.setState({ activeId: '', name: '', selectedObj: [nextProps.studentObj] });
    }
  }

  handleItemClick = (e, { codeid, name }) => {
    const { formSet } = this.props;
    const codeForm = formSet.find(item => item._id === codeid);
    this.setState({ activeId: codeid, codeForm, name });
  }

  loadPDF = () => {
    const { formSet, studentSelected = [], codeid } = this.props;
    const { students } = this.state
    const codeForm = formSet.find(item => item._id === codeid);
    const obj = []
    studentSelected.map(value =>
      obj.push(students.find(item => item.objectId === value))
    )
    this.setState({ activeId: codeid, codeForm, selectedObj: obj });
  }

  render() {
    const { formSet, dirkey, codeid, title, codekey } = this.props;
    const { activeId, codeForm, name, selectedObj } = this.state;
    const level0Obj = formSet.find(item => item.key === dirkey);
    const level1Arr = level0Obj && level0Obj._id ? formSet.filter(item => item.parentFolderId === level0Obj._id).sort((a, b) => a.orderId - b.orderId) : [];
    return (
      <Row>
        {/* {hideSelectList === "1" || !selectList.length ? null :
              <Col width={4}>
                <Form.Group>
                  <Form.Field as="select" multiple style={{ height: 'calc(100vh - 210px)', overflow: 'auto', maxWidth: '300px' }} onChange={this.handleChange} value={value}>
                    {this.getSelectListFormat()}
                  </Form.Field>
                </Form.Group>
                <Button size="small" onClick={() => { this.setState({ value: [] }) }}icon='delete' content='クリア' /> <br />
                <span style={{ fontSize: '12px', color: '#999' }}>(複数を選擇したい時Ctrlを押す)</span>
              </Col>} */}
        {codeid ?
          <h3>
            <Col>
              {
                codekey ?
                  <>
                    <Button variant="primary" onClick={this.loadPDF}>ログイン</Button>{' '}
                    {codekey === 'table09' || codekey === 'table23' ? <ExportTableToExcelButton /> : null}
                  </>
                  : '印刷する書類を選擇して下さい'} {title}
            </Col>
          </h3>
          :
          <Col sm='3'>
            <Card>
              <CardBody>
                <Menu secondary vertical> {level1Arr.map((item, index) => (
                  <Menu.Item
                    key={item._id}
                    codeid={item._id}
                    name={`${index + 1}. ${item.label}`}
                    active={activeId === item._id}
                    onClick={this.handleItemClick}
                  />))}
                </Menu>
              </CardBody>
            </Card>
          </Col>
        }
        <Col sm='9'>
          <h3>{name}</h3>
          {activeId ? <PrintPdf {...this.props} codeForm={codeForm} dataArr={selectedObj} /> : ''}
        </Col>
      </Row>);
  }
}