import React, { Component } from 'react'
import { Container, Icon, Table, Grid, Button, Modal, Header, Form, Pagination, Dropdown, Confirm } from 'semantic-ui-react'
import _ from 'lodash'
import MyModalTable from '../../widgets/MyModalTable';
import Parse from 'widget/parse'

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipse: {
    // boxSizong: 'border-box',
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis'
  }
}
export class HealthyTeacher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      author: '',
      teacher: [],
      workStatus: '1',

    }
  }

  async componentDidMount() {
    const { companyObj, teachers } = this.props
    const { currentSubscription } = this.state
    // const { _id } = match.params;

    // if (teachers._id) {
    // if (this.state.unsub) {
    //   this.state.unsub();
    // }
    // const unsub = firebase.firestore()
    //   .collection(`Teachers`)
    //   // .orderBy('relationship')
    //   // .where('eventDate', '==', eventDate).where('classname', '==', className)
    //   .onSnapshot(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     console.log(data);
    //     this.setState({ teacher: data });

    //   }, err => {
    //     console.log(`Encountered error: ${err}`);
    //   });
    // this.setState({ unsub });
    // }
    const query = new Parse.Query('Teachers');
    query.limit(10000);
    const snap = await query.find();
    const teacher = snap.map(data => data.toJSON());
    this.setState({ teacher })
    if (currentSubscription) {
      // console.log('currentSubscription.unsubscribe')
      await currentSubscription.unsubscribe();
      this.setState({ currentSubscription: null })
    }
    let subscription = await query.subscribe().catch(error => console.error(error));
    subscription.on('create', (data) => {
      const teacher = [...this.state.teacher, data.toJSON()];
      this.setState({ teacher })
    });
    subscription.on('update', (data) => {
      const teacher = [...this.state.teacher, data.toJSON()];
      this.setState({ teacher })
    });
    subscription.on('delete', (data) => {
      const teacher = [...this.state.teacher, data.toJSON()];
      this.setState({ teacher })
    });
    this.setState({ currentSubscription: subscription })
    // const unsub = await Parse.queryData('Teachers', { companyId: companyObj.objectId })
    // this.setState({ unsub })
  }

  async componentWillReceiveProps(nextProps) {
    // this.setState({ doc: nextProps.doc });
    const { firebase, teachers } = nextProps;
    const { currentSubscription } = this.state
    // const { _id } = match.params;

    // if (teachers._id) {
    // if (this.state.unsub) {
    //   this.state.unsub();
    //   console.log('unsub worked!')
    // }
    const query = new Parse.Query('Teachers');
    query.limit(10000);
    const snap = await query.find();
    const teacher = snap.map(data => data.toJSON());
    this.setState({ teacher })
    if (currentSubscription) {
      // console.log('currentSubscription.unsubscribe')
      await currentSubscription.unsubscribe();
      this.setState({ currentSubscription: null })
    }
    let subscription = await query.subscribe().catch(error => console.error(error));
    subscription.on('create', (data) => {
      const teacher = [...this.state.teacher, data.toJSON()];
      this.setState({ teacher })
    });
    subscription.on('update', (data) => {
      const teacher = [...this.state.teacher, data.toJSON()];
      this.setState({ teacher })
    });
    subscription.on('delete', (data) => {
      const teacher = [...this.state.teacher, data.toJSON()];
      this.setState({ teacher })
    });
    this.setState({ currentSubscription: subscription })
    // const unsub = firebase.firestore()
    //   .collection(`Teachers`)
    //   // .orderBy('relationship')
    //   // .where('eventDate', '==', eventDate).where('classname', '==', className)
    //   .onSnapshot(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     console.log(data);
    //     this.setState({ teacher: data }, () => console.log(teacher));
    //   }, err => {
    //     console.log(`Encountered error: ${err}`);
    //   });
    // this.setState({ unsub, /*form: { inquiryDate: this.getToday() }*/ });
    // }
  }

  changeWorkStatus = (e, data) => {
    this.setState({ workStatus: data.value })
  }
  // fields = [
  //   {
  //     id: 'teacherNum',
  //     title: '教員番号'
  //   }, {
  //     id: 'nameTeacherFull',
  //     title: '氏名'
  //   },
  //   // {
  //   //   id: 'voice',
  //   //   title: 'フリガナ'
  //   // },
  //   {
  //     id: 'genderTeacherText',
  //     title: ' 性別'
  //   },
  //   // {
  //   //   id: 'birthdayTeacher',
  //   //   title: ' 生年月日'
  //   // },
  //   {
  //     id: 'bIrthD',
  //     title: '診断の有無'
  //   },
  //   {
  //     id: 'bIrthD',
  //     title: '診断時期'
  //   },
  //   {
  //     id: 'ps',
  //     title: '備考'
  //   },
  // ]

  render() {
    const { firebase } = this.props;
    const { teacher, workStatus } = this.state;

    const isWorkArr = [
      { key: 'isWorkOption1', text: '在勤', value: '1' },
      { key: 'isWorkOption2', text: '離職', value: '2' },
    ]
    const isWorkArrMain = [
      { key: 'isWorkOption0', text: '全部', value: '0' },
      ...isWorkArr
    ]
    // console.log(this.fields);
    return (
      <div>
        <Header as='h2' style={{ margin: 0 }}>健康診断名簿</Header>
        <div style={{ ...style.flexCenter, marginTop: 15 }}>
          <div>
            <label>状態</label>
            <Form.Select options={isWorkArrMain} defaultValue='1' style={{ marginTop: 10 }} onChange={this.changeWorkStatus} />
          </div>
        </div>
        <Table celled striped size='small' selectable>
          <Table.Header>
            <Table.Row textAlign='center'>
              <Table.HeaderCell>教員番号</Table.HeaderCell>
              <Table.HeaderCell>氏名</Table.HeaderCell>
              <Table.HeaderCell>性別</Table.HeaderCell>
              <Table.HeaderCell>診断の有無</Table.HeaderCell>
              <Table.HeaderCell>診断時期</Table.HeaderCell>
              <Table.HeaderCell>備考</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {teacher.sort((a, b) => a.teacherNum - b.teacherNum).map((item, index) => (workStatus === '0' ? <Table.Row key={item._id}>
              {/* {teacher.sort((a, b) => a.teacherNum - b.teacherNum).map((item, index) => (<Table.Row key={item._id}> */}
              <Table.Cell>{item.teacherNum}</Table.Cell>
              <Table.Cell>{item.nameTeacherFull || item.nameTeacher}</Table.Cell>
              <Table.Cell>{item.genderTeacherText}</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
              : item.staffStatus === workStatus ? <Table.Row key={item._id}>
                <Table.Cell>{item.teacherNum}</Table.Cell>
                <Table.Cell>{item.nameTeacherFull || item.nameTeacher}</Table.Cell>
                <Table.Cell>{item.genderTeacherText}</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row> : null))}
          </Table.Body>
        </Table >
        {/* <MyModalTable
          pageTitleText='健康診断名簿'
          firebase={firebase}
          fields={this.fields}
          collection='Teachers'
          {...this.props}
        // orderBy='number'
        /> */}
      </div >
    )
  }
}

export default HealthyTeacher