import React, { Component } from 'react'
// import { Container, Icon, Table, Button, Modal, Header, Form, Pagination, Dropdown, Confirm } from 'semantic-ui-react'
import _ from 'lodash'
import MyModalTable from '../../widgets/MyModalTable';
import { getFormByKey } from 'views/widgets/FieldsRender';
import Parse from 'widget/parse'

export class Reception extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      data: [],
      receptObj: {},
    }
  }
  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { firebase, optSet, formSet, params, companyObj } = props;
    const { dbKey } = params;
    let receptObj = formSet.find(item => item.key === dbKey) || {};
    console.log(params, receptObj)
    const data = await Parse.queryData(receptObj.key, { companyId: companyObj.objectId }, { orderBy: 'createdAt_descend' })
    this.setState({ data })
    // firebase.firestore().collection(`${receptObj.key}`).get().then(snap => {
    //   snap.forEach(doc => data.push(doc.data()));
    //   data.sort((a, b) => b.createdAt - a.createdAt);
    //   this.setState({ data });
    // }, err => {
    //   console.log(`Encountered error: ${err}`);
    // });

    let columns = getFormByKey(formSet, dbKey).sort((a, b) => a.orderId - b.orderId).map(item => ({ name: item.key, title: item.label, ...item }));
    this.setState({ receptObj, columns: columns.length ? columns : [], data: data.length ? data : [] })
  }
  render() {
    const { data, columns, receptObj } = this.state;

    if (!receptObj) {
      return 'key error';
    }

    console.log(columns)
    console.log(data)

    return (<MyModalTable
      {...this.props}
      // pageTitleText={receptObj}
      // fields={this.fields}
      columns={columns}
      rows={data}
      reception="1"
      receptObj={receptObj}
    />)
  }
}
export default Reception