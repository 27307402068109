import React, { Component } from 'react'
import { Container, Header, Button, Dimmer, Form, Grid, Image, Input, Checkbox, Divider, Loader, Segment, Icon } from 'semantic-ui-react';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ThemeColor from './ThemeColor';
import ThemeImg from './ThemeImg';

export default class Theme extends Component {
  state = {
    companyObj: this.props.companyObj,
    // data1: [],
    // dataArr: [],
    // sectorArr: [],
    // sectorSel: {},

    // filenames: [],
    // downloadURLs: [],
    // docs: [],
    // fileArr: [],
    // fileArrCheck: [],
    // show: false,
    // loading: true,
  }

  handleChange = (e, { name, value }) => {
    const { companyObj } = this.state;
    this.setState({ companyObj: { ...companyObj, [name]: value } })
  }

  handleSaveCompany = async () => {
    const { companyObj } = this.state;
    await Parse.saveData('company', companyObj);
  }

  // react-color
  handleChangeColorComplete = (color, event, field) => {
    this.setState({ companyObj: { ...this.state.companyObj, [field]: color.hex } });
  };

  render() {
    // const {companyObj, handleSaveCompany } = this.props
    const { companyObj } = this.state
    return (<div className="content">
      <Header as='h2' inverted={companyObj.inverted}>主題</Header>
      <ThemeImg {...this.props} />
      <br />
      <ThemeColor {...this.props}
        companyObj={companyObj}
        handleChange={this.handleChange}
        handleSaveCompany={this.handleSaveCompany}
        handleChangeColorComplete={this.handleChangeColorComplete}
      />
      <br />
      {/* <br /> */}
      <br />
    </div>
    )
  }
}
