import React, { Component } from 'react'
import { Container, Header, Modal, Button, Table, Form } from 'semantic-ui-react';
// import { SyncLoader } from 'react-spinners';
import { TextArea, Grid, Loader } from 'semantic-ui-react'

export default class Setting extends Component {
  state = {
    formSet: [],
    formSetL: false,
    optSet: [],
    optSetL: false,
    calendar: [],
    calendarL: false,
  }

  componentDidMount() {
    // const { firebase } = this.props;
    // const db = firebase.firestore();

    // db.collection("FormSettings")
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }));
    //     this.setState({ formSet: data, formSetL: true });
    //   });

    // db.collection("OptionSettings")
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }));
    //     this.setState({ optSet: data, optSetL: true });
    //   });

    // db.collection("Calendar")
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }));
    //     this.setState({ calendar: data, calendarL: true });
    //   });
  }

  render() {
    const { formSet, optSet, formSetL, optSetL, calendar, calendarL } = this.state;

    return (
      <div className='content'>
        <Container>
          <Header as='h2' style={{ margin: '2rem auto' }}>FormSet.js</Header>
          {formSetL ?
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <TextArea
                    style={{ width: '50%', margin: '2%' }}
                    rows={10}
                    value={`const str = ${JSON.stringify(formSet, null, 2)}; export default str;`}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid> : <Loader active inline='centered' />}
          <Header as='h2' style={{ margin: '2rem auto' }}>OptSet.js</Header>
          {optSetL ?
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <TextArea
                    style={{ width: '50%', margin: '2%' }}
                    rows={10}
                    value={`const str = ${JSON.stringify(optSet, null, 2)}; export default str;`}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid> : <Loader active inline='centered' />}
          <Header as='h2' style={{ margin: '2rem auto' }}>Calendar.js</Header>
          {calendarL ?
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <TextArea
                    style={{ width: '50%', margin: '2%' }}
                    rows={10}
                    value={`const str = ${JSON.stringify(calendar, null, 2)}; export default str;`}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid> : <Loader active inline='centered' />}
        </Container>
      </div>
    )
  }
}