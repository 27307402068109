import React, { Component, createRef } from 'react'
import { Container, Header, Form, Icon, Image, Select, Divider, Segment, Button, Sticky, Ref } from 'semantic-ui-react'
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, FormGroup, Input, Row, Col, CustomInput,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { IconButton } from '@material-ui/core';
import Timer from 'react-timer-wrapper';
import Timecode from 'react-timecode';
import { DimmerImg } from '../../layouts/Dimmer';
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import PageHeader from 'components/Headers/PageHeader';
import { ListGroup, ListGroupItem } from 'reactstrap';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ToeicA from './ToeicA'
import ToeicB from './ToeicB'
import ToeicC from './ToeicC'

const partArr = ['1', '2', '3', '4', '5', '6', '7']
// let groupCount = 'NONE'
const content = {
  maxWidth: '1170px',
  margin: '80px auto 110px',
  flexDirection: 'column',
}

export class Toeic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data1: [],
      versionsArr: [],
      version: "-1",
      loading: false,
      answer: {},
      start: false,
      show: ''
      // groupCount: 'NONE',
    };
  }

  async componentDidMount() {
    this.handleFetch()
  }

  handleFetch = async () => {
    const { newData } = this.state;
    // this.setState({ loading: true })
    const data = await Parse.queryData('testBank');
    const versionsArr = []
    data.forEach(item => {
      versionsArr.push({ key: item.objectId, value: item.version, text: item.version })
    })
    // console.log(data)
    this.setState({ data, versionsArr });
  }

  handleChange = async (e, { name, value }) => {
    this.setState({ loading: true })
    const data1 = await Parse.queryData('testExBank', { verId: value })
    console.log(data1)
    this.setState({ data1, [name]: value, loading: false, answer: {}, show: true })
  }

  handleAnswer = (e) => {
    this.setState({ answer: { ...this.state.answer, [e.target.name]: e.target.id.split('_')[0] } })
  }

  handleJump = (e, data) => {
    window.location.hash = data.id
  }

  handleTest = () => {
    console.log('@@')
  }

  onTimerUpdate = ({ time, duration }) => {
    this.setState({ time, duration });
  }

  handleStart = () => { this.setState({ start: true, show: false }); }
  handleClose = () => { this.setState({ show: '' }) }

  handleClick = (type) => {
    if (type === '1') {
      this.setState({ show: '1' })
    } else if (type === '2') {
      this.setState({ show: '2' })
    } else if (type === '3') {
      this.setState({ show: '3' })
    } else if (type === '4') {
      this.setState({ show: '4' })
    }
  }
  handleBack = () => {
    this.setState({ show: '' })
  }

  contextRef = createRef()

  render() {
    //     const { auth } = this.props;
    //     if (!auth.uid) return <Redirect to='/signin' />
    const { data, data1, versionsArr, version, loading, answer, duration, time, start, show } = this.state
    // let groupCount = ''
    // setTimeout(this.handleTest, 10000)
    // setInterval(this.handleTest, 10000)
    // console.log(answer)

    return (<>

      <Ref innerRef={this.contextRef}>
        <Container style={content}>
          {show !== '' ? <Button primary floated='right' size='big' onClick={this.handleBack}>返回</Button> : null}
          <br />
          {show !== '1' && show !== '2' && show !== '3' && show !== '4' ? <>
            <Button primary size='massive' onClick={() => this.handleClick('1')}>模擬測驗</Button>
            <Button primary style={{ marginLeft: '10%' }} size='massive' onClick={() => this.handleClick('2')}>隨機測驗</Button>
            <Button primary style={{ marginLeft: '10%' }} size='massive' onClick={() => this.handleClick('3')}>自我鍛鍊</Button>
            <Button primary style={{ marginLeft: '10%' }} size='massive' onClick={() => this.handleClick('4')}>班級測驗</Button>
          </> : null}
          {show === '1' ? <ToeicA {...this.props} /> : null}
          {show === '2' ? <ToeicB {...this.props} /> : null}
          {show === '3' ? <ToeicC {...this.props} /> : null}
          <Modal
            isOpen={show === '4'}
            toggle={this.handleClose}
            size="lg"
            // className="mh-80 h-80 mw-80 w-80"
            style={{ paddingTop: '0px' }}
            scrollable={true}
          >
            <ModalHeader className="justify-content-center">測驗注意</ModalHeader>
            <ModalBody style={{ padding: "20px 30px" }}>
              <Card>
                <CardBody>
                  <CardSubtitle style={{ fontSize: '18px', textAlign: 'center' }}>
                    目前沒有班級測驗
                    </CardSubtitle>
                </CardBody>
              </Card>
            </ModalBody>
            <ModalFooter style={{ justifyContent: 'center', padding: '16px' }}>
              <Button
                primary
                onClick={this.handleClose}
                content="關閉"
              // loading={loading}
              />
            </ModalFooter>
          </Modal>
        </Container>
      </Ref>
    </>)
  }
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     createProject: (project) => dispatch(createProject(project))
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HostIndex)
export default Toeic