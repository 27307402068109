
import React, { Component } from 'react'
import { Form, Grid } from 'semantic-ui-react';
// import ModalPdf from 'widget/pdf/ModalPdf';
import MaterialTable from 'material-table'
// import { closeSync } from 'fs';
import { DimmerImg } from '../../layouts/Dimmer';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ModalPdf from 'widget/pdf/ModalPdf';
import ModalReceivable from './ModalReceivable';
// import RowsDnd from './RowsDnd'
import { dateCol, mgyearOpt, monthOpt, codeOpt } from 'views/web/CustomMaterialTable';
import Pay from './Pay'
// import QRCode from 'qrcode'

const columns = () => ([
  { width: 80, title: '單號', field: 'ordernum' },
  { width: 80, title: '年度', field: 'year' },
  { width: 80, title: '法會名稱', field: 'pray1Text' },
  { width: 80, title: '序號', field: 'serial' },
  { width: 80, title: '建立日期', field: 'applyDate' },
  { width: 80, title: '服務金額', field: 'accReceivable' },
  { width: 80, title: '已收金額', field: 'accReceived' },
  { width: 80, title: '未付金額', field: 'accPending' },
  { width: 80, title: '申請人', field: 'whoapply_text' }
]);

const columns1 = () => ([
  // { title: '預設', field: 'now_use' },
  { title: '收取方式', field: 'receiveTypeText' },
  { title: '已收金額', field: 'receiveNum', type: 'numeric' },
  // { title: '收取日期', field: 'payDate' },
  // { title: '列 數量', field: 'oilpray_x', lookup: objOilPrayX },
  // { title: '欄 數量', field: 'oilpray_y', lookup: objOilPrayY },
  // { title: '專用法會', field: 'only_pray', lookup: pray1 },
  // { title: '價錢', field: 'price' },
  { title: '備註', field: 'ps' },
]);

const payStatusOpt = [
  { key: 'all', text: '全部', value: '-1' },
  { key: 'paid', text: '已繳清', value: '0' },
  { key: 'unpaid', text: '未繳清', value: '1' },
]

class Cashier extends Component {
  state = {
    dataArr: [],
    mgyearId: "109",
    pray1Id: '-1',
    paystatusId: '-1',
    accReceivableTotal: 0,
    accReceivedTotal: 0,
    accPendingTotal: 0,
    loading: false,
    loadingBtn: false,
    pray1Arr: [],
    pray1Opt: [],
    companyObjLive: {},
    data1: [],
    praying1Obj: {},
    searchValue: '',
    people: []
  }
  async componentDidMount() {
    // const { calendar } = this.props
    // this.setState({ dataArr: calendar })
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const companyObjLive = await Parse.fetchDocument('company', companyId, ['yearDef', 'orderalphaDef', 'ordernumDef', 'pray1IdDef']);
    const pray1Arr = await Parse.queryData('pray1', { companyId }, { name: 'name', sort: 'name' });
    const praytypeObj = await Parse.getSelection('praytype', { companyId }, { name: 'value', sort: 'order' });
    const pray1Opt = []
    pray1Arr.forEach(item => {
      pray1Opt.push({ text: item.value, value: item.objectId, key: item.objectId })
    })
    // const pray1Obj = await Parse.getSelection('pray1', { companyId: companyObj.objectId }, { name: 'name', sort: 'name' });
    const selectData = { praytypeObj }

    this.setState({ pray1Opt, companyObjLive, selectData }, () => this.handleFetch())
    // this.handleFetch();
    // console.log(await QRCode.toDataURL('3323'))
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async () => {
    const { companyObj } = this.props;
    const { mgyearId } = this.state
    // const { paystatusId, pray1Id, mgyearId, searchValue } = this.state
    this.setState({ loading: true })
    const db = Parse.Object.extend("praying1");
    const query = new Parse.Query(db);

    query.equalTo("companyId", companyObj.objectId);
    query.equalTo("year", mgyearId);

    // if (pray1Id !== '-1') {
    //   query.equalTo("pray1Id", pray1Id);
    // }
    // if (paystatusId === '0') { // 已繳清
    //   // ref = ref.where('accPending', '==', 0);
    //   query.equalTo("accPending", 0);

    // } else if (paystatusId === '1') { // 未繳清
    //   // ref = ref.where('accPending', '>', 0)
    //   query.notEqualTo("accPending", 0);
    // }

    // const checkNum = (value) => !isNaN(value) ? value : 0;
    // const arrAccNum = (arr, fleld) => arr.reduce((a, b) => Number(a) + Number(b[fleld]), 0);

    query.limit(10000);

    const snap = await query.find();
    const data1 = snap.map(data => data.toJSON());
    // const arr = data1.map(item => ({
    //   ...item,
    //   // accReceivable: typeof item.accReceivable === 'number' ? item.accReceivable : 0,
    //   accReceivable: checkNum(item.accReceivable),
    //   accReceived: checkNum(item.accReceived),
    //   accPending: checkNum(item.accPending),
    // }))

    // const accReceivableTotal = arrAccNum(arr, 'accReceivable');
    // const accReceivedTotal = arrAccNum(arr, 'accReceived');
    // const accPendingTotal = arrAccNum(arr, 'accPending');
    this.setState({ dataArr: data1.sort((a, b) => (a.serial - b.serial)), dataArrOri: data1.sort((a, b) => (a.serial - b.serial)) }, () => this.handleDataArr());

  }

  handleChange = (e, { name, value }) => {
    if (name === 'mgyearId') {
      this.setState({ [name]: value, loading: true }, () => this.handleFetch())
    } else {
      this.setState({ [name]: value, loading: true }, () => this.handleDataArr());
    }
  }

  handleDataArr = () => {
    const { dataArrOri, paystatusId, pray1Id, searchValue } = this.state
    let newDataArr = [...dataArrOri]

    if (pray1Id !== '-1') {
      // query.equalTo("pray1Id", pray1Id);
      newDataArr = newDataArr.filter(item => item.pray1Id === pray1Id)
    }
    if (paystatusId === '0') { // 已繳清
      // ref = ref.where('accPending', '==', 0);
      // query.equalTo("accPending", 0);
      newDataArr = newDataArr.filter(item => item.accPending === 0)
    } else if (paystatusId === '1') { // 未繳清
      // ref = ref.where('accPending', '>', 0)
      // query.notEqualTo("accPending", 0);
      newDataArr = newDataArr.filter(item => item.accPending === 1)
    }
    if (searchValue) {
      const looseSearchValue = RegExp(searchValue.split('').reduce((pattern, item) => pattern + item + '.*', '.*'));
      newDataArr = newDataArr.filter(item => item.ordernum.match(looseSearchValue))
    }
    const checkNum = (value) => !isNaN(value) ? value : 0;
    const arrAccNum = (arr, fleld) => arr.reduce((a, b) => Number(a) + Number(b[fleld]), 0);
    const arr = newDataArr.map(item => ({
      ...item,
      // accReceivable: typeof item.accReceivable === 'number' ? item.accReceivable : 0,
      accReceivable: checkNum(item.accReceivable),
      accReceived: checkNum(item.accReceived),
      accPending: checkNum(item.accPending),
    }))

    const accReceivableTotal = arrAccNum(arr, 'accReceivable');
    const accReceivedTotal = arrAccNum(arr, 'accReceived');
    const accPendingTotal = arrAccNum(arr, 'accPending');
    this.setState({ dataArr: arr, accReceivableTotal, accReceivedTotal, accPendingTotal, loading: false })
  }

  handleData = async (rowData) => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data1 = await Parse.queryData('received', { companyId: companyObj.objectId, praying1Id: rowData.objectId });
    const people = await Parse.queryData("people", { companyId, familyId: rowData.familyId })

    this.setState({ praying1Obj: rowData, data1 })
  }

  handleUpdateAcc = async (newData) => {
    const { companyObj } = this.props;
    const { dataArr } = this.state

    await Parse.saveData('praying1', newData);

    const data = dataArr;
    const index = data.findIndex(item => item.objectId === newData.objectId);
    if (index !== -1) {
      data[index] = newData;
      this.setState({ dataArr: data });
    }
  }
  render() {
    const { companyObj } = this.props
    const { dataArr, people, mgyearId, pray1Id, paystatusId, loading, praying1Obj, accReceivableTotal, accReceivedTotal, accPendingTotal, pray1Obj = {}, data1, pray1Opt, companyObjLive, selectData, searchValue } = this.state;
    const people1 = people.filter(item => item.isLive === "1");
    const people0 = people.filter(item => item.isLive !== "1");
    return (<div className="content">
      <Form style={{ position: 'relative', zIndex: 99, }}>
        <Form.Group inline>
          <Form.Select style={{ zIndex: 99, }} label='年度' options={mgyearOpt} value={mgyearId} name='mgyearId' onChange={this.handleChange} />
          <Form.Select style={{ zIndex: 99, }} label='法會' options={[{ key: 'aa', value: '-1', text: '全部' }].concat(pray1Opt)} value={pray1Id} name='pray1Id' onChange={this.handleChange} />
          <Form.Select style={{ zIndex: 99, }} label='付款狀況' options={payStatusOpt} value={paystatusId} name='paystatusId' onChange={this.handleChange} />
          {/* <Pay {...this.props} /> */}
          {/* <Form.Button color='green' onClick={this.handleStatPray1} loading={loadingBtn} >重整資料</Form.Button> */}
        </Form.Group>
        <br />
        <Form.Group inline>
          <Form.Input label='應收' placeholder='應收' value={accReceivableTotal} name='accReceivableTotal' readOnly />
          <Form.Input label='已收' placeholder='已收' value={accReceivedTotal} name='accReceivedTotal' readOnly />
          <Form.Input label='未付' placeholder='未付' value={accPendingTotal} name='accPendingTotal' readOnly />
        </Form.Group>
        <br />
        <Form.Group inline>
          <Form.Input label='單號搜尋' placeholder='單號搜尋' value={searchValue} name='searchValue' onChange={this.handleChange} />
        </Form.Group>
        <br />
        <Grid>
          <Grid.Row columns='equal'>
            <Grid.Column width={10}>
              <MaterialTable
                isLoading={loading}
                localization={localization()}
                columns={columns()}
                options={{
                  pageSize: 20,
                  tableLayout: 'fixed',
                  search: false
                }}
                data={dataArr}
                title={`查詢結果`}
                actions={[
                  {
                    icon: 'view_headline',
                    tooltip: '查看',
                    onClick: (event, rowData) => this.handleData(rowData)
                  },
                  // {
                  //   icon: 'file_copy',
                  //   tooltip: '複製',
                  //   onClick: (event, rowData) => this.handleDuplicate(rowData)
                  // }
                ]}
                onRowClick={(event, rowData) => this.handleData(rowData)}
              />
            </Grid.Column>
            {praying1Obj && praying1Obj.objectId ?
              <Grid.Column width={6}>
                {loading ? <DimmerImg /> : <>
                  <ModalPdf {...this.props} title='繳款單' nowform={'80'} params={{ people1, people0, praying1Obj, companyObj: companyObjLive }} selectData={selectData} />
                  <ModalPdf {...this.props} title='收據' nowform={'81'} params={{ people1, people0, praying1Obj, companyObj: companyObjLive }} selectData={selectData} />
                  <br />
                  <br />
                  <ModalReceivable {...this.props} praying1Obj={praying1Obj} handleUpdateAcc={this.handleUpdateAcc} />
                </>}
              </Grid.Column> : null}
          </Grid.Row>
        </Grid>
      </Form>

      {/* <Button icon='print' content='產生PDF' color='blue' /> */}
      {/* <Table celled textAlign='center' style={{ margin: '2rem auto' }}>
          <Table.Header>
            <Table.Row>
              {TH.map((item, index) => <Table.HeaderCell key={`dataTh${index}`}>{item.text}</Table.HeaderCell>)}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {dataArr.length ? dataArr.map((item, index) => <Table.Row key={`dataTr${index}`}>
              <Table.Cell></Table.Cell>
            </Table.Row>)
              : <Table.Row><Table.Cell colSpan={TH.length}>暫無資料</Table.Cell></Table.Row>}
          </Table.Body>
        </Table> */}
    </div>);
  }
}

export default Cashier;
