import React, { Component, } from 'react';

import { Form, Button, Table, Grid, Header, Icon, Confirm, Select } from 'semantic-ui-react'
import {
  // showField, showFieldText, getAvailblePeriodArr, getDate, 
  getOptionsByKey, getOptionTextByValue
} from 'views/widgets/FieldsRender';
import Parse from 'widget/parse.js'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class RelativeStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      formModal: {},
      show: false,
      relative: [],
      confirm: false,
      loading: false,
    };
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }


  handleChange = (event, data) => {
    const { name, value } = data;
    const { formModal } = this.state
    this.setState({
      formModal: {
        ...formModal,
        [name]: value
      }
    })
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, match } = props;
    const { _id } = match.params;
    const relative1 = await Parse.queryData('Relative', { companyId: companyObj.objectId, studentId: _id });
    const relative2 = await Parse.queryData('Relative', { companyId: companyObj.objectId, studentIdKey: _id });
    const relative = relative1.concat(relative2)
    this.setState({ relative })
    // firebase.firestore()
    //   .collection(`Students/${_id}/Relative`)
    //   // .orderBy('relationship')
    //   // .where('eventDate', '==', eventDate).where('classname', '==', className)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ relative: data });
    //   }, err => { console.log(`Encountered error: ${err}`); });
  }

  handleChangeSel = (e, { name, value }) => {
    const fieldName = name;
    const fieldValue = value;

    let mappingArr = [];

    if (fieldValue === "fromAgent") {
      // 推薦者 -> 親族 ok
      mappingArr = [
        { from: 'agentName', to: 'name' },
        { from: 'agentNationality', to: 'nationality' },
        { from: 'agentRelationship', to: 'relationship' },
        { from: 'agentRelationshipOther', to: 'relationshipOther' },
        { from: 'agentBirthday', to: 'birthday' },
        { from: 'agentTel', to: 'tel' },
        { from: 'agentCellphone', to: 'cellphone' },
        { from: 'agentWechat', to: 'wechat' },
        { from: 'agentQq', to: 'qq' },
        { from: 'agentFb', to: 'email' },
        { from: 'agentEmail', to: 'email' },
        { from: 'agentPostcode', to: 'postcode' },
        { from: 'agentAddr', to: 'addr' },
        { from: 'agentCoName', to: 'workName' },
        { from: 'agentCoTel', to: 'workTel' },
        { from: 'agentCoEmail', to: 'workEmail' },
        { from: 'agentCoUrl', to: 'workUrl' },
        { from: 'agentCoPostcode', to: 'workPostcode' },
        { from: 'agentCoAddr', to: 'workAddr' },
      ];
    } else if (fieldValue === "fromSupport") {
      // 支付者 -> 親族 ok
      mappingArr = [
        { from: 'supportName', to: 'name' },
        { from: 'supportNationality', to: 'nationality' },
        { from: 'supportRelationship', to: 'relationship' },
        { from: 'supportRelationshipOther', to: 'relationshipOther' },
        { from: 'supportBirthday', to: 'birthday' },
        { from: 'suportCareer', to: 'career' },
        { from: 'suportCareerOther', to: 'careerOther' },
        { from: 'supportIncome', to: 'annualIncome' },
        { from: 'supportIsLiveTogether', to: 'isLiveTogether' },
        { from: 'supportResidenceQualify', to: 'residenceQualify' },
        { from: 'supportResidenceNum', to: 'residenceNum' },
        { from: 'supportTel', to: 'tel' },
        { from: 'supportCellphone', to: 'cellphone' },
        { from: 'supportWechat', to: 'wechat' },
        { from: 'supportQq', to: 'qq' },
        { from: 'supportFacebook', to: 'email' },
        { from: 'supportEmail', to: 'email' },
        { from: 'supportPostcode', to: 'postcode' },
        { from: 'supportAddr', to: 'addr' },
        { from: 'supportWorkName', to: 'workName' },
        { from: 'supportWorkTel', to: 'workTel' },
        { from: 'supportWorkEmail', to: 'workEmail' },
        { from: 'supportWorkUrl', to: 'workUrl' },
        { from: 'supportWorkPostcode', to: 'workPostcode' },
        { from: 'supportWorkAddr', to: 'workAddr' },
      ];
    }

    let formModal = {};

    if (fieldName === 'referRelative' && fieldValue !== "fromSupport" && fieldValue !== "fromAgent") {
      if (fieldValue === 'clear') { // 清空
        formModal.nationality = ''
        formModal.relationship = ''
        formModal.career = ''
        formModal.isLiveTogether = ''
        formModal.residenceQualify = ''
        this.setState({
          formModal: formModal,
          [fieldName]: fieldValue,
        });
      } else { // 親族 -> 親族
        const { relative } = this.state;
        const referRelative = relative.find(item => item.objectId === fieldValue);
        const { objectId, ...rest } = referRelative;

        this.setState({ formModal: referRelative, [fieldName]: fieldValue, });
      }
    } else if (fieldName === 'referRelative') {
      const { relative } = this.state;
      // this.setState({ form: { ...this.state.form, [fieldName]: fieldValue } });
      mappingArr.forEach(({ from, to }) => {
        const setting = this.props.doc[from] && { [to]: this.props.doc[from] };
        // const existRelative = relative.find(item => item.name === this.props.doc[fieldValue.substr(4, fieldValue.length).toLowerCase() + `Name`])
        // let _id = existRelative ? existRelative._id : ''
        let formId = this.state.form.objectId
        formModal = { objectId: formId, ...setting, ...formModal };
      });
      this.setState({ formModal: { ...formModal } });
    } else {
      const ff = this.state.formModal;
      this.setState({ formModal: { ...ff, [fieldName]: fieldValue } });
    }
  };

  handleSubmit = async () => {
    const { companyObj, doc, semesterYear, semester, formSet, optSet } = this.props;
    const { form, formModal, relative } = this.state;
    // const db = firebase.firestore();

    // for (const key in form) { //建立＿＿Text
    //   const element = formSet.find(item => item.key === key);
    //   console.log(element)
    //   if (element && element.type === 'select') {
    //     form[`${key}Text`] = getOptionTextByValue(optSet, element.value, form[key]);
    //     // console.log("student method 508 ", element.value, form[key], getOptionTextByValue(optSet, element.value, form[key]));
    //   }
    // }

    this.setState({ loading: true });
    const newRows = [...relative];
    if (this.isEmpty(form)) {
      // const docRef = db.collection(`Students/${doc._id}/Relative`).doc();
      const db = Parse.Object.extend("Relative");
      const newDoc = new db();
      const docRef = await newDoc.save();
      const obj = {
        ...formModal,
        objectId: docRef.id,
        studentId: doc.objectId,
        studentIdKey: doc.studentId || '',
        studentName: doc.jpnName || '',
        companyId: companyObj.objectId,
        companyName: companyObj.name,
        // semesterYear,
        // semester,
      };
      // await docRef.set(obj);
      await Parse.saveData('Relative', obj)
      newRows.push(obj);
    } else {
      // await db.collection(`Students/${doc._id}/Relative`).doc(form._id).update(formModal);
      await Parse.saveData('Relative', formModal)
      let index = newRows.findIndex((item => item.objectId === form.objectId));
      newRows[index] = formModal;
    }

    this.setState({ relative: newRows, loading: false });
    this.handleClose();
  }

  handleEdit = (relativeObj) => {
    this.setState({ show: true, form: { ...relativeObj }, formModal: { ...relativeObj } });
  }

  handleOpen = (relativeObj) => {
    // const { doc } = this.props
    console.log('object')
    this.setState({ show: true, form: {} });
  }

  handleClose = () => {
    this.setState({ show: false, form: {}, formModal: {} });
  }

  handleRemove = async () => {
    const { firebase, doc } = this.props
    const { form, relative } = this.state;
    // await firebase.firestore().collection("Students").doc(doc._id).collection("Relative").doc(form._id).delete();
    await Parse.deleteData('Relative', form)
    const newRows = [...relative];
    let index = newRows.findIndex((item => item.objectId === form.objectId));
    newRows.splice(index, 1);
    this.setState({ relative: newRows });

    this.handleClose();
    this.hideConfirm();
  }

  showConfirm = (relativeObj) => {
    this.setState({ confirm: true, form: { ...relativeObj } });
    // console.log(relativeObj)
  }
  hideConfirm = () => this.setState({ confirm: false, form: {} });

  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  render() {
    const { optSet, doc, isEdit, companyObj } = this.props;
    const { form, formModal, relative, loading } = this.state;
    const nationalitys = getOptionsByKey(optSet, "nationality");
    const relationships = getOptionsByKey(optSet, "relationship");
    // const annualIncomes = getOptionsByKey(optSet, "annualIncome");
    const career = getOptionsByKey(optSet, "career");
    const isHas = getOptionsByKey(optSet, "isHas");
    const residenceQualify = getOptionsByKey(optSet, "residenceQualify");

    const style = {
      flex: {
        display: 'flex'
      },
      flexCol: {
        display: 'flex',
        flexDirection: 'column'
      },
      flexCenter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }
    }
    // if (!doc || !doc._id) {
    //   return (<h3>找不到對應id</h3>);
    // }

    const options = [
      { key: 'sel', text: '-- 選択 --', value: ' ', disabled: true },
      { key: 'clear', text: '-- クリア --', value: 'clear', disabled: false },
      { key: 'fromSupport', text: `[経費支弁者] ${doc.supportName ? doc.supportName : '(無)'}`, value: 'fromSupport', disabled: doc.supportName ? false : true },
      { key: 'fromAgent', text: `[推薦者] ${doc.agentName ? doc.agentName : '(無)'}`, value: 'fromAgent', disabled: doc.agentName ? false : true },
      { key: 'selRel', text: '===親族===', value: 'fromRelative', disabled: true },
    ]

    const nationalOptions = nationalitys.sort((a, b) => a.orderId - b.orderId).map(item => ({ key: item._id, value: item.value, text: item.label }))

    const nationalOptionsArr = [
      { key: ' ', text: '-- 選択 --', value: '' },
      ...nationalOptions,
    ]
    const relationshipsOptions = relationships.sort((a, b) => a.orderId - b.orderId).map(item => ({ key: item._id, value: item.value, text: item.label }))
    const relationshipsOptionsArr = [
      { key: ' ', text: '-- 選択 --', value: '' },
      ...relationshipsOptions
    ]
    const careerOptions = career.sort((a, b) => a.orderId - b.orderId).map(item => ({ key: item._id, value: item.value, text: item.label }))
    const careerOptionsArr = [
      { key: ' ', text: '-- 選択 --', value: '' },
      ...careerOptions
    ]
    const isLiveTogetherOptions = isHas.sort((a, b) => a.orderId - b.orderId).map(item => ({ key: item._id, value: item.value, text: item.label }))
    const isLiveTogetherOptionsArr = [
      { key: ' ', text: '-- 選択 --', value: '' },
      ...isLiveTogetherOptions
    ]
    const residenceQualifyOptions = residenceQualify.sort((a, b) => a.orderId - b.orderId).map(item => ({ key: item._id, value: item.value, text: item.label }))
    const residenceQualifyOptionsArr = [
      { key: ' ', text: '-- 選択 --', value: '' },
      ...residenceQualifyOptions
    ]

    if (!relative.length) {
      options.push({ key: 'selRelNo', text: '(無)', value: '', disabled: true })
    } else {
      relative.forEach(item => options.push({ key: item.objectId, text: `${getOptionTextByValue(optSet, 'relationship', item.relationship)} ${item.name}`, value: item.objectId }));
    }

    return (
      <>
        <Header as='h2' dividing
          content='親族'
          style={{ backgroundColor: 'lavender' }}
        // subheader='調査時間：2017年4月から 2018年12月 まで'
        />
        <Button disabled={!isEdit} floated="left" inverted={companyObj.inverted} color='blue' onClick={this.handleOpen} icon='add' content='追加' />
        <div style={{ clear: 'both' }}></div>
        <Table celled structured size="small">
          <Table.Header>
            <Table.Row>
              {isEdit ? <Table.HeaderCell rowSpan="2" style={{ width: '50px' }}>#</Table.HeaderCell> : null}
              <Table.HeaderCell rowSpan="2">氏名</Table.HeaderCell>
              <Table.HeaderCell>国籍</Table.HeaderCell>
              <Table.HeaderCell>生年月日</Table.HeaderCell>
              <Table.HeaderCell>年收</Table.HeaderCell>
              <Table.HeaderCell>在留資格</Table.HeaderCell>
              <Table.HeaderCell>電話番号</Table.HeaderCell>
              <Table.HeaderCell>wechat</Table.HeaderCell>
              <Table.HeaderCell>Eメール</Table.HeaderCell>
              <Table.HeaderCell>郵便番号</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell style={{ borderLeft: '1px solid rgba(34,36,38,.15)' }}>関係</Table.HeaderCell>
              <Table.HeaderCell>職業</Table.HeaderCell>
              <Table.HeaderCell>同居予定</Table.HeaderCell>
              <Table.HeaderCell>在留カ一ド番号</Table.HeaderCell>
              <Table.HeaderCell>携帯電話番号</Table.HeaderCell>
              <Table.HeaderCell>qq</Table.HeaderCell>
              <Table.HeaderCell>facebook</Table.HeaderCell>
              <Table.HeaderCell>住所</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!relative.length ? <Table.Row>
              <Table.Cell colSpan={isEdit ? 10 : 9} textAlign='center' >(資料無し)</Table.Cell>
            </Table.Row> : null}
            {relative && relative.sort((a, b) => a.relationship - b.relationship).map(item => (
              <React.Fragment key={item.objectId}>
                <Table.Row>
                  {isEdit ? <Table.Cell rowSpan="2" style={{ padding: 10 }}>
                    <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} icon='edit' /><br /> <br />
                    <Button inverted={companyObj.inverted} color='red' size='small' onClick={() => this.showConfirm(item)} icon='delete' />
                  </Table.Cell> : null}
                  <Table.Cell rowSpan="2">{item.name}</Table.Cell>
                  <Table.Cell>{getOptionTextByValue(optSet, 'nationality', item.nationality)}</Table.Cell>
                  <Table.Cell>{item.birthday}</Table.Cell>
                  <Table.Cell>{item.annualIncome}</Table.Cell>
                  <Table.Cell>{getOptionTextByValue(optSet, 'residenceQualify', item.residenceQualify)}</Table.Cell>
                  <Table.Cell>{item.tel}</Table.Cell>
                  <Table.Cell>{item.wechat}</Table.Cell>
                  <Table.Cell>{item.email}</Table.Cell>
                  <Table.Cell>{item.postcode}</Table.Cell>
                  {/* <Table.Cell></Table.Cell> */}
                </Table.Row>
                <Table.Row>
                  {/* {isEdit ? <Table.Cell>
                    <Button size='small' onClick={() => this.handleEdit(item)} icon='edit' content='修正' />{' '}
                    <Button size='small' onClick={() => this.handleRemove(item)}  icon='delete' content='削除' />
                  </Table.Cell> : null} */}
                  <Table.Cell style={{ borderLeft: '1px solid rgba(34,36,38,.15)' }}>{getOptionTextByValue(optSet, 'relationship', item.relationship)}</Table.Cell>
                  <Table.Cell>{getOptionTextByValue(optSet, 'career', item.career)}</Table.Cell>
                  <Table.Cell>{getOptionTextByValue(optSet, 'isHas', item.isLiveTogether)}</Table.Cell>
                  <Table.Cell>{item.residenceNum}</Table.Cell>
                  <Table.Cell>{item.cellphone}</Table.Cell>
                  <Table.Cell>{item.qq}</Table.Cell>
                  <Table.Cell>{item.facebook}</Table.Cell>
                  <Table.Cell>{item.addr}</Table.Cell>
                </Table.Row>
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>
        {/* <Modal open={this.state.show} onClose={this.handleClose} dialogclassname="modal-90w"> */}
        <Modal
          isOpen={this.state.show}
          toggle={this.handleClose}
          size="lg"
          className="mh-100 h-90 mw-100 w-90"
        >
          <ModalHeader style={style.flexCenter}>
            <h2 style={{ margin: 0 }}> {/*this.isEmpty(form) ? '追加' : '編集'*/}親族</h2>
            <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
          </ModalHeader>
          <ModalBody>
            <Grid style={{ padding: '1.5rem' }}>
              <Grid.Row style={{ ...style.flexCenter, paddingRight: 14 }}>
                <Header as='h4' style={{ fontSize: '1.25rem' }}></Header>
                {/* <Header as='h4' style={{ fontSize: '1.25rem' }}>基本資料</Header> */}
                <Form><Form.Select label='情報参照' name='referRelative' defaultValue=' ' options={options} onChange={this.handleChangeSel} /></Form>
              </Grid.Row>
              <Form >
                <Grid columns='equal'>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Input label='氏名' readOnly={isEdit ? false : true} name="name" value={formModal.name ? formModal.name : ""} onChange={this.handleChange} />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Select
                        label='国籍'
                        options={nationalOptionsArr}
                        readOnly={isEdit ? false : true}
                        name="nationality"
                        value={formModal.nationality}
                        onChange={this.handleChange} />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Select
                        label='関係'
                        options={relationshipsOptionsArr}
                        readOnly={isEdit ? false : true}
                        name="relationship"
                        value={formModal.relationship}
                        onChange={this.handleChange} />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input label='関係(その他)' readOnly={isEdit ? false : true} name="relationshipOther" value={formModal.relationshipOther ? formModal.relationshipOther : ""} onChange={this.handleChange} />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Input label='生年月日' type="date" name="birthday" value={formModal.birthday || ""} onChange={this.handleChange} />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Select
                        label='職業'
                        options={careerOptionsArr}
                        readOnly={isEdit ? false : true}
                        name="career"
                        value={formModal.career}
                        // value={form.career || ""}
                        onChange={this.handleChange} />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        label='職業(其他)' readOnly={isEdit ? false : true} name="careerOther" value={formModal.careerOther || ""} onChange={this.handleChange}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        label='年收' readOnly={isEdit ? false : true} name="annualIncome" value={formModal.annualIncome || ""} onChange={this.handleChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Select
                        label='同居予定'
                        options={isLiveTogetherOptionsArr}
                        readOnly={isEdit ? false : true}
                        name="isLiveTogether"
                        value={formModal.isLiveTogether}
                        onChange={this.handleChange} />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Select
                        label='在留資格'
                        options={residenceQualifyOptionsArr}
                        readOnly={isEdit ? false : true}
                        name="residenceQualify"
                        value={formModal.residenceQualify}
                        onChange={this.handleChange} />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input label='在留カ一ド番号' readOnly={isEdit ? false : true} name="residenceNum" value={formModal.residenceNum || ""} onChange={this.handleChange} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>

              <Grid.Row style={{ margin: '20px 0' }}>
                <Header as='h4' style={{ fontSize: '1.25rem' }}>本人連絡情報</Header>
              </Grid.Row>
              <Form>
                <Grid columns='equal'>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Input label='電話番号' readOnly={isEdit ? false : true} name="tel" value={formModal.tel || ""} onChange={this.handleChange} />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input label='携帯電話番号' readOnly={isEdit ? false : true} name="cellphone" value={formModal.cellphone || ""} onChange={this.handleChange} />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input label='WeChat' readOnly={isEdit ? false : true} name="wechat" value={formModal.wechat || ""} onChange={this.handleChange} />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input label='QQ' readOnly={isEdit ? false : true} name="qq" value={formModal.qq || ""} onChange={this.handleChange} />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Input label='Facebook' readOnly={isEdit ? false : true} name="facebook" value={formModal.facebook || ""} onChange={this.handleChange} />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input label='Eメール' readOnly={isEdit ? false : true} name="email" value={formModal.email || ""} onChange={this.handleChange} /> {/* type="email" */}
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <Form.Input label='郵便番号' readOnly={isEdit ? false : true} name="postcode" value={formModal.postcode || ""} onChange={this.handleChange} />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.TextArea label='住所' readOnly={isEdit ? false : true} name="addr" value={formModal.addr || ""} onChange={this.handleChange} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>

              <Grid.Row style={{ margin: '20px 0' }}>
                <Header as='h4' style={{ fontSize: '1.25rem' }}>勤務先連絡情報</Header>
              </Grid.Row>
              <Form>
                <Grid columns='equal'>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Input label='勤務先' readOnly={isEdit ? false : true} name="workName" value={formModal.workName || ""} onChange={this.handleChange} />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input label='電話番号' readOnly={isEdit ? false : true} name="workTel" value={formModal.workTel || ""} onChange={this.handleChange} />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input label='Eメール' readOnly={isEdit ? false : true} name="workEmail" value={formModal.workEmail || ""} onChange={this.handleChange} />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Input label='URL' readOnly={isEdit ? false : true} name="workUrl" value={formModal.workUrl || ""} onChange={this.handleChange} />
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <Form.Input label='郵便番号' readOnly={isEdit ? false : true} name="workPostcode" value={formModal.workPostcode || ""} onChange={this.handleChange} />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.TextArea label='住所' readOnly={isEdit ? false : true} name="workAddr" value={formModal.workAddr || ""} onChange={this.handleChange} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>

            </Grid>
          </ModalBody>
          <ModalBody style={{ borderTop: '1px solid rgba(34,36,38,.15)', display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={this.handleSubmit} inverted={companyObj.inverted} color='green' loading={loading} icon='checkmark' content={this.isEmpty(form) ? '追加' : '保存'} />
            <Button onClick={this.handleClose} inverted={companyObj.inverted} color='red' icon='delete' content='キャンセル' />
          </ModalBody>
        </Modal>
        {/* <Confirm
          header='削除'
          content='削除してもいいですか?'
          cancelButton='いいえ'
          confirmButton='はい'
          open={this.state.confirm}
          onCancel={this.hideConfirm}
          onConfirm={this.handleRemove}
          size='mini'
          centered={false} /> */}
        <Modal
          isOpen={this.state.confirm}
          toggle={this.hideConfirm}
          size="lg"
          className="mh-100 h-90 mw-100 w-90"
        >
          <ModalHeader>削除</ModalHeader>
          <ModalBody>
            削除してもいいですか？
        </ModalBody>
          <ModalFooter style={{ padding: '16px' }}>
            <Button onClick={this.handleRemove} inverted color='red' icon='delete' content='はい' />
            <Button onClick={this.hideConfirm} content='いいえ' />
          </ModalFooter>
        </Modal>
      </>
    );
  }
}