import React, { Component } from 'react'
import { Container, Table, Dimmer, Loader, Image, Segment, Input, Form } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Moment from 'moment';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton'
import { dateCol, exportPdf, typeCurrency } from 'views/web/CustomMaterialTable';


let is_authOpt = { '1': '在職', '0': '離職' }

const columns = () => ([
  { title: '在職', field: 'is_auth', lookup: is_authOpt, editable: 'never' },
  { title: '員工編號', field: 'jobNum', editable: 'never' },
  { width: 80, title: '員工姓名', field: 'name', editable: 'never' },
  { title: '到職日', field: 'onbroad_date', editable: 'never' },
  { title: '離職日', field: 'leave_date', editable: 'never' },
  { title: '基本薪資', field: 'salary1', ...typeCurrency },
  { title: '交通津貼', field: 'salary2', ...typeCurrency },
  { title: '伙食津貼', field: 'salary3', ...typeCurrency },
  { title: '職務加給', field: 'salary4', ...typeCurrency },
  { title: '其他', field: 'other', ...typeCurrency },
  {
    title: '應領合計', field: 'totalrec', editable: 'never', ...typeCurrency
    // render: rowData => 'NT$' + (Number(rowData.salary1 || 0) + Number(rowData.salary2 || 0) + Number(rowData.salary3 || 0) + Number(rowData.salary4 || 0) + Number(rowData.other || 0))
  },
  { title: '請假', field: 'deduct1', ...typeCurrency },
  { title: '健保', field: 'deduct2', ...typeCurrency },
  { title: '勞保', field: 'deduct3', ...typeCurrency },
  { title: '勞工自提勞退', field: 'deduct5', ...typeCurrency },
  { title: '代扣稅款', field: 'deduct4', ...typeCurrency },
  {
    title: '應扣合計', field: 'totaldedu', editable: 'never', ...typeCurrency
    // render: rowData => 'NT$' + (Number(rowData.deduct1 || 0) + Number(rowData.deduct2 || 0) + Number(rowData.deduct3 || 0) + Number(rowData.deduct4 || 0) + Number(rowData.deduct5 || 0))
  },
  {
    title: '實發金額', field: 'amount', editable: 'never', ...typeCurrency,
    // render: rowData => 'NT$' + ((Number(rowData.salary1 || 0) + Number(rowData.salary2 || 0) + Number(rowData.salary3 || 0) + Number(rowData.salary4 || 0) + Number(rowData.other || 0))
    //   - (Number(rowData.deduct1 || 0) + Number(rowData.deduct2 || 0) + Number(rowData.deduct3 || 0) + Number(rowData.deduct4 || 0)))
  },
  { title: '備註', field: 'salary_ps' },
]);

export default class Fin1112 extends Component {
  state = {
    salaryList: [],
    dataArr: [],
    deleteConfirm: false,
    loading: true,
    monthSel: Moment(new Date()).format('YYYY-MM') || '',
    rowData: null,
    show: false
  }

  componentDidMount() {
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props, type = 1) => {
    const { companyObj } = props;
    const { monthSel } = this.state
    const [year = "", month = ""] = monthSel.split('-');
    const user = await Parse.queryData('User', { authUserA: companyObj.objectId });
    const companyId = companyObj.objectId;
    // const user = users.map(item => ({ ...item, is_auth: (item.is_auth && item.is_auth.value) || '' }))
    const y = Number(year);
    const m = Number(month) - 1;
    const begin_date = new Date(y, m, 1).getTime();
    const end_date = new Date(y, m + 1, 0).getTime();

    const emDataArr = user.map(item => {
      if (!item.onbroad_date || typeof item.onbroad_date === "undefined") {
        return null;
      }

      // 進來時間 比月底還早的話 就要顯示 且 
      // 有離職時間的話 離職時間 比月初還晚的話 就要顯示
      if (new Date(item.onbroad_date).getTime() <= end_date) {
        if (!item.leave_date || typeof item.leave_date === "undefined") {
          // console.log(new Date(item.onbroad_date).getTime())
          let obj = {
            ...item,
            work: '1'
          }
          return obj;
        } else if (new Date(item.leave_date).getTime() >= begin_date) {
          let obj = {
            ...item,
            work: '0'
          }
          // console.log(new Date(item.leave_date).getTime(), begin_date)
          return obj;
        }
      }
      return null;
    }).filter(item => item);
    // console.log(dataArr)

    let salary = []
    // if (type === 1) {
    salary = await Parse.queryData('salary', { companyId, year: year, month: month });
    // }

    const dataArr = emDataArr.map(item => {
      const saObj = salary.find(item2 => item2.userId === item.objectId);//
      if (saObj) {
        let obj = {
          ...saObj,
          ...item,
          is_auth: item.work,
        }
        return obj
      } else {
        let obj = {
          ...item,
          is_auth: item.work,
          userId: item.objectId
        }
        delete obj.objectId
        return obj
      }
    })
    this.setState({ dataArr, loading: false, show: false })

  }

  handleUpdate = async () => {
    const { newData, monthSel = "0-0" } = this.state

    await Parse.saveData('salary', newData);
    this.handleFetch()
  }

  handleChange = (event, data) => {
    const { name, value } = data;
    if (name === 'ps1') {
      this.setState({ rowData: { ...this.state.rowData, [name]: value } })
    } else {
      this.setState({ [name]: value, loading: true, show: false }, () => this.handleFetch(this.props, 0));
    }
  }

  handleData = (rowData) => {
    this.setState({ rowData, show: true })
  }

  render() {
    const { companyObj, auth } = this.props
    const { dataArr, loading, bankaccArr, bankaccSel, monthSel, rowData, show } = this.state;
    const [year, month] = monthSel.split('-');

    return (<>
      {/* 資金需求預估表 */}
      <br />
      <Input
        type="month"
        name="monthSel"
        value={monthSel}
        onChange={this.handleChange}
      />
      {/* <Select style={{
          position: 'relative', zIndex: 99,
          float: 'right',
          minWidth: '200px',
          // borderTopLeftRadius: '0',
          // borderBottomLeftRadius: '0'
        }}
          compact
          name="bankaccSel"
          options={bankaccArr}
          value={bankaccSel}
          onChange={this.handleChange}
        /> */}
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns()}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportAllData: true,
          exportPdf: (columns, data) => {
            const dataArr = data.map(item => ({ ...item, is_auth: (item.is_auth && is_authOpt[item.is_auth]) || '' }))
            return exportPdf(columns, dataArr, `${year} / ${month} 月薪資表`)
          },
          pageSize: 10,
          search: false
        }}
        data={dataArr}
        title={`${year} / ${month} 月薪資表`}
        actions={[
          {
            icon: 'view_headline',
            tooltip: '查看',
            onClick: (event, rowData) => this.handleData(rowData)
          }
        ]}
        onRowClick={(event, rowData) => this.handleData(rowData)}
        editable={{
          isEditHidden: () => !auth.edit,
          onRowUpdate: (newData, oldData) =>
            new Promise(async (resolve, reject) => {
              // const data = [...dataArr];
              // const index = data.indexOf(oldData);
              let objectId = newData.objectId || ''
              if (newData.objectId === undefined) {
                const db = Parse.Object.extend("salary");
                const newDoc = new db();
                const docRef = await newDoc.save();
                objectId = docRef.id
              }
              const obj = {
                ...newData,
                objectId,
                year,
                month,
                monthSel,
                companyId: companyObj.id,
                employeeId: newData.id,
                is_auth: { value: newData.is_auth, label: newData.is_auth === '1' ? "在職" : "離職" },
                jobNum: newData.jobNum || "",
                name: newData.name || "",
                salary1: newData.salary1 || 0,
                salary2: newData.salary2 || 0,
                salary3: newData.salary3 || 0,
                salary4: newData.salary4 || 0,
                other: newData.other || 0,
                totalrec: (Number(newData.salary1 || 0) + Number(newData.salary2 || 0) + Number(newData.salary3 || 0) + Number(newData.salary4 || 0) + Number(newData.other || 0)),
                deduct1: newData.deduct1 || 0,
                deduct2: newData.deduct2 || 0,
                deduct3: newData.deduct3 || 0,
                deduct4: newData.deduct4 || 0,
                deduct5: newData.deduct5 || 0,
                totaldedu: (Number(newData.deduct1 || 0) + Number(newData.deduct2 || 0) + Number(newData.deduct3 || 0) + Number(newData.deduct4 || 0) + Number(newData.deduct5 || 0)),
                amount: (Number(newData.salary1 || 0) + Number(newData.salary2 || 0) + Number(newData.salary3 || 0) + Number(newData.salary4 || 0) + Number(newData.other || 0))
                  - (Number(newData.deduct1 || 0) + Number(newData.deduct2 || 0) + Number(newData.deduct3 || 0) + Number(newData.deduct4 || 0) + Number(newData.deduct5 || 0)),
                ps: newData.ps || "",
              };
              // data[index] = obj;
              this.setState({ newData: obj, oldData, loading: true }, () => resolve());
            }).then(() => this.handleUpdate()),
        }}
      />
      {rowData && rowData.username && show ?
        <>
          <br />
          <ExportTableToExcelButton floated='left' name={`${rowData.name}_${year}年${month}月份薪資單`} id='excel_salary' />
          <br />
          <Table celled structured style={{ width: "50%" }} id='salary'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign='center' colSpan='5'>{companyObj.name}</Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell textAlign='center' colSpan='5'>{year}年{month}月份薪資單</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign='center' width={3}>姓名：</Table.Cell>
                <Table.Cell width={4} textAlign='center'>{rowData.name}</Table.Cell>
                <Table.Cell width={2} textAlign='center'></Table.Cell>
                <Table.Cell width={4} textAlign='center'></Table.Cell>
                <Table.Cell width={2} textAlign='center'></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign='center'>職稱：</Table.Cell>
                <Table.Cell textAlign='center'>{rowData.jobTitle}</Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign='center'>撥薪日：</Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign='center'>帳戶資料：</Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign='center' colSpan='5'>薪資明細</Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell textAlign='center' width={1}>薪資項目</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>金額</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' width={1}>扣款項目</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>金額</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' width={2}>備註</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign='center'>基本薪資</Table.Cell>
                <Table.Cell textAlign='center'>{rowData.salary1}</Table.Cell>
                <Table.Cell textAlign='center'>勞保</Table.Cell>
                <Table.Cell textAlign='center'>{rowData.deduct3}</Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign='center'>職務加給</Table.Cell>
                <Table.Cell textAlign='center'>{rowData.salary4}</Table.Cell>
                <Table.Cell textAlign='center'>健保</Table.Cell>
                <Table.Cell textAlign='center'>{rowData.deduct2}</Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign='center'>交通津貼</Table.Cell>
                <Table.Cell textAlign='center'>{rowData.salary2}</Table.Cell>
                <Table.Cell textAlign='center'>代扣稅款</Table.Cell>
                <Table.Cell textAlign='center'>{rowData.deduct4}</Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign='center'>伙食津貼</Table.Cell>
                <Table.Cell textAlign='center'>{rowData.salary3}</Table.Cell>
                <Table.Cell textAlign='center'>請假</Table.Cell>
                <Table.Cell textAlign='center'>{rowData.deduct1}</Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign='center'>其他</Table.Cell>
                <Table.Cell textAlign='center'>{rowData.other}</Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign='center'>其他</Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign='center'>應領合計</Table.Cell>
                <Table.Cell textAlign='center'>{rowData.totalrec}</Table.Cell>
                <Table.Cell textAlign='center'>應扣合計</Table.Cell>
                <Table.Cell textAlign='center'>{rowData.totaldedu}</Table.Cell>
                <Table.Cell textAlign='center'></Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign='center' width={1}>實發金額</Table.HeaderCell>
                <Table.HeaderCell textAlign='center' colSpan='4'>{rowData.amount}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign='center'>備註</Table.Cell>
                <Table.Cell colSpan='4' textAlign='center'>{rowData.salary_ps}</Table.Cell>
                {/* <Table.Cell  textAlign='center'></Table.Cell> */}
                {/* <Table.Cell textAlign='center'></Table.Cell> */}
                {/* <Table.Cell textAlign='center'></Table.Cell> */}
              </Table.Row>
            </Table.Body>
          </Table>
        </> : null}
      <br />
      <br />
    </>)
  }
}