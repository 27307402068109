export const pad = function (n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export const strDiff = (stringA, stringB) => {
  // console.log(stringA, stringB);
  // var firstOccurrence = stringB.indexOf(stringA);

  // if (firstOccurrence === -1) {
  //   // alert('Search string Not found');
  // }
  // else {
  //   var stringALength = stringA.length;
  //   var newString;

  //   if (firstOccurrence === 0) {
  //     newString = stringB.substring(stringALength);
  //   }
  //   else {
  //     newString = stringB.substring(0, firstOccurrence);
  //     newString += stringB.substring(firstOccurrence + stringALength);
  //   }
  const linesA = stringA.split('\n');
  const linesB = stringB.split('\n');
  const diffArr = [];
  for (let i = 0; i < linesA.length; i++) {
    const a = linesA[i].trim();
    const b = linesB[i].trim();

    if (a !== b) {
      const obj = {
        a, b, line: i + 1,
      };
      diffArr.push(obj);
    }
  }

  return diffArr;
}
