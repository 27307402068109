import React, { Component } from 'react';
import { Container, Table, Grid, Header, Form, Card } from 'semantic-ui-react';
// import { heightElements } from 'juice';
import ReactToPrint from "react-to-print";
import ModalPdf from 'widget/pdf/ModalPdf';
import { getSemesterAttendByWeekly } from './Table13';
import { Col } from 'reactstrap'
import { showField, showFieldText } from 'views/widgets/FieldsRender';
import Parse from 'widget/parse.js'

export default class StudentRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doc: props.doc,
      instruct: [],
    };
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { firebase, match, companyObj } = props;
    const { _id } = match.params;

    const data = await Parse.queryData('Special', { companyId: companyObj.objectId, studentId: _id });
    this.setState({ instruct: data });

    // firebase.firestore()
    //   .collection(`Students/${_id}/Special`)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ instruct: data });
    //   }, err => { console.log(`Encountered error: ${err}`); });

    const data1 = await Parse.queryData('Weekly', { companyId: companyObj.objectId, studentId: _id });
    this.setState({ arr: data1 })

    // firebase.firestore()
    //   .collection(`Students/${_id}/Weekly`)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }))
    //     this.setState({ arr: data })
    //   }, err => { console.log(`Encountered error: ${err}`); });

    const data2 = await Parse.queryData('Grade', { companyId: companyObj.objectId, studentId: _id });
    this.setState({ arrGrade: data2 }, () => this.getPrintData());

    // firebase.firestore()
    //   .collection(`Students/${_id}/Grade`)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     this.setState({ arrGrade: data }, () => this.getPrintData());
    //   }, err => { console.log(`Encountered error: ${err}`); });
  }

  getPrintData = () => {
    const { doc } = this.props;
    const { instruct, arrGrade, arr } = this.state;
    // let obj = { ...instruct[0] }
    const semesterInRoman = doc.semester === "S" ? ['I', 'II', 'III', 'IV'] : ['I', 'II', 'III'];
    let dayAvg = []
    let hourAvg = []
    semesterInRoman.map(item => {
      dayAvg.push(getSemesterAttendByWeekly(arr, item)[4])
      hourAvg.push(getSemesterAttendByWeekly(arr, item)[5])
    })
    this.setState({ params: { instruct, ...doc, arrGrade, semesterInRoman, dayAvg, hourAvg } })
  }

  render() {
    const { formSet, optSet, doc } = this.props;
    const { instruct, params, arr, arrGrade } = this.state
    const semesterInRoman = doc.semester === "S" ? ['I', 'II', 'III', 'IV'] : ['I', 'II', 'III'];

    return (<div>
      <Header as='h2' dividing
        content='学籍名簿'
        style={{ backgroundColor: 'lavender' }}
      // subheader='調査時間：2017年4月から 2018年12月 まで'
      />
      <ModalPdf
        {...this.props}
        // ref={(ref) => { this.pdfModal = ref }}
        codekey="studentRecordPdf"
        params={params}
        style={{ marginBottom: 16 }}
      />
      <div style={{ clear: 'both' }}></div>
      {/* <ReactToPrint
        trigger={(e) => <Form.Button
          content='印刷'
          label='&nbsp;'
          positive icon='print'
          size='small'
        />}
        content={() => this.componentRef}
        pageStyle={"@page { size: auto;  margin: 10mm; }*{font-family: 'Noto Serif JP', serif;}"}
      /> */}
      {/* <div ref={el => (this.componentRef = el)} className="print" > */}
      <Card fluid>
        <Card.Content>
          <div ref={el => (this.componentRef = el)}>
            {/* <Grid columns='equal'> */}
            <Col>{showField(this.props, "jpnName")}</Col>
            <Col>{showField(this.props, "studentId")}</Col>
            <Col>{showField(this.props, "semesterYear")}</Col>
            <Col>{showField(this.props, "semester")}</Col>
            {/* </Grid> */}
            <br />
            <Table celled structured size="small" style={{ textAlign: 'center', tableLayout: "auto", width: '100%' }} >
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan='5'>学 籍 番 號</Table.Cell>
                  <Table.Cell colSpan='5'>{doc.semesterYear.substr(-2)}({doc.semester}){doc.studentId}</Table.Cell>
                  <Table.Cell colSpan='5'>在 留 資 格</Table.Cell>
                  <Table.Cell colSpan='6'>{showFieldText(formSet, optSet, "stayQualify", doc)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan='5'>課 程</Table.Cell>
                  <Table.Cell colSpan='5'>{doc.semester === 'S' ? '2年' : '1年半'}</Table.Cell>
                  <Table.Cell colSpan='5'>在 留 番 號</Table.Cell>
                  <Table.Cell colSpan='6'>{doc.residentCardnumber}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan='5'>氏 名</Table.Cell>
                  <Table.Cell colSpan='5'>{doc.jpnName}</Table.Cell>
                  <Table.Cell colSpan='5' rowSpan='2'>在 留 期 間</Table.Cell>
                  {/* <Table.Cell colSpan='6'>初回：{showFieldText(formSet, optSet, "landingAdmitDate",doc)}～</Table.Cell> */}
                  <Table.Cell colSpan='6'>初回：{doc.landingAdmitDate}～{doc.visaExpireDate1}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan='5'>国 籍</Table.Cell>
                  <Table.Cell colSpan='5'>{showFieldText(formSet, optSet, "nationality", doc)}</Table.Cell>
                  {/* <Table.Cell colSpan='5'></Table.Cell> */}
                  <Table.Cell colSpan='6'>更新：{doc.visaExpireDate2 ? doc.visaExpireDate1 : ''}～{doc.visaExpireDate2 ? doc.visaExpireDate2 : ''}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan='5'>性 別</Table.Cell>
                  <Table.Cell colSpan='5'>{showFieldText(formSet, optSet, "gender", doc)}</Table.Cell>
                  <Table.Cell colSpan='5' rowSpan='2'>住 所</Table.Cell>
                  <Table.Cell colSpan='6'>初回：{doc.jpnAddr}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan='5'>生 年 月 日</Table.Cell>
                  <Table.Cell colSpan='5'>{showFieldText(formSet, optSet, "birthday", doc)}</Table.Cell>
                  {/* <Table.Cell colSpan='5'></Table.Cell> */}
                  <Table.Cell colSpan='6'>移動：</Table.Cell>
                </Table.Row>
                {/* <Table.Row>
                  <Table.Cell colSpan='8' width='8'>在留資格</Table.Cell>
                  <Table.Cell colSpan='8' width='8'>在留期間</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan='8' height='40'></Table.Cell>
                  <Table.Cell colSpan='8' height='40'>
                    {doc.visaDuring1 && `1回目` + showFieldText(formSet, optSet, "visaDuring1", doc)}
                    {doc.visaDuring2 && ` / 2回目` + showFieldText(formSet, optSet, "visaDuring2", doc)}
                    {doc.visaDuring3 && ` / 3回目` + showFieldText(formSet, optSet, "visaDuring3", doc)}
                  </Table.Cell>
                </Table.Row> */}
                <Table.Row>
                  <Table.Cell colSpan='5'>クラス</Table.Cell>
                  <Table.Cell colSpan='5' >{doc.classSchool}</Table.Cell>
                  <Table.Cell colSpan='11' ></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell rowSpan='3' colSpan='5'>出席</Table.Cell>
                  <Table.Cell colSpan='4'>学期</Table.Cell>
                  {semesterInRoman.map((item, index) => (
                    <>
                      <Table.Cell key={'semes' + index} colSpan='3'>{item}</Table.Cell>
                    </>
                  ))}
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan='4'>日出席率</Table.Cell>
                  {semesterInRoman.map((item, index) => (
                    <>
                      <Table.Cell key={'dayatt' + index} colSpan='3'>{arr && getSemesterAttendByWeekly(arr, item)[4]}%</Table.Cell>
                    </>
                  ))}
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan='4'>時出席率</Table.Cell>
                  {semesterInRoman.map((item, index) => (
                    <>
                      <Table.Cell key={'houratt' + index} colSpan='3'>{arr && getSemesterAttendByWeekly(arr, item)[5]}%</Table.Cell>
                    </>
                  ))}
                </Table.Row>
                <Table.Row>
                  <Table.Cell rowSpan={arrGrade && arrGrade.length + 2} colSpan='5'>成績</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan='3'>試験日</Table.Cell>
                  <Table.Cell colSpan='3'>試験レべル</Table.Cell>
                  <Table.Cell colSpan='3'>順位</Table.Cell>
                  <Table.Cell colSpan='1'>文字語彙</Table.Cell>
                  <Table.Cell colSpan='1'>聴解</Table.Cell>
                  <Table.Cell colSpan='1'>文法</Table.Cell>
                  <Table.Cell colSpan='1'>読解</Table.Cell>
                  <Table.Cell colSpan='1'>合計</Table.Cell>
                  <Table.Cell colSpan='1'>作文評価</Table.Cell>
                  <Table.Cell colSpan='1'>会話評価</Table.Cell>
                </Table.Row>
                {arrGrade && arrGrade.map((item, index) =>
                  <Table.Row key={'arrgrade' + index} >
                    <Table.Cell colSpan='3'>{item.testDate}</Table.Cell>
                    <Table.Cell colSpan='3'>{item.testLevel}</Table.Cell>
                    <Table.Cell colSpan='3'>{item.rank}/{item.rankTotal}</Table.Cell>
                    <Table.Cell colSpan='1'>{item.vocabularyScore}</Table.Cell>
                    <Table.Cell colSpan='1'>{item.listeningScore}</Table.Cell>
                    <Table.Cell colSpan='1'>{item.grammarScore}</Table.Cell>
                    <Table.Cell colSpan='1'>{item.readingScore}</Table.Cell>
                    <Table.Cell colSpan='1'>{(Number(item.vocabularyScore) + Number(item.listeningScore) + Number(item.grammarScore) + Number(item.readingScore)).toString()}</Table.Cell>
                    <Table.Cell colSpan='1'>{item.writingScore}</Table.Cell>
                    <Table.Cell colSpan='1'>{item.conversationScore}</Table.Cell>
                  </Table.Row>)}
                <Table.Row>
                  <Table.Cell rowSpan='3' colSpan='5'>評語</Table.Cell>
                  <Table.Cell colSpan='5' height='40'></Table.Cell>
                  <Table.Cell colSpan='11' height='40'></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan='5' height='40'></Table.Cell>
                  <Table.Cell colSpan='11' height='40'></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan='5' height='40'></Table.Cell>
                  <Table.Cell colSpan='11' height='40'></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell rowSpan={instruct.length + 2} colSpan='5' >特別指導</Table.Cell>
                  <Table.Cell colSpan='5' height='40'>日付</Table.Cell>
                  <Table.Cell colSpan='5' height='40'>問題</Table.Cell>
                  <Table.Cell colSpan='6' height='40'>対策</Table.Cell>
                </Table.Row>
                {instruct.length ? instruct.map((item, index) =>
                  <Table.Row key={'ins' + index} >
                    <Table.Cell colSpan='5' height='40'>{item.eventDate}</Table.Cell>
                    <Table.Cell colSpan='5' height='40'>{item.weeklyReportDetail}</Table.Cell>
                    <Table.Cell colSpan='6' height='40'>{item.weeklyReportAction}</Table.Cell>
                  </Table.Row>) : <Table.Row>
                    <Table.Cell colSpan='16' height='40'>無</Table.Cell>
                  </Table.Row>}
              </Table.Body>
            </Table>
          </div>
        </Card.Content>
      </Card>
    </div>);
  }
}