import React, { Component } from 'react';
import { Container, Header, Form, Icon, Grid, Button, Select, Checkbox } from 'semantic-ui-react'

import SchoolHour from '../../widgets/SchoolHour.js';
import { setHomeroomTeacher, getMedianBirthdayOfTeachers, setAllTeacher } from '../Teacher/AutoTimetableAlgo';
import { MdSave, MdPlayForWork, MdModeEdit, MdSettings, MdDelete, MdGridOn, MdLockOutline, MdLockOpen } from "react-icons/md";
import Parse from '../../../widget/parse'

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export default class classSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // filter: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      filter: ['1', '2', '3', '4', '5', '9'],
      // useSelTimetable: false,
      currentSelArr: [],
      selValue: "0",
      teachers: [],
      classroom: [],
      loadingClassroom: true,
      optionsArr: [],
      teachersArrange: [],
    };
    // this.statNonTeachTeacher = this.statNonTeachTeacher.bind(this);
    // this.statTeacher = this.statTeacher.bind(this);
  }

  async componentDidMount() {
    this.handleFetch();

    const { firebase, companyObj } = this.props

    const data = await Parse.queryData('Classroom', { companyId: companyObj.objectId, isEnableClass: true }, { orderBy: 'classSchool' });
    this.setState({ classroom: data, loadingClassroom: false });

    // firebase.firestore().collection("Classroom")
    //   // .where('semesterYear', '==', semesterYear)
    //   // .where('semester', '==', semester)
    //   .where('isEnableClass', '==', true)
    //   .orderBy('classSchool')
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }));
    //     // console.log(data)
    //     this.setState({ classroom: data, loadingClassroom: false });
    //   });
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  };

  handleFetch = async (props = this.props) => {
    const { semesterYear, semester, companyObj } = props;
    // handleLazyLoad('teachersArrange');
    // db.collection("TeachersArrange")
    // .get().then(snap => {
    //   const data = [];
    //   snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }));
    //   this.setState({ teachersArrange: data, teachersArrangeL: true });
    // });
    const teachersArrange = await Parse.queryData('TeachersArrange', { companyId: companyObj.objectId });

    const currentSelArr = (teachersArrange.length && teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester && item.isUse === '1' && item.selArr) && teachersArrange.find(item => item.semesterYear === semesterYear && item.semester === semester && item.isUse === '1' && item.selArr).selArr) || [];
    this.setState({ teachersArrange, currentSelArr, teachersArrangeL: true })
    // handleLazyLoad('teachers');

    const teacherArr = await Parse.queryData('Teachers', { companyId: companyObj.objectId }, { orderBy: 'teacherNum' });
    // let teacherArr = teachers.sort((a, b) => a.teacherNum - b.teacherNum)
    this.setState({ teacherArr })
  }


  render() {
    const { formSet, optSet, students, semesterYear, semester, teachersArrange } = this.props;
    const { filter, currentSelArr, teachers, classroom, selValue } = this.state;


    const numYS = Number(semesterYear + (semester === 'S' ? '04' : '10'));
    const currentTeacher = teachers.filter(item => (!item.endSemesterNum || numYS <= item.endSemesterNum) && (!item.startSemesterNum || numYS >= item.startSemesterNum));
    // console.log(currentTeacher);

    return (
      <div style={{ padding: '0 25px' }}>
        <SchoolHour
          {...this.props}
          // history={history}
          classroom={classroom}
          // teachersArrange={this.state.currentSelArr}
          selArr={currentSelArr}
          teachers={currentTeacher}
          // semesterYear={semesterYear}
          // semester={semester}
          isEdit={false}
        // onSelect={this.handleSelectTeacher}
        // onClick={this.handleClickLockIcon}
        // students={students}
        />
      </div>);
  }
}
