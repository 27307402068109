// import React from 'react';
// import { Button } from 'semantic-ui-react';
// import XLSX from 'xlsx';
// import { MdOpenInNew } from "react-icons/md";
// import Moment from 'moment';

// // 將HTML table 轉成excel檔輸出，可自定義對像(idValue) / 按鈕顯示字(buttonDescription) / 輸出檔名(sheetName)
// const ExportTableToExcelButton = ({ idValue = 'excel',
//   // sheetName = 'sheetjs.xlsx', 
//   sheetName = Moment(new Date()).format("YYYY-MM-DD_HH-mm") + '_sheetjs.xlsx',
//   buttonDescription = 'EXCELとしてダウンロード', ...restProps }) => {
//   const handleClick = (idValue, sheetName) => {
//     // HTML to workbook
//     const wb = XLSX.utils.table_to_book(document.getElementById(idValue), { raw: true });

//     // 下載
//     XLSX.writeFile(wb, sheetName, { cellStyles: true });
//   }

//   return (
//     <Button onClick={() => handleClick(idValue, sheetName)} {...restProps}><MdOpenInNew size='1.2em' />{buttonDescription}</Button>
//   );
// };

// export default ExportTableToExcelButton;

import React from 'react';
import { Form, Button, Icon } from 'semantic-ui-react';
import XLSX from 'xlsx';
// import xlsx_style from 'xlsx-style';
// import { MdOpenInNew } from "react-icons/md";
import Moment from 'moment';

// 將HTML table 轉成excel檔輸出，可自定義對像(idValue) / 按鈕顯示字(buttonDescription) / 輸出檔名(sheetName)
const ExportTableToExcelButton = ({
  floated = 'left',
  // sheetName = 'sheetjs.xlsx', 
  // sheetName = Moment(new Date()).format("YYYY-MM-DD_HH-mm") + '資產負債表_sheetjs.xlsx',
  // sheetName = '',
  buttonDescription = 'EXCEL', ...restProps }) => { //EXCELとしてダウンロード
  const handleClick = (idValue = '_excel', sheetName = '') => {
    // HTML to workbook
    // const htmlstr = document.getElementById(idValue).outerHTML;
    let wb = {}
    idValue = idValue.split('_')[1]
    if (document.getElementById(idValue)) {
      wb = XLSX.utils.table_to_book(document.getElementById(idValue), { raw: true, display: true });
      if (sheetName) {
        sheetName = sheetName + '.xlsx'
      } else {
        sheetName = Moment(new Date()).format("YYYY-MM-DD_HH-mm") + '_sheetjs.xlsx'
      }
    } else {
      console.log('無擷取任何Table')
    }
    console.log(wb)
    // else if (document.getElementById(idValue2)) {
    //   wb = XLSX.utils.table_to_book(document.getElementById(idValue2), { raw: true, display: true });
    //   sheetName = wb.Sheets.Sheet1.B3.v + '_' + wb.Sheets.Sheet1.A2.v + '.xlsx'
    //   console.log(wb)
    // }
    // wb.Sheets.Sheet1.A3.v = {
    //   font: { sz: 13, bold: true, },
    //   alignment: {
    //     horizontal: "center", vertical: "center", wrap_text: true
    //   }
    // };

    // const wb2 = XLSX.utils.table_to_book(document.getElementById(idValue));
    // const wb3 = XLSX.read(htmlstr, {type:'string'});
    if (restProps.name === 'StudentsList') {
      ////在学生名簿 
      /// 找出有クラス的欄位位置
      var range = XLSX.utils.decode_range(wb['Sheets']['Sheet1']['!ref']);

      const rowNumArr = [];
      for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        const firstCell = XLSX.utils.encode_cell({ r: rowNum, c: 0 });
        // console.log(wb['Sheets']['Sheet1'][`${secondCell}`]); 
        if ((wb['Sheets']['Sheet1'][`${firstCell}`].v).indexOf('クラス') !== -1) {
          // console.log('res', rowNum)
          rowNumArr.push(rowNum);
        }
      }
      // console.log(rowNumArr);
      /// 一行6個處理
      for (let i = 0; i < rowNumArr.length; i++) {
        const start = rowNumArr[i];
        const end = (i === rowNumArr.length - 1) ? (range.e.r + 1) : rowNumArr[i + 1];
        const colName = 'ABCDEFGHIJKLMNOPQRSTUVWX'.split('');
        // console.log(start, end);

        let rowNow = (i >= 12) ? 50 : (i >= 6) ? 25 : 1; //
        for (let j = start; j < end; j++) {
          let obj = {}
          let obj2 = {}
          let obj3 = {}
          let obj4 = {}
          const firstCell = XLSX.utils.encode_cell({ r: j, c: 0 });
          const secCell = XLSX.utils.encode_cell({ r: j, c: 1 });
          const triCell = XLSX.utils.encode_cell({ r: j, c: 2 });
          const fourCell = XLSX.utils.encode_cell({ r: j, c: 3 });

          if (wb['Sheets']['Sheet1'][`${firstCell}`]) {
            obj = { t: wb['Sheets']['Sheet1'][`${firstCell}`].t, v: wb['Sheets']['Sheet1'][`${firstCell}`].v }
            delete wb['Sheets']['Sheet1'][`${firstCell}`];
            wb['Sheets']['Sheet1'][`${colName[(0 + 4 * i) % 24]}${rowNow}`] = obj;
          }
          if (wb['Sheets']['Sheet1'][`${secCell}`]) {
            obj2 = { t: wb['Sheets']['Sheet1'][`${secCell}`].t, v: wb['Sheets']['Sheet1'][`${secCell}`].v }
            delete wb['Sheets']['Sheet1'][`${secCell}`];
            wb['Sheets']['Sheet1'][`${colName[(1 + 4 * i) % 24]}${rowNow}`] = obj2;
          }
          if (wb['Sheets']['Sheet1'][`${triCell}`]) {
            obj3 = { t: wb['Sheets']['Sheet1'][`${triCell}`].t, v: wb['Sheets']['Sheet1'][`${triCell}`].v }
            delete wb['Sheets']['Sheet1'][`${triCell}`];
            wb['Sheets']['Sheet1'][`${colName[(2 + 4 * i) % 24]}${rowNow}`] = obj3;
          }
          if (wb['Sheets']['Sheet1'][`${fourCell}`]) {
            obj4 = { t: wb['Sheets']['Sheet1'][`${fourCell}`].t, v: wb['Sheets']['Sheet1'][`${fourCell}`].v }
            delete wb['Sheets']['Sheet1'][`${fourCell}`];
            wb['Sheets']['Sheet1'][`${colName[(3 + 4 * i) % 24]}${rowNow}`] = obj4;
          }
          rowNow++;
          // console.log(obj, obj2, obj3, rowNow);
        }
      }
      // 設定範圍
      wb['Sheets']['Sheet1']['!ref'] = "A1:Z309";
      // 清除原本格式設定
      delete wb['Sheets']['Sheet1']['!merges'];
    }

    // 下載
    XLSX.writeFile(wb, sheetName, { cellStyles: true });
  }

  return (
    <Button
      floated={floated}
      // color='green' 
      color='olive'
      size='small' className="no-print"
      onClick={(e, data) => handleClick(data.id, data.name, e, data)} {...restProps}><Icon name='download' />{buttonDescription}</Button>
  );
};

export default ExportTableToExcelButton;