
import React, { Component } from 'react'
import { Container, Header, Modal, Button, Table, Form, Grid, Image, Input, Checkbox, Divider, Tab } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import localization from 'widget/MaterialTableOpt'
import Parse from 'widget/parse'

const columns = () => ([
  // { width: 80, title: '日期', field: 'date', type: 'date' },
  { width: 80, title: '類型', field: 'type', lookup: { '1': 'bubble', '2': 'carousel', '3': 'text' } },
  { width: 80, title: '區塊', field: 'itemid' },
]);
// reactstrap components

class LineSetting extends Component {
  state = {
  }

  render() {
    const { companyObj } = this.props
    const { dataArr } = this.state

    return (
      <>
        <div className="content">
          <h3>LineSetting</h3>
          <MaterialTable
            // isLoading={loading}
            localization={localization()}
            columns={columns()}
            options={{
              addRowPosition: "first",
              exportButton: true,
              pageSize: 10,
              search: false,
              tableLayout: 'fixed'
            }}
            data={dataArr}
            title=''
            // actions={[
            //   {
            //     icon: 'view_headline',
            //     tooltip: '查看',
            //     onClick: (event, rowData) => this.handleData(rowData)
            //   }
            // ]}
            editable={{
              onRowAdd: newData =>
                new Promise(async (resolve, reject) => {
                  const data = [...dataArr];
                  const db = Parse.Object.extend("lineSetting");
                  const newDoc = new db();
                  const doc = await newDoc.save();

                  const obj = {
                    _id: doc.id,
                    id: doc.id,
                    objectId: doc.id,
                    companyId: companyObj.objectId,
                    ...newData
                  }
                  data.push(obj);
                  this.setState({ dataArr: data, newData: obj }, () => resolve());
                }).then(() => this.handleAdd()),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const data = [...dataArr];
                  const index = data.indexOf(oldData);
                  const obj = {
                    ...newData
                  }
                  data[index] = obj;
                  // handleState('people0', data);
                  this.setState({ dataArr: data, newData: obj, oldData }, () => resolve());
                }).then(() => this.handleUpdate()),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  let data = [...dataArr];
                  const index = data.indexOf(oldData);
                  data.splice(index, 1);
                  // handleState('people0', data);
                  this.setState({ dataArr: data, oldData }, () => resolve());
                }).then(() => this.handleDelete()),
            }}
          />
        </div>      </>
    );
  }
}

export default LineSetting;
