import React, { Component } from 'react'
import { Container, Icon, Table, Grid, Button, Modal, Header, Input, Label, Select } from 'semantic-ui-react'
import _ from 'lodash'
import { Link } from 'react-router-dom';
import Parse from 'widget/parse'

import MyModalTable from '../../widgets/MyModalTable';
import { getLabelName2, getOptionsByKey } from 'views/widgets/FieldsRender';
import ModalPdf from 'widget/pdf/ModalPdf';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipse: {
    // boxSizong: 'border-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}
const getLabel = (formSet, key) => {
  // return key;
  return formSet.find(item2 => item2.key === key) ? formSet.find(item2 => item2.key === key).label : key;
}
const retRTobj = (formSet, arrKey, width) => {
  return arrKey.map(item => {
    var form = formSet.find(obj => obj.key === item);
    if (!form) {
      return { title: item }
    } else {
      var id = item
      if (form.type === 'select') {
        id = `${item}Text`;
      }
      return { id, title: getLabel(formSet, item), width, type: form.type }
    }
  });
}

export class GraduateCollegeInvest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      author: '',
      graduates: [],
      graduateYear: `${Number(this.props.semesterYear) + 1}`,
      yearsemester: `${this.props.semesterYear},${this.props.semester}`
    }
  }
  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, match } = this.props
    // firebase.firestore()
    //   .collectionGroup('Graduate')
    //   // .orderBy('relationship')
    //   // .where('eventDate', '==', eventDate).where('classname', '==', className)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => data.push({ _id: item.id, ...item.data() }));
    //     // console.log(data);
    //     this.setState({ graduates: data });
    //   }, err => {
    //     console.log(`Encountered error: ${err}`);
    //   });
    const graduates = await Parse.queryData('Graduate', { companyId: companyObj.objectId })
    this.setState({ graduates })
  }
  groupBySchool(objectArray, property) {
    return objectArray.reduce(function (acc, obj) {
      var key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }
  handleChange = (event, data) => {
    let fieldName = data.name;
    let fleldVal = data.value;
    this.setState({ [fieldName]: fleldVal });
  }
  render() {
    const { firebase, match, history, formSet, optSet, students, semester, semesterYear } = this.props;
    const { graduates, yearsemester, graduateYear } = this.state;
    const semesterYearArr = getOptionsByKey(optSet, 'semesterYear').map(item => ({ key: item._id, text: item.label, value: item.value }));
    let year = yearsemester.substring(0, 4);
    let semes = yearsemester.substring(5);
    let loadAllCurrentSemesterYear = "1"; // if==1, 2018S => 2017S 2017A 2018S, if==0 2018S => 2018S
    // console.log(graduates)

    let row = graduates.filter(item => item.semesterYear === (Number(graduateYear) - 2 + '')).map(item => ({
      ...item,
      schoolArr: [item.targetSchoolText, item.targetSubject],
      studentArr: `${item.studentIdKey} / ${item.studentName} / ${item.semesterYear}.${item.semester} / ${item.isAdmittedText}`
    }))
    var grouped = this.groupBySchool(row, 'schoolArr');
    let groupedId = Object.keys(grouped);
    let groupedStudentArr = Object.values(grouped);
    let rows = groupedId.map((item, index) => ({
      studentId: groupedStudentArr[index][0].studentId,
      targetSchoolText: groupedStudentArr[index][0].targetSchoolText,
      targetSubject: groupedStudentArr[index][0].targetSubject,
      studentArr: groupedStudentArr[index].map((item2, index) => (<p key={'p' + index}>{item2.studentArr}</p>)),
      stuData: groupedStudentArr[index].map(item2 => item2.studentArr).join('\n')
    }))
    // console.log(rows)
    const semesterArr = [
      { key: '2019,A', text: `2019A`, value: '2019,A' },
      { key: '2019,S', text: '2019S', value: '2019,S' },
      { key: '2018,A', text: '2018A', value: '2018,A' },
      { key: '2018,S', text: '2018S', value: '2018,S' },
      { key: '2017,A', text: '2017A', value: '2017,A' },
      { key: '2017,S', text: '2017S', value: '2017,S' },
    ]
    return (
      <>

        <div style={{ ...style.flexCenter }}>
          <Header as='h2' style={{ margin: 0 }}>進路学校調査</Header>
          <div style={{ ...style.flexCenter }}>
            {/* <Input labelPosition='left' >
                <Label color={'blue'}> <Icon name='file alternate outline' />卒業生入学期</Label>
                <Select style={{
                  minWidth: '250px',
                  borderTopLeftRadius: '0',
                  borderBottomLeftRadius: '0'
                }} compact
                  options={semesterArr} onChange={this.handleChange} value={yearsemester} name='yearsemester' />
              </Input> */}
            <Input labelPosition='left' >
              <Label color={'blue'}> <Icon name='file alternate outline' />卒業年</Label>
              <Select style={{
                minWidth: '120px',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
                marginRight: 10
              }} compact
                options={semesterYearArr} onChange={this.handleChange} value={graduateYear} name='graduateYear' />
            </Input>
            <div style={{ ...style.flexCenter, minWidth: 220, marginLeft: 10 }}>
              <ExportTableToExcelButton size='small' />
              <ModalPdf
                {...this.props}
                // ref={(ref) => { this.pdfModal = ref }}
                codekey="graduateCollegeInvestPdf"
                params={rows.sort((a, b) => a.studentNum - b.studentNum)}
              />
            </div>
          </div>
        </div>
        <div style={{ maxWidth: '100vw', overflowX: 'scroll', marginTop: 25 }}>
          <Table id='excel' celled striped size='small' selectable>
            <Table.Header>
              <Table.Row textAlign='center'>
                {/* <Table.Cell></Table.Cell> */}
                <Table.HeaderCell style={style.textEllipse}>学校名</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>学科</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>学籍番号 / 氏名 / 学年.学期 / 錄取結果</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rows.sort((a, b) => a.studentNum - b.studentNum).map((item, index) => (<Table.Row key={'row' + index}>
                {/* <Link to={`/TeacherPage/${item._id}`}> */}
                {/* <Table.Cell style={style.textEllipse}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                      <Link to={`/TeacherPage/${item._id}`}><Button inverted={companyObj.inverted} color='yellow' size='small' style={{ margin: 15 }}>詳細</Button></Link>
                      <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} style={{ margin: 15 }}icon='edit' content='修正' />
                    </div>
                  </Table.Cell> */}
                <Table.Cell style={style.textEllipse}>{item.targetSchoolText}</Table.Cell>
                <Table.Cell style={style.textEllipse}>{item.targetSubject}</Table.Cell>
                <Table.Cell style={style.textEllipse}>{item.studentArr}</Table.Cell>
                {/* </Link> */}
              </Table.Row>))}
            </Table.Body>
          </Table>
        </div>

        {/* <Modal open={this.state.show} onClose={this.handleClose} size='tiny'>
          <Modal.Header style={style.flexCenter}>
            <h2 style={{ margin: 0 }}> {form._id ? form.teacherTitleText + ' ' + form.nameTeacher + ' 教師' : '教師'}</h2>
            <Icon name='close' onClick={this.handleClose} style={{ cursor: 'pointer', margin: 0 }}></Icon>
          </Modal.Header>
          <Modal.Content>
            <Grid>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>状態</label>
                  <Form.Select
                    options={isWorkArrModal}
                    defaultValue={form._id ? form.staffStatus : ' '}
                    onChange={this.handleChange}
                    name='staffStatus'
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>教員番号</label>
                  <Form.Input
                    style={{ minWidth: 196 }}
                    name='teacherNum'
                    value={form.teacherNum ? form.teacherNum : ' '}
                    onChange={this.handleChange}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>氏名 (簡稱)</label>
                  <Form.Input
                    style={{ minWidth: 196 }}
                    name='nameTeacher'
                    value={form.nameTeacher ? form.nameTeacher : ''}
                    onChange={this.handleChange}
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>氏名 (全名)</label>
                  <Form.Input
                    style={{ minWidth: 196 }}
                    name='nameTeacherFull'
                    value={form.nameTeacherFull || ''}
                    onChange={this.handleChange}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>職名</label>
                  <Form.Select
                    options={staffNameArr}
                    defaultValue={form._id ? form.teacherTitle : ' '}
                    onChange={this.handleChange}
                    name='teacherTitle'
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>専任/非専任</label>
                  <Form.Select
                    options={staffProArr}
                    defaultValue={form._id ? form.partTimeTeacher : ' '}
                    onChange={this.handleChange}
                    name='partTimeTeacher'
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns='equal'>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>就職学期</label>
                  <Form.Select
                    options={semesterArrStart}
                    onChange={this.handleChange}
                    name='startSemesterNum'
                    defaultValue={form.startSemester || ' '}
                  />
                </Grid.Column>
                <Grid.Column>
                  <label style={{ display: 'block', marginBottom: 5 }}>離職学期</label>
                  <Form.Select
                    options={semesterArrEnd}
                    onChange={this.handleChange}
                    name='endSemesterNum'
                    defaultValue={form.endSemester || ' '}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Content style={{ borderTop: '1px solid rgba(34,36,38,.15)', display: 'flex', justifyContent: 'flex-end' }}>
            <Button size='small' onClick={this.handleSubmit} inverted={companyObj.inverted} color='green' loading={loading} icon='checkmark' content={form._id ? '保存' : '追加'} />
            <Button size='small' onClick={this.handleClose} basic>閉じる </Button>
          </Modal.Content>
        </Modal> */}
        {/* <Confirm
          header='削除確認'
          content='削除確認?'
          cancelButton='閉じる'
          confirmButton='削除'
          open={this.state.confirm}
          onCancel={this.hideConfirm}
          onConfirm={this.handleRemove}
          size='mini'
          centered={false} /> */}
      </>
    )
  }
}
export default GraduateCollegeInvest