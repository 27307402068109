
import React, { Component } from 'react'
import { Container, Header, Modal, Table, Form, Grid, Image, Input, Checkbox, Divider, Tab } from 'semantic-ui-react';
import { Row, Col, Button } from 'reactstrap';

import Parse from 'widget/parse'
import ModalRichMenuLayout from './ModalRichMenuLayout';
import { fetchData } from 'widget/http2';

const webtypeSel = [
  { id: 'dorm', text: '套房出租' },
  { id: 'healthy', text: '健康管理' },
  { id: 'restaurant', text: '餐廳' },
  { id: 'clothes', text: '服飾' },
  { id: 'makeup', text: '美妝美容' },
  { id: 'temple', text: '佛教' },
  { id: 'dao', text: '道教' },
  { id: 'hanbury', text: '漢栢略' },
  // { id: 'publishing', text: '出版社' },
  // { id: 'pawnshop', text: '當舖' },
  // { id: 'autorepair', text: '修車廠' },
  // { id: 'salesInventory', text: '銷貨庫存' },
  // { id: 'crm', text: '客戶關係' },
  // { id: 'official', text: '官網' },
  // { id: 'onlinetest', text: '線上測驗' },
  { id: 'school', text: '日語學校' },
  // { id: 'streaming', text: '網路電視' },
  // { id: 'develop', text: '開發用' },
];

const rankArr = [
  { rankText: '老闆', btnColor: 'info', rankId: '1' },
  { rankText: '員工', btnColor: 'warning', rankId: '2' },
  { rankText: '客戶', btnColor: 'danger', rankId: '3' },
  { rankText: '訪客', btnColor: 'secondary', rankId: '4' },
]

const richMenuDefault = (bgId, level, rankId) => ({
  "size": {
    "width": 1080,
    "height": 720
  },
  "selected": true,
  "name": "雲辦公智能選單",
  // "chatBarText": "開啟智能選單",
  "areas": [
    {
      "bounds": {
        "x": 0,
        "y": 0,
        "width": 360,
        "height": 360
      },
      "action": {
        "type": "postback",
        "data": `bgId=${bgId}&level=${level}&rankId=${rankId}&itemid=1`
      }
    },
    {
      "bounds": {
        "x": 360,
        "y": 0,
        "width": 360,
        "height": 360
      },
      "action": {
        "type": "postback",
        "data": `bgId=${bgId}&level=${level}&rankId=${rankId}&itemid=2`
      }
    },
    {
      "bounds": {
        "x": 720,
        "y": 0,
        "width": 360,
        "height": 360
      },
      "action": {
        "type": "postback",
        "data": `bgId=${bgId}&level=${level}&rankId=${rankId}&itemid=3`
      }
    },
    {
      "bounds": {
        "x": 0,
        "y": 360,
        "width": 360,
        "height": 360
      },
      "action": {
        "type": "postback",
        "data": `bgId=${bgId}&level=${level}&rankId=${rankId}&itemid=4`
      }
    },
    {
      "bounds": {
        "x": 360,
        "y": 360,
        "width": 360,
        "height": 360
      },
      "action": {
        "type": "postback",
        "data": `bgId=${bgId}&level=${level}&rankId=${rankId}&itemid=5`
      }
    },
    {
      "bounds": {
        "x": 720,
        "y": 360,
        "width": 360,
        "height": 360
      },
      "action": {
        "type": "postback",
        "data": `bgId=${bgId}&level=${level}&rankId=${rankId}&itemid=6`
      }
    },
  ]
})

class LineRichMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataArr: [],
      loading: true,
    }
    this.konvaRef = React.createRef();
  }

  async componentDidMount() {
    const dataArr = await Parse.queryData('richMenu');
    this.setState({ dataArr, loading: false });
  }

  handleSave = async (e, form, imageFile = null) => {
    if (!form.objectId) {
      const db = Parse.Object.extend("richMenu");
      const newDoc = new db();
      const doc = await newDoc.save();
      form.objectId = doc.id;
      form.id = doc.id;
      form._id = doc.id;
    }

    const { bgId, level, rankId } = form;
    if (imageFile) {
      // console.log('save imageFile', imageFile)

      if (imageFile.size) {
        const res = await Parse.putFile(imageFile, 'image1File').catch((error) => {
          console.error(error.code + " " + error.message);
        }); // 單純存檔
        form = { ...richMenuDefault(bgId, level, rankId), ...form, 'image1File': res };
      } else {
        const parseFile = new Parse.File('image1File', { base64: imageFile }, "image/png");
        const res = await parseFile.save().catch(err => console.error(err));
        form = { ...richMenuDefault(bgId, level, rankId), ...form, 'image1File': res };
      }
    } else {
      form = { ...richMenuDefault(bgId, level, rankId), ...form };
    }

    const { dataArr } = this.state;
    const data = [...dataArr];
    const index = data.findIndex(item => item.objectId === form.objectId);
    if (index !== -1) {
      data[index] = form;
    } else {
      data.push(form)
    }

    this.setState({ dataArr: data })

    delete form.createdAt;
    delete form.updatedAt;
    if (form.image1File && form.image1File.__type === 'File') {
      delete form.image1File;
    }

    // console.log(form)
    await Parse.saveData('richMenu', form);
  }

  handleRestart = async () => {
    const data = {}
    const promises = [];
    // const res = await fetchData(`https://parse.infowin.com.tw/push/richmenu/delall`, data)
    // console.log(res);
    // const res1 = await fetchData(`https://parse.infowin.com.tw/push/richmenu/createAllRichMenu`, data)
    // console.log(res1);
    const channelArr = [{ channelId: '1654001919' }]
    const dataArr = await Parse.queryData('company');
    dataArr.filter(item => item.channelId).forEach(item => {
      channelArr.push({ channelId: item.channelId })
    })
    channelArr.forEach(async (item) => {
      const promise = new Promise(async (resolve) => {
        const res = await fetchData(`https://parse.infowin.com.tw/push/richmenu/delall/${item.channelId}`, data)
        console.log(res);
        const res1 = await fetchData(`https://parse.infowin.com.tw/push/richmenu/create/${item.channelId}`, data)
        console.log(res1);
        //     // const res = await fetchData(`https://parse.infowin.com.tw/push/richmenu/delall`, data)
        //     // .catch ((err) => {
        //     // window.alert('錯誤 ' + err);
        //     // console.error(err);
        //     // });
        //     resolve();
      })
      promises.push(promise);
    })
    // // console.log(promises);
    await Promise.all(promises).then(res => console.log(res), alert('已更新')).catch(error => console.log("error", error));

    //  const res = await fetchData(`https://parse.infowin.com.tw/push/richmenu/createㄥ`, data)

    // console.log(res);
    // if (res && res.ok) {
    //   // Parse.saveData('LineMessage', { channelId: '1654652629', direction: '2', sourceId: chatUserObj.lineId, type: 'text', text: pushValue, sendId: chatUserObj.lineId, sendLineDisplayName: chatUserObj.lineDisplayName })
    //   alert('已更新')
    // }
  }

  handleOneRestart = async (bgId) => {
    const data = { bgId }
    const promises = [];
    const channelArr = [{ channelId: '1654001919' }]
    const dataArr = await Parse.queryData('company', { webtypeId: bgId });
    dataArr.filter(item => item.channelId).forEach(item => {
      channelArr.push({ channelId: item.channelId })
    })
    channelArr.forEach(async (item) => {
      //   const promise = new Promise(async (resolve) => {
      await fetchData(`https://parse.infowin.com.tw/push/richmenu/delete/${item.channelId}`, data)
      //     await fetchData(`https://parse.infowin.com.tw/push/richmenu/createOneRichMenu/${item.channelId}/${bgId}`, data)
      //       // const res = await fetchData(`https://parse.infowin.com.tw/push/richmenu/delall`, data)
      //       .catch((err) => {
      //         window.alert('錯誤 ' + err);
      //         console.error(err);
      //       });
      //     resolve();
    })
    //   promises.push(promise);
    // })
    await Promise.all(promises).then(res => alert('已更新')).catch(error => console.log("error", error));
  }

  render() {
    const { dataArr } = this.state;
    return (<div className="content" >
      <Header as='h2'>
        圖文選單
        {/* <Header.Subheader>您可建立具有視覺效果的互動型選單，於聊天室中提供優惠券、網址連結及相關行銷資訊以進行宣傳。</Header.Subheader> */}
        <Button color={'link'} onClick={this.handleRestart} size="sm">更新Line伺服器</Button>
      </Header>
      <a
        target="_blank"
        href="https://fontawesome.com/icons?d=gallery"
        rel="noopener noreferrer"
        style={{ fontSize: '16px', color: '#000' }}
      >Icon參考網址</a>
      {/* <br /> */}
      {/* 行業別 */}
      { webtypeSel.map(({ id, text }, i) => (<React.Fragment key={`richmenu${i}`}>
        <Header as='h2'>{id} {text}
          {/* <Button color={'link'} onClick={() => this.handleOneRestart(id)} size="sm">更新{text}Line伺服器</Button> */}
        </Header>
        <Grid columns={4} divided>
          <Grid.Row>
            {rankArr.map((item, index) =>
              <Grid.Column key={`header${index}`}>
                <Header as='h4'>{item.rankText}</Header>
              </Grid.Column>)}
          </Grid.Row>
          <Grid.Row>
            {/* 老闆 員工 客戶 */}
            {rankArr.map(({ rankText, btnColor, rankId }, index) =>
              <Grid.Column key={`layout${index}`}>
                <ModalRichMenuLayout
                  btnColor={btnColor}
                  parentId=''
                  level={'0'}
                  rankId={rankId}
                  rankText={rankText}
                  bgId={id}
                  bgText={text}
                  dataArr={dataArr}
                  handleSave={this.handleSave}
                  handleChangeFile={this.handleChangeFile}
                />
              </Grid.Column>)}
          </Grid.Row>
        </Grid>
        <br />
        <br />
      </React.Fragment>))
      }
    </div >);
  }
}

export default LineRichMenu;
