import React, { Component } from 'react'
import { Button, Grid, Form } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import { Select, MenuItem } from '@material-ui/core';

// import ClientPawning2 from './ClientPawning2'
import Parse, { funcPad } from '../../widget/parse'
import localization from 'widget/MaterialTableOpt'
// import ModalEmployeelist from './ModalEmployeelist';
import { dateCol, typeCurrency, exportPdf } from 'views/web/CustomMaterialTable';
// import { ReorgPawning12, payTypeSel } from './ReorgPawning12';

// 2 總帳
const columns = ({ ordernum = '', employee = [] }) => ([
  {
    width: 80, title: '單號', field: 'ordernum', initialEditValue: ordernum, defaultSort: 'desc'
  },
  dateCol({ title: '日期', field: 'startDate' }),
  dateCol({ title: '下次追蹤日期', field: 'planDate' }),
  { width: 80, title: '備註', field: 'ps' },

]);

export default class ClientPawning1 extends Component {
  state = {
    data1: [],
    data2: [],
    newData1: {},
    oldData1: {},
    loading1: false,
    loading2: false,
    employee: [],
    pawnTypeAll: { sel: {}, arr: [] },
    companyObj: {},
  }

  componentDidMount = async () => {
    // console.log('componentDidMount')
    const { companyObj } = this.props
    const companyId = companyObj.objectId;
    const employee = await Parse.queryData('User', { authLoginA: companyId }); // 先抓所有的員工
    this.setState({ employee, companyObj }, () => this.handleParams());
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps')
    this.handleParams(nextProps);
  }

  handleParams = async (props = this.props) => {
    const { match } = props;
    const { companyObj } = this.state
    const { t1, clientId } = match.params;
    console.log(t1)
    console.log(clientId)
    this.setState({ loading1: true, rowData: {} })
    // console.log('handleParams', t1, clientId)
    if (typeof t1 !== 'undefined' && clientId) {
      const companyId = companyObj.objectId;
      const data1 = await Parse.queryData('clientTracking', { companyId, clientId }, { orderBy: 'startDate_descend' }); // 有客戶的id的話 就抓這個人的所有借據
      this.setState({ data1, data2: [], loading1: false }, () => this.handleFetch(props))
    }
  }

  handleFetch = (props = this.props) => {
    const { match } = props;
    const { t1Id, } = match.params;
    const { data1 } = this.state

    if (typeof t1Id !== 'undefined') {
      const pawning1Obj = data1.find(item => item.objectId === t1Id);
      const pawning1Id = (pawning1Obj && pawning1Obj.objectId) || ''
      if (pawning1Id && pawning1Obj.startDate && pawning1Obj.principal && pawning1Obj.interest) {
        this.setState({ rowData: pawning1Obj, loading2: true }, async () => await this.reorgPawning12());
      }
    } else {
      this.setState({ loading2: false })
    }
  }

  handleData = (rowData) => {
    const { history, match, clientObj } = this.props;
    const { t1 = '' } = match.params;
    this.setState({ rowData })
    if (rowData.objectId) {
      history.push(`/admin/CrmClient/${clientObj.objectId}/${t1}/${rowData.objectId}`);
    }
  }

  reorgPawning12 = async () => {
    // const { companyObj, data1, rowData } = this.state
    // // const { p1Obj, p2Arr } = await ReorgPawning12({ companyObj, pawning1Obj: rowData });

    // const data = [...data1];
    // const index = data.findIndex(item => item.objectId === p1Obj.objectId);
    // data[index] = p1Obj;
    // this.setState({ rowData: p1Obj, data1: data, data2: p2Arr, loading1: false, loading2: false });
  }

  handleAdd = async () => { // pawning1 add
    const { history, match, clientObj } = this.props
    const { t1 = '' } = match.params;
    const { newData1, companyObj } = this.state
    console.log(newData1)
    // 這是給 company 的
    const obj = { ...companyObj, ordernumDef: String(Number(companyObj.ordernumDef) + 1) };
    await Parse.saveData('company', obj);
    await Parse.saveData('clientTracking', newData1);
    this.setState({ rowData: newData1, loading1: true }, () => history.push(`/admin/CrmClient/${clientObj.objectId}/${t1}/${newData1.objectId}`));
  }

  handleUpdate = async () => {
    const { newData1 } = this.state
    await Parse.saveData('clientTracking', newData1);
    this.setState({ rowData: newData1 }, () => this.reorgPawning12());
    this.setState({ oldData1: {}, newData1: {} })
  }

  handleDelete = async () => {
    const { history, clientObj, match } = this.props
    const { t1 = '' } = match.params;
    const { oldData1 } = this.state
    // await Parse.destroyData('pawning2', { pawning1Id: oldData1.objectId });
    await Parse.deleteData('clientTracking', oldData1);

    this.setState({ oldData1: {} }, () => history.push(`/admin/CrmClient/${clientObj.objectId}/${t1}`));
  }

  render() {
    const { clientObj } = this.props
    const { data1, data2, loading2, companyObj, loading1, employee, rowData } = this.state;
    const { orderalphaDef, ordernumDef } = companyObj;
    const ordernum = orderalphaDef + funcPad(Number(ordernumDef) + 1, 8);

    // console.log('data1', data1)
    return (<>
      <Grid >
        <Grid.Row columns='equal'>
          <Grid.Column width={14}>
            <MaterialTable
              isLoading={loading1}
              localization={{
                header: { actions: '操作' },
              }}
              title={clientObj.name + ' 客戶追蹤內容'}
              columns={columns({ ordernum, employee })}
              options={{
                addRowPosition: "first",
                exportButton: true,
                exportPdf: (columns, data) => exportPdf(columns, data, clientObj.name + ' 客戶追蹤內容'),
                pageSize: 5,
                search: false,
                tableLayout: 'fixed',
                addRowPosition: 'first'
              }}
              data={data1}
              // actions={[
              //   {
              //     icon: 'view_headline',
              //     tooltip: '查看',
              //     onClick: (event, rowData) => this.handleData(rowData)
              //   },
              //   // {
              //   //   icon: 'file_copy',
              //   //   tooltip: '複製',
              //   //   onClick: (event, rowData) => this.handleDuplicate(rowData)
              //   // }
              // ]}
              // onRowClick={(event, rowData) => this.handleData(rowData)}
              editable={{
                onRowAdd: newData1 =>
                  new Promise(async (resolve, reject) => {
                    const data = [...data1];
                    const db = Parse.Object.extend("clientTracking");
                    const newDoc = new db();
                    const doc = await newDoc.save();

                    const obj = {
                      ...newData1,
                      objectId: doc.id,
                      id: doc.id,
                      _id: doc.id,
                      clientId: clientObj.objectId,
                      clientName: clientObj.name,
                      companyId: companyObj.objectId,
                      companyName: companyObj.name,
                      // pawnTypeText: pawnTypeAll.sel[newData1.pawnTypeId],
                      // payTypeText: payTypeSel[newData1.payTypeId],
                    }
                    data.push(obj);
                    this.setState({ data1: data, newData1: obj }, () => resolve());
                  }).then(() => this.handleAdd()),
                onRowUpdate: (newData1, oldData1) =>
                  new Promise((resolve, reject) => {
                    const obj = {
                      ...newData1,
                      clientName: clientObj.name,
                      // pawnTypeText: pawnTypeAll.sel[newData1.pawnTypeId],
                      // payTypeText: payTypeSel[newData1.payTypeId],
                    }
                    const data = [...data1];
                    const index = data.indexOf(oldData1);
                    data[index] = obj;
                    this.setState({ data1: data, newData1: obj, oldData1 }, () => resolve());
                  }).then(() => this.handleUpdate()),
                onRowDelete: oldData1 =>
                  new Promise((resolve, reject) => {
                    let data = [...data1];
                    const index = data.indexOf(oldData1);
                    data.splice(index, 1);
                    this.setState({ data1: data, oldData1 }, () => resolve());
                  }).then(() => this.handleDelete()),
              }}
            />
          </Grid.Column>
          {/* <Grid.Column width={2}>
            {rowData&& rowData.objectId ? <>
              <ModalPdf {...this.props} title='繳款單' nowform={'80'} params={{ clientObj, pawning1Obj, companyObj: companyObj }} />
              <br />
              <ModalPdf {...this.props} title='收據' nowform={'81'} params={{ clientObj, pawning1Obj, companyObj: companyObj }} />
              <br />
            </> : null}
            {loading2 ? <DimmerImg /> : null}
          </Grid.Column> */}
        </Grid.Row>
        {/* <Grid.Row columns='equal'>
          <Grid.Column width={16}>
            {rowData && rowData.objectId ? <div style={{ maxWidth: '100%' }}>
              <ClientPawning2
                {...this.props}
                loading2={loading2}
                pawning1Obj={rowData}
                data2={data2}
                reorgPawning12={this.reorgPawning12}
              />
            </div> : null}
          </Grid.Column>
        </Grid.Row > */}
      </Grid>
    </>)
  }
}