import React, { Component } from 'react'
import { Container, Header, Table, Form, Menu } from 'semantic-ui-react';
import Hr4a from './Hr4a';
import Hr4b from './Hr4b';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'


const th = [
  { text: '工號' },
  { text: '員工姓名' },
  { text: '就職日' },
  { text: '年資' },
]

const leaveTh = [
  { text: '年度' },
  { text: '有效日期(起)' },
  { text: '有效日期(迄)' },
  { text: '可用特休' },
  { text: '已用特休' },
  { text: '剩餘特休' },
  { text: '本年度補休' },
]
const applyTh = [
  { text: '狀態' },
  { text: '請假類別' },
  { text: '開始時間' },
  { text: '結束時間' },
  { text: '總時數' },
  { text: '事由' },
  { text: '職務代理人' },
  { text: '主管' },
  { text: '人資' },
]
const avalibleTh = [
  { text: '狀態' },
  { text: '公假' },
  { text: '公傷病假' },
  { text: '事假' },
  { text: '病假' },
  { text: '傷假' },
  { text: '婚假' },
  { text: '產假' },
  { text: '生理假' },
  { text: '陪產' },
  { text: '育嬰假' },
  { text: '補休假' },
]
const historyTh = [
  { text: '狀態' },
  { text: '請假類別' },
  { text: '開始時間' },
  { text: '結束時間' },
  { text: '總時數' },
  { text: '事由' },
  { text: '審核回覆' },
  { text: '職務代理人' },
  { text: '主管' },
  { text: '人資' },
]
// const yearOpt2 = [
//   { key: 'yearOptAll', value: 'all', text: '所有年度' },
//   { key: 'yearOpt2016', value: '2016', text: '2016' },
//   { key: 'yearOpt2017', value: '2017', text: '2017' },
//   { key: 'yearOpt2018', value: '2018', text: '2018' },
//   { key: 'yearOpt2019', value: '2019', text: '2019' },
//   { key: 'yearOpt2020', value: '2020', text: '2020' },
// ]

const objDay_offSel = {
  "1": "公假", "2": "公傷病假", "3": "事假", "4": "病假",
  "5": "婚假", "6": "傷假", "7": "產假", "8": "生理假", "9": "陪產假", "10": "特休假",
  "11": "育嬰假", "12": "補休假",
};

const objDay_offSel2 = {
  "13": "加班申請", "14": "補休申請"
};

const objDay_off_status_first = { '0': '已拒絕', "1": "填寫中", "2": "代理人覆核", "3": '主管覆核', "4": '人資覆核', '5': '已通過', '6': '總經理覆核' };
const menu = [
  { id: 'hr4a', content: '4-4-1 申請' },
  { id: 'hr4b', content: '4-4-2 回覆' }
]
// const yearOpt = yearOpt2.slice(1, yearOpt2.length)

let nowYear = String(new Date().getFullYear())

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  thStyle: {
    background: '#f9fafb',
    fontWeight: 700
  },
  tableTitle: {
    // padding: 8,
    // margin: 0,
    // color: '#8a6d3b',
    // background: '#fcf8e3',
    margin: '10px 0'
  }
}
export default class Hr4 extends Component {
  state = {
    loginUser: this.props.user.profile.toJSON(),
    dataArr: [],
    leaveArr: [],
    applyArr: [],
    avalibleArr: [],
    historyArr: [],
    show: false,
    staffArr: [{ key: 'stafArrnull', text: '-- 選擇員工 --', value: '-1' }],
    staffVal: '-1',
    deleteConfirm: false,
    form: {},
    yearVal1: '',
    yearVal2: 'all',
    activeItem: 'hr4a',
    employee: [],
    employeeObj: {},
    dayoffArr: [],
    auth_substitutegoSel: {},
    auth_generalmanagerSel: {},
    yearOpt: [],
  }
  componentDidMount() {
    // const { loginUser } = this.state
    // const { companyObj } = this.props
    // const companyId = companyObj.objectId
    // const authPage = window.location.pathname.split('/')[2]
    // const authUser = companyObj.objectId + '_' + authPage
    // let auth = {}
    // if ((loginUser.authAdminA&&loginUser.authAdminA.indexOf(companyId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
    //   auth = {
    //     edit: true,
    //   }
    // } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
    //   auth = {
    //     edit: false,
    //   }
    // } else {
    //   auth = {
    //     edit: false,
    //   }
    // }
    // this.setState({ auth }, () => this.handleFetch())
    this.handleFetch()
  }
  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }
  handleFetch = async (props = this.props) => {
    const { match, companyObj, user } = props;
    const companyId = companyObj.objectId;
    // const fakeUserId = 'ljv6XmJcg5'
    // const db = firebase.firestore();
    // const email = firebase.auth().currentUser.email;
    // console.log(user.profile.id)
    // console.log(props)
    // const snap = await db.collection(`users`).where("authUserA", "array-contains", companyObj._id).get()
    // .catch(err => { console.log('Error getting document', err); });
    // const employee = await Parse.queryData('User', { authUserA: companyObj.objectId })
    const employee = await Parse.Cloud.run('getEmailsInCompany', { companyId })
    // snap.forEach(item => employee.push({ _id: item.id, ...item.data() }));
    // console.log(employee)
    // let email = employee && employee.find(item => item.objectId === user.profile.id) && employee.find(item => item.objectId === user.profile.id).email
    const employeeObj = (employee && employee.find(item => item.objectId === user.profile.id)) || {};
    // const snap2 = await db.collectionGroup(`mgyear`).where('companyId', '==', companyObj._id).get();
    const year = await Parse.queryData('mgyear', { companyId })
    // snap2.forEach(item => year.push({ _id: item.id, ...item.data() }));
    const yearAll = year.map(item => ({ key: item.objectId, value: item.value_AD, text: `${item.value_AD}` }))
    let now = String(new Date().getFullYear())
    const yearVal1 = (yearAll.find(item => item.value === now) && yearAll.find(item => item.value === now).value) || ''
    // yearAll.unshift({ key: 'yearOptAll', value: 'all', text: '所有年度' })
    // const snap2 = await db.collection(`company/${companyObj._id}/bg/${employeeObj.bgId}/employee/${employeeObj._id}/dayoff`).get();
    // const dayoffArr = [];
    // snap2.forEach(item => dayoffArr.push({ _id: item.id, ...item.data() }));
    // const auth_substitutegoArr = employee.filter(item => item.auth_substitutego === '1' && item.authLoginA && item.authLoginA.indexOf(companyObj._id) !== -1); // 代理人權限
    // const auth_supervisorgoArr = employee.filter(item => item.auth_supervisorgo === '1' && item.authLoginA && item.authLoginA.indexOf(companyObj._id) !== -1); // 放行主管權限
    // const auth_generalmanagerArr = employee.filter(item => item.auth_generalmanagergo === '1' && item.authLoginA && item.authLoginA.indexOf(companyObj._id) !== -1); // 放行總經理權限
    // const auth_hrgoArr = employee.filter(item => item.auth_substitutego === '1' && item.authLoginA && item.authLoginA.indexOf(companyObj._id) !== -1); // 放行人資權限
    // let auth_substitutegoSel = {};
    // auth_substitutegoArr.forEach(item => auth_substitutegoSel = { ...auth_substitutegoSel, [item._id]: item.name });
    // let auth_supervisorgoSel = {};
    // auth_supervisorgoArr.forEach(item => auth_supervisorgoSel = { ...auth_supervisorgoSel, [item._id]: item.name });
    // let auth_generalmanagerSel = {};
    // auth_generalmanagerArr.forEach(item => auth_generalmanagerSel = { ...auth_generalmanagerSel, [item._id]: item.name });
    // let auth_hrgoSel = {};
    // auth_hrgoArr.forEach(item => auth_hrgoSel = { ...auth_hrgoSel, [item._id]: item.name });
    this.setState({ employee, employeeObj, yearOpt: yearAll, yearVal1 });
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  showApprove = () => {
    const { show } = this.state
    if (show)
      this.setState({ show: false, })
    else
      this.setState({ show: true, })
  }

  handleItemClick = (e, { name }) => {
    // const { activeItem, activeItem2 } = this.state
    // console.log(bgId)
    // const menu2 = (menu.find(item => item.id === name) && menu.find(item => item.id === name).menu) || [];
    // this.props.history.push(`/Financial1/${bgId}/${name}/${menu2[0].id}`);
    this.setState({ activeItem: name })
  }

  handleAdd = async (obj) => {
    const { employee } = this.props
    const { employeeObj } = this.state
    // console.log(obj)
    // const db = firebase.firestore();
    // const docRef = db.collection(`users/${employee._id}/dayoff`).doc(obj.id);
    // await docRef.set(obj);
    await Parse.saveData('dayoff', obj);
    // this.setState({ newData: {} });
  }

  handleUpdate = async (obj) => {
    const { employee } = this.props
    const { employeeObj } = this.state
    // const db = firebase.firestore()
    // const docRef = db.collection(`users/${employee._id}/dayoff`).doc(obj.id);
    // await docRef.update(obj);
    await Parse.saveData('dayoff', obj);
    // this.setState({ oldData: {}, newData: {} })
  }

  handleDelete = async (obj) => {
    const { employee } = this.props
    const { employeeObj } = this.state
    // const db = firebase.firestore()
    await Parse.deleteData('dayoff', obj);
    // await db.collection(`users/${employee._id}/dayoff`).doc(obj).delete();
  }

  render() {
    const { companyObj } = this.props
    const { dataArr, employeeObj, yearVal1, yearVal2, activeItem, auth_substitutegoSel, auth_supervisorgoSel, auth_hrgoSel, dayoffArr, auth_generalmanagerSel, employee, yearOpt, auth = {} } = this.state;
    // console.log(employeeObj);
    // console.log(yearOpt, yearOpt2)
    const extProps = {
      employeeObj, auth_substitutegoSel, auth_supervisorgoSel, auth_hrgoSel, objDay_offSel, auth_generalmanagerSel, employee, yearVal1, yearOpt, objDay_offSel2,
      dayoffArr, objDay_off_status_first,
      auth,
    }
    let now = new Date().getTime()
    let onbroad = new Date(employeeObj.onbroad_date).getTime()
    let seniorityYear = parseInt((now - onbroad) / 1000 / 60 / 60 / 24 / 365)
    let seniorityMonth = parseInt(((now - onbroad) - (seniorityYear * 86400000 * 365)) / 1000 / 60 / 60 / 24 / 30)
    if (seniorityMonth < 0) {
      seniorityMonth = 0
    }
    return (<>
      <div className="content">
        <div style={{ ...style.flexCenter, position: 'relative', zIndex: 100 }}>
          {/* <Header as='h2' style={{ margin: '2rem 0' }}>4-3 差勤申請</Header> */}
          <div style={{ ...style.flex }}>
            <Form inverted={companyObj.inverted}>
              <Form.Select label='年度' inline options={yearOpt} value={yearVal1} name='yearVal1' onChange={this.handleChange} />
            </Form>
            {/* <Button content='人事規章' as={Link} to={``} icon='address book' /> */}
          </div>
        </div>
        <div>
          {/* <Button content='預假通知' color='yellow' icon='bell' toggle active={show} onClick={this.showApprove} /> */}
        </div>
        {/* <Header as='h3' content='證件檔案 (上傳護照、身分證檔案)' style={{ ...style.tableTitle }} />  */}
        <br />
        <Table celled textAlign='center' style={{ margin: '0 0 2rem' }}>
          <Table.Header>
            <Table.Row>
              {th.map((item, index) => <Table.HeaderCell key={`dataTh${index}`}>{item.text}</Table.HeaderCell>)}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {employeeObj ?
              <Table.Row key={`dataTr`}>
                <Table.Cell>{employeeObj.jobNum}</Table.Cell>
                <Table.Cell>{employeeObj.name}</Table.Cell>
                <Table.Cell>{employeeObj.onbroad_date || '未輸入'}</Table.Cell>
                <Table.Cell>{`${seniorityYear}年${seniorityMonth}月`}</Table.Cell>
              </Table.Row>
              : <Table.Row><Table.Cell colSpan={th.length}>暫無資料</Table.Cell></Table.Row>}
          </Table.Body>
        </Table>
        {/* menu */}
        <Menu pointing>
          {menu.map(({ id, content }) =>
            <Menu.Item
              key={id}
              name={id}
              // activeIndex={activeIndex}
              content={content}
              active={activeItem === id}
              onClick={this.handleItemClick}
            />)}
        </Menu>
        <br />
        {activeItem === 'hr4a' ? <Hr4a {...this.props} {...extProps} /> : null}
        {activeItem === 'hr4b' ? <Hr4b {...this.props} {...extProps} /> : null}
      </div>
    </>)
  }
}