import React, { Component } from 'react'
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
import { Container, Header, Form, Icon, Button } from 'semantic-ui-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import PageHeader from 'components/Headers/PageHeader';
import { ListGroup, ListGroupItem } from 'reactstrap';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import PreloadFile from '../../views/web/PreloadFile'
import { readFileAsURL } from '../../views/web/RowImage';

export class ModalEditContact extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
      show: false,
      imagePreviewUrlArr: [],
      photo: '',
      form: {},
      addressText: '',
      phoneText: '',
      timeText: '',
      emailText: ''
    };
  }

  componentDidMount = () => {
    const { form } = this.state
    const addressText = (document.getElementById('address').innerHTML) || ''
    const phoneText = (document.getElementById('phone').innerHTML) || ''
    const timeText = (document.getElementById('time').innerHTML) || ''
    const emailText = (document.getElementById('email').innerHTML) || ''
    this.setState({ form: { ...form, newAddress: addressText, newPhone: phoneText, newTime: timeText, newEmail: emailText } })
  }

  handleChange = (id) => {
    const { form } = this.state
    const value = document.getElementById(id).value
    this.setState({ form: { ...form, [id]: value } })
  }

  closeEdit = () => { this.setState({ show: false }) }
  onEdit = () => { this.setState({ show: true }) }
  //   handleChange = (e) => {
  //     // console.log(e);
  //     this.setState({
  //       [e.target.id]: e.target.value
  //     })
  //   }

  //   handleSubmit = (e) => {
  //     // console.log(e);
  //     e.preventDefault();
  //     this.props.createProject(this.state);
  //     this.props.history.push('/');
  //   }

  render() {
    const { handleContact, id } = this.props
    const { show, form } = this.state
    //     if (!auth.uid) return <Redirect to='/signin' />
    // const { value } = this.state
    return (<>
      <a className="j-pop-image" onClick={this.onEdit}>編輯</a>
      <Modal
        isOpen={show}
        toggle={this.closeEdit}
        size="lg"
      // style={{ maxWidth: '1200px', width: '70%', margin: '10px auto' }}
      // className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader >
          <Icon name='close' onClick={this.closeEdit} style={{ cursor: 'pointer', margin: 0 }}></Icon>
        </ModalHeader>
        <ModalBody>
          <div class="white-popup" id="popup-contactsetting">
            <form class="setBox" data-parsley-required-message="尚未填寫" >
              <div class="top_title">
                <h3>聯絡資訊設定</h3>
                <p>填寫完整資訊，讓訪客更容易與您聯繫</p>
              </div>
              <div class="fillinBox">
                <ul>
                  <li>
                    <label><p class="label_title">地址</p></label>
                    <input type="text" name="newAddress" id="newAddress" value={form.newAddress} data-parsley-trigger="change" data-parsley-required="true" onChange={() => this.handleChange('newAddress')} />
                    <p class="the_reminder">地址將會自動帶入 Google Maps，請填寫有效地址</p>
                  </li>
                  <li>
                    <label><p class="label_title">電話</p></label>
                    <input type="text" name="newAddress" id="newPhone" value={form.newPhone} onChange={() => this.handleChange('newPhone')} />
                  </li>
                  <li>
                    <label><p class="label_title">營業時間</p></label>
                    <input type="text" name="newAddress" id="newTime" value={form.newTime} onChange={() => this.handleChange('newTime')} />
                  </li>
                  <li>
                    <label><p class="label_title">電子郵件</p></label>
                    <input type="text" name="newAddress" id="newEmail" value={form.newEmail} onChange={() => this.handleChange('newEmail')} />
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </ModalBody>
        <ModalFooter style={{ padding: '16px', justifyContent: 'center' }}>
          <Button style={{ backgroundColor: '#27d0ca', color: '#fff' }} content='套用' onClick={() => { handleContact(form); this.closeEdit() }} />
        </ModalFooter>
      </Modal>
    </>)
  }
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     createProject: (project) => dispatch(createProject(project))
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HostIndex)
export default ModalEditContact