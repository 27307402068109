import React, { Component } from 'react'
import { Container, Icon, Table, Grid, Button, Modal, Header, Input, Label, Select } from 'semantic-ui-react'
import _ from 'lodash'
import { Link } from 'react-router-dom';

import MyModalTable from '../../widgets/MyModalTable';
import { getLabelName2, getOptionsByKey } from 'views/widgets/FieldsRender';
import ModalPdf from 'widget/pdf/ModalPdf';
import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
import Parse from '../../../widget/parse'

const style = {
  flex: {
    display: 'flex'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  textEllipse: {
    // boxSizong: 'border-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}
const getLabel = (formSet, key) => {
  // return key;
  return formSet.find(item2 => item2.key === key) ? formSet.find(item2 => item2.key === key).label : key;
}
const retRTobj = (formSet, arrKey, width) => {
  return arrKey.map(item => {
    var form = formSet.find(obj => obj.key === item);
    if (!form) {
      return { title: item }
    } else {
      var id = item
      if (form.type === 'select') {
        id = `${item}Text`;
      }
      return { id, title: getLabel(formSet, item), width, type: form.type }
    }
  });
}

export class GraduateInvest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      author: '',
      graduates: [],
      graduateYear: `${Number(this.props.semesterYear) + 1}`,
      yearsemester: `${this.props.semesterYear},${this.props.semester}`,
      isPass: '-1'
    }
  }
  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps');
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    // const { firebase, match } = this.props
    // firebase.firestore()
    //   .collectionGroup('Graduate')
    //   // .orderBy('relationship')
    //   // .where('eventDate', '==', eventDate).where('classname', '==', className)
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(item => {
    //       data.push({
    //         _id: item.id, ...item.data()
    //       })
    //     });
    //     this.setState({ graduates: data });
    //   }, err => {
    //     console.log(`Encountered error: ${err}`);
    //   });
    const data = await Parse.queryData('Graduate');
    this.setState({ graduates: data, loading: false });
  }
  groupByStudent(objectArray, property) {
    return objectArray.reduce(function (acc, obj) {
      var key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj.schoolArr);
      return acc;
    }, {});
  }
  handleChange = (event, data) => {
    let fieldName = data.name;
    let fleldVal = data.value;
    this.setState({ [fieldName]: fleldVal });
  }
  render() {
    const { firebase, match, history, formSet, optSet, students, semester, semesterYear } = this.props;
    const { graduates, yearsemester, isPass, graduateYear } = this.state;
    const semesterYearArr = getOptionsByKey(optSet, 'semesterYear').map(item => ({ key: item.objectId, text: item.label, value: item.value }));
    const semesterArr = [
      { key: '2019,A', text: '2019A', value: '2019,A' },
      { key: '2019,S', text: '2019S', value: '2019,S' },
      { key: '2018,A', text: '2018A', value: '2018,A' },
      { key: '2018,S', text: '2018S', value: '2018,S' },
      { key: '2017,A', text: '2017A', value: '2017,A' },
      { key: '2017,S', text: '2017S', value: '2017,S' },
    ]
    let year = yearsemester.substring(0, 4);
    let semes = yearsemester.substring(5);
    const isPassArr = [
      { key: '-1', text: '-- 選択 --', value: '-1' },
      { key: 'pass', text: '合格', value: '1' },
      { key: 'notpass', text: '不合格', value: '2' },
    ]
    let loadAllCurrentSemesterYear = "1"; // if==1, 2018S => 2017S 2017A 2018S, if==0 2018S => 2018S
    let graduation = [];
    if (isPass === '1' || isPass === '2') {
      graduation = graduates.filter(item => item.isAdmitted === isPass && item.semesterYear === (Number(graduateYear) - 2 + ''))
    } else {

      console.log(graduateYear) // graduateYear為Nan!!
      graduation = graduates.filter(item => item.semesterYear === (Number(graduateYear) - 2 + ''))
    }

    let row = graduation.map(item => (
      {
        ...item,
        schoolArr: `${item.targetSchoolText} / ${item.targetSubject} / ${item.isAdmittedText}`,
      }))
    var grouped = this.groupByStudent(row, 'studentId');
    let groupedId = Object.keys(grouped);
    let groupedSchoolArr = Object.values(grouped);
    let rows = groupedId.map((item, index) => ({
      ...graduates.filter(item2 => item2.studentId === item)[0],
      schoolArr: groupedSchoolArr[index].map((item, index) => (<p key={index}>{item}</p>)),
      schoolData: groupedSchoolArr[index].map(item => item).join('\n'),  //  pdf 印刷撈資料
      schoolEnter: row.filter(item2 => item2.studentId === item && item2.isEnter === '1').map((item3) => `${item3.targetSchoolText} / ${item3.targetSubject}`)
    }))
    return (
      <>

        <div style={{ ...style.flexCenter }}>
          <Header as='h2' style={{ margin: 0 }}>進学受験結果</Header>
          <div style={{ ...style.flexCenter }}>
            <Input labelPosition='left' >
              <Label color={'green'}> <Icon name='file alternate outline' />合格否</Label>
              <Select style={{
                minWidth: '120px',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
                marginRight: 10
              }} compact
                options={isPassArr} onChange={this.handleChange} value={isPass} name='isPass' />
            </Input>
            {/* <Input labelPosition='left' >
                <Label color={'blue'}> <Icon name='file alternate outline' />卒業生入学期</Label>
                <Select style={{
                  minWidth: '120px',
                  borderTopLeftRadius: '0',
                  borderBottomLeftRadius: '0',
                  marginRight: 10
                }} compact
                  options={semesterArr} onChange={this.handleChange} value={yearsemester} name='yearsemester' />
              </Input> */}
            <Input labelPosition='left' >
              <Label color={'blue'}> <Icon name='file alternate outline' />卒業年</Label>
              <Select style={{
                minWidth: '120px',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
                marginRight: 10
              }} compact
                options={semesterYearArr} onChange={this.handleChange} defaultValue={graduateYear} name='graduateYear' />
            </Input>
            <div style={{ ...style.flexCenter, minWidth: 220, marginLeft: 10 }}>
              <ExportTableToExcelButton size='small' />
              <ModalPdf
                {...this.props}
                // ref={(ref) => { this.pdfModal = ref }}
                codekey="graduateInvestPdf"
                params={rows.sort((a, b) => a.studentNum - b.studentNum)}
              />
            </div>
          </div>
        </div>
        <div style={{ maxWidth: '100vw', overflowX: 'scroll', marginTop: 25 }}>
          <Table id='excel' celled striped size='small' selectable>
            <Table.Header>
              <Table.Row textAlign='center'>
                {/* <Table.Cell></Table.Cell> */}
                <Table.HeaderCell style={style.textEllipse}>学籍番号</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>氏名</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>学年</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>学期</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>進路希望校名 / 学科 / 錄取結果</Table.HeaderCell>
                <Table.HeaderCell style={style.textEllipse}>決定入學校名 / 学科</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rows.sort((a, b) => a.studentNum - b.studentNum).map((item, index) => (<Table.Row key={item.objectId}>
                {/* <Link to={`/TeacherPage/${item._id}`}> */}
                {/* <Table.Cell style={style.textEllipse}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                      <Link to={`/TeacherPage/${item._id}`}><Button inverted={companyObj.inverted} color='yellow' size='small' style={{ margin: 15 }}>詳細</Button></Link>
                      <Button inverted={companyObj.inverted} color='green' size='small' onClick={() => this.handleEdit(item)} style={{ margin: 15 }}icon='edit' content='修正' />
                    </div>
                  </Table.Cell> */}
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.studentIdKey}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.studentName}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.semesterYear}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.semester}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.schoolArr}</Link></Table.Cell>
                <Table.Cell style={style.textEllipse}><Link style={{ color: '#000' }} to={`/StudentPage/2/${item.studentId}/1/2`}>{item.schoolEnter}</Link></Table.Cell>
                {/* </Link> */}
              </Table.Row>))}
            </Table.Body>
          </Table>
        </div>

      </>
    )
  }
}
export default GraduateInvest