import React, { Component } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { withCookies } from 'react-cookie';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import Favicon from 'react-favicon';

import { getProfile } from './store/actions/user';
import Parse from './widget/parse'
import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";

import formSet from './FormSet';
import optSet from './OptSet';
import MyDimmer from './layouts/Dimmer';

import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import Sections from "views/Sections.js";
import Presentation from "views/Presentation.js";
import AboutUs from "views/examples/AboutUs.js";
import AddProduct from "views/examples/AddProduct.js";
import BlogPost from "views/examples/BlogPost.js";
import BlogPosts from "views/examples/BlogPosts.js";
import ContactUs from "views/examples/ContactUs.js";
import Discover from "views/examples/Discover.js";
import Ecommerce from "views/examples/Ecommerce.js";
import Error404 from "views/examples/Error404.js";
import Error422 from "views/examples/Error422.js";
import Error500 from "views/examples/Error500.js";
import LandingPage from "views/examples/LandingPage.js";
import LoginPage from "views/examples/LoginPage.js";
import ProductPage from "views/examples/ProductPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import RegisterPage from "views/examples/RegisterPage.js";
import SearchWithSidebar from "views/examples/SearchWithSidebar.js";
import Settings from "views/examples/Settings.js";
import TwitterRedesign from "views/examples/TwitterRedesign.js";

import Profile from "views/webadmin/Profile.jsx";
import WebDesign from "views/webadmin/WebDesign.jsx";
import LocaleManage from "views/webadmin/LocaleManage.jsx";

import Exam from 'views/schoolhost/academicAffair/Exam';
import IndexOfficial from 'views/webpage/IndexOfficial';

const hostname = window.location.hostname;
const path1 = window.location.pathname.split('/')[1];

const locales = { // 內部的字形檔
  "en-US": require('./locales/en-US.json'),
  "zh-CN": require('./locales/zh-CN.json'),
  "zh-TW": require('./locales/zh-TW.json'),
  "ja-JP": require('./locales/ja-JP.json'),
  "vi-VN": require('./locales/vi-VN.json'),
};

// const PathRender = (props) => {
//   const { loggingIn, authenticated = true, component, path, exact, ...rest } = props;
//   return <Route path={path} exact={exact} render={props => React.createElement((component), { ...props, ...rest, loggingIn, authenticated })} />;
// }

const appendCssFile = (url, cssLoaded, id) => {
  const head = document.head;
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.onload = () => { cssLoaded(id); }
  link.href = url;
  head.appendChild(link);
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initDone: false,
      cssLoad1: false,
      cssLoad2: false,
      companies: [],
      companyObj: {},
      isLoading: true,
      webnavArr: [],
      webpageArr: [],
      langArr: [],
      webimageArr: []
    }
  }

  async componentWillMount() {
    // const { cookies } = this.props;
    // console.log(companyId)
    document.body.style.backgroundColor = '#fff';

    let currentLocale = intl.determineLocale({
      urlLocaleKey: 'lang',
      cookieLocaleKey: 'lang'
    });

    if (!locales[currentLocale]) {
      currentLocale = 'zh-TW';
    }

    intl.init({ // react-intl-universal 是單例模式, 只應該實例化一次
      currentLocale,//: 'en-US',
      locales,
    }).then(() => this.setState({ initDone: true }));
  }

  async componentDidMount() {
    // console.log(this.props.user)
    // console.log('componentDidMount')
    let companyObj = {}
    let companies = [];
    if (hostname === 'localhost') { // 一開始讀頁面的時候
      const { cookies } = this.props;
      if (path1 === 'admin' || path1 === 'auth') {
        companies = await Parse.queryData('company');
      } else {
        companies = await Parse.queryData('company', { useOfficial: true });
      }
      const companyId = (cookies.get('companyId') && cookies.get('companyId') !== 'undefined' && cookies.get('companyId')) || (companies.length && companies[0].objectId);
      // console.log('companyId', companyId)
      companyObj = companies.find(item => item.objectId === companyId) || {};
    } else {
      companies = await Parse.queryData('company');
      // companyObj = companies.find(item => hostname.indexOf(item.url) !== -1) || {};
      companyObj = await Parse.findDoc('company', { url: hostname }) || {};
      if (!companyObj.objectId) {
        companyObj = await Parse.findDoc('company', { url: 'infowin.com.tw' }) || {};
      }
    }

    const companyId = companyObj.objectId; //(cookies.get('companyId') !== 'undefined' && cookies.get('companyId'));
    if (path1 === 'admin' || path1 === 'auth') { // 後台
      appendCssFile("/css/paper-dashboard.min.css", this.setCssLoad, 2);
      appendCssFile("/css/demo-dashboard.css", this.setCssLoad, 1);
    }
    else {
      appendCssFile("/css/demo-kit.css", this.setCssLoad, 1);
      // appendCssFile("/css/paper-kit.min.css", this.setCssLoad, 2);
      appendCssFile("/css/paper-kit.css", this.setCssLoad, 2);
      if (companyId === 'aKDM0xR8py') { // 愛知的前端
        appendCssFile("/css/aichi-kit.css", this.setCssLoad, 2);
      } else if (companyId === 'Wuwrcczn0Z') { //愛知測試前端
        appendCssFile("/css/aichi-test.css", this.setCssLoad, 2);
      } else if (companyId === 'GXOrEBmYNb') {
        appendCssFile("/css/test-kit.css", this.setCssLoad, 2);
      }
    }

    this.setState({ companies });
    this.setCompany(companyObj);

    // 把之前舊的 user 資料，給讀到redux中
    this.props.getProfile().finally(() => this.setState({ isLoading: false }));
  }

  setCssLoad = (id) => {
    if (id === 1) {
      this.setState({ cssLoad1: true });
    } else {
      this.setState({ cssLoad2: true });
    }
  }

  changeCompany = (id) => {
    const { companies } = this.state
    const companyObj = companies.find(item => item.objectId === id);
    this.setCompany(companyObj);
  }

  setCompany = async (companyObj) => {
    const { cookies } = this.props;
    const dbLang = cookies.get('dbLang') || 'name';
    cookies.set('companyId', companyObj.objectId, { path: '/' });
    let semesterYear = ''
    let semester = ''
    if (path1 === 'admin') {
      document.title = (companyObj[dbLang] || companyObj.name) + ' - ' + intl.get('BackendSystem');
      if (companyObj.objectId === 'nsgdMpkSq4') {
        const data = await Parse.queryData('aiasoUnits', { companyId: companyObj.objectId });
        // console.log(data)
        semesterYear = data[0].currentSemester.substring(0, 4);
        semester = data[0].currentSemester.substring(5);
      }
    } else {
      document.title = companyObj[dbLang];
    }
    this.setState({ companyObj, semesterYear, semester }, () => {
      if (path1 !== 'admin' && path1 !== 'auth') { // 如果是官方網頁
        this.fetchNav()
      }
    });
  }

  fetchNav = async () => {
    const { companyObj } = this.state;
    const companyId = companyObj.objectId;
    const data1 = await Parse.queryData('webnav', { companyId }); // 所有的選單
    const webnavArr = data1.sort((a, b) => a.order - b.order);
    const data2 = await Parse.queryData('webpage', { companyId });
    const webpageArr = data2.sort((a, b) => a.order - b.order)
    const langArr = await Parse.queryData('localeContent', { companyId }); // 所有的選單
    const data3 = await Parse.queryData('webimage', { companyId: companyObj.objectId })
    document.body.style.backgroundColor = (!webnavArr.length || !webpageArr.length) ? 'lightslategrey' : '#fff';
    this.setState({ webnavArr, webpageArr, webimageArr: data3, langArr });
  }

  render() {
    const props = this.props
    const { user } = props;
    const { companies, cssLoad1, cssLoad2, initDone, companyObj, isLoading, webnavArr, webpageArr, langArr, webimageArr, semesterYear, semester } = this.state
    const p1 = window.location.pathname.split('/')[1];
    const extProps = {
      formSet,
      optSet,
      user,
      langArr,
      webnavArr,
      webimageArr,
      webpageArr,
      companyObj,
      companies,
      handleNavClick: this.handleNavClick,
      changeCompany: this.changeCompany,
      semesterYear,
      semester,
    };
    return (isLoading || !initDone || !cssLoad1 || !cssLoad2 || !companyObj.objectId ? <MyDimmer /> : <>
      <Favicon url={(companyObj.img2File && companyObj.img2File.url) || '/favicon_mai.ico'} />
      <BrowserRouter forceRefresh={!p1 ? true : false}>
        <Switch>
          <Route path="/index" render={props => <Index {...props} {...extProps} />} />
          <Route path="/nucleo-icons" render={props => <NucleoIcons {...props} {...extProps} />} />
          <Route path="/presentation" render={props => <Presentation {...props} {...extProps} />} />
          <Route path="/about-us" render={props => <AboutUs {...props} {...extProps} />} />
          <Route path="/add-product" render={props => <AddProduct {...props} {...extProps} />} />
          <Route path="/blog-post" render={props => <BlogPost {...props} {...extProps} />} />
          <Route path="/blog-posts" render={props => <BlogPosts {...props} {...extProps} />} />
          <Route path="/contact-us" render={props => <ContactUs {...props} {...extProps} />} />
          <Route path="/discover" render={props => <Discover {...props} {...extProps} />} />
          <Route path="/e-commerce" render={props => <Ecommerce {...props} {...extProps} />} />
          <Route path="/error-404" render={props => <Error404 {...props} {...extProps} />} />
          <Route path="/error-422" render={props => <Error422 {...props} {...extProps} />} />
          <Route path="/error-500" render={props => <Error500 {...props} {...extProps} />} />
          <Route path="/landing-page" render={props => <LandingPage {...props} {...extProps} />} />
          <Route path="/login-page" render={props => <LoginPage {...props} {...extProps} />} />
          <Route path="/product-page" render={props => <ProductPage {...props} {...extProps} />} />
          <Route path="/profile-page" render={props => <ProfilePage {...props} {...extProps} />} />
          <Route path="/register-page" render={props => <RegisterPage {...props} {...extProps} />} />
          <Route path="/search-with-sidebar" render={props => <SearchWithSidebar {...props} {...extProps} />} />
          <Route path="/settings" render={props => <Settings {...props} {...extProps} />} />
          <Route path="/twitter-redesign" render={props => <TwitterRedesign {...props} {...extProps} />} />
          <Route path="/auth" render={props => <AuthLayout {...props} {...extProps} />} />
          <Route path="/admin" render={props => <AdminLayout {...props} {...extProps} />} />
          <Route path="/admin/ExamTable/:gradeid" render={props => <Exam {...props} {...extProps} />} />
          <Route path="/sections" render={props => <Sections {...props} {...extProps} />} />
          <Route path="/profile" render={props => <Profile {...props} {...extProps} />} />
          <Route path="/webDesign" render={props => <WebDesign {...props} {...extProps} />} />
          <Route path="/localeManage" render={props => <LocaleManage {...props} {...extProps} />} />
          <Route path="/page/:webnav" exact render={props => <IndexOfficial {...props} {...extProps} />} />
          {companyObj.useOfficial ? <Route path="/" exact render={props => <IndexOfficial {...props} {...extProps} />} /> : <Redirect to="/admin/dashboard" />}
          <Redirect to="/error-404" />
        </Switch>
      </BrowserRouter></>);
  }
}

const mapStateToProps = state => ({
  user: state.user,
  errors: state.errors
})

export default connect(mapStateToProps, { getProfile })(withCookies(App));