
import React, { Component } from 'react'
import { Button } from "reactstrap";
import Parse from 'widget/parse'
import defaultImage from "assets/img/image_placeholder.jpg";

class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.avatar,
    };
  }

  handleImageChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
  }

  handleSubmit = async (e) => {
    // e.preventDefault();
    const { companyObj, type } = this.props
    const { file } = this.state
    var parseFile = new Parse.File(file.name, file);
    const theFile = await parseFile.save()

    const classDb = Parse.Object.extend('company');
    const saveObj = new classDb();
    saveObj.id = companyObj.objectId || companyObj.id;
    if (type === '1') {
      saveObj.set('img0File', theFile);
      await saveObj.save().catch(err => console.error(err))

    } else if (type === '2') {
      saveObj.set('img1File', theFile);
      await saveObj.save().catch(err => console.error(err))

    } else if (type === '3') {
      saveObj.set('img2File', theFile);
      await saveObj.save().catch(err => console.error(err))

    } else if (type === '4') {
      saveObj.set('img3File', theFile);
      await saveObj.save().catch(err => console.error(err))
    }
    this.setState({ file: null })
  }

  handleClick = () => {
    this.refs.fileInput.click();
  }

  handleRemove = () => {
    this.setState({
      file: null,
      imagePreviewUrl: null,
    });
    this.refs.fileInput.value = null;
  }

  handleDelete = async () => {
    if (!window.confirm('確認要刪除嗎？')) {
      return;
    }
    const { companyObj, type } = this.props
    const classDb = Parse.Object.extend('company');
    const saveObj = new classDb();
    saveObj.id = companyObj.objectId || companyObj.id;
    if (type === '1') {
      saveObj.set('img0File', null);
      await saveObj.save().catch(err => console.error(err))

    } else if (type === '2') {
      saveObj.set('img1File', null);
      await saveObj.save().catch(err => console.error(err))

    } else if (type === '3') {
      saveObj.set('img2File', null);
      await saveObj.save().catch(err => console.error(err))

    } else if (type === '4') {
      saveObj.set('img3File', null);
      await saveObj.save().catch(err => console.error(err))
    }
    this.setState({
      file: null,
      imagePreviewUrl: null,
    });
  }

  render() {
    const { imagePreviewUrl, file } = this.state;
    // console.log(file)
    return (
      <div className="fileinput text-center">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" />
        <div className={"thumbnail" + (this.props.avatar ? " img" : "")}>
          <img src={imagePreviewUrl || defaultImage} alt="..." />
        </div>
        <div>
          <Button className="btn-round" onClick={file ? this.handleSubmit : this.handleClick}>
            {file ? "確定" : "更換"}
          </Button>
          {imagePreviewUrl ?
            <Button
              color="danger"
              className="btn-round"
              onClick={file ? this.handleRemove : this.handleDelete}
            >
              <i className="fa fa-times" />
                移除
              </Button> : null}
        </div>
      </div>
    );
  }
}

// ImageUpload.propTypes = {
//   avatar: PropTypes.bool
// };

export default ImageUpload;
