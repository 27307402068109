import Lunar, { GanGB, ZhiGB } from './lunar'

import {
  YinYang, HeavenlyStems, ShengXiaoGB, EarthlyBranches, Palace, FiveElements, DaShian,
  StarM_A14, StarM_A07, StarM_S04, StarM_B06, StarO_S05,
  FiveEleTable, FiveEleArr, Star_A14, Star_Z06, Star_T08, Star_G07,
  Star_S04, Star_B06, Star_OS5, Doctor12, Longevity12, Longevity21, Before12
} from './ziweistar'

/*紫微斗數 Chinese Astrology Zi Wei Dou Shu*/
class ZiWei {
  constructor() {
    this.y = null;
    this.m = null;
    this.d = null;
    this.h = null;
    this.g = null;
    this.l = null;
    this.b = null;
    this.f = null;
    this.s4 = null;
    this.z = null;
    this.yS = null;
    this.mS = null;
    this.dS = null;
    this.LunarDay = null;
    this.ShengXiao = null;
    this.y1Pos = null;
    this.y2Pos = null;
    this.hPos = null;
    this.lPos = null;
    this.bPos = null;
    this.zPos = null;
    this.Palce = null;
    this.Place12 = null;
    this.lunar = null;
    this.main_palace_idx = -1; // 命宮位置(宮支)
    this.body_palace_idx = -1; // 身宮位置(宮支)
    this.bornHeavenlyStem = -1; // 生年天干
    this.bornEarthlyBranch = -1; // 生年地支
    this.lunarBirth = [0, 0, 0]; // 農曆
    this.gan = null;
    this.zhi = null;
  }

  //排紫微命盤
  computeZiWei(y_Solar, m_Solar, d_Solar, h_Solar, g_Solar) {
    //y:年,m:月,d:日,h:時,g:性別,l:命宮,b:身宮,f:五行局,s:起紫微表,s4:四化星;
    this.yS = y_Solar;
    this.mS = m_Solar;
    this.dS = d_Solar;
    //取得農曆時辰，排紫微命盤
    const Lun = new Lunar();
    Lun.setSolar(this.yS, this.mS, this.dS)
    this.lunar = Lun.getLunar();
    this.gan = Lun.getGan();
    this.zhi = Lun.getZhi();
    this.y = HeavenlyStems[(this.yS - 4) % 10] + EarthlyBranches[(this.yS - 4) % 12];
    this.m = this.lunar.m;
    this.d = this.lunar.d;
    this.h = h_Solar;
    this.g = g_Solar;
    this.lunarBirth = [this.lunar.y, this.lunar.m, this.lunar.d]
    // 命宮為生月起子，逆數至生時
    this.main_palace_idx = (12 + this.lunar.m + 2 - this.lunar.h + 1) % 12; // 命宮位置
    // 身宮為生月起子，順數至生時
    this.body_palace_idx = (this.lunar.m + 2 + this.lunar.h - 1) % 12; // 身宮位置

    this.bornHeavenlyStem = (17 + this.lunarBirth[0] - 1900) % 10;
    this.bornEarthlyBranch = (13 + (this.lunarBirth[0] - 1900) % 12) % 12;

    //年:天干地支
    this.y1Pos = HeavenlyStems.indexOf(this.y.substring(0, 1));
    this.y2Pos = EarthlyBranches.indexOf(this.y.substring(1, 2));
    //時:地支
    this.hPos = EarthlyBranches.indexOf(this.h);
    //設定紫微斗數
    this.setZiwei(this.d);
    //stepSetStar
    this.stepSetStar(this.y, this.m, this.d, this.h, this.main_palace_idx, this.body_palace_idx, this.bornHeavenlyStem, this.bornEarthlyBranch, this.lunarBirth, this.hPos);
    return this.Place12;
  }

  //CenterPart↑↓
  getLunarDay() { return GanGB[this.gan.y] + ZhiGB[this.zhi.y] + " 年 " + (this.lunar.l ? "閏" : "") + this.lunar.m + " 月 " + this.lunar.d + " 日 " + this.h + " 時"; }
  getSolarDay() { return this.yS + " 年 " + this.mS + " 月 " + this.dS + " 日 " + this.h + " 時"; }
  getShengXiao() { return ShengXiaoGB[(this.yS - 4) % 12]; }
  getFiveElement() { return this.f; }
  getYinYangGender() { return YinYang[this.y1Pos % 2] + (this.g === "M" ? "男" : "女"); }
  //CenterPart↑
  getStarArr(STAR, size, pos) {
    const starArray = [];
    for (let i = 0; i < size; i++) { starArray[i] = STAR[i][pos]; } return starArray;
  }
  getStarArrByPosArr(STAR, size, PosArr) {
    const starArray = [];
    for (let i = 0; i < size; i++) { starArray[i] = STAR[i][PosArr[i]]; } return starArray;
  }
  putS04Str(starName, STAR) {
    return (STAR.indexOf(starName) >= 0) ? "<b>" + StarM_S04[STAR.indexOf(starName)].substring(1, 2) + "</b>" : "　";
  }
  getS04Str(starName, STAR) {
    return (STAR.indexOf(starName) >= 0) ? StarM_S04[STAR.indexOf(starName)] : "";
  }
  setZiwei(d) {
    //重排宮位
    // ziweiUI.clearPalce(); // MIC: clear

    //安十二宮，安命宮、身宮
    this.l = EarthlyBranches[((12 - this.hPos) + 1 + this.m * 1.0) % 12];
    this.b = EarthlyBranches[(12 - ((22 - this.hPos) + 1 - this.m * 1.0) % 12) % 12];
    this.lPos = EarthlyBranches.indexOf(this.l);
    this.bPos = EarthlyBranches.indexOf(this.b);
    //安五行局
    this.f = FiveElements[FiveEleArr[this.y1Pos % 5][((this.lPos - (this.lPos % 2 === 0 ? 0 : 1)) / 2) % 6]];
    //起紫微表
    this.z = EarthlyBranches[FiveEleTable[FiveElements.indexOf(this.f)][d - 1]];
    this.zPos = EarthlyBranches.indexOf(this.z);
  }

  getAssistantStarLocation = function (monthStem) {
    return new Array((2 + monthStem) % 12, (14 - monthStem) % 12);
  };
  getLiteratureStarLocation = function (hour) {
    console.log(hour)
    console.log(new Array((12 - hour) % 12, (4 + hour) % 12))
    return new Array((12 - hour) % 12, (4 + hour) % 12);
  };


  stepSetStar(y, m, d, h, main_palace_idx, body_palace_idx, bornHeavenlyStem, bornEarthlyBranch, lunarBirth, hour) {
    // console.log(main_palace_idx, body_palace_idx, bornHeavenlyStem, bornEarthlyBranch, lunarBirth)
    //準備星星
    //0:紫微,1:天機,2:太陽,3:武曲,4:天同,5:廉貞,6:天府,7:太陰,8:貪狼,9:巨門,10:天相,11:天梁,12:七殺,13:破軍
    const s14 = Star_A14[this.zPos];
    const sZ06 = this.getStarArr(Star_Z06, 7, this.zPos);
    const sT08 = this.getStarArr(Star_T08, 8, sZ06[6]);
    //0:文昌 1:文曲 (時) 2:左輔 3:右弼 (月) 4:天魁 5:天鉞 6:祿存(年干)
    const sG07 = this.getStarArrByPosArr(Star_G07, 7, [this.hPos, this.hPos, m - 1, m - 1, this.y1Pos, this.y1Pos, this.y1Pos]);
    //四化星
    const sS04 = this.getStarArr(Star_S04, 4, this.y1Pos);
    //六凶星
    const sB06 = [Star_B06[0][this.y1Pos], Star_B06[1][this.y1Pos], Star_B06[2][this.y2Pos % 4][this.hPos], Star_B06[3][this.y2Pos % 4][this.hPos], Star_B06[4][this.hPos], Star_B06[5][this.hPos]];
    //其他
    const OS05 = this.getStarArr(Star_OS5, 12, this.y2Pos);
    //性別
    const gender = YinYang[this.y1Pos % 2] + (this.g === "M" ? "男" : "女")
    this.Place12 = new Array(12);
    //準備開始組星星
    for (let i = 0; i < 12; i++) {
      const lenStar = [0, 0, 0, 0];
      const StarA = [], StarB = [], StarC = [], Star6 = [], StarD = [], StarE = [], StarF = [], StarG = [];
      //紫微星系 & 六凶星
      for (let k = 0; k < 6; k++) {
        if (sZ06[k] === i) { StarA[lenStar[0]] = StarM_A14[k] + this.getS04Str(StarM_A14[k], sS04); lenStar[0] += 1; }
        if (sB06[k] === i) { StarB[lenStar[1]] = StarM_B06[k]; lenStar[1] += 1; }
      }
      //天府星系
      for (let k = 0; k < 8; k++) {
        if (sT08[k] === i) { StarA[lenStar[0]] = StarM_A14[k + 6] + this.getS04Str(StarM_A14[k + 6], sS04); lenStar[0] += 1; }
      }
      //六吉星
      for (let k = 0; k < 7; k++) {
        if (sG07[k] === i) { Star6[lenStar[3]] = StarM_A07[k] + this.getS04Str(StarM_A07[k], sS04); lenStar[3] += 1; }
      }
      //其他星矅StarO_S0.length
      for (let k = 0; k < 12; k++) {
        if (OS05[k] === i) { StarC[lenStar[2]] = StarO_S05[k]; lenStar[2] += 1; }
      }

      //
      //塞入位置
      this.Place12[i] = {
        "branch": EarthlyBranches[i],
        "MangA": HeavenlyStems[((this.y1Pos % 5) * 2 + (i < 2 ? i + 2 : i) % 10) % 10] + EarthlyBranches[i],
        "MangB": Palace[(12 - this.lPos + i) % 12],
        "MangC": (this.bPos === i ? Palace[12] : ""),
        "StarA": StarA, "StarB": StarB, "StarC": StarC, "Star6": Star6, "StarD": StarD, "StarE": StarE, "StarF": StarF, "StarG": StarG
      };
    }
    this.y = (this.yS - 4) % 12;
    //天殤星
    const a = this.Place12.find(item => item.MangB === '交友宮')
    a.StarC.push('天殤')
    //天使星
    const b = this.Place12.find(item => item.MangB === '疾厄宮')
    b.StarC.push('天使')
    //天壽.天才
    const c = this.Place12.find(item => item.MangB === '命宮')
    const cIndex = this.Place12.indexOf(c) + this.y
    const newC = this.Place12[cIndex % 12]
    newC.StarC.push('天壽', '天才')
    //三台星
    const e = this.Place12.find(item => item.Star6.indexOf('左輔') !== -1 || item.Star6.indexOf('左輔化祿') !== -1 || item.Star6.indexOf('左輔化權') !== -1 || item.Star6.indexOf('左輔化科') !== -1 || item.Star6.indexOf('左輔化忌') !== -1)
    const eIndex = this.Place12.indexOf(e) + (this.lunar.d % 12) - 1
    const newE = this.Place12[eIndex % 12]
    newE.StarC.push('三台')
    //八座星
    const f = this.Place12.find(item => item.Star6.indexOf('右弼') !== -1 || item.Star6.indexOf('右弼化祿') !== -1 || item.Star6.indexOf('右弼化權') !== -1 || item.Star6.indexOf('右弼化科') !== -1 || item.Star6.indexOf('右弼化忌') !== -1)
    const fIndex = this.Place12.indexOf(f) - (this.lunar.d % 12) + 13
    const newF = this.Place12[(fIndex) % 12]
    newF.StarC.push('八座')
    //天貴星
    const g = this.Place12.find(item => item.Star6.indexOf('文曲') !== -1 || item.Star6.indexOf('文曲化祿') !== -1 || item.Star6.indexOf('文曲化權') !== -1 || item.Star6.indexOf('文曲化科') !== -1 || item.Star6.indexOf('文曲化忌') !== -1)
    const gIndex = this.Place12.indexOf(g) + (this.lunar.d % 12) - 2
    const newG = this.Place12[gIndex % 12]
    newG.StarC.push('天貴')
    //恩光星
    const i = this.Place12.find(item => item.Star6.indexOf('文昌') !== -1 || item.Star6.indexOf('文昌化祿') !== -1 || item.Star6.indexOf('文昌化權') !== -1 || item.Star6.indexOf('文昌化科') !== -1 || item.Star6.indexOf('文昌化忌') !== -1)
    const iIndex = this.Place12.indexOf(i) + (this.lunar.d % 12) - 2
    const newI = this.Place12[iIndex % 12]
    newI.StarC.push('恩光')
    //陰煞星
    const jArr = ["寅", "子", "戌", "申", "午", "辰", "寅", "子", "戌", "申", "午", "辰"]
    const j = this.Place12.find(item => item.branch === jArr[this.lunar.m - 1])
    j.StarC.push('陰煞')
    //天刑星
    const kArr = ["酉", "戌", "亥", "子", "丑", "寅", "卯", "辰", "巳", "午", "未", "申"]
    const kIndex = kArr[this.lunar.m - 1]
    const k = this.Place12.find(item => item.branch === kIndex)
    k.StarC.push('天刑')
    //天姚星
    const lArr = ["丑", "寅", "卯", "辰", "巳", "午", "未", "申", "酉", "戌", "亥", "子"]
    const lIndex = lArr[this.lunar.m - 1]
    const l = this.Place12.find(item => item.branch === lIndex)
    l.StarC.push('天姚')
    //天月星
    const nArr = ["戌", "巳", "辰", "寅", "未", "卯", "亥", "未", "寅", "午", "戌", "寅"]
    const n = this.Place12.find(item => item.branch === nArr[this.lunar.m - 1])
    n.StarC.push('天月')
    //天巫星
    const oArr = ["巳", "申", "亥", "寅", "巳", "申", "亥", "寅", "巳", "申", "亥", "寅"]
    const o = this.Place12.find(item => item.branch === oArr[this.lunar.m - 1])
    o.StarC.push('天巫')
    //台輔星
    const pArr = ["午", "未", "申", "酉", "戌", "亥", "子", "丑", "寅", "卯", "辰", "巳"]
    const pIndex = EarthlyBranches.indexOf(this.h)
    const p = this.Place12.find(item => item.branch === pArr[pIndex])
    p.StarC.push('台輔')
    //封誥星
    const qArr = ["寅", "卯", "辰", "巳", "午", "未", "申", "酉", "戌", "亥", "子", "丑"]
    const qIndex = EarthlyBranches.indexOf(this.h)
    const q = this.Place12.find(item => item.branch === qArr[qIndex])
    q.StarC.push('封誥')
    //解神星
    const rArr = ["申", "申", "戌", "戌", "子", "子", "寅", "寅", "辰", "辰", "午", "午"]
    const r = this.Place12.find(item => item.branch === rArr[this.lunar.m - 1])
    r.StarC.push('解神')
    //天官星
    const sArr = ["未", "辰", "巳", "寅", "卯", "酉", "亥", "酉", "戌", "午"]
    const sIndex = (this.yS - 4) % 10
    const s = this.Place12.find(item => item.branch === sArr[sIndex])
    s.StarC.push('天官')
    //天福星
    const tArr = ["酉", "申", "子", "亥", "卯", "寅", "午", "巳", "午", "巳"]
    const tIndex = (this.yS - 4) % 10
    const t = this.Place12.find(item => item.branch === tArr[tIndex])
    t.StarC.push('天福')
    // //天空星
    // const uArr = ["亥", "戌", "酉", "申", "未", "午", "巳", "辰", "卯", "寅", "丑", "子"]
    // const uIndex = EarthlyBranches.indexOf(this.h)
    // const u = this.Place12.find(item => item.branch === uArr[uIndex])
    //生年博士十二神
    const aa = this.Place12.find(item => item.Star6.indexOf("祿存") !== -1)
    const aaIndex = this.Place12.indexOf(aa)
    console.log(aaIndex)
    if (gender === '陽男' || gender === '陰女') {
      for (let i = 0; i < 12; i++) {
        if (i < aaIndex) {
          this.Place12[i].StarD.push(Doctor12[12 - aaIndex + i])
        } else {
          this.Place12[i].StarD.push(Doctor12[i - aaIndex])
        }
      }
    } else if (gender === '陰男' || gender === '陽女') {
      for (let i = 0; i < 12; i++) {
        if (i < aaIndex) {
          console.log('陰男')
          this.Place12[i].StarD.push(Doctor12[aaIndex - i])
        } else if (i === aaIndex) {
          this.Place12[i].StarD.push(Doctor12[0])
        } else {
          this.Place12[i].StarD.push(Doctor12[12 + aaIndex - i])
        }
      }
    }
    //長生十二神
    //  ["水二局", "火六局", "土五局", "木三局", "金四局"];
    if (this.f === '水二局') {
      const a = this.Place12.find(item => item.branch === '申')
      const aIndex = this.Place12.indexOf(a)
      if (gender === '陽男' || gender === '陰男') {
        for (let i = 0; i < 12; i++) {
          if (i < aIndex) {
            this.Place12[i].StarE.push(Longevity12[12 - aIndex + i])
          } else {
            this.Place12[i].StarE.push(Longevity12[i - aIndex])
          }
        }
      } else if (gender === '陰男' || gender === '陰女') {
        for (let i = 0; i < 12; i++) {
          if (i < aIndex) {
            this.Place12[i].StarE.push(Longevity21[12 - aIndex + i])
          } else {
            this.Place12[i].StarE.push(Longevity21[i - aIndex])
          }
        }
      }

    } else if (this.f === '火六局') {
      const a = this.Place12.find(item => item.branch === '寅')
      const aIndex = this.Place12.indexOf(a)
      if (gender === '陽男' || gender === '陰男') {
        for (let i = 0; i < 12; i++) {
          if (i < aIndex) {
            this.Place12[i].StarE.push(Longevity12[12 - aIndex + i])
          } else {
            this.Place12[i].StarE.push(Longevity12[i - aIndex])
          }
        }
      } else if (gender === '陰男' || gender === '陰女') {
        for (let i = 0; i < 12; i++) {
          if (i < aIndex) {
            this.Place12[i].StarE.push(Longevity21[12 - aIndex + i])
          } else {
            this.Place12[i].StarE.push(Longevity21[i - aIndex])
          }
        }
      }
    } else if (this.f === '土五局') {
      const a = this.Place12.find(item => item.branch === '申')
      const aIndex = this.Place12.indexOf(a)
      if (gender === '陽男' || gender === '陰男') {
        for (let i = 0; i < 12; i++) {
          if (i < aIndex) {
            this.Place12[i].StarE.push(Longevity12[12 - aIndex + i])
          } else {
            this.Place12[i].StarE.push(Longevity12[i - aIndex])
          }
        }
      } else if (gender === '陰男' || gender === '陰女') {
        for (let i = 0; i < 12; i++) {
          if (i < aIndex) {
            this.Place12[i].StarE.push(Longevity21[12 - aIndex + i])
          } else {
            this.Place12[i].StarE.push(Longevity21[i - aIndex])
          }
        }
      }
    } else if (this.f === '木三局') {
      const a = this.Place12.find(item => item.branch === '亥')
      const aIndex = this.Place12.indexOf(a)
      if (gender === '陽男' || gender === '陰男') {
        for (let i = 0; i < 12; i++) {
          if (i < aIndex) {
            this.Place12[i].StarE.push(Longevity12[12 - aIndex + i])
          } else {
            this.Place12[i].StarE.push(Longevity12[i - aIndex])
          }
        }
      } else if (gender === '陰男' || gender === '陰女') {
        for (let i = 0; i < 12; i++) {
          if (i < aIndex) {
            this.Place12[i].StarE.push(Longevity21[12 - aIndex + i])
          } else {
            this.Place12[i].StarE.push(Longevity21[i - aIndex])
          }
        }
      }
    } else if (this.f === '金四局') {
      const a = this.Place12.find(item => item.branch === '巳')
      const aIndex = this.Place12.indexOf(a)
      if (gender === '陽男' || gender === '陰男') {
        for (let i = 0; i < 12; i++) {
          if (i < aIndex) {
            this.Place12[i].StarE.push(Longevity12[12 - aIndex + i])
          } else {
            this.Place12[i].StarE.push(Longevity12[i - aIndex])
          }
        }
      } else if (gender === '陰男' || gender === '陰女') {
        for (let i = 0; i < 12; i++) {
          if (i < aIndex) {
            this.Place12[i].StarE.push(Longevity21[12 - aIndex + i])
          } else {
            this.Place12[i].StarE.push(Longevity21[i - aIndex])
          }
        }
      }
    }
    //歲前十二星
    const bb = this.Place12.find(item => item.branch === EarthlyBranches[this.y])
    // console.log(bb)
    const bbIndex = this.Place12.indexOf(bb)
    for (let i = 0; i < 12; i++) {
      if (i < bbIndex) {
        this.Place12[i].StarF.push(Before12[12 - bbIndex + i])
      } else {
        this.Place12[i].StarF.push(Before12[i - bbIndex])
      }
    }
    //將前十二星
    // const cc = this.Place12.find(item => item.branch === EarthlyBranches[this.y])
    // const ccIndex = this.Place12.indexOf(cc)
    if (EarthlyBranches[this.y] === '寅' || EarthlyBranches[this.y] === '午' || EarthlyBranches[this.y] === '戌') {
      // const a = ["午", "未", "申", "酉", "戌", "亥", "子", "丑", "寅", "卯", "辰", "巳"]
      for (let i = 0; i < 12; i++) {
        const arr = ["災煞", "天煞", "指背", "咸池", "月煞", "亡神", "將星", "攀鞍", "歲驛", "息神", "華蓋", "劫煞"]
        this.Place12[i].StarG.push(arr[i])
      }
    } else if (EarthlyBranches[this.y] === '申' || EarthlyBranches[this.y] === '子' || EarthlyBranches[this.y] === '辰') {
      for (let i = 0; i < 12; i++) {
        const arr = ["將星", "攀鞍", "歲驛", "息神", "華蓋", "劫煞", "災煞", "天煞", "指背", "咸池", "月煞", "亡神",]
        this.Place12[i].StarG.push(arr[i])
      }
    } else if (EarthlyBranches[this.y] === '巳' || EarthlyBranches[this.y] === '酉' || EarthlyBranches[this.y] === '丑') {
      for (let i = 0; i < 12; i++) {
        const arr = ["息神", "華蓋", "劫煞", "災煞", "天煞", "指背", "咸池", "月煞", "亡神", "將星", "攀鞍", "歲驛"]
        this.Place12[i].StarG.push(arr[i])
      }
    } else if (EarthlyBranches[this.y] === '亥' || EarthlyBranches[this.y] === '卯' || EarthlyBranches[this.y] === '未') {
      for (let i = 0; i < 12; i++) {
        const arr = ["咸池", "月煞", "亡神", "將星", "攀鞍", "歲驛", "息神", "華蓋", "劫煞", "災煞", "天煞", "指背"]
        this.Place12[i].StarG.push(arr[i])
      }
    }
    console.log(this.Place12)
    // for (let i = 0; i < 12; i++) {
    //   if (i < ccIndex) {
    //     this.Place12[i].StarF.push(Before12[12 - ccIndex + i])
    //   } else {
    //     this.Place12[i].StarF.push(Before12[i - ccIndex])
    //   }
    // }
    // console.log(this.Place12)
    // const aaIndex = this.Place12.indexOf(aa)
    // if (gender === '陽男' || '陰女') {
    //   for (let i = 0; i < 12; i++) {
    //     if (i < aaIndex) {
    //       this.Place12[i].StarD.push(Doctor12[12 - aaIndex + i])
    //     } else {
    //       this.Place12[i].StarD.push(Doctor12[i - aaIndex])
    //     }
    //   }
    // } else {
    //   for (let i = 0; i < 12; i++) {
    //     if (i < aaIndex) {
    //       this.Place12[i].StarD.push(Doctor21[12 - aaIndex + i])
    //     } else {
    //       this.Place12[i].StarD.push(Doctor21[i - aaIndex])
    //     }
    //   }
    // }
    //
    //
    //
  }

  //   Palace.prototype.addSecondaryStar = function(star) {
  //     this.secondaryStar.push(star);
  // };

  getDaShian() {
    //大限資料準備
    const DaShianYear = DaShian[FiveElements.indexOf(this.f)];
    let MangDirection = this.y1Pos % 2;
    MangDirection += (this.g === "M") ? 1 : 0;
    //小限資料準備
    let SiaoShianYear, SiaoShianStr, SiaoPlace;
    switch (this.y2Pos) {
      case 2: case 6: case 10: SiaoShianYear = 4; break;
      case 8: case 0: case 4: SiaoShianYear = 11; break;
      case 5: case 9: case 1: SiaoShianYear = 7; break;
      case 11: case 3: case 7: SiaoShianYear = 1; break;
      default: break;
    }
    const DShian = new Array(10);
    const SShian = new Array(10);
    for (let i = 0; i < 12; i++) {
      //起大限表
      const DSY = MangDirection === 1 ? DaShianYear + i * 10 : (DaShianYear - i * 10 + 120) % 120;
      //起小限表
      SiaoShianStr = "";
      for (let j = 0; j < 6; j++) {
        SiaoShianStr += "," + (j * 12 + i + 1);
      }
      SiaoShianStr = SiaoShianStr.substring(1) + " ... ";
      SiaoPlace = (this.g === "M") ? ((SiaoShianYear + i) % 12 + 1).toString() : ((SiaoShianYear - i + 12) % 12 + 1).toString();
      DShian[(i + this.lPos) % 12 + 1] = DSY.toString() + " - " + (DSY + 9).toString();
      SShian[SiaoPlace] = SiaoShianStr;
    }
    return { "DShian": DShian, "SShian": SShian };
  }
}

export default ZiWei
