import React, { Component } from 'react'
import { Image, Loader, Icon, Form } from 'semantic-ui-react'
import {
  // FormGroup,
  // Label,
  // Input,
  Row,
  Col
} from 'reactstrap';
import { readFileAsURL } from './RowImage';

export const previewUrl = (file, preview) => { // 目前的檔案
  if (!file) {
    return
  }
  let url = null;
  if (typeof file._url === 'string' && file._url.length) {
    url = file._url
  } else if (typeof file.url === 'string' && file.url.length) {
    url = file.url
  }

  if (url) {
    const ext = url.split('.').pop() || '';
    if (preview) {
      if (ext === 'png' || ext === 'gif' || ext === 'jpg' || ext === 'jpeg' || ext === 'tiff') {
        return <Image size='large' href={url} target='_blank' rel="noopener noreferrer" src={url} />
      } else if (ext === 'pdf') {
        return <iframe title="preview" src={url} style={{ border: '0px', width: "100%", height: '600px' }} />;
      } else {
        return <a href={url} target='_blank' rel="noopener noreferrer"><Icon name='file' color='olive' size='large' /> </a>
      }
    } else {
      return <a href={url} target='_blank' rel="noopener noreferrer"><Icon name='file' color='olive' size='large' /> </a>
    }
  }
}

export const previewUrl2 = (file, name, preview = 1) => { // 目前的檔案
  if (!file) {
    return
  }
  let url = null;
  if (typeof file._url === 'string' && file._url.length) {
    url = file._url
  } else if (typeof file.url === 'string' && file.url.length) {
    url = file.url
  }
  if (url) {
    const ext = url.split('.').pop() || '';
    if (preview) {
      if (ext === 'png' || ext === 'gif' || ext === 'jpg' || ext === 'jpeg' || ext === 'tiff') {
        return <><Image style={{ marginBottom: '15px' }} size='medium' href={url} target='_blank' rel="noopener noreferrer" src={url} /> {name}</>

        // } else if (ext === 'pdf') {
        //   return <iframe title="preview" src={url} style={{ border: '0px', width: "100%", height: '600px' }} />;
      } else {
        return <a href={url} target='_blank' rel="noopener noreferrer"><Icon name='file' color='olive' size='large' /> {name}</a>
      }
    } else {
      return <a href={url} target='_blank' rel="noopener noreferrer"><Icon name='file' color='olive' size='large' /> {name}</a>
    }
  }
}

export default class PreloadFile extends Component {
  state = {
    imagePreviewUrlArr: [],
  }

  handleChange = async (e) => {
    const { handleChangeFile, multiple, setImagePreviewUrl } = this.props
    e.preventDefault();
    const files = e.target.files;
    if (!files || !files.length) {
      return
    }
    const promises = [];
    for (let i = 0; i < files.length; i++) {
      const item = files[i];
      if (item.type && item.type.indexOf('image') !== -1) {
        const element = readFileAsURL(item)
        promises.push(element);
      } else {
        promises.push({ type: 'file', name: item.name });
      }
    }

    const imagePreviewUrlArr = await Promise.all(promises).catch(error => console.log("error", error));
    this.setState({ imagePreviewUrlArr })
    if (multiple) {
      handleChangeFile(files)
    } else {
      handleChangeFile(files[0]);
      if (setImagePreviewUrl) {
        handleChangeFile('file', files[0]);
        setImagePreviewUrl(imagePreviewUrlArr[0])
      }
    }
  }

  render() {
    const { isImage, multiple = false } = this.props
    const { imagePreviewUrlArr } = this.state
    let type = ''
    if (isImage === '0') {
      type = '*'
    } else if (isImage === '1') {
      type = 'image/*'
    } else if (isImage === '2') {
      type = "audio/*"
    }

    return (<>
      <Row>
        <Col md={6}><input id="files" type="file" multiple={multiple}
          onChange={this.handleChange} accept={type} /></Col>
      </Row>
      <br />
      <br />
      {imagePreviewUrlArr && imagePreviewUrlArr.length ? imagePreviewUrlArr.map((item, index) =>
        item.type === 'file' ? <div key={'iimg' + index}><Icon name='file' color='olive' size='large' /> {item.name}</div> :
          <Image key={'iimg' + index} inline floated='left' size='small' spaced='left' src={item} />) : null}
    </>)
  }
}
