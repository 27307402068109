import React, { Component } from 'react'
import { Icon, Table, Input, Label, Form, Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
// import ReactToPrint from "react-to-print";
// import ModalPdf from 'widget/pdf/ModalPdf';

import ExportTableToExcelButton from 'widget/ExportTableToExcelButton';
// import { getOptionsSelByKey } from 'views/widgets/FieldsRender';

// const style = {
//   tableShow: {
//     display: 'table',
//   },
//   tableHide: {
//     display: 'none',
//   }
// }

export default class ClassExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // activeIndex: props.match.params.activeIndex1 || '0',
      // doc: props.students[0],
      // docId: props.students[0]._id,
      classObj: {},
      arrRow: [],
      loading: true,
      show: false,
      // classSchool: 'A',
      parttimeData: []
    };
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.handleFetch();
  }

  componentWillReceiveProps(nextProps) {
    // const { firebase, weekday, period, eventDate, classId } = nextProps;
    // console.log('componentWillReceiveProps');
    if (nextProps.semesterYear !== this.props.semesterYear ||
      nextProps.semester !== this.props.semester ||
      nextProps.classId !== this.props.classId) {
      this.handleFetch(nextProps)
    }
  }

  handleFetch = (props = this.props) => {
    const { firebase, students, classroom, classroomL, handleLazyLoad, classId } = props
    // const { classSchool } = this.state
    if (!classroomL) {
      handleLazyLoad('classroom');
      // console.log(classroom)
    } else {
      const classObj = classroom.find(item => item.objectId === classId) || {}
      const tmp = students.filter(item =>
        (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
        (item.applyResult === '1' || item.studentType === "2"));
      const arrRow = tmp.filter(item => item.classSchool === classObj.classSchool); // 找到這班的所有人
      this.setState({ classSchool: classObj.classSchool, classObj, arrRow, loading: 1 });
      // console.log(classObj)
    }
    // const db = firebase.firestore();
    // const promises2 = [];
    // arrRow.forEach(doc => {
    //   var promise2 = db.collection(`Students/${doc._id}/Parttime`)
    //     .get().then(snap2 => {
    //       const data = [];
    //       snap2.forEach(parttime => data.push({ _id: parttime.id, ...parttime.data() }))
    //       return data;
    //     })
    //   promises2.push(promise2);
    // });

    // Promise.all(promises2).then(results => {
    //   // console.log('all promises were done')
    //   // console.log(results);
    //   const parttimeData = results.filter(item => item.length);
    //   this.setState({ parttimeData, loading: 0 }, () => this.getPrintData())
    // }).catch(error => {
    //   console.log(error);
    // });
  }

  // handleChange = (event, data) => this.setState({ classSchool: data.value }, () => this.handleFetch())

  // getPrintData = () => {
  //   const { students } = this.props;
  //   const { parttimeData, arrRow } = this.state;
  //   let obj = {};
  //   let params = [];
  //   arrRow.map((item, index) => {//目前只抓班級中的學生
  //     const parttime = parttimeData.find(item2 => item2[0].studentId === item._id) || {};
  //     obj.permitNum = !parttime.length ? '' : parttime[0].permitNum
  //     obj.permitDate = !parttime.length ? '' : parttime[0].permitDate
  //     obj.storeName = !parttime.length ? '' : parttime[0].storeName
  //     obj.storeAddress = !parttime.length ? '' : parttime[0].storeAddress
  //     obj.storetel = !parttime.length ? '' : parttime[0].storetel
  //     obj.workday = !parttime.length ? '' : parttime[0].workday
  //     obj.worktime = !parttime.length ? '' : parttime[0].worktime
  //     obj.salary = !parttime.length ? '' : parttime[0].salary
  //     obj.personincharge = !parttime.length ? '' : parttime[0].personincharge
  //     let doc = { ...item, ...obj }
  //     params.push(doc)
  //     obj = {}
  //   })
  //   this.setState({ params })
  // }

  render() {
    const { history, match, optSet } = this.props;
    const { arrRow, loading, classSchool, classObj, params } = this.state
    // const classSchoolOpt = getOptionsSelByKey(optSet, "classSchool");

    // console.log(parttimeData)
    return (<div ref={el => (this.componentRef = el)} className="">
      <h2 style={{ float: 'left' }}>{classObj && classObj.entryYear && classObj.entryYear.substr(-2)}{classObj && classObj.entrySemester}-{classObj && classObj.number} {classSchool}クラス</h2>
      <div style={{ float: 'right' }}>
        {/* <ReactToPrint
          trigger={(e) => <Button
            content='印刷'
            // label='&nbsp;'
            color='orange' icon='print'
            size='small'
            floated='right'
            className='no-print'
          />}
          content={() => this.componentRef}
          pageStyle={"@page { size: auto;  margin:5mm; }*{font-family: 'Noto Serif JP', serif;}"}
        /> */}
        {/* <ModalPdf
          {...this.props}
          // ref={(ref) => { this.pdfModal = ref }}
          codekey="table05"
          params={params}
        /> */}
        <ExportTableToExcelButton floated='right' size='small' />
        {/* <Input labelPosition='left' style={{ marginRight: 20 }}>
          <Label color='violet'><Icon name='building' />クラス</Label>
          <Form.Select
            style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}
            name='classroom'
            value={classSchool}
            options={classSchoolOpt}
            onChange={this.handleChange} />
        </Input> */}
      </div>
      <div style={{ clear: 'both' }}></div>
      <Table celled striped compact structured fixed
        className="thPadding2px tdPadding2px" id='excel'>
        <Table.Header >
          <Table.Row textAlign='center'>
            <Table.HeaderCell ></Table.HeaderCell>
            <Table.HeaderCell >学籍番号</Table.HeaderCell>
            <Table.HeaderCell >氏名</Table.HeaderCell>
            <Table.HeaderCell >カタカナ</Table.HeaderCell>
            <Table.HeaderCell >英語表記</Table.HeaderCell>
            <Table.HeaderCell ></Table.HeaderCell>
            <Table.HeaderCell ></Table.HeaderCell>
            <Table.HeaderCell ></Table.HeaderCell>
            <Table.HeaderCell ></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body >
          {arrRow.length ? arrRow.map((item, index) => <Table.Row key={index}>
            <Table.Cell>{index + 1}</Table.Cell>
            <Table.Cell>{item.studentId}</Table.Cell>
            <Table.Cell>{item.jpnName}</Table.Cell>
            <Table.Cell>{item.katakanaName}</Table.Cell>
            <Table.Cell>{item.engName}</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          ) : (<Table.Row><Table.Cell colSpan='9' textAlign='center'>(資料無し)</Table.Cell></Table.Row>)}
        </Table.Body>
      </Table>
      {/* {loading ? <Segment>
        <Dimmer active inverted={companyObj.inverted}>
          <Loader inverted={companyObj.inverted}>Loading</Loader>
        </Dimmer>
        <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
      </Segment> : null} */}
    </div>)
  }
}
