import React, { Component } from 'react'
import { Container, Header, Dimmer, Loader, Image, Segment, Divider, Select, Form } from 'semantic-ui-react';
import MaterialTable from 'material-table'
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import { dateCol, exportPdf } from 'views/web/CustomMaterialTable';

const objDay_off_HoursSel = {
  "0": 0, "1": 1, "2": 2, "3": 3, "4": 4,
  "5": 5, "6": 6, "7": 7,
};

const authYN = { '0': '無', '1': '有' };

const all_day_off_class_sel = {
  // { id:"", value:""},
  '0': '公假',
  '1': '公傷病假',
  '2': '事假',
  '3': '病假',
  '4': '婚假',
  '5': '傷假',
  '6': '產假',
  '7': '生理假',
  '8': '陪產假',
  '9': '特休假',
  '10': '育嬰假',
  '11': '補休假',
  '12': '補休申請',
};

const deductions_sel = {
  "0": "全薪",
  "1": "半薪",
  "2": "不扣薪"
};

const columns1 = () => ([
  // { title: '類別 ', field: 'type_id', lookup: { '1': 'Pru Life', '2': 'VOYA', '3': 'Colonial' } },
  { title: '工號', field: 'jobNum', editable: 'never' },
  { title: '員工姓名', field: 'name', editable: 'never' },
  { title: '代理人權限', field: 'auth_substitutego', lookup: authYN },
  { title: '放行主管權限', field: 'auth_supervisorgo', lookup: authYN },
  { title: '放行總經理權限', field: 'auth_generalmanagergo', lookup: authYN },
  { title: '放行人資權限', field: 'auth_hrgo', lookup: authYN },
]);

const columns2 = () => ([
  // { title: '類別 ', field: 'type_id', lookup: { '1': 'Pru Life', '2': 'VOYA', '3': 'Colonial' } },
  { title: '假別', field: 'all_day_off_class', lookup: all_day_off_class_sel },
  { title: '每月工作天數', field: 'equation' },
  { title: '扣薪', field: 'deductions', lookup: deductions_sel },
  { title: '可用天數', field: 'available_days' },
]);

const columns3 = () => ([
  // { title: '類別 ', field: 'type_id', lookup: { '1': 'Pru Life', '2': 'VOYA', '3': 'Colonial' } },
  { title: '工號', field: 'jobNum', editable: 'never' },
  { title: '員工姓名', field: 'name', editable: 'never' },
  { title: '就職日', field: 'onbroad_date', editable: 'never' },
  { title: '年資', field: 'jobyear', editable: 'never' },
  { title: '去年剩餘特休(天)', field: 'preAnnualLeaveDay', type: 'numeric' },
  { title: '去年剩餘特休(時)', field: 'preAnnualLeaveHour', lookup: objDay_off_HoursSel, },
  { title: '本年度特休(天數)', field: 'annualLeave', type: 'numeric' },
]);

export default class Hr7 extends Component {
  state = {
    loginUser: this.props.user.profile.toJSON(),
    dataArr: [],
    dataArr2: [],
    selTypeId: '1',
    bgId: '',
    bgSel: [],
    employee: [],
    loading: true,
    yearAll: [],
    yearId: '',
  }

  async componentDidMount() {
    const { loginUser } = this.state
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const authPage = window.location.pathname.split('/')[2]
    const authUser = companyId + '_' + authPage
    let auth = {}
    if ((loginUser.authAdminA && loginUser.authAdminA.indexOf(companyId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
      auth = {
        edit: true,
      }
    } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
      auth = {
        edit: false,
      }
    } else {
      auth = {
        edit: false,
      }
    }
    const employee = await Parse.queryData('User', { authUserA: companyObj.objectId, authLoginA: companyObj.objectId })

    const year = await Parse.queryData('mgyear', { companyId })
    const yearAll = year.map(item => ({ key: item.objectId, value: item.value_AD, text: `${item.value_AD}` }))
    let now = String(new Date().getFullYear())
    const yearId = (yearAll.find(item => item.value === now) && yearAll.find(item => item.value === now).value) || ''
    this.setState({ auth, yearAll, yearId, employee }, () => this.handleFetch())
    // this.handleFetch();
  }

  // componentWillReceiveProps(nextProps) {
  //   this.handleFetch(nextProps);
  // }

  handleFetch = async (props = this.props) => {

    const { companyObj } = props;
    const { yearId, employee } = this.state;
    // const db = firebase.firestore();
    this.setState({ loading: true })

    // const snap = await db.collection(`users`).where("authUserA", "array-contains", companyObj._id).get()
    // .catch(err => { console.log('Error getting document', err); });
    // snap.forEach(item => employee.push({ _id: item.id, ...item.data() }));
    const dataArr = employee.map(item => {
      let obj = {}
      if (item.annualLeaveObj && item.annualLeaveObj[yearId]) {
        if (item.annualLeaveObj[yearId]) {
          obj = {
            ...item.annualLeaveObj[yearId]
          }
        }
      } else {
        obj = {
          preAnnualLeaveDay: 0,
          preAnnualLeaveHour: '0',
          annualLeave: 0,
        }
      }

      if (item.onbroad_date) {
        let now = new Date().getTime()
        let onbroad = new Date(item.onbroad_date).getTime()
        let seniorityYear = parseInt((now - onbroad) / 1000 / 60 / 60 / 24 / 365)
        let seniorityMonth = parseInt(((now - onbroad) - (seniorityYear * 86400000 * 365)) / 1000 / 60 / 60 / 24 / 30)
        if (seniorityMonth < 0) {
          seniorityMonth = 0
        }
        return {
          ...item,
          ...obj,
          jobyear: `${seniorityYear}年${seniorityMonth}月`
        }
      } else {
        return {
          ...item,
          ...obj,
          jobyear: `未輸入就職日`
        }
      }
    })
    const dataArr2 = []
    dataArr.forEach(item => {
      if (item && item.onbroad_date && item.onbroad_date.split('-')[0] <= yearId) {
        dataArr2.push({ ...item })
      }
    })
    this.setState({ dataArr2, dataArr, loading: false });
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value }, () => this.handleFetch())
  }

  handleUpdate = async (id) => {
    const { companyObj } = this.props
    const { newData, yearId } = this.state
    let obj = {}
    if (id === 0) {
      obj = {
        ...newData
      }
    } else if (id === 1) {
      let annualObj = {
        preAnnualLeaveDay: newData.preAnnualLeaveDay,
        preAnnualLeaveHour: newData.preAnnualLeaveHour,
        annualLeave: newData.annualLeave,
      }
      obj = {
        ...newData,
        annualLeaveObj: { ...newData.annualLeaveObj, [yearId]: annualObj }
      }
    }
    await Parse.saveData('User', obj);
  }

  render() {
    const { companyObj } = this.props
    const { dataArr, dataArr2, loading, auth = {}, yearAll, yearId } = this.state;

    return (<>
      <div className="content">
        {/* <Container> */}
        {/* <Select name="bgId" style={{ marginRight: '4px', position: 'bgId', zIndex: 99, float: 'right' }} options={bgSel} value={bgId} onChange={this.handleChange} /> */}
        {/* <div style={{ ...style.flexCenter, margin: '2rem auto' }}>
        <Header as='h2' style={{ margin: 0 }}></Header>
        <Button content='隸屬事業群設定' />
      </div> */}
        <br />
        <br />
        <br />
        <MaterialTable
          isLoading={loading}
          localization={localization()}
          tableRef={this.tableRef}
          columns={columns1()}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportAllData: true,
            exportPdf: (columns, data) => exportPdf(columns, data, '人員設定'),
            pageSize: dataArr.length || 10,
            search: false,
            tableLayout: 'fixed',
            // showFirstLastPageButtons: true
          }}
          data={dataArr}
          title="人員設定"
          editable={{
            isEditHidden: () => !auth.edit,
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const data = [...dataArr];
                const index = data.indexOf(oldData);
                data[index] = newData;
                // handleState('people0', data);
                this.setState({ dataArr: data, newData, oldData }, () => resolve());
              }).then(() => this.handleUpdate(0)),
          }}
        />
        <br />
        <br />
        <Divider />
        <br />
        <br />
        <Form.Group inline>
          <Form.Select label='年度' options={yearAll} name='yearId' onChange={this.handleChange} value={yearId} style={{ zIndex: 99 }} />
        </Form.Group>
        <br />
        <br />
        <MaterialTable
          isLoading={loading}
          localization={localization()}
          tableRef={this.tableRef}
          columns={columns3()}
          options={{
            addRowPosition: "first",
            exportButton: true,
            exportAllData: true,
            exportPdf: (columns, data) => exportPdf(columns, data, '特休設定'),
            pageSize: dataArr2.length || 10,
            search: false,
            // showFirstLastPageButtons: true
          }}
          // actions={[
          //   {
          //     icon: 'view_headline',
          //     tooltip: '查看',
          //     onClick: (event, rowData) => this.handleData(rowData)
          //   }
          // ]}
          data={dataArr2}
          title="特休設定"
          editable={{
            isEditHidden: () => !auth.edit,
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const data = [...dataArr2];
                const index = data.indexOf(oldData);
                data[index] = newData;
                // handleState('people0', data);
                this.setState({ dataArr2: data, newData, oldData }, () => resolve());
              }).then(() => this.handleUpdate(1)),
          }}
        />
        <br />
        <br />
        <p style={{ color: '#FFFFFF' }}>*特休天數
      <br />     1、工作滿六個月未達一年者 3 日。
      <br />     2、工作一年以上未滿兩年者 7 日。
      <br />     3、二年以上未滿三年者 10 日。
      <br />     4、三年以上未滿五年者 14 日。
      <br />     5、五年以上未滿十年者 15 日。
      <br />     6、第十年起是 16 日，逐年增加 1 日，加至 30 日為止。
      <br />     7、該年度未使用完之天數，可多延6個月期限。
      <br />
          <br />  *喪假天數
      <br />  1、父母、養父母、繼父母、配偶喪亡：8天
      <br />  2、祖父母、外祖父母、子女、配偶之父母、配偶之養父母或繼父母喪亡：6天
      <br />  3、配偶之祖父母、配偶之外祖父母、兄弟、姐妹喪亡：3天 </p>
        <br />
        <br />
      </div>
    </>
    )
  }
}