
import React, { Component } from 'react'
import { Header, Form, Grid, Menu, Input, Dimmer, Loader, Table, Button, Confirm, FormGroup } from 'semantic-ui-react';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MaterialTable from 'material-table'
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { DelayInput } from 'react-delay-input';

import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import ModalNewMember from './ModalNewMember';
// import ModalHandle from './ModalHandle';

const style = {
  flex: {
    display: 'flex'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  sellModalBtn: {
    width: '100%',
    height: '52px',
    fontSize: '22px',

  },
  formControl: {
    fontSize: '32px',
    height: '65px',
  },
  sellModalBtn2: {
    width: '100%',
    height: '52px',
    fontSize: '22px',
  }
}

class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      searchValue: '',
      objectId: '',
      clientObj: {},
      userAll: [],
      employee: [],
      memberArr: [],
      open: false,
      memberOpen: false,
      memberObj: {}
    }
    this.tableRef = React.createRef();
  }
  componentDidMount = () => {
    this.handleFetch()
  }
  handleFetch = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data1 = await Parse.queryData('clients', { companyId });

    this.setState({ memberArr: data1 })
  }
  memberOpen = () => { this.setState({ memberOpen: true }) }
  memberClose = () => { this.setState({ memberOpen: false }) }
  open = () => { this.setState({ open: true }) }
  close = () => { this.setState({ open: false }) }
  handleButton = (e, { dataPrice, dataType }) => {
    console.log(dataPrice, dataType);
  }
  handleMemberButton = (memberObj) => {
    this.setState({ memberObj, memberOpen: false })
  }
  render() {
    const { companyObj, user, profile } = this.props
    const { searchValue, loading, memberArr, userAll, memberObj, memberOpen, open } = this.state;
    const userSel = userAll.sel || {}
    return (<>
      <Button style={{ width: '100%', marginTop: '20px' }} color='blue' content='入帳出帳' onClick={this.open} />
      <Modal
        isOpen={open}
        size='lg'
        toggle={this.close}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggle}>
            <span aria-hidden="true">×</span>
          </button>
        開桌
      </ModalHeader>
        <ModalBody><>
          <div className="modal-content">
            <div className="modal-body">
              <h3>入帳</h3>
              <div className="row">
                <div className="col-md-4">
                  <Button style={{ ...style.sellModalBtn, backgroundColor: '#51cbce' }} dataPrice="1000" dataType="1" className="btn btn-primary sell-modal-btn btn-buy-stuff" onClick={this.handleButton}>儲值</Button>
                </div>
                <div className="col-md-4">
                  <Button style={{ ...style.sellModalBtn, backgroundColor: '#51cbce', fontSize: '18px' }} id="member_card" type="button" className="btn btn-primary sell-modal-btn btn-buy-stuff" dataPrice="100" dataType="2" onClick={this.handleButton}>會員卡 (一般)</Button>
                </div>
                <div className="col-md-4">
                  <Button style={{ ...style.sellModalBtn, backgroundColor: '#51cbce', fontSize: '18px' }} type="button" className="btn btn-primary sell-modal-btn btn-buy-stuff" dataPrice="50" dataType="2" onClick={this.handleButton}>會員卡 (住戶)</Button>
                </div>
              </div>
              <div className="row" style={{ marginTop: '10px', marginBottom: '10px' }}>
                <div className="col-md-4">
                  <Button style={{ ...style.sellModalBtn, backgroundColor: '#51cbce' }} type="button" className="btn btn-primary sell-modal-btn btn-buy-stuff" dataPrice="100" dataType="3" onClick={this.handleButton}>頭飾(100)</Button>
                </div>
                <div className="col-md-4">
                  <Button style={{ ...style.sellModalBtn, backgroundColor: '#51cbce' }} type="button" className="btn btn-primary sell-modal-btn btn-buy-stuff" dataPrice="120" dataType="3" onClick={this.handleButton}>頭飾(120)</Button>
                </div>
                <div className="col-md-4">
                  <Button style={{ ...style.sellModalBtn, backgroundColor: '#51cbce' }} type="button" className="btn btn-primary sell-modal-btn btn-buy-stuff" dataPrice="150" dataType="3" onClick={this.handleButton}>頭飾(150)</Button>
                </div>
              </div>
              <div className="row" style={{ marginTop: '10px', marginBottom: '10px' }}>
                <div className="col-md-4">
                  <Button style={{ ...style.sellModalBtn, backgroundColor: '#51cbce' }} type="button" className="btn btn-primary sell-modal-btn btn-buy-stuff" id="card3" dataPrice="67" dataType="3" onClick={this.handleButton}>員工買鮮奶</Button>
                </div>
                <div className="col-md-4">
                  <Button style={{ ...style.sellModalBtn, backgroundColor: '#51cbce', fontSize: '18px' }} type="button" className="btn btn-primary sell-modal-btn" data-toggle="modal" data-target="#myModalGame" data-type="4" onClick={this.handleButton}>購買/出租桌遊</Button>
                </div>
              </div>
              <h3>出帳</h3>
              <div className="row" style={{ marginTop: '10px', marginBottom: '10px' }}>
                <div className="col-md-4">
                  <button style={{ ...style.sellModalBtn }} type="button" className="btn btn-warning sell-modal-btn btn-payout" data-type="11">返回資本主</button>
                </div>
                <div className="col-md-4">
                  <button style={{ ...style.sellModalBtn }} type="button" className="btn btn-warning sell-modal-btn" data-toggle="modal" data-target="#myModalRentGame" data-type="4">歸還桌遊</button>
                </div>
                <div className="col-md-4">
                  <button style={{ ...style.sellModalBtn }} type="button" className="btn btn-warning sell-modal-btn btn-payout" data-type="12">廠商進貨</button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>其他品項</label>
                    <input style={{ ...style.formControl }} type="text" className="form-control" id="item_name" />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label>交易類別</label>
                    <select style={{ ...style.sellModalBtn }} name="" id="modal-transaction-type" className="sell-modal-btn">
                      <option value="1">入帳</option>
                      <option value="2">出帳</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>金額</label>
                    <input style={{ ...style.formControl }} type="text" className="form-control" id="item_price" />
                  </div>
                </div>
                <div className="col-md-3">
                  <button style={{ ...style.sellModalBtn2, marginTop: '30px' }} type="button" className="btn btn-info sell-modal-btn2" >加入</button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <table className="table">
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th>品項</th>
                        <th>單價</th>
                        <th>數量</th>
                        <th>小計</th>
                        <th>備註</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td >
                          <Button color='red'>刪除</Button>
                        </td>
                        {/* <td>{{ get_index }}</td>
        <td>{{ name }}</td> */}
                        <td>
                          {/* <span id="buy-price{{@index}}">{{ price }}</span> */}
                        </td>
                        <td>
                          {/* <input data-id="{{@index}}" id="buy-num{{@index}}" className="buyitem-num" name="" type="number" value="1" style="width:50px;text-align:center" /> */}
                        </td>
                        <td>
                          {/* <span id="buy_subtotal{{@index}}">{{ buy_sub_total }}</span> */}
                        </td>
                        {/* <td>{ps}</td> */}
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>總計：</td>
                        {/* <td>{{ item_total }}</td> */}
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <button style={{ ...style.sellModalBtn, marginTop: '30px', fontSize: '18px' }} type="button" className="btn btn-info sell-modal-btn" data-toggle="modal" data-target="#myModalMember" onClick={this.memberOpen}>選擇會員</button>
                </div>
                <div className="col-md-9">
                  <table className="table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>會員類別</th>
                        <th>會員名稱</th>
                        <th>會員手機</th>
                        <th>備註</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td >
                          {/* <a href="#" className="del_membersession" style="color:#d33">
            <i className="fa fa-trash-o"></i>刪除</a> */}
                        </td>
                        <td></td>
                        <td>{memberObj.name}</td>
                        <td>{memberObj.cellnum}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>目前無會員卡</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label>備註 (房號/抬頭/統編...)</label>
                    <input style={{ ...style.formControl }} type="text" className="form-control" id="ps_detail" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    {/* <label>經手人</label> */}
                    {/* <select name="" id="manager"  className="sell-modal-btn" multiple> */}
                    經手人 <Form.Input style={{ ...style.sellModalBtn }} type="text" name='staff' value={(profile && profile.name) || ''} readOnly /><br />
                    {/* <option value="{{_id}}">{{ nickname }}</option> */}
                    {/* </select> */}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </></ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button content='關閉' onClick={this.close} />
          <Button positive content='儲存' icon='add' onClick={this.handleSubmit} />
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={memberOpen}
        size='md'
        toggle={this.memberClose}
      >
        <ModalHeader className="justify-content-center">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggle}>
            <span aria-hidden="true">×</span>
          </button>
        開桌
      </ModalHeader>
        <ModalBody><>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>會員</Table.HeaderCell>
                <Table.HeaderCell>手機</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {memberArr.map((item, index) =>
                <Table.Row key={index}>
                  <Table.Cell>
                    <Button positive onClick={() => this.handleMemberButton(item)}>選擇</Button>
                  </Table.Cell>
                  <Table.Cell>{item.name}</Table.Cell>
                  <Table.Cell>{item.cellnum}</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </></ModalBody>
        <ModalFooter style={{ padding: '16px' }}>
          <Button onClick={this.memberClose} >關閉</Button>
          {/* <Button positive icon='add' onClick={thi s.handleSubmit}>儲存</Button> */}
        </ModalFooter>
      </Modal>
    </>);
  }
}

export default Client;
