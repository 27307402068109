import React, { Component } from 'react';
import { Grid, Table } from 'semantic-ui-react';
import Parse from '../../../widget/parse'

// const tdCss = {
//   whiteSpace: 'nowrap',
//   overflow: 'hidden',
//   textOverflow: 'ellipsis'
// };

export default class ViewStudentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // studentArr: [],
      classroom: [],
      students: [],
      loadingClassroom: false,
    };
  }
  componentDidMount() {
    this.handleFetch()
  }
  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }
  handleFetch = async (props = this.props) => {
    const { semesterYear, semester, companyObj } = props;
    // const db = firebase.firestore();
    const classroom = await Parse.queryData('Classroom', { companyId: companyObj.objectId, semesterYear, isEnableClass: true, semester }, { orderBy: 'classSchool' });
    this.setState({ classroom, loadingClassroom: false })
    // db.collection("Classroom")
    //   .where('semesterYear', '==', semesterYear)
    //   .where('semester', '==', semester)
    //   .where('isEnableClass', '==', true)
    //   .orderBy('classSchool')
    //   .get().then(snap => {
    //     const data = [];
    //     snap.forEach(doc => data.push({ _id: doc.id, ...doc.data() }));
    //     this.setState({ classroom: data, loadingClassroom: false });
    //   });

  }

  render() {
    const { semesterYear, semester, studentsArr } = this.props;
    const { classroom, students } = this.state;
    console.log(semester, semesterYear)
    const classroomArr = classroom && classroom.filter(item => item.semesterYear === semesterYear && item.semester === semester);
    const numPerRow = 6
    // console.log(classroomArr)
    const numTotal = classroomArr && classroomArr.length;

    const arr = students.filter(item => (item.studentStatus !== '50' && item.studentStatus !== '51' && item.studentStatus !== '31' && item.studentStatus !== '32' && item.studentStatus !== '0') &&
      (item.applyResult === '1' || item.studentType === "2"))

    return (<Grid style={{ margin: '0px' }} id='excel'>
      {classroomArr && classroomArr.map((item, index) => <React.Fragment key={index}>
        {index % numPerRow === 0 ? <Grid.Row columns='equal' style={{ paddingTop: '0px' }}>
          {classroomArr.slice(index, index + numPerRow <= numTotal ? (index + numPerRow) : (index + (numTotal % numPerRow)))
            .map((classObj, index) => {
              const studentsByClass = studentsArr.filter(item1 => item1.classSchool === classObj.classSchool);
              return (<Grid.Column key={classObj.objectId} style={{ paddingLeft: '1px', paddingRight: '2px', }}>
                <Table celled padded size="small" className="tdPadding0px thPadding0px">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign='center' colSpan={4}>{classObj.entryYear === semesterYear ? '新入生クラス' : '卒業生クラス'} {classObj.entryYear.slice(2, 4)}{(classObj.entryMonth === '4' ? 'S' : 'A')}</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell style={{ width: '40px', textAlign: 'center' }}>{classObj.classSchool} {classObj.classroom ? <span style={{}}>({classObj.classroom})</span> : null}</Table.Cell>
                      <Table.Cell style={{ width: '120px' }}>{classObj.teacherText}  {classObj.teacherTitleText2 ? <span>({classObj.teacherTitleText2})</span> : null}</Table.Cell>
                      <Table.Cell style={{}}>{classObj.level ? `${classObj.level}級` : ''}</Table.Cell>
                      <Table.Cell style={{}}>{studentsByClass.length}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell style={{}}>
                        {/* 学籍 */}
                        番号</Table.Cell>
                      <Table.Cell colSpan={2} style={{}}>氏名</Table.Cell>
                      <Table.Cell style={{}}>{classObj.classTimeText}</Table.Cell>
                    </Table.Row>
                    {studentsByClass.sort((a, b) => a.studentId - b.studentId).map((item2, index2) =>
                      <Table.Row key={item2.objectId}
                        style={{
                          background: item2.plannedWayOut1 === '1' ? '#FFFF99' ://yellow
                            item2.plannedWayOut1 === '2' ? '#99CC33' ://green
                              item2.plannedWayOut1 === '3' ? '#99CCFF' ://blue
                                item2.plannedWayOut1 === '4' ? '#FF9900' ://orange
                                  item2.plannedWayOut1 === '6' ? '#c0c0c0' : null//grey
                          // item2.plannedWayOut1 === '5' ? '' : 
                        }}>
                        {(item2.semesterYear !== classObj.entryYear || item2.semester !== (classObj.entryMonth === '4' ? 'S' : 'A')) ?
                          <Table.Cell style={index2 === 10 ? { borderTop: '1px #000 dashed !important' } : {}} >{item2.studentId + '(' + item2.semesterYear.slice(2, 4) + item2.semester + ')'}</Table.Cell> :
                          <Table.Cell style={index2 === 10 ? { borderTop: '1px #000 dashed !important' } : {}} >{item2.studentId}</Table.Cell>}
                        {/* <Table.Cell style={index2 === 10 ? { borderTop: '1px #000 dashed !important' } : {}} >{item2.studentId}</Table.Cell> className='tdNoWrap'*/}
                        {/* <Table.Cell style={index2 === 10 ? { borderTop: '1px #000 dashed !important', textOverflow: 'linebreak', whiteSpace: 'pre-wrap' } : {}} >{item2.jpnName && item2.jpnName.length > 10 ? item2.jpnName.substring(0, 10) + '\n' + item2.jpnName.substring(10, item2.jpnName.length) : item2.jpnName}</Table.Cell>
                        <Table.Cell style={index2 === 10 ? { borderTop: '1px #000 dashed !important', whiteSpace: 'pre-wrap' } : {}} colSpan={2} >{item2.katakanaName && item2.katakanaName.length > 16 ? item2.katakanaName.substring(0, 16) + '\n' + item2.katakanaName.substring(16, item2.katakanaName.length) : item2.katakanaName}</Table.Cell> */}
                        <Table.Cell style={index2 === 10 ? { borderTop: '1px #000 dashed !important', textOverflow: 'linebreak' } : { textOverflow: 'linebreak' }} >{item2.jpnName}</Table.Cell>
                        <Table.Cell style={index2 === 10 ? { borderTop: '1px #000 dashed !important', textOverflow: 'linebreak' } : { textOverflow: 'linebreak' }} colSpan={2} >{item2.katakanaName}</Table.Cell>
                      </Table.Row>)}
                  </Table.Body>
                </Table>
              </Grid.Column>);
            })}
        </Grid.Row> : null}
      </React.Fragment>)}
    </Grid>);
  }
}