import React, { Component } from 'react'
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
import { Container, Header, Form, Icon, Button } from 'semantic-ui-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MaterialTable, { MTableToolbar } from 'material-table'
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import PageHeader from 'components/Headers/PageHeader';
import { ListGroup, ListGroupItem } from 'reactstrap';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import PreloadFile from '../../views/web/PreloadFile'
import { readFileAsURL } from '../../views/web/RowImage';
import ModalUploadFiles from './ModalUploadFiles';
import { dateCol, imgBtnCol, imgPreviewCol, exportPdf, rowReOrder } from '../web/CustomMaterialTable';

const columns = ({ isMultiLang, photos }) => ([
  { width: 60, title: '順序', field: 'order', type: 'numeric' },
  // { title: '封面', field: 'iscover', type: 'boolean', initialEditValue: false },
  imgBtnCol({ field: 'img1File' }),
  imgPreviewCol({ title: '照片', field: 'img1File', photos }),
  { title: `說明${isMultiLang ? '(繁體中文)' : ''}`, field: 'name' },
  { title: '描述', field: 'description' },
  { title: '簡體中文', field: 'zhHant', hidden: !isMultiLang },
  { title: '日文', field: 'ja', hidden: !isMultiLang },
  { title: '英文', field: 'en', hidden: !isMultiLang },
  { title: '越南文', field: 'vi', hidden: !isMultiLang },
  { title: '備註', field: 'ps' },
]);

export class ModalEditPic extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
      show: false,
      imagePreviewUrlArr: [],
      photo: '',
      colorDropdown: false,
      data1: []
    };
  }

  componentDidMount = () => {
    // const { companyObj } = this.props
    // const { loginUser } = this.state
    // const companyId = companyObj.objectId
    // const authPage = window.location.pathname.split('/')[2]
    // const authUser = companyId + '_' + authPage
    // let auth = {}
    // if ((loginUser.authAdminA&&loginUser.authAdminA.indexOf(companyId) !== -1) || (loginUser.authObj && loginUser.authObj[authUser] && loginUser.authObj[authUser] === '2')) {
    //   auth = {
    //     edit: true,
    //   }
    // } else if (loginUser.authObj && loginUser.authObj[authUser] && (loginUser.authObj[authUser] === '0' || loginUser.authObj[authUser] === '1')) {
    //   auth = {
    //     edit: false,
    //   }
    // } else {
    //   auth = {
    //     edit: false,
    //   }
    // }
    this.handleFetch(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, pageObj, group } = this.props
    const companyId = companyObj.objectId
    this.setState({ loading1: true })
    const data = await Parse.queryData('webimage', { companyId, pageId: pageObj.objectId, group });

    // console.log(data, rowData.objectId)
    this.setState({ loading1: false, data1: data });
  }
  handleState = (dataArr, arrName = 'dataArr') => {
    this.setState({ [arrName]: dataArr })
  }
  handleChange = (e, { value }) => this.setState({ value })
  closeEdit = () => { this.setState({ show: false }) }
  onEdit = () => { this.setState({ show: true }) }
  handleColorDropdown = () => {
    const { colorDropdown } = this.state
    if (colorDropdown === false) {
      const colorDropdown = document.getElementById("colorDropdown");
      colorDropdown.style.display = 'grid';
      this.setState({ colorDropdown: true })
    } else {
      const colorDropdown = document.getElementById("colorDropdown");
      colorDropdown.style.display = 'none';
      this.setState({ colorDropdown: false })
    }
  }
  handleUpdate = async () => {
    const { handleFetch } = this.props
    const { newData } = this.state;
    await Parse.saveData('webimage', newData);
    this.setState({ newData: {}, oldData: {} })
    handleFetch()
  }
  handleDelete = async () => {
    const { handlePhotoDelete } = this.props
    const { oldData } = this.state
    await Parse.deleteData('webimage', oldData);
    handlePhotoDelete(oldData)
    this.setState({ oldData: {} })
  }
  //   handleChange = (e) => {
  //     // console.log(e);
  //     this.setState({
  //       [e.target.id]: e.target.value
  //     })
  //   }

  //   handleSubmit = (e) => {
  //     // console.log(e);
  //     e.preventDefault();
  //     this.props.createProject(this.state);
  //     this.props.history.push('/');
  //   }
  handleChangeFile = async (file) => {
    this.setState({ file, changeConfirm: false })
  }
  handleChange = async (e) => {
    e.preventDefault();
    const files = e.target.files
    if (!files || !files.length) {
      return
    }
    const promises = [];
    const item = files[0];
    const element = readFileAsURL(item)
    promises.push(element);
    const imagePreviewUrlArr = await Promise.all(promises).catch(error => console.log("error", error));
    this.setState({ imagePreviewUrlArr })
  }

  render() {
    const { handleChangeFile, pageObj, photo, companyObj } = this.props
    const { show, imagePreviewUrlArr, data1 } = this.state
    //     if (!auth.uid) return <Redirect to='/signin' />
    // const { value } = this.state
    return (<>
      <a className="j-pop-gallery" data-popup="#popup-gallerysetting" onClick={this.onEdit}>編輯</a>
      <Modal
        isOpen={show}
        toggle={this.closeEdit}
        size="lg"
        // className="mh-100 h-90 mw-100 w-90"
        style={{ maxWidth: '1200px', width: '70%', margin: '10px auto' }}
      >
        <ModalHeader >
          <Icon name='close' onClick={this.closeEdit} style={{ cursor: 'pointer', margin: 0 }}></Icon>
        </ModalHeader>
        <ModalBody>
          <ModalUploadFiles {...this.props} isImage='1' pageObj={pageObj} handleData={() => { this.handleFetch(pageObj) }} />
          <br />
          <br />
          <MaterialTable
            // isLoading={loading1}
            localization={localization()}
            columns={columns({ isMultiLang: companyObj.isMultiLang, photos: data1 })} options={{
              addRowPosition: "first",
              exportButton: true,
              exportPdf: (columns, data) => exportPdf(columns, data, '照片'),
              pageSize: 10,
              search: false,
            }}
            tableRef={this.tableRef}
            data={data1}
            title='照片'
            components={{
              Row: props => rowReOrder(props, 'webimage', 'order', data1, 'data1', this.handleState)
            }}
            // actions={[
            //   {
            //     icon: 'view_headline',
            //     tooltip: '查看',
            //     onClick: (event, rowData) => this.handleData(rowData)
            //   }
            // ]}
            editable={{
              // isEditHidden: () => true,
              // isDeleteHidden: () => true,
              // onRowAdd: newData =>
              //   new Promise(async (resolve, reject) => {
              //     const db = Parse.Object.extend("webimage");
              //     const newDoc = new db();
              //     const docRef = await newDoc.save();
              //     const data = [...data1];
              //     let obj = {
              //       ...newData,
              //       id: docRef.id,
              //       _id: docRef.id,
              //       objectId: docRef.id,
              //       companyId: companyObj.objectId,
              //       companyName: companyObj.name,
              //       albumId: rowData.objectId
              //     }
              //     data.push(obj);
              //     this.setState({ data1: data, newData: obj }, () => resolve());
              //   }).then(() => this.handleUpdate('2')),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  let data = [...data1];
                  const index = data.indexOf(oldData);
                  if (newData.iscover === true) {
                    data = data1.map(item => ({ ...item, iscover: false }))
                  }
                  data[index] = newData;
                  let obj = { ...newData }

                  this.setState({ data1: data, newData: obj, oldData }, () => resolve());
                }).then(() => this.handleUpdate('2')),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  let data = [...data1];
                  const index = data.indexOf(oldData);
                  data.splice(index, 1);
                  this.setState({ data1: data, oldData }, () => resolve());
                }).then(() => this.handleDelete('2')),
            }}
          />
          {/* <MaterialTable
            // isLoading={loading}
            localization={localization()}
            // columns={columns()}
            options={{
              addRowPosition: "first",
              exportButton: true,
              // exportPdf: (columns, data) => exportPdf(columns, data, form.componentId),
              pageSize: 10,
              search: false,
              tableLayout: 'fixed'
            }}
            // tableRef={this.tableRef}
            data={data1}
            title={'相片匯入'}
            editable={{
              onRowAdd: newData =>
                new Promise(async (resolve, reject) => {
                  const db = Parse.Object.extend("webimage");
                  const newDoc = new db();
                  const docRef = await newDoc.save();
                  let data = [...data1];
                  let obj = {
                    ...newData,
                    id: docRef.id,
                    _id: docRef.id,
                    objectId: docRef.id,
                    companyId: companyObj.objectId,
                    companyName: companyObj.name,
                    pageId: pageObj.objectId,
                    componentId: pageObj.name,
                    navId: pageObj.nav1Id,
                  }
                  // if (newData.iscover === true) {
                  //   data = data.map(item => ({ ...item, iscover: false }))
                  // }
                  data.push(obj);
                  // handleState('people0', data);
                  this.setState({ data1: data, newData: obj, loading: true }, () => resolve());
                }).then(() => this.handleAdd()),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  let data = [...data1];
                  const index = data.indexOf(oldData);
                  // if (newData.iscover === true) {
                  //   data = data1.map(item => ({ ...item, iscover: false }))
                  // }
                  data[index] = newData;
                  let obj = { ...newData, }
                  // handleState('people0', data);
                  this.setState({ data1: data, newData: obj, oldData, loading: true }, () => resolve());
                }).then(() => this.handleUpdate()),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  let data = [...data1];
                  const index = data.indexOf(oldData);
                  data.splice(index, 1);
                  // handleState('people0', data);
                  this.setState({ data1: data, oldData }, () => resolve());
                }).then(() => this.handleDelete()),
            }}
          /> */}
          {/* <div class="white-popup" id="popup-gallerysetting">
            <form class="setBox slide_Photo import_photo" data-parsley-required-message="尚未填寫" data-parsley-type-message="格式錯誤" data-parsley-pattern-message="格式錯誤" novalidate="">
              <div class="top_title">
                <h3>相片匯入</h3>
              </div>
              <p class="btn_hollow">
                <a class="j-pop-import" data-popup="#popup-galleryimport">
                  <i class="fas fa-plus"></i> 從社群載入            </a>
              </p>
              <div class="detail-setting clearfix">
                <div class="color-edit-toolbar" onClick={this.handleColorDropdown}>
                  <a class="j-n-dropdown icon-bgcolor">
                    <span class="circleline btn_in" ></span>
                    <span>文字顏色</span>
                  </a>
                  <div id='colorDropdown' class="j-n-list-color choose-iconcolor" style={{ display: 'none' }}>
                    <a class="j-n-action default" data-css="color" data-value="">
                      <i class="fas fa-ban"></i>預設                    </a>
                    <ul class="the-colors clearfix">
                      <li><a class="j-n-action" title="#F44336" data-css="color" data-value="#F44336" style={{ backgroundColor: '#F44336' }}><i class="fas fa-check" style={{ display: 'none' }}></i></a></li>
                      <li><a class="j-n-action" title="#E91E63" data-css="color" data-value="#E91E63" style={{ backgroundColor: '#E91E63' }}><i class="fas fa-check" style={{ display: 'none' }}></i></a></li>
                      <li><a class="j-n-action" title="#9C27B0" data-css="color" data-value="#9C27B0" style={{ backgroundColor: '#9C27B0' }}><i class="fas fa-check" style={{ display: 'none' }}></i></a></li>
                      <li><a class="j-n-action" title="#014D88" data-css="color" data-value="#014D88" style={{ backgroundColor: '#014D88' }}><i class="fas fa-check" style={{ display: 'none' }}></i></a></li>
                      <li><a class="j-n-action" title="#2196F3" data-css="color" data-value="#2196F3" style={{ backgroundColor: '#2196F3' }}><i class="fas fa-check" style={{ display: 'none' }}></i></a></li>
                      <li><a class="j-n-action" title="#01BCD4" data-css="color" data-value="#01BCD4" style={{ backgroundColor: '#01BCD4' }}><i class="fas fa-check" style={{ display: 'none' }}></i></a></li>
                      <li><a class="j-n-action" title="#8BC34A" data-css="color" data-value="#8BC34A" style={{ backgroundColor: '#8BC34A' }}><i class="fas fa-check" style={{ display: 'none' }}></i></a></li>
                      <li><a class="j-n-action" title="#CDDC39" data-css="color" data-value="#CDDC39" style={{ backgroundColor: '#CDDC39' }}><i class="fas fa-check" style={{ display: 'none' }}></i></a></li>
                      <li><a class="j-n-action" title="#FFC107" data-css="color" data-value="#FFC107" style={{ backgroundColor: '#FFC107' }}><i class="fas fa-check" style={{ display: 'none' }}></i></a></li>
                      <li><a class="j-n-action" title="#FFEB3B" data-css="color" data-value="#FFEB3B" style={{ backgroundColor: '#FFEB3B' }}><i class="fas fa-check" style={{ display: 'none' }}></i></a></li>
                      <li><a class="j-n-action" title="#FFFFFF" data-css="color" data-value="#FFFFFF" style={{ backgroundColor: '#ffffff', border: '1px solid #aaaaaa' }}></a></li>
                      <li><a class="j-n-action" title="#AAAAAA" data-css="color" data-value="#AAAAAA" style={{ backgroundColor: '#AAAAAA' }}><i class="fas fa-check" style={{ display: 'none' }}></i></a></li>
                      <li><a class="j-n-action" title="#000000" data-css="color" data-value="#000000" style={{ backgroundColor: '#000000' }}><i class="white fas fa-check" style={{ display: 'none' }}></i></a></li>
                      <li><a class="j-n-action" title="#714343" data-css="color" data-value="#714343" style={{ backgroundColor: '#714343' }}><i class="fas fa-check" style={{ display: 'none' }}></i></a></li>
                    </ul>
                    <div class="user-customize">
                      <p>自定義顏色</p>
                      <input class="color-code" type="text" name="color" value="" placeholder="自定義顏色" />
                    </div>
                  </div>
                </div>
                <div class="btn-switch clearfix">
                  <p>文字背景漸層</p>
                  <input type="hidden" name="gradient-color" value="" />
                  <label class="switch-type">
                    <input type="checkbox" id="gradient-color" data-parsley-excluded="true" data-parsley-multiple="gradient-color" />
                    <div class="styled-switch"></div>
                  </label>
                </div>
              </div>
              <ul class="j-n-gallery-contents Photo_list clearfix" style={{ display: 'block' }}>
                <li class="f-daggle" draggable="false">
                  <div class="f-upload-image photo">
                    <div class="the_btn">
                      <p>
                        <a class="j-n-upload"><i class="fas fa-undo-alt"></i>更新</a>
                        <a class="j-n-delete"><i class="fas fa-trash-alt"></i>刪除</a>
                      </p>
                    </div>
                    <img src="//img.holkee.com/site/theme/unknown/5ed97cfac8f9f9ba7fbee0bd3aaa674f_double.jpg" alt='' />
                    <input type="hidden" name="photo.src" value="site/theme/unknown/5ed97cfac8f9f9ba7fbee0bd3aaa674f_double.jpg" />
                    <input type="hidden" name="photo.width" value="1000" />
                    <input type="hidden" name="photo.height" value="1000" />
                    <input class="hide" type="file" accept="image/*" />
                  </div>
                  <p class="for_pop_note color_red" style={{ display: 'none' }}>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i><span></span>
                  </p>
                  <div class="product_fillinBox">
                    <textarea name="caption.text" placeholder="描述"></textarea>
                    <input type="text" name="caption.link" placeholder="連結網址" data-parsley-trigger="change" data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$" data-parsley-pattern-message="格式錯誤" />
                  </div>
                  <span class="list_nb">1</span>
                  <input type="hidden" name="sort" value="1" />
                </li>
                <li class="f-daggle" draggable="false">
                  <div class="f-upload-image photo">
                    <div class="the_btn">
                      <p>
                        <a class="j-n-upload"><i class="fas fa-undo-alt"></i>更新</a>
                        <a class="j-n-delete"><i class="fas fa-trash-alt"></i>刪除</a>
                      </p>
                    </div>
                    <img src="//img.holkee.com/site/theme/unknown/b4ad1464fd4f3535c6ed2532bb02dc24_double.jpg" alt='' />
                    <input type="hidden" name="photo.src" value="site/theme/unknown/b4ad1464fd4f3535c6ed2532bb02dc24_double.jpg" />
                    <input type="hidden" name="photo.width" value="1000" />
                    <input type="hidden" name="photo.height" value="1000" />
                    <input class="hide" type="file" accept="image/*" />
                  </div>
                  <p class="for_pop_note color_red" style={{ display: 'none' }}>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i><span></span>
                  </p>
                  <div class="product_fillinBox">
                    <textarea name="caption.text" placeholder="描述"></textarea>
                    <input type="text" name="caption.link" placeholder="連結網址" data-parsley-trigger="change" data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$" data-parsley-pattern-message="格式錯誤" />
                  </div>
                  <span class="list_nb">2</span>
                  <input type="hidden" name="sort" value="2" />
                </li>
                <li class="f-daggle" draggable="false">
                  <div class="f-upload-image photo">
                    <div class="the_btn">
                      <p>
                        <a class="j-n-upload"><i class="fas fa-undo-alt"></i>更新</a>
                        <a class="j-n-delete"><i class="fas fa-trash-alt"></i>刪除</a>
                      </p>
                    </div>
                    <img src="//img.holkee.com/site/theme/unknown/fcb3b1a3b7dd8ddc96e76b2358e7e399_double.jpg" alt='' />
                    <input type="hidden" name="photo.src" value="site/theme/unknown/fcb3b1a3b7dd8ddc96e76b2358e7e399_double.jpg" />
                    <input type="hidden" name="photo.width" value="1000" />
                    <input type="hidden" name="photo.height" value="1000" />
                    <input class="hide" type="file" accept="image/*" />
                  </div>
                  <p class="for_pop_note color_red" style={{ display: 'none' }}>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i><span></span>
                  </p>
                  <div class="product_fillinBox">
                    <textarea name="caption.text" placeholder="描述"></textarea>
                    <input type="text" name="caption.link" placeholder="連結網址" data-parsley-trigger="change" data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$" data-parsley-pattern-message="格式錯誤" />
                  </div>
                  <span class="list_nb">3</span>
                  <input type="hidden" name="sort" value="3" />
                </li>
                <li class="f-daggle" draggable="false">
                  <div class="f-upload-image photo">
                    <div class="the_btn">
                      <p>
                        <a class="j-n-upload"><i class="fas fa-undo-alt"></i>更新</a>
                        <a class="j-n-delete"><i class="fas fa-trash-alt"></i>刪除</a>
                      </p>
                    </div>
                    <img src="//img.holkee.com/site/theme/unknown/8018d290a985eab3d6f47c630a9295a2_double.jpg" alt='' />
                    <input type="hidden" name="photo.src" value="site/theme/unknown/8018d290a985eab3d6f47c630a9295a2_double.jpg" />
                    <input type="hidden" name="photo.width" value="1000" />
                    <input type="hidden" name="photo.height" value="1000" />
                    <input class="hide" type="file" accept="image/*" />
                  </div>
                  <p class="for_pop_note color_red" style={{ display: 'none' }}>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i><span></span>
                  </p>
                  <div class="product_fillinBox">
                    <textarea name="caption.text" placeholder="描述"></textarea>
                    <input type="text" name="caption.link" placeholder="連結網址" data-parsley-trigger="change" data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$" data-parsley-pattern-message="格式錯誤" />
                  </div>
                  <span class="list_nb">4</span>
                  <input type="hidden" name="sort" value="4" />
                </li>
                <li class="f-daggle" draggable="false">
                  <div class="f-upload-image photo">
                    <div class="the_btn">
                      <p>
                        <a class="j-n-upload"><i class="fas fa-undo-alt"></i>更新</a>
                        <a class="j-n-delete"><i class="fas fa-trash-alt"></i>刪除</a>
                      </p>
                    </div>
                    <img src="//img.holkee.com/site/theme/unknown/ff2463a15c739851f10407a2e677afed_double.jpg" alt='' />
                    <input type="hidden" name="photo.src" value="site/theme/unknown/ff2463a15c739851f10407a2e677afed_double.jpg" />
                    <input type="hidden" name="photo.width" value="1000" />
                    <input type="hidden" name="photo.height" value="1000" />
                    <input class="hide" type="file" accept="image/*" />
                  </div>
                  <p class="for_pop_note color_red" style={{ display: 'none' }}>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i><span></span>
                  </p>
                  <div class="product_fillinBox">
                    <textarea name="caption.text" placeholder="描述"></textarea>
                    <input type="text" name="caption.link" placeholder="連結網址" data-parsley-trigger="change" data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$" data-parsley-pattern-message="格式錯誤" />
                  </div>
                  <span class="list_nb">5</span>
                  <input type="hidden" name="sort" value="5" />
                </li>
                <li class="f-daggle" draggable="false">
                  <div class="f-upload-image photo">
                    <div class="the_btn">
                      <p>
                        <a class="j-n-upload"><i class="fas fa-undo-alt"></i>更新</a>
                        <a class="j-n-delete"><i class="fas fa-trash-alt"></i>刪除</a>
                      </p>
                    </div>
                    <img src="//img.holkee.com/site/theme/unknown/7dc07c0aca3527f8a0c4649419b7afea_double.jpg" alt='' />
                    <input type="hidden" name="photo.src" value="site/theme/unknown/7dc07c0aca3527f8a0c4649419b7afea_double.jpg" />
                    <input type="hidden" name="photo.width" value="1000" />
                    <input type="hidden" name="photo.height" value="1000" />
                    <input class="hide" type="file" accept="image/*" />
                  </div>
                  <p class="for_pop_note color_red" style={{ display: 'none' }}>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i><span></span>
                  </p>
                  <div class="product_fillinBox">
                    <textarea name="caption.text" placeholder="描述"></textarea>
                    <input type="text" name="caption.link" placeholder="連結網址" data-parsley-trigger="change" data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$" data-parsley-pattern-message="格式錯誤" />
                  </div>
                  <span class="list_nb">6</span>
                  <input type="hidden" name="sort" value="6" />
                </li>
                <li class="f-daggle" draggable="false">
                  <div class="f-upload-image photo">
                    <div class="the_btn">
                      <p>
                        <a class="j-n-upload"><i class="fas fa-undo-alt"></i>更新</a>
                        <a class="j-n-delete"><i class="fas fa-trash-alt"></i>刪除</a>
                      </p>
                    </div>
                    <img src="//img.holkee.com/site/theme/unknown/efb4efac5e7c9d7b8f69075ea948951c_double.jpg" alt='' />
                    <input type="hidden" name="photo.src" value="site/theme/unknown/efb4efac5e7c9d7b8f69075ea948951c_double.jpg" />
                    <input type="hidden" name="photo.width" value="1000" />
                    <input type="hidden" name="photo.height" value="1000" />
                    <input class="hide" type="file" accept="image/*" />
                  </div>
                  <p class="for_pop_note color_red" style={{ display: 'none' }}>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i><span></span>
                  </p>
                  <div class="product_fillinBox">
                    <textarea name="caption.text" placeholder="描述"></textarea>
                    <input type="text" name="caption.link" placeholder="連結網址" data-parsley-trigger="change" data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$" data-parsley-pattern-message="格式錯誤" />
                  </div>
                  <span class="list_nb">7</span>
                  <input type="hidden" name="sort" value="7" />
                </li>
                <li class="f-daggle" draggable="false">
                  <div class="f-upload-image photo">
                    <div class="the_btn">
                      <p>
                        <a class="j-n-upload"><i class="fas fa-undo-alt"></i>更新</a>
                        <a class="j-n-delete"><i class="fas fa-trash-alt"></i>刪除</a>
                      </p>
                    </div>
                    <img src="//img.holkee.com/site/theme/unknown/f89cf56a5b5391146b3a17dab2227893_double.jpg" alt='' />
                    <input type="hidden" name="photo.src" value="site/theme/unknown/f89cf56a5b5391146b3a17dab2227893_double.jpg" />
                    <input type="hidden" name="photo.width" value="1000" />
                    <input type="hidden" name="photo.height" value="1000" />
                    <input class="hide" type="file" accept="image/*" />
                  </div>
                  <p class="for_pop_note color_red" style={{ display: 'none' }}>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i><span></span>
                  </p>
                  <div class="product_fillinBox">
                    <textarea name="caption.text" placeholder="描述"></textarea>
                    <input type="text" name="caption.link" placeholder="連結網址" data-parsley-trigger="change" data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$" data-parsley-pattern-message="格式錯誤" />
                  </div>
                  <span class="list_nb">8</span>
                  <input type="hidden" name="sort" value="8" />
                </li>
                <li class="f-daggle" draggable="false">
                  <div class="f-upload-image photo">
                    <div class="the_btn">
                      <p>
                        <a class="j-n-upload"><i class="fas fa-undo-alt"></i>更新</a>
                        <a class="j-n-delete"><i class="fas fa-trash-alt"></i>刪除</a>
                      </p>
                    </div>
                    <img src="//img.holkee.com/site/theme/unknown/e4bc1dd4c904d2faa66beced9982d5a9_double.jpg" alt='' />
                    <input type="hidden" name="photo.src" value="site/theme/unknown/e4bc1dd4c904d2faa66beced9982d5a9_double.jpg" />
                    <input type="hidden" name="photo.width" value="1000" />
                    <input type="hidden" name="photo.height" value="1000" />
                    <input class="hide" type="file" accept="image/*" />
                  </div>
                  <p class="for_pop_note color_red" style={{ display: 'none' }}>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i><span></span>
                  </p>
                  <div class="product_fillinBox">
                    <textarea name="caption.text" placeholder="描述"></textarea>
                    <input type="text" name="caption.link" placeholder="連結網址" data-parsley-trigger="change" data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$" data-parsley-pattern-message="格式錯誤" />
                  </div>
                  <span class="list_nb">9</span>
                  <input type="hidden" name="sort" value="9" />
                </li>
                <li class="f-daggle" draggable="false">
                  <div class="f-upload-image photo">
                    <div class="the_btn">
                      <p>
                        <a class="j-n-upload"><i class="fas fa-undo-alt"></i>更新</a>
                        <a class="j-n-delete"><i class="fas fa-trash-alt"></i>刪除</a>
                      </p>
                    </div>
                    <img src="//img.holkee.com/site/theme/unknown/63228355096376b2d297a31823816ce9_double.jpg" alt='' />
                    <input type="hidden" name="photo.src" value="site/theme/unknown/63228355096376b2d297a31823816ce9_double.jpg" />
                    <input type="hidden" name="photo.width" value="1000" />
                    <input type="hidden" name="photo.height" value="1000" />
                    <input class="hide" type="file" accept="image/*" />
                  </div>
                  <p class="for_pop_note color_red" style={{ display: 'none' }}>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i><span></span>
                  </p>
                  <div class="product_fillinBox">
                    <textarea name="caption.text" placeholder="描述"></textarea>
                    <input type="text" name="caption.link" placeholder="連結網址" data-parsley-trigger="change" data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$" data-parsley-pattern-message="格式錯誤" />
                  </div>
                  <span class="list_nb">10</span>
                  <input type="hidden" name="sort" value="10" />
                </li>

                <li class="f-daggle" draggable="false">
                  <div class="f-upload-image photo">
                    <div class="the_btn">
                      <p>
                        <a class="j-n-upload"><i class="fas fa-undo-alt"></i>更新</a>
                        <a class="j-n-delete"><i class="fas fa-trash-alt"></i>刪除</a>
                      </p>
                    </div>
                    <img src="//img.holkee.com/site/theme/unknown/4a960ecc8acedc3aabb1eddb36e9f63e_double.jpg" alt='' />
                    <input type="hidden" name="photo.src" value="site/theme/unknown/4a960ecc8acedc3aabb1eddb36e9f63e_double.jpg" />
                    <input type="hidden" name="photo.width" value="1000" />
                    <input type="hidden" name="photo.height" value="1000" />
                    <input class="hide" type="file" accept="image/*" />
                  </div>
                  <p class="for_pop_note color_red" style={{ display: 'none' }}>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i><span></span>
                  </p>
                  <div class="product_fillinBox">
                    <textarea name="caption.text" placeholder="描述"></textarea>
                    <input type="text" name="caption.link" placeholder="連結網址" data-parsley-trigger="change" data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$" data-parsley-pattern-message="格式錯誤" />
                  </div>
                  <span class="list_nb">11</span>
                  <input type="hidden" name="sort" value="11" />
                </li>
                <li class="none_move">
                  <div class="photo">
                    <div class="img_upload">
                      <a class="j-n-add"><p><span><i class="images fas fa-upload"></i>新增</span></p></a>
                    </div>
                  </div>
                </li>
              </ul>
              <p class="the_reminder">
                提醒您，若連結未填寫，就不會顯示外部連結。<br />請確保圖片解析度，最佳建議尺寸為 1000*1000px 以上 JPG 或 PNG。        </p>
            </form>
          </div> */}
        </ModalBody>
        <ModalFooter style={{ padding: '16px', justifyContent: 'center' }}>
          <Button style={{ backgroundColor: '#27d0ca', color: '#fff' }} content='關閉' onClick={this.closeEdit} />
        </ModalFooter>
      </Modal>
    </>)
  }
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     createProject: (project) => dispatch(createProject(project))
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HostIndex)
export default ModalEditPic