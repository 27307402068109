import jsPDF from '../pdf/JspdfMyFonts';

import { prayPage141, prayPage131, prayPage138, memberPage193, prayPage140, prayPage132, prayPage137, prayPage134, prayPage164, taisuiPage151, taisuiPage153, taisuiPage152, prayPage133, prayPage135, prayPage161, prayPage162, prayPage163, prayPage121, memberPage191, memberPage192, prayPage139, Borrowgod11, prayPageD00, prayPageA02 } from './pdfDao';
// import { arrObjTest, arrObjTest2, arrObjTest1, arrObjTest3, arrObjTest4, arrObjTest6, arrObjTest8, arrObjTest5 } from '../pdf/pdfTest';
// import Parse from 'widget/parse'

const d = new Date();
const nowDate = `${d.getFullYear() - 1911}年${d.getMonth() + 1}月${d.getDate()}日`;

const data = (props) => {
  const { arrObj, companyObj, nowform, params = {}, printDate, lightType, selectData } = props;
  console.log(nowform)
  // const companyId = companyObj.objectId
  // const praytypeObj = await Parse.getSelection('praytype', { companyId }, { name: 'name', sort: 'order' }); // 抓所有的類別
  // const praytypeObjArr = praytypeObj.arr || []
  const arrObj2 = params.arrObj2 || []
  let doc;

  console.log(nowform, params.serviceTypeId,
    arrObj, arrObj2
  )
  const serviceTypeId = params.serviceTypeId || '';
  // console.log(nowform)
  // doc = new jsPDF({ format: 'a4', unit: 'pt' });
  if (nowform === "141" || nowform === "a03" || nowform === "b01" || nowform === "c01" || nowform === "d01") { // 報名申報內容
    doc = new jsPDF({ format: 'a4', orientation: "p", unit: 'pt' });

    for (let i = 0; i < arrObj.length; i++) {
      // const entry = arrObj[i];
      //Praying2.find({ listId: entry._id }).fetch();
      const arrP2 = arrObj2.filter(item => item.praying1Id === arrObj[i].objectId);
      if (arrP2.length > 0) {

        ////實際顯示資料數
        let itemCount = 0;
        for (let j = 0; j < arrP2.length; j++) {
          if (arrP2[j].praytypeText && arrP2[j].praytypeText.indexOf("其他 - ") !== -1) {
            continue;
          }
          itemCount++;
        }
        // let total_page = Math.ceil(itemCount / 6) || 1;
        // let now_page = 1;
        // console.log(total_page);
        // doc.font('BiauKai').fontSize(18);
        // console.log(arrP2)
        for (let j = 0; j < itemCount; j += 6) {
          // const element = arrP2[i];
          let sixItemPerPage = j;
          // console.log(index);
          // Api.prayPage41(doc, objP1, arrP2.slice(i, i + 6));
          prayPage141(doc, arrObj[i], arrP2.slice(j, j + 6), sixItemPerPage);

          //     doc.text("第 " + now_page + "頁，共 " + total_page + "頁", 50 - 10, 805, {
          //         width: a4pageWidth - 100,
          //         align: 'center'
          //     });
          //     if (i + 6 < itemCount) {
          //         // console.log(doc);
          //         doc.addPage();
          //         now_page++;
          //     }
        }
        if (i + 1 < arrObj.length) {
          doc.addPage();
        }
      }
    }
  } else if (nowform === "131") { // 報名表格
    doc = new jsPDF({ format: 'a4', orientation: "p", unit: 'pt' });
    prayPage131(doc, arrObj, nowDate);
  } else if (nowform === "138") { // 法會名冊
    doc = new jsPDF({ format: 'a4', orientation: "p", unit: 'pt' });
    prayPage138(doc, arrObj, 0, "二三法會", '3000', nowDate, 1);
  } else if (nowform === "140") { // 正度附度甲狀
    doc = new jsPDF({ format: 'a3', orientation: "l", unit: 'pt' });
    console.log(arrObj)
    console.log(arrObj2)
    prayPage140(doc, arrObj, arrObj2, nowDate);
  } else if (nowform === "132") { // 靈寶大法司
    doc = new jsPDF({ format: 'a4', orientation: "l", unit: 'pt' });
    for (let i = 0; i < arrObj2.length; i++) {
      const objP1 = arrObj.find(item => item.objectId === arrObj2[i].praying1Id);
      prayPage132(doc, objP1, arrObj2[i], nowDate);
      if (i + 1 < arrObj2.length) {
        doc.addPage();
      }
    }
  } else if (nowform === "139") { // 財庫封條
    doc = new jsPDF({ format: 'a3', orientation: "p", unit: 'pt' });
    prayPage139(doc, arrObj, arrObj2, nowDate);
  } else if (nowform === "137") { // 圓桌名條
    doc = new jsPDF({ format: 'g2', orientation: "l", unit: 'pt' });
    prayPage137(doc, arrObj, 0);
  } else if (nowform === "134") { // 禮斗文疏
    doc = new jsPDF({ format: 'b4', orientation: "l", unit: 'pt' });
    let objP1 = []
    if (arrObj.length > 4) {
      for (let i = 0; i < arrObj.length; i++) {
        objP1.push(arrObj[i])
        if ((i + 1) % 5 === 0) {
          prayPage134(doc, objP1, nowDate);
          doc.addPage();
          objP1 = []
        }
      }
    } else {
      prayPage134(doc, arrObj, nowDate);
    }

  } else if (nowform === "164") { // 斗燈清單
    doc = new jsPDF({ format: 'a4', orientation: "p", unit: 'pt' });
    // prayPage164(doc, arrObj, 0, '王一二', 3000, nowDate, nowDate, 1);
    let objP1 = []
    if (arrObj.length > 4) {
      for (let i = 0; i < arrObj.length; i++) {
        objP1.push(arrObj[i])
        if ((i + 1) % 10 === 0) {
          prayPage164(doc, objP1, 0, '王一二', 3000, nowDate, nowDate, 1);
          doc.addPage();
          objP1 = []
        }
      }
    } else {
      prayPage164(doc, arrObj, 0, '王一二', 3000, nowDate, nowDate, 1);
    }
  } else if (nowform === "151") { // 太歲疏文
    doc = new jsPDF({ format: 'b4', orientation: "l", unit: 'pt' });
    taisuiPage151(doc, arrObj, '太歲星君姓名', nowDate);
  } else if (nowform === "153") { // 太歲清單
    doc = new jsPDF({ format: 'a4', orientation: "p", unit: 'pt' });
    taisuiPage153(doc, arrObj, 0, '3000', nowDate);
  } else if (nowform === "152") { // 太歲流年表
    doc = new jsPDF({ format: 'b4', orientation: "p", unit: 'pt' });
    taisuiPage152(doc, arrObj, nowDate);
    // } else if (nowform === "133") { // 財神祈福
  } else if (nowform === "a01" && serviceTypeId === '3DuyPpq6Af') { // 財神祈福
    doc = new jsPDF({ format: 'b4', orientation: "l", unit: 'pt' });

    const arr2 = arrObj2.map(item => item.praying1Id);
    const arr2Unique = Array.from(new Set(arr2));

    arr2Unique.forEach((item, i) => {
      const arrP2 = arrObj2.filter(item2 => item2.praying1Id === item);
      // console.log(arrP2)
      prayPage133(doc, arrP2, nowDate)
      if (i + 1 < arr2Unique.length) {
        doc.addPage();
      }
    })
    // } else if (nowform === "135") { // 消災補運祈福  && 智慧燈
  } else if (nowform === "a01" && (serviceTypeId === 'RqRzFpmEdi' || serviceTypeId === 'kjqdZdQGfx')) { // 消災補運祈福 && 智慧燈
    doc = new jsPDF({ format: 'a3', orientation: "l", unit: 'pt' });
    const arr2 = arrObj2.map(item => item.praying1Id);
    const arr2Unique = Array.from(new Set(arr2));

    arr2Unique.forEach((item, i) => {
      const arrP2 = arrObj2.filter(item2 => item2.praying1Id === item);
      // console.log(arrP2)
      prayPage135(doc, arrP2, nowDate);
      if (i + 1 < arr2Unique.length) {
        doc.addPage();
      }
    })

    // } else if (nowform === "161") { // 業障祈福
  } else if (nowform === "a01" && serviceTypeId === 'QtPLM7eo26') { // 業障祈福
    doc = new jsPDF({ format: 'a3', orientation: "l", unit: 'pt' });
    prayPage161(doc, arrObj2, nowDate);
    // } else if (nowform === "162") { // 事業祈福
  } else if (nowform === "a01" && serviceTypeId === 'RuCnXptgGz') { // 事業祈福
    doc = new jsPDF({ format: 'a3', orientation: "l", unit: 'pt' });
    prayPage162(doc, arrObj, nowDate);
    // } else if (nowform === "163") { // 身體祈福
  } else if (nowform === "a01" && serviceTypeId === 'HGqzFb5bSj') { // 身體祈福
    doc = new jsPDF({ format: 'a3', orientation: "l", unit: 'pt' });
    prayPage163(doc, arrObj, nowDate);
  } else if (nowform === "121") { // 禳燈消災植福
    doc = new jsPDF({ format: 'a3', orientation: "l", unit: 'pt' });
    prayPage121(doc, arrObj, nowDate);
    // } else if (nowform === "191") { // 名冊
  } else if (nowform === "191") { // 名冊
    doc = new jsPDF({ format: 'a4', orientation: "p", unit: 'pt' });
    memberPage191(doc, arrObj, nowDate);
  } else if (nowform === "192") { // 請柬--背面
    doc = new jsPDF({ format: 'a4', orientation: "p", unit: 'pt' });
    memberPage192(doc, arrObj, nowDate);
  } else if (nowform === "193") { // 敬邀--正面
    doc = new jsPDF({ format: 'a4', orientation: "p", unit: 'pt' });
    memberPage193(doc, arrObj, nowDate);
  } else if (nowform === "200") { // 借神
    doc = new jsPDF({ format: 'a4', orientation: "p", unit: 'pt' });
    Borrowgod11(doc, arrObj, nowDate);
  } else if (nowform === 'a02') { //祈福清單
    doc = new jsPDF({ format: 'a4', orientation: "p", unit: 'pt' });
    prayPageA02(doc, arrObj, 0, '3000', nowDate);
  } else if (nowform === 'd00') { //光明燈清單
    doc = new jsPDF({ format: 'a4', orientation: "p", unit: 'pt' });
    prayPageD00(doc, arrObj, 0, '3000', nowDate);
  }
  return doc
}

export default data;
