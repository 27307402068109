import React, { Component } from 'react'
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
import { Container, Header, Form, Icon, Button } from 'semantic-ui-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import PageHeader from 'components/Headers/PageHeader';
import { ListGroup, ListGroupItem } from 'reactstrap';
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'
import PreloadFile from '../../views/web/PreloadFile'
import { readFileAsURL } from '../../views/web/RowImage';

export class ModalEditPic extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
      show: false,
      imagePreviewUrlArr: [],
      photo: ''
    };
  }


  handleChange = (e, { value }) => this.setState({ value })
  closeEdit = () => { this.setState({ show: false }) }
  onEdit = () => { this.setState({ show: true }) }
  //   handleChange = (e) => {
  //     // console.log(e);
  //     this.setState({
  //       [e.target.id]: e.target.value
  //     })
  //   }

  //   handleSubmit = (e) => {
  //     // console.log(e);
  //     e.preventDefault();
  //     this.props.createProject(this.state);
  //     this.props.history.push('/');
  //   }
  handleChangeFile = async (file) => {
    this.setState({ file, changeConfirm: false })
  }
  handleChange = async (e) => {
    e.preventDefault();
    const files = e.target.files
    if (!files || !files.length) {
      return
    }
    const promises = [];
    const item = files[0];
    const element = readFileAsURL(item)
    promises.push(element);
    const imagePreviewUrlArr = await Promise.all(promises).catch(error => console.log("error", error));
    this.setState({ imagePreviewUrlArr })
  }

  render() {
    const { handleChangeFile, originPic, photo } = this.props
    const { show, imagePreviewUrlArr } = this.state
    //     if (!auth.uid) return <Redirect to='/signin' />
    // const { value } = this.state
    return (<>
      <a className="j-pop-image" onClick={this.onEdit}>編輯</a>
      <Modal
        isOpen={show}
        toggle={this.closeEdit}
        size="lg"
      // className="mh-100 h-90 mw-100 w-90"
      >
        <ModalHeader >
          <Icon name='close' onClick={this.closeEdit} style={{ cursor: 'pointer', margin: 0 }}></Icon>
        </ModalHeader>
        <ModalBody>
          <div className="white-popup" id="popup-imagesetting">
            <form className="setBox" data-parsley-required-message="尚未填寫">
              <div className="top_title">
                <h3>圖片設定</h3>
              </div>
              <div className="fillinBox">
                <div className="f-upload-image img_upload">
                  <a className="j-n-upload">
                    <div className="the_btn">
                      <label for='file' style={{ width: '100%', height: '100%', cursor: 'pointer' }}>
                        <p>
                          <span>
                            <i className="fas fa-undo-alt"></i>重新上傳
                          </span>
                        </p>
                      </label>
                      <input id="file" type="file" style={{ display: 'none' }} onChange={this.handleChange} accept='image/*' />
                    </div>
                    <img src={(imagePreviewUrlArr.length && imagePreviewUrlArr[0]) || originPic} alt='' />
                  </a>
                  <p className="the_reminder">提醒您，請確保圖片解析度，最佳建議尺寸為寬度 1000px 以上 JPG 或 PNG。</p>
                  <input type="hidden" name="src" value="site/theme/unknown/6caf44ea63578b440ab6aac66cd7783d_double.png" />
                  <input type="hidden" name="width" value="1600" />
                  <input type="hidden" name="height" value="500" />
                  <input className="hide" type="file" accept="image/*" />
                </div>
                <p className="for_pop_note color_red" style={{ display: 'none' }}><i className="fa fa-exclamation-circle" aria-hidden="true"></i><span></span></p>
              </div>
            </form>
          </div>
        </ModalBody>
        <ModalFooter style={{ padding: '16px', justifyContent: 'center' }}>
          <Button style={{ backgroundColor: '#27d0ca', color: '#fff' }} content='套用' onClick={() => { handleChangeFile(imagePreviewUrlArr[0], photo); this.closeEdit() }} />
        </ModalFooter>
      </Modal>
    </>)
  }
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     createProject: (project) => dispatch(createProject(project))
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HostIndex)
export default ModalEditPic