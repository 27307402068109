import React, { Component } from 'react'
import { Table, Header, Segment, Tab, Select } from 'semantic-ui-react';
import MaterialTable, { MTableToolbar } from 'material-table'
import { Row, Col } from "reactstrap";

import { dateCol, typeCurrency, monthOpt, mgyearOpt, exportPdf } from "views/web/CustomMaterialTable";
import Parse from 'widget/parse'
import localization from 'widget/MaterialTableOpt'

const columns = (typeSel = {}) => ([
  dateCol({ title: '日期', field: 'date' }),
  { width: 80, title: '傳票No.', field: 'invoice' },
  { width: 80, title: '科目', field: 'type', lookup: typeSel },
  { width: 180, title: '摘要', field: 'summary' },
  { width: 80, title: '支出', field: 'expenditure', ...typeCurrency },
  { width: 80, title: '收入', field: 'income', ...typeCurrency },

]);

const d = new Date()
class Cash extends Component {
  state = {
    activeItem: 'bio',
    dataArr: [],
    rowData: {},
    loading: true,
    monthSel: String(d.getMonth() + 1),
    mgyearSel: String(d.getFullYear() - 1911),
    totalAccId: 'total',
    totalExpenditure: 0,
    totalIncome: 0,
    typeAll: { opt: [], sel: {}, arr: [] },
    type: '-1',
    arr: []
  }

  async componentDidMount() {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const typeAll = await Parse.getSelection('subject', { companyId }, { name: 'name', sort: 'order' }); // 抓所有的類別
    this.setState({ typeAll }, () => this.handleData())
  }

  handleData = async () => {
    const { mgyearSel, monthSel, type, typeAll } = this.state
    this.setState({ loading: true });
    const data1 = await Parse.queryData('Cash', { year: mgyearSel, month: monthSel });

    let expenditureTotal = 0;
    let incomeTotal = 0;
    const arr = typeAll.arr.map(item => {
      let expenditure = 0;
      let income = 0;
      data1.filter(item2 => item2.type === item.objectId).forEach(item2 => {
        expenditure += Number(item2.expenditure)
        income += Number(item2.income)
      });
      expenditureTotal += expenditure;
      incomeTotal += income;

      return {
        ...item,
        expenditure,
        income
      }
    });
    this.setState({ arr, expenditureTotal, incomeTotal });

    let dataArr = data1;
    if (type !== '-1') {
      dataArr = await Parse.queryData('Cash', { type, year: mgyearSel, month: monthSel });
    }
    this.setState({ dataArr, loading: false });
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value, totalAccId: value }, () => this.handleData())
  }

  handleAdd = async () => {
    const { newData } = this.state;
    this.setState({ loading: true });
    await Parse.saveData('Cash', newData);
    this.handleData();
    this.setState({ loading: false });
  }

  handleUpdate = async () => {
    const { newData } = this.state;
    this.setState({ loading: true });
    await Parse.saveData('Cash', newData);
    this.handleData();
    this.setState({ loading: false });
  }

  handleDelete = async () => {
    const { oldData } = this.state
    this.setState({ loading: true });
    await Parse.deleteData('Cash', oldData);
    this.setState({ oldData: {}, loading: false })
  }

  render() {
    const { dataArr, loading, totalAccId, monthSel, mgyearSel, typeAll, type, arr, expenditureTotal, incomeTotal } = this.state
    const typeSel = typeAll.sel || {}
    const typeOpt = typeAll.opt || []

    return (<div className="content">
      <Row>
        <Col><legend>雜支</legend></Col>
      </Row>
      <Select name="mgyearSel" style={{ position: 'relative', zIndex: 99, marginRight: '8px' }} options={mgyearOpt} value={mgyearSel} onChange={this.handleChange} />
      <Select name="monthSel" style={{ position: 'relative', zIndex: 99, marginRight: '8px' }} options={monthOpt} value={monthSel} onChange={this.handleChange} />
      <Table celled style={{ marginTop: "1rem" }} size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>項目</Table.HeaderCell>
            <Table.HeaderCell>支出</Table.HeaderCell>
            <Table.HeaderCell>收入</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {arr.map(item =>
            <Table.Row>
              <Table.Cell>{item.name}</Table.Cell>
              <Table.Cell>{item.expenditure}</Table.Cell>
              <Table.Cell>{item.income}</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>合計</Table.HeaderCell>
            <Table.HeaderCell>{expenditureTotal}</Table.HeaderCell>
            <Table.HeaderCell>{incomeTotal}</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <br />
      <Select name="type" style={{ position: 'relative', zIndex: 99 }} options={[{ key: 'aa', value: '-1', text: '全部' }].concat(typeOpt)} value={type} onChange={this.handleChange} />
      <br />
      <br />
      <MaterialTable
        isLoading={loading}
        localization={localization()}
        columns={columns(typeSel)}
        options={{
          addRowPosition: "first",
          exportButton: true,
          exportPdf: (columns, data) => exportPdf(columns, data, `${mgyearSel}年 ${monthSel}月`),
          pageSize: 10,
          search: false,
          tableLayout: 'fixed'
        }}
        data={dataArr}
        title={`${mgyearSel}年 ${monthSel}月`}
        // actions={[
        //   {
        //     icon: 'view_headline',
        //     tooltip: '查看',
        //     onClick: (event, rowData) => this.handleData(rowData)
        //   }
        // ]}
        editable={{
          onRowAdd: newData =>
            new Promise(async (resolve, reject) => {
              const data = [...dataArr];
              const db = Parse.Object.extend("Cash");
              const newDoc = new db();
              const doc = await newDoc.save();
              newData.objectId = doc.id;
              newData.id = doc.id;
              newData._id = doc.id;
              newData.totalAccId = totalAccId;
              newData.year = mgyearSel;
              newData.month = monthSel;

              data.push(newData);
              this.setState({ dataArr: data, newData }, () => resolve());
            }).then(() => this.handleAdd()),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const data = [...dataArr];
              const index = data.indexOf(oldData);
              data[index] = newData;
              // handleState('people0', data);
              this.setState({ dataArr: data, newData, oldData }, () => resolve());
            }).then(() => this.handleUpdate()),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              let data = [...dataArr];
              const index = data.indexOf(oldData);
              data.splice(index, 1);
              // handleState('people0', data);
              this.setState({ dataArr: data, oldData }, () => resolve());
            }).then(() => this.handleDelete()),
        }}
      />
      <br />
    </div>);
  }
}

export default Cash